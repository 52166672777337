<mat-form-field *ngIf="model && field && readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getValue()" readOnly disabled [required]="required" />
  <mat-icon matSuffix *ngIf="getValue() && enableGotoButton === true" class="iuc-goto-button" class="iuc-goto-button" (click)="goToUrl()">open_in_new</mat-icon>
</mat-form-field>
<mat-form-field *ngIf="model && field && !readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getValue()" (ngModelChange)="setValue($event)" [required]="required" />
  <mat-icon matSuffix *ngIf="getValue() && enableGotoButton === true" class="iuc-goto-button" class="iuc-goto-button" (click)="goToUrl()">open_in_new</mat-icon>
</mat-form-field>
<div *ngIf="!isValueValid" class="iuc-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
