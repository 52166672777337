import { Component, Input } from '@angular/core';
import { InputAbstractComponent } from '@app/standard/components/input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-password',
  templateUrl: 'input-password.component.html',
  styleUrls: ['input-password.component.scss']
})
export class InputPasswordComponent extends InputAbstractComponent {
  @Input() inputId: string;
  @Input() forceError = false;

  @Input() set avoidAutoFillingPassword(avoidAutoFillingPassword: boolean) {
    if (avoidAutoFillingPassword) {
      this.hidePassword = false;
      setTimeout(() => {
        this.hidePassword = true;
      }, 300);
    }
  }

  hidePassword = true;
}
