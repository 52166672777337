import { Component, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';

import { I18nDataPipe } from '../../components/i18n-data/i18n-data.pipe';
import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-engagement-box',
  templateUrl: 'engagement-box.component.html',
  styleUrls: ['engagement-box.component.scss']
})
export class EngagementBoxComponent implements OnInit {
  RANGE_VERY_LOW: any = { key: 'rangeVeryLow', score: 2, color: '#ff5757', textColor: 'White' };
  RANGE_LOW: any = { key: 'rangeLow', score: 4, color: '#ff8d0e', textColor: 'White' };
  RANGE_MODERATE: any = { key: 'rangeModerate', score: 6, color: '#ffc107', textColor: 'White' };
  RANGE_HIGH: any = { key: 'rangeHigh', score: 8, color: '#B3BD45', textColor: 'White' };
  RANGE_VERY_HIGH: any = { key: 'rangeVeryHigh', score: 10, color: '#00b72e', textColor: 'White' };
  RANGE_EMPTY: any = { key: 'rangeEmpty', color: '#dedede', textColor: 'Black' };

  _value: number;
  @Input()
  set value(val: number) {
    this._value = val;
    this.calculateRange();
  }
  get value(): number {
    return this._value;
  }

  @Input() label: string;
  @Input() numberUnreadFeedbacks: number;
  @Input() categoryKey: string;
  @Input() showFeedbackSection: boolean = true;
  @Input() descriptionText: string;
  @Input() linkToDetail: boolean = true;

  currentRange: any = this.RANGE_MODERATE;
  pageTranslation: any = {};
  totalFeedbacksLabel: string;

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry, private i18nDataPipe: I18nDataPipe) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('engagement-box-component')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.calculateTotalFeedbacksLabel();
      })
      .catch(() => {
        this.pageTranslation = {};
      });
    this.calculateRange();
  }

  private calculateRange(): void {
    if (check.not.assigned(this.value) || check.not.number(this.value)) {
      this.currentRange = this.RANGE_EMPTY;
      return;
    }

    const auxValue = Number(this.getStringValue());
    if (auxValue <= this.RANGE_VERY_LOW.score) {
      this.currentRange = this.RANGE_VERY_LOW;
    } else if (auxValue <= this.RANGE_LOW.score) {
      this.currentRange = this.RANGE_LOW;
    } else if (auxValue <= this.RANGE_MODERATE.score) {
      this.currentRange = this.RANGE_MODERATE;
    } else if (auxValue <= this.RANGE_HIGH.score) {
      this.currentRange = this.RANGE_HIGH;
    } else {
      this.currentRange = this.RANGE_VERY_HIGH;
    }
  }

  private calculateTotalFeedbacksLabel(): void {
    const data = {
      newFeedbacks: this.numberUnreadFeedbacks
    };
    this.totalFeedbacksLabel = this.i18nDataPipe.transform(this.pageTranslation.newFeedbacksLabel, data);
  }

  getStringValue(): string {
    if (check.not.assigned(this.value)) {
      return '-';
    }
    let result = this.precisionRound(this.value, 1).toString();
    if (check.not.contains(result, '.')) {
      result = `${result}.0`;
    }
    return result;
  }

  private precisionRound(number: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  getPercentage(): string {
    return `${this.value * 10}%`;
  }
}
