<orgos-container *ngIf="dialogData && !loading" class="gpd-dialog-box kenjo-p-40px">
  <orgos-column-container [centerColumns]="true" class="kenjo-mb-20px">
    <div orgosColumn class="kenjo-font-size-20px kenjo-pr-20px gpd-title">{{ dialogTranslation.generatePDFtitle }}</div>
    <div
      orgosColumn="0"
      class="kenjo-font-size-14px gpd-beta-box"
      [matTooltip]="dialogTranslation.featureBeta"
      [matTooltipPosition]="'above'"
    >
      {{ dialogTranslation.beta }}
    </div>
    <orgos-column></orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" (click)="closeDialog()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      >clear</kenjo-icon
    >
  </orgos-column-container>
  <div class="kenjo-font-color-light-text-757575 kenjo-font-size-14p kenjo-mb-30px">{{ dialogTranslation.generatePDFSubtitle }}</div>

  <!-- Empty shifts error -->
  <orgos-column-container
    *ngIf="showEmtpyShiftsError"
    class="kenjo-p-10px gpd-error"
    [class]="areFiltersApplied ? 'kenjo-mb-10px' : 'kenjo-mb-20px'"
    centerColumns="true"
  >
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-danger-ff5757">error_outline</kenjo-icon>
    <div class="kenjo-ml-10px" orgosColumn>{{ dialogTranslation.noShiftsToPrint }}</div>
  </orgos-column-container>

  <!-- Filters -->
  <orgos-column-container *ngIf="areFiltersApplied" class="kenjo-mb-20px kenjo-p-10px gpd-error-area" [centerColumns]="true">
    <orgos-column size="0">
      <kenjo-icon [size]="18" class="kenjo-font-color-warning-ffc107">filter_list</kenjo-icon>
    </orgos-column>
    <div class="kenjo-ml-10px" orgosColumn>{{ dialogTranslation.filtersSelected }}</div>
  </orgos-column-container>

  <!-- Frequency - Date -->
  <orgos-column-container class="kenjo-mb-20px" [centerColumns]="true">
    <orgos-input-simple-select
      orgosColumn
      class="kenjo-pr-10px"
      [value]="selectedValues.frequency"
      [required]="true"
      [label]="dialogTranslation.frequency"
      [options]="frequencyOptions"
      (valueChange)="onFrequencyChange($event)"
    ></orgos-input-simple-select>

    <!-- Date picker -->
    <orgos-column class="kenjo-ml-10px">
      <kenjo-range-datepicker
        *ngIf="selectedValues.frequency === views.WEEK"
        [startAt]="selectedValues.range.from"
        [value]="selectedValues.range"
        [label]="dialogTranslation.currentWeek"
        (newDate)="setRange($event);"
      ></kenjo-range-datepicker>
      <div *ngIf="selectedValues.frequency === views.MONTH">
        <kenjo-input-month-picker
          class="gpd-monthly-picker"
          [label]="dialogTranslation.month"
          [preferenceDate]="selectedValues.range.from"
          (selectedDate)="setRange($event)"
        ></kenjo-input-month-picker>
      </div>
    </orgos-column>
  </orgos-column-container>

  <!--  View - Orientation -->
  <orgos-column-container class="kenjo-mb-20px">
    <orgos-input-simple-select
      orgosColumn
      class="kenjo-mr-10px"
      [required]="true"
      [value]="selectedValues.layout"
      [label]="dialogTranslation.layoutView"
      [options]="layoutOptions"
      (valueChange)="selectedValues.layout = $event; checkPrintPDFoptions()"
    ></orgos-input-simple-select>
    <orgos-input-simple-select
      orgosColumn
      class="kenjo-ml-10px"
      [required]="true"
      [readOnly]="selectedValues.frequency === views.MONTH"
      [label]="dialogTranslation.orientationLabel"
      [value]="selectedValues.orientation"
      [options]="orientationOptions"
      (valueChange)="selectedValues.orientation = $event; checkPrintPDFoptions()"
    ></orgos-input-simple-select>
  </orgos-column-container>

  <!-- Include working time -->
  <orgos-column-container class="kenjo-mb-20px" [centerColumns]="true">
    <orgos-column>
      <mat-checkbox [(ngModel)]="defaultShiftOptions.includeWorkingTime">{{ dialogTranslation.includeWorkingTime }}</mat-checkbox>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mb-40px" [centerColumns]="true">
    <orgos-column>
      <mat-checkbox [(ngModel)]="selectedValues.space">{{ dialogTranslation.addSpace }}</mat-checkbox>
    </orgos-column>
  </orgos-column-container>

  <!-- Shifts status-type -->
  <orgos-column-container>
    <orgos-column class="gpd-flex">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-pb-20px">{{ dialogTranslation.shiftType }}</div>
      <mat-radio-group [(ngModel)]="defaultShiftOptions.type">
        <div *ngFor="let type of shiftOptions.type" class="kenjo-mb-20px">
          <span
            [class.kenjo-cursor-pointer]="type.value !== defaultShiftOptions.type"
            [matTooltip]="type.value !== defaultShiftOptions.type ? dialogTranslation.comingSoon : ''"
            [matTooltipPosition]="'above'"
          >
            <mat-radio-button [disabled]="type.value !== defaultShiftOptions.type" [value]="type.value">{{ type.name }}</mat-radio-button>
          </span>
        </div>
      </mat-radio-group>
    </orgos-column>
    <orgos-column class="gpd-flex">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-pb-20px">{{ dialogTranslation.shiftStatus }}</div>
      <mat-radio-group [(ngModel)]="defaultShiftOptions.status">
        <div *ngFor="let status of shiftOptions.status" class="kenjo-mb-20px">
          <span
            [class.kenjo-cursor-pointer]="status.value !== defaultShiftOptions.status"
            [matTooltip]="status.value !== defaultShiftOptions.status ? dialogTranslation.comingSoon : ''"
            [matTooltipPosition]="'above'"
          >
            <mat-radio-button [disabled]="status.value !== defaultShiftOptions.status" [value]="status.value"
              >{{ status.name }}</mat-radio-button
            >
          </span>
        </div>
      </mat-radio-group>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container>
    <orgos-column class="gpd-flex">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-pb-20px">{{ dialogTranslation.addSpace }}</div>
      <mat-radio-group [(ngModel)]="selectedValues.color">
        <div class="kenjo-mb-20px">
          <mat-radio-button [value]="false">{{ dialogTranslation.lowInk }}</mat-radio-button>
        </div>
        <div class="kenjo-mb-20px">
          <mat-radio-button [value]="true">{{ dialogTranslation.fullInk }}</mat-radio-button>
        </div>
      </mat-radio-group>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="gpd-flex-1">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" class="gpd-align-self">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{ dialogTranslation.goBack }}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px gpd-align-self">
      <orgos-button-raised (click)="closeDialog(true)" color="Success" [disabled]="!isReady || checkingShifts"
        >{{ dialogTranslation.generatePDF }}</orgos-button-raised
      >
    </orgos-column>
  </orgos-column-container>
</orgos-container>
