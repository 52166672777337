import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import * as check from 'check-types';
import * as _ from 'lodash';

import { InputValidation } from '../../../../../core/validation/input-validation';
import { InternationalizationService } from '../../../../../services/core/internationalization.service';
import { IMeetingTemplateModel, MeetingTemplateService } from '../../../../../services/performance-management/meeting-template.service';
import { IMeetingModel, MeetingService } from '../../../../../services/performance-management/meeting.service';

@Component({
  selector: 'orgos-create-template-dialog',
  templateUrl: 'create-template.dialog.html',
  styleUrls: ['create-template.dialog.scss'],
})
export class CreateTemplateDialog implements OnInit {
  isSaving: boolean = false;

  translation: any = {};
  nameValidation: InputValidation;
  templateName: string;
  template: IMeetingTemplateModel = {
    name: '',
    agenda: [],
  };
  meeting: IMeetingModel;
  templates: any = {};
  constructor(
    public dialogRef: MatLegacyDialogRef<CreateTemplateDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    if (check.not.assigned(this.data.meeting)) {
      // is edit or new Meeting?
      return;
    }

    this.meeting = this.data.meeting;
    this.template.agenda = this.data.meeting.agenda;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('meetings-misc')
      .then((dialogTranslation) => {
        this.translation = dialogTranslation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(MeetingTemplateService)
      .getAllTemplates()
      .then((resultTemplates) => {
        this.templates = _.keyBy(resultTemplates, 'name');
      })
      .catch(() => {
        this.templates = {};
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveView(): void {
    if (!this.isDataValid() || this.isSaving === true) {
      return;
    }
    let callToSave;
    let templateId;
    this.isSaving = true;
    if (check.assigned(this.templates) && check.assigned(this.templates[this.template.name])) {
      templateId = this.templates[this.template.name]._id;
      callToSave = this.injector.get(MeetingTemplateService).updateById(templateId, this.template);
    } else {
      callToSave = this.injector.get(MeetingTemplateService).create(this.template);
    }
    callToSave
      .then((result) => {
        if (check.not.assigned(templateId) || check.emptyString(templateId)) {
          templateId = result._id;
          this.injector.get(PrivateChurnzeroService).logSimpleEvent('MEETING_TEMPLATE_CREATED');
        }
        let meetingTemplates: Array<string> = [];
        if (check.assigned(this.meeting.templateId)) {
          meetingTemplates = [];
        }
        meetingTemplates.push(templateId);
        const meetingToUpdate = {
          templateId: meetingTemplates,
        };
        return this.injector.get(MeetingService).updateById(this.meeting._id, meetingToUpdate);
      })
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(() => {
        this.isSaving = false;
      });
  }

  private isDataValid(): boolean {
    return check.assigned(this.nameValidation) && !this.nameValidation.hasErrors();
  }
}
