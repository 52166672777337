import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import {
  IToDo,
  PerformanceReviewToDosController,
} from '@app/cloud-features/performance-review/controllers/performance-review-to-dos.controller';
import { NominatePeersDialog } from '@app/cloud-features/performance-review/dialogs/nominate-peers/nominate-peers-dialog';
import { sortToDosList } from '@app/cloud-features/performance-review/helpers/performance-review-personal.helper';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { IUserWorkModel } from '@app/models/user-work.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UrlChangeService } from '@app/standard/services/core/url-changes.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Component({
  selector: 'kenjo-performance-review-to-dos',
  templateUrl: 'performance-review-to-dos.component.html',
  styleUrls: ['performance-review-to-dos.component.scss'],
})
export class PerformanceReviewToDosComponent implements OnInit {
  loading: boolean = false;
  SOURCE_URL_PARAM: number = 3;
  SOURCE = { PERSONAL: 3, PEOPLE: 0 };
  toDosList = new Array<IToDo>();
  sortingOptions = {
    SORT_ORDER: {
      asc: { icon: 'sort_ascending', value: 'asc', tooltip: '' },
      desc: { icon: ' sort_descending', value: 'desc', tooltip: '' },
    },
  };
  sortIcon: string;
  sortTooltip: string;
  pageTranslations: Record<string, string>;

  @Input() userPersonal: IUserPersonalModel;
  @Input() userWork: IUserWorkModel;

  constructor(private injector: Injector, private router: Router) {}

  ngOnInit() {
    this.initData();
  }

  async initData() {
    try {
      this.loading = true;
      const [translations] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('people-detail-performance-review-page'),
        this.fetchToDosList(),
      ]);

      this.pageTranslations = translations;
      this.loading = false;

      this.setTooltips();
      this.setUrlProfileParam();
    } catch {
      this.pageTranslations = {};
    }
  }

  setUrlProfileParam() {
    const currentURL = this.injector.get(UrlChangeService).getCurrentUrl();
    this.SOURCE_URL_PARAM = currentURL.includes('personal') ? this.SOURCE.PERSONAL : this.SOURCE.PEOPLE;
  }

  setTooltips() {
    this.sortingOptions.SORT_ORDER.asc.tooltip = this.pageTranslations.oldestOnTopTooltip;
    this.sortingOptions.SORT_ORDER.desc.tooltip = this.pageTranslations.newestOnTopTooltip;
    this.sortIcon = this.sortingOptions.SORT_ORDER.asc.icon;
    this.sortTooltip = this.sortingOptions.SORT_ORDER.asc.tooltip;
  }

  onNominatePeersClick(toDo: IToDo) {
    const userId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    const url = `/cloud/people/${userId}/performance/${toDo.performanceReviewId}/nominate`;
    this.router.navigateByUrl(url);
  }

  onViewNominationsClick(toDo: IToDo) {
    const data = {
      nominationUser: {
        photoUrl: this.userPersonal?.photoUrl,
        displayName: this.userPersonal?.displayName,
        _id: this.userPersonal?._id,
        jobTitle: this.userWork?.jobTitle,
      },
      performanceReviewId: toDo.performanceReviewId,
      nominations: undefined,
      readMode: true,
    };
    this.injector.get(MatLegacyDialog).open(NominatePeersDialog, { data });
  }

  onViewNominationsSubordinatesClick(toDo: IToDo) {
    const userId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    const url = `/cloud/people/${userId}/performance/${toDo.performanceReviewId}/nominate-team`;
    this.router.navigateByUrl(url);
  }

  onNominateSubordinatesClick(toDo: IToDo) {
    const userId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    const url = `/cloud/people/${userId}/performance/${toDo.performanceReviewId}/nominate-team`;
    this.router.navigateByUrl(url);
  }

  onWriteReviewClick(toDo: IToDo) {
    this.navigateToWriteForm(toDo);
  }

  onReadReviewClick(toDo: IToDo) {
    if (toDo.feedbacks === undefined || toDo.feedbacks.length === 0) {
      return;
    }
    const feedbackId = toDo.feedbacks[0]?._id;

    if (feedbackId === undefined) {
      return;
    }

    this.navigateToReadForm(toDo);
  }

  navigateToReadForm(toDo: IToDo) {
    if (toDo.feedbacks === undefined || toDo.feedbacks[0] === undefined) {
      return;
    }

    let redirectionFeedback;
    if (toDo.shareFeedbackImmediately === true) {
      const firstSharedFeedback = toDo.feedbacks.find((iFeedback) => {
        return iFeedback.shared === true || iFeedback.status === picklistConstants.PERFORMANCE_REVIEW_RESPONSE_STATUS_SIGNED;
      });
      if (firstSharedFeedback) {
        redirectionFeedback = firstSharedFeedback;
      }
    } else {
      const firstSignedFeedback = toDo.feedbacks.find((iFeedback) => {
        return iFeedback.status === picklistConstants.PERFORMANCE_REVIEW_RESPONSE_STATUS_SIGNED;
      });
      if (firstSignedFeedback) {
        redirectionFeedback = firstSignedFeedback;
      }
    }

    if (redirectionFeedback) {
      const url = `cloud/performance-review/form/${this.userPersonal._id}/${redirectionFeedback._id}/${this.SOURCE_URL_PARAM}`;
      this.router.navigateByUrl(url);
    }
  }

  navigateToWriteForm(toDo: IToDo) {
    if (toDo.feedbacks === undefined || toDo.feedbacks[0] === undefined) {
      return;
    }

    let redirectionFeedback;
    const firstNotCompletedFeedback = toDo.feedbacks.find((iFeedback) => {
      return iFeedback.shared !== true && iFeedback.signed !== true;
    });

    if (firstNotCompletedFeedback) {
      redirectionFeedback = firstNotCompletedFeedback;
    } else {
      redirectionFeedback = toDo.feedbacks[0];
    }

    if (redirectionFeedback) {
      const url = `cloud/performance-review/form/${this.userPersonal._id}/${redirectionFeedback._id}/${this.SOURCE_URL_PARAM}`;
      this.router.navigateByUrl(url);
    }
  }

  changeToDosSortOrder() {
    const sortOrder = this.sortIcon === this.sortingOptions.SORT_ORDER.desc.icon ? 'asc' : 'desc';
    const sortOrderOption = this.sortingOptions.SORT_ORDER[sortOrder];
    this.sortIcon = sortOrderOption.icon;
    this.sortTooltip = sortOrderOption.tooltip;
    this.toDosList = sortToDosList(this.toDosList, sortOrder);

    this.injector.get(PrivateAmplitudeService).logEvent('clicks sort icon (PR To-Dos)', {
      category: 'Performance',
      subcategory1: 'Navigation',
      subcategory2: 'Access To-Dos/Activity',
    });
  }

  async fetchToDosList() {
    try {
      const toDosList = await this.injector.get(PerformanceReviewToDosController).getToDosList();
      this.toDosList = sortToDosList(toDosList, this.sortingOptions.SORT_ORDER.asc.value);
    } catch {
      this.toDosList = [];
    }
  }
}
