import { Component, ElementRef, Input } from '@angular/core';


@Component({
  selector: 'kenjo-scroll-to-top',
  templateUrl: 'scroll-to-top.component.html',
  styleUrls: ['scroll-to-top.component.scss']
})
export class ScrollToTopComponent {

  @Input() container: ElementRef;

  scroll() {
    this.container.nativeElement.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }
}