import { Location } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ComposeEmailDialog } from '@app/cloud-features/recruiting/compose-email-dialog/compose-email-dialog';
import { RecruitingScoreCandidateDialog } from '@app/cloud-features/recruiting/recruiting-score-candidate-dialog/recruiting-score-candidate-dialog.page';
import { PermissionsPipe } from '@app/common-components/permissions-pipe/components/permissions-pipe.pipe';
import { CandidateRejectionReasonDialog } from '@app/common-dialogs/recruiting/candidate-rejection-reason/candidate-rejection-reason.dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { AddEmployeeDialog } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/add-employee.dialog';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CandidateService } from '@app/standard/services/recruiting/candidate.service';
import { PositionCandidateService } from '@app/standard/services/recruiting/position-candidate.service';
import { RecruitingPipelineService } from '@app/standard/services/recruiting/recruiting-pipeline.service';
import { RecruitingRejectionReasonService } from '@app/standard/services/recruiting/recruiting-rejection-reason.service';
import { RecruitingTalentPoolService } from '@app/standard/services/recruiting/recruiting-talent-pool.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-recruiting-candidate-header',
  templateUrl: 'recruiting-candidate-header.component.html',
  styleUrls: ['recruiting-candidate-header.component.scss'],
})
export class RecruitingCandidateHeaderComponent implements OnInit {
  _positionCandidate: GenericCacheModel;
  get positionCandidate() {
    return this._positionCandidate;
  }
  @Input() set positionCandidate(value: GenericCacheModel) {
    this._positionCandidate = value;
    this.computeActionMenuVisibility();
    this.initPipelineStages();
  }
  @Input() position: GenericCacheModel;
  @Input() scoreResults: any;
  @Input() reviewDisabled: boolean = true;
  @Input() candidate: GenericCacheModel;
  @Input() listTabs: Array<string> = [];
  @Input() selectedTab: number = 0;
  @Input() mapStageIdToStage: any = {};
  @Input() showFullHeader: boolean = true;
  @Input() availableActions: any = {};
  @Input() isAgency: boolean = false;
  @Input() canRemoveHiredStatus: boolean = false;
  @Output() positionCandidateDeleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() tabClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() updateScore: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickReviewCandidate: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickInActionButton: EventEmitter<void> = new EventEmitter<void>();
  @Output() qualificationChanged: EventEmitter<void> = new EventEmitter<void>();

  USER_COLORS: any = {};
  pageTranslation: any = {};
  miscTranslation: any = {};
  scorecardTranslation: any = {};
  stageAvailable: boolean = true;
  rejectionReasons: Array<string>;
  currentUser: any;
  addedToFavorite: boolean = false;
  canAnonymizeCandidate: boolean = false;
  recruitingPipelineStages: Array<any>;
  showHiredStatus: boolean;

  actionsMenuVisibility = {
    showConvertToEmployee: false,
    showDisqualify: false,
    showRejectAndSendEmail: false,
    showDeleteFromPosition: false,
    showDeletePermanently: false,
    showAnonymize: false,
  };

  constructor(private injector: Injector, private router: Router) {
    this.USER_COLORS = Object.keys(userColorConstants).reduce((result, iColorKey) => {
      result[iColorKey] = userColorConstants[iColorKey];
      return result;
    }, {});
  }

  ngOnInit(): void {
    this.currentUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-candidate-header-component')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-scorecards-summary-page')
      .then((pageTranslation) => {
        this.scorecardTranslation = pageTranslation;
      })
      .catch(() => {
        this.scorecardTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.getCanBeAnonymized();
    this.showHiredStatus = this.showHiredLabel();

    if (this.showFullHeader === true && (this.currentUser.profileKey === 'admin' || this.currentUser.profileKey === 'recruiter')) {
      this.injector
        .get(RecruitingTalentPoolService)
        .find({ favoriteForUserId: this.currentUser._id })
        .then((favoriteTalentPools) => {
          if (
            check.assigned(favoriteTalentPools) &&
            check.nonEmptyArray(favoriteTalentPools) &&
            check.assigned(favoriteTalentPools[0].listCandidates) &&
            check.nonEmptyArray(favoriteTalentPools[0].listCandidates)
          ) {
            const talentPoolCandidates = favoriteTalentPools[0].listCandidates;
            const favoriteTalentPool = talentPoolCandidates.find((iCandidate) => {
              return iCandidate._candidateId === this.candidate.data._id;
            });
            this.addedToFavorite = check.assigned(favoriteTalentPool) && check.nonEmptyObject(favoriteTalentPool) ? true : false;
          }
        })
        .catch(() => {});
    }

    if (
      check.not.assigned(this.position) ||
      check.not.assigned(this.position.data) ||
      check.not.assigned(this.positionCandidate) ||
      check.not.assigned(this.positionCandidate.data)
    ) {
      return;
    }

    this.initPipelineStages();
    this.rejectionReasons = [];
    if (this.showFullHeader === true) {
      this.injector
        .get(RecruitingRejectionReasonService)
        .find({ _id: { $ne: null } })
        .then((recrutingRejectionReasons) => {
          this.rejectionReasons = Object.values(recrutingRejectionReasons).map((iRecruitingReason) => {
            return iRecruitingReason.name;
          });
          this.rejectionReasons.sort();
        })
        .catch(() => {
          this.rejectionReasons = [];
        });
    }
  }

  public reviewCandidate(): void {
    this.clickInActionButton.emit();
    if (this.reviewDisabled === true) {
      return;
    }
    this.clickReviewCandidate.emit();
  }

  public updateStage(newStageId: string): void {
    this.clickInActionButton.emit();
    if (newStageId === this.positionCandidate.data.stageId) {
      return;
    }

    const data = {
      oldStatus: this.mapStageIdToStage[this.positionCandidate.data.stageId]
        ? this.mapStageIdToStage[this.positionCandidate.data.stageId].name
        : this.positionCandidate.data.stageId,
      newStatus: this.mapStageIdToStage[newStageId].name,
    };
    this.injector
      .get(PositionCandidateService)
      .updateById(this.positionCandidate.data._id, { stageId: newStageId })
      .then(() => {
        this.injector
          .get(MatLegacySnackBar)
          .open(`${this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmStatusUpdateSnackbar, data)}`, 'OK', {
            duration: 5000,
          });
        const result = {
          _oldVal: this.positionCandidate.data.stageId,
          _newVal: newStageId,
          _field: 'stageId',
        };
        result[fieldConstants.UPDATED_AT] = new Date();
        result[fieldConstants.UPDATED_BY] = this.injector.get(AuthenticationService).getLoggedUser()._id;
        this.positionCandidate.data.stageId = newStageId;
      })
      .catch((error) => {});
  }

  public disqualify(): void {
    this.clickInActionButton.emit();
    const candidateName = `${this.candidate.data.firstName} ${this.candidate.data.lastName}`;
    const translationData = {
      candidateName: candidateName,
      positionName: this.position.data.jobTitle,
    };

    let dialogRef;
    if (check.nonEmptyArray(this.rejectionReasons)) {
      dialogRef = this.injector.get(MatLegacyDialog).open(CandidateRejectionReasonDialog, { data: translationData });
    } else {
      const dialogData = {
        titleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmDisqualifyTitle, translationData),
        subtitleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmDisqualifySubtitle, translationData),
        confirmButtonText: this.pageTranslation.confirmDisqualifyButton,
        confirmButtonColor: 'Danger',
        cancelButtonText: this.miscTranslation.goBackButtonDialog,
      };

      dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    }
    dialogRef.afterClosed().subscribe((value) => {
      if (check.assigned(value) && value !== false) {
        const rejectReasons = value === true ? '' : value;
        this.updatePositionCandidate(rejectReasons, false, translationData);
      }
    });
  }

  public requalify(): void {
    this.clickInActionButton.emit();
    const candidateName = `${this.candidate.data.firstName} ${this.candidate.data.lastName}`;
    const translationData = {
      candidateName: candidateName,
      positionName: this.position.data.jobTitle,
    };
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmRequalifyTitle, translationData),
      subtitleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmRequalifySubtitle, translationData),
      confirmButtonText: this.pageTranslation.confirmRequalifyButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector
          .get(PositionCandidateService)
          .updateById(this.positionCandidate.data._id, { isDisqualified: false })
          .then(() => {
            this.positionCandidate.data.isDisqualified = false;
            this.getCanBeAnonymized();
            this.qualificationChanged.emit();
            this.injector
              .get(MatLegacySnackBar)
              .open(`${this.injector.get(I18nDataPipe).transform(this.pageTranslation.snackbarRequalified, translationData)}`, 'OK', {
                duration: 5000,
              });
          })
          .catch(() => {});
      }
    });
  }

  public convertToEmployee(): void {
    this.clickInActionButton.emit();
    const data = {
      firstName: this.candidate.data.firstName,
      lastName: this.candidate.data.lastName,
      candidateId: this.candidate.data._id,
      positionCandidateId: this.positionCandidate.data._id,
      jobTitle: this.position.data.jobTitle,
      companyId: this.position.data.companyId,
      departmentId: this.position.data.departmentId,
      officeId: this.position.data.officeId,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(AddEmployeeDialog, { data });

    dialogRef.afterClosed().subscribe((newUserId) => {
      if (check.assigned(newUserId) && newUserId !== false && check.nonEmptyString(newUserId)) {
        const candidateName = `${this.candidate.data.firstName} ${this.candidate.data.lastName}`;
        const translationData = {
          candidateName: candidateName,
        };
        this.candidate.data.convertedToUserId = newUserId;
        this.positionCandidate.data.hiredDate = new Date();
        this.getCanBeAnonymized();
        this.injector
          .get(MatLegacySnackBar)
          .open(`${this.injector.get(I18nDataPipe).transform(this.pageTranslation.snackbarConfirmConverted, translationData)}`, 'OK', {
            duration: 5000,
          });
        this.router.navigateByUrl(`cloud/people/${newUserId}/personal`);
      }
    });
  }

  public rejectAndSendEmail(): void {
    this.clickInActionButton.emit();
    const data = {
      titleText: this.pageTranslation.confirmRejectTitle,
      confirmButtonText: this.pageTranslation.confirmRejectButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    let dialogRef;
    if (check.nonEmptyArray(this.rejectionReasons)) {
      dialogRef = this.injector.get(MatLegacyDialog).open(CandidateRejectionReasonDialog, { data });
    } else {
      dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    }
    dialogRef.afterClosed().subscribe((value) => {
      if (check.assigned(value) && value !== false) {
        const rejectionReason = value === true ? '' : value;
        this.updatePositionCandidate(rejectionReason, true);
      }
    });
  }

  private async updatePositionCandidate(rejectionReason: string, sendEmail: boolean, translationData?: object) {
    try {
      await this.injector
        .get(PositionCandidateService)
        .updateById(this.positionCandidate.data._id, { isDisqualified: true, rejectionReason });
      this.positionCandidate.data.isDisqualified = true;
      this.positionCandidate.data.rejectionReason = rejectionReason;

      if (check.assigned(this.candidate.data.convertedToUserId) && this.canRemoveHiredStatus === true) {
        await this.injector.get(CandidateService).updateById(this.candidate.data._id, { convertedToUserId: null });
        this.candidate.data.convertedToUserId = undefined;
      }
    } catch {
      return; // Error already handled
    }

    this.getCanBeAnonymized();
    this.qualificationChanged.emit();
    let successMessage;
    if (sendEmail) {
      this.sendEmail();
      successMessage = this.injector.get(I18nDataPipe).transform(this.pageTranslation.rejectSnackbar);
    } else {
      successMessage = this.injector.get(I18nDataPipe).transform(this.pageTranslation.snackbarDisqualified, translationData);
    }
    this.injector.get(MatLegacySnackBar).open(successMessage, 'OK', { duration: 5000 });
  }

  public sendEmail(): void {
    const data = {
      data: {
        candidate: this.candidate.data,
        position: this.position.data,
        positionCandidate: this.positionCandidate.data,
      },
      panelClass: 'kenjo-full-screen-dialog',
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ComposeEmailDialog, data);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (check.assigned(dialogResult) && check.nonEmptyObject(dialogResult)) {
        // email has been sent
      }
    });
  }

  public deleteFromPosition(): void {
    this.clickInActionButton.emit();
    const candidateName = `${this.candidate.data.firstName} ${this.candidate.data.lastName}`;
    const translationData = {
      candidateName: candidateName,
      positionName: this.position.data.jobTitle,
    };
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmDeleteTitle, translationData),
      subtitleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmDeleteSubtitle, translationData),
      confirmButtonText: this.pageTranslation.confirmDeleteButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector
          .get(PositionCandidateService)
          .deleteById(this.positionCandidate.data._id)
          .then(() => {
            this.positionCandidateDeleted.emit();
            this.getCanBeAnonymized();
            this.injector
              .get(MatLegacySnackBar)
              .open(`${this.injector.get(I18nDataPipe).transform(this.pageTranslation.snackbarConfirmDelete, translationData)}`, 'OK', {
                duration: 5000,
              });
            this.injector.get(Location).back();
          })
          .catch(() => {});
      }
    });
  }

  public deletePermanently(): void {
    this.clickInActionButton.emit();
    const data = {
      titleText: this.pageTranslation.deleteTitle,
      subtitleText: this.pageTranslation.deleteSubtitle,
      confirmButtonText: this.pageTranslation.deletePermanently,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector
          .get(CandidateService)
          .deleteById(this.positionCandidate.data._candidateId)
          .then(() => {
            this.injector.get(Location).back();
            this.injector.get(MatLegacySnackBar).open(this.pageTranslation.deletedSuccessfully, 'OK', { duration: 5000 });
          })
          .catch(() => {});
      }
    });
  }

  public anonymize() {
    this.clickInActionButton.emit();
    const data = {
      titleText: this.pageTranslation.confirmAnonymizeTitle,
      subtitleText: this.pageTranslation.confirmAnonymizeSubtitle,
      confirmButtonText: this.pageTranslation.anonymizeButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm === true) {
        this.injector.get(GlobalBarService).setProgressBar(true);
        this.injector
          .get(CandidateService)
          .softDeleteCandidates([this.candidate.data._id])
          .catch(() => {
            // Do nothing
          })
          .finally(() => {
            this.injector.get(GlobalBarService).setProgressBar(false);
            this.injector.get(Location).back();
            this.injector.get(MatLegacySnackBar).open(this.pageTranslation.anonymizedSuccessfully, 'OK', { duration: 5000 });
          });
      }
    });
  }

  private async getCanBeAnonymized() {
    try {
      if (this.isAgency) {
        this.canAnonymizeCandidate = false;
        return;
      }
      const result = await this.injector.get(CandidateService).candidatesCanBeAnonymized([this.positionCandidate.data._candidateId]);
      if (check.nonEmptyObject(result)) {
        const canAnonymizePosition: boolean | undefined = result[this.positionCandidate.data._candidateId];
        this.canAnonymizeCandidate = !!canAnonymizePosition;
      }
    } catch (e) {
      this.canAnonymizeCandidate = false;
    } finally {
      this.computeActionMenuVisibility();
    }
  }

  private computeActionMenuVisibility() {
    this.actionsMenuVisibility = {
      showConvertToEmployee:
        (this.availableActions.canHireCandidate === true ||
          this.injector.get(PermissionsPipe).transform('recruiting-options.c_convertEmployees') === true) &&
        this.positionCandidate?.data?.isDisqualified === false &&
        check.not.assigned(this.candidate?.data?.convertedToUserId),
      showDisqualify: this.availableActions.canRejectCandidate === true && this.positionCandidate?.data?.isDisqualified === false,
      showRejectAndSendEmail:
        this.availableActions.canRejectCandidate === true &&
        this.availableActions.canEmailCandidate === true &&
        this.positionCandidate?.data?.isDisqualified === false,
      showDeleteFromPosition: this.availableActions.canUnassignCandidate === true,
      showDeletePermanently: this.availableActions.canRemoveCandidate === true,
      showAnonymize: this.canAnonymizeCandidate === true && this.availableActions.canRemoveCandidate === true,
    };
  }

  public scoreCandidate(): void {
    this.clickInActionButton.emit();
    const data = {
      data: {
        position: this.position,
        positionCandidate: this.positionCandidate,
        candidate: this.candidate,
      },
      panelClass: 'kenjo-full-screen-dialog',
    };

    this.injector.get(PrivateAmplitudeService).logEvent('open Score Card', {
      platform: 'Web',
      category: 'Recruiting',
      subcategory1: 'Job openings',
      subcategory2: 'Candidate Overview',
    });
    const dialogRef = this.injector.get(MatLegacyDialog).open(RecruitingScoreCandidateDialog, data);
    dialogRef.afterClosed().subscribe((newScore) => {
      if (check.assigned(newScore) && check.nonEmptyObject(newScore) && newScore !== false) {
        this.injector.get(PrivateAmplitudeService).logEvent('save Score Card', {
          platform: 'Web',
          category: 'Recruiting',
          subcategory1: 'Job openings',
          subcategory2: 'Candidate Overview',
        });
        this.updateScore.emit(newScore);
      }
    });
  }

  public clickTab(tabNumber: number): void {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view candidate profile', { category: 'Recruiting', type: `${this.listTabs[tabNumber]}`, source: 'candidates list' });
    this.tabClicked.emit(this.listTabs[tabNumber]);
  }

  private async initPipelineStages() {
    try {
      const pipeline = await this.injector.get(RecruitingPipelineService).getById(this.position.data.recruitingPipelineId);
      this.stageAvailable = this.checkStageAvailability(pipeline, this.positionCandidate.data.stageId);
      this.recruitingPipelineStages = pipeline.stageIds?.map((iStageId) => this.mapStageIdToStage[iStageId]);
    } catch {
      this.stageAvailable = false;
    }
  }

  private checkStageAvailability(pipeline: object, stageId: string): boolean {
    if (check.nonEmptyObject(pipeline) && check.nonEmptyArray(pipeline['stageIds']) && check.assigned(stageId)) {
      const stage = pipeline['stageIds'].find((iStage) => {
        return stageId === iStage;
      });
      return check.assigned(stage) ? true : false;
    }
    return false;
  }

  public updateFavoriteTalentPool(): void {
    if (this.addedToFavorite === false) {
      this.injector
        .get(RecruitingTalentPoolService)
        .addFavorite(this.candidate.data._id)
        .then(() => {
          this.addedToFavorite = true;
        })
        .catch(() => {});
    } else {
      this.injector
        .get(RecruitingTalentPoolService)
        .removeFavorite(this.candidate.data._id)
        .then(() => {
          this.addedToFavorite = false;
        })
        .catch(() => {});
    }
  }

  public navigateToPosition(positionId: string): void {
    this.injector.get(Router).navigate([`/cloud/recruiting/position/${positionId}`]);
  }

  private showHiredLabel(): boolean {
    const isCandidateConverted = check.assigned(this.candidate.data.convertedToUserId);
    if (check.not.assigned(this.position) || check.not.assigned(this.positionCandidate)) {
      return false;
    }

    const sameCandidateId = this.positionCandidate.data._candidateId === this.candidate.data._id;
    const samePositionId = this.positionCandidate.data._positionId === this.position.data._id;

    return isCandidateConverted && sameCandidateId && samePositionId;
  }
}
