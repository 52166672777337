import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appResizable]',
})
export class ResizableDirective {
  @Input() resizableGrabHeight = 18;
  @Input() resizableMinHeight = 15;

  dragging = false;

  constructor(private el: ElementRef) {
    const preventGlobalMouseEvents = () => {
      document.body.style['-webkit-user-select'] = 'none';
      document.body.style['-ms-user-select'] = 'none';
      document.body.style['user-select'] = 'none';
    };

    const restoreGlobalMouseEvents = () => {
      document.body.style['pointer-events'] = 'auto';
    };

    const setNewHeight = (height) => {
      const maxHeight =
        Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 108;
      height = height > maxHeight ? maxHeight : height;
      const newHeight = Math.max(this.resizableMinHeight, height);
      el.nativeElement.style.height = newHeight + 'px';
    };

    const mouseMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      setNewHeight(evt.clientY - el.nativeElement.offsetTop);
      evt.stopPropagation();
    };

    const mouseUpG = (evt) => {
      if (!this.dragging) {
        return;
      }
      restoreGlobalMouseEvents();
      this.dragging = false;
      evt.stopPropagation();
    };

    const mouseDown = (evt) => {
      if (this.inDragRegion(evt)) {
        this.dragging = true;
        preventGlobalMouseEvents();
        evt.stopPropagation();
      }
    };

    const mouseMove = (evt) => {
      if (this.inDragRegion(evt) || this.dragging) {
        el.nativeElement.style.cursor = 'row-resize';
      } else {
        el.nativeElement.style.cursor = 'default';
      }
    };

    document.addEventListener('mousemove', mouseMoveG, true);
    document.addEventListener('mouseup', mouseUpG, true);
    el.nativeElement.addEventListener('mousedown', mouseDown, true);
    el.nativeElement.addEventListener('mousemove', mouseMove, true);
  }

  inDragRegion(evt) {
    return (
      this.el.nativeElement.clientHeight - evt.clientY + this.el.nativeElement.offsetTop <
      this.resizableGrabHeight
    );
  }
}
