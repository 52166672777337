import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as check from 'check-types';
import { Observable } from 'rxjs';
import { map, startWith, takeWhile } from 'rxjs/operators';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';

@Component({
  selector: 'orgos-input-simple-text-autocomplete',
  templateUrl: 'input-simple-text-autocomplete.component.html',
  styleUrls: ['input-simple-text-autocomplete.component.scss']
})
export class InputSimpleTextAutocompleteComponent implements OnInit, OnDestroy {
  private keepSubscriptions: boolean = true;

  isValueValid: boolean = true;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Input() suggestedOptions: Array<string> = [];

  filteredOptions: Observable<Array<string>>;
  formController = new UntypedFormControl();

  ngOnInit(): void {
    this.filteredOptions = this.formController.valueChanges.pipe(
      takeWhile(() => this.keepSubscriptions),
      startWith<string>(''),
      map((value) => this._filter(value))
    );

    this.formController.valueChanges.pipe(takeWhile(() => this.keepSubscriptions)).subscribe((newValue: string) => {
      this.onValueChange(newValue);
    });

    this.formController.setValue(this.value);
  }

  private _filter(value: string): Array<string> {
    const filterValue = value.toLowerCase();

    return this.suggestedOptions.filter((option) => option.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this.keepSubscriptions = false;
  }

  onValueChange(newValue: string): void {
    this.value = newValue;
    this.makeValidation(newValue);

    if (this.isValueValid) {
      this.valueChange.emit(newValue);
    }
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (check.assigned(this.customValidation)) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }
}
