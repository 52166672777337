import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DocumentService, IDocumentModel } from '@app/standard/services/document/document.service';
import { IRecruitingDocumentModel, RecruitingDocumentService } from '@app/standard/services/recruiting/recruiting-document.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { IFileMetadata } from '../file/file-metadata.service';

@Injectable({
  providedIn: 'root'
})
export class SignatureRequestService {
  private SIGNATURE_REQUEST_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/signature-request-db`;
  private SIGNATURE_REQUEST_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/smart-docs/digital-signatures-table`;

  constructor(private injector: Injector) {}

  async createSignatureRequest(signatureRequest: ISignatureCreateRequest): Promise<ISignatureRequest> {
    try {
      const signatureResponse = await this.injector.get(HttpClient).post<ISignatureRequest>(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}`, signatureRequest).toPromise();
      return signatureResponse;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, SignatureRequestService.name, 'createSignatureRequest');
    }
  }

  async getEmployeeSignatureRequests(filter: any): Promise<{ records: Array<ISignature>; total: number }> {
    try {
      return await this.injector.get(HttpClient).post<{ records: Array<ISignature>; total: number }>(`${this.SIGNATURE_REQUEST_CONTROLLER_URL}`, filter).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, SignatureRequestService.name, 'getSignatureRequests');
    }
  }

  async getCandidateSignatureRequests(candidateId: string): Promise<Array<ISignature>> {
    try {
      const findQuery = {
        signatureType: 'Candidate',
        'signatures.signerId': candidateId,
        'signatures.status': { $ne: SIGNATURE_STATUS.CANCELED }
      };
      const rawSignatureRequests = await this.injector.get(HttpClient).post<Array<ISignatureRequest>>(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/find`, findQuery).toPromise();
      const { usersMap, documentsMap } = await this.getUsersAndDocumentsMap(rawSignatureRequests, true);
      const signatures = this.fulfillSignaturesWithUserPersonalAndDocument(rawSignatureRequests, usersMap, documentsMap);
      return signatures;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, SignatureRequestService.name, 'getSignatureRequests');
    }
  }

  sendReminders(signatureDocuments: Array<ISignature>) {
    const [...signatureRequestIds] = new Set(signatureDocuments.map((doc) => doc.requestId));
    return new Promise((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .post(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/send-reminders`, { signatureRequestIds })
        .toPromise()
        .then(resolve)
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, SignatureRequestService.name, 'sendSignatureReminders'));
        });
    });
  }

  voidDocuments(signatureDocuments: Array<ISignature>) {
    const [...ids] = new Set(signatureDocuments.map((doc) => doc.requestId));
    return new Promise((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .post(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/cancel`, { ids })
        .toPromise()
        .then(resolve)
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, SignatureRequestService.name, 'cancelSignatureRequests'));
        });
    });
  }

  async getUsersAndDocumentsMap(signatureRequests: Array<ISignatureRequest>, isRecruiting = false) {
    const [userIds, documentIds] = this.getUserAndDocumentIds(signatureRequests);

    const usersQuery = { _id: { $ne: null } };
    const documentsQuery = { $or: [{ _id: { $in: documentIds } }, { _sentToSignature: true }] };
    const userAccountRequest = this.injector.get(UserAccountService).find(usersQuery, true);
    const userPersonalRequest = this.injector.get(UserPersonalService).find(usersQuery, true);

    const documentsRequest: Promise<Array<IDocumentModel> | undefined> = !isRecruiting ? this.injector.get(DocumentService).find(documentsQuery) : Promise.resolve(undefined);
    const recruitingDocumentsRequest: Promise<Array<IRecruitingDocumentModel> | undefined> = isRecruiting ? this.injector.get(RecruitingDocumentService).find(documentsQuery) : Promise.resolve(undefined);
    const [userAccount, userPersonal, documents, recruitingDocuments] = await Promise.all([userAccountRequest, userPersonalRequest, documentsRequest, recruitingDocumentsRequest]);

    const userAccountEmailMap: IUserAccountMap = userAccount.reduce((prev, curr) => ({ ...prev, [curr._id]: curr.email }), {});
    const usersMap: IUsersMap = userPersonal.reduce((prev, curr) => {
      let signer = {};
      if (curr._isArchived) {
        signer = {
          _id: curr._id,
          displayName: 'Archived',
          _photo: undefined,
          email: 'Archived',
          _isArchived: true
        };
      } else {
        signer = {
          _id: curr._id,
          displayName: curr.displayName,
          _photo: curr._photo,
          email: userAccountEmailMap[curr._id],
          _isArchived: false
        };
      }
      return {
        ...prev,
        [curr._id]: signer
      };
    }, {});

    const initialDocMap: IDocumentsMap = {};
    const docs: Array<any> = documents !== undefined ? documents : recruitingDocuments;
    const documentsMap: IDocumentsMap = docs.reduce(
      (prev, curr) => ({
        ...prev,
        [curr._id]: {
          _id: curr._id,
          name: curr.name,
          _file: curr._file,
          relatedTo: curr.relatedTo,
          _createdAt: curr._createdAt,
          _createdById: curr._createdById
        }
      }),
      initialDocMap
    );

    return { usersMap, documentsMap };
  }

  private getUserAndDocumentIds(signatureRequests: Array<ISignatureRequest>) {
    const usersIds = new Set<string>();
    const documentIds = new Set<string>();
    signatureRequests.forEach((req) => {
      usersIds.add(req.authorId);
      documentIds.add(req.signedDocumentId || req.originalDocumentId);
      req.signatures.forEach((s) => {
        usersIds.add(s.signerId);
      });
    });

    return [[...usersIds], [...documentIds]];
  }

  private fulfillSignaturesWithUserPersonalAndDocument(signatureRequests: Array<ISignatureRequest>, usersMap: IUsersMap, documentsMap: IDocumentsMap) {
    const signatures = new Array<ISignature>();
    const documentsIdInList = new Array<string>();
    const canceledIds = new Array<string>();
    signatureRequests.forEach((signatureRequest) => {
      if (signatureRequest.signatures === undefined || signatureRequest.signatures.length === 0) {
        return;
      }

      const documentId = signatureRequest.signedDocumentId || signatureRequest.originalDocumentId;
      let nextSignaturesAreBlocked = false;
      const newSignatures: Array<ISignature> = signatureRequest.signatures
        .filter((signature) => {
          if (signature.status === SIGNATURE_STATUS.CANCELED) {
            canceledIds.push(documentId);
            return false;
          }
          return true;
        })
        .map((signature) => {
          const newSignature: ISignature = {
            _id: signature._id,
            author: usersMap[signatureRequest.authorId],
            signer: usersMap[signature.signerId],
            document: documentsMap[documentId] || { name: signatureRequest.originalDocumentName },
            signerType: signature.signerType,
            status: nextSignaturesAreBlocked ? SIGNATURE_STATUS.PENDING : signature.status,
            requestId: signatureRequest._id,
            _createdAt: signatureRequest._createdAt
          };
          if (signature.status !== 'completed') {
            nextSignaturesAreBlocked = true;
          }
          return newSignature;
        });
      documentsIdInList.push(documentId);
      nextSignaturesAreBlocked = false;
      signatures.push(...newSignatures);
    });

    const importErrorDocs = Object.values(documentsMap).filter((iDocument) => {
      return !documentsIdInList.includes(iDocument._id);
    });

    importErrorDocs.forEach((iImportError) => {
      if (canceledIds.includes(iImportError._id)) {
        return;
      }

      const newSignature: ISignature = {
        _id: iImportError._id,
        author: usersMap[iImportError._createdById],
        signer: usersMap[iImportError.relatedTo.idRelatedTo],
        document: documentsMap[iImportError._id],
        signerType: 'Employee',
        status: SIGNATURE_STATUS.IMPORT_ERROR,
        requestId: iImportError._id,
        _createdAt: iImportError._createdAt
      };
      signatures.push(newSignature);
    });

    return signatures;
  }

  async getSignaturitMetadata(id: string, signerId: string): Promise<string> {
    const signaturitUrl = await this.injector.get(HttpClient).post<{ url: string }>(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/signature-url`, { id, signerId }).toPromise();

    return signaturitUrl.url;
  }

  getSignature(signatureRequestId: string) {
    return new Promise((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .post(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/find`, { signatureRequestId })
        .toPromise()
        .then((signatureResponse) => {
          resolve(signatureResponse);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, SignatureRequestService.name, 'getSignature'));
        });
    });
  }

  getSignatureRequestCount(): Promise<{ total: number; used: number }> {
    return new Promise((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .get(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/signature-request-count`)
        .toPromise()
        .then((signatureCount: { total: number; used: number }) => {
          resolve(signatureCount);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, SignatureRequestService.name, 'getSignatureRequestCount'));
        });
    });
  }

  //Returns all signature request that still pending from signing
  async getPendingSignatureRequest(originalDocumentId: string) {
    const findQuery = {
      originalDocumentId: originalDocumentId
    };

    const pendingSignatureRequests = await this.injector.get(HttpClient).post<Array<ISignatureRequest>>(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/find`, findQuery).toPromise();
    if (check.emptyArray(pendingSignatureRequests)) {
      return [];
    }
    const requestFinalList = [];
    pendingSignatureRequests.forEach((iRequest) => {
      const notFinishedSignatures = iRequest.signatures.find((iSignature) => {
        return iSignature.status !== 'canceled' && iSignature.status !== 'completed';
      });
      if (check.assigned(notFinishedSignatures) && check.not.emptyArray(notFinishedSignatures)) {
        requestFinalList.push(iRequest);
      }
    });
    return requestFinalList;
  }

  async checkIfDocumentIsASignedDoc(originalDocumentId: string) {
    const findQuery = {
      signedDocumentId: originalDocumentId
    };

    const signedDocResult = await this.injector.get(HttpClient).post<Array<ISignatureRequest>>(`${this.SIGNATURE_REQUEST_MICROSERVICE_URL}/find`, findQuery).toPromise();
    return signedDocResult.length > 0;
  }
}

export interface ISignatureCreateRequest {
  recipients: Array<ISignatureRequestRecipient>;
  docOwners: Array<string>;
  documentId: string;
  signatureType?: 'Employee' | 'Candidate';
  signingMode?: string;
  data?: any;
  deliveryType?: string;
  type?: string;
  personalMessage?: any;
}

export interface ISignatureRequestRecipient {
  signerId: string;
  name: string;
  email: string;
  signerType: 'Employee' | 'Candidate';
}

export interface ISignatureOptions {
  signersOption: string; // specific || all
  signingMode: string; // sequential || parallel
  recipients: Array<any>;
  docOwners: Array<any>;
  companies: Array<{ _id: string; name: string }>; // only in all signersOption
  offices: Array<{ _id: string; name: string }>; // only in all signersOption
  departments: Array<{ _id: string; name: string }>; // only in all signersOption
  includeInactiveEmployees: boolean; // only in all signersOption
  personalMessage?: any;
}

export interface ISignatureRequest {
  _id: string;
  ownerId: string;
  signatureRequestId: string;
  url: string;
  authorId: string;
  originalDocumentId: string;
  originalDocumentName?: string;
  signedDocumentId?: string;
  signatureType: 'Employee' | 'Candidate';
  signatures: Array<ISignatureFromServer>;
  _updatedById: string;
  _createdById: string;
  _createdAt: Date;
  _updatedAt: Date;
}

export interface ISignatureFromServer {
  _id: string;
  signerId: string;
  documentId: string;
  signerType: 'Employee' | 'Candidate';
  status: 'ready' | 'canceled' | 'completed';
}

/**
 * Custom model that provides all the necessary information
 * about a Digital Signature (user-personal, document name...)
 */
export interface ISignature {
  excluded?: boolean;
  _id: string;
  requestId: string;
  signer: ISignatureUser;
  author: ISignatureUser;
  document: ISignatureDocument;
  signerType: 'Employee' | 'Candidate';
  status: ISignatureStatus;
  _createdAt: Date;
}

interface ISignatureUser {
  _id: string;
  displayName: string;
  _photo: IFileMetadata;
  email: string;
}

interface ISignatureDocument {
  _id?: string;
  name: string;
  relatedTo?: any;
  _createdAt?: Date;
  _createdById?: string;
  collapsedTags?: Array<any>;
  processedTooltip?: Array<any>;
  _sentToSignature?: boolean;
  tags?: any;
  ownerId?: string;
}

interface IUserAccountMap {
  [key: string]: string;
}

interface IUsersMap {
  [key: string]: ISignatureUser;
}

interface IDocumentsMap {
  [key: string]: ISignatureDocument;
}

export const SIGNATURE_STATUS: { [key: string]: ISignatureStatus } = {
  PENDING: 'pending',
  READY: 'ready',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  DECLINED: 'declined',
  IMPORT_ERROR: 'import-error'
};

export type ISignatureStatus = 'pending' | 'ready' | 'completed' | 'declined' | 'canceled' | 'import-error';

export const SIGNATURE_STATUS_MAP = {
  ready: { translationField: 'statusReady', color: 'Neutral' },
  pending: { translationField: 'statusPending', color: 'Caution' },
  completed: { translationField: 'statusSigned', color: 'Success' },
  declined: { translationField: 'statusDeclined', color: 'Danger' },
  canceled: { translationField: 'statusCanceled', color: 'Danger' },
  'import-error': { translationField: 'statusImportError', color: 'Danger' }
};
