import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as FileSaver from 'file-saver';
import * as legacy from 'legacy-encoding';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DatevExportController {
  private DATEV_EXPORT_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/datev/export`;

  constructor(private injector: Injector) {}

  async getExport(datevSettingsId: string, to: Date, company: string, periodIdentifier: number) {
    try {
      const { zip } = await this.injector.get(HttpClient).post<any>(`${this.DATEV_EXPORT_CONTROLLER_URL}/`, { datevSettingsId, periodIdentifier }).toPromise();
      this.downloadZIP(zip, to, company);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, 'DatevExportController', 'getExport');
    }
  }

  private downloadZIP(document: any, to: Date, company: string) {
    const encoded = legacy.encode(document, 'base64');
    const blob = new Blob([encoded], { type: 'application/zip' });
    FileSaver.saveAs(blob, `DATEV_${company.replace(/\s+/g, '')}_${moment(to).format('DD.MM.YYYY')}.zip`);
  }
}
