import { Injector } from '@angular/core';
import { CREATE_PERMISSION, DELETE_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION } from '@carlos-orgos/orgos-utils/constants/permissions.constants';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';

import { IUserWorkModel } from '../../models/user-work.model';
import { AuthenticationService } from '../services/core/authentication.service';
import { ErrorManagerService } from '../services/error/error-manager.service';
import { IGenericService } from '../services/generic.service';
import { UserWorkService } from '../services/user/user-work.service';
import { ErrorCodes } from './error/error-codes';
import { OrgosError } from './error/orgos-error';

export abstract class GenericModel {
  protected injector: Injector;
  protected rawData: any;
  protected serviceClass: any;
  protected ownerId: string;
  protected operationOptions: any;

  protected service: IGenericService;

  private permissions: Promise<IPermissions>;
  public canCreate: Promise<boolean>;
  public canRead: Promise<boolean>;
  public canEdit: Promise<boolean>;
  public canDelete: Promise<boolean>;

  constructor(injector: Injector, rawData: any, serviceClass: any, ownerId?: string, operationOptions?: any) {
    if (
      check.not.assigned(injector) ||
      check.not.object(injector) ||
      check.emptyObject(injector) ||
      check.not.assigned(rawData) ||
      (check.not.object(rawData) && check.not.array(rawData)) ||
      (check.object(rawData) && check.emptyObject(rawData)) ||
      check.not.assigned(serviceClass) ||
      check.not.function(serviceClass)
    ) {
      const permissions: IPermissions = {
        create: false,
        read: false,
        edit: false,
        delete: false
      };
      this.setPermissions(permissions);
      return;
    }

    this.injector = injector;
    this.rawData = rawData;
    this.serviceClass = serviceClass;
    this.ownerId = check.assigned(ownerId) && check.string(ownerId) ? ownerId : '';

    this.operationOptions = check.assigned(operationOptions) ? operationOptions : undefined;
    this.service = injector.get(serviceClass);

    this.service.getFieldsTranslations(this.operationOptions); //Prefetch translations;

    this.setPermissions();
  }

  private resolveAllUserWork(): Promise<Array<IUserWorkModel>> {
    return this.injector.get(UserWorkService).getAllUserWorkCache();
  }

  protected setPermissions(newPermissions?: IPermissions): void {
    if (check.assigned(newPermissions)) {
      this.permissions = Promise.resolve(newPermissions);
    }

    this.canCreate = new Promise<boolean>((resolve) => {
      this.resolvePermissions()
        .then((permissions: IPermissions) => {
          resolve(permissions.create);
        })
        .catch((error) => {
          // This situation should not happen because resolvePermissions method always resolves
          const errorManager: ErrorManagerService = this.injector.get(ErrorManagerService);
          const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, GenericModel.name, 'canCreate');
          orgosError.message = 'GenericModel.resolvePermissions cannot reject the promise';
          errorManager.handleParsedErrorSilently(orgosError);

          // The best option is to resolve to false if we do not know the real permission
          resolve(false);
        });
    });

    this.canRead = new Promise<boolean>((resolve) => {
      this.resolvePermissions()
        .then((permissions: IPermissions) => {
          resolve(permissions.read);
        })
        .catch((error) => {
          // This situation should not happen because resolvePermissions method always resolves
          const errorManager: ErrorManagerService = this.injector.get(ErrorManagerService);
          const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, GenericModel.name, 'canRead');
          orgosError.message = 'GenericModel.resolvePermissions cannot reject the promise';
          errorManager.handleParsedErrorSilently(orgosError);

          // The best option is to resolve to false if we do not know the real permission
          resolve(false);
        });
    });

    this.canEdit = new Promise<boolean>((resolve, reject) => {
      this.resolvePermissions()
        .then((permissions: IPermissions) => {
          resolve(permissions.edit);
        })
        .catch((error) => {
          // This situation should not happen because resolvePermissions method always resolves
          const errorManager: ErrorManagerService = this.injector.get(ErrorManagerService);
          const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, GenericModel.name, 'canEdit');
          orgosError.message = 'GenericModel.resolvePermissions cannot reject the promise';
          errorManager.handleParsedErrorSilently(orgosError);

          // The best option is to resolve to false if we do not know the real permission
          resolve(false);
        });
    });

    this.canDelete = new Promise<boolean>((resolve, reject) => {
      this.resolvePermissions()
        .then((permissions: IPermissions) => {
          resolve(permissions.delete);
        })
        .catch((error) => {
          // This situation should not happen because resolvePermissions method always resolves
          const errorManager: ErrorManagerService = this.injector.get(ErrorManagerService);
          const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, GenericModel.name, 'canDelete');
          orgosError.message = 'GenericModel.resolvePermissions cannot reject the promise';
          errorManager.handleParsedErrorSilently(orgosError);

          // The best option is to resolve to false if we do not know the real permission
          resolve(false);
        });
    });
  }

  private resolvePermissions(): Promise<IPermissions> {
    if (check.assigned(this.permissions)) {
      return this.permissions;
    }

    this.permissions = new Promise<IPermissions>((resolve) => {
      this.service
        .getPermissions(this.operationOptions)
        .then((rawPermissions: any) => {
          const permissionPromises = [this.resolveAccessLevel(CREATE_PERMISSION, rawPermissions), this.resolveAccessLevel(READ_PERMISSION, rawPermissions), this.resolveAccessLevel(UPDATE_PERMISSION, rawPermissions), this.resolveAccessLevel(DELETE_PERMISSION, rawPermissions)];

          return Promise.all(permissionPromises);
        })
        .then((permissionResults) => {
          const permissions: IPermissions = {
            create: permissionResults[0],
            read: permissionResults[1],
            edit: permissionResults[2],
            delete: permissionResults[3]
          };
          resolve(permissions);
        })
        .catch((error) => {
          // An error is already shown

          // The best option is to set to false all the permissions if we do not know the real ones.
          const permissions: IPermissions = {
            create: false,
            read: false,
            edit: false,
            delete: false
          };
          resolve(permissions);
        });
    });

    return this.permissions;
  }

  private resolveAccessLevel(permissionType: CREATE_PERMISSION | READ_PERMISSION | UPDATE_PERMISSION | DELETE_PERMISSION, rawPermissions): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (check.assigned(rawPermissions[`${permissionType}_all`]) && rawPermissions[`${permissionType}_all`] === true) {
        resolve(true);
        return;
      }

      const authenticationService = this.injector.get(AuthenticationService);
      const loggedUser = authenticationService.getLoggedUser();
      let collectionName;

      if (check.assigned(rawPermissions[`${permissionType}_custom`]) && check.array(rawPermissions[`${permissionType}_custom`]) && check.nonEmptyArray(rawPermissions[`${permissionType}_custom`])) {
        this.resolveAllUserWork()
          .then((allUserWork) => {
            if (check.assigned(this.service['GOAL_PERMISSIONS_KEY']) && this.service['GOAL_PERMISSIONS_KEY'] === 'goal') {
              collectionName = 'goal';
            } else if (check.assigned(this.service['PROJECT_PERMISSIONS_KEY']) && this.service['PROJECT_PERMISSIONS_KEY'] === 'project') {
              collectionName = 'project';
            } else if (check.assigned(this.service['DOCUMENT_PERMISSIONS_KEY']) && this.service['DOCUMENT_PERMISSIONS_KEY'] === 'document') {
              collectionName = 'document';
            }
            return customPermissions.applyCustomPermissionsToDocument(permissionType, collectionName, rawPermissions[`${permissionType}_custom`], rawPermissions[`${permissionType}_own`], this.rawData, allUserWork, loggedUser, this.ownerId);
          })
          .then((customPermissionResult) => {
            resolve(customPermissionResult);
          })
          .catch((error) => {
            resolve(false);
            return;
          });
      } else if (check.assigned(rawPermissions[`${permissionType}_own`]) && rawPermissions[`${permissionType}_own`] === true) {
        if (check.assigned(this.service['GOAL_PERMISSIONS_KEY']) && this.service['GOAL_PERMISSIONS_KEY'] === 'goal') {
          // For the very specific case of Goals collection, convert own to custom permissions
          if (check.assigned(this.rawData.assignedUsers) && check.nonEmptyArray(this.rawData.assignedUsers) && check.contains(this.rawData.assignedUsers, loggedUser._id)) {
            resolve(true);
          }
          resolve(false);
        }
        if (check.assigned(this.service['PROJECT_PERMISSIONS_KEY']) && this.service['PROJECT_PERMISSIONS_KEY'] === 'project') {
          // For the very specific case of Project collection, convert own to custom permissions
          if (check.assigned(this.rawData._userIds) && check.nonEmptyArray(this.rawData._userIds) && check.contains(this.rawData._userIds, loggedUser._id)) {
            resolve(true);
          }
          resolve(false);
        }
        if (check.assigned(this.ownerId) && check.nonEmptyString(this.ownerId) && check.assigned(loggedUser) && check.not.equal(this.ownerId, loggedUser._id)) {
          resolve(false);
          return;
        }
        if (check.assigned(this.rawData) && check.assigned(this.rawData.ownerId) && check.nonEmptyString(this.rawData.ownerId) && check.not.equal(this.rawData.ownerId, loggedUser._id)) {
          resolve(false);
          return;
        }
        resolve(true);
        return;
      } else {
        resolve(false);
      }
    });
  }

  public getTranslation(): Promise<object> {
    return this.service.getFieldsTranslations(this.operationOptions);
  }
}

export interface IPermissions {
  create: boolean;
  read: boolean;
  edit: boolean;
  delete: boolean;
}
