import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class MeetingService {
  private MEETING_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/meeting-db`;
  private MEETING_PERMISSIONS_KEY: string = 'meeting';
  private MEETING_INTERNATIONALIZATION: string = 'meeting-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private injector: Injector) {}

  create(data: any): Promise<IMeetingModel> {
    return new Promise<IMeetingModel>((resolve, reject) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      data.timezone = timezone;
      this.genericService
        .create(this.MEETING_URL, data)
        .then((meeting: IMeetingModel) => {
          resolve(meeting);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IMeetingModel>> {
    return new Promise<Array<IMeetingModel>>((resolve, reject) => {
      this.genericService
        .find(this.MEETING_URL, findQuery)
        .then((meetings: Array<IMeetingModel>) => {
          resolve(meetings);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingService.name, 'find'));
        });
    });
  }

  sendReminder(id: string, includeOwner: boolean = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const reminderQuery = {
        _id: id,
        timezone: timezone
      };

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.injector
        .get(HttpClient)
        .post(`${this.MEETING_URL}/remind/${includeOwner}`, reminderQuery, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, MeetingService.name, 'sendReminder'));
        });
    });
  }

  getById(id: string): Promise<IMeetingModel> {
    return new Promise<IMeetingModel>((resolve, reject) => {
      this.genericService
        .getById(this.MEETING_URL, id)
        .then((meeting: IMeetingModel) => {
          resolve(meeting);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IMeetingModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      data.timezone = timezone;
      this.genericService
        .updateById(this.MEETING_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.MEETING_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.MEETING_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.MEETING_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.MEETING_URL);
  }

  getAllMeetings(): Promise<Array<IMeetingModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<IMeetingModel>>((resolve, reject) => {
      this.genericService
        .find(this.MEETING_URL, findBody)
        .then((allMeetings: Array<IMeetingModel>) => {
          resolve(allMeetings);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingService.name, 'getAllMeetings'));
        });
    });
  }
}

export interface IMeetingModel {
  _id?: string;
  name?: string;
  type?: 'live' | 'async';
  duration?: number;
  finished?: boolean;
  agenda?: Array<{ type: 'text' | 'list' | 'question' | 'toDo'; content: any; name: string; participantCanAdd: boolean; completed: boolean; _id?: string }>;
  participants?: Array<any>;
  startDate?: Date;
  startTime?: number;
  endTime?: number;
  departmentId?: string;
  templateId?: Array<string>;
  _createdAt?: Date;
  ownerId?: string;
  repeatMeeting?: {
    weeklyRepetitions?: {
      monday?: boolean;
      tuesday?: boolean;
      wednesday?: boolean;
      thursday?: boolean;
      friday?: boolean;
      saturday?: boolean;
      sunday?: boolean;
    };
  };
  remindMeeting?: boolean;
  timezone?: string;
}
