import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { CompanyService } from '@app/standard/services/company/company.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserSalaryService } from '@app/standard/services/user/user-salary.service';
import { IUserWorkScheduleModel, UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-add-salary-dialog',
  templateUrl: 'add-salary.dialog.html',
  styleUrls: ['add-salary.dialog.scss'],
})
export class AddSalaryDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  salary: GenericCacheModel;
  onGoingSalary: GenericCacheModel;

  private _currentAmount: any;
  set currentAmount(currentAmount: any) {
    this._currentAmount = currentAmount;
    this.cdr.detectChanges();
  }
  get currentAmount(): any {
    return this._currentAmount;
  }

  private _currentPartTime: any;
  set currentPartTime(currentPartTime: any) {
    this._currentPartTime = currentPartTime;
    this.cdr.detectChanges();
  }
  get currentPartTime(): any {
    return this._currentPartTime;
  }

  minDate: Date;
  minEndDateOnGoing: Date;
  maxOnGoingEndDate: Date;
  minStartDateNewSalary: Date;
  minEndDateNewSalary: Date;
  effectiveDateValidation: InputValidation;
  payPeriodValidation: InputValidation;
  amountValidation: InputValidation;
  currencyValidation: InputValidation;
  hourlyRateValidation: InputValidation = new InputValidation();
  partTimeValidation: InputValidation = new InputValidation();
  companyOptions: Array<any> = [];
  canEditPast: boolean = false;
  optionEndDate: 'indefinite' | 'certain' = 'indefinite';
  isIndefiniteDate: boolean = true;
  isOnGoingSalary: boolean = false;
  currentWorkSchedule: IUserWorkScheduleModel;
  surchargeHoursEditedManually: boolean = false;
  private workingHoursPerWeek: number = 0;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddSalaryDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private decimalPipe: DecimalPipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.canEditPast = this.data.canEditPast;
    this.isOnGoingSalary = this.data.notEndedSalary;
    this.minStartDateNewSalary = this.data.minStartDate;

    if (check.assigned(this.data.notEndedSalary)) {
      this.minEndDateOnGoing = this.data.notEndedSalary.effectiveDate;
      this.maxOnGoingEndDate = this.data.maxOnGoingEndDate;
      this.onGoingSalary = new GenericCacheModel(
        this.injector,
        { ...this.data.notEndedSalary, endDate: undefined },
        UserSalaryService,
        this.data.ownerId
      );
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-add-salary-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((dialogTranslation) => {
        this.miscTranslation = dialogTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((allCompanies) => {
        this.companyOptions = allCompanies.map((iCompany: any) => {
          const companyOption = {
            name: iCompany.name,
            value: iCompany._id,
          };
          return companyOption;
        });
        const myCompany = allCompanies.find((iCompany) => {
          return iCompany._id === this.data.companyId;
        });

        let defaultCurrency;
        if (check.assigned(myCompany) && check.assigned(myCompany.defaultCurrency) && check.nonEmptyString(myCompany.defaultCurrency)) {
          defaultCurrency = myCompany.defaultCurrency;
        }

        let partTime: number = parseFloat(((this.data.employeeWeeklyHours / this.data.companyWeeklyHours) * 100).toFixed(2));

        if (check.not.number(partTime) || partTime < 0 || partTime > 100) {
          partTime = 100;
        }
        this.salary = new GenericCacheModel(
          this.injector,
          {
            _companyId: this.data.companyId,
            effectiveDate: undefined,
            amount: undefined,
            currency: defaultCurrency,
            partTime: partTime,
            payPeriod: undefined,
            payFrequency: 'Monthly',
          },
          UserSalaryService,
          this.data.ownerId
        );
      })
      .catch(() => {});

    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    await this.initWorkingHoursPerWeek();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getFTESalary(): string {
    if (
      check.not.assigned(this.currentAmount) ||
      check.not.number(this.currentAmount) ||
      check.not.assigned(this.currentPartTime) ||
      check.not.number(this.currentPartTime)
    ) {
      return '-';
    }

    const fteSalary = (this.currentAmount * 100) / this.currentPartTime;
    if (check.not.number(fteSalary)) {
      return '-';
    }

    return this.decimalPipe.transform(fteSalary, '1.0-2');
  }

  getMinStartDate(): Date {
    if (this.onGoingSalary?.data?.endDate) {
      return moment(this.onGoingSalary?.data?.endDate).isSameOrAfter(this.minStartDateNewSalary)
        ? moment(this.onGoingSalary?.data?.endDate).add(1, 'day').toDate()
        : moment(this.minStartDateNewSalary).add(1, 'day').toDate();
    } else {
      return check.assigned(this.minStartDateNewSalary) ? moment(this.minStartDateNewSalary).add(1, 'day').toDate() : undefined;
    }
  }

  getMaxOnGoingEndDate(): Date {
    return this.salary.data?.effectiveDate ? moment(this.salary.data?.effectiveDate).subtract(1, 'day').toDate() : this.maxOnGoingEndDate;
  }

  updateDateType(value): void {
    this.optionEndDate = value;
    this.isIndefiniteDate = this.optionEndDate === 'indefinite';

    if (this.isIndefiniteDate) {
      this.salary.data.selectEndDate = undefined;
    }
  }

  addSalary(): void {
    if (this.salary.data.payPeriod.toLowerCase() === 'hourly') {
      this.salary.data.partTime = 100;
      this.salary.data.surchargeHourlyRate = this.salary.data.amount;
    }

    if (
      check.not.assigned(this.effectiveDateValidation) ||
      this.effectiveDateValidation.hasErrors() ||
      check.not.assigned(this.payPeriodValidation) ||
      this.payPeriodValidation.hasErrors() ||
      check.not.assigned(this.amountValidation) ||
      this.amountValidation.hasErrors() ||
      check.not.assigned(this.currencyValidation) ||
      this.currencyValidation.hasErrors() ||
      check.not.assigned(this.partTimeValidation) ||
      this.partTimeValidation.hasErrors() ||
      check.not.assigned(this.hourlyRateValidation) ||
      this.hourlyRateValidation.hasErrors()
    ) {
      return;
    }

    this.dialogRef.close({
      newSalary: this.salary.data,
      onGoing: check.assigned(this.onGoingSalary) ? this.onGoingSalary.data : undefined,
    });
  }

  surchargeHoursEdited(): void {
    this.surchargeHoursEditedManually = true;
  }

  recalculateSuggestedSurchargeHourlyRate(removeManualEdition: boolean = false): void {
    if (removeManualEdition) {
      this.surchargeHoursEditedManually = false;
    }
    if (this.surchargeHoursEditedManually) {
      return;
    }
    this.salary.data.surchargeHourlyRate = this.injector
      .get(UserSalaryService)
      .calculateSurchargeHourlyRate(this.salary.data, this.workingHoursPerWeek);
  }

  private async initWorkingHoursPerWeek(): Promise<void> {
    const userId = this.data.ownerId;
    this.workingHoursPerWeek = await this.injector.get(UserWorkScheduleService).getWorkingHoursPerWeekForUserId(userId);
  }
}
