import { Component, Injector, Input, OnInit } from '@angular/core';
import { IShiftPlanGeneralSettingsModel } from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
@Component({
  selector: 'kenjo-settings-shift-plan-general',
  templateUrl: 'settings-shift-plan-general.html',
  styleUrls: ['settings-shift-plan-general.scss']
})
export class SettingsShiftPlanGeneral implements OnInit {
  i18n: any = {};
  @Input() generalSettings: IShiftPlanGeneralSettingsModel;
  @Input() parentTranslation: any = {};

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      this.i18n.page = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-general');
    } catch {
      this.i18n.page = {};
    }
  }
}
