import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { PrivateInternationalizationService } from '../../../private/services/private-internationalization.service';

@Injectable()
export class InternationalizationService {
  constructor(private privateInternationalizationService: PrivateInternationalizationService) {}

  getLanguage(): string {
    return this.privateInternationalizationService.getLanguage();
  }

  getLocale(): string {
    return this.privateInternationalizationService.getLocale();
  }

  getAllTranslation(translationResource: string): Promise<any> {
    return this.privateInternationalizationService.getAllTranslation(translationResource);
  }

  getTranslation(translationResource: string, key: string): Promise<string> {
    return this.privateInternationalizationService.getTranslation(translationResource, key);
  }

  getMiscTranslation(): any {
    return this.privateInternationalizationService.getMiscTranslation();
  }

  /**
   * Returns an array of strings with the weekdays
   * translated into the current user's language (instead of region)
   * _Example_:
   * ```
   * ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
   * ```
   */
  getTranslatedWeekdays(): Array<string> {
    return this.privateInternationalizationService.getTranslatedWeekdays();
  }

  /**
   * Returns an array of strings with first two characters of the weekdays
   * translated into the current user's language (instead of region)
   * _Example_:
   * ```
   * ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
   * ```
   */
  getShortTranslatedWeekdays(): Array<string> {
    return this.privateInternationalizationService.getShortTranslatedWeekdays();
  }

  /**
   * ## getSanitizedUTCToTimeZone
   * Generates an UTC date **bypassing** the hour-shift of the
   * current time zone.
   * -----------------------------------
   * > **Warning**: Use only on edge cases
   *
   * -----------------------------------
   * @param date A `MomentInput` (can be empty, a date string...)
   * @returns a `moment.utc` instance with the local hour as if it were UTC.
   */
  getSanitizedUTCToTimeZone(date?: moment.MomentInput): moment.Moment {
    return this.privateInternationalizationService.getSanitizedUTCToTimeZone(date);
  }

  /**
   * ## getSanitizedTimeZoneFromUTC
   * Generates an non-UTC date **bypassing** the hour-shift of the
   * current time zone.
   * -----------------------------------
   * > **Warning**: Use only on edge cases
   *
   * -----------------------------------
   * @param date A UTC `MomentInput` (can be empty, a date string...)
   * @returns a `moment` (non-UTC) instance with the UTC hour as if it were local.
   */
  getSanitizedTimeZoneFromUTC(date?: moment.MomentInput) {
    return this.privateInternationalizationService.getSanitizedTimeZoneFromUTC(date);
  }
}
