<div class="kenjo-p-40px wsc-content">
  <div>
    <div class="kenjo-font-weight-bold kenjo-font-size-16px">{{ translations.workHeader }}</div>

    <orgos-column-container class="kenjo-mt-10px">
      <orgos-input-select
        orgosColumn
        [model]="userWork"
        field="companyId"
        [options]="companiesOptions"
        required="true"
        (validation)="companyValidation = $event"
        class="kenjo-mr-10px"
        (modelChange)="onCompanyChange()"
      >
        <orgos-input-error *ngIf="companyValidation && companyValidation.getError('required')">
          {{ translations.companyRequired }}</orgos-input-error
        >
      </orgos-input-select>
      <orgos-input-select
        orgosColumn
        [model]="userAccount"
        field="language"
        picklist="language"
        class="kenjo-ml-10px"
        required="true"
        (validation)="languageValidation = $event"
        [matTooltip]="translations.companyTooltip"
        matTooltipPosition="above"
      ></orgos-input-select>
    </orgos-column-container>

    <orgos-column-container>
      <orgos-input-email
        orgosColumn
        [model]="userAccount"
        field="email"
        required="true"
        (validation)="workEmailValidation = $event"
        class="kenjo-mr-10px"
      >
        <orgos-input-error *ngIf="workEmailValidation && workEmailValidation.getError('required')">
          {{ translations.workEmailRequired }}</orgos-input-error
        >
        <orgos-input-error
          *ngIf="workEmailValidation && !workEmailValidation.getError('required') && workEmailValidation.getError('email')"
        >
          {{ translations.workEmailNoValidFormat }}</orgos-input-error
        >
      </orgos-input-email>
      <orgos-input-select
        orgosColumn
        [readOnly]="userProfile !== 'admin' && userProfile !== 'hr-admin'"
        [model]="userAccount"
        field="profileKey"
        [required]="true"
        [options]="profiles"
        [sortOptions]="false"
        class="kenjo-ml-10px"
      ></orgos-input-select>
    </orgos-column-container>

    <orgos-column-container>
      <orgos-input-select
        orgosColumn
        [model]="userWork"
        field="officeId"
        [enableClearButton]="true"
        [options]="offices"
        class="kenjo-mr-10px"
      ></orgos-input-select>
      <orgos-input-select
        orgosColumn
        [model]="userWork"
        field="departmentId"
        [enableClearButton]="true"
        [options]="departments"
        class="kenjo-ml-10px"
      ></orgos-input-select>
    </orgos-column-container>

    <orgos-column-container>
      <orgos-input-select
        orgosColumn
        [multiple]="true"
        [model]="userWork"
        field="areaIds"
        [enableClearButton]="true"
        [options]="areas"
        class="kenjo-mr-10px"
      ></orgos-input-select>
      <orgos-input-select
        orgosColumn
        [multiple]="true"
        [model]="userWork"
        field="teamIds"
        [enableClearButton]="true"
        [options]="divisions"
        class="kenjo-ml-10px"
      ></orgos-input-select>
    </orgos-column-container>

    <orgos-column-container>
      <orgos-input-text orgosColumn [model]="userWork" field="jobTitle" class="kenjo-mr-10px"></orgos-input-text>
      <orgos-input-text orgosColumn [model]="userWork" field="employeeNumber" class="kenjo-ml-10px"></orgos-input-text>
    </orgos-column-container>

    <orgos-column-container>
      <orgos-input-simple-search-user
        orgosColumn
        [label]="translations.reportsToLabel"
        [(value)]="userWork.data.reportsToId"
        [enableClearButton]="true"
        [users]="reportsToOptions"
        class="kenjo-mr-10px kenjo-cursor-pointer"
        (valueChange)="onReportsToChange($event)"
        [showAvatar]="true"
      ></orgos-input-simple-search-user>
      <orgos-input-simple-search-user
        *ngIf="isTimeOffEnabled === true && isTimeOffApproverActive === true"
        orgosColumn
        [label]="translations.timeOffApproverLabel"
        [(value)]="userWork.data.timeOffApproverId"
        [enableClearButton]="true"
        (valueChange)="computeTimeOffApprover($event)"
        [users]="reportsToOptions"
        class="kenjo-ml-10px kenjo-cursor-pointer"
        [matTooltip]="translations.timeOffApproverTooltip"
        [matTooltipPosition]="'above'"
        [showAvatar]="true"
      ></orgos-input-simple-search-user>
      <orgos-column *ngIf="isTimeOffEnabled === false || isTimeOffApproverActive === false" class="kenjo-ml-10px"></orgos-column>
    </orgos-column-container>

    <orgos-column-container>
      <orgos-input-date-picker
        orgosColumn
        [model]="userWork"
        field="startDate"
        [enableClearButton]="true"
        (validation)="startDateValidation = $event"
        (modelChange)="onReloadTimeOffStep()"
        class="kenjo-mr-10px"
      >
      </orgos-input-date-picker>
      <orgos-input-date-picker
        orgosColumn
        [model]="userWork"
        field="contractEnd"
        [enableClearButton]="true"
        (validation)="contractEndDateValidation = $event"
        (modelChange)="onReloadTimeOffStep()"
        class="kenjo-ml-10px"
      >
      </orgos-input-date-picker>
    </orgos-column-container>

    <div class="kenjo-font-weight-bold kenjo-font-size-16px kenjo-mt-10px">
      {{ translations.workScheduleHeader }}
    </div>
    <mat-radio-group
      [(ngModel)]="workScheduleConfiguration.workScheduleTypeSelected"
      class="wsc-work-schedule-selector kenjo-mt-20px"
      (change)="onWorkScheduleTypeChange(); validateWorkSchedule()"
    >
      <div>
        <mat-radio-button [value]="'Template'" [matTooltip]="translations.templateTooltip" matTooltipPosition="right">{{
          translations.templateOption
        }}</mat-radio-button>
      </div>
      <div class="kenjo-ml-20px">
        <mat-radio-button [value]="'Legacy'" [matTooltip]="translations.standardTooltip" matTooltipPosition="right">{{
          translations.legacyOption
        }}</mat-radio-button>
      </div>
    </mat-radio-group>
    <orgos-column-container>
      <orgos-input-simple-select
        [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Legacy'"
        class="kenjo-mr-20px"
        orgosColumn="1"
        [label]="translations.workScheduleTemplateField"
        [value]="workScheduleConfiguration.workScheduleTemplateSelected"
        [options]="workScheduleTemplatesOptions"
        (valueChange)="onTemplateChange($event); validateWorkSchedule()"
      ></orgos-input-simple-select>
      <div orgosColumn="1" class="wsc-legacy">
        <div>
          <orgos-input-toggle-round
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            [model]="userWorkSchedule"
            field="mondayWorkingDay"
            [label]="translatedWeekdays[0]"
            [disabledColor]="'Disabled'"
            class="kenjo-mr-5px"
            (modelChange)="onReloadTimeOffStep()"
          ></orgos-input-toggle-round>
          <orgos-input-toggle-round
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            [model]="userWorkSchedule"
            field="tuesdayWorkingDay"
            [label]="translatedWeekdays[1]"
            [disabledColor]="'Disabled'"
            class="kenjo-mr-5px"
            (modelChange)="onReloadTimeOffStep()"
          ></orgos-input-toggle-round>
          <orgos-input-toggle-round
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            [model]="userWorkSchedule"
            field="wednesdayWorkingDay"
            [label]="translatedWeekdays[2]"
            [disabledColor]="'Disabled'"
            class="kenjo-mr-5px"
            (modelChange)="onReloadTimeOffStep()"
          ></orgos-input-toggle-round>
          <orgos-input-toggle-round
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            [model]="userWorkSchedule"
            field="thursdayWorkingDay"
            [label]="translatedWeekdays[3]"
            [disabledColor]="'Disabled'"
            class="kenjo-mr-5px"
            (modelChange)="onReloadTimeOffStep()"
          ></orgos-input-toggle-round>
          <orgos-input-toggle-round
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            [model]="userWorkSchedule"
            field="fridayWorkingDay"
            [label]="translatedWeekdays[4]"
            [disabledColor]="'Disabled'"
            class="kenjo-mr-5px"
            (modelChange)="onReloadTimeOffStep()"
          ></orgos-input-toggle-round>
          <orgos-input-toggle-round
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            [model]="userWorkSchedule"
            field="saturdayWorkingDay"
            [label]="translatedWeekdays[5]"
            [disabledColor]="'Disabled'"
            class="kenjo-mr-5px"
            (modelChange)="onReloadTimeOffStep()"
          ></orgos-input-toggle-round>
          <orgos-input-toggle-round
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            [model]="userWorkSchedule"
            field="sundayWorkingDay"
            [label]="translatedWeekdays[6]"
            [disabledColor]="'Disabled'"
            (modelChange)="onReloadTimeOffStep()"
          ></orgos-input-toggle-round>
        </div>
        <div>
          <orgos-input-number
            step="0.01"
            [readOnly]="workScheduleConfiguration.workScheduleTypeSelected === 'Template'"
            class="kenjo-ml-10px"
            [model]="userWork"
            field="weeklyHours"
            (modelChange)="onReloadTimeOffStep()"
            [min]="1"
            [max]="company ? company.weeklyHours : HOURS_IN_A_WEEK"
            (validation)="weeklyHoursValidation = $event"
            [required]="true"
          >
            <orgos-input-error *ngIf="weeklyHoursValidation && weeklyHoursValidation.hasErrors()">{{
              translations.weeklyHoursNoValidValue
                | i18nData: { min: 1, max: userWork.data.weeklyHours ? userWork.data.weeklyHours : 7 * 24 }
            }}</orgos-input-error>
          </orgos-input-number>
        </div>
      </div>
    </orgos-column-container>
  </div>

  <div class="wsc-button kenjo-mt-5px">
    <orgos-button-raised (click)="onPreviousStep()" color="Neutral">{{ translations.backLabel }}</orgos-button-raised>
    <orgos-button-raised
      (click)="onNextStep()"
      color="Success"
      [disabled]="
        !companyValidation ||
        companyValidation.hasErrors() ||
        !workEmailValidation ||
        workEmailValidation.hasErrors() ||
        startDateValidation?.hasErrors() ||
        !isWorkScheduleValid ||
        (workScheduleConfiguration.workScheduleTypeSelected === 'Legacy' && weeklyHoursValidation && weeklyHoursValidation.hasErrors())
      "
      >{{ translations.nextLabel }}</orgos-button-raised
    >
  </div>
</div>
