import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { USER_SEGMENTATION_FEATURES } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import { shuffle } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserSegmentationService {
  // if this field is true, the user segmentation modal will load on /cloud routes
  private _showUserSegmentationDialog: boolean | null = null;
  public get showUserSegmentationDialog(): boolean | null {
    return this._showUserSegmentationDialog;
  }

  // this prevents the guard from looping on the cloud url
  private _skipSegmentationVerification: boolean = false;
  public get skipSegmentationVerification(): boolean {
    return this._skipSegmentationVerification;
  }

  private _features: Array<IFeatureTile>;
  public get features(): Array<IFeatureTile> {
    return this._features;
  }

  constructor(private injector: Injector) {
    this._features = USER_SEGMENTATION_FEATURES.map((key: string) => ({ key: key, isSelected: false }));
    this._features = shuffle(this.features);
  }

  // we cache the result so the request is only performed once on sign up, not on every navigation
  async getShowUserSegmentationDialog(): Promise<boolean> {
    if (this._showUserSegmentationDialog !== null) {
      return this._showUserSegmentationDialog;
    } else {
      const showUserSegmentation = await this.fetchShowUserSegmentation();
      this._showUserSegmentationDialog = showUserSegmentation;
      return this._showUserSegmentationDialog;
    }
  }

  private async fetchShowUserSegmentation(): Promise<boolean> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const response = await this.injector.get(HttpClient).get<IShowUserSegmentationResponse>(`${environment.PEOPLE_CLOUD_APP_URL}/controller/onboarding/segment-user`, httpOptions).toPromise();
      return response.showUserSegmentation;
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawErrorSilently(error, UserSegmentationService.name, 'fetchShowUserSegmentation');
      return false;
    }
  }

  clearCache(): void {
    this._showUserSegmentationDialog = null;
    this._skipSegmentationVerification = false;
  }

  setSkipSegmentationVerification(): void {
    this._skipSegmentationVerification = true;
  }

  async getStarted() {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const httpRequest = {
        features: this._features.filter((feature: IFeatureTile) => feature.isSelected === true).map((feature: IFeatureTile) => feature.key)
      };
      await this.injector.get(HttpClient).post(`${environment.PEOPLE_CLOUD_APP_URL}/controller/onboarding/segment-user`, httpRequest, httpOptions).toPromise();
      this.injector.get(PrivateAmplitudeService).logEvent('Feature preference saved', { platform: 'Web', category: 'Free trial' });
      // update the cache
      this._skipSegmentationVerification = true;
      this._showUserSegmentationDialog = false;
      this.injector.get(Router).navigateByUrl('/cloud/home');
    } catch (error: any) {
      this.injector.get(ErrorManagerService).handleRawErrorSilently(error, UserSegmentationService.name, 'getStarted');
    }
  }
}

interface IShowUserSegmentationResponse {
  showUserSegmentation: boolean;
}
export interface IFeatureTile {
  key: string;
  isSelected: boolean;
}
