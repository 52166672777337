<div class="dialog-box">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <!-- TITLE -->
  <orgos-column-container centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-text type="Headline1">{{translation.addParticipant}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>

  <!-- CONTENT -->
  <div class="kenjo-ph-60px kenjo-pt-60px mdd-content" *ngIf="userIdToUserPersonal && userIdToUserPersonal !== undefined">
    <orgos-column-container centerColumns="true" class="kenjo-pt-40px">
      <orgos-column>
        <orgos-search [placeholder]="translation.teamMemberSearch" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event">
          <orgos-text class="orgos-search-error" color="Warn">{{translation.dialogNotFoundPeopleError}}</orgos-text>

          <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="assignUser(result._id)">
            <kenjo-truncate-text>{{result.displayName}}</kenjo-truncate-text>
          </orgos-search-option>

          <orgos-column-container wrap="true" class="orgos-search-selection-container">
            <orgos-column size="0" *ngFor="let result of searchResults" class="kenjo-m-10px">
              <orgos-chip [imageUrl]="result._photo?._url" buttonIconName="clear" [onClickButtonFunction]="removeUser(result._id)" [addInitialsIfNoImage]="true" [chipText]="result.displayName">{{result.displayName}}</orgos-chip>
            </orgos-column>
          </orgos-column-container>
        </orgos-search>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-text type="BodyText">{{numberOfParticipants}}</orgos-text>
      </orgos-column>
      <orgos-column *ngIf="newParticipantsAdded !== ''">
        <orgos-text type="BodyText">{{newParticipantsAdded}}</orgos-text>
      </orgos-column>
    </orgos-column-container>
  </div>
  <!-- BUTTONS -->
  <orgos-column-container centerColumns="true" class="kenjo-pb-20px kenjo-mh-60px kenjo-pt-60px" *ngIf="userIdToUserPersonal && userIdToUserPersonal !== undefined">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="mdd-button-align">
          <div>
            <mat-icon class="mdd-cancel-button" (click)="closeDialog()">cancel_icon</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText">{{translation.dialogCancelBtn}}</orgos-text>
          </div>
        </orgos-column>
        <orgos-column size="1" class="mdd-button-align">
          <div>
            <mat-icon class="mdd-create-button" (click)="rightButtonAction()">arrow_next_circle</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText">{{translation.dialogSaveBtn}}</orgos-text>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
