import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';

import { InternationalizationService } from '../../../services/core/internationalization.service';
import { UserAccountService } from '../../../services/user/user-account.service';
import { UserPersonalService } from '../../../services/user/user-personal.service';

@Component({
  selector: 'orgos-profile-box',
  templateUrl: 'profile-box.component.html',
  styleUrls: ['profile-box.component.scss']
})
export class ProfileBoxComponent implements OnInit {
  i18n: any = {};
  tabSelected: number = 0;
  allUserAccount: Array<any>;
  userIdToUserPersonal: any;

  @Output() profileUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() assignmentsUpdated: EventEmitter<any> = new EventEmitter<any>();

  private _profile: any;
  @Input()
  set profile(profileVal: any) {
    this._profile = profileVal;
  }
  get profile(): any {
    return this._profile;
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((i18n) => {
        this.i18n = i18n;
        return this.findAllUserAccounts();
      })
      .then(() => {
        return this.initUserIdToUserPersonal();
      })
      .then(() => {})
      .catch(() => {
        this.i18n = {};
      });
  }

  private findAllUserAccounts(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.injector
        .get(UserAccountService)
        .getAllUserAccount(false, false)
        .then((allUserAccounts: Array<any>) => {
          this.allUserAccount = allUserAccounts;
          resolve();
        })
        .catch((error) => {
          // An error is already shown
          this.allUserAccount = [];
          reject(error);
        });
    });
  }

  private initUserIdToUserPersonal(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userIdToUserPersonal = {};
      this.injector
        .get(UserPersonalService)
        .getAllUserPersonal(false, false)
        .then((allUserPersonals: Array<any>) => {
          allUserPersonals.forEach((iUserPersonal) => {
            this.userIdToUserPersonal[iUserPersonal[fieldConstants.ID]] = iUserPersonal;
          });
          resolve();
        })
        .catch((error) => {
          // An error is already shown
          this.userIdToUserPersonal = {};
          reject(error);
        });
    });
  }

  public emitSave(): void {
    this.profileUpdated.emit(this.profile);
  }

  public emitAssignmentsUpdated(): void {
    this.assignmentsUpdated.emit(this.profile);
  }

  public changeTab(tabNumber: number): void {
    this.tabSelected = tabNumber;
  }
}
