import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IBreakReminder } from '@app/cloud-features/settings-attendance/models/attendance-settings.model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'orgos-add-break-dialog',
  templateUrl: 'add-break.dialog.html',
  styleUrls: ['add-break.dialog.scss'],
})
export class AddBreakDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  breakReminderSettings: IBreakReminder;
  breakTime: number;
  timeEntryDay: moment.Moment;
  trackedTime: number;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddBreakDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('add-break-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.timeEntryDay = this.data.timeEntryDay;

    this.trackedTime = this.data.trackedTime;

    this.breakReminderSettings = { ...this.data.breakReminderSettings };

    this.breakTime = this.breakReminderSettings.suggestedBreakLength > 0 ? this.breakReminderSettings.suggestedBreakLength : null;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.breakTime);
  }
}
