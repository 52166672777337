<div *ngIf="i18n.page">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
    {{ parentTranslation.generalHeader }}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{ i18n.page.shiftEmployees }}</span>
  </div>

  <div class="kenjo-mt-30px kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-mv-20px sspl-white-space">
    {{ i18n.page.shiftEmployeesDescription }}
  </div>

  <div aspect="card" class="sspu-search kenjo-mt-25px">
    <kenjo-filter-bar
      class="sspp-filters"
      [filterOptions]="filters"
      [shown]="true"
      [searchTerm]="queryOptions.search"
      (changeFilterEvent)="toggleFilter($event)"
      (clearFilterEvent)="clearFilter($event)"
      (clearAllFiltersEvent)="clearAllFilters()"
      (searchTermChanged)="searchByTerm($event)"
    ></kenjo-filter-bar>
  </div>
  <div *ngIf="dataLoaded" class="sspu-table kenjo-mt-25px">
    <orgos-column-container>
      <orgos-column class="sspu-list-wrapper">
        <orgos-container class="sspu-table-container">
          <div *ngIf="tableLoading" class="sspu-list-container">
            <div class="sspu-list-loading-spinner kenjo-p-20px">
              <div>
                <orgos-loading-spinner></orgos-loading-spinner>
              </div>
            </div>
          </div>
          <div
            class="kenjo-pt-40px kenjo-pb-30px kenjo-flex-center-vertically kenjo-flex-vertical"
            *ngIf="!tableLoading && usersInfo.length === 0 && !areFiltersEmpty"
          >
            <figure>
              <img src="/assets/images/emptyState.svg" alt="Empty green box" />
            </figure>
            <p class="kenjo-font-size-16px"><strong>{{ i18n.page.noEmployees }}</strong></p>
            <p>{{ i18n.page.adjustFilters }}</p>
            <orgos-button-raised (click)="clearAllFilters()" class="kenjo-cursor-pointer"
              >{{ i18n.page.clearFilters | uppercase}}</orgos-button-raised
            >
          </div>

          <div
            class="kenjo-pt-40px kenjo-pb-30px kenjo-flex-center-vertically kenjo-flex-vertical"
            *ngIf="!tableLoading && usersInfo.length === 0 && areFiltersEmpty && safeVideoUrl"
          >
            <div class="sspu-video-container">
              <iframe width="100%" height="100%" [src]="safeVideoUrl" frameborder="0"></iframe>
            </div>
            <p class="kenjo-font-size-16px"><strong>{{ i18n.page.noEmployeesWithShiftplan }}</strong></p>
            <p>{{ i18n.page.watchVideo }}</p>
            <orgos-button-raised (click)="navigateToPeopleDirectory()" class="kenjo-cursor-pointer"
              >{{ i18n.page.goToEmployeesDirectory | uppercase }}</orgos-button-raised
            >
          </div>

          <orgos-table-smart
            *ngIf="!tableLoading && usersInfo && usersInfo.length > 0"
            [data]="usersInfo"
            [unselectableColumns]="['employee.name']"
            [manageColumns]="true"
            [totalColumns]="totalColumns"
            [displayedColumns]="displayedColumns"
            [headerLabels]="headerLabels"
            [selectable]="false"
            [sortBy]="sortBy"
            [sortOrder]="sortOrder"
            (sortTable)="changeColumnSorting($event)"
            [customHeightToSubstract]="paginationConfiguration.totalOfRecords > 25 ? 460 : '420'"
            [style.height]="paginationConfiguration.totalOfRecords > 25 ? 'calc(100vh - 460px)' : 'calc(100vh - 420px)'"
            [fitParent]="true"
            [selectable]="true"
            (changeSelection)="selectListItem($event)"
            [selectedList]="selectedUsers"
            (changeColumns)="saveColumns($event)"
          >
            <!-- Employee -->
            <orgos-table-smart-column columnKey="employee.name" [header]="i18n.page.employeeNameHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <div class="sspu-employee">
                  <orgos-avatar
                    orgosColumn="0"
                    [avatarName]="row.employee?.name"
                    [photoUrl]="row.employee?._photo?._url"
                    size="tiny"
                  ></orgos-avatar>
                  <a orgosColumn="0" class="kenjo-ml-10px" [routerLink]="['/cloud/people/', row.employee?._id, 'personal']">
                    <div class="sspu-truncate-text kenjo-ml-10px" [matTooltip]="row.employee?.name" [style.max-width]="'150px'">
                      {{ row.employee?.name }}
                    </div>
                  </a>
                </div>
              </ng-container>
            </orgos-table-smart-column>
            <!-- Shiftplan status -->
            <orgos-table-smart-column columnKey="shiftplanStatus" [header]="i18n.page.shiftPlanStatusHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <orgos-column-container
                centerColumns="true">
                  <div
                    orgosColumn="0"
                    *ngIf="userIsAdminHrAdmin || employeesToAccess.includes(row.employee._id)"
                    class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer sspu-edit-icon"
                    (click)="editEmployeeShiftplanProfile(row.employee._id)"
                  >
                    <kenjo-icon [size]="20">mode_edit</kenjo-icon>
                  </div>
                  <orgos-column size="0" >
                    <orgos-chip
                      *ngIf="row.shiftplanStatus === 'active'"
                      class="kenjo-text-align-center sspu-chip-font-color-005738"
                      [color]="'#eefcf2'"
                    >
                      {{ i18n.page.activeStatus }}</orgos-chip
                    >
                    <orgos-chip
                      *ngIf="row.shiftplanStatus === 'inactive'"
                      class="kenjo-text-align-center sspu-chip-font-color-333333"
                      [color]="'#EFEFEF'"
                    >
                      {{ i18n.page.inactive }}</orgos-chip
                    >
                    <orgos-chip
                      *ngIf="row.shiftplanStatus === 'incomplete'"
                      class="kenjo-text-align-center sspu-chip-font-color-7a4f01"
                      [color]="'#FFFAEB'"
                    >
                      {{ i18n.page.incomplete }}</orgos-chip
                    >
                  </orgos-column>
                </orgos-column-container>
              </ng-container>
            </orgos-table-smart-column>
            <!-- Locations -->
            <orgos-table-smart-column columnKey="locations" [header]="i18n.page.locationsHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <div [matTooltip]="row.locations" matTooltipClass="sspu-custom-tooltip" class="sspu-text-container">
                  {{ row.locations }}
                </div>
              </ng-container>
            </orgos-table-smart-column>
            <!-- Roles -->
            <orgos-table-smart-column columnKey="roles" [header]="i18n.page.rolesHeader" [sortable]="true">
              <orgos-column-container orgosColumn="2" *orgosTableSmartCell="let row">
                <div orgosColumn="0" *ngFor="let role of row.roles| slice: 0:2">
                  <orgos-chip
                    [hoverButton]="true"
                    [color]="role?.colour || 'Disabled'"
                    class="kenjo-font-size-14px kenjo-mr-10px kenjo-text-align-center"
                  >
                    <kenjo-simple-truncate-text class="sspu-truncate-text">{{ role?.name }}</kenjo-simple-truncate-text>
                  </orgos-chip>
                </div>
                <div
                  orgosColumn="0"
                  class="sspu-tag-circle"
                  [class.hide]="row.roles?.length <= 2"
                  [matTooltip]="row.roles | tooltipRolesEmployee"
                  matTooltipClass="sspu-custom-tooltip"
                >
                  <div>+ {{ row.roles?.length - 2 }}</div>
                </div>
              </orgos-column-container>
            </orgos-table-smart-column>
            <!-- Working Areas -->
            <orgos-table-smart-column columnKey="workingAreas" [header]="i18n.page.workingAreasHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <div class="sspu-text-container" [matTooltip]="row.workingAreas" matTooltipClass="sspu-custom-tooltip">
                  {{ row.workingAreas }}
                </div>
              </ng-container>
            </orgos-table-smart-column>
            <!-- Tags -->
            <orgos-table-smart-column columnKey="tags" [header]="i18n.page.tagsHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <div class="sspu-text-container" [matTooltip]="row.tags" matTooltipClass="sspu-custom-tooltip">{{ row.tags }}</div>
              </ng-container>
            </orgos-table-smart-column>
            <!-- Reporting to -->
            <orgos-table-smart-column columnKey="reportsTo.displayName" [header]="i18n.page.reportsToHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <div class="sspu-truncate-text kenjo-mh-10px" [matTooltip]="row.reportsTo?.displayName" [style.max-width]="'150px'">
                  {{ row.reportsTo?.displayName }}
                </div>
              </ng-container>
            </orgos-table-smart-column>
            <!-- Employee status -->
            <orgos-table-smart-column columnKey="employee.status" [header]="i18n.page.statusHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <orgos-chip
                  *ngIf="row.employee.status === 'active'"
                  class="kenjo-text-align-center kenjo-font-color-white-ffffff"
                  [color]="'Success'"
                >
                  {{ i18n.page.activeStatus }}</orgos-chip
                >
                <orgos-chip
                  *ngIf="row.employee.status === 'notActivated'"
                  class="d-status-field kenjo-text-align-center kenjo-font-color-white-ffffff"
                  [color]="'Warning'"
                >
                  {{ i18n.page.notActivatedStatus }}</orgos-chip
                >
                <orgos-chip
                  *ngIf="row.employee.status === 'deactivated'"
                  class="d-status-field kenjo-text-align-center kenjo-font-color-light-text-757575"
                  [color]="'Neutral'"
                >
                  {{ i18n.page.deactivatedStatus }}</orgos-chip
                >
              </ng-container>
            </orgos-table-smart-column>
            <!-- Company -->
            <orgos-table-smart-column columnKey="company.name" [header]="i18n.page.companyHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <kenjo-truncate-text> {{ row.company?.name }}</kenjo-truncate-text>
              </ng-container>
            </orgos-table-smart-column>
            <!-- Office -->
            <orgos-table-smart-column columnKey="office.name" [header]="i18n.page.officeHeader" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <kenjo-truncate-text> {{ row.office?.name }}</kenjo-truncate-text>
              </ng-container>
            </orgos-table-smart-column>
          </orgos-table-smart>

          <orgos-column-container
            *ngIf="!tableLoading && paginationConfiguration.totalOfRecords > 25 "
            class="sspu-pagination"
            centerColumns="true"
          >
            <orgos-column></orgos-column>
            <orgos-column size="0">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-light-text-757575"
                  >{{ i18n.page.displaying }}</orgos-column
                >
                <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px sspu-list-num-pages-container">
                  <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
                    <orgos-column-container centerColumns="true">
                      <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-text-333333 sspu-list-records-page"
                        >{{ queryOptions.recordsPerPage }}</orgos-column
                      >
                      <orgos-column size="0">
                        <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                      </orgos-column>
                    </orgos-column-container>
                  </div>
                  <mat-menu #paginationMenu="matMenu">
                    <button *ngFor="let page of recordsToShowSelector" mat-menu-item (click)="changeRecordsToShow(page)">
                      <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ page }}</orgos-column>
                    </button>
                  </mat-menu>
                </orgos-column>
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pl-10px"
                  >{{ i18n.page.itemsOutOf }}</orgos-column
                >
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-10px"
                  >{{ paginationConfiguration.totalOfRecords }}</orgos-column
                >
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="0" class="kenjo-mh-40px">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-mr-10px sspu-list-pagination-icon"
                    [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                    (click)="moveToPage(PAGE_SELECTOR['first'])"
                    >first_page</mat-icon
                  >
                </orgos-column>
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-mr-10px sspu-list-pagination-icon"
                    [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                    (click)="moveToPage(PAGE_SELECTOR['previous'])"
                    >keyboard_arrow_left</mat-icon
                  >
                </orgos-column>
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-10px">
                  {{ queryOptions.page }} / {{ paginationConfiguration.numberOfPages }}
                </orgos-column>
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-ml-10px sspu-list-pagination-icon"
                    [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === paginationConfiguration.numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== paginationConfiguration.numberOfPages }"
                    (click)="moveToPage(PAGE_SELECTOR['next'])"
                    >keyboard_arrow_right</mat-icon
                  >
                </orgos-column>
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-ml-10px sspu-list-pagination-icon"
                    [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === paginationConfiguration.numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== paginationConfiguration.numberOfPages }"
                    (click)="moveToPage(PAGE_SELECTOR['final'])"
                    >last_page</mat-icon
                  >
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column></orgos-column>
          </orgos-column-container>
        </orgos-container>
      </orgos-column>
    </orgos-column-container>
  </div>
</div>
