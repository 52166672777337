import { Component, Inject, Injector, Input, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateFreshdeskService } from '@app/private/services/private-freshdesk.service';
import { IPaywallOptions } from '@app/standard/components/feature-gating/services/feature-gating.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-default-paywall',
  templateUrl: 'default-paywall.dialog.html',
  styleUrls: ['default-paywall.dialog.scss'],
})
export class DefaultPaywallDialog implements OnInit {
  @Input() featureId: string;
  @Input() showCloseDialogButton: boolean = true;

  private BILLING_URL = 'settings/billing';
  pageTranslations: { [translationKey: string]: string };
  canAccessBillingPage: boolean;
  paywallOptions: IPaywallOptions; // depending on the value of featureId of paywallOptions and the translations found at "feature-gating.json" we will show different options in this component

  constructor(
    public dialogRef: MatLegacyDialogRef<DefaultPaywallDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: IPaywallOptions,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.paywallOptions = { ...this.data };
    if (this.featureId) {
      this.paywallOptions.featureId = this.featureId;
    }

    this.getTranslations();
    if (this.injector.get(CloudRoutesService).checkRoute(this.BILLING_URL) === true) {
      this.canAccessBillingPage = true;
    }
  }

  async getTranslations(): Promise<void> {
    this.pageTranslations = await this.injector.get(InternationalizationService).getAllTranslation('feature-gating');
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  navigateToBilling(): void {
    if (this.canAccessBillingPage) {
      this.injector.get(Router).navigate([`/cloud/${this.BILLING_URL}`]);
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('click on see plans', { category: 'Feature Gating', subcategory1: this.featureId });
    }
    this.closeDialog();
  }

  openRequestFeature(header: string, body: string): void {
    this.injector.get(PrivateFreshdeskService).openRequestFeatureFreshDeskWidget(header, body);
  }
}
