import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { Routes } from '@angular/router';
import { ActivateShiftPlanDialog } from '@app/cloud-features/shift-plan/components/activate-shift-plan/activate-shift-plan.dialog';
import { ShiftPlanEmployeeLocations } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-location/shift-plan-employee-locations.component';
import { ShiftPlanEmployeeRoles } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-roles/shift-plan-employee-roles.component';
import { ShiftPlanEmployeeTags } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-tags/shift-plan-employee-tags.component';
import { ShiftPlanEmployeeWorkingAreas } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-working-areas/shift-plan-employee-working-areas.component';
import { InputSearchShiftPlanComponent } from '@app/cloud-features/shift-plan/components/input-search-shift-plan/input-search-shift-plan.component';
import { InputShiftplanDatePickerComponent } from '@app/cloud-features/shift-plan/components/input-shiftplan-date-picker/input-shiftplan-date-picker.component';
import { IsWeekendPipe } from '@app/cloud-features/shift-plan/components/is-weekend/is-weekend.pipe';
import { ResizableDirective } from '@app/cloud-features/shift-plan/components/resizable-directive/resizable.directive';
import { ShiftPlanPaginatorComponent } from '@app/cloud-features/shift-plan/components/shift-plan-paginator/shift-plan-paginator.component';
import { SplitNamePipe } from '@app/cloud-features/shift-plan/components/split-name/split-name.pipe';
import { TooltipRolesEmployeePipe } from '@app/cloud-features/shift-plan/components/tooltip-roles-employee-table/tooltip-roles-employee-table.pipe';
import { AddShiftDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/add-shift-dialog/add-shift.dialog';
import { WorkingDayCircle } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/add-shift-dialog/working-day-circle/working-day-circle.component';
import { AddTemplateDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/add-template-dialog/add-template.dialog';
import { ShiftPlanAttendanceCardComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/attendance-card/shiftplan-attendance-card.component';
import { ClearScheduleDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/clear-schedule-dialog/clear-schedule-dialog';
import { CopyShiftsDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/copy-shifts-dialog/copy-shifts-dialog';
import { CustomDeleteDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/custom-delete-dialog/custom-delete-dialog';
import { DeleteShiftDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/delete-shift-dialog/delete-shift.dialog';
import { GeneratePDFDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/generate-pdf-dialog/generate-pdf-dialog';
import { InputCapacityFormControlComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/input-capacity-time-form-control/input-capacity-time-form-control.component';
import { InputCapacityTimeComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/input-capacity-time/input-capacity-time.component';
import { InputShiftPlanSearchUserComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/input-shiftplan-search-user/input-shiftplan-search-user.component';
import { InputShiftPlanUserComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/input-shiftplan-user/input-shiftplan-user.component';
import { LoadingScreenComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/loading-screen/loading-screen.component';
import { NotificationToggleComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/notification-toggle/notification-toggle.component';
import { ShiftPlanPublicHolidayListComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/public-holiday-list/shift-plan-public-holiday-list.component';
import { ShiftPlanPublicHolidayComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/public-holiday/shift-plan-public-holiday.component';
import { PublishShiftDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/publish-shift-dialog/publish-shift-dialog';
import { ScrollToTopComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/scroll-to-top/scroll-to-top.component';
import { ShiftCardComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-card/shift-card.component';
import { ShiftPlanCapacityComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-capacity/shift-plan-capacity.component';
import { ShiftPlanEmployeeDisplayComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-employee-display/shift-plan-employee-display.component';
import { ShiftCardPDFComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-pdf-template/components/shift-card-pdf/shift-card-pdf.component';
import { ShiftplanPdfFooterComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-pdf-template/components/shiftplan-pdf-footer/shiftplan-pdf-footer.component';
import { ShiftplanPdfHeaderComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-pdf-template/components/shiftplan-pdf-header/shiftplan-pdf-header.component';
import { ShiftplanPdfLabelComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-pdf-template/components/shiftplan-pdf-label/shiftplan-pdf-label.component';
import { ShiftplanPdfRowComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-pdf-template/components/shiftplan-pdf-row/shiftplan-pdf-row.component';
import { ShiftplanPdfTimeoffComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-pdf-template/components/shiftplan-pdf-timeoff/shiftplan-pdf-timeoff.component';
import { ShiftPlanTemplatePDFComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-pdf-template/shift-plan-pdf-template.component';
import { ShiftPlanSelectShiftsComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-select-shifts/shift-plan-select-shifts.component';
import { ShiftPlanTemplateDetailsComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-template-details/shift-plan-template-details.component';
import { ShiftPlanTemplatePanelComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-template-panel/shift-plan-template-panel.component';
import { ShiftplanTemplateSortSettings } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-template-panel/shiftplan-template-sort-settings/shiftplan-template-sort-settings.component';
import { ShiftPlanTemplateComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shift-plan-template/shift-plan-template.component';
import { ShiftplanMonthlyPickerComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shiftplan-monthly-picker/shiftplan-monthly-picker.component';
import { ShiftplanWeeklyPickerComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shiftplan-weekly-picker/shiftplan-weekly-picker.component';
import { ShiftplanWishDayComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/shiftplan-wish-day/shiftplan-wish-day.component';
import { ShiftPlanTimeOffCardComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/time-off-card/shift-plan-time-off-card.component';
import { ShiftPlanTimeOffDetailsComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/time-off-details/shift-plan-time-off-details.component';
import { ShiftPlanTimeOffListComponent } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/time-off-list/shift-plan-time-off-list.component';
import { ShiftPlanCapacityDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/dialogs/shift-plan-capacity.dialog';
import { SchedulesShiftPlanPage } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/schedules-shift-plan.page';
import { SettingsShiftPlanApprovalsDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-approvals/dialogs/settings-shift-plan-approvals-dialog';
import { SettingsShiftPlanApprovals } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-approvals/settings-shift-plan-approvals';
import { SettingsShiftPlanBreaks } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-breaks/settings-shift-plan-breaks';
import { SettingsShiftPlanGeneral } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-general/settings-shift-plan-general';
import { SettingsShiftPlanLocations } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-locations/settings-shift-plan-locations';
import { SettingsShiftPlanNotifications } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-notifications/settings-shift-plan-notifications';
import { SettingsShiftPlanOptions } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-options/settings-shift-plan-options';
import { SettingsShiftPlanRoleDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-roles/dialogs/add-edit-role-dialog';
import { SettingsShiftPlanRoles } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-roles/settings-shift-plan-roles';
import { SettingsShiftPlanSelect } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-select/settings-shift-plan-select';
import { SettingsShiftPlanTagDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-tags/dialogs/add-edit-tag-dialog';
import { SettingsShiftPlanTags } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-tags/settings-shift-plan-tags';
import { SettingsShiftPlanUserPage } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-users/settings-shift-plan-users';
import { SettingsShiftPlanViewOptions } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-view-options/settings-shift-plan-view-options';
import { SettingsShiftPlanWishDays } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-wish-days/settings-shift-plan-wish-days';
import { SettingsShiftPlanWorkingAreasDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-working-areas/dialogs/add-edit-working-areas-dialog';
import { SettingsShiftPlanWorkingAreas } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-working-areas/settings-shift-plan-working-areas';
import { SettingsShiftPlanOpenShifts } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shiftplan-open-shifts/settings-shift-plan-open-shifts';
import { SettingsShiftPlanPage } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/settings-shift-plan.page';
import { FullScreenHeaderModule } from '@app/common-components/full-screen-header/full-screen-header.module';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { RangeDatepickerModule } from '@app/common-components/kenjo-range-datepicker/range-datepicker.module';
import { PermissionsPipeModule } from '@app/common-components/permissions-pipe/permissions-pipe.module';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { WarningMessageModule } from '@app/common-components/warning-message/warning-message.module';
import { WorkingHoursModule } from '@app/common-components/working-hours/working-hours.module';
import { DirectivesModule } from '@app/standard/directives/directives.module';

export const EXTERNAL_MODULES = [
  TruncateTextModule,
  WorkingHoursModule,
  IconModule,
  GoogleMapsModule,
  FullScreenHeaderModule,
  WarningMessageModule,
  ReactiveFormsModule,
  PermissionsPipeModule,
  RangeDatepickerModule,
  DirectivesModule,
];
export const PAGES = [SettingsShiftPlanPage, SchedulesShiftPlanPage];

const GENERATE_PDF_COMPONENTS = [
  ShiftPlanTemplatePDFComponent,
  ShiftCardPDFComponent,
  ShiftplanPdfHeaderComponent,
  ShiftplanPdfFooterComponent,
  ShiftplanPdfLabelComponent,
  ShiftplanPdfRowComponent,
  ShiftplanPdfTimeoffComponent,
];

export const COMPONENTS = [
  SettingsShiftPlanApprovals,
  SettingsShiftPlanGeneral,
  SettingsShiftPlanLocations,
  SettingsShiftPlanOptions,
  SettingsShiftPlanViewOptions,
  SettingsShiftPlanWishDays,
  SettingsShiftPlanRoles,
  SettingsShiftPlanBreaks,
  SettingsShiftPlanTags,
  SettingsShiftPlanUserPage,
  SettingsShiftPlanNotifications,
  WorkingDayCircle,
  ShiftPlanPaginatorComponent,
  ShiftCardComponent,
  SettingsShiftPlanSelect,
  InputSearchShiftPlanComponent,
  InputShiftPlanSearchUserComponent,
  ActivateShiftPlanDialog,
  ShiftPlanEmployeeLocations,
  ShiftPlanEmployeeWorkingAreas,
  ShiftPlanEmployeeRoles,
  ShiftPlanEmployeeTags,
  InputCapacityTimeComponent,
  InputCapacityFormControlComponent,
  ShiftPlanEmployeeDisplayComponent,
  ShiftPlanTemplateComponent,
  ShiftPlanTemplatePanelComponent,
  ShiftPlanTemplateDetailsComponent,
  SettingsShiftPlanWorkingAreas,
  SettingsShiftPlanOpenShifts,
  ScrollToTopComponent,
  InputShiftPlanUserComponent,
  NotificationToggleComponent,
  InputShiftplanDatePickerComponent,
  ShiftplanWeeklyPickerComponent,
  ResizableDirective,
  ShiftPlanSelectShiftsComponent,
  ShiftPlanTimeOffCardComponent,
  ShiftPlanTimeOffDetailsComponent,
  ShiftPlanTimeOffListComponent,
  LoadingScreenComponent,
  SplitNamePipe,
  TooltipRolesEmployeePipe,
  IsWeekendPipe,
  ShiftPlanCapacityComponent,
  ShiftplanMonthlyPickerComponent,
  ShiftPlanPublicHolidayListComponent,
  ShiftPlanPublicHolidayComponent,
  ShiftPlanAttendanceCardComponent,
  ShiftplanTemplateSortSettings,
  ShiftplanWishDayComponent,
  ...GENERATE_PDF_COMPONENTS,
];

export const DIALOGS = [
  InputCapacityFormControlComponent,
  ShiftPlanCapacityDialog,
  SettingsShiftPlanApprovalsDialog,
  SettingsShiftPlanRoleDialog,
  SettingsShiftPlanTagDialog,
  AddShiftDialog,
  AddTemplateDialog,
  PublishShiftDialog,
  ShiftCardComponent,
  SettingsShiftPlanWorkingAreasDialog,
  DeleteShiftDialog,
  CopyShiftsDialog,
  ClearScheduleDialog,
  CustomDeleteDialog,
  GeneratePDFDialog,
];

export const PROVIDERS = [SplitNamePipe];

export const ROUTES: Routes = [
  { path: '', redirectTo: 'schedules', pathMatch: 'full' },
  { path: 'schedules', component: SchedulesShiftPlanPage },
  { path: 'settings/:section', component: SettingsShiftPlanPage },
  { path: 'settings', component: SettingsShiftPlanPage },
];
