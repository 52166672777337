import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable()
export class AppStatusService {
  private APP_STATUS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/app-status`;

  constructor(private injector: Injector) {}

  async getAppStatus(): Promise<IAppStatus> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        return undefined;
      }

      const headers = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const result = await this.injector
        .get(HttpClient)
        .get<IAppStatus>(`${this.APP_STATUS_URL}`, { headers })
        .toPromise();
      return result;
    } catch (error) {
      this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, AppStatusService.name, 'getAppStatus');
      return undefined;
    }
  }
}

export interface IAppStatus {
  latestDeployedVersion: string;
  newActiveNotificationsAvailable: false;
}
