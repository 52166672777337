import { Injectable, Injector } from '@angular/core';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { DocumentService } from '@app/standard/services/document/document.service';
import { MeetingService } from '@app/standard/services/performance-management/meeting.service';
import { CandidateService } from '@app/standard/services/recruiting/candidate.service';
import { PositionCandidateService } from '@app/standard/services/recruiting/position-candidate.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TaskHelperService {
  private taskPermissions: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private mapUserPersonalById: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private allActiveUserPersonal: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  private mapCandidatesById: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private mapPositionCandidateById: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private mapMeetingsById: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private mapDocumentsById: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private injector: Injector) {}

  public refreshData(): void {
    this.injector
      .get(PrivateSecurityService)
      .getPermissionsForCollection('task')
      .then((taskPermissions: any) => {
        this.taskPermissions.next(taskPermissions);
      })
      .catch(() => {
        // Do nothing
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, true)
      .then((allUserPersonal) => {
        const map = _.keyBy(allUserPersonal, '_id');
        this.mapUserPersonalById.next(map);
      })
      .catch(() => {
        // Do nothing
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(true)
      .then((allActiveUserPersonal) => {
        const sortedData = _.sortBy(allActiveUserPersonal, 'displayName');
        this.allActiveUserPersonal.next(sortedData);
      })
      .catch(() => {
        // Do nothing
      });
    this.refreshRecruitingData();

    this.injector
      .get(MeetingService)
      .getAllMeetings()
      .then((allMeetings) => {
        const map = _.keyBy(allMeetings, '_id');
        this.mapMeetingsById.next(map);
      })
      .catch(() => {
        // Do nothing
      });

    this.injector
      .get(DocumentService)
      .find({ _id: { $ne: null } })
      .then((allDocuments) => {
        const map = _.keyBy(allDocuments, '_id');
        this.mapDocumentsById.next(map);
      })
      .catch(() => {
        // Do nothing
      });
  }

  public getTaskPermissions(): Observable<any> {
    return this.taskPermissions.asObservable();
  }

  public getMapUserPersonalById(): Observable<any> {
    return this.mapUserPersonalById.asObservable();
  }

  public getAllActiveUserPersonal(): Observable<Array<any>> {
    return this.allActiveUserPersonal.asObservable();
  }

  public getMapCandidatesById(): Observable<any> {
    return this.mapCandidatesById.asObservable();
  }

  public getMapPositionCandidateById(): Observable<any> {
    return this.mapPositionCandidateById.asObservable();
  }

  public getMapMeetingsById(): Observable<any> {
    return this.mapMeetingsById.asObservable();
  }

  public getMapDocumentsById(): Observable<any> {
    return this.mapDocumentsById.asObservable();
  }

  private refreshRecruitingData() {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

    if (loggedUser.profileKey === 'restricted') {
      return;
    }

    this.injector
      .get(CandidateService)
      .getCandidates()
      .then((allCandidates) => {
        const map = _.keyBy(allCandidates, '_id');
        this.mapCandidatesById.next(map);
      })
      .catch(() => {
        // Do nothing
      });

    this.injector
      .get(PositionCandidateService)
      .find({ _id: { $ne: null } })
      .then((myPositionCandidates) => {
        const map = _.keyBy(myPositionCandidates, '_id');
        this.mapPositionCandidateById.next(map);
      })
      .catch(() => {
        // Do nothing
      });
  }
}
