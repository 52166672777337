<div>
  <mat-accordion displayMode="flat" togglePosition="before">
    <mat-expansion-panel [expanded]="hasPreviousAttachments" class="torac-expansion-panel" (opened)="onOpenExpansionPanel()">
      <mat-expansion-panel-header>
        <mat-panel-title class="torac-expansion-title">
          <ng-container *ngIf="requestPermissions?.canCreateAttachment === true">
            <span class="kenjo-font-color-light-text-757575 kenjo-font-size-14px">{{ translations.addAttachment }}</span>
            <mat-icon [matTooltip]="translations.attachmentInfoTooltip | i18nData: { size: DOCUMENTS_MAX_SIZE, num: DOCUMENTS_MAX_NUM }" class="kenjo-cursor-default kenjo-ml-10px kenjo-font-size-16px kenjo-font-color-primary-5993e3">help</mat-icon>
          </ng-container>
          <ng-container *ngIf="!requestPermissions?.canCreateAttachment" [matTooltip]="translations.noUploadAttachmentPermission">
            <span class="kenjo-font-color-light-text-757575 kenjo-font-size-14px">{{ translations.attachments }}</span>
          </ng-container>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div *ngFor="let attachment of attachments; let index = index; let last = last" [ngClass]="{ 'torac-border-bottom': !last }">
          <orgos-column-container class="torac-attachment-element">
            <orgos-column size="0" class="kenjo-mr-10px">
              <kenjo-icon *ngIf="!attachment.hasError" [size]="18" class="kenjo-font-color-success-00b72e kenjo-cursor-default">done</kenjo-icon>
              <kenjo-icon *ngIf="attachment.hasError" [size]="18" class="kenjo-font-color-danger-ff5757 kenjo-cursor-default">error_outline</kenjo-icon>
            </orgos-column>
            <orgos-column size="0" class="kenjo-mr-10px">
              <kenjo-icon [size]="18" class="kenjo-font-color-light-text-757575 kenjo-cursor-default">files_empty</kenjo-icon>
            </orgos-column>
            <orgos-column size="1" class="kenjo-mr-10px torac-attachment-name">
              <kenjo-truncate-text>{{ attachment.name }}</kenjo-truncate-text>
              <div *ngIf="attachment.hasError" class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">
                <ng-container *ngIf="attachment.error === attachmentError.MAX_SIZE">{{ translations.maxFileSize | i18nData: { size: DOCUMENTS_MAX_SIZE } }}</ng-container>
                <ng-container *ngIf="attachment.error === attachmentError.DUPLICATED">{{ translations.duplicatedFile }}</ng-container>
                <ng-container *ngIf="attachment.error === attachmentError.FORMAT">{{ translations.incorrectFileFormat }}</ng-container>
              </div>
            </orgos-column>
            <orgos-column size="0" class="torac-attachment-size">
              {{ attachment.size }}
            </orgos-column>
            <orgos-column size="0" class="kenjo-ml-10px">
              <kenjo-icon *ngIf="requestPermissions?.canDeleteAttachment === true || attachment.isNew" [size]="18" class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer" (click)="onDelete(index, attachment)">delete</kenjo-icon>
              <kenjo-icon *ngIf="!requestPermissions?.canDeleteAttachment && !attachment.isNew" [size]="18" class="kenjo-font-color-disabled-c4c4c4 kenjo-cursor-default" [matTooltip]="translations.noDeleteAttachmentPermission" matTooltipPosition="above">delete</kenjo-icon>
            </orgos-column>
          </orgos-column-container>
        </div>
        <div [style.display]="requestPermissions?.canCreateAttachment === true ? 'block' : 'none'">
          <div [style.display]="attachments.length < DOCUMENTS_MAX_NUM && hasError === false ? 'flex' : 'none'" #documentDropZone id="documentDropZone" class="torac-document-drop-zone" [class.torac-drag-over]="draggingOver">
            <ng-container *ngIf="uploadingFiles === false && draggingOver === false">
              <div>
                {{ translations.chooseFile1 }} <a (click)="onUpload()">{{ translations.chooseFileLink }}</a> {{ translations.chooseFile2 }}
              </div>
            </ng-container>
            <ng-container *ngIf="uploadingFiles === false && draggingOver === true">
              <div>{{ translations.dropFile }}</div>
            </ng-container>
            <ng-container *ngIf="uploadingFiles === true" class="kenjo-m-20px">
              <mat-progress-bar *ngIf="uploadingFiles === true" [value]="progress" class="torac-progress-bar"></mat-progress-bar>
            </ng-container>
          </div>
          <div *ngIf="attachments.length >= DOCUMENTS_MAX_NUM" class="kenjo-pl-10px kenjo-pv-10px kenjo-font-color-light-text-757575 kenjo-text-align-center">
            {{ translations.maxNumFiles | i18nData: { num: DOCUMENTS_MAX_NUM } }}
          </div>
        </div>
        <div *ngIf="!requestPermissions?.canCreateAttachment" class="torac-document-drop-zone kenjo-font-color-light-text-757575">
          {{ translations.noUploadAttachmentPermission }}
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
