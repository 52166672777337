import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IWorkScheduleTemplateModel } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { WorkScheduleTemplateService } from '@app/cloud-features/settings-attendance/services/work-schedule-template.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { StandardServicesRegistry } from '@app/standard/services/standard-services.registry';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { INACTIVE_REASON_DEACTIVATED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-update-user-work-dialog',
  templateUrl: 'update-user-work-dialog.dialog.html',
  styleUrls: ['update-user-work-dialog.dialog.scss'],
})
export class UpdateUserWorkDialog implements OnInit {
  saving: boolean = false;
  translation: any = {};
  userIds: Array<string> = [];
  dialogTitle: string;
  workPlaces: Array<ISelectOption> = [];
  workPlaceId: string | Array<string> = null;
  operation: string;
  usersWork: Array<any> = [];

  reportsToActions: Array<ISelectOption> = [];
  reportsToUsers: Array<any> = [];
  reportsToSelectedAction: string = 'replaceCurrent';
  userWork: GenericCacheModel;
  descendantsById: any = {};

  timeOffApproverUsers: Array<any> = [];
  selectedTimeOffApprover: string = '';
  employeeAvatars: Array<{ displayName: string; _photo: IFileMetadata }> = [];
  employeeAvatarsTooltip: string;

  userWorksSelected: Array<string> = [];

  workScheduleTemplates: Array<any> = [];
  workScheduleTemplateOptions: Array<ISelectOption> = [];
  workScheduleTemplateId: string = null;
  workScheduleTemplateSelected: IWorkScheduleTemplateModel = null;
  translatedWeekdays: Array<string> = [];

  constructor(
    public dialogRef: MatLegacyDialogRef<UpdateUserWorkDialog>,
    private injector: Injector,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.translatedWeekdays = this.injector.get(InternationalizationService).getTranslatedWeekdays();
    this.operation = this.data.operation;
    this.data.users.forEach((user: any) => {
      this.userIds.push(user._id);
      let userWorkName: string;
      switch (this.operation) {
        case 'reportsTo':
          userWorkName = user.manager;
          this.employeeAvatars.push({ displayName: user.displayName, _photo: user._photo });
          break;
        case 'timeOffApprover':
          this.employeeAvatars.push({ displayName: user.displayName, _photo: user._photo });
          break;
        case 'area':
        case 'team':
          const collection = user[`${this.operation}Names`];
          if (collection && collection.length > 0) {
            collection.forEach((model: string) => {
              if (this.operation !== 'workScheduleTemplate' && this.userWorksSelected.indexOf(model) === -1) {
                this.userWorksSelected.push(model);
              }
            });
          }
          return;
        default:
          userWorkName = user[`${this.operation}Name`];
          break;
      }
      // This array allows to preselect an option if all the selected users have the same workplace/manager
      if (this.operation !== 'workScheduleTemplate' && this.userWorksSelected.indexOf(userWorkName) === -1) {
        this.userWorksSelected.push(userWorkName);
      }
    });

    if (this.operation !== 'workScheduleTemplate') {
      const query = {
        _id: {
          $in: this.userIds,
        },
      };
      this.injector
        .get(UserWorkService)
        .find(query, false)
        .then((usersWork) => {
          this.usersWork = usersWork;
        })
        .catch(() => {
          //
        });
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('update-user-work-dialog')
      .then((translation: any) => {
        this.translation = translation;
        if (this.operation === 'workScheduleTemplate') {
          return;
        }
        const data = {
          operation: this.translation[this.operation].toLowerCase(),
          for: ['reportsTo', 'timeOffApprover'].includes(this.operation) ? this.translation.for : '',
        };
        this.dialogTitle = this.injector.get(I18nDataPipe).transform(this.translation.title, data);
      })
      .catch(() => {
        this.translation = {};
      });

    if (this.operation === 'reportsTo') {
      this.injector
        .get(InternationalizationService)
        .getAllTranslation('standard-picklists')
        .then((translation: any) => {
          if (check.not.assigned(translation) || check.not.assigned(translation.peopleBulkAssignmentReportsToAction)) {
            return;
          }
          this.reportsToActions.push({
            name: translation.peopleBulkAssignmentReportsToAction['replaceCurrent'],
            value: 'replaceCurrent',
          });
          this.reportsToActions.push({
            name: translation.peopleBulkAssignmentReportsToAction['removeCurrent'],
            value: 'removeCurrent',
          });
        })
        .catch(() => {
          this.translation = {};
        });
    }

    let callToWorkSubCollection;
    let callToDeactivatedUsers;
    const promisesToManage = [];

    switch (this.operation) {
      case 'reportsTo':
        callToWorkSubCollection = this.injector.get(UserPersonalService).getAllUserPersonal(false, false);
        const callToDescendants = this.injector.get(UserWorkService).getDescendantsById({ _id: this.userIds });
        callToDeactivatedUsers = this.injector.get(UserAccountService).find({ inactiveReason: INACTIVE_REASON_DEACTIVATED }, false);
        promisesToManage.push(callToDescendants);
        break;
      case 'timeOffApprover':
        callToWorkSubCollection = this.injector.get(UserPersonalService).getAllUserPersonal(false, false);
        callToDeactivatedUsers = this.injector.get(UserAccountService).find({ inactiveReason: INACTIVE_REASON_DEACTIVATED }, false);
        break;
      case 'workScheduleTemplate':
        callToWorkSubCollection = this.injector.get(WorkScheduleTemplateService).getWorkScheduledTemplates();
        break;
      default:
        const serviceClass = this.injector
          .get(StandardServicesRegistry)
          .getService(`${this.operation.charAt(0).toUpperCase()}${this.operation.slice(1)}`);
        callToWorkSubCollection = this.injector.get<any>(serviceClass).getData();
        break;
    }
    promisesToManage.push(callToWorkSubCollection);
    if (check.assigned(callToDeactivatedUsers)) {
      promisesToManage.push(callToDeactivatedUsers);
    }

    let deactivatedUserIds;
    Promise.all(promisesToManage)
      .then((results) => {
        switch (this.operation) {
          case 'reportsTo':
            this.descendantsById = results[0];
            deactivatedUserIds = results[2]?.map((userAccount) => userAccount._id);
            let managerId: string;
            results[1].forEach((user: any) => {
              if (
                check.assigned(this.userWorksSelected) &&
                this.userWorksSelected.length === 1 &&
                this.userWorksSelected.includes(user.displayName)
              ) {
                managerId = user._id;
              }
              // Excludes the selected and deactivated users
              if (!this.userIds.includes(user._id) && !deactivatedUserIds?.includes(user._id)) {
                const userInfo = {
                  _id: user._id,
                  displayName: user.displayName,
                  _photo: user._photo,
                  isActive: true,
                };
                this.reportsToUsers.push(userInfo);
              }
            });
            // Hack to allow reuse user-search
            this.userWork = new GenericCacheModel(this.injector, { reportsToId: managerId }, UserWorkService, '');
            this.userWork.data = {
              reportsToId: managerId,
            };
            return;
          case 'timeOffApprover':
            deactivatedUserIds = results[1]?.map((userAccount) => userAccount._id);
            results[0].forEach((user: any) => {
              // Excludes the selected and deactivated users
              if (!this.userIds.includes(user._id) && !deactivatedUserIds?.includes(user._id)) {
                const userInfo = {
                  _id: user._id,
                  displayName: user.displayName,
                  _photo: user._photo,
                  isActive: true,
                };
                this.timeOffApproverUsers.push(userInfo);
              }
            });
            // repeat the hack from reportsTo
            this.userWork = new GenericCacheModel(this.injector, {}, UserWorkService, '');
            break;
          case 'workScheduleTemplate':
            this.workScheduleTemplates = results[0];
            this.workScheduleTemplates.forEach((template, index) => {
              if (index === 0) {
                this.workScheduleTemplateId = template._id;
                this.workScheduleTemplateSelected = template;
              }
              this.workScheduleTemplateOptions.push({ name: template.name, value: template._id });
            });
            break;
          case 'team':
          case 'area':
            this.workPlaceId = [];
            results[0].forEach((workPlace: any) => {
              const workPlaceInfo = {
                name: workPlace.name,
                value: workPlace._id,
              };
              this.workPlaces.push(workPlaceInfo);
              if (check.assigned(this.userWorksSelected) && this.userWorksSelected.includes(workPlaceInfo.name)) {
                (this.workPlaceId as string[]).push(workPlaceInfo.value);
              }
            });
            return;
          default:
            results[0].forEach((workPlace: any) => {
              const workPlaceInfo = {
                name: workPlace.name,
                value: workPlace._id,
              };
              this.workPlaces.push(workPlaceInfo);
              if (
                check.assigned(this.userWorksSelected) &&
                this.userWorksSelected.length === 1 &&
                this.userWorksSelected.includes(workPlaceInfo.name)
              ) {
                this.workPlaceId = workPlaceInfo.value;
              }
            });
            return;
        }
      })
      .catch(() => {
        //
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveWorkPlace(): void {
    this.saving = true;
    if (this.operation === 'reportsTo') {
      this.userWork.data.reportsToId = this.reportsToSelectedAction === 'removeCurrent' ? null : this.userWork.data.reportsToId;
    }

    const userWorksToUpdate = this.usersWork.map((userWork) => {
      switch (this.operation) {
        case 'reportsTo':
          if (
            check.not.assigned(this.descendantsById[userWork._id]) ||
            (check.assigned(this.descendantsById[userWork._id]) &&
              check.nonEmptyArray(this.descendantsById[userWork._id]) &&
              this.descendantsById[userWork._id].includes(this.userWork.data.reportsToId) === false)
          ) {
            userWork.reportsToId = this.userWork.data.reportsToId;
          }
          break;
        case 'timeOffApprover':
          // this.userWork holds the selected timeoffapprover value
          userWork.timeOffApproverId = this.userWork.data;
          break;
        case 'team':
        case 'area':
          const values = userWork[`${this.operation}Ids`];
          userWork[`${this.operation}Ids`] =
            values !== undefined
              ? (this.workPlaceId as string[]).filter((e: string, i: number, a: Array<string>) => {
                  return check.not.assigned(a.find((itemId, j) => i !== j && e === itemId));
                })
              : this.workPlaceId;
          break;
        default:
          userWork[`${this.operation}Id`] = this.workPlaceId;
          break;
      }
      delete userWork.virtualOffice;
      return this.injector.get(UserWorkService).updateById(userWork._id, userWork);
    });

    let updatedInfo;
    if (this.operation === 'reportsTo') {
      const userToUpdate = this.reportsToUsers.find((user) => {
        return user._id === this.userWork.data.reportsToId;
      });
      updatedInfo = check.assigned(userToUpdate) && check.assigned(userToUpdate.displayName) ? userToUpdate.displayName : undefined;
    } else if (this.operation === 'timeOffApprover') {
      const approverUser = this.timeOffApproverUsers.find((user) => {
        return user._id === this.userWork.data;
      });
      updatedInfo = check.assigned(approverUser) && check.assigned(approverUser.displayName) ? approverUser.displayName : undefined;
    } else {
      const userWorkToUpdate = this.workPlaces.find((workPlace) => {
        return workPlace.value === this.workPlaceId;
      });
      updatedInfo = check.assigned(userWorkToUpdate) && check.assigned(userWorkToUpdate.name) ? userWorkToUpdate.name : undefined;
    }

    Promise.all(userWorksToUpdate)
      .then(() => {
        this.saving = false;
        const operationInfo = {
          operation: this.operation,
          name: ['area', 'team'].includes(this.operation)
            ? this.workPlaces
                .filter((e) => this.workPlaceId.includes(e.value))
                .map((e) => e.name)
                .join(', ')
            : updatedInfo,
        };
        this.dialogRef.close(operationInfo);
      })
      .catch(() => {
        this.saving = false;
      });
  }

  public saveTemplate(): void {
    this.saving = true;
    let workSchedule;

    this.injector
      .get(UserWorkScheduleService)
      .getWorkScheduleByTemplateId(this.workScheduleTemplateId)
      .then((workSchedules) => {
        const userIdsToExclude = workSchedules.map((iWorkSchedule) => {
          return iWorkSchedule._id;
        });

        const userIdsToAssign = this.userIds.filter((userId) => {
          return !userIdsToExclude.includes(userId);
        });

        workSchedule = this.workScheduleTemplates.find((iWorkSchedule) => {
          return iWorkSchedule._id === this.workScheduleTemplateId;
        });

        if (
          check.not.assigned(userIdsToAssign) ||
          check.emptyArray(userIdsToAssign) ||
          check.not.assigned(workSchedule) ||
          check.emptyObject(workSchedule)
        ) {
          return;
        }
        const createTemplateInfo = {
          userIds: userIdsToAssign,
          entry: workSchedule,
        };
        return this.injector.get(UserWorkScheduleService).createScheduleEntries(createTemplateInfo);
      })
      .then(() => {
        this.saving = false;
        const operationInfo = {
          operation: this.operation,
          name: workSchedule.name,
        };
        this.dialogRef.close(operationInfo);
      })
      .catch(() => {
        this.saving = false;
      });
  }

  public changeWorkSchedule(templateId) {
    this.workScheduleTemplateSelected = this.workScheduleTemplates.find((template) => {
      return template._id === templateId;
    });
  }

  onTimeOffApproverChange(event: any): void {
    this.selectedTimeOffApprover = event;
  }
}
