<orgos-container aspect="card" [routerLink]="['/cloud/documents/templates', template._id]">
  <orgos-column-container class="dtcc-preview-icon-container" centerColumns="true">
    <orgos-column size="0" class="dtcc-preview-icon">
      <mat-icon>documents_menu</mat-icon>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-border-top" centerColumns="true">
    <orgos-column size="0" class="dtcc-extension-container">
      <div class="dtcc-extension-text kenjo-font-color-white-ffffff kenjo-font-size-12px kenjo-text-transform-uppercase">{{ template._file?._fileExtension }}</div>
    </orgos-column>
    <orgos-column size="0" class="dtcc-name-container kenjo-p-10px">
      <kenjo-truncate-text>{{ template.name }}</kenjo-truncate-text>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
