<orgos-column-container centerColumns="true" [class.cc-white]="isWhiteColor">
  <orgos-column size="0" *ngIf="imageUrl || addInitialsIfNoImage === true" class="cc-image-container">
    <img [src]="imageUrl" class="cc-image" *ngIf="imageUrl" />
    <div class="name-user" *ngIf="!imageUrl && addInitialsIfNoImage === true">
      <div>{{ nameInitials }}</div>
    </div>
  </orgos-column>
  <orgos-column size="1" class="kenjo-mh-10px kenjo-mv-5px">
    <ng-content></ng-content>
  </orgos-column>
  <orgos-column *ngIf="buttonIconName && !hoverButton" size="0" class="kenjo-mr-10px kenjo-mv-5px cc-button">
    <kenjo-icon [size]="16" class="kenjo-cursor-pointer" (click)="onClick($event)">{{ buttonIconName }}</kenjo-icon>
  </orgos-column>
  <orgos-column *ngIf="buttonIconName && hoverButton" size="0">
    <div class="kenjo-cursor-pointer cc-hoverable-button" (click)="onClick($event)">
      <kenjo-icon [size]="16" class="cc-hoverable-button-icon">{{ buttonIconName }}</kenjo-icon>
    </div>
  </orgos-column>
</orgos-column-container>
