import { Component, Injector, Input, OnInit } from '@angular/core';
import { VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { IPublicHolidays } from '@app/cloud-features/shift-plan/services/shift-plan-public-holidays.service';
import { FormatDatePipe } from '@app/standard/components/format-date/format-date.pipe';

@Component({
  selector: 'kenjo-shiftplan-pdf-header',
  templateUrl: './shiftplan-pdf-header.component.html',
  styleUrls: ['./shiftplan-pdf-header.component.scss'],
})
export class ShiftplanPdfHeaderComponent implements OnInit {
  rangeName: string = '';
  views = { ...VIEWS };

  @Input() currentDays: Array<moment.Moment>;
  @Input() holidays: Array<Array<IPublicHolidays>> = [];
  @Input() frequency: 'week' | 'month';
  constructor(private injector: Injector) {}

  ngOnInit() {
    this.setRangeName();
  }

  setRangeName() {
    if (this.frequency === this.views.WEEK) {
      return;
    }
    this.rangeName = this.injector.get(FormatDatePipe).transform(this.currentDays?.[0], 'MMMM');
  }
}
