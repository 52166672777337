import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot } from '@angular/router';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';

@Injectable({
  providedIn: 'root'
})
export class CloudFeaturesGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(private injector: Injector) {}

  canLoad(route: Route): boolean {
    const path = `${route.path}(\/.*)?`;
    return this.executeGuard(path);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const path = `${route.routeConfig.path}(\/.*)?`;
    return this.executeGuard(path);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let path = state.url.replace('/cloud/', '').split('?')[0];

    Object.keys(route.params).forEach((paramKey) => {
      const paramValue = route.params[paramKey];

      path = path.replace(paramValue, `:${paramKey}`);
    });

    return this.executeGuard(path);
  }

  private executeGuard(path: string): boolean {
    return this.injector.get(CloudRoutesService).checkRoute(path);
  }
}
