<div *ngIf="errorTranslation" class="icn-container" [ngClass]="{ 'icn-input-error': hasErrors || forceError }">
  <input
    type="number"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    (blur)="onBlur($event)"
    [required]="required"
    [disabled]="readOnly"
    [step]="1"
    [min]="min"
    [max]="max"
    autocomplete="off"
    [ngStyle]="{ width: width ? width + 'px' : '60px' }"
  />
  <ng-container *ngIf="hasErrors && errorMessage">
    <kenjo-icon [size]="15" class="kenjo-mh-5px kenjo-font-color-danger-ff5757">error_outline</kenjo-icon>
    <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757 kenjo-inline-block">{{ errorMessage }}</div>
  </ng-container>
</div>
