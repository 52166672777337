<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogTitle" (clickCloseButton)="closeDialog()">
  <div class="kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-mb-20px">{{ dialogTranslation.reminderMessage | i18nData: ({ minutesBeforeReminder: ((minutesBeforeReminder ? minutesBeforeReminder : 0) | duration) })}}</div>

  <textarea class="kenjo-textarea" name="breakReminderMessageField" id="breakReminderMessageField" #breakReminderMessageField="ngModel" matInput [(ngModel)]="breakReminderMessage" [rows]="6" [required]="true"></textarea>

  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{miscTranslation.closeButton}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="save()" color="Success" [disabled]="breakReminderMessageField.invalid && breakReminderMessageField.errors.required">
        {{dialogTranslation.saveButton}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
