import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kenjo-toggle-button',
  templateUrl: 'toggle-button.component.html',
  styleUrls: ['toggle-button.component.scss']
})
export class ToggleButtonComponent {
  @Input() icon: string;
  @Input() selected = false;
  @Input() disabled = false;
}
