import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as check from 'check-types';

import { GenericCacheModel } from '../../../core/generic-cache-model';
import { InputValidation } from '../../../core/validation/input-validation';
import { CostCenterService } from '../../../services/company/cost-center.service';
import { InternationalizationService } from '../../../services/core/internationalization.service';

@Component({
  selector: 'orgos-create-cost-center-dialog',
  templateUrl: 'create-cost-center.dialog.html',
  styleUrls: ['create-cost-center.dialog.scss'],
})
export class CreateCostCenterDialog implements OnInit {
  dialogTranslation: any = {};

  costCenter: GenericCacheModel;

  constructor(public dialogRef: MatLegacyDialogRef<CreateCostCenterDialog>, private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('create-cost-center-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.costCenter = new GenericCacheModel(this.injector, { costCenterId: '', name: '' }, CostCenterService, '');
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addCostCenter(): void {
    this.dialogRef.close(this.costCenter.data);
  }
}
