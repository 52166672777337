<ng-container *ngIf="audienceOptions">
  <div>
    <mat-form-field class="kenjo-block">
      <mat-label kenjoRemoveAriaOwns>{{ selectEntityLabel }}</mat-label>
      <mat-select multiple [value]="selectValue" [disabled]="disabled" (selectionChange)="updateSelectionOfEntity($event.value)">
        <ng-container *ngFor="let entity of ['companies', 'offices', 'areas', 'departments', 'teams']">
          <mat-optgroup [label]="translation[entity]" *ngIf="audienceOptions[entity]?.length > 0">
            <mat-option *ngFor="let item of audienceOptions[entity]" [value]="item.value" class="kenjo-pl-30px">{{ item.name }}</mat-option>
          </mat-optgroup>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="kenjo-mt-10px" *ngIf="includeEmployees">
    <orgos-search [placeholder]="selectUserLabel" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event">
      <orgos-text class="orgos-search-error" color="Warn">{{ translation.dialogNotFoundPeopleError }}</orgos-text>

      <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="assignUser(result._id)">
        <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
      </orgos-search-option>

      <orgos-column-container wrap="true" class="orgos-search-selection-container">
        <orgos-column size="0" *ngFor="let result of searchResults" class="kenjo-m-10px">
          <orgos-chip
            [imageUrl]="result._photo?._url"
            buttonIconName="clear"
            [onClickButtonFunction]="removeUser(result._id)"
            [addInitialsIfNoImage]="true"
            [chipText]="result.displayName"
            >{{ result.displayName }}</orgos-chip
          >
        </orgos-column>
      </orgos-column-container>
    </orgos-search>
  </div>
</ng-container>
