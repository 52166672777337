<mat-form-field *ngIf="model && field && !readOnly" [class.mat-form-field-invalid]="forceError">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [id]="inputId"
    [type]="hidePassword ? 'password' : 'text'"
    [ngModel]="getValue()"
    (ngModelChange)="setValue($event)"
    [required]="required"
    [minlength]="minlength"
    [maxlength]="maxlength"
    autocomplete="off"
  />
  <mat-icon matSuffix (click)="hidePassword = !hidePassword; $event.stopPropagation()" class="ipc-visibility-button">{{
    hidePassword ? 'visibility' : 'visibility_off'
  }}</mat-icon>
</mat-form-field>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [id]="inputId"
    [type]="hidePassword ? 'password' : 'text'"
    [ngModel]="getValue()"
    readOnly
    disabled
    [required]="required"
    autocomplete="off"
  />
  <mat-icon matSuffix (click)="hidePassword = !hidePassword; $event.stopPropagation()" class="ipc-visibility-button">{{
    hidePassword ? 'visibility' : 'visibility_off'
  }}</mat-icon>
</mat-form-field>
<div *ngIf="!isValueValid || forceError" class="ipc-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
