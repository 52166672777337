<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogTitle" (clickCloseButton)="closeDialog()" *ngIf="breakReminderSettings">
  <div class="kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-mb-20px">{{ breakReminderSettings.breakReminderMessage }}</div>
  <div class="abd-break-time-container">
    <div class="abd-info kenjo-font-color-text-333333 kenjo-font-size-14px kenjo-mr-40px">
      {{ dialogTranslation.breakTimeOn | i18nData: ({ dayOfWeek: (timeEntryDay | date:'EEEE':'UTC'), date: (timeEntryDay | date:'MMMM d':'UTC') }) }}
    </div>
    <div>
      <orgos-input-simple-time [forceError]="breakTime >= trackedTime" [value]="breakTime" (valueChange)="breakTime = $event" [label]="dialogTranslation.breakTime" [keepOriginalMinWidth]="true" [enableClearButton]="true"></orgos-input-simple-time>
      <div class="abd-edit-message" *ngIf="breakTime >= trackedTime">
        <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{dialogTranslation.breakTimeCannotBeLonger}}</div>
      </div>
    </div>
  </div>

  <orgos-warning-message *ngIf="!breakTime || breakTime === 0" class="kenjo-mt-20px">
    {{dialogTranslation.yourShiftLengthExceedsMax | i18nData: ({ hoursAndMinutes: (breakReminderSettings.minutesBeforeReminder | duration)})}}
  </orgos-warning-message>

  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column></orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px" *ngIf="breakTime && breakTime > 0">
      <orgos-button-raised [disabled]="breakTime >= trackedTime" (click)="save()" color="Success">
        {{dialogTranslation.saveButton}}
      </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px" *ngIf="!breakTime || breakTime === 0">
      <orgos-button-raised (click)="closeDialog()" color="Danger">
        {{dialogTranslation.saveWithoutBreak}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
