import { Location } from '@angular/common';
import { Component, EventEmitter, Injector, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';

import { I18nDataPipe } from '../../../components/i18n-data/i18n-data.pipe';
import { IWorkflowFilter } from '../../../components/workflow-filter/interface/workflow-filter';
import { GenericCacheModel } from '../../../core/generic-cache-model';
import { ISelectOption } from '../../../core/select-option';
import { InputValidation } from '../../../core/validation/input-validation';
import { StandardServicesRegistry } from '../../../services/standard-services.registry';
import { AddWorkflowTriggerDialog } from '../add-workflow-trigger-dialog/add-workflow-trigger.dialog';

@Component({
  selector: 'orgos-edit-workflow',
  templateUrl: 'edit-workflow.page.html',
  styleUrls: ['edit-workflow.page.scss'],
})
export class EditWorkflowPage implements OnInit, OnDestroy {
  pageTranslation: any = {};
  reports: Array<any>;
  selectDelayInstantOptions: Array<ISelectOption> = [];
  objectSelectOptions: Array<ISelectOption> = [];
  delayNumberValidation: InputValidation;
  nameValidation: InputValidation;
  delayInstantValidation: InputValidation;
  chosenCollection: string;
  availableUseCases: Array<any> = [];
  chosenUseCase: any;
  listCarouselItems: Array<Array<any>>;
  clarificationText: string = '';
  objectTranslation: any;
  collectionFields: Array<any>;
  filtersAreComplete: boolean = true;
  showSummary: boolean = false;
  isRecurringWorkflow: boolean = false;
  recurringEndsOptions: Array<ISelectOption>;
  recurringRepeatOptions: Array<ISelectOption>;
  everyXOptions: Array<ISelectOption>;
  monthlySpecificDateOptions: Array<ISelectOption>;
  monthlySpecificWeekOptions: Array<ISelectOption>;
  weekdayOptions: Array<ISelectOption>;
  recurringEnds: 'never' | 'on' = 'never';
  minDate = new Date();
  monthlyVariant: 'specificDate' | 'certainDayInWeek' = 'specificDate';
  filters: Array<{ conditions: Array<IWorkflowFilter> }> = [];

  // To add use cases or collections, follow these steps
  // 1. Add the collection to standardUseCases
  // 2. Ensure that the service of that collection has the getModel implemented
  // 3. Add the services to MAP_COLLECTION_TO_SERVICE
  // 4. Add the translation to xxxCollection in the backend (at edit-workflow.page.json), where xxx is the collection name
  // 5. In workflow-summary.page.ts add the collection to initCollectionFields in the map collection to service
  // 6. If you want to have email templates for the new object, add the collection to listCollections in edit-email-template.page.ts
  standardUseCases: any = {
    user: [
      {
        selected: false,
        labelKey: 'dependingOnStartDate',
        subtitleKey: '',
        inCollection: 'user',
        serviceName: 'User',
        type: 'scheduled',
        immediate: false,
        referenceDateField: 'userWork.startDate',
      },
      {
        selected: false,
        labelKey: 'dependingOnTerminationDate',
        subtitleKey: '',
        inCollection: 'user',
        serviceName: 'User',
        type: 'scheduled',
        immediate: false,
        referenceDateField: 'userConfidential.terminationDate',
      },
    ],
    document: [
      {
        selected: false,
        labelKey: 'dependingOnValidUntil',
        subtitleKey: '',
        inCollection: 'document',
        serviceName: 'Document',
        type: 'scheduled',
        immediate: false,
        referenceDateField: 'validUntil',
      },
    ],
    'time-off-request': [],
    task: [],
    feed: [],
    position: [],
    'position-candidate': [],
    project: [],
    'project-member': [],
    'project-time-entry': [],
  };

  MAP_COLLECTION_TO_SERVICE: object = {
    document: 'Document',
    feed: 'Feed',
    task: 'Task',
    'time-off-request': 'TimeOffRequest',
    user: 'User',
    'user-confidential': 'User',
    'user-home': 'User',
    'user-work': 'User',
    position: 'Position',
    candidate: 'Candidate',
    'position-candidate': 'PositionCandidate',
    project: 'Project',
    'project-member': 'ProjectMember',
    'project-time-entry': 'ProjectTimeEntry',
  };

  MAX_ITEMS_CAROUSEL: number = 10;
  USER_COLLECTIONS: Array<string> = [
    'user-account',
    'user-address',
    'user-work-schedule',
    'user-confidential',
    'user-emergency',
    'user-employment',
    'user-financial',
    'user-home',
    'user-personal',
    'user-salary',
    'user-work',
  ];

  @Input() customFieldsAvailable: Array<any>;
  @Input() workflow: GenericCacheModel;
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() create: EventEmitter<GenericCacheModel> = new EventEmitter<GenericCacheModel>();

  private backButtonSubscription: Subscription;

  constructor(
    private location: Location,
    private injector: Injector,
    private standardServicesRegistry: StandardServicesRegistry,
    private router: Router,
    public snackBar: MatLegacySnackBar,
    private dialog: MatLegacyDialog,
    private ngZone: NgZone
  ) {
    // When the user clicks on the back button of the Browser:
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.onBackClick(false);
      }
    }) as Subscription;
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    this.injector.get(GlobalBarService).setEnableDefaultBars(false, true);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-workflow-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);

        this.initObjectSelectOptions();
        this.initWorkflow();
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  private initObjectSelectOptions(): void {
    Object.keys(this.standardUseCases).forEach((iCollectionName) => {
      this.objectSelectOptions.push({
        name: this.pageTranslation[`${iCollectionName}Collection`],
        value: iCollectionName,
      });
    });

    this.recurringEndsOptions = [
      { name: this.pageTranslation['recurrenceEndsOn'], value: 'on' },
      { name: this.pageTranslation['recurrenceNeverEnds'], value: 'never' },
    ];
    this.recurringRepeatOptions = [
      { name: this.pageTranslation['repeatOnce'], value: 'once' },
      { name: this.pageTranslation['repeatDaily'], value: 'day' },
      { name: this.pageTranslation['repeatWeekly'], value: 'week' },
      { name: this.pageTranslation['repeatMonthly'], value: 'month' },
      { name: this.pageTranslation['repeatYearly'], value: 'year' },
    ];

    this.monthlySpecificDateOptions = [];
    for (let i = 1; i <= 28; i++) {
      this.monthlySpecificDateOptions.push({
        name: i.toString(),
        value: i,
      });
    }

    this.monthlySpecificWeekOptions = [
      { name: this.pageTranslation['firstWeek'], value: 'first' },
      { name: this.pageTranslation['2ndWeek'], value: '2' },
      { name: this.pageTranslation['3rdWeek'], value: '3' },
      { name: this.pageTranslation['4thWeek'], value: '4' },
      { name: this.pageTranslation['lastWeek'], value: 'last' },
    ];

    const translatedWeekdays = this.injector.get(InternationalizationService).getTranslatedWeekdays();
    this.weekdayOptions = translatedWeekdays.map((iTranslatedDay, index) => {
      if (index === 6) {
        return { name: iTranslatedDay, value: 0 };
      }
      return { name: iTranslatedDay, value: index + 1 };
    });
  }

  private initWorkflow(): void {
    const workflowServiceClass = this.standardServicesRegistry.getService('Workflow');
    if (
      check.assigned(this.workflow) &&
      check.assigned(this.workflow.data) &&
      check.nonEmptyObject(this.workflow.data) &&
      check.assigned(this.workflow.data._id) &&
      check.nonEmptyString(this.workflow.data._id)
    ) {
      // In case the workflow was already initialized
      this.initSelectOptions(false);

      // Set isRecurringWorkflow value
      this.isRecurringWorkflow = this.workflow.data.delayInstant === 'recurrent';

      if (this.workflow.data['inCollection'] === 'recurrent') {
        this.initEveryXOptions();
        if (this.workflow.data.scheduledOnSpecificDate.stopAfter) {
          this.recurringEnds = 'on';
        }

        // For recurring monthly workflows, initialize the options available
        if (
          this.workflow.data.scheduledOnSpecificDate.conditionsForEvery &&
          this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions &&
          this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek &&
          (this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onWeekday ||
            this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onWeekday === 0)
        ) {
          this.monthlyVariant = 'certainDayInWeek';
          this.setValueForRecurringMonthlySpecificDate();
        } else if (
          this.workflow.data.scheduledOnSpecificDate.conditionsForEvery &&
          this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions
        ) {
          this.setValueForRecurringMonthlySpecificWeek();
        }
        return;
      }

      // Find the chosen collection and select it
      this.selectCollection(this.workflow.data['inCollection']);
      const collectionToFind = check.contains(this.USER_COLLECTIONS, this.workflow.data['inCollection'])
        ? 'user'
        : this.workflow.data['inCollection'];

      // Find the use case
      this.chosenUseCase = this.availableUseCases.find((iUseCase) => {
        let type = this.workflow.data['type'];
        if (this.workflow.data['type'] === 'scheduled' && this.workflow.data['referenceDateField'] === '_createdAt') {
          type = 'create';
        } else if (this.workflow.data['type'] === 'scheduled' && this.workflow.data['referenceDateField'] === '_updatedAt') {
          type = 'update';
        }
        return (
          iUseCase.inCollection === this.workflow.data['inCollection'] &&
          iUseCase.type === type &&
          (check.not.assigned(this.workflow.data['referenceDateField']) ||
            iUseCase.referenceDateField === this.workflow.data['referenceDateField'])
        );
      });
      // If we don't find a standard use case, it is because the user created a custom one
      if (check.not.assigned(this.chosenUseCase) || check.not.object(this.chosenUseCase) || check.emptyObject(this.chosenUseCase)) {
        const fieldName = this.workflow.data['referenceDateField'];

        this.injector
          .get(InternationalizationService)
          .getAllTranslation(`${collectionToFind}-collection`)
          .then((objectTranslation) => {
            this.objectTranslation = objectTranslation;
            const customTrigger = this.createCustomTrigger(fieldName);
            this.standardUseCases[this.chosenCollection].push(customTrigger);
            this.initAvailableUseCases(this.chosenCollection);
            const daysVal = this.workflow.data['delayNumber'];
            const orderVal = this.workflow.data['delayInstant'];
            const immediate = this.workflow.data['immediate'];

            this.chosenUseCase = customTrigger;
            this.clarificationText = this.pageTranslation[this.chosenUseCase.labelKey]
              ? this.pageTranslation[this.chosenUseCase.labelKey]
              : this.chosenUseCase.labelKey;
            this.initSelectOptions(false);

            this.workflow.data['delayNumber'] = daysVal;
            if (check.assigned(immediate) && check.equal(true, immediate)) {
              this.workflow.data['delayInstant'] = 'immediately';
            } else {
              this.workflow.data['delayInstant'] = orderVal;
            }

            if (this.workflow.data['delayNumber'] === 0) {
              this.workflow.data['delayInstant'] = 'sameDay';
            }
            this.initFilters();
          })
          .catch(() => {
            this.objectTranslation = {};
          });
      } else {
        this.chosenUseCase.selected = true;
        const daysVal = this.workflow.data['delayNumber'];
        const orderVal = this.workflow.data['delayInstant'];
        const immediate = this.workflow.data['immediate'];
        this.selectUseCase(this.chosenUseCase.labelKey);

        this.workflow.data['delayNumber'] = daysVal;
        if (check.assigned(immediate) && check.equal(true, immediate)) {
          this.workflow.data['delayInstant'] = 'immediately';
        } else if (this.workflow.data['delayNumber'] === 0) {
          this.workflow.data['delayInstant'] = 'sameDay';
        } else {
          this.workflow.data['delayInstant'] = orderVal;
        }
        this.initFilters();
      }
      return;
    }
    const rawWorkflowData = {
      name: undefined,
      inCollection: undefined,
      type: undefined,
      isActive: false,
      conditions: undefined,
      immediate: true,
      delayInstant: undefined,
      delayNumber: undefined,
    };
    this.workflow = new GenericCacheModel(this.injector, rawWorkflowData, workflowServiceClass);
    this.initSelectOptions();
  }

  private initSelectOptions(resetWorkflowDaysAndWhen: boolean = true): void {
    this.selectDelayInstantOptions = [];
    if (check.not.assigned(this.workflow) || check.not.assigned(this.workflow.data)) {
      return;
    }

    if (resetWorkflowDaysAndWhen === true) {
      this.workflow['data']['delayInstant'] = undefined;
      this.workflow['data']['delayNumber'] = undefined;
    }
    if (check.assigned(this.chosenUseCase)) {
      if (
        check.assigned(this.chosenUseCase.type) &&
        check.nonEmptyString(this.chosenUseCase.type) &&
        check.equal('scheduled', this.chosenUseCase.type)
      ) {
        // Scheduled will not allow immediately, but it will allow before and after
        this.selectDelayInstantOptions.push({ name: this.pageTranslation.beforePicklistValue, value: 'before' });
        this.selectDelayInstantOptions.push({ name: this.pageTranslation.sameDayPicklistValue, value: 'sameDay' });
        this.selectDelayInstantOptions.push({ name: this.pageTranslation.afterPicklistValue, value: 'after' });
      } else if (
        check.assigned(this.chosenUseCase.type) &&
        check.nonEmptyString(this.chosenUseCase.type) &&
        (check.equal('update', this.chosenUseCase.type) || check.equal('create', this.chosenUseCase.type))
      ) {
        // Create and Update will not allow before, but they will allow immediately and after
        this.selectDelayInstantOptions.push({ name: this.pageTranslation.immediatelyAfterPicklistValue, value: 'immediately' });
        this.selectDelayInstantOptions.push({ name: this.pageTranslation.afterPicklistValue, value: 'after' });
      }
    }
  }

  selectCollection(collection: string): void {
    this.chosenCollection = check.contains(this.USER_COLLECTIONS, collection) ? 'user' : collection;
    this.initAvailableUseCases(this.chosenCollection);
  }

  private initAvailableUseCases(collection: string): void {
    this.chosenUseCase = null;
    this.availableUseCases = this.getGenericUseCases(collection);
    if (check.assigned(this.standardUseCases[collection]) && check.nonEmptyArray(this.standardUseCases[collection])) {
      this.availableUseCases = this.availableUseCases.concat(this.standardUseCases[collection]);
    }

    const inServiceName = this.MAP_COLLECTION_TO_SERVICE[collection];

    this.getFieldsForCollection(collection, inServiceName);
    this.buildCarousel();
  }

  // Returns the generic use cases record is created and record is updated
  private getGenericUseCases(collection: string): Array<any> {
    const collectionLabelKey = check.contains(this.USER_COLLECTIONS, collection) ? 'user' : collection;
    const translationData = {
      collectionName: this.pageTranslation[`${collectionLabelKey}Collection`]
        ? this.pageTranslation[`${collectionLabelKey}Collection`]
        : collectionLabelKey,
    };
    return [
      {
        selected: false,
        labelKey: this.injector.get(I18nDataPipe).transform(this.pageTranslation.recordIsCreated, translationData),
        subtitleKey: '',
        inCollection: collection,
        serviceName: this.MAP_COLLECTION_TO_SERVICE[collection],
        type: 'create',
        immediate: true,
        referenceDateField: '_createdAt',
      },
      {
        selected: false,
        labelKey: this.injector.get(I18nDataPipe).transform(this.pageTranslation.recordIsUpdated, translationData),
        subtitleKey: '',
        inCollection: collection,
        serviceName: this.MAP_COLLECTION_TO_SERVICE[collection],
        type: 'update',
        immediate: true,
        referenceDateField: '_updatedAt',
      },
    ];
  }

  private buildCarousel(): void {
    this.listCarouselItems = [];
    this.listCarouselItems.push([]);
    let currentIndex = 0;

    this.availableUseCases.forEach((iUseCase, index) => {
      if (check.assigned(iUseCase) && check.nonEmptyObject(iUseCase)) {
        if (this.MAX_ITEMS_CAROUSEL === this.listCarouselItems[currentIndex].length) {
          this.listCarouselItems.push([]);
          currentIndex++;
        }
        this.listCarouselItems[currentIndex].push(iUseCase);
      }
    });
  }

  selectUseCase(useCaseLabelKey: string): void {
    this.chosenUseCase = this.availableUseCases.find((iUseCase) => {
      return iUseCase.labelKey === useCaseLabelKey;
    });
    this.clarificationText = this.pageTranslation[this.chosenUseCase.labelKey]
      ? this.pageTranslation[this.chosenUseCase.labelKey]
      : this.chosenUseCase.labelKey;

    this.initSelectOptions();
    this.getFieldsForCollection();
  }

  private getFieldsForCollection(inCollection?: string, inServiceName?: string): void {
    let collectionName;
    if (check.assigned(inCollection) && check.string(inCollection) && check.nonEmptyString(inCollection)) {
      collectionName = inCollection;
    } else {
      collectionName = this.chosenUseCase.inCollection;
    }

    this.filters = [];
    this.filtersAreComplete = true;
    let serviceName;
    if (check.assigned(inServiceName) && check.string(inServiceName) && check.nonEmptyString(inServiceName)) {
      serviceName = inServiceName;
    } else {
      serviceName = this.chosenUseCase.serviceName;
    }

    const serviceClass = this.standardServicesRegistry.getService(serviceName);
    this.injector
      // tslint:disable-next-line: deprecation
      .get(serviceClass)
      .getModel()
      .then((fieldsResult: any) => {
        this.collectionFields = fieldsResult.filter((iField) => {
          let hideForDocumentCollection = false;
          if (
            collectionName === 'document' &&
            ((iField.type === 'Array' && iField.name === 'readBy') || (iField.type === 'Mixed' && iField.name === 'relatedTo'))
          ) {
            hideForDocumentCollection = true;
          }
          let hideForUserCollection = false;
          if (
            collectionName === 'user' &&
            iField.type === 'Array' &&
            iField.name !== 'user-work.areaIds' &&
            iField.name !== 'user-work.teamIds' &&
            iField.name === 'userHome.children'
          ) {
            hideForUserCollection = true;
          }
          return iField.type && iField.type !== 'Quill' && !hideForDocumentCollection && !hideForUserCollection;
        });
      })
      .catch((error) => {
        // Error is already shown
        this.collectionFields = [];
      });
    const collectionToFind =
      collectionName.startsWith('user-') && check.contains(this.USER_COLLECTIONS, collectionName) ? 'user' : collectionName;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation(`${collectionToFind}-collection`)
      .then((objectTranslation) => {
        this.objectTranslation = objectTranslation;
      })
      .catch((error) => {
        this.objectTranslation = {};
      });
  }

  evaluateDelayNumber(): void {
    if (
      check.assigned(this.workflow['rawData']) &&
      check.assigned(this.workflow['rawData']['delayInstant']) &&
      check.equal('immediately', this.workflow['rawData']['delayInstant'])
    ) {
      this.workflow['rawData']['delayNumber'] = undefined;
    } else if (
      check.assigned(this.workflow['rawData']['delayInstant']) &&
      check.equal('sameDay', this.workflow['rawData']['delayInstant'])
    ) {
      this.workflow['rawData']['delayNumber'] = 0;
    }
  }

  onBackClick(changeLocationState: boolean = true): void {
    this.backButtonSubscription.unsubscribe();

    this.injector.get(GlobalBarService).setEnableDefaultBars(true, changeLocationState);
    this.goBack.emit();
  }

  saveWorkflow(): void {
    if (
      !this.workflow['data']['delayInstant'] ||
      this.filtersAreComplete === false ||
      check.not.assigned(this.nameValidation) ||
      this.nameValidation.isValid() === false ||
      check.not.assigned(this.chosenCollection) ||
      !this.chosenUseCase ||
      (this.workflow['data']['delayInstant'] !== 'immediately' &&
        (check.not.assigned(this.delayInstantValidation) ||
          this.delayInstantValidation.isValid() === false ||
          (this.workflow['data']['delayInstant'] !== 'sameDay' &&
            (check.not.assigned(this.delayNumberValidation) ||
              this.delayNumberValidation.isValid() === false ||
              !this.workflow['data']['delayNumber'] ||
              this.workflow['data']['delayNumber'] === 0))))
    ) {
      return;
    }
    if (this.workflow['data']['delayInstant'] === 'immediately') {
      this.workflow.data['immediate'] = true;
    } else {
      this.workflow.data['immediate'] = false;
    }

    // Merge the workflow document with the chosenUseCase
    this.workflow.data['inCollection'] = this.chosenUseCase.inCollection;
    this.workflow.data['type'] = this.chosenUseCase.type;
    this.workflow.data['conditions'] = this.wrapConditionsBeforeSave();
    if (check.equal(true, this.workflow.data['immediate'])) {
      delete this.workflow.data['delayInstant'];
      delete this.workflow.data['delayType'];
      delete this.workflow.data['delayNumber'];
    } else if (check.equal(false, this.workflow.data['immediate'])) {
      this.workflow.data['delayType'] = 'days';
      this.workflow.data['type'] = 'scheduled';
    }
    if (check.equal('sameDay', this.workflow.data['delayInstant'])) {
      this.workflow.data['delayInstant'] = 'before';
      this.workflow.data['delayNumber'] = 0;
    }
    delete this.workflow.data['referenceDateField'];
    if (
      check.equal(false, this.workflow.data['immediate']) &&
      check.assigned(this.chosenUseCase.referenceDateField) &&
      check.nonEmptyString(this.chosenUseCase.referenceDateField)
    ) {
      this.workflow.data['referenceDateField'] = this.chosenUseCase.referenceDateField;
    }
    if (check.assigned(this.workflow['data']['_id']) && check.nonEmptyString(this.workflow['data']['_id'])) {
      const originalCollection = this.workflow.data['inCollection'];
      delete this.workflow.data['inCollection']; // Do not send it in the update
      this.workflow
        .updateInServer()
        .then(() => {
          this.snackBar.open(`${this.pageTranslation.updatedSnackbarMessage}`, 'OK', { duration: 5000 });
          this.workflow.data['inCollection'] = originalCollection;
          this.showSummary = true;
        })
        .catch((error) => {
          // error is already shown
        });
    } else {
      this.workflow
        .createInServer()
        .then((workflowResult) => {
          this.workflow.data._id = workflowResult.data._id;
          this.snackBar.open(`${this.pageTranslation.createdSnackbarMessage}`, 'OK', { duration: 5000 });
          this.injector.get(PrivateChurnzeroService).logSimpleEvent('NEW_CUSTOM_WORKFLOW_CREATED');
          this.create.emit(this.workflow);
        })
        .catch((error) => {
          // error is already shown
        });
    }
  }

  private wrapConditionsBeforeSave(): Array<any> {
    if (check.not.assigned(this.filters) || check.not.array(this.filters) || check.emptyArray(this.filters)) {
      return [];
    }

    return this.filters.map((iFilter) => {
      return iFilter.conditions.map((iCondition) => {
        const iResult = {
          fieldName: iCondition.field,
          operatorNew: iCondition.condition,
        };
        if (check.assigned(iCondition.value)) {
          if (iCondition.valueType === 'Date' && iCondition.condition === 'lte') {
            iCondition.value = moment.utc(iCondition.value).endOf('day').toISOString();
          }
          if (iCondition.field === '_startTime' || iCondition.field === '_endTime') {
            iCondition.value = this.convertTimeToMinutes(iCondition.value);
          }
          iResult['equalToValue'] = iCondition.value === true ? 'true' : iCondition.value.toString();
          iResult['equalToValueType'] = iCondition.valueType.toLowerCase();
        } else if (check.assigned(iCondition.valueType) && iCondition.condition === 'isChanged') {
          iResult['equalToValueType'] = iCondition.valueType.toLowerCase();
        }
        return iResult;
      });
    });
  }

  convertTimeToMinutes(value: string) {
    const [hours, minutes] = value.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  addFilter() {
    this.filters.push({ conditions: [] });
    this.addCondition(this.filters.length - 1);
  }

  addCondition(filterIndex: number) {
    if (
      check.not.assigned(this.selectCollection) ||
      check.emptyString(this.selectCollection) ||
      check.not.assigned(this.chosenUseCase) ||
      check.emptyObject(this.chosenUseCase)
    ) {
      return;
    }

    this.filters[filterIndex].conditions.push({
      id: `${this.filters[filterIndex].conditions.length}-${Math.random()}`,
      field: undefined,
      condition: undefined,
      value: undefined,
      valueType: undefined,
    });

    this.filtersAreComplete = false;
  }

  deleteFilter(index: number) {
    this.filters.splice(index, 1);
    this.filterUpdate();
  }

  addTrigger(): void {
    const dataForDialog = {
      collectionFields: this.collectionFields.filter((iField) => {
        return iField.type === 'Date';
      }),
      collectionTranslation: this.objectTranslation,
    };
    const dialogRef = this.dialog.open(AddWorkflowTriggerDialog, { data: dataForDialog });
    dialogRef.afterClosed().subscribe((fieldInCollection: any) => {
      if (check.assigned(fieldInCollection) && check.string(fieldInCollection) && check.nonEmptyString(fieldInCollection)) {
        const customTrigger = this.createCustomTrigger(fieldInCollection);
        this.standardUseCases[this.chosenCollection].push(customTrigger);
        this.unselectAllUseCases();
        customTrigger.selected = true;
        // this.availableUseCases = this.standardUseCases[this.chosenCollection];
        this.initAvailableUseCases(this.chosenCollection);
        this.buildCarousel();
        this.selectUseCase(customTrigger.labelKey);
      }
    });
  }

  private unselectAllUseCases(): void {
    this.availableUseCases.forEach((iUseCase) => {
      iUseCase['selected'] = false;
    });
  }

  private createCustomTrigger(fieldInCollection: string): any {
    let fieldTranslationKey = fieldInCollection;
    if (check.contains(fieldTranslationKey, '.') && fieldTranslationKey.startsWith('user-')) {
      // convert user-work to userWork, user-home to userHome, etc
      const userCategory = fieldTranslationKey.split('.')[0].split('user-')[1];
      const categoryFirstCapital = userCategory.charAt(0).toUpperCase() + userCategory.slice(1);
      fieldTranslationKey = `user${categoryFirstCapital}.${fieldTranslationKey.split('.')[1]}`;
    }
    const fieldLabel = this.objectTranslation[fieldTranslationKey] ? this.objectTranslation[fieldTranslationKey] : fieldTranslationKey;
    const fieldApiName =
      this.chosenCollection !== 'user' && check.contains(fieldInCollection, '.') === true
        ? fieldInCollection.split('.')[1]
        : fieldInCollection;
    const labelKey = `${this.pageTranslation.customTrigger} ${fieldLabel}`;
    const inServiceName = this.MAP_COLLECTION_TO_SERVICE[this.chosenCollection];

    // If this is a scheduled update, we must use the exact name of the user-xxx collection:
    const collection = this.chosenCollection.toLowerCase();

    // Add it to the carouselitems
    const customTrigger = {
      selected: true,
      labelKey: labelKey,
      subtitleKey: '',
      inCollection: collection,
      serviceName: inServiceName,
      type: 'scheduled',
      immediate: false,
      referenceDateField: fieldApiName,
    };
    return customTrigger;
  }

  private initFilters(): void {
    if (
      check.assigned(this.workflow.data['conditions']) &&
      check.array(this.workflow.data['conditions']) &&
      check.nonEmptyArray(this.workflow.data['conditions'])
    ) {
      this.filters = this.workflow.data['conditions'].map((iFilter) => {
        const conditions = iFilter.map((iCondition) => {
          let conditionValue = iCondition['equalToValue'];
          if (iCondition['equalToValueType'] === 'boolean') {
            conditionValue = iCondition['equalToValue'] === 'true' || iCondition['equalToValue'] === true ? true : false;
          }
          return {
            id: `${iFilter.length}-${Math.random()}`,
            field: iCondition['fieldName'],
            condition: iCondition['operatorNew'],
            value: conditionValue,
            valueType: iCondition['equalToValueType'],
          };
        });
        return { conditions };
      });
    }
  }

  deleteCondition(conditionId: string, filterIndex: number): void {
    const indexToRemove = this.filters[filterIndex]?.conditions.findIndex((condition) => condition?.id === conditionId);
    if (check.assigned(indexToRemove) && check.number(indexToRemove)) {
      this.filters[filterIndex].conditions.splice(indexToRemove, 1);

      if (this.filters[filterIndex].conditions.length === 0) {
        this.deleteFilter(filterIndex);
      }
    }

    this.filterUpdate();
  }

  filterUpdate(): void {
    this.filtersAreComplete = true;
    this.filters.forEach((iFilter) => {
      iFilter.conditions.forEach((iCondition) => {
        if (
          check.not.assigned(iCondition.field) ||
          check.emptyString(iCondition.field) ||
          check.not.assigned(iCondition.condition) ||
          check.emptyString(iCondition.condition) ||
          (['isChanged', 'isNotEmpty', 'isEmpty'].includes(iCondition.condition) === false && check.not.assigned(iCondition.value)) ||
          check.emptyObject(iCondition.value) ||
          check.not.assigned(iCondition.valueType) ||
          check.emptyString(iCondition.valueType)
        ) {
          this.filtersAreComplete = false;
          return;
        }
      });
    });
  }

  reloadWorkflowSettings(): void {
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, false);
    this.ngZone.run(() => {
      this.router.navigateByUrl('/cloud/settings/workflows', { replaceUrl: true });
    });
  }

  changeInRecuringWorkflow(isRecurring: any): void {
    this.isRecurringWorkflow = isRecurring.checked;
    if (this.isRecurringWorkflow) {
      this.workflow.data.scheduledOnSpecificDate = {
        period: 'year',
        conditionsForEvery: {
          everyX: 1,
        },
        startDate: new Date(),
        stopAfter: null,
      };
      this.recurringEnds = 'never';
      this.workflow.data.delayInstant = 'recurrent';
      this.workflow.data.type = 'scheduled';
      this.workflow.data.conditions = [];
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions = {};
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onWeeklyConditions = {};
    } else {
      this.workflow.data.scheduledOnSpecificDate = {};
      this.workflow.data.delayInstant = null;
    }
  }

  changePeriod(periodSelected: any): void {
    this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX = 1;
    this.workflow.data.scheduledOnSpecificDate.period = periodSelected;
    this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions = {};
    this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onWeeklyConditions = {};

    if (periodSelected === 'month') {
      this.monthlyVariant = 'specificDate';
      this.setValueForRecurringMonthlySpecificDate();
      this.setValueForRecurringMonthlySpecificWeek();
    } else if (periodSelected === 'week') {
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onWeeklyConditions.onWeekday = moment(
        this.workflow.data.scheduledOnSpecificDate.startDate
      ).day();
    }

    this.initEveryXOptions();
  }

  private setValueForRecurringMonthlySpecificWeek(): void {
    this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek = Math.ceil(
      moment(this.workflow.data.scheduledOnSpecificDate.startDate).date() / 7
    );
    if (this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek <= 1) {
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek = 'first';
    } else if (this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek > 4) {
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek = 'last';
    } else {
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek =
        this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek.toString();
    }
    this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onWeekday = moment(
      this.workflow.data.scheduledOnSpecificDate.startDate
    ).day();
  }

  private setValueForRecurringMonthlySpecificDate(): void {
    this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificDate = moment(
      this.workflow.data.scheduledOnSpecificDate.startDate
    ).date();
    if (this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificDate > 28) {
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificDate = 28;
    }
  }

  private initEveryXOptions(): void {
    const periodSelected = this.workflow.data.scheduledOnSpecificDate.period;
    this.everyXOptions = [];

    if (periodSelected === 'once' || periodSelected === 'year') {
      return;
    }

    const min = 1;
    let max = 11;
    if (periodSelected === 'day') {
      max = 30;
    }
    for (let i = min; i <= max; i++) {
      this.everyXOptions.push({
        name: i.toString(),
        value: i,
      });
    }
  }

  changeRecurringEnds(value: string): void {
    if (value === 'on') {
      this.setRecurringEndDate(new Date());
    } else {
      this.resetRecurringEndDate();
    }
  }

  setRecurringEndDate(endDate: Date): void {
    this.recurringEnds = 'on';
    this.workflow.data.scheduledOnSpecificDate.stopAfter = endDate;
  }

  resetRecurringEndDate(): void {
    this.recurringEnds = 'never';
    this.workflow.data.scheduledOnSpecificDate.stopAfter = null;
  }

  saveRecurringWorkflow(): void {
    if (!this.workflow.data.name) {
      return;
    }

    this.workflow.data.delayType = 'scheduledOnSpecificDate';
    this.workflow.data.delayNumber = 0;

    if (
      this.monthlyVariant === 'specificDate' &&
      this.workflow.data.scheduledOnSpecificDate &&
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery &&
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions
    ) {
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek = null;
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onWeekday = null;
    } else if (
      this.monthlyVariant === 'certainDayInWeek' &&
      this.workflow.data.scheduledOnSpecificDate &&
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery &&
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions
    ) {
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificDate = null;
    }
    this.workflow.data.scheduledOnSpecificDate.startDate = new Date(this.workflow.data.scheduledOnSpecificDate.startDate).toISOString();
    if (this.workflow.data.scheduledOnSpecificDate.stopAfter) {
      this.workflow.data.scheduledOnSpecificDate.stopAfter = new Date(this.workflow.data.scheduledOnSpecificDate.stopAfter).toISOString();
    }
    this.workflow.data.inCollection = 'recurrent';
    this.workflow.data.immediate = false;
    this.workflow.data.conditions = [];

    if (check.assigned(this.workflow['data']['_id']) && check.nonEmptyString(this.workflow['data']['_id'])) {
      const originalCollection = this.workflow.data['inCollection'];
      delete this.workflow.data['inCollection']; // Do not send it in the update
      this.workflow
        .updateInServer()
        .then(() => {
          this.snackBar.open(`${this.pageTranslation.updatedSnackbarMessage}`, 'OK', { duration: 5000 });
          this.workflow.data['inCollection'] = originalCollection;
          this.showSummary = true;
        })
        .catch((error) => {
          // error is already shown
        });
    } else {
      this.workflow
        .createInServer()
        .then((workflowResult) => {
          this.workflow.data._id = workflowResult.data._id;
          this.snackBar.open(`${this.pageTranslation.createdSnackbarMessage}`, 'OK', { duration: 5000 });
          this.injector.get(PrivateChurnzeroService).logSimpleEvent('NEW_CUSTOM_WORKFLOW_CREATED');
          this.create.emit(this.workflow);
        })
        .catch((error) => {
          // error is already shown
        });
    }
  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();
  }
}
