import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class UserSnapshotService implements IGenericService {
  private USER_SNAPSHOT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-snapshot-db`;

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IUserSnapshotModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserSnapshotService.name, 'create');
    error.message = 'UserSnapshot should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserSnapshotModel> {
    return new Promise<IUserSnapshotModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_SNAPSHOT_URL, id)
        .then((userSnapshot: IUserSnapshotModel) => {
          resolve(userSnapshot);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserSnapshotService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserSnapshotService.name, 'updateById');
    error.message = 'UserSnapshot should not be updated';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserSnapshotService.name, 'deleteById');
    error.message = 'UserSnapshot should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getUserSnapshots(userId: string): Promise<Array<IUserSnapshotModel>> {
    const findBody = {
      _userId: userId
    };

    return new Promise<Array<IUserSnapshotModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_SNAPSHOT_URL, findBody)
        .then((allUserSnapshot: Array<IUserSnapshotModel>) => {
          resolve(allUserSnapshot);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserSnapshotService.name, 'getUserSnapshotss'));
        });
    });
  }

  getPermissions(): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserSnapshotService.name, 'getPermissions');
    error.message = 'UserSnapshot should not define permissions';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getFieldsTranslations(): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserSnapshotService.name, 'getFieldsTranslations');
    error.message = 'UserSnapshot should not define fields translations';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_SNAPSHOT_URL);
  }
}

export interface IUserSnapshotModel {
  _id: string;
  _createdAt: Date;
  _updatedAt: Date;
  'user-account': any;
  'user-address': any;
  'user-confidential': any;
  'user-emergency': any;
  'user-financial': any;
  'user-home': any;
  'user-personal': any;
  'user-work': any;
  'user-work-schedule': any;
  company: any;
  office?: any;
  department?: any;
  costCenters?: Array<any>;
  profile: any;
}
