import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'kenjo-button-flat',
  templateUrl: 'button-flat.component.html',
  styleUrls: ['button-flat.component.scss']
})
export class ButtonFlatComponent {
  private _disabled: boolean = false;
  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.disabledClass = disabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() color: 'Primary' | 'Disabled' | 'Secondary' = 'Primary';

  @Input() tooltip: string;

  @Input() id: string = '';

  @HostBinding('class.bfc-disabled') disabledClass = false;
}
