<div overlay class="sid-overlay-container kenjo-border" [style.width]="width">
  <div class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-pt-20px kenjo-pb-10px kenjo-ph-20px kenjo-border-bottom">
    <div class="sid-overlay-header kenjo-pb-5px kenjo-border-bottom">
      <input
        #inputSearch
        class="kenjo-cursor-text kenjo-color-grey-background-lighter-f8f8f8"
        matInput
        [ngModel]="''"
        (ngModelChange)="searchByName($event)"
        (keydown.arrowDown)="navigateOptions('down')"
        (keydown.arrowUp)="navigateOptions('up')"
        (keydown.enter)="keyEnter()"
        autocomplete="off"
        [placeholder]="placeholder"/>
      <kenjo-icon [size]="24" class="sid-search-icon kenjo-mr-10px">search</kenjo-icon>
    </div>
  </div>
  <div class="sid-options sid-last">
    <ng-container *ngIf="filteredOptions.length > 0">
      <div
        *ngFor="let option of filteredOptions; last as isLast; first as isFirst"
        (click)="emitSelection(option.value)"
        [class.sid-active-option]="activeOptionValue && option.value === activeOptionValue"
        [class.sid-last]="isLast && !actionText"
        [class.kenjo-border-bottom]="!isLast"
        class="kenjo-color-white-background-ffffff kenjo-cursor-pointer sid-option-container">
        <div *ngIf="!option.subtitle && !option.rightColText; else extendedMode" class="sid-selectable-item">
          {{ option.name }}
        </div>
        <ng-template #extendedMode>
          <div class="sid-extended-option kenjo-pv-15px kenjo-ph-20px">
            <div class="left-col">
              <span class="kenjo-font-weight-bold">
                {{ option.name }}
              </span>
              <div *ngIf="option.subtitle" class="kenjo-font-size-12px kenjo-mt-5px">
                {{ option.subtitle }}
              </div>
            </div>
            <div class="right-col kenjo-ml-20px kenjo-font-weight-bold" *ngIf="option.rightColText">
              {{ option.rightColText }}
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <div *ngIf="filteredOptions.length === 0" [class.sid-last]="!actionText" class="sid-selectable-item kenjo-font-color-danger-ff5757 kenjo-color-white-background-ffffff">
      {{ emptyOptionsText }}
    </div>
    <div *ngIf="actionText" (click)="emitAction()" class="sid-selectable-item sid-last kenjo-font-color-primary-5993e3 kenjo-cursor-pointer kenjo-font-weight-bold kenjo-color-white-background-ffffff kenjo-border-top">{{ actionText }}</div>
  </div>
</div>
