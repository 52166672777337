<ng-container *ngIf="i18n && i18n.page">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
    {{parentTranslation.locationHeader}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333"
      >{{parentTranslation.workingAreasSettings}}</span
    >
  </div>
  <div class="kenjo-mt-30px kenjo-font-color-light-text-757575">{{i18n.page.workingAreasDescription}}</div>

  <orgos-action *ngIf="canCreateWorkingAreas && allWorkingAreas" icon="add_circle" class="kenjo-mt-30px" (click)="addWorkingArea()"
    >{{i18n.page.addWorkingAreaButton}}</orgos-action
  >
  <div *ngIf="allWorkingAreas.length === 0" class="kenjo-text-align-center kenjo-p-40px">
    <div class="kenjo-flex-horizontal kenjo-flex-center">
      <kenjo-icon [size]="16" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon
      ><span class="kenjo-font-size-16px">{{i18n.page.noWorkingAreasHeader}}</span>
    </div>
    <div class="kenjo-mt-20px kenjo-font-color-light-text-757575">{{i18n.page.noWorkingAreasDescription}}</div>
  </div>
  <kenjo-input-search-shift-plan
    *ngIf="allWorkingAreas && allWorkingAreas.length > 0 && mappedWorkingAreasPermissions"
    class="sspwa-working-areas-container"
    [list]="allWorkingAreas"
    [placeholder]="i18n.page.searchForWorkingAreas"
    (filteredResults)="filteredWorkingAreas = $event"
  ></kenjo-input-search-shift-plan>
  <orgos-container
    aspect="card"
    *ngIf="filteredWorkingAreas && filteredWorkingAreas.length > 0"
    class="kenjo-mt-20px kenjo-mb-10px sspwa-working-areas-container"
  >
    <orgos-table [fullHeight]="true" [data]="filteredWorkingAreas" [displayedColumns]="['name','actions']">
      <orgos-table-column columnKey="name" [header]="i18n.page.workingAreasNameColumn" [sortable]="true">
        <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
          <kenjo-truncate-text class="sspwa-working-areas-name">{{row.name}}</kenjo-truncate-text>
        </div>
      </orgos-table-column>
      <orgos-table-column columnKey="actions" [actionColumn]="true" cellWidth="15">
        <div *orgosTableCell="let row" class="kenjo-text-align-left">
          <kenjo-icon
            [size]="20"
            (click)="editWorkingArea(row)"
            [matTooltip]="mappedWorkingAreasPermissions[row._id] ? parentTranslation.noPermissionsTooltip : ''"
            matTooltipPosition="below"
            [class.sspwa-icon-disabled]="mappedWorkingAreasPermissions[row._id]"
            class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >mode_edit</kenjo-icon
          >
          <kenjo-icon
            [size]="20"
            (click)="validateIfWorkingAreaIsAttached(row)"
            [matTooltip]="mappedWorkingAreasPermissions[row._id] ? parentTranslation.noPermissionsTooltip : ''"
            matTooltipPosition="below"
            [class.sspwa-icon-disabled]="mappedWorkingAreasPermissions[row._id]"
            class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757 kenjo-ml-10px"
            >delete</kenjo-icon
          >
        </div>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>
  <kenjo-shift-plan-paginator
    class="sspwa-working-areas-container"
    [parentTranslation]="parentTranslation"
    [data]="allWorkingAreas"
    (recordsToShow)="setFilteredWorkingAreasToShow($event)"
  ></kenjo-shift-plan-paginator>
</ng-container>
