import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';

import { environment } from '../../../../../environments/environment';

export interface IBankHolidaysObject {
  holidayKey: string;
  holidayDate: string;
  holidayDuration: string | 'FullDay' | 'Morning' | 'Afternoon';
}

export interface ITimeOffObject {
  duration?: string | 'FullDay' | 'Morning' | 'Afternoon';
  timeOffs: Array<{ _timeOffTypeName: string; _timeOffTypeId: string; duration: number | string | 'FullDay' | 'Morning' | 'Afternoon'; isHourlyAbsence?: boolean }>;
}
export interface IExpectedHoursByDayObject {
  [key: string]: IExpectedHoursByDay;
}
export interface IExpectedHoursByDay {
  expectedTime: number;
  timeOffs?: ITimeOffObject;
  bankHolidays?: IBankHolidaysObject;
}

export interface IAttendanceSummaryResult {
  expectedHoursByDay: IExpectedHoursByDayObject;
  totalExpectedSoFar: number;
  totalExpectedMonth: number;
}

@Injectable({
  providedIn: 'root'
})
export class AttendanceSummaryController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/user-attendance/attendance-summary/`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) { }

  getExpectedHoursByUserMonth(userId: string, month: number, year: number, detailed: boolean = false): Promise<IAttendanceSummaryResult> {
    return new Promise<IAttendanceSummaryResult>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, AttendanceSummaryController.name, 'getExpectedHoursByUserMonth');
        reject(error);
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.URL}expected-hours/${userId}/${month}/${year}/${detailed}`, httpOptions)
        .toPromise()
        .then((resultData: any) => {
          resolve(resultData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryController.name, 'getExpectedHoursByUserMonth'));
        });
    });
  }

  getExpectedHoursByUserDateRange(userId: string, startDate: string, endDate: string, detailed: boolean = false): Promise<IAttendanceSummaryResult> {
    return new Promise<IAttendanceSummaryResult>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, AttendanceSummaryController.name, 'getExpectedHoursByUserDateRange');
        reject(error);
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.URL}range-expected-hours/${userId}/${startDate}/${endDate}/${detailed}/false`, httpOptions)
        .toPromise()
        .then((resultData: any) => {
          resolve(resultData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryController.name, 'getExpectedHoursByUserDateRange'));
        });
    });
  }

  async getAttendanceMonthInfoPDF(userId: string, month: number, year: number): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, AttendanceSummaryController.name, 'getAttendanceMonthInfoPDF');
      throw this.injector.get(ErrorManagerService).handleRawError(error);
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
      responseType: 'arraybuffer' as 'arraybuffer'
    };

    const options = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    try {
      return await this.http.post(`${this.URL}attendance-month-info-pdf/${userId}/${month}/${year}`, options, httpOptions).toPromise();
    } catch (error) {
      this.errorManager.handleRawError(error, AttendanceSummaryController.name, 'getAttendanceMonthInfoPDF');
    }
  }

}
