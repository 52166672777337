<mat-form-field *ngIf="!readOnly" [class.mat-form-field-invalid]="forceError">
  <mat-label>{{ label }}</mat-label>
  <orgos-input-time-form-control [maskPlaceholder]="maskPlaceholder" [value]="inputValue" (valueChange)="onValueChange($event)" (blurTimeInput)="onBlur()" [required]="required" [debounceNewValues]="debounceNewValues"></orgos-input-time-form-control>
  <mat-icon *ngIf="showClearButton && !showClock" matSuffix class="istc-clear-button kenjo-cursor-pointer" (click)="clearValue(); cleanError(); $event.stopPropagation()">clear</mat-icon>
  <mat-icon *ngIf="showClock" matSuffix>clock</mat-icon>
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput type="text" [ngModel]="inputStringValue" readOnly disabled [required]="required" />
  <mat-icon *ngIf="showClock" matSuffix>clock</mat-icon>
</mat-form-field>
<div *ngIf="!isValueValid || forceError" class="istc-error"><ng-content select="orgos-input-error"></ng-content></div>
