import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { EditTagDialog } from '@app/standard/pages/documents/documents-preferences/dialogs/edit-tag.dialog';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DocumentTagService, IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import { DocumentService } from '@app/standard/services/document/document.service';
import { ISmartDocsSettingsModel, SmartDocsSettingsService } from '@app/standard/services/document/smart-docs-settings.service';
import { SettingsBarService } from '@app/standard/services/settings/settings-bar.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-documents-preferences',
  templateUrl: 'documents-preferences.page.html',
  styleUrls: ['documents-preferences.page.scss'],
})
export class DocumentsPreferencesPage implements OnInit {
  pageTranslation: any = {};
  allDocumentTags: Array<IDocumentTagModel> = [];
  colors: any = userColorConstants;
  defaultTags: Array<GenericSimpleModel> = [];
  customTags: Array<GenericSimpleModel> = [];
  smartDocsSettings: ISmartDocsSettingsModel;
  defaultTagsEnabledMap = {};

  validations: { [key: string]: InputValidation } = {};

  constructor(
    private injector: Injector,
    private snackBar: MatLegacySnackBar,
    private dialog: MatLegacyDialog,
    private i18nDataPipe: I18nDataPipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    try {
      this.injector.get(GlobalBarService).setProgressBar(true);
      await this.fetchTranslations();
      await this.fetchData();
      await this.configureGlobalBar();
    } catch {}
  }

  async fetchTranslations() {
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('smart-docs-preferences-page');
    } catch {
      this.pageTranslation = {};
    }
  }

  private async fetchData(): Promise<void> {
    await Promise.all([this.fetchAllDocumentTags(), this.fetchSmartDocsSettings()]);
    this.injector.get(GlobalBarService).setProgressBar(false);
  }

  private async fetchAllDocumentTags() {
    try {
      const allDocumentTags = await this.injector.get(DocumentTagService).getSettingsDocumentsTags();

      this.allDocumentTags = allDocumentTags
        .filter((tag) => tag._id !== picklists.DOCUMENT_UNLABELLED_ID)
        .sort((iDocumentTagA: any, iDocumentTagB: any) => {
          if (iDocumentTagA.name.toLowerCase() < iDocumentTagB.name.toLowerCase()) {
            return -1;
          } else if (iDocumentTagA.name.toLowerCase() > iDocumentTagB.name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });

      this.customTags = [];
      this.defaultTags = [];

      this.allDocumentTags.forEach((iTag) => {
        if (iTag.predefined === true) {
          this.defaultTags.push(new GenericSimpleModel(this.injector, iTag, DocumentTagService, iTag.ownerId));
        } else {
          iTag.predefined = false;
          this.customTags.push(new GenericSimpleModel(this.injector, iTag, DocumentTagService, iTag.ownerId));
        }
      });

      if (this.customTags.length > 0) this.checkCustomDeletion();
    } catch {
      this.allDocumentTags = [];
    }
  }

  private async fetchSmartDocsSettings() {
    try {
      this.smartDocsSettings = await this.injector.get(SmartDocsSettingsService).find();
    } catch {}
  }

  async updateMandatoryTags(areMandatory: boolean) {
    await this.injector.get(SmartDocsSettingsService).updateMandatoryTags(this.smartDocsSettings._id, areMandatory);
  }

  async checkCustomDeletion() {
    await Promise.all(
      this.customTags.map(async (iTag) => {
        iTag.data.documentsNumber = [];
        iTag.data.documentsNumber = await this.injector.get(DocumentService).getDocsByTag(iTag.data._id);
      })
    );
  }

  addTag(): void {
    const dialogRef = this.dialog.open(EditTagDialog);
    dialogRef.afterClosed().subscribe(async (tagAdded: IDocumentTagModel) => {
      try {
        if (check.not.assigned(tagAdded)) {
          return;
        }

        await this.fetchData();
      } catch {
        // An error is shown
      }
    });
  }

  async changeTagStatus(tag: GenericSimpleModel, checked: boolean) {
    try {
      await this.injector.get(DocumentTagService).updateById(tag.data._id, { isActive: checked });
      const translation = checked ? this.pageTranslation.tagActivatedSnackText : this.pageTranslation.tagDeactivatedSnackText;

      const tagCheckedSnackText = this.i18nDataPipe.transform(translation, tag.data);
      this.snackBar.open(tagCheckedSnackText, 'OK', {
        duration: 5000,
      });
    } catch {
      // An error is shown
    }
  }

  editTagDialog(tag: IDocumentTagModel) {
    const dialogRef = this.dialog.open(EditTagDialog, { data: tag });
    dialogRef.afterClosed().subscribe(async (tagModified: IDocumentTagModel) => {
      try {
        if (check.not.assigned(tagModified)) {
          return;
        }

        if (tagModified.predefined) {
          const defaultTagIndex = this.defaultTags.findIndex((iPredefinedTag) => iPredefinedTag.data._id === tag._id);
          if (defaultTagIndex >= 0) {
            this.defaultTags[defaultTagIndex] = new GenericSimpleModel(this.injector, tagModified, DocumentTagService, tagModified.ownerId);
          }
        } else {
          const currentTagIndex = this.customTags.findIndex((iCustomTag) => iCustomTag.data._id === tag._id);
          if (currentTagIndex >= 0) {
            this.customTags[currentTagIndex] = new GenericSimpleModel(this.injector, tagModified, DocumentTagService, tagModified.ownerId);
          }
        }
      } catch {
        // An error is shown
      }
    });
  }

  async deleteTag(documentTag: GenericSimpleModel): Promise<void> {
    try {
      if (documentTag.data.documentsNumber?.length > 0) {
        return;
      }

      const getDeleteTagDialogTranslations = this.injector.get(InternationalizationService).getAllTranslation('document-delete-tag-dialog');
      const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

      const [deleteTagDialogTranslations, globalMiscTranslation] = await Promise.all([
        getDeleteTagDialogTranslations,
        getGlobalMiscTranslations,
      ]);
      const data = {
        titleText: this.injector.get(I18nDataPipe).transform(deleteTagDialogTranslations.dialogHeader, { name: documentTag.data.name }),
        cancelButtonText: globalMiscTranslation.goBackButtonDialog,
        confirmButtonText: deleteTagDialogTranslations.deleteButtonLabel,
        confirmButtonColor: 'Danger',
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe(async (tagDeleted: boolean) => {
        try {
          if (check.not.assigned(tagDeleted) || tagDeleted === false) {
            return;
          }

          await this.injector.get(DocumentTagService).deleteById(documentTag.data._id);
          const tagDeletedSnackText = this.i18nDataPipe.transform(this.pageTranslation.tagDeletedSnackText, documentTag.data);
          this.snackBar.open(tagDeletedSnackText, 'OK', {
            duration: 5000,
          });

          await this.fetchData();
        } catch {
          // An error is shown
        }
      });
    } catch (e) {
      throw e;
    }
  }

  private async configureGlobalBar(): Promise<void> {
    try {
      const settingsBarTranslation = await this.injector.get(InternationalizationService).getAllTranslation('settings-top-bar');
      const options = await this.injector.get(SettingsBarService).getOptions(settingsBarTranslation);
      this.injector.get(GlobalBarService).setPageName(settingsBarTranslation.pageName);
      this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
      this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(0);
    } catch (error) {
      throw error;
    }
  }

  public goBack(): void {
    this.router.navigate(['/cloud/settings/overview']);
  }
}
