import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InfoMessageModule } from '@app/common-components/info-message/info-message.module';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { AdjustmentHistoryButtonComponent } from '@app/common-components/time-off-user-personal/components/adjustment-history-button/adjustment-history-button.component';
import { AdjustmentHistoryCardComponent } from '@app/common-components/time-off-user-personal/components/adjustment-history-card/adjustment-history-card.component';
import { PolicyDetailsCardComponent } from '@app/common-components/time-off-user-personal/components/policy-details-card/policy-details-card.component';
import { WorkingDayCircle } from '@app/common-components/time-off-user-personal/components/working-day-circle/working-day-circle.component';
import { RepeatRequestConflictInfo } from '@app/common-components/time-off-user-personal/components/repeat-request-conflict-info/repeat-request-conflict-info.component';
import { TimeOffRequestAttachmentsComponent } from '@app/common-components/time-off-user-personal/components/request-attachments/time-off-request-attachments.component';
import { TimeOffRequestStatusComponent } from '@app/common-components/time-off-user-personal/components/request-status/time-off-request-status.component';
import { StatusDetailsCardComponent } from '@app/common-components/time-off-user-personal/components/status-details-card/status-details-card.component';
import { TimeOffHistoryComponent } from '@app/common-components/time-off-user-personal/components/time-off-history/time-off-history.component';
import { TimeOffTypeStatusComponent } from '@app/common-components/time-off-user-personal/components/type-status/time-off-type-status.component';
import { CancelTimeOffRequestDialog } from '@app/common-components/time-off-user-personal/dialog/cancel-time-off-request/cancel-time-off-request.dialog';
import { SubmitTimeOffRequestDialog } from '@app/common-components/time-off-user-personal/dialog/submit-time-off-request/submit-time-off-request.dialog';
import { TimeOffCardDetailsDialog } from '@app/common-components/time-off-user-personal/dialog/time-off-card-details/time-off-card-details.dialog';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';
import { ManagePoliciesDialog } from '@app/cloud-features/time-off/pages/time-off-company/dialogs/manage-policies.dialog';
import { ManageUserPoliciesModule } from '@app/common-components/manage-user-policies/manage-user-policies.module';


@NgModule({
  declarations: [
    TimeOffHistoryComponent,
    TimeOffRequestStatusComponent,
    TimeOffTypeStatusComponent,
    CancelTimeOffRequestDialog,
    SubmitTimeOffRequestDialog,
    TimeOffCardDetailsDialog,
    TimeOffRequestAttachmentsComponent,
    PolicyDetailsCardComponent,
    WorkingDayCircle,
    RepeatRequestConflictInfo,
    StatusDetailsCardComponent,
    AdjustmentHistoryCardComponent,
    AdjustmentHistoryButtonComponent,
    ManagePoliciesDialog,
  ],
  imports: [CommonModule, RouterModule, IconModule, TruncateTextModule, StandardComponentsModule, MaterialModule, InfoMessageModule, ManageUserPoliciesModule],
  providers: [],
  exports: [TimeOffHistoryComponent, TimeOffRequestStatusComponent, TimeOffTypeStatusComponent]
})
export class TimeOffUserPersonalModule {}
