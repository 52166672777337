<orgos-container *ngIf="listProfiles" aspect="card">
  <orgos-column-container class="main-container">
    <orgos-column size="0" class="rp-options-container kenjo-p-30px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="kenjo-font-size-16px kenjo-text-transform-uppercase"> {{i18n.page.profilesLabel}} </orgos-column>
        <kenjo-icon [size]="20" orgosColumn="0" [matTooltip]="i18n.page.addProfileButtonTooltip" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="createNewProfile()">add_circle</kenjo-icon>
      </orgos-column-container>

      <div (mouseenter)="optionHover=index" (mouseleave)="optionHover=-1" *ngFor="let iProfile of listProfiles; let index = index" class="kenjo-pt-15px">
        <orgos-column-container centerColumns="true" class="rp-profile-option">
          <orgos-column size="1">
            <kenjo-truncate-text class="rp-text-name rp-menu" [class.kenjo-font-color-light-text-757575]="!chosenProfile || iProfile._id !== chosenProfile._id" [class.kenjo-font-color-primary-5993e3]="chosenProfile && iProfile._id === chosenProfile._id" (click)="changeProfile(iProfile)">
              {{iProfile.name}}&nbsp;<span *ngIf="profileIdToNumberOfUsers">({{!profileIdToNumberOfUsers[iProfile._id] ? '0' : profileIdToNumberOfUsers[iProfile._id]}})</span>
            </kenjo-truncate-text>
          </orgos-column>
          <kenjo-icon
            orgosColumn="0"
            *ngIf="!iProfile._isStandard || iProfile._isStandard === false"
            [size]="20"
            (click)="deleteProfileDialog(iProfile)"
            [matTooltip]="i18n.page.deleteProfileButtonTooltip"
            matTooltipPosition="before"
            [class.rp-delete-button]="optionHover !== index"
            class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer"
            >delete</kenjo-icon
          >
        </orgos-column-container>
      </div>
    </orgos-column>

    <orgos-column size="1" class="kenjo-p-30px">
      <orgos-profile-box *ngIf="chosenProfile" [profile]="chosenProfile" (profileUpdated)="refreshProfiles(chosenProfile)" (assignmentsUpdated)="refreshUsersInProfile()"> </orgos-profile-box>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
