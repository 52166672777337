import { Component, Injector, Input, OnInit } from '@angular/core';
import { IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import { IDocumentModel } from '@app/standard/services/document/document.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'orgos-document-card',
  templateUrl: 'document-card.component.html',
  styleUrls: ['document-card.component.scss']
})
export class DocumentCardComponent implements OnInit {
  unlabelledId: string = picklists.DOCUMENT_UNLABELLED_ID;

  private _document: IDocumentModel;
  @Input()
  set document(document: IDocumentModel) {
    this._document = document;
  }
  get document(): IDocumentModel {
    return this._document;
  }

  private _allDocumentTags: Array<IDocumentTagModel>;
  @Input()
  set allDocumentTags(allDocumentTags: Array<IDocumentTagModel>) {
    this._allDocumentTags = allDocumentTags;
  }
  get allDocumentTags(): Array<IDocumentTagModel> {
    return this._allDocumentTags;
  }

  private _signatureTooltip: string = '';
  @Input()
  set signatureTooltip(signatureTooltip: string) {
    this._signatureTooltip = signatureTooltip;
  }
  get signatureTooltip(): string {
    return this._signatureTooltip;
  }

  tagNamesMap: { [key: string]: string } = {};
  tagColorsMap: { [key: string]: string } = {};

  ngOnInit(): void {
    this.fetchTags();
  }

  fetchTags() {
    this.initMaps();
  }

  initMaps() {
    this.allDocumentTags.forEach((iDocumentTag) => {
      this.tagNamesMap[iDocumentTag._id] = iDocumentTag.name;
      this.tagColorsMap[iDocumentTag._id] = userColorConstants[iDocumentTag.color];
    });
  };
}
