import * as check from 'check-types';
import { BehaviorSubject, Observable } from 'rxjs';

export type TaskName = 'ParsePdf' | 'MatchPayslipsWithUsers' | 'SplitPayslips';
export type TaskProgressCallback = (taskName, progress) => void;

export class TaskProgress {
  private current: number = 0;
  private _progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private taskName: TaskName, private total: number, private callback?: TaskProgressCallback) {}

  getTaskName(): TaskName {
    return this.taskName;
  }

  getProgressAsObservable(): Observable<number> {
    return this._progress$.asObservable();
  }

  markAsComplete(numOfTimes = 1): void {
    if (check.not.assigned(this.total) || this.total < 0) {
      return;
    }

    this.current += numOfTimes;

    const progress = (this.current / this.total) * 100;

    if (progress < 0) {
      this._progress$.next(0);
    } else if (progress > 99) {
      this._progress$.next(99);
    } else {
      this._progress$.next(parseInt(progress.toFixed(0), 10));
    }

    if (check.assigned(this.callback)) {
      this.callback(this.taskName, this._progress$.value);
    }
  }
}
