import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-warning-message',
  templateUrl: 'warning-message.component.html',
  styleUrls: ['warning-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningMessageComponent {
  @Output() hideWarning: EventEmitter<void> = new EventEmitter<void>();

  @Input() canBeClosed: boolean = false;
}
