<mat-form-field [appearance]="appearance" *ngIf="!readOnly" [class.mat-form-field-invalid]="forceError">
  <mat-label>{{ label }}</mat-label>
  <input class="kenjo-cursor-text" matInput #input="matInput" [kenjo-alphanumeric-input]="enableAlphaNumericFilter"  [ngModel]="value" (ngModelChange)="onValueChange($event)" [required]="required" autocomplete="new-password" [placeholder]="placeholder" [maxlength]="maxlength" [class.kenjo-cursor-pointer]="nonClick" [readonly]="nonClick" />
  <div *ngIf="prefix && (input.focused || !input.empty)" matPrefix>{{ prefix }}</div>
  <button *ngIf="showClearButton && value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="onValueChange('')" class="ist-margin-bottom-icon">
    <mat-icon>clear</mat-icon>
  </button>
  <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
  <mat-icon *ngIf="showClearButton ? value === '' : suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</mat-form-field>
<mat-form-field [appearance]="appearance" *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput #input="matInput" [ngModel]="value" readOnly disabled [required]="required" autocomplete="new-password" />
  <div *ngIf="prefix && (input.focused || !input.empty)" matPrefix>{{ prefix }}</div>
  <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
  <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</mat-form-field>
<div *ngIf="!isValueValid || forceError" [class.istc-error]="!forceError"><ng-content select="orgos-input-error"></ng-content></div>
