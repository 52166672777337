import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CustomFieldService } from '@app/standard/services/custom-field/custom-field.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserAddressService } from '@app/standard/services/user/user-address.service';
import { UserConfidentialService } from '@app/standard/services/user/user-confidential.service';
import { UserEmergencyService } from '@app/standard/services/user/user-emergency.service';
import {
  IUserEmploymentContractTypeModel,
  UserEmploymentContractTypeService,
} from '@app/standard/services/user/user-employment-contract-type.service';
import {
  IUserEmploymentSubcategoryModel,
  UserEmploymentSubcategoryService,
} from '@app/standard/services/user/user-employment-subcategory.service';
import {
  IUserEmploymentTerminationReasonModel,
  UserEmploymentTerminationReasonService,
} from '@app/standard/services/user/user-employment-termination-reason.service';
import { UserFinancialService } from '@app/standard/services/user/user-financial.service';
import { UserHomeService } from '@app/standard/services/user/user-home.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { I18nDataPipe } from '../../components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '../../core/generic-cache-model';
import { SettingsBarService } from '../../services/settings/settings-bar.service';
import { AddCustomFieldDialog } from './dialogs/add-custom-field/add-custom-field.dialog';
import { UserEmploymentContractTypeDialog } from './dialogs/user-employment-contract-type-dialog/user-employment-contract-type-dialog';
import { UserEmploymentSubcategoryDialog } from './dialogs/user-employment-subcategory-dialog/user-employment-subcategory-dialog';
import { UserEmploymentTerminationTypeDialog } from './dialogs/user-employment-termination-type-dialog/user-employment-termination-type-dialog';

@Component({
  selector: 'orgos-settings-employee-information',
  templateUrl: 'settings-employee-information.page.html',
  styleUrls: ['settings-employee-information.page.scss'],
})
export class SettingsEmployeeInformationPage implements OnInit {
  COLLECTION_NAMES = ['user-personal', 'user-work', 'user-address', 'user-home', 'user-financial', 'user-emergency', 'user-confidential'];
  SECTION_ENDS_WITH = 'SectionTitle';
  EMPLOYMENT_INFO_ENDS_WITH = 'EmploymentInformationTitle';
  pageTranslation: any = {};
  miscTranslation: any = {};
  settingsBarTranslation: any = {};
  peopleDetailPageTranslation: any = {};
  peopleDetailSections: Array<string> = [];
  employmentInformationSections: Array<string> = [];
  sectionSelected: string;
  mapCustomFields: object = {}; // key=>Collection name, value=>Array<object> array of custom fields
  userPersonal: GenericCacheModel;
  userWork: GenericCacheModel;
  userAddress: GenericCacheModel;
  userHome: GenericCacheModel;
  userFinancial: GenericCacheModel;
  userEmergency: GenericCacheModel;
  userConfidential: GenericCacheModel;
  userAccount: GenericCacheModel;

  defaultTerminationReasonMap = {};
  defaultTerminationReason: Array<IUserEmploymentTerminationReasonModel> = [];
  terminationReason: IUserEmploymentContractTypeModel;
  showDefaults: boolean = false;
  showDefaultsText: string = '';

  // This is used to prevent users from deleting or disabling the last active custom contract type
  get remainingActiveContractTypes(): Observable<number> {
    return this.injector.get(UserEmploymentContractTypeService).remainingActiveItems;
  }

  get remainingActiveTerminationReasons(): Observable<number> {
    return this.injector.get(UserEmploymentTerminationReasonService).remainingActiveItems;
  }

  get defaultUserEmploymentContractTypes(): Observable<Array<IUserEmploymentContractTypeModel>> {
    return this.injector.get(UserEmploymentContractTypeService).defaultUserEmploymentContractTypes;
  }

  get customUserEmploymentContractTypes(): Observable<Array<IUserEmploymentContractTypeModel>> {
    return this.injector.get(UserEmploymentContractTypeService).customUserEmploymentContractTypes;
  }

  get customUserEmploymentSubcategories(): Observable<Array<IUserEmploymentContractTypeModel>> {
    return this.injector.get(UserEmploymentSubcategoryService).userEmploymentSubcategories;
  }

  get defaultUserEmploymentTerminationReasonTypes(): Observable<Array<IUserEmploymentTerminationReasonModel>> {
    return this.injector.get(UserEmploymentTerminationReasonService).defaultUserEmploymentTerminationReasons;
  }

  get customUserEmploymentTerminationReasonTypes(): Observable<Array<IUserEmploymentTerminationReasonModel>> {
    return this.injector.get(UserEmploymentTerminationReasonService).customUserEmploymentTerminationReasons;
  }

  constructor(private injector: Injector, private snackBar: MatLegacySnackBar, private dialog: MatLegacyDialog) {}

  ngOnInit(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-top-bar')
      .then((pageTranslation: any) => {
        this.settingsBarTranslation = pageTranslation;
        return this.injector.get(SettingsBarService).getOptions(this.settingsBarTranslation);
      })
      .then((options) => {
        const optionIndex = _.findIndex(options, ['name', this.settingsBarTranslation.employeeInfoTab]);

        this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
        this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(optionIndex);
      })
      .catch(() => {
        // An error is already shown

        this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.fetchData();
    this.fetchTerminationReasonData();

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-employee-information-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
        if (check.assigned(this.pageTranslation)) {
          Object.keys(this.pageTranslation).forEach((key) => {
            if (key.endsWith(this.SECTION_ENDS_WITH)) {
              if (check.not.assigned(this.sectionSelected)) {
                this.sectionSelected = this.pageTranslation[key];
              }
              this.peopleDetailSections.push(this.pageTranslation[key]);
            } else if (key.endsWith(this.EMPLOYMENT_INFO_ENDS_WITH)) {
              this.employmentInformationSections.push(this.pageTranslation[key]);
            }
          });
        }
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-personal-page')
      .then((peopleDetailPageTranslation) => {
        this.peopleDetailPageTranslation = peopleDetailPageTranslation;
      })
      .catch(() => {
        this.peopleDetailPageTranslation = {};
      });

    this.injector.get(PrivateAmplitudeService).logEvent('view settings page', { category: 'Navigation', type: 'employee fields' });
  }

  addCustomFieldDialog(collectionName: string): void {
    const data = {
      customField: {
        collectionName: collectionName,
        fieldApiName: '',
        fieldLabel: '',
        fieldType: '',
      },
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(AddCustomFieldDialog, { data });
    dialogRef.afterClosed().subscribe((customFieldCreated) => {
      if (check.assigned(customFieldCreated) && check.object(customFieldCreated) && check.nonEmptyObject(customFieldCreated)) {
        // Fetch the custom fields for the collection that has been removed
        this.reloadCollection(customFieldCreated.collectionName);
      }
    });
  }

  toggleShowDefaults(): void {
    this.showDefaults = !this.showDefaults;
  }

  customFieldUpdated(customFieldDocument: any): void {
    // Fetch the custom fields for the collection that has been removed
    this.reloadCollection(customFieldDocument.collectionName);
  }

  customFieldDeleted(customFieldDocument: any): void {
    const message = this.injector
      .get(I18nDataPipe)
      .transform(this.pageTranslation.customFieldDeletedSnackbar, { fieldLabel: customFieldDocument.fieldLabel });
    this.injector.get(MatLegacySnackBar).open(message, 'OK', {
      duration: 5000,
    });

    // Fetch the custom fields for the collection that has been removed
    const collectionName = customFieldDocument.collectionName;
    this.reloadCollection(collectionName);
  }

  async toggleIsActive(userEmploymentContractType: IUserEmploymentContractTypeModel, isEnabled: boolean): Promise<void> {
    userEmploymentContractType.isActive = isEnabled;
    await this.injector.get(UserEmploymentContractTypeService).updateById(userEmploymentContractType._id, userEmploymentContractType);
    this.snackBar.open(
      this.injector
        .get(I18nDataPipe)
        .transform(isEnabled ? this.pageTranslation.enabledContractTypeSnackBar : this.pageTranslation.disabledContractTypeSnackBar, {
          name: userEmploymentContractType.name,
        }),
      'OK',
      {
        duration: 5000,
      }
    );
    this.injector.get(UserEmploymentContractTypeService).calculateRemainingActiveItems();
  }

  toggleSubcategory(subcategory: IUserEmploymentSubcategoryModel, isEnabled: boolean): void {
    subcategory.isActive = isEnabled;
    this.injector.get(UserEmploymentSubcategoryService).updateById(subcategory._id, subcategory);
    this.snackBar.open(
      this.injector
        .get(I18nDataPipe)
        .transform(isEnabled ? this.pageTranslation.enabledSubcategorySnackBar : this.pageTranslation.disabledSubcategorySnackBar, {
          name: subcategory.name,
        }),
      'OK',
      {
        duration: 5000,
      }
    );
  }

  async toggleTerminationReasonIsActive(terminationReason: IUserEmploymentTerminationReasonModel, isEnabled: boolean): Promise<void> {
    terminationReason.isActive = isEnabled;
    await this.injector.get(UserEmploymentTerminationReasonService).updateById(terminationReason._id, terminationReason);
    this.snackBar.open(
      this.injector
        .get(I18nDataPipe)
        .transform(
          isEnabled ? this.pageTranslation.enabledTerminationReasonSnackBar : this.pageTranslation.disabledTerminationReasonSnackBar,
          { name: terminationReason.name }
        ),
      'OK',
      {
        duration: 5000,
      }
    );
    this.injector.get(UserEmploymentTerminationReasonService).calculateRemainingActiveItems();
  }

  addCustomContractTypeDialog(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(UserEmploymentContractTypeDialog);
    dialogRef.afterClosed().subscribe(() => {
      this.injector.get(UserEmploymentContractTypeService).loadAllUserEmploymentContractTypes();
    });
  }

  addCustomSubcategoryDialog(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(UserEmploymentSubcategoryDialog);
    dialogRef.afterClosed().subscribe(() => {
      this.injector.get(UserEmploymentSubcategoryService).loadAllUserEmploymentSubcategories();
      return;
    });
  }

  addCustomTerminationTypeDialog(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(UserEmploymentTerminationTypeDialog);
    dialogRef.afterClosed().subscribe(() => {
      this.injector.get(UserEmploymentTerminationReasonService).loadAllUserEmploymentTerminationReasons();
    });
  }

  editCustomContractTypeDialog(contractType: IUserEmploymentContractTypeModel): void {
    const data = contractType;
    const dialogRef = this.injector.get(MatLegacyDialog).open(UserEmploymentContractTypeDialog, { data });
    dialogRef.afterClosed().subscribe(() => {
      this.injector.get(UserEmploymentContractTypeService).loadAllUserEmploymentContractTypes();
    });
  }

  editCustomSubcategoryDialog(subcategory: IUserEmploymentSubcategoryModel): void {
    const data = subcategory;
    const dialogRef = this.injector.get(MatLegacyDialog).open(UserEmploymentSubcategoryDialog, { data });
    dialogRef.afterClosed().subscribe(() => {
      this.injector.get(UserEmploymentSubcategoryService).loadAllUserEmploymentSubcategories();
      return;
    });
  }

  editCustomTerminationTypeDialog(terminationReason: IUserEmploymentTerminationReasonModel): void {
    const data = terminationReason;
    const dialogRef = this.injector.get(MatLegacyDialog).open(UserEmploymentTerminationTypeDialog, { data });
    dialogRef.afterClosed().subscribe(() => {
      this.injector.get(UserEmploymentTerminationReasonService).loadAllUserEmploymentTerminationReasons();
    });
  }

  async deleteCustomContractType(contractType: IUserEmploymentContractTypeModel): Promise<void> {
    if (
      check.positive(contractType.usageCount) ||
      (contractType.isActive && check.one(await this.remainingActiveContractTypes.pipe(first()).toPromise()))
    ) {
      return;
    }
    const getDeleteContractTypeDialogTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('delete-user-employment-contract-type-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');
    const [deleteContractTypeDialogTranslations, globalMiscTranslation] = await Promise.all([
      getDeleteContractTypeDialogTranslations,
      getGlobalMiscTranslations,
    ]);
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(deleteContractTypeDialogTranslations.dialogHeader, { name: contractType.name }),
      cancelButtonText: globalMiscTranslation.goBackButtonDialog,
      confirmButtonText: deleteContractTypeDialogTranslations.deleteButtonLabel,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (contractTypeDeleted: boolean) => {
      try {
        if (check.not.assigned(contractTypeDeleted) || contractTypeDeleted === false) {
          return;
        }
        await this.injector.get(UserEmploymentContractTypeService).deleteById(contractType._id);
        const contractTypeDeletedSnackText = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.contractTypeDeletedSnackText, contractType.name);
        this.snackBar.open(contractTypeDeletedSnackText, 'OK', {
          duration: 5000,
        });

        await this.fetchData();
      } catch {
        // An error is shown
      }
    });
  }

  async deleteCustomSubcategory(subcategory: IUserEmploymentSubcategoryModel): Promise<void> {
    if (subcategory.usageCount > 0) {
      return;
    }
    const getDeleteSubcategoryDialogTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('delete-user-employment-subcategory-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');
    const [deleteSubcategoryDialogTranslations, globalMiscTranslation] = await Promise.all([
      getDeleteSubcategoryDialogTranslations,
      getGlobalMiscTranslations,
    ]);
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(deleteSubcategoryDialogTranslations.dialogHeader, { name: subcategory.name }),
      cancelButtonText: globalMiscTranslation.goBackButtonDialog,
      confirmButtonText: deleteSubcategoryDialogTranslations.deleteButtonLabel,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (subcategoryDeleted: boolean) => {
      try {
        if (check.not.assigned(subcategoryDeleted) || subcategoryDeleted === false) {
          return;
        }
        await this.injector.get(UserEmploymentSubcategoryService).deleteById(subcategory._id);
        const subcategoryDeletedSnackText = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.subcategoryDeletedSnackText, subcategory.name);
        this.snackBar.open(subcategoryDeletedSnackText, 'OK', {
          duration: 5000,
        });

        await this.fetchData();
      } catch {
        // An error is shown
      }
    });
  }

  async deleteCustomTerminationReason(terminationReason: IUserEmploymentTerminationReasonModel): Promise<void> {
    if (
      check.positive(terminationReason.usageCount) ||
      (terminationReason.isActive && check.one(await this.remainingActiveTerminationReasons.pipe(first()).toPromise()))
    ) {
      return;
    }
    const getDeleteTerminationReasonDialogTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('delete-user-employment-termination-reason-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');
    const [deleteTerminationReasonDialogTranslations, globalMiscTranslation] = await Promise.all([
      getDeleteTerminationReasonDialogTranslations,
      getGlobalMiscTranslations,
    ]);
    const data = {
      titleText: this.injector
        .get(I18nDataPipe)
        .transform(deleteTerminationReasonDialogTranslations.dialogHeader, { name: terminationReason.name }),
      cancelButtonText: globalMiscTranslation.goBackButtonDialog,
      confirmButtonText: deleteTerminationReasonDialogTranslations.deleteButtonLabel,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (terminationReasonDeleted: boolean) => {
      try {
        if (check.not.assigned(terminationReasonDeleted) || terminationReasonDeleted === false) {
          return;
        }
        await this.injector.get(UserEmploymentTerminationReasonService).deleteById(terminationReason._id);
        const terminationReasonDeletedSnackText = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.terminationReasonSnackText, terminationReason.name);
        this.snackBar.open(terminationReasonDeletedSnackText, 'OK', {
          duration: 5000,
        });

        await this.fetchTerminationReasonData();
      } catch {
        // An error is shown
      }
    });
  }

  private reloadCollection(collectionName: string): void {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.fetchCollectionCustomFields(collectionName)
      .then((iCollectionCustomFields) => {
        this.mapCustomFields[collectionName] = this.getCustomFieldsGrouped(iCollectionCustomFields);
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch(() => {
        this.mapCustomFields[collectionName] = [];
        this.injector.get(GlobalBarService).setProgressBar(false);
      });
  }

  private getCustomFieldsGrouped(listCustomFields: Array<object>): Array<Array<object>> {
    if (check.not.assigned(listCustomFields) || check.not.array(listCustomFields) || check.emptyArray(listCustomFields)) {
      return [];
    }

    const customFieldsGroupedByRow = [];
    const firstRow = [];
    customFieldsGroupedByRow.push(firstRow);

    let rowNumber = 0;
    listCustomFields.forEach((iCustomField: any) => {
      if (check.equal('TextArea', iCustomField.fieldType)) {
        if (customFieldsGroupedByRow[rowNumber].length > 0) {
          customFieldsGroupedByRow.push([]);
          rowNumber++;
        }
        customFieldsGroupedByRow[rowNumber].push(iCustomField);
        customFieldsGroupedByRow.push([]);
        rowNumber++;
        return;
      }

      customFieldsGroupedByRow[rowNumber].push(iCustomField);
      if (customFieldsGroupedByRow[rowNumber].length === 2) {
        customFieldsGroupedByRow.push([]);
        rowNumber++;
      }
    });

    return customFieldsGroupedByRow;
  }

  private async fetchData(): Promise<void> {
    try {
      await this.getUserCollections();
      this.injector.get(UserEmploymentContractTypeService).loadAllUserEmploymentContractTypes();
      this.injector.get(UserEmploymentSubcategoryService).loadAllUserEmploymentSubcategories();
      this.injector.get(GlobalBarService).setProgressBar(false);
      // do not allow to disable the last item
      this.injector.get(UserEmploymentContractTypeService).calculateRemainingActiveItems();
      this.injector.get(UserEmploymentTerminationReasonService).calculateRemainingActiveItems();
    } catch (error) {
      throw error;
    }
  }

  private async fetchTerminationReasonData(): Promise<void> {
    try {
      this.injector.get(UserEmploymentTerminationReasonService).loadAllUserEmploymentTerminationReasons();
    } catch {
      // An error is shown
    }
  }

  private async getUserCollections(): Promise<void> {
    this.userPersonal = new GenericCacheModel(this.injector, { _id: '' }, UserPersonalService, '');
    this.userWork = new GenericCacheModel(this.injector, { _id: '' }, UserWorkService, '');
    this.userAddress = new GenericCacheModel(this.injector, { _id: '' }, UserAddressService, '');
    this.userHome = new GenericCacheModel(this.injector, { _id: '' }, UserHomeService, '');
    this.userFinancial = new GenericCacheModel(this.injector, { _id: '' }, UserFinancialService, '');
    this.userEmergency = new GenericCacheModel(this.injector, { _id: '' }, UserEmergencyService, '');
    this.userConfidential = new GenericCacheModel(this.injector, { _id: '' }, UserConfidentialService, '');
    this.userAccount = new GenericCacheModel(this.injector, { _id: '' }, UserAccountService, '');
    const collectionCustomFieldPromise = this.COLLECTION_NAMES.map((iCollectionName) => this.fetchCollectionCustomFields(iCollectionName));

    const allCollectionCustomFields = await Promise.all(collectionCustomFieldPromise);
    this.COLLECTION_NAMES.forEach((iCollectionName, i) => {
      this.mapCustomFields[iCollectionName] = this.getCustomFieldsGrouped(allCollectionCustomFields[i]);
    });
  }

  private async fetchCollectionCustomFields(collectionName: string): Promise<Array<any>> {
    const customFieldsOfCollection = await this.injector.get(CustomFieldService).getByCollection(collectionName);
    return check.not.assigned(customFieldsOfCollection) || check.emptyObject(customFieldsOfCollection) ? [] : customFieldsOfCollection;
  }
}
