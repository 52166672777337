import { ITimeOffRequestModel } from '@app/cloud-features/time-off/services/time-off-request.service';
import { IUserAttendanceMainInfo, IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import { TimeOffRequest as ITimeOffRequestLib, calculateTimeOffRequestPartOfDayForDate } from '@carlos-orgos/kenjo-shared-libs';
import * as check from 'check-types';
import * as moment from 'moment';

export function convertToMinutes(hour: string): number {
  if (check.not.assigned(hour)) {
    return null;
  }

  let hours = hour.split(':')[0];

  if (hour.includes('PM') && hours !== '12') {
    hours = (Number(hours) + 12).toString();
  }
  if (hour.includes('AM') && hours === '12') {
    hours = (Number(hours) - 12).toString();
  }

  const minutes = hour.split(':')[1];

  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
}

export function convertToTime(minutesValue: number): string {
  if (check.not.assigned(minutesValue)) {
    return undefined;
  }

  const hours = Math.floor((minutesValue / 60) % 24);
  const minutes = Math.round(((minutesValue / 60) % 1) * 60);

  const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return time;
}

export function calculateMinutesInGivenShift(userAttendance: any, withoutBreakTime?: boolean): number {
  const endTime = check.assigned(userAttendance.endTime) && check.number(userAttendance.endTime) ? userAttendance.endTime : undefined;
  const startTime =
    check.assigned(userAttendance.startTime) && check.number(userAttendance.startTime) ? userAttendance.startTime : undefined;
  const breakTime = withoutBreakTime
    ? 0
    : check.assigned(userAttendance.breakTime) && check.number(userAttendance.breakTime)
    ? userAttendance.breakTime
    : 0;

  return userAttendance._deleted === false && check.assigned(startTime) && check.assigned(endTime) ? endTime - startTime - breakTime : 0;
}

export function returnNewAttendanceEndTime(
  currentEntry: any,
  validation: { type: 'MAX_SHIFT_LENGTH'; value: number } | { type: 'MAX_HOURS_PER_DAY'; value: number; totalTrackedAttendance: number }
): number {
  const minutesInCurrentShift = calculateMinutesInGivenShift(currentEntry);
  const breakTimeHelper = currentEntry.breakTime ?? 0;
  let newEndTimeHelper;

  if (validation.type === 'MAX_HOURS_PER_DAY') {
    newEndTimeHelper = Math.abs(validation.value - validation.totalTrackedAttendance);
  } else if (validation.type === 'MAX_SHIFT_LENGTH') {
    newEndTimeHelper = Math.abs(validation.value - minutesInCurrentShift);
  }

  let newEndTime =
    validation.type === 'MAX_HOURS_PER_DAY'
      ? currentEntry.startTime + Number(newEndTimeHelper) + breakTimeHelper
      : currentEntry.endTime - newEndTimeHelper;

  if (newEndTime < 0) {
    newEndTime = 1400 - newEndTime;
  }

  return newEndTime;
}

export function getBreakTime(userAttendance: IUserAttendanceModel): number {
  return (
    userAttendance?.breaks?.reduce((total, iBreak) => {
      if (check.assigned(iBreak.start) && check.assigned(iBreak.end)) {
        return total + iBreak.end - iBreak.start;
      }
      return total;
    }, 0) || 0
  );
}

export function checkOverlapping(currentEntry: IUserAttendanceModel, iShift: IUserAttendanceModel) {
  if (
    iShift?._deleted === false &&
    iShift?._id !== currentEntry?._id &&
    check.assigned(iShift.startTime) &&
    check.assigned(iShift.endTime)
  ) {
    let overlappingStartTime = false;
    let overlappingEndTime = false;

    if (currentEntry.startTime < iShift.endTime && currentEntry.startTime >= iShift.startTime) {
      overlappingStartTime = true;
    }

    if (
      (currentEntry.endTime >= iShift.startTime && currentEntry.endTime <= iShift.endTime) ||
      (currentEntry.startTime <= iShift.startTime && currentEntry.endTime >= iShift.endTime)
    ) {
      overlappingEndTime = true;
    }

    if (check.not.assigned(currentEntry.endTime) && currentEntry.startTime >= iShift.startTime && currentEntry.startTime < iShift.endTime) {
      overlappingStartTime = true;
      overlappingEndTime = true;
    }

    return overlappingStartTime || overlappingEndTime;
  }

  return false;
}

export function getBreakTimeRanges(entryToBeSplit: IUserAttendanceMainInfo) {
  let breakTimeIndex = 0;

  const entries = entryToBeSplit._changesTracking.reduce((acc, change) => {
    if (change.operationType === 'Update' && change.fieldChanged === 'startBreakTime' && check.assigned(change.newValue)) {
      acc.push({ startTime: Number(change.newValue) });
    } else if (change.operationType === 'Update' && change.fieldChanged === 'breakTime' && check.assigned(change.newValue)) {
      if (check.assigned(acc[breakTimeIndex]) && check.not.assigned(acc[breakTimeIndex]?.endTime)) {
        acc[breakTimeIndex].endTime = Number(acc[breakTimeIndex].startTime) + Number(change.newValue);
        acc[breakTimeIndex].finalValue = change.newValue;

        breakTimeIndex++;
      }
    }

    return acc;
  }, []);

  return entries;
}

export function returnEmptyAttendance(loggedUser: any): IUserAttendanceModel {
  return {
    _userId: loggedUser['_id'],
    ownerId: loggedUser['_id'],
    date: new Date(),
    startTime: undefined,
    endTime: undefined,
    breakTime: undefined,
    comment: undefined,
    breaks: [],
  };
}

export function computeCurrentTimeOff(timeOffRequest: ITimeOffRequestModel[]) {
  const timeOffRequestSorted = sortTimeOffByPolicyType(timeOffRequest);
  const currentTimeString = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  for (const iTimeOff of timeOffRequestSorted) {
    if (currentTimeString >= iTimeOff?.from && currentTimeString <= iTimeOff?.to) {
      return iTimeOff;
    }
  }
}

export function sortTimeOffByPolicyType(timeOffRequest: ITimeOffRequestModel[]) {
  return timeOffRequest.sort((previousTimeOff, newTimeOff) => {
    if (previousTimeOff._policyType === 'Hour' && newTimeOff._policyType !== 'Hour') {
      return -1;
    } else if (previousTimeOff._policyType !== 'Hour' && newTimeOff._policyType === 'Hour') {
      return 1;
    } else {
      return 0;
    }
  });
}

export function setTimeOffMap(timeOffRequest: ITimeOffRequestModel[]) {
  return timeOffRequest.reduce((total, iTimeOff) => {
    if (!total) {
      total = {};
    }
    total[iTimeOff._id.toString()] = {
      fromType: calculateTimeOffRequestPartOfDayForDate(iTimeOff.from as any, iTimeOff as ITimeOffRequestLib),
      toType: calculateTimeOffRequestPartOfDayForDate(iTimeOff.to as any, iTimeOff as ITimeOffRequestLib),
      name: iTimeOff?._timeOffTypeName ?? '',
      startTime: moment.utc(iTimeOff?.from).format('HH:mm'),
      endTime: moment.utc(iTimeOff?.to).format('HH:mm'),
    };

    return total;
  }, {});
}
