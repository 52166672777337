import { Injectable, Injector } from '@angular/core';
import { ISubscription, PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Subscribe to this service from any component/service to know the org's subscription status.
 * This can be used, for example, to show/hide features for free trial users.
 * This service is initialized ONLY ONCE on sign in, therefore preventing multiple server calls whenever we need to check the subscription status.
 * If user signs out, the value will be cleared.
 */
@Injectable({
  providedIn: 'root'
})
export class PrivateSubscriptionService {
  private _subscription$: BehaviorSubject<ISubscription> = new BehaviorSubject({ status: '', plans: [], countryCode: '' });
  public get subscription$(): Observable<ISubscription> {
    return this._subscription$.asObservable();
  }
  public get subscription(): Promise<ISubscription> {
    return this._subscription$.toPromise();
  }

  constructor(private injector: Injector) {}

  clearSubscription(): void {
    this._subscription$.next({ status: '', plans: [], countryCode: '' });
  }

  // initialized on sign in
  async setUserOrgSubscription(): Promise<void> {
    const subscription: ISubscription = await this.injector.get(PrivateOrganizationService).getSubscription();
    this._subscription$.next(subscription);
  }
}
