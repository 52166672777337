import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-input-linear-scale',
  templateUrl: 'input-linear-scale.component.html',
  styleUrls: ['input-linear-scale.component.scss']
})
export class InputLinearScaleComponent {
  @Input() small: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() showOptions: boolean = true;
  @Input() options: Array<string> = [];
  @Input() value: number;
  @Input() translation: any = {};
  @Input() isStandard: boolean;

  mouseOverIndex: number;

  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  public selectValue(selection: number): void {
    if (!this.readOnly) {
      this.value = selection;
      this.valueChange.emit(this.value);
    }
  }
}
