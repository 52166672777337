import { Component, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';

import { StandardServicesRegistry } from '../../services/standard-services.registry';
import { I18nDataPipe } from '../i18n-data/i18n-data.pipe';

@Component({
  selector: 'orgos-factor-score',
  templateUrl: 'factor-score.component.html',
  styleUrls: ['factor-score.component.scss']
})
export class FactorScoreComponent implements OnInit {
  RANGE_VERY_LOW: any = { key: 'rangeVeryLow', score: 2, color: '#ff5757' };
  RANGE_LOW: any = { key: 'rangeLow', score: 4, color: '#ff8d0e' };
  RANGE_MODERATE: any = { key: 'rangeModerate', score: 6, color: '#ffc107' };
  RANGE_HIGH: any = { key: 'rangeHigh', score: 8, color: '#B3BD45' };
  RANGE_VERY_HIGH: any = { key: 'rangeVeryHigh', score: 10, color: '#00b72e' };

  @Input() value: number;
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() lastNDays: number;

  currentRange: any = this.RANGE_MODERATE;
  pageTranslation: any = {};
  lastMonthTranslation: string;

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry, private i18nDataPipe: I18nDataPipe) {}

  ngOnInit(): void {
    this.defineRangeScores();
    this.calculateRange();

    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('enps-result-component')
      .then((pageTranslation) => {
        const data = {
          lastNDays: this.lastNDays
        };
        this.lastMonthTranslation = this.i18nDataPipe.transform(pageTranslation.scoreBasedOnLastMonth, data);
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  private defineRangeScores(): void {
    const step = (this.max - this.min) / 5;

    this.RANGE_VERY_LOW.score = this.min + step;
    this.RANGE_LOW.score = this.min + step * 2;
    this.RANGE_MODERATE.score = this.min + step * 3;
    this.RANGE_HIGH.score = this.min + step * 4;
    this.RANGE_VERY_HIGH.score = this.max;
  }

  calculateRange(): void {
    if (check.not.assigned(this.value) || check.not.number(this.value)) {
      return;
    }

    if (this.value <= this.RANGE_VERY_LOW.score) {
      this.currentRange = this.RANGE_VERY_LOW;
    } else if (this.value <= this.RANGE_LOW.score) {
      this.currentRange = this.RANGE_LOW;
    } else if (this.value <= this.RANGE_MODERATE.score) {
      this.currentRange = this.RANGE_MODERATE;
    } else if (this.value <= this.RANGE_HIGH.score) {
      this.currentRange = this.RANGE_HIGH;
    } else {
      this.currentRange = this.RANGE_VERY_HIGH;
    }
  }

  getStringValue(): string {
    if (check.not.assigned(this.value)) {
      return '-';
    }
    let result = this.precisionRound(this.value, 1).toString();
    if (result.length < 2) {
      result = `${result}.0`;
    }
    return result;
  }

  private precisionRound(number: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  getValueInteger(): number {
    if (check.not.assigned(this.value) || check.not.number(this.value)) {
      return 0;
    }

    return Math.ceil(this.value);
  }

  getColumnEmptySize(): number {
    return this.max - this.getValueInteger();
  }
}
