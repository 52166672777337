<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogName" (clickCloseButton)="closeDialog()">
  <ng-container *ngIf="department">
    <orgos-column-container>
      <orgos-input-text orgosColumn [model]="department" field="name" required="true" (validation)="departmentNameValidation = $event" class="kenjo-mr-10px">
        <orgos-input-error *ngIf="departmentNameValidation && departmentNameValidation.getError('required')"> {{dialogTranslation.departmentNameRequired}}</orgos-input-error>
      </orgos-input-text>
    </orgos-column-container>

    <div class="kenjo-mt-60px kenjo-text-align-right">
      <orgos-button-raised color="Success" (click)="addDepartment()" [disabled]="!departmentNameValidation || departmentNameValidation.hasErrors()">{{dialogTranslation.buttonLabel}}</orgos-button-raised>
    </div>
  </ng-container>
</orgos-dialog-container>
