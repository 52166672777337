import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class TaskService {
  private TASK_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/task-db`;
  private TASK_PERMISSIONS_KEY: string = 'task';
  private TASK_INTERNATIONALIZATION: string = 'task-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) { }

  create(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.TASK_URL, data)
        .then((task: any) => {
          resolve(task);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TaskService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.TASK_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((tasks) => {
          resolve(tasks);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TaskService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.TASK_URL, id)
        .then((document: any) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TaskService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.TASK_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TaskService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.TASK_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TaskService.name, 'deleteById'));
        });
    });
  }

  async updateStatus(task: any, status: boolean): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const { _id: id, ownerId } = task;

      return await this.http.put(`${this.TASK_URL}/status/${id}`, { status, ownerId } , httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TaskService.name, 'find');
    }
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.TASK_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.TASK_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.TASK_URL);
  }
}
