import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITimeOffStatus } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TimeOffStatusHistoryService {
  private TIME_OFF_STATUS_HISTORY_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/time-off-status-history-db`;

  constructor(private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  async find(findQuery: any): Promise<Array<ITimeOffStatusHistory>> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      return this.http.post<Array<ITimeOffStatusHistory>>(`${this.TIME_OFF_STATUS_HISTORY_MICROSERVICE_URL}/find`, findQuery, httpOptions).toPromise();
    } catch (error) {
      this.errorManager.handleRawError(error, TimeOffStatusHistoryService.name, 'find');
    }
  }
}

export interface ITimeOffStatusHistory extends ITimeOffStatus {
  _action?: string;
  _timeOffStatusId?: string;
  adjustment?: number;
  _createdAt: string;
  adjustedAccrualSplits?: {
    total: number;
    startDate: number;
    endDate: number;
    partTime: number;
  };
}
