import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerformanceReviewNominationsController {
  private PERFORMANCE_REVIEW_NOMINATIONS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/performance-review/nominations`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) {}

  getNominableUsers(userId: string, performanceReviewId: string): Promise<Array<INominableUser>> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewNominationsController.name, 'getNominableUsers');
      return Promise.reject(error);
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    const body = { userId, performanceReviewId };
    return this.http
      .post<Array<INominableUser>>(`${this.PERFORMANCE_REVIEW_NOMINATIONS_URL}/nominable-users`, body, httpOptions)
      .toPromise()
      .catch((error) => {
        return Promise.reject(this.errorManager.handleRawError(error, PerformanceReviewNominationsController.name, 'getNominableUsers'));
      });
  }

  getNominations(userId: string, performanceReviewId: string): Promise<INominationsResponse> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewNominationsController.name, 'getNominations');
      return Promise.reject(error);
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    const body = { userId, performanceReviewId };
    return this.http
      .post<INominationsResponse>(`${this.PERFORMANCE_REVIEW_NOMINATIONS_URL}/list`, body, httpOptions)
      .toPromise()
      .catch((error) => {
        return Promise.reject(this.errorManager.handleRawError(error, PerformanceReviewNominationsController.name, 'getNominations'));
      });
  }

  getTeamInPerformanceReview(performanceReviewId: string): Promise<ITeamNominations> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewNominationsController.name, 'getTeamInPerformanceReview');
      return Promise.reject(error);
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    return this.http
      .get<ITeamNominations>(`${this.PERFORMANCE_REVIEW_NOMINATIONS_URL}/${performanceReviewId}/team`, httpOptions)
      .toPromise()
      .catch((error) => {
        return Promise.reject(this.errorManager.handleRawError(error, PerformanceReviewNominationsController.name, 'getTeamInPerformanceReview'));
      });
  }
}

export interface INominableUser {
  _id: string;
  displayName: string;
  _photo: IFileMetadata;
  jobTitle: string;
  departmentId: string;
  departmentName: string;
}

export interface INomination {
  user: {
    _id: string;
    _photo: IFileMetadata;
    displayName: string;
    jobTitle: string;
    departmentId: string;
    departmentName: string;
  };
  nominationSubmitted: boolean;
  performanceReviewId: string;
}

export interface INominationsResponse {
  canEdit: boolean;
  nominations: Array<INomination>;
  performanceReviewStatus: string;
}

export interface INominableTeamUser {
  _id: string;
  displayName: string;
  _photo: IFileMetadata;
  jobTitle: string;
  departmentId: string;
  departmentName: string;
  nominations: Array<INomination>;
}

export interface ITeamNominations {
  availableAction: string;
  teamEmployees: Array<INominableTeamUser>;
}
