import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AppreciationService {
  private APPRECIATION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/appreciation-db`;
  private APPRECIATION_PERMISSIONS_KEY: string = 'appreciation';
  private APPRECIATION_INTERNATIONALIZATION: string = 'appreciation-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  getMine(returnOverdue: boolean = false): Promise<Array<IAppreciationModel>> {
    return new Promise<Array<IAppreciationModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.APPRECIATION_URL}/mine`, ErrorCodes.UNAUTHORIZED, AppreciationService.name, 'getMine');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      let endpoint = 'mine';
      if (returnOverdue === true) {
        endpoint = `mine/overdue`;
      }
      this.http
        .get(`${this.APPRECIATION_URL}/${endpoint}`, httpOptions)
        .toPromise()
        .then((appreciations: Array<IAppreciationModel>) => {
          resolve(appreciations);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AppreciationService.name, 'getMine'));
        });
    });
  }

  create(data: any): Promise<IAppreciationModel> {
    return new Promise<IAppreciationModel>((resolve, reject) => {
      this.genericService
        .create(this.APPRECIATION_URL, data)
        .then((meeting: IAppreciationModel) => {
          resolve(meeting);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AppreciationService.name, 'create'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.APPRECIATION_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AppreciationService.name, 'deleteById'));
        });
    });
  }

  addReaction(announcementId: string, emojiIcon: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.APPRECIATION_URL}/reaction`, ErrorCodes.UNAUTHORIZED, AppreciationService.name, 'addReaction');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const updateQuery = {
        emojiIcon: emojiIcon
      };

      this.http
        .put(`${this.APPRECIATION_URL}/reaction/${announcementId}`, updateQuery, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AppreciationService.name, 'addReaction'));
        });
    });
  }

  deleteReaction(announcementId: string, emojiIcon: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.APPRECIATION_URL}/confirm-read`, ErrorCodes.UNAUTHORIZED, AppreciationService.name, 'deleteReaction');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const updateQuery = {
        emojiIcon: emojiIcon
      };

      this.http
        .put(`${this.APPRECIATION_URL}/reaction/remove/${announcementId}`, updateQuery, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AppreciationService.name, 'deleteReaction'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.APPRECIATION_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.APPRECIATION_INTERNATIONALIZATION);
  }
}

export interface IAppreciationReactionModel {
  _id?: string;
  userId?: string;
  _reactionDate?: Date;
  emojiIcon?: string;
}

export interface IAppreciationModel {
  _createdById?: string;
  _createdAt?: Date;
  _id?: string;
  message?: any;
  reactions?: Array<IAppreciationReactionModel>;
  _userId?: string;
  _companyId?: string;
  _companyName?: string;
  _officeId?: string;
  _officeName?: string;
  _departmentId?: string;
  _departmentName?: string;
  _reportsToId?: string;
}
