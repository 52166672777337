<orgos-dialog-container *ngIf="dialogTranslation" class="csd-dialog-box" [title]="shiftsLimitReached ? dialogTranslation.shiftDeletionLimitTitle : dialogTranslation.clearScheduleDialogTitle" [disabledCloseButton]="isDeleting" (clickCloseButton)="closeDialog()">
  <orgos-column-container *ngIf="filters && (filters.locations.length > 0 || filters.employees.length > 0 || filters.roles.length > 0)" class="kenjo-p-10px csd-error-area kenjo-mb-40px" centerColumns="true">
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
    <div orgosColumn class="kenjo-ml-10px">{{dialogTranslation.filtersAppliedClearSchedule}}</div>
  </orgos-column-container>
  <orgos-column-container *ngIf="deleteActionSummaryList && !shiftsLimitReached" class="kenjo-font-color-light-text-757575 csd-white-space">
    <ul orgosColumn class="kenjo-pl-20px kenjo-pv-0 kenjo-mv-0">
      <li *ngFor="let iDeleteActionSummaryItem of deleteActionSummaryList" class="kenjo-mb-10px">{{ iDeleteActionSummaryItem }}</li>
    </ul>
  </orgos-column-container>
  <orgos-column-container *ngIf="shiftsLimitReached" class="kenjo-font-color-light-text-757575 csd-white-space">
    <div orgosColumn class="kenjo-border-bottom kenjo-pb-20px kenjo-mb-40px">{{ (dialogTranslation.shiftDeletionLimitSubtitle | i18nData : { shiftsLimit : SHIFTS_LIMIT_TO_DELETE }) }}</div>
  </orgos-column-container>
  <orgos-column-container *ngIf="!shiftsLimitReached" centerColumns="true" [class.kenjo-font-color-disabled-c4c4c4]="shiftsData.openShiftsCount === 0" class="kenjo-mt-20px kenjo-mb-30px kenjo-p-10px kenjo-color-grey-background-f5f5f5 csd-include-open-box">
    <div orgosColumn="0" class="kenjo-cursor-pointer">
      <mat-slide-toggle [disabled]="shiftsData.openShiftsCount === 0 || isDeleting" [checked]="false" (change)="calculateTotal($event.checked)"></mat-slide-toggle>
    </div>
    <div orgosColumn class="kenjo-pl-10px">{{ dialogTranslation.includeOpen }}</div>
  </orgos-column-container>

  <ng-container>
    <div class="kenjo-font-weight-bold">{{dialogTranslation.deleteShiftSummary}}</div>
    <div class="kenjo-mt-10px">{{ (dialogTranslation.unpublishedScheduled | i18nData: { shiftsNumber: shiftsData.scheduledShiftsCount })}}</div>
    <div class="kenjo-mt-5px">{{ includeOpen ? (dialogTranslation.unpublishedOpen | i18nData: { shiftsNumber: shiftsData.openShiftsCount }) : (dialogTranslation.unpublishedOpen | i18nData: { shiftsNumber: 0 }) }}</div>
    <div class="kenjo-font-weight-bold kenjo-mt-20px">{{ (dialogTranslation.totalSummary | i18nData: { shiftsNumber: totalShifts })}}</div>
  </ng-container>

  <orgos-column-container *ngIf="isDeleting" class="csd-loading-container" centerColumns="true">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <div orgosColumn="0" class="kenjo-pl-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{dialogTranslation.deletingLoading}}</div>
  </orgos-column-container>
  <orgos-column-container *ngIf="!isDeleting" class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" *ngIf="!shiftsLimitReached">
      <orgos-button-raised (click)="dialogRef.close()" color="Neutral">{{dialogTranslation.goBack}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px" *ngIf="!shiftsLimitReached">
      <orgos-button-raised [disabled]="totalShifts === 0" (click)="deleteShifts()" color="Danger">{{dialogTranslation.yesDelete}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px" *ngIf="shiftsLimitReached">
      <orgos-button-raised (click)="closeDialog()" color="Success">{{dialogTranslation.okGotIt}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
