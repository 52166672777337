import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerformanceReviewToDosController {
  private PERFORMANCE_REVIEW_LIST_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/performance-review/to-dos`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) {}

  getToDosList(): Promise<Array<IToDo>> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewToDosController.name, 'getFilters');
      return Promise.reject(error);
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.get<Array<IToDo>>(`${this.PERFORMANCE_REVIEW_LIST_URL}/list`, httpOptions)
      .toPromise()
      .catch(error => {
        return Promise.reject(this.errorManager.handleRawError(error, PerformanceReviewToDosController.name, 'getToDos'));
      });
  }
}

export interface IToDo {
  name: 'nominatePeerReviewersTitle' | 'writeReviewTitle' | 'readReviewTitle' | 'nominateTeamReviewersTitle',
  performanceReviewId: string,
  performanceReviewTitle: string,
  shareFeedbackImmediately: boolean,
  status: 'notStarted' | 'started' | 'completed',
  dueDate: string,
  feedbacks: Array<any>,
  actionType: 'nominatePeersAction' | 'viewNominationsAction' | 'writeReviewAction' | 'readReviewAction' | 'viewNominationsSubordinatesAction' | 'nominateSubordinatesAction';
  feedbackType?: 'self' | 'manager' | 'direct' | 'peer';
  _createdAt: Date,
}

export interface IReviewerResponse {
    matrixValue: Array<any>,
    shared: boolean,
    _id: string,
    revieweeId: string,
    reviewerId?: string | null,
    status: 'notStarted' | 'started' | 'signed',
    type: 'Peer' | 'Direct' | 'Manager'
}