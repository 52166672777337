import { Injectable } from '@angular/core';
import {
  calculateDateIsAfterOrSameToday,
  calculateIsFutureEntry,
  calculateIsFutureOpenEntry,
  checkIsEmptyBreakOrShift,
  checkStartTimeBiggerThanEndTime,
} from '@app/standard/services/attendance/attendance-helpers';
import { IBreak, IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FutureEntriesService {
  error: IFutureEntryErrors;
  MINUTES_IN_DAY = 1440;

  constructor() {
    this.error = {
      isOpenShift: false,
      isFutureEntry: false,
      isFutureBreak: false,
      isOpenBreak: false,
    };
  }

  calculateIsFutureShift(userAttendance: IUserAttendanceModel, allowEntriesInTheFuture: boolean): IFutureEntryErrors {
    if (check.emptyObject(userAttendance) || (!userAttendance?.endTime && !userAttendance?.startTime)) {
      this.resetError();
      return this.error;
    }
    const clonedUserAttendance = _.cloneDeep({ ...userAttendance });
    if (checkStartTimeBiggerThanEndTime(clonedUserAttendance.startTime, clonedUserAttendance.endTime)) {
      clonedUserAttendance.endTime = clonedUserAttendance.endTime + this.MINUTES_IN_DAY;
    }
    const { date, startTime, endTime } = clonedUserAttendance;

    this.calculateOpenShiftError(endTime, startTime, date);
    if (this.error.isOpenShift) {
      this.error.isFutureEntry = false;
      return this.error;
    }

    if (allowEntriesInTheFuture === undefined) {
      return this.error;
    }
    this.calculateIsFutureEntry(endTime, startTime, date, allowEntriesInTheFuture);
    return this.error;
  }

  calculateIsFutureOpenBreak(
    userAttendance: IUserAttendanceModel,
    iBreaks: Array<IBreak>,
    allowEntriesInTheFuture: boolean
  ): IFutureEntryErrors {
    if (checkIsEmptyBreakOrShift(userAttendance) && !this.error.isFutureEntry && !this.error.isOpenShift) {
      this.resetError();
      return this.error;
    }

    if (!userAttendance?.breaks.length) {
      this.error.isOpenBreak = false;
      this.error.isFutureBreak = false;
      return this.error;
    }

    const { date } = userAttendance;

    iBreaks.forEach((iBreak) => {
      this.calculateIsOpenBreakError(date, iBreak);
    });
    if (this.error.isOpenBreak) {
      this.error.isFutureBreak = false;
      return this.error;
    }

    if (allowEntriesInTheFuture === undefined) {
      return this.error;
    }
    iBreaks.forEach((iBreak) => {
      this.calculateIsFutureBreakError(iBreak, date, allowEntriesInTheFuture);
    });
    return this.error;
  }

  calculateOpenShiftError(endTime: number, startTime: number, dateEntry: Date | moment.Moment) {
    const isFutureOpenEntry = calculateIsFutureOpenEntry(startTime, endTime, dateEntry);
    if (isFutureOpenEntry && calculateDateIsAfterOrSameToday(dateEntry)) {
      this.error.isOpenShift = true;
      return this.error;
    }
    this.error.isOpenShift = false;
    return this.error;
  }

  calculateIsFutureEntry(endTime: number, startTime: number, dateEntry: Date | moment.Moment, allowEntriesInTheFuture: boolean) {
    if (calculateIsFutureEntry(startTime, endTime, dateEntry) && !allowEntriesInTheFuture) {
      this.error.isFutureEntry = true;
      return this.error;
    }
    this.error.isFutureEntry = false;
    return this.error;
  }

  calculateIsFutureBreakError(iBreak: IBreak, dateEntry: Date | moment.Moment, allowEntriesInTheFuture: boolean) {
    const { start, end } = iBreak;
    if (calculateIsFutureEntry(start, end, dateEntry) && !allowEntriesInTheFuture) {
      this.error.isFutureBreak = true;
      return this.error;
    }
    this.error.isFutureBreak = false;
    return this.error;
  }

  calculateIsOpenBreakError(dateEntry: Date | moment.Moment, iBreak: IBreak) {
    const { start, end } = iBreak;
    const isFutureOpenBreak = calculateIsFutureOpenEntry(start, end, dateEntry);
    if (isFutureOpenBreak && calculateDateIsAfterOrSameToday(dateEntry) && (!start || !end)) {
      this.error.isOpenBreak = true;
      return this.error;
    }
    this.error.isOpenBreak = false;
    return this.error;
  }

  resetError() {
    this.error = {
      isOpenShift: false,
      isFutureEntry: false,
      isOpenBreak: false,
      isFutureBreak: false,
    };
    return this.error;
  }
}

export interface IFutureEntryErrors {
  isOpenShift: boolean;
  isFutureEntry: boolean;
  isOpenBreak: boolean;
  isFutureBreak: boolean;
}
