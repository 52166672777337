import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as check from 'check-types';

import { ISelectOption } from '../../../core/select-option';
import { StandardServicesRegistry } from '../../../services/standard-services.registry';

@Component({
  selector: 'orgos-dialog-add-workflow-trigger',
  templateUrl: 'add-workflow-trigger.dialog.html',
  styleUrls: ['add-workflow-trigger.dialog.scss'],
})
export class AddWorkflowTriggerDialog implements OnInit {
  pageTranslation: any = {};
  selectFieldOptions: Array<ISelectOption> = [];
  triggerFieldValue: string;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddWorkflowTriggerDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public parentInfo: any,
    private injector: Injector,
    private standardServicesRegistry: StandardServicesRegistry
  ) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('add-workflow-trigger-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.initSelectOptions();
  }

  private initSelectOptions(): void {
    this.parentInfo['collectionFields'].forEach((iField) => {
      if (check.assigned(this.parentInfo['collectionTranslation'][iField.name])) {
        this.selectFieldOptions.push({ name: this.parentInfo['collectionTranslation'][iField.name], value: iField.name });
      } else if (check.string(iField.name) && check.contains(iField.name, 'c_')) {
        const customFieldApiName = iField.name;
        let customFieldLabel = customFieldApiName.replace('c_', '');
        if (check.contains(customFieldApiName, '.')) {
          customFieldLabel = customFieldApiName.split('.')[1].replace('c_', '');
        }
        this.selectFieldOptions.push({ name: customFieldLabel, value: customFieldApiName });
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addAction(): void {
    if (check.assigned(this.triggerFieldValue) && check.nonEmptyString(this.triggerFieldValue)) {
      this.dialogRef.close(this.triggerFieldValue);
    }
  }
}
