<ng-container *ngIf="workflow && showSummary === false">
  <orgos-column-container centerColumns="true" class="ew-top-bar kenjo-border-bottom kenjo-ph-20px">
    <orgos-column size="0">
      <kenjo-icon size="20" (click)="onBackClick()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
    </orgos-column>

    <orgos-column size="0" class="ew-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-column size="0" class="kenjo-font-size-16px"
      >{{workflow.data['_id'] ? pageTranslation.pageNameEdit : pageTranslation.pageName}}</orgos-column
    >

    <orgos-column size="0" class="ew-separator kenjo-mh-20px kenjo-border-left"></orgos-column>
  </orgos-column-container>

  <div class="ew-main-container">
    <div class="kenjo-p-20px ew-content">
      <orgos-container aspect="card" class="kenjo-p-30px">
        <ol class="list-group vertical-steps kenjo-m-0">
          <li
            [ngClass]="{
            'list-group-item': !isRecurringWorkflow,
            'list-group-item-recurrent': isRecurringWorkflow,
            'completed': nameValidation && nameValidation.isValid() === true
          }"
          >
            <div class="kenjo-font-size-16px">{{pageTranslation.workflowDefinitionHeader}}</div>
            <orgos-column-container class="kenjo-pt-10px kenjo-ph-50px">
              <orgos-input-text
                orgosColumn="1"
                [model]="workflow"
                field="name"
                required="true"
                (validation)="nameValidation = $event"
              ></orgos-input-text>
              <orgos-column size="1"></orgos-column>
            </orgos-column-container>

            <orgos-column-container centerColumns="true" class="kenjo-pt-20px kenjo-ph-50px">
              <orgos-column size="0">
                <mat-slide-toggle
                  [checked]="isRecurringWorkflow"
                  (change)="changeInRecuringWorkflow($event)"
                  [disabled]="workflow.data['_id']"
                ></mat-slide-toggle>
              </orgos-column>
              <orgos-column size="0" class="kenjo-ml-10px" [class.kenjo-font-color-disabled-c4c4c4]="workflow.data['_id']"
                >{{ pageTranslation.isRecurringWorkflow }}</orgos-column
              >
            </orgos-column-container>

            <!-- RECURRING WORKFLOW CONFIGURATION STARTS -->
            <ng-container *ngIf="isRecurringWorkflow">
              <orgos-column-container centerColumns="true" class="kenjo-pt-30px kenjo-ph-50px">
                <orgos-input-simple-select
                  orgosColumn="1"
                  [label]="pageTranslation.repeatLabel"
                  [value]="workflow.data.scheduledOnSpecificDate.period"
                  (valueChange)="changePeriod($event)"
                  [options]="recurringRepeatOptions"
                  class="kenjo-mr-20px"
                  [autoOrder]="false"
                ></orgos-input-simple-select>
                <orgos-input-simple-date-picker
                  orgosColumn="1"
                  [label]="pageTranslation.startingOnLabel"
                  [(value)]="workflow.data.scheduledOnSpecificDate.startDate"
                  [min]="minDate"
                ></orgos-input-simple-date-picker>
              </orgos-column-container>

              <!-- DAILY RECURRING WORKFLOW STARTS -->
              <orgos-column-container
                centerColumns="true"
                class="kenjo-pt-5px kenjo-ph-50px"
                *ngIf="workflow.data.scheduledOnSpecificDate.period === 'day'"
              >
                <orgos-column-container orgosColumn="1" centerColumns="true" class="kenjo-mr-20px">
                  <orgos-input-simple-select
                    orgosColumn="1"
                    [label]="pageTranslation.everyLabel"
                    [(value)]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX"
                    [options]="everyXOptions"
                    class="kenjo-pr-5px"
                    [autoOrder]="false"
                  ></orgos-input-simple-select>
                  <orgos-column size="0"
                    >{{workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX === 1 ? pageTranslation.daysOption__singular :
                    pageTranslation.daysOption}}</orgos-column
                  >
                </orgos-column-container>
                <orgos-column size="1"></orgos-column>
              </orgos-column-container>
              <!-- DAILY RECURRING WORKFLOW ENDS -->

              <!-- WEEKLY RECURRING WORKFLOW STARTS -->
              <orgos-column-container
                centerColumns="true"
                class="kenjo-pt-5px kenjo-ph-50px"
                *ngIf="workflow.data.scheduledOnSpecificDate.period === 'week'"
              >
                <orgos-column-container orgosColumn="1" centerColumns="true" class="kenjo-mr-20px">
                  <orgos-input-simple-select
                    orgosColumn="1"
                    [label]="pageTranslation.everyLabel"
                    [(value)]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX"
                    [options]="everyXOptions"
                    class="kenjo-pr-5px"
                    [autoOrder]="false"
                  ></orgos-input-simple-select>
                  <orgos-column size="0"
                    >{{workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX === 1 ? pageTranslation.weeksOption__singular :
                    pageTranslation.weeksOption}}</orgos-column
                  >
                </orgos-column-container>
                <orgos-input-simple-select
                  orgosColumn="1"
                  [label]="pageTranslation.onLabel"
                  [autoOrder]="false"
                  [options]="weekdayOptions"
                  [(value)]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.onWeeklyConditions.onWeekday"
                ></orgos-input-simple-select>
              </orgos-column-container>
              <!-- WEEKLY RECURRING WORKFLOW ENDS -->

              <!-- MONTHLY RECURRING WORKFLOW STARTS -->
              <orgos-column-container
                centerColumns="true"
                class="kenjo-pt-5px kenjo-ph-50px"
                *ngIf="workflow.data.scheduledOnSpecificDate.period === 'month'"
              >
                <orgos-column-container orgosColumn="1" centerColumns="true" class="kenjo-pr-20px">
                  <orgos-input-simple-select
                    orgosColumn="1"
                    [label]="pageTranslation.everyLabel"
                    [(value)]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX"
                    [options]="everyXOptions"
                    class="kenjo-pr-5px"
                    [autoOrder]="false"
                  ></orgos-input-simple-select>
                  <orgos-column size="0"
                    >{{workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX === 1 ? pageTranslation.monthsOption__singular :
                    pageTranslation.monthsOption}}</orgos-column
                  >
                </orgos-column-container>
                <orgos-column size="1">
                  <mat-radio-group [(ngModel)]="monthlyVariant">
                    <mat-radio-button value="specificDate">
                      <orgos-column-container
                        centerColumns="true"
                        [class.kenjo-font-color-disabled-c4c4c4]="monthlyVariant !== 'specificDate'"
                      >
                        <orgos-column size="0">{{pageTranslation.onDay}}</orgos-column>
                        <orgos-input-simple-select
                          orgosColumn="0"
                          [autoOrder]="false"
                          [readOnly]="monthlyVariant !== 'specificDate'"
                          class="kenjo-ph-5px ew-small-picklist"
                          [options]="monthlySpecificDateOptions"
                          [(value)]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificDate"
                        ></orgos-input-simple-select>
                        <orgos-column size="0">{{pageTranslation.ofEveryMonth}}</orgos-column>
                      </orgos-column-container>
                    </mat-radio-button>
                    <mat-radio-button value="certainDayInWeek" class="ew-bottom-radio-button">
                      <orgos-column-container
                        centerColumns="true"
                        [class.kenjo-font-color-disabled-c4c4c4]="monthlyVariant !== 'certainDayInWeek'"
                      >
                        <orgos-column size="0">{{pageTranslation.onThe}}</orgos-column>
                        <orgos-input-simple-select
                          orgosColumn="0"
                          [autoOrder]="false"
                          [readOnly]="monthlyVariant !== 'certainDayInWeek'"
                          class="kenjo-ph-5px ew-medium-picklist"
                          [options]="monthlySpecificWeekOptions"
                          [(value)]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek"
                        ></orgos-input-simple-select>
                        <orgos-input-simple-select
                          orgosColumn="0"
                          [autoOrder]="false"
                          [readOnly]="monthlyVariant !== 'certainDayInWeek'"
                          class="kenjo-ph-5px ew-medium-picklist"
                          [options]="weekdayOptions"
                          [(value)]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onWeekday"
                        ></orgos-input-simple-select>
                        <orgos-column size="0">{{pageTranslation.ofEveryMonth}}</orgos-column>
                      </orgos-column-container>
                    </mat-radio-button>
                  </mat-radio-group>
                </orgos-column>
              </orgos-column-container>
              <!-- MONTHLY RECURRING WORKFLOW ENDS -->

              <orgos-column-container
                centerColumns="true"
                class="kenjo-pt-5px kenjo-ph-50px"
                *ngIf="workflow.data.scheduledOnSpecificDate.period !== 'once'"
              >
                <orgos-input-simple-select
                  orgosColumn="1"
                  [label]="pageTranslation.endsOnLabel"
                  [value]="recurringEnds"
                  (valueChange)="changeRecurringEnds($event)"
                  [options]="recurringEndsOptions"
                  class="kenjo-pr-20px"
                  [autoOrder]="false"
                ></orgos-input-simple-select>
                <orgos-input-simple-date-picker
                  *ngIf="recurringEnds === 'on'"
                  orgosColumn="1"
                  [(value)]="workflow.data.scheduledOnSpecificDate.stopAfter"
                  (valueChange)="setRecurringEndDate($event)"
                  [min]="workflow.data.scheduledOnSpecificDate.conditionsForEvery.startDate"
                ></orgos-input-simple-date-picker>
                <orgos-column size="1" *ngIf="recurringEnds !== 'on'"></orgos-column>
              </orgos-column-container>
            </ng-container>
            <!-- RECURRING WORKFLOW CONFIGURATION ENDS -->
          </li>
          <li *ngIf="isRecurringWorkflow" class="list-group-item-recurrent-last"></li>

          <li *ngIf="!isRecurringWorkflow" class="list-group-item kenjo-pt-40px" [class.completed]="chosenCollection">
            <div class="kenjo-font-size-16px">{{pageTranslation.line2Header}}</div>
            <orgos-column-container>
              <orgos-input-simple-select
                *ngIf="objectSelectOptions && objectSelectOptions.length > 0"
                orgosColumn
                [label]="workflow.data['_id'] ? pageTranslation.objectSelectedLabel : pageTranslation.selectObjectLabel"
                [value]="chosenCollection"
                [options]="objectSelectOptions"
                (valueChange)="selectCollection($event)"
                [readOnly]="workflow.data['_id']"
                class="kenjo-pt-10px kenjo-ph-50px"
              ></orgos-input-simple-select>
              <orgos-column></orgos-column>
            </orgos-column-container>
          </li>

          <li *ngIf="!isRecurringWorkflow" class="list-group-item kenjo-pt-20px" [class.completed]="chosenUseCase">
            <div class="kenjo-font-size-16px">{{pageTranslation.line3Header}}</div>
            <ng-container *ngIf="listCarouselItems">
              <div *ngFor="let iCarouselItems of listCarouselItems" class="kenjo-pt-20px kenjo-ph-50px">
                <orgos-row-selection
                  *ngFor="let iCarouselItem of iCarouselItems"
                  groupName="useCaseName"
                  class="kenjo-pb-10px"
                  [id]="iCarouselItem.labelKey"
                  [title]="pageTranslation[iCarouselItem.labelKey] ? pageTranslation[iCarouselItem.labelKey] : iCarouselItem.labelKey"
                  [subtitle]="pageTranslation[iCarouselItem.subtitleKey]"
                  [selected]="iCarouselItem.selected"
                  (select)="selectUseCase($event)"
                  [readOnly]="workflow.data['_id']"
                >
                </orgos-row-selection>
              </div>
            </ng-container>
            <orgos-button-raised
              *ngIf="this.collectionFields && !workflow.data['_id']"
              (click)="addTrigger()"
              class="kenjo-block kenjo-mh-50px"
            >
              {{pageTranslation.createUseCase}}
            </orgos-button-raised>
          </li>

          <li
            *ngIf="!isRecurringWorkflow"
            class="list-group-item kenjo-pt-40px"
            [class.completed]="workflow['data']['delayInstant'] === 'immediately' || workflow['data']['delayInstant'] === 'sameDay' || (delayInstantValidation && delayInstantValidation.isValid() === true && delayNumberValidation && delayNumberValidation.isValid() === true && workflow['data']['delayInstant'])"
          >
            <div class="kenjo-font-size-16px">{{pageTranslation.line4Header}}</div>
            <orgos-column-container class="kenjo-pt-10px kenjo-ph-50px" centerColumns="true">
              <orgos-input-number
                orgosColumn="1"
                step="1"
                [min]="0"
                [readOnly]="!workflow || (workflow.data.delayInstant && (workflow.data.delayInstant === 'immediately' || workflow.data.delayInstant === 'sameDay'))"
                [model]="workflow"
                field="delayNumber"
                [required]="workflow['data']['delayInstant'] !== 'immediately'"
                (validation)="delayNumberValidation = $event"
              >
                <orgos-input-error *ngIf="delayNumberValidation && delayNumberValidation.getError('min')">
                  {{pageTranslation.validationDelayLessThanZero}}
                </orgos-input-error>
                <orgos-input-error
                  *ngIf="delayNumberValidation && !delayNumberValidation.getError('min') &&delayNumberValidation.getError('integer')"
                >
                  {{pageTranslation.validationDelayInteger}}
                </orgos-input-error>
              </orgos-input-number>
              <orgos-input-select
                orgosColumn="1"
                class="kenjo-pl-20px"
                required="true"
                [options]="selectDelayInstantOptions"
                [model]="workflow"
                field="delayInstant"
                (modelChange)="evaluateDelayNumber()"
                (validation)="delayInstantValidation = $event"
              ></orgos-input-select>
              <orgos-column size="1" class="kenjo-pl-20px">{{clarificationText}}</orgos-column>
            </orgos-column-container>
          </li>

          <li
            *ngIf="!isRecurringWorkflow"
            class="list-group-item"
            [class.list-group-item-filter-not-set]="!filters || filters.length === 0"
            [class.completed]="filters?.length > 0"
          >
            <a
              *ngIf="!filters || filters.length === 0"
              (click)="addFilter()"
              class="ew-add-filter-icon"
              [class.kenjo-font-color-disabled-c4c4c4]="!selectCollection || !chosenUseCase"
              ><mat-icon class="ew-mat-icon-add">add_circle</mat-icon></a
            >
            <orgos-column-container *ngIf="!filters || filters.length === 0">
              <orgos-action
                orgosColumn="0"
                class="kenjo-font-size-14px kenjo-mr-5px"
                [disabled]="!selectCollection || !chosenUseCase"
                (click)="addFilter()"
                >{{pageTranslation.line5Header}}</orgos-action
              >
              <div orgosColumn="0" class="kenjo-font-size-14px kenjo-font-color-light-text-757575">
                {{pageTranslation.line5HeaderOptional}}
              </div>
            </orgos-column-container>

            <div *ngIf="filters?.length > 0" class="kenjo-font-size-16px">Filters</div>

            <div *ngIf="collectionFields && objectTranslation" class="kenjo-ph-50px kenjo-pt-15px">
              <ng-container *ngFor="let iFilter of filters; let filterIndex = index; let lastFilter = last;">
                <orgos-container aspect="card" class="kenjo-mb-30px">
                  <orgos-column-container
                    class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-bottom kenjo-pv-10px"
                    centerColumns="true"
                  >
                    <orgos-column size="1" class="kenjo-pl-20px kenjo-font-size-16px"> Filter&nbsp;{{filterIndex+1}} </orgos-column>
                    <kenjo-icon
                      [size]="20"
                      orgosColumn="0"
                      (click)="deleteFilter(filterIndex)"
                      class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757 kenjo-ph-20px"
                      >delete</kenjo-icon
                    >
                  </orgos-column-container>

                  <ng-container *ngFor="let iCondition of iFilter.conditions; let lastCondition = last;">
                    <orgos-workflow-filter
                      [chosenCollection]="chosenCollection"
                      [workflowFilter]="iCondition"
                      [listFields]="collectionFields"
                      [objectTranslation]="objectTranslation"
                      [customFieldsAvailable]="customFieldsAvailable"
                      (delete)="deleteCondition($event, filterIndex)"
                      (edit)="filterUpdate()"
                      [allowIsChangedOperator]="chosenUseCase.type === 'update' && chosenUseCase.immediate === true"
                    ></orgos-workflow-filter>
                    <div class="ew-flex-container kenjo-mb-30px" *ngIf="lastCondition === true">
                      <kenjo-action-button (click)="addCondition(filterIndex)">{{pageTranslation.andCondition}}</kenjo-action-button>
                    </div>
                    <div *ngIf="lastCondition === false" class="ew-flex-container">
                      <div class="ew-condition-chip kenjo-border">
                        <div class="kenjo-p-5px kenjo-font-size-14px">{{pageTranslation.andCondition}}</div>
                      </div>
                    </div>
                  </ng-container>
                </orgos-container>

                <div *ngIf="lastFilter === false" class="ew-flex-container kenjo-mb-30px">
                  <div class="ew-condition-chip kenjo-border">
                    <div class="kenjo-p-5px kenjo-font-size-14px">{{pageTranslation.orCondition}}</div>
                  </div>
                </div>
              </ng-container>
              <kenjo-action-button *ngIf="filters && filters.length > 0" class="kenjo-ml-30px" (click)="addFilter()"
                >{{pageTranslation.orCondition}}</kenjo-action-button
              >
            </div>
          </li>
        </ol>

        <div class="kenjo-text-align-right kenjo-mt-60px">
          <orgos-button-raised
            *ngIf="!isRecurringWorkflow"
            [disabled]="!workflow['data']['delayInstant'] || filtersAreComplete === false || !nameValidation || nameValidation.isValid() === false || !chosenCollection || !chosenUseCase ||
                    (workflow['data']['delayInstant'] !== 'immediately' &&
                        (!delayInstantValidation || delayInstantValidation.isValid() === false ||
                        (workflow['data']['delayInstant'] !== 'sameDay' &&
                                (!delayNumberValidation || delayNumberValidation.isValid() === false || !workflow['data']['delayNumber'] || workflow['data']['delayNumber'] === 0))))"
            (click)="saveWorkflow()"
            >{{pageTranslation.createWorkflowButton}}</orgos-button-raised
          >
          <orgos-button-raised
            *ngIf="isRecurringWorkflow"
            [disabled]="!nameValidation || nameValidation.isValid() === false"
            (click)="saveRecurringWorkflow()"
          >
            {{pageTranslation.createWorkflowButton}}
          </orgos-button-raised>
        </div>
      </orgos-container>
    </div>
  </div>
</ng-container>

<orgos-workflow-summary
  *ngIf="showSummary === true"
  [chosenCollection]="chosenCollection"
  [workflow]="workflow"
  [customFieldsAvailable]="customFieldsAvailable"
  (goBack)="reloadWorkflowSettings()"
></orgos-workflow-summary>
