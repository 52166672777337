import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as check from 'check-types';

import { InputValidation } from '../../../core/validation/input-validation';
import { InternationalizationService } from '../../../services/core/internationalization.service';

@Component({
  selector: 'orgos-edit-document-import-name.dialog',
  templateUrl: 'edit-document-import-name.dialog.html',
  styleUrls: ['edit-document-import-name.dialog.scss'],
})
export class EditDocumentImportNameDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};
  documentNewName: string;
  fieldLabelValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditDocumentImportNameDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any
  ) {}

  ngOnInit(): void {
    this.documentNewName = this.dialogInfo;

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-import-name-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  updateDocumentsName(): void {
    if (check.not.assigned(this.fieldLabelValidation) || this.fieldLabelValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close(this.documentNewName);
  }
}
