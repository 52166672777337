import { ErrorCodes } from './error-codes';

export class OrgosError {
  public name: string;
  public originalError: any;
  public errorCode: ErrorCodes;
  public serviceName: string;
  public operationName: string;
  public message: string;
  public reloadApp: boolean;

  constructor(originalError: any | undefined, errorCode: ErrorCodes = ErrorCodes.UNKNOWN, serviceName: string = 'unknownService', operationName: string = 'unknownOperation') {
    this.name = 'OrgosError';
    this.originalError = originalError;
    this.errorCode = errorCode;
    this.serviceName = serviceName;
    this.operationName = operationName;
    this.reloadApp = false;
  }
}
