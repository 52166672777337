import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';

import { AuthenticationService } from '../../../services/core/authentication.service';
import { CloudRoutesService } from '../../../services/core/cloud-routes.service';
import { DocumentTagService, IDocumentTagModel } from '../../../services/document/document-tag.service';
import { DocumentService, IDocumentModel } from '../../../services/document/document.service';
import { GenericPage, IMenuOption, ITranslationResource } from '../../generic.page';
import { IUploadDocumentConfig, UploadDocumentDialog } from '../dialogs/upload-document.dialog';

@Component({
  selector: 'orgos-documents-company-docs',
  templateUrl: 'documents-company-docs.page.html',
  styleUrls: ['documents-company-docs.page.scss'],
})
export class DocumentsCompanyDocsPage extends GenericPage {
  MAX_DOC_SIZE: number = 50;

  translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'documents-misc' },
    { name: 'page', translationKey: 'documents-company-docs-page' },
  ];

  hasUploadPermission: boolean = false;
  allCompanyDocs: Array<IDocumentModel> = [];
  allDocumentTags: Array<IDocumentTagModel> = [];

  enabledTags: Array<string> = [];
  tagsToDisplay: Array<IDocumentTagModel> = [];
  companyDocsToDisplay: Array<IDocumentModel> = [];

  unlabelledTag: IDocumentTagModel;
  unlabelledDocs: boolean = false;
  unlabelledFilter: boolean = false;

  wrapperHeight: number;

  protected async beforeInit(): Promise<void> {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.hasUploadPermission = await this.injector
      .get(PrivateSecurityService)
      .computePermissions(loggedUser, 'document-app.c_uploadCompanyDocs');

    return Promise.resolve();
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.injector
      .get(DocumentService)
      .getCompanyDocs()
      .then((allCompanyDocs: Array<IDocumentModel>) => {
        this.allCompanyDocs = allCompanyDocs;

        this.filterDocuments();
        this.filterTags();

        resolveFetchData();
      })
      .catch(() => {
        // An error is already shown
        this.allCompanyDocs = [];

        rejectFetchData();
      });
  }

  protected fetchLazyData(): void {
    this.injector
      .get(DocumentTagService)
      .getDocumentTags()
      .then((allDocumentTags: Array<IDocumentTagModel>) => {
        this.allDocumentTags = allDocumentTags;
        this.enabledTags = [];

        this.filterDocuments();
        this.filterTags();
        this.addUnlabelledTagToFilter();
      })
      .catch((error) => {
        // An error is already shown
        this.allDocumentTags = [];
      });
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    const options: Array<IMenuOption> = [
      { name: this.i18n.misc.myDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/my-docs') },
      { name: this.i18n.misc.companyDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/company-docs') },
    ];

    if (this.injector.get(CloudRoutesService).checkRoute('documents/employee-docs') === true) {
      options.push({ name: this.i18n.misc.employeeDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/employee-docs') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/digital-signature') === true) {
      options.push({
        name: this.i18n.misc.digitalSignatureTab,
        onClick: () => this.router.navigateByUrl('/cloud/documents/digital-signature'),
      });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/templates') === true) {
      options.push({ name: this.i18n.misc.templatesTab, onClick: () => this.router.navigateByUrl('/cloud/documents/templates') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/import') === true) {
      options.push({ name: this.i18n.misc.importTab, onClick: () => this.router.navigateByUrl('/cloud/documents/import') });
    }

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 1;

    return Promise.resolve();
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view smart docs page', { category: 'Navigation', type: 'company docs' });
    return Promise.resolve();
  }

  getColor(color: string): string {
    if (check.not.assigned(color) || color === '') {
      return '#757575';
    }

    return userColorConstants[color];
  }

  onChangeFilter(show: boolean, tag: IDocumentTagModel): void {
    if (check.not.assigned(tag)) {
      return;
    }

    if (check.not.assigned(show) || show === false) {
      this.enabledTags = this.enabledTags.filter((iTagId) => {
        return iTagId !== tag._id;
      });
    } else {
      this.enabledTags = this.enabledTags.concat([tag._id]);
    }

    this.filterDocuments();
  }

  public uploadDocument(): void {
    const uploadConfig: IUploadDocumentConfig = {
      relatedTo: {
        typeRelatedTo: 'Company',
      },
    };

    this.injector.get(PrivateAmplitudeService).logEvent('intent to add company doc', { platform: 'Web', category: 'PQL' });
    this.injector.get(PrivateIntegrationsService).trackChameleonEvent('intent to add company doc');

    this.injector
      .get(UppyHelperService)
      .uploadGenericDocument({ maxDocSize: this.MAX_DOC_SIZE })
      .then((rawDocument: IFileMetadata | null) => {
        if (check.not.assigned(rawDocument)) {
          return Promise.resolve(null);
        }

        return new Promise<void>((resolve, reject) => {
          const dialogData = {
            rawDocument,
            uploadConfig,
          };

          const dialogRef = this.injector.get(MatLegacyDialog).open(UploadDocumentDialog, { data: dialogData });
          dialogRef.afterClosed().subscribe((uploadedDocument: IDocumentModel) => {
            if (check.not.assigned(uploadedDocument)) {
              return;
            }
            this.injector.get(PrivateChurnzeroService).logSimpleEvent('COMPANY_SMARTDOC_ADDED');
            resolve();
          });
        });
      })
      .then(() => {
        return this.refreshData();
      })
      .then(() => {
        this.addUnlabelledTagToFilter();
      })
      .then(() => {
        return this.refreshGlobalBar();
      })
      .then(() => {
        // Do nothing
      })
      .catch(() => {
        // An error already shown
        this.refreshData();
      });
  }

  private filterTags(): void {
    this.tagsToDisplay = this.allDocumentTags.filter((iTag) => {
      const someDocumentHasTag = this.allCompanyDocs.some((iDocument) => {
        return check.contains(iDocument.tags, iTag._id);
      });

      return someDocumentHasTag;
    });

    if (this.hasUploadPermission && check.assigned(this.tagsToDisplay) && this.tagsToDisplay.length > 0) {
      this.wrapperHeight = 178;
    } else if (!this.hasUploadPermission && check.assigned(this.tagsToDisplay) && this.tagsToDisplay.length > 0) {
      this.wrapperHeight = 128;
    } else if (this.hasUploadPermission && (check.not.assigned(this.tagsToDisplay) || this.tagsToDisplay.length === 0)) {
      this.wrapperHeight = 121;
    } else {
      this.wrapperHeight = 71;
    }
  }

  private addUnlabelledTagToFilter(): void {
    this.allCompanyDocs.forEach((doc) => {
      if (doc.tags.length === 0) {
        this.unlabelledDocs = true;
      }
    });
    if (this.unlabelledDocs) {
      this.setUnlabelledTag();
      this.tagsToDisplay.indexOf(this.unlabelledTag) === -1 ? this.tagsToDisplay.push(this.unlabelledTag) : '';
    }
  }

  private setUnlabelledTag(): void {
    this.unlabelledTag = this.allDocumentTags.find((tag) => tag._id === picklists.DOCUMENT_UNLABELLED_ID);
  }

  private filterDocuments(): void {
    this.companyDocsToDisplay = this.allCompanyDocs.filter((iDocument) => {
      if (this.enabledTags.length === 0) {
        return true;
      }

      let hasSomeEnabledTag: boolean;

      hasSomeEnabledTag = iDocument.tags.some((iTagId) => {
        return check.contains(this.enabledTags, iTagId);
      });

      if (this.unlabelledDocs && this.enabledTags.some((tag) => tag === picklists.DOCUMENT_UNLABELLED_ID) && iDocument.tags.length === 0) {
        hasSomeEnabledTag = true;
      }

      return hasSomeEnabledTag;
    });
  }
}
