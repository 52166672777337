<orgos-column-container *ngIf="loadingPage === false" class="dmds-action-bar kenjo-mb-20px" centerColumns="true">
  <orgos-column size="0" class="kenjo-mr-10px">
    <orgos-button-raised [disabled]="selectedSignatures?.length === 0" [matMenuTriggerFor]="massMenu">{{ i18n.page.massEditButton }}</orgos-button-raised>
    <mat-menu #massMenu="matMenu">
      <div [matTooltip]="i18n.page.oneActionLimited" [matTooltipDisabled]="allSelectedAreInReady">
        <button mat-menu-item [disabled]="!allSelectedAreInReady" (click)="sendReminders(selectedSignatures)">{{i18n.page.sendReminder}}</button>
      </div>
      <div [matTooltip]="!allSelectedAreImportError? i18n.page.oneActionLimited : creditsAvailable < 0 ? i18n.importPage.layoutImportPayslips.noMoreCredits : ''" [matTooltipDisabled]="allSelectedAreImportError && creditsAvailable >= 0">
        <button mat-menu-item [disabled]="!allSelectedAreImportError || creditsAvailable < 0" (click)="sendSignatureAgain(selectedSignatures)">{{i18n.page.requestSignatureAgain}}</button>
      </div>
      <div [matTooltip]="i18n.page.oneActionLimited" [matTooltipDisabled]="!someSelectedAreImportError">
        <button mat-menu-item [disabled]="someSelectedAreImportError" (click)="voidDocuments(selectedSignatures)">{{i18n.page.voidDocument}}</button>
      </div>
    </mat-menu>
  </orgos-column>
  <orgos-column *ngIf="selectedSignatures?.length > 0" size="0" class="kenjo-font-color-light-text-757575">{{signaturesSelectedText}}</orgos-column>
  <orgos-column></orgos-column>
  <orgos-column size="0" class="kenjo-ml-20px kenjo-text-align-right">
    <orgos-action icon="add_circle" iconSize="large" (click)="uploadDocument()">{{ i18n.misc.uploadDocumentButtonTooltip }}</orgos-action>
  </orgos-column>
</orgos-column-container>

<!-- FILTER BAR -->
<kenjo-filter-bar
  [filterOptions]="filters"
  [shown]="true"
  [searchTerm]="queryOptions.search"
  (changeFilterEvent)="toggleFilter($event)"
  (clearFilterEvent)="clearFilter($event)"
  (clearAllFiltersEvent)="clearAllFilters()"
  (searchTermChanged)="searchByTerm($event)"
></kenjo-filter-bar>

<ng-container *ngIf="loadingPage === false">
  <!-- No docs -->
  <div *ngIf="signatureDocuments?.length === 0" class="kenjo-p-20px">
    <div *ngIf="profilePermissions['document'] && (profilePermissions['document'].create_all || profilePermissions['document'].create_own)" class="kenjo-text-align-right">
      <orgos-action icon="add_circle" iconSize="large" (click)="uploadDocument()">{{i18n.page.uploadDocumentButtonLabel}}</orgos-action>
    </div>
    <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noDocumentsTitle}}</div>
      <div class="kenjo-mt-5px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.noDocumentsSubtitle}}</div>
    </div>
  </div>

  <!-- LIST VIEW -->
  <orgos-container aspect="card" class="kenjo-mt-20px" *ngIf="signatureDocuments?.length > 0">
    <orgos-table
      [selectable]="true"
      [fullHeight]="false"
      [customHeightToSubstract]="signatureDocuments?.length > 25 ? 330 : 0"
      [style.height]="signatureDocuments?.length > 25 ? 'calc(100vh - 270px)' : '100%'"
      [data]="signatureDocuments"
      [displayedColumns]="totalColumns"
      [stickyLastColumn]="true"
      (changeSelection)="selectListItem($event)"
      [selectedList]="selectedSignatures"
      [sortingDataAccessor]="sortingDataAccessor"
      [tooltipExcludeRows]="i18n.page.noMoreActionsTooltip"
    >
      <orgos-table-column columnKey="select" [sortable]="false" [actionColumn]="true">
        <div *orgosTableCell="let row"></div>
      </orgos-table-column>

      <orgos-table-column columnKey="document" [header]="i18n.page.documentHeader" [sortable]="true">
        <orgos-column-container *orgosTableCell="let row" centerColumns="true">
          <orgos-document-icon orgosColumn="0" size="small" extension="pdf" class="kenjo-pr-10px"></orgos-document-icon>
          <a *ngIf="row.document._id" orgosColumn="0" (click)="viewDocument(row)">
            <kenjo-truncate-text class="dmds-relevant-field">{{row.document.name}}</kenjo-truncate-text>
          </a>
          <kenjo-truncate-text class="dmds-relevant-field" [matTooltip]="i18n.page.noOriginalDoc" orgosColumn="0" *ngIf="!row.document._id && row.status !== 'completed'">{{row.document.name}}</kenjo-truncate-text>
          <kenjo-truncate-text class="dmds-relevant-field" [matTooltip]="i18n.page.noSignedDoc" orgosColumn="0" *ngIf="!row.document._id && row.status === 'completed'">{{row.document.name}}</kenjo-truncate-text>
        </orgos-column-container>
      </orgos-table-column>

      <orgos-table-column columnKey="recipient" [header]="i18n.page.recipientHeader" [sortable]="true">
        <orgos-column-container *orgosTableCell="let row" centerColumns="true">
          <orgos-avatar *ngIf="row.signer && !row.signer._isArchived" orgosColumn="0" [avatarName]="row.signer.displayName" [photoUrl]="row.signer._photo?._url" size="tiny" class="kenjo-cursor-pointer" (click)="openEmployeeDetail(row.signer._id)"></orgos-avatar>
          <a *ngIf="row.signer && !row.signer._isArchived" orgosColumn="1" class="kenjo-ml-10px" (click)="openEmployeeDetail(row.signer._id)">
            <kenjo-truncate-text class="dmds-relevant-field">{{row.signer.displayName}}</kenjo-truncate-text>
          </a>
          <kenjo-truncate-text *ngIf="row.signer && row.signer._isArchived" orgosColumn="1" class="dmds-relevant-field">{{i18n.page.archived}}</kenjo-truncate-text>
        </orgos-column-container>
      </orgos-table-column>

      <orgos-table-column columnKey="recipient-email" [header]="i18n.page.recipientEmailHeader" [sortable]="false">
        <ng-container *orgosTableCell="let row">
          <kenjo-truncate-text class="dmds-relevant-field" *ngIf="row.signer"> {{ row.signer._isArchived ? i18n.page.archived : row.signer.email }} </kenjo-truncate-text>
        </ng-container>
      </orgos-table-column>

      <orgos-table-column columnKey="author" [header]="i18n.page.authorHeader" [sortable]="false">
        <orgos-column-container *orgosTableCell="let row" centerColumns="true">
          <orgos-avatar orgosColumn="0" [avatarName]="row.author.displayName" [photoUrl]="row.author._photo?._url" size="tiny" class="kenjo-cursor-pointer" (click)="openEmployeeDetail(row.author._id)"></orgos-avatar>
          <a orgosColumn="0" class="kenjo-ml-10px" (click)="openEmployeeDetail(row.author._id)">
            <kenjo-truncate-text class="dmds-relevant-field">{{row.author.displayName}}</kenjo-truncate-text>
          </a>
        </orgos-column-container>
      </orgos-table-column>

      <orgos-table-column columnKey="sent-on" [header]="i18n.page.sentOnHeader" [sortable]="true">
        <span *orgosTableCell="let row" class="dmds-relevant-field">{{ row._createdAt | date:"short" }}</span>
      </orgos-table-column>

      <orgos-table-column columnKey="tags" [header]="i18n.page.tagsHeader">
        <!-- tags-->
        <ng-container *orgosTableCell="let row">
          <div *ngIf="row.document.tags?.length === 0">
            <div class="dmds-tag-container kenjo-pr-5px">
              <orgos-chip color="#757575" class="kenjo-text-align-center">
                <kenjo-truncate-text class="dmds-tag-text kenjo-font-color-white-ffffff">{{ i18n.page.unlabelledTagName }}</kenjo-truncate-text>
              </orgos-chip>
            </div>
          </div>
          <div *ngIf="row.document.tags?.length < 4">
            <div *ngFor="let tag of row.document.tags" class="dmds-tag-container kenjo-pr-5px">
              <orgos-chip *ngIf="tag.color" [color]="tag.color" class="kenjo-text-align-center">
                <kenjo-truncate-text class="dmds-tag-text kenjo-font-color-white-ffffff">{{tag.name }} </kenjo-truncate-text>
              </orgos-chip>
            </div>
          </div>
          <orgos-column-container *ngIf="row.document.tags?.length > 3">
            <orgos-column size="0" *ngFor="let tag of row.document.tags; index as i" class="kenjo-pr-5px" [class.dmds-tag-container]="i < 3">
              <orgos-chip *ngIf="tag.color && i < 3" [color]="tag.color" class="kenjo-text-align-center">
                <kenjo-truncate-text class="dmds-tag-text kenjo-font-color-white-ffffff">{{tag.name }} </kenjo-truncate-text>
              </orgos-chip>
              <div *ngIf="i === 3 && row.document.processedTooltip" class="dmds-tag-circle kenjo-pr-5px" [matTooltip]="row.document.processedTooltip.join('\n')" matTooltipClass="allow-cr">
                <div>+{{ row.document.tags?.length - 3 }}</div>
              </div>
            </orgos-column>
          </orgos-column-container>
        </ng-container>
      </orgos-table-column>

      <orgos-table-column columnKey="status" [header]="i18n.page.statusHeader" [cellWidth]="300">
        <div *orgosTableCell="let row" class="dmds-sticky-right-column">
          <div class="dmds-status-chip-container">
            <orgos-chip [color]="statusInfo[row.status].color">
              <div class="dmds-status-text">{{ i18n.page[statusInfo[row.status].translationField] }}</div>
            </orgos-chip>
          </div>
          <kenjo-icon size="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer kenjo-m-10px"> more_vert </kenjo-icon>
          <mat-menu #actionsMenu="matMenu">
            <button mat-menu-item *ngIf="row.document._id" (click)="viewDocument(row)">{{i18n.page.viewDocument}}</button>
            <button mat-menu-item *ngIf="row.status === 'ready'" (click)="sendReminders([row])">{{i18n.page.sendReminder}}</button>
            <div *ngIf="row.status === 'import-error'" [matTooltip]="i18n.importPage.layoutImportPayslips.noMoreCredits" [matTooltipDisabled]="originalAvailable - 1 >= 0">
              <button mat-menu-item [disabled]="originalAvailable - 1 < 0" (click)="sendSignatureAgain([row])">{{i18n.page.requestSignatureAgain}}</button>
            </div>
            <button mat-menu-item *ngIf="row.status === 'pending' || row.status === 'ready'" (click)="voidDocuments([row])">{{i18n.page.voidDocument}}</button>
          </mat-menu>
        </div>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>
</ng-container>
