import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PrivateSignInService } from '@app/private/services/private-sign-in.service';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class SignInWithGoogleGuard implements CanActivate {
  ORIGIN_URL_KEY = 'originalUrl';

  constructor(private injector: Injector) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const codeToken = route.queryParamMap.get('code');
      const redirectUri = route.queryParamMap.get('state');

      if (check.not.assigned(codeToken) || check.not.assigned(redirectUri)) {
        this.injector.get(Router).navigate(['/signin']);
        resolve(false);
        return;
      }

      const originTxt = localStorage.getItem(this.ORIGIN_URL_KEY);

      if (check.assigned(originTxt)) {
        localStorage.removeItem(this.ORIGIN_URL_KEY);
        const origin = JSON.parse(originTxt);
        origin.isSSO = true;
        localStorage.setItem(this.ORIGIN_URL_KEY, JSON.stringify(origin));
      }

      this.injector
        .get(PrivateSignInService)
        .signInWithGoogle(codeToken, redirectUri)
        .then(() => {
          this.injector.get(Router).navigate(['/cloud']);
          resolve(false);
        })
        .catch(() => {
          // An error is already shown

          this.injector.get(Router).navigate(['/signin']);
          resolve(false);
        });
    });
  }
}
