<orgos-column-container centerColumns="true">
  <orgos-column size="1">
    <div>
      <orgos-button-raised color="Basic" class="gb-view-button kenjo-font-size-14px" [matMenuTriggerFor]="massMenu">
        <span class="kenjo-font-color-text-333333">{{ groupByFieldLabel }}:</span>
        {{ listGroupings?.get(groupingValue) }}
        <kenjo-icon class="gb-arrow-icon" [size]="24">arrow_drop_down</kenjo-icon>
      </orgos-button-raised>
      <mat-menu #massMenu="matMenu">
        <orgos-column-container centerColumns="true" *ngFor="let groupOption of listGroupings | keyvalue: originalOrder">
          <orgos-column>
            <button (click)="groupingValue = groupOption.key; groupingValueChange.emit(groupingValue)" mat-menu-item class="gb-view-name-button">
              <kenjo-truncate-text [style.min-width.px]="150">{{ groupOption.value }}</kenjo-truncate-text>
            </button>
          </orgos-column>
        </orgos-column-container>
      </mat-menu>
    </div>
  </orgos-column>
</orgos-column-container>
