import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class CalendarService implements IGenericService {
  private CALENDAR_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/calendar-db`;
  private CALENDAR_PERMISSIONS_KEY: string = 'calendar';
  private CALENDAR_INTERNATIONALIZATION: string = 'calendar-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService) {}

  create(data: object): Promise<ICalendarModel> {
    return new Promise<ICalendarModel>((resolve, reject) => {
      this.genericService
        .create(this.CALENDAR_URL, data)
        .then((calendar: ICalendarModel) => {
          resolve(calendar);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<ICalendarModel> {
    return new Promise<ICalendarModel>((resolve, reject) => {
      this.genericService
        .getById(this.CALENDAR_URL, id)
        .then((calendar: ICalendarModel) => {
          resolve(calendar);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.CALENDAR_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.CALENDAR_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.CALENDAR_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.CALENDAR_INTERNATIONALIZATION);
  }

  getCalendars(): Promise<Array<ICalendarModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<ICalendarModel>>((resolve, reject) => {
      this.genericService
        .find(this.CALENDAR_URL, findBody)
        .then((calendars: Array<ICalendarModel>) => {
          resolve(calendars);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'getCalendars'));
        });
    });
  }

  createHoliday(calendarId: string, holidayData: IHolidayModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const body = {
        holiday: holidayData
      };

      this.genericService
        .create(`${this.CALENDAR_URL}/${calendarId}/holiday`, body)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'createHoliday'));
        });
    });
  }

  deleteHoliday(calendarId: string, holidayId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(`${this.CALENDAR_URL}/${calendarId}/holiday`, holidayId)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'deleteHoliday'));
        });
    });
  }

  computeWorkingDays(userId: string, from: Date, to: Date, partOfDayFrom: string, partOfDayTo: string, countNaturalDays: boolean, startAnnualCycle?: Date): Promise<IWorkingDays> {
    return new Promise<IWorkingDays>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.CALENDAR_URL}/compute-working-days`, ErrorCodes.UNAUTHORIZED, CalendarService.name, 'computeWorkingDays');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        userId,
        from,
        to,
        partOfDayFrom,
        partOfDayTo,
        countNaturalDays,
        startAnnualCycle
      };

      this.http
        .put(`${this.CALENDAR_URL}/compute-working-days`, body, httpOptions)
        .toPromise()
        .then((workingDays: IWorkingDays) => {
          resolve(workingDays);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'computeWorkingDays'));
        });
    });
  }

  computeWorkingHours(userId: string, from: Date, to: Date, startTime: number, endTime: number, startAnnualCycle?: Date): Promise<IWorkingDays> {
    return new Promise<IWorkingDays>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.CALENDAR_URL}/compute-working-hours`, ErrorCodes.UNAUTHORIZED, CalendarService.name, 'computeWorkingHours');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        userId: userId,
        from: from,
        to: to,
        startTime: startTime,
        endTime: endTime,
        startAnnualCycle
      };

      this.http
        .put(`${this.CALENDAR_URL}/compute-working-hours`, body, httpOptions)
        .toPromise()
        .then((workingDays: IWorkingDays) => {
          resolve(workingDays);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarService.name, 'computeWorkingHours'));
        });
    });
  }
}

export interface ICalendarModel {
  _id: string;
  name: string;
  description?: string;
  _calendarTemplateKey: string;
  _mondayWorkingDay: boolean;
  _tuesdayWorkingDay: boolean;
  _wednesdayWorkingDay: boolean;
  _thursdayWorkingDay: boolean;
  _fridayWorkingDay: boolean;
  _saturdayWorkingDay: boolean;
  _sundayWorkingDay: boolean;
  _customHolidays: Array<IHolidayModel>;
}

export enum HolidayDurationOption {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon',
  FULLDAY = 'FullDay'
}

export interface IHolidayModel {
  _id?: string;
  holidayName: string;
  holidayDate: Date;
  holidayDuration: HolidayDurationOption;
}

export interface IWorkingDays {
  workingDays: number;
  duration: number;
  splitByMonth: Array<IWorkingDayByMonth>;
  workingDaysCurrentCycle?: number;
}

export interface IWorkingDayByMonth {
  _from: Date;
  _to: Date;
  _partOfDayFrom?: string;
  _partOfDayTo?: string;
  _startTime?: number;
  _endTime?: number;
  _workingDays: number;
  _duration: number;
  isCurrentCycle: boolean;
}
