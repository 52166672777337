<mat-form-field *ngIf="model && field && !readOnly">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [id]="inputId"
    [ngModel]="getValue()"
    (ngModelChange)="setValue($event)"
    [required]="required"
    [minlength]="minlength"
    [maxlength]="maxlength"
    autocomplete="off"
  />
</mat-form-field>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [id]="inputId" [ngModel]="getValue()" readOnly disabled [required]="required" autocomplete="off" />
</mat-form-field>
<div *ngIf="!isValueValid" class="iec-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
