<orgos-dialog-container [title]="dialogTranslation.costCenterLabel" (clickCloseButton)="closeDialog()">
  <orgos-column-container class="kenjo-mt-40px ecc-wrap-inputs">
    <orgos-input-simple-select [label]="dialogTranslation.costCenterLabel" orgosColumn
      [(value)]="costCenter._costCenterId" [required]="true" [options]="costCenterOptions"
      [enableClearButton]="true" class="kenjo-mr-10px kenjo-pt-15px kenjo-pb-10px"></orgos-input-simple-select>
    <orgos-input-simple-number [label]="dialogTranslation.quantityLabel" orgosColumn [(value)]="costCenter.quantity" [required]="true" [min]="0"
      [max]="100" step="0.5" suffix="%" (validation)="costCenterQuantityValidation = $event"
      class="kenjo-ml-10px kenjo-pt-15px kenjo-pb-10px">
      <orgos-input-error *ngIf="costCenterQuantityValidation && costCenterQuantityValidation.getError('required')">
        {{dialogTranslation.costCenterQuantityRequired}}</orgos-input-error>
      <orgos-input-error
        *ngIf="costCenterQuantityValidation && !costCenterQuantityValidation.getError('required') && !costCenterQuantityValidation.getError('float') && costCenterQuantityValidation.getError('min')">
        {{dialogTranslation.costCenterQuantityMinValue}}</orgos-input-error>
      <orgos-input-error
        *ngIf="costCenterQuantityValidation && !costCenterQuantityValidation.getError('required') && !costCenterQuantityValidation.getError('float') && costCenterQuantityValidation.getError('max')">
        {{dialogTranslation.costCenterQuantityMaxValue}}</orgos-input-error>
      <orgos-input-error
        *ngIf="costCenterQuantityValidation && !costCenterQuantityValidation.getError('required') && costCenterQuantityValidation.getError('float')">
        {{dialogTranslation.costCenterQuantityNoValidFormat}}</orgos-input-error>
    </orgos-input-simple-number>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">
        {{miscTranslation.goBackButtonDialog}}
      </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised [disabled]="!costCenter._costCenterId || !costCenter.quantity || !costCenterQuantityValidation || costCenterQuantityValidation.hasErrors()" (click)="save()" color="Success">
        {{dialogTranslation.saveButton}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
