import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable()
export class OfficeService implements IGenericService {
  private OFFICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/office-db`;
  private SHIFTPLAN_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan`;
  private OFFICE_PERMISSIONS_KEY: string = 'office';
  private OFFICE_INTERNATIONALIZATION: string = 'office-collection';

  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService, private http: HttpClient) {}

  create(data: object): Promise<IOfficeModel> {
    return new Promise<IOfficeModel>((resolve, reject) => {
      this.genericService
        .create(this.OFFICE_URL, data)
        .then((office: IOfficeModel) => {
          resolve(office);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, OfficeService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IOfficeModel> {
    return new Promise<IOfficeModel>((resolve, reject) => {
      this.genericService
        .getById(this.OFFICE_URL, id)
        .then((office: IOfficeModel) => {
          resolve(office);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, OfficeService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.OFFICE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, OfficeService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.OFFICE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, OfficeService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.OFFICE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.OFFICE_INTERNATIONALIZATION);
  }

  getData(
    findBody: any = {
      _id: { $ne: null }
    }
  ) {
    return new Promise<Array<IOfficeModel>>((resolve, reject) => {
      this.genericService
        .find(this.OFFICE_URL, findBody)
        .then((offices: Array<IOfficeModel>) => {
          resolve(offices);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, OfficeService.name, 'getOffices'));
        });
    });
  }

  getOffices(): Promise<Array<IOfficeModel>> {
    return this.getData();
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.OFFICE_URL);
  }

  async canDeleteOfficeLocation(officeLocationId: string, setting = 'office'): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, OfficeService.name, 'canDeleteOfficeLocation');
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };
    try {
      return await this.http.get(`${this.SHIFTPLAN_CONTROLLER_URL}/can-delete/${setting}/${officeLocationId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, OfficeService.name, 'canDeleteOfficeLocation');
    }
  }

  async canDeactiveOfficeLocation(officeLocationId: string, setting = 'office'): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, OfficeService.name, 'canDeactiveOfficeLocation');
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };
    try {
      return await this.http.get(`${this.SHIFTPLAN_CONTROLLER_URL}/can-deactivate/${setting}/${officeLocationId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, OfficeService.name, 'canDeactiveOfficeLocation');
    }
  }

  async getSortedOffices(): Promise<Array<ILocationOfficeModel>> {
    const locations = (await this.getData()) as Array<ILocationOfficeModel>;
    return this.#sortLocations(locations);
  }

  #sortLocations(locations): Array<ILocationOfficeModel> {
    const { activatedLocations, deactivatedLocations } = this.#splitDisableLocations(locations);
    const sortedActivatedLocations = activatedLocations.sort(this.#sortGroupedLocation());
    const sortedDeactivatedLocations = deactivatedLocations.sort(this.#sortGroupedLocation());

    return sortedActivatedLocations.concat(sortedDeactivatedLocations);
  }

  #sortGroupedLocation(): (iLocationA: ILocationOfficeModel, iLocationB: ILocationOfficeModel) => number {
    return (iLocationA: ILocationOfficeModel, iLocationB: ILocationOfficeModel) => {
      const nameA = iLocationA.name.toLowerCase();
      const nameB = iLocationB.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  #splitDisableLocations(locations: Array<ILocationOfficeModel>): { activatedLocations: Array<ILocationOfficeModel>; deactivatedLocations: Array<ILocationOfficeModel> } {
    return locations.reduce(
      (acc, curr) => {
        if (curr.activeForShiftplan) {
          acc.activatedLocations.push(curr);
        } else {
          acc.deactivatedLocations.push(curr);
        }
        return acc;
      },
      { activatedLocations: [], deactivatedLocations: [] }
    );
  }
}

export interface IOfficeModel {
  _id: string;
  name: string;
}

export interface ILocationOfficeModel extends IOfficeModel {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  calendarId: string;
  companyId: string;
  activeForShiftplan?: boolean;
}

export interface IVirtualOffice {
  _id?: string;
  name?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  calendarId?: string;
  motherOfficeId?: string;
}
