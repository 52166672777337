<orgos-column-container centerColumns="true" *ngIf="selectOperatorOptions">
  <orgos-input-simple-select orgosColumn="1" [options]="selectFilterOptions" [autoOrder]="false" [label]="pageTranslation.filter" [(value)]="filterValueChosen" (valueChange)="changeInFilterValue($event)"></orgos-input-simple-select>

  <orgos-input-simple-select
    orgosColumn="1"
    [(value)]="condition.operator"
    [options]="selectOperatorOptions"
    [label]="pageTranslation.condition"
    (valueChange)="changeInCondition()"
    [readOnly]="condition.subordinatesOnly === true || condition.directSubordinatesOnly === true || !filterValueChosen"
    class="kenjo-ml-20px"
  >
  </orgos-input-simple-select>

  <orgos-column size="1" class="kenjo-ml-20px">
    <orgos-input-simple-select
      *ngIf="calculatingValues === false"
      [options]="selectExpectedValueOptions"
      [(value)]="expectedValueChosen"
      [label]="pageTranslation.value"
      [readOnly]="condition.subordinatesOnly === true || condition.directSubordinatesOnly === true || !filterValueChosen"
      (valueChange)="changeInExpectedValue($event)"
      class="kenjo-block"
    ></orgos-input-simple-select>
    <mat-progress-spinner *ngIf="calculatingValues === true" mode="indeterminate" diameter="35" class="kenjo-mh-40px"></mat-progress-spinner>
  </orgos-column>

  <kenjo-icon [size]="18" orgosColumn="0" (click)="deleteThisCondition()" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757 kenjo-ml-20px">delete</kenjo-icon>
</orgos-column-container>
