import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AsanaService {
  private ASANA_URL: string = 'https://app.asana.com/api/1.0';
  private ASANA_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/asana`;

  constructor(private injector: Injector) {}

  getBasicIntegrationInfo() {
    return new Promise<any>((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .get(`${this.ASANA_MICROSERVICE_URL}/basic-info`)
        .toPromise()
        .then((basicIntegrationInfo) => {
          resolve(basicIntegrationInfo);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AsanaService.name, 'getBasicIntegrationInfo'));
        });
    });
  }

  getWorkspaces(): Promise<Array<any>> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.ASANA_URL}/workspaces?opt_fields=name,id`, ErrorCodes.UNAUTHORIZED, AsanaService.name, 'getWorkspaces');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      this.getBasicIntegrationInfo()
        .then((basicIntegrationInfo) => {
          const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${basicIntegrationInfo.accessToken}`);

          const httpOptions = {
            headers: httpHeaders
          };

          return this.injector.get(HttpClient).get(`${this.ASANA_URL}/workspaces?opt_fields=name,id`, httpOptions).toPromise();
        })
        .then((workspacesResponse: any) => {
          resolve(workspacesResponse.data);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AsanaService.name, 'getWorkspaces'));
        });
    });
  }

  getTeams(workspaceId: string): Promise<Array<any>> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.ASANA_URL}/organizations/${workspaceId}/teams?opt_fields=name,id`, ErrorCodes.UNAUTHORIZED, AsanaService.name, 'getTeams');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      this.getBasicIntegrationInfo()
        .then((basicIntegrationInfo) => {
          const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${basicIntegrationInfo.accessToken}`);

          const httpOptions = {
            headers: httpHeaders
          };

          return this.injector.get(HttpClient).get(`${this.ASANA_URL}/organizations/${workspaceId}/teams?opt_fields=name,id`, httpOptions).toPromise();
        })
        .then((teamsResponse: any) => {
          resolve(teamsResponse.data);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AsanaService.name, 'getTeams'));
        });
    });
  }
}
