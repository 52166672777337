<orgos-text class="iec-label" [color]="focused || clickOnCreate ? 'Primary' : 'LightText'">{{ label }}{{ required ? ' *' : '' }}</orgos-text>
<orgos-container [class]="quillSizeClass">
  <quill-editor *ngIf="!readOnly" [modules]="modules" [customOptions]="customOptions" [style]="style" [theme]="theme" [required]="required" placeholder="" (onEditorCreated)="onEditorCreated($event)" (onContentChanged)="onContentChanged($event)" class="orgos-quill-editor"> </quill-editor>
  <quill-editor *ngIf="readOnly" [modules]="{ toolbar: false }" [customOptions]="customOptions" [style]="style" [theme]="theme" placeholder="" [required]="required" [readOnly]="true" (onEditorCreated)="onEditorCreated($event)" class="orgos-quill-editor"> </quill-editor>
</orgos-container>
<div [class.iec-quill-container-no-focus]="!focused" [class.iec-quill-container-focus]="focused"></div>
<div class="iec-hint">
  <div *ngIf="!isValueValid" class="iec-error"><ng-content select="orgos-input-error"></ng-content></div>
  <orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
</div>
