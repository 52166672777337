<ng-container *ngIf="loadingPage === false && allCompanyDocs.length > 0">
  <div *ngIf="hasUploadPermission === true" class="kenjo-text-align-right kenjo-pt-20px kenjo-ph-20px dcdp-action-bar">
    <orgos-action (click)="uploadDocument()" icon="add_circle" iconSize="large"> {{i18n.misc.uploadDocumentButtonTooltip}}</orgos-action>
  </div>
  <div class="dcdp-documents-wrapper kenjo-p-10px" [style.height]="'calc(100vh - ' + wrapperHeight + 'px)'">
    <orgos-column-container *ngIf="allDocumentTags.length > 0" wrap="true" class="dcdp-documents-container">
      <orgos-document-card *ngFor="let document of companyDocsToDisplay" orgosColumn [document]="document" [allDocumentTags]="allDocumentTags" class="kenjo-m-10px"></orgos-document-card>
    </orgos-column-container>
  </div>
  <div *ngIf="tagsToDisplay && tagsToDisplay.length > 0" class="kenjo-border-top kenjo-color-grey-background-lighter-f8f8f8 dcdp-tags-wrapper dcdp-footer">
    <orgos-column-container wrap="true" class="kenjo-ph-20px dcdp-footer">
      <orgos-column size="0" *ngFor="let iDocumentTag of tagsToDisplay">
        <orgos-button-toggle *ngIf="iDocumentTag?.name" (checkedChange)="onChangeFilter($event, iDocumentTag)" [checked]="false" [activeColor]="getColor(iDocumentTag?.color)">{{iDocumentTag?.name}}</orgos-button-toggle>
      </orgos-column>
    </orgos-column-container>
  </div>
</ng-container>

<div *ngIf="loadingPage === false && allCompanyDocs.length === 0" class="kenjo-p-20px">
  <div *ngIf="hasUploadPermission === true" class="kenjo-text-align-right">
    <orgos-action icon="add_circle" iconSize="large" (click)="uploadDocument()">{{i18n.page.uploadDocumentButtonLabel}}</orgos-action>
  </div>
  <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noDocumentsTitle}}</div>
  </div>
</div>
