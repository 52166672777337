import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IAudienceVisibility } from '@app/standard/components/audience-selector/services/audience-selector.component.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable()
export class DocumentTagService {
  private DOCUMENT_TAG_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/document-tag-db`;
  private DOCUMENT_TAG_PERMISSIONS_KEY: string = 'document-tag';
  private DOCUMENT_TAG_INTERNATIONALIZATION: string = 'document-tag-collection';

  private collectionTranslation: any = {};

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService, private injector: Injector) {
    this.loadCollectionTranslation();
  }

  async loadCollectionTranslation() {
    try {
      this.collectionTranslation = await this.injector.get(InternationalizationService).getAllTranslation('document-tag-collection');
    } catch {
      this.collectionTranslation = {};
    }
  }

  create(data: object): Promise<IDocumentTagModel> {
    return new Promise<IDocumentTagModel>((resolve, reject) => {
      this.genericService
        .create(this.DOCUMENT_TAG_URL, data)
        .then((documentTag: IDocumentTagModel) => {
          resolve(documentTag);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTagService.name, 'create'));
        });
    });
  }

  //This method takes all tags without any changes in id
  async getSettingsDocumentsTags(): Promise<Array<IDocumentTagModel>> {
    if (this.authenticationService.isUserAuthenticated() === false) {
      const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, DocumentTagService.name, 'getUsableTags');
      throw this.errorManager.handleRawError(error);
    }
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader())
    };
    const query = { _id: { $ne: null } };

    try {
      const allTags = await this.http.post<Array<IDocumentTagModel>>(`${this.DOCUMENT_TAG_URL}/find`, query, httpOptions).toPromise();
      this.applyTranslation(allTags);
      return allTags;
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentTagService.name, 'getSettingsDocumentsTags');
    }
  }

  getById(id: string): Promise<IDocumentTagModel> {
    return new Promise<IDocumentTagModel>((resolve, reject) => {
      this.genericService
        .getById(this.DOCUMENT_TAG_URL, id)
        .then((documentTag: IDocumentTagModel) => {
          this.applyTranslation(documentTag);
          resolve(documentTag);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTagService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.DOCUMENT_TAG_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTagService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.DOCUMENT_TAG_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTagService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.DOCUMENT_TAG_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.DOCUMENT_TAG_INTERNATIONALIZATION);
  }

  /**
   *
   * @returns All the tags
   */
  getDocumentTags(): Promise<Array<IDocumentTagModel>> {
    return new Promise<Array<IDocumentTagModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, DocumentTagService.name, 'getDocumentTags');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get<Array<IDocumentTagModel>>(`${this.DOCUMENT_TAG_URL}/all`, httpOptions)
        .toPromise()
        .then((documentTagsResponse: Array<IDocumentTagModel>) => {
          this.applyTranslation(documentTagsResponse);
          resolve(documentTagsResponse);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTagService.name, 'getDocumentTags'));
        });
    });
  }

  /**
   *
   * @returns the tags depending two options sent on the body:
   * body is formed by:
   *   applyPermissions: if true this will only return the tags for which user has access
   *   onlyActive: if true only active tags will be returned
   *   docId if given we also add the tags related to the document given
   *
   */
  async getTags(body): Promise<Array<IDocumentTagModel>> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, DocumentTagService.name, 'getTags');
        throw this.errorManager.handleRawError(error);
      }

      const httpOptions = {
        headers: new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader())
      };

      const tags = await this.http.post<Array<IDocumentTagModel>>(`${this.DOCUMENT_TAG_URL}/get-tags`, body, httpOptions).toPromise();
      this.applyTranslation(tags);
      return tags;
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentTagService.name, 'getTags');
    }
  }

  private applyTranslation(documentTag: any): void {
    if (check.not.assigned(documentTag)) {
      return;
    }
    if (check.array(documentTag) && check.nonEmptyArray(documentTag)) {
      documentTag.forEach((iDocumentTag) => {
        if (iDocumentTag.predefined === true && this.collectionTranslation[iDocumentTag.name]) {
          iDocumentTag.originalName = iDocumentTag.name;
          iDocumentTag.name = this.collectionTranslation[iDocumentTag.name];
        }
      });
    } else if (check.nonEmptyObject(documentTag) && check.assigned(documentTag.name) && check.nonEmptyString(documentTag.name) && documentTag.custom === false && check.assigned(this.collectionTranslation[documentTag.name])) {
      documentTag.name = this.collectionTranslation[documentTag.name];
    }
  }
}

export interface IDocumentTagModel {
  _id?: string;
  name?: string;
  color?: string;
  custom?: boolean;
  ownerId?: string;
  tags?: Array<string>;
  visibleToEveryone?: boolean;
  visibleToSpecific?: IAudienceVisibility;
  documentsNumber?: number;
  predefined?: boolean;
  predefinedId?: string;
  isActive?: boolean;
  excusedAbsenceType?: string;
}
