import { DecimalPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { IShiftPlanTemplate } from '@app/cloud-features/shift-plan/services/shift-plan-template.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-shift-plan-template-details',
  templateUrl: 'shift-plan-template-details.component.html',
  styleUrls: ['shift-plan-template-details.component.scss'],
})
export class ShiftPlanTemplateDetailsComponent implements OnInit, AfterViewChecked {
  _template: IShiftPlanTemplate;
  MARGIN_TOP_DIFFERENCE: number = 50;
  @Input() pageTranslation: { [key: string]: string } = {};
  @Input() detailsPosition: DOMRect;

  @Input()
  set template(value: IShiftPlanTemplate) {
    this._template = value;
    if (value) {
      this.initData();
    }
  }

  get template() {
    return this._template;
  }

  startShiftTime: string;
  endShiftTime: string;
  breakShiftTime: string;
  cardColor: string;
  shiftDuration: { hours: number; minutes: number } = { hours: 0, minutes: 0 };
  viewChecked: boolean = false;

  @ViewChild('templateDetails') container: ElementRef;

  constructor(public injector: Injector, private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.initData();
    this.viewChecked = true;
  }

  ngAfterViewChecked() {
    if (this.viewChecked) {
      const viewportHeight = window.innerHeight - this.MARGIN_TOP_DIFFERENCE;
      const availableSpace = viewportHeight - this.detailsPosition.top;
      const smallScreen = window.innerWidth <= 1536;

      if (availableSpace > this.container.nativeElement?.offsetHeight) {
        this.container.nativeElement.style.top = `${this.detailsPosition.top}px`;
      } else {
        const adjustPosition = smallScreen ? 3.5 : 1;
        this.container.nativeElement.style.bottom = `${availableSpace / adjustPosition}px`;
      }
    }
    this.viewChecked = false;
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  initData() {
    this.calculateColor();
    this.calculateShiftDuration();
  }

  private calculateColor() {
    this.cardColor = userColorConstants[this.template.roleColor];
  }

  calculateShiftDuration() {
    this.startShiftTime = this.convertTimeToString(this.template.start);
    this.endShiftTime = this.convertTimeToString(this.template.end);
    this.breakShiftTime = this.convertTimeToString(this.template.break ?? 0);
  }

  convertTimeToString(minutesValue: number): string {
    const hours = Math.floor((minutesValue / 60) % 24);
    const minutes = (((minutesValue / 60) % 24) - Math.floor((minutesValue / 60) % 24)) * 60;
    return `${this.decimalPipe.transform(hours, '2.0-0')}:${this.decimalPipe.transform(minutes, '2.0-0')}`;
  }
}
