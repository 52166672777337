import { OnInit, Component, Injector, Input } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-month-duration',
  templateUrl: 'month-duration.component.html'
})

export class MonthDurationComponent implements OnInit {

  pageTranslation: any = {};
  displayedMonths: number;
  displayedYears: number;

  @Input()
  set months(value: number){
    this.displayedMonths = check.not.assigned(value) ? 0 : value % 12;
    this.displayedYears = (check.not.assigned(value) || value < 12) ? 0 : Math.floor(value / 12);
  }

  constructor(private injector: Injector) {
  }

  ngOnInit(): void {
    this.getPageTranslation();
  }

  private async getPageTranslation() {
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('month-duration-component');
    } catch {
      this.pageTranslation = {};
    }
  }

}