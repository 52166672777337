<div [class.pc-card-deleted]="payslip.mustBeDeleted">
  <div class="pc-preview" [class.pc-preview-deleted]="payslip.mustBeDeleted || (!usersMap[payslip.userId] && !payslip.mustBeDeleted)" [style.height.px]="PREVIEW_HEIGHT">
    <orgos-pdf-render [pdfData]="payslip.rawData" [lazyRendering]="true" [renderingFeedbackText]="i18n.layoutImportPayslips.renderingPayslip" cdkDrag cdkDragLockAxis="y" [cdkDragFreeDragPosition]="dragPosition"> </orgos-pdf-render>
  </div>
  <div *ngIf="!payslip.mustBeDeleted" class="pc-info" [class.pc-info-no-matches]="!usersMap[payslip.userId]">
    <orgos-column-container centerColumns="true">
      <orgos-column class="pc-info-name">
        <orgos-column-container>
          <orgos-column orgosColumn="0" class="kenjo-mt-10px">
            <kenjo-icon *ngIf="usersMap[payslip.userId]" orgosColumn="0" [size]="20" class="kenjo-mr-10px kenjo-font-color-success-00b72e"> check_circle_outline</kenjo-icon>
            <kenjo-icon
              *ngIf="!usersMap[payslip.userId]"
              orgosColumn="0"
              [size]="20"
              [matTooltip]="payslip.tentativeUserIds?.length > 1 ? i18n.layoutImportPayslips.twoOrMoreMatchesTooltip : i18n.layoutImportPayslips.twoOrMoreMatchesTooltip"
              class="kenjo-mt-10px kenjo-cursor-pointer kenjo-mr-10px kenjo-font-color-danger-ff5757"
            >
              error_outline
            </kenjo-icon>
          </orgos-column>
          <orgos-column>
            <div class="kenjo-cursor-pointer">
              <orgos-input-payslip-search-user
                [users]="allUsers"
                [matchingUsers]="matchingUsers"
                [noMatchingUsers]="noMatchingUsers"
                [(value)]="payslip.userId"
                (selectedEmployeeChange)="selectAssignee()"
                [label]="i18n.layoutImportPayslips.employee + ' *'"
                [pageTranslation]="i18n.layoutImportPayslips"
                class="kenjo-block"
              >
              </orgos-input-payslip-search-user>
              <div *ngIf="!payslip.userId" class="kenjo-font-size-12px kenjo-font-color-danger-ff5757 kenjo-mb-10px">
                <div *ngIf="payslip.tentativeUserIds?.length > 1">
                  {{ i18n.layoutImportPayslips.twoOrMoreMatches }}
                </div>
                <div *ngIf="!payslip.tentativeUserIds?.length">
                  {{ i18n.layoutImportPayslips.noMatches }}
                </div>
              </div>
            </div>
          </orgos-column>
        </orgos-column-container>
      </orgos-column>
      <orgos-column *ngIf="digitalSignatureActive" class="kenjo-pl-10px" size="0">
        <orgos-input-simple-checkbox [disabled]="(availableCredits <= 0 && !payslip.signatureRequested) || !payslip.userId" [label]="i18n.layoutImportPayslips.requestDigitalSignature" [value]="payslip.signatureRequested" (valueChange)="requestSignatureChange()"> </orgos-input-simple-checkbox>
      </orgos-column>
      <kenjo-icon orgosColumn="0" [size]="20" class="kenjo-pl-10px kenjo-font-color-danger-ff5757 kenjo-cursor-pointer" (click)="deletePayslip()">delete </kenjo-icon>
    </orgos-column-container>
  </div>
  <div *ngIf="payslip.mustBeDeleted" class="pc-info pc-info-deleted">
    <orgos-column-container centerColumns="true">
      <orgos-column>
        <div>{{ i18n.layoutImportPayslips.deleted }}</div>
      </orgos-column>
      <kenjo-icon orgosColumn="0" [size]="20" class="kenjo-ml-10px kenjo-font-color-danger-ff5757 kenjo-cursor-pointer" (click)="recoverPayslip()"> undo2 </kenjo-icon>
    </orgos-column-container>
  </div>
</div>
