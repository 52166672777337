import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as check from 'check-types';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import isEmail from 'validator/es/lib/isEmail';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';

@Component({
  selector: 'orgos-input-simple-email',
  templateUrl: 'input-simple-email.component.html',
  styleUrls: ['input-simple-email.component.scss']
})
export class InputSimpleEmailComponent implements OnInit {
  isValueValid: boolean = true;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() suffixIcon: string;
  @Input() appearance: string = 'standard';

  @Input() debounceNewValues: boolean | 'true' | 'false';
  @Input() debounceTimer: number = 2000;
  private newValueDebounced: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.makeValidation(this.value);

    if (this.debounceNewValues === true || this.debounceNewValues === 'true') {
      this.newValueDebounced
        .pipe(
          debounce(() => {
            // If we are clearing the value, we do not delay the operation
            const time = this.value === '' ? 0 : this.debounceTimer;
            return timer(time);
          }),
          distinctUntilChanged()
        )
        .subscribe((newValue) => {
          this.validateAndEmit(newValue);
        });
    }
  }

  private validateAndEmit(newValue: any) {
    this.makeValidation(newValue);
    if (this.isValueValid) {
      this.valueChange.emit(newValue);
    }
  }

  onValueChange(newValue: any): void {
    this.value = newValue;

    if (this.debounceNewValues === true || this.debounceNewValues === 'true') {
      this.newValueDebounced.next(newValue);
    } else {
      this.validateAndEmit(newValue);
    }
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (check.assigned(newValue) && check.not.emptyString(newValue) && !isEmail(newValue)) {
      inputValidation.setError('email');
    }

    if (check.assigned(this.customValidation) && check.function(this.customValidation)) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }
}
