import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { changeCardSize } from '@app/cloud-features/shift-plan/helpers/shiftplan-card.helper';
import { ShiftPlanEmployeeListService } from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import {
  IDummyShiftCard,
  IShiftCardFieldOption,
  IShiftCardSizeSettings,
  IShiftPlanGeneralSettingsModel,
  ShiftPlanGeneralSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { CalendarService, ICalendarModel } from '@app/standard/services/company/calendar.service';
import { ILocationOfficeModel } from '@app/standard/services/company/office.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { SHIFT_PLAN_CARD_COMPACT } from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Component({
  selector: 'kenjo-settings-shift-plan-view-options',
  templateUrl: 'settings-shift-plan-view-options.html',
  styleUrls: ['settings-shift-plan-view-options.scss'],
})
export class SettingsShiftPlanViewOptions implements OnInit {
  i18n: any = {};
  generalSettingsId: string;
  timeOffImg: string = 'shiftplan-timeoff.svg';
  attendanceImg: string = 'shiftplan-attendance.svg';
  cardFields: {
    map: IShiftCardSizeSettings;
    list: Array<IShiftCardFieldOption>;
    disabled: IShiftCardSizeSettings;
  } = { map: null, list: [], disabled: null };
  cardSizeSettings: IShiftCardSizeSettings;
  dummyShift: IDummyShiftCard;
  durationTranslation: { [key: string]: string };
  updating: boolean = false;
  calendarsSelected = new FormControl([]);
  settingPermissions: { [key: string]: boolean };
  remainingCalendars: Array<ICalendarModel> = [];
  calendars: Array<ICalendarModel> = [];
  addedCalendars: Array<ICalendarModel> = [];
  PREFERENCE_KEY: string = 'shift-plan-schedule';
  MY_OFFICE_SETTINGS_PATH: string = '/cloud/settings/structure/office';
  MY_COMPANY_SETTINGS_PATH: string = '/cloud/settings/structure/company';
  shiftplanLocations: Array<ILocationOfficeModel> = [];
  locations: Array<ILocationOfficeModel> = [];

  @Input() parentTranslation: { [key: string]: string };
  @Input() generalSettings: IShiftPlanGeneralSettingsModel;

  @Output() updatedSettings: EventEmitter<IShiftPlanGeneralSettingsModel> = new EventEmitter<IShiftPlanGeneralSettingsModel>();

  constructor(private injector: Injector, protected router: Router) {}

  ngOnInit(): void {
    this.fetchData();
    this.setImagesUrl();
  }

  private async fetchData(): Promise<void> {
    try {
      this.i18n.page = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-view-options');
      this.durationTranslation = { hour: this.i18n.page.hour, minute: this.i18n.page.minute };
      this.generalSettingsId = this.generalSettings._id;
      this.settingPermissions = await this.injector
        .get(ShiftPlanPermissionsService)
        .getShiftplPermissionsByCollection('shift-plan-general-settings');
      await this.initCardSizeSettings();
      this.fillDummyShiftCard();
      await this.initCalendars();
    } catch {
      this.i18n.page = {};
    }
  }

  async initCardSizeSettings() {
    this.cardFields.map =
      this.generalSettings.shiftSettings ?? this.injector.get(ShiftPlanGeneralSettingsService).getCardSizeDefaultOptions();
    this.cardFields.list = Object.entries(this.cardFields.map).map(([key, value]) => ({
      key,
      value,
      name: this.i18n.page[key],
    }));
    this.cardFields.disabled = { ...this.cardFields.map };
    await this.onChangeCardSizeSetting(true);
  }

  async initCalendars() {
    await this.getCalendarsFromActiveLocationInShiftplan();

    if (this.generalSettings?.publicHolidaysCalendar) {
      this.addedCalendars = this.calendars.filter(({ _id }) => {
        return this.generalSettings.publicHolidaysCalendar.includes(_id);
      });
      this.calendarsSelected.setValue(this.addedCalendars);
      this.remainingCalendars = this.calendars.filter(({ _id }) => {
        return !this.generalSettings.publicHolidaysCalendar.includes(_id);
      });
    } else {
      this.addedCalendars = [];
      this.calendarsSelected.setValue([]);
      this.remainingCalendars = this.calendars;
    }
  }

  private async getCalendarsFromActiveLocationInShiftplan() {
    const response = await this.injector.get(ShiftPlanEmployeeListService).getEmployeesDetailed();
    this.locations = response.locations;
    this.shiftplanLocations = response.locations.filter((iLocation) => iLocation?.activeForShiftplan === true);
    const allCalendars = await this.injector.get(CalendarService).getCalendars();
    const calendarIDsOfLocations = this.shiftplanLocations.map((location) => location.calendarId);
    this.calendars = allCalendars.filter((calendar) => calendarIDsOfLocations.includes(calendar._id));
  }

  fillDummyShiftCard() {
    this.dummyShift = {
      date: new Date(new Date().setHours(0, 0, 0, 0)),
      start: 660,
      end: 1140,
      employeeId: 'dummyId',
      location: { name: this.i18n.page.location },
      tag: { name: this.i18n.page.tag },
      role: { colour: '#5993e3', name: this.i18n.page.role },
      status: 'published',
    };
  }

  public navigateTo(): void {
    const path = this.locations.length > 0 ? this.MY_OFFICE_SETTINGS_PATH : this.MY_COMPANY_SETTINGS_PATH;
    const url = this.router.serializeUrl(this.router.createUrlTree([path]));
    window.open(url, '_blank');
  }

  async onChangeCardSizeSetting(initSettings = false) {
    this.updating = true;
    const cardFieldsEntries = Object.entries(this.cardFields.map);
    const checkedNumbers = this.checkFieldsNumber(cardFieldsEntries);
    const FIELDS_NUMBER = { MAX: 2, MIN: 1 };

    if (checkedNumbers === FIELDS_NUMBER.MIN) {
      this.cardFields.disabled = { ...this.cardFields.map };
    }

    if (checkedNumbers === FIELDS_NUMBER.MAX) {
      cardFieldsEntries.forEach(([key, _]) => {
        this.cardFields.disabled[key] = false;
        if (!this.cardFields.map[key]) {
          this.cardFields.disabled[key] = true;
        }
      });
    }
    this.changeCardSize();

    if (!initSettings) {
      await this.updateCardSizeSettings();
    }
    this.updating = false;
  }

  checkFieldsNumber(cardFieldsEntries: Array<[string, any]>) {
    return cardFieldsEntries.filter(([_, value]) => value === true).length;
  }

  changeCardSize() {
    const shiftCardSettings =
      this.generalSettings.shiftSettings ?? this.injector.get(ShiftPlanGeneralSettingsService).getCardSizeDefaultOptions();
    const { cardSizeSettings } = changeCardSize(shiftCardSettings, true);
    this.cardSizeSettings = { ...cardSizeSettings, value: SHIFT_PLAN_CARD_COMPACT };
  }

  private setImagesUrl() {
    const language = this.injector.get(AuthenticationService).getLoggedUser()?.language;
    if (language === 'en' || !language) {
      return;
    }

    this.timeOffImg = `shiftplan-timeoff.${language}.svg`;
    this.attendanceImg = `shiftplan-attendance.${language}.svg`;
  }

  public async updateFeatureView(value: boolean, feature: 'timeoff' | 'attendance'): Promise<void> {
    const featureMap = {
      timeoff: { field: 'showTimeOff', name: 'timeoff' },
      attendance: { field: 'showAttendance', name: 'attendance' },
    };
    const selectedFeature = featureMap[feature];

    try {
      this.updating = true;
      this.generalSettings[selectedFeature.field] = value;
      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { [selectedFeature.field]: value });
      const snackBarMessage = this.i18n.page[`${selectedFeature.name}SnackbarMessage`];
      this.showSnackbar(snackBarMessage);
      if (value === true) {
        this.injector.get(PrivateAmplitudeService).logEvent(`Shiftplan ${selectedFeature.name} toggled on`, {
          category: 'Shiftplan',
          subcategory: 'Settings',
          subcategory2: selectedFeature.name,
        });
      }

      this.updatedSettings.emit(this.generalSettings);
      this.updating = false;
    } catch {
      this.updating = false;
    }
  }

  public async updateViewCalendars(value: boolean): Promise<void> {
    try {
      this.updating = true;
      if (this.settingPermissions?.edit_all === true) {
        this.generalSettings.showPublicHolidays = value;

        await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { showPublicHolidays: value });
        const snackBarMessage = this.i18n.page.publicHolidaysMessage;
        this.showSnackbar(snackBarMessage);
        this.updatedSettings.emit(this.generalSettings);
      }
      this.updating = false;
    } catch {
      this.updating = false;
    }
  }

  public async updateCardSizeSettings(): Promise<void> {
    try {
      this.generalSettings.shiftSettings = this.cardFields.map;

      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { shiftSettings: this.cardFields.map });
      const snackBarMessage = this.i18n.page.compactLayoutUpdated;
      this.showSnackbar(snackBarMessage);
      this.updatedSettings.emit(this.generalSettings);
    } catch {
      // do nothing
    }
  }

  public showSnackbar(snackBarMessage: string): void {
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }

  async onCalendarSelectionChange(event: MatLegacySelectChange) {
    try {
      const calendar = event.value;
      if (this.generalSettings?.showPublicHolidays) {
        this.updating = true;
        const isCalendar = this.addedCalendars.find((obj) => obj._id === calendar._id);
        if (!isCalendar) {
          this.addedCalendars.push(calendar);
          this.calendarsSelected.setValue(this.addedCalendars);
        }
        this.remainingCalendars = this.remainingCalendars.filter((cal) => cal !== calendar);
        await this.saveCalendarIds();
        this.updating = false;
      }
    } catch {
      this.updating = false;
    }
  }

  async onCalendarRemoved(calendar) {
    try {
      if (this.generalSettings?.showPublicHolidays) {
        this.updating = true;
        if (calendar) {
          let isCalendar = this.remainingCalendars.find((obj) => obj._id === calendar._id);
          if (!isCalendar) {
            this.remainingCalendars.push(calendar);
          }
          this.addedCalendars = this.addedCalendars.filter((cal) => cal !== calendar);
          this.calendarsSelected.setValue(this.addedCalendars);
          await this.saveCalendarIds();
        }
        this.updating = false;
      }
    } catch {
      this.updating = false;
    }
  }

  private async saveCalendarIds() {
    const calendarIds = this.calendarsSelected.value.map(({ _id }) => _id);
    await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { publicHolidaysCalendar: calendarIds });

    const snackBarMessage = this.i18n.page.publicHolidaysSettingsUpdateMessage;
    this.showSnackbar(snackBarMessage);
  }
}
