import { DecimalPipe } from '@angular/common';
import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { ITimeOffTypeModel } from '@app/cloud-features/time-off/services/time-off-type.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import {
  ACCRUAL_PERIOD_ANNUAL,
  ACCRUAL_TIME_START,
  ALLOWANCE_TYPE_UNLIMITED,
  CARRY_OVER_DURATION_UNIT_MONTH,
  CARRY_OVER_DURATION_UNIT_WEEK,
  CYCLE_TYPE_DISABLED,
  PART_TIME_PRORATION_DISABLED,
  POLICY_TYPE_HOUR,
  TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
@Component({
  selector: 'kenjo-policy-details-card',
  templateUrl: 'policy-details-card.component.html',
  styleUrls: ['policy-details-card.component.scss']
})
export class PolicyDetailsCardComponent implements OnInit, OnChanges {
  @Input() policy: ITimeOffPolicyModel;
  @Input() timeOffType: ITimeOffTypeModel;
  @Input() translations: { [key: string]: string };

  allowanceTypeText: string = '';
  carryQuantityMessage: string = '';
  carryOverDurationMessage: string = '';

  CARRY_OVER_DURATION_UNIT_MONTH: string = CARRY_OVER_DURATION_UNIT_MONTH;
  CARRY_OVER_DURATION_UNIT_WEEK: string = CARRY_OVER_DURATION_UNIT_WEEK;
  ALLOWANCE_TYPE_UNLIMITED: string = ALLOWANCE_TYPE_UNLIMITED;
  CYCLE_TYPE_DISABLED: string = CYCLE_TYPE_DISABLED;
  POLICY_TYPE_HOUR: string = POLICY_TYPE_HOUR;
  TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID: string = TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.allowanceTypeText = this.getAllowanceMessage();
    this.initCarryOverMessages();
  }

  ngOnChanges(): void {
    this.allowanceTypeText = this.getAllowanceMessage();
    this.initCarryOverMessages();
  }

  private getAllowanceMessage(): string {
    if (this.policy.allowanceType === ALLOWANCE_TYPE_UNLIMITED) {
      return this.translations.unlimitedAllowanceMessage;
    }
    let periodMessage;
    if (this.policy.cycleType === CYCLE_TYPE_DISABLED) {
      periodMessage = this.translations.disabledCycleMessage;
    } else if (this.policy.accrualPeriod === ACCRUAL_PERIOD_ANNUAL) {
      periodMessage = this.translations.annualAllowanceMessage;
    } else if (this.policy.accrualTime === ACCRUAL_TIME_START) {
      periodMessage = this.translations.monthlyStartAllowanceMessage;
    } else {
      periodMessage = this.translations.monthlyEndAllowanceMessage;
    }
    const time = this.policy._type === POLICY_TYPE_HOUR ? this.policy.baseAllowance / 60 : this.policy.baseAllowance;
    const timeStr = this.injector.get(DecimalPipe).transform(time, '1.0-2');
    let units;
    if (this.policy._type === POLICY_TYPE_HOUR) {
      units = time === 1 ? this.translations.hour : this.translations.hours;
    } else {
      units = time === 1 ? this.translations.day : this.translations.days;
    }
    const allowanceMessage = this.injector.get(I18nDataPipe).transform(this.translations.allowanceMessage, { time: timeStr, units });
    if (this.policy.startDateProration !== PART_TIME_PRORATION_DISABLED || this.policy.endDateProration !== PART_TIME_PRORATION_DISABLED || this.policy.partTimeProration !== PART_TIME_PRORATION_DISABLED) {
      return `${allowanceMessage} (${this.getProrationMessage()}), ${periodMessage}`;
    }
    return `${allowanceMessage}, ${periodMessage}`;
  }

  private getProrationMessage(): string {
    let totalMessage = this.translations.proratedAllowanceMessage;
    const prorationMessages = [];
    if (this.policy.startDateProration !== PART_TIME_PRORATION_DISABLED) {
      prorationMessages.push(this.translations.proratedStartDateMessage);
    }
    if (this.policy.endDateProration !== PART_TIME_PRORATION_DISABLED) {
      prorationMessages.push(this.translations.proratedContractLengthMessage);
    }
    if (this.policy.partTimeProration !== PART_TIME_PRORATION_DISABLED) {
      prorationMessages.push(this.translations.proratedWorkingDaysMessage);
    }
    prorationMessages.forEach((message, index) => {
      if (index === 0) {
        totalMessage += ` ${message}`;
        return;
      }
      if (index === prorationMessages.length - 1) {
        totalMessage += ` ${this.translations.proratedAndMessage} ${message}`;
        return;
      }
      totalMessage += `, ${message}`;
    });
    return totalMessage;
  }

  private initCarryOverMessages() {
    if (!this.policy.carryOver) {
      return;
    }
    if (this.policy.carryOverUnlimitedQuantity) {
      const carryOverQuantityUnit = this.policy._type === POLICY_TYPE_HOUR ? this.translations.hours : this.translations.days;
      this.carryQuantityMessage = this.injector.get(I18nDataPipe).transform(this.translations.carryOverUnlimitedQuantity, { units: carryOverQuantityUnit });
    } else {
      let carryOverQuantityUnit, time;
      if (this.policy._type === POLICY_TYPE_HOUR) {
        time = this.policy.carryOverQuantityLimit / 60;
        carryOverQuantityUnit = time === 1 ? this.translations.hour : this.translations.hours;
      } else {
        time = this.policy.carryOverQuantityLimit;
        carryOverQuantityUnit = time === 1 ? this.translations.day : this.translations.days;
      }
      const timeStr = this.injector.get(DecimalPipe).transform(time, '1.0-2');
      this.carryQuantityMessage = this.injector.get(I18nDataPipe).transform(this.translations.carryOverQuantity, { quantity: timeStr, units: carryOverQuantityUnit });
    }
    if (this.policy.carryOverUnlimitedDuration) {
      this.carryOverDurationMessage = this.translations.carryOverUnlimitedDuration;
    } else {
      let carryOverDurationUnit;
      if (this.policy.carryOverDurationUnit === CARRY_OVER_DURATION_UNIT_MONTH) {
        carryOverDurationUnit = this.policy.carryOverDuration === 1 ? this.translations.month : this.translations.months;
      } else {
        carryOverDurationUnit = this.policy.carryOverDuration === 1 ? this.translations.week : this.translations.weeks;
      }
      this.carryOverDurationMessage = this.injector.get(I18nDataPipe).transform(this.translations.carryOverDuration, {
        carryOverDuration: this.policy.carryOverDuration,
        carryOverDurationUnit
      });
    }
  }
}
