import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { IDatevSettingsModel } from '@app/cloud-features/settings-datev/models/datev-settings.model';
import { DatevSettingsService } from '@app/cloud-features/settings-datev/services/datev-settings.service';
import { ISurchargeParamsDataModel } from '@app/cloud-features/settings-surcharges/services/payroll-surcharge-rules.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { IQueryDates } from '@app/standard/components/input-month-picker/input-month-picker.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { CloseDatevPayrollDialog } from '@app/standard/pages/people/payroll/dialogs/close-datev-payroll/close-datev-payroll.dialog';
import { ExportAndCloseDatevPayrollDialog } from '@app/standard/pages/people/payroll/dialogs/export-and-close-datev-payroll/export-and-close-datev-payroll.dialog';
import { SurchargeDetailsDialog } from '@app/standard/pages/people/payroll/dialogs/surcharge-details-dialog/surcharge-details.dialog';
import { PayrollExportService } from '@app/standard/pages/people/payroll/services/payroll-export.service';
import { CompanyService, ICompanyModel } from '@app/standard/services/company/company.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { DatevAsciiController } from '@app/standard/services/datev-ascii/datev-ascii.controller';
import { DatevExportService } from '@app/standard/services/datev-export/datev-export.service';
import { DatevExportController } from '@app/standard/services/datev/datev-export.controller';
import { DatevPreviewController } from '@app/standard/services/datev/datev-preview.controller';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { PayrollController } from '@app/standard/services/payroll/controllers/payroll.controller';
import { PayrollCommentService } from '@app/standard/services/payroll/payroll-comment.service';
import { IPayrollSettingModel, PayrollSettingsService } from '@app/standard/services/payroll/payroll-settings.service';
import { PayrollService } from '@app/standard/services/payroll/payroll.service';
import {
  DATEV_INTERFACE_LODAS,
  DATEV_INTERFACE_LOHN_GEHALT,
  PAYROLL_STATUS_CLOSED,
  PAYROLL_TAB_EMPLOYEE_DATA,
  PAYROLL_TAB_EMPLOYEE_DOCS,
  PAYROLL_TAB_TIME_OFF,
  PAYROLL_TAB_VARIABLE_PAYMENTS,
  PAYROLL_VIEW_SPLIT,
  PAYROLL_VIEW_TOTAL,
  PAY_PERIOD_TYPE_BI_MONTHLY,
  PAY_PERIOD_TYPE_CUSTOM_MONTHLY,
  PAY_PERIOD_TYPE_MONTHLY,
  PAY_PERIOD_TYPE_VARIABLE,
  PAY_PERIOD_TYPE_WEEKLY,
  TIME_OFF_TYPE_ACTIVITY_TYPE_PAID,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'payroll-page',
  templateUrl: 'payroll.page.html',
  styleUrls: ['payroll.page.scss'],
})
export class PayrollPage extends GenericPage {
  selectedGroupId: string;
  selectedTab: string;
  selectedTabIndex: number = 0;
  selectedDatevTabIndex: number = 0;
  periodIdentifierValue: number;
  hasUserPayrollPermissions: boolean;
  hasUserPayrollExportPermissions: boolean;
  maxDate: moment.Moment;
  employeeDataColumns: Array<string>;
  payrollSettingsList: Array<IPayrollSettingModel>;
  datevSettingsList: Array<IDatevSettingsModel>;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  preferenceDate: moment.Moment;
  payrollGroupData: any;
  timeOff: { timeOffRecords: Array<any>; timeOffCount: number };
  variablePayments: any;
  employeeDocs: any;
  corrections: any;
  exports: any;
  employeeData: any;
  recordsToShow: any;
  employeeDataColumnsTranslationKeys: any;
  selectedSettings: IPayrollSettingModel | IDatevSettingsModel;
  datevSettings: Array<IDatevSettingsModel>;
  settingsOptions: Array<ISelectOption>;
  companies: Array<ICompanyModel>;
  datevData = [];
  datevExports: any;
  datevDataColumnsTranslationKeys: any;
  datevTables = {};

  viewSelected: string = PAYROLL_VIEW_TOTAL;
  PAY_PERIOD_TYPE_CUSTOM_MONTHLY: string = PAY_PERIOD_TYPE_CUSTOM_MONTHLY;
  PAY_PERIOD_TYPE_MONTHLY: string = PAY_PERIOD_TYPE_MONTHLY;
  PAY_PERIOD_TYPE_VARIABLE: string = PAY_PERIOD_TYPE_VARIABLE;
  PAY_PERIOD_TYPE_WEEKLY: string = PAY_PERIOD_TYPE_WEEKLY;
  PAY_PERIOD_TYPE_BI_MONTHLY: string = PAY_PERIOD_TYPE_BI_MONTHLY;
  PAYROLL_TAB_EMPLOYEE_DATA: string = PAYROLL_TAB_EMPLOYEE_DATA;
  PAYROLL_TAB_TIME_OFF: string = PAYROLL_TAB_TIME_OFF;
  PAYROLL_TAB_VARIABLE_PAYMENTS: string = PAYROLL_TAB_VARIABLE_PAYMENTS;
  PAYROLL_TAB_EMPLOYEE_DOCS: string = PAYROLL_TAB_EMPLOYEE_DOCS;
  PAYROLL_VIEW_SPLIT: string = PAYROLL_VIEW_SPLIT;
  PAYROLL_VIEW_TOTAL: string = PAYROLL_VIEW_TOTAL;
  TIME_OFF_TYPE_ACTIVITY_TYPE_PAID: string = TIME_OFF_TYPE_ACTIVITY_TYPE_PAID;
  selectedFeature: string;
  totalOfRecords: number = 0;
  numberOfPages: number = 1;
  exporting: boolean = false;
  checkingCorrections: boolean = false;
  confirmingPayroll: boolean = false;
  isPayrollConfirmed: boolean = false;
  isPayrollClosed: boolean = false;
  isNaturalMonthSelected: boolean = false;
  isVariablePeriodSelected: boolean = false;
  isStartDateToday: boolean = false;
  isDatevActive: boolean = false;
  isPayrollActive: boolean = false;

  correctionsColumns: Array<string> = ['correctionDate', 'attachment', 'generatedBy'];
  exportsColumns: Array<string> = ['closingDate', 'attachment', 'closedBy'];
  exportsDatevColumns: Array<string> = ['closingDate', 'attachment', 'method', 'closedBy', 'reopen'];
  timeOffColumns: Array<string> = ['avatar', 'firstName', 'lastName', 'payType', 'timeOffTypeName', 'period', 'amount', 'attachment'];
  variablePaymentsColumns: Array<string> = ['avatar', 'firstName', 'lastName', 'date', 'amount', 'type'];
  employeeDocsColumns: Array<string> = ['avatar', 'firstName', 'lastName', 'uploadedOn', 'type', 'document'];
  datevDataColumns = ['company', 'lastExportDate', 'documentCreatedOn', 'exportFile'];
  datevPayAndAttendanceTabKeys = [];
  datevEmployeeDataTabKeys = [];
  recordsToShowSelector: Array<number> = [25, 50, 100];
  recordsToShowDatevSelector: Array<number> = [15, 20, 25, 50, 100];
  isDatevValidationErrors: boolean = false;
  datevCompanyMandatoryFieldsMissing: boolean = false;
  isDatevErrors: boolean = false;
  datevErrors: any = {
    errorsAmount: 0,
  };
  employeeTabHasErrors: boolean = false;
  paymentTabHasErrors: boolean = false;
  isFirstFetch: boolean = true;

  uploadedDocuments: Array<IFileMetadata> = [];
  documentsType: 'Payslips' | 'Generic';
  exportStatus: 'open' | 'readyToExport' | 'readyToConfirm' | 'closed' = 'open';
  usersDisplayInfo: Array<any> = [];
  MAX_PARTICIPANTS = 4;
  datevExportFileName: string;
  closingDatevExportUser: any;
  datevPagination: any;
  fetchingDatevPreviewScreen: boolean = false;
  protected datevSortBy: string = 'personalNumber';
  protected datevSortOrder: string = 'asc';

  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'payroll-page' },
    { name: 'menu', translationKey: 'payroll-menu' },
    { name: 'payroll', translationKey: 'default-payroll-settings' },
    { name: 'picklists', translationKey: 'standard-picklists' },
    { name: 'documents', translationKey: 'documents-import-page' },
    { name: 'datevSettings', translationKey: 'settings-datev-page' },
  ];

  PAGE_SELECTOR: object = {
    first: 1,
    previous: 2,
    next: 3,
    final: 4,
  };

  queryOptions: any = {
    page: 1,
    recordsPerPage: 25,
  };

  datevQueryOptions: any = {
    page: 1,
    recordsPerPage: 15,
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    protected injector: Injector,
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected location: Location
  ) {
    super(injector, cdr, router, route, location);
  }

  public navigateToPayrollSettings(): void {
    this.router.navigate(['/cloud/payroll/settings'], { queryParams: { payrollSettingsId: this.selectedGroupId } });
  }

  public navigateToDatevSettings(): void {
    this.router.navigate(['/cloud/payroll/datev'], { queryParams: { datevSettingsId: this.selectedGroupId } });
  }

  public navigateToHelpCenter(): void {
    window.open(this.i18n.page.datevHelpCenterLink, '_blank');
  }

  public errorNavigation(field: string, userId: string): void {
    if (field === 'salaryNumber' || field === 'salaryType') {
      this.navigateToDatevSettings();
      return;
    }
    this.navigateToPersonalProfile(userId);
  }

  public navigateToPersonalProfile(userId: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cloud/people', userId, 'personal'], { queryParams: { showDatevFields: true } })
    );

    window.open(url, '_blank');
    const snackBarRef = this.injector.get(MatLegacySnackBar).open(this.i18n.page.datevSnackBarMessage, this.i18n.page.datevSnackBarAction);
    snackBarRef.onAction().subscribe(() => {
      this.refreshData(true);
    });
  }

  public async getCurrentPayrollGroupData(): Promise<void> {
    this.selectedGroupId = this.selectedGroupId ?? this.payrollSettingsList[0]._id;

    this.selectedSettings = this.payrollSettingsList.find((iPayrollSetting) => {
      return iPayrollSetting._id === this.selectedGroupId;
    });

    if (check.not.assigned(this.selectedSettings)) {
      this.selectedSettings = this.payrollSettingsList[0];
      this.selectedGroupId = this.selectedSettings._id;
    }

    this.setCommonProperties();

    this.payrollGroupData = await this.injector
      .get(PayrollController)
      .getPayrollGroupData(this.selectedGroupId, this.periodIdentifierValue);
    this.employeeData = this.payrollGroupData.employeeData;
    this.employeeDataColumns = this.payrollGroupData.employeeDataColumns;
    this.employeeDataColumnsTranslationKeys = this.payrollGroupData.employeeDataColumnsTranslationKeys;
    this.timeOff = this.payrollGroupData.timeOff;
    this.variablePayments = this.payrollGroupData.variablePayments;
    this.employeeDocs = this.payrollGroupData.employeeDocs;
    this.corrections = this.payrollGroupData.corrections;
    this.exports = this.payrollGroupData.exports;
    this.fromDate = moment.utc(this.payrollGroupData.fromDate);
    this.toDate = moment.utc(this.payrollGroupData.toDate);
    this.maxDate = moment.utc();

    this.isStartDateToday = this.fromDate.isSame(this.selectedSettings.payPeriodVariableFromToDate, 'day');

    if (this.isNaturalMonthSelected) {
      this.preferenceDate = moment.utc(this.fromDate);
    }

    this.viewSelected = this.PAYROLL_VIEW_TOTAL;

    this.isPayrollConfirmed = this.payrollGroupData.status === PAYROLL_STATUS_CLOSED;

    this.injector
      .get(PayrollCommentService)
      .injectCommentColumns(
        this.payrollGroupData,
        this.selectedSettings,
        this.variablePaymentsColumns,
        this.timeOffColumns,
        this.isPayrollConfirmed
      );
    if (!this.isPayrollConfirmed && this.selectedSettings.commentColumnEnabled === true) {
      await this.injector
        .get(PayrollCommentService)
        .injectCommentData(this.payrollGroupData, this.fromDate, this.isPayrollConfirmed, this.selectedSettings._id);
    }

    this.setPagination();
  }

  private async getCurrentDatevGroupData(): Promise<void> {
    this.selectedSettings = this.datevSettingsList.find((iDatevSetting) => iDatevSetting._id === this.selectedGroupId);
    this.isDatevValidationErrors = false;
    this.datevCompanyMandatoryFieldsMissing = false;
    this.isDatevErrors = false;
    this.datevErrors.errorsAmount = 0;
    this.employeeTabHasErrors = false;
    this.paymentTabHasErrors = false;
    this.isFirstFetch = true;
    this.datevQueryOptions = {
      page: 1,
      recordsPerPage: 15,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { payrollGroupId: this.selectedGroupId, feature: 'datev' },
      queryParamsHandling: 'merge',
    });
    if (
      check.not.assigned(this.selectedSettings?.payrollGroupName) ||
      check.emptyString(this.selectedSettings?.payrollGroupName) ||
      check.not.assigned(this.selectedSettings?.clientNumber) ||
      check.not.assigned(this.selectedSettings?.consultantNumber)
    ) {
      this.datevCompanyMandatoryFieldsMissing = true;
      this.isDatevErrors = true;
    }

    this.filterPayAndAttendanceActiveTabs();
    this.injector.get(PrivateAmplitudeService).logEvent('DATEV payroll group is selected', {
      platform: 'Web',
      category: 'DATEV',
      subcategory1: 'View payroll',
      subcategory2: 'Run payroll',
      subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
    });

    const datevExportData = await this.injector
      .get(DatevExportService)
      .findByPeriodIdentifier(this.selectedGroupId, this.periodIdentifierValue);
    this.fromDate = moment.utc(datevExportData.from);
    this.toDate = moment.utc(datevExportData.to);
    this.datevExports = datevExportData?.datevExports;
    const company = this.companies.find((iCompany) => iCompany._id === (this.selectedSettings as IDatevSettingsModel).companyId);
    const datevExport = this.datevExports.find(
      (iDatevExport) => iDatevExport._companyId === (this.selectedSettings as IDatevSettingsModel).companyId
    );

    if (!datevExport?.closed) {
      await this.filterDatevTables();
    }

    this.maxDate = moment.utc();

    this.setCommonProperties();

    if (this.isNaturalMonthSelected) {
      this.preferenceDate = moment.utc(this.fromDate);
    }

    this.datevData = [];
    if (datevExport?.closed === true) {
      this.datevExportFileName = `DATEV_${company.name.replace(/\s+/g, '')}_${moment(this.toDate).format('DD.MM.YYYY')}.zip`;
    }
    const row = {
      data: {
        datevSettingsId: { type: 'string', value: this.selectedSettings._id },
        company: { type: 'string', value: company.name },
        lastExportDate: { type: 'date', value: datevExport?.lastExportDate },
        documentCreatedOn: { type: 'date', value: new Date() },
        exportFile: { type: 'link', value: 'Download' },
      },
    };

    this.datevData.push(row);

    if (this.datevExports.length === 0) {
      this.isPayrollConfirmed = false;
      this.exportStatus = 'readyToConfirm';
      return;
    }
    this.isPayrollConfirmed = this.datevExports.every((iDatevExport) => iDatevExport.confirmed === true);
    this.isPayrollClosed = this.datevExports.every((iDatevExport) => iDatevExport.closed === true);
    if (this.isPayrollClosed === true) {
      this.exportStatus = 'closed';
    } else {
      this.exportStatus = this.isPayrollConfirmed === true ? 'readyToExport' : 'readyToConfirm';
    }
  }

  private filterPayAndAttendanceActiveTabs() {
    const datevSettings = this.selectedSettings as IDatevSettingsModel;
    const tables = datevSettings.target === DATEV_INTERFACE_LODAS ? datevSettings.userTables : datevSettings.lugTables;
    if (!tables) {
      return;
    }

    Object.keys(tables).forEach((iTableKey) => {
      if (tables?.[iTableKey]?.isActive === true) {
        const mainTab = this.getDatevTabByName(iTableKey);
        mainTab === 'payAndAttendanceTab' &&
          this.datevPayAndAttendanceTabKeys.includes(iTableKey) === false &&
          this.datevPayAndAttendanceTabKeys.push(iTableKey);
        mainTab === 'employeeDataTab' &&
          this.datevEmployeeDataTabKeys.includes(iTableKey) === false &&
          this.datevEmployeeDataTabKeys.push(iTableKey);
      }
    });
  }

  private async filterDatevTables(tableKey?: string) {
    const datevSettings = this.selectedSettings as IDatevSettingsModel;
    const tables = datevSettings.target === DATEV_INTERFACE_LODAS ? datevSettings.userTables : datevSettings.lugTables;
    if (!tables) {
      return;
    }

    if (this.fetchingDatevPreviewScreen === true || check.not.nonEmptyObject(tables)) {
      return;
    }

    this.fetchingDatevPreviewScreen = true;
    let mainTabToFilter: string | undefined;
    let updatingTable: boolean = false;
    if (check.assigned(tableKey)) {
      mainTabToFilter = this.getDatevTabByName(tableKey);
      updatingTable = true;
    }
    let userDatevPreview: any, employeeErrors: any;

    if (updatingTable === false || mainTabToFilter === 'employeeDataTab') {
      [userDatevPreview, employeeErrors] = await Promise.all([
        this.injector.get(DatevPreviewController).getEmployeePreview({
          datevSettingsId: this.selectedGroupId,
          periodIdentifier: this.periodIdentifierValue,
          page: this.datevQueryOptions.page,
          recordsPerPage: this.datevQueryOptions.recordsPerPage,
          sortBy: this.datevSortBy,
          sortOrder: this.datevSortOrder,
          selectedTab: 'employeeTabs',
        }),
        this.injector.get(DatevPreviewController).getErrors(this.selectedGroupId, this.periodIdentifierValue, 'employeeTabs'),
      ]);

      this.datevPagination = userDatevPreview?.pagination;
      if (this.isFirstFetch === true && check.assigned(employeeErrors.errorsAmount) && employeeErrors.errorsAmount > 0) {
        this.employeeTabHasErrors = true;
        this.datevErrors.errorsAmount += employeeErrors.errorsAmount;
      }
    }
    const payAndAttendanceTabsPromises: Array<Promise<any>> = [];
    this.datevTables = Object.keys(tables).reduce(
      (datevTablesMap, userTableKey: string) => {
        if (tables[userTableKey]?.isActive === false) {
          return datevTablesMap;
        }
        const datevMainTabName = this.getDatevTabByName(userTableKey);
        if (datevMainTabName === 'employeeDataTab' && (updatingTable === false || mainTabToFilter === 'employeeDataTab')) {
          this.datevErrors[userTableKey] = employeeErrors?.tables?.[userTableKey].hasErrors;
          datevTablesMap[datevMainTabName][userTableKey] = {
            key: userTableKey,
            name: this.i18n?.datevSettings?.employeeProfileFieldsTranslation?.[`${userTableKey}Header`],
            employeeDataColumnsTranslationKeys: this.filterDatevTableData(
              tables[userTableKey],
              this.i18n?.datevSettings?.employeeProfileFieldsTranslation,
              userTableKey
            ),
            ...userDatevPreview?.tables?.[userTableKey],
            hasErrors: employeeErrors?.tables?.[userTableKey].hasErrors,
          };
        } else if (
          datevMainTabName === 'payAndAttendanceTab' &&
          userTableKey !== 'hourlyAttendanceFields' &&
          (updatingTable === false || (mainTabToFilter === 'payAndAttendanceTab' && userTableKey === tableKey))
        ) {
          payAndAttendanceTabsPromises.push(
            this.managePayAndAttendanceTab(
              datevTablesMap[datevMainTabName],
              datevTablesMap.payAndAttendancePagination,
              userTableKey,
              tables,
              null
            )
          );
        }

        return datevTablesMap;
      },
      {
        employeeDataTab: this.datevTables?.['employeeDataTab'] ?? {},
        payAndAttendanceTab: this.datevTables?.['payAndAttendanceTab'] ?? {},
        payAndAttendancePagination: this.datevTables?.['payAndAttendancePagination'] ?? {},
      }
    );

    await Promise.all(payAndAttendanceTabsPromises);

    if (this.datevErrors.errorsAmount > 0) {
      this.isDatevErrors = true;
      this.isDatevValidationErrors = true;
    }

    this.isFirstFetch = false;
    this.fetchingDatevPreviewScreen = false;
  }

  private async managePayAndAttendanceTab(
    payAndAttendanceTab: any,
    payAndAttendancePagination: any,
    tableKey: string,
    userTables: any,
    pageNumber: any
  ) {
    let page = 1;
    if (
      check.assigned(payAndAttendanceTab?.[tableKey]?.paginationKey) &&
      check.nonEmptyObject(payAndAttendancePagination?.[payAndAttendanceTab?.[tableKey]?.paginationKey])
    ) {
      page = pageNumber ?? payAndAttendancePagination?.[payAndAttendanceTab[tableKey].paginationKey].currentPage;
    }

    const previewPromise =
      tableKey === 'employeeTabs'
        ? this.injector.get(DatevPreviewController).getEmployeePreview({
            datevSettingsId: this.selectedGroupId,
            periodIdentifier: this.periodIdentifierValue,
            page: page,
            recordsPerPage: this.datevQueryOptions.recordsPerPage,
            sortBy: this.datevSortBy,
            sortOrder: this.datevSortOrder,
            selectedTab: tableKey,
          })
        : this.injector.get(DatevPreviewController).getPaymentPreview({
            datevSettingsId: this.selectedGroupId,
            periodIdentifier: this.periodIdentifierValue,
            page: page,
            recordsPerPage: this.datevQueryOptions.recordsPerPage,
            sortBy: this.datevSortBy,
            sortOrder: this.datevSortOrder,
            selectedTab: tableKey,
          });

    const [datevTabPreview, paymentTabErrors] = await Promise.all([
      previewPromise,
      this.injector.get(DatevPreviewController).getErrors(this.selectedGroupId, this.periodIdentifierValue, tableKey),
    ]);
    if (check.nonEmptyObject(datevTabPreview?.tables) && check.nonEmptyObject(datevTabPreview?.pagination)) {
      if (paymentTabErrors.errorsAmount > 0 && this.isFirstFetch === true) {
        this.datevErrors.errorsAmount += paymentTabErrors.errorsAmount;
        this.paymentTabHasErrors = true;
      }
      Object.keys(datevTabPreview.tables).forEach((iTableKey) => {
        payAndAttendancePagination[tableKey] = datevTabPreview.pagination;
        if (check.assigned(payAndAttendanceTab[iTableKey])) {
          payAndAttendanceTab[iTableKey].table = { ...payAndAttendanceTab[iTableKey].table, ...datevTabPreview.tables[iTableKey] };
          return;
        }
        payAndAttendanceTab[iTableKey] = {
          table: {
            key: iTableKey,
            name: this.i18n?.datevSettings?.employeeProfileFieldsTranslation?.[`${iTableKey}Header`],
            employeeDataColumnsTranslationKeys: this.filterDatevTableData(
              userTables[iTableKey],
              this.i18n?.datevSettings?.employeeProfileFieldsTranslation,
              iTableKey
            ),
            ...datevTabPreview.tables[iTableKey],
            hasErrors: paymentTabErrors?.tables?.[iTableKey].hasErrors,
          },
          paginationKey: tableKey,
        };
      });
    }
  }

  private getDatevTabByName(tableName: string) {
    const lodasEmployeeTables = [
      'employeeFields',
      'bankFields',
      'jobFields',
      'taxFields',
      'activityFields',
      'socialSecurityFields',
      'regularWorkingHoursFields',
    ];
    const lugEmployeeTables = [
      'addressNameFields',
      'personalDetailsFields',
      'bankFields',
      'employmentPeriodFields',
      'lugTaxFields',
      'occupationFields',
      'socialSecurityStatusFields',
      'trainingFields',
      'lugActivityFields',
      'lugSocialSecurityFields',
      'basicLeaveEntitlementFields',
      'fixedWorkScheduleFields',
      'statusCodeFields',
      'costCenterFields',
    ];

    if (lodasEmployeeTables.includes(tableName) || lugEmployeeTables.includes(tableName)) {
      return 'employeeDataTab';
    }

    return 'payAndAttendanceTab';
  }

  private filterDatevTableData(userTable: any, tableTranslations: any, tableName: string) {
    const fields = {};

    Object.keys(userTable.fields).forEach((fieldKey) => {
      fields[fieldKey] = tableTranslations?.[`${tableName}`]?.[fieldKey]?.title;
      if (fieldKey === 'personalNumber') {
        fields['firstName'] = tableTranslations?.['employeeFields']?.['firstName']?.title;
        fields['lastName'] = tableTranslations?.['employeeFields']?.['lastName']?.title;
      }
    });

    if (tableName === 'hourlySalaryFields' || tableName === 'lugHourlySalaryFields') {
      fields['hourlySalary1'] = tableTranslations?.[`${tableName}`]?.['hourlySalary1']?.title;
      fields['hourlySalary2'] = tableTranslations?.[`${tableName}`]?.['hourlySalary2']?.title;
      fields['hourlySalary3'] = tableTranslations?.[`${tableName}`]?.['hourlySalary3']?.title;
      delete fields['hourlySalary'];
    }

    return fields;
  }

  private setCommonProperties(): void {
    this.isNaturalMonthSelected = this.selectedSettings.payPeriod === this.PAY_PERIOD_TYPE_MONTHLY;
    this.isVariablePeriodSelected = this.selectedSettings.payPeriod === this.PAY_PERIOD_TYPE_VARIABLE;
  }

  /**
   * moveToDate(operation: number)
   * Increase, decrease or set the current period for payroll
   * @param operation is -1, 0 or 1
   */
  public async moveToPeriod(): Promise<void> {
    if (this.selectedSettings?._id === 'datev') {
      return;
    }

    await this.refreshData(true);
  }

  public detectChangesOnMonthPicker(event: IQueryDates) {
    const fromDate = moment.utc(this.fromDate);
    const newDate = moment.utc(event.startDayOfMonth);
    const currentMonth = fromDate.format('M');
    const newDateMonth = newDate.format('M');
    let extraPeriodsPerYear = 0;

    if (fromDate.format('YYYY') !== newDate.format('YYYY')) {
      extraPeriodsPerYear = (parseInt(newDate.format('YYYY')) - parseInt(fromDate.format('YYYY'))) * 12;
    }

    this.periodIdentifierValue = parseInt(newDateMonth) - parseInt(currentMonth) + this.periodIdentifierValue + extraPeriodsPerYear;

    if (this.selectedSettings?._id === 'datev') {
      return;
    }
    this.refreshData(true);
  }

  public downloadAttachment(attachment: any): void {
    if (attachment?.correctionDate) {
      this.injector.get(PrivateAmplitudeService).logEvent('download corrections export', {
        platform: 'Web',
        category: 'Payroll',
        subcategory1: 'Payroll',
        subcategory2: 'Payroll Group',
      });
    } else if (attachment?.exportDate) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('download export', { platform: 'Web', category: 'Payroll', subcategory1: 'Payroll', subcategory2: 'Payroll Group' });
    }
    this.injector
      .get(HttpClient)
      .get(attachment.attachmentUrl, { responseType: 'arraybuffer' })
      .subscribe((arrayBuffer) => {
        FileSaver.saveAs(new Blob([arrayBuffer]), attachment.attachmentName);
      });
  }

  public toggleTableView(event: string) {
    this.viewSelected = event;

    this.employeeData =
      this.viewSelected === this.PAYROLL_VIEW_SPLIT ? this.payrollGroupData.employeeSplitData : this.payrollGroupData.employeeData;
    this.employeeDataColumns =
      this.viewSelected === this.PAYROLL_VIEW_SPLIT
        ? this.payrollGroupData.employeeSplitDataColumns
        : this.payrollGroupData.employeeDataColumns;
    this.employeeDataColumnsTranslationKeys =
      this.viewSelected === this.PAYROLL_VIEW_SPLIT
        ? this.payrollGroupData.employeeSplitDataColumnsTranslationKeys
        : this.payrollGroupData.employeeDataColumnsTranslationKeys;

    this.setPagination();
  }

  public changeSelectedTab(tabName) {
    if (tabName === PAYROLL_TAB_EMPLOYEE_DATA) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('view employee data', { platform: 'Web', category: 'Payroll', subcategory1: 'Payroll', subcategory2: 'Payroll Group' });
    } else if (tabName === PAYROLL_TAB_TIME_OFF) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('view time off data', { platform: 'Web', category: 'Payroll', subcategory1: 'Payroll', subcategory2: 'Payroll Group' });
    } else if (tabName === PAYROLL_TAB_VARIABLE_PAYMENTS) {
      this.injector.get(PrivateAmplitudeService).logEvent('view variable payments', {
        platform: 'Web',
        category: 'Payroll',
        subcategory1: 'Payroll',
        subcategory2: 'Payroll Group',
      });
    } else if (tabName === PAYROLL_TAB_EMPLOYEE_DOCS) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('view employee docs', { platform: 'Web', category: 'Payroll', subcategory1: 'Payroll', subcategory2: 'Payroll Group' });
    }

    this.selectedTab = tabName;
  }

  public async onCommentUpdated() {
    await this.injector
      .get(PayrollCommentService)
      .injectCommentData(this.payrollGroupData, this.fromDate, this.isPayrollConfirmed, this.selectedSettings._id);
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    try {
      if (check.not.assigned(this.selectedGroupId)) {
        await this.initData();
      }

      if (!this.hasUserPayrollPermissions) {
        this.router.navigate(['/cloud/home']);
        return;
      }

      if (this.selectedFeature === 'payroll') {
        this.selectedTab = PAYROLL_TAB_EMPLOYEE_DATA;
        await this.getCurrentPayrollGroupData();
      }
      if (this.selectedFeature === 'datev') {
        await this.getCurrentDatevGroupData();
      }

      resolveFetchData();
    } catch (error) {
      rejectFetchData(error);
    }
  }

  private async initData(): Promise<void> {
    await this.configureGlobalBar();

    this.route.queryParams.subscribe((params) => {
      this.selectedGroupId = params.payrollGroupId;
      this.selectedFeature = this.selectedFeature ?? params.feature;
      this.periodIdentifierValue = check.assigned(params.period) ? parseInt(params.period) : 0;
    });

    const [userPermissions] = await Promise.all([
      this.injector.get(PrivateSecurityService).getAllPermissions(),
      this.checkDatevActive(),
      this.checkPayrollActive(),
    ]);

    this.hasUserPayrollExportPermissions = userPermissions['payroll-feature'].c_exportCompanyPayroll === true;
    this.hasUserPayrollPermissions =
      userPermissions['payroll-feature'].c_managePayroll === true && this.hasUserPayrollExportPermissions === true;

    if (!this.hasUserPayrollPermissions) {
      return;
    }
    const findQuery = { _id: { $ne: null } };

    const settingsPromisesToResolve: Array<Promise<any>> = [this.injector.get(PayrollSettingsService).getAllPayrollSettings(findQuery)];

    if (this.hasUserPayrollPermissions && this.isDatevActive) {
      settingsPromisesToResolve.push(this.getDatevSettings());
    }

    const [payrollSettingsList, datevSettingsList] = await Promise.all(settingsPromisesToResolve);
    this.payrollSettingsList = payrollSettingsList;
    this.datevSettingsList = datevSettingsList;

    const isAssignedPayrollSettingsList =
      check.assigned(this.payrollSettingsList) && check.array(this.payrollSettingsList) && check.not.emptyArray(this.payrollSettingsList);

    // If user has permissions for payroll (admin /finance-admin or hr-admin) but there aren't created settings, we need to create the default settings
    if (this.hasUserPayrollPermissions && !isAssignedPayrollSettingsList) {
      this.payrollSettingsList = await this.injector.get(PayrollSettingsService).createDefaultPayrollSettings();
    }

    if (this.hasUserPayrollPermissions && isAssignedPayrollSettingsList) {
      this.processDefaultPayrollSettings();
      this.settingsOptions = [];
      if (this.isPayrollActive) {
        this.settingsOptions = [...this.createSettingsOptions(this.payrollSettingsList, this.i18n.page.payrollGroupSelectLabel, 'payroll')];
      }

      if (this.hasUserPayrollPermissions && this.isDatevActive) {
        const datevSettingsOptions = this.createSettingsOptions(this.datevSettingsList, this.i18n.page.datevGroupSelectLabel, 'datev');
        this.settingsOptions = [...this.settingsOptions, ...datevSettingsOptions];
      }
    }
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view payroll tab', { platform: 'Web', category: 'People', subcategory1: 'Payroll' });
  }

  protected async configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;
    const options: Array<IMenuOption> = [
      {
        name: this.i18n.menu.companyTab,
        onClick: () => {
          this.router.navigate(['/cloud/payroll/company'], { relativeTo: this.route });
        },
      },
    ];

    if (this.injector.get(CloudRoutesService).checkRoute('payroll/settings') === true) {
      options.push({
        name: this.i18n.menu.payrollSettingsTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/payroll/settings');
        },
      });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('payroll/datev') === true) {
      options.push({
        name: this.i18n.menu.datevTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/payroll/datev');
        },
      });
    }

    const optionIndex = _.findIndex(options, ['name', this.i18n.menu.companyTab]);
    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
  }

  private processDefaultPayrollSettings() {
    this.payrollSettingsList.forEach((iPayrollSetting) => {
      iPayrollSetting.name =
        iPayrollSetting.name === '--- FIXED ---'
          ? this.i18n.payroll['--- FIXED ---']
          : iPayrollSetting.name === '--- HOURLY ---'
          ? this.i18n.payroll['--- HOURLY ---']
          : iPayrollSetting.name;
    });
  }

  private createSettingsOptions(
    settingsOptions: Array<IPayrollSettingModel> | Array<IDatevSettingsModel>,
    label: string,
    feature: string
  ): Array<ISelectOption> {
    const labelSelectOption = {
      name: label,
      value: undefined,
    };

    const selectOptions = settingsOptions.map((iSetting) => {
      return {
        name: iSetting.name ?? iSetting.payrollGroupName,
        value: `${feature}.${iSetting._id}`,
      };
    });

    return [labelSelectOption, ...selectOptions];
  }

  public async downloadExport() {
    this.exporting = true;
    try {
      await this.injector.get(PayrollExportService).downloadExport(this.payrollGroupData, this.selectedSettings);
    } finally {
      this.injector.get(PrivateAmplitudeService).logEvent('download export', {
        platform: 'Web',
        category: 'Payroll',
        subcategory1: 'Payroll',
        subcategory2: 'Payroll Group',
        subcategory3: this.selectedSettings.payPeriod,
      });
      this.exporting = false;
    }
  }

  public async confirmDatev(): Promise<void> {
    this.confirmingPayroll = true;
    try {
      this.injector.get(PrivateAmplitudeService).logEvent(`confirm DATEV Payroll`, {
        platform: 'Web',
        category: 'DATEV',
        subcategory1: 'View payroll',
        subcategory2: 'Run payroll',
        subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
      });
      const datevExports = this.datevData.map((iDatevData) => {
        const companyId = this.companies.find((iCompany) => iCompany.name === iDatevData.data.company.value)?._id;
        return {
          companyId,
          datevSettingsId: iDatevData?.data?.datevSettingsId?.value,
          from: this.fromDate.toDate(),
          to: this.toDate.toDate(),
          periodIdentifier: this.periodIdentifierValue,
        };
      });
      await this.injector.get(DatevExportService).confirm(datevExports);
      this.refreshData(true);
    } finally {
      this.confirmingPayroll = false;
    }
  }

  public async closeDatevPayroll(): Promise<void> {
    const data = {
      companyName: this.selectedSettings?.['payrollGroupName'],
      datevSettingsId: this.selectedSettings._id,
      target: (this.selectedSettings as IDatevSettingsModel)?.target,
    };

    const response = await this.injector.get(MatLegacyDialog).open(CloseDatevPayrollDialog, { data }).afterClosed().toPromise();

    if (check.not.assigned(response)) {
      return;
    }

    const { method } = response;

    if (check.not.assigned(method)) {
      return;
    }

    if (method === 'download') {
      await this.downloadDatevFile();
      return;
    }

    await this.sendToDatev();
  }

  private async downloadDatevFile() {
    try {
      this.confirmingPayroll = true;
      this.injector.get(PrivateAmplitudeService).logEvent(`close DATEV Payroll`, {
        platform: 'Web',
        category: 'DATEV',
        subcategory1: 'View payroll',
        subcategory2: 'Run payroll',
        subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
      });
      this.injector.get(PrivateChurnzeroService).logDatevExportEvent();

      const datevSettings = this.selectedSettings as IDatevSettingsModel;

      if (datevSettings.target === DATEV_INTERFACE_LODAS) {
        await this.injector
          .get(DatevAsciiController)
          .exportDatevZip(
            this.selectedGroupId,
            this.fromDate.toDate(),
            this.toDate.toDate(),
            this.periodIdentifierValue,
            datevSettings?.payrollGroupName
          );
        this.injector.get(PrivateAmplitudeService).logEvent('download DATEV export', {
          platform: 'Web',
          category: 'DATEV',
          subcategory1: 'Payroll',
          subcategory2: 'LODAS',
          subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
        });
      } else {
        await this.injector
          .get(DatevExportController)
          .getExport(this.selectedGroupId, this.toDate.toDate(), datevSettings?.payrollGroupName, this.periodIdentifierValue);
        this.injector.get(PrivateAmplitudeService).logEvent('download DATEV export', {
          platform: 'Web',
          category: 'DATEV',
          subcategory1: 'Payroll',
          subcategory2: 'Lohn & Gehalt',
          subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
        });
      }

      this.refreshData(true);
    } finally {
      this.confirmingPayroll = false;
    }
  }

  private async sendToDatev() {
    this.injector.get(PrivateAmplitudeService).logEvent(`close DATEV Payroll`, {
      platform: 'Web',
      category: 'DATEV',
      subcategory1: 'View payroll',
      subcategory2: 'Run payroll',
      subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
    });
    this.injector.get(PrivateChurnzeroService).logDatevExportEvent();

    const data = {
      selectedGroupId: this.selectedGroupId,
      fromDate: this.fromDate.toDate(),
      toDate: this.toDate.toDate(),
      periodIdentifier: this.periodIdentifierValue,
    };

    const success = await this.injector.get(MatLegacyDialog).open(ExportAndCloseDatevPayrollDialog, { data }).afterClosed().toPromise();

    if (success) {
      this.injector.get(PrivateAmplitudeService).logEvent('send DATEV Dataservices', {
        platform: 'Web',
        category: 'DATEV',
        subcategory1: 'Payroll',
        subcategory2: 'LODAS',
        subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
      });
      this.refreshData(true);
    }
  }

  public async reopenDatevPayroll(): Promise<void> {
    const data = {
      titleText: this.i18n.page.reopenDialogTitle,
      subtitleText: this.i18n.page.reopenDialogSubtitle,
      confirmButtonText: this.i18n.page.reopenDialogConfirmButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.page.reopenDialogBackButton,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm: boolean) => {
      if (confirm !== true) {
        return;
      }

      this.confirmingPayroll = true;
      try {
        this.injector.get(PrivateAmplitudeService).logEvent(`reopen DATEV Payroll`, {
          platform: 'Web',
          category: 'DATEV',
          subcategory1: 'View payroll',
          subcategory2: 'Run payroll',
          subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
        });

        const datevExports = this.datevData.map((iDatevData) => {
          const companyId = this.companies.find((iCompany) => iCompany.name === iDatevData.data.company.value)?._id;
          return {
            companyId,
            datevSettingsId: iDatevData?.data?.datevSettingsId?.value,
            from: this.fromDate.toDate(),
            to: this.toDate.toDate(),
          };
        });

        await this.injector.get(DatevExportService).reopen(datevExports);
        this.refreshData(true);
        return;
      } finally {
        this.confirmingPayroll = false;
      }
    });
  }

  public async confirmPayroll(): Promise<void> {
    const data = {
      titleText: this.i18n.page.closePayrollTitle,
      subtitleText: this.selectedSettings._id === 'datev' ? this.i18n.page.closeDatevMessage : this.i18n.page.closePayrollMessage,
      confirmButtonText: this.i18n.page.confirmButton,
      confirmButtonColor: 'Success',
      cancelButtonText: this.i18n.page.goBackButton,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm: boolean) => {
      if (confirm !== true) {
        return;
      }

      this.confirmingPayroll = true;
      try {
        if (
          this.selectedGroupId === 'datev' ||
          [DATEV_INTERFACE_LODAS, DATEV_INTERFACE_LOHN_GEHALT].includes(this.selectedSettings?.['target'])
        ) {
          this.injector.get(PrivateAmplitudeService).logEvent(`confirm DATEV Payroll`, {
            platform: 'Web',
            category: 'DATEV',
            subcategory1: 'Payroll',
            subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
          });

          const datevExports = this.datevData.map((iDatevData) => {
            const companyId = this.companies.find((iCompany) => iCompany.name === iDatevData.data.company.value)?._id;
            return {
              companyId,
              datevSettingsId: iDatevData?.data?.datevSettingsId?.value,
              from: this.fromDate.toDate(),
              to: this.toDate.toDate(),
            };
          });

          await this.injector.get(DatevExportService).confirm(datevExports);
          this.refreshData(true);
          return;
        }

        await this.injector.get(PayrollService).confirmPayroll(this.payrollGroupData, this.selectedSettings);
        this.injector.get(PrivateAmplitudeService).logEvent('confirm', {
          platform: 'Web',
          category: 'Payroll',
          subcategory1: 'Payroll',
          subcategory2: 'Payroll Group',
          subcategory3: this.selectedSettings.payPeriod,
        });
        this.refreshData(true);
      } finally {
        this.confirmingPayroll = false;
      }
    });
  }

  public async checkForCorrections(): Promise<void> {
    this.checkingCorrections = true;
    try {
      await this.injector.get(PayrollService).checkForCorrections(this.payrollGroupData, this.selectedSettings);
      this.refreshData(true);
    } finally {
      this.injector.get(PrivateAmplitudeService).logEvent('check for corrections', {
        platform: 'Web',
        category: 'Payroll',
        subcategory1: 'Payroll',
        subcategory2: 'Payroll Group',
      });
      this.checkingCorrections = false;
    }
  }

  public amplitudeEvent(): void {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view payroll group', { platform: 'Web', category: 'Payroll', subcategory1: 'Payroll', subcategory2: 'Payroll Group' });
  }

  /**
   *  changeRecordsToShow(recordsPerPage: number)
   *  When the number of records per page changes it is saved by fetching to the data engine
   */
  public changeRecordsToShow(recordsPerPage: number): void {
    if (recordsPerPage === this.queryOptions.recordsPerPage) {
      return;
    }
    this.queryOptions.recordsPerPage = recordsPerPage;
    this.queryOptions.page = 1;

    this.setPagination();
  }

  async changeDatevRecordsToShow(recordsPerPage: number, tableKey: string): Promise<void> {
    if (recordsPerPage === this.datevQueryOptions.recordsPerPage) {
      return;
    }
    this.datevQueryOptions.recordsPerPage = recordsPerPage;
    this.datevQueryOptions.page = 1;

    await this.filterDatevTables(tableKey);
  }

  /**
   *  moveToPage(option: number)
   *  When the current page changes it is saved by fetching to the data engine
   */
  public moveToPage(option: number): void {
    const currentPagePrev = this.queryOptions.page;
    if (option === this.PAGE_SELECTOR['first']) {
      this.queryOptions.page = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && this.queryOptions.page > 1) {
      this.queryOptions.page--;
    } else if (option === this.PAGE_SELECTOR['next'] && this.queryOptions.page < this.numberOfPages) {
      this.queryOptions.page++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      this.queryOptions.page = this.numberOfPages;
    }
    if (currentPagePrev === this.queryOptions.page) {
      return;
    }

    this.selectRecordsPerPage();
  }

  /**
   *  moveToDatevPage(option: number)
   *  When the current page changes it is saved by fetching to the data engine
   */
  async moveToDatevPage(option: number, tableKey: string): Promise<void> {
    const currentPagePrev = this.datevQueryOptions.page;
    if (option === this.PAGE_SELECTOR['first']) {
      this.datevQueryOptions.page = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && this.datevQueryOptions.page > 1) {
      this.datevQueryOptions.page--;
    } else if (option === this.PAGE_SELECTOR['next'] && this.datevQueryOptions.page < this.datevPagination.pages) {
      this.datevQueryOptions.page++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      this.datevQueryOptions.page = this.datevPagination.pages;
    }
    if (currentPagePrev === this.datevQueryOptions.page) {
      return;
    }

    await this.filterDatevTables(tableKey);
  }

  async moveToDatevPaymentPage(option: number, iTableKey: string): Promise<void> {
    const tableKey = iTableKey === 'hourlyAttendanceFields' ? 'hourlySalaryFields' : iTableKey;
    if (check.not.assigned(this.datevTables['payAndAttendanceTab']?.[tableKey].paginationKey)) {
      return;
    }

    const currentPagePrev =
      this.datevTables?.['payAndAttendancePagination']?.[this.datevTables['payAndAttendanceTab'][tableKey].paginationKey]?.currentPage;

    if (check.not.assigned(currentPagePrev)) {
      return;
    }

    let newPage = currentPagePrev;

    if (option === this.PAGE_SELECTOR['first']) {
      newPage = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && newPage > 1) {
      newPage--;
    } else if (
      option === this.PAGE_SELECTOR['next'] &&
      newPage < this.datevTables?.['payAndAttendancePagination']?.[this.datevTables['payAndAttendanceTab'][tableKey].paginationKey]?.pages
    ) {
      newPage++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      newPage = this.datevTables?.['payAndAttendancePagination']?.[this.datevTables['payAndAttendanceTab'][tableKey].paginationKey]?.pages;
    }
    if (currentPagePrev === newPage) {
      return;
    }

    const datevSettings = this.selectedSettings as IDatevSettingsModel;
    const tables = datevSettings.target === DATEV_INTERFACE_LODAS ? datevSettings.userTables : datevSettings.lugTables;
    await this.managePayAndAttendanceTab(
      this.datevTables['payAndAttendanceTab'],
      this.datevTables['payAndAttendancePagination'],
      tableKey,
      tables,
      newPage
    );
  }

  private setPagination() {
    this.totalOfRecords = this.employeeData.length;
    this.numberOfPages = Math.ceil(this.employeeData.length / this.queryOptions.recordsPerPage);

    this.selectRecordsPerPage();
  }

  private selectRecordsPerPage() {
    const firstRecordIndex = (this.queryOptions.page - 1) * this.queryOptions.recordsPerPage;
    const lastRecordIndex = this.queryOptions.page * this.queryOptions.recordsPerPage;

    this.recordsToShow = this.employeeData.slice(firstRecordIndex, lastRecordIndex);
  }

  private async getDatevSettings(): Promise<Array<IDatevSettingsModel>> {
    const [datevSettings, companies] = await Promise.all([
      this.injector.get(DatevSettingsService).find(),
      this.injector.get(CompanyService).getData(),
    ]);
    this.companies = companies;

    if (check.not.assigned(datevSettings)) {
      this.isDatevActive = false;
    }

    return datevSettings;
  }

  private async checkDatevActive(): Promise<void> {
    const datevStatus = await this.injector.get(CloudRoutesService).getAppStatus('datev');
    this.isDatevActive = datevStatus?.isActive;
  }

  private async checkPayrollActive(): Promise<void> {
    const payrollStatus = await this.injector.get(CloudRoutesService).getAppStatus('payroll');
    this.isPayrollActive = payrollStatus?.isActive;
  }

  public sortingDataAccessor: (row: any, sortHeaderKey: string) => string | number = (row: any, sortHeaderKey: string): string | number => {
    return row.data[sortHeaderKey].value;
  };

  public changeGroup(value: string): void {
    const [feature, id] = value.split('.');
    this.selectedGroupId = id;
    this.selectedFeature = feature;
    this.periodIdentifierValue = 0;
    this.queryOptions.page = 1;
    this.datevPayAndAttendanceTabKeys = [];
    this.datevEmployeeDataTabKeys = [];

    this.amplitudeEvent();
    this.refreshData(true);
  }

  public onUploadedDocuments(event: Array<IFileMetadata>) {
    if (this.selectedFeature === 'datev') {
      this.injector.get(PrivateAmplitudeService).logEvent('Import payslips finishes', {
        platform: 'Web',
        category: 'DATEV',
        subcategory1: 'View payroll',
        subcategory2: 'Run payroll',
        subcategory3: (this.selectedSettings as IDatevSettingsModel)?.target === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
      });
    }
    this.uploadedDocuments = event;
  }

  protected changeTab(index: number) {
    this.selectedTabIndex = index;
  }

  protected changeDatevTab(index: number) {
    this.selectedDatevTabIndex = index;
  }

  protected async changeColumnSort(sortOptions: any, tableKey: string): Promise<void> {
    if (check.not.assigned(sortOptions) || check.emptyObject(sortOptions)) {
      return;
    }
    this.datevSortBy = sortOptions?.sortBy;
    this.datevSortOrder = sortOptions?.sortOrder;
    await this.filterDatevTables(tableKey);
  }

  public openSurchargeDetails(row): void {
    if (!this.fromDate || !this.toDate || !row?.userId || !row?.payingCompanyId) {
      return;
    }

    const popupData: ISurchargeParamsDataModel = {
      userId: row.userId,
      companyId: row.payingCompanyId,
      fromDate: this.fromDate.toDate(),
      toDate: this.toDate.toDate(),
    };

    this.injector.get(MatLegacyDialog).open(SurchargeDetailsDialog, {
      data: { surchargeParams: popupData, trackedHours: row?.data?.['payroll-custom.trackedHours']?.value },
    });
  }
}
