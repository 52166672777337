import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kenjo-shiftplan-notification-toggle',
  templateUrl: 'notification-toggle.component.html',
  styleUrls: ['notification-toggle.component.scss']
})
export class NotificationToggleComponent implements OnInit {
  notificationToggle: boolean = false;

  @Input() parentTranslation: { [key: string]: string };
  @Input() canSendNotifications: boolean = false;
  @Input() loading: boolean = false;

  @Output() toggleValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.notificationToggle = this.canSendNotifications;
  }

  onToggleChange(event: boolean) {
    this.toggleValue.emit(event);
  }
}
