import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PayrollSettingsService {
  private USER_PAYROLL_SETTING_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/payroll-settings-db`;

  private httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
  private httpOptions = {
    headers: this.httpHeaders
  };

  constructor(private genericService: GenericService, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  async getAllPayrollSettings(findQuery): Promise<Array<IPayrollSettingModel>> {
    if (this.authenticationService.isUserAuthenticated() === false) {
      const error = new OrgosError(this.USER_PAYROLL_SETTING_URL, ErrorCodes.UNAUTHORIZED, PayrollSettingsService.name, 'getAllPayrollSettings');
      throw this.errorManager.handleRawError(error, PayrollSettingsService.name, 'getAllPayrollSettings');
    }

    try {
      return await this.http.post<any>(`${this.USER_PAYROLL_SETTING_URL}/find`, findQuery, this.httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollSettingsService.name, 'getAllPayrollSettings');
    }
  }

  async createDefaultPayrollSettings(): Promise<Array<IPayrollSettingModel>> {
    if (this.authenticationService.isUserAuthenticated() === false) {
      const error = new OrgosError(this.USER_PAYROLL_SETTING_URL, ErrorCodes.UNAUTHORIZED, PayrollSettingsService.name, 'createDefaultPayrollSettings');
      throw this.errorManager.handleRawError(error, PayrollSettingsService.name, 'createDefaultPayrollSettings');
    }

    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

    const httpOptions = {
      headers: httpHeaders
    };
    try {
      return await this.http.post<any>(`${this.USER_PAYROLL_SETTING_URL}/default`, { create: true }, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollSettingsService.name, 'createDefaultPayrollSettings');
    }
  }

  async create(data: object): Promise<IPayrollSettingModel> {
    try {
      return await this.genericService.create(this.USER_PAYROLL_SETTING_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollSettingsService.name, 'create');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.USER_PAYROLL_SETTING_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollSettingsService.name, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.USER_PAYROLL_SETTING_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollSettingsService.name, 'deleteById');
    }
  }
}

export interface IPayrollSettingModel {
  name: string;
  description?: string;
  payPeriodWeeklyFirstDay?: number;
  conditions: Array<{
    blockAndConditions?: [
      {
        collectionName: string;
        operator: string;
        value: string;
      }
    ];
  }>;
  payPeriod: string;
  hourlyCalculation: string;
  payPeriodCustomMonthlyDate?: number;
  payPeriodVariableFromToDate: moment.Moment;
  numberOfExtraPayments: number;
  extraPaymentsMonths?: Array<string>;
  timeOffType: Array<any>;
  documentType: Array<any>;
  exportFormat: string;
  dataIncluded: string;
  files: {
    attendanceReport: boolean;
    timeOff: boolean;
    documents: Array<any>;
  };
  listFields: Array<any>;
  lastConfirmationDate: moment.Moment;
  _id?: string;
  _containsLegacy?: true;
  commentColumnEnabled?: boolean;
}
