<div *ngIf="i18n.page && parentTranslation && i18n.country">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
    {{parentTranslation.locationHeader}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333"
      >{{parentTranslation.availableLocations}}</span
    >
  </div>
  <div class="kenjo-mt-30px kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-mv-20px sspl-white-space">
    {{i18n.page.locationsDescription}}
    <orgos-action [uppercase]="false" (click)="navigateToOfficeLocation()">
      {{i18n.page.settings}}
      <kenjo-icon size="14" class="kenjo-font-color-primary-5993e3">launch</kenjo-icon>
    </orgos-action>
  </div>
  <div *ngIf="locations?.length === 0" class="sspl-no-locations-container kenjo-mt-60px">
    <img class="spet-no-tag-image" src="/assets/images/emptyState.svg" />
    <div class="kenjo-font-size-16px kenjo-mt-30px kenjo-font-color-text-000000">{{ i18n.page.noLocationsTitle }}</div>
    <div class="kenjo-font-size-14px kenjo-mt-10px kenjo-font-color-light-text-757575">{{ i18n.page.noLocationsSubtitle }}</div>
  </div>

  <kenjo-input-search-shift-plan
    *ngIf="locations?.length > 0"
    [sortable]="false"
    [list]="locations"
    [placeholder]="i18n.page.searchForLocations"
    (filteredResults)="filteredLocations = $event"
  >
  </kenjo-input-search-shift-plan>

  <div *ngIf="loading === true" class="kenjo-mh-20px sspl-loading-container">
    <div class="kenjo-text-align-center">
      <orgos-loading-spinner></orgos-loading-spinner>
      <div class="kenjo-mt-20px">{{i18n.page.loading}}</div>
    </div>
  </div>
  <orgos-container aspect="card" *ngIf="filteredLocations?.length > 0 && !loading" class="kenjo-mt-20px kenjo-mb-10px">
    <orgos-table
      [data]="filteredLocations"
      [manageColumns]="false"
      [displayedColumns]="displayedColumns"
      [totalColumns]="displayedColumns"
      [selectable]="false"
      enableCustomSorting="true"
    >
      <orgos-table-column columnKey="name" [showTooltip]="false" [header]="i18n.page.locationName">
        <ng-container *orgosTableCell="let row">
          <div
            [matTooltipPosition]="'above'"
            [matTooltip]="!row.activeForShiftplan ? i18n.page.tooltipDisableLocation : ''"
            [class.sspl-disable]="!row.activeForShiftplan"
          >
            {{ row.name }}
          </div>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="companyId" [showTooltip]="false" [header]="i18n.page.locationCompany">
        <ng-container *orgosTableCell="let row">
          <div
            [matTooltipPosition]="'above'"
            [matTooltip]="!row.activeForShiftplan ? i18n.page.tooltipDisableLocation : ''"
            [class.sspl-disable]="!row.activeForShiftplan"
          >
            {{ companyMapName[row.companyId] }}
          </div>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="street" [showTooltip]="false" [header]="i18n.page.locationStreet">
        <ng-container *orgosTableCell="let row">
          <div
            [matTooltipPosition]="'above'"
            [matTooltip]="!row.activeForShiftplan ? i18n.page.tooltipDisableLocation : ''"
            [class.sspl-disable]="!row.activeForShiftplan"
          >
            {{ row.street }}
          </div>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="city" [showTooltip]="false" [header]="i18n.page.locationCity">
        <ng-container *orgosTableCell="let row">
          <div
            [matTooltipPosition]="'above'"
            [matTooltip]="!row.activeForShiftplan ? i18n.page.tooltipDisableLocation : ''"
            [class.sspl-disable]="!row.activeForShiftplan"
          >
            {{ row.city }}
          </div>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="postalCode" [showTooltip]="false" [header]="i18n.page.locationPostalCode">
        <ng-container *orgosTableCell="let row">
          <div
            [matTooltipPosition]="'above'"
            [matTooltip]="!row.activeForShiftplan ? i18n.page.tooltipDisableLocation : ''"
            [class.sspl-disable]="!row.activeForShiftplan"
          >
            {{ row.postalCode }}
          </div>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="country" [showTooltip]="false" [header]="i18n.page.locationCountry">
        <ng-container *orgosTableCell="let row">
          <div
            [matTooltipPosition]="'above'"
            [matTooltip]="!row.activeForShiftplan ? i18n.page.tooltipDisableLocation : ''"
            [class.sspl-disable]="!row.activeForShiftplan"
          >
            {{ i18n.country[row.country] }}
          </div>
        </ng-container>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>
  <kenjo-shift-plan-paginator
    *ngIf="!loading"
    [parentTranslation]="parentTranslation"
    [data]="locations"
    (recordsToShow)="setFilteredLocationsToShow($event)"
  ></kenjo-shift-plan-paginator>
</div>
