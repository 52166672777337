import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';

import { environment } from '../../../environments/environment';
import { ErrorCodes } from '../../standard/core/error/error-codes';
import { OrgosError } from '../../standard/core/error/orgos-error';
import { AuthenticationService } from '../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../../standard/services/error/error-manager.service';

@Injectable({
  providedIn: 'root',
})
export class PrivateUserAccountService {
  private USER_ACCOUNT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-account-db/user-accounts`;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private errorManager: ErrorManagerService,
    private userPersonalService: UserPersonalService
  ) {}

  completeOnboarding(userIdToUpdate: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, PrivateUserAccountService.name, 'completeOnboarding');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .put(`${this.USER_ACCOUNT_URL}/complete-onboarding/${userIdToUpdate}`, null, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PrivateUserAccountService.name, 'completeOnboarding'));
        });
    });
  }

  resetPassword(email: string, showErrors: boolean = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .post(`${this.USER_ACCOUNT_URL}/reset-password`, { email: email }, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (showErrors === true) {
            reject(this.errorManager.handleRawError(error, PrivateUserAccountService.name, 'resetPassword'));
            return;
          }

          reject(this.errorManager.handleRawErrorSilently(error, PrivateUserAccountService.name, 'resetPassword'));
          return;
        });
    });
  }

  resendActivation(userId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, PrivateUserAccountService.name, 'resendActivation');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .post(`${this.USER_ACCOUNT_URL}/resend-activation/${userId}`, null, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PrivateUserAccountService.name, 'resendActivation'));
        });
    });
  }

  archiveUser(userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.authenticationService.getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .put(`${this.USER_ACCOUNT_URL}/archive/${userId}`, { _id: userId }, httpOptions)
        .toPromise()
        .then(() => {
          this.userPersonalService.clearCache();
          resolve();
          return;
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PrivateUserAccountService.name, 'archiveUser'));
          return;
        });
    });
  }

  async deactivateUser(userId: string): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.authenticationService.getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      return await this.http.put(`${this.USER_ACCOUNT_URL}/deactivate/${userId}`, { _id: userId }, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, PrivateUserAccountService.name, 'deactivateUser');
    }
  }
}
