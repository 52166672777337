import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  private SLACK_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/slack`;

  constructor(private injector: Injector) {}

  getBasicIntegrationInfo(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .get(`${this.SLACK_MICROSERVICE_URL}/basic-info`)
        .toPromise()
        .then((basicIntegrationInfo) => {
          resolve(basicIntegrationInfo);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, SlackService.name, 'getBasicIntegrationInfo'));
        });
    });
  }

  getChannels(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getBasicIntegrationInfo()
        .then((basicIntegrationInfo) => {
          return new Promise((resolvePage, rejectPage) => {
            const data = {
              accessToken: basicIntegrationInfo.accessToken,
              allChannels: []
            };
            this.getPageOfChannelFromSlack(data, resolvePage, rejectPage);
          });
        })
        .then((channels) => {
          resolve(channels);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, SlackService.name, 'getChannels'));
        });
    });
  }

  private getPageOfChannelFromSlack(data, resolveOnFinish, rejectOnError, cursor?) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const httpOptions = {
      headers: httpHeaders
    };

    let httpBody: HttpParams;
    if (check.assigned(cursor)) {
      httpBody = new HttpParams().set('token', data.accessToken).set('exclude_archived', 'true').set('cursor', cursor);
    } else {
      httpBody = new HttpParams().set('token', data.accessToken).set('exclude_archived', 'true');
    }

    this.injector
      .get(HttpClient)
      .post(`https://slack.com/api/conversations.list`, httpBody, httpOptions)
      .toPromise()
      .then((slackResponse: any) => {
        if (slackResponse.ok === false) {
          resolveOnFinish(data.allChannels);
          return;
        }

        data.allChannels = data.allChannels.concat(slackResponse.channels);

        if (check.assigned(slackResponse.response_metadata) && check.assigned(slackResponse.response_metadata.next_cursor) && check.nonEmptyString(slackResponse.response_metadata.next_cursor)) {
          this.getPageOfChannelFromSlack(data, resolveOnFinish, rejectOnError, slackResponse.response_metadata.next_cursor);
        } else {
          resolveOnFinish(data.allChannels);
        }
      })
      .catch((error) => {
        rejectOnError(error);
      });
  }
}
