<div class="dialog-box">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <!-- TITLE -->
  <orgos-column-container centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-text type="Headline1">{{translation.dailogTitle}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <!-- SUBTITLE -->
  <orgos-column-container centerColumns="true" class="kenjo-mt-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-text *ngIf="wizardStep === 1" type="Headline4">{{translation.dialogStep1Title}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 2" type="Headline4">{{translation.dialogStep2Title}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 3" type="Headline4">{{translation.dialogStep3Title}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 4" type="Headline4">{{translation.dialogStep4Title}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 5" type="Headline4">{{translation.dialogStep5Title}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <!-- CONTENT -->
  <div class="kenjo-ph-60px cgd-content">
    <!-- STEP 1 - GOAL TYPE -->
    <orgos-column-container *ngIf="wizardStep === 1" centerColumns="true" class="kenjo-pt-60px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
          <orgos-column size="0" class="cgd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div
              class="cgd-option"
              [class.selected-option]="goalCacheModel.data.type === TYPE_INDIVIDUAL"
              (click)="goalCacheModel.data.type = TYPE_INDIVIDUAL"
            >
              <mat-icon class="cgd-option-icon">person</mat-icon>
            </div>
            <div class="kenjo-pv-20px cgd-option-text">
              <orgos-text [color]="goalCacheModel.data.type === TYPE_INDIVIDUAL? 'Primary': 'NormalText'">
                {{translation.individual | uppercase}}</orgos-text
              >
            </div>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="cgd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div
              class="cgd-option"
              [class.selected-option]="goalCacheModel.data.type === TYPE_DEPARTMENT"
              (click)="goalCacheModel.data.type = TYPE_DEPARTMENT"
            >
              <mat-icon class="cgd-option-icon">department</mat-icon>
            </div>
            <div class="kenjo-pv-20px cgd-option-text">
              <orgos-text [color]="goalCacheModel.data.type === TYPE_DEPARTMENT? 'Primary': 'NormalText'">
                {{translation.department | uppercase}}</orgos-text
              >
            </div>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="cgd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div
              class="cgd-option"
              [class.selected-option]="goalCacheModel.data.type === TYPE_BRAND"
              (click)="goalCacheModel.data.type = TYPE_BRAND"
            >
              <mat-icon class="cgd-option-icon">public</mat-icon>
            </div>
            <div class="kenjo-pv-20px cgd-option-text">
              <orgos-text [color]="goalCacheModel.data.type === TYPE_BRAND? 'Primary': 'NormalText'">
                {{translation.brand | uppercase}}</orgos-text
              >
            </div>
          </orgos-column>
        </orgos-column-container>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>

    <!-- STEP 2 - GOAL DEFINITION -->
    <div *ngIf="wizardStep === 2">
      <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
        <orgos-column>
          <orgos-input-text
            [model]="goalCacheModel"
            field="title"
            required="true"
            (validation)="titleValidation = $event"
            class="kenjo-mr-10px cgd-one-field"
          >
            <orgos-input-error *ngIf="titleValidation && titleValidation.getError('required')">
              {{translation.titleMadatory}}</orgos-input-error
            >
          </orgos-input-text>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
        <orgos-column>
          <orgos-input-text-area [model]="goalCacheModel" field="description" required="false" class="kenjo-mr-10px cgd-one-field">
          </orgos-input-text-area>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
        <orgos-column [class.kenjo-pr-20px]="goalCacheModel.data.type === TYPE_DEPARTMENT">
          <orgos-input-date-picker
            [model]="goalCacheModel"
            field="dueDate"
            [min]="TODAY"
            required="false"
            min=""
            class="kenjo-mr-10px cgd-one-field"
          >
          </orgos-input-date-picker>
        </orgos-column>
        <orgos-column *ngIf="goalCacheModel.data.type === TYPE_DEPARTMENT">
          <orgos-input-select
            [model]="goalCacheModel"
            [options]="departments"
            field="departmentId"
            (validation)="departmentValidation = $event"
            [required]="true"
            class="kenjo-mr-10px cgd-one-field"
          >
            <orgos-input-error *ngIf="departmentValidation && departmentValidation.getError('required')">
              {{translation.departmentMandatory}}</orgos-input-error
            >
          </orgos-input-select>
        </orgos-column>
      </orgos-column-container>
    </div>

    <!-- STEP 3 - OWNERS -->
    <div *ngIf="wizardStep === 3">
      <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
        <orgos-column>
          <orgos-search
            [placeholder]="translation.dialogUsersLabel"
            [searchFunction]="searchUserFunction"
            (searchResultsChange)="searchResults = $event"
          >
            <orgos-text class="orgos-search-error" color="Warn">{{translation.dialogNotFoundPeopleError}}</orgos-text>
            <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="assignUser(result._id)">
              <kenjo-truncate-text>{{result.displayName}}</kenjo-truncate-text>
            </orgos-search-option>
            <orgos-column-container wrap="true" class="orgos-search-selection-container">
              <orgos-column size="0" *ngFor="let result of searchResults" class="kenjo-m-10px">
                <orgos-chip
                  [imageUrl]="result._photo?._url"
                  buttonIconName="clear"
                  [onClickButtonFunction]="removeUser(result._id)"
                  class="srp-search-result"
                  [addInitialsIfNoImage]="true"
                  [chipText]="result.displayName"
                  >{{result.displayName}}</orgos-chip
                >
              </orgos-column>
            </orgos-column-container>
          </orgos-search>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container
        centerColumns="true"
        class="kenjo-pt-20px"
        *ngIf="numberOfAssingedUsers && goalCacheModel.data.assignedUsers && goalCacheModel.data.assignedUsers.length > 0"
      >
        <orgos-column>
          <orgos-text type="BodyText">{{numberOfAssingedUsers}}</orgos-text>
        </orgos-column>
      </orgos-column-container>
    </div>

    <!-- STEP 4 - TRACKER SELECTION -->
    <orgos-column-container *ngIf="wizardStep === 4" centerColumns="true" class="kenjo-pt-60px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
          <orgos-column size="0" class="cgd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div class="cgd-opn" [class.selected-option]="trackerType === TRACKER_PROGRESS" (click)="trackerType = TRACKER_PROGRESS">
              < >
              <mat-icon class="cgd-option-icon">progress_bar</mat-icon>
            </div>
            <div class="kenjo-pv-20px cgd-option-text">
              <orgos-text [color]="trackerType === TRACKER_PROGRESS? 'Primary': 'NormalText'">
                {{translation.progressBar | uppercase}}</orgos-text
              >
            </div>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="cgd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div class="cgd-opn" [class.selected-option]="trackerType === TRACKER_KEY" (click)="trackerType = TRACKER_KEY">
              < >
              <mat-icon class="cgd-option-icon">key_results</mat-icon>
            </div>
            <div class="kenjo-pv-20px cgd-option-text">
              <orgos-text [color]="trackerType === TRACKER_KEY? 'Primary': 'NormalText'"> {{translation.keyResult | uppercase}}</orgos-text>
            </div>
          </orgos-column>
        </orgos-column-container>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>

    <!-- STEP 5 - KEY RESULTS -->
    <div *ngIf="wizardStep === 5" class="kenjo-mv-40px">
      <div *ngIf="goalCacheModel.data.keyResults && goalCacheModel.data.keyResults.length > 0">
        <orgos-column-container
          *ngFor="let iKeyResult of goalCacheModel.data.keyResults; let i = index; trackBy: trackTitle"
          centerColumns="true"
          class="kenjo-border kenjo-border-radius kenjo-mv-10px"
        >
          <orgos-column size="1">
            <input
              type="text"
              [placeholder]="translation.dialogKeyResultLabel"
              [ngModel]="iKeyResult.title"
              (ngModelChange)="changeKeyResult(i, $event)"
              (click)="editName = i"
              (blur)="editName = -1"
              class="cgd-title"
              matTooltipPosition="above"
            />
          </orgos-column>
          <orgos-column size="0" class="kenjo-pr-20px">
            <kenjo-icon [size]="20" (click)="deleteKeyResult(i)" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757"
              >delete</kenjo-icon
            >
          </orgos-column>
        </orgos-column-container>
      </div>
      <orgos-text
        class="kenjo-pb-40px cgd-warning-text"
        *ngIf="!goalCacheModel.data.keyResults || goalCacheModel.data.keyResults.length === 0"
      >
        {{translation.dialogKeyResultMandatory}}
      </orgos-text>
      <orgos-text class="kenjo-pb-40px cgd-warning-text" *ngIf="addKeyResultWarning && addKeyResultWarning===true">
        {{translation.dialogKeyResultTitleMandatory}}
      </orgos-text>
      <orgos-text type="Headline4" class="kenjo-pb-20px kenjo-pt-60px">
        <orgos-action (click)="addKeyResult();" icon="add_circle">{{translation.addKeyResult}}</orgos-action>
      </orgos-text>
    </div>
  </div>
  <!-- BUTTONS -->
  <orgos-column-container centerColumns="true" class="kenjo-pb-20px kenjo-mh-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="cgd-button-align">
          <div>
            <mat-icon class="cgd-cancel-button" (click)="leftButtonAction()">
              {{wizardStep > 1 ? 'arrow_back_circle' : 'cancel_icon'}}</mat-icon
            >
          </div>
          <div>
            <orgos-text type="SecondaryText"> {{wizardStep > 1 ? translation.dialogBackBtn : translation.dialogCancelBtn}}</orgos-text>
          </div>
        </orgos-column>
        <orgos-column size="1" class="cgd-button-align">
          <div>
            <mat-icon class="cgd-create-button" (click)="rightButtonAction()" [class.cgd-disabled-button]="!isValidFormData()"
              >arrow_next_circle</mat-icon
            >
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.cgd-disabled-button]="!isValidFormData()">
              {{wizardStep === WIZARD_FINAL_STEP || (wizardStep === STEP_TRACKER && trackerType === TRACKER_PROGRESS)?
              translation.dialogSaveBtn : translation.dialogNextBtn}}
            </orgos-text>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
