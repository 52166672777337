import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import * as check from 'check-types';
import * as _ from 'lodash';

import { GenericArrayModel } from '../../core/generic-array-model';
import { GenericSimpleModel } from '../../core/generic-simple-model';
import { CostCenterService } from '../../services/company/cost-center.service';
import { SettingsBarService } from '../../services/settings/settings-bar.service';
import { UserPersonalService } from '../../services/user/user-personal.service';
import { UserWorkService } from '../../services/user/user-work.service';
import { GenericPage, ITranslationResource } from '../generic.page';
import { CreateCostCenterDialog } from './dialogs/create-cost-center.dialog';

@Component({
  selector: 'orgos-settings-cost-centers',
  templateUrl: 'settings-cost-centers.page.html',
  styleUrls: ['settings-cost-centers.page.scss'],
})
export class SettingsCostCentersPage extends GenericPage {
  costCentersModel: GenericArrayModel;
  costCenterToEdit: GenericSimpleModel;
  costCenterIdToUserIds: any;
  optionHover: number = -1;

  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'settings-top-bar' },
    { name: 'page', translationKey: 'settings-cost-centers-page' },
    { name: 'globalMisc', translationKey: 'misc' },
    { name: 'deleteWarningDialog', translationKey: 'delete-warning-cost-center-dialog' },
    { name: 'deleteCostCenterDialog', translationKey: 'delete-cost-center-dialog' },
  ];

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(SettingsBarService)
        .getOptions(this.i18n.misc)
        .then((options) => {
          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.costCentersTab]);
          resolve();
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    const NUMBER_OF_DATA_TO_FETCH = 2;
    let dataFetched = 0;

    this.injector
      .get(CostCenterService)
      .getCostCenters()
      .then((costCenters: Array<any>) => {
        this.costCentersModel = new GenericArrayModel(this.injector, costCenters, CostCenterService, '');
        if (
          check.assigned(this.costCentersModel) &&
          check.nonEmptyArray(this.costCentersModel.data) &&
          check.assigned(this.costCentersModel.data[0])
        ) {
          let newCostCenterToEdit;
          if (check.assigned(this.costCenterToEdit)) {
            newCostCenterToEdit = this.costCenterToEdit.data.find((iCostCenter) => {
              return this.costCenterToEdit.data._id === iCostCenter.data._id;
            });
          }
          this.costCenterToEdit = check.assigned(newCostCenterToEdit) ? newCostCenterToEdit : this.costCentersModel.data[0];
        }

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        // An error is already shown
        this.costCentersModel = null;
        this.costCenterToEdit = null;
        rejectFetchData();
      });

    const getAllUserWork = this.injector.get(UserWorkService).getAllUserWorkComplete(true);
    const getAllUserPersonal = this.injector.get(UserPersonalService).getAllUserPersonal(true);
    Promise.all([getAllUserWork, getAllUserPersonal])
      .then((results) => {
        const allUserWork = results[0];
        const allUserPersonal = results[1];

        const userPersonalInfo = allUserPersonal.reduce((total, curr) => {
          const userInfo = {
            displayName: curr.displayName,
            _photo: curr._photo,
          };
          total[curr['_id']] = userInfo;
          return total;
        }, {});

        // Map for retrieve users by cost center Id
        this.costCenterIdToUserIds = allUserWork.reduce((total, curr) => {
          if (check.assigned(curr.costCenters) && check.nonEmptyArray(curr.costCenters)) {
            curr.costCenters.forEach((iCostCenter) => {
              if (check.not.assigned(total[iCostCenter._costCenterId])) {
                total[iCostCenter._costCenterId] = [];
              }
              total[iCostCenter._costCenterId].push(userPersonalInfo[curr._id]);
            });
          }
          return total;
        }, {});
        // User alphabetical order for every cost center id
        this.costCenterIdToUserIds = Object.keys(this.costCenterIdToUserIds).reduce((total, curr) => {
          const orderedUsers = _.orderBy(this.costCenterIdToUserIds[curr], ['displayName'], ['asc']);
          total[curr] = orderedUsers;
          return total;
        }, {});

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        // An error is already shown
        rejectFetchData();
      });
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view settings page', { category: 'Navigation', type: 'cost centers' });
    return Promise.resolve();
  }

  openAddCostCenterDialog(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateCostCenterDialog);
    dialogRef.afterClosed().subscribe((rawCostCenterData: any) => {
      if (check.assigned(rawCostCenterData)) {
        this.costCentersModel
          .createNewElement(rawCostCenterData)
          .then(() => {})
          .catch(() => {
            // Do nothing, an error is already shown
          });
      }
    });
  }

  openDeleteCostCenterDialog(costCenterIndex: number): void {
    const centerToDelete = this.costCentersModel.data[costCenterIndex];
    if (this.getNumberOfPeopleInCostCenters(centerToDelete.data._id) > 0) {
      const dialogData: any = {
        titleText: this.i18n.deleteWarningDialog.dialogHeader,
        subtitleText: this.i18n.deleteWarningDialog.dialogSubtitle,
        confirmButtonText: this.i18n.deleteWarningDialog.okCostCenterButtonLabel,
        confirmButtonColor: 'Success',
      };

      this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
      return;
    }

    const data: any = {
      titleText: this.i18n.deleteCostCenterDialog.dialogHeader,
      subtitleText: this.i18n.deleteCostCenterDialog.dialogSubtitle,
      confirmButtonText: this.i18n.deleteCostCenterDialog.deleteCostCenterButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.globalMisc.goBackButtonDialog,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe((deleteCostCenter: boolean) => {
      if (check.assigned(deleteCostCenter) && deleteCostCenter === true && costCenterIndex >= 0) {
        this.costCentersModel
          .deleteElement(costCenterIndex)
          .then(() => {
            this.costCenterToEdit = check.nonEmptyArray(this.costCentersModel.data) ? this.costCentersModel.data[0] : null;
          })
          .catch(() => {
            // Do nothing, an error is already shown
          });
      }
    });
  }

  getNumberOfPeopleInCostCenters(costCenterId: string): number {
    if (
      check.not.assigned(this.costCenterIdToUserIds) ||
      check.emptyArray(this.costCenterIdToUserIds) ||
      check.not.assigned(this.costCenterIdToUserIds[costCenterId])
    ) {
      return 0;
    }
    return this.costCenterIdToUserIds[costCenterId].length;
  }
}
