import { Component, Input, OnInit } from '@angular/core';
import { STATUS, VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { IShiftCard } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { IShiftplanExportPDFOptions } from '@app/cloud-features/shift-plan/services/shift-plan-generate-pdf.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shift-card-pdf',
  templateUrl: 'shift-card-pdf.component.html',
  styleUrls: ['shift-card-pdf.component.scss'],
})
export class ShiftCardPDFComponent implements OnInit {
  start: string;
  end: string;
  views = { ...VIEWS };
  status = { ...STATUS };
  roleName: string;
  MAX_CHAR_LENGTH = {
    WEEK: 0,
    MONTH: 2,
  };
  colorConstants: any = userColorConstants;

  @Input() shift: IShiftCard;
  @Input() options: IShiftplanExportPDFOptions;

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.calculateShiftDuration();
    this.MAX_CHAR_LENGTH.WEEK = this.options.orientation === 'landscape' ? 15 : 10;
    this.roleName = this.trimRoleName();
  }

  calculateShiftDuration() {
    const startDate = moment.utc(this.shift.date).clone().add(this.shift.start, 'minutes');
    const endDate = moment.utc(this.shift.date).clone().add(this.shift.end, 'minutes');
    this.start = startDate.format('HH:mm');
    this.end = endDate.format('HH:mm');
  }

  trimRoleName() {
    if (this.options.frequency === this.views.WEEK) {
      return this.shift.role.name.length >= this.MAX_CHAR_LENGTH.WEEK
        ? `${this.shift.role.name.slice(0, this.MAX_CHAR_LENGTH.WEEK)}...`
        : this.shift.role.name;
    }

    if (this.options.frequency === this.views.MONTH) {
      return this.shift.role?.codeName || this.shift.role.name.slice(0, this.MAX_CHAR_LENGTH.MONTH);
    }
  }
}
