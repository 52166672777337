<orgos-dialog-container class="dialog-box" *ngIf="tag" [title]="tag._id ? dialogTranslation.dialogTitleEdit : dialogTranslation.dialogTitleAdd" (clickCloseButton)="closeDialog()">
  <orgos-column-container>
    <orgos-input-simple-text orgosColumn="1" [label]="dialogTranslation.tagName" [(value)]="tag.name" [required]="true" (valueChange)="checkIfDataIsCompleted()" (validation)="nameValidation = $event" [readOnly]="predefinedTag" (validation)="nameValidation = $event">
      <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')"> {{dialogTranslation.nameRequired}}</orgos-input-error>
    </orgos-input-simple-text>
  </orgos-column-container>
  <orgos-column class="kenjo-ml-20px">
    <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{documentTagCollectionTranslation.color}}</div>
    <orgos-input-simple-color-picker [(value)]="tag.color" [readOnly]="tag.predefined"></orgos-input-simple-color-picker>
  </orgos-column>

  <div class="kenjo-font-color-text-333333 kenjo-font-size-16px kenjo-mt-40px kenjo-mb-30px">{{dialogTranslation.visibleToEveryoneTitle }}</div>

  <div class="etd-visible-everyone">
    <div class="kenjo-font-color-light-text-757575">{{dialogTranslation.visibleToEveryoneToggle }}</div>
    <mat-slide-toggle [checked]="tag.visibleToEveryone" (change)="tag.visibleToEveryone = $event.checked; checkIfDataIsCompleted()"></mat-slide-toggle>
  </div>

  <div *ngIf="!tag.visibleToEveryone" class="kenjo-mt-50px">
    <kenjo-audience-selector
      [audienceVisibility]="tag.visibleToSpecific"
      (onChange)="tag.visibleToSpecific = $event; checkIfDataIsCompleted()"
      [selectEntityLabel]="dialogTranslation.selectVisibilityEntityLabel"
      [includeEmployees]="true"
      [selectUserLabel]="dialogTranslation.selectVisibilityUserLabel"
    >
    </kenjo-audience-selector>
  </div>

  <div class="kenjo-mt-60px kenjo-text-align-right">
    <orgos-button-raised *ngIf="!isEdit" color="Success" (click)="addTag()" [disabled]="!dataIsCompleted || nameValidation.getError('required')">{{dialogTranslation.addButtonLabel}} </orgos-button-raised>
    <orgos-button-raised *ngIf="isEdit" color="Neutral" class="kenjo-mr-10px" (click)="closeDialog()" [disabled]="!dataIsCompleted">{{dialogTranslation.goBackButtonLabel}} </orgos-button-raised>
    <orgos-button-raised *ngIf="isEdit" color="Success" (click)="editTag()" [disabled]="!dataIsCompleted || nameValidation.getError('required')">{{dialogTranslation.saveButtonLabel}} </orgos-button-raised>
  </div>
</orgos-dialog-container>
