import { Component, Input } from '@angular/core';

@Component({
  selector: 'orgos-loading-spinner',
  templateUrl: 'loading-spinner.component.html',
  styleUrls: ['loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  @Input() size: string = 'Regular';
  @Input() color: string = '';
}
