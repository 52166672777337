<div class="spel-main-container">
  <div>
    <!-- Title -->
    <div class="kenjo-font-size-16px kenjo-mb-10px kenjo-font-weight-bold">{{ activateShiftPlanTranslation.locationsStep }}</div>
    <div class="kenjo-font-size-14px kenjo-mb-30px kenjo-font-color-light-text-757575">{{ activateShiftPlanTranslation.locationsSubTitle }}</div>
    <kenjo-input-search-shift-plan id="spel-locations" [sortable]="false" [list]="allLocations" [placeholder]="activateShiftPlanTranslation.searchLocations" (filteredResults)="filteredLocations = $event"></kenjo-input-search-shift-plan>

    <!-- Locations Table -->
    <orgos-table
      *ngIf="filteredLocations?.length > 0"
      class="kenjo-border-radius kenjo-border"
      [data]="filteredLocations"
      [manageColumns]="false"
      [displayedColumns]="displayedColumns"
      [totalColumns]="displayedColumns"
      [fullHeight]="true"
      [maxHeight]="allLocations.length > 25 ? MAX_TABLE_HEIGHT_WITH_PAGINATION : MAX_TABLE_HEIGHT_WITHOUT_PAGINATION"
      [selectable]="true"
    >
      <orgos-table-column [showTooltip]="false" columnKey="selected" [header]="activateShiftPlanTranslation.select" cellWidth="10">
        <ng-container *orgosTableCell="let row">
          <orgos-input-simple-checkbox [disabled]="!row?.activeForShiftplan" [value]="row.selected" (valueChange)="changeSelectedLocations($event, row._id)" class="kenjo-font-size-14px"></orgos-input-simple-checkbox>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column [showTooltip]="false" columnKey="name" [header]="activateShiftPlanTranslation.name">
        <ng-container *orgosTableCell="let row">
          <orgos-column-container centerColumns="true" [matTooltipPosition]="'above'" [matTooltip]="!row.activeForShiftplan ? activateShiftPlanTranslation.tooltipDisableLocation : ''" [class.spel-disable]="!row.activeForShiftplan">
            <div orgosColumn="0" [class.spel-circle-mini]="currentUserOfficeId === row._id"></div>
            <div orgosColumn [class.kenjo-pl-5px]="currentUserOfficeId === row._id">{{ row.name }}</div>
          </orgos-column-container>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column [showTooltip]="false" columnKey="street" [header]="activateShiftPlanTranslation.street">
        <ng-container *orgosTableCell="let row">
          <div [matTooltipPosition]="'above'" [matTooltip]="!row.activeForShiftplan ? activateShiftPlanTranslation.tooltipDisableLocation : ''" [class.spel-disable]="!row.activeForShiftplan">{{ row.street }}</div>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column [showTooltip]="false" columnKey="city" [header]="activateShiftPlanTranslation.city">
        <ng-container *orgosTableCell="let row">
          <div [matTooltipPosition]="'above'" [matTooltip]="!row.activeForShiftplan ? activateShiftPlanTranslation.tooltipDisableLocation : ''" [class.spel-disable]="!row.activeForShiftplan">{{ row.city }}</div>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column [showTooltip]="false" columnKey="country" [header]="activateShiftPlanTranslation.country">
        <ng-container *orgosTableCell="let row">
          <div [matTooltipPosition]="'above'" [matTooltip]="!row.activeForShiftplan ? activateShiftPlanTranslation.tooltipDisableLocation : ''" [class.spel-disable]="!row.activeForShiftplan">{{ countriesTranslations.country[row.country] }}</div>
        </ng-container>
      </orgos-table-column>
    </orgos-table>
  </div>
</div>

<orgos-column-container centerColumns="true" class="kenjo-mt-20px">
  <orgos-column>
    <orgos-column-container centerColumns="true">
      <div orgosColumn="0" class="spel-circle-big"></div>
      <div orgosColumn class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pl-10px">
        {{ activateShiftPlanTranslation.selectedLocation }}
      </div>
    </orgos-column-container>
  </orgos-column>
  <orgos-column size="0">
    <!-- Navigation buttons for creation-->
    <div *ngIf="userWork.data.shiftPlanActivationStatus !== 'finished'" class="kenjo-text-align-right">
      <orgos-button-raised id="spel-next-button" [color]="'Success'" (click)="nextStep()" [disabled]="selectedLocationsIds.length <= 0">{{ activateShiftPlanTranslation.nextButton }} </orgos-button-raised>
    </div>

    <!-- Navigation buttons for edition-->
    <div *ngIf="userWork.data.shiftPlanActivationStatus === 'finished'" class="kenjo-text-align-right">
      <orgos-button-raised id="spel-cancel-button" [color]="'Neutral'" class="kenjo-mr-20px" [disabled]="!dataHasChanged" (click)="cancelChanges(); dataHasChanged = false; filteredLocations = allLocations">{{ activateShiftPlanTranslation.cancelButton }}</orgos-button-raised>
      <orgos-button-raised id="spel-save-button" [color]="'Success'" [disabled]="!dataHasChanged || selectedLocationsIds.length <= 0" (click)="saveData(); saveSnackbar(); dataHasChanged = false">{{ activateShiftPlanTranslation.saveButton }}</orgos-button-raised>
    </div>
  </orgos-column>
</orgos-column-container>
