<div class="tpc-search-input">
  <input [placeholder]="placeholder" [(ngModel)]="searchTerm" />
</div>
<div *ngIf="foundTags && foundTags.length > 0" class="tpc-results">
  <div *ngFor="let iTag of foundTags" class="tpc-result-item" (click)="selectTag(iTag)">
    <orgos-column-container centerColumns="true">
      <orgos-column size="0" class="kenjo-mr-10px">
        <div class="tpc-tag-color" [style.background-color]="getTagColor(iTag.color)"></div>
      </orgos-column>
      <orgos-column>{{ iTag.name }}</orgos-column>
    </orgos-column-container>
  </div>
</div>
<div *ngIf="!foundTags || foundTags.length === 0" class="tpc-no-results kenjo-font-color-danger-ff5757">
  {{ errorMessage }}
</div>
