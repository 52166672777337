<ng-container *ngIf="model && field">
  <mat-checkbox *ngIf="readOnly" [disabled]="true" [ngModel]="getValue()" [required]="required">
    <orgos-text *ngIf="labelPosition === 'after'" color="LightText">{{ label }}</orgos-text>
  </mat-checkbox>
  <mat-checkbox *ngIf="!readOnly" [disabled]="false" [ngModel]="getValue()" (ngModelChange)="setValue($event)" [required]="required">
    <orgos-text *ngIf="labelPosition === 'after'">{{ label }}</orgos-text>
  </mat-checkbox>
  <orgos-text *ngIf="labelPosition === 'bottom'" class="kenjo-mt-10px" [color]="readOnly ? 'LightText' : 'NormalText'">{{ label }}</orgos-text>
</ng-container>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
