<orgos-container *ngIf="!loadingPage">
  <!-- HEADER -->
  <orgos-column-container centerColumns="true" class="pdpnt-header">
    <orgos-column-container orgosColumn="0" centerColumns="true" class="pdpnt-container kenjo-border-right">
      <orgos-column size="0">
        <mat-icon orgosColumn="0" (click)="clickBack()" class="pdpnt-back-arrow kenjo-mh-10px kenjo-font-color-primary-5993e3">arrow_back </mat-icon>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container orgosColumn="0" centerColumns="true" class="pdpnt-container kenjo-border-right">
      <orgos-column size="0" class="kenjo-font-size-16px"> {{ i18n.page.tabTitle }} </orgos-column>
    </orgos-column-container>
  </orgos-column-container>
  <orgos-container aspect="card" class="pdpnt-main-container kenjo-mt-20px">
    <div class="kenjo-pt-30px kenjo-pl-30px kenjo-pr-30px">
      <div class="kenjo-font-size-18px kenjo-font-color-text-333333 pdpnt-spacing-1">{{ i18n.page.title }}</div>
      <orgos-column-container centerColumns="true" class="kenjo-pt-15px">
        <kenjo-icon orgosColumn="0" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
        <orgos-column class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pl-10px"> {{ i18n.page.description }} </orgos-column>
      </orgos-column-container>

      <orgos-container aspect="card" *ngIf="teamEmployees.length > 0" class="kenjo-mt-40px">
        <orgos-table fullHeight [data]="teamEmployees" [manageColumns]="false" [totalColumns]="totalColumns" [selectable]="false">
          <orgos-table-column columnKey="name" [header]="i18n.page.columnName">
            <ng-container *orgosTableCell="let row">
              <orgos-column-container centerColumns="true" class="kenjo-mt-10px kenjo-mb-10px">
                <orgos-avatar orgosColumn="0" [avatarName]="row.displayName" [photoUrl]="row._photo?._url" size="small"> </orgos-avatar>
                <kenjo-truncate-text class="pdpnt-relevant-field kenjo-ml-10px kenjo-font-size-14px kenjo-mr-60px" orgosColumn="1"> {{row.displayName}}</kenjo-truncate-text>
              </orgos-column-container>
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="jobTitle" [header]="i18n.page.columnJobTitle">
            <ng-container *orgosTableCell="let row">
              <kenjo-truncate-text class="pdpnt-relevant-field kenjo-font-size-14px kenjo-mr-60px"> {{row.jobTitle}}</kenjo-truncate-text>
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="status" [header]="i18n.page.columnStatus">
            <orgos-chip *orgosTableCell="let row" [color]="STATUS_COLOR_MAP[row.status]">{{i18n.page[row.status]}}</orgos-chip>
          </orgos-table-column>
          <orgos-table-column columnKey="actions" [header]="i18n.page.columnActions">
            <ng-container *orgosTableCell="let row">
              <orgos-button-raised orgosColumn="0" color="Primary" (click)="openPeers(row)"> {{ i18n.page[availableAction] }} </orgos-button-raised>
            </ng-container>
          </orgos-table-column>
        </orgos-table>
      </orgos-container>
    </div>
  </orgos-container>
</orgos-container>
