import { Component, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { InternationalizationService } from '../../services/core/internationalization.service';

@Component({
  selector: 'orgos-report-table',
  templateUrl: 'report-table.component.html',
  styleUrls: ['report-table.component.scss']
})
export class ReportTableComponent implements OnInit {
  specificHeaderTranslations: any = {};

  @Input() template: any;
  @Input() reportDataToDisplay: Array<any>; // Array of object with the fields that will be displayed
  @Input() displayedColumns: Array<string>;
  @Input() translationMatrix: any = {};

  reportDataToDisplayParsed: Array<any>; // Array of object with the fields that will be displayed

  constructor(private injector: Injector) {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('reports-page')
      .then((pageTranslations) => {
        if (check.assigned(pageTranslations) && check.assigned(pageTranslations.reportHeaders)) {
          this.specificHeaderTranslations = pageTranslations.reportHeaders;
        }
      })
      .catch(() => {
        this.specificHeaderTranslations = {};
      });
  }

  ngOnInit(): void {
    this.parseRows();
    this.sortTable();
  }

  getTranslation(fieldName: string): string {
    if (check.not.assigned(fieldName) || check.not.string(fieldName) || check.emptyString(fieldName)) {
      return '';
    }

    if (check.assigned(this.specificHeaderTranslations) && check.assigned(this.specificHeaderTranslations[fieldName])) {
      return this.specificHeaderTranslations[fieldName];
    }

    if (check.contains(fieldName, '.')) {
      const object = fieldName.split('.')[0].trim();
      const field = fieldName.split('.')[1].trim();
      return this.translationMatrix[object] && this.translationMatrix[object][field] ? this.translationMatrix[object][field] : field;
    }

    const masterObject = this.template.metadata.collection;
    return this.translationMatrix[masterObject] && this.translationMatrix[masterObject][fieldName] ? this.translationMatrix[masterObject][fieldName] : fieldName;
  }

  private sortTable(): void {
    this.reportDataToDisplayParsed = _.sortBy(this.reportDataToDisplayParsed, (iElement) => {
      if (typeof this.displayedColumns[0] === 'string') {
        return this.displayedColumns[0].toLowerCase();
      }
      return iElement[this.displayedColumns[0]];
    });
  }

  private parseRows(): void {
    this.reportDataToDisplayParsed = this.reportDataToDisplay.map((iRow) => {
      const parsedRow = {};
      this.displayedColumns.forEach((iColumn) => {
        parsedRow[iColumn] = iRow[iColumn]; // all the data is the same but if there is a subCollection -> array of objects
        if (check.array(iRow[iColumn]) && check.object(iRow[iColumn][0])) {
          const value = iRow[iColumn].map((iSubData) => {
            let subData = '';
            Object.keys(iSubData).forEach((iSubColumn) => {
              if (iSubColumn !== '_id') {
                let fieldValue: any = iSubData[iSubColumn];
                if (check.date(new Date(fieldValue))) {
                  fieldValue = moment(fieldValue).format('L');
                }
                subData += `${this.getTranslation(`${iColumn.split('.')[0]}.${iSubColumn}`)}: ${fieldValue} `;
              }
            });
            return subData;
          });
          parsedRow[iColumn] = value;
        }
      });
      return parsedRow;
    });
  }
}
