<div *ngIf="componentTranslation && statusMaps" class="sptoc-container">
  <div #timeOffCard>
    <orgos-column-container
      *ngIf="view === VIEWS.WEEK"
      (click)="onClickCard()"
      class="sptoc-card kenjo-cursor-pointer"
      [class.empty]="!timeOff"
      [class.kenjo-mb-10px]="!show.more"
      [centerColumns]="true"
    >
      <orgos-column size="1">
        <kenjo-truncate-text *ngIf="timeOff" class="kenjo-font-size-12px kenjo-font-color-text-333333 sptoc-card-text">{{
          componentTranslation.timeOffLabel
        }}</kenjo-truncate-text>
      </orgos-column>
      <orgos-column size="0" class="sptoc-card-status">
        <orgos-chip *ngIf="timeOff?.status" [color]="statusMaps.color[timeOff?.status]">
          {{ statusMaps.list[timeOff?.status] | uppercase }}
        </orgos-chip>
      </orgos-column>
    </orgos-column-container>
    <div
      *ngIf="view === VIEWS.MONTH"
      (click)="onClickMonthly()"
      class="sptoc-card-reduced kenjo-border kenjo-cursor-pointer"
      [class.empty]="!timeOff"
    >
      <orgos-chip *ngIf="timeOff?.status" [color]="statusMaps.color[timeOff?.status]"></orgos-chip>
    </div>
  </div>
  <orgos-column-container
    (click)="onClickMore()"
    *ngIf="show.more && view === VIEWS.WEEK"
    class="sptoc-more kenjo-font-color-text-333333 kenjo-mb-10px kenjo-border-radius-4px"
    [class.single-time-off]="timeOff?.total <= 1"
    [centerColumns]="true"
  >
    <orgos-column *ngIf="timeOff?.total > 1"
      >{{ componentTranslation.moreLabel | i18nData: { timeOffsNumber: timeOff.total - 1 } }}
    </orgos-column>
  </orgos-column-container>

  <!-- Details Menu -->
  <div #detailsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="timeOffDetailsMenu" (menuClosed)="closeList()"></div>
  <mat-menu #timeOffDetailsMenu="matMenu" yPosition="above" class="sptoc-details-menu">
    <kenjo-shift-plan-time-off-details
      *ngIf="show.details"
      [parentTranslation]="componentTranslation"
      [statusMaps]="statusMaps"
      [timeOffDetail]="selectedTimeOff"
      [userId]="userId"
      [disconnected]="disconnected"
      (close)="closeDetails()"
    ></kenjo-shift-plan-time-off-details>
  </mat-menu>

  <!-- List Menu -->
  <div #listMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="timeOffListMenu" (menuClosed)="closeList()"></div>
  <mat-menu #timeOffListMenu="matMenu" yPosition="above" class="sptoc-list-menu">
    <kenjo-shift-plan-time-off-list
      *ngIf="show.more && show.list"
      [parentTranslation]="componentTranslation"
      [statusMaps]="statusMaps"
      [timeOffList]="timeOffList"
      [userId]="userId"
      [date]="currentDay.value"
      [disconnected]="disconnected"
      (close)="closeList()"
    ></kenjo-shift-plan-time-off-list>
  </mat-menu>
</div>
