<div class="kenjo-p-40px psc-content">
  <div>
    <div class="kenjo-font-weight-bold kenjo-font-size-16px">{{ translations.personalHeader }}</div>

    <orgos-column-container class="kenjo-mt-10px">
      <orgos-input-text orgosColumn [model]="userPersonal" field="firstName" required="true" (validation)="firstNameValidation = $event" class="kenjo-mr-10px">
        <orgos-input-error *ngIf="firstNameValidation && firstNameValidation.getError('required')"> {{ translations.firstNameRequired }}</orgos-input-error>
      </orgos-input-text>
      <orgos-input-text orgosColumn [model]="userPersonal" field="lastName" required="true" (validation)="lastNameValidation = $event" class="kenjo-ml-10px">
        <orgos-input-error *ngIf="lastNameValidation && lastNameValidation.getError('required')"> {{ translations.lastNameRequired }}</orgos-input-error>
      </orgos-input-text>
    </orgos-column-container>

    <orgos-column-container class="kenjo-mt-5px">
      <orgos-input-date-picker orgosColumn [model]="userPersonal" field="birthdate" [max]="todayDate" (validation)="birthdateValidation = $event" class="kenjo-mr-10px"> </orgos-input-date-picker>
      <orgos-input-select orgosColumn [model]="userPersonal" field="gender" picklist="gender" class="kenjo-ml-10px"></orgos-input-select>
    </orgos-column-container>

    <orgos-column-container class="kenjo-mt-5px">
      <orgos-input-select orgosColumn [model]="userPersonal" field="nationality" picklist="nationality" [enableClearButton]="true" class="kenjo-mr-20px"></orgos-input-select>
      <orgos-column></orgos-column>
    </orgos-column-container>
  </div>

  <div class="psc-button kenjo-mt-40px">
    <orgos-button-raised (click)="onNextStep()" color="Success" [disabled]="!firstNameValidation || firstNameValidation.hasErrors() || !lastNameValidation || lastNameValidation.hasErrors()">{{ translations.nextLabel }}</orgos-button-raised>
  </div>
</div>
