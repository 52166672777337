import { Injectable, Injector } from '@angular/core';
import { IPdfMetadata, PdfUtilsService } from '@app/common-components/layout-import-payslips/services/pdf-utils.service';
import { TaskName, TaskProgress } from '@app/common-components/layout-import-payslips/services/task-progress';
import { IDocumentModel } from '@app/standard/services/document/document.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as lunr from 'lunr';

@Injectable({
  providedIn: 'root'
})
export class PayslipsImportService {
  constructor(private injector: Injector) {}

  processUploadedPayslips(uploadedPayslips: Array<IFileMetadata>, usersMap: IUsersMap, onProgress: OnProgressCallback): Promise<Array<IProcessedPayslipMetadata>> {
    return new Promise((resolve, reject) => {
      this.parseUploadedPayslips(uploadedPayslips, onProgress)
        .then((parsedPayslips: Array<IPayslipMetadata>) => {
          return this.matchParsedPayslipsWithUsers(parsedPayslips, usersMap, onProgress);
        })
        .then((matchedPayslips) => {
          return this.splitPayslips(matchedPayslips, onProgress);
        })
        .then((splitPayslips) => {
          return this.generateFinalPayslips(splitPayslips);
        })
        .then((finalPayslips) => {
          resolve(finalPayslips);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private parseUploadedPayslips(uploadedPayslips: Array<IFileMetadata>, onProgress: OnProgressCallback): Promise<Array<IPayslipMetadata>> {
    const taskProgress = new TaskProgress('ParsePdf', uploadedPayslips.length, onProgress);

    const parsePayslipPromises: Array<Promise<IPayslipMetadata>> = uploadedPayslips.map((iUploadedPayslip) => {
      return new Promise((resolve, reject) => {
        this.injector
          .get(PdfUtilsService)
          .parsePdf(iUploadedPayslip._url, taskProgress)
          .then((pdfMetadata: IPdfMetadata) => {
            const payslip: IPayslipMetadata = { fileMetadata: iUploadedPayslip, pdfMetadata: pdfMetadata, identifiedUsersPerPage: new Array(pdfMetadata.numPages), splitPayslips: null };
            resolve(payslip);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    return Promise.all(parsePayslipPromises);
  }

  private matchParsedPayslipsWithUsers(parsedPayslips: Array<IPayslipMetadata>, usersMap: IUsersMap, onProgress: OnProgressCallback): Promise<any> {
    const taskProgress = new TaskProgress('MatchPayslipsWithUsers', _.keys(usersMap).length, onProgress);

    return new Promise((resolve) => {
      const payslipsIndex = this.createPayslipsIndexForSearch(parsedPayslips);

      _.entries(usersMap).forEach(([userId, userData]) => {
        const query = this.buildSearchQueryForLunr(userData);
        const searchResult = payslipsIndex.search(query);
        if (check.assigned(searchResult) && check.nonEmptyArray(searchResult)) {
          let previousMatchUsingRegExp = false;
          // CASE 1: If Lunr engine returns a result, we use the result to match the payslip with the user
          searchResult.forEach((iSearchResult) => {
            if (previousMatchUsingRegExp === true) {
              return;
            }

            const isResultReliable = check.assigned(iSearchResult.matchData) && check.assigned(iSearchResult.matchData.metadata) && _.keys(iSearchResult.matchData.metadata).length > 3 && iSearchResult.score > 5;
            if (isResultReliable !== true) {
              // If we have a match with only three fields or the score is lower than 5, we consider that it is not reliable and we try to match using the regexp approach (in order to reduce the false positives).
              this.matchPayslipsUsingRegExp(parsedPayslips, userData, userId);
              previousMatchUsingRegExp = true;
              return;
            }

            const payslipPosition = iSearchResult.ref.split('#')[0];
            const pagePosition = iSearchResult.ref.split('#')[1];
            if (check.not.assigned(parsedPayslips[payslipPosition].identifiedUsersPerPage[pagePosition])) {
              parsedPayslips[payslipPosition].identifiedUsersPerPage[pagePosition] = [];
            }

            if (!parsedPayslips[payslipPosition].identifiedUsersPerPage[pagePosition].includes(userId)) {
              parsedPayslips[payslipPosition].identifiedUsersPerPage[pagePosition].push(userId);
            }
          });
        } else {
          // CASE 2: If Lurn is not able to return any result, we try to match the payslips with the user using a RegExp
          this.matchPayslipsUsingRegExp(parsedPayslips, userData, userId);
        }

        taskProgress.markAsComplete();
      });

      resolve(parsedPayslips);
    });
  }

  private matchPayslipsUsingRegExp(parsedPayslips, userData, userId): void {
    _.each(parsedPayslips, (iPayslip, payslipIndex) => {
      _.each(iPayslip.pdfMetadata.textPerPage, (iText, pageIndex) => {
        const textToSearch = iText
          .replace(/ /gi, '')
          .replace(/\u{00a0}/giu, '')
          .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');
        const regExp = this.buildRegExpQuery(userData);
        let matched;
        try {
          matched = check.nonEmptyString(regExp) ? textToSearch.search(new RegExp(regExp, 'gi')) > -1 : false;
        } catch (error) {
          matched = false;
        }

        if (matched === true) {
          if (check.not.assigned(parsedPayslips[payslipIndex].identifiedUsersPerPage[pageIndex])) {
            parsedPayslips[payslipIndex].identifiedUsersPerPage[pageIndex] = [];
          }

          if (!parsedPayslips[payslipIndex].identifiedUsersPerPage[pageIndex].includes(userId)) {
            parsedPayslips[payslipIndex].identifiedUsersPerPage[pageIndex].push(userId);
          }
          return;
        }
      });
    });
  }

  private createPayslipsIndexForSearch(payslips: Array<IPayslipMetadata>): any {
    // tslint:disable: no-invalid-this
    const payslipsIndex = lunr(function () {
      this.ref('id');
      this.field('text');

      payslips.forEach((iPayslip, payslipIndex) => {
        iPayslip.pdfMetadata.textPerPage.forEach((iPageText, pageIndex) => {
          this.add({
            id: `${payslipIndex}#${pageIndex}`,
            text: iPageText
          });
        });
      });
    });
    // tslint:enable: no-invalid-this
    return payslipsIndex;
  }

  private buildSearchQueryForLunr(userData: IUserData): string {
    const query = [];

    if (check.assigned(userData.firstName)) {
      const firstName = `&&&${userData.firstName}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&&&&/gi, ' ')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');
      query.push(firstName);
    }

    if (check.assigned(userData.lastName)) {
      const lastName = `&&&${userData.lastName}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&&&&/gi, ' ')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');
      query.push(lastName);
    }

    if (check.assigned(userData.nationalInsuranceNumber)) {
      const nationalInsuranceNumber = `&&&${userData.nationalInsuranceNumber}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&&&&/gi, ' ')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');
      query.push(nationalInsuranceNumber);
    }

    if (check.assigned(userData.nationalId)) {
      const nationalId = `&&&${userData.nationalId}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&&&&/gi, ' ')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');
      query.push(nationalId);
    }

    if (check.assigned(userData.taxCode)) {
      const taxCode = `&&&${userData.taxCode}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&&&&/gi, ' ')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');
      query.push(taxCode);
    }

    if (check.assigned(userData.passport)) {
      const passport = `&&&${userData.passport}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&&&&/gi, ' ')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');
      query.push(passport);
    }

    if (check.assigned(userData.displayName) && userData.displayName !== `${userData.firstName} ${userData.lastName}`) {
      const displayName = `&&&${userData.displayName}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&&&&/gi, ' ')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/:]/gi, '');

      if (displayName.length > (userData.displayName.length * 3) / 4) {
        query.push(displayName);
      }
    }

    return query.join(' ').replace(/\s+/gi, ' ').trim();
  }

  private buildRegExpQuery(userData: IUserData): string {
    const query = [];

    if (check.assigned(userData.firstName) && check.assigned(userData.lastName)) {
      const firstNameLastName = `&&&${userData.firstName} ${userData.lastName}&&&`
        .replace(/\s+/gi, '')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      const lastNameFirstName = `&&&${userData.lastName} ${userData.firstName}&&&`
        .replace(/\s+/gi, '')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      const firstNameCommaLastName = `&&&${userData.firstName}, ${userData.lastName}&&&`
        .replace(/\s+/gi, '')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      const lastNameCommaFirstName = `&&&${userData.lastName}, ${userData.firstName}&&&`
        .replace(/\s+/gi, '')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      query.push(firstNameLastName);
      query.push(lastNameFirstName);
      query.push(firstNameCommaLastName);
      query.push(lastNameCommaFirstName);
    }

    if (check.assigned(userData.nationalInsuranceNumber)) {
      const nationalInsuranceNumber = `&&&${userData.nationalInsuranceNumber}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      query.push(nationalInsuranceNumber);
    }

    if (check.assigned(userData.nationalId)) {
      const nationalId = `&&&${userData.nationalId}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      query.push(nationalId);
    }

    if (check.assigned(userData.taxCode)) {
      const taxCode = `&&&${userData.taxCode}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      query.push(taxCode);
    }

    if (check.assigned(userData.passport)) {
      const passport = `&&&${userData.passport}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&[^&]{1,4}&&&/gi, '')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      query.push(passport);
    }

    if (check.assigned(userData.displayName) && userData.displayName !== `${userData.firstName} ${userData.lastName}` && userData.displayName.length > 5) {
      const displayName = `&&&${userData.displayName}&&&`
        .replace(/\s+/gi, '&&&&&&')
        .replace(/&&&/gi, '')
        .replace(/[|{}()[\]^$+*?.\-/]/gi, '');
      query.push(displayName);
    }

    return query
      .join('|')
      .replace(/\s+/gi, '')
      .replace(/\|+/gi, '|')
      .replace(/(^\|)|(\|$)/gi, '')
      .trim();
  }

  private splitPayslips(payslips: Array<IPayslipMetadata>, onProgress: OnProgressCallback): Promise<Array<IPayslipMetadata>> {
    const taskProgress = new TaskProgress('SplitPayslips', _.sumBy(payslips, 'pdfMetadata.numPages'), onProgress);

    return new Promise((resolve, reject) => {
      const getSplitPayslips = _.map(payslips, (iPayslip) => {
        // CASE 1 - When the payslip has only one page, don't split
        if (iPayslip.pdfMetadata.numPages < 2) {
          taskProgress.markAsComplete(iPayslip.pdfMetadata.numPages);

          return Promise.resolve(iPayslip);
        }

        // CASE 2 - When the payslip has several pages and all of them are for the same user, don't split
        const allAssignedUsers = iPayslip.identifiedUsersPerPage.reduce((accumulator, value) => accumulator.concat(value), []);
        const allPagesAreFromSameUser = _.uniq(allAssignedUsers).length === 1 && check.nonEmptyString(allAssignedUsers[0]);
        if (allPagesAreFromSameUser) {
          taskProgress.markAsComplete(iPayslip.pdfMetadata.numPages);

          return Promise.resolve(iPayslip);
        }

        /*
         * CASE 3 - When the payslip has several pages for different users, split page-by-page
         * CASE 4 - When the payslip has several pages for unknown users, split page-by-page
         */
        return new Promise((resolveSplit, rejectSplit) => {
          this.injector
            .get(PdfUtilsService)
            .splitPdf(iPayslip.pdfMetadata.rawData, taskProgress)
            .then((rawSplitPayslips) => {
              iPayslip.splitPayslips = rawSplitPayslips;
              resolveSplit(iPayslip);
            })
            .catch((error) => {
              rejectSplit(error);
            });
        });
      });

      Promise.all(getSplitPayslips)
        .then((splitPayslips: Array<IPayslipMetadata>) => {
          resolve(splitPayslips);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private generateFinalPayslips(payslips: Array<IPayslipMetadata>): Promise<Array<IProcessedPayslipMetadata>> {
    return new Promise((resolve) => {
      const finalPayslips: Array<IProcessedPayslipMetadata> = _.flatMap(payslips, (iPayslip) => {
        if (check.not.assigned(iPayslip.splitPayslips) || check.emptyArray(iPayslip.splitPayslips)) {
          const finalPayslip: IProcessedPayslipMetadata = {
            originalFileMetadata: iPayslip.fileMetadata,
            fileMetadata: iPayslip.fileMetadata,
            userId: iPayslip.identifiedUsersPerPage[0]?.length === 1 ? iPayslip.identifiedUsersPerPage[0][0] : undefined,
            tentativeUserIds: iPayslip.identifiedUsersPerPage[0],
            rawData: iPayslip.pdfMetadata.rawData,
            isNewFile: false,
            mustBeDeleted: false
          };

          return finalPayslip;
        }

        const finalSplitPayslips = iPayslip.splitPayslips.map((iSplitPayslip, index) => {
          const finalPayslip: IProcessedPayslipMetadata = {
            originalFileMetadata: iPayslip.fileMetadata,
            userId: iPayslip.identifiedUsersPerPage[index]?.length === 1 ? iPayslip.identifiedUsersPerPage[index][0] : undefined,
            tentativeUserIds: iPayslip.identifiedUsersPerPage[index],
            rawData: iSplitPayslip,
            isNewFile: true,
            mustBeDeleted: false
          };

          return finalPayslip;
        });

        return finalSplitPayslips;
      });

      resolve(finalPayslips);
    });
  }
}

export interface IUsersMap {
  [_id: string]: IUserData;
}
export interface IUserData {
  firstName?: string;
  lastName?: string;
  displayName: string;
  nationalId?: string;
  passport?: string;
  nationalInsuranceNumber?: string;
  taxCode?: string;
  photoUrl?: string;
  email?: string;
  _id?: string;
}

interface IPayslipMetadata {
  fileMetadata: IFileMetadata;
  pdfMetadata: IPdfMetadata;
  identifiedUsersPerPage?: Array<Array<string>>;
  splitPayslips?: Array<Uint8Array>;
}

export interface IProcessedPayslipMetadata {
  originalFileMetadata?: IFileMetadata;
  fileMetadata?: IFileMetadata;
  document?: IDocumentModel;
  rawData?: Uint8Array;
  userId?: string;
  tentativeUserIds?: Array<string>;
  isNewFile?: boolean;
  mustBeDeleted?: boolean;
  signatureRequested?: boolean;
}

type OnProgressCallback = (taskName: TaskName, progress: number) => void;
