import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { Injector, NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { LegacyDateAdapter, MAT_LEGACY_DATE_FORMATS, MAT_LEGACY_DATE_LOCALE } from '@angular/material/legacy-core';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, MatLegacyDialogConfig, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorIntl, MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

const defaultDialogConfig = new MatLegacyDialogConfig();
defaultDialogConfig.autoFocus = false;

@NgModule({
  exports: [
    MatLegacyButtonModule,
    MatButtonToggleModule,
    MatLegacyCardModule,
    MatLegacyCheckboxModule,
    MatLegacyChipsModule,
    MatDatepickerModule,
    MatLegacyDialogModule,
    MatIconModule,
    MatLegacyInputModule,
    MatLegacyMenuModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyProgressBarModule,
    MatLegacyRadioModule,
    MatExpansionModule,
    MatLegacySelectModule,
    MatLegacySliderModule,
    MatLegacySlideToggleModule,
    MatLegacySnackBarModule,
    MatSortModule,
    MatLegacyTableModule,
    MatLegacyTabsModule,
    MatLegacyTooltipModule,
    MatLegacyAutocompleteModule,
    MatLegacyPaginatorModule,
    MatDividerModule,

    MatMomentDateModule,
    DragDropModule,
    OverlayModule,
    PortalModule,
    CdkTableModule,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: LegacyDateAdapter, useClass: MomentDateAdapter, deps: [MAT_LEGACY_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_LEGACY_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, useValue: defaultDialogConfig },
    { provide: MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
  ],
})
export class MaterialModule {
  constructor(private injector: Injector) {
    this.injector.get(MatIconRegistry).setDefaultFontSetClass('orgos-font');

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('date-picker-component')
      .then((translations) => {
        const matDatepickerIntl = this.injector.get(MatDatepickerIntl);
        Object.keys(matDatepickerIntl).forEach((iKey: string) => {
          const translation = translations[iKey];
          if (check.assigned(translation) && check.not.emptyString(translation)) {
            matDatepickerIntl[iKey] = translation;
          }
        });
      })
      .catch(() => {
        // Do nothing, component translation is kept
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('paginator-component')
      .then((translations) => {
        const matPaginatorIntl = this.injector.get(MatLegacyPaginatorIntl);
        Object.keys(matPaginatorIntl).forEach((iKey: string) => {
          const translation = translations[iKey];
          if (check.assigned(translation) && check.not.emptyString(translation)) {
            matPaginatorIntl[iKey] = translation;
          }
        });
      })
      .catch(() => {
        // Do nothing, component translation is kept
      });
  }
}
