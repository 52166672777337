import { Injectable } from '@angular/core';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { InternationalizationService } from './internationalization.service';

@Injectable()
export class PicklistsService {
  constructor(private internationalizationService: InternationalizationService, private errorManager: ErrorManagerService) {}

  getPicklist(picklist: string): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      if (check.not.assigned(picklist)) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, PicklistsService.name, 'getPicklist');
        error.message = 'Picklist parameter must be defined';
        reject(this.errorManager.handleParsedErrorSilently(error));
        return;
      }

      this.internationalizationService
        .getAllTranslation('standard-picklists')
        .then((standardPicklists) => {
          if (check.not.assigned(standardPicklists[picklist])) {
            const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, PicklistsService.name, 'getPicklist');
            error.message = 'Picklist not defined';
            reject(this.errorManager.handleParsedErrorSilently(error));
            return;
          }

          resolve(standardPicklists[picklist]);
        })
        .catch((error) => {
          // Do nothing, an error is already shown;
          reject(error);
        });
    });
  }
}
