<div class="rumdd-dialog-box">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <div class="kenjo-mh-60px">
    <orgos-column-container centerColumns="true">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-text type="Headline1">{{dialogTranslations.dialogTitle}}</orgos-text>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-text type="Headline4">{{dialogTranslations.dialogSubtitle}}</orgos-text>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <div class="rumdd-doc-list-container kenjo-mt-40px">
      <div *ngFor="let doc of docsToUpload">
        <orgos-column-container *ngIf="doc.data" class="kenjo-border kenjo-border-radius kenjo-mb-15px kenjo-pt-5px" centerColumns="true">
          <orgos-column size="0" class="kenjo-ml-20px">
            <orgos-document-icon *ngIf="doc.data.extension" orgosColumn="0" [extension]="doc.data.extension"> </orgos-document-icon>
          </orgos-column>
          <orgos-column size="1" class="kenjo-mh-20px">
            <orgos-input-text [model]="doc" field="name" [required]="true" class="rumdd-name-input-field" (validation)="mapNameValidation[doc.data._id] = $event; changeDocName()">
              <orgos-input-error *ngIf="mapNameValidation[doc.data._id] && mapNameValidation[doc.data._id].getError('required')"> {{dialogTranslations.nameRequired}}</orgos-input-error>
            </orgos-input-text>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column *ngIf="tagIdToRecruitingTag[doc.data.tag]" size="0" class="kenjo-mr-20px">
            <div [matMenuTriggerFor]="recruitingTagMenu" class="rumdd-tag-selector">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-pr-10px">
                  <orgos-container class="rumdd-tag-color-circle" [style.background-color]="tagIdToColor[doc.data.tag]"> </orgos-container>
                </orgos-column>
                <orgos-text orgosColumn="0" type="BodyText" color="NormalText"> {{tagIdToRecruitingTag[doc.data.tag].name}}</orgos-text>
                <orgos-column size="0">
                  <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                </orgos-column>
              </orgos-column-container>
            </div>
            <mat-menu #recruitingTagMenu="matMenu">
              <button *ngFor="let tag of listTags" mat-menu-item (click)="doc.data.tag = tag._id">
                <orgos-column-container centerColumns="true">
                  <orgos-column size="0" class="kenjo-pr-10px">
                    <orgos-container class="rumdd-tag-color-circle" [style.background-color]="tagIdToColor[tag._id]"> </orgos-container>
                  </orgos-column>
                  <orgos-text orgosColumn="0" type="BodyText" color="LightText">{{tag.name}}</orgos-text>
                </orgos-column-container>
              </button>
            </mat-menu>
          </orgos-column>
        </orgos-column-container>
      </div>
    </div>
  </div>
  <orgos-column-container centerColumns="true" class="kenjo-mb-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
        <orgos-column size="1" class="rumdd-button-align">
          <div>
            <mat-icon class="rumdd-cancel-button" (click)="closeDialog()">cancel_icon</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText">{{dialogTranslations.cancelButton}}</orgos-text>
          </div>
        </orgos-column>
        <orgos-column size="1" class="rumdd-button-align">
          <div>
            <mat-icon class="rumdd-create-button" [class.rumdd-disabled-button]="cannotSave || uploadingFiles" (click)="uploadDocument()">arrow_next_circle</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.rumdd-disabled-button]="cannotSave || uploadingFiles"> {{dialogTranslations.uploadButton}}</orgos-text>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
