import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ISignatureOptions } from '@app/standard/services/signature-request/signature-request.service';

@Component({
  selector: 'orgos-add-personal-message.dialog',
  templateUrl: 'add-personal-message.dialog.html',
  styleUrls: ['add-personal-message.dialog.scss'],
})
export class AddPersonalMessageDialog implements OnInit {
  dialogTranslation: any = {};
  signatureOptions: ISignatureOptions;

  toolbar: Array<any> = [
    // quill toolbar
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ header: [1, 2, 3, 4, false] }],
    [{ align: [] }],
  ];

  constructor(
    public dialogRef: MatLegacyDialogRef<AddPersonalMessageDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: ISignatureOptions
  ) {}

  ngOnInit(): void {
    this.signatureOptions = {
      ...this.data,
      recipients: [...this.data.recipients],
      companies: [...this.data.companies],
      offices: [...this.data.offices],
      departments: [...this.data.departments],
    };
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-add-personal-message-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });
  }

  onSaveAddPersonalMessage() {
    this.dialogRef.close({
      ...this.signatureOptions,
    });
  }

  onCancelAddPersonalMessage() {
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
