import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserCompensationService } from './user-compensation.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { AuthenticationService } from '../core/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserSalaryService implements IGenericService {
  private USER_SALARY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-salary-db`;
  private USER_SALARY_INTERNATIONALIZATION: string = 'user-salary-collection';
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/user-salary`;


  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private userCompensationService: UserCompensationService, private injector: Injector) {}

  create(data: object): Promise<IUserSalaryModel> {
    return new Promise<IUserSalaryModel>((resolve, reject) => {
      this.genericService
        .create(this.USER_SALARY_URL, data)
        .then((userSalary: IUserSalaryModel) => {
          resolve(userSalary);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserSalaryService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IUserSalaryModel> {
    return new Promise<IUserSalaryModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_SALARY_URL, id)
        .then((userSalary: IUserSalaryModel) => {
          resolve(userSalary);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserSalaryService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_SALARY_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserSalaryService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.USER_SALARY_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserSalaryService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.userCompensationService.getPermissions();
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_SALARY_INTERNATIONALIZATION);
  }

  async getPaidHoursVisibility(paidHoursInfo: IPaidHoursInfo): Promise<IPaidHoursInfoResult> {
    try {
      const { userId, month, year } = paidHoursInfo;
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      return await this.injector.get(HttpClient).get<IPaidHoursInfoResult>(`${this.CONTROLLER_URL}/paid-hours-visibility/${userId}/${month}/${year}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, 'UserSalaryService', 'getPaidHoursVisibility');
    }
  }

  getUserSalaries(userId: string): Promise<Array<IUserSalaryModel>> {
    const findBody = {
      _userId: userId
    };

    return new Promise<Array<IUserSalaryModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_SALARY_URL, findBody)
        .then((userSalaries: Array<IUserSalaryModel>) => {
          resolve(userSalaries);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserSalaryService.name, 'getUserSalaries'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_SALARY_URL);
  }

  calculateSurchargeHourlyRate(userSalary: IUserSalaryModel, workingHoursPerWeek: number): number {
    if (!userSalary || !userSalary.amount || !userSalary.payPeriod || !workingHoursPerWeek) {
      return 0;
    }

    if (userSalary.payPeriod === picklistConstants.PAY_PERIOD_HOURLY) {
      return userSalary.amount;
    }

    const averageHoursPerMonth = (workingHoursPerWeek * 52.2) / 12;
    if (userSalary.payPeriod === picklistConstants.PAY_PERIOD_MONTHLY) {
      return +(userSalary.amount / averageHoursPerMonth).toFixed(2);
    }
    if (userSalary.payPeriod === picklistConstants.PAY_PERIOD_ANNUAL) {
      return +((userSalary.amount / 12) / averageHoursPerMonth).toFixed(2);
    }

    return 0;
  }
}

export interface IUserSalaryModel {
  _id: string;
  _userId: string;
  _companyId?: string;
  effectiveDate?: string;
  amount?: number;
  currency?: string;
  payPeriod?: string; // picklistConstants.PAY_PERIOD_LIST: Annual, Monthly, Hourly
  payFrequency?: string;
  comments?: string;
  endDate?: Date;
  surchargeHourlyRate?: number;
}

export interface IPaidHoursInfoResult {
  showPaidHours: boolean;
}

export interface IPaidHoursInfo {
  userId: string;
  month: number
  year: number;
}
