<div *ngIf="i18n.page">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">{{i18n.page.shiftRules}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{i18n.page.breaks}}</span></div>
  <div class="kenjo-mt-40px kenjo-font-size-18px kenjo-font-color-text-333333">{{i18n.page.breaksTitle}}</div>
  <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
    <div orgosColumn="1" class="kenjo-mr-20px">
      <div>
        <div class="kenjo-font-size-16px">
          {{i18n.page.breaksToggle}} <kenjo-icon [size]="16" class="kenjo-inline-block kenjo-ml-10px kenjo-font-color-light-text-757575 kenjo-ml-5px kenjo-cursor-pointer" [matTooltip]="i18n.page.breaksInformationTooltip" matTooltipPosition="right">help</kenjo-icon>
        </div>
      </div>
      <div class="kenjo-mt-20px kenjo-font-color-light-text-757575">{{i18n.page.breaksExplanation}}</div>
    </div>
    <orgos-column>
      <mat-slide-toggle
        [disabled]="!breaktimePermissions?.edit_all"
        [matTooltipDisabled]="breaktimePermissions?.edit_all === true"
        [matTooltip]="parentTranslation.noPermissionsTooltip"
        matTooltipPosition="below"
        [checked]="isBreaktimeDeducted"
        (change)="deductBreakDuration($event)"
      ></mat-slide-toggle>
    </orgos-column>
  </orgos-column-container>
</div>
