import { DatePipe } from '@angular/common';
import { AfterViewChecked, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar, MatLegacySnackBarRef } from '@angular/material/legacy-snack-bar';
import { IAttendanceConflict } from '@app/cloud-features/settings-attendance/services/attendance-conflicts.service';
import { IUserAccount } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { AttendancePreferencesService } from '@app/cloud-features/settings-attendance/services/attendance-preferences.service';
import { AttendanceSettingsService } from '@app/cloud-features/settings-attendance/services/attendance-settings.service';
import { ITimeOffUserPolicy, TimeOffUserPolicyController } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { IUserWorkModel } from '@app/models/user-work.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateInternationalizationService } from '@app/private/services/private-internationalization.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IQueryDates } from '@app/standard/components/input-month-picker/input-month-picker.component';
import { CreateListViewDialog } from '@app/standard/components/list-view/dialogs/create-list-view.dialog';
import { ListViewComponent } from '@app/standard/components/list-view/list-view.component';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { OvertimeAdjustBalanceDialog } from '@app/standard/pages/people/dialogs/overtime-adjust-balance-dialog/overtime-adjust-balance.dialog';
import { OvertimeBalanceHistoryDialog } from '@app/standard/pages/people/dialogs/overtime-balance-history-dialog/overtime-balance-history.dialog';
import { OvertimeCompensationPayDialog } from '@app/standard/pages/people/dialogs/overtime-compensation-pay-dialog/overtime-compensation-pay.dialog';
import { OvertimeCompensationTimeOffDialog } from '@app/standard/pages/people/dialogs/overtime-compensation-time-off-dialog/overtime-compensation-time-off.dialog';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { QueryParamsService } from '@app/standard/services/navigation/query-params.service';
import { IPreferenceModel, PreferenceService } from '@app/standard/services/preference/preference.service';
import { UserAttendanceSummaryController } from '@app/standard/services/user/controllers/user-attendance-summary.controller';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserAttendanceService } from '@app/standard/services/user/user-attendance.service';
import { VariablePayTypeService } from '@app/standard/services/variable-pay-type/variable-pay-type.service';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'orgos-attendance-summary',
  templateUrl: 'attendance-summary.page.html',
  styleUrls: ['attendance-summary.page.scss'],
})
export class AttendanceSummaryPage extends GenericPage implements AfterViewChecked, OnDestroy {
  preferenceDate: any;
  private snackBarRefreshRef: MatLegacySnackBarRef<any>;
  private destroySnackbar$ = new Subject<void>();

  startDayOfWeek: moment.Moment = moment.utc().startOf('week');
  endDayOfWeek: moment.Moment = moment.utc().endOf('week');
  startDayOfMonth: moment.Moment = moment.utc().startOf('month');
  endDayOfMonth: moment.Moment = moment.utc().endOf('month');
  today: moment.Moment = moment.utc().startOf('day');
  emptyWeeksTable: Array<number> = [];
  monthWeeks: Array<any> = [];
  dateTooltipMap: Array<any> = [];
  userToManage: Array<string> = [];
  editableUserIds: Array<string> = [];
  approvableUserIds: Array<string> = [];
  timeOffManageableUserIds: Array<string> = [];
  months: Array<string> = [];
  allStatus: Array<any> = [];
  allCompanies: Array<any> = [];
  allOffices: Array<any> = [];
  allAreas: Array<any> = [];
  allDepartments: Array<any> = [];
  allTeams: Array<any> = [];
  listTabs: Array<string> = [];

  DECREMENT_TIME: number = -1;
  CURRENT_TIME: number = 0;
  INCREMENT_TIME: number = 1;
  selectedTab: number = 0;
  moment: any = moment();

  // filters and view
  filtersOpened: boolean = false;
  PREFERENCE_VIEW_KEY: string = 'view-user-attendance-summary-list';
  PREFERENCE_OPTION_KEY: string = 'user-attendance-summary-list';
  selectedView: string;

  DEFAULT_RECORDS_PER_PAGE: number = 25;
  DEFAULT_PAGE: number = 1;
  PAGE_SELECTOR: any = {
    first: 1,
    previous: 2,
    next: 3,
    final: 4,
  };

  viewsLoaded: boolean = false;
  SEARCH_CHARACTERS_MIN_NUMBER: number = 3;

  monthSelected: boolean = true;
  queryOptions: any = {
    from: this.monthSelected === true ? this.startDayOfMonth : this.startDayOfWeek,
    to: this.monthSelected === true ? this.endDayOfMonth : this.endDayOfWeek,
    isWeekly: !this.monthSelected,
    recordsPerPage: this.DEFAULT_RECORDS_PER_PAGE,
    filterConflicts: false,
    page: this.DEFAULT_PAGE,
    'user-personal': {
      sortBy: 'sortingName',
      sortOrder: 'asc',
    },
  };
  totalOfRecords: number = 0;
  totalUsersWithConflicts: number = 0;
  numberOfPages: number = 1;
  sortBy: string = 'sortingName';
  sortOrder: string = 'asc';

  selectedUsers: Array<IAttendanceSummaryUser> = [];
  letConvertInBulk: boolean = false;
  usersSelectedText: string = '';
  trackedColumnTooltipText: string = '';
  canPayOvertime: boolean = false;

  attendanceUserList: Array<IAttendanceSummaryUser> = [];
  attendanceConflictsPerUser: { [key: string]: { [key: string]: IAttendanceConflict[] } };
  conflictTimeout: NodeJS.Timeout;
  usersDisplayedColumns: Array<string>;
  STATUS_BAR_COMPLETE_SIZE: number = 150;
  statusBarColorMap: any = {
    tracking: '#8f8f8f',
    completed: '#00b72e',
    overtime: '#ffc107',
  };

  recordsToShowSelector: Array<number> = [25, 50, 100];

  dataLoaded: boolean = false;
  pageInfo: any = {
    transferNextMonth: false,
    hasWaiver: false,
  };

  trackOvertimeEnabled: boolean = false;

  commonPolicies: Array<ITimeOffUserPolicy> = [];
  currentUser;

  @ViewChild(ListViewComponent) listView: ListViewComponent;
  ngAfterViewChecked(): void {
    if (check.assigned(this.listView)) {
      this.viewsLoaded = this.listView.isLoaded;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.conflictTimeout);
    this.destroySnackbar$.next();
    this.destroySnackbar$.complete();
    if (this.snackBarRefreshRef) {
      this.snackBarRefreshRef.dismiss();
    }
  }

  protected translationResources: Array<ITranslationResource> = [
    { name: 'menu', translationKey: 'attendance-menu' },
    { name: 'page', translationKey: 'people-attendance-page' },
    { name: 'misc', translationKey: 'misc' },
  ];

  protected beforeInit(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.injector
        .get(UserAccountService)
        .getUsersAttendanceManaged(false)
        .then((users) => {
          this.userToManage = users.map((iUser) => {
            return iUser._id;
          });
        })
        .catch(() => {
          this.userToManage = [];
          reject();
        });
      this.injector
        .get(UserAccountService)
        .getUsersWithEditableAttendance()
        .then((userAccounts) => {
          this.editableUserIds = userAccounts.map((iUserAccount) => iUserAccount._id);
        })
        .catch(() => {
          this.editableUserIds = [];
          reject();
        });
      this.injector
        .get(UserAccountService)
        .getUsersWithApprovableAttendance()
        .then((userAccounts) => {
          this.approvableUserIds = userAccounts.map((iUserAccount) => iUserAccount._id);
        })
        .catch(() => {
          this.approvableUserIds = [];
          reject();
        });
      this.injector
        .get(UserAccountService)
        .getUserAccountWithManageableTimeOffs(false)
        .then((userAccounts) => {
          this.timeOffManageableUserIds = userAccounts.map((iUserAccount) => iUserAccount._id);
        })
        .catch(() => {
          this.timeOffManageableUserIds = [];
          reject();
        });
      this.injector
        .get(PreferenceService)
        .getPreferenceByKey(this.PREFERENCE_OPTION_KEY)
        .then((preferenceResult: IPreferenceModel) => {
          this.setViewsDefaultValues(preferenceResult);
          // List selected
          this.checkStatusSelectedView();
          resolve();
        })
        .catch(() => {
          // Do nothing
          reject();
        })
        .finally(async () => {
          try {
            if (this.queryOptions.isWeekly) {
              await this.getAttendanceConflictsByUserIds();
            }
          } catch (error) {
            this.injector.get(ErrorManagerService).handleRawError(error, AttendanceSummaryPage.name, 'getAttendanceConflictsByUserIds');
          }
        });
    });
  }

  protected async configureGlobalBar(): Promise<void> {
    try {
      this.globalBarConfig.pageName = this.i18n.menu.pageName;

      const options: Array<IMenuOption> = [];

      if (this.injector.get(CloudRoutesService).checkRoute('attendance/my-attendance') === true) {
        options.push({
          name: this.i18n.menu.myAttendanceTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/my-attendance');
          },
        });
      }

      const attendanceSummaryPermission = await this.injector.get(PrivateSecurityService).getPermissionsForCollection('attendance-app');
      let attendanceSummaryTabStatus;

      if (check.not.assigned(attendanceSummaryPermission)) {
        return;
      }

      if (
        (check.assigned(attendanceSummaryPermission.c_viewAttendanceSummary_all) &&
          attendanceSummaryPermission.c_viewAttendanceSummary_all === true) ||
        (check.assigned(attendanceSummaryPermission.c_viewAttendanceSummary_custom) &&
          check.not.emptyArray(attendanceSummaryPermission.c_viewAttendanceSummary_custom))
      ) {
        attendanceSummaryTabStatus = await this.injector.get(AttendancePreferencesService).getAttendanceSummaryTabAccess();
      }

      if (check.assigned(attendanceSummaryTabStatus) && attendanceSummaryTabStatus === true) {
        options.push({
          name: this.i18n.menu.attendanceSummaryTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/attendance-summary');
          },
        });
      }

      const isPresenceSummaryTabEnabled = await this.injector.get(AttendanceSettingsService).getPresenceSummaryTabAccess();

      if (
        check.assigned(attendanceSummaryPermission.c_viewAttendanceCheckInTab) &&
        attendanceSummaryPermission.c_viewAttendanceCheckInTab === true &&
        this.injector.get(CloudRoutesService).checkRoute('attendance/check-ins') === true &&
        check.assigned(isPresenceSummaryTabEnabled) &&
        isPresenceSummaryTabEnabled
      ) {
        options.push({
          name: this.i18n.menu.checkInTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/check-ins');
          },
        });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('attendance/settings') === true) {
        options.push({
          name: this.i18n.menu.settingsTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/settings');
          },
        });
      }

      const optionIndex = _.findIndex(options, ['name', this.i18n.menu.attendanceSummaryTab]);
      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
      this.listTabs = [this.i18n.page.monthOption, this.i18n.page.weekOption];
    } catch (error) {
      throw error;
    }
  }

  detectChangesOnMonthPicker(event: IQueryDates) {
    this.startDayOfMonth = event.startDayOfMonth;
    this.endDayOfMonth = event.endDayOfMonth;
    this.refreshData();
  }

  private getTextForTrackedColumnTooltip(): void {
    if (check.not.assigned(this.i18n.page) || check.not.assigned(this.i18n.page.trackedMonthTooltip)) {
      return;
    }
    if (this.startDayOfMonth.isSame(this.today, 'month')) {
      this.trackedColumnTooltipText = `${this.i18n.page.trackedMonthTooltip.current} (${this.startDayOfMonth.format(
        'l'
      )} - ${this.today.format('l')})`;
      return;
    }

    const data = {
      month: moment().month(this.startDayOfMonth.month()).format('MMMM'),
    };
    this.trackedColumnTooltipText = this.injector.get(I18nDataPipe).transform(this.i18n.page.trackedMonthTooltip.previous, data);
  }

  private getMonths(): void {
    const currentLanguage = this.injector.get(PrivateInternationalizationService).getLanguage();
    const localizedMoment = this.moment.locale(currentLanguage);

    this.months = localizedMoment._locale._months;
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.currentUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.getMonths();
    this.getTextForTrackedColumnTooltip();
    this.dataLoaded = false;
    this.getDayTooltipMap();
    if (this.monthSelected !== true) {
      this.calculateNumberOfWeeks();
    }

    const firstWeekOfMonth = this.startDayOfMonth.isoWeeks();
    const firstWeekOfMonthTotalWeeks = this.startDayOfMonth.isoWeeksInYear();
    const lastWeekOfMonth = this.endDayOfMonth.isoWeeks();

    let months = lastWeekOfMonth - firstWeekOfMonth + 1;

    if (months < 0) {
      let firstWeekOfMonthParsed = firstWeekOfMonthTotalWeeks - firstWeekOfMonth;

      if (firstWeekOfMonthTotalWeeks === 52 && (firstWeekOfMonth === 53 || firstWeekOfMonth === 52)) {
        firstWeekOfMonthParsed = 1;
      }

      months = firstWeekOfMonthParsed + lastWeekOfMonth;
    }

    this.emptyWeeksTable = Array.from({ length: months }, (__) => {
      return 0;
    });

    this.queryOptions.from = this.monthSelected === true ? this.startDayOfMonth : this.startDayOfWeek;
    this.queryOptions.to = this.monthSelected === true ? (moment.utc().isSame(this.queryOptions.from, 'month') ? moment.utc().toISOString() : this.endDayOfMonth) : this.endDayOfWeek;

    this.queryOptions.isWeekly = !this.monthSelected;
    this.preferenceDate = moment(this.queryOptions.from).utc();
    this.selectedUsers = [];
    this.injector
      .get(VariablePayTypeService)
      .getAllTypes()
      .then((types) => {
        if (check.nonEmptyArray(types)) {
          this.canPayOvertime = true;
        }
      })
      .catch(() => {
        this.canPayOvertime = false;
      });
    this.injector
      .get(UserAttendanceSummaryController)
      .getList(this.queryOptions)
      .then((attendanceUsers) => {
        this.pageInfo = attendanceUsers.pageInfo;
        this.totalUsersWithConflicts = attendanceUsers.totalConflicts;
        this.attendanceUserList = this.mapUserConflicts(attendanceUsers.records as IAttendanceSummaryUser[]);

        this.trackOvertimeEnabled = this.attendanceUserList.some((user) => {
          return check.assigned(user.trackOvertime) && user.trackOvertime === true;
        });
        this.displayColumns();

        this.totalOfRecords = attendanceUsers.count !== 0 ? attendanceUsers.count : this.totalUsersWithConflicts;
        this.numberOfPages = Math.ceil(this.totalOfRecords / this.queryOptions.recordsPerPage);
        return this.injector.get(UserAttendanceSummaryController).getFiltersData();
      })
      .then((filters) => {
        if (check.assigned(filters)) {
          this.allCompanies = check.assigned(filters.companies) && check.nonEmptyArray(filters.companies) ? filters.companies : [];
          this.allOffices = check.assigned(filters.offices) && check.nonEmptyArray(filters.offices) ? filters.offices : [];
          this.allAreas = check.assigned(filters.areas) && check.nonEmptyArray(filters.areas) ? filters.areas : [];
          this.allDepartments = check.assigned(filters.departments) && check.nonEmptyArray(filters.departments) ? filters.departments : [];
          this.allTeams = check.assigned(filters.teams) && check.nonEmptyArray(filters.teams) ? filters.teams : [];
        }

        this.initListStaticFilters();

        this.dataLoaded = true;
        resolveFetchData();
      })
      .catch(() => {
        this.dataLoaded = true;
        rejectFetchData();
      })
      .finally(async () => {
        try {
          if (this.queryOptions.isWeekly) {
            await this.getAttendanceConflictsByUserIds();
          }
        } catch (error) {
          this.injector.get(ErrorManagerService).handleRawError(error, AttendanceSummaryPage.name, 'getAttendanceConflictsByUserIds');
        }
      });
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('click attendance summary', {
      category: 'Attendance',
      platform: 'Web',
      subcategory1: 'Attendance Summary',
      subcategory2: 'Monthly',
    });
    this.injector.get(PrivateAmplitudeService).logEvent('view people page', { category: 'Navigation', type: 'attendance summary' });
    return Promise.resolve();
  }

  /**
   * moveToDate(operation: number)
   * Increase, decrease or set in the current week/month to find time entries
   * @param operation is -1, 0 or 1
   */
  public moveToDate(operation: number): void {
    if (!this.dataLoaded) {
      return;
    }

    if (this.monthSelected === true) {
      if (operation === this.DECREMENT_TIME) {
        const currentMonth = this.startDayOfMonth.clone().add(this.DECREMENT_TIME, 'month');
        this.startDayOfMonth = currentMonth.clone().startOf('month');
        this.endDayOfMonth = currentMonth.clone().endOf('month');
      }
      if (operation === this.CURRENT_TIME) {
        this.startDayOfMonth = moment.utc().startOf('month');
        this.endDayOfMonth = moment.utc().endOf('month');
      }
      if (operation === this.INCREMENT_TIME) {
        const currentMonth = this.startDayOfMonth.clone().add(this.INCREMENT_TIME, 'month');
        this.startDayOfMonth = currentMonth.clone().startOf('month');
        this.endDayOfMonth = currentMonth.clone().endOf('month');
      }

      this.preferenceDate = this.startDayOfMonth;
      this.startDayOfWeek = this.preferenceDate.clone().startOf('week');
      this.endDayOfWeek = this.preferenceDate.clone().endOf('week');
    } else {
      if (operation === this.DECREMENT_TIME) {
        this.startDayOfWeek = this.startDayOfWeek.clone().add(this.DECREMENT_TIME, 'week');
        this.endDayOfWeek = this.endDayOfWeek.clone().add(this.DECREMENT_TIME, 'week');
      }
      if (operation === this.CURRENT_TIME) {
        this.startDayOfWeek = moment.utc().startOf('week');
        this.endDayOfWeek = moment.utc().endOf('week');
      }
      if (operation === this.INCREMENT_TIME) {
        this.startDayOfWeek = this.startDayOfWeek.clone().add(this.INCREMENT_TIME, 'week');
        this.endDayOfWeek = this.endDayOfWeek.clone().add(this.INCREMENT_TIME, 'week');
      }

      this.preferenceDate = this.startDayOfWeek.clone().startOf('month');
    }
    this.refreshData();
  }

  /**
   * toggleViewToMonth()
   * Changes view to month or week and set de default filter values
   */
  public toggleViewToMonth(event: any): void {
    this.selectedTab = event.index;
    this.monthSelected = this.selectedTab === 0 ? true : false;
    if (this.monthSelected) {
      this.injector.get(PrivateAmplitudeService).logEvent('click attendance summary', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Attendance Summary',
        subcategory2: 'Monthly',
      });
    } else {
      this.injector.get(PrivateAmplitudeService).logEvent('click attendance summary', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Attendance Summary',
        subcategory2: 'Weekly',
      });
    }
    this.displayColumns();
    this.refreshData();
  }

  /**
   * displayColumns()
   * Updates the columns to show depending on the selection (month, week) and if trackovertime is active or not
   */
  private displayColumns(): void {
    const columnsToDisplay = ['select', 'sortingName', 'additionalInfo', 'column3', 'column4', 'column5', 'column6'];
    if (this.monthSelected) {
      this.usersDisplayedColumns = this.trackOvertimeEnabled ? columnsToDisplay : columnsToDisplay.slice(0, 5);
    } else {
      this.usersDisplayedColumns = columnsToDisplay.filter((col) => {
        return col !== 'column4';
      });
      this.usersDisplayedColumns = this.trackOvertimeEnabled ? this.usersDisplayedColumns : this.usersDisplayedColumns.slice(0, 5);
    }
  }

  /**
   * getDayTooltipMap()
   * Updates the tooltip map to show the corresponding day with the on hover box
   */
  private getDayTooltipMap(): void {
    this.dateTooltipMap = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = this.startDayOfWeek.clone().add(i, 'days');
      this.dateTooltipMap[i] = {
        toolTip: moment.utc(currentDay.toISOString()).locale(this.currentUser.language).format('ddd, D MMM').replace(/\./g, ''),
        date: moment.utc(currentDay.toISOString()).startOf('day').toISOString(),
      };
    }
  }

  /**
   * calculateNumberOfWeeks()
   * Create an empty array to show correctly the weeks per month when there are no existing data about tracking for an user
   */
  private calculateNumberOfWeeks(): void {
    const firstWeekOfMonth = this.startDayOfMonth.isoWeeks();
    const lastWeekOfMonth =
      firstWeekOfMonth < this.endDayOfMonth.isoWeeks()
        ? this.endDayOfMonth.isoWeeks()
        : moment.utc(this.startDayOfMonth).isoWeeksInYear() + 1;
    const numberOfWeeks = lastWeekOfMonth - firstWeekOfMonth + 1;

    if (numberOfWeeks === this.emptyWeeksTable.length) {
      return;
    }
    this.emptyWeeksTable = [];
    for (let i = 0; i < numberOfWeeks; i++) {
      this.emptyWeeksTable.push(0);
    }
  }

  /**
   *  changeRecordsToShow(recordsPerPage: number)
   *  When the number of records per page changes it is saved by fetching to the data engine
   */
  public changeRecordsToShow(recordsPerPage: number): void {
    if (recordsPerPage === this.queryOptions.recordsPerPage) {
      return;
    }
    this.queryOptions.recordsPerPage = recordsPerPage;
    this.queryOptions.page = 1;
    this.refreshData();
  }

  /**
   *  moveToPage(option: number)
   *  When the current page changes it is saved by fetching to the data engine
   */
  public moveToPage(option: number): void {
    const currentPagePrev = this.queryOptions.page;
    if (option === this.PAGE_SELECTOR['first']) {
      this.queryOptions.page = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && this.queryOptions.page > 1) {
      this.queryOptions.page--;
    } else if (option === this.PAGE_SELECTOR['next'] && this.queryOptions.page < this.numberOfPages) {
      this.queryOptions.page++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      this.queryOptions.page = this.numberOfPages;
    }
    if (currentPagePrev === this.queryOptions.page) {
      return;
    }
    this.refreshData();
  }

  /**
   *  changePage(page: number)
   *  When the current page changes it is saved by fetching to the data engine
   */
  public changePage(): void {
    this.refreshData();
  }

  public sortColumn(value: any) {
    this.queryOptions['user-personal'].sortBy = value.sortBy;
    this.queryOptions['user-personal'].sortOrder = value.sortOrder;
    this.sortBy = value.sortBy;
    this.sortOrder = value.sortOrder;
    this.refreshData();
  }

  /**
   * initListStaticFilters()
   * Init static filters for LIST VIEW
   */
  private initListStaticFilters() {
    if (check.nonEmptyArray(this.allStatus)) {
      return;
    }

    this.allStatus.push(
      { name: this.i18n.page.activeStatus, value: pickLists.USER_STATUS_ACTIVE },
      { name: this.i18n.page.notActivatedStatus, value: pickLists.USER_STATUS_NOTACTIVATED },
      { name: this.i18n.page.deactivatedStatus, value: pickLists.USER_STATUS_DEACTIVATED }
    );
  }

  private setViewsDefaultValues(preferenceResult: any) {
    if (check.assigned(preferenceResult) && check.assigned(preferenceResult.preference)) {
      this.queryOptions = preferenceResult.preference;
      this.queryOptions.filterConflicts = false;
      this.preferenceDate = moment(this.queryOptions.from).utc();
      if (this.queryOptions.isWeekly) {
        this.startDayOfWeek = moment.utc(this.queryOptions.from);
        this.endDayOfWeek = moment.utc(this.queryOptions.to);
      } else {
        this.startDayOfMonth = moment.utc(this.queryOptions.from);
        this.endDayOfMonth = moment.utc(this.queryOptions.to);
      }

      return;
    }

    this.queryOptions.page = this.DEFAULT_PAGE;
    this.queryOptions.recordsPerPage = this.DEFAULT_RECORDS_PER_PAGE;
    this.queryOptions.sortBy = 'sortingName';
    this.queryOptions.sortOrder = 'asc';
  }

  /**
   *  checkStatusSelectedView()
   *  Updates the selected view if any view was selected in the previous navigation
   */
  private checkStatusSelectedView(): void {
    if (
      check.assigned(this.queryOptions.selectedView) &&
      check.not.emptyString(this.queryOptions.selectedView) &&
      this.queryOptions.selectedView !== 'All'
    ) {
      this.selectedView = this.queryOptions.selectedView;
    }
  }

  /**
   *  searchUser(searchTerm: string)
   *  When the value in the input search is a string with more than 3 characters it is saved by fetching to the data engine
   */
  public searchUser(searchTerm: string): void {
    if (searchTerm.length > 0 && searchTerm.length < this.SEARCH_CHARACTERS_MIN_NUMBER) {
      return;
    }

    this.queryOptions.page = 1;
    this.queryOptions.search = searchTerm;
    this.refreshData();
  }

  /**
   *  changeFilters(collection: string, field: string, value: string, active: boolean)
   *  When a filter changes it is saved by fetching to the data engine
   *  1. prePopulateQuery() helps to prepare the queryOptions object to saved the new filters, creating if needed
   *  2. Depending on the checkbox selection it calls to addFilter (checked) or removeFilter (unchecked) and put the condition in the corresponding collection
   */
  public changeFilters(value: string, active: boolean, field: string): void {
    this.queryOptions.page = 1;
    if (active === true) {
      this.addFilter(value, field);
    } else {
      this.removeFilter(value, field);
    }
    this.refreshData();
  }

  public changeStatusFilters(value: string, active: boolean, field: string): void {
    this.queryOptions.page = 1;
    if (active === true) {
      this.addStatusFilter(value);
    } else {
      this.removeStatusFilter(value);
    }
    this.refreshData();
  }

  /**
   *  addStatusFilter(optionSelected: string)
   *  If a filter is selected to add then built the condition according to the case
   */
  public addStatusFilter(optionSelected: string): void {
    if (
      check.not.assigned(this.queryOptions['user-account']) ||
      (check.assigned(this.queryOptions['user-account']) && check.not.assigned(this.queryOptions['user-account'].where))
    ) {
      this.queryOptions['user-account'] = {
        where: {},
      };
    }
    if (
      optionSelected === pickLists.USER_STATUS_ACTIVE ||
      optionSelected === pickLists.USER_STATUS_NOTACTIVATED ||
      optionSelected === pickLists.USER_STATUS_DEACTIVATED
    ) {
      if (optionSelected === pickLists.USER_STATUS_ACTIVE) {
        this.queryOptions['user-account'].where[optionSelected] = {
          'users-account.isActive': true,
          'users-account.inactiveReason': { $ne: pickLists.USER_STATUS_DEACTIVATED },
        };
      } else if (optionSelected === pickLists.USER_STATUS_NOTACTIVATED) {
        this.queryOptions['user-account'].where[optionSelected] = {
          'users-account.isActive': false,
          'users-account.inactiveReason': { $ne: pickLists.USER_STATUS_DEACTIVATED },
        };
      } else {
        this.queryOptions['user-account'].where[optionSelected] = {
          'users-account.isActive': false,
          'users-account.inactiveReason': pickLists.USER_STATUS_DEACTIVATED,
        };
      }
    }
  }

  /**
   *  removeStatusFilter(optionSelected: string)
   *  If a filter is selected to remove then built the condition according to the case
   */
  public removeStatusFilter(optionSelected: string): void {
    if (
      optionSelected === pickLists.USER_STATUS_ACTIVE ||
      optionSelected === pickLists.USER_STATUS_NOTACTIVATED ||
      optionSelected === pickLists.USER_STATUS_DEACTIVATED
    ) {
      if (optionSelected === pickLists.USER_STATUS_ACTIVE) {
        delete this.queryOptions['user-account'].where[optionSelected];
      } else if (optionSelected === pickLists.USER_STATUS_NOTACTIVATED) {
        delete this.queryOptions['user-account'].where[optionSelected];
      } else {
        delete this.queryOptions['user-account'].where[optionSelected];
      }
    }
  }

  /**
   *  addFilter(collection: string, field: string, value: string)
   *  If a filter is selected to add then built the condition according to the case
   */
  private addFilter(value: string, field: string): void {
    if (
      check.not.assigned(this.queryOptions['user-work']) ||
      (check.assigned(this.queryOptions['user-work']) && check.not.assigned(this.queryOptions['user-work'].where))
    ) {
      this.queryOptions['user-work'] = {
        where: {},
      };
    }
    if (check.not.assigned(this.queryOptions['user-work'].where[field])) {
      this.queryOptions['user-work'].where[field] = {
        $in: [],
      };
    }
    if (this.queryOptions['user-work'].where[field].$in.indexOf(value) === -1) {
      this.queryOptions['user-work'].where[field].$in.push(value);
    }
  }

  /**
   *  removeFilter(collection: string, field: string, value: string)
   *  If a filter is selected to remove then built the condition according to the case
   */
  private removeFilter(value: string, field: string): void {
    if (
      check.assigned(this.queryOptions['user-work'].where[field].$in) &&
      check.nonEmptyArray(this.queryOptions['user-work'].where[field].$in) &&
      this.queryOptions['user-work'].where[field].$in.length > 1
    ) {
      this.queryOptions['user-work'].where[field].$in = this.queryOptions['user-work'].where[field].$in.filter((fieldValue) => {
        return fieldValue !== value;
      });
    } else if (
      check.assigned(this.queryOptions['user-work'].where[field].$in) &&
      check.nonEmptyArray(this.queryOptions['user-work'].where[field].$in) &&
      this.queryOptions['user-work'].where[field].$in.length === 1
    ) {
      delete this.queryOptions['user-work'].where[field];
    }

    if (
      check.not.assigned(this.queryOptions['user-work'].where.companyId) &&
      check.not.assigned(this.queryOptions['user-work'].where.officeId) &&
      check.not.assigned(this.queryOptions['user-work'].where.departmentId) &&
      check.not.assigned(this.queryOptions['user-work'].where.teamIds)
    ) {
      delete this.queryOptions['user-work'];
    }
  }

  /**
   * canManageInBulk()
   * check if I can manage the selected users
   */
  public canManageInBulk(): boolean {
    if (check.not.assigned(this.selectedUsers)) {
      return false;
    }
    let canManage = true;
    this.selectedUsers.forEach((iUser) => {
      canManage = canManage && this.userToManage.includes(iUser._id);
    });

    return canManage;
  }

  /**
   * canApproveInBulk()
   * check if I can edit the selected users
   */
  public canApproveInBulk(): boolean {
    if (check.not.assigned(this.selectedUsers)) {
      return false;
    }
    const canApprove = this.selectedUsers.every((iUser) => this.approvableUserIds.includes(iUser._id));

    return canApprove;
  }

  /**
   *  updateAfterViewChange(viewInfo)
   *  Send new preferences to engine if a view is updated or removed with the current configuration and update list.
   *  If 'ALL' view is selected queryOptions are restarted
   */
  public updateAfterViewChange(viewInfo): void {
    if (check.not.assigned(viewInfo) || check.emptyObject(viewInfo)) {
      return;
    }
    this.queryOptions = viewInfo.filters;
    this.selectedView = viewInfo.viewName;
    if (check.not.assigned(viewInfo.filters) || check.emptyObject(viewInfo.filters) || this.selectedView === 'All') {
      this.queryOptions = {
        page: 1,
        recordsPerPage: 25,
      };
    }
    this.queryOptions.selectedView = this.selectedView;
    if (this.queryOptions.selectedView === 'All') {
      delete this.queryOptions.selectedView;
    }
    this.refreshData();
  }

  /**
   *  createView()
   *  When a new view is created the current filter and sort configuration is saved by fetching to the data engine
   */
  public createView(): void {
    const viewInfo = {
      viewType: this.PREFERENCE_VIEW_KEY,
      filters: this.queryOptions,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateListViewDialog, { data: { viewInfo } });
    dialogRef.afterClosed().subscribe((viewName) => {
      if (check.assigned(viewName)) {
        this.selectedView = viewName;
        this.queryOptions.selectedView = this.selectedView;
        this.refreshData();
        const message = this.i18n.page.viewCreatedMessage;
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public async selectListItem(selectedUsers: Array<any>): Promise<void> {
    await this.checkSelectedList(selectedUsers);

    const data = {
      numberSelected: this.selectedUsers.length,
    };
    if (selectedUsers?.length > 1) {
      this.usersSelectedText = this.injector.get(I18nDataPipe).transform(this.i18n.page.usersSelected, data);
    } else if (selectedUsers?.length === 1) {
      this.usersSelectedText = this.injector.get(I18nDataPipe).transform(this.i18n.page.userSelected, data);
    }
  }

  private async checkSelectedList(selectedUsers: Array<IAttendanceSummaryUser>): Promise<boolean> {
    if (selectedUsers.length === 0) {
      this.selectedUsers = [];
      this.letConvertInBulk = false;
      return;
    }
    let letConvertInBulk = true;
    const canManageTimeOff = this.selectedUsers.every((iUser) => this.timeOffManageableUserIds.includes(iUser._id));
    letConvertInBulk = canManageTimeOff;

    this.selectedUsers = selectedUsers.map((user) => {
      letConvertInBulk = letConvertInBulk && user.currentOvertimeBalance > 0;
      return user;
    });
    if (!letConvertInBulk) {
      this.letConvertInBulk = false;
      return;
    }
    try {
      this.commonPolicies = await this.injector.get(TimeOffUserPolicyController).getCommonPolicies(
        this.selectedUsers.map((u) => u._id),
        false
      );
      if (this.commonPolicies?.length > 0) {
        this.letConvertInBulk = true;
      }
    } catch {
      this.commonPolicies = [];
    }
  }

  public compensateTimeOff(): void {
    const users = this.selectedUsers.reduce((total, iUser) => {
      total[iUser._id] = iUser.currentOvertimeBalance;
      return total;
    }, {});
    const data = {
      userIds: users,
      month: moment(this.startDayOfMonth).month() + 1,
      year: moment(this.startDayOfMonth).year(),
      commonPolicies: this.commonPolicies,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(OvertimeCompensationTimeOffDialog, { data: data });

    dialogRef.afterClosed().subscribe((compensated) => {
      if (compensated) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.misc.overtimeConfirmationActions.timeOff, 'OK', {
          duration: 5000,
        });

        if (check.assigned(users) && Object.keys(users).length === 1) {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('manage attendance/overtime', { type: 'convert to time off', source: 'company summary' });
        } else if (check.assigned(users) && Object.keys(users).length > 1) {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('manage attendance/overtime', { type: 'convert to time off in bulk', source: 'company summary' });
        }

        this.refreshData();
      }
    });
  }

  public compensatePayment(): void {
    const users = this.selectedUsers.reduce((total, iUser) => {
      total[iUser._id] = iUser.currentOvertimeBalance;
      return total;
    }, {});
    const data = {
      userIds: users,
      month: moment(this.startDayOfMonth).month() + 1,
      year: moment(this.startDayOfMonth).year(),
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(OvertimeCompensationPayDialog, { data: data });

    dialogRef.afterClosed().subscribe((compensated) => {
      if (compensated) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.misc.overtimeConfirmationActions.pay, 'OK', {
          duration: 5000,
        });
        this.injector.get(PrivateAmplitudeService).logEvent('manage attendance/overtime', { type: 'pay out', source: 'company summary' });
        this.refreshData();
      }
    });
  }

  public adjustBalance(): void {
    const users = this.selectedUsers.reduce((total, iUser) => {
      total[iUser._id] = iUser.currentOvertimeBalance;
      return total;
    }, {});
    const data = {
      userIds: users,
      month: moment(this.startDayOfMonth).month() + 1,
      year: moment(this.startDayOfMonth).year(),
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(OvertimeAdjustBalanceDialog, { data: data });

    dialogRef.afterClosed().subscribe((compensated) => {
      if (compensated) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.misc.overtimeConfirmationActions.balanceAdjusted, 'OK', {
          duration: 5000,
        });
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('adjust balance', { category: 'Attendance', platform: 'Web', subcategory1: 'Attendance Summary' });

        if (check.assigned(users) && Object.keys(users).length === 1) {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('manage attendance/overtime', { type: 'adjust balance', source: 'company summary' });
        } else if (check.assigned(users) && Object.keys(users).length > 1) {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('manage attendance/overtime', { type: 'adjust balance in bulk', source: 'company summary' });
        }

        this.refreshData();
      }
    });
  }

  public openApproveAllEntries() {
    const selectedEmployeesData = {
      year: this.startDayOfMonth.format('YYYY'),
      month: this.months[this.startDayOfMonth.month()],
      selectedEmployees: this.selectedUsers.length,
    };
    const conflicts = _.flatMap(this.selectedUsers, (iUser) => iUser?.attendanceConflicts || []);
    const data = this.setApproveConflictsDialogData(conflicts, selectedEmployeesData);
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response) {
        const year = this.startDayOfMonth.clone().year();
        const month = this.startDayOfMonth.clone().month();
        const userIds = this.selectedUsers.filter((iUser) => !iUser.attendanceConflicts?.length).map((iUser) => iUser._id);
        this.injector
          .get(UserAttendanceService)
          .approveAllEntries(userIds, year, month, pickLists.ATTENDANCE_INTERFACE_ATTENDANCE_SUMMARY)
          .then((allAttendanceEntriesWereComplete?: boolean) => {
            this.injector
              .get(PrivateAmplitudeService)
              .logEvent('approve all entries', { category: 'Attendance', platform: 'Web', subcategory1: 'Attendance Summary' });
            this.injector
              .get(PrivateAmplitudeService)
              .logEvent('manage attendance/overtime', { type: 'approve all', source: 'company summary' });
            this.injector
              .get(MatLegacySnackBar)
              .open(
                check.not.assigned(allAttendanceEntriesWereComplete)
                  ? this.i18n.page.approvedEntriesMessage
                  : this.i18n.page.approveEntriesWithSomeIncompleteMessage,
                'OK',
                { duration: 5000 }
              );
            this.refreshData();
          })
          .catch(() => {
            this.selectedUsers = [];
          });

        this.selectedUsers = [];
      }
    });
  }

  openApproveWeekly(): void {
    if (check.not.assigned(this.startDayOfWeek) || check.not.assigned(this.endDayOfWeek)) {
      return;
    }

    const selectedEmployeesData = {
      year: this.startDayOfMonth.format('YYYY'),
      selectedEmployees: this.selectedUsers.length,
      startDayOfWeek: this.injector.get(DatePipe).transform(this.startDayOfWeek.clone().toDate(), 'shortDate', 'UTC'),
      endDayOfWeek: this.injector.get(DatePipe).transform(this.endDayOfWeek.clone().toDate(), 'shortDate', 'UTC'),
    };

    const conflicts = _.flatMap(this.selectedUsers, (iUser) => iUser.attendanceConflicts || []);
    const data = this.setApproveConflictsDialogData(conflicts, selectedEmployeesData);

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response) {
        const year = this.startDayOfMonth.clone().year();
        const week = {
          startDate: this.startDayOfWeek.toISOString(),
          endDate: this.endDayOfWeek.toISOString(),
        };
        const userIds = this.selectedUsers.filter((iUser) => !iUser.attendanceConflicts?.length).map((iUser) => iUser._id);
        this.injector
          .get(UserAttendanceService)
          .approveAllEntries(userIds, year, week, pickLists.ATTENDANCE_INTERFACE_ATTENDANCE_SUMMARY)
          .then((allAttendanceEntriesWereComplete?: boolean) => {
            this.injector
              .get(PrivateAmplitudeService)
              .logEvent('approve all entries', { category: 'Attendance', platform: 'Web', subcategory1: 'Attendance Summary' });
            this.injector
              .get(PrivateAmplitudeService)
              .logEvent('manage attendance/overtime', { type: 'approve all', source: 'company summary' });
            this.injector
              .get(MatLegacySnackBar)
              .open(
                check.not.assigned(allAttendanceEntriesWereComplete)
                  ? this.i18n.page.approvedEntriesMessage
                  : this.i18n.page.approveEntriesWithSomeIncompleteMessage,
                'OK',
                { duration: 5000 }
              );
            this.refreshData();
          })
          .catch(() => {
            this.selectedUsers = [];
          });

        this.selectedUsers = [];
      }
    });
  }
  async getAttendanceConflictsByUserIds() {
    this.attendanceConflictsPerUser = this.attendanceUserList.reduce((map, iUser) => {
      if (iUser?.attendanceConflicts?.length) {
        map[iUser._id.toString()] = iUser?.attendanceConflicts?.reduce((mapConflicts, iConflict: IAttendanceConflict) => {
          const dateKey = iConflict._date.toString();
          if (!mapConflicts[dateKey]) {
            mapConflicts[dateKey] = [];
          }
          mapConflicts[dateKey].push(iConflict);
          return mapConflicts;
        }, {});
      }
      return map;
    }, {});
  }

  mapUserConflicts(userAttendanceSummary: IAttendanceSummaryUser[]) {
    return _.cloneDeep(userAttendanceSummary).map((iUser) => {
      const attendanceConflicts = iUser?.attendanceConflicts;
      const filteredConflicts = attendanceConflicts?.filter((iAttendanceConflict: IAttendanceConflict) => !iAttendanceConflict?.isResolved);
      return {
        ...iUser,
        attendanceConflicts: filteredConflicts,
      };
    });
  }

  filterUsersWithConflicts() {
    if (!this.loadingPage) {
      this.queryOptions.filterConflicts = !this.queryOptions.filterConflicts;
      this.queryOptions.page = this.DEFAULT_PAGE;
      this.refreshData();
    }
  }

  public navigateToAttendanceTab($event, userId: string) {
    $event.preventDefault();
    const queryParams = {
      conflicts: true,
      month: this.monthSelected ? this.preferenceDate?.format('MM-01-YYYY') : this.startDayOfWeek?.format('MM-01-YYYY'),
    };

    this.router.navigate(['/cloud/people', userId, 'attendance'], { queryParams });
  }

  public navigateToNewAttendanceTab(): void {
    this.conflictTimeout = setTimeout(() => {
      this.snackBarRefreshRef = this.injector.get(MatLegacySnackBar).open(this.i18n.page.refreshConflicts, this.i18n.page.refresh);
      this.snackBarRefreshRef
        .onAction()
        .pipe(takeUntil(this.destroySnackbar$))
        .subscribe(() => {
          this.refreshData(true);
        });
    }, 2000);
  }

  public openHistory(): void {
    const data = {
      userId: this.selectedUsers[0]._id,
      month: moment(this.startDayOfMonth).month() + 1,
      year: moment(this.startDayOfMonth).year(),
      fullDetail: true,
    };
    this.injector.get(MatLegacyDialog).open(OvertimeBalanceHistoryDialog, { data: data });
    this.injector.get(PrivateAmplitudeService).logEvent('manage attendance/overtime', { type: 'show details', source: 'company summary' });
  }

  public setComeFromSummary() {
    this.injector.get(QueryParamsService).setComesFrom('attendance-summary');
  }

  setApproveConflictsDialogData(conflicts: IAttendanceConflict[], selectedEmployeesData) {
    if (conflicts?.length) {
      return {
        titleText: this.i18n.page.ignoreInBulkConflictsDialog.title,
        subtitleText: this.i18n.page.ignoreInBulkConflictsDialog.subtitle,
        cancelButtonText: this.i18n.page.ignoreInBulkConflictsDialog.cancel,
        confirmButtonText: this.i18n.page.ignoreInBulkConflictsDialog.ignoreButton,
        confirmButtonColor: 'Success',
      };
    } else {
      return {
        titleText: this.i18n.page.approveEntriesDialogHeader,
        subtitleText: this.injector.get(I18nDataPipe).transform(this.i18n.page.approveEntriesDialogInformation, selectedEmployeesData),
        cancelButtonText: this.i18n.page.cancelButtonDialog,
        confirmButtonText: this.i18n.page.approveAllButtonLabel,
        confirmButtonColor: 'Success',
      };
    }
  }
}

export interface IAttendanceSummaryUser {
  _id: string;
  _photo: IFileMetadata;
  displayName: string;
  'users-account': IUserAccount[];
  attendanceConflicts: IAttendanceConflict[];
  hasConflicts: boolean;
  userWork: IUserWorkModel[];
  startDate?: Date;
  sortingName: string;
  totalExpectedHours: number;
  totalTrackedHours: number;
  overtimeHours: number;
  trackedHours: number | null;
  overtimeCompensated: number;
  currentOvertimeBalance: number;
  trackOvertime: boolean;
  pendingToProcess: boolean;
  difference: number;
  status: any;
  pendingToApprove: boolean;
  approvalCounts: {
    approved: number;
    pending: number;
  };
}
