<orgos-widget titleKey="platformUsage" [loadingWidget]="loadingWidget" [existingData]="true" [footerHeight]="40">
  <orgos-widget-header-chip>
    <orgos-chip color="New">{{ translations.newChip }}</orgos-chip>
  </orgos-widget-header-chip>
  <orgos-widget-header-action>
    <div class="kenjo-flex-center-content kenjo-flex-gap-10px">
      <orgos-action (click)="refreshIconClicked()" icon="sync" iconSize="18px" [matTooltip]="translations.refreshTooltip" [matTooltipPosition]="'above'"></orgos-action>
      <orgos-action (click)="learnMoreIconClicked()" icon="import_contacts" iconSize="18px" [matTooltip]="translations.learnMoreTooltip" [matTooltipPosition]="'above'"></orgos-action>
    </div>
  </orgos-widget-header-action>
  <orgos-widget-content>
    <div class="kenjo-flex-vertical kenjo-flex-gap-15px">
      <div class="kenjo-font-color-light-text-757575 kenjo-mh-10px">{{ translations.description }}</div>
      <div *ngIf="cumulAuth.id && isDatasetEmpty === false; else emptyDatasetWidget">
        <cumulio-dashboard #dashboardInstance [dashboardId]="widgetDashboard.cumulId" [authKey]="cumulAuth.id" [authToken]="cumulAuth.token" [language]="loggedUser.language" [screenMode]="widgetDashboard.screenMode" [language]="widgetDashboard.language"></cumulio-dashboard>
      </div>
    </div>
  </orgos-widget-content>
  <orgos-widget-footer>
    <div class="kenjo-flex-center-content" [matTooltip]="hasAccessToBilling === false ? translations.noUsagePermissionsTooltip : ''" matTooltipPosition="above">
      <orgos-button-raised [disabled]="hasAccessToBilling === false" (click)="goToPlatformUsagePage()">{{ translations.goToDashboard }}</orgos-button-raised>
    </div>
  </orgos-widget-footer>
</orgos-widget>

<ng-template #emptyDatasetWidget>
  <div class="kenjo-p-10px kenjo-flex-vertical kenjo-flex-center-vertically kenjo-flex-gap-20px">
    <div class="kwpu-image">
      <img src="/assets/images/platform-usage-widget-empty.png" />
    </div>
    <div class="kenjo-font-size-12px kenjo-text-align-center">{{ translations.stillCollecting }}</div>
  </div>
</ng-template>
