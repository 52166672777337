import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class DepartmentService implements IGenericService {
  private DEPARTMENT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/department-db`;
  private DEPARTMENT_PERMISSIONS_KEY: string = 'department';
  private DEPARTMENT_INTERNATIONALIZATION: string = 'department-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IDepartmentModel> {
    return new Promise<IDepartmentModel>((resolve, reject) => {
      this.genericService
        .create(this.DEPARTMENT_URL, data)
        .then((department: IDepartmentModel) => {
          resolve(department);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DepartmentService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IDepartmentModel> {
    return new Promise<IDepartmentModel>((resolve, reject) => {
      this.genericService
        .getById(this.DEPARTMENT_URL, id)
        .then((department: IDepartmentModel) => {
          resolve(department);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DepartmentService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.DEPARTMENT_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DepartmentService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.DEPARTMENT_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DepartmentService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.DEPARTMENT_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.DEPARTMENT_INTERNATIONALIZATION);
  }

  getData(
    findBody = {
      _id: { $ne: null }
    }
  ) {
    return new Promise<Array<IDepartmentModel>>((resolve, reject) => {
      this.genericService
        .find(this.DEPARTMENT_URL, findBody)
        .then((departments: Array<IDepartmentModel>) => {
          resolve(departments);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DepartmentService.name, 'getDepartments'));
        });
    });
  }

  getDepartments(): Promise<Array<IDepartmentModel>> {
    return this.getData();
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.DEPARTMENT_URL);
  }
}

export interface IDepartmentModel {
  _id: string;
  name: string;
}
