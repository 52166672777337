<ng-container *ngIf="workflow && pageTranslation && standardPicklists">
  <orgos-column-container centerColumns="true" class="ws-top-bar kenjo-border-bottom kenjo-ph-20px">
    <orgos-column size="0">
      <kenjo-icon size="20" (click)="onBackClick()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
    </orgos-column>

    <orgos-column size="0" class="ws-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-column size="0" class="kenjo-font-size-16px">{{workflow.data['name']}}</orgos-column>

    <orgos-column size="0" class="ws-separator kenjo-mh-20px kenjo-border-left"></orgos-column>
  </orgos-column-container>

  <div class="ws-main-container">
    <div class="kenjo-p-20px ws-content">
      <orgos-container aspect="card" class="kenjo-p-30px">
        <div class="kenjo-mb-20px kenjo-font-size-18px">{{pageTranslation.pageHeading}}</div>
        <div class="kenjo-p-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
          <orgos-column-container>
            <orgos-column size="1">{{objectTranslation.name}}</orgos-column>
            <orgos-column size="5" class="kenjo-font-color-light-text-757575">{{workflow.data['name']}}</orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-15px">
            <orgos-column size="1">{{objectTranslation.isActive}}</orgos-column>
            <orgos-column size="5" class="kenjo-font-color-light-text-757575">
              {{workflow.data['isActive'] === true ? pageTranslation.activeLabel : pageTranslation.inactiveLabel}}
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-15px">
            <orgos-column size="1">{{objectTranslation.inCollection}} </orgos-column>
            <orgos-column size="5" class="kenjo-font-color-light-text-757575">{{getLabel(workflow.data['inCollection'])}} </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-15px" *ngIf="workflow.data.delayInstant !== 'recurrent'">
            <orgos-column size="1">{{pageTranslation.timeRangeLabel}} </orgos-column>
            <orgos-column size="5" class="kenjo-font-color-light-text-757575" *ngIf="workflow.data['immediate'] === true">
              {{workflow.data['type'] === 'create' ? pageTranslation.immediatelyAfterCreated : pageTranslation.immediatelyAfterUpdated}}
            </orgos-column>
            <orgos-column
              size="5"
              class="kenjo-font-color-light-text-757575"
              *ngIf="workflow.data['immediate'] === false && workflow.data['type'] !== 'scheduled'"
            >
              {{workflow.data['delayNumber']}} {{workflow.data['type'] === 'create' ? pageTranslation.xDaysAfterCreated :
              pageTranslation.xDaysAfterUpdated}}
            </orgos-column>
            <orgos-column
              size="5"
              class="kenjo-font-color-light-text-757575"
              *ngIf="workflow.data['immediate'] === false && workflow.data['type'] === 'scheduled' && workflow.data['delayNumber'] > 0 && targetObjectTranslation && targetObjectTranslation[workflow.data['referenceDateField']]"
            >
              {{workflow.data['delayNumber']}} {{workflow.data['delayInstant'] === 'after' ? pageTranslation.xDaysAfterSchedule :
              pageTranslation.xDaysBeforeSchedule}} {{targetObjectTranslation[workflow.data['referenceDateField']] }}
            </orgos-column>
            <orgos-column
              size="5"
              class="kenjo-font-color-light-text-757575"
              *ngIf="workflow.data['immediate'] === false && workflow.data['type'] === 'scheduled' && workflow.data['delayNumber'] > 0 && targetObjectTranslation && !targetObjectTranslation[workflow.data['referenceDateField']]"
            >
              {{workflow.data['delayNumber']}} {{workflow.data['delayInstant'] === 'after' ? pageTranslation.xDaysAfterSchedule :
              pageTranslation.xDaysBeforeSchedule}} {{pageTranslation[workflow.data['referenceDateField']] }}
            </orgos-column>
            <orgos-column
              size="5"
              class="kenjo-font-color-light-text-757575"
              *ngIf="targetObjectTranslation && workflow.data['immediate'] === false && workflow.data['type'] === 'scheduled' && workflow.data['delayNumber'] === 0 && targetObjectTranslation && targetObjectTranslation[workflow.data['referenceDateField']]"
            >
              {{pageTranslation.sameDayAs}} {{targetObjectTranslation[workflow.data['referenceDateField']] }}
            </orgos-column>
          </orgos-column-container>
          <!-- RECURRING WORKFLOW TIME RANGE STARTS -->
          <orgos-column-container class="kenjo-mt-15px" *ngIf="workflow.data.delayInstant === 'recurrent'">
            <orgos-column size="1">{{pageTranslation.timeRangeLabel}} </orgos-column>
            <orgos-column size="5" class="kenjo-font-color-light-text-757575">
              {{recurrentWorkflowTimeRange}}
              <div *ngIf="nextRecurringWorkflowDateTranslated" [class.kenjo-pt-5px]="recurrentWorkflowTimeRange">
                {{nextRecurringWorkflowDateTranslated}}
              </div>
            </orgos-column>
          </orgos-column-container>
          <!-- RECURRING WORKFLOW TIME RANGE ENDS -->
          <orgos-column-container *ngIf="filters && filters.length > 0" class="kenjo-pt-15px">
            <orgos-column size="1">{{pageTranslation.filterTextLabel}} </orgos-column>
            <orgos-column size="5"></orgos-column>
          </orgos-column-container>
          <div
            class="kenjo-border kenjo-color-white-background-ffffff kenjo-p-20px kenjo-mt-10px kenjo-border-radius kenjo-font-size-14px"
            *ngIf="chosenCollection && filters && filters.length > 0 && collectionFields && targetObjectTranslation"
          >
            <div *ngFor="let iFilter of filters; let lastFilter = last" class="ws-filter-container">
              <div class="kenjo-border ws-and-block-container" [class.kenjo-mb-10px]="lastFilter === false">
                <div class="kenjo-p-10px">
                  <ng-container *ngFor="let iCondition of iFilter.conditions; let lastCondition = last">
                    <div class="ws-condition-line">
                      <div>{{targetObjectTranslation[iCondition.field]}}</div>
                      <div class="kenjo-font-color-light-text-757575">{{pageTranslation[iCondition.condition]}}</div>
                      <div *ngIf="['isChanged', 'isEmpty', 'isNotEmpty'].includes(iCondition.condition) === false">
                        {{fieldNameOptionsMap?.[iCondition?.field]?.[iCondition?.value] ?
                        fieldNameOptionsMap?.[iCondition?.field][iCondition?.value] : iCondition.value}}
                      </div>
                    </div>
                    <div class="kenjo-mh-10px kenjo-text-transform-uppercase" *ngIf="lastCondition === false">
                      {{pageTranslation.andOperator}}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="lastFilter === false" class="kenjo-mb-10px kenjo-text-transform-uppercase">{{pageTranslation.orOperator}}</div>
            </div>
          </div>
        </div>

        <orgos-action icon="add_circle" (click)="addAction()" iconSize="large" class="kenjo-mt-40px kenjo-mb-20px">
          {{pageTranslation.addActionTitle}}
        </orgos-action>

        <orgos-container *ngIf="listActions && listActions.length > 0" aspect="card">
          <orgos-table [fullHeight]="true" [data]="listActions" [displayedColumns]="displayedColumns">
            <orgos-table-column columnKey="name" [header]="wfActionTranslation.name" [sortable]="true">
              <div class="ws-action-name-column kenjo-pv-10px" *orgosTableCell="let row">{{row['data'].name}}</div>
            </orgos-table-column>

            <orgos-table-column columnKey="type" [header]="wfActionTranslation.type" [sortable]="true">
              <ng-container *orgosTableCell="let row">{{standardPicklists.workflowActionType[row['data'].type]}} </ng-container>
            </orgos-table-column>

            <orgos-table-column columnKey="actions" [actionColumn]="true">
              <ng-container *orgosTableCell="let row">
                <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert</kenjo-icon>
                <mat-menu #actionsMenu="matMenu">
                  <button mat-menu-item (click)="editAction(row)">{{pageTranslation.editActionButtonLabel}}</button>
                  <button mat-menu-item (click)="deleteAction(row)">{{pageTranslation.deleteActionButtonLabel}}</button>
                </mat-menu>
              </ng-container>
            </orgos-table-column>
          </orgos-table>
        </orgos-container>

        <div class="kenjo-mt-60px kenjo-text-align-right">
          <orgos-button-raised (click)="onBackClick()"> {{pageTranslation.saveButtonLabel}}</orgos-button-raised>
        </div>
      </orgos-container>
    </div>
  </div>
</ng-container>
