import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';

@Injectable()
export class PeopleBarService {
  constructor(private router: Router, private injector: Injector) {}

  async getOptions(pageTranslation: any): Promise<Array<any>> {
    try {
      const options: Array<any> = [{ name: pageTranslation.directoryTab, onClick: () => this.router.navigateByUrl('/cloud/people/directory') }];

      if (this.injector.get(CloudRoutesService).checkRoute('people/org-chart')) {
        options.push({ name: pageTranslation.orgChartTab, onClick: () => this.router.navigateByUrl('/cloud/people/org-chart') });
      }

      return options;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PeopleBarService.name, 'getOptions');
    }
  }
}
