<orgos-container class="pdpr-main-container" *ngIf="!loadingPage && userPersonal && userWork && !seeDetailsUserId" aspect="card">
  <orgos-column-container centerColumns="true" class="pdpr-user-info-container kenjo-pl-20px kenjo-pr-20px kenjo-pt-10px kenjo-pb-10px">
    <orgos-column></orgos-column>
    <orgos-user-header
      orgosColumn="1"
      class="kenjo-pl-40px kenjo-pr-40px kenjo-pt-10px kenjo-pb-10px"
      size="medium"
      [userPersonal]="userPersonal"
      [userWork]="userWork"
    ></orgos-user-header>
    <orgos-column></orgos-column>
  </orgos-column-container>

  <mat-tab-group
    *ngIf="showToDosTab"
    [selectedIndex]="tabIndex"
    (selectedIndexChange)="tabIndex = $event;"
    class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-bottom"
  >
    <mat-tab *ngIf="showToDosTab" [label]="i18n.page.toDosTab"></mat-tab>
    <mat-tab [label]="i18n.page.myActivityTab"></mat-tab>
  </mat-tab-group>
  <mat-tab-group
    *ngIf="!showToDosTab"
    [selectedIndex]="tabIndex"
    (selectedIndexChange)="tabIndex = $event;"
    class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-bottom"
  >
    <mat-tab [label]="i18n.page.myActivityTab"></mat-tab>
  </mat-tab-group>

  <!-- If it is still loading -->
  <orgos-container *ngIf="loadingPage" class="pdpr-list-spinner-container">
    <div class="pdpr-list-loading-spinner kenjo-p-20px">
      <div>
        <orgos-loading-spinner></orgos-loading-spinner>
        <orgos-text type="SecondaryText" color="LightText" class="kenjo-mt-20px"> {{i18n.page.loadingDataMessage}} </orgos-text>
      </div>
    </div>
  </orgos-container>

  <div *ngIf="tabIndex === 1 || !showToDosTab">
    <div class="kenjo-p-40px" *ngIf="!loadingPage">
      <orgos-column-container centerColumns="true">
        <orgos-text orgosColumn="0" type="BodyText" class="kenjo-mr-10px">{{i18n.page.filterByLabel}}</orgos-text>
        <orgos-column size="0" class="kenjo-mr-40px">
          <mat-form-field>
            <mat-select [(ngModel)]="selectedFilterBy" (selectionChange)="changeFilterBy()">
              <mat-option *ngFor="let filter of filterBy" [value]="filter.value"> {{filter.name}} </mat-option>
            </mat-select>
          </mat-form-field>
        </orgos-column>
        <orgos-text orgosColumn="0" type="BodyText" class="kenjo-mr-10px">{{i18n.page.filterFromLabel}}</orgos-text>
        <orgos-column size="0" class="pp-filter">
          <mat-form-field>
            <mat-select [(ngModel)]="selectedFilterFrom" (selectionChange)="changeFilterFrom()">
              <mat-option *ngFor="let filter of filterFrom" [value]="filter.value"> {{filter.name}} </mat-option>
            </mat-select>
          </mat-form-field>
        </orgos-column>
      </orgos-column-container>

      <!-- If no data found -->
      <orgos-column-container
        *ngIf="(selectedFilterBy === 'allActivity' && !reviewsToShow ) || (selectedFilterBy === 'userIsReviewer' && !hasReviewInfo('userIsReviewer')) || (selectedFilterBy === 'userIsReviewee' && !hasReviewInfo('userIsReviewee'))"
        class="kenjo-mt-80px"
      >
        <orgos-column size="1"></orgos-column>
        <orgos-column size="0" class="pdpr-no-data-img">
          <img src="/assets/images/emptyPerformanceData.svg" />
          <orgos-text type="Headline4" class="kenjo-mt-20px">{{ i18n.page.noReviewsYet }}</orgos-text>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>

      <div class="pdpr-list-container">
        <div
          *ngIf="((selectedFilterBy === 'allActivity' && reviewsToShow) || selectedFilterBy === 'userIsReviewer') && hasReviewInfo('userIsReviewer')"
        >
          <orgos-column-container class="kenjo-mt-40px kenjo-mb-20px">
            <orgos-text orgosColumn="0" type="Headline3">{{i18n.page.iGaveFeedbackFilterOption}}</orgos-text>
            <orgos-column size="1"></orgos-column>
          </orgos-column-container>
          <div class="pdpr-review-cards-grid">
            <orgos-review-card
              *ngFor="let review of feedbacksByType.userIsReviewer"
              (click)="openReview(review, true)"
              [review]="review"
            ></orgos-review-card>
          </div>
        </div>

        <div
          *ngIf="((selectedFilterBy === 'allActivity' && reviewsToShow) || selectedFilterBy === 'userIsReviewee') && hasReviewInfo('userIsReviewee')"
        >
          <orgos-column-container class="kenjo-mt-40px kenjo-mb-20px">
            <orgos-text orgosColumn="0" type="Headline3">{{i18n.page.iReceivedFeedbackHeader}}</orgos-text>
            <orgos-column size="1"></orgos-column>
          </orgos-column-container>
          <div class="pdpr-review-cards-grid">
            <orgos-review-card
              *ngFor="let review of feedbacksByType.userIsReviewee"
              (click)="openReview(review, false)"
              [review]="review"
            ></orgos-review-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tabIndex === 0 && showToDosTab">
    <kenjo-performance-review-to-dos [userPersonal]="userPersonal" [userWork]="userWork"></kenjo-performance-review-to-dos>
  </div>
</orgos-container>

<orgos-time-off-user-page
  *ngIf="seeDetailsUserId"
  [userId]="seeDetailsUserId"
  [managePoliciesInfo]="userManagePoliciesInfo"
  (goBack)="exitTimeOffFullScreen()"
  class="pdprp-time-off-page"
></orgos-time-off-user-page>
