import { Component, ContentChild, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-search-option',
  templateUrl: 'search-option.component.html',
  styleUrls: ['search-option.component.scss']
})
export class SearchOptionComponent {
  @Input() userPersonal: any;

  @Output() readonly selected: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('attr.tabindex') tabIndex = 0;

  @HostListener('keydown.enter', ['$event']) handleKeyboardEvent(event: KeyboardEvent): void {
    this.selected.emit();
  }
}
