<div>
  <orgos-column-container cdkOverlayOrigin #calendarOverlay="cdkOverlayOrigin" [centerColumns]="true">
    <mat-form-field
      orgosColumn="1"
      (click)="openCalendar('start')"
      class="idrcp-date-input kenjo-cursor-pointer"
      [ngClass]="{ 'mat-form-field-invalid': startDateError }"
      [matTooltip]="warningTooltipStart"
      matTooltipPosition="above"
    >
      <mat-label>{{ labelStart }} <span *ngIf="requiredStart">*</span></mat-label>
      <input
        matInput
        #startInput
        [ngModel]="startTime?.toDate() | date: 'shortDate':'UTC'"
        [disabled]="readOnlyStart"
        (ngModelChange)="onInputDateChange($event, 'start')"
        [ngClass]="{ 'kenjo-cursor-pointer': !readOnlyStart }"
      />
      <mat-icon
        matSuffix
        *ngIf="warningTooltipStart"
        class="idrcp-warning-icon"
        >warning_outline</mat-icon
      >
      <mat-datepicker-toggle matSuffix class="idrcp-calendar-icon"></mat-datepicker-toggle>
      <mat-hint class="mat-error" *ngIf="startDateError">{{ startDateError }}</mat-hint>
    </mat-form-field>
    <div *ngIf="showToLabel" class="kenjo-ml-20px kenjo-font-color-light-text-757575" orgosColumn="0">{{ translations?.to }}</div>
    <mat-form-field
      orgosColumn="1"
      (click)="openCalendar('end')"
      class="idrcp-date-input kenjo-cursor-pointer"
      [ngClass]="{ 'mat-form-field-invalid': endDateError, 'kenjo-ml-20px': showToLabel, 'kenjo-ml-30px': !showToLabel }"
    >
      <mat-label>{{ labelEnd }} <span *ngIf="requiredEnd">*</span></mat-label>
      <input
        matInput
        #endInput
        [ngModel]="endTime?.toDate() | date: 'shortDate':'UTC'"
        [disabled]="readOnlyEnd"
        (ngModelChange)="onInputDateChange($event, 'end')"
        [ngClass]="{ 'kenjo-cursor-pointer': !readOnlyStart }"
      />
      <mat-icon matSuffix *ngIf="warningTooltipEnd" [matTooltip]="warningTooltipEnd" matTooltipPosition="above" class="idrcp-warning-icon"
        >warning_outline</mat-icon
      >
      <mat-datepicker-toggle matSuffix class="idrcp-calendar-icon"></mat-datepicker-toggle>
      <mat-hint class="mat-error" *ngIf="endDateError">{{ endDateError }}</mat-hint>
    </mat-form-field>
  </orgos-column-container>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="calendarOverlay"
    [cdkConnectedOverlayOpen]="displayCalendar"
    [cdkConnectedOverlayOffsetX]="-40"
    [cdkConnectedOverlayOffsetY]="-10"
    (overlayOutsideClick)="closeCalendar()"
  >
    <mat-card class="idrcp-calendar-card" (mousedown)="onClickCalendar($event)">
      <orgos-loading-spinner *ngIf="loading" class="idrcp-loading"></orgos-loading-spinner>
      <ng-container *ngIf="!loading">
        <orgos-column-container centerColumns="true" class="idrcp-calendar-headers kenjo-font-weight-bold kenjo-mt-15px kenjo-mb-15px">
          <orgos-column size="1" class="idrcp-calendar-header">
            <orgos-column-container class="idrcp-header-buttons idrcp-header-left-buttons">
              <button
                orgosColumn="0"
                mat-icon-button
                [disabled]="dateLimits.minYearReached"
                [matTooltip]="translations.previousYear"
                matTooltipPosition="above"
                (click)="moveCalendarToPrevious('year')"
              >
                <mat-icon>double_arrow_left</mat-icon>
              </button>
              <button
                orgosColumn="0"
                mat-icon-button
                [disabled]="dateLimits.minMonthReached"
                [matTooltip]="translations.previousMonth"
                matTooltipPosition="above"
                (click)="moveCalendarToPrevious('month')"
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </orgos-column-container>
            <div class="idrcp-header-title">
              <span>{{ startCalendarPeriodLabel | uppercase }}</span>
            </div>
          </orgos-column>
          <orgos-column size="1" class="idrcp-calendar-header">
            <orgos-column-container class="idrcp-header-buttons idrcp-header-right-buttons">
              <button
                orgosColumn="0"
                mat-icon-button
                [disabled]="dateLimits.maxMonthReached"
                [matTooltip]="translations.nextMonth"
                matTooltipPosition="above"
                (click)="moveCalendarToNext('month')"
              >
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
              <button
                orgosColumn="0"
                mat-icon-button
                [disabled]="dateLimits.maxYearReached"
                [matTooltip]="translations.nextYear"
                matTooltipPosition="above"
                (click)="moveCalendarToNext('year')"
              >
                <mat-icon>double_arrow_right</mat-icon>
              </button>
            </orgos-column-container>
            <div class="idrcp-header-title">
              <span>{{ endCalendarPeriodLabel | uppercase }}</span>
            </div>
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container>
          <orgos-column class="idrcp-month-calendar">
            <mat-calendar
              #startCalendar
              class="idrcp-start-calendar"
              [dateClass]="dateClass"
              [selected]="selectedRangeValue"
              [startAt]="startCalendarStartAt"
              [minDate]="minDate"
              [maxDate]="maxDate"
              (selectedChange)="selectedChange($event)"
            >
            </mat-calendar>
          </orgos-column>
          <orgos-column class="idrcp-month-calendar">
            <mat-calendar
              #endCalendar
              class="idrcp-end-calendar"
              [dateClass]="dateClass"
              [selected]="selectedRangeValue"
              [startAt]="endCalendarStartAt"
              [minDate]="minDate"
              [maxDate]="maxDate"
              (selectedChange)="selectedChange($event)"
            >
            </mat-calendar>
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container class="idrcp-calendar-footer">
          <orgos-column size="0" class="idrcp-selected-days kenjo-font-size-14px kenjo-font-color-light-text-757575" *ngIf="selectedDays">
            {{ (selectedDays === 1 ? translations.selectedDay : translations.selectedDays) | i18nData: { amount: selectedDays } }}
          </orgos-column>
          <orgos-column size="0" class="kenjo-mr-10px">
            <orgos-button-raised (click)="closeCalendar()" color="Primary">{{ translations.closeButton }} </orgos-button-raised>
          </orgos-column>
        </orgos-column-container>
      </ng-container>
    </mat-card>
  </ng-template>
</div>
