<orgos-dialog-container [title]="titleText" (clickCloseButton)="closeDialog()">

  <orgos-input-simple-text-area class="ecdc-editor" [rows]="2" [(value)]="comment" [autosize]="true" [label]="commentLabel"></orgos-input-simple-text-area>

  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ cancelButtonText }}</orgos-button-raised>
    </orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Success" (click)="confirmDialog()" [disabled]="!comment || comment.length === 0">
        {{ confirmButtonText }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>

</orgos-dialog-container>
