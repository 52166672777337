import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ParamMap, Router } from '@angular/router';
import { NominatePeersDialog } from '@app/cloud-features/performance-review/dialogs/nominate-peers/nominate-peers-dialog';
import { INominableUser, PerformanceReviewNominationsController } from '@app/cloud-features/performance-review/controllers/performance-review-nominations.controller';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import * as _ from 'lodash';
import { picklist } from '@carlos-orgos/orgos-utils/constants';
import { UrlChangeService } from '@app/standard/services/core/url-changes.service';
@Component({
  selector: 'kenjo-people-detail-performance-nominate-team',
  templateUrl: 'people-detail-performance-nominate-team.page.html',
  styleUrls: ['people-detail-performance-nominate-team.page.scss'],
})
export class PeopleDetailPerformanceNominateTeamPage extends GenericPage {
  protected translationResources: Array<ITranslationResource> = [{ name: 'page', translationKey: 'performance-review-nominate-team-page' }];

  performanceReviewId: string;
  userId: string;
  STATUS_COLOR_MAP = {
    notStarted: 'Neutral',
    started: 'Caution',
    completed: 'Success',
  };

  totalColumns: Array<string> = ['name', 'jobTitle', 'status', 'actions'];

  teamEmployees = new Array<INominableUser>();
  availableAction: string;
  previousUrl: string;

  protected beforeInit(): Promise<void> {
    this.globalBarConfig.enableFullScreenMode = true;
    this.globalBarConfig.fullScreenModeUseSameUrl = false;
    this.route.paramMap.subscribe((param: ParamMap) => {
      const reviewId = param.get('performanceId');
      if (reviewId !== undefined) {
        this.performanceReviewId = reviewId;
        this.userId = param.get('id');
      } else {
        this.performanceReviewId = param.get('id');
      }
       this.previousUrl = this.injector.get(UrlChangeService).getPreviousUrl();
    });
    return Promise.resolve();
  }

  protected fetchData(resolveFetchData: Function): void {
    this.fetchTeamNominations().then(() => resolveFetchData());
  }

  private fetchTeamNominations() {
    return this.injector
      .get(PerformanceReviewNominationsController)
      .getTeamInPerformanceReview(this.performanceReviewId)
      .then((response) => {
        this.teamEmployees = response.teamEmployees;
        this.availableAction = response.availableAction;
      })
      .catch((e) => {
        this.teamEmployees = [];
        this.availableAction = picklist.TODO_ACTION_VIEW_SUBORDINATES;
      });
  }

  openPeers(user) {
    const data = {
      nominationUser: {
        photoUrl: user.photoUrl,
        displayName: user.displayName,
        _id: user._id,
        jobTitle: user.jobTitle,
      },
      performanceReviewId: this.performanceReviewId,
      nominations: user.nominations,
      readMode: this.availableAction === picklist.TODO_ACTION_VIEW_SUBORDINATES,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(NominatePeersDialog, { data });
    dialogRef.afterClosed().subscribe((nominationsSaved) => {
      if (nominationsSaved !== false) {
        this.fetchTeamNominations();
      }
    });
  }

  clickBack() {
    this.previousUrl = this.injector.get(UrlChangeService).getPreviousUrl();
    const fromPersonal = this.previousUrl.endsWith('personal');
    const notValidIds= this.performanceReviewId === undefined || this.userId === undefined

    if (fromPersonal || notValidIds) {
      this.previousUrl = `/cloud/performance-review/personal`
    } else if (this.performanceReviewId !== undefined && this.userId !== undefined) {
      this.previousUrl = `/cloud/people/${this.userId}/performance`
    }

    this.injector.get(Router).navigateByUrl(this.previousUrl);
  }
}
