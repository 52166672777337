import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IToDo } from '@app/cloud-features/performance-review/controllers/performance-review-to-dos.controller';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import check from 'check-types';
import * as moment from 'moment';

const STATUS_COLOR_MAP = {
  notStarted: 'Neutral',
  started: 'Caution',
  completed: 'Success',
};

@Component({
  selector: 'kenjo-performance-review-to-do-card',
  templateUrl: 'performance-review-to-do-card.component.html',
  styleUrls: ['performance-review-to-do-card.component.scss'],
})
export class PerformanceReviewToDoCardComponent implements OnInit {
  pageTranslations: Record<string, string> = {};
  statusColor: string;
  statusTranslation: string;
  dueDateTranslation: string;
  feedbackTypeSubtitle: string;
  isOverdue: boolean = false;

  @Input() toDo: IToDo;

  @Output() nominatePeersClick? = new EventEmitter<IToDo>();
  @Output() viewNominationsClick? = new EventEmitter<IToDo>();
  @Output() writeReviewClick? = new EventEmitter<IToDo>();
  @Output() readReviewClick? = new EventEmitter<IToDo>();
  @Output() nominateSubordinatesClick? = new EventEmitter<IToDo>();
  @Output() viewNominationsSubordinatesClick? = new EventEmitter<IToDo>();

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.initData();
  }

  async initData() {
    try {
      await this.initTranslations();
      this.setRestVariables();
    } catch {
      this.pageTranslations = {};
    }
  }

  async initTranslations() {
    this.pageTranslations = await this.injector.get(InternationalizationService).getAllTranslation('people-detail-performance-review-page');
  }

  setRestVariables() {
    if (check.assigned(this.toDo.dueDate)) {
      const dueDate = moment(this.toDo.dueDate).format('L');
      this.dueDateTranslation = this.injector.get(I18nDataPipe).transform(this.pageTranslations.dueDate, { dueDate });
    }

    if (check.assigned(this.toDo.feedbackType)) {
      this.feedbackTypeSubtitle = this.pageTranslations[`${this.toDo.feedbackType}Subtitle`];
    }

    if (check.not.assigned(this.toDo)) {
      return;
    }

    if (check.assigned(this.toDo.status)) {
      this.statusColor = STATUS_COLOR_MAP[this.toDo.status];
    }

    if (check.assigned(this.toDo.dueDate)) {
      this.isOverdue = moment.utc().isAfter(moment.utc(this.toDo.dueDate), 'day');
    }
  }

  onActionButtonClick() {
    if (this.toDo.actionType === 'nominatePeersAction') {
      this.nominatePeersClick.emit(this.toDo);
    } else if (this.toDo.actionType === 'viewNominationsAction') {
      this.viewNominationsClick.emit(this.toDo);
    } else if (this.toDo.actionType === 'writeReviewAction') {
      this.writeReviewClick.emit(this.toDo);
    } else if (this.toDo.actionType === 'readReviewAction') {
      this.readReviewClick.emit(this.toDo);
    } else if (this.toDo.actionType === 'nominateSubordinatesAction') {
      this.nominateSubordinatesClick.emit(this.toDo);
    } else if (this.toDo.actionType === 'viewNominationsSubordinatesAction') {
      this.viewNominationsSubordinatesClick.emit(this.toDo);
    }
  }
}
