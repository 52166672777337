<orgos-column-container *ngIf="document" class="ddp-full-screen">
  <orgos-column class="ddp-pdf-container" size="9">
    <kenjo-previewer [metadata]="document._file"></kenjo-previewer>
  </orgos-column>
  <orgos-column size="3" class="ddp-info-container kenjo-p-20px kenjo-color-grey-background-f5f5f5 kenjo-border-left">
    <orgos-action *ngIf="currentMode === 'readMode'" icon="arrow_back" (click)="onBackClick()"> {{pageTranslation.back}}</orgos-action>

    <!-- READ MODE -->
    <ng-container *ngIf="currentMode === 'readMode'">
      <orgos-container *ngIf="isRemindRequestReadEnabled" aspect="card" class="kenjo-p-20px kenjo-mt-40px">
        <orgos-column-container centerColumns="true">
          <orgos-column class="kenjo-mr-20px">
            {{pageTranslation.remindRequestReadDescription | i18nData: getUserPersonal(document.relatedTo.idRelatedTo)}}
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="onReadRequestClick()"> {{pageTranslation.remindRequestReadButtonLabel}}</orgos-action>
          </orgos-column>
        </orgos-column-container>
      </orgos-container>

      <div *ngIf="isConfirmReadEnabled" class="kenjo-mt-40px">
        <orgos-button-raised color="Primary" (click)="confirmRead()"> {{pageTranslation.confirmReadButtonLabel}} </orgos-button-raised>
      </div>

      <div class="kenjo-font-size-16px kenjo-mt-40px">{{pageTranslation.actionsTitle}}</div>
      <div *ngIf="hasEditPermission === true" class="kenjo-mt-10px">
        <orgos-action icon="mode_edit" (click)="currentMode = 'editMode'"> {{pageTranslation.editDocumentButtonLabel}}</orgos-action>
      </div>
      <div class="kenjo-mt-10px">
        <orgos-action icon="file_download" (click)="download()"> {{pageTranslation.downloadDocumentButtonLabel}} </orgos-action>
      </div>
      <div class="kenjo-mt-10px">
        <orgos-action
          *ngIf="signatureRequestFeatureActive && userCanCreateSignatureRequest && isSignable && !document.digitalSigned"
          icon="signature"
          (click)="currentMode = 'signatureMode'"
        >
          {{pageTranslation.digitalSignatureButtonLabel}}
        </orgos-action>
      </div>
      <div *ngIf="!isRemindRequestReadEnabled" class="kenjo-mt-10px">
        <orgos-action *ngIf="isRequestReadEnabled" [icon]="'send'" [disabled]="sendingReadRequest === true" (click)="onReadRequestClick()"
          >{{pageTranslation.requestReadButtonLabel}}
        </orgos-action>
        <orgos-action *ngIf="isReviewReadStatusEnabled" [icon]="'visibility'" (click)="onReadReviewClick()"
          >{{pageTranslation.reviewReadStatusButtonLabel}}
        </orgos-action>
      </div>
      <div
        *ngIf="hasDeletePermission === true && document.managed !== true"
        class="kenjo-mt-10px"
        [matTooltip]="hasPendingSignatureRequest.length > 0 ? pageTranslation.notAllowDeletePending: ''"
        matTooltipPosition="below"
      >
        <orgos-action color="Danger" icon="delete" (click)="delete()" [disabled]="hasPendingSignatureRequest.length > 0"
          >{{pageTranslation.deleteDocumentButtonLabel}}
        </orgos-action>
      </div>

      <div class="kenjo-font-size-16px kenjo-mt-40px">{{pageTranslation.documentsDetailsTitle}}</div>
      <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px">{{documentCollectionTranslation.name}} </orgos-column>
        <orgos-column size="2">{{document.name}}</orgos-column>
      </orgos-column-container>
      <orgos-column-container *ngIf="document.relatedTo" class="kenjo-mt-10px" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px"> {{documentCollectionTranslation.relatedTo}} </orgos-column>
        <orgos-column size="2">
          <ng-container *ngIf="document.relatedTo.typeRelatedTo === 'Company'">{{pageTranslation.viewableByCompany}} </ng-container>
          <ng-container *ngIf="document.relatedTo.typeRelatedTo === 'User' && document.relatedTo.idRelatedTo">
            {{getUserPersonal(document.relatedTo.idRelatedTo)?.displayName}}</ng-container
          >
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container
        *ngIf="document.relatedTo && document.relatedTo.typeRelatedTo === 'User'"
        class="kenjo-mt-10px"
        centerColumns="true"
      >
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px">
          {{documentCollectionTranslation.viewableByUser}}
        </orgos-column>
        <orgos-column size="2">
          <ng-container *ngIf="document.hidden === true">{{documentCollectionTranslation.viewableByUserNo}} </ng-container>
          <ng-container *ngIf="document.hidden === false">{{documentCollectionTranslation.viewableByUserYes}} </ng-container>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px">
          {{documentCollectionTranslation._createdById}}
        </orgos-column>
        <orgos-column size="2">{{getUserPersonal(document._createdById)?.displayName}}</orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px"> {{documentCollectionTranslation._createdAt}} </orgos-column>
        <orgos-column size="2">{{document._createdAt | date: 'shortDate'}}</orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-10px" *ngIf="document.validUntil" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px"> {{documentCollectionTranslation.validUntil}} </orgos-column>
        <orgos-column size="2">{{document.validUntil | utcDate | date: 'shortDate'}}</orgos-column>
      </orgos-column-container>

      <ng-container *ngIf="document.lastReadRequestAt">
        <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
          <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px">
            {{documentCollectionTranslation.lastReadRequestAt}}
          </orgos-column>
          <orgos-column size="2">{{document.lastReadRequestAt | date: 'shortDate'}}</orgos-column>
        </orgos-column-container>

        <orgos-column-container *ngIf="isUserARequestee === true" class="kenjo-mt-10px" centerColumns="true">
          <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px">{{documentCollectionTranslation.readBy}} </orgos-column>
          <orgos-column size="2">
            <orgos-column-container wrap="true">
              <orgos-avatar
                *ngIf="currentUserRequestAcknowledge.readDate"
                orgosColumn="0"
                [avatarName]="getUserPersonal(currentUserRequestAcknowledge.userId)?.displayName"
                [photoUrl]="getUserPersonal(currentUserRequestAcknowledge.userId)?._photo?._url"
                size="small"
                [matTooltip]="currentUserRequestAcknowledge.readDate | date: 'short'"
                matTooltipPosition="above"
              ></orgos-avatar>
            </orgos-column-container>
            <ng-container *ngIf="!currentUserRequestAcknowledge.readDate">{{pageTranslation.naLabel}}</ng-container>
          </orgos-column>
        </orgos-column-container>
      </ng-container>

      <div class="kenjo-font-color-light-text-757575 kenjo-mt-10px">{{documentCollectionTranslation.tags}}</div>
      <orgos-column-container *ngIf="document.tags && document.tags.length > 0" wrap="true">
        <orgos-chip
          orgosColumn="0"
          *ngFor="let tag of document.tags"
          [color]="getTagColor(tag)"
          class="kenjo-mt-10px kenjo-mr-10px kenjo-font-color-white-ffffff"
          >{{getTagName(tag)}}</orgos-chip
        >
      </orgos-column-container>

      <orgos-column-container *ngIf="document.tags.length === 0" wrap="true">
        <orgos-chip orgosColumn="0" class="kenjo-mt-10px kenjo-mr-10px kenjo-font-color-white-ffffff" [color]="getTagColor(unlabelledTagId)"
          >{{ pageTranslation.unlabelledTagName }}</orgos-chip
        >
      </orgos-column-container>

      <div *ngIf="hasEditPermission === true">
        <div class="kenjo-font-size-16px kenjo-mt-40px kenjo-mb-10px">{{pageTranslation.conversationTitle}}</div>
        <orgos-conversation
          [commentList]="document.comments"
          (commentCreated)="createComment($event)"
          (commentUpdated)="updateComment($event)"
        ></orgos-conversation>
      </div>
    </ng-container>
    <!-- END READ MODE -->

    <!-- EDIT MODE -->
    <ng-container *ngIf="currentMode === 'editMode'">
      <orgos-input-text
        [model]="documentModel"
        field="name"
        class="kenjo-block kenjo-mt-20px"
        [required]="true"
        (validation)="nameValidation = $event"
      >
        <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">
          {{pageTranslation.nameRequired}}</orgos-input-error
        >
      </orgos-input-text>
      <orgos-input-date-picker
        [model]="documentModel"
        field="validUntil"
        [enableClearButton]="true"
        class="kenjo-block kenjo-mt-20px"
      ></orgos-input-date-picker>
      <ng-container *ngIf="hasEditPermission === true">
        <div class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{pageTranslation.tag}}</div>
        <orgos-chip
          *ngIf="allDocumentTags?.length === 0"
          color="#757575"
          class="kenjo-text-align-center kenjo-mt-10px"
          [matTooltip]="pageTranslation.noDocumentTagsTooltip"
          [matTooltipPosition]="'above'"
        >
          <kenjo-truncate-text class="ddp-tag-text kenjo-font-color-white-ffffff"
            >{{pageTranslation.unlabelledTagName}}</kenjo-truncate-text
          >
        </orgos-chip>
        <orgos-column-container wrap="true">
          <orgos-chip
            orgosColumn="0"
            *ngFor="let tag of allDocumentTags"
            [color]="getTagColorOnEditMode(tag)"
            [buttonIconName]="isTagSelectedOnEditMode(tag) === true ? 'clear' : 'add'"
            [onClickButtonFunction]="toggleTagOnEditMode(tag)"
            [class.kenjo-font-color-white-ffffff]="isTagSelectedOnEditMode(tag) === true"
            class="kenjo-mt-10px kenjo-mr-10px"
          >
            {{tag.name}}</orgos-chip
          >
        </orgos-column-container>
      </ng-container>
      <orgos-column-container class="kenjo-mt-60px">
        <orgos-column></orgos-column>
        <orgos-button-raised orgosColumn="0" (click)="cancelUpdate()" color="Neutral">
          {{miscTranslation.goBackButtonDialog}}</orgos-button-raised
        >
        <orgos-column
          size="0"
          [matTooltip]="(this.documentModel.data.tags.length === 0 && this.areTagsMandatory) ? pageTranslation.missingTagsToolTip : null"
        >
          <orgos-button-raised
            orgosColumn="0"
            (click)="updateDocument()"
            color="Success"
            [disabled]="!nameValidation || nameValidation.hasErrors() || (this.documentModel.data.tags.length === 0 && this.areTagsMandatory)"
            class="kenjo-ml-10px"
            >{{pageTranslation.saveButtonLabel}}</orgos-button-raised
          >
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <!-- END EDIT MODE -->

    <!-- SIGNATURE MODE -->
    <ng-container *ngIf="currentMode === 'signatureMode'">
      <div class="kenjo-font-size-16px kenjo-mt-40px">{{pageTranslation.selectSignersTitle}}</div>
      <div class="kenjo-font-size-14px kenjo-mt-10px kenjo-font-color-light-text-757575">{{pageTranslation.selectSignersDescription}}</div>
      <div class="kenjo-mt-20px">
        <mat-radio-group [ngModel]="signatureOptions.signersOption" (change)="onChangeSignersOption($event)">
          <mat-radio-button *ngFor="let option of signersOptions; let last = last" [value]="option.value" class="kenjo-mt-15px kenjo-block">
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Specific option -->
      <div class="kenjo-mt-30px" *ngIf="signatureOptions.signersOption === 'specific' && signatureOptions.recipients.length === 0">
        <orgos-action icon="add_circle" iconSize="normal" (click)="onSelectOrEditSigners()">
          {{pageTranslation.selectSignersButtonLabel}}
        </orgos-action>
      </div>

      <!-- All option -->
      <div *ngIf="signatureOptions.signersOption === 'all'">
        <div class="kenjo-mt-30px kenjo-font-size-12px kenjo-font-color-light-text-757575">
          {{pageTranslation.selectCompanyOfficeDepartment}}
        </div>
        <div style="width: 100%">
          <mat-form-field [matMenuTriggerFor]="selectMenu">
            <mat-label kenjoRemoveAriaOwns>{{allEmployeesLabel}}</mat-label>
            <mat-select></mat-select>
          </mat-form-field>
          <mat-menu #selectMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="companiesMenu">{{pageTranslation.companyLabel}}</button>
            <button mat-menu-item [matMenuTriggerFor]="officesMenu">{{pageTranslation.officeLabel}}</button>
            <button mat-menu-item [matMenuTriggerFor]="departmentsMenu">{{pageTranslation.departmentLabel}}</button>
          </mat-menu>
          <mat-menu #companiesMenu="matMenu">
            <div *ngFor="let company of allCompanies" mat-menu-item (click)="$event.stopPropagation()">
              <mat-checkbox [ngModel]="getCheckedCompanyValue(company._id)" (ngModelChange)="changeSelectedCompanies($event, company)"
                >{{ company.name }}</mat-checkbox
              >
            </div>
          </mat-menu>
          <mat-menu #officesMenu="matMenu">
            <div *ngFor="let office of allOffices" mat-menu-item (click)="$event.stopPropagation()">
              <mat-checkbox [ngModel]="getCheckedOfficeValue(office._id)" (ngModelChange)="changeSelectedOffices($event, office)"
                >{{ office.name }}</mat-checkbox
              >
            </div>
          </mat-menu>
          <mat-menu #departmentsMenu="matMenu">
            <div *ngFor="let department of allDepartments" mat-menu-item (click)="$event.stopPropagation()">
              <mat-checkbox
                [ngModel]="getCheckedDepartmentValue(department._id)"
                (ngModelChange)="changeSelectedDepartments($event, department)"
                >{{ department.name }}</mat-checkbox
              >
            </div>
          </mat-menu>
        </div>
        <mat-slide-toggle [checked]="signatureOptions.includeInactiveEmployees" (change)="changeIncludeInactive($event.checked)"
          >{{pageTranslation.includeInactiveEmployeesLabel}}</mat-slide-toggle
        >
      </div>

      <div class="ddp-employee-counter-box kenjo-pv-20px kenjo-mt-20px kenjo-ph-15px">
        <!-- Edit-->
        <div class="ddp-employee-counter-box-edit">
          <div *ngIf="signatureOptions.recipients.length > 0 || signatureOptions.docOwners.length > 0">
            <kenjo-icon
              [size]="24"
              class="kenjo-font-color-primary-5993e3 kenjo-pr-10px kenjo-cursor-pointer"
              (click)="onSelectOrEditSigners()"
            >
              mode_edit
            </kenjo-icon>
            <kenjo-icon [size]="24" class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer" (click)="onCancelSignerSelection()">
              delete
            </kenjo-icon>
          </div>
        </div>
        <!-- Signers -->
        <div class="ddp-employee-counter-box-section kenjo-mt-10px">
          <p class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-mv-0">{{pageTranslation.selectedSignersLabel}}</p>
          <p class="kenjo-font-size-14px kenjo-mv-0 kenjo-text-align-right">
            {{ signatureOptions.recipients.length > 0 ? signatureOptions.recipients.length + ' employees' :
            pageTranslation.noEmployeesSelectedText}}
          </p>
        </div>
        <!-- Assignments -->
        <div
          class="ddp-employee-counter-box-section kenjo-mt-10px"
          *ngIf="signatureOptions.signersOption === 'specific' && signatureOptions.signingMode === 'sequential'"
        >
          <p class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-mv-0">{{pageTranslation.selectedAssignmentLabel}}</p>
          <p class="kenjo-font-size-14px kenjo-mv-0 kenjo-text-align-right">
            {{ signatureOptions.docOwners.length > 0 ? signatureOptions.docOwners.length + ' employees' :
            pageTranslation.noEmployeesSelectedText}}
          </p>
        </div>
      </div>

      <div class="kenjo-mt-60px kenjo-font-size-16px">{{pageTranslation.addPersonalMessageTitle}}</div>
      <div class="kenjo-mt-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">
        {{pageTranslation.addPersonalMessageSubtitle}}
      </div>
      <div class="kenjo-mt-20px" *ngIf="!signatureOptions.personalMessage">
        <orgos-action icon="add_circle" iconSize="normal" (click)="onAddOrEditPersonalMessage()">
          {{pageTranslation.addPersonalMessageButtonLabel}}
        </orgos-action>
      </div>

      <div class="employee-counter-box kenjo-pv-20px kenjo-mt-20px kenjo-ph-15px" *ngIf="signatureOptions.personalMessage">
        <p class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-mv-0">{{pageTranslation.personalMessageLabel}}</p>
        <div>
          <kenjo-icon
            [size]="24"
            class="kenjo-font-color-primary-5993e3 kenjo-pr-10px kenjo-cursor-pointer"
            (click)="onAddOrEditPersonalMessage()"
          >
            mode_edit
          </kenjo-icon>
          <kenjo-icon [size]="24" class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer" (click)="onDeletePersonalMessage()">
            delete
          </kenjo-icon>
        </div>
      </div>

      <orgos-column-container class="kenjo-mt-60px">
        <orgos-column></orgos-column>
        <orgos-button-raised orgosColumn="0" (click)="cancelUpdate()" color="Neutral">
          {{miscTranslation.goBackButtonDialog}}</orgos-button-raised
        >
        <orgos-button-raised
          orgosColumn="0"
          (click)="sendDocument()"
          color="Success"
          [disabled]="sendingSignatureRequest === true || signatureOptions.recipients.length === 0 || (signatureOptions.signersOption === 'specific' && signatureOptions.signingMode === 'sequential' && signatureOptions.docOwners.length === 0 )"
          class="kenjo-ml-10px"
        >
          {{pageTranslation.sendDocumentLabel}}</orgos-button-raised
        >
      </orgos-column-container>
    </ng-container>
    <!-- END SIGNATURE MODE -->
  </orgos-column>
</orgos-column-container>
