import { Injectable, Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import * as _ from 'lodash';

@Injectable()
export class SettingsBarService {
  constructor(private router: Router, private injector: Injector, public dialog: MatLegacyDialog) {}

  getOptions(pageTranslation): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      const options: Array<any> = [];
      if (this.injector.get(CloudRoutesService).checkRoute('settings/overview') === true) {
        options.push({ name: pageTranslation.overviewTab, onClick: () => this.router.navigateByUrl('/cloud/settings/overview') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/structure/company') === true) {
        options.push({ name: pageTranslation.companiesTab, onClick: () => this.router.navigateByUrl('/cloud/settings/structure/company') });
      } else if (this.injector.get(CloudRoutesService).checkRoute('settings/structure/office') === true) {
        options.push({ name: pageTranslation.companiesTab, onClick: () => this.router.navigateByUrl('/cloud/settings/structure/office') });
      } else if (this.injector.get(CloudRoutesService).checkRoute('settings/structure/area') === true) {
        options.push({ name: pageTranslation.companiesTab, onClick: () => this.router.navigateByUrl('/cloud/settings/structure/area') });
      } else if (this.injector.get(CloudRoutesService).checkRoute('settings/structure/department') === true) {
        options.push({
          name: pageTranslation.companiesTab,
          onClick: () => this.router.navigateByUrl('/cloud/settings/structure/department'),
        });
      } else if (this.injector.get(CloudRoutesService).checkRoute('settings/structure/team') === true) {
        options.push({ name: pageTranslation.companiesTab, onClick: () => this.router.navigateByUrl('/cloud/settings/structure/team') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/data-import') === true) {
        options.push({ name: pageTranslation.dataImportTab, onClick: () => this.router.navigateByUrl('/cloud/settings/data-import') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/workflows') === true) {
        options.push({ name: pageTranslation.workflowsTab, onClick: () => this.router.navigateByUrl('/cloud/settings/workflows') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/employee-information') === true) {
        options.push({
          name: pageTranslation.employeeInfoTab,
          onClick: () => this.router.navigateByUrl('/cloud/settings/employee-information'),
        });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/email-template') === true) {
        options.push({
          name: pageTranslation.emailTemplateTab,
          onClick: () => this.router.navigateByUrl('/cloud/settings/email-template'),
        });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/welcome-wizard') === true) {
        options.push({
          name: pageTranslation.welcomeWizardTab,
          onClick: () => this.router.navigateByUrl('/cloud/settings/welcome-wizard'),
        });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/roles-and-permissions') === true) {
        options.push({
          name: pageTranslation.profilesAndPermissionsTab,
          onClick: () => this.router.navigateByUrl('/cloud/settings/roles-and-permissions'),
        });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/single-sign-on') === true) {
        options.push({ name: pageTranslation.securityTab, onClick: () => this.router.navigateByUrl('/cloud/settings/single-sign-on') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/calendar') === true) {
        options.push({ name: pageTranslation.calendarTab, onClick: () => this.router.navigateByUrl('/cloud/settings/calendar') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/cost-centers') === true) {
        options.push({ name: pageTranslation.costCentersTab, onClick: () => this.router.navigateByUrl('/cloud/settings/cost-centers') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('settings/billing') === true) {
        options.push({ name: pageTranslation.billingTab, onClick: () => this.router.navigateByUrl('/cloud/settings/billing/info') });
      }
      resolve(options);
    });
  }
}
