<mat-form-field *ngIf="model && field" [class.idpc-clickable]="!readOnly" (click)="openDatepicker()">
  <mat-label [class.kenjo-ml-10px]="leftDot && !getValue()">{{ label }}</mat-label>
  <input *ngIf="!leftDot" [class.idpc-show]="readOnly" matInput [ngModel]="getValue() | utcDate | date: 'shortDate'" readOnly [disabled]="readOnly" [required]="required" />
  <div *ngIf="leftDot" class="idpc-circle-mini-container">
    <div class="idpc-circle-mini"></div>
    <input [class.idpc-show]="readOnly" matInput [ngModel]="getValue() | utcDate | date: 'shortDate'" readOnly [disabled]="readOnly" [required]="required" />
  </div>
  <input [class.idpc-show]="!readOnly" matInput [matDatepicker]="datepicker" [ngModel]="getValue()" (ngModelChange)="setValue($event)" [required]="required" [min]="min" [max]="max" disabled="true" [class.idpc-clickable]="!readOnly" class="idp-editable" />
  <mat-datepicker-toggle [class.idpc-show]="!readOnly" matSuffix [for]="datepicker"></mat-datepicker-toggle>
  <mat-icon *ngIf="showClearButton && datepicker.opened === false" matSuffix class="idpc-clear-button" (click)="clearValue(); $event.stopPropagation()">clear</mat-icon>
</mat-form-field>
<mat-datepicker #datepicker [startAt]="startDate" disabled="false"></mat-datepicker>
<div *ngIf="!isValueValid" class="idpc-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint" class="idpc-saved-hint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
