import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Injectable, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MatCalendarCellClassFunction, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { MatCalendar } from '@angular/material/datepicker';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export class DoubleCalendarRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  previewDateChange$: Subject<{ activeDate: D; currentRange: DateRange<D> }> = new Subject();
  enablePreview: boolean = true;

  constructor() {}

  selectionFinished(date: D, currentRange: DateRange<D>) {
    this.previewDateChange$.next({ activeDate: null, currentRange });
    return new DateRange<D>(currentRange.start, currentRange.end);
  }

  createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
    if (this.enablePreview) {
      this.previewDateChange$.next({ activeDate, currentRange });
      let { start } = currentRange;
      if (start && activeDate) {
        return new DateRange<D>(start, activeDate);
      }
    }
    return new DateRange<D>(null, null);
  }
}

@Component({
  selector: 'kenjo-input-date-range-calendar-picker',
  templateUrl: 'input-date-range-calendar-picker.component.html',
  styleUrls: ['input-date-range-calendar-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DoubleCalendarRangeSelectionStrategy
    }
  ]
})
export class InputDateRangeCalendarPickerComponent implements OnInit, OnDestroy {
  @Input() requiredStart: boolean = false;
  @Input() requiredEnd: boolean = false;
  @Input() readOnlyStart: boolean = false;
  @Input() readOnlyEnd: boolean = false;
  @Input() labelStart: string;
  @Input() labelEnd: string;
  @Input() showToLabel: boolean = false;
  @Input() fillEndDateOnClose: boolean = true;
  @Input() warningTooltipStart: string;
  @Input() warningTooltipEnd: string;

  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;

  @Input() set singleEvents(value: Array<ISingleCalendarEvent>) {
    this.initCalendarEvents(value);
  }
  @Input() recurringEvents: Array<IRecurringCalendarEvent>;
  @Input() selectedRangeValue: DateRange<moment.Moment>;

  @Input() loading: boolean = false;
  @Input() eventsRange: DateRange<moment.Moment>;

  @Output() selectedRangeValueChange = new EventEmitter<ISelectedRange>();
  @Output() startDateValidationChange: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Output() endDateValidationChange: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Output() loadEventsChange: EventEmitter<{ time: SelectedTimeType; date: moment.Moment }> = new EventEmitter<{ time: SelectedTimeType; date: moment.Moment }>();
  @Output() calendarOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('startCalendar') startCalendar: MatCalendar<moment.Moment>;
  @ViewChild('endCalendar') endCalendar: MatCalendar<moment.Moment>;
  @ViewChild('startInput') startInput: ElementRef;
  @ViewChild('endInput') endInput: ElementRef;

  VALID_DATE_FORMATS: Array<string> = ['DD-MM-YYYY', 'D-MM-YYYY', 'DD-M-YYYY', 'D-M-YYYY', 'DD-MM-YY', 'D-MM-YY', 'DD-M-YY', 'D-M-YY'];

  selected: moment.Moment | null;
  startTime: moment.Moment | null;
  endTime: moment.Moment | null;
  selectedTime: SelectedTimeType;
  selectedDays: number;
  displayCalendar = false;

  dayEventsMap: Map<string, IDayCalendarEvent>;
  startCalendarStartAt: moment.Moment | null;
  endCalendarStartAt: moment.Moment | null;
  startCalendarPeriodLabel: string;
  endCalendarPeriodLabel: string;

  dateLimits = {
    minMonthReached: false,
    minYearReached: false,
    maxMonthReached: false,
    maxYearReached: false
  };
  startDateValidation: InputValidation;
  endDateValidation: InputValidation;
  startDateError: string;
  endDateError: string;

  previewDateChangeSubscription: Subscription;

  translations: { [translationKey: string]: string };

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    if (view === 'month') {
      return this.getDayEventStyles(cellDate.format('YYYY-MM-DD'));
    }
    return '';
  };

  constructor(private injector: Injector, @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY) private selectionStrategy: DoubleCalendarRangeSelectionStrategy<moment.Moment>) {}

  ngOnInit(): void {
    this.initTranslations();
    this.initCalendarDates();
    this.initSelectionChangesSubscription();
  }

  ngOnDestroy(): void {
    this.removeSelectionChangesSubscription();
  }

  private async initTranslations() {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('input-date-range-calendar-picker-component');
    } catch {
      this.translations = {};
    }
  }

  private initCalendarDates() {
    this.initStartAndEndTime();
    this.updateStartAt();
  }

  private initSelectionChangesSubscription() {
    this.previewDateChangeSubscription = this.selectionStrategy.previewDateChange$.subscribe(({ activeDate, currentRange }) => {
      this.printCalendarPreview({ activeDate, currentRange });
    });
  }

  private removeSelectionChangesSubscription() {
    if (check.assigned(this.selectionStrategy.previewDateChange$)) {
      this.previewDateChangeSubscription.unsubscribe();
    }
  }

  private initCalendarEvents(singleEvents: Array<ISingleCalendarEvent>) {
    this.dayEventsMap = new Map<string, IDayCalendarEvent>();
    if (check.nonEmptyArray(singleEvents)) {
      singleEvents.forEach((singleEvent) => {
        const startDateMoment = moment.utc(singleEvent.startDate);
        const daysBetween = Math.ceil(moment.utc(singleEvent.endDate).diff(startDateMoment, 'days')) + 1;
        [...Array(daysBetween).keys()].forEach((key) => {
          const formattedDate = startDateMoment.clone().add(key, 'day').format('YYYY-MM-DD');
          const isStart = key === 0;
          const isEnd = key === daysBetween - 1;
          this.addDayEvent({ formattedDate, name: singleEvent.name, isStart, isEnd, backgroundColor: singleEvent.backgroundColor, fontColor: singleEvent.fontColor });
        });
      });
    }
  }

  private addDayEvent({ formattedDate, name, isStart, isEnd, backgroundColor, fontColor }: { formattedDate: string; name: string; isStart: boolean; isEnd: boolean; backgroundColor: boolean; fontColor: boolean }) {
    let newDateEvent: IDayCalendarEvent;
    const previousDayEvent = this.dayEventsMap.get(formattedDate);
    if (check.assigned(previousDayEvent)) {
      newDateEvent = {
        names: [...previousDayEvent.names, name],
        isStart: previousDayEvent.isStart && isStart,
        isEnd: previousDayEvent.isEnd && isEnd,
        backgroundColor: previousDayEvent.backgroundColor || backgroundColor,
        fontColor: previousDayEvent.fontColor || fontColor
      };
    } else {
      newDateEvent = {
        names: [name],
        isStart,
        isEnd,
        backgroundColor,
        fontColor
      };
    }
    this.dayEventsMap.set(formattedDate, newDateEvent);
  }

  private getDayEventStyles(date: string) {
    let dayEvent = this.dayEventsMap.get(date);
    dayEvent = this.processDayRecurringEvents(date, dayEvent);
    const styles = [];
    if (check.assigned(dayEvent)) {
      if (dayEvent.isStart && dayEvent.isEnd) {
        styles.push('idrcp-single-day');
      } else if (dayEvent.isStart) {
        styles.push('idrcp-first-day');
      } else if (dayEvent.isEnd) {
        styles.push('idrcp-last-day');
      }
      if (dayEvent.backgroundColor) {
        styles.push('idrcp-background-color-day');
      }
      if (dayEvent.fontColor) {
        styles.push('idrcp-font-color-day');
      }
      const tooltipName = dayEvent.names.join('\n');
      const tooltipClass = `idrcp-${timeOffHelpers.generateUniqueId()}`;
      styles.push(tooltipClass, 'tooltip');
      setTimeout(() => {
        const tooltipElements = document.querySelectorAll(`.${tooltipClass} .mat-calendar-body-cell-content`);
        tooltipElements.forEach((elem) => {
          elem.setAttribute('tooltip-data', tooltipName);
        });
      }, 200);
    }
    return styles.join(' ');
  }

  private processDayRecurringEvents(date: string, dayEvent: IDayCalendarEvent): IDayCalendarEvent {
    const normalizedIsoWeekday = moment.utc(date).isoWeekday();
    let processedDayEvent = dayEvent;
    this.recurringEvents.forEach((recurringEvent) => {
      if (moment.utc(date).isBetween(recurringEvent.startDate, recurringEvent.endDate, 'day', '[]') && recurringEvent.daysOfWeek.includes(normalizedIsoWeekday)) {
        if (check.assigned(processedDayEvent)) {
          processedDayEvent = {
            names: [...processedDayEvent.names, recurringEvent.name],
            isStart: processedDayEvent.isStart,
            isEnd: processedDayEvent.isEnd,
            backgroundColor: processedDayEvent.backgroundColor || recurringEvent.backgroundColor,
            fontColor: processedDayEvent.fontColor || recurringEvent.fontColor
          };
        } else {
          processedDayEvent = {
            names: [recurringEvent.name],
            isStart: true,
            isEnd: true,
            backgroundColor: recurringEvent.backgroundColor,
            fontColor: recurringEvent.fontColor
          };
        }
      }
    });
    return processedDayEvent;
  }

  public openCalendar(time: SelectedTimeType) {
    if ((time === 'start' && this.readOnlyStart) || (time === 'end' && this.readOnlyEnd)) {
      return;
    }
    this.selectedTime = this.startTime ? time : 'start';
    this.updateInputFocus();
    this.displayCalendar = true;
    this.calendarOpened.emit(true);
    if (check.not.assigned(this.selectedRangeValue)) {
      const start = this.startTime ?? null;
      const end = this.endTime ?? null;
      this.selectedRangeValue = new DateRange<moment.Moment>(start, end);
    }
    this.updateStartAt(this.selectedTime);
    this.selectionStrategy.enablePreview = this.selectedTime === 'end';
  }

  onClickCalendar(event) {
    // Prevent input focusout event from being triggered
    event.preventDefault();
    event.stopPropagation();
  }

  public closeCalendar() {
    this.displayCalendar = false;
    this.calendarOpened.emit(false);
    if (this.fillEndDateOnClose) {
      this.fillEmptyEndDate();
    }
    this.checkEmptyDates();
    this.printDateErrors();
  }

  public selectedChange(date: moment.Moment | null, manual: boolean = false, dateStr?: string) {
    const newDate = date?.startOf('day');
    let lastSelectedTime = this.selectedTime;
    const validation = this.checkValidDate(newDate, lastSelectedTime, manual, dateStr);
    if (validation.hasErrors()) {
      if (this.selectedTime === 'start' && this.startDateValidation.getError('required')) {
        this.startTime = null;
      } else if (this.selectedTime === 'end' && this.endDateValidation.getError('required')) {
        this.endTime = null;
      }
      this.setNewDateRange(this.selectedTime);
      return;
    }
    if (this.selectedTime === 'start' || (this.startTime && moment.utc(this.startTime).isAfter(newDate) && !this.readOnlyStart)) {
      this.startTime = newDate;
      if (!this.readOnlyEnd) {
        this.endTime = null;
        lastSelectedTime = 'start';
        this.selectedTime = 'end';
      }
    } else if (!this.readOnlyEnd) {
      this.endTime = newDate;
      this.selectedTime = this.readOnlyStart ? 'end' : 'start';
    }
    this.setNewDateRange(lastSelectedTime);
    this.selectionStrategy.enablePreview = this.selectedTime === 'end';
    this.updateSelectedDays();
    this.printCalendarPreview({ activeDate: null });
    this.printDateErrors(lastSelectedTime);
    this.updateInputFocus();
    if (manual === true) {
      this.moveCalendarToDate(newDate);
    }
  }

  public moveCalendarToPrevious(unit: 'month' | 'year') {
    this.startCalendar.activeDate = this.startCalendar.activeDate.clone().subtract(1, unit);
    this.endCalendar.activeDate = this.endCalendar.activeDate.clone().subtract(1, unit);
    this.updateCalendarPeriodLabel(this.startCalendar.activeDate, this.endCalendar.activeDate);
    this.checkDateLimits(this.startCalendar.activeDate, this.endCalendar.activeDate);
    this.syncStartAt();
    this.loadEvents();
  }

  public moveCalendarToNext(unit: 'month' | 'year') {
    this.startCalendar.activeDate = this.startCalendar.activeDate.clone().add(1, unit);
    this.endCalendar.activeDate = this.endCalendar.activeDate.clone().add(1, unit);
    this.updateCalendarPeriodLabel(this.startCalendar.activeDate, this.endCalendar.activeDate);
    this.checkDateLimits(this.startCalendar.activeDate, this.endCalendar.activeDate);
    this.syncStartAt();
    this.loadEvents();
  }

  private moveCalendarToDate(date: moment.Moment) {
    this.startCalendar.activeDate = date.clone();
    this.endCalendar.activeDate = date.clone().add(1, 'month');
    this.updateCalendarPeriodLabel(this.startCalendar.activeDate, this.endCalendar.activeDate);
    this.checkDateLimits(this.startCalendar.activeDate, this.endCalendar.activeDate);
    this.syncStartAt();
    this.loadEvents();
  }

  private loadEvents() {
    if (this.startCalendar.activeDate.isBefore(this.eventsRange?.start)) {
      this.loadEventsChange.emit({ time: 'start', date: this.startCalendar.activeDate.clone().startOf('month') });
    } else if (this.endCalendar.activeDate.isSameOrAfter(this.eventsRange?.end)) {
      this.loadEventsChange.emit({ time: 'end', date: this.endCalendar.activeDate.clone().add(1, 'month').startOf('month') });
    }
  }

  private syncStartAt() {
    this.startCalendarStartAt = this.startCalendar.activeDate.clone();
    this.endCalendarStartAt = this.endCalendar.activeDate.clone();
  }

  private initStartAndEndTime() {
    if (check.assigned(this.selectedRangeValue)) {
      this.startTime = this.selectedRangeValue.start;
      this.endTime = this.selectedRangeValue.end;
      if (this.readOnlyEnd) {
        this.maxDate = this.endTime.clone();
      }
      if (this.readOnlyStart) {
        this.minDate = this.startTime.clone();
      }
    }
  }

  private updateInputFocus() {
    if (this.selectedTime === 'start') {
      this.startInput.nativeElement.focus();
    } else {
      this.endInput.nativeElement.focus();
    }
  }

  private updateStartAt(time?: SelectedTimeType) {
    let startAt;
    if (check.not.assigned(time) || time === 'start' || !this.endTime) {
      startAt = this.startTime ? this.startTime : moment.utc();
    } else {
      startAt = this.startTime.isSame(this.endTime, 'month') ? this.endTime : this.endTime.clone().subtract(1, 'month');
    }
    const startAtStartOfMonth = startAt.clone().startOf('month');
    if (startAtStartOfMonth.isSameOrBefore(this.minDate.clone().startOf('month'))) {
      startAt = this.minDate.clone();
    } else if (startAtStartOfMonth.isSameOrAfter(this.maxDate.clone().startOf('month'))) {
      startAt = this.maxDate.clone().subtract(1, 'month');
    }
    this.startCalendarStartAt = startAt;
    this.endCalendarStartAt = startAt.clone().add(1, 'month');
    this.updateCalendarPeriodLabel(this.startCalendarStartAt, this.endCalendarStartAt);
    this.checkDateLimits(this.startCalendarStartAt, this.endCalendarStartAt);
  }

  private updateCalendarPeriodLabel(startDate: moment.Moment, endDate: moment.Moment) {
    this.startCalendarPeriodLabel = this.injector.get(DatePipe).transform(startDate.toDate(), 'MMM y');
    this.endCalendarPeriodLabel = this.injector.get(DatePipe).transform(endDate.toDate(), 'MMM y');
  }

  private checkDateLimits(startDate: moment.Moment, endDate: moment.Moment) {
    const minDateStartOfMonth = this.minDate.clone().startOf('month');
    const maxDateStartOfMonth = this.maxDate.clone().startOf('month');
    this.dateLimits.minYearReached = startDate.clone().startOf('month').add(1, 'month').subtract(1, 'year').isSameOrBefore(minDateStartOfMonth);
    this.dateLimits.minMonthReached = startDate.clone().startOf('month').isSameOrBefore(minDateStartOfMonth);
    this.dateLimits.maxYearReached = endDate.clone().endOf('month').subtract(1, 'month').add(1, 'year').isSameOrAfter(maxDateStartOfMonth);
    this.dateLimits.maxMonthReached = endDate.clone().endOf('month').isSameOrAfter(maxDateStartOfMonth);
  }

  private printCalendarPreview({ activeDate, currentRange }: { activeDate: moment.Moment; currentRange?: DateRange<moment.Moment> }) {
    if ((currentRange && !currentRange.start) || activeDate?.isSame(this.startCalendar.activeDate, 'month')) {
      return;
    }
    if (currentRange?.start && activeDate && currentRange.start.month() === activeDate.month()) {
      return;
    }
    const isStartDayInLastMonth = currentRange?.start && activeDate && currentRange.start.clone().add(1, 'month').month() === activeDate.month();
    const startDay = isStartDayInLastMonth ? currentRange.start.date() : 0;
    const startCalendarDayElements = document.querySelectorAll('.idrcp-start-calendar .mat-calendar-body-cell');
    if (check.not.assigned(startCalendarDayElements) || startCalendarDayElements?.length === 0) {
      return;
    }
    startCalendarDayElements.forEach((dayElement) => {
      if (check.not.assigned(activeDate)) {
        dayElement.classList.remove('mat-calendar-body-in-preview', 'mat-calendar-body-preview-start');
        return;
      }
      const contentElement = dayElement.querySelector('.mat-calendar-body-cell-content');
      if (check.not.assigned(contentElement)) {
        return;
      }
      const contentDay = +contentElement.innerHTML;
      if (contentDay === startDay) {
        dayElement.classList.add('mat-calendar-body-preview-start', 'mat-calendar-body-in-preview');
      } else if (contentDay > startDay) {
        dayElement.classList.add('mat-calendar-body-in-preview');
      } else {
        dayElement.classList.remove('mat-calendar-body-in-preview', 'mat-calendar-body-preview-start');
      }
    });
  }

  public onInputDateChange(dateStr: string, time: SelectedTimeType) {
    const momentDate = this.parseDateWithCustomSeparators(dateStr);
    this.selectedTime = time;
    this.selectedChange(momentDate, true, dateStr);
  }

  private parseDateWithCustomSeparators(dateString) {
    const cleanedDateString = dateString?.replace(/[\/.]/g, '-');
    return moment.utc(cleanedDateString, this.VALID_DATE_FORMATS, true);
  }

  private fillEmptyEndDate() {
    if (check.assigned(this.startTime) && check.not.assigned(this.endTime)) {
      this.endTime = this.startTime.clone();
      this.endDateValidation = new InputValidation();
      this.endDateValidationChange.emit(this.endDateValidation);
      this.setNewDateRange('end');
    }
  }

  private checkEmptyDates() {
    if (check.not.assigned(this.startDateValidation)) {
      const inputValidation = new InputValidation();
      if (this.requiredStart && check.not.assigned(this.startTime)) {
        inputValidation.setError('required');
      }
      if (inputValidation.hasErrors()) {
        this.startDateValidationChange.emit(inputValidation);
        this.startDateValidation = inputValidation;
      }
    }
    if (check.not.assigned(this.endDateValidation)) {
      const inputValidation = new InputValidation();
      if (this.requiredEnd && check.not.assigned(this.endTime)) {
        inputValidation.setError('required');
      }
      if (inputValidation.hasErrors()) {
        this.endDateValidationChange.emit(inputValidation);
        this.endDateValidation = inputValidation;
      }
    }
  }

  private checkValidDate(date: moment.Moment, time: SelectedTimeType, manual: boolean, dateStr?: string) {
    const inputValidation = new InputValidation();
    const required = time === 'start' ? this.requiredStart : this.requiredEnd;
    if (required && manual === true && (check.not.assigned(dateStr) || check.emptyString(dateStr))) {
      inputValidation.setError('required');
    } else if (check.assigned(date)) {
      if (!date.isValid()) {
        inputValidation.setError('format');
      } else if (check.assigned(this.minDate) && date.isBefore(this.minDate)) {
        inputValidation.setError('min');
      } else if (check.assigned(this.maxDate) && date.isAfter(this.maxDate)) {
        inputValidation.setError('max');
      }
    }
    if (time === 'start') {
      this.startDateValidation = inputValidation;
      this.startDateValidationChange.emit(inputValidation);
    } else {
      this.endDateValidation = inputValidation;
      this.endDateValidationChange.emit(inputValidation);
    }
    return inputValidation;
  }

  private printDateErrors(time?: SelectedTimeType) {
    if (check.not.assigned(time) || time === 'start') {
      if (check.not.assigned(this.startDateValidation) || !this.startDateValidation.hasErrors()) {
        this.startDateError = undefined;
      } else if (this.startDateValidation.getError('required')) {
        this.startDateError = this.translations.startDateRequired;
      } else if (this.startDateValidation.getError('format')) {
        this.startDateError = this.translations.startDateFormat;
      } else if (this.startDateValidation.getError('min')) {
        this.startDateError = this.injector.get(I18nDataPipe).transform(this.translations.startDateMin, { min: this.minDate.format('L') });
      } else if (this.startDateValidation.getError('max')) {
        this.startDateError = this.injector.get(I18nDataPipe).transform(this.translations.startDateMax, { max: this.maxDate.format('L') });
      } else {
        this.startDateError = undefined;
      }
    }
    if (check.not.assigned(time) || time === 'end') {
      if (check.not.assigned(this.endDateValidation) || !this.endDateValidation.hasErrors()) {
        this.endDateError = undefined;
      } else if (this.endDateValidation.getError('required')) {
        this.endDateError = this.translations.endDateRequired;
      } else if (this.endDateValidation.getError('format')) {
        this.endDateError = this.translations.endDateFormat;
      } else if (this.endDateValidation.getError('min')) {
        this.endDateError = this.injector.get(I18nDataPipe).transform(this.translations.endDateMin, { min: this.minDate.format('L') });
      } else if (this.endDateValidation.getError('max')) {
        this.endDateError = this.injector.get(I18nDataPipe).transform(this.translations.endDateMax, { max: this.maxDate.format('L') });
      } else {
        this.endDateError = undefined;
      }
    }
  }

  private updateSelectedDays() {
    if (check.not.assigned(this.startTime) || check.not.assigned(this.endTime)) {
      this.selectedDays = undefined;
      return;
    }
    this.selectedDays = this.endTime.diff(this.startTime, 'days') + 1;
  }

  private setNewDateRange(lastSelectedTime: SelectedTimeType) {
    this.selectedRangeValue = new DateRange<moment.Moment>(this.startTime, this.endTime);
    this.selectedRangeValueChange.emit({ selectedTime: lastSelectedTime, dateRange: this.selectedRangeValue });
  }
}

export type SelectedTimeType = 'start' | 'end' | undefined;

export interface IRecurringCalendarEvent {
  name: string;
  startDate: Date;
  endDate: Date;
  daysOfWeek: Array<number>;
  backgroundColor: boolean;
  fontColor: boolean;
}

export interface ISingleCalendarEvent {
  name: string;
  startDate: Date;
  endDate: Date;
  backgroundColor: boolean;
  fontColor: boolean;
}

export interface IDayCalendarEvent {
  names: Array<string>;
  isStart: boolean;
  isEnd: boolean;
  backgroundColor: boolean;
  fontColor: boolean;
}

export interface ISelectedRange {
  selectedTime: SelectedTimeType;
  dateRange: DateRange<moment.Moment>;
}
