import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { TimeOffRequestService } from '@app/cloud-features/time-off/services/time-off-request.service';
import { ITimeOffAssignmentModel, TimeOffStatusService } from '@app/cloud-features/time-off/services/time-off-status.service';
import {
  HistoryAction,
  IDetailHistory,
  IFilter,
  IHistoryResult,
  IRequestAttachment,
  ITimeOffHistory,
  ITimeOffType,
  TimeOffUserHistoryController,
} from '@app/cloud-features/time-off/services/time-off-user-history.controller';
import { ITimeOffUserPolicy } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { CancelTimeOffRequestDialog } from '@app/common-components/time-off-user-personal/dialog/cancel-time-off-request/cancel-time-off-request.dialog';
import { SubmitTimeOffRequestDialog } from '@app/common-components/time-off-user-personal/dialog/submit-time-off-request/submit-time-off-request.dialog';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ISelectOption } from '@app/standard/core/select-option';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { DocumentService, IDocumentModel } from '@app/standard/services/document/document.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import {
  POLICY_TYPE_DAY,
  TIME_OFF_ACTION_ACCRUAL,
  TIME_OFF_ACTION_ADD_EXTRA_ALLOWANCE_DAYS,
  TIME_OFF_ACTION_ADJUST_BALANCE,
  TIME_OFF_ACTION_ADJUST_CARRY_OVER,
  TIME_OFF_ACTION_ADJUST_COMPENSATION,
  TIME_OFF_ACTION_ADJUST_TAKEN,
  TIME_OFF_ACTION_APPROVE_REQUEST,
  TIME_OFF_ACTION_ASSIGN_POLICY,
  TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_REQUEST,
  TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_SUBMISSION,
  TIME_OFF_ACTION_CANCEL_REQUEST,
  TIME_OFF_ACTION_CANCEL_SUBMISSION,
  TIME_OFF_ACTION_CARRY_OVER,
  TIME_OFF_ACTION_DECLINE_REQUEST,
  TIME_OFF_ACTION_EDIT_REQUEST,
  TIME_OFF_ACTION_EXPIRE_CARRY_OVER,
  TIME_OFF_ACTION_NEGATIVE_BALANCE,
  TIME_OFF_ACTION_NEW_CYCLE,
  TIME_OFF_ACTION_PROCESS_SUBMISSION,
  TIME_OFF_ACTION_REASSIGN_POLICY,
  TIME_OFF_ACTION_REQUEST_TIME_OFF,
  TIME_OFF_ACTION_SCHEDULE_POLICY,
  TIME_OFF_ACTION_SUBMIT_TIME_OFF,
  TIME_OFF_ACTION_UNASSIGN_POLICY,
  TIME_OFF_ASSIGNMENT_ACTION_UNSCHEDULE,
  TIME_OFF_REQUEST_STATUS_CANCELLED,
  TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED,
  TIME_OFF_REQUEST_STATUS_DECLINED,
  TIME_OFF_REQUEST_STATUS_IN_APPROVAL,
  TIME_OFF_REQUEST_STATUS_PENDING,
  TIME_OFF_REQUEST_TYPE_REQUEST
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import { environment } from '@env';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'orgos-time-off-history',
  templateUrl: 'time-off-history.component.html',
  styleUrls: ['time-off-history.component.scss'],
})
export class TimeOffHistoryComponent implements OnInit, OnDestroy {
  // Constants
  TIME_OFF_ACTION_ASSIGN_POLICY: string = TIME_OFF_ACTION_ASSIGN_POLICY;
  TIME_OFF_ACTION_REASSIGN_POLICY: string = TIME_OFF_ACTION_REASSIGN_POLICY;
  TIME_OFF_ACTION_UNASSIGN_POLICY: string = TIME_OFF_ACTION_UNASSIGN_POLICY;
  TIME_OFF_ACTION_SCHEDULE_POLICY: string = TIME_OFF_ACTION_SCHEDULE_POLICY;
  TIME_OFF_REQUEST_STATUS_PENDING: string = TIME_OFF_REQUEST_STATUS_PENDING;
  TIME_OFF_REQUEST_STATUS_IN_APPROVAL: string = TIME_OFF_REQUEST_STATUS_IN_APPROVAL;
  TIME_OFF_ACTION_SUBMIT_TIME_OFF: string = TIME_OFF_ACTION_SUBMIT_TIME_OFF;
  TIME_OFF_ACTION_APPROVE_REQUEST: string = TIME_OFF_ACTION_APPROVE_REQUEST;
  TIME_OFF_ACTION_EDIT_REQUEST: string = TIME_OFF_ACTION_EDIT_REQUEST;
  TIME_OFF_ACTION_REQUEST_TIME_OFF: string = TIME_OFF_ACTION_REQUEST_TIME_OFF;
  TIME_OFF_ACTION_DECLINE_REQUEST: string = TIME_OFF_ACTION_DECLINE_REQUEST;
  TIME_OFF_ACTION_CANCEL_REQUEST: string = TIME_OFF_ACTION_CANCEL_REQUEST;
  TIME_OFF_ACTION_PROCESS_SUBMISSION: string = TIME_OFF_ACTION_PROCESS_SUBMISSION;
  TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_REQUEST: string = TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_REQUEST;
  TIME_OFF_ACTION_CANCEL_SUBMISSION: string = TIME_OFF_ACTION_CANCEL_SUBMISSION;
  TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_SUBMISSION: string = TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_SUBMISSION;
  TIME_OFF_ACTION_ADJUST_CARRY_OVER: string = TIME_OFF_ACTION_ADJUST_CARRY_OVER;
  TIME_OFF_ACTION_ADJUST_TAKEN: string = TIME_OFF_ACTION_ADJUST_TAKEN;
  TIME_OFF_ACTION_ADJUST_BALANCE: string = TIME_OFF_ACTION_ADJUST_BALANCE;
  TIME_OFF_ACTION_ADJUST_COMPENSATION: string = TIME_OFF_ACTION_ADJUST_COMPENSATION;
  TIME_OFF_ACTION_ADD_EXTRA_ALLOWANCE_DAYS: string = TIME_OFF_ACTION_ADD_EXTRA_ALLOWANCE_DAYS;
  TIME_OFF_ACTION_ACCRUAL: string = TIME_OFF_ACTION_ACCRUAL;
  TIME_OFF_ACTION_CARRY_OVER: string = TIME_OFF_ACTION_CARRY_OVER;
  TIME_OFF_ACTION_EXPIRE_CARRY_OVER: string = TIME_OFF_ACTION_EXPIRE_CARRY_OVER;
  TIME_OFF_ACTION_NEW_CYCLE: string = TIME_OFF_ACTION_NEW_CYCLE;
  TIME_OFF_ACTION_NEGATIVE_BALANCE: string = TIME_OFF_ACTION_NEGATIVE_BALANCE;
  POLICY_TYPE_DAY: string = POLICY_TYPE_DAY;
  MAX_ATTACHMENT_COUNT: number = environment.MAX_ATTACHMENT_COUNT;
  TIME_TO_SHOW_EMPTY_HISTORY: number = 1000;

  COLORS: any = userColorConstants;

  // Input / Output
  @Input() userId: string;
  @Input() viewAsAdminOrManager: boolean = false;
  @Input() refreshDataEvent: Observable<void>;
  @Input() statusList: Array<ITimeOffUserPolicy>;
  @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() emptyTimeOffShown: EventEmitter<void> = new EventEmitter<void>();

  // Subscriptions
  private refreshDataSubscription: Subscription;

  // Variables
  componentTranslation: any = {};
  cancelRequestTranslation: any = {};
  globalMiscTranslation: any = {};
  loggedUser: any = {};
  authenticationService: any = {};
  requestPermissions: { canCreateAttachment: boolean, canDeleteAttachment: boolean };

  loadingComponent: boolean = false;
  disabledShowMoreButton: boolean = false;
  typeSelected: string;
  showPolicyChanges: boolean = false;

  timeOffTypeOptions: Array<ISelectOption> = [];

  historyList: Array<ITimeOffHistory> = [];
  previousLength: number = 0;
  filteredHistoryList: Array<ITimeOffHistory> = [];
  historyListPage: number = 1;
  expandedElement: ITimeOffHistory;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.initPage();
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  private initSubscriptions() {
    this.refreshDataSubscription = this.refreshDataEvent.subscribe(() => this.fetchData());
  }

  private destroySubscriptions() {
    this.refreshDataSubscription.unsubscribe();
  }

  private async initPage() {
    this.loadingComponent = true;
    await this.initPermissions();
    await this.initTranslations();
    await this.fetchData();
    this.loadingComponent = false;
  }

  private async initPermissions(): Promise<void> {
    this.authenticationService = this.injector.get(AuthenticationService);
    this.loggedUser = this.authenticationService.getLoggedUser();
    await this.getAllPermissions();
  }

  private async getAllPermissions(): Promise<void> {
    try {
      const [canCreateAttachment, canDeleteAttachment] = await Promise.all([
        this.injector.get(PrivateSecurityService).computePermissions({ ownerId: this.userId }, 'document.create'),
        this.injector.get(PrivateSecurityService).computePermissions({ ownerId: this.userId }, 'document.delete'),
      ]);
      this.requestPermissions = { canCreateAttachment, canDeleteAttachment };
    } catch {
      this.requestPermissions = { canCreateAttachment: false, canDeleteAttachment: false };
    }
  }

  private async initTranslations(): Promise<void> {
    try {
      this.componentTranslation = await this.injector.get(InternationalizationService).getAllTranslation('time-off-history');
      this.cancelRequestTranslation = await this.injector
        .get(InternationalizationService)
        .getAllTranslation('time-off-cancel-request-dialog');
      this.globalMiscTranslation = await this.injector.get(InternationalizationService).getAllTranslation('misc');
    } catch {
      this.componentTranslation = {};
      this.cancelRequestTranslation = {};
      this.globalMiscTranslation = {};
    }
  }

  private async fetchData(): Promise<void> {
    await Promise.all([this.getHistoryList(), this.createTypeOptions()]);
  }

  private async getHistoryList(): Promise<void> {
    try {
      const filterOptions: IFilter = {
        userId: this.userId,
        historyListPage: this.historyListPage,
        showPolicyChanges: this.showPolicyChanges,
        typeSelected: this.typeSelected,
      };

      this.previousLength = this.historyList.length;
      const callResult: IHistoryResult = await this.injector.get(TimeOffUserHistoryController).getUserHistoryList(filterOptions);
      this.disabledShowMoreButton = callResult.disabledShowMoreButton;
      this.historyList = callResult.records;
      await this.checkIfEmptyTimeOff();
    } catch {
      // Do nothing
    }
  }

  private async checkIfEmptyTimeOff() {
    if (
      this.historyListPage === 1 &&
      this.showPolicyChanges === false &&
      check.not.assigned(this.typeSelected) &&
      this.previousLength === 0 &&
      this.historyList?.length === 0 &&
      (!this.statusList || this.statusList.length === 0)
    ) {
      await new Promise((res) => setTimeout(res, this.TIME_TO_SHOW_EMPTY_HISTORY));
      this.emptyTimeOffShown.emit();
    }
  }

  private async createTypeOptions(): Promise<void> {
    const types: Array<ITimeOffType> = await this.injector.get(TimeOffUserHistoryController).getTypes(this.userId);
    const timeOffTypeOptions = [{ name: this.componentTranslation.all, value: '0' }];
    types.forEach((type) => {
      timeOffTypeOptions.push({
        name: type.name,
        value: type._id,
      });
    });
    this.timeOffTypeOptions = timeOffTypeOptions;
  }

  public showMoreRecords(): void {
    this.historyListPage++;
    this.getHistoryList();
  }

  public filterByType(type: string): void {
    this.typeSelected = type;
    this.getHistoryList();
  }

  public changeShowPolicy(): void {
    this.showPolicyChanges = !this.showPolicyChanges;
    this.getHistoryList();
  }

  public logViewTimeOffDetails() {
    if (
      check.not.assigned(this.expandedElement) ||
      this.expandedElement.isRequest === false ||
      check.not.assigned(this.expandedElement.request)
    ) {
      return;
    }

    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view time off request details', { category: 'Time off', platform: 'Web', subcategory1: 'timeoff history' });
  }

  openCancelScheduledAssignment(history: ITimeOffHistory): void {
    const data = {
      titleText: this.componentTranslation.cancelScheduledAssignmentDialogTitle,
      subtitleText: this.injector
        .get(I18nDataPipe)
        .transform(this.componentTranslation.cancelScheduledAssignmentDialogSubtitle, { policy: history.policy.name }),
      cancelButtonText: this.globalMiscTranslation.goBackButtonDialog,
      confirmButtonText: this.componentTranslation.cancelScheduledAssignmentDialogButton,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe((cancelScheduledAssignment: any) => {
      if (check.not.assigned(cancelScheduledAssignment) || cancelScheduledAssignment === false) {
        return;
      }
      this.cancelScheduleAssignment(history);
    });
  }

  private async cancelScheduleAssignment(history: ITimeOffHistory) {
    try {
      const assignments: Array<ITimeOffAssignmentModel> = [
        {
          policyId: history.policy._id,
          timeOffTypeId: history.timeOffType._id,
          userId: this.userId,
          action: TIME_OFF_ASSIGNMENT_ACTION_UNSCHEDULE,
        },
      ];
      await this.injector.get(TimeOffStatusService).assignUserPolicies(assignments);
      this.historyList = this.historyList.filter((filteredHistory) => filteredHistory !== history);
      this.getHistoryList();
    } catch {
      // Do nothing
    }
  }

  getHistoryDetail(history: ITimeOffHistory): Array<IDetailHistory> {
    if (history.isRequest === false) {
      return [history];
    }
    return history.requestHistory;
  }

  openCancelRequestDialog(history: ITimeOffHistory): void {
    const dialogTranslations = {
      titleText: this.cancelRequestTranslation.dialogRequestHeader,
      descriptionLabel: this.cancelRequestTranslation.descriptionLabel,
      cancelLabel: this.cancelRequestTranslation.cancelButtonLabel,
      backLabel: this.globalMiscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CancelTimeOffRequestDialog, { data: dialogTranslations });

    dialogRef.afterClosed().subscribe((result: { cancel: boolean; description: string }) => {
      if (check.not.assigned(result) || result.cancel !== true) {
        return;
      }
      this.cancelRequest(history.request._id, result.description);
      this.injector.get(PrivateAmplitudeService).logEvent('cancel time off request', {
        category: 'Time off',
        platform: 'Web',
        subcategory1: 'timeoff request',
        subcategory2: history.policy._type,
      });
    });
  }

  private async cancelRequest(requestId: string, description: string): Promise<void> {
    try {
      await this.injector.get(TimeOffRequestService).cancelRequest(requestId, description);
      this.injector.get(MatLegacySnackBar).open(this.cancelRequestTranslation.cancelationSuccessful, 'OK', {
        duration: 5000,
      });
      this.dataChanged.emit();
    } catch {
      // Do nothing
    }
  }

  public async openEditRequestDialog(history: ITimeOffHistory): Promise<void> {
    const userPolicyStatus = this.statusList.find((status) => status.timeOffType._id === history.timeOffType._id);
    const dialogOptions = {
      policy: userPolicyStatus.policy,
      timeOffType: userPolicyStatus.timeOffType,
      currentStatus: userPolicyStatus.currentStatus,
      startDate: userPolicyStatus.userWork.startDate,
      reportsToId: userPolicyStatus.userWork.reportsToId,
      isOnBehalf: false,
      isRequest: history.request._type === TIME_OFF_REQUEST_TYPE_REQUEST,
      isEdit: true,
      userId: this.userId,
      requestPermissions: this.requestPermissions,
      oldRequest: history.request,
      oldRequestAttachments: history.requestAttachments,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(SubmitTimeOffRequestDialog, { data: dialogOptions, disableClose: true });

    dialogRef.afterClosed().subscribe((requested: boolean) => {
      if (check.assigned(requested) && requested === true) {
        this.dataChanged.emit();
      }
    });
  }

  public async addAttachment(history: ITimeOffHistory): Promise<void> {
    try {
      const uploadedDocument: IFileMetadata = await this.injector.get(UppyHelperService).uploadGenericDocument();
      if (check.not.assigned(uploadedDocument)) {
        return;
      }
      const createdDocument = await this.createDocument(history, uploadedDocument);
      if (check.not.assigned(createdDocument)) {
        return;
      }
      await this.updateAttachments(history, uploadedDocument, createdDocument);
    } catch {
      // Do nothing
    }
  }

  private async createDocument(history: ITimeOffHistory, uploadedDocument: IFileMetadata) {
    const documentToCreate: IDocumentModel = {
      name: uploadedDocument._fileName,
      relatedTo: {
        typeRelatedTo: 'User',
        idRelatedTo: history.request.ownerId,
      },
      _file: uploadedDocument,
      hidden: false,
      managed: true,
    };
    if (check.nonEmptyArray(history.timeOffType.tags)) {
      documentToCreate.tags = [...history.timeOffType.tags];
    } else {
      documentToCreate.tags = [];
    }
    return this.injector.get(DocumentService).create(documentToCreate);
  }

  private async updateAttachments(history: ITimeOffHistory, uploadedDocument: IFileMetadata, createdDocument: IDocumentModel) {
    let attachments = [];
    if (check.nonEmptyArray(history.request.attachments)) {
      attachments = [...history.request.attachments];
    }
    attachments.push(createdDocument._id);
    await this.injector.get(TimeOffRequestService).updateAttachments(history.request._id, attachments);
    history.request.attachments = attachments;
    if (check.not.assigned(history.requestAttachments)) {
      history.requestAttachments = [];
    }
    const userPersonal = await this.injector.get(UserPersonalService).getById(this.loggedUser._id);
    history.requestAttachments.push(this.fillRequestAttachmentByDocument(createdDocument, uploadedDocument._url, userPersonal));
  }

  private fillRequestAttachmentByDocument(document: IDocumentModel, url: string, userPersonal: IUserPersonalModel): IRequestAttachment {
    return {
      id: document._id,
      documentName: document.name,
      documentUrl: url,
      documentExtension: document._file._fileExtension,
      documentSize: document._file._fileSizeInBytes,
      createdByName: userPersonal.displayName,
      createdByPhotoUrl: userPersonal?._photo?._url,
      createdAt: document._createdAt.toString(),
    };
  }

  public async deleteAttachment(history: ITimeOffHistory, attachment: IRequestAttachment): Promise<void> {
    try {
      const attachmentIndex = history.request.attachments.findIndex((attachmentId) => attachmentId === attachment.id);
      if (attachmentIndex !== -1) {
        history.request.attachments.splice(attachmentIndex, 1);
      }
      await this.injector.get(TimeOffRequestService).updateAttachments(history.request._id, history.request.attachments);

      const documentIndex = history.requestAttachments.findIndex((iAttachment) => iAttachment.id === attachment.id);
      if (documentIndex !== -1) {
        history.requestAttachments.splice(documentIndex, 1);
      }
      await this.injector.get(DocumentService).deleteById(attachment.id);
    } catch {
      // Do nothing
    }
  }

  public async downloadAttachment(attachment: IRequestAttachment): Promise<void> {
    try {
      const arrayBuffer = await this.injector.get(HttpClient).get(attachment.documentUrl, { responseType: 'arraybuffer' }).toPromise();
      if (check.assigned(arrayBuffer)) {
        FileSaver.saveAs(new Blob([arrayBuffer]), attachment.documentName);
      }
    } catch {
      // Do nothing
    }
  }

  public checkAttachmentsCanBeAdded(history: ITimeOffHistory): boolean {
    return history.timeOffType.attachments === true && history.request.status !== TIME_OFF_REQUEST_STATUS_DECLINED && history.request.status !== TIME_OFF_REQUEST_STATUS_CANCELLED && history.request.status !== TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED;
  }

  public showHistoryAvatar(isRequest: boolean, action: HistoryAction) {
    return (
      isRequest === true ||
      action === TIME_OFF_ACTION_ASSIGN_POLICY ||
      action === TIME_OFF_ACTION_REASSIGN_POLICY ||
      action === TIME_OFF_ACTION_UNASSIGN_POLICY ||
      action === TIME_OFF_ACTION_SCHEDULE_POLICY ||
      action === TIME_OFF_ACTION_ADJUST_BALANCE ||
      action === TIME_OFF_ACTION_ADJUST_TAKEN ||
      action === TIME_OFF_ACTION_ADJUST_CARRY_OVER
    );
  }

  public checkIfHistoryInApproval(history: ITimeOffHistory) {
    return (
      history.isRequest === true &&
      (history.request.status === TIME_OFF_REQUEST_STATUS_PENDING || history.request.status === TIME_OFF_REQUEST_STATUS_IN_APPROVAL)
    );
  }

  public getShownCycleEndDate(cycleEndDate: string) {
    return moment.utc(cycleEndDate).subtract(1, 'days').toISOString();
  }

  public checkEditionInTimeZone(requestTo: string) {
    return moment().isBefore(moment.utc(requestTo).format('YYYY-MM-DDTHH:mm:ss'));
  }

}
