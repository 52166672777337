import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FilterBarFieldComponent } from '@app/standard/components/filter-bar-field/filter-bar-field.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-filter-bar',
  templateUrl: 'filter-bar.component.html',
  styleUrls: ['filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit, OnChanges {
  filtersListWidth: number = 0;
  filtersListItemsWidth: number = 0;
  showLeftArrow: boolean = false;
  showRightArrow: boolean = true;
  optionsListWidth: number = 0;
  optionsListItemsWidth: number = 0;
  showOptionsLeftArrow: boolean = false;
  showOptionsRightArrow: boolean = true;

  CLEAR_BUTTON_WIDTH: number = 57;

  @Input() filterOptions: IFilterOptions;
  @Input() filterFields: Array<IFilterField>;
  @Input() applyFiltersImmediately: boolean = true;
  @Output() changeFilterEvent = new EventEmitter();
  @Output() clearFilterEvent = new EventEmitter();
  @Output() clearAllFiltersEvent = new EventEmitter();
  @Output() setFiltersAreEmpty = new EventEmitter<boolean>();
  @Input() shown: boolean = false;
  @Input() applyEachFilter: boolean = false;
  @Input() showButtonToApplyAllFilters: boolean = false;
  @Input() includeEmployeeSearch: boolean = false; // If true, includes the search bar for employees
  @Input() isSearchBarExpanded = false;
  @Input() resetTooltipPosition: 'above' | 'below' | 'left' | 'right' | 'before' | 'after' = 'above';
  @Input() disabledApplyFilters: boolean = false;
  @ViewChildren(FilterBarFieldComponent) filterBarFields: QueryList<FilterBarFieldComponent>;

  private _searchTerm = '';
  @Input() set searchTerm(t: string) {
    this._searchTerm = t;
    if (this._searchTerm?.length) {
      this.isSearchBarExpanded = true;
    }
    this.filtersAreEmpty = this.checkFiltersAreEmpty(this.activeOptions, t);
  }
  get searchTerm() {
    return this._searchTerm;
  }
  @Output() searchTermChanged = new EventEmitter<string>();

  @ViewChild('filtersList') filtersList: ElementRef;
  @ViewChild('optionsList') optionsList: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  filterOptionsKeys: Array<string>;
  componentTranslations: IFilterBarTranslations;
  translationsLoaded: boolean = false;
  optionKeyToLabel: { [key: string]: string };

  activeOptions: IFilterOptions = {};
  private _filtersAreEmpty = false;
  set filtersAreEmpty(value: boolean) {
    this._filtersAreEmpty = value;
    this.setFiltersAreEmpty.emit(value);
  }
  get filtersAreEmpty() {
    return this._filtersAreEmpty;
  }
  noFilters: boolean = true;
  filtersDifference: boolean = false;
  filterDifference: { [key: string]: boolean };
  filtersApplied: IFilterOptions;

  private optionKeyToPickerType: { [key: string]: 'single' | 'multiple' };

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.fetchData();
  }

  ngAfterViewChecked(): void {
    if (check.assigned(this.filtersList)) {
      this.filtersListWidth = this.filtersList.nativeElement.clientWidth;
      this.filtersListItemsWidth = this.filtersList.nativeElement.scrollWidth;
      this.cdr.detectChanges();
    }
    if (check.assigned(this.optionsList)) {
      this.optionsListWidth = this.optionsList.nativeElement.clientWidth;
      this.optionsListItemsWidth = this.optionsList.nativeElement.scrollWidth;
      this.cdr.detectChanges();
    }
  }

  async fetchData() {
    try {
      this.componentTranslations = await this.injector.get(InternationalizationService).getAllTranslation('filter-bar-component');

      // This component has 2 different ways of working.
      // a) "Standard Mode": as it is being used in time-off-requests.page.ts, where we can't control the order of the components and all of the selectors are multi-select. It will always include an employee search
      // b) "Flexible Mode": order and label of the filters can be added. The search employee bar is configurable too
      if (this.filterFields?.length) {
        // If "flexible mode"
        this.filterOptionsKeys = this.filterFields.map((filterField: IFilterField) => {
          return filterField.key;
        });
        this.optionKeyToPickerType = {};
        this.filterOptions = {};
        this.optionKeyToLabel = {};
        this.filterFields.forEach((filterField: IFilterField) => {
          this.optionKeyToPickerType[filterField.key] = filterField.pickerType;
          this.optionKeyToLabel[filterField.key] = filterField.label;
          this.filterOptions[filterField.key] = filterField.options;
          this.activeOptions[filterField.key] = this.computeActiveOptions(filterField.options);
        });
      } else if (this.filterOptions) {
        // If "standard mode"
        this.filterDifference = {};
        Object.keys(this.filterOptions).forEach((key) => {
          this.filterDifference[key] = false;
        });
        this.optionKeyToPickerType = {};
        this.optionKeyToLabel = {};
        this.filterOptionsKeys = Object.keys(this.filterOptions);
        this.filterOptionsKeys.forEach((optionKey) => {
          this.optionKeyToLabel[optionKey] = this.getFilterLabel(optionKey);
          this.optionKeyToPickerType[optionKey] = 'multiple'; // in standard mode, all options are multiple by default
          this.activeOptions[optionKey] = this.computeActiveOptions(this.filterOptions[optionKey]);
        });
      }

      this.noFilters = this.checkFiltersAreEmpty(this.filterOptions, this.searchTerm);
      this.filtersAreEmpty = this.checkFiltersAreEmpty(this.activeOptions, this.searchTerm);

      this.scrollFilters(0);
    } catch {
      this.componentTranslations = {};
    } finally {
      this.translationsLoaded = true;
      this.filtersApplied = { ...this.activeOptions };
      this.filtersDifference = false;

      if (this.applyEachFilter) {
        Object.keys(this.filterDifference).forEach((optionKey) => {
          this.checkFilterIsAlreadyApplied(optionKey);
        });
      }
    }
  }

  ngOnChanges() {
    this.fetchData();
  }

  public getFilterLabel(optionName): string {
    const translationField = `${optionName}FilterLabel`;
    return this.componentTranslations[translationField];
  }

  public toggleFilter({ optionKey, optionIndex }) {
    if (!(optionKey in this.filterOptions) || !(optionIndex in this.filterOptions[optionKey])) {
      return;
    }

    if (this.optionKeyToPickerType && this.optionKeyToPickerType[optionKey] === 'single') {
      this.invalidateOptionsForKey(optionKey);
    }

    const newValue = !this.filterOptions[optionKey][optionIndex].active;
    this.filterOptions[optionKey][optionIndex].active = newValue;
    this.activeOptions[optionKey] = this.computeActiveOptions(this.filterOptions[optionKey]);
    this.filtersAreEmpty = this.checkFiltersAreEmpty(this.activeOptions, this.searchTerm);

    if (this.applyFiltersImmediately) {
      this.emitChangeFilterEvent(optionKey, optionIndex);
      this.resetScrollOnFilterTags();
    }

    if (!this.applyFiltersImmediately && this.showButtonToApplyAllFilters) {
      this.emitChangeFilterEvent(optionKey, optionIndex);
      this.resetScrollOnFilterTags();
    }

    if (this.applyEachFilter) {
      this.checkFilterIsAlreadyApplied(optionKey);
    }

    this.checkIfSelectedFilterIsAlreadyApplied();
    if (this.applyEachFilter) {
      this.checkFilterIsAlreadyApplied(optionKey);
    }
    this.recalculateOptionsScroll(false);
  }

  applyFilters() {
    this.changeFilterEvent.emit(null);
  }

  applyFilter({ optionKey, options }) {
    this.changeFilterEvent.emit({ optionKey, options });
    this.checkFilterIsAlreadyApplied(optionKey);
  }

  private invalidateOptionsForKey(optionKey) {
    this.filterOptions[optionKey].forEach((value: IFilterOption) => {
      value.active = false;
    });
  }

  public clearFilter(optionKey, emitEvent: boolean = true) {
    if (!(optionKey in this.filterOptions)) {
      return;
    }
    this.filterOptions[optionKey].forEach((opt) => {
      if (opt.active) {
        opt.active = false;
      }
    });
    this.activeOptions[optionKey] = [];
    this.filtersAreEmpty = this.checkFiltersAreEmpty(this.activeOptions, this.searchTerm);

    if (this.applyEachFilter) {
      this.checkFilterIsAlreadyApplied(optionKey);
      if (this.filterDifference[optionKey]) {
        this.applyFilter({ optionKey, options: [] });
      }
    }

    if (emitEvent && this.showButtonToApplyAllFilters) {
      this.clearFilterEvent.emit(optionKey);
    }

    if (emitEvent && !this.applyEachFilter && !this.showButtonToApplyAllFilters) {
      this.clearFilterEvent.emit(optionKey);
    }

    this.resetScrollOnFilterTags();
    this.checkIfSelectedFilterIsAlreadyApplied();
    this.recalculateOptionsScroll(true);
    if (this.applyEachFilter) {
      this.checkFilterIsAlreadyApplied(optionKey);
    }
  }

  private resetScrollOnFilterTags(): void {
    if (check.not.assigned(this.filtersList?.nativeElement)) {
      return;
    }

    this.filtersList.nativeElement.scrollLeft = 0;

    if (this.filtersList.nativeElement.scrollWidth < this.filtersList.nativeElement.clientWidth) {
      return;
    }

    this.showLeftArrow = false;
    this.showRightArrow = true;
  }

  public scrollFilters(direction: number): void {
    if (check.not.assigned(this.filtersList) || direction === 0) {
      return;
    }

    Promise.resolve()
      .then(() => {
        const newScrollLeft = this.filtersList.nativeElement.scrollLeft + direction * 150;
        return Promise.resolve(newScrollLeft);
      })
      .then((newScrollLeft) => {
        this.filtersList.nativeElement.scrollLeft = newScrollLeft;

        if (!this.showLeftArrow && this.showRightArrow) {
          this.showLeftArrow = newScrollLeft >= 0;
          this.showRightArrow =
            this.filtersList.nativeElement.scrollWidth - (this.filtersList.nativeElement.clientWidth + newScrollLeft) > 0;
          return;
        }

        this.showLeftArrow = newScrollLeft > 34;
        this.showRightArrow =
          this.filtersList.nativeElement.scrollWidth - (this.filtersList.nativeElement.clientWidth + newScrollLeft) > 34;
      });
  }

  public scrollOptions(direction: number): void {
    if (check.not.assigned(this.optionsList) || direction === 0) {
      return;
    }

    Promise.resolve()
      .then(() => {
        const newScrollLeft = this.optionsList.nativeElement.scrollLeft + direction * 150;
        return Promise.resolve(newScrollLeft);
      })
      .then((newScrollLeft) => {
        this.optionsList.nativeElement.scrollLeft = newScrollLeft;

        if (!this.showOptionsLeftArrow && this.showOptionsRightArrow) {
          this.showOptionsLeftArrow = newScrollLeft >= 0;
          this.showOptionsRightArrow =
            this.optionsList.nativeElement.scrollWidth - (this.optionsList.nativeElement.clientWidth + newScrollLeft) > 0;
          return;
        }

        this.showOptionsLeftArrow = newScrollLeft > 34;
        this.showOptionsRightArrow =
          this.optionsList.nativeElement.scrollWidth - (this.optionsList.nativeElement.clientWidth + newScrollLeft) > 34;
      });
  }

  private resetOptionsScroll(): void {
    if (check.not.assigned(this.optionsList?.nativeElement)) {
      return;
    }
    this.optionsList.nativeElement.scrollLeft = 0;
    if (this.optionsList.nativeElement.scrollWidth < this.optionsList.nativeElement.clientWidth) {
      return;
    }
    this.showOptionsLeftArrow = false;
    this.showOptionsRightArrow = true;
  }

  private recalculateOptionsScroll(isClear: boolean): void {
    if (check.not.assigned(this.optionsList?.nativeElement)) {
      return;
    }
    if (this.optionsList.nativeElement.scrollWidth < this.optionsList.nativeElement.clientWidth) {
      this.showOptionsLeftArrow = false;
      this.showOptionsRightArrow = false;
      return;
    }
    if (this.optionsList.nativeElement.scrollLeft > 0) {
      this.showOptionsLeftArrow = true;
    } else {
      this.showOptionsLeftArrow = false;
    }
    const offset = isClear ? -this.CLEAR_BUTTON_WIDTH : this.CLEAR_BUTTON_WIDTH;
    if (
      this.optionsList.nativeElement.clientWidth + this.optionsList.nativeElement.scrollLeft <
      this.optionsList.nativeElement.scrollWidth + offset
    ) {
      this.showOptionsRightArrow = true;
    } else {
      this.showOptionsRightArrow = false;
    }
  }

  emitSearchTermChanged(searchTerm: string) {
    this.searchTermChanged.emit(searchTerm);
  }

  public clearAllFilters() {
    for (const optionKey of Object.keys(this.filterOptions)) {
      this.clearFilter(optionKey, false);
    }

    this.isSearchBarExpanded = false;
    this.filtersAreEmpty = true;
    this.filterBarFields.forEach((iFilterBarFields) => {
      if (iFilterBarFields.pickerType === 'single' && check.assigned(iFilterBarFields.radioButtons)) {
        iFilterBarFields.radioButtons.forEach((iButton) => {
          iButton.checked = false;
        });
      }
    });
    this.clearAllFiltersEvent.emit();
    this.checkIfSelectedFilterIsAlreadyApplied();

    if (!this.applyFiltersImmediately && this.filtersDifference && !this.applyEachFilter) {
      this.applyFilters();
    }

    if (this.applyEachFilter) {
      Object.keys(this.filterDifference).forEach((optionKey) => {
        this.checkFilterIsAlreadyApplied(optionKey);
      });
    }
  }

  private computeActiveOptions(options: Array<IFilterOption>) {
    return options.filter(({ active }) => active);
  }

  private checkFiltersAreEmpty(activeFilters: IFilterOptions, searchTerm: string): boolean {
    let filtersEmpty = true;
    if (check.assigned(activeFilters)) {
      const filterArrays = Object.values(activeFilters);
      filtersEmpty = filterArrays.length === 0 || filterArrays.every((optionArray) => optionArray.length === 0);
    }

    const searchEmpty = searchTerm === undefined || searchTerm === '' || searchTerm.length < 3;
    return filtersEmpty && searchEmpty;
  }

  private emitChangeFilterEvent(field, optionIndex): void {
    const option = this.filterOptions[field][optionIndex];
    const { _id: value, active } = option;
    this.changeFilterEvent.emit({ value, active, field });
  }

  public expandSearcher(): void {
    this.isSearchBarExpanded = true;
    this.resetOptionsScroll();

    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);
  }

  public checkIfSelectedFilterIsAlreadyApplied() {
    this.filtersDifference = !_.isEqual(this.filtersApplied, this.activeOptions);
  }

  public checkFilterIsAlreadyApplied(optionKey) {
    this.filterDifference[optionKey] = !_.isEqual(this.filtersApplied[optionKey], this.activeOptions[optionKey]);
  }
}

export interface IFilterOptions {
  [key: string]: Array<IFilterOption>;
}

export interface IFilterOption {
  name: string;
  // this is actually a 'value', it could be anything you want to filter by, not just an _id
  _id: any;
  photoUrl?: string;
  active: boolean;
}

export interface IFilterBarTranslations {
  companiesFilterLabel?: string;
  officesFilterLabel?: string;
  areasFilterLabel?: string;
  departmentsFilterLabel?: string;
  teamsFilterLabel?: string;
  managersFilterLabel?: string;
  searchForEmployeesLabel?: string;
  noMatchingEmployeesLabel?: string;
  searchLabel?: string;
  resetFilters?: string;
  applyFilters?: string;
  noFiltersYet?: string;
  clearFilters?: string;
  applyFilterButton?: string;
}

export interface IFilterField {
  key: string;
  label: string;
  options: Array<IFilterOption>;
  showAvatar?: boolean;
  pickerType: 'single' | 'multiple';
}
