import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PayrollExportService } from '@app/standard/pages/people/payroll/services/payroll-export.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { PayrollController } from '@app/standard/services/payroll/controllers/payroll.controller';
import { PAYROLL_STATUS_CLOSED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class PayrollService {
  private PAYROLL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/payroll-db`;

  constructor(private injector: Injector) {}

  async confirmPayroll(payrollGroupData, payrollGroupSetting): Promise<void> {
    try {
      // 1. Generate and store payroll export in S3
      const exportFileMetadata = await this.injector.get(PayrollExportService).generateAndStoreExportInS3(payrollGroupData, payrollGroupSetting, false);

      // 2. Create payroll confirmation snapshot
      const contentData = { ...payrollGroupData };
      contentData.status = PAYROLL_STATUS_CLOSED;
      delete contentData.exports;
      delete contentData.corrections;

      const confirmPayrollBody = {
        _content: contentData,
        _payrollSettingsId: payrollGroupData.payrollGroupId,
        _fromDate: payrollGroupData.fromDate,
        _toDate: payrollGroupData.toDate,
        _isCorrection: false,
        _exportedFile: exportFileMetadata
      };

      await this.injector.get(HttpClient).post<Array<any>>(`${this.PAYROLL_URL}`, confirmPayrollBody).toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollService.name, 'confirmPayroll');
      throw error;
    }
  }

  async checkForCorrections(payrollGroupData, payrollGroupSetting): Promise<void> {
    try {
      // 1. Call controller to get corrections
      const correctionPayrollData = await this.injector.get(PayrollController).checkForCorrections(payrollGroupData.payrollGroupId, payrollGroupData.fromDate, payrollGroupData.toDate, payrollGroupData);

      // 2. Generate and store payroll export in S3
      const exportFileMetadata = await this.injector.get(PayrollExportService).generateAndStoreExportInS3(correctionPayrollData, payrollGroupSetting, true);

      // 3. Check if a previous correction was generated
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const findQuery = {
        _fromDate: correctionPayrollData.fromDate,
        _toDate: correctionPayrollData.toDate,
        _isCorrection: true,
        _payrollSettingsId: payrollGroupSetting._id
      };

      const previousCorrections = await this.injector.get(HttpClient).post<Array<any>>(`${this.PAYROLL_URL}/find`, findQuery, httpOptions).toPromise();

      // 4. Create payroll correction snapshot
      const contentData = { ...correctionPayrollData };
      contentData.status = PAYROLL_STATUS_CLOSED;
      delete contentData.exports;
      delete contentData.corrections;

      const checkForCorrectionsBody = {
        _content: contentData,
        _payrollSettingsId: correctionPayrollData.payrollGroupId,
        _fromDate: correctionPayrollData.fromDate,
        _toDate: correctionPayrollData.toDate,
        _isCorrection: true,
        _exportedFile: exportFileMetadata
      };

      // 5. If a previous snapshot exists, we remove it
      if (check.assigned(previousCorrections) && check.nonEmptyArray(previousCorrections)) {
        const correctionsToRemovePromises = previousCorrections.map((iCorrection) => this.injector.get(HttpClient).delete<Array<any>>(`${this.PAYROLL_URL}/${iCorrection._id}`, httpOptions).toPromise());
        await Promise.all(correctionsToRemovePromises);
      }

      // 6. Creating the new correction snapshot
      await this.injector.get(HttpClient).post<Array<any>>(`${this.PAYROLL_URL}/`, checkForCorrectionsBody, httpOptions).toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollService.name, 'checkForCorrections');
      throw error;
    }
  }
}
