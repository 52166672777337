import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { DefaultPaywallDialog } from '@app/standard/components/feature-gating/dialogs/default-paywall-dialog/default-paywall.dialog';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { ENTITLEMENTS_LIST } from '@carlos-orgos/orgos-utils/constants/feature-gating.constants';
import { environment } from '@env';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FeatureGatingService {
  private FEATURE_GATING_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/organization-db/organizations`;
  private CACHE_TTL_IN_SECS: number = 1800; // 30 min
  private entitlementCache: IEntitlementMap;
  private cacheDate: Date;

  constructor(private injector: Injector) {}

  /**
   * @description use this to validate an entitlement to show the icon of entitlement based on the cached data
   * @param entitlementKey a valid entitlementKey, one of ENTITLEMENTS_LIST
   * @return Promise<boolean> true if the entitlement is enabled, false otherwise
   */
  async validateEntitlementFromCache(entitlementKey: ENTITLEMENTS_LIST): Promise<boolean> {
    try {
      const entitlementsMap: IEntitlementMap = await this.getEntitlementMapFromCache();
      if (entitlementsMap && entitlementsMap[entitlementKey]?.isEnabled) {
        return true;
      }
      return false;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FeatureGatingService.name, 'validateEntitlementFromCache');
    }
  }

  /**
   * @description it will return the entitlements based on a cached result, which will be refreshed every CACHE_TTL_IN_SECS
   * @return Promise<IEntitlementMap> with the entitlements available
   */
  private async getEntitlementMapFromCache(): Promise<IEntitlementMap> {
    try {
      if (
        this.entitlementCache &&
        this.cacheDate &&
        moment().isSameOrBefore(moment(this.cacheDate).add(this.CACHE_TTL_IN_SECS, 'seconds'))
      ) {
        return this.entitlementCache;
      }

      await this.getEntitlements();
      return this.entitlementCache;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FeatureGatingService.name, 'getEntitlementMapFromCache');
    }
  }

  /**
   * @description will evaluate if this organization has an entitlement to perform the required action.
   *            Note that this will query the entitlements on real time.
   *            If not, it will create an alert dependin on the paywallMode
   * @param entitlementKey a valid entitlementKey, one of ENTITLEMENTS_LIST
   * @param paywallMode the mode in which the front-end should react to a missing entitlement
   * @param paywallOptions the options to launch the paywall
   * @returns boolean, true if the entitlement is enabled, false if it is not
   */
  async requireEntitlement(
    entitlementKey: ENTITLEMENTS_LIST,
    paywallMode: 'dialog' = 'dialog',
    paywallOptions?: IPaywallOptions
  ): Promise<boolean> {
    try {
      const isEntitled = await this.isEntitlementEnabled(entitlementKey);
      if (isEntitled) {
        return true;
      }

      if (paywallMode === 'dialog') {
        if (!paywallOptions) {
          paywallOptions = {};
        }
        if (!paywallOptions.featureId) {
          paywallOptions.featureId = entitlementKey;
        }
        this.injector.get(MatLegacyDialog).open(DefaultPaywallDialog, { data: paywallOptions });
      }
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('feature gating blocks access', { category: 'Feature Gating', subcategory1: paywallOptions?.featureId });
      return false;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FeatureGatingService.name, 'requireEntitlement');
    }
  }

  // Will return true if the entitlement is valid, false otherwise
  private async isEntitlementEnabled(entitlementKey): Promise<boolean> {
    try {
      const entitlementMap: IEntitlementMap = await this.getEntitlements();
      if (entitlementMap[entitlementKey]?.isEnabled) {
        return true;
      }
      return false;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FeatureGatingService.name, 'getEntitlements');
    }
  }

  private async getEntitlements(): Promise<IEntitlementMap> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      const entitlementMap: IEntitlementMap = await this.injector
        .get(HttpClient)
        .get<IEntitlementMap>(`${this.FEATURE_GATING_URL}/entitlements`, httpOptions)
        .toPromise();
      this.setCachedData(entitlementMap);
      return entitlementMap;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FeatureGatingService.name, 'getEntitlements');
    }
  }

  private setCachedData(entitlementMap: IEntitlementMap): void {
    this.entitlementCache = entitlementMap;
    this.cacheDate = new Date();
  }
}

export interface IEntitlementModel {
  featureId?: string;
  isEnabled?: boolean;
  accessExpirationDate?: Date;
  numberOfCredits?: number;
}

export interface IEntitlementMap {
  [entitlementKey: string]: IEntitlementModel;
}

export interface IPaywallOptions {
  featureId?: ENTITLEMENTS_LIST;
}
