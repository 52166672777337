import { Component, Injector, Input, OnInit } from '@angular/core';

import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-pulse-participation',
  templateUrl: 'pulse-participation.component.html',
  styleUrls: ['pulse-participation.component.scss']
})
export class PulseParticipationComponent implements OnInit {
  @Input() pageTranslation: any = {};
  @Input() participation: any; // {percentage, responded, total}

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('pulse-participation-component')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }
}
