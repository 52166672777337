import { DecimalPipe } from '@angular/common';
import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Pipe({
  name: 'formattedValue',
  pure: false
})

/** 
    Pipe to use in tables or reports. Get the value passed as param and return a number (or date) in the format of the locale selected by the user
**/
export class FormattedValuePipe implements PipeTransform {
  private cachedUser: any;
  constructor(private injector: Injector) {}

  transform(value: any): any {
    if (check.not.assigned(value)) {
      return;
    }

    if (check.not.assigned(this.cachedUser)) {
      this.cachedUser = this.injector.get(AuthenticationService).getLoggedUser();
    }

    if (!isNaN(value) && !isNaN(Number(value) - parseFloat(value))) {
      return this.injector.get(DecimalPipe).transform(value, '1.0-2', 'kenjo-locale');
    } else if ((value.indexOf('-') !== -1 || value.indexOf('/') !== -1) && moment(value, true).isValid()) {
      return moment.utc(value).locale(this.cachedUser.language).format('LLLL');
    }

    return value;
  }
}
