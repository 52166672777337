import { Component } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateUppyService } from '@app/private/services/private-uppy.service';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { DocumentTemplateService, IDocumentTemplateCreateModel, IDocumentTemplateModel } from '@app/standard/services/document/document-template.service';
import { environment } from '@env';
import { UppyOptions } from '@uppy/core';
import * as check from 'check-types';

@Component({
  selector: 'orgos-documents-templates',
  templateUrl: 'documents-templates.page.html',
  styleUrls: ['documents-templates.page.scss']
})
export class DocumentsTemplatesPage extends GenericPage {
  translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'documents-misc' },
    { name: 'page', translationKey: 'documents-templates-page' }
  ];

  allTemplates: Array<IDocumentTemplateModel> = [];

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.injector
      .get(DocumentTemplateService)
      .getAllTemplates()
      .then((allTemplates: Array<IDocumentTemplateModel>) => {
        this.allTemplates = allTemplates.reverse();

        resolveFetchData();
      })
      .catch(() => {
        // An error is already shown
        this.allTemplates = [];

        rejectFetchData();
      });
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    let selectedOption = 3;

    const options: Array<IMenuOption> = [
      { name: this.i18n.misc.myDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/my-docs') },
      { name: this.i18n.misc.companyDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/company-docs') }
    ];

    if (this.injector.get(CloudRoutesService).checkRoute('documents/employee-docs') === true) {
      options.push({ name: this.i18n.misc.employeeDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/employee-docs') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/digital-signature') === true) {
      selectedOption = 4;
      options.push({ name: this.i18n.misc.digitalSignatureTab, onClick: () => this.router.navigateByUrl('/cloud/documents/digital-signature') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/templates') === true) {
      options.push({ name: this.i18n.misc.templatesTab, onClick: () => this.router.navigateByUrl('/cloud/documents/templates') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/import') === true) {
      options.push({ name: this.i18n.misc.importTab, onClick: () => this.router.navigateByUrl('/cloud/documents/import') });
    }

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = selectedOption;

    return Promise.resolve();
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view smart docs page', { category: 'Navigation', type: 'templates' });
    return Promise.resolve();
  }

  public async uploadTemplate() {
    const pickerOptions: UppyOptions = {
      allowMultipleUploadBatches: false,
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ['.docx'],
        maxFileSize: parseInt(environment.MAX_SIZE_FOR_DOCUMENTS, 10) * 1024 * 1024,
        maxNumberOfFiles: 1
      }
    };

    try {
      const uploadedFile = await this.injector.get(PrivateUppyService).picker(pickerOptions, false);

      if (check.not.assigned(uploadedFile)) {
        return Promise.resolve(null);
      }

      const rawTemplate: IDocumentTemplateCreateModel = {
        _file: uploadedFile,
        name: uploadedFile._fileName,
        setupDone: false
      };

      await this.injector.get(DocumentTemplateService).create(rawTemplate);

      this.refreshData();
    } catch (error) {
      // An error is already shown
      this.refreshData();
    }
  }
}
