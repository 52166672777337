import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import {
  REPEAT_REQUEST_CONFLICT_TYPE_NON_WORKING_TIME,
  REPEAT_REQUEST_CONFLICT_TYPE_PUBLIC_HOLIDAY,
  REPEAT_REQUEST_CONFLICT_TYPE_REQUEST_OVERLAP,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-repeat-request-conflict',
  templateUrl: 'repeat-request-conflict-info.component.html',
  styleUrls: ['repeat-request-conflict-info.component.scss'],
})
export class RepeatRequestConflictInfo implements OnInit, OnChanges {
  @Input() repeatConflicts: IRepeatConflict[];
  @Input() translations: any;

  REPEAT_REQUEST_CONFLICT_TYPE_NON_WORKING_TIME = REPEAT_REQUEST_CONFLICT_TYPE_NON_WORKING_TIME;
  REPEAT_REQUEST_CONFLICT_TYPE_PUBLIC_HOLIDAY = REPEAT_REQUEST_CONFLICT_TYPE_PUBLIC_HOLIDAY;
  REPEAT_REQUEST_CONFLICT_TYPE_REQUEST_OVERLAP = REPEAT_REQUEST_CONFLICT_TYPE_REQUEST_OVERLAP;

  showFull = false;

  constructor(private injector: Injector, private datePipe: DatePipe) {}

  showMore() {
    this.showFull = !this.showFull;
  }

  ngOnChanges() {
    this.updateConflictTranslations();
  }

  ngOnInit() {
    this.updateConflictTranslations();
  }

  updateConflictTranslations() {
    this.repeatConflicts.forEach((conflict) => {
      const dates = conflict.dates
        .map(({ from, to, date }) => {
          if (check.assigned(date)) {
            return this.datePipe.transform(date, 'shortDate', 'UTC');
          }
          return this.injector
            .get(I18nDataPipe)
            .transform(this.translations.multiDayConflict, {
              from: this.datePipe.transform(from, 'shortDate', 'UTC'),
              to: this.datePipe.transform(to, 'shortDate', 'UTC'),
            });
        })
        .join(', ');
      conflict.translatedDates = dates;
    });
  }
}

export interface IRepeatConflict {
  conflictType: REPEAT_REQUEST_CONFLICT_TYPE_NON_WORKING_TIME | REPEAT_REQUEST_CONFLICT_TYPE_PUBLIC_HOLIDAY | REPEAT_REQUEST_CONFLICT_TYPE_REQUEST_OVERLAP;
  label?: string;
  dates: [{ date?: Date; from?: Date; to?: Date }];
  translatedDates?: string;
}
