import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PrivateSignInService } from '@app/private/services/private-sign-in.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ACCESS_TYPE_PASSWORD_EXPIRED_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordPolicyGuard implements CanActivate {
  constructor(private injector: Injector) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      if (!this.injector.get(AuthenticationService).isUserAuthenticated()) {
        await this.injector.get(PrivateSignInService).signInWithCookie();
      }

      if (this.injector.get(AuthenticationService).getAccessType() !== ACCESS_TYPE_PASSWORD_EXPIRED_ACCESS) {
        return false;
      }

      return true;
    } catch {
      // An error is already shown if necessary
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }
  }
}
