export const REST_OF_WORLD = 'rest';
/**
 * Later more testimonials might be added. To add more sets, because we want to distinguish between countries of the same language,
 * we will get the country as well and use it as key for COUNTRY_TO_MARKETING_REGION, and the value is the region
 */
export const COUNTRY_TO_MARKETING_REGION = {
  // DACH
  de: 'dach',
  at: 'dach',
  ch: 'dach',
  li: 'dach',
  // ES
  es: 'es',
  // LATAM
  ag: 'latam',
  ar: 'latam',
  aw: 'latam',
  bb: 'latam',
  bl: 'latam',
  bo: 'latam',
  br: 'latam',
  bs: 'latam',
  bz: 'latam',
  cl: 'latam',
  co: 'latam',
  cr: 'latam',
  cu: 'latam',
  dm: 'latam',
  do: 'latam',
  ec: 'latam',
  fk: 'latam',
  gd: 'latam',
  gf: 'latam',
  gp: 'latam',
  gt: 'latam',
  gy: 'latam',
  hn: 'latam',
  ht: 'latam',
  jm: 'latam',
  kn: 'latam',
  ky: 'latam',
  lc: 'latam',
  mq: 'latam',
  mx: 'latam',
  ni: 'latam',
  pa: 'latam',
  pe: 'latam',
  pr: 'latam',
  py: 'latam',
  sr: 'latam',
  sv: 'latam',
  tc: 'latam',
  tt: 'latam',
  uy: 'latam',
  vc: 'latam',
  ve: 'latam',
  vg: 'latam',
  vi: 'latam'
};
