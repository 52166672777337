import { Component, Input } from '@angular/core';

import { IDocumentTemplateModel } from '../../../../services/document/document-template.service';

@Component({
  selector: 'orgos-document-template-card',
  templateUrl: 'document-template-card.component.html',
  styleUrls: ['document-template-card.component.scss']
})
export class DocumentTemplateCardComponent {
  private _template: IDocumentTemplateModel;
  @Input()
  set template(template: IDocumentTemplateModel) {
    this._template = template;
  }
  get template(): IDocumentTemplateModel {
    return this._template;
  }
}
