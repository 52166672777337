import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CustomFieldService } from '@app/standard/services/custom-field/custom-field.service';
import { WorkflowService } from '@app/standard/services/workflow/workflow.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { GenericCacheModel } from '../../core/generic-cache-model';
import { GlobalBarService } from '../../services/core/global-bar.service';
import { SettingsBarService } from '../../services/settings/settings-bar.service';

@Component({
  selector: 'orgos-settings-workflows-page',
  templateUrl: 'settings-workflows.page.html',
  styleUrls: ['settings-workflows.page.scss'],
})
export class SettingsWorkflowsPage implements OnInit {
  pageTranslation: any;
  miscTranslation: any = {};
  editWorkflowTranslation: any = {};
  settingsBarTranslation: any = {};
  listWorkflows: Array<any>;
  chosenWorkflow: GenericCacheModel;
  chosenWorkflowActions: GenericCacheModel;
  objectTranslation: any = {};
  displayedColumns: Array<string> = ['name', 'inCollection', 'isActive', 'actions'];
  createNewWorkflow: boolean = false;
  customFieldsAvailable: Array<any>;

  sortingDataAccessor: (row: any, sortHeaderId: string) => string | number = (row: any, sortHeaderId: string): string | number => {
    const value: any = row[sortHeaderId];
    if (sortHeaderId === 'inCollection') {
      return this.getLabel(row.inCollection).toLowerCase();
    }

    if (isNaN(+value) === false && check.nonEmptyString(value)) {
      return +value;
    }

    const isoDate = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true);
    if (isoDate.isValid()) {
      return isoDate.valueOf();
    }

    const localizedShortDate = moment.utc(value, 'DD-MM-YYYY', true);

    if (localizedShortDate.isValid()) {
      return localizedShortDate.valueOf();
    }

    const localizedShortDateUsa = moment.utc(value, 'MM-DD-YYYY', true);
    if (localizedShortDateUsa.isValid()) {
      return localizedShortDateUsa.valueOf();
    }

    // If the value is a string and only whitespace, return the value.
    // Otherwise +value will convert it to 0.
    if (typeof value === 'string' && check.emptyString(value.trim())) {
      return value;
    }

    if (check.not.assigned(value)) {
      return '';
    }

    return value.toString().toLowerCase();
  };

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    public dialog: MatLegacyDialog,
    public snackBar: MatLegacySnackBar
  ) {}

  ngOnInit(): void {
    this.setPageName();
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('workflow-collection')
      .then((objectTranslation) => {
        this.objectTranslation = objectTranslation;
      })
      .catch(() => {
        this.objectTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-workflow-page')
      .then((editWorkflowTranslation) => {
        this.editWorkflowTranslation = editWorkflowTranslation;
      })
      .catch(() => {
        this.editWorkflowTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-top-bar')
      .then((pageTranslation: any) => {
        this.settingsBarTranslation = pageTranslation;
        return this.injector.get(SettingsBarService).getOptions(this.settingsBarTranslation);
      })
      .then((options) => {
        const optionIndex = _.findIndex(options, ['name', this.settingsBarTranslation.workflowsTab]);

        this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
        this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(optionIndex);
      })
      .catch(() => {
        // An error is already shown
        this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.fetchData();
    this.injector.get(PrivateAmplitudeService).logEvent('view settings page', { category: 'Navigation', type: 'workflows' });
  }

  setPageName(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-workflows-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  private fetchData(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.injector
      .get(WorkflowService)
      .getAllWorkflows()
      .then((workflows) => {
        this.listWorkflows = workflows;
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch((error) => {
        this.injector.get(GlobalBarService).setProgressBar(false);
        this.listWorkflows = null;
      });

    this.initCustomFieldsAvailable();
  }

  private initCustomFieldsAvailable(): void {
    this.injector
      .get(CustomFieldService)
      .find({ _id: { $ne: null } })
      .then((customFields) => {
        if (check.not.assigned(customFields) || check.not.array(customFields)) {
          this.customFieldsAvailable = [];
        }

        this.customFieldsAvailable = customFields;
      })
      .catch((err) => {
        this.customFieldsAvailable = [];
      });
  }

  createWorkflow(): void {
    this.createNewWorkflow = true;
    this.cdr.detectChanges();
  }

  editWorkflow(workflowDocument: any): void {
    const clonedWF = Object.assign({}, workflowDocument);
    this.chosenWorkflow = new GenericCacheModel(this.injector, clonedWF, WorkflowService);
    this.cdr.detectChanges();
  }

  editWorkflowActions(workflowDocument: any): void {
    this.chosenWorkflowActions = new GenericCacheModel(this.injector, workflowDocument, WorkflowService);
    this.cdr.detectChanges();
  }

  activateWorkflow(workflowDocument: any): void {
    if (workflowDocument.isActive === true) {
      this.injector
        .get(WorkflowService)
        .updateById(workflowDocument[fieldConstants.ID], { isActive: true })
        .then(() => {
          this.snackBar.open(`${this.pageTranslation.workflowActivatedSnackbarMessage}`, 'OK', { duration: 5000 });
        })
        .catch((error) => {
          // an error is already shown
        });
    } else if (workflowDocument.isActive === false) {
      this.injector
        .get(WorkflowService)
        .updateById(workflowDocument[fieldConstants.ID], { isActive: false })
        .then(() => {
          this.snackBar.open(`${this.pageTranslation.workflowDeactivatedSnackbarMessage}`, 'OK', { duration: 5000 });
        })
        .catch((error) => {
          // an error is already shown
        });
    }
  }

  deleteWorkflow(workflowDocument: any): void {
    const data = {
      titleText: this.pageTranslation.confirmDeleteTitle,
      subtitleText: this.pageTranslation.confirmDeleteSubtitle,
      confirmButtonText: this.pageTranslation.confirmDeleteButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector
          .get(WorkflowService)
          .deleteById(workflowDocument[fieldConstants.ID])
          .then(() => {
            this.fetchData();
            this.snackBar.open(`${this.pageTranslation.workflowDeletedSnackbarMessage}`, 'OK', { duration: 5000 });
          })
          .catch((error) => {
            // an error is already shown
          });
      }
    });
  }

  addActionsToNewWorkflow(workflowCreated: GenericCacheModel): void {
    this.resetChosenWorkflow();
    this.editWorkflowActions(workflowCreated.data);
  }

  resetChosenWorkflow(): void {
    this.fetchData();
    this.createNewWorkflow = false;
    this.chosenWorkflow = null;
    this.setPageName();
  }

  resetChosenWorkflowActions(): void {
    this.createNewWorkflow = false;
    this.chosenWorkflowActions = null;
    this.setPageName();
  }

  getLabel(inCollection: string): string {
    if (inCollection.startsWith('user-')) {
      return this.editWorkflowTranslation['userCollection'];
    }
    return this.editWorkflowTranslation[`${inCollection}Collection`]
      ? this.editWorkflowTranslation[`${inCollection}Collection`]
      : inCollection;
  }
}
