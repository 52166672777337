import { Component, Input } from '@angular/core';
import {
  WORK_SCHEDULE_STATUS_BANK_HOLIDAY,
  WORK_SCHEDULE_STATUS_CHECKED_IN,
  WORK_SCHEDULE_STATUS_LATE_CHECK_IN,
  WORK_SCHEDULE_STATUS_NON_WORKING_DAY,
  WORK_SCHEDULE_STATUS_NOT_CHECKED,
  WORK_SCHEDULE_STATUS_TIME_OFF,
  WORK_SCHEDULE_TEMPLATE_TYPE_FIXED,
  WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE,
  WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Component({
  selector: 'kenjo-presence-validation-card',
  templateUrl: 'presence-validation-card.component.html',
  styleUrls: ['presence-validation-card.component.scss']
})
export class PresenceValidationCardComponent {
  WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE: string = WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE;
  WORK_SCHEDULE_TEMPLATE_TYPE_FIXED: string = WORK_SCHEDULE_TEMPLATE_TYPE_FIXED;
  WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD: string = WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD;
  WORK_SCHEDULE_STATUS_NON_WORKING_DAY: string = WORK_SCHEDULE_STATUS_NON_WORKING_DAY;
  WORK_SCHEDULE_STATUS_BANK_HOLIDAY: string = WORK_SCHEDULE_STATUS_BANK_HOLIDAY;
  WORK_SCHEDULE_STATUS_CHECKED_IN: string = WORK_SCHEDULE_STATUS_CHECKED_IN;
  WORK_SCHEDULE_STATUS_LATE_CHECK_IN: string = WORK_SCHEDULE_STATUS_LATE_CHECK_IN;
  WORK_SCHEDULE_STATUS_NOT_CHECKED: string = WORK_SCHEDULE_STATUS_NOT_CHECKED;
  WORK_SCHEDULE_STATUS_TIME_OFF: string = WORK_SCHEDULE_STATUS_TIME_OFF;
  componentLoaded: boolean = false;

  @Input() pageTranslation: any;
  @Input() userValidation: any;
}
