import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ParamMap } from '@angular/router';
import {
  IPayrollSurchargeRule,
  PayrollSurchargeRulesService,
} from '@app/cloud-features/settings-surcharges/services/payroll-surcharge-rules.service';
import { PayrollSurchargeUtilsService } from '@app/cloud-features/settings-surcharges/services/payroll-surcharge-utils.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ISelectOption } from '@app/standard/core/select-option';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  IUserEmploymentContractTypeModel,
  UserEmploymentContractTypeService,
} from '@app/standard/services/user/user-employment-contract-type.service';
import {
  IUserEmploymentSubcategoryModel,
  UserEmploymentSubcategoryService,
} from '@app/standard/services/user/user-employment-subcategory.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUserPersonalModel } from '../../../../models/user-personal.model';
import { IUserWorkModel } from '../../../../models/user-work.model';
import { PrivateSecurityService } from '../../../../private/services/private-security.service';
import { CompanyService } from '../../../services/company/company.service';
import { AuthenticationService } from '../../../services/core/authentication.service';
import { CloudRoutesService } from '../../../services/core/cloud-routes.service';
import { UserEmploymentService } from '../../../services/user/user-employment.service';
import { UserPersonalService } from '../../../services/user/user-personal.service';
import { UserSalaryService } from '../../../services/user/user-salary.service';
import { UserVariablePayService } from '../../../services/user/user-variable-pay.service';
import { IUserWorkScheduleModel, UserWorkScheduleService } from '../../../services/user/user-work-schedule.service';
import { UserWorkService } from '../../../services/user/user-work.service';
import { VariablePayTypeService } from '../../../services/variable-pay-type/variable-pay-type.service';
import { GenericPage, IMenuOption, ITranslationResource } from '../../generic.page';
import { AddEmploymentDialog } from './dialogs/add-employment.dialog';
import { AddSalaryDialog } from './dialogs/add-salary.dialog';
import { AddVariablePayDialog } from './dialogs/add-variable-pay.dialog';
import { EditEmploymentDialog } from './dialogs/edit-employment.dialog';
import { EditSalaryDialog } from './dialogs/edit-salary.dialog';

@Component({
  selector: 'orgos-people-detail-compensation-page',
  templateUrl: 'people-detail-compensation.page.html',
  styleUrls: ['people-detail-compensation.page.scss'],
})
export class PeopleDetailCompensationPage extends GenericPage {
  id: string;

  protected profilePermissionsResources: Array<string> = ['user-compensation', 'payroll-feature', 'employees'];
  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'people-detail-compensation-page' },
    { name: 'misc', translationKey: 'people-detail-misc' },
    { name: 'userEmployment', translationKey: 'user-employment-collection' },
    { name: 'userSalary', translationKey: 'user-salary-collection' },
    { name: 'userVariablePay', translationKey: 'user-variable-pay-collection' },
    { name: 'standardPicklist', translationKey: 'standard-picklists' },
    { name: 'settingsPayroll', translationKey: 'settings-payroll-page' },
    { name: 'miscGlobal', translationKey: 'misc' },
  ];

  isNotMyUser: boolean;
  myPermissions: any = {};

  hasPermission: any = {
    createUserEmployment: false,
    editUserEmployment: false,
    deleteUserEmployment: false,
    createUserSalary: false,
    editUserSalary: false,
    deleteUserSalary: false,
    createUserVariablePay: false,
    editUserVariablePay: false,
    deleteUserVariablePay: false,
    createSurcharge: false,
    deleteSurcharge: false,
  };

  currentSalary: Array<any> = [];

  userEmployments: Array<any> = [];
  userSalaries: Array<any> = [];
  userVariablePay: Array<any> = [];
  payrollSettings: any;
  variablePayTypes: any = {};

  haveUserPersonal: boolean = false;
  userPersonal: IUserPersonalModel;
  userWork: IUserWorkModel = null;
  userWorkSchedule: IUserWorkScheduleModel = null;
  userCompany: any;
  mapCompanyIdToCompanyName: any = {};
  mapContractTypeIdToContractTypeName$: Observable<{ [id: string]: string }>;
  mapSubcategoryIdToContractTypeName$: Observable<{ [id: string]: string }>;
  companyNamesAndIds: any = [];
  defaultContractTypeTranslations: any;

  salarySurcharges: Array<IPayrollSurchargeRule>;
  availableSurcharges: Array<IPayrollSurchargeRule>;
  surchargeIdToConditionMessage: { [surchargeId: string]: string } = {};
  surchargeIdToSurchargeStatus: { [surchargeId: string]: 'SURCHARGE_STATUS_ACTIVE' | 'SURCHARGE_STATUS_PAST' | 'SURCHARGE_STATUS_FUTURE' };
  openAddSuchargeOverlay: boolean = false;
  autocompleteOptions: Array<ISelectOption> = [];
  addSurchargesTooltip: string;
  addSurchargesDisabled: boolean = false;
  isAttendanceEnabled: boolean = false;

  userManagePoliciesInfo: any;
  seeDetailsUserId: string;
  seeDetailsUserIdSubscription: Subscription;

  protected beforeInit(): Promise<void> {
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        this.id = id;
        this.refreshData()
          .then(() => {
            this.refreshLazyData();
            this.refreshGlobalBar();
            return;
          })
          .catch(() => {
            // An error is already shown
            this.router.navigate(['../'], { relativeTo: this.route });
            return;
          });
      });

    return Promise.resolve();
  }

  protected async configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    await this.injector.get(PeopleDetailService).onPeopleRouteLoaded(this.id);

    const options: Array<IMenuOption> = [
      {
        name: this.i18n.misc.publicProfileTab,
        onClick: () => {
          this.router.navigate(['../'], { relativeTo: this.route });
        },
      },
    ];

    const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
      options.push({
        name: this.i18n.misc.personalTab,
        onClick: () => {
          this.router.navigate(['../personal'], { relativeTo: this.route });
        },
      });
      options.push({ key: 'compensation-tab', name: this.i18n.misc.compensationTab, onClick: () => {} });
      options.push({
        name: this.i18n.misc.payrollTab,
        onClick: () => {
          this.router.navigate(['../payroll'], { relativeTo: this.route });
        },
      });
      if (
        this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') &&
        check.assigned(this.userWorkSchedule) &&
        check.assigned(this.userWorkSchedule.trackAttendance) &&
        this.userWorkSchedule.trackAttendance === true
      ) {
        options.splice(2, 0, {
          key: 'attendance-tab',
          name: this.i18n.misc.attendanceTab,
          onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
        });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance')) {
        options.push({
          name: this.i18n.misc.performanceTab,
          onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
        });
      }

      if (this.injector.get(PeopleDetailService).canSeeUsersSmartDocs()) {
        options.push({
          name: this.i18n.misc.smartDocsTab,
          onClick: () => this.injector.get(PeopleDetailService).openEmployeeDoc(this.id),
        });
      }

      if (this.injector.get(PeopleDetailService).canSeeUsersTimeOff()) {
        options.push({
          name: this.i18n.misc.timeOffTab,
          onClick: () => this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.id),
        });
      }

      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.compensationTab]);
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      let payrollPermissions;
      this.getPermissions()
        .then(() => {
          payrollPermissions = this.myPermissions['payroll-feature'];
          return this.injector.get(PeopleDetailService).getPermissionsToSeePersonalTab(this.myPermissions['employees'], this.id);
        })
        .then((canAccessPersonal: boolean) => {
          if (canAccessPersonal === true) {
            options.push({
              name: this.i18n.misc.personalTab,
              onClick: () => {
                this.router.navigate(['../personal'], { relativeTo: this.route });
              },
            });
          }
          return this.getPermissionsToSeeAttendanceTab(this.myPermissions['employees']);
        })
        .then((canAccessAttendance: boolean) => {
          if (canAccessAttendance === true) {
            options.splice(2, 0, {
              key: 'attendance-tab',
              name: this.i18n.misc.attendanceTab,
              onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
            });
          }
          return this.getPermissionsToSeePayrollTabs(payrollPermissions);
        })
        .then((canSeeCompensationTab: boolean) => {
          if (canSeeCompensationTab === true) {
            options.push({
              key: 'compensation-tab',
              name: this.i18n.misc.compensationTab,
              onClick: () => this.router.navigate(['../compensation'], { relativeTo: this.route }),
            });
            options.push({
              name: this.i18n.misc.payrollTab,
              onClick: () => this.router.navigate(['../payroll'], { relativeTo: this.route }),
            });
          }
          return this.getPermissionsToSeePerformanceTab(this.myPermissions['performance-feedback-results']);
        })
        .then((canSeePerformanceTab) => {
          if (canSeePerformanceTab === true) {
            options.push({
              name: this.i18n.misc.performanceTab,
              onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
            });
          }

          if (this.injector.get(PeopleDetailService).canSeeUsersSmartDocs()) {
            options.push({
              name: this.i18n.misc.smartDocsTab,
              onClick: () => this.injector.get(PeopleDetailService).openEmployeeDoc(this.id),
            });
          }

          if (this.injector.get(PeopleDetailService).canSeeUsersTimeOff()) {
            options.push({
              name: this.i18n.misc.timeOffTab,
              onClick: () => this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.id),
            });
          }

          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.compensationTab]);
          resolve();
        })
        .catch(() => {
          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.compensationTab]);
          resolve();
        });
    });
  }

  protected afterInit(): Promise<void> {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view employee page', { category: 'Navigation', platform: 'Web', subcategory1: 'compensation', subcategory2: this.id });
    return Promise.resolve();
  }

  public canEditCompensation(compensation: any, operation: string): boolean {
    // We check if startDate + 1 month is bigger than today to know if the user can edit
    if (check.not.assigned(compensation.startDate) && check.not.assigned(compensation.effectiveDate)) {
      return false;
    }

    const startDate = check.not.assigned(compensation.startDate) ? moment(compensation.effectiveDate) : moment(compensation.startDate);

    return (
      (this.canEditPastCompensation(compensation, operation) && startDate.isBefore(moment().add(1, 'month').startOf('month'), 'day')) ||
      (this.hasPermission[operation] && startDate.isSameOrAfter(moment().add(1, 'month').startOf('month'), 'day'))
    );
  }

  private canEditPastCompensations(): boolean {
    return (
      this.profilePermissions['payroll-feature'].c_editPastCompensation &&
      this.profilePermissions['payroll-feature'].c_editPastCompensation === true
    );
  }

  private canEditPastCompensation(compensation: any, operation: string): boolean {
    return (
      this.profilePermissions['payroll-feature'].c_editPastCompensation &&
      this.profilePermissions['payroll-feature'].c_editPastCompensation === true &&
      this.hasPermission[operation]
    );
  }

  private async calculatePermissions(): Promise<void> {
    this.checkPermissions('create');
    this.checkPermissions('delete');
    this.checkPermissions('edit');
    await this.checkAttendanceEnabled();
  }

  private async checkAttendanceEnabled(): Promise<void> {
    const attendanceStatus = await this.injector.get(CloudRoutesService).getAppStatus('attendance');
    this.isAttendanceEnabled = attendanceStatus?.isActive;
    if (!this.isAttendanceEnabled) {
      this.addSurchargesDisabled = true;
      this.addSurchargesTooltip = this.i18n.page.enableAttendanceToCreateSurchargesTooltip;
    }
  }

  private checkPermissions(permissionType: string): void {
    const permissions = this.profilePermissions['user-compensation'];
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

    if (permissions && permissions[`${permissionType}_all`] && permissions[`${permissionType}_all`] === true) {
      this.enablePermissions(permissionType);
    } else if (
      permissions &&
      permissions[`${permissionType}_own`] &&
      permissions[`${permissionType}_own`] === true &&
      this.id === loggedUser._id
    ) {
      this.enablePermissions(permissionType);
    } else {
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            permissions[`${permissionType}_custom`],
            permissions[`${permissionType}_own`],
            myUserWork,
            allUserWork,
            loggedUser,
            this.id
          );
        })
        .then((customPermissionResult) => {
          if (customPermissionResult === true) {
            this.enablePermissions(permissionType);
          }
        })
        .catch(() => {
          return false;
        });
    }
  }

  private enablePermissions(operation: string): void {
    if (operation === 'create') {
      this.hasPermission.createUserEmployment = true;
      this.hasPermission.createUserSalary = true;
      this.hasPermission.createUserVariablePay = true;
      this.hasPermission.createSurcharge = true;
    } else if (operation === 'delete') {
      this.hasPermission.deleteUserEmployment = true;
      this.hasPermission.deleteUserSalary = true;
      this.hasPermission.deleteUserVariablePay = true;
      this.hasPermission.deleteSurcharge = true;
    } else if (operation === 'edit') {
      this.hasPermission.editUserEmployment = true;
      this.hasPermission.editUserSalary = true;
      this.hasPermission.editUserVariablePay = true;
      this.hasPermission.createSurcharge = true;
    }
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    if (check.not.assigned(this.id) || check.emptyString(this.id)) {
      resolveFetchData();
      return;
    }

    await this.calculatePermissions();
    this.userEmployments = [];
    this.userSalaries = [];
    this.userVariablePay = [];

    const currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    if (check.not.equal(this.id, currentUserId)) {
      // Do not allow to deactivate your own user
      this.isNotMyUser = true;
    } else {
      this.isNotMyUser = false;
    }

    const getUserEmployments = this.injector.get(UserEmploymentService).getUserEmployments(this.id);
    const getUserSalaries = this.injector.get(UserSalaryService).getUserSalaries(this.id);
    const getUserVariablePay = this.injector.get(UserVariablePayService).getAllUserVariablePay(this.id);
    const getUserPersonal = this.injector.get(UserPersonalService).getById(this.id);
    const getUserWork = this.injector.get(UserWorkService).getById(this.id);
    const getUserWorkSchedule = this.injector.get(UserWorkScheduleService).getById(this.id);
    const getVariablePayTypes = this.injector.get(VariablePayTypeService).getAllTypes();

    Promise.all([
      getUserEmployments,
      getUserSalaries,
      getUserVariablePay,
      getUserPersonal,
      getUserWork,
      getUserWorkSchedule,
      getVariablePayTypes,
    ])
      .then(async (userData: Array<any>) => {
        await this.fetchSalarySurcharges();
        this.userEmployments = userData[0].sort(this.sortBy('startDate')).reverse();
        this.userSalaries = userData[1].sort(this.sortSalaryBy('effectiveDate')).reverse();
        this.userVariablePay = userData[2].sort(this.sortBy('effectiveDate')).reverse();
        this.userPersonal = userData[3];
        this.userWork = userData[4];
        this.userWorkSchedule = userData[5];

        if (check.assigned(userData[6]) && check.nonEmptyArray(userData[6])) {
          userData[6].forEach((iType) => {
            this.variablePayTypes[iType._id] = iType.name;
          });
        }

        const foundSalary = this.userSalaries.find((iSalary: any) => {
          return moment(iSalary.effectiveDate).isSameOrBefore(moment(), 'day');
        });

        if (check.assigned(foundSalary)) {
          this.currentSalary = [foundSalary];
        } else {
          this.currentSalary = [];
        }

        this.injector
          .get(InternationalizationService)
          .getAllTranslation('user-employment-contract-type-db-defaults')
          .then((defaultContractTypes) => {
            this.defaultContractTypeTranslations = defaultContractTypes;
          })
          .catch(() => {
            this.defaultContractTypeTranslations = {};
          });
        // Load contract types, mapped to user-employment
        this.injector.get(UserEmploymentContractTypeService).loadAllUserEmploymentContractTypes();
        this.mapContractTypeIdToContractTypeName$ = this.injector.get(UserEmploymentContractTypeService).userEmploymentContractTypes.pipe(
          map((contractTypes: Array<IUserEmploymentContractTypeModel>) =>
            contractTypes.reduce<{ [id: string]: string }>(
              (contractTypeMap: { [id: string]: string }, contractType: IUserEmploymentContractTypeModel) => {
                contractTypeMap[contractType._id] = this.defaultContractTypeTranslations[contractType.name]
                  ? this.defaultContractTypeTranslations[contractType.name]
                  : contractType.name;
                return contractTypeMap;
              },
              {}
            )
          )
        );
        // Load subcategories, mapped to user-employment
        this.injector.get(UserEmploymentSubcategoryService).loadAllUserEmploymentSubcategories();
        this.mapSubcategoryIdToContractTypeName$ = this.injector.get(UserEmploymentSubcategoryService).userEmploymentSubcategories.pipe(
          map((subcategories: Array<IUserEmploymentSubcategoryModel>) =>
            subcategories.reduce<{ [id: string]: string }>(
              (subcategoryMap: { [id: string]: string }, subcategory: IUserEmploymentSubcategoryModel) => {
                subcategoryMap[subcategory._id] = subcategory.name;
                return subcategoryMap;
              },
              {}
            )
          )
        );
        return this.injector.get(CompanyService).getCompanies();
      })
      .then((companies: Array<any>) => {
        this.userCompany = companies.find((iCompany) => {
          return iCompany._id === this.userWork.companyId;
        });
        this.companyNamesAndIds = [];
        this.mapCompanyIdToCompanyName = companies.reduce((result, iCompany) => {
          result[iCompany._id] = iCompany.name;
          this.companyNamesAndIds = [
            ...this.companyNamesAndIds,
            {
              _idCompany: iCompany._id,
              companyName: iCompany.name,
            },
          ];
          return result;
        }, {});
        this.seeDetailsUserIdSubscription = this.injector.get(PeopleDetailService).seeDetailsUserId$.subscribe((id: string) => {
          this.userManagePoliciesInfo = {
            userPersonal: this.userPersonal,
            userStartDate: this.userWork.startDate,
            timeOffTypes: this.injector.get(PeopleDetailService).timeOffTypes,
          };
          this.seeDetailsUserId = id;
        });
        resolveFetchData();
      })
      .catch(() => {
        // An error is already shown
        this.userEmployments = [];
        this.userSalaries = [];
        this.userVariablePay = [];
        this.userPersonal = null;
        this.userWork = null;
        rejectFetchData();
      });
  }

  protected fetchLazyData(): void {
    if (check.not.assigned(this.id) || check.emptyString(this.id)) {
      return;
    }
  }

  private sortBy(dateField: string): (a, b) => number {
    return (a, b) => {
      const aMoment = moment.utc(a[dateField]);
      const bMoment = moment.utc(b[dateField]);

      if (aMoment.isBefore(bMoment)) {
        return -1;
      } else if (aMoment.isAfter(bMoment)) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  private sortSalaryBy(dateField: string): (a, b) => number {
    return (a, b) => {
      const aMoment = moment.utc(a[dateField]);
      const bMoment = moment.utc(b[dateField]);

      if (aMoment.isBefore(bMoment)) {
        return -1;
      } else if (aMoment.isAfter(bMoment)) {
        return 1;
      }

      if (a.amount < b.amount) {
        return -1;
      } else if (a.amount > b.amount) {
        return 1;
      }

      return 0;
    };
  }

  addEmployment(): void {
    const dialogOptions = { canEditPast: this.canEditPastCompensations(), userId: this.id };
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddEmploymentDialog, { data: dialogOptions });
    dialogRef.afterClosed().subscribe((rawEmploymentData: any) => {
      if (check.not.assigned(rawEmploymentData)) {
        return;
      }

      rawEmploymentData._userId = this.id;

      this.injector
        .get(UserEmploymentService)
        .create(rawEmploymentData)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          // An error is already shown
        });
    });
  }

  editEmployment(employmentToEdit: any): void {
    const dialogOptions = {
      canEditPast: this.canEditPastCompensations(),
      record: employmentToEdit,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditEmploymentDialog, { data: dialogOptions });
    dialogRef.afterClosed().subscribe((rawEmploymentDataToUpdate: any) => {
      if (check.not.assigned(rawEmploymentDataToUpdate)) {
        this.refreshData();
        return;
      }

      this.injector
        .get(UserEmploymentService)
        .updateById(rawEmploymentDataToUpdate._id, rawEmploymentDataToUpdate)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          // An error is already shown
        });
    });
  }

  deleteEmployment(employmentToDelete: any): void {
    const getPeopleDetailCompensationDeleteEmploymentDialogTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-delete-employment-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

    Promise.all([getPeopleDetailCompensationDeleteEmploymentDialogTranslations, getGlobalMiscTranslations])
      .then(([peopleDetailCompensationDeleteEmploymentDialogTranslations, globalMiscTranslation]) => {
        const data = {
          titleText: peopleDetailCompensationDeleteEmploymentDialogTranslations.dialogHeader,
          subtitleText: peopleDetailCompensationDeleteEmploymentDialogTranslations.warningMessageText,
          cancelButtonText: globalMiscTranslation.goBackButtonDialog,
          confirmButtonText: peopleDetailCompensationDeleteEmploymentDialogTranslations.deleteEmploymentButtonLabel,
          confirmButtonColor: 'Danger',
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
        dialogRef.afterClosed().subscribe((deleteEmployment: boolean) => {
          if (check.not.assigned(deleteEmployment) || deleteEmployment === false) {
            return;
          }

          this.injector
            .get(UserEmploymentService)
            .deleteById(employmentToDelete._id)
            .then(() => {
              this.refreshData();
            })
            .catch(() => {
              // An error is already shown
            });
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  addSalary(_idCompany: string): void {
    let maxOnGoingEndDate;
    let minStartDate;

    const filteredSalaries = this.userSalaries
      .filter((salary) => salary._companyId === _idCompany)
      .sort(this.sortSalaryBy('effectiveDate'))
      .reverse();
    const notEndedUserSalary = filteredSalaries.find((salary) => salary.endDate === undefined);

    if (check.assigned(notEndedUserSalary)) {
      const indexNotEndedSalary = filteredSalaries.findIndex((salary) => salary._id === notEndedUserSalary._id);
      maxOnGoingEndDate = check.assigned(filteredSalaries[indexNotEndedSalary - 1])
        ? moment(filteredSalaries[indexNotEndedSalary - 1].effectiveDate)
            .subtract(1, 'day')
            .toDate()
        : undefined;
    }

    if (check.assigned(filteredSalaries[0])) {
      if (check.assigned(filteredSalaries[0].endDate)) {
        minStartDate = filteredSalaries[0].endDate;
      } else {
        minStartDate = filteredSalaries[0].effectiveDate;
      }
    }

    const data = {
      employeeWeeklyHours: this.userWork.weeklyHours,
      companyWeeklyHours: this.userCompany.weeklyHours,
      canEditPast: this.canEditPastCompensations(),
      notEndedSalary: notEndedUserSalary,
      companyId: _idCompany,
      minStartDate,
      maxOnGoingEndDate,
      ownerId: this.id,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(AddSalaryDialog, { data: data });
    dialogRef.afterClosed().subscribe((rawSalaryData: any) => {
      if (check.not.assigned(rawSalaryData)) {
        return;
      }
      const newSalary = { ...rawSalaryData.newSalary, _userId: this.id, endDate: rawSalaryData.newSalary.selectEndDate };
      delete newSalary.selectEndDate;

      if (check.assigned(rawSalaryData.onGoing)) {
        const onGoingSalary = { ...rawSalaryData.onGoing };
        this.injector
          .get(UserSalaryService)
          .updateById(onGoingSalary._id, onGoingSalary)
          .then(() => {
            this.refreshData();
          })
          .catch(() => {
            // An error is already shown
          });
      }

      this.injector
        .get(UserSalaryService)
        .create(newSalary)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          // An error is already shown
        });
    });
  }

  editSalary(salaryToEdit: any): void {
    const allSalaries = this.userSalaries
      .filter((salary) => salary._companyId === salaryToEdit._companyId)
      .sort(this.sortSalaryBy('effectiveDate'));
    const indexSalaryToEdit = allSalaries.findIndex((salary) => salary._id === salaryToEdit._id);

    let minStartDate;
    if (check.assigned(allSalaries[indexSalaryToEdit - 1])) {
      if (check.assigned(allSalaries[indexSalaryToEdit - 1].endDate)) {
        minStartDate = moment(allSalaries[indexSalaryToEdit - 1].endDate).isAfter(allSalaries[indexSalaryToEdit - 1].effectiveDate)
          ? allSalaries[indexSalaryToEdit - 1].endDate
          : allSalaries[indexSalaryToEdit - 1].effectiveDate;
      } else {
        minStartDate = allSalaries[indexSalaryToEdit - 1].effectiveDate;
      }
    }
    let maxStartDate = check.assigned(allSalaries[indexSalaryToEdit + 1]) ? allSalaries[indexSalaryToEdit + 1].effectiveDate : undefined;
    let maxEndDate = maxStartDate;

    if (check.assigned(allSalaries[indexSalaryToEdit]) && check.assigned(allSalaries[indexSalaryToEdit].endDate)) {
      maxStartDate = allSalaries[indexSalaryToEdit].endDate;
    }

    maxEndDate = check.assigned(maxEndDate) ? moment.utc(maxEndDate).subtract(1, 'day').toDate() : undefined;

    const dialogOptions = {
      canEditPast: this.canEditPastCompensations(),
      record: salaryToEdit,
      dateLimits: { minStartDate, maxStartDate, maxEndDate },
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditSalaryDialog, { data: dialogOptions });
    dialogRef.afterClosed().subscribe((rawSalaryDataToUpdate: any) => {
      if (check.not.assigned(rawSalaryDataToUpdate)) {
        this.refreshData();
        return;
      }

      this.injector
        .get(UserSalaryService)
        .updateById(rawSalaryDataToUpdate._id, rawSalaryDataToUpdate)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          // An error is already shown
        });
    });
  }

  deleteSalary(salaryToDelete: any): void {
    const getPeopleDetailCompensationDeleteSalaryDialogTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-delete-salary-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

    Promise.all([getPeopleDetailCompensationDeleteSalaryDialogTranslations, getGlobalMiscTranslations])
      .then(([peopleDetailCompensationDeleteSalaryDialogTranslations, globalMiscTranslation]) => {
        const data = {
          titleText: peopleDetailCompensationDeleteSalaryDialogTranslations.dialogHeader,
          subtitleText: peopleDetailCompensationDeleteSalaryDialogTranslations.warningMessageText,
          cancelButtonText: globalMiscTranslation.goBackButtonDialog,
          confirmButtonText: peopleDetailCompensationDeleteSalaryDialogTranslations.deleteSalaryButtonLabel,
          confirmButtonColor: 'Danger',
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
        dialogRef.afterClosed().subscribe((deleteSalary: boolean) => {
          if (check.not.assigned(deleteSalary) || deleteSalary === false) {
            return;
          }

          this.injector
            .get(UserSalaryService)
            .deleteById(salaryToDelete._id)
            .then(() => {
              this.refreshData();
            })
            .catch(() => {
              // An error is already shown
            });
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  addVariablePay(): void {
    const dialogOptions = { canEditPast: this.canEditPastCompensations(), ownerId: this.id };
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddVariablePayDialog, { data: dialogOptions });
    dialogRef.afterClosed().subscribe((rawVariablePayData) => {
      if (check.not.assigned(rawVariablePayData)) {
        return;
      }

      rawVariablePayData._userId = this.id;

      this.injector
        .get(UserVariablePayService)
        .create(rawVariablePayData)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          // An error is already shown
        });
    });
  }

  editVariablePay(variablePayToEdit: any): void {
    const variablePay = Object.assign({}, variablePayToEdit);
    const dialogOptions = {
      canEditPast: this.canEditPastCompensations(),
      record: variablePay,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddVariablePayDialog, { data: dialogOptions });
    dialogRef.afterClosed().subscribe((rawVariablePayDataToUpdate: any) => {
      if (check.not.assigned(rawVariablePayDataToUpdate)) {
        this.refreshData();
        return;
      }

      this.injector
        .get(UserVariablePayService)
        .updateById(rawVariablePayDataToUpdate._id, rawVariablePayDataToUpdate)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          // An error is already shown
        });
    });
  }

  deleteVariablePay(variablePayToDelete: any): void {
    const getPeopleDetailCompensationDeleteVariablePayDialogTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-delete-variable-pay-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

    Promise.all([getPeopleDetailCompensationDeleteVariablePayDialogTranslations, getGlobalMiscTranslations])
      .then(([peopleDetailCompensationDeleteVariablePayDialogTranslations, globalMiscTranslation]) => {
        const data = {
          titleText: peopleDetailCompensationDeleteVariablePayDialogTranslations.dialogHeader,
          subtitleText: peopleDetailCompensationDeleteVariablePayDialogTranslations.warningMessageText,
          cancelButtonText: globalMiscTranslation.goBackButtonDialog,
          confirmButtonText: peopleDetailCompensationDeleteVariablePayDialogTranslations.deleteVariablePayButtonLabel,
          confirmButtonColor: 'Danger',
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
        dialogRef.afterClosed().subscribe((deleteVariablePay: boolean) => {
          if (check.not.assigned(deleteVariablePay) || deleteVariablePay === false) {
            return;
          }

          this.injector
            .get(UserVariablePayService)
            .deleteById(variablePayToDelete._id)
            .then(() => {
              this.refreshData();
            })
            .catch(() => {
              // An error is already shown
            });
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  private getPermissions(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (check.assigned(this.myPermissions) && check.nonEmptyObject(this.myPermissions)) {
        resolve();
        return;
      }

      this.injector
        .get(PrivateSecurityService)
        .getAllPermissions()
        .then((permissions) => {
          this.myPermissions = permissions;
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private initUserWorkSchedule(): Promise<void> {
    if (check.assigned(this.userWorkSchedule) && check.nonEmptyObject(this.userWorkSchedule)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.injector
        .get(UserWorkScheduleService)
        .getById(this.id)
        .then((userWorkSchedule: IUserWorkScheduleModel) => {
          this.userWorkSchedule = userWorkSchedule;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  private getPermissionsToSeeAttendanceTab(employeesPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      this.initUserWorkSchedule().then(() => {
        if (
          this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') === false ||
          !this.userWorkSchedule ||
          this.userWorkSchedule.trackAttendance === false
        ) {
          resolve(false);
          return;
        }

        if (loggedUser._id === this.id && employeesPermissions.c_viewAttendanceTab_own === true) {
          resolve(true);
          return;
        }

        if (employeesPermissions.c_viewAttendanceTab_all === true) {
          resolve(true);
          return;
        }

        if (
          check.not.assigned(employeesPermissions.c_viewAttendanceTab_custom) ||
          check.emptyArray(employeesPermissions.c_viewAttendanceTab_custom)
        ) {
          resolve(false);
          return;
        }

        this.injector
          .get(UserWorkService)
          .getAllUserWorkCache()
          .then((allUserWork) => {
            let myUserWork = this.userWork;
            if (check.not.assigned(myUserWork)) {
              myUserWork = allUserWork.find((iUserWork) => {
                return iUserWork._id === this.id;
              });
            }
            return customPermissions.applyCustomPermissionsToDocument(
              null,
              'user-work',
              employeesPermissions[`c_viewAttendanceTab_custom`],
              employeesPermissions[`c_viewAttendanceTab_own`],
              myUserWork,
              allUserWork,
              loggedUser,
              this.id
            );
          })
          .then((customPermissionResult) => {
            resolve(customPermissionResult);
          })
          .catch(() => {
            resolve(false);
          });
      });
    });
  }

  private getPermissionsToSeePayrollTabs(payrollPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/compensation') === false) {
        resolve(false);
      }

      if (loggedUser._id === this.id && payrollPermissions.c_viewCompensation_own === true) {
        resolve(true);
      }
      if (payrollPermissions.c_viewCompensation_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(payrollPermissions.c_viewCompensation_custom) ||
        check.emptyArray(payrollPermissions.c_viewCompensation_custom)
      ) {
        resolve(false);
      }

      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            payrollPermissions[`c_viewCompensation_custom`],
            payrollPermissions[`c_viewCompensation_own`],
            myUserWork,
            allUserWork,
            loggedUser,
            this.id
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private getPermissionsToSeePerformanceTab(performancePermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance') === false) {
        resolve(false);
      }
      if (loggedUser._id === this.id) {
        resolve(true);
      }
      if (performancePermissions.c_readFeedback_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(performancePermissions.c_readFeedback_custom) ||
        check.emptyArray(performancePermissions.c_readFeedback_custom)
      ) {
        resolve(false);
      }
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            performancePermissions.c_readFeedback_custom,
            performancePermissions.read_own,
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  public toggleSuchargeOverlay(): void {
    if (this.addSurchargesDisabled) {
      return;
    }

    this.openAddSuchargeOverlay = !this.openAddSuchargeOverlay;
  }

  private async fetchSalarySurcharges(): Promise<void> {
    if (!this.isAttendanceEnabled) {
      return;
    }

    const allSurcharges: Array<IPayrollSurchargeRule> = await this.injector.get(PayrollSurchargeRulesService).getAllForUserId(this.id);
    this.salarySurcharges = allSurcharges.filter((surcharge: IPayrollSurchargeRule) => {
      return surcharge.assignedUserIds?.length && surcharge.assignedUserIds.includes(this.id);
    });
    const surchargeIdsAssigned = this.salarySurcharges.map((surcharge: IPayrollSurchargeRule) => surcharge._id);
    this.availableSurcharges = allSurcharges.filter((surcharge: IPayrollSurchargeRule) => !surchargeIdsAssigned.includes(surcharge._id));
    this.surchargeIdToConditionMessage = await this.injector
      .get(PayrollSurchargeUtilsService)
      .calculateSurchargeIdToConditionMessage([...this.salarySurcharges, ...this.availableSurcharges]);
    this.surchargeIdToSurchargeStatus = this.injector
      .get(PayrollSurchargeUtilsService)
      .calculateSurchargeIdToSurchargeStatus([...this.salarySurcharges, ...this.availableSurcharges]);

    this.salarySurcharges = this.salarySurcharges.sort((surchargeA: IPayrollSurchargeRule, surchargeB: IPayrollSurchargeRule) => {
      const statusA = this.surchargeIdToSurchargeStatus[surchargeA._id];
      const statusB = this.surchargeIdToSurchargeStatus[surchargeB._id];

      if (statusA < statusB) {
        return -1;
      } else if (statusA > statusB) {
        return 1;
      }

      const nameA = surchargeA.name.toLowerCase();
      const nameB = surchargeB.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    this.addSurchargesDisabled = false;
    this.addSurchargesTooltip = null;
    if (!allSurcharges?.length) {
      this.addSurchargesDisabled = true;
      this.addSurchargesTooltip = this.i18n.page.noRulesCreatedTooltip;
    } else if (!this.availableSurcharges?.length) {
      this.addSurchargesDisabled = true;
      this.addSurchargesTooltip = this.i18n.page.allRulesAssignedTooltip;
    }
    await this.initAutocompleteOptions();
  }

  private async initAutocompleteOptions(): Promise<void> {
    this.autocompleteOptions = this.availableSurcharges.map((surchargeRule) => {
      return {
        name: surchargeRule.name,
        value: surchargeRule._id,
        subtitle: this.surchargeIdToConditionMessage[surchargeRule._id],
        rightColText: `${surchargeRule.extraRatePercentage}%`,
      };
    });
  }

  async deleteEmployeeFromSurchargeRule(surchargeRuleToDelete: IPayrollSurchargeRule): Promise<void> {
    const translationData = {
      surchargeRuleName: surchargeRuleToDelete.name,
      employeeName: this.userPersonal?.displayName,
    };

    const confirmDialogData = {
      titleText: this.injector.get(I18nDataPipe).transform(this.i18n.page.deleteEmployeeFromSurchargeRuleDialogTitle, translationData),
      subtitleText: this.i18n.page.deleteEmployeeFromSurchargeRuleDialogDescription,
      confirmButtonText: this.i18n.page.deleteEmployeeFromSurchargeRuleDialogConfirmButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.page.deleteEmployeeFromSurchargeRuleDialogCancelButton,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: confirmDialogData });
    const result = await dialogRef.afterClosed().toPromise();
    if (result === true) {
      try {
        await this.injector.get(PayrollSurchargeRulesService).removeEmployeeFromRule(this.id, surchargeRuleToDelete._id);
        this.injector
          .get(MatLegacySnackBar)
          .open(
            this.injector.get(I18nDataPipe).transform(this.i18n.page.deleteEmployeeFromSurchargeRuleConfirmSnackbar, translationData),
            'OK',
            {
              duration: 5000,
            }
          );
        this.injector.get(PrivateAmplitudeService).logEvent('remove surcharge in compensation', {
          platform: 'Web',
          category: 'Compensation',
          subcategory1: 'Salary surcharges',
          subcategory2: 'Salary surcharges',
        });
        await this.fetchSalarySurcharges();
      } catch {}
    }
  }

  async addSurchargeRule(surchargeId: string): Promise<void> {
    this.openAddSuchargeOverlay = false;
    if (!surchargeId) {
      return;
    }

    try {
      await this.injector.get(PayrollSurchargeRulesService).addEmployeeToRule(this.id, surchargeId);
      const surchargeRule: IPayrollSurchargeRule = this.availableSurcharges.find((rule) => surchargeId === rule._id);
      const employeeName = this.userPersonal?.displayName;
      this.injector.get(MatLegacySnackBar).open(
        this.injector.get(I18nDataPipe).transform(this.i18n.settingsPayroll.assignSurchargesFromCompensationSnackbar, {
          surchargeRuleName: surchargeRule?.name,
          employeeName,
        }),
        'OK',
        { duration: 5000 }
      );
      this.injector.get(PrivateAmplitudeService).logEvent('add surcharge in compensation', {
        platform: 'Web',
        category: 'Compensation',
        subcategory1: 'Salary surcharges',
        subcategory2: 'Salary surcharges',
      });
      await this.fetchSalarySurcharges();
    } catch (error) {}
  }

  destroy(): Promise<void> {
    if (this.seeDetailsUserIdSubscription) {
      this.seeDetailsUserIdSubscription.unsubscribe();
    }
    return Promise.resolve();
  }

  exitTimeOffFullScreen() {
    this.injector.get(PeopleDetailService).closeEmployeeTimeOff();
    this.injector.get(GlobalBarService).setPageName(this.i18n.page.pageName);
    this.refreshGlobalBar();
  }
}
