import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable()
export class CloudRoutesService {
  private ROUTES_DB_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/route-db`;

  constructor(private injector: Injector) {}

  checkRoute(route: string): boolean {
    if (check.not.assigned(route) || check.not.string(route) || check.emptyString(route)) {
      return false;
    }

    return this.injector.get(CloudRoutingService).checkIfRouteExists(route);
  }

  getAppStatus(appKey: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, CloudRoutesService.name, 'getAppStatus');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<any>(`${this.ROUTES_DB_URL}/${appKey}`, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, CloudRoutesService.name, 'getAppStatus'));
        });
    });
  }

  activateApp(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, CloudRoutesService.name, 'activateApp');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put<void>(`${this.ROUTES_DB_URL}/${appKey}/activate`, {}, httpOptions)
        .toPromise()
        .then(() => {
          this.injector.get(CloudRoutingService).refreshRouter();
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, CloudRoutesService.name, 'activateApp'));
        });
    });
  }

  deactivateApp(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, CloudRoutesService.name, 'deactivateApp');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put<void>(`${this.ROUTES_DB_URL}/${appKey}/deactivate`, {}, httpOptions)
        .toPromise()
        .then(() => {
          this.injector.get(CloudRoutingService).refreshRouter();
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, CloudRoutesService.name, 'deactivateApp'));
        });
    });
  }

  sendSupportNotification(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, CloudRoutesService.name, 'sendSupportNotification');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put<void>(`${this.ROUTES_DB_URL}/${appKey}/send-support-notification`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, CloudRoutesService.name, 'sendSupportNotification'));
        });
    });
  }

  forceCheckIfNewNotifications(): void {
    this.injector.get(CloudRoutingService).forceCheckIfNewNotifications();
  }
}
