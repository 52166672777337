<div class="capsd-main kenjo-p-40px">
  <kenjo-icon [size]="24" class="capsd-clear kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="close()">clear</kenjo-icon>

  <div class="kenjo-text-align-center">
    <img src="/assets/images/success.svg" width="35%" height="35%" />
  </div>

  <div class="kenjo-font-size-20px kenjo-text-align-center kenjo-mt-10px">{{ translations.policyCreated }}</div>
  <div class="capsd-description kenjo-font-color-light-text-757575 kenjo-text-align-center kenjo-mt-10px">{{ translations.description }}</div>

  <div class="capsd-buttons kenjo-mt-30px">
    <orgos-button-raised class="capsd-button" (click)="openAdvancedSettings()" color="Neutral">{{ translations.advancedSettings }}</orgos-button-raised>
    <orgos-button-raised class="capsd-button" (click)="openAssignEmployees()" color="Primary">{{ translations.assignEmployees }}</orgos-button-raised>
  </div>

  <div class="kenjo-mt-20px kenjo-text-align-center">
    <a [href]="translations.learnMoreLink" target="_blank" (click)="logLearnMoreEvent()">{{ translations.learnMore }}</a>
  </div>
</div>
