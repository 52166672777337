import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanCapacityService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-capacity-db`;
  private SHIFTPLAN_CAPACITY_SERVICE: string = 'ShiftPlanCapacityService';

  constructor(
    private genericService: GenericService,
    private errorManager: ErrorManagerService,
    private http: HttpClient,
    private injector: Injector
  ) {}

  async getCapacity(): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CAPACITY_SERVICE, 'getCapacity');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.get(`${this.MICROSERVICE_URL}/own-capacity`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CAPACITY_SERVICE, 'getCapacity');
    }
  }

  async create(data: object): Promise<IShiftPlanCapacityModel> {
    try {
      return await this.genericService.create(this.MICROSERVICE_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CAPACITY_SERVICE, 'createCapacity');
    }
  }

  async updateCapacity(id: string, data: any): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CAPACITY_SERVICE, 'updateCapacity');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.put(`${this.MICROSERVICE_URL}/${id}`, data, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CAPACITY_SERVICE, 'updateCapacity');
    }
  }
}
export interface IShiftPlanCapacityModel extends IShiftPlanCapacityPerDay {
  _id: string;
  ownerId: string;
  s_orgId: string;
}

export interface IShiftPlanCapacityPerDay {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}
