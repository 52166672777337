<orgos-column-container centerColumns="true">
  <orgos-column size="0" class="eb-number-circle" [style.background]="currentRange.color">
    <div class="eb-number-value kenjo-font-size-25px" [class.kenjo-font-color-white-ffffff]="currentRange.textColor === 'White'" [class.kenjo-font-color-text-333333]="currentRange.textColor === 'Black'">{{ getStringValue() }}</div>
  </orgos-column>

  <orgos-column size="1" class="kenjo-pl-10px">
    <orgos-column-container class="kenjo-pr-20px" centerColumns="true">
      <orgos-column size="0">
        <a routerLink="/cloud/pulse/factor" [queryParams]="{ id: categoryKey }" *ngIf="linkToDetail === true" class="kenjo-font-size-16px">
          {{ label }}
        </a>
        <div *ngIf="linkToDetail !== true" class="kenjo-font-size-16px">{{ label }}</div>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>

    <div class="eb-progress-bar-container kenjo-mt-10px">
      <div [style.background-color]="currentRange.color" [style.width]="getPercentage()" class="eb-progress-bar-fill"></div>
    </div>

    <orgos-column-container class="kenjo-pt-10px" centerColumns="true" *ngIf="showFeedbackSection === true">
      <orgos-column size="1"></orgos-column>
      <orgos-text orgosColumn="0" type="BodyText2" color="LightText">
        {{ numberUnreadFeedbacks && numberUnreadFeedbacks > 0 ? totalFeedbacksLabel : pageTranslation.noFeedbacksLabel }}
      </orgos-text>
      <a orgosColumn="0" class="kenjo-ml-10px" routerLink="/cloud/pulse/feed" [queryParams]="{ type: categoryKey }">
        {{ pageTranslation.reviewLink }}
      </a>
    </orgos-column-container>
  </orgos-column>
</orgos-column-container>

<orgos-container class="kenjo-pt-20px" *ngIf="descriptionText">
  <orgos-text type="BodyText2" color="LightText">{{ descriptionText }}</orgos-text>
</orgos-container>
