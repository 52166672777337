import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class RecruitingTalentPoolService implements IGenericService {
  private RECRUITING_TALENT_POOL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-talent-pool-db`;
  private RECRUITING_TALENT_POOL_PERMISSIONS_KEY: string = 'recruiting-talent-pool';
  private RECRUITING_TALENT_POOL_INTERNATIONALIZATION: string = 'recruiting-talent-pool-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  getById(id: string): Promise<IRecrutingTalentPoolModel> {
    return new Promise<IRecrutingTalentPoolModel>((resolve, reject) => {
      this.genericService
        .getById(this.RECRUITING_TALENT_POOL_URL, id)
        .then((talentPool: any) => {
          resolve(talentPool);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'getById'));
        });
    });
  }

  create(data: object): Promise<IRecrutingTalentPoolModel> {
    return new Promise<IRecrutingTalentPoolModel>((resolve, reject) => {
      this.genericService
        .create(this.RECRUITING_TALENT_POOL_URL, data)
        .then((talentPool: IRecrutingTalentPoolModel) => {
          resolve(talentPool);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'create'));
        });
    });
  }

  find(findBody: object): Promise<Array<IRecrutingTalentPoolModel>> {
    return new Promise<Array<IRecrutingTalentPoolModel>>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_TALENT_POOL_URL, findBody)
        .then((talentPools: Array<any>) => {
          resolve(talentPools);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: IRecrutingTalentPoolModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.RECRUITING_TALENT_POOL_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.RECRUITING_TALENT_POOL_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'deleteById'));
        });
    });
  }

  addCandidate(talentPoolId: string, candidateId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.RECRUITING_TALENT_POOL_URL}/${talentPoolId}/add-candidate/${candidateId}`, ErrorCodes.UNAUTHORIZED, RecruitingTalentPoolService.name, 'addCandidate');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        _id: { $ne: null }
      };

      this.http
        .put(`${this.RECRUITING_TALENT_POOL_URL}/${talentPoolId}/add-candidate/${candidateId}`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'addCandidate'));
        });
    });
  }

  removeCandidate(talentPoolId: string, candidateId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.RECRUITING_TALENT_POOL_URL}/${talentPoolId}/remove-candidate/${candidateId}`, ErrorCodes.UNAUTHORIZED, RecruitingTalentPoolService.name, 'removeCandidate');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        _id: { $ne: null }
      };

      this.http
        .put(`${this.RECRUITING_TALENT_POOL_URL}/${talentPoolId}/remove-candidate/${candidateId}`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'removeCandidate'));
        });
    });
  }

  addFavorite(candidateId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.RECRUITING_TALENT_POOL_URL}/add-favorite/${candidateId}`, ErrorCodes.UNAUTHORIZED, RecruitingTalentPoolService.name, 'addFavorite');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        _id: { $ne: null }
      };

      this.http
        .put(`${this.RECRUITING_TALENT_POOL_URL}/add-favorite/${candidateId}`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'addFavorite'));
        });
    });
  }

  removeFavorite(candidateId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.RECRUITING_TALENT_POOL_URL}/remove-favorite/${candidateId}`, ErrorCodes.UNAUTHORIZED, RecruitingTalentPoolService.name, 'removeFavorite');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        _id: { $ne: null }
      };

      this.http
        .put(`${this.RECRUITING_TALENT_POOL_URL}/remove-favorite/${candidateId}`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingTalentPoolService.name, 'removeFavorite'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.RECRUITING_TALENT_POOL_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.RECRUITING_TALENT_POOL_INTERNATIONALIZATION);
  }
}

export interface IRecrutingTalentPoolModel {
  _id?: string;
  name: string;
  description: string;
  favoriteForUserId: string;
  listCandidates: Array<any>;
  _isMailInbox: boolean;
}
