import { Injectable, Injector } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { PrivateSignInService } from '@app/private/services/private-sign-in.service';
import { AuthenticationService } from '@app/standard//services/core/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthenticatedGuard implements CanLoad, CanActivate {
  constructor(private injector: Injector) {}

  canLoad(): Promise<boolean> {
    return this.executeGuard();
  }

  canActivate(): Promise<boolean> {
    return this.executeGuard();
  }

  private executeGuard(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === true && this.injector.get(AuthenticationService).getLoggedUser().isActive === true) {
        this.injector.get(Router).navigate(['/cloud']);
        resolve(false);
        return;
      }

      this.injector
        .get(PrivateSignInService)
        .signInWithCookie()
        .then(() => {
          if (this.injector.get(AuthenticationService).isUserAuthenticated() === true && this.injector.get(AuthenticationService).getLoggedUser().isActive === true) {
            this.injector.get(Router).navigate(['/cloud']);
            resolve(false);
            return;
          }

          this.injector.get(AuthenticationService).signOut();
          resolve(true);
          return;
        })
        .catch(() => {
          resolve(true);
          return;
        });
    });
  }
}
