import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IShiftplanTemplatePreference } from '@app/cloud-features/shift-plan/services/shift-plan-template.service';
import { ISelectOption } from '@app/standard/core/select-option';

@Component({
  selector: 'kenjo-shiftplan-template-sort-settings',
  templateUrl: 'shiftplan-template-sort-settings.component.html',
  styleUrls: ['shiftplan-template-sort-settings.component.scss'],
})
export class ShiftplanTemplateSortSettings implements OnInit {
  sortByOptions: Array<ISelectOption>;
  applyPageFilters: boolean = false;

  @Input() sortPreference: IShiftplanTemplatePreference;
  @Input() templateTranslations: { [key: string]: string };
  @Input() isSorting: boolean = false;
  @Input() canBeSorted: boolean = true;

  @Output() sortByChange = new EventEmitter<'_createdAt' | 'name'>();
  @Output() applyFiltersChange = new EventEmitter<void>();


  ngOnInit() {
    this.initData();
  }

  initData() {
    this.applyPageFilters = this.sortPreference.applyFilters;
    this.setSortByOptions();
  }

  setSortByOptions() {
    const { creationDate, templateName } = this.templateTranslations;
    this.sortByOptions = [
      { value: '_createdAt', name: creationDate },
      { value: 'name', name: templateName },
    ];
  }

  onClick(event: PointerEvent) {
    event.stopPropagation();
  }

  changeSortBy(sortyBy: '_createdAt' | 'name') {
    this.sortByChange.emit(sortyBy);
  }

  changeApplyFilters() {
    this.applyFiltersChange.emit();
  }
}
