import { Injectable } from '@angular/core';
import { ShiftPlanWishDayType } from '@app/cloud-features/shift-plan/services/shift-plan-wish-day.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanGeneralSettingsService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-general-settings-db`;
  private SHIFTPLAN_GENERAL_SETTINGS_SERVICE: string = 'ShiftPlanGeneralSettingsService';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  async getGeneralSettings(): Promise<Array<IShiftPlanGeneralSettingsModel>> {
    try {
      return await this.genericService.find(this.MICROSERVICE_URL, { _id: { $ne: null } });
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_GENERAL_SETTINGS_SERVICE, 'getGeneralSettings');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.MICROSERVICE_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_GENERAL_SETTINGS_SERVICE, 'updateById');
    }
  }

  getCardSizeDefaultOptions(): IShiftCardSizeSettings {
    try {
      return { role: true, time: true, duration: false, location: false, tag: false };
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_GENERAL_SETTINGS_SERVICE, 'getCardSizeDefaultOptions');
    }
  }
}

export interface IShiftPlanGeneralSettingsModel {
  _id?: string;
  startRangeHoursOperation: number;
  endRangeHoursOperation: number;
  employeesCanSeeOtherSchedules: boolean;
  employeesCanSeeOtherUnavailable: boolean;
  sendNotifications: boolean;
  showTimeOff?: boolean;
  showAttendance?: boolean;
  openShifts: { requiresApproval: boolean; autoAssign: boolean };
  shiftSettings?: IShiftCardSizeSettings;
  showPublicHolidays?: boolean;
  publicHolidaysCalendar?: Array<string>;
  wishDays?: IWishDaySettings;
}

export interface IShiftCardSizeSettings {
  role: boolean;
  time: boolean;
  duration: boolean;
  location: boolean;
  tag: boolean;
  value?: string;
  compactHeight?: boolean;
  firstField?: string;
  fieldsNumber?: number;
}

export interface IShiftCardFieldOption {
  key: string;
  value: boolean;
  name: string;
}

export interface IWishDaySettings {
  show: boolean;
  type: ShiftPlanWishDayType;
  reminder?: {
    send: boolean;
    value: number;
    range: 'week' | 'month';
  };
}

export interface IDummyShiftCard {
  date: Date;
  start: number;
  end: number;
  employeeId: string;
  location: {
    name: string;
  };
  tag: {
    name: string;
  };
  role: {
    colour: string;
    name: string;
  };
  status: string;
}
