import { Component, Injector, Input, OnInit } from '@angular/core';
import { ISelectItem } from '@app/standard/components/select-dropdown-custom/select-dropdown-custom.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-select-item-attendance-policy',
  templateUrl: './select-item-attendance-policy.component.html',
  styleUrls: ['./select-item-attendance-policy.component.scss'],
})
export class SelectItemAttendancePolicyComponent implements OnInit {
  @Input() option: ISelectItem;
  translations: any;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  private async getTranslations(): Promise<void> {
    this.translations = await this.injector
      .get(InternationalizationService)
      .getAllTranslation('add-employee-dialog');
  }
}
