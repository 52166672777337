import { Component, Injector, Input, OnInit } from '@angular/core';
import { USER_COLOR_2, USER_COLOR_4 } from '@carlos-orgos/orgos-utils/constants/user-color.constants';

import { CloudRoutesService } from '../../services/core/cloud-routes.service';

@Component({
  selector: 'orgos-custom-app-card',
  templateUrl: 'custom-app-card.component.html',
  styleUrls: ['custom-app-card.component.scss']
})
export class CustomAppCardComponent implements OnInit {
  headerCssClasses: string = 'cacc-header cacc-not-active';

  inactiveColor: string = USER_COLOR_2;
  activeColor: string = USER_COLOR_4;

  appStatus: any = null;

  @Input() appMetadata: any;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(CloudRoutesService)
      .getAppStatus(this.appMetadata.appKey)
      .then((appStatus: any) => {
        this.appStatus = appStatus;

        if (this.appStatus.isActive === true) {
          const orgosColorCss: string = this.appMetadata.color.replace(/_/gi, '-');
          this.headerCssClasses = `cacc-header orgos-${orgosColorCss.toLowerCase()}`;
        }
      })
      .catch(() => {
        // An error is already shown
        this.appStatus = null;
      });
  }
}
