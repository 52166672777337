import { Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar, MatLegacySnackBarDismiss } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ITask } from '@app/models/task.model';
import { IUserAccountModel } from '@app/models/user-account.model';
import { IFilterField } from '@app/standard/components/filter-bar/filter-bar.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ListLimiter } from '@app/standard/components/limit-list-pipe/list-limiter.model';
import { TaskDialogComponent } from '@app/standard/pages/tasks/dialogs/task-dialog/task-dialog.component';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { CandidateService } from '@app/standard/services/recruiting/candidate.service';
import { HiringTeamService } from '@app/standard/services/recruiting/hiring-team.service';
import { PositionCandidateService } from '@app/standard/services/recruiting/position-candidate.service';
import { TaskService } from '@app/standard/services/task/task.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

export abstract class TasksAbstractPage {
  listTasks: Array<Array<any>>;
  objectTranslation: any = {};
  tabOrder: number;
  abstract readonly pageFindQuery: any;
  NUM_TASK_LIST_LIMIT = 15;
  TASK_TITLE_TRUNCATE_AFTER = 40;
  listLimiters = [];
  PREFERENCE_OPTION_KEY = 'tasks';

  // Permissions
  positionCandidateToHiringTeamPermissions: any = {};
  isAdminOrRecruiter = false;
  mapUserIdToUserPersonal: any = {};

  // Groupings
  listGroupings: Map<string, string>;
  groupFields: Array<string>;
  groupingValue: string = 'no_grouping';
  mapGroupingBuckets: any = {};
  groupingBuckets: Array<string>;

  // Filters
  filters: ITasksFilter;
  filterFields: Array<IFilterField>;
  tabKey: string;
  searchTerm: string = '';
  // these options are static, no need to store them in the database
  protected STATUS_FILTER = {
    ALL: { index: -1, key: 'ALL', value: { $or: [{ isCompleted: true }, { isCompleted: false }] } },
    OPEN: { index: 0, key: 'OPEN', value: { isCompleted: false } },
    DONE: { index: 1, key: 'DONE', value: { isCompleted: true } },
  };
  protected DUEDATE_FILTER = {
    ALL: { index: -1, key: 'ALL', value: { _id: { $ne: null } } },
    OVERDUE: { index: 0, key: 'OVERDUE', value: { dueDate: { $lte: moment().add(-1, 'days').endOf('day') } } },
    THIS_WEEK: { index: 1, key: 'THIS_WEEK', value: { dueDate: { $gte: moment().startOf('week'), $lt: moment().endOf('week') } } },
    THIS_MONTH: { index: 2, key: 'THIS_MONTH', value: { dueDate: { $gte: moment().startOf('month'), $lt: moment().endOf('month') } } },
    THIS_YEAR: { index: 3, key: 'THIS_YEAR', value: { dueDate: { $gte: moment().startOf('year'), $lt: moment().endOf('year') } } },
    TODAY: { index: 4, key: 'TODAY', value: { dueDate: { $gte: moment().startOf('day'), $lt: moment().endOf('day') } } },
  };
  private SEARCH_CHARACTERS_MIN_NUMBER: number = 3;

  pageTranslation: any = {};

  constructor(protected router: Router, protected injector: Injector) {}

  protected async fetchData(): Promise<void> {
    this.injector.get(GlobalBarService).setProgressBar(true);
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('tasks-mine-page');
      this.objectTranslation = await this.injector.get(InternationalizationService).getAllTranslation('task-collection');
      this.objectTranslation[this.groupingValue] = this.pageTranslation.noGroupingPicklistOption;
    } catch {
      this.pageTranslation = {};
      this.objectTranslation = {};
    }

    this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
    await this.populateFilters();
    this.initGroupings();
    this.initMenu();
    this.loadTasks();
  }

  protected abstract loadTasks(): Promise<void>;

  protected async handlePermissions(loggedUser: IUserAccountModel): Promise<any> {
    let hiringTeams;
    if (!['admin', 'recruiter'].includes(loggedUser.profileKey)) {
      hiringTeams = await this.injector.get(HiringTeamService).find({ _id: { $ne: null } });
      const mappedPositionIds = hiringTeams.map((hiringTeam: any) => {
        return hiringTeam._positionId;
      });

      const positionCandidates =
        loggedUser.profileKey === 'restricted'
          ? []
          : await this.injector.get(PositionCandidateService).find({ _positionId: { $in: mappedPositionIds } });
      const positionCandidatePermissionsMap = positionCandidates.reduce((acc: { [key: string]: any }, candidate: any) => {
        const hiringTeamsForPosition = hiringTeams.filter((team: any) => {
          return team._positionId === candidate._positionId;
        });
        if (check.array(hiringTeamsForPosition) && hiringTeamsForPosition.length > 0) {
          acc[candidate._id] = hiringTeamsForPosition.find((team: any) => team._userId === loggedUser._id)?.permissions ?? {};
        }

        return acc;
      }, {});

      this.positionCandidateToHiringTeamPermissions = positionCandidatePermissionsMap;
    } else {
      this.isAdminOrRecruiter = true;
    }
  }

  protected async handleTasks(tasks: Array<ITask>, loggedUser: IUserAccountModel): Promise<void> {
    const userIdsToFind = Array.from(
      new Set([
        ...tasks.map((iTask) => iTask.relatedTo),
        ...tasks.map((iTask) => iTask._createdById),
        ...tasks.map((iTask) => iTask.ownerId),
      ])
    );

    const userPersonalFind = this.injector.get(UserPersonalService).find({ _id: { $in: userIdsToFind } });
    const promisesFind = [userPersonalFind];

    if (loggedUser.profileKey !== 'restricted') {
      const positionCandidatesFind = this.injector.get(PositionCandidateService).find({ _id: { $in: userIdsToFind } });
      const candidatesFind = this.injector.get(CandidateService).find({ _id: { $ne: null } });
      promisesFind.push(positionCandidatesFind, candidatesFind);
    }
    const [userPersonalData, positionCandidatesData, candidatesData] = await Promise.all(promisesFind);
    const candidateIds = _.keyBy(candidatesData, '_id');

    userPersonalData.forEach((iUserPersonal) => {
      this.mapUserIdToUserPersonal[iUserPersonal._id] = iUserPersonal;
      this.mapUserIdToUserPersonal[iUserPersonal._id].url = `/cloud/people/${iUserPersonal._id}`;
    });

    positionCandidatesData?.forEach((iPositionCandidate) => {
      const iCandidate = candidateIds[iPositionCandidate._candidateId];
      if (check.assigned(iCandidate)) {
        this.mapUserIdToUserPersonal[iPositionCandidate._id] = {
          _photo: iCandidate.photo,
          displayName: `${iCandidate.firstName} ${iCandidate.lastName}`,
          url: `cloud/recruiting/candidate/${iPositionCandidate._candidateId}/${iPositionCandidate._positionId}`,
        };
      }
    });
  }

  private async initMenu(): Promise<void> {
    try {
      const tasksTopBar = await this.injector.get(InternationalizationService).getAllTranslation('tasks-top-bar');
      const options = [];
      options.push({ name: tasksTopBar.mineTab, onClick: () => this.router.navigateByUrl('/cloud/tasks/mine') });
      if (this.injector.get(CloudRoutesService).checkRoute('tasks/assigned') === true) {
        options.push({ name: tasksTopBar.assignedTab, onClick: () => this.router.navigateByUrl('/cloud/tasks/assigned') });
      }
      if (this.injector.get(CloudRoutesService).checkRoute('tasks/company') === true) {
        options.push({ name: tasksTopBar.companyTab, onClick: () => this.router.navigateByUrl('/cloud/tasks/company') });
      }
      this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
      this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(this.tabOrder);
    } catch {
      this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
    }
  }

  private initGroupings(): void {
    this.listGroupings = new Map();
    this.listGroupings.set(this.groupingValue, this.pageTranslation.noGroupingPicklistOption);
    this.groupFields.forEach((iFieldName) => this.listGroupings.set(iFieldName, this.objectTranslation[iFieldName]));
  }

  protected groupTasks(tasks: Array<any>): void {
    this.listTasks = [];
    this.groupingBuckets = [];
    this.mapGroupingBuckets = {};
    if (this.groupingValue === 'no_grouping') {
      this.mapGroupingBuckets['no_grouping'] = true;
      this.listTasks.push(tasks);
      this.listLimiters[0] = new ListLimiter(this.NUM_TASK_LIST_LIMIT);
      return;
    }

    tasks.forEach((iTask) => {
      if (check.not.contains(this.groupingBuckets, iTask[this.groupingValue])) {
        this.listTasks.push([]);
        this.groupingBuckets.push(iTask[this.groupingValue]);
      }
    });

    this.groupingBuckets.forEach((iGrouping, index) => {
      this.mapGroupingBuckets[iGrouping] = true;
      this.listTasks[index] = tasks.filter((iTask) => {
        return iTask[this.groupingValue] === iGrouping;
      });
      this.listLimiters[index] = new ListLimiter(this.NUM_TASK_LIST_LIMIT);
    });
  }

  protected changeGroupingStatus(groupName: string): void {
    this.mapGroupingBuckets[groupName] = !this.mapGroupingBuckets[groupName];
  }

  async findData(findQuery: any): Promise<any> {
    try {
      const tasks = await this.injector.get(TaskService).find(findQuery);
      // tslint:disable-next-line: no-unbound-method
      tasks.sort(this.compareByDueDate);
      return tasks;
    } catch {
      // do nothing
    }
  }

  private compareByDueDate(a: any, b: any): number {
    const valA = new Date(a.dueDate);
    const valB = new Date(b.dueDate);

    let comparison = 0;
    if (valA < valB) {
      comparison = -1;
    } else if (valA > valB) {
      comparison = 1;
    }
    return comparison;
  }

  // FILTER METHODS //
  // We cannot extend GenericFilterPage because it forces us to implement multiple methods that we don't need
  async populateFilters(): Promise<void> {
    const { taskFilterDueDate, taskFilterStatus } = await this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists');
    await this.fetchOrBuildFilters(taskFilterDueDate, taskFilterStatus);
  }

  private async fetchOrBuildFilters(
    taskFilterDueDate: { [key: string]: string },
    taskFilterStatus: { [key: string]: string }
  ): Promise<void> {
    this.filterFields = this.buildDefaultTaskFilterFields(taskFilterDueDate, taskFilterStatus);
  }

  private buildDefaultTaskFilterFields(
    taskFilterDueDate: { [key: string]: string },
    taskFilterStatus: { [key: string]: string }
  ): Array<IFilterField> {
    const defaultFilterFields = this.buildTaskFilterFields(taskFilterDueDate, taskFilterStatus);
    const activeStatusIndex = this.STATUS_FILTER['OPEN'].index;
    defaultFilterFields[1].options[activeStatusIndex].active = true;
    this.buildDefaultFilters();
    return defaultFilterFields;
  }

  private buildTaskFilterFields(
    taskFilterDueDate: { [key: string]: string },
    taskFilterStatus: { [key: string]: string }
  ): Array<IFilterField> {
    return [
      {
        key: 'dueDate',
        label: this.pageTranslation.dueDateFilterLabel,
        options: [
          {
            name: taskFilterDueDate.overdue,
            _id: this.DUEDATE_FILTER.OVERDUE,
            active: false,
          },
          {
            name: taskFilterDueDate.this_week,
            _id: this.DUEDATE_FILTER.THIS_WEEK,
            active: false,
          },
          {
            name: taskFilterDueDate.this_month,
            _id: this.DUEDATE_FILTER.THIS_MONTH,
            active: false,
          },
          {
            name: taskFilterDueDate.this_year,
            _id: this.DUEDATE_FILTER.THIS_YEAR,
            active: false,
          },
          {
            name: taskFilterDueDate.today,
            _id: this.DUEDATE_FILTER.TODAY,
            active: false,
          },
        ],
        showAvatar: false,
        pickerType: 'single',
      },
      {
        key: 'status',
        label: this.pageTranslation.statusFilterLabel,
        options: [
          {
            name: taskFilterStatus.open,
            _id: this.STATUS_FILTER.OPEN,
            active: false,
          },
          {
            name: taskFilterStatus.done,
            _id: this.STATUS_FILTER.DONE,
            active: false,
          },
        ],
        showAvatar: false,
        pickerType: 'single',
      },
    ];
  }

  async clearFilter(event: string): Promise<void> {
    this.filters[this.tabKey][event] = event === 'status' ? this.STATUS_FILTER.ALL : this.DUEDATE_FILTER.ALL;
    this.applyFilters();
  }

  async changeFilter(event: { value: any; field: string }): Promise<void> {
    this.filters[this.tabKey][event.field] = event.value;
    this.applyFilters();
  }

  // clearing filters shows everything
  async clearAllFilters(): Promise<void> {
    this.filters[this.tabKey] = {
      status: this.STATUS_FILTER.ALL,
      dueDate: this.DUEDATE_FILTER.ALL,
    };
    this.searchTerm = '';
    this.applyFilters();
  }

  async applyFilters(): Promise<Array<ITask>> {
    try {
      this.injector.get(GlobalBarService).setProgressBar(true);
      const tasks: Array<ITask> = await this.displayTasks();
      this.injector.get(GlobalBarService).setProgressBar(false);
      return tasks;
    } catch (error) {
      this.listTasks = [];
      this.injector.get(ErrorManagerService).handleRawErrorSilently(error, this.constructor.name, 'applyFilters');
    }
  }

  async displayTasks(): Promise<Array<ITask>> {
    try {
      const findQuery: any = {
        ...this.filters[this.tabKey].status.value,
        ...this.filters[this.tabKey].dueDate.value,
        ...this.pageFindQuery,
        // tslint:disable-next-line: strict-boolean-expressions
        ...(this.searchTerm.length >= this.SEARCH_CHARACTERS_MIN_NUMBER && { title: { $regex: this.searchTerm, $options: '$i' } }),
      };

      const tasks: Array<ITask> = await this.findData(findQuery);
      this.groupTasks(tasks);

      this.injector.get(GlobalBarService).setProgressBar(false);
      return tasks;
    } catch (error) {
      this.listTasks = [];
      this.injector.get(ErrorManagerService).handleRawErrorSilently(error, this.constructor.name, 'displayTasks');
    }
  }

  async searchTitle(event: string): Promise<void> {
    const processedSearchTerm = event.trim();
    if (processedSearchTerm === this.searchTerm) {
      return;
    }
    if (processedSearchTerm.length > 0 && processedSearchTerm.length < this.SEARCH_CHARACTERS_MIN_NUMBER) {
      return;
    }
    this.searchTerm = processedSearchTerm;
    this.applyFilters();
  }

  private buildDefaultFilters(): void {
    this.filters = {
      mine: { status: this.STATUS_FILTER.OPEN, dueDate: this.DUEDATE_FILTER.ALL },
      assigned: { status: this.STATUS_FILTER.OPEN, dueDate: this.DUEDATE_FILTER.ALL },
      company: { status: this.STATUS_FILTER.OPEN, dueDate: this.DUEDATE_FILTER.ALL },
    };
  }

  /**
   * Processes the taskcompleted event from task component. task list refresh is handled by another event, which triggers after the animations are done.
   * @param completedTask task to update
   */
  async taskCompleted(completedTask: ITask): Promise<void> {
    const possiblyTruncatedTitle: string =
      completedTask.title.length > this.TASK_TITLE_TRUNCATE_AFTER
        ? `'${completedTask.title.slice(0, this.TASK_TITLE_TRUNCATE_AFTER)}...'`
        : `'${completedTask.title}'`;
    const displayText = this.injector
      .get(I18nDataPipe)
      .transform(this.pageTranslation.completedSuccessfully, { title: possiblyTruncatedTitle });
    const snackBarDismiss: MatLegacySnackBarDismiss = await this.injector
      .get(MatLegacySnackBar)
      .open(displayText, this.pageTranslation.undo, {
        duration: 5000,
      })
      .afterDismissed()
      .toPromise();

    if (snackBarDismiss.dismissedByAction) {
      completedTask.isCompleted = false;
      await this.injector.get(TaskService).updateById(completedTask._id, completedTask);
      this.displayTasks();
    }
  }

  async addTask(): Promise<void> {
    const dialogRef = this.injector.get(MatLegacyDialog).open(TaskDialogComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.displayTasks();
    });
  }
}

interface ITasksFilterOption {
  index: number;
  key: string;
  value: any;
}

interface ITasksTabFilter {
  status: ITasksFilterOption;
  dueDate: ITasksFilterOption;
}

interface ITasksFilter {
  mine: ITasksTabFilter;
  assigned: ITasksTabFilter;
  company: ITasksTabFilter;
}
