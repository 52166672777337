import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from '@app/core/interceptors/authentication.interceptor';
import { ContentTypeInterceptor } from '@app/core/interceptors/content-type.interceptor';
import { TimeProfilerInterceptor } from '@app/core/interceptors/time-profiler.interceptor';

export const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TimeProfilerInterceptor, multi: true }
];
