import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputSimpleEditorComponent } from '@app/standard/components/input-simple-editor/input-simple-editor.component';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { EmailSignatureTemplateService } from '@app/standard/services/email-signature-template/email-signature-template.service';
import { EmailTemplateService } from '@app/standard/services/email-template/email-template.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { CandidateService } from '@app/standard/services/recruiting/candidate.service';
import { PositionService } from '@app/standard/services/recruiting/position.service';
import { IEmail, RecruitingEmailService } from '@app/standard/services/recruiting/recruiting-email.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-mass-email-composer',
  templateUrl: 'mass-email-composer.html',
  styleUrls: ['mass-email-composer.scss'],
})
export class MassEmailComposerComponent implements OnInit {
  minDate: moment.Moment;
  emailDate: moment.Moment = moment();
  emailTimeOptions: Array<ISelectOption> = [];
  emailTimeValue: number;

  dialogTranslation: any = {};
  listTemplates: Array<ISelectOption> = [];
  emailTemplateIdToEmailTemplate: any = {};
  emailTemplateObject: string;
  ccEnabled: boolean = false;
  ccReceivers: string;
  bccEnabled: boolean = false;
  bccReceivers: string;
  emailTemplateChosen: string;
  emailBody: any;
  attachments: Array<any> = [];
  calculatingEmailTemplate: boolean = false;
  subjectValidation: InputValidation;
  bodyValidation: InputValidation;
  sendingEmail: boolean = false;
  emailAddressesOfCandidates: string = '';
  tooltipAddress: string;
  customFieldsAvailable: Array<any>;
  mapPositionIdToPosition: any;
  mapCandidateIdToCandidate: any;
  multipleRecipientsClarificationText: string;
  sendLater: boolean = false;
  loggedUser: any = {};
  loggedUserWork: any = {};

  @Input() readOnlySubject: boolean = false;
  @Input() emailSubject: string;
  @Input() allowSendLater: boolean = true;
  @Input() listPositionCandidates: Array<any>;
  @Output() emailScheduled: EventEmitter<void> = new EventEmitter<void>();
  @Output() emailSent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('bodyQuillEditor', { static: true }) bodyQuillEditor: InputSimpleEditorComponent;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-candidate-emails-page')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
        if (check.assigned(this.listPositionCandidates) && check.nonEmptyArray(this.listPositionCandidates)) {
          this.multipleRecipientsClarificationText = this.injector
            .get(I18nDataPipe)
            .transform(dialogTranslation.multipleRecipientsClarification, { numberOfCandidates: this.listPositionCandidates.length });
        }
        this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
        this.injector
          .get(UserWorkService)
          .getByIdComplete(this.loggedUser._id)
          .then((loggedUserWork) => {
            this.loggedUserWork = loggedUserWork;
          })
          .catch(() => {
            this.loggedUserWork = {};
          });
        this.initDates();
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    Promise.all([this.initListTemplates(), this.initMapCandidateIdToCandidate(), this.initMapPositionIdToPosition()])
      .then(() => {
        this.initEmailAddressesOfCandidates();
      })
      .catch(() => {
        //
      });
  }

  private initListTemplates(): Promise<void> {
    const templateCollections = ['position', 'candidate', 'position-candidate'];
    return new Promise((resolve, reject) => {
      this.injector
        .get(EmailTemplateService)
        .find({ inCollection: { $in: templateCollections } })
        .then((emailTemplates) => {
          if (check.not.array(emailTemplates) || check.emptyArray(emailTemplates)) {
            this.listTemplates = [];
            resolve();
            return;
          }
          const templatesOrder = _.sortBy(emailTemplates, (iTemplate) => {
            return iTemplate.name.toLowerCase();
          });
          this.emailTemplateIdToEmailTemplate = {};
          this.listTemplates = templatesOrder.map((iTemplate) => {
            this.emailTemplateIdToEmailTemplate[iTemplate._id] = iTemplate;
            return { name: iTemplate.name, value: iTemplate._id };
          });
          resolve();
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  }

  private initEmailAddressesOfCandidates(): void {
    if (
      check.not.assigned(this.listPositionCandidates) ||
      check.not.array(this.listPositionCandidates) ||
      check.emptyArray(this.listPositionCandidates)
    ) {
      return;
    }
    const candidateIds = _.chain(this.listPositionCandidates).map('_candidateId').uniq().value();

    this.emailAddressesOfCandidates = candidateIds.reduce((result, iCandidateId) => {
      const firstName = check.assigned(this.mapCandidateIdToCandidate[iCandidateId])
        ? this.mapCandidateIdToCandidate[iCandidateId].firstName
        : '';
      const lastName = check.assigned(this.mapCandidateIdToCandidate[iCandidateId])
        ? this.mapCandidateIdToCandidate[iCandidateId].lastName
        : '';
      const email = check.assigned(this.mapCandidateIdToCandidate[iCandidateId]) ? this.mapCandidateIdToCandidate[iCandidateId].email : '';
      this.tooltipAddress = check.assigned(this.tooltipAddress)
        ? `${this.tooltipAddress}, ${firstName} ${lastName} <${email}>`
        : `${firstName} ${lastName} <${email}>`;
      return result ? `${result}, ${email}` : email;
    }, null);
  }

  private initMapPositionIdToPosition(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(PositionService)
        .getPositions()
        .then((positions) => {
          this.mapPositionIdToPosition = positions.reduce((result, iPosition) => {
            result[iPosition[fieldConstants.ID]] = iPosition;
            return result;
          }, {});
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private initMapCandidateIdToCandidate(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(CandidateService)
        .getCandidates()
        .then((candidates) => {
          this.mapCandidateIdToCandidate = candidates.reduce((result, iCandidate) => {
            result[iCandidate[fieldConstants.ID]] = iCandidate;
            return result;
          }, {});
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public applyEmailTemplate(): void {
    this.calculatingEmailTemplate = true;
    this.emailTemplateObject = this.emailTemplateIdToEmailTemplate[this.emailTemplateChosen].inCollection;
    this.injector
      .get(EmailTemplateService)
      .applyEmailTemplate(this.emailTemplateChosen, {})
      .then((templateResult) => {
        this.cdr.markForCheck();
        this.bodyValidation = new InputValidation();
        this.subjectValidation = new InputValidation();
        if (this.readOnlySubject === false) {
          this.emailSubject = templateResult.subject;
        }
        this.attachments =
          check.assigned(templateResult.attachments) && check.array(templateResult.attachments) ? templateResult.attachments : [];
        this.emailBody = this.convertToQuill(templateResult.body);
        this.calculatingEmailTemplate = false;
        this.cdr.detectChanges();
      })
      .catch(() => {
        const error = new OrgosError(
          'EMAIL TEMPLATE ERROR',
          ErrorCodes.CLIENT_ERROR,
          MassEmailComposerComponent.name,
          'applyEmailTemplate'
        );
        error.message = `Email template could not be applied`;
        this.injector.get(ErrorManagerService).handleParsedError(error);
      });
  }

  private convertToQuill(textInHtml: string): any {
    const result = {
      html: textInHtml,
      delta: undefined,
    };
    return result;
  }

  public sendEmail(): void {
    if (this.sendLater === true) {
      this.sendEmailLater();
    } else {
      this.sendEmailNow();
    }
  }

  private sendEmailNow(): void {
    if (
      !this.isValidEmail() ||
      this.sendingEmail ||
      check.not.assigned(this.listPositionCandidates) ||
      check.emptyArray(this.listPositionCandidates)
    ) {
      return;
    }
    this.sendingEmail = true;
    this.createEmailMessages()
      .then((emailMessages) => {
        const listPromises = this.listPositionCandidates.map((iPositionCandidate, index) => {
          return this.injector.get(RecruitingEmailService).sendEmail(iPositionCandidate._candidateId, emailMessages[index]);
        });
        return Promise.all(listPromises);
      })
      .then(() => {
        const data = {
          numberSelected: this.listPositionCandidates.length,
        };
        this.injector
          .get(MatLegacySnackBar)
          .open(`${this.injector.get(I18nDataPipe).transform(this.dialogTranslation.confirmMassEmailSent, data)}`, 'OK', {
            duration: 5000,
          });
        this.emailSent.emit();
      })
      .catch(() => {
        const error = new OrgosError('EMAIL SENDER ERROR', ErrorCodes.CLIENT_ERROR, MassEmailComposerComponent.name, 'sendEmailNow');
        error.message = `Email could not be sent`;
        this.injector.get(ErrorManagerService).handleParsedError(error);
      });
  }

  private sendEmailLater(): void {
    if (
      !this.isValidEmail() ||
      this.sendingEmail ||
      check.not.assigned(this.listPositionCandidates) ||
      check.emptyArray(this.listPositionCandidates)
    ) {
      return;
    }

    this.sendingEmail = true;
    if (check.assigned(this.emailDate) && check.assigned(this.emailTimeValue)) {
      const emailDate = moment(this.emailDate);
      if (check.not.assigned(emailDate) || check.emptyObject(emailDate)) {
        return;
      }

      emailDate.set({ hour: this.emailTimeValue, minute: 0 });

      this.createEmailMessages()
        .then((emailMessages) => {
          const listPromises = this.listPositionCandidates.map((iPositionCandidate, index) => {
            return this.injector
              .get(RecruitingEmailService)
              .scheduleEmail(iPositionCandidate._candidateId, emailMessages[index], emailDate.toDate());
          });
          return Promise.all(listPromises);
        })
        .then(() => {
          this.injector.get(MatLegacySnackBar).open(this.dialogTranslation.emailScheduledSnackbar, 'OK', { duration: 5000 });
          this.emailScheduled.emit();
        })
        .catch(() => {});
    }
  }

  public reevaluateEmailTimeOptions(): void {
    const today = moment();

    const hours: Array<ISelectOption> = Array.from({ length: 24 }, (item, iHour) => {
      return moment().startOf('day').hour(iHour);
    }).map((iDate) => {
      return { name: iDate.format('LT'), value: iDate.hour() };
    });

    if (today.isBefore(this.emailDate)) {
      this.emailTimeOptions = [].concat(hours);
      return;
    }

    const minEmailTime = this.getMinHour();
    if (this.emailTimeValue < minEmailTime) {
      this.emailTimeValue = minEmailTime;
    }
    this.emailTimeOptions = hours.filter((iOption) => {
      return iOption.value >= minEmailTime;
    });
  }

  private getEmailMessage(iPosition: any, iCandidate: any, iPositionCandidate: any): Promise<IEmail> {
    return new Promise<IEmail>((resolve, reject) => {
      if (check.not.assigned(this.emailTemplateObject) || check.emptyString(this.emailTemplateObject)) {
        const emailMessage: IEmail = {
          subject: this.emailSubject,
          text: this.emailBody && this.emailBody.html ? this.emailBody.html : '',
          html: this.emailBody && this.emailBody.html ? this.emailBody.html : '',
          attachments: this.attachments,
          cc: this.convertToArrayOfEmails(this.ccReceivers),
          bcc: this.convertToArrayOfEmails(this.bccReceivers),
          _positionId: iPosition[fieldConstants.ID],
        };

        if (check.assigned(iPosition) && check.assigned(iPosition._id) && check.nonEmptyString(iPosition._id)) {
          emailMessage._positionId = iPosition._id;
        }
        resolve(emailMessage);
        return;
      }

      let document;
      if (this.emailTemplateObject === 'position') {
        document = iPosition;
      } else if (this.emailTemplateObject === 'candidate') {
        document = iCandidate;
      } else {
        document = iPositionCandidate;
      }

      this.injector
        .get(EmailTemplateService)
        .testEmailTemplate(this.emailSubject, this.emailBody.html, this.emailTemplateObject, document)
        .then((emailMerged) => {
          const emailMessage: IEmail = {
            subject: emailMerged.subject,
            text: emailMerged.body,
            html: emailMerged.body,
            attachments: this.attachments,
            cc: this.convertToArrayOfEmails(this.ccReceivers),
            bcc: this.convertToArrayOfEmails(this.bccReceivers),
            _positionId: iPosition[fieldConstants.ID],
          };
          if (check.assigned(iPosition) && check.assigned(iPosition._id) && check.nonEmptyString(iPosition._id)) {
            emailMessage._positionId = iPosition._id;
          }

          resolve(emailMessage);
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  }

  private convertToArrayOfEmails(stringIn: string): Array<string> {
    if (check.not.assigned(stringIn) || check.emptyString(stringIn)) {
      return [];
    }

    if (check.not.contains(stringIn, ',')) {
      return [stringIn.trim()];
    }

    return stringIn.split(',').map((iEmail: string) => {
      return iEmail.trim();
    });
  }

  public addAttachment(): void {
    this.injector
      .get(UppyHelperService)
      .uploadGenericDocument()
      .then((uploadedDocument: IFileMetadata | null) => {
        if (check.not.assigned(uploadedDocument)) {
          return;
        }

        this.attachments.push(uploadedDocument);
      })
      .catch(() => {});
  }

  public downloadAttachment(attachment): void {
    this.injector
      .get(HttpClient)
      .get(attachment._url, { responseType: 'arraybuffer' })
      .subscribe((arrayBuffer) => {
        FileSaver.saveAs(new Blob([arrayBuffer]), attachment._fileName);
      });
  }

  public addSignature(): void {
    if (check.assigned(this.bodyQuillEditor)) {
      // Fix issue: https://app.asana.com/0/389662948049697/876724777404004
      this.bodyQuillEditor.refreshContent();
    }

    if (
      check.not.assigned(this.loggedUserWork) ||
      check.not.assigned(this.loggedUserWork.emailSignature) ||
      check.not.assigned(this.loggedUserWork.emailSignature.html) ||
      check.equal(this.loggedUserWork.emailSignature.html, '<p><br></p>')
    ) {
      return;
    }
    const withoutParsing = this.loggedUserWork.emailSignature;
    withoutParsing.userId = this.loggedUserWork._id;
    this.injector
      .get(EmailSignatureTemplateService)
      .parseTemplate(withoutParsing)
      .then((signatureParsed) => {
        this.cdr.markForCheck();
        if (this.emailBody && this.emailBody.html) {
          this.emailBody.html += signatureParsed.html;
        } else {
          // Fix: https://app.asana.com/0/389662948049697/1112857414947068/f
          this.emailBody = this.convertToQuill(signatureParsed.html);
          this.cdr.detectChanges();
        }
        if (
          check.assigned(this.emailBody) &&
          check.assigned(this.emailBody.delta) &&
          check.assigned(this.emailBody.delta.ops) &&
          check.assigned(signatureParsed.delta) &&
          check.assigned(signatureParsed.delta.ops)
        ) {
          this.emailBody.delta.ops = this.emailBody.delta.ops.concat(signatureParsed.delta.ops);
        }

        if (check.assigned(this.bodyQuillEditor)) {
          this.bodyQuillEditor.refreshContent();
        }
      })
      .catch(() => {
        //
      });
  }

  public deleteAttachment(attachment): void {
    _.remove(this.attachments, (iAttachment) => {
      return check.equal(attachment._fileNameInS3, iAttachment._fileNameInS3);
    });
  }

  private isValidEmail(): boolean {
    if (
      check.not.assigned(this.subjectValidation) ||
      this.subjectValidation.hasErrors() ||
      check.not.assigned(this.bodyValidation) ||
      this.bodyValidation.hasErrors()
    ) {
      return false;
    }
    return true;
  }

  public validateSchedule(): boolean {
    if (this.sendLater === false) {
      return true;
    }

    if (check.not.assigned(this.emailTimeValue) || check.not.assigned(this.emailDate)) {
      return false;
    }

    return true;
  }

  private initDates(): void {
    this.minDate = moment();
    if (this.minDate.hour() === 23) {
      this.minDate.add(1, 'day').startOf('day');
    }
    this.emailTimeValue = this.getMinHour();
    this.reevaluateEmailTimeOptions();
  }

  private getMinHour(): number {
    const hour: number = this.minDate.hour();
    return hour + 1;
  }

  private createEmailMessages(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      const listPromises = this.listPositionCandidates.map((iPositionCandidate) => {
        const iPosition = this.mapPositionIdToPosition[iPositionCandidate._positionId]
          ? this.mapPositionIdToPosition[iPositionCandidate._positionId]
          : null;
        const iCandidate = this.mapCandidateIdToCandidate[iPositionCandidate._candidateId]
          ? this.mapCandidateIdToCandidate[iPositionCandidate._candidateId]
          : null;
        return this.getEmailMessage(iPosition, iCandidate, iPositionCandidate);
      });
      Promise.all(listPromises)
        .then((emailMessages: Array<any>) => {
          resolve(emailMessages);
          return;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
