import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class VariablePayTypeService implements IGenericService {
  private VARIABLE_PAY_TYPE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/variable-pay-type-db`;
  private VARIABLE_PAY_TYPE_PERMISSIONS_KEY: string = 'variable-pay-type';
  private VARIABLE_PAY_TYPE_INTERNATIONALIZATION: string = 'variable-pay-type-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IVariablePayType> {
    return new Promise<IVariablePayType>((resolve, reject) => {
      this.genericService
        .create(this.VARIABLE_PAY_TYPE_URL, data)
        .then((variablePayType: IVariablePayType) => {
          resolve(variablePayType);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, VariablePayTypeService.name, 'create'));
        });
    });
  }

  getAllTypes(): Promise<Array<IVariablePayType>> {
    return new Promise<Array<IVariablePayType>>((resolve, reject) => {
      this.genericService
        .find(this.VARIABLE_PAY_TYPE_URL, { name: { $ne: null } })
        .then((types: Array<IVariablePayType>) => {
          resolve(types);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, VariablePayTypeService.name, 'getAllTypes'));
        });
    });
  }

  getById(id: string): Promise<IVariablePayType> {
    return new Promise<IVariablePayType>((resolve, reject) => {
      this.genericService
        .getById(this.VARIABLE_PAY_TYPE_URL, id)
        .then((variablePayType: IVariablePayType) => {
          resolve(variablePayType);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, VariablePayTypeService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.VARIABLE_PAY_TYPE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, VariablePayTypeService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.VARIABLE_PAY_TYPE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, VariablePayTypeService.name, 'deleteById'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.VARIABLE_PAY_TYPE_URL);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.VARIABLE_PAY_TYPE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.VARIABLE_PAY_TYPE_INTERNATIONALIZATION);
  }
}

export interface IVariablePayType {
  _id?: string;
  name: string;
}
