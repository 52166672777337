import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class ProjectService {
  private PROJECT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/project-db`;
  private PROJECT_PERMISSIONS_KEY: string = 'project';
  private PROJECT_INTERNATIONALIZATION: string = 'project-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private http: HttpClient, private authenticationService: AuthenticationService) {}

  create(data: any): Promise<IProjectModel> {
    return new Promise<IProjectModel>((resolve, reject) => {
      this.genericService
        .create(this.PROJECT_URL, data)
        .then((project: IProjectModel) => {
          resolve(project);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IProjectModel>> {
    return new Promise<Array<IProjectModel>>((resolve, reject) => {
      this.genericService
        .find(this.PROJECT_URL, findQuery)
        .then((projects: Array<IProjectModel>) => {
          resolve(projects);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<IProjectModel> {
    return new Promise<IProjectModel>((resolve, reject) => {
      this.genericService
        .getById(this.PROJECT_URL, id)
        .then((project: IProjectModel) => {
          resolve(project);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IProjectModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.PROJECT_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.PROJECT_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.PROJECT_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.PROJECT_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.PROJECT_URL);
  }

  // Get all of the projects with basic information (id, title and status)
  getAll(): Promise<Array<IProjectModel>> {
    return new Promise<Array<any>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, ProjectService.name, 'getAllProjects');
        return Promise.reject(this.errorManager.handleRawError(error));
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(this.PROJECT_URL, httpOptions)
        .toPromise()
        .then((myPositionCandidates: Array<any>) => {
          resolve(myPositionCandidates);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectService.name, 'getAllProjects'));
        });
    });
  }

  getAllProjects(): Promise<Array<any>> {
    const findBody = {
      _id: { $ne: null }
    };
    return new Promise<Array<IProjectModel>>((resolve, reject) => {
      this.genericService
        .find(this.PROJECT_URL, findBody)
        .then((allProjects: Array<IProjectModel>) => {
          resolve(allProjects);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectService.name, 'getAll'));
        });
    });
  }
}

export interface IProjectModel {
  _id?: string;
  title?: string;
  description?: string;
  dueDate?: Date;
  startDate?: Date;
  completedDate?: Date;
  projectType?: 'turnkey' | 'timeAndMaterials' | 'training' | 'other';
  status?: 'inactive' | 'active' | 'completed';
  totalHours?: number;
  cost?: number;
  departmentId?: string;
  officeId?: string;
  companyId?: string;
}
