<div *ngIf="arrayPermissionsToDisplay">
  <div *ngIf="permissionKey" class="kenjo-pv-10px kenjo-ph-20px kenjo-mb-10px kenjo-border-bottom kenjo-font-size-16px">
    {{ i18n[permissionKey] }}
  </div>
  <div class="kenjo-ph-20px">
    <ng-container *ngFor="let iPermissionName of arrayPermissionsToDisplay">
      <orgos-column-container *ngIf="!customPermissions[iPermissionName] || customPermissions[iPermissionName] === false" centerColumns="true" class="kenjo-pt-10px">
        <orgos-column size="0" class="kenjo-pr-10px">
          <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues[iPermissionName])" [value]="permissionValues[iPermissionName]" (valueChange)="emitChange(iPermissionName, $event)"> </orgos-input-simple-checkbox>
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ i18n[iPermissionName] }}
        </orgos-column>
      </orgos-column-container>

      <!-- If this permissions is a custom permission, treat it as such -->
      <ng-container *ngIf="customPermissions[iPermissionName] && customPermissions[iPermissionName] === true">
        <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
          <orgos-column size="0">
            <orgos-action icon="add_circle" class="cpb-add-icon" (click)="openCustomPermissionsDialog(iPermissionName)" [disabled]="readOnly || (readOnlyValues && readOnlyValues[iPermissionName])" color="Primary"> </orgos-action>
          </orgos-column>
          <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
            {{ i18n[iPermissionName] }}
          </orgos-column>
        </orgos-column-container>
        <orgos-custom-permission-summary
          *ngIf="permissionKey && permissionValues[iPermissionName] && permissionValues[iPermissionName].length > 0 && !readOnlyValues[iPermissionName]"
          [customPermission]="permissionValues[iPermissionName]"
          [permissionKey]="permissionKey"
          class="kenjo-pl-20px kenjo-pr-10px"
        ></orgos-custom-permission-summary>
      </ng-container>
    </ng-container>
  </div>
</div>
