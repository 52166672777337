import { Component, Injector, OnInit } from '@angular/core';

import { ISelectOption } from '../../../core/select-option';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { IcalService } from '../../../services/ical/ical.service';

declare var document;

@Component({
  selector: 'orgos-export-calendar-popup',
  templateUrl: 'export-calendar-popup.component.html',
  styleUrls: ['export-calendar-popup.component.scss']
})
export class ExportCalendarPopupComponent implements OnInit {
  popupTranslation: any = {};
  calendars: Array<ISelectOption> = [];
  selectedCalendarUrl: string;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    const internationalizationPromise = this.injector.get(InternationalizationService).getAllTranslation('calendar-export-popup');
    const icalPromise = this.injector.get(IcalService).getAvailableCalendars();

    Promise.all([internationalizationPromise, icalPromise])
      .then((results) => {
        this.popupTranslation = results[0];
        this.calendars = results[1];
        if (!this.calendars?.length) {
          return;
        }

        if (this.calendars[0].name === 'Away') {
          this.calendars[0].name = this.popupTranslation.awayCalendar;
        }
        this.selectedCalendarUrl = this.calendars[0].value;
      })
      .catch(() => {
        this.popupTranslation = {};
        this.calendars = [];
      });
  }

  copyUrl(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.selectedCalendarUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
