import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';

import { IMyTeamWidgetSettingsModel } from '../models/my-team-widget-settings.model';

@Injectable({
  providedIn: 'root'
})
export class MyTeamWidgetSettingsService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/my-team-widget-settings-db`;

  constructor(private genericService: GenericService, private http: HttpClient, private injector: Injector, private errorManager: ErrorManagerService) {}

  async find(): Promise<IMyTeamWidgetSettingsModel> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get<IMyTeamWidgetSettingsModel>(`${this.MICROSERVICE_URL}/`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, MyTeamWidgetSettingsService.name, 'find');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      if (check.not.assigned(id) || check.emptyString(id) || check.not.assigned(data) || check.emptyObject(data)) {
        const error = new OrgosError(`${this.MICROSERVICE_URL}`, ErrorCodes.BAD_REQUEST, MyTeamWidgetSettingsService.name, 'updateById');
        throw error;
      }

      await this.genericService.updateById(this.MICROSERVICE_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, MyTeamWidgetSettingsService.name, 'updateById');
    }
  }
}
