import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { calculateShiftDurationPure } from '@app/cloud-features/shift-plan/helpers/shiftplan-card.helper';
import { filterLocationsPure, mapValuesFromRawCollection } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { IShiftPlanTemplate, ShiftPlanTemplateService } from '@app/cloud-features/shift-plan/services/shift-plan-template.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { ILocationOfficeModel } from '@app/standard/services/company/office.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { SHIFT_PLAN_APPROVAL_RULE_REQUIRED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-add-template.dialog',
  templateUrl: 'add-template.dialog.html',
  styleUrls: ['add-template.dialog.scss'],
})
export class AddTemplateDialog implements OnInit {
  template: GenericCacheModel;
  locations: Array<ISelectOption>;
  roles: Array<ISelectOption>;
  workingAreas: ISelectOption[] = [];
  tags: ISelectOption[] = [];

  startTouched: boolean = false;
  endTouched: boolean = false;
  startValidation: InputValidation;
  endValidation: InputValidation;
  locationValidation: InputValidation;
  roleValidation: InputValidation;
  nameValidation: InputValidation;

  hasDurationMinErrors: boolean = false;
  hasDurationMaxErrors: boolean = false;
  hasBreakErrors: boolean = false;

  shiftDuration: { hours: number; minutes: number } = { hours: 0, minutes: 0 };
  isTemplateReady: boolean = false;
  isDialogDisabled: boolean = false;

  pageTranslation: { [key: string]: string } = {};
  loading: boolean = false;
  dataLoaded: boolean = false;
  deleting: boolean = false;

  editMode: boolean = false;
  editDataTouched: boolean = false;
  deletePermission: boolean = false;

  activeLocations: Array<ILocationOfficeModel> = [];
  initialTemplateData: IShiftPlanTemplate;

  SHIFT_PLAN_SETTINGS_ROLES_PATH: string = '/cloud/shift-plan/settings/roles';
  MY_COMPANY_SETTINGS_PATH: string = '/cloud/settings/structure/company';
  MY_OFFICE_SETTINGS_PATH: string = '/cloud/settings/structure/office';

  breaktimeDeduction: boolean = false;
  loggedUser: IUserAccountModel;
  isUserAdmin: boolean = false;
  approvalRuleOptions: Array<ISelectOption>;
  SHIFT_PLAN_APPROVAL_RULE_REQUIRED = SHIFT_PLAN_APPROVAL_RULE_REQUIRED;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddTemplateDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    public snackBar: MatLegacySnackBar,
    private confirmDialog: MatLegacyDialog,
    protected router: Router
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit() {
    this.initData();
  }

  async initData() {
    this.dataLoaded = false;

    this.filterLocations();
    const templateRawData = check.assigned(this.dialogInfo.template) ? this.createTemplateFromData() : this.createNewTemplateData();
    this.template = new GenericCacheModel(this.injector, templateRawData, ShiftPlanTemplateService, '');
    this.template.canEdit = Promise.resolve(true);

    if (this.dialogInfo.editMode === true) {
      await this.initEditModeData();
    } else {
      await this.getData();
    }
    await this.getPermissions();

    this.dataLoaded = true;
  }

  async initEditModeData() {
    this.dialogRef.disableClose = true;
    this.editMode = true;
    await this.getData();
    this.calculateShiftDuration();
  }

  async getData() {
    this.loading = false;
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.isUserAdmin = this.loggedUser.profileKey === 'admin' || this.loggedUser.profileKey === 'hr-admin';
    this.breaktimeDeduction = this.dialogInfo.breaktimeDeduction;
    this.pageTranslation = await this.fetchTranslation();
    this.pageTranslation = { ...this.pageTranslation, ...this.dialogInfo.translations };
    this.roles = mapValuesFromRawCollection(this.dialogInfo.response.roles);
    this.workingAreas = mapValuesFromRawCollection(this.dialogInfo.response.workingAreas);
    this.tags = mapValuesFromRawCollection(this.dialogInfo.response.tags);
    this.isDialogDisabled = this.roles.length === 0 || this.locations.length === 0;
    this.initApprovalRulesOptions();
  }

  async fetchTranslation() {
    return await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-add-shift-dialog');
  }

  public navigateTo(path: string): void {
    this.injector.get(Router).navigateByUrl(path);
    this.dialogRef.close(true);
  }

  async createTemplate() {
    this.loading = true;
    try {
      const response = await this.injector.get(ShiftPlanTemplateService).createTemplate(this.template.data);
      this.loading = false;

      if (check.not.assigned(response)) {
        return;
      }

      this.dialogRef.close(true);
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('new template created', { category: 'Shiftplan', subcategory: 'templates', subcategory2: 'creation' });
      this.showSnackbar(this.pageTranslation.snackbarTemplateCreated, 'OK');
    } catch {
      this.loading = false;
    }
  }

  private createTemplateFromData() {
    const template = { ...this.dialogInfo.template };
    const activeLocationsIds = this.activeLocations.map((iLocation) => iLocation._id);

    if (!activeLocationsIds.includes(template.locationId)) {
      template.locationId = null;
    }

    template.name = this.dialogInfo.template.name ?? '';
    template.locationId = this.dialogInfo.template.locationId;
    template.workingAreaId = template.workingAreaId ?? null;
    template.tagId = template.tagId ?? null;
    template.notes = template.notes ?? '';
    template.break = template.break ?? 0;
    template.approvalRule = template.approvalRule ?? this.SHIFT_PLAN_APPROVAL_RULE_REQUIRED;

    this.initialTemplateData = { ...template };
    return { ...template };
  }

  async editTemplate() {
    try {
      this.loading = true;
      const templateToEdit = this.createTemplateBodyToEdit();
      const templateId = this.template.data._id;
      await this.injector.get(ShiftPlanTemplateService).updateById(templateId, templateToEdit);
      this.loading = false;
      this.showSnackbar(this.pageTranslation.snackbarSuccessTemplateEdited, 'OK');
      this.dialogRef.close(true);
    } catch {
      // do nothing
    }
  }
  private createTemplateBodyToEdit() {
    const template = { ...this.template.data };
    return {
      name: template.name,
      start: template.start,
      end: template.end,
      break: template.break,
      locationId: template.locationId,
      workingAreaId: template.workingAreaId,
      roleId: template.roleId,
      tagId: template.tagId,
      notes: template.notes,
      approvalRule: template.approvalRule,
    };
  }

  async removeTemplate() {
    try {
      const templateId = this.template.data._id;
      const data = {
        titleText: this.pageTranslation.deleteTemplateTitle,
        subtitleText: this.pageTranslation.deleteTemplateSubtitle,
        confirmButtonText: this.pageTranslation.yesDelete,
        confirmButtonColor: 'Danger',
        cancelButtonText: this.pageTranslation.backButton,
      };
      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe(async (confirm) => {
        this.loading = true;
        this.deleting = true;
        if (confirm === true) {
          await this.injector.get(ShiftPlanTemplateService).deleteById(templateId);
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('new template deleted', { category: 'Shiftplan', subcategory: 'templates', subcategory2: 'deletion' });
          this.showSnackbar(this.pageTranslation.snackbarSuccessTemplateDeleted, 'OK');
          this.dialogRef.close(true);
        }
        this.loading = false;
        this.deleting = false;
      });
    } catch {
      // do nothing
    }
  }

  calculateShiftDuration() {
    const { shiftDuration, errors } = calculateShiftDurationPure(
      this.template.data.start,
      this.template.data.end,
      this.template.data.break ?? 0,
      this.breaktimeDeduction
    );
    this.shiftDuration = { ...shiftDuration };
    this.hasDurationMinErrors = errors['hasDurationMinErrors'];
    this.hasDurationMaxErrors = errors['hasDurationMaxErrors'];
    this.hasBreakErrors = errors['hasBreakErrors'];
  }

  private createNewTemplateData() {
    return {
      name: undefined,
      start: undefined,
      end: undefined,
      break: 0,
      locationId: undefined,
      workingAreaId: undefined,
      roleId: undefined,
      tagId: undefined,
      notes: undefined,
      approvalRule: undefined,
    };
  }

  public closeDialog() {
    if (this.loading) {
      return;
    }

    if (!this.editDataTouched) {
      this.dialogRef.close(false);
      return;
    }

    const data = {
      titleText: this.pageTranslation.closeTemplatePopUpHeader,
      subtitleText: this.pageTranslation.closeTemplatePopUpText,
      confirmButtonText: this.pageTranslation.yesExit,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.pageTranslation.backButton,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm) => {
      if (confirm === true) {
        this.dialogRef.close(false);
      }
    });
  }

  public showSnackbar(message: string, action: string): void {
    this.injector.get(MatLegacySnackBar).open(message, action, { duration: 5000 });
  }

  filterLocations(): void {
    this.locations = filterLocationsPure(this.dialogInfo.response.locations);
  }

  setEditTouched() {
    this.editDataTouched = false;
    if (!_.isEqual(this.initialTemplateData, this.template.data)) {
      this.editDataTouched = true;
    }
  }

  setEditApprovalRule() {
    if (this.template.data.approvalRule === null) {
      delete this.template.data.approvalRule;
    }
  }

  checkTemplateReady() {
    const allMandatoryDataFilled =
      check.assigned(this.template.data.name) &&
      check.assigned(this.template.data.start) &&
      check.assigned(this.template.data.end) &&
      check.assigned(this.template.data.locationId) &&
      check.assigned(this.template.data.roleId);
    const inputsValidation =
      !this.startValidation ||
      this.startValidation.hasErrors() ||
      !this.endValidation ||
      this.endValidation.hasErrors() ||
      !this.locationValidation ||
      this.locationValidation.hasErrors() ||
      !this.roleValidation ||
      this.roleValidation.hasErrors() ||
      !this.nameValidation ||
      this.nameValidation.hasErrors();
    const durationTimeErrors = this.hasDurationMaxErrors || this.hasDurationMinErrors || this.hasBreakErrors;

    this.isTemplateReady = allMandatoryDataFilled && !inputsValidation && !durationTimeErrors;
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  public setTemplatePermissions(shiftplanPermissions: IShiftPlanPermissions): boolean {
    if (shiftplanPermissions['templates'].delete_all === true) {
      return false;
    }

    if (shiftplanPermissions['templates'].delete_own === true) {
      return this.template.data.ownerId !== this.loggedUser._id;
    }
    return true;
  }

  async getPermissions(): Promise<void> {
    const shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
    this.deletePermission = this.setTemplatePermissions(shiftplanPermissions);
  }

  initApprovalRulesOptions() {
    const approvalRulesValues = this.dialogInfo.openShiftSettings ?? { requiresApproval: true, autoAssign: false };
    this.approvalRuleOptions = Object.entries(approvalRulesValues)
      .filter(([_, value]) => value === true)
      .map(([key, _]) => {
        return {
          name: this.pageTranslation[key],
          value: key,
        };
      });
  }

  public navigateToOfficeLocation(): void {
    const path = this.dialogInfo.response.locations.length > 0 ? this.MY_OFFICE_SETTINGS_PATH : this.MY_COMPANY_SETTINGS_PATH;
    const url = this.router.serializeUrl(this.router.createUrlTree([path]));
    window.open(url, '_blank');
  }
}
