import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as check from 'check-types';

@Component({
  selector: 'orgos-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss']
})
export class AvatarComponent {
  nameInitials: string;
  sizeClass: string = 'avatar-medium';
  overlayDiameter: number = 70;
  textTypeClass: string = 'Headline3';
  anonymousClass: string = 'ac-oval-medium';
  anonymousIcon: string = 'ac-anonymous-icon-medium';

  @Input() isAnonymous: boolean = false;
  @Input() photoUrl: string;
  @Input()
  set avatarName(name: string) {
    this.calculateInitials(name);
  }

  private _size: 'large' | 'medium' | 'small' | 'smaller' | 'extra-small' | 'tiny' = 'medium';
  @Input()
  set size(size: 'large' | 'medium' | 'small' | 'smaller' | 'extra-small' | 'tiny') {
    this._size = size;
    if (size === 'large') {
      this.sizeClass = 'avatar-large';
      this.overlayDiameter = 80;
      this.textTypeClass = 'Headline2';
      this.anonymousClass = 'ac-oval-large';
      this.anonymousIcon = 'ac-anonymous-icon-large';
    } else if (size === 'medium') {
      this.sizeClass = 'avatar-medium';
      this.overlayDiameter = 70;
      this.textTypeClass = 'Headline3';
      this.anonymousClass = 'ac-oval-medium';
      this.anonymousIcon = 'ac-anonymous-icon-medium';
    } else if (size === 'small') {
      this.sizeClass = 'avatar-small';
      this.overlayDiameter = 50;
      this.textTypeClass = 'NormalText';
      this.anonymousClass = 'ac-oval-small';
      this.anonymousIcon = 'ac-anonymous-icon-small';
    } else if (size === 'smaller') {
      this.sizeClass = 'avatar-smaller';
      this.overlayDiameter = 50;
      this.textTypeClass = 'NormalText';
      this.anonymousClass = 'ac-oval-smaller';
      this.anonymousIcon = 'ac-anonymous-icon-smaller';
    } else if (size === 'extra-small') {
      this.sizeClass = 'avatar-extra-small';
      this.overlayDiameter = 20;
      this.textTypeClass = 'SecondaryText';
      this.anonymousClass = 'ac-oval-extra-small';
      this.anonymousIcon = 'ac-anonymous-icon-extra-small';
    } else if (size === 'tiny') {
      this.sizeClass = 'avatar-tiny';
      this.overlayDiameter = 20;
      this.textTypeClass = 'SecondaryText';
      this.anonymousClass = 'ac-oval-tiny';
      this.anonymousIcon = 'ac-anonymous-icon-tiny';
    } else {
      this.size = 'medium';
      this.sizeClass = 'avatar-medium';
      this.overlayDiameter = 70;
      this.textTypeClass = 'Headline3';
      this.anonymousClass = 'ac-oval-medium';
      this.anonymousIcon = 'ac-anonymous-icon-medium';
    }
  }
  get size(): 'large' | 'medium' | 'small' | 'smaller' | 'extra-small' | 'tiny' {
    return this._size;
  }

  @Input() enableChangePhoto: boolean = false;
  @Output() changePhoto: EventEmitter<void> = new EventEmitter<void>();

  onAvatarClick(): void {
    if (this.enableChangePhoto === true) {
      this.changePhoto.emit();
    }
  }

  private calculateInitials(name: string): void {
    if (check.not.assigned(name) || check.not.string(name)) {
      this.nameInitials = '-';
      return;
    }

    const avatarName = name.trim();

    if (check.emptyString(avatarName)) {
      this.nameInitials = '-';
      return;
    }

    const words = avatarName.trim().split(' ');
    if (check.not.array(words) || check.emptyArray(words) || words.length < 2) {
      this.nameInitials = `${avatarName.charAt(0)}${avatarName.charAt(0)}`.toUpperCase();
      return;
    }

    if (words[0] === '+') {
      // If the name is a number (EG +22), use the whole word -> +22
      this.nameInitials = `${words[0].trim()}${words[1].trim()}`;
    } else {
      // If the name is a proper name (EG John Smith). use the initials -> JS
      this.nameInitials = `${words[0].trim().charAt(0)}${words[1].trim().charAt(0)}`.toUpperCase();
    }
  }
}
