import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { IDocumentModel } from './document.service';

@Injectable()
export class DocumentTemplateService {
  private DOCUMENT_TEMPLATE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/document-template-db`;
  private DOCUMENT_TEMPLATE_PERMISSIONS_KEY: string = 'document-template';
  private DOCUMENT_TEMPLATE_INTERNATIONALIZATION: string = 'document-template-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IDocumentTemplateModel> {
    return new Promise<IDocumentTemplateModel>((resolve, reject) => {
      this.genericService
        .create(this.DOCUMENT_TEMPLATE_URL, data)
        .then((document: IDocumentTemplateModel) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTemplateService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IDocumentTemplateModel> {
    return new Promise<IDocumentTemplateModel>((resolve, reject) => {
      this.genericService
        .getById(this.DOCUMENT_TEMPLATE_URL, id)
        .then((document: IDocumentTemplateModel) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTemplateService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.DOCUMENT_TEMPLATE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTemplateService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.DOCUMENT_TEMPLATE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTemplateService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.DOCUMENT_TEMPLATE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.DOCUMENT_TEMPLATE_INTERNATIONALIZATION);
  }

  getAllTemplates(): Promise<Array<IDocumentTemplateModel>> {
    return new Promise<Array<IDocumentTemplateModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_TEMPLATE_URL}/find`, ErrorCodes.UNAUTHORIZED, DocumentTemplateService.name, 'getAllTemplates');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const findBody = {
        _id: { $ne: null }
      };

      this.genericService
        .find(this.DOCUMENT_TEMPLATE_URL, findBody)
        .then((templates: Array<IDocumentTemplateModel>) => {
          resolve(templates);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTemplateService.name, 'getAllTemplates'));
        });
    });
  }

  createDocument(body: any): Promise<IDocumentModel> {
    return new Promise<IDocumentModel>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_TEMPLATE_URL}/create-document`, ErrorCodes.UNAUTHORIZED, DocumentTemplateService.name, 'createDocument');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.DOCUMENT_TEMPLATE_URL}/create-document`, body, httpOptions)
        .toPromise()
        .then((documentCreated) => {
          resolve(documentCreated);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentTemplateService.name, 'createDocument'));
        });
    });
  }
}

export interface IDocumentTemplateModel {
  _id?: string;
  _file?: IFileMetadata;
  ownerId?: string;
  name?: string;
  _createdBy?: string;
  _createdAt?: Date;
  fields?: Array<{ tag: string; field: string }>;
  setupDone?: boolean;
}

export interface IDocumentTemplateCreateModel {
  _file: IFileMetadata;
  name: string;
  setupDone: boolean;
  _url?: string;
}
