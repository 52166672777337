<orgos-column-container centerColumns="true">
  <orgos-column class="kenjo-ml-15px" size="0">
    <orgos-avatar [photoUrl]="user._photo?._url" [avatarName]="user.displayName" size="small" class="kenjo-mr-10px"></orgos-avatar>
  </orgos-column>
  <orgos-column size="1">
    <div class="isu-truncate-text" [style.max-width]="'200px'">{{ user.displayName }}</div>
    <orgos-column-container class="kenjo-mt-5px" orgosColumn *ngIf="isSameWeek">
      <div orgosColumn="0" class="kenjo-mr-5px" [ngClass]="workingDay ? 'isuc-workingDay' : 'isuc-notWorkingDay'" *ngFor="let workingDay of employeeSchedules?.[user._id]?.workSchedule ?? DEFAULT_SCHEDULE"></div>
    </orgos-column-container>
  </orgos-column>
  <orgos-column class="isu-full-match" [ngClass]="isFullMatch ? 'isu-show' : 'isu-hide'">
    <kenjo-icon class="kenjo-pl-10px kenjo-pr-5px" [size]="20">verified</kenjo-icon>
  </orgos-column>
  <orgos-column-container orgosColumn="2">
    <div orgosColumn="0" [class.isuc-opacity]="notMatchedEntities[entity._id]" *ngFor="let entity of user.entities | slice: 0:2">
      <orgos-chip [hoverButton]="true" [color]="entity.colour || 'Disabled'" class="kenjo-font-size-14px kenjo-mr-10px kenjo-text-align-center">
        <div class="isu-truncate-text" [style.min-width]="'140px'" [style.max-width]="'140px'">{{ entity.name }}</div>
      </orgos-chip>
    </div>
    <div orgosColumn="0" class="isuc-tag-circle" [class.hide]="user.entities?.length <= 2" [matTooltip]="extraEntitiesTooltip" matTooltipClass="isuc-custom-tooltip">
      <div>+ {{ user.entities?.length - 2 }}</div>
    </div>
  </orgos-column-container>
</orgos-column-container>
