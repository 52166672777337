<orgos-container *ngIf="loadingWidget" aspect="card" class="widget-container">
  <div *ngIf="titleKey" class="widget-header kenjo-pv-10px kenjo-ph-20px">
    <orgos-text type="Headline4">{{ nameTranslation[titleKey] }}</orgos-text>
  </div>
  <div class="widget-loading-spinner kenjo-p-20px">
    <div>
      <orgos-loading-spinner></orgos-loading-spinner>
      <orgos-text type="SecondaryText" color="LightText" class="kenjo-mt-20px">{{ translation.loadingMessage }}</orgos-text>
    </div>
  </div>
</orgos-container>

<orgos-container *ngIf="!loadingWidget && existingData" aspect="card" class="widget-container">
  <div *ngIf="titleKey" class="kenjo-ph-20px widget-header kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-flex-spread" [ngClass]="chipComponent ? 'widget-title-chip-padding': 'kenjo-pv-10px'">
    <div class="kenjo-flex-size-1 kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-flex-gap-10px">
      <orgos-text type="Headline4">{{ nameTranslation[titleKey] }}</orgos-text>
      <ng-content select="orgos-widget-header-chip"></ng-content>
    </div>
    <div class="kenjo-flex-size-0">
      <ng-content select="orgos-widget-header-action"></ng-content>
    </div>
  </div>
  <div class="widget-content" [class.kenjo-p-20px]="!noContentPadding" [style.height.px]="footerHeight > 0 ? 353 - footerHeight - (noContentPadding ? 0 : 40) : 353">
    <ng-content select="orgos-widget-content"></ng-content>
  </div>
  <div *ngIf="footerComponent && footerHeight > 0" class="widget-footer kenjo-p-20px" [style.height.px]="footerHeight" [style.backgroundColor]="footerBackgroundColor">
    <ng-content select="orgos-widget-footer"></ng-content>
  </div>
</orgos-container>

<orgos-container *ngIf="!loadingWidget && !existingData" aspect="card" class="widget-container">
  <div *ngIf="titleKey" class="kenjo-pv-10px kenjo-ph-20px widget-header">
    <orgos-text type="Headline4">{{ nameTranslation[titleKey] }}</orgos-text>
  </div>
  <div class="widget-no-data kenjo-p-20px">
    <orgos-column-container centerColumns="true">
      <orgos-column size="0">
        <mat-icon class="widget-no-data-icon">widgets</mat-icon>
      </orgos-column>
      <orgos-column size="0" class="kenjo-ml-10px widget-no-data-text">
        <orgos-text type="SecondaryText" color="LightText">{{ translation[titleKey] || translation.noDataMessage }}</orgos-text>
      </orgos-column>
    </orgos-column-container>
  </div>
</orgos-container>
