import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-edit-cost-centers-dialog',
  templateUrl: 'edit-cost-centers.dialog.html',
  styleUrls: ['edit-cost-centers.dialog.scss'],
})
export class EditCostCentersDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  costCenterQuantityValidation: InputValidation;

  costCenter;
  costCenterOptions;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditCostCentersDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public parentInfo: any,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.initTranslations();
    this.costCenter = check.assigned(this.parentInfo.costCenter)
      ? this.parentInfo.costCenter
      : {
          quantity: null,
          _costCenterId: '',
        };
    this.costCenterOptions = this.parentInfo.costCenterOptions;
  }

  private async initTranslations(): Promise<void> {
    try {
      this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('edit-cost-centers-dialog');
      this.miscTranslation = await this.injector.get(InternationalizationService).getAllTranslation('misc');
    } catch {
      this.dialogTranslation = {};
      this.miscTranslation = {};
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.dialogRef.close(this.costCenter);
  }
}

export interface IEditCostCentersData {}
