import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { AttendanceSummaryService } from '@app/cloud-features/settings-attendance/services/attendance-summary.service';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { OvertimeBalanceDetailsController } from '@app/standard/services/user/controllers/overtime-balance-details.controller';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-negative-balance',
  templateUrl: 'overtime-negative-balance.dialog.html',
  styleUrls: ['overtime-negative-balance.dialog.scss'],
})
export class OvertimeNegativeBalanceDialog implements OnInit {
  i18n: any = {};

  /**
   * @param dialogRef reference to the dialog
   * @param data object like:
   *  overtime: {userId: string, year: number, month: number}
   * @param injector injector of angular
   */
  constructor(
    public dialogRef: MatLegacyDialogRef<OvertimeNegativeBalanceDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('overtime-negative-balance-dialog')
      .then((i18n: any) => {
        this.i18n = i18n;
      })
      .catch(() => {
        this.i18n = {};
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
