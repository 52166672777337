import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

import { InputValidation } from '../../../../core/validation/input-validation';
import { InternationalizationService } from '../../../../services/core/internationalization.service';
import { IVariablePayType, VariablePayTypeService } from '../../../../services/variable-pay-type/variable-pay-type.service';

@Component({
  selector: 'orgos-dialog-add-variable-pay-type',
  templateUrl: 'add-variable-pay-type.dialog.html',
  styleUrls: ['add-variable-pay-type.dialog.scss'],
})
export class AddVariablePayTypeDialog implements OnInit {
  pageTranslation: any = {};
  typeName: string;

  variablePayTypeValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddVariablePayTypeDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('add-variable-pay-type-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  save(): void {
    const variablePayType: IVariablePayType = {
      name: this.typeName,
    };

    this.injector
      .get(VariablePayTypeService)
      .create(variablePayType)
      .then((newType) => {
        this.dialogRef.close(newType);
      })
      .catch((e) => {});
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
