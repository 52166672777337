import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'orgos-page-message',
  templateUrl: 'page-message.component.html',
  styleUrls: ['page-message.component.scss']
})
export class PageMessageComponent {
  @Input() messageText: string;

  private _messageType: 'warning' | 'error' | 'info' = 'warning';
  @Input()
  set messageType(messageTypeIn: 'warning' | 'error' | 'info') {
    this._messageType = messageTypeIn;

    this.warningAreaClass = false;
    this.errorAreaClass = false;
    this.infoAreaClass = false;

    switch (messageTypeIn) {
      case 'warning': {
        this.warningAreaClass = true;
        break;
      }
      case 'error': {
        this.errorAreaClass = true;
        break;
      }
      case 'info': {
        this.infoAreaClass = true;
        break;
      }
      default: {
        this.warningAreaClass = true;
        break;
      }
    }
  }
  get messageType(): 'warning' | 'error' | 'info' {
    return this._messageType;
  }

  @HostBinding('class.pm-warning-area') warningAreaClass: boolean = true;
  @HostBinding('class.pm-error-area') errorAreaClass: boolean = false;
  @HostBinding('class.pm-info-area') infoAreaClass: boolean = false;
}
