import { Component, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';

import { GenericCacheModel } from '../../core/generic-cache-model';
import { GenericSimpleModel } from '../../core/generic-simple-model';
import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-custom-fields',
  templateUrl: 'custom-fields.component.html',
  styleUrls: ['custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit {
  @Input() model: GenericSimpleModel | GenericCacheModel;
  @Input() collectionName: string;
  @Input() maxFieldsPerRow: number = 2;
  @Input() columnContainerClass: string = '';
  @Input() leftColumnClass: string = '';
  @Input() rightColumnClass: string = '';
  listCustomFields: Array<Array<object>>;

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    if (check.assigned(this.collectionName) && check.nonEmptyString(this.collectionName)) {
      this.fetchCollectionCustomFields(this.collectionName)
        .then((customFieldsOfCollection) => {
          this.listCustomFields = this.getCustomFieldsGrouped(customFieldsOfCollection);
        })
        .catch(() => {
          // Error will be handled by the service
          this.listCustomFields = [];
        });
    }
  }

  private fetchCollectionCustomFields(collectionName: string): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      const customFieldServiceClass = this.standardServicesRegistry.getService('CustomField');
      this.injector
        .get(customFieldServiceClass)
        .getByCollection(collectionName)
        .then((customFieldsOfCollection) => {
          if (check.not.assigned(customFieldsOfCollection) || check.emptyObject(customFieldsOfCollection)) {
            resolve([]);
          }
          resolve(customFieldsOfCollection);
        })
        .catch((error) => {
          // Error will be handled by the service
          reject(error);
        });
    });
  }

  // Returns an array of arrays, where each array is a row of fields in the layout (these rows can be composed by 1 or maxFieldsPerRow fields)
  private getCustomFieldsGrouped(listCustomFields: Array<object>): Array<Array<object>> {
    if (check.not.assigned(listCustomFields) || check.not.array(listCustomFields) || check.emptyArray(listCustomFields)) {
      return [];
    }

    const customFieldsGroupedByRow = [];
    const firstRow = [];
    customFieldsGroupedByRow.push(firstRow);

    let rowNumber = 0;
    listCustomFields.forEach((iCustomField: any) => {
      if (check.equal('TextArea', iCustomField.fieldType)) {
        if (customFieldsGroupedByRow[rowNumber].length > 0) {
          customFieldsGroupedByRow.push([]);
          rowNumber++;
        }
        customFieldsGroupedByRow[rowNumber].push(iCustomField);
        customFieldsGroupedByRow.push([]);
        rowNumber++;
        return;
      }

      customFieldsGroupedByRow[rowNumber].push(iCustomField);
      if (customFieldsGroupedByRow[rowNumber].length === this.maxFieldsPerRow) {
        customFieldsGroupedByRow.push([]);
        rowNumber++;
      }
    });

    return customFieldsGroupedByRow;
  }
}
