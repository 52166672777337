import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plusMinusSign'
})
export class PlusMinusSignPipe implements PipeTransform {
  transform(value: string): string {
    const formatted = value.replace(',', '.');
    const number = Number(formatted);
    if (number > 0) {
      return `+${value}`;
    }

    return `${value}`;
  }
}
