import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-simple-truncate-text',
  templateUrl: 'simple-truncate-text.component.html',
  styleUrls: ['simple-truncate-text.component.scss'],
})
export class SimpleTruncateTextComponent implements AfterContentChecked {
  enableTooltip: boolean = false;
  tooltipText: string = '';

  @Input() vertical: boolean = false;
  @Input() lines: number = 1;

  @ViewChild('content', { static: false }) content: ElementRef;
  @HostBinding('style.--lines') textLines: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.refreshTooltip();
  }

  refreshTooltip(): void {
    if (check.not.assigned(this.content)) {
      this.tooltipText = '';
      this.enableTooltip = false;
      this.cdr.detectChanges();
      return;
    }

    this.tooltipText = this.content.nativeElement.innerText;
    this.enableTooltip = this.content.nativeElement.scrollWidth > this.content.nativeElement.clientWidth;

    if (this.vertical) {
      this.textLines = this.lines;
      this.enableTooltip =
        this.content.nativeElement.scrollWidth > this.content.nativeElement.clientWidth ||
        this.content.nativeElement.scrollHeight > this.content.nativeElement.clientHeight + 1;
    }

    this.cdr.detectChanges();
  }
}
