import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ITimeOffAssignmentModel, TimeOffStatusService } from '@app/cloud-features/time-off/services/time-off-status.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { TIME_OFF_ASSIGNMENT_ACTION_UNASSIGN } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-manage-policies-dialog',
  templateUrl: 'manage-policies.dialog.html',
  styleUrls: ['manage-policies.dialog.scss'],
})
export class ManagePoliciesDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  loadingData: boolean = true;
  savingData: boolean = false;
  userPersonal: any;
  userStartDate: any;
  timeOffTypes: any;
  userStatus: any;
  scheduledPolicies: any;
  assignments: Array<ITimeOffAssignmentModel> = [];

  constructor(
    private injector: Injector,
    private dialogRef: MatLegacyDialogRef<ManagePoliciesDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.initPage();
  }

  private async initPage() {
    try {
      this.loadingData = true;
      const [dialogTranslation, miscTranslation] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('manage-policies-dialog'),
        this.injector.get(InternationalizationService).getAllTranslation('misc'),
      ]);
      this.dialogTranslation = dialogTranslation;
      this.miscTranslation = miscTranslation;
      const { userPersonal, userStartDate, timeOffTypes, userStatus, scheduledPolicies } = this.data;
      this.userPersonal = userPersonal;
      this.userStartDate = userStartDate;
      this.timeOffTypes = timeOffTypes;
      this.userStatus = userStatus;
      this.scheduledPolicies = scheduledPolicies;
      this.loadingData = false;
    } catch {
      this.dialogTranslation = {};
      this.miscTranslation = {};
    }
  }

  saveChanges(): void {
    this.savingData = true;
    const hasUnassignCalls = this.assignments.some((assignment) => assignment.action === TIME_OFF_ASSIGNMENT_ACTION_UNASSIGN);
    if (hasUnassignCalls === true) {
      const dialogData = {
        titleText: this.dialogTranslation.confirmUnassignmentManagePoliciesDialogTitle,
        subtitleText: this.dialogTranslation.confirmUnassignmentManagePoliciesDialogSubtitle,
        confirmButtonText: this.dialogTranslation.confirmUnassignmentManagePoliciesDialogConfirmButton,
        confirmButtonColor: 'Success',
        cancelButtonText: this.dialogTranslation.confirmUnassignmentManagePoliciesDialogCancelButton,
      };
      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
      dialogRef.afterClosed().subscribe((confirmClose: boolean) => {
        if (confirmClose === true) {
          this.confirmSaveChanges();
        } else {
          this.savingData = false;
        }
      });
    } else {
      this.confirmSaveChanges();
    }
  }

  private async confirmSaveChanges(): Promise<void> {
    try {
      await this.injector.get(TimeOffStatusService).assignUserPolicies(this.assignments);
      const managePolicyActions = this.getUniqueActions(this.assignments);
      await this.logAmplitudeActions(managePolicyActions);
      await this.injector
        .get(PrivateAmplitudeService)
        .logEvent('manage time off', { category: 'Time off', platform: 'Web', type: 'manage policies' });
      this.injector.get(PrivateIntegrationsService).trackChameleonEvent('time off policy assigned');
      this.dialogRef.close(true);
      this.injector.get(MatLegacySnackBar).open(this.dialogTranslation.saveChangesSnackbar, 'OK', {
        duration: 5000,
      });
    } catch {
      // Do nothing
    }
    this.savingData = false;
  }

  public closeDialog(): void {
    // If there are no changes on the policy data, don't show the closing confirmation dialog
    if (check.emptyArray(this.assignments)) {
      this.dialogRef.close(false);
      return;
    }

    const dialogData: any = {
      titleText: this.dialogTranslation.closeManagePoliciesDialogTitle,
      subtitleText: this.dialogTranslation.closeManagePoliciesDialogSubtitle,
      confirmButtonText: this.dialogTranslation.closeManagePoliciesDialogConfirmButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.dialogTranslation.closeManagePoliciesDialogCancelButton,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe((confirmClose: boolean) => {
      if (check.assigned(confirmClose) && confirmClose === true) {
        this.dialogRef.close(false);
      }
    });
  }

  private getUniqueActions(assignments: Array<ITimeOffAssignmentModel>) {
    const uniqueActions = new Set();
    assignments.forEach((assignment) => {
      if (assignment.action) {
        uniqueActions.add(assignment.action);
      }
    });

    return Array.from(uniqueActions); // Convert set to an array
  }

  private async logAmplitudeActions(actions: Array<any>): Promise<Array<void>> {
    const amplitudeEvents = actions.map((action) => {
      this.injector.get(PrivateAmplitudeService).logEvent(`${action} time off policy`.toLowerCase(), {
        platform: 'Web',
        category: 'Time off',
        subcategory1: 'Settings',
        type: 'manage policies',
      });
    });
    return Promise.all(amplitudeEvents);
  }
}

type ApplyDate = 'StartDate' | 'ThisCycle' | 'Today' | 'NextCycle' | 'EmployeeStartDate';

interface IPolicyToManage {
  timeOffTypeId: string;
  timeOffTypeName: string;
  policiesOptions: Array<ISelectOption>;
  oldPolicyId: string | null;
  oldPolicyType: string | null;
  newPolicyId: string | null;
  newPolicyType: string | null;
  applyDateOptions?: Array<ISelectOption>;
  applyDate?: ApplyDate;
  calculatedBalance?: number;
  unlimitedBalance?: boolean;
  scheduledAssignment?: {
    policyId: string;
    policyName: string;
    nameUserScheduled: string;
    pictureUserScheduled: string;
  };
  _policiesTypes?: any;
}

export interface IManagePoliciesOfEmployee {
  userId: string;
  unassignedPolicies: Array<IUnassignedPoliciesToSave>;
  newPolicyAssignments: Array<INewPolicyAssignmentsToSave>;
}

interface IUnassignedPoliciesToSave {
  timeOffTypeId: string;
  policyId: string;
}

interface INewPolicyAssignmentsToSave {
  timeOffTypeId: string;
  policyId: string;
  assignmentAction: 'New' | 'Change' | 'Schedule';
  newBalance: number;
  applyDateOption: ApplyDate;
  effectiveDate?: string;
}
