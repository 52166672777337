import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  IUserEmploymentSubcategoryModel,
  UserEmploymentSubcategoryService,
} from '@app/standard/services/user/user-employment-subcategory.service';
import * as check from 'check-types';

@Component({
  selector: 'user-employment-subcategory-dialog',
  templateUrl: './user-employment-subcategory-dialog.html',
  styleUrls: ['./user-employment-subcategory-dialog.scss'],
})
export class UserEmploymentSubcategoryDialog implements OnInit {
  pageTranslation: any = {};
  subcategory: IUserEmploymentSubcategoryModel;
  inputValidation: InputValidation;
  dialogTitle: string = '';

  constructor(
    private dialogRef: MatLegacyDialogRef<UserEmploymentSubcategoryDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: IUserEmploymentSubcategoryModel,
    private injector: Injector
  ) {}

  get conflictErrorCode() {
    return ErrorCodes.CONFLICT;
  }

  ngOnInit(): void {
    this.loadTranslation();
    this.inputValidation = new InputValidation();
    this.subcategory = check.assigned(this.data)
      ? this.data
      : {
          name: '',
          isDefault: false,
          isActive: true,
        };
  }

  private async loadTranslation(): Promise<void> {
    this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('user-employment-subcategory-dialog');
    this.dialogTitle = check.assigned(this.data)
      ? this.injector.get(I18nDataPipe).transform(this.pageTranslation.editSubcategory, { name: this.data.name })
      : this.pageTranslation.addSubcategory;
  }

  /**
   * Saves the changes to the database. If there is _id, we are editing, else we are creating
   */
  async save(): Promise<void> {
    if (check.assigned(this.subcategory._id)) {
      try {
        this.injector.get(UserEmploymentSubcategoryService).updateById(this.subcategory._id, this.subcategory);
        this.dialogRef.close();
      } catch (e: any) {}
    } else {
      try {
        this.injector.get(UserEmploymentSubcategoryService).create(this.subcategory);
        this.dialogRef.close();
      } catch (e: any) {}
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
