<orgos-container class="rcc-review-card kenjo-p-10px" [class]="review.isManagerCard && type === 'performance' ? 'rcc-normal-card' : 'rcc-small-card'" [class.rcc-cannot-open]="!review.canRead">
  <kenjo-truncate-text class="kenjo-font-size-14px">{{ review.title }} </kenjo-truncate-text>
  <orgos-container *ngIf="!review.subordinates">
    <orgos-column-container *ngIf="!review.isMultiple" centerColumns="true" class="kenjo-mt-10px">
      <orgos-avatar orgosColumn="0" [isAnonymous]="review.isAnonymous" [avatarName]="review.reviewerDisplayName" [photoUrl]="review.reviewerPhoto?._url" size="tiny" class="kenjo-mr-10px"></orgos-avatar>
      <orgos-column class="kenjo-mr-10px" size="1">
        <orgos-chip [color]="STATUS_COLORS[review.status]" class="kenjo-ph-10px">
          <kenjo-truncate-text class="rcc-chip-text kenjo-font-color-white-ffffff kenjo-font-size-12px">
            {{ miscTranslations[review.status + 'Status'] }}
          </kenjo-truncate-text>
        </orgos-chip>
      </orgos-column>
      <orgos-text orgosColumn="1" type="SecondaryText" class="kenjo-ml-10px">
        {{ review.status === 'notStarted' ? translations.dueDate : translations.onDate }}
        {{ review.date | date: 'shortDate' }}
      </orgos-text>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" *ngIf="!review.isMultiple && review.selfAssessment" class="kenjo-mt-10px">
      <orgos-avatar orgosColumn="0" [isAnonymous]="review.isAnonymous" [avatarName]="review.revieweeDisplayName" [photoUrl]="review.revieweePhoto?._url" size="tiny" class="kenjo-mr-10px"></orgos-avatar>
      <orgos-column class="kenjo-mr-10px" size="1">
        <orgos-chip [color]="STATUS_COLORS[review.status]" class="kenjo-ph-10px">
          <kenjo-truncate-text class="rcc-chip-text kenjo-font-color-white-ffffff kenjo-font-size-12px">
            {{ miscTranslations[review.status + 'Status'] }}
          </kenjo-truncate-text>
        </orgos-chip>
      </orgos-column>
      <orgos-text orgosColumn="1" type="SecondaryText" class="kenjo-ml-10px">
        {{ review.status === 'notStarted' ? translations.dueDate : translations.onDate }}
        {{ review.date | date: 'shortDate' }}
      </orgos-text>
    </orgos-column-container>
    <orgos-column-container *ngIf="review.matrixValue" centerColumns="true" class="kenjo-mt-10px">
      <orgos-column class="kenjo-ml-5px kenjo-mr-10px" size="0">
        <mat-icon>grid_icon</mat-icon>
      </orgos-column>
      <orgos-text orgosColumn="0" type="SecondaryText">
        {{ miscTranslations['xAxis' + review.matrixValue[0] + 'yAxis' + review.matrixValue[1]] ? miscTranslations['xAxis' + review.matrixValue[0] + 'yAxis' + review.matrixValue[1]] : translations.noAddedYet }}
      </orgos-text>
    </orgos-column-container>
    <orgos-column-container *ngIf="review.ratingValue" centerColumns="true" class="kenjo-mt-10px">
      <orgos-column class="kenjo-ml-5px kenjo-mr-10px" size="0">
        <mat-icon>star_empty</mat-icon>
      </orgos-column>
      <orgos-text *ngIf="review.ratingValue === -1 || !RATING_VALUES[review.ratingValue]" orgosColumn="0" type="SecondaryText">
        {{ translations.noAddedYet }}
      </orgos-text>
      <orgos-text class="rcc-rating" *ngIf="review.ratingValue !== -1 && RATING_VALUES && RATING_VALUES[review.ratingValue]" orgosColumn="0" type="SecondaryText" [noWrapText]="RATING_VALUES[review.ratingValue]">
        {{ RATING_VALUES[review.ratingValue] }}
      </orgos-text>
    </orgos-column-container>
  </orgos-container>

  <orgos-container *ngIf="review.subordinates">
    <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
      <orgos-avatar orgosColumn="0" [isAnonymous]="review.isAnonymous" [avatarName]="review.reviewerDisplayName" [photoUrl]="review.reviewerPhoto?._url" size="tiny" class="kenjo-mr-10px"></orgos-avatar>
      <orgos-column class="kenjo-mr-10px" size="1">
        <orgos-chip [color]="STATUS_COLORS[review.status]" class="kenjo-ph-10px">
          <kenjo-truncate-text class="rcc-chip-text kenjo-font-color-white-ffffff kenjo-font-size-12px">
            {{ miscTranslations[review.status + 'Status'] }}
          </kenjo-truncate-text>
        </orgos-chip>
      </orgos-column>
      <orgos-text orgosColumn="1" type="SecondaryText" class="kenjo-ml-10px">
        {{ review.status === 'notStarted' ? translations.dueDate : translations.onDate }}
        {{ review.date | date: 'shortDate' }}
      </orgos-text>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
      <orgos-avatar *ngFor="let subordinate of review.subordinates" orgosColumn="0" [isAnonymous]="subordinate.isAnonymous" [avatarName]="subordinate.displayName" [photoUrl]="subordinate._photo?._url" size="tiny" class="kenjo-mr-5px" [matTooltip]="subordinate.displayName"> </orgos-avatar>
      <orgos-column size="0" *ngIf="review.moreEmployees">
        <orgos-chip>+{{ review.moreEmployees }}</orgos-chip>
      </orgos-column>
    </orgos-column-container>
  </orgos-container>
</orgos-container>
