import { Component, Input } from '@angular/core';
import * as check from 'check-types';

@Component({
  selector: 'orgos-editor-viewer',
  templateUrl: 'editor-viewer.component.html',
  styleUrls: ['editor-viewer.component.scss']
})
export class EditorViewerComponent {
  private editor: any;

  public isEmpty: boolean = true;

  private _editorContent: IEditorContent;
  @Input() display: 'inline' | 'block' | 'inline-block';
  @Input()
  set editorContent(editorContent: IEditorContent) {
    this._editorContent = editorContent;
    this.displayContent();
  }
  get editorContent(): IEditorContent {
    return this._editorContent;
  }

  modules: any = {
    toolbar: false
  };

  style: any = {
    'font-family': 'Nunito, sans-serif'
  };

  onEditorCreated(editor: any): void {
    this.editor = editor;

    this.displayContent();
  }

  scrollToTop(): void {
    if (check.assigned(this.editor?.scrollingContainer)) {
      this.editor.scrollingContainer.scrollTop = 0;
    }
  }

  private displayContent(): void {
    if (check.not.assigned(this.editor) || check.not.assigned(this.editorContent)) {
      return;
    }

    if (check.assigned(this.editorContent.delta)) {
      this.editor.setContents(this.editorContent.delta);
      this.isEmpty = this.editor.getText() === '<p><br></p>';
    } else if (check.assigned(this.editorContent.html)) {
      this.editor.clipboard.dangerouslyPasteHTML(this.editorContent.html);
      this.isEmpty = this.editor.getText() === '<p><br></p>';
    } else {
      this.editor.setContents(null);
      this.isEmpty = true;
    }
  }
}

export interface IEditorContent {
  delta?: any;
  html?: any;
}
