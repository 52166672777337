import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { ISelectOption } from '@app/standard/core/select-option';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { PayrollController } from '@app/standard/services/payroll/controllers/payroll.controller';
import { PayrollSettingsService } from '@app/standard/services/payroll/payroll-settings.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { IUserWorkScheduleModel, UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'orgos-people-detail-payroll-page',
  templateUrl: 'people-detail-payroll.page.html',
  styleUrls: ['people-detail-payroll.page.scss'],
})
export class PeopleDetailPayrollPage extends GenericPage implements OnInit {
  id: string;

  protected profilePermissionsResources: Array<string> = ['user-confidential', 'payroll-feature', 'employees'];
  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'people-detail-payroll-page' },
    { name: 'payroll', translationKey: 'payroll-page' },
    { name: 'misc', translationKey: 'people-detail-misc' },
    { name: 'generalMisc', translationKey: 'misc' },
    { name: 'userSalary', translationKey: 'user-salary-collection' },
    { name: 'userVariablePay', translationKey: 'user-variable-pay-collection' },
    { name: 'standardPicklist', translationKey: 'standard-picklists' },
  ];

  isNotMyUser: boolean;
  myPermissions: any = {};

  hasPermission: any = {
    createUserEmployment: false,
    editUserEmployment: false,
    deleteUserEmployment: false,
    createUserSalary: false,
    editUserSalary: false,
    deleteUserSalary: false,
    createUserVariablePay: false,
    editUserVariablePay: false,
    deleteUserVariablePay: false,
  };

  optionCompany: Array<ISelectOption> = [];
  companySelected: string;
  year: number = moment().year();
  currentSalary: Array<any> = [];
  months: Array<string> = moment.months();
  labelsMonths: Array<{
    start: Date;
    end: Date;
  }> = [];

  lastCalculationDate: Date;
  lastCalculationText: string;
  cutOffDate: number;

  userPersonal: IUserPersonalModel;
  userWorkSchedule: IUserWorkScheduleModel;
  personalPayroll: any;

  payrollData: any = {};
  userPayRoll: Array<any> = [];
  terminationDate: Date;

  userWork: any;
  loadingChangeYear: boolean = true;

  userManagePoliciesInfo: any;
  seeDetailsUserId: string;
  seeDetailsUserIdSubscription: Subscription;

  protected beforeInit(): Promise<void> {
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        this.id = id;
        this.refreshData()
          .then(() => {
            this.refreshLazyData();
            this.refreshGlobalBar();
            return;
          })
          .catch(() => {
            // An error is already shown
            this.router.navigate(['../'], { relativeTo: this.route });
            return;
          });
      });

    return Promise.resolve();
  }

  protected async configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    await this.injector.get(PeopleDetailService).onPeopleRouteLoaded(this.id);

    const options: Array<IMenuOption> = [
      {
        name: this.i18n.misc.publicProfileTab,
        onClick: () => {
          this.router.navigate(['../'], { relativeTo: this.route });
        },
      },
    ];

    const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;

    if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
      options.push({
        name: this.i18n.misc.personalTab,
        onClick: () => {
          this.router.navigate(['../personal'], { relativeTo: this.route });
        },
      });
      options.push({
        key: 'compensation-tab',
        name: this.i18n.misc.compensationTab,
        onClick: () => {
          this.router.navigate(['../compensation'], { relativeTo: this.route });
        },
      });
      options.push({ name: this.i18n.misc.payrollTab, onClick: () => {} });

      if (
        this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') &&
        check.assigned(this.userWorkSchedule) &&
        check.assigned(this.userWorkSchedule.trackAttendance) &&
        this.userWorkSchedule.trackAttendance === true
      ) {
        options.splice(2, 0, {
          key: 'attendance-tab',
          name: this.i18n.misc.attendanceTab,
          onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
        });
      }
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance')) {
        options.push({
          name: this.i18n.misc.performanceTab,
          onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
        });
      }

      if (this.injector.get(PeopleDetailService).canSeeUsersSmartDocs()) {
        options.push({
          name: this.i18n.misc.smartDocsTab,
          onClick: () => this.injector.get(PeopleDetailService).openEmployeeDoc(this.id),
        });
      }

      if (this.injector.get(PeopleDetailService).canSeeUsersTimeOff()) {
        options.push({
          name: this.i18n.misc.timeOffTab,
          onClick: () => this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.id),
        });
      }

      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.payrollTab]);

      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      let payrollPermissions;
      this.getPermissions()
        .then(() => {
          payrollPermissions = this.myPermissions['payroll-feature'];
          return this.injector.get(PeopleDetailService).getPermissionsToSeePersonalTab(this.myPermissions['employees'], this.id);
        })
        .then((canAccessPersonal: boolean) => {
          if (canAccessPersonal === true) {
            options.push({
              name: this.i18n.misc.personalTab,
              onClick: () => {
                this.router.navigate(['../personal'], { relativeTo: this.route });
              },
            });
          }
          return this.getPermissionsToSeeAttendanceTab(this.myPermissions['employees']);
        })
        .then((canAccessAttendance: boolean) => {
          if (canAccessAttendance === true) {
            options.splice(2, 0, {
              key: 'attendance-tab',
              name: this.i18n.misc.attendanceTab,
              onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
            });
          }
          return this.getPermissionsToSeeCompensationTab(payrollPermissions);
        })
        .then((canSeeCompensationTab: boolean) => {
          if (canSeeCompensationTab === true) {
            options.push({
              key: 'compensation-tab',
              name: this.i18n.misc.compensationTab,
              onClick: () => this.router.navigate(['../compensation'], { relativeTo: this.route }),
            });
            options.push({
              name: this.i18n.misc.payrollTab,
              onClick: () => this.router.navigate(['../payroll'], { relativeTo: this.route }),
            });
          }
          return this.getPermissionsToSeePerformanceTab(this.myPermissions['performance-feedback-results']);
        })
        .then((canSeePerformanceTab) => {
          if (canSeePerformanceTab === true) {
            options.push({
              name: this.i18n.misc.performanceTab,
              onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
            });
          }

          if (this.injector.get(PeopleDetailService).canSeeUsersSmartDocs()) {
            options.push({
              name: this.i18n.misc.smartDocsTab,
              onClick: () => this.injector.get(PeopleDetailService).openEmployeeDoc(this.id),
            });
          }

          if (this.injector.get(PeopleDetailService).canSeeUsersTimeOff()) {
            options.push({
              name: this.i18n.misc.timeOffTab,
              onClick: () => this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.id),
            });
          }

          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.payrollTab]);
          resolve();
        })
        .catch(() => {
          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.payrollTab]);
          resolve();
        });
    });
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    try {
      if (check.not.assigned(this.id) || check.emptyString(this.id)) {
        resolveFetchData();
        return;
      }

      const currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
      if (check.not.equal(this.id, currentUserId)) {
        // Do not allow to deactivate your own user
        this.isNotMyUser = true;
      } else {
        this.isNotMyUser = false;
      }

      const payrollSettings = await this.injector.get(PayrollSettingsService).getAllPayrollSettings({ _id: { $ne: null } });

      // If there aren't created the setting for the org, we need to create the default settings
      if (check.not.assigned(payrollSettings) || check.not.array(payrollSettings) | check.emptyArray(payrollSettings)) {
        await this.injector.get(PayrollSettingsService).createDefaultPayrollSettings();
      }

      const getUserPersonal = this.injector.get(UserPersonalService).getById(this.id);
      const getUserWork = this.injector.get(UserWorkService).getByIdComplete(this.id);
      const getUserWorkSchedule = this.injector.get(UserWorkScheduleService).getById(this.id);
      const getPersonalPayroll = this.injector.get(PayrollController).getPersonalPayroll(this.id, this.year);

      [this.userPersonal, this.userWork, this.userWorkSchedule, this.personalPayroll] = await Promise.all([
        getUserPersonal,
        getUserWork,
        getUserWorkSchedule,
        getPersonalPayroll,
      ]);

      const companies = check.assigned(this.personalPayroll) ? this.personalPayroll.companies : null;

      this.buildCompanySelector(companies);

      this.payrollData = check.assigned(this.personalPayroll) ? this.personalPayroll.payrollData : null;
      this.buildRows();

      this.seeDetailsUserIdSubscription = this.injector.get(PeopleDetailService).seeDetailsUserId$.subscribe((id: string) => {
        this.userManagePoliciesInfo = {
          userPersonal: this.userPersonal,
          userStartDate: this.userWork.startDate,
          timeOffTypes: this.injector.get(PeopleDetailService).timeOffTypes,
        };
        this.seeDetailsUserId = id;
      });
      resolveFetchData();
    } catch (error) {
      // An error is already shown
      this.userPayRoll = [];
      rejectFetchData();
    }
  }

  private buildCompanySelector(companies: Array<any>) {
    const companyIds = [];
    if (check.assigned(companies) && check.nonEmptyArray(companies)) {
      companies.forEach((iCompany) => {
        const option = {
          name: iCompany.name,
          value: iCompany._id,
        };

        companyIds.push(iCompany._id);
        this.optionCompany.push(option);
      });
    }

    if (check.assigned(this.userWork)) {
      this.companySelected = companyIds.includes(this.userWork.companyId) ? this.userWork.companyId : companyIds[0];
    }
  }

  protected afterInit(): Promise<void> {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view employee page', { category: 'Navigation', platform: 'Web', subcategory1: 'payroll', subcategory2: this.id });
    return Promise.resolve();
  }

  public downloadAttachment(attachment): void {
    this.injector
      .get(HttpClient)
      .get(attachment._file._url, { responseType: 'arraybuffer' })
      .subscribe((arrayBuffer) => {
        const extension: string = attachment._file._fileExtension;
        const documentName: string =
          check.nonEmptyString(extension) && attachment.name.endsWith(extension) === false
            ? `${attachment.name}.${extension}`
            : attachment.name;

        FileSaver.saveAs(new Blob([arrayBuffer]), documentName);
      });
  }

  public changeVariableView(row): void {
    if (check.not.assigned(row.detailedVariables) || check.not.array(row.detailedVariables) || check.emptyArray(row.detailedVariables)) {
      return;
    }
    row.collapsed = !row.collapsed;
  }

  public changeYear(step: number): void {
    this.loadingChangeYear = true;
    this.year = this.year + step;
    this.reloadPayRoll();
  }

  public changeCompany(companyId: string): void {
    this.loadingChangeYear = true;
    this.companySelected = companyId;
    this.buildRows();
  }

  private reloadPayRoll(): void {
    this.injector
      .get(PayrollController)
      .getPersonalPayroll(this.id, this.year)
      .then((payroll) => {
        this.optionCompany = [];
        this.payrollData = payroll.payrollData;
        this.buildCompanySelector(payroll.companies);
        this.buildRows();
        return;
      })
      .catch((e) => {
        // An error is already shown
        this.userPayRoll = [];
        this.loadingChangeYear = false;
      });
  }

  private buildRows(): void {
    const tempPayroll =
      check.not.assigned(this.payrollData) ||
      check.not.assigned(this.payrollData[this.companySelected]) ||
      check.emptyObject(this.payrollData[this.companySelected])
        ? this.defaultYear()
        : Object.values(this.payrollData[this.companySelected]);

    this.userPayRoll = tempPayroll
      .map((iPayroll) => {
        const startDate = moment.utc(iPayroll.date);
        const endDate = startDate.clone().endOf('month');
        endDate.endOf('month');

        iPayroll.endDate = endDate;
        iPayroll.total = iPayroll.salary + iPayroll.variable;

        return iPayroll;
      })
      .sort((a, b) => {
        if (moment(a.date).isSameOrBefore(b.date)) {
          return -1;
        }

        return 1;
      });
    this.loadingChangeYear = false;
  }

  private getPermissions(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (check.assigned(this.myPermissions) && check.nonEmptyObject(this.myPermissions)) {
        resolve();
        return;
      }

      this.injector
        .get(PrivateSecurityService)
        .getAllPermissions()
        .then((allPermissions) => {
          this.myPermissions = allPermissions;
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private defaultYear(): Array<any> {
    let defaultYear = [];
    this.months.forEach((element, index) => {
      const startDate = moment.utc([this.year, index]).startOf('month');

      defaultYear = defaultYear.concat({
        date: startDate,
        salary: null,
        variable: null,
      });
    });

    return defaultYear;
  }

  private initUserWorkSchedule(): Promise<void> {
    if (check.assigned(this.userWorkSchedule) && check.nonEmptyObject(this.userWorkSchedule)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.injector
        .get(UserWorkScheduleService)
        .getById(this.id)
        .then((userWorkSchedule: IUserWorkScheduleModel) => {
          this.userWorkSchedule = userWorkSchedule;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  private getPermissionsToSeeAttendanceTab(employeesPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      this.initUserWorkSchedule().then(() => {
        if (
          this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') === false ||
          check.not.assigned(this.userWorkSchedule) ||
          this.userWorkSchedule.trackAttendance === false
        ) {
          resolve(false);
          return;
        }

        if (loggedUser._id === this.id && employeesPermissions.c_viewAttendanceTab_own === true) {
          resolve(true);
          return;
        }

        if (employeesPermissions.c_viewAttendanceTab_all === true) {
          resolve(true);
          return;
        }

        if (
          check.not.assigned(employeesPermissions.c_viewAttendanceTab_custom) ||
          check.emptyArray(employeesPermissions.c_viewAttendanceTab_custom)
        ) {
          resolve(false);
          return;
        }

        return this.injector
          .get(UserWorkService)
          .getAllUserWorkCache()
          .then((allUserWork) => {
            let myUserWork = this.userWork;
            if (check.not.assigned(myUserWork)) {
              myUserWork = allUserWork.find((iUserWork) => {
                return iUserWork._id === this.id;
              });
            }
            return customPermissions.applyCustomPermissionsToDocument(
              null,
              'user-work',
              employeesPermissions[`c_viewAttendanceTab_custom`],
              employeesPermissions[`c_viewAttendanceTab_own`],
              myUserWork,
              allUserWork,
              loggedUser
            );
          })
          .then((customPermissionResult) => {
            resolve(customPermissionResult);
          })
          .catch(() => {
            resolve(false);
          });
      });
    });
  }

  private getPermissionsToSeeCompensationTab(payrollPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/compensation') === false) {
        resolve(false);
      }

      if (loggedUser._id === this.id && payrollPermissions.c_viewCompensation_own === true) {
        resolve(true);
      }
      if (payrollPermissions.c_viewCompensation_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(payrollPermissions.c_viewCompensation_custom) ||
        check.emptyArray(payrollPermissions.c_viewCompensation_custom)
      ) {
        resolve(false);
      }

      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            payrollPermissions[`c_viewCompensation_custom`],
            payrollPermissions[`c_viewCompensation_own`],
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private getPermissionsToSeePerformanceTab(performancePermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance') === false) {
        resolve(false);
      }
      if (loggedUser._id === this.id) {
        resolve(true);
      }
      if (performancePermissions.c_readFeedback_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(performancePermissions.c_readFeedback_custom) ||
        check.emptyArray(performancePermissions.c_readFeedback_custom)
      ) {
        resolve(false);
      }
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            performancePermissions.c_readFeedback_custom,
            performancePermissions.read_own,
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  exitTimeOffFullScreen() {
    this.injector.get(PeopleDetailService).closeEmployeeTimeOff();
    this.injector.get(GlobalBarService).setPageName(this.i18n.page.pageName);
    this.refreshGlobalBar();
  }

  destroy(): Promise<void> {
    this.seeDetailsUserIdSubscription.unsubscribe();
    return Promise.resolve();
  }
}
