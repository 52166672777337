import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-tags-picklist',
  templateUrl: 'tags-picklist.component.html',
  styleUrls: ['tags-picklist.component.scss']
})
export class TagsPicklistComponent implements OnInit {
  _searchTerm: string;
  set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
    this.refreshSearchResults();
  }
  get searchTerm(): string {
    return this._searchTerm;
  }

  foundTags: Array<IDocumentTagModel> = [];

  @Input() tags: Array<IDocumentTagModel> = [];
  @Input() placeholder: string = '';
  @Input() errorMessage: string = '';
  @Output() tagSelected: EventEmitter<IDocumentTagModel> = new EventEmitter<IDocumentTagModel>();

  ngOnInit(): void {
    this.searchTerm = '';
  }

  refreshSearchResults(): void {
    if (check.not.assigned(this.searchTerm) || check.emptyString(this.searchTerm)) {
      this.foundTags = this.tags;
      return;
    }

    this.foundTags = this.tags.filter((iTag: IDocumentTagModel) => {
      const regExp = new RegExp(`^.*${this.searchTerm}.*$`, 'i');
      return regExp.test(iTag.name);
    });
  }

  selectTag(tag: IDocumentTagModel) {
    this.tagSelected.emit(tag);
    this.searchTerm = '';
  }

  getTagColor(colorKey: string): string {
    return userColorConstants[colorKey];
  }
}
