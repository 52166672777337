import { Injectable, Injector } from '@angular/core';

import { IUserAccountModel } from '../../../models/user-account.model';
import { PrivateAuthenticationService } from '../../../private/services/private-authentication.service';

@Injectable()
export class AuthenticationService {
  constructor(private injector: Injector) {}

  getAuthorizationHeader(): string {
    return this.injector.get(PrivateAuthenticationService).getAuthorizationHeader();
  }

  getAccessType(): string {
    return this.injector.get(PrivateAuthenticationService).getAccessType();
  }

  getLoggedUser(): IUserAccountModel {
    return this.injector.get(PrivateAuthenticationService).getLoggedUser();
  }

  isUserAuthenticated(): boolean {
    return this.injector.get(PrivateAuthenticationService).isUserAuthenticated();
  }

  refreshLoggedUser(): Promise<void> {
    return this.injector.get(PrivateAuthenticationService).refreshLoggedUser();
  }

  signOut(): void {
    this.injector.get(PrivateAuthenticationService).signOut();
  }

  expireSession(): void {
    this.injector.get(PrivateAuthenticationService).expireSession();
  }
}
