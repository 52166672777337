import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import {
  IChargebeeCustomer,
  IChargebeeCustomerBillingInfo,
  IChargebeePaymentSource,
  IChargebeeSubscription,
  PrivateChargebeeService,
} from '@app/private/services/private-chargebee.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { SettingsBarService } from '@app/standard/services/settings/settings-bar.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'orgos-settings-billing-info-page',
  templateUrl: 'settings-billing-info.page.html',
  styleUrls: ['settings-billing-info.page.scss'],
})
export class SettingsBillingInfoPage extends GenericPage {
  private updateCustomerDebounced: Subject<IChargebeeCustomer> = new Subject<IChargebeeCustomer>();
  private updateCustomerBillingInfoDebounced: Subject<IChargebeeCustomerBillingInfo> = new Subject<IChargebeeCustomerBillingInfo>();

  updatingCustomerInfo: boolean = false;
  updatingCustomerBillingInfo: boolean = false;

  selectedCustomerIndex: number = -1;
  multiBilling: boolean = false;
  chargebeeCustomers: Array<IChargebeeCustomer>;
  paymentSourcesByCustomerIdMap: { [customerId: string]: Array<IChargebeePaymentSource> } = {};
  currentPaymentSources: Array<IChargebeePaymentSource> = [];
  paymentMethodsTableDisplayedColumns: Array<string> = ['type', 'owner', 'number', 'expirationDate', 'status', 'delete'];
  subscriptions: Array<IChargebeeSubscription>;
  isLegacy: boolean = true;
  hasPaymentSource = false;

  chargebeeLocales: Array<ISelectOption> = [];
  cbInstance: any;

  showError: boolean = false;

  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'misc' },
    { name: 'page', translationKey: 'settings-billing-page' },
    { name: 'settingsTopBar', translationKey: 'settings-top-bar' },
  ];

  protected beforeInit(): Promise<void> {
    this.injector
      .get(PrivateChargebeeService)
      .getChargebeeInstance()
      .then((cbInstance) => (this.cbInstance = cbInstance));

    this.updateCustomerDebounced
      .pipe(
        takeWhile(() => !this.pageDestroyed),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe((newData) => {
        this.injector
          .get(PrivateChargebeeService)
          .updateCustomerInfo(newData)
          .then(() => {
            this.updatingCustomerInfo = false;
            this.injector.get(MatLegacySnackBar).open(this.i18n.page.billingInfo.billingInfoUpdatedSnackbar, 'OK', {
              duration: 5000,
            });
          })
          .catch(() => {
            this.updatingCustomerInfo = false;
            // An error is already shown
          });
      });

    this.updateCustomerBillingInfoDebounced
      .pipe(
        takeWhile(() => !this.pageDestroyed),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe((newData) => {
        this.injector
          .get(PrivateChargebeeService)
          .updateCustomerBillingInfo(newData)
          .then(() => {
            this.updatingCustomerBillingInfo = false;
            this.injector.get(MatLegacySnackBar).open(this.i18n.page.billingInfo.billingInfoUpdatedSnackbar, 'OK', {
              duration: 5000,
            });
          })
          .catch(() => {
            this.updatingCustomerBillingInfo = false;
            // An error is already shown
          });
      });

    return Promise.resolve();
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    const NUMBER_OF_DATA_TO_FETCH = 3;
    let dataFetched = 0;

    this.fetchCustomer()
      .then(() => {
        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        rejectFetchData();
        this.showError = true;
      });

    this.fetchPaymentSources()
      .then(() => {
        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        rejectFetchData();
        this.showError = true;
      });

    this.fetchSubscriptions()
      .then(() => {
        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        rejectFetchData();
        this.showError = true;
      });
  }

  openPaymentSources(index: number) {
    this.cbInstance.openCheckout({
      hostedPage: () => this.injector.get(PrivateChargebeeService).getHostedPageToManagePaymentSources(this.chargebeeCustomers[index].id),
      close: async () => {
        await this.refreshData();
        this.setSelectedCustomerIndex(index);
      },
      paymentSourceAdd: async () => {
        await this.injector.get(PrivateChargebeeService).activateSubscriptions();
        if (
          !this.hasPaymentSource &&
          this.injector.get(AuthenticationService).getLoggedUser()?.chargebeeStatus?.customerStatus === 'in_trial'
        ) {
          this.hasPaymentSource = true;
          this.injector.get(PrivateAmplitudeService).logEvent('checkout success', { category: 'Billing' });
        }
        await this.refreshData();
        this.setSelectedCustomerIndex(index);
        this.injector.get(PrivateChurnzeroService).logSimpleEvent('PAYMENT_METHOD_ADDED');
      },
      paymentSourceUpdate: async () => {
        await this.refreshData();
        this.setSelectedCustomerIndex(index);
      },
      paymentSourceRemove: async () => {
        await this.injector.get(PrivateChargebeeService).checkTrialEndAction();
        await this.refreshData();
        this.setSelectedCustomerIndex(index);
      },
    });
  }

  private async fetchCustomer() {
    const chargebeeCustomer = await this.injector.get(PrivateChargebeeService).getCustomerInfo();

    this.multiBilling = chargebeeCustomer.multiBilling;
    chargebeeCustomer.customers.forEach((iCustomer) => {
      iCustomer.billing_address = check.not.assigned(iCustomer.billing_address) ? {} : iCustomer.billing_address;
    });

    this.chargebeeCustomers = _.sortBy(chargebeeCustomer.customers, 'created_at');
  }

  private async fetchPaymentSources() {
    const paymentSources = await this.injector.get(PrivateChargebeeService).getPaymentSources();
    if (paymentSources.length > 0) {
      this.hasPaymentSource = true;
    }
    this.paymentSourcesByCustomerIdMap = _.groupBy(paymentSources, 'customer_id');
  }

  private async fetchSubscriptions() {
    const subscriptionsWrapper = await this.injector.get(PrivateChargebeeService).getCustomerSubscriptions();
    this.subscriptions = subscriptionsWrapper.subscriptions;
    this.isLegacy = this.subscriptions.every((iSubscription) => {
      const itemPriceId = iSubscription?.plan?.item_price_id?.toLowerCase();

      return !(itemPriceId?.includes('starter') || itemPriceId?.includes('growth') || itemPriceId?.includes('connect'));
    });
  }

  protected async configureGlobalBar(): Promise<void> {
    try {
      this.globalBarConfig.pageName = this.i18n.page.pageName;

      const options = await this.injector.get(SettingsBarService).getOptions(this.i18n.settingsTopBar);
      const optionIndex = _.findIndex(options, ['name', this.i18n.settingsTopBar.billingTab]);

      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
    } catch {
      // An error is already shown
    }
  }

  protected async afterInit(): Promise<void> {
    if (check.nonEmptyArray(this.chargebeeCustomers)) {
      this.setSelectedCustomerIndex(0);
    }

    this.chargebeeLocales = Object.keys(this.i18n.page.billingInfo.chargebeeLocales).map((iKey) => {
      const option: ISelectOption = {
        value: iKey,
        name: this.i18n.page.billingInfo.chargebeeLocales[iKey],
      };

      return option;
    });
  }

  protected onFetchingDataError(): void {
    this.refreshGlobalBar();
  }

  setSelectedCustomerIndex(newIndex: number): void {
    this.selectedCustomerIndex = newIndex;
    const currentChargebeeCustomerId = this.chargebeeCustomers[this.selectedCustomerIndex].id;
    this.currentPaymentSources = this.paymentSourcesByCustomerIdMap[currentChargebeeCustomerId];
  }

  updateCustomerInfo(customerIndexToUpdate: number, fieldToUpdate: string, newValue: any): void {
    this.updatingCustomerInfo = true;
    this.chargebeeCustomers[customerIndexToUpdate] = _.set(this.chargebeeCustomers[customerIndexToUpdate], fieldToUpdate, newValue);

    const infoToUpdate: IChargebeeCustomer = <IChargebeeCustomer>(
      _.pick(this.chargebeeCustomers[customerIndexToUpdate], ['id', 'company', 'first_name', 'last_name', 'email', 'locale'])
    );
    this.updateCustomerDebounced.next(infoToUpdate);
  }

  updateCustomerBillingInfo(customerIndexToUpdate: number, fieldToUpdate: string, newValue: any): void {
    this.updatingCustomerBillingInfo = true;
    this.chargebeeCustomers[customerIndexToUpdate] = _.set(this.chargebeeCustomers[customerIndexToUpdate], fieldToUpdate, newValue);

    const infoToUpdate: IChargebeeCustomerBillingInfo = <IChargebeeCustomerBillingInfo>(
      _.pick(this.chargebeeCustomers[customerIndexToUpdate], [
        'id',
        'billing_address.company',
        'billing_address.first_name',
        'billing_address.last_name',
        'billing_address.email',
        'billing_address.line1',
        'billing_address.line2',
        'billing_address.zip',
        'billing_address.city',
        'billing_address.state',
        'billing_address.country',
        'vat_number',
      ])
    );
    this.updateCustomerBillingInfoDebounced.next(infoToUpdate);
  }

  deletePaymentSource(paymentSourceId: string, index: number): void {
    const dialogData = {
      titleText: this.i18n.page.billingInfo.deleteDialog.title,
      subtitleText: this.i18n.page.billingInfo.deleteDialog.subtitle,
      confirmButtonText: this.i18n.page.billingInfo.deleteDialog.deleteButton,
      cancelButtonText: this.i18n.misc.goBackButtonDialog,
      confirmButtonColor: 'Danger',
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(async (confirm: boolean) => {
      try {
        if (confirm !== true) {
          return;
        }

        await this.injector.get(PrivateChargebeeService).deletePaymentSource(paymentSourceId);
        await this.injector.get(PrivateChargebeeService).checkTrialEndAction();
        await this.refreshData();
        this.setSelectedCustomerIndex(index);
        this.injector.get(MatLegacySnackBar).open(this.i18n.page.billingInfo.paymentMethodDeletedSnackbar, 'OK', {
          duration: 5000,
        });
      } catch {
        this.refreshData();
      }
    });
  }
}
