<ng-container *ngIf="subtitleMessage && userAccountsWithThisProfile">
  <div class="kenjo-flex-horizontal kenjo-flex-spread kenjo-flex-center-vertically kenjo-flex-gap-100px">
    <div>
      <div class="kenjo-font-size-16px">{{ titleMessage }}</div>
      <div class="kenjo-mv-20px kenjo-font-color-light-text-757575">{{ subtitleMessage }}</div>
      <orgos-page-message class="kenjo-mb-20px" *ngIf="profile._profileKey === 'restricted'" messageType="warning" [messageText]="i18n.restrictedProfileMessage"></orgos-page-message>
    </div>
    <div class="pa-invite-button kenjo-flex-align-self-start" *ngIf="profile._profileKey === 'admin' && (inAppInviteService.isInAppFeatureAvailable | async) === true">
      <kenjo-button-flat (click)="displayInviteDialog()">
        <kenjo-icon class="pa-invite-icon" [size]="18">person_add</kenjo-icon>
        <div>{{ i18n.inviteAdmins }}</div>
      </kenjo-button-flat>
    </div>
  </div>
  <div class="pa-search-container">
    <orgos-search [placeholder]="i18n.findingUserMessage" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event">
      <orgos-text class="orgos-search-error" color="Warn">{{ i18n.notFoundPeopleError }}</orgos-text>

      <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="addUserToSelectedProfile(result._id)">
        <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
      </orgos-search-option>

      <orgos-column-container wrap="true" class="orgos-search-selection-container">
        <orgos-column size="0" *ngFor="let result of searchResults" class="kenjo-m-10px">
          <ng-container *ngIf="result._id !== loggedUserId">
            <orgos-chip [imageUrl]="result._photo?._url" buttonIconName="clear" [onClickButtonFunction]="getFunctionDeleteUserFromSelectedProfile(result._id)" class="srp-search-result" [addInitialsIfNoImage]="true" [chipText]="result.displayName">{{ result.displayName }}</orgos-chip>
          </ng-container>
          <ng-container *ngIf="result._id === loggedUserId">
            <orgos-chip [imageUrl]="result._photo?._url" class="srp-search-result" [addInitialsIfNoImage]="true" [chipText]="result.displayName">{{ result.displayName }}</orgos-chip>
          </ng-container>
        </orgos-column>
      </orgos-column-container>
    </orgos-search>
  </div>
</ng-container>
