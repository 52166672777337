import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { getEmptyFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { IFilterOption } from '@app/standard/components/filter-bar/filter-bar.component';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanCardService implements IGenericService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-card-db`;
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan/calendar`;
  private USER_WORK_PERMISSIONS_KEY: string = 'user-work';
  private USER_WORK_INTERNATIONALIZATION: string = 'user-work-collection';
  private SHIFTPLAN_CARD_SERVICE: string = 'ShiftPlanCardService';

  constructor(
    private genericService: GenericService,
    private errorManager: ErrorManagerService,
    private http: HttpClient,
    private injector: Injector
  ) {}

  getById(id: string, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async create(data: object): Promise<any> {
    try {
      return await this.genericService.create(this.MICROSERVICE_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CARD_SERVICE, 'create');
    }
  }

  async updateById(id: string, data: object, notify: boolean = false): Promise<void> {
    try {
      await this.genericService.updateById(this.MICROSERVICE_URL, id, { ...data, notify });
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CARD_SERVICE, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.MICROSERVICE_URL, id);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_CARD_SERVICE, 'deleteById');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  async deleteAndNotify(shiftId: string, notify: boolean = false): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'deleteAndNotify');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.post(`${this.MICROSERVICE_URL}/remove`, { notify, shiftId }, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CARD_SERVICE, 'deleteAndNotify');
    }
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_WORK_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_WORK_INTERNATIONALIZATION);
  }

  getData?(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async getShiftCards(
    startDate,
    endDate,
    { locations, roles, employees, workingAreas, tags }: ShiftCardFilters,
    groupBy = 'user',
    detailed = true
  ): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'getShiftCards');
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      return await this.http
        .post(
          `${this.CONTROLLER_URL}/shift-cards`,
          { startDate, endDate, locations, roles, employees, workingAreas, tags, groupBy, detailed },
          httpOptions
        )
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CARD_SERVICE, 'getShiftCards');
    }
  }

  async getShiftCardsFilters(): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'getShiftCardsFilters');
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      return await this.http.get(`${this.CONTROLLER_URL}/filters`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CARD_SERVICE, 'getShiftCardsFilters');
    }
  }

  async publishShiftCards(
    startDate,
    endDate,
    sendNotification = true,
    filters: ShiftCardFilters = { ...getEmptyFilters() },
    publishType: ShiftplanPublishType
  ) {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'publishShiftCards');
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      return await this.http
        .put(`${this.MICROSERVICE_URL}/publish/shift-cards`, { startDate, endDate, sendNotification, filters, publishType }, httpOptions)
        .toPromise();
    } catch {
      // do nothing
    }
  }

  async removeMany(
    startDate,
    endDate,
    includeOpen,
    filters: ShiftCardFilters = { ...getEmptyFilters() },
    shiftsToDelete: Array<string> = [],
    range?: 'week' | 'month'
  ) {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'publishShiftCards');
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      return await this.http
        .post(`${this.MICROSERVICE_URL}/remove-many`, { startDate, endDate, includeOpen, filters, shiftsToDelete, range }, httpOptions)
        .toPromise();
    } catch {
      // do nothing
    }
  }

  async createMany(createShiftPayload): Promise<{ status }> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'createMany');
      }

      const observe: 'body' | 'events' | 'response' = 'response';
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
        observe,
      };
      const { status } = await this.http.post(`${this.MICROSERVICE_URL}/create-many`, createShiftPayload, httpOptions).toPromise();
      return { status };
    } catch (e) {
      const status = e.status === ErrorCodes.CONFLICT ? ErrorCodes.CONFLICT : ErrorCodes.UNKNOWN;
      return { status };
    }
  }

  async copyShifts(payload, isDay = false) {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'copyShifts');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      if (isDay) {
        return await this.http.post(`${this.MICROSERVICE_URL}/copy-day`, payload, httpOptions).toPromise();
      } else {
        return await this.http.post(`${this.MICROSERVICE_URL}/copy-week`, payload, httpOptions).toPromise();
      }
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CARD_SERVICE, 'copyShifts');
    }
  }

  async copyShiftsMultipleWeeks(payload) {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_CARD_SERVICE, 'copyShiftsMultipleWeeks');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.post(`${this.MICROSERVICE_URL}/copy-multiple-week`, payload, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_CARD_SERVICE, 'copyShiftsMultipleWeeks');
    }
  }
}
export interface IShiftPlanCardModel {
  _id?: string;
  date: Date;
  start: number;
  end: number;
  locationId: string;
  workingAreaId: string;
  roleId: string;
  tagId?: string;
  employeeId?: string;
  notes?: string;
  status: string;
  approvalRule?: 'requiresApproval' | 'autoAssign';
}

export type ShiftCardFilters = {
  locations: string[];
  roles: string[];
  employees: string[];
  workingAreas: string[];
  tags: string[];
};

export type ShiftplanPublishType = 'open' | 'scheduled' | 'both';
export interface IShiftCard {
  _id?: string;
  date: moment.Moment;
  start: number;
  end: number;
  break?: number;
  locationId: string;
  location: {
    name: string;
  };
  roleId: string;
  role: {
    colour: string;
    name: string;
    codeName?: string;
  };
  workingAreaId?: string;
  workingArea?: any;
  tagId?: string;
  tag?: any;
  employeeId: string;
  notes?: string;
  status: string;
  selected?: boolean;
  approvalRule?: 'requiresApproval' | 'autoAssign';
}

export interface IShiftPlanFilters extends IFilterOption {
  locations?: Array<IShiftPlanFilterOption>;
  workingAreas?: Array<IShiftPlanFilterOption>;
  employees?: Array<IShiftPlanFilterOption>;
  roles?: Array<IShiftPlanFilterOption>;
  tags?: Array<IShiftPlanFilterOption>;
}
export interface IShiftPlanFilterOption {
  name: string;
  _id: string;
  photoUrl?: string;
  active?: boolean;
  roles?: Array<IFilterOption>;
  locations?: Array<IFilterOption>;
  workingAreas?: Array<IFilterOption>;
  tags?: Array<IFilterOption>;
}
export interface IShiftPlanDate {
  from: moment.Moment;
  to: moment.Moment;
}
