<div class="sper-main-container">
  <div>
    <!-- Title -->
    <div class="kenjo-font-size-16px kenjo-mb-10px kenjo-font-weight-bold">{{ activateShiftPlanTranslation.rolesStep }}</div>
    <div class="kenjo-font-size-14px kenjo-mb-30px kenjo-font-color-light-text-757575">{{ activateShiftPlanTranslation.rolesSubtitle }}</div>

    <kenjo-input-search-shift-plan id="sper-roles" [list]="allRoles" [placeholder]="activateShiftPlanTranslation.searchRoles" (filteredResults)="filteredRoles = $event"></kenjo-input-search-shift-plan>
    <kenjo-settings-shift-plan-select *ngIf="filteredRoles.length > 0" [initialData]="allRoles" [data]="filteredRoles" [dataType]="activateShiftPlanTranslation.rolesStep" (onChange)="changeSelectedRoles($event)"></kenjo-settings-shift-plan-select>
  </div>
</div>

<!-- Navigation buttons for creation-->
<div *ngIf="userWork.data.shiftPlanActivationStatus !== 'finished'" class="kenjo-text-align-right kenjo-mt-20px">
  <orgos-button-raised id="sper-previous-button" [color]="'Neutral'" class="kenjo-mr-20px" (click)="previousStep()">{{ activateShiftPlanTranslation.backButton }}</orgos-button-raised>
  <orgos-button-raised id="sper-next-button" [color]="'Success'" (click)="nextStep()" [disabled]="selectedRoles.length <= 0">{{ activateShiftPlanTranslation.nextButton }}</orgos-button-raised>
</div>

<!-- Navigation buttons for edition-->
<div *ngIf="userWork.data.shiftPlanActivationStatus === 'finished'" class="kenjo-text-align-right kenjo-mt-20px">
  <orgos-button-raised id="sper-cancel-button" [color]="'Neutral'" class="kenjo-mr-20px" [disabled]="!dataHasChanged" (click)="cancelChanges(); dataHasChanged = false">{{ activateShiftPlanTranslation.cancelButton }}</orgos-button-raised>
  <orgos-button-raised id="sper-save-button" [color]="'Success'" [disabled]="!dataHasChanged || selectedRoles.length <= 0" (click)="saveData(); saveSnackbar(); dataHasChanged = false">{{ activateShiftPlanTranslation.saveButton }} </orgos-button-raised>
</div>
