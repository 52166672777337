<mat-form-field *ngIf="model && field && !readOnly && (options.length <= 15 || multiple)">
  <mat-label kenjoRemoveAriaOwns>{{ label }}</mat-label>
  <mat-select
    [multiple]="multiple"
    [id]="id"
    [ngModel]="getValue()"
    (ngModelChange)="setValue($event)"
    [required]="required"
    #matSelect
    (closed)="forceValidation()"
  >
    <mat-option *ngFor="let option of options" [value]="option.value">{{ option.name }}</mat-option>
  </mat-select>
  <mat-icon
    *ngIf="showClearButton && matSelect.panelOpen === false"
    matSuffix
    class="isc-clear-button"
    (click)="clearValue(); matSelect.value = null; $event.stopPropagation()"
    >clear</mat-icon
  >
</mat-form-field>
<ng-container *ngIf="model && field && !readOnly && options.length > 15 && !multiple">
  <orgos-input-simple-autocomplete
    [sortOptions]="sortOptions"
    [label]="label"
    [options]="options"
    [value]="getValue()"
    (valueChange)="setValue($event)"
    [required]="required"
    [enableClearButton]="enableClearButton"
    class="isc-autocomplete"
  ></orgos-input-simple-autocomplete>
</ng-container>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label [class.kenjo-ml-10px]="leftDot && !getValueName()">{{ label }}</mat-label>
  <div *ngIf="leftDot" class="isc-circle-mini-container">
    <div class="isc-circle-mini"></div>
    <input matInput [id]="id" [ngModel]="getValueName()" readOnly disabled [required]="required" />
  </div>
  <input *ngIf="!leftDot" matInput [id]="id" [ngModel]="getValueName()" readOnly disabled [required]="required" />
</mat-form-field>
<div *ngIf="!isValueValid" class="isc-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint" class="isc-saved-hint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
