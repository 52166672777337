import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DatevApiController } from '@app/standard/services/datev/datev-api.controller';
import { DatevAuthService } from '@app/standard/services/datev/datev-auth.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-close-datev-payroll-dialog',
  templateUrl: 'close-datev-payroll.dialog.html',
  styleUrls: ['close-datev-payroll.dialog.scss'],
})
export class CloseDatevPayrollDialog implements OnInit {
  dialogTranslation: any = {};
  selectedExportMethod: string = 'download';
  dialogTitle: string;
  companyName: string;
  datevSettingsId: string;
  target: string = 'lodas';
  isDownloadAndCloseStep = false;
  isExportAndCloseStep = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<CloseDatevPayrollDialog>,
    private injector: Injector,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData() {
    await this.getDialogTranslation();
    this.dialogTitle = this.dialogTranslation?.title;
    this.companyName = this.data?.companyName;
    this.datevSettingsId = this.data?.datevSettingsId;
    this.target = this.data?.target;
  }

  private async getDialogTranslation() {
    try {
      this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('close-datev-payroll-dialog');
    } catch {
      this.dialogTranslation = {};
    }
  }

  closeDialog(): void {
    this.dialogRef.close({});
  }

  async confirmDialog() {
    if (this.selectedExportMethod === 'download') {
      this.dialogTitle = this.dialogTranslation?.confirmDownloadAndCloseTitle;
      this.isDownloadAndCloseStep = true;
      return;
    }

    this.dialogTitle = this.dialogTranslation?.confirmExportAndCloseTitle;
    this.isExportAndCloseStep = true;
  }

  downloadAndClose(): void {
    this.dialogRef.close({ method: this.selectedExportMethod });
  }

  async exportAndClose() {
    const datevAuth = await this.injector.get(DatevAuthService).getAuth(this.datevSettingsId);
    if (check.assigned(datevAuth)) {
      this.dialogRef.close({ method: this.selectedExportMethod });
      return;
    }

    const loggedIn = await this.injector.get(DatevApiController).loginWithOauth(this.datevSettingsId, this.target);
    if (loggedIn) {
      this.dialogRef.close({ method: this.selectedExportMethod });
    }
  }

  backDialogButton(): void {
    this.dialogTitle = this.dialogTranslation?.title;
    this.isDownloadAndCloseStep = false;
    this.isExportAndCloseStep = false;
  }

  changeExportMethod(exportMethod: string): void {
    this.selectedExportMethod = exportMethod;
  }
}
