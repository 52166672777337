<ng-template [ngIf]="model && field">
  <ng-template [ngIf]="!readOnly">
    <label [for]="label">{{ label }}<span *ngIf="required && label">*</span></label>
    <mat-radio-group [ngModel]="getValue()" [class.inline]="inline" (ngModelChange)="setValue($event)" [name]="label" [required]="required">
      <mat-radio-button *ngFor="let option of options" [value]="option.value" class="kenjo-mt-20px">
        {{ option.name }}
      </mat-radio-button>
    </mat-radio-group>
    <orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
  </ng-template>

  <ng-template [ngIf]="readOnly">
    <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <input matInput [ngModel]="getValueName()" readOnly disabled [required]="required" />
    </mat-form-field>
  </ng-template>
</ng-template>
