import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import * as check from 'check-types';
import * as _ from 'lodash';

import { InternationalizationService } from '../../../services/core/internationalization.service';
import { EditCustomPermissionsDialog } from '../edit-custom-permissions-dialog/edit-custom-permissions.dialog';

@Component({
  selector: 'orgos-custom-permissions-box-multiple',
  templateUrl: 'custom-permissions-box-multiple.component.html',
  styleUrls: ['custom-permissions-box-multiple.component.scss'],
})
export class CustomPermissionsBoxMultipleComponent implements OnInit {
  i18n: any = {};
  customTranslations: any = [];
  customPermissions: any = []; // Key: permissionName, Value: boolean (true to indicate if the permission is custom or not)

  @Output() permissionsChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() permissionsTitle: string;
  @Input() readOnly: boolean = true;
  @Input() permissionKeys: any;
  @Input() permissionTitles: any;

  private _permissionValues: any = [];
  @Input()
  set permissionValues(permissionValues: any) {
    this._permissionValues = _.cloneDeep(permissionValues);
    if (check.assigned(this.arrayPermissionsToDisplay) && check.nonEmptyArray(this.arrayPermissionsToDisplay)) {
      this.calculateReadOnlyToTrue();
    }
  }
  get permissionValues(): any {
    if (check.not.assigned(this._permissionValues)) {
      return {};
    }

    return this._permissionValues;
  }

  @Input() arrayPermissionsToDisplay: Array<Array<string>>;

  @Input() readOnlyValues: any = {};

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.customPermissions = this.arrayPermissionsToDisplay.map((permissions) =>
      permissions.reduce((total, iPermissionKey) => {
        if (iPermissionKey.endsWith('_custom') && iPermissionKey.startsWith('c_')) {
          total[iPermissionKey] = true;
        }
        return total;
      }, {})
    );

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((i18n) => {
        this.i18n = i18n;
      })
      .catch(() => {
        this.i18n = {};
      });

    if (check.not.assigned(this.readOnlyValues)) {
      this.readOnlyValues = [];
    }
    this.calculateReadOnlyToTrue();
  }

  private calculateReadOnlyToTrue(): void {
    if (
      check.contains(this.arrayPermissionsToDisplay, 'c_viewAttendanceTab_all') &&
      this.readOnlyValues['c_viewAttendanceTab_custom'] !== true
    ) {
      this.readOnlyValues['c_viewAttendanceTab_custom'] = this.permissionValues['c_viewAttendanceTab_all'];
    }
    if (check.contains(this.arrayPermissionsToDisplay, 'c_viewPersonalTab_all')) {
      this.readOnlyValues['c_viewPersonalTab_custom'] = this.permissionValues['c_viewPersonalTab_all'];
    }
  }

  public emitChange(i: number, permissionName: string, value: boolean): void {
    this.permissionValues[i][permissionName] = value;

    if (value === true && permissionName.endsWith('_all')) {
      // If we have just enabled an _all permission
      const permissionToModify = permissionName.replace('_all', '_custom');
      if (check.assigned(this.customPermissions) && check.assigned(this.customPermissions[i][permissionToModify])) {
        this.readOnlyValues[i][permissionToModify] = true;
      }
    } else if (value === false && permissionName.endsWith('_all')) {
      // If we have just disabled an _all permission
      const permissionToModify = permissionName.replace('_all', '_custom');
      if (check.assigned(this.customPermissions) && check.assigned(this.customPermissions[i][permissionToModify])) {
        this.readOnlyValues[i][permissionToModify] = false;
      }
    }

    const data = {
      permissionKey: this.permissionKeys[i],
      permissions: this.permissionValues[i],
    };

    this.permissionsChanged.emit(data);
  }

  public openCustomPermissionsDialog(i: number, permissionName: string): void {
    if (
      check.not.assigned(permissionName) ||
      check.not.string(permissionName) ||
      this.readOnly === true ||
      (check.assigned(this.readOnlyValues) && this.readOnlyValues[i][permissionName] === true)
    ) {
      return;
    }

    const data = {
      permissions: _.cloneDeep(this.permissionValues[i][permissionName]),
      permissionLabel: this.i18n[i][permissionName],
      permissionKey: this.permissionKeys[i],
      permissionName: permissionName,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditCustomPermissionsDialog, { data });
    dialogRef.afterClosed().subscribe((newPermissions) => {
      if (check.not.assigned(newPermissions) || check.not.array(newPermissions)) {
        return;
      }
      this.permissionValues[i][permissionName] = newPermissions;
      this.emitChange(i, permissionName, newPermissions);
    });
  }
}
