import { IShiftCardSizeSettings } from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import {
  SHIFT_PLAN_CARD_COMPACT,
  SHIFT_PLAN_CARD_EXPANDED,
  SHIFT_PLAN_MONTHLY_VIEW,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

const TIME_CONSTANTS = {
  MINIMUM_DIFFERENCE_SHIFT_DURATION: 15,
  MAXIMUM_DIFFERENCE_SHIFT_DURATION: 1425,
  MINUTES_IN_A_DAY: 1440,
  MINUTES_IN_AN_HOUR: 60,
};

export const calculateShiftDurationPure = (start: number, end: number, breakTime: number, breakTimeDeducted: boolean) => {
  let shiftDuration = { hours: 0, minutes: 0 };
  const errors = {};
  if (check.assigned(start) && check.assigned(end)) {
    const minutesDuration = end > start ? end - start : end + TIME_CONSTANTS.MINUTES_IN_A_DAY - start;
    const totalMinutesDurationIncludingBreaks = minutesDuration - breakTime;
    errors['hasBreakErrors'] = totalMinutesDurationIncludingBreaks <= breakTime;
    errors['hasDurationMinErrors'] = totalMinutesDurationIncludingBreaks < TIME_CONSTANTS.MINIMUM_DIFFERENCE_SHIFT_DURATION;
    errors['hasDurationMaxErrors'] = totalMinutesDurationIncludingBreaks > TIME_CONSTANTS.MAXIMUM_DIFFERENCE_SHIFT_DURATION;
    if (!breakTimeDeducted) {
      shiftDuration = {
        hours: Math.floor(minutesDuration / TIME_CONSTANTS.MINUTES_IN_AN_HOUR),
        minutes: minutesDuration % TIME_CONSTANTS.MINUTES_IN_AN_HOUR,
      };
    } else {
      shiftDuration = {
        hours: Math.floor(totalMinutesDurationIncludingBreaks / TIME_CONSTANTS.MINUTES_IN_AN_HOUR),
        minutes: totalMinutesDurationIncludingBreaks % TIME_CONSTANTS.MINUTES_IN_AN_HOUR,
      };
    }
  }

  const isThereAnyError = Object.values(errors).some(Boolean);
  if (isThereAnyError) {
    shiftDuration = { hours: 0, minutes: 0 };
  }
  return { shiftDuration, errors };
};

export const handleCardSize = ({ cardSize, view }): string => {
  if (check.not.assigned(cardSize)) {
    return 'compact';
  }

  if (check.nonEmptyObject(cardSize)) {
    return cardSize[view];
  }

  if (view === SHIFT_PLAN_MONTHLY_VIEW && cardSize === SHIFT_PLAN_CARD_EXPANDED) {
    return SHIFT_PLAN_CARD_COMPACT;
  }

  return cardSize;
};

export const changeCardSize = (
  shiftCardSettings: IShiftCardSizeSettings,
  compact: boolean = true
): { cardSizeSettings: IShiftCardSizeSettings; fieldsNumber: number } => {
  const cardSizeSettings = {
    role: !compact || (shiftCardSettings.role && compact),
    time: !compact || (shiftCardSettings.time && compact),
    duration: !compact || (shiftCardSettings.duration && compact),
    location: !compact || (shiftCardSettings.location && compact),
    tag: shiftCardSettings.tag && compact,
  };

  let compactHeight, fieldsNumber;
  if (compact) {
    fieldsNumber = Object.values(cardSizeSettings).filter(Boolean).length;
    compactHeight = `${fieldsNumber * 23 + 8}px`;
  } else {
    fieldsNumber = 4;
    compactHeight = '93px';
  }
  const firstField = Object.entries(cardSizeSettings).find(([_, value]) => value === true);
  return {
    cardSizeSettings: { ...cardSizeSettings, compactHeight, firstField: firstField[0] },
    fieldsNumber,
  };
};
