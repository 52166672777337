import { CdkOverlayOrigin, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { TagsPicklistComponent } from '@app/common-components/layout-import-payslips/components/tags-picklist/tags-picklist.component';
import {
  IProcessedPayslipMetadata,
  IUsersMap,
  PayslipsImportService,
} from '@app/common-components/layout-import-payslips/services/payslips-import.service';
import { TaskName } from '@app/common-components/layout-import-payslips/services/task-progress';
import { SendSignatureConfirmDialogComponent } from '@app/common-components/send-signature-confirm-dialog/send-signature-confirm-dialog.component';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { CoreFeaturesService } from '@app/standard/services/core-features/core-features.service';
import { DocumentTagService, IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { SignatureRequestService } from '@app/standard/services/signature-request/signature-request.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserFinancialService } from '@app/standard/services/user/user-financial.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

const TWO_USERS_IN_SAME_PAGE = 'ORGOS_TWO_USERS_IN_SAME_PAGE';

@Component({
  selector: 'orgos-layout-import-payslips',
  templateUrl: 'layout-import-payslips.component.html',
  styleUrls: ['layout-import-payslips.component.scss'],
})
export class LayoutImportPayslipsComponent implements OnInit {
  payslips: Array<IProcessedPayslipMetadata> = [];

  usersMap: IUsersMap = {};
  tagsMap: _.Dictionary<IDocumentTagModel> = {};

  payslipsName: string = '';
  payslipsDate: moment.Moment = moment.utc();
  payslipsTags: Array<string> = [];
  maxDate: moment.Moment = moment.utc().add(6, 'months');

  payslipsNameValidation: InputValidation;

  processingPayslips: boolean = false;
  processingPayslipsTask: TaskName = 'ParsePdf';
  processingPayslipsProgress: number = 0;

  importingPayslips: boolean = false;
  importingProgress: number = 0;

  assignedPayslips: number = 0;
  uniqueEmployeesAssigned: number = 0;
  totalPayslips: number = 0;

  userCanCreateSignatureRequest: boolean = false;
  selectedForSignaturePayslips: number = 0;
  digitalSignatureActive: boolean = false;
  creditsAvailable: number;
  originalCreditsAvailable: number;
  allAssignedPayslipsSelected: boolean = false;

  @Input() uploadedPayslips: Array<IFileMetadata> = [];
  @Input() i18n: any = {};
  @Output() exit: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(CdkOverlayOrigin) tagsPicklistOrigin: CdkOverlayOrigin;

  constructor(private injector: Injector, private overlay: Overlay, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.processingPayslips = true;
    this.loadPageData();
  }

  private async loadPageData(): Promise<void> {
    const getUserPersonal = this.injector.get(UserPersonalService).getAllUserPersonal(false);
    const getUserAccount = this.injector.get(UserAccountService).find({ _id: { $ne: null } });
    const getUserFinancial = this.injector.get(UserFinancialService).find({ _id: { $ne: null } });
    const getTags = this.injector.get(DocumentTagService).getTags({ applyPermissions: false, onlyActive: true });
    const getDigitalSignatureStatus = this.injector.get(CoreFeaturesService).getAppDetail('digital-signature');
    const getSignaturePermission = this.injector.get(PrivateSecurityService).getPermissionsForCollections(['e-signature']);

    const [userAccounts, userPersonals, userFinancial, tags, digitalSignature, permissions] = await Promise.all([
      getUserAccount,
      getUserPersonal,
      getUserFinancial,
      getTags,
      getDigitalSignatureStatus,
      getSignaturePermission,
    ]);
    const usersMap: IUsersMap = <any>_.keyBy(userPersonals, '_id');
    const userEmails: Map<string, string> = new Map();
    userAccounts.forEach((iAccount) => {
      userEmails.set(iAccount._id, iAccount.email);
    });
    userFinancial.forEach((iUser) => {
      if (check.assigned(usersMap[iUser._id])) {
        usersMap[iUser._id] = Object.assign({}, usersMap[iUser._id], iUser, { email: userEmails.get(iUser._id) });
      }
    });
    this.userCanCreateSignatureRequest = permissions?.['e-signature']?.create_requests === true;

    this.usersMap = usersMap;
    this.processUploadedPayslips();

    this.tagsMap = _.keyBy(tags, '_id');
    if (digitalSignature.isActive) {
      this.digitalSignatureActive = true;
      const { total, used } = await this.injector.get(SignatureRequestService).getSignatureRequestCount();
      this.creditsAvailable = total - used < 0 ? 0 : total - used;
      this.originalCreditsAvailable = total - used < 0 ? 0 : total - used;
    }
  }

  goBack(): void {
    this.exit.emit();
  }

  setPayslipDate(eventDate: { startDayOfMonth: moment.Moment; endDayOfMonth: moment.Moment }) {
    this.payslipsDate = eventDate.startDayOfMonth;
  }

  private async processUploadedPayslips(): Promise<void> {
    try {
      if (check.not.emptyArray(this.uploadedPayslips)) {
        const newPayslips = await this.injector
          .get(PayslipsImportService)
          .processUploadedPayslips(this.uploadedPayslips, this.usersMap, (taskName: TaskName, progress: number) => {
            this.processingPayslipsTask = taskName;
            this.processingPayslipsProgress = progress;
          });

        const unorderedPayslips = this.payslips.concat(newPayslips);
        this.payslips = unorderedPayslips.sort((a, b) => {
          if (check.not.assigned(a.userId) || check.emptyString(a.userId) || a.userId === TWO_USERS_IN_SAME_PAGE) {
            return -1;
          }

          if (check.not.assigned(b.userId) || check.emptyString(b.userId) || b.userId === TWO_USERS_IN_SAME_PAGE) {
            return 1;
          }

          const nameA = this.usersMap[a.userId].displayName.toLowerCase();
          const nameB = this.usersMap[b.userId].displayName.toLowerCase();

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });

        this.recomputePayslipsAssignments();
        this.processingPayslips = false;
      }
    } catch {
      this.recomputePayslipsAssignments();
      this.processingPayslips = false;
    }
  }

  private recomputePayslipsAssignments(): void {
    // total documents
    this.totalPayslips = _.sumBy(this.payslips, (iPayslips) => {
      return iPayslips.mustBeDeleted !== true ? 1 : 0;
    });

    // total documents with users
    const assignedUsers: Set<string> = new Set<string>();
    this.assignedPayslips = _.sumBy(this.payslips, (iPayslips) => {
      if (check.assigned(iPayslips.userId) && iPayslips.userId !== TWO_USERS_IN_SAME_PAGE && iPayslips.mustBeDeleted !== true) {
        assignedUsers.add(iPayslips.userId);
        return 1;
      }
      return 0;
    });
    this.uniqueEmployeesAssigned = assignedUsers.size;

    // total documents for signatures
    this.selectedForSignaturePayslips = _.sumBy(this.payslips, (iPayslips) => {
      return iPayslips.signatureRequested === true && iPayslips.mustBeDeleted !== true ? 1 : 0;
    });

    // credits available
    this.creditsAvailable = this.originalCreditsAvailable - this.selectedForSignaturePayslips;
    this.allAssignedPayslipsSelected =
      this.assignedPayslips > 0 &&
      (this.selectedForSignaturePayslips === this.assignedPayslips || (this.creditsAvailable === 0 && this.originalCreditsAvailable > 0));
    this.cdr.detectChanges();
  }

  public changSelectionInAssignedPayslips(): void {
    if (
      (!this.allAssignedPayslipsSelected === false && this.creditsAvailable === 0 && this.originalCreditsAvailable > 0) ||
      this.assignedPayslips <= 0 ||
      this.originalCreditsAvailable <= 0
    ) {
      this.allAssignedPayslipsSelected = false;
      return;
    }
    let tempAvailable = this.creditsAvailable;
    this.payslips.forEach((iPayslip) => {
      if (check.assigned(iPayslip.userId)) {
        const diffQuantity = this.allAssignedPayslipsSelected
          ? !iPayslip.signatureRequested
            ? 1
            : 0
          : iPayslip.signatureRequested
          ? -1
          : 0;
        tempAvailable -= diffQuantity;
        if (tempAvailable >= 0 || this.allAssignedPayslipsSelected === false) {
          iPayslip.signatureRequested = this.allAssignedPayslipsSelected;
        }
      }
    });
    this.recomputePayslipsAssignments();
  }

  openImportAllPayslipsConfirmDialog(): void {
    const data = {
      translations: this.i18n.layoutImportPayslips.summary,
      summaryData: {
        documents: this.totalPayslips,
        employees: this.uniqueEmployeesAssigned,
        signatures: this.selectedForSignaturePayslips,
        currentCredits: this.originalCreditsAvailable,
        creditsToBeUsed: this.selectedForSignaturePayslips,
        showSignatures: this.digitalSignatureActive && this.userCanCreateSignatureRequest,
      },
      shouldImportPayslips: true,
      payslipsName: this.payslipsName,
      payslips: this.payslips,
      payslipsTags: this.payslipsTags,
      payslipsDate: this.payslipsDate,
      usersMap: this.usersMap,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(SendSignatureConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((value) => {
      if (check.assigned(value) && value !== false) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.layoutImportPayslips.payslipsImportedSnackBar, 'OK', { duration: 5000 });
        this.exit.emit();
      }
    });
  }

  addTag(): void {
    const tagsPicklistComponent = new ComponentPortal(TagsPicklistComponent);

    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.backdropClass = '';
    config.minWidth = 250;
    config.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.tagsPicklistOrigin.elementRef)
      .withPositions([
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
        { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' },
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
      ])
      .withPush(false);

    const tagsPicklistOverlayRef = this.overlay.create(config);
    const tagsPicklistComponentRef = tagsPicklistOverlayRef.attach(tagsPicklistComponent);
    tagsPicklistComponentRef.instance.placeholder = this.i18n.layoutImportPayslips.tagsPicklist.searchPlaceholder;
    tagsPicklistComponentRef.instance.errorMessage = this.i18n.layoutImportPayslips.tagsPicklist.errorMessage;
    tagsPicklistComponentRef.instance.tags = _.values(this.tagsMap).filter((iTag) => {
      return check.not.contains(this.payslipsTags, iTag._id);
    });
    tagsPicklistComponentRef.instance.refreshSearchResults();

    tagsPicklistComponentRef.instance.tagSelected.subscribe((tagSelected) => {
      this.payslipsTags = [...this.payslipsTags, tagSelected._id];
      tagsPicklistOverlayRef.dispose();
    });

    tagsPicklistOverlayRef.backdropClick().subscribe(() => {
      tagsPicklistOverlayRef.dispose();
    });
  }

  getTagColor(colorKey: string): string {
    return userColorConstants[colorKey];
  }

  deleteTag(tagId: string): Function {
    return () => {
      this.payslipsTags = this.payslipsTags.filter((iTagId) => {
        return iTagId !== tagId;
      });
    };
  }
}
