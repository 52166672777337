import { DatePipe, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IUserOptions } from '@app/standard/components/input-search-user/input-search-user.component';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { AreaService } from '@app/standard/services/company/area.service';
import { CompanyService } from '@app/standard/services/company/company.service';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { OfficeService } from '@app/standard/services/company/office.service';
import { TeamService } from '@app/standard/services/company/team.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CustomFieldService } from '@app/standard/services/custom-field/custom-field.service';
import { DocumentTemplateService, IDocumentTemplateModel } from '@app/standard/services/document/document-template.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserService } from '@app/standard/services/user/user.service';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'orgos-documents-templates-detail',
  templateUrl: 'documents-templates-detail.page.html',
  styleUrls: ['documents-templates-detail.page.scss'],
})
export class DocumentsTemplatesDetailPage implements OnInit, OnDestroy {
  pageTranslation: any = {};
  documentTemplateCollectionTranslation: any = {};
  standardPicklists: any = {};
  miscTranslation: any = {};
  documentTemplate: IDocumentTemplateModel;

  templateId: string = '';
  hasEditPermission: boolean = false;
  hasDeletePermission: boolean = false;

  createDocumentModeActivated: boolean = false;
  editModeActivated: boolean = false;
  get readModeActivated(): boolean {
    return this.createDocumentModeActivated === false && this.editModeActivated === false;
  }

  documentTemplateModel: GenericCacheModel;

  allUserPersonal: Array<any> = [];
  allFields: Array<ISelectOption> = [];

  createDocumentModel: GenericCacheModel;
  assigneeOptions: Array<IUserOptions> = [];
  data: any = {};

  makeVisibleToAssignee: boolean = false;

  nameValidation: InputValidation;
  assigneeValidation: InputValidation;

  creatingDocument: boolean = false;

  idsToValues: Map<string, any> = new Map<string, any>();

  private keepSubscriptions: boolean = true;
  private backButtonSubscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, private injector: Injector, private location: Location) {
    // When the user clicks on the back button of the Browser:
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.onBackClick(false);
      }
    }) as Subscription;
  }

  ngOnInit(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.injector.get(GlobalBarService).setEnableDefaultBars(false);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('documents-templates-detail-page')
      .then((pageTranslation: any) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-template-collection')
      .then((documentTemplateCollectionTranslation: any) => {
        this.documentTemplateCollectionTranslation = documentTemplateCollectionTranslation;
      })
      .catch(() => {
        this.documentTemplateCollectionTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((standardPicklists: any) => {
        this.standardPicklists = standardPicklists;
      })
      .catch(() => {
        this.standardPicklists = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation: any) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.route.paramMap
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        this.documentTemplate = null;
        this.documentTemplateModel = null;

        this.injector.get(GlobalBarService).setProgressBar(true);

        this.templateId = id;

        this.fetchData();
      });
  }

  ngOnDestroy(): void {
    this.keepSubscriptions = false;
    this.backButtonSubscription.unsubscribe();
  }

  private fetchData(): void {
    this.injector
      .get(DocumentTemplateService)
      .getById(this.templateId)
      .then((documentTemplate: IDocumentTemplateModel) => {
        this.documentTemplate = documentTemplate;
        this.documentTemplateModel = new GenericCacheModel(
          this.injector,
          documentTemplate,
          DocumentTemplateService,
          documentTemplate.ownerId
        );

        this.injector.get(GlobalBarService).setProgressBar(false);

        this.injector
          .get(DocumentTemplateService)
          .getPermissions()
          .then((permissions: any) => {
            if (permissions.edit_all && permissions.edit_all === true) {
              this.hasEditPermission = true;
            } else if (permissions.edit_own && permissions.edit_own === true) {
              this.hasEditPermission = this.documentTemplate.ownerId === this.injector.get(AuthenticationService).getLoggedUser()._id;
            } else {
              this.hasEditPermission = false;
            }

            if (permissions.delete_all && permissions.delete_all === true) {
              this.hasDeletePermission = true;
            } else if (permissions.delete_own && permissions.delete_own === true) {
              this.hasDeletePermission = this.documentTemplate.ownerId === this.injector.get(AuthenticationService).getLoggedUser()._id;
            } else {
              this.hasDeletePermission = false;
            }
          })
          .catch(() => {
            // An error is already shown
            this.hasEditPermission = false;
            this.hasDeletePermission = false;
          });
      })
      .catch((error) => {
        // An error is already shown
        this.documentTemplate = null;
        this.documentTemplateModel = null;
        this.injector.get(GlobalBarService).setProgressBar(false);
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, true)
      .then((allUserPersonal: Array<any>) => {
        this.allUserPersonal = allUserPersonal;

        allUserPersonal.forEach((iUserPersonal) => {
          this.idsToValues.set(iUserPersonal._id, iUserPersonal.displayName);
        });
      })
      .catch(() => {
        // An error is already shown
        this.allUserPersonal = [];
      });

    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((allCompanies: Array<any>) => {
        allCompanies.forEach((iCompany) => {
          this.idsToValues.set(iCompany._id, iCompany.name);
        });
      })
      .catch(() => {
        // An error is already shown
      });

    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((allDepartments: Array<any>) => {
        allDepartments.forEach((iDepartment) => {
          this.idsToValues.set(iDepartment._id, iDepartment.name);
        });
      })
      .catch(() => {
        // An error is already shown
      });

    this.injector
      .get(OfficeService)
      .getOffices()
      .then((allOffices: Array<any>) => {
        allOffices.forEach((iOffice) => {
          this.idsToValues.set(iOffice._id, iOffice.name);
        });
      })
      .catch(() => {
        // An error is already shown
      });

    this.injector
      .get(AreaService)
      .getAreas()
      .then((allAreas: Array<any>) => {
        allAreas.forEach((iArea) => {
          this.idsToValues.set(iArea._id, iArea.name);
        });
      })
      .catch(() => {
        // An error is already shown
      });

    this.injector
      .get(TeamService)
      .getTeams()
      .then((allTeams: Array<any>) => {
        allTeams.forEach((iTeam) => {
          this.idsToValues.set(iTeam._id, iTeam.name);
        });
      })
      .catch(() => {
        // An error is already shown
      });

    const getFields = this.injector.get(UserService).getModel();
    const getFieldsNames = this.injector.get(InternationalizationService).getAllTranslation('user-collection');
    const getCustomFieldsNames = this.injector.get(CustomFieldService).getUserCustomFields();

    Promise.all([getFields, getFieldsNames, getCustomFieldsNames])
      .then((result: any) => {
        const fields = result[0];
        let fieldsNames = result[1];
        const customFieldsNames = result[2];
        const customFieldsNamesFormated = customFieldsNames.reduce((total, curr) => {
          const index = curr.collectionName.indexOf('-');
          const textToReplace = curr.collectionName.slice(index, index + 2);
          const newText = textToReplace.replace('-', '').toUpperCase();
          const formatedName = curr.collectionName.replace(textToReplace, newText);
          total[`${formatedName}.${curr.fieldApiName}`] = curr.fieldLabel;
          return total;
        }, {});
        fieldsNames = Object.assign({}, fieldsNames, customFieldsNamesFormated);

        const allFields: Array<ISelectOption> = [];
        fields.forEach((iField: any) => {
          const iFieldName = fieldsNames[iField.name];
          if (check.assigned(iFieldName) && check.not.emptyString(iFieldName)) {
            allFields.push({
              name: iFieldName,
              value: iField.name,
            });
          }
        });
        this.allFields = allFields;
      })
      .catch(() => {
        this.allFields = [];
      });
  }

  onBackClick(changeLocationState: boolean = true): void {
    this.backButtonSubscription.unsubscribe();

    this.documentTemplate = null;
    this.documentTemplateModel = null;
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, changeLocationState);
  }

  updateDocumentTemplate(): void {
    if (!this.nameValidation || this.nameValidation.hasErrors()) {
      return;
    }

    const documentTemplate = this.documentTemplateModel.data;
    documentTemplate.setupDone = true;

    this.injector
      .get(DocumentTemplateService)
      .updateById(this.documentTemplateModel.data._id, documentTemplate)
      .then(() => {
        const documentTemplateEditedSnackText = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.documentTemplateEditedSnackText, this.documentTemplateModel.data);
        this.injector.get(MatLegacySnackBar).open(documentTemplateEditedSnackText, 'OK', {
          duration: 5000,
        });

        this.fetchData();
        this.editModeActivated = false;
      })
      .catch(() => {
        // An error is already shown
      });
  }

  cancel(): void {
    this.fetchData();
    this.createDocumentModeActivated = false;
    this.editModeActivated = false;
  }

  createDocument(): void {
    if (!this.assigneeValidation || this.assigneeValidation.hasErrors()) {
      return;
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-create-dialog')
      .then((documentCreateTranslation) => {
        const dialogData = {
          titleText: documentCreateTranslation.dialogHeader,
          subtitleText: documentCreateTranslation.warningMessageText,
          confirmButtonText: documentCreateTranslation.createButtonLabel,
          confirmButtonColor: 'Success',
          cancelButtonText: this.miscTranslation.goBackButtonDialog,
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
        dialogRef.afterClosed().subscribe((createDocument: boolean) => {
          if (check.not.assigned(createDocument) || createDocument === false) {
            return;
          }

          const createDocumentBody = {
            templateId: this.templateId,
            assigneeId: this.createDocumentModel.data.assigneeId,
            makeVisibleToAssignee: this.makeVisibleToAssignee,
            data: this.data,
          };

          this.creatingDocument = true;

          this.injector
            .get(DocumentTemplateService)
            .createDocument(createDocumentBody)
            .then((documentCreated) => {
              this.creatingDocument = false;

              const snackBarRef = this.injector
                .get(MatLegacySnackBar)
                .open(
                  this.pageTranslation.documentSuccessfullyCreatedSnackText,
                  this.pageTranslation.documentSuccessfullyCreatedSnackAction,
                  {
                    duration: 5000,
                  }
                );

              snackBarRef.onAction().subscribe(() => {
                this.router.navigate(['/cloud/documents/employee-docs', documentCreated.relatedTo.idRelatedTo]);
              });

              this.router.navigateByUrl('/cloud/documents/templates');
            })
            .catch(() => {
              // An error is already shown

              this.creatingDocument = false;
            });
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  download(): void {
    this.injector
      .get(HttpClient)
      .get(this.documentTemplate._file._url, { responseType: 'arraybuffer' })
      .subscribe((arrayBuffer) => {
        const extension: string = this.documentTemplate._file._fileExtension;
        const documentName: string =
          check.nonEmptyString(extension) && this.documentTemplate.name.endsWith(extension) === false
            ? `${this.documentTemplate.name}.${extension}`
            : this.documentTemplate.name;

        FileSaver.saveAs(new Blob([arrayBuffer]), documentName);
      });
  }

  delete(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-delete-template-dialog')
      .then((documentTemplateDeleteTranslation) => {
        const dialogData = {
          titleText: this.injector.get(I18nDataPipe).transform(documentTemplateDeleteTranslation.dialogHeader, this.documentTemplate),
          subtitleText: documentTemplateDeleteTranslation.warningMessageText,
          confirmButtonText: documentTemplateDeleteTranslation.deleteButtonLabel,
          confirmButtonColor: 'Danger',
          cancelButtonText: this.miscTranslation.goBackButtonDialog,
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
        dialogRef.afterClosed().subscribe((deleteDocumentTemplate: boolean) => {
          if (check.not.assigned(deleteDocumentTemplate) || deleteDocumentTemplate === false) {
            return;
          }

          this.injector
            .get(DocumentTemplateService)
            .deleteById(this.templateId)
            .then(() => {
              const documentTemplateDeletedSnackText = this.injector
                .get(I18nDataPipe)
                .transform(this.pageTranslation.documentTemplateDeletedSnackText, this.documentTemplate);
              this.injector.get(MatLegacySnackBar).open(documentTemplateDeletedSnackText, 'OK', {
                duration: 5000,
              });

              this.onBackClick();
            })
            .catch(() => {
              // An error is already shown.
            });
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  getUserPersonal(userId: string): any {
    const user = this.allUserPersonal.find((iUserPersonal) => {
      return iUserPersonal._id === userId;
    });

    return user;
  }

  getProfile(profileKey: string): string {
    if (
      check.not.assigned(this.standardPicklists) ||
      check.not.assigned(this.standardPicklists.profiles) ||
      check.not.assigned(this.standardPicklists.profiles[profileKey])
    ) {
      return profileKey;
    }

    return this.standardPicklists.profiles[profileKey];
  }

  getFieldName(fieldValue: string): string {
    const field = this.allFields.find((iField) => {
      return iField.value === fieldValue;
    });

    if (check.not.assigned(field)) {
      return '';
    }

    return field.name;
  }

  enableCreateDocumentMode(): void {
    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(true)
      .then((allUserPersonalData: Array<any>) => {
        this.assigneeOptions = allUserPersonalData.map((iUser) => {
          return {
            _id: iUser._id,
            displayName: iUser.displayName,
            _photo: iUser._photo,
          };
        });
      })
      .catch(() => {
        // An error is already shown
        this.assigneeOptions = [];
      });

    const rawCreateDocument = {
      assigneeId: '',
    };
    this.createDocumentModel = new GenericCacheModel(this.injector, rawCreateDocument, DocumentTemplateService, '');
    this.assigneeValidation = null;

    this.createDocumentModeActivated = true;
  }

  assigneeChange(): void {
    if (
      check.not.assigned(this.createDocumentModel) ||
      check.not.assigned(this.createDocumentModel.data.assigneeId) ||
      check.emptyString(this.createDocumentModel.data.assigneeId)
    ) {
      this.data = {};
      return;
    }

    this.injector
      .get(UserService)
      .getUserDetail(this.createDocumentModel.data.assigneeId, true)
      .then((assigneeData) => {
        this.data = this.processAssigneeData(assigneeData);
      })
      .catch(() => {
        this.data = {};
      });
  }

  processAssigneeData(assigneeData: any): any {
    if (check.not.assigned(assigneeData) || check.emptyObject(assigneeData)) {
      return {};
    }

    const processedData = {};

    this.documentTemplate.fields.forEach((iField: any) => {
      let value = _.get(assigneeData, iField.field);

      if (this.idsToValues.has(value)) {
        value = this.idsToValues.get(value);
      }

      if (check.nonEmptyArray(value)) {
        let changedValue = '';
        for (let index = 0; index < value.length; index++) {
          if (index !== 0) {
            changedValue += ', ';
          }

          if (this.idsToValues.has(value[index])) {
            changedValue += this.idsToValues.get(value[index]);
          } else {
            changedValue += value[index];
          }
        }
        value = changedValue;
      }

      if (check.assigned(value) && isNaN(value) && moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()) {
        value = this.injector.get(DatePipe).transform(value, 'shortDate', 'UTC');
      }

      processedData[iField.tag] = value;
    });

    return processedData;
  }
}
