<orgos-dialog-container class="dialog-box" [title]="dialogTitle" (clickCloseButton)="closeDialog()">
  <div *ngIf="!isDownloadAndCloseStep && !isExportAndCloseStep">
    <orgos-column-container class="cdpd-company-name-container kenjo-border-radius kenjo-font-size-18px kenjo-font-color-light-text-757575 kenjo-p-10px kenjo-mb-30px">
      <orgos-column size="0">
        <kenjo-icon [size]="20" class="kenjo-mr-5px">company</kenjo-icon>
      </orgos-column>
      <orgos-column size="1"> {{ dialogTranslation?.companyTitle }} {{ companyName }} </orgos-column>
    </orgos-column-container>
    <div class="kenjo-font-color-text-333333 cdpd-select-export-method-text kenjo-mb-20px">{{ dialogTranslation?.selectExportMethod | uppercase }}</div>
    <orgos-column-container class="cdpd-export-method-options-container">
      <orgos-column size="12" class="kenjo-cursor-pointer kenjo-text-align-center kenjo-pv-15px kenjo-border kenjo-border-radius" [class.cdpd-button-border-selected]="selectedExportMethod === 'sendToDatev'" (click)="changeExportMethod('sendToDatev')">
        <orgos-column-container class="cdpd-export-method-options-container">
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0">
            <mat-radio-button [checked]="selectedExportMethod === 'sendToDatev'"></mat-radio-button>
          </orgos-column>
          <orgos-column size="0" class="cdpd-export-method-option-text">
            <span>{{ dialogTranslation?.exportMethodSendToDatevText | uppercase }}</span>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
        </orgos-column-container>
      </orgos-column>

      <orgos-column size="1"></orgos-column>

      <orgos-column size="12" class="kenjo-cursor-pointer kenjo-text-align-center kenjo-pv-15px kenjo-border kenjo-border-radius cdpd-export-method-button-container" [class.cdpd-button-border-selected]="selectedExportMethod === 'download'" (click)="changeExportMethod('download')">
        <orgos-column-container class="cdpd-export-method-options-container">
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0">
            <mat-radio-button [checked]="selectedExportMethod === 'download'"></mat-radio-button>
          </orgos-column>
          <orgos-column size="0" class="kenjo-mr-5px kenjo-font-color-light-text-757575">
            <kenjo-icon [size]="20">file_download</kenjo-icon>
          </orgos-column>
          <orgos-column size="0" class="cdpd-export-method-option-text">
            <span>{{ dialogTranslation?.exportMethodDownloadText | uppercase }}</span>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container *ngIf="dialogTranslation?.warningMessageText" class="kenjo-mt-30px cdpd-warning-box" centerColumns="true">
      <orgos-column size="0">
        <kenjo-icon [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
      </orgos-column>
      <orgos-column size="1" class="kenjo-ml-10px"> {{ dialogTranslation?.warningMessageText }} </orgos-column>
    </orgos-column-container>

    <!-- ACTIONS -->
    <orgos-column-container class="kenjo-mv-40px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Neutral" (click)="closeDialog()"> {{ dialogTranslation?.cancelButtonText | uppercase }} </orgos-button-raised>
      </orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Success" class="kenjo-ml-20px" (click)="confirmDialog()"> {{ selectedExportMethod === 'sendToDatev' ? dialogTranslation?.confirmSendButtonText : dialogTranslation?.confirmDownloadButtonText | uppercase }} </orgos-button-raised>
      </orgos-column>
    </orgos-column-container>
  </div>

  <!-- DOWNLOAD AND CLOSE STEP -->
  <div *ngIf="isDownloadAndCloseStep">
    <orgos-column-container class="">
      <orgos-column size="1">
        <div class="kenjo-font-color-light-text-757575 kenjo-mb-20px">{{dialogTranslation?.downloadAndCloseFirstText}} {{dialogTranslation?.downloadAndCloseSecondText}}</div>
        <div class="kenjo-font-weight-bold">{{dialogTranslation?.downloadAndCloseDisclaimerText}}</div>
      </orgos-column>
    </orgos-column-container>
    <!-- ACTIONS -->
    <orgos-column-container class="kenjo-mt-40px kenjo-mb-10px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Neutral" (click)="backDialogButton()">{{ dialogTranslation?.backFirstStepButtonText | uppercase }}</orgos-button-raised>
      </orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Success" class="kenjo-ml-20px" (click)="downloadAndClose()">{{ dialogTranslation?.confirmDownloadSecondButtonText | uppercase }}</orgos-button-raised>
      </orgos-column>
    </orgos-column-container>
  </div>

  <!-- Export and close -->
  <div *ngIf="isExportAndCloseStep">
    <div class="kenjo-font-color-light-text-757575">{{ dialogTranslation.exportAndCloseDescription }}</div>

    <orgos-column-container class="kenjo-mt-40px kenjo-mb-10px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Neutral" (click)="backDialogButton()">{{ dialogTranslation?.backFirstStepButtonText | uppercase }}</orgos-button-raised>
      </orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Success" class="kenjo-ml-20px" (click)="exportAndClose()">{{ dialogTranslation?.confirmExportSecondButtonText | uppercase }}</orgos-button-raised>
      </orgos-column>
    </orgos-column-container>
  </div>
</orgos-dialog-container>
