import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class UserCompensationService implements IGenericService {
  private USER_COMPENSATION_PERMISSIONS_KEY: string = 'user-compensation';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserCompensationService.name, 'create');
    error.message = 'UserCompensation should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserCompensationService.name, 'getById');
    error.message = 'UserCompensation should not be got';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  updateById(id: string, data: object): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserCompensationService.name, 'updateById');
    error.message = 'UserCompensation should not be updated';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserCompensationService.name, 'deleteById');
    error.message = 'UserCompensation should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_COMPENSATION_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserCompensationService.name, 'getFieldsTranslations');
    error.message = 'UserCompensation should not be internationalized';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }
}
