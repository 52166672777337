import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class FeedService {
  private FEED_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/feed-db`;
  private FEED_PERMISSIONS_KEY: string = 'feed';
  private FEED_INTERNATIONALIZATION: string = 'feed-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private injector: Injector, private authenticationService: AuthenticationService, private http: HttpClient) {}

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.FEED_URL, data)
        .then((feedDocument: any) => {
          resolve(feedDocument);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, FeedService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.FEED_URL, id)
        .then((document: any) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, FeedService.name, 'getById'));
        });
    });
  }

  find(findBody: object): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.genericService
        .find(this.FEED_URL, findBody)
        .then((results: Array<any>) => {
          resolve(results);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, FeedService.name, 'find'));
        });
    });
  }

  countUnreadFeedsPerTypes(feedTypes: Array<string>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const findQuery = {
        feedType: feedTypes
      };
      this.http
        .post(`${this.FEED_URL}/unread-count`, findQuery, httpOptions)
        .toPromise()
        .then((unreadFeedsPerType) => {
          resolve(unreadFeedsPerType);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, FeedService.name, 'countUnreadFeedsPerTypes'));
        });
    });
  }

  getAllFeeds(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const findQuery = {
        _id: { $ne: null }
      };
      this.http
        .post(`${this.FEED_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((feedDocs) => {
          resolve(feedDocs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, FeedService.name, 'getAllFeeds'));
        });
    });
  }

  createComment(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.FEED_URL}/comment/create/${id}`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, FeedService.name, 'createComment'));
        });
    });
  }

  readComment(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.FEED_URL}/comment/read/${id}`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawErrorSilently(error, FeedService.name, 'readComment'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.FEED_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.FEED_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.FEED_URL);
  }
}
