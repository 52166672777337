import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
// Subscribe to this observable to get live updates about the current state of the navbar.
// This DOES NOT control the status of the navbar, it only provides the current state.
export class NavbarService {
  private _isNavbarExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public get isNavbarExpanded$(): Observable<boolean> {
    return this._isNavbarExpanded.asObservable();
  }

  constructor() {}

  public setNavbarState(state: boolean): void {
    this._isNavbarExpanded.next(state);
  }
}
