import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { IUserAccountModel } from '@app/models/user-account.model';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  EMAIL_DELIVERED,
  EMAIL_FAILED,
  EMAIL_PROCESSED,
  EMAIL_READ,
  INVITATION_COMPLETED,
  INVITATION_EXPIRED,
  NOT_INVITED,
  UserAccountService,
  UserInvitationEmailStatus,
} from '@app/standard/services/user/user-account.service';
import { ChipV2Component } from '@app/standard/standalone-components/chip/chip.component';

@Component({
  selector: 'kenjo-user-invitation-status-chip',
  templateUrl: 'user-invitation-status-chip.component.html',
  styleUrls: ['user-invitation-status-chip.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, IconModule, ChipV2Component, MatLegacyTooltipModule],
})
export class UserInvitationStatusChipComponent implements OnInit, OnChanges {
  @Input() userAccount: IUserAccountModel;
  @Input() displayName: string;
  @Input() hideFinalStatus: boolean; // if true, the component will not be shown if the status is final

  private userInvitationStatus: UserInvitationEmailStatus;
  private translations: Record<string, string> = {};

  chipSemantic: string;
  chipText: string;
  isFinalStatus: boolean;
  tooltipText: string;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.initData();
    }
  }

  private async initData(): Promise<void> {
    try {
      this.initUserInvitationStatus();
      this.isFinalStatus = this.isInFinalStatus();
      if (this.isFinalStatus) {
        return;
      }

      await this.initTranslations();
      this.initChipAttributes();
    } catch {}
  }

  private isInFinalStatus(): boolean {
    if (!this.hideFinalStatus) {
      return false;
    }

    if (this.userAccount?.isActive || this.userAccount?.inactiveReason) {
      return true;
    }

    const thresholdDate = new Date('2024-06-15'); // on this date is when we started to track the invitation status
    if (
      this.userInvitationStatus === NOT_INVITED &&
      !this.userAccount._invitationEmailStatus &&
      this.userAccount._createdAt &&
      new Date(this.userAccount._createdAt) < thresholdDate
    ) {
      return true;
    }

    return false;
  }

  private async initTranslations(): Promise<void> {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('directory-page');
    } catch {
      this.translations = {};
    }
  }

  private initUserInvitationStatus(): void {
    this.userInvitationStatus = this.injector.get(UserAccountService).getUserInvitationStatus(this.userAccount);
  }

  private initChipAttributes(): void {
    if (this.userInvitationStatus === EMAIL_PROCESSED) {
      this.chipSemantic = 'warning';
      this.chipText = this.translations.inviteEmailSent;
      this.initTooltipWithExpirationTime(EMAIL_PROCESSED);
      return;
    }

    if (this.userInvitationStatus === EMAIL_DELIVERED) {
      this.chipSemantic = 'warning';
      this.chipText = this.translations.inviteEmailDelivered;
      this.initTooltipWithExpirationTime(EMAIL_DELIVERED);
      return;
    }

    if (this.userInvitationStatus === EMAIL_READ) {
      this.chipSemantic = 'success';
      this.chipText = this.translations.inviteEmailRead;
      this.initTooltipWithExpirationTime(EMAIL_READ);
      return;
    }

    if (this.userInvitationStatus === NOT_INVITED) {
      this.chipSemantic = 'danger';
      this.chipText = this.translations.notInvited;
      this.tooltipText = this.injector.get(I18nDataPipe).transform(this.translations.notInvitedTooltip, {
        displayName: this.displayName,
      });
      return;
    }

    if (this.userInvitationStatus === EMAIL_FAILED) {
      this.chipSemantic = 'danger';
      this.chipText = this.translations.inviteEmailFailed;
      this.initTooltipForFailedEmail();
      return;
    }

    if (this.userInvitationStatus === INVITATION_EXPIRED) {
      this.chipSemantic = 'danger';
      this.chipText = this.translations.inviteEmailExpired;
      this.initTooltipForExpiredInvitation();
      return;
    }

    if (this.userInvitationStatus === INVITATION_COMPLETED) {
      this.chipSemantic = 'success';
      this.chipText = this.translations.inviteEmailCompleted;
      return;
    }

    this.chipText = this.userInvitationStatus;
  }

  private initTooltipForFailedEmail(): void {
    this.tooltipText = this.injector
      .get(I18nDataPipe)
      .transform(this.translations.inviteEmailFailedTooltip, { email: this.userAccount.email });
  }

  private initTooltipWithExpirationTime(emailStatus: typeof EMAIL_PROCESSED | typeof EMAIL_DELIVERED | typeof EMAIL_READ): void {
    let tooltipText1: string = this.translations.inviteEmailProcessedTooltip;
    if (emailStatus === EMAIL_DELIVERED) {
      tooltipText1 = this.translations.inviteEmailDeliveredTooltip;
    } else if (emailStatus === EMAIL_READ) {
      tooltipText1 = this.injector.get(I18nDataPipe).transform(this.translations.inviteEmailReadTooltip, {
        displayName: this.displayName,
      });
    }

    if (!this.userAccount?._invitationEmailStatus?.invitationExpiresOn) {
      this.tooltipText = tooltipText1;
      return;
    }

    const expirationDateInHumanFormat = this.convertDateToHumanReadable(this.userAccount._invitationEmailStatus.invitationExpiresOn);
    const tooltipText2 = (this.tooltipText = this.injector.get(I18nDataPipe).transform(this.translations.inviteEmailWillExpireOnTooltip, {
      expirationDate: expirationDateInHumanFormat,
    }));

    this.tooltipText = `${tooltipText1} ${tooltipText2}`;
  }

  private initTooltipForExpiredInvitation(): void {
    if (!this.userAccount?._invitationEmailStatus?.invitationExpiresOn) {
      return;
    }

    const expirationDateInHumanFormat = this.convertDateToHumanReadable(this.userAccount._invitationEmailStatus.invitationExpiresOn);
    this.tooltipText = this.tooltipText = this.injector.get(I18nDataPipe).transform(this.translations.inviteEmailHasExpiredOnTooltip, {
      expirationDate: expirationDateInHumanFormat,
    });
  }

  private convertDateToHumanReadable(dateIn: Date): string {
    return this.injector.get(DatePipe).transform(dateIn, 'medium');
  }
}
