<div *ngIf="translations" class="aoto-main-container kenjo-p-20px kenjo-border kenjo-border-radius-10px">
  <div class="flex-horizontal flex-center-vertically">
    <div class="kenjo-flex-size-1 kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ translations.otherTimeOffsDialogTitle }}</div>
    <div class="kenjo-flex-size-0 kenjo-ml-10px">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog.emit()">clear</kenjo-icon>
    </div>
  </div>

  <div
    *ngFor="let timeOff of timeOffs"
    class="kenjo-mt-10px kenjo-p-10px kenjo-border-radius-5px kenjo-color-grey-background-lighter-f8f8f8"
  >
    <div class="flex-horizontal flex-center-vertically kenjo-mt-5px">
      <div [style.background]="USER_COLORS[timeOff?.timeOffEntryInfo?._timeOffTypeColor]" class="aoto-circle-color"></div>
      <div class="kenjo-pl-5px kenjo-font-size-14px kenjo-font-weight-bold">{{ timeOff?.timeOffEntryInfo?._timeOffTypeName }}</div>
    </div>
    <div class="kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-pt-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">
      <ng-container *ngIf="timeOff?.timeOffEntryInfo?.duration === 'FullDay'">{{ translations.timeOffRequestFullDay }}</ng-container>
      <ng-container *ngIf="timeOff?.timeOffEntryInfo?.duration === 'Morning'">{{ translations.timeOffRequestMorning }}</ng-container>
      <ng-container *ngIf="timeOff?.timeOffEntryInfo?.duration === 'Afternoon'">{{ translations.timeOffRequestAfternoon }}</ng-container>
      <ng-container
        *ngIf="timeOff?.timeOffEntryInfo?.duration !== 'FullDay' && timeOff?.timeOffEntryInfo?.duration !== 'Morning' && timeOff?.timeOffEntryInfo?.duration !== 'Afternoon'"
      >
        {{ timeOff?.timeOffEntryInfo?.from }}<kenjo-icon [size]="18" class="aoto-arrow kenjo-mh-5px">arrow_right_alt</kenjo-icon>{{
        timeOff?.timeOffEntryInfo?.to }}
      </ng-container>
    </div>
  </div>
</div>
