import { Component, Input } from '@angular/core';
import { IHistoryEntry } from '@app/common-components/time-off-user-personal/components/status-details-card/status-details-card.component';
import { POLICY_TYPE_DAY } from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Component({
  selector: 'kenjo-adjustment-history-card',
  templateUrl: 'adjustment-history-card.component.html',
  styleUrls: ['adjustment-history-card.component.scss']
})
export class AdjustmentHistoryCardComponent {
  @Input() field: string;
  @Input() rows: Array<IHistoryEntry>;
  @Input() showTooltip: boolean;
  @Input() policyType: string;
  @Input() translations: { [key: string]: string };

  POLICY_TYPE_DAY = POLICY_TYPE_DAY;

  constructor() {}
}
