import { Injectable, Injector } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { IUserSalaryModel } from '@app/standard/services/user/user-salary.service';
import { IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollSurchargeRulesService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/payroll-surcharge-rules-db`;
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/salary-surcharges`;

  constructor(private injector: Injector) {}

  // use this to getAll from the settings page
  async getAll(): Promise<Array<IPayrollSurchargeRule>> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result: Array<IPayrollSurchargeRule> = await this.injector.get(HttpClient).get<Array<IPayrollSurchargeRule>>(`${this.URL}/all`, httpOptions).toPromise();
      result.forEach((surchargeRule: IPayrollSurchargeRule) => {
        surchargeRule.employees = surchargeRule.assignedUserIds?.length ? surchargeRule.assignedUserIds.length : 0;
      });
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'getAll')
    }
  }

  // use this to getAll surcharges from the compensation page
  async getAllForUserId(userId: string): Promise<Array<IPayrollSurchargeRule>> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result: Array<IPayrollSurchargeRule> = await this.injector.get(HttpClient).get<Array<IPayrollSurchargeRule>>(`${this.URL}/all/${userId}`, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'getAll')
    }
  }

  async getPayrollSurchargeRule(id: string): Promise<IPayrollSurchargeRule> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result: IPayrollSurchargeRule = await this.injector.get(HttpClient).get<IPayrollSurchargeRule>(`${this.URL}/${id}`, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'getPayrollSurchargeRule')
    }
  }

  async create(surchargeRule: IPayrollSurchargeRule): Promise<IPayrollSurchargeRule> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try {
      const result: IPayrollSurchargeRule = await this.injector.get(HttpClient).post<IPayrollSurchargeRule>(`${this.URL}/`, surchargeRule, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'create');
    }
  }

  async update(id: string, surchargeRule: IPayrollSurchargeRule): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result = await this.injector.get(HttpClient).put(`${this.URL}/${id}`, surchargeRule, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'update')
    }
  }

  async delete(id: string): Promise<void> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      await this.injector.get(HttpClient).delete(`${this.URL}/${id}`, httpOptions).toPromise();
      return;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'delete');
    }
  }

  async setAssignedUserIds(suchargeRuleId: string, assignedUserIds: Array<string>): Promise<void> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      await this.injector.get(HttpClient).put(`${this.URL}/assign/${suchargeRuleId}`, {assignedUserIds}, httpOptions).toPromise();
      return;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'delete');
    }
  }

  async getSurchargeRulesOfEmployee(userId: string): Promise<Array<IPayrollSurchargeRule>> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result: Array<IPayrollSurchargeRule> = await this.injector.get(HttpClient).get<Array<IPayrollSurchargeRule>>(`${this.URL}/employee/${userId}`, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'getSurchargeRulesOfEmployee')
    }
  }

  async removeEmployeeFromRule(userId: string, surchargeRuleId: string): Promise<void> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      await this.injector.get(HttpClient).put(`${this.URL}/remove-employee/${surchargeRuleId}/${userId}`, { userId }, httpOptions).toPromise();
      return;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'removeEmployeeFromRule');
    }
  }

  async addEmployeeToRule(userId: string, surchargeRuleId: string): Promise<void> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      await this.injector.get(HttpClient).put(`${this.URL}/add-employee/${surchargeRuleId}/${userId}`, { userId }, httpOptions).toPromise();
      return;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'addEmployeeFromRule');
    }
  }

  async getSurchargeSummary(surchargeParams: ISurchargeParamsDataModel): Promise<ISurchargeSummaryModel> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const result: ISurchargeSummaryModel = await this.injector.get(HttpClient).post<ISurchargeSummaryModel>(`${this.CONTROLLER_URL}/calculate-summary-amount`, surchargeParams, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollSurchargeRulesService.name, 'getSurchargeSummary');
    }
  }
}

export interface IWeekdaysSurcharge {
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

export interface IPayrollSurchargeRule {
  _id?: string;
  name?: string;
  description?: string;
  assignedUserIds?: Array<string>;
  extraRatePercentage?: number;
  ruleStartDate?: Date;
  ruleEndDate?: Date;
  appliesToAllDay?: boolean;
  appliesFromTime?: number;
  appliesToTime?: number;
  appliesToPublicHolidays?: boolean;
  appliesToWeekdays?: IWeekdaysSurcharge;
  employees?: number;
}

export interface ISurchargeParamsDataModel {
  userId: string;
  fromDate: Date;
  toDate: Date;
  companyId: string;
}

export interface ISurchargeSummaryModel {
  fromDate: Date;
  toDate: Date;
  userPersonal: IUserPersonalModel;
  salariesApplied: Array<IUserSalaryModel>;
  currencySymbol: number;
  surchargePayments: Array<ISurchargePayment>;
}

export interface ISurchargePayment {
  surchargeRule: IPayrollSurchargeRule;
  surchargeTime: {
    hours: number;
    minutes: number;
  };
  additionalRatesPerHour: Array<number>; // Might be an array if the surcharges come from 2 different salaries. If surchargeHourlyRate = 100 and userAttendance._salarySurcharges._surchargeRulePercentage = 200 (2x), additionalRatePerHour = 100 * 200 / 100 = 200
  additionalPayment: number; // additionalRatePerHour * hours in surcharge
  surchargeAttendances: Array<ISurchargeAttendance>;
  showAttendances?: boolean; // used in the UI only
};

export interface ISurchargeAttendance {
  userAttendance: IUserAttendanceModel;
  surchargeTime: {
    hours: number;
    minutes: number;
  };
  surchargeHourlyRate: number;
  additionalRatePerHour: number;
  additionalPayment: number;
}
