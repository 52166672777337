<div class="rd-container kenjo-cursor-pointer" (click)="openDatePicker()">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      type="text"
      class="kenjo-cursor-pointer"
      required
      [ngModel]="dateFormatted"
      readonly
      [value]="dateFormatted"
      [disabled]="disabled"
    />
    <mat-date-range-input [formGroup]="range" [rangePicker]="datepicker" hidden>
      <input matStartDate [formControlName]="'from'" readonly hidden />
      <input matEndDate [formControlName]="'to'" (dateChange)="onDateChange()" readonly hidden/>
    </mat-date-range-input>
    <mat-date-range-picker [startAt]="startAt" #datepicker></mat-date-range-picker>
  </mat-form-field>
</div>
