import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ISelectOption } from '../../core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-input-simple-select-dropdown',
  templateUrl: './input-simple-select-dropdown.component.html',
  styleUrls: ['./input-simple-select-dropdown.component.scss']
})
export class InputSelectDropdownComponent implements OnInit {
  options: Array<ISelectOption> = [];

  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() label: string;
  @Input() width: string = '260px';
  @Input() selectedOptionValue: string;
  @Input() displayLegacyOptions: false;

  selectedOptionValidation: InputValidation = new InputValidation();
  selectedOptionError: string;
  selectedOption: string;
  displayDropdown = false;

  _selectableOptions: Array<ISelectOption>;
  get selectableOptions(): any {
    return this._selectableOptions;
  }
  @Input() set selectableOptions(value) {
    this._selectableOptions = value;
    this.options = value;
  }

  @Output() selectOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() handleAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();

  translations: { [translationKey: string]: string };

  constructor(private injector: Injector) { }

  ngOnInit(): void {
    this.initTranslations();
    this.initSelectedOption();
  }

  private initSelectedOption() {
    if (check.assigned(this.selectedOptionValue)) {
      this.selectedOption = this.selectedOptionValue;
    }
  }
  private async initTranslations() {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('input-date-calendar-picker');
    } catch {
      this.translations = {};
    }
  }

  public closeDropdown() {
    this.displayDropdown = false;
    this.printOptionErrors();
  }

  emitSelection(value: string): void {
    if (!this.readOnly) {
      this.selectedOption = value;
    }
    this.closeDropdown();
    this.printOptionErrors();
    this.selectOption.emit(value);
  }

  public openDropdown() {
    if (this.readOnly) {
      return;
    }
    this.displayDropdown = true;
  }

  private printOptionErrors() {
    if (check.not.assigned(this.selectedOptionValidation) || !this.selectedOptionValidation.hasErrors()) {
      this.selectedOptionError = undefined;
    } else if (this.selectedOptionValidation.getError('required')) {
      this.selectedOptionError = this.translations.required;
    } else {
      this.selectedOptionError = undefined;
    }
    this.validation.emit(this.selectedOptionValidation);
  }
}
