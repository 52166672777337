import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilterOption } from '@app/standard/components/filter-bar/filter-bar.component';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { environment } from '@env';
import * as check from 'check-types';

import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class ReportService {
  private REPORT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/report-db`;
  private PAYGAP_REPORT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/paygap-report`;
  private REPORT_PERMISSIONS_KEY: string = 'report';
  private REPORT_INTERNATIONALIZATION: string = 'report-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  getAllReports(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.REPORT_URL}/all`, httpOptions)
        .toPromise()
        .then((feedDocs) => {
          resolve(feedDocs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ReportService.name, 'getAllReports'));
        });
    });
  }

  find(findQuery: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.REPORT_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ReportService.name, 'getAllReports'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.REPORT_URL}/identifier/${id}`, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawErrorSilently(error, ReportService.name, 'getById'));
        });
    });
  }

  generateReport(id: string, reportFilters?: any, reportGroupBy?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const filterQuery = reportFilters ? reportFilters : { filters: {} };
      if (check.assigned(reportGroupBy) && check.nonEmptyObject(reportGroupBy)) {
        filterQuery.grouping = reportGroupBy;
      }

      this.http
        .post(`${this.REPORT_URL}/generate-report/${id}`, filterQuery, httpOptions)
        .toPromise()
        .then((result: any) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawErrorSilently(error, ReportService.name, 'generateReport'));
        });
    });
  }

  generateMetadata(id: string, reportFilters?: any, reportGroupBy?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const filterQuery = check.assigned(reportFilters) && check.nonEmptyObject(reportFilters) ? { filters: reportFilters } : { filters: {} };
      if (check.assigned(reportGroupBy) && check.nonEmptyObject(reportGroupBy)) {
        filterQuery['grouping'] = reportGroupBy;
      }

      this.http
        .post(`${this.REPORT_URL}/generate-metadata/${id}`, filterQuery, httpOptions)
        .toPromise()
        .then((result: any) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawErrorSilently(error, ReportService.name, 'generateReport'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.REPORT_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.REPORT_INTERNATIONALIZATION);
  }

  create(data: object): Promise<IReport> {
    return new Promise<IReport>((resolve, reject) => {
      this.genericService
        .create(this.REPORT_URL, data)
        .then((reportCreated: IReport) => {
          resolve(reportCreated);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ReportService.name, 'create'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.REPORT_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ReportService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.REPORT_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ReportService.name, 'deleteById'));
        });
    });
  }

  adhocReports(orgName: string = 'techonrails', reportName: string = 'billing', queryParams: any = {}) {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      let httpParams = new HttpParams();
      Object.keys(queryParams).forEach((iQueryParam) => {
        httpParams = httpParams.append(iQueryParam, queryParams[iQueryParam]);
      });
      const httpOptions = {
        headers: httpHeaders,
        params: httpParams
      };
      this.http
        .get(`${this.REPORT_URL}/${orgName}/${reportName}`, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPaygapReportFilters(): Promise<IPaygapReportFilters> {
    return new Promise<IPaygapReportFilters>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.PAYGAP_REPORT_URL}/filters`, httpOptions)
        .toPromise()
        .then((reportFilters: IPaygapReportFilters) => {
          resolve(reportFilters);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ReportService.name, 'getPaygapReportFilters'));
        });
    });
  }

  getPaygapReport(filters: IPaygapReportFilters): Promise<IPaygapReport> {
    return new Promise<IPaygapReport>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      let httpParams = new HttpParams();
      const httpOptions = {
        headers: httpHeaders
      };

      if (filters) {
        Object.keys(filters).forEach((iQueryParam) => {
          const filterOptions: Array<IFilterOption> = filters[iQueryParam];
          let queryValue;
          if (filterOptions.length === 1) {
            queryValue = filterOptions[0]._id;
          } else if (filterOptions.length > 0) {
            queryValue = filterOptions
              .map((iFilterOption: IFilterOption) => {
                return iFilterOption._id;
              })
              .join(';');
          }
          httpParams = httpParams.append(iQueryParam, queryValue);
        });
        httpOptions['params'] = httpParams;
      }

      this.http
        .get(`${this.PAYGAP_REPORT_URL}`, httpOptions)
        .toPromise()
        .then((report: IPaygapReport) => {
          resolve(report);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ReportService.name, 'getPaygapReport'));
        });
    });
  }
}

export interface IPaygapReportFilters {
  requiredFilters?: Array<string>; // Array with the keys of the report filters that are required to run a paygap report
  jobTitle?: Array<IFilterOption>;
  companyId?: Array<IFilterOption>;
  officeId?: Array<IFilterOption>;
  departmentId?: Array<IFilterOption>;
  year?: Array<IFilterOption>;
  areaIds?: Array<IFilterOption>;
  teamIds?: Array<IFilterOption>;
}

export interface IPaygapReport {
  tableData: IPaygapReportTable;
  mapOfCharts: { [key: string]: SalaryOrPayData };
}

export interface SalaryOrPayData {
  totalAmount: number;
  totalAverage: number;
  totalMedian: number;
  chartData: IChartData;
}

export interface IChartData {
  labels: Array<string>;
  datasets: IDataset;
}

export interface IPaygapReportTable {
  listSalaries: Array<IPaygapSalary>;
  listVariablePayments: Array<IPaygapVariablePay>;
}

export interface IPaygapSalary {
  _id: string;
  _companyId: string;
  effectiveDate: Date;
  amount: number;
  partTime: number;
  payPeriod: string;
  _userId: string;
  gender: string;
  displayName: string;
  photoUrl: string;
  fullTimeYearlyAmount: number;
  costPerHour: number;
}

export interface IPaygapVariablePay {
  _id: string;
  _companyId: string;
  effectiveDate: Date;
  amount: number;
  type: string;
  typeName: string;
  _userId: string;
  gender: string;
  displayName: string;
  photoUrl: string;
}
export interface IDataset {
  backgroundColor: Array<string>;
  totals: Array<number>;
  dataAverage: Array<number>;
  dataMedian: Array<number>;
  deviationAverage?: Array<number>;
  deviationMedian?: Array<number>;
  totalDeviationAverageFemale?: number; // if females earn more than females, this value will be less than zero (negative)
  totalDeviationMedianFemale?: number; // if females earn more than females, this value will be less than zero (negative)
  percentageDeviationMedianFemale?: number; // if females earn more than females, this value will be less than zero (negative)
  percentageDeviationAverageFemale?: number; // if females earn more than females, this value will be less than zero (negative)
}
export interface IReport {
  name: string;
  category?: string;
  description?: string;
  fields?: Array<string>;
  metadata?: Object;
  type?: string;
  _isStandard?: boolean;
  _id?: string;
}
