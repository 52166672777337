import { Component, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';

import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-enps-result',
  templateUrl: 'enps-result.component.html',
  styleUrls: ['enps-result.component.scss']
})
export class EnpsResultComponent implements OnInit {
  RANGE_VERY_LOW: any = { key: 'rangeVeryLow', score: -50, color: '#ff5757' };
  RANGE_LOW: any = { key: 'rangeLow', score: -20, color: '#ff8d0e' };
  RANGE_MODERATE: any = { key: 'rangeModerate', score: 20, color: '#ffc107' };
  RANGE_HIGH: any = { key: 'rangeHigh', score: 50, color: '#B3BD45' };
  RANGE_VERY_HIGH: any = { key: 'rangeVeryHigh', score: 100, color: '#00b72e' };

  @Input() value: number;
  @Input() min: number = -100;
  @Input() max: number = 100;

  currentRange: any = this.RANGE_MODERATE;
  pageTranslation: any = {};

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    this.defineRangeScores();

    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('enps-result-component')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.calculateRange();
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  private defineRangeScores(): void {
    const step = (Number(this.max) - Number(this.min)) / 5;

    this.RANGE_VERY_LOW.score = Number(this.min) + step;
    this.RANGE_LOW.score = Number(this.min) + step * 2;
    this.RANGE_MODERATE.score = Number(this.min) + step * 3;
    this.RANGE_HIGH.score = Number(this.min) + step * 4;
    this.RANGE_VERY_HIGH.score = Number(this.max);
  }

  calculateRange(): void {
    if (check.not.assigned(this.value) || check.not.number(this.value)) {
      return;
    }

    if (this.value <= this.RANGE_VERY_LOW.score) {
      this.currentRange = this.RANGE_VERY_LOW;
    } else if (this.value <= this.RANGE_LOW.score) {
      this.currentRange = this.RANGE_LOW;
    } else if (this.value <= this.RANGE_MODERATE.score) {
      this.currentRange = this.RANGE_MODERATE;
    } else if (this.value <= this.RANGE_HIGH.score) {
      this.currentRange = this.RANGE_HIGH;
    } else {
      this.currentRange = this.RANGE_VERY_HIGH;
    }
  }

  getStringValue(): string {
    if (check.not.assigned(this.value)) {
      return '-';
    }
    let result = this.precisionRound(this.value, 1).toString();
    if (result.length < 2) {
      result = `${result}.0`;
    }
    return result;
  }

  private precisionRound(number: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  getMiddleValue(): number {
    return Number(this.min) + Number(Number(this.max) - Number(this.min)) / 2;
  }
}
