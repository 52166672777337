<orgos-column-container centerColumns="true" class="rfsh-top-bar kenjo-border-bottom kenjo-ph-20px">
  <orgos-column *ngIf="enableBackArrow === true" size="0">
    <kenjo-icon [size]="20" (click)="clickBack()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
  </orgos-column>

  <orgos-column size="0" class="rfsh-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

  <orgos-column *ngIf="pageName" size="0" class="kenjo-font-size-16px rfsh-page-name">
    <kenjo-truncate-text>{{ pageName }}</kenjo-truncate-text>
  </orgos-column>

  <orgos-column size="0" class="rfsh-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

  <orgos-column> </orgos-column>

  <orgos-column *ngIf="rightAreaText" size="0" class="rfsh-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

  <orgos-column *ngIf="rightAreaText" size="0">
    <orgos-action *ngIf="showRightIcon" (click)="clickRightArea()" [icon]="rightAreaIcon">{{ rightAreaText }}</orgos-action>
    <orgos-action *ngIf="!showRightIcon" (click)="clickRightArea()">{{ rightAreaText }}</orgos-action>
  </orgos-column>
</orgos-column-container>
