<orgos-dialog-container class="cp-dialog" [title]="pageTranslation.cloneProfileTitle" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-text orgosColumn="1" [label]="pageTranslation.nameOfProfileLabel" [required]="true" [(value)]="newProfileName" (validation)="profileNameValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="profileNameValidation && profileNameValidation.getError('required')"> {{pageTranslation.profileNameRequired}}</orgos-input-error>
  </orgos-input-simple-text>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{pageTranslation.goBackButtonText}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Success" (click)="cloneProfile()" [disabled]="!profileNameValidation || profileNameValidation.hasErrors()">{{pageTranslation.cloneButtonText}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
