<orgos-dialog-container class="td-main-container" [title]="pageTranslation.dialogHeader" (clickCloseButton)="closeDialog()">
  <div class="flex-vertical flex-spread flex-gap-20px">
    <orgos-input-simple-text [(value)]="task.title" [label]="pageTranslation.taskTitle" (validation)="inputValidation = $event" required="true" class="kenjo-full-width" maxlength="600" [placeholder]="pageTranslation.taskTitlePlaceholder">
      <orgos-input-error *ngIf="inputValidation && inputValidation.getError('required')">{{ pageTranslation.taskTitleRequired }}</orgos-input-error>
    </orgos-input-simple-text>
    <div class="flex-horizontal flex-spread flex-gap-20px">
      <orgos-input-simple-search-user class="kenjo-full-width" [label]="pageTranslation.asignee" [placeholder]="pageTranslation.asigneePlaceholder" [users]="allActiveUserPersonal" [(value)]="task.ownerId" [enableClearButton]="true" [largeResultsBox]="true"></orgos-input-simple-search-user>
      <orgos-input-simple-date-picker class="kenjo-full-width" [(value)]="task.dueDate" [label]="pageTranslation.dueDate"></orgos-input-simple-date-picker>
    </div>
    <div class="kenjo-pt-20px kenjo-text-align-right flex-horizontal flex-end flex-gap-20px">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ pageTranslation.goBack }}</orgos-button-raised>
      <orgos-button-raised color="Success" [disabled]="!inputValidation || inputValidation.hasErrors()" (click)="save()">{{ pageTranslation.addTask }}</orgos-button-raised>
    </div>
  </div>
</orgos-dialog-container>
