import { Component, Input } from '@angular/core';

import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-feed-comment',
  templateUrl: 'feed-comment.component.html',
  styleUrls: ['feed-comment.component.scss']
})
export class FeedComment {
  @Input() feedComment: any;
  @Input() displayName: string;
  @Input() photoUrl: string;
}
