import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '@app/standard/core/select-option';

@Component({
  selector: 'kenjo-button-toggle-group',
  templateUrl: 'button-toggle-group.component.html',
  styleUrls: ['button-toggle-group.component.scss']
})
export class ButtonToggleGroupComponent {
  @Input() options: Array<ISelectOption>;
  @Input() value: string;
  @Input() readOnly: boolean = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  public onChangeValue(event) {
    this.valueChange.emit(event.value);
  }
}
