<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogDeleteDummyHeader" (clickCloseButton)="closeDialog()">
  <div class="kenjo-font-color-light-text-757575">{{dialogTranslation.dialogDeleteDummyMessage}}</div>

  <orgos-column-container *ngIf="!deleteInProgress" class="kenjo-mt-60px" centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-button-raised orgosColumn="0" color="Neutral" (click)="closeDialog()"> {{miscTranslation.goBackButtonDialog}}</orgos-button-raised>
    <orgos-button-raised orgosColumn="0" color="Danger" (click)="performDeleteOfDummyData()" class="kenjo-ml-20px"> {{dialogTranslation.dialogDeleteDummyConfirmButton}}</orgos-button-raised>
  </orgos-column-container>

  <orgos-column-container *ngIf="deleteInProgress" class="kenjo-mt-60px" orgosColumn="0" centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" class="kenjo-mr-10px">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <orgos-column size="0" class="kenjo-font-color-light-text-757575">{{dialogTranslation.dialogDeletingAction}}</orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
