import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { AttendanceSummaryService } from '@app/cloud-features/settings-attendance/services/attendance-summary.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { OvertimeBalanceDetailsController } from '@app/standard/services/user/controllers/overtime-balance-details.controller';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import {
  OVERTIME_HISTORY_ENTRY_TYPE_PAY,
  OVERTIME_HISTORY_ENTRY_TYPE_TIME_OFF,
  POLICY_TYPE_DAY,
  WAIVER_TYPE_DAILY,
  WAIVER_TYPE_MONTHLY,
  WAIVER_TYPE_WEEKLY,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { PrivateInternationalizationService } from '../../../../../private/services/private-internationalization.service';

@Component({
  selector: 'orgos-overtime-balance-history',
  templateUrl: 'overtime-balance-history.dialog.html',
  styleUrls: ['overtime-balance-history.dialog.scss'],
})
export class OvertimeBalanceHistoryDialog implements OnInit {
  translation: any = {};

  currentBalance: number = 0;
  tracked: number = 0;
  fullDetail: boolean;
  history: Array<{ detail: string; when: Date; createdBy: string }> = [];
  months: any;
  daysOfCurrentMonth: Array<moment.Moment> = [];
  todayDate: moment.Moment = moment.utc();

  year: number;
  month: number;
  currentMonthDate: moment.Moment;
  userId: string;
  userPersonal: any = {};
  seeDetails: boolean = false;
  seeOvertimeHistory: boolean = false;
  seeEnabledFunctions: boolean = false;

  moment: any = moment();

  balanceHelper: number = 0;

  parsedOvertimeHistory: Array<any> = [];
  overtimeHistory: Array<any> = [];
  overtimeSettings: any = {};
  trackedOvertime: number = 0;
  lastMonthOvertimeToSum: number = 0;
  lastMonthOvertime: number = 0;
  compensatedHours: number = 0;
  loadingTableInfo: boolean = true;
  earliestCalculation = moment()
    .year(moment().year() - 1)
    .startOf('year');
  overtimeEarnedTooltip: string;

  calculateSince: Date;
  maxRecalculationDate: moment.Moment;
  allowUndertime: boolean = false;
  transferNextMonth: boolean = false;
  pendingToProcess: boolean = false;
  showAdjustmentWarning: boolean = false;
  waiver: number = 0;
  waiverType: 'monthly' | 'daily' | 'weekly' = null;
  waiverActive: boolean = false;
  waiverTypeText: string = '';
  hasRecalculated: boolean;
  isCurrentMonthBeforeCalculateSince: boolean = false;

  /**
   * @param dialogRef reference to the dialog
   * @param data object like:
   *  overtime: {userId: string, year: number, month: number}
   * @param injector injector of angular
   */
  constructor(
    public dialogRef: MatLegacyDialogRef<OvertimeBalanceHistoryDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.userId = this.data.userId;
    this.year = this.data.year;
    this.month = this.data.month;
    this.currentMonthDate = moment
      .utc()
      .set({ year: this.year, month: this.month - 1 })
      .startOf('month');
    this.fullDetail = this.data.fullDetail ? this.data.fullDetail : false;

    const currentLanguage = this.injector.get(PrivateInternationalizationService).getLanguage();
    const localizedMoment = this.moment.locale(currentLanguage);

    this.months = localizedMoment._locale._months;

    const options = {
      userId: this.userId,
      year: this.year,
      month: this.month,
    };

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('overtime-history-component')
      .then((translation: any) => {
        this.translation = translation;

        this.overtimeEarnedTooltip = this.translation.trackedHoursExpectedHours;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false)
      .then((userPersonals) => {
        this.userPersonal = userPersonals.reduce((total, iPersonal) => {
          total[iPersonal._id] = iPersonal;
          return total;
        }, {});
        return this.injector.get(OvertimeBalanceDetailsController).getUserMonthDetails(options);
      })
      .then((detail) => {
        this.trackedOvertime = detail.trackedOvertime ?? 0;
        this.lastMonthOvertimeToSum = detail.lastMonthOvertimeToSum ?? 0;
        this.lastMonthOvertime = Math.round(detail.lastMonthOvertime) ?? 0;
        this.currentBalance = Math.round(detail.currentBalance) ?? 0;
        this.tracked = detail.tracked ?? 0;
        this.waiver = detail.overTimeStartHour ?? 0;
        this.waiverType = detail.waiverType ?? null;
        this.waiverActive = detail.waiverActive ?? false;
        this.calculateSince = detail.calculateSince ?? null;
        this.maxRecalculationDate = check.assigned(this.calculateSince)
          ? moment.max(moment(this.calculateSince), this.earliestCalculation)
          : this.earliestCalculation;
        this.allowUndertime = detail.allowUndertime ?? false;
        this.transferNextMonth = detail.transferNextMonth ?? false;
        this.pendingToProcess = detail.pendingToProcess ?? false;
        const timeOffs = _.keyBy(detail.timeOffs, '_id');

        if (check.assigned(this.waiverType)) {
          switch (this.waiverType) {
            case WAIVER_TYPE_DAILY:
              this.waiverTypeText = this.translation.day;
              this.overtimeEarnedTooltip = this.translation.overtimeEarnedWithDailyWaiver;
              break;
            case WAIVER_TYPE_WEEKLY:
              this.waiverTypeText = this.translation.week;
              this.overtimeEarnedTooltip = this.injector
                .get(I18nDataPipe)
                .transform(this.translation.overtimeEarnedWithWeeklyOrMonthlyWaiver, { weeklyOrMonthly: this.waiverTypeText });
              break;
            case WAIVER_TYPE_MONTHLY:
              this.waiverTypeText = this.translation.month;
              this.overtimeEarnedTooltip = this.injector
                .get(I18nDataPipe)
                .transform(this.translation.overtimeEarnedWithWeeklyOrMonthlyWaiver, { weeklyOrMonthly: this.waiverTypeText });
              break;
            default:
              break;
          }
        }

        this.isCurrentMonthBeforeCalculateSince = this.currentMonthDate.isBefore(moment.utc(this.calculateSince), 'month');

        if (this.isCurrentMonthBeforeCalculateSince) {
          this.loadingTableInfo = false;
          return;
        }

        this.history = detail.history.map((iEntry) => {
          let createdAt = moment.utc(iEntry._createdAt);

          if (moment.utc(iEntry._createdAt).isAfter(this.currentMonthDate, 'month')) {
            createdAt = this.currentMonthDate.clone().endOf('month');
          }

          const entry = {
            when: createdAt,
            detail: null,
            createdBy: iEntry._createdById,
            compensatedHours: 0,
            newAdjustedOvertimeBalance: undefined,
            type: undefined,
          };
          const data: any = {
            from: null,
            to: null,
            displayName: this.userPersonal[iEntry._createdById].displayName,
            comment: null,
          };
          let textToTransform;
          if (iEntry.type === OVERTIME_HISTORY_ENTRY_TYPE_PAY) {
            data.from = this.injector.get(DurationPipe).transform(iEntry.minutes);
            data.to = `${
              check.assigned(detail.payments) &&
              check.assigned(detail.payments[iEntry.relatedToId]) &&
              detail.payments[iEntry.relatedToId].amount
                ? detail.payments[iEntry.relatedToId].amount.toFixed(2)
                : 0
            } ${
              check.assigned(detail.payments) &&
              check.assigned(detail.payments[iEntry.relatedToId]) &&
              detail.payments[iEntry.relatedToId].currency
                ? detail.payments[iEntry.relatedToId].currency
                : ''
            }`;
            data.comment = iEntry.comments;
            textToTransform =
              check.assigned(data.comment) && check.not.emptyString(data.comment)
                ? this.translation.payEntryWithComment
                : this.translation.payEntry;
            entry.detail = this.injector.get(I18nDataPipe).transform(textToTransform, data);
            entry.compensatedHours = iEntry.minutes;
            entry.type = 'pay';
          } else if (iEntry.type === OVERTIME_HISTORY_ENTRY_TYPE_TIME_OFF) {
            let timeOffTo = '';
            if (check.assigned(detail.timeOffs?.[iEntry.relatedToId]?.adjustment)) {
              const timeOff = detail.timeOffs[iEntry.relatedToId];
              if (timeOff._policyType === POLICY_TYPE_DAY) {
                timeOffTo = timeOff.adjustment.toFixed(2);
              } else {
                timeOffTo = (timeOff.adjustment / 60).toFixed(2);
              }
            }
            data.from = this.injector.get(DurationPipe).transform(iEntry.minutes);
            data.to = timeOffTo;
            data.comment = iEntry.comments;

            const matchedTimeOff = timeOffs[iEntry.relatedToId];

            if (matchedTimeOff) {
              data.timeOffType = matchedTimeOff._policyType === POLICY_TYPE_DAY ? this.translation.days : this.translation.hours;
            } else {
              data.timeOffType = this.translation.days;
            }

            textToTransform =
              check.assigned(data.comment) && check.not.emptyString(data.comment)
                ? this.translation.timeOffEntryWithComment
                : this.translation.timeOffEntry;
            entry.detail = this.injector.get(I18nDataPipe).transform(textToTransform, data);
            entry.compensatedHours = iEntry.minutes;
            entry.type = 'time-off';
          } else {
            data.from = check.assigned(iEntry.minutesBefore) ? this.injector.get(DurationPipe).transform(iEntry.minutesBefore) : '';
            data.to = this.injector.get(DurationPipe).transform(iEntry.minutes);
            data.comment = iEntry.comments;
            textToTransform =
              check.assigned(data.comment) && check.not.emptyString(data.comment)
                ? this.translation.adjustEntryWithComment
                : this.translation.adjustEntry;
            entry.detail = this.injector.get(I18nDataPipe).transform(textToTransform, data);
            entry.newAdjustedOvertimeBalance = iEntry.minutes;
            entry.type = 'adjustment';
          }
          return entry;
        });

        this.getUserWorkSchedule();
      })
      .catch((err) => {
        this.currentBalance = null;
        this.tracked = null;
        this.waiver = null;
        this.history = [];
      });
  }

  public getUserWorkSchedule(): void {
    const summaryQuery = {
      userId: this.userId,
      month: this.month,
      year: this.year,
    };

    this.injector
      .get(UserWorkScheduleService)
      .getById(this.userId)
      .then((userWorkSchedule) => {
        if (check.assigned(userWorkSchedule) && check.nonEmptyObject(userWorkSchedule.overtimeSettings)) {
          this.overtimeSettings = userWorkSchedule.overtimeSettings;
        }
        return this.injector.get(AttendanceSummaryService).find(summaryQuery);
      })
      .then((withOvertime) => {
        const allEntries = [];
        this.daysOfCurrentMonth = Array.from(
          {
            length: this.currentMonthDate.daysInMonth(),
          },
          (value, iDayOfMonth) => {
            return this.currentMonthDate.clone().date(iDayOfMonth + 1);
          }
        );

        if (
          check.not.assigned(withOvertime) ||
          check.emptyArray(withOvertime) ||
          check.emptyObject(withOvertime[0]) ||
          check.emptyArray(withOvertime[0].attendanceEntries)
        ) {
          this.overtimeHistory = [];
        } else {
          this.showAdjustmentWarning = !!withOvertime[0]?.showAdjustmentWarning;

          withOvertime[0].attendanceEntries.forEach((entry) => {
            if (
              ((check.assigned(this.overtimeSettings) && this.overtimeSettings.allowUndertime && entry.totalOvertime !== 0) || ((check.not.assigned(this.overtimeSettings.allowUndertime) || this.overtimeSettings.allowUndertime === false) && entry.totalOvertime > 0)) &&
              moment.utc().isSameOrAfter(entry.date, 'day') && moment.utc(entry.date).isSame(moment.utc(entry.date).startOf('day'))
            ) {
              allEntries.push(entry);
            }
          });

          this.overtimeHistory = allEntries.reduce((acc, entry, index) => {
            entry.date = moment.utc(entry.date);

            if (index > 0 && moment.utc(allEntries[index - 1].date).isSame(entry.date, 'day')) {
              const oldEntry = acc.pop();
              entry.totalOvertime += oldEntry.totalOvertime;
            }

            acc.push(entry);
            return acc;
          }, []);
        }

        this.balanceHelper = undefined;
        this.compensatedHours = 0;

        this.daysOfCurrentMonth.forEach((date: moment.Moment) => {
          let overtime = 0;
          let hoursToRest = 0;
          let newAdjustedOvertimeBalance;
          let balance;
          let onlyAdjustments = false;

          if (check.assigned(this.calculateSince) && moment.utc(this.calculateSince).isAfter(date)) {
            onlyAdjustments = true;
          }

          const filteredOvertimeHistory = this.overtimeHistory.find((iOvertimeHistory) => iOvertimeHistory.date.isSame(date, 'day'));
          const filteredHistory = this.history.filter((iHistory: any) => {
            if (iHistory.when.isSame(date, 'day')) {
              if (iHistory.type === 'pay' || iHistory.type === 'time-off') {
                hoursToRest += iHistory.compensatedHours;

                this.compensatedHours += iHistory.compensatedHours;
              }

              if (iHistory.type === 'adjustment') {
                hoursToRest = 0;
                newAdjustedOvertimeBalance = iHistory.newAdjustedOvertimeBalance;
              }

              return true;
            }
          });

          if (
            (check.not.assigned(filteredOvertimeHistory) && check.emptyArray(filteredHistory)) ||
            (onlyAdjustments && check.emptyArray(filteredHistory))
          ) {
            return;
          }

          if (filteredOvertimeHistory && filteredOvertimeHistory.totalOvertime && !onlyAdjustments) {
            overtime = filteredOvertimeHistory.totalOvertime;
          }

          if (check.assigned(newAdjustedOvertimeBalance)) {
            this.balanceHelper = newAdjustedOvertimeBalance;
          }

          if (hoursToRest > 0) {
            this.balanceHelper = Number(this.balanceHelper ?? this.lastMonthOvertime ?? 0) - hoursToRest;
          }

          if (check.not.assigned(this.balanceHelper)) {
            balance =
              Number(newAdjustedOvertimeBalance ?? this.lastMonthOvertime ?? 0) +
              Number(this.balanceHelper ?? 0) +
              Number(check.assigned(newAdjustedOvertimeBalance) ? 0 : overtime);
          } else {
            balance = Number(this.balanceHelper ?? 0) + Number(check.assigned(newAdjustedOvertimeBalance) ? 0 : overtime);
          }

          this.balanceHelper = balance;

          this.parsedOvertimeHistory.push({
            date: date.format('L'),
            totalOvertime: overtime === 0 ? undefined : overtime,
            adjustments: filteredHistory,
            balance,
          });
        });
      })
      .catch(() => {
        this.overtimeHistory = [];
      })
      .finally(() => {
        this.loadingTableInfo = false;
      });
  }

  public recalculateUser() {
    this.hasRecalculated = true;
    return this.injector
      .get(AttendanceSummaryService)
      .recalculateUsers([this.userId])
      .then(() => {
        this.injector.get(MatLegacySnackBar).open(this.translation.recalculationDone, 'OK', {
          duration: 5000,
        });
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('recalculate', { category: 'Attendance', platform: 'Web', subcategory1: 'Attendance Tab', subcategory2: 'Monthly' });
        this.closeDialog();
        return;
      })
      .catch(() => {
        // an error is already shown
      });
  }
  public closeDialog(): void {
    this.dialogRef.close({ hasRecalculated: this.hasRecalculated });
  }
}
