<div class="ttc-table">
  <orgos-column-container class="kenjo-pv-10px ttc-table-header">
    <orgos-column size="1">
      <div class="kenjo-font-size-12px kenjo-font-color-text-333333 kenjo-pl-40px ttc-highlight-label">{{ translations.daysColumn | uppercase }}</div>
    </orgos-column>
    <orgos-column *ngIf="workScheduleTemplate.type === 'fixed'" size="1">
      <div class="kenjo-font-size-12px kenjo-font-color-text-333333 kenjo-pl-40px ttc-highlight-label">{{ translations.workSchedulesColumn | uppercase }}</div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-12px kenjo-font-color-text-333333 ttc-highlight-label" [class.kenjo-pl-40px]="workScheduleTemplate.type === 'fixed'" [class.kenjo-pl-20px]="workScheduleTemplate.type === 'flexible'">{{ translations.expectedHoursColumn | uppercase }}</div>
    </orgos-column>
  </orgos-column-container>

  <div *ngFor="let day of workScheduleTemplate.dayShifts; let dayCounter = index">
    <orgos-column-container centerColumns="true" class="ttc-row-horizontal-line">
      <orgos-column size="1">
        <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-40px ttc-highlight-label" [class.ttc-disable-row]="day.minutes === undefined">
          {{ translatedWeekdays[dayCounter] }}
        </div>
      </orgos-column>
      <orgos-column *ngIf="workScheduleTemplate.type === 'fixed'" size="1" class="ttc-row-vertical-line">
        <orgos-column-container *ngFor="let dayTrack of day.shifts; last as isLast; let trackCounter = index" centerColumns="true" class="kenjo-pl-10px kenjo-font-color-text-333333 ttc-day">
          <orgos-column size="0" class="kenjo-mr-10px ttc-add-entry-button" (click)="createShift(dayCounter)">
            <kenjo-icon *ngIf="day.minutes !== undefined && weekdayShiftsValid[dayCounter] && isLast && !readOnly && validDayShifts[dayCounter]" [size]="20" class="kenjo-mr-10px kenjo-font-color-primary-5993e3">add_circle</kenjo-icon>
          </orgos-column>
          <orgos-column size="0" *ngIf="dayTrack">
            <orgos-input-simple-time class="kenjo-mr-10px" [value]="dayTrack.start" [forceError]="!validDayShifts[dayCounter] && isLast" (valueChange)="dayTrack.start = $event; calculateMinutes(); validateDayShifts(dayCounter); checkStartAndEnd(dayTrack.start, dayTrack.end);" [enableClearButton]="true" [label]="translations.startLabel" [readOnly]="day.minutes === undefined || readOnly"> </orgos-input-simple-time>
          </orgos-column>
          <orgos-column size="0">
            <kenjo-icon [size]="18" class="kenjo-mr-10px kenjo-font-color-light-text-757575 ttc-time-entry-arrow-icon">arrow_right_alt</kenjo-icon>
          </orgos-column>
          <orgos-column size="0" *ngIf="dayTrack">
            <orgos-input-simple-time class="kenjo-mr-10px" [value]="dayTrack.end" [forceError]="!validDayShifts[dayCounter] && isLast" (valueChange)="dayTrack.end = $event; calculateMinutes(); validateDayShifts(dayCounter); checkStartAndEnd(dayTrack.start, dayTrack.end);" [enableClearButton]="true" [label]="translations.endLabel" [readOnly]="day.minutes === undefined || readOnly"> </orgos-input-simple-time>
          </orgos-column>
          <orgos-column size="0" class="ttc-delete-entry-button">
            <kenjo-icon *ngIf="day.minutes !== undefined && day.shifts.length > 1 && isLast && !readOnly" [size]="20" class="kenjo-mr-10px kenjo-font-color-danger-ff5757" (click)="deleteShift(dayCounter)">delete</kenjo-icon>
          </orgos-column>
        </orgos-column-container>
        <div class="ttc-error-message kenjo-mb-10px kenjo-ml-30px" *ngIf="!validDayShifts[dayCounter]">
          <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{translations.overlappingErrorMessage}}</div>
        </div>
      </orgos-column>
      <orgos-column *ngIf="workScheduleTemplate.type === 'fixed'" size="1">
        <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-40px">{{ day.minutes | duration }}</div>
      </orgos-column>
      <orgos-column *ngIf="workScheduleTemplate.type === 'flexible'" size="1" class="ttc-row-vertical-line kenjo-pl-40px">
        <orgos-input-simple-time [value]="day.minutes" (valueChange)="day.minutes = $event; calculateMinutes()" [enableClearButton]="true" [label]="translations.timeLabel" [readOnly]="day.minutes === undefined || readOnly"> </orgos-input-simple-time>
      </orgos-column>
    </orgos-column-container>
  </div>

  <orgos-column-container class="kenjo-pv-10px ttc-table-footer">
    <orgos-column size="1"> </orgos-column>
    <orgos-column *ngIf="workScheduleTemplate.type === 'fixed'" size="1">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-40px ttc-highlight-label">{{ this.workScheduleTemplate.weeklyMinutes | duration }}</div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-40px ttc-highlight-label" [class.kenjo-pl-40px]="workScheduleTemplate.type === 'fixed'" [class.kenjo-pl-20px]="workScheduleTemplate.type === 'flexible'">{{ this.workScheduleTemplate.weeklyMinutes | duration }}</div>
    </orgos-column>
  </orgos-column-container>
</div>
