import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ISelectOption } from '@app/standard/core/select-option';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-search-item-dropdown-custom',
  templateUrl: './search-item-dropdown-custom.component.html',
  styleUrls: ['./search-item-dropdown-custom.component.scss'],
})
export class SearchItemDropdownCustomComponent implements OnInit, AfterViewInit, AfterContentChecked {
  filteredOptions: Array<ISelectOption> = [];
  activeOptionValue: string = null;
  activeOption: ISelectOption;
  term: string;
  MAX_LENGTH_OPTIONS: number = 4;

  @ViewChild('inputSearch') inputSearch: ElementRef;
  @ViewChild('optionsContainer') optionsContainer: ElementRef;
  @ViewChild('optionItem') optionItem: ElementRef;

  @Input() placeholder: string;
  @Input() actionText: string;
  @Input() emptyOptionsText: string;
  @Input() width: string = '260px';
  @Input() autofocus: boolean = false;
  @Input() autoOrder: boolean = true;
  @Input() componentKey: ComponentItemType;

  _selectableOptions: Array<ISelectOption>;
  get selectableOptions(): any {
    return this._selectableOptions;
  }
  @Input() set selectableOptions(value) {
    this._selectableOptions = value;
    this.filteredOptions = value;
  }

  @Output() selectOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelectOption: EventEmitter<ISelectOption> = new EventEmitter<ISelectOption>();
  @Output() handleAction: EventEmitter<void> = new EventEmitter<void>();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.selectableOptions = this.selectableOptions.sort((a, b) => {
      if (!a?.name) {
        return -1;
      }

      if (!b.name) {
        return 1;
      }

      if (a._isDefault && !b._isDefault) {
        return -1;
      }

      if (b._isDefault && !a._isDefault) {
        return 1;
      }

      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 1;
    });
    this.setFocus();
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this.inputSearch?.nativeElement) {
      this.setFocus();
    }
  }

  ngAfterContentChecked() {
    this.setFocus();
  }

  private setFocus(): void {
    this.injector.get(NgZone).runOutsideAngular(() => {
      Promise.resolve().then(() => {
        this.inputSearch.nativeElement.focus();
      });
    });
  }

  emitSelection(id: string): void {
    this.selectOption.emit(id);
  }
  emitSelectedOption(option: ISelectOption): void {
    this.onSelectOption.emit(option);
  }

  emitAction(): void {
    this.handleAction.emit();
  }

  searchByName(term: string): void {
    this.activeOptionValue = null;
    this.term = term;

    if (check.not.assigned(term) || check.emptyString(term)) {
      this.filteredOptions = this.selectableOptions;
      return;
    }

    this.filteredOptions = this.selectableOptions.filter((iOption: any) => {
      const regExp = new RegExp(`^.*${term}.*$`, 'i');
      return regExp.test(iOption.name);
    });

    this.activeOptionValue = this.filteredOptions.length > 0 ? this.filteredOptions[0].value : null;
    this.activeOption = this.filteredOptions.length > 0 ? this.filteredOptions[0] : null;
  }

  // To enable keyboard actions
  navigateOptions(direction: 'up' | 'down'): void {
    const container = this.optionsContainer.nativeElement;
    const item = this.optionItem.nativeElement;
    if (!this.filteredOptions?.length) {
      return;
    }

    if (!this.activeOptionValue) {
      this.activeOptionValue = this.filteredOptions[0].value;
      this.activeOption = this.filteredOptions[0];
      return;
    }

    let newIndex: number;
    const currentIndex: number = this.filteredOptions.findIndex((option) => option.value === this.activeOptionValue);
    if (currentIndex < 0) {
      return;
    }

    if (direction === 'up') {
      newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
      container.scrollTop -= item.scrollHeight;
    } else if (direction === 'down') {
      newIndex = currentIndex < this.filteredOptions.length - 1 ? currentIndex + 1 : currentIndex;
      container.scrollTop += item.scrollHeight;
    }

    this.activeOptionValue = this.filteredOptions[newIndex].value;
    this.activeOption = this.filteredOptions[newIndex];
  }

  keyEnter(): void {
    if (this.activeOptionValue) {
      this.emitSelection(this.activeOptionValue);
      this.emitSelectedOption(this.activeOption);
    }
  }

  onMenuClick($event: MouseEvent) {
    $event.stopPropagation();
  }
}

export type ComponentItemType = 'policy-item' | 'category-item' | '';
