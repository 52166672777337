import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'disabled-apps-dialog',
  templateUrl: './disabled-apps.dialog.html',
  styleUrls: ['./disabled-apps.dialog.scss'],
})
export class DisabledAppsDialog implements OnInit {
  translations: any = {};
  numberOfDisabledApps: number;

  constructor(
    private injector: Injector,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: Array<string>,
    private dialogRef: MatLegacyDialogRef<DisabledAppsDialog>
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.loadTranslations();
    this.numberOfDisabledApps = this.data.length;
  }

  private async loadTranslations(): Promise<void> {
    this.translations = await this.injector.get(InternationalizationService).getAllTranslation('platform-usage-other-tab-dialog');
  }

  buttonClicked(): void {
    this.dialogRef.close();
  }

  callToActionClicked(): void {
    this.injector.get(Router).navigateByUrl('cloud/settings/overview');
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
