import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { IPublicHolidays } from '@app/cloud-features/shift-plan/services/shift-plan-public-holidays.service';
@Component({
  selector: 'kenjo-shift-plan-public-holiday',
  templateUrl: 'shift-plan-public-holiday.component.html',
  styleUrls: ['shift-plan-public-holiday.component.scss'],
})
export class ShiftPlanPublicHolidayComponent implements OnInit {
  formattedDate: string;
  loaded: boolean = false;
  hasToMove: boolean = false;
  isLastDay = false;
  views = { ...VIEWS };

  @Input() publicHolidays: Array<IPublicHolidays>;
  @Input() date: Date;
  @Input() dayIndex: number;
  @Input() totalOfDays: number;
  @Input() view: string;
  @Input() translations: { publicHolidays: ''; holidayIn: '' };
  @Input() language: string;

  @ViewChild('publicHolidayCard') publicHolidayCard: ElementRef;

  ngOnInit() {
    this.isLastDay = this.totalOfDays === this.dayIndex + 1;
    this.loaded = true;
  }

  move() {
    if (this.publicHolidayCard.nativeElement.getBoundingClientRect().left + 364 >= window.innerWidth) {
      this.hasToMove = true;
    }
  }
}
