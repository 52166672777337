import { Component, Input, OnInit } from '@angular/core';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

declare const window: any;

@Component({
  selector: 'orgos-input-url',
  templateUrl: 'input-url.component.html',
  styleUrls: ['input-url.component.scss']
})
export class InputUrlComponent extends InputAbstractComponent implements OnInit {
  @Input() enableGotoButton: boolean = true;

  public goToUrl(): void {
    if (super.getValue().startsWith('http://') || super.getValue().startsWith('https://')) {
      window.open(super.getValue(), '_blank');
      return;
    }
    window.open(`http://${super.getValue()}`, '_blank');
  }
}
