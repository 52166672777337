import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { IUserWorkModel } from '../../../models/user-work.model';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserAccountService } from './user-account.service';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';

@Injectable()
export class UserWorkService implements IGenericService {
  private USER_WORK_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-work-db`;
  private USER_WORK_PERMISSIONS_KEY: string = 'user-work';
  private USER_WORK_INTERNATIONALIZATION: string = 'user-work-collection';
  private USER_WORK_SERVICE: string = 'UserWorkService';

  private userWorkCache: Promise<Array<IUserWorkModel>>;

  constructor(private http: HttpClient, private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IUserWorkModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, this.USER_WORK_SERVICE, 'create');
    error.message = 'UserWork should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserWorkModel> {
    return new Promise<IUserWorkModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_WORK_URL, id)
        .then((userWork: IUserWorkModel) => {
          resolve(userWork);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getById'));
        });
    });
  }

  getByIdComplete(id: string): Promise<IUserWorkModel> {
    return new Promise<IUserWorkModel>((resolve, reject) => {
      this.genericService
        .getById(`${this.USER_WORK_URL}/complete`, id)
        .then((userWork: IUserWorkModel) => {
          resolve(userWork);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getByIdComplete'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_WORK_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, this.USER_WORK_SERVICE, 'deleteById');
    error.message = 'UserWork should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_WORK_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_WORK_INTERNATIONALIZATION);
  }

  getAllUserWorkCache(): Promise<Array<IUserWorkModel>> {
    return new Promise<Array<IUserWorkModel>>((resolve, reject) => {
      if (check.not.assigned(this.userWorkCache)) {
        this.userWorkCache = this.getAllUserWork();
      }

      this.userWorkCache
        .then((allUserWork: Array<IUserWorkModel>) => {
          resolve(allUserWork);
        })
        .catch((error) => {
          // An error is already shown
          // The best option is to set to array<any> all the user work if there is any error
          resolve([]);
        });
    });
  }

  clearUserWorkCache(): void {
    this.userWorkCache = null;
  }

  getAllUserWork(activeUsersOnly?: boolean, showArchived: boolean = false): Promise<Array<IUserWorkModel>> {
    if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(true, activeUsersOnly)) {
      // Find the active users only
      return new Promise<Array<IUserWorkModel>>((resolve, reject) => {
        this.injector
          .get(UserAccountService)
          .getAllUserAccount(true)
          .then((allActiveUsers: Array<any>) => {
            const arrayActiveUserIds = allActiveUsers.map((iActiveUser) => {
              return iActiveUser._id;
            });
            const findBody = {
              _id: {
                $in: arrayActiveUserIds
              }
            };
            if (showArchived === true) {
              findBody['isArchived'] = true;
            }
            return this.genericService.find(this.USER_WORK_URL, findBody);
          })
          .then((allUserWork: Array<IUserWorkModel>) => {
            resolve(allUserWork);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getAllUserWork'));
          });
      });
    } else {
      // Find all user work (active and inactive)
      const findBody = {
        _id: { $ne: null }
      };
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }
      return new Promise<Array<IUserWorkModel>>((resolve, reject) => {
        this.genericService
          .find(this.USER_WORK_URL, findBody)
          .then((allUserWork: Array<IUserWorkModel>) => {
            resolve(allUserWork);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getAllUserWork'));
          });
      });
    }
  }

  // getAllUserWorkComplete will return all the fields, taking into consideration the permissions to see personal tab
  getAllUserWorkComplete(activeUsersOnly?: boolean, showArchived: boolean = false): Promise<Array<IUserWorkModel>> {
    if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(true, activeUsersOnly)) {
      // Find the active users only
      return new Promise<Array<IUserWorkModel>>((resolve, reject) => {
        this.injector
          .get(UserAccountService)
          .getAllUserAccount(true)
          .then((allActiveUsers: Array<any>) => {
            const arrayActiveUserIds = allActiveUsers.map((iActiveUser) => {
              return iActiveUser._id;
            });
            const findBody = {
              _id: {
                $in: arrayActiveUserIds
              }
            };
            if (showArchived === true) {
              findBody['isArchived'] = true;
            }
            return this.genericService.find(`${this.USER_WORK_URL}/complete`, findBody);
          })
          .then((allUserWork: Array<IUserWorkModel>) => {
            resolve(allUserWork);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getAllUserWorkComplete'));
          });
      });
    } else {
      // Find all user work (active and inactive)
      const findBody = {
        _id: { $ne: null }
      };
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }
      return new Promise<Array<IUserWorkModel>>((resolve, reject) => {
        this.genericService
          .find(`${this.USER_WORK_URL}/complete`, findBody)
          .then((allUserWork: Array<IUserWorkModel>) => {
            resolve(allUserWork);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getAllUserWorkComplete'));
          });
      });
    }
  }

  checkIfUserIsMyDescendant(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, this.USER_WORK_SERVICE, 'checkIfUserIsMyDescendant');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.USER_WORK_URL}/${id}/my-descendant`, httpOptions)
        .toPromise()
        .then((responseData: any) => {
          resolve(responseData.isMyDescendant);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'checkIfUserIsMyDescendant'));
        });
    });
  }

  getMyDescendants(): Promise<Array<string>> {
    return new Promise<Array<string>>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, this.USER_WORK_SERVICE, 'getMyDescendants');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.USER_WORK_URL}/my-descendants`, httpOptions)
        .toPromise()
        .then((responseData: any) => {
          resolve(responseData.myDescendants);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getMyDescendants'));
        });
    });
  }

  // Find all ids for user filtered by the permission c_viewAnalyseTabs
  async findUserWorkIdsByPermissions() {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, this.USER_WORK_SERVICE, 'getMyDescendants');
      this.errorManager.handleRawError(error);
      return;
    }
    const findBody = {
      _id: {
        $ne: null
      }
    };
    try {
      const responseData = await this.injector.get(HttpClient).post(`${this.USER_WORK_URL}/user-work/readable-performance-tab`, findBody).toPromise();
      return responseData;
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'findUserWorkIdsByPermissions');
    }
  }

  getMyCalendar(userId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, this.USER_WORK_SERVICE, 'getMyCalendar');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.USER_WORK_URL}/${userId}/calendar`, httpOptions)
        .toPromise()
        .then((responseData: any) => {
          resolve(responseData.calendarId);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getMyCalendar'));
        });
    });
  }

  find(findBody: any, showArchived: boolean = false): Promise<Array<IUserWorkModel>> {
    return new Promise<Array<IUserWorkModel>>((resolve, reject) => {
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }
      this.genericService
        .find(this.USER_WORK_URL, findBody)
        .then((allUserPersonal: Array<IUserWorkModel>) => {
          resolve(allUserPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'find'));
        });
    });
  }

  // findComplete will return all the fields, taking into consideration the permissions to see personal tab
  findComplete(findBody: any, showArchived: boolean = false): Promise<Array<IUserWorkModel>> {
    return new Promise<Array<IUserWorkModel>>((resolve, reject) => {
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }
      this.genericService
        .find(`${this.USER_WORK_URL}/complete`, findBody)
        .then((allUserPersonal: Array<IUserWorkModel>) => {
          resolve(allUserPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'findComplete'));
        });
    });
  }

  checkIfUserHasDescendants(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, this.USER_WORK_SERVICE, 'checkIfUserHasDescendants');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.USER_WORK_URL}/${id}/has-descendants`, httpOptions)
        .toPromise()
        .then((responseData: any) => {
          resolve(responseData.hasDescendants);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'checkIfUserHasDescendants'));
        });
    });
  }

  getDescendantsById(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, this.USER_WORK_SERVICE, 'getDescendantsById');
        reject(this.errorManager.handleRawError(error));
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.USER_WORK_URL}/descendants`, data, httpOptions)
        .toPromise()
        .then((descendantsById) => {
          resolve(descendantsById);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'getDescendantsById'));
        });
    });
  }

  updateEmailSignature(query: { _id: string; emailSignature: any }): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };
    return this.http.post(`${this.USER_WORK_URL}/update-signature`, query, httpOptions).toPromise();
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_WORK_URL);
  }

  async getUserWorkShiftPlanActive(): Promise<any> {
    try {
      const body = { _id: { $ne: null }, shiftPlanActive: { $eq: true } };
      return this.genericService.find(this.USER_WORK_URL, body);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, this.USER_WORK_SERVICE, 'getUserWorkShiftPlanActive');
    }
  }

  async checkIfUserIsTimeOffApprover(id: string) {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.USER_WORK_URL, ErrorCodes.UNAUTHORIZED, this.USER_WORK_SERVICE, 'checkIfUserIsTimeOffApprover');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      return await this.http.get<boolean>(`${this.USER_WORK_URL}/${id}/is-time-off-approver`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.USER_WORK_SERVICE, 'checkIfUserIsTimeOffApprover');
    }
  }
}
