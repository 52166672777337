import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '@env';
import { AuthenticationService } from '../core/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class FileMetadataService {
  private FILE_METADATA_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/file-metadata`;

  constructor(private injector: Injector, private authenticationService: AuthenticationService) {}

  async getTemporalUrl(fileNameInS3: string, isWhistleblower: boolean = false): Promise<string> {
    try {

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const { temporalUrl } = await this.injector.get(HttpClient).post<{ temporalUrl: string }>(`${this.FILE_METADATA_URL}/temporal-url`, { fileNameInS3, isWhistleblower }, httpOptions).toPromise();
      return temporalUrl;
    } catch {
      return '';
    }
  }

  // Will generate an URL valid for only a few minutes. Use it to generate one-off downloadable documents
  async getEphemeralUrl(fileNameInS3: string, isWhistleblower: boolean = false): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const { ephemeralUrl } = await this.injector.get(HttpClient).post<{ ephemeralUrl: string }>(`${this.FILE_METADATA_URL}/ephemeral-url`, { fileNameInS3, isWhistleblower }, httpOptions).toPromise();
      return ephemeralUrl;
    } catch {
      return '';
    }
  }
}

export interface IFileMetadata {
  _fileName: string;
  _fileExtension?: string;
  _fileNameInS3?: string;
  _fileMimetype?: string;
  _fileSizeInBytes?: number;
  _convertedToPdf?: boolean;
  _url?: string;
  _convertedPdfUrl?: string;
  _uploadedById?: string;
  _uploadedAt?: string;
}
