import { Component, EventEmitter, Output, OnInit, Injector } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-see-more-button',
  templateUrl: 'see-more-button.component.html',
  styleUrls: ['see-more-button.component.scss']
})
export class KenjoSeeMoreButton implements OnInit {
  @Output() seeMore = new EventEmitter<void>();
  seeMoreTitle: string;

  constructor (private injector: Injector) { }

  ngOnInit(): void {
    this.initTranslation();
  }

  private async initTranslation() {
    const { seeMore } = await this.injector.get(InternationalizationService).getMiscTranslation()
    this.seeMoreTitle = seeMore;
  }
}
