import { Component, Inject, Injector, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as check from 'check-types';

import { InternationalizationService } from '../../../../standard/services/core/internationalization.service';

@Component({
  selector: 'orgos-edit-custom-permissions-dialog',
  templateUrl: 'edit-custom-permissions.dialog.html',
  styleUrls: ['edit-custom-permissions.dialog.scss'],
})
export class EditCustomPermissionsDialog {
  pageTranslation: any = {};
  isValid: boolean = false;
  collectionOfOwner: 'user-work' | 'thisDocument' = 'user-work';

  customPermission: any;
  permissionKey: string;
  permissionLabel: string;
  permissionName: string;

  arrayFields: Array<string> = ['areaIds', 'teamIds'];
  arrayOperators: Array<string> = ['in', 'nin'];

  constructor(
    public dialogRef: MatLegacyDialogRef<EditCustomPermissionsDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.customPermission = this.data.permissions;
    this.permissionLabel = this.data.permissionLabel;
    this.permissionKey = this.data.permissionKey;
    this.permissionName = this.data.permissionName;
    if (check.assigned(this.data.collectionOfOwner) && this.data.collectionOfOwner === 'thisDocument') {
      this.collectionOfOwner = 'thisDocument';
    }

    if (check.not.array(this.customPermission) || check.emptyArray(this.customPermission)) {
      this.customPermission = [];
      this.addRule();
    }
  }

  public addCondition(blockAndConditions: any): void {
    blockAndConditions.push(this.getDefaultCondition());
    this.evaluateIsValid();
  }

  public addRule(): void {
    this.customPermission.push({
      blockAndConditions: [this.getDefaultCondition()],
    });
    this.evaluateIsValid();
  }

  private getDefaultCondition(): any {
    return {
      subordinatesOnly: false,
      directSubordinatesOnly: false,
      ownerInCollection: {
        collection: this.collectionOfOwner,
        field: '',
      },
      operator: 'eq',
      equalsToStaticValue: '',
      equalsToLoggedUserField: {
        collection: 'user-work',
        field: '',
      },
    };
  }

  public deleteRule(ruleIndex: number): void {
    this.customPermission.splice(ruleIndex, 1);
    this.evaluateIsValid();
  }

  public deleteConditionInRule(blockAndConditions: Array<any>, conditionIndex: number): void {
    blockAndConditions.splice(conditionIndex, 1);
    this.evaluateIsValid();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public savePermissions(): void {
    if (this.isValid !== true) {
      return;
    }
    this.dialogRef.close(this.customPermission);
  }

  public evaluateIsValid(): void {
    this.isValid = false;

    if (check.not.array(this.customPermission)) {
      return;
    }

    if (check.emptyArray(this.customPermission)) {
      // Allow empty rules to erase the existing filters
      this.isValid = true;
      return;
    }

    const invalidRules = this.customPermission.filter((iRule) => {
      return this.isThisRuleValid(iRule) === false;
    });
    if (check.nonEmptyArray(invalidRules)) {
      return;
    }

    this.isValid = true;
  }

  private isThisRuleValid(rule: any): boolean {
    if (
      check.not.assigned(rule.blockAndConditions) ||
      check.not.array(rule.blockAndConditions) ||
      check.emptyArray(rule.blockAndConditions)
    ) {
      return false;
    }
    const invalidConditions = rule.blockAndConditions.filter((iCondition) => {
      return this.isThisConditionValid(iCondition) === false;
    });
    return check.emptyArray(invalidConditions);
  }

  private isThisConditionValid(condition: any): boolean {
    if (check.emptyObject(condition)) {
      return false;
    }

    if (condition.subordinatesOnly === true) {
      return true;
    }

    if (condition.directSubordinatesOnly === true) {
      return true;
    }

    if (
      check.not.assigned(condition.ownerInCollection) ||
      check.not.assigned(condition.ownerInCollection.field) ||
      check.emptyString(condition.ownerInCollection.field) ||
      (condition.ownerInCollection.collection !== 'user-work' && condition.ownerInCollection.collection !== 'thisDocument')
    ) {
      return false;
    }

    if (
      check.not.assigned(condition.operator) ||
      (condition.operator !== 'eq' && condition.operator !== 'ne' && condition.operator !== 'in' && condition.operator !== 'nin')
    ) {
      return false;
    }

    if (
      condition.ownerInCollection.collection === 'user-work' &&
      this.arrayFields.includes(condition.ownerInCollection.field) &&
      !this.arrayOperators.includes(condition.operator)
    ) {
      return false;
    }

    // For array comparisons, the value that must be cotained should not be an array
    if (
      check.assigned(condition.equalsToStaticValue) &&
      check.nonEmptyString(condition.equalsToStaticValue) &&
      check.not.array(condition.equalsToStaticValue)
    ) {
      return true;
    }

    if (
      check.not.assigned(condition.equalsToLoggedUserField) ||
      check.not.assigned(condition.equalsToLoggedUserField.field) ||
      check.emptyString(condition.equalsToLoggedUserField.field) ||
      condition.equalsToLoggedUserField.collection !== 'user-work'
    ) {
      return false;
    }

    return true;
  }
}
