import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { LAYOUTS, VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { isAnyFilterApplied } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { checkEmptySchedule, parseRange } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import { setInitialWeekRange } from '@app/cloud-features/shift-plan/helpers/shiftplan-schedule.helper';
import { IShiftPlanDate, ShiftPlanCardService } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { DisplayOrientation, IShiftplanExportPDFOptions } from '@app/cloud-features/shift-plan/services/shift-plan-generate-pdf.service';
import { FormatDatePipe } from '@app/standard/components/format-date/format-date.pipe';
import { IQueryDates } from '@app/standard/components/input-month-picker/input-month-picker.component';
import { ISelectOption } from '@app/standard/core/select-option';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-generate-pdf-dialog',
  templateUrl: 'generate-pdf-dialog.html',
  styleUrls: ['generate-pdf-dialog.scss'],
})
export class GeneratePDFDialog implements OnInit {
  loading: boolean = false;
  dialogTranslation: { [key: string]: string };
  dialogData: any;
  areFiltersApplied: boolean = false;
  rangeLabel: { from: string; to: string };
  shiftOptions: { type: Array<ISelectOption>; status: Array<ISelectOption> };
  defaultShiftOptions: {
    type: 'both' | 'open' | 'scheduled';
    status: 'draft' | 'published' | 'both';
    includeWorkingTime: boolean;
  } = { type: 'both', status: 'both', includeWorkingTime: false };
  orientationOptions: Array<ISelectOption>;
  selectedValues: {
    range: IShiftPlanDate;
    orientation: DisplayOrientation;
    frequency: 'week' | 'month';
    layout: 'user' | 'role';
    color: boolean;
    space: boolean;
  };
  formerRange: IShiftPlanDate;
  frequencyOptions: Array<ISelectOption>;
  layoutOptions: Array<ISelectOption>;
  views = { ...VIEWS };
  layouts = { ...LAYOUTS };

  isReady: boolean = false;
  showEmtpyShiftsError: boolean = false;
  checkingShifts: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<GeneratePDFDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    public snackBar: MatLegacySnackBar
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.loading = true;
    this.initiData();
    this.loading = false;
  }

  initiData() {
    this.initValues();
    this.initDialogData();
    this.initShiftOptions();
    this.initSelectOptions();
  }

  initValues() {
    this.selectedValues = { range: null, orientation: null, frequency: null, layout: null, color: false, space: false };
  }

  initDialogData() {
    this.dialogData = this.dialogInfo;
    this.areFiltersApplied = isAnyFilterApplied(this.dialogData?.filters);
    this.selectedValues.range = this.dialogData.range;
    if (this.dialogData.view === this.views.WEEK) {
      this.formerRange = { ...this.dialogData.range };
    }
    this.dialogTranslation = this.dialogData.dialogTranslation;
  }

  initShiftOptions() {
    const typeOptions = [
      { name: this.dialogTranslation.openShifts, value: 'open' },
      { name: this.dialogTranslation.scheduledShifts, value: 'scheduled' },
      { name: this.dialogTranslation.openAndScheduled, value: 'both' },
    ];
    const statusOptions = [
      { name: this.dialogTranslation.published, value: 'published' },
      { name: this.dialogTranslation.unpublished, value: 'draft' },
      { name: this.dialogTranslation.publishedAndUnpublished, value: 'both' },
    ];
    this.shiftOptions = { type: [...typeOptions], status: [...statusOptions] };
  }

  initSelectOptions() {
    this.layoutOptions = [
      { name: this.dialogTranslation.listByEmployee, value: this.layouts.USER },
      { name: this.dialogTranslation.listByRole, value: this.layouts.ROLE },
    ];
    this.orientationOptions = [
      { name: this.dialogTranslation.portrait, value: 'portrait' },
      { name: this.dialogTranslation.landscape, value: 'landscape' },
    ];
    this.frequencyOptions = [
      { name: this.dialogTranslation.monthly, value: this.views.MONTH },
      { name: this.dialogTranslation.weekly, value: this.views.WEEK },
    ];
    const initialFrequency = this.dialogData.view ?? this.views.WEEK;
    this.selectedValues.layout = this.dialogData.layout;
    this.selectedValues.color = false;
    this.onFrequencyChange(initialFrequency);
  }

  setRange(newRange: IShiftPlanDate | IQueryDates) {
    if (newRange.hasOwnProperty('startDayOfMonth') && newRange.hasOwnProperty('endDayOfMonth')) {
      const { startDayOfMonth, endDayOfMonth } = newRange as IQueryDates;
      this.selectedValues.range = { from: startDayOfMonth, to: endDayOfMonth };
    } else {
      const weekleRange = newRange as IShiftPlanDate;
      this.selectedValues.range = { from: weekleRange.from, to: weekleRange.to.endOf('day') };
    }
    this.checkPrintPDFoptions();
  }

  checkPrintPDFoptions() {
    this.showEmtpyShiftsError = false;
    const { range, orientation, frequency, layout } = this.selectedValues;
    this.isReady = check.assigned(range) && check.assigned(orientation) && check.assigned(frequency) && check.assigned(layout);
  }

  onFrequencyChange(newFrequency: 'week' | 'month') {
    this.selectedValues.frequency = newFrequency;
    const { range } = this.selectedValues;

    if (this.selectedValues.frequency === this.views.MONTH) {
      this.selectedValues.orientation = 'landscape';
      this.formerRange = check.assigned(this.formerRange) ? { ...range } : setInitialWeekRange(range.from);
    }

    const parsedRange = parseRange(range.from, this.selectedValues.frequency, this.formerRange);
    this.setRange(parsedRange);
  }

  async closeDialog(generatePDF: boolean = false) {
    if (generatePDF !== true) {
      this.dialogRef.close(false);
      return;
    }

    try {
      this.checkingShifts = true;
      const { range, orientation, frequency, layout, color, space } = this.selectedValues;
      const shifts = await this.injector
        .get(ShiftPlanCardService)
        .getShiftCards(range.from, range.to, this.dialogData?.filters, layout, false);

      if (checkEmptySchedule(shifts)) {
        this.showEmtpyShiftsError = true;
        this.checkingShifts = false;
        return;
      }

      const rangeLabel = {
        from: this.injector.get(FormatDatePipe).transform(range.from, 'D MMM'),
        to: this.injector.get(FormatDatePipe).transform(range.to, 'D MMM, YYYY'),
      };

      const options: IShiftplanExportPDFOptions = {
        rangeLabel,
        selectedRange: { ...range },
        frequency,
        listView: layout,
        shiftType: this.defaultShiftOptions.type,
        shiftStatus: this.defaultShiftOptions.status,
        orientation,
        includeWorkingTime: this.defaultShiftOptions.includeWorkingTime,
        color,
        space,
      };
      this.checkingShifts = false;
      this.dialogRef.close({ generatePDF, options });
    } catch {
      this.checkingShifts = false;
    }
  }
}
