import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DatevApiController } from '@app/standard/services/datev/datev-api.controller';

@Component({
  selector: 'kenjo-export-and-close-datev-payroll-dialog',
  templateUrl: 'export-and-close-datev-payroll.dialog.html',
  styleUrls: ['export-and-close-datev-payroll.dialog.scss'],
})
export class ExportAndCloseDatevPayrollDialog implements OnInit {
  translations: any = {};
  isSendingStep = true;
  success = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<ExportAndCloseDatevPayrollDialog>,
    private injector: Injector,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: any
  ) {}

  ngOnInit() {
    this.initData();
    this.sendData();
  }

  private async initData() {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('export-and-close-datev-payroll-dialog');
    } catch {
      this.translations = {};
    }
  }

  private async sendData() {
    try {
      await this.injector
        .get(DatevApiController)
        .sendFile(this.data.selectedGroupId, this.data.fromDate, this.data.toDate, this.data.periodIdentifier);
      this.isSendingStep = false;
      this.success = true;
    } catch {
      this.dialogRef.close(this.success);
    }
  }

  close() {
    this.dialogRef.close(this.success);
  }
}
