<orgos-container aspect="card" *ngIf="pageTranslation" class="akpp-main-container">
  <orgos-column-container centerColumns="true" class="kenjo-border-bottom kenjo-pv-10px kenjo-ph-30px">
    <orgos-column class="kenjo-font-size-18px">{{pageTranslation.apiKeySectionTitle}}</orgos-column>
  </orgos-column-container>
  <div class="kenjo-ph-30px  kenjo-pv-10px">
    <div *ngIf="apiKeys && apiKeys.length === 0" class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-color-text-333333">{{pageTranslation.noDataMessage}}
      </div>
      <div class="kenjo-mt-20px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{pageTranslation.infoMessage}}
        <a href="https://kenjo.readme.io/reference/generate-the-api-key" target="_blank">{{
          pageTranslation.learnMore | titlecase }}</a>
      </div>
      <orgos-action class="kenjo-mt-20px" [uppercase]="true" icon="add_circle"
        (click)="generateApiKey()">{{pageTranslation.generateApiKeyButton }}</orgos-action>
    </div>

    <div *ngIf="apiKeys && apiKeys.length > 0" class="kenjo-mt-20px">
      <orgos-column-container centerColumns="true">
        <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-success-00b72e">error_outline</kenjo-icon>
        <orgos-column
          class="kenjo-ml-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{pageTranslation.infoMessage}}
          <a href="https://kenjo.readme.io/reference/generate-the-api-key" target="_blank">{{
            pageTranslation.learnMore | titlecase }}</a>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container centerColumns="true">
        <orgos-column size="0"
          [matTooltip]="apiKeys.length >= MAX_API_KEYS_NUMBER ? pageTranslation.maxNumberOfApiKeysTooltip : ''">
          <orgos-action [disabled]="apiKeys.length >= MAX_API_KEYS_NUMBER" class="kenjo-mt-40px kenjo-ml-20px"
            [uppercase]="true" icon="add_circle" (click)="generateApiKey()">{{pageTranslation.generateApiKeyButton
            }}</orgos-action>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>

      <orgos-container aspect="card" class="kenjo-mt-15px">
        <orgos-table [data]="apiKeys" [displayedColumns]="displayedColumns">
          <orgos-table-column columnKey="alias" [header]="pageTranslation.aliasColumn" [sortable]="true">
            <ng-container *orgosTableCell="let row">
              {{row.alias}}
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="maskedKey" [header]="pageTranslation.apiKeyColumn">
            <ng-container *orgosTableCell="let row">
              {{row._maskedApiKey}}
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="_refreshedAt" [header]="pageTranslation.createdColumn" [sortable]="true">
            <ng-container *orgosTableCell="let row">
              <orgos-column-container centerColumns="true">
                <kenjo-icon orgosColumn="0" *ngIf="row.daysAgo < DAYS_AGO_LIMIT_1" [size]="18"
                  class="akpp-days-ago-limit-1">check_circle_outline</kenjo-icon>
                <kenjo-icon orgosColumn="0" *ngIf="row.daysAgo >= DAYS_AGO_LIMIT_1 && row.daysAgo < DAYS_AGO_LIMIT_2"
                  [size]="18" class="akpp-days-ago-limit-2">error_outline</kenjo-icon>
                <kenjo-icon orgosColumn="0" *ngIf="row.daysAgo > DAYS_AGO_LIMIT_2" [size]="18"
                  class="akpp-days-ago-limit-3">warning_outline</kenjo-icon>

                <orgos-column class="kenjo-ml-10px"
                  [ngClass]="{ 'akpp-days-ago-limit-1': row.daysAgo < DAYS_AGO_LIMIT_1, 'akpp-days-ago-limit-2': row.daysAgo >= DAYS_AGO_LIMIT_1 && row.daysAgo < DAYS_AGO_LIMIT_2, 'akpp-days-ago-limit-3': row.daysAgo > DAYS_AGO_LIMIT_2  }">
                  {{row._refreshedAt | timeAgo}}
                </orgos-column>

              </orgos-column-container>
            </ng-container>
          </orgos-table-column>
          <orgos-table-column cellWidth="2" columnKey="actions" [actionColumn]="true">
            <ng-container *orgosTableCell="let row">
              <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu"
                class="kenjo-cursor-pointer">more_vert</kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="editKey(row._id)">{{pageTranslation.editKey}}</button>
                <button mat-menu-item (click)="revokeApiKey(row._id, row.alias)">{{pageTranslation.deleteKey}}</button>
              </mat-menu>
            </ng-container>
          </orgos-table-column>
        </orgos-table>
      </orgos-container>
    </div>
  </div>
</orgos-container>