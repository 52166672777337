import { AfterViewChecked, ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { EmailTemplateService } from '@app/standard/services/email-template/email-template.service';
import { AsanaService } from '@app/standard/services/integrations/asana.service';
import { SlackService } from '@app/standard/services/integrations/slack.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';

import { SearchComponent, SearchFunction } from '../../../components/search/search.component';
import { GenericCacheModel } from '../../../core/generic-cache-model';
import { GenericSimpleModel } from '../../../core/generic-simple-model';
import { ISelectOption } from '../../../core/select-option';
import { InputValidation } from '../../../core/validation/input-validation';
import { AreaService } from '../../../services/company/area.service';
import { CompanyService } from '../../../services/company/company.service';
import { DepartmentService } from '../../../services/company/department.service';
import { OfficeService } from '../../../services/company/office.service';
import { TeamService } from '../../../services/company/team.service';
import { AuthenticationService } from '../../../services/core/authentication.service';

@Component({
  selector: 'orgos-dialog-add-workflow-action',
  templateUrl: 'add-workflow-action.dialog.html',
  styleUrls: ['add-workflow-action.dialog.scss'],
})
export class AddWorkflowActionDialog implements OnInit, AfterViewChecked {
  pageTranslation: any;

  disableSaveButton: boolean = true;
  isSaving: boolean = false;

  actionTypeOptions: Array<ISelectOption> = [];
  emailTemplateOptions: Array<ISelectOption> = [];
  targetFieldOptions: Array<ISelectOption> = [];
  toCompanyOptions: Array<ISelectOption> = [];
  toOfficeOptions: Array<ISelectOption> = [];
  toDepartmentOptions: Array<ISelectOption> = [];
  toTeamOptions: Array<ISelectOption> = [];
  toAreaOptions: Array<ISelectOption> = [];
  toTargetFieldValue: string;
  toSpecificEmailValue: string;
  toCompaniesValue: Array<any> = [];
  toOfficesValue: Array<any> = [];
  toDepartmentsValue: Array<any> = [];
  toTeamsValue: Array<any> = [];
  toAreasValue: Array<any> = [];
  emailTemplateValidation: InputValidation;
  senderNameValidation: InputValidation;
  senderDomainValidation: InputValidation;
  nameValidation: InputValidation;
  dueDateValidation: InputValidation;

  targetFieldValidation: InputValidation;
  toSpecificEmailValidation: InputValidation;

  toPositionCandidate: boolean = false;
  toEmployeeEmail: boolean = false;
  toManagerEmail: boolean = false;
  toTimeOffApproverEmail: boolean = false;
  toSpecificUser: boolean = false;
  toSpecificEmail: boolean = false;
  toCompanies: boolean = false;
  toOffices: boolean = false;
  toDepartments: boolean = false;
  toTeams: boolean = false;
  toAreas: boolean = false;
  toMentionedUsers: boolean = false;
  toTargetField: boolean = false;
  toGroupsEnabled: boolean = false;
  isTimeOffApproverEnabled: boolean = false;
  specificPeopleToSendEmail: Array<GenericSimpleModel> = [];
  allUserPersonal: Array<GenericSimpleModel>;
  searchResults: Array<any> = [];
  customFieldsAvailable: Array<any>;

  senderDomainOptions: Array<ISelectOption> = [];
  domain: string;
  KENJO_DOMAIN: string = '@kenjo.io';
  WF_ADDRESS: string = 'notifications';
  freeDomainList: Array<string> = ['@gmail', '@hotmail', '@yahoo', '@10Minutemail'];

  groupOptionsPerTargetObject = {
    position: [
      {
        key: 'positionHiringManagers',
        selected: false,
        translationKeyLabel: 'hiringManagersLabel',
        findAudienceByHttp: {
          endpoint: '/hiring-team-db/find',
          findQueryStaticAttributes: {
            role: 'hiringManager',
          },
          findQueryDynamicAttributes: [
            {
              fieldName: '_positionId',
              operator: 'eq',
              equalToField: '_id',
            },
          ],
          httpAction: 'POST',
          audienceFieldInResult: '_userId',
        },
      },
      {
        key: 'positionHiringMembers',
        selected: false,
        translationKeyLabel: 'hiringMembersLabel',
        findAudienceByHttp: {
          endpoint: '/hiring-team-db/find',
          findQueryStaticAttributes: {
            role: 'hiringMember',
          },
          findQueryDynamicAttributes: [
            {
              fieldName: '_positionId',
              operator: 'eq',
              equalToField: '_id',
            },
          ],
          httpAction: 'POST',
          audienceFieldInResult: '_userId',
        },
      },
    ],
    'position-candidate': [
      {
        key: 'positionCandidateHiringManagers',
        selected: false,
        translationKeyLabel: 'hiringManagersLabel',
        findAudienceByHttp: {
          endpoint: '/hiring-team-db/find',
          findQueryStaticAttributes: {
            role: 'hiringManager',
          },
          findQueryDynamicAttributes: [
            {
              fieldName: '_positionId',
              operator: 'eq',
              equalToField: '_positionId',
            },
          ],
          httpAction: 'POST',
          audienceFieldInResult: '_userId',
        },
      },
      {
        key: 'positionCandidateHiringMembers',
        selected: false,
        translationKeyLabel: 'hiringMembersLabel',
        findAudienceByHttp: {
          endpoint: '/hiring-team-db/find',
          findQueryStaticAttributes: {
            role: 'hiringMember',
          },
          findQueryDynamicAttributes: [
            {
              fieldName: '_positionId',
              operator: 'eq',
              equalToField: '_positionId',
            },
          ],
          httpAction: 'POST',
          audienceFieldInResult: '_userId',
        },
      },
    ],
  };

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  slackChannelValidation: InputValidation;

  slackChannels: Array<ISelectOption> = [];

  asanaWorkspaceValidation: InputValidation;
  asanaTeamValidation: InputValidation;
  asanaProjectNameValidation: InputValidation;

  asanaWorkspaces: Array<ISelectOption> = [];
  asanaTeams: Array<ISelectOption> = [];

  constructor(
    public dialogRef: MatLegacyDialogRef<AddWorkflowActionDialog>,
    private injector: Injector,
    public cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public parentInfo: any
  ) {}

  ngOnInit(): void {
    this.initEmailTemplates();
    this.initCheckboxes();
    this.initTargetFieldOptions();
    this.initSpecificAddress();
    this.initCompanyOptions();
    this.initOfficeOptions();
    this.initDepartmentOptions();
    this.initMentionedOptions();
    this.initTeamOptions();
    this.initAreaOptions();

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('add-workflow-action-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.initSenderInfo();
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, false)
      .then((allUserPersonal: Array<any>) => {
        this.allUserPersonal = allUserPersonal.map((userPersonal) => {
          return new GenericSimpleModel(this.injector, userPersonal, UserPersonalService, userPersonal.ownerId);
        });
        this.initSpecificEmailDestinators();
      })
      .catch(() => {
        // An error is already shown
        this.allUserPersonal = [];
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((standardPicklists) => {
        return this.getWorkflowActionType(standardPicklists.workflowActionType);
      })
      .then((actionTypesOptions) => {
        const targetObject = this.parentInfo['inCollection'];
        if (targetObject === 'task') {
          // Do not allow to create to dos if the workflow is in to do collection. Do this to prevent loops in the code.
          this.actionTypeOptions = actionTypesOptions.filter((iOption) => {
            return iOption.value !== 'new_task';
          });
        } else {
          this.actionTypeOptions = actionTypesOptions;
        }
      })
      .catch(() => {
        this.actionTypeOptions = [];
      });

    this.initAsanaOptions();
    this.initSlackOptions();
    this.selectGroups();
  }

  ngAfterViewChecked(): void {
    this.disableSaveButton = this.isDataInvalid();
    this.cdr.detectChanges();
  }

  private initEmailTemplates(): void {
    let targetObject = this.parentInfo['inCollection'];
    if (targetObject.startsWith('user-') === true) {
      targetObject = 'user';
    }
    this.emailTemplateOptions = [];

    this.injector
      .get(EmailTemplateService)
      .getTemplatesForCollection(targetObject)
      .then((emailTemplates) => {
        this.emailTemplateOptions = emailTemplates.map((iTemplate) => {
          return {
            name: iTemplate['name'],
            value: iTemplate[fieldConstants.ID],
          };
        });
      });
  }

  private selectGroups(): void {
    const targetObject = this.parentInfo['inCollection'];
    if (
      check.not.assigned(targetObject) ||
      check.not.assigned(this.groupOptionsPerTargetObject[targetObject]) ||
      check.emptyArray(this.groupOptionsPerTargetObject[targetObject])
    ) {
      return;
    }
    if (
      check.not.assigned(this.parentInfo['workflowAction']) ||
      check.not.assigned(this.parentInfo['workflowAction'].data) ||
      check.not.assigned(this.parentInfo['workflowAction'].data['data']) ||
      check.not.assigned(this.parentInfo['workflowAction'].data['data'].findAudienceByHttp) ||
      check.not.array(this.parentInfo['workflowAction'].data['data'].findAudienceByHttp) ||
      check.emptyArray(this.parentInfo['workflowAction'].data['data'].findAudienceByHttp)
    ) {
      return;
    }

    // Per each element of findAudienceByHttp, find the groupOptionsPerTargetObject
    this.parentInfo['workflowAction'].data['data'].findAudienceByHttp.forEach((iElement) => {
      const optionToSelect = this.groupOptionsPerTargetObject[targetObject].find((iOption) => {
        if (
          iOption.findAudienceByHttp.endpoint !== iElement.endpoint ||
          iOption.findAudienceByHttp.httpAction !== iElement.httpAction ||
          iOption.findAudienceByHttp.audienceFieldInResult !== iElement.audienceFieldInResult
        ) {
          return false;
        }
        if (
          check.array(iOption.findAudienceByHttp.findQueryStaticAttributes) !== check.array(iElement.findQueryStaticAttributes) ||
          iOption.findAudienceByHttp.findQueryDynamicAttributes.length !== iElement.findQueryDynamicAttributes.length
        ) {
          return false;
        }
        if (check.nonEmptyArray(iOption.findAudienceByHttp.findQueryDynamicAttributes)) {
          const aSorted = iOption.findAudienceByHttp.findQueryDynamicAttributes
            .sort((optionA: any, optionB: any) => {
              if (optionA.fieldName < optionB.fieldName) {
                return -1;
              } else if (optionB.fieldName < optionA.fieldName) {
                return 1;
              } else {
                return 0;
              }
            })
            .toString();
          const bSorted = iOption.findAudienceByHttp.findQueryDynamicAttributes
            .sort((optionA: any, optionB: any) => {
              if (optionA.fieldName < optionB.fieldName) {
                return -1;
              } else if (optionB.fieldName < optionA.fieldName) {
                return 1;
              } else {
                return 0;
              }
            })
            .toString();
          if (aSorted !== bSorted) {
            return false;
          }
        }
        if (
          check.nonEmptyObject(iOption.findAudienceByHttp.findQueryStaticAttributes) !==
          check.nonEmptyObject(iElement.findQueryStaticAttributes)
        ) {
          return false;
        }
        if (check.nonEmptyObject(iOption.findAudienceByHttp.findQueryStaticAttributes)) {
          let isValid = true;
          Object.keys(iOption.findAudienceByHttp.findQueryStaticAttributes).forEach((iKey) => {
            if (iOption.findAudienceByHttp.findQueryStaticAttributes[iKey] !== iElement.findQueryStaticAttributes[iKey]) {
              isValid = false;
            }
          });
          if (!isValid) {
            return false;
          }
        }
        return true;
      });
      if (check.assigned(optionToSelect) && check.nonEmptyObject(optionToSelect)) {
        optionToSelect.selected = true;
      }
    });
    this.toGroupsEnabled = true;
  }

  private initCheckboxes(): void {
    if (
      check.assigned(this.parentInfo['workflowAction']) &&
      check.assigned(this.parentInfo['workflowAction'].data['data']) &&
      check.assigned(this.parentInfo['workflowAction'].data['data']['toManagerOfUserIdField']) &&
      check.string(this.parentInfo['workflowAction'].data['data']['toManagerOfUserIdField']) &&
      check.nonEmptyString(this.parentInfo['workflowAction'].data['data']['toManagerOfUserIdField'])
    ) {
      this.toManagerEmail = true;
    }

    if (check.nonEmptyString(this.parentInfo?.['workflowAction']?.data?.['data']?.['toTimeOffApproverOfUserIdField'])) {
      this.toTimeOffApproverEmail = true;
    }

    if (
      check.assigned(this.parentInfo['workflowAction']) &&
      check.assigned(this.parentInfo['workflowAction'].data['data']) &&
      check.assigned(this.parentInfo['workflowAction'].data['data']['toField']) &&
      check.array(this.parentInfo['workflowAction'].data['data']['toField']) &&
      check.nonEmptyArray(this.parentInfo['workflowAction'].data['data']['toField'])
    ) {
      const targetObject = this.parentInfo['inCollection'];
      const targetUserField = this.getTargetUserFieldForCollection(targetObject);
      if (check.contains(this.parentInfo['workflowAction'].data['data']['toField'], targetUserField)) {
        this.toEmployeeEmail = true;
      }
      if (
        check.not.contains(this.parentInfo['workflowAction'].data['data']['toField'], targetUserField) ||
        this.parentInfo['workflowAction'].data['data']['toField'].length > 1
      ) {
        this.toTargetFieldValue = this.parentInfo['workflowAction'].data['data']['toField'].find((iField) => {
          return iField !== targetUserField;
        });
        this.toTargetField =
          check.assigned(this.toTargetFieldValue) && check.string(this.toTargetFieldValue) && check.nonEmptyString(this.toTargetFieldValue);
      }
    }
  }

  private initSpecificEmailDestinators(): void {
    if (
      check.not.assigned(this.parentInfo['workflowAction'].data['data']['to']) ||
      check.not.array(this.parentInfo['workflowAction'].data['data']['to']) ||
      check.emptyArray(this.parentInfo['workflowAction'].data['data']['to'])
    ) {
      this.toSpecificUser = false;
      return;
    }
    this.specificPeopleToSendEmail = this.allUserPersonal.filter((iUserPersonal: GenericSimpleModel) => {
      return check.contains(this.parentInfo['workflowAction'].data['data']['to'], iUserPersonal.data[fieldConstants.ID]);
    });
    this.toSpecificUser = check.nonEmptyArray(this.specificPeopleToSendEmail);
  }

  private initTargetFieldOptions(): void {
    if (check.assigned(this.parentInfo.customFieldsAvailable)) {
      this.customFieldsAvailable = this.parentInfo.customFieldsAvailable;
    }

    if (check.assigned(this.parentInfo) && check.assigned(this.parentInfo['collectionFields'])) {
      this.targetFieldOptions = this.parentInfo['collectionFields']
        .filter((iField) => {
          return iField['type'] === 'Email';
        })
        .map((iField) => {
          let fieldLabel = this.parentInfo['collectionTranslation'][iField.name]
            ? this.parentInfo['collectionTranslation'][iField.name]
            : iField.name;
          if (fieldLabel.startsWith('c_') || (check.contains(iField['name'], '.') && iField['name'].split('.')[1].startsWith('c_'))) {
            fieldLabel = this.findCustomFieldTranslation(fieldLabel);
          }
          return { name: fieldLabel, value: iField.name };
        });
    }

    if (
      check.assigned(this.parentInfo) &&
      check.assigned(this.parentInfo.workflowAction) &&
      check.assigned(this.parentInfo.workflowAction.data) &&
      check.assigned(this.parentInfo.workflowAction.data.data) &&
      this.parentInfo.workflowAction.data.data.toPositionCandidate === true
    ) {
      this.toPositionCandidate = true;
    }

    // Check time-off-approver setting to display approver as the target
    this.injector
      .get(PrivateOrganizationService)
      .getTimeOffConfiguration()
      .then((timeOffConfiguration) => {
        this.isTimeOffApproverEnabled = timeOffConfiguration?.enableTimeOffApprover;
      })
      .catch(() => {
        this.isTimeOffApproverEnabled = false;
      });
  }

  private initSpecificAddress(): void {
    if (
      check.assigned(this.parentInfo['workflowAction']) &&
      check.assigned(this.parentInfo['workflowAction'].data['data']['toSpecificEmail']) &&
      this.parentInfo['workflowAction'].data['data']['toSpecificEmail']
    ) {
      this.toSpecificEmail = true;
      this.toSpecificEmailValue = this.parentInfo['workflowAction'].data['data']['toSpecificEmail'];
    }
  }

  private initCompanyOptions(): void {
    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((allCompanies: Array<any>) => {
        this.toCompanyOptions = allCompanies.map((iCompany) => {
          const result = {
            name: iCompany.name,
            value: iCompany._id,
          };
          return result;
        });

        if (
          check.assigned(this.parentInfo['workflowAction']) &&
          check.assigned(this.parentInfo['workflowAction'].data['data']['toCompanyIds']) &&
          this.parentInfo['workflowAction'].data['data']['toCompanyIds']
        ) {
          this.toCompanies = true;
          this.toCompaniesValue = this.parentInfo['workflowAction'].data['data']['toCompanyIds'];
        }
      })
      .catch(() => {
        this.toOfficeOptions = [];
      });
  }

  private initOfficeOptions(): void {
    this.injector
      .get(OfficeService)
      .getOffices()
      .then((allOffices: Array<any>) => {
        this.toOfficeOptions = allOffices.map((iOffice) => {
          const result = {
            name: iOffice.name,
            value: iOffice._id,
          };
          return result;
        });

        if (
          check.assigned(this.parentInfo['workflowAction']) &&
          check.assigned(this.parentInfo['workflowAction'].data['data']['toOfficeIds']) &&
          this.parentInfo['workflowAction'].data['data']['toOfficeIds']
        ) {
          this.toOffices = true;
          this.toOfficesValue = this.parentInfo['workflowAction'].data['data']['toOfficeIds'];
        }
      })
      .catch(() => {
        this.toOfficeOptions = [];
      });
  }

  private initDepartmentOptions(): void {
    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((allDepartments: Array<any>) => {
        this.toDepartmentOptions = allDepartments.map((iDepartment) => {
          const result = {
            name: iDepartment.name,
            value: iDepartment._id,
          };
          return result;
        });

        if (
          check.assigned(this.parentInfo['workflowAction']) &&
          check.assigned(this.parentInfo['workflowAction'].data['data']['toDepartmentIds']) &&
          this.parentInfo['workflowAction'].data['data']['toDepartmentIds']
        ) {
          this.toDepartments = true;
          this.toDepartmentsValue = this.parentInfo['workflowAction'].data['data']['toDepartmentIds'];
        }
      })
      .catch(() => {
        this.toDepartmentOptions = [];
      });
  }

  private initMentionedOptions() {
    this.toMentionedUsers = !!this.parentInfo['workflowAction']?.data?.data?.toMentionedUsers;
  }

  private initTeamOptions(): void {
    this.injector
      .get(TeamService)
      .getTeams()
      .then((allTeams: Array<any>) => {
        this.toTeamOptions = allTeams.map((iTeam) => {
          const result = {
            name: iTeam.name,
            value: iTeam._id,
          };
          return result;
        });

        if (
          check.assigned(this.parentInfo['workflowAction']) &&
          check.assigned(this.parentInfo['workflowAction'].data['data']['toTeamIds']) &&
          this.parentInfo['workflowAction'].data['data']['toTeamIds']
        ) {
          this.toTeams = true;
          this.toTeamsValue = this.parentInfo['workflowAction'].data['data']['toTeamIds'];
        }
      })
      .catch(() => {
        this.toTeamOptions = [];
      });
  }

  private initAreaOptions(): void {
    this.injector
      .get(AreaService)
      .getAreas()
      .then((allAreas: Array<any>) => {
        this.toAreaOptions = allAreas.map((iArea) => {
          const result = {
            name: iArea.name,
            value: iArea._id,
          };
          return result;
        });

        if (
          check.assigned(this.parentInfo['workflowAction']) &&
          check.assigned(this.parentInfo['workflowAction'].data['data']['toAreaIds']) &&
          this.parentInfo['workflowAction'].data['data']['toAreaIds']
        ) {
          this.toAreas = true;
          this.toAreasValue = this.parentInfo['workflowAction'].data['data']['toAreaIds'];
        }
      })
      .catch(() => {
        this.toAreaOptions = [];
      });
  }

  private initSenderInfo(): void {
    if (check.not.assigned(this.parentInfo['workflowAction'].data['data'].senderName)) {
      this.parentInfo['workflowAction'].data['data'].senderName = this.WF_ADDRESS;
    }
    this.domain = `@${this.injector.get(AuthenticationService).getLoggedUser().email.split('@')[1]}`;
    const domainName = this.domain.split('.')[0];
    if (!this.freeDomainList.includes(domainName)) {
      this.senderDomainOptions.push({ name: this.domain, value: this.domain });
    }
    if (this.domain !== this.KENJO_DOMAIN) {
      this.senderDomainOptions.push({ name: this.KENJO_DOMAIN, value: this.KENJO_DOMAIN });
    }

    // Checks if former domain exists
    if (
      check.assigned(this.parentInfo['workflowAction'].data['data'].senderEmail) &&
      check.assigned(this.parentInfo['workflowAction'].data['data'].senderEmail.split('@')[1])
    ) {
      const formerDomain = `@${this.parentInfo['workflowAction'].data['data']['senderEmail'].split('@')[1]}`;
      if (formerDomain !== this.domain && formerDomain !== this.KENJO_DOMAIN) {
        this.senderDomainOptions.push({ name: formerDomain, value: formerDomain });
      }
      this.domain = formerDomain;
    }
  }

  public changeDomain(value: string): void {
    this.domain = value;
    if (this.domain === this.KENJO_DOMAIN) {
      this.parentInfo['workflowAction'].data.data.senderName = this.WF_ADDRESS;
    }
  }

  private findCustomFieldTranslation(fieldApiName): string {
    if (check.not.assigned(fieldApiName) || check.not.string(fieldApiName) || check.emptyString(fieldApiName)) {
      return fieldApiName;
    }

    if (
      check.not.assigned(this.customFieldsAvailable) ||
      check.not.array(this.customFieldsAvailable) ||
      check.emptyArray(this.customFieldsAvailable)
    ) {
      return fieldApiName;
    }

    if (check.not.contains(fieldApiName, '.')) {
      const candidate = this.customFieldsAvailable.find((iCustomField) => {
        return iCustomField.fieldApiName === fieldApiName;
      });
      if (check.assigned(candidate) && check.assigned(candidate.fieldLabel)) {
        return candidate.fieldLabel;
      }
    } else if (check.contains(fieldApiName, '.')) {
      const field = fieldApiName.split('.')[1];

      const candidate = this.customFieldsAvailable.find((iCustomField) => {
        return iCustomField.fieldApiName === field;
      });
      if (check.assigned(candidate) && check.assigned(candidate.fieldLabel)) {
        return candidate.fieldLabel;
      }
    }

    return fieldApiName;
  }

  private initAsanaOptions(): void {
    if (this.parentInfo.workflowAction.data.type === 'asana_task') {
      this.injector
        .get(AsanaService)
        .getWorkspaces()
        .then((workspaces) => {
          this.asanaWorkspaces = workspaces.map((iWorkspace) => {
            return { value: iWorkspace.id, name: iWorkspace.name };
          });
        })
        .catch(() => {
          // An error is already shown
          this.asanaWorkspaces = [];
        });

      this.injector
        .get(AsanaService)
        .getTeams(this.parentInfo['workflowAction'].data.data.asanaWorkspaceId)
        .then((teams) => {
          this.asanaTeams = teams.map((iTeam) => {
            return { value: iTeam.id, name: iTeam.name };
          });
        })
        .catch(() => {
          // An error is already shown
          this.asanaTeams = [];
        });
    }
  }

  private initSlackOptions(): void {
    if (this.parentInfo.workflowAction.data.type === 'slack_post') {
      this.injector
        .get(SlackService)
        .getChannels()
        .then((channels) => {
          this.slackChannels = channels.map((iChannel) => {
            return { value: `#${iChannel.name}`, name: iChannel.name };
          });
        })
        .catch(() => {
          // An error is already shown
          this.slackChannels = [];
        });
    }
  }

  private getWorkflowActionType(workflowActionType: any): Promise<Array<ISelectOption>> {
    return new Promise<Array<ISelectOption>>((resolve, reject) => {
      const actionTypeOptions: Array<ISelectOption> = Object.keys(workflowActionType).map((iKey) => {
        return { value: iKey, name: workflowActionType[iKey] };
      });

      const slackIntegration = this.injector.get(SlackService).getBasicIntegrationInfo();
      const asanaIntegration = this.injector.get(AsanaService).getBasicIntegrationInfo();

      Promise.all([slackIntegration, asanaIntegration])
        .then((allIntegrationsInfo) => {
          const inactiveIntegrations = allIntegrationsInfo.filter((iIntegrationInfo) => {
            if (iIntegrationInfo.integration === 'slack') {
              return (
                iIntegrationInfo.isConnected === false ||
                (iIntegrationInfo.isConnected === true &&
                  (check.not.assigned(iIntegrationInfo.config) ||
                    check.not.assigned(iIntegrationInfo.config.workflowsIntegrationEnabled) ||
                    iIntegrationInfo.config.workflowsIntegrationEnabled === false))
              );
            }

            return iIntegrationInfo.isConnected === false;
          });

          if (check.emptyArray(inactiveIntegrations)) {
            resolve(actionTypeOptions);
            return;
          }

          let options = actionTypeOptions;
          inactiveIntegrations.forEach((iIntegration) => {
            options = options.filter((iActionType: ISelectOption) => {
              return check.not.contains(iActionType.value.toLowerCase(), iIntegration.integration.toLowerCase());
            });
          });

          resolve(options);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  onActionTypeChange(): void {
    if (this.parentInfo.workflowAction.data.type === 'asana_task') {
      this.injector
        .get(AsanaService)
        .getWorkspaces()
        .then((workspaces) => {
          this.asanaWorkspaces = workspaces.map((iWorkspace) => {
            return { value: iWorkspace.id, name: iWorkspace.name };
          });
        })
        .catch(() => {
          // An error is already shown
          this.asanaWorkspaces = [];
        });
    }

    if (this.parentInfo.workflowAction.data.type === 'slack_post') {
      this.injector
        .get(SlackService)
        .getChannels()
        .then((channels) => {
          this.slackChannels = channels.map((iChannel) => {
            return { value: `#${iChannel.name}`, name: iChannel.name };
          });
        })
        .catch(() => {
          // An error is already shown
          this.slackChannels = [];
        });
    }
  }

  onAsanaWorkspaceChange(): void {
    if (check.not.assigned(this.parentInfo['workflowAction'].data.data.asanaWorkspaceId)) {
      return;
    }

    this.injector
      .get(AsanaService)
      .getTeams(this.parentInfo['workflowAction'].data.data.asanaWorkspaceId)
      .then((teams) => {
        this.asanaTeams = teams.map((iTeam) => {
          return { value: iTeam.id, name: iTeam.name };
        });
      })
      .catch(() => {
        // An error is already shown
        this.asanaTeams = [];
      });
  }

  isDataInvalid(): boolean {
    let groupOptionSelected = false;
    if (
      check.assigned(this.parentInfo['inCollection']) &&
      check.assigned(this.groupOptionsPerTargetObject[this.parentInfo['inCollection']]) &&
      check.nonEmptyArray(
        this.groupOptionsPerTargetObject[this.parentInfo['inCollection']].filter((iGroup) => {
          return iGroup.selected === true;
        })
      )
    ) {
      groupOptionSelected = true;
    }

    if (
      this.parentInfo.workflowAction.data.type === 'email_alert' &&
      this.toEmployeeEmail === false &&
      this.toManagerEmail === false &&
      this.toTimeOffApproverEmail === false &&
      this.toTargetField === false &&
      this.specificPeopleToSendEmail.length === 0 &&
      groupOptionSelected === false &&
      this.toPositionCandidate === false &&
      this.toSpecificEmail === false &&
      this.toCompanies === false &&
      this.toOffices === false &&
      this.toDepartments === false &&
      this.toTeams === false &&
      this.toAreas === false &&
      this.toMentionedUsers === false
    ) {
      return true;
    } else if (
      this.parentInfo.workflowAction.data.type === 'email_alert' &&
      this.toSpecificUser === true &&
      this.specificPeopleToSendEmail.length === 0
    ) {
      return true;
    } else if (
      this.parentInfo.workflowAction.data.type === 'email_alert' &&
      this.toTargetField === true &&
      (check.not.assigned(this.toTargetFieldValue) ||
        check.not.string(this.toTargetFieldValue) ||
        check.emptyString(this.toTargetFieldValue))
    ) {
      return true;
    } else if (
      this.parentInfo.workflowAction.data.type === 'email_alert' &&
      this.toSpecificEmail === true &&
      check.assigned(this.toSpecificEmailValidation) &&
      this.toSpecificEmailValidation.hasErrors()
    ) {
      return true;
    } else if (
      this.parentInfo.workflowAction.data.type === 'email_alert' &&
      this.toCompanies === true &&
      this.toCompaniesValue.length === 0
    ) {
      return true;
    } else if (this.parentInfo.workflowAction.data.type === 'email_alert' && this.toOffices === true && this.toOfficesValue.length === 0) {
      return true;
    } else if (
      this.parentInfo.workflowAction.data.type === 'email_alert' &&
      this.toDepartments === true &&
      this.toDepartmentsValue.length === 0
    ) {
      return true;
    } else if (this.parentInfo.workflowAction.data.type === 'email_alert' && this.toTeams === true && this.toTeamsValue.length === 0) {
      return true;
    } else if (this.parentInfo.workflowAction.data.type === 'email_alert' && this.toAreas === true && this.toAreasValue.length === 0) {
      return true;
    } else if (
      this.parentInfo.workflowAction.data.type === 'email_alert' &&
      (check.not.assigned(this.domain) || this.freeDomainList.includes(this.domain.split('.')[0]))
    ) {
      return true;
    } else if (this.parentInfo.workflowAction.data.type === 'email_alert') {
      return (
        check.not.assigned(this.emailTemplateValidation) ||
        this.emailTemplateValidation.hasErrors() ||
        check.not.assigned(this.nameValidation) ||
        this.nameValidation.hasErrors() ||
        this.senderNameValidation?.hasErrors()
      );
    } else if (this.parentInfo.workflowAction.data.type === 'slack_post') {
      return (
        check.not.assigned(this.emailTemplateValidation) ||
        this.emailTemplateValidation.hasErrors() ||
        check.not.assigned(this.nameValidation) ||
        this.nameValidation.hasErrors() ||
        check.not.assigned(this.slackChannelValidation) ||
        this.slackChannelValidation.hasErrors()
      );
    } else if (this.parentInfo.workflowAction.data.type === 'asana_task') {
      return (
        check.not.assigned(this.nameValidation) ||
        this.nameValidation.hasErrors() ||
        check.not.assigned(this.asanaWorkspaceValidation) ||
        this.asanaWorkspaceValidation.hasErrors() ||
        check.not.assigned(this.asanaTeamValidation) ||
        this.asanaTeamValidation.hasErrors() ||
        check.not.assigned(this.asanaProjectNameValidation) ||
        this.asanaProjectNameValidation.hasErrors()
      );
    } else if (
      this.parentInfo.workflowAction.data.type === 'new_task' &&
      this.toEmployeeEmail === false &&
      this.toManagerEmail === false &&
      this.toMentionedUsers === false &&
      this.specificPeopleToSendEmail.length === 0
    ) {
      return true;
    } else if (this.parentInfo.workflowAction.data.type === 'new_task') {
      return (
        check.not.assigned(this.nameValidation) ||
        this.nameValidation.hasErrors() ||
        check.not.assigned(this.dueDateValidation) ||
        this.dueDateValidation.hasErrors()
      );
    } else {
      return true;
    }
  }

  resetSearch(): void {
    this.specificPeopleToSendEmail = [];
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveAction(): void {
    if (this.isDataInvalid() === true || this.isSaving === true) {
      return;
    }
    this.isSaving = true;
    const newAction: GenericCacheModel = this.parentInfo['workflowAction'];
    if (newAction.data.type === 'email_alert') {
      newAction.data.data.senderEmail = `${this.domain === this.KENJO_DOMAIN ? this.WF_ADDRESS : newAction.data.data.senderName}${
        this.domain
      }`;
    }

    this.setEmailDestinators(newAction);
    if (
      check.not.assigned(newAction.data[fieldConstants.ID]) ||
      check.not.string(newAction.data[fieldConstants.ID]) ||
      check.emptyString(newAction.data[fieldConstants.ID])
    ) {
      newAction
        .createInServer()
        .then(() => {
          this.dialogRef.close(true);
        })
        .catch(() => {
          // An error is already shown.
        });
    } else {
      newAction
        .updateInServer()
        .then(() => {
          this.dialogRef.close(true);
        })
        .catch(() => {
          this.isSaving = false;
        });
    }
  }

  private setEmailDestinators(newAction: GenericCacheModel): void {
    if (this.toSpecificUser) {
      newAction.data['data']['to'] = this.specificPeopleToSendEmail.map((iUserWork: GenericSimpleModel) => {
        return iUserWork.data[fieldConstants.ID];
      });
    } else {
      newAction.data['data']['to'] = [];
    }
    newAction.data['data']['cc'] = [];
    newAction.data['data']['bcc'] = [];
    newAction.data['data']['toField'] = [];
    newAction.data['data']['ccField'] = [];
    newAction.data['data']['bccField'] = [];
    newAction.data['data']['toManagerOfUserIdField'] = null;
    newAction.data['data']['toTimeOffApproverOfUserIdField'] = null;
    newAction.data['data']['findAudienceByHttp'] = [];
    newAction.data['data']['toSpecificEmail'] = null;
    newAction.data['data']['toCompanyIds'] = null;
    newAction.data['data']['toOfficeIds'] = null;
    newAction.data['data']['toDepartmentIds'] = null;
    newAction.data['data']['toTeamIds'] = null;
    newAction.data['data']['toAreaIds'] = null;
    newAction.data['data']['toMentionedUsers'] = null;

    const selectedGroupOptions = this.getSelectedGroupOptions();
    if (check.nonEmptyArray(selectedGroupOptions)) {
      newAction.data['data']['findAudienceByHttp'] = selectedGroupOptions.map((iOption) => {
        return iOption.findAudienceByHttp;
      });
    }
    const targetObject = this.parentInfo['inCollection'];
    const targetUserField = this.getTargetUserFieldForCollection(targetObject);

    if (this.toEmployeeEmail === true) {
      newAction.data['data']['toField'].push(targetUserField);
    }

    if (
      this.toTargetField === true &&
      check.assigned(this.toTargetFieldValue) &&
      check.string(this.toTargetFieldValue) &&
      check.nonEmptyString(this.toTargetFieldValue)
    ) {
      newAction.data['data']['toField'].push(this.toTargetFieldValue);
    }

    if (this.toManagerEmail === true) {
      newAction.data['data']['toManagerOfUserIdField'] = targetObject === 'task' ? 'relatedTo' : targetUserField;
    }

    if (this.parentInfo.workflowAction.data.type !== 'new_task') {
      if (this.toTimeOffApproverEmail === true) {
        newAction.data['data']['toTimeOffApproverOfUserIdField'] = targetUserField;
      }

      if (this.toSpecificEmail === true && check.nonEmptyString(this.toSpecificEmailValue)) {
        newAction.data['data']['toSpecificEmail'] = this.toSpecificEmailValue;
      }

      if (this.toCompanies === true && this.toCompaniesValue.length > 0) {
        newAction.data['data']['toCompanyIds'] = this.toCompaniesValue;
      }

      if (this.toOffices === true && this.toOfficesValue.length > 0) {
        newAction.data['data']['toOfficeIds'] = this.toOfficesValue;
      }

      if (this.toDepartments === true && this.toDepartmentsValue.length > 0) {
        newAction.data['data']['toDepartmentIds'] = this.toDepartmentsValue;
      }

      if (this.toTeams === true && this.toTeamsValue.length > 0) {
        newAction.data['data']['toTeamIds'] = this.toTeamsValue;
      }

      if (this.toAreas === true && this.toAreasValue.length > 0) {
        newAction.data['data']['toAreaIds'] = this.toAreasValue;
      }
    }

    if (this.toMentionedUsers === true) {
      newAction.data['data']['toMentionedUsers'] = true;
    }

    if (this.toPositionCandidate === true) {
      newAction.data['data'].toPositionCandidate = true;
    } else {
      newAction.data['data'].toPositionCandidate = false;
    }

    if (this.parentInfo.workflowAction.data.type === 'new_task') {
      if (check.nonEmptyArray(newAction.data['data']['toField'])) {
        newAction.data['data']['toField'] = [targetUserField];
      }
      const relateTaskToField = this.getTargetUserFieldForCollection(targetObject);
      if (check.assigned(relateTaskToField) && check.nonEmptyString(relateTaskToField)) {
        newAction.data['data']['relateTaskToField'] = relateTaskToField;
      }
    } else if (this.parentInfo.workflowAction.data.type === 'email_alert' || this.parentInfo.workflowAction.data.type === 'slack_post') {
      // Related User id
      const relatedUserField = this.getTargetUserFieldForCollection(targetObject);
      if (check.assigned(relatedUserField) && check.nonEmptyString(relatedUserField)) {
        newAction.data['data']['relatedUserField'] = relatedUserField;
      }
      // Related Project id
      const relatedProjectField = this.getRelatedProjectFieldForCollection(targetObject);
      if (check.assigned(relatedProjectField) && check.nonEmptyString(relatedProjectField)) {
        newAction.data['data']['relatedProjectField'] = relatedProjectField;
      }
    }
  }

  private getTargetUserFieldForCollection(targetObject: string): string {
    if (targetObject === 'document') {
      return 'relatedTo.idRelatedTo';
    } else if (targetObject === 'user') {
      return 'userAccount._id';
    } else if (targetObject === 'time-off-request') {
      return '_userId';
    } else if (targetObject === 'task') {
      return 'ownerId';
    } else if (targetObject === 'project') {
      return '_createdById';
    } else if (targetObject === 'project-member') {
      return '_userId';
    } else if (targetObject === 'project-time-entry') {
      return '_userId';
    } else if (targetObject === 'feed') {
      return 'ownerId';
    }
    return fieldConstants.ID;
  }

  private getRelatedProjectFieldForCollection(targetObject: string): string {
    if (targetObject === 'project-member' || targetObject === 'project-time-entry') {
      return '_projectId';
    }
    return fieldConstants.ID;
  }

  searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      return Promise.resolve(this.specificPeopleToSendEmail);
    }

    const results = this.allUserPersonal.filter((userPersonal: GenericSimpleModel) => {
      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return regExp.test(userPersonal.data.displayName);
    });

    return Promise.resolve(results);
  };

  addUserToSpecificPeopleSendEmail(userId: string): void {
    const userIdInList: GenericSimpleModel = this.specificPeopleToSendEmail.find((iUserPersonal: GenericSimpleModel) => {
      return iUserPersonal.data[fieldConstants.ID] === userId;
    });

    if (check.assigned(userIdInList) && check.object(userIdInList) && check.nonEmptyObject(userIdInList)) {
      return;
    }

    const foundUserPersonal: GenericSimpleModel = this.allUserPersonal.find((iUserPersonal: GenericSimpleModel) => {
      return iUserPersonal.data[fieldConstants.ID] === userId;
    });
    this.specificPeopleToSendEmail.push(foundUserPersonal);
    this.searchComponent.clearSearch();
  }

  getFunctionDeleteUserFromSpecificPeopleSendEmail(userId: string): Function {
    return () => {
      let indexToRemove = -1;
      this.specificPeopleToSendEmail.forEach((iUserPersonal: GenericSimpleModel, index: number) => {
        if (iUserPersonal.data[fieldConstants.ID] === userId) {
          indexToRemove = index;
        }
      });

      if (indexToRemove >= 0) {
        this.specificPeopleToSendEmail.splice(indexToRemove, 1);
      }
    };
  }

  changeInToGroupsEnabled(newVal): void {
    this.toGroupsEnabled = newVal;
    if (this.toGroupsEnabled === false) {
      this.unselectAllGroupOptions();
    }
  }

  unselectAllGroupOptions(): void {
    if (
      check.not.assigned(this.parentInfo['inCollection']) ||
      check.not.assigned(this.groupOptionsPerTargetObject[this.parentInfo['inCollection']]) ||
      check.emptyArray(this.groupOptionsPerTargetObject[this.parentInfo['inCollection']])
    ) {
      return;
    }

    this.groupOptionsPerTargetObject[this.parentInfo['inCollection']].forEach((iGroup) => {
      iGroup.selected = false;
    });
  }

  private getSelectedGroupOptions(): Array<any> {
    if (
      check.not.assigned(this.parentInfo['inCollection']) ||
      check.not.assigned(this.groupOptionsPerTargetObject[this.parentInfo['inCollection']]) ||
      check.emptyArray(this.groupOptionsPerTargetObject[this.parentInfo['inCollection']])
    ) {
      return [];
    }

    return this.groupOptionsPerTargetObject[this.parentInfo['inCollection']].filter((iGroup) => {
      return iGroup.selected === true;
    });
  }

  // If there is a change in specific user
  changeToSpecificUser(newVal): void {
    this.toSpecificUser = newVal;
    if (newVal === false) {
      this.specificPeopleToSendEmail = [];
      this.searchResults = [];
    }
  }
}
