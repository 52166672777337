import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as moment from 'moment';

@Pipe({
  name: 'isWeekend',
  pure: false
})
export class IsWeekendPipe implements PipeTransform {
  private latestRawValue: moment.Moment;
  private latestComputedValue: boolean = false;
  private DAY_INDEX = { SUNDAY: 0, SATURDAY: 6 };

  transform(value: moment.Moment): boolean {
    if (check.not.assigned(value)) {
      return false;
    }
    if (check.assigned(this.latestRawValue) && this.latestRawValue.isSame(value, 'day')) {
      return this.latestComputedValue;
    }

    this.latestRawValue = value;
    this.latestComputedValue = [this.DAY_INDEX.SATURDAY, this.DAY_INDEX.SUNDAY].includes(value.day());

    return this.latestComputedValue;
  }
}
