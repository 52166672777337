import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

import { GenericCacheModel } from '../../../../../core/generic-cache-model';
import { ISelectOption } from '../../../../../core/select-option';
import { InputValidation } from '../../../../../core/validation/input-validation';
import { AuthenticationService } from '../../../../../services/core/authentication.service';
import { InternationalizationService } from '../../../../../services/core/internationalization.service';
import { IMeetingModel, MeetingService } from '../../../../../services/performance-management/meeting.service';

@Component({
  selector: 'add-agenda-item',
  templateUrl: 'add-agenda-item.dialog.html',
  styleUrls: ['add-agenda-item.dialog.scss'],
})
export class AddAgendaItemDialog implements OnInit {
  meeting: IMeetingModel;
  createdOn: string;
  userPersonalMap: any;
  userWorkMap: any;
  loggedUser: any;
  meetingCache: GenericCacheModel;
  numberOfParticipants: string = '';
  searchResults: Array<any> = [];
  allUsers: Array<any>;
  userIdToUserPersonal: any;
  translation: any = {};
  picklistTranslation: any = {};

  wizardStep: number = 1;
  WIZARD_FINAL_STEP: number = 2;
  STEP_TYPE_SELECTION: number = 1;
  STEP_DETAIL: number = 2;
  agendaItem: any = {
    name: '',
    participantCanAdd: false,
    type: '',
    content: {},
  };
  visibilityOptions: Array<ISelectOption> = [];
  questionIsPublic: boolean = true;

  anonymityOptions: Array<ISelectOption> = [];
  questionIsAnonymous: boolean = false;

  titleValidation: InputValidation;
  isUpdate: boolean = false;

  TYPE_TEXT: string = picklists.AGENDA_TYPE_TEXT;
  TYPE_LIST: string = picklists.AGENDA_TYPE_LIST_VAL;
  TYPE_TODO: string = picklists.AGENDA_TYPE_TODO;
  TYPE_QUESTION: string = picklists.AGENDA_TYPE_QUESTION;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddAgendaItemDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private snackBar: MatLegacySnackBar,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

    if (check.not.assigned(this.data) || check.not.assigned(this.data.meeting)) {
      return;
    }

    const data = _.cloneDeep(this.data);

    this.meetingCache = new GenericCacheModel(this.injector, data.meeting, MeetingService, loggedUser._id);

    if (
      check.assigned(data.itemIndex) &&
      check.assigned(this.meetingCache.data.agenda) &&
      check.assigned(this.meetingCache.data.agenda[data.itemIndex])
    ) {
      this.agendaItem = this.meetingCache.data.agenda[data.itemIndex];
      this.isUpdate = true;
      if (this.agendaItem.type === this.TYPE_QUESTION) {
        if (check.assigned(this.agendaItem.content) && check.assigned(this.agendaItem.content.public)) {
          this.questionIsPublic = this.agendaItem.content.public;
        }
        if (check.assigned(this.agendaItem.content) && check.assigned(this.agendaItem.content.anonymous)) {
          this.questionIsAnonymous = this.agendaItem.content.anonymous;
        }
      }
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('meetings-misc')
      .then((dialogTranslation) => {
        this.translation = dialogTranslation;
        this.visibilityOptions = [
          { name: this.translation.responsePrivate, value: false },
          { name: this.translation.responsePublic, value: true },
        ];
        this.anonymityOptions = [
          { name: this.translation.responseAnonymous, value: true },
          { name: this.translation.responseKnown, value: false },
        ];
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((picklistTranslations) => {
        this.picklistTranslation = picklistTranslations;
      })
      .catch(() => {
        this.picklistTranslation = {};
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public leftButtonAction(): void {
    if (this.wizardStep === 1) {
      this.dialogRef.close();
    }
    if (this.wizardStep >= 2) {
      this.wizardStep--;
    }
  }

  public rightButtonAction(): void {
    if (!this.isValidFormData()) {
      return;
    }
    if (this.wizardStep === this.WIZARD_FINAL_STEP) {
      if (this.agendaItem.type === this.TYPE_QUESTION) {
        this.agendaItem.content = {};
        this.agendaItem.content['public'] = this.questionIsPublic;
        this.agendaItem.content['anonymous'] = this.questionIsAnonymous;
      }
      if (this.isUpdate === false) {
        this.meetingCache.data.agenda.push(this.agendaItem);
      }
      this.meetingCache
        .updateInServer()
        .then(() => {
          this.snackBar.open(this.translation.itemCreated, 'OK', {
            duration: 5000,
          });
          this.logAmplitudeEvent();
          this.dialogRef.close(true);
          return;
        })
        .catch(() => {
          //
        });
    } else {
      this.wizardStep++;
    }
  }

  logAmplitudeEvent() {
    let itemType: string;
    if (this.agendaItem.type === this.TYPE_QUESTION) {
      itemType = 'open question';
    } else if (this.agendaItem.type === this.TYPE_LIST) {
      itemType = 'list';
    } else if (this.agendaItem.type === this.TYPE_TEXT) {
      itemType = 'text';
    } else {
      itemType = 'to-do';
    }
    this.injector.get(PrivateAmplitudeService).logEvent('add meeting agenda item', { category: 'Meetings', type: itemType });
  }

  public isValidFormData(): boolean {
    if (this.wizardStep === 1) {
      return check.assigned(this.agendaItem.type) && check.nonEmptyString(this.agendaItem.type);
    } else {
      return !(check.not.assigned(this.titleValidation) || this.titleValidation.hasErrors());
    }
  }

  public setParticipantCanAdd(value: boolean): void {
    this.agendaItem.participantCanAdd = value;
  }
}
