export const SHIFTPLAN_PERMISSIONS_PACKAGE = {
  packageKey: 'shiftplan-app',
  map: [
    {
      name: 'h_featureManagement',
      key: 'features',
      permissions: [
        { name: 'c_viewSettingsTab', key: 'c_viewSettingsTab', type: 'individual' },
        { name: 'c_viewScheduleTab', key: 'c_viewScheduleTab', type: 'individual' },
        {
          name: 'h_editEmployeeProfileShiftplan',
          type: 'set',
          isHeader: true,
          permissions: [
            { name: 'c_editEmployeeProfileShiftplan_own', key: 'c_editEmployeeProfileShiftplan_own' },
            { name: 'c_editEmployeeProfileShiftplan_custom', key: 'c_editEmployeeProfileShiftplan_custom' },
            { name: 'c_editEmployeeProfileShiftplan_all', key: 'c_editEmployeeProfileShiftplan_all' }
          ]
        },
        {
          name: 'h_roles',
          key: 'shift-plan-role',
          type: 'collection',
          isHeader: true,
          permissions: [
            { name: 'c_createRole_all', key: 'create_all', type: 'collection-permission' },
            {
              name: 'h_editRole',
              isHeader: true,
              permissions: [
                { name: 'c_editRole_own', key: 'edit_own', type: 'collection-permission' },
                { name: 'c_editRole_all', key: 'edit_all', type: 'collection-permission' }
              ]
            },
            {
              name: 'h_deleteRole',
              isHeader: true,
              permissions: [
                { name: 'c_deleteRole_own', key: 'delete_own', type: 'collection-permission' },
                { name: 'c_deleteRole_all', key: 'delete_all', type: 'collection-permission' }
              ]
            }
          ]
        },
        {
          name: 'h_workingAreas',
          key: 'shift-plan-working-area',
          type: 'collection',
          isHeader: true,
          permissions: [
            { name: 'c_createWorkingArea_all', key: 'create_all', type: 'collection-permission' },
            {
              name: 'h_editWorkingArea',
              isHeader: true,
              permissions: [
                { name: 'c_editWorkingArea_own', key: 'edit_own', type: 'collection-permission' },
                { name: 'c_editWorkingArea_all', key: 'edit_all', type: 'collection-permission' }
              ]
            },
            {
              name: 'h_deleteWorkingArea',
              isHeader: true,
              permissions: [
                { name: 'c_deleteWorkingArea_own', key: 'delete_own', type: 'collection-permission' },
                { name: 'c_deleteWorkingArea_all', key: 'delete_all', type: 'collection-permission' }
              ]
            }
          ]
        },
        {
          name: 'h_tags',
          key: 'shift-plan-tag',
          type: 'collection',
          isHeader: true,
          permissions: [
            { name: 'c_createTag_all', key: 'create_all', type: 'collection-permission' },
            {
              name: 'h_editTag',
              isHeader: true,
              permissions: [
                { name: 'c_editTag_own', key: 'edit_own', type: 'collection-permission' },
                { name: 'c_editTag_all', key: 'edit_all', type: 'collection-permission' }
              ]
            },
            {
              name: 'h_deleteTag',
              isHeader: true,
              permissions: [
                { name: 'c_deleteTag_own', key: 'delete_own', type: 'collection-permission' },
                { name: 'c_deleteTag_all', key: 'delete_all', type: 'collection-permission' }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'h_schedulingManagement',
      key: 'scheduling',
      permissions: [
        {
          name: 'h_manageAccessShiftplan',
          type: 'group',
          isHeader: true,
          permissionControl: [
            { name: 'c_manageAccessShiftplan_own', key: 'c_manageScheduleShifts_own' },
            { name: 'c_manageAccessShiftplan_custom', key: 'c_manageScheduleShifts_custom' },
            { name: 'c_manageAccessShiftplan_all', key: 'c_manageScheduleShifts_all' }
          ],
          permissionSections: [
            {
              name: 'h_scheduledShiftsAccess',
              isHeader: true,
              type: 'section',
              permissions: [
                { name: 'c_shiftsViewPublished', key: 'c_scheduledShiftsViewPublished' },
                { name: 'c_shiftsViewUnpublished', key: 'c_scheduledShiftsViewUnpublished' },
                { name: 'c_shiftsCreate', key: 'c_scheduledShiftsCreate' },
                { name: 'c_shiftsEdit', key: 'c_scheduledShiftsEdit' },
                { name: 'c_shiftsDelete', key: 'c_scheduledShiftsDelete' }
              ]
            }
          ]
        },
        {
          name: 'h_manageAccessShiftplan',
          type: 'group',
          isHeader: true,
          permissionControl: [
            { name: 'c_manageAccessShiftplan_custom', key: 'c_manageOpenShifts_custom' },
            { name: 'c_manageAccessShiftplan_all', key: 'c_manageOpenShifts_all' }
          ],
          permissionSections: [
            {
              name: 'h_openShiftsAccess',
              isHeader: true,
              type: 'section',
              permissions: [
                { name: 'c_shiftsViewPublished', key: 'c_openShiftsViewPublished' },
                { name: 'c_shiftsViewUnpublished', key: 'c_openShiftsViewUnpublished' },
                { name: 'c_shiftsCreate', key: 'c_openShiftsCreate' },
                { name: 'c_shiftsEdit', key: 'c_openShiftsEdit' },
                { name: 'c_shiftsDelete', key: 'c_openShiftsDelete' }
              ]
            }
          ]
        },
        { name: 'c_capacityManagement', key: 'c_capacityManagement', type: 'individual' },
        {
          name: 'h_actionsAccess',
          type: 'set_permissions',
          isHeader: true,
          permissions: [
            { name: 'c_copyShifts', key: 'c_copyShifts' },
            { name: 'c_deleteShiftsInBulk', key: 'c_deleteShiftsInBulk' },
            { name: 'c_publishShifts', key: 'c_publishShifts' }
          ]
        },
        {
          name: 'h_templates',
          key: 'shift-plan-template',
          type: 'collection',
          isHeader: true,
          permissions: [
            { name: 'c_readTemplate_all', key: 'read_all', type: 'collection-permission' },
            { name: 'c_createTemplate_all', key: 'create_all', type: 'collection-permission' },
            {
              name: 'h_editTemplate',
              isHeader: true,
              permissions: [
                { name: 'c_editTemplate_own', key: 'edit_own', type: 'collection-permission' },
                { name: 'c_editTemplate_all', key: 'edit_all', type: 'collection-permission' }
              ]
            },
            {
              name: 'h_deleteTemplate',
              isHeader: true,
              permissions: [
                { name: 'c_deleteTemplate_own', key: 'delete_own', type: 'collection-permission' },
                { name: 'c_deleteTemplate_all', key: 'delete_all', type: 'collection-permission' }
              ]
            }
          ]
        }
      ]
    }
  ]
};
