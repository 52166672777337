<orgos-dialog-container *ngIf="apiKeyInfo && apiKeyInfo.apiKey && apiKeyInfo.apiKey !== ''"
  [title]="pageTranslation.dialogHeaderText" (clickCloseButton)="closeDialog()">
  <div class="cakd-alias">
    <orgos-input-simple-text *ngIf="data && !data.apiKeyId" [label]="pageTranslation.aliasInput" maxlength="40"
      [(value)]="apiKeyInfo.alias" required="true" [customValidation]="existingAliasValidationFunction"
      (validation)="existingAliasValidation = $event">
      <orgos-input-error *ngIf="existingAliasValidation && existingAliasValidation.getError('notValid')">
        {{pageTranslation.aliasUsedBeforeValidation}}</orgos-input-error>
      <orgos-input-error *ngIf="existingAliasValidation && existingAliasValidation.getError('empty')">
        {{pageTranslation.descriptionValidationMessage}}</orgos-input-error>
    </orgos-input-simple-text>
    <div *ngIf="data && data.id !== undefined" class="cakd-regenerate-button kenjo-ml-20px kenjo-mt-10px">
      <orgos-button-raised (click)="getApiKey()">
        <kenjo-icon class="cakd-regenerate-icon" [size]="18">sync</kenjo-icon>
        <div>{{ pageTranslation.regenerateApiKey }}</div>
      </orgos-button-raised>
    </div>
  </div>
  <div class="cakd-api-key-fields">
    <orgos-input-simple-text [readOnly]="true" [label]="pageTranslation.apiKeyInput" [(value)]="apiKeyInfo.apiKey"
      class="kenjo-pr-10px"></orgos-input-simple-text>
    <kenjo-icon *ngIf="apiKeyRegenerated === true" [size]="18"
      class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3 kenjo-mt-20px"
      (click)="copyFieldToClipboard()">files_empty</kenjo-icon>
  </div>
  <div *ngIf="apiKeyRegenerated === true" class="kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-mt-5px">
    {{pageTranslation.apiKeyInputFooter}}</div>

  <orgos-column-container class="kenjo-mt-40px kenjo-mb-60px">
    <orgos-column size="1">
      <div class="kenjo-font-size-14px kenjo-mb-15px kenjo-font-color-text-333333">
        {{pageTranslation.permissionsListTittle}}</div>
      <div *ngFor="let permission of permissions">
        <div class="cakd-list-item kenjo-mt-10px">
          <orgos-input-simple-checkbox class="cakd-checkbox" [(value)]="permission.selected">
          </orgos-input-simple-checkbox>
          <div class="cakd-permission-item kenjo-ml-5px kenjo-font-size-12px
            kenjo-font-color-text-333333"
            (click)="permission.selected = !permission.selected; $event.stopPropagation();" style="cursor: pointer;">
            {{permission.label}}
          </div>
        </div>
      </div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333">{{pageTranslation.modulesListTittle}}</div>
      <orgos-column-container class="kenjo-mt-15px">
        <orgos-column size="1">
          <div *ngFor="let module of modulesCol1">
            <div class="cakd-list-item">
              <kenjo-icon [size]="12">done</kenjo-icon>
              <div class="kenjo-ml-5px kenjo-mb-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{module}}
              </div>
            </div>
          </div>
        </orgos-column>
        <orgos-column size="1">
          <div *ngFor="let module of modulesCol2">
            <div class="cakd-list-item">
              <kenjo-icon [size]="12">done</kenjo-icon>
              <div class="kenjo-ml-5px kenjo-mb-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{module}}
              </div>
            </div>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container *ngIf="data && data.id !== undefined" centerColumns="true" class="kenjo-mt-60px">
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-success-00b72e">error_outline</kenjo-icon>
    <orgos-column
      class="kenjo-ml-5px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{pageTranslation.updateWarningMessage}}
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-30px kenjo-mb-10px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{pageTranslation.goBackButtonText}}
      </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Success"
        [disabled]="(!existingAliasValidation || existingAliasValidation.hasErrors()) && data && !data.apiKeyId"
        orgosColumn="0" (click)="saveApiKey()">{{pageTranslation.saveButton}}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>