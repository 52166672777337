<orgos-dialog-container [title]="translations.dialogHeader" (clickCloseButton)="closeDialog()">
  <div class="kenjo-flex-vertical kenjo-flex-gap-40px cdrtd-dialog kenjo-pb-10px">
    <div class="kenjo-flex-vertical kenjo-flex-gap-20px">
      <div class="kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-flex-spread">
        <orgos-button-raised
          color="Primary"
          [matMenuTriggerFor]="actionsMenu"
          [disabled]="isLoading === true || service.readTrackingEmployees.length === 0"
          >{{ translations.actionsButtonLabel }}</orgos-button-raised
        >
        <mat-menu #actionsMenu="matMenu">
          <ng-container>
            <button
              mat-menu-item
              (click)="sendBulkReadRequest()"
              [disabled]="numberOfEmployeesWhoHaventReceivedRequest < 1 || sendingReadRequest === true"
            >
              {{ translations.bulkRequestButtonLabel }}
            </button>
            <button
              mat-menu-item
              (click)="sendBulkReadReminder()"
              [disabled]="numberOfEmployeesWhoCanReceiveReminder < 1 || sendingReadRequest === true"
            >
              {{ translations.bulkRemindButtonLabel }}
            </button>
            <button mat-menu-item (click)="exportData()" [disabled]="sendingReadRequest === true">
              {{ translations.exportButtonLabel }}
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <div class="kenjo-flex-vertical">
        <kenjo-filter-bar
          *ngIf="service.readTrackingEmployeesMetadata.totalRecords > 0"
          [shown]="true"
          [filterFields]="filterFields"
          [includeEmployeeSearch]="true"
          [searchTerm]="queryOptions.searchTerm"
          (changeFilterEvent)="changeFilter($event)"
          (clearFilterEvent)="clearFilter($event)"
          (clearAllFiltersEvent)="clearAllFilters()"
          (searchTermChanged)="searchTitle($event)"
        ></kenjo-filter-bar>
        <div
          *ngIf="isLoading === false; else spinner"
          class="kenjo-flex-vertical"
          [ngClass]="{'cdrtd-content': service.readTrackingEmployeesMetadata.totalRecords > 0}"
        >
          <ng-container *ngIf="service.readTrackingEmployeesMetadata.totalRecords > 0; else empty">
            <ng-container *ngIf="service.readTrackingEmployees.length > 0; else noMatchingEmployees">
              <div
                class="cdrt-table-container"
                [ngClass]="service.readTrackingEmployeesMetadata.numberOfPages > 1 ? 'cdrtd-table-container-paginated' : 'cdrtd-table-container-unpaginated'"
              >
                <orgos-table
                  *ngIf="service.readTrackingEmployees.length > 0"
                  [fullHeight]="true"
                  [headerLabels]="headerLabels"
                  [displayedColumns]="columns"
                  [data]="service.readTrackingEmployees"
                  [enableCustomSorting]="true"
                  (customSortTable)="sortColumn($event)"
                  [customSortBy]="queryOptions.sortOptions.sortBy"
                  [customSortOrder]="queryOptions.sortOptions.sortOrder"
                  [hidePagination]="true"
                >
                  <orgos-table-column columnKey="displayName" [header]="headerLabels.displayName" [sortable]="true" cellWidth="2">
                    <ng-container *orgosTableCell="let row">
                      <div class="kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-flex-gap-10px">
                        <orgos-avatar
                          [avatarName]="row.displayName"
                          [photoUrl]="row._photo?._url"
                          size="smaller"
                          class="kenjo-cursor-pointer"
                          [routerLink]="['/cloud/people/', row._id, 'personal']"
                          (click)="closeDialog()"
                        ></orgos-avatar>
                        <div class="kenjo-flex-min-width-0 kenjo-flex-size-1">
                          <kenjo-truncate-text class="cdrtd-relevant-field">{{ row.displayName }}</kenjo-truncate-text>
                        </div>
                      </div>
                    </ng-container>
                  </orgos-table-column>
                  <orgos-table-column columnKey="requestedStatus" [header]="headerLabels.requestedStatus" [sortable]="true" cellWidth="4">
                    <ng-container *orgosTableCell="let row">
                      <orgos-chip color="SuccessSecondaryType" *ngIf="row.readBy.userId">{{ translations.requestedChip }}</orgos-chip>
                      <orgos-chip color="WarningSecondaryType" *ngIf="!row.readBy.userId">{{ translations.notRequestedChip }}</orgos-chip>
                    </ng-container>
                  </orgos-table-column>
                  <orgos-table-column columnKey="progressStatus" [header]="headerLabels.progressStatus" [sortable]="true" cellWidth="2">
                    <ng-container *orgosTableCell="let row">
                      <orgos-chip color="SuccessSecondaryType" *ngIf="row.readBy.userId && row.readBy.readDate"
                        >{{ translations.progressReadChip }}</orgos-chip
                      >
                      <orgos-chip color="DangerSecondaryType" *ngIf="row.readBy.userId && !row.readBy.readDate"
                        >{{ translations.progressNotReadChip }}</orgos-chip
                      >
                      <div *ngIf="!row.readBy.userId">--</div>
                    </ng-container>
                  </orgos-table-column>
                  <orgos-table-column columnKey="readDate" [header]="headerLabels.readDate" [sortable]="true" cellWidth="6">
                    <ng-container *orgosTableCell="let row">
                      <div *ngIf="row.readBy.readDate">{{ row.readBy.readDate | date: 'short' }}</div>
                      <div *ngIf="!row.readBy.readDate">--</div>
                    </ng-container>
                  </orgos-table-column>
                  <orgos-table-column columnKey="hasAccess" [header]="headerLabels.hasAccess" [sortable]="true" cellWidth="2">
                    <ng-container *orgosTableCell="let row">
                      <div>{{ row.hasAccess ? translations.hasAccessText : translations.hasNoAccessText }}</div>
                    </ng-container>
                  </orgos-table-column>
                  <orgos-table-column columnKey="icon" cellWidth="1">
                    <ng-container *orgosTableCell="let row">
                      <kenjo-icon
                        *ngIf="!row.readBy.userId"
                        [size]="20"
                        class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
                        (click)="sendIndividualRequest(row)"
                        [matTooltip]="translations.requestReadReceiptButtonTooltip"
                        matTooltipPosition="above"
                        [disabled]="sendingReadRequest === true"
                        >send</kenjo-icon
                      >
                      <kenjo-icon
                        *ngIf="row.readBy.userId && !row.readBy.readDate && row.hasAccess"
                        [size]="20"
                        class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
                        (click)="sendIndividualRequest(row)"
                        [matTooltip]="translations.sendReminderTooltip"
                        matTooltipPosition="above"
                        [disabled]="sendingReadRequest === true"
                        >mail</kenjo-icon
                      >
                      <kenjo-icon
                        *ngIf="row.readBy.userId && !row.readBy.readDate && !row.hasAccess"
                        [size]="20"
                        class="kenjo-font-color-disabled-c4c4c4"
                        [matTooltip]="translations.reminderDisabledDueToPermissionsTooltip"
                        matTooltipPosition="above"
                        >mail</kenjo-icon
                      >
                      <kenjo-icon
                        *ngIf="row.readBy.readDate"
                        [size]="20"
                        class="kenjo-font-color-disabled-c4c4c4"
                        [matTooltip]="translations.documentReadTooltip"
                        matTooltipPosition="above"
                        >drafts</kenjo-icon
                      >
                    </ng-container>
                  </orgos-table-column>
                </orgos-table>
              </div>
              <div
                *ngIf="service.readTrackingEmployeesMetadata.totalRecords > recordsToShowSelector[0] && service.readTrackingEmployees.length > 0"
                class="cdrtd-paginator kenjo-flex-center-content kenjo-flex-gap-40px"
              >
                <div class="kenjo-flex-center-content kenjo-flex-gap-10px">
                  <div class="kenjo-font-color-light-text-757575">{{ translations.pagination.displayingLabel }}</div>
                  <div
                    class="kenjo-cursor-pointer kenjo-flex-center-content cdrtd-list-num-pages-container kenjo-pl-10px"
                    [matMenuTriggerFor]="paginationMenu"
                  >
                    <div class="cdrtd-list-records-page">{{ queryOptions.paginationOptions.recordsPerPage }}</div>
                    <div class="kenjo-flex-size-0">
                      <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                    </div>
                  </div>
                  <mat-menu #paginationMenu="matMenu">
                    <button
                      *ngFor="let page of recordsToShowSelector"
                      mat-menu-item
                      (click)="onChangeRecordsToShow(page)"
                      class="kenjo-font-color-light-text-757575"
                    >
                      {{ page }}
                    </button>
                  </mat-menu>
                  <div class="kenjo-font-color-light-text-757575">{{ translations.pagination.itemsOutOfLabel }}</div>
                  <div>{{ filteredRecords }}</div>
                </div>
                <div class="kenjo-flex-center-content kenjo-flex-gap-10px">
                  <div>
                    <mat-icon
                      class="cdrtd-list-pagination-icon"
                      [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.paginationOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.paginationOptions.page !== 1 }"
                      (click)="moveToPage(PAGE_SELECTOR['first'])"
                      >first_page</mat-icon
                    >
                  </div>
                  <div>
                    <mat-icon
                      class="cdrtd-list-pagination-icon"
                      [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.paginationOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.paginationOptions.page !== 1 }"
                      (click)="moveToPage(PAGE_SELECTOR['previous'])"
                      >keyboard_arrow_left</mat-icon
                    >
                  </div>
                  <div>{{ queryOptions.paginationOptions.page }} / {{ numberOfPages }}</div>
                  <div>
                    <mat-icon
                      class="cdrtd-list-pagination-icon"
                      [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.paginationOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.paginationOptions.page !== numberOfPages }"
                      (click)="moveToPage(PAGE_SELECTOR['next'])"
                      >keyboard_arrow_right</mat-icon
                    >
                  </div>
                  <div>
                    <mat-icon
                      class="cdrtd-list-pagination-icon"
                      [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.paginationOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.paginationOptions.page !== numberOfPages }"
                      (click)="moveToPage(PAGE_SELECTOR['final'])"
                      >last_page</mat-icon
                    >
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="kenjo-flex-align-self-end">
      <orgos-button-raised color="Success" (click)="closeDialog()">{{ translations.doneButtonLabel }}</orgos-button-raised>
    </div>
  </div>
</orgos-dialog-container>

<ng-template #spinner>
  <div class="kenjo-flex-vertical kenjo-flex-center kenjo-flex-center-vertically kenjo-flex-gap-10px cdrtd-spinner">
    <orgos-loading-spinner color="Success"></orgos-loading-spinner>
    <div>{{ translations.spinnerText }}</div>
  </div>
</ng-template>

<ng-template #empty>
  <div class="kenjo-flex-vertical kenjo-flex-center-vertically kenjo-flex-gap-5px kenjo-pv-40px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-font-weight-bold kenjo-mt-5px">
      {{ translations.noEmployeeAccessBoldText }}
    </div>
    <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">
      {{ translations.noEmployeeAccessBeforeSettings }}<a [routerLink]="['/cloud/settings/documents']" (click)="closeDialog()"
        >{{ translations.noEmployeeAccessSettings }}</a
      >{{ translations.noEmployeeAccessAfterSettings }}
    </div>
  </div>
</ng-template>

<ng-template #noMatchingEmployees>
  <div
    class="
      kenjo-flex-vertical kenjo-flex-center-vertically kenjo-flex-center kenjo-flex-gap-5px kenjo-pv-40px kenjo-border kenjo-border-radius
      cdrtd-content
    "
  >
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-font-size-16px kenjo-font-weight-bold kenjo-mt-5px">{{ translations.noMatchingEmployees }}</div>
    <div class="kenjo-font-color-light-text-757575 kenjo-text-align-center">
      {{ translations.noMatchingEmployeesDescriptionLine1 }}<br />
      {{ translations.noMatchingEmployeesDescriptionLine2 }}
    </div>
  </div>
</ng-template>
