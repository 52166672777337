<orgos-dialog-container *ngIf="dialogTranslation" class="dialog-box" [title]="dialogTranslation.addApprovers" (clickCloseButton)="closeDialog(false)">
  <div class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{dialogTranslation.subTitle}}</div>

  <div class="kenjo-mt-30px">
    <orgos-input-simple-checkbox [value]="true"> </orgos-input-simple-checkbox>
    <span class="kenjo-ml-10px">{{ dialogTranslation.specificEmployee }}</span>
  </div>

  <div class="kenjo-mt-30px">
    <orgos-input-simple-checkbox [value]="true"> </orgos-input-simple-checkbox>
    <span class="kenjo-ml-10px">{{ dialogTranslation.managerApprover }}</span>
  </div>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-button-raised orgosColumn="0" color="Primary" class="kenjo-ml-20px"> {{ dialogTranslation.addApprovers }} </orgos-button-raised>
  </orgos-column-container>
</orgos-dialog-container>
