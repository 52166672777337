import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import { CandidateService } from 'src/app/standard/services/recruiting/candidate.service';

import { GenericCacheModel } from '../../../core/generic-cache-model';
import { AuthenticationService } from '../../../services/core/authentication.service';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { RecruitingDocumentTagService } from '../../../services/recruiting/recruiting-document-tag.service';
import { RecruitingDocumentService } from '../../../services/recruiting/recruiting-document.service';

@Component({
  selector: 'orgos-recruiting-upload-multiple-docs-dialog',
  templateUrl: 'recruiting-upload-multiple-docs.dialog.html',
  styleUrls: ['recruiting-upload-multiple-docs.dialog.scss'],
})
export class RecruitingUploadMultipleDocsDialog implements OnInit {
  candidateId: string;
  positionCandidateId: string;
  dialogTranslations: any = {};
  listTags: Array<any>;
  tagIdToRecruitingTag: any = {};

  mapNameValidation: any = {};
  tagIdToColor: any = {};

  docsToUpload: Array<any>;
  cannotSave: boolean = true;
  uploadingFiles: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<RecruitingUploadMultipleDocsDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {
    if (check.assigned(this.data.uploadConfig)) {
      if (check.assigned(this.data.uploadConfig.candidateId)) {
        this.candidateId = this.data.uploadConfig.candidateId;
      }
      if (check.assigned(this.data.uploadConfig.positionCandidateId)) {
        this.positionCandidateId = this.data.uploadConfig.positionCandidateId;
      }
    }
    this.initTagData()
      .then(() => {
        if (check.assigned(this.data.docsToUpload) && check.not.emptyArray(this.data.docsToUpload)) {
          const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
          this.docsToUpload = this.data.docsToUpload.map((doc) => {
            doc.tag = this.listTags[0]._id;
            doc.extension = this.getExtension(doc._file._fileName);
            doc.name = this.getFileName(doc._file._fileName);
            const document = new GenericCacheModel(this.injector, doc, RecruitingDocumentService, loggedUser._id);
            return document;
          });
        }
      })
      .catch(() => {
        //
      });
  }

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-upload-multiple-docs-dialog')
      .then((dialogTranslations: any) => {
        this.dialogTranslations = dialogTranslations;
      })
      .catch(() => {
        this.dialogTranslations = {};
      });
  }

  private initTagData(): Promise<Array<any> | void> {
    return new Promise<Array<any> | void>((resolve, reject) => {
      const inCollection = ['candidate'];
      if (check.assigned(this.positionCandidateId) && check.nonEmptyString(this.positionCandidateId)) {
        inCollection.push('position-candidate');
      }

      this.injector
        .get(RecruitingDocumentTagService)
        .find({ _inCollection: inCollection })
        .then((listTags) => {
          this.listTags = listTags.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            } else if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.tagIdToRecruitingTag = listTags.reduce((total, tag) => {
            total[tag._id] = tag;
            return total;
          }, {});
          this.initTagIdColorMap();
          resolve();
        })
        .catch(() => {
          this.listTags = null;
          reject();
        });
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private getExtension(docName: string): string {
    if (check.not.assigned(docName) || docName.split('.').length < 2) {
      return '';
    }

    const extension = docName.split('.').pop();
    return extension.substring(0, 4);
  }

  private getFileName(docName: string): string {
    if (check.not.assigned(docName)) {
      return '';
    }

    return docName.split('.')[0];
  }

  private initTagIdColorMap(): void {
    this.tagIdToColor = this.listTags.reduce((total, tag) => {
      total[tag._id] =
        check.assigned(tag.color) && check.assigned(userColorConstants[tag.color]) ? userColorConstants[tag.color] : '#757575';
      return total;
    }, {});
  }

  public uploadDocument(): void {
    if (this.uploadingFiles === true) {
      return;
    }

    if (this.cannotSave === true) {
      return;
    }

    this.uploadingFiles = true;

    this.processDocuments();
    const docsToCreate = this.docsToUpload.map((doc) => {
      return doc.createInServer();
    });
    Promise.all(docsToCreate)
      .then((createResult) => {
        this.injector
          .get(CandidateService)
          .touchUpdatedAtById(this.candidateId)
          .then(() => {})
          .catch(() => {});
        this.injector.get(MatLegacySnackBar).open(this.dialogTranslations.documentsUploadedSnackText, 'OK', {
          duration: 5000,
        });
        const createdDocs = createResult.map((document) => {
          return document.data;
        });

        this.dialogRef.close(createdDocs);
      })
      .catch(() => {
        this.uploadingFiles = false;
      });
  }

  /**
   * processDocuments()
   * Prepare each file to populate the relatedTo field and delete interaction fields to be aligned with the schema model
   */
  private processDocuments(): void {
    this.docsToUpload.forEach((doc) => {
      const tag = this.tagIdToRecruitingTag[doc.data.tag];
      if (check.not.assigned(tag) || check.not.assigned(tag._inCollection) || check.emptyString(tag._inCollection)) {
        return;
      }
      if (tag._inCollection === 'candidate') {
        doc.data.relatedTo = this.candidateId;
      } else if (tag._inCollection === 'position-candidate') {
        doc.data.relatedTo = this.positionCandidateId;
      }
      delete doc.data.extension;
      delete doc.data.color;
    });
  }

  public changeDocName(): void {
    this.cannotSave = Object.keys(this.mapNameValidation).some((key) => {
      return this.mapNameValidation[key] && this.mapNameValidation[key].hasErrors() === true;
    });
  }
}
