import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ConfigureVisiblityDialog } from '@app/cloud-features/home/components/appreciation-widget/dialogs/configure-visibility/configure-visibility.dialog';
import { AppreciationService, IAppreciationModel } from '@app/cloud-features/home/services/appreciation.service';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IMentionOption } from '@app/standard/components/input-simple-editor/input-simple-editor.component';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  templateUrl: 'new-appreciation.dialog.html',
  styleUrls: ['new-appreciation.dialog.scss'],
})
export class NewAppreciationDialog implements OnInit {
  appreciation: IAppreciationModel = {};
  userPersonal: IUserPersonalModel;
  translation: any = {};
  dialogTitle: string = '';
  messageValidation: InputValidation;
  mentionOptions: Array<IMentionOption> = [];
  toolbar: Array<any> = [
    //quill toolbar
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }],
    [{ align: [] }],
    ['link'],
  ];
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<ConfigureVisiblityDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {
    this.userPersonal = this.data.userPersonal;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true; // Disable close because we want the user to stay on this screen
    this.appreciation = {
      _userId: this.userPersonal._id,
    };
    this.getTranslations();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (check.not.assigned(this.messageValidation) || this.messageValidation.hasErrors()) {
      return;
    }

    this.isLoading = true;
    this.injector
      .get(AppreciationService)
      .create(this.appreciation)
      .then(() => {
        const snackbarRef = this.injector
          .get(MatLegacySnackBar)
          .open(this.translation.snackbarAppreciationCreated, this.translation.goToHomePage, {
            duration: 5000,
          });
        snackbarRef.onAction().subscribe(() => {
          this.injector.get(Router).navigateByUrl('/cloud/home');
        });
        this.injector.get(PrivateAmplitudeService).logEvent('give shout out', { category: 'Shout outs' });
        this.dialogRef.close(this.appreciation);
        this.isLoading = false;
      })
      .catch(() => {
        // error is displayed
        this.isLoading = false;
      });
  }

  private getTranslations(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('home-page')
      .then((translation) => {
        this.translation = translation;
        this.dialogTitle = this.injector
          .get(I18nDataPipe)
          .transform(this.translation.newAppreciationDialogTitle, { firstName: this.userPersonal.firstName });
      })
      .catch(() => {
        this.translation = {};
      });
  }
}
