<div class="kenjo-mv-30px kenjo-mh-40px" *ngIf="(!employees || employees.length === 0) && !icon">
  <div class="dc-title kenjo-font-size-20px">{{ title }}</div>
  <kenjo-icon [size]="24" class="dc-close-button kenjo-cursor-pointer kenjo-ml-40px kenjo-font-color-light-text-757575" [disabled]="disabledCloseButton" (click)="clickCloseButton.emit()">clear</kenjo-icon>
</div>

<div class="dc-title-icon kenjo-mt-30px kenjo-mb-10px kenjo-mh-40px" *ngIf="icon">
  <div class="dc-title-icon">
    <kenjo-icon [size]="20">{{ icon }}</kenjo-icon>
    <div class="kenjo-font-size-20px kenjo-ml-10px">{{ title }}</div>
  </div>
  <kenjo-icon [size]="24" class="kenjo-cursor-pointer kenjo-ml-40px kenjo-font-color-light-text-757575" [disabled]="disabledCloseButton" (click)="clickCloseButton.emit()">clear</kenjo-icon>
</div>

<div class="dc-employees-dialog kenjo-mv-30px kenjo-mh-40px" *ngIf="employees && employees.length > 0 && !icon">
  <div class="kenjo-font-size-20px">
    <div>{{ title }}</div>
    <div class="dc-employees kenjo-ml-10px">
      <div class="dc-avatar" *ngFor="let employee of employees | slice: 0:MAX_EMPLOYEES; let i = index">
        <orgos-avatar *ngIf="i < MAX_EMPLOYEES" [avatarName]="employee.displayName" [photoUrl]="employee._photo?._url" size="small"></orgos-avatar>
      </div>
      <div *ngIf="employees.length > MAX_EMPLOYEES" class="dc-circle kenjo-ml-5px kenjo-p-5px" [matTooltip]="employeesTooltip" matTooltipClass="allow-cr" matTooltipPosition="above">
        <div>+{{ employees.length - MAX_EMPLOYEES }}</div>
      </div>
    </div>
  </div>
  <kenjo-icon [size]="24" class="dc-close-button kenjo-cursor-pointer kenjo-ml-40px kenjo-font-color-light-text-757575" [disabled]="disabledCloseButton" (click)="clickCloseButton.emit()">clear</kenjo-icon>
</div>
<div class="dc-content kenjo-pb-30px kenjo-ph-40px">
  <ng-content></ng-content>
</div>
