<div *ngIf="template" cdkDrag (cdkDragStarted)="onDrag(true)" (cdkDragEnded)="onDrag(false)" id="template">
  <div *ngIf="cardColor" class="spt-card spt-template-card kenjo-mb-10px" [style.border-color]="cardColor">
    <div class="spt-card-left-border" [style.background-color]="cardColor"></div>
    <orgos-column-container centerColumns="true" [style.border-color]="cardColor">
      <orgos-column size="0">
        <kenjo-icon class="spt-template-drag kenjo-font-color-light-text-757575 spt-template-padding" [size]="20" cdkDragHandle
          >drag_indicator</kenjo-icon
        >
      </orgos-column>
      <kenjo-truncate-text orgosColumn="1" [class.spt-template-name]="showTemplateDetails && selectedTemplateId === template._id">{{
        template.name
      }}</kenjo-truncate-text>
      <div
        *ngIf="showTemplateDetails && selectedTemplateId === template._id && shiftplanPermissions"
        orgosColumn="0"
        class="spt-icon-container kenjo-cursor-pointer kenjo-ml-10px"
        [matTooltip]="pageTranslation.addShift"
        [ngClass]="{
          'spt-icon-container-enabled': shiftplanPermissions['open'].create || shiftplanPermissions['scheduled'].create,
          'spt-icon-container-disabled': !shiftplanPermissions['open'].create && !shiftplanPermissions['scheduled'].create
        }"
      >
        <kenjo-icon
          [size]="16"
          [disabled]="!shiftplanPermissions['open'].create && !shiftplanPermissions['scheduled'].create"
          class="kenjo-font-color-light-text-757575"
          (click)="openAddShiftDialog()"
          >add_box</kenjo-icon
        >
      </div>
      <div
        *ngIf="showTemplateDetails && selectedTemplateId === template._id"
        orgosColumn="0"
        class="spt-icon-container kenjo-ml-5px kenjo-mr-10px"
        [ngClass]="{ 'spt-icon-container-enabled': !editDisabledPermission, 'spt-icon-container-disabled': editDisabledPermission }"
        [matTooltip]="editTooltip"
      >
        <kenjo-icon [size]="16" [disabled]="editDisabledPermission" (click)="editTemplate()">mode_edit</kenjo-icon>
      </div>
    </orgos-column-container>
  </div>
</div>
