import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { catchError } from 'rxjs/operators';

declare const window: any;

@Injectable()
export class TimeProfilerInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const startTime = window.performance.now();

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 503) {
          const spentTimeInMs = window.performance.now() - startTime;
          const spentTimeString = `${(spentTimeInMs / 1000).toFixed(0)}s ${(spentTimeInMs % 1000).toFixed(3)}ms`;

          const orgosError = new OrgosError(error, error.status, 'TimeProfilerInterceptor', 'CatchError');
          orgosError.message = `${req.method} ${req.urlWithParams} spent ${spentTimeString}`;
          this.injector.get(ErrorManagerService).handleParsedErrorSilently(orgosError);
        }

        throw error;
      })
    );
  }
}
