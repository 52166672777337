import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IWidgetSettingsModel } from '@app/cloud-features/settings-who-is-away/models/widget-settings.model';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class WidgetSettingsService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/widget-settings-db`;

  constructor(private genericService: GenericService, private http: HttpClient, private injector: Injector, private errorManager: ErrorManagerService) {}

  async find(widget: string): Promise<IWidgetSettingsModel> {
    try {
      const whoIsAwayWidgetSettings = await this.http
        .post<Array<IWidgetSettingsModel>>(`${this.MICROSERVICE_URL}/find`, { widget })
        .toPromise();

      return whoIsAwayWidgetSettings[0];
    } catch (error) {
      throw this.errorManager.handleRawError(error, WidgetSettingsService.name, 'find');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      if (check.not.assigned(id) || check.emptyString(id) || check.not.assigned(data) || check.emptyObject(data)) {
        throw new OrgosError(`${this.MICROSERVICE_URL}`, ErrorCodes.BAD_REQUEST, WidgetSettingsService.name, 'updateById');
      }

      await this.genericService.updateById(this.MICROSERVICE_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, WidgetSettingsService.name, 'updateById');
    }
  }
}
