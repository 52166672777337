<mat-form-field *ngIf="!readOnly" (click)="enableSearching()" cdk-overlay-origin>
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="searching ? searchTerm : currentUser?.displayName" (ngModelChange)="searchTerm = $event" #isucInput />

  <mat-icon matSuffix *ngIf="searching">search</mat-icon>
  <div matSuffix *ngIf="!searching" class="issuc-select-arrow-wrapper mat-select-arrow"></div>
  <mat-icon *ngIf="!searching && showClearButton" matSuffix class="issuc-clear-button" (click)="clearValue(); $event.stopPropagation()">clear</mat-icon>
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="searching ? searchTerm : currentUser?.displayName" readOnly disabled />
</mat-form-field>
<ng-template cdk-portal>
  <div class="issuc-cdk-box">
    <div class="issuc-results-container">
      <div class="kenjo-pl-10px kenjo-pv-20px 7575 kenjo-font-color-light-text-757575">{{ pageTranslation.matching | uppercase }}</div>
      <div class="kenjo-pl-10px kenjo-p-10px kenjo-pv-20px kenjo-font-color-danger-ff5757" *ngIf="searchMatchingResults.length === 0">{{ pageTranslation.noFoundUsers }}</div>
      <div *ngIf="searchMatchingResults?.length > 0">
        <cdk-virtual-scroll-viewport itemSize="50" [class.issu-more-items]="searchMatchingResults?.length >= 3" [class.issu-one-item]="searchMatchingResults?.length == 1" [class.issu-two-items]="searchMatchingResults?.length == 2">
          <div *cdkVirtualFor="let result of searchMatchingResults" class="kenjo-p-10px kenjo-pl-20px issuc-result issu-item" (click)="setValue(result._id)" orgosAutocompleteOption>
            <orgos-column-container centerColumns="true">
              <orgos-avatar orgosColumn="0" [photoUrl]="result._photo?._url" [avatarName]="result.displayName" size="small" class="kenjo-mr-10px"></orgos-avatar>
              <kenjo-truncate-text orgosColumn>{{ result.displayName }}</kenjo-truncate-text>
            </orgos-column-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>

      <div *ngIf="searchResults?.length > 0">
        <div class="kenjo-p-10px kenjo-font-color-light-text-757575">
          {{ pageTranslation.allEmployees | uppercase }}
        </div>

        <cdk-virtual-scroll-viewport itemSize="50" [class.issu-more-items]="searchResults?.length >= 3" [class.issu-one-item]="searchResults?.length == 1" [class.issu-two-items]="searchResults?.length == 2">
          <div *cdkVirtualFor="let result of searchResults" class="kenjo-p-10px kenjo-pl-20px issuc-result issu-item" (click)="setValue(result._id)" orgosAutocompleteOption>
            <orgos-column-container centerColumns="true">
              <orgos-avatar orgosColumn="0" [photoUrl]="result._photo?._url" [avatarName]="result.displayName" size="small" class="kenjo-mr-10px"></orgos-avatar>
              <kenjo-truncate-text orgosColumn>{{ result.displayName }}</kenjo-truncate-text>
            </orgos-column-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</ng-template>
