import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class SurveyResultService {
  private SURVEY_RESULT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/survey-result-db`;
  private SURVEY_RESULT_PERMISSIONS_KEY: string = 'survey-result';
  private SURVEY_INTERNATIONALIZATION: string = 'survey-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService) {}

  getQuestionsForSurvey(id: string): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      const loggedUser = this.authenticationService.getLoggedUser();

      const findBody = {
        _surveyId: id,
        ownerId: loggedUser._id,
        _receiverId: loggedUser._id
      };
      this.genericService
        .find(this.SURVEY_RESULT_URL, findBody)
        .then((openQuestions: Array<any>) => {
          resolve(openQuestions);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawErrorSilently(error, SurveyResultService.name, 'getQuestionsForSurvey'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.SURVEY_RESULT_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyResultService.name, 'updateById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.SURVEY_RESULT_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.SURVEY_INTERNATIONALIZATION);
  }
}
