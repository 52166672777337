<div *ngIf="workScheduleTemplate">
  <orgos-column-container centerColumns="true">
    <orgos-column size="1" class="kenjo-mr-20px">
      <div class="kenjo-font-size-16px kenjo-font-color-text-333333">{{ translations.addName }}</div>
      <orgos-input-simple-text [label]="translations.nameLabel" [(value)]="workScheduleTemplate.name" class="kenjo-mt-20px whc-fields-width" [placeholder]="translations.namePlaceholder" required="true"> </orgos-input-simple-text>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-16px kenjo-font-color-text-333333">{{ translations.startDate }}</div>
      <orgos-input-simple-date-picker [(value)]="workScheduleTemplate.startDate" [label]="translations.startDateLabel" class="kenjo-mt-20px whc-fields-width" required="true" [readOnly]="editMode"> </orgos-input-simple-date-picker>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-50px">
    <orgos-column size="1" class="kenjo-mr-20px">
      <div class="kenjo-font-size-16px kenjo-font-color-text-333333">{{ translations.workDays }}</div>
      <div class="kenjo-mt-20px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.workDaysLabel }}</div>
      <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
        <orgos-column *ngFor="let day of workScheduleTemplate.dayShifts; let weekDay = index" size="0" class="kenjo-mr-10px" (click)="clickOnDay(weekDay)">
          <div *ngIf="readOnly" class="whc-toggle whc-disabled" [class.whc-checked]="day.minutes !== undefined && day.minutes >= 0">
            <span>{{ translatedWeekdays[weekDay] }}</span>
          </div>
          <div *ngIf="!readOnly" class="whc-toggle" [class.whc-checked]="day.minutes !== undefined && day.minutes >= 0">
            <span>{{ translatedWeekdays[weekDay] }}</span>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1" class="whc-fields-width">
      <div class="kenjo-font-size-16px kenjo-font-color-text-333333">{{ translations.expectedHoursLabel }}</div>
      <mat-form-field>
        <mat-select [(ngModel)]="workScheduleTemplate.type" (selectionChange)="workScheduleTemplate.type = $event.value;">
          <mat-option *ngFor="let type of templateType" [value]="type.value">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </orgos-column>
  </orgos-column-container>

  <div class="kenjo-mt-60px kenjo-font-size-16px kenjo-font-color-text-333333">{{ translations.selectHoursLabel }}</div>
  <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
    <orgos-column size="0">
      <kenjo-icon [size]="18" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ translations.timeTableInfo }}</div>
    </orgos-column>
  </orgos-column-container>

  <kenjo-time-table (validSave)="emitValidSave($event);" [workScheduleTemplate]="workScheduleTemplate" class="kenjo-mt-20px"></kenjo-time-table>
</div>
