<div class="mpd-dialog kenjo-p-50px">
  <orgos-column-container centerColumns="true" class="kenjo-mb-30px">
    <orgos-column size="1" class="kenjo-font-size-20px">
      {{dialogTranslation.dialogName | i18nData:userPersonal}}
    </orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24"
      class="kenjo-ml-40px kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()">
      clear
    </kenjo-icon>
  </orgos-column-container>

  <orgos-container aspect="card" class="mpd-manage-user-policies-card" *ngIf="!loadingData">
    <kenjo-manage-user-policies [timeOffTypes]="timeOffTypes" [userStatus]="userStatus" [userStartDate]="userStartDate"
      [userId]="userPersonal._id" [scheduledPolicies]="scheduledPolicies" (assignments)="assignments = $event">
    </kenjo-manage-user-policies>
    <div class="kenjo-text-align-right kenjo-p-30px mpd-button-strip">
      <orgos-button-raised color="Neutral" (click)="closeDialog()" class="kenjo-mr-20px" [disabled]="savingData">
        {{miscTranslation.goBackButtonDialog}}</orgos-button-raised>
      <orgos-button-raised color="Success" (click)="saveChanges()" [disabled]="!(assignments.length > 0) || savingData">
        {{dialogTranslation.saveChanges}}</orgos-button-raised>
    </div>

    <div *ngIf="!loadingData && !(timeOffTypes?.length > 0)"
      class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{dialogTranslation.noTimeOffTypeTitle}}</div>
      <div class="kenjo-mt-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">
        {{dialogTranslation.noTimeOffTypeSubtitle}}</div>
    </div>
  </orgos-container>
</div>