import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HolidayDurationOption } from '@app/standard/services/company/calendar.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

import { AuthenticationService } from '../core/authentication.service';

@Injectable()
export class CalendarTemplateService {
  private CALENDAR_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/calendar-template-db`;

  constructor(private http: HttpClient, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService) {}

  getAvailableTemplates(): Promise<Array<ICalendarTemplateModel>> {
    return new Promise<Array<ICalendarTemplateModel>>((resolve, reject) => {
      this.http
        .get(`${this.CALENDAR_URL}/templates`)
        .toPromise()
        .then((availableCalendarTemplates: Array<ICalendarTemplateModel>) => {
          resolve(availableCalendarTemplates);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarTemplateService.name, 'getAvailableTemplates'));
        });
    });
  }

  getTemplate(calendarTemplateKey: string): Promise<ICalendarTemplateModel> {
    return new Promise<ICalendarTemplateModel>((resolve, reject) => {
      this.http
        .get(`${this.CALENDAR_URL}/templates/${calendarTemplateKey}`)
        .toPromise()
        .then((calendarTemplate: ICalendarTemplateModel) => {
          resolve(calendarTemplate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CalendarTemplateService.name, 'getTemplate'));
        });
    });
  }
}

export interface ICalendarTemplateModel {
  templateKey: string;
  holidays: Array<IHolidayTemplateModel>;
}

export interface IHolidayTemplateModel {
  holidayKey: string;
  holidayName?: string;
  holidayDate: Date;
  holidayDuration: HolidayDurationOption;
  customHoliday?: boolean;
}
