import { DecimalPipe } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { IStatusValues, ITimeOffStatus } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { IUserWorkModel } from '@app/models/user-work.model';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ACCRUAL_PERIOD_MONTHLY, ALLOWANCE_TYPE_UNLIMITED, CYCLE_TYPE_DISABLED, POLICY_TYPE_HOUR, TIME_OFF_ACTION_ASSIGN_POLICY, TIME_OFF_ACTION_NEW_CYCLE, TIME_OFF_STATUS_CYCLE_STATUS_FUTURE } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-status-details-card',
  templateUrl: 'status-details-card.component.html',
  styleUrls: ['status-details-card.component.scss']
})
export class StatusDetailsCardComponent implements OnInit {
  CYCLE_TYPE_DISABLED = CYCLE_TYPE_DISABLED;
  POLICY_TYPE_HOUR = POLICY_TYPE_HOUR;
  ACCRUAL_PERIOD_MONTHLY = ACCRUAL_PERIOD_MONTHLY;
  TIME_OFF_STATUS_CYCLE_STATUS_FUTURE = TIME_OFF_STATUS_CYCLE_STATUS_FUTURE;

  @Input() status: ITimeOffStatus;
  @Input() policy: ITimeOffPolicyModel;
  @Input() userWork: IUserWorkModel;
  @Input() displayScheduledAssignment: boolean = false;
  @Input() translations: { [key: string]: string };

  statusValues: IStatusValues = {};
  extraAllowanceValue: string;
  workAnniversary: IWorkAnniversary;
  cycleStartDate: Date;
  cycleEndDate: Date;
  expireDateInfo: string;
  prorationTooltip: string;
  carryOver: number | string;
  currentCarryOver: string;
  takenCarryOver: number | string;
  expireDateText: string;

  PLUS_MINUS_FIELD = ['givenCompensation', 'extraAllowance'];

  isLoading: boolean = true;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    this.initViewFields();
  }

  private async initViewFields(): Promise<void> {
    this.calculateStatusValuesAndTexts();
    if (this.policy.cycleType !== CYCLE_TYPE_DISABLED) {
      this.initDates();
      this.initProrationTooltip();
      await this.initExtraAllowance();
    }
    this.isLoading = false;
  }

  private calculateStatusValuesAndTexts() {
    const statusValues: IStatusValues = timeOffHelpers.getStatusDetailsCardValues(this.status, this.status.unlimitedAllowance);
    Object.keys(statusValues).forEach((iKey) => {
      this.statusValues[iKey] = this.getFieldValue({ value: statusValues[iKey], key: iKey });
    });
    if (!this.status.unlimitedAllowance) {
      this.initCarryOver(statusValues);
    }
    this.isLoading = false;
  }

  private initDates() {
    const currentStatusStartMoment = moment.utc(this.status.cycleStartDate);
    const currentStatusEndMoment = moment.utc(this.status.cycleEndDate).subtract(1, 'days');
    this.cycleStartDate = currentStatusStartMoment.toDate();
    this.cycleEndDate = currentStatusEndMoment.toDate();
  }

  private initProrationTooltip() {
    if (this.policy.accrualPeriod !== ACCRUAL_PERIOD_MONTHLY  && this.status.adjustAccrued === 0 && check.assigned(this.status.accrualSplits) && (this.status.accrualSplits.startDate !== 0 || this.status.accrualSplits.endDate !== 0)) {
      const startDate = check.assigned(this.userWork.startDate) ? moment.utc(this.userWork.startDate).format('L') : '';
      const endDate = check.assigned(this.userWork.contractEnd) ? moment.utc(this.userWork.contractEnd).format('L') : '';
      if (this.status.accrualSplits.startDate !== 0 && this.status.accrualSplits.endDate !== 0) {
        this.prorationTooltip = this.injector.get(I18nDataPipe).transform(this.translations.startAndEndDateProration, { startDate, endDate });
      } else if (this.status.accrualSplits.startDate !== 0) {
        this.prorationTooltip = this.injector.get(I18nDataPipe).transform(this.translations.startDateProration, { startDate });
      } else if (this.status.accrualSplits.endDate !== 0) {
        this.prorationTooltip = this.injector.get(I18nDataPipe).transform(this.translations.endDateProration, { endDate });
      }
    }
  }

  private async initExtraAllowance() {
    const givenExtraAllowance = this.status.givenExtraAllowance ?? 0;
    const extraAllowanceEvent = this.status.extraAllowanceEvents?.[0];
    const hasPendingExtraAllowanceEvent = check.nonEmptyObject(extraAllowanceEvent) && moment.utc().startOf('day').isBefore(extraAllowanceEvent.extraAllowanceDate) && extraAllowanceEvent.extraAllowanceTime - givenExtraAllowance > 0;
    if (givenExtraAllowance > 0 || hasPendingExtraAllowanceEvent === true) {
      this.extraAllowanceValue = this.getFieldValue({ value: givenExtraAllowance });
      if (hasPendingExtraAllowanceEvent === true) {
        this.workAnniversary = this.getWorkAnniversary(extraAllowanceEvent, givenExtraAllowance);
      }
    }
  }

  private getWorkAnniversary(extraAllowanceEvent: { extraAllowanceDate: string; extraAllowanceTime: number }, givenExtraAllowanceToSubtract: number = 0) {
    const anniversaryDate = moment.utc(extraAllowanceEvent.extraAllowanceDate);
    const workAnniversary: IWorkAnniversary = {
      value: this.getFieldValue({ value: extraAllowanceEvent.extraAllowanceTime - givenExtraAllowanceToSubtract, key: 'extraAllowance' }),
      date: anniversaryDate.toDate()
    };
    return workAnniversary;
  }

  private initCarryOver(statusValues: IStatusValues) {
    if (this.status.carryOverEnabled === true) {
      this.carryOver = statusValues.carryOver;
      this.takenCarryOver = statusValues.takenCarryOver;
      this.currentCarryOver = this.getFieldValue({ value: +statusValues.currentCarryOver, withoutDayText: true });
      if (check.assigned(this.status.carryOverExpireDate) && (this.status.hasExpiredCarryOver === true || moment.utc().startOf('day').isSameOrBefore(this.status.carryOverExpireDate))) {
        this.expireDateText = moment.utc(this.status.carryOverExpireDate).format('MMM D');
      }
    }
  }

  private getFieldValue({ value, key, withoutDayText }: { value: number; key?: string; withoutDayText?: boolean }) {
    const addPlusSign = check.assigned(key) && this.PLUS_MINUS_FIELD.includes(key) && value > 0;
    if (this.policy._type === POLICY_TYPE_HOUR) {
      return `${addPlusSign ? '+' : ''}${timeOffHelpers.getMinutesToDisplay(value, this.injector)}`;
    }
    const numberWithDecimals = this.injector.get(DecimalPipe).transform(value, '1.0-2');
    const numberText = `${addPlusSign ? '+' : ''}${numberWithDecimals}`;
    return withoutDayText === true ? numberText : `${numberText} ${Math.abs(+numberWithDecimals) === 1 ? this.translations.day : this.translations.days}`;
  }
}

export interface IHistoryEntry {
  text: string;
  value: number;
  date?: Date;
  highlight?: boolean;
  noSign?: boolean;
  showTooltip?: boolean;
  tooltipInfo?: string;
}

export interface IWorkAnniversary {
  value: string;
  date: Date;
}
