import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class HistoryService implements IGenericService {
  private HISTORY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/history-db`;

  constructor(private genericService: GenericService, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService, private injector: Injector) {}

  create(data: any): Promise<any> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, HistoryService.name, 'create');
    error.message = 'History should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.HISTORY_URL, id)
        .then((history: any) => {
          resolve(history);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HistoryService.name, 'getById'));
        });
    });
  }

  find(findBody): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.genericService
        .find(this.HISTORY_URL, findBody)
        .then((allHistory: Array<any>) => {
          resolve(allHistory);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HistoryService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, HistoryService.name, 'updateById');
    error.message = 'History should not be updated';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, HistoryService.name, 'deleteById');
    error.message = 'History should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, HistoryService.name, 'getPermissions');
    error.message = 'History should not have permissions';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getFieldsTranslations(internationalizationResource: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, HistoryService.name, 'getFieldsTranslations');
    error.message = 'History should not have field translations';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  async getUserHistory(userId: string): Promise<any> {
    if (this.authenticationService.isUserAuthenticated() === false) {
      const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, HistoryService.name, 'getUserHistory');
      throw this.errorManager.handleRawError(error);
    }
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const readableCollections = ['user-account', 'user-personal', 'user-work', 'user-address', 'user-emergency', 'user-financial', 'user-home', 'user-work-schedule', 'user-confidential'];
      const processedCollections = await Promise.all(readableCollections.map((collection) => this.injector.get(PrivateSecurityService).computePermissions({ ownerId: userId }, `${collection}.read`)));

      const findBody = {
        _collection: {
          $in: readableCollections.filter((__, index) => processedCollections[index])
        },
        _recordId: userId
      };
      return this.http.post(`${this.HISTORY_URL}/find`, findBody, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, HistoryService.name, 'getUserHistory');
    }
  }
}
