import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

import { InputValidation } from '../../../../../core/validation/input-validation';
import { InternationalizationService } from '../../../../../services/core/internationalization.service';

@Component({
  selector: 'orgos-change-work-email-dialog',
  templateUrl: 'change-work-email.dialog.html',
  styleUrls: ['change-work-email.dialog.scss'],
})
export class ChangeWorkEmailDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  currentEmail: string;
  workEmail: string;
  workEmailValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<ChangeWorkEmailDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private parentInfo: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-personal-change-work-email-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.currentEmail = this.parentInfo.workEmail;
    this.workEmail = this.parentInfo.workEmail;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.workEmail);
  }
}
