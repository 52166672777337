<orgos-dialog-container [title]="pageTranslation.dialogHeader" (clickCloseButton)="closeDialog()">
  <orgos-container aspect="card">
    <orgos-table [data]="historyData" [displayedColumns]="displayedColumns" tableWidth="100%" [customHeightToSubstract]="335">
      <orgos-table-column columnKey="field" [header]="pageTranslation.fieldColHeader" [sortable]="true">
        <orgos-text *orgosTableCell="let row">{{getTranslationValue(row.object, row.field, false)}}</orgos-text>
      </orgos-table-column>

      <orgos-table-column columnKey="object" [header]="pageTranslation.objectColHeader" [sortable]="true">
        <orgos-text *orgosTableCell="let row">{{getTranslationValue(row.object, 'mainHeader', true)}}<span *ngIf="row.childElement"> - {{getTranslationValue(row.object, row.childElement, false)}}</span></orgos-text>
      </orgos-table-column>

      <orgos-table-column columnKey="oldVal" [header]="pageTranslation.oldValColHeader" [sortable]="true">
        <orgos-text *orgosTableCell="let row">{{row.oldVal}}</orgos-text>
      </orgos-table-column>

      <orgos-table-column columnKey="newVal" [header]="pageTranslation.newValColHeader" [sortable]="true">
        <orgos-text *orgosTableCell="let row">{{row.newVal}}</orgos-text>
      </orgos-table-column>

      <orgos-table-column columnKey="updatedDate" [header]="pageTranslation.dateColHeader" [sortable]="true">
        <orgos-text *orgosTableCell="let row">{{ row.updatedDate | date:'short' }}</orgos-text>
      </orgos-table-column>

      <orgos-table-column columnKey="updatedBy" [header]="pageTranslation.updatedByColHeader" [sortable]="true">
        <orgos-text *orgosTableCell="let row">{{row.updatedBy}}</orgos-text>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>
</orgos-dialog-container>
