<ng-container *ngIf="emailTemplate && emailTemplate.data">
  <orgos-column-container centerColumns="true" class="eet-top-bar kenjo-border-bottom kenjo-ph-20px">
    <orgos-column size="0">
      <kenjo-icon size="20" (click)="onBackClick(true, false)" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
    </orgos-column>

    <orgos-column size="0" class="eet-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-column size="0" class="kenjo-font-size-16px">{{emailTemplate.data._id ? pageTranslation.pageNameEdit : pageTranslation.pageName}}</orgos-column>

    <orgos-column size="0" class="eet-separator kenjo-mh-20px kenjo-border-left"></orgos-column>
  </orgos-column-container>

  <div class="eet-main-container">
    <div class="kenjo-p-20px eet-content">
      <orgos-column-container>
        <orgos-column size="3">
          <orgos-container aspect="card" class="kenjo-p-30px">
            <orgos-column-container>
              <orgos-column size="1">
                <orgos-input-text [model]="emailTemplate" field="name" required="true" (validation)="nameValidation = $event" class="kenjo-block">
                  <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">{{pageTranslation.nameRequired}}</orgos-input-error>
                </orgos-input-text>
              </orgos-column>
              <orgos-column size="1"></orgos-column>
            </orgos-column-container>

            <orgos-column-container>
              <orgos-column size="1">
                <orgos-input-text [model]="emailTemplate" field="subject" required="true" (validation)="subjectValidation = $event" class="kenjo-block">
                  <orgos-input-error *ngIf="subjectValidation && subjectValidation.getError('required')">{{pageTranslation.subjectRequired}}</orgos-input-error>
                </orgos-input-text>
              </orgos-column>
              <orgos-column size="1"></orgos-column>
            </orgos-column-container>

            <div class="kenjo-text-align-right kenjo-mt-20px">
              <orgos-action [selected]="!emailTemplate.data.bodyType || emailTemplate.data.bodyType === 'htmlText'" (click)="changeEntryType('htmlText')">{{pageTranslation.htmlText}}</orgos-action>
              <orgos-action [selected]="emailTemplate.data.bodyType === 'rawText'" (click)="changeEntryType('rawText')" class="kenjo-ml-10px">{{pageTranslation.rawText}}</orgos-action>
            </div>

            <div class="kenjo-pt-20px">
              <orgos-input-text-area rows="19" #inputHtmlText *ngIf="emailTemplate && emailTemplate.data && (!emailTemplate.data.bodyType || emailTemplate.data.bodyType === 'htmlText')" [model]="emailTemplate" field="body" [required]="true" (validation)="bodyValidation = $event">
                <orgos-input-error *ngIf="bodyValidation && bodyValidation.getError('required')">{{pageTranslation.bodyRequired}}</orgos-input-error>
              </orgos-input-text-area>
              <orgos-input-editor #inputRawText *ngIf="emailTemplate && emailTemplate.data && emailTemplate.data.bodyType === 'rawText'" [model]="emailTemplate" field="bodyQuill" size="medium" [required]="true" (validation)="bodyValidation = $event">
                <orgos-input-error *ngIf="bodyValidation && bodyValidation.getError('required')">{{pageTranslation.bodyRequired}}</orgos-input-error>
              </orgos-input-editor>
            </div>

            <div class="kenjo-mt-10px">
              <!-- ATTACHMENTS START HERE -->
              <div *ngIf="emailTemplate && emailTemplate.data">
                <div *ngIf="emailTemplate.data.documents && emailTemplate.data.documents.length > 0" class="kenjo-font-weight-bold">{{pageTranslation.attachmentsTitle | i18nData:{nAttachments: emailTemplate.data.documents.length} }}</div>
                <div *ngIf="emailTemplate.data.documents && emailTemplate.data.documents.length > 0">
                  <orgos-column-container class="eet-attachment-container kenjo-border kenjo-color-grey-background-lighter-f8f8f8 kenjo-mt-10px kenjo-mr-10px kenjo-pv-5px kenjo-ph-10px" *ngFor="let iDocument of emailTemplate.data.documents" centerColumns="true">
                    <orgos-column size="0">
                      <orgos-document-icon size="small" [extension]="iDocument._fileExtension" (click)="downloadAttachment(iDocument)"></orgos-document-icon>
                    </orgos-column>
                    <orgos-column size="0" class="kenjo-ml-10px kenjo-mr-5px">
                      <a (click)="downloadAttachment(iDocument)"><kenjo-truncate-text class="eet-attachment-text">{{iDocument._fileName}}</kenjo-truncate-text></a>
                    </orgos-column>
                    <kenjo-icon orgosColumn="0" [size]="16" (click)="deleteAttachment(iDocument)" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757" [matTooltip]="pageTranslation.deleteAttachmentTooltip">cancel</kenjo-icon>
                  </orgos-column-container>
                </div>
              </div>
              <!-- ATTACHMENTS END HERE -->
            </div>

            <orgos-column-container class="kenjo-pt-30px" centerColumns="true">
              <orgos-column size="1"></orgos-column>
              <orgos-column size="0">
                <orgos-action (click)="addAttachment()" color="Primary" icon="attach_file" [matTooltip]="pageTranslation.attachFiles">{{pageTranslation.attachFiles}}</orgos-action>
              </orgos-column>
              <orgos-column size="0" class="kenjo-ml-20px" *ngIf="emailTemplate && emailTemplate.data">
                <orgos-button-raised
                  [disabled]="!nameValidation || nameValidation.isValid() === false || !subjectValidation || subjectValidation.isValid() === false || !bodyValidation || bodyValidation.isValid() === false || !collectionValidation || collectionValidation.isValid() === false || !categoryValidation || categoryValidation.isValid() === false || (emailTemplate.data.bodyType === 'rawText' && (!emailTemplate.data.bodyQuill || !emailTemplate.data.bodyQuill.html || emailTemplate.data.bodyQuill.html === ''))"
                  (click)="saveTemplate()"
                >
                  {{emailTemplate.data['_id'] ? pageTranslation.saveButtonEdit : pageTranslation.saveButton}}
                </orgos-button-raised>
              </orgos-column>
            </orgos-column-container>
          </orgos-container>
        </orgos-column>

        <orgos-column size="1" class="kenjo-ml-20px">
          <div class="kenjo-font-size-16px">{{pageTranslation.templateSettings}}</div>
          <orgos-input-select [model]="emailTemplate" field="category" picklist="emailTemplateCategory" class="kenjo-pt-20px kenjo-block" required="true" (validation)="categoryValidation = $event">
            <orgos-input-error *ngIf="categoryValidation && categoryValidation.getError('required')">{{pageTranslation.categoryRequired}}</orgos-input-error>
          </orgos-input-select>
          <orgos-input-select [model]="emailTemplate" field="companyId" [options]="companyOptions" class="kenjo-pt-10px kenjo-block" [enableClearButton]="true"></orgos-input-select>
          <orgos-input-select [model]="emailTemplate" field="language" picklist="emailTemplateLanguage" class="kenjo-pt-10px kenjo-block" [enableClearButton]="true"></orgos-input-select>
          <orgos-input-select [model]="emailTemplate" field="inCollection" [options]="collectionsOptions" required="true" (validation)="collectionValidation = $event" (modelChange)="selectCollection()" [readOnly]="emailTemplate.data['_id']" class="kenjo-pt-10px kenjo-block"></orgos-input-select>

          <orgos-column-container class="kenjo-pt-10px" centerColumns="true">
            <orgos-column size="1">
              <orgos-input-select [options]="fieldsOptions" [model]="fieldChosen" field="field" orgosColumn="1" (modelChange)="selectFieldValue()" class="kenjo-block"></orgos-input-select>
            </orgos-column>
            <orgos-column size="0" class="kenjo-ml-10px">
              <kenjo-icon [size]="16" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3" (click)="copyFieldToClipboard()">files_empty</kenjo-icon>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
      </orgos-column-container>
    </div>
  </div>
</ng-container>
