import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as check from 'check-types';

import { PrivateInternationalizationService } from '../../../private/services/private-internationalization.service';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';

@Injectable()
export class ErrorParserService {
  constructor(private injector: Injector) {}

  parseError(error: any, serviceName: string, operationName: string, internalErrorCode?: string): Promise<OrgosError> {
    return new Promise<OrgosError>((resolve) => {
      const orgosError: OrgosError = this.processError(error, serviceName, operationName);

      this.getMessage(serviceName, operationName, orgosError.errorCode, internalErrorCode)
        .then((message: string) => {
          orgosError.message = message;
          resolve(orgosError);
        })
        .catch((message: string) => {
          orgosError.reloadApp = true;
          orgosError.message = message;
          resolve(orgosError);
        });
    });
  }

  private processError(error: any, serviceName: string, operationName: string): OrgosError {
    if (check.not.assigned(error)) {
      return new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNKNOWN, serviceName, operationName);
    }

    if (check.instanceStrict(error, OrgosError)) {
      return <OrgosError>error;
    }

    if (check.instanceStrict(error, HttpErrorResponse)) {
      return this.processHttpErrorResponse(<HttpErrorResponse>error, serviceName, operationName);
    }

    if (check.instance(error, ErrorEvent)) {
      const orgosError = new OrgosError(error.error, ErrorCodes.UNKNOWN, serviceName, operationName);
      orgosError.originalError.errorEvent = {
        message: error.message,
        filename: error.filename,
        lineno: error.lineno,
        colno: error.colno
      };
      return orgosError;
    }

    return new OrgosError(error, ErrorCodes.UNKNOWN, serviceName, operationName);
  }

  private processHttpErrorResponse(error: HttpErrorResponse, serviceName: string, operationName: string): OrgosError {
    const knownError = Object.keys(ErrorCodes)
      .filter((iErrorCode: string) => {
        return iErrorCode === String(error.status);
      })
      .map((iErrorCode: string) => {
        const errorNumber = Number(iErrorCode);
        return new OrgosError(error, errorNumber, serviceName, operationName);
      })[0];

    return check.assigned(knownError) ? knownError : new OrgosError(error, ErrorCodes.UNKNOWN, serviceName, operationName);
  }

  private getMessage(serviceName: string, operationName: string, errorCode: ErrorCodes, internalErrorCode?: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.injector
        .get(PrivateInternationalizationService)
        .getAllTranslation('error-messages')
        .then((errorTranslations: any) => {
          const errorName = internalErrorCode || ErrorCodes[errorCode] || ErrorCodes[ErrorCodes.UNKNOWN];
          if (check.assigned(errorTranslations[serviceName]) && check.assigned(errorTranslations[serviceName][operationName]) && check.assigned(errorTranslations[serviceName][operationName][errorName])) {
            resolve(errorTranslations[serviceName][operationName][errorName]);
          } else if (check.assigned(errorTranslations[serviceName]) && check.assigned(errorTranslations[serviceName].defaultMessages) && check.assigned(errorTranslations[serviceName].defaultMessages[errorName])) {
            resolve(errorTranslations[serviceName].defaultMessages[errorName]);
          } else if (check.assigned(errorTranslations.defaultMessages[errorName])) {
            resolve(errorTranslations.defaultMessages[errorName]);
          } else {
            const UNKNOWN_ERROR = ErrorCodes[ErrorCodes.UNKNOWN];
            resolve(errorTranslations.defaultMessages[UNKNOWN_ERROR]);
          }
        })
        .catch(() => {
          reject('Kenjo is unavailable due to connection problems.');
        });
    });
  }
}
