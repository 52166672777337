<div class="kenjo-font-color-text-333333" *ngIf="!isLoading">
  <!-- Cycle dates -->
  <orgos-column-container>
    <orgos-column *ngIf="!status.disabledCycle"> {{ cycleStartDate | date: 'shortDate':'UTC' }} - {{ cycleEndDate | date: 'shortDate':'UTC' }} </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" *ngIf="displayScheduledAssignment" class="kenjo-mt-20px">
    <kenjo-icon orgosColumn="0" [size]="20" class="kenjo-mr-5px kenjo-font-color-success-00b72e dc-help-icon">info_outline</kenjo-icon>
    <orgos-column>{{ translations.scheduledAssignmentInfoMessage }}</orgos-column>
  </orgos-column-container>

  <!-- Accordion -->
  <orgos-column-container class="kenjo-mt-20px">
    <orgos-column size="1">
      <mat-accordion displayMode="flat" togglePosition="before" multi>
        <!-- Total Allowance -->
        <mat-expansion-panel class="sdcc-expansion-panel">
          <mat-expansion-panel-header class="sdcc-expansion-panel-header">
            <mat-panel-title>
              <orgos-column-container centerColumns="true" class="kenjo-font-size-16px">
                <orgos-column size="0" class="sdcc-field-title">
                  <orgos-column-container centerColumns="true" class="kenjo-font-size-16px">
                    <orgos-column size="1">
                      {{ translations.totalAllowance }}
                    </orgos-column>
                    <kenjo-icon orgosColumn="0" [size]="22" class="kenjo-font-color-light-text-757575">trending_flat</kenjo-icon>
                  </orgos-column-container>
                </orgos-column>
                <orgos-column size="0" class="sdcc-field-value">
                  {{ status.unlimitedAllowance ? translations.unlimited : statusValues.totalAllowance }}
                </orgos-column>
              </orgos-column-container>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Cycle Allowance -->
          <orgos-column-container centerColumns="true" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pv-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.cycleAllowance }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ status.unlimitedAllowance ? translations.unlimited : statusValues.cycleAllowance }}
            </orgos-column>
            <kenjo-icon orgosColumn="0" *ngIf="prorationTooltip" [size]="16" class="kenjo-ml-15px kenjo-cursor-pointer kenjo-font-color-success-00b72e" [matTooltip]="prorationTooltip">info_outline</kenjo-icon>
            <orgos-column size="0" *ngIf="status.cycleStatus !== TIME_OFF_STATUS_CYCLE_STATUS_FUTURE && ((status.adjustBalance && status.adjustBalance !== 0) || policy.accrualPeriod === ACCRUAL_PERIOD_MONTHLY)">
              <kenjo-adjustment-history-button [status]="status" field="cycleAllowanceHistory" [policy]="policy" [userWork]="userWork" [translations]="translations"></kenjo-adjustment-history-button>
            </orgos-column>
          </orgos-column-container>

          <!-- Extra allowance -->
          <orgos-column-container *ngIf="extraAllowanceValue" centerColumns="true" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pv-5px kenjo-mt-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.givenExtraAllowance }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ extraAllowanceValue }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-status-chip-container">
              <div class="sdcc-status-chip sdcc-chip-primary-color kenjo-ml-15px" *ngIf="workAnniversary">{{ translations.workAnniversary | i18nData: { amount: workAnniversary.value, date: (workAnniversary.date | date: 'MMMM d') } }}</div>
            </orgos-column>
          </orgos-column-container>

          <!-- Negative balance -->
          <orgos-column-container *ngIf="status.negativeStartingBalance < 0" centerColumns="true" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pv-5px kenjo-mt-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.negativeStartingBalance }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ statusValues.negativeStartingBalance }}
            </orgos-column>
          </orgos-column-container>

          <!-- Initial carry over -->
          <orgos-column-container *ngIf="carryOver && carryOver > 0" centerColumns="true" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pv-5px kenjo-mt-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.carryOver }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ statusValues.carryOver }}
            </orgos-column>
            <orgos-column size="0" *ngIf="status.adjustCarryOver && status.adjustCarryOver !== 0">
              <kenjo-adjustment-history-button [status]="status" field="adjustCarryOverHistory" [policy]="policy" [userWork]="userWork" [translations]="translations"></kenjo-adjustment-history-button>
            </orgos-column>
          </orgos-column-container>

          <!-- Expired carry over -->
          <orgos-column-container *ngIf="status.expiredCarryOver && status.cycleStatus !== TIME_OFF_STATUS_CYCLE_STATUS_FUTURE && status.expiredCarryOver !== 0" centerColumns="true" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pv-5px kenjo-mt-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.expiredCarryOver }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ statusValues.expiredCarryOver }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-status-chip-container">
              <div class="sdcc-status-chip sdcc-chip-danger-color kenjo-ml-15px" *ngIf="expireDateText">{{ translations.expiredOn | i18nData: { date: expireDateText } }}</div>
            </orgos-column>
          </orgos-column-container>

          <!-- Overtime Compensation -->
          <orgos-column-container *ngIf="status.givenCompensation && status.cycleStatus !== TIME_OFF_STATUS_CYCLE_STATUS_FUTURE && status.givenCompensation !== 0" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-mt-5px kenjo-pv-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.overtimeCompensation }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ statusValues.givenCompensation }}
            </orgos-column>
            <orgos-column size="0">
              <kenjo-adjustment-history-button [status]="status" field="givenCompensationHistory" [policy]="policy" [userWork]="userWork" [translations]="translations"></kenjo-adjustment-history-button>
            </orgos-column>
          </orgos-column-container>
        </mat-expansion-panel>

        <!-- Taken or Planned -->
        <mat-expansion-panel class="sdcc-expansion-panel kenjo-mt-10px">
          <mat-expansion-panel-header class="sdcc-expansion-panel-header">
            <mat-panel-title>
              <orgos-column-container centerColumns="true" class="kenjo-font-size-16px">
                <orgos-column size="0" class="sdcc-field-title">
                  <orgos-column-container centerColumns="true" class="kenjo-font-size-16px">
                    <orgos-column size="1">
                      {{ translations.totalTakenPlanned }}
                    </orgos-column>
                    <kenjo-icon orgosColumn="0" [size]="22" class="kenjo-font-color-light-text-757575">trending_flat</kenjo-icon>
                  </orgos-column-container>
                </orgos-column>
                <orgos-column size="0" class="sdcc-field-value">
                  {{ statusValues.totalTakenPlanned }}
                </orgos-column>
              </orgos-column-container>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-column-container centerColumns="true" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pv-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.taken }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ statusValues.taken }}
            </orgos-column>
            <orgos-column size="0" *ngIf="takenCarryOver > 0">
              <div class="sdcc-status-chip sdcc-chip-grey-color kenjo-ml-15px">{{ (takenCarryOver === 1 ? translations.takenCarryOverSingular : translations.takenCarryOverPlural) | i18nData: { amount: statusValues.takenCarryOver } }}</div>
            </orgos-column>
            <orgos-column size="0" *ngIf="status.adjustTaken && status.cycleStatus !== TIME_OFF_STATUS_CYCLE_STATUS_FUTURE && status.adjustTaken !== 0">
              <kenjo-adjustment-history-button [status]="status" field="adjustTakenHistory" [policy]="policy" [userWork]="userWork" [translations]="translations" [showTitle]="!takenCarryOver"></kenjo-adjustment-history-button>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container centerColumns="true" class="sdcc-field-row kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pv-5px kenjo-mt-5px">
            <orgos-column size="0" class="sdcc-field-title">
              {{ translations.planned }}
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value">
              {{ statusValues.planned }}
            </orgos-column>
            <orgos-column size="0" *ngIf="status.plannedCarryOver > 0">
              <div class="sdcc-status-chip sdcc-chip-grey-color kenjo-ml-15px">{{ (status.plannedCarryOver === 1 ? translations.plannedCarryOverSingular : translations.plannedCarryOverPlural ) | i18nData: { amount: statusValues.plannedCarryOver } }}</div>
            </orgos-column>
          </orgos-column-container>
        </mat-expansion-panel>

        <!-- Available -->
        <div class="sdcc-available-container kenjo-mt-10px">
          <orgos-column-container centerColumns="true" class="kenjo-font-size-16px kenjo-full-height">
            <orgos-column size="0" class="sdcc-field-title">
              <orgos-column-container centerColumns="true" class="kenjo-font-size-16px">
                <orgos-column size="1" class="kenjo-font-weight-bold">
                  {{ status._policyType === POLICY_TYPE_HOUR ? translations.availableHours : translations.availableDays }}
                </orgos-column>
                <kenjo-icon orgosColumn="0" [size]="22" class="kenjo-font-color-light-text-757575">trending_flat</kenjo-icon>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="0" class="sdcc-field-value kenjo-font-weight-bold">
              {{ status.unlimitedAllowance ? translations.unlimited : statusValues.available }}
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container *ngIf="status.carryOverEnabled && !status.hasExpiredCarryOver && (status.currentCarryOver > 0 || status.initialCarryOver || status.adjustCarryOver)" class="sdcc-carry-over-container kenjo-mt-15px kenjo-p-10px kenjo-font-color-light-text-757575">
            <orgos-column size="0" class="sdcc-carry-over-amount">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="sdcc-carry-over-calendar">
                  <kenjo-icon class="kenjo-font-color-primary-5993e3" [size]="14">calendar_today</kenjo-icon>
                </orgos-column>
                <orgos-column size="1" class="kenjo-font-color-primary-5993e3" *ngIf="status.currentCarryOver === 0">
                  {{ status._policyType === POLICY_TYPE_HOUR ? translations.noPendingCarryOverHours : translations.noPendingCarryOverDays }}
                </orgos-column>
                <orgos-column size="1" class="kenjo-font-color-primary-5993e3" *ngIf="status.currentCarryOver > 0">
                  <ng-container *ngIf="status.currentCarryOver <= status.available">
                    <span class="kenjo-font-weight-bold">{{ translations.pendingCarryOverAmount | i18nData: { pending: currentCarryOver, total: statusValues.available } }}</span>
                    <span> {{ status._policyType !== POLICY_TYPE_HOUR && (status.currentCarryOver === 1 || status.currentCarryOver === -1) ? translations.pendingCarryOverOneDayText : translations.pendingCarryOverText }}</span>
                  </ng-container>
                  <ng-container *ngIf="status.currentCarryOver > status.available">
                    <span class="kenjo-font-weight-bold">{{ statusValues.currentCarryOver }}</span>
                    <span> {{ translations.pendingCarryOverWithExpiredCarryOver }}</span>
                  </ng-container>
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="0" class="sdcc-status-chip-container" *ngIf="status.currentCarryOver > 0 && expireDateText">
              <div class="sdcc-status-chip sdcc-chip-warning-color kenjo-ml-15px">{{ translations.expiresOn | i18nData: { date: expireDateText } }}</div>
              <kenjo-icon *ngIf="status.currentCarryOver > status.available" [size]="16" class="kenjo-ml-10px kenjo-cursor-pointer kenjo-font-color-success-00b72e" matTooltip="{{ translations.availableWithExpiredCarryOver | i18nData: { available: statusValues.availableAfterExpireCarryOver } }}"
                >info_outline</kenjo-icon
              >
            </orgos-column>
          </orgos-column-container>
        </div>
      </mat-accordion>
    </orgos-column>
  </orgos-column-container>
</div>
