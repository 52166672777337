import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { IUserAccountModel } from '@app/models/user-account.model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { ChipV2Component } from '@app/standard/standalone-components/chip/chip.component';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Component({
  selector: 'kenjo-user-account-status-chip',
  templateUrl: 'user-account-status-chip.component.html',
  styleUrls: ['user-account-status-chip.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, IconModule, ChipV2Component],
})
export class UserAccountStatusChipComponent implements OnInit, OnChanges {
  @Input() userAccount: IUserAccountModel;

  private userAccountStatus: pickLists.USER_STATUS_LIST;
  private translations: Record<string, string> = {};

  chipType: 'primary' | 'secondary';
  chipSemantic: 'success' | 'warning' | 'neutral';
  chipText: string;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.initData();
    }
  }

  private async initData(): Promise<void> {
    try {
      await this.initTranslations();
      this.initUserAccountStatus();
      this.initChipAttributes();
    } catch {}
  }

  private async initTranslations(): Promise<void> {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('directory-page');
    } catch {
      this.translations = {};
    }
  }

  private initUserAccountStatus(): void {
    this.userAccountStatus = this.injector.get(UserAccountService).getUserAccountStatus(this.userAccount);
  }

  private initChipAttributes(): void {
    if (this.userAccountStatus === pickLists.USER_STATUS_ACTIVE) {
      this.chipType = 'primary';
      this.chipSemantic = 'success';
      this.chipText = this.translations.activeStatus;
      return;
    }

    if (this.userAccountStatus === pickLists.USER_STATUS_NOTACTIVATED) {
      this.chipType = 'primary';
      this.chipSemantic = 'warning';
      this.chipText = this.translations.notActivatedStatus;
      return;
    }

    if (this.userAccountStatus === pickLists.USER_STATUS_DEACTIVATED) {
      this.chipType = 'secondary';
      this.chipSemantic = 'neutral';
      this.chipText = this.translations.deactivatedStatus;
      return;
    }

    this.chipText = this.userAccountStatus;
  }
}
