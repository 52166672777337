<orgos-container *ngIf="!readOnly && !loadingBulk && pageTranslation" class="asd-dialog-box kenjo-p-40px">
  <!-- Header -->
  <orgos-column-container [centerColumns]="true" class="kenjo-mb-30px">
    <orgos-column-container *ngIf="!editMode" orgosColumn [centerColumns]="true" class="asd-employee-header">
      <div orgosColumn="0" class="kenjo-font-size-20px kenjo-pr-20px">{{ pageTranslation.addShiftTitle }}</div>
      <kenjo-shiftplan-weekly-picker
        *ngIf="dateRange"
        orgosColumn
        [states]="{ loading, dragging: false, dropping: false }"
        [blockPicker]="true"
        [weekRange]="dateRange"
        (valueChange)="handleWeek($event)"
      ></kenjo-shiftplan-weekly-picker>
    </orgos-column-container>
    <orgos-column *ngIf="editMode" class="asd-employee-header">
      <div class="kenjo-font-size-20px">{{pageTranslation.editTitle}}</div>
    </orgos-column>
    <div
      *ngIf="editMode"
      orgosColumn="0"
      class="asd-icon-container kenjo-cursor-pointer"
      [class.container-disabled]="loading || actionPermissions.delete === false"
    >
      <kenjo-icon
        [size]="24"
        (click)="removeShift()"
        class="kenjo-font-color-danger-ff5757"
        [class.kenjo-font-color-disabled-c4c4c4]="loading || actionPermissions.delete === false"
        [matTooltip]="actionPermissions.delete ? pageTranslation.deleteTooltip : pageTranslation.noPermissionsTooltip"
        [matTooltipPosition]="'left'"
        >delete</kenjo-icon
      >
    </div>
    <kenjo-icon
      orgosColumn="0"
      [size]="24"
      (click)="closeDialog()"
      class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      [disabled]="loading"
      >clear</kenjo-icon
    >
  </orgos-column-container>

  <!-- Banner errors -->
  <orgos-column-container *ngIf="!loading && roles?.length === 0" class="kenjo-p-10px asd-error-area" centerColumns="true">
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-danger-ff5757">error_outline</kenjo-icon>
    <div class="kenjo-ml-10px" orgosColumn>
      {{pageTranslation.oneRoleRequired}}&nbsp;<a (click)="navigateTo(SHIFT_PLAN_SETTINGS_ROLES_PATH)">{{pageTranslation.settingsLink}}</a>
    </div>
  </orgos-column-container>
  <orgos-column-container
    *ngIf="!loading && locations?.length === 0"
    class="kenjo-p-10px kenjo-mt-10px asd-error-area"
    centerColumns="true"
  >
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-danger-ff5757">error_outline</kenjo-icon>
    <div class="kenjo-ml-10px" orgosColumn>
      {{pageTranslation.oneLocationRequired}}&nbsp;<a (click)="navigateToOfficeLocation();$event.stopPropagation()"
        >{{pageTranslation.settingsLink}}</a
      >
    </div>
  </orgos-column-container>

  <!-- Advanced options -->
  <ng-container *ngIf="!editMode">
    <div class="kenjo-mt-30px asd-repeat-on">{{pageTranslation.repeatOn}}</div>
    <orgos-column-container centerColumns="true" [class.asd-opacity-disabled]="isDialogDisabled">
      <orgos-column>
        <orgos-working-day-circle
          class="kenjo-mr-10px"
          *ngFor="let day of weekDayLabels; let i = index"
          [label]="day"
          [(value)]="selectedWeekDays[i]"
          [readOnly]="isDialogDisabled || loading"
          (valueChange)="filterEmployees(); calculateDateRangesToCreate()"
        ></orgos-working-day-circle>
        <orgos-input-error *ngIf="!isSomeDaySelected" class="kenjo-pt-5px"> {{pageTranslation.dayRequired}} </orgos-input-error>
      </orgos-column>
      <orgos-column>
        <orgos-column-container centerColumns="true">
          <mat-slide-toggle
            [disabled]="!isSomeDaySelected"
            orgosColumn="0"
            [checked]="viewAdvancedOptions"
            (change)="showAdvancedOptions($event.checked)"
          ></mat-slide-toggle>
          <div orgosColumn class="kenjo-font-size-14px kenjo-pl-10px">{{ pageTranslation.advancedOptions }}</div>
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container
      *ngIf="viewAdvancedOptions && isSomeDaySelected"
      class="kenjo-mt-30px"
      [class.asd-opacity-disabled]="isDialogDisabled"
    >
      <orgos-input-simple-select
        orgosColumn
        class="kenjo-pr-20px kenjo-font-size-14px"
        panelClass="kenjo-mt-40px"
        required="true"
        [label]="pageTranslation.frequency"
        [value]="1"
        [autoOrder]="false"
        [options]="frequencyOptions"
        (valueChange)="frequencySelected = $event; calculateDateRangesToCreate()"
        [optionsDisabled]="loading"
      >
      </orgos-input-simple-select>
      <orgos-input-simple-date-picker
        orgosColumn
        [class.asd-error-date]="repeatEndDateValidation?.getError('required')"
        class="kenjo-pl-20px asd-repeat-end-date-picker"
        required="true"
        [label]="pageTranslation.endsOn"
        [(value)]="repeatEndDate"
        [enableClearButton]="true"
        (valueChange)="repeatEndDate = $event; checkAdvancedOptions()"
        [min]="minDayToCreate"
        [max]="maxDayToCreate"
        (validation)="repeatEndDateValidation = $event; checkAdvancedOptions()"
      >
        <orgos-input-error *ngIf="repeatEndDateValidation?.getError('required')">{{pageTranslation.endDateRequired}} </orgos-input-error>
      </orgos-input-simple-date-picker>
    </orgos-column-container>
  </ng-container>

  <!-- Date -->
  <ng-container *ngIf="editMode">
    <orgos-warning-message
      *ngIf="shiftData?.status === SHIFT_PLAN_CARD_STATUS_PUBLISHED && !isOpenShift && shift?.data?.employees?.length > 0"
    >
      {{pageTranslation.publishedShiftWarning}}</orgos-warning-message
    >
    <orgos-column-container>
      <orgos-input-date-picker
        orgosColumn
        [label]="pageTranslation.date"
        [model]="shift"
        field="date"
        required="true"
        [readOnly]="isDialogDisabled || loading"
        (validation)="dateValidation = $event"
        (modelChange)="filterEmployees(); setEditTouched()"
      ></orgos-input-date-picker>
      <orgos-input-error *ngIf="dateValidation && dateValidation.getError('required')">{{pageTranslation.dayRequired}} </orgos-input-error>
    </orgos-column-container>
  </ng-container>

  <!-- Start,end & break time -->
  <orgos-column-container class="kenjo-pt-20px" [class.asd-opacity-disabled]="isDialogDisabled">
    <orgos-input-simple-time
      orgosColumn
      class="kenjo-mr-10px"
      [label]="pageTranslation.startTime"
      required="true"
      [(value)]="shift.data.start"
      (valueChange)="calculateShiftDuration(); checkShiftReady(); setEditTouched()"
      [showClock]="true"
      [forceError]="hasDurationMinErrors || hasDurationMaxErrors"
      (validation)="startValidation = $event; checkShiftReady()"
      (blurTimeInput)="startTouched = true"
      [readOnly]="isDialogDisabled || loading"
    >
    </orgos-input-simple-time>
    <orgos-input-simple-time
      orgosColumn
      class="kenjo-mr-10px kenjo-ml-10px"
      [label]="pageTranslation.endTime"
      required="true"
      [(value)]="shift.data.end"
      (valueChange)="calculateShiftDuration(); checkShiftReady(); setEditTouched()"
      [showClock]="true"
      [forceError]="hasDurationMinErrors || hasDurationMaxErrors"
      (validation)="endValidation = $event; checkShiftReady()"
      (blurTimeInput)="endTouched = true"
      [readOnly]="isDialogDisabled || loading"
    >
    </orgos-input-simple-time>

    <orgos-input-simple-time
      orgosColumn
      class="kenjo-ml-10px"
      [label]="pageTranslation.break"
      [(value)]="shift.data.break"
      (valueChange)="calculateShiftDuration(); checkShiftReady(); setEditTouched()"
      [showClock]="true"
      [forceError]="hasBreakErrors"
      [readOnly]="isDialogDisabled || loading"
    >
    </orgos-input-simple-time>
  </orgos-column-container>
  <orgos-column-container [class.asd-opacity-disabled]="isDialogDisabled">
    <orgos-column class="kenjo-mr-10px">
      <orgos-input-error *ngIf="startTouched && startValidation && startValidation.getError('required')">
        {{pageTranslation.startRequired}}</orgos-input-error
      >
    </orgos-column>
    <orgos-column class="kenjo-mr-10px kenjo-ml-10px">
      <orgos-input-error *ngIf="endTouched && endValidation && endValidation.getError('required')">
        {{pageTranslation.endRequired}}</orgos-input-error
      >
      <ng-container *ngIf="hasDurationMinErrors || hasDurationMaxErrors">
        <orgos-input-error *ngIf="hasDurationMinErrors">{{pageTranslation.minimumShiftDuration}} </orgos-input-error>
        <orgos-input-error *ngIf="hasDurationMaxErrors">{{pageTranslation.maximumShiftDuration}} </orgos-input-error>
      </ng-container>
    </orgos-column>
    <orgos-column class="kenjo-ml-10px">
      <orgos-input-error *ngIf="hasBreakErrors"> {{pageTranslation.maximumBreakDuration}} </orgos-input-error>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-30px" [class.asd-opacity-disabled]="isDialogDisabled">
    <div orgosColumn="0" class="kenjo-font-color-light-text-757575">
      {{breaktimeDeduction ? (pageTranslation.totalDurationIncludingBreaks | uppercase ) : (pageTranslation.totalDurationExcludingBreaks |
      uppercase)}}
    </div>
    <div orgosColumn="0" class="kenjo-ml-10px">
      {{shiftDuration.hours}}{{pageTranslation.hour}} {{shiftDuration.minutes}}{{pageTranslation.minutes}}
    </div>
  </orgos-column-container>

  <!-- Location & working area -->
  <orgos-column-container class="kenjo-mt-30px" [class.asd-opacity-disabled]="isDialogDisabled">
    <orgos-input-select
      (modelChange)="filterEmployees(); setEditTouched()"
      (validation)="locationValidation = $event; checkShiftReady()"
      (currentValue)="shift.data.locationId = $event; filterEmployees()"
      class="kenjo-pr-10px"
      required="true"
      orgosColumn
      [label]="pageTranslation.location"
      [model]="shift"
      field="locationId"
      [enableClearButton]="true"
      [options]="locations"
      [sortOptions]="false"
      [readOnly]="isDialogDisabled || loading"
      [id]="'asd-location-id'"
    >
      <orgos-input-error>{{pageTranslation.locationRequired}}</orgos-input-error>
    </orgos-input-select>
    <orgos-input-select
      (modelChange)="filterEmployees(); setEditTouched()"
      class="kenjo-pl-10px"
      orgosColumn
      [label]="pageTranslation.workingArea"
      [model]="shift"
      field="workingAreaId"
      [enableClearButton]="true"
      [options]="workingAreas"
      [sortOptions]="false"
      [readOnly]="isDialogDisabled || loading"
      [id]="'asd-working-area-id'"
    ></orgos-input-select>
  </orgos-column-container>

  <!-- Role & tag -->
  <orgos-column-container class="kenjo-mt-20px" [class.asd-opacity-disabled]="isDialogDisabled">
    <orgos-input-select
      (modelChange)="filterEmployees(); setEditTouched()"
      (validation)="roleValidation = $event; checkShiftReady()"
      (currentValue)="shift.data.roleId = $event; filterEmployees()"
      class="kenjo-pr-10px"
      required="true"
      orgosColumn
      [label]="pageTranslation.role"
      [model]="shift"
      field="roleId"
      [enableClearButton]="true"
      [options]="roles"
      [sortOptions]="false"
      [readOnly]="isDialogDisabled || loading"
      [id]="'asd-role-id'"
    >
      <orgos-input-error>{{pageTranslation.roleRequired}}</orgos-input-error>
    </orgos-input-select>
    <orgos-input-select
      (modelChange)="filterEmployees(); setEditTouched()"
      class="kenjo-pl-10px"
      orgosColumn
      [label]="pageTranslation.tag"
      [model]="shift"
      field="tagId"
      [enableClearButton]="true"
      [options]="tags"
      [sortOptions]="false"
      [readOnly]="isDialogDisabled || loading"
      [id]="'asd-tag-id'"
    ></orgos-input-select>
  </orgos-column-container>

  <!-- Approval rule edit -->
  <orgos-column-container *ngIf="editMode" class="kenjo-mt-20px" [class.asd-opacity-disabled]="!isOpenShift" [centerColumns]="true">
    <orgos-column-container orgosColumn [centerColumns]="true">
      <kenjo-icon orgosColumn="0" class="kenjo-mr-20px" [size]="20">people</kenjo-icon>
      <orgos-column>
        <div class="kenjo-font-size-16px">
          {{ !shift.data?.applicants?.length || shift.data?.applicants?.length === 0 ? pageTranslation.notAssignedYet :
          (pageTranslation.interestedEmployees | i18nData: { applicantsNumber: shift.data?.applicants?.length }) }}
        </div>
      </orgos-column>
    </orgos-column-container>
    <orgos-input-simple-select
      orgosColumn
      [readOnly]="!isOpenShift"
      class="kenjo-pl-10px"
      [required]="true"
      [label]="pageTranslation.openShiftAssignment"
      [value]="approvalRuleSelected"
      [autoOrder]="false"
      [options]="approvalRuleOptions"
      (valueChange)="approvalRuleSelected = $event; checkShiftReady(); editDataTouched = true"
      [optionsDisabled]="loading"
    >
    </orgos-input-simple-select>
  </orgos-column-container>

  <!-- Employees -->
  <orgos-column-container *ngIf="!editMode" class="kenjo-mt-20px" [class.asd-opacity-disabled]="isDialogDisabled">
    <orgos-column>
      <orgos-input-shiftplan-search-user
        [employeeSchedules]="employeesWorkSchedule"
        [users]="everyoneElse"
        [matchingUsers]="filteredEmployees"
        [(selectedEmployees)]="shift.data.employees"
        [defaultEmployeeId]="defaultEmployeeId"
        (selectedEmployeesChange)="checkShiftReady()"
        (openShift)="isOpenShift = $event; checkShiftReady()"
        (maxEmployees)="maxEmployeesReached = $event; checkShiftReady()"
        (onBlur)="employeeTouched = true"
        *ngIf="!loading && filteredEmployees && isEmployeeDataLoaded"
        [label]="pageTranslation.employee + ' *'"
        [readOnly]="isDialogDisabled || loading"
        [pageTranslation]="pageTranslation"
        class="kenjo-block"
        [notMatchedEntities]="notMatchedEntitiesMap"
        [componentIds]="shiftplanUserSearchIds"
        [selectedData]="shift.data"
        [isAssignShift]="isAssignShift"
      >
      </orgos-input-shiftplan-search-user>
      <orgos-input-error *ngIf="employeeTouched && shift.data.employees.length === 0 && !isOpenShift">
        {{pageTranslation.employeeRequired}}</orgos-input-error
      >
      <orgos-input-error *ngIf="employeeTouched && maxEmployeesReached" class="kenjo-pt-10px">
        {{pageTranslation.maxEmployeesError}}</orgos-input-error
      >
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container *ngIf="editMode" class="kenjo-mt-20px">
    <orgos-column>
      <orgos-input-shiftplan-search-user
        [editMode]="editMode"
        [employeeSchedules]="employeesWorkSchedule"
        [isSameWeek]="sameWeekEdit"
        [users]="everyoneElse"
        [matchingUsers]="filteredEmployees"
        [(selectedEmployees)]="shift.data.employees"
        [defaultEmployeeId]="defaultEmployeeId"
        (selectedEmployeesChange)="checkShiftReady(); setEditTouched(); setSelectEmployee(false)"
        (openShift)="isOpenShift = $event; checkShiftReady()"
        (onBlur)="employeeTouched = true"
        *ngIf="!loading && filteredEmployees && isEmployeeDataLoaded"
        [pageTranslation]="pageTranslation"
        [label]="pageTranslation.employee + ' *'"
        class="kenjo-block"
        [notMatchedEntities]="notMatchedEntitiesMap"
        [componentIds]="shiftplanUserSearchIds"
        [readOnly]="loading"
        [selectedData]="shift.data"
        [isAssignShift]="isAssignShift"
      >
      </orgos-input-shiftplan-search-user>
      <orgos-input-error *ngIf="employeeTouched && shift.data.employees.length === 0 && !isOpenShift">
        {{pageTranslation.employeeRequired}}
      </orgos-input-error>
    </orgos-column>
  </orgos-column-container>

  <!-- Slots & approval rule from create -->
  <orgos-column-container *ngIf="isOpenShift && !editMode" class="kenjo-mt-20px" [class.asd-opacity-disabled]="isDialogDisabled">
    <orgos-column-container orgosColumn class="kenjo-pr-10px">
      <orgos-input-simple-number
        class="kenjo-block"
        orgosColumn="0"
        [label]="pageTranslation.slots"
        [step]="1"
        [min]="1"
        [max]="10"
        [required]="true"
        [(value)]="shift.data.slots"
        [keyboardOnly]="true"
        [isDeletionAllowed]="false"
        (valueChange)="checkShiftReady()"
        [readOnly]="isDialogDisabled || loading"
        [id]="'asd-slots'"
      >
      </orgos-input-simple-number>
      <orgos-column></orgos-column>
    </orgos-column-container>

    <orgos-input-simple-select
      orgosColumn
      class="kenjo-pl-10px"
      [required]="true"
      [label]="pageTranslation.openShiftAssignment"
      [value]="approvalRuleSelected"
      [autoOrder]="false"
      [options]="approvalRuleOptions"
      (valueChange)="approvalRuleSelected = $event; checkShiftReady()"
      [optionsDisabled]="loading"
    >
    </orgos-input-simple-select>
  </orgos-column-container>

  <orgos-column-container [class.asd-opacity-disabled]="isDialogDisabled">
    <orgos-input-text-area
      orgosColumn
      rows="2"
      [model]="shift"
      field="notes"
      (modelChange)="setEditTouched()"
      [label]="pageTranslation.notes"
      [placeholder]="pageTranslation.notesPlaceholder"
      [readOnly]="isDialogDisabled || loading"
      [id]="'asd-notes'"
    ></orgos-input-text-area>
  </orgos-column-container>

  <kenjo-shiftplan-notification-toggle
    *ngIf="editMode && shiftData?.status === SHIFT_PLAN_CARD_STATUS_PUBLISHED && !isOpenShift && shift?.data?.employees?.length > 0"
    [parentTranslation]="pageTranslation"
    [canSendNotifications]="canSendNotifications"
    [loading]="loading"
    (toggleValue)="notificationToggle = $event"
  ></kenjo-shiftplan-notification-toggle>

  <orgos-column-container *ngIf="loading" class="asd-loading-container" centerColumns="true">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <div orgosColumn="0" class="kenjo-pl-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">
      {{ pageTranslation.saving | uppercase }}
    </div>
  </orgos-column-container>

  <orgos-column-container *ngIf="!loading" class="kenjo-mt-60px" centerColumns="true">
    <orgos-column-container orgosColumn="0" *ngIf="isOpenShift && currentOwner" size="0" [centerColumns]="true">
      <orgos-column size="0" class="kenjo-mr-10px">
        <orgos-avatar [photoUrl]="currentOwner._photo?._url" [avatarName]="currentOwner.displayName" size="small"></orgos-avatar>
      </orgos-column>
      <orgos-column size="0">
        <div class="kenjo-font-size-12px">{{ pageTranslation.currentOwner }}</div>
        <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px">{{ currentOwner?.displayName }}</div>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container orgosColumn centerColumns="true">
      <div orgosColumn="3" *ngIf="shiftMaxReached" class="kenjo-font-color-danger-ff5757">
        {{pageTranslation.shiftsMaxNumberReachedError}}
      </div>
      <orgos-column size="2"></orgos-column>
    </orgos-column-container>
    <orgos-column size="0">
      <orgos-button-raised [id]="'asd-back-button'" [disabled]="loading" (click)="closeDialog()" color="Neutral">
        {{pageTranslation.backButton}}
      </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised
        [id]="'asd-save-button'"
        *ngIf="!editMode && !isDialogDisabled"
        [disabled]="!isShiftReady || loading"
        (click)="createShift()"
        color="Success"
      >
        {{pageTranslation.saveButton}}
      </orgos-button-raised>
      <orgos-button-raised
        [id]="'asd-edit-button'"
        *ngIf="editMode"
        [disabled]="(!(isShiftReady && editDataTouched) || loading)"
        (click)="editShift()"
        color="Success"
      >
        {{pageTranslation.saveButton}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-container>

<!-- READ ONLY DIALOG -->
<orgos-container aspect="card" class="asd-dialog-box kenjo-p-40px" *ngIf="readOnly && pageTranslation && shiftplanPermissions">
  <orgos-column-container centerColumns="true">
    <orgos-column *ngIf="shiftData.employeeId" class="asd-employee-header">
      <orgos-avatar
        *ngIf="shiftData.employee"
        [photoUrl]="shiftData.employee._photo?._url"
        [avatarName]="shiftData.employee.displayName"
        size="small"
      ></orgos-avatar>
      <div class="kenjo-ml-10px kenjo-font-size-20px">{{shiftData.employee.displayName}}</div>
    </orgos-column>
    <orgos-column *ngIf="!shiftData.employeeId" class="asd-employee-header">
      <div class="kenjo-font-size-20px">{{pageTranslation.openShift}}</div>
    </orgos-column>

    <div
      class="asd-icon-container kenjo-cursor-pointer"
      [class.container-disabled]="loading || actionPermissions.edit === false"
      orgosColumn="0"
    >
      <kenjo-icon
        [size]="24"
        (click)="initEditModeData();"
        class="kenjo-font-color-primary-5993e3"
        [class.kenjo-font-color-disabled-c4c4c4]="loading || actionPermissions.edit === false"
        [matTooltip]="actionPermissions.edit ? pageTranslation.editTooltip : pageTranslation.noPermissionsTooltip"
        [matTooltipPosition]="'left'"
        >mode_edit
      </kenjo-icon>
    </div>
    <div
      class="asd-icon-container kenjo-cursor-pointer"
      [class.container-disabled]="loading || actionPermissions.delete === false"
      orgosColumn="0"
    >
      <kenjo-icon
        [size]="24"
        (click)="removeShift()"
        class="kenjo-font-color-danger-ff5757"
        [class.kenjo-font-color-disabled-c4c4c4]="loading || actionPermissions.delete === false"
        [matTooltip]="actionPermissions.delete ? pageTranslation.deleteTooltip : pageTranslation.noPermissionsTooltip"
        [matTooltipPosition]="'left'"
        >delete</kenjo-icon
      >
    </div>
    <kenjo-icon orgosColumn="0" [size]="24" (click)="dialogRef.close()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      >clear</kenjo-icon
    >
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-40px">
    <orgos-input-simple-text
      orgosColumn
      [label]="pageTranslation.date"
      [readOnly]="true"
      [value]="shiftData.date | date: 'd MMM y': 'UTC'"
    ></orgos-input-simple-text>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-20px">
    <orgos-input-simple-time
      orgosColumn
      [label]="pageTranslation.startTime"
      [readOnly]="true"
      [value]="shift.data.start"
      [showClock]="true"
      class="kenjo-mr-10px"
    >
    </orgos-input-simple-time>
    <orgos-input-simple-time
      orgosColumn
      [label]="pageTranslation.endTime"
      [readOnly]="true"
      [value]="shift.data.end"
      [showClock]="true"
      class="kenjo-mr-10px"
    >
    </orgos-input-simple-time>
    <orgos-input-simple-time
      orgosColumn
      [label]="pageTranslation.break"
      [readOnly]="true"
      [value]="shift.data.break ? shift.data.break : 0"
      [showClock]="true"
      class="kenjo-mr-10px"
    >
    </orgos-input-simple-time>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-30px">
    <div orgosColumn="0" class="kenjo-font-color-light-text-757575">
      {{breaktimeDeduction ? (pageTranslation.totalDurationIncludingBreaks | uppercase ) : (pageTranslation.totalDurationExcludingBreaks |
      uppercase)}}
    </div>
    <div orgosColumn="0" class="kenjo-ml-10px">
      {{shiftDuration.hours}}{{pageTranslation.hour}} {{shiftDuration.minutes}}{{pageTranslation.minutes}}
    </div>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-40px">
    <orgos-input-simple-text
      orgosColumn
      [label]="pageTranslation.location"
      [readOnly]="true"
      [value]="shiftData.location.name"
    ></orgos-input-simple-text>
    <orgos-input-simple-text
      orgosColumn
      [label]="pageTranslation.workingArea"
      [readOnly]="true"
      class="kenjo-pl-20px"
      [value]="shiftData.workingArea ? shiftData.workingArea.name : ' '"
    ></orgos-input-simple-text>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-20px">
    <orgos-input-simple-text orgosColumn [label]="pageTranslation.role" [readOnly]="true" [value]="shiftData.role.name">
    </orgos-input-simple-text>
    <orgos-input-simple-text
      orgosColumn
      [label]="pageTranslation.tag"
      class="kenjo-pl-20px"
      [readOnly]="true"
      [value]="shiftData.tag ? shiftData.tag.name : ' '"
    ></orgos-input-simple-text>
  </orgos-column-container>

  <orgos-column-container *ngIf="isOpenShift" class="kenjo-mt-20px kenjo-font-color-light-text-757575">
    <orgos-column-container orgosColumn [centerColumns]="true">
      <kenjo-icon orgosColumn="0" class="kenjo-mr-20px" [size]="20">people</kenjo-icon>
      <orgos-column>
        <div class="kenjo-font-size-16px">
          {{ !shiftData.applicants?.length || shiftData.applicants?.length === 0 ? pageTranslation.notAssignedYet :
          (pageTranslation.interestedEmployees | i18nData: { applicantsNumber: shiftData.applicants?.length }) }}
        </div>
        <orgos-action
          [disabled]="actionPermissions.edit === false"
          [upercase]="false"
          (click)="initEditModeData(); setSelectEmployee(true)"
        >
          <span class="kenjo-font-size-12px">{{ pageTranslation.assignShift }}</span>
        </orgos-action>
      </orgos-column>
    </orgos-column-container>
    <orgos-input-simple-text
      orgosColumn
      [label]="pageTranslation.openShiftAssignment"
      class="kenjo-pl-20px"
      [readOnly]="true"
      [value]="pageTranslation[approvalRuleSelected]"
    ></orgos-input-simple-text>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-40px">
    <mat-form-field orgosColumn>
      <mat-label>{{pageTranslation.notes}}</mat-label>
      <textarea class="kenjo-font-color-text-333333" matInput [ngModel]="shiftData.notes" readonly disabled></textarea>
    </mat-form-field>
  </orgos-column-container>

  <orgos-column-container [class.kenjo-mt-60px]="true">
    <!-- Current owner info -->
    <orgos-column-container orgosColumn="0" *ngIf="isOpenShift && currentOwner" size="0" [centerColumns]="true">
      <orgos-column size="0" class="kenjo-mr-10px">
        <orgos-avatar [photoUrl]="currentOwner._photo?._url" [avatarName]="currentOwner.displayName" size="small"></orgos-avatar>
      </orgos-column>
      <orgos-column size="0">
        <div class="kenjo-font-size-12px">{{ pageTranslation.currentOwner }}</div>
        <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px">{{ currentOwner?.displayName }}</div>
      </orgos-column>
    </orgos-column-container>

    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="dialogRef.close()" color="Neutral">{{pageTranslation.backButton}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-container>

<orgos-container *ngIf="loadingBulk && !editMode && viewAdvancedOptions && areAdvancedOptionsValid" class="asd-loading-box kenjo-p-40px">
  <orgos-column-container [centerColumns]="true"
    >>
    <orgos-column></orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" (click)="closeDialogLoading()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      >clear</kenjo-icon
    >
  </orgos-column-container>
  <div class="asd-loading-content">
    <orgos-loading-spinner [size]="'Large'" [color]="'Success'"></orgos-loading-spinner>
    <div class="asd-loading-text">
      <div class="kenjo-pt-20px kenjo-font-size-30px kenjo-font-color-light-text-757575">
        {{pageTranslation.preparingBulkCreationTitle}}
      </div>
      <div class="kenjo-pt-10px kenjo-font-size-18px kenjo-font-color-light-text-757575 kenjo-text-align-center">
        {{pageTranslation.preparingBulkCreationSubtitle}}
      </div>
    </div>
  </div>
</orgos-container>
