import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftplanPublicHolidaysService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan/public-holidays`;
  private SHIFTPLAN_PUBLIC_HOLIDAY_SERVICE: string = 'ShiftplanPublicHolidaysService';

  constructor(private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  async getPublicHoliday(data): Promise<IPublicHolidayList> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_PUBLIC_HOLIDAY_SERVICE, 'getPublicHoliday');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.post(`${this.MICROSERVICE_URL}`, data, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_PUBLIC_HOLIDAY_SERVICE, 'getPublicHoliday');
    }
  }
}
export interface IPublicHolidays {
  calendar?: string;
  holidayName?: string;
}

export interface IDropdownCalendar {
  _id: string;
  name: string;
  isOffice: boolean;
  isSelected: boolean;
}

export interface IPublicHolidayList {
  dropdownCalendarList?: Array<IDropdownCalendar>;
  publicHolidays?: Array<Array<IPublicHolidays>>;
}
