<div>
  <div class="pwd-close-icon-container" *ngIf="showCloseDialogButton">
    <kenjo-icon [size]="24" class="pwd-close-button kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"
      >clear</kenjo-icon
    >
  </div>

  <div *ngIf="pageTranslations" class="pwd-main-container kenjo-p-40px">
    <mat-icon class="kenjo-font-color-warning-ffc107 pwd-icon">upgrade_filled</mat-icon>

    <div class="kenjo-font-color-text-333333 kenjo-pt-20px kenjo-font-size-16px kenjo-font-weight-bold">
      <ng-template [ngIf]="!paywallOptions?.featureId || !pageTranslations[paywallOptions.featureId]?.title" [ngIfElse]="specificTitle">
        {{ pageTranslations.defaultTitle }}
      </ng-template>

      <ng-template #specificTitle> {{ pageTranslations[paywallOptions.featureId].title }} </ng-template>
    </div>

    <div class="kenjo-font-color-text-333333 kenjo-pt-10px kenjo-font-size-12px">
      {{ pageTranslations.paywallWhistleblowerDescription }}
      <ng-template
        [ngIf]="!paywallOptions?.featureId || !pageTranslations[paywallOptions.featureId]?.description"
        [ngIfElse]="specificDescription"
      >
        {{ pageTranslations.defaultDescription }}
      </ng-template>

      <ng-template #specificDescription> {{ pageTranslations[paywallOptions.featureId].description }} </ng-template>
    </div>

    <div class="kenjo-pt-20px" *ngIf="paywallOptions?.featureId && pageTranslations[paywallOptions.featureId]?.bulletPoints">
      <div
        class="pwd-bullet-point-container"
        *ngFor="let bulletPoint of pageTranslations[paywallOptions.featureId].bulletPoints | keyvalue; let first = first;"
        [ngClass]="{ 'kenjo-pt-5px': !first }"
      >
        <kenjo-icon [size]="18" class="kenjo-font-color-light-text-757575 kenjo-mr-5px">done</kenjo-icon>
        <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px pwd-bullet-point-text">
          {{ pageTranslations[paywallOptions.featureId]?.bulletPoints[bulletPoint.key] }}
        </div>
      </div>
    </div>

    <div class="kenjo-pt-20px" *ngIf="canAccessBillingPage">
      <kenjo-button-flat (click)="navigateToBilling()" class="pwd-billing-button">
        {{ pageTranslations.seePlansButton }}
      </kenjo-button-flat>
    </div>

    <div class="kenjo-pt-10px" *ngIf="pageTranslations[paywallOptions.featureId]?.requestFeature">
      <kenjo-button-flat
        color="Secondary"
        (click)="openRequestFeature(pageTranslations[paywallOptions.featureId]?.requestFeature?.header, pageTranslations[paywallOptions.featureId]?.requestFeature?.body)"
        class="pwd-billing-button"
      >
        {{ pageTranslations.requestFeatureButton }}
      </kenjo-button-flat>
    </div>
  </div>
</div>
