import { IUserWorkScheduleModel } from '@app/standard/services/user/user-work-schedule.service';
import * as check from 'check-types';
import * as moment from 'moment';

export const getCurrentWorkScheduleFromHistory = (currentDay: Date | moment.Moment, userWorkSchedule: IUserWorkScheduleModel) => {
  if (!userWorkSchedule.history?.length) {
    return false;
  }
  const today = moment.utc(currentDay).endOf('day');
  const currentUserWorkSchedule = userWorkSchedule.history.find((iWorkSchedule, i) => {
    return (
      moment.utc(iWorkSchedule.startDate).isSameOrBefore(today) &&
      (check.not.assigned(userWorkSchedule.history[i + 1]) || moment.utc(userWorkSchedule.history[i + 1].startDate).isAfter(today))
    );
  });
  return currentUserWorkSchedule;
};

export const calculateHoursAndMinutes = (minutes: number) => {
  const duration = moment.duration(minutes, 'minutes');
  const hours = duration.hours();
  const remainingMinutes = duration.minutes();
  return moment().hours(hours).minutes(remainingMinutes).format('HH:mm');
};

export const formatWorkSchedule = (fixedWorkSchedule: any, userWorkSchedule: IUserWorkScheduleModel) => {
  if (!userWorkSchedule) {
    return;
  }
  if (fixedWorkSchedule?.dayShifts?.length) {
    userWorkSchedule.mondayWorkingDay = fixedWorkSchedule.dayShifts[0]?.minutes ?? false;
    userWorkSchedule.tuesdayWorkingDay = fixedWorkSchedule.dayShifts[1]?.minutes ?? false;
    userWorkSchedule.wednesdayWorkingDay = fixedWorkSchedule.dayShifts[2]?.minutes ?? false;
    userWorkSchedule.thursdayWorkingDay = fixedWorkSchedule.dayShifts[3]?.minutes ?? false;
    userWorkSchedule.fridayWorkingDay = fixedWorkSchedule.dayShifts[4]?.minutes ?? false;
    userWorkSchedule.saturdayWorkingDay = fixedWorkSchedule.dayShifts[5]?.minutes ?? false;
    userWorkSchedule.sundayWorkingDay = fixedWorkSchedule.dayShifts[6]?.minutes ?? false;
    userWorkSchedule.startDate = fixedWorkSchedule.startDate;
  }
  return userWorkSchedule;
};