<orgos-widget titleKey="whoAway" [loadingWidget]="loadingWidget" [existingData]="awayRequests && awayRequests.length > 0" [footerHeight]="!noRequestsToShow ? 85 : 0">
  <orgos-widget-header-action>
    <orgos-action *ngIf="configurationKeys && configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" (click)="openDetailsDialog()">{{ translation.personalizeButton }}</orgos-action>
    <orgos-action *ngIf="configurationKeys && !configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" (click)="openDetailsDialog()">{{ translation.moreInfoButton }}</orgos-action>
  </orgos-widget-header-action>

  <orgos-widget-content *ngIf="awayRequests && awayRequests.length > 0 && noRequestsToShow === false">
    <ng-container *ngFor="let awayRequestsPerDay of awayRequests; let iDay = index">
      <ng-container *ngIf="awayRequestsPerDay.length > 0">
        <orgos-text type="SecondaryText" color="LightText">{{ getDayTitle(iDay) | uppercase }}</orgos-text>
        <orgos-column-container wrap="true" class="kenjo-mb-10px">
          <orgos-column *ngFor="let awayRequest of awayRequestsPerDay; let order = index" size="0" class="kenjo-mv-10px kenjo-mr-10px">
            <orgos-avatar
              class="wwa-avatar"
              [class.wwa-avatar-selected]="userSelected === awayRequest"
              (click)="navigateToPersonalPage(awayRequest.user._id)"
              [photoUrl]="awayRequest.user.photoUrl"
              [avatarName]="awayRequest.user.displayName"
              size="small"
              (mouseenter)="userSelected = awayRequest"
            ></orgos-avatar>
          </orgos-column>
        </orgos-column-container>
      </ng-container>
    </ng-container>
  </orgos-widget-content>

  <orgos-widget-content *ngIf="awayRequests && noRequestsToShow === true">
    <div class="wwa-no-data kenjo-p-20px kenjo-font-color-light-text-757575">
      <div><kenjo-icon [size]="20">widgets</kenjo-icon></div>
      <div *ngIf="configurationKeys && configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" class="kenjo-font-size-12px kenjo-ml-10px kenjo-text-align-center">{{ translation.noEmployeeMatchingWithFavorites }}</div>
      <div *ngIf="configurationKeys && !configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" class="kenjo-font-size-12px kenjo-ml-10px kenjo-text-align-center">{{ translation.noCurentTeamsMembersAway }}</div>
    </div>
  </orgos-widget-content>

  <orgos-widget-footer *ngIf="!noRequestsToShow">
    <kenjo-truncate-text [ngClass]="{ 'kenjo-pb-10px': !isOnlyOneDay(userSelected) || isHourTimeOff(userSelected), 'kenjo-pv-10px': isOnlyOneDay(userSelected) && !isHourTimeOff(userSelected) }" *ngIf="userSelected"> {{ userSelected.user.displayName }}</kenjo-truncate-text>
    <orgos-text *ngIf="userSelected && userSelected?._timeOffTypeName" class="kenjo-pb-10px" type="SecondaryText" color="LightText"
      >{{ translation.timeOff }} <span class="kenjo-font-color-text-333333">{{ userSelected?._timeOffTypeName }}</span></orgos-text
    >
    <orgos-text *ngIf="userSelected && !isOnlyOneDay(userSelected)" class="kenjo-pb-10px" type="SecondaryText" color="LightText"
      >{{ translation.from }}
      <span class="kenjo-font-color-text-333333"
        >{{ userSelected?.from | date: 'longDate':'UTC' }}<span *ngIf="userSelected.partOfDayFrom === 'HalfOfDay'"> ({{ translation.afternoon }})</span></span
      ></orgos-text
    >
    <orgos-text *ngIf="userSelected && !isOnlyOneDay(userSelected)" type="SecondaryText" color="LightText">
      {{ translation.to }}
      <span class="kenjo-font-color-text-333333"
        >{{ userSelected?.to | date: 'longDate':'UTC' }}<span *ngIf="userSelected.partOfDayTo === 'HalfOfDay'"> ({{ translation.morning }})</span></span
      ></orgos-text
    >
    <orgos-text *ngIf="userSelected && isOnlyOneDay(userSelected) && !isHourTimeOff(userSelected)" type="SecondaryText" color="LightText">
      {{ translation.date }}
      <span class="kenjo-font-color-text-333333"
        >{{ userSelected?.from | date: 'longDate':'UTC' }}<span *ngIf="userSelected.partOfDayFrom === 'HalfOfDay'"> ({{ translation.afternoon }})</span><span *ngIf="userSelected.partOfDayTo === 'HalfOfDay'"> ({{ translation.morning }})</span></span
      ></orgos-text
    >
    <orgos-text *ngIf="userSelected && isOnlyOneDay(userSelected) && isHourTimeOff(userSelected)" class="kenjo-pb-10px" type="SecondaryText" color="LightText">
      {{ translation.date }}
      <span class="kenjo-font-color-text-333333">{{ userSelected?.from | date: 'longDate':'UTC' }}</span></orgos-text
    >
    <orgos-text *ngIf="userSelected && isOnlyOneDay(userSelected) && isHourTimeOff(userSelected)" class="kenjo-pb-10px" type="SecondaryText" color="LightText">
      {{ translation.duration }}
      <span class="kenjo-font-color-text-333333">{{ getFormattedDuration(userSelected) }}</span></orgos-text
    >
  </orgos-widget-footer>
</orgos-widget>
