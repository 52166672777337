<orgos-column-container
  *ngIf="task"
  class="kenjo-border kenjo-border-radius kenjo-full-height"
  centerColumns="true"
  [@checkingTask]="animationOngoing ? 'checked' : 'unchecked'"
  (@checkingTask.done)="animationFinished($event)"
>
  <orgos-column size="0">
    <div
      class="tc-legend-task tc-animate-border"
      [class.tc-overdue-task]="!task.isCompleted && moment(task.dueDate).isBefore(today, 'day')"
      [class.tc-done-task]="task.isCompleted"
    ></div>
  </orgos-column>
  <orgos-column size="0" class="float-icon">
    <kenjo-icon
      *ngIf="!task.isCompleted || animationOngoing"
      [size]="20"
      (click)="changeTaskStatus(true)"
      class="icon"
      [ngClass]="
        animationOngoing
          ? 'kenjo-font-color-success-00b72e'
          : !isHoveringCheckMark
          ? 'kenjo-font-color-light-text-757575'
          : 'icon kenjo-font-color-success-light-aaf2bc'
      "
      [matTooltip]="isHoveringCheckMark ? i18n.checkmarkTooltip : ''"
      [class.kenjo-cursor-pointer]="(hasEditPermission && !readOnlyStatus) || isOwner"
      (mouseover)="mouseOverIcon()"
      (mouseout)="mouseOutIcon()"
    >
      {{ animationOngoing ? 'check_circle_thin_outline' : isHoveringCheckMark ? 'check_circle_thin_outline' : 'check_circle_thin' }}
    </kenjo-icon>
    <kenjo-icon
      *ngIf="task.isCompleted && !animationOngoing"
      [size]="20"
      (click)="changeTaskStatus(false)"
      class="kenjo-font-color-success-00b72e"
      [class.kenjo-cursor-pointer]="(hasEditPermission && !readOnlyStatus) || isOwner"
    >
      check_circle_thin_outline</kenjo-icon
    >
  </orgos-column>
  <orgos-column size="6" class="tc-main-content">
    <input
      *ngIf="!showFullTitleInReadOnly"
      type="text"
      [placeholder]="i18n.titlePlaceholder"
      [ngModel]="task.title"
      [readOnly]="!hasEditPermission || readOnlyTitle"
      (ngModelChange)="changeTask('title', $event)"
      (click)="editTitle = hasEditPermission && !readOnlyTitle"
      (blur)="editTitle = false"
      class="tc-title tc-animate-input"
      [class.tc-title-edit-mode]="editTitle || !task.title"
      [class.kenjo-cursor-pointer]="hasEditPermission && !readOnlyTitle"
      [matTooltip]="task.title"
      [matTooltipDisabled]="editTitle || !task.title"
      matTooltipPosition="above"
    />
    <div class="tc-title" *ngIf="showFullTitleInReadOnly">
      {{ task.title }}
    </div>

    <div
      (click)="dueDatePicker.open()"
      class="tc-due-date kenjo-mr-5px tc-animate-date"
      [class.kenjo-cursor-pointer]="hasEditPermission && !readOnlyDueDate"
      [class.tc-due-date-edit-mode]="dueDatePicker.opened || !task.dueDate"
    >
      <div
        [class.kenjo-font-color-danger-ff5757]="!task.isCompleted && task.dueDate && moment(task.dueDate).isBefore(today, 'day')"
        [class.kenjo-font-color-light-text-757575]="task.isCompleted || !task.dueDate || moment(task.dueDate).isSameOrAfter(today, 'day')"
      >
        <ng-container *ngIf="task.dueDate">{{ task.dueDate | utcDate | humanDate: false:'short' }}</ng-container>
        <ng-container *ngIf="!task.dueDate">{{ i18n.dueDatePlaceholder }}</ng-container>
      </div>
      <input [matDatepicker]="dueDatePicker" [ngModel]="task.dueDate" (ngModelChange)="changeTask('dueDate', $event)" disabled />
      <mat-datepicker #dueDatePicker [disabled]="!hasEditPermission || readOnlyDueDate"></mat-datepicker>
      <mat-icon
        *ngIf="task.dueDate && hasEditPermission && !readOnlyDueDate"
        (click)="changeTask('dueDate', null); $event.stopPropagation()"
        class="tc-due-date-clear kenjo-ml-5px kenjo-cursor-pointer"
      >
        clear</mat-icon
      >
    </div>
  </orgos-column>
  <orgos-column size="1" class="kenjo-mh-10px kenjo-text-align-right tc-nowrap">
    <div class="kenjo-font-color-light-text-757575">{{ i18n.taskAsigneeLabel }}:</div>
    <div
      *ngIf="workflowName"
      class="kenjo-font-color-light-text-757575"
      [class.kenjo-mt-10px]="showRelatedTo"
      [class.kenjo-mt-15px]="!showRelatedTo"
    >
      {{ i18n.taskWorkflowLabel }}:
    </div>
    <div
      *ngIf="creator"
      class="kenjo-font-color-light-text-757575"
      [class.kenjo-mt-10px]="showRelatedTo"
      [class.kenjo-mt-15px]="!showRelatedTo"
    >
      {{ i18n.taskCreatorLabel }}:
    </div>
    <div *ngIf="showRelatedTo && relatedToEmployee" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
      {{ i18n.taskRelatedToEmployeeLabel }}:
    </div>
    <div *ngIf="showRelatedTo && relatedToPositionCandidate" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
      {{ i18n.taskRelatedToCandidateLabel }}:
    </div>
    <div *ngIf="showRelatedTo && relatedToMeeting" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
      {{ i18n.taskRelatedToMeetingLabel }}:
    </div>
    <div *ngIf="showRelatedTo && relatedToDocument" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
      {{ i18n.taskRelatedToDocumentLabel }}:
    </div>
  </orgos-column>
  <orgos-column *ngIf="assignee" size="2" class="kenjo-mr-15px">
    <div #assigneeBlock (click)="searchAssignee(assigneeBlock)" [class.kenjo-cursor-pointer]="hasEditPermission && !readOnlyAssignee">
      <orgos-user-item
        [maxWidth]="'145px'"
        size="tiny"
        [photoUrl]="assignee?._photo?._url"
        [displayName]="assignee.displayName"
        [title]="assignee.displayName"
      >
      </orgos-user-item>
    </div>
    <kenjo-truncate-text
      class="tc-raw-text"
      *ngIf="workflowName"
      [class.kenjo-mt-5px]="showRelatedTo"
      [class.kenjo-mt-10px]="!showRelatedTo"
    >
      {{ workflowName }}</kenjo-truncate-text
    >
    <orgos-user-item
      *ngIf="creator"
      [maxWidth]="'145px'"
      size="tiny"
      [photoUrl]="creator?._photo?._url"
      [displayName]="creator?.displayName"
      [title]="creator?.displayName"
      [class.kenjo-mt-5px]="showRelatedTo"
      [class.kenjo-mt-10px]="!showRelatedTo"
    >
    </orgos-user-item>
    <div *ngIf="showRelatedTo && relatedToEmployee" class="kenjo-cursor-pointer kenjo-mt-5px">
      <a [routerLink]="['/cloud/people/', relatedToEmployee._id, 'personal']"
        ><orgos-user-item
          [maxWidth]="'145px'"
          size="tiny"
          [photoUrl]="relatedToEmployee?._photo?._url"
          [displayName]="relatedToEmployee?.displayName"
          [title]="relatedToEmployee?.displayName"
        >
        </orgos-user-item
      ></a>
    </div>
    <div *ngIf="showRelatedTo && relatedToPositionCandidate" class="kenjo-cursor-pointer kenjo-mt-5px">
      <orgos-user-item
        [maxWidth]="'145px'"
        size="tiny"
        [photoUrl]="mapCandidatesById[relatedToPositionCandidate?._candidateId]?.photo?._url"
        [displayName]="
          mapCandidatesById[relatedToPositionCandidate?._candidateId]?.firstName +
          ' ' +
          mapCandidatesById[relatedToPositionCandidate?._candidateId]?.lastName
        "
        [title]="
          mapCandidatesById[relatedToPositionCandidate?._candidateId]?.firstName +
          ' ' +
          mapCandidatesById[relatedToPositionCandidate?._candidateId]?.lastName
        "
        (click)="navigateToPositionCandidate()"
      >
      </orgos-user-item>
    </div>
    <kenjo-truncate-text
      class="tc-raw-text kenjo-cursor-pointer kenjo-mt-5px"
      *ngIf="showRelatedTo && relatedToMeeting"
      (click)="navigateToMeeting()"
    >
      {{ relatedToMeeting.name }}
    </kenjo-truncate-text>
    <kenjo-truncate-text
      class="tc-raw-text kenjo-cursor-pointer kenjo-mt-5px"
      *ngIf="showRelatedTo && relatedToDocument"
      (click)="navigateToDocument()"
    >
      {{ relatedToDocument.name }}.{{ relatedToDocument._file?._fileExtension }}
    </kenjo-truncate-text>
  </orgos-column>
  <orgos-column
    *ngIf="hasDeletePermission && !disableDeletion"
    [matTooltip]="isHoveringDelete ? i18n.deleteTooltip : ''"
    size="0"
    class="tc-delete-button kenjo-cursor-pointer flex-horizontal flex-center flex-center-vertically"
    (click)="onDeleteTask()"
    (mouseover)="isHoveringDelete = true"
    (mouseout)="isHoveringDelete = false"
  >
    <kenjo-icon [size]="20" class="tc-delete-icon">delete</kenjo-icon>
  </orgos-column>
</orgos-column-container>

<ng-template cdkPortal>
  <orgos-search
    #search
    [placeholder]="i18n.searchUserPlaceholder"
    [searchFunction]="searchAssigneeFunction"
    (searchResultsChange)="searchResults = $event"
    class="tc-search-widget"
  >
    <div class="orgos-search-error kenjo-font-color-danger-ff5757">{{ i18n.noUserFound }}</div>

    <orgos-search-option
      *ngFor="let result of searchResults"
      [userPersonal]="result"
      (selected)="search.clearSearch(); selectAssignee(result)"
    >
      <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
    </orgos-search-option>

    <orgos-container class="orgos-search-selection-container tc-search-no-term">
      <orgos-search-option
        *ngFor="let result of searchResults"
        [userPersonal]="result"
        (selected)="search.clearSearch(); selectAssignee(result)"
      >
        <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
      </orgos-search-option>
    </orgos-container>
  </orgos-search>
</ng-template>
