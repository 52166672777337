import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ITask } from '@app/models/task.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { TasksAbstractPage } from '@app/standard/pages/tasks/tasks-abstract.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { TaskHelperService } from '@app/standard/services/task/task-helper.service';

@Component({
  selector: 'orgos-tasks-company',
  templateUrl: 'tasks-company.page.html',
  styleUrls: ['tasks-company.page.scss']
})
export class TasksCompanyPage extends TasksAbstractPage implements OnInit {
  pageFindQuery: any;

  constructor(protected router: Router, protected injector: Injector) {
    super(router, injector);
  }

  ngOnInit(): void {
    this.tabOrder = 2;
    this.tabKey = 'company';
    this.groupFields = ['_createdById', 'relatedTo', 'ownerId', 'workflowName'];
    this.pageFindQuery = { _id: { $ne: null } };

    this.injector.get(TaskHelperService).refreshData();
    this.fetchData();
    this.injector.get(PrivateAmplitudeService).logEvent('view to-dos page', { category: 'Navigation', type: 'company to-dos' });
  }

  protected async loadTasks(): Promise<void> {
    try {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      const tasks: Array<ITask> = await this.applyFilters();
      await Promise.all([this.handlePermissions(loggedUser), this.handleTasks(tasks, loggedUser)]);
    } catch (error) {
      this.listTasks = [];
    }
  }
}