<div class="spss-main-container">
  <orgos-column-container centerColumns="true">
    <orgos-column class="spss-shift-counter kenjo-font-size-14px kenjo-pl-40px kenjo-border-right kenjo-font-color-text-333333 kenjo-pr-20px" size="0">
      <ng-container *ngIf="selectedShifts < 1"
        ><div class="kenjo-text-align-right">{{ parentTranslation.selectAtLeastOneShift }}</div></ng-container
      >
      <ng-container *ngIf="selectedShifts === 1">{{ selectedShifts }} {{ parentTranslation.shiftSelected }}</ng-container>
      <ng-container *ngIf="selectedShifts > 1">{{ selectedShifts }} {{ parentTranslation.shiftsSelected }}</ng-container>
    </orgos-column>
    <orgos-column size="0">
      <div
        class="spss-icon-container kenjo-cursor-pointer"
        [class.container-disabled]="loading || selectedShifts === 0 || selectedShifts >= SHIFTS_LIMIT_TO_DELETE_CUSTOM"
        [matTooltip]="loading || selectedShifts >= SHIFTS_LIMIT_TO_DELETE_CUSTOM ? (parentTranslation.maxShiftsReachedCustom | i18nData: { shiftsNumber: SHIFTS_LIMIT_TO_DELETE_CUSTOM }) : parentTranslation.deleteSelectedShifts"
        [matTooltipPosition]="'above'"
      >
        <kenjo-icon [size]="24" (click)="deleteShifts()" class="kenjo-font-color-danger-ff5757" [class.kenjo-font-color-disabled-c4c4c4]="loading || selectedShifts === 0 || selectedShifts >= SHIFTS_LIMIT_TO_DELETE_CUSTOM">delete</kenjo-icon>
      </div>
    </orgos-column>
    <orgos-column></orgos-column>
    <orgos-column size="0" class="kenjo-pr-20px">
      <a *ngIf="selectedShifts >= 0 && selectedShifts !== shiftsNumber" (click)="selectAll()">{{ parentTranslation.selectAll }}</a>
      <a *ngIf="selectedShifts === shiftsNumber" (click)="clearAll()">{{ parentTranslation.deselectAll }}</a>
    </orgos-column>
    <orgos-column size="0" class="spss-close-icon kenjo-border-left">
      <kenjo-icon [size]="24" (click)="onClose()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" [disabled]="loading">clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>
</div>
