import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { AddPersonalMessageDialog } from '@app/standard/pages/documents/dialogs/add-personal-message.dialog';
import { CompanyDocReadTrackingDialogServiceService } from '@app/standard/pages/documents/dialogs/company-doc-read-tracking-dialog/company-doc-read-tracking-dialog.service';
import { CompanyDocReadTrackingDialog } from '@app/standard/pages/documents/dialogs/company-doc-read-tracking-dialog/company-doc-read-tracking.dialog';
import { SelectSignersDialog } from '@app/standard/pages/documents/dialogs/select-signers.dialog';
import { CompanyService } from '@app/standard/services/company/company.service';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { OfficeService } from '@app/standard/services/company/office.service';
import { CoreFeaturesService } from '@app/standard/services/core-features/core-features.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DocumentTagService, IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import { DocumentService, IDocumentModel, IReadBy } from '@app/standard/services/document/document.service';
import { ISmartDocsSettingsModel, SmartDocsSettingsService } from '@app/standard/services/document/smart-docs-settings.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserService } from '@app/standard/services/user/user.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, takeWhile } from 'rxjs/operators';
import { PrivateSecurityService } from 'src/app/private/services/private-security.service';
import {
  ISignatureCreateRequest,
  ISignatureOptions,
  SignatureRequestService,
} from 'src/app/standard/services/signature-request/signature-request.service';
import { UserWorkService } from 'src/app/standard/services/user/user-work.service';

@Component({
  selector: 'orgos-documents-detail',
  templateUrl: 'documents-detail.page.html',
  styleUrls: ['documents-detail.page.scss'],
})
export class DocumentsDetailPage implements OnInit, OnDestroy {
  pageTranslation: any = {};
  documentCollectionTranslation: any = {};
  standardPicklists: any = {};
  miscTranslation: any = {};
  document: IDocumentModel;
  allDocumentTags: Array<IDocumentTagModel> = [];

  documentId: string = '';
  hasEditPermission: boolean = false;
  hasDeletePermission: boolean = false;
  hasTagDocumentPermission: boolean = false;
  hasPendingSignatureRequest: any = [];

  editModeActivated: boolean = false;
  currentMode: string = 'readMode';
  documentModel: GenericCacheModel;
  smartDocsSettings: ISmartDocsSettingsModel;

  areTagsMandatory: boolean = false;
  unlabelledTagId: string = picklists.DOCUMENT_UNLABELLED_ID;

  // Signature properties
  signersOptions = [];
  signatureOptions: ISignatureOptions = {
    signersOption: 'specific',
    signingMode: 'sequential',
    recipients: [],
    docOwners: [],
    companies: [],
    offices: [],
    departments: [],
    includeInactiveEmployees: true,
  };
  allCompanies = [];
  allOffices = [];
  allDepartments = [];
  allUsers = []; // users with email, company, office, department...
  isSignable = false;
  userCanCreateSignatureRequest: boolean = false;
  userCanManageSignatureRequest: boolean = false;
  signatureRequestFeatureActive: boolean = false;
  isAPendingDocumentToSign: boolean = false;
  sendingSignatureRequest = false;
  sendingReadRequest = false;

  allUserPersonal: Array<any> = [];

  nameValidation: InputValidation;

  testComments: Array<any> = [{ comment: 'hola1' }, { comment: 'hola2' }, { comment: 'hola3' }];

  private keepSubscriptions: boolean = true;
  private backButtonSubscription: Subscription;

  isUserARequestee: boolean = false;
  hasCompanyDocsPermissions: boolean = false;
  currentUserRequestAcknowledge: IReadBy = null;
  hasNotReadCompanyDocument: boolean = false;
  hasNotReadEmployeeDocument: boolean = false;
  isRequestReadEnabled: boolean = false;
  isRemindRequestReadEnabled: boolean = false;
  isConfirmReadEnabled: boolean = false;
  isReviewReadStatusEnabled: boolean = false;

  constructor(private route: ActivatedRoute, private injector: Injector, private router: Router, private location: Location) {
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.onBackClick(false);
      }
    }) as Subscription;
  }

  ngOnInit(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.injector.get(GlobalBarService).setEnableDefaultBars(false);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('documents-detail-page')
      .then((pageTranslation: any) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
        this.fillSignersOptions();
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-collection')
      .then((documentCollectionTranslation: any) => {
        this.documentCollectionTranslation = documentCollectionTranslation;
      })
      .catch(() => {
        this.documentCollectionTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((standardPicklists: any) => {
        this.standardPicklists = standardPicklists;
      })
      .catch(() => {
        this.standardPicklists = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation: any) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.route.paramMap
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe(async (id: string) => {
        if (!this.isValidId(id)) {
          this.router.navigateByUrl('/cloud/home');
          return;
        }
        this.document = null;
        this.documentModel = null;

        this.injector.get(GlobalBarService).setProgressBar(true);

        this.documentId = id;

        await this.fetchData();
        this.injector.get(PrivateAmplitudeService).logEvent('view document', {
          platform: 'Web',
          category: 'Smart docs',
          subcategory1: this.document?._id,
          subcategory2: this.document?.ownerId,
        });
      });
  }

  ngOnDestroy(): void {
    this.keepSubscriptions = false;
    this.backButtonSubscription.unsubscribe();
  }

  /**
   * fetchData()
   * 1. Get company docs and return the document if it is a 'Company' doc
   * 2. If is not a 'Company' doc directly find the doc
   * 3. Applies get the edit and delete permissions for the logged user over the doc
   * 4. Establish if the logged user has document tag permissions
   */
  private async fetchData(): Promise<void> {
    try {
      let userPermissions = {};
      let companyDoc: IDocumentModel = {};

      const companyDocs = await this.injector.get(DocumentService).getCompanyDocs();
      companyDoc = companyDocs.find((doc) => {
        return doc._id === this.documentId;
      });

      let userDoc;
      if (check.not.assigned(companyDoc) || check.emptyObject(companyDoc)) {
        userDoc = await this.getDocument(this.documentId);
      }
      this.document = check.not.assigned(userDoc) && check.assigned(companyDoc) ? companyDoc : userDoc;
      this.documentModel = new GenericCacheModel(this.injector, this.document, DocumentService, this.document.ownerId);
      this.documentIsSignable(this.document._file?._fileExtension);
      this.injector.get(GlobalBarService).setProgressBar(false);

      const permissions = await this.injector.get(PrivateSecurityService).getAllPermissions();
      userPermissions = permissions;
      this.userCanCreateSignatureRequest = userPermissions['e-signature'] && userPermissions['e-signature'].create_requests === true;
      this.userCanManageSignatureRequest = userPermissions['e-signature'] && userPermissions['e-signature'].requests_tab === true;
      this.hasCompanyDocsPermissions = userPermissions['document-app']?.c_uploadCompanyDocs === true;

      this.hasEditPermission = await this.getPermissionsToChangeDoc(userPermissions, 'edit');
      this.hasDeletePermission = await this.getPermissionsToChangeDoc(userPermissions, 'delete');
      this.hasPendingSignatureRequest = await this.injector.get(SignatureRequestService).getPendingSignatureRequest(this.documentId);

      this.allDocumentTags = await this.injector
        .get(DocumentTagService)
        .getTags({ applyPermissions: true, onlyActive: false, docId: this.documentId, docTags: this.document.tags });
      this.filterSelectableTags();

      this.allUserPersonal = await this.injector.get(UserPersonalService).getAllUserPersonal(false, true);

      // Used for signature
      this.allCompanies = await this.injector.get(CompanyService).getCompanies();
      this.allOffices = await this.injector.get(OfficeService).getOffices();
      this.allDepartments = await this.injector.get(DepartmentService).getDepartments();

      const allUsersFull = await this.injector.get(UserService).getAllUsersFull(false);
      this.allUsers = allUsersFull
        .reduce((accUsers, iUser) => {
          if (iUser.userAccount.profileKey !== 'restricted') {
            const iUserData = {
              _id: iUser.userPersonal._id,
              displayName: iUser.userPersonal.displayName,
              name: iUser.userPersonal.displayName,
              _photo: iUser.userPersonal._photo,
              isActive: iUser.userAccount.isActive,
              email: iUser.userAccount.email,
              company: iUser.userWork.companyId,
              office: iUser.userWork.officeId,
              department: iUser.userWork.departmentId,
            };
            return [...accUsers, iUserData];
          }
          return accUsers;
        }, [])
        .sort((a: any, b: any) => {
          if (a.isActive !== b.isActive) {
            if (a.isActive) {
              return -1;
            }
            return 1;
          }
          return 0;
        });

      const app = await this.injector.get(CoreFeaturesService).getAppDetail('digital-signature');
      this.signatureRequestFeatureActive = app.isActive;

      this.computeReadAcknowledge();
      this.fetchSmartDocsSettings();

      this.hasNotReadCompanyDocument = this.checkUserHasNotReadCompanyDocument();
      this.hasNotReadEmployeeDocument = this.checkUserHasNotReadUserDocument();
      this.isRequestReadEnabled = this.checkIsRequestReadEnabled();
      this.isRemindRequestReadEnabled = this.checkIsRemindRequestReadEnabled();
      this.isConfirmReadEnabled = this.checkIsConfirmReadEnabled();
      this.isReviewReadStatusEnabled = this.checkIsReviewReadStatusEnabled();
    } catch {
      // An error is already shown
      this.document = null;
      this.documentModel = null;
      this.injector.get(GlobalBarService).setProgressBar(false);
      this.allDocumentTags = [];
      this.allUserPersonal = [];

      this.allCompanies = [];
      this.allOffices = [];
      this.signatureRequestFeatureActive = false;
    } finally {
      this.sendingReadRequest = false;
    }
  }

  onBackClick(changeLocationState: boolean = true): void {
    this.backButtonSubscription.unsubscribe();

    this.document = null;
    this.documentModel = null;
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, changeLocationState);
  }

  getTagName(tagId: any): string {
    const tag = this.allDocumentTags.find((iTag) => {
      return iTag._id === tagId;
    });

    if (check.not.assigned(tag)) {
      return;
    }

    return tag.name;
  }

  getTagColor(tagId: any): string {
    const tag = this.allDocumentTags.find((iTag) => {
      return iTag._id === tagId;
    });

    if (tagId === this.unlabelledTagId) {
      return '#757575';
    }

    if (check.not.assigned(tag)) {
      return 'Neutral';
    }

    return userColorConstants[tag.color];
  }

  isTagSelectedOnEditMode(tag: IDocumentTagModel): boolean {
    if (
      check.not.assigned(this.documentModel) ||
      check.not.assigned(this.documentModel.data.tags) ||
      check.emptyArray(this.documentModel.data.tags)
    ) {
      return false;
    }

    return check.contains(this.documentModel.data.tags, tag._id);
  }

  getTagColorOnEditMode(tag: IDocumentTagModel): string {
    if (this.isTagSelectedOnEditMode(tag) === false) {
      return 'Neutral';
    }

    return userColorConstants[tag.color];
  }

  toggleTagOnEditMode(tag: IDocumentTagModel): Function {
    return () => {
      if (this.isTagSelectedOnEditMode(tag) === false) {
        this.documentModel.data.tags = this.documentModel.data.tags.concat([tag._id]);
        return;
      }

      this.documentModel.data.tags = this.documentModel.data.tags.filter((iTag: string) => {
        return iTag !== tag._id;
      });
    };
  }

  updateDocument(): void {
    if (check.not.assigned(this.nameValidation) || this.nameValidation.hasErrors() || this.checkTags()) {
      return;
    }

    this.injector
      .get(DocumentService)
      .updateById(this.documentModel.data._id, this.documentModel.data)
      .then(() => {
        const documentEditedSnackText = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.documentEditedSnackText, this.documentModel.data);
        this.injector.get(MatLegacySnackBar).open(documentEditedSnackText, 'OK', {
          duration: 5000,
        });

        this.fetchData();
        this.editModeActivated = false;
        this.currentMode = 'readMode';

        this.injector.get(PrivateAmplitudeService).logEvent('edit document', {
          platform: 'Web',
          category: 'Smart docs',
          subcategory1: this.document?._id,
          subcategory2: this.document?.ownerId,
        });
      })
      .catch(() => {
        // An error is already shown
      });
  }

  cancelUpdate(): void {
    this.fetchData();
    this.editModeActivated = false;
    this.currentMode = 'readMode';
    this.signatureOptions = this.resetSignatureOptions();
  }

  download(): void {
    const documentToDownload = this.document;
    this.injector
      .get(HttpClient)
      .get(documentToDownload._file._url, { responseType: 'arraybuffer' })
      .subscribe((arrayBuffer) => {
        const extension: string = documentToDownload._file._fileExtension;
        const documentName: string =
          check.nonEmptyString(extension) && documentToDownload.name.endsWith(extension) === false
            ? `${documentToDownload.name}.${extension}`
            : documentToDownload.name;

        FileSaver.saveAs(new Blob([arrayBuffer]), documentName);

        this.injector.get(PrivateAmplitudeService).logEvent('download document', {
          platform: 'Web',
          category: 'Smart docs',
          subcategory1: documentToDownload?._id,
          subcategory2: documentToDownload?.ownerId,
        });
      });
  }

  delete(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-delete-dialog')
      .then((documentDeleteTranslation) => {
        const dialogData = {
          titleText: this.injector.get(I18nDataPipe).transform(documentDeleteTranslation.dialogHeader, this.document),
          subtitleText: documentDeleteTranslation.warningMessageText,
          confirmButtonText: documentDeleteTranslation.deleteButtonLabel,
          confirmButtonColor: 'Danger',
          cancelButtonText: this.miscTranslation.goBackButtonDialog,
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
        dialogRef.afterClosed().subscribe((deleteDocument: boolean) => {
          if (check.not.assigned(deleteDocument) || deleteDocument === false) {
            return;
          }

          this.injector
            .get(DocumentService)
            .deleteById(this.documentId)
            .then(() => {
              const documentDeletedSnackText = this.injector
                .get(I18nDataPipe)
                .transform(this.pageTranslation.documentDeletedSnackText, this.document);
              this.injector.get(MatLegacySnackBar).open(documentDeletedSnackText, 'OK', {
                duration: 5000,
              });

              this.injector.get(PrivateAmplitudeService).logEvent('delete document', {
                platform: 'Web',
                category: 'Smart docs',
                subcategory1: this.document?._id,
                subcategory2: this.document?.ownerId,
              });

              this.onBackClick();
            })
            .catch(() => {
              // An error is already shown.
            });
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  getUserPersonal(userId: string): any {
    const user = this.allUserPersonal.find((iUserPersonal) => {
      return iUserPersonal._id === userId;
    });

    return user;
  }

  async onReadRequestClick(): Promise<void> {
    if (this.document.relatedTo?.typeRelatedTo === 'Company') {
      await this.requestReadForCompany();
    } else {
      await this.requestReadForUser();
    }
  }

  async requestReadForUser(): Promise<void> {
    try {
      let documentRequestReadTranslation;
      if (this.isRemindRequestReadEnabled === true) {
        documentRequestReadTranslation = await this.injector
          .get(InternationalizationService)
          .getAllTranslation('documents-read-tracking-individual-reminder-confirmation-dialog');
      } else {
        documentRequestReadTranslation = await this.injector
          .get(InternationalizationService)
          .getAllTranslation('documents-read-tracking-individual-request-confirmation-dialog');
      }

      const dialogData = {
        titleText: documentRequestReadTranslation.dialogHeader,
        subtitleText: documentRequestReadTranslation.warningMessageText,
        confirmButtonText: documentRequestReadTranslation.confirmButtonLabel,
        confirmButtonColor: 'Success',
        cancelButtonText: documentRequestReadTranslation.cancelButtonLabel,
        employees: [this.getUserPersonal(this.document.relatedTo?.idRelatedTo)],
      };
      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
      dialogRef.afterClosed().subscribe(async (requested: boolean) => {
        if (check.not.assigned(requested) || requested === false) {
          return;
        }
        try {
          this.sendingReadRequest = true;
          await this.injector.get(DocumentService).requestRead(this.document);
          this.injector.get(PrivateAmplitudeService).logEvent('request read document', {
            platform: 'Web',
            category: 'Smart docs',
            subcategory1: this.document?._id,
            subcategory2: this.document?.ownerId,
          });
        } catch {
          // An error is already shown
        } finally {
          this.fetchData();
        }
      });
    } catch {
      // An error is already shown
    } finally {
      this.sendingReadRequest = false;
    }
  }

  async requestReadForCompany(): Promise<void> {
    try {
      const [documentRequestReadTranslation, recipients] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('documents-detail-confirm-read-request-dialog'),
        this.injector.get(DocumentService).getReadRequestRecipients(this.document),
      ]);

      const dialogData = {
        titleText: documentRequestReadTranslation.dialogHeader,
        subtitleText: this.injector
          .get(I18nDataPipe)
          .transform(documentRequestReadTranslation.warningMessageText, { count: recipients.length }),
        confirmButtonText: documentRequestReadTranslation.confirmButtonLabel,
        confirmButtonColor: 'Success',
        cancelButtonText: documentRequestReadTranslation.cancelButtonLabel,
        employees: recipients,
      };
      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
      dialogRef.afterClosed().subscribe(async (requested: boolean) => {
        if (check.not.assigned(requested) || requested === false) {
          return;
        }
        try {
          this.sendingReadRequest = true;
          await this.injector.get(CompanyDocReadTrackingDialogServiceService).sendCompanyWideBulkRequestRead(this.document);
          this.injector.get(MatLegacySnackBar).open(this.pageTranslation.requestReadSnackbar, 'OK', {
            duration: 5000,
          });
        } catch {
          // An error is already shown
        } finally {
          this.fetchData();
        }
      });
    } catch {
      // An error is already shown
    } finally {
      this.sendingReadRequest = false;
    }
  }

  async onReadReviewClick(): Promise<void> {
    const dialogRef = this.injector
      .get(MatLegacyDialog)
      .open(CompanyDocReadTrackingDialog, { data: { documentId: this.document._id, documentName: this.document.name } });
    dialogRef.afterClosed().subscribe(async () => {
      await this.fetchData();
    });
  }

  confirmRead(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-acknowledge-read-dialog')
      .then((documentAcknowledgeReadTranslation) => {
        const dialogData = {
          titleText: documentAcknowledgeReadTranslation.dialogHeader,
          confirmButtonText: documentAcknowledgeReadTranslation.acknowledgeButtonLabel,
          confirmButtonColor: 'Success',
          cancelButtonText: documentAcknowledgeReadTranslation.goBackButtonLabel,
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
        dialogRef.afterClosed().subscribe((acknowldeged: boolean) => {
          if (check.not.assigned(acknowldeged) || acknowldeged === false) {
            return;
          }

          this.injector
            .get(DocumentService)
            .confirmRead(this.documentId)
            .then(() => {
              this.injector.get(PrivateAmplitudeService).logEvent('confirm read document', {
                platform: 'Web',
                category: 'Smart docs',
                subcategory1: this.document?._id,
                subcategory2: this.document?.ownerId,
              });

              this.fetchData();
            })
            .catch(() => {
              // An error is already shown

              this.fetchData();
            });
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  private computeReadAcknowledge(): void {
    if (check.not.assigned(this.document?.readBy) || check.emptyArray(this.document?.readBy)) {
      return;
    }

    this.currentUserRequestAcknowledge = this.document.readBy.find(
      (readBy: IReadBy) => readBy.userId === this.injector.get(AuthenticationService).getLoggedUser()._id
    );

    if (check.assigned(this.currentUserRequestAcknowledge)) {
      this.isUserARequestee = true;
    }
  }

  private checkIsRequestReadEnabled(): boolean {
    return (
      (this.document.hidden === false &&
        check.equal(this.document.relatedTo?.typeRelatedTo, 'User') &&
        this.document.relatedTo?.idRelatedTo !== this.injector.get(AuthenticationService).getLoggedUser()._id &&
        this.checkUserHasNotReadUserDocument() &&
        this.hasEditPermission) ||
      (check.equal(this.document.relatedTo?.typeRelatedTo, 'Company') &&
        check.not.assigned(this.document.lastReadRequestAt) &&
        this.hasCompanyDocsPermissions)
    );
  }

  private checkIsRemindRequestReadEnabled(): boolean {
    return check.assigned(this.document.lastReadRequestAt) && this.checkIsRequestReadEnabled() && !this.checkIsConfirmReadEnabled();
  }

  private checkIsReviewReadStatusEnabled(): boolean {
    return (
      check.equal(this.document.relatedTo?.typeRelatedTo, 'Company') &&
      check.assigned(this.document.lastReadRequestAt) &&
      this.hasCompanyDocsPermissions
    );
  }

  private checkIsConfirmReadEnabled(): boolean {
    return (
      this.document.hidden === false &&
      check.assigned(this.document.lastReadRequestAt) === true &&
      ((check.equal(this.document.relatedTo?.typeRelatedTo, 'User') &&
        check.equal(this.document.relatedTo?.idRelatedTo, this.injector.get(AuthenticationService).getLoggedUser()._id)) ||
        check.equal(this.document.relatedTo?.typeRelatedTo, 'Company')) &&
      (this.document.relatedTo?.typeRelatedTo === 'Company'
        ? this.hasNotReadCompanyDocument === true
        : this.hasNotReadEmployeeDocument === true)
    );
  }

  private checkUserHasNotReadCompanyDocument(): boolean {
    const userToReadDocument = this.document.readBy.find((iReadBy: any) => {
      return iReadBy.userId === this.injector.get(AuthenticationService).getLoggedUser()._id;
    });
    return check.assigned(userToReadDocument) && check.not.assigned(userToReadDocument.readDate);
  }

  private checkUserHasNotReadUserDocument(): boolean {
    if (check.not.assigned(this.document.readBy)) {
      return true;
    }

    const userToReadDocument = this.document.readBy.find((iReadBy: any) => {
      return iReadBy.userId === this.document.relatedTo.idRelatedTo;
    });

    if (check.not.assigned(userToReadDocument)) {
      return true;
    }

    return check.not.assigned(userToReadDocument.readDate);
  }

  private getPermissionsToChangeDoc(permissions: any, permissionType: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (check.not.assigned(permissions) || check.not.assigned(permissions['document']) || check.emptyObject(permissions['document'])) {
        resolve(false);
      }

      if (
        check.not.assigned(this.document.relatedTo) ||
        (this.document.relatedTo.typeRelatedTo === 'User' && check.not.assigned(this.document.relatedTo.idRelatedTo))
      ) {
        resolve(false);
      }

      if (check.assigned(permissions.document[`${permissionType}_all`]) && permissions.document[`${permissionType}_all`] === true) {
        resolve(true);
      }

      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (
        loggedUser._id === this.document.relatedTo.idRelatedTo &&
        check.assigned(permissions.document[`${permissionType}_own`]) &&
        permissions.document[`${permissionType}_own`] === true
      ) {
        resolve(true);
      }

      if (
        check.not.assigned(permissions.document[`${permissionType}_custom`]) ||
        check.emptyArray(permissions.document[`${permissionType}_custom`])
      ) {
        resolve(false);
      }

      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          const myUserWork = allUserWork.find((iUserWork) => {
            return iUserWork._id === this.document.relatedTo.idRelatedTo;
          });
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            permissions.document[`${permissionType}_custom`],
            permissions.document[`${permissionType}_own`],
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  public createComment(comment: any): void {
    this.injector
      .get(DocumentService)
      .createComment(this.documentId, comment)
      .then(() => {
        this.fetchData();
        this.injector.get(MatLegacySnackBar).open(this.pageTranslation.commentCreatedSnackText, 'OK', {
          duration: 5000,
        });
      })
      .catch(() => {
        // An error is already shown
        this.fetchData();
      });
  }

  public updateComment(commentInfo: any): void {
    this.injector
      .get(DocumentService)
      .updateComment(this.documentId, commentInfo)
      .then(() => {
        this.fetchData();
        this.injector.get(MatLegacySnackBar).open(this.pageTranslation.commentUpdatedSnackText, 'OK', {
          duration: 5000,
        });
      })
      .catch(() => {
        // An error is already shown
        this.fetchData();
      });
  }

  // Signature methods
  fillSignersOptions() {
    this.signersOptions = [
      { name: this.pageTranslation.specificEmployeesOption, value: 'specific' },
      { name: this.pageTranslation.allEmployeesOption, value: 'all' },
    ];
  }

  onChangeSignersOption(option: any) {
    this.signatureOptions = {
      ...this.resetSignatureOptions(),
      signersOption: option.value,
      personalMessage: this.signatureOptions.personalMessage,
    };
    if (this.signatureOptions.signersOption === 'all') {
      this.addAllRecipients();
      this.signatureOptions.signingMode = 'parallel';
    }
  }

  onSelectOrEditSigners() {
    const dialogRef = this.injector.get(MatLegacyDialog).open(SelectSignersDialog, { data: this.signatureOptions });
    dialogRef.afterClosed().subscribe((signatureOptions: ISignatureOptions) => {
      if (check.not.assigned(signatureOptions) || check.emptyObject(signatureOptions)) {
        return;
      }
      this.signatureOptions = signatureOptions;
      return;
    });
  }

  onCancelSignerSelection() {
    this.signatureOptions = { ...this.resetSignatureOptions() };
  }

  onAddOrEditPersonalMessage() {
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddPersonalMessageDialog, { data: this.signatureOptions });
    dialogRef.afterClosed().subscribe((signatureOptions: ISignatureOptions) => {
      if (check.not.assigned(signatureOptions) || check.emptyObject(signatureOptions)) {
        return;
      }
      this.signatureOptions = signatureOptions;
      return;
    });
  }

  onDeletePersonalMessage() {
    const { personalMessage, ...signatureOptions } = this.signatureOptions;
    this.signatureOptions = { ...signatureOptions };
  }

  sendDocument(): void {
    this.sendingSignatureRequest = true;
    const dialogData = {
      titleText: this.pageTranslation.confirmSignatureRequestDialogTitle,
      subtitleText: this.pageTranslation.confirmSignatureRequestDialogSubtitle,
      confirmButtonText: this.pageTranslation.sendDocumentLabel,
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm !== true) {
        this.sendingSignatureRequest = false;
        return;
      }
      const formattedRecipients = this.signatureOptions.recipients.map((recipient) => {
        return {
          ...recipient,
          signerId: recipient._id,
          signerType: 'Employee',
        };
      });
      const formattedDocOwners: Array<string> = this.signatureOptions.docOwners.map((owner) => {
        return owner._id;
      });

      const signatureRequest: ISignatureCreateRequest = {
        recipients: formattedRecipients,
        docOwners: formattedDocOwners,
        documentId: this.documentId,
        signingMode: this.signatureOptions.signingMode,
        deliveryType: 'url',
        personalMessage: this.signatureOptions.personalMessage,
      };

      this.injector
        .get(SignatureRequestService)
        .createSignatureRequest(signatureRequest)
        .then(() => {
          this.injector.get(PrivateAmplitudeService).logEvent('request sign document', {
            platform: 'Web',
            category: 'Smart docs',
            subcategory1: this.document?._id,
            subcategory2: this.document?.ownerId,
          });

          this.sendingSignatureRequest = false;
          this.signatureOptions = this.resetSignatureOptions();
          this.currentMode = 'readMode';
          this.injector.get(MatLegacySnackBar).open(this.pageTranslation.signatureRequestCreatedSnackbarMessage, 'OK', {
            duration: 5000,
          });
          if (this.userCanManageSignatureRequest) {
            this.injector.get(Router).navigateByUrl('/cloud/documents/digital-signature');
          }
        })
        .catch((error) => {
          // do nothing
        });
    });
  }

  getCheckedCompanyValue(companyId: string): boolean {
    const checked = this.signatureOptions.companies.find((c) => c._id === companyId) !== undefined ? true : false;
    return checked;
  }

  changeSelectedCompanies(addingCompany: boolean, company: any): void {
    if (addingCompany) {
      this.signatureOptions.companies.push({ _id: company._id, name: company.name });
    } else {
      this.signatureOptions.companies = this.signatureOptions.companies.filter((c) => c._id !== company._id);
    }
    this.filterRecipients();
  }

  getCheckedOfficeValue(officeId: string): boolean {
    const checked = this.signatureOptions.offices.find((o) => o._id === officeId) !== undefined ? true : false;
    return checked;
  }

  changeSelectedOffices(addingOffice: boolean, office: any): void {
    if (addingOffice) {
      this.signatureOptions.offices.push({ _id: office._id, name: office.name });
    } else {
      this.signatureOptions.offices = this.signatureOptions.offices.filter((o) => o._id !== office._id);
    }
    this.filterRecipients();
  }

  getCheckedDepartmentValue(departmentId: string): boolean {
    const checked = this.signatureOptions.departments.find((d) => d._id === departmentId) !== undefined ? true : false;
    return checked;
  }

  changeSelectedDepartments(addingDepartment: boolean, department: any): void {
    if (addingDepartment) {
      this.signatureOptions.departments.push({ _id: department._id, name: department.name });
    } else {
      this.signatureOptions.departments = this.signatureOptions.departments.filter((d) => d._id !== department._id);
    }
    this.filterRecipients();
  }

  changeIncludeInactive(includeInactive: boolean) {
    this.signatureOptions.includeInactiveEmployees = includeInactive;
    this.filterRecipients();
  }

  get allEmployeesLabel(): string {
    if (
      this.signatureOptions.companies.length === 0 &&
      this.signatureOptions.offices.length === 0 &&
      this.signatureOptions.departments.length === 0
    ) {
      return this.pageTranslation.allEmployeesLabel;
    }

    const companies = this.signatureOptions.companies.map((c) => c.name);
    const offices = this.signatureOptions.offices.map((o) => o.name);
    const departments = this.signatureOptions.departments.map((d) => d.name);

    return [...companies, ...offices, ...departments].join(', ');
  }

  addAllRecipients() {
    this.signatureOptions.recipients = [...this.allUsers];
  }

  filterRecipients() {
    this.signatureOptions.recipients = this.allUsers.filter((u) => {
      if (!this.signatureOptions.includeInactiveEmployees && !u.isActive) {
        return false;
      }

      if (
        (this.signatureOptions.companies.length === 0 || this.signatureOptions.companies.find((c) => c._id === u.company)) &&
        (this.signatureOptions.offices.length === 0 || this.signatureOptions.offices.find((c) => c._id === u.office)) &&
        (this.signatureOptions.departments.length === 0 || this.signatureOptions.departments.find((c) => c._id === u.department))
      ) {
        return true;
      }

      return false;
    });
  }

  documentIsSignable(extension: string): void {
    this.isSignable = extension === 'pdf' || extension === 'doc' || extension === 'docx';
  }

  resetSignatureOptions(): ISignatureOptions {
    return {
      signersOption: 'specific',
      signingMode: 'sequential',
      recipients: [],
      docOwners: [],
      companies: [],
      offices: [],
      departments: [],
      includeInactiveEmployees: true,
    };
  }

  async getDocument(id: string) {
    try {
      let document = await this.injector.get(DocumentService).getById(id);
      if (check.assigned(document) && check.not.emptyObject(document)) {
        return document;
      }

      document = await this.injector.get(DocumentService).findWithPendingSignature(id);
      if (check.assigned(document) && check.not.emptyObject(document)) {
        this.isAPendingDocumentToSign = true;
        return document;
      }

      // If no document is found
      throw new Error();
    } catch {
      this.injector.get(Router).navigateByUrl('/cloud/home');
    }
  }

  private checkTags(): boolean {
    return this.documentModel.data.tags.length === 0 && this.areTagsMandatory;
  }

  private async fetchSmartDocsSettings() {
    try {
      this.smartDocsSettings = await this.injector.get(SmartDocsSettingsService).find();
      this.areTagsMandatory = this.smartDocsSettings.areTagsMandatory;
    } catch {}
  }

  //Filter tags for showing only the one already selected for the current document and the visible ones(by permissions and with active status)
  filterSelectableTags() {
    this.allDocumentTags = this.allDocumentTags.filter((iTag) => {
      return this.document.tags.includes(iTag._id) || iTag.isActive;
    });
  }

  isValidId(id: any) {
    return new RegExp('^[0-9a-fA-F]{24}$').test(id);
  }
}
