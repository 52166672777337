import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable()
export class FreebieDataGeneratorService {
  private FREEBIE_DATA_GENERATORL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/freebie-data-generator`;

  constructor(private injector: Injector) {}

  clearDummyData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, FreebieDataGeneratorService.name, 'clearDummyData');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
        body: { _id: { $ne: null } } // This specific case requires a body to pass the permissions middleware in backend
      };

      this.injector
        .get(HttpClient)
        .request('delete', `${this.FREEBIE_DATA_GENERATORL_URL}/clear-all`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, FreebieDataGeneratorService.name, 'clearDummyData'));
        });
    });
  }
}
