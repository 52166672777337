<orgos-dialog-container *ngIf="dialogTranslation" class="dsd-dialog-box" [title]="title" [disabledCloseButton]="isDeleting" (clickCloseButton)="closeDialog()">
  <div *ngIf="subtitle" class="kenjo-font-color-light-text-757575 dsd-white-space">{{ subtitle }}</div>
  <kenjo-shiftplan-notification-toggle class="kenjo-mt-40px kenjo-mb-80px" [parentTranslation]="dialogTranslation" [canSendNotifications]="canSendNotifications" [loading]="isDeleting" (toggleValue)="notificationToggle = $event"></kenjo-shiftplan-notification-toggle>
  <orgos-column-container *ngIf="isDeleting" class="dsd-loading-container" centerColumns="true">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <div orgosColumn="0" class="kenjo-pl-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{dialogTranslation.deletingLoading | uppercase}}</div>
  </orgos-column-container>
  <orgos-column-container *ngIf="!isDeleting" class="kenjo-mt-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised [id]="'dsd-cancel-published-button'" (click)="dialogRef.close()" color="Neutral">{{dialogTranslation.backButton}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised [id]="'dsd-delete-published-button'" (click)="deleteShift()" color="Danger">{{dialogTranslation.deleteButton}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
