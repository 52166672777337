import { Component, OnInit } from '@angular/core';
import { GRADIENT_COLORS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-gradient-color-picker',
  templateUrl: 'input-gradient-color-picker.component.html',
  styleUrls: ['input-gradient-color-picker.component.scss']
})
export class InputGradientColorPickerComponent extends InputAbstractComponent implements OnInit {
  colors: Array<any> = [];

  selectedColorKey: string = '';

  ngOnInit(): void {
    const value = super.getValue();
    this.colors = GRADIENT_COLORS.map((iColorKey: string) => {
      if (value === iColorKey) {
        this.selectedColorKey = iColorKey;
      }

      const orgosColorCss: string = iColorKey.replace(/_/gi, '-');
      const color = {
        colorKey: iColorKey,
        class: `orgos-${orgosColorCss.toLowerCase()}`
      };

      return color;
    });

    super.ngOnInit();
  }

  setValue(newColorKey: string): void {
    if (check.assigned(newColorKey) && this.readOnly === false) {
      this.selectedColorKey = newColorKey;
    }

    super.setValue(newColorKey);
  }
}
