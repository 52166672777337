import { Component, ElementRef, HostListener, Injector, Input, OnInit } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-collapsible-wrapper',
  templateUrl: 'collapsible-wrapper.component.html',
  styleUrls: ['collapsible-wrapper.component.scss']
})
export class CollapsibleWrapperComponent implements OnInit {
  @Input() maxHeight: number = 100;
  @Input() collapsedByDefault: boolean = false;

  isCollapsed: boolean = false;
  isCollapsable: boolean = false;
  pageTranslation: any = {};

  constructor(private injector: Injector, private elementRef: ElementRef) {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('collapsible-wrapper-component')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  ngOnInit(): void {
    if (this.collapsedByDefault === true) {
      this.isCollapsed = true;
      this.isCollapsable = true;
    } else {
      this.recalculateCollapsible();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (this.collapsedByDefault === true) {
      return;
    }
    this.recalculateCollapsible();
  }

  private recalculateCollapsible(): void {
    const currentHeight = this.elementRef.nativeElement.getElementsByClassName('collapsibleElement')[0].offsetHeight;
    if (currentHeight > this.maxHeight) {
      this.isCollapsed = true;
      this.isCollapsable = true;
    }
  }
}
