import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ITimeOffPolicyModel, PolicyCycleType, PolicyTypeType } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { ITimeOffTypeModel } from '@app/cloud-features/time-off/services/time-off-type.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
@Injectable({
  providedIn: 'root'
})
export class TimeOffUserPolicyController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/time-off-user-policy`;

  constructor(private injector: Injector) {}

  async getAllUserStatus(): Promise<Array<ITimeOffUserPolicy>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'getAllUserStatus');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get<Array<ITimeOffUserPolicy>>(`${this.URL}/status`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'getAllUserStatus');
    }
  }

  async getStatusByUserId(userId: string): Promise<Array<ITimeOffUserPolicy>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'getStatusByUserId');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get<Array<ITimeOffUserPolicy>>(`${this.URL}/status/${userId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'getStatusByUserId');
    }
  }

  async getNextStatusByUserIdAndPolicyId(userId: string, policyId: string): Promise<ITimeOffNextUserPolicyStatus> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'getNextStatusByUserIdAndPolicyId');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get<ITimeOffNextUserPolicyStatus>(`${this.URL}/next-status/${userId}/policy/${policyId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'getNextStatusByUserIdAndPolicyId');
    }
  }

  async calculatePreAssignment(preAssignmentInfo: Array<IPreAssignmentInfo>): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'calculatePreAssignmentData');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        preassignments: preAssignmentInfo
      };

      return await this.injector.get(HttpClient).post(`${this.URL}/preassignments`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'calculatePreAssignmentData');
    }
  }

  async isPolicyAssigned(policyId: string): Promise<boolean> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'isPolicyAssigned');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const result = await this.injector.get(HttpClient).get<any>(`${this.URL}/is-policy-assigned/${policyId}`, httpOptions).toPromise();
      return result.isAssigned;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'isPolicyAssigned');
    }
  }

  async getUserPolicies(userId: string): Promise<Array<ITimeOffUserPolicy>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'getUserPolicies');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).get<Array<ITimeOffUserPolicy>>(`${this.URL}/policies-assigned/${userId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'getUserPolicies');
    }
  }

  async getCommonPolicies(userIds: Array<string>, unlimitedAllowance: boolean): Promise<Array<ITimeOffUserPolicy>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'getCommonPolicies');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const body = {
        userIds,
        unlimitedAllowance
      };
      return await this.injector.get(HttpClient).post<Array<ITimeOffUserPolicy>>(`${this.URL}/common-policies-assigned`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'getCommonPolicies');
    }
  }

  async getPolicyAssignedUsers(policyId: string, queryOptions: any): Promise<IPolicyAssignedUserResponse> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'getPolicyAssignedUsers');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).post<IPolicyAssignedUserResponse>(`${this.URL}/assigned-users/${policyId}`, queryOptions, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'getPolicyAssignedUsers');
    }
  }

  async getManageEmployeeFilters(): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserPolicyController.name, 'getManageEmployeeFilters');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).get<any>(`${this.URL}/manage-policy-filters`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserPolicyController.name, 'getManageEmployeeFilters');
    }
  }
}

export interface ITimeOffUserPolicy {
  timeOffType: ITimeOffTypeModel;
  policy: ITimeOffPolicyModel;
  userWork: ITimeOffUserWork;
  currentStatus: ITimeOffStatus;
  nextStatus: ITimeOffStatus;
}
export interface ITimeOffUserWork {
  startDate?: string;
  isOnProbation?: boolean;
  reportsToId?: string;
}

export interface ITimeOffStatus {
  _id: string;
  _userId: string;
  _timeOffTypeId: string;
  _policyId: string;
  _policyType: PolicyTypeType;
  disabledCycle: boolean;
  cycleStatus: string;
  cycleStartDate?: string;
  cycleEndDate?: string;
  unlimitedAllowance: boolean;
  carryOverEnabled: boolean;
  planned?: number;
  taken?: number;
  accrued?: number;
  adjustAccrued?: number;
  adjustBalance?: number;
  adjustTaken?: number;
  adjustCarryOver?: number;
  givenCompensation?: number;
  negativeStartingBalance?: number;
  initialBalance?: number;
  currentBalance?: number;
  available?: number;
  carryOverExpireDate?: string;
  initialCarryOver?: number;
  currentCarryOver?: number;
  takenCarryOver: number;
  plannedCarryOver: number;
  expiredCarryOver?: number;
  hasExpiredCarryOver: boolean;
  givenExtraAllowance?: number;
  extraAllowanceEvents?: Array<{
    extraAllowanceDate: string;
    extraAllowanceTime: number;
  }>;
  accrualSplits: IAccrualSplits;
  replacedBy?: string;
  previousStatusId?: string;
  replacedAt?: string;
  scheduledBy?: string;
  scheduledAt?: string;
  scheduledFor?: string;
  dummyDate?: string;
}

export interface ITimeOffNextUserPolicyStatus {
  cycleStartDate: string;
  cycleEndDate: string;
  carryOverExpireDate: string;
  accrued: number;
  accrualSplits?: IAccrualSplits;
  givenExtraAllowance: number;
  scheduledPolicy?: ITimeOffPolicyModel;
  plannedBeforeExpireDate?: number;
  takenBeforeExpireDate?: number;
}

export interface ITimeOffNextCalculatedStatus {
  _policyType: PolicyTypeType;
  disabledCycle: boolean;
  cycleStatus: string;
  cycleStartDate?: string;
  cycleEndDate?: string;
  unlimitedAllowance: boolean;
  carryOverEnabled: boolean;
  taken?: number;
  planned?: number;
  accrued?: number;
  accrualSplits?: IAccrualSplits;
  adjustAccrued?: number;
  negativeStartingBalance?: number;
  initialBalance?: number;
  currentBalance?: number;
  available?: number;
  carryOverExpireDate?: string;
  initialCarryOver?: number;
  currentCarryOver?: number;
  plannedCarryOver?: number;
  takenCarryOver?: number;
  givenExtraAllowance?: number;
  extraAllowanceEvents?: Array<{
    extraAllowanceDate: string;
    extraAllowanceTime: number;
  }>;
}

export interface ITimeOffByUser {
  userId: string;
  status: {
    [key: string]: ITimeOffUserPolicy;
  };
}

export interface IPreAssignmentInfo {
  timeOffTypeId: string;
  policyId: string;
  userId?: string;
  applyDate: string;
  fixedDate?: Date;
  userInfo?: IPreAssignmentUserInfo;
}

export interface IPreAssignmentUserInfo {
  startDate?: Date;
  contractEnd?: Date;
  companyId: string;
  schedule: {
    mondayWorkingDay: boolean;
    tuesdayWorkingDay: boolean;
    wednesdayWorkingDay: boolean;
    thursdayWorkingDay: boolean;
    fridayWorkingDay: boolean;
    saturdayWorkingDay: boolean;
    sundayWorkingDay: boolean;
    history?: [
      {
        startDate: Date;
        dayShifts: [
          {
            minutes: number;
          }
        ];
      }
    ];
  };
}

export interface IStatusValues {
  totalAllowance?: number | string;
  cycleAllowance?: number | string;
  planned?: number | string;
  taken?: number | string;
  totalTakenPlanned?: number | string;
  carryOver?: number | string;
  expiredCarryOver?: number | string;
  currentCarryOver?: number | string;
  takenCarryOver?: number | string;
  plannedCarryOver?: number | string;
  available?: number | string;
  negativeStartingBalance?: number | string;
  givenExtraAllowance?: number | string;
  givenCompensation?: number | string;
  availableAfterExpireCarryOver?: number | string;
}

export interface IUserTimeOffCompany {
  userId: string;
  timeOffTypeName: string;
  timeOffTypeId?: string;
  policyId?: string;
  policyName: string;
  policyType?: PolicyTypeType;
  policyCycleType?: PolicyCycleType;
  unlimitedAllowance?: boolean;
  carryOverEnabled?: boolean;
  hasExpiredCarryOver?: boolean;
  taken?: number;
  adjustTaken?: number;
  planned?: number;
  plannedNextCycle?: number;
  carryOver?: number;
  initialCarryOver?: number;
  adjustCarryOver?: number;
  balance?: number;
  available?: number;
}

export interface ITimeOffUserPolicy {
  id: string;
  name: string;
  type: PolicyTypeType;
  timeOffTypeId: string;
}

export interface IPolicyAssignedUser {
  _id: string;
  displayName: string;
  _photo: any;
  cycleStartDate: Date;
  available: number;
  _createdAt: Date;
}

export interface IPolicyAssignedUserResponse {
  records: Array<IPolicyAssignedUser>;
  currentPage: number;
  pages: number;
  totalOfRecords: number;
}

export interface IAccrualSplits {
  total: number;
  startDate: number;
  endDate: number;
  partTime: number;
}
