<mat-form-field [appearance]="appearance" *ngIf="!readOnly && !optionsDisabled && multiSelect === false">
  <mat-label kenjoRemoveAriaOwns>{{ label }}</mat-label>
  <mat-select [panelClass]="panelClass" [ngModel]="value" (ngModelChange)="onValueChange($event)" [required]="required" #matSelect>
    <mat-option
      *ngFor="let option of options"
      [value]="option.value"
      [ngClass]="{
        'kenjo-font-weight-bold': !option.value && option.value !== 0,
        'issc-label-option': !option.value && option.value !== 0
      }"
      [disabled]="option.disabled"
    >
      <kenjo-simple-truncate-text>{{ option.name }}</kenjo-simple-truncate-text>
    </mat-option>
  </mat-select>
  <mat-icon
    *ngIf="showClearButton && matSelect.panelOpen === false"
    matSuffix
    class="issc-clear-button"
    (click)="clearValue(); matSelect.value = null; $event.stopPropagation()"
    >clear</mat-icon
  >
</mat-form-field>
<mat-form-field [appearance]="appearance" *ngIf="!readOnly && !optionsDisabled && multiSelect === true">
  <mat-label kenjoRemoveAriaOwns>{{ label }}</mat-label>
  <mat-select
    panelClass="kenjo-select-panel-multiple"
    multiple
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    [required]="required"
    #matSelectMultiple
  >
    <mat-option *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled">
      <kenjo-simple-truncate-text>{{ option.name }}</kenjo-simple-truncate-text>
    </mat-option>
  </mat-select>
  <mat-icon
    *ngIf="showClearButton && matSelectMultiple.panelOpen === false"
    matSuffix
    class="issc-clear-button"
    (click)="clearValue(); matSelectMultiple.value = null; $event.stopPropagation()"
    >clear</mat-icon
  >
</mat-form-field>
<mat-form-field [appearance]="appearance" *ngIf="readOnly && !optionsDisabled">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getValueName()" readOnly disabled [required]="required" />
</mat-form-field>
<mat-form-field [appearance]="appearance" *ngIf="optionsDisabled">
  <mat-label kenjoRemoveAriaOwns>{{ label }}</mat-label>
  <mat-select [ngModel]="value" [required]="required" #matSelect>
    <mat-option *ngFor="let option of options" [value]="option.value" disabled>
      <div>{{ option.name }}</div>
    </mat-option>
  </mat-select>
</mat-form-field>
<div *ngIf="!isValueValid" class="issc-error"><ng-content select="orgos-input-error"></ng-content></div>
