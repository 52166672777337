import { IAttendancePolicy, IBreakReminder } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { IAttendanceChangeTracking, IBreak, IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import * as check from 'check-types';
import * as moment from 'moment';

const MAX_SHIFT_LENGTH = 1440;

export const getCurrentMinutes = (): number => {
  const currentTime = moment();

  const hours = currentTime.hours();
  const minutes = currentTime.minutes();
  const totalMinutes = hours * 60 + minutes;

  return totalMinutes;
};

/**
 * Checks if a given date is after or the same as today's date.
 */
export const calculateDateIsAfterOrSameToday = (date: Date | moment.Moment): boolean => {
  return calculateDateIsAfterOrSame(date, new Date());
};

/**
 * Checks if a given date is after or the same as a reference date.
 * @param date The date to compare with a reference date.
 * @param referenceDate Date of reference.
 */
export const calculateDateIsAfterOrSame = (date: Date | moment.Moment, referenceDate: Date | moment.Moment): boolean =>
  moment(date).startOf('day').isSameOrAfter(moment(referenceDate).startOf('day'));

export const calculateIsFutureOpenEntry = (startTime: number, endTime: number, date: Date | moment.Moment): boolean => {
  return calculateIsOpenEntry(startTime, endTime) && calculateIsFutureEntry(startTime, endTime, date);
};

export const calculateIsOpenEntry = (startTime: number, endTime: number): boolean => {
  return (check.not.assigned(endTime) && check.assigned(startTime)) || (check.not.assigned(startTime) && check.assigned(endTime));
};

export const calculateIsCloseEntry = (userAttendance: IUserAttendanceModel): boolean => {
  return check.assigned(userAttendance?.startTime) && check.assigned(userAttendance?.endTime);
};

export const evaluateOverlappingTimeOff = (userAttendance: IUserAttendanceModel, timeOffOverlappingValidationIsActive: boolean): boolean => {
  return timeOffOverlappingValidationIsActive && check.assigned(userAttendance.timeOffEntryInfo);
};

export const calculateIsOpenEndTimeEntry = (startTime: number, endTime: number): boolean => {
  return check.assigned(startTime) && check.not.assigned(endTime);
};

export const calculateIsFutureEntry = (startTime: number, endTime: number, date: Date | moment.Moment): boolean => {
  return moment().isBefore(date, 'day') || ((endTime > getCurrentMinutes() || startTime > getCurrentMinutes()) && moment().isSame(date, 'day'));
};

export const calculateWasOvernight = (iUserAttendance: IUserAttendanceModel) => {
  return (
    calculateIsCloseEntry(iUserAttendance) &&
    iUserAttendance.endTime > MAX_SHIFT_LENGTH &&
    iUserAttendance.endTime - iUserAttendance.startTime > MAX_SHIFT_LENGTH
  );
};

export const calculateIsOpenBreak = (breakStartTime: number, breakEndTime: number): boolean => {
  return calculateIsOpenEntry(breakStartTime, breakEndTime);
};

export const calculateIsOpenShift = (shiftStartTime: number | undefined, shiftEndTime: number | undefined): boolean => {
  return calculateIsOpenEntry(shiftStartTime, shiftEndTime);
};

export const calculateTotalBreakDuration = (userAttendance: IUserAttendanceModel) => {
  if (checkNotAssignedBreak(userAttendance)) {
    return 0;
  }

  return userAttendance.breaks?.reduce((total, iBreak) => {
    if (check.not.assigned(iBreak?.duration) && check.assigned(iBreak?.end) && check.assigned(iBreak.start)) {
      iBreak.duration = iBreak.end - iBreak.start;
    }
    return total + (iBreak?.duration ?? 0);
  }, 0);
};

/**
 * Calculates the duration of a user's shift, including break times.
 *
 * @param {IUserAttendanceModel} userAttendance - The user's attendance record.
 * @returns {number} - The total shift duration in minutes, excluding breaks.
 */
export const calculateShiftDuration = (userAttendance: IUserAttendanceModel) => {
  const totalBreakTime = calculateTotalBreakDuration(userAttendance);
  const shiftDurationWithoutBreaks =
    userAttendance?.endTime < userAttendance?.startTime
      ? userAttendance?.endTime - userAttendance?.startTime + MAX_SHIFT_LENGTH
      : userAttendance?.endTime - userAttendance?.startTime;
  return shiftDurationWithoutBreaks - totalBreakTime;
};

/**
 * Calculates the duration of a user's shift, and the break duration separately.
 *
 * @param {IUserAttendanceModel} userAttendance - The user's attendance record.
 * @returns {number} - The total shift duration in minutes, excluding breaks.
 */
export const calculateShiftAndBreakDuration = (userAttendance: IUserAttendanceModel) => {
  const totalBreakTime = calculateTotalBreakDuration(userAttendance);
  const shiftDurationWithoutBreaks =
    userAttendance?.endTime < userAttendance?.startTime
      ? userAttendance?.endTime - userAttendance?.startTime + MAX_SHIFT_LENGTH
      : userAttendance?.endTime - userAttendance?.startTime;
  return { shiftDuration: shiftDurationWithoutBreaks, breakDuration: totalBreakTime };
};

export const calculateAutoDeductedBreaksDuration = (userAttendance: IUserAttendanceModel) => {
  const clonedUserAttendance = { ...userAttendance };
  if (!userAttendance?.breaks) return 0;
  clonedUserAttendance.breaks = clonedUserAttendance?.breaks.filter((iBreak) => iBreak.autoDeducted);
  return calculateTotalBreakDuration(clonedUserAttendance);
};

/**
 * Calculate the minutes worked in a given edited shift.
 * @param {Object} timeEntryBeingEdited - The time entry being edited.
 * @param {boolean} [withoutBreakTime=false] - Whether to exclude break time from the calculation.
 * @returns {number} - The minutes worked in the edited shift.
 */
export function calculateMinutesWorkedInEditedShift(
  timeEntryBeingEdited:
    | undefined
    | { index: number; userAttendance: IUserAttendanceModel; type: string | 'startTime' | 'endTime' | 'breakTime'; value: number },
  withoutBreakTime: boolean = false
): number {
  if (check.not.assigned(timeEntryBeingEdited?.userAttendance)) {
    return 0;
  }

  const endTime = timeEntryBeingEdited.userAttendance.endTime ?? 0;
  const startTime = timeEntryBeingEdited.userAttendance.startTime ?? 0;
  const breakTime = withoutBreakTime ? 0 : timeEntryBeingEdited.userAttendance.breakTime ?? 0;
  const minutesWorkedInEditedShift = timeEntryBeingEdited.userAttendance._deleted === false ? endTime - startTime - breakTime : 0;
  return minutesWorkedInEditedShift;
}

/**
 * Adjusts the start and end times of overnight breaks in a user's attendance record adding 1440 min if necessary.
 *
 * @param {IUserAttendanceModel} userAttendance - The user's attendance record.
 * @returns {void} - This function does not return a value. It modifies the break times in the input object.
 */
export const recalculateOvernightBreaks = (userAttendance: IUserAttendanceModel) => {
  userAttendance?.breaks?.forEach((iBreak) => {
    if (
      check.assigned(iBreak?.end) &&
      check.assigned(iBreak?.start) &&
      (iBreak.end < iBreak.start || iBreak.end <= userAttendance?.startTime)
    ) {
      iBreak.end += MAX_SHIFT_LENGTH;
    }

    if (check.assigned(iBreak.start) && iBreak.start < userAttendance?.startTime) {
      iBreak.start += MAX_SHIFT_LENGTH;
    }
  });
};

/**
 * Computes the duration for each break in the given array of breaks.
 *
 * @param {IBreak[]} breaks - Array of break records.
 * @returns {void} - This function does not return a value. It modifies the duration property of each break object in the input array.
 */
export const computeBreakDurations = (breaks: IBreak[]) => {
  if (!Array.isArray(breaks)) {
    return;
  }

  breaks?.forEach((iBreak) => {
    if (check.assigned(iBreak?.start) && check.assigned(iBreak?.end)) {
      iBreak.duration = iBreak.end < iBreak.start ? iBreak.end - iBreak.start + MAX_SHIFT_LENGTH : iBreak.end - iBreak.start;
    } else {
      iBreak.duration = 0;
    }
  });
};

export const isAnyBreakOpen = (userAttendance: IUserAttendanceModel): boolean => {
  return userAttendance?.breaks?.some((iBreak) => calculateIsOpenBreak(iBreak.start, iBreak.end));
};

/**
 * Checks if a new break can be added to the user's attendance.
 *
 * @param {IUserAttendanceModel} userAttendance - The user's attendance model.
 * @returns {boolean} - Returns true if a new break can be added, otherwise false.
 */
export const isAnyBreakIncomplete = (userAttendance: IUserAttendanceModel): boolean => {
  if (checkNotAssignedBreak(userAttendance)) {
    return false;
  }
  return userAttendance?.breaks?.some((iBreak) => {
    if (check.not.assigned(iBreak.start) || check.not.assigned(iBreak.end)) {
      return true;
    }
    return false;
  });
};

export const checkStartTimeBiggerThanEndTime = (startTime: number, endTime: number): boolean => {
  return check.assigned(endTime) && check.assigned(startTime) && endTime < startTime;
};

export const checkIsEmptyBreakOrShift = (userAttendance: IUserAttendanceModel): boolean => {
  return (
    check.emptyObject(userAttendance) ||
    check.emptyArray(userAttendance?.breaks) ||
    (check.not.assigned(userAttendance?.startTime) && check.not.assigned(userAttendance?.endTime))
  );
};

/**
 * @param {IUserAttendanceModel} userAttendance - The user's attendance record.
 * @returns {boolean} - Returns true if the shift is without breaks, otherwise false.
 */
export const checkShiftWithoutBreak = (userAttendance: IUserAttendanceModel): boolean => {
  return check.not.assigned(userAttendance?.breaks) && (check.not.assigned(userAttendance?.breakTime) || userAttendance?.breakTime === 0);
};

export const checkNotAssignedBreak = (userAttendance: IUserAttendanceModel): boolean => {
  return check.not.assigned(userAttendance?.breaks) || userAttendance?.breaks?.length === 0;
};

export const checkOldBreak = (userAttendance: IUserAttendanceModel): boolean => {
  return check.not.assigned(userAttendance?.breaks) && userAttendance?.breakTime > 0;
};

/**
 * Checks if a user's attendance shift is overnight.
 *
 * @param {IUserAttendanceModel} userAttendance - The user's attendance record.
 * @returns {boolean} - Returns true if the shift is overnight, otherwise false.
 */
export const checkIsOvernight = (userAttendance: IUserAttendanceModel): boolean => {
  if (userAttendance?.endTime > MAX_SHIFT_LENGTH) {
    userAttendance.endTime = userAttendance.endTime - MAX_SHIFT_LENGTH;
  }

  return calculateIsCloseEntry(userAttendance) ? userAttendance?.endTime < userAttendance?.startTime : false;
};

/**
 * Checks if a break conflicts with the start time of the user attendance shift.
 * @param {IBreak} iBreak - The break to be checked for conflict.
 * @param {IUserAttendanceModel} userAttendance - The user attendance object containing the shift start and end times.
 * @param {boolean} isOvernight - Indicates whether the shift start one day and ends another.
 * @returns {boolean} True if there is a conflict, otherwise false.
 */
export const checkShiftStartConflict = (iBreak: IBreak, userAttendance: IUserAttendanceModel, isOvernight: boolean) => {
  if (check.not.assigned(userAttendance?.startTime)) {
    return true;
  }
  const endTime = setRealEndTime(userAttendance);
  if (!isOvernight) {
    if (
      (check.assigned(iBreak.start) && iBreak.start < userAttendance?.startTime) ||
      (check.assigned(iBreak.end) && check.assigned(iBreak.start) && iBreak.end <= userAttendance?.startTime)
    ) {
      return true;
    }
  } else {
    if (iBreak.start < userAttendance?.startTime && check.assigned(endTime) && iBreak.start + MAX_SHIFT_LENGTH > endTime) {
      return true;
    }
  }
  return false;
};

/**
 * Checks if a break conflicts with the end time of the user attendance shift.
 * @param {IBreak} iBreak - The break to be checked for conflict.
 * @param {IUserAttendanceModel} userAttendance - The user attendance object containing the shift start and end times.
 * @param {boolean} isOvernight - Indicates whether the shift start one day and ends another.
 * @returns {boolean} True if there is a conflict, otherwise false.
 */
export const checkShiftEndConflict = (iBreak: IBreak, userAttendance: IUserAttendanceModel, isOvernight: boolean): boolean => {
  const endTime = setRealEndTime(userAttendance);
  if (!isOvernight) {
    if (
      (check.assigned(endTime) && check.assigned(iBreak?.start) && iBreak?.start >= endTime) ||
      (check.assigned(endTime) && check.assigned(iBreak?.end) && iBreak?.end > endTime)
    ) {
      return true;
    }
  } else {
    const end = userAttendance?.startTime > iBreak?.end ? iBreak?.end + MAX_SHIFT_LENGTH : iBreak?.end;
    if (end > endTime || iBreak?.duration > endTime - userAttendance?.startTime) {
      return true;
    }
  }
  return false;
};

export const checkShiftInsideTimeOff = (allShifts: IUserAttendanceModel[], userAttendance: IUserAttendanceModel): boolean => {
  userAttendance.endTime = setRealEndTime(userAttendance);
  const isWithingTimeOff = allShifts?.some((iShift) => {
    if (iShift?._timeOffRequestId) {
      if (
        (iShift?.startTime < userAttendance?.startTime && iShift?.endTime > userAttendance?.startTime) ||
        (iShift?.startTime < userAttendance?.endTime && iShift?.endTime > userAttendance?.endTime) ||
        (iShift?.startTime > userAttendance?.startTime && iShift?.startTime < userAttendance?.endTime) ||
        (iShift?.endTime > userAttendance?.startTime && iShift?.endTime <= userAttendance?.endTime)
      ) {
        return true;
      }
    }
  });
  return isWithingTimeOff;
};

export const checkBreakInsideTimeOff = (allShifts: IUserAttendanceModel[], userAttendance: IUserAttendanceModel): boolean => {
  userAttendance.endTime = setRealEndTime(userAttendance);
  let isBreakWithingTimeOff = false;
  for (let index = 0; index < allShifts?.length; index++) {
    if (allShifts[index]?._timeOffRequestId && userAttendance?.breaks) {
      isBreakWithingTimeOff = userAttendance?.breaks.some((iBreak) => {
        if (
          (allShifts[index]?.startTime < iBreak?.start && allShifts[index]?.endTime > iBreak?.start) ||
          (allShifts[index]?.startTime < iBreak?.end && allShifts[index]?.endTime > iBreak?.end) ||
          (allShifts[index]?.startTime > iBreak?.start && allShifts[index]?.startTime < iBreak?.end) ||
          (allShifts[index]?.endTime > iBreak?.start && allShifts[index]?.endTime <= iBreak?.end)
        ) {
          return true;
        }
      });
      if (isBreakWithingTimeOff) break;
    }
  }

  return isBreakWithingTimeOff;
};

export const checkBreakInsideShift = (allShifts: IUserAttendanceModel[], userAttendance: IUserAttendanceModel): boolean => {
  let isBreakWithingShift = false;
  for (let index = 0; index < allShifts?.length; index++) {
    if (allShifts[index] && userAttendance?.breaks) {
      isBreakWithingShift = userAttendance?.breaks.some((iBreak) => {
        if (
          (allShifts[index]?.startTime < iBreak?.start && allShifts[index]?.endTime > iBreak?.start) ||
          (allShifts[index]?.startTime < iBreak?.end && allShifts[index]?.endTime > iBreak?.end) ||
          (allShifts[index]?.startTime > iBreak?.start && allShifts[index]?.startTime < iBreak?.end) ||
          (allShifts[index]?.endTime > iBreak?.start && allShifts[index]?.endTime <= iBreak?.end)
        ) {
          return true;
        }
      });
      if (isBreakWithingShift) break;
    }
  }

  return isBreakWithingShift;
};

/**
 * Checks for overlapping breaks in a user-attendance.
 *
 * @param {IUserAttendanceModel} userAttendance - The user's attendance model.
 * @param {boolean} isOvernight - Indicates if the user's shift is overnight.
 * @param {IBreak[]} sortedBreaks - The sorted array of breaks.
 * @returns {boolean} - Returns true if there are overlapping breaks, otherwise false.
 */
export const checkBreaksOverlapping = (userAttendance: IUserAttendanceModel, isOvernight: boolean, sortedBreaks: IBreak[]) => {
  let overlappingConflict = false;
  if (checkNotAssignedBreak(userAttendance)) {
    return false;
  }
  sortedBreaks.forEach((iBreak, i) => {
    if (check.not.assigned(iBreak?.conflicts)) {
      iBreak.conflicts = {};
    }
    if (i === 0) {
      iBreak.conflicts.overlappingStart = false;
      iBreak.conflicts.overlappingEnd = false;
    }

    iBreak.conflicts.sameStartAndEnd =
      check.assigned(iBreak?.start) &&
      check.assigned(iBreak?.end) &&
      (iBreak?.end === iBreak?.start || (check.assigned(userAttendance.endTime) && !isOvernight && iBreak?.end <= iBreak?.start));
    if (iBreak?.conflicts?.sameStartAndEnd) {
      overlappingConflict = true;
    }

    if (sortedBreaks?.length === i + 1) {
      return;
    }
    const iBreakStartAdjusted = adjustBreakStart(iBreak, userAttendance);
    const iBreakEndAdjusted = adjustBreakEnd(iBreak, userAttendance);
    for (let j = i + 1; j < sortedBreaks?.length; j++) {
      const jBreak = sortedBreaks[j];
      if (check.not.assigned(jBreak.conflicts)) {
        jBreak.conflicts = {};
      }
      const jBreakStartAdjusted = adjustBreakStart(jBreak, userAttendance);
      const jBreakEndAdjusted = adjustBreakEnd(jBreak, userAttendance);

      jBreak.conflicts.overlappingStart =
        jBreakStartAdjusted === iBreakStartAdjusted ||
        (jBreakStartAdjusted >= iBreakStartAdjusted && jBreakStartAdjusted < iBreakEndAdjusted);
      jBreak.conflicts.overlappingEnd =
        check.assigned(jBreak?.end) && check.assigned(iBreak?.end) && jBreakEndAdjusted <= iBreakEndAdjusted;

      if (jBreak?.conflicts?.overlappingStart || jBreak?.conflicts?.overlappingEnd) {
        overlappingConflict = true;
      }
    }
  });
  return overlappingConflict;
};

/**
 * Checks if the given user's attendance shift overlaps with any other shifts on the same day.
 *
 * @param {IUserAttendanceModel[]} otherShiftsThisDay - Array of user attendance records for the same day.
 * @param {IUserAttendanceModel} userAttendance - The user's attendance record to check for overlapping.
 * @returns {boolean} - Returns true if there is an overlapping shift, otherwise false.
 */
export const checkShiftOverlapping = (otherShiftsThisDay: IUserAttendanceModel[], userAttendance: IUserAttendanceModel) => {
  if (check.not.assigned(otherShiftsThisDay) || otherShiftsThisDay?.length === 0) {
    return false;
  }
  const shiftOverlapping = otherShiftsThisDay.some((iUserAttendance) => {
    const areBothEntriesClose = calculateIsCloseEntry(iUserAttendance) && calculateIsCloseEntry(userAttendance);
    if (areBothEntriesClose) {
      const overlappingStartTime =
        userAttendance?.startTime < iUserAttendance?.endTime && userAttendance?.startTime >= iUserAttendance?.startTime;
      const overlappingEndTime =
        (userAttendance?.endTime > iUserAttendance.startTime && userAttendance?.endTime <= iUserAttendance.endTime) ||
        (userAttendance?.startTime <= iUserAttendance.startTime && userAttendance?.endTime >= iUserAttendance.endTime);
      return overlappingStartTime || overlappingEndTime;
    }
    return false;
  });

  return shiftOverlapping;
};

export const checkPolicyHasConflictsActivated = (attendancePolicy: IAttendancePolicy) => {
  return (
    attendancePolicy?.limitDailyHours?.conflicts ||
    attendancePolicy?.breakReminder?.conflicts ||
    attendancePolicy?.overlappingWithAbsence?.nonWorkingDays ||
    attendancePolicy?.overlappingWithAbsence?.publicHolidays ||
    attendancePolicy?.restrictCheckIn?.conflicts ||
    attendancePolicy?.missingFullEntry?.isActive ||
    attendancePolicy?.entryNotCompleted?.isActive
  );
};

export const checkRemindersAreEqual = (firstReminder: IBreakReminder[], secondReminder: IBreakReminder[]) => {
  if (firstReminder?.length !== secondReminder?.length || check.not.assigned(firstReminder) || check.not.assigned(secondReminder)) {
    return false;
  }

  if (firstReminder?.length === 0 && secondReminder?.length === 0) {
    return true;
  }

  for (let i = 0; i < firstReminder.length; i++) {
    const reminder1 = firstReminder[i];
    const reminder2 = secondReminder[i];

    if (reminder1.triggeredAfter !== reminder2.triggeredAfter || reminder1.suggestedBreak !== reminder2.suggestedBreak) {
      return false;
    }
  }
  return true;
};

/**
 * Checks if there is any conflict between the user attendance shift and its breaks.
 * @param {IUserAttendanceModel} userAttendance - The user attendance object containing the shift start and end times, and breaks.
 * @returns {boolean} True if there is a conflict, otherwise false.
 */
export const hasShiftBreakConflict = (userAttendance: IUserAttendanceModel): boolean => {
  if (check.not.assigned(userAttendance?.breaks) || userAttendance?.breaks?.length === 0) {
    return false;
  }

  if (check.not.assigned(userAttendance?.startTime)) {
    return true;
  }

  let shiftStartConflict = false;
  let shiftEndConflict = false;
  const isOvernight = userAttendance?.endTime > MAX_SHIFT_LENGTH;

  userAttendance.breaks.forEach((iBreak) => {
    shiftStartConflict = checkShiftStartConflict(iBreak, userAttendance, isOvernight);
    shiftEndConflict = checkShiftEndConflict(iBreak, userAttendance, isOvernight);
  });
  return shiftStartConflict || shiftEndConflict;
};

/**
 * Splits the 'newValue' and 'oldValue' fields of an attendance change tracking object into 'newStart' and 'newEnd', 'oldStart' and 'oldEnd'.
 *
 * @param {IAttendanceChangeTracking} iChange - The attendance change tracking object.
 * @returns {IAttendanceChangeTracking} - The modified attendance change tracking object with split break times.
 */
export const splitChangeTrackingBreaks = (iChange: IAttendanceChangeTracking) => {
  if (iChange?.fieldChanged === 'breaks') {
    if (!iChange?.newValue) {
      iChange.newStart = null;
      iChange.newEnd = null;
    } else {
      const [newStart, newEnd] = iChange?.newValue?.split('-');
      iChange.newStart = newStart;
      iChange.newEnd = newEnd;
    }

    if (!iChange?.oldValue) {
      iChange.oldStart = null;
      iChange.oldEnd = null;
    } else {
      const [oldStart, oldEnd] = iChange?.oldValue?.split('-');
      iChange.oldStart = oldStart;
      iChange.oldEnd = oldEnd;
    }
  }
  return iChange;
};

export const sortBreaks = (userAttendance: IUserAttendanceModel, breaks: IBreak[]) => {
  if (!Array.isArray(breaks)) {
    return;
  }
  const shiftStartsAt = userAttendance?.startTime;
  breaks.sort((iBreak, jBreak) => {
    let iBreakStartAdjusted = iBreak.start < shiftStartsAt ? iBreak.start + MAX_SHIFT_LENGTH : iBreak.start;
    let jBreakStartAdjusted = jBreak.start < shiftStartsAt ? jBreak.start + MAX_SHIFT_LENGTH : jBreak.start;

    return iBreakStartAdjusted - jBreakStartAdjusted;
  });
};

/**
 * Adjusts the start and end times of breaks if they exceed the number of minutes in a day.
 *
 * @param {Array<IBreak>} breaks - The array of break objects, each containing a `start` and `end` time.
 * @param {number} minutesInDay - The total number of minutes in a day (1440).
 * @returns {void} - This function does not return a value. It modifies the duration property of each break object in the input array.
 */
export const adjustBreakTimes = (breaks: IBreak[]) => {
  if (!Array.isArray(breaks)) {
    return;
  }

  breaks.forEach((iBreak) => {
    if (iBreak.end > MAX_SHIFT_LENGTH) {
      iBreak.end -= MAX_SHIFT_LENGTH;
    }

    if (iBreak.start > MAX_SHIFT_LENGTH) {
      iBreak.start -= MAX_SHIFT_LENGTH;
    }
  });
};

export const adjustBreakStart = (iBreak: IBreak, userAttendance: IUserAttendanceModel) =>
  iBreak.start < userAttendance.startTime ? iBreak.start + MAX_SHIFT_LENGTH : iBreak.start;

export const adjustBreakEnd = (iBreak: IBreak, userAttendance: IUserAttendanceModel) =>
  iBreak?.end < userAttendance?.startTime ? iBreak?.end + MAX_SHIFT_LENGTH : iBreak?.end;

/**
 * Calculates the real breaks time based on the provided user attendance object.
 * If the break end time is assigned and is less than the start time, adds 1440 minutes (24 hours) to it.
 * @param {IUserAttendanceModel} userAttendance - The user attendance object containing start and end times.
 * @returns {void} - This function does not return a value. It modifies each break object of the userAttendance.
 */
export const setRealBreaksTime = (userAttendance: IUserAttendanceModel): void => {
  userAttendance.breaks.forEach((iBreak) => {
    if (
      check.assigned(iBreak?.end) &&
      check.assigned(iBreak?.start) &&
      (iBreak.end < iBreak.start || iBreak.end <= userAttendance.startTime)
    ) {
      iBreak.end += MAX_SHIFT_LENGTH;
    }

    if (check.assigned(iBreak?.start) && iBreak.start < userAttendance.startTime) {
      iBreak.start += MAX_SHIFT_LENGTH;
    }
  });
};

/**
 * Calculates the real end time based on the provided user attendance object.
 * If the end time is not assigned, returns undefined.
 * If the end time is assigned and is less than the start time, adds 1440 minutes (24 hours) to it.
 * @param {IUserAttendanceModel} userAttendance - The user attendance object containing start and end times.
 * @returns {number | undefined} The calculated real end time in minutes, or undefined if not assigned.
 */
export const setRealEndTime = (userAttendance: IUserAttendanceModel) => {
  return check.assigned(userAttendance?.endTime)
    ? userAttendance?.endTime < userAttendance?.startTime
      ? userAttendance.endTime + MAX_SHIFT_LENGTH
      : userAttendance?.endTime
    : undefined;
};

export const isAutoDeductBreakNeeded = (userAttendance: IUserAttendanceModel, attendancePolicy: IAttendancePolicy, profileKey: string) => {
  const reminders = !['admin', 'hr-admin'].includes(profileKey) ? attendancePolicy.breakReminder.reminders : [];
  const shiftsAndBreaks = calculateShiftAndBreakDuration(userAttendance);
  if (check.nonEmptyArray(reminders) && attendancePolicy.breakReminder.autoDeductBreaks) {
    for (const iReminder of reminders) {
      if (
        iReminder.triggeredAfter <= shiftsAndBreaks.shiftDuration - shiftsAndBreaks.breakDuration &&
        iReminder.suggestedBreak > shiftsAndBreaks.breakDuration
      ) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Check if two attendances are equal.
 * @param {IUserAttendanceModel} originalAttendance - The original attendance data.
 * @param {IUserAttendanceModel} attendanceToCompare - The attendance data to compare.
 * @returns {boolean} - True if the attendances are equal, false otherwise.
 */
export function areAttendancesEqual(originalAttendance: IUserAttendanceModel, attendanceToCompare: IUserAttendanceModel): boolean {
  let areAttendancesEqual = true;

  if (check.assigned(attendanceToCompare)) {
    Object.keys(attendanceToCompare).forEach((key: string) => {
      if (
        (originalAttendance[key] !== undefined && originalAttendance[key] !== attendanceToCompare[key]) ||
        (originalAttendance[key] === undefined && check.assigned(attendanceToCompare[key]))
      ) {
        areAttendancesEqual = false;
      }
    });
  }

  return areAttendancesEqual;
}
