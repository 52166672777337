import { Component, Injector, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { IWorkScheduleTemplateModel } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { TimeTableComponent } from '@app/common-components/time-table/component/time-table.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-working-hours',
  templateUrl: 'working-hours.component.html',
  styleUrls: ['working-hours.component.scss']
})
export class WorkingHoursComponent implements OnInit {
  translations: any = {};
  pickListTranslations: any = {};
  templateType: Array<ISelectOption> = [];

  @Input() workScheduleTemplate: IWorkScheduleTemplateModel;
  @Input() editMode: boolean = false;
  @ViewChild(TimeTableComponent) timeTable: TimeTableComponent;
  @Output() validSave: EventEmitter<boolean> = new EventEmitter();
  readOnly: boolean = false;
  translatedWeekdays: Array<string> = [];

  constructor(private injector: Injector) {}
  ngOnInit(): void {
    this.translatedWeekdays = this.injector.get(InternationalizationService).getShortTranslatedWeekdays();
    this.fetchData();
  }

  private fetchData(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('working-hours-component')
      .then((pageTranslation) => {
        this.translations = pageTranslation;
      })
      .catch(() => {
        this.translations = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((standardTranslation) => {
        this.pickListTranslations = standardTranslation;
        this.templateType.push({ name: standardTranslation.workScheduleTemplateType.flexible, value: 'flexible' });
        this.templateType.push({ name: standardTranslation.workScheduleTemplateType.fixed, value: 'fixed' });
      })
      .catch(() => {
        this.pickListTranslations = {};
      });
  }

  public clickOnDay(weekDay: number): void {
    if (check.assigned(this.workScheduleTemplate.dayShifts[weekDay].minutes)) {
      this.workScheduleTemplate.dayShifts[weekDay] = {
        shifts: [{ start: undefined, end: undefined }],
        minutes: undefined
      };
      this.timeTable.calculateMinutes();
      return;
    }
    this.workScheduleTemplate.dayShifts[weekDay] = {
      shifts: [{ start: 0, end: 0 }],
      minutes: 0
    };
    this.timeTable.calculateMinutes();
  }

  public updateTable(value: string): void {
    if (value === pickLists.WORK_SCHEDULE_TEMPLATE_TYPE_FIXED) {
      this.timeTable.resetTimeTable();
    }
  }

  emitValidSave(isValid:boolean): void {
    this.validSave.emit(isValid);
  }
}
