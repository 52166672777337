import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable()
export class SingleSignOnService {
  private SINGLE_SIGN_ON_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/single-sign-on-db`;

  constructor(private http: HttpClient, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService) {}

  activateProvider(provider: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'activateProvider');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const body = {
        provider: provider
      };

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.SINGLE_SIGN_ON_URL}/providers`, body, httpOptions)
        .toPromise()
        .then((ssoProvider: any) => {
          resolve(ssoProvider);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SingleSignOnService.name, 'activateProvider'));
        });
    });
  }

  getProvider(provider: string): Promise<ISSOProvider> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'getProvider');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.SINGLE_SIGN_ON_URL}/providers/${provider}`, httpOptions)
        .toPromise()
        .then((ssoProvider: ISSOProvider) => {
          resolve(ssoProvider);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SingleSignOnService.name, 'getProvider'));
        });
    });
  }

  async getAllProviders(): Promise<Array<ISSOProvider>> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'getAllProviders');
        throw this.errorManager.handleRawError(error);
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const ssoProviders = await this.http.get(`${this.SINGLE_SIGN_ON_URL}/providers`, httpOptions).toPromise();
      return ssoProviders as Array<ISSOProvider>;
    } catch {
      return [];
    }
  }

  deactivateProvider(provider: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'deactivateProvider');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .delete(`${this.SINGLE_SIGN_ON_URL}/providers/${provider}`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SingleSignOnService.name, 'deactivateProvider'));
        });
    });
  }

  addDomain(provider: string, domain: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'addDomain');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const body = {
        domain: domain
      };

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.SINGLE_SIGN_ON_URL}/providers/${provider}/domains`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SingleSignOnService.name, 'addDomain'));
        });
    });
  }

  removeDomain(provider: string, domain: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'removeDomain');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .delete(`${this.SINGLE_SIGN_ON_URL}/providers/${provider}/domains/${domain}`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SingleSignOnService.name, 'removeDomain'));
        });
    });
  }

  async removeAllDomains(provider: string, domains: Array<string>): Promise<void> {
    if (!domains?.length) {
      return;
    }

    try {
      const listPromises = domains.map((domain: string) => this.removeDomain(provider, domain));
      await Promise.all(listPromises);
    } catch(error) {
      throw this.errorManager.handleRawError(error, SingleSignOnService.name, 'removeAllDomains');
    }
  }

  checkIfMandatory(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'checkIfMandatory');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const body = {
        domain: email.split('@')[1]
      };

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.SINGLE_SIGN_ON_URL}/check-if-mandatory`, body, httpOptions)
        .toPromise()
        .then((isSSOMandatoryData) => {
          resolve(isSSOMandatoryData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SingleSignOnService.name, 'checkIfMandatory'));
        });
    });
  }

  async checkIfAnyProviderIsEnabled(): Promise<any> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, SingleSignOnService.name, 'checkIfAnyProviderIsEnabled');
        throw this.errorManager.handleRawError(error);
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const isAnyProviderEnabled = await this.http.get(`${this.SINGLE_SIGN_ON_URL}/check-if-enabled-providers`, httpOptions).toPromise();

      return isAnyProviderEnabled;
    } catch (error) {
      throw this.errorManager.handleRawError(error, SingleSignOnService.name, 'checkIfAnyProviderIsEnabled');
    }
  }
}

export interface ISSOProvider {
  _id?: string;
  domains?: Array<string>;
  provider?: string;
}
