<div *ngIf="!loading && previewAvailable" class="pc-main-container" (mouseenter)="mouseInDocument = true" (mouseleave)="mouseInDocument = false">
  <orgos-action *ngIf="showDownloadButton && mouseInDocument" class="kenjo-mb-20px pc-download-button kenjo-font-size-25px kenjo-font-color-white-ffffff kenjo-border-radius-4px" icon="file_download" (click)="download()"></orgos-action>
  <!-- PDF -->
  <div *ngIf="fileMajorType === 'document'" class="pc-document-container">
    <div class="pc-document-inner-container">
      <orgos-pdf-render [pdfUrl]="metadata._url"></orgos-pdf-render>
      <div class="pc-spacer"></div>
    </div>
  </div>

  <!-- OFFICE -->
  <div *ngIf="fileMajorType === 'office'" class="pc-document-container">
    <div class="pc-document-inner-container">
      <orgos-pdf-render [pdfUrl]="metadata._convertedPdfUrl"></orgos-pdf-render>
      <div class="pc-spacer"></div>
    </div>
  </div>

  <!-- IMAGE -->
  <img *ngIf="fileMajorType === 'image'" class="pc-image" [src]="metadata._url" />

  <!-- TEXT -->
  <div *ngIf="fileMajorType === 'text'" class="pc-text-container">
    <div>
      {{ fileTextContent }}
    </div>
  </div>

  <!-- AUDIO -->
  <div *ngIf="fileMajorType === 'audio'" class="pc-audio-container">
    <div class="kenjo-font-size-20px kenjo-font-color-white-ffffff kenjo-mb-20px">{{ metadata._fileName }}</div>
    <audio controls class="pc-audio">
      <source [src]="metadata._url" [type]="metadata._fileMimetype" (error)="showPreviewNotAvailable()" />
    </audio>
  </div>

  <!-- VIDEO -->
  <div *ngIf="fileMajorType === 'video'" class="pc-video-container">
    <div class="kenjo-font-size-20px kenjo-font-color-white-ffffff kenjo-mb-20px">{{ metadata._fileName }}</div>
    <video controls>
      <source [src]="metadata._url" [type]="metadata._fileMimetype" (error)="showPreviewNotAvailable()" />
    </video>
  </div>
</div>

<!-- PREVIEW NOT AVAILABLE -->
<div *ngIf="!loading && !previewAvailable" class="pc-no-preview-container">
  <div class="kenjo-text-align-center" *ngIf="!isPDF && pageTranslation">
    <img src="/assets/images/pdf-format-preview.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-color-text-333333">{{ pageTranslation.noPreviewTitle }}</div>
    <div class="kenjo-mt-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ pageTranslation.noPreviewDescription }}</div>
    <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ pageTranslation.previewDownload }}</div>
    <orgos-button-raised color="Success" (click)="download()" class="kenjo-mt-40px"> {{ pageTranslation.buttonDownload | uppercase }} </orgos-button-raised>
  </div>
</div>

<!-- LOADING -->
<div *ngIf="loading" class="pc-loading-container">
  <orgos-loading-spinner></orgos-loading-spinner>
</div>
