import { Routes } from '@angular/router';
import { CloudGuard } from '@app/core-features/_guards/cloud.guard';
import { CompanyVibeHashGuard } from '@app/core-features/_guards/company-vibe-hash.guard';
import { EmployeeOnboardingGuard } from '@app/core-features/_guards/employee-onboarding.guard';
import { NewUserHashGuard } from '@app/core-features/_guards/new-user-hash.guard';
import { NotAuthenticatedGuard } from '@app/core-features/_guards/not-authenticated.guard';
import { OnboardingFreeTrialGuard } from '@app/core-features/_guards/onboarding-free-trial.guard';
import { RecruitingAgencyHashGuard } from '@app/core-features/_guards/recruiting-agency-hash.guard';
import { ResetPasswordPolicyHashMobileGuard } from '@app/core-features/_guards/reset-password-hash-mobile.guard';
import { ResetPasswordHashGuard } from '@app/core-features/_guards/reset-password-hash.guard';
import { ResetPasswordPolicyGuard } from '@app/core-features/_guards/reset-password-policy.guard';
import { SignInWithDatevGuard } from '@app/core-features/_guards/sign-in-with-datev.guard';
import { SignInWithGoogleGuard } from '@app/core-features/_guards/sign-in-with-google.guard';
import { SignInWithMicrosoftGuard } from '@app/core-features/_guards/sign-in-with-microsoft.guard';
import { SupportHashGuard } from '@app/core-features/_guards/support-hash.guard';
import { WhistleblowerHashGuard } from '@app/core-features/_guards/whistleblower-hash.guard';
import { environment } from '@env';

export const CoreFeaturesRoutes: Routes = [
  { path: 'signin', canLoad: [NotAuthenticatedGuard], canActivate: [NotAuthenticatedGuard], loadChildren: () => import('./sign-in/sign-in.module').then((mod) => mod.SignInModule) },
  { path: 'signin-with-google', canActivate: [SignInWithGoogleGuard], children: [] },
  { path: 'signin-with-microsoft', canActivate: [SignInWithMicrosoftGuard], children: [] },
  { path: 'signin-with-datev', canActivate: [SignInWithDatevGuard], children: [] },

  { path: `signup-free-trial/${environment.SIGN_UP_FREE_TRIAL_KEY}`, canLoad: [NotAuthenticatedGuard], canActivate: [NotAuthenticatedGuard], loadChildren: () => import('./sign-up-free-trial/sign-up-free-trial.module').then((mod) => mod.SignUpFreeTrialModule) },
  // { path: 'signup-customer', canLoad: [NotAuthenticatedGuard], canActivate: [NotAuthenticatedGuard], loadChildren: () => import('./sign-up-customer/sign-up-customer.module').then((mod) => mod.SignUpCustomerModule) },
  // { path: 'signup-customer-with-google', canActivate: [SignUpWithGoogleGuard], children: [] },
  { path: 'o/:hash/onboarding', canLoad: [OnboardingFreeTrialGuard], loadChildren: () => import('./onboarding/onboarding.module').then((mod) => mod.OnboardingModule) },
  { path: 'forgot-password', canLoad: [NotAuthenticatedGuard], canActivate: [NotAuthenticatedGuard], loadChildren: () => import('./forgot-password/forgot-password.module').then((mod) => mod.ForgotPasswordModule) },
  { path: 'p/:hash', canLoad: [ResetPasswordHashGuard], canActivate: [ResetPasswordHashGuard], loadChildren: () => import('./reset-password/reset-password.module').then((mod) => mod.ResetPasswordModule) },
  { path: 'reset-password', canActivate: [ResetPasswordPolicyGuard], loadChildren: () => import('./reset-password/reset-password.module').then((mod) => mod.ResetPasswordModule) },
  { path: 'reset-password/:hash', canActivate: [ResetPasswordPolicyHashMobileGuard], loadChildren: () => import('./reset-password/reset-password.module').then((mod) => mod.ResetPasswordModule) },

  { path: 'e/:hash', canLoad: [NewUserHashGuard], canActivate: [NewUserHashGuard], loadChildren: () => import('./employee-onboarding/employee-onboarding.module').then((mod) => mod.EmployeeOnboardingModule) },
  { path: 'employee-onboarding', canLoad: [EmployeeOnboardingGuard], canActivate: [EmployeeOnboardingGuard], loadChildren: () => import('./employee-onboarding/employee-onboarding.module').then((mod) => mod.EmployeeOnboardingModule) },

  { path: 'cvibe/:hash/survey/:surveyId', canLoad: [CompanyVibeHashGuard], canActivate: [CompanyVibeHashGuard], loadChildren: () => import('./survey/survey.module').then((mod) => mod.SurveyModule) },

  { path: 's/:hash', canActivate: [SupportHashGuard], children: [] },

  { path: 'a/:hash', canActivate: [RecruitingAgencyHashGuard], children: [] },
  { path: 'agency', loadChildren: () => import('./recruiting-agencies/recruiting-agencies.module').then((mod) => mod.RecruitingAgenciesModule) },

  { path: 'whistleblower/:portalIdentifier', canLoad: [WhistleblowerHashGuard], canActivate: [WhistleblowerHashGuard], loadChildren: () => import('./whistleblower-portal/whistleblower-portal.module').then((mod) => mod.WhistleblowerPortalModule) },

  { path: 'cloud', canLoad: [CloudGuard], canActivate: [CloudGuard], loadChildren: () => import('./cloud/cloud.module').then((mod) => mod.CloudModule) }
];
