import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class SurveyService {
  private SURVEY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/survey-db`;
  private SURVEY_PERMISSIONS_KEY: string = 'survey';
  private SURVEY_INTERNATIONALIZATION: string = 'survey-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.SURVEY_URL, id)
        .then((document: any) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'getById'));
        });
    });
  }

  completeSurvey(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .put(`${this.SURVEY_URL}/complete/${id}`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'completeSurvey'));
        });
    });
  }

  getLastPulseDate(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.SURVEY_URL}/agenda/last-company-vibe`, httpOptions)
        .toPromise()
        .then((agendaDate) => {
          resolve(agendaDate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'getLastPulseDate'));
        });
    });
  }

  getParticipation(lastNDays: number = 30): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.SURVEY_URL}/participation/${lastNDays}`, httpOptions)
        .toPromise()
        .then((participation) => {
          resolve(participation);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'getParticipation'));
        });
    });
  }

  getPendingUsers(lastNDays: number = 30): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.SURVEY_URL}/pending-users/${lastNDays}`, httpOptions)
        .toPromise()
        .then((participation) => {
          resolve(participation);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'getPendingUsers'));
        });
    });
  }

  getNextPulseDate(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.SURVEY_URL}/agenda/next-company-vibe`, httpOptions)
        .toPromise()
        .then((agendaDate) => {
          resolve(agendaDate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'getNextPulseDate'));
        });
    });
  }

  disablePulse(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.SURVEY_URL}/agenda/disable-company-vibe`, { cancelled: true }, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'disablePulse'));
        });
    });
  }

  schedulePulse(startDate: Moment): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.SURVEY_URL}/agenda/schedule/company-vibe`, { startDate: startDate.toISOString() }, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'schedulePulse'));
        });
    });
  }

  generateForAudience(userIds: Array<string>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.SURVEY_URL}/generate-for-audience`, { receiverIds: userIds }, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, SurveyService.name, 'generateForAudience'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.SURVEY_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.SURVEY_INTERNATIONALIZATION);
  }
}
