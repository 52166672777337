import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../environments/environment';
import { ErrorManagerService } from '../../standard/services/error/error-manager.service';
import { PositionCandidateService } from '../../standard/services/recruiting/position-candidate.service';

@Injectable({
  providedIn: 'root'
})
export class PrivateRecruitingAgenciesService {
  private RECRUITING_AGENCIES_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-agency-db`;

  constructor(private http: HttpClient, private errorManager: ErrorManagerService, private injector: Injector) {}

  checkHash(hash: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get<any>(`${this.RECRUITING_AGENCIES_URL}/checkHash/${hash}`, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PrivateRecruitingAgenciesService.name, 'checkHash'));
        });
    });
  }

  getPositionCandidates(candidate: string, position: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const queryPositionCandidate = { _candidateId: candidate, _positionId: position };

      this.injector
        .get(PositionCandidateService)
        .find(queryPositionCandidate)
        .then((results) => {
          const returnArray = results.map((iPositionCandidate) => {
            if (check.assigned(iPositionCandidate) && check.assigned(iPositionCandidate.emails)) {
              delete iPositionCandidate.emails;
            }
            return iPositionCandidate;
          });
          resolve(returnArray);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PrivateRecruitingAgenciesService.name, 'getPositionCandidates'));
        });
    });
  }
}
