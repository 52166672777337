import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IAttendanceLimitSettings } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AttendanceLimitsController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/user-attendance/limits-config`;

  constructor(private injector: Injector) {}

  async getLimitsConfig(userId?: string, dateIn: Date = new Date()): Promise<IAttendanceLimitSettings> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, AttendanceLimitsController.name, 'getLimitsConfig');
        throw error;
      }

      if (!userId) {
        userId = this.injector.get(AuthenticationService).getLoggedUser()._id;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const limitsConfig = await this.injector.get(HttpClient).get(`${this.URL}/${userId}/${dateIn.toISOString()}`, httpOptions).toPromise();

      return limitsConfig;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, AttendanceLimitsController.name, 'getLimitsConfig');
    }
  }
}
