import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ACCESS_TYPE_FULL_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { UserSegmentationService } from '@app/cloud-features/home/components/user-segmentation/user-segmentation.service';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import * as check from 'check-types';

const AGENCY_PROFILE_KEY = 'agency';
const ORIGIN_URL_KEY = 'originalUrl';

@Injectable({
  providedIn: 'root'
})
export class CloudGuard implements CanLoad, CanActivate {
  constructor(private injector: Injector) {}

  async canLoad(route: Route, segments: Array<UrlSegment>): Promise<boolean> {
    const fullPath = segments?.reduce((path, currentSegment) => {
      return `${path}/${currentSegment.path}`;
    }, '');
    return await this.executeGuard(fullPath);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.executeGuard(state.url, route);
  }

  private async executeGuard(url?: string, route?: any): Promise<boolean> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      if (url && url !== '') {
        const origin = {
          url: url,
          timestamp: Date.now()
        };
        // save the original url requested to navigate to there after google/ms SSO or 2FA login
        localStorage.setItem(ORIGIN_URL_KEY, JSON.stringify(origin));
      }
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    if (loggedUser.isActive === true && loggedUser.profileKey === AGENCY_PROFILE_KEY) {
      this.injector.get(Router).navigate(['/agency/home']);
      return false;
    }

    if (loggedUser.isActive === true && loggedUser._onboarding === 'Employee') {
      this.injector.get(Router).navigate(['/employee-onboarding']);
      return false;
    }

    if (loggedUser.isActive === true && loggedUser._onboarding === 'Finished' && this.injector.get(AuthenticationService).getAccessType() === ACCESS_TYPE_FULL_ACCESS) {
      const showDialog = await this.injector.get(UserSegmentationService).getShowUserSegmentationDialog();
      const skip = this.injector.get(UserSegmentationService).skipSegmentationVerification;
      if (showDialog === true && skip === false && check.assigned(route)) {
        this.injector.get(UserSegmentationService).setSkipSegmentationVerification();
        this.injector.get(CloudRoutingService).setInitialUrl('/cloud/home?onboarding=true');
        this.injector.get(Router).navigate(['/cloud/home'], { queryParams: { onboarding: true }, queryParamsHandling: 'merge', preserveFragment: true });
        return false;
      }
      return true;
    }

    this.injector.get(Router).navigate(['/signin']);
    return false;
  }
}
