<orgos-container *ngIf="loadingPage === false && costCentersModel && costCentersModel.data.length > 0" aspect="card">
  <orgos-column-container class="main-container">
    <!-- MENU -->
    <orgos-column size="0" class="scc-options-container kenjo-p-30px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1">
          <kenjo-truncate-text class="scc-text-name kenjo-font-size-16px kenjo-text-transform-uppercase">{{i18n.page.menuHeaderTitle}}</kenjo-truncate-text>
        </orgos-column>
        <kenjo-icon [size]="20" orgosColumn="0" [matTooltip]="i18n.page.addCostCenterButtonTooltip" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="openAddCostCenterDialog()">add_circle</kenjo-icon>
      </orgos-column-container>
      <div (mouseenter)="optionHover=index" (mouseleave)="optionHover=-1" *ngFor="let costCenter of costCentersModel.data; let index = index" class="kenjo-pt-15px">
        <orgos-column-container centerColumns="true" class="scc-cost-center-option">
          <orgos-column size="1">
            <kenjo-truncate-text
              class="scc-text-name scc-menu"
              [class.kenjo-font-color-light-text-757575]="!costCenterToEdit || costCenter.id !== costCenterToEdit.id"
              [class.kenjo-font-color-primary-5993e3]="costCenterToEdit && costCenter.id === costCenterToEdit.id"
              (click)="costCenterToEdit = costCenter"
            >
              {{costCenter.data.costCenterId}}&nbsp;-&nbsp;{{costCenter.data.name}}&nbsp;<span *ngIf="costCenterIdToUserIds">({{getNumberOfPeopleInCostCenters(costCenter.data._id)}})</span>
            </kenjo-truncate-text>
          </orgos-column>
          <kenjo-icon orgosColumn="0" [size]="20" (click)="openDeleteCostCenterDialog(index)" [matTooltip]="i18n.page.deleteCostCenterButton" matTooltipPosition="before" [class.scc-delete-button]="optionHover !== index" class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer">delete</kenjo-icon>
        </orgos-column-container>
      </div>
    </orgos-column>
    <!-- FORM -->
    <orgos-column size="1" class="kenjo-p-30px">
      <ng-container *ngIf="costCenterToEdit">
        <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">{{i18n.page.menuHeaderTitle}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{costCenterToEdit.data.name}}</span></div>
        <orgos-column-container class="kenjo-mt-30px">
          <orgos-input-text orgosColumn [model]="costCenterToEdit" field="costCenterId" class="kenjo-mr-10px"></orgos-input-text>
          <orgos-input-text orgosColumn [model]="costCenterToEdit" field="name" class="kenjo-ml-10px"></orgos-input-text>
        </orgos-column-container>
        <div *ngIf="getNumberOfPeopleInCostCenters(costCenterToEdit.id) > 0">
          <div *ngIf="costCenterIdToUserIds" class="kenjo-mt-30px" class="kenjo-font-color-light-text-757575">{{getNumberOfPeopleInCostCenters(costCenterToEdit.id)}} {{i18n.page.numberOfEmployeesLabel}}</div>
          <orgos-column-container *ngIf="costCenterIdToUserIds" wrap="true">
            <orgos-column *ngFor="let user of costCenterIdToUserIds[costCenterToEdit.id]" class="kenjo-pr-10px kenjo-pt-10px" size="0"><orgos-chip [imageUrl]="user._photo?._url" [chipText]="user.displayName" [addInitialsIfNoImage]="true">{{user.displayName}}</orgos-chip></orgos-column>
          </orgos-column-container>
        </div>
      </ng-container>
    </orgos-column>
  </orgos-column-container>
</orgos-container>

<div *ngIf="loadingPage === false && costCentersModel && costCentersModel.data.length === 0">
  <div class="kenjo-text-align-right">
    <orgos-action icon="add_circle" iconSize="large" (click)="openAddCostCenterDialog()">{{i18n.page.addCostCenterButton}}</orgos-action>
  </div>
  <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noCostCenters}}</div>
  </div>
</div>
