import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

declare const window: any;

@Component({
  templateUrl: 'update-app.dialog.html',
  styleUrls: ['update-app.dialog.scss'],
})
export class UpdateAppDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};
  refreshTimer: NodeJS.Timeout;
  counterInterval: NodeJS.Timer;
  counter: number = 60;

  constructor(
    public dialogRef: MatLegacyDialogRef<any>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.refreshTimer = setTimeout(() => {
      this.reloadApp();
    }, 60 * 1000);
    this.counterInterval = setInterval(() => {
      this.counter--;
    }, 1000);
  }

  async initTranslations() {
    try {
      const [dialogTranslations, miscTranslation] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('update-app-dialog'),
        this.injector.get(InternationalizationService).getAllTranslation('misc'),
      ]);
      this.dialogTranslation = dialogTranslations;
      this.miscTranslation = miscTranslation;
    } catch {
      this.dialogTranslation = {};
      this.miscTranslation = {};
    }
  }

  closeDialog(): void {
    clearTimeout(this.refreshTimer);
    clearInterval(this.counterInterval);
    this.dialogRef.close();
  }

  reloadApp() {
    window.location.reload(true);
  }
}
