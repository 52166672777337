<orgos-column-container centerColumns="true" class="wtm-top-bar kenjo-border-bottom kenjo-ph-20px">
  <orgos-column size="0">
    <kenjo-icon size="20" (click)="onBackClick()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
  </orgos-column>

  <orgos-column size="0" class="wtm-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

  <orgos-column size="0" class="kenjo-font-size-16px">{{pageTranslation.mainHeader}}</orgos-column>

  <orgos-column size="0" class="wtm-separator kenjo-mh-20px kenjo-border-left"></orgos-column>
</orgos-column-container>

<div class="wtm-main-container">
  <orgos-container aspect="card" class="wtm-content kenjo-mv-20px kenjo-p-20px">
    <div class="kenjo-mb-20px kenjo-font-size-16px">{{pageTranslation.triggerWorkflowTitle}}</div>

    <orgos-container *ngIf="listWorkflows && listWorkflows.length > 0" aspect="card">
      <orgos-column-container centerColumns="true" class="kenjo-ph-20px kenjo-pv-10px kenjo-color-grey-background-lighter-f8f8f8">
        <orgos-column size="6" class="kenjo-font-size-12px kenjo-font-weight-bold kenjo-text-transform-uppercase">
          {{pageTranslation.nameColumnHeader}}
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-size-12px kenjo-font-weight-bold kenjo-text-transform-uppercase">
          {{pageTranslation.actionColumnHeader}}
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container
        *ngFor="let workflow of listWorkflows; let i = index"
        centerColumns="true"
        class="kenjo-pv-10px kenjo-ph-20px kenjo-border-top"
      >
        <orgos-column size="6"> {{workflow.name}} </orgos-column>
        <orgos-column size="1">
          <orgos-action (click)="confirmDialog(workflow)">{{pageTranslation.triggerActionLabel}}</orgos-action>
        </orgos-column>
      </orgos-column-container>
    </orgos-container>

    <div
      *ngIf="!listWorkflows || listWorkflows.length === 0"
      class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-border kenjo-border-radius kenjo-p-20px kenjo-font-color-light-text-757575"
    >
      {{pageTranslation.noWorkflowsDescription}}
    </div>

    <div class="kenjo-mt-40px kenjo-mb-20px kenjo-font-size-16px">{{pageTranslation.workflowHistoryTitle}}</div>

    <orgos-container *ngIf="workflowHistory?.length > 0" aspect="card">
      <orgos-column-container centerColumns="true" class="kenjo-ph-20px kenjo-pv-10px kenjo-color-grey-background-lighter-f8f8f8">
        <orgos-column size="6" class="kenjo-font-size-12px kenjo-font-weight-bold kenjo-text-transform-uppercase">
          {{pageTranslation.nameColumnHeader}}
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-size-12px kenjo-font-weight-bold kenjo-text-transform-uppercase">
          {{pageTranslation.dateColumnHeader}}
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container
        *ngFor="let iWorkflowHistory of workflowHistory; let i = index"
        centerColumns="true"
        class="kenjo-pv-10px kenjo-ph-20px kenjo-border-top"
      >
        <orgos-column size="6">{{iWorkflowHistory.name}} </orgos-column>
        <orgos-column size="1"> {{ iWorkflowHistory.date | date:'shortDate' }} </orgos-column>
      </orgos-column-container>
    </orgos-container>

    <div
      *ngIf="!workflowHistory || workflowHistory.length === 0"
      class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-border kenjo-border-radius kenjo-p-20px kenjo-font-color-light-text-757575"
    >
      {{pageTranslation.noWorkflowHistoryDescription}}
    </div>
  </orgos-container>
</div>
