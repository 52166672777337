import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class CandidateListService {
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller`;

  constructor(private injector: Injector) {}

  getDataCandidateList(queryOptions: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url = `${this.CONTROLLER_URL}/recruiting/candidate-list/list`;
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(url, ErrorCodes.UNAUTHORIZED, CandidateListService.name, 'getDataCandidateList');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .post(url, queryOptions, httpOptions)
        .toPromise()
        .then((candidateList) => {
          resolve(candidateList);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, CandidateListService.name, 'getDataCandidateList'));
        });
    });
  }

  getFiltersCandidate(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url = `${this.CONTROLLER_URL}/recruiting/candidate-filters/filters`;
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(url, ErrorCodes.UNAUTHORIZED, CandidateListService.name, 'getFiltersCandidate');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get(url, httpOptions)
        .toPromise()
        .then((candidateList) => {
          resolve(candidateList);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, CandidateListService.name, 'getFiltersCandidate'));
        });
    });
  }
}
