import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { ITimeOffRequestModel } from '@app/cloud-features/time-off/services/time-off-request.service';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { computeCurrentTimeOff, setTimeOffMap } from '@app/common-components/widgets/punch-clock/orgos-widget-punch-clock.helpers';
import { IconHighlightComponent } from '@app/standard/standalone-components/icon-highlight/icon-highlight.component';
import {
  HOLIDAY_DURATION_AFTERNOON,
  HOLIDAY_DURATION_FULL_DAY,
  HOLIDAY_DURATION_MORNING,
  POLICY_TYPE_HOUR,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'kenjo-time-off-step',
  templateUrl: 'time-off-step.component.html',
  styleUrls: ['time-off-step.component.scss'],
  standalone: true,
  imports: [IconModule, IconHighlightComponent, DatePipe, CommonModule, MatLegacyTooltipModule, RouterModule, TruncateTextModule],
})
export class TimeOffStepComponent {
  showFromDate: boolean;
  showToDate: boolean;
  isFullDayOff: boolean;
  isMorningOff: boolean;
  isAfternoonOff: boolean;
  isCustomTimeOff: boolean;
  showArrowIcon: boolean;
  showEndDate: boolean;
  showTodayRequest: boolean;
  isFullDayOffEnd: boolean;
  isMorningOffEnd: boolean;
  isAfternoonOffEnd: boolean;

  _currentTimeOff: ITimeOffRequestModel;
  _timeOffRequest: ITimeOffRequestModel[];
  _timeOffMap: { [key: string]: { name: string; toType: string; fromType: string; startTime: string; endTime: string } };
  _timeOffToolTip: string;
  isSameDate: {
    startDate: boolean;
    endDate: boolean;
    startAndEndDate: boolean;
  } = {
    startDate: false,
    endDate: false,
    startAndEndDate: false,
  };

  @Input() translations: Record<string, string>;
  @Input()
  set timeOffRequest(timeOffRequest: ITimeOffRequestModel[]) {
    const clonedTimeOff = cloneDeep(timeOffRequest);
    this._timeOffMap = setTimeOffMap(clonedTimeOff);
    this._currentTimeOff = computeCurrentTimeOff(clonedTimeOff);

    if (!this._currentTimeOff) {
      return;
    }

    this._timeOffToolTip = this.setTodayTimeOffRequestToolTip(clonedTimeOff, this.timeOffMap);
    this._timeOffRequest = clonedTimeOff;
    this.setIsSameDate(this._currentTimeOff);
    this._currentTimeOff.to = moment.utc(this._currentTimeOff?.to).startOf('day').toISOString();
    this.updateViewConditions();
  }

  get timeOffRequest(): ITimeOffRequestModel[] {
    return this._timeOffRequest;
  }

  get currentTimeOff(): ITimeOffRequestModel {
    return this._currentTimeOff;
  }

  get timeOffMap() {
    return this._timeOffMap;
  }

  get timeOffToolTip() {
    return this._timeOffToolTip;
  }

  TIME_OFF_TYPES = {
    MORNING: HOLIDAY_DURATION_MORNING,
    AFTERNOON: HOLIDAY_DURATION_AFTERNOON,
    FULL_DAY: HOLIDAY_DURATION_FULL_DAY,
  };

  constructor() {}

  setTodayTimeOffRequestToolTip(
    timeOffRequest: ITimeOffRequestModel[],
    timeOffMap: { [key: string]: { name: string; toType: string; fromType: string; startTime: string; endTime: string } }
  ) {
    let toolTip = '';

    for (const iTimeOff of timeOffRequest) {
      let type: string;
      type =
        moment().format(DATE_FORMAT) === moment.utc(iTimeOff.from).format(DATE_FORMAT)
          ? timeOffMap[iTimeOff._id]?.fromType
          : timeOffMap[iTimeOff._id]?.toType;

      if (!type && iTimeOff._policyType === POLICY_TYPE_HOUR) {
        type = `${moment.utc(iTimeOff.from).format('HH:mm')} - ${moment.utc(iTimeOff.to).format('HH:mm')}`;
      }
      toolTip += `${timeOffMap[iTimeOff._id]?.name} : ${type}\n`;
    }
    return toolTip;
  }

  setIsSameDate(currentTimeOff: ITimeOffRequestModel) {
    this.isSameDate.startAndEndDate =
      moment.utc(currentTimeOff?.to).format(DATE_FORMAT) === moment.utc(currentTimeOff?.from).format(DATE_FORMAT);
    this.isSameDate.startDate = moment.utc(currentTimeOff?.from).format(DATE_FORMAT) === moment().format(DATE_FORMAT);
    this.isSameDate.endDate = moment.utc(currentTimeOff?.to).format(DATE_FORMAT) === moment().format(DATE_FORMAT);
  }

  updateViewConditions() {
    this.showFromDate = this.setShowFromDate();
    this.showToDate = this.setShowToDate();
    this.isFullDayOff = this.checkIsFullDayOff();
    this.isMorningOff = this.checkIsMorningOff();
    this.isAfternoonOff = this.checkIsAfternoonOff();
    this.isCustomTimeOff = this.checkIsCustomTimeOff();
    this.isFullDayOffEnd = this.checkIsFullDayOffEnd();
    this.isAfternoonOffEnd = this.checkIsAfternoonOffEnd();
    this.isMorningOffEnd = this.checkIsMorningOffEnd();
    this.showArrowIcon = this.setShowArrowIcon();
    this.showEndDate = this.setShowEndDate();
    this.showTodayRequest = this.setShowTodayRequest();
  }

  setShowFromDate() {
    return !(
      this.timeOffMap[this.currentTimeOff?._id]?.toType === this.TIME_OFF_TYPES.MORNING &&
      this.isSameDate.endDate &&
      !this.isSameDate.startAndEndDate
    );
  }

  setShowToDate() {
    return (
      this.timeOffMap[this.currentTimeOff?._id]?.toType === this.TIME_OFF_TYPES.MORNING &&
      this.isSameDate.endDate &&
      !this.isSameDate.startAndEndDate
    );
  }

  checkIsFullDayOff() {
    return this.timeOffMap[this.currentTimeOff?._id]?.fromType === this.TIME_OFF_TYPES.FULL_DAY && !this.showToDate;
  }

  checkIsMorningOff() {
    return this.timeOffMap[this.currentTimeOff?._id]?.fromType === this.TIME_OFF_TYPES.MORNING || this.showToDate;
  }

  checkIsAfternoonOff() {
    return this.timeOffMap[this.currentTimeOff?._id]?.fromType === this.TIME_OFF_TYPES.AFTERNOON && !this.showToDate;
  }

  checkIsCustomTimeOff() {
    return ![this.TIME_OFF_TYPES.AFTERNOON, this.TIME_OFF_TYPES.MORNING, this.TIME_OFF_TYPES.FULL_DAY].includes(
      this.timeOffMap[this.currentTimeOff?._id]?.fromType
    );
  }

  setShowArrowIcon() {
    return (
      !this.isSameDate.startAndEndDate &&
      !(
        [this.TIME_OFF_TYPES.AFTERNOON, this.TIME_OFF_TYPES.MORNING, this.TIME_OFF_TYPES.FULL_DAY].includes(
          this.timeOffMap[this.currentTimeOff?._id]?.toType
        ) && this.isSameDate.endDate
      ) &&
      (!(
        this.timeOffMap[this.currentTimeOff?._id]?.toType === this.TIME_OFF_TYPES.MORNING &&
        this.isSameDate.endDate &&
        !this.isSameDate.startAndEndDate
      ) ||
        ([this.TIME_OFF_TYPES.AFTERNOON, this.TIME_OFF_TYPES.FULL_DAY].includes(this.timeOffMap[this.currentTimeOff?._id]?.toType) &&
          !this.isSameDate.startAndEndDate))
    );
  }

  setShowEndDate() {
    return !this.isSameDate.startAndEndDate && !this.showToDate;
  }

  checkIsFullDayOffEnd(): boolean {
    return (
      this.timeOffMap[this.currentTimeOff?._id]?.toType === this.TIME_OFF_TYPES.FULL_DAY &&
      !this.isSameDate.startAndEndDate &&
      !this.isSameDate.endDate
    );
  }

  checkIsMorningOffEnd(): boolean {
    return (
      this.timeOffMap[this.currentTimeOff?._id]?.toType === this.TIME_OFF_TYPES.MORNING &&
      !this.isSameDate.endDate &&
      !this.isSameDate.startAndEndDate
    );
  }

  checkIsAfternoonOffEnd(): boolean {
    return this.timeOffMap[this.currentTimeOff?._id]?.toType === this.TIME_OFF_TYPES.AFTERNOON;
  }

  setShowTodayRequest() {
    return (
      (!([this.TIME_OFF_TYPES.FULL_DAY].includes(this.timeOffMap[this.currentTimeOff?._id]?.toType) && this.isSameDate.endDate) ||
        !([this.TIME_OFF_TYPES.FULL_DAY].includes(this.timeOffMap[this.currentTimeOff?._id]?.fromType) && this.isSameDate.startDate)) &&
      this.timeOffRequest?.length > 1
    );
  }
}
