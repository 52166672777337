
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'orgos-action',
  templateUrl: 'action.component.html',
  styleUrls: ['action.component.scss']
})
export class ActionComponent {
  normalIconClass: boolean = true;
  largeIconClass: boolean = false;
  smallIconClass: boolean = false;
  fourteenPxIconClass: boolean = false;
  eighteenPxIconClass: boolean = false;

  @Input() icon: string = '';
  @Input() uppercase: boolean = true;
  @Input() forceBlockContainer: boolean = true;
  @Input() smallText: boolean = false;

  @Input()
  set iconSize(iconSize: 'large' | 'normal' | 'small' | '14px' | '18px') {
    // large, normal
    this.normalIconClass = false;
    this.largeIconClass = false;
    this.smallIconClass = false;

    if (!iconSize) {
      this.normalIconClass = true;
    } else if (iconSize === 'large') {
      this.largeIconClass = true;
    } else if (iconSize === 'normal') {
      this.normalIconClass = true;
    } else if (iconSize === 'small') {
      this.smallIconClass = true;
    } else if (iconSize === '14px') {
      this.fourteenPxIconClass = true;
    } else if (iconSize === '18px') {
      this.eighteenPxIconClass = true;
    } else {
      this.normalIconClass = true;
    }
  }

  private _color: 'Primary' | 'Danger' | 'Success' | 'NormalText' | 'LightText' | 'White' = 'Primary';
  @Input()
  set color(color: 'Primary' | 'Danger' | 'Success' | 'NormalText' | 'LightText' | 'White') {
    this._color = color;

    this.colorPrimaryClass = false;
    this.colorDangerClass = false;
    this.colorSuccessClass = false;
    this.colorNormalTextClass = false;
    this.colorLightTextClass = false;
    this.colorWhiteClass = false;
    this.colorGreyDarkClass = false;

    if (this.disabled) {
      return;
    }

    if (!color && !this.disabled) {
      this.colorPrimaryClass = true;
    } else if (color === 'Primary') {
      this.colorPrimaryClass = true;
    } else if (color === 'Danger') {
      this.colorDangerClass = true;
    } else if (color === 'Success') {
      this.colorSuccessClass = true;
    } else if (color === 'NormalText') {
      this.colorNormalTextClass = true;
    } else if (color === 'LightText') {
      this.colorLightTextClass = true;
    } else if (color === 'White') {
      this.colorWhiteClass = true;
    } else if (!this.disabled) {
      this.colorPrimaryClass = true;
    }
  }

  private _disabled: boolean = false;
  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.disabledClass = disabled;

    this.color = this._color;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  private _selected: boolean = false;
  @Input()
  set selected(selected: boolean) {
    this._selected = selected;
    this.selectedClass = selected;
  }
  get selected(): boolean {
    return this._selected;
  }

  @HostBinding('class.kenjo-font-color-primary-5993e3') colorPrimaryClass: boolean = true;
  @HostBinding('class.kenjo-font-color-danger-ff5757') colorDangerClass: boolean = false;
  @HostBinding('class.kenjo-font-color-success-00b72e') colorSuccessClass: boolean = false;
  @HostBinding('class.kenjo-font-color-text-333333') colorNormalTextClass: boolean = false;
  @HostBinding('class.kenjo-font-color-light-text-757575') colorLightTextClass: boolean = false;
  @HostBinding('class.kenjo-font-color-white-ffffff') colorWhiteClass: boolean = false;
  @HostBinding('class.kenjo-font-color-light-text-757575') colorGreyDarkClass: boolean = false;

  @HostBinding('class.ac-disabled') disabledClass = false;
  @HostBinding('class.ac-selected') selectedClass = false;
}
