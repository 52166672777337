import { Component, Input } from '@angular/core';

@Component({
  selector: 'orgos-user-header',
  templateUrl: 'user-header.component.html',
  styleUrls: ['user-header.component.scss']
})
export class UserHeaderComponent {
  @Input() userPersonal: any;
  @Input() userWork: any;
  @Input() set size(size: 'large' | 'medium' | 'small') {
    this.setHeadlineSize(size);
    this.setTextSize(size);
  }

  headlineSize: string = '';
  textSize: string = '';

  setHeadlineSize(size: 'large' | 'medium' | 'small') {
    if (size === 'small') {
      this.headlineSize = 'kenjo-font-size-16px';
    } else if (size === 'medium') {
      this.headlineSize = 'kenjo-font-size-18px';
    } else {
      this.headlineSize = 'kenjo-font-size-25px';
    }
  }

  setTextSize(size: 'large' | 'medium' | 'small') {
    if (size === 'large') {
      this.textSize = 'kenjo-font-size-14px';
    } else {
      this.textSize = 'kenjo-font-size-12px';
    }
  }

  public getLeftMargin(): string {
    if (this.size === 'large') {
      return 'kenjo-ml-20px';
    } else {
      return 'kenjo-ml-10px';
    }
  }
}
