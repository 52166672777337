import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { EmailTemplateService } from '@app/standard/services/email-template/email-template.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import { EMAIL_TEMPLATE_CATEGORY_LIST, EMAIL_TEMPLATE_LANGUAGES_LIST } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { CompanyService } from '../../services/company/company.service';
import { SettingsBarService } from '../../services/settings/settings-bar.service';
import { WorkflowActionService } from '../../services/workflow/workflow-action.service';

@Component({
  selector: 'orgos-settings-email-template-page',
  templateUrl: 'settings-email-template.page.html',
  styleUrls: ['settings-email-template.page.scss'],
})
export class SettingsEmailTemplatePage implements OnInit {
  displayedColumns: Array<string> = ['category', 'name', 'inCollection', 'language', 'companyId', 'actions'];
  pageTranslation: any = {};
  miscTranslation: any = {};
  workflowsTranslation: any = {};
  objectTranslation: any = {};
  standardPicklistsTranslation: any = {};
  listEmailTemplates: Array<any>;
  emailTemplateToEdit: any;
  editWorkflowTranslation: any = {};
  mapCompanyIdToCompany: any = {};
  settingsBarTranslation: any = {};

  filteredData: Array<any>;
  filters: { [id: string]: boolean | string } = {};
  allCategories: Array<any> = [];
  allLanguages: Array<any> = [];
  allCompanies: Array<any> = [];

  constructor(private injector: Injector, public dialog: MatLegacyDialog, public snackBar: MatLegacySnackBar) {}

  ngOnInit(): void {
    this.setPageName();
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('email-template-collection')
      .then((objectTranslation) => {
        this.objectTranslation = objectTranslation;
      })
      .catch(() => {
        this.objectTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-top-bar')
      .then((pageTranslation: any) => {
        this.settingsBarTranslation = pageTranslation;
        return this.injector.get(SettingsBarService).getOptions(this.settingsBarTranslation);
      })
      .then((options) => {
        const optionIndex = _.findIndex(options, ['name', this.settingsBarTranslation.emailTemplateTab]);

        this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
        this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(optionIndex);
      })
      .catch(() => {
        // An error is already shown
        this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-workflows-page')
      .then((pageTranslation) => {
        this.workflowsTranslation = pageTranslation;
      })
      .catch(() => {
        this.workflowsTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-workflow-page')
      .then((editWorkflowTranslation) => {
        this.editWorkflowTranslation = editWorkflowTranslation;
      })
      .catch(() => {
        this.editWorkflowTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.fetchData();
    this.injector.get(PrivateAmplitudeService).logEvent('view settings page', { category: 'Navigation', type: 'email templates' });
  }

  sortingDataAccessor: (row: any, sortHeaderId: string) => string | number = (row: any, sortHeaderId: string): string | number => {
    const value: any = row[sortHeaderId];

    if (sortHeaderId === 'inCollection') {
      return this.getLabel(value);
    }
    if (sortHeaderId === 'companyId') {
      return this.mapCompanyIdToCompany[value] && this.mapCompanyIdToCompany[value].name ? this.mapCompanyIdToCompany[value].name : '';
    }
    if (sortHeaderId === 'category') {
      return value &&
        this.standardPicklistsTranslation.emailTemplateCategory &&
        this.standardPicklistsTranslation.emailTemplateCategory[value]
        ? this.standardPicklistsTranslation.emailTemplateCategory[value]
        : '';
    }

    const isoDate = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true);
    if (isoDate.isValid()) {
      return isoDate.valueOf();
    }

    const localizedShortDate = moment.utc(value, 'DD-MM-YYYY', true);
    if (localizedShortDate.isValid()) {
      return localizedShortDate.valueOf();
    }

    const localizedShortDateUsa = moment.utc(value, 'MM-DD-YYYY', true);
    if (localizedShortDateUsa.isValid()) {
      return localizedShortDateUsa.valueOf();
    }

    // If the value is a string and only whitespace, return the value.
    // Otherwise +value will convert it to 0.
    if (typeof value === 'string' && check.not.assigned(value.trim())) {
      return value;
    }

    if (check.not.assigned(value)) {
      return '';
    }

    return isNaN(+value) ? value.toLowerCase() : +value;
  };

  setPageName(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-email-template-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  private fetchData(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((standardPicklistsTranslation) => {
        this.standardPicklistsTranslation = standardPicklistsTranslation;
        this.initFilters();
        return this.injector.get(EmailTemplateService).getAllTemplates();
      })
      .then((emailTemplates) => {
        this.listEmailTemplates = emailTemplates;
        this.filteredData = this.listEmailTemplates;
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch(() => {
        this.injector.get(GlobalBarService).setProgressBar(false);
        this.listEmailTemplates = null;
        this.standardPicklistsTranslation = {};
      });
  }

  private initFilters(): void {
    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((allCompanies: Array<any>) => {
        this.allCompanies = allCompanies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.mapCompanyIdToCompany = allCompanies.reduce((totalVal, currentVal) => {
          totalVal[currentVal._id] = currentVal;
          return totalVal;
        }, {});
      })
      .catch(() => {
        this.allCompanies = [];
      });

    this.allCategories = EMAIL_TEMPLATE_CATEGORY_LIST.sort((a, b) => {
      const valA = this.standardPicklistsTranslation.emailTemplateCategory[a];
      const valB = this.standardPicklistsTranslation.emailTemplateCategory[b];
      if (valA.toUpperCase() < valB.toUpperCase()) {
        return -1;
      } else if (valA.toUpperCase() > valB.toUpperCase()) {
        return 1;
      }
      return;
    });

    this.allLanguages = EMAIL_TEMPLATE_LANGUAGES_LIST.sort((a, b) => {
      const valA = this.standardPicklistsTranslation.emailTemplateLanguage[a] ?? '';
      const valB = this.standardPicklistsTranslation.emailTemplateLanguage[b] ?? '';
      if (valA.toUpperCase() < valB.toUpperCase()) {
        return -1;
      } else if (valA.toUpperCase() > valB.toUpperCase()) {
        return 1;
      }
      return;
    });
  }

  public changeFilter(id: string, newValue: boolean | string): void {
    if (newValue === true || (check.string(newValue) && check.not.emptyString(newValue))) {
      this.filters[id] = newValue;
    } else {
      delete this.filters[id];
    }

    this.filterData();
  }

  private filterData(): void {
    const activeFilters = Object.keys(this.filters);

    if (check.emptyArray(activeFilters)) {
      this.filteredData = this.listEmailTemplates;
      return;
    }

    const activeFiltersByType = _.groupBy(activeFilters, (iFilter) => {
      return iFilter === 'search' ? 'search' : iFilter.split('-')[0];
    });

    if (check.assigned(activeFiltersByType['search'])) {
      activeFiltersByType['search'] = [<string>this.filters['search']];
    }

    const filtering = (filteredResult, iEmailTemplate) => {
      let allEmailTemplateData = '';

      // Name
      if (check.nonEmptyString(iEmailTemplate.name)) {
        allEmailTemplateData += iEmailTemplate.name;
      }

      // In collection
      if (check.nonEmptyString(iEmailTemplate.inCollection)) {
        allEmailTemplateData += this.getLabel(iEmailTemplate.inCollection);
      }

      // Category
      if (check.assigned(iEmailTemplate.category) && check.nonEmptyString(iEmailTemplate.category)) {
        allEmailTemplateData += `category-${iEmailTemplate.category}`;
      }

      // CompanyId
      if (check.assigned(iEmailTemplate.companyId) && check.nonEmptyString(iEmailTemplate.companyId)) {
        allEmailTemplateData += `company-${iEmailTemplate.companyId}`;
      }

      // Language
      if (check.assigned(iEmailTemplate.language) && check.nonEmptyString(iEmailTemplate.language)) {
        allEmailTemplateData += `language-${iEmailTemplate.language}`;
      }

      allEmailTemplateData = allEmailTemplateData.toLowerCase();

      const showUser = Object.keys(activeFiltersByType).every((iActiveFilterType) => {
        return activeFiltersByType[iActiveFilterType].some((iFilter) => {
          const transformedFilter = iFilter.trim().toLowerCase();

          return allEmailTemplateData.indexOf(transformedFilter) !== -1;
        });
      });
      if (showUser === true) {
        filteredResult.push(iEmailTemplate);
      }
      return filteredResult;
    };

    this.filteredData = this.listEmailTemplates.reduce(filtering, []);
  }

  createEmailTemplate(): void {
    this.emailTemplateToEdit = {};
  }

  editEmailTemplate(emailTemplate: any): void {
    this.emailTemplateToEdit = _.cloneDeep(emailTemplate);
  }

  deleteEmailTemplate(emailTemplate: any): void {
    this.findWorkflowActionsWithThisTemplate(emailTemplate._id)
      .then((workflowActions) => {
        let data = {};
        if (check.array(workflowActions) && check.nonEmptyArray(workflowActions)) {
          data = {
            titleText: this.pageTranslation.notDeletableTemplate,
            subtitleText: null,
            confirmButtonText: this.pageTranslation.okButton,
            confirmButtonColor: 'Success',
            deleteProtection: true,
          };
        } else {
          data = {
            titleText: this.pageTranslation.confirmDeleteTitle,
            subtitleText: this.pageTranslation.confirmDeleteSubtitle,
            confirmButtonText: this.pageTranslation.confirmDeleteButtonLabel,
            confirmButtonColor: 'Danger',
            cancelButtonText: this.miscTranslation.goBackButtonDialog,
          };
        }
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });

        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm && confirm === true) {
            this.injector
              .get(EmailTemplateService)
              .deleteById(emailTemplate[fieldConstants.ID])
              .then(() => {
                this.fetchData();
                this.snackBar.open(`${this.pageTranslation.deletedSnackbarMessage}`, 'OK', { duration: 5000 });
              })
              .catch(() => {
                // an error is already shown
              });
          }
        });
      })
      .catch(() => {});
  }

  private findWorkflowActionsWithThisTemplate(emailTemplateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const findQuery = {
        'data.emailTemplateId': emailTemplateId,
      };
      this.injector
        .get(WorkflowActionService)
        .find(findQuery)
        .then((wfActions) => {
          resolve(wfActions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  resetEmailTemplateToEdit(refreshList: boolean): void {
    this.emailTemplateToEdit = null;
    this.setPageName();
    if (refreshList && refreshList === true) {
      this.fetchData();
    }
  }

  getLabel(inCollection: string): string {
    if (inCollection.startsWith('user-')) {
      return this.editWorkflowTranslation['userCollection'];
    }
    return this.editWorkflowTranslation[`${inCollection}Collection`]
      ? this.editWorkflowTranslation[`${inCollection}Collection`]
      : inCollection;
  }
}
