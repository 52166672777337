import { DecimalPipe } from '@angular/common';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { IAttendanceConflict } from '@app/cloud-features/settings-attendance/services/attendance-conflicts.service';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AutoDeductDialogComponent } from '@app/standard/components/auto-deduct-break-dialog/auto-deduct-dialog.component';
import {
  adjustBreakTimes,
  calculateIsCloseEntry,
  calculateShiftDuration,
  calculateTotalBreakDuration,
  checkBreakInsideShift,
  checkBreakInsideTimeOff,
  checkBreaksOverlapping,
  checkIsOvernight,
  checkNotAssignedBreak,
  checkOldBreak,
  checkShiftEndConflict,
  checkShiftOverlapping,
  checkShiftStartConflict,
  computeBreakDurations,
  isAnyBreakIncomplete,
  setRealBreaksTime,
  setRealEndTime,
  sortBreaks,
} from '@app/standard/services/attendance/attendance-helpers';
import { FutureEntriesService, IFutureEntryErrors } from '@app/standard/services/attendance/future-entries.service';
import { IBreak, IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kenjo-breaks',
  templateUrl: 'breaks.component.html',
  styleUrls: ['breaks.component.scss'],
})
export class BreaksComponent implements OnInit, OnDestroy {
  @Input() translations: any;

  originalUserAttendance: IUserAttendanceModel;
  _userAttendance: IUserAttendanceModel;
  @Input() set userAttendance(userAttendance: IUserAttendanceModel) {
    this._userAttendance = _.cloneDeep(userAttendance);
    adjustBreakTimes(this._userAttendance.breaks);
    this.originalUserAttendance = userAttendance;
  }

  get userAttendance() {
    return this._userAttendance;
  }

  @Input() limits: { maxShiftLength: number; maxHoursPerDay: number };
  @Input() minutesWorkedThisDay = 0;
  @Input() otherShiftsThisDay: Array<IUserAttendanceModel>;
  @Input() readOnly = false;
  @Input() isRestrictCheckIn: boolean = false;
  @Input() restrictTimeLimit: boolean = false;
  @Input() nextShiftDate: boolean = false;
  @Input() attendancePolicy: IAttendancePolicy;
  @Input() loggedUser: IUserAccountModel;
  @Input() attendanceConflictsCurrentDay: IAttendanceConflict[] = [];

  futureEntriesErrors: IFutureEntryErrors = {
    isOpenShift: false,
    isFutureEntry: false,
    isOpenBreak: false,
    isFutureBreak: false,
  };
  totalBreakTime: number;
  shiftDuration: number;
  canBeSaved = false;
  showCantAddMessage = false;
  shiftOrBreakChanged = false;
  overlappingConflict = false;
  overlappingWithShift = false;
  overlappingBreakTimeOff = false;
  shiftStartConflict = false;
  shiftEndConflict = false;
  isOldBreak = false;
  isOvernight = false;
  maxHoursPerShiftReached = false;
  maxHoursPerDayReached = false;
  breakTimeLonger = false;
  shiftOverlapping = false;
  shiftStartAndEndEquals = false;
  showCleanShiftsInputs = true;

  MINUTES_IN_DAY = 1440;

  private backButtonSubscription: Subscription;

  conflicts = {
    maxDailyHours: false,
  };
  @ViewChild('breaksContainer') breaksContainer: ElementRef;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<{
    save: boolean;
    hasChanges?: boolean;
    newAttendance?: IUserAttendanceModel;
    operation?: 'saveNewBreak' | 'saveOldBreak' | 'forceClose';
  }> = new EventEmitter<{ save: boolean; newAttendance?: IUserAttendanceModel; operation?: 'saveNewBreak' | 'saveOldBreak' }>();
  @Output() hasChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() restrictLimitChange: EventEmitter<IUserAttendanceModel> = new EventEmitter<IUserAttendanceModel>();

  constructor(private location: Location, private injector: Injector) {
    // When the user clicks on the back button of the browser
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.forceClose();
      }
    }) as Subscription;
  }

  ngOnInit() {
    this.checkOldBreak();
    if (this.isOldBreak) {
      return;
    }

    this.checkIsOvernight();
    this.minutesWorkedThisDay = this.minutesWorkedThisDay - this.getMinutesInShift();
    this.initConflicts();
    sortBreaks(this.userAttendance, this.userAttendance.breaks);
    sortBreaks(this.userAttendance, this.originalUserAttendance.breaks || []);
    this.computeDurations();
    this.checkShiftOverlapping();
    this.computeCanBeSaved();
    this.checkOverlappingBreaks();
    this.checkOverlappingBreakTimeOff();
    this.checkShiftConflicts();
    this.checkShiftLimits();

    if (check.not.assigned(this.userAttendance.breaks)) {
      this.userAttendance.breaks = [];
    }

    if (this.userAttendance.breaks.length === 0) {
      this.userAttendance.breaks.push({ start: undefined, end: undefined, conflicts: {} });
    }
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }

  private checkIsOvernight() {
    this.isOvernight = checkIsOvernight(this.userAttendance);
  }

  private checkOldBreak() {
    this.isOldBreak = checkOldBreak(this.userAttendance);
  }

  private initConflicts() {
    this.userAttendance.breaks.forEach((iBreak) => {
      if (check.not.assigned(iBreak.conflicts)) {
        iBreak.conflicts = {};
      }
    });
  }

  addBreak() {
    if (this.overlappingConflict) {
      return;
    }

    const canAdd = this.canAddNewBreak();
    if (!canAdd) {
      this.showCantAddMessage = true;
      return;
    }

    this.userAttendance.breaks.push({ start: undefined, end: undefined, conflicts: {} });
    this.computeCanBeSaved();
    this.scrollToBottom();
  }

  onKeydown() {
    this.showCleanShiftsInputs = false;
  }

  onShiftChange() {
    this.showCantAddMessage = false;
    this.injector.get(ChangeDetectorRef).detectChanges();
    this.emitRestrictLimit();
    this.checkIsOvernight();
    this.computeDurations();
    this.checkShiftOverlapping();
    this.checkShiftConflicts();
    this.checkShiftLimits();
    this.checkHasChanges();
    this.computeCanBeSaved();
    this.calculateIsFutureEntry();
    this.calculateIsFutureOpenBreak(this.userAttendance?.breaks);
  }

  private checkShiftOverlapping() {
    this.shiftOverlapping = checkShiftOverlapping(this.otherShiftsThisDay, this.userAttendance);
  }

  private checkShiftLimits() {
    const minutesInShift = this.getMinutesInShift();
    const minutesWorkedInThisDay = this.minutesWorkedThisDay + minutesInShift;
    this.attendanceConflictsCurrentDay = undefined;
    this.conflicts.maxDailyHours =
      this.attendancePolicy?.limitDailyHours?.conflicts ||
      this.attendanceConflictsCurrentDay?.some((conflict) => !conflict?.isResolved && conflict?._type === 'max-daily-hours');

    this.maxHoursPerShiftReached = minutesInShift > this.limits.maxShiftLength && minutesWorkedInThisDay <= this.limits.maxHoursPerDay;
    this.maxHoursPerDayReached = minutesWorkedInThisDay > this.limits.maxHoursPerDay && !this.conflicts.maxDailyHours;
  }

  private getMinutesInShift() {
    if (check.not.assigned(this.userAttendance.startTime) || check.not.assigned(this.userAttendance.endTime)) {
      return 0;
    }
    const minutesInShift = calculateShiftDuration(this.userAttendance);
    return !isNaN(minutesInShift) ? minutesInShift : 0;
  }

  onBreakChange() {
    this.showCantAddMessage = false;
    setRealBreaksTime(this.userAttendance);
    this.injector.get(ChangeDetectorRef).detectChanges();
    this.computeDurations();
    this.computeCanBeSaved();
    this.checkHasChanges();
    this.checkOverlappingBreaks();
    this.checkOverlappingBreakTimeOff();
    this.checkBreakOverlappingWithShift();
    this.checkShiftConflicts();
    this.checkShiftLimits();
    this.calculateIsFutureOpenBreak(this.userAttendance?.breaks);
  }

  parseTimeEntry(totalMinutes: number | undefined | null, type: 'start' | 'end', index: number): string {
    if (check.not.assigned(totalMinutes)) {
      return check.assigned(this.userAttendance.breaks[index][type]) ? ' ' : null;
    }

    const hours = Math.floor((totalMinutes / 60) % 24);
    const minutes = (((totalMinutes / 60) % 24) - Math.floor((totalMinutes / 60) % 24)) * 60;

    return `${this.injector.get(DecimalPipe).transform(hours, '2.0-0')}:${this.injector.get(DecimalPipe).transform(minutes, '2.0-0')}`;
  }

  deleteBreak(index: number) {
    this.userAttendance.breaks.splice(index, 1);
    this.onBreakChange();
  }

  computeDurations() {
    this.computeBreakDurations();
    this.computeBreakTime();
  }

  private computeBreakDurations() {
    computeBreakDurations(this.userAttendance?.breaks);
  }

  private computeBreakTime() {
    this.totalBreakTime = calculateTotalBreakDuration(this.userAttendance);
    this.shiftDuration = calculateShiftDuration(this.userAttendance);
  }

  computeCanBeSaved() {
    if (
      this.readOnly ||
      this.isRestrictCheckIn ||
      check.not.assigned(this.userAttendance.startTime) ||
      this.shiftStartAndEndEquals ||
      this.shiftOverlapping ||
      this.overlappingWithShift ||
      this.overlappingBreakTimeOff
    ) {
      this.canBeSaved = false;
      return;
    }

    if (checkNotAssignedBreak(this.userAttendance)) {
      this.canBeSaved = true;
      return;
    }

    if (calculateIsCloseEntry(this.userAttendance) && this.totalBreakTime > 0 && this.shiftDuration < 0) {
      this.canBeSaved = false;
      this.breakTimeLonger = true;
      return;
    }
    this.breakTimeLonger = false;

    let incompleteBreaks = 0;
    let emptyBreaks = 0;
    let wrongBreaks = 0;
    this.userAttendance.breaks.forEach((iBreak) => {
      if (check.not.assigned(iBreak.start) && check.not.assigned(iBreak.end)) {
        emptyBreaks += 1;
      }

      if (check.assigned(iBreak.start) && check.not.assigned(iBreak.end)) {
        incompleteBreaks += 1;
      }

      if (check.not.assigned(iBreak.start) && check.assigned(iBreak.end)) {
        wrongBreaks += 1;
      }
    });

    this.canBeSaved = emptyBreaks === 0 && wrongBreaks === 0 && incompleteBreaks <= 1;
  }

  private scrollToBottom() {
    setTimeout(() => {
      Promise.resolve().then(() => {
        this.breaksContainer.nativeElement.scrollTo({ top: this.breaksContainer.nativeElement.scrollHeight, behavior: 'smooth' });
      });
    }, 300);
  }

  checkHasChanges() {
    if (
      this.originalUserAttendance.startTime !== this.userAttendance.startTime ||
      this.originalUserAttendance.endTime !== this.userAttendance.endTime
    ) {
      this.shiftOrBreakChanged = true;
      this.hasChanges.emit(this.shiftOrBreakChanged);
      return;
    }

    if (this.userAttendance.breaks?.length !== this.originalUserAttendance.breaks?.length) {
      this.shiftOrBreakChanged = true;
      this.hasChanges.emit(this.shiftOrBreakChanged);
      return;
    }

    const breakChanged = this.userAttendance.breaks?.find((iBreak, i) => {
      const jBreak = this.originalUserAttendance.breaks[i];
      if (iBreak.start !== jBreak?.start || iBreak.end !== jBreak?.end) {
        return true;
      }

      return false;
    });

    if (check.assigned(breakChanged)) {
      this.shiftOrBreakChanged = true;
      this.hasChanges.emit(this.shiftOrBreakChanged);
      return;
    }

    this.shiftOrBreakChanged = false;
    this.hasChanges.emit(this.shiftOrBreakChanged);
  }

  private checkOverlappingBreaks() {
    const sortedBreaks = [...this.userAttendance.breaks];
    sortBreaks(this.userAttendance, sortedBreaks);
    this.overlappingConflict = checkBreaksOverlapping(this.userAttendance, this.isOvernight, sortedBreaks);
  }

  /**
   * @summary Checks that there is no break inside a shift or a NEW time off type
   */
  private checkBreakOverlappingWithShift() {
    this.overlappingWithShift = checkBreakInsideShift(this.otherShiftsThisDay, this.userAttendance);
  }

  private checkOverlappingBreakTimeOff() {
    const sortedBreaks = [...this.userAttendance.breaks];
    sortBreaks(this.userAttendance, sortedBreaks);
    this.overlappingBreakTimeOff = checkBreakInsideTimeOff(this.otherShiftsThisDay, this.userAttendance);
  }

  private checkShiftConflicts() {
    if (calculateIsCloseEntry(this.userAttendance) && this.userAttendance.startTime === this.userAttendance.endTime) {
      this.shiftStartAndEndEquals = true;
      return;
    }

    this.shiftStartAndEndEquals = false;

    if (checkNotAssignedBreak(this.userAttendance)) {
      this.shiftStartConflict = false;
      this.shiftEndConflict = false;
      this.breakTimeLonger = false;
      return;
    }

    let shiftStartConflict = false;
    let shiftEndConflict = false;

    if (check.not.assigned(this.userAttendance.startTime)) {
      shiftStartConflict = true;
    }

    this.userAttendance.breaks.forEach((iBreak) => {
      let outOfShiftStart = false;
      let outOfShiftEnd = false;
      this.calculateIsFutureOpenBreak([iBreak]);

      // If we don't know if it's overnight because there's no endTime
      if (check.not.assigned(this.userAttendance.endTime)) {
        if (
          check.assigned(iBreak.start) &&
          check.assigned(iBreak.end) &&
          iBreak.start < this.userAttendance.startTime &&
          iBreak.end > this.userAttendance.startTime
        ) {
          shiftStartConflict = true;
          outOfShiftEnd = true;
        }

        if (
          check.assigned(iBreak.start) &&
          check.assigned(iBreak.end) &&
          iBreak.end > this.userAttendance.startTime &&
          iBreak.start > iBreak.end
        ) {
          shiftStartConflict = true;
          outOfShiftEnd = true;
        }
      } else {
        shiftStartConflict = checkShiftStartConflict(iBreak, this.userAttendance, this.isOvernight);
        outOfShiftStart = checkShiftStartConflict(iBreak, this.userAttendance, this.isOvernight);

        shiftEndConflict = checkShiftEndConflict(iBreak, this.userAttendance, this.isOvernight);
        outOfShiftEnd = checkShiftEndConflict(iBreak, this.userAttendance, this.isOvernight);
      }

      iBreak.conflicts.outOfShiftStart = outOfShiftStart;
      iBreak.conflicts.outOfShiftEnd = outOfShiftEnd;
      iBreak.conflicts.isOpenBreak = this.futureEntriesErrors.isOpenBreak;
      iBreak.conflicts.isFutureBreak = this.futureEntriesErrors.isFutureBreak;
    });

    this.shiftStartConflict = shiftStartConflict;
    this.shiftEndConflict = shiftEndConflict;
  }

  private canAddNewBreak() {
    if (this.readOnly) {
      return false;
    }
    const anyIncomplete = isAnyBreakIncomplete(this.userAttendance);
    return !anyIncomplete;
  }

  goBack() {
    this.injector.get(FutureEntriesService).resetError();
    this.close.emit({ save: false, hasChanges: this.shiftOrBreakChanged });
  }

  forceClose() {
    this.injector.get(FutureEntriesService).resetError();
    this.close.emit({ save: false, operation: 'forceClose' });
  }

  save() {
    this.computeCanBeSaved();
    if (!this.canBeSaved) {
      return;
    }

    if (this.userAttendance?.startTime !== this.originalUserAttendance?.startTime) {
      this.logAmplitudeEvent('Shift start time was changed');
    }

    if (this.userAttendance?.endTime !== this.originalUserAttendance?.endTime) {
      this.logAmplitudeEvent('Shift end time was changed');
    }

    const userAttendance = _.cloneDeep(this.userAttendance);
    userAttendance.endTime = setRealEndTime(userAttendance);
    setRealBreaksTime(userAttendance);

    userAttendance?.breaks.forEach((iBreak) => {
      delete iBreak.duration;
      delete iBreak.conflicts;
    });

    userAttendance.breakTime = this.totalBreakTime || 0;

    this.close.emit({ save: true, newAttendance: userAttendance, operation: 'saveNewBreak' });
  }

  updateToNewBreaks() {
    this.userAttendance.breaks = [];
    this.isOldBreak = false;
    this.ngOnInit();
  }

  saveOldBreak() {
    if (this.userAttendance.breakTime === 0) {
      this.userAttendance.breakTime = null;
    }

    this.close.emit({ save: true, newAttendance: { ...this.userAttendance }, operation: 'saveOldBreak' });
  }

  calculateIsFutureEntry() {
    const isAdmin = ['admin', 'hr-admin'].includes(this.loggedUser?.profileKey);
    const allowEntriesInTheFuture = isAdmin || this.attendancePolicy?.allowEntriesInTheFuture;
    const clonedUserAttendance = _.cloneDeep({ ...this.userAttendance });
    this.futureEntriesErrors = this.injector
      .get(FutureEntriesService)
      .calculateIsFutureShift(clonedUserAttendance, allowEntriesInTheFuture);
  }

  calculateIsFutureOpenBreak(iBreaks: Array<IBreak>) {
    const isAdmin = ['admin', 'hr-admin'].includes(this.loggedUser?.profileKey);
    const allowEntriesInTheFuture = isAdmin || this.attendancePolicy?.allowEntriesInTheFuture;
    this.futureEntriesErrors = this.injector
      .get(FutureEntriesService)
      .calculateIsFutureOpenBreak(this.userAttendance, iBreaks, allowEntriesInTheFuture);
  }

  emitRestrictLimit() {
    if (this.userAttendance.startTime !== null) {
      this.restrictLimitChange.emit(this.userAttendance);
    }
  }

  async openAutoDeductDialog(iBreak: IBreak) {
    const data = {
      attendancePolicy: this.attendancePolicy,
      break: iBreak,
    };

    await this.injector
      .get(MatLegacyDialog)
      .open(AutoDeductDialogComponent, {
        data,
      })
      .afterClosed()
      .toPromise();
  }

  private logAmplitudeEvent(feature) {
    this.injector.get(PrivateAmplitudeService).logEvent(feature, {
      platform: 'Web',
      category: 'Attendance',
      subcategory1: 'Attendance Details',
      subcategory2: 'Breakmodal',
    });
  }
}
