import { NumberSymbol } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { AttendancePreferencesService } from '@app/cloud-features/settings-attendance/services/attendance-preferences.service';
import { AttendanceSettingsService } from '@app/cloud-features/settings-attendance/services/attendance-settings.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import { PresenceValidationController } from '@app/standard/services/attendance/controllers/presence-validation.controller';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { IMenuOption } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { NavbarService } from '@app/standard/services/core/navbar.service';
import { FiltersController } from '@app/standard/services/filters/filters.controller';
import {
  HOLIDAY_DURATION_AFTERNOON,
  HOLIDAY_DURATION_FULL_DAY,
  HOLIDAY_DURATION_MORNING,
  PART_OF_DAY_END,
  PART_OF_DAY_START,
  POLICY_TYPE_DAY,
  POLICY_TYPE_HOUR,
  WORK_SCHEDULE_STATUS_BANK_HOLIDAY,
  WORK_SCHEDULE_STATUS_CHECKED_IN,
  WORK_SCHEDULE_STATUS_LATE_CHECK_IN,
  WORK_SCHEDULE_STATUS_NON_WORKING_DAY,
  WORK_SCHEDULE_STATUS_NOT_CHECKED,
  WORK_SCHEDULE_STATUS_TIME_OFF,
  WORK_SCHEDULE_TEMPLATE_TYPE_ALL,
  WORK_SCHEDULE_TEMPLATE_TYPE_FIXED,
  WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE,
  WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD,
  TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kenjo-presence-validation',
  templateUrl: 'presence-validation.page.html',
  styleUrls: ['presence-validation.page.scss'],
})
export class PresenceValidationPage extends GenericPage {
  selectedDate: string;
  selectedTime: string;
  lateCheckInRange: NumberSymbol;
  allPresenceValidation: any;
  allPresenceValidationFiltered: any;
  allPresenceValidationProcessedAndFiltered: any;

  searchTerm: string = '';
  WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE: string = WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE;
  WORK_SCHEDULE_TEMPLATE_TYPE_FIXED: string = WORK_SCHEDULE_TEMPLATE_TYPE_FIXED;
  WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD: string = WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD;
  WORK_SCHEDULE_TEMPLATE_TYPE_ALL: string = WORK_SCHEDULE_TEMPLATE_TYPE_ALL;
  WORK_SCHEDULE_STATUS_NON_WORKING_DAY: string = WORK_SCHEDULE_STATUS_NON_WORKING_DAY;
  WORK_SCHEDULE_STATUS_BANK_HOLIDAY: string = WORK_SCHEDULE_STATUS_BANK_HOLIDAY;
  WORK_SCHEDULE_STATUS_CHECKED_IN: string = WORK_SCHEDULE_STATUS_CHECKED_IN;
  WORK_SCHEDULE_STATUS_LATE_CHECK_IN: string = WORK_SCHEDULE_STATUS_LATE_CHECK_IN;
  WORK_SCHEDULE_STATUS_NOT_CHECKED: string = WORK_SCHEDULE_STATUS_NOT_CHECKED;
  WORK_SCHEDULE_STATUS_TIME_OFF: string = WORK_SCHEDULE_STATUS_TIME_OFF;
  HOLIDAY_DURATION_MORNING: string = HOLIDAY_DURATION_MORNING;
  HOLIDAY_DURATION_AFTERNOON: string = HOLIDAY_DURATION_AFTERNOON;
  HOLIDAY_DURATION_FULL_DAY: string = HOLIDAY_DURATION_FULL_DAY;
  POLICY_TYPE_DAY: string = POLICY_TYPE_DAY;
  POLICY_TYPE_HOUR: string = POLICY_TYPE_HOUR;
  PART_OF_DAY_START: string = PART_OF_DAY_START;
  PART_OF_DAY_END: string = PART_OF_DAY_END;
  SEARCH_CHARACTERS_MIN_NUMBER: number = 3;
  isGridSelected: boolean = true;
  pageLoaded: boolean = false;
  dataLoaded: boolean = false;
  filtersOpen: boolean = false;
  allCompanies: Array<any> = [];
  allOffices: Array<any> = [];
  allDepartments: Array<any> = [];
  allTeams: Array<any> = [];
  allAreas: Array<any> = [];
  filtersName: Array<string> = ['company', 'office', 'department', 'area', 'team'];
  filters: any = {};
  statusFilter: any = {};
  maxDate: Date = new Date();

  isNavbarOpen: boolean = false;
  navBarSubscription: Subscription;

  standardFilters: any = {
    companyId: [],
    officeId: [],
    departmentId: [],
    areaId: [],
    teamId: [],
  };

  mapColorByStatus: any = {
    [WORK_SCHEDULE_STATUS_NON_WORKING_DAY]: 'Neutral',
    [WORK_SCHEDULE_STATUS_BANK_HOLIDAY]: 'Neutral',
    [WORK_SCHEDULE_STATUS_CHECKED_IN]: 'Secondary',
    [WORK_SCHEDULE_STATUS_LATE_CHECK_IN]: 'Success',
    [WORK_SCHEDULE_STATUS_NOT_CHECKED]: 'Danger',
    [WORK_SCHEDULE_STATUS_TIME_OFF]: 'Primary',
  };

  protected translationResources: Array<ITranslationResource> = [
    { name: 'menu', translationKey: 'attendance-menu' },
    { name: 'page', translationKey: 'people-presence-validation-page' },
  ];

  private async initData(): Promise<void> {
    this.configureGlobalBar();
    this.filters.type = this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL;
    this.filters.date = moment().utc().startOf('day');
    this.filters.time = moment().diff(moment().startOf('day'), 'minutes');
    this.filters.status = [];
    this.statusFilter.options = [];
    Object.keys(this.i18n.page.chipTexts).forEach((iKey) => {
      const option = {
        name: this.i18n.page.chipTexts[iKey],
        _id: iKey,
        active: false,
      };

      if (iKey === this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL) {
        option.active = true;
      }

      this.statusFilter.options.push(option);
    });
    this.statusFilter.pickerType = 'multiple';
    this.statusFilter.label = this.i18n.page.statusLabel;
    this.statusFilter.key = this.i18n.page.statusLabel;
    this.statusFilter.showAvatar = true;
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent("click who's checked in", { platform: 'Web', category: 'Attendance', subcategory1: "Who's checked in" });

    this.navBarSubscription = this.injector.get(NavbarService).isNavbarExpanded$.subscribe((isNavbarOpen: boolean) => {
      this.isNavbarOpen = isNavbarOpen;
    });
  }

  protected destroy(): Promise<void> {
    this.navBarSubscription.unsubscribe();
    return Promise.resolve();
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    try {
      this.initData();
      this.lateCheckInRange = await this.injector.get(AttendanceSettingsService).getLateCheckInRange();
      await this.refreshAllData();

      const filters = await this.injector.get(FiltersController).getAllFiltersValues(this.filtersName);

      if (check.assigned(filters) && check.nonEmptyObject(filters)) {
        this.allCompanies = check.assigned(filters.company) && check.nonEmptyArray(filters.company) ? filters.company : [];
        this.allOffices = check.assigned(filters.office) && check.nonEmptyArray(filters.office) ? filters.office : [];
        this.allDepartments = check.assigned(filters.department) && check.nonEmptyArray(filters.department) ? filters.department : [];
        this.allTeams = check.assigned(filters.team) && check.nonEmptyArray(filters.team) ? filters.team : [];
        this.allAreas = check.assigned(filters.area) && check.nonEmptyArray(filters.area) ? filters.area : [];
      }

      this.dataLoaded = true;
      this.pageLoaded = true;

      resolveFetchData();
    } catch (error) {
      rejectFetchData(error);
    }
  }

  protected toggleFilter({ optionKey, optionIndex }: any): void {
    const currentOptionFilter = this.statusFilter.options[optionIndex];
    const allOptionIndex = this.statusFilter.options.findIndex((iOption) => iOption._id === this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL);
    const allOptionFilter = this.statusFilter.options[allOptionIndex];

    currentOptionFilter.active = !currentOptionFilter.active;

    if (currentOptionFilter._id === this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL) {
      if (!allOptionFilter.active && this.filters.status.length === 1) {
        allOptionFilter.active = true;
        return;
      }

      this.filters.status = [this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL];
      this.statusFilter.options.forEach((iOption) => {
        iOption.active = iOption._id === this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL ? iOption.active : false;
      });
    } else {
      if (currentOptionFilter.active && !this.filters.status.includes(currentOptionFilter._id)) {
        this.filters.status.push(currentOptionFilter._id);
        allOptionFilter.active = this.filters.status.length >= 1 ? false : true;

        if (!allOptionFilter.active) {
          this.filters.status = this.filters.status.filter((iKey) => iKey !== this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL);
        }
      }

      if (!currentOptionFilter.active && this.filters.status.includes(currentOptionFilter._id)) {
        this.filters.status = this.filters.status.filter((iStatus) => iStatus !== currentOptionFilter._id);
        allOptionFilter.active = this.filters.status.length === 0 ? true : false;

        if (allOptionFilter.active) {
          this.filters.status.push(this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL);
        }
      }
    }
  }

  protected clearAllCustomFilters(): void {
    const allOptionIndex = this.statusFilter.options.findIndex((iOption) => iOption._id === this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL);

    this.statusFilter.options.forEach((iOption, index) => (iOption.active = allOptionIndex === index ? true : false));
    this.filters.status = [this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL];
  }

  protected async configureGlobalBar(): Promise<void> {
    try {
      this.globalBarConfig.pageName = this.i18n.menu.pageName;
      const options: Array<IMenuOption> = [];

      if (this.injector.get(CloudRoutesService).checkRoute('attendance/my-attendance') === true) {
        options.push({
          name: this.i18n.menu.myAttendanceTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/my-attendance');
          },
        });
      }

      const attendanceSummaryPermission = await this.injector.get(PrivateSecurityService).getPermissionsForCollection('attendance-app');
      let attendanceSummaryTabStatus;

      if (check.not.assigned(attendanceSummaryPermission)) {
        return;
      }

      if (
        (check.assigned(attendanceSummaryPermission.c_viewAttendanceSummary_all) &&
          attendanceSummaryPermission.c_viewAttendanceSummary_all === true) ||
        (check.assigned(attendanceSummaryPermission.c_viewAttendanceSummary_custom) &&
          check.not.emptyArray(attendanceSummaryPermission.c_viewAttendanceSummary_custom))
      ) {
        attendanceSummaryTabStatus = await this.injector.get(AttendancePreferencesService).getAttendanceSummaryTabAccess();
      }

      if (check.assigned(attendanceSummaryTabStatus) && attendanceSummaryTabStatus === true) {
        options.push({
          name: this.i18n.menu.attendanceSummaryTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/attendance-summary');
          },
        });
      }

      if (
        check.assigned(attendanceSummaryPermission.c_viewAttendanceCheckInTab) &&
        attendanceSummaryPermission.c_viewAttendanceCheckInTab === true &&
        this.injector.get(CloudRoutesService).checkRoute('attendance/check-ins') === true
      ) {
        options.push({
          name: this.i18n.menu.checkInTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/check-ins');
          },
        });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('attendance/settings') === true) {
        options.push({
          name: this.i18n.menu.settingsTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/attendance/settings');
          },
        });
      }

      const optionIndex = _.findIndex(options, ['name', this.i18n.menu.checkInTab]);
      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
    } catch (error) {
      throw error;
    }
  }

  private async refreshAllData(): Promise<void> {
    this.dataLoaded = false;

    const date = moment.utc(this.filters.date).startOf('day').toISOString();
    const allPresenceValidation = await this.injector.get(PresenceValidationController).getPresenceValidation(date);
    this.allPresenceValidation = this.processPresences(allPresenceValidation);
    this.allPresenceValidationFiltered = this.applyCustomFilters();
    this.applyStandardFilters();
    this.selectedDate = this.injector.get(InternationalizationService).getSanitizedTimeZoneFromUTC(moment(this.filters.date)).format('L');
    this.selectedTime = moment.utc().startOf('day').minutes(this.filters.time).format('LT');

    this.dataLoaded = true;
  }

  private amplitudeEventRefresh(): any {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('click refresh', { platform: 'Web', category: 'Attendance', subcategory1: "Who's checked in" });
  }

  private processPresences(allPresenceValidationNotProcessed): any {
    if (check.not.assigned(allPresenceValidationNotProcessed)) {
      return [];
    }

    const presenceValidation = _.cloneDeep(allPresenceValidationNotProcessed);

    const allPresenceValidationProcessed = presenceValidation.map((iPresence) => {
      // Checking if user has checked-in
      if (check.assigned(iPresence.attendanceStartTime) && iPresence.attendanceStartTime <= this.filters.time) {
        iPresence.formattedAttendanceStartTime = moment.utc().startOf('day').minutes(iPresence.attendanceStartTime).format('LT');
      }

      // Checking for bank holidays or time off day or non-working day (no schedule)
      if (check.assigned(iPresence.bankHolidays)) {
        if (
          iPresence.bankHolidays.holidayDuration === this.HOLIDAY_DURATION_FULL_DAY ||
          (iPresence.bankHolidays.holidayDuration === this.HOLIDAY_DURATION_MORNING && this.filters.time < 720) ||
          (iPresence.bankHolidays.holidayDuration === this.HOLIDAY_DURATION_AFTERNOON && this.filters.time > 720)
        ) {
          iPresence.status = this.WORK_SCHEDULE_STATUS_BANK_HOLIDAY;
          this.checkConflicts(iPresence);
          return iPresence;
        }

        this.checkWorkTime(iPresence);
      }

      if (check.assigned(iPresence.timeOffRequests)) {
        if (check.nonEmptyArray(iPresence.timeOffRequests)) {
          iPresence.hasNotWorkingTimeOff = iPresence?.timeOffRequests?.some(iTimeOffRequest => iTimeOffRequest._workTime !== TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER);
        }

        // Full day
        if (
          check.object(iPresence.timeOffRequests) &&
          check.assigned(iPresence.timeOffRequests.isFullDay) &&
          iPresence.timeOffRequests.isFullDay
        ) {
          iPresence.status = this.WORK_SCHEDULE_STATUS_TIME_OFF;
          this.checkConflicts(iPresence);
          return iPresence;
        }

        // Other case (morning or afternoon time off)
        const isTimeOff = iPresence.timeOffRequests.some((iTimeOffRequest) => {
          if (iTimeOffRequest._policyType === this.POLICY_TYPE_HOUR) {
            const minutesFrom = moment.utc(iTimeOffRequest.from).diff(moment.utc(this.filters.date).startOf('day'), 'minutes');
            const minutesTo = moment.utc(iTimeOffRequest.to).diff(moment.utc(this.filters.date).startOf('day'), 'minutes');
            return minutesFrom <= this.filters.time && minutesTo > this.filters.time;
          }
          return (
            (iTimeOffRequest.partOfDayFrom === this.PART_OF_DAY_START && this.filters.time < 720) ||
            (iTimeOffRequest.partOfDayFrom !== this.PART_OF_DAY_START && this.filters.time > 720)
          );
        });

        if (isTimeOff) {
          iPresence.status = this.WORK_SCHEDULE_STATUS_TIME_OFF;
          this.checkConflicts(iPresence);
          return iPresence;
        }

        this.checkWorkTime(iPresence);
      }

      if (iPresence.expectedMinutes === 0) {
        iPresence.status = this.WORK_SCHEDULE_STATUS_NON_WORKING_DAY;
        this.checkConflicts(iPresence);
        return iPresence;
      }

      // Checking all other cases
      if (check.not.assigned(iPresence.status)) {
        this.checkWorkTime(iPresence);
      }

      iPresence.chipText = this.i18n.page.chipTexts[iPresence.status];
      return iPresence;
    });

    return allPresenceValidationProcessed;
  }

  private checkWorkTime(presenceValidation: any): void {
    if (presenceValidation.type === this.WORK_SCHEDULE_TEMPLATE_TYPE_FIXED) {
      if (check.assigned(presenceValidation.attendanceStartTime) && presenceValidation.attendanceStartTime <= this.filters.time) {
        presenceValidation.status =
          check.assigned(presenceValidation.attendanceStartTime) &&
          check.assigned(presenceValidation.shiftStartTime) &&
          presenceValidation.attendanceStartTime - this.lateCheckInRange <= presenceValidation.shiftStartTime
            ? this.WORK_SCHEDULE_STATUS_CHECKED_IN
            : this.WORK_SCHEDULE_STATUS_LATE_CHECK_IN;

        if (presenceValidation.status === this.WORK_SCHEDULE_STATUS_LATE_CHECK_IN) {
          presenceValidation.minLate = this.injector
            .get(DurationPipe)
            .transform(parseInt(presenceValidation.attendanceStartTime, 10) - parseInt(presenceValidation.shiftStartTime, 10));
        }
      } else {
        presenceValidation.status = this.WORK_SCHEDULE_STATUS_NOT_CHECKED;
      }
      presenceValidation.chipText = this.i18n.page.chipTexts[presenceValidation.status];
      return;
    }

    presenceValidation.status =
      check.assigned(presenceValidation.attendanceStartTime) && presenceValidation.attendanceStartTime <= this.filters.time
        ? this.WORK_SCHEDULE_STATUS_CHECKED_IN
        : this.WORK_SCHEDULE_STATUS_NOT_CHECKED;
  }

  private checkConflicts(presenceValidation: any): void {
    presenceValidation.hasConflict =
      (presenceValidation.status === this.WORK_SCHEDULE_STATUS_NON_WORKING_DAY ||
        presenceValidation.status === this.WORK_SCHEDULE_STATUS_BANK_HOLIDAY ||
        presenceValidation.status === this.WORK_SCHEDULE_STATUS_TIME_OFF) &&
      check.assigned(presenceValidation.attendanceStartTime) &&
      check.not.assigned(presenceValidation.hasNotWorkingTimeOff) &&
      presenceValidation.attendanceStartTime <= this.filters.time;

    if (presenceValidation.hasConflict) {
      presenceValidation.conflictedStatus =
        presenceValidation.type === this.WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE ||
        presenceValidation.attendanceStartTime - this.lateCheckInRange <= presenceValidation.shiftStartTime
          ? this.WORK_SCHEDULE_STATUS_CHECKED_IN
          : this.WORK_SCHEDULE_STATUS_LATE_CHECK_IN;
    }

    if (presenceValidation.conflictedStatus === this.WORK_SCHEDULE_STATUS_LATE_CHECK_IN) {
      presenceValidation.minLate = this.injector
        .get(DurationPipe)
        .transform(parseInt(presenceValidation.attendanceStartTime, 10) - parseInt(presenceValidation.shiftStartTime, 10));
    }

    presenceValidation.chipText = check.assigned(presenceValidation.conflictedStatus)
      ? this.i18n.page.chipTexts[presenceValidation.conflictedStatus]
      : this.i18n.page.chipTexts[presenceValidation.status];
  }

  protected adjustTime(): void {
    const today = moment().utc().startOf('day');
    const selectedDate = this.injector
      .get(InternationalizationService)
      .getSanitizedTimeZoneFromUTC(moment(this.filters.date).utc().startOf('day'));
    const currentMinutesOfDay = moment().diff(moment().startOf('day'), 'minutes');

    if (selectedDate.isSame(today) && this.filters.time > currentMinutesOfDay) {
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.filters.time = currentMinutesOfDay;
    }
  }

  protected changeStandardFilters(value?: string, active?: boolean, field?: string): void {
    if (check.assigned(value) && check.assigned(active) && check.assigned(field)) {
      if (active === true) {
        this.standardFilters[field].push(value);
      } else {
        this.standardFilters[field] = this.standardFilters[field].filter((iFilterValue) => iFilterValue !== value);
      }
    }

    this.applyStandardFilters();
  }

  /**
   * Method to filter by standard filters (name, company, office, department)
   * For each element on allPresenceValidationFiltered we will apply all filters
   *    1. belongsToSelection -> If the current standard selection includes the element
   *    2. matchesRegex -> If exists match between names
   */
  private applyStandardFilters(): void {
    this.allPresenceValidationProcessedAndFiltered = this.allPresenceValidationFiltered.filter((iPresence) => {
      const belongsToSelection = Object.keys(this.standardFilters).every((iKey) => {
        if (check.emptyArray(this.standardFilters[iKey])) {
          return true;
        }
        // teamIds and areaIds are plural but the key is singular
        const value: any = iPresence[iKey] ?? iPresence[`${iKey}s`]; // string | Array<string>
        if (check.assigned(value) && check.array(value) && check.nonEmptyArray(value)) {
          return value.some((iValue) => this.standardFilters[iKey].includes(iValue));
        }
        return this.standardFilters[iKey].includes(value);
      });

      const matchesRegex =
        this.searchTerm.length > 0 && this.searchTerm.length < this.SEARCH_CHARACTERS_MIN_NUMBER
          ? true
          : iPresence.displayName.toLowerCase().search(this.searchTerm) !== -1 || iPresence.displayName.search(this.searchTerm) !== -1;

      return belongsToSelection && matchesRegex;
    });
  }

  /**
   * Method to filter by custom filters (status, date, time...)
   * For each element on allPresenceValidation we will apply all filters
   *    1. belongsToSelectedType -> If the current custom selection includes the element
   *    2. belongsToSelectedStatus -> If the element has same status than one in selection
   */
  private applyCustomFilters(): Array<void> {
    const allPresenceValidationFiltered = this.allPresenceValidation.filter((iPresence) => {
      const belongsToSelectedType =
        this.filters.type === this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL ? true : this.filters.type === iPresence.type;

      let belongsToSelectedStatus = true;

      if (check.assigned(this.filters.status) && check.nonEmptyArray(this.filters.status)) {
        belongsToSelectedStatus = this.filters.status.includes(this.WORK_SCHEDULE_TEMPLATE_TYPE_ALL)
          ? true
          : this.filters.status.includes(iPresence.status);
      }

      return belongsToSelectedType && belongsToSelectedStatus;
    });

    return allPresenceValidationFiltered;
  }
}
