import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'kenjo-edit-attendance-comment',
  templateUrl: 'edit-comment.dialog.html',
  styleUrls: ['edit-comment.dialog.scss'],
})
export class EditCommentDialog {
  translations: any;
  originalComment?: string;
  comment?: string;

  constructor(public dialogRef: MatLegacyDialogRef<EditCommentDialog>, @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any) {
    this.translations = this.data.translations;
    this.originalComment = this.data.comment;
    this.comment = this.data.comment;
  }

  save() {
    this.dialogRef.close(this.comment);
  }

  close() {
    this.dialogRef.close();
  }
}
