<div *ngIf="arrayPermissionsToDisplay">
  <div *ngIf="true" class="kenjo-pv-10px kenjo-ph-20px kenjo-mb-10px kenjo-border-bottom kenjo-font-size-16px">{{ i18n[permissionsTitle] }}</div>
  <div class="kenjo-ph-20px" *ngFor="let group of arrayPermissionsToDisplay; let i = index">
    <div *ngIf="permissionKeys[i]" class="kenjo-pv-10px kenjo-mt-20px kenjo-font-color-light-text-757575 kenjo-font-size-14px">{{ i18n[permissionKeys[i]] }}</div>
    <ng-container *ngFor="let iPermissionName of group">
      <orgos-column-container *ngIf="!customPermissions[i][iPermissionName] || customPermissions[i][iPermissionName] === false" centerColumns="true" class="kenjo-pt-10px">
        <orgos-column size="0" class="kenjo-pr-10px">
          <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues[i][iPermissionName])" [value]="permissionValues[i][iPermissionName]" (valueChange)="emitChange(i, iPermissionName, $event)"> </orgos-input-simple-checkbox>
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px"> {{ i18n[iPermissionName] }} </orgos-column>
      </orgos-column-container>

      <!-- If this permissions is a custom permission, treat it as such -->
      <ng-container *ngIf="customPermissions[i][iPermissionName] && customPermissions[i][iPermissionName] === true">
        <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
          <orgos-column size="0">
            <orgos-action icon="add_circle" class="cpb-add-icon" (click)="openCustomPermissionsDialog(i, iPermissionName)" [disabled]="readOnly || (readOnlyValues && readOnlyValues[i][iPermissionName])" color="Primary"> </orgos-action>
          </orgos-column>
          <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px"> {{ i18n[iPermissionName] }} </orgos-column>
        </orgos-column-container>
        <orgos-custom-permission-summary
          *ngIf="permissionKey[i] && permissionValues[i][iPermissionName] && permissionValues[i][iPermissionName].length > 0 && !readOnlyValues[i][iPermissionName]"
          [customPermission]="permissionValues[i][iPermissionName]"
          [permissionKey]="permissionKeys[i]"
          class="kenjo-pl-20px kenjo-pr-10px"
        ></orgos-custom-permission-summary>
      </ng-container>
    </ng-container>
  </div>
</div>
