<orgos-dialog-container [title]="data.mode === 'add' || data.mode === 'addFirst' ? i18n.dialog.addDialogTitle : i18n.dialog.editDialogTitle" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-select [label]="i18n.dialog.elementType" [options]="options" [(value)]="nodeInfo.nodeType" [autoOrder]="false" class="kenjo-block"></orgos-input-simple-select>

  <orgos-input-simple-search-user *ngIf="nodeInfo.nodeType === 'Employee' || nodeInfo.nodeType === 'Assistant'" [label]="i18n.dialog.selectEmployee" [users]="employeeOptions" [(value)]="nodeInfo.employeeId" [required]="true" class="kenjo-block"></orgos-input-simple-search-user>
  <orgos-input-simple-text *ngIf="nodeInfo.nodeType === 'Employee' || nodeInfo.nodeType === 'Assistant'" [label]="i18n.dialog.jobTitle" [readOnly]="true" [value]="userWorkMap[nodeInfo.employeeId]?.jobTitle || ''" class="kenjo-block"></orgos-input-simple-text>

  <orgos-input-simple-select *ngIf="nodeInfo.nodeType === 'Company'" [label]="i18n.dialog.selectCompany" [options]="companyOptions" [(value)]="nodeInfo.companyId" [required]="true" (validation)="companyValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="companyValidation && companyValidation.getError('required')">{{ i18n.dialog.companyIsMandatory }}</orgos-input-error>
  </orgos-input-simple-select>

  <orgos-input-simple-select *ngIf="nodeInfo.nodeType === 'Area'" [label]="i18n.dialog.selectArea" [options]="areaOptions" [(value)]="nodeInfo.areaId" [required]="true" (validation)="areaValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="areaValidation && areaValidation.getError('required')">{{ i18n.dialog.areaIsMandatory }}</orgos-input-error>
  </orgos-input-simple-select>

  <orgos-input-simple-select *ngIf="nodeInfo.nodeType === 'Office'" [label]="i18n.dialog.selectOffice" [options]="officeOptions" [(value)]="nodeInfo.officeId" [required]="true" (validation)="officeValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="officeValidation && officeValidation.getError('required')">{{ i18n.dialog.officeIsMandatory }}</orgos-input-error>
  </orgos-input-simple-select>

  <orgos-input-simple-select *ngIf="nodeInfo.nodeType === 'Department'" [label]="i18n.dialog.selectDepartment" [options]="departmentOptions" [(value)]="nodeInfo.departmentId" [required]="true" (validation)="departmentValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="departmentValidation && departmentValidation.getError('required')">{{ i18n.dialog.departmentIsMandatory }}</orgos-input-error>
  </orgos-input-simple-select>

  <orgos-input-simple-select *ngIf="nodeInfo.nodeType === 'Team'" [label]="i18n.dialog.selectTeam" [options]="teamOptions" [(value)]="nodeInfo.teamId" [required]="true" (validation)="teamValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="teamValidation && teamValidation.getError('required')">{{ i18n.dialog.teamIsMandatory }}</orgos-input-error>
  </orgos-input-simple-select>

  <orgos-input-simple-text-autocomplete *ngIf="nodeInfo.nodeType === 'FutureRole'" [label]="i18n.dialog.futureRole" [(value)]="nodeInfo.futureRoleName" [required]="true" [suggestedOptions]="jobOpeningNames" (validation)="futureRoleValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="futureRoleValidation && futureRoleValidation.getError('required')">{{ i18n.dialog.futureRoleIsMandatory }}</orgos-input-error>
  </orgos-input-simple-text-autocomplete>

  <div class="kenjo-mt-15px kenjo-mb-10px">
    <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{i18n.dialog.color}}*</div>
    <orgos-input-simple-color-picker [(value)]="nodeInfo.color"></orgos-input-simple-color-picker>
  </div>

  <mat-slide-toggle *ngIf="data.mode !== 'addFirst'" [(ngModel)]="nodeInfo.applyColorSubchart">{{i18n.dialog.applyColorSubchart}}</mat-slide-toggle>

  <!-- BUTTONS -->
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ i18n.misc.goBackButtonDialog }}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Success" (click)="save()" [disabled]="!isFormValid()">
        {{ i18n.misc.save }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
