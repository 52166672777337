import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { IUserPersonalModel } from '../../../models/user-personal.model';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserAccountService } from './user-account.service';

@Injectable()
export class UserPersonalService implements IGenericService {
  private USER_PERSONAL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-personal-db`;
  private USER_PERSONAL_PERMISSIONS_KEY: string = 'user-personal';
  private USER_PERSONAL_INTERNATIONALIZATION: string = 'user-personal-collection';
  private userPersonalMapById = new UserPersonalMapById();
  private userPersonalMapByQuery = new UserPersonalMapByQuery();
  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService, private userAccountService: UserAccountService, private http: HttpClient) {}

  create(data: object): Promise<IUserPersonalModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserPersonalService.name, 'create');
    error.message = 'UserPersonal should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserPersonalModel> {
    const cachedItem = this.userPersonalMapById.getItem(id);
    if (cachedItem) {
      return Promise.resolve(cachedItem);
    }
    return new Promise<IUserPersonalModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_PERSONAL_URL, id)
        .then((userPersonal: IUserPersonalModel) => {
          this.userPersonalMapById.setItem(id, userPersonal);
          resolve(userPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserPersonalService.name, 'getById'));
        });
    });
  }

  getByIdComplete(id: string): Promise<IUserPersonalModel> {
    const cachedItem = this.userPersonalMapById.getItem(id);
    if (cachedItem) {
      return Promise.resolve(cachedItem);
    }
    return new Promise<IUserPersonalModel>((resolve, reject) => {
      this.genericService
        .getById(`${this.USER_PERSONAL_URL}/complete`, id)
        .then((userPersonal: IUserPersonalModel) => {
          this.userPersonalMapById.setItem(id, userPersonal);
          resolve(userPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserPersonalService.name, 'getByIdComplete'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_PERSONAL_URL, id, data)
        .then(() => {
          this.userPersonalMapById.clearItem(id);
          this.userPersonalMapByQuery.clearAll();
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserPersonalService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserPersonalService.name, 'deleteById');
    error.message = 'UserPersonal should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_PERSONAL_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_PERSONAL_INTERNATIONALIZATION);
  }

  find(findBody: any, showArchived: boolean = false): Promise<Array<IUserPersonalModel>> {
    return new Promise<Array<IUserPersonalModel>>((resolve, reject) => {
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }
      const cachedItems = this.userPersonalMapByQuery.getItem(findBody);
      if (cachedItems) {
        resolve(cachedItems);
        return;
      }

      this.genericService
        .find(this.USER_PERSONAL_URL, findBody)
        .then((allUserPersonal: Array<IUserPersonalModel>) => {
          this.userPersonalMapByQuery.setItem(findBody, allUserPersonal);
          resolve(allUserPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserPersonalService.name, 'find'));
        });
    });
  }

  // findComplete will return all the fields, taking into consideration the permissions to see personal tab
  findComplete(findBody: any, showArchived: boolean = false): Promise<Array<IUserPersonalModel>> {
    return new Promise<Array<IUserPersonalModel>>((resolve, reject) => {
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }
      const cachedItems = this.userPersonalMapByQuery.getItem(findBody);
      if (cachedItems) {
        return resolve(cachedItems);
      }

      this.genericService
        .find(`${this.USER_PERSONAL_URL}/complete`, findBody)
        .then((allUserPersonal: Array<IUserPersonalModel>) => {
          this.userPersonalMapByQuery.setItem(findBody, allUserPersonal);
          resolve(allUserPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserPersonalService.name, 'findComplete'));
        });
    });
  }

  getAllUserPersonal(activeUsersOnly: boolean, showArchived: boolean = false): Promise<Array<IUserPersonalModel>> {
    let cachedItems, findBody;
    if (activeUsersOnly === true) {
      // Find the active users only
      return new Promise<Array<IUserPersonalModel>>((resolve, reject) => {
        this.userAccountService
          .getAllUserAccount(true)
          .then((allActiveUsers: Array<any>) => {
            const arrayActiveUserIds = allActiveUsers.map((iActiveUser) => {
              return iActiveUser._id;
            });
            findBody = {
              _id: {
                $in: arrayActiveUserIds
              }
            };
            if (showArchived === true) {
              findBody['isArchived'] = true;
            }
            cachedItems = this.userPersonalMapByQuery.getItem(findBody);
            if (cachedItems) {
              return Promise.resolve(cachedItems);
            }
            return this.genericService.find(this.USER_PERSONAL_URL, findBody);
          })
          .then((allUserPersonal: Array<IUserPersonalModel>) => {
            if (!cachedItems) {
              this.userPersonalMapByQuery.setItem(findBody, allUserPersonal);
            }
            resolve(allUserPersonal);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, UserPersonalService.name, 'getAllUserPersonal'));
          });
      });
    } else {
      findBody = {
        _id: { $ne: null }
      };
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }

      cachedItems = this.userPersonalMapByQuery.getItem(findBody);
      if (cachedItems) {
        return Promise.resolve(cachedItems);
      }
      return new Promise<Array<IUserPersonalModel>>((resolve, reject) => {
        this.genericService
          .find(this.USER_PERSONAL_URL, findBody)
          .then((allUserPersonal: Array<IUserPersonalModel>) => {
            this.userPersonalMapByQuery.setItem(findBody, allUserPersonal);
            resolve(allUserPersonal);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, UserPersonalService.name, 'getAllUserPersonal'));
          });
      });
    }
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_PERSONAL_URL);
  }

  async getUserPersonalShiftPlanActive(): Promise<Array<IUserPersonalModel>> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const body = { _id: { $ne: null } };
      return await this.http.post<Array<IUserPersonalModel>>(`${this.USER_PERSONAL_URL}/shift-plan-active`, body, httpOptions).toPromise();

    } catch (error) {
      throw this.errorManager.handleRawError(error, UserPersonalService.name, 'getUserPersonalShiftPlanActive');
    }
  }

  clearCache() {
    this.userPersonalMapById.clearAll();
    this.userPersonalMapByQuery.clearAll();
  }
}

class CacheMap<U, T> {
  cache: {[key: string]: T} = {};

  constructor() {
    setInterval(() => this.clearAll(), 60 * 60 * 1000);
  }

  getItem(key: U): T {
    const serializedKey = this.getSerializedKey(key);
    const item = this.cache[serializedKey];
    return item && this.clone(item);
  }

  setItem(key: U, item: T): void {
    const serializedKey = this.getSerializedKey(key);
    this.cache[serializedKey] = this.clone(item);
  }

  getSerializedKey(key: U): string {
    return JSON.stringify(key);
  }

  clearItem(key: U): void {
    const serializedKey = this.getSerializedKey(key);
    this.cache[serializedKey] = undefined;
  }

  clearAll(): void {
    this.cache = {};
  }

  clone(item: T): T {
    return item;
  }
}

class UserPersonalMapById extends CacheMap<string, IUserPersonalModel> {
  getSerializedKey(key: string): string {
    return key;
  }

  clone(item: IUserPersonalModel): IUserPersonalModel {
    return {...item};
  }
}

class UserPersonalMapByQuery extends CacheMap<any, Array<IUserPersonalModel>> {
  clone(item: Array<IUserPersonalModel>): Array<IUserPersonalModel> {
    return [...item];
  }
}