import { Injectable } from '@angular/core';
import { CreateAttendancePolicySuccessDialog } from '@app/cloud-features/settings-attendance/pages/attendance-policies/dialogs/create-attendance-policy-dialog/dialogs/create-attendance-policy-success/create-attendance-policy-success.dialog';
import { EditCommentDialog } from '@app/common-components/widgets/punch-clock/dialogs/edit-comment.dialog';
import { CompanyDocReadTrackingDialog } from '@app/standard/pages/documents/dialogs/company-doc-read-tracking-dialog/company-doc-read-tracking.dialog';
import { AddEmployeeDialog } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/add-employee.dialog';
import { AddEmployeeActivationStepComponent } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/components/activation-step/activation-step.component';
import { AddEmployeeAttendanceStepComponent } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/components/attendance-step/attendance-step.component';
import { AddEmployeePersonalStepComponent } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/components/personal-step/personal-step.component';
import { AddEmployeeTimeOffStepComponent } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/components/time-off-step/time-off-step.component';
import { AddEmployeeWorkStepComponent } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/components/work-step/work-step.component';
import { ExportAndCloseDatevPayrollDialog } from '@app/standard/pages/people/payroll/dialogs/export-and-close-datev-payroll/export-and-close-datev-payroll.dialog';
import { SurchargeDetailsDialog } from '@app/standard/pages/people/payroll/dialogs/surcharge-details-dialog/surcharge-details.dialog';
import { PlanCardComponent } from '@app/standard/pages/settings-billing/components/plan-card/plan-card.component';
import { PriceCalculatorComponent } from '@app/standard/pages/settings-billing/components/price-calculator/price-calculator.component';
import { SubscriptionCardComponent } from '@app/standard/pages/settings-billing/components/subscription-card/subscription-card.component';
import { DisabledAppDialog } from '@app/standard/pages/settings-billing/settings-billing-platform-usage/disabled-app-dialog/disabled-app.dialog';
import { DisabledAppsDialog } from '@app/standard/pages/settings-billing/settings-billing-platform-usage/disabled-apps-dialog/disabled-apps.dialog';
import { SettingsBillingPlatformUsagePage } from '@app/standard/pages/settings-billing/settings-billing-platform-usage/settings-billing-platform-usage.page';
import { SettingsBillingUpgradePage } from '@app/standard/pages/settings-billing/settings-billing-upgrade/settings-billing-upgrade.page';
import { DeleteDummyDataDialog } from '@app/standard/pages/settings-overview/dialogs/delete-dummy-data.dialog';

import { ChangeBreakReminderMessageDialog } from '../../cloud-features/settings-attendance/components/change-break-reminder-message/change-break-reminder-message.dialog';
import { UpdateAppDialog } from './app-status/update-app.dialog';
import { CalendarPage } from './calendar/calendar.page';
import { ExportCalendarPopupComponent } from './calendar/export-calendar-popup/export-calendar-popup.component';
import { DocumentCardComponent } from './documents/components/document-card/document-card.component';
import { DocumentTemplateCardComponent } from './documents/components/document-template-card/document-template-card.component';
import { SignerAutocompleteComponent } from './documents/components/signer-autocomplete/signer-autocomplete.component';
import { AddPersonalMessageDialog } from './documents/dialogs/add-personal-message.dialog';
import { EditDocumentImportNameDialog } from './documents/dialogs/edit-document-import-name.dialog';
import { EditDocumentImportUserDialog } from './documents/dialogs/edit-document-import-user.dialog';
import { RecruitingUploadDocumentDialog } from './documents/dialogs/recruiting-upload-document.dialog';
import { RecruitingUploadMultipleDocsDialog } from './documents/dialogs/recruiting-upload-multiple-docs.dialog';
import { SelectSignersDialog } from './documents/dialogs/select-signers.dialog';
import { SendReminderDialog } from './documents/dialogs/send-reminder.dialog';
import { UploadDocumentDialog } from './documents/dialogs/upload-document.dialog';
import { VoidDocumentDialog } from './documents/dialogs/void-document.dialog';
import { DocumentsCompanyDocsPage } from './documents/documents-company-docs/documents-company-docs.page';
import { DocumentsDetailPage } from './documents/documents-detail/documents-detail.page';
import { DocumentsDigitalSignaturePage } from './documents/documents-digital-signature/documents-digital-signature.page';
import { DocumentsEmployeeDetailDocsPage } from './documents/documents-employee-detail-docs/documents-employee-detail-docs.page';
import { DocumentsEmployeeDocsPage } from './documents/documents-employee-docs/documents-employee-docs.page';
import { DocumentsMyDocsPage } from './documents/documents-my-docs/documents-my-docs.page';
import { EditTagDialog } from './documents/documents-preferences/dialogs/edit-tag.dialog';
import { DocumentsPreferencesPage } from './documents/documents-preferences/documents-preferences.page';
import { DocumentsTemplatesDetailPage } from './documents/documents-templates-detail/documents-templates-detail.page';
import { DocumentsTemplatesPage } from './documents/documents-templates/documents-templates.page';
import { GenericPage } from './generic.page';
import { ApiKeyPreferencesPage } from './integrations/api-key/api-key-preferences/api-key-preferences.page';
import { CreateApiKeyDialog } from './integrations/api-key/api-key-preferences/dialogs/create-api-key.dialog';
import { PeopleDetailPincodeActionComponent } from './people-detail/components/people-detail-pincode-action/people-detail-pincode-action.component';
import { EditPinCodeDialog } from './people-detail/dialogs/edit-pincode.dialog';
import { NewAppreciationDialog } from './people-detail/dialogs/new-appreciation.dialog';
import { PeopleHistoryDialog } from './people-detail/dialogs/people-history.dialog';
import { SetCategoryDialog } from './people-detail/dialogs/set-category.dialog';
import { PeopleDetailAttendancePage } from './people-detail/people-detail-attendance/people-detail-attendance.page';
import { AddEmploymentDialog } from './people-detail/people-detail-compensation/dialogs/add-employment.dialog';
import { AddSalaryDialog } from './people-detail/people-detail-compensation/dialogs/add-salary.dialog';
import { AddVariablePayDialog } from './people-detail/people-detail-compensation/dialogs/add-variable-pay.dialog';
import { AssignSurchargeRuleDialog } from './people-detail/people-detail-compensation/dialogs/assign-surcharge-rule/assign-surcharge-rule.dialog';
import { EditEmploymentDialog } from './people-detail/people-detail-compensation/dialogs/edit-employment.dialog';
import { EditSalaryDialog } from './people-detail/people-detail-compensation/dialogs/edit-salary.dialog';
import { PeopleDetailCompensationPage } from './people-detail/people-detail-compensation/people-detail-compensation.page';
import { PeopleDetailPayrollPage } from './people-detail/people-detail-payroll/people-detail-payroll.page';
import { PeopleDetailPerformanceNominateTeamPage } from './people-detail/people-detail-performance-nominate-team/people-detail-performance-nominate-team.page';
import { PeopleDetailPerformanceNominatePage } from './people-detail/people-detail-performance-nominate/people-detail-performance-nominate.page';
import { PeopleDetailPerformanceReviewPage } from './people-detail/people-detail-performance-review/people-detail-performance-review.page';
import { AddEmailSignatureDialog } from './people-detail/people-detail-personal/dialogs/add-email-signature/add-email-signature.dialog';
import { AddVirtualOfficeDialog } from './people-detail/people-detail-personal/dialogs/add-virtual-office/add-virtual-office.dialog';
import { ChangeWorkEmailDialog } from './people-detail/people-detail-personal/dialogs/change-work-email/change-work-email.dialog';
import { EditCostCentersDialog } from './people-detail/people-detail-personal/dialogs/edit-cost-centers/edit-cost-centers.dialog';
import { EditWorkScheduleSimpleDialog } from './people-detail/people-detail-personal/dialogs/edit-work-schedule-simple/edit-work-schedule-simple.dialog';
import { EditWorkScheduleDialog } from './people-detail/people-detail-personal/dialogs/edit-work-schedule/edit-work-schedule.dialog';
import { CompleteShiftplanActivationDialog } from './people-detail/people-detail-personal/dialogs/shift-plan-complete-activation/shift-plan-complete-activation.dialog';
import { ShowWorkScheduleDialog } from './people-detail/people-detail-personal/dialogs/show-work-schedule/show-work-schedule.dialog';
import { PeopleDetailPersonalPage } from './people-detail/people-detail-personal/people-detail-personal.page';
import { PeopleDetailPublicProfilePage } from './people-detail/people-detail-public-profile/people-detail-public-profile.page';
import { AttendanceSummaryPage } from './people/attendance/attendance-summary.page';
import { AddBreakDialog } from './people/dialogs/add-break-dialog/add-break.dialog';
import { OvertimeAdjustBalanceDialog } from './people/dialogs/overtime-adjust-balance-dialog/overtime-adjust-balance.dialog';
import { OvertimeBalanceHistoryDialog } from './people/dialogs/overtime-balance-history-dialog/overtime-balance-history.dialog';
import { OvertimeCompensationPayDialog } from './people/dialogs/overtime-compensation-pay-dialog/overtime-compensation-pay.dialog';
import { OvertimeCompensationTimeOffDialog } from './people/dialogs/overtime-compensation-time-off-dialog/overtime-compensation-time-off.dialog';
import { OvertimeNegativeBalanceDialog } from './people/dialogs/overtime-negative-balance-dialog/overtime-negative-balance.dialog';
import { UpdateUserWorkDialog } from './people/dialogs/update-user-work-dialog/update-user-work-dialog.dialog';
import { PeoplePage } from './people/directory/directory.page';
import { OrgChartAddEditNodeDialog } from './people/org-chart/components/dialogs/org-chart-add-edit-node-dialog/org-chart-add-edit-node.dialog';
import { OrgChartAddViewDialog } from './people/org-chart/components/dialogs/org-chart-add-view-dialog/org-chart-add-view.dialog';
import { OrgChartDeleteNodeDialog } from './people/org-chart/components/dialogs/org-chart-delete-node-dialog/org-chart-delete-node.dialog';
import { OrgChartRenameViewDialog } from './people/org-chart/components/dialogs/org-chart-rename-view-dialog/org-chart-rename-view.dialog';
import { OrgChartComponent } from './people/org-chart/components/org-chart/org-chart.component';
import { OrgChartPage } from './people/org-chart/org-chart.page';
import { ConvertChildrenPipe } from './people/payroll/components/convert-children/convert-children.pipe';
import { EditCommentDialogComponent } from './people/payroll/components/edit-comment-dialog/edit-comment-dialog.component';
import { PayrollCommentCellComponent } from './people/payroll/components/payroll-comment-cell/payroll-comment-cell.component';
import { PayrollUploadPayslipsComponent } from './people/payroll/components/payroll-upload-payslips/payroll-upload-payslips.component';
import { CloseDatevPayrollDialog } from './people/payroll/dialogs/close-datev-payroll/close-datev-payroll.dialog';
import { PayrollPage } from './people/payroll/payroll.page';
import { PresenceValidationCardComponent } from './people/presence-validation/components/presence-validation-card/presence-validation-card.component';
import { PresenceValidationPage } from './people/presence-validation/presence-validation.page';
import { GoalsDetailPage } from './performance-management/goals/goals-detail/goals-detail.page';
import { CreateGoalDialog } from './performance-management/goals/goals/dialogs/create-goal-dialog/create-goal.dialog';
import { GoalsPage } from './performance-management/goals/goals/goals.page';
import { AddAgendaItemDialog } from './performance-management/meetings/dialogs/add-agenda-item-dialog/add-agenda-item.dialog';
import { AddParticipantDialog } from './performance-management/meetings/dialogs/add-participant-dialog/add-participant.dialog';
import { CreateTemplateDialog } from './performance-management/meetings/dialogs/create-template-dialog/create-template.dialog';
import { NewMeetingDialog } from './performance-management/meetings/dialogs/new-meeting-dialog/new-meeting.dialog';
import { MeetingsDetailPage } from './performance-management/meetings/meetings-detail/meetings-detail.page';
import { MeetingsPage } from './performance-management/meetings/meetings/meetings.page';
import { SettingsBillingInfoPage } from './settings-billing/settings-billing-info/settings-billing-info.page';
import { SettingsBillingInvoicesPage } from './settings-billing/settings-billing-invoices/settings-billing-invoices.page';
import { SettingsBillingMenuComponent } from './settings-billing/settings-billing-menu/settings-billing-menu.component';
import { CreateCostCenterDialog } from './settings-cost-centers/dialogs/create-cost-center.dialog';
import { SettingsCostCentersPage } from './settings-cost-centers/settings-cost-centers.page';
import { CreateDepartmentDialog } from './settings-departments/dialogs/create-department.dialog';
import { EditEmailTemplatePage } from './settings-email-template/edit-email-template/edit-email-template.page';
import { SettingsEmailTemplatePage } from './settings-email-template/settings-email-template.page';
import { AddCustomFieldDialog } from './settings-employee-information/dialogs/add-custom-field/add-custom-field.dialog';
import { AddVariablePayTypeDialog } from './settings-employee-information/dialogs/add-variable-pay-type/add-variable-pay-type.dialog';
import { EditVariablePayTypeDialog } from './settings-employee-information/dialogs/edit-variable-pay-type/edit-variable-pay-type.dialog';
import { UserEmploymentContractTypeDialog } from './settings-employee-information/dialogs/user-employment-contract-type-dialog/user-employment-contract-type-dialog';
import { UserEmploymentSubcategoryDialog } from './settings-employee-information/dialogs/user-employment-subcategory-dialog/user-employment-subcategory-dialog';
import { UserEmploymentTerminationTypeDialog } from './settings-employee-information/dialogs/user-employment-termination-type-dialog/user-employment-termination-type-dialog';
import { SettingsEmployeeInformationPage } from './settings-employee-information/settings-employee-information.page';
import { SettingsOverviewPage } from './settings-overview/settings-overview.page';
import { CloneProfileDialog } from './settings-roles-and-permissions/clone-profile-dialog/clone-profile.dialog';
import { CustomPermissionSummaryComponent } from './settings-roles-and-permissions/custom-permission-summary/custom-permission-summary.component';
import { CustomPermissionsBoxMultipleComponent } from './settings-roles-and-permissions/custom-permissions-box-multiple/custom-permissions-box-multiple.component';
import { CustomPermissionsBoxComponent } from './settings-roles-and-permissions/custom-permissions-box/custom-permissions-box.component';
import { CustomPermissionConditionComponent } from './settings-roles-and-permissions/edit-custom-permissions-dialog/components/custom-permission-condition.component';
import { EditCustomPermissionsDialog } from './settings-roles-and-permissions/edit-custom-permissions-dialog/edit-custom-permissions.dialog';
import { PermissionsBoxComponent } from './settings-roles-and-permissions/permissions-box/permissions-box.component';
import { PermissionsPackage } from './settings-roles-and-permissions/permissions-package/permissions-package.component';
import { PermissionsSummaryComponent } from './settings-roles-and-permissions/permissions-summary/permissions-summary.component';
import { ProfileAssignmentComponent } from './settings-roles-and-permissions/profile-assignment/profile-assignment.component';
import { ProfileBoxComponent } from './settings-roles-and-permissions/profile-box/profile-box.component';
import { SettingsRolesAndPermissionsPage } from './settings-roles-and-permissions/settings-roles-and-permissions.page';
import { SettingsWelcomeWizardPage } from './settings-welcome-wizard/settings-welcome-wizard.page';
import { AddWorkflowActionDialog } from './settings-workflows/add-worfklow-action-dialog/add-workflow-action.dialog';
import { AddWorkflowTriggerDialog } from './settings-workflows/add-workflow-trigger-dialog/add-workflow-trigger.dialog';
import { EditWorkflowPage } from './settings-workflows/edit-workflow/edit-workflow.page';
import { SettingsWorkflowsPage } from './settings-workflows/settings-workflows.page';
import { WorkflowSummaryPage } from './settings-workflows/workflow-summary/workflow-summary.page';
import { WorkflowTriggerManualPage } from './settings-workflows/workflow-trigger-manual/workflow-trigger-manual.page';
import { TasksAssignedPage } from './tasks/tasks-assigned/tasks-assigned.page';
import { TasksCompanyPage } from './tasks/tasks-company/tasks-company.page';
import { TasksMinePage } from './tasks/tasks-mine/tasks-mine.page';

const pageRegistry = {
  directory: PeoplePage,
  payroll: PayrollPage,
  'org-chart': OrgChartPage,
  'people-detail-public': PeopleDetailPublicProfilePage,
  'people-detail-personal': PeopleDetailPersonalPage,
  'people-detail-attendance': PeopleDetailAttendancePage,
  'people-detail-compensation': PeopleDetailCompensationPage,
  'people-detail-payroll': PeopleDetailPayrollPage,
  'people-detail-performance-review': PeopleDetailPerformanceReviewPage,
  'people-detail-performance-nominate': PeopleDetailPerformanceNominatePage,
  'people-detail-performance-nominate-team': PeopleDetailPerformanceNominateTeamPage,
  'people-attendance-summary': AttendanceSummaryPage,
  'people-presence-validation': PresenceValidationPage,
  'documents-company-docs': DocumentsCompanyDocsPage,
  'documents-my-docs': DocumentsMyDocsPage,
  'documents-employee-detail-docs': DocumentsEmployeeDetailDocsPage,
  'documents-employee-docs': DocumentsEmployeeDocsPage,
  'documents-digital-signature': DocumentsDigitalSignaturePage,
  'documents-preferences': DocumentsPreferencesPage,
  'documents-detail': DocumentsDetailPage,
  'documents-templates': DocumentsTemplatesPage,
  'documents-templates-detail': DocumentsTemplatesDetailPage,
  calendar: CalendarPage,
  'settings-overview': SettingsOverviewPage,
  'settings-employee-information': SettingsEmployeeInformationPage,
  'settings-email-template': SettingsEmailTemplatePage,
  'edit-email-template': EditEmailTemplatePage,
  'settings-welcome-wizard': SettingsWelcomeWizardPage,
  'settings-roles-and-permissions': SettingsRolesAndPermissionsPage,
  'settings-workflows': SettingsWorkflowsPage,
  'settings-cost-centers': SettingsCostCentersPage,
  'settings-billing-upgrade': SettingsBillingUpgradePage,
  'settings-billing-info': SettingsBillingInfoPage,
  'settings-billing-invoices': SettingsBillingInvoicesPage,
  'settings-billing-platform-usage': SettingsBillingPlatformUsagePage,
  'tasks-mine': TasksMinePage,
  'tasks-company': TasksCompanyPage,
  'tasks-assigned': TasksAssignedPage,
  goals: GoalsPage,
  'goals-detail': GoalsDetailPage,
  meetings: MeetingsPage,
  'meetings-detail': MeetingsDetailPage,
  'api-keys-preferences': ApiKeyPreferencesPage,
};

export const standardPages = [
  ConvertChildrenPipe,
  GenericPage,
  PeoplePage,
  PayrollPage,
  PayrollUploadPayslipsComponent,
  PayrollCommentCellComponent,
  EditCommentDialogComponent,
  OrgChartPage,
  AddEmployeeDialog,
  AddEmployeePersonalStepComponent,
  AddEmployeeWorkStepComponent,
  AddEmployeeTimeOffStepComponent,
  AddEmployeeActivationStepComponent,
  AddEmployeeAttendanceStepComponent,
  OvertimeCompensationTimeOffDialog,
  OvertimeAdjustBalanceDialog,
  OvertimeCompensationPayDialog,
  OvertimeBalanceHistoryDialog,
  PeopleDetailPublicProfilePage,
  PeopleDetailPersonalPage,
  PeopleDetailAttendancePage,
  PeopleDetailCompensationPage,
  PeopleDetailPayrollPage,
  PeopleDetailPerformanceReviewPage,
  PeopleDetailPerformanceNominatePage,
  PeopleDetailPerformanceNominateTeamPage,
  PeopleDetailPincodeActionComponent,
  AttendanceSummaryPage,
  NewAppreciationDialog,
  EditPinCodeDialog,
  SetCategoryDialog,
  PeopleHistoryDialog,
  AddEmploymentDialog,
  EditEmploymentDialog,
  AddSalaryDialog,
  EditSalaryDialog,
  AddVariablePayDialog,
  AddEmailSignatureDialog,
  AddVirtualOfficeDialog,
  ChangeWorkEmailDialog,
  EditCostCentersDialog,
  ChangeBreakReminderMessageDialog,
  DocumentsCompanyDocsPage,
  DocumentsMyDocsPage,
  DocumentsEmployeeDetailDocsPage,
  DocumentsEmployeeDocsPage,
  DocumentsDigitalSignaturePage,
  DocumentsPreferencesPage,
  DocumentsDetailPage,
  DocumentCardComponent,
  SignerAutocompleteComponent,
  UploadDocumentDialog,
  PresenceValidationPage,
  EditTagDialog,
  DocumentsTemplatesPage,
  DocumentTemplateCardComponent,
  DocumentsTemplatesDetailPage,
  PresenceValidationCardComponent,
  CalendarPage,
  ExportCalendarPopupComponent,
  SettingsOverviewPage,
  CreateDepartmentDialog,
  SettingsEmployeeInformationPage,
  AddCustomFieldDialog,
  AddVariablePayTypeDialog,
  EditVariablePayTypeDialog,
  UserEmploymentContractTypeDialog,
  UserEmploymentSubcategoryDialog,
  UserEmploymentTerminationTypeDialog,
  SettingsWelcomeWizardPage,
  SettingsRolesAndPermissionsPage,
  SettingsWorkflowsPage,
  EditWorkflowPage,
  AddWorkflowActionDialog,
  AddWorkflowTriggerDialog,
  WorkflowTriggerManualPage,
  WorkflowSummaryPage,
  SettingsEmailTemplatePage,
  EditEmailTemplatePage,
  TasksMinePage,
  TasksCompanyPage,
  TasksAssignedPage,
  RecruitingUploadDocumentDialog,
  RecruitingUploadMultipleDocsDialog,
  ProfileBoxComponent,
  PermissionsSummaryComponent,
  ProfileAssignmentComponent,
  CloneProfileDialog,
  PermissionsBoxComponent,
  CustomPermissionsBoxComponent,
  PermissionsPackage,
  CustomPermissionsBoxMultipleComponent,
  CustomPermissionSummaryComponent,
  EditCustomPermissionsDialog,
  CustomPermissionConditionComponent,
  EditDocumentImportNameDialog,
  EditDocumentImportUserDialog,
  CompanyDocReadTrackingDialog,
  SettingsCostCentersPage,
  CreateCostCenterDialog,
  GoalsPage,
  GoalsDetailPage,
  CreateGoalDialog,
  AddParticipantDialog,
  MeetingsPage,
  MeetingsDetailPage,
  AddAgendaItemDialog,
  NewMeetingDialog,
  CreateTemplateDialog,
  OrgChartComponent,
  OrgChartAddEditNodeDialog,
  OrgChartDeleteNodeDialog,
  OrgChartAddViewDialog,
  OrgChartRenameViewDialog,
  SettingsBillingInfoPage,
  SettingsBillingInvoicesPage,
  SettingsBillingMenuComponent,
  SettingsBillingPlatformUsagePage,
  DisabledAppsDialog,
  DisabledAppDialog,
  SubscriptionCardComponent,
  PlanCardComponent,
  PriceCalculatorComponent,
  SettingsBillingUpgradePage,
  ApiKeyPreferencesPage,
  CreateApiKeyDialog,
  DeleteDummyDataDialog,
  ShowWorkScheduleDialog,
  EditWorkScheduleDialog,
  EditWorkScheduleSimpleDialog,
  CompleteShiftplanActivationDialog,
  UpdateUserWorkDialog,
  SelectSignersDialog,
  SendReminderDialog,
  VoidDocumentDialog,
  AddPersonalMessageDialog,
  OvertimeNegativeBalanceDialog,
  AddBreakDialog,
  UpdateAppDialog,
  CloseDatevPayrollDialog,
  ExportAndCloseDatevPayrollDialog,
  EditCommentDialog,
  AssignSurchargeRuleDialog,
  SurchargeDetailsDialog,
  CreateAttendancePolicySuccessDialog,
];

@Injectable()
export class StandardPagesRegistry {
  getPage(pageName: string): any {
    return pageRegistry[pageName];
  }
}
