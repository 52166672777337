import { AfterViewInit, Component, DoCheck, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormField } from '@angular/material/legacy-form-field';
import { InputAbstractComponent } from '@app/standard/components/input-abstract/input-abstract.component';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import * as check from 'check-types';
import isEmail from 'validator/es/lib/isEmail';

@Component({
  selector: 'kenjo-input-email-name',
  templateUrl: 'input-email-name.component.html',
  styleUrls: ['input-email-name.component.scss'],
})
export class InputEmailNameComponent extends InputAbstractComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() inputId: string;
  @Input() domain: string;
  @Input() showDomain: boolean;
  @Input() errorMessage: string;

  @ViewChild('hiddenText') textEl: ElementRef;
  @ViewChild('elasticInput') elasticInput: ElementRef;
  @ViewChild('emailNameFormField') emailNameFormField: MatLegacyFormField;

  formController: UntypedFormControl;
  maxWidth: number;
  mounted: boolean = false;

  ngOnInit() {
    this.initForm();
  }
  ngAfterViewInit(): void {
    this.mounted = true;
    this.calculateEmailWidth();
  }
  ngOnChanges() {
    if (this.mounted === true) {
      this.initForm();
      this.forceValidation();
      this.calculateEmailWidth(this.formController.value);
    }
  }

  initForm() {
    this.formController = new UntypedFormControl({ value: this.getValue(), disabled: this.readOnly });
  }

  calculateEmailWidth(value?: string) {
    this.maxWidth = this.emailNameFormField._elementRef.nativeElement.clientWidth;
    this.resize(value);
  }

  protected validateValue(newValue: any): InputValidation {
    const inputValidation = super.validateValue(newValue);
    const email = `${newValue}${this.domain}`;
    if (check.assigned(newValue) && check.not.emptyString(newValue) && !isEmail(email, { allow_utf8_local_part: false })) {
      inputValidation.setError('email');
      this.formController.setErrors({ email: true });
    }
    return inputValidation;
  }

  resize(value?: string) {
    this.textEl.nativeElement.textContent = value ?? this.elasticInput.nativeElement.value;
    const matSuffixWidth: number = this.emailNameFormField._elementRef.nativeElement.querySelector('.mat-form-field-suffix').clientWidth;
    let width: number = Math.min(this.textEl.nativeElement.offsetWidth, this.maxWidth - matSuffixWidth);
    if (width === 0) {
      width = 1;
    }

    this.emailNameFormField._elementRef.nativeElement.style.setProperty('width', `${width}px`);
    this.emailNameFormField._elementRef.nativeElement.style.setProperty('flex', 'none');
  }
}
