import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoMessageModule } from '@app/common-components/info-message/info-message.module';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { RangeDatepickerComponent } from '@app/common-components/kenjo-range-datepicker/component/range-datepicker.component';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';

@NgModule({
  declarations: [RangeDatepickerComponent],
  imports: [
    CommonModule,
    IconModule,
    TruncateTextModule,
    MaterialModule,
    StandardComponentsModule,
    InfoMessageModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [],
  exports: [RangeDatepickerComponent],
})
export class RangeDatepickerModule {}
