import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { IShiftPlanDate } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import {
  ITimeOffRequestDay,
  IUserTimeOffRequestDetail,
  IUserTimeOffRequests,
  ShiftPlanTimeOffService,
} from '@app/cloud-features/shift-plan/services/shift-plan-time-off.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shift-plan-time-off-card',
  templateUrl: 'shift-plan-time-off-card.component.html',
  styleUrls: ['shift-plan-time-off-card.component.scss'],
})
export class ShiftPlanTimeOffCardComponent implements OnInit {
  show = { details: false, list: false, more: false };
  timeOffData: IUserTimeOffRequests;
  disconnected: boolean = false;
  timeOffList: Array<IUserTimeOffRequestDetail>;
  selectedTimeOff: IUserTimeOffRequestDetail;
  VIEWS = { ...VIEWS };

  @Input() timeOff: ITimeOffRequestDay;
  @Input() showMore: boolean;
  @Input() currentDay: { index: number; value: string };
  @Input() dateRange: IShiftPlanDate | null;
  @Input() container: ElementRef;
  @Input() userId: string;
  @Input() view: string;
  @Input() componentTranslation: { [key: string]: string };
  @Input() statusMaps: { color: { [key: string]: string }; list: { [key: string]: string } };

  @Output() blockScroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('detailsMenuTrigger', { static: false }) detailsMenuTrigger: MatMenuTrigger;
  @ViewChild('listMenuTrigger', { static: false }) listMenuTrigger: MatMenuTrigger;

  @ViewChild('timeOffCard') timeOffCard: ElementRef;

  constructor(public injector: Injector) {}
  ngOnInit() {
    this.show.more = this.showMore;
    if (check.not.assigned(this.timeOff) || this.timeOff.total === 0) {
      this.timeOff = null;
    }
  }

  async onClickCard(isTimeOffSelected = false) {
    if (!isTimeOffSelected) {
      await this.getTimeOffDetails();
    }
    this.show.details = !this.show.details;
    this.onBlockScroll();
    if (!this.show.details) {
      return;
    }
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('Shiftplan timeoff details checked', { category: 'Shiftplan', subcategory: 'Scheduling', subcategory2: 'timeoff' });
    this.detailsMenuTrigger.openMenu();
  }

  closeDetails() {
    this.show.details = false;
    this.detailsMenuTrigger.closeMenu();
    this.onBlockScroll();
  }

  closeList() {
    this.show.list = false;
    this.listMenuTrigger.closeMenu();
    this.onBlockScroll();
  }

  async onClickMore() {
    const showList = !this.show.list;
    if (!showList || !this.show.more || check.not.assigned(this.timeOff?.total) || this.timeOff?.total <= 1) {
      return;
    }

    await this.getTimeOffDetails();
    this.show.list = showList;
    this.onBlockScroll();
    this.listMenuTrigger.openMenu();
  }

  onClickMonthly() {
    if (check.not.assigned(this.timeOff?.total)) {
      return;
    }

    if (this.timeOff?.total > 1) {
      this.onClickMore();
    }
    if (this.timeOff?.total === 1) {
      this.onClickCard();
    }
  }

  async getTimeOffDetails() {
    try {
      this.timeOffData = await this.injector
        .get(ShiftPlanTimeOffService)
        .getTimeOffRequestDetail(this.dateRange.from.toDate(), this.dateRange.to.endOf('day').toDate(), this.userId, this.view);
      this.timeOffList = this.timeOffData?.days?.[this.currentDay.index].sort(this.sortByDate());
      this.selectedTimeOff = this.timeOffList[0];
      this.disconnected = false;
    } catch {
      this.disconnected = true;
    }
  }

  public onBlockScroll() {
    const isScrollBlocked = this.show.details || this.show.list;
    this.blockScroll.emit(isScrollBlocked);
  }

  private sortByDate(): (a, b) => number {
    return (a, b) => (moment.utc(a._createdAt).isAfter(b._createdAt) ? -1 : 1);
  }
}
