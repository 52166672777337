<orgos-container aspect="card" *ngIf="!showOvertimeSummary && pageTranslation && !loadingPage" class="kenjo-overflow-x-auto">
  <orgos-column-container centerColumns="true" class="kenjo-border-bottom kenjo-pv-10px kenjo-ph-30px">
    <orgos-column class="kenjo-font-size-18px">{{pageTranslation.shiftPlanSettingsPageTitle}}</orgos-column>
  </orgos-column-container>
  <orgos-column-container class="sspp-main-container">
    <orgos-column id="shift-plan-settings-side-menu" size="0" class="sspp-left-container kenjo-p-30px">
      <div class="kenjo-font-size-16px kenjo-text-transform-uppercase">{{pageTranslation.generalHeader}}</div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('users')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'users'"
      >
        {{pageTranslation.shiftEmployeesSettings}}
      </div>

      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('notifications')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'notifications'"
      >
        {{pageTranslation.notificationsSettings}}
      </div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('view-options')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'view-options'"
      >
        {{pageTranslation.viewOptionsSettings}}
      </div>

      <div class="kenjo-mt-30px kenjo-font-size-16px kenjo-text-transform-uppercase">{{pageTranslation.locationHeader}}</div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('locations')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'locations'"
      >
        {{pageTranslation.locationHeader}}
      </div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('working-areas')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'working-areas'"
      >
        {{pageTranslation.workingAreasSettings}}
      </div>

      <div class="kenjo-mt-30px kenjo-font-size-16px kenjo-text-transform-uppercase">{{pageTranslation.rolesTagsHeader}}</div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('roles')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'roles'"
      >
        {{pageTranslation.rolesSettings}}
      </div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('tags')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'tags'"
      >
        {{pageTranslation.tagsSettings}}
      </div>
      <div class="kenjo-mt-30px kenjo-font-size-16px kenjo-text-transform-uppercase">{{pageTranslation.rulesHeader}}</div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('breaks')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'breaks'"
      >
        {{pageTranslation.breaksSettings}}
      </div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('open-shifts')"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'open-shifts'"
      >
        {{pageTranslation.openShiftsSettings}}
      </div>
      <div
        class="kenjo-mt-15px sspp-section-header"
        (click)="changeSection('wish-days'); logAmplitudeEvent()"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'wish-days'"
      >
        {{pageTranslation.availability}}
        <orgos-chip *ngIf="showChip" color="New">{{ pageTranslation.newChip }}</orgos-chip>
      </div>
      <!-- TO-KEEP
      <div class="kenjo-mt-30px kenjo-font-size-16px kenjo-text-transform-uppercase">{{pageTranslation.approvalsHeader}}</div>
      <div class="kenjo-mt-15px sa-section-header" (click)="changeSection('approvals')" [class.kenjo-font-color-primary-5993e3]="sectionSelected === 'approvals'">{{pageTranslation.delegatedApprovalsSettings}}</div>
      -->
    </orgos-column>
    <orgos-column size="1" class="kenjo-p-30px">
      <kenjo-settings-shift-plan-users
        *ngIf="sectionSelected === 'users'"
        [parentTranslation]="pageTranslation"
        [shiftplanPermissions]="shiftplanPermissions"
      ></kenjo-settings-shift-plan-users>
      <kenjo-settings-shift-plan-notifications
        *ngIf="sectionSelected === 'notifications'"
        [parentTranslation]="pageTranslation"
        [generalSettings]="generalSettings"
        (updatedSettings)="generalSettings = $event"
      ></kenjo-settings-shift-plan-notifications>
      <kenjo-settings-shift-plan-view-options
        *ngIf="sectionSelected === 'view-options'"
        [parentTranslation]="pageTranslation"
        [generalSettings]="generalSettings"
        (updatedSettings)="generalSettings = $event"
      ></kenjo-settings-shift-plan-view-options>
      <kenjo-settings-shift-plan-locations
        *ngIf="sectionSelected === 'locations'"
        [parentTranslation]="pageTranslation"
      ></kenjo-settings-shift-plan-locations>
      <kenjo-settings-shift-plan-working-areas
        *ngIf="sectionSelected === 'working-areas'"
        [parentTranslation]="pageTranslation"
        [myProfileId]="myProfile._id"
      ></kenjo-settings-shift-plan-working-areas>
      <kenjo-settings-shift-plan-roles
        *ngIf="sectionSelected === 'roles'"
        [parentTranslation]="pageTranslation"
        [myProfileId]="myProfile._id"
      ></kenjo-settings-shift-plan-roles>
      <kenjo-settings-shift-plan-tags
        *ngIf="sectionSelected === 'tags'"
        [parentTranslation]="pageTranslation"
        [myProfileId]="myProfile._id"
      ></kenjo-settings-shift-plan-tags>
      <kenjo-settings-shift-plan-breaks
        *ngIf="sectionSelected === 'breaks'"
        [parentTranslation]="pageTranslation"
      ></kenjo-settings-shift-plan-breaks>
      <kenjo-settings-shift-plan-open-shifts
        *ngIf="sectionSelected === 'open-shifts'"
        [parentTranslation]="pageTranslation"
        [generalSettings]="generalSettings"
        (updatedSettings)="generalSettings = $event"
      ></kenjo-settings-shift-plan-open-shifts>
      <kenjo-settings-shift-plan-wish-days
        *ngIf="sectionSelected === 'wish-days'"
        [parentTranslation]="pageTranslation"
        [generalSettings]="generalSettings"
        (updatedSettings)="generalSettings = $event"
      >
      </kenjo-settings-shift-plan-wish-days>
      <!-- TO-KEEP
        <kenjo-settings-shift-plan-approvals [parentTranslation]="pageTranslation" *ngIf="sectionSelected === 'approvals'"></kenjo-settings-shift-plan-approvals>
      -->
    </orgos-column>
  </orgos-column-container>
</orgos-container>
