import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'orgos-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary' = 'Success';
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;
  confirmDisabled: boolean;
  cancelDisabled: boolean;
  employees: Array<any>;
  bullets: Array<{ title: string; text: string }>;
  bulletSeparator: string = '-';

  constructor(public dialogRef: MatLegacyDialogRef<ConfirmDialogComponent>, @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any) {
    this.titleText = this.data.titleText;
    this.subtitleText = this.data.subtitleText;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.confirmButtonId = this.data.confirmButtonId ?? '';
    this.cancelButtonId = this.data.cancelButtonId ?? '';
    this.componentId = this.data.componentId ?? null;
    this.confirmDisabled = this.data.confirmDisabled ?? false;
    this.cancelDisabled = this.data.cancelDisabled ?? false;
    this.employees = this.data.employees;
    this.bullets = this.data.bullets;
    this.bulletSeparator = this.data.bulletSeparator ?? this.bulletSeparator;
    this.dialogRef.disableClose = !!this.data.disableClose;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }
}

export interface IConfirmDialogData {
  titleText: string,
  subtitleText: string,
  confirmButtonText: string,
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary',
  cancelButtonText: string,
};