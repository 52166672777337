import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-truncate-text',
  templateUrl: 'truncate-text.component.html',
  styleUrls: ['truncate-text.component.scss']
})
export class TruncateTextComponent implements AfterContentChecked {
  disableTooltip: boolean = true;
  tooltipText: string = '';

  @ViewChild('content', { static: true }) content: ElementRef;
  @ViewChild('parentContainer', { static: true }) parentContainer: ElementRef;
  @Input() tooltipPosition: string = 'above';
  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.refreshTooltip();
  }

  refreshTooltip(): void {
    if (check.not.assigned(this.content)) {
      this.tooltipText = '';
      this.disableTooltip = true;
      this.cdr.detectChanges();
      return;
    }

    this.tooltipText = this.content.nativeElement.innerText;
    const parentWidth = this.parentContainer.nativeElement.offsetWidth;
    const contentWidth = this.content.nativeElement.offsetWidth;
    this.disableTooltip = check.emptyString(this.tooltipText) || parentWidth >= contentWidth;
    this.cdr.detectChanges();
  }
}
