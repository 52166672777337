import { DecimalPipe, getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ISelectOption } from '@app/standard/core/select-option';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-price-calculator',
  templateUrl: 'price-calculator.component.html',
  styleUrls: ['price-calculator.component.scss'],
})
export class PriceCalculatorComponent implements OnInit {
  private _translations: any;
  get translations() {
    return this._translations;
  }
  @Input() set translations(translations: any) {
    this._translations = translations;

    this.planOptions = [
      { value: 'starter', name: translations.plans.starter.title },
      { value: 'growth', name: translations.plans.growth.title },
    ];
  }

  @Input() recruitmentAddonPrices: any;
  @Input() recruitmentPlusAddonPrices: any;
  @Input() onboardingAddonPrices: { id: string; priceByPlan: { connect: number; growth: number } };

  _minEmployees: number;
  @Input() set minEmployees(minEmployees: number) {
    this.employees = minEmployees;
    this._minEmployees = minEmployees;
    this.onEmployeesChange();
    this.computePrice();
    this.fakeSliderWidth = (minEmployees / 300) * 100;
  }

  get minEmployees() {
    return this._minEmployees;
  }

  _datevImplementationFeePrices: any;
  @Input() set datevImplementationFeePrices(datevImplementationFeePrices: any) {
    this._datevImplementationFeePrices = datevImplementationFeePrices;
    this.showDatevImplementation =
      check.assigned(this._datevImplementationFeePrices) &&
      check.not.emptyObject(this._datevImplementationFeePrices);
  }

  private _planPrices: any;
  @Input() set planPrices(planPrices: any) {
    this._planPrices = planPrices;
    this.computePrice();
  }
  get planPrices() {
    return this._planPrices;
  }

  private _showYearly: boolean;
  @Input() set showYearly(showYearly: boolean) {
    this._showYearly = showYearly;
    this.computePrice();
  }
  get showYearly() {
    return this._showYearly;
  }

  @Input() currentPlan: { isStarter: boolean; isGrowth: boolean; isConnect: boolean };
  showContactUs: boolean = false;
  @Input() isInTrialOrCancelled = false;
  @Input() isCurrentBillingYearly = false;
  @Input() contactUsLink: string;
  @Input() isInTrial: boolean = false;

  @Output() changeShowYearly: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() recruitingAddon: EventEmitter<string> = new EventEmitter();
  @Output() datevImplementation: EventEmitter<boolean> = new EventEmitter(false);
  @Output() onboardingAddon: EventEmitter<boolean> = new EventEmitter(false);
  @Output() openCheckout: EventEmitter<string> = new EventEmitter();
  @Output() selectedEmployees: EventEmitter<number> = new EventEmitter();

  totalPrice: string;
  planPrice: string;
  addonPrice: string;
  tentativeRecruitingAddonPrice: string;
  tentativeRecruitingPlusAddonPrice: string;
  datevPrice: string;
  tentativeDatevPrice: string;
  onboardingPrice: string;
  tentativeOnboardingPrice: string;
  discount: string;
  employees: number = 150;
  planOptions: Array<ISelectOption>;
  plan = 'starter';
  activateRecruitingAddon: boolean = true;
  currentRecruitingAddon = 'recruiting';
  showDatevImplementation = true;
  includeDatevImplementation = false;
  includeOnboardingAddon = false;
  fakeSliderWidth = 0;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.computeShowContactUs();
  }

  computeShowContactUs(event?: any): void {
    // connect will always show contact,
    if (this.currentPlan.isConnect) {
      this.showContactUs = true;
      return;
    }
    // for growth: show contact if either
    // 1. starter is selected in radio buttons
    // 2. plan is yearly and monthly is selected
    if (this.currentPlan.isGrowth && this.isInTrialOrCancelled === false) {
      this.showContactUs =
        this.plan === 'starter' ||
        (this.isCurrentBillingYearly &&
          ((this._showYearly === true && event?.value === 'monthly') ||
            (this._showYearly === false && event === 'growth')));
      return;
    }
    // starter will always show upgrade, unless yearly to monthly
    if (this.currentPlan.isStarter && this.isInTrialOrCancelled === false) {
      this.showContactUs =
        this.isCurrentBillingYearly &&
        ((this._showYearly === true && event?.value === 'monthly') ||
          (this._showYearly === false && event?.value !== 'yearly'));
    }
  }

  computePrice(event?: any) {
    const employees = event?.value ?? this.employees;

    const plan = this.showYearly
      ? this.planPrices[this.plan].yearly
      : this.planPrices[this.plan].monthly;

    const planPrice = (plan.amount / 100) * employees;
    const tentativeRecruitingAddonPrice = this.getAddonPrice(
      employees,
      this.recruitmentAddonPrices,
      this.showYearly
    );
    const tentativeRecruitingPlusAddonPrice = this.getAddonPrice(
      employees,
      this.recruitmentPlusAddonPrices,
      this.showYearly
    );
    const addonPrice =
      this.activateRecruitingAddon === false || employees === 0
        ? 0
        : this.currentRecruitingAddon === 'recruiting'
        ? tentativeRecruitingAddonPrice
        : tentativeRecruitingPlusAddonPrice;
    const tentativeDatevPrice = this.getDatevPrice(employees);
    const tentativeOnboardingPrice = this.getOnboardingPrice();
    const datevPrice =
      this.showDatevImplementation && this.includeDatevImplementation ? tentativeDatevPrice : 0;
    const onboardingPrice = this.includeOnboardingAddon ? tentativeOnboardingPrice : 0;
    const planDiscount = (this.planPrices[this.plan].monthly.amount / 100) * employees - planPrice;
    const addonDiscount =
      this.activateRecruitingAddon === false
        ? 0
        : this.currentRecruitingAddon === 'recruiting'
        ? this.getAddonPrice(employees, this.recruitmentAddonPrices) - tentativeRecruitingAddonPrice
        : this.getAddonPrice(employees, this.recruitmentPlusAddonPrices) -
          tentativeRecruitingPlusAddonPrice;

    this.planPrice = `${this.injector
      .get(DecimalPipe)
      .transform(planPrice, '1.0-2')}${getCurrencySymbol(plan.currency, 'narrow')}`;
    this.addonPrice = `${this.injector
      .get(DecimalPipe)
      .transform(addonPrice, '1.0-2')}${getCurrencySymbol(plan.currency, 'narrow')}`;
    this.datevPrice = `${this.injector
      .get(DecimalPipe)
      .transform(datevPrice, '1.0-2')}${getCurrencySymbol(plan.currency, 'narrow')}`;
    this.onboardingPrice = `${this.injector
      .get(DecimalPipe)
      .transform(onboardingPrice, '1.0-2')}${getCurrencySymbol(plan.currency, 'narrow')}`;
    this.tentativeOnboardingPrice = `${this.injector
      .get(DecimalPipe)
      .transform(tentativeOnboardingPrice, '1.0-2')}${getCurrencySymbol(plan.currency, 'narrow')}`;
    this.tentativeDatevPrice = `${this.injector
      .get(DecimalPipe)
      .transform(tentativeDatevPrice, '1.0-2')}${getCurrencySymbol(plan.currency, 'narrow')}`;
    this.tentativeRecruitingAddonPrice = `${this.injector
      .get(DecimalPipe)
      .transform(tentativeRecruitingAddonPrice, '1.0-2')}${getCurrencySymbol(
      plan.currency,
      'narrow'
    )}`;
    this.tentativeRecruitingPlusAddonPrice = `${this.injector
      .get(DecimalPipe)
      .transform(tentativeRecruitingPlusAddonPrice, '1.0-2')}${getCurrencySymbol(
      plan.currency,
      'narrow'
    )}`;
    this.discount = `${planDiscount + addonDiscount > 0 ? '-' : ''}${this.injector
      .get(DecimalPipe)
      .transform(planDiscount + addonDiscount, '1.0-2')}${getCurrencySymbol(
      plan.currency,
      'narrow'
    )}`;
    this.totalPrice = `${this.injector
      .get(DecimalPipe)
      .transform(planPrice + addonPrice, '1.0-2')}${getCurrencySymbol(plan.currency, 'narrow')}`;
  }

  private getAddonPrice(employees: number, addon: any, isYearly = false) {
    for (const step in addon.monthly.steps) {
      const [start, end] = step.split('-');
      const startNumber = Number(start);
      const endNumber = end === '*' ? 999999 : Number(end);

      if (employees >= startNumber && employees <= endNumber) {
        return isYearly ? addon.yearly.steps[step] / 100 : addon.monthly.steps[step] / 100;
      }
    }

    return 0;
  }

  private getDatevPrice(employees: number) {
    for (const step in this._datevImplementationFeePrices.steps) {
      const [start, end] = step.split('-');
      const startNumber = Number(start);
      const endNumber = end === '*' ? 999999 : Number(end);

      if (employees >= startNumber && employees <= endNumber) {
        return this._datevImplementationFeePrices.steps[step] / 100;
      }
    }

    return 0;
  }

  private getOnboardingPrice() {
    return this.onboardingAddonPrices.priceByPlan[this.plan] / 100;
  }

  changeYearly(element: any) {
    if (element?.value === 'yearly') {
      this.changeShowYearly.emit(true);
    }

    if (element?.value === 'monthly') {
      this.changeShowYearly.emit(false);
    }
  }

  onOpenCheckout() {
    this.openCheckout.emit(this.plan);
  }

  onContactUs(): void {
    window.open(this.contactUsLink);
  }

  onAddRecruitingAddon(event: any) {
    this.activateRecruitingAddon = event;
    if (this.activateRecruitingAddon) {
      this.recruitingAddon.emit(this.currentRecruitingAddon);
      return;
    }

    this.recruitingAddon.emit('');
  }

  onChangeRecruitingAddon(addon: string) {
    this.currentRecruitingAddon = addon;
    this.recruitingAddon.emit(this.currentRecruitingAddon);
    this.computePrice();
  }

  onAddDatevImplementation(event: boolean) {
    this.includeDatevImplementation = event;
    this.computePrice();
    this.datevImplementation.emit(this.includeDatevImplementation);
  }

  onAddOnboardingAddon(event: boolean) {
    this.includeOnboardingAddon = event;
    this.computePrice();
    this.onboardingAddon.emit(this.includeOnboardingAddon);
  }

  onEmployeesChange() {
    this.selectedEmployees.emit(this.employees);
  }
}
