import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import {
  ISurchargeParamsDataModel,
  ISurchargePayment,
  ISurchargeSummaryModel,
  PayrollSurchargeRulesService,
} from '@app/cloud-features/settings-surcharges/services/payroll-surcharge-rules.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';

@Component({
  templateUrl: 'surcharge-details.dialog.html',
  styleUrls: ['surcharge-details.dialog.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateY(-20px)',
          height: '0',
          overflow: 'hidden',
        })
      ),
      transition('void <=> *', [animate('300ms ease-in-out'), style({ height: '*' })]),
    ]),
    trigger('rotateIcon', [
      state(
        'false',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      state(
        'true',
        style({
          transform: 'rotate(180deg)',
        })
      ),
      transition('false <=> true', animate('300ms ease-in-out')),
    ]),
  ],
})
export class SurchargeDetailsDialog implements OnInit {
  translations: { [translationKey: string]: string } = {};
  surchargeSummary: ISurchargeSummaryModel;
  trackedHours: number;
  canSeeUserAttendance: boolean;
  private surchargeParams: ISurchargeParamsDataModel;

  constructor(
    public dialogRef: MatLegacyDialogRef<SurchargeDetailsDialog>,
    private injector: Injector,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: { surchargeParams: ISurchargeParamsDataModel; trackedHours?: number }
  ) {
    this.surchargeParams = this.data.surchargeParams;
    this.trackedHours = this.data.trackedHours;
  }

  ngOnInit() {
    this.initData();
  }

  private async initData() {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('payroll-page');
      await this.fetchSurchargeSummary();
      await this.setCanSeeUserAttendance();

      this.injector.get(PrivateAmplitudeService).logEvent('surcharge payments breakdown', {
        platform: 'Web',
        category: 'Payroll',
        subcategory1: 'Salary surcharges',
        subcategory2: 'Salary surcharges',
      });
    } catch {
      this.translations = {};
      this.closeDialog();
    }
  }

  private async fetchSurchargeSummary(): Promise<void> {
    this.surchargeSummary = await this.injector.get(PayrollSurchargeRulesService).getSurchargeSummary(this.surchargeParams);
    if (!this.surchargeSummary?.surchargePayments?.length) {
      return;
    }
    this.surchargeSummary.surchargePayments.forEach((surchargePayment: ISurchargePayment) => {
      surchargePayment.showAttendances = false;
    });
  }

  private async setCanSeeUserAttendance(): Promise<void> {
    this.canSeeUserAttendance = false;
    try {
      const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
      if (['admin', 'hr-admin', 'finance-admin'].includes(myProfile)) {
        this.canSeeUserAttendance = true;
        return;
      }

      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') === false) {
        return;
      }

      const employeesPermissions = await this.injector.get(PrivateSecurityService).getPermissionsForCollection('employees');
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

      if (employeesPermissions.c_viewAttendanceTab_all === true) {
        this.canSeeUserAttendance = true;
        return;
      }

      if (loggedUser._id === this.data.surchargeParams.userId && employeesPermissions.c_viewAttendanceTab_own === true) {
        this.canSeeUserAttendance = true;
        return;
      }

      if (!employeesPermissions.c_viewAttendanceTab_custom || !employeesPermissions.c_viewAttendanceTab_custom?.length) {
        return;
      }

      const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
      const employeeUserWork = allUserWork.find((iUserWork) => {
        return iUserWork._id === this.data.surchargeParams.userId;
      });
      const canSeeAttendanceViaCustomPermissions = await customPermissions.applyCustomPermissionsToDocument(
        null,
        'user-work',
        employeesPermissions[`c_viewAttendanceTab_custom`],
        employeesPermissions[`c_viewAttendanceTab_own`],
        employeeUserWork,
        allUserWork,
        loggedUser
      );
      return canSeeAttendanceViaCustomPermissions;
    } catch {
      return;
    }
  }

  openAttendance(): void {
    if (!this.canSeeUserAttendance) {
      return;
    }
    window.open(`/cloud/people/${this.surchargeParams.userId}/attendance`, '_blank');
  }

  closeDialog(): void {
    this.dialogRef.close({});
  }
}
