<mat-form-field [appearance]="appearance" *ngIf="!readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput #input="matInput" [ngModel]="value" (ngModelChange)="onValueChange($event)" [required]="required" autocomplete="off" />
  <div *ngIf="prefix && (input.focused || !input.empty)" matPrefix>{{ prefix }}</div>
  <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
  <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</mat-form-field>
<mat-form-field [appearance]="appearance" *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput #input="matInput" [ngModel]="value" readOnly disabled [required]="required" autocomplete="off" />
  <div *ngIf="prefix && (input.focused || !input.empty)" matPrefix>{{ prefix }}</div>
  <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
  <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</mat-form-field>
<div *ngIf="!isValueValid" class="istc-error"><ng-content select="orgos-input-error"></ng-content></div>
