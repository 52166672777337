import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserCompensationService } from './user-compensation.service';

@Injectable()
export class UserVariablePayService implements IGenericService {
  private USER_VARIABLE_PAY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-variable-pay-db`;
  private USER_VARIABLE_PAY_INTERNATIONALIZATION: string = 'user-variable-pay-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private userCompensationService: UserCompensationService) {}

  create(data: object): Promise<IUserVariablePayModel> {
    return new Promise<IUserVariablePayModel>((resolve, reject) => {
      this.genericService
        .create(this.USER_VARIABLE_PAY_URL, data)
        .then((userVariablePay: IUserVariablePayModel) => {
          resolve(userVariablePay);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserVariablePayService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IUserVariablePayModel> {
    return new Promise<IUserVariablePayModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_VARIABLE_PAY_URL, id)
        .then((userVariablePay: IUserVariablePayModel) => {
          resolve(userVariablePay);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserVariablePayService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_VARIABLE_PAY_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserVariablePayService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.USER_VARIABLE_PAY_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserVariablePayService.name, 'deleteById'));
        });
    });
  }

  find(findBody: any): Promise<Array<IUserVariablePayModel>> {
    return new Promise<Array<IUserVariablePayModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_VARIABLE_PAY_URL, findBody)
        .then((result: Array<IUserVariablePayModel>) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserVariablePayService.name, 'find'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.userCompensationService.getPermissions();
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_VARIABLE_PAY_INTERNATIONALIZATION);
  }

  getAllUserVariablePay(userId: string): Promise<Array<IUserVariablePayModel>> {
    const findBody = {
      _userId: userId
    };

    return new Promise<Array<IUserVariablePayModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_VARIABLE_PAY_URL, findBody)
        .then((allUserVariablePay: Array<IUserVariablePayModel>) => {
          resolve(allUserVariablePay);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserVariablePayService.name, 'getAllUserVariablePay'));
        });
    });
  }

  getAll(): Promise<Array<IUserVariablePayModel>> {
    const findBody = {
      _userId: { $ne: null }
    };

    return new Promise<Array<IUserVariablePayModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_VARIABLE_PAY_URL, findBody)
        .then((allUserVariablePay: Array<IUserVariablePayModel>) => {
          resolve(allUserVariablePay);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserVariablePayService.name, 'getAll'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_VARIABLE_PAY_URL);
  }
}

export interface IUserVariablePayModel {
  _id: string;
  _userId: string;
  _companyId: string;
  effectiveDate?: Date;
  type?: string;
  amount?: number;
  currency?: string;
  comments?: string;
  everyMonths?: boolean;
  frequency?: number;
  endDate?: Date;
  _createdAt?: Date;
}
