import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as legacy from 'legacy-encoding';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DatevAsciiController {
  private DATEV_ASCII_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/datev-ascii`;


  constructor(private injector: Injector, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  async generateDatevDocument(datevSettingsId: string, from: Date, to: Date) {
    try {
      const datevDocument = await this.injector.get(HttpClient).post<IDatevDocument>(`${this.DATEV_ASCII_CONTROLLER_URL}/document`, { datevSettingsId, from, to }).toPromise();
      this.downloadDocument(datevDocument);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevAsciiController.name, 'generateDatevDocument');
    }
  }

  async exportDatevZip(datevSettingsId: string, from: Date, to: Date, periodIdentifier: Number, companyName: string) {
    try {
      const datevDocument = await this.injector.get(HttpClient).post<IDatevExportZip>(`${this.DATEV_ASCII_CONTROLLER_URL}/export-datev`, { datevSettingsId, from, to, periodIdentifier }).toPromise();
      this.downloadZIP(datevDocument.zip, to, companyName);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevAsciiController.name, 'exportDatevZip');
    }
  }

  downloadDocument(document: IDatevDocument) {
    const encoded = legacy.encode(document.content, 'windows-1252');
    const txtFile = new Blob([encoded], { type: 'text/plain;charset=ansi' });
    FileSaver.saveAs(txtFile, 'datev.txt');
  }

  downloadZIP(document: any, toDate: Date, companyName: string) {
    const encoded = legacy.encode(document, 'base64');
    const blob = new Blob([encoded], { type: 'application/zip' });
    const nameFormatDate = moment(toDate).format('DD.MM.YYYY');

    FileSaver.saveAs(blob, `DATEV_${companyName.replace(/\s+/g, '')}_${nameFormatDate}.zip`);
  }
}

interface IDatevDocument {
  content: string;
}

interface IDatevExportZip {
  zip: string;
}