import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IRecruitingActivity } from '@app/common-components/recruiting/recruiting-activity-widget/recruiting-activity-widget.component';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ICandidateInitialData } from '@app/cloud-features/recruiting/recruiting-candidate-main/model/candidate-initial-data.model';
import { ICandidateReviewData } from '@app/cloud-features/recruiting/recruiting-candidate-main/model/candidate-review-data.model';
import { IDiscussionComment } from '@app/cloud-features/recruiting/recruiting-candidate-main/model/discussion-comment.model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({ providedIn: 'root' })
export class RecruitingCandidateService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting/candidate-detail`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) {}

  /**
   * ### Candidate Page Initial Data
   * -----
   * First call that should be made in the Candidate Main page, allows you
   * obtain information about the permissions that the current user has in terms
   * of actions, tabs, positions and widgets. It also fetches the data about the given
   * candidate, including the position-candidate and the positions
   */
  async getInitialData(candidateId: string): Promise<ICandidateInitialData> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingCandidateService.name, 'getInitialData');
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers };
      const result = await this.http.get<ICandidateInitialData>(`${this.URL}/initial-data/${candidateId}`, httpOptions).toPromise();
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, RecruitingCandidateService.name, 'getInitialData');
    }
  }

  async getCandidateActivity(candidateId: string, positionId: string) {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingCandidateService.name, 'getCandidateActivity');
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers };
      const result = await this.http.get<Array<IRecruitingActivity>>(`${this.URL}/activity/${candidateId}/${positionId}`, httpOptions).toPromise();
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, RecruitingCandidateService.name, 'getCandidateActivity');
    }
  }

  async getDiscussionWidgetData(candidateId: string, positionId: string): Promise<Array<IDiscussionComment>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingCandidateService.name, 'getDiscussionWidgetData');
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers };
      const result = await this.http.get<Array<IDiscussionComment>>(`${this.URL}/discussion-widget-data/${candidateId}/${positionId}`, httpOptions).toPromise();

      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, RecruitingCandidateService.name, 'getDiscussionWidgetData');
    }
  }

  async getReviewData(candidateId: string, positionId?: string): Promise<ICandidateReviewData> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingCandidateService.name, 'getReviewData');
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers };
      const url = check.assigned(positionId) ? `${this.URL}/review/${candidateId}/${positionId}` : `${this.URL}/review/${candidateId}`;
      const result = await this.http.get<ICandidateReviewData>(url, httpOptions).toPromise();

      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, RecruitingCandidateService.name, 'getReviewData');
    }
  }

  async getOtherCandidatesWidgetData(candidateId: string, positionId: string): Promise<Array<any>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingCandidateService.name, 'getOtherCandidatesWidgetData');
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers };
      const result = await this.http.get<Array<any>>(`${this.URL}/other-candidates-widget-data/${candidateId}/${positionId}`, httpOptions).toPromise();

      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, RecruitingCandidateService.name, 'getOtherCandidatesWidgetData');
    }
  }

  async getAttachmentsWidgetData(candidateId: string, positionCandidateId?: string): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingCandidateService.name, 'getAttachmentsWidgetData');
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers };
      const url = check.assigned(positionCandidateId) ? `${this.URL}/attachments/${candidateId}/${positionCandidateId}` : `${this.URL}/attachments/${candidateId}`;
      const result = await this.http.get<Array<any>>(url, httpOptions).toPromise();

      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, RecruitingCandidateService.name, 'getAttachmentsWidgetData');
    }
  }
}
