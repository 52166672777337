<div class="bc-shift-container kenjo-pv-20px kenjo-ph-30px">
  <div class="bc-shift">
    <div class="kenjo-mr-20px">
      <div class="kenjo-font-size-18px">{{ translations.shift }}</div>
      <div class="kenjo-mt-5px">{{ userAttendance.date | date: 'longDate' }}</div>
    </div>
    <div class="bc-shift-inputs">
      <orgos-input-simple-time
        [class.kenjo-mr-10px]="userAttendance.startTime || !showCleanShiftsInputs"
        [class.bc-clean-shift-input]="showCleanShiftsInputs"
        [(value)]="userAttendance.startTime"
        [label]="translations.start"
        (valueChange)="onShiftChange()"
        [forceError]="
          shiftStartConflict ||
          overlappingWithShift ||
          shiftOverlapping ||
          shiftStartAndEndEquals ||
          isRestrictCheckIn ||
          futureEntriesErrors.isFutureEntry ||
          futureEntriesErrors.isOpenShift
        "
        [enableClearButton]="false"
        [readOnly]="readOnly"
        (keydown)="onKeydown()"
      ></orgos-input-simple-time>
      <kenjo-icon
        [size]="18"
        [class.bc-clean-arrow]="showCleanShiftsInputs"
        [class.kenjo-mt-15px]="!showCleanShiftsInputs"
        class="kenjo-mr-10px kenjo-font-color-light-text-757575"
        >arrow_right_alt</kenjo-icon
      >
      <orgos-input-simple-time
        [class.bc-clean-shift-input]="showCleanShiftsInputs"
        [(value)]="userAttendance.endTime"
        [label]="translations.end"
        (valueChange)="onShiftChange()"
        [forceError]="
          shiftEndConflict ||
          overlappingWithShift ||
          shiftOverlapping ||
          shiftStartAndEndEquals ||
          futureEntriesErrors.isFutureEntry ||
          futureEntriesErrors.isOpenShift
        "
        [enableClearButton]="false"
        [readOnly]="readOnly"
        (keydown)="onKeydown()"
      ></orgos-input-simple-time>
    </div>
  </div>

  <kenjo-icon [size]="18" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="goBack()">clear</kenjo-icon>

  <div *ngIf="shiftStartAndEndEquals" class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px">
    {{ translations.endTimeMustBeAfterStartTime }}
  </div>
  <div *ngIf="isRestrictCheckIn" class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px">
    <div *ngIf="!this.nextShiftDate">
      {{ translations.restrictCheckInConflict | i18nData: { hourLimit: restrictTimeLimit } }}
    </div>
    <div *ngIf="this.nextShiftDate">
      {{ translations.restrictCheckInWithDateConflict | i18nData: { hourLimit: restrictTimeLimit, dateLimit: nextShiftDate } }}
    </div>
  </div>
  <div *ngIf="futureEntriesErrors.isFutureEntry" class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px">
    {{ translations.futureEntriesNotAllowed }}
  </div>
  <div *ngIf="futureEntriesErrors.isOpenShift" class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px">
    {{ translations.openShiftNotAllowed }}
  </div>
  <div *ngIf="maxHoursPerShiftReached && !shiftStartAndEndEquals" class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px">
    {{ translations.maxShiftLength | i18nData: { hoursAndMinutes: (limits.maxShiftLength | duration) } }}
  </div>
  <div
    *ngIf="maxHoursPerDayReached && !maxHoursPerShiftReached && !shiftStartAndEndEquals"
    class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
  >
    {{ translations.maxHoursPerDay | i18nData: { hoursAndMinutes: (limits.maxHoursPerDay | duration) } }}
  </div>
  <div
    *ngIf="breakTimeLonger && !maxHoursPerDayReached && !maxHoursPerShiftReached && !shiftStartAndEndEquals"
    class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
  >
    {{ translations.breakTimeLonger }}
  </div>
  <div
    *ngIf="
      (shiftOverlapping || overlappingWithShift) &&
      !maxHoursPerDayReached &&
      !maxHoursPerShiftReached &&
      !breakTimeLonger &&
      !shiftStartAndEndEquals
    "
    class="kenjo-mt-5px kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
  >
    {{ translations.overlappingShift }}
  </div>
</div>

<div class="kenjo-pb-30px kenjo-ph-30px kenjo-pt-20px">
  <div class="bc-breaks-title kenjo-mb-10px">
    <kenjo-icon [size]="20">breaks</kenjo-icon>
    <div class="kenjo-font-size-20px kenjo-ml-10px">{{ translations.breaks }}</div>
  </div>

  <ng-container *ngIf="!isOldBreak">
    <div #breaksContainer class="bc-breaks">
      <div *ngFor="let iBreak of userAttendance.breaks; let index = index" class="bc-break kenjo-ph-15px kenjo-pt-10px">
        <div>
          <orgos-input-simple-time
            class="kenjo-mr-20px"
            [(value)]="iBreak.start"
            [label]="translations.from"
            [keepOriginalMinWidth]="true"
            (valueChange)="onBreakChange('start')"
            [forceError]="
              iBreak.conflicts.overlappingStart ||
              iBreak.conflicts.outOfShiftStart ||
              iBreak.conflicts.sameStartAndEnd ||
              iBreak.conflicts?.isOpenBreak ||
              iBreak.conflicts?.isFutureBreak
            "
            [maskPlaceholder]="iBreak.start > MINUTES_IN_DAY ? parseTimeEntry(iBreak.start, 'start', index) : null"
            [enableClearButton]="true"
            [readOnly]="readOnly"
          >
            <orgos-input-error *ngIf="iBreak.conflicts.overlappingStart">{{ translations.overlappingEntry }}</orgos-input-error>
            <orgos-input-error *ngIf="!iBreak.conflicts.overlappingStart && iBreak.conflicts.outOfShiftStart">{{
              translations.outOfShiftEntry
            }}</orgos-input-error>
          </orgos-input-simple-time>
          <kenjo-icon [size]="18" class="kenjo-mr-20px kenjo-font-color-light-text-757575">arrow_right_alt</kenjo-icon>
          <orgos-input-simple-time
            [(value)]="iBreak.end"
            [label]="translations.to"
            [keepOriginalMinWidth]="true"
            (valueChange)="onBreakChange('end')"
            [forceError]="
              iBreak.conflicts.overlappingEnd ||
              iBreak.conflicts.outOfShiftEnd ||
              iBreak.conflicts.sameStartAndEnd ||
              iBreak.conflicts?.isOpenBreak ||
              iBreak.conflicts?.isFutureBreak
            "
            [enableClearButton]="true"
            [readOnly]="readOnly"
          >
            <orgos-input-error *ngIf="!iBreak.conflicts.sameStartAndEnd && iBreak.conflicts.overlappingEnd">{{
              translations.overlappingEntry
            }}</orgos-input-error>
            <orgos-input-error
              *ngIf="!iBreak.conflicts.sameStartAndEnd && !iBreak.conflicts.overlappingEnd && iBreak.conflicts.outOfShiftEnd"
              >{{ translations.outOfShiftEntry }}</orgos-input-error
            >
          </orgos-input-simple-time>
        </div>
        <kenjo-icon
          *ngIf="!readOnly"
          [size]="24"
          class="bc-delete-break kenjo-font-color-danger-ff5757 kenjo-cursor-pointer kenjo-mb-20px"
          (click)="deleteBreak(index)"
          >delete</kenjo-icon
        >
        <div class="bc-break">
          <div *ngIf="iBreak?.autoDeducted" class="bc-break-duration kenjo-cursor-pointer" (click)="openAutoDeductDialog(iBreak)">
            <div>{{ translations.autoDeductBreak }}</div>
            <kenjo-icon [size]="15" class="bc-break-duration-icon kenjo-border-radius">help</kenjo-icon>
          </div>
          <div *ngIf="iBreak.duration" class="bc-break-duration kenjo-ml-10px">{{ iBreak.duration | duration: false:true:true }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="showCantAddMessage" class="kenjo-font-color-danger-ff5757 kenjo-mt-10px kenjo-font-size-12px">
      {{ translations.completeBreaks }}
    </div>
    <div *ngIf="futureEntriesErrors.isOpenBreak" class="kenjo-font-color-danger-ff5757 kenjo-mt-10px kenjo-font-size-12px">
      {{ translations.openBreaksNotAllowed }}
    </div>
    <div *ngIf="futureEntriesErrors.isFutureBreak" class="kenjo-font-color-danger-ff5757 kenjo-mt-10px kenjo-font-size-12px">
      {{ translations.futureEntriesNotAllowed }}
    </div>
    <div *ngIf="overlappingBreakTimeOff" class="kenjo-font-color-danger-ff5757 kenjo-mt-10px kenjo-font-size-12px">
      {{ translations.overlappingShift }}
    </div>
    <div
      *ngIf="!readOnly"
      class="bc-add-break kenjo-ph-15px kenjo-pv-10px"
      (click)="addBreak()"
      [class.kenjo-mt-10px]="userAttendance.breaks?.length > 0"
    >
      <orgos-action [uppercase]="false" [forceBlockContainer]="false" icon="add_circle">{{ translations.addBreak }}</orgos-action>
    </div>

    <div class="bc-break-time kenjo-font-size-16px kenjo-mt-30px">
      <div>{{ translations.totalBreakTime }}</div>
      <div>{{ totalBreakTime | duration: false:true:true }}</div>
    </div>

    <div class="bc-action kenjo-mt-10px">
      <orgos-button-raised color="Neutral" (click)="goBack()">{{ translations.goBack }}</orgos-button-raised>
      <orgos-button-raised
        class="kenjo-ml-20px"
        color="Success"
        [disabled]="
          !canBeSaved ||
          overlappingConflict ||
          overlappingWithShift ||
          shiftStartConflict ||
          shiftEndConflict ||
          !shiftOrBreakChanged ||
          maxHoursPerShiftReached ||
          maxHoursPerDayReached ||
          futureEntriesErrors.isOpenBreak ||
          futureEntriesErrors.isFutureEntry ||
          futureEntriesErrors.isOpenShift ||
          futureEntriesErrors.isFutureBreak
        "
        (click)="save()"
        >{{ translations.save }}</orgos-button-raised
      >
    </div>
  </ng-container>

  <ng-container *ngIf="isOldBreak">
    <div class="bc-current-shift">
      <div class="kenjo-mr-20px">{{ userAttendance.date | date: 'longDate' }}</div>
    </div>
    <div class="bc-old-break kenjo-mt-20px kenjo-p-15px">
      <orgos-input-simple-time
        class="kenjo-mr-10px"
        [(value)]="userAttendance.breakTime"
        [label]="translations.breakDuration"
        [customMinWidth]="100"
        [enableClearButton]="true"
        [readOnly]="readOnly"
      ></orgos-input-simple-time>
      <div class="bc-update-container">
        <div class="bc-help">
          <kenjo-icon [size]="15" class="kenjo-font-color-light-text-757575 kenjo-mr-5px">help_outline</kenjo-icon>
          <div class="kenjo-font-color-light-text-757575">{{ translations.olderBreaksUsed }}</div>
        </div>
        <div class="kenjo-mt-10px">
          <orgos-button-raised color="Neutral" (click)="updateToNewBreaks()" [disabled]="readOnly">{{
            translations.update
          }}</orgos-button-raised>
        </div>
      </div>
    </div>
    <div class="bc-action kenjo-mt-20px">
      <orgos-button-raised color="Neutral" (click)="goBack()">{{ translations.goBack }}</orgos-button-raised>
      <orgos-button-raised class="kenjo-ml-20px" color="Success" (click)="saveOldBreak()" [disabled]="readOnly">{{
        translations.save
      }}</orgos-button-raised>
    </div>
  </ng-container>
</div>
