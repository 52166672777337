<orgos-container aspect="card">
  <orgos-column-container class="main-container">
    <!-- MENU -->
    <orgos-column size="0" class="seip-options-container kenjo-p-30px">
      <!-- EMPLOYEE FIELDS -->
      <div class="kenjo-font-size-16px kenjo-text-transform-uppercase">{{pageTranslation.employeeFields}}</div>
      <div *ngFor="let section of peopleDetailSections" class="kenjo-mt-15px seip-option"
        [class.kenjo-font-color-light-text-757575]="sectionSelected !== section"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === section" (click)="sectionSelected = section ">
        {{section}}</div>
      <div class="kenjo-mt-30px kenjo-font-size-16px kenjo-text-transform-uppercase">
        {{pageTranslation.employmentInformation}}</div>
      <div *ngFor="let section of employmentInformationSections" class="kenjo-mt-15px seip-option"
        [class.kenjo-font-color-light-text-757575]="sectionSelected !== section"
        [class.kenjo-font-color-primary-5993e3]="sectionSelected === section" (click)="sectionSelected = section ">
        {{section}}</div>
    </orgos-column>

    <!-- PRIMARY CONTENT -->
    <orgos-column size="1">
      <!-- USER PERSONAL SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.personalSectionTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employeeFields}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{peopleDetailPageTranslation.personalSectionTitle}}</span>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="addCustomFieldDialog('user-personal'); $event.preventDefault()" icon="add_circle">
              {{pageTranslation.addCustomField}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.noteMessageText}}</orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->

        <div *ngIf="userPersonal && sectionSelected === pageTranslation.personalSectionTitle">
          <!-- DISPLAY STANDARD FIELDS ENDS -->
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="firstName"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="lastName" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="middleName"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="displayName"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="birthdate"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="gender" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="nationality"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="linkedin" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="skype" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="slack" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="twitter" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userPersonal" field="facebook" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-input-text-area readOnly="true" [model]="userPersonal" field="bio" class="kenjo-mt-20px">
          </orgos-input-text-area>
          <!-- DISPLAY STANDARD FIELDS ENDS -->

          <!-- DISPLAY CUSTOM FIELDS STARTS -->
          <orgos-container
            *ngIf="mapCustomFields && mapCustomFields['user-personal'] && mapCustomFields['user-personal'].length > 0">
            <orgos-column-container class="kenjo-mt-20px"
              *ngFor="let iRow of mapCustomFields['user-personal']; let rowIndex = index">
              <orgos-input-custom-field
                *ngFor="let iCustomField of iRow; let colIndex = index; let odd = odd; let even = even;" orgosColumn
                readOnly="true" manageCustomField="true" [model]="userPersonal" [customFieldDocument]="iCustomField"
                (deleteCustomField)="customFieldDeleted($event)" (updateCustomField)="customFieldUpdated($event)"
                [class.kenjo-mr-10px]="even" [class.kenjo-ml-10px]="odd"></orgos-input-custom-field>
              <orgos-column
                *ngIf="mapCustomFields['user-personal'][rowIndex].length < 2 && mapCustomFields['user-personal'][rowIndex][0] && mapCustomFields['user-personal'][rowIndex][0].fieldType !== 'TextArea'"
                class="kenjo-ml-10px"></orgos-column>
            </orgos-column-container>
          </orgos-container>
          <!-- DISPLAY CUSTOM FIELDS ENDS -->
        </div>
      </div>
      <!-- USER PERSONAL SECTION ENDS -->

      <!-- USER WORK SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.workSectionTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employeeFields}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{peopleDetailPageTranslation.workSectionTitle}}</span>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="addCustomFieldDialog('user-work'); $event.preventDefault()" icon="add_circle">
              {{pageTranslation.addCustomField}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.noteMessageText}}</orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->

        <div *ngIf="userWork && sectionSelected === pageTranslation.workSectionTitle">
          <!-- DISPLAY STANDARD FIELDS STARTS -->
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="jobTitle" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userAccount" field="email" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="workPhone" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="workMobile" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="startDate" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="companyId" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="officeId" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="virtualOffice"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="areaIds" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="departmentId" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="teamIds" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="reportsToId" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="probationPeriodEnd"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="contractEnd" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="weeklyHours" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text readOnly="true" orgosColumn [model]="userWork" field="employeeNumber"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <!-- DISPLAY STANDARD FIELDS ENDS -->

          <!-- DISPLAY CUSTOM FIELDS STARTS -->
          <orgos-container
            *ngIf="mapCustomFields && mapCustomFields['user-work'] && mapCustomFields['user-work'].length > 0">
            <orgos-column-container class="kenjo-mt-20px"
              *ngFor="let iRow of mapCustomFields['user-work']; let rowIndex = index">
              <orgos-input-custom-field *ngFor="let iCustomField of iRow; let colIndex = index" orgosColumn
                readOnly="true" manageCustomField="true" [model]="userWork" [customFieldDocument]="iCustomField"
                (deleteCustomField)="customFieldDeleted($event)" (updateCustomField)="customFieldUpdated($event)">
              </orgos-input-custom-field>
              <orgos-column
                *ngIf="mapCustomFields['user-work'][rowIndex].length < 2 && mapCustomFields['user-work'][rowIndex][0] && mapCustomFields['user-work'][rowIndex][0].fieldType !== 'TextArea'"
                class="kenjo-ml-10px"></orgos-column>
            </orgos-column-container>
          </orgos-container>
          <!-- DISPLAY CUSTOM FIELDS ENDS -->
        </div>
      </div>
      <!-- USER WORK SECTION ENDS -->

      <!-- USER ADDRESS SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.addressSectionTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employeeFields}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{peopleDetailPageTranslation.addressSectionTitle}}</span>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="addCustomFieldDialog('user-address'); $event.preventDefault()" icon="add_circle">
              {{pageTranslation.addCustomField}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.noteMessageText}}</orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->

        <div *ngIf="userAddress && sectionSelected === pageTranslation.addressSectionTitle">
          <!-- DISPLAY STANDARD FIELDS STARTS -->
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userAddress" field="street" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userAddress" field="postalCode"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userAddress" field="city" class="kenjo-mr-10px">
            </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userAddress" field="country" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <!-- DISPLAY STANDARD FIELDS ENDS -->

          <!-- DISPLAY CUSTOM FIELDS STARTS -->
          <orgos-container
            *ngIf="mapCustomFields && mapCustomFields['user-address'] && mapCustomFields['user-address'].length > 0">
            <orgos-column-container class="kenjo-mt-20px"
              *ngFor="let iRow of mapCustomFields['user-address']; let rowIndex = index">
              <orgos-input-custom-field *ngFor="let iCustomField of iRow; let colIndex = index" orgosColumn
                readOnly="true" manageCustomField="true" [model]="userAddress" [customFieldDocument]="iCustomField"
                (deleteCustomField)="customFieldDeleted($event)" (updateCustomField)="customFieldUpdated($event)">
              </orgos-input-custom-field>
              <orgos-column
                *ngIf="mapCustomFields['user-address'][rowIndex].length < 2 && mapCustomFields['user-address'][rowIndex][0] && mapCustomFields['user-address'][rowIndex][0].fieldType !== 'TextArea'"
                class="kenjo-ml-10px"></orgos-column>
            </orgos-column-container>
          </orgos-container>
          <!-- DISPLAY CUSTOM FIELDS ENDS -->
        </div>
      </div>
      <!-- USER ADDRESS SECTION ENDS -->

      <!-- USER HOME SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.homeSectionTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employeeFields}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{peopleDetailPageTranslation.homeSectionTitle}}</span>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="addCustomFieldDialog('user-home'); $event.preventDefault()" icon="add_circle">
              {{pageTranslation.addCustomField}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.noteMessageText}}</orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->

        <div *ngIf="userHome && sectionSelected === pageTranslation.homeSectionTitle">
          <!-- DISPLAY STANDARD FIELDS STARTS -->
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userHome" field="personalEmail"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userHome" field="personalPhone"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userHome" field="personalMobile"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userHome" field="maritalStatus"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <!-- DISPLAY STANDARD FIELDS ENDS -->

          <!-- DISPLAY CUSTOM FIELDS STARTS -->
          <orgos-container
            *ngIf="mapCustomFields && mapCustomFields['user-home'] && mapCustomFields['user-home'].length > 0">
            <orgos-column-container class="kenjo-mt-20px"
              *ngFor="let iRow of mapCustomFields['user-home']; let rowIndex = index">
              <orgos-input-custom-field *ngFor="let iCustomField of iRow; let colIndex = index" orgosColumn
                readOnly="true" manageCustomField="true" [model]="userHome" [customFieldDocument]="iCustomField"
                (deleteCustomField)="customFieldDeleted($event)" (updateCustomField)="customFieldUpdated($event)">
              </orgos-input-custom-field>
              <orgos-column
                *ngIf="mapCustomFields['user-home'][rowIndex].length < 2 && mapCustomFields['user-home'][rowIndex][0] && mapCustomFields['user-home'][rowIndex][0].fieldType !== 'TextArea'"
                class="kenjo-ml-10px"></orgos-column>
            </orgos-column-container>
          </orgos-container>
          <!-- DISPLAY CUSTOM FIELDS ENDS -->
        </div>
      </div>
      <!-- USER HOME SECTION ENDS -->

      <!-- USER FINANCE SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.financeSectionTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employeeFields}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{peopleDetailPageTranslation.financeSectionTitle}}</span>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="addCustomFieldDialog('user-financial'); $event.preventDefault()" icon="add_circle">
              {{pageTranslation.addCustomField}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.noteMessageText}}</orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->

        <div *ngIf="userFinancial && sectionSelected === pageTranslation.financeSectionTitle">
          <!-- DISPLAY STANDARD FIELDS STARTS -->
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="bankName"
              class="kenjo-mr-10px"> </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="accountHolderName"
              class="kenjo-ml-10px"> </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="accountNumber"
              class="kenjo-mr-10px"> </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="iban" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="swiftCode"
              class="kenjo-mr-10px"> </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="nationalId"
              class="kenjo-ml-10px"> </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="passport"
              class="kenjo-mr-10px"> </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="nationalInsuranceNumber"
              class="kenjo-ml-10px"> </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="taxIdentificationNumber"
              class="kenjo-mr-10px"> </orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userFinancial" field="taxCode" class="kenjo-ml-10px">
            </orgos-input-text>
          </orgos-column-container>
          <!-- DISPLAY STANDARD FIELDS ENDS -->

          <!-- DISPLAY CUSTOM FIELDS STARTS -->
          <orgos-container
            *ngIf="mapCustomFields && mapCustomFields['user-financial'] && mapCustomFields['user-financial'].length > 0">
            <orgos-column-container class="kenjo-mt-20px"
              *ngFor="let iRow of mapCustomFields['user-financial']; let rowIndex = index">
              <orgos-input-custom-field *ngFor="let iCustomField of iRow; let colIndex = index" orgosColumn
                readOnly="true" manageCustomField="true" [model]="userFinancial" [customFieldDocument]="iCustomField"
                (deleteCustomField)="customFieldDeleted($event)" (updateCustomField)="customFieldUpdated($event)">
              </orgos-input-custom-field>
              <orgos-column
                *ngIf="mapCustomFields['user-financial'][rowIndex].length < 2 && mapCustomFields['user-financial'][rowIndex][0] && mapCustomFields['user-financial'][rowIndex][0].fieldType !== 'TextArea'"
                class="kenjo-ml-10px"></orgos-column>
            </orgos-column-container>
          </orgos-container>
          <!-- DISPLAY CUSTOM FIELDS ENDS -->
        </div>
      </div>
      <!-- USER FINANCE SECTION ENDS -->

      <!-- USER EMERGENCY SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.emergencySectionTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employeeFields}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{peopleDetailPageTranslation.emergencySectionTitle}}</span>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="addCustomFieldDialog('user-emergency'); $event.preventDefault()" icon="add_circle">
              {{pageTranslation.addCustomField}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.noteMessageText}}</orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->

        <div *ngIf="userEmergency && sectionSelected === pageTranslation.emergencySectionTitle">
          <!-- DISPLAY STANDARD FIELDS STARTS -->
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyFirstName"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyMiddleName"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyLastName"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="relation"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyPhoneNumber"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyMobileNumber"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyEmail"
              class="kenjo-mr-10px"></orgos-input-text>
            <div orgosColumn class="kenjo-ml-10px"></div>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyAddress"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyPostCode"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyCity"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userEmergency" field="emergencyCountry"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <!-- DISPLAY STANDARD FIELDS ENDS -->

          <!-- DISPLAY CUSTOM FIELDS STARTS -->
          <orgos-container
            *ngIf="mapCustomFields && mapCustomFields['user-emergency'] && mapCustomFields['user-emergency'].length > 0">
            <orgos-column-container class="kenjo-mt-20px"
              *ngFor="let iRow of mapCustomFields['user-emergency']; let rowIndex = index">
              <orgos-input-custom-field *ngFor="let iCustomField of iRow; let colIndex = index" orgosColumn
                readOnly="true" manageCustomField="true" [model]="userEmergency" [customFieldDocument]="iCustomField"
                (deleteCustomField)="customFieldDeleted($event)" (updateCustomField)="customFieldUpdated($event)">
              </orgos-input-custom-field>
              <orgos-column
                *ngIf="mapCustomFields['user-emergency'][rowIndex].length < 2 && mapCustomFields['user-emergency'][rowIndex][0] && mapCustomFields['user-emergency'][rowIndex][0].fieldType !== 'TextArea'"
                class="kenjo-ml-10px"></orgos-column>
            </orgos-column-container>
          </orgos-container>
          <!-- DISPLAY CUSTOM FIELDS ENDS -->
        </div>
      </div>
      <!-- USER EMERGENCY SECTION ENDS -->

      <!-- USER CONFIDENTIAL SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.confidentialSectionTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employeeFields}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{peopleDetailPageTranslation.confidentialSectionTitle}}</span>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action (click)="addCustomFieldDialog('user-confidential'); $event.preventDefault()"
              icon="add_circle"> {{pageTranslation.addCustomField}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.noteMessageText}}</orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->

        <div *ngIf="userConfidential  && sectionSelected === pageTranslation.confidentialSectionTitle">
          <!-- DISPLAY STANDARD FIELDS STARTS -->

          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn readOnly="true" [model]="userConfidential" field="terminationDate"
              class="kenjo-mr-10px"></orgos-input-text>
            <orgos-input-text orgosColumn readOnly="true" [model]="userConfidential" field="terminationReason"
              class="kenjo-ml-10px"></orgos-input-text>
          </orgos-column-container>
          <!-- DISPLAY STANDARD FIELDS ENDS -->

          <!-- DISPLAY CUSTOM FIELDS STARTS -->
          <orgos-container
            *ngIf="mapCustomFields && mapCustomFields['user-confidential'] && mapCustomFields['user-confidential'].length > 0">
            <orgos-column-container class="kenjo-mt-20px"
              *ngFor="let iRow of mapCustomFields['user-confidential']; let rowIndex = index">
              <orgos-input-custom-field *ngFor="let iCustomField of iRow; let colIndex = index" orgosColumn
                readOnly="true" manageCustomField="true" [model]="userConfidential" [customFieldDocument]="iCustomField"
                (deleteCustomField)="customFieldDeleted($event)" (updateCustomField)="customFieldUpdated($event)">
              </orgos-input-custom-field>
              <orgos-column
                *ngIf="mapCustomFields['user-confidential'][rowIndex].length < 2 && mapCustomFields['user-confidential'][rowIndex][0] && mapCustomFields['user-confidential'][rowIndex][0].fieldType !== 'TextArea'"
                class="kenjo-ml-10px"></orgos-column>
            </orgos-column-container>
          </orgos-container>
          <!-- DISPLAY CUSTOM FIELDS ENDS -->
        </div>
      </div>
      <!-- USER CONFIDENTIAL SECTION ENDS -->

      <!-- USER CONTRACT TYPES SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.contractTypesEmploymentInformationTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employmentInformation}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{pageTranslation.contractTypesEmploymentInformationTitle}}</span>
          </orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->
        <div *ngIf="sectionSelected === pageTranslation.contractTypesEmploymentInformationTitle">
          <!-- DISPLAY DEFAULT CONTRACT TYPES -->
          <div class="kenjo-font-size-16px kenjo-mb-20px kenjo-mt-30px">{{pageTranslation.defaultContractTypes}}</div>
          <orgos-column-container *ngFor="let contractType of defaultUserEmploymentContractTypes | async"
            class="kenjo-mt-10px kenjo-border kenjo-border-radius" centerColumns="true">
            <orgos-column size="0" class="kenjo-border-right">
              <mat-slide-toggle [matTooltip]="pageTranslation.cannotDeleteLastContractTypeWarning"
                [matTooltipPosition]="'above'"
                [matTooltipDisabled]="!(contractType.isActive && (remainingActiveContractTypes | async) === 1)"
                class="seip-slide-toggle kenjo-pv-10px kenjo-ph-20px" [checked]="contractType.isActive"
                (change)="toggleIsActive(contractType, $event.checked)"
                [disabled]="contractType.isActive && (remainingActiveContractTypes | async) === 1"></mat-slide-toggle>
            </orgos-column>
            <orgos-column size="1" class="seip-title kenjo-ml-20px">
              <kenjo-simple-truncate-text class="kenjo-ml-10px kenjo-mr-30px"> {{ contractType.name }}
              </kenjo-simple-truncate-text>
            </orgos-column>
          </orgos-column-container>
          <!-- DISPLAY CUSTOM CONTRACT TYPES -->
          <orgos-column-container centerColumns="true">
            <orgos-column class="kenjo-font-size-16px kenjo-mb-20px kenjo-mt-40px">
              {{pageTranslation.customContractTypes}}</orgos-column>
            <orgos-column size="0" class="kenjo-mb-20px kenjo-mt-40px">
              <orgos-action (click)="addCustomContractTypeDialog(); $event.preventDefault()" icon="add_circle">
                {{pageTranslation.addContractType}}</orgos-action>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container *ngFor="let contractType of customUserEmploymentContractTypes | async"
            class="kenjo-mt-10px kenjo-border kenjo-border-radius" centerColumns="true">
            <orgos-column size="0" class="kenjo-border-right">
              <mat-slide-toggle [matTooltip]="pageTranslation.cannotDeleteLastContractTypeWarning"
                [matTooltipPosition]="'above'"
                [matTooltipDisabled]="!(contractType.isActive && (remainingActiveContractTypes | async) === 1)"
                class="seip-slide-toggle kenjo-pv-10px kenjo-ph-20px" [checked]="contractType.isActive"
                (change)="toggleIsActive(contractType, $event.checked)"
                [disabled]="contractType.isActive && (remainingActiveContractTypes | async) === 1"></mat-slide-toggle>
            </orgos-column>
            <orgos-column size="1" class="seip-title kenjo-ml-20px">
              <kenjo-simple-truncate-text class="kenjo-ml-10px kenjo-mr-30px"> {{ contractType.name }}
              </kenjo-simple-truncate-text>
            </orgos-column>
            <orgos-column size="0" class="seip-clickable-icon-container kenjo-border-left"
              (click)="editCustomContractTypeDialog(contractType)">
              <kenjo-icon size="20" class="kenjo-font-color-primary-5993e3 kenjo-pv-10px kenjo-ph-15px"
                [matTooltip]="pageTranslation.editContractType | i18nData: {name: contractType.name}"
                matTooltipPosition="above">mode_edit</kenjo-icon>
            </orgos-column>
            <orgos-column size="0" class="seip-clickable-icon-container kenjo-border-left">
              <kenjo-icon size="20" class="kenjo-pv-10px kenjo-ph-15px" [class]="contractType.usageCount > 0 ?
                  'kenjo-font-color-light-text-757575' : (
                    contractType.isActive && (remainingActiveContractTypes | async) === 1 ?
                      'kenjo-font-color-light-text-757575' :
                  'kenjo-cursor-pointer kenjo-font-color-danger-ff5757'
                )" [matTooltip]="contractType.usageCount > 0 ? (
                  pageTranslation.warningMessage | i18nData: {usage: contractType.usageCount}) : (
                    contractType.isActive && (remainingActiveContractTypes | async) === 1 ?
                      pageTranslation.cannotDeleteLastContractTypeWarning :
                  pageTranslation.delete
                )" (click)="deleteCustomContractType(contractType)" matTooltipPosition="above">delete</kenjo-icon>
            </orgos-column>
          </orgos-column-container>
        </div>
      </div>
      <div class="kenjo-p-30px"
        *ngIf="sectionSelected === pageTranslation.terminationReasonsEmploymentInformationTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employmentInformation}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{pageTranslation.terminationReasonsEmploymentInformationTitle}}</span>
          </orgos-column>
        </orgos-column-container>
        <!-- SHOW DIFFERENT TERMINATION TYPES terminationReasonList -->
        <orgos-column-container>
          <orgos-column>
            <div class="kenjo-font-size-16px kenjo-mb-20px kenjo-mt-30px">{{pageTranslation.defaultTerminationReasons}}
            </div>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action *ngIf="!showDefaults" class="kenjo-mb-20px kenjo-mt-30px" (click)="toggleShowDefaults()">
              {{pageTranslation.showDefaultTerminationReasons}} </orgos-action>
            <orgos-action *ngIf="showDefaults" class="kenjo-mb-20px kenjo-mt-30px" (click)="toggleShowDefaults()">
              {{pageTranslation.hideDefaultTerminationReasons}} </orgos-action>
          </orgos-column>
        </orgos-column-container>
        <div *ngIf="showDefaults">
          <orgos-column-container *ngFor="let terminationReason of defaultUserEmploymentTerminationReasonTypes | async"
            class="kenjo-mt-10px kenjo-border kenjo-border-radius" centerColumns="true">
            <orgos-column size="0" class="kenjo-border-right">
              <mat-slide-toggle [matTooltip]="pageTranslation.cannotDeleteLastTerminationReasonWarning"
                [matTooltipPosition]="'above'"
                [matTooltipDisabled]="!(terminationReason.isActive && (remainingActiveTerminationReasons | async) === 1)"
                class="seip-slide-toggle kenjo-pv-10px kenjo-ph-20px" [checked]="terminationReason.isActive"
                (change)="toggleTerminationReasonIsActive(terminationReason, $event.checked)"
                [disabled]="terminationReason.isActive && (remainingActiveTerminationReasons | async) === 1">
              </mat-slide-toggle>
            </orgos-column>
            <orgos-column size="1" class="seip-title kenjo-ml-20px">
              <kenjo-simple-truncate-text class="kenjo-ml-10px kenjo-mr-30px">{{ terminationReason.name }}
              </kenjo-simple-truncate-text>
            </orgos-column>
          </orgos-column-container>
        </div>
        <orgos-column-container centerColumns="true">
          <orgos-column class="kenjo-font-size-16px kenjo-mb-20px kenjo-mt-40px">
            {{pageTranslation.customTerminationReasons}}</orgos-column>
          <orgos-column size="0" class="kenjo-mb-20px kenjo-mt-40px">
            <orgos-action (click)="addCustomTerminationTypeDialog(); $event.preventDefault()" icon="add_circle">
              {{pageTranslation.addTerminationReason}}</orgos-action>
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container *ngFor="let terminationReason of customUserEmploymentTerminationReasonTypes | async"
          class="kenjo-mt-10px kenjo-border kenjo-border-radius" centerColumns="true">
          <orgos-column size="0" class="kenjo-border-right">
            <mat-slide-toggle [matTooltip]="pageTranslation.cannotDeleteLastTerminationReasonWarning"
              [matTooltipPosition]="'above'"
              [matTooltipDisabled]="!(terminationReason.isActive && (remainingActiveTerminationReasons | async) === 1)"
              class="seip-slide-toggle kenjo-pv-10px kenjo-ph-20px" [checked]="terminationReason.isActive"
              (change)="toggleTerminationReasonIsActive(terminationReason, $event.checked)"
              [disabled]="terminationReason.isActive && (remainingActiveTerminationReasons | async) === 1">
            </mat-slide-toggle>
          </orgos-column>
          <orgos-column size="1" class="seip-title kenjo-ml-20px">
            <kenjo-simple-truncate-text class="kenjo-ml-10px kenjo-mr-30px"> {{ terminationReason.name }}
            </kenjo-simple-truncate-text>
          </orgos-column>
          <orgos-column size="0" class="seip-clickable-icon-container kenjo-border-left"
            (click)="editCustomTerminationTypeDialog(terminationReason)">
            <kenjo-icon size="20" class="kenjo-font-color-primary-5993e3 kenjo-pv-10px kenjo-ph-15px"
              [matTooltip]="pageTranslation.editTerminationType | i18nData: {name: terminationReason.name}"
              matTooltipPosition="above">mode_edit</kenjo-icon>
          </orgos-column>
          <orgos-column size="0" class="seip-clickable-icon-container kenjo-border-left">
            <kenjo-icon size="20" class="kenjo-pv-10px kenjo-ph-15px" [class]="terminationReason.usageCount > 0 ?
                'kenjo-font-color-light-text-757575' : (
                  terminationReason.isActive && (remainingActiveTerminationReasons | async) === 1 ?
                    'kenjo-font-color-light-text-757575' :
                'kenjo-cursor-pointer kenjo-font-color-danger-ff5757'
              )" [matTooltip]="terminationReason.usageCount > 0 ? (
                pageTranslation.warningMessageTerminationType | i18nData: {usage: terminationReason.usageCount}) : (
                  terminationReason.isActive && (remainingActiveTerminationReasons | async) === 1 ?
                    pageTranslation.cannotDeleteLastTerminationReasonWarning :
                pageTranslation.deleteTerminationReason
              )" (click)="deleteCustomTerminationReason(terminationReason)" matTooltipPosition="above">delete
            </kenjo-icon>
          </orgos-column>
        </orgos-column-container>
      </div>
      <!-- USER CONTRACT TYPES SECTION ENDS -->
      <!-- USER SUBCATEGORIES SECTION STARTS -->
      <!-- TOP BLOCK STARTS -->
      <div class="kenjo-p-30px" *ngIf="sectionSelected === pageTranslation.subcategoriesEmploymentInformationTitle">
        <orgos-column-container centerColumns="true" class="kenjo-pb-30px">
          <orgos-column class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
            {{pageTranslation.employmentInformation}}&nbsp;/&nbsp;<span
              class="kenjo-font-color-text-333333">{{pageTranslation.subcategoriesEmploymentInformationTitle}}</span>
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline
          </kenjo-icon>
          <orgos-column class="kenjo-font-color-light-text-757575">{{pageTranslation.subcategoryDescriptionText}}
          </orgos-column>
        </orgos-column-container>
        <!-- TOP BLOCK ENDS -->
        <div *ngIf="sectionSelected === pageTranslation.subcategoriesEmploymentInformationTitle">
          <!-- DISPLAY CUSTOM SUBCATEGORIES -->
          <orgos-column-container centerColumns="true">
            <orgos-column class="kenjo-font-size-16px kenjo-mb-20px kenjo-mt-40px">
              {{pageTranslation.customSubcategories}}</orgos-column>
            <orgos-column size="0" class="kenjo-mb-20px kenjo-mt-40px">
              <orgos-action (click)="addCustomSubcategoryDialog(); $event.preventDefault()" icon="add_circle">
                {{pageTranslation.addSubcategory}}</orgos-action>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container *ngFor="let subcategory of customUserEmploymentSubcategories | async"
            class="kenjo-mt-10px kenjo-border kenjo-border-radius" centerColumns="true">
            <orgos-column size="0" class="kenjo-border-right">
              <mat-slide-toggle class="seip-slide-toggle kenjo-pv-10px kenjo-ph-20px" [checked]="subcategory.isActive"
                (change)="toggleSubcategory(subcategory, $event.checked)"></mat-slide-toggle>
            </orgos-column>
            <orgos-column size="1" class="seip-title kenjo-ml-20px">
              <kenjo-simple-truncate-text class="kenjo-ml-10px kenjo-mr-30px"> {{ subcategory.name }}
              </kenjo-simple-truncate-text>
            </orgos-column>
            <orgos-column size="0" class="seip-clickable-icon-container kenjo-border-left"
              (click)="editCustomSubcategoryDialog(subcategory)">
              <kenjo-icon size="20" class="kenjo-font-color-primary-5993e3 kenjo-pv-10px kenjo-ph-15px"
                [matTooltip]="pageTranslation.editSubcategory | i18nData: {name: subcategory.name}"
                matTooltipPosition="above">mode_edit</kenjo-icon>
            </orgos-column>
            <orgos-column size="0" class="seip-clickable-icon-container kenjo-border-left">
              <kenjo-icon size="20" class="kenjo-pv-10px kenjo-ph-15px"
                [class]="subcategory.usageCount > 0 ? 'kenjo-font-color-light-text-757575' : 'kenjo-cursor-pointer kenjo-font-color-danger-ff5757'"
                [matTooltip]="subcategory.usageCount > 0 ? (pageTranslation.subCategoryWarningMessage | i18nData: {usage: subcategory.usageCount}) : pageTranslation.deleteSubcategory"
                (click)="deleteCustomSubcategory(subcategory)" matTooltipPosition="above">delete</kenjo-icon>
            </orgos-column>
          </orgos-column-container>
        </div>
      </div>
      <!-- USER SUBCATEGORIES SECTION ENDS -->
    </orgos-column>
  </orgos-column-container>
</orgos-container>