import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class SignInWithDatevGuard implements CanActivate {
  constructor(private injector: Injector) {}

  async canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Promise<boolean> {
    try {
      const params = this.getParamsFromUrl(routerState.url);
      new BroadcastChannel('datev-sign-in').postMessage(params);
      window.close();
      return false;
    } catch {
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }
  }

  getParamsFromUrl(url: string) {
    const fragment = decodeURIComponent(url).split('#')[1];
    if (check.assigned(fragment)) {
      const parts = fragment.split('&');
      const params = parts.reduce((total, iPart) => {
        const pieces = iPart.split('=');
        total[pieces[0]] = pieces[1];
        return total;
      }, {});

      return params;
    }

    return {};
  }
}
