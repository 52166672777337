import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TimeOffStatusService } from '@app/cloud-features/time-off/services/time-off-status.service';
import { ITimeOffUserPolicy, TimeOffUserPolicyController } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserOvertimeHistoryService } from '@app/standard/services/user/user-overtime-history.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-overtime-compensation-time-off',
  templateUrl: 'overtime-compensation-time-off.dialog.html',
  styleUrls: ['overtime-compensation-time-off.dialog.scss'],
})
export class OvertimeCompensationTimeOffDialog implements OnInit {
  maxValues: { hours?: number; minutes?: number } = {
    hours: 0,
    minutes: 0,
  };

  newBalance = {
    hours: 0,
    minutes: 0,
  };

  compensation: number;
  comments: string;

  COMPENSATION_TYPE: string = picklistConstants.OVERTIME_HISTORY_ENTRY_TYPE_TIME_OFF;
  saving: boolean = false;

  timeOffOptions: Array<ISelectOption> = [];
  commonPolicies: Array<ITimeOffUserPolicy> = [];
  policyId: string;

  policyValidation: InputValidation;
  daysValidation: InputValidation;

  translation: any = {};

  year: number;
  month: number;
  userIds: Array<string> = [];
  userPersonal: any = {};
  balances: { [userId: string]: number };
  nextBalance: number = 0;
  usersSelectedVisible: boolean = false;

  isBulk: boolean = false;
  currentBalance: number = 0;
  balanceAfterText: string;
  maxBalanceToCompensate: number = 0;

  hourlyTimeOffs: { [key: string]: boolean } = {};
  /**
   * @param dialogRef reference to the dialog
   * @param data object like:
   *  overtime: {[userId: string]: number}
   * It is a map (userId, overtime)
   * @param injector injector of angular
   */
  constructor(
    public dialogRef: MatLegacyDialogRef<OvertimeCompensationTimeOffDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.userIds = Object.keys(this.data.userIds);
    this.balances = this.data.userIds;
    this.year = this.data.year;
    this.month = this.data.month;
    this.initCommonPolicies();

    this.isBulk = this.userIds.length > 1;
    this.currentBalance = this.balances[this.userIds[0]];
    this.maxBalanceToCompensate = this.getMinValue(this.balances);

    const maxValue = this.isBulk ? this.maxBalanceToCompensate : this.currentBalance;
    this.setNewMaxValuesFromMinutes(maxValue);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('overtime-compensation-time-off-dialog')
      .then((translation: any) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(UserPersonalService)
      .find({ _id: { $in: this.userIds } })
      .then((userPersonals) => {
        this.userPersonal = userPersonals.reduce((total, iPersonal) => {
          total[iPersonal._id] = iPersonal;
          return total;
        }, {});
      })
      .catch(() => {
        this.userPersonal = [];
      });
  }

  private async initCommonPolicies() {
    if (check.nonEmptyArray(this.data.commonPolicies)) {
      this.commonPolicies = this.data.commonPolicies;
    } else {
      try {
        this.commonPolicies = await this.injector.get(TimeOffUserPolicyController).getCommonPolicies(this.userIds, false);
      } catch {
        this.commonPolicies = [];
      }
    }
    this.commonPolicies.forEach((policy) => {
      this.timeOffOptions.push({ name: policy.name, value: policy.id });
      this.hourlyTimeOffs[policy.id] = policy.type === picklistConstants.POLICY_TYPE_HOUR;
    });
  }

  public changeNewBalance(): void {
    this.nextBalance = this.currentBalance - this.getNewBalanceMinutes();
    const duration = this.injector.get(DurationPipe).transform(this.nextBalance);
    this.balanceAfterText = this.injector.get(I18nDataPipe).transform(this.translation.balanceAfterConversion, { hours: duration });
  }

  public preventWrongMinutesValue(newMinutesValue?: number | null): void {
    if (newMinutesValue === null) {
      this.newBalance.minutes = newMinutesValue;
      return;
    }

    const minutesToCompare = check.assigned(newMinutesValue) ? newMinutesValue : this.newBalance.minutes;

    if (this.newBalance.hours === (this.maxValues?.hours ?? 0) && minutesToCompare > this.maxValues.minutes) {
      this.newBalance.minutes = [45, 30, 15, 0].find((value) => value <= this.maxValues.minutes);
    } else if (
      this.newBalance.hours === (this.maxValues?.hours ?? 0) &&
      check.assigned(newMinutesValue) &&
      newMinutesValue <= this.maxValues.minutes
    ) {
      this.newBalance.minutes = newMinutesValue;
    } else if (this.newBalance.hours < (this.maxValues?.hours ?? 0) && check.assigned(newMinutesValue)) {
      this.newBalance.minutes = newMinutesValue;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public async save(): Promise<void> {
    this.saving = true;
    try {
      const adjustCompensationParams = this.userIds.map((userId) => {
        const policy = this.commonPolicies.find((policy) => policy.id === this.policyId);
        return {
          userId,
          timeOffTypeId: policy.timeOffTypeId,
          compensation: policy.type === picklistConstants.POLICY_TYPE_HOUR ? this.compensation * 60 : this.compensation,
          description: this.comments,
        };
      });
      const userStatusList = await this.injector.get(TimeOffStatusService).giveCompensation(adjustCompensationParams);
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('time added from overtime', { category: 'Time off', platform: 'Web', type: 'timeoff manage' });
      if (check.nonEmptyArray(userStatusList)) {
        const callsToSaveCompensations = userStatusList.map((userStatus: any) => {
          const historyEntry = {
            type: this.COMPENSATION_TYPE,
            minutes: this.getNewBalanceMinutes(),
            minutesBefore: this.balances[userStatus._userId],
            relatedToId: userStatus._statusHistoryId,
            comments: this.comments,
            userId: userStatus._userId,
            month: this.month,
            year: this.year,
          };
          return this.injector.get(UserOvertimeHistoryService).create(historyEntry);
        });
        await Promise.all(callsToSaveCompensations);
      }
      this.saving = false;
      this.dialogRef.close(true);
    } catch {
      this.saving = false;
    }
  }

  private getMinValue(balances: { [userId: string]: number }) {
    const max = Object.values(balances).reduce((tempMax, balance) => {
      if (balance < tempMax) {
        return balance;
      }
      return tempMax;
    }, 999999);

    return parseFloat(max.toFixed(2));
  }

  public getNewBalanceMinutes(): number {
    const hoursMinutes = check.assigned(this.newBalance.hours) ? this.newBalance.hours * 60 : 0;
    const minutes = check.assigned(this.newBalance.minutes) ? this.newBalance.minutes : 0;

    return Number(hoursMinutes) + Number(minutes);
  }

  public setNewMaxValuesFromMinutes(amount: number): void {
    const hours = amount / 60;
    const hoursRounded = Math.floor(hours);
    const minutes = Math.round((hours - hoursRounded) * 60);

    this.maxValues = {
      hours: hoursRounded,
      minutes,
    };
  }

  public parseBalanceHours() {
    const hoursValue = Math.round(Math.abs(this.newBalance.hours ?? 0));

    this.newBalance.hours = hoursValue > this.maxValues?.hours ? this.maxValues?.hours : hoursValue;
  }

  public parseBalanceMinutes() {
    const minutesValue = Math.round(Math.abs(this.newBalance.minutes ?? 0));
    const maxValuesMinutes = this.newBalance.hours === this.maxValues?.hours ? this.maxValues?.minutes : 59;

    this.newBalance.minutes = minutesValue > maxValuesMinutes ? maxValuesMinutes : minutesValue;
  }
}
