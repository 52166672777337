import { Component, Input } from '@angular/core';

@Component({
  selector: 'kenjo-filter-bar-tag',
  templateUrl: './filter-bar-tag.component.html',
  styleUrls: ['./filter-bar-tag.component.scss']
})
export class FilterBarTagComponent {
  private _activeValues = new Array<IFilterOption>();
  get activeValues() {
    return this._activeValues;
  }
  @Input() set activeValues(actives: Array<IFilterOption> | undefined) {
    if (actives === undefined) {
      return;
    }
    this._activeValues = actives;
    this.text = this.computeText(this.label, actives);
  }

  @Input() label = '';
  @Input() optionKey: string;

  text: string;

  private computeText(label, activeValues) {
    const activeValuesNames = activeValues.map((val) => val.name);
    const activeValuesString = activeValuesNames.join(', ');
    return `${label}: ${activeValuesString}`;
  }
}

export interface IFilterOption {
  name: string;
  _id: string;
  photoUrl?: string;
  active?: boolean;
}