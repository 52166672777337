import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IDocumentCommentModel } from '@app/standard/services/document/document.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as check from 'check-types';

import { InputSimpleEditorComponent } from '../input-simple-editor/input-simple-editor.component';

@Component({
  selector: 'orgos-conversation',
  templateUrl: 'conversation.component.html',
  styleUrls: ['conversation.component.scss']
})
export class ConversationComponent implements OnInit {
  constructor(private injector: Injector) {}

  i18n: any = {};
  creatingComment: boolean = false;
  userPersonalById: any = {};
  commentValidation: InputValidation;
  _commentList: Array<any> = [];
  userComments: Array<any> = [];
  comment: IDocumentCommentModel = {
    comment: ''
  };

  @ViewChild('bodyQuillEditor', { static: true }) bodyQuillEditor: InputSimpleEditorComponent;
  @Output() commentCreated: EventEmitter<IDocumentCommentModel> = new EventEmitter<IDocumentCommentModel>();
  @Output() commentUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set commentList(commentList: Array<any>) {
    this._commentList = commentList;
    this.loadUserComments();
  }
  get commentList(): Array<any> {
    return this._commentList;
  }

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('conversation-component')
      .then((i18n) => {
        this.i18n = i18n;
      })
      .catch(() => {
        this.i18n = {};
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false)
      .then((allUserPersonal: Array<any>) => {
        this.userPersonalById = allUserPersonal.reduce((map, userPersonal) => {
          const user = {
            displayName: userPersonal.displayName,
            _photo: userPersonal._photo
          };
          map[userPersonal._id] = user;
          return map;
        }, {});
        this.loadUserComments();
      })
      .catch(() => {});
  }

  createComment(): void {
    if (this.creatingComment || check.not.assigned(this.comment.comment) || check.not.assigned(this.commentValidation) || this.commentValidation.hasErrors()) {
      return;
    }
    this.creatingComment = true;
    this.commentCreated.emit(this.comment);
    this.comment = {
      comment: null
    };

    if (check.assigned(this.bodyQuillEditor) && this.bodyQuillEditor.value) {
      this.bodyQuillEditor.value.delta = {};
      this.bodyQuillEditor.value.html = '';
      this.bodyQuillEditor.refreshContent();
      this.creatingComment = false;
    }
  }

  saveComment(updatedComment: any, id: string): void {
    const commentInfo = {
      commentId: id,
      comment: updatedComment
    };
    this.commentUpdated.emit(commentInfo);
  }

  private loadUserComments(): void {
    if (check.not.assigned(this.commentList) || check.emptyArray(this.commentList)) {
      return;
    }
    this.userComments = this.commentList
      .map((comment) => {
        const userComment = {
          id: comment._id,
          displayName: comment._createdById && this.userPersonalById[comment._createdById] ? this.userPersonalById[comment._createdById].displayName : null,
          _photo: comment._createdById && this.userPersonalById[comment._createdById] ? this.userPersonalById[comment._createdById]._photo : null,
          createdAt: comment._createdAt,
          updatedAt: comment._updatedAt,
          createdById: comment._createdById,
          comment: comment.comment
        };
        return userComment;
      })
      .sort((a, b) => {
        const valA = new Date(a.updatedAt);
        const valB = new Date(b.updatedAt);
        let comparison = 0;
        if (valA > valB) {
          comparison = -1;
        } else if (valA < valB) {
          comparison = 1;
        }
        return comparison;
      });
  }
}
