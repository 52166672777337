<orgos-dialog-container [title]="titleText" (clickCloseButton)="closeDialog()">
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 cld-white-space">
    {{ subtitleText }} <orgos-action [uppercase]="false" (click)="goToLink()" class="mtwd-action-button kenjo-font-size-14px"> {{ linkTo }} <kenjo-icon size="14" class="kenjo-font-color-primary-5993e3">launch</kenjo-icon></orgos-action>
  </div>

  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column *ngIf="cancelButtonText" size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ cancelButtonText }}</orgos-button-raised>
    </orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="kenjo-ml-20px">
      <orgos-button-raised [color]="confirmButtonColor" (click)="confirmDialog()">
        {{ confirmButtonText }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
