<div class="mupc-content" *ngIf="componentLoaded">
  <div class="mupc-time-off-types" *ngIf="timeOffTypes?.length > 0">
    <div *ngFor="let iTimeOffType of timeOffTypes" class="mupc-row kenjo-ph-30px">
      <orgos-column-container centerColumns="true">
        <!-- TIME OFF TYPE NAME -->
        <orgos-column size="0" class="kenjo-font-weight-bold kenjo-mr-20px mupc-row-title">
          <kenjo-truncate-text>
            {{ iTimeOffType.name }}
          </kenjo-truncate-text>
        </orgos-column>

        <!-- POLICY SELECTOR  -->
        <orgos-column size="0" class="kenjo-block kenjo-mr-20px" *ngIf="iTimeOffType?.policyOptions.length > 0">
          <orgos-input-simple-select class="mupc-policy-fields" [label]="translations.policyAssignedField" [options]="iTimeOffType.policyOptions" [enableClearButton]="true" [value]="iTimeOffType.newPolicyId" (valueChange)="onPolicyChange(iTimeOffType, $event)"> </orgos-input-simple-select>
        </orgos-column>

        <!-- UNASSIGN MESSAGE  -->
        <orgos-column size="0" *ngIf="iTimeOffType.oldPolicyId !== iTimeOffType.newPolicyId && !iTimeOffType.newPolicyId" class="kenjo-mr-40px">
          <orgos-column-container centerColumns="true">
            <kenjo-icon orgosColumn="0" class="kenjo-font-color-warning-ffc107 kenjo-mr-10px">warning_outline </kenjo-icon>
            <orgos-column class="mupc-info-text">{{ translations.unassignPolicy }}</orgos-column>
          </orgos-column-container>
        </orgos-column>

        <!-- NO POLICIES ON TIME OFF TYPE -->
        <orgos-column size="0" *ngIf="iTimeOffType?.policyOptions.length === 0" class="kenjo-mr-80px">
          <orgos-column-container centerColumns="true">
            <kenjo-icon orgosColumn="0" class="kenjo-font-color-success-00b72e kenjo-mr-10px">info_outline</kenjo-icon>
            <orgos-column class="mupc-info-text">{{ translations.noPoliciesDefined }}</orgos-column>
          </orgos-column-container>
        </orgos-column>

        <!-- EMPLOYEE WITHOUT START DATE AND DYNAMIC POLICY -->
        <orgos-column size="0" *ngIf="iTimeOffType.assignmentConflict === DYNAMIC_CYCLE_MISSING" class="mupc-info-text kenjo-mr-40px">
          <orgos-column-container centerColumns="true">
            <kenjo-icon orgosColumn="0" class="kenjo-font-color-warning-ffc107 kenjo-mr-10px">warning_outline</kenjo-icon>
            <orgos-column>{{ translations.noStartDateDynamicPolicyConflict }}</orgos-column>
          </orgos-column-container>
        </orgos-column>

        <!-- APPLY DATE OPTIONS -->
        <orgos-column size="0" class="kenjo-block kenjo-mr-20px" *ngIf="(iTimeOffType.assignmentConflict === undefined || iTimeOffType.assignmentConflict === START_DATE_MISSING) && iTimeOffType.newPolicyId && iTimeOffType.oldPolicyId !== iTimeOffType.newPolicyId">
          <orgos-input-simple-select
            class="mupc-short-policy-fields"
            [label]="translations.applyDateField"
            [options]="iTimeOffType.applyDateOptions"
            [autoOrder]="false"
            [value]="iTimeOffType.applyDate"
            [readOnly]="iTimeOffType.applyDateOptions && iTimeOffType?.applyDateOptions.length === 1"
            (valueChange)="onApplyDateChange(iTimeOffType, $event)"
          >
          </orgos-input-simple-select>
        </orgos-column>

        <!-- POLICY WITH START DATE PRORATION AND EMPLOYEE WITHOUT START DATE -->
        <orgos-column size="0" *ngIf="iTimeOffType.assignmentConflict === START_DATE_MISSING" class="mupc-info-text">
          <orgos-column-container centerColumns="true">
            <kenjo-icon orgosColumn="0" class="kenjo-font-color-warning-ffc107 kenjo-mr-10px">warning_outline </kenjo-icon>
            <orgos-column>{{ translations.noStartDateDynamicPolicyConflict }}</orgos-column>
          </orgos-column-container>
        </orgos-column>

        <!-- ASSIGN DATE -->
        <orgos-column size="0" class="kenjo-block kenjo-mr-20px" *ngIf="iTimeOffType.assignmentConflict === undefined && iTimeOffType.newPolicyId && iTimeOffType.oldPolicyId !== iTimeOffType.newPolicyId">
          <orgos-input-simple-date-picker
            class="mupc-short-policy-fields"
            [required]="true"
            [(value)]="iTimeOffType.assignDate"
            [readOnly]="!iTimeOffType.applyDate || iTimeOffType?.disabledAssignDate"
            [label]="translations.assignDateLabel"
            [min]="iTimeOffType?.minAssignDate"
            (valueChange)="onAssignDateChange(iTimeOffType, $event)"
          >
          </orgos-input-simple-date-picker>
        </orgos-column>

        <!-- BALANCE FIELDS -->
        <orgos-column size="0" class="kenjo-block" *ngIf="iTimeOffType.assignmentConflict === undefined && iTimeOffType.newPolicyId && iTimeOffType.oldPolicyId !== iTimeOffType.newPolicyId">
          <!-- BALANCE -->
          <ng-container *ngIf="!iTimeOffType.newPolicyUnlimitedBalance">
            <orgos-input-simple-number
              class="mupc-short-policy-fields"
              *ngIf="iTimeOffType.newPolicyType === POLICY_TYPE_DAY"
              [label]="translations.balanceLabel"
              [(value)]="iTimeOffType.balance"
              [readOnly]="!iTimeOffType.applyDate"
              [min]="POLICY_TYPE_DAY_MIN_VALUE"
              [max]="POLICY_TYPE_DAY_MAX_VALUE"
              [step]="0.01"
              [forceError]="iTimeOffType.balance !== undefined && ((balanceFieldsValidation[iTimeOffType.newPolicyId] && balanceFieldsValidation[iTimeOffType.newPolicyId].hasErrors() === true) || iTimeOffType.balance === null)"
              (validation)="validateBalanceField($event, iTimeOffType.newPolicyId, iTimeOffType.newPolicyType)"
              (valueChange)="checkInputValidations()"
            >
            </orgos-input-simple-number>
            <orgos-column-container *ngIf="iTimeOffType.newPolicyType !== POLICY_TYPE_DAY && iTimeOffType.balance">
              <orgos-column size="0">
                <orgos-input-simple-number
                  [label]="translations.balanceLabel"
                  [step]="1"
                  [min]="POLICY_TYPE_HOUR_MIN_VALUE"
                  [max]="POLICY_TYPE_HOUR_MAX_VALUE"
                  [suffix]="translations.hoursUnits"
                  [(value)]="iTimeOffType.balance.hours"
                  (validation)="validateBalanceField($event, iTimeOffType.newPolicyId, iTimeOffType.newPolicyType, 'hours')"
                  (valueChange)="checkInputValidations()"
                  class="mupc-small-picker kenjo-mr-20px"
                ></orgos-input-simple-number>
              </orgos-column>
              <orgos-column size="0">
                <orgos-input-simple-number
                  [step]="1"
                  [min]="POLICY_TYPE_MINUTE_MIN_VALUE"
                  [max]="POLICY_TYPE_MINUTE_MAX_VALUE"
                  [suffix]="translations.minutesUnits"
                  [(value)]="iTimeOffType.balance.minutes"
                  (validation)="validateBalanceField($event, iTimeOffType.newPolicyId, iTimeOffType.newPolicyType, 'minutes')"
                  (valueChange)="checkInputValidations()"
                  class="mupc-small-picker"
                ></orgos-input-simple-number>
              </orgos-column>
            </orgos-column-container>
          </ng-container>
          <ng-container *ngIf="iTimeOffType.newPolicyType === POLICY_TYPE_DAY">
            <orgos-input-error class="mupc-input-error" *ngIf="iTimeOffType.balance !== undefined && balanceFieldsValidation[iTimeOffType.newPolicyId] && balanceFieldsValidation[iTimeOffType.newPolicyId].getError('max')">
              {{ translations.newBalanceMaxValue }}
            </orgos-input-error>
            <orgos-input-error class="mupc-input-error" *ngIf="iTimeOffType.balance !== undefined && balanceFieldsValidation[iTimeOffType.newPolicyId] && balanceFieldsValidation[iTimeOffType.newPolicyId].getError('min')">
              {{ translations.newBalanceMinValue }}
            </orgos-input-error>
          </ng-container>
          <ng-container *ngIf="iTimeOffType.newPolicyType !== POLICY_TYPE_DAY && iTimeOffType.balance">
            <orgos-input-error class="mupc-input-error" *ngIf="iTimeOffType.balance.hours !== undefined && (balanceFieldsValidation[iTimeOffType.newPolicyId]?.hours?.getError('max') || balanceFieldsValidation[iTimeOffType.newPolicyId]?.maxHours === true)">
              {{ translations.newBalanceMaxValue }}
            </orgos-input-error>
            <orgos-input-error class="mupc-input-error" *ngIf="iTimeOffType.balance.hours !== undefined && balanceFieldsValidation[iTimeOffType.newPolicyId]?.hours?.getError('min')">
              {{ translations.newBalanceMinValue }}
            </orgos-input-error>
            <ng-container *ngIf="!balanceFieldsValidation[iTimeOffType.newPolicyId]?.hours?.getError('max') && !balanceFieldsValidation[iTimeOffType.newPolicyId]?.hours?.getError('min') && !balanceFieldsValidation[iTimeOffType.newPolicyId]?.maxHours">
              <orgos-input-error class="mupc-input-error" *ngIf="iTimeOffType.balance.minutes !== undefined && balanceFieldsValidation[iTimeOffType.newPolicyId]?.minutes?.getError('max')">
                {{ translations.newBalanceMinutesMaxValue }}
              </orgos-input-error>
              <orgos-input-error class="mupc-input-error" *ngIf="iTimeOffType.balance.minutes !== undefined && balanceFieldsValidation[iTimeOffType.newPolicyId]?.minutes?.getError('min')">
                {{ translations.newBalanceMinutesMinValue }}
              </orgos-input-error>
              <ng-container *ngIf="!balanceFieldsValidation[iTimeOffType.newPolicyId]?.minutes?.getError('max') && !balanceFieldsValidation[iTimeOffType.newPolicyId]?.minutes?.getError('min')">
                <orgos-input-error class="mupc-input-error" *ngIf="iTimeOffType.balance.hours !== undefined && balanceFieldsValidation[iTimeOffType.newPolicyId]?.positiveNegative === true">
                  {{ translations.newBalancePositiveNegativeValue }}
                </orgos-input-error>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- UNLIMITED POLICY -->
          <orgos-input-simple-text
            *ngIf="iTimeOffType.newPolicyUnlimitedBalance"
            class="mupc-short-policy-fields"
            [label]="translations.balanceLabel"
            [required]="true"
            [readOnly]="true"
            (validation)="validateBalanceField($event, iTimeOffType.newPolicyId, iTimeOffType.newPolicyType)"
            [value]="iTimeOffType.newPolicyType === POLICY_TYPE_DAY ? translations.unlimitedBalance : translations.unlimitedBalanceHourly"
          >
          </orgos-input-simple-text>
        </orgos-column>
      </orgos-column-container>

      <!-- INFO CREATED SCHEDULED ASSIGNMENT -->
      <orgos-column-container centerColumns="true" *ngIf="iTimeOffType?.scheduledAssignment === true">
        <orgos-column>
          <kenjo-info-message orgosColumn messageType="info" [messageText]="translations.scheduleMessage | i18nData: { assignDate: iTimeOffType.assignDate | date: 'shortDate' }" class="kenjo-mb-20px"> </kenjo-info-message>
        </orgos-column>
      </orgos-column-container>

      <!-- INFO REMOVED SCHEDULED ASSIGNMENT -->
      <orgos-column-container centerColumns="true" *ngIf="iTimeOffType?.scheduledPolicy && !iTimeOffType?.unscheduleAssignment && !(iTimeOffType?.scheduledAssignment === true) && iTimeOffType.newPolicyId && iTimeOffType.oldPolicyId !== iTimeOffType.newPolicyId">
        <orgos-column>
          <kenjo-info-message orgosColumn messageType="warning" [messageText]="translations.unscheduleAssignmentMessage | i18nData: { policyName: iTimeOffType.scheduledPolicy.policyName, scheduledFor: iTimeOffType.scheduledPolicy.scheduledFor | date: 'shortDate' }" class="kenjo-mb-20px">
          </kenjo-info-message>
        </orgos-column>
      </orgos-column-container>

      <!-- CURRENT SCHEDULED ASSIGNMENT -->
      <orgos-column-container
        *ngIf="iTimeOffType?.scheduledPolicy && !iTimeOffType?.unscheduleAssignment && !(iTimeOffType?.scheduledAssignment === true) && (!iTimeOffType.newPolicyId || iTimeOffType.oldPolicyId === iTimeOffType.newPolicyId)"
        class="kenjo-border kenjo-border-radius kenjo-mb-20px kenjo-color-grey-background-lighter-f8f8f8"
        centerColumns="true"
      >
        <orgos-column size="0" class="kenjo-ml-20px">
          <orgos-avatar [photoUrl]="iTimeOffType?.scheduledPolicy.pictureUserScheduled" [avatarName]="iTimeOffType?.scheduledPolicy.nameUserScheduled" size="tiny"></orgos-avatar>
        </orgos-column>
        <orgos-column size="1" class="kenjo-ml-10px kenjo-mr-20px">
          {{
            translations.scheduledAssignmentMessage
              | i18nData
                : {
                    nameUserScheduled: iTimeOffType.scheduledPolicy.nameUserScheduled,
                    policyName: iTimeOffType.scheduledPolicy.policyName,
                    scheduledFor: iTimeOffType.scheduledPolicy.scheduledFor | date: 'shortDate'
                  }
          }}
        </orgos-column>
        <orgos-column size="0" class="kenjo-border-left kenjo-pv-10px kenjo-ph-20px">
          <kenjo-icon [size]="20" class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer" [matTooltip]="translations.cancelScheduledAssignmentTooltip" (click)="cancelScheduledAssignment(iTimeOffType)"> cancel </kenjo-icon>
        </orgos-column>
      </orgos-column-container>
    </div>
  </div>
</div>
