<orgos-container aspect="card" [routerLink]="['/cloud/documents', document._id]" class="relative-card">

    <div *ngIf="document.tags.length === 0">
      <orgos-column-container class="dcc-tags-container">
        <orgos-column class="dcc-tag dcc-tag-unlabelled" [matTooltip]="tagNamesMap[unlabelledId]"> </orgos-column>
      </orgos-column-container>
    </div>
    <div *ngIf="document.tags.length > 0">
      <orgos-column-container class="dcc-tags-container">
        <orgos-column *ngFor="let tag of document.tags" [style.background-color]="tagColorsMap[tag]" class="dcc-tag" [matTooltip]="tagNamesMap[tag]"></orgos-column>
      </orgos-column-container>
    </div>

  <div *ngIf="document.digitalSigned" class="signature-icon">
    <kenjo-icon [size]="18" [matTooltip]="signatureTooltip" matTooltipPosition="above"> signature </kenjo-icon>
  </div>

  <orgos-column-container class="dcc-preview-icon-container" centerColumns="true">
    <orgos-column size="0" class="dcc-preview-icon">
      <mat-icon>documents_menu</mat-icon>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-border-top" centerColumns="true">
    <orgos-column size="0" class="dcc-extension-container">
      <div class="dcc-extension-text kenjo-font-color-white-ffffff kenjo-font-size-12px kenjo-text-transform-uppercase">{{ document._file._fileExtension }}</div>
    </orgos-column>
    <orgos-column size="0" class="dcc-name-container kenjo-p-10px">
      <kenjo-truncate-text>{{ document.name }}</kenjo-truncate-text>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
