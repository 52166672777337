import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class CandidateService {
  private CANDIDATE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/candidate-db`;
  private CANDIDATE_ANONYMIZATION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting/candidate-anonymization`;
  private CANDIDATE_SCHEDULED_DELETION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting/candidate-scheduled-deletion`;
  private CANDIDATE_PERMISSIONS_KEY: string = 'candidate';
  private CANDIDATE_INTERNATIONALIZATION: string = 'candidate-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.CANDIDATE_URL, data)
        .then((candidate: any) => {
          resolve(candidate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateService.name, 'create'));
        });
    });
  }

  async getById(id: string): Promise<any> {
    try {
      const result = await this.genericService.find(this.CANDIDATE_URL, { _id: id, _isArchived: { $ne: true } });
      if (check.nonEmptyArray(result)) {
        return result[0];
      } else {
        return undefined;
      }
    } catch (error) {
      throw this.errorManager.handleRawError(error, CandidateService.name, 'getById');
    }
  }

  async find(findBody: any): Promise<any> {
    try {
      const query = { ...findBody, _isArchived: { $ne: true } };
      const result = await this.genericService.find(this.CANDIDATE_URL, query);
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, CandidateService.name, 'find');
    }
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.CANDIDATE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.CANDIDATE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateService.name, 'deleteById'));
        });
    });
  }

  createComment(id: string, data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, CandidateService.name, 'createComment');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.CANDIDATE_URL}/${id}/comment`, data, httpOptions)
        .toPromise()
        .then((result: any) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateService.name, 'createComment'));
        });
    });
  }

  async updateComment(id: string, commentId: string, updatedComment: object, positionId?: string, isPrivate?: boolean): Promise<any> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, CandidateService.name, 'updateComment');
        throw this.errorManager.handleRawError(error);
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        comment: updatedComment,
        positionId,
        private: isPrivate
      };

      return this.http.put(`${this.CANDIDATE_URL}/${id}/comment/${commentId}`, body, httpOptions).toPromise();
    } catch (error) {
      this.errorManager.handleRawError(error, CandidateService.name, 'updateComment');
    }
  }

  public touchUpdatedAtById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, CandidateService.name, 'touchUpdatedAtById');
        return Promise.reject(this.errorManager.handleRawError(error));
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.CANDIDATE_URL}/${id}/touch/`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateService.name, 'touchUpdatedAtById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.CANDIDATE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.CANDIDATE_INTERNATIONALIZATION);
  }

  async getCandidates(): Promise<Array<any>> {
    try {
      const result = await this.genericService.find(this.CANDIDATE_URL, { _id: { $ne: null }, _isArchived: { $ne: true } });
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, CandidateService.name, 'getCandidates');
    }
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.CANDIDATE_URL);
  }

  async softDeleteCandidates(candidateIds: Array<string>) {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      await this.http.post(`${this.CANDIDATE_URL}/archive`, { candidates: candidateIds }, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, CandidateService.name, 'softDeleteCandidates');
    }
  }

  async candidatesCanBeAnonymized(candidateIds: Array<string>): Promise<{ [key: string]: boolean }> {
    if (check.not.assigned(candidateIds) || check.emptyArray(candidateIds)) {
      return {};
    }

    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const result = await this.http.post<{ [key: string]: boolean }>(`${this.CANDIDATE_ANONYMIZATION_URL}/can-anonymize-candidates`, candidateIds, httpOptions).toPromise();
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, CandidateService.name, 'canAnonymizeCandidate');
    }
  }

  async getDaysToDeleteCandidates(candidates: Array<string>): Promise<{ [key: string]: number | false }> {
    if (check.not.assigned(candidates) || check.emptyArray(candidates)) {
      return {};
    }

    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const result = await this.http.post<{ [key: string]: number | false }>(`${this.CANDIDATE_SCHEDULED_DELETION_URL}/days-to-delete`, { candidates }, httpOptions).toPromise();
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, CandidateService.name, 'canAnonymizeCandidate');
    }
  }
}
