import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { UserDatevService } from '@app/standard/services/user/user-datev.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-add-employee-activation-step',
  templateUrl: 'activation-step.component.html',
  styleUrls: ['activation-step.component.scss'],
})
export class AddEmployeeActivationStepComponent {
  constructor(private injector: Injector) {}

  @Input() translations: any = {};

  @Input() userWorkSchedule: GenericCacheModel;
  @Input() activationOptions: { sendInvitation: boolean };
  @Input() activationPermissions: { sendInvitation: boolean; trackAttendance: boolean };
  @Input() attendanceEnabled: boolean = true;
  @Input() datevOptions: { isActive: boolean; personalNumber?: Number | undefined; companyId?: string | undefined; datevEnabled: boolean };

  @Output() previousStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addEmployee: EventEmitter<{ addEmployee: boolean; addUserDatev: boolean; userDatevPersonalNumber: Number }> = new EventEmitter<{
    addEmployee: boolean;
    addUserDatev: boolean;
    userDatevPersonalNumber: Number;
  }>();

  personalNumberValidation: InputValidation;

  addEmployeeAllowed: boolean = true;
  isValidPersonalNumber: boolean = true;

  disableButton: boolean = false;

  onPreviousStep() {
    this.previousStep.emit(true);
  }

  async onAddEmployee() {
    this.disableButton = true;
    this.isValidPersonalNumber = await this.checkValidPersonalNumber();
    if (this.isValidPersonalNumber !== true) {
      this.addEmployeeAllowed = false;
      this.disableButton = false;
      return;
    }
    this.addEmployee.emit({
      addEmployee: true,
      addUserDatev: this.datevOptions.datevEnabled,
      userDatevPersonalNumber: this.datevOptions.personalNumber,
    });
  }

  async checkValidPersonalNumber() {
    if (check.assigned(this.datevOptions.companyId) && check.assigned(this.datevOptions.personalNumber)) {
      const userDatev = await this.injector
        .get(UserDatevService)
        .find({ companyId: this.datevOptions.companyId, personalNumber: this.datevOptions.personalNumber });
      return check.not.nonEmptyArray(userDatev);
    }
    return true;
  }

  onChangeValue() {
    this.addEmployeeAllowed = true;
    this.isValidPersonalNumber = true;
  }

  logActivationEvent(label: string, checked: boolean) {
    if (!checked) {
      return;
    }

    const event =
      label === 'invitation'
        ? 'deactivate send invitation'
        : label === 'datev'
        ? 'activate datev'
        : 'deactivate activate attendance tracking';

    this.injector.get(PrivateAmplitudeService).logEvent(event, { platform: 'Web', category: 'Employee', subcategory1: 'Creation wizard' });
  }
}
