import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { IBreakReminderConfiguration } from '@app/standard/services/attendance/controllers/break-reminder.controller';
import { IBreak } from '@app/standard/services/user/user-attendance.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  templateUrl: 'break-reminder.dialog.html',
  styleUrls: ['break-reminder.dialog.scss'],
})
export class BreakReminderDialog implements OnInit {
  translations: any = {};
  breakReminderConfig: IBreakReminderConfiguration;
  breaks: Array<IBreakReminderBreak>;
  originalBreaks: Array<IBreakReminderBreak>;

  breakTime = 0;
  canBeSaved = false;
  overlappingConflict = false;
  shiftConflict = false;
  timeOffConflict = false;
  from: string;
  to: string;

  MINUTES_IN_DAY = 1440;

  @ViewChild('breaksContainer') breaksContainer: ElementRef;

  constructor(
    public dialogRef: MatLegacyDialogRef<BreakReminderDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {
    this.translations = this.data.translations;
    this.breakReminderConfig = this.data.breakReminderConfig;
  }

  ngOnInit() {
    this.initBreaks();
    this.initInterval();
    this.computeDurations();
    this.computeBreakTime();
    this.computeCanBeSaved();
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('break reminder is triggered', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Attendance policies',
        subcategory2: 'Break Reminder Desktop',
      });
  }

  private initBreaks() {
    this.breaks = this.breakReminderConfig.breaks.length > 0 ? this.breakReminderConfig.breaks : [{ start: undefined, end: undefined }];

    if (!this.breakReminderConfig.canEditCurrentBreaks && this.breakReminderConfig.breaks.length > 0) {
      this.breaks.forEach((iBreak) => (iBreak.isReadOnly = true));
      this.breaks.push({ start: undefined, end: undefined });
    }

    this.breaks.forEach((iBreak) => {
      if (check.not.assigned(iBreak.conflicts)) {
        iBreak.conflicts = {};
      }
    });

    this.originalBreaks = _.cloneDeep(this.breaks);
  }

  private initInterval() {
    this.from = this.convertToTime(this.breakReminderConfig.from);
    this.to = this.convertToTime(this.breakReminderConfig.to);
  }

  onBreakChange() {
    this.computeDurations();
    this.computeBreakTime();
    this.checkBreakConflicts();
    this.checkShiftConflicts();
    this.checkTimeOffConflicts();
    this.computeCanBeSaved();
  }

  parseTimeEntry(totalMinutes: number | undefined | null, type: 'start' | 'end', index: number): string {
    if (check.not.assigned(totalMinutes)) {
      return check.assigned(this.breaks[index][type]) ? ' ' : null;
    }

    const hours = Math.floor((totalMinutes / 60) % 24);
    const minutes = (((totalMinutes / 60) % 24) - Math.floor((totalMinutes / 60) % 24)) * 60;

    return `${this.injector.get(DecimalPipe).transform(hours, '2.0-0')}:${this.injector.get(DecimalPipe).transform(minutes, '2.0-0')}`;
  }

  addBreak() {
    const canAdd = this.canAddNewBreak();
    if (!canAdd) {
      return;
    }

    this.breaks.push({ start: undefined, end: undefined, conflicts: {}, duration: 0 });

    this.computeCanBeSaved();
    this.scrollToBottom();
  }

  deleteBreak(index: number) {
    this.breaks.splice(index, 1);
    this.onBreakChange();
  }

  private canAddNewBreak() {
    if (this.breaks.length === 0) {
      return true;
    }

    const anyIncomplete = this.breaks.some((iBreak) => check.not.assigned(iBreak.start) || check.not.assigned(iBreak.end));

    return !anyIncomplete;
  }

  private computeDurations() {
    this.breaks?.forEach((iBreak) => {
      if (check.assigned(iBreak.start) && check.assigned(iBreak.end)) {
        iBreak.duration = iBreak.end < iBreak.start ? iBreak.end - iBreak.start + this.MINUTES_IN_DAY : iBreak.end - iBreak.start;
      } else {
        iBreak.duration = 0;
      }
    });
  }

  private computeBreakTime() {
    this.breakTime = this.breaks.reduce((total, iBreak) => total + iBreak.duration, 0);
  }

  private checkBreakConflicts() {
    if (this.breaks.length === 0) {
      return;
    }

    const sortedBreaks = [...this.breaks];
    this.sortBreaks(sortedBreaks);

    let overlappingConflict = false;
    sortedBreaks.forEach((iBreak, i) => {
      if (i === 0) {
        iBreak.conflicts.overlappingStart = false;
        iBreak.conflicts.overlappingEnd = false;
      }

      iBreak.conflicts.sameStartAndEnd = check.assigned(iBreak.start) && check.assigned(iBreak.end) && iBreak.end === iBreak.start;
      if (iBreak.conflicts.sameStartAndEnd) {
        overlappingConflict = true;
      }

      if (sortedBreaks.length === i + 1) {
        return;
      }

      for (let j = i + 1; j < sortedBreaks.length; j++) {
        const jBreak = sortedBreaks[j];

        const iEnd =
          check.assigned(iBreak.start) && check.assigned(iBreak.end) && this.breakReminderConfig.isOvernight && iBreak.start > iBreak.end
            ? iBreak.end + this.MINUTES_IN_DAY
            : iBreak.end;
        const jEnd =
          check.assigned(jBreak.start) && check.assigned(jBreak.end) && this.breakReminderConfig.isOvernight && jBreak.start > jBreak.end
            ? jBreak.end + this.MINUTES_IN_DAY
            : jBreak.end;

        if (jBreak.isReadOnly) {
          iBreak.conflicts.overlappingStart = jBreak.start === iBreak.start || (jBreak.start >= iBreak.start && jBreak.start < iEnd);
          iBreak.conflicts.overlappingEnd = check.assigned(jEnd) && check.assigned(iEnd) && jEnd <= iEnd;
        } else {
          jBreak.conflicts.overlappingStart = jBreak.start === iBreak.start || (jBreak.start >= iBreak.start && jBreak.start < iEnd);
          jBreak.conflicts.overlappingEnd = check.assigned(jEnd) && check.assigned(iEnd) && jEnd <= iEnd;
        }

        if (
          jBreak.conflicts.overlappingStart ||
          jBreak.conflicts.overlappingEnd ||
          iBreak.conflicts.overlappingStart ||
          iBreak.conflicts.overlappingEnd
        ) {
          overlappingConflict = true;
        }
      }
    });

    this.overlappingConflict = overlappingConflict;
  }

  private sortBreaks(breaks: Array<IBreak>) {
    breaks.sort((iBreak, jBreak) => {
      if (iBreak.start > jBreak.start) {
        return 1;
      }

      if (iBreak.start < jBreak.start) {
        return -1;
      }

      if (iBreak.start === jBreak.start && iBreak.end > jBreak.end) {
        return 1;
      }

      return -1;
    });
  }

  private checkShiftConflicts() {
    let shiftConflict = false;
    this.breaks.forEach((iBreak) => {
      iBreak.conflicts.outOfShiftStart = !this.isInInterval(iBreak.start);
      iBreak.conflicts.outOfShiftEnd = !this.isInInterval(iBreak.end < iBreak.start ? iBreak.end + this.MINUTES_IN_DAY : iBreak.end);

      if (iBreak.conflicts.outOfShiftStart || iBreak.conflicts.outOfShiftEnd) {
        shiftConflict = true;
      }
    });

    this.shiftConflict = shiftConflict;
  }

  private isInInterval(n?: number): boolean {
    if (check.not.assigned(n)) {
      return true;
    }

    if (n >= this.breakReminderConfig.from && n <= this.breakReminderConfig.to) {
      return true;
    }

    if (!this.breakReminderConfig.isOvernight || n > this.MINUTES_IN_DAY) {
      return false;
    }

    if (n + this.MINUTES_IN_DAY >= this.breakReminderConfig.from && n + this.MINUTES_IN_DAY <= this.breakReminderConfig.to) {
      return true;
    }

    return false;
  }

  private checkTimeOffConflicts() {
    if (check.not.assigned(this.breakReminderConfig.timeOffShifts) || this.breakReminderConfig.timeOffShifts.length === 0) {
      this.timeOffConflict = false;
      return;
    }

    let timeOffConflict = false;
    this.breaks.forEach((iBreak) => {
      if (check.not.assigned(iBreak.start) || check.not.assigned(iBreak.end)) {
        return;
      }

      const timeOff = this.breakReminderConfig.timeOffShifts.find((iTimeOff) => {
        return (
          (iBreak.start >= iTimeOff.startTime && iBreak.start < iTimeOff.endTime) ||
          (iBreak.end > iTimeOff.startTime && iBreak.end <= iTimeOff.endTime) ||
          (iTimeOff.startTime >= iBreak.start && iTimeOff.startTime < iBreak.end) ||
          (iTimeOff.endTime > iBreak.start && iTimeOff.endTime <= iBreak.end)
        );
      });

      iBreak.conflicts.overlappingTimeOff = check.assigned(timeOff);
      timeOffConflict = timeOffConflict || check.assigned(timeOff);
    });

    this.timeOffConflict = timeOffConflict;
  }

  private computeCanBeSaved() {
    if (this.overlappingConflict || this.shiftConflict || this.timeOffConflict) {
      this.canBeSaved = false;
      return;
    }

    if (this.breaks.length === 0) {
      this.canBeSaved = true;
      return;
    }

    let [incompleteBreaks, emptyBreaks, wrongBreaks] = [0, 0, 0];
    this.breaks.forEach((iBreak) => {
      if (check.not.assigned(iBreak.start) && check.not.assigned(iBreak.end)) {
        emptyBreaks += 1;
      }

      if (check.assigned(iBreak.start) && check.not.assigned(iBreak.end)) {
        incompleteBreaks += 1;
      }

      if (check.not.assigned(iBreak.start) && check.assigned(iBreak.end)) {
        wrongBreaks += 1;
      }
    });

    this.canBeSaved = emptyBreaks === 0 && wrongBreaks === 0 && incompleteBreaks === 0;
  }

  private convertToTime(minutesValue: number): string {
    const hours = Math.floor((minutesValue / 60) % 24);
    const minutes = (((minutesValue / 60) % 24) - Math.floor((minutesValue / 60) % 24)) * 60;

    return `${this.injector.get(DecimalPipe).transform(hours, '2.0-0')}:${this.injector.get(DecimalPipe).transform(minutes, '2.0-0')}`;
  }

  private scrollToBottom() {
    setTimeout(() => {
      Promise.resolve().then(() => {
        this.breaksContainer.nativeElement.scrollTo({ top: this.breaksContainer.nativeElement.scrollHeight, behavior: 'smooth' });
      });
    }, 200);
  }

  private hasChanges() {
    const breaks = this.breaks.map(({ _id, start, end }) => ({ _id, start, end }));
    const originalBreaks = this.originalBreaks.map(({ _id, start, end }) => ({ _id, start, end }));
    return !_.isEqual(breaks, originalBreaks);
  }

  save() {
    this.computeCanBeSaved();
    if (!this.canBeSaved) {
      return;
    }

    const newBreaks = this.breaks.map((iBreak) => {
      const start =
        this.breakReminderConfig.isOvernight && iBreak.start < this.breakReminderConfig.from
          ? iBreak.start + this.MINUTES_IN_DAY
          : iBreak.start;
      const end = this.breakReminderConfig.isOvernight && start > iBreak.end ? iBreak.end + this.MINUTES_IN_DAY : iBreak.end;

      return { start, end, ...(check.assigned(iBreak._id) && { _id: iBreak._id }) };
    });

    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('break reminder is saved with value', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Attendance policies',
        subcategory2: 'Break Reminder Desktop',
      });

    this.dialogRef.close(newBreaks);
  }

  async close() {
    if (!this.hasChanges()) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('break reminder is skipped', {
          platform: 'Web',
          category: 'Attendance',
          subcategory1: 'Attendance policies',
          subcategory2: 'Break Reminder Desktop',
        });
      this.dialogRef.close();
      return;
    }

    const dialogData = {
      titleText: this.translations.closeDialog.title,
      subtitleText: this.translations.closeDialog.subtitle,
      confirmButtonText: this.translations.closeDialog.confirm,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.translations.closeDialog.cancel,
    };

    const confirm = await this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData }).afterClosed().toPromise();
    if (confirm) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('break reminder is skipped', {
          platform: 'Web',
          category: 'Attendance',
          subcategory1: 'Attendance policies',
          subcategory2: 'Break Reminder Desktop',
        });
      this.dialogRef.close();
    }
  }
}

interface IBreakReminderBreak extends IBreak {
  isReadOnly?: boolean;
  duration?: number;
  conflicts?: {
    overlappingStart?: boolean;
    overlappingEnd?: boolean;
    outOfShiftStart?: boolean;
    outOfShiftEnd?: boolean;
    sameStartAndEnd?: boolean;
    overlappingTimeOff?: boolean;
  };
}
