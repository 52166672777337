<orgos-widget titleKey="newApplicant" [loadingWidget]="loadingWidget" [existingData]="newApplicants && newApplicants.length > 0">
  <orgos-widget-header-action *ngIf="showRejected">
    <orgos-action (click)="changeRejectedVisibility()">{{ translation.hideRejected }}</orgos-action>
  </orgos-widget-header-action>
  <orgos-widget-header-action *ngIf="!showRejected">
    <orgos-action (click)="changeRejectedVisibility()">{{ translation.showRejected }}</orgos-action>
  </orgos-widget-header-action>
  <orgos-widget-content>
    <div *ngFor="let iApplicant of newApplicants" size="0" class="kenjo-mb-20px">
      <div *ngIf="iApplicant?.position?.jobTitle && (showRejected || positionIdContainsNonRejected[iApplicant.position._id])" class="kenjo-font-size-12px kenjo-text-transform-uppercase kenjo-font-color-light-text-757575">
        {{ iApplicant.position.jobTitle }}
      </div>
      <div *ngIf="iApplicant?.applicants?.length">
        <div *ngFor="let applicant of iApplicant.applicants">
          <orgos-column-container
            class="kenjo-mv-15px kenjo-cursor-pointer"
            centerColumns="true"
            *ngIf="showRejected || !applicant.positionCandidate.isDisqualified"
            [class.wna-rejected-applicant]="applicant.isDisqualified"
            (click)="navigateToCandidatePage(applicant.candidate._id, iApplicant.position._id)"
          >
            <orgos-column size="0" class="kenjo-mr-10px">
              <orgos-avatar *ngIf="applicant.candidate" class="wna-avatar" orgosColumn="0" [photoUrl]="applicant.candidate.photo?._url" [avatarName]="applicant.candidate.firstName + ' ' + applicant.candidate.lastName" size="small"></orgos-avatar>
            </orgos-column>
            <orgos-column size="1" class="kenjo-mr-10px" *ngIf="applicant.candidate">
              <div>{{ applicant.candidate.firstName }} {{ applicant.candidate.lastName }}</div>
              <div *ngIf="applicant.positionCandidate.isDisqualified" class="kenjo-mt-5px kenjo-font-color-danger-ff5757">
                {{ translation.rejected }}
              </div>
            </orgos-column>
            <orgos-column size="0">
              <div class="kenjo-font-color-light-text-757575">{{ applicant.positionCandidate._createdAt | humanDate: false:'short' }}</div>
            </orgos-column>
          </orgos-column-container>
        </div>
      </div>
    </div>
  </orgos-widget-content>
</orgos-widget>
