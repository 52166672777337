import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostBinding, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { getEmptyFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { AddTemplateDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/add-template-dialog/add-template.dialog';
import { ShiftPlanEmployeeListService } from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import { ShiftCardFilters } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import {
  IShiftPlanTemplate,
  IShiftplanTemplatePreference,
  IShiftplanTemplateSortOptions,
} from '@app/cloud-features/shift-plan/services/shift-plan-template.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import * as check from 'check-types';
import { isEqual } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shift-plan-template-panel',
  templateUrl: 'shift-plan-template-panel.component.html',
  styleUrls: ['shift-plan-template-panel.component.scss'],
  animations: [
    trigger('isSortingAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('3s cubic-bezier(.17,.7,.32,1)', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s cubic-bezier(.17,.7,.32,1)', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ShiftPlanTemplatePanelComponent implements OnInit, OnDestroy {
  @Input() showTemplateDetails: boolean;
  @Input() closePanel: boolean = false;
  @Input() isDragging: boolean = false;
  @Input() LIST_IDS: Array<string> = [];
  @Input() allDataResponse;
  @Input() breaktimeDeduction: boolean = false;
  @Input() openShifts;
  @Input() panelStatus: 'close' | 'open' | 'standby' = 'standby';
  @Input()
  get filters() {
    return this._filters;
  }
  set filters(newFilters: ShiftCardFilters) {
    if (check.not.assigned(newFilters) || this.isSameFilters(newFilters)) {
      return;
    }

    this._filters = { ...newFilters, employees: [] };
    if (this.sortPreference && this.sortPreference.applyFilters) {
      this.isSorting = true;
      this.sortTemplates();
      this.delayIsSorting(500);
    }
  }

  @Output() handleDraggingTemplateEvent = new EventEmitter<{ isDragging: boolean; template: IShiftPlanTemplate }>();
  @Output() addNewShiftEvent = new EventEmitter<any>();
  @ViewChild('templateList') templateList: CdkDropList;
  @HostBinding('style.--n-templates') nTemplates: string;

  _filters: ShiftCardFilters = { ...getEmptyFilters() };
  allTemplates: Array<IShiftPlanTemplate> = [];
  templateTranslations: { [key: string]: string };
  detailsPosition: DOMRect;
  shiftplanPermissions: IShiftPlanPermissions;
  isLoading: boolean = false;
  isSorting: boolean = false;
  areFiltersEmpty: boolean = false;
  templateDetail: IShiftPlanTemplate;
  sortPreference: IShiftplanTemplatePreference;
  TEMPLATE_PREFERENCE_KEY = 'shiftplan-template-sort';

  displayedTemplates: Array<IShiftPlanTemplate>;
  sortedTemplates: Array<IShiftPlanTemplate>;
  searchedTemplates: Array<IShiftPlanTemplate>;

  sortIcon: string;
  sortTooltip: string;
  searchTerm: string = '';
  sortingOptions: IShiftplanTemplateSortOptions = {
    SORT_ORDER: {
      asc: { icon: 'sort_ascending', value: 'asc', tooltips: { _createdAt: '', name: '' } },
      desc: { icon: ' sort_descending', value: 'desc', tooltips: { _createdAt: '', name: '' } },
    },
    SORT_BY: { createdAt: '_createdAt', name: 'name' },
  };

  constructor(private injector: Injector) {}
  ngOnInit() {
    this.initData();
  }

  async initData() {
    this.isLoading = true;
    const [shiftplanPermissions, translations] = await Promise.all([
      this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions(),
      this.injector.get(InternationalizationService).getAllTranslation('shift-plan-templates'),
    ]);

    this.allTemplates = this.allDataResponse.templates;
    this.shiftplanPermissions = shiftplanPermissions;
    this.templateTranslations = translations;
    this.sortPreference = await this.getTemplatePreferences();
    this.searchTerm = this.sortPreference.search;
    this.setTooltips();
    this.sortTemplates();

    this.isLoading = false;
  }

  setTooltips() {
    this.sortingOptions.SORT_ORDER.asc.tooltips._createdAt = this.templateTranslations.showOldest;
    this.sortingOptions.SORT_ORDER.asc.tooltips.name = this.templateTranslations.showZtoA;
    this.sortingOptions.SORT_ORDER.desc.tooltips._createdAt = this.templateTranslations.showNewest;
    this.sortingOptions.SORT_ORDER.desc.tooltips.name = this.templateTranslations.showAtoZ;
  }

  updateTemplatesNumber(listLength: number = 0) {
    this.nTemplates = `${(listLength - 1) * 100}%`;
  }

  async getTemplatePreferences() {
    const preference = await this.injector.get(PreferenceService).getPreferenceByKey(this.TEMPLATE_PREFERENCE_KEY);
    return preference?.preference ?? { sortBy: '_createdAt', sortOrder: 'asc', applyFilters: true, search: '' };
  }

  addNewTemplate(template: null | IShiftPlanTemplate = null) {
    if (this.isLoading) {
      return;
    }

    const openDialogTime = new Date();
    const addTemplateDialog = this.injector.get(MatLegacyDialog).open(AddTemplateDialog, {
      data: {
        response: this.allDataResponse,
        template,
        editMode: check.assigned(template),
        translations: this.templateTranslations,
        breaktimeDeduction: this.breaktimeDeduction,
        openShiftSettings: this.openShifts,
      },
    });

    addTemplateDialog.afterClosed().subscribe(async (confirmedAction) => {
      const closeDialogTime = new Date();
      const timeElapsed = closeDialogTime.getTime() - openDialogTime.getTime();
      this.injector.get(PrivateAmplitudeService).logEvent('time spent to add new template', {
        category: 'Shiftplan',
        subcategory: 'NewTemplate',
        subcategory2: 'Time spent',
        value: timeElapsed,
      });
      if (confirmedAction) {
        const response = await this.injector.get(ShiftPlanEmployeeListService).getEmployeesDetailed();
        this.allTemplates = response.templates;
        this.sortTemplates();
      }
    });
  }

  addNewShift(selectedDayIndex = null, selectedData = {}, template: null | IShiftPlanTemplate = null) {
    this.addNewShiftEvent.emit({ selectedDayIndex, selectedData, template });
  }

  handleDraggingTemplate(isDragging: boolean, template: IShiftPlanTemplate): void {
    this.handleDraggingTemplateEvent.emit({ isDragging, template });
    this.hideDetails();
  }

  showDetails(event: MouseEvent, template: IShiftPlanTemplate) {
    if (this.templateDetail !== template) {
      this.templateDetail = template;
    }
    const templateDOM = event.target as HTMLElement;
    this.detailsPosition = templateDOM.getBoundingClientRect();
    this.showTemplateDetails = true;
    this.templateDetail = template;
  }

  sortTemplates() {
    const { sortOrder, sortBy } = this.sortPreference;
    const sortOrderOption = this.sortingOptions.SORT_ORDER[sortOrder];
    this.sortIcon = sortOrderOption.icon;
    this.sortTooltip = sortOrderOption.tooltips[sortBy];

    const sortFunction = this.sortPreference.sortBy === this.sortingOptions.SORT_BY.createdAt ? this.sortByDate : this.sortByName;
    const sortFactor = this.sortPreference.sortOrder === this.sortingOptions.SORT_ORDER.asc.value ? 1 : -1;

    const templatesToSort = [...this.handleTemplatesToSort()];
    this.updateTemplatesNumber(templatesToSort.length);
    this.sortedTemplates = templatesToSort.sort(sortFunction(sortFactor));

    if (check.not.emptyString(this.searchTerm)) {
      this.searchTemplates(this.searchTerm);
    } else {
      this.displayedTemplates = [...this.sortedTemplates];
    }
  }

  async saveSortPreferences() {
    await this.injector.get(PreferenceService).setPreferenceByKey(this.TEMPLATE_PREFERENCE_KEY, this.sortPreference);
  }

  handleTemplatesToSort() {
    this.checkEmptyFilters();
    if (this.sortPreference.applyFilters && !this.areFiltersEmpty) {
      return this.filterTemplates();
    } else {
      return this.allTemplates;
    }
  }

  changeSortOrder() {
    const isAsc = this.sortPreference.sortOrder === this.sortingOptions.SORT_ORDER.asc.value;
    this.sortPreference.sortOrder = isAsc ? this.sortingOptions.SORT_ORDER.desc.value : this.sortingOptions.SORT_ORDER.asc.value;
    this.handleAnimateElement();

    this.sortTemplates();
  }

  handleAnimateElement() {
    const prevAnimatedElement = this.templateList.element.nativeElement.querySelector('.sptp-animate');
    if (prevAnimatedElement) {
      prevAnimatedElement.classList.remove('sptp-animate');
    }

    const lastTemplate = this.templateList.element.nativeElement.lastElementChild;
    if (lastTemplate) {
      lastTemplate.classList.add('sptp-animate');
    }
  }

  changeSortBy(sortBy: 'name' | '_createdAt') {
    this.isSorting = true;
    this.sortPreference.sortBy = sortBy;
    this.sortTemplates();
    this.delayIsSorting(1000);
  }

  changeApplyFilters() {
    this.isSorting = true;
    this.sortPreference.applyFilters = !this.sortPreference.applyFilters;
    this.sortTemplates();
    this.delayIsSorting(1000);
  }

  hideDetails() {
    this.showTemplateDetails = false;
    this.templateDetail = null;
  }

  delayIsSorting(delay: number) {
    const sortTimeout = setTimeout(() => {
      this.isSorting = false;
      clearTimeout(sortTimeout);
    }, delay);
  }

  trackByTemplate(index: number, template: IShiftPlanTemplate) {
    return template._id;
  }

  searchTemplates(searchTerm: string) {
    this.sortPreference.search = searchTerm;

    //No need to filter
    if (check.emptyString(searchTerm)) {
      this.isSorting = true;
      const searchResults = [...this.sortedTemplates];
      this.updateTemplatesNumber(searchResults.length);
      this.displayedTemplates = [...searchResults];
      this.delayIsSorting(500);
      return;
    }

    const searchResults = [...this.sortedTemplates].filter((iTemplate) => {
      const regExp = new RegExp(`^.*${searchTerm.trim()}.*$`, 'i');
      return regExp.test(iTemplate.name);
    });
    this.updateTemplatesNumber(searchResults.length);
    this.displayedTemplates = [...searchResults];
  }

  private sortByDate(sortFactor: number): (a, b) => number {
    return (a, b) => {
      return moment.utc(a._createdAt).isAfter(b._createdAt) ? -1 * sortFactor : 1 * sortFactor;
    };
  }

  private sortByName(sortFactor: number): (a, b) => number {
    return (a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1 * sortFactor;
      }

      return -1 * sortFactor;
    };
  }

  checkEmptyFilters() {
    this.areFiltersEmpty =
      this.filters.locations.length === 0 &&
      this.filters.roles.length === 0 &&
      this.filters.workingAreas.length === 0 &&
      this.filters.tags.length === 0;
  }

  filterTemplates() {
    const { locations, roles, workingAreas, tags } = this.filters;

    return this.allTemplates.filter((iTemplate) => {
      const matchingLocation = locations.length === 0 || locations.includes(iTemplate.locationId);
      const matchingRole = roles.length === 0 || roles.includes(iTemplate.roleId);
      const matchingWorkingArea = workingAreas.length === 0 || workingAreas.includes(iTemplate.workingAreaId);
      const matchingTag = tags.length === 0 || tags.includes(iTemplate.tagId);
      return matchingLocation && matchingRole && matchingWorkingArea && matchingTag;
    });
  }

  isSameFilters(newFilters: ShiftCardFilters) {
    // We get all filters except employees and compare them
    const { employees: newEmployees, ...newRestOfFilters } = newFilters;
    const { employees, ...restOfFilters } = this.filters;

    return isEqual(newRestOfFilters, restOfFilters);
  }

  ngOnDestroy() {
    if (check.emptyObject(this.sortPreference)) {
      return;
    }

    this.saveSortPreferences();
  }
}
