<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogName" (clickCloseButton)="closeDialog()">
  <ng-container *ngIf="employment">
    <orgos-column-container>
      <orgos-input-select orgosColumn [model]="employment" field="_companyId" [options]="companyOptions" [required]="true" class="kenjo-mr-10px"></orgos-input-select>
      <orgos-input-date-picker orgosColumn [model]="employment" [min]="minDate" field="startDate" [required]="true" (validation)="startDateValidation = $event" class="kenjo-ml-10px">
        <orgos-input-error *ngIf="startDateValidation && startDateValidation.getError('required')"> {{dialogTranslation.startDateRequired}}</orgos-input-error>
        <orgos-input-error *ngIf="startDateValidation && startDateValidation.getError('date')"> {{dialogTranslation.startDateNoValidFormat}}</orgos-input-error>
      </orgos-input-date-picker>
    </orgos-column-container>
    <orgos-column-container>
      <orgos-input-select [sortOptions]="false" orgosColumn [model]="employment" field="userEmploymentContractType" [options]="userEmploymentContractTypes$ | async" [required]="true" class="kenjo-mr-10px"></orgos-input-select>
      <orgos-input-select
        [sortOptions]="false"
        orgosColumn
        [model]="employment"
        field="userEmploymentSubcategory"
        [options]="userEmploymentSubcategories$ | async"
        [required]="false"
        class="kenjo-ml-10px"
        [readOnly]="true"
        [enableClearButton]="true"
        [readOnly]="!employment.data.userEmploymentContractType"
      ></orgos-input-select>
    </orgos-column-container>
    <orgos-column-container *ngIf="!canEditPast">
      <orgos-column></orgos-column>
      <orgos-column class="kenjo-ml-10px">
        <orgos-column-container centerColumns="true">
          <orgos-column size="0" class="kenjo-font-color-success-00b72e kenjo-font-size-18px kenjo-mr-5px">
            <mat-icon>info_outline</mat-icon>
          </orgos-column>
          <orgos-column>{{miscTranslation.payrollMinDate}}</orgos-column>
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container class="kenjo-mt-20px">
      <orgos-input-select orgosColumn [model]="employment" field="contract" picklist="contract" [required]="true" (validation)="contractValidation = $event" class="kenjo-mr-10px">
        <orgos-input-error *ngIf="contractValidation && contractValidation.getError('required')"> {{dialogTranslation.contractRequired}}</orgos-input-error>
      </orgos-input-select>
      <orgos-input-select orgosColumn [model]="employment" field="type" picklist="employmentType" [required]="true" (validation)="typeValidation = $event" class="kenjo-ml-10px">
        <orgos-input-error *ngIf="typeValidation && typeValidation.getError('required')"> {{dialogTranslation.typeRequired}}</orgos-input-error>
      </orgos-input-select>
    </orgos-column-container>

    <!-- short work details -->
    <orgos-column-container class="kenjo-mt-20px" *ngIf="employment?.data?.type === 'short-work'">
      <orgos-input-number
        orgosColumn
        [model]="employment"
        field="weeklyHoursBeforeShortTime"
        [step]="0.01"
        [required]="true"
        (modelChange)="recalculateRatio()"
        [min]="employment.data.weeklyHoursShortTime? employment.data.weeklyHoursShortTime + 1: 0"
        (validation)="hoursBeforeValidation = $event"
        class="kenjo-mr-10px"
      >
        <orgos-input-error *ngIf="hoursBeforeValidation && hoursBeforeValidation.getError('min')"> {{dialogTranslation.hoursValidation}}</orgos-input-error>
        <orgos-input-error *ngIf="hoursBeforeValidation && hoursBeforeValidation.getError('required')"> {{dialogTranslation.typeRequired}}</orgos-input-error>
      </orgos-input-number>
      <orgos-input-number
        orgosColumn
        [model]="employment"
        field="weeklyHoursShortTime"
        [step]="0.01"
        [required]="true"
        (modelChange)="recalculateRatio()"
        [max]="employment.data.weeklyHoursBeforeShortTime? employment.data.weeklyHoursBeforeShortTime - 1: 99"
        (validation)="hoursAfterValidation = $event"
        class="kenjo-ml-10px"
      >
        <orgos-input-error *ngIf="hoursAfterValidation && hoursAfterValidation.getError('max')"> {{dialogTranslation.hoursValidation}}</orgos-input-error>
        <orgos-input-error *ngIf="hoursAfterValidation && hoursAfterValidation.getError('required')"> {{dialogTranslation.typeRequired}}</orgos-input-error>
      </orgos-input-number>
    </orgos-column-container>
    <orgos-column-container class="kenjo-mt-20px" *ngIf="employment?.data?.type === 'short-work'">
      <orgos-input-number orgosColumn [model]="employment" field="ratioShortTime" readOnly="true" class="kenjo-mr-10px"> </orgos-input-number>
      <orgos-column></orgos-column>
    </orgos-column-container>
    <orgos-column-container class="kenjo-mt-20px" *ngIf="employment?.data?.type === 'short-work'">
      <orgos-input-select orgosColumn [model]="employment" field="sickTimeOffType" [required]="true" [options]="timeOffOptions" (validation)="typeSickValidation = $event" class="kenjo-mr-10px">
        <orgos-input-error *ngIf="typeSickValidation && typeSickValidation.getError('required')"> {{dialogTranslation.typeRequired}}</orgos-input-error>
      </orgos-input-select>
      <orgos-input-select orgosColumn [model]="employment" field="vacationTimeOffType" [required]="true" [options]="timeOffOptions" (validation)="typeVacationValidation = $event" class="kenjo-ml-10px">
        <orgos-input-error *ngIf="typeVacationValidation && typeVacationValidation.getError('required')"> {{dialogTranslation.typeRequired}}</orgos-input-error>
      </orgos-input-select>
    </orgos-column-container>
    <!-- END short work details -->

    <orgos-input-text-area [model]="employment" field="comments" class="kenjo-mt-40px"></orgos-input-text-area>

    <orgos-column-container class="kenjo-mt-60px">
      <orgos-column></orgos-column>
      <orgos-column size="0">
        <orgos-button-raised (click)="closeDialog()" color="Neutral">{{miscTranslation.goBackButtonDialog}} </orgos-button-raised>
      </orgos-column>
      <orgos-column size="0" class="kenjo-ml-20px">
        <orgos-button-raised
          color="Success"
          (click)="addEmployment()"
          [disabled]="!startDateValidation || startDateValidation.hasErrors() ||
                      !contractValidation || contractValidation.hasErrors() ||
                      !typeValidation || typeValidation.hasErrors() ||
                      (employment.data.type === 'short-work' && (!hoursBeforeValidation || hoursBeforeValidation.hasErrors() ||
                                                                  !hoursAfterValidation || hoursAfterValidation.hasErrors() ||
                                                                  !typeSickValidation || typeSickValidation.hasErrors() ||
                                                                  !typeVacationValidation || typeVacationValidation.hasErrors()))"
        >
          {{dialogTranslation.buttonLabel}}</orgos-button-raised
        >
      </orgos-column>
    </orgos-column-container>
  </ng-container>
</orgos-dialog-container>
