import { Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ShiftPlanEmployeeLocations } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-location/shift-plan-employee-locations.component';
import { ShiftPlanEmployeeRoles } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-roles/shift-plan-employee-roles.component';
import { ShiftPlanEmployeeTags } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-tags/shift-plan-employee-tags.component';
import { ShiftPlanEmployeeWorkingAreas } from '@app/cloud-features/shift-plan/components/activate-shift-plan/shift-plan-employee-working-areas/shift-plan-employee-working-areas.component';
import { ACTIVATION_STATUS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-activate-shift-plan-dialog',
  templateUrl: 'activate-shift-plan.dialog.html',
  styleUrls: ['activate-shift-plan.dialog.scss'],
})
export class ActivateShiftPlanDialog implements OnInit {
  peopleDetailPageTranslation: any;
  activateShiftPlanTranslation: any;
  shiftPlanSettingsTranslations: any;
  progressIndex: number = 0;
  FIRST_STEP = {
    name: picklists.SHIFT_PLAN_ACTIVATION_STATUS_LOCATION,
    index: 0,
  };
  userPersonal: GenericSimpleModel;
  userWork: GenericSimpleModel;
  userAccount: GenericSimpleModel;
  reportsToValidation: InputValidation;
  workEmailValidation: InputValidation;
  workPhoneValidation: InputValidation;
  dataLoaded: boolean = false;
  countriesTranslations: any[];
  activateShiftPlanStatusList: Array<string> = ACTIVATION_STATUS;
  activateShiftPlanStatus: string;
  activationIsPossible: boolean;
  locationStepCompleted: boolean;
  workingAreasStepCompleted: boolean;
  rolesStepCompleted: boolean;
  isNotAdmin: boolean = false;
  originalShiftPlanActive: boolean = false;
  dataSaved: boolean = false;

  ACTIVATION_STATUS_NOT_STARTED = picklists.SHIFT_PLAN_ACTIVATION_STATUS_NOT_STARTED;
  ACTIVATION_STATUS_FINISHED = picklists.SHIFT_PLAN_ACTIVATION_STATUS_FINISHED;
  ACTIVATION_STATUS_PERSONAL = picklists.SHIFT_PLAN_ACTIVATION_STATUS_PERSONAL;

  @ViewChild(ShiftPlanEmployeeLocations) locationsComponent: ShiftPlanEmployeeLocations;
  @ViewChild(ShiftPlanEmployeeWorkingAreas) workingAreasComponent: ShiftPlanEmployeeWorkingAreas;
  @ViewChild(ShiftPlanEmployeeRoles) rolesComponent: ShiftPlanEmployeeLocations;
  @ViewChild(ShiftPlanEmployeeTags) tagsComponent: ShiftPlanEmployeeTags;

  constructor(
    public dialogRef: MatLegacyDialogRef<ActivateShiftPlanDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    this.userPersonal = this.data.userPersonal;
    this.peopleDetailPageTranslation = this.data.peopleDetailPageTranslation;
    this.userWork = this.data.userWork;
    this.userAccount = this.data.userAccount;
    this.isNotAdmin = this.data.isNotAdmin;
    this.originalShiftPlanActive = this.userWork.data.shiftPlanActive;
    this.dialogRef.disableClose = true;

    this.activateShiftPlanStatus = check.assigned(this.userWork.data.shiftPlanActivationStatus)
      ? this.userWork.data.shiftPlanActivationStatus
      : this.ACTIVATION_STATUS_NOT_STARTED;
    this.fetchTranslations();
    if (this.activateShiftPlanStatus !== this.ACTIVATION_STATUS_FINISHED) {
      await this.getNextStep();
    } else if (this.activateShiftPlanStatus === this.ACTIVATION_STATUS_FINISHED) {
      this.checkAllMandatoryStepsAreCompleted();
      this.progressIndex = 0;
    }
  }

  async fetchTranslations() {
    const [shiftPlanSettingsTranslations, activateShiftPlanTranslation, countriesTranslations] = await Promise.all([
      this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-page'),
      this.injector.get(InternationalizationService).getAllTranslation('shift-plan-activate-employee-dialog'),
      this.injector.get(InternationalizationService).getAllTranslation('standard-picklists'),
    ]);

    this.shiftPlanSettingsTranslations = shiftPlanSettingsTranslations;
    this.activateShiftPlanTranslation = activateShiftPlanTranslation;
    this.countriesTranslations = countriesTranslations;
  }

  nextStep() {
    this.progressIndex++;
  }

  //Receive the event from the components and change to new step index
  async changeIndex(index: number) {
    this.progressIndex = index;
    this.activateShiftPlanStatus = this.activateShiftPlanStatusList[index];
    this.userWork.data.shiftPlanActivationStatus = this.activateShiftPlanStatus;
    await this.injector
      .get(UserWorkService)
      .updateById(this.userWork.data._id, { shiftPlanActivationStatus: this.activateShiftPlanStatus });
  }

  async getNextStep() {
    const activationStepIndex = this.activateShiftPlanStatusList.findIndex((iStatus) => iStatus === this.activateShiftPlanStatus);
    if (activationStepIndex === -1) {
      this.userWork.data.shiftPlanActivationStatus = this.FIRST_STEP['name'];
      this.activateShiftPlanStatus = this.activateShiftPlanStatusList[this.FIRST_STEP['index']];
      await this.injector
        .get(UserWorkService)
        .updateById(this.userWork.data._id, { shiftPlanActivationStatus: this.activateShiftPlanStatus });
      this.progressIndex = this.FIRST_STEP['index'];
    } else {
      this.progressIndex = activationStepIndex;
    }
  }

  async closeDialog() {
    const data = {
      titleText: this.activateShiftPlanTranslation.confirmTitle,
      subtitleText:
        this.activateShiftPlanStatus !== this.ACTIVATION_STATUS_FINISHED
          ? this.activateShiftPlanTranslation.confirmSubtitle
          : this.activateShiftPlanTranslation.confirmExitNotSave,
      confirmButtonText:
        this.activateShiftPlanStatus !== this.ACTIVATION_STATUS_FINISHED
          ? this.activateShiftPlanTranslation.saveLeaveButton
          : this.activateShiftPlanTranslation.leaveButton,
      cancelButtonText: this.activateShiftPlanTranslation.goBackButton,
      confirmButtonColor: 'Danger',
    };
    //Dialog for leaving without save only appears in activation and in edition when there are changes to save
    if (
      this.activateShiftPlanStatus !== this.ACTIVATION_STATUS_FINISHED ||
      (this.activateShiftPlanStatus === this.ACTIVATION_STATUS_FINISHED &&
        (this.locationsComponent?.dataHasChanged ||
          this.workingAreasComponent?.dataHasChanged ||
          this.rolesComponent?.dataHasChanged ||
          this.tagsComponent?.dataHasChanged))
    ) {
      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: data });
      dialogRef.afterClosed().subscribe(async (confirmClose: boolean) => {
        if (confirmClose) {
          if (this.activateShiftPlanStatus !== this.ACTIVATION_STATUS_FINISHED) {
            this.injector.get(PrivateAmplitudeService).logEvent('employee activation incomplete', {
              category: 'Shiftplan',
              subcategory: 'Settings',
              subcategory2: 'Employee setup',
            });
            this.saveData();
          }
          this.dialogRef.close(true);
        }
      });
    } else {
      if (this.originalShiftPlanActive !== this.userWork.data.shiftPlanActive || this.dataSaved) {
        this.dialogRef.close(true);
        return;
      }

      this.dialogRef.close();
    }
  }

  //Save data depending of the step
  saveData() {
    switch (this.progressIndex) {
      case 0:
        this.locationsComponent.saveData();
        break;
      case 1:
        this.workingAreasComponent.saveData();
        break;
      case 2:
        this.rolesComponent.saveData();
        break;
      case 3:
        this.tagsComponent.saveData();
        break;
    }
  }

  async activateShiftPlan(closeDialog = false) {
    this.activateShiftPlanStatus = this.ACTIVATION_STATUS_FINISHED;
    this.userWork.data.shiftPlanActive = true;
    this.userWork.data.shiftPlanActivationStatus = this.activateShiftPlanStatus;
    this.injector.get(MatLegacySnackBar).open(`${this.peopleDetailPageTranslation.shiftPlanActivationSnackbar}`, 'OK', {
      duration: 5000,
    });
    if (this.userAccount.data.profileKey === 'employee') {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('activate std employee', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Employee setup' });
    }
    await this.injector
      .get(UserWorkService)
      .updateById(this.userWork.data._id, { shiftPlanActive: true, shiftPlanActivationStatus: this.ACTIVATION_STATUS_FINISHED });

    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('activate employee', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Employee setup' });
    this.injector.get(PrivateChurnzeroService).logSimpleEvent('SHIFTPLAN_ACTIVATED_FOR_EMPLOYEE');

    if (closeDialog) {
      this.dialogRef.close(true);
    }
  }

  async changeShiftPlanStatus(status: boolean) {
    if (status) {
      await this.activateShiftPlan();
    } else {
      this.injector.get(MatLegacySnackBar).open(`${this.peopleDetailPageTranslation.shiftPlanDeactivationSnackbar}`, 'OK', {
        duration: 5000,
      });
      this.userWork.data.shiftPlanActive = false;
      await this.injector.get(UserWorkService).updateById(this.userWork.data._id, { shiftPlanActive: false });
    }

    //Refresh routes for custom profiles
    const currentUser = this.injector.get(PrivateAuthenticationService).getLoggedUser();
    if (currentUser.profile?._isStandard === false) {
      this.injector.get(CloudRoutingService).refreshRouter();
    }
  }

  navigateToOtherSteps(index: number) {
    //Check if any of the steps has data not saved and that changes don't delete any mandatory fields (as it only can be unsaved data in one step each)
    if (
      (this.locationsComponent?.dataHasChanged && this.locationStepCompleted) ||
      (this.workingAreasComponent?.dataHasChanged && this.workingAreasStepCompleted) ||
      (this.rolesComponent?.dataHasChanged && this.rolesStepCompleted) ||
      this.tagsComponent?.dataHasChanged
    ) {
      this.navigateDialog(index);
    } else {
      this.progressIndex = index;
    }
  }

  async navigateDialog(index: number) {
    const data = {
      titleText: this.activateShiftPlanTranslation.navigateTitle,
      subtitleText: this.activateShiftPlanTranslation.navigateSubtitle,
      confirmButtonText: this.activateShiftPlanTranslation.saveAndContinueButton,
      cancelButtonText: this.activateShiftPlanTranslation.continueButton,
      confirmButtonColor: 'Success',
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(async (confirmClose: boolean) => {
      if (confirmClose) {
        this.saveData();
      }
      this.progressIndex = index;
    });
  }

  checkAllMandatoryStepsAreCompleted() {
    //check for locations
    this.locationStepCompleted = check.not.emptyArray(this.userWork.data.locations);

    //check for working Areas
    this.workingAreasStepCompleted = check.not.emptyArray(this.userWork.data.workingAreas);

    //check for roles
    this.rolesStepCompleted = check.not.emptyArray(this.userWork.data.rolesStepCompleted);
  }
}
