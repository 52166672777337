import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'input[noNegativeInput]'
})
export class NoNegativeInputDirective {
    @Input('noNegativeInput') noNegativeInput: boolean;

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
        if (!this.noNegativeInput) {
            return; // Do nothing if the flag is false
        }

        const input = event.target as HTMLInputElement;
        const value = input.value;

        // Remove the "-" symbol if present
        input.value = value.replace(/-/g, '');

        // Emit the updated value
        input.dispatchEvent(new Event('input'));
    }
}
