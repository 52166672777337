import { Component, Inject, Injector, Input, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'orgos-change-break-reminder-message-dialog',
  templateUrl: 'change-break-reminder-message.dialog.html',
  styleUrls: ['change-break-reminder-message.dialog.scss'],
})
export class ChangeBreakReminderMessageDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  minutesBeforeReminder: number;
  breakReminderMessage: string;
  breakReminderMessageValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<ChangeBreakReminderMessageDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('change-break-reminder-message-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.breakReminderMessage = this.data.breakReminderMessage;
    this.minutesBeforeReminder = this.data.minutesBeforeReminder;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.breakReminderMessage);
  }
}
