import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { AttendancePolicyService } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { HistoryService } from '../../../services/core/history.service';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { CustomFieldService } from '../../../services/custom-field/custom-field.service';
import { ProfileService } from '../../../services/profile/profile.service';

@Component({
  selector: 'orgos-dialog-people-history',
  templateUrl: 'people-history.dialog.html',
  styleUrls: ['people-history.dialog.scss'],
})
export class PeopleHistoryDialog implements OnInit {
  pageTranslation: any = {};
  displayedColumns: Array<string> = ['field', 'object', 'oldVal', 'newVal', 'updatedDate', 'updatedBy'];
  translationObject: any = {};
  fieldsToSayModifiedOnly: Array<string> = ['user-personal._photo', 'user-personal.bio', 'user-work.emailSignature'];
  fieldsToHide: Array<string> = [
    'user-account.inactiveReason',
    'user-account.pushNotificationsToken',
    'user-work-schedule.overtimeSettings',
    'user-work-schedule.overtimeSettings.pendingToProcess',
    'user-work-schedule.history.$',
  ];
  historyData: Array<any> = [];

  constructor(
    public dialogRef: MatLegacyDialogRef<PeopleHistoryDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private parentInfo: any,
    private injector: Injector,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.fetchData();

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-history-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    // Translations for object history:
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-account-collection')
      .then((pageTranslation) => {
        this.translationObject['user-account'] = pageTranslation;
        this.translationObject['user-account'].mainHeader = this.parentInfo.peopleDetailPageTranslation.userAccountSectionTitle;
      })
      .catch((err) => {
        //  TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-address-collection')
      .then((pageTranslation) => {
        this.translationObject['user-address'] = pageTranslation;
        this.translationObject['user-address'].mainHeader = this.parentInfo.peopleDetailPageTranslation.addressSectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-confidential-collection')
      .then((pageTranslation) => {
        this.translationObject['user-confidential'] = pageTranslation;
        this.translationObject['user-confidential'].mainHeader = this.parentInfo.peopleDetailPageTranslation.confidentialSectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-emergency-collection')
      .then((pageTranslation) => {
        this.translationObject['user-emergency'] = pageTranslation;
        this.translationObject['user-emergency'].mainHeader = this.parentInfo.peopleDetailPageTranslation.emergencySectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-financial-collection')
      .then((pageTranslation) => {
        this.translationObject['user-financial'] = pageTranslation;
        this.translationObject['user-financial'].mainHeader = this.parentInfo.peopleDetailPageTranslation.financeSectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-home-collection')
      .then((pageTranslation) => {
        this.translationObject['user-home'] = pageTranslation;
        this.translationObject['user-home'].mainHeader = this.parentInfo.peopleDetailPageTranslation.homeSectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-personal-collection')
      .then((pageTranslation) => {
        this.translationObject['user-personal'] = pageTranslation;
        this.translationObject['user-personal'].mainHeader = this.parentInfo.peopleDetailPageTranslation.personalSectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-work-collection')
      .then((pageTranslation) => {
        this.translationObject['user-work'] = pageTranslation;
        this.translationObject['user-work'].mainHeader = this.parentInfo.peopleDetailPageTranslation.workSectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-work-schedule-collection')
      .then((pageTranslation) => {
        this.translationObject['user-work-schedule'] = pageTranslation;
        this.translationObject['user-work-schedule'].mainHeader = this.parentInfo.peopleDetailPageTranslation.workScheduleSectionTitle;
      })
      .catch((err) => {
        // TODO: ??
      });

    // Get the custom fields
    this.injector
      .get(CustomFieldService)
      .getUserCustomFields()
      .then((customFields) => {
        // As all the other translations were cached, this result is afer all the other parts of translationObject are set
        customFields.forEach((iCustomField) => {
          this.translationObject[iCustomField.collectionName][iCustomField.fieldApiName] = iCustomField.fieldLabel;
        });
      })
      .catch((err) => {
        // TODO: CustomFields could not be retrieved
      });
  }

  private initProfiles(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(ProfileService)
        .getProfiles()
        .then((listProfiles) => {
          listProfiles.forEach((iProfile) => {
            this.parentInfo.idToName[iProfile._id] = iProfile.name;
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
          // Error already displayed
        });
    });
  }

  private initUsers(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(UserPersonalService)
        .getAllUserPersonal(false)
        .then((allUserPersonal) => {
          allUserPersonal.forEach((iUserPersonal) => {
            this.parentInfo.idToName[iUserPersonal._id] = iUserPersonal.displayName;
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
          // Error already displayed
        });
    });
  }

  private async fetchData(): Promise<void> {
    const userId = this.parentInfo._id;
    const [, , userHistory, appStatus] = await Promise.all([
      this.initProfiles(),
      this.initUsers(),
      this.injector.get(HistoryService).getUserHistory(userId),
      this.injector.get(CloudRoutesService).getAppStatus('attendance'),
    ]);

    const { isActive } = appStatus;
    if (!isActive) {
      this.createHistoryArray(userHistory);
      return;
    }

    const attendancePolicyService = this.injector.get(AttendancePolicyService);
    const attendancePolicies = await attendancePolicyService.find();
    const attendancePoliciesMap = attendancePolicies.reduce((iTotalAttendancePolicies, item) => {
      iTotalAttendancePolicies[item._id] = item.name;
      return iTotalAttendancePolicies;
    }, {});
    userHistory.map((historyItem) => {
      if (historyItem._field === 'attendancePolicy') {
        const oldPolicyName = attendancePoliciesMap[historyItem._oldVal];
        const newPolicyName = attendancePoliciesMap[historyItem._newVal];

        historyItem._oldVal = oldPolicyName;
        historyItem._newVal = newPolicyName;
      }
    });
    this.createHistoryArray(userHistory);
  }

  private parseValue(valueIn: any, collection: string, fieldName: string, isNewVal: boolean): string {
    try {
      if (
        check.assigned(valueIn) &&
        check.nonEmptyString(valueIn) &&
        check.assigned(this.parentInfo.idToName) &&
        check.assigned(this.parentInfo.idToName[valueIn]) &&
        check.nonEmptyString(this.parentInfo.idToName[valueIn])
      ) {
        return this.parentInfo.idToName[valueIn];
      }

      if (check.assigned(valueIn) && isNaN(valueIn) && moment(valueIn, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()) {
        return this.datePipe.transform(valueIn, 'shortDate', 'UTC');
      }

      if (
        check.assigned(collection) &&
        check.nonEmptyString(collection) &&
        check.assigned(fieldName) &&
        check.nonEmptyString(fieldName) &&
        this.fieldsToSayModifiedOnly.includes(`${collection}.${fieldName}`)
      ) {
        if (check.assigned(isNewVal) && check.equal(isNewVal, true)) {
          return this.pageTranslation.updatedText;
        } else {
          return '';
        }
      }

      return valueIn;
    } catch (error) {
      return valueIn;
    }
  }

  private async createHistoryArray(userHistory: Array<any>): Promise<void> {
    const createdDateArray: Array<IHistoryElement> = await this.getCreatedDateArray();

    const userHistoryArray = _.flatMap(userHistory, (iUserHistory: any) => {
      if (check.contains(this.fieldsToHide, `${iUserHistory._collection}.${iUserHistory._field}`)) {
        return [];
      }
      if (check.array(iUserHistory._newVal) || check.array(iUserHistory._oldVal)) {
        const isAreaOrTeam = ['areaIds', 'teamIds'].includes(iUserHistory._field);
        const firstValidItem = iUserHistory._newVal[0] !== undefined ? iUserHistory._oldVal[0] : iUserHistory._newVal[0];
        if (check.string(firstValidItem) || isAreaOrTeam) {
          let updatedByVal = this.parseValue(iUserHistory._updatedById, null, null, null);
          if (this.isMongoDbId(updatedByVal)) {
            updatedByVal = '-';
          }

          const historyElement: IHistoryElement = {
            object: iUserHistory._collection,
            field: iUserHistory._field,
            oldVal: check.array(iUserHistory._oldVal)
              ? iUserHistory._oldVal.map((value) => this.parseValue(value, iUserHistory._collection, iUserHistory._field, false)).join(', ')
              : '-',
            newVal: check.array(iUserHistory._newVal)
              ? iUserHistory._newVal.map((value) => this.parseValue(value, iUserHistory._collection, iUserHistory._field, false)).join(', ')
              : '-',
            updatedDate: iUserHistory._updatedAt,
            updatedBy: updatedByVal,
          };

          return [historyElement];
        }

        const arrayChildElements: Array<IHistoryElement> = [];
        const length = Math.max(iUserHistory._newVal.length, iUserHistory._oldVal.length);
        for (let i = 0; i < length; i++) {
          let rawHistoryObject = {};
          if (check.assigned(iUserHistory._newVal[i])) {
            rawHistoryObject = Array.isArray(iUserHistory._newVal) ? iUserHistory._newVal[i] : iUserHistory._newVal;
          } else {
            rawHistoryObject = Array.isArray(iUserHistory._oldVal) ? iUserHistory._oldVal[i] : iUserHistory._oldVal;
          }

          if (typeof rawHistoryObject === 'string') {
            rawHistoryObject = [rawHistoryObject];
          }
          Object.keys(rawHistoryObject).forEach((iChildKey) => {
            const iChildOldVal =
              check.assigned(iUserHistory._oldVal[i]) && check.assigned(iUserHistory._oldVal[i][iChildKey])
                ? Array.isArray(iUserHistory._oldVal[i])
                  ? iUserHistory._oldVal[i][iChildKey]
                  : iUserHistory._oldVal[i]
                : '';
            const iChildNewVal =
              check.assigned(iUserHistory._newVal[i]) && check.assigned(iUserHistory._newVal[i][iChildKey])
                ? Array.isArray(iUserHistory._newVal[i])
                  ? iUserHistory._newVal[i][iChildKey]
                  : iUserHistory._newVal[i]
                : '';

            if (check.not.equal(fieldConstants.ID, iChildKey) && check.not.equal(iChildOldVal, iChildNewVal)) {
              let updatedByVal = this.parseValue(iUserHistory._updatedById, null, null, null);
              if (this.isMongoDbId(updatedByVal)) {
                updatedByVal = '-';
              }
              const iElement: IHistoryElement = {
                object: iUserHistory._collection,
                field: iUserHistory._field,
                childElement: iUserHistory._field,
                oldVal: this.parseValue(iChildOldVal, null, null, false),
                newVal: this.parseValue(iChildNewVal, null, null, true),
                updatedDate: iUserHistory._updatedAt,
                updatedBy: updatedByVal,
              };
              arrayChildElements.push(iElement);
            }
          });
        }
        return arrayChildElements;
      } else {
        let updatedByVal = this.parseValue(iUserHistory._updatedById, null, null, null);
        if (this.isMongoDbId(updatedByVal)) {
          updatedByVal = '-';
        }

        const historyElement: IHistoryElement = {
          object: iUserHistory._collection,
          field: iUserHistory._field,
          oldVal: this.parseValue(iUserHistory._oldVal, iUserHistory._collection, iUserHistory._field, false),
          newVal: this.parseValue(iUserHistory._newVal, iUserHistory._collection, iUserHistory._field, true),
          updatedDate: iUserHistory._updatedAt,
          updatedBy: updatedByVal,
        };

        return [historyElement];
      }
    });

    userHistoryArray.sort(this.compareByDate.bind(this));

    const fullHistoryArray = userHistoryArray.concat(createdDateArray.reverse());

    this.historyData = fullHistoryArray;
  }

  private isMongoDbId(input: string): boolean {
    const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
    return checkForHexRegExp.test(input);
  }

  private async getCreatedDateArray(): Promise<Array<IHistoryElement>> {
    const usersCollections = [
      'user-account',
      'user-personal',
      'user-work',
      'user-work-schedule',
      'user-address',
      'user-home',
      'user-financial',
      'user-emergency',
      'user-confidential',
    ];
    const processedCollections = await Promise.all(
      usersCollections.map((collection) =>
        this.injector.get(PrivateSecurityService).computePermissions({ ownerId: this.parentInfo._id }, `${collection}.read`)
      )
    );
    const filteredCollections = usersCollections.filter((__, index) => processedCollections[index]);

    const createdDateArray: Array<IHistoryElement> = filteredCollections.map((iUserCollection) => {
      const iResult: IHistoryElement = {
        object: iUserCollection,
        field: this.pageTranslation.createdText,
        oldVal: '-',
        newVal: '-',
        updatedDate: this.parentInfo.userCreatedAt,
        updatedBy: this.parseValue(this.parentInfo.userCreatedById, null, null, null),
      };
      return iResult;
    });

    return createdDateArray;
  }

  private compareByDate(a, b): number {
    const valA = a.updatedDate;
    const valB = b.updatedDate;

    let comparison = 0;
    if (valA < valB) {
      comparison = 1;
    } else if (valA > valB) {
      comparison = -1;
    }
    return comparison;
  }

  getTranslationValue(object: string, field: string, isObjectColumn: boolean): string {
    if (check.assigned(this.translationObject[object][field]) && check.nonEmptyString(this.translationObject[object][field])) {
      return this.translationObject[object][field];
    }
    if (check.assigned(isObjectColumn) && check.equal(true, isObjectColumn)) {
      return object;
    }

    return field;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

export interface IHistoryElement {
  object: string;
  field: string;
  childElement?: string;
  oldVal: any;
  newVal: any;
  updatedDate: Date;
  updatedBy: string;
}

export interface IHistoryChanges {
  _id: string;
  _collection: string;
  _recordId: string;
  _field: string;
  _oldVal: string;
  _newVal: string;
  _createdAt: Date;
  _updatedAt: Date;
  _createdById: string;
  _updatedById: string;
}
