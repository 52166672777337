import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPublicHolidays } from '@app/cloud-features/shift-plan/services/shift-plan-public-holidays.service';
import * as moment from 'moment';

const fadeInOut = trigger('fadeInOutAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
]);

@Component({
  selector: 'kenjo-shift-plan-public-holiday-list',
  templateUrl: 'shift-plan-public-holiday-list.component.html',
  styleUrls: ['shift-plan-public-holiday-list.component.scss'],
  animations: [fadeInOut],
})
export class ShiftPlanPublicHolidayListComponent implements OnInit {
  formattedDate: string;

  @Input() publicHolidays: Array<IPublicHolidays>;
  @Input() date: Date;
  @Input() translations: { publicHolidays: ''; holidayIn: '' };
  @Input() language: string;
  @Input() hasToMove: boolean;

  loaded: boolean = false;
  labelDay: string = '';
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  ngOnInit() {
    const newDay = moment.utc(this.date);
    this.labelDay = newDay.utc().locale(this.language).format('ddd, D MMM').replace(/\./g, '');
  }

  closeDetails() {
    this.close.emit();
  }
}
