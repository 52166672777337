<div *ngIf="pageTranslations" class="fgi-main-container kenjo-cursor-pointer" (click)="showPaywallDialog()">
  <div class="kenjo-mr-5px">
    <mat-icon orgosColumn="0" class="fgi-icon kenjo-font-color-warning-ffc107">
      upgrade
    </mat-icon>
  </div>
  <div class="kenjo-font-size-18px kenjo-font-color-warning-ffc107">
    {{ pageTranslations.upgradeIconText }}
  </div>
</div>
