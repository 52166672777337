import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { ACCESS_TYPE_SURVEY_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class PrivateIntegrationsService {
  private INTEGRATIONS_URL = `${environment.PEOPLE_CLOUD_APP_URL}/controller/integrations`;
  private CHAMELEON_SCRIPT_URL = `https://fast.trychameleon.com/snippet/${environment.CHAMELEON_SECRET_KEY}/snippet.js`;

  private _chameleonData: IChameleonData;

  constructor(private injector: Injector) {}

  async init() {
    try {
      if (this.injector.get(AuthenticationService).getAccessType() === ACCESS_TYPE_SURVEY_ACCESS) {
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const integrations = await this.injector.get(HttpClient).get<Integrations>(`${this.INTEGRATIONS_URL}/`, httpOptions).toPromise();
      await this.initChameleon(integrations.chameleon);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateIntegrationsService.name, 'init');
    }
  }

  private loadScriptChameleon(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const firstScriptElement = document.getElementsByTagName('script')[0];
      const chameleonScript = document.createElement('script');
      chameleonScript.async = true;
      chameleonScript.crossOrigin = 'anonymous';
      chameleonScript.defer = true;
      chameleonScript.src = this.CHAMELEON_SCRIPT_URL;
      chameleonScript.id = 'chameleon_script';
      chameleonScript.type = 'text/javascript';
      firstScriptElement.parentNode.insertBefore(chameleonScript, firstScriptElement);
      chameleonScript.onload = () => {
        resolve();
      };
      chameleonScript.onerror = (event, source, lineno, colno, error) => {
        reject(new Error(`Could not load Chameleon script: ${typeof event === 'string' ? event : ''} ${error?.message}`));
      };
    });
  }

  private async initChameleon(chameleonData: IChameleonData): Promise<void> {
    try {
      this._chameleonData = chameleonData;
      await this.loadScriptChameleon();
      if (chameleonData?.c_freeTrial === true) {
        const userLogged = this.injector.get(AuthenticationService).getLoggedUser();
        window.chmln.identify(userLogged._id, chameleonData);
      }
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateIntegrationsService.name, 'initChameleon');
    }
  }

  trackChameleonEvent(eventId: string) {
    if (this._chameleonData?.c_freeTrial !== true) {
      return;
    }
    if (this._chameleonData?.role !== 'Admin') {
      return;
    }
    window.chmln.track(eventId);
  }
}

interface Integrations {
  chameleon: IChameleonData;
}

interface IChameleonData {
  email: string;
  created: Date;
  role: string;
  c_language: string;
  c_apps: string;
  c_freeTrial: boolean;
  company: {
    uid: string;
    created: Date;
    name: string;
  };
}
