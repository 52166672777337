import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as check from 'check-types';

import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class UserUtilsService {
  constructor(private router: Router, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  navigateToMyProfile(): void {
    if (this.authenticationService.isUserAuthenticated() === false) {
      const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserUtilsService.name, 'navigateToMyProfile');
      this.errorManager.handleRawError(error);
      return;
    }

    const currentUserId = this.authenticationService.getLoggedUser()._id;
    this.router.navigate(['cloud', 'people', currentUserId, 'personal']);
  }

  getYourTeamMembers(allUsers: Array<object>, maxUsers: number): Promise<any> {
    if (this.authenticationService.isUserAuthenticated() === false) {
      const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserUtilsService.name, 'getYourTeamMembers');
      return Promise.reject(this.errorManager.handleRawError(error));
    }

    let listYourTeamMembers = [];
    const currentUserId = this.authenticationService.getLoggedUser()._id;
    const myManagerId = allUsers.filter((iUser: any) => {
      return check.equal(iUser.userAccount._id, currentUserId);
    })[0]['userWork'].reportsToId;

    allUsers.forEach((iUser: any) => {
      if (iUser.userAccount.isActive === true && iUser.userWork._id !== currentUserId && check.assigned(myManagerId) && iUser.userWork._id === myManagerId) {
        listYourTeamMembers.push(iUser);
      } else if (
        (iUser.userAccount.isActive === true && iUser.userWork._id !== currentUserId && check.assigned(iUser.userWork.reportsToId) && iUser.userWork.reportsToId === currentUserId) ||
        (iUser.userAccount.isActive === true && iUser.userWork._id !== currentUserId && check.nonEmptyString(iUser.userWork.reportsToId) && iUser.userWork.reportsToId === myManagerId)
      ) {
        listYourTeamMembers.push(iUser);
      }
    });

    listYourTeamMembers.sort(this.compareByDisplayName.bind(this));

    if (check.assigned(maxUsers) && check.number(maxUsers)) {
      listYourTeamMembers = listYourTeamMembers.slice(0, maxUsers);
    }

    return Promise.resolve(listYourTeamMembers);
  }

  getPeopleToKnow(allUsers: Array<object>, maxUsers: number): Promise<any> {
    let listPeopleToKnow = [];

    listPeopleToKnow = allUsers.filter((iUser: any) => {
      return iUser.userPersonal?.peopleToKnow && iUser.userAccount.isActive === true;
    });

    listPeopleToKnow.sort(this.compareByDisplayName.bind(this));

    if (check.assigned(maxUsers) && check.number(maxUsers)) {
      listPeopleToKnow = listPeopleToKnow.slice(0, maxUsers);
    }

    return Promise.resolve(listPeopleToKnow);
  }

  private compareByDisplayName(a: any, b: any): number {
    // Use toUpperCase() to ignore character casing
    const valA = a.userPersonal.displayName.toUpperCase();
    const valB = b.userPersonal.displayName.toUpperCase();

    let comparison = 0;
    if (valA > valB) {
      comparison = 1;
    } else if (valA < valB) {
      comparison = -1;
    }
    return comparison;
  }
}
