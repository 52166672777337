import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InputValidationFunction } from '@app/standard/core/validation/input-validation-function';
import * as check from 'check-types';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'orgos-input-simple-date-picker-box',
  templateUrl: 'input-simple-date-picker-box.component.html',
  styleUrls: ['input-simple-date-picker-box.component.scss']
})
export class InputSimpleDatePickerBoxComponent implements OnInit, OnDestroy {
  startDate: string;
  isValueValid: boolean = true;
  showClearButton: boolean = false;
  isDatePickerOpened: boolean = false;
  datePickerClosedSubscription: Subscription;
  datePickerOpenedSubscription: Subscription;

  @Input() enableClearButton: boolean = false;
  @Input() enableCalendarButton: boolean = true;
  @Input() min: Date;
  @Input() max: Date;
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() value: any;
  @Input() placeholder: string = '';
  @Input() style: 'short' | 'long' = 'short';
  @Input() customValidation: InputValidationFunction;
  @Input() forceError: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();

  @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<Date>;
  ngOnInit(): void {
    const currentISODate = this.value;
    this.startDate = check.assigned(currentISODate) && check.nonEmptyString(currentISODate) ? this.value : moment().toISOString();

    this.showClearButton = this.enableClearButton && check.assigned(currentISODate);
    this.initDatePickerSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroyDatePickerSubscriptions();
  }

  getValue(): string {
    return this.value;
  }

  onValueChange(newDate: moment.Moment): void {
    this.startDate = check.assigned(newDate) ? newDate.toISOString() : null;

    this.showClearButton = this.enableClearButton && check.assigned(newDate);

    this.value = check.assigned(newDate) ? newDate.toISOString() : null;

    this.makeValidation(this.value);

    if (this.isValueValid) {
      this.valueChange.emit(this.value);
    }
  }

  private makeValidation(isoDateToValidate: string): void {
    const inputValidation = this.validateValue(isoDateToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  protected validateValue(newISODate: string): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newISODate) || check.emptyString(newISODate))) {
      inputValidation.setError('required');
    }

    if (check.assigned(newISODate) && !moment.utc(newISODate).isValid()) {
      inputValidation.setError('date');
    }

    if (this.customValidation) {
      const customInputValidation = this.customValidation(newISODate);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  openDatepicker(): void {
    if (!this.readOnly) {
      this.datepicker.open();
      this.isDatePickerOpened = true;
    }
  }

  initDatePickerSubscriptions() {
    this.datePickerOpenedSubscription = this.datepicker.openedStream.subscribe(() => {
      this.isDatePickerOpened = true;
    });
    this.datePickerClosedSubscription = this.datepicker.closedStream.subscribe(() => {
      this.isDatePickerOpened = false;
    });
  }

  destroyDatePickerSubscriptions() {
    if (this.datePickerOpenedSubscription) {
      this.datePickerOpenedSubscription.unsubscribe();
    }
    if (this.datePickerClosedSubscription) {
      this.datePickerClosedSubscription.unsubscribe();
    }
  }

  clearValue(): void {
    this.onValueChange(null);
  }
}
