import { AfterViewInit, Component, ElementRef, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ApproverTimeOffService } from '@app/cloud-features/time-off/services/approver-time-off.service';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import {
  HolidayDurationType,
  ITimeOffRequestModel,
  PartOfDayFromType,
  PartOfDayToType,
  TimeOffRequestService,
} from '@app/cloud-features/time-off/services/time-off-request.service';
import { ITimeOffTypeModel } from '@app/cloud-features/time-off/services/time-off-type.service';
import { IRequestAttachment } from '@app/cloud-features/time-off/services/time-off-user-history.controller';
import { ITimeOffStatus } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import {
  IPreRequestInfoModel,
  IPreRequestParamsModel,
  ITimeOffHolidayEvent,
  ITimeOffNonWorkingEvent,
  ITimeOffRequestEvent,
  PreRequestConflictType,
  TimeOffUserRequestController,
} from '@app/cloud-features/time-off/services/time-off-user-request.controller';
import { getWeekdayPositionInMonth, isLastWeekdayOfMonth, setWeekDaysLabelPure } from '@app/cloud-features/time-off/time-off.helpers';
import { IRepeatConflict } from '@app/common-components/time-off-user-personal/components/repeat-request-conflict-info/repeat-request-conflict-info.component';
import {
  ITimeOffAttachment,
  ITimeOffAttachmentUpdate,
} from '@app/common-components/time-off-user-personal/components/request-attachments/time-off-request-attachments.component';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateFreshdeskService } from '@app/private/services/private-freshdesk.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { ITimeOffConfigurationModel, PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import {
  IRecurringCalendarEvent,
  ISelectedRange,
  ISingleCalendarEvent,
  SelectedTimeType,
} from '@app/standard/components/input-date-range-calendar-picker/input-date-range-calendar-picker.component';
import { IHourlyRange, IValidationErrors } from '@app/standard/components/input-simple-hourly-range/input-simple-hourly-range.component';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DocumentService, IDocumentModel } from '@app/standard/services/document/document.service';
import {
  ALLOWANCE_TYPE_UNLIMITED,
  CYCLE_TYPE_DISABLED,
  HOLIDAY_DURATION_AFTERNOON,
  HOLIDAY_DURATION_FULL_DAY,
  HOLIDAY_DURATION_MORNING,
  PART_OF_DAY_END,
  PART_OF_DAY_HALF,
  PART_OF_DAY_START,
  POLICY_TYPE_HOUR,
  REPEAT_FREQUENCY_UNIT_MONTH,
  REPEAT_FREQUENCY_UNIT_WEEK,
  REPEAT_MONTH_DAY_OPTION_DATE,
  REPEAT_MONTH_DAY_OPTION_FIRST_OF_MONTH,
  REPEAT_MONTH_DAY_OPTION_FOURTH_OF_MONTH,
  REPEAT_MONTH_DAY_OPTION_LAST_OF_MONTH,
  REPEAT_MONTH_DAY_OPTION_SECOND_OF_MONTH,
  REPEAT_MONTH_DAY_OPTION_THIRD_OF_MONTH,
  TIME_OFF_REQUEST_CONFLICT_EXPIRED_CARRY_OVER,
  TIME_OFF_REQUEST_CONFLICT_MISSING_PAST_CYCLE,
  TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE,
  TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME,
  TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST,
  TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT,
  TIME_OFF_REQUEST_CONFLICT_WORK_SCHEDULE_LIMITS,
  TIME_OFF_REQUEST_STATUS_APPROVED,
  TIME_OFF_REQUEST_STATUS_PROCESSED,
  TIME_OFF_REQUEST_STATUS_SUBMITTED,
  TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER,
  TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_WORKING_PAID,
  TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_NOT_WORKING_PAID
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

const SELECTED_TIME_START = 'start';
const SELECTED_TIME_END = 'end';

@Component({
  selector: 'orgos-submit-time-off-request',
  templateUrl: 'submit-time-off-request.dialog.html',
  styleUrls: ['submit-time-off-request.dialog.scss'],
  providers: [ApproverTimeOffService],
})

export class SubmitTimeOffRequestDialog implements OnInit, AfterViewInit {
  // Constants
  ALLOWANCE_TYPE_UNLIMITED = ALLOWANCE_TYPE_UNLIMITED;
  TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE = TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE;
  TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME = TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME;
  TIME_OFF_REQUEST_CONFLICT_WORK_SCHEDULE_LIMITS = TIME_OFF_REQUEST_CONFLICT_WORK_SCHEDULE_LIMITS;
  TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST = TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST;
  TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT = TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT;
  USER_COLORS: any = userColorConstants;
  partOfDayWhenFromAndToAreSameDay: HolidayDurationType = HOLIDAY_DURATION_FULL_DAY;
  partOfDayFrom: PartOfDayFromType = PART_OF_DAY_START;
  partOfDayTo: PartOfDayToType = PART_OF_DAY_END;
  HOUR_TOGGLE_FULL_DAY = 'FullDay';
  HOUR_TOGGLE_CUSTOM_TIME = 'CustomTime';
  hourToggleSingleDay = this.HOUR_TOGGLE_FULL_DAY;
  REPEAT_MAX_LIMIT_WEEK = 52;
  REPEAT_MAX_LIMIT_MONTH = 12;
  REPEAT_FREQUENCY_UNIT_WEEK = REPEAT_FREQUENCY_UNIT_WEEK;
  DAYS_IN_A_WEEK = 7;
  DAYS_IN_A_MONTH = 30;

  CONFLICT_TRANSLATIONS = {
    [TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST]: 'requestConflictOverlapRequest',
    [TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT]: 'requestConflictOverlapShift',
    [TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME]: 'requestConflictNonWorkingTime',
    [TIME_OFF_REQUEST_CONFLICT_MISSING_PAST_CYCLE]: 'requestConflictMissingPastCycle',
    [TIME_OFF_REQUEST_CONFLICT_EXPIRED_CARRY_OVER]: 'requestConflictExpiredCarryOver',
    [TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE]: 'requestConflictNegativeAvailable',
    [TIME_OFF_REQUEST_CONFLICT_WORK_SCHEDULE_LIMITS]: 'requestConflictWorkScheduleLimits',
  };

  DESCRIPTION_MAX_LENGTH = 1000;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  dialogTranslation: { [key: string]: string } = {};
  singularUnits: string;
  pluralUnits: string;
  holidaysTranslation: any = {};
  requestPermissions: { canDeleteAttachment: boolean; canCreateAttachment: boolean };

  // Param fields
  policy: ITimeOffPolicyModel;
  timeOffType: ITimeOffTypeModel;
  currentStatus: ITimeOffStatus;
  isHourType: boolean = false;
  employeeStartDate: string;
  reportsToId: string;
  isEdit: boolean = false;
  isRequest: boolean = false;
  isOnBehalf: boolean = false;
  oldRequest: ITimeOffRequestModel;

  // Picklist
  partOfDayPicklistTranslation: any = {};
  partOfDaySameDayOptions: Array<ISelectOption> = [];
  partOfDayFromOptions: Array<ISelectOption> = [];
  partOfDayToOptions: Array<ISelectOption> = [];
  hourlySingleDayOptions: Array<ISelectOption> = [];

  // Form fields
  timeOffRequest: GenericCacheModel;
  availableText: string;

  minFromDate: moment.Moment;
  maxToDate: moment.Moment;
  fromAndToAreSameDay: boolean = true;

  // preRequest fields
  conflict: boolean = true;
  conflictType: PreRequestConflictType;
  conflictMessage: string;
  showWarningExpiredCarryOver: boolean;
  newAvailable: number = 0;
  newAvailableAfterExpireCarryOver: number;
  workingTime: number = 0;
  workingTimePastCycle: number;
  pastCycleAvailable: number = 0;
  workingTimeCurrentCycle: number = 0;
  nextCycleWorkingTime: number = 0;
  nextCycleAvailable: number = 0;

  // Edit request fields
  oldRequestData: IRequestData;
  oldWorkingDays: number = 0;
  oldWorkingDaysInCurrentCycle: number = 0;
  oldSplitRequests: Array<any> = [];
  oldHourlyRange: IHourlyRange;
  dailyRange: DateRange<moment.Moment>;

  // Hour Fields
  fromTimeStartTime: number = 0;
  toTimeStartTime: number = 1380;
  fromTimeEndTime: number = 0;
  toTimeEndTime: number = 1440;
  showDifference: boolean = false;
  hourlyRange: IHourlyRange = {
    startTime: undefined,
    endTime: undefined,
    hasErrors: undefined,
  };

  // View fields
  submittingTimeOff: boolean = false;
  isOngoing: boolean = false;
  startDateClicked: boolean = false;
  requestEdited: boolean = false;
  duration: number;

  // Delegated approval
  delegatedApproverName: string;
  hasDescendants: boolean = false;
  hasDelegatedApprover: boolean = false;
  userOnBehalfName: any;

  // Attachments
  oldRequestAttachments: Array<IRequestAttachment>;
  attachmentsError: boolean = false;
  attachments: Array<ITimeOffAttachment> = [];
  attachmentsIdsToDelete: Array<string> = [];

  descriptionValidation: InputValidation;
  startDateValidation: InputValidation;
  endDateValidation: InputValidation;
  repeatsOnEndDateValidation: InputValidation; // single calendar

  singleEvents: Array<ISingleCalendarEvent> = [];
  recurringEvents: Array<IRecurringCalendarEvent> = [];

  loadingPage = true;
  loadingEvents = true;
  eventsRange: DateRange<moment.Moment>;

  // Repeats on config
  repeatsOnEnabled: boolean = false;
  weekDayLabels: Array<string>;
  selectedWeekDays: Array<any>;
  displayOriginalWeekdayError = false;

  frequencyQuantity: number = 1;
  frequencyQuantityValidation: InputValidation;

  frequencyUnit: string = REPEAT_FREQUENCY_UNIT_WEEK;
  frequencyUnitOptions: Array<ISelectOption> = [];
  repeatFrequencyMinLimit: number = 1;

  repeatsOnEndDate: any;
  repeatsMinDate: moment.Moment = moment.utc();
  repeatsMaxDate: moment.Moment = moment.utc();

  // Monthly options
  monthDaySelector: any;
  monthDaySelectorOptions: Array<ISelectOption> = [];
  monthDayValidation: InputValidation;

  repeatConflicts: IRepeatConflict[];

  REPEAT_REQUEST_MONTH_DAY = [
    REPEAT_MONTH_DAY_OPTION_FIRST_OF_MONTH,
    REPEAT_MONTH_DAY_OPTION_SECOND_OF_MONTH,
    REPEAT_MONTH_DAY_OPTION_THIRD_OF_MONTH,
    REPEAT_MONTH_DAY_OPTION_FOURTH_OF_MONTH,
    REPEAT_MONTH_DAY_OPTION_LAST_OF_MONTH,
  ];

  isRepeatConfigurationValid = false;
  isFrequencyQuantityNotValid = false;

  LEGACY_ACTIVITY_TYPES = [
    TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_WORKING_PAID,
    TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_NOT_WORKING_PAID
  ]

  isWorkingDay = false;

  constructor(
    private injector: Injector,
    private dialogRef: MatLegacyDialogRef<SubmitTimeOffRequestDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private approverTimeOffService: ApproverTimeOffService
  ) { }

  ngOnInit(): void {
    // Closes the modal when esc key is pressed
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.closeDialog();
      }
    });
    this.initData();
  }

  ngAfterViewInit(): void {
    this.updateCalendarEvents();
  }

  closeDialog(): void {
    this.injector.get(PrivateAmplitudeService).logEvent(this.isEdit ? 'abandoned timeoff submission' : 'abandoned timeoff editing', {
      category: 'Time off',
      platform: 'Web',
      subcategory1: this.isOnBehalf === true ? 'time off on behalf' : 'time off personal',
    });
    this.dialogRef.close();
  }

  openHelpLink() {
    this.injector.get(PrivateFreshdeskService).openArticle('timeOffRequest');
  }

  async initData(): Promise<void> {
    this.fillInitFields();
    this.initWeekDaySelector();
    await Promise.all([this.initTranslations(), this.initPermissions()]);
    this.initDateFields();
    await this.initRequest();
    this.initPartDayFields();
    this.initViewFields();
    await this.initDelegatedApproval();
    await this.initPicklist();
    this.updateMonthDaySelectorOptions();
    this.loadingPage = false;
  }

  private async initTranslations(): Promise<void> {
    try {
      this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('time-off-submit-request-dialog');
      this.initRepeatOptions();
      this.singularUnits = this.isHourType ? this.dialogTranslation.hour : this.dialogTranslation.day;
      this.pluralUnits = this.isHourType ? this.dialogTranslation.hours : this.dialogTranslation.days;
    } catch {
      this.dialogTranslation = {};
    }
    try {
      this.holidaysTranslation = await this.injector.get(InternationalizationService).getTranslation('standard-picklists', 'holidays');
    } catch {
      this.holidaysTranslation = {};
    }
  }

  private async initPicklist(): Promise<void> {
    try {
      const standardPicklists = await this.injector.get(InternationalizationService).getAllTranslation('standard-picklists');
      let partOfDaySameDayOptions = [
        { name: standardPicklists.partOfDay.sameDay[HOLIDAY_DURATION_FULL_DAY], value: HOLIDAY_DURATION_FULL_DAY },
      ];
      const partOfDayFromOptions = [{ name: standardPicklists.partOfDay.from[PART_OF_DAY_START], value: PART_OF_DAY_START }];
      const partOfDayToOptions = [{ name: standardPicklists.partOfDay.to[PART_OF_DAY_END], value: PART_OF_DAY_END }];
      if (this.policy.allowHalfDays === true) {
        const partOfDaySameDayHalfDayOptions = [
          { name: standardPicklists.partOfDay.sameDay[HOLIDAY_DURATION_MORNING], value: HOLIDAY_DURATION_MORNING },
          { name: standardPicklists.partOfDay.sameDay[HOLIDAY_DURATION_AFTERNOON], value: HOLIDAY_DURATION_AFTERNOON },
        ];
        partOfDaySameDayOptions = [...partOfDaySameDayOptions, ...partOfDaySameDayHalfDayOptions];
        partOfDayFromOptions.push({ name: standardPicklists.partOfDay.from[PART_OF_DAY_HALF], value: PART_OF_DAY_HALF });
        partOfDayToOptions.push({ name: standardPicklists.partOfDay.to[PART_OF_DAY_HALF], value: PART_OF_DAY_HALF });
      }
      this.partOfDaySameDayOptions = partOfDaySameDayOptions;
      this.partOfDayFromOptions = partOfDayFromOptions;
      this.partOfDayToOptions = partOfDayToOptions;
      this.updateHourToggleOptions();
    } catch {
      this.partOfDaySameDayOptions = [];
      this.partOfDayFromOptions = [];
      this.partOfDayToOptions = [];
    }
  }

  private async initPermissions(): Promise<void> {
    try {
      let requestPermissions = this.data.requestPermissions;
      if (check.not.assigned(requestPermissions)) {
        const [canCreateAttachment, canDeleteAttachment] = await Promise.all([
          this.injector.get(PrivateSecurityService).computePermissions({ ownerId: this.data.userId }, 'document.create'),
          this.injector.get(PrivateSecurityService).computePermissions({ ownerId: this.data.userId }, 'document.delete'),
        ]);
        requestPermissions = { canCreateAttachment, canDeleteAttachment };
      }
      this.requestPermissions = requestPermissions;
    } catch {
      this.requestPermissions = { canCreateAttachment: false, canDeleteAttachment: false };
    }
  }

  private fillInitFields(): void {
    this.policy = this.data.policy;
    this.timeOffType = this.data.timeOffType;
    this.currentStatus = this.data.currentStatus;
    this.reportsToId = this.data.reportsToId;
    this.employeeStartDate = this.data.startDate;
    this.isHourType = this.policy._type === POLICY_TYPE_HOUR;
    this.isEdit = this.data.isEdit;
    this.isRequest = this.data.isRequest;
    this.isOnBehalf = this.data.isOnBehalf;
    this.oldRequestAttachments = this.data.oldRequestAttachments;
    this.oldRequest = this.data.oldRequest;
  }

  private initDateFields(): void {
    if (this.policy.cycleType !== CYCLE_TYPE_DISABLED) {
      this.minFromDate = moment.utc(this.currentStatus.cycleStartDate).subtract(1, 'year');
      const maxDate = moment.utc(this.currentStatus.cycleStartDate).add(3, 'years').subtract(1, 'day');
      this.maxToDate = maxDate;
      this.repeatsMaxDate = maxDate;
    } else {
      this.minFromDate = moment.utc().startOf('year').subtract(3, 'year');
      const maxDate = moment.utc().startOf('year').add(3, 'years').subtract(1, 'day');
      this.maxToDate = maxDate;
      this.repeatsMaxDate = maxDate;
    }
    if (check.assigned(this.employeeStartDate) && moment.utc(this.employeeStartDate).startOf('day').isAfter(this.minFromDate, 'day')) {
      this.minFromDate = moment.utc(this.employeeStartDate).startOf('day');
    }
  }

  private async initRequest(): Promise<void> {
    let initialRequestData: IRequestData;
    if (this.isEdit === true) {
      const fromMoment = moment.utc(this.oldRequest.from).startOf('day');
      const toMoment = moment.utc(this.oldRequest.to).startOf('day');
      initialRequestData = {
        _id: this.oldRequest._id,
        _userId: this.oldRequest._userId,
        _policyId: this.oldRequest._policyId,
        from: fromMoment.toISOString(),
        to: toMoment.toISOString(),
        partOfDayFrom: this.oldRequest.partOfDayFrom,
        partOfDayTo: this.oldRequest.partOfDayTo,
        description: this.oldRequest.description,
      };
      this.oldRequestData = { ...initialRequestData };
      this.dailyRange = new DateRange<moment.Moment>(fromMoment, toMoment);
      if (this.isHourType) {
        const isMultiDayRequest = !fromMoment.isSame(toMoment, 'day');
        if (!isMultiDayRequest &&
          check.assigned(this.oldRequest?.from) && check.assigned(this.oldRequest?.to) &&
          check.not.assigned(this.oldRequest.partOfDayFrom) && check.not.assigned(this.oldRequest.partOfDayTo)) {
          this.hourlyRange.startTime = moment.utc(this.oldRequest.from).diff(moment.utc(this.oldRequest.from).startOf('day'), 'minutes');
          this.hourlyRange.endTime = moment.utc(this.oldRequest.to).diff(moment.utc(this.oldRequest.to).startOf('day'), 'minutes');
        }
      }
      this.oldHourlyRange = { ...this.hourlyRange };
    } else {
      initialRequestData = {
        _userId: this.data.userId,
        _policyId: this.policy._id,
        partOfDayFrom: this.isHourType ? undefined : PART_OF_DAY_START,
        partOfDayTo: this.isHourType ? undefined : PART_OF_DAY_END,
        description: '',
      };
    }
    this.timeOffRequest = new GenericCacheModel(this.injector, initialRequestData, TimeOffRequestService, initialRequestData._userId);
    await this.getHourlyFields();
  }

  private initPartDayFields(): void {
    this.fromAndToAreSameDay =
      check.not.assigned(this.timeOffRequest.data.to) ||
      moment.utc(this.timeOffRequest.data.to).isSame(moment.utc(this.timeOffRequest.data.from), 'day');
    this.partOfDayFrom = this.timeOffRequest.data.partOfDayFrom;
    this.partOfDayTo = this.timeOffRequest.data.partOfDayTo;
    if (this.fromAndToAreSameDay === true && this.partOfDayFrom === PART_OF_DAY_HALF) {
      this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_AFTERNOON;
    } else if (this.fromAndToAreSameDay === true && this.partOfDayTo === PART_OF_DAY_HALF) {
      this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_MORNING;
    } else {
      this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_FULL_DAY;
    }
  }

  private initViewFields(): void {
    if (this.isEdit === true) {
      this.calculateIsOngoing();
      this.calculateDuration();
    }
    if (this.isHourType) {
      this.onChangeStartTime();
    } else {
      this.onChangePartOfDay();
    }
  }

  private calculateIsOngoing(): void {
    if (
      [TIME_OFF_REQUEST_STATUS_SUBMITTED, TIME_OFF_REQUEST_STATUS_APPROVED, TIME_OFF_REQUEST_STATUS_PROCESSED].includes(
        this.oldRequest.status
      ) &&
      moment.utc().isSameOrAfter(moment.utc(this.oldRequest.from))
    ) {
      this.isOngoing = true;
    }
  }

  public onChangeStartTime() {
    if (check.assigned(this.timeOffRequest.data._startTime)) {
      this.fromTimeEndTime = this.timeOffRequest.data._startTime;
      this.showDifference = true;
    }

    if (
      check.assigned(this.timeOffRequest.data._startTime) &&
      check.assigned(this.timeOffRequest.data._endTime) &&
      this.timeOffRequest.data._startTime >= this.timeOffRequest.data._endTime
    ) {
      this.timeOffRequest.data._endTime = Number(this.timeOffRequest.data._startTime) + 60;
    }

    this.computeWorkingDaysRequested();
  }

  public onChangePartOfDay(): void {
    if (this.policy.allowHalfDays !== true) {
      this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_FULL_DAY;
      this.partOfDayFrom = PART_OF_DAY_START;
      this.partOfDayTo = PART_OF_DAY_END;
    } else if (this.fromAndToAreSameDay === true) {
      this.partOfDayFrom = this.partOfDayWhenFromAndToAreSameDay === HOLIDAY_DURATION_AFTERNOON ? PART_OF_DAY_HALF : PART_OF_DAY_START;
      this.partOfDayTo = this.partOfDayWhenFromAndToAreSameDay === HOLIDAY_DURATION_MORNING ? PART_OF_DAY_HALF : PART_OF_DAY_END;
    }

    this.timeOffRequest.data.partOfDayFrom = this.partOfDayFrom;
    this.timeOffRequest.data.partOfDayTo = this.partOfDayTo;
    if (this.repeatsOnEnabled) {
      this.repeatsOnEnabled = false;
      this.resetRepeatConfiguration();
    }
    this.computeWorkingDaysRequested();
  }

  private async initDelegatedApproval() {
    try {
      if (this.timeOffType.workTime !== TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER) {
        const timeOffConfiguration: ITimeOffConfigurationModel = await this.injector
          .get(PrivateOrganizationService)
          .getTimeOffConfiguration();
        if (timeOffConfiguration.enableApprovalDelegation === true) {
          const { delegatedApproverName, hasDescendants, hasApprover, userOnBehalfName } = await this.approverTimeOffService.getApprover(
            this.timeOffRequest.data._userId
          );
          this.delegatedApproverName = delegatedApproverName;
          this.hasDescendants = hasDescendants;
          this.hasDelegatedApprover = hasApprover;
          this.userOnBehalfName = userOnBehalfName;
        }
      }
    } catch {
      // Do nothing
    }
  }

  private initWeekDaySelector() {
    const weekDayLabels = this.injector.get(InternationalizationService).getShortTranslatedWeekdays();
    const currentLocale = this.injector.get(InternationalizationService).getLocale();
    this.weekDayLabels = setWeekDaysLabelPure(weekDayLabels, currentLocale);
    this.selectedWeekDays = this.weekDayLabels.map(() => {
      return { value: false, preventToggle: false };
    }); // Initialize all days to not checked
  }

  private initRepeatOptions() {
    this.frequencyUnitOptions = [
      {
        name: this.dialogTranslation.weekUnit,
        value: REPEAT_FREQUENCY_UNIT_WEEK,
      },
      {
        name: this.dialogTranslation.monthUnit,
        value: REPEAT_FREQUENCY_UNIT_MONTH,
      },
    ];
  }

  public updateMonthDaySelectorOptions(): void {
    if (this.isEdit || check.not.assigned(this.timeOffRequest?.data?.from)) {
      return;
    }
    const requestFrom = this.timeOffRequest.data.from;
    const weekOfMonth = getWeekdayPositionInMonth(requestFrom);
    const weekOptions = [this.createSelectOption(this.REPEAT_REQUEST_MONTH_DAY[weekOfMonth - 1], requestFrom.format('dddd'))];
    if (weekOfMonth === 4) {
      const lastWeekOfMonth = isLastWeekdayOfMonth(requestFrom);
      if (lastWeekOfMonth) {
        weekOptions.push(this.createSelectOption(REPEAT_MONTH_DAY_OPTION_LAST_OF_MONTH, requestFrom.format('dddd')));
      }
    }
    this.monthDaySelectorOptions = [...weekOptions, this.createSelectOption(REPEAT_MONTH_DAY_OPTION_DATE, requestFrom.date().toString())];

    this.monthDaySelector = REPEAT_MONTH_DAY_OPTION_DATE;
  }

  private createSelectOption(monthDay: string, date: string) {
    return {
      value: monthDay,
      name: this.injector.get(I18nDataPipe).transform(this.dialogTranslation[`repeatInfoMonth${monthDay}`], { date }),
    };
  }

  private updateWeekDaySelector() {
    this.selectedWeekDays[moment.utc(this.timeOffRequest?.data?.from).isoWeekday() - 1].value = true;
    this.selectedWeekDays[moment.utc(this.timeOffRequest?.data?.from).isoWeekday() - 1].preventToggle = true;
  }

  public checkPreventToggle(weekDay: number): void {
    const originalWeekDay = moment.utc(this.timeOffRequest.data.from).isoWeekday() - 1;
    if (weekDay === originalWeekDay) {
      this.displayOriginalWeekdayError = true;
      setTimeout(() => {
        this.displayOriginalWeekdayError = false;
      }, 3000);
    }
  }

  public changeWeekDay(newValue: boolean, weekDay: number): void {
    if (!this.repeatsOnEnabled || this.selectedWeekDays?.length < 1) {
      return;
    }
    this.selectedWeekDays[moment.utc(this.timeOffRequest?.data?.from).isoWeekday() - 1].value = true;
    this.selectedWeekDays[moment.utc(this.timeOffRequest?.data?.from).isoWeekday() - 1].preventToggle = true;
    const originalWeekDay = moment.utc(this.timeOffRequest.data.from).isoWeekday() - 1;
    if (newValue === false && weekDay === originalWeekDay) {
      this.displayOriginalWeekdayError = true;
      setTimeout(() => {
        this.displayOriginalWeekdayError = false;
      }, 3000);

      return;
    }
    this.selectedWeekDays[weekDay] = { value: newValue };
    this.validateRepeatConfiguration();
    this.computeWorkingDaysRequested();
  }

  private getWeekdaysSelected(): Array<number> {
    return this.selectedWeekDays.reduce((total, current, index) => {
      if (current?.value === true) {
        total.push(index + 1);
      }
      return total;
    }, []);
  }

  public onChangeFrequencyUnit(frequencyUnit: string): void {
    this.frequencyUnit = frequencyUnit;
    this.initRepeatFrequencyLimits();
    this.checkRepeatQuantityError();
    this.validateRepeatConfiguration();
    this.updateMonthDaySelectorOptions();
    this.computeWorkingDaysRequested();
  }

  public onChangeMonthDaySelector() {
    this.validateRepeatConfiguration();
    this.computeWorkingDaysRequested();
  }

  public onChangeRepeatsOnEnd(repeatsOnEndDate) {
    this.repeatsOnEndDate = repeatsOnEndDate;
    this.validateRepeatConfiguration();
    this.computeWorkingDaysRequested();
  }

  public toggleEnableRepeat() {
    this.resetRepeatConfiguration();
    this.validateRepeatConfiguration();
    this.computeWorkingDaysRequested();
    if (this.repeatsOnEnabled) {
      this.injector.get(PrivateAmplitudeService).logEvent('enable repeat requests', {
        category: 'Time off',
        platform: 'Web',
        subcategory1: 'timeoff request',
        subcategory2: this.policy._type,
      });
    }
  }

  private resetRepeatConfiguration() {
    this.initRepeatFrequency();
    this.initWeekdays();
    this.repeatsOnEndDate = undefined;
    this.updateMonthDaySelectorOptions();
  }

  initWeekdays() {
    this.selectedWeekDays.forEach((weekday) => {
      weekday.value = false;
      weekday.preventToggle = false;
    });
    if (this.fromAndToAreSameDay) {
      this.selectedWeekDays[moment.utc(this.timeOffRequest?.data?.from).isoWeekday() - 1].value = true;
      this.selectedWeekDays[moment.utc(this.timeOffRequest?.data?.from).isoWeekday() - 1].preventToggle = true;
    }
  }

  initRepeatFrequency() {
    this.frequencyUnit = REPEAT_FREQUENCY_UNIT_WEEK;
    this.initRepeatFrequencyLimits();
    this.frequencyQuantity = this.repeatFrequencyMinLimit;
  }

  initRepeatFrequencyLimits() {
    if (check.not.assigned(this.timeOffRequest?.data?.from) || check.not.assigned(this.timeOffRequest?.data?.to)) {
      return;
    }
    const dateUnit = this.frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK ? 'week' : 'month';
    this.repeatFrequencyMinLimit = moment.utc(this.timeOffRequest.data.to).diff(this.timeOffRequest.data.from, dateUnit) + 1;
  }

  onChangeFrequencyQuantity() {
    this.validateRepeatConfiguration();
    this.computeWorkingDaysRequested();
  }

  public validateRepeatConfiguration(): void {
    // Quantity/Unit
    if (this.frequencyQuantityValidation?.hasErrors()) {
      this.isRepeatConfigurationValid = false;
      return;
    }

    // weekDays
    if (
      this.fromAndToAreSameDay &&
      this.frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK &&
      this.selectedWeekDays.reduce((total, value) => {
        return total + (value ? 1 : 0);
      }, 0) < 1
    ) {
      this.isRepeatConfigurationValid = false;
      return;
    }

    // monthDay
    if (this.monthDayValidation?.hasErrors()) {
      this.isRepeatConfigurationValid = false;
      return;
    }

    // endDate
    if (
      check.not.assigned(this.repeatsOnEndDate) ||
      check.not.assigned(this.repeatsOnEndDateValidation) ||
      this.repeatsOnEndDateValidation?.hasErrors()
    ) {
      this.isRepeatConfigurationValid = false;
      return;
    }
    this.isRepeatConfigurationValid = true;
  }

  private checkRepeatQuantityError() {
    const inputValidation = new InputValidation();
    if (check.not.assigned(this.frequencyQuantity)) {
      inputValidation.setError('required');
    }
    if (this.frequencyQuantity < this.repeatFrequencyMinLimit) {
      inputValidation.setError('min');
    }
    if (this.frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK && this.frequencyQuantity > this.REPEAT_MAX_LIMIT_WEEK) {
      inputValidation.setError('max');
    }
    if (this.frequencyUnit !== REPEAT_FREQUENCY_UNIT_WEEK && this.frequencyQuantity > this.REPEAT_MAX_LIMIT_MONTH) {
      inputValidation.setError('max');
    }
    this.frequencyQuantityValidation = inputValidation;
  }

  private async updateCalendarEvents(movedTo?: { time: SelectedTimeType; date: moment.Moment }) {
    this.loadingEvents = true;
    const rangeToRequest = this.updateEventsRange(movedTo);
    await this.loadCalendarEvents(rangeToRequest, !movedTo);
    this.loadingEvents = false;
  }

  private updateEventsRange(movedTo?: { time: SelectedTimeType; date: moment.Moment }) {
    let startDate;
    let endDate;
    let rangeToRequestStart;
    let rangeToRequestEnd;
    if (check.not.assigned(this.eventsRange)) {
      const focusDate = this.dailyRange?.start ?? moment.utc();
      startDate = focusDate.clone().startOf('month').subtract(6, 'month');
      endDate = focusDate.clone().startOf('month').add(6, 'month');
      rangeToRequestStart = startDate.clone();
      rangeToRequestEnd = endDate.clone();
    } else if (movedTo.time === SELECTED_TIME_START) {
      startDate = this.eventsRange.start.clone().subtract(6, 'month');
      if (movedTo.date.isBefore(startDate)) {
        startDate = movedTo.date.clone();
      }
      endDate = this.eventsRange.end.clone();
      rangeToRequestStart = startDate.clone();
      rangeToRequestEnd = this.eventsRange.start.clone();
    } else {
      startDate = this.eventsRange.start.clone();
      endDate = this.eventsRange.end.clone().add(6, 'month');
      if (movedTo.date.isAfter(endDate)) {
        endDate = movedTo.date.clone();
      }
      rangeToRequestStart = this.eventsRange.end.clone();
      rangeToRequestEnd = endDate.clone();
    }
    this.eventsRange = new DateRange<moment.Moment>(startDate, endDate);
    return new DateRange<moment.Moment>(rangeToRequestStart, rangeToRequestEnd);
  }

  public async loadCalendarEvents(rangeToRequest: DateRange<moment.Moment>, firstLoad: boolean) {
    const calendarEvents = await this.injector
      .get(TimeOffUserRequestController)
      .getCalendarEvents(this.data.userId, rangeToRequest.start.toDate(), rangeToRequest.end.toDate());
    let newRecurringEvents;
    if (check.nonEmptyArray(calendarEvents?.nonWorkingEvents)) {
      newRecurringEvents = calendarEvents.nonWorkingEvents.map((nonWorkingEvent) => this.buildNonWorkingEvent(nonWorkingEvent));
    } else {
      newRecurringEvents = [];
    }
    let newSingleEvents;
    if (check.nonEmptyArray(calendarEvents?.holidayEvents)) {
      newSingleEvents = calendarEvents.holidayEvents.map((holidayEvent) => this.buildHolidayEvent(holidayEvent));
    } else {
      newSingleEvents = [];
    }
    if (check.nonEmptyArray(calendarEvents?.requestEvents)) {
      newSingleEvents.push(...calendarEvents.requestEvents.map((requestEvent) => this.buildRequestEvent(requestEvent)));
    }
    if (check.assigned(this.employeeStartDate) && firstLoad === true) {
      newSingleEvents.push(this.buildEmployeeStartDateEvent(this.employeeStartDate));
    }
    this.recurringEvents.push(...newRecurringEvents);
    const singleEvents = _.cloneDeep(this.singleEvents);
    singleEvents.push(...newSingleEvents);
    this.singleEvents = singleEvents;
  }

  private buildNonWorkingEvent(nonWorkingEvent: ITimeOffNonWorkingEvent) {
    return {
      name: this.dialogTranslation.nonWorkingDay,
      startDate: nonWorkingEvent.startDate,
      endDate: nonWorkingEvent.endDate,
      daysOfWeek: nonWorkingEvent.daysOfWeek,
      backgroundColor: false,
      fontColor: true,
    };
  }

  private buildHolidayEvent(holidayEvent: ITimeOffHolidayEvent) {
    return {
      name: holidayEvent.name ?? this.holidaysTranslation[holidayEvent.key] ?? this.dialogTranslation.publicHoliday,
      startDate: holidayEvent.date,
      endDate: holidayEvent.date,
      backgroundColor: false,
      fontColor: true,
    };
  }

  private buildRequestEvent(requestEvent: ITimeOffRequestEvent) {
    return {
      name: requestEvent.name,
      startDate: requestEvent.startDate,
      endDate: requestEvent.endDate,
      backgroundColor: true,
      fontColor: false,
    };
  }

  private buildEmployeeStartDateEvent(employeeStartDate: string) {
    return {
      name: this.dialogTranslation.employeeStartDate,
      startDate: moment.utc(employeeStartDate).toDate(),
      endDate: moment.utc(employeeStartDate).toDate(),
      backgroundColor: true,
      fontColor: false,
    };
  }

  public async onChangeDates({ selectedTime, dateRange }: ISelectedRange): Promise<void> {
    this.resetRequestFields();
    this.timeOffRequest.data.from = dateRange.start;
    this.timeOffRequest.data.to = dateRange.end;
    this.calculateDuration();
    this.repeatsMinDate = moment.utc(this.timeOffRequest.data.to).add(1, 'day');
    if (this.repeatsOnEnabled) {
      this.repeatsOnEnabled = false;
      this.resetRepeatConfiguration();
    }
    if (selectedTime === SELECTED_TIME_START) {
      await this.onChangeFromDate();
    } else {
      await this.onChangeToDate();
    }
    if (this.timeOffRequest.data.from?.isSame(this.timeOffRequest.data.to)) {
      this.fromAndToAreSameDay = true;
      this.updateWeekDaySelector();
    }
  }

  public async onChangeFromDate(): Promise<void> {
    this.fromAndToAreSameDay =
      check.not.assigned(this.timeOffRequest.data.to) ||
      moment.utc(this.timeOffRequest.data.to)?.isSame(moment.utc(this.timeOffRequest.data.from), 'day');
    this.resetPartOfDayIfSameDay();
    if (this.isHourType) {
      await this.getHourlyFields();
    }
    this.computeWorkingDaysRequested();
  }

  public async onChangeToDate(): Promise<void> {
    this.fromAndToAreSameDay =
      check.not.assigned(this.timeOffRequest.data.from) ||
      moment.utc(this.timeOffRequest.data.to).isSame(moment.utc(this.timeOffRequest.data.from), 'day');
    this.resetPartOfDayIfSameDay();
    if (this.isHourType) {
      await this.getHourlyFields();
    }
    this.computeWorkingDaysRequested();
  }

  private resetPartOfDayIfSameDay() {
    if (!this.fromAndToAreSameDay) {
      return;
    }
    if (this.isOngoing) {
      if (this.partOfDayFrom === PART_OF_DAY_START) {
        this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_FULL_DAY;
      } else if (this.partOfDayFrom === PART_OF_DAY_HALF) {
        this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_AFTERNOON;
      }
      this.partOfDayTo = PART_OF_DAY_END;
      this.timeOffRequest.data.partOfDayTo = this.partOfDayTo;
    }
    if (this.partOfDayFrom === this.partOfDayTo && !this.isHourType) {
      this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_FULL_DAY;
      this.partOfDayFrom = PART_OF_DAY_START;
      this.partOfDayTo = PART_OF_DAY_END;
      this.timeOffRequest.data.partOfDayFrom = this.partOfDayFrom;
      this.timeOffRequest.data.partOfDayTo = this.partOfDayTo;
    } else if (this.partOfDayFrom === PART_OF_DAY_HALF) {
      this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_AFTERNOON;
    } else if (this.partOfDayTo === PART_OF_DAY_HALF) {
      this.partOfDayWhenFromAndToAreSameDay = HOLIDAY_DURATION_MORNING;
    }
  }

  private async getHourlyFields(): Promise<void> {
    if (!this.timeOffRequest?.data?.from || !this.timeOffRequest?.data?.to || this.isOngoing) {
      return;
    }
    if (!this.isEdit) {
      this.hourlyRange.startTime = undefined;
      this.hourlyRange.endTime = undefined;
    }
    const workScheduleHourLimits = await this.injector.get(TimeOffUserRequestController).getHourlyFields(this.data.userId, moment.utc(this.timeOffRequest.data.from).format('YYYY-MM-DD'), moment.utc(this.timeOffRequest.data.to).format('YYYY-MM-DD'));
    this.isWorkingDay = workScheduleHourLimits.isWorkingDay;
    if (this.isWorkingDay === false && this.policy.countNaturalDays === false) {
      this.conflict = true;
      this.conflictMessage = this.dialogTranslation['requestConflictNonWorkingTimeHour'];
    }
    this.updateHourToggleOptions();

    const editingOriginalDay = !this.isEdit || !moment.utc(this.timeOffRequest.data.from).startOf('day').isSame(moment.utc(this.oldRequest.from).startOf('day'));
    if (workScheduleHourLimits?.startTime && editingOriginalDay) {
      this.hourlyRange.startTime = workScheduleHourLimits.startTime;
    }
    if (workScheduleHourLimits?.endTime && editingOriginalDay) {
      this.hourlyRange.endTime = workScheduleHourLimits.endTime;
    }
  }

  private updateHourToggleOptions() {
    this.hourlySingleDayOptions = [{
      name: this.dialogTranslation.hourToggleSingleCustomTime,
      value: this.HOUR_TOGGLE_CUSTOM_TIME
    }];
    this.hourToggleSingleDay = this.HOUR_TOGGLE_CUSTOM_TIME;
    if ((this.isWorkingDay || this.timeOffRequest.data.partOfDayFrom) && !this.LEGACY_ACTIVITY_TYPES.includes(this.timeOffType.workTime)) {
      this.hourlySingleDayOptions.unshift({
        name: this.dialogTranslation.hourToggleSingleDayFullDay,
        value: this.HOUR_TOGGLE_FULL_DAY
      });
      if (!this.isEdit || this.timeOffRequest.data.partOfDayFrom) {
        this.hourToggleSingleDay = this.HOUR_TOGGLE_FULL_DAY;
      }
    }
  }

  public async onChangeFromDateHour(from: Date) {
    if (check.not.assigned(from)) {
      return;
    }
    this.timeOffRequest.data.from = from;
    this.timeOffRequest.data.to = from;
    await this.getHourlyFields();
    this.computeWorkingDaysRequested();
  }

  public async computeWorkingDaysRequested(): Promise<void> {
    const { _userId, from, to, partOfDayFrom, partOfDayTo } = this.timeOffRequest.data;

    if (
      check.not.assigned(from) ||
      check.not.assigned(to) ||
      this.isRequestDateRangeInvalid() ||
      (this.repeatsOnEnabled && !this.isRepeatConfigurationValid) ||
      (this.isHourType && this.isCustomTimeNotValid())
    ) {
      this.repeatConflicts = undefined;
      return;
    }
    const preRequestParams = this.getPreRequestParams({ _userId, from, to, partOfDayFrom, partOfDayTo });

    let preRequestInfo: IPreRequestInfoModel;
    try {
      preRequestInfo = await this.injector.get(TimeOffUserRequestController).calculatePreRequestInfo(preRequestParams);
    } catch {
      this.repeatConflicts = preRequestInfo?.repeatConflicts;
      return;
    }
    this.conflict = preRequestInfo.conflict;
    this.conflictType = preRequestInfo.conflictType;
    this.repeatConflicts = preRequestInfo?.repeatConflicts;
    if (this.conflict === true) {
      this.updateWarningMessage();
      if (
        this.conflictType === TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE ||
        this.conflictType === TIME_OFF_REQUEST_CONFLICT_EXPIRED_CARRY_OVER ||
        this.conflictType === TIME_OFF_REQUEST_CONFLICT_WORK_SCHEDULE_LIMITS
      ) {
        this.updateRequestFields(preRequestInfo);
      } else {
        this.resetRequestFields();
      }
    } else {
      this.updateRequestFields(preRequestInfo);
      this.resetConflictMessage();
    }
    this.calculateIfEditedRequest();
  }

  private isCustomTimeNotValid() {
    const customHourRangeNotDefined = check.not.assigned(this.hourlyRange?.startTime) || check.not.assigned(this.hourlyRange?.endTime);
    return (check.assigned(this.hourlyRange?.hasErrors) && this.hourlyRange?.hasErrors) || (this.fromAndToAreSameDay && this.hourToggleSingleDay === this.HOUR_TOGGLE_CUSTOM_TIME && customHourRangeNotDefined);
  }

  private updateRequestFields(preRequestInfo: IPreRequestInfoModel) {
    this.newAvailable = preRequestInfo.newAvailable;
    this.newAvailableAfterExpireCarryOver = preRequestInfo.newAvailableAfterExpireCarryOver;
    this.workingTime = preRequestInfo.workingTime;
    this.workingTimePastCycle = preRequestInfo.workingTimePastCycle;
    this.workingTimeCurrentCycle = preRequestInfo.workingTimeCurrentCycle;
    this.pastCycleAvailable = preRequestInfo.pastCycleAvailable;
    this.nextCycleWorkingTime = preRequestInfo.nextCycleWorkingTime;
    this.nextCycleAvailable = preRequestInfo.nextCycleAvailable;
    this.showWarningExpiredCarryOver =
      this.newAvailable !== this.newAvailableAfterExpireCarryOver && this.newAvailableAfterExpireCarryOver < 0;
  }

  private updateWarningMessage() {
    let conflictTranslation = this.CONFLICT_TRANSLATIONS[this.conflictType];
    if (check.not.assigned(conflictTranslation)) {
      return;
    }
    if (this.conflictType === TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME) {
      conflictTranslation = `${conflictTranslation}${this.isHourType ? 'Hour' : 'Day'}`;
    }
    this.conflictMessage = this.dialogTranslation[conflictTranslation];
  }

  private resetRequestFields() {
    this.newAvailable = undefined;
    this.newAvailableAfterExpireCarryOver = undefined;
    this.workingTime = 0;
    this.showWarningExpiredCarryOver = undefined;
  }

  private resetConflictMessage() {
    this.conflictMessage = undefined;
  }

  changeSingleDayToggle() {
    this.resetRequestFields();
    this.computeWorkingDaysRequested();
  }

  private getPreRequestParams({ _userId, from, to, partOfDayFrom, partOfDayTo }): IPreRequestParamsModel {
    const preRequestParams: IPreRequestParamsModel = {
      _policyId: this.policy._id.toString(),
      _userId: _userId,
      from,
      to
    };
    if (this.repeatsOnEnabled && this.isRepeatConfigurationValid) {
      preRequestParams.repeatConfig = {
        frequencyQuantity: this.frequencyQuantity,
        frequencyUnit: this.frequencyUnit,
        endDate: this.repeatsOnEndDate,
        weekdays: this.frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK && this.fromAndToAreSameDay ? this.getWeekdaysSelected() : undefined,
        monthDay: this.frequencyUnit !== REPEAT_FREQUENCY_UNIT_WEEK ? this.monthDaySelector : undefined,
      };
    } else {
      preRequestParams.repeatConfig = undefined;
    }

    if (this.isHourType && this.fromAndToAreSameDay) {
      const { startTime, endTime, partOfDayFrom, partOfDayTo } = this.getHourRequestConfig(from, to);
      preRequestParams.startTime = startTime;
      preRequestParams.endTime = endTime;
      preRequestParams.partOfDayFrom = partOfDayFrom;
      preRequestParams.partOfDayTo = partOfDayTo;
    }

    if (!this.isHourType) {
      preRequestParams.partOfDayFrom = partOfDayFrom;
      preRequestParams.partOfDayTo = partOfDayTo;
    }

    if (this.isEdit) {
      preRequestParams.isEdit = true;
      preRequestParams.requestId = this.oldRequest._id;
    }
    return preRequestParams;
  }

  private getHourRequestConfig(from: string, to: string) {
    const hourRequestConfig = {
      startTime: moment.utc(from).startOf('day').add(this.hourlyRange.startTime, 'minutes').format(),
      endTime: moment.utc(to).startOf('day').add(this.hourlyRange.endTime, 'minutes').format(),
      partOfDayFrom: undefined,
      partOfDayTo: undefined
    }
    if (!this.LEGACY_ACTIVITY_TYPES.includes(this.timeOffType.workTime) && this.hourToggleSingleDay === this.HOUR_TOGGLE_FULL_DAY) {
      return {
        startTime: moment.utc(from).startOf('day').format(),
        endTime: moment.utc(to).endOf('day').format(),
        partOfDayFrom: 'StartOfDay',
        partOfDayTo: 'EndOfDay'
      }
    }
    return hourRequestConfig;
  }

  async submitTimeOffRequest(): Promise<void> {
    if (this.checkIfInvalidForm() === true) {
      return;
    }
    this.submittingTimeOff = true;
    try {
      const requestId = await this.saveRequest();

      if (this.timeOffType.attachment === true) {
        const [{ needAttachmentsUpdate, documentIds }, needAttachmentsDelete] = await Promise.all([
          this.saveDocuments(),
          this.deleteDocuments(),
        ]);
        if (needAttachmentsUpdate === true || needAttachmentsDelete === true) {
          await this.saveRequestAttachments(requestId, documentIds);
        }
      }

      if (this.isRequest) {
        this.injector.get(MatLegacySnackBar).open(this.dialogTranslation.requestSuccessful, 'OK', {
          duration: 5000,
        });
      } else if (!this.isRequest && !this.isOnBehalf) {
        this.injector.get(MatLegacySnackBar).open(this.dialogTranslation.submissionSuccessful, 'OK', {
          duration: 5000,
        });
      } else {
        this.injector.get(MatLegacySnackBar).open(this.dialogTranslation.submissionOnBehalfSuccessful, 'OK', {
          duration: 5000,
        });
      }
      this.dialogRef.close(true);
    } catch {
      this.submittingTimeOff = false;
    }
  }

  public checkIfInvalidForm(): boolean {
    return (
      this.conflict === true ||
      this.submittingTimeOff === true ||
      this.attachmentsError === true ||
      (check.assigned(this.startDateValidation) && !this.startDateValidation.isValid()) ||
      (check.assigned(this.endDateValidation) && !this.endDateValidation.isValid()) ||
      (check.assigned(this.descriptionValidation) && !this.descriptionValidation.isValid()) ||
      (this.isEdit === true && this.requestEdited === false) ||
      (this.isHourType && this.isCustomTimeNotValid()) ||
      (this.repeatsOnEnabled && !this.isRepeatConfigurationValid)
    );
  }

  private isRequestDateRangeInvalid() {
    return (
      (check.assigned(this.startDateValidation) && !this.startDateValidation.isValid()) ||
      (check.assigned(this.endDateValidation) && !this.endDateValidation.isValid())
    );
  }

  public calculateIfEditedRequest() {
    if (!this.isEdit) {
      return;
    }
    const hasNewAttachment = this.attachments.some((attachment) => attachment.isNew === true);
    const editedHourlyRequest =
      this.isHourType &&
      (this.oldHourlyRange?.startTime !== this.hourlyRange?.startTime || this.oldHourlyRange?.endTime !== this.hourlyRange?.endTime);
    this.requestEdited =
      hasNewAttachment === true ||
      check.nonEmptyArray(this.attachmentsIdsToDelete) ||
      JSON.stringify(this.oldRequestData) !== JSON.stringify(this.timeOffRequest.data) ||
      editedHourlyRequest === true;
  }

  private async saveDocuments(): Promise<{ needAttachmentsUpdate: boolean; documentIds: Array<string> }> {
    const documentsToCreate = [];
    const documentsIds = [];
    let needAttachmentsUpdate = false;
    this.attachments.forEach((attachment) => {
      if (attachment.isNew === true) {
        const documentToCreate: IDocumentModel = {
          name: attachment.document._fileName,
          relatedTo: {
            typeRelatedTo: 'User',
            idRelatedTo: this.timeOffRequest.data._userId,
          },
          _file: attachment.document,
          hidden: false,
          managed: true,
        };
        if (check.assigned(this.timeOffType.tags)) {
          documentToCreate.tags = [].concat(this.timeOffType.tags);
        } else {
          documentToCreate.tags = [];
        }
        documentsToCreate.push(documentToCreate);
      } else {
        documentsIds.push(attachment.id);
      }
    });
    let newDocumentIds = [];
    if (documentsToCreate.length > 0) {
      const documentsCreated = await this.injector.get(DocumentService).bulkCreate(documentsToCreate);
      newDocumentIds = documentsCreated.map((documentCreated) => documentCreated._id);
      needAttachmentsUpdate = true;
    }
    return { needAttachmentsUpdate, documentIds: documentsIds.concat(newDocumentIds) };
  }
  private async deleteDocuments(): Promise<boolean> {
    if (this.attachmentsIdsToDelete.length > 0) {
      await this.injector.get(DocumentService).deleteInBulk(this.attachmentsIdsToDelete);
      return true;
    }
    return false;
  }

  private async saveRequestAttachments(requestId: string, createdDocumentIds: Array<string>): Promise<void> {
    await this.injector.get(TimeOffRequestService).updateAttachments(requestId, createdDocumentIds);
  }

  private logSubmitTimeOff() {
    if (check.not.assigned(this.policy?._type) || check.not.assigned(this.timeOffRequest?.data)) {
      return;
    }

    this.injector.get(PrivateAmplitudeService).logEvent('submit time off request', {
      category: 'Time off',
      platform: 'Web',
      subcategory1: this.isOnBehalf === true ? 'time off on behalf' : 'time off personal',
      subcategory2: this.policy._type,
      subcategory3: this.timeOffRequest.data.attachments?.length > 0 ? 'document attached' : 'document not attached',
      requestLength: moment.utc(this.timeOffRequest.data.to).diff(this.timeOffRequest.data.from, 'days') + 1,
      isFullDay: this.isFullDayRequest(),
    });

    if (this.repeatsOnEnabled && this.isRepeatConfigurationValid) {
      this.injector.get(PrivateAmplitudeService).logEvent('create repeat requests', {
        category: 'Time off',
        platform: 'Web',
        subcategory1: 'timeoff request',
        subcategory2: this.policy._type,
        frequency: this.timeOffRequest.data.repeatConfig.frequencyUnit,
        ...(this.timeOffRequest.data.repeatConfig.frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK && {
          weekdays: this.timeOffRequest.data.repeatConfig.weekdays?.length! > 1 ? 'Multiple' : 'Single',
        }),
      });
    }
    this.injector.get(PrivateIntegrationsService).trackChameleonEvent('submit time off request');
  }

  private logEditTimeOff() {
    if (check.not.assigned(this.timeOffRequest?.data) || check.not.assigned(this.policy?._type)) {
      return;
    }

    this.injector.get(PrivateAmplitudeService).logEvent('edit time off request', {
      category: 'Time off',
      platform: 'Web',
      subcategory1: this.isOnBehalf === true ? 'time off on behalf' : 'time off personal',
      subcategory2: this.policy._type,
      subcategory3: this.timeOffRequest.data.attachments?.length > 0 ? 'document attached' : 'document not attached',
      requestLength: moment.utc(this.timeOffRequest.data.to).diff(this.timeOffRequest.data.from, 'days') + 1,
      isFullDay: this.isFullDayRequest(),
    });
  }

  private async saveRequest(): Promise<string> {
    if (this.repeatsOnEnabled && this.isRepeatConfigurationValid) {
      this.timeOffRequest.data.repeatConfig = {
        frequencyQuantity: this.frequencyQuantity,
        frequencyUnit: this.frequencyUnit,
        endDate: this.repeatsOnEndDate,
        weekdays: this.frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK && this.fromAndToAreSameDay ? this.getWeekdaysSelected() : undefined,
        monthDay: this.frequencyUnit !== REPEAT_FREQUENCY_UNIT_WEEK ? this.monthDaySelector : undefined,
      };
    } else {
      this.timeOffRequest.data.repeatConfig = undefined;
    }
    if (this.isHourType && this.fromAndToAreSameDay) {
      const { from, to } = this.timeOffRequest.data;
      const { startTime, endTime, partOfDayFrom, partOfDayTo } = this.getHourRequestConfig(from, to);
      this.timeOffRequest.data.startTime = startTime;
      this.timeOffRequest.data.endTime = endTime;
      this.timeOffRequest.data.partOfDayFrom = partOfDayFrom;
      this.timeOffRequest.data.partOfDayTo = partOfDayTo;
    }

    if (this.isHourType && this.hourToggleSingleDay === this.HOUR_TOGGLE_CUSTOM_TIME) {
      delete this.timeOffRequest.data.partOfDayFrom;
      delete this.timeOffRequest.data.partOfDayTo;
    }

    if (this.isEdit === true) {
      await this.timeOffRequest.updateInServer();
      this.logEditTimeOff();
      return this.timeOffRequest.data._id;
    } else {
      const createdRequest = await this.timeOffRequest.createInServer();
      this.logSubmitTimeOff();
      return createdRequest.data._id;
    }
  }

  public validateHourlyRanges(error: IValidationErrors, inputName: 'start' | 'end', hourlyRange: IHourlyRange, errorMessages: { [key: string]: string }): IValidationErrors {
    if (inputName === SELECTED_TIME_START) {
      if (check.not.number(hourlyRange.startTime)) {
        error.startTimeError = true;
        error.startTimeErrorMessage = errorMessages?.startTimeRequiredMessage;
        error.hasErrors = true;
        return error;
      } else {
        error.startTimeError = false;
        delete error.startTimeErrorMessage;
      }
    }
    if (inputName === SELECTED_TIME_END) {
      if (check.not.number(hourlyRange.endTime)) {
        error.endTimeError = true;
        error.endTimeErrorMessage = errorMessages?.endTimeRequiredMessage;
        error.hasErrors = true;
        return error;
      } else {
        error.endTimeError = false;
        delete error.endTimeErrorMessage;
      }
    }

    if (check.number(hourlyRange.startTime) && check.number(hourlyRange.endTime)) {
      if (hourlyRange.startTime >= hourlyRange.endTime) {
        error.startTimeError = true;
        error.endTimeError = true;
        error.startTimeErrorMessage = errorMessages?.invalidLengthErrorMessage;
      } else {
        error.startTimeError = false;
        error.endTimeError = false;
        delete error.startTimeErrorMessage;
      }
    }
    else if (check.assigned(hourlyRange.startTime) && check.not.assigned(hourlyRange.endTime)) {
      delete error.startTimeErrorMessage;
    }

    error.hasErrors = error.startTimeError || error.endTimeError;
    return error;
  }

  public showOngoingWarning(): void {
    this.startDateClicked = true;
  }

  public updateAttachments({ hasError, attachments, attachmentsIdsToDelete }: ITimeOffAttachmentUpdate): void {
    this.attachmentsError = hasError;
    this.attachments = attachments;
    this.attachmentsIdsToDelete = attachmentsIdsToDelete;
    this.calculateIfEditedRequest();
  }

  public onExpandAttachments() {
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    }, 400);
  }

  private calculateDuration() {
    if (this.timeOffRequest?.data?.from && this.timeOffRequest?.data?.to) {
      this.duration = moment.utc(this.timeOffRequest?.data?.to).diff(this.timeOffRequest?.data?.from, 'day') + 1;
    } else {
      this.duration = undefined;
    }
  }

  private isFullDayRequest() {
    const requestLength = moment.utc(this.timeOffRequest.data.to).diff(this.timeOffRequest.data.from, 'days') + 1;
    if (this.isHourType && requestLength > 1) {
      // multi day hour request are always full day
      return true;
    }
    return this.timeOffRequest.data.partOfDayFrom === 'StartOfDay' && this.timeOffRequest.data.partOfDayTo === 'EndOfDay';
  }
}

interface IRequestData {
  _id?: string;
  _userId: string;
  _policyId: string;
  from?: string;
  to?: string;
  partOfDayFrom: PartOfDayFromType;
  partOfDayTo: PartOfDayToType;
  description: string;
}
