import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IAttendanceCategory } from '@app/cloud-features/settings-attendance/services/attendance-category.service';
import { IAttendanceConflict } from '@app/cloud-features/settings-attendance/services/attendance-conflicts.service';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { isAutoDeductBreakNeeded } from '@app/standard/services/attendance/attendance-helpers';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable()
export class UserAttendanceService implements IGenericService {
  private USER_ATTENDANCE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db`;
  private USER_ATTENDANCE_PERMISSIONS_KEY: string = 'user-attendance';
  private USER_ATTENDANCE_INTERNATIONALIZATION: string = 'user-attendance-collection';
  private SERVICE_NAMES = {
    USER_ATTENDANCE_SERVICE: 'UserAttendanceService',
  };
  private USER_ATTENDANCE_PUNCH_CLOCK = `${environment.PEOPLE_CLOUD_APP_URL}/controller/attendance/punch-clock`;
  private HTTP_ERRORS = {
    OPEN_SHIFT_ERROR: "There's an open shift",
    DELETED_SHIFT_ERROR: 'This entry can not be updated since it has been deleted',
    NO_COMPLETE_ENTRIES_TO_APPROVE: 'There are no complete entries left to approve.',
  };

  constructor(
    private injector: Injector,
    private genericService: GenericService,
    private errorManager: ErrorManagerService,
    private http: HttpClient
  ) {}

  create(data: object): Promise<IUserAttendanceModel> {
    return new Promise<IUserAttendanceModel>((resolve, reject) => {
      this.genericService
        .create(this.USER_ATTENDANCE_URL, data)
        .then((userAttendance: IUserAttendanceModel) => {
          resolve(userAttendance);
        })
        .catch((error) => {
          if (error?.error === this.HTTP_ERRORS.OPEN_SHIFT_ERROR) {
            reject(error);
          } else {
            reject(this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'create'));
          }
        });
    });
  }

  getById(id: string): Promise<IUserAttendanceModel> {
    return new Promise<IUserAttendanceModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_ATTENDANCE_URL, id)
        .then((userAttendance: IUserAttendanceModel) => {
          resolve(userAttendance);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'getById'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IUserAttendanceModel>> {
    return new Promise<Array<IUserAttendanceModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_ATTENDANCE_URL, findQuery)
        .then((userAttendances: Array<IUserAttendanceModel>) => {
          resolve(userAttendances);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_ATTENDANCE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (error.error === this.HTTP_ERRORS.DELETED_SHIFT_ERROR) {
            reject(this.errorManager.forceReloadError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'thereIsDeletedEntry'));
          } else {
            reject(this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'updateById'));
          }
        });
    });
  }

  async deleteById(id: string, _interface: string = picklists.ATTENDANCE_INTERFACE_ATTENDANCE_TAB): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      await this.http.delete(`${this.USER_ATTENDANCE_URL}/${id}/${_interface}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'deleteById');
    }
  }

  approveDay(
    userId: string,
    year: string,
    month: string,
    day: string,
    _interface: string = picklists.ATTENDANCE_INTERFACE_ATTENDANCE_TAB
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const body = {
        _approved: true,
        interface: _interface,
      };
      this.http
        .put(`${this.USER_ATTENDANCE_URL}/${userId}/${year}/${month}/${day}`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'approveDay'));
        });
    });
  }

  async disapproveDay(
    userId: string,
    year: string,
    month: string,
    day: string,
    _interface: string = picklists.ATTENDANCE_INTERFACE_ATTENDANCE_TAB
  ): Promise<void> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    const body = {
      _approved: false,
      interface: _interface,
    };
    try {
      await this.http.put(`${this.USER_ATTENDANCE_URL}/disapprove/${userId}/${year}/${month}/${day}`, body, httpOptions).toPromise();
    } catch (error) {
      this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'disapproveDay');
    }
  }

  approveAllEntries(
    userIds: Array<string>,
    year: number,
    month: number | { startDate: string; endDate: string },
    _interface: string = picklists.ATTENDANCE_INTERFACE_ATTENDANCE_TAB,
    attendanceConflicts: IAttendanceConflict[] = []
  ): Promise<void | boolean> {
    return new Promise<void | boolean>((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const body = {
        userIds: [...userIds],
        year,
        month,
        interface: _interface,
        attendanceConflicts,
      };
      this.http
        .post(`${this.USER_ATTENDANCE_URL}/approve-in-bulk`, body, httpOptions)
        .toPromise()
        .then((allAttendanceEntriesWereComplete?: boolean) => {
          if (check.assigned(allAttendanceEntriesWereComplete)) {
            resolve(allAttendanceEntriesWereComplete);
          } else {
            resolve();
          }
        })
        .catch((error) => {
          const errorKey =
            error.error === this.HTTP_ERRORS.NO_COMPLETE_ENTRIES_TO_APPROVE ? 'approveAllEntriesWithSomeIncomplete' : 'approveAllEntries';
          if (errorKey === 'approveAllEntriesWithSomeIncomplete') {
            return reject(
              this.injector
                .get(ErrorManagerService)
                .displayCustomError(
                  error,
                  this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE,
                  'approveAllEntriesWithSomeIncomplete',
                  ErrorCodes[404]
                )
            );
          }
          reject(this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, errorKey));
        });
    });
  }

  async splitEntries(
    entryToBeSplit: IUserAttendanceModel,
    breakStart?: number
  ): Promise<{ result: string; modifiedEntries: IUserAttendanceModel[]; originalEntries: IUserAttendanceModel[] }> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const body = {
        entryToBeSplit: {
          ...entryToBeSplit,
          interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB,
        },
        breakStart,
      };

      return await this.http
        .post<{ result: string; modifiedEntries: IUserAttendanceModel[]; originalEntries: IUserAttendanceModel[] }>(
          `${this.USER_ATTENDANCE_URL}/split-entries`,
          body,
          httpOptions
        )
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'splitEntries');
    }
  }

  async getUsedCategories(): Promise<Array<string>> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      return await this.http.get<Array<string>>(`${this.USER_ATTENDANCE_URL}/categories-used`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SERVICE_NAMES.USER_ATTENDANCE_SERVICE, 'getUsedCategories');
    }
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_ATTENDANCE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_ATTENDANCE_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_ATTENDANCE_URL);
  }

  /**
   * This method returns the global status of attendance geolocation given an Array of user-attendance
   * The rule is:
   * GREEN: all of them are within radius
   * YELLOW: there is at least one tracked outside the radius
   * GREY: all of them are grey
   */
  getColorForAttendances(userAttendanceDocs: Array<IUserAttendanceModel>): 'GREEN' | 'GREY' | 'YELLOW' {
    if (check.not.assigned(userAttendanceDocs) || check.emptyArray(userAttendanceDocs)) {
      return 'GREY';
    }

    let canBeGreen = true;
    let canBeGrey = true;
    userAttendanceDocs.forEach((iTimeShift: IUserAttendanceModel) => {
      if (canBeGreen || canBeGrey) {
        iTimeShift._changesTracking?.forEach((changeTracking: IAttendanceChangeTracking) => {
          if (
            canBeGreen &&
            (check.not.assigned(changeTracking._geolocation) || !changeTracking._geolocation._isWithinRadius) &&
            changeTracking.fieldChanged !== '_approved'
          ) {
            canBeGreen = false;
          }
          if (
            canBeGrey &&
            check.assigned(changeTracking._geolocation) &&
            check.assigned(changeTracking._geolocation.latitude) &&
            check.assigned(changeTracking._geolocation.longitude)
          ) {
            canBeGrey = false;
          }
        });
      }
    });

    if (canBeGreen === canBeGrey) {
      return 'YELLOW';
    }
    if (canBeGreen === true) {
      return 'GREEN';
    }
    return 'GREY';
  }

  getMaxHoursPerDayAutomationInfo(attendanceEntry: IUserAttendanceModel, newEndTime: number): IAttendanceAutomation {
    return {
      key: picklists.ATTENDANCE_AUTOMATION_MAX_HOURS,
      changes: [
        {
          fieldChanged: 'endTime',
          newValue: newEndTime,
          oldValue: attendanceEntry.endTime,
        },
      ],
    };
  }

  getMaxHoursPerDayFromBreakAutomationInfo(attendanceEntry: IUserAttendanceModel, newEndTime: number): IAttendanceAutomation {
    return {
      key: picklists.ATTENDANCE_AUTOMATION_MAX_HOURS,
      changes: [
        {
          fieldChanged: 'breaks',
          newValue: '',
          oldValue: `${attendanceEntry.endTime}-`,
        },
        {
          fieldChanged: 'endTime',
          newValue: newEndTime,
          oldValue: undefined,
        },
      ],
    };
  }

  public customBehaviors = {
    injectCustomFieldsForClockIn: (attendanceEntry: IUserAttendanceModel) => {
      if (this.customBehaviors.checkOrgHasCustomAttendance()) {
        attendanceEntry.startTimeSec = new Date().getSeconds();
      }
    },

    injectCustomFieldsForClockOut: (attendanceEntry: IUserAttendanceModel) => {
      if (this.customBehaviors.checkOrgHasCustomAttendance()) {
        attendanceEntry.endTimeSec = new Date().getSeconds();
      }
    },

    checkCanCreateCategoryFromShift: () => this.customBehaviors.checkOrgHasCustomAttendance(),
    checkShouldHideBreakButton: () => this.customBehaviors.checkOrgHasCustomAttendance(),
    checkAllowCheckoutBeforeAMinute: () => this.customBehaviors.checkOrgHasCustomAttendance(),

    checkOrgHasCustomAttendance: () => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      return loggedUser?.email?.includes('webhelp.');
    },

    checkOverlappingWithSeconds: (entry: IUserAttendanceModel, otherEntry: IUserAttendanceModel) => {
      if (check.not.assigned(otherEntry.startTime) || check.not.assigned(otherEntry.endTime)) {
        return false;
      }
      const startInsideOtherShift = entry.startTime < otherEntry.endTime && entry.startTime > otherEntry.startTime;
      const endInsideOtherShift = entry.endTime > otherEntry.startTime && entry.endTime < otherEntry.endTime;
      const shiftInsideOtherShift = entry.startTime < otherEntry.startTime && entry.endTime > otherEntry.endTime;
      return startInsideOtherShift || endInsideOtherShift || shiftInsideOtherShift;
    },

    checkOverlappingStartAndEndWithSeconds: (entry: IUserAttendanceModel, otherEntry: IUserAttendanceModel) => {
      if (
        otherEntry?._deleted !== false ||
        otherEntry?._id === entry?._id ||
        check.not.assigned(otherEntry.startTime) ||
        check.not.assigned(otherEntry.endTime)
      ) {
        return false;
      }
      let overlappingStartTime = false;
      let overlappingEndTime = false;

      if (entry.startTime < otherEntry.endTime && entry.startTime > otherEntry.startTime) {
        overlappingStartTime = true;
      }

      if (
        (entry.endTime > otherEntry.startTime && entry.endTime < otherEntry.endTime) ||
        (entry.startTime < otherEntry.startTime && entry.endTime > otherEntry.endTime)
      ) {
        overlappingEndTime = true;
      }

      if (check.not.assigned(entry.endTime) && entry.startTime > otherEntry.startTime && entry.startTime < otherEntry.endTime) {
        overlappingStartTime = true;
        overlappingEndTime = true;
      }

      return overlappingStartTime && overlappingEndTime;
    },
  };

  async getDailyTime(): Promise<{ totalWorkTime: number }> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const date = new Date();
      const currentMinutes = date.getMinutes() + date.getHours() * 60;
      return await this.http
        .post<{ totalWorkTime: number }>(
          `${this.USER_ATTENDANCE_PUNCH_CLOCK}/daily-time`,
          { date: new Date(date).setUTCHours(0, 0, 0, 0), currentMinutes },
          httpOptions
        )
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserAttendanceService.name, 'getDailyTime');
    }
  }

  async autoDeductBreak(userAttendanceToSave: IUserAttendanceModel, attendancePolicy: IAttendancePolicy, profileKey: string) {
    if (isAutoDeductBreakNeeded(userAttendanceToSave, attendancePolicy, profileKey)) {
      await this.updateById(userAttendanceToSave?._id, {
        ...userAttendanceToSave,
        autoDeductBreak: true,
      });
    }
  }

  async canUpdateAttendance(_updatedAt: Date, _id: string): Promise<boolean> {
    return (await this.find({ _updatedAt, _id })).length > 0;
  }
}

export interface IUserAttendanceModel {
  _id?: string;
  _userId?: string;
  ownerId?: string;
  date?: Date;
  startTime?: number;
  startTimeSec?: number;
  endTime?: number;
  endTimeSec?: number;
  startBreakTime?: number;
  breakTime?: number;
  comment?: string;
  _deleted?: boolean;
  _approved?: boolean;
  _timeOffRequestId?: string;
  _allowanceType?: string;
  _changesTracking?: Array<IAttendanceChangeTracking>;
  attendanceCategoryId?: string;
  attendanceSubCategoryId?: string;
  breaks?: Array<IBreak>;
  automation?: IAttendanceAutomation;
  category?: IAttendanceCategory;
  timeOffEntryInfo?: ITimeOffEntry;
  fullDayTimeOff?: boolean;
  _updatedAt?: Date;
}

export interface IBreak {
  _id?: string;
  start?: number;
  end?: number;
  duration?: number;
  triggeredAfter?: number;
  suggestedBreak?: number;
  autoDeducted?: boolean;
  conflicts?: {
    overlappingStart?: boolean;
    overlappingEnd?: boolean;
    outOfShiftStart?: boolean;
    outOfShiftEnd?: boolean;
    sameStartAndEnd?: boolean;
    isFutureBreak?: boolean;
    isOpenBreak?: boolean;
  };
}

export interface IUserAttendanceMainInfo extends IUserAttendanceModel {
  main?: boolean;
}

export interface IAttendanceGeoLocation {
  accuracy?: number; // Accuracy of the location expressed in meters
  latitude?: number;
  longitude?: number;
  address?: string;
  _isWithinRadius?: boolean; // True if this was logged within the radius expected
  _distanceToTarget?: number; // Distance to target in meters
  _targetName?: string; // Informed only if there is a valid location related to it
  _targetAddress?: string; // Informed only if there is a valid location related to it
  _targetId?: string; // Informed only if there is a valid location related to it
  _targetLongitude?: string;
  _targetLatitude?: string;
}

export interface IAttendanceChangeTracking {
  _geolocation?: IAttendanceGeoLocation;
  operationType?: 'Update' | 'Create';
  fieldChanged?: string;
  oldValue?: string;
  newValue?: string;
  _createdAt?: Date;
  _createdById?: string;

  // Used for break changes only in the front
  newStart?: string;
  newEnd?: string;
  oldStart?: string;
  oldEnd?: string;
}

export interface IAttendanceAutomation {
  key: string;
  changes: {
    fieldChanged: string;
    oldValue: number | string;
    newValue: number | string;
  }[];
}

export interface ITimeOffEntry {
  _timeOffTypeName: string;
  _timeOffTypeColor: string;
  _policyType: string;
  duration: string | number;
  from?: string;
  to?: string;
  _timeOffTypeId?: string;
  _workTime?: string;
}
