import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as check from 'check-types';
import * as moment from 'moment';

import { InputValidation } from '../../core/validation/input-validation';
import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-date-picker',
  templateUrl: 'input-date-picker.component.html',
  styleUrls: ['input-date-picker.component.scss']
})
export class InputDatePickerComponent extends InputAbstractComponent implements OnInit {
  startDate: string;

  public showClearButton: boolean = false;

  @Input() enableClearButton: boolean = false;

  @Input() leftDot = false;

  @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<Date>;

  ngOnInit(): void {
    const currentISODate = super.getValue();
    if (currentISODate && check.nonEmptyString(currentISODate)) {
      this.startDate = currentISODate;
    } else if (!isNaN(currentISODate) && currentISODate instanceof Date) {
      this.startDate = currentISODate.toISOString();
    } else {
      this.startDate = moment().toISOString();
    }

    this.showClearButton = this.enableClearButton && check.assigned(currentISODate);

    super.ngOnInit();
  }

  setValue(newDate: moment.Moment): void {
    this.startDate = check.assigned(newDate) ? newDate.toISOString() : moment().toISOString();

    this.showClearButton = this.enableClearButton && check.assigned(newDate);

    const finalDate = check.assigned(newDate) ? newDate.toISOString() : null;
    super.setValue(finalDate);
  }

  protected validateValue(newISODate: string): InputValidation {
    const inputValidation = super.validateValue(newISODate);

    if (check.assigned(newISODate) && !moment.utc(newISODate).isValid()) {
      inputValidation.setError('date');
    }

    return inputValidation;
  }

  openDatepicker(): void {
    if (this.readOnly === false) {
      this.datepicker.open();
    }
  }
}
