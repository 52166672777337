<orgos-column-container>
  <orgos-column [size]="getValueInteger()">
    <orgos-column-container class="kenjo-pt-10px kenjo-pb-10px" [style.background-color]="currentRange.color" centerColumns="true">
      <orgos-column size="0" class="kenjo-pl-40px kenjo-pr-20px">
        <div class="fs-score-circle kenjo-border">
          <div class="kenjo-pt-15px kenjo-font-size-30px">
            {{ getStringValue() }}
          </div>
        </div>
      </orgos-column>
      <orgos-column size="0">
        <div class="kenjo-color-white-background-ffffff kenjo-text-align-left kenjo-border kenjo-border-radius kenjo-pv-5px kenjo-ph-20px">
          {{ lastMonthTranslation }}
        </div>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
  </orgos-column>
  <orgos-column [size]="getColumnEmptySize()"></orgos-column>
</orgos-column-container>
