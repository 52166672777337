import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private NOTIFICATION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-notification-db`;
  private PREFERENCE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-preference-db`;
  private PREFERENCE_KEY: string = 'lastAccessToNotifications';
  private NOTIFICATION_SERVICE: string = 'NotificationService';

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private authenticationService: AuthenticationService,
    private errorManager: ErrorManagerService
  ) {}

  getMyActiveNotifications(): Promise<{ allMyActivityNotifications: Array<INotificationModel> }> {
    return new Promise<{ allMyActivityNotifications: Array<INotificationModel> }>(
      (resolve, reject) => {
        if (this.authenticationService.isUserAuthenticated() === false) {
          const error = new OrgosError(
            undefined,
            ErrorCodes.UNAUTHORIZED,
            this.NOTIFICATION_SERVICE,
            'getMyActiveNotifications'
          );
          reject(this.errorManager.handleRawError(error));
          return;
        }

        const httpHeaders = new HttpHeaders().set(
          'Authorization',
          this.authenticationService.getAuthorizationHeader()
        );

        const httpOptions = {
          headers: httpHeaders,
        };

        this.http
          .get<Array<INotificationModel>>(
            `${this.NOTIFICATION_URL}/my-active-notifications`,
            httpOptions
          )
          .toPromise()
          .then((activity: Array<INotificationModel>) => {
            resolve({ allMyActivityNotifications: activity });
          })
          .catch((error) => {
            reject(
              this.errorManager.handleRawErrorSilently(
                error,
                this.NOTIFICATION_SERVICE,
                'getMyActiveNotifications'
              )
            );
          });
      }
    );
  }

  getMyArchivedNotifications(): Promise<Array<INotificationModel>> {
    return new Promise<Array<INotificationModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(
          undefined,
          ErrorCodes.UNAUTHORIZED,
          this.NOTIFICATION_SERVICE,
          'getMyArchivedNotifications'
        );
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.authenticationService.getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .get<Array<INotificationModel>>(
          `${this.NOTIFICATION_URL}/my-archived-notifications`,
          httpOptions
        )
        .toPromise()
        .then((activity: Array<INotificationModel>) => {
          resolve(activity);
        })
        .catch((error) => {
          reject(
            this.errorManager.handleRawErrorSilently(
              error,
              this.NOTIFICATION_SERVICE,
              'getMyArchivedNotifications'
            )
          );
        });
    });
  }

  updateMyNotification(id: string, isArchived: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(
          undefined,
          ErrorCodes.UNAUTHORIZED,
          this.NOTIFICATION_SERVICE,
          'updateMyNotification'
        );
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const body = {
        isArchived: isArchived,
      };
      this.http
        .put(`${this.NOTIFICATION_URL}/${id}`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(
            this.errorManager.handleRawError(
              error,
              this.NOTIFICATION_SERVICE,
              'updateMyNotification'
            )
          );
        });
    });
  }

  archiveAllMyNotifications(createdAt: Date): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(
          undefined,
          ErrorCodes.UNAUTHORIZED,
          this.NOTIFICATION_SERVICE,
          'archiveAllMyNotifications'
        );
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const body = {
        _createdAt: createdAt,
      };
      this.http
        .put(`${this.NOTIFICATION_URL}/archive-all-my-notifications`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(
            this.errorManager.handleRawError(
              error,
              this.NOTIFICATION_SERVICE,
              'archiveAllMyNotifications'
            )
          );
        });
    });
  }

  setLastAccessToNotifications(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(
          undefined,
          ErrorCodes.UNAUTHORIZED,
          this.NOTIFICATION_SERVICE,
          'setLastAccessToNotifications'
        );
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const body = {
        preference: moment(),
      };

      this.http
        .put(`${this.PREFERENCE_URL}/${this.PREFERENCE_KEY}`, body, httpOptions)
        .toPromise()
        .then(() => {
          this.injector.get(CloudRoutesService).forceCheckIfNewNotifications();
          resolve();
        })
        .catch((error) => {
          reject(
            this.errorManager.handleRawError(
              error,
              this.NOTIFICATION_SERVICE,
              'setLastAccessToNotifications'
            )
          );
        });
    });
  }

  getLastAccessToNotifications(): Promise<moment.Moment | null> {
    return new Promise<moment.Moment | null>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(
          undefined,
          ErrorCodes.UNAUTHORIZED,
          this.NOTIFICATION_SERVICE,
          'getLastAccessToNotifications'
        );
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .get(`${this.PREFERENCE_URL}/${this.PREFERENCE_KEY}`, httpOptions)
        .toPromise()
        .then((lastAccessToNotificationPreference: any) => {
          resolve(moment(lastAccessToNotificationPreference.preference));
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  checkIfNewNotifications(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Promise.all([this.getMyActiveNotifications(), this.getLastAccessToNotifications()])
        .then((results: any) => {
          if (
            check.not.assigned(results[0]) ||
            check.not.array(results[0]) ||
            check.emptyArray(results[0])
          ) {
            resolve(false);
            return;
          }

          if (check.not.assigned(results[1])) {
            resolve(true);
            return;
          }

          const lastNotification: INotificationModel = _.maxBy<INotificationModel>(
            results[0],
            '_createdAt'
          );

          resolve(moment(lastNotification._createdAt).isAfter(results[1]));
        })
        .catch((error) => {
          reject(
            this.errorManager.handleRawErrorSilently(
              error,
              this.NOTIFICATION_SERVICE,
              'checkIfNewNotifications'
            )
          );
        });
    });
  }
}
export interface INotificationModel {
  _id?: string;
  _type?: string;
  _notification?: any;
  _userId?: string;
  _createdAt?: Date;
  _createdById?: string;
  isArchived?: boolean;
  showNotification?: boolean;
}
