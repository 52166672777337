<div class="pcc-container">
  <div class="pcc-left kenjo-ph-30px kenjo-pv-30px">
    <div class="kenjo-font-color-light-text-757575 kenjo-font-size-16px">
      {{ translations.howManyPeople }}
    </div>

    <div class="pcc-number-employees kenjo-mt-20px">
      <div class="kenjo-border kenjo-inline-block kenjo-pv-5px kenjo-ph-15px kenjo-text-align-center">
        {{ translations.employeesNumber | i18nData: { employeesNumber: slider.displayValue } }}
      </div>
    </div>

    <div class="pcc-slider-container">
      <div class="pcc-compound-slider" [class.pcc-custom-border-radius]="minEmployees > 1">
        <div
          *ngIf="minEmployees > 1"
          class="pcc-fake-slider kenjo-cursor-pointer"
          [style.width]="fakeSliderWidth + '%'"
          [matTooltip]="isInTrial ? translations.freeTrialMinimum : translations.customerMinimum"
          matTooltipPosition="above"
        ></div>
        <mat-slider
          #slider
          [min]="minEmployees"
          max="300"
          step="1"
          [(value)]="employees"
          (input)="computePrice($event)"
          (valueChange)="onEmployeesChange()"
          class="kenjo-block"
        ></mat-slider>
      </div>
      <div class="pcc-range-labels">
        <div class="kenjo-font-size-16px">1</div>
        <div class="kenjo-font-size-16px">300+</div>
      </div>
    </div>

    <div class="kenjo-mt-40px">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-16px">
        {{ translations.choosePlan }}
      </div>
      <orgos-input-simple-radio
        class="kenjo-mt-20px"
        [options]="planOptions"
        [value]="plan"
        (valueChange)="plan = $event; computePrice(); computeShowContactUs($event)"
        [inline]="true"
      ></orgos-input-simple-radio>
    </div>

    <div class="kenjo-mt-40px">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-16px">
        {{ translations.extraAddon }}
      </div>
      <orgos-input-simple-checkbox
        class="kenjo-mt-20px"
        [value]="activateRecruitingAddon"
        (valueChange)="activateRecruitingAddon = $event; computePrice(); onAddRecruitingAddon($event)"
        [label]="translations.recruitmentAddonLabel"
      ></orgos-input-simple-checkbox>

      <div
        *ngIf="activateRecruitingAddon"
        class="
          pcc-recruitment-addon-selector
          kenjo-color-grey-background-lighter-f8f8f8 kenjo-mt-10px kenjo-pv-15px kenjo-ph-20px kenjo-ml-20px
        "
      >
        <div class="kenjo-cursor-pointer" (click)="onChangeRecruitingAddon('recruiting')">
          <mat-radio-button [checked]="currentRecruitingAddon === 'recruiting'"></mat-radio-button>
          <div>
            <div class="kenjo-font-weight-bold kenjo-font-size-12px">
              {{ translations.recruitment | i18nData: { price: tentativeRecruitingAddonPrice } }}
            </div>
            <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px kenjo-font-size-12px">
              {{ translations.recruitmentDescription }}
            </div>
          </div>
        </div>
        <div class="kenjo-cursor-pointer" (click)="onChangeRecruitingAddon('recruitingPlus')">
          <mat-radio-button [checked]="currentRecruitingAddon === 'recruitingPlus'"></mat-radio-button>
          <div>
            <div class="kenjo-font-weight-bold kenjo-font-size-12px">
              {{ translations.recruitmentPlus | i18nData: { price: tentativeRecruitingPlusAddonPrice } }}
            </div>
            <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px kenjo-font-size-12px">
              {{ translations.recruitmentPlusDescription }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <orgos-input-simple-checkbox
          class="kenjo-mt-20px"
          [value]="includeOnboardingAddon"
          (valueChange)="onAddOnboardingAddon($event)"
          [label]="translations.onboardingAddon | i18nData: { price: tentativeOnboardingPrice }"
        ></orgos-input-simple-checkbox>
        <div class="pcc-datev-description kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ translations.onboardingAddonDescription }}
        </div>
      </div>

      <div *ngIf="showDatevImplementation">
        <orgos-input-simple-checkbox
          class="kenjo-mt-20px"
          [value]="includeDatevImplementation"
          (valueChange)="onAddDatevImplementation($event)"
          [label]="translations.datevIntegration | i18nData: { price: tentativeDatevPrice }"
        ></orgos-input-simple-checkbox>
        <div class="pcc-datev-description kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ translations.datevIntegrationDescription }}
        </div>
      </div>
    </div>
  </div>

  <div class="pcc-right kenjo-ph-20px kenjo-pv-30px">
    <div class="pcc-price kenjo-font-color-text-000000">{{ totalPrice }}</div>
    <div class="kenjo-font-color-light-text-757575">
      {{ showYearly ? translations.perMonthPaidYearly : translations.perMonthPaidMonthly }}
    </div>

    <div class="pcc-time-container kenjo-mt-15px">
      <div class="pcc-time">
        <div class="pcc-discount kenjo-font-color-white-ffffff kenjo-font-weight-bold">{{ translations.save }} 10%</div>

        <mat-button-toggle-group
          #toggleGroup="matButtonToggleGroup"
          [value]="showYearly ? 'yearly' : 'monthly'"
          class="pcc-time-options"
          (change)="changeYearly($event); computePrice(); computeShowContactUs($event)"
        >
          <mat-button-toggle class="pcc-time-option" [class.kenjo-font-color-light-text-757575]="!showYearly" value="yearly">
            {{ translations.yearlyOption }}
          </mat-button-toggle>
          <mat-button-toggle class="pcc-time-option" [class.kenjo-font-color-light-text-757575]="showYearly" value="monthly">
            {{ translations.monthlyOption }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="pcc-breakdown kenjo-mt-40px">
      <div class="kenjo-font-weight-bold">{{ translations.subscription }}</div>
      <div class="kenjo-text-align-right kenjo-font-size-12px kenjo-font-color-light-text-757575">
        {{ translations.perMonth }}
      </div>
      <div>
        {{ translations.planAndEmployees | i18nData: { planName: translations.plans[plan].title, employeesNumber: slider.displayValue } }}
        <span class="kenjo-font-color-success-00b72e kenjo-font-weight-bold">{{ showYearly ? ' -10%' : '' }}</span>
      </div>
      <div class="kenjo-text-align-right">{{ planPrice }}</div>
      <div *ngIf="activateRecruitingAddon">
        {{ translations.recruitmentAddonLabel }}
        <span class="kenjo-font-color-success-00b72e kenjo-font-weight-bold">{{ showYearly ? ' -10%' : '' }}</span>
      </div>
      <div *ngIf="activateRecruitingAddon" class="kenjo-text-align-right">{{ addonPrice }}</div>
    </div>

    <div *ngIf="showYearly" class="pcc-discount-box kenjo-font-size-12px kenjo-font-color-success-00b72e">
      <div class="kenjo-p-10px">{{ translations.discountApplied | i18nData: { discount } }}</div>
    </div>

    <div *ngIf="includeDatevImplementation || includeOnboardingAddon" class="pcc-breakdown kenjo-mt-40px">
      <div class="pcc-one-time-title">
        <div class="kenjo-font-weight-bold kenjo-mr-10px">{{ translations.oneTimePayment }}</div>
        <kenjo-icon
          [size]="20"
          class="kenjo-font-color-success-00b72e kenjo-cursor-pointer"
          [matTooltip]="translations.oneTimePaymentTooltip"
          matTooltipPosition="above"
          >info_outline</kenjo-icon
        >
      </div>
      <div></div>
      <div *ngIf="includeOnboardingAddon">{{ translations.guidedOnboarding }}</div>
      <div *ngIf="includeOnboardingAddon" class="kenjo-text-align-right">{{ onboardingPrice }}</div>
      <div *ngIf="includeDatevImplementation">{{ translations.datevImplementation }}</div>
      <div *ngIf="includeDatevImplementation" class="kenjo-text-align-right">{{ datevPrice }}</div>
    </div>

    <div class="kenjo-mt-40px">
      <orgos-button-raised *ngIf="!showContactUs" color="Upgrade" (click)="onOpenCheckout()">{{
        translations.upgradeNow
      }}</orgos-button-raised>
      <orgos-button-raised *ngIf="showContactUs" (click)="onContactUs()">{{ translations.contactUs }}</orgos-button-raised>
    </div>
  </div>
</div>
