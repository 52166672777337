import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as check from 'check-types';

import { GenericCacheModel } from '../../../core/generic-cache-model';
import { InputValidation } from '../../../core/validation/input-validation';
import { StandardServicesRegistry } from '../../../services/standard-services.registry';

@Component({
  selector: 'orgos-create-department-dialog',
  templateUrl: 'create-department.dialog.html',
  styleUrls: ['create-department.dialog.scss'],
})
export class CreateDepartmentDialog implements OnInit {
  dialogTranslation: any = {};

  department: GenericCacheModel;

  departmentNameValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<CreateDepartmentDialog>,
    private injector: Injector,
    private standardServicesRegistry: StandardServicesRegistry
  ) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('create-department-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    const departmentServiceClass = this.standardServicesRegistry.getService('Department');
    this.department = new GenericCacheModel(this.injector, { name: '' }, departmentServiceClass, '');
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addDepartment(): void {
    if (!this.departmentNameValidation || this.departmentNameValidation.hasErrors()) {
      return;
    }

    this.dialogRef.close(this.department.data);
  }
}
