<orgos-dialog-container *ngIf="pageTranslation" class="psd-dialog-box" [title]="publishTitle" [disabledCloseButton]="isPublishing" (clickCloseButton)="closeDialog()">
  <orgos-column-container *ngIf="filtersApplied" class="kenjo-p-10px psd-error-area kenjo-mb-30px" [centerColumns]="true">
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
    <div class="kenjo-ml-10px" orgosColumn>{{ pageTranslation.filtersApplied }}</div>
  </orgos-column-container>
  <ng-container>
    <div class="kenjo-mt-20px" [class.psd-disabled]="shiftsData.openShiftsCount === 0">
      <mat-slide-toggle [disabled]="shiftsData.openShiftsCount === 0" [(ngModel)]="publishOptions.open">{{ pageTranslation.openShifts }}</mat-slide-toggle>
    </div>
    <orgos-column-container *ngIf="otherShiftsOwners" class="kenjo-pl-40px kenjo-pt-15px kenjo-pb-10px">
      <orgos-column size="0" class="kenjo-ml-5px">
        <kenjo-icon class="kenjo-font-color-primary-5993e3" [size]="18">info_outline</kenjo-icon>
      </orgos-column>
      <div orgosColumn class="kenjo-font-color-light-text-757575 kenjo-pl-10px">{{ pageTranslation.otherShiftsOwners }}</div>
    </orgos-column-container>
    <orgos-column-container *ngIf="shiftsData.openShiftsCount > 0" class="psd-info-box selected kenjo-pl-40px kenjo-pt-15px kenjo-pb-20px" [class.not-selected]="!publishOptions.open" [centerColumns]="true">
      <kenjo-icon orgosColumn="0" class="psd-icon-box" [size]="24">view_week</kenjo-icon>
      <div orgosColumn="0" class="kenjo-pl-15px">{{ shiftsData.openShiftsCount}} {{ pageTranslation.shifts }}</div>
      <kenjo-icon orgosColumn="0" class="psd-icon-box kenjo-ml-40px" [size]="24">clock</kenjo-icon>
      <div orgosColumn="0" class="kenjo-pl-15px">{{ shiftsData.openShiftsMinutesCount | timeShiftplan : false : true }}</div>
    </orgos-column-container>
    <orgos-column-container *ngIf="shiftsData.openShiftsCount === 0" class="kenjo-pl-40px kenjo-pt-15px kenjo-pb-20px" [centerColumns]="true">
      <div orgosColumn="0" class="kenjo-p-15px kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-border kenjo-color-grey-background-f5f5f5 kenjo-border-radius">{{ pageTranslation.noShiftsToPublish | i18nData: { shiftType: (pageTranslation.openShifts | lowercase) } }}</div>
    </orgos-column-container>
  </ng-container>
  <ng-container>
    <div class="kenjo-mt-20px" [class.psd-disabled]="shiftsData.scheduledShiftsCount === 0">
      <mat-slide-toggle [disabled]="shiftsData.scheduledShiftsCount === 0" [(ngModel)]="publishOptions.scheduled">{{ pageTranslation.scheduledShifts }}</mat-slide-toggle>
    </div>
    <orgos-column-container *ngIf="shiftsData.scheduledShiftsCount > 0" class="psd-info-box selected kenjo-pl-40px kenjo-pt-15px kenjo-pb-20px" [class.not-selected]="!publishOptions.scheduled" [centerColumns]="true">
      <kenjo-icon orgosColumn="0" class="psd-icon-box" [size]="24">view_week</kenjo-icon>
      <div orgosColumn="0" class="kenjo-pl-15px">{{ shiftsData.scheduledShiftsCount }} {{ pageTranslation.shifts }}</div>
      <kenjo-icon orgosColumn="0" class="psd-icon-box kenjo-ml-40px" [size]="24">clock</kenjo-icon>
      <div orgosColumn="0" class="kenjo-pl-15px">{{ shiftsData.scheduledShiftsMinutesCount | timeShiftplan : false: true }}</div>
      <kenjo-icon orgosColumn="0" class="psd-icon-box kenjo-ml-40px" [size]="24">people</kenjo-icon>
      <div orgosColumn="0" class="kenjo-pl-15px">{{ shiftsData.scheduledShiftsEmployeeCount }} {{ pageTranslation.employees }}</div>
    </orgos-column-container>
    <orgos-column-container *ngIf="shiftsData.scheduledShiftsCount === 0" class="kenjo-pl-40px kenjo-pt-15px kenjo-pb-20px" [centerColumns]="true">
      <div orgosColumn="0" class="kenjo-p-15px kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-border kenjo-color-grey-background-f5f5f5 kenjo-border-radius">{{ pageTranslation.noShiftsToPublish | i18nData: { shiftType: (pageTranslation.scheduledShifts | lowercase) } }}</div>
    </orgos-column-container>
  </ng-container>
  <orgos-column-container *ngIf="publishShiftsLimit" class="kenjo-p-10px psd-error-area kenjo-mb-30px" [centerColumns]="true">
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
    <div class="kenjo-ml-10px" orgosColumn>{{ pageTranslation.publishShiftsLimitText  }}</div>
  </orgos-column-container>
  <kenjo-shiftplan-notification-toggle [parentTranslation]="pageTranslation" [canSendNotifications]="canSendNotifications" [loading]="isPublishing" (toggleValue)="notificationToggle = $event"></kenjo-shiftplan-notification-toggle>
  <orgos-column-container *ngIf="isPublishing" class="psd-loading-container" [centerColumns]="true">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <div orgosColumn="0" class="kenjo-pl-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ pageTranslation.publishingLoading }}</div>
  </orgos-column-container>
  <orgos-column-container *ngIf="!isPublishing" class="kenjo-mt-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="dialogRef.close()" color="Neutral">{{ pageTranslation.backButton }} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised [disabled]="(publishOptions.open === false && publishOptions.scheduled === false) || publishShiftsLimit" (click)="publishShifts()" color="Success">{{ pageTranslation.publishButton }} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
