<div *ngIf="!allActivity" class="kenjo-text-align-center kenjo-pv-40px">
  <orgos-loading-spinner></orgos-loading-spinner>
</div>
<div *ngIf="allActivity">
  <div *ngFor="let iActivity of allActivity; trackBy: trackActivities" class="rawc-activity">
    <orgos-column-container centerColumns="true">
      <orgos-column size="0">
        <kenjo-icon [size]="20" [style.color]="iActivity.iconColor ? iActivity.iconColor : '#757575'">{{ iActivity.icon }} </kenjo-icon>
      </orgos-column>
      <orgos-column size="1" class="kenjo-ph-10px">
        <a *ngIf="iActivity._candidateId" (click)="goToCandidate(iActivity._candidateId, iActivity._positionId)"> {{ iActivity.mainInfo }}</a>
        <div *ngIf="!iActivity._candidateId">{{ iActivity.mainInfo }}</div>
      </orgos-column>
      <orgos-column size="0">
        <div class="kenjo-font-color-light-text-757575">{{ iActivity.date | humanDate: false:'short' }}</div>
      </orgos-column>
    </orgos-column-container>

    <div class="kenjo-mt-10px kenjo-border kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-radius" *ngIf="iActivity.additionalInfoTitle || iActivity.additionalInfoHtml || iActivity.additionalInfoText">
      <div *ngIf="iActivity.additionalInfoTitle" class="kenjo-p-10px rawc-additional-info-title kenjo-font-size-12px">
        {{ iActivity.additionalInfoTitle }}
      </div>
      <orgos-editor-viewer *ngIf="iActivity.additionalInfoHtml" [editorContent]="{ html: iActivity.additionalInfoHtml }" class="kenjo-p-10px"></orgos-editor-viewer>
      <div *ngIf="!iActivity.additionalInfoHtml && iActivity.additionalInfoText" class="kenjo-p-10px kenjo-font-size-12px">{{ iActivity.additionalInfoText }}</div>
    </div>
  </div>
</div>
