import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as _ from 'lodash';

@Pipe({
  name: 'time',
  pure: true
})
export class TimePipe implements PipeTransform {
  private latestRawValue: number;
  private latestComputedValue: string | null = null;

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string | null {
    if (check.not.assigned(value) || check.not.number(value)) {
      return null;
    }

    if (_.isEqual(this.latestRawValue, value)) {
      return this.latestComputedValue;
    }

    this.latestRawValue = value;
    this.latestComputedValue = this.convertMinutesToStringTime(value);

    return this.latestComputedValue;
  }

  private convertMinutesToStringTime(totalMinutes: number) {
    const hours = Math.floor((totalMinutes / 60) % 24);
    const minutes = (((totalMinutes / 60) % 24) - Math.floor((totalMinutes / 60) % 24)) * 60;

    return `${this.decimalPipe.transform(hours, '2.0-0')}:${this.decimalPipe.transform(minutes, '2.0-0')}`;
  }
}
