import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import * as check from 'check-types';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserAttendanceSummaryController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/user-attendance/user-attendance-summary`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) {}

  getList(options: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, UserAttendanceSummaryController.name, 'getList');
        reject(error);
        return;
      }

      if (check.not.assigned(options) || check.emptyString(options)) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.BAD_REQUEST, UserAttendanceSummaryController.name, 'getList');
        reject(error);
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.URL}/list`, options, httpOptions)
        .toPromise()
        .then((resultData: any) => {
          resolve(resultData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserAttendanceSummaryController.name, 'getList'));
        });
    });
  }

  getFiltersData(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, UserAttendanceSummaryController.name, 'getFiltersData');
        reject(error);
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.URL}/view-filters`, {}, httpOptions)
        .toPromise()
        .then((resultData: any) => {
          resolve(resultData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserAttendanceSummaryController.name, 'getFiltersData'));
        });
    });
  }
}
