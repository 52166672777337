import { Injector, Pipe, PipeTransform } from '@angular/core';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';

@Pipe({
  name: 'permissions',
  pure: false
})
export class PermissionsPipe implements PipeTransform {
  private allPermissions: any = null;
  private cachedResult: boolean;
  private cachedDocument: any;
  private cachedPermissionKey: string;
  private allUserWork: any;
  constructor(private injector: Injector) {}

  transform(permissionKey: string, document: any = null): boolean {
    if (check.not.assigned(permissionKey) || check.not.string(permissionKey) || check.emptyString(permissionKey)) {
      return false;
    }

    if (/_(custom|own|all)$/gi.test(permissionKey)) {
      throw new Error('PROGRAMMING ERROR: Permission key must not end in _custom | _own | _all');
    }

    if (check.assigned(this.cachedResult) && _.eq(document, this.cachedDocument) && _.eq(this.cachedPermissionKey, permissionKey)) {
      return this.cachedResult;
    }

    this.computePermissions(document, permissionKey);
    return false;
  }

  async computePermissions(document: any, permissionKey: string) {
    try {
      if (check.not.assigned(this.allPermissions)) {
        this.allPermissions = await this.injector.get(PrivateSecurityService).getAllPermissions();
      }

      const permissionsAll = (_.get(this.allPermissions, `${permissionKey}_all`) || _.get(this.allPermissions, permissionKey)) ?? false;

      if (permissionsAll === true) {
        this.cachedDocument = document;
        this.cachedPermissionKey = permissionKey;
        this.cachedResult = permissionsAll;
        return;
      }

      if (check.not.assigned(document)) {
        this.cachedDocument = document;
        this.cachedPermissionKey = permissionKey;
        this.cachedResult = false;
        return;
      }

      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      const permissionsOwn = _.get(this.allPermissions, `${permissionKey}_own`) ?? false;
      if (permissionsOwn === true && check.assigned(document.ownerId) && loggedUser?._id === document.ownerId) {
        this.cachedDocument = document;
        this.cachedPermissionKey = permissionKey;
        this.cachedResult = permissionsOwn;
        return;
      }

      if (check.not.assigned(this.allUserWork)) {
        this.cachedDocument = document;
        this.cachedPermissionKey = permissionKey;
        this.allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
      }

      if (check.not.assigned(this.allUserWork)) {
        this.cachedDocument = document;
        this.cachedPermissionKey = permissionKey;
        this.cachedResult = false;
        return;
      }

      const permissionsCustom = _.get(this.allPermissions, `${permissionKey}_custom`) ?? [];

      const [collectionName] = permissionKey.split('.');

      const permissionCustom = await customPermissions.applyCustomPermissionsToDocument(null, collectionName, permissionsCustom, permissionsOwn, document, this.allUserWork, loggedUser);
      this.cachedDocument = document;
      this.cachedPermissionKey = permissionKey;
      this.cachedResult = permissionCustom ?? false;
    } catch (error) {
      this.cachedDocument = document;
      this.cachedPermissionKey = permissionKey;
      this.cachedResult = false;
    }
  }
}
