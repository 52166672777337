import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

import { ISelectOption } from '../../../../../core/select-option';
import { InputValidation } from '../../../../../core/validation/input-validation';
import { CalendarService } from '../../../../../services/company/calendar.service';
import { IVirtualOffice } from '../../../../../services/company/office.service';
import { InternationalizationService } from '../../../../../services/core/internationalization.service';

@Component({
  selector: 'orgos-add-virtual-office-dialog',
  templateUrl: 'add-virtual-office.dialog.html',
  styleUrls: ['add-virtual-office.dialog.scss'],
})
export class AddVirtualOfficeDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  officeId: any;
  newVirtualOffice: IVirtualOffice;

  officeNameValidation: InputValidation;
  officeCalendarValidation: InputValidation;

  calendars: Array<ISelectOption> = [];

  constructor(
    public dialogRef: MatLegacyDialogRef<AddVirtualOfficeDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private parentInfo: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-offices-page')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.injector
      .get(CalendarService)
      .getCalendars()
      .then((calendars: Array<any>) => {
        this.calendars = calendars.map((calendar: any) => {
          const calendarOption = {
            name: calendar.name,
            value: calendar._id,
          };

          return calendarOption;
        });
      })
      .catch(() => {
        // An error is already shown
        this.calendars = [];
      });

    this.officeId = this.parentInfo.officeId;
    this.newVirtualOffice = this.parentInfo.virtualOffice
      ? this.parentInfo.virtualOffice
      : {
          name: '',
          street: '',
          postalCode: '',
          city: '',
          country: '',
          calendarId: '',
          motherOfficeId: '',
        };
  }

  closeDialog(): void {
    this.dialogRef.close({ saved: false });
  }

  save(): void {
    this.dialogRef.close({ saved: true, virtualOffice: this.newVirtualOffice });
  }
}
