import { Component, Inject, Injector, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'orgos-confirm-link-dialog',
  templateUrl: 'confirm-link-dialog.component.html',
  styleUrls: ['confirm-link-dialog.component.scss'],
})
export class ConfirmLinkDialogComponent {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' = 'Success';
  linkUrl: string;
  linkTo: string;

  constructor(
    public dialogRef: MatLegacyDialogRef<ConfirmLinkDialogComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {
    this.titleText = this.data.titleText;
    this.subtitleText = this.data.subtitleText;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.linkTo = this.data.linkTo;
    this.linkUrl = this.data.linkUrl;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }

  goToLink(): void {
    this.injector.get(Router).navigateByUrl(this.linkUrl, { replaceUrl: true });
    this.dialogRef.close({ navigate: true });
  }
}
