import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class FiltersController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/filters`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) {}

  async getAllFiltersValues(workplaces: Array<string>): Promise<any> {
    try {
      if (check.not.assigned(workplaces) || (check.assigned(workplaces) && check.emptyArray(workplaces))) {
        const error = new OrgosError(`${this.URL}`, ErrorCodes.BAD_REQUEST, FiltersController.name, 'getAllFiltersValues');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.post(`${this.URL}/list-filters`, { filters: workplaces }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FiltersController.name, 'getAllFiltersValues');
    }
  }
}
