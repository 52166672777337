<orgos-dialog-container class="acfd-main-container" [title]="pageTranslation.dialogHeaderText" (clickCloseButton)="closeDialog()">
  <orgos-column-container>
    <orgos-input-text orgosColumn [model]="customFieldDocument" field="fieldLabel" required="true" (validation)="fieldLabelValidation = $event" class="kenjo-pr-10px">
      <orgos-input-error *ngIf="fieldLabelValidation && fieldLabelValidation.getError('required')"> {{pageTranslation.fieldLabelRequired}}</orgos-input-error>
    </orgos-input-text>
    <orgos-input-select orgosColumn readOnly="true" [model]="customFieldDocument" field="fieldType" picklist="customFieldType" required="true" class="kenjo-pl-10px"></orgos-input-select>
  </orgos-column-container>

  <!-- TO ADD LIST ITEMS STARTS -->
  <div class="kenjo-pt-30px" *ngIf="customFieldDocument.data.fieldType === 'List'">
    <orgos-action (click)="addNewListItem(); $event.preventDefault()" icon="add_circle"> {{pageTranslation.addNewEntriesActionText}}</orgos-action>

    <div *ngIf="chosenListValues && chosenListValues.length > 0" class="kenjo-mt-15px kenjo-border kenjo-border-radius">
      <orgos-column-container centerColumns="true" *ngFor="let iValue of chosenListValues; let iIndex=index; let last = last; trackBy:customTrackBy" class="kenjo-ph-10px" [class.kenjo-border-bottom]="!last">
        <orgos-column size="1" class="kenjo-mr-10px kenjo-border-right kenjo-pv-10px">
          <input class="acfd-list-item-input" [placeholder]="pageTranslation.placeHolderAddListItemText" [ngModel]="chosenListValues[iIndex]" (ngModelChange)="chosenListValues[iIndex] = $event; evaluateListItems()" />
        </orgos-column>
        <orgos-column size="0">
          <kenjo-icon [size]="20" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757" (click)="removeEntry(iValue)">delete</kenjo-icon>
        </orgos-column>
      </orgos-column-container>
    </div>
  </div>
  <!-- TO ADD LIST ITEMS ENDS -->

  <!-- AUTO NUMBER CONFIG STARTS -->
  <div *ngIf="customFieldDocument.data.fieldType === 'AutoNumber'">
    <orgos-column-container centerColumns="true">
      <orgos-input-text orgosColumn [model]="customFieldDocument" field="autoNumberMask" [required]="true" class="kenjo-pr-10px" (validation)="autoNumberMaskValidation = $event" [customValidation]="autoNumberMaskValidationFunction">
        <orgos-input-error *ngIf="autoNumberMaskValidation && autoNumberMaskValidation.getError('required')"> {{pageTranslation.displayFormatRequired}}</orgos-input-error>
        <orgos-input-error *ngIf="autoNumberMaskValidation && autoNumberMaskValidation.getError('notValid')"> {{pageTranslation.displayFormatNotValid}}</orgos-input-error>
      </orgos-input-text>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container centerColumns="true">
          <orgos-column class="kenjo-font-color-light-text-757575" size="0" class="kenjo-mr-5px"> {{pageTranslation.displayFormatSingleExample}}</orgos-column>
          <orgos-column class="kenjo-font-color-success-00b72e kenjo-font-size-18px">
            <mat-icon [matTooltip]="pageTranslation.displayFormatSingleExampleTooltip" matTooltipPosition="above">help_outline</mat-icon>
          </orgos-column>
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true">
      <orgos-input-number orgosColumn [model]="customFieldDocument" field="autoNumberNextValue" [step]="1" [min]="0" [required]="true" class="kenjo-pr-10px" (validation)="autoNumberNextValueValidation = $event">
        <orgos-input-error *ngIf="autoNumberNextValueValidation && autoNumberNextValueValidation.getError('required')"> {{pageTranslation.startingNumberRequired}}</orgos-input-error>
        <orgos-input-error *ngIf="autoNumberNextValueValidation && autoNumberNextValueValidation.getError('min')"> {{pageTranslation.startingNumberMin}}</orgos-input-error>
      </orgos-input-number>
      <orgos-column class="kenjo-pl-10px"></orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mv-20px">
      <orgos-column size="0" class="kenjo-font-color-success-00b72e kenjo-font-size-18px kenjo-mr-5px">
        <mat-icon>info_outline</mat-icon>
      </orgos-column>
      <orgos-column>{{pageTranslation.displayFormatExamples}}</orgos-column>
    </orgos-column-container>
    <table class="acfd-display-format-table kenjo-font-size-12px">
      <tr>
        <th>{{pageTranslation.displayFormatTable?.headers.header1}}</th>
        <th>{{pageTranslation.displayFormatTable?.headers.header2}}</th>
        <th>{{pageTranslation.displayFormatTable?.headers.header3}}</th>
        <th>{{pageTranslation.displayFormatTable?.headers.header4}}</th>
      </tr>
      <tr>
        <td>{{pageTranslation.displayFormatTable?.row1.data1}}</td>
        <td>{{pageTranslation.displayFormatTable?.row1.data2}}</td>
        <td>{{pageTranslation.displayFormatTable?.row1.data3}}</td>
        <td>{{pageTranslation.displayFormatTable?.row1.data4}}</td>
      </tr>
      <tr>
        <td>{{pageTranslation.displayFormatTable?.row2.data1}}</td>
        <td>{{pageTranslation.displayFormatTable?.row2.data2}}</td>
        <td>{{pageTranslation.displayFormatTable?.row2.data3}}</td>
        <td>{{pageTranslation.displayFormatTable?.row2.data4}}</td>
      </tr>
      <tr>
        <td>{{pageTranslation.displayFormatTable?.row3.data1}}</td>
        <td>{{pageTranslation.displayFormatTable?.row3.data2}}</td>
        <td>{{pageTranslation.displayFormatTable?.row3.data3}}</td>
        <td>{{pageTranslation.displayFormatTable?.row3.data4}}</td>
      </tr>
    </table>
  </div>
  <!-- AUTO NUMBER CONFIG ENDS -->

  <div class="kenjo-pt-60px kenjo-text-align-right">
    <orgos-button-raised
      color="Success"
      (click)="updateField()"
      [disabled]="updatingCustomField || !fieldLabelValidation || fieldLabelValidation.hasErrors() || emptyListItems || (customFieldDocument.data.fieldType === 'AutoNumber' && (!autoNumberMaskValidation || autoNumberMaskValidation.hasErrors())) || (customFieldDocument.data.fieldType === 'AutoNumber' && (!autoNumberNextValueValidation || autoNumberNextValueValidation.hasErrors()))"
    >
      {{pageTranslation.addFieldButtonLabel}}</orgos-button-raised
    >
  </div>
</orgos-dialog-container>
