<orgos-container aspect="card" class="kenjo-pv-10px kenjo-ph-20px sc-box" [matTooltip]="tooltipMessage" matTooltipPosition="above" [matTooltipDisabled]="!disabled || !tooltipMessage" [class.alternative]="alternativeStyle">
  <input [disabled]="disabled" (keydown.arrowdown)="searchFocus()" [placeholder]="placeholder" [(ngModel)]="searchTerm" [class.disabled]="disabled" class="kenjo-font-size-14px" />
  <kenjo-icon *ngIf="alternativeStyle" class="kenjo-no-cursor" size="24">{{ icon }}</kenjo-icon>
</orgos-container>
<div class="kenjo-p-10px sc-box orgos-search-error-container" *ngIf="searchTerm && searchTerm.length > 0 && !someSearchResult">
  <ng-content select=".orgos-search-error"></ng-content>
</div>
<div (keydown.arrowup)="moveInSearch('up')" (keydown.arrowdown)="moveInSearch('down')" #searchList tabindex="0" class="sc-box search-list" [class.sc-box-alternative]="alternativeStyle" *ngIf="searchTerm && searchTerm.length > 0 && someSearchResult">
  <ng-content select="orgos-search-option"></ng-content>
</div>
<div class="kenjo-p-10px sc-box" [class.alternative-result]="alternativeStyle" *ngIf="(!searchTerm || (searchTerm && searchTerm.length === 0)) && someSearchResult">
  <ng-content select=".orgos-search-selection-container"></ng-content>
</div>
