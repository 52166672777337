import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IManagePoliciesOfEmployee } from '@app/cloud-features/time-off/pages/time-off-company/dialogs/manage-policies.dialog';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { CYCLE_TYPE_DISABLED, CYCLE_TYPE_DYNAMIC, CYCLE_TYPE_FIXED, POLICY_TYPE_DAY, POLICY_TYPE_HOUR } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TimeOffPolicyService implements IGenericService {
  private TIME_OFF_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/time-off-policy-db`;
  private TIME_OFF_PERMISSIONS_KEY: string = 'time-off-policy';
  private TIME_OFF_INTERNATIONALIZATION: string = 'time-off-policy-collection';
  private TIME_OFF_POLICY_SERVICE: string = 'TimeOffPolicyService';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  async create(data: object): Promise<ITimeOffPolicyModel> {
    try {
      return await this.genericService.create(this.TIME_OFF_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.TIME_OFF_POLICY_SERVICE, 'create');
    }
  }

  async getById(id: string): Promise<ITimeOffPolicyModel> {
    try {
      return await this.genericService.getById(this.TIME_OFF_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.TIME_OFF_POLICY_SERVICE, 'getById');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.TIME_OFF_URL, id, data);
      return;
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.TIME_OFF_POLICY_SERVICE, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.TIME_OFF_URL, id);
      return;
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.TIME_OFF_POLICY_SERVICE, 'deleteById');
    }
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.TIME_OFF_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.TIME_OFF_INTERNATIONALIZATION);
  }

  async find(findBody): Promise<Array<ITimeOffPolicyModel>> {
    try {
      return await this.genericService.find(this.TIME_OFF_URL, findBody);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.TIME_OFF_POLICY_SERVICE, 'find');
    }
  }

  managePoliciesOfEmployee(managePoliciesOfEmployeeInfo: IManagePoliciesOfEmployee): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_URL}/manage/policies-of-employee`, ErrorCodes.UNAUTHORIZED, this.TIME_OFF_POLICY_SERVICE, 'managePoliciesOfEmployee');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .put(`${this.TIME_OFF_URL}/manage/policies-of-employee`, managePoliciesOfEmployeeInfo, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.TIME_OFF_POLICY_SERVICE, 'managePoliciesOfEmployee'));
        });
    });
  }

  cancelScheduledAssignmentOfEmployee(timeOffTypeId: string, policyId: string, userId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_URL}/cancel/scheduled-assignment-of-employee`, ErrorCodes.UNAUTHORIZED, this.TIME_OFF_POLICY_SERVICE, 'cancelScheduledAssignmentOfEmployee');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        timeOffTypeId: timeOffTypeId,
        policyId: policyId,
        userId: userId
      };

      this.http
        .put(`${this.TIME_OFF_URL}/cancel/scheduled-assignment-of-employee`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.TIME_OFF_POLICY_SERVICE, 'cancelScheduledAssignmentOfEmployee'));
        });
    });
  }
}

export interface ITimeOffPolicyModel {
  _timeOffTypeId?: string;
  _id?: string;
  _type?: PolicyTypeType;
  ownerId?: string;
  name: string;
  description?: string;
  allowanceType: string;
  baseAllowance?: number;
  hoursWorkedRatio?: number;
  hoursGivenRatio?: number;
  annualCycle?: number;
  accrualPeriod?: string;
  accrualTime?: string;
  cycleType: PolicyCycleType;
  allowExtraAllowance?: boolean;
  extraAllowanceRules?: Array<IExtraAllowanceRuleModel>;
  carryOver?: boolean;
  carryOverQuantityLimit?: number;
  carryOverDuration?: number;
  carryOverDurationUnit?: string;
  carryOverUnlimitedQuantity?: boolean;
  carryOverUnlimitedDuration?: boolean;
  countNaturalDays?: boolean;
  allowHalfDays?: boolean;
  allowanceRounding?: string;
  showDetails?: boolean;
  freezeUntilProbationEnds?: boolean;
  allowNegativeBalance?: boolean;
  negativeBalanceLimit?: number;
  negativeBalanceUnlimited?: boolean;
  requireApproval?: boolean;
  partTimeDeduction?: boolean;
  startDateProration?: string;
  endDateProration?: string;
  partTimeProration?: string;
}

export type PolicyTypeType = POLICY_TYPE_DAY | POLICY_TYPE_HOUR;
export type PolicyCycleType = CYCLE_TYPE_FIXED | CYCLE_TYPE_DISABLED | CYCLE_TYPE_DYNAMIC;

export interface IExtraAllowanceRuleModel {
  monthsWorked: number;
  extraAllowanceTime: number;
}
