<ng-container *ngIf="profile">
  <orgos-column-container centerColumns="true">
    <orgos-column size="1">
      <orgos-input-text [model]="profileCacheModel" field="name" required="true" (validation)="nameValidation = $event" [readOnly]="profile._isStandard && profile._isStandard === true">
        <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">{{ i18n.nameRequired }} </orgos-input-error>
      </orgos-input-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" *ngIf="!profile._isStandard || profile._isStandard === false">
      <orgos-button-raised (click)="saveProfile()" [disabled]="!nameValidation || nameValidation.hasErrors()"> {{ i18n.saveButtonLabel }}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class=" kenjo-flex-align-self-start" *ngIf="profile._profileKey === 'admin' && (inAppInviteService.isInAppFeatureAvailable | async) === true">
      <div class="ps-invite-button">
        <kenjo-button-flat (click)="displayInviteDialog()">
          <kenjo-icon class="ps-invite-icon" [size]="18">person_add</kenjo-icon>
          <div>{{ i18n.inviteAdmins }}</div>
        </kenjo-button-flat>
      </div>
    </orgos-column>
  </orgos-column-container>

  <orgos-page-message *ngIf="profile._profileKey === 'restricted'" messageType="warning" [messageText]="i18n.restrictedProfileMessage"></orgos-page-message>
  <div class="kenjo-mt-20px" *ngIf="profile._profileKey !== 'restricted'">
    <div class="kenjo-font-size-16px">{{ i18n.permissionsLabel }}</div>
    <orgos-column-container wrap="true" class="kenjo-mt-20px permissions-container">
      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['employees']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'employees'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['employees']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['employees'] ? READ_ONLY_PERMISSIONS['employees'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>
      <orgos-column *ngFor="let iCollectionName of orderedListCollectionNames" class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-permissions-box
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionName]="collectionNamesTranslation[iCollectionName] ? collectionNamesTranslation[iCollectionName] : iCollectionName"
            [permissionsToIgnore]="IGNORE_PERMISSIONS_MAP[iCollectionName] ? IGNORE_PERMISSIONS_MAP[iCollectionName] : null"
            [specificTranslations]="CUSTOM_TRANSLATIONS_MAP[iCollectionName] ? CUSTOM_TRANSLATIONS_MAP[iCollectionName] : null"
            (permissionsChanged)="permissionsChanged($event)"
            [permissionKey]="iCollectionName"
            [readOnlyValues]="READ_ONLY_PERMISSIONS[iCollectionName] ? READ_ONLY_PERMISSIONS[iCollectionName] : null"
            [permissions]="tentativeProfile.permissions[iCollectionName]"
          >
          </orgos-permissions-box>
        </orgos-container>
      </orgos-column>

      <!--BOXES FOR CUSTOM PERMISSIONS-->
      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['attendance-app']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'attendance-app'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['attendance-app']"
            (permissionsChanged)="permissionsChanged($event)"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['attendance-app'] ? READ_ONLY_PERMISSIONS['attendance-app'] : {}"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['payroll-feature']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'payroll-feature'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['payroll-feature']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['payroll-feature'] ? READ_ONLY_PERMISSIONS['payroll-feature'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['document-app']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'document-app'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['document-app']"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
          <orgos-permissions-box
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionsToIgnore]="IGNORE_PERMISSIONS_MAP['document'] ? IGNORE_PERMISSIONS_MAP['document'] : null"
            [specificTranslations]="CUSTOM_TRANSLATIONS_MAP['document'] ? CUSTOM_TRANSLATIONS_MAP['document'] : null"
            (permissionsChanged)="permissionsChanged($event)"
            [permissionKey]="'document'"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['document'] ? READ_ONLY_PERMISSIONS['document'] : null"
            [permissions]="tentativeProfile.permissions['document']"
          >
          </orgos-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['time-off-app']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'time-off-app'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['time-off-app']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['time-off-app'] ? READ_ONLY_PERMISSIONS['time-off-app'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box-multiple
            [permissionsTitle]="'analytics'"
            [permissionValues]="[tentativeProfile.permissions['dashboards-app'], tentativeProfile.permissions['reports-app']]"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKeys]="['dashboards-app', 'reports-app']"
            [arrayPermissionsToDisplay]="[CUSTOM_PERMISSIONS['dashboards-app'], CUSTOM_PERMISSIONS['reports-app']]"
            [readOnlyValues]="[READ_ONLY_PERMISSIONS['dashboards-app'] ? READ_ONLY_PERMISSIONS['dashboards-app'] : {}, READ_ONLY_PERMISSIONS['reports-app'] ? READ_ONLY_PERMISSIONS['reports-app'] : {}]"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box-multiple>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['pulse-app']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'pulse-app'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['pulse-app']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['pulse-app'] ? READ_ONLY_PERMISSIONS['pulse-app'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['org-chart-feature']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'org-chart-feature'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['org-chart-feature']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['org-chart-feature'] ? READ_ONLY_PERMISSIONS['org-chart-feature'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['tasks-app']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'tasks-app'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['tasks-app']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['tasks-app'] ? READ_ONLY_PERMISSIONS['tasks-app'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['e-signature']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'e-signature'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['e-signature']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['e-signature'] ? READ_ONLY_PERMISSIONS['e-signature'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['project-app']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'project-app'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['project-app']"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
          <orgos-permissions-box
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionsToIgnore]="IGNORE_PERMISSIONS_MAP['project'] ? IGNORE_PERMISSIONS_MAP['project'] : null"
            [specificTranslations]="CUSTOM_TRANSLATIONS_MAP['project'] ? CUSTOM_TRANSLATIONS_MAP['project'] : null"
            (permissionsChanged)="permissionsChanged($event)"
            [permissionKey]="'project'"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['project'] ? READ_ONLY_PERMISSIONS['project'] : null"
            [permissions]="tentativeProfile.permissions['project']"
          >
          </orgos-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-permissions-box
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionName]="collectionNamesTranslation['goal'] ? collectionNamesTranslation['goal'] : 'goal'"
            [permissionsToIgnore]="IGNORE_PERMISSIONS_MAP['goal'] ? IGNORE_PERMISSIONS_MAP['goal'] : null"
            [specificTranslations]="CUSTOM_TRANSLATIONS_MAP['goal'] ? CUSTOM_TRANSLATIONS_MAP['goal'] : null"
            (permissionsChanged)="permissionsChanged($event)"
            [permissionKey]="'goal'"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['goal'] ? READ_ONLY_PERMISSIONS['goal'] : null"
            [permissions]="tentativeProfile.permissions['goal']"
          >
          </orgos-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-permissions-box
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionName]="collectionNamesTranslation['performanceReview'] ? collectionNamesTranslation['performanceReview'] : 'Performance Review'"
            [permissionsToIgnore]="IGNORE_PERMISSIONS_MAP['performance-review'] ? IGNORE_PERMISSIONS_MAP['performance-review'] : null"
            [specificTranslations]="CUSTOM_TRANSLATIONS_MAP['performance-review'] ? CUSTOM_TRANSLATIONS_MAP['performance-review'] : null"
            (permissionsChanged)="permissionsChanged($event)"
            [permissionKey]="'performance-review'"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['performance-review'] ? READ_ONLY_PERMISSIONS['performance-review'] : null"
            [permissions]="tentativeProfile.permissions['performance-review']"
          >
          </orgos-permissions-box>
        </orgos-container>
      </orgos-column>
      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['performance-feedback-results'] ? tentativeProfile.permissions['performance-feedback-results'] : {}"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'performance-feedback-results'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['performance-feedback-results']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['performance-feedback-results'] ? READ_ONLY_PERMISSIONS['performance-feedback-results'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['recruiting-options']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'recruiting-options'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['recruiting-options']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['recruiting-options'] ? READ_ONLY_PERMISSIONS['recruiting-options'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column" *ngIf="isShiftplanActive === true && profile?._profileKey !== 'agency' && profile?._profileKey !== 'finance-admin' && profile?._profileKey !== 'recruiter' && profile?._profileKey !== 'restricted'">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <kenjo-permissions-package
            [packageKey]="shiftplanPackageKey"
            [permissionValues]="tentativeProfile.permissions"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            (permissionsChanged)="shiftplanPermissionsChanged($event)"
            [permissionsPackage]="shiftplanPackageMap"
            [readOnlyValues]="READ_ONLY_SHIFTPLAN_PERMISSIONS"
          >
          </kenjo-permissions-package>
        </orgos-container>
      </orgos-column>

      <orgos-column class="kenjo-p-10px permissions-column">
        <orgos-container aspect="card" class="permissions-box kenjo-pb-20px">
          <orgos-custom-permissions-box
            [permissionValues]="tentativeProfile.permissions['settings']"
            [readOnly]="profile._isStandard && profile._isStandard === true"
            [permissionKey]="'settings'"
            [arrayPermissionsToDisplay]="CUSTOM_PERMISSIONS['settings']"
            [readOnlyValues]="READ_ONLY_PERMISSIONS['settings'] ? READ_ONLY_PERMISSIONS['settings'] : {}"
            (permissionsChanged)="permissionsChanged($event)"
          >
          </orgos-custom-permissions-box>
        </orgos-container>
      </orgos-column>
    </orgos-column-container>
  </div>

  <div *ngIf="!profile._isStandard || profile._isStandard === false" class="kenjo-text-align-right kenjo-mt-40px">
    <orgos-button-raised (click)="saveProfile()" [disabled]="!nameValidation || nameValidation.hasErrors()"> {{ i18n.saveButtonLabel }}</orgos-button-raised>
  </div>
</ng-container>
