import { HttpClient } from '@angular/common/http';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'kenjo-previewer',
  templateUrl: 'previewer.component.html',
  styleUrls: ['previewer.component.scss']
})
export class PreviewerComponent implements OnInit {
  #metadata: IFileMetadata;
  @Input()
  set metadata(metadata: IFileMetadata) {
    this.#metadata = metadata;
    this.initPreviewContentType();
  }
  get metadata(): IFileMetadata {
    return this.#metadata;
  }

  @Input() showDownloadButton: boolean = false;

  pageTranslation: any = {};
  loading: boolean;
  previewAvailable: boolean;
  fileMajorType: FileMajorType;
  fileTextContent?: string;

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.initTranslations();
  }

  private async initTranslations(): Promise<void> {
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('previewer');
    } catch (error) {
      this.pageTranslation = {};
    }
  }

  private async initPreviewContentType() {
    if (check.not.assigned(this.metadata?._fileMimetype)) {
      this.previewAvailable = false;
      return;
    }

    Object.entries(SUPPORTED_FORMATS).find(([iMajorType, iMimeTypesArray]) => {
      if (iMimeTypesArray.includes(this.metadata._fileMimetype)) {
        this.fileMajorType = iMajorType as FileMajorType;
        return true;
      }
    });

    if (!this.fileMajorType) {
      this.showPreviewNotAvailable();
      return;
    }

    if (this.fileMajorType === 'office' && (check.not.assigned(this.metadata._convertedPdfUrl) || check.emptyString(this.metadata._convertedPdfUrl))) {
      this.showPreviewNotAvailable();
      return;
    }

    try {
      this.loading = true;
      if (this.fileMajorType === 'text') {
        await this.loadFileTextContent();
      }
      this.previewAvailable = true;
    } catch (e) {
      this.showPreviewNotAvailable();
    } finally {
      this.loading = false;
    }
  }

  async loadFileTextContent() {
    const textContent = await this.injector.get(HttpClient).get(this.metadata._url, { responseType: 'text' }).toPromise();
    if (textContent) {
      this.fileTextContent = textContent;
    } else {
      // Let the parent method know that the preview couldn't be loaded
      throw 'Preview not available';
    }
  }

  showPreviewNotAvailable() {
    this.previewAvailable = false;
  }

  public async download(): Promise<void> {
    const arrayBuffer = await this.injector.get(HttpClient).get(this.metadata._url, { responseType: 'arraybuffer' }).toPromise();
    FileSaver.saveAs(new Blob([arrayBuffer]), this.metadata._fileName);
  }
}

const SUPPORTED_IMAGE_MIMETYPES = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

const SUPPORTED_AUDIO_MIMETYPES = ['audio/wav', 'audio/mpeg', 'audio/mp4', 'audio/acc', 'audio/accp', 'audio/ogg', 'audio/webm', 'audio/ogg', 'audio/flac', 'audio/ogg'];

const SUPPORTED_VIDEO_MIMETYPES = ['video/mp4', 'video/webm', 'video/ogg'];

const SUPPORTED_DOCUMENT_MIMETYPES = ['application/pdf', 'application/pdf, application/pdf'];

const SUPPORTED_TEXT_MIMETYPES = ['text/plain', 'text/csv', 'text/html'];

const SUPPORTED_OFFICE_MIMETYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf'
];

const SUPPORTED_FORMATS: { [key in FileMajorType]: Array<string> } = {
  image: SUPPORTED_IMAGE_MIMETYPES,
  audio: SUPPORTED_AUDIO_MIMETYPES,
  video: SUPPORTED_VIDEO_MIMETYPES,
  document: SUPPORTED_DOCUMENT_MIMETYPES,
  text: SUPPORTED_TEXT_MIMETYPES,
  office: SUPPORTED_OFFICE_MIMETYPES
};

type FileMajorType = 'image' | 'audio' | 'video' | 'document' | 'text' | 'office';
