import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'orgos-cancel-time-off-request',
  templateUrl: 'cancel-time-off-request.dialog.html',
  styleUrls: ['cancel-time-off-request.dialog.scss'],
})
export class CancelTimeOffRequestDialog implements OnInit {
  titleText: string;
  descriptionLabel: string;
  cancelLabel: string;
  backLabel: string;

  description: string = '';

  constructor(
    private dialogRef: MatLegacyDialogRef<CancelTimeOffRequestDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.initTranslations();
  }

  private initTranslations() {
    const { titleText, descriptionLabel, cancelLabel, backLabel } = this.data;
    this.titleText = titleText;
    this.descriptionLabel = descriptionLabel;
    this.cancelLabel = cancelLabel;
    this.backLabel = backLabel;
  }

  closeDialog(): void {
    this.dialogRef.close({ cancel: false });
  }

  cancelTimeOffRequest(): void {
    this.dialogRef.close({ cancel: true, description: this.description });
  }
}
