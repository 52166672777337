import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SettingsShiftPlanApprovalsDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-approvals/dialogs/settings-shift-plan-approvals-dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-settings-shift-plan-approvals',
  templateUrl: 'settings-shift-plan-approvals.html',
  styleUrls: ['settings-shift-plan-approvals.scss'],
})
export class SettingsShiftPlanApprovals implements OnInit {
  lateCheckInRange: number;

  i18n: any = {};
  attendanceTrackingEnabled: boolean = false;
  attendanceSummaryTabEnabled: boolean = false;
  presenceSummaryTabEnabled: boolean = false;

  @Input() parentTranslation: any = {};

  ngOnInit(): void {
    this.fetchData();
  }

  constructor(private injector: Injector, private router: Router) {}

  private async fetchData(): Promise<void> {
    try {
      this.i18n.page = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-approvals');
    } catch {
      this.i18n.page = {};
    }
  }

  public openApprovalsDialog() {
    const dialogRef = this.injector.get(MatLegacyDialog).open(SettingsShiftPlanApprovalsDialog, { data: {} });
  }
}
