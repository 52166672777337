<orgos-container aspect="card" class="kenjo-p-20px kenjo-mb-20px">
  <canvas #chartElement class="rb-chart-container"></canvas>
</orgos-container>

<orgos-container aspect="card">
  <orgos-table [data]="reportDataToDisplay" [displayedColumns]="displayedColumns" [stickyRow]="true" [stickyColumn]="true" [customHeightToSubstract]="90">
    <orgos-table-column *ngFor="let iField of displayedColumns" [columnKey]="iField" [header]="getTranslation(iField)" [sortable]="true">
      <ng-container *orgosTableCell="let row"> {{ row[iField] }}</ng-container>
    </orgos-table-column>
  </orgos-table>
</orgos-container>
