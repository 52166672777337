import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import * as PDFJS from 'pdfjs-dist/build/pdf.js';
import { debounceTime, takeWhile } from 'rxjs/operators';

PDFJS.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.js';

@Component({
  selector: 'orgos-pdf-render',
  templateUrl: 'pdf-render.component.html',
  styleUrls: ['pdf-render.component.scss']
})
export class PdfRenderComponent implements AfterContentInit, OnDestroy {
  @Input() pdfUrl: string;
  @Input() pdfData: Uint8Array;
  @Input() lazyRendering: boolean = false;
  @Input() renderingFeedbackText: string = 'Rendering...';

  @HostBinding('style.height') hostHeight: string = '100%';

  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  isPDF: boolean = true;

  rendering: boolean = false;
  private rendered: boolean = false;
  private componentDestroyed: boolean = false;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    if (this.lazyRendering === true) {
      this.initLazyRender();
    }

    this.checkRender();
  }

  private initLazyRender(): void {
    this.injector
      .get(ScrollDispatcher)
      .scrolled()
      .pipe(
        takeWhile(() => this.componentDestroyed === false && this.rendered === false),
        debounceTime(100)
      )
      .subscribe(() => {
        this.checkRender();
      });

    this.injector
      .get(ViewportRuler)
      .change()
      .pipe(
        takeWhile(() => this.componentDestroyed === false && this.rendered === false),
        debounceTime(100)
      )
      .subscribe(() => {
        this.checkRender();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed = true;
  }

  private checkRender(): void {
    if (this.rendered === true || this.rendering === true) {
      return;
    }

    if (this.lazyRendering === false) {
      this.#render();
      return;
    }

    const viewportHeight = this.injector.get(ViewportRuler).getViewportSize().height;
    if (this.canvas.nativeElement.getBoundingClientRect().bottom > 0 && this.canvas.nativeElement.getBoundingClientRect().top < 4 * (viewportHeight - 488)) {
      this.#render();
    }
  }

  async #render(): Promise<void> {
    const pdfToRender = this.pdfUrl || this.pdfData;
    if (this.rendered === true || this.rendering === true) {
      return;
    }

    this.rendering = true;
    this.hostHeight = '100%';
    try {
      const canvasContainer = this.canvas.nativeElement;
      // Remove previous rendered pages
      canvasContainer.innerHTML = '';

      const pdfDocument = await PDFJS.getDocument(pdfToRender).promise;
      const renderPages = [];
      for (let page = 1; page <= pdfDocument.numPages; page++) {
        const canvas = document.createElement('canvas');
        canvasContainer.appendChild(canvas);
        renderPages.push(this.#handlePage(pdfDocument, page, canvas, canvasContainer.clientWidth));
      }
      await Promise.all(renderPages);
      this.rendered = true;
    } catch (error) {
      throw error;
    } finally {
      this.rendering = false;
      this.hostHeight = 'unset';
      this.cdr.detectChanges();
    }
  }

  async #handlePage(pdfDocument, page, canvas, containerWidth) {
    const pdfPage = await pdfDocument.getPage(page);
    const viewport = pdfPage.getViewport({ scale: 1 });
    const desiredScale = containerWidth / viewport.width;
    const scaledViewPort = pdfPage.getViewport({ scale: desiredScale });

    // Prepare canvas using PDF page dimensions
    canvas.style.display = 'block';
    canvas.style.marginTop = '20px';
    canvas.height = scaledViewPort.height;
    canvas.width = scaledViewPort.width;

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: canvas.getContext('2d'),
      viewport: scaledViewPort
    };

    pdfPage.render(renderContext).promise;
  }
}
