<mat-form-field
  *ngIf="!readOnly"
  (click)="enableSearching()"
  cdk-overlay-origin
  [ngClass]="showAvatar && !searching && currentUser ? 'isuc-mat-input' : ''"
>
  <mat-label>{{ label }}</mat-label>
  <div class="kenjo-flex-center-content">
    <orgos-avatar
      matPrefix
      *ngIf="showAvatar === true && !searching && currentUserId"
      [photoUrl]="currentUser?._photo?._url"
      [avatarName]="currentUser?.displayName"
      size="extra-small"
      class="kenjo-mr-10px"
    ></orgos-avatar>
    <input
      matInput
      [ngModel]="searching ? searchTerm : currentUser?.displayName"
      (ngModelChange)="searchTerm = $event"
      [required]="required"
      #isucInput
      autocomplete="off"
    />
    <mat-icon matSuffix *ngIf="searching" [ngClass]="searching ? 'kenjo-font-size-22px' : ''">search</mat-icon>
    <div matSuffix *ngIf="!searching" class="isuc-select-arrow-wrapper mat-select-arrow"></div>
    <mat-icon *ngIf="!searching && showClearButton" matSuffix class="isuc-clear-button" (click)="clearValue(); $event.stopPropagation()"
      >clear</mat-icon
    >
  </div>
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="currentUser?.displayName" readOnly disabled [required]="required" />
</mat-form-field>
<ng-template cdk-portal>
  <orgos-container class="isuc-results-container">
    <orgos-text *ngIf="searchResults && searchResults.length === 0" color="Warn" class="kenjo-p-10px">{{
      miscTranslation.userNotFoundInInputSearchUser
    }}</orgos-text>
    <orgos-column-container
      *ngFor="let result of searchResults; let i = index"
      centerColumns="true"
      class="kenjo-p-10px isuc-result"
      [class.isuc-result-focused]="indexSearchResultFocused === i"
      (click)="setValue(result._id)"
      orgosAutocompleteOption
    >
      <orgos-avatar
        orgosColumn="0"
        [photoUrl]="result._photo?._url"
        [avatarName]="result.displayName"
        size="small"
        class="kenjo-mr-10px"
      ></orgos-avatar>
      <orgos-text orgosColumn>{{ result.displayName }}</orgos-text>
    </orgos-column-container>
  </orgos-container>
</ng-template>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
