import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class CostCenterService implements IGenericService {
  private COST_CENTER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/cost-center-db`;
  private COST_CENTER_PERMISSIONS_KEY: string = 'cost-center';
  private COST_CENTER_INTERNATIONALIZATION: string = 'cost-center-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<ICostCenterModel> {
    return new Promise<ICostCenterModel>((resolve, reject) => {
      this.genericService
        .create(this.COST_CENTER_URL, data)
        .then((costCenter: ICostCenterModel) => {
          resolve(costCenter);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CostCenterService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<ICostCenterModel> {
    return new Promise<ICostCenterModel>((resolve, reject) => {
      this.genericService
        .getById(this.COST_CENTER_URL, id)
        .then((costCenter: ICostCenterModel) => {
          resolve(costCenter);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CostCenterService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.COST_CENTER_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CostCenterService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.COST_CENTER_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CostCenterService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.COST_CENTER_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.COST_CENTER_INTERNATIONALIZATION);
  }

  getCostCenters(): Promise<Array<ICostCenterModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<ICostCenterModel>>((resolve, reject) => {
      this.genericService
        .find(this.COST_CENTER_URL, findBody)
        .then((costCenters: Array<ICostCenterModel>) => {
          resolve(costCenters);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CostCenterService.name, 'getCostCenters'));
        });
    });
  }
}

export interface ICostCenterModel {
  _id: string;
  name: string;
  costCenterId: string;
}
