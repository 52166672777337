import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable()
export class UserAddressService implements IGenericService {
  private USER_ADDRESS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-address-db`;
  private USER_ADDRESS_PERMISSIONS_KEY: string = 'user-address';
  private USER_ADDRESS_INTERNATIONALIZATION: string = 'user-address-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(): Promise<IUserAddressModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserAddressService.name, 'create');
    error.message = 'UserAddress should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserAddressModel> {
    return new Promise<IUserAddressModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_ADDRESS_URL, id)
        .then((userAddress: IUserAddressModel) => {
          resolve(userAddress);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserAddressService.name, 'getById'));
        });
    });
  }

  find(findBody: any): Promise<Array<IUserAddressModel>> {
    return new Promise<Array<IUserAddressModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_ADDRESS_URL, findBody)
        .then((allUserPersonal: Array<IUserAddressModel>) => {
          resolve(allUserPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserAddressService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_ADDRESS_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserAddressService.name, 'updateById'));
        });
    });
  }

  deleteById(): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserAddressService.name, 'deleteById');
    error.message = 'UserAddress should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_ADDRESS_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_ADDRESS_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_ADDRESS_URL);
  }
}

export interface IUserAddressModel {
  _id: string;
  street?: string;
  streetName?: string;
  houseNumber?: string;
  additionalAddress?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}
