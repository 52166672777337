import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { IUserFinancialModel } from '../../../models/user-financial.model';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class UserFinancialService implements IGenericService {
  private USER_FINANCIAL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-financial-db`;
  private USER_FINANCIAL_PERMISSIONS_KEY: string = 'user-financial';
  private USER_FINANCIAL_INTERNATIONALIZATION: string = 'user-financial-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IUserFinancialModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserFinancialService.name, 'create');
    error.message = 'UserFinancial should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserFinancialModel> {
    return new Promise<IUserFinancialModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_FINANCIAL_URL, id)
        .then((userFinancial: IUserFinancialModel) => {
          resolve(userFinancial);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserFinancialService.name, 'getById'));
        });
    });
  }

  find(findBody: any): Promise<Array<IUserFinancialModel>> {
    return new Promise<Array<IUserFinancialModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_FINANCIAL_URL, findBody)
        .then((allUserPersonal: Array<IUserFinancialModel>) => {
          resolve(allUserPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserFinancialService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_FINANCIAL_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserFinancialService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserFinancialService.name, 'deleteById');
    error.message = 'UserFinancial should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_FINANCIAL_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_FINANCIAL_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_FINANCIAL_URL);
  }
}
