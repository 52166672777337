import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { IValidateHashOptions, IValidateHashResult, PrivateHashService } from '@app/private/services/private-hash.service';
import { ISignInWithHashOptions, PrivateSignInService } from '@app/private/services/private-sign-in.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { HASH_TYPE_ONBOARDING_FREE_TRIAL } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

const MAP_ROUTE_TO_PAGE = {
  '/free-trial': 'main lead',
  '/create-password': 'invitee'
};

@Injectable({
  providedIn: 'root'
})
export class OnboardingFreeTrialGuard implements CanActivate, CanLoad {
  constructor(private injector: Injector) {}

  canLoad(_: Route, urlSegments: Array<UrlSegment>): boolean | UrlTree {
    if (check.not.assigned(urlSegments) || check.emptyArray(urlSegments) || check.not.assigned(urlSegments[1]) || check.emptyString(urlSegments[1].path)) {
      return this.injector.get(Router).parseUrl('/signin');
    }
    return true;
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (check.not.assigned(route) || check.not.assigned(route.params) || check.not.assigned(route.params.hash) || check.emptyString(route.params.hash)) {
      return this.injector.get(Router).parseUrl('/signin');
    }

    try {
      const hash = route.params.hash;
      const validateHashOptions: IValidateHashOptions = {
        type: HASH_TYPE_ONBOARDING_FREE_TRIAL,
        hashValue: hash
      };

      let validateHashResult: IValidateHashResult;
      validateHashResult = await this.injector.get(PrivateHashService).validateHash(validateHashOptions);

      const signInWithHashOptions: ISignInWithHashOptions = {
        type: HASH_TYPE_ONBOARDING_FREE_TRIAL,
        hashValue: hash,
        referenceId: validateHashResult.referenceId,
        s_orgId: validateHashResult.s_orgId
      };
      await this.injector.get(PrivateSignInService).signInWithOnboardingHash(signInWithHashOptions);
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        return this.injector.get(Router).parseUrl('/signin');
      }

      const leadType = MAP_ROUTE_TO_PAGE[state.url.split('/onboarding')?.[1]];
      this.injector.get(PrivateAmplitudeService).logSimpleEvent('Free trial link followed', { platform: 'Web', category: 'Free trial', subcategory1: leadType ?? '' });
      return true;
    } catch {
      // An error is already shown if necessary
      return this.injector.get(Router).parseUrl('/signin');
    }
  }
}
