import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class PositionCandidateService {
  private POSITION_CANDIDATE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/position-candidate-db`;
  private POSITION_CANDIDATE_STAGES_IN_USE: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting/position-candidate`;
  private POSITION_CANDIDATE_PERMISSIONS_KEY: string = 'position-candidate';
  private POSITION_CANDIDATE_INTERNATIONALIZATION: string = 'position-candidate-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.POSITION_CANDIDATE_URL, data)
        .then((positionCandidate: any) => {
          resolve(positionCandidate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateService.name, 'create'));
        });
    });
  }

  async find(findBody: any): Promise<any> {
    try {
      const query = { ...findBody, _isArchived: { $ne: true } };
      const result = await this.genericService.find(this.POSITION_CANDIDATE_URL, query);
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, PositionCandidateService.name, 'find');
    }
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.POSITION_CANDIDATE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.POSITION_CANDIDATE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.POSITION_CANDIDATE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.POSITION_CANDIDATE_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.POSITION_CANDIDATE_URL);
  }

  async getMyPositionCandidates(): Promise<Array<any>> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PositionCandidateService.name, 'getMyPositionCandidates');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const myPositionCandidates = await this.http.get<Array<any>>(`${this.POSITION_CANDIDATE_URL}/my-position-candidates`, httpOptions).toPromise();
      return myPositionCandidates;
    } catch (error) {
      throw this.errorManager.handleRawError(error, PositionCandidateService.name, 'getMyPositionCandidates');
    }
  }

  async getStagesInUse(pipelineId: string): Promise<Array<string>> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const stagesInUse = await this.http.get<Array<string>>(`${this.POSITION_CANDIDATE_STAGES_IN_USE}/stages-in-use/${pipelineId}`, httpOptions).toPromise();
      return stagesInUse;
    } catch (error) {
      throw this.errorManager.handleRawError(error, PositionCandidateService.name, 'getStagesInUse');
    }
  }

  public touchUpdatedAtById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PositionCandidateService.name, 'touchUpdatedAtById');
        return Promise.reject(this.errorManager.handleRawError(error));
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.POSITION_CANDIDATE_URL}/${id}/touch/`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateService.name, 'touchUpdatedAtById'));
        });
    });
  }
}

export interface ICandidateCommentModel {
  _id?: string;
  private?: boolean;
  _candidateId?: string;
  comment?: string;
  agency?: string;
  _positionId?: string;
}
