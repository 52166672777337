import { Injectable } from '@angular/core';
import { DateRange, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { LegacyDateAdapter } from '@angular/material/legacy-core';
import * as check from 'check-types';
import * as moment from 'moment';

@Injectable()
export class WeekRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: LegacyDateAdapter<D>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this.createWeekRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this.createWeekRange(activeDate);
  }

  private createWeekRange(date: D | null): DateRange<D> {
    if (check.not.assigned(date)) {
      return new DateRange<D>(null, null);
    }
    const { startDiff, endDiff } = this.calculateDistanceInWeek(moment.utc(date));
    const start = this._dateAdapter.addCalendarDays(date, startDiff);
    const end = this._dateAdapter.addCalendarDays(date, endDiff);
    return new DateRange<D>(start, end);
  }

  private calculateDistanceInWeek(date: moment.Moment) {
    const start = moment.utc(date).startOf('week');
    const end = moment.utc(date).endOf('week');

    const startDiff = start.diff(date, 'days');
    const endDiff = end.diff(date, 'days');
    return { startDiff, endDiff };
  }
}
