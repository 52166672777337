<ng-container *ngIf="isDataLoaded && employeeId && userPersonal && userAccount">
  <orgos-column-container centerColumns="true" class="deddp-top-bar kenjo-border-bottom kenjo-ph-20px">
    <orgos-column size="0">
      <kenjo-icon size="20" (click)="onBackClick()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
    </orgos-column>

    <orgos-column size="0" class="deddp-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-column size="0" class="kenjo-font-size-16px">{{pageTranslation.pageName}}</orgos-column>

    <orgos-column size="0" class="deddp-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-avatar orgosColumn="0" [photoUrl]="userPersonal?._photo?._url" [avatarName]="userPersonal?.displayName" size="small" class="kenjo-mr-10px"></orgos-avatar>
    <orgos-column>{{userPersonal?.displayName}}</orgos-column>
  </orgos-column-container>

  <div class="deddp-main-wrapper" [style.height]="tagsToDisplay && tagsToDisplay.length > 0 ? documentsHeightStyle : 'calc(100vh - 49px)'">
    <orgos-column-container cdkDropListGroup class="deddp-main-container">
      <!-- VISIBLE -->
      <orgos-column class="deddp-visible-documents-container kenjo-p-10px">
        <orgos-column-container centerColumns="true" class="kenjo-p-10px">
          <orgos-column size="0" class="kenjo-font-size-18px"> {{pageTranslation.visibleTo | i18nData: userPersonal}} </orgos-column>
          <kenjo-icon orgosColumn="0" *ngIf="userAccount && (userAccount.profileKey === 'admin' || userAccount.profileKey === 'hr-admin')" [size]="18" class="kenjo-ml-10px kenjo-font-color-success-00b72e" [matTooltip]="pageTranslation.descriptionAdmin | i18nData: userPersonal">
            help_outline
          </kenjo-icon>
          <orgos-column></orgos-column>
          <orgos-column size="0" *ngIf="canUploadDocs && canUploadDocs === true" class="kenjo-ml-40px">
            <orgos-action (click)="uploadVisibleDoc()" icon="add_circle" iconSize="large"> {{pageTranslation.uploadDocument}}</orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container *ngIf="allDocumentTags.length > 0" wrap="true" cdkDropList (cdkDropListDropped)="droppedDocument($event)" class="deddp-drop-list">
          <orgos-document-card cdkDrag (cdkDragStarted)="startToDragDocument(document, 'visible')" *ngFor="let document of employeeVisibleDocs" orgosColumn [document]="document" [allDocumentTags]="allDocumentTags" class="kenjo-m-10px"> </orgos-document-card>
        </orgos-column-container>
      </orgos-column>

      <!-- HIDDEN -->
      <orgos-column *ngIf="canViewHiddenDocs && canViewHiddenDocs === true" class="deddp-hidden-documents-container kenjo-p-10px kenjo-border-left">
        <orgos-column-container centerColumns="true" class="kenjo-p-10px">
          <orgos-column size="0" class="kenjo-font-size-18px"> {{pageTranslation.hiddenFrom | i18nData: userPersonal}} </orgos-column>
          <kenjo-icon orgosColumn="0" *ngIf="userAccount && (userAccount.profileKey === 'admin' || userAccount.profileKey === 'hr-admin')" [size]="18" class="kenjo-ml-10px kenjo-font-color-success-00b72e" [matTooltip]="pageTranslation.descriptionAdmin | i18nData: userPersonal">
            help_outline
          </kenjo-icon>
          <orgos-column></orgos-column>
          <orgos-column size="0" *ngIf="canUploadDocs && canUploadDocs === true" class="kenjo-ml-40px">
            <orgos-action (click)="uploadHiddenDoc()" icon="add_circle" iconSize="large"> {{pageTranslation.uploadDocument}} </orgos-action>
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container *ngIf="allDocumentTags.length > 0" wrap="true" cdkDropList (cdkDropListDropped)="droppedDocument($event)" class="deddp-drop-list">
          <orgos-document-card cdkDrag (cdkDragStarted)="startToDragDocument(document, 'hidden')" *ngFor="let document of employeeHiddenDocs" orgosColumn [allDocumentTags]="allDocumentTags" [document]="document" class="kenjo-m-10px"> </orgos-document-card>
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
  </div>

  <div #tagFooter *ngIf="tagsToDisplay && tagsToDisplay.length > 0" class="kenjo-border-top kenjo-color-grey-background-lighter-f8f8f8">
    <orgos-column-container wrap="true" class="kenjo-ph-20px deddp-tags-wrapper">
      <orgos-column size="0" *ngFor="let iDocumentTag of tagsToDisplay">
        <orgos-button-toggle (checkedChange)="onChangeFilter($event, iDocumentTag)" [checked]="false" [activeColor]="getColor(iDocumentTag.color)">{{ iDocumentTag.name }}</orgos-button-toggle>
      </orgos-column>
    </orgos-column-container>
  </div>
</ng-container>
