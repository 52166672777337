import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { PrivateUppyService } from '@app/private/services/private-uppy.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-payroll-upload-payslips',
  templateUrl: 'payroll-upload-payslips.component.html',
  styleUrls: ['payroll-upload-payslips.component.scss']
})
export class PayrollUploadPayslipsComponent {
  uploadingFiles: boolean = false;
  progress: number = 0;
  MAX_SIZE_FOR_DOCUMENTS_PAYSLIPS: number = 50; // Payslips documents have now a 50MB size limit

  @Input() i18n: any = {};
  @Output() documentsUploaded: EventEmitter<Array<IFileMetadata>> = new EventEmitter<Array<IFileMetadata>>();
  @Output() documentsType: EventEmitter<'Payslips' | 'Generic'> = new EventEmitter<'Payslips' | 'Generic'>();

  constructor(private injector: Injector, private elementRef: ElementRef) {}

  private payslipsDropZonePayrollInit: boolean = false;
  @ViewChild('payslipsDropZonePayroll')
  set payslipsDropZonePayroll(payslipsDropZonePayroll: any) {
    if (check.not.assigned(payslipsDropZonePayroll) || this.payslipsDropZonePayrollInit === true) {
      return;
    }

    this.payslipsDropZonePayrollInit = true;

    const payslipsDropPaneOptions = {
      allowMultipleUploadBatches: true,
      restrictions: {
        maxFileSize: this.MAX_SIZE_FOR_DOCUMENTS_PAYSLIPS * 1024 * 1024,
        maxNumberOfFiles: 1000,
        allowedFileTypes: ['.pdf']
      }
    };

    this.injector
      .get(PrivateUppyService)
      .getDropClient(
        '#payslipsDropZonePayroll',
        payslipsDropPaneOptions,
        () => {
          this.progress = 0;
          this.uploadingFiles = true;
        },
        (progress) => {
          this.progress = progress >= 100 ? 99 : progress;
        }
      )
      .then((uploadedPayslips: Array<IFileMetadata>) => {
        if (check.not.emptyArray(uploadedPayslips)) {
          this.documentsUploaded.emit(uploadedPayslips);
          this.documentsType.emit('Payslips');
        }

        this.progress = 100;
        this.uploadingFiles = false;
      });
  }

  onUpload() {
    const button = this.elementRef.nativeElement.getElementsByClassName('uppy-FileInput-btn');
    button?.[0]?.click();
  }
}
