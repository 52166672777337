<orgos-column-container *ngIf="monthRange" [centerColumns]="true">
  <kenjo-icon
    orgosColumn="0"
    [size]="25"
    class="kenjo-font-color-primary-5993e3"
    [class.kenjo-cursor-pointer]="!states.dragging"
    [disabled]="states.loading || states.dropping"
    [class.kenjo-font-color-disabled-c4c4c4]="states.loading || states.dropping"
    (click)="valueChange.emit(MONTH_VALUES.BEFORE)"
    >keyboard_arrow_left</kenjo-icon
  >
  <kenjo-input-month-picker class="smp-component" orgosColumn="0" [preferenceDate]="monthRange.from" (selectedDate)="onValueChange($event)" [disabled]="states.loading || states.dropping"> </kenjo-input-month-picker>
  <kenjo-icon orgosColumn="0" [size]="25" class="kenjo-font-color-primary-5993e3" [class.kenjo-cursor-pointer]="!states.dragging" [disabled]="states.loading || states.dropping" [class.kenjo-font-color-disabled-c4c4c4]="states.loading || states.dropping" (click)="valueChange.emit(MONTH_VALUES.AFTER)"
    >keyboard_arrow_right</kenjo-icon
  >
</orgos-column-container>
