import { Inject, Injector } from '@angular/core';
import { Optional } from '@angular/core';
import { Component } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';

@Component({
  selector: 'kenjo-create-attendance-policy-success-dialog',
  templateUrl: 'create-attendance-policy-success.dialog.html',
  styleUrls: ['create-attendance-policy-success.dialog.scss'],
})
export class CreateAttendancePolicySuccessDialog {
  translations: any;
  attendancePolicyId: string;
  editPolicyAction: Function;
  manageEmployeesAction: Function;

  constructor(
    public dialogRef: MatLegacyDialogRef<CreateAttendancePolicySuccessDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {
    this.translations = data.translations;
    this.attendancePolicyId = data.attendancePolicyId;
    this.editPolicyAction = data.editPolicyAction;
    this.manageEmployeesAction = data.manageEmployeesAction;
  }

  openAdvancedSettings() {
    this.editPolicyAction(this.attendancePolicyId);
    this.injector.get(PrivateAmplitudeService).logEvent('advanced settings', {
      platform: 'Web',
      category: 'Attendance',
      subcategory1: 'Attendance Policies',
      subcategory2: 'Creation Wizard Success',
    });
    this.dialogRef.close();
  }

  openAssignEmployees() {
    this.manageEmployeesAction(this.attendancePolicyId);
    this.injector.get(PrivateAmplitudeService).logEvent('assign employees', {
      platform: 'Web',
      category: 'Attendance',
      subcategory1: 'Attendance Policies',
      subcategory2: 'Creation Wizard Success',
    });
    this.dialogRef.close();
  }

  logLearnMoreEvent() {
    this.injector.get(PrivateAmplitudeService).logEvent('more about advanced settings', {
      platform: 'Web',
      category: 'Attendance',
      subcategory1: 'Attendance Policies',
      subcategory2: 'Creation Wizard Success',
    });
  }

  close() {
    this.dialogRef.close();
    this.injector.get(PrivateAmplitudeService).logEvent('close the success pop up', {
      platform: 'Web',
      category: 'Attendance',
      subcategory1: 'Attendance Policies',
      subcategory2: 'Creation Wizard Success',
    });

    this.injector
      .get(MatLegacySnackBar)
      .open(this.translations.policyCreatedSuccess, this.translations.editPolicySettings, {
        duration: 5000,
      })
      .onAction()
      .subscribe(() => {
        this.editPolicyAction(this.attendancePolicyId);
      });
  }
}
