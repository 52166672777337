import { Component, OnInit } from '@angular/core';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-color-picker',
  templateUrl: 'input-color-picker.component.html',
  styleUrls: ['input-color-picker.component.scss']
})
export class InputColorPickerComponent extends InputAbstractComponent implements OnInit {
  colors: Array<any> = [];

  selectedColorKey: string = '';

  ngOnInit(): void {
    const value = super.getValue();
    this.colors = Object.keys(userColorConstants).map((iColorKey: string) => {
      if (value === iColorKey) {
        this.selectedColorKey = iColorKey;
      }

      const color = {
        colorKey: iColorKey,
        value: userColorConstants[iColorKey]
      };

      return color;
    });

    super.ngOnInit();
  }

  setValue(newColorKey: string): void {
    if (check.assigned(newColorKey) && this.readOnly === false) {
      this.selectedColorKey = newColorKey;
    }

    super.setValue(newColorKey);
  }
}
