<orgos-column-container class="kenjo-p-5px">
  <orgos-column size="0" class="kenjo-mr-5px">
    <orgos-avatar [photoUrl]="employee._photo?._url" [avatarName]="employee.displayName" size="smaller"></orgos-avatar>
  </orgos-column>
  <orgos-column size="3" class="kenjo-font-size-14px">
    <orgos-column-container [centerColumns]="true" class="kenjo-mb-5px">
      <a orgosColumn [routerLink]="['/cloud/people/', employee._id, 'personal']">
        <kenjo-truncate-text class="kenjo-inline-block spedc-simple-text-width">{{ employee.displayName }}</kenjo-truncate-text>
      </a>
    </orgos-column-container>
    <orgos-chip
      *ngIf="shiftsWorkingTime"
      class="spedc-height-22px spedc-chip"
      [color]="shiftsWorkingTime === totalExpected ? 'Neutral' : shiftsWorkingTime > totalExpected ? 'Danger' : 'Warning'"
    >
      <kenjo-truncate-text class="spedc-line-height spedc-trunc-chip"
        >{{ shiftsWorkingTime | timeShiftplan }}/{{ totalExpected | timeShiftplan }}</kenjo-truncate-text
      >
    </orgos-chip>
    <orgos-chip
      *ngIf="!shiftsWorkingTime"
      class="spedc-height-22px spedc-chip"
      [color]="
        employee.workingTimeInMinutes === totalExpected ? 'Neutral' : employee.workingTimeInMinutes > totalExpected ? 'Danger' : 'Warning'
      "
    >
      <kenjo-truncate-text class="spedc-line-height spedc-trunc-chip"
        >{{ employee.workingTimeInMinutes | timeShiftplan }}/{{ totalExpected | timeShiftplan }}</kenjo-truncate-text
      >
    </orgos-chip>
  </orgos-column>

  <!-- Attendance info -->
  <orgos-column size="0">
    <div
      class="spedc-attendance kenjo-cursor-pointer"
      (click)="openAttendanceMenu()"
      [class.overtime]="attendance.hasOvertime && attendanceVisibility.tracking"
    >
      <div #attendaceMenuTrigger [matMenuTriggerFor]="attendanceMenu"></div>
      <kenjo-icon [size]="18">attendance_menu</kenjo-icon>
    </div>
    <mat-menu #attendanceMenu="matMenu" class="spedc-attendance-menu kenjo-font-size-14px">
      <kenjo-shiftplan-attendance-card
        [parentTranslation]="parentTranslation"
        [startDay]="attendance.startDay"
        [myAttendance]="myAttendance"
        [isCurrentMonth]="isCurrentMonth"
        [attendanceVisibility]="attendanceVisibility"
      ></kenjo-shiftplan-attendance-card>
    </mat-menu>
  </orgos-column>
</orgos-column-container>

<!-- To keep -->
<!-- <div *ngIf="employee.notMatchingRole === true && view === 'week'" [matTooltip]="warningTooltip" [matTooltipPosition]="'right'" class="spedc-warning-icon">
  <kenjo-icon [size]="22" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
</div> -->
