import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ShiftPlanCardService } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-delete-shift-dialog',
  templateUrl: 'delete-shift.dialog.html',
  styleUrls: ['delete-shift.dialog.scss'],
})
export class DeleteShiftDialog implements OnInit {
  dialogTranslation: any = {};
  loading = true;
  notificationToggle: boolean = true;
  canSendNotifications: boolean = false;
  isDeleting: boolean = false;
  title: string;
  subtitle: string;
  shiftData;
  todayDate: moment.Moment = moment.utc();
  shiftDate: moment.Moment;

  constructor(
    public dialogRef: MatLegacyDialogRef<DeleteShiftDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    public snackBar: MatLegacySnackBar
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.shiftData = this.dialogInfo.shift;
    this.canSendNotifications = this.dialogInfo.notificationSetting;
    this.shiftDate = moment(this.shiftData.date).startOf('date');
    this.fetchTranslations();
  }

  async fetchTranslations() {
    this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-delete-dialog');
    this.setDialogTitle();
  }

  private setDialogTitle(): void {
    const shiftStart = moment(this.shiftDate).add(this.shiftData.start, 'minutes');
    const shiftEnd = moment(this.shiftDate).add(this.shiftData.end, 'minutes');
    this.subtitle = this.dialogTranslation.subtitleSecondary;

    if (this.todayDate.isBetween(shiftStart, shiftEnd)) {
      this.title = this.dialogTranslation.ongoingShiftTitle;
    } else if (this.todayDate.isAfter(shiftEnd)) {
      this.title = this.dialogTranslation.pastShiftTitle;
    } else {
      this.title = this.dialogTranslation.defaultTitle;
      this.subtitle = this.dialogTranslation.subtitleDefault;
    }
  }

  public async deleteShift() {
    try {
      this.isDeleting = true;
      await this.injector.get(ShiftPlanCardService).deleteAndNotify(this.shiftData._id, this.notificationToggle);
      this.showSnackbar();
    } catch {
      // do nothing
    } finally {
      this.isDeleting = false;
      this.dialogRef.close(true);
    }
  }

  public closeDialog(): void {
    if (this.isDeleting) {
      return;
    }
    this.dialogRef.close();
  }

  public showSnackbar(): void {
    const snackBarMessage = this.notificationToggle
      ? this.dialogTranslation.snackbarPublishedAndNotifiedShiftDeleted
      : this.dialogTranslation.snackbarPublishedShiftDeleted;
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }
}
