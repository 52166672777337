import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class ProjectMemberService {
  private PROJECT_MEMBER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/project-member-db`;
  private PROJECT_MEMBER_PERMISSIONS_KEY: string = 'project-member';
  private PROJECT_MEMBER_INTERNATIONALIZATION: string = 'project-member-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any): Promise<IProjectMemberModel> {
    return new Promise<IProjectMemberModel>((resolve, reject) => {
      this.genericService
        .create(this.PROJECT_MEMBER_URL, data)
        .then((projectMember: IProjectMemberModel) => {
          resolve(projectMember);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectMemberService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IProjectMemberModel>> {
    return new Promise<Array<IProjectMemberModel>>((resolve, reject) => {
      this.genericService
        .find(this.PROJECT_MEMBER_URL, findQuery)
        .then((projectMembers: Array<IProjectMemberModel>) => {
          resolve(projectMembers);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectMemberService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<IProjectMemberModel> {
    return new Promise<IProjectMemberModel>((resolve, reject) => {
      this.genericService
        .getById(this.PROJECT_MEMBER_URL, id)
        .then((projectMember: IProjectMemberModel) => {
          resolve(projectMember);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectMemberService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IProjectMemberModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.PROJECT_MEMBER_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectMemberService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.PROJECT_MEMBER_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectMemberService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.PROJECT_MEMBER_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.PROJECT_MEMBER_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.PROJECT_MEMBER_URL);
  }

  getAllProjectMembers(): Promise<Array<IProjectMemberModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<IProjectMemberModel>>((resolve, reject) => {
      this.genericService
        .find(this.PROJECT_MEMBER_URL, findBody)
        .then((allProjectMembers: Array<IProjectMemberModel>) => {
          resolve(allProjectMembers);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectMemberService.name, 'getAllProjectMembers'));
        });
    });
  }
}

export interface IProjectMemberModel {
  _userId?: string;
  _projectId?: string;
  costPerHour?: number;
  roleName?: string;
}
