import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import {
  IShiftplanAttendanceData,
  ShiftPlanAttendanceService,
} from '@app/cloud-features/shift-plan/services/shift-plan-attendance.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shift-plan-employee-display',
  templateUrl: 'shift-plan-employee-display.component.html',
  styleUrls: ['shift-plan-employee-display.component.scss'],
})
export class ShiftPlanEmployeeDisplayComponent implements OnInit {
  loading: boolean = false;
  myAttendance: IShiftplanAttendanceData;
  isCurrentMonth: boolean;
  attendanceVisibility = {
    notViewPermission: false,
    tracking: false,
    appStatus: false,
    showAttendance: false,
    hasOvertime: false,
    overtimeTracking: false
    };

  @Input() employee;
  @Input() warningTooltip: string;
  @Input() totalExpected: number = 0;
  @Input() shiftsWorkingTime: number;
  @Input() view: string;
  @Input() attendance: {
    startDay: moment.Moment;
    hasOvertime: boolean;
    tracking: boolean;
    showAttendance: boolean;
    overtimeTracking: boolean;
    showOvertimeTracking: boolean;
  };
  @Input() parentTranslation: { [key: string]: string };
  @ViewChild('attendaceMenuTrigger', { read: MatLegacyMenuTrigger }) attendaceMenuTrigger: MatLegacyMenuTrigger;

  constructor(private router: Router, private injector: Injector) {}

  ngOnInit() {
    this.roundWorkingTime();
    this.attendanceVisibility.tracking = this.attendance.tracking;
    this.attendanceVisibility.overtimeTracking =  this.attendance.overtimeTracking && this.attendance.showOvertimeTracking;
    this.attendanceVisibility.showAttendance = this.attendance.showAttendance;
    this.attendanceVisibility.hasOvertime = this.attendance.hasOvertime;
  }

  roundWorkingTime() {
    this.totalExpected = Math.round(this.totalExpected);
    if (check.assigned(this.shiftsWorkingTime)) {
      this.shiftsWorkingTime = Math.round(this.shiftsWorkingTime);
    } else {
      this.employee.workingTimeInMinutes = Math.round(this.employee.workingTimeInMinutes);
    }
  }

  async getAttendanceAppStatus() {
    const appStatus = await this.injector.get(CloudRoutesService).getAppStatus('attendance');
    this.attendanceVisibility.appStatus = appStatus?.isActive;
  }

  openEmployeeDetail(userId: string) {
    this.router.navigateByUrl(`cloud/people/${userId}`);
  }

  async openAttendanceMenu() {
    if (this.loading) {
      return;
    }
    await this.fetchRequiredAttendanceData();
    this.attendaceMenuTrigger.openMenu();
  }

  async fetchRequiredAttendanceData() {
    this.loading = true;
    this.injector.get(PrivateAmplitudeService).logEvent('checked attendance data in shiftplan', {
      category: 'Shiftplan',
      subcategory: 'Schedule',
    });

    try {
      if (check.not.assigned(this.attendance.startDay)) {
        this.attendanceVisibility.notViewPermission = true;
        this.loading = false;
        return;
      }

      this.isCurrentMonth = this.checkCurrentMonth();
      await this.getAttendanceAppStatus();

      if (!this.attendanceVisibility.tracking) {
        this.myAttendance = undefined;
        this.loading = false;
        return;
      }
      const myAttendance = await this.injector.get(ShiftPlanAttendanceService).getAttendanceData({
        startDay: this.attendance.startDay,
        userIds: [this.employee._id],
        onlyOvertime: false,
      });
      this.myAttendance = (myAttendance?.[this.employee._id] as IShiftplanAttendanceData) ?? {
        ...this.injector.get(ShiftPlanAttendanceService).defaultAttendanceData(),
        userId: this.employee._id,
      };
      this.loading = false;
    } catch {
      this.attendanceVisibility.notViewPermission = true;
      this.loading = false;
    }
  }

  checkCurrentMonth() {
    const currentMonth = {
      from: moment.utc(new Date()).startOf('month'),
      to: moment.utc(new Date()).endOf('month'),
    };
    return this.attendance.startDay.isBetween(currentMonth.from, currentMonth.to, 'day', '[]');
  }
}
