import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { InputSearchShiftPlanComponent } from '@app/cloud-features/shift-plan/components/input-search-shift-plan/input-search-shift-plan.component';
import { SettingsShiftPlanSelect } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-select/settings-shift-plan-select';
import {
  IShiftPlanWorkingAreaSettingsModel,
  ShiftPlanWorkingAreaSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-working-area.service';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { SHIFT_PLAN_ACTIVATION_STATUS_FINISHED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-shift-plan-employee-working-areas',
  templateUrl: 'shift-plan-employee-working-areas.component.html',
  styleUrls: ['shift-plan-employee-working-areas.component.scss'],
})
export class ShiftPlanEmployeeWorkingAreas implements OnInit {
  @Input() activateShiftPlanTranslation: any;
  @Input() shiftPlanSettingsTranslations: any;
  @Input() userWork: GenericSimpleModel;
  @Input() progressIndex: number;
  @Output() changeIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() workingAreasIsCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(SettingsShiftPlanSelect) selectComponent: SettingsShiftPlanSelect;
  @ViewChild(InputSearchShiftPlanComponent) searchComponent: InputSearchShiftPlanComponent;

  allWorkingAreas: Array<IWorkingAreaEmployeeModel> = [];
  selectedWorkingAreas: Array<IShiftPlanWorkingAreaSettingsModel> = [];
  selectedWorkingAreasInitialValues: Array<IShiftPlanWorkingAreaSettingsModel> = [];
  countriesTranslations: any;
  filteredWorkingAreas: Array<IShiftPlanWorkingAreaSettingsModel> = [];

  dataHasChanged: boolean = false;
  formerWorkingAreasData: Array<string> = [];

  constructor(private injector: Injector, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit() {
    this.fetchAllWorkingAreas();
    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.formerWorkingAreasData = this.userWork.data.workingAreas.slice();
    }
  }

  private async fetchAllWorkingAreas() {
    try {
      this.allWorkingAreas = await this.injector.get(ShiftPlanWorkingAreaSettingsService).getWorkingAreas();
      this.registerWorkingAreasData();
      this.checkIfFieldsAreCorrect();
    } catch (error) {}
  }

  registerWorkingAreasData() {
    this.selectedWorkingAreas = this.userWork.data.workingAreas ? this.userWork.data.workingAreas : [];
    if (check.assigned(this.userWork.data.workingAreas) && check.not.emptyArray(this.userWork.data.workingAreas)) {
      this.allWorkingAreas.forEach((iWorkingArea) => {
        iWorkingArea.selected = this.userWork.data.workingAreas.includes(iWorkingArea._id);
      });
    }
    this.selectedWorkingAreasInitialValues = this.selectedWorkingAreas;
    this.filteredWorkingAreas = this.allWorkingAreas;
  }

  changeSelectedWorkingAreas(selection: any) {
    this.selectedWorkingAreas = [];
    selection.forEach((iWorkingArea) => {
      if (iWorkingArea.selected) {
        this.selectedWorkingAreas.push(iWorkingArea._id);
      }
    });
    const checkIfWorkingAreasAreTheSame = _.isEmpty(_.xor(this.selectedWorkingAreasInitialValues, this.selectedWorkingAreas));
    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataHasChanged = !checkIfWorkingAreasAreTheSame;
    }
    this.checkIfFieldsAreCorrect();
  }

  nextStep() {
    this.saveData();
    this.progressIndex++;
    this.changeIndex.emit(this.progressIndex);
  }

  previousStep() {
    this.saveData();
    this.progressIndex--;
    this.changeIndex.emit(this.progressIndex);
  }

  checkIfFieldsAreCorrect() {
    const fieldsAreCorrect = check.not.emptyArray(this.selectedWorkingAreas);
    this.workingAreasIsCompleted.emit(fieldsAreCorrect);
  }

  async saveData() {
    this.userWork.data.workingAreas = [];
    this.userWork.data.workingAreas = this.selectedWorkingAreas;
    await this.injector.get(UserWorkService).updateById(this.userWork.data._id, { workingAreas: this.selectedWorkingAreas });

    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataSaved.emit(true);
    }
  }

  cancelChanges() {
    this.userWork.data.workingAreas = this.formerWorkingAreasData;
    this.registerWorkingAreasData();
    this.checkIfFieldsAreCorrect();

    // Force child components to update
    this.selectComponent.data = this.filteredWorkingAreas;
    this.selectComponent.checkCounter();
    this.searchComponent.searchTerm = '';
  }

  saveSnackbar() {
    this.injector.get(MatLegacySnackBar).open(this.activateShiftPlanTranslation.changesSuccessful, 'OK', {
      duration: 5000,
    });
  }
}

export interface IWorkingAreaEmployeeModel {
  _id?: string;
  name: string;
  selected?: boolean;
}
