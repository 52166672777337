import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-input-simple-color-picker',
  templateUrl: 'input-simple-color-picker.component.html',
  styleUrls: ['input-simple-color-picker.component.scss']
})
export class InputSimpleColorPickerComponent implements OnInit {
  colors: Array<any> = [];

  @Input() compactColors: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() value: string = '';
  @Input() availableColors?: Array<string>;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    const availableColors = this.availableColors || userColorConstants;
    this.colors = Object.keys(availableColors).reduce((total: { colorKey: string, value: string }[], iColorKey: string) => {
      if (this.value === iColorKey) {
        this.value = iColorKey;
      }

      if (iColorKey !== 'default') {
        const color = {
          colorKey: iColorKey,
          value: availableColors[iColorKey]
        };
        total.push(color);
      }
      return total;
    }, []);
  }

  onValueChange(newColorKey: string): void {
    if (check.not.assigned(newColorKey) || this.readOnly === true) {
      return;
    }

    this.value = newColorKey;
    this.valueChange.emit(newColorKey);
  }
}
