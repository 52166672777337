<div *ngIf="parentTranslation" #timeOffDetailContainer @fadeInOutAnimation class="sptod-container kenjo-border" (click)="onClick($event)">
  <orgos-column-container [centerColumns]="true" class="kenjo-mb-20px">
    <kenjo-icon orgosColumn="0" [size]="24">time_off_menu</kenjo-icon>
    <div orgosColumn class="kenjo-font-size-20px kenjo-pl-5px">{{ parentTranslation.timeOffDetailsTitle }}</div>
    <kenjo-icon orgosColumn="0" [size]="24" (click)="closeDetails()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      >clear</kenjo-icon
    >
  </orgos-column-container>
  <div *ngIf="timeOffDetail && !disconnected" class="sptod-inside-container">
    <!-- Type  -->
    <orgos-column-container [centerColumns]="true" class="kenjo-mb-20px">
      <div orgosColumn class="sptod-label">{{ parentTranslation.type }}</div>
      <orgos-column-container orgosColumn="2" [centerColumns]="true">
        <div
          orgosColumn="0"
          class="sptod-dot kenjo-mr-10px"
          [style.background-color]="USER_COLOR_CONSTANTS[timeOffDetail?._timeOffTypeColor]"
        ></div>
        <kenjo-truncate-text orgosColumn class="kenjo-font-size-14px sptod-name kenjo-font-color-text-333333">{{
          timeOffDetail?._timeOffTypeName
        }}</kenjo-truncate-text>
      </orgos-column-container>
    </orgos-column-container>

    <!-- Status  -->
    <orgos-column-container [centerColumns]="true" class="kenjo-mb-20px">
      <div orgosColumn class="sptod-label">{{ parentTranslation.status }}</div>
      <orgos-column size="2">
        <orgos-chip [color]="statusMaps.color[timeOffDetail?.status]">{{ statusMaps.list[timeOffDetail?.status] | uppercase }}</orgos-chip>
      </orgos-column>
    </orgos-column-container>

    <!-- Period  -->
    <orgos-column-container class="kenjo-mb-20px">
      <div orgosColumn class="sptod-label">{{ parentTranslation.period }}</div>
      <orgos-column-container orgosColumn="2">
        <orgos-colum orgosColumn="0" class="sptod-period-time">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-font-weight-bold">
            {{ periodTime.from.date }}
          </div>
          <div class="kenjo-font-size-12px kenjo-font-color-text-333333">
            {{ periodTime.from.hour }}
          </div>
        </orgos-colum>
        <kenjo-icon orgosColumn="0" [size]="24" class="sptod-arrow kenjo-font-color-primary-5993e3">arrow_forward</kenjo-icon>
        <orgos-colum orgosColumn="0" class="sptod-period-time">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-font-weight-bold">
            {{ periodTime.to.date }}
          </div>
          <div class="kenjo-font-size-12px kenjo-font-color-text-333333">
            {{ periodTime.to.hour }}
          </div>
        </orgos-colum>
      </orgos-column-container>
    </orgos-column-container>

    <!-- Total time  -->
    <orgos-column-container class="kenjo-mb-20px" [centerColumns]="true">
      <div orgosColumn class="sptod-label">{{ parentTranslation.totalTime }}</div>
      <orgos-column *ngIf="timeOffDetail?._policyType === 'Hour'" size="2">{{ timeOffDetail.duration | duration }}</orgos-column>
      <orgos-column *ngIf="timeOffDetail?._policyType === 'Day'" size="2"
        >{{ timeOffDetail.duration }} {{ timeOffDetail.duration === 1 ? parentTranslation.day : parentTranslation.days }}</orgos-column
      >
    </orgos-column-container>

    <!-- Activity type  -->
    <orgos-column-container class="kenjo-mb-20px" [centerColumns]="true">
      <div orgosColumn class="sptod-label">{{ parentTranslation.activityType }}</div>
      <orgos-column size="2">
        <div class="sptod-activity-type kenjo-ph-10px kenjo-font-size-12px">{{ parentTranslation[timeOffDetail?._workTime] }}</div>
      </orgos-column>
    </orgos-column-container>

    <!-- Description  -->
    <orgos-column-container class="kenjo-mb-30px">
      <div orgosColumn class="sptod-label">{{ parentTranslation.description }}</div>
      <orgos-column size="2">
        <kenjo-simple-truncate-text [vertical]="true" [lines]="4" class="sptod-description">
          {{ timeOffDetail?.description }}
        </kenjo-simple-truncate-text>
      </orgos-column>
    </orgos-column-container>

    <!-- Manage request  -->
    <orgos-column-container *ngIf="showManageRequest">
      <orgos-column></orgos-column>
      <orgos-column size="0">
        <a (click)="goToManageRequest()">{{ parentTranslation.manageRequest }}</a>
      </orgos-column>
    </orgos-column-container>
  </div>
  <div *ngIf="!timeOffDetail && !disconnected" class="sptod-empty-details">
    <div>
      <kenjo-icon [size]="63">event_busy</kenjo-icon>
      <div class="no-connection kenjo-font-size-20px kenjo-font-color-text-333333 kenjo-mt-10px">
        <div class="kenjo-text-align-center">{{ parentTranslation.timeOffCancelled }}</div>
        <div class="kenjo-text-align-center">{{ parentTranslation.refreshForUpdates }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="disconnected" class="sptod-empty-details">
    <div>
      <kenjo-icon [size]="63">cloud_off</kenjo-icon>
      <div class="no-connection kenjo-font-size-20px kenjo-font-color-text-333333 kenjo-mt-10px">{{ parentTranslation.noConnection }}</div>
    </div>
  </div>
</div>
