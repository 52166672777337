import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class SettingsCalendarIntegrationService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/calendar-settings-db`;

  constructor(private injector: Injector) {}

  async getCalendarSettings(): Promise<ICalendarIntegrationSettings> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try {
      const result: ICalendarIntegrationSettings = await this.injector.get(HttpClient).get<ICalendarIntegrationSettings>(`${this.URL}/`, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, SettingsCalendarIntegrationService.name, 'getCalendarSettings')
    }
  }

  async update(id: string, calendarSettings: ICalendarIntegrationSettings): Promise<any> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try {
      const result = await this.injector.get(HttpClient).put(`${this.URL}/${id}`, calendarSettings, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, SettingsCalendarIntegrationService.name, 'update');
    }
  }
}

export interface ICalendarIntegrationSettings {
  _id?: string;
  enableICalIntegration?: boolean;
  showAwayOnICalIntegration?: boolean;
}
