<div class="kenjo-p-40px kenjo-flex-vertical kenjo-flex-gap-30px">
  <div class="kenjo-flex-vertical kenjo-flex-center-vertically">
    <div class="kenjo-flex-align-self-end">
      <kenjo-icon [size]="24" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()">clear</kenjo-icon>
    </div>
    <div class="kenjo-flex-vertical kenjo-flex-gap-20px kenjo-text-align-center">
      <div><img src="/assets/images/emptyState.svg" /></div>
      <div class="kenjo-flex-vertical kenjo-flex-gap-10px kenjo-flex-center-vertically kenjo-text-align-center">
        <div class="kenjo-font-weight-bold dad-text">{{ translations.title }}</div>
        <div class="dad-text kenjo-font-color-light-text-757575">{{ translations.preActionCallText }} <a (click)="callToActionClicked()">{{ translations.actionCall }}</a> {{ translations.postActionCallText }}</div>
      </div>
    </div>
  </div>
  <div class="kenjo-flex-align-self-end">
    <orgos-button-raised (click)="buttonClicked()" color="Success">{{ translations.okGotIt }}</orgos-button-raised>
  </div>
</div>
