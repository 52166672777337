import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as _ from 'lodash';

@Pipe({
  name: 'splitName',
  pure: false
})
export class SplitNamePipe implements PipeTransform {
  private latestRawValue: string;
  private latestComputedValue: string | null = null;

  constructor() {}

  transform(value: string, display: 'name' | 'lastName'): string | null {
    if (check.not.assigned(value) || check.not.string(value)) {
      return null;
    }
    if (_.isEqual(this.latestRawValue, value)) {
      return this.latestComputedValue;
    }

    this.latestRawValue = value;
    this.latestComputedValue = this.#splitDisplayName(value, display);

    return this.latestComputedValue;
  }

  #splitDisplayName(displayName: string, display: 'name' | 'lastName') {
    const splittedName = displayName.split(' ');
    return display === 'name' ? splittedName[0] : splittedName[1];
  }
}
