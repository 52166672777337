import { Component, Input } from '@angular/core';

@Component({
  selector: 'kenjo-shiftplan-pdf-label',
  templateUrl: './shiftplan-pdf-label.component.html',
  styleUrls: ['./shiftplan-pdf-label.component.scss'],
})
export class ShiftplanPdfLabelComponent {
  HOLIDAYS_EXAMPLE_NUMBER = '00';

  @Input() labelHeader: string = '';
  @Input() labelText: string = '';
  @Input() status: 'published' | 'draft' | 'holiday' | 'title' | 'time-off';

  constructor() {}
}
