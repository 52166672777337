import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import * as check from 'check-types';

import { ISelectOption } from '../../core/select-option';
import { PicklistsService } from '../../services/core/picklists.service';

@Component({
  selector: 'orgos-input-simple-radio',
  templateUrl: 'input-simple-radio.component.html',
  styleUrls: ['input-simple-radio.component.scss']
})
export class InputSimpleRadioComponent {
  miscTranslation: any = {};

  @Input() options: Array<ISelectOption> = [];
  @Input()
  set picklist(picklistName: string) {
    this.picklistService
      .getPicklist(picklistName)
      .then((picklist: object) => {
        this.options = Object.keys(picklist).reduce((tempOptions: Array<ISelectOption>, value: string) => {
          const picklistOption: ISelectOption = {
            name: picklist[value],
            value: value
          };

          tempOptions.push(picklistOption);

          return tempOptions;
        }, []);
      })
      .catch(() => {
        this.options = [];
      });
  }

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() value: any;
  @Input() width: any;
  @Input() name: string = '';

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.isr-inline') @Input() inline: boolean = false;
  @HostBinding('class.isr-disabled') @Input() disabled: boolean = false;

  constructor(private picklistService: PicklistsService) {}

  getValueName(): string {
    if (check.not.assigned(this.value)) {
      return '';
    }

    const option = this.options.find((iOption: ISelectOption) => {
      return iOption.value === this.value;
    });

    if (check.not.assigned(option)) {
      return '';
    }

    return option.name;
  }
}
