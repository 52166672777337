import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

import { IAttendanceSummaryModel } from '../models/attendance-summary.model';

@Injectable({
  providedIn: 'root'
})
export class AttendanceSummaryService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/attendance-summary-db`;

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  create(data: any): Promise<IAttendanceSummaryModel> {
    return new Promise<IAttendanceSummaryModel>((resolve, reject) => {
      this.genericService
        .create(this.MICROSERVICE_URL, data)
        .then((AttendanceSummary: IAttendanceSummaryModel) => {
          resolve(AttendanceSummary);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IAttendanceSummaryModel> {
    return new Promise<IAttendanceSummaryModel>((resolve, reject) => {
      this.genericService
        .getById(this.MICROSERVICE_URL, id)
        .then((AttendanceSummary: IAttendanceSummaryModel) => {
          resolve(AttendanceSummary);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryService.name, 'getById'));
        });
    });
  }

  getAttendanceSummaryUserByDate(data: any): Promise<IAttendanceSummaryModel> {
    return new Promise<IAttendanceSummaryModel>((resolve, reject) => {
      this.genericService
        .find(this.MICROSERVICE_URL, data)
        .then((attendanceSummary: any) => {
          resolve(attendanceSummary);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryService.name, 'getAttendanceSummaryUserByDate'));
        });
    });
  }

  find(data: any): Promise<Array<IAttendanceSummaryModel>> {
    return new Promise<Array<IAttendanceSummaryModel>>((resolve, reject) => {
      this.genericService
        .find(this.MICROSERVICE_URL, data)
        .then((attendanceSummary: any) => {
          resolve(attendanceSummary);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryService.name, 'getAttendanceSummaryUserByDate'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.MICROSERVICE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryService.name, 'updateById'));
        });
    });
  }

  recalculateUsers(userIds) {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.injector
        .get(HttpClient)
        .post(`${this.MICROSERVICE_URL}/recalculate`, { userIds }, httpOptions)
        .toPromise()
        .then((setAsDefaultResult) => {
          resolve(setAsDefaultResult);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryService.name, 'calculateOvertime'));
        });
    });
  }

  calculateOvertime(attendanceSummaryId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.injector
        .get(HttpClient)
        .put(`${this.MICROSERVICE_URL}/summarize/${attendanceSummaryId}`, {}, httpOptions)
        .toPromise()
        .then((setAsDefaultResult) => {
          resolve(setAsDefaultResult);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AttendanceSummaryService.name, 'calculateOvertime'));
        });
    });
  }
}
