import { Injectable, Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { UppyDialog } from '@app/common-dialogs/uppy/dialog/uppy.dialog';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { IUploadPictureOptions } from '@app/standard/services/core/uppy-helper.service';
import { FileMetadataService, IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import AwsS3 from '@uppy/aws-s3';
import { Uppy, UppyOptions } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import DropTarget from '@uppy/drop-target';
import FileInput from '@uppy/file-input';
import ImageEditor from '@uppy/image-editor';
import Webcam from '@uppy/webcam';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root',
})
export class PrivateUppyService {
  private DOCUMENTS_IMPORT_INTERNATIONALIZATION: string = 'documents-import-page';

  constructor(private injector: Injector, private genericService: GenericService) {}

  public picker(
    pickerOptions: UppyOptions,
    isImage: boolean = false,
    imageOptions: IUploadPictureOptions = {},
    uploadWithPermanentUrlMetadata: boolean = false,
    onClose = () => {}
  ): Promise<IFileMetadata> {
    return new Promise<IFileMetadata>(async (resolve, reject) => {
      const uploader = this.injector.get(MatLegacyDialog).open(UppyDialog);
      const uppyClient = await this.#getClient(pickerOptions, isImage, imageOptions, false, uploadWithPermanentUrlMetadata);
      (uppyClient.getPlugin('Dashboard') as any).openModal();
      uppyClient.on('dashboard:modal-closed', () => {
        onClose();
        uploader.close();
      });

      uppyClient.on('file-editor:cancel', () => {
        uppyClient.cancelAll({ reason: 'user' });
      });

      uppyClient.on('complete', async (result) => {
        const keyParts = (result.successful[0].meta['key'] as string).split('/');
        const fileNameInS3: string = keyParts.pop();

        const temporalUrl = await this.injector.get(FileMetadataService).getTemporalUrl(fileNameInS3);

        const fileMetadata: IFileMetadata = {
          _fileName: result.successful[0].name,
          _fileExtension: result.successful[0].extension ? result.successful[0].extension : '',
          _fileNameInS3: fileNameInS3,
          _fileMimetype: result.successful[0].type ? result.successful[0].type : ' ',
          _fileSizeInBytes: result.successful[0].size,
          _uploadedById: this.injector.get(AuthenticationService).getLoggedUser()._id,
          _url: temporalUrl,
        };

        uploader.close();
        (uppyClient.getPlugin('Dashboard') as any).closeModal();
        uppyClient.close();

        resolve(fileMetadata);
      });
    });
  }

  public multipleFilesPicker(
    pickerOptions: UppyOptions,
    isImage: boolean = false,
    imageOptions: IUploadPictureOptions = {},
    uploadWithPermanentUrlMetadata: boolean = false
  ): Promise<Array<IFileMetadata>> {
    return new Promise<Array<IFileMetadata>>(async (resolve, reject) => {
      const uploader = this.injector.get(MatLegacyDialog).open(UppyDialog);
      const uppyClient = await this.#getClient(pickerOptions, isImage, imageOptions, false, uploadWithPermanentUrlMetadata);
      (uppyClient.getPlugin('Dashboard') as any).openModal();
      uppyClient.on('dashboard:modal-closed', () => {
        uploader.close();
      });
      uppyClient.on('complete', async (result: any) => {
        const fileMetadatas = [];

        for (const iSuccessful of result.successful) {
          const keyParts = iSuccessful.meta['key'].split('/');
          const fileNameInS3: string = keyParts.pop();

          const temporalUrl = await this.injector.get(FileMetadataService).getTemporalUrl(fileNameInS3);

          const iFileMetadata: IFileMetadata = {
            _fileName: iSuccessful.name,
            _fileExtension: iSuccessful.extension ? iSuccessful.extension : '',
            _fileNameInS3: fileNameInS3,
            _fileMimetype: iSuccessful.type ? iSuccessful.type : ' ',
            _fileSizeInBytes: iSuccessful.size,
            _uploadedById: this.injector.get(AuthenticationService).getLoggedUser()._id,
            _url: temporalUrl,
          };

          fileMetadatas.push(iFileMetadata);
        }

        uploader.close();
        (uppyClient.getPlugin('Dashboard') as any).closeModal();
        uppyClient.close();

        resolve(fileMetadatas);
      });
    });
  }

  public getDropClient(
    dropAreaCssSelector: string,
    pickerOptions: UppyOptions,
    uploadStarted?: () => void,
    uploadProgress?: (progress) => void,
    dragOptions?: {
      onDragOver?: () => void;
      onDragLeave?: () => void;
    },
    restrictionError?: (fileName, fileSize, error) => void,
    isWhistleblower?: boolean
  ): Promise<Array<IFileMetadata>> {
    return new Promise<Array<IFileMetadata>>((resolve, reject) => {
      const client = new Uppy({ ...pickerOptions, autoProceed: true });

      if (check.assigned(uploadStarted)) {
        client.on('upload', uploadStarted);
      }

      if (check.assigned(uploadProgress)) {
        client.on('progress', uploadProgress);
      }
      if (check.assigned(restrictionError)) {
        client.on('restriction-failed', (file, error) => {
          let errorType;
          if (check.assigned(error?.message)) {
            if (error.message.includes('exceeds maximum allowed size of')) {
              errorType = AttachmentError.MAX_SIZE;
            } else if (error.message.includes('Cannot add the duplicate file')) {
              errorType = AttachmentError.DUPLICATED;
            } else if (/You can only upload [0-9]+ files/g.test(error.message)) {
              errorType = AttachmentError.MAX_NUM;
            } else if (error.message.includes('You can only upload:')) {
              errorType = AttachmentError.FORMAT;
            }
          }
          restrictionError(file.name, file.data?.size, errorType);
        });
      }

      const options: any = { target: dropAreaCssSelector };

      if (check.assigned(dragOptions?.onDragOver)) {
        options.onDragOver = () => dragOptions.onDragOver();
      }

      if (check.assigned(dragOptions?.onDragLeave)) {
        options.onDragLeave = () => dragOptions.onDragLeave();
      }

      client
        .use(DropTarget, options)
        .use(FileInput, {
          target: dropAreaCssSelector,
        })
        .use(AwsS3, {
          companionUrl: `${environment.PEOPLE_CLOUD_APP_URL}/companion`,
          companionHeaders: {
            'uppy-auth-token': `${isWhistleblower ? 'Whistleblower ' : ''}${this.injector
              .get(AuthenticationService)
              .getAuthorizationHeader()}`,
          },
        } as any)
        .on('complete', async (result: any) => {
          const fileMetadatas = [];

          for (const iSuccessful of result.successful) {
            const keyParts = iSuccessful.meta['key'].split('/');
            const fileNameInS3: string = keyParts.pop();

            const temporalUrl = await this.injector.get(FileMetadataService).getTemporalUrl(fileNameInS3, isWhistleblower);
            const iFileMetadata: IFileMetadata = {
              _fileName: iSuccessful.name,
              _fileExtension: iSuccessful.extension ? iSuccessful.extension : '',
              _fileNameInS3: fileNameInS3,
              _fileMimetype: iSuccessful.type ? iSuccessful.type : ' ',
              _fileSizeInBytes: iSuccessful.size,
              _uploadedById: this.injector.get(AuthenticationService).getLoggedUser()._id,
              _url: temporalUrl,
            };

            fileMetadatas.push(iFileMetadata);
          }

          client.close();

          resolve(fileMetadatas);
        });
    });
  }

  public upload(binaryFile: Blob, fileName: string, fileMimetype: string, uploadProgress?: (progress) => void): Promise<IFileMetadata> {
    return new Promise<IFileMetadata>((resolve, reject) => {
      const client = new Uppy({ autoProceed: true });

      if (check.assigned(uploadProgress)) {
        client.on('progress', uploadProgress);
      }

      client
        .use(AwsS3, {
          companionUrl: `${environment.PEOPLE_CLOUD_APP_URL}/companion`,
          companionHeaders: {
            'uppy-auth-token': this.injector.get(AuthenticationService).getAuthorizationHeader(),
          },
        } as any)
        .on('complete', async (result) => {
          const keyParts = (result.successful[0].meta['key'] as string).split('/');
          const fileNameInS3: string = keyParts.pop();

          const temporalUrl = await this.injector.get(FileMetadataService).getTemporalUrl(fileNameInS3);

          const fileMetadata: IFileMetadata = {
            _fileName: result.successful[0].name,
            _fileExtension: result.successful[0].extension ? result.successful[0].extension : '',
            _fileNameInS3: fileNameInS3,
            _fileMimetype: result.successful[0].type ? result.successful[0].type : ' ',
            _fileSizeInBytes: result.successful[0].size,
            _uploadedById: this.injector.get(AuthenticationService).getLoggedUser()._id,
            _url: temporalUrl,
          };

          resolve(fileMetadata);
        });

      client.addFile({
        name: fileName,
        type: fileMimetype,
        data: binaryFile,
      });
    });
  }

  async #getClient(
    pickerOptions: UppyOptions,
    isImage: boolean = false,
    imageOptions: IUploadPictureOptions,
    showInline: boolean = true,
    uploadWithPermanentUrlMetadata: boolean = false
  ): Promise<Uppy> {
    const documentImportTranslations = await this.#getFieldsTranslations();
    pickerOptions.onBeforeFileAdded = (currentFile) => {
      currentFile.name = currentFile.name.replace("'", '_');
      currentFile.meta.name = currentFile.meta.name.replace("'", '_');
      return true;
    };
    const client = new Uppy(pickerOptions);

    const pluginObject = {
      target: Dashboard,
      companionUrl: `${environment.PEOPLE_CLOUD_APP_URL}/companion`,
      companionHeaders: {
        'uppy-auth-token': this.injector.get(AuthenticationService).getAuthorizationHeader(),
      },
    };

    const awsS3Option: any = { ...pluginObject };

    if (uploadWithPermanentUrlMetadata === true) {
      awsS3Option.allowedMetaFields = ['permanenturl'];
      client.on('file-added', (file) => {
        file.meta['permanenturl'] = true;
      });
    }

    client
      .use(Dashboard, {
        animateOpenClose: true,
        closeModalOnClickOutside: true,
        target: '.adc-uppy-container',
        inline: showInline,
        hideUploadButton: pickerOptions.autoProceed ? pickerOptions.autoProceed : false,
        autoOpenFileEditor: true,
        hideCancelButton: true,
        browserBackButtonClose: false,
        proudlyDisplayPoweredByUppy: false,
        locale: {
          strings: {
            dropPasteFiles: documentImportTranslations.dialogDropOrBrowseFiles.dropPasteFiles,
            browseFiles: documentImportTranslations.dialogDropOrBrowseFiles.browseFiles,
          },
        },
      })
      .use(AwsS3, awsS3Option);

    if (isImage) {
      client.use(Webcam, { ...pluginObject, modes: ['picture'] });
      client.on('file-editor:complete', () => {
        if (pickerOptions?.restrictions?.maxNumberOfFiles === 1) {
          client.upload();
        }
      });
      this.#setUpImageEditor(client, imageOptions);
    }
    return client;
  }

  #setUpImageEditor(client: Uppy, imageOptions: IUploadPictureOptions) {
    if (imageOptions?.makeTransformations !== true) {
      return;
    }

    client.use(ImageEditor, {
      id: 'ImageEditor',
      target: Dashboard,
      quality: 0.8,
      cropperOptions: {
        croppedCanvasOptions: {},
        viewMode: (imageOptions?.imageEditorOptions?.cropperOptions as any)?.viewMode ?? 1,
        aspectRatio: (imageOptions?.imageEditorOptions?.cropperOptions as any)?.aspectRatio ?? 1,
      },
      actions: {
        revert: imageOptions?.imageEditorOptions?.actions?.revert ?? false,
        cropWidescreen: imageOptions?.imageEditorOptions?.actions?.cropWidescreen ?? false,
        cropWidescreenVertical: imageOptions?.imageEditorOptions?.actions?.cropWidescreenVertical ?? false,
      } as any,
    });
  }

  #getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.DOCUMENTS_IMPORT_INTERNATIONALIZATION);
  }
}

export enum AttachmentError {
  DUPLICATED,
  MAX_NUM,
  MAX_SIZE,
  FORMAT,
}
