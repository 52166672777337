import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class DatevPreviewController {
  private DATEV_PREVIEW_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/datev/preview`;

  constructor(private injector: Injector) {}

  async getEmployeePreview(findQuery: any): Promise<any> {
    try {
      const httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
      };

      return await this.injector.get(HttpClient).post(`${this.DATEV_PREVIEW_URL}/employee`, findQuery, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevPreviewController.name, 'getEmployeePreview');
    }
  }

  async getPaymentPreview(findQuery: any): Promise<any> {
    try {
      const httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
      };

      return await this.injector.get(HttpClient).post(`${this.DATEV_PREVIEW_URL}/payment`, findQuery, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevPreviewController.name, 'getPaymentPreview');
    }
  }

  async getErrors(datevSettingsId: string, periodIdentifier: number, selectedTab: string): Promise<any> {
    try {
      const httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
      };

      return await this.injector.get(HttpClient).post(`${this.DATEV_PREVIEW_URL}/errors`, { datevSettingsId, periodIdentifier, selectedTab }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevPreviewController.name, 'getErrors');
    }
  }
}
