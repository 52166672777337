<div *ngIf="activateShiftPlanTranslation" class="ssp-full-container kenjo-border">
  <div class="ssp-data-container kenjo-p-20px">
    <orgos-column-container class="ssp-items-container">
      <div
        orgosColumn="0"
        *ngFor="let item of data"
        class="ssp-data kenjo-ph-10px kenjo-pv-5px kenjo-mr-10px"
        [class.selected]="item.selected"
        [style.backgroundColor]="item.selected && item.colour ? colorConstants[item.colour] : item.selected ? DEFAULT_SELECTED_BACKGROUND : DEFAULT_UNSELECTED_BACKGROUND"
      >
        <orgos-column-container centerColumns="true" (change)="checkCounter()">
          <div orgosColumn="0">{{item.name}}</div>
          <kenjo-icon *ngIf="!item.selected" (click)="select(item)" class="kenjo-cursor-pointer" orgosColumn="0" [size]="16">add </kenjo-icon>
          <kenjo-icon *ngIf="item.selected" (click)="clear(item)" class="kenjo-cursor-pointer" orgosColumn="0" [size]="16">clear </kenjo-icon>
        </orgos-column-container>
      </div>
    </orgos-column-container>
  </div>
  <div class="ssp-bottom-container kenjo-p-20px">
    <orgos-column-container>
      <div orgosColumn>{{selectedCounter}}/{{initialData.length}} {{dataType}} {{activateShiftPlanTranslation.selected}}</div>
      <orgos-action (click)="clearAll()" orgosColumn="0" color="Danger" class="kenjo-mr-20px"> {{activateShiftPlanTranslation.clearAllButton}} </orgos-action>
      <orgos-action (click)="selectAll()" orgosColumn="0"> {{activateShiftPlanTranslation.selectAllButton}} </orgos-action>
    </orgos-column-container>
  </div>
</div>
