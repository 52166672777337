import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { getPossibleValuesOfReference } from '@carlos-orgos/orgos-utils/metadata-describer';
import * as check from 'check-types';
import * as _ from 'lodash';

import { environment } from '../../../../../../environments/environment';
import { ISelectOption } from '../../../../../standard/core/select-option';
import { InternationalizationService } from '../../../../../standard/services/core/internationalization.service';
import { I18nDataPipe } from '../../../../components/i18n-data/i18n-data.pipe';
import { AuthenticationService } from '../../../../services/core/authentication.service';
import { PicklistsService } from '../../../../services/core/picklists.service';

@Component({
  selector: 'orgos-custom-permission-condition-component',
  templateUrl: 'custom-permission-condition.component.html',
  styleUrls: ['custom-permission-condition.component.scss']
})
export class CustomPermissionConditionComponent implements OnInit {
  pageTranslation: any = {};
  userWorkTranslation: any = {};
  filterTranslation: any = {};
  selectOperatorOptions: Array<ISelectOption>;
  selectFilterOptions: Array<ISelectOption>;
  selectExpectedValueOptions: Array<ISelectOption>;
  allCompanyOptions: Array<ISelectOption>;
  allDepartmentOptions: Array<ISelectOption>;
  allAreaOptions: Array<ISelectOption>;
  allTeamOptions: Array<ISelectOption>;
  allOfficeOptions: Array<ISelectOption>;
  calculatingValues: boolean = false;
  filterValueChosen: string;
  expectedValueChosen: string;

  @Input() permissionKey: string;
  @Input() permissionName: string;
  @Input() condition: any;
  @Output() updateInCondition: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCondition: EventEmitter<any> = new EventEmitter<any>();

  constructor(private injector: Injector) {
    this.selectFilterOptions = [];
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        if (this.permissionKey && this.permissionKey === 'goal') {
          // Specific conditions for goals
          if (check.not.assigned(this.condition.ownerInCollection)) {
            this.condition.ownerInCollection = {};
          }
          this.condition.ownerInCollection.collection = 'thisDocument';
          this.selectFilterOptions.push({ name: pageTranslation.assignedUsersOfGoal, value: 'goalAssignedToSubordinate' });
          this.selectFilterOptions.push({ name: pageTranslation.departmentOfGoal, value: 'departmentId' });
          this.selectFilterOptions.push({ name: pageTranslation.typeOfGoal, value: 'type' });
        } else if (this.permissionKey && this.permissionKey === 'project') {
          // Specific conditions for project
          if (check.not.assigned(this.condition.ownerInCollection)) {
            this.condition.ownerInCollection = {};
          }
          this.condition.ownerInCollection.collection = 'thisDocument';
          this.selectFilterOptions.push({ name: pageTranslation.teamMemberAmongSubordinates, value: 'teamMemberAmongSubordinates' });
          this.selectFilterOptions.push({ name: pageTranslation.companyOfProject, value: 'companyId' });
          this.selectFilterOptions.push({ name: pageTranslation.officeOfProject, value: 'officeId' });
          this.selectFilterOptions.push({ name: pageTranslation.departmentOfProject, value: 'departmentId' });
          this.selectFilterOptions.push({ name: pageTranslation.typeOfProject, value: 'projectType' });
        } else if (this.permissionKey && this.permissionKey === 'recruiting-options') {
          // Specific conditions for recruiting-options
          this.condition.ownerInCollection.collection = 'user-work';
        } else {
          // Standard permissions for collections that do not require specific permissions
          this.condition.ownerInCollection.collection = 'user-work';
          this.selectFilterOptions.push({ name: pageTranslation.directSubordinateEmployees, value: 'directSubordinateEmployees' });
          this.selectFilterOptions.push({ name: pageTranslation.subordinateEmployees, value: 'subordinateEmployees' });
        }
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-work-collection')
      .then((userWorkTranslation) => {
        this.userWorkTranslation = userWorkTranslation;
        if (!this.permissionKey || (this.permissionKey !== 'goal' && this.permissionKey !== 'project' && this.permissionKey !== 'recruiting-options' && this.permissionName !== 'c_addEmployees_custom')) {
          // Standard permissions for collections that do not require specific permissions
          this.selectFilterOptions.push({ name: userWorkTranslation.companyId, value: 'companyId' });
          this.selectFilterOptions.push({ name: userWorkTranslation.officeId, value: 'officeId' });
          this.selectFilterOptions.push({ name: userWorkTranslation.areaIds, value: 'areaIds' });
          this.selectFilterOptions.push({ name: userWorkTranslation.departmentId, value: 'departmentId' });
          this.selectFilterOptions.push({ name: userWorkTranslation.teamIds, value: 'teamIds' });
        } else if (this.permissionKey === 'employees' && this.permissionName === 'c_addEmployees_custom') {
          this.selectFilterOptions.push({ name: userWorkTranslation.companyId, value: 'companyId' });
          this.selectFilterOptions.push({ name: userWorkTranslation.officeId, value: 'officeId' });
          this.selectFilterOptions.push({ name: userWorkTranslation.departmentId, value: 'departmentId' });
        } else if (this.permissionKey === 'recruiting-options') {
          this.selectFilterOptions.push({ name: userWorkTranslation.companyId, value: 'companyId' });
          this.selectFilterOptions.push({ name: userWorkTranslation.officeId, value: 'officeId' });
          this.selectFilterOptions.push({ name: userWorkTranslation.departmentId, value: 'departmentId' });
        }
      })
      .catch(() => {
        this.userWorkTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('workflow-filter-component')
      .then((filterTranslation) => {
        this.filterTranslation = filterTranslation;
        if (this.condition?.ownerInCollection?.field === 'areaIds' || this.condition?.ownerInCollection?.field === 'teamIds') {
          this.initArrayOperatorOptions();
        } else {
          this.initSimpleOperatorOptions();
        }
      })
      .catch(() => {
        this.filterTranslation = {};
      });
  }

  ngOnInit(): void {
    if (check.assigned(this.condition) && check.nonEmptyObject(this.condition)) {
      // Init existing condition:
      let filterValue;
      let expectedValue;
      // 1. Set the filter
      if (this.permissionKey === 'goal' && check.assigned(this.condition.subordinatesOnly) && this.condition.subordinatesOnly === true) {
        filterValue = 'goalAssignedToSubordinate';
      } else if (this.permissionKey === 'project' && check.assigned(this.condition.subordinatesOnly) && this.condition.subordinatesOnly === true) {
        filterValue = 'teamMemberAmongSubordinates';
      } else if (check.assigned(this.condition.subordinatesOnly) && this.condition.subordinatesOnly === true) {
        filterValue = 'subordinateEmployees';
      } else if (check.assigned(this.condition.directSubordinatesOnly) && this.condition.directSubordinatesOnly === true) {
        filterValue = 'directSubordinateEmployees';
      } else if (check.assigned(this.condition.ownerInCollection.field) && check.nonEmptyString(this.condition.ownerInCollection.field)) {
        filterValue = this.condition.ownerInCollection.field;
      }

      // 2. Set the expected value
      if (check.assigned(this.condition.equalsToStaticValue) && check.nonEmptyString(this.condition.equalsToStaticValue)) {
        expectedValue = this.condition.equalsToStaticValue;
      } else if (check.assigned(this.condition.equalsToLoggedUserField) && check.assigned(this.condition.equalsToLoggedUserField.field) && check.nonEmptyString(this.condition.equalsToLoggedUserField.field) && this.condition.equalsToLoggedUserField.field === this.condition.ownerInCollection.field) {
        expectedValue = '-mine-';
      }

      this.filterValueChosen = filterValue;

      this.setFilterValuePromise(filterValue).then(() => {
        this.expectedValueChosen = expectedValue;
        this.changeInExpectedValue(expectedValue, false);
        this.changeInCondition();
      });
    }
  }

  private initSimpleOperatorOptions(): void {
    this.selectOperatorOptions = [];
    this.selectOperatorOptions.push({ name: this.filterTranslation.equalToOperator, value: 'eq' });
    this.selectOperatorOptions.push({ name: this.filterTranslation.notEqualToOperator, value: 'ne' });
  }

  private initArrayOperatorOptions(): void {
    this.selectOperatorOptions = [];
    this.selectOperatorOptions.push({ name: this.filterTranslation.containsOperator, value: 'in' });
    this.selectOperatorOptions.push({ name: this.filterTranslation.doesNotContainOperator, value: 'nin' });
  }

  public changeInFilterValue(filterValue: string, emitUpdate: boolean = true): void {
    if (filterValue === 'areaIds' || filterValue === 'teamIds') {
      this.initArrayOperatorOptions();
    } else {
      this.initSimpleOperatorOptions();
    }
    this.calculatingValues = true;
    this.expectedValueChosen = null;
    this.selectExpectedValueOptions = [];

    this.condition.subordinatesOnly = false;
    this.condition.directSubordinatesOnly = false;
    this.condition.equalsToStaticValue = '';
    if (check.not.assigned(this.condition.equalsToLoggedUserField)) {
      this.condition.equalsToLoggedUserField = {};
    }
    this.condition.equalsToLoggedUserField.field = '';
    this.setFilterValuePromise(filterValue).then(() => {
      if (emitUpdate === true) {
        this.changeInCondition();
      }
    });
  }

  private setFilterValuePromise(filterValue): Promise<void> {
    return new Promise((resolve, reject) => {
      this.calculatingValues = true;
      if (check.not.assigned(this.condition.ownerInCollection)) {
        this.condition.ownerInCollection = {};
      }
      if (filterValue === 'subordinateEmployees') {
        this.condition.operator = '';
        this.condition.subordinatesOnly = true;
        this.condition.directSubordinatesOnly = false;
        this.calculatingValues = false;
        resolve();
      } else if (filterValue === 'directSubordinateEmployees') {
        this.condition.operator = '';
        this.condition.subordinatesOnly = false;
        this.condition.directSubordinatesOnly = true;
        this.calculatingValues = false;
        resolve();
      } else if (filterValue === 'goalAssignedToSubordinate') {
        this.condition.ownerInCollection.field = 'assignedUsers';
        this.condition.operator = 'eq';
        this.condition.subordinatesOnly = true;
        this.condition.directSubordinatesOnly = false;
        this.calculatingValues = false;
        resolve();
      } else if (filterValue === 'teamMemberAmongSubordinates') {
        this.condition.ownerInCollection.field = '_userIds';
        this.condition.operator = 'eq';
        this.condition.subordinatesOnly = true;
        this.condition.directSubordinatesOnly = false;
        this.calculatingValues = false;
        resolve();
      } else if (this.permissionKey && this.permissionKey === 'goal' && filterValue === 'type') {
        this.condition.ownerInCollection.field = filterValue;
        this.injector
          .get(PicklistsService)
          .getPicklist('goalType')
          .then((goalTypes) => {
            this.selectExpectedValueOptions = [];
            Object.keys(goalTypes).forEach((iGoalTypeKey) => {
              this.selectExpectedValueOptions.push({ name: goalTypes[iGoalTypeKey], value: iGoalTypeKey });
            });
            this.calculatingValues = false;
            resolve();
          });
      } else if (this.permissionKey && this.permissionKey === 'project' && filterValue === 'projectType') {
        this.condition.ownerInCollection.field = filterValue;
        this.injector
          .get(PicklistsService)
          .getPicklist('projectType')
          .then((projectTypes) => {
            this.selectExpectedValueOptions = [];
            Object.keys(projectTypes).forEach((iProjectTypeKey) => {
              this.selectExpectedValueOptions.push({ name: projectTypes[iProjectTypeKey], value: iProjectTypeKey });
            });
            this.calculatingValues = false;
            resolve();
          });
      } else {
        this.condition.ownerInCollection.field = filterValue;
        this.initLookupSelectOption(filterValue)
          .then((selectOptions) => {
            this.selectExpectedValueOptions = selectOptions;
            const translationData = {
              fieldName: this.userWorkTranslation[this.condition.ownerInCollection.field]
            };
            let myLabel = this.injector.get(I18nDataPipe).transform(this.pageTranslation.loggedUserField, translationData);
            if (this.condition?.ownerInCollection?.field === 'areaIds' || this.condition?.ownerInCollection?.field === 'teamIds') {
              // when dealing with arrays, use a different label for -mine-
              let myLabel = this.injector.get(I18nDataPipe).transform(this.pageTranslation.loggedUserArrayField, translationData);
            }
            if (check.not.contains(_.map(this.selectExpectedValueOptions, 'value'), '-mine-')) {
              this.selectExpectedValueOptions.push({ name: myLabel, value: '-mine-' });
            }
            this.calculatingValues = false;
            resolve();
          })
          .catch(() => {
            resolve();
          });
      }
    });
  }

  public changeInExpectedValue(expectedValue: string, emitUpdate: boolean = true): void {
    if (expectedValue === '-mine-') {
      delete this.condition.equalsToStaticValue;
      this.condition.equalsToLoggedUserField.collection = 'user-work';
      this.condition.equalsToLoggedUserField.field = this.condition.ownerInCollection.field;
    } else {
      this.condition.equalsToStaticValue = expectedValue;
    }

    if (emitUpdate === true) {
      this.changeInCondition();
    }
  }

  private initLookupSelectOption(fieldName: string): Promise<Array<ISelectOption>> {
    if (fieldName === 'departmentId' && check.nonEmptyArray(this.allDepartmentOptions)) {
      return Promise.resolve(this.allDepartmentOptions);
    }

    if (fieldName === 'companyId' && check.nonEmptyArray(this.allCompanyOptions)) {
      return Promise.resolve(this.allCompanyOptions);
    }

    if (fieldName === 'officeId' && check.nonEmptyArray(this.allOfficeOptions)) {
      return Promise.resolve(this.allOfficeOptions);
    }

    if (fieldName === 'areaIds' && check.nonEmptyArray(this.allAreaOptions)) {
      return Promise.resolve(this.allAreaOptions);
    }

    if (fieldName === 'teamIds' && check.nonEmptyArray(this.allTeamOptions)) {
      return Promise.resolve(this.allTeamOptions);
    }

    return new Promise((resolve, reject) => {
      getPossibleValuesOfReference(this.injector.get(AuthenticationService).getAuthorizationHeader(), environment.PEOPLE_CLOUD_APP_URL, 'user-work', fieldName)
        .then((selectOptions: Array<ISelectOption>) => {
          if (fieldName === 'departmentId') {
            this.allDepartmentOptions = selectOptions;
          } else if (fieldName === 'companyId') {
            this.allCompanyOptions = selectOptions;
          } else if (fieldName === 'officeId') {
            this.allOfficeOptions = selectOptions;
          } else if (fieldName === 'areaIds') {
            this.allAreaOptions = selectOptions;
          } else if (fieldName === 'teamIds') {
            this.allTeamOptions = selectOptions;
          }
          resolve(selectOptions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public changeInCondition(): void {
    this.updateInCondition.emit(this.condition);
  }

  public deleteThisCondition(): void {
    this.deleteCondition.emit(this.condition);
  }
}
