<orgos-dialog-container [title]="titleText" (clickCloseButton)="closeDialog()" [attr.id]="componentId !== null ? componentId : null">
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 add-white-space">
    {{ subtitleText }}
  </div>
  <div *ngIf="bullets" class="kenjo-mt-20px kenjo-font-color-light-text-757575">
    <ul *ngFor="let bullet of bullets">
      <li>
        <div class="add-flex-container">
          {{ bullet.text }} <kenjo-icon class="kenjo-font-color-caution-ff8d0b kenjo-cursor-pointer" [size]="18" *ngIf="isPastAutoDeduct" [matTooltip]="attendancePolicy?.breakReminder?.autoDeductBreaks ? tooltipText : translations?.autoDeductDialog.tooltipOff">history</kenjo-icon>
        </div>
      </li>
    </ul>
  </div>
  <orgos-column-container class="kenjo-mt-40px add-wrap-buttons">
    <orgos-column size="1" class="add-empty-column"></orgos-column>
    <orgos-column *ngIf="cancelButtonText" size="0" class="add-flex-shrink">
      <orgos-button-raised [disabled]="cancelDisabled" color="Neutral" (click)="closeDialog()" class="add-close add-flex-shrink">{{
        cancelButtonText
      }}</orgos-button-raised>
    </orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="add-flex-shrink">
      <orgos-button-raised
        [disabled]="confirmDisabled"
        [color]="confirmButtonColor"
        class="kenjo-ml-20px add-confirm"
        (click)="confirmDialog()"
      >
        {{ confirmButtonText }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
