<orgos-dialog-container class="dialog-box" [title]="translation.title" (clickCloseButton)="closeDialog()">
  <orgos-column-container>
    <orgos-column class="kenjo-pr-20px">
      <orgos-text>{{translation.currentBalance}}</orgos-text>
    </orgos-column>
    <orgos-column>
      <orgos-text>{{currentBalance >0? '+': ''}}{{currentBalance | duration}}</orgos-text>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <orgos-text>{{translation.amountOfHours}}</orgos-text>
    </orgos-column>
    <orgos-column>
      <orgos-input-simple-number
        [step]="1"
        [min]="0"
        [max]="maxValues?.hours"
        class="kenjo-block"
        [(value)]="newBalance.hours"
        [changeValueOnBlur]="true"
        (blurInput)="parseBalanceHours(); preventWrongMinutesValue(); changeNewBalance()"
        [suffix]="translation.hoursUnits"
        class="ocp-small-picker kenjo-mr-20px"
      ></orgos-input-simple-number>
      <orgos-input-simple-number
        [step]="1"
        [min]="0"
        [max]="newBalance.hours === maxValues?.hours ? maxValues?.minutes : 59"
        class="kenjo-block"
        [(value)]="newBalance.minutes"
        [changeValueOnBlur]="true"
        (blurInput)="parseBalanceMinutes(); preventWrongMinutesValue($event); changeNewBalance()"
        [suffix]="translation.minutesUnits"
        class="ocp-small-picker"
      ></orgos-input-simple-number>
      <orgos-text *ngIf="newBalance.hours > 0 || newBalance.minutes > 0" class="dialog-inline">{{balanceAfterText}}</orgos-text>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <orgos-text>{{translation.currentSalary}}</orgos-text>
    </orgos-column>
    <orgos-column>
      <orgos-input-simple-number class="dialog-input kenjo-mr-20px" [step]="0.01" [(value)]="amount" [min]="0" (valueChange)="changeNewBalance()"></orgos-input-simple-number>
      <orgos-input-simple-select class="ocp-small-picker" [options]="currencyOptions" [(value)]="currency"></orgos-input-simple-select>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <orgos-text>{{translation.typeOfPay}}</orgos-text>
    </orgos-column>
    <orgos-column *ngIf="variableTypeOptions.length > 0">
      <orgos-input-simple-select class="kenjo-block" [options]="variableTypeOptions" [(value)]="variablePayTypeId"></orgos-input-simple-select>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <orgos-text>{{translation.payDate}}</orgos-text>
    </orgos-column>
    <orgos-column>
      <orgos-input-simple-date-picker class="kenjo-block" [(value)]="paymentDate"></orgos-input-simple-date-picker>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-pv-10px">
    <orgos-column class="kenjo-pr-20px">
      <orgos-text>{{translation.total}}</orgos-text>
    </orgos-column>
    <orgos-column>
      <orgos-text>{{totalAmount}} {{currency}}</orgos-text>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container>
    <orgos-column>
      <orgos-input-simple-text-area class="kenjo-block" [(value)]="comments" [label]="translation.comment"></orgos-input-simple-text-area>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{translation.cancel}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="save()" color="Success" [disabled]="saving || newBalance.hours === null || newBalance.minutes === null || (newBalance.hours === 0 && newBalance.minutes === 0) || !amount || !variablePayTypeId || !paymentDate">
        {{translation.save}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
