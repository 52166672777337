<orgos-column-container class="kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8 kenjo-pv-10px kenjo-ph-20px" centerColumns="true">
  <orgos-column size="1">
    <div *ngIf="!feature.useLogo" [class.kenjo-font-color-disabled-c4c4c4]="!feature.isActive">{{ miscTranslation[feature.appKey] }}</div>
    <div *ngIf="feature.appKey === 'datev'" style="display: flex;">
      <!-- WE NEED TO KEEP THIS CODE COMMENTED UNTIL WE GET THE DATEV PARTNERSHIP -->
      <!-- <img src="/assets/integrations/{{ feature.appKey }}.svg" class="fb-logo"
        [class.fb-logo-inactive]="!feature.isActive" /> -->
      <!-- WE WILL NEED TO REMOVE THIS CODE WHEN WE GET THE DATEV PARTNERSHIP -->
      {{ miscTranslation[feature.appKey] }}
    </div>
    <img *ngIf="feature.useLogo && feature.appKey !== 'datev'" src="/assets/integrations/{{ feature.appKey }}.svg" class="fb-logo" [class.fb-logo-inactive]="!feature.isActive" />
  </orgos-column>
  <orgos-column size="0" class="kenjo-pl-20px">
    <kenjo-feature-gating-icon *ngIf="!feature.isActive && showEntitlementIcon" [featureId]="entitlementRequiredToActivateFeature"></kenjo-feature-gating-icon>
    <orgos-action
      *ngIf="
        !disabled &&
        feature.hasSettings &&
        feature.isActive &&
        ((feature.appKey !== 'api-keys' && feature.appKey !== 'join' && feature.appKey !== 'datev' && feature.appKey !== 'slack' && feature.appKey !== 'payroll') || profileName === 'admin' || (profileName === 'hr-admin' && feature.appKey === 'datev'))
      "
      color="Primary"
      (click)="goToSettings()"
    >
      {{ pageTranslation.settings }}
    </orgos-action>
    <div class="kenjo-font-color-disabled-c4c4c4" *ngIf="!feature.isActive && !feature.lastSupportNotificationDate && !showEntitlementIcon">
      {{ pageTranslation.deactivated }}
    </div>
    <div class="kenjo-font-color-light-text-757575" *ngIf="!feature.isActive && feature.lastSupportNotificationDate && !showEntitlementIcon">
      {{ pageTranslation.activationRequested }}
    </div>
  </orgos-column>
  <orgos-column size="0" class="kenjo-pl-20px fb-toggle">
    <mat-slide-toggle
      [id]="FEATURE_MAT_SLIDE_TOGGLE_ID"
      *ngIf="!feature.hideActivationToggle"
      [matTooltip]="miscTranslation[feature.appKey + '__tooltip']"
      [disabled]="profileName !== 'admin' || disabled || !available || (!feature.isActive && feature.lastSupportNotificationDate)"
      [checked]="checked"
      (change)="changeActive()"
    ></mat-slide-toggle>
  </orgos-column>
</orgos-column-container>
