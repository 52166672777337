<div>
  <orgos-column-container cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-form-field orgosColumn="1" (click)="openDropdown()" class="ssd-input kenjo-cursor-pointer"
      [ngClass]="{ 'mat-form-field-invalid': selectedOptionError }">
      <mat-label>{{ label }} <span *ngIf="required">*</span></mat-label>
      <input matInput readonly [ngModel]="selectedOptionValue" [disabled]="readOnly"
        [ngClass]="{ 'kenjo-cursor-pointer': !readOnly }" />
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
      <mat-hint class="mat-error" *ngIf="selectedOptionError">{{ selectedOptionError }}</mat-hint>
    </mat-form-field>
  </orgos-column-container>
  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="displayDropdown"
    [cdkConnectedOverlayOffsetY]="-10" (overlayOutsideClick)="displayDropdown = false"
    (backdropClick)="displayDropdown = false" (detach)="displayDropdown = false">
    <div overlay class="ssd-overlay-container" [style.width]="width">
      <div class="ssd-options ssd-last ssd-disable-scrollbars">
        <ng-container *ngIf="options.length > 0">
          <div *ngFor="let option of options; last as isLast; first as isFirst" (click)="emitSelection(option.value)"
            [class.ssd-last]="isLast" [class.ssd-first]="isFirst" [class.kenjo-border-bottom]="!isLast"
            class="kenjo-color-white-background-ffffff kenjo-cursor-pointer ssd-option-container">
            <div *ngIf="!option.subtitle && !option.rightColText; else extendedMode" class="ssd-selectable-item">
              {{ option.name }}
            </div>
            <ng-template #extendedMode>
              <div class="ssd-extended-option kenjo-pv-15px kenjo-ph-20px">
                <div class="left-col">
                  <span class="kenjo-font-weight-bold"
                    [ngClass]="{ 'ssd-legacy-option': displayLegacyOptions && option.rightColText }">
                    {{ option.name }} <span *ngIf="option.rightColText && displayLegacyOptions">{{ option.rightColText
                      }}</span>
                  </span>
                  <div *ngIf="option.subtitle" class="kenjo-font-size-12px kenjo-mt-5px"
                    [ngClass]="{ 'ssd-legacy-option': displayLegacyOptions && option.rightColText }">
                    {{ option.subtitle }}
                  </div>
                </div>
                <div class="right-col kenjo-ml-20px kenjo-font-weight-bold"
                  *ngIf="option.rightColText && !displayLegacyOptions">
                  {{ option.rightColText }}
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>