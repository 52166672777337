import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { I18nDataPipe } from '../../../../../../components/i18n-data/i18n-data.pipe';
import { SearchComponent, SearchFunction } from '../../../../../../components/search/search.component';
import { GenericCacheModel } from '../../../../../../core/generic-cache-model';
import { ISelectOption } from '../../../../../../core/select-option';
import { InputValidation } from '../../../../../../core/validation/input-validation';
import { DepartmentService } from '../../../../../../services/company/department.service';
import { AuthenticationService } from '../../../../../../services/core/authentication.service';
import { InternationalizationService } from '../../../../../../services/core/internationalization.service';
import { GoalService, IGoalModel } from '../../../../../../services/performance-management/goal.service';
import { UserPersonalService } from '../../../../../../services/user/user-personal.service';

@Component({
  selector: 'orgos-create-goal-dialog',
  templateUrl: 'create-goal.dialog.html',
  styleUrls: ['create-goal.dialog.scss'],
})
export class CreateGoalDialog implements OnInit {
  translation: any = {};
  goalCacheModel: GenericCacheModel;
  wizardStep: number = 1;
  WIZARD_FINAL_STEP: number = 5;

  departments: Array<ISelectOption> = [];

  editName: number = -1;

  TYPE_INDIVIDUAL: string = picklists.GOAL_TYPE_INDIVIDUAL;
  TYPE_DEPARTMENT: string = picklists.GOAL_TYPE_DEPARTMENT;
  TYPE_BRAND: string = picklists.GOAL_TYPE_BRAND;

  STEP_TYPE_SELECTION: number = 1;
  STEP_DESCRIPTION: number = 2;
  STEP_ASSIGNMENT: number = 3;
  STEP_TRACKER: number = 4;
  STEP_KEYRESULTS: number = 5;
  TODAY = moment();
  changedKeyResult: string;
  addKeyResultWarning: boolean = false;

  goal: IGoalModel = {
    title: undefined,
    description: undefined,
    type: picklists.GOAL_TYPE_INDIVIDUAL,
    dueDate: undefined,
    status: picklists.GOAL_STATUS_NOTSTARTED,
    assignedUsers: [],
    keyResults: [{ title: '', isCompleted: false }],
  };

  TRACKER_PROGRESS: string = 'progress';
  TRACKER_KEY: string = 'key';
  trackerType: string = this.TRACKER_PROGRESS;

  creatingGoal: boolean = false;

  titleValidation: InputValidation;
  departmentValidation: InputValidation;
  emptyKeys: boolean;

  numberOfAssingedUsers: string = '';
  searchResults: Array<any> = [];
  allUsers: Array<any>;
  userIdToUserPersonal: any;

  keyToEdit: number = -1;

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  constructor(
    public dialogRef: MatLegacyDialogRef<CreateGoalDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private snackBar: MatLegacySnackBar,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.initWizardStep(this.wizardStep);

    if (check.assigned(this.data) && check.assigned(this.data.goal)) {
      this.goalCacheModel = new GenericCacheModel(this.injector, this.data.goal, GoalService, loggedUser._id);
      if (
        check.assigned(this.goalCacheModel.data.keyResults) &&
        check.array(this.goalCacheModel.data.keyResults) &&
        check.nonEmptyArray(this.goalCacheModel.data.keyResults)
      ) {
        this.trackerType = this.TRACKER_KEY;
      } else {
        this.trackerType = this.TRACKER_PROGRESS;
      }
    } else {
      this.goalCacheModel = new GenericCacheModel(this.injector, this.goal, GoalService, loggedUser._id);
      this.goalCacheModel.data.assignedUsers.push(loggedUser[fieldConstants.ID]);
      this.emptyKeys = true;
    }

    this.initWizardStep(this.wizardStep);
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('goals-misc')
      .then((dialogTranslation) => {
        this.translation = dialogTranslation;
        this.numberOfAssingedUsers = this.injector
          .get(I18nDataPipe)
          .transform(this.translation.usersAssigned, { numberOfUsers: this.goalCacheModel.data.assignedUsers.length });
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((resultDepartments) => {
        this.departments = resultDepartments.map((iDepartment) => {
          const iOption: ISelectOption = {
            name: iDepartment.name,
            value: iDepartment._id,
          };
          return iOption;
        });
      })
      .catch(() => {
        this.departments = [];
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(true, false)
      .then((allUsers) => {
        this.allUsers = allUsers;
        this.userIdToUserPersonal = _.keyBy(allUsers, fieldConstants.ID);

        _.remove(
          this.allUsers,
          _.iteratee({
            _id: loggedUser._id,
          })
        );
      })
      .catch((error) => {
        this.allUsers = [];
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public leftButtonAction(): void {
    if (this.wizardStep === 1) {
      this.dialogRef.close();
    }
    if (this.wizardStep >= 2) {
      this.wizardStep--;
    }
  }

  public rightButtonAction(): void {
    if (
      (this.wizardStep === this.WIZARD_FINAL_STEP ||
        (this.wizardStep === this.STEP_TRACKER && this.trackerType === this.TRACKER_PROGRESS)) &&
      this.isValidFormData()
    ) {
      if (this.wizardStep === this.STEP_TRACKER && this.trackerType === this.TRACKER_PROGRESS) {
        this.goalCacheModel.data.keyResults = null;
      }
      if (this.goalCacheModel.data.type !== this.TYPE_DEPARTMENT) {
        this.goalCacheModel.data.departmentId = null;
      }
      if (this.isValidFormData()) {
        let callTosave;
        if (check.assigned(this.goalCacheModel.data._id)) {
          callTosave = this.goalCacheModel.updateInServer();
          this.injector.get(PrivateAmplitudeService).logEvent('update goal', { category: 'Goals', type: 'edit' });
        } else {
          callTosave = this.goalCacheModel.createInServer();
          this.logAmplitudeCreateEvent();
        }
        callTosave
          .then((goal) => {
            this.snackBar.open(this.translation.dialogGoalSaved, 'OK', {
              duration: 5000,
            });

            this.dialogRef.close(true);
            return;
          })
          .catch(() => {
            //
          });
      }
    } else if (this.wizardStep >= 1 && this.wizardStep < this.WIZARD_FINAL_STEP && this.isValidFormData()) {
      if (
        this.wizardStep === this.STEP_TRACKER &&
        this.trackerType === this.TRACKER_KEY &&
        (check.not.assigned(this.goalCacheModel.data.keyResults) || check.emptyArray(this.goalCacheModel.data.keyResults))
      ) {
        this.goalCacheModel.data.keyResults = [{ title: '', isCompleted: false }];
      }
      this.wizardStep++;
    }
  }

  private initWizardStep(step: number): void {
    this.wizardStep = step;
    return;
  }

  private logAmplitudeCreateEvent() {
    let eventType: string;
    if (this.goalCacheModel.data.type === this.TYPE_DEPARTMENT) {
      eventType = 'department';
    } else if (this.goalCacheModel.data.type === this.TYPE_BRAND) {
      eventType = 'company';
    } else {
      eventType = 'individual';
    }
    this.injector.get(PrivateAmplitudeService).logEvent('create goal', { category: 'Goals', type: eventType });
  }

  public isValidFormData(): boolean {
    if (this.wizardStep === this.STEP_DESCRIPTION) {
      return this.isValidDescription();
    }
    if (this.wizardStep === this.STEP_KEYRESULTS) {
      return this.isValidKeyResults();
    }
    return true;
  }

  private isValidDescription(): boolean {
    return !(
      check.not.assigned(this.titleValidation) ||
      this.titleValidation.hasErrors() ||
      (this.goalCacheModel.data.type === this.TYPE_DEPARTMENT &&
        (check.not.assigned(this.departmentValidation) || this.departmentValidation.hasErrors()))
    );
  }

  private isValidKeyResults(): boolean {
    if (
      !(
        check.assigned(this.goalCacheModel.data.keyResults) &&
        check.array(this.goalCacheModel.data.keyResults) &&
        check.nonEmptyArray(this.goalCacheModel.data.keyResults)
      )
    ) {
      return false;
    }
    this.checkEmpties();
    return check.assigned(this.emptyKeys) && this.emptyKeys === false;
  }

  // STEP 4 ASSIGN USERS
  public searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      const userPersonals = this.goalCacheModel.data.assignedUsers.map((iUserId) => {
        return this.userIdToUserPersonal[iUserId];
      });
      return Promise.resolve(userPersonals);
    }
    const results = _.chain(this.allUsers)
      .filter((userAccount: any) => {
        const userPersonal = this.userIdToUserPersonal[userAccount[fieldConstants.ID]];

        if (check.not.assigned(userPersonal)) {
          return false;
        }

        const regExp = new RegExp(`^.*${value}.*$`, 'i');
        return regExp.test(userPersonal.displayName);
      })
      .forEach((iResult) => {
        iResult.displayName = check.assigned(this.userIdToUserPersonal[iResult._id])
          ? this.userIdToUserPersonal[iResult._id].displayName
          : '';
      })
      .orderBy(['displayName'], ['asc'])
      .value();

    return Promise.resolve(
      results.map((iResult) => {
        return this.userIdToUserPersonal[iResult[fieldConstants.ID]];
      })
    );
  };

  public assignUser(userId: string): void {
    if (check.not.assigned(userId) || check.emptyString(userId)) {
      return;
    }
    this.goalCacheModel.data.assignedUsers.push(userId);
    _.remove(
      this.allUsers,
      _.iteratee({
        _id: userId,
      })
    );

    this.numberOfAssingedUsers = this.injector
      .get(I18nDataPipe)
      .transform(this.translation.usersAssigned, { numberOfUsers: this.goalCacheModel.data.assignedUsers.length });
    this.searchComponent.clearSearch();
  }

  public removeUser(userId: string): Function {
    return () => {
      if (check.not.assigned(userId) || check.emptyString(userId)) {
        return;
      }
      _.remove(this.goalCacheModel.data.assignedUsers, (element) => {
        return element === userId;
      });
      this.allUsers.push(this.userIdToUserPersonal[userId]);
      this.numberOfAssingedUsers = this.injector
        .get(I18nDataPipe)
        .transform(this.translation.usersAssigned, { numberOfUsers: this.goalCacheModel.data.assignedUsers.length });
      this.searchComponent.clearSearch();
      this.searchComponent.refreshSearchResults();
    };
  }

  // STEP 5 - KEY RESULTS
  public deleteKeyResult(keyIndex: number): void {
    if (check.not.assigned(keyIndex)) {
      return;
    }
    this.goalCacheModel.data.keyResults.splice(keyIndex, 1);
    const emptyValue = this.goalCacheModel.data.keyResults.find((iKeyResult) => {
      return check.not.assigned(iKeyResult.title) || check.emptyString(iKeyResult.title);
    });
    this.emptyKeys = check.assigned(emptyValue) && check.nonEmptyObject(emptyValue);
    return;
  }

  public changeKeyResult(index: number, value: any): void {
    if (
      check.not.assigned(index) ||
      check.not.assigned(this.goalCacheModel.data.keyResults) ||
      check.emptyArray(this.goalCacheModel.data.keyResults) ||
      check.not.assigned(this.goalCacheModel.data.keyResults[index])
    ) {
      return;
    }

    this.goalCacheModel.data.keyResults[index].title = value;
    this.addKeyResultWarning = check.not.assigned(value) || check.emptyString(value);
    this.checkEmpties();
  }

  public checkEmpties(): void {
    const emptyValue = this.goalCacheModel.data.keyResults.find((iKeyResult) => {
      return check.not.assigned(iKeyResult.title) || check.emptyString(iKeyResult.title);
    });
    this.emptyKeys = check.assigned(emptyValue) && check.nonEmptyObject(emptyValue);
  }

  public trackTitle(keyResult: any): string {
    return keyResult.title;
  }

  public addKeyResult(): void {
    if (
      check.assigned(this.goalCacheModel.data.keyResults) &&
      check.array(this.goalCacheModel.data.keyResults) &&
      check.nonEmptyArray(this.goalCacheModel.data.keyResults)
    ) {
      const emptyTitle = this.goalCacheModel.data.keyResults.find((iKey) => {
        return check.not.assigned(iKey.title) || iKey.title === '';
      });
      if (check.assigned(emptyTitle)) {
        this.addKeyResultWarning = true;
        return;
      }
    }
    this.addKeyResultWarning = false;
    if (check.not.assigned(this.goalCacheModel.data.keyResults)) {
      this.goalCacheModel.data.keyResults = [{ title: '', isCompleted: false }];
    } else {
      this.goalCacheModel.data.keyResults.push({ title: '', isCompleted: false });
    }
    this.cdr.detectChanges();
    return;
  }
}
