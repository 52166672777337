<div *ngIf="!loadingPage" class="cp-wrapper">
  <orgos-column-container class="kenjo-pb-20px" centerColumns="true">
    <orgos-column size="0">
      <orgos-action *ngIf="!filtersOpened" (click)="filtersOpened = true">{{i18n.page.showFilters}}</orgos-action>
      <orgos-action *ngIf="filtersOpened" (click)="filtersOpened = false">{{i18n.page.hideFilters}}</orgos-action>
    </orgos-column>
    <orgos-column> </orgos-column>
    <orgos-column size="0" *ngIf="calendarIntegrationSettings?.enableICalIntegration">
      <div #exportButton>
        <orgos-action icon="open_in_new" (click)="openExportCalendarPopup(exportButton)">{{i18n.page.export}} </orgos-action>
      </div>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container class="cp-main-content">
    <orgos-column *ngIf="filtersOpened" size="0" class="cp-filters-column kenjo-mr-20px">
      <!-- VIEWS -->
      <orgos-list-view [preferenceKey]="preferenceKey" [newView]="selectedView" (onDeleteView)="updateAfterViewChange($event)" (onChangeView)="updateAfterViewChange($event)"></orgos-list-view>
      <orgos-action (click)="createView()" icon="add_circle" color="Primary" class="kenjo-m-20px" *ngIf="viewsLoaded"> {{i18n.page.saveViewButton}}</orgos-action>
      <!-- FILTERS -->
      <orgos-container aspect="card">
        <mat-accordion displayMode="flat" multi="true">
          <mat-expansion-panel hideToggle="true" expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.peopleFilter}}</mat-panel-title>
            </mat-expansion-panel-header>

            <orgos-input-simple-radio [options]="peopleFilterOptions" [value]="filters.people" (valueChange)="changeFilters('people', $event)"></orgos-input-simple-radio>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="filters.people === 'All' &&  allOffices.length > 0" expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.officeFilter}}</mat-panel-title>
            </mat-expansion-panel-header>
            <orgos-input-simple-checkbox *ngFor="let iOffice of allOffices" [label]="iOffice.name" [value]="filters.offices.includes(iOffice._id)" (valueChange)="changeFilters('offices', iOffice._id)" class="kenjo-block kenjo-mb-10px"></orgos-input-simple-checkbox>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="filters.people === 'All' && allAreas.length > 0 && filters.areas" expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.areaFilter}}</mat-panel-title>
            </mat-expansion-panel-header>
            <orgos-input-simple-checkbox *ngFor="let iArea of allAreas" [label]="iArea.name" [value]="filters.areas.includes(iArea._id)" (valueChange)="changeFilters('areas', iArea._id)" class="kenjo-block kenjo-mb-10px"> </orgos-input-simple-checkbox>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="filters.people === 'All' && allDepartments.length > 0" expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.departmentFilter}}</mat-panel-title>
            </mat-expansion-panel-header>
            <orgos-input-simple-checkbox *ngFor="let iDepartment of allDepartments" [label]="iDepartment.name" [value]="filters.departments.includes(iDepartment._id)" (valueChange)="changeFilters('departments', iDepartment._id)" class="kenjo-block kenjo-mb-10px"> </orgos-input-simple-checkbox>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="filters.people === 'All' && allTeams.length > 0 && filters.teams" expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.teamFilter}}</mat-panel-title>
            </mat-expansion-panel-header>
            <orgos-input-simple-checkbox *ngFor="let iTeam of allTeams" [label]="iTeam.name" [value]="filters.teams.includes(iTeam._id)" (valueChange)="changeFilters('teams', iTeam._id)" class="kenjo-block kenjo-mb-10px"> </orgos-input-simple-checkbox>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="filters.people === 'Employees'" hideToggle="true" expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.employeesFilter}}</mat-panel-title>
            </mat-expansion-panel-header>

            <orgos-search #search [placeholder]="i18n.page.searchPlaceholder" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event">
              <orgos-text class="orgos-search-error" color="Warn">{{i18n.page.notFoundPeopleError}}</orgos-text>

              <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="changeFilters('employees', result._id); search.refreshSearchResults()">
                <kenjo-truncate-text>{{result.displayName}}</kenjo-truncate-text>
              </orgos-search-option>

              <orgos-container class="orgos-search-selection-container">
                <orgos-chip *ngFor="let result of searchResults" [imageUrl]="result._photo?._url" buttonIconName="clear" [onClickButtonFunction]="removeEmployeeFromFilters(result._id, search)" class="kenjo-mv-10px" [addInitialsIfNoImage]="true" [chipText]="result.displayName">
                  <kenjo-truncate-text class="cp-search-result">{{result.displayName}}</kenjo-truncate-text>
                </orgos-chip>
              </orgos-container>
            </orgos-search>
          </mat-expansion-panel>
        </mat-accordion>
      </orgos-container>
    </orgos-column>
    <orgos-column class="cp-calendar-column" [class.cp-calendar-column-with-filters]="filtersOpened">
      <orgos-calendar [users]="filteredUsersForCalendar" [year]="year" (yearChange)="refreshUsersForCalendar(false, $event)" [style.height]="calendarHeightStyle"> </orgos-calendar>

      <div #calendarFooter>
        <orgos-column-container *ngIf="timeOffTypeIdsToDisplay && timeOffTypeIdsToDisplay.length > 0" wrap="true" class="cp-footer kenjo-ph-20px">
          <orgos-column size="0" *ngFor="let iTimeOffType of timeOffTypeIdsToDisplay">
            <orgos-button-toggle (checkedChange)="changeTimeOffTypeVisibility($event, iTimeOffType)" [checked]="iTimeOffType.checked" [activeColor]="iTimeOffType.color">{{iTimeOffType.name}} </orgos-button-toggle>
          </orgos-column>
        </orgos-column-container>
      </div>
    </orgos-column>
  </orgos-column-container>
</div>
