<orgos-column-container centerColumns="true" *ngIf="customFieldDocument">
  <orgos-column size="1">
    <orgos-input-text *ngIf="customFieldDocument.fieldType === 'Text'" [readOnly]="readOnly" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block"></orgos-input-text>
    <orgos-input-text-area *ngIf="customFieldDocument.fieldType === 'TextArea'" [readOnly]="readOnly" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block"></orgos-input-text-area>
    <orgos-input-select *ngIf="customFieldDocument.fieldType === 'List'" [options]="listOptions" [readOnly]="readOnly" [enableClearButton]="true" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block"></orgos-input-select>
    <orgos-input-date-picker *ngIf="customFieldDocument.fieldType === 'Date'" [readOnly]="readOnly" [enableClearButton]="true" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block"></orgos-input-date-picker>
    <orgos-input-number *ngIf="customFieldDocument.fieldType === 'Number'" [readOnly]="readOnly" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block" step="0.01"></orgos-input-number>
    <orgos-input-email *ngIf="customFieldDocument.fieldType === 'Email'" [readOnly]="readOnly" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" (validation)="emailValidation = $event" class="kenjo-block">
      <orgos-input-error *ngIf="emailValidation && emailValidation.getError('email')">{{ pageTranslation.emailValidationError }}</orgos-input-error>
    </orgos-input-email>
    <orgos-input-checkbox *ngIf="customFieldDocument.fieldType === 'Boolean'" [readOnly]="readOnly" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block"></orgos-input-checkbox>
    <orgos-input-url *ngIf="customFieldDocument.fieldType === 'Url'" [readOnly]="readOnly" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block"></orgos-input-url>
    <orgos-input-text *ngIf="customFieldDocument.fieldType === 'AutoNumber'" [readOnly]="true" [model]="model" [field]="customFieldDocument.fieldApiName" [customFieldDocument]="customFieldDocument" class="kenjo-block"></orgos-input-text>
  </orgos-column>
  <orgos-column size="0" *ngIf="manageCustomField === true || manageCustomField === 'true'" class="kenjo-pl-10px">
    <kenjo-icon [size]="16" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3" (click)="editCustomFieldDialog()">mode_edit</kenjo-icon>
  </orgos-column>
  <orgos-column size="0" *ngIf="manageCustomField === true || manageCustomField === 'true'" class="kenjo-pl-10px">
    <kenjo-icon [size]="16" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757" (click)="deleteCustomFieldDialog()">delete</kenjo-icon>
  </orgos-column>
</orgos-column-container>
