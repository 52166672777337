import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ParamMap } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as picklistValues from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColors from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '../../../../components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '../../../../core/generic-cache-model';
import { DepartmentService } from '../../../../services/company/department.service';
import { AuthenticationService } from '../../../../services/core/authentication.service';
import { CloudRoutesService } from '../../../../services/core/cloud-routes.service';
import { GoalService, IGoalModel } from '../../../../services/performance-management/goal.service';
import { UserPersonalService } from '../../../../services/user/user-personal.service';
import { UserWorkService } from '../../../../services/user/user-work.service';
import { GenericPage, IMenuOption, ITranslationResource } from '../../../generic.page';
import { CreateGoalDialog } from '../goals/dialogs/create-goal-dialog/create-goal.dialog';

@Component({
  selector: 'orgos-goals-detail',
  templateUrl: 'goals-detail.page.html',
  styleUrls: ['goals-detail.page.scss'],
})
export class GoalsDetailPage extends GenericPage {
  private goalId: string;
  goalCacheModel: GenericCacheModel;
  createdOn: string;
  userPersonalMap: any;
  userWorkMap: any;
  update: string;
  loggedUser: any;
  progress: number = 0;
  statusColorMap: any = {
    OnTrack: userColors.USER_COLOR_3,
    Behind: '#FFC107',
    Achieved: '#00B72E',
    NotAchieved: '#FF8D0B',
    Cancelled: '#8F8F8F',
    Deferred: '#8F8F8F',
    NotStarted: userColors.USER_COLOR_2,
  };

  listStatus: Array<string> = picklistValues.GOAL_STATUS_LIST;

  TYPE_INDIVIDUAL: string = picklistValues.GOAL_TYPE_INDIVIDUAL;
  TYPE_DEPARTMENT: string = picklistValues.GOAL_TYPE_DEPARTMENT;
  TYPE_BRAND: string = picklistValues.GOAL_TYPE_BRAND;

  UPDATE_TYPE_USER: string = 'user';
  UPDATE_TYPE_PROGRESS: string = 'progress';
  UPDATE_TYPE_STATUS: string = 'status';
  UPDATE_TYPE_KEY: string = 'key';

  TODAY: any = moment();
  departments: any = {};
  departmentText: string;

  progressChanged: boolean = false;

  private _updates: Array<any> = [];
  get updates(): Array<any> {
    if (
      check.not.assigned(this.goalCacheModel) ||
      check.not.assigned(this.goalCacheModel.data) ||
      check.not.assigned(this.goalCacheModel.data.updates) ||
      check.not.array(this.goalCacheModel.data.updates) ||
      check.emptyArray(this.goalCacheModel.data.updates)
    ) {
      return [];
    }

    const orderUpdates = _.orderBy(this.goalCacheModel.data.updates, ['when'], ['desc']);
    return orderUpdates;
  }
  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'goals-misc' },
    { name: 'page', translationKey: 'goals-detail-page' },
    { name: 'generalMisc', translationKey: 'misc' },
    { name: 'picklists', translationKey: 'standard-picklists' },
  ];

  protected beforeInit(): Promise<void> {
    this.globalBarConfig.enableFullScreenMode = true;
    this.globalBarConfig.fullScreenModeUseSameUrl = false;

    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        this.goalId = id;
        this.refreshData();
      });

    return Promise.resolve();
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    if (check.not.assigned(this.goalId) || check.emptyString(this.goalId)) {
      resolveFetchData();
      return;
    }

    const NUMBER_OF_DATA_TO_FETCH = 3;
    let dataFetched = 0;
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((resultDepartments) => {
        this.departments = _.keyBy(resultDepartments, '_id');
        return this.injector.get(GoalService).getById(this.goalId);
      })
      .then((goal: IGoalModel) => {
        this.goalCacheModel = new GenericCacheModel(this.injector, goal, GoalService, '');

        const createdAtFormated = this.injector.get(DatePipe).transform(goal._createdAt, 'shortDate', 'UTC');
        this.createdOn = this.injector.get(I18nDataPipe).transform(this.i18n.page.createdOn, { createdAtFormated });

        if (
          check.assigned(goal.departmentId) &&
          check.nonEmptyString(goal.departmentId) &&
          check.assigned(this.departments[goal.departmentId])
        ) {
          this.departmentText = this.injector
            .get(I18nDataPipe)
            .transform(this.i18n.page.departmentGoal, { departmentName: this.departments[goal.departmentId].name });
        }
        if (check.assigned(goal.progress)) {
          this.progress = goal.progress;
        }

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        rejectFetchData();
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, true)
      .then((allUserPersonal: Array<any>) => {
        this.userPersonalMap = _.keyBy(allUserPersonal, '_id');

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.userPersonalMap = {};
        rejectFetchData();
      });

    this.injector
      .get(UserWorkService)
      .getAllUserWork(false, true)
      .then((allUserWorks: Array<any>) => {
        this.userWorkMap = _.keyBy(allUserWorks, '_id');

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.userPersonalMap = {};
        rejectFetchData();
      });
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.misc.pageName;

    const options: Array<IMenuOption> = [
      { name: this.i18n.misc.homeTab, onClick: () => this.router.navigateByUrl('/cloud/home') },
      { name: this.i18n.misc.goalsTab, onClick: () => this.router.navigateByUrl('/cloud/goals') },
    ];
    if (this.injector.get(CloudRoutesService).checkRoute('meetings') === true) {
      options.push({
        name: this.i18n.misc.meetingsTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/meetings');
        },
      });
    }
    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 1;

    return Promise.resolve();
  }

  public updateGoal(): void {
    this.goalCacheModel
      .updateInServer()
      .then(() => {
        this.refreshData();
      })
      .catch(() => {
        // An error is already shown
      });
  }

  public closeGoalDetailPage(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  public completedKeyResults(): number {
    const numberOfKeyResultsCompleted = this.goalCacheModel.data.keyResults.filter((iKeyResult) => {
      return iKeyResult.isCompleted === true;
    }).length;

    return numberOfKeyResultsCompleted;
  }

  public completeKeyResult(keyResultIndex: number): void {
    const title: string = this.goalCacheModel.data.keyResults[keyResultIndex].title;
    this.goalCacheModel.data.keyResults[keyResultIndex].isCompleted = !this.goalCacheModel.data.keyResults[keyResultIndex].isCompleted;
    this.goalCacheModel.data.progress = (this.completedKeyResults() / this.goalCacheModel.data.keyResults.length) * 100;

    if (check.not.assigned(this.goalCacheModel.data.updates)) {
      this.goalCacheModel.data.updates = [];
    }

    const actionText = this.goalCacheModel.data.keyResults[keyResultIndex].isCompleted ? 'completed' : 'uncompleted';
    const text = this.loggedUser._id + '##' + title + '##' + actionText;
    const updateToSave = {
      text: text,
      type: this.UPDATE_TYPE_KEY,
      when: moment().toDate(),
      postedBy: `${this.i18n.page.keyResult}##${actionText}`,
    };
    this.goalCacheModel.data.updates.push(updateToSave);
    this.injector.get(PrivateAmplitudeService).logEvent('update goal', { category: 'Goals', type: 'check off key result' });
    this.updateGoal();
  }

  public changeProgress(): void {
    if (this.goalCacheModel.data.progress === this.progress) {
      return;
    }

    const oldProgress: number = this.goalCacheModel.data.progress;
    this.goalCacheModel.data.progress = this.progress;

    if (check.not.assigned(this.goalCacheModel.data.updates)) {
      this.goalCacheModel.data.updates = [];
    }
    const text = this.loggedUser._id + '##' + this.goalCacheModel.data.progress + '%';
    const updateToSave = {
      text: text,
      type: this.UPDATE_TYPE_PROGRESS,
      when: moment().toDate(),
      postedBy: this.i18n.page.progressUpdated,
    };
    this.goalCacheModel.data.updates.push(updateToSave);
    this.updateGoal();
  }

  public updateProgress(value: number): void {
    this.progressChanged = this.goalCacheModel.data.progress !== value;
  }

  public changeStatusTo(value: 'OnTrack' | 'Behind' | 'Achieved' | 'NotAchieved' | 'Cancelled' | 'Deferred' | 'NotStarted'): void {
    const oldStatus: 'OnTrack' | 'Behind' | 'Achieved' | 'NotAchieved' | 'Cancelled' | 'Deferred' | 'NotStarted' =
      this.goalCacheModel.data.status;
    this.goalCacheModel.data.status = value;

    if (check.not.assigned(this.goalCacheModel.data.updates)) {
      this.goalCacheModel.data.updates = [];
    }

    const text = this.loggedUser._id + '##' + oldStatus + '##' + this.goalCacheModel.data.status;
    const updateToSave = {
      text: text,
      type: this.UPDATE_TYPE_STATUS,
      when: moment().toDate(),
      postedBy: this.i18n.page.statusUpdated,
    };
    this.goalCacheModel.data.updates.push(updateToSave);
    this.injector.get(PrivateAmplitudeService).logEvent('update goal', { category: 'Goals', type: 'update status' });
    this.updateGoal();
  }

  public postUpdate(): void {
    if (check.not.assigned(this.update) || check.emptyString(this.update)) {
      return;
    }

    if (check.not.assigned(this.goalCacheModel.data.updates)) {
      this.goalCacheModel.data.updates = [];
    }

    let postedBy = this.loggedUser._id;
    if (
      check.assigned(this.userWorkMap[this.loggedUser._id]) &&
      check.assigned(this.userWorkMap[this.loggedUser._id].departmentId) &&
      check.assigned(this.departments[this.userWorkMap[this.loggedUser._id].departmentId])
    ) {
      postedBy += '##' + this.departments[this.userWorkMap[this.loggedUser._id].departmentId].name;
    }
    const updateToSave = {
      text: this.update,
      type: this.UPDATE_TYPE_USER,
      when: moment().toDate(),
      postedBy: postedBy,
    };
    this.goalCacheModel.data.updates.push(updateToSave);
    this.update = '';

    this.injector.get(PrivateAmplitudeService).logEvent('update goal', { category: 'Goals', type: 'post update' });
    this.updateGoal();
  }

  public editGoal(): void {
    const goalToEdit = _.cloneDeep(this.goalCacheModel.data);
    const data: any = {
      goal: goalToEdit,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateGoalDialog, { data });
    dialogRef.afterClosed().subscribe((saved) => {
      if (saved === true) {
        this.refreshData();
      }
    });
  }

  public deleteGoal(): void {
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(this.i18n.page.deleteGoalDialogTitle, { name: this.goalCacheModel.data.title }),
      subtitleText: this.i18n.page.deleteGoalDialogSubtitle,
      confirmButtonText: this.i18n.page.deleteGoalDialogConfirmButtonText,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.generalMisc.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector
          .get(GoalService)
          .deleteById(this.goalCacheModel.data._id)
          .then(() => {
            this.injector.get(MatLegacySnackBar).open(this.i18n.page.goalDeletedSnackBar, 'OK', { duration: 5000 });
            this.router.navigateByUrl('/cloud/goals');
          })
          .catch(() => {
            // An error is already shown
          });
      }
    });
  }

  public deleteUpdate(updateIndex: number): void {
    const data = {
      titleText: this.i18n.page.deleteUpdateDialogTitle,
      subtitleText: this.i18n.page.deleteGoalDialogSubtitle,
      confirmButtonText: this.i18n.page.deleteGoalUpdateDialogConfirmButtonText,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.generalMisc.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    this.goalCacheModel.data.updates = _.orderBy(this.goalCacheModel.data.updates, ['when'], ['desc']);
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.goalCacheModel.data.updates.splice(updateIndex, 1);
        this.updateGoal();
        this.injector.get(MatLegacySnackBar).open(this.i18n.page.updateDeletedSnackBar, 'OK', { duration: 5000 });
      }
    });
  }

  public transforUpdate(element: any): any {
    const transFormElement: any = {};
    if (check.assigned(element.type) && check.equal(element.type, this.UPDATE_TYPE_USER)) {
      const dataPostedBy = element.postedBy.split('##');
      if (dataPostedBy.length > 1) {
        transFormElement.postedBy = `${this.userPersonalMap[dataPostedBy[0]].displayName} ${this.i18n.page.from} ${dataPostedBy[1]}`;
      } else {
        if (check.assigned(this.userPersonalMap[element.postedBy]) && check.assigned(this.userPersonalMap[element.postedBy].displayName)) {
          transFormElement.postedBy = `${this.userPersonalMap[element.postedBy].displayName}`;
        }
      }
    } else {
      let data: Array<string> = [];
      if (check.assigned(element.text)) {
        data = element.text.split('##');
      }
      if (data.length > 1) {
        let translateText: string;
        let transformData: any;
        if (check.assigned(element.type) && check.equal(element.type, this.UPDATE_TYPE_PROGRESS)) {
          transformData = { progress: data[1] };
          translateText = `${this.userPersonalMap[data[0]].displayName} ${this.injector
            .get(I18nDataPipe)
            .transform(this.i18n.page.progressSetTo, transformData)}`;
          transFormElement.postedBy = this.i18n.page.progressUpdated;
        } else if (check.assigned(element.type) && check.equal(element.type, this.UPDATE_TYPE_STATUS)) {
          transformData = { statusFrom: this.i18n.picklists.goalStatus[data[1]], statusTo: this.i18n.picklists.goalStatus[data[2]] };
          translateText = `${this.userPersonalMap[data[0]].displayName} ${this.injector
            .get(I18nDataPipe)
            .transform(this.i18n.page.statusChanged, transformData)}`;
          transFormElement.postedBy = this.i18n.page.statusUpdated;
        } else if (check.assigned(element.type) && check.equal(element.type, this.UPDATE_TYPE_KEY)) {
          transformData = { title: data[1] };
          transFormElement.postedBy = `${this.i18n.page.keyResult} ${this.i18n.page[data[2]]}`;
          translateText = `${this.userPersonalMap[data[0]].displayName} ${this.injector
            .get(I18nDataPipe)
            .transform(this.i18n.page.markKeyResult, transformData)} ${this.i18n.page[data[2]]}`;
        }
        transFormElement.text = translateText;
      }
    }

    return transFormElement;
  }
}
