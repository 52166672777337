import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class SmartDocsService {
  private DATA_ENGINE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/data-engine`;

  constructor(private injector: Injector) {}

  async getListDataMyDocs(queryOptions: any): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DATA_ENGINE_URL}`, ErrorCodes.UNAUTHORIZED, SmartDocsService.name, 'getListDataMyDocs');
        throw this.injector.get(ErrorManagerService).handleRawError(error);
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const users = this.injector.get(HttpClient).post(`${this.DATA_ENGINE_URL}/my-docs/list`, queryOptions, httpOptions).toPromise();

      return users;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, SmartDocsService.name, 'getListDataMyDocs');
    }
  }
}
