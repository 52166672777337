import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kenjo-shift-plan-select-shifts',
  templateUrl: 'shift-plan-select-shifts.component.html',
  styleUrls: ['shift-plan-select-shifts.component.scss']
})
export class ShiftPlanSelectShiftsComponent implements OnInit {
  SHIFTS_LIMIT_TO_DELETE_CUSTOM: number = 2000;

  @Input() parentTranslation: { [key: string]: string } = {};
  @Input() loading: boolean;
  @Input() shiftsNumber: number = 0;
  @Input() selectedShifts: number = 0;

  @Output() clearAllEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectAllEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteDialog: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
  ngOnInit() {}

  public clearAll() {
    this.clearAllEvent.emit();
  }

  public selectAll() {
    this.selectAllEvent.emit();
  }

  public deleteShifts() {
    if (this.loading || this.selectedShifts === 0 || this.selectedShifts >= this.SHIFTS_LIMIT_TO_DELETE_CUSTOM) {
      return;
    }
    this.deleteDialog.emit();
  }

  public onClose() {
    if (this.loading) {
      return;
    }
    this.closeDialog.emit();
  }
}
