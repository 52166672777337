<orgos-column-container *ngIf="parentTranslation" [centerColumns]="true">
  <kenjo-icon orgosColumn="0" [size]="25" class="kenjo-font-color-primary-5993e3" [class.kenjo-cursor-pointer]="!states.dragging" [disabled]="states.loading || states.dropping" [class.kenjo-font-color-disabled-c4c4c4]="states.loading || states.dropping" (click)="valueChange.emit(WEEK_VALUES.BEFORE)"
    >keyboard_arrow_left</kenjo-icon
  >
  <orgos-column-container orgosColumn="0" [centerColumns]="true">
    <orgos-column size="0" class="swp-column-container">
      <a (click)="openDatepicker()" [class.disabled]="states.loading || blockPicker">
        <div class="kenjo-font-size-18px">{{ startDate }} - {{ endDate }}</div>
      </a>
      <mat-form-field class="kenjo-cursor-pointer swp-calendar">
        <mat-date-range-input [formGroup]="rangeWeekly" [rangePicker]="datepicker" [min]="limitDates.min" [max]="limitDates.max" hidden>
          <input matStartDate [formControlName]="'fromWeek'" (dateInput)="onDateChange($event)" readonly />
          <input matEndDate [formControlName]="'toWeek'" readonly hidden />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-date-range-picker (closed)="onClose()" #datepicker [startAt]="startAt"></mat-date-range-picker>
      </mat-form-field>
    </orgos-column>
  </orgos-column-container>

  <kenjo-icon orgosColumn="0" [size]="25" class="kenjo-font-color-primary-5993e3" [class.kenjo-cursor-pointer]="!states.dragging" [disabled]="states.loading || states.dropping" [class.kenjo-font-color-disabled-c4c4c4]="states.loading || states.dropping" (click)="valueChange.emit(WEEK_VALUES.AFTER)"
    >keyboard_arrow_right</kenjo-icon
  >
</orgos-column-container>

<div [style.display]="'none'">
  <div #datepickerFooter>
    <orgos-column-container class="kenjo-border-top" [centerColumns]="true">
      <orgos-column></orgos-column>
      <orgos-button-raised [disabled]="!isValueValid" class="kenjo-p-10px" orgosColumn="0" color="Primary" (click)="onClose()">{{ parentTranslation.doneButton }}</orgos-button-raised>
    </orgos-column-container>
  </div>
</div>
