import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeCompare'
})
export class TimeCompare implements PipeTransform {
  transform(date1: Date, date2: Date, comparison: 'isSameDay'): boolean {
    if (comparison === 'isSameDay') {
      return moment.utc(date1).isSame(date2, 'day');
    }
    return;
  }
}