// Inspired by: https://christianliebel.com/2016/05/angular-2-a-simple-click-outside-directive/
import { Directive, ElementRef, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Directive({
  selector: '[orgosClickOutside]'
})
export class ClickOutsideDirective {
  @Output('orgosClickOutside') clickOutside: EventEmitter<void> = new EventEmitter<void>();
  // Accept multiple class names as an input
  @Input('excludeClass') excludeClasses: string[] = [];

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  public onClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (clickedInside) {
      return;
    }

    let shouldEmitEvent = true;
    for (let clazz of this.excludeClasses) {
      if ((event.target as Element).closest('.' + clazz)) {
        shouldEmitEvent = false;
        break;
      }
    }

    if (shouldEmitEvent) {
      this.clickOutside.emit();
    }
  }
}

