import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { getPossibleValuesOfReference } from '@carlos-orgos/orgos-utils/metadata-describer';
import * as check from 'check-types';
import * as _ from 'lodash';

import { environment } from '../../../../environments/environment';
import { GenericCacheModel } from '../../core/generic-cache-model';
import { ISelectOption } from '../../core/select-option';
import { AuthenticationService } from '../../services/core/authentication.service';
import { StandardServicesRegistry } from '../../services/standard-services.registry';
import { IWorkflowFilter } from './interface/workflow-filter';

@Component({
  selector: 'orgos-workflow-filter',
  templateUrl: 'workflow-filter.component.html',
  styleUrls: ['workflow-filter.component.scss'],
})
export class WorkflowFilterComponent implements OnInit {
  @Input() listFields: Array<any>;
  @Input() objectTranslation: any;
  @Input() readOnly: boolean = false;
  @Input() workflowFilter: IWorkflowFilter;
  @Input() customFieldsAvailable: Array<any>;
  @Input() chosenCollection: string;
  @Input() allowIsChangedOperator: boolean = false;

  @Output() delete: EventEmitter<string> = new EventEmitter<string>();
  @Output() edit: EventEmitter<string> = new EventEmitter<string>();

  selectFieldOptions: Array<ISelectOption> = [];
  selectOperatorOptions: Array<ISelectOption> = [];
  selectValueOptions: Array<ISelectOption>;
  findingSelectedOptions: boolean = false;
  pageTranslation: any = {};
  fieldKeyToTranslatedField: any = {};
  translatedFieldValue: string;
  picklistTranslations: any = {};
  documentTagsTranslations: any = {};

  auxModel: GenericCacheModel;

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    const filterServiceClass = this.standardServicesRegistry.getService('Workflow');
    const rawData = {
      field: this.workflowFilter.field,
      condition: this.workflowFilter.condition,
      value: this.workflowFilter.value,
    };

    if (check.equal('_startTime', rawData.field) || check.equal('_endTime', rawData.field)) {
      rawData.value = this.convertMinutesToTime(rawData.value);
    }

    this.auxModel = new GenericCacheModel(this.injector, rawData, filterServiceClass);
    const callToTranslatedPicklist = this.injector.get(InternationalizationService).getAllTranslation('standard-picklists');
    const callToTranslateWorkflowFilters = this.injector.get(InternationalizationService).getAllTranslation('workflow-filter-component');
    const callToTranslateDocumentTags = this.injector.get(InternationalizationService).getAllTranslation('document-tag-collection');

    Promise.all([callToTranslateWorkflowFilters, callToTranslatedPicklist, callToTranslateDocumentTags])
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation[0];
        this.picklistTranslations = pageTranslation[1];
        this.documentTagsTranslations = pageTranslation[2];
        this.initOperatorOptions(true);
      })
      .catch((error) => {
        this.pageTranslation = {};
      });
    this.initFieldOptions();
  }

  convertMinutesToTime(value): string {
    const hourAux = parseInt((value / 60).toString().split('.')[0]);
    const hours = hourAux >= 10 ? hourAux : `0${hourAux}`;
    const minutesAux = parseInt(value) % 60;
    const minutes = minutesAux >= 10 ? minutesAux : `0${minutesAux}`;
    const time = `${hours}:${minutes}`;
    return time.toString();
  }
  changeInFilter(recalculateOperator: boolean = false): void {
    this.workflowFilter.field = this.auxModel.data['field'];
    this.translatedFieldValue = this.fieldKeyToTranslatedField[this.workflowFilter.field]
      ? this.fieldKeyToTranslatedField[this.workflowFilter.field]
      : this.workflowFilter.field;
    this.workflowFilter.condition = this.auxModel.data['condition'];
    this.workflowFilter.value = this.auxModel.data['value'];
    if (check.assigned(recalculateOperator) && check.equal(true, recalculateOperator)) {
      this.initOperatorOptions(false);
      const usedField = this.listFields.find((iField: any) => {
        return iField.name === this.workflowFilter.field;
      });
      if (
        check.assigned(usedField) &&
        check.assigned(usedField.type) &&
        (check.equal('ObjectID', usedField.type) || check.equal('Array', usedField.type))
      ) {
        this.findingSelectedOptions = true;
        this.initLookupSelectOption(usedField)
          .then(() => {
            this.findingSelectedOptions = false;
          })
          .catch(() => {
            this.findingSelectedOptions = false;
          });
      }
    }
    this.edit.emit(this.workflowFilter.id);
  }

  deleteFilter(): void {
    this.delete.emit(this.workflowFilter.id);
  }

  private initFieldOptions(): void {
    this.selectFieldOptions = [];
    this.fieldKeyToTranslatedField = {};
    this.listFields.forEach((iField) => {
      if (
        check.assigned(this.objectTranslation) &&
        check.assigned(this.objectTranslation[iField['name']]) &&
        check.nonEmptyString(this.objectTranslation[iField['name']])
      ) {
        this.selectFieldOptions.push({
          name: this.objectTranslation[iField['name']],
          value: iField['name'],
        });
      } else if (
        check.assigned(iField['name']) &&
        check.string(iField['name']) &&
        check.nonEmptyString(iField['name']) &&
        (iField['name'].startsWith('c_') || (check.contains(iField['name'], '.') && iField['name'].split('.')[1].startsWith('c_')))
      ) {
        const customFieldTranslation = this.findCustomFieldTranslation(iField['name']);
        if (check.assigned(customFieldTranslation) && check.nonEmptyString(customFieldTranslation)) {
          this.selectFieldOptions.push({
            name: customFieldTranslation,
            value: iField['name'],
          });
        }
      }
      if (check.nonEmptyArray(this.selectFieldOptions)) {
        this.fieldKeyToTranslatedField[this.selectFieldOptions[this.selectFieldOptions.length - 1]['value']] =
          this.selectFieldOptions[this.selectFieldOptions.length - 1]['name'];
      }
    });
  }

  private initLookupSelectOption(fieldModel: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (check.not.assigned(fieldModel) || check.not.assigned(fieldModel.name) || check.emptyString(fieldModel.name)) {
        resolve();
        return;
      }

      let collectionName = this.chosenCollection;
      let fieldName = fieldModel.name;
      if (check.contains(fieldModel.name, '.')) {
        collectionName = fieldModel.name.split('.')[0].replace('user', 'user-').toLowerCase();
        fieldName = fieldModel.name.split('.')[1];
      }
      this.selectValueOptions = [];

      getPossibleValuesOfReference(
        this.injector.get(AuthenticationService).getAuthorizationHeader(),
        environment.PEOPLE_CLOUD_APP_URL,
        collectionName,
        fieldName
      )
        .then((selectOptions: Array<ISelectOption>) => {
          let auxSelectOptions;

          if (collectionName === 'document' && fieldName === 'tags') {
            auxSelectOptions = _.clone(selectOptions);

            auxSelectOptions.forEach((value: ISelectOption, index, tagsArray) => {
              if (this.documentTagsTranslations[value.name]) {
                tagsArray[index].name = this.documentTagsTranslations[value.name];
              }
            });
          }

          this.selectValueOptions = auxSelectOptions ? auxSelectOptions : selectOptions;

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private findCustomFieldTranslation(fieldApiName): string {
    if (
      check.not.assigned(this.customFieldsAvailable) ||
      check.not.array(this.customFieldsAvailable) ||
      check.emptyArray(this.customFieldsAvailable)
    ) {
      return null;
    }

    if (check.not.assigned(fieldApiName) || check.not.string(fieldApiName) || check.emptyString(fieldApiName)) {
      return null;
    }

    if (check.not.contains(fieldApiName, '.')) {
      const candidate = this.customFieldsAvailable.find((iCustomField) => {
        return iCustomField.fieldApiName === fieldApiName;
      });
      if (check.assigned(candidate) && check.assigned(candidate.fieldLabel)) {
        return candidate.fieldLabel;
      }
    } else if (check.contains(fieldApiName, '.')) {
      const object = fieldApiName.split('.')[0];
      const field = fieldApiName.split('.')[1];

      const candidate = this.customFieldsAvailable.find((iCustomField) => {
        return iCustomField.fieldApiName === field;
      });
      if (check.assigned(candidate) && check.assigned(candidate.fieldLabel)) {
        return candidate.fieldLabel;
      }
    }

    return null;
  }

  initOperatorOptions(firstLoad: boolean): void {
    this.selectValueOptions = null;
    if (check.not.assigned(firstLoad) || check.not.equal(true, firstLoad)) {
      this.auxModel['data']['value'] = null;
    }
    this.selectOperatorOptions = [];
    if (
      check.not.assigned(this.workflowFilter.field) ||
      check.not.string(this.workflowFilter.field) ||
      check.emptyString(this.workflowFilter.field)
    ) {
      return;
    }

    const chosenField = this.listFields.find((iFieldType) => {
      return this.workflowFilter.field === iFieldType['name'];
    });
    this.workflowFilter.valueType = chosenField['type'];

    if (chosenField['type'] !== 'Date' && chosenField['name'] !== 'mentioned') {
      this.selectOperatorOptions.push({ name: this.pageTranslation.equalToOperator, value: 'eq' });
      this.selectOperatorOptions.push({ name: this.pageTranslation.notEqualToOperator, value: 'ne' });
    }

    this.selectOperatorOptions.push({ name: this.pageTranslation.isEmptyOperator, value: 'isEmpty' });
    this.selectOperatorOptions.push({ name: this.pageTranslation.isNotEmptyOperator, value: 'isNotEmpty' });

    // Possible types are Date, Number, Boolean, String (could have enumValues), Email, ObjectID
    if (chosenField['type'] === 'Number' || chosenField['type'] === 'Date') {
      this.selectOperatorOptions.push({ name: this.pageTranslation.lessThanOperator, value: 'lt' });
      this.selectOperatorOptions.push({ name: this.pageTranslation.lessThanEqualOperator, value: 'lte' });
      this.selectOperatorOptions.push({ name: this.pageTranslation.greaterThanOperator, value: 'gt' });
      this.selectOperatorOptions.push({ name: this.pageTranslation.greaterThanEqualOperator, value: 'gte' });
    } else if (chosenField['type'] === 'Boolean') {
      this.auxModel['data']['value'] = this.workflowFilter.value === true ? true : false;
    } else if (
      chosenField['type'] === 'String' &&
      check.assigned(chosenField['enumValues']) &&
      check.array(chosenField['enumValues']) &&
      check.nonEmptyArray(chosenField['enumValues'])
    ) {
      const name = chosenField.name.includes('.') ? chosenField.name.split('.')[1] : chosenField.name;
      if (
        check.assigned(this.picklistTranslations[name]) ||
        check.assigned(this.picklistTranslations[`${this.chosenCollection}_${name}`])
      ) {
        const translation = this.picklistTranslations[name] ?? this.picklistTranslations[`${this.chosenCollection}_${name}`];
        this.selectValueOptions = [];
        Object.keys(translation).forEach((key) => {
          this.selectValueOptions.push({ name: translation[key], value: key });
        });
      } else {
        this.selectValueOptions = chosenField.enumValues.map((iEnumValue) => {
          return { name: iEnumValue, value: iEnumValue };
        });
      }
    } else if (chosenField['type'] === 'ObjectID' || chosenField['type'] === 'Array' || chosenField['name'] === 'userAccount.profileKey') {
      this.findingSelectedOptions = true;
      this.initLookupSelectOption(chosenField)
        .then(() => {
          this.findingSelectedOptions = false;
        })
        .catch(() => {
          this.findingSelectedOptions = false;
        });
    }

    if (this.allowIsChangedOperator || this.readOnly) {
      this.selectOperatorOptions.push({ name: this.pageTranslation.isChanged, value: 'isChanged' });
    }

    this.changeInFilter(false);
  }
}
