<orgos-column-container centerColumns="true" [class.kenjo-block]="!uppercase && forceBlockContainer">
  <mat-icon
    orgosColumn="0"
    *ngIf="icon"
    class="kenjo-mr-10px ac-flex-auto"
    [class.ac-icon-normal]="normalIconClass"
    [class.ac-icon-large]="largeIconClass"
    [class.ac-icon-small]="smallIconClass"
    [class.ac-14px-icon-size]="fourteenPxIconClass"
    [class.ac-18px-icon-size]="eighteenPxIconClass"
    >{{ icon }}</mat-icon
  >
  <orgos-column
    size="0"
    [class.ac-uppercase]="uppercase"
    [class.ac-lowercase]="!uppercase"
    [class.ac-small-text]="smallText"
    class="ac-action-text ac-flex-auto"
  >
    <ng-content></ng-content>
  </orgos-column>
</orgos-column-container>
