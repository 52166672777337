<div class="kenjo-p-40px asc-content">
  <div>
    <div class="asc-header">
      <div>
        <div class="kenjo-font-weight-bold kenjo-font-size-16px">{{ translations.attendanceHeader }}</div>
        <div class="kenjo-font-color-light-text-757575 kenjo-mt-10px kenjo-mb-10px">{{ translations.attendanceDescription }}</div>
      </div>
    </div>

    <kenjo-select-dropdown-custom [translations]="translations" [defaultOption]="defaultPolicy" [model]="userAccount" field="attendancePolicy" (modelChange)="onPolicyChange()" [componentKey]="'policy-item'" [options]="attendancePoliciesOptions"></kenjo-select-dropdown-custom>
    <div *ngIf="userAccount.data.attendancePolicy" class="kenjo-mt-20px">{{ translations.methodsInPolicy }}</div>

    <div *ngIf="userAccount.data.attendancePolicy && !methods.timeSheet && !methods.punchClock && !methods.pinCode && !methods.qrCode" class="kenjo-mt-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.policyWithoutMethods }}</div>

    <div *ngIf="userAccount.data.attendancePolicy && !methods.timeSheet && !methods.punchClock && !methods.pinCode && !methods.qrCode" class="asc-api kenjo-mt-10px kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-15px">
      <div class="kenjo-font-weight-bold">{{ translations.attendanceViaApi }}</div>
      <a class="kenjo-font-size-12px" [href]="translations.apiLearnMoreLink" target="_blank">{{ translations.learnMore }}</a>
    </div>

    <div *ngIf="userAccount.data.attendancePolicy" class="asc-methods kenjo-mt-10px">
      <div *ngIf="methods.timeSheet" class="asc-method kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-15px">
        <div class="asc-method-title kenjo-font-weight-bold">{{ translations.timeSheetManagement }}</div>
        <div class="asc-list">
          <div class="kenjo-font-color-light-text-757575">
            <kenjo-icon [size]="18">devices</kenjo-icon>
            <div class="kenjo-font-size-12px kenjo-ml-10px">{{ translations.worksOnDesktopAndMobile }}</div>
          </div>
          <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px">
            <kenjo-icon [size]="18">location</kenjo-icon>
            <div class="kenjo-font-size-12px kenjo-ml-10px">{{ translations.allowsLocationTrackingOnMobile }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="methods.punchClock" class="asc-method kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-15px">
        <div class="asc-method-title kenjo-font-weight-bold">{{ translations.punchClock }}</div>
        <div class="asc-list">
          <div class="kenjo-font-color-light-text-757575">
            <kenjo-icon [size]="18">devices</kenjo-icon>
            <div class="kenjo-font-size-12px kenjo-ml-10px">{{ translations.worksOnDesktopAndMobile }}</div>
          </div>
          <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px">
            <kenjo-icon [size]="18">location</kenjo-icon>
            <div class="kenjo-font-size-12px kenjo-ml-10px">{{ translations.allowsLocationTrackingOnMobile }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="methods.pinCode" class="asc-method kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-15px">
        <div class="asc-method-title">
          <div class="kenjo-font-weight-bold">{{ translations.pinCode }}</div>
          <span class="kenjo-font-size-12px kenjo-font-color-primary-5993e3">({{ translations.kioskMethod }})</span>
        </div>
        <div class="asc-list">
          <div class="kenjo-font-color-light-text-757575">
            <kenjo-icon [size]="18">kiosk</kenjo-icon>
            <div class="kenjo-font-size-12px kenjo-ml-10px">{{ translations.onlyWorksWithKiosk }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="methods.qrCode" class="asc-method kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-15px">
        <div class="asc-method-title">
          <div class="kenjo-font-weight-bold">{{ translations.qrCode }}</div>
          <span class="kenjo-font-size-12px kenjo-font-color-primary-5993e3">({{ translations.kioskMethod }})</span>
        </div>
        <div class="asc-list">
          <div class="kenjo-font-color-light-text-757575">
            <kenjo-icon [size]="18">kiosk</kenjo-icon>
            <div class="kenjo-font-size-12px kenjo-ml-10px">{{ translations.onlyWorksWithKiosk }}</div>
          </div>
          <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px">
            <kenjo-icon [size]="18">smartphone</kenjo-icon>
            <div class="kenjo-font-size-12px kenjo-ml-10px">{{ translations.smartphoneRequired }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="asc-button kenjo-mt-40px">
    <orgos-button-raised (click)="onPreviousStep()" color="Neutral">{{ translations.backLabel }}</orgos-button-raised>
    <orgos-button-raised (click)="onNextStep()" color="Success" [disabled]="!userAccount.data.attendancePolicy">{{ translations.nextLabel }}</orgos-button-raised>
  </div>
</div>
