import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PerformanceReviewSharedModule } from '@app/cloud-features/performance-review/performance-review.shared.module';
import { ShiftPlanModule } from '@app/cloud-features/shift-plan/shift-plan.module';
import { CoreFeaturesPipeModule } from '@app/common-components/core-features-pipe/core-features-pipe.module';
import { CustomRuleConditionModule } from '@app/common-components/custom-rule-condition/custom-rule-condition.module';
import { FullScreenHeaderModule } from '@app/common-components/full-screen-header/full-screen-header.module';
import { InfoMessageModule } from '@app/common-components/info-message/info-message.module';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { RangeDatepickerModule } from '@app/common-components/kenjo-range-datepicker/range-datepicker.module';
import { LayoutImportPayslipsModule } from '@app/common-components/layout-import-payslips/layout-import-payslips.module';
import { ManageUserPoliciesModule } from '@app/common-components/manage-user-policies/manage-user-policies.module';
import { PdfRenderModule } from '@app/common-components/pdf-render/pdf-render.module';
import { PermissionsPipeModule } from '@app/common-components/permissions-pipe/permissions-pipe.module';
import { PreviewerModule } from '@app/common-components/previewer/previewer.module';
import { RecruitingComponentsModule } from '@app/common-components/recruiting/recruiting-components.module';
import { StickySaveFooterModule } from '@app/common-components/sticky-save-footer/sticky-save-footer.module';
import { TimeOffUserModule } from '@app/common-components/time-off-user/time-off-user.module';
import { TimeTableModule } from '@app/common-components/time-table/time-table.module';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { WarningMessageModule } from '@app/common-components/warning-message/warning-message.module';
import { WidgetsModule } from '@app/common-components/widgets/widgets.module';
import { WorkingHoursModule } from '@app/common-components/working-hours/working-hours.module';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';
import { ConvertChildrenPipe } from '@app/standard/pages/people/payroll/components/convert-children/convert-children.pipe';
import { ButtonSemanticComponent } from '@app/standard/standalone-components/button-semantic/button-semantic.component';
import { ConflictsDialogComponent } from '@app/standard/standalone-components/conflicts-dialog/conflicts-dialog.component';
import { ToggleErrorButtonComponent } from '@app/standard/standalone-components/toggle-error-button/toggle-error-button.component';
import { NgxCumulioDashboardModule } from '@cumul.io/ngx-cumulio-dashboard';
import { QuillModule } from 'ngx-quill';

import { StandardPagesRegistry, standardPages } from './standard-pages.registry';
import { TaskDialogComponent } from './tasks/dialogs/task-dialog/task-dialog.component';
import { IconHighlightComponent } from '@app/standard/standalone-components/icon-highlight/icon-highlight.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    StandardComponentsModule,
    WidgetsModule,
    RecruitingComponentsModule,
    TimeOffUserModule,
    TruncateTextModule,
    PermissionsPipeModule,
    CoreFeaturesPipeModule,
    IconModule,
    PreviewerModule,
    PdfRenderModule,
    TimeTableModule,
    WorkingHoursModule,
    FullScreenHeaderModule,
    WarningMessageModule,
    InfoMessageModule,
    CustomRuleConditionModule,
    LayoutImportPayslipsModule,
    ManageUserPoliciesModule,
    RangeDatepickerModule,
    QuillModule.forRoot(),
    StickySaveFooterModule,
    NgxCumulioDashboardModule,
    ButtonSemanticComponent,
    ConflictsDialogComponent,
    ToggleErrorButtonComponent,
    IconHighlightComponent,
    PerformanceReviewSharedModule
  ],
  providers: [StandardPagesRegistry, DatePipe, DecimalPipe, ConvertChildrenPipe, ShiftPlanModule],
  declarations: [standardPages, TaskDialogComponent],
  exports: [standardPages],
})
export class StandardPagesModule {}
