import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import * as check from 'check-types';

@Component({
  templateUrl: 'add-edit-working-areas-dialog.html',
  styleUrls: ['add-edit-working-areas-dialog.scss'],
})
export class SettingsShiftPlanWorkingAreasDialog implements OnInit {
  dialogTranslation: any = {};
  isNewWorkingArea: boolean;
  workingArea: GenericCacheModel;

  nameCreateValidation: InputValidation;
  nameEditValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<SettingsShiftPlanWorkingAreasDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private snackBar: MatLegacySnackBar,
    private injector: Injector,
    private i18nDataPipe: I18nDataPipe
  ) {}

  ngOnInit(): void {
    this.workingArea = this.data.workingArea;
    this.isNewWorkingArea = this.data.isNewWorkingArea;
    this.dialogTranslation = this.data.dialogTranslation;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public addWorkingArea(): void {
    if (check.not.assigned(this.nameCreateValidation) || this.nameCreateValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close({
      workingAreaData: this.workingArea.data,
    });
  }

  public editWorkingArea(): void {
    if (check.not.assigned(this.nameEditValidation) || this.nameEditValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close({
      workingAreaData: this.workingArea.data,
    });
  }
}
