import { Component, ContentChild, Injector, Input, OnInit } from '@angular/core';

import { InternationalizationService } from '../../services/core/internationalization.service';
import { WidgetFooterComponent } from '../widget-footer/widget-footer.component';
import { WidgetHeaderChipComponent } from '@app/standard/components/widget-header-chip/widget-header-chip.component';

@Component({
  selector: 'orgos-widget',
  templateUrl: 'widget.component.html',
  styleUrls: ['widget.component.scss']
})
export class WidgetComponent implements OnInit {
  @Input() titleKey: string = '';
  @Input() noContentPadding: boolean = false;
  @Input() loadingWidget: boolean = true;
  @Input() existingData: boolean = false;
  @Input() footerHeight: number = 0;
  @Input() footerBackgroundColor: string = '#f8f8f8';
  @ContentChild(WidgetFooterComponent) footerComponent: WidgetFooterComponent;
  @ContentChild(WidgetHeaderChipComponent) chipComponent: WidgetHeaderChipComponent;

  translation: any = {};
  nameTranslation: any = {};

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('widget-component')
      .then((translation) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((nameTranslation) => {
        this.nameTranslation = nameTranslation;
      })
      .catch(() => {
        this.nameTranslation = {};
      });
  }
}
