<mat-form-field *ngIf="model && field && !readOnly">
  <mat-label>{{ label }}</mat-label>
  <input
   matInput
   type="number"
   [ngModel]="getValue()"
   (ngModelChange)="setValue($event)"
   [required]="required"
   [min]="min"
   [max]="max"
   [step]="step"
   autocomplete="off"
   (keydown)="!keyboardOnly || (keyboardOnly && checkKey($event.key))"
  />
  <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
  <div *ngIf="suffixIcon" matSuffix [matTooltip]="suffixIconTooltip" class="kenjo-cursor-pointer" (click)="suffixIconClick.emit()">
    <mat-icon class="icon-font-size">{{ suffixIcon }}</mat-icon>
  </div>
</mat-form-field>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getValue()" readOnly disabled [required]="required" autocomplete="off" />
  <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
</mat-form-field>
<div *ngIf="!isValueValid" class="inc-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
