import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { INomination } from '@app/cloud-features/performance-review/controllers/performance-review-nominations.controller';
import {
  IParticipantManageStep,
  IPerformanceReviewUsersMapInfo,
} from '@app/cloud-features/performance-review/controllers/performance-review-participants.controller';
import { IPerformanceSurveyResponseModel } from '@app/cloud-features/performance-review/services/performance-survey-response.service';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { IUserWorkModel } from '@app/models/user-work.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class PerformanceReviewService implements IGenericService {
  private PERFORMANCE_REVIEW_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/performance-review-db`;
  private PERFORMANCE_REVIEW_PERMISSIONS_KEY: string = 'performance-review';
  private PERFORMANCE_REVIEW_INTERNATIONALIZATION: string = 'performance-review-collection';
  private AMPLITUDE_EVENTS = {
    personal: { name: 'clicks my performance tab (within PR)', subcategory2: 'Access To-Dos/Activity' },
    reviews: { name: 'clicks reviews tab', subcategory2: 'Access Reviews' },
    results: { name: 'clicks results tab', subcategory2: 'Access Results' },
    compare: { name: 'clicks compare tab', subcategory2: 'Access Compare' },
  };
  private PERFORMANCE_REVIEW_SERVICE_NAME = 'PerformanceReviewService';

  constructor(
    private genericService: GenericService,
    private errorManager: ErrorManagerService,
    private http: HttpClient,
    private injector: Injector
  ) {}

  create(data: object): Promise<IPerformanceReviewModel> {
    return new Promise<IPerformanceReviewModel>((resolve, reject) => {
      this.genericService
        .create(this.PERFORMANCE_REVIEW_URL, data)
        .then((performanceReview: IPerformanceReviewModel) => {
          resolve(performanceReview);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'create'));
        });
    });
  }

  getById(id: string): Promise<IPerformanceReviewModel> {
    return new Promise<IPerformanceReviewModel>((resolve, reject) => {
      this.genericService
        .getById(this.PERFORMANCE_REVIEW_URL, id)
        .then((performanceReview: IPerformanceReviewModel) => {
          resolve(performanceReview);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.PERFORMANCE_REVIEW_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.PERFORMANCE_REVIEW_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.PERFORMANCE_REVIEW_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.PERFORMANCE_REVIEW_INTERNATIONALIZATION);
  }

  updateChildInReview(id: string, query: any, updateValue: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const data = {
        id: id,
        childCondition: query,
        updateValue: updateValue,
      };

      this.http
        .post(`${this.PERFORMANCE_REVIEW_URL}/update-child/${id}`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'updateChildInReview'));
        });
    });
  }

  updateFeedbacks(id: string, arrayFilters: any, updateValue: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const data = {
        id: id,
        arrayFilters: arrayFilters,
        updateValue: updateValue,
      };

      this.http
        .post(`${this.PERFORMANCE_REVIEW_URL}/update-feedbacks/${id}`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'updateChildInReview'));
        });
    });
  }

  getPerformanceReviews(): Promise<Array<IPerformanceReviewModel>> {
    const findBody = {
      _id: { $ne: null },
    };
    return new Promise<Array<IPerformanceReviewModel>>((resolve, reject) => {
      this.genericService
        .find(this.PERFORMANCE_REVIEW_URL, findBody)
        .then((performanceReview: Array<IPerformanceReviewModel>) => {
          resolve(performanceReview);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'getPerformanceReviews'));
        });
    });
  }

  updateNominations(
    newNominations: Array<INomination>,
    performanceReviewId: string,
    submit: boolean,
    revieweeId: string
  ): Promise<boolean> {
    const updateBody = {
      revieweeId,
      submit,
      reviewersIds: newNominations.map((iNomination) => iNomination.user._id.toString()),
    };
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };

    return this.http
      .put(`${this.PERFORMANCE_REVIEW_URL}/nominations/${performanceReviewId.toString()}`, updateBody, httpOptions)
      .toPromise()
      .then((result) => Promise.resolve(true))
      .catch((error) => {
        return Promise.reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'updateNominations'));
      });
  }

  getStandardNotifications(): Promise<Array<IPerformanceNotification>> {
    return new Promise<Array<IPerformanceNotification>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      this.http
        .get(`${this.PERFORMANCE_REVIEW_URL}/standard-notifications`, httpOptions)
        .toPromise()
        .then((notifications: Array<IPerformanceNotification>) => {
          resolve(notifications);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'getStandardNotifications'));
        });
    });
  }

  async extendReviewEndDate(
    reviewId: string,
    payload: { reviewEndEvent: ITimelineEvent; shareFeedbackImmediately: boolean }
  ): Promise<void> {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };

    try {
      await this.http.put(`${this.PERFORMANCE_REVIEW_URL}/extend-review/${reviewId}`, payload, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.PERFORMANCE_REVIEW_SERVICE_NAME, 'extendReviewEndDate');
    }
  }

  navigatePerformanceTab(performanceTab: PerformanceReviewTab) {
    this.injector.get(Router).navigateByUrl(`cloud/performance-review/${performanceTab}`);
    this.logAmplitudeEventTab(performanceTab);
  }

  logAmplitudeEventTab(performanceTab: PerformanceReviewTab) {
    const event = this.AMPLITUDE_EVENTS[performanceTab];
    if (!event) {
      return;
    }
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent(event.name, { category: 'Performance', subcategory1: 'Navigation', subcategory2: event.subcategory2 });
  }
}

export interface IPerformanceReviewModel {
  _id: string;
  title: string;
  description: string;
  type: string;
  selfAssessment: boolean;
  cycleBased: boolean;
  startDate: Date;
  endDate: Date;
  weekDay: number;
  launchTime: number;
  segments: any;
  rounds?: Array<any>;
  excludedEmployees: any;
  questions: Array<string>;
  questionSampling: string;
  includeMatrix: boolean;
  participants: Array<any>;
  participantIds?: Array<string>;
  selfAssessmentResponses: Array<any>;
  status: string;
  customReviewers: Array<any>;
  reviewerType: any;
  timeline?: Array<ITimelineEvent>;
  shareFeedbackImmediately?: boolean;
  revieweeChart?: any;
  reviewerChart?: any;
  isDraft: boolean;
  createFormStep: number;
  customNotifications?: Array<IPerformanceNotification>;
}

export interface IPerformanceReviewProcessInformation {
  segmentsAdded: {};
  participantIds: Array<string>;
  mergedParticipantIds: [];
  totalEmployees?: number;
  excludedEmployees: [];
  previousExcluded: [];
  excludedFromSegment: [];
  usersMapInfo: IPerformanceReviewUsersMapInfo;
  customReviewers: [];
  participants: Array<IParticipantManageStep>;
  typesHasChanged: boolean;
  stepsVisited: { details: boolean; participants: boolean; questions: boolean; timeline: boolean };
}
export interface IPerformanceReviewDetailModel {
  _id: string;
  title: string;
  status: string;
  shareFeedbackImmediately: boolean;
  selfAssessment: boolean;
  isDraft: boolean;
  createFormStep: number;
  chartsData: {
    revieweeChart: number[];
    reviewerChart: number[];
  };
  peopleResponsesList: any[];
}

export interface IPerformanceReviewDetailedModel {
  _id: string;
  title: string;
  description?: string;
  type: string;
  selfAssessment: boolean;
  cycleBased: boolean;
  startDate: Date;
  endDate: Date;
  launchTime: number;
  includeMatrix: boolean;
  isSelfAssessment?: boolean;
  response: {
    _id: string;
    status: string;
    revieweeId: string;
    reviewerId: string;
    ratingValue: number;
    matrixValue: Array<number>;
    ratingComment: string;
    shared: boolean;
    startedDate: Date;
    signedDate: Date;
    sharedDate: Date;
  };
  selfResponse: {
    _id: string;
    status: string;
    selfReviewerId: string;
    ratingValue: number;
    matrixValue: Array<number>;
    ratingComment: string;
    shared: boolean;
    startedDate: Date;
    signedDate: Date;
    sharedDate: Date;
  };
  reviewerResponseSurveys?: Array<IPerformanceSurveyResponseModel>;
  selfResponseSurveys?: Array<IPerformanceSurveyResponseModel>;
  reviewer?: {
    work: IUserWorkModel;
    personal: IUserPersonalModel;
  };
  reviewee?: {
    work: IUserWorkModel;
    personal: IUserPersonalModel;
  };
  leaderReviewers?: {
    [index: string]: {
      work: IUserWorkModel;
      personal: IUserPersonalModel;
      shared: boolean;
      reviewerSurveys?: Array<IPerformanceSurveyResponseModel>;
    };
  };
  leaderReviewResponses?: {
    [index: string]: {
      _id: string;
      status: string;
      revieweeId: string;
      reviewerId: string;
      ratingValue: number;
      matrixValue: Array<number>;
      ratingComment: string;
      shared: boolean;
      startedDate: Date;
      signedDate: Date;
      sharedDate: Date;
    };
  };
  readOnly?: boolean;
  isDraft: boolean;
  createFormStep: number;
}

export interface ISegmentModel {
  field: string;
  criteria: string;
}

export interface IPerformanceNotification {
  _id: string;
  name: string;
  subject: string;
  bodyType: string;
  language: string;
  updatedBy: string;
  updatedOn: Date;
  bodyQuill: {
    delta?: any;
    html?: any;
  };
}
export interface ITimelineEvent {
  name: string;
  date?: Date;
  eventRelated?: {
    name?: string;
    unit?: 'days' | 'weeks';
    amount?: number;
  };
}
export interface ITimelineEventMap {
  name: PerformanceReviewTimelineEvent;
  date?: Date;
  eventRelated?: PerformanceReviewTimelineEvent;
}
export interface ITimelineEventOutput {
  timelineEvents: Map<PerformanceReviewTimelineEvent, ITimelineEventMap>;
  resetEvents?: { start?: boolean; end: boolean };
}

export type PerformanceReviewTab = 'personal' | 'reviews' | 'results' | 'compare';
export type PerformanceReviewAction = 'edit' | 'duplicate' | 'reminder' | 'delete';
export type PerformanceReviewFeedbackOption = 'immediately' | 'afterCycleEnds';
export type PerformanceReviewTimelineEvent = 'peerNominationStarts' | 'peerNominationEnds' | 'reviewStart' | 'reviewEnd';
export type PerformanceReviewType = 'direct' | 'manager' | 'peer' | 'self';

export interface IPerformanceReviewActionsPermissions {
  edit: {
    details: boolean;
    participants: boolean;
    questions: boolean;
    timeline: boolean;
    notifications: boolean;
  };
  reminder: boolean;
  duplicate: boolean;
  delete: boolean;
}
