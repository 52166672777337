import { Component, HostBinding, HostListener, Injector, Input } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ShiftPlanCapacityDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/dialogs/shift-plan-capacity.dialog';
import {
  IShiftPlanCapacityModel,
  IShiftPlanCapacityPerDay,
  ShiftPlanCapacityService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-capacity.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { SHIFT_PLAN_MONTHLY_VIEW, SHIFT_PLAN_WEEKLY_VIEW } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

type ChipColor = 'Neutral' | 'Warning' | 'Danger';

@Component({
  selector: 'kenjo-shift-plan-capacity',
  templateUrl: 'shift-plan-capacity.component.html',
  styleUrls: ['shift-plan-capacity.component.scss'],
})
export class ShiftPlanCapacityComponent {
  plannedCapacityTimePerDay: Array<number>;
  capacityMap: IShiftPlanCapacityPerDay;
  capacityId: string;
  chipColors: { [key: string]: ChipColor } = { neutral: 'Neutral', warning: 'Warning', danger: 'Danger' };
  VIEWS = { MONTH: SHIFT_PLAN_MONTHLY_VIEW, WEEK: SHIFT_PLAN_WEEKLY_VIEW };
  view: 'week' | 'month';

  @HostBinding('style.--monthly-tab') tabWidthMinimal: string;
  @Input() parentTranslation: { [key: string]: string } = {};
  @Input() states: { loading: boolean; dropping: boolean; dragging: boolean };
  @Input() workingTimeInMinutesPerDay: Array<number>;
  @Input() employeeCountByDays: Array<number>;
  @Input() daysOfThisMonth: Array<moment.Moment>;
  @Input() hasScrolled: boolean = false;
  @Input() minimal: boolean = false;

  @Input()
  set viewRange(newValue: 'week' | 'month') {
    this.view = newValue;
    this.getCapacity();
  }

  constructor(private injector: Injector) {}

  private async getCapacity() {
    const capacity: IShiftPlanCapacityModel = await this.injector.get(ShiftPlanCapacityService).getCapacity();
    this.capacityId = capacity._id;
    this.capacityMap = {
      monday: capacity.monday,
      tuesday: capacity.tuesday,
      wednesday: capacity.wednesday,
      thursday: capacity.thursday,
      friday: capacity.friday,
      saturday: capacity.saturday,
      sunday: capacity.sunday,
    };

    if (this.view === this.VIEWS.MONTH && this.daysOfThisMonth && check.nonEmptyArray(this.daysOfThisMonth)) {
      this.fillMonthlyCapactiy();
      return;
    }

    this.plannedCapacityTimePerDay = Object.values(this.capacityMap);
  }

  public async openShiftPlanCapacityDialog(): Promise<void> {
    if (this.states.loading || this.states.dropping) {
      return;
    }

    const data = {
      capacity: this.capacityMap,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ShiftPlanCapacityDialog, { data });
    dialogRef.afterClosed().subscribe(async (capacityData: any) => {
      try {
        if (check.not.assigned(capacityData)) {
          return;
        }
        capacityData.ownerId = this.injector.get(AuthenticationService).getLoggedUser()._id;
        const result = await this.injector.get(ShiftPlanCapacityService).updateCapacity(this.capacityId, capacityData);
        if (check.assigned(result?.code)) {
          return;
        }

        this.injector.get(MatLegacySnackBar).open(this.parentTranslation.plannedCapacityUpdated, 'OK', {
          duration: 5000,
        });
        await this.getCapacity();
      } catch {}
    });
  }

  fillMonthlyCapactiy() {
    if (this.minimal) {
      this.calculateDateTabWidth();
    }

    const capacityArray = Object.values(this.capacityMap) as Array<number>;
    this.plannedCapacityTimePerDay = this.daysOfThisMonth.map((day) => {
      const dayWeekIndex = moment(day).isoWeekday();
      return capacityArray[dayWeekIndex - 1];
    });
  }

  calculateDateTabWidth() {
    const tabWidth = (window.innerWidth - 325) / this.daysOfThisMonth.length;
    if (tabWidth < 49) {
      this.tabWidthMinimal = '49px';
      return;
    }
    this.tabWidthMinimal = `${tabWidth}px`;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    if (this.view === this.VIEWS.MONTH) {
      this.calculateDateTabWidth();
    }
  }
}
