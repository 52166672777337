<orgos-dialog-container class="cd-main-container" [title]="dialogTitle" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-editor [label]="translation.newAppreciationDialogSubtitle" required="true" size="medium" [(value)]="appreciation.message" [toolbar]="toolbar" (validation)="messageValidation = $event">
    <orgos-input-error *ngIf="messageValidation && messageValidation.getError('required')"> {{ translation.addYourAppreciationRequired }}</orgos-input-error>
  </orgos-input-simple-editor>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised [disabled]="isLoading" (click)="closeDialog()" color="Neutral"> {{ translation.manageWidgetDialogCancelButton }}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised [disabled]="isLoading || !messageValidation || messageValidation.hasErrors()" (click)="save()" color="Success">{{ translation.manageWidgetDialogSaveButton }} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
