import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { InputSearchShiftPlanComponent } from '@app/cloud-features/shift-plan/components/input-search-shift-plan/input-search-shift-plan.component';
import { TableComponent } from '@app/standard/components/table/table.component';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { ILocationOfficeModel, OfficeService } from '@app/standard/services/company/office.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { SHIFT_PLAN_ACTIVATION_STATUS_FINISHED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-shift-plan-employee-locations',
  templateUrl: 'shift-plan-employee-locations.component.html',
  styleUrls: ['shift-plan-employee-locations.component.scss'],
})
export class ShiftPlanEmployeeLocations implements OnInit {
  @Input() activateShiftPlanTranslation: any;
  @Input() shiftPlanSettingsTranslations: any;
  @Input() userWork: GenericSimpleModel;
  @Input() progressIndex: number;
  @Output() changeIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() locationsIsCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(TableComponent) tableComponent: TableComponent;
  @ViewChild(InputSearchShiftPlanComponent) searchComponent: InputSearchShiftPlanComponent;

  allLocations: Array<ILocationEmployeeModel> = [];
  selectedLocationsIds: Array<string> = [];
  selectedLocationsIdsInitialValues: Array<string> = [];
  countriesTranslations: any;
  filteredLocations: Array<ILocationEmployeeModel> = [];
  currentUserOfficeId: string = '';

  displayedColumns: Array<string> = ['selected', 'name', 'street', 'city', 'country'];
  dataHasChanged: boolean = false;
  formerLocationsData: Array<string> = [];
  deactivatedSelectedLocations: Array<string> = [];
  MAX_TABLE_HEIGHT_WITHOUT_PAGINATION: string = '325px';
  MAX_TABLE_HEIGHT_WITH_PAGINATION: string = '270px';

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.fetchTranslations();
    this.fetchAllLocations();
    this.currentUserOfficeId = this.userWork.data.officeId;
    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.formerLocationsData = this.userWork.data.locations.slice();
    }
  }

  private async fetchAllLocations() {
    try {
      this.allLocations = await this.injector.get(OfficeService).getSortedOffices();
      this.registerLocationsData();
      this.checkIfFieldsAreCorrect();
    } catch {
      // do nothing
    }
  }

  // Register data necessary for the component functionality
  registerLocationsData() {
    if (check.assigned(this.userWork.data.locations) && check.not.emptyArray(this.userWork.data.locations)) {
      this.initLocationsData();
    }
    this.filteredLocations = this.allLocations;
  }

  async fetchTranslations() {
    this.countriesTranslations = await this.injector.get(InternationalizationService).getAllTranslation('standard-picklists');
  }

  changeSelectedLocations(checked: boolean, id: string) {
    if (checked) {
      this.selectedLocationsIds = [...this.selectedLocationsIds, id];
    } else {
      this.selectedLocationsIds = this.selectedLocationsIds.filter((iLocation) => {
        return iLocation != id;
      });
    }

    this.allLocations.map((iLocation) => {
      if (iLocation._id === id) {
        iLocation.selected = checked;
      }
    });
    this.filteredLocations.map((iLocation) => {
      if (iLocation._id === id) {
        iLocation.selected = checked;
      }
    });
    this.filteredLocations = this.allLocations;
    const checkIfLocationsAreTheSame = _.isEmpty(_.xor(this.selectedLocationsIdsInitialValues, this.selectedLocationsIds));
    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataHasChanged = !checkIfLocationsAreTheSame;
    }
    this.checkIfFieldsAreCorrect();
  }

  nextStep() {
    this.saveData();
    this.progressIndex++;
    this.changeIndex.emit(this.progressIndex);
  }

  checkIfFieldsAreCorrect() {
    const filteredSelectedIds = this.filterActiveLocations(this.selectedLocationsIds);
    const fieldsAreCorrect = check.not.emptyArray(filteredSelectedIds);
    this.locationsIsCompleted.emit(fieldsAreCorrect);
  }

  async saveData() {
    this.userWork.data.locations = [];
    this.userWork.data.locations = [...this.selectedLocationsIds, ...this.deactivatedSelectedLocations];
    this.formerLocationsData = [...this.userWork.data.locations];
    this.registerLocationsData();
    await this.injector.get(UserWorkService).updateById(this.userWork.data._id, { locations: this.userWork.data.locations });

    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataSaved.emit(true);
    }
  }

  cancelChanges() {
    this.userWork.data.locations = this.formerLocationsData;
    this.registerLocationsData();
    this.checkIfFieldsAreCorrect();
    this.searchComponent.searchTerm = '';
  }

  saveSnackbar() {
    this.injector.get(MatLegacySnackBar).open(this.activateShiftPlanTranslation.changesSuccessful, 'OK', {
      duration: 5000,
    });
  }

  initLocationsData(): void {
    this.selectedLocationsIds = this.filterActiveLocations(this.userWork.data.locations);
    this.selectedLocationsIdsInitialValues = this.selectedLocationsIds;

    this.allLocations.forEach((iLocation) => {
      iLocation.selected = this.userWork.data.locations?.includes(iLocation._id);
    });

    this.deactivatedSelectedLocations = this.allLocations.reduce((acc, curr) => {
      return !curr.activeForShiftplan && curr.selected ? [...acc, curr._id] : acc;
    }, []);
  }

  filterActiveLocations(locationIds): Array<string> {
    const filteredLocations = this.allLocations.filter((iLocation) => iLocation.activeForShiftplan && locationIds.includes(iLocation._id));
    return filteredLocations.map((iLocation: ILocationOfficeModel) => iLocation._id);
  }
}

export interface ILocationEmployeeModel extends ILocationOfficeModel {
  selected?: boolean;
}
