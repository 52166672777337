import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '../../components/i18n-data/i18n-data.pipe';
import { AuthenticationService } from '../../services/core/authentication.service';
import { IProfileModel, ProfileService } from '../../services/profile/profile.service';
import { SettingsBarService } from '../../services/settings/settings-bar.service';
import { UserAccountService } from '../../services/user/user-account.service';
import { GenericPage, ITranslationResource } from '../generic.page';
import { CloneProfileDialog } from './clone-profile-dialog/clone-profile.dialog';

@Component({
  selector: 'orgos-settings-roles-and-permissions',
  templateUrl: 'settings-roles-and-permissions.page.html',
  styleUrls: ['settings-roles-and-permissions.page.scss'],
})
export class SettingsRolesAndPermissionsPage extends GenericPage {
  listProfiles: Array<IProfileModel>;
  chosenProfile: IProfileModel;
  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'settings-roles-and-permissions-page' },
    { name: 'picklist', translationKey: 'standard-picklists' },
    { name: 'misc', translationKey: 'settings-top-bar' },
  ];
  canCreate: boolean = false;
  profileIdToNumberOfUsers: any = {};

  optionHover: number = -1;

  protected beforeInit(): Promise<void> {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.canCreate = loggedUser.profileKey === 'admin';

    return Promise.resolve();
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.findAllProfiles()
      .then(() => {})
      .then(() => {
        resolveFetchData();
      })
      .catch((error) => {
        // An error is already shown
        rejectFetchData();
      });
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(SettingsBarService)
        .getOptions(this.i18n.misc)
        .then((options) => {
          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.profilesAndPermissionsTab]);
          resolve();
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view settings page', { category: 'Navigation', type: 'profiles and permissions' });
    return Promise.resolve();
  }

  private findAllProfiles(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.injector
        .get(ProfileService)
        .getProfiles()
        .then((allProfiles: Array<IProfileModel>) => {
          const translatedStandardProfiles = allProfiles
            .filter((standardProfile) => {
              return check.assigned(standardProfile._profileKey);
            })
            .map((profile) => {
              profile['name'] = this.i18n.picklist.profiles[profile._profileKey];
              return profile;
            });
          const standardProfileById = _.keyBy(translatedStandardProfiles, '_id');
          const orderedStandardProfiles = [];
          orderedStandardProfiles.push(standardProfileById['admin']);
          orderedStandardProfiles.push(standardProfileById['hr-admin']);
          orderedStandardProfiles.push(standardProfileById['recruiter']);
          orderedStandardProfiles.push(standardProfileById['finance-admin']);
          orderedStandardProfiles.push(standardProfileById['manager']);
          orderedStandardProfiles.push(standardProfileById['employee']);
          orderedStandardProfiles.push(standardProfileById['restricted']);

          const customProfiles = allProfiles.filter((customProfile) => {
            return check.not.assigned(customProfile._profileKey);
          });
          const orderedCustomProfiles = _.orderBy(customProfiles, ['name'], ['asc']);

          this.listProfiles = orderedStandardProfiles.concat(orderedCustomProfiles);
          if (check.not.assigned(this.chosenProfile) && check.nonEmptyArray(this.listProfiles)) {
            this.changeProfile(this.listProfiles[0]);
          }
          return this.countUsersPerProfile();
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          // An error is already shown
          this.listProfiles = [];
          reject(error);
        });
    });
  }

  private countUsersPerProfile(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.profileIdToNumberOfUsers = {};
      this.injector
        .get(UserAccountService)
        .getAllUserAccount(false, false)
        .then((allUserAccounts) => {
          allUserAccounts.forEach((iUserAccount: any) => {
            if (check.not.assigned(this.profileIdToNumberOfUsers[iUserAccount.profileKey])) {
              this.profileIdToNumberOfUsers[iUserAccount.profileKey] = 0;
            }
            this.profileIdToNumberOfUsers[iUserAccount.profileKey]++;
          });
          resolve();
        })
        .catch((error) => {
          this.profileIdToNumberOfUsers = {};
          reject(error);
        });
    });
  }

  public refreshProfiles(chosenProfile: IProfileModel): void {
    this.findAllProfiles()
      .then(() => {
        this.changeProfile(chosenProfile);
      })
      .catch(() => {});
  }

  public refreshUsersInProfile(): void {
    this.countUsersPerProfile().then(() => {});
  }

  public changeProfile(newProfile: IProfileModel): void {
    this.chosenProfile = newProfile;
  }

  public createNewProfile(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(CloneProfileDialog);
    dialogRef.afterClosed().subscribe((newProfile) => {
      if (check.assigned(newProfile) && check.object(newProfile) && check.nonEmptyObject(newProfile)) {
        this.findAllProfiles().then(() => {
          const profileToChoose = this.listProfiles.find((iProfile) => {
            return iProfile._id === newProfile._id;
          });
          if (check.assigned(profileToChoose)) {
            this.changeProfile(profileToChoose);
          }
        });
      }
    });
  }

  public deleteProfileDialog(iProfile: IProfileModel): void {
    const translationData = {
      profileName: iProfile.name,
    };
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(this.i18n.page.confirmDeleteTitle, translationData),
      subtitleText: this.i18n.page.confirmDeleteSubtitle,
      cancelButtonText: this.i18n.page.cancelButtonText,
      confirmButtonText: this.i18n.page.deleteProfileButtonText,
      confirmButtonColor: 'Danger',
    };
    this.injector
      .get(UserAccountService)
      .find({ profileKey: iProfile._id })
      .then((listUserAccounts: Array<any>) => {
        if (check.assigned(listUserAccounts) && check.array(listUserAccounts) && check.nonEmptyArray(listUserAccounts)) {
          delete data.confirmButtonText;
          data.titleText = this.i18n.page.notDeletableTitle;
          data.subtitleText = this.injector.get(I18nDataPipe).transform(this.i18n.page.notDeletableSubtitle, translationData);
        }
        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
        dialogRef.afterClosed().subscribe((confirm) => {
          if (!confirm || confirm === false) {
            return;
          }

          this.injector
            .get(ProfileService)
            .deleteById(iProfile[fieldConstants.ID])
            .then(() => {
              if (this.chosenProfile._id === iProfile._id) {
                this.chosenProfile = null;
              }
              this.findAllProfiles();
              const message = this.injector.get(I18nDataPipe).transform(this.i18n.page.profileDeletedSnackbar, translationData);
              this.injector.get(MatLegacySnackBar).open(message, 'OK', { duration: 5000 });
            })
            .catch(() => {});
        });
      })
      .catch(() => {});
  }
}
