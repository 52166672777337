<ng-container *ngIf="i18n && i18n.page">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
    {{parentTranslation.rolesTagsHeader}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{parentTranslation.tagsSettings}}</span>
  </div>
  <div class="kenjo-mt-30px kenjo-font-color-light-text-757575">{{i18n.page.tagsDescription}}</div>

  <orgos-action *ngIf="canCreateTags && allTags" icon="add_circle" class="kenjo-mt-30px" (click)="addTag()"
    >{{i18n.page.addTagButton}}</orgos-action
  >
  <div *ngIf="allTags.length === 0" class="kenjo-text-align-center kenjo-p-40px">
    <div class="kenjo-flex-horizontal kenjo-flex-center">
      <kenjo-icon [size]="16" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon
      ><span class="kenjo-font-size-16px">{{i18n.page.noTagsHeader}}</span>
    </div>
    <div class="kenjo-mt-20px kenjo-font-color-light-text-757575">{{i18n.page.noTagsDescription}}</div>
  </div>
  <kenjo-input-search-shift-plan
    class="sspt-tag-container"
    *ngIf="allTags && allTags.length > 0 && mappedTagsPermissions"
    [list]="allTags"
    [placeholder]="i18n.page.searchForTags"
    (filteredResults)="filteredTags = $event"
  ></kenjo-input-search-shift-plan>
  <orgos-container class="kenjo-mt-20px kenjo-mb-10px sspt-tag-container" aspect="card" *ngIf="filteredTags && filteredTags.length > 0">
    <orgos-table [fullHeight]="true" [data]="filteredTags" [displayedColumns]="['name', 'category' ,'actions']">
      <orgos-table-column columnKey="name" [header]="i18n.page.tagNameColumn" [sortable]="true">
        <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
          <kenjo-truncate-text class="sspt-tag-name">{{row.name}}</kenjo-truncate-text>
        </div>
      </orgos-table-column>
      <orgos-table-column columnKey="category" [header]="i18n.page.tagCategoryColumn" [sortable]="true" cellWidth="45">
        <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
          {{tagOptionsTranslations[row.category]}}
        </div>
      </orgos-table-column>
      <orgos-table-column columnKey="actions" [actionColumn]="true" cellWidth="15">
        <div *orgosTableCell="let row" class="kenjo-text-align-left">
          <kenjo-icon
            [size]="20"
            (click)="editTag(row)"
            [matTooltip]="mappedTagsPermissions[row._id] ? parentTranslation.noPermissionsTooltip : ''"
            matTooltipPosition="below"
            [class.sspt-icon-disabled]="mappedTagsPermissions[row._id]"
            class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >mode_edit</kenjo-icon
          >
          <kenjo-icon
            [size]="20"
            (click)="deleteTag(row)"
            [matTooltip]="mappedTagsPermissions[row._id] ? parentTranslation.noPermissionsTooltip : ''"
            matTooltipPosition="below"
            [class.sspt-icon-disabled]="mappedTagsPermissions[row._id]"
            class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757 kenjo-ml-10px"
            >delete</kenjo-icon
          >
        </div>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>
  <kenjo-shift-plan-paginator
    class="sspt-tag-container"
    [parentTranslation]="parentTranslation"
    [data]="allTags"
    (recordsToShow)="setFilteredTagsToShow($event)"
  ></kenjo-shift-plan-paginator>
</ng-container>
