<orgos-widget titleKey="anniversary" [loadingWidget]="loadingWidget" [existingData]="(todayUsers && todayUsers.length > 0) || (thisWeekUsers && thisWeekUsers.length > 0) || (followingMonthUsers && followingMonthUsers.length > 0)" [footerHeight]="40">
  <orgos-widget-content>
    <ng-container *ngIf="todayUsers && todayUsers.length > 0">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ translation.todayLabel }}
      </div>
      <orgos-column-container wrap="true" class="kenjo-mb-10px">
        <orgos-column *ngFor="let user of todayUsers" size="0" class="kenjo-mv-10px kenjo-mr-10px">
          <orgos-avatar
            *ngIf="user.userPersonal && user.userWork"
            (click)="navigateToPersonalPage(user.userPersonal._id)"
            class="wb-avatar"
            [class.wb-avatar-selected]="userSelected === user"
            [photoUrl]="user.userPersonal._photo?._url"
            [avatarName]="user.userPersonal.displayName"
            size="small"
            (mouseenter)="userSelected = user"
          ></orgos-avatar>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <ng-container *ngIf="thisWeekUsers && thisWeekUsers.length > 0">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ translation.thisWeekLabel }}
      </div>
      <orgos-column-container wrap="true" class="kenjo-mb-10px">
        <orgos-column *ngFor="let user of thisWeekUsers" size="0" class="kenjo-mv-10px kenjo-mr-10px">
          <orgos-avatar
            *ngIf="user.userPersonal && user.userWork"
            (click)="navigateToPersonalPage(user.userPersonal._id)"
            class="wb-avatar"
            [class.wb-avatar-selected]="userSelected === user"
            [photoUrl]="user.userPersonal._photo?._url"
            [avatarName]="user.userPersonal.displayName"
            size="small"
            (mouseenter)="userSelected = user"
          ></orgos-avatar>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <ng-container *ngIf="followingMonthUsers && followingMonthUsers.length > 0">
      <div *ngFor="let user of followingMonthUsers" size="0" class="kenjo-mb-10px">
        <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ getDateInMomentFormat(user.month) | date: 'MMMM':'UTC' | uppercase }}
        </div>
        <ng-container *ngIf="user.anniversaries && user.anniversaries.length > 0">
          <orgos-column-container wrap="true">
            <orgos-column *ngFor="let user of user.anniversaries" size="0" class="kenjo-mv-10px kenjo-mr-10px">
              <orgos-avatar
                *ngIf="user.userPersonal && user.userWork"
                (click)="navigateToPersonalPage(user.userPersonal._id)"
                class="wb-avatar"
                [class.wb-avatar-selected]="userSelected === user"
                [photoUrl]="user.userPersonal._photo?._url"
                [avatarName]="user.userPersonal.displayName"
                size="small"
                (mouseenter)="userSelected = user"
              >
              </orgos-avatar>
            </orgos-column>
          </orgos-column-container>
        </ng-container>
      </div>
    </ng-container>
  </orgos-widget-content>
  <orgos-widget-footer>
    <kenjo-truncate-text class="kenjo-pb-10px kenjo-font-size-14px" *ngIf="userSelected && userSelected.userPersonal"> {{ userSelected.userPersonal.displayName }}</kenjo-truncate-text>
    <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px" *ngIf="userSelected && userSelected.userWork">
      {{ translation.anniversaryLabel }}: <span class="kenjo-font-color-text-333333">{{ userSelected.userWork.startDate | date: getDateFormat('dayMonth'):'UTC' }} {{ translation.numberOfYearsInCompany | i18nData: getNumberOfYearsInCompany(userSelected.userWork.originalStartDate) }}</span>
    </div>
  </orgos-widget-footer>
</orgos-widget>
