import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { IAvailableActions } from '@app/cloud-features/recruiting/recruiting-candidate-main/model/available-actions.model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { RecruitingCandidateService } from '@app/standard/services/recruiting/controllers/recruiting-candidate.service';
import { IHiringTeamModel } from '@app/standard/services/recruiting/hiring-team.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-recruiting-activity-widget',
  templateUrl: 'recruiting-activity-widget.component.html',
  styleUrls: ['recruiting-activity-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecruitingActivityWidgetComponent implements OnInit {
  i18n: any = {};

  @Input() candidate: GenericSimpleModel;
  @Input() currentPosition: GenericSimpleModel;
  @Input() currentPositionCandidate: GenericSimpleModel;
  @Input() availableActions: IAvailableActions;
  @Input() candidateId: any = {};
  @Input() allUserPersonal: Array<IUserPersonalModel>;
  @Input() teamMembers: Array<IHiringTeamModel>;
  allActivity: Array<IRecruitingActivity>;
  currentUserId: string;
  hiringTeamActivities: Array<IRecruitingActivity>;
  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    this.initTranslationsAndData();
  }

  async initTranslationsAndData() {
    this.i18n.activity = await this.injector.get(InternationalizationService).getAllTranslation('recruiting-activity-widget');
    this.i18n.scorecard = await this.injector.get(InternationalizationService).getAllTranslation('recruiting-score-candidate-dialog');
    this.i18n.standardPicklists = await this.injector.get(InternationalizationService).getAllTranslation('standard-picklists');
    await this.initAllActivity();
  }

  async initAllActivity() {
    try {
      const result = await this.injector.get(RecruitingCandidateService).getCandidateActivity(this.candidate.data._id, this.currentPosition.data._id);
      if (check.nonEmptyArray(result)) {
        this.allActivity = result.map((iActivity) => {
          const { mainInfoTranslationKey, mainInfoTranslationData, mainInfo } = iActivity;
          let mainInfoTranslated = mainInfo;
          if (check.assigned(mainInfoTranslationKey)) {
            if (check.nonEmptyObject(mainInfoTranslationData)) {
              const data = mainInfoTranslationData;
              if (check.assigned(data.scoreValueTranslationKey)) {
                data.scoreValue = this.i18n.scorecard[data.scoreValueTranslationKey];
              }
              if (check.assigned(data.roleName)) {
                data.roleName = this.i18n.standardPicklists.hiringTeamRoles[data.roleName];
              }
              mainInfoTranslated = this.injector.get(I18nDataPipe).transform(this.i18n.activity[mainInfoTranslationKey], data);
            } else {
              mainInfoTranslated = this.i18n.activity[mainInfoTranslationKey];
            }
          }

          return { ...iActivity, mainInfo: mainInfoTranslated };
        });
      } else {
        this.allActivity = [];
      }
      this.cdr.detectChanges();
    } catch (e) {
      this.allActivity = [];
    }
  }

  trackActivities(index: number, activity: IRecruitingActivity): number {
    return activity.date.valueOf();
  }

  public goToCandidate(candidateId: string, positionId?: string): void {
    if (check.assigned(positionId)) {
      this.injector.get(Router).navigateByUrl(`cloud/recruiting/candidate/${candidateId}/${positionId}`);
      return;
    }
    this.injector.get(Router).navigateByUrl(`cloud/recruiting/candidate/${candidateId}`);
  }
}

export interface IRecruitingActivity {
  _id?: string;
  comment?: any;
  _createdById?: string;
  _candidateId?: string;
  _positionId?: string;
  icon: string;
  date: moment.Moment;
  mainInfo: string;
  mainInfoTranslationKey?: string;
  mainInfoTranslationData?: any;
  additionalInfoTitle?: string;
  additionalInfoText?: string;
  additionalInfoHtml?: string;
  iconColor?: string;
  isPrivate?: boolean;
  agency?: string;
}
