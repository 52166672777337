<div class="dialog-box" *ngIf="i18n">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()">clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <div class="kenjo-mh-60px">
    <div class="kenjo-font-size-30px kenjo-text-align-center">{{i18n.addViewDialog.title}}</div>
    <div class="kenjo-mt-10px kenjo-font-size-16px kenjo-text-align-center">{{i18n.addViewDialog.subtitle}}</div>
  </div>

  <orgos-column-container centerColumns="true" class="kenjo-mv-80px kenjo-mh-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="3">
      <orgos-input-simple-text [label]="i18n.commonDialog.orgChartNameLabel" [(value)]="viewInfo.name" (validation)="nameValidation = $event" required="true" class="kenjo-block">
        <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">{{i18n.commonDialog.nameFieldRequired}} </orgos-input-error>
      </orgos-input-simple-text>
      <orgos-input-simple-select [label]="i18n.addViewDialog.selectBase" [options]="baseOptions" [autoOrder]="false" [(value)]="viewInfo.base" class="kenjo-block kenjo-mt-40px"> </orgos-input-simple-select>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-mb-20px kenjo-mh-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" class="kenjo-text-align-center kenjo-mr-40px">
      <kenjo-icon class="kenjo-font-color-light-text-757575 kenjo-cursor-pointer" [size]="30" (click)="closeDialog()">cancel_icon</kenjo-icon>
      <div class="kenjo-font-size-12px kenjo-mt-5px">
        {{i18n.commonDialog.cancelButtonLabel}}
      </div>
    </orgos-column>
    <orgos-column size="0" class="kenjo-text-align-center kenjo-ml-40px">
      <kenjo-icon
        [size]="30"
        (click)="addView()"
        [class.kenjo-cursor-pointer]="nameValidation && nameValidation.isValid()"
        [class.kenjo-font-color-light-text-757575]="!nameValidation || nameValidation.isValid()"
        [class.kenjo-font-color-disabled-c4c4c4]="nameValidation && nameValidation.getError('required')"
        >arrow_next_circle
      </kenjo-icon>
      <div class="kenjo-font-size-12px kenjo-mt-5px" [class.kenjo-font-color-disabled-c4c4c4]="nameValidation && nameValidation.getError('required')">
        {{i18n.addViewDialog.addButtonLabel}}
      </div>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
