import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import {
  AudienceSelectorComponentService,
  IAudienceSelectOptions,
  IAudienceVisibility,
} from '@app/standard/components/audience-selector/services/audience-selector.component.service';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as _ from 'lodash';

export type EntityType = 'companyIds' | 'areaIds' | 'officeIds' | 'departmentIds' | 'teamIds';

@Component({
  selector: 'kenjo-audience-selector',
  templateUrl: 'audience-selector.component.html',
  styleUrls: ['audience-selector.component.scss'],
})
export class AudienceSelectorComponent implements OnInit {
  @ViewChild(SearchComponent) searchComponent: SearchComponent;
  @Input() audienceVisibility: IAudienceVisibility;
  @Input() includeEmployees: boolean = true;
  @Input() selectEntityLabel: string = ''; // Label used in the picklist that allows to choose company, area, department, office or team
  @Input() selectUserLabel: string = ''; // Label used in the input of user
  @Input() disabled: boolean = false;

  @Output() onChange: EventEmitter<IAudienceVisibility> = new EventEmitter<IAudienceVisibility>();

  private usersSelected: Array<string> = [];
  private allUsers: Array<IUserPersonalModel>;

  translation: any = {};
  audienceOptions: IAudienceSelectOptions = {};
  selectValue: Array<string> = []; // Array that combines all possible ids of the different entities of EntityType

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.fetchTranslations();
    this.buildSelectOptions();
  }

  private async fetchTranslations() {
    this.translation = await this.injector.get(InternationalizationService).getAllTranslation('audience-selector-component');
  }

  private async buildSelectOptions() {
    this.audienceOptions = await this.injector.get(AudienceSelectorComponentService).getAudienceSelector(this.includeEmployees);

    if (this.audienceOptions?.mapUserIdToUserPersonal && check.nonEmptyObject(this.audienceOptions.mapUserIdToUserPersonal)) {
      this.allUsers = Object.values(this.audienceOptions.mapUserIdToUserPersonal);
    }

    this.initSelectedValues();
  }

  // If there are already some values for audienceVisibility
  private initSelectedValues() {
    this.selectValue = [];
    if (!this.audienceVisibility) {
      this.audienceVisibility = {};
      return;
    }

    if (this.audienceVisibility?.companyIds?.length) {
      this.selectValue = this.selectValue.concat(this.audienceVisibility.companyIds);
    }

    if (this.audienceVisibility?.officeIds?.length) {
      this.selectValue = this.selectValue.concat(this.audienceVisibility.officeIds);
    }

    if (this.audienceVisibility?.departmentIds?.length) {
      this.selectValue = this.selectValue.concat(this.audienceVisibility.departmentIds);
    }

    if (this.audienceVisibility?.areaIds?.length) {
      this.selectValue = this.selectValue.concat(this.audienceVisibility.areaIds);
    }

    if (this.audienceVisibility?.teamIds?.length) {
      this.selectValue = this.selectValue.concat(this.audienceVisibility.teamIds);
    }

    if (this.includeEmployees && this.audienceVisibility?.userIds?.length) {
      this.audienceVisibility.userIds.forEach((userId: string) => {
        this.assignUser(userId);
      });
    }
  }

  updateSelectionOfEntity(selectedIds: Array<string>): void {
    this.resetAudienceVisibility(false);
    selectedIds.forEach((id) => {
      const entityName = this.getEntityOfId(id);
      if (!this.audienceVisibility[entityName]) {
        this.audienceVisibility[entityName] = [];
      }
      this.audienceVisibility[entityName].push(id);
    });
    this.onChange.emit(this.audienceVisibility);
  }

  private resetAudienceVisibility(resetUsersToo: boolean = true): void {
    if (resetUsersToo) {
      this.audienceVisibility = {};
      return;
    }

    delete this.audienceVisibility.areaIds;
    delete this.audienceVisibility.companyIds;
    delete this.audienceVisibility.departmentIds;
    delete this.audienceVisibility.officeIds;
    delete this.audienceVisibility.teamIds;
  }

  // Given an id, depending on which array of IAudienceSelectOptions, it will return a different value for EntityType
  private getEntityOfId(id: string): EntityType {
    if (this.audienceOptions?.offices?.length) {
      const isInEntity = this.audienceOptions.offices.find((selectOption: ISelectOption) => {
        return selectOption.value === id;
      });
      if (isInEntity?.value) {
        return 'officeIds';
      }
    }
    if (this.audienceOptions?.departments?.length) {
      const isInEntity = this.audienceOptions.departments.find((selectOption: ISelectOption) => {
        return selectOption.value === id;
      });
      if (isInEntity?.value) {
        return 'departmentIds';
      }
    }
    if (this.audienceOptions?.areas?.length) {
      const isInEntity = this.audienceOptions.areas.find((selectOption: ISelectOption) => {
        return selectOption.value === id;
      });
      if (isInEntity?.value) {
        return 'areaIds';
      }
    }
    if (this.audienceOptions?.teams?.length) {
      const isInEntity = this.audienceOptions.teams.find((selectOption: ISelectOption) => {
        return selectOption.value === id;
      });
      if (isInEntity?.value) {
        return 'teamIds';
      }
    }
    if (this.audienceOptions?.companies?.length) {
      const isInEntity = this.audienceOptions.companies.find((selectOption: ISelectOption) => {
        return selectOption.value === id;
      });
      if (isInEntity?.value) {
        return 'companyIds';
      }
    }
    return null;
  }

  searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      const userPersonals = this.usersSelected.map((iUserId) => {
        return this.audienceOptions.mapUserIdToUserPersonal[iUserId];
      });
      return Promise.resolve(userPersonals);
    }
    const results = _.chain(this.allUsers)
      .filter((userAccount: any) => {
        const userPersonal = this.audienceOptions.mapUserIdToUserPersonal[userAccount._id];

        if (check.not.assigned(userPersonal)) {
          return false;
        }

        const regExp = new RegExp(`^.*${value}.*$`, 'i');
        return regExp.test(userPersonal.displayName);
      })
      .forEach((iResult) => {
        iResult.displayName = check.assigned(this.audienceOptions.mapUserIdToUserPersonal[iResult._id])
          ? this.audienceOptions.mapUserIdToUserPersonal[iResult._id].displayName
          : '';
      })
      .orderBy(['displayName'], ['asc'])
      .value();

    return Promise.resolve(
      results.map((iResult) => {
        return this.audienceOptions.mapUserIdToUserPersonal[iResult._id];
      })
    );
  };

  assignUser(userId: string): void {
    if (
      check.not.assigned(userId) ||
      check.emptyString(userId) ||
      check.not.assigned(this.audienceOptions.mapUserIdToUserPersonal[userId])
    ) {
      return;
    }
    this.usersSelected.push(userId);

    _.remove(
      this.allUsers,
      _.iteratee({
        _id: userId,
      })
    );

    this.audienceVisibility.userIds = this.usersSelected;
    this.searchComponent.clearSearch();
    this.onChange.emit(this.audienceVisibility);
  }

  removeUser(userId: string): Function {
    return () => {
      if (check.not.assigned(userId) || check.emptyString(userId)) {
        return;
      }
      _.remove(this.usersSelected, (element) => {
        return element === userId;
      });

      this.audienceVisibility.userIds = this.usersSelected;

      this.allUsers.push(this.audienceOptions.mapUserIdToUserPersonal[userId]);
      this.searchComponent.clearSearch();
      this.searchComponent.refreshSearchResults();
      this.onChange.emit(this.audienceVisibility);
    };
  }
}
