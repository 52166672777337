import { Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DocumentService } from '@app/standard/services/document/document.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-edit-document-import-user.dialog',
  templateUrl: 'edit-document-import-user.dialog.html',
  styleUrls: ['edit-document-import-user.dialog.scss'],
})
export class EditDocumentImportUserDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};
  assignedUser: Array<any> = [];
  private allUserPersonal: Array<IUserPersonalModel> = [];
  searchResults: Array<any> = [];

  @ViewChild(SearchComponent, { static: true }) searchComponent: SearchComponent;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditDocumentImportUserDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-import-user-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
        return this.getUserList();
      })
      .then((allUserPersonal) => {
        this.allUserPersonal = allUserPersonal;
        if (check.assigned(this.dialogInfo) && check.nonEmptyObject(this.dialogInfo)) {
          const selectedUser = this.allUserPersonal?.find((iUser) => {
            return iUser._id === this.dialogInfo.userId;
          });

          if (check.assigned(selectedUser)) {
            this.selectUser(selectedUser);
          }
        }
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  private async getUserList(): Promise<Array<IUserPersonalModel>> {
    const docPermissions: any = await this.injector.get(DocumentService).getPermissions();
    if (docPermissions.create_all === true) {
      return this.injector.get(UserPersonalService).getAllUserPersonal(false, false);
    } else if (check.nonEmptyArray(docPermissions.create_custom)) {
      return this.getVisibleUsers(docPermissions.create_custom);
    }
  }

  private async getVisibleUsers(customPermissionConditions: Array<any>): Promise<Array<IUserPersonalModel>> {
    let userIds = [];
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const userRequest = {
      userInfo: {
        _id: loggedUser._id,
      },
    };
    const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
    const relatedUserQueries = customPermissionConditions.map((block) => {
      return customPermissions.getQueryFromBlockAndConditions(
        userRequest,
        null,
        'document',
        block.blockAndConditions,
        null,
        null,
        allUserWork
      );
    });
    const blockAndConditions = await Promise.all(relatedUserQueries);
    blockAndConditions.forEach((block) => {
      if (check.assigned(block['relatedTo.idRelatedTo']) && check.nonEmptyArray(block['relatedTo.idRelatedTo'].$in)) {
        userIds = userIds.concat(block['relatedTo.idRelatedTo'].$in);
      }
    });
    const findUsers = {
      _id: { $in: userIds },
    };
    return await this.injector.get(UserPersonalService).find(findUsers);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  updateDocumentsUser(): void {
    if (check.not.assigned(this.assignedUser) || check.emptyArray(this.assignedUser) || check.not.assigned(this.assignedUser[0]._id)) {
      return;
    }
    this.dialogRef.close(this.assignedUser[0]._id);
  }

  searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      return Promise.resolve(this.assignedUser);
    }
    const results = _.chain(this.allUserPersonal)
      .filter((iUserPersonal: any) => {
        const regExp = new RegExp(`^.*${value}.*$`, 'i');
        return regExp.test(iUserPersonal.displayName);
      })
      .orderBy(['displayName'], ['asc'])
      .value();
    return Promise.resolve(results);
  };

  selectUser(user: any): void {
    this.assignedUser = [user];
    this.searchResults = [user];
    if (check.assigned(this.searchComponent)) {
      this.searchComponent.refreshSearchResults();
      this.searchComponent.clearSearch();
    }
  }
}
