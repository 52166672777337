import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class CandidateTagService {
  private CANDIDATE_TAG_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/candidate-tag-db`;
  private CANDIDATE_TAG_PERMISSIONS_KEY: string = 'candidate-tag';
  private CANDIDATE_TAG_INTERNATIONALIZATION: string = 'candidate-tag-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<ICandidateTagModel> {
    return new Promise<ICandidateTagModel>((resolve, reject) => {
      this.genericService
        .create(this.CANDIDATE_TAG_URL, data)
        .then((candidateTag: ICandidateTagModel) => {
          resolve(candidateTag);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateTagService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<ICandidateTagModel> {
    return new Promise<ICandidateTagModel>((resolve, reject) => {
      this.genericService
        .getById(this.CANDIDATE_TAG_URL, id)
        .then((candidateTag: ICandidateTagModel) => {
          resolve(candidateTag);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateTagService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.CANDIDATE_TAG_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateTagService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.CANDIDATE_TAG_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateTagService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.CANDIDATE_TAG_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.CANDIDATE_TAG_INTERNATIONALIZATION);
  }

  getCandidateTags(): Promise<Array<ICandidateTagModel>> {
    return new Promise<Array<ICandidateTagModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, CandidateTagService.name, 'getCandidateTags');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get<Array<ICandidateTagModel>>(`${this.CANDIDATE_TAG_URL}/all`, httpOptions)
        .toPromise()
        .then((candidateTagsResponse: Array<ICandidateTagModel>) => {
          resolve(candidateTagsResponse);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CandidateTagService.name, 'getCandidateTags'));
        });
    });
  }
}

export interface ICandidateTagModel {
  _id?: string;
  name?: string;
  color?: string;
  ownerId?: string;
}
