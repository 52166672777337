import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { InAppInviteDialog } from '@app/common-dialogs/in-app-invite/in-app-invite-dialog/in-app-invite.dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InAppInviteService } from '@app/standard/services/in-app-invite/in-app-invite.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

import { I18nDataPipe } from '../../../components/i18n-data/i18n-data.pipe';
import { SearchComponent, SearchFunction } from '../../../components/search/search.component';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { UserAccountService } from '../../../services/user/user-account.service';

@Component({
  selector: 'orgos-profile-assignment',
  templateUrl: 'profile-assignment.component.html',
  styleUrls: ['profile-assignment.component.scss'],
})
export class ProfileAssignmentComponent implements OnInit {
  i18n: any = {};
  tabSelected: number = 0;
  userAccountsWithThisProfile: Array<any>;
  userAccountsWithoutThisProfile: Array<any>;
  searchResults: Array<any> = [];
  titleMessage: string;
  subtitleMessage: string;
  loggedUserId: string;
  inAppInviteService: InAppInviteService;

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  @Output() profileUpdated: EventEmitter<any> = new EventEmitter<any>();

  private _allUserAccount: Array<any>;
  @Input()
  set allUserAccount(values: Array<any>) {
    this._allUserAccount = values;
  }
  get allUserAccount(): Array<any> {
    return this._allUserAccount;
  }

  @Input() userIdToUserPersonal: any;

  private _profile: any;
  @Input()
  set profile(profileVal: any) {
    this._profile = profileVal;
    this.initData();
  }
  get profile(): any {
    return this._profile;
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.loggedUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((i18n) => {
        this.i18n = i18n;
        this.initData();
      })
      .catch(() => {
        this.i18n = {};
      });
    this.inAppInviteService = this.injector.get(InAppInviteService);
  }

  private initData(): void {
    this.findUserAccountsWithThisProfile();
    this.transformTranslations();
    if (check.assigned(this.searchComponent)) {
      this.searchComponent.refreshSearchResults();
    }
  }

  private transformTranslations(): void {
    const data = {
      profileName: this.profile.name,
    };
    if (check.assigned(this.i18n) && check.nonEmptyObject(this.i18n)) {
      this.titleMessage = this.injector.get(I18nDataPipe).transform(this.i18n.peopleAssignedToThisProfileTitle, data);
      this.subtitleMessage = this.injector.get(I18nDataPipe).transform(this.i18n.peopleAssignedToThisProfileSubtitle, data);
    } else {
      this.injector
        .get(InternationalizationService)
        .getAllTranslation('settings-roles-and-permissions-page')
        .then((i18n) => {
          this.i18n = i18n;
          this.titleMessage = this.injector.get(I18nDataPipe).transform(this.i18n.peopleAssignedToThisProfileTitle, data);
          this.subtitleMessage = this.injector.get(I18nDataPipe).transform(this.i18n.peopleAssignedToThisProfileSubtitle, data);
        })
        .catch(() => {
          this.i18n = {};
        });
    }
  }

  private findUserAccountsWithThisProfile(): void {
    this.userAccountsWithThisProfile = [];

    if (check.not.assigned(this.allUserAccount) || check.emptyArray(this.allUserAccount) || check.not.assigned(this.userIdToUserPersonal)) {
      this.findUserAccountsWithoutThisProfile();
      return;
    }

    if (check.not.assigned(this.profile) || check.not.assigned(this.profile[fieldConstants.ID])) {
      this.findUserAccountsWithoutThisProfile();
      return;
    }

    this.userAccountsWithThisProfile = this.allUserAccount
      .filter((iUserAccount) => {
        return iUserAccount.profileKey === this.profile[fieldConstants.ID];
      })
      .sort((userAccountA: any, userAccountB: any) => {
        const nameA = this.userIdToUserPersonal[userAccountA[fieldConstants.ID]].displayName;
        const nameB = this.userIdToUserPersonal[userAccountB[fieldConstants.ID]].displayName;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });

    this.findUserAccountsWithoutThisProfile();
  }

  private findUserAccountsWithoutThisProfile(): void {
    this.userAccountsWithoutThisProfile = [];
    if (check.not.assigned(this.allUserAccount) || check.emptyArray(this.allUserAccount)) {
      return;
    }

    this.userAccountsWithoutThisProfile = this.allUserAccount.filter((iUserAccount) => {
      return iUserAccount.profileKey !== this.profile[fieldConstants.ID];
    });
  }

  public saveProfile(): void {
    this.profileUpdated.emit(this.profile);
  }

  public searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      const userPersonals = this.userAccountsWithThisProfile.map(
        (iUserAccount) => this.userIdToUserPersonal[iUserAccount[fieldConstants.ID]]
      );
      return Promise.resolve(userPersonals);
    }

    const results = _.chain(this.userAccountsWithoutThisProfile)
      .filter((userAccount: any) => {
        if (userAccount._id === this.loggedUserId) {
          return;
        }

        const userPersonal = this.userIdToUserPersonal[userAccount[fieldConstants.ID]];

        if (check.not.assigned(userPersonal)) {
          return false;
        }

        const regExp = new RegExp(`^.*${value}.*$`, 'i');
        return regExp.test(userPersonal.displayName);
      })
      .forEach((iResult) => {
        iResult.displayName = check.assigned(this.userIdToUserPersonal[iResult._id])
          ? this.userIdToUserPersonal[iResult._id].displayName
          : '';
      })
      .orderBy(['displayName'], ['asc'])
      .value();

    return Promise.resolve(
      results.map((iResult) => {
        return this.userIdToUserPersonal[iResult[fieldConstants.ID]];
      })
    );
  };

  public addUserToSelectedProfile(userId: string): void {
    if (userId === this.loggedUserId) {
      return;
    }

    const foundUserAccount = this.allUserAccount.find((userAccount) => {
      return userId === userAccount._id;
    });

    if (check.assigned(foundUserAccount)) {
      const oldProfileKey = foundUserAccount.profileKey;
      foundUserAccount.profileKey = this.profile[fieldConstants.ID];
      this.findUserAccountsWithThisProfile();
      this.searchComponent.clearSearch();
      this.injector
        .get(UserAccountService)
        .updateById(foundUserAccount[fieldConstants.ID], { profileKey: foundUserAccount.profileKey })
        .then(() => {
          this.saveProfile();
          const data = {
            profileName: this.profile.name,
            userName: this.userIdToUserPersonal[userId].displayName,
          };
          if (this.profile._id === 'admin') {
            this.injector.get(PrivateAmplitudeService).logEvent('single admin invited in-app', { platform: 'Web', category: 'PQL' });
            this.injector.get(PrivateIntegrationsService).trackChameleonEvent('single admin invited in-app');
            this.injector.get(PrivateChurnzeroService).logSimpleEvent('ADMIN_USER_ADDED');
          }
          const message = this.injector.get(I18nDataPipe).transform(this.i18n.userAddedToProfileSnackbar, data);
          this.injector.get(MatLegacySnackBar).open(message, 'OK', { duration: 5000 });
        })
        .catch(() => {
          // An error is already shown
          foundUserAccount.profileKey = oldProfileKey;
          this.searchComponent.refreshSearchResults();
        });
    }
  }

  getFunctionDeleteUserFromSelectedProfile(userId: string): Function {
    return () => {
      if (userId === this.loggedUserId) {
        return;
      }
      const foundUserAccount = this.allUserAccount.find((userAccount: any) => {
        return userId === userAccount[fieldConstants.ID];
      });

      if (check.assigned(foundUserAccount)) {
        const oldProfileKey = foundUserAccount.profileKey;
        foundUserAccount.profileKey = 'employee';
        this.findUserAccountsWithThisProfile();
        this.searchComponent.refreshSearchResults();
        this.injector
          .get(UserAccountService)
          .updateById(foundUserAccount[fieldConstants.ID], { profileKey: foundUserAccount.profileKey, isActive: foundUserAccount.isActive })
          .then(() => {
            this.saveProfile();
            const data = {
              profileName: this.profile.name,
              userName: this.userIdToUserPersonal[userId].displayName,
            };
            const message = this.injector.get(I18nDataPipe).transform(this.i18n.userRemovedFromProfileSnackbar, data);
            this.injector.get(MatLegacySnackBar).open(message, 'OK', { duration: 5000 });
          })
          .catch(() => {
            // An error is already shown
            foundUserAccount.profileKey = oldProfileKey;
            this.searchComponent.refreshSearchResults();
          });
      }
    };
  }

  displayInviteDialog(): void {
    this.injector.get(MatLegacyDialog).open(InAppInviteDialog);
    this.injector.get(PrivateAmplitudeService).logEvent('Invite intent from permissions', { category: 'PLG', subcategory1: 'Invitations' });
  }
}
