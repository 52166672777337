import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class JobadpartnerService {
  private JOBADPARTNER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/jobadpartner-db`;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  getSalesProducts(companyId: string, filters?: Array<object>): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.JOBADPARTNER_URL}/sales-products/${companyId}`, httpOptions)
        .toPromise()
        .then((salesProducts) => {
          resolve(salesProducts);
        })
        .catch((error) => {
          if (error.status === 409 && check.nonEmptyString(error.error.companyName) && check.nonEmptyArray(error.error.fieldsMissing)) {
            reject(this.errorManager.handleRawErrorSilently(error, JobadpartnerService.name, 'getSalesProducts'));
          } else {
            reject(this.errorManager.handleRawError(error, JobadpartnerService.name, 'getSalesProducts'));
          }
        });
    });
  }

  getSalesProductsPackage(companyId: string, salesProductIds: Array<string>): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      let productIdParams = '';
      salesProductIds.forEach((iProductId, index) => {
        const concatChar = index === 0 ? '?ids=' : '&ids=';
        productIdParams = `${productIdParams}${concatChar}${iProductId}`;
      });
      this.http
        .get(`${this.JOBADPARTNER_URL}/sales-products-packages/${companyId}${productIdParams}`, httpOptions)
        .toPromise()
        .then((salesProductsPackage) => {
          resolve(salesProductsPackage);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, JobadpartnerService.name, 'getSalesProductPackages'));
        });
    });
  }

  getOrdersForPositionId(companyId: string, positionId: string): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.JOBADPARTNER_URL}/orders/position/${companyId}/${positionId}`, httpOptions)
        .toPromise()
        .then((ordersForPosition: Array<any>) => {
          resolve(ordersForPosition);
        })
        .catch((error) => {
          if (error.status === 409) {
            reject(error);
          } else {
            reject(this.errorManager.handleRawError(error, JobadpartnerService.name, 'getOrdersForPositionId'));
          }
        });
    });
  }

  createOrderForPositionId(companyId: string, positionId: string, orderBody: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.JOBADPARTNER_URL}/order/${companyId}/${positionId}`, orderBody, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, JobadpartnerService.name, 'createOrderForPositionId'));
        });
    });
  }

  getCustomer(companyId: string): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.JOBADPARTNER_URL}/customer/${companyId}`, httpOptions)
        .toPromise()
        .then((customer) => {
          resolve(customer['body']);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, JobadpartnerService.name, 'getCustomer'));
        });
    });
  }

  updateCustomer(companyId: string, customerBody: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .put(`${this.JOBADPARTNER_URL}/customer`, customerBody, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, JobadpartnerService.name, 'updateCustomer'));
        });
    });
  }
}
