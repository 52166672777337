<div class="rrdc-dialog kenjo-p-60px">
  <orgos-column-container centerColumns="true" class="kenjo-mb-30px">
    <orgos-column size="1" class="kenjo-font-size-20px">{{dialogTranslation.emailComposerTitle}}</orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" class="kenjo-ml-40px kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()">clear </kenjo-icon>
  </orgos-column-container>

  <orgos-container aspect="card" class="kenjo-p-40px">
    <orgos-email-composer *ngIf="!multiplePositionCandidates" [candidate]="candidate" [position]="position" [positionCandidate]="positionCandidate" [isDialog]="true" (emailSent)="closeDialog({emailSent: true})" (emailScheduled)="closeDialog({emailScheduled: true})"></orgos-email-composer>
    <orgos-mass-email-composer *ngIf="multiplePositionCandidates && multiplePositionCandidates.length > 0" [listPositionCandidates]="multiplePositionCandidates" (emailSent)="closeDialog({emailSent: true})" (emailScheduled)="closeDialog({emailScheduled: true})"></orgos-mass-email-composer>
  </orgos-container>
</div>
