<div *ngIf="attendanceVisibility.appStatus">
  <div
    *ngIf="
      parentTranslation &&
      !attendanceVisibility.notViewPermission &&
      attendanceVisibility.showAttendance
    "
  >
    <div class="kenjo-font-weight-bold kenjo-mb-10px">{{ startDay | formatDate: 'MMMM YYYY' }}</div>
    <orgos-column-container class="kenjo-mb-10px">
      <orgos-column> {{ parentTranslation.attendanceTracked }} </orgos-column>
      <orgos-column *ngIf="myAttendance" size="0">{{
        myAttendance.trackedTime | duration
      }}</orgos-column>
      <orgos-column
        *ngIf="!myAttendance"
        size="0"
        [class.sab-overtime]="attendanceVisibility.hasOvertime"
        >{{ parentTranslation.inactive }}</orgos-column
      >
    </orgos-column-container>
    <orgos-column-container class="kenjo-mb-10px">
      <orgos-column>
        {{
          isCurrentMonth
            ? parentTranslation.currentOvertimeBalance
            : parentTranslation.endOvertimeBalance
        }}
      </orgos-column>
      <orgos-column
        *ngIf="myAttendance"
        size="0"
        [class.sab-overtime]="myAttendance.totalOvertime > 0"
      >
        <div *ngIf="attendanceVisibility.overtimeTracking">{{ myAttendance?.totalOvertime | duration }}</div>
        <div *ngIf="!attendanceVisibility.overtimeTracking">{{ parentTranslation.inactive }}</div>
      </orgos-column>
      <orgos-column
        *ngIf="!myAttendance"
        size="0"
        [class.sab-overtime]="attendanceVisibility.hasOvertime"
        >{{ parentTranslation.inactive }}</orgos-column
      >
    </orgos-column-container>
    <orgos-column-container *ngIf="myAttendance">
      <orgos-column></orgos-column>
      <div orgosColumn="0" class="kenjo-font-color-primary-5993e3 kenjo-font-size-14px">
        <orgos-action [uppercase]="false" (click)="openAttendanceTab()">
          {{ parentTranslation.attendance }}
          <kenjo-icon [size]="14" class="kenjo-font-color-primary-5993e3">launch</kenjo-icon>
        </orgos-action>
      </div>
    </orgos-column-container>
  </div>
  <div
    *ngIf="
      parentTranslation && attendanceVisibility.notViewPermission && attendanceVisibility.tracking
    "
  >
    <div class="kenjo-text-align-center kenjo-font-color-light-text-757575 kenjo-mb-10px">
      <kenjo-icon [size]="40">lock_person</kenjo-icon>
    </div>
    <div class="kenjo-font-size-14px kenjo-text-align-center sab-no-permission-text">
      <span class="kenjo-font-weight-bold">{{ parentTranslation.notVisibilityPermission }}</span>
      {{ parentTranslation.contactYourAdmin }}
    </div>
  </div>
</div>

<div *ngIf="parentTranslation && !attendanceVisibility.appStatus">
  <div class="kenjo-text-align-center kenjo-font-color-light-text-757575 kenjo-mb-10px">
    <kenjo-icon [size]="40">attendance_menu</kenjo-icon>
  </div>
  <div class="kenjo-font-size-14px kenjo-text-align-center sab-no-permission-text">
    <div *ngIf="!attendanceVisibility.showAttendance">
      <span class="kenjo-font-weight-bold">{{ parentTranslation.attendanceInactive }}</span>
    </div>
    <div *ngIf="attendanceVisibility.showAttendance">
      <span class="kenjo-font-weight-bold">{{ parentTranslation.attendanceDisabled }}</span>
      {{ parentTranslation.attendanceDisabledEnable }}
    </div>
  </div>
</div>
