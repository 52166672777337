import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IUserWorkScheduleModel, UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import * as check from 'check-types';
import * as moment from 'moment';

import { GenericCacheModel } from '../../../../core/generic-cache-model';
import { InputValidation } from '../../../../core/validation/input-validation';
import { CompanyService } from '../../../../services/company/company.service';
import { InternationalizationService } from '../../../../services/core/internationalization.service';
import { UserSalaryService } from '../../../../services/user/user-salary.service';

@Component({
  selector: 'orgos-edit-salary-dialog',
  templateUrl: 'edit-salary.dialog.html',
  styleUrls: ['edit-salary.dialog.scss'],
})
export class EditSalaryDialog implements OnInit {
  dialogTranslation: any = {};
  addSalaryTranslation: any = {};
  miscTranslation: any = {};
  currentWorkSchedule: IUserWorkScheduleModel;
  surchargeHoursEditedManually: boolean = false;
  private workingHoursPerWeek: number = 0;

  salary: GenericCacheModel;

  private _currentAmount: any;
  set currentAmount(currentAmount: any) {
    this._currentAmount = currentAmount;
    this.cdr.detectChanges();
  }
  get currentAmount(): any {
    return this._currentAmount;
  }

  private _currentPartTime: any;
  set currentPartTime(currentPartTime: any) {
    this._currentPartTime = currentPartTime;
    this.cdr.detectChanges();
  }
  get currentPartTime(): any {
    return this._currentPartTime;
  }
  minDate: Date;
  minStartDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  effectiveDateValidation: InputValidation;
  endDateValidation: InputValidation;
  payPeriodValidation: InputValidation;
  amountValidation: InputValidation;
  currencyValidation: InputValidation;
  hourlyRateValidation: InputValidation;
  partTimeValidation: InputValidation = new InputValidation();
  companyOptions: Array<any> = [];
  canEditPast: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditSalaryDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public rawSalary: any,
    private decimalPipe: DecimalPipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.canEditPast = this.rawSalary.canEditPast;
    this.loadDateLimits();

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-edit-salary-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-add-salary-dialog')
      .then((addSalaryTranslation) => {
        this.addSalaryTranslation = addSalaryTranslation;
      })
      .catch(() => {
        this.addSalaryTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((dialogTranslation) => {
        this.miscTranslation = dialogTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((allCompanies) => {
        this.companyOptions = allCompanies.map((iCompany: any) => {
          const companyOption = {
            name: iCompany.name,
            value: iCompany._id,
          };
          return companyOption;
        });
      })
      .catch(() => {
        this.companyOptions = [];
      });

    this.salary = new GenericCacheModel(this.injector, this.rawSalary.record, UserSalaryService, '');
    this.cdr.detectChanges();

    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    await this.initWorkingHoursPerWeek();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getFTESalary(): string {
    if (
      check.not.assigned(this.currentAmount) ||
      check.not.number(this.currentAmount) ||
      check.not.assigned(this.currentPartTime) ||
      check.not.number(this.currentPartTime)
    ) {
      return '-';
    }

    const fteSalary = (this.currentAmount * 100) / this.currentPartTime;
    if (check.not.number(fteSalary)) {
      return '-';
    }

    return this.decimalPipe.transform(fteSalary, '1.0-2');
  }

  loadDateLimits(): void {
    this.minStartDate = check.assigned(this.rawSalary.dateLimits.minStartDate)
      ? moment.utc(this.rawSalary.dateLimits.minStartDate).add(1, 'day').toDate()
      : undefined;
    this.maxStartDate = check.assigned(this.rawSalary.dateLimits.maxStartDate)
      ? moment.utc(this.rawSalary.dateLimits.maxStartDate).subtract(1, 'day').toDate()
      : undefined;
    this.maxEndDate = this.rawSalary.dateLimits.maxEndDate;
  }

  updateSalary(): void {
    if (
      check.not.assigned(this.effectiveDateValidation) ||
      this.effectiveDateValidation.hasErrors() ||
      check.not.assigned(this.payPeriodValidation) ||
      this.payPeriodValidation.hasErrors() ||
      check.not.assigned(this.amountValidation) ||
      this.amountValidation.hasErrors() ||
      check.not.assigned(this.currencyValidation) ||
      this.currencyValidation.hasErrors() ||
      this.hourlyRateValidation?.hasErrors()
    ) {
      return;
    }

    if (this.salary.data.payPeriod.toLowerCase() === 'hourly') {
      this.salary.data.surchargeHourlyRate = this.salary.data.amount;
    }

    this.dialogRef.close(this.salary.data);
  }

  surchargeHoursEdited(): void {
    this.surchargeHoursEditedManually = true;
  }

  recalculateSuggestedSurchargeHourlyRate(removeManualEdition: boolean = false): void {
    if (removeManualEdition) {
      this.surchargeHoursEditedManually = false;
    }
    if (this.surchargeHoursEditedManually) {
      return;
    }
    this.salary.data.surchargeHourlyRate = this.injector
      .get(UserSalaryService)
      .calculateSurchargeHourlyRate(this.salary.data, this.workingHoursPerWeek);
  }

  private async initWorkingHoursPerWeek(): Promise<void> {
    const userId = this.salary.data.ownerId;
    this.workingHoursPerWeek = await this.injector.get(UserWorkScheduleService).getWorkingHoursPerWeekForUserId(userId);
  }
}
