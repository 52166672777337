import { Injectable, Injector } from "@angular/core";
import { environment } from "@env";
import * as moment from "moment";
import { Moment } from "moment";
import { InternationalizationService } from "../core/internationalization.service";
import { GenericService } from "../generic.service";
import { IPayrollSettingModel } from "./payroll-settings.service";
import { PAYROLL_COMMENT_EMPLOYEE, PAYROLL_COMMENT_TIMEOFF, PAYROLL_COMMENT_VARIABLE } from '@carlos-orgos/orgos-utils/constants/picklist.constants';

const EMPTY_COMMENT = { changed: false, type: 'comment', collection: 'payroll-comment', field: 'comment', value: undefined };

@Injectable({
  providedIn: 'root'
})
export class PayrollCommentService {
  private PAYROLL_COMMENT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/payroll-comment-db`;
  private commentColumnHeaderTranslation: string;
  constructor(private genericService: GenericService, private injector: Injector) {}

  async injectCommentColumns(payrollData: IPayrollData, selectedPayrollSettings: IPayrollSettingModel, variablePaymentColumns: Array<string>, timeOffColumns: Array<string>, isPayrollConfirmed: boolean) {
    if (selectedPayrollSettings.commentColumnEnabled === true) {
      await this.addCommentColumns(payrollData, variablePaymentColumns, timeOffColumns, isPayrollConfirmed);
    } else {
      this.removeCommentColumns(payrollData, variablePaymentColumns, timeOffColumns);
    }
  }

  private async addCommentColumns(payrollData: IPayrollData, variablePaymentColumns: Array<string>, timeOffColumns: Array<string>, isPayrollConfirmed: boolean) {
    if (!this.commentColumnHeaderTranslation) {
      this.commentColumnHeaderTranslation = await this.injector.get(InternationalizationService).getTranslation('payroll-page', 'commentColumn');
    }
    payrollData.employeeDataColumnsTranslationKeys['payroll-comment.comment'] = this.commentColumnHeaderTranslation;
    this.addColumnByName(variablePaymentColumns, 'comment');
    this.addColumnByName(timeOffColumns, 'comment');

    const commentsAreIncludedInEmployee = payrollData.employeeData.some(iEmployeeData => iEmployeeData.data['payroll-comment.comment'] !== undefined);

    // Edge case: Comments have been enabled after the payroll was closed.
    if (isPayrollConfirmed && !commentsAreIncludedInEmployee) {
      return;
    }
    this.addColumnByName(payrollData.employeeDataColumns, 'payroll-comment.comment');
  }

  private removeCommentColumns(payrollData: IPayrollData, variablePaymentColumns: Array<string>, timeOffColumns: Array<string>) {
    this.removeColumnByName(payrollData.employeeDataColumns, 'payroll-comment.comment');
    this.removeColumnByName(variablePaymentColumns, 'comment');
    this.removeColumnByName(timeOffColumns, 'comment');
  }

  private removeColumnByName(columnsArray: Array<string>, columnName: string) {
    const indexOfColumn = columnsArray.indexOf(columnName);
    if (indexOfColumn !== -1) {
      columnsArray.splice(indexOfColumn, 1);
    }
  }

  private addColumnByName(columnsArray: Array<string>, columnName: string) {
    if (!columnsArray.includes(columnName)) {
      columnsArray.push(columnName);
    }
  }

  async injectCommentData(payrollData: IPayrollData, fromDate: Moment, payrollConfirmed: boolean, payrollSettingsId: string) {
    let allComments: Array<IPayrollComment> = [];
    try {
      allComments = payrollConfirmed ? [] : await this.fetchAllComments(fromDate, payrollSettingsId);
    } catch {
      // Do nothing
    }
    const indexedComments = this.indexComments(allComments);
    payrollData.employeeData.forEach(iEmployeeData => {
      const comment = { ...EMPTY_COMMENT };
      const commentId = { ...EMPTY_COMMENT };
      let matchedComment = this.matchEmployeeComments(fromDate, iEmployeeData, indexedComments);
      if (matchedComment) {
        comment.value = matchedComment.comment;
        commentId.value = matchedComment._id;
      }
      iEmployeeData.data['payroll-comment.comment'] = comment;
      iEmployeeData.data['payroll-comment.commentId'] = commentId;
    });

    payrollData.variablePayments.forEach(iVariablePayment => {
      const matchedComment = this.matchVariableComments(fromDate, iVariablePayment, indexedComments);
      iVariablePayment.comment = matchedComment?.comment;
      iVariablePayment.commentId = matchedComment?._id;
    });

    payrollData.timeOff?.timeOffRecords?.forEach(iTimeOff => {
      const matchedComment = this.matchTimeOffComments(fromDate, iTimeOff, indexedComments);
      iTimeOff.comment = matchedComment?.comment;
      iTimeOff.commentId = matchedComment?._id;
    })
  }

  private indexComments(comments: Array<IPayrollComment>) {
    const result: {[key: string]: IPayrollComment} = {};
    comments.forEach(iComment => {
      let index;
      if (iComment.commentType === PAYROLL_COMMENT_EMPLOYEE) {
        index = `${iComment.periodFrom}-${iComment._userId}-${iComment.currency}-${iComment.payingCompanyId}`;
      } else if (iComment.commentType === PAYROLL_COMMENT_VARIABLE) {
        index = `${iComment.periodFrom}-${iComment._userId}-${iComment.variableTypeId}-${iComment.variableDate}`;
      } else if (iComment.commentType === PAYROLL_COMMENT_TIMEOFF) {
        index = `${iComment.periodFrom}-${iComment._userId}-${iComment.timeOffTypeId}-${iComment.requestFrom}`;
      }
      result[index] = iComment;
    });
    return result;
  }

  private matchEmployeeComments(periodFrom: moment.Moment, employeeData: IPayrollEmployeeData, comments: {[key: string]: IPayrollComment}) {
    const index = `${periodFrom.toISOString()}-${employeeData.userId}-${employeeData.currency}-${employeeData.payingCompanyId}`;
    const matchedComment = comments[index];
    return matchedComment;
  }

  private matchVariableComments(periodFrom: moment.Moment, variablePayment: IPayrollVariablePaymentData, comments: {[key: string]: IPayrollComment}) {
    const index = `${periodFrom.toISOString()}-${variablePayment.userId}-${variablePayment.typeId}-${variablePayment.date}`;
    const matchedComment = comments[index];
    return matchedComment;
  }

  private matchTimeOffComments(periodFrom: moment.Moment, timeOff: IPayrollTimeOffData, comments: {[key: string]: IPayrollComment}) {
    const index = `${periodFrom.toISOString()}-${timeOff.userId}-${timeOff.timeOffTypeId}-${timeOff.from}`;
    const matchedComment = comments[index];
    return matchedComment;
  }

  private async fetchAllComments(fromDate: Moment, payrollSettingsId: string): Promise<Array<IPayrollComment>> {
    const query = {
      periodFrom: fromDate.toDate(),
      _payrollSettingsId: payrollSettingsId
    };
    const allComments = await this.genericService.find(`${this.PAYROLL_COMMENT_URL}`, query);
    return allComments;
  }

  async updateComment(commentId: string, newComment: string) {
    await this.genericService.updateById(this.PAYROLL_COMMENT_URL, commentId, { comment: newComment });
  }

  async createEmployeeComment(fromDate: Moment, employeeData: IPayrollEmployeeData, newComment: string, payrollSettingsId: string) {
    const commentBody = {
      periodFrom: fromDate.toDate(),
      _userId: employeeData.userId,
      commentType: PAYROLL_COMMENT_EMPLOYEE,
      comment: newComment,
      currency: employeeData.currency,
      payingCompanyId: employeeData.payingCompanyId,
      _payrollSettingsId: payrollSettingsId
    }

    await this.genericService.create(`${this.PAYROLL_COMMENT_URL}`, commentBody);
  }

  async createVariableComment(fromDate: Moment, variablePaymentData: IPayrollVariablePaymentData, newComment: string, payrollSettingsId: string) {
    const commentBody = {
      periodFrom: fromDate.toDate(),
      _userId: variablePaymentData.userId,
      commentType: PAYROLL_COMMENT_VARIABLE,
      comment: newComment,
      variableDate: variablePaymentData.date,
      variableTypeId: variablePaymentData.typeId,
      _payrollSettingsId: payrollSettingsId
    }
    await this.genericService.create(`${this.PAYROLL_COMMENT_URL}`, commentBody);
  }

  async createTimeOffComment(fromDate: Moment, timeOffData: IPayrollTimeOffData, newComment: string, payrollSettingsId: string) {
    const commentBody = {
      periodFrom: fromDate.toDate(),
      _userId: timeOffData.userId,
      commentType: PAYROLL_COMMENT_TIMEOFF,
      comment: newComment,
      timeOffTypeId: timeOffData.timeOffTypeId,
      requestFrom: timeOffData.from,
      _payrollSettingsId: payrollSettingsId
    }
    await this.genericService.create(`${this.PAYROLL_COMMENT_URL}`, commentBody);
  }

  async deleteComment(commentId: string) {
    await this.genericService.deleteById(`${this.PAYROLL_COMMENT_URL}`, commentId);
  }
}

interface IPayrollData {
  payrollGroupId: string,
  corrections: Array<any>;
  employeeDataColumns: Array<string>;
  employeeDataColumnsTranslationKeys: {[key: string]: string};
  employeeDocs: Array<any>;
  employeeSplitData: Array<any>;
  enableSplitByCalendarMonth: boolean;
  exports: Array<any>;
  numberOfEmployees: number;
  payrollGroupName: string;
  status: string;
  timeOff: {
    timeOffRecords: Array<IPayrollTimeOffData>
  };
  fromDate: Date;
  toDate: Date;
  variablePayments: Array<IPayrollVariablePaymentData>;
  employeeData: Array<IPayrollEmployeeData>;
}

export interface IPayrollEmployeeData {
  currency: IPayrollDataField;
  data: {[key: string]: IPayrollDataField};
  displayName: string;
  hasChanges: boolean;
  payingCompanyId: string;
  userId: string;
}

interface IPayrollComment {
  _id: string,
  periodFrom: Date,
  _userId: string,
  commentType: string,
  comment: string,

  currency?: string,
  payingCompanyId?: string,

  timeOffTypeId?: string,
  requestFrom?: Date,

  variableDate?: Date,
  variableTypeId?: string
}

interface IPayrollDataField {
  changed?: boolean,
  collection?: string,
  field?: string,
  type?: string,
  value?: string
}

export interface IPayrollVariablePaymentData {
  amount: number,
  currency: string,
  date: Date,
  displayName: string,
  firstName: string,
  lastName: string,
  photoUrl: string,
  type: string,
  typeId: string,
  userId: string,
  _id: string,
  comment?: string,
  commentId?: string
}

export interface IPayrollTimeOffData {
  attachments: Array<any>,
  displayName: string,
  firstName: string,
  from: Date,
  lastName: string,
  photoUrl: string,
  timeOffTypeId: string,
  timeOffTypeName: string,
  to: Date,
  userId: string,
  workingDays: number,
  _id: string,
  comment?: string,
  commentId?: string
}