<mat-form-field *ngIf="model && field && !readOnly">
  <mat-label>{{ label }}</mat-label>
  <textarea matInput [rows]="rows" [id]="id" [ngModel]="getValue()" (ngModelChange)="setValue($event)" [required]="required" [minlength]="minlength" [maxlength]="maxlength" [placeholder]="placeholder"></textarea>
</mat-form-field>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label>{{ label }}</mat-label>
  <textarea matInput [rows]="rows" [id]="id" [ngModel]="getValue()" readOnly disabled [required]="required"></textarea>
</mat-form-field>
<div *ngIf="!isValueValid" class="itac-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
