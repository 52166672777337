import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'kenjo-info-message',
  templateUrl: 'info-message.component.html',
  styleUrls: ['info-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoMessageComponent {
  @Input() messageText: string;
  @Input() contentProjection: boolean = false;

  private _messageType: 'warning' | 'error' | 'info' | 'primary' = 'warning';
  @Input()
  set messageType(messageTypeIn: 'warning' | 'error' | 'info' | 'primary') {
    this._messageType = messageTypeIn;

    this.warningAreaClass = false;
    this.errorAreaClass = false;
    this.infoAreaClass = false;
    this.primaryAreaClass = false;

    switch (messageTypeIn) {
      case 'warning': {
        this.warningAreaClass = true;
        break;
      }
      case 'error': {
        this.errorAreaClass = true;
        break;
      }
      case 'info': {
        this.infoAreaClass = true;
        break;
      }
      case 'primary': {
        this.primaryAreaClass = true;
        break;
      }
      default: {
        this.warningAreaClass = true;
        break;
      }
    }
  }
  get messageType(): 'warning' | 'error' | 'info' | 'primary' {
    return this._messageType;
  }

  @HostBinding('class.pm-warning-area') warningAreaClass: boolean = true;
  @HostBinding('class.pm-error-area') errorAreaClass: boolean = false;
  @HostBinding('class.pm-info-area') infoAreaClass: boolean = false;
  @HostBinding('class.pm-primary-area') primaryAreaClass: boolean = false;
}
