import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IUniversalModel } from '@app/models/universal.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserCompensationService } from './user-compensation.service';

@Injectable()
export class UserEmploymentContractTypeService implements IGenericService {
  private USER_EMPLOYMENT_CONTRACT_TYPE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-employment-contract-type-db`;
  private USER_EMPLOYMENT_CONTRACT_TYPE_INTERNATIONALIZATION: string = 'user-employment-contract-type-collection';

  private $userEmploymentContractTypes = new BehaviorSubject<Array<IUserEmploymentContractTypeModel>>([]);
  private $defaultUserEmploymentContractTypes = new BehaviorSubject<Array<IUserEmploymentContractTypeModel>>([]);
  private $customUserEmploymentContractTypes = new BehaviorSubject<Array<IUserEmploymentContractTypeModel>>([]);
  private $activeUserEmploymentContractTypes = new BehaviorSubject<Array<IUserEmploymentContractTypeModel>>([]);
  private $remainingActiveItems = new BehaviorSubject<number>(0);

  constructor(
    private injector: Injector,
    private genericService: GenericService,
    private errorManager: ErrorManagerService,
    private userCompensationService: UserCompensationService
  ) {}

  async create(data: object): Promise<IUserEmploymentContractTypeModel> {
    try {
      return await this.genericService.create(this.USER_EMPLOYMENT_CONTRACT_TYPE_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentContractTypeService.name, 'create');
    }
  }

  async getById(id: string): Promise<IUserEmploymentContractTypeModel> {
    try {
      return await this.genericService.getById(this.USER_EMPLOYMENT_CONTRACT_TYPE_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentContractTypeService.name, 'getById');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.USER_EMPLOYMENT_CONTRACT_TYPE_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentContractTypeService.name, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.USER_EMPLOYMENT_CONTRACT_TYPE_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentContractTypeService.name, 'deleteById');
    }
  }

  getPermissions(): Promise<object> {
    return this.userCompensationService.getPermissions();
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_EMPLOYMENT_CONTRACT_TYPE_INTERNATIONALIZATION);
  }

  /**
   * Fetches all default + custom user-employment-contract-type-db objects for this org, including their usage count
   * If this org has no custom contract types, the server will add the default ones the first time this list is loaded.
   * @returns observable with list (will never be empty)
   */
  loadAllUserEmploymentContractTypes(): void {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    this.injector
      .get(HttpClient)
      .get<Array<IUserEmploymentContractTypeModel>>(this.USER_EMPLOYMENT_CONTRACT_TYPE_URL, httpOptions)
      .subscribe(
        (contractTypes: Array<IUserEmploymentContractTypeModel>) => {
          this.setUserEmploymentContractTypes(contractTypes);
          this.setDefaultUserEmploymentContractTypes(
            contractTypes.filter((contractType: IUserEmploymentContractTypeModel) => contractType.isDefault)
          );
          this.setCustomUserEmploymentContractTypes(
            contractTypes.filter((contractType: IUserEmploymentContractTypeModel) => !contractType.isDefault)
          );
          this.calculateRemainingActiveItems();
          return;
        },
        (error: any) =>
          this.errorManager.handleRawError(error, UserEmploymentContractTypeService.name, 'loadAllUserEmploymentContractTypes')
      );
  }

  /**
   * Fetches ACTIVE default + custom user-employment-contract-type-db objects for this org, including their usage count
   * If this org has no custom contract types, the server will add the default ones the first time this list is loaded.
   * @returns observable with list (will never be empty)
   */
  loadActiveUserEmploymentContractTypes(): void {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    this.injector
      .get(HttpClient)
      .get<Array<IUserEmploymentContractTypeModel>>(`${this.USER_EMPLOYMENT_CONTRACT_TYPE_URL}/active`, httpOptions)
      .subscribe(
        (contractTypes: Array<IUserEmploymentContractTypeModel>) => {
          this.setActiveUserEmploymentContractTypes(contractTypes);
          return;
        },
        (error: any) =>
          this.errorManager.handleRawError(error, UserEmploymentContractTypeService.name, 'loadAllUserEmploymentContractTypes')
      );
  }

  get userEmploymentContractTypes(): Observable<Array<IUserEmploymentContractTypeModel>> {
    return this.$userEmploymentContractTypes.asObservable();
  }

  setUserEmploymentContractTypes(contractTypes: Array<IUserEmploymentContractTypeModel>): void {
    this.$userEmploymentContractTypes.next(contractTypes);
  }
  get defaultUserEmploymentContractTypes(): Observable<Array<IUserEmploymentContractTypeModel>> {
    return this.$defaultUserEmploymentContractTypes.asObservable();
  }
  setDefaultUserEmploymentContractTypes(contractTypes: Array<IUserEmploymentContractTypeModel>): void {
    this.$defaultUserEmploymentContractTypes.next(contractTypes);
  }

  get customUserEmploymentContractTypes(): Observable<Array<IUserEmploymentContractTypeModel>> {
    return this.$customUserEmploymentContractTypes.asObservable();
  }
  setCustomUserEmploymentContractTypes(contractTypes: Array<IUserEmploymentContractTypeModel>): void {
    this.$customUserEmploymentContractTypes.next(contractTypes);
  }

  get activeUserEmploymentContractTypes(): Observable<Array<IUserEmploymentContractTypeModel>> {
    return this.$activeUserEmploymentContractTypes.asObservable();
  }
  setActiveUserEmploymentContractTypes(contractTypes: Array<IUserEmploymentContractTypeModel>) {
    this.$activeUserEmploymentContractTypes.next(contractTypes);
  }

  get remainingActiveItems(): Observable<number> {
    return this.$remainingActiveItems;
  }

  async calculateRemainingActiveItems() {
    const contractTypes = await this.userEmploymentContractTypes.pipe(first()).toPromise();
    this.$remainingActiveItems.next(
      contractTypes.reduce((count: number, item: IUserEmploymentContractTypeModel) => count + (item.isActive ? 1 : 0), 0)
    );
  }
}

export interface IUserEmploymentContractTypeModel extends IUniversalModel {
  name: string;
  isDefault: boolean;
  isActive: boolean;
  usageCount?: number;
}
