import { Component, Input, OnInit } from '@angular/core';
import * as check from 'check-types';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-icon-picker',
  templateUrl: 'input-icon-picker.component.html',
  styleUrls: ['input-icon-picker.component.scss']
})
export class InputIconPickerComponent extends InputAbstractComponent implements OnInit {
  @Input() icons: Array<string> = [];

  selectedIconKey: string = '';

  ngOnInit(): void {
    const value = super.getValue();
    if (check.contains(this.icons, value)) {
      this.selectedIconKey = value;
    }

    super.ngOnInit();
  }

  setValue(newIcon: string): void {
    if (check.assigned(newIcon) && this.readOnly === false) {
      this.selectedIconKey = newIcon;
    }

    super.setValue(newIcon);
  }
}
