<orgos-row-container
  [ngClass]="{ 'npd-peers-dialog-box': !hasNominationsLimit, 'npd-peers-limited-dialog-box': hasNominationsLimit}"
  class="kenjo-border kenjo-border-radius"
>
  <orgos-row size="1">
    <orgos-column-container
      [ngClass]="{ 'kenjo-pb-20px': hasNominationsLimit, 'kenjo-pb-30px': !hasNominationsLimit, 'kenjo-border-bottom': hasNominationsLimit}"
      class="kenjo-ph-40px kenjo-pt-30px"
      centerColumns="true"
    >
      <orgos-column size="0" class="kenjo-mr-10px">
        <orgos-avatar [photoUrl]="nominationUser._photo?._url" [avatarName]="nominationUser.displayName" size="medium"> </orgos-avatar>
      </orgos-column>
      <orgos-column size="1">
        <div class="kenjo-font-color-text-333333 kenjo-font-size-20px">{{nominationUser.displayName}}</div>
        <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px">{{nominationUser.jobTitle}}</div>
      </orgos-column>
      <orgos-column size="0" class="kenjo-mr-10px">
        <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog(false)">
          clear</kenjo-icon
        >
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container centerColumns="true" *ngIf="!loading && hasNominationsLimit" class="kenjo-mh-40px kenjo-mt-20px kenjo-mb-30px">
      <orgos-column size="0">
        <kenjo-icon [size]="24" class="kenjo-mr-5px kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
      </orgos-column>
      <orgos-column class="kenjo-font-color-light-text-757575">
        {{ translation.nominationsLimitMessage | i18nData: { min: nominationsLimit.min, max: nominationsLimit.max } }}
      </orgos-column>
    </orgos-column-container>

    <div *ngIf="!loading" class="kenjo-ml-40px kenjo-mr-40px">
      <div class="kenjo-font-color-archived-8f8f8f kenjo-font-size-14px kenjo-text-align-left">{{translation.peersTitle}}</div>
      <div *ngIf="!readMode" class="kenjo-mt-10px kenjo-mb-20px">
        <orgos-search
          [disabled]="hasNominationsLimit && nominations.length === nominationsLimit.max"
          [tooltipMessage]="translation.maxNominationsTooltip"
          alternativeStyle="true"
          icon="search"
          [placeholder]="translation.searchForEmployeesLabel"
          [searchFunction]="searchUserFunction"
          (searchResultsChange)="searchResults = $event"
          class="kenjo-cursor-pointer"
        >
          <div class="orgos-search-error kenjo-font-color-danger-ff5757">{{ translation.noUserFound }}</div>
          <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="assignUser(result)">
            <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
          </orgos-search-option>
        </orgos-search>
      </div>
    </div>

    <div
      *ngIf="!loading && nominations && nominations.length > 0"
      class="kenjo-ml-40px kenjo-mr-40px kenjo-mt-20px kenjo-border kenjo-border-radius npd-nominations-table"
    >
      <orgos-table
        fullHeight
        [stickyRow]="false"
        [stickyColumn]="false"
        [data]="nominations"
        [manageColumns]="false"
        [totalColumns]="totalColumns"
        [selectable]="false"
      >
        <orgos-table-column columnKey="name" [header]="'Name'">
          <ng-container *orgosTableCell="let row">
            <orgos-column-container centerColumns="true" class="kenjo-mt-10px kenjo-mb-10px">
              <orgos-avatar
                orgosColumn="1"
                [avatarName]="row.user.displayName"
                [photoUrl]="row.user._photo?._url"
                size="small"
              ></orgos-avatar>
              <kenjo-truncate-text class="npd-relevant-field kenjo-ml-10px kenjo-font-size-14px kenjo-mr-60px" orgosColumn="0">
                {{row.user.displayName}}</kenjo-truncate-text
              >
            </orgos-column-container>
          </ng-container>
        </orgos-table-column>

        <orgos-table-column columnKey="jobTitle" [header]="'Job Title'">
          <ng-container *orgosTableCell="let row">
            <kenjo-truncate-text class="npd-relevant-field kenjo-font-size-14px kenjo-mr-60px"> {{row.user.jobTitle}}</kenjo-truncate-text>
          </ng-container>
        </orgos-table-column>

        <orgos-table-column *ngIf="!readMode" columnKey="actions" [header]="'Actions'">
          <ng-container *orgosTableCell="let row">
            <orgos-button-raised orgosColumn="0" color="Danger" (click)="remove(row)"> {{translation.remove}} </orgos-button-raised>
          </ng-container>
        </orgos-table-column>
      </orgos-table>
    </div>

    <div *ngIf="loading" class="kenjo-empty-state kenjo-text-align-center kenjo-mt-40px">
      <orgos-loading-spinner></orgos-loading-spinner>
    </div>

    <div *ngIf="nominations && nominations.length === 0" class="kenjo-empty-state kenjo-text-align-center kenjo-mt-40px">
      <img src="/assets/images/emptyPerformanceData.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{translation.noPeers}}</div>
    </div>
  </orgos-row>
  <orgos-row size="0" *ngIf="!loading">
    <orgos-column-container class="kenjo-mb-30px kenjo-mr-40px npd-option-btns" centerColumns="true">
      <orgos-column size="1"></orgos-column>
      <orgos-button-raised
        orgosColumn="0"
        [disabled]="savingNominations"
        color="Neutral"
        (click)="closeDialog(false)"
        class="kenjo-ml-20px"
      >
        {{translation.back}}
      </orgos-button-raised>

      <orgos-button-raised
        *ngIf="!readMode"
        [disabled]="savingNominations || (nominations &&  (hasNominationsLimit && nominations.length < nominationsLimit.min) || (!hasNominationsLimit && nominations.length === 0))"
        orgosColumn="0"
        color="Success"
        (click)="save()"
        class="kenjo-ml-20px"
      >
        {{translation.save}}
      </orgos-button-raised>
    </orgos-column-container>
  </orgos-row>
</orgos-row-container>
