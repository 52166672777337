import { Component, Injector, Input } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { DefaultPaywallDialog } from '@app/standard/components/feature-gating/dialogs/default-paywall-dialog/default-paywall.dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-feature-gating-icon',
  templateUrl: 'feature-gating-icon.component.html',
  styleUrls: ['feature-gating-icon.component.scss'],
})
export class FeatureGatingIconComponent {
  @Input() featureId: string;
  pageTranslations: { [translationKey: string]: string };

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  async getTranslations(): Promise<void> {
    this.pageTranslations = await this.injector.get(InternationalizationService).getAllTranslation('feature-gating');
  }

  showPaywallDialog(): void {
    this.injector.get(MatLegacyDialog).open(DefaultPaywallDialog, { data: { featureId: this.featureId } });
  }
}
