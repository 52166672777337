<ng-container *ngIf="categories?.length">
  <div class="acs-container" *ngIf="!readOnly">
    <ng-container *ngIf="label">
      <mat-label class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-pb-5px">{{label}}</mat-label>
    </ng-container>
    <div [matMenuTriggerFor]="categoriesMenu" class="kenjo-cursor-pointer">
      <div class="acs-select-container kenjo-border kenjo-border-radius" *ngIf="!chosenCategoryId">
        <div class="acs-select-container__title kenjo-ph-10px kenjo-pv-5px">
          {{ miscTranslations.selectAttendanceCategory }}
        </div>
        <div class="acs-select-container__icon kenjo-ph-10px kenjo-pv-5px">
          <kenjo-icon size="24">arrow_drop_down</kenjo-icon>
        </div>
      </div>
      <div class="acs-select-container kenjo-border kenjo-border-radius" *ngIf="chosenCategoryId">
        <div class="acs-select-container__color kenjo-mr-5px kenjo-ml-10px" [style.background-color]="colors[chosenCategory.color]"></div>
        <div class="acs-select-container__title kenjo-ph-10px kenjo-pv-5px">{{ chosenCategory.name }}</div>
        <div class="acs-select-container__icon kenjo-pv-5px kenjo-pl-5px">
          <kenjo-icon size="24">arrow_drop_down</kenjo-icon>
        </div>
        <div class="acs-select-container__icon kenjo-pr-10px kenjo-pv-5px">
          <mat-icon matSuffix class="kenjo-cursor-pointer kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pl-5px" (click)="clearValue(); $event.stopPropagation()">clear</mat-icon>
        </div>
      </div>
    </div>
    <mat-menu #categoriesMenu="matMenu" class="acs-mat-menu">
      <ng-container *ngIf="!showShiftsFromCategoryTooltips || categoryTooltips">
        <div *ngFor="let category of categories" class="acs-mat-menu-item">
          <button
            mat-menu-item
            (click)="selectCategory(category)"
            [ngClass]="{'rounded-borders': createShiftsFromCategory}"
            [matTooltip]="showShiftsFromCategoryTooltips ? categoryTooltips[category._id]?.currentShift : ''"
          >
            <div class="acs-category-container">
              <div class="acs-color-container kenjo-mr-10px" [style.background-color]="colors[category.color]"></div>
              <div class="acs-title-container">{{ category.name }}</div>
            </div>
          </button>
          <div *ngIf="createShiftsFromCategory">
            <button
              mat-menu-item
              class="acs-new-shift-button"
              [matTooltip]="showShiftsFromCategoryTooltips ? categoryTooltips[category._id]?.newShift : ''"
              (click)="newShiftForCategory(category)">
              <kenjo-icon class="kenjo-font-color-primary-5993e3" [size]="20">add_circle</kenjo-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </mat-menu>
  </div>

  <div class="acs-category-container" *ngIf="chosenCategory?._id && readOnly">
    <div class="acs-color-container kenjo-mr-5px" [style.background-color]="colors[chosenCategory.color]"></div>
    <div class="acs-title-container">{{ chosenCategory.name }}</div>
  </div>
</ng-container>