<orgos-dialog-container [title]="titleText" [employees]="employees" (clickCloseButton)="closeDialog()" [attr.id]="componentId !== null ? componentId : null">
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 cdf-white-space">
    {{ subtitleText }}
  </div>
  <div *ngFor="let input of inputs" class="kenjo-mt-20px">
    <orgos-input-simple-text class="kenjo-full-width" *ngIf="input.type === 'text'" [(value)]="input.value" [label]="input.label"></orgos-input-simple-text>
  </div>
  <orgos-column-container class="kenjo-mt-40px cdf-wrap-buttons">
    <orgos-column size="1" class="cdf-empty-column"></orgos-column>
    <orgos-column *ngIf="cancelButtonText" size="0" class="cdf-flex-shrink">
      <orgos-button-raised [disabled]="cancelDisabled" color="Neutral" (click)="closeDialog()" class="cdf-close cdf-flex-shrink">{{ cancelButtonText }}</orgos-button-raised>
    </orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="cdf-flex-shrink">
      <orgos-button-raised [disabled]="confirmDisabled" [color]="confirmButtonColor" class="kenjo-ml-20px cdf-confirm" (click)="confirmDialog()">
        {{ confirmButtonText }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
