<orgos-container *ngIf="!loadingPage && uploadedDocuments.length === 0" aspect="card" class="pp-payroll-box">
  <orgos-column-container *ngIf="!selectedFeature" class="pp-landing-box">
    <orgos-column class="kenjo-mt-120px">
      <img src="/assets/images/payroll-landing.svg" />
      <div class="kenjo-font-size-16px kenjo-font-color-light-text-757575 kenjo-mt-30px">
        {{ i18n.page.payrollLandingPage.landingFirstLineStart }}
        <span class="kenjo-font-color-text-333333">{{ i18n.page.payrollLandingPage.landingFirstLineMiddle }} </span>
        {{ i18n.page.payrollLandingPage.landingFirstLineEnd }}
      </div>
      <div class="kenjo-font-size-16px kenjo-font-color-light-text-757575">{{ i18n.page.payrollLandingPage.landingSecondLine }}</div>
      <orgos-input-simple-select
        [value]="i18n.page.payrollGroupLabel"
        [options]="settingsOptions"
        [label]="i18n.page.payrollGroupLabel"
        (valueChange)="changeGroup($event)"
        [autoOrder]="false"
        class="pp-landing-action-bar__input kenjo-pt-5px"
      ></orgos-input-simple-select>
    </orgos-column>
  </orgos-column-container>
  <!-- ACTION BAR -->
  <orgos-column-container
    centerColumns="true"
    class="kenjo-pt-10px kenjo-pb-5px kenjo-ph-40px kenjo-border-bottom"
    *ngIf="selectedFeature === 'payroll' || selectedFeature === 'datev'"
  >
    <orgos-column>
      <orgos-column-container centerColumns="true">
        <orgos-column size="0">
          <orgos-input-simple-select
            [value]="selectedFeature + '.' + selectedSettings._id"
            [options]="settingsOptions"
            [label]="i18n.page.payrollGroupLabel"
            (valueChange)="changeGroup($event)"
            [autoOrder]="false"
            class="pp-action-bar__input kenjo-pt-5px"
          ></orgos-input-simple-select>
        </orgos-column>
        <orgos-column size="0" *ngIf="payrollGroupData?.enableSplitByCalendarMonth">
          <kenjo-icon
            [size]="18"
            [matTooltip]="i18n.page.forecastingTooltip"
            class="kenjo-font-color-success-00b72e kenjo-cursor-pointer kenjo-ml-15px kenjo-mt-10px"
            >info_outline</kenjo-icon
          >
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column>
      <orgos-column-container centerColumns="true" class="kenjo-ph-60px kenjo-ph-40px">
        <kenjo-icon
          orgosColumn="0"
          [size]="25"
          [disabled]="selectedSettings.payPeriod === PAY_PERIOD_TYPE_VARIABLE && (!selectedSettings?.lastConfirmationDate || isStartDateToday)"
          class="kenjo-cursor-pointer kenjo-pl-20px"
          [class.kenjo-font-color-disabled-c4c4c4]="selectedSettings.payPeriod === PAY_PERIOD_TYPE_VARIABLE && (!selectedSettings.lastConfirmationDate || isStartDateToday)"
          [class.kenjo-font-color-primary-5993e3]="selectedSettings.payPeriod !== PAY_PERIOD_TYPE_VARIABLE || selectedSettings.lastConfirmationDate && !isStartDateToday"
          (click)="periodIdentifierValue = periodIdentifierValue - 1; moveToPeriod()"
        >
          keyboard_arrow_left
        </kenjo-icon>
        <orgos-column class="kenjo-mh-20px kenjo-text-align-center">
          <div *ngIf="selectedSettings.payPeriod === PAY_PERIOD_TYPE_MONTHLY" class="kenjo-font-size-18px">
            <kenjo-input-month-picker
              [maxDate]="maxDate"
              [preferenceDate]="preferenceDate"
              [tooltipMessage]="i18n.page.futureMonthsTooltip"
              (selectedDate)="detectChangesOnMonthPicker($event)"
            ></kenjo-input-month-picker>
          </div>
          <div
            *ngIf="selectedSettings.payPeriod === PAY_PERIOD_TYPE_CUSTOM_MONTHLY || selectedSettings.payPeriod === PAY_PERIOD_TYPE_VARIABLE || selectedSettings.payPeriod === PAY_PERIOD_TYPE_BI_MONTHLY || selectedSettings.payPeriod === PAY_PERIOD_TYPE_WEEKLY"
          >
            {{ fromDate | humanDate }} - {{ toDate | humanDate }}
          </div>
        </orgos-column>
        <kenjo-icon
          orgosColumn="0"
          [size]="25"
          [disabled]="periodIdentifierValue === 0"
          class="kenjo-cursor-pointer kenjo-pr-20px"
          [class.kenjo-font-color-disabled-c4c4c4]="periodIdentifierValue === 0"
          [class.kenjo-font-color-primary-5993e3]="periodIdentifierValue < 0"
          (click)="periodIdentifierValue = periodIdentifierValue + 1; moveToPeriod()"
        >
          keyboard_arrow_right
        </kenjo-icon>
      </orgos-column-container>
    </orgos-column>
    <orgos-column class="kenjo-text-align-right">
      <orgos-column-container *ngIf="confirmingPayroll">
        <orgos-column></orgos-column>
        <orgos-column size="0">
          <orgos-loading-spinner></orgos-loading-spinner>
        </orgos-column>
        <orgos-column size="0"
          ><div class="kenjo-ml-10px kenjo-font-color-light-text-757575 kenjo-text-transform-uppercase">
            {{ i18n.page.confirmingPayroll }}
          </div></orgos-column
        >
      </orgos-column-container>
      <div
        class="kenjo-inline-block"
        *ngIf="!confirmingPayroll && selectedFeature === 'payroll'"
        [matTooltip]="i18n.page.confirmButtonDisabledTooltip"
        [matTooltipDisabled]="periodIdentifierValue <= 0"
      >
        <orgos-button-raised *ngIf="!isPayrollConfirmed" (click)="confirmPayroll()" [disabled]="periodIdentifierValue > 0"
          >{{ i18n.page.confirmButton }}</orgos-button-raised
        >
        <orgos-chip *ngIf="isPayrollConfirmed" [color]="'Success'">{{ i18n.page.payrollClosedChip | uppercase }}</orgos-chip>
      </div>
      <orgos-chip *ngIf="!confirmingPayroll && selectedFeature === 'datev' && exportStatus === 'open'" [color]="'Neutral'"
        >{{ i18n.page.payrollOpenChip | uppercase }}</orgos-chip
      >
      <orgos-chip *ngIf="!confirmingPayroll && selectedFeature === 'datev' && exportStatus === 'readyToExport'" [color]="'Warning'"
        >{{ i18n.page.payrollReadyToExportChip | uppercase }}</orgos-chip
      >
      <orgos-chip *ngIf="!confirmingPayroll && selectedFeature === 'datev' && exportStatus === 'readyToConfirm'" [color]="'Neutral'"
        >{{ i18n.page.payrollOpenChip | uppercase }}</orgos-chip
      >
      <orgos-chip *ngIf="!confirmingPayroll && selectedFeature === 'datev' && exportStatus === 'closed'" [color]="'Success'"
        >{{ i18n.page.payrollClosedChip | uppercase }}</orgos-chip
      >
    </orgos-column>
  </orgos-column-container>
  <!-- INFO SECTION -->
  <!-- PAYROLL SECTION -->
  <div class="kenjo-border-bottom kenjo-ph-40px" *ngIf="selectedFeature === 'payroll'">
    <!-- NOT CONFIRMED PAYROLL -->
    <ng-container *ngIf="!isPayrollConfirmed">
      <orgos-page-message
        *ngIf="selectedSettings.payPeriod === PAY_PERIOD_TYPE_VARIABLE && !selectedSettings.lastConfirmationDate"
        messageType="warning"
        [messageText]="i18n.page.alertMessageVariablePayPeriodActive | i18nData: { startDate: selectedSettings.payPeriodVariableFromToDate | humanDate:false:'short' }"
        class="pp-alert-message kenjo-mt-20px"
      ></orgos-page-message>
      <orgos-column-container centerColumns="true" class="pp-info kenjo-pt-40px kenjo-pb-30px">
        <orgos-column>
          <orgos-column-container centerColumns="true">
            <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-success-00b72e kenjo-mr-5px kenjo-inline-block"
              >info_outline</kenjo-icon
            >
            <orgos-column>
              <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-mr-5px">{{ i18n.page.settingsInfoTextStart }}</div>
              <orgos-action [uppercase]="false" (click)="navigateToPayrollSettings()" class="kenjo-inline-block kenjo-font-size-14px">
                <orgos-column-container centerColumns="true">
                  <orgos-column size="0">{{ i18n.page.settingsInfoTextLink }}</orgos-column>
                  <orgos-column size="0" class="kenjo-ml-5px">
                    <kenjo-icon [size]="16" class="icon kenjo-font-color-primary-5993e3">launch</kenjo-icon>
                  </orgos-column>
                </orgos-column-container>
              </orgos-action>
              <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-ml-5px">{{ i18n.page.settingsInfoTextEnd }}</div>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="0">
          <orgos-column-container *ngIf="exporting">
            <orgos-column size="0">
              <orgos-loading-spinner></orgos-loading-spinner>
            </orgos-column>
            <orgos-column size="0"
              ><div class="kenjo-ml-10px kenjo-text-transform-uppercase">{{ i18n.page.exportingFile }}</div></orgos-column
            >
          </orgos-column-container>
          <orgos-action *ngIf="!exporting" icon="file_download" (click)="downloadExport()"
            >{{ i18n.page.exportPayrollButton }}</orgos-action
          >
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container centerColumns="true" class="kenjo-pb-40px">
        <orgos-column>
          <orgos-column-container centerColumns="true">
            <orgos-column size="0">{{ payrollGroupData.numberOfEmployees }}</orgos-column>
            <orgos-column size="0">
              <span class="kenjo-ml-5px kenjo-font-color-light-text-757575">{{ i18n.page.employeesPerGroup }}</span>
            </orgos-column>
            <kenjo-icon
              orgosColumn="0"
              [size]="18"
              [matTooltip]="i18n.page.employeesPerGroupTooltip"
              class="kenjo-ml-5px kenjo-font-color-success-00b72e"
              >help_outline</kenjo-icon
            >
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="0">
          <orgos-column-container centerColumns="true" class="kenjo-font-color-light-text-757575">
            <orgos-column size="0" class="pp-legend-icon kenjo-mr-5px"></orgos-column>
            <orgos-column
              >{{ i18n.page.changesLegend | i18nData: { startDate: fromDate | humanDate, endDate: toDate | humanDate } }}</orgos-column
            >
            <kenjo-icon
              orgosColumn="0"
              [size]="18"
              [matTooltip]="i18n.page.changesLegendTooltip"
              class="kenjo-ml-5px kenjo-font-color-success-00b72e"
              >help_outline</kenjo-icon
            >
          </orgos-column-container>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <!-- CONFIRMED PAYROLL -->
    <ng-container *ngIf="isPayrollConfirmed">
      <orgos-column-container class="pp-confirmed-payroll kenjo-pt-40px">
        <orgos-column>
          <div class="pp-payroll-closed-container">
            <div class="kenjo-font-size-18px kenjo-mb-20px">{{ i18n.page.payrollClosedTitle }}</div>
            <div class="kenjo-font-color-light-text-757575">{{ i18n.page.payrollClosedDescription }}</div>
            <orgos-action
              *ngIf="!checkingCorrections && !(isPayrollConfirmed && employeeData.length === 0)"
              (click)="checkForCorrections()"
              class="kenjo-mt-40px"
              >{{ i18n.page.checkForCorrectionsButton }}</orgos-action
            >
            <orgos-column-container *ngIf="checkingCorrections" class="kenjo-mt-40px">
              <orgos-column size="0">
                <orgos-loading-spinner></orgos-loading-spinner>
              </orgos-column>
              <orgos-column size="0">
                <div class="kenjo-ml-10px kenjo-font-color-light-text-757575 kenjo-text-transform-uppercase">
                  {{ i18n.page.checkingCorrections }}
                </div>
              </orgos-column>
            </orgos-column-container>
          </div>
        </orgos-column>
        <orgos-column size="0" class="pp-confirmed-payroll__right-side kenjo-border-radius kenjo-text-align-center">
          <kenjo-payroll-upload-payslips
            (documentsUploaded)="uploadedDocuments = $event"
            (documentsType)="documentsType = $event"
            [i18n]="i18n.page"
          ></kenjo-payroll-upload-payslips>
        </orgos-column>
      </orgos-column-container>
      <div *ngIf="corrections && corrections.length > 0" class="kenjo-mt-40px">
        <div class="kenjo-mb-20px kenjo-text-transform-uppercase kenjo-font-color-light-text-757575">
          {{i18n.page.correctionsTableTitle}}
        </div>
        <orgos-table
          [fullHeight]="true"
          [data]="corrections"
          [displayedColumns]="correctionsColumns"
          class="kenjo-border kenjo-border-radius"
        >
          <orgos-table-column columnKey="correctionDate" [header]="i18n.page.correctionDateColumn" cellWidth="33.3%">
            <div *orgosTableCell="let correction" class="kenjo-ml-5px">{{ correction.correctionDate | humanDate: false: 'short' }}</div>
          </orgos-table-column>
          <orgos-table-column columnKey="attachment" [header]="i18n.page.attachmentColumn" cellWidth="33.3%">
            <div *orgosTableCell="let correction">
              <orgos-column-container (click)="downloadAttachment(correction)" class="kenjo-cursor-pointer" centerColumns="true">
                <orgos-document-icon
                  orgosColumn="0"
                  size="small"
                  [extension]="correction.extension"
                  class="kenjo-mr-10px"
                ></orgos-document-icon>
                <orgos-column class="pp-attachment-text">
                  <a>
                    <kenjo-truncate-text>{{ correction.attachmentName }}</kenjo-truncate-text>
                  </a>
                </orgos-column>
              </orgos-column-container>
            </div>
          </orgos-table-column>
          <orgos-table-column columnKey="generatedBy" [header]="i18n.page.generatedByColumn" cellWidth="33.3%">
            <orgos-column-container centerColumns="true" *orgosTableCell="let correction">
              <orgos-avatar
                orgosColumn="0"
                [photoUrl]="correction.photoUrl"
                [avatarName]="correction.displayName"
                size="small"
                class="kenjo-mr-10px"
              ></orgos-avatar>
              <orgos-column>{{ correction.displayName }}</orgos-column>
            </orgos-column-container>
          </orgos-table-column>
        </orgos-table>
      </div>
      <div class="kenjo-mv-40px">
        <div class="kenjo-mb-20px kenjo-text-transform-uppercase kenjo-font-color-light-text-757575">
          {{i18n.page.confirmedPayrollSummaryTableTitle}}
        </div>
        <orgos-table [fullHeight]="true" [data]="exports" [displayedColumns]="exportsColumns" class="kenjo-border kenjo-border-radius">
          <orgos-table-column columnKey="closingDate" [header]="i18n.page.closingDateColumn" cellWidth="33.3%">
            <div *orgosTableCell="let export" class="kenjo-ml-5px">{{ export.exportDate | humanDate: false: 'short' }}</div>
          </orgos-table-column>
          <orgos-table-column columnKey="attachment" [header]="i18n.page.attachmentColumn" cellWidth="33.3%">
            <div *orgosTableCell="let export">
              <orgos-column-container (click)="downloadAttachment(export)" class="kenjo-cursor-pointer" centerColumns="true">
                <orgos-document-icon
                  orgosColumn="0"
                  size="small"
                  [extension]="export.extension"
                  class="kenjo-mr-10px"
                ></orgos-document-icon>
                <orgos-column class="pp-attachment-text">
                  <a>
                    <kenjo-truncate-text>{{ export.attachmentName }}</kenjo-truncate-text>
                  </a>
                </orgos-column>
              </orgos-column-container>
              <div></div>
            </div>
          </orgos-table-column>
          <orgos-table-column columnKey="closedBy" [header]="i18n.page.closedByColumn" cellWidth="33.3%">
            <orgos-column-container centerColumns="true" *orgosTableCell="let export">
              <orgos-avatar
                orgosColumn="0"
                [photoUrl]="export.photoUrl"
                [avatarName]="export.displayName"
                size="small"
                class="kenjo-mr-10px"
              ></orgos-avatar>
              <orgos-column>{{ export.displayName }}</orgos-column>
            </orgos-column-container>
          </orgos-table-column>
        </orgos-table>
      </div>
    </ng-container>
  </div>

  <!-- DATEV SECTION -->
  <div class="pp-data kenjo-pv-30px" *ngIf="selectedFeature === 'datev'">
    <ng-container *ngIf="exportStatus !== 'closed'">
      <!-- DATEV HEADER -->
      <div class="pp-box-shadow kenjo-color-white-background-ffffff kenjo-mh-40px kenjo-mb-30px">
        <!-- CONFIRM STEP -->
        <orgos-column-container>
          <orgos-column class="kenjo-pt-20px kenjo-ph-20px kenjo-pb-5px">
            <orgos-column-container class="kenjo-pb-10px pp-step-header-container">
              <orgos-column
                size="0"
                class="pp-step-number-item kenjo-mr-10px"
                [class.pp-step-number-success]="exportStatus === 'readyToConfirm'"
                [class.pp-step-number-disabled]="exportStatus !== 'readyToConfirm' || isDatevErrors"
              >
                {{ i18n.page?.datevPanelSteps?.reviewStepNumber }}
              </orgos-column>
              <orgos-column
                size="1"
                class="pp-step-text-item kenjo-font-size-14px"
                [class.kenjo-font-color-text-333333]="exportStatus === 'readyToConfirm'"
                [class.kenjo-font-color-disabled-c4c4c4]="exportStatus !== 'readyToConfirm'"
              >
                {{ i18n.page?.datevPanelSteps?.reviewTitle | uppercase }}
                <span
                  *ngIf="exportStatus === 'readyToExport'"
                  class="kenjo-font-color-light-text-757575 pp-step-text-completed kenjo-ml-5px"
                >
                  {{ i18n.page?.datevPanelSteps?.reviewTitleCompleted }}
                </span>
              </orgos-column>
            </orgos-column-container>
            <orgos-column-container
              *ngIf="exportStatus === 'readyToConfirm'"
              centerColumns="true"
              class="kenjo-border-radius kenjo-p-20px kenjo-mb-10px"
              [ngClass]="{'kenjo-color-grey-background-lighter-f8f8f8': !isDatevErrors, 'pp-datev-first-step-error-background': isDatevErrors}"
            >
              <!-- LENS -->
              <orgos-column size="0" class="kenjo-mr-20px">
                <img [src]="isDatevErrors ? '/assets/images/lens-error.svg' : '/assets/images/lens.svg'" />
              </orgos-column>
              <orgos-column size="1">
                <div class="kenjo-font-size-14px">
                  <div class="kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-m-0" *ngIf="!isDatevErrors">
                    <span class="kenjo-font-color-text-333333 kenjo-font-weight-bold">
                      {{ i18n.page?.datevPanelSteps?.reviewDescriptionStart }}
                    </span>
                    {{ i18n.page?.datevPanelSteps?.reviewDescriptionEnd }}
                  </div>
                  <div class="kenjo-font-color-danger-ff5757 kenjo-font-weight-bold" *ngIf="datevCompanyMandatoryFieldsMissing">
                    {{ i18n.page?.datevPanelSteps?.companyMandatoryFieldsDescription }}
                  </div>
                  <div
                    class="kenjo-font-color-danger-ff5757 kenjo-font-weight-bold"
                    *ngIf="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors"
                  >
                    {{ datevErrors.errorsAmount }} {{ i18n.page?.datevPanelSteps?.validationErrorsDescription }}
                  </div>
                </div>
                <div *ngIf="datevCompanyMandatoryFieldsMissing || !isDatevValidationErrors">
                  <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-mr-5px kenjo-mt-10px">
                    {{ datevCompanyMandatoryFieldsMissing ? i18n.page.settingsInfoMandatoryFieldErrorsTextStart :
                    i18n.page.settingsInfoTextStart }}
                  </div>
                  <orgos-action [uppercase]="false" (click)="navigateToDatevSettings()" class="kenjo-inline-block kenjo-font-size-14px">
                    <orgos-column-container centerColumns="true">
                      <orgos-column size="0">{{ i18n.page.settingsInfoTextLink }}</orgos-column>
                      <orgos-column size="0" class="kenjo-ml-5px">
                        <kenjo-icon [size]="16" class="icon kenjo-font-color-primary-5993e3">launch</kenjo-icon>
                      </orgos-column>
                    </orgos-column-container>
                  </orgos-action>
                  <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-ml-5px">
                    {{ datevCompanyMandatoryFieldsMissing ? i18n.page.settingsInfoMandatoryFieldErrorsTextEnd :
                    i18n.page.settingsInfoTextEnd }}
                  </div>
                </div>
                <div *ngIf="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors">
                  <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-mt-5px">
                    {{ i18n.page.settingsInfoValidationErrorsTextStart }}
                  </div>
                  <div class="kenjo-inline-block kenjo-mt-5px">
                    <span class="kenjo-font-color-text-333333 kenjo-font-weight-bold">
                      {{ i18n.page.settingsInfoValidationErrorsTextEnd }}
                    </span>
                    <orgos-action [uppercase]="false" (click)="navigateToHelpCenter()" class="kenjo-inline-block kenjo-font-size-12px">
                      <orgos-column-container centerColumns="true">
                        <orgos-column size="0" class="kenjo-font-size-14px">{{ i18n.page.settingsNeedHelpTextLink }}</orgos-column>
                      </orgos-column-container>
                    </orgos-action>
                  </div>
                </div>
              </orgos-column>
              <orgos-column size="0">
                <orgos-button-raised
                  [disabled]="hasUserPayrollExportPermissions === false || exportStatus !== 'readyToConfirm' || (datevPagination?.totalOfRecords === 0 && (!datevTables?.payAndAttendancePagination?.hourlyCorrectionsFields?.totalOfRecords || datevTables?.payAndAttendancePagination?.hourlyCorrectionsFields?.totalOfRecords === 0)) || isDatevErrors"
                  (click)="confirmDatev()"
                  >{{ i18n.page?.datevPanelSteps?.confirmReviewButton }}</orgos-button-raised
                >
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
        </orgos-column-container>
        <!-- EXPORT AND CLOSE STEP -->
        <orgos-column-container>
          <orgos-column class="kenjo-pb-20px kenjo-ph-20px">
            <orgos-column-container class="kenjo-pb-10px pp-step-header-container">
              <orgos-column
                size="0"
                class="pp-step-number-item kenjo-mr-10px"
                [class.pp-step-number-success]="exportStatus === 'readyToExport'"
                [class.pp-step-number-disabled]="exportStatus !== 'readyToExport' || isDatevErrors"
              >
                {{ i18n.page?.datevPanelSteps?.exportStepNumber }}
              </orgos-column>
              <orgos-column
                size="1"
                class="pp-step-text-item kenjo-font-size-14px"
                [class.kenjo-font-color-text-333333]="exportStatus === 'readyToExport'"
                [class.kenjo-font-color-disabled-c4c4c4]="exportStatus !== 'readyToExport'"
              >
                {{ i18n.page?.datevPanelSteps?.exportTitle | uppercase }}
              </orgos-column>
            </orgos-column-container>
            <orgos-column-container
              *ngIf="exportStatus === 'readyToExport'"
              centerColumns="true"
              class="kenjo-border-radius kenjo-p-20px kenjo-mb-10px"
              [ngClass]="{'kenjo-color-grey-background-lighter-f8f8f8': !isDatevErrors, 'pp-datev-first-step-error-background': isDatevErrors}"
            >
              <!-- LENS -->
              <orgos-column size="0" class="kenjo-mr-20px">
                <img [src]="isDatevErrors ? '/assets/images/lens-error.svg' : '/assets/images/lens-second-step.svg'" />
              </orgos-column>
              <orgos-column size="1">
                <div class="kenjo-font-size-14px">
                  <div class="kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-m-0" *ngIf="!isDatevErrors">
                    {{ i18n.page?.datevPanelSteps?.exportDescriptionStart }}
                    <span class="kenjo-font-color-text-333333 kenjo-font-weight-bold">
                      {{ i18n.page?.datevPanelSteps?.exportDescriptionMiddle }}
                    </span>
                    {{ i18n.page?.datevPanelSteps?.exportDescriptionEnd }}
                  </div>
                  <div class="kenjo-font-color-danger-ff5757 kenjo-font-weight-bold" *ngIf="datevCompanyMandatoryFieldsMissing">
                    {{ i18n.page?.datevPanelSteps?.companyMandatoryFieldsDescription }}
                  </div>
                  <div
                    class="kenjo-font-color-danger-ff5757 kenjo-font-weight-bold"
                    *ngIf="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors"
                  >
                    {{ datevErrors.errorsAmount }} {{ i18n.page?.datevPanelSteps?.validationErrorsDescription }}
                  </div>
                </div>
                <div *ngIf="datevCompanyMandatoryFieldsMissing || !isDatevValidationErrors">
                  <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-mr-5px kenjo-mt-10px">
                    {{ datevCompanyMandatoryFieldsMissing ? i18n.page.settingsInfoMandatoryFieldErrorsTextStart :
                    i18n.page.settingsInfoTextStart }}
                  </div>
                  <orgos-action [uppercase]="false" (click)="navigateToDatevSettings()" class="kenjo-inline-block kenjo-font-size-14px">
                    <orgos-column-container centerColumns="true">
                      <orgos-column size="0">{{ i18n.page.settingsInfoTextLink }}</orgos-column>
                      <orgos-column size="0" class="kenjo-ml-5px">
                        <kenjo-icon [size]="16" class="icon kenjo-font-color-primary-5993e3">launch</kenjo-icon>
                      </orgos-column>
                    </orgos-column-container>
                  </orgos-action>
                  <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-ml-5px">
                    {{ datevCompanyMandatoryFieldsMissing ? i18n.page.settingsInfoMandatoryFieldErrorsTextEnd :
                    i18n.page.settingsInfoTextEnd }}
                  </div>
                </div>
                <div *ngIf="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors">
                  <div class="kenjo-inline-block kenjo-font-color-light-text-757575 kenjo-mt-5px">
                    {{ i18n.page.settingsInfoValidationErrorsTextStart }}
                  </div>
                  <div class="kenjo-inline-block kenjo-mt-5px">
                    <span class="kenjo-font-color-text-333333 kenjo-font-weight-bold">
                      {{ i18n.page.settingsInfoValidationErrorsTextEnd }}
                    </span>
                    <orgos-action [uppercase]="false" (click)="navigateToHelpCenter()" class="kenjo-inline-block kenjo-font-size-12px">
                      <orgos-column-container centerColumns="true">
                        <orgos-column size="0" class="kenjo-font-size-14px">{{ i18n.page.settingsNeedHelpTextLink }}</orgos-column>
                      </orgos-column-container>
                    </orgos-action>
                  </div>
                </div>
              </orgos-column>
              <orgos-column size="0">
                <orgos-button-raised
                  [disabled]="hasUserPayrollExportPermissions === false || exportStatus !== 'readyToExport' || (datevPagination?.totalOfRecords === 0 && (!datevTables?.payAndAttendancePagination?.hourlyCorrectionsFields?.totalOfRecords || datevTables?.payAndAttendancePagination?.hourlyCorrectionsFields?.totalOfRecords === 0)) || isDatevErrors"
                  color="Success"
                  (click)="closeDatevPayroll()"
                  >{{ i18n.page?.datevPanelSteps?.exportButton }}</orgos-button-raised
                >
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
        </orgos-column-container>
      </div>

      <!-- FIRST LEVEL TABS -->
      <mat-tab-group
        class="pp-mat-tab-group kenjo-mb-20px"
        [disableRipple]="true"
        [selectedIndex]="selectedTabIndex"
        (selectedTabChange)="changeTab($event.index)"
      >
        <mat-tab #tab>
          <ng-template mat-tab-label>
            <orgos-column-container centerColumns="true">
              <orgos-column class="kenjo-mr-5px">{{ i18n.page.datevTabs.employeeDataTab | uppercase }}</orgos-column>
              <orgos-column size="0" *ngIf="employeeTabHasErrors === true">
                <kenjo-icon
                  [size]="20"
                  [class.kenjo-font-color-danger-ff5757]="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors"
                  [class.kenjo-color-disabled-c4c4c4]="datevCompanyMandatoryFieldsMissing"
                  >error_outline</kenjo-icon
                >
              </orgos-column>
            </orgos-column-container>
          </ng-template>

          <!-- SECOND LEVEL TABS -->
          <ng-template matTabContent>
            <mat-tab-group
              class="pp-datev-mat-tab-group"
              [disableRipple]="true"
              [selectedIndex]="selectedDatevTabIndex"
              (selectedTabChange)="changeDatevTab($event.index)"
            >
              <mat-tab #tab *ngFor="let datevTableKey of datevEmployeeDataTabKeys">
                <ng-template mat-tab-label>
                  <orgos-column-container centerColumns="true">
                    <orgos-column class="kenjo-mr-5px">{{ datevTables['employeeDataTab'][datevTableKey]?.name }}</orgos-column>
                    <orgos-column size="0" *ngIf="datevTables['employeeDataTab'][datevTableKey]?.hasErrors === true">
                      <kenjo-icon
                        [size]="20"
                        [class.kenjo-font-color-danger-ff5757]="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors"
                        [class.kenjo-color-disabled-c4c4c4]="datevCompanyMandatoryFieldsMissing"
                        >error_outline</kenjo-icon
                      >
                    </orgos-column>
                  </orgos-column-container>
                </ng-template>
                <ng-template matTabContent>
                  <div class="kenjo-ph-40px">
                    <div
                      *ngIf="datevTables['employeeDataTab'][datevTableKey]?.employeeData?.length > 0"
                      class="kenjo-border kenjo-border-radius kenjo-mt-20px kenjo-mb-50px"
                    >
                      <!-- DATEV DATA -->
                      <orgos-table-smart
                        [data]="datevTables['employeeDataTab'][datevTableKey].employeeData"
                        (sortTable)="changeColumnSort($event, datevTableKey)"
                        [sortBy]="datevSortBy"
                        [sortOrder]="datevSortOrder"
                        [totalColumns]="datevTables['employeeDataTab'][datevTableKey].employeeDataColumns"
                        [displayedColumns]="datevTables['employeeDataTab'][datevTableKey].employeeDataColumns"
                        [numberOfStickyColumns]="3"
                        [positionRelativeTd]="true"
                      >
                        <orgos-table-smart-column
                          *ngFor="let field of datevTables['employeeDataTab'][datevTableKey].employeeDataColumns; let i = index"
                          columnKey="{{ field }}"
                          [header]="datevTables['employeeDataTab'][datevTableKey].employeeDataColumnsTranslationKeys[field]"
                          [sortable]="field === 'firstName' || field === 'lastName' || field === 'personalNumber'"
                        >
                          <div
                            *orgosTableSmartCell="let row"
                            [class.pp-cell-content-error]="row.data[field]?.hasErrors"
                            [class.pp-warning-cell]="row?.hasChanges && !row.data[field]?.changed"
                            [class.pp-changed-cell]="row?.hasChanges && row.data[field]?.changed"
                          >
                            <div class="pp-cell-content">
                              <div *ngIf="row.data[field]?.type === 'avatar'">
                                <orgos-avatar
                                  [photoUrl]="row.data[field].value"
                                  [avatarName]="row.displayName"
                                  size="small"
                                  class="kenjo-ml-5px"
                                ></orgos-avatar>
                              </div>
                              <div *ngIf="row.data[field]?.type === 'number'" class="pp-number-column">
                                {{ row.data[field].value | number:'1.0-2' }}
                              </div>
                              <div *ngIf="row.data[field]?.type === 'string'" class="pp-string-column">
                                <div
                                  *ngIf="row.data[field]?.hasErrors && !datevCompanyMandatoryFieldsMissing"
                                  class="pp-cell-content-error-message"
                                  [ngClass]="{'pp-cell-content-error-message-sticky': field === 'firstName' || field === 'lastName'}"
                                  (click)="errorNavigation(field, row.userId)"
                                  [matTooltip]="row.data[field]?.tooltipMessage ?? i18n.page.datevValidationButtonHover"
                                  matTooltipPosition="above"
                                >
                                  {{ row.data[field]?.errorMessage }}
                                </div>
                                <div
                                  *ngIf="row.data[field]?.hasErrors && datevCompanyMandatoryFieldsMissing"
                                  class="pp-cell-content-error-message-disabled"
                                >
                                  {{ row.data[field]?.errorMessage }}
                                </div>
                                <div *ngIf="field !== 'firstName' && !row.data[field]?.hasErrors" class="pp-string-column-content">
                                  {{ row.data[field]?.value }}
                                </div>
                                <a
                                  *ngIf="field === 'firstName' && !row.data[field]?.hasErrors"
                                  (click)="navigateToPersonalProfile(row.userId)"
                                  [queryParams]="{ payrollGroupId, period: periodIdentifierValue }"
                                  class="kenjo-text-decoration-none kenjo-cursor-pointer"
                                >
                                  {{ row.data[field]?.value }}
                                </a>
                              </div>
                              <div *ngIf="row.data[field]?.type === 'date'" class="pp-string-column">
                                <div
                                  *ngIf="row.data[field]?.hasErrors && !datevCompanyMandatoryFieldsMissing"
                                  class="pp-cell-content-error-message"
                                  (click)="errorNavigation(field, row.userId)"
                                  [matTooltip]="row.data[field]?.tooltipMessage ?? i18n.page.datevValidationButtonHover"
                                  matTooltipPosition="above"
                                >
                                  {{ row.data[field]?.errorMessage }}
                                </div>
                                <div
                                  *ngIf="row.data[field]?.hasErrors && datevCompanyMandatoryFieldsMissing"
                                  class="pp-cell-content-error-message-disabled"
                                >
                                  {{ row.data[field]?.errorMessage }}
                                </div>
                                <div *ngIf="!row.data[field]?.hasErrors" class="pp-string-column-content">
                                  {{ row.data[field].value | date:'shortDate':'UTC'}}
                                </div>
                              </div>
                              <div *ngIf="row.data[field]?.type === 'hour'">{{ row.data[field].value * 60 | duration }}</div>
                              <div *ngIf="row.data[field]?.type === 'children'" class="pp-children-column">
                                {{ row.data[field].value | convertChildren }}
                              </div>
                              <div *ngIf="row.data[field]?.type === 'comment'" class="pp-comment-column">
                                <kenjo-payroll-comment-cell
                                  [comment]="row.data[field].value"
                                  [i18n]="i18n.page"
                                  [payrollConfirmed]="isPayrollConfirmed"
                                  [employeeData]="row"
                                  [periodFrom]="fromDate"
                                  (commentUpdated)="onCommentUpdated()"
                                  [settingsId]="selectedSettings._id"
                                ></kenjo-payroll-comment-cell>
                              </div>
                            </div>
                          </div>
                        </orgos-table-smart-column>
                      </orgos-table-smart>

                      <!-- PAGINATION -->
                      <orgos-column-container
                        centerColumns="true"
                        *ngIf="!loadingPage && datevTables['employeeDataTab'][datevTableKey].employeeData?.length > 0"
                        class="pp-pagination"
                      >
                        <orgos-column></orgos-column>
                        <orgos-column size="0">
                          <orgos-column-container centerColumns="true">
                            <orgos-column size="0" class="kenjo-font-color-light-text-757575">
                              {{ i18n.page.displayingLabel }}
                            </orgos-column>
                            <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px pp-list-num-pages-container">
                              <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
                                <orgos-column-container centerColumns="true">
                                  <orgos-column class="kenjo-font-color-text-333333" class="pp-records-page">
                                    {{ datevQueryOptions.recordsPerPage }}</orgos-column
                                  >
                                  <orgos-column size="0">
                                    <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                                  </orgos-column>
                                </orgos-column-container>
                              </div>
                              <mat-menu #paginationMenu="matMenu">
                                <button
                                  *ngFor="let page of recordsToShowDatevSelector"
                                  mat-menu-item
                                  (click)="changeDatevRecordsToShow(page, datevTableKey)"
                                >
                                  <div class="kenjo-font-color-light-text-757575">{{ page }}</div>
                                </button>
                              </mat-menu>
                            </orgos-column>
                            <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-pl-10px">
                              {{ i18n.page.outOfLabel }}</orgos-column
                            >
                            <orgos-column class="kenjo-font-color-text-333333 kenjo-pl-10px"
                              >{{ datevPagination?.totalOfRecords }}
                            </orgos-column>
                          </orgos-column-container>
                        </orgos-column>
                        <orgos-column size="0" class="kenjo-mh-40px">
                          <orgos-column-container centerColumns="true">
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-mr-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevPagination.currentPage === 1, 'kenjo-font-color-primary-5993e3': datevPagination.currentPage !== 1 }"
                                (click)="moveToDatevPage(PAGE_SELECTOR['first'], datevTableKey)"
                                >first_page</mat-icon
                              >
                            </orgos-column>
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-mr-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevPagination.currentPage === 1, 'kenjo-font-color-primary-5993e3': datevPagination.currentPage !== 1 }"
                                (click)="moveToDatevPage(PAGE_SELECTOR['previous'], datevTableKey)"
                                >keyboard_arrow_left</mat-icon
                              >
                            </orgos-column>
                            <orgos-column size="0" class="kenjo-ml-10px">
                              {{ datevPagination?.currentPage }} / {{ datevPagination.pages }}</orgos-column
                            >
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-ml-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevPagination.currentPage === datevPagination.pages, 'kenjo-font-color-primary-5993e3': datevPagination.currentPage !== datevPagination.pages }"
                                (click)="moveToDatevPage(PAGE_SELECTOR['next'], datevTableKey)"
                                >keyboard_arrow_right</mat-icon
                              >
                            </orgos-column>
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-ml-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevPagination.currentPage === datevPagination.pages, 'kenjo-font-color-primary-5993e3': datevPagination.currentPage !== datevPagination.pages }"
                                (click)="moveToDatevPage(PAGE_SELECTOR['final'], datevTableKey)"
                                >last_page</mat-icon
                              >
                            </orgos-column>
                          </orgos-column-container>
                        </orgos-column>
                        <orgos-column></orgos-column>
                      </orgos-column-container>
                    </div>

                    <!-- NO DATA YET -->
                    <div *ngIf="datevTables['employeeDataTab'][datevTableKey]?.employeeData?.length === 0" class="kenjo-text-align-center">
                      <div class="kenjo-empty-state kenjo-text-align-center kenjo-mt-80px kenjo-mb-30px">
                        <img src="/assets/images/lama.svg" />
                      </div>
                      <div class="kenjo-font-size-16px kenjo-mb-20px">{{ i18n.page.noDatevTableDataFoundTitle }}</div>
                      <div class="pp-not-found-message kenjo-font-color-light-text-757575">
                        {{ i18n.page.noDatevTableDataFoundSubtitle }}
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- DATEV TABLE -->
              </mat-tab>
            </mat-tab-group>
          </ng-template>
        </mat-tab>
        <mat-tab #tab *ngIf="datevPayAndAttendanceTabKeys.length > 0 && datevTables?.['payAndAttendanceTab']">
          <ng-template mat-tab-label>
            <orgos-column-container centerColumns="true">
              <orgos-column class="kenjo-mr-5px">{{ i18n.page.datevTabs.payAndAttendanceTab | uppercase }}</orgos-column>
              <orgos-column size="0" *ngIf="paymentTabHasErrors === true">
                <kenjo-icon
                  [size]="20"
                  [class.kenjo-font-color-danger-ff5757]="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors"
                  [class.kenjo-color-disabled-c4c4c4]="datevCompanyMandatoryFieldsMissing"
                  >error_outline</kenjo-icon
                >
              </orgos-column>
            </orgos-column-container>
          </ng-template>
          <ng-template matTabContent>
            <mat-tab-group
              class="pp-datev-mat-tab-group"
              [disableRipple]="true"
              [selectedIndex]="selectedDatevTabIndex"
              (selectedTabChange)="changeDatevTab($event.index)"
            >
              <mat-tab *ngFor="let datevTableKey of datevPayAndAttendanceTabKeys">
                <ng-template mat-tab-label>
                  <orgos-column-container centerColumns="true">
                    <orgos-column class="kenjo-mr-5px">{{ datevTables['payAndAttendanceTab'][datevTableKey]?.table.name }}</orgos-column>
                    <orgos-column size="0" *ngIf="datevTables['payAndAttendanceTab'][datevTableKey]?.table.hasErrors === true">
                      <kenjo-icon
                        [size]="20"
                        [class.kenjo-font-color-danger-ff5757]="!datevCompanyMandatoryFieldsMissing && isDatevValidationErrors"
                        [class.kenjo-color-disabled-c4c4c4]="datevCompanyMandatoryFieldsMissing"
                        >error_outline</kenjo-icon
                      >
                    </orgos-column>
                  </orgos-column-container>
                </ng-template>

                <!-- DATEV TABLE -->
                <ng-template matTabContent>
                  <div class="kenjo-ph-40px">
                    <div
                      *ngIf="datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeData?.length > 0"
                      class="kenjo-border kenjo-border-radius kenjo-mt-20px kenjo-mb-50px"
                    >
                      <!-- DATEV DATA -->
                      <orgos-table-smart
                        [data]="datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeData"
                        (sortTable)="changeColumnSort($event, datevTableKey)"
                        [sortBy]="datevSortBy"
                        [sortOrder]="datevSortOrder"
                        [totalColumns]="datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeDataColumns"
                        [displayedColumns]="datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeDataColumns"
                        [numberOfStickyColumns]="3"
                        [positionRelativeTd]="true"
                      >
                        <orgos-table-smart-column
                          *ngFor="let field of datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeDataColumns; let i = index"
                          columnKey="{{ field }}"
                          [header]="datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeDataColumnsTranslationKeys[field]"
                          [sortable]="field === 'firstName' || field === 'lastName' || field === 'personalNumber'"
                        >
                          <div
                            *orgosTableSmartCell="let row"
                            [class.pp-cell-content-error]="row.data[field]?.hasErrors"
                            [class.pp-warning-cell]="row?.hasChanges && !row.data[field]?.changed"
                            [class.pp-changed-cell]="row?.hasChanges && row.data[field]?.changed"
                          >
                            <div class="pp-cell-content">
                              <div *ngIf="row.data[field]?.type === 'avatar'">
                                <orgos-avatar
                                  [photoUrl]="row.data[field].value"
                                  [avatarName]="row.displayName"
                                  size="small"
                                  class="kenjo-ml-5px"
                                ></orgos-avatar>
                              </div>
                              <div *ngIf="row.data[field]?.type === 'number'" class="pp-number-column">
                                {{ row.data[field].value | number:'1.0-2' }}
                              </div>
                              <div *ngIf="row.data[field]?.type === 'string'" class="pp-string-column">
                                <div
                                  *ngIf="row.data[field]?.hasErrors && !datevCompanyMandatoryFieldsMissing"
                                  class="pp-cell-content-error-message"
                                  [ngClass]="{'pp-cell-content-error-message-sticky': field === 'firstName' || field === 'lastName'}"
                                  (click)="errorNavigation(field, row.userId)"
                                  [matTooltip]="row.data[field]?.tooltipMessage ?? i18n.page.datevValidationButtonHover"
                                  matTooltipPosition="above"
                                >
                                  {{ row.data[field]?.errorMessage }}
                                </div>
                                <div
                                  *ngIf="row.data[field]?.hasErrors && datevCompanyMandatoryFieldsMissing"
                                  class="pp-cell-content-error-message-disabled"
                                >
                                  {{ row.data[field]?.errorMessage }}
                                </div>
                                <div *ngIf="field !== 'firstName' && !row.data[field]?.hasErrors" class="pp-string-column-content">
                                  {{ row.data[field]?.value }}
                                </div>
                                <a
                                  *ngIf="field === 'firstName' && !row.data[field]?.hasErrors"
                                  (click)="navigateToPersonalProfile(row.userId)"
                                  [queryParams]="{ payrollGroupId, period: periodIdentifierValue }"
                                  class="kenjo-text-decoration-none kenjo-cursor-pointer"
                                >
                                  {{ row.data[field]?.value }}
                                </a>
                              </div>
                              <div *ngIf="row.data[field]?.type === 'date'">{{ row.data[field].value | date:'shortDate':'UTC'}}</div>
                              <div *ngIf="row.data[field]?.type === 'hour'">{{ row.data[field].value * 60 | duration }}</div>
                              <div *ngIf="row.data[field]?.type === 'children'" class="pp-children-column">
                                {{ row.data[field].value | convertChildren }}
                              </div>
                              <div *ngIf="row.data[field]?.type === 'comment'" class="pp-comment-column">
                                <kenjo-payroll-comment-cell
                                  [comment]="row.data[field].value"
                                  [i18n]="i18n.page"
                                  [payrollConfirmed]="isPayrollConfirmed"
                                  [employeeData]="row"
                                  [periodFrom]="fromDate"
                                  (commentUpdated)="onCommentUpdated()"
                                  [settingsId]="selectedSettings._id"
                                ></kenjo-payroll-comment-cell>
                              </div>
                            </div>
                          </div>
                        </orgos-table-smart-column>
                      </orgos-table-smart>

                      <!-- PAGINATION -->
                      <orgos-column-container
                        centerColumns="true"
                        *ngIf="!loadingPage && datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeData?.length > 0"
                        class="pp-pagination"
                      >
                        <orgos-column></orgos-column>
                        <orgos-column size="0">
                          <orgos-column-container centerColumns="true">
                            <orgos-column size="0" class="kenjo-font-color-light-text-757575">
                              {{ i18n.page.displayingLabel }}
                            </orgos-column>
                            <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px pp-list-num-pages-container">
                              <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
                                <orgos-column-container centerColumns="true">
                                  <orgos-column class="kenjo-font-color-text-333333" class="pp-records-page">
                                    {{ datevQueryOptions.recordsPerPage }}</orgos-column
                                  >
                                  <orgos-column size="0">
                                    <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                                  </orgos-column>
                                </orgos-column-container>
                              </div>
                              <mat-menu #paginationMenu="matMenu">
                                <button
                                  *ngFor="let page of recordsToShowDatevSelector"
                                  mat-menu-item
                                  (click)="changeDatevRecordsToShow(page, datevTableKey)"
                                >
                                  <div class="kenjo-font-color-light-text-757575">{{ page }}</div>
                                </button>
                              </mat-menu>
                            </orgos-column>
                            <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-pl-10px">
                              {{ i18n.page.outOfLabel }}</orgos-column
                            >
                            <orgos-column class="kenjo-font-color-text-333333 kenjo-pl-10px"
                              >{{
                              datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.totalOfRecords
                              }}</orgos-column
                            >
                          </orgos-column-container>
                        </orgos-column>
                        <orgos-column size="0" class="kenjo-mh-40px">
                          <orgos-column-container centerColumns="true">
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-mr-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage === 1, 'kenjo-font-color-primary-5993e3': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage !== 1 }"
                                (click)="moveToDatevPaymentPage(PAGE_SELECTOR['first'], datevTableKey)"
                                >first_page</mat-icon
                              >
                            </orgos-column>
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-mr-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage === 1, 'kenjo-font-color-primary-5993e3': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage !== 1 }"
                                (click)="moveToDatevPaymentPage(PAGE_SELECTOR['previous'], datevTableKey)"
                                >keyboard_arrow_left</mat-icon
                              >
                            </orgos-column>
                            <orgos-column size="0" class="kenjo-ml-10px">
                              {{
                              datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage
                              }} / {{
                              datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.pages
                              }}</orgos-column
                            >
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-ml-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage === datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.pages, 'kenjo-font-color-primary-5993e3': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage !== datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.pages }"
                                (click)="moveToDatevPaymentPage(PAGE_SELECTOR['next'], datevTableKey)"
                                >keyboard_arrow_right</mat-icon
                              >
                            </orgos-column>
                            <orgos-column size="0">
                              <mat-icon
                                class="kenjo-ml-10px pp-pagination-icon"
                                [ngClass]="{'kenjo-font-color-disabled-c4c4c4': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage === datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.pages, 'kenjo-font-color-primary-5993e3': datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.currentPage !== datevTables?.['payAndAttendancePagination']?.[datevTables['payAndAttendanceTab'][datevTableKey].paginationKey]?.pages }"
                                (click)="moveToDatevPaymentPage(PAGE_SELECTOR['final'], datevTableKey)"
                                >last_page</mat-icon
                              >
                            </orgos-column>
                          </orgos-column-container>
                        </orgos-column>
                        <orgos-column></orgos-column>
                      </orgos-column-container>
                    </div>

                    <!-- NO DATA YET -->
                    <div
                      *ngIf="!datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeData || datevTables['payAndAttendanceTab'][datevTableKey]?.table.employeeData?.length === 0"
                      class="kenjo-text-align-center"
                    >
                      <div class="kenjo-empty-state kenjo-text-align-center kenjo-mt-80px kenjo-mb-30px">
                        <img src="/assets/images/lama.svg" />
                      </div>
                      <div class="kenjo-font-size-16px kenjo-mb-20px">{{ i18n.page.noDatevTableDataFoundTitle }}</div>
                      <div class="pp-not-found-message kenjo-font-color-light-text-757575">
                        {{ i18n.page.noDatevTableDataFoundSubtitle }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-container *ngIf="exportStatus === 'closed'">
      <orgos-column-container class="pp-confirmed-payroll kenjo-mt-10px kenjo-mh-40px">
        <orgos-column>
          <div class="pp-payroll-closed-container">
            <div class="kenjo-font-size-18px kenjo-mb-20px">{{ i18n.page.payrollClosedTitle }}</div>
            <div class="kenjo-font-color-light-text-757575">{{ i18n.page.payrollClosedDescription }}</div>
          </div>
        </orgos-column>
        <orgos-column size="0" class="pp-confirmed-payroll__right-side kenjo-border-radius kenjo-text-align-center">
          <kenjo-payroll-upload-payslips
            (documentsUploaded)="onUploadedDocuments($event)"
            (documentsType)="documentsType = $event"
            [i18n]="i18n.page"
          ></kenjo-payroll-upload-payslips>
        </orgos-column>
      </orgos-column-container>
      <div class="kenjo-mv-40px kenjo-mh-40px">
        <div class="kenjo-mb-20px kenjo-text-transform-uppercase kenjo-font-color-light-text-757575">
          {{i18n.page.confirmedPayrollSummaryTableTitle}}
        </div>
        <orgos-table
          [fullHeight]="true"
          [data]="datevExports"
          [displayedColumns]="exportsDatevColumns"
          class="kenjo-border kenjo-border-radius"
        >
          <orgos-table-column columnKey="closingDate" [header]="i18n.page.closingDateColumn" cellWidth="20%">
            <div *orgosTableCell="let export" class="kenjo-ml-5px">{{ export._updatedAt | humanDate: false: 'short' }}</div>
          </orgos-table-column>
          <orgos-table-column columnKey="attachment" [header]="i18n.page.attachmentColumn" cellWidth="20%">
            <div *orgosTableCell="let export">
              <orgos-column-container
                *ngIf="hasUserPayrollExportPermissions === true"
                (click)="downloadDatevFile(export._datevSettingsId)"
                class="kenjo-cursor-pointer"
                centerColumns="true"
              >
                <orgos-document-icon orgosColumn="0" size="small" extension="zip" class="kenjo-mr-10px"></orgos-document-icon>
                <orgos-column class="pp-attachment-text">
                  <a>
                    <kenjo-truncate-text>{{ datevExportFileName }}</kenjo-truncate-text>
                  </a>
                </orgos-column>
              </orgos-column-container>
              <div></div>
            </div>
          </orgos-table-column>
          <orgos-table-column columnKey="method" [header]="i18n.page.methodColumn" cellWidth="20%">
            <div *orgosTableCell="let export" class="kenjo-ml-5px">
              {{ export.method === 'DATEV_API' ? i18n.page.datevExportApi : i18n.page.datevExportDownload }}
            </div>
          </orgos-table-column>
          <orgos-table-column columnKey="closedBy" [header]="i18n.page.closedByColumn" cellWidth="30%">
            <orgos-column-container centerColumns="true" *orgosTableCell="let export">
              <orgos-avatar
                orgosColumn="0"
                [photoUrl]="export?.closingUserPhotoUrl"
                [avatarName]="export?.closingUserDisplayName"
                size="small"
                class="kenjo-mr-10px"
              ></orgos-avatar>
              <orgos-column>{{ export?.closingUserDisplayName }}</orgos-column>
            </orgos-column-container>
          </orgos-table-column>
          <orgos-table-column columnKey="reopen" header="" cellWidth="10%">
            <orgos-column-container centerColumns="true" *orgosTableCell="let export">
              <orgos-column *ngIf="hasUserPayrollExportPermissions === true" class="kenjo-cursor-pointer" (click)="reopenDatevPayroll()">
                <a>
                  <kenjo-truncate-text>{{ i18n.page.datevReopenButton | uppercase }}</kenjo-truncate-text>
                </a>
              </orgos-column>
            </orgos-column-container>
          </orgos-table-column>
        </orgos-table>
      </div>
    </ng-container>
  </div>

  <!-- ALL DATA -->
  <!-- PAYROLL SECTION -->
  <div class="pp-data kenjo-pv-40px kenjo-ph-40px" *ngIf="selectedFeature === 'payroll'">
    <orgos-column-container centerColumns="true">
      <orgos-column size="0">
        <orgos-action [selected]="selectedTab === PAYROLL_TAB_EMPLOYEE_DATA" (click)="changeSelectedTab(PAYROLL_TAB_EMPLOYEE_DATA)"
          >{{ i18n.page.employeeDataTab }}</orgos-action
        >
      </orgos-column>
      <orgos-column *ngIf="timeOff?.timeOffRecords?.length > 0" size="0">
        <orgos-action
          [selected]="selectedTab === PAYROLL_TAB_TIME_OFF"
          (click)="changeSelectedTab(PAYROLL_TAB_TIME_OFF)"
          class="kenjo-ml-15px"
          >{{ i18n.page.timeOffTab | i18nData: { numberOfTimeOffs: timeOff.timeOffCount } }}
        </orgos-action>
      </orgos-column>
      <orgos-column size="0">
        <orgos-action
          [selected]="selectedTab === PAYROLL_TAB_VARIABLE_PAYMENTS"
          (click)="changeSelectedTab(PAYROLL_TAB_VARIABLE_PAYMENTS)"
          class="kenjo-ml-15px"
          >{{ i18n.page.variablePaymentsTab | i18nData: { numberOfVariablePayments: variablePayments.length } }}</orgos-action
        >
      </orgos-column>
      <orgos-column *ngIf="employeeDocs.length > 0" size="0">
        <orgos-action
          [selected]="selectedTab === PAYROLL_TAB_EMPLOYEE_DOCS"
          (click)="changeSelectedTab(PAYROLL_TAB_EMPLOYEE_DOCS)"
          class="kenjo-ml-15px"
          >{{ i18n.page.employeeDocs | i18nData: { numberOfDocs: employeeDocs.length } }}</orgos-action
        >
      </orgos-column>
    </orgos-column-container>

    <!-- EMPLOYEE DATA -->
    <div *ngIf="selectedTab === PAYROLL_TAB_EMPLOYEE_DATA">
      <orgos-input-simple-radio
        *ngIf="payrollGroupData?.enableSplitByCalendarMonth"
        picklist="payrollTableViews"
        [value]="viewSelected"
        (valueChange)="toggleTableView($event)"
        inline="true"
        class="kenjo-mt-30px kenjo-pt-5px"
      ></orgos-input-simple-radio>
      <div *ngIf="employeeData.length > 0" class="kenjo-border kenjo-border-radius kenjo-mt-30px kenjo-mb-50px">
        <orgos-table-smart
          [data]="recordsToShow"
          [totalColumns]="employeeDataColumns"
          [displayedColumns]="employeeDataColumns"
          [sortingDataAccessor]="sortingDataAccessor"
          [numberOfStickyColumns]="3"
          [positionRelativeTd]="true"
        >
          <orgos-table-smart-column
            *ngFor="let field of employeeDataColumns; let i = index"
            columnKey="{{ field }}"
            [showTooltip]="false"
            [header]="employeeDataColumnsTranslationKeys[field]"
            [sortable]="field === 'user-personal.firstName' || field === 'user-personal.lastName'"
          >
            <div
              *orgosTableSmartCell="let row"
              [class.pp-confirmed-cell]="isPayrollConfirmed && field !== 'payroll-custom.salarySurcharges'"
              [class.pp-warning-cell]="row.hasChanges && !row.data[field]?.changed"
              [class.pp-changed-cell]="row.hasChanges && row.data[field]?.changed"
            >
              <div class="pp-cell-content">
                <div *ngIf="row.data[field].type === 'avatar'">
                  <orgos-avatar
                    [photoUrl]="row.data[field].value"
                    [avatarName]="row.displayName"
                    size="small"
                    class="kenjo-ml-5px"
                  ></orgos-avatar>
                </div>
                <div
                  *ngIf="row.data[field].type === 'number'"
                  [class]="field !== 'payroll-custom.salarySurcharges' ? 'pp-number-column' : 'pp-payroll-string-column'"
                >
                  <ng-container *ngIf="field !== 'payroll-custom.salarySurcharges'; else surchargeNumber">
                    <kenjo-truncate-text
                      >{{ row.data[field]?.value?.split(' ')?.[0] | number:'1.0-2' }} {{ row.data[field]?.value?.split(' ')?.[1]
                      }}</kenjo-truncate-text
                    >
                  </ng-container>
                  <ng-template #surchargeNumber>
                    <ng-container *ngIf="!row.data[field]?.value; else canOpenSurcharge"> - </ng-container>
                    <ng-template #canOpenSurcharge>
                      <a (click)="openSurchargeDetails(row)">{{ row.data[field].value }}</a>
                    </ng-template>
                  </ng-template>
                </div>
                <div *ngIf="row.data[field].type === 'string'" class="pp-payroll-string-column">
                  <!-- Different elements for add link to user personal if has enough permissions and if it's the firstName field -->
                  <kenjo-truncate-text
                    *ngIf="isPayrollConfirmed || !hasUserPayrollPermissions || (hasUserPayrollPermissions && row.data[field].field !== 'firstName')"
                    >{{ row.data[field].value }}</kenjo-truncate-text
                  >
                  <a
                    *ngIf="!isPayrollConfirmed && hasUserPayrollPermissions && row.data[field].field === 'firstName'"
                    [routerLink]="['/cloud/people', row.userId, 'personal']"
                    [queryParams]="{ payrollGroupId, period: periodIdentifierValue }"
                    class="kenjo-text-decoration-none kenjo-cursor-pointer"
                  >
                    <kenjo-truncate-text>{{ row.data[field].value }}</kenjo-truncate-text>
                  </a>
                </div>
                <div *ngIf="row.data[field].type === 'date'">
                  <kenjo-truncate-text>{{ row.data[field].value | date:'shortDate':'UTC'}}</kenjo-truncate-text>
                </div>
                <div *ngIf="row.data[field].type === 'hour'" class="pp-payroll-string-column">
                  <kenjo-truncate-text>{{ row.data[field].value * 60 | duration }}</kenjo-truncate-text>
                </div>
                <div *ngIf="row.data[field].type === 'children'" class="pp-children-column">
                  <kenjo-truncate-text>{{ row.data[field].value | convertChildren }}</kenjo-truncate-text>
                </div>
                <div *ngIf="row.data[field].type === 'comment'" class="pp-comment-column">
                  <kenjo-payroll-comment-cell
                    [comment]="row.data[field].value"
                    [i18n]="i18n.page"
                    [payrollConfirmed]="isPayrollConfirmed"
                    [employeeData]="row"
                    [periodFrom]="fromDate"
                    (commentUpdated)="onCommentUpdated()"
                    [settingsId]="selectedSettings._id"
                  ></kenjo-payroll-comment-cell>
                </div>
              </div>
            </div>
          </orgos-table-smart-column>
        </orgos-table-smart>

        <!-- PAGINATION -->
        <orgos-column-container centerColumns="true" *ngIf="!loadingPage && employeeData.length > 1" class="pp-pagination">
          <orgos-column></orgos-column>
          <orgos-column size="0">
            <orgos-column-container centerColumns="true">
              <orgos-column size="0" class="kenjo-font-color-light-text-757575"> {{ i18n.page.displayingLabel }} </orgos-column>
              <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px pp-list-num-pages-container">
                <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
                  <orgos-column-container centerColumns="true">
                    <orgos-column class="kenjo-font-color-text-333333" class="pp-records-page">
                      {{ queryOptions.recordsPerPage }}</orgos-column
                    >
                    <orgos-column size="0">
                      <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                    </orgos-column>
                  </orgos-column-container>
                </div>
                <mat-menu #paginationMenu="matMenu">
                  <button *ngFor="let page of recordsToShowSelector" mat-menu-item (click)="changeRecordsToShow(page)">
                    <div class="kenjo-font-color-light-text-757575">{{ page }}</div>
                  </button>
                </mat-menu>
              </orgos-column>
              <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-pl-10px"> {{ i18n.page.outOfLabel }}</orgos-column>
              <orgos-column class="kenjo-font-color-text-333333 kenjo-pl-10px">{{ totalOfRecords }} </orgos-column>
            </orgos-column-container>
          </orgos-column>
          <orgos-column size="0" class="kenjo-mh-40px">
            <orgos-column-container centerColumns="true">
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-mr-10px pp-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                  (click)="moveToPage(PAGE_SELECTOR['first'])"
                  >first_page</mat-icon
                >
              </orgos-column>
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-mr-10px pp-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                  (click)="moveToPage(PAGE_SELECTOR['previous'])"
                  >keyboard_arrow_left</mat-icon
                >
              </orgos-column>
              <orgos-column size="0" class="kenjo-ml-10px"> {{ queryOptions.page }} / {{ numberOfPages }}</orgos-column>
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-ml-10px pp-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== numberOfPages }"
                  (click)="moveToPage(PAGE_SELECTOR['next'])"
                  >keyboard_arrow_right</mat-icon
                >
              </orgos-column>
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-ml-10px pp-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== numberOfPages }"
                  (click)="moveToPage(PAGE_SELECTOR['final'])"
                  >last_page</mat-icon
                >
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
          <orgos-column></orgos-column>
        </orgos-column-container>
      </div>

      <!-- NO DATA YET -->
      <div *ngIf="employeeData.length === 0" class="kenjo-text-align-center">
        <div class="kenjo-empty-state kenjo-text-align-center kenjo-mt-80px kenjo-mb-30px">
          <img src="/assets/images/lama.svg" />
        </div>
        <div class="kenjo-font-size-16px kenjo-mb-20px">{{ i18n.page.noEmployeeDataFound }}</div>
        <div class="pp-not-found-message kenjo-font-color-light-text-757575">{{ i18n.page.complementaryText }}</div>
      </div>
    </div>

    <!-- TIME OFF -->
    <div *ngIf="selectedTab === PAYROLL_TAB_TIME_OFF">
      <div class="kenjo-border kenjo-border-radius kenjo-mt-30px kenjo-mb-50px">
        <orgos-table-smart
          [data]="timeOff.timeOffRecords"
          [totalColumns]="timeOffColumns"
          [displayedColumns]="timeOffColumns"
          [positionRelativeTd]="true"
        >
          <orgos-table-smart-column columnKey="avatar">
            <div *orgosTableSmartCell="let timeOff" [class.pp-confirmed-cell]="isPayrollConfirmed">
              <orgos-avatar
                [photoUrl]="timeOff.photoUrl"
                [avatarName]="timeOff.displayName"
                size="small"
                class="kenjo-ml-5px"
              ></orgos-avatar>
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="firstName" [header]="i18n.page.firstNameColumn" [sortable]="true">
            <div *orgosTableSmartCell="let timeOff" [class.pp-confirmed-cell]="isPayrollConfirmed">{{ timeOff.firstName }}</div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="lastName" [header]="i18n.page.lastNameColumn" [sortable]="true">
            <div *orgosTableSmartCell="let timeOff" [class.pp-confirmed-cell]="isPayrollConfirmed">{{ timeOff.lastName }}</div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="payType" [header]="i18n.page.payTypeColumn" [sortable]="true">
            <div *orgosTableSmartCell="let timeOff" [class.pp-confirmed-cell]="isPayrollConfirmed">
              <orgos-chip
                class="kenjo-ph-10px {{timeOff.payType === TIME_OFF_TYPE_ACTIVITY_TYPE_PAID ? 'pp-chip_paid': 'pp-chip_unpaid'}}"
                [color]="timeOff.payType === TIME_OFF_TYPE_ACTIVITY_TYPE_PAID ? '#EEFCF2': '#FFFAEB'"
                [chip-text]="timeOff.payType === TIME_OFF_TYPE_ACTIVITY_TYPE_PAID ? i18n.page.paidTimeOff: i18n.page.unpaidTimeOff
                  "
              >
                {{ timeOff.payType === TIME_OFF_TYPE_ACTIVITY_TYPE_PAID ? i18n.page.paidTimeOff: i18n.page.unpaidTimeOff }}
              </orgos-chip>
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="timeOffTypeName" [header]="i18n.page.timeOffTypeNameColumn">
            <div *orgosTableSmartCell="let timeOff" [class.pp-confirmed-cell]="isPayrollConfirmed">{{ timeOff.timeOffTypeName }}</div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="period" [header]="i18n.page.periodColumn">
            <div
              *orgosTableSmartCell="let timeOff"
              [class.pp-confirmed-cell]="isPayrollConfirmed"
              [class.pp-payroll-string-column]="timeOff.workingDays && timeOff.workingDays > 1"
            >
              {{ timeOff.from | date: 'shortDate':'UTC' }}
              <span *ngIf="!(timeOff.from | timeCompare : timeOff.to : 'isSameDay')">- {{ timeOff.to | date: 'shortDate':'UTC' }}</span>
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="amount" [header]="i18n.page.amountColumn">
            <div *orgosTableSmartCell="let timeOff" [class.pp-confirmed-cell]="isPayrollConfirmed">
              <div *ngIf="timeOff.workingDays">
                {{ timeOff.workingDays === 1 ? i18n.page.dayText : (i18n.page.daysText | i18nData: { amount: timeOff.workingDays }) }}
              </div>
              <div *ngIf="timeOff.workingHours">
                {{ timeOff.workingHours > 1 ? (i18n.page.hoursText | i18nData: { amount: timeOff.workingHours }) : i18n.page.hourText }}
              </div>
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="attachment" [header]="i18n.page.attachmentColumn">
            <div *orgosTableSmartCell="let timeOff" [class.pp-confirmed-cell]="isPayrollConfirmed">
              <ng-container *ngIf="timeOff">
                <ng-container *ngIf="!timeOff.attachment"> -- </ng-container>

                <ng-container *ngIf="timeOff.attachment">
                  <orgos-column-container
                    *ngIf="timeOff.attachment"
                    (click)="downloadAttachment(timeOff.attachment)"
                    class="kenjo-cursor-pointer"
                    centerColumns="true"
                  >
                    <orgos-document-icon orgosColumn="0" size="small" [extension]="timeOff.attachment.extension" class="kenjo-mr-10px">
                    </orgos-document-icon>
                    <orgos-column class="pp-attachment-text">
                      <a>
                        <kenjo-truncate-text>{{ timeOff.attachment.attachmentOriginalName }}</kenjo-truncate-text>
                      </a>
                    </orgos-column>
                  </orgos-column-container>
                </ng-container>
              </ng-container>
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column *ngIf="timeOffColumns.includes('comment')" columnKey="comment" [header]="i18n.page.commentColumn">
            <kenjo-payroll-comment-cell
              *orgosTableSmartCell="let timeOffData"
              [comment]="timeOffData.comment"
              [i18n]="i18n.page"
              [payrollConfirmed]="isPayrollConfirmed"
              [timeOffData]="timeOffData"
              [periodFrom]="fromDate"
              (commentUpdated)="onCommentUpdated()"
              [settingsId]="selectedSettings._id"
            ></kenjo-payroll-comment-cell>
          </orgos-table-smart-column>
        </orgos-table-smart>
      </div>
    </div>
    <!-- VARIABLE PAYMENTS -->
    <div *ngIf="selectedTab === PAYROLL_TAB_VARIABLE_PAYMENTS">
      <div *ngIf="variablePayments.length > 0" class="kenjo-border kenjo-border-radius kenjo-mt-30px kenjo-mb-50px">
        <orgos-table-smart
          [headerLabels]="[]"
          [data]="variablePayments"
          [totalColumns]="variablePaymentsColumns"
          [displayedColumns]="variablePaymentsColumns"
          [positionRelativeTd]="true"
        >
          <orgos-table-smart-column columnKey="avatar">
            <div *orgosTableSmartCell="let variablePayment" [class.pp-confirmed-cell]="isPayrollConfirmed">
              <orgos-avatar
                [photoUrl]="variablePayment.photoUrl"
                [avatarName]="variablePayment.displayName"
                size="small"
                class="kenjo-ml-5px"
              ></orgos-avatar>
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="firstName" [header]="i18n.page.firstNameColumn" [sortable]="true">
            <div *orgosTableSmartCell="let variablePayment" [class.pp-confirmed-cell]="isPayrollConfirmed">
              {{ variablePayment.firstName }}
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="lastName" [header]="i18n.page.lastNameColumn" [sortable]="true">
            <div *orgosTableSmartCell="let variablePayment" [class.pp-confirmed-cell]="isPayrollConfirmed">
              {{ variablePayment.lastName }}
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="date" [header]="i18n.page.dateColumn">
            <div *orgosTableSmartCell="let variablePayment" [class.pp-confirmed-cell]="isPayrollConfirmed">
              {{ variablePayment.date | humanDate: false: 'short' }}
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="amount" [header]="i18n.page.amountColumn">
            <div *orgosTableSmartCell="let variablePayment" [class.pp-confirmed-cell]="isPayrollConfirmed">
              {{ variablePayment.amount }} ({{ variablePayment.currency }})
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="type" [header]="i18n.page.typeColumn">
            <div *orgosTableSmartCell="let variablePayment" [class.pp-confirmed-cell]="isPayrollConfirmed">{{ variablePayment.type }}</div>
          </orgos-table-smart-column>
          <orgos-table-smart-column
            *ngIf="variablePaymentsColumns.includes('comment')"
            columnKey="comment"
            [header]="i18n.page.commentColumn"
          >
            <kenjo-payroll-comment-cell
              *orgosTableSmartCell="let variablePayment"
              [comment]="variablePayment.comment"
              [i18n]="i18n.page"
              [payrollConfirmed]="isPayrollConfirmed"
              [variablePaymentData]="variablePayment"
              [periodFrom]="fromDate"
              (commentUpdated)="onCommentUpdated()"
              [settingsId]="selectedSettings._id"
            ></kenjo-payroll-comment-cell>
          </orgos-table-smart-column>
        </orgos-table-smart>
      </div>
      <div *ngIf="variablePayments.length === 0" class="kenjo-text-align-center">
        <div class="kenjo-empty-state kenjo-text-align-center kenjo-mt-80px kenjo-mb-30px">
          <img src="/assets/images/lama.svg" />
        </div>
        <div class="kenjo-font-size-16px kenjo-mb-20px">{{ i18n.page.noVariablePaymentsFound }}</div>
        <div class="pp-not-found-message kenjo-font-color-light-text-757575">{{ i18n.page.complementaryText }}</div>
      </div>
    </div>
    <!-- EMPLOYEE DOCS -->
    <div *ngIf="selectedTab === PAYROLL_TAB_EMPLOYEE_DOCS">
      <div class="kenjo-border kenjo-border-radius kenjo-mt-30px kenjo-mb-50px">
        <orgos-table-smart
          [headerLabels]="[]"
          [data]="employeeDocs"
          [totalColumns]="employeeDocsColumns"
          [displayedColumns]="employeeDocsColumns"
          [positionRelativeTd]="true"
        >
          <orgos-table-smart-column columnKey="avatar">
            <div *orgosTableSmartCell="let employeeDoc" [class.pp-confirmed-cell]="isPayrollConfirmed">
              <orgos-avatar
                [photoUrl]="employeeDoc.photoUrl"
                [avatarName]="employeeDoc.displayName"
                size="small"
                class="kenjo-ml-5px"
              ></orgos-avatar>
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="firstName" [header]="i18n.page.firstNameColumn" [sortable]="true">
            <div *orgosTableSmartCell="let employeeDoc" [class.pp-confirmed-cell]="isPayrollConfirmed">{{ employeeDoc.firstName }}</div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="lastName" [header]="i18n.page.lastNameColumn" [sortable]="true">
            <div *orgosTableSmartCell="let employeeDoc" [class.pp-confirmed-cell]="isPayrollConfirmed">{{ employeeDoc.lastName }}</div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="uploadedOn" [header]="i18n.page.uploadedOnColumn">
            <div *orgosTableSmartCell="let employeeDoc" [class.pp-confirmed-cell]="isPayrollConfirmed">
              {{ employeeDoc.uploadedOn | humanDate: false: 'short' }}
            </div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="type" [header]="i18n.page.typeColumn">
            <div *orgosTableSmartCell="let employeeDoc" [class.pp-confirmed-cell]="isPayrollConfirmed">{{ employeeDoc.type }}</div>
          </orgos-table-smart-column>
          <orgos-table-smart-column columnKey="document" [header]="i18n.page.documentColumn">
            <div *orgosTableSmartCell="let employeeDoc" [class.pp-confirmed-cell]="isPayrollConfirmed">
              <orgos-column-container
                *ngIf="employeeDoc"
                (click)="downloadAttachment(employeeDoc)"
                class="kenjo-cursor-pointer"
                centerColumns="true"
              >
                <orgos-document-icon orgosColumn="0" size="small" [extension]="employeeDoc.extension" class="kenjo-mr-10px">
                </orgos-document-icon>
                <orgos-column class="pp-attachment-text">
                  <a>
                    <kenjo-truncate-text>{{ employeeDoc.attachmentName }}</kenjo-truncate-text>
                  </a>
                </orgos-column>
              </orgos-column-container>
            </div>
          </orgos-table-smart-column>
        </orgos-table-smart>
      </div>
    </div>
  </div>
</orgos-container>
<orgos-layout-import-payslips
  *ngIf="uploadedDocuments.length > 0 && documentsType === 'Payslips'"
  [uploadedPayslips]="uploadedDocuments"
  [i18n]="i18n.documents"
  (exit)="uploadedDocuments = []"
>
</orgos-layout-import-payslips>
