import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { IQueryDates } from '@app/standard/components/input-month-picker/input-month-picker.component';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shiftplan-monthly-picker',
  templateUrl: 'shiftplan-monthly-picker.component.html',
  styleUrls: ['shiftplan-monthly-picker.component.scss']
})
export class ShiftplanMonthlyPickerComponent {
  MONTH_VALUES = { BEFORE: -1, CURRENT: 0, AFTER: 1 };
  isValueValid: boolean = false;

  @Input() states: { loading: boolean; dropping: boolean; dragging: boolean };
  @Input() monthRange: { start: moment.Moment; end: moment.Moment };

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public injector: Injector) {}

  onValueChange(monthRange: IQueryDates) {
    const { startDayOfMonth, endDayOfMonth } = monthRange;
    this.valueChange.emit({ from: startDayOfMonth, to: endDayOfMonth });
  }
}
