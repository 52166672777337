import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-shift-plan-paginator',
  templateUrl: 'shift-plan-paginator.component.html',
  styleUrls: ['shift-plan-paginator.component.scss']
})
export class ShiftPlanPaginatorComponent implements OnInit {
  totalOfRecords: number = 0;
  numberOfPages: number = 1;
  MIN_RECORDS_TO_SHOW: number = 5;
  _data: Array<any>;
  _recordsPerPage: number;
  recordsToShowSelector: Array<number> = [this.MIN_RECORDS_TO_SHOW, 10, 20, 40];
  PAGE_SELECTOR: object = {
    first: 1,
    previous: 2,
    next: 3,
    final: 4
  };
  queryOptions: any = {
    page: 1,
    recordsPerPage: 10
  };

  @Input() parentTranslation: any = {};
  @Input()
  set recordsPerPage(value: number) {
    if (check.not.assigned(value) || check.not.number(value) || !this.recordsToShowSelector.includes(value)) {
      return;
    }
    this.queryOptions.recordsPerPage = value;

    if (this.MIN_RECORDS_TO_SHOW === value) {
      this.recordsToShowSelector.length = 1;
    } else {
      this.recordsToShowSelector.shift();
    }
  }
  @Input()
  set data(value: Array<any>) {
    if (check.not.assigned(value) || check.not.array(value)) {
      return;
    }
    this._data = value;
    this.setPagination();
  }

  get data(): Array<any> {
    return this._data;
  }
  @Output() recordsToShow: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  ngOnInit(): void {}

  public changeRecordsToShow(recordsPerPage: number): void {
    this.queryOptions.recordsPerPage = recordsPerPage;
    this.queryOptions.page = 1;
    this.setPagination();
  }

  public moveToPage(option: number): void {
    const currentPagePrev = this.queryOptions.page;
    if (option === this.PAGE_SELECTOR['first']) {
      this.queryOptions.page = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && this.queryOptions.page > 1) {
      this.queryOptions.page--;
    } else if (option === this.PAGE_SELECTOR['next'] && this.queryOptions.page < this.numberOfPages) {
      this.queryOptions.page++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      this.queryOptions.page = this.numberOfPages;
    }
    if (currentPagePrev === this.queryOptions.page) {
      return;
    }

    this.selectRecordsPerPage();
  }

  private setPagination() {
    this.totalOfRecords = this.data.length;
    this.numberOfPages = Math.ceil(this.data.length / this.queryOptions.recordsPerPage);
    this.selectRecordsPerPage();
  }

  private selectRecordsPerPage() {
    const firstRecordIndex = (this.queryOptions.page - 1) * this.queryOptions.recordsPerPage;
    const lastRecordIndex = this.queryOptions.page * this.queryOptions.recordsPerPage;

    const recordsToShow = this.data.slice(firstRecordIndex, lastRecordIndex);
    if (recordsToShow.length === 0) {
      this.recordsToShow.emit(this.data);
    } else {
      this.recordsToShow.emit(recordsToShow);
    }
  }
}
