import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PrivateSignInService } from '@app/private/services/private-sign-in.service';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class SupportHashGuard implements CanActivate {
  constructor(private injector: Injector) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (check.not.assigned(route) || check.not.assigned(route.params) || check.not.assigned(route.params.hash) || check.emptyString(route.params.hash)) {
        this.injector.get(Router).navigate(['/signin']);
        resolve(false);
        return;
      }

      const hash = route.params.hash;

      this.injector
        .get(PrivateSignInService)
        .signInWithSupportHash(hash)
        .then(() => {
          this.injector.get(Router).navigate(['/cloud']);
          resolve(false);
        })
        .catch(() => {
          // An error is already shown if necessary
          this.injector.get(Router).navigate(['/signin']);
          resolve(false);
        });
    });
  }
}
