import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IBreakReminder } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IBreak, IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class BreakReminderController {
  private BREAK_REMINDER_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/attendance/break-reminder`;

  constructor(private injector: Injector) {}

  async getConfiguration(_userId: string, date: Date): Promise<IBreakReminderConfiguration> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).post<IBreakReminderConfiguration>(`${this.BREAK_REMINDER_CONTROLLER_URL}/get-configuration`, { _userId, date }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, BreakReminderController.name, 'getConfiguration');
    }
  }

  async distributeBreaks(_userId: string, date: Date, breaks: Array<IBreak>, _interface: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      await this.injector.get(HttpClient).post(`${this.BREAK_REMINDER_CONTROLLER_URL}/distribute-breaks`, { _userId, date, breaks, _interface }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, BreakReminderController.name, 'distributeBreaks');
    }
  }
}

export interface IBreakReminderConfiguration {
  openReminder: boolean;
  reminder?: IBreakReminder;
  breaks?: Array<IBreak>;
  from?: number;
  to?: number;
  workTime?: number;
  timeOffShifts?: Array<IUserAttendanceModel>;
  canEditCurrentBreaks?: boolean;
  isOvernight?: boolean;
}
