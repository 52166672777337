import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

declare const FreshworksWidget: any;

@Injectable({
  providedIn: 'root',
})
export class PrivateFreshdeskService {
  private FRESHDESK_WIDGET_ID: string = '60000001882';
  private FRESHDESK_TOKEN_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/freshdesk/token`;

  private FRESHDESK_ARTICLE_LIBRARY = {
    datevTutorial: 60000707860,
    attendancePoliciesIntroduction: 60001041220,
    attendancePolicyMethods: 60000953486,
    attendancePolicyLegalDisclaimer: 60000953489,
    attendancePolicyAPI: 60000953486,
    locationTracking: 60000683375,
    reminders: 60000959969,
    kiosk: 60000815808,
    timeOffRequest: 60000709069,
    notificationsTypes: 60000697139,
    permissions: 60001224986,
    shiftRules: 60001224987,
    importWizard: 60000562380,
    deductBreaksAutomatically: 60001163371
  };
  private FRESHDESK_FORM_ID = 60000050978;

  constructor(private injector: Injector) {}

  /**
   * Freshdesk widget is loaded from the cloud component itself, since a user cannot access the freshdesk button if this component is not loaded.
   * If you want to load the widget from somewhere else, just call this method
   */
  public async loadFreshdeskWidget(): Promise<void> {
    try {
      const currentLanguage: string = this.injector.get(InternationalizationService).getLanguage();
      await this.settings(currentLanguage);
      await this.downloadOrBootScript();
      FreshworksWidget('hide', 'launcher');
      await this.setWidgetLabels(currentLanguage);

      const { token: token } = await this.injector.get<any>(HttpClient).post(this.FRESHDESK_TOKEN_URL, {}).toPromise();
      const userProfile: string = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
      if (['admin', 'hr-admin'].includes(userProfile)) {
        FreshworksWidget('authenticate', {
          token: token,
          callback: this.authenticateCallback,
        });
      }
    } catch (error: any) {
      this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(`Error loading Freshdesk script: ${error?.message}`, PrivateFreshdeskService.name, 'loadFreshdeskWidget');
    }
  }

  public async openFreshDeskWidget(): Promise<void> {
    FreshworksWidget('show');
    FreshworksWidget('open');
  }

  public async freshdeskWidgetSignOut(): Promise<void> {
    try {
      FreshworksWidget('logout');
      FreshworksWidget('destroy');
    } catch {
      // do nothing. no need to destroy the widget if it doesn't exist
    }
  }

  public async openRequestFeatureFreshDeskWidget(headerTranslation: string, bodyTranslation: string): Promise<void> {
    FreshworksWidget('show');
    FreshworksWidget('open', 'ticketForm', {
      formId: this.FRESHDESK_FORM_ID,
    });
    FreshworksWidget('prefill', 'ticketForm', { subject: headerTranslation, description: bodyTranslation });
  }

  public openArticle(articleKey: string): void {
    try {
      FreshworksWidget('open', 'article', { id: this.FRESHDESK_ARTICLE_LIBRARY[articleKey] });
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PrivateFreshdeskService.name, 'openArticle');
    }
  }

  // PRIVATE METHODS //
  private settings(currentLanguage: string): Promise<void> {
    const script: HTMLElement = document.getElementById('fd_script_settings');
    if (check.assigned(script)) {
      script.remove();
    }
    return new Promise<void>((resolve, reject) => {
      const firstScriptElement = document.getElementsByTagName('script')[0];
      const freshdeskSettingsScript = document.createElement('script');
      freshdeskSettingsScript.id = 'fd_script_settings';
      freshdeskSettingsScript.innerHTML = `window.fwSettings={'widget_id':60000001882,'locale':'${currentLanguage}'};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
      firstScriptElement.parentNode.insertBefore(freshdeskSettingsScript, firstScriptElement);
      resolve();
    });
  }

  private downloadOrBootScript(): Promise<void> {
    const script: HTMLElement = document.getElementById('fd_script');
    if (check.assigned(script)) {
      FreshworksWidget('boot');
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      const settingsScriptElement: HTMLElement = document.getElementById('fd_script_settings');
      const freshdeskScript: HTMLScriptElement = document.createElement('script');
      freshdeskScript.id = 'fd_script';
      freshdeskScript.type = 'text/javascript';
      freshdeskScript.src = `https://widget.freshworks.com/widgets/${this.FRESHDESK_WIDGET_ID}.js`;
      freshdeskScript.async = true;
      freshdeskScript.defer = true;

      settingsScriptElement.parentNode.append(freshdeskScript, settingsScriptElement);
      freshdeskScript.onload = () => {
        resolve();
      };
      freshdeskScript.onerror = (event, source, lineno, colno, error) => {
        reject(new Error(`Could not load freshdesk script: ${typeof event === 'string' ? event : ''} ${error?.message}`));
      };
    });
  }

  private authenticateCallback(): Function {
    return async () => {
      const { token: token } = await this.injector.get<any>(HttpClient).post(this.FRESHDESK_TOKEN_URL, {}).toPromise();
      FreshworksWidget('authenticate', {
        token: token,
      });
    };
  }

  private async setWidgetLabels(currentLanguage: string): Promise<void> {
    const widgetTranslations = await this.injector.get(InternationalizationService).getAllTranslation('freshdesk-widget');
    const labels = {};
    labels[currentLanguage] = {
      banner: widgetTranslations.banner,
      contactForm: {
        title: widgetTranslations.contactForm.title,
        submit: widgetTranslations.contactForm.submit,
        confirmation: widgetTranslations.contactForm.confirmation,
      },
    };
    FreshworksWidget('setLabels', labels);
  }
}
