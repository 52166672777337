<ng-container *ngIf="pageTranslation && editWorkflowTranslation && !chosenWorkflow && !chosenWorkflowActions && createNewWorkflow === false">
  <div class="kenjo-text-align-right kenjo-mb-20px">
    <orgos-action (click)="createWorkflow()" icon="add_circle" iconSize="large">{{pageTranslation.addWorkflowTooltip}}</orgos-action>
  </div>

  <div *ngIf="!chosenWorkflow && !chosenWorkflowActions && createNewWorkflow === false && listWorkflows && listWorkflows.length === 0" class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{pageTranslation.noWorkflowHeader}}</div>
    <div class="kenjo-mt-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{pageTranslation.noWorkflowDescription}}</div>
  </div>

  <orgos-container aspect="card" *ngIf="!chosenWorkflow && !chosenWorkflowActions && createNewWorkflow === false && listWorkflows && listWorkflows.length > 0">
    <orgos-table [data]="listWorkflows" [displayedColumns]="displayedColumns" [sortingDataAccessor]="sortingDataAccessor" [customHeightToSubstract]="listWorkflows.length > 25 ? 200 : 143" [style.height]="'calc(100vh - 143px)'">
      <orgos-table-column columnKey="name" [header]="objectTranslation.name" [sortable]="true">
        <a *orgosTableCell="let row" (click)="editWorkflow(row)">{{row.name}}</a>
      </orgos-table-column>

      <orgos-table-column columnKey="inCollection" [header]="objectTranslation.inCollection" [sortable]="true">
        <ng-container *orgosTableCell="let row">{{getLabel(row.inCollection)}}</ng-container>
      </orgos-table-column>

      <orgos-table-column columnKey="isActive" [header]="pageTranslation.activeColumnHeader" [sortable]="true">
        <mat-slide-toggle *orgosTableCell="let row" [(ngModel)]="row.isActive" (change)="activateWorkflow(row)"></mat-slide-toggle>
      </orgos-table-column>

      <orgos-table-column columnKey="actions" [actionColumn]="true">
        <ng-container *orgosTableCell="let row">
          <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert</kenjo-icon>
          <mat-menu #actionsMenu="matMenu">
            <button mat-menu-item (click)="editWorkflow(row)">
              <span>{{pageTranslation.editButtonLabel}}</span>
            </button>
            <button mat-menu-item (click)="editWorkflowActions(row)">
              <span>{{pageTranslation.editActionsButtonLabel}}</span>
            </button>
            <button *ngIf="row.isStandard !== true" mat-menu-item (click)="deleteWorkflow(row)">
              <span>{{pageTranslation.deleteButtonLabel}}</span>
            </button>
          </mat-menu>
        </ng-container>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>
</ng-container>

<orgos-edit-workflow *ngIf="customFieldsAvailable && (chosenWorkflow || createNewWorkflow === true)" [workflow]="chosenWorkflow" [customFieldsAvailable]="customFieldsAvailable" (goBack)="resetChosenWorkflow()" (create)="addActionsToNewWorkflow($event)"></orgos-edit-workflow>

<orgos-workflow-summary *ngIf="chosenWorkflowActions && customFieldsAvailable" [workflow]="chosenWorkflowActions" [customFieldsAvailable]="customFieldsAvailable" (goBack)="resetChosenWorkflowActions()" [chosenCollection]="chosenWorkflowActions['data'].inCollection"></orgos-workflow-summary>
