import { AnimationEvent, animate, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ITask } from '@app/models/task.model';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeWhile } from 'rxjs/operators';

import { InternationalizationService } from '../../services/core/internationalization.service';
import { TaskHelperService } from '../../services/task/task-helper.service';
import { TaskService } from '../../services/task/task.service';
import { SearchFunction } from '../search/search.component';

const PULSE_OFFSET_EASEIN = 15;
const PULSE_OFFSET_EASEOUT = 300;
const PULSE_OFFSET_DELAY = 800;

const ANIMATION_DURATION_MS = PULSE_OFFSET_EASEIN + PULSE_OFFSET_DELAY + PULSE_OFFSET_EASEOUT;
const PULSE_OFFSET_START = PULSE_OFFSET_EASEIN / ANIMATION_DURATION_MS;
const PULSE_OFFSET_END = (PULSE_OFFSET_EASEIN + PULSE_OFFSET_DELAY) / ANIMATION_DURATION_MS;

@Component({
  selector: 'orgos-task',
  templateUrl: 'task.component.html',
  styleUrls: ['task.component.scss'],
  animations: [
    trigger('checkingTask', [
      state(
        'unchecked',
        style({
          background: '#ffffff',
        })
      ),
      state(
        'checked',
        style({
          background: '#ffffff',
        })
      ),
      transition('unchecked => checked', [
        group([
          animate(
            // animate green background
            `${ANIMATION_DURATION_MS}ms ease-in`,
            keyframes([
              style({ background: '#ffffff', offset: 0 }),
              style({ background: '#E9FEEE', offset: PULSE_OFFSET_START }),
              style({ background: '#E9FEEE', offset: PULSE_OFFSET_END }),
              style({ background: '#ffffff', offset: 1 }),
            ])
          ),
          // green left border
          query('.tc-animate-border', [animate('150ms ease-in', style({ opacity: 1, 'background-color': 'rgb(0, 183, 46)' }))]),
          // inputs
          query('.tc-animate-input', [
            animate(
              `${ANIMATION_DURATION_MS}ms ease-in`,
              keyframes([
                style({ background: '#ffffff', border: '1px solid rgba(255, 255, 255, 0)', offset: 0 }),
                style({ background: '#E9FEEE', border: '1px solid rgba(255, 255, 255, 0)', offset: PULSE_OFFSET_START }),
                style({ background: '#E9FEEE', border: '1px solid rgba(255, 255, 255, 0)', offset: PULSE_OFFSET_END }),
                style({ background: '#ffffff', border: '1px solid rgba(255, 255, 255, 0)', offset: 1 }),
              ])
            ),
          ]),
          query('.tc-animate-date', [
            animate(
              `${ANIMATION_DURATION_MS}ms ease-in`,
              keyframes([
                style({ background: '#ffffff', border: '1px solid rgba(255, 255, 255, 0)', offset: 0 }),
                style({ background: '#E9FEEE', border: '1px solid rgba(255, 255, 255, 0)', offset: PULSE_OFFSET_START }),
                style({ background: '#E9FEEE', border: '1px solid rgba(255, 255, 255, 0)', offset: PULSE_OFFSET_END }),
                style({ background: '#ffffff', border: '1px solid rgba(255, 255, 255, 0)', offset: 1 }),
              ])
            ),
          ]),
          // green check button
          query('.icon', [
            animate(
              `${ANIMATION_DURATION_MS}ms ease-in`,
              keyframes([
                style({ transform: 'scale(1)', offset: 0 }),
                style({ transform: 'scale(1.5)', offset: PULSE_OFFSET_START }),
                style({ transform: 'scale(1.5)', offset: PULSE_OFFSET_END }),
                style({ transform: 'scale(1)', offset: 1 }),
              ])
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class TaskComponent implements OnInit, OnDestroy {
  i18n: any = {};
  editTitle: boolean = false;
  today: moment.Moment = moment();
  moment: any = moment;
  searchUserOverlay: OverlayRef;
  searchResults: Array<any> = [];
  allActiveUserPersonal: Array<any> = [];
  hasEditPermission: boolean = false;
  hasDeletePermission: boolean = false;
  isHoveringCheckMark: boolean = false;
  isHoveringDelete: boolean = false;
  // prevents tasks reload from triggering before task update
  previousCompletedValue: boolean = false;
  animationOngoing: boolean = false;
  TASK_TITLE_TRUNCATE_AFTER: number = 40;
  isOwner: boolean;

  mapUserPersonalById: any = {};
  mapCandidatesById: any = {};
  mapPositionCandidateById: any = {};
  mapMeetingsById: any = {};
  mapDocumentsById: any = {};

  workflowName: any;
  creator: any;
  assignee: any;
  relatedToEmployee: any;
  relatedToPositionCandidate: any;
  relatedToMeeting: any;
  relatedToDocument: any;

  private taskUpdateDebounced: EventEmitter<any> = new EventEmitter<any>();
  private keepSubscriptions: boolean = true;
  private pendingUpdate: boolean = false;

  @Input() task: any;
  @Input() showRelatedTo: boolean = true;
  @Input()
  set readOnly(readOnly: boolean) {
    this.readOnlyStatus = readOnly;
    this.readOnlyTitle = readOnly;
    this.readOnlyDueDate = readOnly;
    this.readOnlyAssignee = readOnly;
    this.disableDeletion = readOnly;
  }
  @Input() readOnlyStatus: boolean = false;
  @Input() readOnlyTitle: boolean = false;
  @Input() readOnlyDueDate: boolean = false;
  @Input() readOnlyAssignee: boolean = false; // Set to true if the user can only see his own tasks
  @Input() disableDeletion: boolean = false;
  @Input() showFullTitleInReadOnly: boolean = false;

  @Output() readonly taskChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly taskCompleted: EventEmitter<any> = new EventEmitter<ITask>();
  @Output() readonly taskDeleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly animationComplete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(CdkPortal) searchPortal: Portal<any>;

  constructor(private injector: Injector, private overlay: Overlay, private router: Router) {}

  ngOnInit(): void {
    if (!this.task) {
      return;
    }

    this.isOwner = this.injector.get(AuthenticationService).getLoggedUser()._id === this.task.ownerId;

    this.initTranslations();

    this.injector
      .get(TaskHelperService)
      .getTaskPermissions()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((taskPermissions: any) => {
        const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
        this.hasEditPermission = taskPermissions.edit_all || (taskPermissions.edit_own && this.task.ownerId === loggedUser._id);
        // can only delete tasks the user has created, not assigned
        this.hasDeletePermission = taskPermissions.delete_all || (taskPermissions.delete_own && this.task._createdById === loggedUser._id);
      });

    this.injector
      .get(TaskHelperService)
      .getMapUserPersonalById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapUserPersonalById: any) => {
        this.mapUserPersonalById = mapUserPersonalById;
        this.refreshData();
      });

    this.injector
      .get(TaskHelperService)
      .getAllActiveUserPersonal()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((allActiveUserPersonal: Array<any>) => {
        this.allActiveUserPersonal = allActiveUserPersonal;
      });

    this.injector
      .get(TaskHelperService)
      .getMapCandidatesById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapCandidatesById: any) => {
        this.mapCandidatesById = mapCandidatesById;
      });

    this.injector
      .get(TaskHelperService)
      .getMapPositionCandidateById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapPositionCandidateById: any) => {
        this.mapPositionCandidateById = mapPositionCandidateById;
        this.refreshData();
      });

    this.injector
      .get(TaskHelperService)
      .getMapMeetingsById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapMeetingsById: any) => {
        this.mapMeetingsById = mapMeetingsById;
        this.refreshData();
      });

    this.injector
      .get(TaskHelperService)
      .getMapDocumentsById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapDocumentsById: any) => {
        this.mapDocumentsById = mapDocumentsById;
        this.refreshData();
      });

    this.taskUpdateDebounced
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        debounce((taskChange: any) => {
          const time = taskChange.field === 'title' ? 2000 : 0;

          return timer(time);
        }),
        distinctUntilChanged((taskChangeA: any, taskChangeB: any) => {
          return _.isEqual(taskChangeA, taskChangeB);
        })
      )
      .subscribe(() => {
        this.updateTask();
        this.pendingUpdate = false;
      });
  }

  ngOnDestroy(): void {
    this.keepSubscriptions = false;

    if (this.pendingUpdate === true) {
      this.updateTask();
    }
  }

  searchAssignee(assigneeElement: ElementRef): void {
    if (!this.hasEditPermission || this.readOnlyAssignee) {
      return;
    }

    const searchUserOverlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
    };
    searchUserOverlayConfig.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(assigneeElement)
      .withPositions([
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
        { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' },
      ])
      .withPush(false);

    this.searchUserOverlay = this.overlay.create(searchUserOverlayConfig);
    this.searchUserOverlay.attach(this.searchPortal);
    this.searchUserOverlay.backdropClick().subscribe(() => {
      this.searchUserOverlay.dispose();
    });
  }

  selectAssignee(newAssignee: any): void {
    this.changeTask('ownerId', newAssignee._id);
    this.searchUserOverlay.dispose();
  }

  public searchAssigneeFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      return Promise.resolve(this.allActiveUserPersonal);
    }

    const results = this.allActiveUserPersonal.filter((iUserPersonal: any) => {
      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return regExp.test(iUserPersonal.displayName);
    });

    return Promise.resolve(results);
  };

  mouseOverIcon(): void {
    if (this.readOnlyStatus) {
      return;
    }
    this.isHoveringCheckMark = true;
  }

  mouseOutIcon(): void {
    if (this.readOnlyStatus) {
      return;
    }
    this.isHoveringCheckMark = false;
  }

  changeTask(field: string, newValue: any): void {
    if (this.hasEditPermission === false || this.readOnlyStatus === true) {
      return;
    }
    this.previousCompletedValue = this.task.isCompleted;
    this.pendingUpdate = true;

    this.taskUpdateDebounced.emit({
      field: field,
      newValue: newValue,
    });

    this.task[field] = newValue;
  }

  async onDeleteTask(): Promise<void> {
    try {
      const getTaskDeleteTranslations = this.injector.get(InternationalizationService).getAllTranslation('task-delete-dialog');
      const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');
      const [taskDeleteTranslation, globalMiscTranslation] = await Promise.all([getTaskDeleteTranslations, getGlobalMiscTranslations]);

      const possiblyTruncatedTitle: string =
        this.task.title.length > this.TASK_TITLE_TRUNCATE_AFTER
          ? `'${this.task.title.slice(0, this.TASK_TITLE_TRUNCATE_AFTER)}...'`
          : `'${this.task.title}'`;
      const displayTaskTitle = this.injector
        .get(I18nDataPipe)
        .transform(taskDeleteTranslation.dialogHeaderDynamic, { title: possiblyTruncatedTitle });
      const snackBarText = this.injector.get(I18nDataPipe).transform(this.i18n.taskDeletedSnackText, { title: possiblyTruncatedTitle });

      const data = {
        titleText: displayTaskTitle,
        subtitleText: taskDeleteTranslation.warningMessageText,
        cancelButtonText: globalMiscTranslation.goBackButtonDialog,
        confirmButtonText: taskDeleteTranslation.deleteButtonLabel,
        confirmButtonColor: 'Danger',
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe((deleteTask: boolean) => {
        if (check.not.assigned(deleteTask) || deleteTask === false) {
          return;
        }
        this.deleteTask(snackBarText);
      });
    } catch {
      // Do nothing
    }
  }

  async changeTaskStatus(newStatus: boolean): Promise<void> {
    if (this.readOnlyStatus) {
      return;
    }
    if (newStatus === true) {
      this.animationOngoing = true;
    }
    await this.injector.get(TaskService).updateStatus(this.task, newStatus);

    this.task['isCompleted'] = newStatus;
    this.refreshData();
    this.injector.get(MatLegacySnackBar).open(this.i18n.taskUpdatedSnackText, 'OK', {
      duration: 5000,
    });
  }

  private async updateTask(): Promise<void> {
    if (check.not.assigned(this.task)) {
      return;
    }

    try {
      await this.injector.get(TaskService).updateById(this.task._id, this.task);
      this.refreshData();
      this.injector.get(MatLegacySnackBar).open(this.i18n.taskUpdatedSnackText, 'OK', {
        duration: 5000,
      });
      if (this.previousCompletedValue === false && this.task.isCompleted === true) {
        this.taskCompleted.emit(this.task);
      }
    } catch {
      // An error is already shown.
    }
  }

  private async deleteTask(snackBarText: string): Promise<void> {
    try {
      await this.injector.get(TaskService).deleteById(this.task._id);
      this.task = null;
      this.taskDeleted.emit();
      this.injector.get(MatLegacySnackBar).open(snackBarText, 'OK', {
        duration: 5000,
      });
    } catch {
      // An error is already shown.
    }
  }

  private refreshData(): void {
    if (!this.task) {
      return;
    }

    this.workflowName = null;
    this.creator = null;
    this.assignee = null;
    this.relatedToEmployee = null;
    this.relatedToPositionCandidate = null;
    this.relatedToMeeting = null;
    this.relatedToDocument = null;

    if (this.task.workflowName) {
      this.workflowName = this.task.workflowName;
    } else {
      this.creator = this.mapUserPersonalById[this.task._createdById];
    }

    this.assignee = this.mapUserPersonalById[this.task.ownerId];

    this.relatedToEmployee = this.mapUserPersonalById[this.task.relatedTo];
    this.relatedToPositionCandidate = this.mapPositionCandidateById[this.task.relatedTo];
    this.relatedToMeeting = this.mapMeetingsById[this.task.relatedTo];
    this.relatedToDocument = this.mapDocumentsById[this.task.relatedTo];
  }

  private async initTranslations() {
    try {
      this.i18n = await this.injector.get(InternationalizationService).getAllTranslation('task-component');
    } catch {
      this.i18n = {};
    }
  }

  public navigateToPositionCandidate(): void {
    const positionCandidatePageURL = check.assigned(this.relatedToPositionCandidate)
      ? `cloud/recruiting/candidate/${this.relatedToPositionCandidate._candidateId}/${this.relatedToPositionCandidate._positionId}`
      : '';
    this.router.navigateByUrl(positionCandidatePageURL);
  }

  public navigateToMeeting(): void {
    const meetingPageURL = check.assigned(this.relatedToMeeting) ? `cloud/meetings/${this.relatedToMeeting._id}` : '';
    this.router.navigateByUrl(meetingPageURL);
  }

  public navigateToDocument(): void {
    const documentPageURL = check.assigned(this.relatedToDocument) ? `cloud/documents/${this.relatedToDocument._id}` : '';
    this.router.navigateByUrl(documentPageURL);
  }

  animationFinished(event: AnimationEvent): void {
    // callback fires on every state, even on those we don't define. it's intended, terrible and unintuitive: https://github.com/angular/angular/issues/23535#issuecomment-1013730138
    if (event.fromState === 'unchecked' && event.toState === 'checked') {
      this.animationOngoing = false;
      this.animationComplete.emit();
    }
  }
}
