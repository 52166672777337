import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { InputValidationFunction } from '@app/standard/core/validation/input-validation-function';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { InputValidation } from '../../core/validation/input-validation';

@Component({
  selector: 'kenjo-input-date-range-picker',
  templateUrl: 'input-date-range-picker.component.html',
  styleUrls: ['input-date-range-picker.component.scss']
})
export class InputDateRangePickerComponent implements OnInit {
  isValueValid: boolean = true;
  startAt: Date = this.injector.get(InternationalizationService).getSanitizedUTCToTimeZone().toDate();
  @Input() label: string;
  @Input() required: boolean | 'true' | 'false' = false;
  @Input() readOnly: boolean | 'true' | 'false' = false;
  @Input() savedHint: boolean | 'true' | 'false';
  @Input() debounceNewValues: boolean | 'true' | 'false';
  @Input() value = { from: undefined, to: undefined };
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Input() max: Date | number;
  @Input() min: Date | number;
  @Input() customFieldDocument: any;
  @Input() enableClearButton: boolean = false;
  @Input() fromPlaceholder: string = '';
  @Input() toPlaceholder: string = '';

  @Input() showMenu: boolean = true;

  @Input()
  menuOptions: string;
  @Output() menuChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('datepicker', { static: false }) datepicker: MatDateRangePicker<Date>;

  private isClearing: boolean = false;

  public showClearButton: boolean = false;

  public rangeValue = { from: undefined, to: undefined };

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.makeValidation(this.value);

    this.showClearButton = (this.enableClearButton && check.assigned(this.value.from)) || check.assigned(this.value?.to);
  }

  onValueChange(newDate: moment.Moment, type: 'from' | 'to'): void {
    if (this.isClearing) {
      return;
    }

    if (type === 'from') {
      this.rangeValue.from = check.assigned(newDate) ? newDate.toISOString() : null;
      this.rangeValue.to = null;
    }

    if (type === 'to') {
      this.rangeValue.to = check.assigned(newDate) ? newDate.toISOString() : null;
    }

    this.makeValidation(newDate);

    if (this.isValueValid) {
      this.value = _.clone(this.rangeValue);
      if (check.assigned(this.rangeValue.from) && check.assigned(this.rangeValue.to)) {
        this.valueChange.emit(this.rangeValue);
      }
    }
  }

  openDatepicker(): void {
    if (this.readOnly === false) {
      this.datepicker.open();
    }
  }

  private makeValidation(valueToValidate: { from: any; to: any }): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: { from: any; to: any }): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue.from) || check.not.assigned(newValue?.to))) {
      inputValidation.setError('required');
    }

    if (check.assigned(this.customValidation)) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  clearValue(): void {
    this.rangeValue = { from: null, to: null };

    this.valueChange.emit({ from: null, to: null });

    this.isClearing = true;

    setTimeout(() => (this.isClearing = false), 0);
  }

  onClose(): void {}

  public menuChangeHandler(value: string) {
    this.menuChange.emit(value);
  }
}
