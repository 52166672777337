import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyInput } from '@angular/material/legacy-input';
import * as check from 'check-types';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { GenericCacheModel } from '../../core/generic-cache-model';
import { GenericSimpleModel } from '../../core/generic-simple-model';
import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';
import { InternationalizationService } from '../../services/core/internationalization.service';
import { ErrorManagerService } from '../../services/error/error-manager.service';

@Component({
  template: '',
})
export class InputAbstractComponent implements OnInit, OnDestroy, AfterViewInit {
  showSavedHint: boolean = false;
  isValueValid: boolean = true;
  miscTranslation: any = {};

  private isComponentDestroyed: boolean = false;
  private newValueDebounced: Subject<any> = new Subject<any>();

  private timeouts: Array<number> = [];
  private inputInitialized: boolean = false;

  private clearingValue: boolean = false;

  private _model: GenericSimpleModel | GenericCacheModel;
  @Input()
  set model(model: GenericSimpleModel | GenericCacheModel) {
    this._model = model;

    if (this.inputInitialized === true) {
      this.resetInput();
    }
  }
  get model(): GenericSimpleModel | GenericCacheModel {
    return this._model;
  }

  private _value: any;
  @Input()
  set value(value: any) {
    if (this.inputInitialized === true) {
      return;
    }

    this._value = value;
  }
  get value(): any {
    return this._value;
  }

  private _field: string;
  @Input()
  set field(field: string) {
    if (this.inputInitialized === true) {
      return;
    }

    this._field = field;
  }
  get field(): string {
    return this._field;
  }

  private _prefix: string;
  @Input()
  set prefix(prefix: string) {
    this._prefix = prefix;
    this.prefixClass = check.assigned(prefix) && check.not.emptyString(prefix);
  }
  get prefix(): string {
    return this._prefix;
  }

  private _suffix: string;
  @Input()
  set suffix(suffix: string) {
    this._suffix = suffix;
    this.suffixClass = check.assigned(suffix) && check.not.emptyString(suffix);
  }
  get suffix(): string {
    return this._suffix;
  }

  private _suffixIcon: string;
  @Input()
  set suffixIcon(suffixIcon: string) {
    this._suffixIcon = suffixIcon;
  }
  get suffixIcon(): string {
    return this._suffixIcon;
  }

  @Input() autoFocus: boolean;
  @Input() label: string;
  @Input() required: boolean | 'true' | 'false' = false;
  @Input() readOnly: boolean | 'true' | 'false' = false;
  @Input() savedHint: boolean | 'true' | 'false';
  @Input() trimValue: boolean | 'true' | 'false';
  @Input() debounceNewValues: boolean | 'true' | 'false';
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() max: Date | number;
  @Input() min: Date | number;
  @Input() customValidation: InputValidationFunction;
  @Input() customFieldDocument: any;
  @Input() id: string = '';
  @Input() suffixIconTooltip: string = '';
  @Output() currentValue: EventEmitter<any> = new EventEmitter<void>();
  @Output() modelChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Output() updateError: EventEmitter<void> = new EventEmitter<void>();
  @Output() suffixIconClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatLegacyInput) matInput: MatLegacyInput;

  private _makeValidationOnDirty: boolean = false;
  @Input()
  set makeValidationOnDirty(makeValidationOnDirty: boolean) {
    this.errorsOnTouched = makeValidationOnDirty === false;
    this.errorsOnDirty = makeValidationOnDirty === true;

    this._makeValidationOnDirty = makeValidationOnDirty;
  }
  get makeValidationOnDirty(): boolean {
    return this._makeValidationOnDirty;
  }

  @HostBinding('class.iac-errors-on-touched') errorsOnTouched: boolean = true;
  @HostBinding('class.iac-errors-on-dirty') errorsOnDirty: boolean = false;
  @HostBinding('class.iac-prefix') prefixClass: boolean = false;
  @HostBinding('class.iac-suffix') suffixClass: boolean = false;

  constructor(protected cdr: ChangeDetectorRef, protected injector: Injector) {}

  ngOnInit(): void {
    this.inputInitialized = true;

    if (check.not.assigned(this.model) || check.not.assigned(this.field)) {
      const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, InputAbstractComponent.name, 'ngOnInit');
      error.message = `Non-simple inputs must be initialized with model and field. Model: ${this.model}. Field: ${this.field}.`;
      this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);
      return;
    }

    if (
      check.not.assigned(this.label) &&
      check.assigned(this.customFieldDocument) &&
      check.object(this.customFieldDocument) &&
      check.nonEmptyObject(this.customFieldDocument)
    ) {
      this.label = this.customFieldDocument.fieldLabel;
    } else if (check.not.assigned(this.label)) {
      this.model
        .getTranslation()
        .then((fieldsTranslation) => {
          this.label = fieldsTranslation[this.field];
        })
        .catch(() => {
          // An error is already shown

          // Set the field api name in order to minimize the problem.
          this.label = this.field;
        });
    }

    if (!this.readOnly) {
      this.model.canEdit
        .then((canEdit) => {
          this.readOnly = canEdit ? false : true;
          if (!canEdit && (check.not.assigned(this.model.data._id) || check.emptyString(this.model.data._id))) {
            this.model.canCreate
              .then((canCreate) => {
                this.readOnly = canCreate ? false : true;
              })
              .catch(() => {
                this.readOnly = true;
              });
          }
        })
        .catch(() => {
          this.readOnly = true;
        });
    }

    if (check.not.assigned(this.savedHint)) {
      this.savedHint = check.instanceStrict(this.model, GenericSimpleModel);
    }

    if (check.not.assigned(this.debounceNewValues)) {
      this.debounceNewValues = check.instanceStrict(this.model, GenericSimpleModel);
    }

    if (this.debounceNewValues === true || this.debounceNewValues === 'true') {
      this.newValueDebounced
        .pipe(
          debounce(() => {
            // If we are clearing the value, we do not delay the operation
            const time = this.clearingValue ? 0 : 2000;
            this.clearingValue = false;

            return timer(time);
          }),
          distinctUntilChanged()
        )
        .subscribe((newValue) => {
          this.processNewValue(newValue);
        });
    }

    if (check.assigned(this.getValue())) {
      this.forceValidation();
    }
    this.currentValue.emit(this.getValue());

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((translation) => {
        this.miscTranslation = translation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  private resetInput(): void {
    this.isValueValid = true;
    if (check.assigned(this.matInput)) {
      this.matInput.ngControl.control.markAsPristine();
      this.matInput.ngControl.control.markAsUntouched();
    }

    if (check.assigned(this.getValue())) {
      this.forceValidation();
    }
  }

  ngAfterViewInit(): void {
    if (check.assigned(this.matInput)) {
      if (this.autoFocus) {
        this.matInput.focus();
      }
      this.matInput.errorState =
        !this.isValueValid && (this.makeValidationOnDirty ? this.matInput.ngControl.dirty : this.matInput.ngControl.touched);
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.isComponentDestroyed = true;
    this.newValueDebounced.complete();

    this.timeouts.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  }

  getValue(): any {
    if (this.value) {
      return this.value[this.field];
    }

    return this.model.data[this.field];
  }

  protected validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if ((this.required === true || this.required === 'true') && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (check.assigned(newValue) && check.assigned(this.minlength) && newValue.length < this.minlength) {
      inputValidation.setError('minlength');
    }

    if (check.assigned(newValue) && check.assigned(this.maxlength) && newValue.length > this.maxlength) {
      inputValidation.setError('maxlength');
    }

    if (check.assigned(newValue) && check.assigned(this.min) && newValue < this.min) {
      inputValidation.setError('min');
    }

    if (check.assigned(newValue) && check.assigned(this.max) && newValue > this.max) {
      inputValidation.setError('max');
    }

    if (check.assigned(this.customValidation)) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  forceValidation(): void {
    this.makeValidation(this.getValue());
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  setValue(newValue: any): void {
    if (this.readOnly === true || this.readOnly === 'true') {
      return;
    }

    this.currentValue.emit(newValue);

    this.makeValidation(newValue);

    if (this.debounceNewValues === true || this.debounceNewValues === 'true') {
      this.newValueDebounced.next(newValue);
    } else {
      this.processNewValue(newValue);
    }
  }

  clearValue(): void {
    this.clearingValue = true;
    this.setValue(null);
  }

  private processNewValue(newValue: any): void {
    this.makeValidation(newValue);

    if (this.isValueValid === false) {
      return;
    }

    if (this.value) {
      this.value[this.field] = this.trimValue ? newValue.trim() : newValue;
    } else {
      this.model.data[this.field] = this.trimValue ? newValue.trim() : newValue;
    }

    this.currentValue.emit(this.trimValue ? newValue.trim() : newValue);

    this.update();
  }

  private update(): void {
    this.model
      .update()
      .then(() => {
        this.modelChange.emit();
        this.activateSavedHint();
      })
      .catch(() => {
        this.updateError.emit();
      });
  }

  private activateSavedHint(): void {
    if (
      this.isComponentDestroyed === true ||
      check.not.assigned(this.savedHint) ||
      this.savedHint === false ||
      this.savedHint === 'false'
    ) {
      return;
    }

    this.showSavedHint = true;
    this.cdr.detectChanges();

    const timeoutId: number = window.setTimeout(() => {
      this.showSavedHint = false;
      this.cdr.detectChanges();
    }, 1000);

    this.timeouts.push(timeoutId);
  }

  forceActivateSavedHint(): void {
    const oldSavedHint = this.savedHint;
    this.savedHint = true;
    this.activateSavedHint();
    this.savedHint = oldSavedHint;
  }
}
