import { Component, HostBinding, Input, OnInit } from '@angular/core';
import * as userColorsConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['chip.component.scss'],
})
export class ChipComponent implements OnInit {
  nameInitials: string;

  @Input()
  set color(
    color:
      | 'Neutral'
      | 'Success'
      | 'SuccessSecondaryType'
      | 'Primary'
      | 'PrimarySecondaryType'
      | 'Caution'
      | 'CautionSecondaryType'
      | 'Warning'
      | 'WarningSecondaryType'
      | 'Danger'
      | 'DangerSecondaryType'
      | 'Archived'
      | 'Disabled'
      | 'New'
      | string
  ) {
    this.neutralColorClass = false;
    this.successColorClass = false;
    this.successColorClassSecondaryType = false;
    this.primaryColorClass = false;
    this.primaryColorClassSecondaryType = false;
    this.secondaryColorClass = false;
    this.cautionColorClass = false;
    this.cautionColorClassSecondaryType = false;
    this.warningColorClass = false;
    this.warningColorClassSecondaryType = false;
    this.dangerColorClass = false;
    this.dangerColorClassSecondaryType = false;
    this.archivedColorClass = false;
    this.disabledColorClass = false;
    this.newColorClass = false;
    this.backgroundColorStyle = '';

    if (color === 'Neutral') {
      this.neutralColorClass = true;
    } else if (color === 'Success') {
      this.successColorClass = true;
    } else if (color === 'SuccessSecondaryType') {
      this.successColorClassSecondaryType = true;
    } else if (color === 'Primary') {
      this.primaryColorClass = true;
    } else if (color === 'PrimarySecondaryType') {
      this.primaryColorClassSecondaryType = true;
    } else if (color === 'Secondary') {
      this.secondaryColorClass = true;
    } else if (color === 'Caution') {
      this.cautionColorClass = true;
    } else if (color === 'CautionSecondaryType') {
      this.cautionColorClassSecondaryType = true;
    } else if (color === 'Warning') {
      this.warningColorClass = true;
    } else if (color === 'WarningSecondaryType') {
      this.warningColorClassSecondaryType = true;
    } else if (color === 'Danger') {
      this.dangerColorClass = true;
    } else if (color === 'DangerSecondaryType') {
      this.dangerColorClassSecondaryType = true;
    } else if (color === 'Archived') {
      this.archivedColorClass = true;
    } else if (color === 'Disabled') {
      this.disabledColorClass = true;
    } else if (color === 'New') {
      this.newColorClass = true;
    } else if (check.assigned(color) && color.startsWith('#') && (color.length === 4 || color.length === 7)) {
      this.backgroundColorStyle = color;
    } else if (check.assigned(userColorsConstants[color])) {
      this.backgroundColorStyle = userColorsConstants[color];
      this.isWhiteColor = true;
    } else {
      this.neutralColorClass = true;
    }
  }

  @Input() imageUrl: string;
  @Input() buttonIconName: string;
  @Input() onClickButtonFunction: OnClickButtonFunction;
  @Input() addInitialsIfNoImage: boolean = false;
  @Input() chipText: string;
  @Input() hoverButton: boolean = false;
  @HostBinding('class.cc-neutral-color') neutralColorClass: boolean = true;
  @HostBinding('class.cc-success-color') successColorClass: boolean = false;
  @HostBinding('class.cc-success-color-secondary-type') successColorClassSecondaryType: boolean = false;
  @HostBinding('class.cc-primary-color') primaryColorClass: boolean = false;
  @HostBinding('class.cc-primary-color-secondary-type') primaryColorClassSecondaryType: boolean = false;
  @HostBinding('class.cc-secondary-color') secondaryColorClass: boolean = false;
  @HostBinding('class.cc-caution-color') cautionColorClass: boolean = false;
  @HostBinding('class.cc-caution-color-secondary-type') cautionColorClassSecondaryType: boolean = false;
  @HostBinding('class.cc-warning-color') warningColorClass: boolean = false;
  @HostBinding('class.cc-warning-color-secondary-type') warningColorClassSecondaryType: boolean = false;
  @HostBinding('class.cc-danger-color') dangerColorClass: boolean = false;
  @HostBinding('class.cc-danger-color-secondary-type') dangerColorClassSecondaryType: boolean = false;
  @HostBinding('class.cc-archived-color') archivedColorClass: boolean = false;
  @HostBinding('class.cc-disabled-color') disabledColorClass: boolean = false;
  @HostBinding('class.cc-new-color') newColorClass: boolean = false;
  @HostBinding('style.background-color') backgroundColorStyle: string = '';
  isWhiteColor: boolean = false;

  ngOnInit(): void {
    if (this.addInitialsIfNoImage === true) {
      this.calculateInitials();
    }
  }

  onClick(event: Event): void {
    if (check.assigned(this.onClickButtonFunction) && check.function(this.onClickButtonFunction)) {
      this.onClickButtonFunction(event);
    }
  }

  private calculateInitials(): void {
    if (check.not.assigned(this.chipText) || check.not.string(this.chipText)) {
      this.nameInitials = '-';
      return;
    }

    const avatarName = this.chipText.trim();

    if (check.emptyString(avatarName)) {
      this.nameInitials = '-';
      return;
    }

    const words = avatarName.trim().split(' ');
    if (check.not.array(words) || check.emptyArray(words) || words.length < 2) {
      this.nameInitials = `${avatarName.charAt(0)}${avatarName.charAt(0)}`.toUpperCase();
      return;
    }

    this.nameInitials = `${words[0].trim().charAt(0)}${words[1].trim().charAt(0)}`.toUpperCase();
  }
}

export type OnClickButtonFunction = (event?: Event) => void;
