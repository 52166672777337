<div *ngIf="i18n.page && parentTranslation">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575 sspn-flex">{{parentTranslation.generalHeader}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{parentTranslation.notificationsSettings}}</span></div>
  <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
    <orgos-column size="1" class="kenjo-mr-40px">
      <div class="kenjo-mb-20px kenjo-font-size-16px">{{i18n.page.shiftUpdates}}</div>
      <div class="kenjo-font-color-light-text-757575">{{i18n.page.shiftUpdatesText}}</div>
    </orgos-column>
    <orgos-column size="1" class="kenjo-ml-40px">
      <!-- TODO Also do that for shift plan rules section to break time deduction toggle when it is implemented -->
      <mat-slide-toggle
        [disabled]="settingPermissions?.edit_all === false"
        [checked]="generalSettings.sendNotifications"
        (change)="updateNotification($event.checked)"
        [matTooltipDisabled]="settingPermissions?.edit_all === true"
        [matTooltip]="parentTranslation.noPermissionsTooltip"
        matTooltipPosition="below"
      ></mat-slide-toggle>
    </orgos-column>
  </orgos-column-container>
</div>
