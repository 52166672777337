import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanEmployeeListService implements IGenericService {
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan/employee`;
  private USER_WORK_PERMISSIONS_KEY: string = 'user-work';
  private USER_WORK_INTERNATIONALIZATION: string = 'user-work-collection';
  private SHIFTPLAN_EMPLOYEE_LIST_SERVICE: string = 'ShiftPlanEmployeeListService';

  constructor(
    private genericService: GenericService,
    private errorManager: ErrorManagerService,
    private http: HttpClient,
    private injector: Injector
  ) {}

  create(data): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getById(id: string, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  updateById(id: string, data: any, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }
  deleteById(id: string, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_WORK_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_WORK_INTERNATIONALIZATION);
  }
  getData?(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async getEmployeesDetailed(): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(
        'PROGRAMMING ERROR',
        ErrorCodes.UNAUTHORIZED,
        this.SHIFTPLAN_EMPLOYEE_LIST_SERVICE,
        'getEmployeesDataShiftPlanActive'
      );
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      return await this.http.get(`${this.CONTROLLER_URL}/list`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_EMPLOYEE_LIST_SERVICE, 'getEmployeesDataShiftPlanActive');
    }
  }

  async getEmployeesSchedules(startDate: moment.Moment, endDate: moment.Moment, view: string) {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_EMPLOYEE_LIST_SERVICE, 'getEmployeesSchedules');
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      return await this.http
        .post<IUserShiftplanWorkSchedules>(`${this.CONTROLLER_URL}/schedules`, { startDate, endDate, view }, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_EMPLOYEE_LIST_SERVICE, 'getEmployeesSchedules');
    }
  }

  async getEmployeesEditAccess(): Promise<Array<string>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_EMPLOYEE_LIST_SERVICE, 'getEmployeesEditAccess');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.get<Array<string>>(`${this.CONTROLLER_URL}/edit-access`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_EMPLOYEE_LIST_SERVICE, 'getEmployeesEditAccess');
    }
  }
}

export interface IUserShiftplanWorkSchedules {
  [id: string]: { totalExpected: number; _id: string; workSchedule?: Array<boolean> };
}
