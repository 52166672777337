import { Component, HostBinding, Input } from '@angular/core';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-checkbox',
  templateUrl: 'input-checkbox.component.html',
  styleUrls: ['input-checkbox.component.scss']
})
export class InputCheckboxComponent extends InputAbstractComponent {
  _labelPosition: 'after' | 'bottom' = 'after';
  @Input()
  set labelPosition(labelPosition: 'after' | 'bottom') {
    this._labelPosition = labelPosition;

    if (labelPosition === 'bottom') {
      this.textAlignStyle = 'center';
    } else {
      this.textAlignStyle = '';
    }
  }
  get labelPosition(): 'after' | 'bottom' {
    return this._labelPosition;
  }

  _marginTop = '20px';
  @Input()
  set marginTop(marginTop: string) {
    this._marginTop = marginTop;
    this.marginTopStyle = marginTop;
  }
  get marginTop(): string {
    return this._marginTop;
  }

  @HostBinding('style.text-align') textAlignStyle: string = '';
  @HostBinding('style.margin-top') marginTopStyle = '20px';
}
