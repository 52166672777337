import { CommonModule } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { IConflictsDialog } from '@app/cloud-features/settings-attendance/services/attendance-conflicts.service';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';

@Component({
  selector: 'kenjo-conflicts-dialog',
  templateUrl: 'conflicts-dialog.component.html',
  styleUrls: ['conflicts-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, IconModule, StandardComponentsModule, MatLegacyTooltipModule],
})
export class ConflictsDialogComponent {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary' = 'Success';
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;
  confirmDisabled: boolean;
  cancelDisabled: boolean;
  employees: Array<any>;
  conflicts: IConflictsDialog;
  bullets: Array<{ title: string; text: string }>;
  attendancePolicy: IAttendancePolicy;

  constructor(
    public dialogRef: MatLegacyDialogRef<ConflictsDialogComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {
    this.titleText = this.data.titleText;
    this.subtitleText = this.data.subtitleText;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.confirmButtonId = this.data.confirmButtonId ?? '';
    this.cancelButtonId = this.data.cancelButtonId ?? '';
    this.componentId = this.data.componentId ?? null;
    this.confirmDisabled = this.data.confirmDisabled ?? false;
    this.cancelDisabled = this.data.cancelDisabled ?? false;
    this.employees = this.data.employees;
    this.conflicts = this.data.attendanceConflicts;
    this.attendancePolicy = this.data.attendancePolicy;
    this.bullets = this.data.bullets;
    this.dialogRef.disableClose = !!this.data.disableClose;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }
}
