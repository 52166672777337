import * as check from 'check-types';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kenjo-input-simple-hourly-range',
  templateUrl: 'input-simple-hourly-range.component.html',
  styleUrls: ['input-simple-hourly-range.component.scss']
})
export class InputSimpleHourlyRangeComponent {

  @Input() hourlyRange: IHourlyRange;
  @Input() validateHourlyRanges: Function;
  @Input() readOnlyStart: any = false;
  @Input() readOnlyEnd: any = false;
  @Input() displayStart: boolean = false;
  _translations: any;
  @Input()
  get translations(): any {
    return this._translations;
  }
  set translations(value: any) {
    this._translations = value;
  }

  @Input() inputSize: 'small' | 'medium'| 'long' = 'small';
  @Input() debounceNewValues: boolean = false;
  @Input() showTouchedInputErrors: boolean = false;

  @Output() hourlyRangeChange: EventEmitter<any> = new EventEmitter<any>();

  error: IValidationErrors = {
    hasErrors: false,
    startTimeError: false,
    endTimeError: false,
  };

  startTimeTouched: boolean = false;
  endTimeTouched: boolean = false;

  displayStartError = false;
  displayEndError = false;


  public validateRanges(inputName: 'start' | 'end') {
    this.error = this.validateHourlyRanges(this.error, inputName, this.hourlyRange, this.translations);
    this.hourlyRange.hasErrors = this.error.hasErrors;
    this.hourlyRangeChange.emit(this.hourlyRange);
    this.checkEmptyInputs(inputName);
  }

  checkEmptyInputs(inputName: 'start' | 'end') {
    if (this.showTouchedInputErrors && this.startTimeTouched && this.endTimeTouched && (check.not.number(this.hourlyRange?.startTime) || check.not.number(this.hourlyRange?.endTime))) {
        this.displayStartError = check.not.number(this.hourlyRange?.startTime);
        this.displayEndError = check.not.number(this.hourlyRange?.endTime) && inputName !== 'end';
    } else {
      this.displayStartError = false;
      this.displayEndError = false;
    }
  }
}

export interface IHourlyRange {
  startTime: number;
  endTime: number;
  hasErrors: boolean;
}

export interface IValidationErrors {
  hasErrors: boolean;
  startTimeError: boolean;
  endTimeError: boolean;
  startTimeErrorMessage?: string;
  endTimeErrorMessage?: string;
}
