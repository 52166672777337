import { Component, Input, OnInit } from '@angular/core';
import { VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { ITimeOffRequestDay } from '@app/cloud-features/shift-plan/services/shift-plan-time-off.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-shiftplan-pdf-timeoff',
  templateUrl: './shiftplan-pdf-timeoff.component.html',
  styleUrls: ['./shiftplan-pdf-timeoff.component.scss'],
})
export class ShiftplanPdfTimeoffComponent implements OnInit {
  views = { ...VIEWS };
  totalTimeOffs: Array<number> = [];

  @Input() timeOff: ITimeOffRequestDay;
  @Input() frequency: 'week' | 'month';
  @Input() emptyShifts: boolean = false;
  @Input() timeOffTranslation: { [key: string]: string };

  constructor() {}

  ngOnInit() {
    this.initTimeOffCard();
  }

  initTimeOffCard() {
    if (check.not.assigned(this.timeOff)) {
      return;
    }

    this.totalTimeOffs = Array.from({ length: this.timeOff.total });
  }
}
