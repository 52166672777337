import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateUppyService } from '@app/private/services/private-uppy.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ConvertChildrenPipe } from '@app/standard/pages/people/payroll/components/convert-children/convert-children.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { DATA_INCLUDED_ALL, EXPORT_FORMAT_CSV } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as exceljs from 'exceljs';
import * as FileSaver from 'file-saver';
import * as json2csv from 'json2csv';
import * as JSZip from 'jszip';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PayrollExportService {
  constructor(private injector: Injector) {}

  public async downloadExport(payrollGroupData, selectedPayrollSettings): Promise<void> {
    try {
      const { fileName, fileBlob } = await this.#generateExport(payrollGroupData, selectedPayrollSettings, false);
      FileSaver.saveAs(fileBlob, fileName);
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, PayrollExportService.name, 'downloadExport');
      throw error;
    }
  }

  public async generateAndStoreExportInS3(payrollGroupData, selectedPayrollSettings, isCorrection: boolean): Promise<IFileMetadata> {
    const { fileName, fileBlob } = await this.#generateExport(payrollGroupData, selectedPayrollSettings, isCorrection);
    return await this.injector.get(PrivateUppyService).upload(fileBlob, fileName, 'application/zip');
  }

  async #generateExport(payrollGroupData, selectedPayrollSettings, isCorrection: boolean): Promise<{ fileName: string; fileBlob: Blob }> {
    const isCsv: boolean = selectedPayrollSettings?.exportFormat === EXPORT_FORMAT_CSV;
    const allData: boolean = selectedPayrollSettings?.dataIncluded === DATA_INCLUDED_ALL;
    const isTimeOffIncluded: boolean = selectedPayrollSettings?.files?.timeOff === true;
    const documents = selectedPayrollSettings?.files?.documents ? selectedPayrollSettings.files.documents : [];

    const config = {
      isCsv: isCsv,
      allData: allData,
      isTimeOffIncluded: isTimeOffIncluded,
      documents: documents,
      fromDate: moment.utc(payrollGroupData.fromDate),
      toDate: moment.utc(payrollGroupData.toDate),
      payrollGroupData: payrollGroupData,
      selectedPayrollSettingsName: selectedPayrollSettings?.name,
      i18n: {} as any
    };

    config.i18n = await this.injector.get(InternationalizationService).getAllTranslation('payroll-page');

    const file = config.isCsv === true ? await this.#buildCsv(config) : await this.#buildExcel(config);

    return {
      fileName: `${config.fromDate.format('YYYY-MM-DD')}_${config.toDate.format('YYYY-MM-DD')}_${_.kebabCase(config.selectedPayrollSettingsName)}_${isCorrection === true ? config.i18n.correctionFileNameSuffix : config.i18n.exportFileNameSuffix}.zip`,
      fileBlob: new Blob([file])
    };
  }

  async #buildExcel(config): Promise<ArrayBuffer> {
    const getExportExcelCalls = [];
    const getAttachmentsExportCalls = [];
    const workbook = new exceljs.Workbook();

    const worksheetPayroll = workbook.addWorksheet(`${config.i18n.payrollExcelSheet}`.replace('.csv', ''));
    const worksheetVariable = workbook.addWorksheet(`${config.i18n.variablePaymentsExcelSheet}`.replace('.csv', ''));

    getExportExcelCalls.push(this.#getPayrollExport(config, worksheetPayroll));
    getExportExcelCalls.push(this.#getVariablePayExport(config, worksheetVariable));

    // Salary surcharges sheet
    if (config?.payrollGroupData?.hasSurchargePayments) {
      const worksheetSurcharges = workbook.addWorksheet(`${config.i18n.surchargePaymentsExcelSheet}`.replace('.csv', ''));
      getExportExcelCalls.push(this.#getSurchargePaymentsExport(config, worksheetSurcharges));
    }

    if (config.isTimeOffIncluded === true) {
      const worksheetTimeOff = workbook.addWorksheet(`${config.i18n.timeOffExcelSheet}`.replace('.csv', ''));
      getExportExcelCalls.push(this.#getTimeOffExport(config, worksheetTimeOff));
      getAttachmentsExportCalls.push(this.#getTimeOffAttachmentExport(config));
    }

    if (check.nonEmptyArray(config.documents)) {
      getAttachmentsExportCalls.push(this.#getEmployeeDocsExport(config));
    }

    await Promise.all(getExportExcelCalls);

    const exportedAttachmentsData = await Promise.all(getAttachmentsExportCalls);
    const data: BlobPart = await workbook.xlsx.writeBuffer();

    const excelFile: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const zip = new JSZip();
    zip.file(`${config.i18n.excelFile}.xlsx`, excelFile);

    let timeOffAttachments;
    let employeeDocs;
    if (config.isTimeOffIncluded === true) {
      timeOffAttachments = zip.folder(`${config.i18n.timeOffAttachmentsFolder}`);
      exportedAttachmentsData[0].forEach((iAttachment) => {
        timeOffAttachments.file(iAttachment.name, iAttachment.file);
      });
    }

    if (check.nonEmptyArray(config.documents)) {
      employeeDocs = zip.folder(`${config.i18n.employeeDocsFolder}`);
      exportedAttachmentsData[exportedAttachmentsData.length - 1].forEach((iAttachment) => {
        employeeDocs.file(iAttachment.name, iAttachment.file);
      });
    }

    const zipGenerated = await zip.generateAsync({ type: 'arraybuffer' });
    return zipGenerated;
  }

  async #buildCsv(config): Promise<ArrayBuffer> {
    const getCsvExportCalls = [];
    const getAttachmentsExportCalls = [];

    getCsvExportCalls.push(this.#getPayrollExport(config));
    getCsvExportCalls.push(this.#getVariablePayExport(config));

    if (config.isTimeOffIncluded === true) {
      getCsvExportCalls.push(this.#getTimeOffExport(config));
      getAttachmentsExportCalls.push(this.#getTimeOffAttachmentExport(config));
    }

    if (check.nonEmptyArray(config.documents)) {
      getAttachmentsExportCalls.push(this.#getEmployeeDocsExport(config));
    }

    // Salary surcharges sheet
    getCsvExportCalls.push(this.#getSurchargePaymentsExport(config));

    const exportedCsvData = await Promise.all(getCsvExportCalls);

    const zip = new JSZip();
    zip.file(`${config.i18n.payrollCsvFile}.csv`, exportedCsvData[0]);
    zip.file(`${config.i18n.variablePaymentsCsvFile}.csv`, exportedCsvData[1]);

    const exportedAttachmentsData = await Promise.all(getAttachmentsExportCalls);

    let timeOffAttachments;
    let employeeDocs;

    if (config.isTimeOffIncluded === true) {
      zip.file(`${config.i18n.timeOffCsvFile}.csv`, exportedCsvData[2]);
      timeOffAttachments = zip.folder(`${config.i18n.timeOffAttachmentsFolder}`);
      exportedAttachmentsData[0].forEach((iAttachment) => {
        timeOffAttachments.file(iAttachment.name, iAttachment.file);
      });
    }
    if (check.nonEmptyArray(config.documents)) {
      employeeDocs = zip.folder(`${config.i18n.employeeDocsFolder}`);
      exportedAttachmentsData[exportedAttachmentsData.length - 1].forEach((iAttachment) => {
        employeeDocs.file(iAttachment.name, iAttachment.file);
      });
    }

    // include salary surcharges
    zip.file(`${config.i18n.surchargePaymentsCsvFile}.csv`, exportedCsvData[exportedCsvData.length-1]);

    const zipGenerated = await zip.generateAsync({ type: 'arraybuffer' });
    return zipGenerated;
  }

  async #getPayrollExport(config, worksheet?: any): Promise<any> {
    const { payrollGroupData } = config;
    const isCSV: boolean = config.isCsv;
    const allData: boolean = config.allData;
    const employeeDataColumns = payrollGroupData.employeeDataColumns.filter((employeeDataColumn) => employeeDataColumn !== 'user-personal.photoUrl');
    const fields: Array<any> = employeeDataColumns.map((employeeDataColumn) => {
      return {
        label: payrollGroupData.employeeDataColumnsTranslationKeys[employeeDataColumn],
        value: employeeDataColumn
      };
    });
    const rowData = payrollGroupData.employeeData.reduce((result, currEmployee) => {
      const row = {};
      let newResult = [];
      row['hasChanges'] = currEmployee.hasChanges;

      employeeDataColumns.forEach((employeeDataColumn) => {
        let value = currEmployee.data[employeeDataColumn].value;

        if (currEmployee.data[employeeDataColumn].type === 'number') {
          value = this.injector.get(DecimalPipe).transform(value, '1.0-2');
        }

        if (currEmployee.data[employeeDataColumn].type === 'date') {
          value = this.injector.get(DatePipe).transform(value, 'shortDate', 'UTC');
        }

        if (currEmployee.data[employeeDataColumn].type === 'children') {
          value = this.injector.get(ConvertChildrenPipe).transform(value);
        }

        row[employeeDataColumn] = isCSV === true ? value : { value: value, isChanged: currEmployee.data[employeeDataColumn].changed };
      });

      if (allData === true) {
        newResult = [...result, row];
      } else {
        newResult = currEmployee.hasChanges === true ? [...result, row] : result;
      }

      return newResult;
    }, []);

    if (isCSV === true) {
      const csv = json2csv.parse(rowData, { fields: fields, withBOM: true });
      const blob = new Blob([csv], { type: 'text/csv' });
      return blob;
    }

    const headers = fields.map((header) => {
      return header.label;
    });
    const fieldIndex = fields.map((header) => {
      return header.value;
    });
    const titleRow = worksheet.addRow(headers);
    titleRow.font = { bold: true };

    rowData.forEach((row) => {
      const hightLighted = [];
      const notHightLighted = [];

      const excelRow = fieldIndex.map((data, index) => {
        if (check.assigned(row[data].isChanged) && row[data].isChanged === true) {
          hightLighted.push(index + 1);
        } else {
          notHightLighted.push(index + 1);
        }
        return row[data].value;
      });

      const rowAdded = worksheet.addRow(excelRow);
      hightLighted.forEach((colIndex) => {
        rowAdded.getCell(colIndex).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FEE8A4' }
        };
      });

      if (row.hasChanges === true) {
        notHightLighted.forEach((colIndex) => {
          rowAdded.getCell(colIndex).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FDF9E7' }
          };
        });
      }
    });

    return [];
  }

  async #getTimeOffExport(config, worksheet?: any): Promise<any> {
    const isCSV: boolean = config.isCsv;
    const timeOffColumnsExports: Array<string> = ['firstName', 'lastName', 'timeOffTypeName', 'amount', 'period'];
    const timeOffRecords = config.payrollGroupData.timeOff.length >= 0 ? config.payrollGroupData.timeOff : config.payrollGroupData.timeOff.timeOffRecords;

    if (timeOffRecords?.some((iTimeOff) => iTimeOff.comment !== undefined)) {
      timeOffColumnsExports.push('comment');
    }
    const fields: Array<any> = timeOffColumnsExports.map((timeOffColumnExport) => {
      return {
        value: timeOffColumnExport,
        label: config.i18n[`${timeOffColumnExport}Column`]
      };
    });

    let rowData = [];
    if (check.nonEmptyArray(timeOffRecords)) {
      rowData = timeOffRecords?.map((eachTimeOff) => {
        const row = {};
        fields.forEach((field) => {
          if (check.assigned(eachTimeOff[field.value])) {
            row[field.value] = isCSV === true ? eachTimeOff[field.value] : { value: eachTimeOff[field.value] };
          } else if (field.value === 'amount') {
            let amount: string = '';
            if (eachTimeOff.workingDays === 1) {
              amount = config.i18n.dayText;
            } else if (eachTimeOff.workingDays > 1 || eachTimeOff.workingDays === 0.5) {
              amount = this.injector.get(I18nDataPipe).transform(config.i18n.daysText, { amount: eachTimeOff.workingDays });
            } else if (eachTimeOff.workingHours === 1) {
              amount = config.i18n.hourText;
            } else if (eachTimeOff.workingHours > 1) {
              amount = this.injector.get(I18nDataPipe).transform(config.i18n.hoursText, { amount: eachTimeOff.workingHours });
            }
            row[field.value] = isCSV === true ? amount : { value: amount };
          } else if (field.value === 'period') {
            let period = this.injector.get(DatePipe).transform(eachTimeOff.from, 'shortDate', 'UTC');

            if (eachTimeOff.workingDays && eachTimeOff.workingDays > 1) {
              period += ` - ${this.injector.get(DatePipe).transform(eachTimeOff.to, 'shortDate', 'UTC')}`;
            }

            row[field.value] = isCSV === true ? period : { value: period };
          } else if (field.value === 'comment') {
            row[field.value] = { value: undefined };
          }
        });
        return row;
      });
    }

    if (isCSV === true) {
      const csv = json2csv.parse(rowData, { fields: fields, withBOM: true });
      const blob = new Blob([csv], { type: 'text/csv' });
      return blob;
    }

    const headers = fields.map((header) => {
      return header.label;
    });
    const fieldIndex = fields.map((header) => {
      return header.value;
    });
    const titleRow = worksheet.addRow(headers);
    titleRow.font = { bold: true };

    rowData.forEach((row) => {
      const excelRow = fieldIndex.map((data) => {
        return row[data].value;
      });
      worksheet.addRow(excelRow);
    });

    return [];
  }

  async #getVariablePayExport(config, worksheet?: any): Promise<any> {
    const isCSV: boolean = config.isCsv;
    const variablePaymentsColumnsExports: Array<string> = ['firstName', 'lastName', 'date', 'amount', 'currency', 'type'];
    if (config.payrollGroupData.variablePayments?.some((iPayment) => iPayment.comment !== undefined)) {
      variablePaymentsColumnsExports.push('comment');
    }
    const fields: Array<any> = variablePaymentsColumnsExports.map((variablePaymentsColumn) => {
      return {
        value: variablePaymentsColumn,
        label: config.i18n[`${variablePaymentsColumn}Column`]
      };
    });

    const rowData = config.payrollGroupData.variablePayments.map((eachVariablePayment) => {
      const row = {};
      fields.forEach((field) => {
        if (check.assigned(eachVariablePayment[field.value])) {
          if (moment.utc(eachVariablePayment[field.value], 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()) {
            eachVariablePayment[field.value] = this.injector.get(DatePipe).transform(eachVariablePayment[field.value], 'shortDate', 'UTC');
          }
          row[field.value] = isCSV === true ? eachVariablePayment[field.value] : { value: eachVariablePayment[field.value] };
        } else {
          row[field.value] = isCSV === true ? '' : { value: '' };
        }
      });

      return row;
    });

    if (isCSV === true) {
      const csv = json2csv.parse(rowData, { fields: fields, withBOM: true });
      const blob = new Blob([csv], { type: 'text/csv' });
      return blob;
    }

    const headers = fields.map((header) => {
      return header.label;
    });
    const fieldIndex = fields.map((header) => {
      return header.value;
    });
    const titleRow = worksheet.addRow(headers);
    titleRow.font = { bold: true };

    rowData.forEach((row) => {
      const excelRow = fieldIndex.map((data) => {
        return row[data].value;
      });
      worksheet.addRow(excelRow);
    });

    return [];
  }

  #getAttachment(attachment: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .get(attachment.attachmentUrl, { responseType: 'arraybuffer' })
        .subscribe(
          (arrayBuffer) => {
            const extension: string = check.assigned(attachment.attachmentName) && attachment.attachmentName.split('.').length > 1 ? attachment.attachmentName.split('.').pop() : '';
            let attachmentName: string = `${attachment.attachmentName}.${attachment.extension}`;
            if (check.nonEmptyString(extension) && extension === attachment.extension) {
              attachmentName = attachment.attachmentName;
            }
            resolve({ file: new Blob([arrayBuffer]), name: attachmentName });
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  async #getTimeOffAttachmentExport({ payrollGroupData }): Promise<any> {
    const timeOffRecords = payrollGroupData.timeOff.length >= 0 ? payrollGroupData.timeOff : payrollGroupData.timeOff.timeOffRecords;

    if (check.not.assigned(timeOffRecords)) {
      return [];
    }

    const callsToAttachments = [];
    timeOffRecords.forEach((eachTimeOff) => {
      if (check.assigned(eachTimeOff.attachment)) {
        callsToAttachments.push(this.#getAttachment(eachTimeOff.attachment));
      }
    });

    const results = await Promise.all(callsToAttachments);
    return results;
  }

  async #getEmployeeDocsExport(config): Promise<any> {
    const callsToEmployeeDocs = [];

    const filteredEmployeeDocs = config.payrollGroupData.employeeDocs.filter((employeeDoc) => {
      return employeeDoc.typeId.some((employeeDocTypeId) => config.documents.indexOf(employeeDocTypeId) >= 0);
    });

    filteredEmployeeDocs.forEach((filteredEmployeeDoc) => {
      callsToEmployeeDocs.push(this.#getAttachment(filteredEmployeeDoc));
    });

    const results = await Promise.all(callsToEmployeeDocs);
    return results;
  }

  async #getSurchargePaymentsExport(config, worksheet?: any): Promise<any> {
    const isCSV: boolean = config.isCsv;
    const { payrollGroupData } = config;
    const surchargePaymentsColumnsExports: Array<string> = ['firstName', 'lastName'];
    const surchargeRuleIdsInExport = {}; // will identify the column index of the surcharge payment
    const surchargePaymentRows: Array<ISurchargePaymentRow> = [];

    // include the columns of the surcharge payments, one column per each surcharge
    payrollGroupData.employeeData.forEach((employeeData) => {
      if (employeeData?.data?.['payroll-custom.surchargesSummary']?.length) { // if there is a surcharge summary for this employee
        employeeData.data['payroll-custom.surchargesSummary'].forEach((surchargeSummary: IPayrollSurchargeSummaryRowModel) => {
          const { surchargeHoursColumnName, surchargePaymentColumnName } = this.#calculateSurchargeColNames(surchargeSummary, config.i18n);
          if (!surchargeRuleIdsInExport[surchargeSummary.surchargeRuleId]) {
            surchargeRuleIdsInExport[surchargeSummary.surchargeRuleId] = surchargePaymentsColumnsExports.length;
            surchargePaymentsColumnsExports.push(surchargeHoursColumnName);
            surchargePaymentsColumnsExports.push(surchargePaymentColumnName);
          }
        });
        const surchargePaymentRow: ISurchargePaymentRow = {
          firstName: employeeData.data['user-personal.firstName']?.value,
          lastName: employeeData.data['user-personal.lastName']?.value,
          currency: employeeData.data['payroll-custom.currency']?.value,
          surchargesSummary: employeeData.data['payroll-custom.surchargesSummary']
        };
        surchargePaymentRows.push(surchargePaymentRow);
      }
    });
    surchargePaymentsColumnsExports.push('currency');

    if (!surchargePaymentRows?.length) {
      return;
    }

    const fields: Array<{value: string | number, label: string}> = surchargePaymentsColumnsExports.map((surchargePaymentsColumn) => {
      return {
        value: surchargePaymentsColumn,
        label: config.i18n[`${surchargePaymentsColumn}Column`] ? config.i18n[`${surchargePaymentsColumn}Column`] : surchargePaymentsColumn,
      };
    });

    const rowData = surchargePaymentRows.map((surchargePaymentRow: ISurchargePaymentRow) => {
      const row = {
        firstName: surchargePaymentRow.firstName,
        lastName: surchargePaymentRow.lastName,
        currency: surchargePaymentRow.currency,
      };
      surchargePaymentRow.surchargesSummary.forEach((surchargeSummary: IPayrollSurchargeSummaryRowModel) => {
        const { surchargeHoursColumnName, surchargePaymentColumnName } = this.#calculateSurchargeColNames(surchargeSummary, config.i18n);
        row[surchargeHoursColumnName] = surchargeSummary.surchargeHours;
        row[surchargePaymentColumnName] = surchargeSummary.surchargePayment;
      });
      return row;
    });

    if (isCSV === true) {
      const csv = json2csv.parse(rowData, { fields: fields, withBOM: true });
      const blob = new Blob([csv], { type: 'text/csv' });
      return blob;
    }

    const headers = fields.map((header) => {
      return header.label;
    });
    const titleRow = worksheet.addRow(headers);
    titleRow.font = { bold: true };

    const fieldIndex = fields.map((field) => {
      return field.value;
    });

    rowData.forEach((row) => {
      const excelRow = fieldIndex.map((data) => {
        return row[data];
      });
      worksheet.addRow(excelRow);
    });

    return [];
  }

  #calculateSurchargeColNames(surchargeSummary: IPayrollSurchargeSummaryRowModel, i18n: {[translationKey: string]: string}) {
    const translationData = {
      surchargeRuleName: surchargeSummary.surchargeRuleName,
      surchargeRulePercentage: surchargeSummary.surchargeRulePercentage,
    };
    const surchargeHoursColumnName = this.injector.get(I18nDataPipe).transform(i18n.excelExportSurchargeNrHoursColumn, translationData);
    const surchargePaymentColumnName = this.injector.get(I18nDataPipe).transform(i18n.excelExportSurchargeNrAmountColumn, translationData);
    return {
      surchargeHoursColumnName,
      surchargePaymentColumnName,
    };
  }
}

export interface IPayrollSurchargeSummaryRowModel {
  surchargeRuleId: string;
  surchargeRuleName: string;
  baseHourlyRate: number;
  surchargeRulePercentage: number;
  surchargeHours: number;
  surchargeRatePerHour: number;
  surchargePayment: number;
}

export interface ISurchargePaymentRow {
  firstName: string;
  lastName: string;
  currency: string;
  surchargesSummary: Array<IPayrollSurchargeSummaryRowModel>;
}
