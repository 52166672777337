import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IAudienceVisibility } from '@app/standard/components/audience-selector/services/audience-selector.component.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AttendanceCategoryService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/attendance-category-db`;

  constructor(private injector: Injector) {}

  async getMine(): Promise<Array<IAttendanceCategory>> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result: Array<IAttendanceCategory> = await this.injector.get(HttpClient).get<Array<IAttendanceCategory>>(`${this.URL}/mine`, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendanceCategoryService.name, 'getMine')
    }
  }

  async getAll(): Promise<Array<IAttendanceCategory>> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result: Array<IAttendanceCategory> = await this.injector.get(HttpClient).get<Array<IAttendanceCategory>>(`${this.URL}/all`, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendanceCategoryService.name, 'getAll')
    }
  }

  async create(attendanceCategory: IAttendanceCategory): Promise<IAttendanceCategory> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try {
      const result: IAttendanceCategory = await this.injector.get(HttpClient).post<IAttendanceCategory>(`${this.URL}/`, attendanceCategory, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendanceCategoryService.name, 'create');
    }
  }

  async update(id: string, attendanceCategory: IAttendanceCategory): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      const result = await this.injector.get(HttpClient).put(`${this.URL}/${id}`, attendanceCategory, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendanceCategoryService.name, 'update')
    }
  }

  async delete(id: string): Promise<void> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try{
      await this.injector.get(HttpClient).delete(`${this.URL}/${id}`, httpOptions).toPromise();
      return;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendanceCategoryService.name, 'delete');
    }
  }
}

export interface IAttendanceCategory {
  _id?: string;
  name?: string;
  parentCategoryId?: string;
  color?: string;
  isActive?: boolean;
  visibleToEveryone?: boolean;
  visibleToSpecific?: IAudienceVisibility;
  _createdAt?: Date;
  _updatedAt?: Date;
  value?: string;
}
