import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { USER_COLOR_1, USER_COLOR_2, USER_COLOR_3, USER_COLOR_4, USER_COLOR_5, USER_COLOR_6 } from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as Chart from 'chart.js';
import * as check from 'check-types';
import * as moment from 'moment';

import { InternationalizationService } from '../../services/core/internationalization.service';
import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-pulse-chart',
  templateUrl: 'pulse-chart.component.html',
  styleUrls: ['pulse-chart.component.scss']
})
export class PulseChartComponent implements OnInit {
  MONTH_NAMES: Array<string> = [];

  @ViewChild('chartElement', { static: true }) chartElement: ElementRef;

  @Input() chartType: string = 'line'; // Options bar, line
  @Input() pulseTrend: Array<any> = [];
  @Input() label: string;
  @Input() min: number = -100;
  @Input() max: number = 100;
  @Input() showLegend: boolean = true;
  @Input() additionalLines: any; // {Object} => key => Label, value => array to display

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    this.MONTH_NAMES = moment.months();
    this.drawChart();
  }

  drawChart(): void {
    const chartCtx = this.chartElement.nativeElement.getContext('2d');
    const labels = [];
    const datas = [];
    const backGroundColors = [];
    const borderColors = [];
    this.pulseTrend.forEach((iResult) => {
      const currentMonth = parseInt(iResult.month);
      labels.push(`${this.MONTH_NAMES[currentMonth]} ${iResult.year}`);
      datas.push(this.precisionRound(iResult.value, 1));
      backGroundColors.push('#00b72e');
      borderColors.push('#00b72e');
    });

    let data = {
      labels: labels,
      datasets: [
        {
          label: this.label,
          data: datas,
          fill: false,
          borderColor: '#00b72e',
          pointBackgroundColor: '#00b72e',
          pointBorderColor: '#00b72e',
          borderWidth: 2
        }
      ]
    };

    const additionalDatasets = this.getAdditionalDataSets();
    if (check.assigned(additionalDatasets) && check.array(additionalDatasets) && check.nonEmptyArray(additionalDatasets)) {
      data.datasets = data.datasets.concat(additionalDatasets);
    }
    const chart = new Chart(chartCtx, {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                min: Number(this.min),
                max: Number(this.max)
              }
            }
          ]
        },
        animation: {
          duration: 200,
          easing: 'linear'
        },
        legend: {
          display: this.showLegend === true ? true : false
        }
      }
    });
  }

  private getAdditionalDataSets(): any {
    const COLORS_ADDITIONAL_LINES = [USER_COLOR_1, USER_COLOR_2, USER_COLOR_3, USER_COLOR_4, USER_COLOR_5, USER_COLOR_6];
    if (check.not.assigned(this.additionalLines) || check.emptyObject(this.additionalLines)) {
      return null;
    }

    const additionalDataSets = [];
    Object.keys(this.additionalLines).forEach((iCategoryLabel, index) => {
      if (check.assigned(this.additionalLines[iCategoryLabel]) && check.array(this.additionalLines[iCategoryLabel]) && check.nonEmptyArray(this.additionalLines[iCategoryLabel])) {
        const iColor = index < COLORS_ADDITIONAL_LINES.length ? COLORS_ADDITIONAL_LINES[index] : COLORS_ADDITIONAL_LINES[0];
        const iDataset = {
          hidden: false,
          label: iCategoryLabel,
          data: this.buildArrayLines(this.additionalLines[iCategoryLabel]),
          fill: false,
          borderColor: iColor,
          pointBackgroundColor: iColor,
          pointBorderColor: iColor,
          borderWidth: 3
        };
        additionalDataSets.push(iDataset);
      }
    });
    return additionalDataSets;
  }

  private buildArrayLines(trendObjectArray: Array<any>): Array<number> {
    const result = trendObjectArray.map((iTrendObject) => {
      if (check.not.assigned(iTrendObject.value) || check.not.number(iTrendObject.value)) {
        return Number.NaN;
      }
      return this.precisionRound(iTrendObject.value, 1);
    });
    return result;
  }

  private precisionRound(number: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }
}
