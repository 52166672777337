import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UserSegmentationService } from '@app/cloud-features/home/components/user-segmentation/user-segmentation.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateChargebeeService } from '@app/private/services/private-chargebee.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { PrivateFreshdeskService } from '@app/private/services/private-freshdesk.service';
import { PrivateHubspotService } from '@app/private/services/private-hubspot.service';
import { PrivateInternationalizationService } from '@app/private/services/private-internationalization.service';
import { PrivateSearchService } from '@app/private/services/private-search.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { PrivateSubscriptionService } from '@app/private/services/private-subscription.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as profiles from '@carlos-orgos/orgos-security/profiles';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import { ACCESS_TYPE_AGENCY_ACCESS, ACCESS_TYPE_FULL_ACCESS, ACCESS_TYPE_NO_ACCESS, ACCESS_TYPE_ONBOARDING_ACCESS, ACCESS_TYPE_WHISTLEBLOWER } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';
import { KJUR } from 'jsrsasign';

@Injectable({
  providedIn: 'root'
})
export class PrivateAuthenticationService {
  private rawJwt: string = '';
  private loggedUser: IUserAccountModel;
  private USER_ACCOUNT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-account-db/user-accounts`;
  private AUTH_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/auth`;
  private accessType: string = ACCESS_TYPE_NO_ACCESS;

  constructor(private injector: Injector) {}

  authenticate(jwt: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isJwtValid(jwt) === false) {
        // The authentication is clear to force a new authentication
        this.clearAuthenticationState();

        const error = new OrgosError(`TOKEN NOT VALID: ${jwt}`, ErrorCodes.CLIENT_ERROR, PrivateAuthenticationService.name, 'authenticate');
        this.injector.get(ErrorManagerService).handleRawError(error);

        reject(error);
        return;
      }

      if (this.getAccessType() === ACCESS_TYPE_WHISTLEBLOWER) {
        this.rawJwt = jwt;
        this.loggedUser = {
          profile: {
            name: 'Whistleblower',
            _profileKey: 'Whistleblower',
            _isStandard: true,
            _isAdmin: false
          },
          chargebeeStatus: null,
          inactiveReason: null
        };
        resolve(true);
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${jwt}`);

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<IUserAccountModel>(`${this.USER_ACCOUNT_URL}/me`, httpOptions)
        .toPromise()
        .then((userAccountResponseData) => {
          this.loggedUser = userAccountResponseData;
          this.rawJwt = jwt;
          this.injector.get(PrivateInternationalizationService).setUserLanguage(this.loggedUser.language);
          this.injector.get(PrivateInternationalizationService).setUserLocale(this.loggedUser.locale);

          if (this.getAccessType() === ACCESS_TYPE_FULL_ACCESS || this.getAccessType() === ACCESS_TYPE_ONBOARDING_ACCESS) {
            this.injector.get(PrivateSubscriptionService).setUserOrgSubscription();
          }

          const httpOptionsForPersistToken = {
            headers: httpHeaders,
            withCredentials: true
          };

          if (this.getAccessType() === ACCESS_TYPE_FULL_ACCESS || this.getAccessType() === ACCESS_TYPE_AGENCY_ACCESS) {
            this.injector
              .get(HttpClient)
              .get(`${this.AUTH_URL}/persist-token`, httpOptionsForPersistToken)
              .toPromise()
              .then(() => {
                // No actions, the browser has persisted the cookie
              })
              .catch((error) => {
                // If persisting the token fails, a silent error is sent (it can be a programming error)
                // User is not notify, it will require to authenticate again if the app is reloaded.
                this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateAuthenticationService.name, 'authenticate');
              });
          }

          // Find the profile of the user:
          this.loggedUser.profile = {
            name: 'Employee',
            _profileKey: 'employee',
            _isStandard: true,
            _isAdmin: false
          };

          if (check.assigned(this.loggedUser.profileKey) && check.nonEmptyString(this.loggedUser.profileKey)) {
            profiles
              .getProfile(this.loggedUser.profileKey)
              .then((profile: any) => {
                this.loggedUser.profile = profile;
              })
              .catch((error) => {
                // If we cannot assure the loggedUser, we set with the minimum permissions.
                this.loggedUser.profile = {
                  name: 'Employee',
                  _profileKey: 'employee',
                  _isStandard: true,
                  _isAdmin: false
                };

                // A silent error is sent (it can be a programming error)
                // The user is not notify, maybe the user cannot execute some actions.
                this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateAuthenticationService.name, 'authenticate');
              });
          }

          this.injector
            .get(PrivateChargebeeService)
            .getChargebeeStatus()
            .then((status) => {
              this.loggedUser.chargebeeStatus = status;
              this.injector.get(GlobalBarService).setChargebeeStatus(status);
            })
            .catch(() => {
              this.loggedUser.chargebeeStatus = {
                subscriptions: [],
                plans: [],
                customerStatus: '',
                showFreeTrialBanner: false,
                overdueAmount: { amount: 0, currency: 'EUR' }
              };
            });

          resolve(true);
        })
        .catch((error) => {
          // The authentication is clear to force a new authentication
          this.clearAuthenticationState();

          const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, PrivateAuthenticationService.name, 'authenticate');
          this.injector.get(ErrorManagerService).handleRawError(orgosError);

          reject(orgosError);
        });
    });
  }

  getAuthorizationHeader(): string {
    if (this.isJwtValid(this.rawJwt) === false) {
      return '';
    }

    return `Bearer ${this.rawJwt}`;
  }

  getAccessType(): string {
    return this.accessType;
  }

  getLoggedUser(): IUserAccountModel {
    if (check.not.assigned(this.loggedUser)) {
      return null;
    }

    return Object.assign({}, this.loggedUser);
  }

  isUserAuthenticated(): boolean {
    return this.isJwtValid(this.rawJwt) && check.assigned(this.loggedUser);
  }

  refreshLoggedUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.isUserAuthenticated() === false) {
        resolve();
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.rawJwt}`);

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<IUserAccountModel>(`${this.USER_ACCOUNT_URL}/me`, httpOptions)
        .toPromise()
        .then((userAccountResponseData) => {
          this.loggedUser = <IUserAccountModel>userAccountResponseData;
          this.injector.get(PrivateInternationalizationService).setUserLanguage(this.loggedUser.language);
          this.injector.get(PrivateInternationalizationService).setUserLocale(this.loggedUser.locale);

          if (this.getAccessType() === ACCESS_TYPE_FULL_ACCESS || this.getAccessType() === ACCESS_TYPE_ONBOARDING_ACCESS) {
            this.injector.get(PrivateSubscriptionService).setUserOrgSubscription();
          }

          // Find the profile of the user:
          this.loggedUser.profile = {
            name: 'Employee',
            _profileKey: 'employee',
            _isStandard: true,
            _isAdmin: false
          };
          if (check.assigned(this.loggedUser.profileKey) && check.nonEmptyString(this.loggedUser.profileKey)) {
            profiles
              .getProfile(this.loggedUser.profileKey)
              .then((profile: any) => {
                this.loggedUser.profile = profile;
              })
              .catch((error) => {
                // If we cannot assure the loggedUser, we set with the minimum permissions.
                this.loggedUser.profile = {
                  name: 'Employee',
                  _profileKey: 'employee',
                  _isStandard: true,
                  _isAdmin: false
                };

                // A silent error is sent (it can be a programming error)
                // The user is not notify, maybe the user cannot execute some actions.
                this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateAuthenticationService.name, 'refreshLoggedUser');
              });
          }

          resolve();
        })
        .catch((error) => {
          // The authentication is clear to force a new authentication
          this.clearAuthenticationState();

          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateAuthenticationService.name, 'refreshLoggedUser'));
        });
    });
  }

  private isJwtValid(rawJwt: string): boolean {
    this.accessType = ACCESS_TYPE_NO_ACCESS;
    if (check.not.assigned(rawJwt) || check.not.string(rawJwt) || check.emptyString(rawJwt)) {
      return false;
    }

    let jwt: any = {};
    try {
      jwt = KJUR.jws.JWS.parse(rawJwt);
    } catch (error) {
      return false;
    }

    if (
      check.not.assigned(jwt) ||
      check.not.object(jwt) ||
      check.emptyObject(jwt) ||
      check.not.assigned(jwt.headerObj) ||
      check.not.object(jwt.headerObj) ||
      check.emptyObject(jwt.headerObj) ||
      check.not.assigned(jwt.payloadObj) ||
      check.not.object(jwt.payloadObj) ||
      check.emptyObject(jwt.payloadObj) ||
      check.not.assigned(jwt.headerObj.alg) ||
      check.not.string(jwt.headerObj.alg) ||
      check.emptyString(jwt.headerObj.alg) ||
      jwt.headerObj.alg !== 'HS512' ||
      check.not.assigned(jwt.headerObj.typ) ||
      check.not.string(jwt.headerObj.typ) ||
      check.emptyString(jwt.headerObj.typ) ||
      check.not.assigned(jwt.payloadObj.iss) ||
      check.not.string(jwt.payloadObj.iss) ||
      check.emptyString(jwt.payloadObj.iss) ||
      check.not.assigned(jwt.payloadObj.sub) ||
      check.not.string(jwt.payloadObj.sub) ||
      check.emptyString(jwt.payloadObj.sub) ||
      check.not.assigned(jwt.payloadObj.aud) ||
      check.not.string(jwt.payloadObj.aud) ||
      check.emptyString(jwt.payloadObj.aud) ||
      check.not.assigned(jwt.payloadObj.iat) ||
      check.not.number(jwt.payloadObj.iat) ||
      check.not.assigned(jwt.payloadObj.nbf) ||
      check.not.number(jwt.payloadObj.nbf) ||
      check.not.assigned(jwt.payloadObj.exp) ||
      check.not.number(jwt.payloadObj.exp) ||
      check.not.assigned(jwt.payloadObj[fieldConstants.ORG_ID]) ||
      check.not.string(jwt.payloadObj[fieldConstants.ORG_ID]) ||
      check.emptyString(jwt.payloadObj[fieldConstants.ORG_ID]) ||
      check.not.assigned(jwt.payloadObj.accessType) ||
      check.not.string(jwt.payloadObj.accessType) ||
      check.emptyString(jwt.payloadObj.accessType)
    ) {
      return false;
    }

    this.accessType = jwt.payloadObj.accessType;
    return true;
  }

  // If this method receives a new token, that means that it comes form a multi-org switch.
  async signOut(newAccessToken: string = null): Promise<void> {
    try {
      if (this.isUserAuthenticated() === false) {
        this.expireSession();
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${this.rawJwt}`);

      const httpOptions = {
        headers: httpHeaders,
        withCredentials: true
      };

      const body = {
        token: this.rawJwt
      };

      await this.injector.get(HttpClient).post(`${this.AUTH_URL}/revoke`, body, httpOptions).toPromise();

      this.expireSession(newAccessToken);
    } catch (error) {
      // A silent error is sent (it can be a programming error)
      // The user is not notify, because it is not critical and we force the sign out in the app.
      this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateAuthenticationService.name, 'signOut');
      this.expireSession();
    }
  }

  expireSession(newAccessToken: string = null): void {
    // The authentication is clear to force a new authentication
    this.clearAuthenticationState(newAccessToken);
    this.injector.get(PrivateSecurityService).clearCache();
    this.injector.get(UserPersonalService).clearCache();
    this.injector.get(UserSegmentationService).clearCache();
    this.injector.get(PrivateSearchService).clearSearchInfo();
    this.injector.get(PrivateChurnzeroService).churnZeroSignOut();
    this.injector.get(PrivateHubspotService).hubspotSignOut();
    this.injector.get(PrivateFreshdeskService).freshdeskWidgetSignOut();

    if (check.not.assigned(newAccessToken)) {
      this.injector.get(Router).navigateByUrl('/signin');
    }
  }

  private clearAuthenticationState(newAccessToken: string = null): void {
    this.rawJwt = newAccessToken ?? '';
    this.loggedUser = null;
    this.injector.get(PrivateInternationalizationService).setUserLanguage(null);
    this.injector.get(PrivateInternationalizationService).setUserLocale(null);
    this.injector.get(PrivateSubscriptionService).clearSubscription();
    this.accessType = ACCESS_TYPE_NO_ACCESS;
  }

  async getNewTokenAndSignOutForMultiOrg(loggedUserId, targetOrgId): Promise<string> {
    try {
      if (this.isUserAuthenticated() === false) {
        this.expireSession();
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const { jwt: rawJwt } = await this.injector.get(HttpClient).get<any>(`${this.AUTH_URL}/multi-org/${loggedUserId}/${targetOrgId}`, httpOptions).toPromise();

      if (!this.isJwtValid(rawJwt)) {
        throw new OrgosError(`${this.AUTH_URL}/multi-org/${loggedUserId}/${targetOrgId}`, ErrorCodes.UNAUTHORIZED, PrivateAuthenticationService.name, 'switchOrganization');
      }

      await this.signOut(rawJwt);

      return rawJwt;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateAuthenticationService.name, 'switchOrganization');
    }
  }
}
