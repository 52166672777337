import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorCodes } from '../../core/error/error-codes';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class IcalService {
  private ICAL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/ical`;

  constructor(private http: HttpClient, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService) {}

  getAvailableCalendars(): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.ICAL_URL}/available-calendars`, httpOptions)
        .toPromise()
        .then((availableCalendars: Array<any>) => {
          resolve(availableCalendars);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawErrorSilently(error, IcalService.name, 'getAvailableCalendars'));
        });
    });
  }
}
