import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

import { InternationalizationService } from '../../../../../services/core/internationalization.service';

@Component({
  selector: 'kenjo-show-work-schedule-dialog',
  templateUrl: 'show-work-schedule.dialog.html',
  styleUrls: ['show-work-schedule.dialog.scss'],
})
export class ShowWorkScheduleDialog implements OnInit {
  dialogTranslation: any = {};
  constructor(
    public dialogRef: MatLegacyDialogRef<ShowWorkScheduleDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public workScheduleTemplate: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('show-work-schedule-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
