<orgos-dialog-container
  class="ssd-dialog-box"
  [title]="signatureOptions.signersOption === 'specific' ? dialogTranslation.dialogHeader : dialogTranslation.dialogEditHeader"
  (clickCloseButton)="closeDialog()"
>
  <orgos-column-container centerColumns="true" *ngIf="signatureOptions.signersOption === 'specific'" class="kenjo-font-size-16px">
    <orgos-column size="0"> {{dialogTranslation.dialogSubheader}} </orgos-column>
    <kenjo-icon
      orgosColumn="0"
      [size]="18"
      class="kenjo-font-color-success-00b72e kenjo-ml-5px kenjo-cursor-pointer"
      [matTooltip]="dialogTranslation.deliveryModeHelpTooltip"
      >help_outline</kenjo-icon
    >
  </orgos-column-container>
  <div class="kenjo-mt-30px">
    <mat-radio-group
      *ngIf="signatureOptions.signersOption === 'specific'"
      [ngModel]="signatureOptions.signingMode"
      (change)="onChangeSendingOption($event)"
    >
      <mat-radio-button
        *ngFor="let option of sendingOptions; let last = last"
        [value]="option.value"
        [ngClass]="{'ssd-last-radio-option': last}"
      >
        {{ option.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Sequential -->
  <div *ngIf="signatureOptions.signersOption === 'specific' && signatureOptions.signingMode === 'sequential'">
    <!-- Signers -->
    <div class="kenjo-mt-30px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{dialogTranslation.selectedEmployeesTitle}}</div>
    <div class="ssd-sequential-employees kenjo-mt-10px">
      <div *ngIf="usersLoaded" cdkDropList (cdkDropListDropped)="dropSigner($event)">
        <div
          cdkDrag
          class="ssd-sequential-signer-item kenjo-mt-10px kenjo-pr-10px"
          *ngFor="let signer of signatureOptions.recipients; let orderIndex = index"
        >
          <div class="ssd-sequential-signer-item-order">
            <div cdkDragHandle class="ssd-handler">
              <kenjo-icon [size]="24" class="kenjo-block kenjo-mr-10px">drag_indicator</kenjo-icon>
            </div>
            <div class="kenjo-text-transform-uppercase kenjo-font-color-light-text-757575 kenjo-font-size-12px">
              {{dialogTranslation.signerPositionLabel}} {{orderIndex + 1}}
            </div>
          </div>
          <div style="flex-grow: 1">
            <signer-autocomplete
              [label]="dialogTranslation.searchForEmployeesLabel"
              [model]="signatureOptions.recipients[orderIndex]"
              field="_id"
              [users]="availableSequentialSigners(signatureOptions.recipients[orderIndex])"
              (modelChange)="addSequentialSigner(signatureOptions.recipients[orderIndex], orderIndex)"
            ></signer-autocomplete>
          </div>
        </div>
      </div>
      <div class="ssd-add-signer-option kenjo-mt-10px kenjo-border kenjo-border-radius kenjo-p-10px kenjo-pl-20px kenjo-mr-10px">
        <orgos-action icon="add_circle" iconSize="normal" (click)="addSequentialSignerInput()">
          {{dialogTranslation.addAnotherSignerButtonLabel}}
        </orgos-action>
      </div>
    </div>

    <!-- Assignment -->
    <div class="kenjo-mt-40px kenjo-mb-30px">
      <div class="ssd-title kenjo-font-size-20px">{{dialogTranslation.selectAssignmentHeader}}</div>
    </div>
    <orgos-column-container centerColumns="true" class="kenjo-font-size-16px kenjo-mt-20px">
      <orgos-column size="0"> {{dialogTranslation.selectAssignmentSubheader}} </orgos-column>
      <kenjo-icon
        orgosColumn="0"
        [size]="18"
        class="kenjo-font-color-success-00b72e kenjo-ml-5px kenjo-cursor-pointer"
        [matTooltip]="dialogTranslation.selectAssignmentTooltip"
        >help_outline</kenjo-icon
      >
    </orgos-column-container>
    <div class="kenjo-mt-30px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{dialogTranslation.selectedEmployeesTitle}}</div>
    <div class="ssd-sequential-employees kenjo-mt-10px">
      <div *ngIf="usersLoaded" cdkDropList (cdkDropListDropped)="dropOwner($event)">
        <div
          cdkDrag
          class="ssd-sequential-signer-item kenjo-mt-10px kenjo-pr-10px"
          *ngFor="let owner of signatureOptions.docOwners; let orderIndex = index"
        >
          <div class="ssd-sequential-signer-item-order">
            <div cdkDragHandle class="ssd-handler">
              <kenjo-icon [size]="24" class="kenjo-block kenjo-mr-10px">drag_indicator</kenjo-icon>
            </div>
            <div class="kenjo-text-transform-uppercase kenjo-font-color-light-text-757575 kenjo-font-size-12px">
              {{dialogTranslation.assignmentPositionLabel}} {{orderIndex + 1}}
            </div>
          </div>
          <div style="flex-grow: 1">
            <signer-autocomplete
              [label]="dialogTranslation.searchForEmployeesLabel"
              [model]="signatureOptions.docOwners[orderIndex]"
              field="_id"
              [users]="getAvailableOwners(signatureOptions.docOwners[orderIndex])"
              (modelChange)="addOwner(signatureOptions.docOwners[orderIndex], orderIndex)"
            ></signer-autocomplete>
          </div>
        </div>
      </div>
      <div class="ssd-add-signer-option kenjo-mt-10px kenjo-border kenjo-border-radius kenjo-p-10px kenjo-pl-20px kenjo-mr-10px">
        <orgos-action icon="add_circle" iconSize="normal" (click)="addOwnerInput()">
          {{dialogTranslation.addAssignmentButton}}
        </orgos-action>
      </div>
    </div>
  </div>

  <!-- Parallel -->
  <div *ngIf="signatureOptions.signersOption === 'specific' && signatureOptions.signingMode === 'parallel'">
    <div class="kenjo-mt-40px kenjo-font-size-16px">{{dialogTranslation.selectEmployeesTitle}}</div>

    <div *ngIf="usersLoaded" class="ssd-search">
      <orgos-search
        [placeholder]="dialogTranslation.searchForEmployeesLabel"
        [searchFunction]="searchUserFunction"
        (searchResultsChange)="searchResults = $event"
        class="kenjo-mt-15px kenjo-border kenjo-border-radius"
      >
        <orgos-text class="orgos-search-error" color="Warn">{{dialogTranslation.dialogNotFoundPeopleError}}</orgos-text>

        <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="addParallelSigner(result._id)">
          <kenjo-truncate-text>{{result.displayName}}</kenjo-truncate-text>
        </orgos-search-option>

        <div class="ssd-parallel-signers-grid orgos-search-selection-container">
          <div *ngFor="let signer of signatureOptions.recipients" class="kenjo-p-5px ssd-parallel-signer-item">
            <div
              class="kenjo-border-radius"
              [class.ssd-active-user]="signer.isActive === true"
              [class.ssd-inactive-user]="signer.isActive !== true"
              [matTooltip]="signer.isActive ? null : dialogTranslation.inactiveEmployeeTooltip"
            >
              <orgos-avatar
                orgosColumn="0"
                [photoUrl]="signer._photo?._url"
                [avatarName]="signer.name"
                size="small"
                class="kenjo-mr-10px"
              ></orgos-avatar>
              <div class="kenjo-font-size-12px kenjo-text-align-center">{{signer.name}}</div>
              <div class="kenjo-p-10px">
                <kenjo-icon [size]="16" class="kenjo-cursor-pointer kenjo-block" (click)="removeParallelSigner(signer._id)"
                  >clear</kenjo-icon
                >
              </div>
            </div>
          </div>
        </div>
      </orgos-search>
    </div>
  </div>

  <!-- Edit all employees option -->
  <div *ngIf="signatureOptions.signersOption === 'all'">
    <div class="ssd-edit-signers-toolbar">
      <div>
        <p class="kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-m-0">{{dialogTranslation.selectCompanyOfficeDepartment}}</p>
        <mat-form-field [matMenuTriggerFor]="selectMenu">
          <mat-label kenjoRemoveAriaOwns class="kenjo-font-size-14px">{{allEmployeesLabel}}</mat-label>
          <mat-select></mat-select>
        </mat-form-field>
        <mat-menu #selectMenu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="companiesMenu">{{dialogTranslation.companyLabel}}</button>
          <button mat-menu-item [matMenuTriggerFor]="officesMenu">{{dialogTranslation.officeLabel}}</button>
          <button mat-menu-item [matMenuTriggerFor]="departmentsMenu">{{dialogTranslation.departmentLabel}}</button>
        </mat-menu>
        <mat-menu #companiesMenu="matMenu">
          <div *ngFor="let company of allCompanies" mat-menu-item (click)="$event.stopPropagation()">
            <mat-checkbox [ngModel]="getCheckedCompanyValue(company._id)" (ngModelChange)="changeSelectedCompanies($event, company)"
              >{{ company.name }}</mat-checkbox
            >
          </div>
        </mat-menu>
        <mat-menu #officesMenu="matMenu">
          <div *ngFor="let office of allOffices" mat-menu-item (click)="$event.stopPropagation()">
            <mat-checkbox [ngModel]="getCheckedOfficeValue(office._id)" (ngModelChange)="changeSelectedOffices($event, office)"
              >{{ office.name }}</mat-checkbox
            >
          </div>
        </mat-menu>
        <mat-menu #departmentsMenu="matMenu">
          <div *ngFor="let department of allDepartments" mat-menu-item (click)="$event.stopPropagation()">
            <mat-checkbox
              [ngModel]="getCheckedDepartmentValue(department._id)"
              (ngModelChange)="changeSelectedDepartments($event, department)"
              >{{ department.name }}</mat-checkbox
            >
          </div>
        </mat-menu>
      </div>
      <div class="kenjo-font-size-14px">
        <div class="kenjo-ml-40px">
          <mat-slide-toggle [checked]="signatureOptions.includeInactiveEmployees" (change)="changeIncludeInactive($event.checked)"
            >Include inactive employees</mat-slide-toggle
          >
        </div>
      </div>
    </div>

    <div class="kenjo-mt-30px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{dialogTranslation.selectedEmployeesTitle}}</div>

    <div class="kenjo-border kenjo-border-radius ssd-parallel-signers-grid kenjo-mt-10px kenjo-p-10px">
      <div *ngFor="let signer of signatureOptions.recipients" class="kenjo-p-5px ssd-parallel-signer-item">
        <div
          class="kenjo-border-radius"
          [ngClass]="{ 'ssd-inactive-user': !signer.isActive, 'ssd-active-user': signer.isActive }"
          [matTooltip]="signer.isActive ? null : dialogTranslation.inactiveEmployeeTooltip"
        >
          <orgos-avatar
            orgosColumn="0"
            [photoUrl]="signer._photo?._url"
            [avatarName]="signer.name"
            size="small"
            class="kenjo-mr-10px"
          ></orgos-avatar>
          <div class="kenjo-font-size-12px kenjo-text-align-center">{{signer.name}}</div>
          <div class="kenjo-p-10px">
            <kenjo-icon [size]="16" class="kenjo-cursor-pointer kenjo-block" (click)="removeParallelSigner(signer._id)">clear</kenjo-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column></orgos-column>
    <orgos-button-raised orgosColumn="0" (click)="onCancelSelectSigners()" color="Neutral"
      >{{dialogTranslation.goBackButtonLabel}}</orgos-button-raised
    >
    <orgos-button-raised orgosColumn="0" (click)="onSaveSelectSigners()" color="Success" [disabled]="false" class="kenjo-ml-10px"
      >{{dialogTranslation.saveButtonLabel}}</orgos-button-raised
    >
  </orgos-column-container>
</orgos-dialog-container>
