<ng-container *ngIf="options.length > 0">
  <div
    [matMenuTriggerFor]="optionsMenu"
    (menuClosed)="closeFilterField()"
    [class.kenjo-border-right]="hasBorder"
    class="fbf-container kenjo-cursor-pointer"
  >
    <div class="kenjo-mh-10px fbf-label">{{ label }}</div>
    <div class="fbf-count-chip" [class.fbf-chip-hidden]="optionsActiveCount === 0" class="kenjo-mr-10px">
      <orgos-chip
        buttonIconName="clear"
        color="Primary"
        [hoverButton]="true"
        [onClickButtonFunction]="optionsActiveCount > 0 ? handleClearClick : undefined"
      >
        {{ optionsActiveCount > 0 ? optionsActiveCount : ' ' }}
      </orgos-chip>
    </div>
    <kenjo-icon class="fbf-dropdown-arrow kenjo-font-color-light-text-757575">arrow_drop_down</kenjo-icon>
  </div>
  <mat-menu #optionsMenu="matMenu">
    <ng-container *ngIf="pickerType === 'multiple'">
      <div class="fbf-search fbf-sticky kenjo-color-white-background-ffffff" *ngIf="hasSearch">
        <orgos-input-simple-text
          class="kenjo-block kenjo-mh-15px"
          (click)="$event.stopPropagation()"
          [label]="'Search'"
          (valueChange)="search($event)"
          suffixIcon="search"
        >
        </orgos-input-simple-text>
      </div>
      <div
        [class.fbf-option-list-container-search]="showApplyFilterButton && hasSearch"
        [class.fbf-option-list-container]="showApplyFilterButton && !hasSearch"
      >
        <div
          mat-menu-item
          *ngFor="let option of matchedOptions; let optionIndex = index"
          (click)="handleValueChange(optionIndex, $event)"
          class="fbf-option"
        >
          <orgos-input-simple-checkbox
            (valueChange)="handleValueChange(optionIndex, $event)"
            (click)="$event.stopPropagation()"
            [value]="option.active"
            class="kenjo-mr-10px fbf-checkbox"
          ></orgos-input-simple-checkbox>
          <orgos-avatar
            *ngIf="showAvatar"
            [photoUrl]="option.photoUrl"
            [avatarName]="option.name"
            size="smaller"
            class="kenjo-mr-10px"
          ></orgos-avatar>
          <kenjo-simple-truncate-text class="kenjo-mr-30px fbf-name">{{ option.name }}</kenjo-simple-truncate-text>
        </div>
      </div>
      <div *ngIf="matchedOptions.length === 0" class="kenjo-font-color-light-text-757575 kenjo-m-15px">{{ noMatchingEmployeesLabel }}</div>
      <orgos-column-container
        class="kenjo-p-15px kenjo-border-top fbf-apply-filter fbf-sticky kenjo-color-white-background-ffffff"
        *ngIf="showApplyFilterButton"
      >
        <orgos-column>
          <orgos-button [disabled]="loading || !optionsActiveCount > 0" (click)="handleClearClick($event)" color="Primary"
            ><span class="fbf-remove-filters-button">{{ buttonTranslations.reset }}</span></orgos-button
          >
        </orgos-column>
        <orgos-column>
          <orgos-button-raised [disabled]="loading || !filterDifference[optionKey]" (click)="applyFilter()" color="Primary">
            {{ buttonTranslations.apply }}
          </orgos-button-raised>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <ng-container *ngIf="pickerType === 'single'">
      <mat-radio-group>
        <div mat-menu-item *ngFor="let option of options; let optionIndex = index" class="fbf-option kenjo-p-0">
          <orgos-avatar
            *ngIf="showAvatar"
            [photoUrl]="option.photoUrl"
            [avatarName]="option.name"
            size="smaller"
            class="kenjo-mr-10px"
          ></orgos-avatar>
          <mat-radio-button [value]="option.active" [checked]="option.active" (click)="handleValueChangeRadioButton(optionIndex, $event)"
            ><kenjo-simple-truncate-text class="kenjo-mr-30px fbf-name">{{ option.name }}</kenjo-simple-truncate-text></mat-radio-button
          >
        </div>
      </mat-radio-group>
    </ng-container>
  </mat-menu>
</ng-container>
