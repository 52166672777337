<div *ngIf="i18n.page && parentTranslation">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">{{parentTranslation.generalHeader}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{parentTranslation.generalSettings}}</span></div>
  <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
    <div orgosColumn="1" class="kenjo-font-size-16px">{{i18n.page.hoursOperation}}</div>
    <mat-slide-toggle orgosColumn="1" [checked]="false"> </mat-slide-toggle>
  </orgos-column-container>
  <div class="kenjo-mt-20px kenjo-font-color-light-text-757575">{{i18n.page.rangeExplanation}}</div>

  <orgos-column-container centerColumns="true" class="kenjo-mt-20px">
    <orgos-input-simple-time orgosColumn="0" class="kenjo-mr-20px" [enableClearButton]="true" [label]="i18n.page.from"></orgos-input-simple-time>
    <orgos-input-simple-time orgosColumn="0" [enableClearButton]="true" [label]="i18n.page.to"></orgos-input-simple-time>
  </orgos-column-container>
</div>
