import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class DatevExportService implements IGenericService {
  private DATEV_EXPORT_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/datev-export-db`;

  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getById(id: string, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  updateById(id: string, data: any, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  deleteById(id: string, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  getPermissions(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getFieldsTranslations(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getData?(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async findByPeriodIdentifier(datevSettingsId: string, periodIdentifier: number): Promise<any> {
    try {
      const datevExports = await this.injector.get(HttpClient).post<any>(`${this.DATEV_EXPORT_MICROSERVICE_URL}/find-by-period-identifier`, { datevSettingsId, periodIdentifier }).toPromise();
      return datevExports;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevExportService.name, 'findByPeriodIdentifier');
    }
  }

  async saveNewVersion(datevSettingsId: string, from: Date, to: Date) {
    try {
      await this.injector.get(HttpClient).post<any>(`${this.DATEV_EXPORT_MICROSERVICE_URL}/save-new-version`, { datevSettingsId, from, to }).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevExportService.name, 'saveNewVersion');
    }
  }

  async confirm(datevExports: any) {
    try {
      await this.injector.get(HttpClient).post<any>(`${this.DATEV_EXPORT_MICROSERVICE_URL}/confirm`, { datevExports }).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevExportService.name, 'confirm');
    }
  }

  async close(datevExports: any) {
    try {
      await this.injector.get(HttpClient).post<any>(`${this.DATEV_EXPORT_MICROSERVICE_URL}/close`, { datevExports }).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevExportService.name, 'confirm');
    }
  }

  async reopen(datevExports: any) {
    try {
      await this.injector.get(HttpClient).post<any>(`${this.DATEV_EXPORT_MICROSERVICE_URL}/reopen`, { datevExports }).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevExportService.name, 'reopen');
    }
  }
}

interface IDatevExport {
  _fromDate: Date;
  _toDate: Date;
  content: string;
  _companyId: string;
  lastExportDate: Date;
  confirmed: boolean;
}
