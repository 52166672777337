import { Component, Inject, Injector, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IAttendanceConflict } from '@app/cloud-features/settings-attendance/services/attendance-conflicts.service';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { calculateIsWithinShift, calculateNextShiftDate } from '@app/common-components/widgets/widgets.helpers';
import { IUserAccountModel } from '@app/models/user-account.model';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import {
  formatWorkSchedule,
  getCurrentWorkScheduleFromHistory,
} from '@app/standard/pages/people-detail/people-detail-attendance/people-detail.helpers';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import {
  AttendanceSummaryController,
  IExpectedHoursByDayObject,
} from '@app/standard/services/user/controllers/attendance-summary.controller';
import { IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import { IUserWorkScheduleModel } from '@app/standard/services/user/user-work-schedule.service';
import * as moment from 'moment';

@Component({
  templateUrl: 'breaks.dialog.html',
  styleUrls: ['breaks.dialog.scss'],
})
export class BreaksDialog {
  translations: any = {};
  userAttendance: IUserAttendanceModel;
  limits: { maxShiftLength: number; maxHoursPerDay: number };
  minutesWorkedThisDay = 0;
  readOnly = false;
  otherShiftsThisDay: Array<IUserAttendanceModel>;
  userWorkSchedule: IUserWorkScheduleModel;
  attendancePolicy: IAttendancePolicy;
  loggedUser: IUserAccountModel;
  expectedHoursByDay: IExpectedHoursByDayObject;
  attendanceConflictsToday: IAttendanceConflict[];
  restrictTimeLimit: number | string;
  nextShiftDate: string;
  isRestrictCheckIn: boolean;
  WORK_SCHEDULE_TYPE = 'fixed';

  constructor(
    public dialogRef: MatLegacyDialogRef<BreaksDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {
    this.translations = this.data.translations;
    this.userAttendance = this.data.userAttendance;
    this.attendancePolicy = this.data.attendancePolicy;
    this.userWorkSchedule = this.data.userWorkSchedule;
    this.limits = this.data.limits;
    this.minutesWorkedThisDay = this.data.minutesWorkedThisDay;
    this.readOnly = this.data.readOnly;
    this.otherShiftsThisDay = this.data.otherShiftsThisDay;
    this.attendanceConflictsToday = this.data.attendanceConflictsToday;
  }

  async closeDialog(saveOperation: {
    save: boolean;
    hasChanges?: boolean;
    newAttendance?: IUserAttendanceModel;
    operation?: 'saveNewBreak' | 'saveOldBreak';
  }) {
    if (!saveOperation.save && saveOperation.hasChanges) {
      const dialogData = {
        titleText: this.translations.closeDialog.title,
        subtitleText: this.translations.closeDialog.subtitle,
        confirmButtonText: this.translations.closeDialog.confirm,
        confirmButtonColor: 'Danger',
        cancelButtonText: this.translations.closeDialog.cancel,
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
      const confirm = await dialogRef.afterClosed().toPromise();
      if (confirm) {
        this.dialogRef.close(saveOperation);
      }
    } else {
      this.dialogRef.close(saveOperation);
    }
  }

  async fetchData() {
    try {
      this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      const { expectedHoursByDay } = await this.getExpectedHours();
      this.expectedHoursByDay = expectedHoursByDay;
    } catch {}
  }

  private restrictCheckIn(startTime: number) {
    const isAdmin = ['admin', 'hr-admin'].includes(this.loggedUser.profileKey);
    const language = this.loggedUser.language || 'en';
    const { isActive } = this.attendancePolicy?.restrictCheckIn;
    if (isAdmin || !isActive) {
      return false;
    }
    const date = moment();
    const fixedWorkSchedule = getCurrentWorkScheduleFromHistory(date, this.userWorkSchedule);
    this.userWorkSchedule = formatWorkSchedule(fixedWorkSchedule, this.userWorkSchedule);
    const {
      mondayWorkingDay,
      tuesdayWorkingDay,
      wednesdayWorkingDay,
      thursdayWorkingDay,
      fridayWorkingDay,
      saturdayWorkingDay,
      sundayWorkingDay,
    } = this.userWorkSchedule;
    const workingDays = [
      mondayWorkingDay,
      tuesdayWorkingDay,
      wednesdayWorkingDay,
      thursdayWorkingDay,
      fridayWorkingDay,
      saturdayWorkingDay,
      sundayWorkingDay,
    ];
    let dayIndex = moment(date).day() - 1;
    const currentDay = moment(date).date();
    if (startTime) {
      this.restrictTimeLimit = 1440;
    }

    if (!fixedWorkSchedule || fixedWorkSchedule.type !== this.WORK_SCHEDULE_TYPE) {
      return false;
    }

    if (!workingDays[dayIndex]) {
      this.setNextShiftValues(date, dayIndex, fixedWorkSchedule, this.expectedHoursByDay, language);
      return true;
    }

    if (this.expectedHoursByDay[currentDay].bankHolidays) {
      this.setNextShiftValues(date, dayIndex, fixedWorkSchedule, this.expectedHoursByDay, language);
      return true;
    }

    const shiftsOnDay = fixedWorkSchedule.dayShifts[dayIndex].shifts;
    const { isWithinShift, restrictTimeLimit, nextShiftDate } = calculateIsWithinShift(shiftsOnDay, startTime, {
      dayOfMonth: date,
      dayIndex,
      fixedWorkSchedule,
      restrictTimeLimit: this.restrictTimeLimit,
      userWorkSchedule: this.userWorkSchedule,
      attendancePolicy: this.attendancePolicy,
      expectedHoursByDay: this.expectedHoursByDay,
      language,
    });
    this.restrictTimeLimit = restrictTimeLimit;
    this.nextShiftDate = nextShiftDate;
    return !isWithinShift;
  }

  setNextShiftValues(
    date: moment.Moment,
    dayIndex: number,
    fixedWorkSchedule,
    expectedHoursByDay: IExpectedHoursByDayObject,
    language: string
  ) {
    const { nextShiftDate, restrictTimeLimit } = calculateNextShiftDate({
      dayOfMonth: date,
      dayIndex,
      fixedWorkSchedule,
      restrictTimeLimit: this.restrictTimeLimit,
      userWorkSchedule: this.userWorkSchedule,
      attendancePolicy: this.attendancePolicy,
      expectedHoursByDay,
      language,
    });
    this.nextShiftDate = nextShiftDate;
    this.restrictTimeLimit = restrictTimeLimit;
  }

  async getExpectedHours() {
    const response = await this.injector
      .get(AttendanceSummaryController)
      .getExpectedHoursByUserMonth((this.loggedUser as IUserAccountModel)._id, moment().month(), moment().year(), true);

    return response;
  }

  async onShiftChanges($event) {
    await this.fetchData();
    this.isRestrictCheckIn = this.restrictCheckIn($event.startTime);
  }
}
