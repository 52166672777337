import { Component, Input, OnInit } from '@angular/core';
import { IShiftPlanRoleSettingsModel } from '@app/cloud-features/shift-plan/services/settings-shift-plan-role.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-shiftplan-pdf-footer',
  templateUrl: './shiftplan-pdf-footer.component.html',
  styleUrls: ['./shiftplan-pdf-footer.component.scss'],
})
export class ShiftplanPdfFooterComponent implements OnInit {
  MAX_LENGTH_CHAR = 20;
  colorConstants: any = userColorConstants;

  @Input() roles: Array<IShiftPlanRoleSettingsModel>;
  @Input() color: boolean = false;
  constructor() {}

  ngOnInit() {
    this.parseRoleName();
  }

  parseRoleName() {
    this.roles = this.roles.map((role) => {
      const newRole = { ...role };
      if (role.name.length > this.MAX_LENGTH_CHAR) {
        newRole.name = `${role.name.slice(0, this.MAX_LENGTH_CHAR)}...`;
      }
      return newRole;
    });
  }
}
