import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { CompanyService } from '@app/standard/services/company/company.service';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { OfficeService } from '@app/standard/services/company/office.service';
import { ISignatureOptions } from '@app/standard/services/signature-request/signature-request.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserService } from '@app/standard/services/user/user.service';
import * as _ from 'lodash';

import { InternationalizationService } from '../../../services/core/internationalization.service';

@Component({
  selector: 'orgos-select-signers.dialog',
  templateUrl: 'select-signers.dialog.html',
  styleUrls: ['select-signers.dialog.scss'],
})
export class SelectSignersDialog implements OnInit {
  dialogTranslation: any = {};
  sendingOptions: Array<ISelectOption> = []; // sequential || parallel
  availableSigners: Array<any>;
  availableOwners: Array<any>;
  allUsers: Array<any>;
  allCompanies = [];
  allOffices = [];
  allDepartments = [];
  usersLoaded = false;
  parallelSigner = new GenericCacheModel(this.injector, { _id: null }, UserPersonalService, '');
  signatureOptions: ISignatureOptions;

  searchResults: Array<any> = [];
  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  constructor(
    public dialogRef: MatLegacyDialogRef<SelectSignersDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: ISignatureOptions
  ) {}

  ngOnInit(): void {
    this.signatureOptions = {
      ...this.data,
      docOwners: [...this.data.docOwners],
      recipients: [...this.data.recipients],
      companies: [...this.data.companies],
      offices: [...this.data.offices],
      departments: [...this.data.departments],
    };
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-select-signers-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
        this.initForm();
        this.fetchData();
      })
      .catch(() => {
        this.dialogTranslation = {};
      });
  }

  fetchData() {
    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((companies) => {
        this.allCompanies = companies;
      })
      .catch((error) => {
        this.allCompanies = [];
      });

    this.injector
      .get(OfficeService)
      .getOffices()
      .then((offices) => {
        this.allOffices = offices;
      })
      .catch((error) => {
        this.allOffices = [];
      });

    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((departments) => {
        this.allDepartments = departments;
      })
      .catch((error) => {
        this.allDepartments = [];
      });
  }

  initForm() {
    this.fillSendingOptions();
    this.findUsers();
    if (this.signatureOptions.signersOption === 'specific' && this.signatureOptions.signingMode === 'sequential') {
      this.fillSequentialRecipients();
      this.fillOwners();
    }
  }

  updateAvailableSigners() {
    this.availableSigners = this.availableSigners.filter((iAvailableSigner) => {
      let user: any;
      if (this.signatureOptions.signersOption === 'specific' && this.signatureOptions.signingMode === 'sequential') {
        if (iAvailableSigner.isActive === false) {
          return false;
        }
        user = this.signatureOptions.recipients.find((signer) => signer.data._id === iAvailableSigner._id);
      } else {
        user = this.signatureOptions.recipients.find((signer) => signer._id === iAvailableSigner._id);
      }
      if (user) {
        return false;
      }
      return true;
    });
  }
  updateAvailableOwners() {
    this.availableOwners = this.availableOwners.filter((iAvailableOwner) => {
      let user: any;
      if (this.signatureOptions.signersOption === 'specific' && this.signatureOptions.signingMode === 'sequential') {
        if (iAvailableOwner.isActive === false) {
          return false;
        }
        user = this.signatureOptions.docOwners.find((owner) => owner.data._id === iAvailableOwner._id);
      } else {
        user = this.signatureOptions.docOwners.find((owner) => owner._id === iAvailableOwner._id);
      }
      if (user) {
        return false;
      }
      return true;
    });
  }

  fillSendingOptions() {
    this.sendingOptions = [
      { name: this.dialogTranslation.sequentialDeliveryOption, value: 'sequential' },
      { name: this.dialogTranslation.parallelDeliveryOption, value: 'parallel' },
    ];
  }

  private findUsers(): void {
    this.injector
      .get(UserService)
      .getAllUsersFull(false)
      .then((allUsersFull) => {
        this.allUsers = allUsersFull
          .filter((iUser: any) => iUser.userAccount.profileKey !== 'restricted')
          .map((iUser: any) => {
            return {
              _id: iUser.userPersonal._id,
              displayName: iUser.userPersonal.displayName,
              name: iUser.userPersonal.displayName,
              _photo: iUser.userPersonal._photo,
              isActive: iUser.userAccount.isActive,
              email: iUser.userAccount.email,
              company: iUser.userWork.companyId,
              office: iUser.userWork.officeId,
              department: iUser.userWork.departmentId,
            };
          })
          .sort((a: any, b: any) => {
            if (a.isActive !== b.isActive) {
              if (a.isActive) {
                return -1;
              }
              return 1;
            }
            return 0;
          });

        this.availableSigners = [...this.allUsers];
        this.availableOwners = [...this.allUsers];
        if (this.signatureOptions.recipients.length > 0) {
          this.updateAvailableSigners();
        }
        if (this.signatureOptions.docOwners.length > 0) {
          this.updateAvailableOwners();
        }
        this.usersLoaded = true;
      })
      .catch(() => {
        // An error is already shown
      });
  }

  fillSequentialRecipients() {
    if (this.signatureOptions.recipients.length === 0) {
      this.addSequentialSignerInput();
    } else {
      this.signatureOptions.recipients = this.signatureOptions.recipients.map((r) => {
        return new GenericCacheModel(this.injector, { _id: r._id }, UserPersonalService, '');
      });
    }
  }
  fillOwners() {
    if (this.signatureOptions.docOwners.length === 0) {
      this.addOwnerInput();
    } else {
      this.signatureOptions.docOwners = this.signatureOptions.docOwners.map((r) => {
        return new GenericCacheModel(this.injector, { _id: r._id }, UserPersonalService, '');
      });
    }
  }

  onChangeSendingOption(event: any) {
    this.signatureOptions.signingMode = event.value;
    this.signatureOptions.recipients = [];
    this.signatureOptions.docOwners = [];
    this.availableSigners = [...this.allUsers];
    this.availableOwners = [...this.allUsers];
    if (this.signatureOptions.signersOption === 'specific' && this.signatureOptions.signingMode === 'sequential') {
      this.availableSigners = this.availableSigners.filter((iAvailableSigner) => iAvailableSigner.isActive === true);
      this.availableOwners = this.availableOwners.filter((iAvailableOwner) => iAvailableOwner.isActive === true);
      this.addSequentialSignerInput();
    }
  }

  addSequentialSignerInput() {
    this.signatureOptions.recipients.push(new GenericCacheModel(this.injector, { _id: null }, UserPersonalService, ''));
  }
  addOwnerInput() {
    this.signatureOptions.docOwners.push(new GenericCacheModel(this.injector, { _id: null }, UserPersonalService, ''));
  }

  addSequentialSigner() {
    this.availableSigners = this.allUsers.filter((user) => {
      if (this.signatureOptions.recipients.find((rec) => rec.data._id === user._id)) {
        return false;
      }

      if (user.isActive === false) {
        return false;
      }

      return true;
    });
  }
  addOwner() {
    this.availableOwners = this.allUsers.filter((user) => {
      if (this.signatureOptions.docOwners.find((rec) => rec.data._id === user._id)) {
        return false;
      }

      if (user.isActive === false) {
        return false;
      }

      return true;
    });
  }

  addParallelSigner(id: string) {
    const user = this.availableSigners.find((s) => s._id === id);
    if (user) {
      this.signatureOptions.recipients.push(user);
      this.availableSigners = this.availableSigners.filter((s) => s._id !== id);
    }
    this.searchComponent.clearSearch();
  }

  public searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    const results = _.chain(this.availableSigners)
      .filter((user: any) => {
        const regExp = new RegExp(`^.*${value}.*$`, 'i');
        return regExp.test(user.displayName);
      })
      .orderBy(['displayName'], ['asc'])
      .value();
    return Promise.resolve(results);
  };

  removeParallelSigner(signerId: string) {
    this.signatureOptions.recipients = this.signatureOptions.recipients.filter((s) => s._id !== signerId);
    this.availableSigners = this.allUsers.filter((s) => {
      const user = this.signatureOptions.recipients.find((signer) => signer._id === s._id);
      if (user) {
        return false;
      }
      return true;
    });
  }

  get allEmployeesLabel(): string {
    if (
      this.signatureOptions.companies.length === 0 &&
      this.signatureOptions.offices.length === 0 &&
      this.signatureOptions.departments.length === 0
    ) {
      return this.dialogTranslation.allEmployeesLabel;
    }

    const companies = this.signatureOptions.companies.map((c) => c.name);
    const offices = this.signatureOptions.offices.map((o) => o.name);
    const departments = this.signatureOptions.departments.map((d) => d.name);

    return [...companies, ...offices, ...departments].join(', ');
  }

  getCheckedCompanyValue(companyId: string): boolean {
    const checked = this.signatureOptions.companies.find((c) => c._id === companyId) !== undefined ? true : false;
    return checked;
  }

  changeSelectedCompanies(addingCompany: boolean, company: any): void {
    if (addingCompany) {
      this.signatureOptions.companies.push({ _id: company._id, name: company.name });
    } else {
      this.signatureOptions.companies = this.signatureOptions.companies.filter((c) => c._id !== company._id);
    }
    this.filterRecipients();
  }

  getCheckedOfficeValue(officeId: string): boolean {
    const checked = this.signatureOptions.offices.find((o) => o._id === officeId) !== undefined ? true : false;
    return checked;
  }

  changeSelectedOffices(addingOffice: boolean, office: any): void {
    if (addingOffice) {
      this.signatureOptions.offices.push({ _id: office._id, name: office.name });
    } else {
      this.signatureOptions.offices = this.signatureOptions.offices.filter((o) => o._id !== office._id);
    }
    this.filterRecipients();
  }

  getCheckedDepartmentValue(departmentId: string): boolean {
    const checked = this.signatureOptions.departments.find((d) => d._id === departmentId) !== undefined ? true : false;
    return checked;
  }

  changeSelectedDepartments(addingDepartment: boolean, department: any): void {
    if (addingDepartment) {
      this.signatureOptions.departments.push({ _id: department._id, name: department.name });
    } else {
      this.signatureOptions.departments = this.signatureOptions.departments.filter((d) => d._id !== department._id);
    }
    this.filterRecipients();
  }

  changeIncludeInactive(includeInactive: boolean) {
    this.signatureOptions.includeInactiveEmployees = includeInactive;
    this.filterRecipients();
  }

  filterRecipients() {
    this.signatureOptions.recipients = this.allUsers.filter((u) => {
      if (!this.signatureOptions.includeInactiveEmployees && !u.isActive) {
        return false;
      }

      if (
        (this.signatureOptions.companies.length === 0 || this.signatureOptions.companies.find((c) => c._id === u.company)) &&
        (this.signatureOptions.offices.length === 0 || this.signatureOptions.offices.find((c) => c._id === u.office)) &&
        (this.signatureOptions.departments.length === 0 || this.signatureOptions.departments.find((c) => c._id === u.department))
      ) {
        return true;
      }

      return false;
    });
  }

  onCancelSelectSigners() {
    this.closeDialog();
  }

  onSaveSelectSigners() {
    const recipients = this.formatRecipients();
    const docOwners = this.formatOwners();
    this.dialogRef.close({
      ...this.signatureOptions,
      recipients,
      docOwners,
    });
  }

  formatRecipients(): Array<any> {
    if (this.signatureOptions.signersOption === 'specific' && this.signatureOptions.signingMode === 'sequential') {
      return this.signatureOptions.recipients
        .filter((signer) => signer.data._id)
        .reduce((recipients, signer) => {
          const user = this.allUsers.find((s) => s._id === signer.data._id);
          if (user) {
            recipients.push(user);
          }
          return recipients;
        }, []);
    }
    return this.signatureOptions.recipients;
  }
  formatOwners(): Array<any> {
    if (this.signatureOptions.signersOption === 'specific' && this.signatureOptions.signingMode === 'sequential') {
      return this.signatureOptions.docOwners
        .filter((owner) => owner.data._id)
        .reduce((owners, owner) => {
          const user = this.allUsers.find((s) => s._id === owner.data._id);
          if (user) {
            owners.push(user);
          }
          return owners;
        }, []);
    }
    return this.signatureOptions.docOwners;
  }

  availableSequentialSigners(selectedSigner: any) {
    if (selectedSigner.data._id !== null) {
      return [...this.availableSigners, this.allUsers.find((user) => user._id === selectedSigner.data._id)];
    }

    return [...this.availableSigners];
  }
  getAvailableOwners(selectedOwner: any) {
    if (selectedOwner.data._id !== null) {
      return [...this.availableOwners, this.allUsers.find((user) => user._id === selectedOwner.data._id)];
    }

    return [...this.availableOwners];
  }

  dropSigner(event: CdkDragDrop<Array<any>>) {
    moveItemInArray(this.signatureOptions.recipients, event.previousIndex, event.currentIndex);
  }

  dropOwner(event: CdkDragDrop<Array<any>>) {
    moveItemInArray(this.signatureOptions.docOwners, event.previousIndex, event.currentIndex);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
