import { Component, Input } from '@angular/core';

@Component({
  selector: 'orgos-powered-by',
  templateUrl: 'powered-by.component.html',
  styleUrls: ['powered-by.component.scss']
})
export class PoweredByComponent {
  @Input() color = 'LightText';
  @Input() alignCenter: boolean = false;
}
