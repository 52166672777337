import { Component, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CumulService } from '@app/cloud-features/dashboards/services/cumul.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSubscriptionService } from '@app/private/services/private-subscription.service';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import { DisabledAppDialog } from '@app/standard/pages/settings-billing/settings-billing-platform-usage/disabled-app-dialog/disabled-app.dialog';
import { DisabledAppsDialog } from '@app/standard/pages/settings-billing/settings-billing-platform-usage/disabled-apps-dialog/disabled-apps.dialog';
import { PlatformUsageService } from '@app/standard/services/platform-usage/platform-usage.service';
import { SettingsBarService } from '@app/standard/services/settings/settings-bar.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { NgxCumulioDashboardComponent } from '@cumul.io/ngx-cumulio-dashboard';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-settings-billing-platform-usage',
  templateUrl: './settings-billing-platform-usage.page.html',
  styleUrls: ['./settings-billing-platform-usage.page.scss'],
})
export class SettingsBillingPlatformUsagePage extends GenericPage {
  private _bookADemoLinks = {
    en: 'https://www.kenjo.io/book-a-demo',
    de: 'https://www.kenjo.io/de/demo-buchen',
    es: 'https://www.kenjo.io/es/agendar-demo',
  };
  showLinkIfStatus: Array<string> = [picklistConstants.SUBSCRIPTION_IN_TRIAL, picklistConstants.SUBSCRIPTION_CANCELLED];

  subscriptionService: PrivateSubscriptionService;
  service: PlatformUsageService;

  // controls for page behavior
  isDatasetEmpty: boolean = false;
  isOpenDashboardFeatureEnabled: boolean = true;
  private _tabIndexToName: Array<string> = ['general', 'attendance', 'timeOff', 'shiftplan', 'payroll', 'workflows'];
  private _featuresInOtherTab: number = 3;

  // dashboard selectors
  selectedTab: number = 0;
  // temporary setup to test from an environment if the dashboard loads
  cumulAuth: any = {};
  loggedUser: any = {};
  dashboards: any = {
    generalDashboardId: '3b3d8071-3827-4ae5-b238-19026b825d3b',
    attendanceDashboardId: '9bb06cc3-0d7a-42ae-837a-02064d4d1fd5',
    timeoffDashboardId: '631f2853-52ee-414f-b21e-ef73c3f0286e',
    shiftplanDashboardId: '96d61c46-8714-492e-a245-80e529564383',
    payrollDashboardId: 'a36ed809-7cde-4f4b-850b-f33c8e1fc376',
    workflowsDashboardId: '653f531c-678e-4b14-8f28-461ac6a441e7',
    othersDashboardId: '917e30fb-7532-4589-bb28-66f31ffdae26',
  };
  qeVersion: number = 2;

  @ViewChild('generalDashboardInstance') generalDashboardInstance: NgxCumulioDashboardComponent;
  @ViewChild('attendanceDashboardInstance') attendanceDashboardInstance: NgxCumulioDashboardComponent;
  @ViewChild('timeoffDashboardInstance') timeoffDashboardInstance: NgxCumulioDashboardComponent;
  @ViewChild('shiftplanDashboardInstance') shiftplanDashboardInstance: NgxCumulioDashboardComponent;
  @ViewChild('payrollDashboardInstance') payrollDashboardInstance: NgxCumulioDashboardComponent;
  @ViewChild('worlkflowsDashboardInstance') worlkflowsDashboardInstance: NgxCumulioDashboardComponent;
  @ViewChild('othersDashboardInstance') othersDashboardInstance: NgxCumulioDashboardComponent;

  private mapIdToComponent = {};

  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'settings-billing-page' },
    { name: 'settingsTopBar', translationKey: 'settings-top-bar' },
  ];

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    // temporary code to test from an environment if the dashboard loads
    this.service = this.injector.get(PlatformUsageService);
    [this.cumulAuth, this.isDatasetEmpty] = await Promise.all([
      this.injector.get(CumulService).getSSOAuth(),
      this.service.getIsDatasetEmpty(),
    ]);
    this.loggedUser = this.getLoggedUser();
    this.dashboards.language = this.loggedUser.language || 'en';
    this.subscriptionService = this.injector.get(PrivateSubscriptionService);
    resolveFetchData();
  }

  protected async configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;
    const options = await this.injector.get(SettingsBarService).getOptions(this.i18n.settingsTopBar);
    const optionIndex = options.findIndex((option) => option.name === this.i18n.settingsTopBar.billingTab);
    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
  }

  protected onFetchingDataError(): void {
    this.refreshGlobalBar();
  }

  scheduleACallButtonClick(): void {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('Initiate call via usage dashboard', { category: 'PLG', subcategory1: 'Platform usage' });
    window.open(this._bookADemoLinks[this.dashboards.language], '_blank');
  }

  async onTabChange($event: number): Promise<void> {
    this.selectedTab = $event;
    this.service.enableDownloadButton();

    // special treatment for other tab
    if ($event === 6) {
      const otherFeaturesDisabled: Array<string> = await this.service.getDisabledOtherFeatures();
      this.isOpenDashboardFeatureEnabled = otherFeaturesDisabled.length < this._featuresInOtherTab;
      if (check.not.emptyArray(otherFeaturesDisabled)) {
        this.injector.get(MatLegacyDialog).open(DisabledAppsDialog, { data: otherFeaturesDisabled });
      }
    } else {
      this.isOpenDashboardFeatureEnabled = await this.service.isAppEnabled(this._tabIndexToName[$event]);
      if (this.isOpenDashboardFeatureEnabled === false) {
        this.injector.get(MatLegacyDialog).open(DisabledAppDialog);
      }
    }
  }

  async createPDF(tabName: string): Promise<void> {
    this.mapIdToComponent = {
      generalDashboardInstance: this.generalDashboardInstance,
      attendanceDashboardInstance: this.attendanceDashboardInstance,
      timeoffDashboardInstance: this.timeoffDashboardInstance,
      shiftplanDashboardInstance: this.shiftplanDashboardInstance,
      payrollDashboardInstance: this.payrollDashboardInstance,
      worlkflowsDashboardInstance: this.worlkflowsDashboardInstance,
      othersDashboardInstance: this.othersDashboardInstance,
    };
    const element: NgxCumulioDashboardComponent = this.mapIdToComponent[tabName];
    await this.service.printPDF(element);
  }
}
