<div class="etc-table-container kenjo-mt-20px">
  <orgos-column-container [class.kenjo-border-bottom]="isExpanded" class="etc-table-wrapper kenjo-border-bottom">
    <orgos-column size="1">
      <orgos-column-container class="etc-table-header-wrapper">
        <orgos-column size="0" [matTooltip]="headerTooltip" matTooltipPosition="right" class="etc-table-header-title">
          <div>{{ label }}</div>
          <kenjo-icon *ngIf="helpTitle && helpDescription" [size]="18" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="onPressHelp()">help</kenjo-icon>
        </orgos-column>
        <orgos-column size="0">
          <div #button>
            <orgos-action *ngIf="isAdditionEnabled" (click)="addItem(button)" class="et-icon kenjo-font-size-18px" icon="add_circle">{{ buttonText }}</orgos-action>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="0" (click)="isExpanded = !isExpanded">
      <kenjo-icon *ngIf="isExpanded" [size]="24" class="kenjo-p-15px kenjo-border-left kenjo-font-color-primary-5993e3">keyboard_arrow_up</kenjo-icon>
      <kenjo-icon *ngIf="!isExpanded" [size]="24" class="kenjo-p-15px kenjo-border-left kenjo-font-color-primary-5993e3">keyboard_arrow_down</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <div class="etc-table" [class.etc-table-hidden]="!isExpanded">
    <ng-content select="[table]"></ng-content>
  </div>
</div>

<ng-template cdkPortal>
  <ng-content select="[overlay]"></ng-content>
</ng-template>
