<div class="thm-main-container" [class.thm-manage-columns]="inColumnsSetup">
  <!-- TABLE DATA-->
  <div *ngIf="!inColumnsSetup" class="thm-table-container">
    <div class="thm-full-table-wrapper">
      <ng-container *ngIf="displayedColumns && displayedColumns.length > 0">
        <table
          mat-table
          (matSortChange)="sortColumn($event)"
          matSort
          [matSortActive]="sortBy"
          [matSortDirection]="sortOrder"
          [dataSource]="dataSource"
          [style.min-width]="tableWidth"
          [style.max-height]="'100%'"
          class="table-header-rotated"
        >
          <ng-container
            *ngFor="let tableColumn of tableColumns; let i = index"
            [matColumnDef]="tableColumn.columnKey + '-subHeader'"
            [sticky]="i === 0"
          >
            <ng-container>
              <td
                mat-header-cell
                *matHeaderCellDef
                class="sub-header"
                [class.specialColumn]="i === 0"
                [class.thm-center-content]="centerContent || tableColumn.centerContent"
              >
                <orgos-column-container centerColumns="true">
                  <orgos-column class="header-label-max-width">
                    <div class="header-text-max-width kenjo-font-size-14px" [class.kenjo-pl-20px]="i === 0">
                      {{ tableColumn.subHeader }}
                    </div>
                  </orgos-column>
                </orgos-column-container>
              </td>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let tableColumn of tableColumns; let i = index" [matColumnDef]="tableColumn.columnKey" [sticky]="i === 0">
            <ng-container *ngIf="tableColumn.sortable === true">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="thm-vertical-column mat-sort-cell"
                [class.thm-center-content]="centerContent"
              >
                <mat-icon
                  *ngIf="(omManageIcon || viewManageColumns) && manageColumns"
                  class="thm-manage-icon"
                  (mouseenter)="omManageIcon = true"
                  (mouseleave)="omManageIcon = false"
                  [matTooltip]="miscTranslation.manageColumns"
                  (click)="openManageColumns()"
                  >view_column</mat-icon
                >
                <orgos-column-container centerColumns="true">
                  <orgos-column class="header-label-max-width">
                    <div
                      class="header-text-max-width kenjo-font-size-12px"
                      [matTooltipPosition]="tooltipPosition"
                      [matTooltip]="
                        tableColumn.showTooltip === true ? (tableColumn.tooltip ? tableColumn.tooltip : tableColumn.header) : ''
                      "
                    >
                      {{ tableColumn.header | titlecase }}
                    </div>
                  </orgos-column>
                  <mat-icon
                    orgosColumn="0"
                    class="kenjo-pl-10px"
                    *ngIf="tableColumn.info"
                    class="thm-info-icon"
                    [matTooltip]="tableColumn.info"
                    >help_outline</mat-icon
                  >
                </orgos-column-container>
              </th>
            </ng-container>

            <ng-container *ngIf="tableColumn.sortable === false">
              <th
                mat-header-cell
                *matHeaderCellDef
                [class.thm-center-content]="centerContent"
                [class.kenjo-font-weight-bold]="i === 1"
                [matTooltip]="tableColumn.header | titlecase"
              >
                <div centerColumns="true" class="rotated-header-container">
                  <div class="kenjo-font-size-12px rotated-header-content">
                    <div class="header-label-content" [class.kenjo-font-weight-bold]="i === 1">
                      {{ tableColumn.header }}
                    </div>
                  </div>
                </div>
              </th>
            </ng-container>

            <td
              mat-cell
              *matCellDef="let row"
              [class.sticky-column]="i === 0"
              [class.thm-center-content]="centerContent || tableColumn.centerContent"
              [class.kenjo-p-0]="tableColumn.fullCell"
            >
              <ng-container *ngIf="tableColumn && tableColumn.tableCell && tableColumn.tableCell.templateRef">
                <ng-template
                  [ngTemplateOutlet]="tableColumn.tableCell.templateRef"
                  [ngTemplateOutletContext]="{ $implicit: row }"
                ></ng-template>
              </ng-container>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            (mouseenter)="viewManageColumns = true"
            (mouseleave)="hideManageIcon()"
          ></tr>
          <tr mat-header-row *matHeaderRowDef="subHeaders; sticky: true" class="sub-header-row"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="kenjo-pv-10px"
            [class.highlight-row]="!disableRowHighlight"
          ></tr>
        </table>
      </ng-container>
    </div>
  </div>
  <!-- END TABLE DATA-->
  <!-- MANAGE COLUMNS-->
  <div *ngIf="inColumnsSetup" [@slideInSetup] class="thm-table-container thm-manage-columns">
    <orgos-column-container centerColumns="true" class="kenjo-pv-10px kenjo-ph-20px kenjo-border-bottom">
      <orgos-column class="kenjo-font-size-16px">{{ miscTranslation.manageColumns }}</orgos-column>
      <kenjo-icon
        orgosColumn="0"
        [size]="22"
        class="kenjo-ml-30px kenjo-cursor-pointer kenjo-font-color-light-text-757575"
        (click)="closeManageColumns()"
      >
        clear
      </kenjo-icon>
    </orgos-column-container>
    <div class="kenjo-p-20px thm-manage-columns-content">
      <orgos-list-sortable [greySkin]="true" [items]="columnsControl" (changedOrder)="saveColumns()">
        <ng-container *orgosListItem="let item; let itemIndex = index">
          <orgos-column-container centerColumns="true" class="kenjo-pv-10px">
            <orgos-column size="1">
              {{ headerLabels[item.columnKey] }}
            </orgos-column>
            <orgos-column size="0">
              <mat-slide-toggle
                labelPosition="before"
                [(checked)]="item.visible"
                (change)="changeColumn($event.checked, itemIndex)"
                [disabled]="itemIndex === 0 || itemIndex === 1 || (displayedColumns.length === 1 && item.visible === true)"
              >
              </mat-slide-toggle>
            </orgos-column>
          </orgos-column-container>
        </ng-container>
      </orgos-list-sortable>
    </div>
  </div>
  <!-- END MANAGE COLUMNS -->
</div>
