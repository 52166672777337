import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IUserAccountModel } from '@app/models/user-account.model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { environment } from '@env';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AttendancePolicyService implements IGenericService {
  private URL = `${environment.PEOPLE_CLOUD_APP_URL}/attendance-policy-db`;
  private INTERNATIONALIZATION: string = 'attendance-policy-collection';
  private PERMISSION_KEY: string = 'attendance-policy';

  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService) {}

  async create(attendancePolicy: any): Promise<IAttendancePolicy> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      return await this.injector.get(HttpClient).post<IAttendancePolicy>(`${this.URL}/create`, attendancePolicy, httpOptions).toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendancePolicyService.name, 'create');
    }
  }

  async getById(id: string, operationOptions?: any): Promise<IAttendancePolicy> {
    try {
      return await this.genericService.getById(this.URL, id);
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendancePolicyService.name, 'getById');
    }
  }

  async updateById(id: string, data: any, returnError = false): Promise<void | any> {
    try {
      const dataClone = cloneDeep(data);

      const { _isDefault } = dataClone;
      if (_isDefault) {
        delete dataClone.name;
        delete dataClone.description;
      }

      await this.genericService.updateById(this.URL, id, dataClone);
    } catch (error) {
      if (returnError) {
        return error;
      }
      throw this.errorManager.handleRawError(error, AttendancePolicyService.name, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, AttendancePolicyService.name, 'deleteById');
    }
  }

  async getAttendancePolicyByUserId(id: string): Promise<IAttendancePolicy> {
    try {
      const attendancePolicyId =
        id === this.injector.get(AuthenticationService).getLoggedUser()._id
          ? this.injector.get(AuthenticationService).getLoggedUser().attendancePolicy
          : (await this.injector.get(UserAccountService).getById(id)).attendancePolicy;

      return await this.injector.get(AttendancePolicyService).getById(attendancePolicyId);
    } catch (error) {
      throw this.errorManager.handleRawError(error, AttendancePolicyService.name, 'getAttendancePolicyByUserId');
    }
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.PERMISSION_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.INTERNATIONALIZATION);
  }

  getData?(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async find(query?: any): Promise<Array<IAttendancePolicy>> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      return await this.injector
        .get(HttpClient)
        .post<Array<IAttendancePolicy>>(`${this.URL}/find`, query ?? { _id: { $ne: null } }, httpOptions)
        .toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, AttendancePolicyService.name, 'find');
    }
  }

  async getLoggedUserHasRestrictedPolicy() {
    const userAccount = this.injector.get(AuthenticationService).getLoggedUser();
    const policyIsRestricted = await this.checkPolicyHasRestrictedUpdates(userAccount);
    return policyIsRestricted;
  }

  async getUserHasRestrictedPolicy(userId: string) {
    const userAccount = await this.injector.get(UserAccountService).getById(userId);
    if (!userAccount) {
      return false;
    }
    const policyIsRestricted = await this.checkPolicyHasRestrictedUpdates(userAccount as IUserAccountModel);
    return policyIsRestricted;
  }

  private async checkPolicyHasRestrictedUpdates(userAccount: IUserAccountModel) {
    if (!userAccount.attendancePolicy) {
      return false;
    }
    const [policy] = await this.find({ _id: userAccount.attendancePolicy });
    if (!policy) {
      return false;
    }
    return (policy.methods.pinCode || policy.methods.qrCode) && !policy.methods.timeSheet;
  }

  calculateTotalSuggestedBreak(IAttendancePolicy: IAttendancePolicy) {
    return IAttendancePolicy?.breakReminder?.reminders.reduce((previousBreak, currentBreak) => {
      return previousBreak + currentBreak?.suggestedBreak;
    }, 0);
  }
}

export interface IAttendancePolicy {
  _id: string;
  _createdAt: Date;
  name: string;
  employees: number;
  description: string;
  methods: {
    timeSheet: boolean;
    punchClock: boolean;
    qrCode: boolean;
    pinCode: boolean;
  };
  locationTracking: boolean;
  allowEntriesInTheFuture: boolean;
  breakReminder: {
    reminders: Array<IBreakReminder>;
    conflicts: boolean;
    showReminder: boolean;
    autoDeductBreaks: boolean;
  };
  limitDailyHours: {
    isActive: boolean;
    limit: number;
    conflicts: boolean;
  };
  restrictCheckIn: {
    isActive: boolean;
    graceMinutes: number;
    type: 'grace-minutes' | 'no-grace-minutes' | '';
    conflicts: boolean;
  };
  _isDefault: boolean;
  deactivatedEmployeesNames?: string[];
  deactivatedEmployees?: IUserAccount[];
  overlappingWithAbsence: {
    nonWorkingDays: boolean;
    publicHolidays: boolean;
  };
  overlappingWithShifts: {
    isActive: boolean;
  };
  missingFullEntry: {
    isActive: boolean;
  };
  entryNotCompleted: {
    isActive: boolean;
  };
  overlappingWithTimeOff: {
    isActive: boolean;
    conflicts?: boolean;
  };
}

export interface IBreakReminder {
  triggeredAfter: number;
  suggestedBreak: number;
}

export interface IBreakReminder {
  triggeredAfter: number;
  suggestedBreak: number;
}

export interface IUserAccount {
  _id?: string;
  isActive?: boolean;
  language?: string;
  locale?: string;
  accessToModules?: any[];
  profileKey?: string;
  email?: string;
  ownerId?: string;
  _isDummy?: boolean;
  _onboarding?: string;
  s_orgId?: string;
  _updatedById?: string;
  _createdById?: string;
  s_password?: string;
  s_salt?: string;
  attendancePolicy?: string;
  _createdAt?: Date;
  _updatedAt?: Date;
  s__v?: number;
}
