import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class AttendancePreferencesService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/attendance-preferences-db`;

  constructor(private injector: Injector) {}

  getAttendanceOvertimeEnabledStatus(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get(`${this.URL}/attendance-overtime-status`, httpOptions)
        .toPromise()
        .then((attendanceOvertimeEnableStatus) => {
          resolve(attendanceOvertimeEnableStatus);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'getAttendanceOvertimeEnabledStatus'));
        });
    });
  }

  changeAttendanceOvertimeEnabledStatus(preferenceId: string, newValue: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.URL}/attendance-overtime-status/${preferenceId}`, { overtimeStatus: newValue }, httpOptions)
        .toPromise()
        .then((trackAttendanceEnabledResult) => {
          resolve(trackAttendanceEnabledResult);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'changeAttendanceOvertimeEnabledStatus'));
        });
    });
  }

  getAttendanceSummaryTabStatus(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get(`${this.URL}/attendance-summary-tab-status`, httpOptions)
        .toPromise()
        .then((attendanceSummaryTabStatus) => {
          resolve(attendanceSummaryTabStatus);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'getAttendanceSummaryTabStatus'));
        });
    });
  }

  async getAttendanceSummaryTabAccess(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return this.injector.get(HttpClient).get(`${this.URL}/attendance-summary-tab-access`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'getAttendanceSummaryTabAccess');
    }
  }

  changeAttendanceSummaryTabStatus(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.URL}/attendance-summary-tab-status`, {}, httpOptions)
        .toPromise()
        .then((attendanceSummaryTabStatus) => {
          resolve(attendanceSummaryTabStatus);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'changeAttendanceSummaryTabStatus'));
        });
    });
  }

  getLocationPreferences(): Promise<IGeoLocationPreferences> {
    return new Promise<IGeoLocationPreferences>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get(`${this.URL}/location-preferences`, httpOptions)
        .toPromise()
        .then((geolocationPreferences: IGeoLocationPreferences) => {
          resolve(geolocationPreferences);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'getAttendanceTrackStatus'));
        });
    });
  }

  geocoding(attendanceLocation: IAttendanceLocation): Promise<IAttendanceLocation | void> {
    return new Promise((resolve, reject) => {
      if (check.emptyObject(attendanceLocation)) {
        resolve();
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      let address = '';
      if (check.nonEmptyString(attendanceLocation.address)) {
        address = `${address} ${attendanceLocation.address}`;
      }
      if (check.nonEmptyString(attendanceLocation.postalCode)) {
        address = `${address} ${attendanceLocation.postalCode}`;
      }
      if (check.nonEmptyString(attendanceLocation.city)) {
        address = `${address} ${attendanceLocation.city}`;
      }

      this.injector
        .get(InternationalizationService)
        .getAllTranslation('standard-picklists')
        .then((pageTranslation) => {
          if (check.nonEmptyString(attendanceLocation.country) && check.assigned(pageTranslation.country[attendanceLocation.country])) {
            const translatedCountry = pageTranslation.country[attendanceLocation.country]; // this will ease google API to find a valid locaiton
            address = `${address} ${translatedCountry}`;
          }

          const fullAddress = encodeURI(address);
          return this.injector.get(HttpClient).get(`${this.URL}/geocoding/${fullAddress}`, httpOptions).toPromise();
        })
        .then((geolocation: any) => {
          const result: IAttendanceLocation = {};
          if (geolocation && geolocation.latitude) {
            result._latitude = geolocation.latitude;
          }
          if (geolocation && geolocation.longitude) {
            result._longitude = geolocation.longitude;
          }
          resolve(result);
        })
        .catch((e) => {
          this.injector
            .get(InternationalizationService)
            .getAllTranslation('attendance-geolocation-page')
            .then((translation) => {
              const error = new OrgosError(translation.addressCouldNotBeFoundError, ErrorCodes.CLIENT_ERROR, AttendancePreferencesService.name, 'geocoding');
              error.message = translation.addressCouldNotBeFoundError;
              this.injector.get(ErrorManagerService).handleParsedError(error);
              reject(e);
            });
        });
    });
  }

  createLocation(attendanceLocation: IAttendanceLocation): Promise<void> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .post(`${this.URL}/location`, attendanceLocation, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'createLocation'));
        });
    });
  }

  updateLocation(locationId: string, attendanceLocation: IAttendanceLocation): Promise<void> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.URL}/location/${locationId}`, attendanceLocation, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'updateLocation'));
        });
    });
  }

  deleteLocation(locationId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .delete(`${this.URL}/location/${locationId}`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, AttendancePreferencesService.name, 'deleteLocation'));
        });
    });
  }
}

export interface IAttendanceLocation {
  _id?: string;
  name?: string; // alias for this location
  address?: string; // address name given by the user
  city?: string; // distance in meters to the location that will be considered as valid location
  country?: string;
  postalCode?: string;
  radiusInMeters?: number;
  _latitude?: number;
  _longitude?: number;
  _locationAddress?: string; // address name given by the geolocation service
}

export interface IGeoLocationPreferences {
  validLocations: Array<IAttendanceLocation>;
}
