import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { InternationalizationService } from '../../services/core/internationalization.service';

@Component({
  selector: 'orgos-report-bar',
  templateUrl: 'report-bar.component.html',
  styleUrls: ['report-bar.component.scss']
})
export class ReportBarComponent implements OnInit {
  MONTH_NAMES: Array<string> = [];
  @ViewChild('chartElement', { static: true }) chartElement: ElementRef;

  @Input() template: any;
  @Input() reportDataToDisplay: Array<any>; // Array of object with the fields that will be displayed
  @Input() reportGrouping: any;
  @Input() displayedColumns: Array<string>;
  @Input() translationMatrix: any = {};
  @Input() chartType: string = 'bar'; // Options bar, line
  @Input() summaryTranslation: string;

  pageTranslation: any = {};

  constructor(private injector: Injector) {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('reports-page')
      .then((translation: any) => {
        this.pageTranslation = translation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  ngOnInit(): void {
    this.MONTH_NAMES = moment.months();
    this.drawChart();
    this.sortTable();
  }

  drawChart(): void {
    if (this.chartType !== 'line') {
      this.chartType = 'bar';
    }

    const chartCtx = this.chartElement.nativeElement.getContext('2d');

    const groupConditions = this.template['metadata']['grouping']['groupConditions'];
    const labels = Object.keys(this.reportGrouping).map((iLabel) => {
      if (groupConditions === '-MONTH-') {
        const monthVal = this.MONTH_NAMES[new Date(iLabel).getMonth()];
        const yearVal = new Date(iLabel).getFullYear() % 100;
        if (yearVal < 10) {
          return `${monthVal} 0${yearVal}`;
        }
        return `${monthVal} ${yearVal}`;
      } else if (groupConditions === '-YEAR-') {
        return new Date(iLabel).getFullYear();
      } else {
        return iLabel;
      }
    });
    const datas = [];
    const backGroundColors = [];
    const borderColors = [];
    Object.keys(this.reportGrouping).forEach((iLabel) => {
      datas.push(this.reportGrouping[iLabel]['total']);
      backGroundColors.push('#00b72e');
      borderColors.push('#00b72e');
    });

    let data = {};
    const fromObject = this.template['metadata']['grouping']['fromObject'] === '-PARENT-' ? this.template['metadata']['collection'] : this.template['metadata']['grouping']['fromObject'];
    const inField = this.template['metadata']['grouping']['groupLevel1'];
    let reportLabel = check.assigned(this.translationMatrix[fromObject]) && check.assigned(this.translationMatrix[fromObject][inField]) ? this.translationMatrix[fromObject][inField] : '';
    if (check.assigned(this.template['metadata']['grouping']['groupLevel1Label']) && check.nonEmptyString(this.template['metadata']['grouping']['groupLevel1Label'])) {
      reportLabel = this.pageTranslation[this.template['metadata']['grouping']['groupLevel1Label']] ? this.pageTranslation[this.template['metadata']['grouping']['groupLevel1Label']] : this.template['metadata']['grouping']['groupLevel1Label'];
    }
    if (this.chartType === 'bar') {
      data = {
        labels: labels,
        datasets: [
          {
            label: reportLabel,
            data: datas,
            backgroundColor: backGroundColors,
            borderColor: borderColors,
            borderWidth: 1
          }
        ]
      };
    } else if (this.chartType === 'line') {
      data = {
        labels: labels,
        datasets: [
          {
            label: reportLabel,
            data: datas,
            fill: false,
            borderColor: '#00b72e',
            pointBackgroundColor: '#00b72e',
            pointBorderColor: '#00b72e',
            borderWidth: 2
          }
        ]
      };
    }

    const chart = new Chart(chartCtx, {
      type: this.chartType,
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: this.summaryTranslation ? this.summaryTranslation : ''
              }
            }
          ]
        },
        animation: {
          duration: 500,
          easing: 'linear'
        }
      }
    });
  }

  getTranslation(fieldName: string): string {
    if (check.not.assigned(fieldName) || check.not.string(fieldName) || check.emptyString(fieldName)) {
      return '';
    }

    if (check.contains(fieldName, '.')) {
      const object = fieldName.split('.')[0].trim();
      const field = fieldName.split('.')[1].trim();
      return this.translationMatrix[object] && this.translationMatrix[object][field] ? this.translationMatrix[object][field] : field;
    }

    const masterObject = this.template.metadata.collection;
    return this.translationMatrix[masterObject] && this.translationMatrix[masterObject][fieldName] ? this.translationMatrix[masterObject][fieldName] : fieldName;
  }

  private sortTable(): void {
    this.reportDataToDisplay = _.sortBy(this.reportDataToDisplay, (iElement) => {
      return iElement[this.displayedColumns[0]];
    });
  }
}
