<div class="kenjo-font-size-16px kenjo-text-transform-uppercase">
  {{ menuTranslation.title }}
</div>

<div class="kenjo-pt-15px">
  <a class="sbm-link" [class.kenjo-font-color-primary-5993e3]="selectedOption === 1" [class.kenjo-font-color-light-text-757575]="selectedOption !== 1" routerLink="/cloud/settings/billing">{{ menuTranslation.upgradeLabel }}</a>
</div>
<div class="kenjo-pt-15px">
  <a class="sbm-link" [class.kenjo-font-color-primary-5993e3]="selectedOption === 2" [class.kenjo-font-color-light-text-757575]="selectedOption !== 2" routerLink="/cloud/settings/billing/info">{{ menuTranslation.yourSubscriptionLabel }}</a>
</div>
<div class="kenjo-pt-15px">
  <a class="sbm-link" [class.kenjo-font-color-primary-5993e3]="selectedOption === 3" [class.kenjo-font-color-light-text-757575]="selectedOption !== 3" routerLink="/cloud/settings/billing/invoices">{{ menuTranslation.invoicesLabel }}</a>
</div>

<div class="kenjo-font-size-16px kenjo-text-transform-uppercase kenjo-mt-40px">
  {{ menuTranslation.usageTitle }}
</div>
<div class="kenjo-pt-15px">
  <a class="sbm-link" [class.kenjo-font-color-primary-5993e3]="selectedOption === 3" [class.kenjo-font-color-light-text-757575]="selectedOption !== 4" routerLink="/cloud/settings/billing/platform-usage" (click)="sendPUEvent()">{{ menuTranslation.platformUsage }}</a>
</div>