import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { InternationalizationService } from '../core/internationalization.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class DocumentExportService {
  private DOCUMENT_EXPORT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/document-export`;

  constructor(private injector: Injector) {}

  getDocument(templateId: string, fileContent: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_EXPORT_URL}/export`, ErrorCodes.UNAUTHORIZED, DocumentExportService.name, 'getDocument');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
        responseType: 'arraybuffer' as 'arraybuffer'
      };

      const documentConfiguration = {
        templateId: templateId,
        fileContent: fileContent
      };

      this.injector
        .get(HttpClient)
        .post(`${this.DOCUMENT_EXPORT_URL}/export`, documentConfiguration, httpOptions)
        .toPromise()
        .then((documentToExport) => {
          resolve(documentToExport);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, DocumentExportService.name, 'getDocument'));
        });
    });
  }
}
