import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';
import * as moment from 'moment';

import { InputAbstractComponent } from '../../components/input-abstract/input-abstract.component';
import { ISelectOption } from '../../core/select-option';

@Component({
  selector: 'orgos-input-select-time',
  templateUrl: 'input-select-time.component.html',
  styleUrls: ['input-select-time.component.scss']
})
export class InputSelectTimeComponent extends InputAbstractComponent implements OnInit {
  private wasInitialized: boolean = false;
  private MAX_MINUTES = 1440;

  private _originalOptions: Array<ISelectOption> = [];

  private _options: Array<ISelectOption> = [];
  @Input()
  set options(options: Array<ISelectOption>) {
    if (check.not.assigned(options)) {
      this._originalOptions = [];
      return;
    }

    this._originalOptions = options;
    this.applySort();
  }
  get options(): Array<ISelectOption> {
    return this._options;
  }

  private _fromTime: number = 0;
  @Input()
  set fromTime(fromTime: number) {
    this._fromTime = fromTime;
    if (this.wasInitialized) {
      this.generateOptions();
    }
  }

  get fromTime(): number {
    return this._fromTime;
  }

  private _toTime: number = this.MAX_MINUTES;
  @Input()
  set toTime(toTime: number) {
    this._toTime = toTime;
  }

  get toTime(): number {
    return this._toTime;
  }

  private _showDifference: boolean = false;
  @Input()
  set showDifference(showDifference: boolean) {
    this._showDifference = showDifference;
  }

  get showDifference(): boolean {
    return this._showDifference;
  }

  private _excludeFromAsOption: boolean = false;
  @Input()
  set excludeFromAsOption(excludeFromAsOption: boolean) {
    this._excludeFromAsOption = excludeFromAsOption;
  }

  get excludeFromAsOption(): boolean {
    return this._excludeFromAsOption;
  }

  @Input() hourLabel: string = '';

  @Input() hoursLabel: string = '';

  @Input() defaultHour: string;

  constructor(cdr: ChangeDetectorRef, injector: Injector) {
    super(cdr, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this._options.length === 0) {
      this.generateOptions();
    }

    this.wasInitialized = true;
  }

  setValue(newValue: any): void {
    super.setValue(newValue);
  }

  getValueName(): string {
    const value = super.getValue();

    if (check.not.assigned(value)) {
      return '';
    }

    const option = this.options.find((optionItem: ISelectOption) => {
      return optionItem.value === value;
    });

    if (check.not.assigned(option)) {
      return '';
    }

    return option.name;
  }

  private applySort(): void {
    this._options = this._originalOptions.slice();
  }

  private generateOptions() {
    this._options = [];
    let initValue = this._fromTime;
    if (this._excludeFromAsOption) {
      initValue += 60;
    }

    for (let i = initValue; i <= this._toTime; i = i + 60) {
      const timeString = moment.utc().startOf('day').add(i, 'minutes').format('LT');

      if (check.assigned(this.defaultHour) && timeString === this.defaultHour) {
        this.setValue(i);
      }

      this._options.push({ name: timeString, value: i });
    }
  }

  getDifference(value: number): string {
    const hours = (value - this._fromTime) / 60;
    return ` (${hours} ${hours !== 1 ? this.hoursLabel : this.hourLabel})`;
  }
}
