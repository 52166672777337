import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as check from 'check-types';
import * as _ from 'lodash';

import { PrivateOrganizationService } from '../../../private/services/private-organization.service';
import { InputAbstractComponent } from '../../components/input-abstract/input-abstract.component';
import { SearchComponent, SearchFunction } from '../../components/search/search.component';
import { GenericCacheModel } from '../../core/generic-cache-model';
import { GenericSimpleModel } from '../../core/generic-simple-model';
import { InternationalizationService } from '../../services/core/internationalization.service';
import { SettingsBarService } from '../../services/settings/settings-bar.service';
import { StandardServicesRegistry } from '../../services/standard-services.registry';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';

@Component({
  selector: 'orgos-settings-welcome-wizard',
  templateUrl: 'settings-welcome-wizard.page.html',
  styleUrls: ['settings-welcome-wizard.page.scss']
})
export class SettingsWelcomeWizardPage implements OnInit {
  pageTranslation: any = {};
  settingsBarTranslation: any = {};

  availableLanguages: Array<any> = [];

  searchResults: Array<any> = [];

  allUserPersonal: Array<GenericSimpleModel> = [];

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry, private privateOrganizationService: PrivateOrganizationService) {}

  ngOnInit(): void {
    const globalBarServiceClass = this.standardServicesRegistry.getService('GlobalBar');
    this.injector.get(globalBarServiceClass).setProgressBar(true);

    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');

    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('settings-top-bar')
      .then((pageTranslation: any) => {
        this.settingsBarTranslation = pageTranslation;
        return this.injector.get(SettingsBarService).getOptions(this.settingsBarTranslation);
      })
      .then((options) => {
        const optionIndex = _.findIndex(options, ['name', this.settingsBarTranslation.welcomeWizardTab]);

        this.injector.get(globalBarServiceClass).setSecondaryMenuOptions(options);
        this.injector.get(globalBarServiceClass).setSelectedSecondaryMenuOption(optionIndex);
      })
      .catch(() => {
        // An error is already shown
        this.injector.get(globalBarServiceClass).setSecondaryMenuOptions([]);
      });

    this.fetchData();

    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('settings-welcome-wizard-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;

        this.injector.get(globalBarServiceClass).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector.get(PrivateAmplitudeService).logEvent('view settings page', { category: 'Navigation', type: 'welcome wizard' });
  }

  private fetchData(): void {
    this.injector
      .get(InternationalizationService)
      .getTranslation('standard-picklists', 'language')
      .then((languages: any) => {
        this.availableLanguages = Object.keys(languages).map((iLanguageKey) => {
          const language = {
            languageKey: iLanguageKey,
            languageLabel: languages[iLanguageKey]
          };

          return language;
        });

        const organizationCalls = this.availableLanguages.map((iLanguage: any) => {
          const getInvitationEmail = this.privateOrganizationService.getInvitationEmail(iLanguage.languageKey);
          const getAboutOrganization = this.privateOrganizationService.getAboutOrganization(iLanguage.languageKey);
          return Promise.all([getInvitationEmail, getAboutOrganization]);
        });

        return Promise.all(organizationCalls);
      })
      .then((results: Array<any>) => {
        results.forEach((iResult, index) => {
          this.availableLanguages[index].invitationEmailModel = new GenericCacheModel(this.injector, iResult[0], PrivateOrganizationService, '');
          this.availableLanguages[index].aboutOrganizationModel = new GenericCacheModel(this.injector, iResult[1], PrivateOrganizationService, '');
        });
      })
      .catch(() => {
        // An error is already shown

        this.availableLanguages = [];
      });

    const userPersonalServiceClass = this.standardServicesRegistry.getService('UserPersonal');
    this.injector
      .get(userPersonalServiceClass)
      .getAllUserPersonal(true)
      .then((allUserPersonal: Array<any>) => {
        this.allUserPersonal = allUserPersonal.map((userPersonal) => {
          return new GenericSimpleModel(this.injector, userPersonal, userPersonalServiceClass, userPersonal.ownerId);
        });

        const globalBarServiceClass = this.standardServicesRegistry.getService('GlobalBar');
        this.injector.get(globalBarServiceClass).setProgressBar(false);
      })
      .catch(() => {
        // An error is already shown
        this.allUserPersonal = [];

        const globalBarServiceClass = this.standardServicesRegistry.getService('GlobalBar');
        this.injector.get(globalBarServiceClass).setProgressBar(false);
      });
  }

  updateInvitationEmail(invitationEmail: GenericCacheModel, input: InputAbstractComponent): void {
    this.privateOrganizationService
      .updateInvitationEmail(invitationEmail.data)
      .then(() => {
        input.forceActivateSavedHint();
        this.injector.get(PrivateChurnzeroService).logSimpleEvent('EMAIL_CONTENT_UPDATED');
      })
      .catch(() => {
        // Do nothing, an error is already shown
      });
  }

  updateAboutOrganization(aboutOrganization: GenericCacheModel, input: InputAbstractComponent): void {
    this.privateOrganizationService
      .updateAboutOrganization(aboutOrganization.data)
      .then(() => {
        input.forceActivateSavedHint();
      })
      .catch(() => {
        // Do nothing, an error is already shown
      });
  }

  peopleToKnow(): Array<any> {
    const peopleToKnow = this.allUserPersonal.filter((userPersonal: GenericSimpleModel) => {
      return userPersonal.data.peopleToKnow === true;
    });

    return peopleToKnow;
  }

  noPeopleToKnow(): Array<any> {
    const noPeopleToKnow = this.allUserPersonal.filter((userPersonal: GenericSimpleModel) => {
      return check.not.assigned(userPersonal.data.peopleToKnow) || userPersonal.data.peopleToKnow === false;
    });

    return noPeopleToKnow;
  }

  searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    let peopleToKnow;
    if (check.not.assigned(value) || check.emptyString(value)) {
      peopleToKnow = this.peopleToKnow();
      return Promise.resolve(peopleToKnow);
    }

    peopleToKnow = this.peopleToKnow();
    if (peopleToKnow.length >= 6) {
      return Promise.resolve([]);
    }

    const results = this.noPeopleToKnow().filter((userPersonal: GenericSimpleModel) => {
      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return regExp.test(userPersonal.data.displayName);
    });

    return Promise.resolve(results);
  };

  addUserToPeopleToKnow(userId: string): void {
    const foundUserAccount = this.allUserPersonal.find((userPersonal: GenericSimpleModel) => {
      return userId === userPersonal.data._id;
    });

    if (check.assigned(foundUserAccount)) {
      foundUserAccount.data.peopleToKnow = true;
      this.searchComponent.clearSearch();
      foundUserAccount
        .update()
        .then(() => {
          // Do nothing
        })
        .catch(() => {
          // An error is already shown
          foundUserAccount.data.peopleToKnow = false;
          this.searchComponent.refreshSearchResults();
        });
    }
  }

  getFunctionDeleteUserFromPeopleToKnow(userId: string): Function {
    return () => {
      const foundUserAccount = this.allUserPersonal.find((userPersonal: GenericSimpleModel) => {
        return userId === userPersonal.data._id;
      });

      if (check.assigned(foundUserAccount)) {
        foundUserAccount.data.peopleToKnow = false;
        this.searchComponent.refreshSearchResults();
        foundUserAccount
          .update()
          .then(() => {
            // Do nothing
          })
          .catch(() => {
            // An error is already shown
            foundUserAccount.data.peopleToKnow = true;
            this.searchComponent.refreshSearchResults();
          });
      }
    };
  }
}
