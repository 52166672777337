import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputValidationFunction } from '@app/standard/core/validation/input-validation-function';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CustomFieldService } from '@app/standard/services/custom-field/custom-field.service';
import * as check from 'check-types';

import { GenericCacheModel } from '../../../../core/generic-cache-model';
import { InputValidation } from '../../../../core/validation/input-validation';

@Component({
  selector: 'orgos-dialog-add-custom-field',
  templateUrl: 'add-custom-field.dialog.html',
  styleUrls: ['add-custom-field.dialog.scss'],
})
export class AddCustomFieldDialog implements OnInit {
  pageTranslation: any = {};
  customFieldDocument: GenericCacheModel;
  chosenListValues: Array<string> = [];
  emptyListItems: boolean = false;

  fieldLabelValidation: InputValidation;
  fieldTypeValidation: InputValidation;
  autoNumberMaskValidation: InputValidation;
  autoNumberNextValueValidation: InputValidation;

  generateAutoNumberForExistingRecords: boolean = false;

  creatingCustomField: boolean = false;
  candidateCustomField: boolean;
  candidateCollections: Array<string> = ['candidate', 'position-candidate'];

  autoNumberMaskValidationFunction: InputValidationFunction = (value: any): InputValidation => {
    const inputValidation = new InputValidation();

    if (check.not.match(value, /.*({\d+}).*/gi)) {
      inputValidation.setError('notValid');
    }

    return inputValidation;
  };

  constructor(
    public dialogRef: MatLegacyDialogRef<AddCustomFieldDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('add-custom-field-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.customFieldDocument = new GenericCacheModel(this.injector, this.data.customField, CustomFieldService, '');
    this.chosenListValues = this.data.customField.listValues;
    if (this.candidateCollections.includes(this.data.customField.collectionName)) {
      this.candidateCustomField = true;
    }
  }

  addNewListItem(): void {
    if (check.not.assigned(this.chosenListValues)) {
      this.chosenListValues = [];
    }
    this.chosenListValues.push('');
    this.evaluateListItems();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addField(): void {
    if (
      this.creatingCustomField === true ||
      check.not.assigned(this.fieldLabelValidation) ||
      this.fieldLabelValidation.hasErrors() ||
      check.not.assigned(this.fieldTypeValidation) ||
      this.fieldTypeValidation.hasErrors() ||
      this.emptyListItems ||
      (this.customFieldDocument.data.fieldType === 'AutoNumber' &&
        (check.not.assigned(this.autoNumberMaskValidation) || this.autoNumberMaskValidation.hasErrors())) ||
      (this.customFieldDocument.data.fieldType === 'AutoNumber' &&
        (check.not.assigned(this.autoNumberNextValueValidation) || this.autoNumberNextValueValidation.hasErrors()))
    ) {
      return;
    }

    this.creatingCustomField = true;

    this.injector.get(GlobalBarService).setProgressBar(true);

    if (check.assigned(this.chosenListValues) && check.nonEmptyArray(this.chosenListValues)) {
      this.customFieldDocument.data.listValues = this.chosenListValues;
    }

    const generateForExistingRecords =
      this.customFieldDocument.data.fieldType === 'AutoNumber' && this.generateAutoNumberForExistingRecords;

    this.injector
      .get(CustomFieldService)
      .create(this.customFieldDocument.data, generateForExistingRecords)
      .then(() => {
        const message = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.createdSnackbarMessage, { fieldName: this.customFieldDocument.data.fieldLabel });
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });

        this.dialogRef.close(this.customFieldDocument.data);
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch(() => {
        // An error is already shown

        this.injector.get(GlobalBarService).setProgressBar(false);
        this.creatingCustomField = false;
      });
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  removeEntry(entryName: string): void {
    this.chosenListValues = this.chosenListValues.filter((iValue) => {
      return iValue !== entryName;
    });
    this.evaluateListItems();
  }

  evaluateListItems(): void {
    if (
      check.not.assigned(this.customFieldDocument) ||
      check.not.assigned(this.customFieldDocument.data) ||
      check.not.assigned(this.customFieldDocument.data.fieldType) ||
      check.not.equal(this.customFieldDocument.data.fieldType, 'List')
    ) {
      this.emptyListItems = false;
      return;
    } else if (
      check.not.assigned(this.chosenListValues) ||
      check.not.array(this.chosenListValues) ||
      check.emptyArray(this.chosenListValues)
    ) {
      this.emptyListItems = true;
      return;
    }

    const emptyArrayItems = this.chosenListValues.filter((iItem: string) => {
      return check.emptyString(iItem);
    });
    if (check.emptyArray(emptyArrayItems)) {
      // If there is not even one element which is empty
      this.emptyListItems = false;
      return;
    }
    this.emptyListItems = true;
  }
}
