import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';

import { ListItemDirective } from './list-item.directive';

@Component({
  selector: 'orgos-list-sortable',
  templateUrl: 'list-sortable.component.html',
  styleUrls: ['list-sortable.component.scss'],
})
export class ListSortableComponent {
  @Input() items: Array<any> = [];
  @Input() greySkin: boolean = false;
  @Input() whiteStyle: boolean = false;
  @Input() isExtension: boolean = false;
  @Input() isExtended: boolean = false;
  @Input() drag: boolean = true;
  @Input() manageColumns: boolean = false;

  @Output() changedOrder: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild(ListItemDirective) listItem: ListItemDirective;

  drop(event: CdkDragDrop<Array<any>>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.changedOrder.emit();
  }
}
