import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import {
  TIME_OFF_TYPE_ACTIVITY_TYPE_PAID,
  TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID,
  TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID_NO_REDUCES_EXPECTED,
  TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER,
  TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_WORKING_PAID,
  TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_NOT_WORKING_PAID
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TimeOffTypeService implements IGenericService {
  private TIME_OFF_TYPE_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/time-off-type-db`;
  private TIME_OFF_TYPE_POLICY_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/time-off-type-policy`;
  private TIME_OFF_PERMISSIONS_KEY: string = 'time-off-type';
  private TIME_OFF_INTERNATIONALIZATION: string = 'time-off-type-collection';

  constructor(private injector: Injector, private http: HttpClient, private authenticationService: AuthenticationService, private genericService: GenericService, private errorManager: ErrorManagerService) {}

  async create(data: object): Promise<ITimeOffTypeModel> {
    try {
      return await this.genericService.create(this.TIME_OFF_TYPE_MICROSERVICE_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'create');
    }
  }

  async getById(id: string): Promise<ITimeOffTypeModel> {
    try {
      return await this.genericService.getById(this.TIME_OFF_TYPE_MICROSERVICE_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'getById');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.TIME_OFF_TYPE_MICROSERVICE_URL, id, data);
      return;
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.TIME_OFF_TYPE_MICROSERVICE_URL, id);
      return;
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'deleteById');
    }
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.TIME_OFF_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.TIME_OFF_INTERNATIONALIZATION);
  }

  async getTimeOffTypes(): Promise<Array<ITimeOffTypeModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    try {
      return await this.genericService.find(this.TIME_OFF_TYPE_MICROSERVICE_URL, findBody);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'getTimeOffTypes');
    }
  }

  async find(findBody): Promise<Array<ITimeOffTypeModel>> {
    try {
      return await this.genericService.find(this.TIME_OFF_TYPE_MICROSERVICE_URL, findBody);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'find');
    }
  }

  async findTimeOffTypePolicies(query): Promise<Array<ITimeOffTypePolicyModel>> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.TIME_OFF_TYPE_POLICY_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, TimeOffTypeService.name, 'findTimeOffTypePolicies');
        throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'findTimeOffTypePolicies');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.post<Array<ITimeOffTypePolicyModel>>(`${this.TIME_OFF_TYPE_POLICY_CONTROLLER_URL}/find`, query, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'findTimeOffTypePolicies');
    }
  }

  async getTypePolicies(timeOffTypeId: string): Promise<Array<ITypePolicyModel>> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.TIME_OFF_TYPE_POLICY_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, TimeOffTypeService.name, 'getTypePolicies');
        throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'getTypePolicies');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get<Array<ITypePolicyModel>>(`${this.TIME_OFF_TYPE_POLICY_CONTROLLER_URL}/type-policies/${timeOffTypeId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffTypeService.name, 'getTypePolicies');
    }
  }
}

export interface ITimeOffTypeModel {
  _id: string;
  name: string;
  description?: string;
  workTime?: WorkTimeType;
  color?: string;
  public?: boolean;
  attachment?: boolean;
  tags?: Array<string>;
  excusedAbsenceType?: string;
  dummyDate?: Date;
  _createdAt: string;
}

export interface ITimeOffTypePolicyModel {
  _id: string;
  name: string;
  description?: string;
  workTime?: WorkTimeType;
  color?: string;
  public?: boolean;
  attachment?: boolean;
  tags?: Array<string>;
  excusedAbsenceType?: string;
  dummyDate?: Date;
  _policies?: Array<ITimeOffPolicyModel>;
}

export interface ITypePolicyModel extends ITimeOffPolicyModel {
    employees: number
}

export type WorkTimeType =
  | TIME_OFF_TYPE_ACTIVITY_TYPE_PAID
  | TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER
  | TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID_NO_REDUCES_EXPECTED
  | TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID
  | TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_WORKING_PAID
  | TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_NOT_WORKING_PAID;

export interface IEmptyTimeOffTypeModel {
  _id: string;
  name: string;
}
