import { Component } from '@angular/core';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateChargebeeService } from '@app/private/services/private-chargebee.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { SettingsBarService } from '@app/standard/services/settings/settings-bar.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-settings-billing-upgrade-page',
  templateUrl: 'settings-billing-upgrade.page.html',
  styleUrls: ['settings-billing-upgrade.page.scss'],
})
export class SettingsBillingUpgradePage extends GenericPage {
  plansInfo: { [key: string]: { key: string; features: Array<string> } };
  featuresInfo: {
    [key: string]: {
      key: string;
      features: Array<{ key: string; starter: boolean; growth: boolean; connect: boolean }>;
    };
  };
  planPrices: { [key: string]: { yearly: object; monthly: object } };
  recruitmentAddonPrices: { [key: string]: { yearly: object; monthly: object } };
  recruitmentPlusAddonPrices: { [key: string]: { yearly: object; monthly: object } };
  datevImplementationFeePrices: { [key: string]: { id: string; steps: object } };
  onboardingAddonPrices: { id: string; priceByPlan: { connect: number; growth: number } };
  currentPlan: { isStarter: boolean; isGrowth: boolean; isConnect: boolean };
  showFeatures: boolean = false;
  showYearly: boolean = true;
  isCountrySetInBilling: boolean = true;
  isInTrial: boolean = false;
  isMultiBilling: boolean;
  contactLink: string;
  demoLink: string = 'https://demodesk.com/select/kenjo-gmbh/yqadiqwf';
  customerId: string;
  isLegacy: boolean;
  defaultSubscriptionId: string;
  hasPaymentSources: boolean;
  recruitingAddon: string = 'recruiting';
  datevImplementation: boolean = false;
  onboardingAddon: boolean = false;
  errorRetrievingInfo: boolean = true;
  oldCurrency: string;
  cbInstance: any;
  isCancelled: boolean;
  isCurrentBillingYearly: boolean = false;
  selectedEmployees = 150;
  minEmployees = 1;

  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'misc' },
    { name: 'page', translationKey: 'settings-billing-upgrade-page' },
    { name: 'billingPage', translationKey: 'settings-billing-page' },
    { name: 'settingsTopBar', translationKey: 'settings-top-bar' },
  ];

  protected async beforeInit(): Promise<void> {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('visited inapp pricing', { category: 'Billing' });
    this.cbInstance = await this.injector.get(PrivateChargebeeService).getChargebeeInstance();
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    const NUMBER_OF_DATA_TO_FETCH = 1;
    let dataFetched = 0;

    this.injector
      .get(PrivateChargebeeService)
      .getPlansAndFeatures()
      .then((plansAndFeatures) => {
        this.featuresInfo = plansAndFeatures.features;
        this.planPrices = plansAndFeatures.prices.planPrices;
        this.recruitmentAddonPrices = plansAndFeatures.prices.recruitmentAddonPrices;
        this.recruitmentPlusAddonPrices = plansAndFeatures.prices.recruitmentPlusAddonPrices;
        this.datevImplementationFeePrices = plansAndFeatures.prices.datevImplementationFeePrices;
        this.onboardingAddonPrices = plansAndFeatures.prices.onboardingAddonPrices;
        this.plansInfo = plansAndFeatures.plans;
        this.currentPlan = plansAndFeatures.currentPlan;
        this.isCountrySetInBilling = plansAndFeatures.isCountrySetInBilling;
        this.isInTrial = plansAndFeatures.isInTrial;
        this.isMultiBilling = plansAndFeatures.isMultiBilling;
        this.hasPaymentSources = plansAndFeatures.hasPaymentSources;
        this.customerId = plansAndFeatures.customerId;
        this.errorRetrievingInfo = plansAndFeatures.errorRetrievingInfo;
        this.minEmployees = plansAndFeatures.minEmployees;

        this.isCancelled = plansAndFeatures.isCancelled ?? false;
        this.isCurrentBillingYearly = plansAndFeatures.isBillingYearly ?? false;
        this.contactLink =
          this.isInTrial || this.isCancelled ? plansAndFeatures.contactLink : this.demoLink;

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }

        this.cdr.detectChanges();
      })
      .catch(() => {
        // An error is already shown
        rejectFetchData();
        this.errorRetrievingInfo = true;
      });
  }

  openCheckout(plan: string, origin?: string) {
    const billingFrequency = this.showYearly ? 'yearly' : 'monthly';

    const event =
      origin !== 'calculator' ? 'clicked upgrade now in plan' : 'clicked upgrade now in summary ';
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent(event, { category: 'Billing', subcategory1: plan, subcategory2: billingFrequency });

    this.cbInstance.openCheckout({
      hostedPage: async () => {
        const { hostedPage, isLegacy, oldCurrency, defaultSubscriptionId } = await this.injector
          .get(PrivateChargebeeService)
          .getCheckoutPage(
            plan,
            billingFrequency,
            this.recruitingAddon,
            this.datevImplementation,
            this.onboardingAddon,
            this.selectedEmployees
          );
        this.isLegacy = isLegacy;
        this.defaultSubscriptionId = defaultSubscriptionId;
        this.oldCurrency = oldCurrency;
        return hostedPage;
      },
      close: () => this.refreshData(),
      success: async () => {
        this.injector.get(PrivateAmplitudeService).logEvent('checkout success', {
          category: 'Billing',
          subcategory1: plan,
          subcategory2: billingFrequency,
        });

        if (this.isLegacy) {
          await this.injector.get(PrivateChargebeeService).cancelNonDefaultSubscriptions();
        }

        if (check.assigned(this.oldCurrency)) {
          await this.injector
            .get(PrivateChargebeeService)
            .cancelDefaultSubscriptionForCurrency(this.oldCurrency);
          this.oldCurrency = null;
        }

        if (check.assigned(this.defaultSubscriptionId)) {
          await this.injector
            .get(PrivateChargebeeService)
            .activateSubscriptions(this.defaultSubscriptionId);
          await this.injector.get(PrivateChargebeeService).updateFloor(this.defaultSubscriptionId);
        }

        if (this.injector.get(CloudRoutingService).isFreeTrialExpired) {
          window.location.reload();
        }

        this.reloadData();
      },
      error: (cbError: any) => {
        // This error is already handled by the CB modal
        if (cbError === 'Already another checkout is in progress') {
          return;
        }

        this.injector.get(PrivateAmplitudeService).logEvent('checkout error', {
          category: 'Billing',
          subcategory1: plan,
          subcategory2: billingFrequency,
        });

        try {
          const error = new OrgosError(
            cbError,
            ErrorCodes.THIRD_PARTY_SERVICE_ERROR,
            SettingsBillingUpgradePage.name,
            'openCheckout'
          );
          error.message = 'Error during Chargebee checkout';
          this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);
        } catch {
          // do nothing
        }
      },
    });
  }

  private async reloadData(): Promise<void> {
    this.refreshData();
    const status = await this.injector.get(PrivateChargebeeService).getChargebeeStatus();
    this.injector.get(GlobalBarService).setChargebeeStatus(status);
  }

  calculatePrice(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth' });
  }

  openPortal() {
    this.cbInstance.createChargebeePortal().open({});
  }

  protected async configureGlobalBar(): Promise<void> {
    try {
      this.globalBarConfig.pageName = this.i18n.billingPage.pageName;

      const options = await this.injector
        .get(SettingsBarService)
        .getOptions(this.i18n.settingsTopBar);
      const optionIndex = _.findIndex(options, ['name', this.i18n.settingsTopBar.billingTab]);

      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
    } catch {
      // An error is already shown
    }
  }

  onShowFeatures() {
    this.showFeatures = !this.showFeatures;
    if (this.showFeatures) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('expand feature list', { category: 'Billing' });
    }
  }
}
