import { IShiftPlanDate } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { ISelectOption } from '@app/standard/core/select-option';
import { SHIFT_PLAN_WEEKLY_VIEW } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

export const mapValuesFromRawCollection = (collection) => {
  return collection.map((eachCollection) => {
    return {
      ...eachCollection,
      value: eachCollection._id,
    };
  });
};

export const filterLocationsPure = (responseLocations): Array<ISelectOption> => {
  const activeLocations = responseLocations.filter((iLocation) => iLocation?.activeForShiftplan === true);
  return mapValuesFromRawCollection(activeLocations);
};

export const mapById = (collection) => {
  return collection.reduce((acc, curr) => {
    return { ...acc, [curr._id]: curr };
  }, {});
};

export const processShiftplanPermissions = (
  entries: Array<any>,
  collectionPermissions: any,
  profileId: string
): { [id: string]: boolean } => {
  return entries.reduce((acc, currDoc) => {
    if (collectionPermissions?.edit_all === true) {
      return { ...acc, [currDoc._id]: false };
    }

    if (collectionPermissions?.edit_own === true) {
      return { ...acc, [currDoc._id]: currDoc.ownerId !== profileId };
    }

    return { ...acc, [currDoc._id]: true };
  }, {});
};

export const isRangeInvalid = (range: IShiftPlanDate): boolean => {
  return check.not.assigned(range) || check.not.assigned(range.from) || check.not.assigned(range.to);
};

export const parseRange = (startDate: moment.Moment, range: 'week' | 'month', formerRange: IShiftPlanDate): IShiftPlanDate => {
  if (range === SHIFT_PLAN_WEEKLY_VIEW && startDate.isSame(formerRange.from, 'month')) {
    return { ...formerRange };
  }

  const from = moment.utc(startDate).startOf(range);
  const to = moment.utc(startDate).endOf(range).endOf('day');
  return { from, to };
};

export const checkEmptySchedule = (shiftCards) => {
  return (
    shiftCards?.openDraftCount === 0 &&
    shiftCards?.openPublishedCount === 0 &&
    shiftCards?.scheduledDraftCount === 0 &&
    shiftCards?.scheduledPublishedCount === 0
  );
};
