<ng-container *ngIf="!loadingPage">
  <orgos-column-container *ngIf="!enabledEditMode" class="kenjo-p-20px" centerColumns="true">
    <orgos-column size="0">
      <orgos-action (click)="changeView()">{{viewsOpened ? i18n.page.hideViews : i18n.page.showViews}}</orgos-action>
    </orgos-column>
    <orgos-column> </orgos-column>
    <orgos-column *ngIf="profilePermissions['org-chart-feature'].c_createOrgChart" size="0">
      <orgos-action icon="add_circle" iconSize="large" (click)="addOrgChartView()">{{i18n.page.addOrgChartView}}</orgos-action>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-40px">
      <orgos-action [matMenuTriggerFor]="exportMenu">{{i18n.page.exportChart}}</orgos-action>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exportOrgChartToPDF()">{{ i18n.page.exportMenuOptions.exportToPdf }}</button>
        <button mat-menu-item (click)="exportOrgChartToSVG()">{{ i18n.page.exportMenuOptions.exportToSvg }}</button>
      </mat-menu>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container *ngIf="enabledEditMode" class="kenjo-p-20px" centerColumns="true">
    <orgos-column size="0" class="kenjo-mr-10px">
      <kenjo-icon class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
    </orgos-column>
    <orgos-column size="0"> {{i18n.page.editModeInfoMessage}} </orgos-column>
    <orgos-column> </orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="exitEditMode()">{{i18n.page.exitEditMode}}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
  <!-- HERE -->
  <orgos-column-container class="poc-main-content">
    <orgos-column *ngIf="viewsOpened" size="0" class="poc-views-column kenjo-ml-20px">
      <orgos-container aspect="card" class="kenjo-pt-20px kenjo-ph-20px kenjo-mb-20px">
        <div class="kenjo-font-weight-bold">{{i18n.page.views}}</div>
        <orgos-column-container>
          <!-- Custom made orgchart views -->
          <orgos-column>
            <orgos-input-simple-radio width="170" [options]="availableOrgChartList" [value]="selectedView" (valueChange)="renderOrgChartView($event)"></orgos-input-simple-radio>
          </orgos-column>
          <orgos-column size="0" *ngIf="profilePermissions['org-chart-feature'].c_createOrgChart">
            <div *ngFor="let iOrgChartView of availableOrgChartList; let index = index" class="kenjo-mt-20px poc-views-column-menu-button">
              <kenjo-icon *ngIf="index >= availableOrgChartList.length - orgChartCustomViews.length" [size]="20" class="kenjo-cursor-pointer" [matMenuTriggerFor]="actionsMenu">more_vert</kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="renameOrgChartView(iOrgChartView.value, iOrgChartView.name)">{{ i18n.page.viewsMenuOptions.rename }}</button>
                <button mat-menu-item (click)="editOrgChartView(iOrgChartView.value)">{{ i18n.page.viewsMenuOptions.edit }}</button>
                <button mat-menu-item (click)="deleteOrgChartView(iOrgChartView.value)">{{ i18n.page.viewsMenuOptions.delete }}</button>
              </mat-menu>
            </div>
          </orgos-column>
        </orgos-column-container>
      </orgos-container>
      <orgos-container aspect="card" class="kenjo-ph-20px kenjo-mt-20px">
        <orgos-column-container class="kenjo-pb-20px">
          <orgos-column>
            <orgos-input-simple-text class="kenjo-block" [label]="i18n.page.search" [debounceNewValues]="true" [debounceTimer]="500" [(value)]="orgChartSearchTerms" suffixIcon="search" [showClearButton]="true"></orgos-input-simple-text>
            <orgos-input-error *ngIf="searchFailed">{{ i18n.page.searchFailed }}</orgos-input-error>
            <orgos-text *ngIf="showSearchResultsMessage" type="SecondaryText" color="LightText">{{ i18n.page.searchResults | i18nData: {number: searchCount} }}</orgos-text>
          </orgos-column>
        </orgos-column-container>
      </orgos-container>
    </orgos-column>
    <orgos-column size="1">
      <orgos-container aspect="card" class="kenjo-mh-20px kenjo-mb-20px ocp-chart-container">
        <div *ngIf="loadingOrgChartView === true" class="ocp-loading-container">
          <orgos-loading-spinner></orgos-loading-spinner>
          <div class="kenjo-mt-20px">{{ i18n.page.loadingOrgChart }}</div>
        </div>
        <kenjo-org-chart
          *ngIf="loadingOrgChartView === false && orgChartTree && orgChartTree.children && orgChartTree.children.length > 0"
          [tree]="orgChartTree"
          [svgPosition]="svgPosition"
          [enabledEditMode]="enabledEditMode"
          [isStandardOrgChart]="selectedView === 'masterOrgChart'"
          [searchTerms]="orgChartSearchTerms"
          (addNode)="addNode($event)"
          (editNode)="editNode($event)"
          (deleteNode)="deleteNode($event)"
          (failedSearch)="showErrorMessage($event)"
          (searchResults)="showSearchResults($event)"
          #orgChart
        ></kenjo-org-chart>
        <div *ngIf="loadingOrgChartView === false && (!orgChartTree || !orgChartTree.children || orgChartTree.children.length === 0)" class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
          <img src="/assets/images/emptyState.svg" />
          <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noElementsLine1}}</div>
          <div *ngIf="profilePermissions['org-chart-feature'].c_createOrgChart" class="kenjo-mt-5px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.noElementsLine2}}</div>
          <orgos-button-raised *ngIf="profilePermissions['org-chart-feature'].c_createOrgChart" (click)="addFirstNode()" class="kenjo-mt-30px">{{i18n.page.createFirstElement}}</orgos-button-raised>
        </div>
      </orgos-container>
    </orgos-column>
  </orgos-column-container>
</ng-container>
