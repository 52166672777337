<!-- Open shifts row -->
<orgos-column-container *ngIf="openShifts" class="kenjo-border-right kenjo-border-bottom kenjo-border-left">
  <orgos-column class="kenjo-p-5px spr-subtitle spr-flex-center spr-column">{{ componentTranslations.openShifts }}</orgos-column>
  <orgos-column
    *ngFor="let day of currentDays; let i = index"
    class="kenjo-border-left"
    [ngClass]="{
      'spr-weekly': options.frequency === views.WEEK,
      'spr-monthly': options.frequency === views.MONTH,
      'spr-holiday': holidays[i]?.length > 0
    }"
  >
    <kenjo-shift-card-pdf
      [class.kenjo-mb-10px]="options.space"
      *ngFor="let shift of openShifts[i]; let shiftIndex = index"
      [shift]="shift"
      [options]="options"
      [class.spr-mb-2px]="shiftIndex < openShifts[i].length - 1"
    ></kenjo-shift-card-pdf>
  </orgos-column>
</orgos-column-container>

<!-- Employee shifts row -->
<orgos-column-container *ngIf="employee" class="kenjo-border-right kenjo-border-bottom kenjo-border-left">
  <orgos-column *ngIf="options.orientation === orientation.PORTRAIT" class="kenjo-p-5px spr-subtitle spr-flex-center spr-column">
    <kenjo-truncate-text class="kenjo-inline-block spr-simple-text-width">{{
      employee.displayName | splitName: 'name'
    }}</kenjo-truncate-text>
    <kenjo-truncate-text class="kenjo-inline-block spr-simple-text-width">{{
      employee.displayName | splitName: 'lastName'
    }}</kenjo-truncate-text>
    <div *ngIf="options.includeWorkingTime" class="spr-working-time kenjo-pl-5px">{{ employee.workingTimeInMinutes | timeShiftplan }}</div>
  </orgos-column>
  <orgos-column *ngIf="options.orientation === orientation.LANDSCAPE" class="kenjo-p-5px spr-subtitle spr-flex-center spr-column">
    <kenjo-truncate-text class="kenjo-inline-block spr-simple-text-width">{{ employee.displayName }}</kenjo-truncate-text>
    <div *ngIf="options.includeWorkingTime" class="spr-working-time kenjo-pl-5px">{{ employee.workingTimeInMinutes | timeShiftplan }}</div>
  </orgos-column>
  <orgos-column
    *ngFor="let day of currentDays; let i = index"
    class="kenjo-border-left"
    [ngClass]="{
      'spr-weekly': options.frequency === views.WEEK,
      'spr-monthly': options.frequency === views.MONTH,
      'spr-holiday': holidays[i]?.length > 0
    }"
  >
    <kenjo-shiftplan-pdf-timeoff
      *ngIf="timeOff?.days?.[i]?.total > 0"
      class="spr-timeoff kenjo-mb-5px"
      [class.spr-h-100]="employee.shiftsByDay[i].length === 0"
      [timeOff]="timeOff.days[i]"
      [frequency]="options.frequency"
      [emptyShifts]="employee.shiftsByDay[i].length === 0"
      [timeOffTranslation]="{ label: componentTranslations.timeOffLabel }"
    >
    </kenjo-shiftplan-pdf-timeoff>

    <kenjo-shift-card-pdf
      [class.kenjo-mb-10px]="options.space"
      *ngFor="let shift of employee.shiftsByDay[i]; let shiftIndex = index"
      [shift]="shift"
      [options]="options"
      [class.spr-mb-2px]="shiftIndex < employee.shiftsByDay[i].length - 1"
    ></kenjo-shift-card-pdf>
  </orgos-column>
</orgos-column-container>
