import { Inject } from '@angular/core';
import { Optional } from '@angular/core';
import { Component, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IWorkScheduleTemplateModel } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { ITimeOffAssignmentModel } from '@app/cloud-features/time-off/services/time-off-status.service';
import { ITimeOffTypeModel } from '@app/cloud-features/time-off/services/time-off-type.service';
import { IUserWorkModel } from '@app/models/user-work.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { ICompanyModel } from '@app/standard/services/company/company.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { AddEmployeeController } from '@app/standard/services/employee-onboarding/controllers/add-employee.controller';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-dialog-add-employee',
  templateUrl: 'add-employee.dialog.html',
  styleUrls: ['add-employee.dialog.scss'],
})
export class AddEmployeeDialog implements OnInit {
  dialogTranslations: any = {};
  translatedWeekdays: any = {};
  currentSection: 0 | 1 | 2 | 3 | 4 = 0;
  dataLoaded: boolean = false;

  userPersonal: GenericCacheModel;
  userAccount: GenericCacheModel;
  userWork: GenericCacheModel;
  userWorkSchedule: GenericCacheModel;

  companies: Array<ICompanyModel>;
  companiesOptions: Array<ISelectOption>;
  offices: Array<ISelectOption>;
  departments: Array<ISelectOption>;
  areas: Array<ISelectOption>;
  divisions: Array<ISelectOption>;
  profiles: Array<ISelectOption>;
  reportsToOptions: Array<{ _id: string; displayName: string; _photo?: any; isActive: boolean }>;
  userProfile: string;

  assignments: Array<ITimeOffAssignmentModel> = [];

  workScheduleTemplates: Array<IWorkScheduleTemplateModel>;
  workScheduleTemplatesOptions: Array<ISelectOption>;
  workScheduleConfiguration: { workScheduleTypeSelected?: 'Template' | 'Legacy'; workScheduleTemplateSelected?: string } = {};

  annualCycleOptions: Array<ISelectOption>;

  timeOffTypes: Array<ITimeOffTypeModel>;
  originalOffTypes: Array<ITimeOffTypeModel>;
  attendancePolicies: Array<IAttendancePolicy>;
  attendancePoliciesOptions: Array<ISelectOption>;
  timeOffEnabled: boolean = true;
  isTimeOffApproverActive: boolean = true;
  attendanceEnabled: boolean = true;
  datevOptions: { isActive: boolean; personalNumber?: Number | undefined; companyId?: string | undefined; datevEnabled: boolean } = {
    isActive: false,
    personalNumber: undefined,
    companyId: undefined,
    datevEnabled: false,
  };

  activationOptions = { sendInvitation: true };
  activationPermissions = { sendInvitation: false, trackAttendance: false };
  timeOffStepPermission: boolean = false;

  private profilePermissionsResources: Array<string> = ['attendance-app', 'employees', 'time-off-app'];
  private profilePermissions: any;
  private myUserWork: IUserWorkModel;

  creatingEmployee = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddEmployeeDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });
    this.initData();
  }

  private async initData() {
    await this.getTimeOffAttendanceStatus();
    await this.getDatevStatus();
    await this.getTranslations();
    await this.getCreationData();
    this.initFields();
    this.getPermissions();
    this.userProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    this.dataLoaded = true;
  }

  private async getTimeOffAttendanceStatus() {
    try {
      const [timeOffConfiguration, timeOffStatus, attendanceStatus] = await Promise.all([
        this.injector.get(PrivateOrganizationService).getTimeOffConfiguration(),
        this.injector.get(CloudRoutesService).getAppStatus('time-off'),
        this.injector.get(CloudRoutesService).getAppStatus('attendance'),
      ]);

      this.timeOffEnabled = timeOffStatus.isActive;
      this.isTimeOffApproverActive = timeOffConfiguration.enableTimeOffApprover;
      this.attendanceEnabled = attendanceStatus.isActive;
    } catch {
      this.timeOffEnabled = true;
      this.isTimeOffApproverActive = true;
      this.attendanceEnabled = true;
    }
  }

  private async getDatevStatus() {
    try {
      const datevStatus = await this.injector.get(CloudRoutesService).getAppStatus('datev');
      this.datevOptions.isActive = datevStatus.isActive;
    } catch {
      this.datevOptions.isActive = false;
    }
  }

  private async getTranslations() {
    try {
      [this.dialogTranslations, this.translatedWeekdays] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('add-employee-dialog'),
        this.injector.get(InternationalizationService).getShortTranslatedWeekdays(),
      ]);
    } catch {
      this.dialogTranslations = {};
      this.translatedWeekdays = {};
    }
  }

  private async getCreationData() {
    try {
      const creationData = await this.injector.get(AddEmployeeController).getCreationData();
      this.companies = creationData.companies;
      this.companiesOptions = creationData.companies.map((iCompany) => ({ value: iCompany._id, name: iCompany.name }));
      this.offices = creationData.offices;
      this.departments = creationData.departments;
      this.areas = creationData.areas;
      this.divisions = creationData.divisions;

      this.profiles = creationData.profiles;
      this.reportsToOptions = creationData.reportsToOptions;
      this.timeOffTypes = creationData.timeOffTypes;
      this.originalOffTypes = _.cloneDeep(creationData.timeOffTypes);
      this.attendancePolicies = creationData.attendancePolicies;
      this.attendancePoliciesOptions = creationData.attendancePolicies.map((iAttendancePolicy) => ({
        value: iAttendancePolicy._id,
        name: iAttendancePolicy._isDefault ? this.dialogTranslations.defaultPolicy : iAttendancePolicy.name,
        _isDefault: iAttendancePolicy._isDefault,
      }));

      this.workScheduleTemplates = creationData.workScheduleTemplates;
      this.workScheduleTemplatesOptions = creationData.workScheduleTemplates.map((iTemplate) => ({
        value: iTemplate._id,
        name: iTemplate.name,
      }));
      this.workScheduleConfiguration.workScheduleTemplateSelected = creationData.workScheduleTemplates.find(
        (iTemplate) => iTemplate.isDefault
      )?._id;
      this.workScheduleConfiguration.workScheduleTypeSelected = check.assigned(this.workScheduleConfiguration.workScheduleTemplateSelected)
        ? 'Template'
        : 'Legacy';

      if (this.workScheduleConfiguration.workScheduleTypeSelected === 'Legacy' && creationData.workScheduleTemplates.length > 0) {
        this.workScheduleConfiguration.workScheduleTemplateSelected = creationData.workScheduleTemplates[0]._id;
      }
    } catch {
      // do nothing
    }
  }

  private initFields() {
    const selectedCompany = this.data?.companyId
      ? this.companies.find((iCompany) => iCompany._id === this.data.companyId)
      : { defaultLanguage: undefined, weeklyHours: 0 };
    const defaultPolicy = this.attendancePolicies.find((iAttendancePolicy) => iAttendancePolicy._isDefault);

    this.userAccount = new GenericCacheModel(
      this.injector,
      { email: '', language: selectedCompany?.defaultLanguage, profileKey: 'employee', attendancePolicy: defaultPolicy._id },
      UserAccountService,
      ''
    );
    this.userWorkSchedule = new GenericCacheModel(
      this.injector,
      {
        trackAttendance: false,
        mondayWorkingDay: true,
        tuesdayWorkingDay: true,
        wednesdayWorkingDay: true,
        thursdayWorkingDay: true,
        fridayWorkingDay: true,
        saturdayWorkingDay: false,
        sundayWorkingDay: false,
      },
      UserWorkScheduleService,
      ''
    );

    //In case we have data we add that data: firstName, lastName and jobTitle, in the employee info
    this.userPersonal = this.data
      ? new GenericCacheModel(
          this.injector,
          { firstName: this.data.firstName, lastName: this.data.lastName, gender: undefined },
          UserPersonalService,
          ''
        )
      : new GenericCacheModel(this.injector, { firstName: '', lastName: '', gender: undefined }, UserPersonalService, '');

    this.userWork = this.data
      ? new GenericCacheModel(
          this.injector,
          {
            jobTitle: this.data.jobTitle,
            startDate: undefined,
            reportsToId: undefined,
            employeeNumber: undefined,
            departmentId: this.data.departmentId,
            officeId: this.data.officeId,
            areaIds: undefined,
            teamIds: undefined,
            companyId: this.data.companyId,
            weeklyHours: selectedCompany?.weeklyHours ?? 0,
          },
          UserWorkService,
          ''
        )
      : new GenericCacheModel(
          this.injector,
          {
            jobTitle: undefined,
            startDate: undefined,
            reportsToId: undefined,
            employeeNumber: undefined,
            departmentId: undefined,
            officeId: undefined,
            areaIds: undefined,
            teamIds: undefined,
            companyId: undefined,
            weeklyHours: 0,
          },
          UserWorkService,
          ''
        );
  }

  private async getPermissions() {
    const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
    this.myUserWork = allUserWork.find((iUserWork) => iUserWork._id === this.injector.get(AuthenticationService).getLoggedUser()._id);

    this.profilePermissions = await this.injector
      .get(PrivateSecurityService)
      .getPermissionsForCollections(this.profilePermissionsResources);
    this.activationPermissions = {
      sendInvitation: this.profilePermissions['employees'].c_activateEmployees_all === true,
      trackAttendance: this.profilePermissions['attendance-app'].c_activateDeactivateTrackingPersonal_all === true,
    };
    this.timeOffStepPermission = this.profilePermissions['time-off-app'].c_manageTimeOff_all === true;
  }

  async onNextStep() {
    // Recompute permissions once we have the new userWork information
    if (this.currentSection === 1) {
      await Promise.all([this.computeTimeOffPermissions(), this.computeActivationPermissions()]);
      if (this.datevOptions.isActive === true && check.assigned(this.userWork?.data?.companyId)) {
        this.datevOptions.companyId = this.userWork.data.companyId;
      }
    }

    if (this.currentSection === 1) {
      if (!this.timeOffEnabled && !this.attendanceEnabled) {
        this.currentSection = 4;
      } else if (!this.timeOffEnabled && this.attendanceEnabled) {
        this.currentSection = 3;
      } else {
        this.currentSection = 2;
      }

      return;
    }

    if (this.currentSection === 2) {
      if (!this.attendanceEnabled) {
        this.currentSection = 4;
      } else {
        this.currentSection = 3;
      }

      return;
    }

    this.currentSection += 1;
  }

  onPreviousStep() {
    if (this.currentSection === 4) {
      if (!this.attendanceEnabled && !this.timeOffEnabled) {
        this.currentSection = 1;
      } else if (!this.attendanceEnabled && this.timeOffEnabled) {
        this.currentSection = 2;
      } else {
        this.currentSection = 3;
      }

      return;
    }

    if (this.currentSection === 3) {
      if (!this.timeOffEnabled) {
        this.currentSection = 1;
      } else {
        this.currentSection = 2;
      }

      return;
    }

    this.currentSection -= 1;
  }

  onReloadTimeOffStep() {
    this.timeOffTypes = _.cloneDeep(this.originalOffTypes);
  }

  goToSection(section: 0 | 1 | 2 | 3) {
    if (section >= this.currentSection) {
      return;
    }

    this.currentSection = section;
  }

  async onAddEmployee(event: any) {
    try {
      this.creatingEmployee = true;
      const { addUserDatev, userDatevPersonalNumber } = event;
      const userPersonal = Object.assign({}, this.userPersonal.data);
      const userWork = Object.assign({}, this.userWork.data);
      const userAccount = Object.assign({}, this.userAccount.data);
      const userWorkSchedule = Object.assign({}, this.userWorkSchedule.data);
      const timeOffAssignments = { assignments: this.assignments };
      this.sendAmplitudeEvents({ userPersonal, userWork });

      const candidateData = {
        candidateId: this.data?.candidateId,
        positionCandidateId: this.data?.positionCandidateId,
      };

      if (check.assigned(userWork.weeklyHours) && userWork.weeklyHours <= 0) {
        delete userWork.weeklyHours;
      }

      if (check.assigned(userWork.weeklyHours) && userWork.weeklyHours <= 0) {
        delete userWork.weeklyHours;
      }

      if (userPersonal.nationality === null) {
        delete userPersonal.nationality;
      }

      const newUserId = check.not.assigned(this.data)
        ? await this.injector
            .get(AddEmployeeController)
            .addEmployee(
              userAccount,
              userPersonal,
              userWork,
              userWorkSchedule,
              timeOffAssignments,
              this.activationOptions.sendInvitation,
              addUserDatev,
              userDatevPersonalNumber
            )
        : await this.injector
            .get(AddEmployeeController)
            .addCandidateToEmployees(
              userAccount,
              userPersonal,
              userWork,
              userWorkSchedule,
              timeOffAssignments,
              this.activationOptions.sendInvitation,
              candidateData,
              addUserDatev,
              userDatevPersonalNumber
            );
      this.creatingEmployee = false;
      this.dialogRef.close(newUserId);
    } catch {
      // An error is already shown
      this.injector.get(GlobalBarService).setProgressBar(false);
      this.creatingEmployee = false;
    }
  }

  private async sendAmplitudeEvents({ userPersonal, userWork }): Promise<void> {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('add employee', { platform: 'Web', category: 'Employee', subcategory1: 'Creation wizard' });
    if (check.not.assigned(this.data)) {
      this.injector.get(PrivateAmplitudeService).logEvent('invite employee', { platform: 'Web', category: 'PQL' });
    }
    const fields = [];
    if (check.assigned(userPersonal.birthdate)) fields.push('date of birth');
    if (check.assigned(userPersonal.gender)) fields.push('gender');
    if (check.assigned(userPersonal.nationality)) fields.push('nationality');
    if (check.assigned(userWork.officeId)) fields.push('office');
    if (check.assigned(userWork.departmentId)) fields.push('department');
    if (check.assigned(userWork.areaIds)) fields.push('divisions');
    if (check.assigned(userWork.teamIds)) fields.push('teams');
    if (check.assigned(userWork.jobTitle)) fields.push('job Tittle');
    if (check.assigned(userWork.reportsToId)) fields.push('report to');
    if (check.assigned(userWork.employeeNumber)) fields.push('employee ID');
    if (check.assigned(userWork.startDate)) fields.push('start date');
    if (check.assigned(userWork.contractEnd)) fields.push('contract end');

    fields.forEach((iEvent) => {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent(iEvent, { platform: 'Web', category: 'Employee', subcategory1: 'Creation wizard' });
    });
  }

  private async computeTimeOffPermissions() {
    try {
      this.timeOffStepPermission = await this.computePermission('time-off-app.c_manageTimeOff');

      if (this.timeOffStepPermission === false) {
        this.onReloadTimeOffStep();
      }
    } catch {}
  }

  private async computeActivationPermissions() {
    this.activationPermissions = {
      sendInvitation: await this.computePermission('employees.c_activateEmployees'),
      trackAttendance: await this.computePermission('attendance-app.c_activateDeactivateTrackingPersonal'),
    };

    if (this.activationPermissions.sendInvitation === false) {
      this.activationOptions.sendInvitation = false;
    }

    this.userWorkSchedule.data.trackAttendance = this.attendanceEnabled && this.activationPermissions.trackAttendance;
  }

  private async computePermission(permission: string) {
    if (check.not.assigned(this.userWork.data)) {
      return false;
    }

    if (this.userProfile === 'admin' || this.userProfile === 'hr-admin') {
      return true;
    }

    const [category, permissionKey] = permission.split('.');

    const permissionsAll = this.profilePermissions[category][`${permissionKey}_all`] === true;
    if (permissionsAll) {
      return true;
    }

    const newUserWork = { ..._.cloneDeep(this.userWork.data), _id: 'someId', ownerId: 'someId' };

    const permissionsCustom = await customPermissions.applyCustomPermissionsToDocument(
      null,
      'user-work',
      this.profilePermissions[category][`${permissionKey}_custom`],
      null,
      newUserWork,
      [this.myUserWork, newUserWork],
      this.injector.get(AuthenticationService).getLoggedUser()
    );

    return permissionsCustom;
  }

  closeDialog(): void {
    const dialogData: any = {
      titleText: this.dialogTranslations.closeDialogTitleText,
      subtitleText: this.dialogTranslations.closeDialogSubtitleText,
      confirmButtonText: this.dialogTranslations.closeDialogConfirmButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.dialogTranslations.closeDialogCancelButton,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe((confirmClose: boolean) => {
      if (check.assigned(confirmClose) && confirmClose === true) {
        this.dialogRef.close(0);
      }
    });
  }
}
