import { Component, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { ITimeOffAssignmentModel } from '@app/cloud-features/time-off/services/time-off-status.service';
import { ITimeOffTypePolicyModel } from '@app/cloud-features/time-off/services/time-off-type.service';
import { IPreAssignmentInfo, IPreAssignmentUserInfo, TimeOffUserPolicyController } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  ALLOWANCE_TYPE_UNLIMITED,
  CYCLE_TYPE_DISABLED,
  CYCLE_TYPE_DYNAMIC,
  CYCLE_TYPE_FIXED,
  POLICY_TYPE_DAY,
  POLICY_TYPE_HOUR,
  TIME_OFF_ASSIGNMENT_ACTION_ASSIGN,
  TIME_OFF_ASSIGNMENT_ACTION_UNASSIGN,
  TIME_OFF_ASSIGNMENT_ACTION_UNSCHEDULE,
  TIME_OFF_ASSIGNMENT_APPLY_DATE_EMPLOYEE_START,
  TIME_OFF_ASSIGNMENT_APPLY_DATE_FIXED
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-manage-user-policies',
  templateUrl: 'manage-user-policies.component.html',
  styleUrls: ['manage-user-policies.component.scss']
})
export class ManageUserPoliciesComponent implements OnInit {
  @Input() timeOffTypes: Array<ITimeOffTypeToManageModel>;
  @Input() originalTimeOffTypes: Array<ITimeOffTypePolicyModel>;
  @Input() userStartDate: any;
  @Input() contractEnd: any;
  @Input() userStatus: any = {};
  @Input() userId: string;
  @Input() userWorkSchedule: GenericCacheModel;
  @Input() userWork: GenericCacheModel;
  @Input() scheduledPolicies: Array<IScheduledPolicyModel>;
  @Output() assignments: EventEmitter<Array<ITimeOffAssignmentModel>> = new EventEmitter<Array<ITimeOffAssignmentModel>>();
  @Output() validAssignments: EventEmitter<boolean> = new EventEmitter<boolean>();

  translations: any = {};
  componentLoaded: boolean = false;
  balanceFieldsValidation: any = {};

  DYNAMIC_CYCLE_MISSING: string = 'DynamicCycleMissing';
  START_DATE_MISSING: string = 'StartDateMissing';

  applyDateOptions: Array<ISelectOption> = [];

  POLICY_TYPE_HOUR: string = POLICY_TYPE_HOUR;
  POLICY_TYPE_DAY: string = POLICY_TYPE_DAY;
  POLICY_TYPE_DAY_MAX_VALUE = 366;
  POLICY_TYPE_DAY_MIN_VALUE = -366;
  POLICY_TYPE_HOUR_MAX_VALUE = 8784;
  POLICY_TYPE_HOUR_MIN_VALUE = -8784;
  POLICY_TYPE_MINUTE_MAX_VALUE = 59;
  POLICY_TYPE_MINUTE_MIN_VALUE = -59;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initComponent();
  }

  private async initComponent() {
    try {
      await this.initTranslations();
      if (check.not.assigned(this.originalTimeOffTypes) || (check.assigned(this.originalTimeOffTypes) && _.isEqual(this.originalTimeOffTypes, this.timeOffTypes))) {
        this.timeOffTypes = this.initPoliciesToManage();
      }
      this.componentLoaded = true;
    } catch {
      // Do nothing
    }
  }

  private async initTranslations() {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('manage-user-policies');
      const { applyDatePicklist } = this.translations;
      this.applyDateOptions = Object.keys(applyDatePicklist).map((key) => {
        return { name: applyDatePicklist[key], value: key };
      });
    } catch {
      this.translations = {};
      this.applyDateOptions = [];
    }
  }

  private initPoliciesToManage() {
    const status = this.userStatus?.status;
    const typesWithPolicyOptions = this.timeOffTypes.map((iTimeOffType) => {
      const currentStatus = status?.find((iStatus) => iStatus.timeOffType._id === iTimeOffType._id);
      const scheduledPolicy = this.scheduledPolicies?.find((iScheduledPolicy) => iScheduledPolicy.timeOffTypeId === iTimeOffType._id);
      if (check.nonEmptyObject(scheduledPolicy)) {
        const policy = iTimeOffType._policies.find((iPolicy) => iPolicy._id === scheduledPolicy.policyId);
        scheduledPolicy['policyName'] = policy.name;
        scheduledPolicy['policyType'] = policy._type;
      }
      iTimeOffType.scheduledPolicy = scheduledPolicy;
      iTimeOffType.oldPolicyId = currentStatus?.policy._id;
      iTimeOffType.newPolicyId = currentStatus?.policy._id;
      iTimeOffType.oldPolicyType = currentStatus?.policy._type;
      iTimeOffType.newPolicyType = currentStatus?.policy._type;

      if (iTimeOffType.newPolicyType !== POLICY_TYPE_DAY) {
        iTimeOffType.balance = { hours: undefined, minutes: undefined };
      }

      const policyOptions: Array<ISelectOption> = iTimeOffType._policies.reduce((total, iPolicy) => {
        if (this.checkSameTypeAsCurrentStatus(iPolicy, currentStatus)) {
          return total.concat({ value: iPolicy._id, name: iPolicy.name });
        }
        return total;
      }, []);

      iTimeOffType.policyOptions = policyOptions;
      return iTimeOffType;
    });
    return typesWithPolicyOptions;
  }

  onPolicyChange(timeOffType: ITimeOffTypeToManageModel, newPolicyId: string | null): void {
    const policy = timeOffType._policies.find((iPolicy) => iPolicy._id === newPolicyId);
    this.removeNewPolicyFields(timeOffType);
    this.removePolicyInputValidation(timeOffType);

    // delete selected policy
    if (newPolicyId === null) {
      // unassignments
      this.checkInputValidations();
      return;
    }

    // check if there are startDate conflicts or endDate proration conflicts
    this.getSelectedPolicyConflicts(policy, timeOffType);

    // set the new policy config
    timeOffType.newPolicyCycleType = policy.cycleType;
    timeOffType.newPolicyId = newPolicyId;
    timeOffType.newPolicyType = policy._type;

    if (policy.allowanceType === ALLOWANCE_TYPE_UNLIMITED) {
      timeOffType.newPolicyUnlimitedBalance = true;
    } else {
      timeOffType.newPolicyUnlimitedBalance = false;
    }
    this.validateBalanceField(new InputValidation(), timeOffType.newPolicyId, timeOffType.newPolicyType);
    if (!check.nonEmptyString(timeOffType?.assignmentConflict)) {
      this.calculateApplyDateOptions(timeOffType);
    }
  }

  private async calculateApplyDateOptions(timeOffType: ITimeOffTypeToManageModel) {
    const policySelected = timeOffType._policies.find((iPolicy) => iPolicy._id === timeOffType.newPolicyId);
    if (policySelected.cycleType === CYCLE_TYPE_FIXED) {
      timeOffType.applyDateOptions = this.applyDateOptions;
      timeOffType.disabledAssignDate = false;
    }
    if (policySelected.cycleType === CYCLE_TYPE_DYNAMIC) {
      const applyDate = this.applyDateOptions.filter((iApplyDateOption) => iApplyDateOption.value === TIME_OFF_ASSIGNMENT_APPLY_DATE_EMPLOYEE_START);
      timeOffType.applyDateOptions = applyDate;
      timeOffType.applyDate = applyDate[0].value;
      timeOffType.disabledAssignDate = true;
      this.onApplyDateChange(timeOffType, applyDate[0].value);
    }
    if (policySelected.cycleType === CYCLE_TYPE_DISABLED) {
      const applyDate = this.applyDateOptions.filter((iApplyDateOption) => iApplyDateOption.value === TIME_OFF_ASSIGNMENT_APPLY_DATE_FIXED);
      timeOffType.applyDateOptions = applyDate;
      timeOffType.applyDate = applyDate[0].value;
      timeOffType.disabledAssignDate = false;
      this.onApplyDateChange(timeOffType, applyDate[0].value);
    }
  }

  async onApplyDateChange(timeOffType: ITimeOffTypeToManageModel, newApplyDate) {
    timeOffType.applyDate = newApplyDate;
    const policySelected = timeOffType._policies.find((iPolicy) => iPolicy._id === timeOffType.newPolicyId);

    if (newApplyDate === TIME_OFF_ASSIGNMENT_APPLY_DATE_EMPLOYEE_START && check.not.assigned(this.userStartDate)) {
      timeOffType.assignmentConflict = this.START_DATE_MISSING;
      this.checkInputValidations();
      return;
    }
    delete timeOffType.assignmentConflict;

    const preAssignmentData = {
      timeOffTypeId: policySelected._timeOffTypeId,
      policyId: policySelected._id,
      userId: this.userId,
      applyDate: newApplyDate
    };

    if (newApplyDate === TIME_OFF_ASSIGNMENT_APPLY_DATE_FIXED) {
      preAssignmentData['fixedDate'] = moment.utc().startOf('day').toISOString();
    }

    const preAssignmentInfo = await this.calculatePreAssignment(preAssignmentData);
    timeOffType.assignDate = preAssignmentInfo[0].assignDate;
    timeOffType.balance = this.calculateBalanceUnits(policySelected._type, preAssignmentInfo[0].balance);
    timeOffType.minAssignDate = this.calculateMinDate(policySelected);
    timeOffType.scheduledAssignment = this.calculateScheduledAssignment(policySelected, timeOffType.assignDate);
    this.checkInputValidations();
  }

  private calculateMinDate(policy: ITimeOffPolicyModel) {
    const { cycleType, annualCycle } = policy;
    if (cycleType === CYCLE_TYPE_DISABLED) {
      return moment.utc().startOf('day').toDate();
    }
    if (cycleType === CYCLE_TYPE_FIXED) {
      return timeOffHelpers.calculateStartDateThisCycle(annualCycle).toDate();
    }
  }

  async onAssignDateChange(timeOffType: ITimeOffTypeToManageModel, newAssignDate) {
    timeOffType.applyDate = TIME_OFF_ASSIGNMENT_APPLY_DATE_FIXED;
    const policySelected = timeOffType._policies.find((iPolicy) => iPolicy._id === timeOffType.newPolicyId);
    const preAssignmentData = {
      timeOffTypeId: policySelected._timeOffTypeId,
      policyId: policySelected._id,
      userId: this.userId,
      applyDate: timeOffType.applyDate,
      fixedDate: newAssignDate
    };

    const preAssignmentInfo = await this.calculatePreAssignment(preAssignmentData);
    timeOffType.assignDate = preAssignmentInfo[0].assignDate;
    timeOffType.balance = this.calculateBalanceUnits(policySelected._type, preAssignmentInfo[0].balance);
    timeOffType.scheduledAssignment = this.calculateScheduledAssignment(policySelected, preAssignmentInfo[0].assignDate);
    this.checkInputValidations();
  }

  private async calculatePreAssignment(preAssignmentInfo: IPreAssignmentInfo) {
    if (check.assigned(this.userWorkSchedule) && check.assigned(this.userWork)) {
      const userInfo: IPreAssignmentUserInfo = {
        companyId: this.userWork.data?.companyId,
        schedule: {
          mondayWorkingDay: this.userWorkSchedule.data?.mondayWorkingDay,
          tuesdayWorkingDay: this.userWorkSchedule.data?.tuesdayWorkingDay,
          wednesdayWorkingDay: this.userWorkSchedule.data?.wednesdayWorkingDay,
          thursdayWorkingDay: this.userWorkSchedule.data?.thursdayWorkingDay,
          fridayWorkingDay: this.userWorkSchedule.data?.fridayWorkingDay,
          saturdayWorkingDay: this.userWorkSchedule.data?.saturdayWorkingDay,
          sundayWorkingDay: this.userWorkSchedule.data?.sundayWorkingDay,
          history: this.userWorkSchedule.data?.history ? this.buildHistory(this.userWorkSchedule.data?.history) : undefined
        }
      };

      if (check.assigned(this.userWork.data?.contractEnd) || check.assigned(this.contractEnd)) {
        userInfo.contractEnd = this.userWork.data?.contractEnd ?? this.contractEnd;
      }

      if (check.assigned(this.userWork.data?.startDate) || check.assigned(this.userStartDate)) {
        userInfo.startDate = this.userWork.data?.startDate ?? this.userStartDate;
      }

      preAssignmentInfo['userInfo'] = userInfo;
    }
    return this.injector.get(TimeOffUserPolicyController).calculatePreAssignment([preAssignmentInfo]);
  }

  private calculateScheduledAssignment(policy: ITimeOffPolicyModel, newAssignDate: Date): boolean {
    const assignDate = moment.utc(newAssignDate);
    const today = moment.utc();
    return assignDate.isAfter(today, 'day');
  }

  validateBalanceField(inputValidation: InputValidation, newPolicyId: string, newPolicyType: string, inputType?: string): void {
    if (newPolicyType === POLICY_TYPE_DAY) {
      this.balanceFieldsValidation[newPolicyId] = inputValidation;
    } else if (check.not.assigned(inputType)) {
      this.balanceFieldsValidation[newPolicyId] = {
        hours: inputValidation,
        minutes: inputValidation
      };
    } else {
      if (check.not.assigned(this.balanceFieldsValidation[newPolicyId])) {
        this.balanceFieldsValidation[newPolicyId] = {};
      }
      if (inputType === 'hours') {
        this.balanceFieldsValidation[newPolicyId].hours = inputValidation;
      } else {
        this.balanceFieldsValidation[newPolicyId].minutes = inputValidation;
      }
    }
    this.checkInputValidations();
  }

  public checkInputValidations() {
    const allInputsValid = Object.keys(this.balanceFieldsValidation).every((key: string) => {
      const timeOffType = this.timeOffTypes.find((iTimeOffType) => iTimeOffType.newPolicyId === key);
      let validInput = false;
      let balanceAssigned = false;
      if (timeOffType?.newPolicyType === POLICY_TYPE_DAY) {
        validInput = !this.balanceFieldsValidation[key].hasErrors();
        balanceAssigned = check.number(timeOffType?.balance);
      }
      if (timeOffType?.newPolicyType === POLICY_TYPE_HOUR) {
        validInput = !this.balanceFieldsValidation[key]?.hours?.hasErrors() && !this.balanceFieldsValidation[key]?.minutes?.hasErrors();
        if (validInput === true && check.assigned(timeOffType?.balance?.['hours']) && check.assigned(timeOffType?.balance?.['minutes'])) {
          const maxHoursError = Math.abs(timeOffType?.balance?.['hours']) === Math.abs(this.POLICY_TYPE_HOUR_MAX_VALUE) && timeOffType?.balance?.['minutes'] !== 0;
          const positiveNegativeError = (timeOffType?.balance?.['hours'] > 0 && timeOffType?.balance?.['minutes'] < 0) || (timeOffType?.balance?.['hours'] < 0 && timeOffType?.balance?.['minutes'] > 0);
          if (maxHoursError || positiveNegativeError) {
            if (check.not.assigned(this.balanceFieldsValidation[key])) {
              this.balanceFieldsValidation[key] = {};
            }
            validInput = false;
          }
          this.balanceFieldsValidation[key].maxHours = maxHoursError;
          this.balanceFieldsValidation[key].positiveNegative = positiveNegativeError;
        }
        balanceAssigned = check.number(timeOffType?.balance?.['hours']) && check.number(timeOffType?.balance?.['minutes']);
      }
      const policyIsUnlimited = timeOffType?.newPolicyUnlimitedBalance;
      const hasConflicts = check.nonEmptyString(timeOffType?.assignmentConflict);
      return validInput && (balanceAssigned || (policyIsUnlimited && check.assigned(timeOffType.applyDate))) && !hasConflicts;
    });

    const anyTypeHasConflicts = this.timeOffTypes.some((iTimeOffType) => {
      return check.nonEmptyString(iTimeOffType?.assignmentConflict);
    });

    const assignments = allInputsValid && !anyTypeHasConflicts ? this.buildAssignments() : [];
    Promise.resolve().then(() => {
      this.assignments.emit(assignments);
    });
  }

  private buildAssignments() {
    return this.timeOffTypes.reduce((total, iTimeOffType) => {
      let assignments = [];

      // Assignment or reassignment
      if (check.nonEmptyString(iTimeOffType?.newPolicyId) && iTimeOffType?.newPolicyId !== iTimeOffType?.oldPolicyId) {
        const assignment = {
          timeOffTypeId: iTimeOffType._id,
          policyId: iTimeOffType.newPolicyId,
          userId: this.userId,
          action: TIME_OFF_ASSIGNMENT_ACTION_ASSIGN,
          assignDate: iTimeOffType.assignDate
        };

        if (iTimeOffType.newPolicyUnlimitedBalance !== true) {
          if (iTimeOffType.newPolicyType === POLICY_TYPE_DAY) {
            assignment['balance'] = iTimeOffType.balance;
          } else {
            if (check.not.assigned(iTimeOffType.balance)) {
              assignment['balance'] = { hours: undefined, minutes: undefined };
            } else {
              assignment['balance'] = timeOffHelpers.fromHoursAndMinutesToMinutes(iTimeOffType.balance['hours'], iTimeOffType.balance['minutes']);
            }
          }
        }
        assignments = assignments.concat(assignment);
      }

      // Unassignment
      if (check.nonEmptyString(iTimeOffType?.oldPolicyId) && check.not.assigned(iTimeOffType?.newPolicyId)) {
        const unassignCall = {
          timeOffTypeId: iTimeOffType._id,
          policyId: iTimeOffType.oldPolicyId,
          userId: this.userId,
          action: TIME_OFF_ASSIGNMENT_ACTION_UNASSIGN
        };
        assignments = assignments.concat(unassignCall);
      }

      // Unschedule
      if (iTimeOffType.unscheduleAssignment === true && iTimeOffType.scheduledAssignment !== true && (check.not.assigned(iTimeOffType?.newPolicyId) || iTimeOffType?.newPolicyId === iTimeOffType?.oldPolicyId)) {
        const unscheduleCall = {
          timeOffTypeId: iTimeOffType._id,
          policyId: iTimeOffType.scheduledPolicy.policyId,
          userId: this.userId,
          action: TIME_OFF_ASSIGNMENT_ACTION_UNSCHEDULE
        };
        assignments = assignments.concat(unscheduleCall);
      }

      return total.concat(assignments);
    }, []);
  }

  private removeNewPolicyFields(timeOffType: ITimeOffTypeToManageModel): void {
    delete timeOffType.newPolicyId;
    delete timeOffType.newPolicyType;
    delete timeOffType.newPolicyUnlimitedBalance;
    delete timeOffType.applyDate;
    delete timeOffType.balance;
    delete timeOffType.assignDate;
    delete timeOffType.assignmentConflict;
    delete timeOffType.scheduledAssignment;
  }

  private removePolicyInputValidation(timeOffType: ITimeOffTypePolicyModel): void {
    const policiesBeingValidated = Object.keys(this.balanceFieldsValidation);
    const policyId = timeOffType._policies.find((iPolicy: ITimeOffPolicyModel) => policiesBeingValidated.includes(iPolicy._id))?._id;
    if (check.assigned(policyId)) {
      delete this.balanceFieldsValidation[policyId];
    }
  }

  cancelScheduledAssignment(timeOffType: ITimeOffTypeToManageModel): void {
    timeOffType.unscheduleAssignment = true;
    this.checkInputValidations();
  }

  private buildHistory(history) {
    return history.map((iHistory) => {
      return {
        startDate: iHistory.startDate,
        dayShifts: iHistory.dayShifts.map((iShift) => {
          return { minutes: iShift.minutes };
        })
      };
    });
  }

  private getSelectedPolicyConflicts(policy: ITimeOffPolicyModel, timeOffType: ITimeOffTypeToManageModel) {
    if (policy.cycleType === CYCLE_TYPE_DYNAMIC && check.not.assigned(this.userStartDate)) {
      timeOffType.assignmentConflict = this.DYNAMIC_CYCLE_MISSING;
      return;
    }
  }

  private checkSameTypeAsCurrentStatus(policy, currentStatus) {
    return check.not.assigned(currentStatus) || (check.assigned(currentStatus) && currentStatus.policy._type === policy._type);
  }

  private calculateBalanceUnits(policyType: string, balance: number) {
    if (policyType === POLICY_TYPE_DAY) {
      return balance;
    }
    return timeOffHelpers.fromMinutesToHoursAndMinutes(balance);
  }
}

export interface ITimeOffTypeToManageModel extends ITimeOffTypePolicyModel {
  oldPolicyId: string;
  newPolicyId: string;
  oldPolicyType: string;
  newPolicyType: string;
  newPolicyCycleType: string;
  policyOptions: Array<ISelectOption>;
  balance: number | { hours: number; minutes: number };
  newPolicyUnlimitedBalance: boolean;
  applyDate: string;
  assignDate: Date;
  applyDateOptions: Array<ISelectOption>;
  minAssignDate: Date;
  disabledAssignDate: boolean;
  scheduledAssignment: boolean;
  assignmentConflict?: string;
  scheduledPolicy: IScheduledPolicyModel;
  unscheduleAssignment?: boolean;
}

export interface IScheduledPolicyModel {
  pictureUserScheduled: string;
  nameUserScheduled: string;
  policyId: string;
  timeOffTypeId: string;
  policyName?: string;
  policyType?: string;
  scheduledFor?: Date;
}
