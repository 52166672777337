<orgos-column-container class="wmc-warning-container kenjo-p-10px" centerColumns="true">
  <orgos-column size="0">
    <kenjo-icon [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
  </orgos-column>
  <orgos-column size="1" class="kenjo-ml-10px kenjo-font-color-light-text-757575">
    <ng-content></ng-content>
  </orgos-column>
  <orgos-column *ngIf="canBeClosed" size="0" class="kenjo-ml-10px kenjo-font-color-light-text-757575">
    <kenjo-icon [size]="18" (click)="hideWarning.emit()" class="kenjo-font-color-text-333333 kenjo-cursor-pointer">clear</kenjo-icon>
  </orgos-column>
</orgos-column-container>
