<orgos-dialog-container class="dialog-box" *ngIf="role" [title]="isNewRole ? dialogTranslation.dialogAddRole : dialogTranslation.dialogEditRole" (clickCloseButton)="closeDialog()">
  <!-- Create -->
  <div class="kenjo-pt-20px" *ngIf="isNewRole">
    <orgos-column-container [centerColumns]="true" class="kenjo-mb-20px">
      <orgos-input-text orgosColumn="2" [label]="dialogTranslation.roleName" [placeholder]="dialogTranslation.namePlaceholder" [model]="role" field="name" [minlength]="3" [maxlength]="51" [required]="true" class="kenjo-mr-50px" (validation)="nameCreateValidation = $event">
        <orgos-input-error *ngIf="nameCreateValidation && nameCreateValidation.getError('required')"> {{dialogTranslation.nameRequired}}</orgos-input-error>
        <orgos-input-error *ngIf="nameCreateValidation && !nameCreateValidation.getError('required') && nameCreateValidation.getError('minlength')"> {{dialogTranslation.roleMinLength}} </orgos-input-error>
        <orgos-input-error *ngIf="nameCreateValidation && !nameCreateValidation.getError('required') && nameCreateValidation.getError('maxlength')"> {{dialogTranslation.roleMaxLength}} </orgos-input-error>
      </orgos-input-text>

      <orgos-input-text orgosColumn [label]="dialogTranslation.roleCodeName" [placeholder]="dialogTranslation.codeNamePlaceholder" [model]="role" field="codeName" [maxlength]="2" (currentValue)="validateCodeName($event)">
        <orgos-input-error *ngIf="!isCodeValid"> {{dialogTranslation.codeNameLength}} </orgos-input-error>
      </orgos-input-text>
    </orgos-column-container>

    <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{dialogTranslation.roleColour}}</div>
    <orgos-input-color-picker [model]="role" field="colour" [required]="true"></orgos-input-color-picker>
  </div>

  <!-- Edit -->
  <div class="kenjo-pt-20px" *ngIf="!isNewRole">
    <orgos-column-container [centerColumns]="true" class="kenjo-mb-20px">
      <orgos-input-text orgosColumn [label]="dialogTranslation.roleName" [placeholder]="dialogTranslation.namePlaceholder" [model]="role" field="name" [minlength]="3" [maxlength]="51" [required]="true" class="kenjo-mr-50px" (validation)="nameEditValidation = $event">
        <orgos-input-error *ngIf="nameEditValidation && nameEditValidation.getError('required')"> {{dialogTranslation.nameRequired}}</orgos-input-error>
        <orgos-input-error *ngIf="nameEditValidation && !nameEditValidation.getError('required') && nameEditValidation.getError('minlength')"> {{dialogTranslation.roleMinLength}} </orgos-input-error>
        <orgos-input-error *ngIf="nameEditValidation && !nameEditValidation.getError('required') && nameEditValidation.getError('maxlength')"> {{dialogTranslation.roleMaxLength}} </orgos-input-error>
      </orgos-input-text>

      <orgos-input-text orgosColumn [label]="dialogTranslation.roleCodeName" [placeholder]="dialogTranslation.codeNamePlaceholder" [model]="role" field="codeName" [maxlength]="2" (currentValue)="validateCodeName($event)">
        <orgos-input-error *ngIf="!isCodeValid"> {{dialogTranslation.codeNameLength}} </orgos-input-error>
      </orgos-input-text>
    </orgos-column-container>

    <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{dialogTranslation.roleColour}}*</div>
    <orgos-input-color-picker [model]="role" field="colour" [required]="true"></orgos-input-color-picker>
  </div>
  <div class="kenjo-mt-60px kenjo-text-align-right">
    <orgos-button-raised id="aerd-go-back-btn" class="kenjo-mr-10px" color="Neutral" (click)="closeDialog()">{{dialogTranslation.goBackButtonDialog}}</orgos-button-raised>
    <orgos-button-raised id="aerd-add-role-btn" *ngIf="isNewRole" color="Success" (click)="addRole()" [disabled]="!nameCreateValidation || nameCreateValidation.hasErrors() || !isCodeValid"> {{dialogTranslation.saveButtonLabel}} </orgos-button-raised>
    <orgos-button-raised id="aerd-edit-role-btn" *ngIf="!isNewRole" color="Success" (click)="editRole()" [disabled]="!nameEditValidation || nameEditValidation.hasErrors() || !isCodeValid"> {{dialogTranslation.saveButtonLabel}} </orgos-button-raised>
  </div>
</orgos-dialog-container>
