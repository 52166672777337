import { Pipe, PipeTransform } from '@angular/core';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Pipe({
  name: 'duration',
  pure: false,
})
export class DurationPipe implements PipeTransform {
  private latestRawValue: number;
  private latestComputedValue: string | null = null;
  private translationsShortVersion: string = '%%data.hours%%h %%data.minutes%%m';

  constructor(private internationalizationService: InternationalizationService, private i18nDataPipe: I18nDataPipe) {}

  transform(
    value: number,
    usePlusSymbol: boolean = false,
    shortVersion: boolean = false,
    hideIfPossible = false,
    minutesIfZeroValue = false
  ): string | null {
    if (check.not.assigned(value) || check.not.number(value)) {
      return null;
    }

    if (_.isEqual(this.latestRawValue, value)) {
      return this.latestComputedValue;
    }

    this.latestRawValue = value;

    const miscTranslation = this.internationalizationService.getMiscTranslation();
    if (shortVersion === true) {
      this.latestComputedValue = this.convertDuration(
        value,
        usePlusSymbol,
        this.translationsShortVersion,
        hideIfPossible,
        minutesIfZeroValue
      );
    } else if (check.not.assigned(miscTranslation) || check.emptyObject(miscTranslation) || check.not.assigned(miscTranslation.duration)) {
      this.latestComputedValue = this.convertDuration(value, usePlusSymbol, miscTranslation.duration, hideIfPossible, minutesIfZeroValue);
    } else {
      this.latestComputedValue = this.convertDuration(value, usePlusSymbol);
    }

    return this.latestComputedValue;
  }

  private convertDuration(
    value: number,
    usePlusSymbol: boolean,
    translations: string = '%%data.hours%%h %%data.minutes%%min',
    hideIfPossible = false,
    minutesIfZeroValue = false
  ): string {
    const absValue = Math.abs(value);
    const convertedValue = {
      hours: Math.floor(absValue / 60),
      minutes: Math.round((absValue / 60 - Math.floor(absValue / 60)) * 60),
    };

    let duration: string;
    if (
      hideIfPossible &&
      convertedValue.hours === 0 &&
      ((convertedValue.minutes === 0 && minutesIfZeroValue) || convertedValue.minutes > 0)
    ) {
      duration = this.i18nDataPipe.transform(translations.split(' ')[1], convertedValue);
    } else if (hideIfPossible && convertedValue.minutes === 0) {
      duration = this.i18nDataPipe.transform(translations.split(' ')[0], convertedValue);
    } else {
      duration = this.i18nDataPipe.transform(translations, convertedValue);
    }

    if (value < 0) {
      duration = `-${duration}`;
    }
    if (value > 0 && usePlusSymbol === true) {
      duration = `+${duration}`;
    }
    return duration;
  }
}
