import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPositionSummary } from '@app/models/controllers/recruiting.model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { WidgetsService } from '@app/standard/services/widgets/widgets.service';

@Component({
  selector: 'orgos-widget-job-opening',
  templateUrl: 'orgos-widget-job-opening.component.html',
  styleUrls: ['orgos-widget-job-opening.component.scss']
})
export class WidgetJobOpeningComponent implements OnInit {
  translation: any = {};
  loadingWidget: boolean = false;
  jobOpenings: Array<IPositionSummary>;

  positions: Array<any> = [];
  positionNameById: object = {};

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.loadingWidget = true;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('home-widget-job-opening-component')
      .then((translation) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.getWidgetInformation();
  }

  private getWidgetInformation(): Promise<void> {
    return new Promise((resolve) => {
      this.injector
        .get(WidgetsService)
        .getJobOpenings()
        .then((jobOpeningsRes: Array<IPositionSummary>) => {
          this.jobOpenings = jobOpeningsRes;
          this.loadingWidget = false;
          resolve();
        })
        .catch((error) => {
          this.jobOpenings = [];
          this.loadingWidget = false;
          resolve();
        });
    });
  }

  public navigateToAJobPositionPage(positionId: string): void {
    this.injector.get(Router).navigateByUrl(`cloud/recruiting/position/${positionId}`);
  }
}
