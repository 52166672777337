<div class="ews-main-container kenjo-pv-30px kenjo-ph-40px">
  <orgos-column-container class="kenjo-mb-30px">
    <orgos-column size="1" class="kenjo-font-size-20px">{{dialogTranslation.pageHeader}}</orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" class="kenjo-ml-40px kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()">clear </kenjo-icon>
  </orgos-column-container>
  <orgos-column-container>
    <orgos-column size="1" class="kenjo-font-size-10px">{{dialogTranslation.warningMessage | i18nData: userPersonal }}</orgos-column>
  </orgos-column-container>

  <orgos-warning-message *ngIf="overtimeActive === true" class="kenjo-mt-15px"
    >{{dialogTranslation.overtimeActiveWarningMessageTitle}} <br />
    {{dialogTranslation.overtimeActiveWarningMessageSubtitle}}</orgos-warning-message
  >

  <orgos-column-container class="kenjo-mt-20px" centerColumns="true">
    <orgos-column class="kenjo-mr-10px">
      <!-- {{ workSchedule | json }} -->
      <orgos-input-toggle-round-simple [(value)]="workSchedule.mondayWorkingDay" [label]="translatedWeekdays[0]" [readOnly]="!(canEditWorkSchedule | async)" class="kenjo-mr-10px"></orgos-input-toggle-round-simple>
      <orgos-input-toggle-round-simple [(value)]="workSchedule.tuesdayWorkingDay" [label]="translatedWeekdays[1]" [readOnly]="!(canEditWorkSchedule | async)" class="kenjo-mr-10px"></orgos-input-toggle-round-simple>
      <orgos-input-toggle-round-simple [(value)]="workSchedule.wednesdayWorkingDay" [label]="translatedWeekdays[2]" [readOnly]="!(canEditWorkSchedule | async)" class="kenjo-mr-10px"></orgos-input-toggle-round-simple>
      <orgos-input-toggle-round-simple [(value)]="workSchedule.thursdayWorkingDay" [label]="translatedWeekdays[3]" [readOnly]="!(canEditWorkSchedule | async)" class="kenjo-mr-10px"></orgos-input-toggle-round-simple>
      <orgos-input-toggle-round-simple [(value)]="workSchedule.fridayWorkingDay" [label]="translatedWeekdays[4]" [readOnly]="!(canEditWorkSchedule | async)" class="kenjo-mr-10px"></orgos-input-toggle-round-simple>
      <orgos-input-toggle-round-simple [(value)]="workSchedule.saturdayWorkingDay" [label]="translatedWeekdays[5]" [readOnly]="!(canEditWorkSchedule | async)" class="kenjo-mr-10px"></orgos-input-toggle-round-simple>
      <orgos-input-toggle-round-simple [(value)]="workSchedule.sundayWorkingDay" [label]="translatedWeekdays[6]" [readOnly]="!(canEditWorkSchedule | async)"></orgos-input-toggle-round-simple>
    </orgos-column>
    <orgos-input-simple-number
      *ngIf="company"
      orgosColumn
      step="0.01"
      [value]="tempWeeklyHours"
      [min]="1"
      [max]="company.weeklyHours"
      (valueChange)="tempWeeklyHours = $event;"
      [suffix]="'/' + company.weeklyHours + '(' + (tempWeeklyHours ? (tempWeeklyHours / company.weeklyHours | percent: '1.0-2') : '' ) + ')'"
      (validation)="weeklyHoursValidation = $event"
      class="kenjo-mr-10px"
      [label]="dialogTranslation.weeklyHours"
      [readOnly]="!(canEditUserWork | async)"
      [required]="true"
    >
      <orgos-input-error *ngIf="weeklyHoursValidation && weeklyHoursValidation.hasErrors()">{{dialogTranslation.weeklyHoursNoValidValue | i18nData: {min: 1, max: company.weeklyHours} }}</orgos-input-error>
    </orgos-input-simple-number>
  </orgos-column-container>
  <orgos-column-container centerColumns="true" class="kenjo-mt-50px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" class="kenjo-mr-20px" (click)="closeDialog()">{{dialogTranslation.cancelButton}}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Success" (click)="save()" [disabled]="weeklyHoursValidation && weeklyHoursValidation.hasErrors()">{{dialogTranslation.saveButton}}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</div>
