<orgos-dialog-container class="dialog-box" [title]="translation.title + ' ' + (isBulk ? translation.bulk : '')"
  (clickCloseButton)="closeDialog()">
  <orgos-column-container *ngIf="!isBulk">
    <orgos-column class="kenjo-pr-20px">
      <orgos-text class="kenjo-font-color-light-text-757575">{{translation.currentBalance}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
    <orgos-column>
      <orgos-text class="kenjo-font-color-light-text-757575">{{currentBalance | duration : true}}</orgos-text>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <orgos-text>{{translation.newBalance}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
    <orgos-column>
      <orgos-input-simple-number [step]="1" [min]="0" [max]="MAX_HOURS_VALUE" class="kenjo-block"
        [(value)]="newBalance.hours" [changeValueOnBlur]="true" (blurInput)="parseBalanceHours()"
        [suffix]="translation.hoursUnits" class="oab-small-picker kenjo-mr-20px"
        avoidNegativeSymbol=true></orgos-input-simple-number>
      <orgos-input-simple-number [step]="1" [min]="0" [max]="MAX_MINUTES_VALUE" class="kenjo-block"
        [(value)]="newBalance.minutes" [changeValueOnBlur]="true" (blurInput)="parseBalanceMinutes()"
        [suffix]="translation.minutesUnits" class="oab-small-picker"
        avoidNegativeSymbol=true></orgos-input-simple-number>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1"></orgos-column>
    <orgos-input-simple-checkbox orgosColumn="1" [(value)]="activateNegativeBalance"
      [label]="translation.negativeBalanceCheckboxLabel" class="kenjo-ml-20px"></orgos-input-simple-checkbox>
  </orgos-column-container>

  <kenjo-info-message class="kenjo-mv-20px" messageType="info" [messageText]="translation.adjustInfoMessage | i18nData: { balance: ((newBalance.hours + newBalance.minutes > 0) &&
    activateNegativeBalance === true ? '-' : '') +
    newBalance.hours + 'h ' +
    newBalance.minutes +
    'min', today: dateToApplyAdjustment}">
  </kenjo-info-message>

  <orgos-column-container>
    <orgos-column>
      <orgos-input-simple-text-area class="kenjo-block" [(value)]="comments"
        [label]="translation.comment"></orgos-input-simple-text-area>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
    <orgos-column class="kenjo-pr-20px" size="0">
      <orgos-text>{{isBulk? translation.selectedUsers : translation.trackedHistory}}</orgos-text>
    </orgos-column>
    <orgos-column size="0">
      <orgos-action (click)="seeHistory = false" *ngIf="seeHistory">
        <kenjo-icon>keyboard_arrow_up</kenjo-icon>
      </orgos-action>
      <orgos-action (click)="seeHistory = true" *ngIf="!seeHistory">
        <kenjo-icon>keyboard_arrow_down</kenjo-icon>
      </orgos-action>
    </orgos-column>
    <orgos-column></orgos-column>
  </orgos-column-container>

  <div *ngIf="seeHistory">
    <orgos-table *ngIf="isBulk && userIds && userIds.length > 0" class="kenjo-mt-10px aws-table" [data]="userIds"
      [totalColumns]="['name', 'overtime']">
      <orgos-table-column columnKey="name" [header]="translation.name | uppercase">
        <ng-container *orgosTableCell="let row">
          <orgos-column-container centerColumns="true">
            <orgos-avatar orgosColumn="0" [avatarName]="userPersonal[row].displayName"
              [photoUrl]="userPersonal[row]._photo?._url" size="tiny" class="kenjo-pr-5px"> </orgos-avatar>
            <kenjo-truncate-text orgosColumn="1">{{userPersonal[row].displayName}}</kenjo-truncate-text>
          </orgos-column-container>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="overtime" [header]="translation.overtime | uppercase">
        <ng-container *orgosTableCell="let row"> {{balances[row] >0? '+': ''}} {{balances[row] | duration}}
        </ng-container>
      </orgos-table-column>
    </orgos-table>

    <orgos-table *ngIf="!isBulk && overtimeHistory && overtimeHistory.length > 0"
      class="kenjo-mv-20px kenjo-border kenjo-border-radius" [data]="overtimeHistory"
      [totalColumns]="['date', 'overtime']">
      <orgos-table-column columnKey="date" [header]="translation.date | uppercase">
        <ng-container *orgosTableCell="let row"> {{row.date | date: 'fullDate' }} </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="overtime" [header]="translation.overtime | uppercase">
        <ng-container *orgosTableCell="let row"> {{row.totalOvertime | duration : true}} </ng-container>
      </orgos-table-column>
    </orgos-table>
  </div>

  <orgos-column-container *ngIf="!isBulk" centerColumns="true" class="kenjo-pt-20px">
    <orgos-column class="kenjo-pr-20px" size="0">
      <orgos-text>{{translation.overtimeHistory}}</orgos-text>
    </orgos-column>
    <orgos-column size="0">
      <orgos-action (click)="seeOvertimeHistory = false" *ngIf="seeOvertimeHistory">
        <kenjo-icon>keyboard_arrow_up</kenjo-icon>
      </orgos-action>
      <orgos-action (click)="seeOvertimeHistory = true" *ngIf="!seeOvertimeHistory">
        <kenjo-icon>keyboard_arrow_down</kenjo-icon>
      </orgos-action>
    </orgos-column>
    <orgos-column></orgos-column>
  </orgos-column-container>

  <div *ngIf="!isBulk && seeOvertimeHistory"
    class="kenjo-pv-10px kenjo-ph-20px kenjo-mv-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
    <orgos-column-container centerColumns="true" *ngFor="let entry of history" class="kenjo-mb-20px">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-avatar orgosColumn="0" [avatarName]="userPersonal[entry.createdBy].displayName"
          [photoUrl]="userPersonal[entry.createdBy]._photo?._url" size="tiny" class="kenjo-pr-5px"> </orgos-avatar>
      </orgos-column>
      <orgos-column>
        {{entry.detail}}
        <div class="dialog-inline kenjo-font-color-light-text-757575">{{entry.when | date: 'short'}}</div>
      </orgos-column>
    </orgos-column-container>
    <orgos-text *ngIf="!history || history.length < 1">{{translation.noData}}</orgos-text>
  </div>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{translation.cancel}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="save()" color="Success"
        [disabled]="saving || newBalance.hours === null || newBalance.minutes === null"> {{translation.save}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>