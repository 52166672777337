<ng-template [ngIf]="!readOnly">
  <label [for]="label">{{ label }}<span *ngIf="required">*</span></label>
  <mat-radio-group [ngModel]="value" (ngModelChange)="!disabled && valueChange.emit($event)" [name]="label ? label : name" [required]="required" [class.inline]="inline">
    <mat-radio-button *ngFor="let option of options" [value]="option.value" [class.mrb-full-width]="width === undefined && !inline">
      <kenjo-truncate-text class="isr-radio-label" *ngIf="!inline" [style.min-width.px]="width">
        {{ option.name }}
      </kenjo-truncate-text>
      <div *ngIf="inline" class="isr-radio-label" [style.min-width.px]="width">
        {{ option.name }}
      </div>
    </mat-radio-button>
  </mat-radio-group>
</ng-template>

<ng-template [ngIf]="readOnly">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input matInput [ngModel]="getValueName()" readOnly disabled [required]="required" />
  </mat-form-field>
</ng-template>
