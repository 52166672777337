import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IHolidayModel } from '@app/standard/services/company/calendar.service';

@Component({
  selector: 'kenjo-attendance-bank-holidays-dialog',
  templateUrl: 'attendance-bank-holidays.dialog.html',
  styleUrls: ['attendance-bank-holidays.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttendanceBankHolidaysDialog implements OnInit {
  @Input() bankHolidays: IHolidayModel;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  translations: Record<string, string>;

  constructor(private injector: Injector) {
  }

  ngOnInit() {
    this.getTranslations();
  }

  private async getTranslations(): Promise<void> {
    this.translations = await this.injector.get(InternationalizationService).getAllTranslation('people-detail-attendance-page');
    this.injector.get(ChangeDetectorRef).markForCheck();
  }
}
