import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class FieldLevelPermissionsService {
  private FIELD_LEVEL_PERMISSIONS_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/field-level-permissions-db`;

  constructor(private injector: Injector) {}

  async getByCollection(collection: string): Promise<IFieldLevelPermissionsResponse> {
    try {
      return await this.injector.get(HttpClient).get<IFieldLevelPermissionsResponse>(`${this.FIELD_LEVEL_PERMISSIONS_MICROSERVICE_URL}/findByCollection/${collection}`).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FieldLevelPermissionsService.name, 'getByCollection');
    }
  }
}

interface IFieldLevelPermissionsResponse {
  _id: string;
  [key: string]: IFieldsCollections | string;
}

interface IFieldsCollections {
  [key: string]: IPermissions;
}

interface IPermissions {
  read: boolean;
  update: boolean;
}
