import { Component, Input } from '@angular/core';
import * as check from 'check-types';

import { InputValidation } from '../../core/validation/input-validation';
import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-number',
  templateUrl: 'input-number.component.html',
  styleUrls: ['input-number.component.scss']
})
export class InputNumberComponent extends InputAbstractComponent {
  private _step: number = 1;
  @Input()
  set step(step: number) {
    this._step = Math.abs(step);
  }
  get step(): number {
    return this._step;
  }

  @Input() keyboardOnly: boolean = false;
  _allowedKeys = ['ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab'];


  protected validateValue(newValue: any): InputValidation {
    const inputValidation = super.validateValue(newValue);

    const acceptOnlyIntegers = check.integer(this.step);

    let absModulo = 0;
    if (acceptOnlyIntegers === false) {
      const tempAbsModulo = Math.round(newValue * Math.pow(10, this.decimalPlaces(this.step))) % Math.round(this.step * Math.pow(10, this.decimalPlaces(this.step)));
      absModulo = parseFloat(Math.abs(tempAbsModulo).toFixed(this.decimalPlaces(this.step)));
    }

    if (check.assigned(newValue) && check.not.emptyString(newValue) && acceptOnlyIntegers === true && check.not.integer(newValue)) {
      inputValidation.setError('integer');
    } else if (check.assigned(newValue) && check.not.emptyString(newValue) && acceptOnlyIntegers === false && (this.decimalPlaces(newValue) > this.decimalPlaces(this.step) || absModulo > 0)) {
      inputValidation.setError('float');
    }

    if (check.assigned(newValue) && check.not.emptyString(newValue) && newValue < this.min) {
      inputValidation.setError('min');
    } else if (check.assigned(newValue) && check.not.emptyString(newValue) && newValue > this.max) {
      inputValidation.setError('max');
    }

    return inputValidation;
  }

  private decimalPlaces(newValue: any): number {
    const match = `${newValue}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

    if (check.not.assigned(match)) {
      return 0;
    }

    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0)
    );
  }

  public checkKey(keyCode: string): boolean {
    if (!this._allowedKeys.includes(keyCode)) {
      return false;
    }
  }
}
