import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { GenericService } from '@app/standard/services/generic.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable()
export class DocumentService {
  private DOCUMENT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/document-db`;
  private COMPANY_DOCS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/smart-docs/company-docs`;
  private DOCUMENT_PERMISSIONS_KEY: string = 'document';
  private DOCUMENT_INTERNATIONALIZATION: string = 'document-collection';
  private allDocumentsCache: Array<IDocumentModel> | undefined;

  constructor(
    private http: HttpClient,
    private genericService: GenericService,
    private authenticationService: AuthenticationService,
    private errorManager: ErrorManagerService,
    private injector: Injector
  ) {}

  async create(data: object): Promise<IDocumentModel> {
    try {
      const document = await this.genericService.create(this.DOCUMENT_URL, data);
      this.invalidateAllDocumentsCache();
      return document;
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'create');
    }
  }

  async getById(id: string): Promise<IDocumentModel> {
    try {
      const document = await this.genericService.getById(this.DOCUMENT_URL, id);
      return document;
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'getById');
    }
  }

  async findWithPendingSignature(id: string): Promise<IDocumentModel | {}> {
    try {
      const httpOptions = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', this.authenticationService.getAuthorizationHeader()),
      };

      const document = await this.http.post(`${this.DOCUMENT_URL}/find-with-pending-signature`, { id }, httpOptions).toPromise();
      if (check.not.assigned(document)) {
        return {};
      }
      return document;
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'findWithPendingSignature');
    }
  }

  async find(findBody: any): Promise<Array<IDocumentModel>> {
    try {
      if (findBody?._id?.$ne === null && this.allDocumentsCache !== undefined) {
        return this.allDocumentsCache;
      }

      const documents = await this.genericService.find(this.DOCUMENT_URL, findBody);

      if (findBody?._id?.$ne === null) {
        this.allDocumentsCache = documents;
      }

      return documents;
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'find');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.DOCUMENT_URL, id, data);
      this.invalidateAllDocumentsCache();
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.DOCUMENT_URL, id);
      this.invalidateAllDocumentsCache();
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'deleteById');
    }
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.DOCUMENT_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.DOCUMENT_INTERNATIONALIZATION);
  }

  async getMyDocs(preferenceKey: string, queryOptions: any): Promise<Array<IDocumentModel>> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_URL}/find`, ErrorCodes.UNAUTHORIZED, DocumentService.name, 'getMyDocs');
        throw this.errorManager.handleRawError(error);
      }

      const findBody = {
        'relatedTo.typeRelatedTo': 'User',
        'relatedTo.idRelatedTo': this.authenticationService.getLoggedUser()._id,
      };

      if (check.assigned(queryOptions.document?.where)) {
        findBody['tags'] = queryOptions.document.where.tags;
        findBody['_file._fileExtension'] = queryOptions.document.where['_file._fileExtension'];
      }

      findBody['name'] = queryOptions?.search;

      const promisesToResolve = [
        this.genericService.find(this.DOCUMENT_URL, findBody),
        this.injector.get(PreferenceService).setPreferenceByKey(preferenceKey, queryOptions),
      ];

      const [documents] = await Promise.all(promisesToResolve);

      return documents;
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'getMyDocs');
    }
  }

  getCompanyDocs(): Promise<Array<IDocumentModel>> {
    return new Promise<Array<IDocumentModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_URL}/company-docs`, ErrorCodes.UNAUTHORIZED, DocumentService.name, 'getCompanyDocs');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .get(`${this.DOCUMENT_URL}/company-docs`, httpOptions)
        .toPromise()
        .then((companyDocs: Array<IDocumentModel>) => {
          resolve(companyDocs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'getCompanyDocs'));
        });
    });
  }

  getEmployeeDocs(employeeId: string): Promise<Array<IDocumentModel>> {
    return new Promise<Array<IDocumentModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_URL}/find`, ErrorCodes.UNAUTHORIZED, DocumentService.name, 'getEmployeeDocs');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const findBody = {
        'relatedTo.typeRelatedTo': 'User',
        'relatedTo.idRelatedTo': employeeId,
      };

      this.genericService
        .find(this.DOCUMENT_URL, findBody)
        .then((documents: Array<IDocumentModel>) => {
          resolve(documents);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'getEmployeeDocs'));
        });
    });
  }

  getDocsByTag(tagId: string): Promise<Array<IDocumentModel>> {
    return new Promise<Array<IDocumentModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_URL}/find`, ErrorCodes.UNAUTHORIZED, DocumentService.name, 'getDocsByTag');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const findBody = {
        tags: tagId,
      };

      this.genericService
        .find(this.DOCUMENT_URL, findBody)
        .then((documents: Array<IDocumentModel>) => {
          resolve(documents);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'getDocsByTag'));
        });
    });
  }

  async requestRead(document: IDocumentModel): Promise<void> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_URL}/request-read`, ErrorCodes.UNAUTHORIZED, DocumentService.name, 'requestRead');
        throw this.errorManager.handleRawError(error);
      }

      if (document.relatedTo.typeRelatedTo !== 'User') {
        const error = new OrgosError(
          `The document (${document._id}) cannot be requested to be read`,
          ErrorCodes.CLIENT_ERROR,
          DocumentService.name,
          'requestRead'
        );
        throw this.errorManager.handleRawError(error);
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      const requestReadQuery = {
        documentId: document._id,
        documentName: document.name,
        relatedToId: document.relatedTo.idRelatedTo,
      };

      await this.http.post(`${this.DOCUMENT_URL}/request-read`, requestReadQuery, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'requestRead');
    }
  }

  async getReadRequestRecipients(document: IDocumentModel): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.get(`${this.COMPANY_DOCS_URL}/visible-to-whom/${document._id}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'getReadRequestRecipients');
    }
  }

  confirmRead(documentId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_URL}/confirm-read`, ErrorCodes.UNAUTHORIZED, DocumentService.name, 'confirmRead');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      const confirmReadQuery = {
        documentId: documentId,
      };

      this.http
        .post(`${this.DOCUMENT_URL}/confirm-read`, confirmReadQuery, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'confirmRead'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.DOCUMENT_URL);
  }

  //To avoid automatic notifications
  createInBulk(data: Array<any>): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      this.http
        .post(`${this.DOCUMENT_URL}/create-in-bulk`, data, httpOptions)
        .toPromise()
        .then((createdData: any) => {
          this.invalidateAllDocumentsCache();
          resolve(createdData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'createInBulk'));
        });
    });
  }

  // To send automatic notifications
  bulkCreate(data: Array<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      this.http
        .post(`${this.DOCUMENT_URL}/bulk-create`, { documents: data }, httpOptions)
        .toPromise()
        .then((documents) => {
          this.invalidateAllDocumentsCache();
          resolve(documents);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'bulkCreate'));
        });
    });
  }

  getPayrollDocs(findBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DOCUMENT_URL}/payroll-docs`, ErrorCodes.UNAUTHORIZED, DocumentService.name, 'getPayrollDocs');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .post(`${this.DOCUMENT_URL}/payroll-docs`, findBody, httpOptions)
        .toPromise()
        .then((payrollDocs) => {
          resolve(payrollDocs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'getPayrollDocs'));
        });
    });
  }

  createComment(documentId: string, comment: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, DocumentService.name, 'createComment');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .post(`${this.DOCUMENT_URL}/${documentId}/create-comment`, comment, httpOptions)
        .toPromise()
        .then((result: any) => {
          this.invalidateAllDocumentsCache();
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'createComment'));
        });
    });
  }

  updateComment(documentId: string, commentInfo: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, DocumentService.name, 'createComment');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };
      this.http
        .post(`${this.DOCUMENT_URL}/${documentId}/update-comment`, commentInfo, httpOptions)
        .toPromise()
        .then((result: any) => {
          this.invalidateAllDocumentsCache();
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, DocumentService.name, 'createComment'));
        });
    });
  }

  public async deleteInBulk(data: Array<string>): Promise<void> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.authenticationService.getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      await this.http.post(`${this.DOCUMENT_URL}/bulk-delete`, { documents: data }, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, DocumentService.name, 'deleteInBulk');
    }
  }

  private invalidateAllDocumentsCache() {
    this.allDocumentsCache = undefined;
  }
}

export interface IDocumentModel {
  processedTooltip?: any[];
  collapsedTagIds?: any;
  _id?: string;
  _file?: IFileMetadata;
  name?: string;
  relatedTo?: { typeRelatedTo: 'User' | 'Company'; idRelatedTo: any };
  ownerId?: string;
  tags?: Array<string>;
  _createdById?: string;
  _createdAt?: Date;
  _updatedAt?: Date;
  validUntil?: Date;
  readBy?: Array<IReadBy>;
  lastReadRequestAt?: Date;
  managed?: boolean;
  hidden?: boolean;
  comments?: { comment: any; _createdById: Date; _createdAt: Date };
  digitalSigned?: boolean;
}

export interface ISignatureRequest {
  _id?: string;
  signatureRequestId: string;
  signatureUrl: string;
  signatures: Array<ISignature>;
}

export interface ISignature {
  _id?: string;
  signerId: string;
  status: string;
}

export interface IDocumentCommentModel {
  _id?: string;
  comment: any;
}

export interface IReadBy {
  userId: string;
  readDate: Date;
}
