<div class="dialog-box" *ngIf="variablePay">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <!-- TITLE -->
  <div *ngIf="!variablePay.data._id" class="kenjo-text-align-center kenjo-font-size-30px">
    {{dialogTranslation.dialogNameAdd}}
  </div>
  <div *ngIf="variablePay.data._id" class="kenjo-text-align-center kenjo-font-size-30px">
    {{dialogTranslation.dialogNameEdit}}
  </div>

  <!-- SUBTITLE -->
  <div *ngIf="wizardStep === 1" class="kenjo-text-align-center kenjo-font-size-16px kenjo-mt-10px">
    {{dialogTranslation.subtitleStep1}}
  </div>
  <div *ngIf="wizardStep === 2 && variablePay.data.everyMonths === false" class="kenjo-text-align-center kenjo-font-size-16px kenjo-mt-10px">
    {{dialogTranslation.subtitleStep21}}
  </div>
  <div *ngIf="wizardStep === 2 && variablePay.data.everyMonths === true" class="kenjo-text-align-center kenjo-font-size-16px kenjo-mt-10px">
    {{dialogTranslation.subtitleStep22}}
  </div>

  <!-- CONTENT -->
  <div class="kenjo-ph-60px kenjo-pt-20px wcss-content">
    <!-- STEP 1 - VARIABLE PAY TYPE -->
    <orgos-column-container *ngIf="wizardStep === STEP_TYPE_SELECTION" centerColumns="true" class="kenjo-pt-40px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-column-container centerColumns="true" class="kenjo-pt-20px">
          <orgos-column size="0" class="kenjo-text-align-center kenjo-ph-40px kenjo-pv-20px">
            <div class="wcss-option" [class.selected-option]="variablePay.data.everyMonths === false" (click)="variablePay.data.everyMonths = false">
              <div class="wcss-option-icon">
                <mat-icon>event</mat-icon>
              </div>
            </div>
            <div class="kenjo-pv-20px wcss-option-text">
              <orgos-text [color]="variablePay.data.everyMonths === false? 'Primary': 'NormalText'"> {{dialogTranslation.once}}</orgos-text>
            </div>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="kenjo-text-align-center kenjo-ph-40px kenjo-pv-20px">
            <div class="wcss-option" [class.selected-option]="variablePay.data.everyMonths === true" (click)="variablePay.data.everyMonths = true">
              <div class="wcss-option-icon">
                <mat-icon>recurrent</mat-icon>
              </div>
            </div>
            <div class="kenjo-pv-20px wcss-option-text">
              <orgos-text [color]="variablePay.data.everyMonths === true? 'Primary': 'NormalText'"> {{dialogTranslation.recurrent}}</orgos-text>
            </div>
          </orgos-column>
        </orgos-column-container>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>

    <!-- STEP 2 - VARIABLE PAY - ONCE -->
    <ng-container *ngIf="wizardStep === STEP_DETAILS && variablePay.data.everyMonths === false">
      <orgos-column-container>
        <orgos-input-select orgosColumn [readOnly]="!!variablePay.data._id" [model]="variablePay" field="_companyId" [options]="companyOptions" [required]="true"></orgos-input-select>
      </orgos-column-container>
      <orgos-column-container>
        <orgos-input-select orgosColumn [model]="variablePay" field="type" [options]="variablePayTypes" [required]="true" (validation)="typeValidation = $event" class="kenjo-mr-10px">
          <orgos-input-error *ngIf="typeValidation && typeValidation.getError('required')"> {{dialogTranslation.typeRequired}}</orgos-input-error>
        </orgos-input-select>
        <orgos-input-date-picker orgosColumn [model]="variablePay" [min]="minDate" field="effectiveDate" [required]="true" (validation)="effectiveDateValidation = $event" class="kenjo-ml-10px">
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('required')"> {{dialogTranslation.effectiveDateRequired}}</orgos-input-error>
          <orgos-input-error *ngIf="effectiveDateValidation && !effectiveDateValidation.getError('required') && effectiveDateValidation.getError('date')"> {{dialogTranslation.effectiveDateNoValidFormat}}</orgos-input-error>
        </orgos-input-date-picker>
      </orgos-column-container>
      <orgos-column-container *ngIf="!canEditPast">
        <orgos-column></orgos-column>
        <orgos-column class="kenjo-ml-10px">
          <orgos-column-container centerColumns="true">
            <orgos-column size="0" class="kenjo-font-color-success-00b72e kenjo-font-size-18px kenjo-mr-5px">
              <mat-icon>info_outline</mat-icon>
            </orgos-column>
            <orgos-column>{{miscTranslation.payrollMinDate}}</orgos-column>
          </orgos-column-container>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-20px">
        <orgos-input-number orgosColumn [model]="variablePay" field="amount" step="0.01" [required]="true" (validation)="amountValidation = $event" class="kenjo-mr-10px">
          <orgos-input-error *ngIf="amountValidation && amountValidation.getError('required')"> {{dialogTranslation.amountRequired}}</orgos-input-error>
          <orgos-input-error *ngIf="amountValidation && !amountValidation.getError('required') && amountValidation.getError('float')"> {{dialogTranslation.amountNoValidFormat}}</orgos-input-error>
        </orgos-input-number>
        <orgos-input-select orgosColumn [model]="variablePay" [sortOptions]="false" field="currency" picklist="currency" [required]="true" (validation)="currencyValidation = $event" class="kenjo-ml-10px">
          <orgos-input-error *ngIf="currencyValidation && currencyValidation.getError('required')"> {{dialogTranslation.currencyRequired}}</orgos-input-error>
        </orgos-input-select>
      </orgos-column-container>
      <orgos-input-text-area [model]="variablePay" field="comments" class="kenjo-mt-40px"></orgos-input-text-area>
    </ng-container>

    <!-- STEP 2 - VARIABLE PAY - RECURRENT -->
    <ng-container *ngIf="wizardStep === STEP_DETAILS && variablePay.data.everyMonths === true">
      <orgos-column-container>
        <orgos-input-select orgosColumn [readOnly]="!!variablePay.data._id" [model]="variablePay" field="_companyId" [options]="companyOptions" [required]="true"></orgos-input-select>
      </orgos-column-container>
      <orgos-column-container>
        <orgos-input-select orgosColumn [model]="variablePay" field="type" [options]="variablePayTypes" [required]="true" (validation)="typeValidation = $event" class="kenjo-mr-10px">
          <orgos-input-error *ngIf="typeValidation && typeValidation.getError('required')"> {{dialogTranslation.typeRequired}}</orgos-input-error>
        </orgos-input-select>
        <orgos-input-date-picker orgosColumn [label]="dialogTranslation.startDate" [model]="variablePay" [min]="minDate" [max]="variablePay.data.endDate? variablePay.data.endDate: null" field="effectiveDate" [required]="true" (validation)="effectiveDateValidation = $event" class="wcss-one-field">
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('required')"> {{dialogTranslation.startDateRequired}}</orgos-input-error>
          <orgos-input-error *ngIf="effectiveDateValidation && !effectiveDateValidation.getError('required') && effectiveDateValidation.getError('date')"> {{dialogTranslation.startDateNoValidFormat}}</orgos-input-error>
        </orgos-input-date-picker>
      </orgos-column-container>
      <orgos-column-container *ngIf="!canEditPast">
        <orgos-column></orgos-column>
        <orgos-column class="kenjo-ml-10px">
          <orgos-column-container centerColumns="true">
            <orgos-column size="0" class="kenjo-font-color-success-00b72e kenjo-font-size-18px kenjo-mr-5px">
              <mat-icon>info_outline</mat-icon>
            </orgos-column>
            <orgos-column>{{miscTranslation.payrollMinDate}}</orgos-column>
          </orgos-column-container>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-20px">
        <orgos-input-date-picker orgosColumn [model]="variablePay" [min]="variablePay.data.effectiveDate? variablePay.data.effectiveDate: minDate" field="endDate" (validation)="endDateValidation = $event" class="wcss-one-field" [enableClearButton]="true">
          <orgos-input-error *ngIf="endDateValidation && endDateValidation.getError('date')"> {{dialogTranslation.endDateNoValidFormat}}</orgos-input-error>
        </orgos-input-date-picker>
        <orgos-input-number orgosColumn [model]="variablePay" [label]="dialogTranslation.every" field="frequency" step="1" [min]="1" [required]="true" (validation)="frequencyValidation = $event" class="kenjo-ml-10px" [suffix]="dialogTranslation.month">
          <orgos-input-error *ngIf="frequencyValidation && frequencyValidation.getError('required')"> {{dialogTranslation.frequencyRequired}}</orgos-input-error>
          <orgos-input-error *ngIf="frequencyValidation && !frequencyValidation.getError('required') && frequencyValidation.getError('integer')"> {{dialogTranslation.frequencyNoValidFormat}}</orgos-input-error>
          <orgos-input-error *ngIf="frequencyValidation && !frequencyValidation.getError('required') && !frequencyValidation.getError('integer') && frequencyValidation.getError('min')"> {{dialogTranslation.frequencyMinValue}}</orgos-input-error>
        </orgos-input-number>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-20px">
        <orgos-input-number orgosColumn [model]="variablePay" field="amount" step="0.01" [required]="true" (validation)="amountValidation = $event" class="kenjo-mr-10px">
          <orgos-input-error *ngIf="amountValidation && amountValidation.getError('required')"> {{dialogTranslation.amountRequired}}</orgos-input-error>
          <orgos-input-error *ngIf="amountValidation && !amountValidation.getError('required') && amountValidation.getError('float')"> {{dialogTranslation.amountNoValidFormat}}</orgos-input-error>
        </orgos-input-number>
        <orgos-input-select orgosColumn [model]="variablePay" [sortOptions]="false" field="currency" picklist="currency" [required]="true" (validation)="currencyValidation = $event" class="kenjo-ml-10px">
          <orgos-input-error *ngIf="currencyValidation && currencyValidation.getError('required')"> {{dialogTranslation.currencyRequired}}</orgos-input-error>
        </orgos-input-select>
      </orgos-column-container>
      <orgos-input-text-area [model]="variablePay" field="comments" class="kenjo-mt-40px"></orgos-input-text-area>
    </ng-container>
  </div>
  <!-- BUTTONS -->
  <orgos-column-container centerColumns="true" class="kenjo-pv-30px kenjo-mh-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="kenjo-text-align-center">
          <div>
            <kenjo-icon [size]="30" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="leftButtonAction()"> {{wizardStep > 1 ? 'arrow_back_circle' : 'cancel_icon'}}</kenjo-icon>
          </div>
          <div>
            <div class="kenjo-font-size-12px" *ngIf="wizardStep === 1">{{dialogTranslation.cancelBtn}}</div>
          </div>
          <div>
            <div class="kenjo-font-size-12px" *ngIf="wizardStep > 1">{{dialogTranslation.backBtn}}</div>
          </div>
        </orgos-column>
        <orgos-column size="1" class="kenjo-text-align-center">
          <div>
            <kenjo-icon [size]="30" [class.kenjo-cursor-pointer]="isValidFormData()" [class.kenjo-font-color-light-text-757575]="isValidFormData()" [class.kenjo-font-color-disabled-c4c4c4]="!isValidFormData()" (click)="rightButtonAction()"> arrow_next_circle</kenjo-icon>
          </div>
          <div>
            <div class="kenjo-font-size-12px" [class.kenjo-font-color-disabled-c4c4c4]="!isValidFormData()" *ngIf="!(wizardStep === WIZARD_FINAL_STEP)">
              {{dialogTranslation.nextBtn}}
            </div>
          </div>
          <div>
            <div class="kenjo-font-size-12px" [class.kenjo-font-color-disabled-c4c4c4]="!isValidFormData()" *ngIf="wizardStep === WIZARD_FINAL_STEP">
              {{variablePay.data._id ? dialogTranslation.saveBtn : dialogTranslation.createBtn}}
            </div>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
