import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as Chart from 'chart.js';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { I18nDataPipe } from '../../../standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '../../services/core/internationalization.service';
import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-report-stacked',
  templateUrl: 'report-stacked.component.html',
  styleUrls: ['report-stacked.component.scss']
})
export class ReportStackedComponent implements OnInit {
  MONTH_NAMES: Array<string> = [];
  MAX_GROUPS_IN_REPORT: number = 25;
  @ViewChild('chartElement', { static: true }) chartElement: ElementRef;

  @Input() template: any;
  @Input() reportDataToDisplay: Array<any>; // Array of object with the fields that will be displayed
  @Input() reportGrouping: any;
  @Input() translationMatrix: any = {};
  @Input() pageTranslation: any = {}; // reports-page.json
  @Input() idToName: any = {};
  @Input() groupTranslation: string;
  @Input() summaryTranslation: string;

  displayedColumns: Array<string> = [];
  stackedDataForDataSource: Array<any> = [];
  mapStackToDataSet: any = {};
  emptyLabel: string = '';
  maxNumberOfGroups: number = 0;

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    this.MONTH_NAMES = moment.months();
    this.drawChart();
    this.sortTable();
  }

  drawChart(): void {
    this.emptyLabel =
      check.assigned(this.template.metadata) && check.assigned(this.template.metadata.grouping) && check.assigned(this.template.metadata.grouping.emptyGroupLevel2Label) && check.nonEmptyString(this.template.metadata.grouping.emptyGroupLevel2Label)
        ? this.template.metadata.grouping.emptyGroupLevel2Label
        : '';
    if (check.assigned(this.pageTranslation[this.emptyLabel])) {
      this.emptyLabel = this.pageTranslation[this.emptyLabel];
    }
    const secondRowForDataset = [];

    // Build the x axis
    const xAxis = [];
    const arrayLength = Object.keys(this.reportGrouping).length;
    Object.keys(this.reportGrouping).forEach((xElement, index) => {
      xAxis.push(xElement);

      Object.keys(this.reportGrouping[xElement]).forEach((secondRowKey) => {
        let translatedSecondRowKey = this.idToName[secondRowKey] ? this.idToName[secondRowKey] : secondRowKey;
        if (check.assigned(this.pageTranslation[secondRowKey])) {
          translatedSecondRowKey = this.pageTranslation[secondRowKey];
        }
        if (check.not.assigned(this.mapStackToDataSet[translatedSecondRowKey])) {
          this.mapStackToDataSet[translatedSecondRowKey] = this.initArrayForSecondRow(arrayLength);
        }

        const totalVal = this.reportGrouping[xElement][secondRowKey]['total'];
        this.mapStackToDataSet[translatedSecondRowKey][index] += totalVal;
      });
    });

    const chartCtx = this.chartElement.nativeElement.getContext('2d');

    const groupConditions = this.template['metadata']['grouping']['groupConditions'];
    const labels = Object.keys(this.reportGrouping).map((iLabel) => {
      return this.getGroupNameForLabel(iLabel, groupConditions);
    });
    this.displayedColumns = [' '].concat(labels);

    // Build the data needed for the table:
    this.stackedDataForDataSource = [];
    Object.keys(this.mapStackToDataSet).forEach((iKey) => {
      const iLabel = check.emptyString(iKey) ? this.emptyLabel : iKey;
      const iDatasetElement = this.buildNewStackRowTable(xAxis, this.mapStackToDataSet[iKey], iKey, groupConditions);
      this.stackedDataForDataSource.push(iDatasetElement);
    });

    const datasets = [];
    Object.keys(this.mapStackToDataSet).forEach((iStackName, index) => {
      const iColor = this.getUserColor(index);
      const iDataset = {
        label: check.emptyString(iStackName) ? this.emptyLabel : iStackName,
        data: this.mapStackToDataSet[iStackName],
        backgroundColor: iColor,
        borderColor: iColor,
        hoverBorderWidth: 2
      };
      datasets.push(iDataset);
    });

    this.maxNumberOfGroups = datasets.length;
    if (this.maxNumberOfGroups > this.MAX_GROUPS_IN_REPORT) {
      return;
    }

    const chart = new Chart(chartCtx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: this.getSummaryTranslation()
              }
            }
          ]
        },
        animation: {
          duration: 500,
          easing: 'linear'
        }
      }
    });
  }

  private getGroupNameForLabel(labelValue: string, groupConditions: string): string {
    if (check.emptyString(labelValue)) {
      return this.pageTranslation.emptyValue;
    }
    if (check.assigned(this.pageTranslation[labelValue])) {
      return this.pageTranslation[labelValue];
    }

    if (check.assigned(this.template.metadata) && check.assigned(this.template.metadata.xAxis) && check.nonEmptyObject(this.template.metadata.xAxis)) {
      return labelValue;
    }

    if (groupConditions === '-MONTH-') {
      const yearVal = new Date(labelValue).getFullYear() % 100;
      const monthVal = this.MONTH_NAMES[new Date(labelValue).getMonth()];
      return `${monthVal} ${yearVal}`;
    } else if (groupConditions === '-YEAR-') {
      return new Date(labelValue).getFullYear().toString();
    } else {
      return labelValue;
    }
  }

  private buildNewStackRowTable(listFields: Array<string>, listValues: Array<number>, label: string, groupConditions: string): any {
    const result = {
      ' ': check.emptyString(label) ? this.emptyLabel : label
    };

    if (check.assigned(listFields) && check.array(listFields) && check.nonEmptyArray(listFields)) {
      listFields.forEach((iField, index) => {
        const iFieldLabel = this.getGroupNameForLabel(iField, groupConditions);
        result[iFieldLabel] = listValues[index];
      });
    }
    return result;
  }

  private getUserColor(index: number): string {
    if (index < 1) {
      return userColorConstants['USER_COLOR_1'];
    } else if (index >= Object.keys(userColorConstants).length) {
      const colorNumber = (index % Object.keys(userColorConstants).length) + 1;
      return userColorConstants[`USER_COLOR_${colorNumber}`];
    } else {
      const colorNumber = index + 1;
      return userColorConstants[`USER_COLOR_${colorNumber}`];
    }
  }

  private initArrayForSecondRow(arrayLength: number): Array<number> {
    const result = [];
    for (let i = 0; i < arrayLength; i++) {
      result.push(0);
    }
    return result;
  }

  getTranslation(fieldName: string): string {
    if (check.not.assigned(fieldName) || check.not.string(fieldName) || check.emptyString(fieldName)) {
      return '';
    }

    if (check.contains(fieldName, '.')) {
      const object = fieldName.split('.')[0].trim();
      const field = fieldName.split('.')[1].trim();
      return this.translationMatrix[object] && this.translationMatrix[object][field] ? this.translationMatrix[object][field] : field;
    }

    const masterObject = this.template.metadata.collection;
    return this.translationMatrix[masterObject] && this.translationMatrix[masterObject][fieldName] ? this.translationMatrix[masterObject][fieldName] : fieldName;
  }

  getSummaryTranslation(): string {
    return this.summaryTranslation ? this.summaryTranslation : '';
  }

  getGroupTranslation(): string {
    return this.groupTranslation ? this.groupTranslation : '';
  }

  private sortTable(): void {
    if (check.not.assigned(this.stackedDataForDataSource) || check.emptyArray(this.stackedDataForDataSource)) {
      return;
    }

    this.stackedDataForDataSource = _.sortBy(this.stackedDataForDataSource, (iElement) => {
      return iElement[this.displayedColumns[0]];
    });
  }
}
