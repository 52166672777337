import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { InAppInviteDialog } from '@app/common-dialogs/in-app-invite/in-app-invite-dialog/in-app-invite.dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { InAppInviteService } from '@app/standard/services/in-app-invite/in-app-invite.service';
import * as check from 'check-types';
import * as _ from 'lodash';

import { GenericCacheModel } from '../../../core/generic-cache-model';
import { InputValidation } from '../../../core/validation/input-validation';
import { GlobalBarService } from '../../../services/core/global-bar.service';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { ProfileService } from '../../../services/profile/profile.service';
import * as SHIFTPLAN_PERMISSIONS from './permission-packages/shiftplan';

@Component({
  selector: 'orgos-permissions-summary',
  templateUrl: 'permissions-summary.component.html',
  styleUrls: ['permissions-summary.component.scss'],
})
export class PermissionsSummaryComponent implements OnInit {
  CUSTOM_PERMISSIONS: any = {
    employees: [
      'c_addEmployees_all',
      'c_addEmployees_custom',
      'c_activateEmployees_all',
      'c_activateEmployees_custom',
      'c_deactivateEmployees_all',
      'c_deactivateEmployees_custom',
      'c_deleteEmployees_all',
      'c_deleteEmployees_custom',
      'c_viewPersonalTab_own',
      'c_viewPersonalTab_custom',
      'c_viewPersonalTab_all',
      'c_changeWorkEmail_own',
      'c_changeWorkEmail_custom',
      'c_changeWorkEmail_all',
      'c_resetPassword_own',
      'c_resetPassword_custom',
      'c_resetPassword_all',
      'c_triggerManualWorkflows_custom',
      'c_triggerManualWorkflows_all',
      'c_editEmailSignature_own',
      'c_editEmailSignature_custom',
      'c_editEmailSignature_all',
      'c_viewHistory_own',
      'c_viewHistory_custom',
      'c_viewHistory_all',
      'c_viewOnlyMyOwnEmail',
    ],
    'payroll-feature': [
      'c_managePayroll',
      'c_viewCompensation_own',
      'c_viewCompensation_custom',
      'c_viewCompensation_all',
      'c_editCompensation_own',
      'c_editCompensation_custom',
      'c_editCompensation_all',
      'c_editPastCompensation',
      'c_viewCompanyPayroll',
      'c_exportCompanyPayroll',
    ],
    'time-off-app': [
      'c_useTimeOff',
      'c_requestTimeOff',
      'c_cancelFutureTimeOffRequests_own',
      'c_cancelFutureTimeOffRequests_custom',
      'c_cancelFutureTimeOffRequests_all',
      'c_cancelProcessedTimeOffRequests_own',
      'c_cancelProcessedTimeOffRequests_custom',
      'c_cancelProcessedTimeOffRequests_all',
      'c_submitRequestsOnBehalf_custom',
      'c_submitRequestsOnBehalf_all',
      'c_viewTimeOffRequestDetails_own',
      'c_viewTimeOffRequestDetails_custom',
      'c_viewTimeOffRequestDetails_all',
      'c_approveTimeOffRequests_own',
      'c_approveTimeOffRequests_custom',
      'c_approveTimeOffRequests_all',
      'c_accessTimeOffCompanyPage_custom',
      'c_accessTimeOffCompanyPage_all',
      'c_manageTimeOff_own',
      'c_manageTimeOff_custom',
      'c_manageTimeOff_all',
    ],
    'reports-app': [
      'c_viewFinanceReports',
      'c_viewPeopleReports',
      'c_viewRecruitingReports',
      'c_viewTimeOffReports',
      'c_viewProjectReports',
      'c_exportReports',
    ],
    'dashboards-app': ['c_viewFinanceDashboards', 'c_viewManagementDashboards', 'c_viewRecruitingDashboards', 'c_exportData'],
    settings: [
      'c_manageCompanies',
      'c_manageWorkflows',
      'c_manageOffices',
      'c_manageTeams',
      'c_manageAreas',
      'c_manageDepartments',
      'c_manageEmployeeFields',
      'c_manageEmailTemplates',
      'c_manageWelcomeWizard',
      'c_manageProfilesAndPermissions',
      'c_manageSingleSignOn',
      'c_manageCalendars',
      'c_manageCostCenters',
    ],
    'tasks-app': ['c_accessMyTasks', 'c_viewTasksAssignedToOthers', 'c_accessCompanyTasks'],
    'recruiting-options': [
      'c_talent_pool',
      'c_talent_pool_full',
      'c_candidate_list',
      'c_addJobOpening_all',
      'c_addJobOpening_custom',
      'c_add_candidates',
      'c_add_position_candidates',
      'c_chrome_extension',
      'c_accessInbox',
      'c_convertEmployees',
    ],
    'project-app': ['c_viewSummaryTab', 'c_projectAndMemberCosts', 'c_manageTeamMembers'],
    'document-app': ['c_viewHiddenDocuments', 'c_accessToImports', 'c_uploadCompanyDocs', 'c_accessToDocumentTemplates'],
    'attendance-app': [
      'c_launchKioskAndPinCode',
      'c_viewAttendanceSummary_custom',
      'c_viewAttendanceSummary_all',
      'c_manageAttendanceSummary_custom',
      'c_manageAttendanceSummary_all',
      'c_viewAttendanceTab_own',
      'c_viewAttendanceTab_custom',
      'c_viewAttendanceTab_all',
      'c_editAttendanceTab_custom',
      'c_editAttendanceTab_all',
      'c_viewAttendanceCheckInTab',
      'c_activateDeactivateTrackingPersonal_own',
      'c_activateDeactivateTrackingPersonal_custom',
      'c_activateDeactivateTrackingPersonal_all',
      'c_approveAttendance_own',
      'c_approveAttendance_custom',
      'c_approveAttendance_all',
    ],
    'pulse-app': ['c_pulseInsights', 'c_pulseFeeds'],
    'org-chart-feature': ['c_viewOrgChart', 'c_createOrgChart'],
    'e-signature': ['create_requests', 'requests_tab'],
    'performance-feedback-results': ['c_readFeedback_all', 'c_readFeedback_custom', 'c_viewAnalyseTabs_all', 'c_viewAnalyseTabs_custom'],
  };

  // Shiftplan specific permissions package
  SHIFTPLAN_PERMISSIONS_PACKAGE: any = SHIFTPLAN_PERMISSIONS.SHIFTPLAN_PERMISSIONS_PACKAGE;
  shiftplanPackageMap = this.SHIFTPLAN_PERMISSIONS_PACKAGE.map;
  shiftplanPackageKey = this.SHIFTPLAN_PERMISSIONS_PACKAGE.packageKey;
  isShiftplanActive: Boolean = false;

  LIST_OTHER_PERMISSIONS: Array<string> = ['reports-app', 'dashboards-app', 'settings', 'recruiting-options'];

  PERMISSIONS_TO_INCLUDE = [
    'user-personal',
    'user-work',
    'user-work-schedule',
    'user-address',
    'user-home',
    'user-financial',
    'user-emergency',
    'user-confidential',
  ];
  IGNORE_PERMISSIONS_MAP: any = {
    // The ones set to true will be ignored and won't be displayed on the screen
    'user-personal': {
      create_all: true,
      create_custom: true,
      create_own: true,
      read_all: true,
      read_custom: true,
      read_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-work': {
      create_all: true,
      create_custom: true,
      create_own: true,
      read_all: true,
      read_custom: true,
      read_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-work-schedule': {
      create_all: true,
      create_custom: true,
      create_own: true,
      read_all: true,
      read_custom: true,
      read_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-address': {
      create_all: true,
      create_custom: true,
      create_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-home': {
      create_all: true,
      create_custom: true,
      create_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-financial': {
      create_all: true,
      create_custom: true,
      create_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-emergency': {
      create_all: true,
      create_custom: true,
      create_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-confidential': {
      create_all: true,
      create_custom: true,
      create_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    'user-attendance': {
      create_all: true,
      create_custom: true,
      create_own: true,
      read_all: true,
      read_custom: true,
      read_own: true,
      edit_all: true,
      edit_custom: true,
      edit_own: true,
      delete_all: true,
      delete_custom: true,
      delete_own: true,
    },
    goal: {
      create_custom: true,
      create_own: true,
    },
    'performance-review': {
      create_own: true,
      create_custom: true,
      edit_own: true,
      edit_custom: true,
      read_custom: true,
      read_own: true,
      read_all: true,
      delete_own: true,
      delete_custom: true,
    },
    project: {
      create_custom: true,
      create_own: true,
      delete_custom: true,
      delete_own: true,
    },
  };
  CUSTOM_TRANSLATIONS_MAP: any = {
    goal: {
      create_all: 'createGoals',
      edit_own: 'editGoalsOwn',
      edit_custom: 'editGoalsCustom',
      read_own: 'viewGoalsOwn',
      read_custom: 'viewGoalsCustom',
      delete_own: 'deleteGoalsOwn',
      delete_custom: 'deleteGoalsCustom',
    },
    project: {
      create_all: 'createProjects',
      edit_custom: 'editProjectsCustom',
      edit_all: 'editProjects',
      edit_own: 'editProjectsOwn',
      read_custom: 'viewProjectsCustom',
      read_all: 'viewProjects',
      read_own: 'viewProjectsOwn',
      delete_all: 'deleteProjects',
    },
    document: {
      create_own: 'createOwnDocuments',
      create_custom: 'createCustomDocuments',
      create_all: 'createAllDocuments',
      edit_custom: 'editDocumentsCustom',
      edit_all: 'editAllDocuments',
      edit_own: 'editOwnDocuments',
      read_all: 'viewAllDocuments',
      read_custom: 'viewCustomDocuments',
      read_own: 'viewOwnDocuments',
      delete_all: 'deleteAllDocuments',
      delete_custom: 'deleteCustomDocuments',
      delete_own: 'deleteOwnDocuments',
    },
    'performance-review': {
      create_all: 'createReview',
      read_all: 'readReview',
      edit_all: 'editReview',
      delete_all: 'deleteReview',
    },
    'user-personal': {
      edit_own: 'editOwnPersonal',
    },
    'user-work': {
      edit_own: 'editOwnWork',
    },
    'user-work-schedule': {
      edit_own: 'editOwnWorkSchedule',
    },
    'user-address': {
      read_own: 'viewOwnAddress',
      edit_own: 'editOwnAddress',
    },
    'user-home': {
      read_own: 'viewOwnHome',
      edit_own: 'editOwnHome',
    },
    'user-financial': {
      read_own: 'viewOwnFinancial',
      edit_own: 'editOwnFinancial',
    },
    'user-emergency': {
      read_own: 'viewOwnEmergency',
      edit_own: 'editOwnEmergency',
    },
    'user-confidential': {
      read_own: 'viewOwnConfidential',
      edit_own: 'editOwnConfidential',
    },
  };
  READ_ONLY_PERMISSIONS: any = {
    'user-personal': {
      read_own: true,
    },
    'user-work': {
      read_own: true,
    },
    'reports-app': {},
    'dashboards-app': {},
    'time-off-app': {
      c_useTimeOff: true,
      c_requestTimeOff: true,
      c_cancelFutureTimeOffRequests: true,
      c_approveTimeOffRequests: true,
    },
    employees: {},
    'payroll-feature': {},
    'user-compensation': {},
    'user-address': {},
    'user-home': {},
    'user-financial': {},
    'user-emergency': {},
    'user-confidential': {},
    'recruiting-options': {},
    'attendance-app': {},
    'tasks-app': {},
    project: {
      read_own: true,
    },
    document: {
      read_own: true,
    },
    'org-chart-feature': {},
    'e-signature': {},
    'shift-plan-role': {},
    'shift-plan-working-area': {},
    'shift-plan-tag': {},
    'shiftplan-app': {
      scheduling: {},
      features: {
        c_viewScheduleTab: true,
      },
    },
  };

  READ_ONLY_SHIFTPLAN_PERMISSIONS: any = {
    'shift-plan-role': {},
    'shift-plan-working-area': {},
    'shift-plan-tag': {},
    'shift-plan-template': {},
    'shiftplan-app': {
      scheduling: {},
      features: {
        c_viewScheduleTab: true,
      },
    },
  };
  i18n: any = {};
  collectionNamesTranslation: any = {};
  tabSelected: number = 0;
  profileCacheModel: GenericCacheModel;
  nameValidation: InputValidation;
  orderedListCollectionNames: Array<string>;
  tentativeProfile: any = {};
  inAppInviteService: InAppInviteService;

  @Output() profileUpdated: EventEmitter<any> = new EventEmitter<any>();

  private _profile: any;
  @Input()
  set profile(profileVal: any) {
    this.initReadOnlyPermissions();
    this._profile = profileVal;
    this.profileCacheModel = new GenericCacheModel(this.injector, { _id: profileVal._id, name: profileVal.name }, ProfileService, '');
    this.tentativeProfile = {
      permissions: {},
    };
    this.tentativeProfile.permissions = _.cloneDeep(profileVal.permissions);
    this.initPermissionsList(profileVal);
    this.setReadOnlyAsNeeded();
  }
  get profile(): any {
    return this._profile;
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((i18n) => {
        this.i18n = i18n;
      })
      .catch(() => {
        this.i18n = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('collection-names')
      .then((collectionNamesTranslation) => {
        this.collectionNamesTranslation = collectionNamesTranslation;
      })
      .catch(() => {
        this.collectionNamesTranslation = {};
      });

    this.injector
      .get(CloudRoutesService)
      .getAppStatus('shift-plan')
      .then((shiftplanStatus) => {
        this.isShiftplanActive = shiftplanStatus?.isActive === true;
      })
      .catch(() => {});

    this.inAppInviteService = this.injector.get(InAppInviteService);
  }

  public saveProfile(): void {
    if (check.not.assigned(this.nameValidation) || this.nameValidation.hasErrors()) {
      return;
    }

    this.injector.get(GlobalBarService).setProgressBar(true);

    if (check.assigned(this.tentativeProfile) && check.nonEmptyObject(this.tentativeProfile.permissions)) {
      // In case there are any updates on permissions
      Object.keys(this.tentativeProfile.permissions).forEach((iPermissionKey) => {
        this.profile.permissions[iPermissionKey] = this.tentativeProfile.permissions[iPermissionKey];
      });
    }
    this.injector
      .get(ProfileService)
      .updateById(this.profile._id, { name: this.profileCacheModel.data.name, permissions: this.profile.permissions })
      .then(() => {
        this.profile.name = this.profileCacheModel.data.name;
        this.profileUpdated.emit(this.profile);
        this.injector.get(MatLegacySnackBar).open(this.i18n.profileUpdatedSnackbar, 'OK', { duration: 5000 });
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch(() => {});
  }

  public permissionsChanged(iPermission: any): void {
    this.tentativeProfile = _.cloneDeep(this.tentativeProfile);
    const previous = iPermission.previousPermissions;
    const readAllMustBeEnabled = iPermission.permissions.read_all || iPermission.permissions.edit_all || iPermission.permissions.delete_all;
    this.tentativeProfile.permissions[iPermission.permissionKey] = Object.assign({}, iPermission.permissions);

    if (readAllMustBeEnabled && iPermission.permissionKey.startsWith('user-')) {
      this.tentativeProfile.permissions['user-personal'].read_all = true;
      this.tentativeProfile.permissions['user-personal'].read_own = true;
      this.tentativeProfile.permissions['user-work'].read_all = true;
      this.tentativeProfile.permissions['user-work'].read_own = true;
    }

    // Ensure that user-personal and user-work have always the very same value for read_all
    if (iPermission.permissionKey === 'user-work') {
      this.tentativeProfile.permissions['user-personal'].read_all = iPermission.permissions.read_all;
    } else if (iPermission.permissionKey === 'user-personal') {
      this.tentativeProfile.permissions['user-work'].read_all = iPermission.permissions.read_all;
    }

    if (this.tentativeProfile.permissions['employees'].c_addEmployees_all === true) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_activateEmployees_all === true) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_deactivateEmployees_all === true) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_deleteEmployees_all === true) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_changeWorkEmail_custom)
    ) {
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail = false;
    }
    if (this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all === true) {
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_own = true;
    }
    if (
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all === true ||
      this.tentativeProfile.permissions['employees'].c_resetPassword_all === true
    ) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_own === true ||
      this.tentativeProfile.permissions['employees'].c_resetPassword_own === true
    ) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_own = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail === true) {
      this.tentativeProfile.permissions['employees'].c_addEmployees_all = false;
      this.tentativeProfile.permissions['employees'].c_addEmployees_custom = [];
      this.tentativeProfile.permissions['employees'].c_activateEmployees_all = false;
      this.tentativeProfile.permissions['employees'].c_activateEmployees_custom = [];
      this.tentativeProfile.permissions['employees'].c_deactivateEmployees_all = false;
      this.tentativeProfile.permissions['employees'].c_deactivateEmployees_custom = [];
      this.tentativeProfile.permissions['employees'].c_deleteEmployees_all = false;
      this.tentativeProfile.permissions['employees'].c_deleteEmployees_custom = [];
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all = false;
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_custom = [];
      this.tentativeProfile.permissions['employees'].c_resetPassword_all = false;
      this.tentativeProfile.permissions['employees'].c_resetPassword_custom = [];
      this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_all = false;
      this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_custom = [];
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_all = false;
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_custom = [];
      this.tentativeProfile.permissions['employees'].c_viewHistory_all = false;
      this.tentativeProfile.permissions['employees'].c_viewHistory_custom = [];
    }

    if (
      this.tentativeProfile.permissions['employees'].c_addEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_addEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_activateEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_activateEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_deactivateEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_deactivateEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_deleteEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_deleteEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_changeWorkEmail_custom) ||
      this.tentativeProfile.permissions['employees'].c_resetPassword_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_resetPassword_custom) ||
      this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_custom) ||
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_editEmailSignature_custom) ||
      this.tentativeProfile.permissions['employees'].c_viewHistory_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_viewHistory_custom)
    ) {
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail = false;
    }

    if (
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_resetPassword_custom) &&
      check.emptyArray(this.tentativeProfile.permissions['employees'].c_viewPersonalTab_custom)
    ) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_custom =
        this.tentativeProfile.permissions['employees'].c_resetPassword_custom;
    }

    if (this.tentativeProfile.permissions['employees'].c_viewHistory_all) {
      this.tentativeProfile.permissions['employees'].c_viewHistory_own = true;
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_viewHistory_own) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_own = true;
    }

    if (this.tentativeProfile.permissions['employees'].c_resetPassword_all) {
      this.tentativeProfile.permissions['employees'].c_resetPassword_own = true;
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_resetPassword_own) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_own = true;
    }

    if (this.tentativeProfile.permissions['employees'].c_editEmailSignature_all) {
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_own = true;
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_editEmailSignature_own) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_own = true;
    }
    if (this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_all) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all = true;
    }

    if (this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all === true) {
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_own = true;
    }

    // SMART-DOCS
    if (this.tentativeProfile.permissions['document-app'].c_accessToImports === true) {
      this.tentativeProfile.permissions['document'].read_all = true;
      this.tentativeProfile.permissions['document'].read_own = true;
    }

    if (previous?.c_accessToImports === true && this.tentativeProfile.permissions['document-app'].c_accessToImports === false) {
      this.tentativeProfile.permissions['document'].read_all =
        false || this.tentativeProfile.permissions['document'].delete_all || this.tentativeProfile.permissions['document'].edit_all;
    }

    // TIME OFF PERMISSIONS:
    if (this.tentativeProfile.permissions['time-off-app'].c_manageTimeOff_all === true) {
      this.tentativeProfile.permissions['time-off-app'].c_accessTimeOffCompanyPage_all = true;
      this.tentativeProfile.permissions['time-off-app'].c_manageTimeOff_own = true;
      this.tentativeProfile.permissions['time-off-app'].c_submitRequestsOnBehalf_all = true;
    }
    if (this.tentativeProfile.permissions['time-off-app'].c_accessTimeOffCompanyPage_all === true) {
      this.tentativeProfile.permissions['time-off-app'].c_viewTimeOffRequestDetails_all = true;
    }
    if (this.tentativeProfile.permissions['time-off-app'].c_approveTimeOffRequests_all === true) {
      this.tentativeProfile.permissions['time-off-app'].c_approveTimeOffRequests_own = true;
      this.tentativeProfile.permissions['time-off-app'].c_viewTimeOffRequestDetails_all = true;
    }
    if (this.tentativeProfile.permissions['time-off-app'].c_viewTimeOffRequestDetails_all === true) {
      this.tentativeProfile.permissions['time-off-app'].c_viewTimeOffRequestDetails_own = true;
    }
    if (
      this.tentativeProfile.permissions['time-off-app'].c_cancelFutureTimeOffRequests_all === true ||
      this.tentativeProfile.permissions['time-off-app'].c_cancelFutureTimeOffRequests_own === true ||
      this.tentativeProfile.permissions['time-off-app'].c_cancelProcessedTimeOffRequests_all === true ||
      this.tentativeProfile.permissions['time-off-app'].c_cancelProcessedTimeOffRequests_own === true
    ) {
      this.tentativeProfile.permissions['time-off-app'].c_requestTimeOff = true;
    }
    if (this.tentativeProfile.permissions['time-off-app'].c_requestTimeOff === true) {
      this.tentativeProfile.permissions['time-off-app'].c_useTimeOff = true;
    }

    // PAYROLL
    if (this.tentativeProfile.permissions['payroll-feature'].c_exportCompanyPayroll === true) {
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_own = true;
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll = true;
    }

    if (this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll === true) {
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_all = true;
    }

    if (this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_all === true) {
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_own = true;
    }

    if (this.tentativeProfile.permissions['payroll-feature'].c_editCompensation_own === true) {
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_own = true;
    }

    if (this.tentativeProfile.permissions['payroll-feature'].c_editCompensation_all === true) {
      this.tentativeProfile.permissions['payroll-feature'].c_editCompensation_own = true;
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_all = true;
    }

    // PROCESS TASKS PERMISSIONS
    if (this.tentativeProfile.permissions['tasks-app'].c_accessCompanyTasks === true) {
      this.tentativeProfile.permissions['tasks-app'].c_accessMyTasks = true;
    }

    if (this.tentativeProfile.permissions['tasks-app'].c_viewTasksAssignedToOthers === true) {
      this.tentativeProfile.permissions['tasks-app'].c_accessMyTasks = true;
    }

    // PROCESS RECRUITING PERMISSIONS
    if (
      this.tentativeProfile.permissions['recruiting-options'].c_add_position_candidates === true ||
      this.tentativeProfile.permissions['recruiting-options'].c_add_candidates === true
    ) {
      this.tentativeProfile.permissions['recruiting-options'].c_candidate_list = true;
    }

    if (this.tentativeProfile.permissions['recruiting-options'].c_chrome_extension === true) {
      this.tentativeProfile.permissions['recruiting-options'].c_add_candidates = true;
      this.tentativeProfile.permissions['recruiting-options'].c_add_position_candidates = true;
    }

    if (this.tentativeProfile.permissions['recruiting-options'].c_talent_pool_full === true) {
      this.tentativeProfile.permissions['recruiting-options'].c_talent_pool = true;
      this.tentativeProfile.permissions['recruiting-options'].c_accessInbox = true;
      this.tentativeProfile.permissions['recruiting-options'].c_inbox = true;
    }

    // PROCESS ORG CHART
    if (this.tentativeProfile.permissions['org-chart-feature'].c_createOrgChart === true) {
      this.tentativeProfile.permissions['org-chart-feature'].c_viewOrgChart = true;
    }

    // ATTENDANCE PERMISSIONS
    this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceSummary_all =
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceSummary_all;
    this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceSummary_custom = _.cloneDeep(
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceSummary_custom
    );

    if (this.tentativeProfile.permissions['attendance-app'].c_editAttendanceTab_all === true) {
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceSummary_all = true;
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceTab_own = true;
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceTab_all = true;
    }

    if (this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceTab_all === true) {
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceTab_own = true;
    }

    if (this.tentativeProfile.permissions['attendance-app'].c_activateDeactivateTrackingPersonal_all === true) {
      this.tentativeProfile.permissions['attendance-app'].c_activateDeactivateTrackingPersonal_own = true;
    }

    this.setReadOnlyAsNeeded();
    this.initPermissionsList(this.tentativeProfile);
  }

  private setReadOnlyAsNeeded(): void {
    this.READ_ONLY_PERMISSIONS = _.cloneDeep(this.READ_ONLY_PERMISSIONS);

    // EMPLOYEES
    this.READ_ONLY_PERMISSIONS['employees'].c_viewPersonalTab_all =
      this.tentativeProfile.permissions['employees'].c_addEmployees_all ||
      this.tentativeProfile.permissions['employees'].c_activateEmployees_all ||
      this.tentativeProfile.permissions['employees'].c_deactivateEmployees_all ||
      this.tentativeProfile.permissions['employees'].c_deleteEmployees_all ||
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all ||
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_all ||
      this.tentativeProfile.permissions['employees'].c_resetPassword_all ||
      this.tentativeProfile.permissions['employees'].c_viewHistory_all ||
      this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_viewPersonalTab_own =
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_own ||
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_own ||
      this.tentativeProfile.permissions['employees'].c_resetPassword_own ||
      this.tentativeProfile.permissions['employees'].c_viewHistory_own ||
      this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_viewPersonalTab_custom = this.tentativeProfile.permissions['employees'].c_viewPersonalTab_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_changeWorkEmail_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_changeWorkEmail_custom =
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all ||
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_changeWorkEmail_own = this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_viewHistory_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_viewHistory_custom =
      this.tentativeProfile.permissions['employees'].c_viewHistory_all ||
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_viewHistory_own = this.tentativeProfile.permissions['employees'].c_viewHistory_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_resetPassword_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_resetPassword_custom =
      this.tentativeProfile.permissions['employees'].c_resetPassword_all ||
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_resetPassword_own = this.tentativeProfile.permissions['employees'].c_resetPassword_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_triggerManualWorkflows_all =
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_triggerManualWorkflows_custom =
      this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_all ||
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_editEmailSignature_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_editEmailSignature_custom =
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_all ||
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_editEmailSignature_own =
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_viewOnlyMyOwnEmail =
      this.tentativeProfile.permissions['employees'].c_addEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_addEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_activateEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_activateEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_deactivateEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_deactivateEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_deleteEmployees_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_deleteEmployees_custom) ||
      this.tentativeProfile.permissions['employees'].c_changeWorkEmail_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_changeWorkEmail_custom) ||
      this.tentativeProfile.permissions['employees'].c_resetPassword_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_resetPassword_custom) ||
      this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_triggerManualWorkflows_custom) ||
      this.tentativeProfile.permissions['employees'].c_editEmailSignature_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_editEmailSignature_custom) ||
      this.tentativeProfile.permissions['employees'].c_viewHistory_all === true ||
      check.nonEmptyArray(this.tentativeProfile.permissions['employees'].c_viewHistory_custom);
    this.READ_ONLY_PERMISSIONS['employees'].c_addEmployees_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_addEmployees_custom =
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail ||
      this.tentativeProfile.permissions['employees'].c_addEmployees_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_activateEmployees_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_activateEmployees_custom =
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail ||
      this.tentativeProfile.permissions['employees'].c_activateEmployees_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_deactivateEmployees_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_deactivateEmployees_custom =
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail ||
      this.tentativeProfile.permissions['employees'].c_deactivateEmployees_all;
    this.READ_ONLY_PERMISSIONS['employees'].c_deleteEmployees_all = this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail;
    this.READ_ONLY_PERMISSIONS['employees'].c_deleteEmployees_custom =
      this.tentativeProfile.permissions['employees'].c_viewOnlyMyOwnEmail ||
      this.tentativeProfile.permissions['employees'].c_deleteEmployees_all;

    this.READ_ONLY_PERMISSIONS['document'].read_all = this.tentativeProfile.permissions['document-app'].c_accessToImports === true;
    this.READ_ONLY_PERMISSIONS['document'].read_own = this.tentativeProfile.permissions['document-app'].c_accessToImports === true;

    // TIME OFF PERMISSIONS:
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_useTimeOff = true;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_requestTimeOff = true; // TODO: PATCH UNTIL SUBORDINATES
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_cancelFutureTimeOffRequests_all =
      this.tentativeProfile.permissions['time-off-app'].c_approveTimeOffRequests_all;
    /*this.READ_ONLY_PERMISSIONS['time-off-app'].c_requestTimeOff = this.tentativeProfile.permissions['time-off-app'].c_cancelFutureTimeOffRequests || this.tentativeProfile.permissions['time-off-app'].c_cancelProcessedTimeOffRequests;
        this.READ_ONLY_PERMISSIONS['time-off-app'].c_cancelFutureTimeOffRequests = this.tentativeProfile.permissions['time-off-app'].c_approveTimeOffRequests;*/
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_cancelProcessedTimeOffRequests_all =
      this.tentativeProfile.permissions['time-off-app'].c_accessTimeOffCompanyPage_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_manageTimeOff_own = this.tentativeProfile.permissions['time-off-app'].c_manageTimeOff_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_manageTimeOff_custom =
      this.tentativeProfile.permissions['time-off-app'].c_manageTimeOff_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_submitRequestsOnBehalf_all =
      this.tentativeProfile.permissions['time-off-app'].c_manageTimeOff_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_accessTimeOffCompanyPage_all =
      this.tentativeProfile.permissions['time-off-app'].c_manageTimeOff_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_accessTimeOffCompanyPage_custom =
      this.tentativeProfile.permissions['time-off-app'].c_accessTimeOffCompanyPage_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_approveTimeOffRequests_own =
      this.tentativeProfile.permissions['time-off-app'].c_approveTimeOffRequests_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_approveTimeOffRequests_custom =
      this.tentativeProfile.permissions['time-off-app'].c_approveTimeOffRequests_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_viewTimeOffRequestDetails_own = true;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_viewTimeOffRequestDetails_custom =
      this.tentativeProfile.permissions['time-off-app'].c_viewTimeOffRequestDetails_all;
    this.READ_ONLY_PERMISSIONS['time-off-app'].c_viewTimeOffRequestDetails_all =
      this.tentativeProfile.permissions['time-off-app'].c_accessTimeOffCompanyPage_all ||
      this.tentativeProfile.permissions['time-off-app'].c_approveTimeOffRequests_all;

    // ATTENDANCE
    this.READ_ONLY_PERMISSIONS['attendance-app'].c_viewAttendanceSummary_custom =
      this.tentativeProfile.permissions['attendance-app']?.c_viewAttendanceSummary_all;

    this.READ_ONLY_PERMISSIONS['attendance-app'].c_manageAttendanceSummary_custom =
      (!this.tentativeProfile.permissions['attendance-app']?.c_viewAttendanceSummary_all &&
        check.emptyArray(this.tentativeProfile.permissions['attendance-app']?.c_viewAttendanceSummary_custom)) ||
      this.tentativeProfile.permissions['attendance-app']?.c_manageAttendanceSummary_all;

    this.READ_ONLY_PERMISSIONS['attendance-app'].c_manageAttendanceSummary_all =
      !this.tentativeProfile.permissions['attendance-app']?.c_viewAttendanceSummary_all;

    this.READ_ONLY_PERMISSIONS['attendance-app'].c_viewAttendanceTab_own =
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceTab_all ||
      this.tentativeProfile.permissions['attendance-app'].c_editAttendanceTab_all;

    this.READ_ONLY_PERMISSIONS['attendance-app'].c_viewAttendanceTab_all =
      this.tentativeProfile.permissions['attendance-app'].c_editAttendanceTab_all;

    this.READ_ONLY_PERMISSIONS['attendance-app'].c_editAttendanceTab_custom =
      this.tentativeProfile.permissions['attendance-app']?.c_editAttendanceTab_all;

    this.READ_ONLY_PERMISSIONS['attendance-app'].c_viewAttendanceTab_custom =
      this.tentativeProfile.permissions['attendance-app'].c_editAttendanceTab_all ||
      this.tentativeProfile.permissions['attendance-app'].c_viewAttendanceTab_all;

    this.READ_ONLY_PERMISSIONS['attendance-app'].c_activateDeactivateTrackingPersonal_own =
      this.tentativeProfile.permissions['attendance-app'].c_activateDeactivateTrackingPersonal_all;
    this.READ_ONLY_PERMISSIONS['attendance-app'].c_activateDeactivateTrackingPersonal_custom =
      this.tentativeProfile.permissions['attendance-app'].c_activateDeactivateTrackingPersonal_all;

    // PAYROLL
    this.READ_ONLY_PERMISSIONS['payroll-feature'].c_viewCompensation_own =
      this.tentativeProfile.permissions['payroll-feature'].c_editCompensation_own ||
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_all;
    this.READ_ONLY_PERMISSIONS['payroll-feature'].c_viewCompensation_custom =
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompensation_all;
    this.READ_ONLY_PERMISSIONS['payroll-feature'].c_viewCompensation_all =
      this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll ||
      this.tentativeProfile.permissions['payroll-feature'].c_editCompensation_all;
    this.READ_ONLY_PERMISSIONS['payroll-feature'].c_editCompensation_own =
      this.tentativeProfile.permissions['payroll-feature'].c_editCompensation_all;
    this.READ_ONLY_PERMISSIONS['payroll-feature'].c_editCompensation_custom =
      this.tentativeProfile.permissions['payroll-feature'].c_editCompensation_all;
    this.READ_ONLY_PERMISSIONS['payroll-feature'].c_viewCompanyPayroll =
      this.tentativeProfile.permissions['payroll-feature'].c_exportCompanyPayroll;

    // USER COLLECTIONS
    this.READ_ONLY_PERMISSIONS['user-address'].read_all = this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll;
    this.READ_ONLY_PERMISSIONS['user-home'].read_all = this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll;
    this.READ_ONLY_PERMISSIONS['user-financial'].read_all = this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll;
    this.READ_ONLY_PERMISSIONS['user-emergency'].read_all = this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll;
    this.READ_ONLY_PERMISSIONS['user-confidential'].read_all = this.tentativeProfile.permissions['payroll-feature'].c_viewCompanyPayroll;

    // TASKS
    this.READ_ONLY_PERMISSIONS['tasks-app'].c_accessMyTasks =
      this.tentativeProfile.permissions['tasks-app'].c_accessCompanyTasks ||
      this.tentativeProfile.permissions['tasks-app'].c_viewTasksAssignedToOthers;

    // RECRUITING
    this.READ_ONLY_PERMISSIONS['recruiting-options'].c_add_candidates = this.tentativeProfile.permissions['recruiting-options']
      ? this.tentativeProfile.permissions['recruiting-options'].c_chrome_extension
      : false;
    this.READ_ONLY_PERMISSIONS['recruiting-options'].c_add_position_candidates = this.tentativeProfile.permissions['recruiting-options']
      ? this.tentativeProfile.permissions['recruiting-options'].c_chrome_extension
      : false;
    this.READ_ONLY_PERMISSIONS['recruiting-options'].c_candidate_list = this.tentativeProfile.permissions['recruiting-options']
      ? (this.tentativeProfile.permissions['recruiting-options'].c_add_position_candidates ||
          this.tentativeProfile.permissions['recruiting-options'].c_add_candidates) &&
        !this.tentativeProfile.permissions['recruiting-options'].c_chrome_extension
      : false;
    this.READ_ONLY_PERMISSIONS['recruiting-options'].c_talent_pool = this.tentativeProfile.permissions['recruiting-options']
      ? this.tentativeProfile.permissions['recruiting-options'].c_talent_pool_full
      : false;
    this.READ_ONLY_PERMISSIONS['recruiting-options'].c_accessInbox = this.tentativeProfile.permissions['recruiting-options']
      ? this.tentativeProfile.permissions['recruiting-options'].c_talent_pool_full
      : false;
    this.READ_ONLY_PERMISSIONS['recruiting-options'].c_inbox = this.tentativeProfile.permissions['recruiting-options']
      ? this.tentativeProfile.permissions['recruiting-options'].c_talent_pool_full
      : false;

    // ORG CHART
    this.READ_ONLY_PERMISSIONS['org-chart-feature'].c_viewOrgChart =
      this.tentativeProfile.permissions['org-chart-feature'].c_createOrgChart;
    // Shiftplan
    this.processShiftplanReadOnly();
  }

  private initPermissionsList(profileVal: any): void {
    this.orderedListCollectionNames = [];
    if (check.not.assigned(profileVal) || check.not.assigned(profileVal.permissions) || check.emptyObject(profileVal.permissions)) {
      return;
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('collection-names')
      .then((collectionNamesTranslation) => {
        this.collectionNamesTranslation = collectionNamesTranslation;
        this.orderedListCollectionNames = this.PERMISSIONS_TO_INCLUDE;
      })
      .catch(() => {
        this.collectionNamesTranslation = {};
      });
  }

  /**
   * Change all the values of this.READ_ONLY_PERMISSIONS so that the
   * component orgos-permissions-box updates the value we are passing
   */
  private initReadOnlyPermissions() {
    Object.keys(this.READ_ONLY_PERMISSIONS).forEach((iReadOnlyKey) => {
      const readOnlyValue = _.cloneDeep(this.READ_ONLY_PERMISSIONS[iReadOnlyKey]);
      this.READ_ONLY_PERMISSIONS[iReadOnlyKey] = {};
      this.READ_ONLY_PERMISSIONS[iReadOnlyKey] = readOnlyValue;
    });

    Object.keys(this.READ_ONLY_SHIFTPLAN_PERMISSIONS).forEach((iReadOnlyKey) => {
      const readOnlyValue = _.cloneDeep(this.READ_ONLY_SHIFTPLAN_PERMISSIONS[iReadOnlyKey]);
      this.READ_ONLY_SHIFTPLAN_PERMISSIONS[iReadOnlyKey] = {};
      this.READ_ONLY_SHIFTPLAN_PERMISSIONS[iReadOnlyKey] = readOnlyValue;
    });
  }

  /**************************************************
   * Methods for the shiftplan permissions packages *
   **************************************************/

  public shiftplanPermissionsChanged(iPermission: any): void {
    this.tentativeProfile = _.cloneDeep(this.tentativeProfile);
    this.tentativeProfile.permissions[iPermission.permissionKey] = Object.assign({}, iPermission.permissions);

    if (this.tentativeProfile.permissions['shiftplan-app'].scheduling.c_manageScheduleShifts_custom.length > 0) {
      this.tentativeProfile.permissions['shiftplan-app'].scheduling.c_manageScheduleShifts_own = true;
    }

    const differentAttribute = this.findDifferentAttribute(iPermission.previousPermissions, iPermission.permissions);
    if (iPermission.permissionKey === 'shiftplan-app') {
      this.processShiftplanAppDependencies(differentAttribute);
    } else {
      this.processOtherShiftplanPermission(iPermission.permissionKey, differentAttribute);
    }
    this.processShiftplanReadOnly();
  }

  private findDifferentAttribute(obj1, obj2) {
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const nestedDiff = this.findDifferentAttribute(obj1[key], obj2[key]);
          if (nestedDiff) {
            return key + '.' + nestedDiff;
          }
        } else if (obj1[key] !== obj2[key]) {
          return key;
        }
      }
    }
    return null;
  }

  private processShiftplanAppDependencies(differentAttribute) {
    switch (differentAttribute) {
      case 'features.c_editEmployeeProfileShiftplan_all':
        if (this.tentativeProfile.permissions['shiftplan-app'].features.c_editEmployeeProfileShiftplan_all === true) {
          this.tentativeProfile.permissions['shiftplan-app'].features.c_editEmployeeProfileShiftplan_own = true;
        }
        break;
      case 'scheduling.c_manageAccessShiftplan_own':
        this.tentativeProfile.permissions['shiftplan-app'].scheduling.c_manageAccessShiftplan_own =
          this.tentativeProfile?.permissions?.['shiftplan-app']?.features?.c_viewScheduleTab ?? false;
        break;
      case 'features.c_viewSettingsTab':
        if (this.tentativeProfile.permissions['shiftplan-app'].features.c_viewSettingsTab === false) {
          const permissions = ['shift-plan-role', 'shift-plan-working-area', 'shift-plan-tag'];
          const actions = ['create_all', 'edit_own', 'edit_all', 'delete_own', 'delete_all'];
          permissions.forEach((permission) => {
            actions.forEach((action) => {
              this.tentativeProfile.permissions[permission][action] = false;
            });
          });
        }
        break;
      case 'scheduling.c_manageScheduleShifts_all':
        if (this.tentativeProfile.permissions['shiftplan-app'].scheduling.c_manageScheduleShifts_all === true) {
          this.tentativeProfile.permissions['shiftplan-app'].scheduling.c_manageScheduleShifts_own = true;
        }
        break;
      case 'scheduling.c_scheduledShiftsCreate':
      case 'scheduling.c_scheduledShiftsEdit':
      case 'scheduling.c_scheduledShiftsDelete':
        const [, scheduledAttr] = differentAttribute.split('.');
        if (this.tentativeProfile.permissions['shiftplan-app'].scheduling[scheduledAttr]) {
          this.tentativeProfile.permissions['shiftplan-app'].scheduling.c_scheduledShiftsViewUnpublished = true;
        }
        break;
      case 'scheduling.c_openShiftsCreate':
      case 'scheduling.c_openShiftsEdit':
      case 'scheduling.c_openShiftsDelete':
        const [, openAttr] = differentAttribute.split('.');
        if (this.tentativeProfile.permissions['shiftplan-app'].scheduling[openAttr] === true) {
          this.tentativeProfile.permissions['shiftplan-app'].scheduling.c_openShiftsViewUnpublished = true;
        }
        break;
      case 'scheduling.c_copyShifts':
      case 'scheduling.c_deleteShiftsInBulk':
      case 'scheduling.c_publishShifts':
        const [, actionAttr] = differentAttribute.split('.');
        if (this.tentativeProfile.permissions['shiftplan-app'].scheduling[actionAttr] === true) {
          this.updateShiftplanPermissions(
            this.tentativeProfile.permissions['shiftplan-app'].scheduling,
            [
              'c_scheduledShiftsCreate',
              'c_scheduledShiftsViewUnpublished',
              'c_scheduledShiftsEdit',
              'c_scheduledShiftsDelete',
              'c_openShiftsCreate',
              'c_openShiftsViewUnpublished',
              'c_openShiftsEdit',
              'c_openShiftsDelete',
            ],
            {
              c_scheduledShiftsCreate: true,
              c_scheduledShiftsViewUnpublished: true,
              c_scheduledShiftsEdit: true,
              c_scheduledShiftsDelete: true,
              c_openShiftsCreate: true,
              c_openShiftsViewUnpublished: true,
              c_openShiftsEdit: true,
              c_openShiftsDelete: true,
            }
          );
        }
        break;
      case 'scheduling.c_scheduledShiftsViewUnpublished':
      case 'scheduling.c_openShiftsViewUnpublished':
        const [, unpublishedAttribute] = differentAttribute.split('.');
        const attrsToUpdate = ['c_copyShifts', 'c_deleteShiftsInBulk'];
        const valuesToUpdate = { c_copyShifts: false, create_all: false };

        if (unpublishedAttribute === 'c_scheduledShiftsViewUnpublished') {
          attrsToUpdate.push('c_publishShifts');
          valuesToUpdate['c_publishShifts'] = false;
        }

        if (this.tentativeProfile.permissions['shiftplan-app'].scheduling[unpublishedAttribute] === false) {
          this.updateShiftplanPermissions(this.tentativeProfile.permissions['shiftplan-app'].scheduling, attrsToUpdate, valuesToUpdate);
        }
      default:
        break;
    }
  }
  private processOtherShiftplanPermission(permissionKey, differentAttribute) {
    if (permissionKey === 'shift-plan-template') {
      switch (differentAttribute) {
        case 'create_all':
          if (this.tentativeProfile.permissions['shift-plan-template'].create_all === true) {
            this.updateShiftplanPermissions(
              this.tentativeProfile.permissions['shift-plan-template'],
              ['edit_own', 'delete_own', 'read_all'],
              { edit_own: true, delete_own: true, read_all: true }
            );
          } else {
            this.updateShiftplanPermissions(
              this.tentativeProfile.permissions['shift-plan-template'],
              ['edit_own', 'delete_own', 'edit_all', 'delete_all'],
              { edit_own: false, delete_own: false, edit_all: false, delete_all: false }
            );
          }
          break;
        case 'read_all':
          if (this.tentativeProfile.permissions['shift-plan-template'].read_all === false) {
            this.tentativeProfile.permissions['shift-plan-template'].create_all === false;
          }
          break;
        case 'edit_own':
        case 'delete_own':
          const otherAttributeOwn = differentAttribute === 'edit_own' ? 'delete_own' : 'edit_own';
          const newValueOwn = this.tentativeProfile.permissions['shift-plan-template'][differentAttribute] === true;

          const attrToUpdate = [otherAttributeOwn, 'create_all'];
          const valuesToUpdate = { [otherAttributeOwn]: newValueOwn, create_all: newValueOwn };

          if (newValueOwn === true) {
            attrToUpdate.push('read_all');
            valuesToUpdate['read_all'] = true;
          }

          this.updateShiftplanPermissions(this.tentativeProfile.permissions['shift-plan-template'], attrToUpdate, valuesToUpdate);
          break;
        case 'edit_all':
        case 'delete_all':
          const otherAttributeAll = differentAttribute === 'edit_all' ? 'delete_all' : 'edit_all';
          const newValueAll = this.tentativeProfile.permissions['shift-plan-template'][differentAttribute] === true;
          if (newValueAll) {
            this.updateShiftplanPermissions(
              this.tentativeProfile.permissions['shift-plan-template'],
              [otherAttributeAll, 'create_all', 'read_all', 'edit_own', 'delete_own'],
              { [otherAttributeAll]: newValueAll, create_all: true, read_all: true, edit_own: true, delete_own: true }
            );
          } else {
            this.updateShiftplanPermissions(
              this.tentativeProfile.permissions['shift-plan-template'],
              [otherAttributeAll, 'edit_own', 'delete_own'],
              { [otherAttributeAll]: newValueAll, edit_own: true, delete_own: true }
            );
          }
          break;
        default:
          break;
      }
      return;
    }

    //Shiftplan collections changing
    const newPermissionValue = this.tentativeProfile.permissions[permissionKey][differentAttribute];
    const visibility = differentAttribute.endsWith('all') && !differentAttribute.startsWith('create') ? 'all' : 'own';

    this.tentativeProfile.permissions[permissionKey][`delete_${visibility}`] = newPermissionValue;
    this.tentativeProfile.permissions[permissionKey][`edit_${visibility}`] = newPermissionValue;
    this.tentativeProfile.permissions[permissionKey][`create_all`] = newPermissionValue;

    if (visibility === 'all') {
      this.updateShiftplanPermissions(this.tentativeProfile.permissions[permissionKey], ['edit_own', 'delete_own', 'create_all'], {
        edit_own: true,
        delete_own: true,
        create_all: true,
      });
    }

    if (differentAttribute === 'create_all' && !newPermissionValue) {
      this.updateShiftplanPermissions(
        this.tentativeProfile.permissions[permissionKey],
        ['edit_own', 'delete_own', 'edit_all', 'delete_all'],
        { edit_own: false, delete_own: false, edit_all: false, delete_all: false }
      );
    }
  }

  private updateShiftplanPermissions(appPermissions, permissionKeys, values) {
    permissionKeys.forEach((key) => {
      appPermissions[key] = values[key];
    });
  }

  private processShiftplanReadOnly() {
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS = _.cloneDeep(this.READ_ONLY_SHIFTPLAN_PERMISSIONS);

    // Shiftplan 'schedule tab' selected
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].scheduling.c_manageAccessShiftplan_own =
      this.tentativeProfile?.permissions?.['shiftplan-app']?.features?.c_viewScheduleTab ?? false;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].features.c_viewScheduleTab = true;
    const permissions = ['shift-plan-role', 'shift-plan-working-area', 'shift-plan-tag'];
    const actions = ['create_all', 'edit_own', 'edit_all', 'delete_own', 'delete_all'];

    permissions.forEach((permission) => {
      actions.forEach((action) => {
        if (['edit_all', 'delete_all'].includes(action)) {
          const ownPermission = action === 'edit_all' ? 'edit_own' : 'delete_own';
          this.READ_ONLY_SHIFTPLAN_PERMISSIONS[permission][ownPermission] =
            this.tentativeProfile.permissions[permission][action] === true ||
            this.tentativeProfile.permissions['shiftplan-app']?.features?.c_viewSettingsTab === false;
        }
        this.READ_ONLY_SHIFTPLAN_PERMISSIONS[permission][action] =
          this.tentativeProfile.permissions['shiftplan-app']?.features?.c_viewSettingsTab === false;
      });
    });

    //Employee access in personal
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].features.c_editEmployeeProfileShiftplan_own =
      this.tentativeProfile?.permissions?.['shiftplan-app']?.features?.c_editEmployeeProfileShiftplan_all === true;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].features.c_editEmployeeProfileShiftplan_custom =
      this.tentativeProfile?.permissions?.['shiftplan-app']?.features?.c_editEmployeeProfileShiftplan_all === true;

    //Scheduled shifts
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].scheduling.c_manageScheduleShifts_own =
      this.tentativeProfile?.permissions?.['shiftplan-app']?.scheduling?.c_manageScheduleShifts_all === true ||
      this.tentativeProfile?.permissions?.['shiftplan-app']?.scheduling?.c_manageScheduleShifts_custom.length !== 0;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].scheduling.c_manageScheduleShifts_custom =
      this.tentativeProfile?.permissions?.['shiftplan-app']?.scheduling?.c_manageScheduleShifts_all === true;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].scheduling.c_scheduledShiftsViewPublished = true;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].scheduling.c_scheduledShiftsViewUnpublished = ['Create', 'Edit', 'Delete'].some(
      (permission) => this.tentativeProfile?.permissions?.['shiftplan-app']?.scheduling?.[`c_scheduledShifts${permission}`] === true
    );

    //Open shifts
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].scheduling.c_manageOpenShifts_custom =
      this.tentativeProfile?.permissions?.['shiftplan-app']?.scheduling?.c_manageOpenShifts_all === true;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shiftplan-app'].scheduling.c_openShiftsViewUnpublished = ['Create', 'Edit', 'Delete'].some(
      (permission) => this.tentativeProfile?.permissions?.['shiftplan-app']?.scheduling?.[`c_openShifts${permission}`] === true
    );

    //Templates
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shift-plan-template'].read_all =
      this.tentativeProfile?.permissions?.['shift-plan-template']?.create_all === true;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shift-plan-template'].edit_own =
      this.tentativeProfile?.permissions?.['shift-plan-template']?.edit_all === true;
    this.READ_ONLY_SHIFTPLAN_PERMISSIONS['shift-plan-template'].delete_own =
      this.tentativeProfile?.permissions?.['shift-plan-template']?.delete_all === true;
  }

  displayInviteDialog(): void {
    this.injector.get(MatLegacyDialog).open(InAppInviteDialog);
    this.injector.get(PrivateAmplitudeService).logEvent('Invite intent from permissions', { category: 'PLG', subcategory1: 'Invitations' });
  }
}
