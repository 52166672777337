import { ChangeDetectorRef, Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ParamMap } from '@angular/router';
import { TimeOffUserRequestController } from '@app/cloud-features/time-off/services/time-off-user-request.controller';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { IMentionOption } from '@app/standard/components/input-simple-editor/input-simple-editor.component';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { TaskHelperService } from '@app/standard/services/task/task-helper.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as picklistValues from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as _ from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '../../../../components/i18n-data/i18n-data.pipe';
import { DepartmentService } from '../../../../services/company/department.service';
import { AuthenticationService } from '../../../../services/core/authentication.service';
import {
  IMeetingResponseModel,
  IQuestionResponses,
  MeetingResponseService,
} from '../../../../services/performance-management/meeting-response.service';
import { IMeetingModel, MeetingService } from '../../../../services/performance-management/meeting.service';
import { TaskService } from '../../../../services/task/task.service';
import { UserPersonalService } from '../../../../services/user/user-personal.service';
import { GenericPage, ITranslationResource } from '../../../generic.page';
import { AddAgendaItemDialog } from '../dialogs/add-agenda-item-dialog/add-agenda-item.dialog';
import { AddParticipantDialog } from '../dialogs/add-participant-dialog/add-participant.dialog';
import { CreateTemplateDialog } from '../dialogs/create-template-dialog/create-template.dialog';
import { NewMeetingDialog } from '../dialogs/new-meeting-dialog/new-meeting.dialog';

@Component({
  selector: 'orgos-meetings-detail',
  templateUrl: 'meetings-detail.page.html',
  styleUrls: ['meetings-detail.page.scss'],
})
export class MeetingsDetailPage extends GenericPage {
  private meetingId: string;
  meeting: IMeetingModel;
  createdOn: string;
  userPersonalMap: any;
  loggedUser: any;
  progress: number = 0;
  usersAway: any = {};
  responsesMap: any = {};
  participantsWithOneResponse: Array<string> = [];
  myResponses: Array<string> = [];
  dontSave: boolean = false;
  repeatMeeting: boolean = false;
  mentionOptions: Array<IMentionOption> = [];
  userIdToParticipantIndex: any = {}; // To ensure that the responses always have the same order

  mouseHere: number = -1;
  clickHere: number = -1;
  editName: number = -1;
  editResponse: number = -1;

  TYPE_TEXT: string = picklistValues.AGENDA_TYPE_TEXT;
  TYPE_LIST: string = picklistValues.AGENDA_TYPE_LIST_VAL;
  TYPE_TODO: string = picklistValues.AGENDA_TYPE_TODO;
  TYPE_QUESTION: string = picklistValues.AGENDA_TYPE_QUESTION;
  WEEKDAYS_ARRAY = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  translatedWeekdays: Array<string>;
  TODAY: any = moment();
  departments: any = {};
  departmentText: string;
  taskReadOnlyAssignee: boolean = false;

  interval: any;
  allTasks: any = {};

  toolbar: Array<any> = [
    // quill toolbar
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['link'],
  ];
  isAdmin: boolean = false;
  exportingToPdf: boolean = false;

  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'meetings-misc' },
    { name: 'page', translationKey: 'meetings-detail-page' },
    { name: 'picklists', translationKey: 'standard-picklists' },
    { name: 'generalMisc', translationKey: 'misc' },
  ];

  protected beforeInit(): Promise<void> {
    this.translatedWeekdays = this.injector.get(InternationalizationService).getShortTranslatedWeekdays();
    this.globalBarConfig.enableFullScreenMode = true;
    this.globalBarConfig.fullScreenModeUseSameUrl = false;
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        this.meetingId = id;
        const query = { _id: this.meetingId };
        this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
        if (this.loggedUser.profileKey === 'admin') {
          query['internalQuery'] = true;
        }
        this.injector
          .get(MeetingService)
          .find(query)
          .then((meetings: Array<IMeetingModel>) => {
            if (check.not.assigned(meetings)) {
              this.dontSave = true;
              this.router.navigateByUrl('/cloud/meetings');
              return;
            }
            this.meeting = meetings[0];
            this.initIsAdmin();
            return this.refreshData();
          })
          .catch((e) => {
            //
          });
      });

    return Promise.resolve();
  }

  protected destroy(): Promise<void> {
    return Promise.resolve();
  }

  protected fetchLazyData(): void {
    this.injector.get(TaskHelperService).refreshData();
  }

  private async findUsersAway() {
    try {
      const localDate = timeOffHelpers.getLocalDate(this.meeting.startDate);
      const usersAway = await this.injector.get(TimeOffUserRequestController).getUsersAway(localDate);
      this.usersAway = {};
      if (check.nonEmptyArray(usersAway)) {
        usersAway.forEach((userId) => {
          this.usersAway[userId] = true;
        });
      }
    } catch {
      this.usersAway = {};
    }
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function) {
    if (check.not.assigned(this.meetingId) || check.emptyString(this.meetingId)) {
      resolveFetchData();
      return;
    }

    const NUMBER_OF_DATA_TO_FETCH = 3;
    let dataFetched = 0;
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((resultDepartments) => {
        this.departments = _.keyBy(resultDepartments, '_id');
        const query = { _id: this.meetingId };
        if (this.loggedUser.profileKey === 'admin') {
          query['internalQuery'] = true;
        }
        return this.injector.get(MeetingService).find(query);
      })
      .then((meetings: Array<IMeetingModel>) => {
        this.meeting = meetings[0];

        if (check.assigned(this.meeting.participants) && check.nonEmptyArray(this.meeting.participants)) {
          this.userIdToParticipantIndex = this.meeting.participants.reduce((total, iParticipant, index) => {
            total[iParticipant.userId] = index;
            return total;
          }, {});
        }
        this.userIdToParticipantIndex[this.meeting.ownerId] = -1; // owner will be always the first of the responses
        this.prepareDetailInfo();
        this.findUsersAway();
        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.usersAway = [];
        rejectFetchData();
      });

    this.injector
      .get(TaskService)
      .find({ relatedTo: this.meetingId })
      .then((tasks: Array<any>) => {
        const reverseTasks = tasks.reverse();
        this.allTasks = _.keyBy(reverseTasks, '_id');
        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.allTasks = {};
      });

    const taskPermissions = await this.injector.get(TaskService).getPermissions();
    this.taskReadOnlyAssignee = taskPermissions.edit_own && !taskPermissions.edit_all && !taskPermissions.edit_custom;

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, true)
      .then((allUserPersonal: Array<any>) => {
        this.userPersonalMap = _.keyBy(allUserPersonal, '_id');

        this.mentionOptions = allUserPersonal.map((iUserPersonal: any) => {
          const mentionOption: IMentionOption = {
            id: iUserPersonal._id,
            value: iUserPersonal.displayName,
            photoUrl: iUserPersonal._photo?._url,
          };

          return mentionOption;
        });

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.userPersonalMap = {};
        rejectFetchData();
      });
  }

  public deleteMeeting(): void {
    const name = this.meeting.name;
    const data = {
      titleText: this.injector
        .get(I18nDataPipe)
        .transform(this.i18n.page.deleteMeetingTitle, { item: this.i18n.page.agendaItem, name: name }),
      subtitleText: this.repeatMeeting ? this.i18n.page.confirmationForScheduledSubtitle : this.i18n.page.confirmationSubtitle,
      confirmButtonText: this.i18n.page.deleteMeeting,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.generalMisc.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector
          .get(MeetingService)
          .deleteById(this.meeting._id)
          .then(() => {
            const mens = this.injector.get(I18nDataPipe).transform(this.i18n.page.elementDeleted, { name: name });
            this.showSnackBar(mens, 'OK', 5000);
            this.dontSave = true;
            this.injector.get(PrivateAmplitudeService).logEvent('update meeting', { category: 'Meetings', type: 'delete' });
            this.router.navigateByUrl('/cloud/meetings');
          })
          .catch(() => {
            // error will be shown
          });
      }
    });
  }

  public editMeeting(): void {
    const meetingToEdit = _.cloneDeep(this.meeting);
    const data: any = {
      meeting: meetingToEdit,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(NewMeetingDialog, { data });
    dialogRef.afterClosed().subscribe((saved) => {
      if (saved === true) {
        this.updateResponses();
        this.injector.get(PrivateAmplitudeService).logEvent('update meeting', { category: 'Meetings', type: 'edit' });
      }
    });
  }

  public loadTemplate(): void {
    const meetingToEdit = _.cloneDeep(this.meeting);
    const data: any = {
      meeting: meetingToEdit,
      isLoadTemplate: true,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(NewMeetingDialog, { data });
    dialogRef.afterClosed().subscribe((saved) => {
      if (saved === true) {
        this.updateResponses();
        this.injector.get(PrivateAmplitudeService).logEvent('update meeting', { category: 'Meetings', type: 'load template' });
      }
    });
  }

  public saveAsTemplate(): void {
    const meetingToEdit = _.cloneDeep(this.meeting);
    const data: any = {
      meeting: meetingToEdit,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateTemplateDialog, { data });
    dialogRef.afterClosed().subscribe((saved) => {
      if (saved === true) {
        this.refreshData();
        const mens = this.i18n.misc.dialogTemplateCreated;
        this.showSnackBar(mens, 'OK', 5000);
        this.injector.get(PrivateAmplitudeService).logEvent('update meeting', { category: 'Meetings', type: 'save as template' });
      }
    });
  }

  // Participants controls
  public addParticipant(): void {
    const meetingToChange = {
      participants: this.meeting.participants,
      _id: this.meeting._id,
    };
    const data: any = {
      meeting: meetingToChange,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddParticipantDialog, { data });
    dialogRef.afterClosed().subscribe((saved) => {
      if (saved === true) {
        this.updateResponses();
        this.injector.get(PrivateAmplitudeService).logEvent('update meeting', { category: 'Meetings', type: 'add participant' });
      }
    });
  }

  public deleteParticipant(userId: string): void {
    if (check.not.assigned(userId) || check.emptyString(userId) || this.participantsWithOneResponse.includes(userId)) {
      return;
    }

    const data = {
      titleText: this.injector
        .get(I18nDataPipe)
        .transform(this.i18n.page.deleteTitle, { item: this.i18n.page.participant, name: this.userPersonalMap[userId].displayName }),
      confirmButtonText: this.i18n.page.deleteParticipant,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.generalMisc.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        const participant = _.remove(this.meeting.participants, (iParticipant) => {
          return iParticipant.userId === userId;
        });

        if (check.assigned(participant) || check.nonEmptyObject(participant)) {
          const meetingToUpdate = {
            participants: this.meeting.participants,
          };
          this.updateMeetingData(meetingToUpdate)
            .then(() => {
              return this.removeResponses(userId);
            })
            .then(() => {
              this.refreshData();
              const mens = this.injector
                .get(I18nDataPipe)
                .transform(this.i18n.page.elementDeleted, { name: this.userPersonalMap[participant[0].userId].displayName });
              this.showSnackBar(mens, 'OK', 5000);
            })
            .catch(() => {
              // error will be shown
            });
        }
      }
    });
  }

  public changeAbsent(absent: boolean, userId: string): void {
    if (
      check.not.assigned(absent) ||
      check.not.assigned(userId) ||
      check.emptyString(userId) ||
      this.meeting.ownerId !== this.loggedUser._id
    ) {
      return;
    }

    this.meeting.participants.forEach((iParticipant) => {
      if (iParticipant.userId === userId) {
        iParticipant.absent = absent;
      }
    });
    const meetingToUpdate = {
      participants: this.meeting.participants,
    };
    this.updateMeetingData(meetingToUpdate)
      .then(() => {
        this.refreshData();
        const mens = this.injector.get(I18nDataPipe).transform(this.i18n.page.participantChanged, {
          name: this.userPersonalMap[userId].displayName,
          action: absent ? this.i18n.page.absent : this.i18n.page.present,
        });
        this.showSnackBar(mens, 'OK', 5000);
      })
      .catch(() => {
        // error will be shown
      });
  }

  public changeOwnership(userId: string): void {
    if (check.not.assigned(userId) || check.emptyString(userId)) {
      return;
    }

    const data = {
      titleText: this.i18n.page.transferTitle,
      subtitleText: this.i18n.page.confirmationSubtitle,
      confirmButtonText: this.i18n.page.transfer,
      cancelButtonText: this.i18n.generalMisc.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        // Make the previous owner as participant of this meeting
        if (check.not.assigned(this.meeting.participants)) {
          this.meeting.participants = [];
        }
        this.meeting.participants.push({
          absent: false,
          userId: this.meeting.ownerId,
        });
        // remove the owner from the list of participants:
        this.meeting.participants = this.meeting.participants.filter((participant) => {
          return participant.userId !== userId;
        });

        this.meeting.ownerId = userId;
        const meetingToUpdate = {
          ownerId: this.meeting.ownerId,
          participants: this.meeting.participants,
        };
        this.updateMeetingData(meetingToUpdate)
          .then(() => {
            this.refreshData();
            const mens = this.i18n.page.ownershipTransferred;
            this.showSnackBar(mens, 'OK', 5000);
          })
          .catch(() => {
            // error will be shown
          });
      }
    });
  }

  // Reminder controls
  public sendReminder(): void {
    if (check.not.assigned(this.meeting._id) || check.emptyString(this.meeting._id)) {
      return;
    }

    const data = {
      titleText: this.i18n.misc.sendReminderPopupHeader,
      confirmButtonText: this.i18n.misc.sendReminderConfirmPopupButton,
      cancelButtonText: this.i18n.misc.sendReminderCancelPopupButton,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector
          .get(MeetingService)
          .sendReminder(this.meeting._id, false)
          .then(() => {
            const mens = this.i18n.misc.reminderHasBeenSent;
            this.showSnackBar(mens, 'OK', 5000);
            this.injector.get(PrivateAmplitudeService).logEvent('update meeting', { category: 'Meetings', type: 'send reminder' });
          })
          .catch(() => {
            // error will be displayed
          });
      }
    });
  }

  // Agenda controls
  public addAgendaItem(): void {
    const meetingToChange = {
      _id: this.meetingId,
      agenda: this.meeting.agenda,
    };
    const data: any = {
      meeting: meetingToChange,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddAgendaItemDialog, { data });
    dialogRef.afterClosed().subscribe((agendaSaved) => {
      if (check.assigned(agendaSaved) && agendaSaved === true) {
        this.updateResponses();
      }
    });
  }

  public editAgendaItem(itemIndex: number): void {
    const meetingToChange = {
      _id: this.meetingId,
      agenda: this.meeting.agenda,
    };

    const data: any = {
      meeting: meetingToChange,
      itemIndex: itemIndex,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddAgendaItemDialog, { data });
    dialogRef.afterClosed().subscribe((saved) => {
      if (saved === true) {
        this.updateResponses();
      }
    });
  }

  public changeCompleteAgendaItem(index: number): void {
    if (
      check.not.assigned(index) ||
      check.not.assigned(this.meeting.agenda) ||
      check.emptyArray(this.meeting.agenda) ||
      check.not.assigned(this.meeting.agenda[index])
    ) {
      return;
    }

    this.getMeetingUpdated()
      .then((updatedMeeting) => {
        updatedMeeting.agenda[index].completed = !updatedMeeting.agenda[index].completed;
        const meetingToUpdate = {
          agenda: updatedMeeting.agenda,
        };
        return this.updateMeetingData(meetingToUpdate);
      })
      .then(() => {
        this.refreshData();
        let mens = '';
        if (this.meeting.agenda[index].completed === true) {
          mens = this.injector.get(I18nDataPipe).transform(this.i18n.page.itemUncompleted, { itemName: this.meeting.agenda[index].name });
        } else {
          mens = this.injector.get(I18nDataPipe).transform(this.i18n.page.itemCompleted, { itemName: this.meeting.agenda[index].name });
        }
        this.showSnackBar(mens, 'OK', 5000);
      })
      .catch(() => {
        // error will be shown
      });
  }

  public deleteAgendaItem(index: number): void {
    if (
      check.not.assigned(index) ||
      check.not.assigned(this.meeting.agenda) ||
      check.emptyArray(this.meeting.agenda) ||
      check.not.assigned(this.meeting.agenda[index])
    ) {
      return;
    }
    const name = this.meeting.agenda[index].name;
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(this.i18n.page.deleteTitle, { item: this.i18n.page.agendaItem, name: name }),
      subtitleText: this.i18n.page.confirmationSubtitle,
      confirmButtonText: this.i18n.page.deleteAgendaItem,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.generalMisc.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        const idItem = this.meeting.agenda[index]._id;
        this.getMeetingUpdated()
          .then((updatedMeeting) => {
            const agendaItem = _.remove(updatedMeeting.agenda, (agendaItem) => {
              return agendaItem._id === idItem;
            });
            const meetingToUpdate = {
              agenda: updatedMeeting.agenda,
            };
            return this.updateMeetingData(meetingToUpdate);
          })
          .then(() => {
            this.refreshData();
            const mens = this.injector.get(I18nDataPipe).transform(this.i18n.page.elementDeleted, { name: name });
            this.showSnackBar(mens, 'OK', 5000);
          })
          .catch(() => {
            // error will be shown
          });
      }
    });
  }

  public saveAgendaOrder(): void {
    if (this.meeting.ownerId !== this.loggedUser._id) {
      this.refreshData();
      return;
    }

    this.getMeetingUpdated()
      .then((updatedMeeting) => {
        const mapItems = _.keyBy(updatedMeeting.agenda, '_id');
        const newAgenda = this.meeting.agenda.map((item) => {
          item.content = mapItems[item._id].content;
          return item;
        });

        const meetingToUpdate = {
          agenda: newAgenda,
        };
        return this.updateMeetingData(meetingToUpdate);
      })
      .then(() => {
        this.refreshData();
      })
      .catch(() => {
        // error will be shown
      });
  }

  // detail changes CONTROLS
  // TYPE =>  TEXT
  public changeTextElement(index: any): void {
    this.clickHere = -1;
    this.mouseHere = -1;
    this.updateAgendaItem(index)
      .then(() => {
        this.showSnackBar(this.i18n.page.textUpdated, 'OK', 5000);
        this.refreshData();
      })
      .catch(() => {
        // error will be shown
      });
  }

  public async changeIndexAndSaveText(newIndex: any): Promise<void> {
    try {
      if (this.clickHere !== -1 && this.meeting.agenda[this.clickHere]?.type === 'text') {
        await this.updateAgendaItem(this.clickHere);
        this.showSnackBar(this.i18n.page.textUpdated, 'OK', 5000);
        this.refreshData();
      }
    } catch {
      // error will be shown
    } finally {
      this.clickHere = newIndex;
    }
  }

  // TYPE => TODOS
  public trackTasks(task: any): string {
    return task._id;
  }

  public createTask(indexElement: number): void {
    const emptyTask = {
      title: '',
      relatedTo: this.meeting._id,
    };

    let idTask;
    this.injector
      .get(TaskService)
      .create(emptyTask)
      .then((createdTask: any) => {
        idTask = createdTask._id;
        return this.getMeetingUpdated();
      })
      .then((updatedMeeting) => {
        if (check.not.assigned(updatedMeeting) || check.emptyObject(updatedMeeting)) {
          return;
        }
        const element = this.meeting.agenda[indexElement];
        const newAgenda = updatedMeeting.agenda.map((updateAgendaItem) => {
          if (updateAgendaItem._id === element._id) {
            updateAgendaItem.content = element.content;
            if (check.not.assigned(updateAgendaItem.content) || check.emptyArray(updateAgendaItem.content)) {
              updateAgendaItem.content = [idTask];
            } else {
              updateAgendaItem.content = [idTask].concat(updateAgendaItem.content);
            }
          }
          return updateAgendaItem;
        });

        const meetingToUpdate = {
          agenda: newAgenda,
        };
        return this.updateMeetingData(meetingToUpdate);
      })
      .then(() => {
        this.showSnackBar(this.i18n.page.toDoCreate, 'OK', 5000);
        this.refreshData();
      })
      .catch(() => {
        // An error is already shown
      });
  }

  public onTaskDeleted(indexElement: number, indexOfTaskDeleted: number): void {
    this.meeting.agenda[indexElement].content.splice(indexOfTaskDeleted, 1);
    this.getMeetingUpdated()
      .then((updatedMeeting) => {
        if (check.not.assigned(updatedMeeting) || check.emptyObject(updatedMeeting)) {
          return;
        }
        const element = this.meeting.agenda[indexElement];
        const idTask = this.meeting.agenda[indexElement][indexOfTaskDeleted];
        const newAgenda = updatedMeeting.agenda.map((updateAgendaItem) => {
          if (updateAgendaItem._id === element._id) {
            updateAgendaItem.content = element.content;
            _.remove(updateAgendaItem.content, (task) => {
              return task === idTask;
            });
          }
          return updateAgendaItem;
        });
        const meetingToUpdate = {
          agenda: newAgenda,
        };
        return this.updateMeetingData(meetingToUpdate);
      })
      .then(() => {
        this.showSnackBar(this.i18n.page.textUpdated, 'OK', 5000);
        this.refreshData();
      })
      .catch(() => {
        // error will be shown
      });
  }

  // TYPE => LISTS
  public deleteListItem(indexElement: number, index: number): void {
    if (
      check.not.assigned(indexElement) ||
      check.not.assigned(this.meeting.agenda) ||
      check.emptyArray(this.meeting.agenda) ||
      check.not.assigned(this.meeting.agenda[indexElement])
    ) {
      return;
    }

    if (
      check.not.assigned(index) ||
      check.not.assigned(this.meeting.agenda[indexElement].content) ||
      check.emptyArray(this.meeting.agenda[indexElement].content) ||
      check.not.assigned(this.meeting.agenda[indexElement].content[index])
    ) {
      return;
    }

    const name = this.meeting.agenda[indexElement].content[index].name;
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(this.i18n.page.deleteTitle, { item: this.i18n.page.agendaItem, name: name }),
      subtitleText: this.i18n.page.confirmationSubtitle,
      confirmButtonText: this.i18n.page.deleteListItem,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.generalMisc.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.meeting.agenda[indexElement].content.splice(index, 1);
        this.updateAgendaItem(indexElement)
          .then(() => {
            this.refreshData();
            const mens = this.injector.get(I18nDataPipe).transform(this.i18n.page.elementDeleted, { name: name });
            this.showSnackBar(mens, 'OK', 5000);
          })
          .catch(() => {
            // error will be shown
          });
      }
    });
  }

  public saveListOrder(indexElement: number): void {
    if (
      (this.meeting.ownerId !== this.loggedUser._id &&
        (check.not.assigned(this.meeting.agenda[indexElement].participantCanAdd) ||
          this.meeting.agenda[indexElement].participantCanAdd === false)) ||
      this.meeting.agenda[indexElement].completed === true
    ) {
      this.refreshData();
      return;
    }
    this.updateAgendaItem(indexElement)
      .then(() => {})
      .catch(() => {
        // error will be shown
      });
  }

  public changeCompleteListItem(indexElement: number, index: number): void {
    if (
      check.not.assigned(indexElement) ||
      check.not.assigned(this.meeting.agenda) ||
      check.emptyArray(this.meeting.agenda) ||
      check.not.assigned(this.meeting.agenda[indexElement])
    ) {
      return;
    }

    if (
      check.not.assigned(index) ||
      check.not.assigned(this.meeting.agenda[indexElement].content) ||
      check.emptyArray(this.meeting.agenda[indexElement].content) ||
      check.not.assigned(this.meeting.agenda[indexElement].content[index])
    ) {
      return;
    }

    if (
      !(
        (!this.meeting.agenda[indexElement].completed || this.meeting.agenda[indexElement].completed === false) &&
        ((this.meeting.agenda[indexElement].participantCanAdd && this.meeting.agenda[indexElement].participantCanAdd === true) ||
          this.meeting.ownerId === this.loggedUser._id)
      )
    ) {
      return;
    }

    this.meeting.agenda[indexElement].content[index].completed = !this.meeting.agenda[indexElement].content[index].completed;
    this.updateAgendaItem(indexElement)
      .then(() => {
        this.refreshData();
        let mens = '';
        if (this.meeting.agenda[indexElement].content[index].completed === true) {
          mens = this.injector
            .get(I18nDataPipe)
            .transform(this.i18n.page.itemCompleted, { itemName: this.meeting.agenda[indexElement].content[index].name });
        } else {
          mens = this.injector
            .get(I18nDataPipe)
            .transform(this.i18n.page.itemUncompleted, { itemName: this.meeting.agenda[indexElement].content[index].name });
        }
        this.showSnackBar(mens, 'OK', 5000);
      })
      .catch(() => {
        // error will be shown
      });
  }

  public createListItem(indexElement: number): void {
    const listItem = {
      completed: false,
    };

    if (check.not.assigned(this.meeting.agenda[indexElement].content) || check.emptyArray(this.meeting.agenda[indexElement].content)) {
      this.meeting.agenda[indexElement].content = [];
    }
    this.meeting.agenda[indexElement].content = [listItem].concat(this.meeting.agenda[indexElement].content);

    this.updateAgendaItem(indexElement)
      .then(() => {
        this.refreshData();
        this.showSnackBar(this.i18n.page.listItemCreate, 'OK', 5000);
      })
      .catch(() => {
        // error will be shown
      });
  }

  public changeListItem(indexElement: number, index: number, value: any): void {
    if (
      check.not.assigned(indexElement) ||
      check.not.assigned(this.meeting.agenda) ||
      check.emptyArray(this.meeting.agenda) ||
      check.not.assigned(this.meeting.agenda[indexElement])
    ) {
      return;
    }

    if (
      check.not.assigned(index) ||
      check.not.assigned(this.meeting.agenda[indexElement].content) ||
      check.emptyArray(this.meeting.agenda[indexElement].content) ||
      check.not.assigned(this.meeting.agenda[indexElement].content[index])
    ) {
      return;
    }

    this.meeting.agenda[indexElement].content[index].name = value;
    this.updateAgendaItem(indexElement)
      .then(() => {
        //
      })
      .catch(() => {
        // error will be shown
      });
  }

  // TYPE => OPEN QUESTION
  public updateResponse(responseToUpdate: IMeetingResponseModel): void {
    responseToUpdate.replied = true;
    this.injector
      .get(MeetingResponseService)
      .updateById(responseToUpdate._id, responseToUpdate)
      .then(() => {
        this.showSnackBar(this.i18n.page.responseUpdated, 'OK', 5000);
        this.refreshData();
      })
      .catch(() => {
        //
      });
  }

  // Meeting header controls
  public goBack(): void {
    this.router.navigateByUrl('/cloud/meetings', { replaceUrl: true });
    // this.exitFullScreenMode();
  }

  public async exportToPDF(): Promise<void> {
    this.exportingToPdf = !this.exportingToPdf;
    this.injector.get(ChangeDetectorRef).detectChanges();

    const elementId = 'meeting-exportable';
    const meetingName = this.meeting.name;

    try {
      // hide the sections that we don't want to export to PDF
      document.querySelectorAll('.exclude-from-pdf').forEach((el) => {
        el.classList.add('hide-for-pdf');
      });
      document.querySelectorAll('.include-in-pdf').forEach((el) => {
        el.classList.add('show-in-pdf');
      });

      document.getElementById(elementId).style.height = 'auto'; // to be able to render the content out of the current scroll
      const canvas = await html2canvas(document.querySelector(`#${elementId}`)!);

      const pdf = new jsPDF({
        orientation: 'portrait',
      });

      const imgWidth = 208; // Approx A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let position = 0;

      // Add image to PDF
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(meetingName);
    } catch (err) {
      const error = new OrgosError('Export to PDF is not possible', ErrorCodes.CLIENT_ERROR, MeetingsDetailPage.name, 'exportToPDF');
      error.message = 'Export to PDF is not possible';
      this.injector.get(ErrorManagerService).handleParsedError(error);
    } finally {
      document.getElementById(elementId).style.height = 'calc(100vh - 50px)';

      document.querySelectorAll('.exclude-from-pdf').forEach((el) => {
        el.classList.remove('hide-for-pdf');
      });
      document.querySelectorAll('.include-in-pdf').forEach((el) => {
        el.classList.remove('show-in-pdf');
      });
      this.exportingToPdf = false;
    }
  }

  // **************************************************
  // PRIVATE FUNCTIONS
  // **************************************************
  private prepareDetailInfo(): void {
    // does it repeat?
    this.checkIfMeetingRepeats(this.meeting);

    // has department?
    if (
      check.assigned(this.meeting.departmentId) &&
      check.nonEmptyString(this.meeting.departmentId) &&
      check.assigned(this.departments[this.meeting.departmentId])
    ) {
      this.departmentText = this.injector
        .get(I18nDataPipe)
        .transform(this.i18n.page.departmentMeeting, { departmentName: this.departments[this.meeting.departmentId].name });
    }
    // responses
    const calls = [];
    this.meeting.agenda.forEach((agendaItem) => {
      if (agendaItem.type === this.TYPE_QUESTION) {
        calls.push(this.calculateResponses(agendaItem._id));
      }
    });

    Promise.all(calls)
      .then((results) => {
        this.responsesMap = _.keyBy(results, 'questionId');
      })
      .catch(() => {
        //
      });
  }

  private checkIfMeetingRepeats(meeting: IMeetingModel): void {
    this.repeatMeeting = false;
    if (
      check.not.assigned(meeting) ||
      check.not.assigned(meeting.repeatMeeting) ||
      check.not.assigned(meeting.repeatMeeting.weeklyRepetitions) ||
      check.emptyObject(meeting.repeatMeeting.weeklyRepetitions)
    ) {
      return;
    }
    const repeatArray = Object.keys(meeting.repeatMeeting.weeklyRepetitions).filter((iKey) => {
      return meeting.repeatMeeting.weeklyRepetitions[iKey] === true;
    });
    this.repeatMeeting = check.nonEmptyArray(repeatArray);
  }

  private updateMeetingData(meeting: IMeetingModel): Promise<void> {
    return this.injector.get(MeetingService).updateById(this.meeting._id, meeting);
  }

  private showSnackBar(message: string, result: string, time: number): void {
    this.injector.get(MatLegacySnackBar).open(message, result, { duration: time });
  }

  private updateResponses(): void {
    const callsToCreate = [];
    this.injector
      .get(MeetingService)
      .find({ _id: this.meeting._id })
      .then((meeting) => {
        if (check.not.assigned(meeting) || check.emptyArray(meeting)) {
          return;
        }

        const participantsId = meeting[0].participants.map((iParticipant) => {
          return iParticipant.userId;
        });

        participantsId.push(this.meeting.ownerId);

        meeting[0].agenda.forEach((item) => {
          if (item.type === this.TYPE_QUESTION) {
            callsToCreate.push(this.createResponses(item[fieldConstants.ID], participantsId));
          }
        });

        if (check.emptyArray(callsToCreate)) {
          this.refreshData();
          return;
        }

        Promise.all(callsToCreate)
          .then(() => {
            this.refreshData();
          })
          .catch(() => {
            //
          });
      })
      .catch(() => {
        //
      });
  }

  private calculateResponses(questionId: string): Promise<IQuestionResponses> {
    return new Promise<IQuestionResponses>((resolve, reject) => {
      const where = {
        _questionId: questionId,
      };
      const result: IQuestionResponses = {};

      this.injector
        .get(MeetingResponseService)
        .find(where)
        .then((responses) => {
          responses.sort((a: IMeetingResponseModel, b: IMeetingResponseModel) => {
            // ensure that the responses appear on the same order as the participants
            const aIndex = this.userIdToParticipantIndex[a._receiverId];
            const bIndex = this.userIdToParticipantIndex[b._receiverId];
            if (aIndex < bIndex) {
              return -1;
            }
            return 1;
          });
          result.total = responses.length;
          result.responded = 0;
          result.responses = [];
          result.questionId = questionId;

          responses.forEach((response) => {
            if (response.replied && response.replied === true) {
              result.responded++;
              if (!this.participantsWithOneResponse.includes(response._receiverId)) {
                this.participantsWithOneResponse.push(response._receiverId);
              }
              if (response._receiverId === this.loggedUser._id) {
                this.myResponses.push(response._questionId);
              }
            }
            if (
              check.assigned(response[`${fieldConstants.READ_ONLY_PREFIX}receiverId`]) &&
              response[`${fieldConstants.READ_ONLY_PREFIX}receiverId`] === this.loggedUser._id
            ) {
              result.responses = [response].concat(result.responses);
            } else {
              result.responses.push(response);
            }
          });
          resolve(result);
        })
        .catch((e) => {
          reject(e);
          return;
        });
    });
  }

  private createResponses(questionId: string, users: Array<string>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const where = {
        _questionId: questionId,
      };

      this.injector
        .get(MeetingResponseService)
        .find(where)
        .then((responses) => {
          const mapIdWithQuestion = responses.map((iResponse) => {
            return iResponse[`${fieldConstants.READ_ONLY_PREFIX}receiverId`];
          });

          const toCreate = [];
          users.forEach((iParticipant) => {
            if (check.not.assigned(mapIdWithQuestion) || check.emptyArray(mapIdWithQuestion) || !mapIdWithQuestion.includes(iParticipant)) {
              const response = {
                replied: false,
              };
              response[`${fieldConstants.READ_ONLY_PREFIX}receiverId`] = iParticipant;
              response[`${fieldConstants.READ_ONLY_PREFIX}meetingId`] = this.meeting[fieldConstants.ID];
              response[`${fieldConstants.READ_ONLY_PREFIX}questionId`] = questionId;
              toCreate.push(this.injector.get(MeetingResponseService).create(response));
            }
          });
          if (check.assigned(toCreate) && check.nonEmptyArray(toCreate)) {
            return Promise.all(toCreate);
          } else {
            resolve();
            return;
          }
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
          return;
        });
    });
  }

  private removeResponses(userId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const questionIds = [];
      this.meeting.agenda.forEach((item) => {
        if (item.type === this.TYPE_QUESTION) {
          questionIds.push(item._id);
        }
      });

      const where = {
        _receiverId: userId,
        _questionId: { $in: questionIds },
      };

      this.injector
        .get(MeetingResponseService)
        .find(where)
        .then((responses) => {
          const callsToRemove = responses.map((iResponse) => {
            return this.injector.get(MeetingResponseService).deleteById(iResponse._id);
          });

          if (check.assigned(callsToRemove) && check.nonEmptyArray(callsToRemove)) {
            return Promise.all(callsToRemove);
          } else {
            resolve();
            return;
          }
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
          return;
        });
    });
  }

  private getMeetingUpdated(): Promise<IMeetingModel> {
    return new Promise<IMeetingModel>((resolve, reject) => {
      this.injector
        .get(MeetingService)
        .getById(this.meetingId)
        .then((meeting: IMeetingModel) => {
          resolve(meeting);
        })
        .catch((e) => {
          //
        });
    });
  }

  private updateAgendaItem(index: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getMeetingUpdated()
        .then((updatedMeeting) => {
          if (check.not.assigned(updatedMeeting) || check.emptyObject(updatedMeeting)) {
            return;
          }
          const element = this.meeting.agenda[index];
          const newAgenda = updatedMeeting.agenda.map((updateAgendaItem) => {
            if (updateAgendaItem._id === element._id) {
              updateAgendaItem.content = element.content;
            }
            return updateAgendaItem;
          });
          const meetingToUpdate = {
            agenda: newAgenda,
          };
          return this.updateMeetingData(meetingToUpdate);
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  private initIsAdmin(): void {
    const userProfile = this.injector.get(AuthenticationService).getLoggedUser().profile;
    this.isAdmin = userProfile?._isAdmin === true;
  }
}