import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import {
  IShiftPlanGeneralSettingsModel,
  ShiftPlanGeneralSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-settings-shift-plan-notifications',
  templateUrl: 'settings-shift-plan-notifications.html',
  styleUrls: ['settings-shift-plan-notifications.scss'],
})
export class SettingsShiftPlanNotifications implements OnInit {
  i18n: any = {};
  generalSettingsId: string;
  settingPermissions: { [key: string]: boolean };

  @Input() parentTranslation: any = {};
  @Input() generalSettings: IShiftPlanGeneralSettingsModel;

  @Output() updatedSettings: EventEmitter<IShiftPlanGeneralSettingsModel> = new EventEmitter<IShiftPlanGeneralSettingsModel>();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      this.i18n.page = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-general');
      this.settingPermissions = await this.injector
        .get(ShiftPlanPermissionsService)
        .getShiftplPermissionsByCollection('shift-plan-general-settings');
      this.generalSettingsId = this.generalSettings._id;
    } catch {
      this.i18n.page = {};
    }
  }

  public async updateNotification(value: boolean): Promise<void> {
    try {
      this.generalSettings.sendNotifications = value;
      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { sendNotifications: value });
      this.showSnackbar();
      this.injector.get(PrivateAmplitudeService).logEvent(value ? 'activate shift notification' : 'deactivate shift notification', {
        category: 'Shiftplan',
        subcategory: 'Settings',
        subcategory2: 'Company setup',
      });
      this.injector.get(PrivateChurnzeroService).logSimpleEvent('SHIFTPLAN_MOBILE_NOTIFICATIONS_ACTIVATED');
      this.updatedSettings.emit(this.generalSettings);
    } catch {
      // do nothing
    }
  }

  public showSnackbar(): void {
    const snackBarMessage = this.i18n.page.shiftSnackbarMessage;
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }
}
