import { ErrorHandler, LOCALE_ID } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { Routes } from '@angular/router';
import { UppyDialogsModule } from '@app/common-dialogs/uppy/uppy-dialogs.module';
import { CoreFeaturesRoutes } from '@app/core-features/core-features.routes';
import { INTERCEPTORS } from '@app/core/interceptors/interceptors.config';
import { PrivateUnexpectedErrorHandler } from '@app/private/services/private-unexpected-error-handler.service';
import { StandardPagesModule } from '@app/standard/pages/standard-pages.module';
import { standardServices } from '@app/standard/services/standard-services.registry';

export const OtherOptions: MatTooltipDefaultOptions = {
  ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
  disableTooltipInteractivity: true,
};

export const EXTERNAL_MODULES = [StandardPagesModule, UppyDialogsModule];

export const PROVIDERS = [
  ...standardServices,
  { provide: LOCALE_ID, useValue: 'kenjo-locale' },
  { provide: ErrorHandler, useClass: PrivateUnexpectedErrorHandler },
  { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions },
  ...INTERCEPTORS,
];

export const ROUTES: Routes = [...CoreFeaturesRoutes, { path: '**', redirectTo: 'signin', pathMatch: 'full' }];
