<div *ngIf="!allActivity" class="kenjo-text-align-center kenjo-pv-40px">
  <orgos-loading-spinner></orgos-loading-spinner>
</div>
<div *ngIf="allActivity">
  <div *ngFor="let iActivity of allActivity | limitList:listLimiter.limit; trackBy: trackActivities; let i = index"
    class="rawc-activity kenjo-border kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-radius kenjo-mb-20px kenjo-p-20px">
    <orgos-column-container [centerColumns]="true">
      <orgos-column size="0">
        <kenjo-icon [size]="20" [style.color]="iActivity.iconColor ? iActivity.iconColor : '#757575'">{{ iActivity.icon }}
        </kenjo-icon>
      </orgos-column>
      <orgos-column size="1" class="kenjo-ph-10px">
        <a *ngIf="iActivity._candidateId" (click)="goToCandidate(iActivity._candidateId, iActivity._positionId)">
          {{iActivity.mainInfo }}
        </a>
        <div *ngIf="!iActivity._candidateId">{{ iActivity.mainInfo }}</div>
      </orgos-column>
      <orgos-column size="0">
        <div class="kenjo-font-color-light-text-757575">{{ iActivity.date | humanDate: false:'short' }}</div>
      </orgos-column>
      <orgos-column *ngIf="iActivity._createdById === currentUserId" size="0">
        <kenjo-icon size="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer kenjo-mv-5px">
          more_vert
        </kenjo-icon>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="editComment(i)">
            <span>{{ i18n?.activity?.editComment }}</span>
          </button>
          <button mat-menu-item (click)="deleteComment(iActivity._id)">
            <span>{{ i18n?.activity?.deleteComment }}</span>
          </button>
        </mat-menu>
      </orgos-column>
    </orgos-column-container>
    <div class="kenjo-mt-10px" *ngIf="iActivity.additionalInfoTitle || iActivity.additionalInfoHtml || iActivity.additionalInfoText">
      <div *ngIf="iActivity.additionalInfoTitle" class="kenjo-p-10px rawc-additional-info-title kenjo-font-size-12px">
        {{ iActivity.additionalInfoTitle }}
      </div>
      <orgos-editor-viewer *ngIf="iActivity.additionalInfoHtml && allowEditComments === false" [editorContent]="{ html: iActivity.additionalInfoHtml }" class="kenjo-p-10px"></orgos-editor-viewer>
      <orgos-comment-editor *ngIf="iActivity.additionalInfoHtml && allowEditComments === true" [commentOwnerId]="iActivity._createdById" [editorContent]="iActivity.comment" (updateComment)="saveComment($event, iActivity._id, iActivity.isPrivate)" class="kenjo-ph-10px kenjo-pt-10px"></orgos-comment-editor>
      <div *ngIf="!iActivity.additionalInfoHtml && iActivity.additionalInfoText" class="kenjo-p-10px kenjo-font-size-12px">{{ iActivity.additionalInfoText }}</div>
    </div>
  </div>
  <kenjo-see-more-button *ngIf="limitList && listLimiter.limit < allActivity.length" (seeMore)="listLimiter.showMore()"></kenjo-see-more-button>
</div>