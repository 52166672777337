import { Component, Injector, Input, OnInit } from '@angular/core';
import { ISelectCategoryItem } from '@app/standard/components/select-dropdown-custom/select-dropdown-custom.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-select-item-category',
  templateUrl: './select-item-category.component.html',
  styleUrls: ['./select-item-category.component.scss'],
})
export class SelectItemCategoryComponent implements OnInit {
  @Input() option: ISelectCategoryItem;
  translations: any;
  colors: any = userColorConstants;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  private async getTranslations(): Promise<void> {
    this.translations = await this.injector
      .get(InternationalizationService)
      .getAllTranslation('add-employee-dialog');
  }
}
