<mat-form-field *ngIf="model && field && !readOnly">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    #input="matInput"
    [id]="id"
    [ngModel]="getValue()"
    (ngModelChange)="setValue($event)"
    [required]="required"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [placeholder]="placeholder"
    autocomplete="off"
  />
  <div *ngIf="prefix && (input.focused || !input.empty)" matPrefix>{{ prefix }}</div>
</mat-form-field>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label [class.kenjo-ml-10px]="leftDot && !getValue()">{{ label }}</mat-label>
  <div *ngIf="leftDot" class="itc-circle-mini-container">
    <div class="itc-circle-mini"></div>
    <input
      matInput
      #input="matInput"
      [id]="id"
      [ngModel]="getValue()"
      readOnly
      disabled
      [placeholder]="placeholder"
      [required]="required"
      autocomplete="off"
    />
  </div>
  <input
    *ngIf="!leftDot"
    matInput
    #input="matInput"
    [id]="id"
    [ngModel]="getValue()"
    readOnly
    disabled
    [required]="required"
    autocomplete="off"
  />
  <div *ngIf="prefix && input && (input.focused || !input.empty)" matPrefix>{{ prefix }}</div>
</mat-form-field>
<div *ngIf="!isValueValid" class="itc-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
