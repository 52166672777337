import { OverlayConfig } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IUserShiftplanWorkSchedules } from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { InputSimpleSearchUserComponent } from '@app/standard/components/input-simple-search-user/input-simple-search-user.component';
import { SHIFT_PLAN_CARD_STATUS_PUBLISHED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-input-shiftplan-search-user',
  templateUrl: 'input-shiftplan-search-user.component.html',
  styleUrls: ['input-shiftplan-search-user.component.scss']
})
export class InputShiftPlanSearchUserComponent extends InputSimpleSearchUserComponent implements OnInit, AfterViewInit {
  @Input() employeeSchedules: IUserShiftplanWorkSchedules;
  @Input() notMatchedEntities = {};

  @Input() pageTranslation: any = {};

  @Input() selectedEmployees = [];
  @Input() isSameWeek = true;
  @Input() componentIds: { [key: string]: string } = {};
  @Input() selectedData;
  @Input() isAssignShift: boolean;

  @Output() selectedEmployeesChange = new EventEmitter();

  @ViewChild('isucInput') searchInput;

  matchingEmployees = [];
  searchMatchingResults = [];
  allEmployees = [];
  isOpenShift = false;
  MAX_EMPLOYEE_NUMBER: number = 15;
  daysInWeekList = [...Array(7).keys()];
  timeoutEnableSearch: NodeJS.Timeout;

  shiftplanPermissions: IShiftPlanPermissions;
  showInterestedEmployees: boolean;

  @Input()
  set users(users) {
    this.allEmployees = this.sortEmployees(users);
  }

  @Input()
  set matchingUsers(users) {
    this.matchingEmployees = this.sortEmployees(users);
  }

  private sortEmployees(users) {
    if (check.not.assigned(users)) {
      return [];
    }

    return users.slice().sort((userA, userB) => {
      const nameA = userA.displayName.toLowerCase();
      const nameB = userB.displayName.toLowerCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  get users() {
    return this.allEmployees;
  }

  @Input() defaultEmployeeId: string;
  @Input() editMode: boolean = false;
  @Output() openShift = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter();
  @Output() maxEmployees = new EventEmitter<boolean>();

  ngOnInit(): void {
    super.ngOnInit();
    this.getPermissions();
    this.checkInterestedEmployees();
  }

  async getPermissions() {
    this.shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
  }

  ngAfterViewInit(): void {
    if (this.defaultEmployeeId) {
      this.setValue(this.defaultEmployeeId);
    }

    if (this.isAssignShift) {
      this.timeoutEnableSearch = setTimeout(() => {
        this.enableSearching();
      }, 50);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutEnableSearch);
  }

  setValue(_id: string) {
    if (this.readOnly === true) {
      return;
    }
    super.setValue(_id);
    if (_id === 'open-shift') {
      this.isOpenShift = true;
      this.selectedEmployees = [];
    } else {
      this.isOpenShift = false;
      const name = this.allEmployees.find((employee) => employee._id === _id)?.displayName || this.matchingEmployees.find((employee) => employee._id === _id)?.displayName;
      if (this.selectedEmployees.length + 1 <= this.MAX_EMPLOYEE_NUMBER) {
        this.selectedEmployees = this.editMode === true ? [{ name, _id }] : [...this.selectedEmployees, { name, _id }];
      }
      if (this.selectedEmployees.length > 0) {
        this.searchInput.nativeElement.focus();
      }
    }
    this.searchTerm = '';
    this.openShift.emit(this.isOpenShift);
    this.selectedEmployeesChange.emit(this.selectedEmployees);
    this.maxEmployeeEvent();
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  remove(employee, event) {
    event.stopPropagation();
    const index = this.selectedEmployees.findIndex((selectedEmployee) => selectedEmployee._id === employee._id);
    this.selectedEmployees.splice(index, 1);
    this.selectedEmployeesChange.emit(this.selectedEmployees);
    this.maxEmployeeEvent();
  }

  refreshSearchResults() {
    this.searchResults = this.allEmployees.filter((employee) => {
      if (this.selectedEmployees.find((selectedEmployee) => selectedEmployee._id === employee._id)) return false;
      return employee.displayName.toLowerCase().includes(this.searchTerm.toLocaleLowerCase());
    });
    this.searchMatchingResults = this.matchingEmployees.filter((employee) => {
      if (this.selectedEmployees.find((selectedEmployee) => selectedEmployee._id === employee._id)) return false;
      return employee.displayName.toLowerCase().includes(this.searchTerm.toLocaleLowerCase());
    });
  }

  blurEvent() {
    this.onBlur.emit();
  }

  maxEmployeeEvent() {
    const valueToEmit = this.selectedEmployees.length > this.MAX_EMPLOYEE_NUMBER;
    this.maxEmployees.emit(valueToEmit);
  }

  checkInterestedEmployees() {
    this.showInterestedEmployees = this.editMode === true && this.selectedData?.status === SHIFT_PLAN_CARD_STATUS_PUBLISHED && check.assigned(this.selectedData?.applicants);
  }

  enableSearching(): void {
    if (this.selectedEmployees.length >= this.MAX_EMPLOYEE_NUMBER) {
      this.maxEmployees.emit(true);
      return;
    }
    this.isOpenShift = false;
    this.searchInput.nativeElement.focus();
    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.minWidth = this.searchInputOrigin.elementRef.nativeElement.getBoundingClientRect().width;
    config.backdropClass = 'cp-event-details-overlay';
    config.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.searchInputOrigin.elementRef)
      .withPositions([
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
        { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' }
      ])
      .withPush(false);
    super.enableSearching(config);
    this.injector.get(ChangeDetectorRef).detectChanges();
  }
}
