import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ShiftPlanCapacityTimeEntry } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/input-capacity-time-form-control/input-capacity-time-form-control.component';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InputValidationFunction } from '@app/standard/core/validation/input-validation-function';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-shift-plan-input-capacity-time',
  templateUrl: 'input-capacity-time.component.html',
  styleUrls: ['input-capacity-time.component.scss']
})
export class InputCapacityTimeComponent implements OnInit {
  isValueValid: boolean = true;
  inputValue: ShiftPlanCapacityTimeEntry;
  inputStringValue: string;
  showClearButton: boolean = false;
  MAX_TIME_IN_MINUTES: number = 59999;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() enableClearButton: boolean = false;
  @Input()
  set value(value: number) {
    this.inputValue = this.convertToTimeEntry(value);
    this.inputStringValue = this.convertToString(value);
  }
  @Input() forceError: boolean = false;

  @Input() keepOriginalMinWidth: boolean = false;

  @Output() readonly valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() customValidation: InputValidationFunction;

  @Output() readonly validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Output() blurTimeInput: EventEmitter<void> = new EventEmitter<void>();

  @Input() maskPlaceholder: string | null = null;

  @HostBinding('style.width.px') minWidth: number = 90;

  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.makeValidation(this.inputValue);
    this.showClearButton = this.enableClearButton && check.assigned(this.inputValue) && this.inputValue.hour !== '' && this.inputValue.minute !== '';
    this.minWidth = this.enableClearButton && !this.readOnly ? 90 : this.keepOriginalMinWidth ? 90 : 70;
  }

  onValueChange(newValue: ShiftPlanCapacityTimeEntry): void {
    if (check.not.assigned(newValue)) {
      this.inputValue = null;
    } else {
      this.inputValue = newValue;
    }
    this.showClearButton = this.enableClearButton && check.assigned(this.inputValue) && this.inputValue.hour !== '' && this.inputValue.minute !== '';
    this.minWidth = this.enableClearButton && !this.readOnly ? 90 : this.keepOriginalMinWidth ? 90 : 70;

    this.makeValidation(this.inputValue);

    if (this.isValueValid) {
      this.valueChange.emit(this.convertToMinutes(this.inputValue));
    }
  }

  onBlur(): void {
    this.blurTimeInput.emit();
  }

  private makeValidation(valueToValidate: ShiftPlanCapacityTimeEntry): void {
    let valueToValidateInMinutes = null;

    if (check.assigned(valueToValidate) && (valueToValidate.hour === '' || valueToValidate.minute === '')) {
      valueToValidateInMinutes = -1;
    }

    if (check.assigned(valueToValidate) && valueToValidate.hour !== '' && valueToValidate.minute !== '') {
      valueToValidateInMinutes = this.convertToMinutes(valueToValidate);
    }

    const inputValidation = this.validateValue(valueToValidateInMinutes).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.not.number(newValue))) {
      inputValidation.setError('required');
    }

    if (check.assigned(newValue) && (newValue < 0 || newValue > this.MAX_TIME_IN_MINUTES)) {
      inputValidation.setError('timeNotValid');
    }

    if (this.customValidation) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  clearValue(): void {
    this.onValueChange(this.convertToTimeEntry(0));
  }

  convertToMinutes(timeEntryValue: ShiftPlanCapacityTimeEntry): number {
    if (check.not.assigned(timeEntryValue)) {
      return null;
    }

    const hour = timeEntryValue.hour !== '' ? timeEntryValue.hour : '0';
    const minute = timeEntryValue.minute !== '' ? timeEntryValue.minute : '0';

    return parseInt(hour, 10) * 60 + parseInt(minute, 10);
  }

  convertToTimeEntry(minutesValue: number): ShiftPlanCapacityTimeEntry {
    if (check.not.assigned(minutesValue)) {
      return undefined;
    }

    const absValue = Math.abs(minutesValue);
    const hours = Math.floor(absValue / 60);
    const minutes = absValue % 60;

    return new ShiftPlanCapacityTimeEntry(`${this.decimalPipe.transform(hours, '3.0-0')}`, `${this.decimalPipe.transform(minutes, '2.0-0')}`);
  }

  convertToString(minutesValue: number): string {
    if (check.not.assigned(minutesValue)) {
      return undefined;
    }

    const absValue = Math.abs(minutesValue);
    const hours = Math.floor(absValue / 60);
    const minutes = absValue % 60;

    return `${this.decimalPipe.transform(hours, '3.0-0')}:${this.decimalPipe.transform(minutes, '2.0-0')}`;
  }
}
