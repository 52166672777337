import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class CategoryService {
  private CATEGORY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/category-db`;
  private CATEGORY_PERMISSIONS_KEY: string = 'category';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  getResults(categoryName: string, month: number, year: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.CATEGORY_URL}/results/${categoryName}/${month}/${year}`, httpOptions)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CategoryService.name, 'getResults'));
        });
    });
  }

  getResultsLastNDays(categoryName: string, nDays: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.CATEGORY_URL}/result-lastdays/${categoryName}/${nDays}`, httpOptions)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CategoryService.name, 'getResultsLastNDays'));
        });
    });
  }

  getTrend(categoryName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.CATEGORY_URL}/trend/${categoryName}`, httpOptions)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CategoryService.name, 'getTrend'));
        });
    });
  }

  getSummary(categoryName: string, month: number, year: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.CATEGORY_URL}/summary/${categoryName}/${month}/${year}`, httpOptions)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CategoryService.name, 'getSummary'));
        });
    });
  }

  getSummaryLastNDays(categoryName: string, nDays: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.CATEGORY_URL}/summary-lastdays/${categoryName}/${nDays}`, httpOptions)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CategoryService.name, 'getSummaryLastNDays'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.CATEGORY_URL, id)
        .then((document: any) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CategoryService.name, 'getById'));
        });
    });
  }

  getStandardCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.CATEGORY_URL}/standard`, httpOptions)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CategoryService.name, 'getStandardCategories'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.CATEGORY_PERMISSIONS_KEY);
  }
}
