<ng-container *ngIf="profile && userIdToUserPersonal">
  <orgos-column-container class="kenjo-pb-30px" centerColumns="true">
    <orgos-column size="1" class="kenjo-mr-20px kenjo-font-size-18px kenjo-font-color-light-text-757575">
      {{ i18n.profilesLabel }}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{ profile.name }}</span>
    </orgos-column>

    <orgos-column size="0">
      <orgos-action icon="lock_outline" (click)="changeTab(0)" [selected]="tabSelected === 0"> {{ i18n.permissionsTabLabel }}</orgos-action>
    </orgos-column>

    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-action icon="people" (click)="changeTab(1)" [selected]="tabSelected === 1">{{ i18n.membersTabLabel }} </orgos-action>
    </orgos-column>
  </orgos-column-container>

  <orgos-permissions-summary *ngIf="tabSelected === 0" [profile]="profile" (profileUpdated)="emitSave()"> </orgos-permissions-summary>
  <orgos-profile-assignment *ngIf="tabSelected === 1" [profile]="profile" [allUserAccount]="allUserAccount" [userIdToUserPersonal]="userIdToUserPersonal" (profileUpdated)="emitAssignmentsUpdated()"> </orgos-profile-assignment>
</ng-container>
