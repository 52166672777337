import { Component } from '@angular/core';
import {
  IShiftPlanGeneralSettingsModel,
  ShiftPlanGeneralSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { GenericPage, IMenuOption } from '@app/standard/pages/generic.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-settings-shift-plan',
  templateUrl: 'settings-shift-plan.page.html',
  styleUrls: ['settings-shift-plan.page.scss'],
})
export class SettingsShiftPlanPage extends GenericPage {
  dataLoaded: boolean = false;
  pageTranslation: any = {};
  sectionSelected: string = 'users';
  showOvertimeSummary: boolean = false;
  generalSettings: IShiftPlanGeneralSettingsModel;
  shiftplanPermissions: IShiftPlanPermissions;
  myProfile: IUserAccountModel;
  section: string = '';
  showChip: boolean = false;

  protected async fetchData(resolveFetchData: Function): Promise<void> {
    try {
      await this.injector.get(ShiftPlanPermissionsService).refreshPermissions();
      this.shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-page');
      this.myProfile = this.injector.get(AuthenticationService).getLoggedUser();
      const generalSettings = await this.injector.get(ShiftPlanGeneralSettingsService).getGeneralSettings();
      this.generalSettings = generalSettings[0];
      resolveFetchData();
    } catch {
      this.pageTranslation = {};
    }
  }
  protected beforeInit(): Promise<void> {
    this.section = this.route.params['value']?.section;
    const sections = ['users', 'notifications', 'locations', 'working-areas', 'roles', 'tags', 'breaks'];

    if (this.section) {
      this.sectionSelected = sections.find((value) => this.section.includes(value));
      if (!this.sectionSelected) {
        this.sectionSelected = 'users';
        this.section = this.sectionSelected;
      }
    } else {
      this.sectionSelected = 'users';
    }
    const creationDate = new Date('2024-07-24');
    this.showChip = this.shouldShowChip(creationDate);
    return Promise.resolve();
  }

  private shouldShowChip(creationDate: Date): boolean {
    const currentDate = new Date();
    const sixMonthsInMillis = 6 * 30 * 24 * 60 * 60 * 1000;
    return currentDate.getTime() - creationDate.getTime() < sixMonthsInMillis;
  }

  protected async configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.pageTranslation.pageName;

    const options: Array<IMenuOption> = [];
    if (this.shiftplanPermissions['view_schedule']) {
      options.push({
        key: 'shiftplan-schedules',
        name: this.pageTranslation.schedulesTab,
        onClick: () => this.router.navigateByUrl('/cloud/shift-plan/schedules'),
      });
    }

    if (this.shiftplanPermissions['view_settings']) {
      options.push({
        key: 'shiftplan-settings',
        name: this.pageTranslation.settingsTab,
        onClick: () => this.router.navigateByUrl(`/cloud/shift-plan/settings/${this.section}`),
      });
    }
    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 1;
    return Promise.resolve();
  }

  public changeSection(chosenSection: string): void {
    this.sectionSelected = chosenSection;
  }

  public goBack(): void {
    this.router.navigate(['/cloud/shift-plan/settings']);
  }

  protected destroy(): Promise<void> {
    this.injector.get(ShiftPlanPermissionsService).disposePermissions();
    return Promise.resolve();
  }

  public logAmplitudeEvent(): void {
    this.injector.get(PrivateAmplitudeService).logEvent('navigated to availability settings', {
      category: 'Shiftplan',
      subcategory: 'Availability',
      subcategory2: 'Availability navigation desktop',
    });
  }
}
