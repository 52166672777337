import { animate, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import * as check from 'check-types';
import * as moment from 'moment';

import { GenericModel } from '../../core/generic-model';
import { InternationalizationService } from '../../services/core/internationalization.service';
import { TableColumnDirective } from './table-column.directive';

@Component({
  selector: 'orgos-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInSetup', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', background: '#fdfdfd' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)', background: 'inherit' })),
      ]),
    ]),
  ],
})
export class TableComponent implements OnInit, AfterViewInit {
  miscTranslation: any = {};
  tableInitialized: boolean = false;

  private PX_PER_COLUMN = 200;
  dataSource: MatLegacyTableDataSource<any> = new MatLegacyTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  @Input() emptyStateMessage: string;
  @Input() customPageSize: number;
  @Input() tooltipExcludeRows: string;

  @Input() tooltipPosition: 'above' | 'below' | 'left' | 'right' | 'before' | 'after' = 'before';

  // Bind component selection to changes in the parent selection
  @Input()
  set selectedList(selection: Array<any>) {
    this.selection = new SelectionModel<any>(true, selection);
  }

  private initialData: Array<any> = [];
  @Input()
  set data(data: Array<any>) {
    this.selection = new SelectionModel<any>(true, []);
    this.selection.clear();
    this.maxDynamicHeight = this.fullHeight === false ? `calc(100vh - ${this.customHeightToSubstract}px)` : '';

    if (this.tableInitialized === true) {
      this.initialData = null;
      this.dataSource.data = data;
    } else {
      this.initialData = data;
    }
  }

  defaultFilterPredicate: (row: any, filter: string) => boolean = (row: any, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const accumulator = (currentTerm, key) => {
      if (check.instanceStrict(row, GenericModel)) {
        return `${currentTerm}${row.data[key]}`;
      } else {
        return `${currentTerm}${row[key]}`;
      }
    };

    let dataKeys;
    if (check.instanceStrict(row, GenericModel)) {
      dataKeys = Object.keys(row.data);
    } else {
      dataKeys = Object.keys(row);
    }

    const dataStr = dataKeys.reduce(accumulator, '').toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) !== -1;
  };

  private _customFilterPredicate: (row: any, filter: string) => boolean;
  @Input()
  set filterPredicate(filterPredicate: (row: any, filter: string) => boolean) {
    this._customFilterPredicate = filterPredicate;
    this.dataSource.filterPredicate = filterPredicate;
  }
  get filterPredicate(): (row: any, filter: string) => boolean {
    if (check.not.assigned(this._customFilterPredicate)) {
      return this.defaultFilterPredicate;
    }

    return this._customFilterPredicate;
  }

  defaultSortingDataAccessor: (row: any, sortHeaderId: string) => string | number = (row: any, sortHeaderId: string): string | number => {
    let value: any;
    if (check.instanceStrict(row, GenericModel)) {
      value = row.data[sortHeaderId];
    } else {
      value = row[sortHeaderId];
    }
    if (check.not.assigned(value) || check.emptyString(value)) {
      return '';
    }

    if (isNaN(+value) === false) {
      return +value;
    }

    const isoDate = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true);
    if (isoDate.isValid()) {
      return isoDate.valueOf();
    }

    const localizedShortDate = moment.utc(value, 'DD-MM-YYYY');
    if (localizedShortDate.isValid()) {
      return localizedShortDate.format('YYYYMMDD');
    }

    const localizedShortDateUsa = moment.utc(value, 'MM-DD-YYYY');
    if (localizedShortDateUsa.isValid()) {
      return localizedShortDateUsa.format('YYYYMMDD');
    }

    // If the value is a string and only whitespace, return the value.
    // Otherwise +value will convert it to 0.
    if (typeof value === 'string' && check.nonEmptyString(value.trim())) {
      return value.toLowerCase();
    }

    return value.toString().toLowerCase();
  };

  private _customSortingDataAccessor: (data: any, sortColumnKey: string) => string | number;
  @Input()
  set sortingDataAccessor(sortingDataAccessor: (data: any, sortColumnKey: string) => string | number) {
    this._customSortingDataAccessor = sortingDataAccessor;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }
  get sortingDataAccessor(): (data: any, sortColumnKey: string) => string | number {
    if (check.not.assigned(this._customSortingDataAccessor)) {
      return this.defaultSortingDataAccessor;
    }

    return this._customSortingDataAccessor;
  }

  @Input() fullHeight: boolean = false;
  @Input() customHeightToSubstract: string = '210';
  @Input() numberOfStickyColumns: number = 0;
  maxDynamicHeight: string;

  @Input() manageColumns: boolean = false;
  @Input() totalColumns: Array<string> = [];
  @Input() displayedColumns: Array<string> = [];
  @Input() headerLabels: IHeader;
  @Output() changeColumns: EventEmitter<any> = new EventEmitter<any>();

  @Output() mouseEnterInRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() mouseLeaveInRow: EventEmitter<any> = new EventEmitter<any>();

  viewManageColumns: boolean = false;
  omManageIcon: boolean = false;
  inColumnsSetup: boolean = false;
  columnsControl: Array<IColumn> = [];
  actionColumns: Array<string> = [];

  @Input() stickyRow: boolean = true;
  @Input() stickyColumn: boolean = true;
  @Input() stickyLastColumn: boolean = false;
  @Input() centerContent: boolean = false;
  @Input() maxHeight: string = '100%';
  @Input() selectable: boolean = false;
  @Output() changeSelection: EventEmitter<any> = new EventEmitter<any>();

  private _tableWidth: string | 'dynamic' = '100%';
  @Input()
  set tableWidth(tableWidth: string | 'dynamic') {
    this._tableWidth = tableWidth;
  }
  get tableWidth(): string {
    if (this._tableWidth === 'dynamic') {
      return `${this.PX_PER_COLUMN * this.displayedColumns.length}px`;
    }

    return this._tableWidth;
  }

  @Input() hidePagination: boolean = false;
  @Input() disableRowHighlight: boolean = false;

  @Input() enableCustomSorting: boolean = false;
  @Output() customSortTable: EventEmitter<any> = new EventEmitter<any>();
  @Input() customSortBy: string;
  @Input() customSortOrder: string;

  @Input()
  set inheritBackground(inheritBackground: boolean | 'true' | 'false') {
    if (inheritBackground === true || inheritBackground === 'true') {
      this.backgroundStyle = 'inherit';
    } else {
      this.backgroundStyle = 'white';
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  private sourceColumns: Array<TableColumnDirective>;
  private _tableColumns: Array<TableColumnDirective>;
  @ContentChildren(TableColumnDirective)
  set tableColumns(tableColumns: Array<TableColumnDirective>) {
    if (check.not.assigned(this.displayedColumns) || check.emptyArray(this.displayedColumns)) {
      this.displayedColumns = this.totalColumns;
    }
    this.sourceColumns = tableColumns;
    this.sourceColumns.forEach((iColumn) => {
      if (iColumn.actionColumn === true) {
        this.actionColumns.push(iColumn.columnKey);
      }
    });
    this._tableColumns = this.displayedColumns.map((element) => {
      return tableColumns.find((iColumn) => {
        return iColumn.columnKey === element;
      });
    });
  }
  get tableColumns(): Array<TableColumnDirective> {
    return this._tableColumns;
  }

  @HostBinding('style.background') backgroundStyle: string = 'white';

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation: any) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    if (check.assigned(this.totalColumns) && check.assigned(this.displayedColumns)) {
      this.columnsControl = this.displayedColumns.map((iColumnKey) => {
        return { columnKey: iColumnKey, visible: true };
      });

      if (check.not.equal(this.totalColumns.length, this.columnsControl.length)) {
        this.totalColumns
          .filter((iColumnKey) => {
            return !this.displayedColumns.includes(iColumnKey);
          })
          .forEach((iMissingHeader) => {
            this.columnsControl.push({ columnKey: iMissingHeader, visible: false });
          });
      }
    }

    this.maxDynamicHeight = this.fullHeight === false ? `calc(100vh - ${this.customHeightToSubstract}px)` : '';
  }

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    if (this.hidePagination === false) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.initialData;
    this.tableInitialized = true;

    this.cdr.detectChanges();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter((row) => !row.excluded).length;
    return numSelected === numRows;
  }

  // It emits selected list once click on the master checkbox
  masterToggle() {
    const allSelected = this.isAllSelected();
    if (allSelected === true) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => {
        if (!row.excluded) {
          this.selection.select(row);
        }
      });
    }
    this.changeSelection.emit(this.selection.selected);
  }

  // It emits selected list once click on each checkbox
  selectCheckbox(row) {
    this.selection.toggle(row);
    this.changeSelection.emit(this.selection.selected);
  }

  public hideManageIcon(): void {
    setTimeout(() => {
      this.viewManageColumns = false;
      this.cdr.detectChanges();
    }, 50);
  }

  public openManageColumns(): void {
    this.inColumnsSetup = true;
  }

  public closeManageColumns(): void {
    this.inColumnsSetup = false;
  }

  public saveColumns(): void {
    this._tableColumns = [];
    this.displayedColumns = [];

    this.columnsControl.forEach((iColumn) => {
      if (iColumn.visible) {
        this.displayedColumns.push(iColumn.columnKey);
        const realColumn = this.sourceColumns.find((iRealColumns) => {
          return iRealColumns.columnKey === iColumn.columnKey;
        });
        this._tableColumns.push(realColumn);
      }
    });

    this.changeColumns.emit(this.displayedColumns);
  }

  public enterInRow(index: number): void {
    this.mouseEnterInRow.emit(index);
  }

  public leaveInRow(index: number): void {
    this.mouseLeaveInRow.emit(index);
  }

  public changeColumn(checked: boolean, index: number): void {
    this.columnsControl[index].visible = checked;
    this.saveColumns();
  }

  public customSortColumn(value: any): void {
    if (this.enableCustomSorting === false) {
      return;
    }
    const sortOptions: any = {};
    if (check.assigned(value.direction) && check.nonEmptyString(value.direction)) {
      sortOptions.sortBy = value.active;
      sortOptions.sortOrder = value.direction;
    }
    this.customSortTable.emit(sortOptions);
  }
}

export interface IHeader {
  [columnKey: string]: string;
}

export interface IColumn {
  visible: boolean;
  columnKey: string;
}
