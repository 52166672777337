import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[orgosAutocompleteOption]'
})
export class AutocompleteOptionDirective {
  constructor(private el: ElementRef) {}

  getElementRef(): ElementRef {
    return this.el;
  }
}
