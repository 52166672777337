import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as check from 'check-types';
import * as _ from 'lodash';

import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { ISelectOption } from '../../core/select-option';
import { InternationalizationService } from '../../services/core/internationalization.service';
import { PreferenceService } from '../../services/preference/preference.service';

@Component({
  selector: 'orgos-list-view',
  templateUrl: 'list-view.component.html',
  styleUrls: ['list-view.component.scss'],
})
export class ListViewComponent implements OnInit {
  @Output() onChangeView: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteView: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickNew: EventEmitter<any> = new EventEmitter<any>();

  @Input() preferenceKey: string;
  @Input() isLoaded: boolean;
  @Input() standardView: boolean = true;
  @Input() simpleView: boolean = false;

  @Input()
  set newView(newViewName: string) {
    this.updateViews()
      .then(() => {
        this.selectedView = newViewName;
      })
      .catch(() => {});
  }

  views: object = {};
  viewLabels: Array<ISelectOption> = [];
  selectedView: string;
  translation: any = {};
  width: string = '150';

  miscTranslation: any = {};

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('list-view-component')
      .then((translation) => {
        this.translation = translation;
        return this.updateViews();
      })
      .then(() => {
        this.isLoaded = true;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  private updateViews(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Preferences for views
      this.injector
        .get(PreferenceService)
        .getPreferenceByKey(this.preferenceKey)
        .then((views) => {
          this.views = check.assigned(views.preference) ? views.preference : {};
          this.viewLabels = Object.keys(this.views).map((iView) => {
            const view = {
              name: iView,
              value: iView,
            };
            return view;
          });
          this.viewLabels = _.orderBy(this.viewLabels, ['name'], ['asc']);
          const optionAll = {
            name: this.translation.allViewsLabel,
            value: this.translation.allViewsLabel,
          };
          this.viewLabels.splice(0, 0, optionAll);
          if (check.not.assigned(this.selectedView) || check.emptyString(this.selectedView)) {
            this.selectedView = this.translation.allViewsLabel;
          }

          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  public createView(): void {
    this.onClickNew.emit();
  }

  public changeView(viewName: any): void {
    this.selectedView = check.assigned(viewName) ? viewName : this.translation.allViewsLabel;
    const viewInfo = {
      viewName: this.selectedView,
      filters: Object.assign({}, this.views[this.selectedView]),
    };
    this.onChangeView.emit(viewInfo);
  }

  public deleteView(viewName: any): void {
    const data = {
      titleText: this.translation.confirmMassRemoveViewTitle,
      confirmButtonText: this.translation.confirmRemoveButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        delete this.views[viewName];
        this.injector
          .get(PreferenceService)
          .setPreferenceByKey(this.preferenceKey, this.views)
          .then(() => {
            return this.updateViews();
          })
          .then(() => {
            this.selectedView = this.translation.allViewsLabel;
            const viewInfo = {
              viewName: this.selectedView,
              filters: {},
            };
            this.onDeleteView.emit(viewInfo);
            const message = this.translation.viewDeletedMessage;
            this.injector.get(MatLegacySnackBar).open(message, 'OK', {
              duration: 5000,
            });
          })
          .catch(() => {});
      }
    });
  }
}
