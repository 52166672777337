import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AttendancePolicyService } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { AttendanceSettingsService } from '@app/cloud-features/settings-attendance/services/attendance-settings.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { environment } from '@env';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KioskService {
  public userPinCode$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(private injector: Injector) {}

  async fetchPinCodeStatus(userId: string) {
    try {
      this.userPinCode$.next(undefined);
      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const userAccount = await this.injector.get(UserAccountService).getById(userId);
      if (!userAccount.attendancePolicy) {
        return undefined;
      }

      const attendanceStatus = await this.injector.get(CloudRoutesService).getAppStatus('attendance');
      if (attendanceStatus?.isActive !== true) {
        return undefined;
      }

      const [[userPolicy], [attendanceSettings]] = await Promise.all([this.injector.get(AttendancePolicyService).find({ _id: userAccount.attendancePolicy }), this.injector.get(AttendanceSettingsService).find({ _id: { $ne: null } })]);
      if (!userPolicy.methods?.pinCode || !attendanceSettings?.kioskActive) {
        return undefined;
      }

      try {
        const { pinCode } = await this.injector.get(HttpClient).get<{ pinCode: string }>(`${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/pincode/${userId}`, { headers }).toPromise();
        this.userPinCode$.next(pinCode);
        return pinCode;
      } catch {
        // No rights to see pincode, do nothing
      }
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, KioskService.name, 'fetchPinCodeStatus');
      this.userPinCode$.next(undefined);
    }
  }

  async refreshPinCode(userId: string) {
    try {
      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const body = { userId };
      await this.injector.get(HttpClient).post<{ pincode: string }>(`${environment.PEOPLE_CLOUD_APP_URL}/user-account-db/refresh-pincode`, body, { headers }).toPromise();
      const { pinCode } = await this.injector.get(HttpClient).get<{ pinCode: string }>(`${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/pincode/${userId}`, { headers }).toPromise();
      this.userPinCode$.next(pinCode);
      return false;
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, KioskService.name, 'refreshPinCode');
      this.userPinCode$.next(undefined);
      return true;
    }
  }

  flushPincode() {
    this.userPinCode$.next(undefined);
  }

  async fetchKioskSessions(queryOptions: any): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).post(`${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/list`, queryOptions, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, KioskService.name, 'fetchKioskSessions');
    }
  }
}
