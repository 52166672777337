import { Component, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

import { RowDirective } from './row.directive';

@Component({
  selector: 'orgos-row',
  template: ` <ng-content></ng-content> `
})
export class RowComponent {
  @Input()
  set size(size: string) {
    this.orgosRow.size = size;
  }

  @HostBinding('attr.orgosRow') orgosRow: RowDirective = new RowDirective(this.renderer, this.el);

  constructor(private renderer: Renderer2, private el: ElementRef) {}
}
