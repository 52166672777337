import { Component, Injector, Input } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivateShiftPlanDialog } from '@app/cloud-features/shift-plan/components/activate-shift-plan/activate-shift-plan.dialog';
import { ShiftPlanEmployeeListService } from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import {
  IShiftplanEmployee,
  ShiftPlanEmployeeDirectoryService,
} from '@app/cloud-features/shift-plan/services/shift-plan-employee-directory.service';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { IHeader } from '@app/standard/components/table/table.component';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { GenericFilterPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic-filter.page';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IPreferenceModel, PreferenceService } from '@app/standard/services/preference/preference.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { UserService } from '@app/standard/services/user/user.service';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
@Component({
  selector: 'kenjo-settings-shift-plan-users',
  templateUrl: 'settings-shift-plan-users.html',
  styleUrls: ['settings-shift-plan-users.scss'],
})
export class SettingsShiftPlanUserPage extends GenericFilterPage {
  @Input() parentTranslation: any = {};
  @Input() shiftplanPermissions: IShiftPlanPermissions;

  translationResources: ITranslationResource[] = [
    { name: 'page', translationKey: 'shift-plan-employees' },
    { name: 'dialog', translationKey: 'people-detail-personal-page' },
  ];
  tableLoading: boolean = false;
  userIsAdminHrAdmin: boolean = false;
  usersInfo: Array<IShiftplanEmployee> = [];
  headerLabels: IHeader;
  displayedColumns: Array<string> = [];
  PREFERENCE_OPTION_COLUMNS_KEY: string = 'shiftplan-employee-list';
  PREFERENCE_OPTION_FILTERS_KEY: string = 'shiftplan-employee-list-filters';
  PREFERENCE_TYPE: string = pickLists.PREFERENCE_TYPE_TABLE;

  totalColumns: Array<string> = [
    'employee.name',
    'shiftplanStatus',
    'locations',
    'roles',
    'workingAreas',
    'tags',
    'reportsTo.displayName',
    'employee.status',
    'company.name',
    'office.name',
  ];

  employeesToAccess: string[];
  currentUser: IUserAccountModel;
  safeVideoUrl: SafeResourceUrl;
  areFiltersEmpty: boolean;
  columnPreferences: IPreferenceModel;

  constructor(private sanitizer: DomSanitizer, protected injector: Injector, protected router: Router) {
    super(injector, router);
  }

  protected async getGlobalBarOptions(): Promise<IMenuOption[]> {
    this.injector.get(ShiftPlanPermissionsService).refreshPermissions();
    const pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-page');
    const shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
    const options: Array<IMenuOption> = [];
    if (shiftplanPermissions['view_schedule']) {
      options.push({
        key: 'shiftplan-schedules',
        name: pageTranslation.schedulesTab,
        onClick: () => this.router.navigateByUrl('/cloud/shift-plan/schedules'),
      });
    }

    if (shiftplanPermissions['view_settings']) {
      options.push({
        key: 'shiftplan-settings',
        name: pageTranslation.settingsTab,
        onClick: () => this.router.navigateByUrl('/cloud/shift-plan/settings'),
      });
    }

    this.globalBarConfig.pageName = pageTranslation.pageName;
    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 1;
    return options;
  }

  protected fetchGridData(): Promise<void> {
    return;
  }

  protected getCurrentQueryValues() {
    return {
      locations: this.queryOptions['user-work']?.where?.locations?.$in ?? [],
      roles: this.queryOptions['user-work']?.where?.roles?.$in ?? [],
      workingAreas: this.queryOptions['user-work']?.where?.workingAreas?.$in ?? [],
      tags: this.queryOptions['user-work']?.where?.tags?.$in ?? [],
    };
  }

  protected async getPreferences(): Promise<void> {
    this.preferences = await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_OPTION_FILTERS_KEY);
    this.columnPreferences = await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_OPTION_COLUMNS_KEY);
  }

  private async setColumnsPreferences(): Promise<void> {
    await this.injector
      .get(PreferenceService)
      .setPreferenceByKey(this.PREFERENCE_OPTION_COLUMNS_KEY, this.displayedColumns, this.PREFERENCE_TYPE);
  }

  private async setFiltersPreferences(): Promise<void> {
    if (check.assigned(this.queryOptions['user-work']?.where)) {
      await this.injector
        .get(PreferenceService)
        .setPreferenceByKey(this.PREFERENCE_OPTION_FILTERS_KEY, this.queryOptions['user-work']?.where);
    } else {
      await this.injector.get(PreferenceService).setPreferenceByKey(this.PREFERENCE_OPTION_FILTERS_KEY, {});
    }
  }

  changeColumnSorting({ sortBy, sortOrder }) {
    const sortedArray = this.usersInfo.sort((a, b) => {
      let valueA = this.getNestedPropertyValue(a, sortBy);
      let valueB = this.getNestedPropertyValue(b, sortBy);
      if (Array.isArray(valueA) && Array.isArray(valueB)) {
        valueA = valueA.length > 0 ? valueA[0].name : '';
        valueB = valueB.length > 0 ? valueB[0].name : '';
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toUpperCase();
        valueB = valueB.toUpperCase();
      }

      let result = 0;
      if (valueA < valueB) {
        result = -1;
      } else if (valueA > valueB) {
        result = 1;
      }

      if (sortOrder === 'desc') {
        result *= -1;
      }
      return result;
    });

    this.queryOptions.sortBy = sortBy;
    this.queryOptions.sortOrder = sortOrder;
    return sortedArray;
  }

  private getNestedPropertyValue(obj, path) {
    const keys = path.split('.');

    let value = obj;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        value = '';
        break;
      }
    }
    return value;
  }

  protected clearCustomFilters(): void {
    if (check.assigned(this.queryOptions['user-work']?.where)) {
      this.queryOptions['user-work'].where = {};
    }
  }

  public async clearAllFilters(): Promise<void> {
    this.resetView();

    this.clearCustomFilters();

    if (check.assigned(this.queryOptions.search)) {
      this.queryOptions.search = '';
    }

    this.initBarFilters();
    await this.moveToPage(this.PAGE_SELECTOR['first']);
  }

  async getFilters() {
    try {
      const response = await this.injector.get(ShiftPlanEmployeeDirectoryService).getShiftPlanFilters();
      this.filters = {
        locations: response.locations,
        workingAreas: response.workingAreas,
        roles: response.roles,
        tags: response.tags,
      };

      if (this.preferences?.preference && Object.keys(this.preferences?.preference).length > 0) {
        const filtersExist = Object.keys(this.preferences?.preference).every((filter) => {
          return Object.keys(this.filters).includes(filter);
        });

        if (filtersExist) {
          if (check.not.assigned(this.queryOptions['user-work']?.where)) {
            this.queryOptions['user-work'] = {
              where: {},
            };
          }
          this.queryOptions['user-work'].where = this.preferences?.preference;
        }
      }
    } catch {}
  }

  protected addFilter(value: string, field: any) {
    if (check.not.assigned(this.queryOptions['user-work']?.where)) {
      this.queryOptions['user-work'] = {
        where: {},
      };
    }

    if (check.not.assigned(this.queryOptions['user-work'].where[field])) {
      this.queryOptions['user-work'].where[field] = {
        $in: [],
      };
    }

    if (this.queryOptions['user-work'].where[field].$in.indexOf(value) === -1) {
      this.queryOptions['user-work'].where[field].$in.push(value);
    }
    this.moveToPage(this.PAGE_SELECTOR['first']);
  }

  removeFilter(value: string, field: string) {
    if (
      check.not.assigned(this.queryOptions['user-work']?.where[field].$in) ||
      check.emptyArray(this.queryOptions['user-work']?.where[field].$in)
    ) {
      return;
    }

    if (this.queryOptions['user-work']?.where[field].$in.length > 1) {
      this.queryOptions['user-work'].where[field].$in = this.queryOptions['user-work']?.where[field].$in.filter(
        (fieldValue) => fieldValue !== value
      );
    } else if (this.queryOptions['user-work']?.where[field].$in.length === 1) {
      delete this.queryOptions['user-work']?.where[field];
    }

    if (check.not.assigned(this.queryOptions['user-work']?.where) || check.emptyObject(this.queryOptions['user-work']?.where)) {
      delete this.queryOptions['user-work'];
    }
  }

  async toggleFilter({ value, active, field }) {
    const fieldName = field;

    this.executeActionOnFilter(active, value, fieldName);
    await this.moveToPage(this.PAGE_SELECTOR['first']);
  }

  async changeRecordsToShow(recordsPerPage: number) {
    if (recordsPerPage === this.queryOptions.recordsPerPage) {
      return;
    }

    this.queryOptions.recordsPerPage = recordsPerPage;
    this.queryOptions.page = 1;

    await this.fetchListData();
  }

  async moveToPage(option: number) {
    if (option === this.PAGE_SELECTOR['first']) {
      this.queryOptions.page = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && this.queryOptions.page > 1) {
      this.queryOptions.page--;
    } else if (option === this.PAGE_SELECTOR['next'] && this.queryOptions.page < this.paginationConfiguration.numberOfPages) {
      this.queryOptions.page++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      this.queryOptions.page = this.paginationConfiguration.numberOfPages;
    }
    await this.fetchListData(true);
  }

  async clearFilter(field: string) {
    const collection = 'user-work';
    if (check.assigned(this.queryOptions[collection]?.where[field])) {
      delete this.queryOptions[collection]?.where[field];
    }

    await this.moveToPage(this.PAGE_SELECTOR['first']);
  }

  protected async fetchListData(moveToAfterFilter: boolean = false): Promise<void> {
    try {
      this.tableLoading = true;
      this.usersInfo = [];

      const response = await this.injector.get(ShiftPlanEmployeeDirectoryService).getShiftPlanEmployeesDirectory(this.queryOptions);

      this.usersInfo = response.records.map((employee) => {
        const newObj: IShiftplanEmployee = { ...employee };
        newObj.locations = employee.locations ? employee.locations.map((loc) => loc.name).join(', ') : '';
        newObj.workingAreas = employee.workingAreas ? employee.workingAreas.map((area) => area.name).join(', ') : '';
        newObj.tags = employee.tags ? employee.tags.map((tag) => tag.name).join(', ') : '';

        return newObj;
      });
      this.paginationConfiguration = { numberOfPages: response.pages, totalOfRecords: response.totalOfRecords };
      this.areFiltersEmpty =
        (typeof this.queryOptions?.search === 'undefined' || this.queryOptions?.search?.length === 0) &&
        (!this.queryOptions['user-work'] ||
          (this.queryOptions['user-work'].where && Object.keys(this.queryOptions['user-work'].where).length === 0));
      this.tableLoading = false;
      if (moveToAfterFilter) {
        this.setFiltersPreferences();
      }
    } catch {
      this.usersInfo = [];
      this.areFiltersEmpty =
        !this.queryOptions['user-work'] ||
        (this.queryOptions['user-work'].where && Object.keys(this.queryOptions['user-work'].where).length === 0);
      this.tableLoading = false;
    }
  }
  protected async fetchRelatedData(): Promise<void> {
    this.safeVideoUrl = await this.sanitizer.bypassSecurityTrustResourceUrl(this.i18n.page.videoUrl);
    this.employeesToAccess = await this.injector.get(ShiftPlanEmployeeListService).getEmployeesEditAccess();
    const userProfile = this.getLoggedUser().profile;

    this.userIsAdminHrAdmin =
      userProfile._isAdmin === true || userProfile._profileKey === 'hr-admin' || check.emptyArray(this.employeesToAccess);
    await this.getColumns();
    this.headerLabels = this.getHeaderLabels();
  }

  private async getColumns(): Promise<void> {
    await this.getPreferences();
    if (!this.columnPreferences?.preference || check.not.array(this.columnPreferences.preference)) {
      this.displayedColumns = this.totalColumns;
      return;
    }
    this.displayedColumns = this.columnPreferences.preference.filter((preference) => this.totalColumns.includes(preference));
  }

  public async saveColumns(columns: Array<string>): Promise<void> {
    if (check.not.assigned(columns)) {
      return;
    }
    this.displayedColumns = columns;
    await this.setColumnsPreferences();
  }

  private getHeaderLabels(): IHeader {
    return {
      'employee.name': this.i18n.page.employeeNameHeader,
      shiftplanStatus: this.i18n.page.shiftPlanStatusHeader,
      locations: this.i18n.page.locationsHeader,
      roles: this.i18n.page.rolesHeader,
      workingAreas: this.i18n.page.workingAreasHeader,
      tags: this.i18n.page.tagsHeader,
      'reportsTo.displayName': this.i18n.page.reportsToHeader,
      'employee.status': this.i18n.page.statusHeader,
      'company.name': this.i18n.page.companyHeader,
      'office.name': this.i18n.page.officeHeader,
    };
  }

  editEmployeeShiftplanProfile(userId: string) {
    this.openShiftPlanActivationDialog(userId);
  }

  async openShiftPlanActivationDialog(userId) {
    const fullUser = await this.injector.get(UserService).getUserDetail(userId, true);
    const userPersonal = new GenericSimpleModel(this.injector, fullUser.userPersonal, UserPersonalService, userId);
    const userWork = new GenericSimpleModel(this.injector, fullUser.userWork, UserWorkService, userId);
    const userAccount = new GenericSimpleModel(this.injector, fullUser.userAccount, UserAccountService, userId);

    const data = {
      peopleDetailPageTranslation: this.i18n.dialog,
      userPersonal: userPersonal,
      userWork: userWork,
      userAccount: userAccount,
      isNotAdmin: !this.userIsAdminHrAdmin,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ActivateShiftPlanDialog, { data: data });

    dialogRef.afterClosed().subscribe((dataHasChanged) => {
      if (dataHasChanged) {
        this.fetchListData();
      }
    });
  }

  navigateToPeopleDirectory() {
    this.router.navigateByUrl('/cloud/people/directory');
  }
}
