import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { SettingsShiftPlanRoleDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-roles/dialogs/add-edit-role-dialog';
import {
  IShiftPlanRoleSettingsModel,
  ShiftPlanRoleSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-role.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { processShiftplanPermissions } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as Chance from 'chance';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-settings-shift-plan-roles',
  templateUrl: 'settings-shift-plan-roles.html',
  styleUrls: ['settings-shift-plan-roles.scss'],
})
export class SettingsShiftPlanRoles implements OnInit {
  dialogTranslation: any = {};
  allRoles: Array<IShiftPlanRoleSettingsModel> = [];
  dataLoaded: boolean = false;
  filteredRoles: Array<IShiftPlanRoleSettingsModel> = [];
  colorConstantsObject: any = userColorConstants;
  colorConstantsKeys: Array<string> = Object.keys(userColorConstants).slice(0, -1);
  i18n: any = {};
  mappedRolesPermissions: { [id: string]: boolean };
  canCreateRoles: boolean;
  SHIFT_PLAN_ROLE: string = 'shift-plan-role';

  @Input() parentTranslation: any = {};
  @Input() myProfileId: string;

  constructor(
    private injector: Injector,
    public snackBar: MatLegacySnackBar,
    private dialog: MatLegacyDialog,
    private i18nDataPipe: I18nDataPipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData() {
    try {
      const [i18nPage, dialogTranslation] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-roles-tags'),
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-role-dialog'),
        this.findAllRoles(),
      ]);
      this.i18n.page = i18nPage;
      this.dialogTranslation = dialogTranslation;
      const rolesPermission = await this.injector.get(ShiftPlanPermissionsService).getShiftplPermissionsByCollection('shift-plan-role');
      this.canCreateRoles = rolesPermission.create_all ?? false;
      this.mappedRolesPermissions = processShiftplanPermissions(this.allRoles, rolesPermission, this.myProfileId);
    } catch {
      this.i18n.page = {};
      this.dialogTranslation = {};
    }
  }

  private async findAllRoles(): Promise<void> {
    try {
      this.dataLoaded = false;
      const allRoles = await this.injector.get(ShiftPlanRoleSettingsService).getRoles({ _id: { $ne: null } });
      this.allRoles = allRoles;
      this.filteredRoles = allRoles;
      this.dataLoaded = true;
    } catch {
      this.allRoles = [];
      this.dataLoaded = false;
    }
  }

  public addRole(): void {
    const chance = new Chance();
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const roleDocument: GenericCacheModel = new GenericCacheModel(
      this.injector,
      { name: '', colour: chance.pickone(this.colorConstantsKeys), codeName: '' },
      ShiftPlanRoleSettingsService,
      loggedUser[fieldConstants.ID]
    );

    const data = {
      role: roleDocument,
      isNewRole: true,
      dialogTranslation: this.dialogTranslation,
    };

    const dialogRef = this.dialog.open(SettingsShiftPlanRoleDialog, { data });
    dialogRef.afterClosed().subscribe(async (newRole: any) => {
      try {
        if (check.not.assigned(newRole)) {
          return;
        }
        await this.injector.get(ShiftPlanRoleSettingsService).create(newRole.roleData);
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('add role', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Company setup' });
        this.findAllRoles();
      } catch {
        this.findAllRoles();
      }
    });
  }

  public editRole(role: IShiftPlanRoleSettingsModel): void {
    const disabled = this.mappedRolesPermissions[role._id];
    if (disabled) {
      return;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const roleDocument: GenericCacheModel = new GenericCacheModel(
      this.injector,
      { _id: role[fieldConstants.ID], name: role.name, colour: role.colour, codeName: role.codeName },
      ShiftPlanRoleSettingsService,
      loggedUser[fieldConstants.ID]
    );
    const data = {
      role: roleDocument,
      isNewRole: false,
      dialogTranslation: this.dialogTranslation,
    };

    const dialogRef = this.dialog.open(SettingsShiftPlanRoleDialog, { data });
    dialogRef.afterClosed().subscribe(async (editRole: any) => {
      try {
        if (check.not.assigned(editRole)) {
          return;
        }
        await this.injector.get(ShiftPlanRoleSettingsService).updateById(editRole.roleData[fieldConstants.ID], editRole.roleData);
        this.findAllRoles();
      } catch {
        this.findAllRoles();
      }
    });
  }

  private deleteRole(translationData: any, roleId: string): void {
    const confirmDeleteTransformed = this.i18nDataPipe.transform(this.dialogTranslation.confirmRoleDeletionTitle, translationData);
    const data = {
      titleText: confirmDeleteTransformed,
      subtitleText: this.dialogTranslation.confirmRoleDeletionSubtitle,
      confirmButtonText: this.dialogTranslation.confirmDeleteButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.dialogTranslation.goBackButtonDialog,
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm) => {
      try {
        if (confirm && confirm === true) {
          await this.injector.get(ShiftPlanRoleSettingsService).deleteById(roleId);
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('delete role', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Company setup' });
          const snackbarTransformed = this.i18nDataPipe.transform(this.dialogTranslation.snackbarSuccessRoleDeleted, translationData);
          this.snackBar.open(snackbarTransformed, 'OK', { duration: 5000 });
          this.findAllRoles();
        }
      } catch {
        this.findAllRoles();
      }
    });
  }

  public async validateIfRoleIsAttached(roleDocument: IShiftPlanRoleSettingsModel): Promise<void> {
    const disabled = this.mappedRolesPermissions[roleDocument._id];
    if (disabled) {
      return;
    }

    const translationData = {
      name: roleDocument.name,
    };
    const roleId = roleDocument[fieldConstants.ID];

    const canDeleteResponse = await this.injector.get(ShiftPlanRoleSettingsService).canDeleteRole(roleId);

    if (check.not.assigned(canDeleteResponse) || check.emptyObject(canDeleteResponse) || check.not.assigned(canDeleteResponse.canDelete)) {
      return;
    }
    const { canDelete } = canDeleteResponse;

    if (canDelete === true) {
      this.deleteRole(translationData, roleId);
      return;
    }

    if (check.not.assigned(canDeleteResponse.hasAssociatedKey)) {
      return;
    }

    const titleErrorMessage = this.getCanDeleteErrorMessage(canDeleteResponse.hasAssociatedKey);
    const data = {
      titleText: this.i18nDataPipe.transform(titleErrorMessage, translationData),
      confirmButtonText: this.dialogTranslation.gotItButton,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    await dialogRef.afterClosed().toPromise();
  }

  public setFilteredRolesToShow(recordsToShow: Array<IShiftPlanRoleSettingsModel>): void {
    this.filteredRoles = recordsToShow;
    this.cdr.detectChanges();
  }

  public getCanDeleteErrorMessage(key: string) {
    if (key === 'employees') {
      return this.dialogTranslation.roleAttachedEmployeeMessage;
    }
    if (key === 'shift-cards') {
      return this.dialogTranslation.roleAttachedShiftMessage;
    }
    if (key === 'shift-templates') {
      return this.dialogTranslation.roleAttachedTemplateMessage;
    }
  }
}
