<ng-container *ngIf="loadingPage === false">
  <orgos-column-container class="kenjo-mb-20px">
    <orgos-column size="0" class="kenjo-mr-20px">
      <orgos-list-view
        [preferenceKey]="PREFERENCE_VIEW"
        [newView]="selectedView"
        (onDeleteView)="switchView($event)"
        (onChangeView)="switchView($event)"
        (onClickNew)="createView()"
        [simpleView]="true"
      ></orgos-list-view>
    </orgos-column>

    <!-- TABLE OPTIONS -->
    <orgos-column *ngIf="bulkActionsAllowed && queryOptions.listView" size="0" class="d-table-options kenjo-mr-20px">
      <orgos-button-raised [disabled]="selectedUsers && selectedUsers.length === 0" [matMenuTriggerFor]="massMenu">
        {{ i18n.page.massEditButton }}</orgos-button-raised
      >
      <mat-menu #massMenu="matMenu">
        <ng-container *ngIf="selectedUsers && selectedUsers.length > 0">
          <button mat-menu-item (click)="activateEmployees()" [disabled]="bypassEmail">{{ i18n.page.activateEmployees }}</button>
          <button mat-menu-item (click)="changeUserWork('company')">{{ i18n.page.changeCompany }}</button>
          <button mat-menu-item (click)="changeUserWork('office')">{{ i18n.page.changeOffice }}</button>
          <button mat-menu-item *ngIf="filters && filters.areas.length > 0" (click)="changeUserWork('area')">
            {{ i18n.page.changeArea }}
          </button>
          <button mat-menu-item (click)="changeUserWork('department')">{{ i18n.page.changeDepartment }}</button>
          <button mat-menu-item *ngIf="filters && filters.teams.length > 0" (click)="changeUserWork('team')">
            {{ i18n.page.changeTeam }}
          </button>
          <button mat-menu-item (click)="changeUserWork('reportsTo')">{{ i18n.page.changeReportsTo }}</button>
          <button mat-menu-item *ngIf="isTimeOffApproverActive" (click)="changeUserWork('timeOffApprover')">
            {{ i18n.page.changeTimeOffApprover }}
          </button>
          <button mat-menu-item (click)="assignUserWorkScheduleTemplate()">{{ i18n.page.assignWorkScheduleTemplate }}</button>
        </ng-container>
      </mat-menu>
    </orgos-column>

    <!-- GRID OPTIONS -->
    <orgos-column size="0" *ngIf="queryOptions.listView === false">
      <orgos-column-container centerColumns="true">
        <orgos-column size="0" class="kenjo-mr-5px">
          <span>{{ i18n.page.filterByLabel }}:</span>
        </orgos-column>
        <orgos-column size="0" class="d-filter">
          <mat-form-field>
            <b>
              <mat-select [(ngModel)]="queryOptions.groupBy" (selectionChange)="changeGroupField()">
                <mat-option *ngFor="let filter of sortFilters" [value]="filter.value"> {{ filter.name }} </mat-option>
              </mat-select>
            </b>
          </mat-form-field>
        </orgos-column>
        <orgos-column></orgos-column>
      </orgos-column-container>
    </orgos-column>

    <orgos-column
      *ngIf="queryOptions.listView && selectedUsers && selectedUsers.length > 0"
      size="0"
      class="kenjo-mt-10px kenjo-font-color-light-text-757575"
      >{{ employeesSelectedText }}</orgos-column
    >
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" class="kenjo-mr-40px kenjo-mt-5px">
      <orgos-action [matMenuTriggerFor]="actionsMenu" *ngIf="canAddEmployees || canImportEmployees" icon="add_circle" iconSize="large">
        {{ i18n.page.addEmployeeButtonTooltip }}</orgos-action
      >
      <mat-menu #actionsMenu="matMenu">
        <ng-container>
          <button mat-menu-item *ngIf="canAddEmployees" (click)="createEmployeePopup()">{{ i18n.page.addSingleEmployee }}</button>
          <button mat-menu-item *ngIf="canImportEmployees" (click)="navigateToDataImport()">{{ i18n.page.importData }}</button>
        </ng-container>
      </mat-menu>
    </orgos-column>
    <orgos-column size="0">
      <kenjo-icon-toggle
        [optionAIcon]="'list_icon'"
        [optionBIcon]="'grid_icon'"
        [selectedValue]="queryOptions.listView"
        (checkedChange)="toggleView($event)"
      ></kenjo-icon-toggle>
    </orgos-column>
  </orgos-column-container>

  <!-- FILTER BAR -->
  <kenjo-filter-bar
    [filterOptions]="filters"
    [shown]="true"
    [searchTerm]="queryOptions.search"
    (changeFilterEvent)="toggleFilter($event)"
    (clearFilterEvent)="clearFilter($event)"
    (clearAllFiltersEvent)="clearAllFilters()"
    (searchTermChanged)="searchByTerm($event)"
  ></kenjo-filter-bar>

  <!-- No data -->
  <div
    *ngIf="dataLoaded && ((queryOptions.listView === true && usersList && usersList.length === 0) || (queryOptions.listView === false && listGroups && listGroups.length === 0))"
    class="d-list-container kenjo-text-align-center"
  >
    <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{ i18n.page.noEmployeeTitle }}</div>
      <div class="kenjo-mt-5px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.noEmployeeSubtitle }}</div>
    </div>
  </div>

  <!-- If still loading (GRID) -->
  <div *ngIf="!dataLoaded && queryOptions.listView === false" class="d-list-container d-list-loading-spinner kenjo-p-20px">
    <div>
      <orgos-loading-spinner></orgos-loading-spinner>
      <div class="kenjo-mt-20px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ i18n.page.loadingDataMessage }}</div>
    </div>
  </div>

  <!-- TABLE VIEW -->
  <div *ngIf="queryOptions.listView === true">
    <orgos-column-container>
      <orgos-column class="d-list-wrapper">
        <orgos-container aspect="card" class="d-table-container">
          <!-- If still loading (LIST) -->
          <orgos-container *ngIf="!dataLoaded" class="d-list-container">
            <div class="d-list-loading-spinner d-relative kenjo-p-20px">
              <div>
                <orgos-loading-spinner></orgos-loading-spinner>
                <div class="kenjo-mt-20px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ i18n.page.loadingDataMessage }}</div>
              </div>
            </div>
          </orgos-container>

          <!-- List -->
          <orgos-table-smart
            *ngIf="dataLoaded && usersList && usersList.length > 0"
            [data]="usersList"
            [manageColumns]="true"
            [totalColumns]="totalColumns"
            [bulkActionsAllowed]="bulkActionsAllowed"
            [displayedColumns]="displayedColumns"
            [headerLabels]="headerLabels"
            [selectable]="false"
            [sortBy]="sortBy"
            [sortOrder]="sortOrder"
            (sortTable)="changeColumnSort($event)"
            [customHeightToSubstract]="paginationConfiguration.totalOfRecords > 25 ? 319 : 271"
            [style.height]="paginationConfiguration.totalOfRecords > 25 ? 'calc(100vh - 319px)' : 'calc(100vh - 271px)'"
            [selectable]="true"
            (changeSelection)="selectListItem($event)"
            [selectedList]="selectedUsers"
            (changeColumns)="saveColumns($event)"
          >
            <orgos-table-smart-column *ngIf="bulkActionsAllowed" columnKey="select" [sortable]="false" [actionColumn]="true">
            </orgos-table-smart-column>
            <orgos-table-smart-column columnKey="user-personal.displayName" [header]="i18n.page.displayName" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <orgos-column-container centerColumns="true">
                  <orgos-avatar
                    orgosColumn="0"
                    [avatarName]="row.displayName"
                    [photoUrl]="row._photo?._url"
                    size="tiny"
                    class="kenjo-cursor-pointer"
                    [routerLink]="['/cloud/people/', row._id, 'personal']"
                  ></orgos-avatar>
                  <a orgosColumn="0" class="kenjo-ml-10px" [routerLink]="['/cloud/people/', row._id, 'personal']">
                    <kenjo-truncate-text class="d-relevant-field">{{ row.displayName }}</kenjo-truncate-text>
                  </a>
                </orgos-column-container>
              </ng-container>
            </orgos-table-smart-column>
            <orgos-table-smart-column columnKey="user-work.jobTitle" [header]="i18n.page.jobTitle" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <kenjo-truncate-text class="d-relevant-field">{{ row.jobTitle }}</kenjo-truncate-text>
              </ng-container>
            </orgos-table-smart-column>
            <orgos-table-smart-column columnKey="user-work.manager" [header]="i18n.page.managerName" [sortable]="false">
              <ng-container *orgosTableSmartCell="let row" class="kenjo-ml-10px">
                <kenjo-truncate-text class="d-relevant-field"> {{ row.manager }}</kenjo-truncate-text>
              </ng-container>
            </orgos-table-smart-column>
            <orgos-table-smart-column columnKey="company.name" [header]="i18n.page.companyName" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <kenjo-truncate-text class="d-relevant-field"> {{ row.companyName }}</kenjo-truncate-text>
              </ng-container>
            </orgos-table-smart-column>
            <orgos-table-smart-column columnKey="office.name" [header]="i18n.page.officeName" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <kenjo-truncate-text class="d-irrelevant-field"> {{ row.officeName }}</kenjo-truncate-text>
              </ng-container>
            </orgos-table-smart-column>
            <orgos-table-smart-column columnKey="department.name" [header]="i18n.page.departmentName" [sortable]="true">
              <ng-container *orgosTableSmartCell="let row">
                <kenjo-truncate-text class="d-irrelevant-field"> {{ row.departmentName }}</kenjo-truncate-text>
              </ng-container>
            </orgos-table-smart-column>
            <orgos-table-smart-column columnKey="user-account.status" [header]="i18n.page.status" [sortable]="false">
              <ng-container *orgosTableSmartCell="let row">
                <orgos-chip
                  *ngIf="row.status === 'active'"
                  class="kenjo-text-align-center kenjo-font-color-white-ffffff"
                  [color]="'Success'"
                >
                  {{ i18n.page.activeStatus }}</orgos-chip
                >
                <orgos-chip
                  *ngIf="row.status === 'notActivate'"
                  class="d-status-field kenjo-text-align-center kenjo-font-color-white-ffffff"
                  [color]="'Warning'"
                >
                  {{ i18n.page.notActivatedStatus }}</orgos-chip
                >
                <orgos-chip
                  *ngIf="row.status === 'deactivate'"
                  class="d-status-field kenjo-text-align-center kenjo-font-color-light-text-757575"
                  [color]="'Neutral'"
                >
                  {{ i18n.page.deactivatedStatus }}</orgos-chip
                >
              </ng-container>
            </orgos-table-smart-column>
          </orgos-table-smart>

          <!-- PAGINATION -->
          <orgos-column-container
            class="d-pagination"
            centerColumns="true"
            *ngIf="dataLoaded && paginationConfiguration.totalOfRecords > 25"
          >
            <orgos-column></orgos-column>
            <orgos-column size="0">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-light-text-757575">
                  {{ i18n.page.displayingLabel }}
                </orgos-column>
                <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px d-list-num-pages-container">
                  <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
                    <orgos-column-container centerColumns="true">
                      <orgos-column
                        size="0"
                        class="kenjo-font-size-14px kenjo-font-color-text-333333 d-list-records-page"
                        [matTooltip]="i18n.page.numberOfRecordsToShowText"
                      >
                        {{ queryOptions.recordsPerPage }}
                      </orgos-column>
                      <orgos-column size="0">
                        <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                      </orgos-column>
                    </orgos-column-container>
                  </div>
                  <mat-menu #paginationMenu="matMenu">
                    <button *ngFor="let page of recordsToShowSelector" mat-menu-item (click)="changeRecordsToShow(page)">
                      <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-light-text-757575"> {{ page }} </orgos-column>
                    </button>
                  </mat-menu>
                </orgos-column>
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-pl-10px">
                  {{ i18n.page.outOfLabel }}
                </orgos-column>
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-10px">
                  {{ paginationConfiguration.totalOfRecords }}
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="0" class="kenjo-mh-40px">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-mr-10px d-list-pagination-icon"
                    [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                    (click)="moveToPage(PAGE_SELECTOR['first'])"
                    >first_page</mat-icon
                  >
                </orgos-column>
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-mr-10px d-list-pagination-icon"
                    [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                    (click)="moveToPage(PAGE_SELECTOR['previous'])"
                    >keyboard_arrow_left</mat-icon
                  >
                </orgos-column>
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-10px">
                  {{ queryOptions.page }} / {{ paginationConfiguration.numberOfPages }}
                </orgos-column>
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-ml-10px d-list-pagination-icon"
                    [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === paginationConfiguration.numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== paginationConfiguration.numberOfPages }"
                    (click)="moveToPage(PAGE_SELECTOR['next'])"
                    >keyboard_arrow_right</mat-icon
                  >
                </orgos-column>
                <orgos-column size="0">
                  <mat-icon
                    class="kenjo-ml-10px d-list-pagination-icon"
                    [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === paginationConfiguration.numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== paginationConfiguration.numberOfPages }"
                    (click)="moveToPage(PAGE_SELECTOR['final'])"
                    >last_page</mat-icon
                  >
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column></orgos-column>
          </orgos-column-container>
        </orgos-container>
      </orgos-column>
    </orgos-column-container>
  </div>

  <!-- GRID VIEW -->
  <div *ngIf="dataLoaded && queryOptions.listView === false && listGroups && listGroups.length > 0">
    <div *ngFor="let group of listGroups">
      <orgos-column-container class="kenjo-pb-20px" centerColumns="true">
        <orgos-column size="1" class="kenjo-font-size-18px">
          {{ queryOptions.groupBy === 'isActive' ? i18n.page[group] : group === 'undefined' ? i18n.page['emptyGroup'][queryOptions.groupBy]
          : group }}
        </orgos-column>
        <orgos-column size="0" *ngIf="usersPerGroup && usersPerGroup[group]" class="kenjo-ml-40px">
          {{ usersPerGroup[group].length }} {{ i18n.page.membersInText }} {{ queryOptions.groupBy === 'isActive'? i18n.page[group] : group
          === 'undefined' ? i18n.page['emptyGroup'][queryOptions.groupBy] : group }}
        </orgos-column>
      </orgos-column-container>

      <orgos-column-container wrap="true">
        <orgos-column size="0" *ngFor="let user of usersPerGroup[group]">
          <!-- User card for active users -->
          <a
            *ngIf="user.isActive"
            [routerLink]="['/cloud/people/', user._id, 'personal']"
            class="
              kenjo-block
              kenjo-text-decoration-none
              kenjo-mr-20px
              kenjo-mb-20px
              kenjo-p-20px
              kenjo-border
              kenjo-border-radius
              kenjo-cursor-pointer
              kenjo-text-align-center
              d-card-element
            "
          >
            <orgos-avatar [avatarName]="user.displayName" [photoUrl]="user._photo?._url"></orgos-avatar>
            <kenjo-truncate-text class="kenjo-mt-10px d-card-element-name">{{ user.displayName }}</kenjo-truncate-text>
            <kenjo-truncate-text class="kenjo-mt-5px kenjo-font-color-light-text-757575">{{ user.jobTitle }}</kenjo-truncate-text>
          </a>
          <!-- User card for inactive users: notActivated or deactivated -->
          <a
            *ngIf="!user.isActive"
            [routerLink]="['/cloud/people/', user._id, 'personal']"
            class="
              d-user-opacity
              kenjo-block
              kenjo-text-decoration-none
              kenjo-mr-20px
              kenjo-mb-20px
              kenjo-p-20px
              kenjo-border
              kenjo-border-radius
              kenjo-cursor-pointer
              kenjo-text-align-center
              d-card-element
            "
          >
            <orgos-avatar [avatarName]="user.displayName" [photoUrl]="user._photo?._url"></orgos-avatar>
            <orgos-chip
              *ngIf="!user.inactiveReason"
              class="d-status-label kenjo-text-align-center kenjo-font-color-white-ffffff"
              [color]="'Warning'"
            >
              {{i18n.page.notActivatedStatus}}</orgos-chip
            >
            <orgos-chip
              *ngIf="user.inactiveReason === 'deactivated'"
              class="d-status-label kenjo-text-align-center kenjo-font-color-light-text-757575"
              [color]="'Neutral'"
            >
              {{i18n.page.deactivatedStatus}}</orgos-chip
            >
            <kenjo-truncate-text class="d-card-element-name kenjo-mt-10px">{{ user.displayName }}</kenjo-truncate-text>
            <kenjo-truncate-text class="kenjo-mt-5px kenjo-font-color-light-text-757575">{{ user.jobTitle }}</kenjo-truncate-text>
          </a>
        </orgos-column>
      </orgos-column-container>
    </div>
  </div>
</ng-container>
