import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-working-day-circle',
  templateUrl: 'working-day-circle.component.html',
  styleUrls: ['working-day-circle.component.scss']
})
export class WorkingDayCircle {
  @Input() label: string;
  @Input() value = false;
  @Input() readOnly = false;
  @Output() valueChange = new EventEmitter();

  change() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
