<div #templateDetails class="sptd-card sptd-details-card kenjo-mb-5px">
  <div class="sptd-card-left-border" [style.background-color]="cardColor"></div>
  <div class="kenjo-font-color-light-text-757575">
    <div class="kenjo-font-size-14px kenjo-mb-15px">{{ pageTranslation.duration }}</div>
    <orgos-column-container centerColumns="true" class="sptd-underline kenjo-mb-10px">
      <orgos-column size="1">
        <div class="kenjo-font-size-16px kenjo-font-color-text-000000">{{ startShiftTime }} - {{ endShiftTime }}</div>
      </orgos-column>
      <orgos-column size="0">
        <kenjo-icon [size]="20" [matTooltip]="pageTranslation.addShift">clock</kenjo-icon>
      </orgos-column>
    </orgos-column-container>
    <div class="kenjo-font-size-14px kenjo-mb-15px kenjo-mt-20px">{{ pageTranslation.breakTime }}</div>
    <orgos-column-container centerColumns="true" class="sptd-underline kenjo-mb-10px">
      <orgos-column size="1">
        <div class="kenjo-font-size-16px kenjo-font-color-text-000000">{{ breakShiftTime }}</div>
      </orgos-column>
      <orgos-column size="0">
        <kenjo-icon [size]="20" [matTooltip]="pageTranslation.addShift">clock</kenjo-icon>
      </orgos-column>
    </orgos-column-container>
    <div class="sptd-underline kenjo-mb-10px">
      <div class="kenjo-font-size-14px kenjo-mb-15px kenjo-mt-20px">{{ pageTranslation.location }}</div>
      <div class="kenjo-font-size-16px kenjo-font-color-text-000000">
        <kenjo-truncate-text>{{ template.locationName }}</kenjo-truncate-text>
      </div>
    </div>
    <div class="sptd-underline kenjo-mb-10px">
      <div class="kenjo-font-size-14px kenjo-mb-15px kenjo-mt-20px">{{ pageTranslation.role }}</div>
      <div class="kenjo-font-size-16px kenjo-font-color-text-000000">
        <kenjo-truncate-text>{{ template.roleName }}</kenjo-truncate-text>
      </div>
    </div>
    <div *ngIf="template.workingAreaName" class="sptd-underline kenjo-mb-10px">
      <div class="kenjo-font-size-14px kenjo-mb-15px kenjo-mt-20px">{{ pageTranslation.workingArea }}</div>
      <div class="kenjo-font-size-16px kenjo-font-color-text-000000">
        <kenjo-truncate-text>{{ template.workingAreaName }}</kenjo-truncate-text>
      </div>
    </div>
    <div *ngIf="template.tagName" class="sptd-underline kenjo-mb-10px">
      <div class="kenjo-font-size-14px kenjo-mb-15px kenjo-mt-20px">{{ pageTranslation.tag }}</div>
      <div class="kenjo-font-size-16px kenjo-font-color-text-000000">
        <kenjo-truncate-text>{{ template.tagName }}</kenjo-truncate-text>
      </div>
    </div>
  </div>
</div>
