<orgos-dialog-container class="spcd-dialog-box" *ngIf="capacity" [title]="dialogTranslation.dialogTitle" (clickCloseButton)="closeDialog()">
  <div class="spcd-info-container kenjo-mb-40px">
    <kenjo-icon [size]="14" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
    <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{dialogTranslation.dialogInfoDescription}}</div>
  </div>

  <div class="spcd-table">
    <div>
      <orgos-column-container class="spcd-table-header">
        <orgos-column size="1" class="spcd-days-entry-width">
          <div class="kenjo-font-size-12px kenjo-font-color-text-333333 kenjo-pl-20px kenjo-pv-10px kenjo-font-weight-bold">{{dialogTranslation.daysTableHeader }}</div>
        </orgos-column>
        <orgos-column class="spcd-row-vertical-line">
          <div class="kenjo-font-size-12px kenjo-font-color-text-333333 kenjo-pl-20px kenjo-pv-10px kenjo-font-weight-bold">{{ dialogTranslation.plannedHoursTableHeader}}</div>
        </orgos-column>
      </orgos-column-container>
    </div>

    <div *ngFor="let row of capacityData ; let dayCounter = index" >
      <orgos-column-container centerColumns="true" class="spcd-row-horizontal-line" [class.spcd-row-horizontal-line-last]="dayCounter + 1 === capacityData.length">
        <orgos-column size="1" class="spcd-days-entry-width">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-pl-20px" [class.spcd-disable-row]="false">{{ row.dayName }}</div>
        </orgos-column>
        <orgos-column size="1" class="spcd-row-vertical-line">
          <kenjo-shift-plan-input-capacity-time
            class="kenjo-ml-20px kenjo-mr-10px"
            [value]="capacity[row.key]"
            (valueChange)="updatePlannedCapacity($event, row.key)"
            [keepOriginalMinWidth]="true"
            [enableClearButton]="true"
            (validation)="updateCapacityValidation($event)"
          ></kenjo-shift-plan-input-capacity-time>
        </orgos-column>
      </orgos-column-container>
    </div>
  </div>

  <div class="kenjo-mt-40px kenjo-mb-10px kenjo-text-align-right">
    <orgos-button-raised class="kenjo-mr-20px" color="Neutral" buttonId="spcd-go-back-btn-id" (click)="closeDialog()">{{dialogTranslation.goBackButtonDialog}}</orgos-button-raised>
    <orgos-button-raised color="Success" (click)="savePlannedCapacity()" buttonId="spcd-save-btn-id" [disabled]="!this.capacityValidation || this.capacityValidation.hasErrors()">{{dialogTranslation.saveButtonLabel}}</orgos-button-raised>
  </div>
</orgos-dialog-container>
