<orgos-dialog-container [title]="permissionLabel" (clickCloseButton)="closeDialog()">
  <ng-container *ngFor="let iBlockAndConditions of customPermission; let ruleNumber = index; let isLastOr = last;">
    <orgos-container aspect="card">
      <orgos-column-container class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-bottom kenjo-pv-10px" centerColumns="true">
        <orgos-column size="1" class="kenjo-pl-20px kenjo-font-size-16px">
          {{pageTranslation.ruleTitle}}&nbsp;{{ruleNumber+1}}
        </orgos-column>
        <kenjo-icon [size]="20" orgosColumn="0" (click)="deleteRule(ruleNumber)" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757 kenjo-ph-20px">delete</kenjo-icon>
      </orgos-column-container>

      <div class="kenjo-p-20px">
        <ng-container *ngFor="let iCondition of iBlockAndConditions.blockAndConditions; let conditionIndex = index; let isLastAnd = last;">
          <orgos-custom-permission-condition-component
            [condition]="iCondition"
            (updateInCondition)="evaluateIsValid()"
            (deleteCondition)="deleteConditionInRule(iBlockAndConditions.blockAndConditions, conditionIndex)"
            [permissionKey]="permissionKey"
            [permissionName]="permissionName"
          ></orgos-custom-permission-condition-component>

          <div *ngIf="iBlockAndConditions.blockAndConditions.length > 0 && isLastAnd === false" class="kenjo-text-align-center kenjo-font-size-12px kenjo-font-color-light-text-757575">
            {{pageTranslation.and}}
          </div>
        </ng-container>

        <div class="kenjo-text-align-center kenjo-pt-10px">
          <orgos-action color="Primary" icon="add_circle" (click)="addCondition(iBlockAndConditions.blockAndConditions)"> {{pageTranslation.addConditionAction}}</orgos-action>
        </div>
      </div>
    </orgos-container>

    <div *ngIf="customPermission.length > 0 && isLastOr === false" class="kenjo-text-align-center kenjo-pv-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">
      {{pageTranslation.or}}
    </div>
  </ng-container>

  <orgos-action class="kenjo-mt-20px" icon="add_circle" (click)="addRule()" iconSize="large"> {{pageTranslation.addRuleAction}}</orgos-action>

  <orgos-custom-permission-summary *ngIf="isValid === true && permissionKey" [customPermission]="customPermission" [permissionKey]="permissionKey" class="kenjo-mt-20px"></orgos-custom-permission-summary>

  <orgos-page-message *ngIf="isValid === false" messageType="warning" [messageText]="pageTranslation.ruleNotCompleted" class="kenjo-mt-20px"></orgos-page-message>

  <div class="kenjo-mt-60px kenjo-text-align-right">
    <orgos-button-raised color="Success" (click)="savePermissions()" [disabled]="isValid === false"> {{pageTranslation.finishButton}}</orgos-button-raised>
  </div>
</orgos-dialog-container>
