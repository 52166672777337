<orgos-dialog-container class="dialog-box" [title]="titleText" (clickCloseButton)="cancelRejection()">
  <div class="kenjo-font-color-light-text-757575">{{i18n.dialog.dialogSubtitle}}</div>
  <orgos-column-container *ngFor="let rejectionReason of rejectionReasons" class="kenjo-mt-10px">
    <orgos-input-simple-radio-button orgosColumn="0" [label]="rejectionReason" [value]="rejectionReasonSelected === rejectionReason ? true : false" (valueChange)="changeRejectionReason(rejectionReason, $event)"></orgos-input-simple-radio-button>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="cancelRejection()">{{miscTranslation.goBackButtonDialog}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Danger" (click)="acceptRejection()">{{i18n.dialog.rejectionButton}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
