<mat-form-field *ngIf="!readOnly" (click)="enableSearching()" cdk-overlay-origin>
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getValueName()" (ngModelChange)="searchTerm = $event" [required]="required" #isacInput autocomplete="off" />
  <mat-icon matSuffix *ngIf="searching">search</mat-icon>
  <div matSuffix *ngIf="!searching" class="isac-select-arrow-wrapper mat-select-arrow"></div>
  <mat-icon *ngIf="!searching && showClearButton" matSuffix class="isac-clear-button" (click)="clearValue(); $event.stopPropagation()"
    >clear</mat-icon
  >
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getValueName()" readOnly disabled [required]="required" />
</mat-form-field>
<ng-template cdk-portal>
  <orgos-container class="isac-results-container">
    <orgos-text *ngIf="searchResults && searchResults.length === 0" color="Warn" class="kenjo-p-10px">{{
      miscTranslation.noResultsFound
    }}</orgos-text>
    <orgos-text
      *ngFor="let result of searchResults; let i = index"
      class="kenjo-p-10px isac-result"
      [class.isac-result-focused]="indexSearchResultFocused === i"
      [class.isac-disabled-entry]="result.disabled"
      (click)="!result.disabled ? selectOption(result.value) : return;"
      orgosAutocompleteOption
      >{{ result.name }}</orgos-text
    >
  </orgos-container>
</ng-template>
