import { CommonModule } from '@angular/common';
// prettier-ignore
// tslint:disable:ordered-imports
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExpansionPanelModule } from '@app/common-components/kenjo-expansion-panel/kenjo-expansion-panel.module';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { TimeOffStepComponent } from '@app/common-components/widgets/punch-clock/steps/time-off-step.component';
import { MaterialModule } from '@app/material.module';
import { ActionButtonComponent } from '@app/standard/components/action-button/action-button.component';
import { AttendanceCategorySelectComponent } from '@app/standard/components/attendance-category-select/attendance-category-select.component';
import { AudienceSelectorComponent } from '@app/standard/components/audience-selector/component/audience-selector.component';
import { AudienceSelectorComponentService } from '@app/standard/components/audience-selector/services/audience-selector.component.service';
import { ButtonFlatComponent } from '@app/standard/components/button-flat/button-flat.component';
import { ButtonWatchTutorialComponent } from '@app/standard/components/button-watch-tutorial/button-watch-tutorial.component';
import { TimePipe } from '@app/standard/components/duration/time.pipe';
import { FeatureGatingIconComponent } from '@app/standard/components/feature-gating/components/feature-gating-icon/feature-gating-icon.component';
import { DefaultPaywallDialog } from '@app/standard/components/feature-gating/dialogs/default-paywall-dialog/default-paywall.dialog';
import { FormattedValuePipe } from '@app/standard/components/formatted-value/formatted-value.pipe';
import { GroupByComponent } from '@app/standard/components/group-by/group-by.component';
import { IconToggleComponent } from '@app/standard/components/icon-toggle/icon-toggle.component';
import { InputSimpleHourlyRangeComponent } from '@app/standard/components/input-simple-hourly-range/input-simple-hourly-range.component';
import { WidgetHeaderChipComponent } from '@app/standard/components/widget-header-chip/widget-header-chip.component';
import { BreaksComponent } from '@app/standard/pages/people-detail/people-detail-attendance/components/breaks-component/breaks.component';
import { AttendanceOtherTimeOffsDialog } from '@app/standard/pages/people-detail/people-detail-attendance/dialogs/attendance-other-time-offs/attendance-other-time-offs.dialog';
import { BreakReminderDialog } from '@app/standard/pages/people-detail/people-detail-attendance/dialogs/break-reminder/break-reminder.dialog';
import { AttendanceBankHolidaysDialog } from '@app/standard/pages/people-detail/people-detail-attendance/dialogs/attendance-bank-holidays/attendance-bank-holidays.dialog';
import { BreaksDialog } from '@app/standard/pages/people-detail/people-detail-attendance/dialogs/breaks-dialog/breaks.dialog';
// Standalone components that need to be copied to the standard components module
import { ChipV2Component } from '@app/standard/standalone-components/chip/chip.component';
import { UserAccountStatusChipComponent } from '@app/standard/standalone-components/chip/user-account-status-chip/user-account-status-chip.component';
import { UserInvitationStatusChipComponent } from '@app/standard/standalone-components/chip/user-invitation-status-chip/user-invitation-status-chip.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { QuillModule } from 'ngx-quill';

import { DirectivesModule } from '../directives/directives.module';
import { ActionComponent } from './action/action.component';
import { AutoDeductDialogComponent } from './auto-deduct-break-dialog/auto-deduct-dialog.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ButtonRaisedComponent } from './button-raised/button-raised.component';
import { ButtonToggleGroupComponent } from './button-toggle-group/button-toggle-group.component';
import { ButtonToggleComponent } from './button-toggle/button-toggle.component';
import { ButtonComponent } from './button/button.component';
import { CalendarMonthComponent } from './calendar-month/calendar-month.component';
import { CalendarYearComponent } from './calendar-year/calendar-year.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ChipComponent } from './chip/chip.component';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { CollapsibleWrapperComponent } from './collapsible-wrapper/collapsible-wrapper.component';
import { ColumnContainerComponent } from './column-container/column-container.component';
import { ColumnComponent } from './column/column.component';
import { ColumnDirective } from './column/column.directive';
import { CommentEditorComponent } from './comment-editor/comment-editor.component';
import { ConfirmDialogFormComponent } from './confirm-dialog-form/confirm-dialog-form.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmLinkDialogComponent } from './confirm-link-dialog/confirm-link-dialog.component';
import { ConfirmListDialogComponent } from './confirm-list-dialog/confirm-list-dialog.component';
import { ContainerComponent } from './container/container.component';
import { ConversationComponent } from './conversation/conversation.component';
import { CustomAppCardComponent } from './custom-app-card/custom-app-card.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { DocumentIconComponent } from './document-icon/document-icon.component';
import { DurationPipe } from './duration/duration.pipe';
import { TimeAgoPipe } from './duration/time-ago.pipe';
import { TimeCompare } from './duration/time-compare.pipe';
import { TimeOffTimePipe } from './duration/time-off-time.pipe';
import { TimeShiftPlanPipe } from './duration/time-shiftplan.pipe';
import { EditorViewerComponent } from './editor-viewer/editor-viewer.component';
import { EngagementBoxComponent } from './engagement-box/engagement-box.component';
import { EnpsResultComponent } from './enps-result/enps-result.component';
import { ExtensibleTableComponent } from './extensible-table/extensible-table.component';
import { FactorScoreComponent } from './factor-score/factor-score.component';
import { FeatureBoxComponent } from './feature-box/feature-box.component';
import { FeedComment } from './feed-comment/feed-comment.component';
import { FeedCommentsDialog } from './feed/dialogs/feed-comments.dialog';
import { FeedComponent } from './feed/feed.component';
import { FilterBarFieldComponent } from './filter-bar-field/filter-bar-field.component';
import { FilterBarTagComponent } from './filter-bar-tag/filter-bar-tag.component';
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { FormatDatePipe } from './format-date/format-date.pipe';
import { HumanDatePipe } from './human-date/human-date.pipe';
import { I18nDataPipe } from './i18n-data/i18n-data.pipe';
import { InputAbstractComponent } from './input-abstract/input-abstract.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { InputCodeNumberComponent } from './input-code-number/input-code-number.component';
import { InputColorPickerComponent } from './input-color-picker/input-color-picker.component';
import { EditCustomFieldDialog } from './input-custom-field/dialogs/edit-custom-field.dialog';
import { InputCustomFieldComponent } from './input-custom-field/input-custom-field.component';
import { InputDateCalendarPickerComponent } from './input-date-calendar-picker/input-date-calendar-picker.component';
import { InputDatePickerComponent } from './input-date-picker/input-date-picker.component';
import { InputDateRangeCalendarPickerComponent } from './input-date-range-calendar-picker/input-date-range-calendar-picker.component';
import { InputDateRangePickerComponent } from './input-date-range-picker/input-date-range-picker.component';
import { InputEditorComponent } from './input-editor/input-editor.component';
import { InputEmailNameComponent } from './input-email-name/input-email-name.component';
import { InputEmailComponent } from './input-email/input-email.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { InputGradientColorPickerComponent } from './input-gradient-color-picker/input-gradient-color-picker.component';
import { InputHintComponent } from './input-hint/input-hint.component';
import { InputIconPickerComponent } from './input-icon-picker/input-icon-picker.component';
import { InputLinearScaleComponent } from './input-linear-scale/input-linear-scale.component';
import { InputMonthPickerComponent } from './input-month-picker/input-month-picker.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { InputSearchUserComponent } from './input-search-user/input-search-user.component';
import { InputSelectDropdownComponent } from './input-select-dropdown/input-simple-select-dropdown.component';
import { InputSelectTimeComponent } from './input-select-time/input-select-time.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { AutocompleteOptionDirective } from './input-simple-autocomplete/autocomplete-option.directive';
import { InputSimpleAutocompleteComponent } from './input-simple-autocomplete/input-simple-autocomplete.component';
import { InputSimpleCheckboxComponent } from './input-simple-checkbox/input-simple-checkbox.component';
import { InputSimpleColorPickerComponent } from './input-simple-color-picker/input-simple-color-picker.component';
import { InputSimpleDatePickerBoxComponent } from './input-simple-date-picker-box/input-simple-date-picker-box.component';
import { InputSimpleDatePickerComponent } from './input-simple-date-picker/input-simple-date-picker.component';
import { InputSimpleEditorComponent } from './input-simple-editor/input-simple-editor.component';
import { InputSimpleEmailComponent } from './input-simple-email/input-simple-email.component';
import { InputSimpleEmojiComponent } from './input-simple-emoji/input-simple-emoji.component';
import { InputSimpleNumberComponent } from './input-simple-number/input-simple-number.component';
import { NoNegativeInputDirective } from './input-simple-number/no-negative-input.directive';
import { InputSimpleRadioButtonComponent } from './input-simple-radio-button/input-simple-radio-button.component';
import { InputSimpleRadioComponent } from './input-simple-radio/input-simple-radio.component';
import { InputSimpleSearchUserComponent } from './input-simple-search-user/input-simple-search-user.component';
import { InputSimpleSelectComponent } from './input-simple-select/input-simple-select.component';
import { InputSimpleTextAreaComponent } from './input-simple-text-area/input-simple-text-area.component';
import { InputSimpleTextAutocompleteComponent } from './input-simple-text-autocomplete/input-simple-text-autocomplete.component';
import { InputSimpleTextComponent } from './input-simple-text/input-simple-text.component';
import { InputSimpleTimeComponent } from './input-simple-time/input-simple-time.component';
import { InputSlideToggleComponent } from './input-slide-toggle/input-slide-toggle.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { InputTextAutocompleteComponent } from './input-text-autocomplete/input-text-autocomplete.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTimeFormControlComponent } from './input-time-form-control/input-time-form-control.component';
import { AllowNumbersOnlyDirective } from './input-time-form-control/numbers-only.directive';
import { InputToggleRoundSimpleComponent } from './input-toggle-round-simple/input-toggle-round-simple.component';
import { InputToggleRoundComponent } from './input-toggle-round/input-toggle-round.component';
import { InputUrlComponent } from './input-url/input-url.component';
import { LimitListPipe } from './limit-list-pipe/limit-list.pipe';
import { ListItemDirective } from './list-sortable/list-item.directive';
import { ListSortableComponent } from './list-sortable/list-sortable.component';
import { CreateListViewDialog } from './list-view/dialogs/create-list-view.dialog';
import { ListViewComponent } from './list-view/list-view.component';
import { ListComponent } from './list/list.component';
import { loadingIconComponent } from './loading-icon/loading-icon.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MonthDurationComponent } from './month-duration/month-duration.component';
import { OutputComponent } from './output/ouput.component';
import { PageMessageComponent } from './page-message/page-message.component';
import { PlusMinusSignPipe } from './plus-minus-sign/plus-minus-sign.pipe';
import { PoweredByComponent } from './powered-by/powered-by.component';
import { PulseChartComponent } from './pulse-chart/pulse-chart.component';
import { PulseParticipationComponent } from './pulse-participation/pulse-participation.component';
import { ReportBarComponent } from './report-bar/report-bar.component';
import { ReportStackedComponent } from './report-stacked/report-stacked.component';
import { ReportSummaryComponent } from './report-summary/report-summary.component';
import { ReportTableComponent } from './report-table/report-table.component';
import { ReviewCardComponent } from './review-card/review-card.component';
import { RowContainerComponent } from './row-container/row-container.component';
import { RowSelectionComponent } from './row-selection/row-selection.component';
import { RowComponent } from './row/row.component';
import { RowDirective } from './row/row.directive';
import { SearchItemDropdownCustomComponent } from './search-item-dropdown-custom/search-item-dropdown-custom.component';
import { SearchItemDropdownComponent } from './search-item-dropdown/search-item-dropdown.component';
import { SearchOptionComponent } from './search-option/search-option.component';
import { SearchComponent } from './search/search.component';
import { KenjoSeeMoreButton } from './see-more-button/see-more-button.component';
import { SelectItemAttendancePolicyComponent } from './select-custom-items/select-item-attendance-policy/select-item-attendance-policy.component';
import { SelectItemCategoryComponent } from './select-custom-items/select-item-category/select-item-category.component';
import { SelectDropdownCustomComponent } from './select-dropdown-custom/select-dropdown-custom.component';
import { SelectSimpleDropdownCustomComponent } from './select-simple-dropdown-custom/select-simple-dropdown-custom.component';
import { SplitDurationPipe } from './split-duration/split-duration.pipe';
import { TableHeatmapCellDirective } from './table-heatmap/table-heatmap-cell.directive';
import { TableHeatmapColumnDirective } from './table-heatmap/table-heatmap-column.directive';
import { TableHeatmapComponent } from './table-heatmap/table-heatmap.component';
import { TableSmartCellDirective } from './table-smart/table-smart-cell.directive';
import { TableSmartColumnDirective } from './table-smart/table-smart-column.directive';
import { TableHeaderIconDirective } from './table-smart/table-smart-header-icon.directive';
import { TableSmartComponent } from './table-smart/table-smart.component';
import { TableCellDirective } from './table/table-cell.directive';
import { TableColumnDirective } from './table/table-column.directive';
import { TableComponent } from './table/table.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { TaskWithButtonsComponent } from './task-with-buttons/task-with-buttons.component';
import { TaskComponent } from './task/task.component';
import { TextIconComponent } from './text-icon/text-icon.component';
import { TextComponent } from './text/text.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserItemComponent } from './user-item/user-item.component';
import { UtcDatePipe } from './utc-date/utc-date.pipe';
import { WidgetContentComponent } from './widget-content/widget-content.component';
import { WidgetFooterComponent } from './widget-footer/widget-footer.component';
import { WidgetHeaderActionComponent } from './widget-header-action/widget-header-action.component';
import { WidgetComponent } from './widget/widget.component';
import { WorkflowFilterComponent } from './workflow-filter/workflow-filter.component';

const standardComponents = [
  ActionButtonComponent,
  ActionComponent,
  AvatarComponent,
  ButtonComponent,
  ButtonFlatComponent,
  ButtonRaisedComponent,
  ButtonToggleComponent,
  ButtonToggleGroupComponent,
  CalendarComponent,
  ChipComponent,
  ClickOutsideDirective,
  CollapsibleWrapperComponent,
  ColumnComponent,
  ColumnDirective,
  ColumnContainerComponent,
  ConfirmDialogFormComponent,
  ConfirmDialogComponent,
  ConfirmLinkDialogComponent,
  ConfirmListDialogComponent,
  loadingIconComponent,
  ContainerComponent,
  CustomAppCardComponent,
  CustomFieldsComponent,
  CreateListViewDialog,
  DialogContainerComponent,
  DurationPipe,
  SplitDurationPipe,
  FormattedValuePipe,
  LimitListPipe,
  KenjoSeeMoreButton,
  EditCustomFieldDialog,
  EditorViewerComponent,
  CommentEditorComponent,
  ConversationComponent,
  EnpsResultComponent,
  DocumentIconComponent,
  EngagementBoxComponent,
  FactorScoreComponent,
  FeatureBoxComponent,
  FeedComment,
  FeedCommentsDialog,
  FeedComponent,
  FilterBarComponent,
  FilterBarFieldComponent,
  FilterBarTagComponent,
  GroupByComponent,
  HumanDatePipe,
  UtcDatePipe,
  I18nDataPipe,
  InputAbstractComponent,
  InputCheckboxComponent,
  InputColorPickerComponent,
  InputCustomFieldComponent,
  InputDatePickerComponent,
  InputDateCalendarPickerComponent,
  InputDateRangeCalendarPickerComponent,
  InputDateRangePickerComponent,
  InputEditorComponent,
  InputEmailComponent,
  InputEmailNameComponent,
  InputErrorComponent,
  InputGradientColorPickerComponent,
  InputGradientColorPickerComponent,
  InputHintComponent,
  InputIconPickerComponent,
  InputLinearScaleComponent,
  InputNumberComponent,
  InputMonthPickerComponent,
  InputPasswordComponent,
  InputRadioComponent,
  InputSearchUserComponent,
  InputSelectDropdownComponent,
  InputSelectComponent,
  InputSimpleEmojiComponent,
  InputSelectDropdownComponent,
  AutocompleteOptionDirective,
  InputSimpleAutocompleteComponent,
  InputSimpleCheckboxComponent,
  InputSimpleColorPickerComponent,
  InputSimpleDatePickerComponent,
  InputSimpleDatePickerBoxComponent,
  InputSimpleEditorComponent,
  InputSimpleEmailComponent,
  InputSimpleNumberComponent,
  InputCodeNumberComponent,
  InputSimpleRadioComponent,
  InputSimpleRadioButtonComponent,
  InputSimpleSearchUserComponent,
  InputSimpleSelectComponent,
  InputSimpleTextComponent,
  SearchItemDropdownCustomComponent,
  SearchItemDropdownComponent,
  SelectDropdownCustomComponent,
  SelectSimpleDropdownCustomComponent,
  InputSimpleTextAreaComponent,
  InputSimpleTextAutocompleteComponent,
  InputTimeFormControlComponent,
  InputSimpleTimeComponent,
  InputSlideToggleComponent,
  InputTextComponent,
  InputSelectTimeComponent,
  InputTextAutocompleteComponent,
  InputTextAreaComponent,
  InputToggleRoundComponent,
  IconToggleComponent,
  InputToggleRoundSimpleComponent,
  InputUrlComponent,
  ListComponent,
  ListItemDirective,
  ListSortableComponent,
  ListViewComponent,
  LoadingSpinnerComponent,
  MonthDurationComponent,
  OutputComponent,
  PageMessageComponent,
  PlusMinusSignPipe,
  PoweredByComponent,
  PulseChartComponent,
  PulseParticipationComponent,
  ReportBarComponent,
  ReportStackedComponent,
  ReportSummaryComponent,
  ReportTableComponent,
  RowComponent,
  RowDirective,
  RowContainerComponent,
  RowSelectionComponent,
  ReviewCardComponent,
  SearchComponent,
  SearchOptionComponent,
  SelectItemAttendancePolicyComponent,
  SelectItemCategoryComponent,
  TableCellDirective,
  TableColumnDirective,
  TableComponent,
  ExtensibleTableComponent,
  TableSmartComponent,
  TableSmartColumnDirective,
  TableSmartCellDirective,
  TableHeaderIconDirective,
  TableHeatmapComponent,
  TableHeatmapColumnDirective,
  TableHeatmapCellDirective,
  TagListComponent,
  TaskComponent,
  TaskWithButtonsComponent,
  TextComponent,
  TextIconComponent,
  ToggleButtonComponent,
  TimeShiftPlanPipe,
  TimePipe,
  TimeOffTimePipe,
  UserHeaderComponent,
  UserItemComponent,
  WorkflowFilterComponent,
  WidgetComponent,
  WidgetContentComponent,
  WidgetFooterComponent,
  WidgetHeaderActionComponent,
  WidgetHeaderChipComponent,
  AllowNumbersOnlyDirective,
  AudienceSelectorComponent,
  AttendanceCategorySelectComponent,
  InputSimpleHourlyRangeComponent,
  CalendarMonthComponent,
  CalendarYearComponent,
  BreaksComponent,
  BreaksDialog,
  AutoDeductDialogComponent,
  BreakReminderDialog,
  AttendanceOtherTimeOffsDialog,
  AttendanceBankHolidaysDialog,
  DefaultPaywallDialog,
  FeatureGatingIconComponent,
  NoNegativeInputDirective,
  TimeAgoPipe,
  TimeCompare,
  FormatDatePipe,
  ButtonWatchTutorialComponent,
];

const standaloneComponents = [
  // ideally, this array should be empty
  UserInvitationStatusChipComponent,
  UserAccountStatusChipComponent,
  ChipV2Component,
  TimeOffStepComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    QuillModule,
    PickerModule,
    EmojiModule,
    TruncateTextModule,
    IconModule,
    ExpansionPanelModule,
    DirectivesModule,
    ...standaloneComponents,
  ],
  declarations: standardComponents,
  providers: [
    I18nDataPipe,
    HumanDatePipe,
    TimeAgoPipe,
    TimeCompare,
    UtcDatePipe,
    FormattedValuePipe,
    DurationPipe,
    SplitDurationPipe,
    TimeShiftPlanPipe,
    TimeOffTimePipe,
    FormatDatePipe,
    AudienceSelectorComponentService,
    TimePipe,
  ],
  exports: standardComponents,
})
export class StandardComponentsModule {}
