import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class FilterBarController {
  filtersPath = {
    directory: 'people/directory-tab',
    'my-docs': 'smart-docs/my-docs-tab',
    'kiosk-sessions': 'kiosk/sessions',
    'manage-employees': 'time-off/manage-employees',
    'attendance-tracking-directory': 'attendance/attendance-tracking-directory',
    'attendance-manage-directory': 'attendance/attendance-manage-directory'
  };

  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/components/filter-bar`;

  constructor(private injector: Injector, private http: HttpClient) {}

  async getFiltersByKey(key: string): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get(`${this.URL}/${this.filtersPath[key]}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FilterBarController.name, 'getFiltersByKey');
    }
  }

  async getDashboardFilters(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get(`${this.URL}/dashboards/dashboards-filters`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FilterBarController.name, 'getDashboardFilters');
    }
  }

  async getDigitalSignaturesFilters(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get(`${this.URL}/smart-docs/digital-signatures-tab`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, FilterBarController.name, 'getDashboardFilters');
    }
  }
}
