<div
  *ngIf="options.frequency === views.WEEK"
  class="scp-container"
  [ngClass]="{
    'scp-draft': shift.status === status.DRAFT,
    'scp-published': shift.status === status.PUBLISHED,
    monthly: options.frequency === views.MONTH,
    'scp-draft-default': !options.color && shift.status === status.DRAFT,
    'scp-draft-color': options.color && shift.status === status.DRAFT
  }"
  [ngStyle]="{
    '--gradient-color': options.color && shift.status === status.DRAFT ? colorConstants[shift.role.colour] : null,
    'background-color': options.color && shift.status === status.PUBLISHED ? colorConstants[shift.role.colour] : null
  }"
>
  <div *ngIf="shift.status === status.DRAFT" class="scp-card-left-border"></div>
  <div class="kenjo-font-weight-bold">{{ start }} - {{ end }}</div>
  <div>{{ roleName }}</div>
  <div *ngIf="options.includeWorkingTime && options.orientation === 'portrait'" class="kenjo-p-5px"></div>
</div>

<div
  *ngIf="options.frequency === views.MONTH"
  class="scp-container monthly"
  [ngClass]="{
    'scp-draft': shift.status === status.DRAFT,
    'scp-published': shift.status === status.PUBLISHED,
    monthly: options.frequency === views.MONTH,
    'scp-draft-default': !options.color && shift.status === status.DRAFT,
    'scp-draft-color': options.color && shift.status === status.DRAFT
  }"
  [ngStyle]="{
    '--gradient-color': options.color && shift.status === status.DRAFT ? colorConstants[shift.role.colour] : null,
    'background-color': options.color && shift.status === status.PUBLISHED ? colorConstants[shift.role.colour] : null
  }"
>
  <div *ngIf="shift.status === status.DRAFT" class="scp-card-left-border"></div>
  <div class="kenjo-font-weight-bold">{{ roleName }}</div>
</div>
