import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { ITimeOffStatusHistory, TimeOffStatusHistoryService } from '@app/cloud-features/time-off/services/time-off-status-history.service';
import { ITimeOffStatus } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { IHistoryEntry } from '@app/common-components/time-off-user-personal/components/status-details-card/status-details-card.component';
import { IUserWorkModel } from '@app/models/user-work.model';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import {
  ACCRUAL_PERIOD_MONTHLY,
  TIME_OFF_ACTION_ACCRUAL,
  TIME_OFF_ACTION_ADJUST_BALANCE,
  TIME_OFF_ACTION_ADJUST_CARRY_OVER,
  TIME_OFF_ACTION_ADJUST_COMPENSATION,
  TIME_OFF_ACTION_ADJUST_TAKEN,
  TIME_OFF_ACTION_ASSIGN_POLICY,
  TIME_OFF_ACTION_REASSIGN_POLICY
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-adjustment-history-button',
  templateUrl: 'adjustment-history-button.component.html',
  styleUrls: ['adjustment-history-button.component.scss']
})
export class AdjustmentHistoryButtonComponent implements OnInit {
  @Input() status: ITimeOffStatus;
  @Input() field: 'givenCompensationHistory' | 'cycleAllowanceHistory' | 'adjustTakenHistory' | 'adjustCarryOverHistory';
  @Input() policy: ITimeOffPolicyModel;
  @Input() userWork: IUserWorkModel;
  @Input() translations: { [key: string]: string };
  @Input() showTitle: boolean = true;
  @Output() onOpenHistory: EventEmitter<boolean> = new EventEmitter<boolean>();

  isHistoryOpen: boolean = false;
  historyEntries: Array<IHistoryEntry>;
  historyTranslations: { [key: string]: string };
  showTooltip: boolean = false;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initTranslations();
  }

  private initTranslations() {
    this.historyTranslations = { day: this.translations.day, days: this.translations.days };
  }

  public async openHistory() {
    this.isHistoryOpen = !this.isHistoryOpen;
    this.onOpenHistory.emit(this.isHistoryOpen);
    if (check.not.assigned(this.historyEntries)) {
      this.historyEntries = await this.getHistoryEntries();
    }
  }

  private async getHistoryEntries() {
    switch (this.field) {
      case 'givenCompensationHistory':
        return await this.getGivenCompensationHistory();
      case 'adjustTakenHistory':
        return await this.getAdjustTakenHistory();
      case 'adjustCarryOverHistory':
        return await this.getAdjustCarryOverHistory();
      case 'cycleAllowanceHistory':
        return await this.getCycleAllowanceHistory();
    }
  }

  private async getGivenCompensationHistory() {
    const compensationHistory = await this.injector.get(TimeOffStatusHistoryService).find({ _timeOffStatusId: this.status._id, _action: TIME_OFF_ACTION_ADJUST_COMPENSATION });
    return this.getHistoryValues(compensationHistory, this.translations.addedOn);
  }

  private async getAdjustTakenHistory() {
    const adjustTakenHistory = await this.injector.get(TimeOffStatusHistoryService).find({ _timeOffStatusId: this.status._id, _action: TIME_OFF_ACTION_ADJUST_TAKEN });
    const adjustTakenValues = this.getHistoryValues(adjustTakenHistory, this.translations.adjustedOn);
    adjustTakenValues.push(this.getHeaderTextAndValue(this.translations.initialTaken, timeOffHelpers.getInitialTaken(this.status)));
    return adjustTakenValues;
  }

  private async getAdjustCarryOverHistory() {
    const adjustCarryOverHistory = await this.injector.get(TimeOffStatusHistoryService).find({ _timeOffStatusId: this.status._id, _action: TIME_OFF_ACTION_ADJUST_CARRY_OVER });
    const adjustCarryOverValues = this.getHistoryValues(adjustCarryOverHistory, this.translations.adjustedOn);
    adjustCarryOverValues.push(this.getHeaderTextAndValue(this.translations.initialCarryOver, timeOffHelpers.getInitialCarryOver(this.status)));
    return adjustCarryOverValues;
  }

  private async getCycleAllowanceHistory() {
    const events = [];
    if (this.status.adjustBalance !== 0) {
      events.push(TIME_OFF_ACTION_ADJUST_BALANCE);
    }
    if (this.policy.accrualPeriod === ACCRUAL_PERIOD_MONTHLY) {
      events.push(TIME_OFF_ACTION_ACCRUAL, TIME_OFF_ACTION_REASSIGN_POLICY, TIME_OFF_ACTION_ASSIGN_POLICY);
    }
    const cycleAllowanceHistory = await this.injector.get(TimeOffStatusHistoryService).find({ _timeOffStatusId: this.status._id, _action: { $in: events } });
    cycleAllowanceHistory.sort((a, b) => moment.utc(b._createdAt).diff(a._createdAt));
    if (this.policy.accrualPeriod !== ACCRUAL_PERIOD_MONTHLY) {
      return this.getYearlyCycleAllowanceHistory(cycleAllowanceHistory);
    }
    return this.getMonthlyCycleAllowanceHistory(cycleAllowanceHistory);
  }

  private getYearlyCycleAllowanceHistory(cycleAllowanceHistory: Array<ITimeOffStatusHistory>) {
    const adjustBalanceValues = this.getHistoryValues(cycleAllowanceHistory, this.translations.adjustedOn);
    adjustBalanceValues.push(this.getHeaderTextAndValue(this.translations.initialCycleAllowance, timeOffHelpers.getInitialCycleAllowance(this.status)));
    return adjustBalanceValues;
  }

  private getMonthlyCycleAllowanceHistory(cycleAllowanceHistory: Array<ITimeOffStatusHistory>) {
    const reassignHistory = cycleAllowanceHistory.find((iHistory) => iHistory._action === TIME_OFF_ACTION_REASSIGN_POLICY);
    const filteredCycleAllowanceHistory = cycleAllowanceHistory.filter((iHistory) => {
      if (iHistory._action === TIME_OFF_ACTION_ADJUST_BALANCE) {
        return true;
      }
      if (iHistory._action === TIME_OFF_ACTION_ACCRUAL && (check.not.assigned(reassignHistory) || moment.utc(iHistory._createdAt).isAfter(reassignHistory._createdAt))) {
        return true;
      }
      return false;
    });

    const cycleAllowanceValues = filteredCycleAllowanceHistory.map((iHistory, index) => {
      if (iHistory._action === TIME_OFF_ACTION_ADJUST_BALANCE) {
        return this.getHistoryTextAndValue(iHistory, this.translations.adjustedOn);
      }
      const tooltipInfo = this.getTooltipInfo(iHistory);
      if (check.assigned(tooltipInfo)) {
        this.showTooltip = true;
      }
      return this.getHistoryTextAndValue(iHistory, this.translations.grantedOn, tooltipInfo);
    });
    cycleAllowanceValues.push(this.getMonthlyCycleAllowanceHeader(cycleAllowanceHistory, reassignHistory));
    return cycleAllowanceValues;
  }

  private getMonthlyCycleAllowanceHeader(cycleAllowanceHistory, reassignHistory) {
    let initialAllowanceStatus;
    if (check.assigned(reassignHistory)) {
      initialAllowanceStatus = reassignHistory;
    } else {
      const assignHistory = cycleAllowanceHistory.find((iHistory) => iHistory._action === TIME_OFF_ACTION_ASSIGN_POLICY);
      if (check.assigned(assignHistory)) {
        initialAllowanceStatus = assignHistory;
      } else {
        initialAllowanceStatus = {
          ...this.status,
          adjustment: 0,
          accrued: 0,
          adjustAccrued: 0,
          _createdAt: this.status.cycleStartDate
        };
      }
    }
    const tooltipInfo = this.getTooltipInfo(initialAllowanceStatus);
    if (check.assigned(tooltipInfo)) {
      this.showTooltip = true;
    }
    return this.getHeaderTextAndValue(this.translations.initialCycleAllowance, timeOffHelpers.getInitialCycleAllowance(initialAllowanceStatus), tooltipInfo);
  }

  private getHeaderTextAndValue(text: string, value: number, tooltipInfo?: string) {
    const info: IHistoryEntry = { text, value, highlight: true, noSign: true };
    if (check.assigned(tooltipInfo)) {
      info.tooltipInfo = tooltipInfo;
    }
    return info;
  }

  private getHistoryValues(historyEntries: Array<ITimeOffStatusHistory>, titleTranslation: string) {
    historyEntries.sort((a, b) => moment.utc(b._createdAt).diff(a._createdAt));
    return historyEntries.map((iHistory) => {
      return this.getHistoryTextAndValue(iHistory, titleTranslation);
    });
  }

  private getTooltipInfo(iHistory: ITimeOffStatusHistory) {
    if (iHistory.adjustAccrued === 0 && (check.assigned(iHistory.adjustedAccrualSplits) || (iHistory._action === TIME_OFF_ACTION_ASSIGN_POLICY && check.assigned(iHistory.accrualSplits)))) {
      const accrualSplits = iHistory.adjustedAccrualSplits ?? iHistory.accrualSplits;
      const startDate = check.assigned(this.userWork.startDate) ? moment.utc(this.userWork.startDate).format('L') : '';
      const endDate = check.assigned(this.userWork.contractEnd) ? moment.utc(this.userWork.contractEnd).format('L') : '';
      if (accrualSplits.startDate !== 0 && accrualSplits.endDate !== 0) {
        return this.injector.get(I18nDataPipe).transform(this.translations.startAndEndDateProration, { startDate, endDate });
      } else if (accrualSplits.startDate !== 0) {
        return this.injector.get(I18nDataPipe).transform(this.translations.startDateProration, { startDate });
      } else if (accrualSplits.endDate !== 0) {
        return this.injector.get(I18nDataPipe).transform(this.translations.endDateProration, { endDate });
      }
    }
  }

  private getHistoryTextAndValue(iHistory: ITimeOffStatusHistory, titleTranslation: string, tooltipInfo?: string) {
    const date = this.injector.get(DatePipe).transform(iHistory._createdAt, 'shortDate');
    const historyText = this.injector.get(I18nDataPipe).transform(titleTranslation, { date });
    const historyTextAndValue: IHistoryEntry = { text: historyText, value: iHistory.adjustment };
    if (check.assigned(tooltipInfo)) {
      historyTextAndValue.tooltipInfo = tooltipInfo;
    }
    return historyTextAndValue;
  }
}
