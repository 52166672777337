<orgos-dialog-container class="dialog-box" [title]="fullDetail ? translation.titleFullHistory : translation.title" (clickCloseButton)="closeDialog()" *ngIf="!loadingTableInfo">
  <div class="obhd-header">
    <div class="kenjo-font-color-text-000000 kenjo-font-size-18px">{{this.months[this.month - 1]}}, {{this.year}}</div>
    <orgos-action
      [matTooltip]="(translation.recalculationMessage | i18nData: ({ date: maxRecalculationDate | utcDate | humanDate: false:'short' })) + ' ' + ((maxRecalculationDate.isSame(earliestCalculation)) 
      ? translation.recalculationMessageEarlier : '') "
      [disabled]="hasRecalculated"
      (click)="recalculateUser()"
      icon="sync"
    >
      {{translation.recalculate}}
    </orgos-action>
  </div>

  <div class="obhd-info-message">
    <kenjo-icon [size]="20" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
    <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-ml-10px">{{translation.somethingDoesNotAddUp}}</div>
  </div>

  <orgos-warning-message *ngIf="showAdjustmentWarning" class="kenjo-mv-15px">{{translation.overtimeWasManuallyAdjustedRecently}}</orgos-warning-message>

  <orgos-column-container class="kenjo-mt-15px">
    <orgos-column class="kenjo-pr-120px">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333 obhd-overtime-label">{{translation.startingBalance}}</div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333">{{(lastMonthOvertime ? lastMonthOvertime : 0) | duration : true}}</div>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-mt-15px">
    <orgos-column class="kenjo-pr-120px">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333 obhd-overtime-label">{{translation.overtimeInCurrentMonth | i18nData: ({ currentMonth: this.months[this.month - 1] })}}</div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333">{{(trackedOvertime ? trackedOvertime : 0) | duration: true}}</div>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-mt-15px">
    <orgos-column class="kenjo-pr-120px">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333 obhd-overtime-label">{{translation.compensatedInCurrentMonth | i18nData: ({ currentMonth: this.months[this.month - 1] })}}</div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333">{{(compensatedHours ? -compensatedHours : -0) | duration: true}}</div>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-15px kenjo-mb-40px">
    <orgos-column class="kenjo-pr-120px obdh-align-bottom">
      <div class="kenjo-font-size-14px kenjo-font-color-text-333333 obhd-overtime-label">{{todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') ? translation.currentBalance : translation.endBalance}}</div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-font-size-18px">{{(currentBalance ? currentBalance : 0) | duration : true}}</div>
    </orgos-column>
  </orgos-column-container>

  <div *ngIf="waiver > 0 && waiverActive" class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-mb-20px">{{ translation.pleaseNote | i18nData: ({ waiverMinutes: (waiver | duration), dayWeekOrMonth: waiverTypeText }) }}</div>

  <div class="obhd-custom-table">
    <div class="obhd-custom-table__header">
      <div class="obhd-custom-table__header__item">
        <span>{{translation.date}}</span>
      </div>
      <div class="obhd-custom-table__header__item">
        <span>{{translation.overtimeEarned}}</span>
        <kenjo-icon [size]="20" class="kenjo-font-color-success-00b72e kenjo-ml-5px kenjo-cursor-pointer" [matTooltip]="overtimeEarnedTooltip">help_outline</kenjo-icon>
      </div>
      <div class="obhd-custom-table__header__item">
        <span>{{translation.overtimeBalance}}</span>
        <kenjo-icon [size]="20" class="kenjo-font-color-success-00b72e kenjo-ml-5px kenjo-cursor-pointer" [matTooltip]="translation.overtimeBalanceExplanation">help_outline</kenjo-icon>
      </div>
    </div>
    <div class="obhd-custom-table__body">
      <div class="obhd-custom-table__empty kenjo-font-size-14px kenjo-font-color-light-text-757575" *ngIf="parsedOvertimeHistory && parsedOvertimeHistory.length <= 0">{{translation.youHaveNotAccruedAnyOvertimeYet}}</div>
      <div class="obhd-custom-table__row" *ngFor="let iHistory of parsedOvertimeHistory">
        <div class="date">{{iHistory.date}}</div>
        <div class="overtime-and-balance">
          <div class="obhd-custom-table__row__adjustment" *ngIf="iHistory.adjustments && iHistory.adjustments.length > 0">
            <ng-container *ngFor="let entry of iHistory.adjustments">
              <div class="obhd-custom-table__adjustment-item kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
                <div class="obhd-custom-table__adjustment-item__top">
                  <orgos-avatar orgosColumn="0" [avatarName]="userPersonal[entry.createdBy].displayName" [photoUrl]="userPersonal[entry.createdBy]._photo?._url" size="tiny" class="kenjo-pr-5px"></orgos-avatar>
                  <span class="kenjo-font-color-text-333333 kenjo-font-size-14px">{{userPersonal[entry.createdBy].displayName}}</span>
                </div>
                <div class="obhd-custom-table__adjustment-item__bottom kenjo-font-color-light-text-757575 kenjo-font-size-14px">{{entry.detail}}</div>
              </div>
            </ng-container>
          </div>
          <div class="obhd-custom-table__row__info">
            <div class="overtime">{{iHistory.totalOvertime ? (iHistory.totalOvertime | duration : true) : '-'}}</div>
            <div class="balance">{{iHistory.balance | duration}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- See enabled functions -->
  <div class="kenjo-mb-10px kenjo-font-size-16px kenjo-font-color-text-000000 kenjo-mt-30px">{{translation.enabledFunctions}}</div>

  <div class="kenjo-pv-20px kenjo-ph-20px kenjo-mt-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
    <orgos-column-container *ngIf="calculateSince" centerColumns="true" class="kenjo-mb-20px">
      <orgos-column class="kenjo-pr-120px">
        <div class="kenjo-font-color-text-333333 obhd-function-label kenjo-font-size-14px">{{translation.calculateSince}}</div>
      </orgos-column>
      <orgos-column size="1">
        <orgos-column-container centerColumns="true">
          <orgos-column size="0" class="kenjo-font-color-text-333333">{{calculateSince | date:"shortDate":"UTC"}}</orgos-column>
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true">
      <orgos-column class="kenjo-pr-120px">
        <div class="kenjo-font-color-text-333333 obhd-function-label kenjo-font-size-14px">{{translation.transferToNextMonth}}</div>
      </orgos-column>
      <orgos-column size="1">
        <orgos-chip [color]="transferNextMonth ? 'Success' : 'Danger'">
          <div class="kenjo-font-size-12px">{{(transferNextMonth ? translation.activated : translation.deactivated)| uppercase}}</div>
        </orgos-chip>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mt-20px">
      <orgos-column class="kenjo-pr-120px">
        <div class="kenjo-font-color-text-333333 obhd-function-label kenjo-font-size-14px">{{translation.allowUndertime}}</div>
      </orgos-column>
      <orgos-column size="1">
        <orgos-chip [color]="allowUndertime ? 'Success' : 'Danger'">
          <div class="kenjo-font-size-12px">{{(allowUndertime ? translation.activated : translation.deactivated)| uppercase}}</div>
        </orgos-chip>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mt-20px">
      <orgos-column class="kenjo-pr-120px">
        <div class="kenjo-font-color-text-333333 obhd-function-label kenjo-font-size-14px">{{translation.overtimeWaiver}}</div>
      </orgos-column>
      <orgos-column size="1">
        <orgos-chip [color]="waiverActive ? 'Success' : 'Danger'">
          <div class="kenjo-font-size-12px">{{(waiverActive ? translation.activated : translation.deactivated)| uppercase}}</div>
        </orgos-chip>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mt-20px">
      <orgos-column class="kenjo-pr-120px">
        <div class="kenjo-font-color-text-333333 obhd-function-label kenjo-font-size-14px">{{translation.overtimeStartsAccruing}}</div>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-text-333333">{{waiverActive && waiver > 0 ? (translation.afterExtraDayWeekOrMonth | i18nData: ({ waiverMinutes: (waiver | duration), dayWeekOrMonth: waiverTypeText })) : translation.immediatelyAfterExpectedHoursAreMet}}</orgos-column>
    </orgos-column-container>
  </div>

  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{translation.close}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
