<orgos-column-container class="rrci-message" [centerColumns]="true">
  <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-warning-ffc107 kenjo-mr-10px">warning_outline</kenjo-icon>
  <orgos-column size="1">
    <p>
      {{ translations.conflictEventsInfo }}
    </p>
    <ul>
      <div *ngFor="let conflict of repeatConflicts; let i = index">
        <li *ngIf="showFull || i < 2" class="kenjo-font-weight-bold">
          <span *ngIf="conflict.conflictType === REPEAT_REQUEST_CONFLICT_TYPE_REQUEST_OVERLAP"> {{ conflict.label }}: </span>
          <span *ngIf="conflict.conflictType === REPEAT_REQUEST_CONFLICT_TYPE_NON_WORKING_TIME">
            {{ translations.nonWorkingTimeConflicts }}
          </span>
          <span *ngIf="conflict.conflictType === REPEAT_REQUEST_CONFLICT_TYPE_PUBLIC_HOLIDAY">
            {{ translations.publicHolidayConflicts }}
          </span>
          <span *ngIf="conflict.conflictType === 'RepeatConflictRequestWorkingSchedule'">
            {{ translations.workScheduleConflicts }}
          </span>
          <span>
            {{ conflict.translatedDates }}
          </span>
        </li>
      </div>
    </ul>
    <orgos-action
      orgosColumn="1"
      [uppercase]="false"
      *ngIf="repeatConflicts?.length > 2"
      class="rrci-see-more-button kenjo-font-size-14px"
      (click)="showMore()"
    >
      {{ showFull ? translations.seeLessButton : translations.seeMoreButton }}
    </orgos-action>
  </orgos-column>
</orgos-column-container>
