<div class="flex-vertical flex-spread flex-gap-20px">
  <div class="flex-horizontal flex-spread flex-gap-20px">
    <kenjo-group-by [groupByFieldLabel]="pageTranslation.groupByFieldLabel" [listGroupings]="listGroupings" (groupingValueChange)="groupingValue = $event; applyFilters()"></kenjo-group-by>
    <div class="flex-horizontal flex-vertical flex-center-vertically tm-mt-2px">
      <orgos-action *ngIf="true" icon="add_circle" iconSize="large" (click)="addTask()"> {{ pageTranslation.addNewTask }}</orgos-action>
    </div>
  </div>
  <kenjo-filter-bar [shown]="true" [filterFields]="filterFields" [includeEmployeeSearch]="true" (changeFilterEvent)="changeFilter($event)" (clearFilterEvent)="clearFilter($event)" (clearAllFiltersEvent)="clearAllFilters()" (searchTermChanged)="searchTitle($event)"></kenjo-filter-bar>
</div>

<orgos-container *ngIf="listTasks && listTasks.length > 0 && listTasks[0].length > 0">
  <ng-container *ngFor="let iTaskGroup of listTasks; let i = index; let first = first">
    <orgos-column-container *ngIf="groupingValue !== 'no_grouping'" class="kenjo-mb-10px" [class.kenjo-mt-20px]="!first" centerColumns="true">
      <orgos-column size="0" class="kenjo-mr-10px">
        <kenjo-icon *ngIf="mapGroupingBuckets[groupingBuckets[i]] === true" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="changeGroupingStatus(groupingBuckets[i])"> keyboard_arrow_down</kenjo-icon>
        <kenjo-icon *ngIf="!mapGroupingBuckets[groupingBuckets[i]] || mapGroupingBuckets[groupingBuckets[i]] === false" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="changeGroupingStatus(groupingBuckets[i])"> keyboard_arrow_right</kenjo-icon>
      </orgos-column>
      <orgos-column size="1">
        <div *ngIf="groupingValue !== 'relatedTo' && groupingValue !== 'ownerId' && groupingValue !== '_createdById'" class="kenjo-font-size-16px">{{groupingBuckets[i]}}</div>

        <orgos-user-item
          *ngIf="(groupingValue === 'relatedTo' || groupingValue === 'ownerId' || groupingValue === '_createdById') && mapUserIdToUserPersonal[groupingBuckets[i]]"
          [title]="mapUserIdToUserPersonal[groupingBuckets[i]].displayName"
          [photoUrl]="mapUserIdToUserPersonal[groupingBuckets[i]]._photo?._url"
          size="small"
        ></orgos-user-item>
      </orgos-column>
    </orgos-column-container>

    <ng-container *ngIf="groupingValue === 'no_grouping' || mapGroupingBuckets[groupingBuckets[i]] === true">
      <orgos-task
        *ngFor="let iTask of iTaskGroup | limitList:listLimiters[i].limit"
        [task]="iTask"
        [readOnlyTitle]="!this.isAdminOrRecruiter && positionCandidateToHiringTeamPermissions[iTask.relatedTo] !== undefined && positionCandidateToHiringTeamPermissions[iTask.relatedTo]?.createManageTasks"
        [readOnlyDueDate]="!this.isAdminOrRecruiter && positionCandidateToHiringTeamPermissions[iTask.relatedTo] !== undefined && positionCandidateToHiringTeamPermissions[iTask.relatedTo]?.createManageTasks"
        [readOnlyAssignee]="!this.isAdminOrRecruiter && positionCandidateToHiringTeamPermissions[iTask.relatedTo] !== undefined && positionCandidateToHiringTeamPermissions[iTask.relatedTo]?.createManageTasks"
        class="kenjo-mb-10px"
        (taskCompleted)="taskCompleted($event)"
        (animationComplete)="displayTasks()"
      ></orgos-task>
      <kenjo-see-more-button class="kenjo-m-20px" *ngIf="listLimiters[i].limit < iTaskGroup.length" (seeMore)="listLimiters[i].showMore()"></kenjo-see-more-button>
    </ng-container>
  </ng-container>
</orgos-container>

<orgos-container *ngIf="listTasks && (listTasks.length === 0 || listTasks[0].length === 0)" aspect="card" class="kenjo-text-align-center kenjo-p-40px">
  <div><kenjo-icon [size]="16" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon><span class="kenjo-font-size-16px">{{pageTranslation.noTasksHeader}}</span></div>
  <div class="kenjo-mt-20px kenjo-font-color-light-text-757575">{{pageTranslation.noTasksDescription}}</div>
</orgos-container>
