import { DecimalPipe } from '@angular/common';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { IStatusValues, ITimeOffNextCalculatedStatus, ITimeOffNextUserPolicyStatus, ITimeOffStatus } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ALLOWANCE_TYPE_UNLIMITED, CARRY_OVER_DURATION_UNIT_WEEK, CYCLE_TYPE_DISABLED, POLICY_TYPE_DAY, TIME_OFF_STATUS_CYCLE_STATUS_FUTURE } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';
const MINUTES_IN_HOUR = 60;

export const calculateStartDateNextCycle = (cycleMonth) => {
  const currentMoment = moment.utc();
  const currentMonth = currentMoment.month() + 1;
  const currentYear = currentMoment.year();
  const assignmentYear = currentMonth < cycleMonth ? currentYear : currentYear + 1;
  return currentMoment.set({ year: assignmentYear, month: cycleMonth - 1, date: 1 }).startOf('day');
};

export const calculateStartDateThisCycle = (cycleMonth) => {
  const currentMoment = moment.utc();
  const currentMonth = currentMoment.month() + 1;
  const currentYear = currentMoment.year();
  const assignmentYear = currentMonth >= cycleMonth ? currentYear : currentYear - 1;
  return currentMoment.set({ year: assignmentYear, month: cycleMonth - 1, date: 1 }).startOf('day');
};

export const calculateCycleEndDate = (cycleStartDate) => {
  if (check.not.assigned(cycleStartDate)) {
    return undefined;
  }
  return moment.utc(cycleStartDate).add(1, 'years').toDate();
};

export const calculateCarryOverExpireDate = (policy, cycleStartDate) => {
  if (!policy.carryOver || policy.carryOverUnlimitedDuration) {
    return;
  }
  const durationUnit = policy.carryOverDurationUnit === CARRY_OVER_DURATION_UNIT_WEEK ? 'weeks' : 'months';
  return moment.utc(cycleStartDate).add(policy.carryOverDuration, durationUnit).subtract(1, 'day');
};

export const getDaysToDisplay = (value, injector, translation) => {
  const days = injector.get(DecimalPipe).transform(value, '1.0-2');
  return injector.get(I18nDataPipe).transform(Math.abs(+days) === 1 ? translation.dayText : translation.daysText, { number: days });
};

export const getMinutesToDisplay = (value, injector) => {
  return injector.get(DurationPipe).transform(value);
};

export const fromMinutesToHoursAndMinutes = (allMinutes: number) => {
  return {
    hours: Math.floor(allMinutes / 60),
    minutes: allMinutes % 60
  };
};

export const fromHoursAndMinutesToMinutes = (hours: number, minutes: number) => {
  return hours * 60 + minutes;
};

export const getLocalDate = (date) => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const setToPolicyUnits = (policyType: string, value: number) => {
  return policyType === POLICY_TYPE_DAY ? value : value * MINUTES_IN_HOUR;
};

export const getFromPolicyUnits = (policyType: string, value: number) => {
  return policyType === POLICY_TYPE_DAY ? value : value / MINUTES_IN_HOUR;
};

export const buildBulkAction = (users: Array<string>, timeOffTypeId: string, policyId: string, action) => {
  return users.map((user) => {
    return {
      timeOffTypeId: timeOffTypeId,
      policyId: policyId,
      userId: user,
      action: action
    };
  });
};

// TIME OFF STATUS VALUES
export const getStatusSummaryCardValues = (status: ITimeOffStatus, allowanceType: string) => {
  const statusValues: IStatusValues = {
    taken: getTaken(status),
    planned: status.planned
  };
  if (allowanceType !== ALLOWANCE_TYPE_UNLIMITED) {
    statusValues.totalAllowance = getTotalAllowance(status);
    statusValues.available = status.available;
    if (status.carryOverEnabled) {
      statusValues.currentCarryOver = status.currentCarryOver ?? 0;
      statusValues.availableAfterExpireCarryOver = status.available - (status.currentCarryOver ?? 0);
    }
  }
  return statusValues;
};

export const getStatusDetailsCardValues = (status: ITimeOffStatus, unlimitedAllowance: boolean) => {
  const statusValues: IStatusValues = {
    taken: getTaken(status),
    planned: status.planned,
    totalTakenPlanned: status.planned + getTaken(status)
  };
  if (!unlimitedAllowance) {
    statusValues.totalAllowance = getTotalAllowance(status);
    statusValues.cycleAllowance = getCycleAllowance(status);
    statusValues.carryOver = getCarryOver(status);
    statusValues.expiredCarryOver = -status.expiredCarryOver ?? 0;
    statusValues.currentCarryOver = status.currentCarryOver ?? 0;
    statusValues.available = status.available;
    statusValues.negativeStartingBalance = status.negativeStartingBalance ?? 0;
    statusValues.givenCompensation = status.givenCompensation ?? 0;
    statusValues.takenCarryOver = status.takenCarryOver ?? 0;
    statusValues.plannedCarryOver = status.plannedCarryOver ?? 0;
    statusValues.availableAfterExpireCarryOver = status.available - (status.currentCarryOver ?? 0);
  }
  return statusValues;
};

export const getInitialTaken = (status: ITimeOffStatus) => {
  return status.taken;
};

export const getTaken = (status: ITimeOffStatus) => {
  return status.taken + (status?.adjustTaken ?? 0);
};

export const getTotalAllowance = (status: ITimeOffStatus) => {
  return status.initialBalance + (status.adjustBalance ?? 0) + (status?.givenCompensation ?? 0) - (status?.expiredCarryOver ?? 0);
};

export const getCycleAllowance = (status: ITimeOffStatus) => {
  return status.accrued + (status?.adjustAccrued ?? 0) + (status?.adjustBalance ?? 0);
};

export const getInitialCycleAllowance = (status: ITimeOffStatus) => {
  return status.accrued + (status?.adjustAccrued ?? 0);
};

export const getInitialCarryOver = (status: ITimeOffStatus) => {
  return status.initialCarryOver ?? 0;
};

export const getCarryOver = (status: ITimeOffStatus) => {
  return (status.initialCarryOver ?? 0) + (status.adjustCarryOver ?? 0);
};

export const calculateNextStatus = (policy: ITimeOffPolicyModel, currentStatus: ITimeOffStatus, nextStatusFields: ITimeOffNextUserPolicyStatus, nextPlanned: number, nextTaken: number, userStartDate: string) => {
  let calculatedNextStatus: ITimeOffNextCalculatedStatus = {
    disabledCycle: policy.cycleType === CYCLE_TYPE_DISABLED,
    cycleStatus: TIME_OFF_STATUS_CYCLE_STATUS_FUTURE,
    unlimitedAllowance: policy.allowanceType === ALLOWANCE_TYPE_UNLIMITED,
    carryOverEnabled: policy.carryOver,
    _policyType: policy._type
  };

  if (policy.cycleType !== CYCLE_TYPE_DISABLED) {
    calculatedNextStatus.cycleStartDate = nextStatusFields.cycleStartDate;
    calculatedNextStatus.cycleEndDate = nextStatusFields.cycleEndDate;
  }

  calculatedNextStatus.taken = nextTaken;
  calculatedNextStatus.planned = nextPlanned;

  if (policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED) {
    calculatedNextStatus.accrued = nextStatusFields.accrued;
    calculatedNextStatus.accrualSplits = nextStatusFields.accrualSplits;
    calculatedNextStatus.adjustAccrued = 0;
    calculatedNextStatus.givenExtraAllowance = nextStatusFields.givenExtraAllowance;

    let initialBalance = calculatedNextStatus.accrued + calculatedNextStatus.givenExtraAllowance;

    const projectedAvailable = calculateProjectedAvailable(currentStatus);
    if (policy.carryOver && projectedAvailable > 0) {
      const initialCarryOver = calculateInitialCarryOverNextCycle(projectedAvailable, policy);
      calculatedNextStatus.initialCarryOver = initialCarryOver;
      calculatedNextStatus.takenCarryOver = Math.min(nextStatusFields.takenBeforeExpireDate, initialCarryOver);
      calculatedNextStatus.plannedCarryOver = Math.min(nextStatusFields.plannedBeforeExpireDate, initialCarryOver - calculatedNextStatus.takenCarryOver);
      calculatedNextStatus.currentCarryOver = initialCarryOver - calculatedNextStatus.takenCarryOver - calculatedNextStatus.plannedCarryOver;
      calculatedNextStatus.carryOverExpireDate = nextStatusFields.carryOverExpireDate;
      initialBalance += initialCarryOver;
    }

    if (projectedAvailable < 0) {
      calculatedNextStatus.negativeStartingBalance = projectedAvailable;
      initialBalance += projectedAvailable;
    }

    calculatedNextStatus.initialBalance = initialBalance;
    calculatedNextStatus.currentBalance = initialBalance - nextTaken;
    calculatedNextStatus.available = initialBalance - nextTaken - nextPlanned;
  }

  calculatedNextStatus.extraAllowanceEvents = getCycleExtraAllowanceEvents(policy.extraAllowanceRules, userStartDate, nextStatusFields.cycleStartDate, nextStatusFields.cycleEndDate, policy.cycleType === CYCLE_TYPE_DISABLED);

  return calculatedNextStatus;
};

export const calculateProjectedAvailable = (currentStatus: ITimeOffStatus) => {
  let projectedAvailable = currentStatus.available ?? 0;
  if (!currentStatus.hasExpiredCarryOver && check.assigned(currentStatus.carryOverExpireDate) && moment.utc().startOf('day').isSameOrBefore(currentStatus.carryOverExpireDate) && currentStatus.currentCarryOver > 0) {
    projectedAvailable -= currentStatus.currentCarryOver;
  }
  return projectedAvailable;
};

export const calculateInitialCarryOverNextCycle = (projectedAvailable: number, policy: ITimeOffPolicyModel) => {
  if (policy.carryOverUnlimitedQuantity) {
    return projectedAvailable;
  }
  return Math.min(projectedAvailable, policy.carryOverQuantityLimit);
};

export const getCycleExtraAllowanceEvents = (extraAllowanceRules, startDate, cycleStartDate, cycleEndDate, disabledCycle) => {
  return extraAllowanceRules?.reduce((extraAllowanceEvents, rule) => {
    const ruleApplyDate = moment.utc(startDate).startOf('day').add(rule.monthsWorked, 'month');
    if (disabledCycle || (ruleApplyDate.isSameOrAfter(cycleStartDate) && ruleApplyDate.isBefore(cycleEndDate))) {
      extraAllowanceEvents.push({
        extraAllowanceDate: ruleApplyDate.toDate(),
        extraAllowanceTime: rule.extraAllowanceTime
      });
    }
    return extraAllowanceEvents;
  }, []);
};

export const generateUniqueId = () => {
  const dateStr = Date.now().toString(36);
  const randomStr = Math.random().toString(36).substring(2, 8);
  return `${dateStr}-${randomStr}`;
};

export const setWeekDaysLabelPure = (weekDayLabels: Array<string>, currentLocale: string): Array<string> => {
  const americaLocales = ['es-MX', 'en-US'];
  const europeLocales = ['es', 'de-AT', 'de', 'en-GB'];

  if (!americaLocales.includes(currentLocale) && !europeLocales.includes(currentLocale)) {
    return [];
  }

  if (!americaLocales.includes(currentLocale)) {
    return weekDayLabels;
  }

  const mondayLabel = weekDayLabels[0];
  const sundayLabel = weekDayLabels[6];
  const restOfDayLabels = weekDayLabels.slice(1, 6);

  return [sundayLabel, mondayLabel, ...restOfDayLabels];
};

export const getWeekdayPositionInMonth = (date: moment.Moment): number => {
  const firstWeekdayOfMonth = date.clone().startOf('month').day();
  const weekday = date.day();
  const daysFromStartOfMonthToDate = (weekday - firstWeekdayOfMonth + 7) % 7;
  return  Math.floor((date.date() - daysFromStartOfMonthToDate + 7) / 7);
}

export const isLastWeekdayOfMonth = (date: moment.Moment): boolean => {
  const firstDayLastWeekOfMonth = date.clone().endOf('month').subtract(7, 'days');
  return firstDayLastWeekOfMonth.isBefore(date);
}