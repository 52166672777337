import { CdkOverlayOrigin, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as check from 'check-types';

import { ISelectOption } from '../../core/select-option';
import { InternationalizationService } from '../../services/core/internationalization.service';
import { PicklistsService } from '../../services/core/picklists.service';
import { AutocompleteOptionDirective } from './autocomplete-option.directive';

@Component({
  selector: 'orgos-input-simple-autocomplete',
  templateUrl: 'input-simple-autocomplete.component.html',
  styleUrls: ['input-simple-autocomplete.component.scss']
})
export class InputSimpleAutocompleteComponent implements OnInit, AfterViewChecked {
  showClearButton: boolean = false;
  miscTranslation: any = {};

  searching: boolean = false;
  searchResults: Array<ISelectOption> = [];

  private _indexSearchResultFocused: number = 0;
  set indexSearchResultFocused(indexSearchResultFocused: number) {
    this._indexSearchResultFocused = indexSearchResultFocused;

    this.makeScroll = true;
  }
  get indexSearchResultFocused(): number {
    return this._indexSearchResultFocused;
  }

  makeScroll: boolean = false;

  searchResultsContaineOverlayRef: OverlayRef = null;

  private _searchTerm: string = '';
  set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
    this.refreshSearchResults();
  }
  get searchTerm(): string {
    return this._searchTerm;
  }

  private _sortOptions: boolean = true;
  @Input()
  set sortOptions(sortOptions: boolean) {
    this._sortOptions = sortOptions;
    this.applySort();
  }
  get sortOptions(): boolean {
    return this._sortOptions;
  }

  private _originalOptions: Array<ISelectOption> = [];
  private _options: Array<ISelectOption> = [];
  @Input()
  set options(options: Array<ISelectOption>) {
    if (check.not.assigned(options)) {
      this._originalOptions = [];
      this.applySort();
      return;
    }

    this._originalOptions = options;
    this.applySort();
  }
  get options(): Array<ISelectOption> {
    return this._options;
  }

  @Input()
  set picklist(picklistName: string) {
    this.picklistService
      .getPicklist(picklistName)
      .then((picklist: object) => {
        this.options = Object.keys(picklist).reduce((tempOptions: Array<ISelectOption>, value: string) => {
          const picklistOption: ISelectOption = {
            name: picklist[value],
            value: value
          };

          tempOptions.push(picklistOption);

          return tempOptions;
        }, []);
      })
      .catch(() => {
        this.options = [];
      });
  }

  @Input() enableClearButton: boolean = false;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(CdkPortal, { static: true }) searchResultsContainer: Portal<any>;
  @ViewChild(CdkOverlayOrigin) searchInputOrigin: CdkOverlayOrigin;
  @ViewChild('isacInput') autocompleteInput: ElementRef;
  @ViewChildren(AutocompleteOptionDirective) results: QueryList<AutocompleteOptionDirective>;

  @HostListener('keydown', ['$event']) handleKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Down' || event.key === 'ArrowDown') {
      if (check.assigned(this.searchResults) && check.not.emptyArray(this.searchResults)) {
        this.indexSearchResultFocused = (this.indexSearchResultFocused + 1) % this.searchResults.length;
      }
      event.preventDefault();
    } else if (event.key === 'Up' || event.key === 'ArrowUp') {
      if (check.assigned(this.searchResults) && check.not.emptyArray(this.searchResults)) {
        this.indexSearchResultFocused = this.indexSearchResultFocused - 1 < 0 ? this.searchResults.length - 1 : this.indexSearchResultFocused - 1;
      }
      event.preventDefault();
    } else if (event.key === 'Enter') {
      if (check.assigned(this.searchResults) && check.not.emptyArray(this.searchResults)) {
        this.selectOption(this.searchResults[this.indexSearchResultFocused].value);
      }

      event.preventDefault();
    } else if (event.key === 'Escape') {
      this.disableSearching();
      event.preventDefault();
    }
  }

  constructor(private overlay: Overlay, private picklistService: PicklistsService, private internationalizationService: InternationalizationService) {}

  ngOnInit(): void {
    this.internationalizationService
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.showClearButton = this.enableClearButton && check.assigned(this.value);
  }

  ngAfterViewChecked(): void {
    this.scrollToSearchResultFocused();
  }

  enableSearching(): void {
    if (this.readOnly === true || this.searching === true) {
      return;
    }

    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.minWidth = this.searchInputOrigin.elementRef.nativeElement.getBoundingClientRect().width;
    config.backdropClass = 'cp-event-details-overlay';
    config.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.searchInputOrigin.elementRef)
      .withPositions([
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
        { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' }
      ])
      .withPush(false);

    this.searchResultsContaineOverlayRef = this.overlay.create(config);
    this.searchResultsContaineOverlayRef.attach(this.searchResultsContainer);

    this.searchResultsContaineOverlayRef.backdropClick().subscribe(() => {
      this.disableSearching();
    });

    this.searching = true;
    this.searchTerm = '';

    this.refreshSearchResults();
  }

  private disableSearching(): void {
    if (check.assigned(this.searchResultsContaineOverlayRef)) {
      this.searchResultsContaineOverlayRef.detachBackdrop();
      this.searchResultsContaineOverlayRef.dispose();
    }

    this.searching = false;
    this.searchTerm = '';
  }

  selectOption(value: any): void {
    this.value = value;

    this.valueChange.emit(value);

    this.showClearButton = this.enableClearButton && check.assigned(this.value);

    this.disableSearching();

    this.autocompleteInput.nativeElement.blur();
  }

  clearValue(): void {
    this.selectOption(null);
  }

  getValueName(): string {
    if (this.searching === true) {
      return this.searchTerm;
    }

    if (check.not.assigned(this.value)) {
      return '';
    }

    const option = this.options.find((selectOption: ISelectOption) => {
      return selectOption.value === this.value;
    });

    if (check.not.assigned(option)) {
      return '';
    }

    return option.name;
  }

  private refreshSearchResults(): void {
    if (this.searching === false) {
      this.searchResults = [];
      return;
    }

    const allResults = this.options;

    if (check.not.assigned(this.searchTerm) || check.emptyString(this.searchTerm)) {
      this.searchResults = allResults;

      this.indexSearchResultFocused = this.searchResults.findIndex((iSearchResult: ISelectOption) => {
        return iSearchResult.value === this.value;
      });
      return;
    }

    const filteredResults = allResults.filter((option: ISelectOption) => {
      const regExp = new RegExp(`^.*${this.searchTerm}.*$`, 'i');
      return regExp.test(option.name);
    });

    this.searchResults = filteredResults;

    this.indexSearchResultFocused = 0;
    return;
  }

  private scrollToSearchResultFocused(): void {
    if (this.makeScroll === true && check.assigned(this.results) && this.results.length > 0) {
      const searchResultFocused = this.results.toArray()[this.indexSearchResultFocused];
      if (check.assigned(searchResultFocused)) {
        searchResultFocused.getElementRef().nativeElement.scrollIntoView(/*NO SUPPORTED BY ALL BROWSERS: {block: 'center', inline: 'nearest'}*/);
      }

      this.makeScroll = false;
    }
  }

  private applySort(): void {
    if (this.sortOptions === false) {
      this._options = this._originalOptions.slice();
      return;
    }

    this._options = this._originalOptions.slice().sort((optionA: ISelectOption, optionB: ISelectOption) => {
      const nameA = optionA.name.toLowerCase();
      const nameB = optionB.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
