import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { WidgetsService } from '@app/standard/services/widgets/widgets.service';
import { getDateFormat } from '@carlos-orgos/orgos-utils/date-utils';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-widget-birthday',
  templateUrl: 'orgos-widget-birthday.component.html',
  styleUrls: ['orgos-widget-birthday.component.scss'],
})
export class WidgetBirthdayComponent implements OnInit {
  translation: any = {};

  loadingWidget: boolean = false;
  allUserPersonals: Array<IUserPersonalModel>;
  userSelected: IUserPersonalModel;
  todayUsers: Array<IUserPersonalModel> = [];
  thisWeekUsers: Array<IUserPersonalModel> = [];
  followingMonthUsers: Array<IUserPersonalModel> = [];

  getDateFormat: Function = (formatName) => {
    return getDateFormat(formatName, this.injector.get(InternationalizationService).getLocale());
  };

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.loadingWidget = true;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('home-widget-birthday-component')
      .then((translation) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(WidgetsService)
      .getNextBirthdays()
      .then((userPersonals: Array<IUserPersonalModel>) => {
        this.allUserPersonals = userPersonals;
        this.loadBirthdays();
      })
      .catch(() => {
        // Do nothing
      });
  }

  private loadBirthdays(): void {
    const today = moment.utc();
    const weekStarts = moment.utc().startOf('day');
    const weekEnds = moment.utc().endOf('week');

    const userBirthdates = [];
    this.allUserPersonals.forEach((iUserPersonal: IUserPersonalModel) => {
      const birthdate = moment.utc(iUserPersonal.nextBirthday);
      const birthdayUser = Object.assign({}, iUserPersonal);
      birthdayUser.nextBirthday = birthdate;
      userBirthdates.push(birthdayUser);
    });
    const unsortedTodayUsers = userBirthdates.filter((iUserPersonal: IUserPersonalModel) => {
      const birthdate = moment.utc(iUserPersonal.nextBirthday);
      return moment.utc(birthdate).isSame(today, 'day');
    });

    this.todayUsers = _.orderBy(
      unsortedTodayUsers,
      (iUserPersonal: IUserPersonalModel) => {
        return moment.utc(iUserPersonal.nextBirthday).format('YYYYMMDD');
      },
      ['asc']
    );

    const unsortedThisWeekUsers = userBirthdates.filter((iUserPersonal: IUserPersonalModel) => {
      const birthdate = moment.utc(iUserPersonal.nextBirthday);
      return moment.utc(birthdate).isBetween(weekStarts, weekEnds, 'day', '(]');
    });

    this.thisWeekUsers = _.orderBy(
      unsortedThisWeekUsers,
      (iUserPersonal: IUserPersonalModel) => {
        return moment.utc(iUserPersonal.nextBirthday).format('YYYYMMDD');
      },
      ['asc']
    );

    const birthdaysByMonth = {};
    userBirthdates
      .filter((iUserPersonal: IUserPersonalModel) => {
        const birthdate = moment.utc(iUserPersonal.nextBirthday);
        return moment.utc(birthdate).isAfter(weekEnds, 'day');
      })
      .forEach((iUserPersonal: IUserPersonalModel) => {
        const birthdayMonth = moment.utc(iUserPersonal.nextBirthday).startOf('month').format('YYYYMMDD');
        if (check.not.assigned(birthdaysByMonth[birthdayMonth])) {
          birthdaysByMonth[birthdayMonth] = [];
        }
        birthdaysByMonth[birthdayMonth].push(iUserPersonal);
      });

    const unorderedBirthdays = Object.keys(birthdaysByMonth).map((month) => {
      const sortedUsersInMonth = _.orderBy(
        birthdaysByMonth[month],
        (iUserPersonal: IUserPersonalModel) => {
          return moment.utc(iUserPersonal.nextBirthday).format('YYYYMMDD');
        },
        ['asc']
      );

      const birthdayMonth = {
        month: month,
        birthdays: sortedUsersInMonth,
      };
      return birthdayMonth;
    });

    this.followingMonthUsers = _.orderBy(
      unorderedBirthdays,
      (birthdayMonth: any) => {
        return moment.utc(birthdayMonth.month).format('YYYYMMDD');
      },
      ['asc']
    );

    if (check.not.emptyArray(this.todayUsers) === true && check.assigned(this.todayUsers[0])) {
      this.userSelected = this.todayUsers[0];
    } else if (check.not.emptyArray(this.thisWeekUsers) === true && check.assigned(this.thisWeekUsers[0])) {
      this.userSelected = this.thisWeekUsers[0];
    } else if (check.not.emptyArray(this.followingMonthUsers) === true && check.assigned(this.followingMonthUsers[0].birthdays[0])) {
      this.userSelected = this.followingMonthUsers[0].birthdays[0];
    }
    this.loadingWidget = false;
  }

  public getDateInMomentFormat(unformatedDate: string): any {
    return moment.utc(unformatedDate, 'YYYYMMDD');
  }

  public navigateToPersonalPage(userId: string): void {
    this.injector.get(Router).navigateByUrl(`cloud/people/${userId}/personal`);
  }
}
