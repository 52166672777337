import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import check from 'check-types';

@Component({
  selector: 'kenjo-expansion-panel',
  templateUrl: './kenjo-expansion-panel.component.html',
  styleUrls: ['./kenjo-expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpansionPanelComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public summary?: string;

  @Input()
  public icon?: string;

  @Output()
  public iconClick = new EventEmitter();

  @Input()
  public iconTooltip: string;

  @Input()
  public id: string;

  @Output()
  public panelOpenStateChange = new EventEmitter();

  @Input()
  public set panelOpenState(value: boolean) {
    this._panelOpenState = value;
    this.panelOpenStateChange.emit(this._panelOpenState);
  }

  public get panelOpenState() {
    return this._panelOpenState;
  }

  private _panelOpenState: boolean;

  constructor() {}

  ngOnInit(): void {}

  onClick(event: Event) {
    event.stopPropagation();
    if (check.assigned(this.iconClick)) {
      this.iconClick.emit(event);
    }
  }
}
