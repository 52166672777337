<orgos-column-container class="kenjo-border" [centerColumns]="true">
  <orgos-column class="kenjo-p-5px sph-first-cell kenjo-font-size-12px">{{ rangeName | titlecase }}</orgos-column>
  <orgos-column
    *ngFor="let day of currentDays; let dayIndex = index"
    class="sph-flex-center sph-subtitle kenjo-border-left"
    [class.weekend]="(day | isWeekend) || holidays[dayIndex]?.length > 0"
  >
    <div *ngIf="frequency === views.WEEK" class="sph-row">
      <div class="sph-day weekly" [class.holiday]="holidays[dayIndex]?.length > 0">{{ day | formatDate: 'ddd D/M' }}</div>
    </div>
    <div *ngIf="frequency === views.MONTH" class="sph-column">
      <div class="label">{{ day | formatDate: 'dd' }}</div>
      <div class="sph-day" [class.holiday]="holidays[dayIndex]?.length > 0">{{ day | formatDate: 'DD' }}</div>
    </div>
  </orgos-column>
</orgos-column-container>
