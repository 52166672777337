import { Injectable } from '@angular/core';
import { TaskProgress } from '@app/common-components/layout-import-payslips/services/task-progress';
import * as check from 'check-types';
import { PDFDocument } from 'pdf-lib';
import * as PDFJS from 'pdfjs-dist/build/pdf.js';
PDFJS.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.js';

@Injectable({
  providedIn: 'root'
})
export class PdfUtilsService {
  parsePdf(pdfUrl: string | Blob, taskProgress: TaskProgress): Promise<IPdfMetadata> {
    return new Promise<IPdfMetadata>(async (resolve) => {
      const pdfDocument = await PDFJS.getDocument(pdfUrl).promise;
      taskProgress.markAsComplete(0.25);
      const metadata = await pdfDocument.getMetadata();
      taskProgress.markAsComplete(0.25);
      const textPerPage = await this.getTextFromPdf(pdfDocument);
      taskProgress.markAsComplete(0.25);
      const rawData = await pdfDocument.getData();
      taskProgress.markAsComplete(0.25);
      pdfDocument.cleanup();
      pdfDocument.destroy();

      const pdfMetadata: IPdfMetadata = {
        numPages: pdfDocument.numPages,
        info: check.assigned(metadata) ? metadata.info : null,
        textPerPage: textPerPage,
        rawData: rawData
      };

      resolve(pdfMetadata);
    });
  }

  private getTextFromPdf(pdfDocument): Promise<Array<string>> {
    return new Promise<Array<string>>((resolve, reject) => {
      const getPageTextPromises = [];

      for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
        const getPageTextPromise = this.getTextFromPage(pdfDocument, pageNum);
        getPageTextPromises.push(getPageTextPromise);
      }

      Promise.all(getPageTextPromises)
        .then((textInPdf) => {
          resolve(textInPdf);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private getTextFromPage(pdfDocument, pageNum): Promise<string> {
    return new Promise<string>(async (resolve) => {
      const pdfPage = await pdfDocument.getPage(pageNum);
      const textContent = await pdfPage.getTextContent();

      let textInPage = '';
      for (const item of textContent.items) {
        textInPage += `${item.str} `;
      }

      resolve(textInPage);
    });
  }

  splitPdf(sourceRawPdf: Uint8Array, taskProgress: TaskProgress): Promise<Array<Uint8Array>> {
    return new Promise((resolve, reject) => {
      PDFDocument.load(sourceRawPdf, { ignoreEncryption: true })
        .then((sourcePdfDocument) => {
          const pageIndices = sourcePdfDocument.getPageIndices();

          const splitRawPdfsPromises = pageIndices.map((iPageIndex) => {
            return new Promise((resolveCopy, rejectCopy) => {
              this.copyPageFromPdf(sourcePdfDocument, iPageIndex)
                .then((splitRawPdf) => {
                  taskProgress.markAsComplete();
                  resolveCopy(splitRawPdf);
                })
                .catch((error) => {
                  rejectCopy(error);
                });
            });
          });

          return Promise.all(splitRawPdfsPromises);
        })
        .then((splitRawPdfs: Array<Uint8Array>) => {
          resolve(splitRawPdfs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private copyPageFromPdf(sourcePdfDocument, pageIndex): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      PDFDocument.create()
        .then(async (newPdfDocument) => {
          const [page] = await newPdfDocument.copyPages(sourcePdfDocument, [pageIndex]);

          await newPdfDocument.addPage(page);

          return newPdfDocument.save();
        })
        .then((newRawPdf: Uint8Array) => {
          resolve(newRawPdf);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export interface IPdfMetadata {
  numPages: number;
  info?: any;
  textPerPage: Array<string>;
  rawData: Uint8Array;
}
