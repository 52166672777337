import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { ISelectOption } from '@app/standard/core/select-option';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { environment } from '@env';

@Injectable()
export class AudienceSelectorComponentService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/components/audience-selector`;

  constructor(private http: HttpClient, private injector: Injector) {}

  async getAudienceSelector(includeUserIds: boolean = false): Promise<IAudienceSelectOptions> {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };

    try {
      return await this.http.get<IAudienceSelectOptions>(`${this.URL}/${includeUserIds}`, httpOptions).toPromise();
    } catch (e) {
      // error already shown
    }
  }
}

export interface IAudienceSelectOptions {
  companies?: Array<ISelectOption>;
  offices?: Array<ISelectOption>;
  departments?: Array<ISelectOption>;
  areas?: Array<ISelectOption>;
  teams?: Array<ISelectOption>;
  mapUserIdToUserPersonal?: {[key: string]: IUserPersonalModel};
}

export interface IAudienceVisibility {
  departmentIds?: Array<string>;
  areaIds?: Array<string>;
  teamIds?: Array<string>;
  officeIds?: Array<string>;
  companyIds?: Array<string>;
  userIds?: Array<string>;
}
