import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as moment from 'moment';

import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Pipe({
  name: 'utcDate',
  pure: false
})
export class UtcDatePipe implements PipeTransform {
  constructor(private internationalizationService: InternationalizationService) {}

  transform(value: any): Date | null {
    if (check.not.assigned(value) || (check.string(value) && check.emptyString(value)) || (check.not.string(value) && !moment.isMoment(value) && !moment.isDate(value))) {
      return null;
    }

    const utcDate = this.sanitizeDate(value).toDate();
    return utcDate;
  }

  private sanitizeDate(value: moment.MomentInput): moment.Moment {
    return this.internationalizationService.getSanitizedTimeZoneFromUTC(value);
  }
}
