import { Component, Input } from '@angular/core';
import { IUserShiftplanWorkSchedules } from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-input-shiftplan-user',
  templateUrl: 'input-shiftplan-user.component.html',
  styleUrls: ['input-shiftplan-user.component.scss'],
})
export class InputShiftPlanUserComponent {
  extraEntitiesTooltip: string;
  activeUserLocations: any;
  isFullMatch: boolean = false;
  _user;
  DEFAULT_SCHEDULE = Array.from({ length: 7 }, () => false);

  @Input()
  set user(newUser) {
    this._user = newUser;
    this.initEntities(newUser);
  }
  get user() {
    return this._user;
  }

  @Input() employeeSchedules: IUserShiftplanWorkSchedules;
  @Input() notMatchedEntities = {};
  @Input() pageTranslation: any = {};
  @Input() selectedData;
  @Input() isSameWeek: boolean = true;

  initEntities(newUser) {
    this.manageMatchEntitiesOrder();
    this._user.entities = [...newUser?.entities].filter((entity) => entity !== undefined);
    this.setExtraEntitiesToolTip();
    this.calculateFullMatch();
  }

  private setExtraEntitiesToolTip() {
    this.extraEntitiesTooltip = this.pageTranslation.rolesTooltip + this.user.roles.map((role) => role.name).join(', ');
    this.extraEntitiesTooltip += '\n\n';
    this.extraEntitiesTooltip +=
      this.pageTranslation.locationsTooltip + this.activeUserLocations.map((location) => location.name).join(', ');
  }

  private calculateFullMatch() {
    if (check.not.assigned(this.selectedData?.locationId) || check.not.assigned(this.selectedData?.roleId)) {
      return;
    }
    const selectionMatches = { location: false, role: false };

    this.user.entities.forEach((entity) => {
      if (entity._id === this.selectedData.locationId) {
        selectionMatches.location = true;
      }

      if (entity._id === this.selectedData.roleId) {
        selectionMatches.role = true;
      }
    });
    this.isFullMatch = selectionMatches.location && selectionMatches.role;
  }

  private sortMatchEntities(entities) {
    const matchingEntities = [];
    const notMatchingEntities = [];
    entities.forEach((entity) => {
      if (this.notMatchedEntities[entity._id]) {
        notMatchingEntities.push(entity);
      } else {
        matchingEntities.push(entity);
      }
    });
    return [matchingEntities, notMatchingEntities];
  }

  private manageMatchEntitiesOrder(): void {
    const [matchingRoles, notMatchingRoles] = this.sortMatchEntities(this.user.roles);
    this.activeUserLocations = this.user.locations?.filter((iUserLocation) => iUserLocation.activeForShiftplan);
    const [matchingLocations, notMatchingLocations] = this.sortMatchEntities(this.activeUserLocations);

    if (matchingRoles.length > 0 && matchingLocations.length > 0) {
      const firstMatchingLocation = matchingLocations.shift();
      const firstMatchingRole = matchingRoles.shift();
      this._user.entities = [
        firstMatchingRole,
        firstMatchingLocation,
        ...matchingRoles,
        ...matchingLocations,
        ...notMatchingRoles,
        ...notMatchingLocations,
      ];
      return;
    }

    if (matchingRoles.length > 0 && matchingLocations.length === 0) {
      const firstMatchingRole = matchingRoles.shift();
      this._user.entities = [firstMatchingRole, ...notMatchingLocations, ...matchingRoles, ...notMatchingRoles];
      return;
    }

    if (matchingRoles.length === 0 && matchingLocations.length > 0) {
      const firstNoMatchingRole = notMatchingRoles.shift();
      const firstMatchingLocation = matchingLocations.shift();
      this._user.entities = [
        firstNoMatchingRole,
        firstMatchingLocation,
        ...matchingLocations,
        ...notMatchingLocations,
        ...notMatchingRoles,
      ];
      return;
    }

    if (matchingRoles.length === 0 && matchingLocations.length === 0) {
      const firstNoMatchingRole = notMatchingRoles.shift();
      const firstNoMatchingLocation = notMatchingLocations.shift();
      this._user.entities = [firstNoMatchingRole, firstNoMatchingLocation, ...notMatchingLocations, ...notMatchingRoles];
      return;
    }
  }
}
