import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import {
  IShiftPlanGeneralSettingsModel,
  IWishDaySettings,
  ShiftPlanGeneralSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { FeatureGatingService } from '@app/standard/components/feature-gating/services/feature-gating.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as featureGatingConstants from '@carlos-orgos/orgos-utils/constants/feature-gating.constants';
import { SHIFT_PLAN_AVAILABILITY, SHIFT_PLAN_UNAVAILABILITY } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-settings-shift-plan-wish-days',
  templateUrl: 'settings-shift-plan-wish-days.html',
  styleUrls: ['settings-shift-plan-wish-days.scss'],
})
export class SettingsShiftPlanWishDays implements OnInit {
  translations: Record<string, string> = {};
  SHIFT_PLAN_AVAILABILITY = SHIFT_PLAN_AVAILABILITY;
  SHIFT_PLAN_UNAVAILABILITY = SHIFT_PLAN_UNAVAILABILITY;
  settingsPermissions: { [key: string]: boolean } = {};
  wishDaysPreferences: IWishDaySettings = {
    show: false,
    type: this.SHIFT_PLAN_AVAILABILITY,
    reminder: {
      send: false,
      value: 1,
      range: 'week',
    },
  };
  dummyWishDayData: { type: string; time: { start: string; end: string } };
  loading: boolean = true;
  showAvailability: boolean = true;
  reminderValue: number = 0;
  selectedCategory: string = '';
  range: { [key: string]: string } = {};
  rangeKeys: string[];
  entitlementRequiredToActivateFeature: string;
  showEntitlementIcon: boolean = false;
  tooltipText: string = '';

  @Input() parentTranslation: { [key: string]: string } = {};
  @Input() generalSettings: IShiftPlanGeneralSettingsModel;
  @Output() updatedSettings: EventEmitter<IShiftPlanGeneralSettingsModel> = new EventEmitter<IShiftPlanGeneralSettingsModel>();

  constructor(private injector: Injector, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      this.loading = true;
      await Promise.all([this.fetchPermissions(), this.fetchTranslations()]);
      this.setData();
      await this.evaluateIfEntitlementIsRequired();
      await this.deactivateWishDaySettingsIfNeeded();
      this.loading = false;
    } catch {
      this.translations = {};
    }
  }

  async deactivateWishDaySettingsIfNeeded() {
    if (this.showEntitlementIcon && this.wishDaysPreferences.show) {
      this.wishDaysPreferences.show = false;
      this.wishDaysPreferences.reminder.send = false;
      this.generalSettings.wishDays = this.wishDaysPreferences;
      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettings._id, { wishDays: this.wishDaysPreferences });
      this.updatedSettings.emit(this.generalSettings);
    }
  }

  async fetchPermissions() {
    this.settingsPermissions = await this.injector
      .get(ShiftPlanPermissionsService)
      .getShiftplPermissionsByCollection('shift-plan-general-settings');
  }

  private setData() {
    if (check.not.emptyObject(this.generalSettings.wishDays) && this.generalSettings.wishDays !== undefined) {
      this.wishDaysPreferences = {
        ...this.wishDaysPreferences,
        ...this.generalSettings?.wishDays,
        reminder: {
          ...this.wishDaysPreferences?.reminder,
          ...this.generalSettings?.wishDays?.reminder,
        },
      };
    }

    this.dummyWishDayData = {
      type:
        this.wishDaysPreferences?.type === this.SHIFT_PLAN_AVAILABILITY
          ? this.translations.availabilityPreview
          : this.translations.unavailabilityPreview,
      time: {
        start: '09:00',
        end: '17:00',
      },
    };
  }

  private async fetchTranslations(): Promise<void> {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-wish-days');
      this.range = { week: this.translations.week, month: this.translations.month };
      this.rangeKeys = Object.keys(this.range);
    } catch {
      this.translations = {};
    }
  }
  private async evaluateIfEntitlementIsRequired(): Promise<void> {
    this.entitlementRequiredToActivateFeature = featureGatingConstants.ENTITLEMENT_SHP_WISH_DAYS;
    await this.evaluateIfEntitlementIconShouldBeShown();
    this.tooltipText = this.showEntitlementIcon ? this.translations.featureGatingUpgradeModal : this.translations.featureGatingModal;
  }

  private async evaluateIfEntitlementIconShouldBeShown(): Promise<void> {
    try {
      if (!this.entitlementRequiredToActivateFeature) {
        this.showEntitlementIcon = true;
        return;
      }
      const isEntitlementEnabled: boolean = await this.injector
        .get(FeatureGatingService)
        .validateEntitlementFromCache(this.entitlementRequiredToActivateFeature);
      this.showEntitlementIcon = !isEntitlementEnabled;
    } catch {
      this.showEntitlementIcon = true;
    }
  }
  private async checkEntitlementValue(entitlementKey: featureGatingConstants.ENTITLEMENTS_LIST) {
    const isEntitled = await this.injector.get(FeatureGatingService).requireEntitlement(entitlementKey);
    return isEntitled;
  }

  async saveData() {
    try {
      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettings._id, { wishDays: this.wishDaysPreferences });
      this.dummyWishDayData.type =
        this.wishDaysPreferences.type === this.SHIFT_PLAN_AVAILABILITY
          ? this.translations.availabilityPreview
          : this.translations.unavailabilityPreview;
      this.showSnackbar(this.translations.availabilityModal);
      this.generalSettings.wishDays = this.wishDaysPreferences;
      this.updatedSettings.emit(this.generalSettings);
    } catch {}
  }

  async showWishDays(event: any) {
    if (event.checked) {
      const isEntitled = await this.checkEntitlementValue(featureGatingConstants.ENTITLEMENT_SHP_WISH_DAYS);
      if (!isEntitled) {
        this.wishDaysPreferences.show = false;
        return;
      }
    }
    this.saveData();
  }

  public showSnackbar(snackBarMessage: string): void {
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }

  public logAmplitudeEvent(): void {
    this.injector.get(PrivateAmplitudeService).logEvent('Toggled availability', {
      category: 'Shiftplan',
      subcategory: 'Availability',
      subcategory2: 'Availability toggled',
    });
  }
}
