<div
  *ngIf="task"
  class="kenjo-border kenjo-border-radius kenjo-color-white-background-ffffff twb-min-width"
  [class]="collapseComponent ? 'twb-main-container-collapse' : ''"
>
  <orgos-column-container centerColumns="true" [class]="collapseComponent ? 'twb-grip-container-collapse' : 'twb-grip-container'">
    <orgos-column [class]="collapseComponent ? 'twb-status-collapse' : 'twb-status'">
      <div
        class="twb-legend-task"
        [class.twb-overdue-task]="!task.isCompleted && moment(task.dueDate).isBefore(today, 'day')"
        [class.twb-done-task]="task.isCompleted"
      ></div>
    </orgos-column>
    <orgos-column [class]="collapseComponent ? 'twb-status-icon-collapse' : 'kenjo-mh-10px'">
      <kenjo-icon
        *ngIf="!task.isCompleted"
        [size]="25"
        (click)="changeTaskStatus(true)"
        class="kenjo-font-color-light-text-757575 kenjo-cursor-pointer"
      >
        check_circle_outline</kenjo-icon
      >
      <kenjo-icon
        *ngIf="task.isCompleted"
        [size]="25"
        (click)="changeTaskStatus(false)"
        class="kenjo-font-color-success-00b72e kenjo-cursor-pointer"
      >
        check_circle</kenjo-icon
      >
    </orgos-column>
    <orgos-column class="twb-main-content" [class]="collapseComponent ? 'twb-main-content-collapse kenjo-mb-10px kenjo-ml-5px' : ''">
      <input
        type="text"
        [placeholder]="i18n.titlePlaceholder"
        [ngModel]="task.title"
        [readOnly]="!hasEditPermission || !(actionTaskId === task._id)"
        (ngModelChange)="changeTask('title', $event)"
        (click)="editTitle = hasEditPermission && actionTaskId === task._id ? true : false"
        (blur)="editTitle = false"
        class="twb-title"
        [class.twb-title-edit-mode]="editTitle || !task.title"
        [matTooltip]="task.title"
        [matTooltipDisabled]="editTitle || !task.title"
        matTooltipPosition="above"
      />

      <div
        (click)="dueDatePicker.open()"
        class="twb-due-date kenjo-mr-5px"
        [class.kenjo-cursor-pointer]="actionTaskId === task._id"
        [class.twb-due-date-edit-mode]="dueDatePicker.opened || !task.dueDate"
        [class.twb-due-date-collapse]="collapseComponent"
      >
        <div
          [class.kenjo-font-color-danger-ff5757]="!task.isCompleted && task.dueDate && moment(task.dueDate).isBefore(today, 'day')"
          [class.kenjo-font-color-light-text-757575]="task.isCompleted || !task.dueDate || moment(task.dueDate).isSameOrAfter(today, 'day')"
        >
          <div *ngIf="task.dueDate && collapseComponent">
            <span class="kenjo-mr-20px">{{ i18n.dueDatePlaceholder }}:</span>
            {{ task.dueDate | utcDate | humanDate: false:'short' }}
          </div>
          <ng-container *ngIf="task.dueDate && !collapseComponent"> {{ task.dueDate | utcDate | humanDate: false:'short' }}</ng-container>
          <ng-container *ngIf="!task.dueDate">{{ i18n.dueDatePlaceholder }}</ng-container>
        </div>
        <input
          class="twb-hidden-input"
          [matDatepicker]="dueDatePicker"
          [ngModel]="task.dueDate"
          (ngModelChange)="changeTask('dueDate', $event)"
          disabled
        />
        <mat-datepicker #dueDatePicker [disabled]="actionTaskId !== task._id"></mat-datepicker>
        <mat-icon
          *ngIf="task.dueDate && hasEditPermission && actionTaskId === task._id"
          (click)="changeTask('dueDate', null); $event.stopPropagation()"
          class="twb-due-date-clear kenjo-ml-5px kenjo-cursor-pointer"
        >
          clear</mat-icon
        >
      </div>
    </orgos-column>
    <orgos-column class="kenjo-mh-10px kenjo-text-align-right" [class]="collapseComponent ? 'twb-relatedTo-label-collapse' : ''">
      <div class="kenjo-font-color-light-text-757575">{{ i18n.taskAsigneeLabel }}:</div>
      <div
        *ngIf="workflowName"
        class="kenjo-font-color-light-text-757575"
        [class.kenjo-mt-10px]="showRelatedTo"
        [class.kenjo-mt-15px]="!showRelatedTo"
      >
        {{ i18n.taskWorkflowLabel }}:
      </div>
      <div
        *ngIf="creator"
        class="kenjo-font-color-light-text-757575"
        [class.kenjo-mt-10px]="showRelatedTo"
        [class.kenjo-mt-15px]="!showRelatedTo"
      >
        {{ i18n.taskCreatorLabel }}:
      </div>
      <div *ngIf="showRelatedTo && relatedToEmployee" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
        {{ i18n.taskRelatedToEmployeeLabel }}:
      </div>
      <div *ngIf="showRelatedTo && relatedToPositionCandidate" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
        {{ i18n.taskRelatedToCandidateLabel }}:
      </div>
      <div *ngIf="showRelatedTo && relatedToMeeting" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
        {{ i18n.taskRelatedToMeetingLabel }}:
      </div>
      <div *ngIf="showRelatedTo && relatedToDocument" class="kenjo-mt-10px kenjo-font-color-light-text-757575">
        {{ i18n.taskRelatedToDocumentLabel }}:
      </div>
    </orgos-column>
    <orgos-column *ngIf="assignee" size="0" [class]="collapseComponent ? 'twb-assignee-collapse' : 'kenjo-mr-15px'">
      <div
        #assigneeBlock
        (click)="!readOnlyAssignee && hasEditPermission && actionTaskId === task._id ? searchAssignee(assigneeBlock) : ''"
        [class.kenjo-cursor-pointer]="!readOnlyAssignee && hasEditPermission && actionTaskId === task._id"
      >
        <orgos-user-item
          [maxWidth]="'120px'"
          size="tiny"
          [photoUrl]="assignee._photo?._url"
          [displayName]="assignee.displayName"
          [title]="assignee.displayName"
        >
        </orgos-user-item>
      </div>
      <kenjo-truncate-text
        class="twb-raw-text"
        *ngIf="workflowName"
        [class.kenjo-mt-5px]="showRelatedTo"
        [class.kenjo-mt-10px]="!showRelatedTo"
      >
        {{ workflowName }}</kenjo-truncate-text
      >
      <orgos-user-item
        *ngIf="creator"
        [maxWidth]="'120px'"
        size="tiny"
        [photoUrl]="creator?._photo?._url"
        [displayName]="creator?.displayName"
        [title]="creator?.displayName"
        [class.kenjo-mt-5px]="showRelatedTo"
        [class.kenjo-mt-10px]="!showRelatedTo"
      >
      </orgos-user-item>
      <div *ngIf="showRelatedTo && relatedToEmployee" class="kenjo-mt-5px">
        <a [routerLink]="['/cloud/people/', relatedToEmployee._id, 'personal']">
          <orgos-user-item
            [maxWidth]="'120px'"
            size="tiny"
            [photoUrl]="relatedToEmployee?._photo?._url"
            [displayName]="relatedToEmployee?.displayName"
            [title]="relatedToEmployee?.displayName"
          >
          </orgos-user-item>
        </a>
      </div>
      <div *ngIf="showRelatedTo && relatedToPositionCandidate" class="kenjo-cursor-pointer kenjo-mt-5px">
        <orgos-user-item
          [maxWidth]="'120px'"
          size="tiny"
          [photoUrl]="mapCandidatesById[relatedToPositionCandidate?._candidateId]?.photo?._url"
          [displayName]="
            mapCandidatesById[relatedToPositionCandidate?._candidateId]?.firstName +
            ' ' +
            mapCandidatesById[relatedToPositionCandidate?._candidateId]?.lastName
          "
          [title]="
            mapCandidatesById[relatedToPositionCandidate?._candidateId]?.firstName +
            ' ' +
            mapCandidatesById[relatedToPositionCandidate?._candidateId]?.lastName
          "
          (click)="navigateToPositionCandidate()"
        >
        </orgos-user-item>
      </div>
      <kenjo-truncate-text
        class="twb-raw-text kenjo-cursor-pointer kenjo-mt-5px"
        *ngIf="showRelatedTo && relatedToMeeting"
        (click)="navigateToMeeting()"
      >
        {{ relatedToMeeting.name }}
      </kenjo-truncate-text>
      <kenjo-truncate-text
        class="twb-raw-text kenjo-cursor-pointer kenjo-mt-5px"
        *ngIf="showRelatedTo && relatedToDocument"
        (click)="navigateToDocument()"
      >
        {{ relatedToDocument.name }}.{{ relatedToDocument._file?._fileExtension }}
      </kenjo-truncate-text>
    </orgos-column>
    <orgos-column *ngIf="hasEditPermission" [class]="collapseComponent ? 'twb-dot-menu-collapse' : 'kenjo-mr-15px'">
      <kenjo-icon *ngIf="task._id !== undefined" [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer"
        >more_vert</kenjo-icon
      >
      <mat-menu #actionsMenu="matMenu">
        <button [disabled]="!hasDeletePermission || disableDeletion" mat-menu-item (click)="onDeleteTask()">
          {{ i18n.deleteMenuItem }}
        </button>
        <button mat-menu-item (click)="onEditTask()">{{ i18n.editMenuItem }}</button>
      </mat-menu>
    </orgos-column>
    <orgos-column
      [class]="
        collapseComponent
          ? 'twb-second-color-container-collapse'
          : actionTaskId !== task._id
          ? 'hidden-button'
          : 'twb-second-color-container'
      "
    >
      <div
        [class]="collapseComponent ? 'twb-actions-color-collapse' : 'twb-actions-color'"
        [class.twb-overdue-task]="!task.isCompleted && moment(task.dueDate).isBefore(today, 'day')"
        [class.twb-done-task]="task.isCompleted"
      ></div>
    </orgos-column>
    <orgos-column
      [class]="
        actionTaskId !== task._id ? 'hidden-button' : collapseComponent ? 'twb-actions-collapse kenjo-mt-15px' : 'twb-actions kenjo-mb-10px'
      "
    >
      <orgos-button-raised class="kenjo-mr-10px" (click)="cancelTask()">{{ i18n.cancelButton }}</orgos-button-raised>
      <orgos-button-raised *ngIf="!task._id" [disabled]="!taskIsValid" class="kenjo-mr-10px" (click)="addTask(task)">{{
        i18n.addButton
      }}</orgos-button-raised>
      <orgos-button-raised *ngIf="task._id" [disabled]="!taskIsValid" class="kenjo-mr-10px" (click)="updateTask()">{{
        i18n.updateButton
      }}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</div>

<ng-template cdkPortal>
  <orgos-search
    #search
    [placeholder]="i18n.searchUserPlaceholder"
    [searchFunction]="searchAssigneeFunction"
    (searchResultsChange)="searchResults = $event"
    class="twb-search-widget"
  >
    <div class="orgos-search-error kenjo-font-color-danger-ff5757">{{ i18n.noUserFound }}</div>

    <orgos-search-option
      *ngFor="let result of searchResults"
      [userPersonal]="result"
      (selected)="search.clearSearch(); selectAssignee(result)"
    >
      <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
    </orgos-search-option>

    <orgos-container class="orgos-search-selection-container twb-search-no-term">
      <orgos-search-option
        *ngFor="let result of searchResults"
        [userPersonal]="result"
        (selected)="search.clearSearch(); selectAssignee(result)"
      >
        <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
      </orgos-search-option>
    </orgos-container>
  </orgos-search>
</ng-template>
