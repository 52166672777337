import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TimeOffTypeService } from '@app/cloud-features/time-off/services/time-off-type.service';
import { PermissionsPipe } from '@app/common-components/permissions-pipe/components/permissions-pipe.pipe';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DocumentService } from '@app/standard/services/document/document.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PeopleDetailService {
  // workaround for tabs to be shown in order
  isTabReady: { employeeTimeOffs: boolean; employeeDocs: boolean } = {
    employeeTimeOffs: false,
    employeeDocs: false,
  };

  private _miscTranslations: any;
  public get miscTranslations(): any {
    return this._miscTranslations;
  }

  private _isDocumentsActive: boolean = false;
  public get isDocumentsActive(): boolean {
    return this._isDocumentsActive;
  }

  private _isTimeOffActive: boolean = false;
  public get isTimeOffActive(): boolean {
    return this._isTimeOffActive;
  }

  private _isEmployeeTimeOffsVisible: boolean = false;
  public get isEmployeeTimeOffsVisible(): boolean {
    return this._isEmployeeTimeOffsVisible;
  }

  private _isEmployeeDocsVisible: boolean = false;
  public get isEmployeeDocsVisible(): boolean {
    return this._isEmployeeDocsVisible;
  }

  private _timeOffTypes: any;
  public get timeOffTypes(): any {
    return this._timeOffTypes;
  }

  private _seeDetailsUserId: string;
  public seeDetailsUserId$: Subject<string> = new Subject<string>();

  private _currentlyOpenTab: number;
  public get currentlyOpenTab(): number {
    return this._currentlyOpenTab;
  }

  private _lastOpenedRoute: string;
  public get lastOpenedRoute(): string {
    return this._lastOpenedRoute;
  }

  private _userWasKickedOutOfPersonalTabFromUrl: string;
  public get userWasKickedOutOfPersonalTabFromUrl(): string {
    return this._userWasKickedOutOfPersonalTabFromUrl;
  }

  constructor(private injector: Injector) {}

  async getPermissionsToSeePersonalTab(employeesPermissions: any, targetId: string): Promise<boolean> {
    try {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (loggedUser._id === targetId && employeesPermissions.c_viewPersonalTab_own === true) {
        return true;
      }
      if (employeesPermissions.c_viewPersonalTab_all === true) {
        return true;
      }
      if (
        check.not.assigned(employeesPermissions.c_viewPersonalTab_custom) ||
        check.emptyArray(employeesPermissions.c_viewPersonalTab_custom)
      ) {
        return false;
      }

      const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
      const thisUserWork = allUserWork.find((iUserWork) => {
        return iUserWork._id === targetId;
      });
      if (check.not.assigned(thisUserWork) || check.emptyObject(thisUserWork)) {
        return false;
      }
      const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
        null,
        'user-work',
        employeesPermissions[`c_viewPersonalTab_custom`],
        employeesPermissions[`c_viewPersonalTab_own`],
        thisUserWork,
        allUserWork,
        loggedUser
      );
      return customPermissionResult;
    } catch (e) {
      return false;
    }
  }

  /**
   * this method is called on loading /cloud/people route and loading is completed before showing the page
   */
  async onPeopleRouteLoaded(id: string): Promise<void> {
    try {
      const mistTranslationsPromise = this.injector.get(InternationalizationService).getAllTranslation('people-detail-misc');
      const documentsPromise = this.injector.get(CloudRoutesService).getAppStatus('documents');
      const myPermissionsPromise = await this.injector.get(PrivateSecurityService).getAllPermissions();

      this._isTimeOffActive = this.injector.get(CloudRoutesService).checkRoute('time-off/personal');

      const [miscTranslations, documentsStatus, myPermissions] = await Promise.all([
        mistTranslationsPromise,
        documentsPromise,
        myPermissionsPromise,
      ]);

      this._isDocumentsActive = documentsStatus?.isActive;
      this._miscTranslations = miscTranslations;

      if (this._isDocumentsActive === true) {
        this._isEmployeeDocsVisible = await this.getEmployeeDocVisibility(id);
      }

      if (this._isTimeOffActive === true) {
        const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

        // user is trying to see own page and has permissions for it
        if (loggedUser._id === id && myPermissions?.['time-off-app']?.c_useTimeOff === true) {
          this._isEmployeeTimeOffsVisible = true;
          return;
        }

        // user is trying to see another employee's page
        const userAcccounts = await this.injector.get(UserAccountService).getUserAccountWithAccessibleTimeOffs(false);
        const currentUserIsAccessible = userAcccounts.find((userAccount) => {
          return userAccount._id === id;
        });
        this._isEmployeeTimeOffsVisible = check.assigned(currentUserIsAccessible);
      }
    } catch {
      // An error is already shown
    }
  }

  canSeeUsersSmartDocs(): boolean {
    return this.isEmployeeDocsVisible === true && this._isDocumentsActive === true;
  }

  canSeeUsersTimeOff(): boolean {
    return this._isEmployeeTimeOffsVisible === true && this._isTimeOffActive === true;
  }

  verifySmartDocsLink(id: string): void {
    if (this.isEmployeeDocsVisible === true && this._isDocumentsActive === true) {
      this.isTabReady.employeeDocs = true;
      this.pushSecondaryMenuOptionsInOrder(id);
    }
  }

  verifyShowTimeOffLink(id: string, userPersonal: any): void {
    if (
      this._isTimeOffActive &&
      (this._isEmployeeTimeOffsVisible === true ||
        this.injector.get(PermissionsPipe).transform('time-off-app.c_accessTimeOffCompanyPage', userPersonal) === true)
    ) {
      this.isTabReady.employeeTimeOffs = true;
      this.pushSecondaryMenuOptionsInOrder(id);
    }
  }

  openEmployeeDoc(id: string): void {
    this._lastOpenedRoute = this.injector.get(Router).url;
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const docUrl = id === loggedUser._id ? `cloud/documents/my-docs` : `cloud/documents/employee-docs/${id}?ep=true`; // we add ep=true (employee-public) at the end to route the custom back button from employee-docs page
    this.injector.get(Router).navigateByUrl(docUrl);
  }

  async openEmployeeTimeOff(id: string): Promise<void> {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    // case 1: opening own time off: redirect to route
    if (id === loggedUser._id) {
      this.injector.get(Router).navigateByUrl(`cloud/time-off/personal`);
    } else {
      // case 2: opening someone else's time off: make component visible
      this._currentlyOpenTab = this.injector.get(GlobalBarService).selectedSecondaryMenuOption.getValue();
      this._timeOffTypes = await this.injector.get(TimeOffTypeService).findTimeOffTypePolicies({ _id: { $ne: null } });
      this._seeDetailsUserId = id;
      this.seeDetailsUserId$.next(this._seeDetailsUserId);
    }
  }

  async closeEmployeeTimeOff(): Promise<void> {
    this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(this._currentlyOpenTab);
    this._seeDetailsUserId = null;
    this.seeDetailsUserId$.next(this._seeDetailsUserId);
  }

  private pushSecondaryMenuOptionsInOrder(id: string): void {
    if (this.isTabReady.employeeDocs === true) {
      this.injector
        .get(GlobalBarService)
        .pushSecondaryMenuOption({ name: this._miscTranslations.smartDocsTab, onClick: () => this.openEmployeeDoc(id) });
      this.isTabReady.employeeDocs = false;
    }
    if (this.isTabReady.employeeTimeOffs === true) {
      this.injector
        .get(GlobalBarService)
        .pushSecondaryMenuOption({ name: this._miscTranslations.timeOffTab, onClick: () => this.openEmployeeTimeOff(id) });
      this.isTabReady.employeeTimeOffs = false;
    }
  }

  private getEmployeeDocVisibility(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let docPermissions;
      let userRequest = {};
      this.injector
        .get(DocumentService)
        .getPermissions()
        .then((documentPermissionsResult) => {
          docPermissions = documentPermissionsResult;
          if (check.not.assigned(docPermissions)) {
            resolve(false);
          }

          if (check.assigned(docPermissions.read_all) && docPermissions.read_all === true) {
            resolve(true);
          }

          const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
          if (check.assigned(docPermissions.read_own) && docPermissions.read_own === true && id === loggedUser._id) {
            resolve(true);
          }

          if (check.not.assigned(docPermissions.read_custom) || check.emptyArray(docPermissions.read_custom)) {
            resolve(false);
          }

          userRequest = {
            userInfo: {
              _id: loggedUser._id,
            },
          };
          return this.injector.get(UserWorkService).getAllUserWorkCache();
        })
        .then((allUserWork) => {
          const relatedUserQueries = docPermissions.read_custom.map((block) => {
            return customPermissions.getQueryFromBlockAndConditions(
              userRequest,
              null,
              'document',
              block.blockAndConditions,
              null,
              null,
              allUserWork
            );
          });
          return Promise.all(relatedUserQueries);
        })
        .then((blockAndConditions) => {
          blockAndConditions.forEach((block) => {
            if (
              check.assigned(block['relatedTo.idRelatedTo']) &&
              check.nonEmptyArray(block['relatedTo.idRelatedTo'].$in) &&
              block['relatedTo.idRelatedTo'].$in.includes(id)
            ) {
              resolve(true);
            }
          });
          resolve(false);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  setUserWasKickedOutOfPersonalTabFromUrl(url: string) {
    if (url.includes('personal')) {
      return;
    }
    this._userWasKickedOutOfPersonalTabFromUrl = url;
  }
}
