<mat-form-field (click)="openDatepicker()" class="kenjo-cursor-pointer">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-date-range-input [rangePicker]="datepicker">
    <input [placeholder]="fromPlaceholder" readonly matStartDate [ngModel]="value?.from" (ngModelChange)="onValueChange($event, 'from')" />
    <input [placeholder]="toPlaceholder" readonly matEndDate [ngModel]="value?.to" (ngModelChange)="onValueChange($event, 'to')" />
  </mat-date-range-input>
  <mat-icon matSuffix *ngIf="value?.from || value?.to" class="idpc-clear-button" (click)="clearValue(); $event.stopPropagation()">clear</mat-icon>
  <mat-icon *ngIf="showMenu" matSuffix class="idpc-menu-button" [class.idr-menu-disabled]="menuOptions?.length <= 0" [matMenuTriggerFor]="rangeMenu" (click)="$event.stopPropagation()">arrow_drop_down</mat-icon>
  <mat-menu *ngIf="showMenu" menu #rangeMenu="matMenu" class="prc-menu">
    <button (click)="menuChangeHandler(option.value)" *ngFor="let option of menuOptions" mat-menu-item>{{ option.name }}</button>
  </mat-menu>
</mat-form-field>

<mat-date-range-picker (closed)="onClose()" #datepicker [startAt]="startAt"></mat-date-range-picker>
