import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'orgos-confirm-list-dialog',
  templateUrl: 'confirm-list-dialog.component.html',
  styleUrls: ['confirm-list-dialog.component.scss'],
})
export class ConfirmListDialogComponent {
  titleText: string;
  subtitleText: string;
  list: Array<string>;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' = 'Success';
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;

  constructor(
    public dialogRef: MatLegacyDialogRef<ConfirmListDialogComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {
    this.titleText = this.data.titleText;
    this.subtitleText = this.data.subtitleText;
    this.list = this.data.list;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.confirmButtonId = this.data.confirmButtonId ?? '';
    this.cancelButtonId = this.data.cancelButtonId ?? '';
    this.componentId = this.data.componentId ?? null;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }
}
