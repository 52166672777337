import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class WorkflowRunsService {
  private WORKFLOW_RUNS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/workflow-runs-db`;

  constructor(private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  async getWorkflowsRuns(query: any): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      const docs = await this.http.post(`${this.WORKFLOW_RUNS_URL}/find`, query, httpOptions).toPromise();

      return docs;
    } catch (error) {
      throw this.errorManager.handleRawError(error, 'WorkflowRunsService', 'getAllWorkflowsRuns');
    }
  }
}
