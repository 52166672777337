import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger } from '@angular/material/legacy-menu';
import { IAudienceVisibility } from '@app/standard/components/audience-selector/services/audience-selector.component.service';
import { InputAbstractComponent } from '@app/standard/components/input-abstract/input-abstract.component';
import { ComponentItemType } from '@app/standard/components/search-item-dropdown-custom/search-item-dropdown-custom.component';
import { ISelectOption } from '@app/standard/core/select-option';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-select-dropdown-custom',
  templateUrl: './select-dropdown-custom.component.html',
  styleUrls: ['./select-dropdown-custom.component.scss'],
})
export class SelectDropdownCustomComponent extends InputAbstractComponent implements OnInit {
  @Input() readonly options: Array<ISelectItem | ISelectCategoryItem> = [];
  @Input() required: boolean = false;
  @Input() width: string = '300px';
  @Input() componentKey: ComponentItemType;
  @Input() defaultOption: ISelectItem;
  @Input() translations: any;
  @Output() selectOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() cleanOption: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('optionInput', { static: false }) optionInput?: any;
  @ViewChild(MatLegacyMenuTrigger) trigger: MatLegacyMenuTrigger;
  item: ISelectItem | ISelectCategoryItem = { name: '', value: '' };
  emptyOptionsText: string = '';
  label: string = '';
  searchPlaceholder: string = '';
  colors: any = userColorConstants;

  ngOnInit(): void {
    this.initTranslations();
    if (this.defaultOption) {
      this.item = this.defaultOption;

      this.emitSelection(this.defaultOption.value);
    }
  }

  onSelectOption($event: ISelectItem) {
    if (this.componentKey !== 'category-item') {
      this.optionInput.value = $event.name;
    }
    this.item = $event;
    this.emitSelection($event.value);
    this.trigger.closeMenu();
  }

  emitSelection(id: string): void {
    this.selectOption.emit(id);
    this.setValue(id);
  }

  initTranslations() {
    this.label = this.translations?.label || '';
    this.searchPlaceholder = this.translations?.search || '';
    this.emptyOptionsText = this.translations?.emptyOptionsText || '';
  }

  onCleanValue($event: Event) {
    this.item = { name: '', value: '' };
    this.cleanOption.emit();
    $event.stopPropagation();
  }
}

export interface ISelectItem extends ISelectOption {
  _isDefault: boolean;
}

export interface ISelectCategoryItem extends ISelectOption {
  color?: string;
  _id?: string;
  parentCategoryId?: string;
  isActive?: boolean;
  visibleToEveryone?: boolean;
  visibleToSpecific?: IAudienceVisibility;
  _createdAt?: Date;
  _updatedAt?: Date;
}
