<orgos-dialog-container class="dialog-box" [title]="pageTranslation.dialogName" (clickCloseButton)="closeDialog()">
  <orgos-column-container centerColumns="true" class="kenjo-pv-10px kenjo-ph-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
    <orgos-document-icon orgosColumn="0" [extension]="getExtension()" class="kenjo-pr-20px"></orgos-document-icon>
    <orgos-output class="rud-output" orgosColumn="1" [model]="document" field="name"></orgos-output>
    <orgos-text orgosColumn="0" class="kenjo-pr-20px">.{{getExtension()}}</orgos-text>
    <kenjo-icon orgosColumn="0" class="kenjo-font-size-18px kenjo-font-color-success-00b72e">
      done
    </kenjo-icon>
  </orgos-column-container>

  <div class="kenjo-mt-20px">
    <orgos-input-text [model]="document" field="name" [required]="true" class="udd-field" (validation)="nameValidation = $event">
      <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">{{pageTranslation.nameRequired}} </orgos-input-error>
    </orgos-input-text>
  </div>

  <div class="kenjo-mt-10px kenjo-mb-5px kenjo-font-color-light-text-757575">{{pageTranslation.tagOnlyOne}}</div>
  <orgos-column-container wrap="true">
    <orgos-chip orgosColumn="0" *ngFor="let tag of listTags" [color]="getTagColor(tag)" [buttonIconName]="isTagSelected(tag) === true ? 'clear' : 'add'" [class.kenjo-font-color-white-ffffff]="isTagSelected(tag) === true" [onClickButtonFunction]="toggleTag(tag)" class="kenjo-mv-5px kenjo-mr-10px"
      >{{pageTranslation[tag.name] ? pageTranslation[tag.name] : tag.name}}
    </orgos-chip>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ miscTranslation.goBackButtonDialog }} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Success" [disabled]="!nameValidation || nameValidation.hasErrors() || !document.data.tag || uploadingFile" (click)="uploadDocument()">
        {{document.data._id ? pageTranslation.saveButtonLabel : pageTranslation.uploadButtonLabel}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
