import * as check from 'check-types';

interface IErrors {
  [errorName: string]: boolean;
}

export class InputValidation {
  private errors: IErrors = {};

  isValid(): boolean {
    return !this.hasErrors();
  }

  hasErrors(): boolean {
    return check.not.emptyObject(this.errors);
  }

  getAllErrors(): IErrors {
    return this.errors;
  }

  getError(name: string): boolean {
    const error = this.errors[name];
    return check.assigned(error) && error === true;
  }

  setError(name: string): void {
    this.errors[name] = true;
  }

  freeze(): InputValidation {
    Object.freeze(this.errors);
    Object.freeze(this);
    return this;
  }
}

export type IInputValidationFunction = (value: any) => InputValidation;
