import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlphaNumericInputDirective } from '@app/standard/directives/alpha-numeric-input.directive';
import { KenjoRemoveAriaOwnsDirective } from '@app/standard/directives/remove-aria-owns.directive';

@NgModule({
  declarations: [AlphaNumericInputDirective, KenjoRemoveAriaOwnsDirective],
  imports: [CommonModule],
  exports: [AlphaNumericInputDirective, KenjoRemoveAriaOwnsDirective],
})
export class DirectivesModule {}
