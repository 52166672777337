import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { NgxCumulioDashboardComponent } from '@cumul.io/ngx-cumulio-dashboard';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PlatformUsageService {
  private USAGE_DATA_METRICS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/usage-data-metrics`;

  private _preparingExport: boolean = false;
  public get preparingExport(): boolean {
    return this._preparingExport;
  }

  private _isDatasetEmpty: boolean | null = null;
  private _alwaysOnApps: any = {
    workflows: true,
    general: true,
    payroll: true
  };

  constructor(private injector: Injector) {}

  async getIsDatasetEmpty(): Promise<boolean> {
    if (this._isDatasetEmpty !== null) {
      return this._isDatasetEmpty;
    } else {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return (await this.injector.get(HttpClient).get(`${this.USAGE_DATA_METRICS_URL}/is-dataset-empty`, httpOptions).toPromise()) as boolean;
    }
  }

  // we cannot cache this info because apps can be enabled and disabled without reloading, but it's fine because this page won't be visited often
  private async fetchApps(): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };
    const apps = await this.injector.get(HttpClient).get(`${this.USAGE_DATA_METRICS_URL}/enabled-apps-for-usage`, httpOptions).toPromise();
    return Object.assign(apps, this._alwaysOnApps);
  }

  async isAppEnabled(appKey: string): Promise<boolean> {
    const appsMap = await this.fetchApps();
    return appsMap[appKey];
  }

  async getDisabledOtherFeatures(): Promise<Array<string>> {
    const apps = await this.fetchApps();
    const disabledOtherFeatures = [];
    apps.recruiting === false && disabledOtherFeatures.push('recruiting');
    apps.performanceReview === false && disabledOtherFeatures.push('performanceReview');
    apps.smartDocs === false && disabledOtherFeatures.push('smartDocs');
    return disabledOtherFeatures;
  }

  async printPDF(element: NgxCumulioDashboardComponent): Promise<void> {
    try {
      this._preparingExport = true;
      await element.exportDashboard('pdf').toPromise();
    } catch (error: any) {
      this.injector.get(ErrorManagerService).handleRawError(error, 'settings-billing-platform-usage', 'createPDF');
    } finally {
      this._preparingExport = false;
    }
  }

  enableDownloadButton(): void {
    this._preparingExport = false;
  }
}
