import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUserPersonalWorkModel } from '@app/models/controllers/user-personal-work.model';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { WidgetsService } from '@app/standard/services/widgets/widgets.service';
import { getDateFormat } from '@carlos-orgos/orgos-utils/date-utils';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-widget-anniversary',
  templateUrl: 'orgos-widget-anniversary.component.html',
  styleUrls: ['orgos-widget-anniversary.component.scss'],
})
export class WidgetAnniversaryComponent implements OnInit {
  translation: any = {};
  loadingWidget: boolean = false;

  allUsers: Array<IUserPersonalWorkModel>;
  userSelected: any;
  todayUsers: Array<IUserPersonalWorkModel> = [];
  thisWeekUsers: Array<IUserPersonalWorkModel> = [];
  followingMonthUsers: Array<any> = [];

  getDateFormat: Function = (formatName) => {
    return getDateFormat(formatName, this.injector.get(InternationalizationService).getLocale());
  };

  getNumberOfYearsInCompany: Function = (startDate: string) => {
    const data = {
      numberOfYearsInCompany: Math.ceil(moment.utc().subtract(1, 'day').diff(moment.utc(startDate), 'years', true)),
    };

    return data;
  };

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.loadingWidget = true;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('home-widget-anniversary-component')
      .then((translation) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(WidgetsService)
      .getWorkAnniversaries()
      .then((activeUsers: Array<IUserPersonalWorkModel>) => {
        this.allUsers = activeUsers;
        this.loadAnniversaries();
      })
      .catch(() => {
        this.loadingWidget = false;
        // Do nothing
      });
  }

  private loadAnniversaries(): void {
    const today = moment.utc();
    const weekStarts = moment.utc().startOf('day');
    const weekEnds = moment.utc().endOf('week');

    const userAnniversaries = [];
    this.allUsers.forEach((iUser) => {
      const startDate = moment.utc(iUser.userWork.startDate);

      if (startDate.isSameOrAfter(today, 'day')) {
        return;
      }

      startDate.year(today.year());
      if (startDate.isBefore(today, 'day')) {
        startDate.add(1, 'year');
      }

      const startDataUser: any = Object.assign({}, iUser);
      startDataUser.userWork.originalStartDate = iUser.userWork.startDate;
      startDataUser.userWork.startDate = startDate;
      userAnniversaries.push(startDataUser);
    });

    const unsortedTodayUsers = userAnniversaries.filter((user) => {
      const startDates = moment.utc(user.userWork.startDate);
      return moment.utc(startDates).isSame(today, 'day');
    });

    this.todayUsers = _.orderBy(
      unsortedTodayUsers,
      (iUser) => {
        return moment.utc(iUser.userWork.startDate).format('YYYYMMDD');
      },
      ['asc']
    );

    const unsortedThisWeekUsers = userAnniversaries.filter((user) => {
      const startDates = moment.utc(user.userWork.startDate);
      return moment.utc(startDates).isBetween(weekStarts, weekEnds, 'day', '(]');
    });

    this.thisWeekUsers = _.orderBy(
      unsortedThisWeekUsers,
      (iUser) => {
        return moment.utc(iUser.userWork.startDate).format('YYYYMMDD');
      },
      ['asc']
    );

    const startDateByMonth = {};
    userAnniversaries
      .filter((user) => {
        const startDates = moment.utc(user.userWork.startDate);
        return moment.utc(startDates).isAfter(weekEnds, 'day');
      })
      .forEach((user) => {
        const anniversaryMonth = moment.utc(user.userWork.startDate).startOf('month').format('YYYYMMDD');
        if (check.not.assigned(startDateByMonth[anniversaryMonth])) {
          startDateByMonth[anniversaryMonth] = [];
        }
        startDateByMonth[anniversaryMonth].push(user);
      });

    const unsortedAnniversaries = Object.keys(startDateByMonth).map((month) => {
      const sortedUsersInMonth = _.orderBy(
        startDateByMonth[month],
        (iUser) => {
          return moment.utc(iUser.userWork.startDate).format('YYYYMMDD');
        },
        ['asc']
      );

      const anniversaryMonth = {
        month: month,
        anniversaries: sortedUsersInMonth,
      };
      return anniversaryMonth;
    });

    this.followingMonthUsers = _.orderBy(
      unsortedAnniversaries,
      (anniversaryMonth: any) => {
        return moment.utc(anniversaryMonth.month).format('YYYYMMDD');
      },
      ['asc']
    );

    if (check.not.emptyArray(this.todayUsers) === true && check.assigned(this.todayUsers[0])) {
      this.userSelected = this.todayUsers[0];
    } else if (check.not.emptyArray(this.thisWeekUsers) === true && check.assigned(this.thisWeekUsers[0])) {
      this.userSelected = this.thisWeekUsers[0];
    } else if (check.not.emptyArray(this.followingMonthUsers) === true && check.assigned(this.followingMonthUsers[0].anniversaries[0])) {
      this.userSelected = this.followingMonthUsers[0].anniversaries[0];
    }
    this.loadingWidget = false;
  }

  public getDateInMomentFormat(unformatedDate: string): any {
    return moment.utc(unformatedDate, 'YYYYMMDD');
  }

  public navigateToPersonalPage(userId: string): void {
    this.injector.get(Router).navigateByUrl(`cloud/people/${userId}/personal`);
  }
}
