<ng-container *ngIf="mode === 'admin'">
  <div class="sec-main-container kenjo-p-40px kenjo-text-not-selectable">
    <div class="sec-main-header">
      <div class="sec-admin-header">
        <div class="kenjo-font-color-text-333333 kenjo-font-size-18px kenjo-font-weight-bold">
          {{ pageTranslations.freeTrialExpiredTitle }}
        </div>

        <div class="kenjo-font-color-light-text-757575 kenjo-pt-10px kenjo-font-size-14px">
          {{ pageTranslations.freeTrialExpiredDescriptionAdminMode }}
        </div>
      </div>

      <div class="sec-close-icon-container">
        <kenjo-icon [size]="24" class="sec-close-button kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"
          >clear</kenjo-icon
        >
      </div>
    </div>

    <div class="kenjo-mt-30px sec-cards-container">
      <div class="sec-message-card kenjo-p-20px kenjo-mr-15px">
        <div class="sec-header-container kenjo-pv-10px">
          <kenjo-icon-highlight icon="support_agent" semantic="brand" size="l"></kenjo-icon-highlight>
          <div class="sec-card-title kenjo-font-color-text-333333 kenjo-mt-20px kenjo-font-size-18px kenjo-font-weight-bold">
            {{ pageTranslations.freeTrialExpiredBookDemoTitle }}
          </div>
        </div>
        <div class="sec-card-description kenjo-mt-20px kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ pageTranslations.freeTrialExpiredBookDemoDescription }}
        </div>
        <div class="sec-card-button kenjo-mt-20px">
          <orgos-button-raised (click)="bookDemo()" [disabled]="disableButtons" color="Neutral" class="sec-button">
            {{ pageTranslations.freeTrialExpiredBookDemoButtonLabel }}
          </orgos-button-raised>
        </div>
      </div>
      <div class="sec-message-card kenjo-p-20px kenjo-ml-15px">
        <div class="sec-header-container kenjo-pv-10px">
          <kenjo-icon-highlight icon="upgrade" semantic="brand" size="l"></kenjo-icon-highlight>
          <div class="sec-card-title kenjo-font-color-text-333333 kenjo-mt-20px kenjo-font-size-18px kenjo-font-weight-bold">
            {{ pageTranslations.freeTrialExpiredUpgradeToPlanTitle }}
          </div>
        </div>
        <div class="sec-card-description kenjo-mt-20px kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ pageTranslations.freeTrialExpiredUpgradeToPlanDescription }}
        </div>
        <div class="sec-card-button kenjo-mt-20px">
          <kenjo-button-flat (click)="choosePlan()" [disabled]="disableButtons" class="sec-button">
            {{ pageTranslations.freeTrialExpiredUpgradeToPlanButtonLabel }}
          </kenjo-button-flat>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="mode === 'non-admin'" class="sec-main-container kenjo-p-40px">
  <div class="sec-header-container kenjo-pv-10px">
    <kenjo-icon-highlight icon="upgrade" semantic="brand" size="2xl"></kenjo-icon-highlight>
    <div class="kenjo-font-color-text-333333 kenjo-pt-20px kenjo-font-size-18px kenjo-font-weight-bold">
      {{ pageTranslations.freeTrialExpiredTitle }}
    </div>
  </div>

  <div class="kenjo-font-color-light-text-757575 kenjo-pt-20px kenjo-font-size-14px">
    {{ pageTranslations.freeTrialExpiredDescriptionNonAdminMode }}
  </div>

  <div class="kenjo-pt-20px">
    <orgos-action (click)="signOut()" icon="logout">
      {{ pageTranslations.freeTrialExpiredSignOutButtonLabel }}
    </orgos-action>
  </div>
</div>
