import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class RecruitingScheduleDeletionConfigurationService implements IGenericService {
  private RECRUITING_SCHEDULE_DELETION_CONFIGURATION_URL = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-schedule-deletion-configuration-db`;
  private RECRUITING_SCHEDULE_DELETION_CONFIGURATION_INTERNATIONALIZATION = 'recruiting-automatic-deletion-page';
  private RECRUITING_SCHEDULE_DELETION_CONFIGURATION_PERMISSIONS_KEY = 'recruiting-schedule-deletion-configuration';
  constructor(private injector: Injector, private errorManager: ErrorManagerService, private http: HttpClient, private genericService: GenericService) {}

  async getConfiguration() {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, RecruitingScheduleDeletionConfigurationService.name, 'getConfiguration');
        throw this.errorManager.handleRawError(error);
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = { headers };

      const result = await this.http.get<IRecruitingScheduleDeletionConfiguration>(`${this.RECRUITING_SCHEDULE_DELETION_CONFIGURATION_URL}/`, httpOptions).toPromise();
      return result;
    } catch(error) {
      throw this.errorManager.handleRawError(error, RecruitingScheduleDeletionConfigurationService.name, 'getConfiguration')
    }
  }

  async updateById(id: string, data: object) {
    try {
      await this.genericService.updateById(this.RECRUITING_SCHEDULE_DELETION_CONFIGURATION_URL, id, data)
    } catch(error) {
      throw this.errorManager.handleRawError(error, RecruitingScheduleDeletionConfigurationService.name, 'updateById');
    }
  }

  async getFieldsTranslations() {
    return this.genericService.getFieldsTranslations(this.RECRUITING_SCHEDULE_DELETION_CONFIGURATION_INTERNATIONALIZATION);
  }

  async getById(id: string) {
    try {
      const result = await this.genericService.getById(this.RECRUITING_SCHEDULE_DELETION_CONFIGURATION_URL, id);
      return result;
    } catch(e) {
      throw this.errorManager.handleRawError(e, RecruitingScheduleDeletionConfigurationService.name, 'getById');
    }
  }

  deleteById(id: string) {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, RecruitingScheduleDeletionConfigurationService.name, 'deleteById');
    error.message = 'Schedule Deletion Configuration should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.RECRUITING_SCHEDULE_DELETION_CONFIGURATION_PERMISSIONS_KEY);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.RECRUITING_SCHEDULE_DELETION_CONFIGURATION_URL);
  }

  create(data: object): Promise<IRecruitingScheduleDeletionConfiguration> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, RecruitingScheduleDeletionConfigurationService.name, 'create');
    error.message = 'Schedule Deletion Configuration should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }
}

export interface IRecruitingScheduleDeletionConfiguration {
  _id: string;
  isAutomaticDeletionEnabled?: boolean;
  deleteAfterDays?: number;
  deletionType?: string;
}