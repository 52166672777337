import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class HiringTeamService {
  private HIRING_TEAM_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/hiring-team-db`;
  private HIRING_TEAM_PERMISSIONS_KEY: string = 'hiring-team';
  private HIRING_TEAM_INTERNATIONALIZATION: string = 'hiring-team-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IHiringTeamModel> {
    return new Promise<IHiringTeamModel>((resolve, reject) => {
      this.genericService
        .create(this.HIRING_TEAM_URL, data)
        .then((HiringTeam: IHiringTeamModel) => {
          resolve(HiringTeam);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HiringTeamService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IHiringTeamModel> {
    return new Promise<IHiringTeamModel>((resolve, reject) => {
      this.genericService
        .getById(this.HIRING_TEAM_URL, id)
        .then((HiringTeam: IHiringTeamModel) => {
          resolve(HiringTeam);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HiringTeamService.name, 'getById'));
        });
    });
  }

  getMembersForPositionId(positionId: string): Promise<Array<IHiringTeamModel>> {
    return new Promise<Array<IHiringTeamModel>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, HiringTeamService.name, 'getMembersForPositionId');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const findQuery = {
        _positionId: positionId
      };

      this.http
        .post<Array<IHiringTeamModel>>(`${this.HIRING_TEAM_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((hiringTeamResponse: Array<IHiringTeamModel>) => {
          resolve(hiringTeamResponse);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HiringTeamService.name, 'getMembersForPositionId'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.HIRING_TEAM_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HiringTeamService.name, 'updateById'));
        });
    });
  }

  setAllMembersForPositionId(positionId: string, members: Array<IHiringTeamModel>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, HiringTeamService.name, 'setAllMembersForPositionId');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const updateQuery = {
        hiringTeam: members
      };

      this.http
        .put(`${this.HIRING_TEAM_URL}/set-all-members/${positionId}`, updateQuery, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HiringTeamService.name, 'setAllMembersForPositionId'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.HIRING_TEAM_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HiringTeamService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.HIRING_TEAM_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.HIRING_TEAM_INTERNATIONALIZATION);
  }

  find(findBody: object): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.genericService
        .find(this.HIRING_TEAM_URL, findBody)
        .then((results: Array<any>) => {
          resolve(results);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, HiringTeamService.name, 'find'));
        });
    });
  }
}

export interface IHiringTeamModel {
  _id?: string;
  role?: string;
  permissions?: object;
  _userId?: string;
  _positionId?: string;
  _updatedAt?: Date;
  _updatedById?: string;
}
