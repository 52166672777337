<orgos-column-container centerColumns="true">
  <orgos-avatar
    orgosColumn="0"
    [avatarName]="displayName || title"
    [photoUrl]="photoUrl"
    [size]="componentSize"
    [enableChangePhoto]="enableChangePhoto"
    (changePhoto)="changePhoto.emit()"
  ></orgos-avatar>
  <orgos-column size="1" class="kenjo-ml-10px">
    <orgos-text [style.width]="width" [style.max-width]="maxWidth" [noWrapText]="title" [color]="color">
      {{ title }}
    </orgos-text>
    <orgos-text
      [style.width]="width"
      [style.max-width]="maxWidth"
      [noWrapText]="subtitle"
      color="LightText"
      class="kenjo-pt-10px"
      *ngIf="subtitle"
    >
      {{ subtitle }}
    </orgos-text>
    <orgos-column-container *ngIf="actionText || extraInfo" class="kenjo-pt-10px">
      <orgos-column *ngIf="actionText" size="1">
        <orgos-action>{{ actionText }}</orgos-action>
      </orgos-column>
      <orgos-column *ngIf="extraInfo" size="0">
        <orgos-text [style.width]="width" [style.max-width]="maxWidth" [noWrapText]="extraInfo" color="LightText">
          {{ extraInfo }}</orgos-text
        >
      </orgos-column>
    </orgos-column-container>
    <div class="kenjo-mt-10px flex-horizontal" *ngIf="userAccountSatus && invitationStatus">
      <ng-container *ngIf="userAccountSatus">
        <kenjo-user-account-status-chip [userAccount]="userAccountSatus"></kenjo-user-account-status-chip>
      </ng-container>
      <ng-container *ngIf="invitationStatus">
        <kenjo-user-invitation-status-chip
          hideFinalStatus="true"
          class="kenjo-ml-5px"
          [userAccount]="invitationStatus"
          [displayName]="title"
        ></kenjo-user-invitation-status-chip>
      </ng-container>
    </div>
  </orgos-column>
</orgos-column-container>
