import { Component, Injector, Input, OnInit } from '@angular/core';
import { RequestStatusType, RequestTypeType } from '@app/cloud-features/time-off/services/time-off-request.service';
import { StandardServicesRegistry } from '@app/standard/services/standard-services.registry';
import {
  TIME_OFF_REQUEST_STATUS_APPROVED,
  TIME_OFF_REQUEST_STATUS_CANCELLED,
  TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED,
  TIME_OFF_REQUEST_STATUS_DECLINED,
  TIME_OFF_REQUEST_STATUS_IN_APPROVAL,
  TIME_OFF_REQUEST_STATUS_PENDING,
  TIME_OFF_REQUEST_STATUS_PROCESSED,
  TIME_OFF_REQUEST_STATUS_SUBMITTED,
  TIME_OFF_REQUEST_TYPE_REQUEST,
  TIME_OFF_REQUEST_TYPE_SUBMISSION
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'orgos-time-off-request-status',
  templateUrl: 'time-off-request-status.component.html',
  styleUrls: ['time-off-request-status.component.scss']
})
export class TimeOffRequestStatusComponent implements OnInit {
  componentTranslation: any = {};

  @Input() type: RequestTypeType;
  @Input() status: RequestStatusType;

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('time-off-request-status')
      .then((componentTranslation) => {
        this.componentTranslation = componentTranslation;
      })
      .catch(() => {
        this.componentTranslation = {};
      });
  }

  getStatusColor(): string {
    if (check.not.assigned(this.type) || check.not.assigned(this.status)) {
      return 'Neutral';
    }
    if (this.status === TIME_OFF_REQUEST_STATUS_APPROVED || this.status === TIME_OFF_REQUEST_STATUS_SUBMITTED || this.status === TIME_OFF_REQUEST_STATUS_PROCESSED) {
      return 'Success';
    } else if (this.status === TIME_OFF_REQUEST_STATUS_PENDING || this.status === TIME_OFF_REQUEST_STATUS_IN_APPROVAL) {
      return 'Warning';
    } else if (this.status === TIME_OFF_REQUEST_STATUS_DECLINED) {
      return 'Danger';
    } else if (this.status === TIME_OFF_REQUEST_STATUS_CANCELLED || this.status === TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED) {
      return 'Archived';
    }
    return 'Neutral';
  }

  getStatusName(): string {
    if (check.not.assigned(this.type) || check.not.assigned(this.status)) {
      return '';
    }
    if (this.type === TIME_OFF_REQUEST_TYPE_REQUEST) {
      if (this.status === TIME_OFF_REQUEST_STATUS_APPROVED || this.status === TIME_OFF_REQUEST_STATUS_PROCESSED) {
        return this.componentTranslation['RequestAcceptedStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_PENDING || this.status === TIME_OFF_REQUEST_STATUS_IN_APPROVAL) {
        return this.componentTranslation['RequestPendingStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_DECLINED) {
        return this.componentTranslation['RequestDeclinedStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_CANCELLED || this.status === TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED) {
        return this.componentTranslation['RequestCancelledStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_SUBMITTED) {
        return this.componentTranslation['SubmittedStatus'];
      }
    }
    if (this.type === TIME_OFF_REQUEST_TYPE_SUBMISSION) {
      if (this.status === TIME_OFF_REQUEST_STATUS_SUBMITTED || this.status === TIME_OFF_REQUEST_STATUS_PROCESSED) {
        return this.componentTranslation['SubmittedStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_PENDING || this.status === TIME_OFF_REQUEST_STATUS_IN_APPROVAL) {
        return this.componentTranslation['RequestPendingStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_APPROVED) {
        return this.componentTranslation['RequestAcceptedStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_DECLINED) {
        return this.componentTranslation['RequestDeclinedStatus'];
      }
      if (this.status === TIME_OFF_REQUEST_STATUS_CANCELLED || this.status === TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED) {
        return this.componentTranslation['SubmissionCancelledStatus'];
      }
    }
    return '-';
  }
}
