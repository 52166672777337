<orgos-container aspect="card" *ngIf="!loadingPage && !errorOnFetchingData">
  <orgos-column-container class="sbp-main-container">
    <orgos-column size="0" class="sbp-menu kenjo-p-30px kenjo-border-right">
      <orgos-settings-billing-menu [menuTranslation]="i18n.page.menu" [selectedOption]="3"></orgos-settings-billing-menu>
    </orgos-column>

    <orgos-column size="1" class="kenjo-p-30px">
      <div class="kenjo-font-size-18px kenjo-mb-20px">{{i18n.page.invoices.pageTitle}}</div>

      <orgos-container aspect="card">
        <orgos-table [fullHeight]="true" *ngIf="chargebeeInvoices && chargebeeInvoices.length > 0" [data]="chargebeeInvoices" [displayedColumns]="invoicesTableDisplayedColumns">
          <orgos-table-column columnKey="company" [header]="i18n.page.invoices.company" [sortable]="true">
            <ng-container *orgosTableCell="let iChargebeeInvoice"> {{chargebeeCustomersByIdMap[iChargebeeInvoice.customer_id]?.cf_orgos_company_name}} </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="date" [header]="i18n.page.invoices.date" [sortable]="true">
            <ng-container *orgosTableCell="let iChargebeeInvoice"> {{iChargebeeInvoice.date | date:'shortDate'}} </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="status" [header]="i18n.page.invoices.status" [sortable]="true">
            <ng-container *orgosTableCell="let iChargebeeInvoice"> {{i18n.page.invoices.invoiceStatuses[iChargebeeInvoice.status]}} </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="amount" [header]="i18n.page.invoices.amount" [sortable]="true">
            <ng-container *orgosTableCell="let iChargebeeInvoice"> {{iChargebeeInvoice.total / 100 | currency:iChargebeeInvoice.currency_code}} </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="downloadCreditNote" [header]="i18n.page.invoices.creditNote">
            <ng-container *orgosTableCell="let iChargebeeInvoice">
              <ng-container *ngIf="mapInvoiceIdToCreditNoteIds[iChargebeeInvoice.id]?.length">
                <a *ngFor="let creditNoteId of mapInvoiceIdToCreditNoteIds[iChargebeeInvoice.id]" class="sbp-download kenjo-font-color-primary-5993e3" (click)="downloadCreditNote(creditNoteId)" [matTooltip]="i18n.page.invoices.downloadCreditNoteTooltip"><mat-icon>file_download</mat-icon></a>
              </ng-container>
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="download" [header]="i18n.page.invoices.invoice">
            <ng-container *orgosTableCell="let iChargebeeInvoice">
              <a class="sbp-download kenjo-font-color-primary-5993e3" (click)="downloadInvoice(iChargebeeInvoice.id)" [matTooltip]="i18n.page.invoices.downloadTooltip"><mat-icon>file_download</mat-icon></a>
            </ng-container>
          </orgos-table-column>
        </orgos-table>
      </orgos-container>

      <orgos-text *ngIf="!chargebeeInvoices || chargebeeInvoices.length === 0" color="LightText" class="kenjo-pt-20px">{{i18n.page.invoices.noInvoices}}</orgos-text>
    </orgos-column>
  </orgos-column-container>
</orgos-container>

<orgos-container aspect="card" *ngIf="!loadingPage && errorOnFetchingData && i18n.page">
  <orgos-column-container class="sbp-main-container">
    <orgos-column size="0" class="sbp-menu kenjo-p-30px kenjo-border-right">
      <orgos-settings-billing-menu [menuTranslation]="i18n.page.menu" [selectedOption]="2"></orgos-settings-billing-menu>
    </orgos-column>
    <orgos-column class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.errorPage.title}}</div>
      <div class="kenjo-mt-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.errorPage.subtitle}}</div>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
