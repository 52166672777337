import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { SearchFunction } from '@app/standard/components/search/search.component';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { TaskController } from '@app/standard/services/task/controllers/task.controller';
import { TaskHelperService } from '@app/standard/services/task/task-helper.service';
import { TaskService } from '@app/standard/services/task/task.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'kenjo-task-with-buttons',
  templateUrl: 'task-with-buttons.component.html',
  styleUrls: ['task-with-buttons.component.scss'],
})
export class TaskWithButtonsComponent implements OnInit, OnDestroy {
  i18n: any = {};
  editTitle: boolean = false;
  today: moment.Moment = moment();
  moment: any = moment;
  searchUserOverlay: OverlayRef;
  searchResults: Array<any> = [];
  allActiveUserPersonal: Array<any> = [];
  hasEditPermission: boolean = false;
  hasDeletePermission: boolean = false;

  mapUserPersonalById: any = {};
  mapCandidatesById: any = {};
  mapPositionCandidateById: any = {};
  mapMeetingsById: any = {};
  mapDocumentsById: any = {};

  workflowName: any;
  creator: any;
  assignee: any;
  relatedToEmployee: any;
  relatedToPositionCandidate: any;
  relatedToMeeting: any; // delete if only for recruiting
  relatedToDocument: any; // delete if only for recruiting
  taskIsValid: boolean = false;
  actionTaskId: string;
  loggedUser: any;
  initialStatus: any;
  initialAssignee: any;

  private keepSubscriptions: boolean = true;

  @Input() task: any;
  @Input() showRelatedTo: boolean = true;
  @Input() readOnly: boolean = false;
  @Input() readOnlyAssignee: boolean = false;
  @Input() collapseComponent: boolean = false;
  @Input() disableDeletion: boolean = false;
  @Input() hasHiringTeamTaskPermissions: boolean = false;

  @Output() readonly taskAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly taskChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly taskDeleted: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(CdkPortal) searchPortal: Portal<any>;

  constructor(private injector: Injector, private overlay: Overlay, private router: Router) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData() {
    try {
      this.i18n = await this.injector.get(InternationalizationService).getAllTranslation('task-component');
    } catch {
      this.i18n = {};
    }
    this.injector
      .get(TaskHelperService)
      .getTaskPermissions()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((taskPermissions: any) => {
        this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
        this.hasEditPermission =
          (taskPermissions.edit_all === true ||
            (taskPermissions.edit_own === true && this.task.ownerId === this.loggedUser._id) ||
            this.hasHiringTeamTaskPermissions === true) &&
          this.readOnly === false;
        this.hasDeletePermission =
          (taskPermissions.delete_all === true ||
            (taskPermissions.delete_own === true && this.task.ownerId === this.loggedUser._id) ||
            this.hasHiringTeamTaskPermissions === true) &&
          this.readOnly === false;
        this.readOnlyAssignee =
          taskPermissions.edit_own === true && taskPermissions.edit_all === false && taskPermissions.edit_custom === false;
      });

    this.injector
      .get(TaskHelperService)
      .getMapUserPersonalById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapUserPersonalById: any) => {
        this.mapUserPersonalById = mapUserPersonalById;
        this.refreshData();
      });

    this.injector
      .get(TaskHelperService)
      .getAllActiveUserPersonal()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((allActiveUserPersonal: Array<any>) => {
        this.allActiveUserPersonal = allActiveUserPersonal;
      });

    this.injector
      .get(TaskHelperService)
      .getMapCandidatesById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapCandidatesById: any) => {
        this.mapCandidatesById = mapCandidatesById;
      });

    this.injector
      .get(TaskHelperService)
      .getMapPositionCandidateById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapPositionCandidateById: any) => {
        this.mapPositionCandidateById = mapPositionCandidateById;
        this.refreshData();
      });

    this.injector
      .get(TaskHelperService)
      .getMapMeetingsById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapMeetingsById: any) => {
        this.mapMeetingsById = mapMeetingsById;
        this.refreshData();
      });

    this.injector
      .get(TaskHelperService)
      .getMapDocumentsById()
      .pipe(
        takeWhile(() => this.keepSubscriptions),
        distinctUntilChanged((dataA: any, dataB: any) => {
          return _.isEqual(dataA, dataB);
        })
      )
      .subscribe((mapDocumentsById: any) => {
        this.mapDocumentsById = mapDocumentsById;
        this.refreshData();
      });

    this.checkValidateButton();
  }

  ngOnDestroy(): void {
    this.keepSubscriptions = false;
  }

  searchAssignee(assigneeElement: ElementRef): void {
    if (this.hasEditPermission === false) {
      return;
    }

    const searchUserOverlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
    };
    searchUserOverlayConfig.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(assigneeElement)
      .withPositions([
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
        { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' },
      ])
      .withPush(false);

    this.searchUserOverlay = this.overlay.create(searchUserOverlayConfig);
    this.searchUserOverlay.attach(this.searchPortal);
    this.searchUserOverlay.backdropClick().subscribe(() => {
      this.searchUserOverlay.dispose();
    });
  }

  selectAssignee(newAssignee: any): void {
    this.changeTask('ownerId', newAssignee._id);
    this.searchUserOverlay.dispose();
    this.refreshData();
  }

  public searchAssigneeFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      return Promise.resolve(this.allActiveUserPersonal);
    }

    const results = this.allActiveUserPersonal.filter((iUserPersonal: any) => {
      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return regExp.test(iUserPersonal.displayName);
    });

    return Promise.resolve(results);
  };

  changeTask(field: string, newValue: any): void {
    if (this.hasEditPermission === false && this.hasHiringTeamTaskPermissions === false && field !== 'isCompleted') {
      return;
    }

    this.task[field] = newValue;
    if (field === 'isCompleted') {
      this.updateTask();
      this.taskChanged.emit(this.task);
    }
    this.checkValidateButton();
  }

  async changeTaskStatus(newStatus: boolean): Promise<void> {
    await this.injector.get(TaskService).updateStatus(this.task, newStatus);

    this.task['isCompleted'] = newStatus;
    this.refreshData();
    this.injector.get(MatLegacySnackBar).open(this.i18n.taskUpdatedSnackText, 'OK', {
      duration: 5000,
    });
  }

  async onDeleteTask(): Promise<void> {
    const getTaskDeleteTranslations = this.injector.get(InternationalizationService).getAllTranslation('task-delete-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

    const [taskDeleteTranslation, globalMiscTranslation] = await Promise.all([getTaskDeleteTranslations, getGlobalMiscTranslations]);
    const data = {
      titleText: taskDeleteTranslation.dialogHeader,
      subtitleText: taskDeleteTranslation.warningMessageText,
      cancelButtonText: globalMiscTranslation.goBackButtonDialog,
      confirmButtonText: taskDeleteTranslation.deleteButtonLabel,
      confirmButtonColor: 'Danger',
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((deleteTask: boolean) => {
      if (check.not.assigned(deleteTask) || deleteTask === false) {
        return;
      }
      this.deleteTask();
    });
  }

  onEditTask() {
    this.actionTaskId = this.task._id;
    this.initialStatus = Object.assign({}, this.task);
    this.initialAssignee = Object.assign({}, this.assignee);
  }

  async updateTask(): Promise<void> {
    if (check.not.assigned(this.task)) {
      return;
    }
    await this.injector.get(TaskController).updateTaskForCandidate(this.task._id, this.task);
    this.refreshData();
    this.injector.get(MatLegacySnackBar).open(this.i18n.taskUpdatedSnackText, 'OK', {
      duration: 5000,
    });
    this.actionTaskId = '';
  }

  private async deleteTask(): Promise<void> {
    await this.injector.get(TaskController).deleteTaskForCandidate(this.task._id);
    this.task = null;
    this.taskDeleted.emit();
    this.injector.get(MatLegacySnackBar).open(this.i18n.taskDeletedSnackText, 'OK', {
      duration: 5000,
    });
  }

  private refreshData(): void {
    if (!this.task) {
      return;
    }

    this.workflowName = null;
    this.creator = null;
    this.assignee = null;
    this.relatedToEmployee = null;
    this.relatedToPositionCandidate = null;
    this.relatedToMeeting = null;
    this.relatedToDocument = null;

    if (this.task.workflowName) {
      this.workflowName = this.task.workflowName;
    } else {
      this.creator = this.mapUserPersonalById[this.task._createdById];
    }

    this.assignee = this.task.ownerId ? this.mapUserPersonalById[this.task.ownerId] : this.mapUserPersonalById[this.task._createdById];

    this.relatedToEmployee = this.mapUserPersonalById[this.task.relatedTo];
    this.relatedToPositionCandidate = this.mapPositionCandidateById[this.task.relatedTo];
    this.relatedToMeeting = this.mapMeetingsById[this.task.relatedTo];
    this.relatedToDocument = this.mapDocumentsById[this.task.relatedTo];
  }

  public checkValidateButton() {
    if (check.assigned(this.task.title) && check.not.emptyString(this.task.title) && check.assigned(this.task.dueDate)) {
      this.taskIsValid = true;
    } else {
      this.taskIsValid = false;
    }
  }

  public async addTask(task) {
    const createdTask = await this.injector.get(TaskController).createTaskForCandidate(task);

    this.task = createdTask;
    this.injector.get(MatLegacySnackBar).open(this.i18n.taskCreatedSnackText, 'OK', {
      duration: 5000,
    });
    this.actionTaskId = '';
    this.taskAdd.emit(createdTask);
  }

  public cancelTask() {
    this.actionTaskId = '';
    if (check.not.assigned(this.task._id)) {
      this.taskDeleted.emit();
    } else {
      this.task = this.initialStatus;
      this.assignee = this.initialAssignee;
    }
  }

  public navigateToPositionCandidate(): void {
    const positionCandidatePageURL = check.assigned(this.relatedToPositionCandidate)
      ? `cloud/recruiting/candidate/${this.relatedToPositionCandidate._candidateId}/${this.relatedToPositionCandidate._positionId}`
      : '';
    this.router.navigateByUrl(positionCandidatePageURL);
  }

  public navigateToMeeting(): void {
    const meetingPageURL = check.assigned(this.relatedToMeeting) ? `cloud/meetings/${this.relatedToMeeting._id}` : '';
    this.router.navigateByUrl(meetingPageURL);
  }

  public navigateToDocument(): void {
    const documentPageURL = check.assigned(this.relatedToDocument) ? `cloud/documents/${this.relatedToDocument._id}` : '';
    this.router.navigateByUrl(documentPageURL);
  }
}
