import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

import { InputValidation } from '../../../core/validation/input-validation';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { PreferenceService } from '../../../services/preference/preference.service';

@Component({
  selector: 'orgos-create-list-view-dialog',
  templateUrl: 'create-list-view.dialog.html',
  styleUrls: ['create-list-view.dialog.scss'],
})
export class CreateListViewDialog implements OnInit {
  translation: any = {};
  nameValidation: InputValidation;
  viewName: string;
  viewInfo: any;
  views: object = {};

  constructor(
    public dialogRef: MatLegacyDialogRef<CreateListViewDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.viewInfo = this.data.viewInfo;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('list-view-component')
      .then((dialogTranslation) => {
        this.translation = dialogTranslation;
        this.viewName = check.not.equal(this.viewName, this.translation.allViewsLabel) ? this.viewName : null;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(PreferenceService)
      .getPreferenceByKey(this.viewInfo.viewType)
      .then((views) => {
        this.views = check.assigned(views) && check.assigned(views.preference) ? views.preference : {};
      })
      .catch(() => {
        this.views = {};
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveView(): void {
    if (!this.isDataValid()) {
      return;
    }
    this.views[this.viewName] = this.viewInfo.filters;

    this.injector
      .get(PreferenceService)
      .setPreferenceByKey(this.viewInfo.viewType, this.views, picklists.PREFERENCE_TYPE_VIEW)
      .then(() => {
        this.dialogRef.close(this.viewName);
      })
      .catch(() => {});
  }

  private isDataValid(): boolean {
    return (
      check.assigned(this.nameValidation) &&
      !this.nameValidation.hasErrors() &&
      check.not.equal(this.viewName, this.translation.allViewsLabel)
    );
  }
}
