import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';

@Component({
  selector: 'orgos-dialog-container',
  templateUrl: 'dialog-container.component.html',
  styleUrls: ['dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogContainerComponent {
  MAX_EMPLOYEES = 5;
  employeesTooltip: string;

  @Input() title: string = '';
  @Input() disabledCloseButton: boolean = false;

  _employees: Array<{ displayName: string; _photo: IFileMetadata }>;
  @Input() set employees(employees: Array<{ displayName: string; _photo: IFileMetadata }>) {
    if (!employees) {
      return;
    }

    this._employees = employees;

    if (this._employees.length > this.MAX_EMPLOYEES) {
      this.employeesTooltip = this._employees
        .slice(this.MAX_EMPLOYEES)
        .map((iEmployee) => iEmployee.displayName)
        .join(', ');
    }
  }

  @Input() icon: string;

  get employees() {
    return this._employees;
  }

  @Output() clickCloseButton: EventEmitter<void> = new EventEmitter<void>();
}
