import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUserAccountModel } from '@app/models/user-account.model';

@Component({
  selector: 'orgos-user-item',
  templateUrl: 'user-item.component.html',
  styleUrls: ['user-item.component.scss']
})
export class UserItemComponent {
  componentSize: string = 'medium';

  @Input() title: string;
  @Input() subtitle: string;
  @Input() actionText: string;
  @Input() extraInfo: string;
  @Input() displayName: string;
  @Input() photoUrl: string;
  @Input() color: string = 'NormalText';
  @Input() userAccountSatus: IUserAccountModel;
  @Input() invitationStatus: IUserAccountModel;
  @Input()
  set size(size: 'large' | 'medium' | 'small' | 'tiny') {
    // large, medium, small
    this.componentSize = size;
  }

  @Input() enableChangePhoto: boolean = false;
  @Output() changePhoto: EventEmitter<void> = new EventEmitter<void>();
  @Input() width: string = null;
  @Input() maxWidth: string = null;
}
