<orgos-container aspect="card" *ngIf="!loadingPage && !errorOnFetchingData">
  <orgos-column-container class="sbp-main-container">
    <orgos-column size="0" class="sbp-menu kenjo-p-30px kenjo-border-right">
      <orgos-settings-billing-menu [menuTranslation]="i18n.page.menu" [selectedOption]="4"></orgos-settings-billing-menu>
    </orgos-column>
    <orgos-column class="kenjo-ph-30px">
      <div class="kenjo-pv-30px kenjo-flex-vertical kenjo-flex-gap-30px">
        <div class="kenjo-flex-horizontal kenjo-flex-gap-70px">
          <div class="kenjo-flex-vertical kenjo-flex-gap-20px">
            <div class="kenjo-flex-vertical kenjo-flex-gap-20px">
              <div class="kenjo-font-size-30px">{{ i18n.page.platformUsage.platformUsageTitle }}</div>
              <div class="kenjo-flex-vertical kenjo-flex-gap-8px kenjo-font-color-light-text-757575">
                <div>{{ i18n.page.platformUsage.paragraph1 }}</div>
                <div>{{ i18n.page.platformUsage.paragraph2 }}</div>
              </div>
            </div>
            <kenjo-info-message *ngIf="isDatasetEmpty === true" [messageType]="'primary'" [contentProjection]="true"><span class="kenjo-font-weight-bold">{{ i18n.page.platformUsage.emptyDatasetBoldSentence }}</span> {{ i18n.page.platformUsage.emptyDatasetNormalSentence }}</kenjo-info-message>
          </div>
          <div class="kenjo-p-20px kenjo-color-grey-background-lighter-f8f8f8 kenjo-flex-vertical kenjo-flex-gap-20px kenjo-flex-align-end">
            <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
              <div class="kenjo-font-weight-bold">{{ i18n.page.platformUsage.increaseUsage }}</div>
              <div class="kenjo-text-align-left kenjo-text-nowrap">
                <div class="kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-flex-gap-5px">
                  <kenjo-icon [size]="18" class="kenjo-font-color-primary-5993e3">done</kenjo-icon>
                  <div class="kenjo-font-color-light-text-757575">{{ i18n.page.platformUsage.talkOurTeam }}</div>
                </div>
                <div class="kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-flex-gap-5px">
                  <kenjo-icon [size]="18" class="kenjo-font-color-primary-5993e3">done</kenjo-icon>
                  <div class="kenjo-font-color-light-text-757575">{{ i18n.page.platformUsage.understandFeatures }}</div>
                </div>
                <div class="kenjo-flex-horizontal kenjo-flex-center-vertically kenjo-flex-gap-5px">
                  <kenjo-icon [size]="18" class="kenjo-font-color-primary-5993e3">done</kenjo-icon>
                  <div class="kenjo-font-color-light-text-757575">{{ i18n.page.platformUsage.getDigital }}</div>
                </div>
              </div>
            </div>
            <div *ngIf="showLinkIfStatus.includes((subscriptionService.subscription$ | async)?.status)">
              <kenjo-button-flat (click)="scheduleACallButtonClick()">{{ i18n.page.platformUsage.scheduleCallButton }}</kenjo-button-flat>
            </div>
            <div></div>
          </div>
        </div>
        <div>
          <div class="kenjo-secondary-menu">
            <mat-tab-group class="sbpu-tabs" [disableRipple]="true" [selectedIndex]="selectedTab" (selectedIndexChange)="onTabChange($event)">
              <!-- matTabContent makes them lazy load for best performance -->
              <mat-tab [label]="i18n.page.platformUsage.generalTab">
                <ng-template matTabContent>
                  <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
                    <orgos-action class="kenjo-flex-align-self-end kenjo-mr-20px" (click)="createPDF('generalDashboardInstance')" icon="file_download" [disabled]="service.preparingExport || isOpenDashboardFeatureEnabled === false || isDatasetEmpty">{{ i18n.page.platformUsage.download }}</orgos-action>
                    <div *ngIf="cumulAuth.id">
                      <cumulio-dashboard
                        #generalDashboardInstance
                        id="chart-general"
                        [dashboardId]="dashboards.generalDashboardId"
                        [authKey]="cumulAuth.id"
                        [authToken]="cumulAuth.token"
                        [language]="loggedUser.language"
                        [qeVersion]="qeVersion"
                        [language]="dashboards.language"
                      ></cumulio-dashboard>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab [label]="i18n.page.platformUsage.attendanceTab">
                <ng-template matTabContent>
                  <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
                    <orgos-action class="kenjo-flex-align-self-end kenjo-mr-20px" *ngIf="true" (click)="createPDF('attendanceDashboardInstance')" icon="file_download" [disabled]="service.preparingExport || isOpenDashboardFeatureEnabled === false || isDatasetEmpty">{{ i18n.page.platformUsage.download }}</orgos-action>
                    <div *ngIf="cumulAuth.id">
                      <cumulio-dashboard
                        #attendanceDashboardInstance
                        [dashboardId]="dashboards.attendanceDashboardId"
                        [authKey]="cumulAuth.id"
                        [authToken]="cumulAuth.token"
                        [language]="loggedUser.language"
                        [qeVersion]="qeVersion"
                        [language]="dashboards.language"
                      ></cumulio-dashboard>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab [label]="i18n.page.platformUsage.timeoffTab">
                <ng-template matTabContent>
                  <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
                    <orgos-action class="kenjo-flex-align-self-end kenjo-mr-20px" *ngIf="true" (click)="createPDF('timeoffDashboardInstance')" icon="file_download" [disabled]="service.preparingExport || isOpenDashboardFeatureEnabled === false || isDatasetEmpty">{{ i18n.page.platformUsage.download }}</orgos-action>
                    <div *ngIf="cumulAuth.id">
                      <cumulio-dashboard
                        #timeoffDashboardInstance
                        [dashboardId]="dashboards.timeoffDashboardId"
                        [authKey]="cumulAuth.id"
                        [authToken]="cumulAuth.token"
                        [language]="loggedUser.language"
                        [qeVersion]="qeVersion"
                        [language]="dashboards.language"
                      ></cumulio-dashboard>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab [label]="i18n.page.platformUsage.shiftplanTab">
                <ng-template matTabContent>
                  <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
                    <orgos-action class="kenjo-flex-align-self-end kenjo-mr-20px" *ngIf="true" (click)="createPDF('shiftplanDashboardInstance')" icon="file_download" [disabled]="service.preparingExport || isOpenDashboardFeatureEnabled === false || isDatasetEmpty">{{ i18n.page.platformUsage.download }}</orgos-action>
                    <div *ngIf="cumulAuth.id">
                      <cumulio-dashboard
                        #shiftplanDashboardInstance
                        [dashboardId]="dashboards.shiftplanDashboardId"
                        [authKey]="cumulAuth.id"
                        [authToken]="cumulAuth.token"
                        [language]="loggedUser.language"
                        [qeVersion]="qeVersion"
                        [language]="dashboards.language"
                      ></cumulio-dashboard>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab [label]="i18n.page.platformUsage.payrollTab">
                <ng-template matTabContent>
                  <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
                    <orgos-action class="kenjo-flex-align-self-end kenjo-mr-20px" *ngIf="true" (click)="createPDF('payrollDashboardInstance')" icon="file_download" [disabled]="service.preparingExport || isOpenDashboardFeatureEnabled === false || isDatasetEmpty">{{ i18n.page.platformUsage.download }}</orgos-action>
                    <div *ngIf="cumulAuth.id">
                      <cumulio-dashboard
                        #payrollDashboardInstance
                        [dashboardId]="dashboards.payrollDashboardId"
                        [authKey]="cumulAuth.id"
                        [authToken]="cumulAuth.token"
                        [language]="loggedUser.language"
                        [qeVersion]="qeVersion"
                        [language]="dashboards.language"
                      ></cumulio-dashboard>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab [label]="i18n.page.platformUsage.workflowsTab">
                <ng-template matTabContent>
                  <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
                    <orgos-action class="kenjo-flex-align-self-end kenjo-mr-20px" *ngIf="true" (click)="createPDF('worlkflowsDashboardInstance')" icon="file_download" [disabled]="service.preparingExport || isOpenDashboardFeatureEnabled === false || isDatasetEmpty">{{ i18n.page.platformUsage.download }}</orgos-action>
                    <div *ngIf="cumulAuth.id">
                      <cumulio-dashboard
                        #worlkflowsDashboardInstance
                        [dashboardId]="dashboards.workflowsDashboardId"
                        [authKey]="cumulAuth.id"
                        [authToken]="cumulAuth.token"
                        [language]="loggedUser.language"
                        [qeVersion]="qeVersion"
                        [language]="dashboards.language"
                      ></cumulio-dashboard>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab [label]="i18n.page.platformUsage.othersTab">
                <ng-template matTabContent>
                  <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
                    <orgos-action class="kenjo-flex-align-self-end kenjo-mr-20px" *ngIf="true" (click)="createPDF('othersDashboardInstance')" icon="file_download" [disabled]="service.preparingExport || isOpenDashboardFeatureEnabled === false || isDatasetEmpty">{{ i18n.page.platformUsage.download }}</orgos-action>
                    <div *ngIf="cumulAuth.id">
                      <cumulio-dashboard
                        #othersDashboardInstance
                        [dashboardId]="dashboards.othersDashboardId"
                        [authKey]="cumulAuth.id"
                        [authToken]="cumulAuth.token"
                        [language]="loggedUser.language"
                        [qeVersion]="qeVersion"
                        [language]="dashboards.language"
                      ></cumulio-dashboard>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </orgos-column>
  </orgos-column-container>
</orgos-container>

<orgos-container aspect="card" *ngIf="!loadingPage && errorOnFetchingData && i18n.page">
  <orgos-column-container class="sbp-main-container">
    <orgos-column size="0" class="sbp-menu kenjo-p-30px kenjo-border-right">
      <orgos-settings-billing-menu [menuTranslation]="i18n.page.menu" [selectedOption]="2"></orgos-settings-billing-menu>
    </orgos-column>
    <orgos-column class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.errorPage.title}}</div>
      <div class="kenjo-mt-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.errorPage.subtitle}}</div>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
