import { Injectable, Injector, OnDestroy } from '@angular/core';
import { IUserAccountModel } from '@app/models/user-account.model';
import { ISubscription } from '@app/private/services/private-organization.service';
import { PrivateSubscriptionService } from '@app/private/services/private-subscription.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class InAppInviteService {
  private _primaryMenuButtonName: string = '';
  public get primaryMenuButtonName(): string {
    return this._primaryMenuButtonName;
  }

  // multiple app components might need to read this property at different times,
  // so we provide an observable that they can subscribe to
  public get isInAppFeatureAvailable(): Observable<boolean> {
    const userProfile: IUserAccountModel = this.injector.get(AuthenticationService).getLoggedUser();
    return this.injector.get(PrivateSubscriptionService).subscription$.pipe(
      map((subscription: ISubscription) => {
        return subscription?.status === picklists.SUBSCRIPTION_IN_TRIAL && userProfile.profileKey === 'admin';
      })
    );
  }

  constructor(private injector: Injector) {}

  // this initialization is required on app start, in order to know if user has access to the invite dialog. it is loaded by the cloud component when it loads the side menu
  public async setInviteButtonTranslation(buttonTranslation: string): Promise<void> {
    this._primaryMenuButtonName = buttonTranslation;
  }
}
