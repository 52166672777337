import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { FreebieDataGeneratorService } from '@app/standard/services/freebie-data-generator/freebie-data-generator.service';

@Component({
  templateUrl: 'delete-dummy-data.dialog.html',
  styleUrls: ['delete-dummy-data.dialog.scss'],
})
export class DeleteDummyDataDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};
  deleteInProgress: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<DeleteDummyDataDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-overview-page')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  closeDialog(): void {
    if (this.deleteInProgress) {
      // Do not allow to delete this dialog if delete is in progress
      return;
    }
    this.dialogRef.close();
  }

  performDeleteOfDummyData(): void {
    if (this.injector.get(AuthenticationService).getLoggedUser().profileKey !== 'admin') {
      return;
    }

    this.deleteInProgress = true;
    this.dialogRef.disableClose = true; // Disable close because we want the user to stay on this screen
    this.injector
      .get(FreebieDataGeneratorService)
      .clearDummyData()
      .then(() => {
        this.deleteInProgress = false;
        this.injector.get(MatLegacySnackBar).open(this.dialogTranslation.dummyDataDeletedConfirmSnackbar, 'OK', { duration: 5000 });
        this.dialogRef.close(true);
      })
      .catch(() => {
        // Error will be displayed on the screen
        this.deleteInProgress = false;
      });
  }
}
