import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { IShiftPlanTemplate } from '@app/cloud-features/shift-plan/services/shift-plan-template.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-shift-plan-template',
  templateUrl: 'shift-plan-template.component.html',
  styleUrls: ['shift-plan-template.component.scss'],
})
export class ShiftPlanTemplateComponent implements OnInit {
  editDisabledPermission: boolean = false;
  cardColor: string;
  editTooltip: string = '';

  shiftplanPermissions: IShiftPlanPermissions;

  @Input() template: IShiftPlanTemplate;
  @Input() pageTranslation: { [key: string]: string } = {};
  @Input() showTemplateDetails: boolean;
  @Input() selectedTemplateId: string;
  @Input() loading: boolean;

  @Output() openAddShiftDialogEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() editTemplateEvent: EventEmitter<IShiftPlanTemplate> = new EventEmitter<IShiftPlanTemplate>();
  @Output() draggingEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private injector: Injector) {}
  ngOnInit() {
    this.getPermissions();
    this.calculateColor();
  }

  private calculateColor() {
    this.cardColor = userColorConstants[this.template.roleColor];
  }

  onDrag(event: boolean) {
    this.draggingEventEmitter.emit(event);
  }
  openAddShiftDialog() {
    if (this.loading) {
      return;
    }
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('add shift from template intent', { category: 'Shiftplan', subcategory: 'templates', subcategory2: 'using templates' });
    this.openAddShiftDialogEvent.emit();
  }

  editTemplate() {
    if (this.loading) {
      return;
    }
    this.editTemplateEvent.emit(this.template);
  }

  setTemplatePermissions(): boolean {
    const currentUser = this.injector.get(AuthenticationService).getLoggedUser();

    if (this.shiftplanPermissions['templates'].edit_all === true) {
      return false;
    }

    if (this.shiftplanPermissions['templates'].edit_own === true) {
      return this.template.ownerId !== currentUser._id;
    }
    return true;
  }

  async getPermissions(): Promise<void> {
    this.shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
    this.editDisabledPermission = this.setTemplatePermissions();
    this.editTooltip = this.editDisabledPermission ? this.pageTranslation.editDisabled : this.pageTranslation.edit;
  }
}
