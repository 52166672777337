<orgos-container
  *ngIf="activateShiftPlanTranslation && peopleDetailPageTranslation"
  class="kenjo-pb-60px kenjo-ph-40px kenjo-color-grey-background-f5f5f5 kenjo-border-radius"
>
  <orgos-column-container [centerColumns]="true" class="asp-header">
    <div orgosColumn="0">
      <div *ngIf="activateShiftPlanStatus !== ACTIVATION_STATUS_FINISHED" orgosColumn class="kenjo-font-size-18px">
        {{ activateShiftPlanTranslation.activateShiftPlan }}
      </div>
      <div *ngIf="activateShiftPlanStatus === ACTIVATION_STATUS_FINISHED" orgosColumn class="kenjo-font-size-18px">
        {{ activateShiftPlanTranslation.editShiftPlan }}
        <mat-slide-toggle
          class="kenjo-font-size-12px kenjo-ml-15px"
          *ngIf="activateShiftPlanStatus === ACTIVATION_STATUS_FINISHED"
          [checked]="userWork.data.shiftPlanActive"
          (change)="changeShiftPlanStatus($event.checked)"
          [disabled]="!locationStepCompleted || !rolesStepCompleted || !workingAreasStepCompleted"
        >
          {{ activateShiftPlanTranslation.activationToggle }}
        </mat-slide-toggle>
      </div>
    </div>
    <div orgosColumn="0">
      <div class="kenjo-ml-15px asp-employee">
        <orgos-avatar
          orgosColumn="0"
          [avatarName]="userPersonal?.data?.displayName"
          [photoUrl]="userPersonal?.data?._photo?._url"
          size="tiny"
        ></orgos-avatar>
        <div class="asp-truncate-text kenjo-ml-10px" [matTooltip]="userPersonal?.data?.displayName" [style.max-width]="'250px'">
          {{ userPersonal?.data?.displayName }}
        </div>
      </div>
    </div>
    <orgos-column></orgos-column>
    <kenjo-icon orgosColumn="0" class="kenjo-text-align-right kenjo-cursor-pointer" (click)="closeDialog()">clear</kenjo-icon>
  </orgos-column-container>
  <orgos-column-container div [style.height]="'600px'">
    <orgos-column *ngIf="activateShiftPlanStatus !== ACTIVATION_STATUS_FINISHED" size="0" class="kenjo-color-grey-background-f5f5f5">
      <ol class="list-group vertical-steps kenjo-mt-40px kenjo-mr-30px">
        <li class="list-group-item kenjo-pb-40px kenjo-pl-20px" [class.completed]="progressIndex >= 0">
          <div class="kenjo-font-size-14px" [style.font-weight]="progressIndex === 0 ? 'bold' : ''">
            {{ activateShiftPlanTranslation.locationsStep }}
          </div>
        </li>
        <li class="list-group-item kenjo-pb-40px kenjo-pl-20px" [class.completed]="progressIndex >= 1">
          <div
            class="kenjo-font-size-14px"
            [style.border-right]="progressIndex === 1 ? 'none' : '4px solid transparent'"
            [style.font-weight]="progressIndex === 1 ? 'bold' : ''"
          >
            {{ activateShiftPlanTranslation.workingAreasStep }}
          </div>
        </li>
        <li class="list-group-item kenjo-pb-40px kenjo-pl-20px" [class.completed]="progressIndex >= 2">
          <div class="kenjo-font-size-14px" [style.font-weight]="progressIndex === 2 ? 'bold' : ''">
            {{ activateShiftPlanTranslation.rolesStep }}
          </div>
        </li>
        <li class="list-group-item kenjo-pb-40px kenjo-pl-20px" [class.completed]="progressIndex >= 3">
          <div class="kenjo-font-size-14px" [style.font-weight]="progressIndex === 3 ? 'bold' : ''">
            {{ activateShiftPlanTranslation.tagsStep }}
          </div>
        </li>
      </ol>
    </orgos-column>
    <orgos-column
      *ngIf="activateShiftPlanStatus === ACTIVATION_STATUS_FINISHED"
      class="asp-menu-column-menu kenjo-border-right kenjo-color-white-background-ffffff"
      size="0"
    >
      <ol class="kenjo-mt-40px kenjo-cursor-pointer">
        <li class="kenjo-pb-20px kenjo-pl-10px" (click)="navigateToOtherSteps(0)">
          <div class="kenjo-font-size-14px" [class.kenjo-font-color-primary-5993e3]="progressIndex === 0">
            {{ activateShiftPlanTranslation.locationsStep }}
          </div>
        </li>
        <li class="kenjo-pb-20px kenjo-pl-10px" (click)="navigateToOtherSteps(1)">
          <div class="kenjo-font-size-14px" [class.kenjo-font-color-primary-5993e3]="progressIndex === 1">
            {{ activateShiftPlanTranslation.workingAreasStep }}
          </div>
        </li>
        <li class="kenjo-pb-20px kenjo-pl-10px" (click)="navigateToOtherSteps(2)">
          <div class="kenjo-font-size-14px" [class.kenjo-font-color-primary-5993e3]="progressIndex === 2">
            {{ activateShiftPlanTranslation.rolesStep }}
          </div>
        </li>
        <li class="kenjo-pb-20px kenjo-pl-10px" (click)="navigateToOtherSteps(3)">
          <div class="kenjo-font-size-14px" [class.kenjo-font-color-primary-5993e3]="progressIndex === 3">
            {{ activateShiftPlanTranslation.tagsStep }}
          </div>
        </li>
      </ol>
    </orgos-column>

    <!-- Locations -->
    <orgos-column
      *ngIf="progressIndex === 0"
      size="0"
      class="asp-main-content"
      [class.asp-border-left-radius]="activateShiftPlanStatus !== ACTIVATION_STATUS_FINISHED"
    >
      <kenjo-shift-plan-employee-locations
        [progressIndex]="progressIndex"
        [activateShiftPlanTranslation]="activateShiftPlanTranslation"
        [shiftPlanSettingsTranslations]="shiftPlanSettingsTranslations"
        [userWork]="userWork"
        (changeIndex)="changeIndex($event)"
        (locationsIsCompleted)="locationStepCompleted = $event"
        (dataSaved)="dataSaved = $event"
      ></kenjo-shift-plan-employee-locations>
    </orgos-column>

    <!-- Working Areas -->
    <orgos-column
      *ngIf="progressIndex === 1"
      size="0"
      class="asp-main-content"
      [class.asp-border-left-radius]="activateShiftPlanStatus !== ACTIVATION_STATUS_FINISHED"
    >
      <kenjo-shift-plan-employee-working-areas
        [progressIndex]="progressIndex"
        [activateShiftPlanTranslation]="activateShiftPlanTranslation"
        [shiftPlanSettingsTranslations]="shiftPlanSettingsTranslations"
        [userWork]="userWork"
        (changeIndex)="changeIndex($event)"
        (workingAreasIsCompleted)="workingAreasStepCompleted = $event"
        (dataSaved)="dataSaved = $event"
      ></kenjo-shift-plan-employee-working-areas>
    </orgos-column>

    <!-- Roles -->
    <orgos-column
      *ngIf="progressIndex === 2"
      size="0"
      class="asp-main-content"
      [class.asp-border-left-radius]="activateShiftPlanStatus !== ACTIVATION_STATUS_FINISHED"
    >
      <kenjo-shift-plan-employee-roles
        [progressIndex]="progressIndex"
        [activateShiftPlanTranslation]="activateShiftPlanTranslation"
        [shiftPlanSettingsTranslations]="shiftPlanSettingsTranslations"
        [userWork]="userWork"
        (changeIndex)="changeIndex($event)"
        (rolesIsCompleted)="rolesStepCompleted = $event"
        (dataSaved)="dataSaved = $event"
      ></kenjo-shift-plan-employee-roles>
    </orgos-column>

    <!-- Tags -->
    <orgos-column
      *ngIf="progressIndex === 3"
      size="0"
      class="asp-main-content"
      [class.asp-border-left-radius]="activateShiftPlanStatus !== ACTIVATION_STATUS_FINISHED"
    >
      <kenjo-shift-plan-employee-tags
        [progressIndex]="progressIndex"
        [activateShiftPlanTranslation]="activateShiftPlanTranslation"
        [userWork]="userWork"
        (changeIndex)="changeIndex($event)"
        (activation)="activateShiftPlan(true)"
        (dataSaved)="dataSaved = $event"
      ></kenjo-shift-plan-employee-tags>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
