<div (click)="onClick($event)">
  <div class="stss-filters kenjo-pv-10px kenjo-ph-15px">
    <span class="kenjo-block">{{ templateTranslations.matchScheduleFilters }}</span>
    <orgos-column-container [centerColumns]="true">
      <orgos-column>
        <span class="apply-schedule">
          {{ templateTranslations.applyScheduleFilters }}
        </span>
      </orgos-column>
      <orgos-column size="0">
        <mat-slide-toggle
          [disabled]="isSorting"
          [(ngModel)]="applyPageFilters"
          (change)="changeApplyFilters()"
        ></mat-slide-toggle>
      </orgos-column>
    </orgos-column-container>
  </div>
  <div class="stss-divider kenjo-mv-5px"></div>
  <div class="stss-sort-by kenjo-pv-10px">
    <div class="kenjo-font-color-light-text-757575 kenjo-font-weight-bold kenjo-mb-5px kenjo-ph-15px">
      {{ templateTranslations.ordering | uppercase }}
    </div>
    <mat-radio-group class="radio-group">
      <mat-radio-button
        class="kenjo-font-size-14px kenjo-ph-15px kenjo-pv-10px"
        (change)="changeSortBy($event.value)"
        *ngFor="let option of sortByOptions"
        [value]="option.value"
        [checked]="option.value === sortPreference.sortBy"
        [disabled]="isSorting || !canBeSorted"
      >
        {{ option.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
