<ng-container *ngIf="translations">
  <orgos-column-container [centerColumns]="true">
    <ng-content orgosColumn="1"></ng-content>
    <orgos-input-simple-time
      orgosColumn="0"
      class="kenjo-mr-20px"
      [(value)]="hourlyRange.startTime"
      (valueChange)="validateRanges('start')"
      label="{{ translations.startTimeLabel }} *"
      [enableClearButton]="true"
      [forceError]="(error && error.startTimeError) || displayStartError"
      [readOnly]="readOnlyStart"
      [debounceNewValues]="debounceNewValues"
      (focusout)="checkEmptyInputs('start')"
      (blurTimeInput)="startTimeTouched = true"
      [ngClass]="{ 'ishr-medium-input': inputSize === 'medium', 'ishr-long-input': inputSize === 'long' }"
    >
    </orgos-input-simple-time>
    <kenjo-icon *ngIf="!displayStart" orgosColumn="1" [size]="18" class="kenjo-font-color-light-text-757575 ishr-arrow-icon">
      arrow_right_alt</kenjo-icon
    >
    <orgos-input-simple-time
      *ngIf="!displayStart"
      class="kenjo-ml-20px"
      orgosColumn="0"
      [(value)]="hourlyRange.endTime"
      (valueChange)="validateRanges('end')"
      label="{{ translations.endTimeLabel }} *"
      [enableClearButton]="true"
      [forceError]="(error && error.endTimeError) || displayEndError"
      [readOnly]="readOnlyEnd"
      [debounceNewValues]="debounceNewValues"
      (focusout)="checkEmptyInputs('end')"
      (blurTimeInput)="endTimeTouched = true"
      [ngClass]="{ 'ishr-medium-input': inputSize === 'medium', 'ishr-long-input': inputSize === 'long' }"
    >
    </orgos-input-simple-time>
  </orgos-column-container>
  <orgos-column-container *ngIf="error">
    <orgos-column
      size="0"
      class="ishr-error-message kenjo-font-size-12px kenjo-font-color-danger-ff5757"
      [ngClass]="{
        'ishr-medium-error': inputSize === 'medium' && error.endTimeErrorMessage,
        'ishr-long-error': inputSize === 'long' && error.endTimeErrorMessage
      }"
    >
      <div *ngIf="error.startTimeErrorMessage">
        {{ error.startTimeErrorMessage }}
      </div>
    </orgos-column>
    <orgos-column size="0" class="ishr-error-message kenjo-font-size-12px kenjo-font-color-danger-ff5757">
      <div *ngIf="!error.startTimeErrorMessage && error.endTimeErrorMessage">
        {{ error.endTimeErrorMessage }}
      </div>
    </orgos-column>
  </orgos-column-container>
</ng-container>
