import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { DATEV_INTERFACE_LODAS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root',
})
export class DatevApiController {
  private DATEV_API_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/datev/api`;

  constructor(private injector: Injector, @Inject(DOCUMENT) private document: any) {}

  async getEndpoints() {
    try {
      return await this.injector.get(HttpClient).get<any>(`${this.DATEV_API_CONTROLLER_URL}/endpoints`).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevApiController.name, 'getEndpoints');
    }
  }

  async getCodeChallengeAndNonce(datevSettingsId: string) {
    try {
      return await this.injector
        .get(HttpClient)
        .get<any>(`${this.DATEV_API_CONTROLLER_URL}/code-challenge-nonce/${datevSettingsId}`)
        .toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevApiController.name, 'getCodeChallenge');
    }
  }

  async getToken(code: string, redirectUri: string, codeChallenge: string, _interface = 'lodas') {
    try {
      const result = await this.injector
        .get(HttpClient)
        .post(`${this.DATEV_API_CONTROLLER_URL}/token`, { code, redirectUri, codeChallenge })
        .toPromise();

      this.injector.get(PrivateAmplitudeService).logEvent('connect to api', {
        platform: 'Web',
        category: 'DATEV',
        subcategory1: 'Payroll',
        subcategory3: _interface === DATEV_INTERFACE_LODAS ? 'LODAS' : 'Lohn & Gehalt',
      });

      return result;
    } catch (error) {
      const internalErrorCode = this.isDatevApiError(error.error) ? error.error : undefined;
      throw this.injector.get(ErrorManagerService).handleRawError(error, 'DatevApiController', 'getToken', internalErrorCode);
    }
  }

  async sendFile(datevSettingsId: string, from: Date, to: Date, periodIdentifier: number) {
    try {
      return await this.injector
        .get(HttpClient)
        .post(`${this.DATEV_API_CONTROLLER_URL}/file`, { datevSettingsId, from, to, periodIdentifier })
        .toPromise();
    } catch (error) {
      const internalErrorCode = this.isDatevApiError(error.error) ? error.error : undefined;
      throw this.injector.get(ErrorManagerService).handleRawError(error, 'DatevApiController', 'sendFile', internalErrorCode);
    }
  }

  async loginWithOauth(datevSettingsId: string, _interface = 'lodas'): Promise<boolean> {
    const endpoints = await this.getEndpoints();
    const { codeChallenge, nonce } = await this.getCodeChallengeAndNonce(datevSettingsId);

    const queryParams = new HttpParams()
      .set('state', `${this.document.location.origin}/signin-with-datev`)
      .set('scope', 'openid profile datev:hr:payrolldataupload')
      .set('response_type', 'code id_token')
      .set('redirect_uri', `${this.document.location.origin}/signin-with-datev`)
      .set('client_id', environment.DATEV_CLIENT_ID)
      .set('nonce', nonce)
      .set('code_challenge_method', 'S256')
      .set('code_challenge', codeChallenge);

    this.createOauthWindow(`${endpoints.authorization_endpoint}?${queryParams.toString()}`);

    const { code, state } = await this.getCodeAndState();
    if (check.not.assigned(code) || check.not.assigned(state)) {
      return false;
    }

    await this.getToken(code, state, codeChallenge, _interface);
    return true;
  }

  async getCodeAndState(): Promise<any> {
    const channel = new BroadcastChannel('datev-sign-in');

    return new Promise((resolve) => {
      channel.addEventListener('message', (event) => {
        resolve(event.data);
        channel.removeAllListeners('message');
        return;
      });

      setTimeout(() => {
        resolve(false);
        channel.removeAllListeners('message');
      }, 600000);
    });
  }

  createOauthWindow(url: string, name = 'Authorization', width = 700, height = 800) {
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    const options = `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no,rel=opener`;
    return window.open(url, name, options);
  }

  isDatevApiError(error: string) {
    return error.startsWith('#D');
  }
}
