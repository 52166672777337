import { Component, Inject, Injector, OnChanges, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { IUserWorkModel } from '@app/models/user-work.model';
import { IUserOptions } from '@app/standard/components/input-search-user/input-search-user.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { INodeData } from '@app/standard/pages/people/org-chart/services/org-chart.service';
import { AreaService } from '@app/standard/services/company/area.service';
import { CompanyService } from '@app/standard/services/company/company.service';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { OfficeService } from '@app/standard/services/company/office.service';
import { TeamService } from '@app/standard/services/company/team.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { PicklistsService } from '@app/standard/services/core/picklists.service';
import { PositionService } from '@app/standard/services/recruiting/position.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-org-chart-add-edit-node-dialog',
  templateUrl: 'org-chart-add-edit-node.dialog.html',
  styleUrls: ['org-chart-add-edit-node.dialog.scss'],
})
export class OrgChartAddEditNodeDialog implements OnInit {
  i18n: any = { dialog: {}, misc: {} };
  colors: Array<any> = [];
  options: any = [];

  nodeInfo: INodeData;

  employeeOptions: Array<IUserOptions> = [];
  userWorkMap: { [id: string]: IUserWorkModel } = {};
  companyOptions: Array<ISelectOption> = [];
  areaOptions: Array<ISelectOption> = [];
  officeOptions: Array<ISelectOption> = [];
  departmentOptions: Array<ISelectOption> = [];
  teamOptions: Array<ISelectOption> = [];
  jobOpeningNames: Array<string> = [];

  companyValidation: InputValidation;
  departmentValidation: InputValidation;
  officeValidation: InputValidation;
  futureRoleValidation: InputValidation;
  areaValidation: InputValidation;
  teamValidation: InputValidation;

  constructor(
    private dialogRef: MatLegacyDialogRef<OrgChartAddEditNodeDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: IAddEditNodeDialogData,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.nodeInfo = { ...this.data.nodeInfo };

    this.colors = Object.keys(userColorConstants).map((iColorKey: string) => {
      const color = {
        colorKey: iColorKey,
        value: userColorConstants[iColorKey],
      };
      return color;
    });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('org-chart-add-edit-node-dialog')
      .then((dialogTranslation) => {
        this.i18n.dialog = dialogTranslation;
      })
      .catch(() => {
        this.i18n.dialog = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.i18n.misc = miscTranslation;
      })
      .catch(() => {
        this.i18n.misc = {};
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, false)
      .then((allUserPersonal: Array<IUserPersonalModel>) => {
        this.employeeOptions = _.map(allUserPersonal, (iUserPersonal) => {
          return <IUserOptions>_.pick(iUserPersonal, ['_id', 'displayName', '_photo._url']);
        });
      })
      .catch(() => {
        this.employeeOptions = [];
      });

    this.injector
      .get(UserWorkService)
      .getAllUserWork(false, false)
      .then((allUserWork) => {
        this.userWorkMap = _.keyBy(allUserWork, '_id');
      })
      .catch(() => {
        this.userWorkMap = {};
      });

    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((companies) => {
        this.companyOptions = _.map(companies, (iCompany) => {
          const companyOption: ISelectOption = {
            name: iCompany.name,
            value: iCompany._id,
          };

          return companyOption;
        });
      })
      .catch(() => {
        this.companyOptions = [];
      });

    this.injector
      .get(PicklistsService)
      .getPicklist('orgChartElementType')
      .then((picklist: any) => {
        this.options = this.filterPicklist(picklist);
        this.injector
          .get(DepartmentService)
          .getDepartments()
          .then((departments) => {
            this.departmentOptions = _.map(departments, (iDepartment) => {
              const departmentOption: ISelectOption = {
                name: iDepartment.name,
                value: iDepartment._id,
              };
              return departmentOption;
            });
            if (this.departmentOptions.length <= 0) {
              delete picklist.Department;
              this.options = this.filterPicklist(picklist);
            }
          })
          .catch(() => {
            this.departmentOptions = [];
          });

        this.injector
          .get(AreaService)
          .getAreas()
          .then((areas) => {
            this.areaOptions = _.map(areas, (iArea) => {
              const areaOption: ISelectOption = {
                name: iArea.name,
                value: iArea._id,
              };

              return areaOption;
            });
            if (this.areaOptions.length <= 0) {
              delete picklist.Area;
              this.options = this.filterPicklist(picklist);
            }
          })
          .catch(() => {
            this.areaOptions = [];
          });

        this.injector
          .get(OfficeService)
          .getOffices()
          .then((offices) => {
            this.officeOptions = _.map(offices, (iOffice) => {
              const officeOption: ISelectOption = {
                name: iOffice.name,
                value: iOffice._id,
              };

              return officeOption;
            });
            if (this.officeOptions.length <= 0) {
              delete picklist.Office;
              this.options = this.filterPicklist(picklist);
            }
          })
          .catch(() => {
            this.officeOptions = [];
          });

        this.injector
          .get(TeamService)
          .getTeams()
          .then((teams) => {
            this.teamOptions = _.map(teams, (iTeam) => {
              const teamOption: ISelectOption = {
                name: iTeam.name,
                value: iTeam._id,
              };
              return teamOption;
            });
            if (this.teamOptions.length <= 0) {
              delete picklist.Team;
              this.options = this.filterPicklist(picklist);
            }
          })
          .catch(() => {
            this.teamOptions = [];
          });
      });

    this.injector
      .get(PositionService)
      .find({ status: { $ne: picklistConstants.POSITION_STATUS_ARCHIVED } })
      .then((jobOpenings) => {
        this.jobOpeningNames = _.map(jobOpenings, 'jobTitle');
      })
      .catch(() => {
        this.jobOpeningNames = [];
      });
  }

  public filterPicklist(picklist) {
    return Object.keys(picklist).reduce((tempOptions: Array<ISelectOption>, value: string) => {
      const picklistOption: ISelectOption = {
        name: picklist[value],
        value: value,
      };

      tempOptions.push(picklistOption);
      return tempOptions;
    }, []);
  }
  public isFormValid(): boolean {
    switch (this.nodeInfo.nodeType) {
      case 'Employee':
      case 'Assistant':
        return check.assigned(this.nodeInfo.employeeId);
      case 'Department':
        return (
          check.assigned(this.nodeInfo.departmentId) && check.assigned(this.departmentValidation) && this.departmentValidation.isValid()
        );
      case 'Company':
        return check.assigned(this.nodeInfo.companyId) && check.assigned(this.companyValidation) && this.companyValidation.isValid();
      case 'FutureRole':
        return (
          check.assigned(this.nodeInfo.futureRoleName) &&
          check.nonEmptyString(this.nodeInfo.futureRoleName) &&
          check.assigned(this.futureRoleValidation) &&
          this.futureRoleValidation.isValid()
        );
      case 'Area':
        return check.assigned(this.nodeInfo.areaId) && check.assigned(this.areaValidation) && this.areaValidation.isValid();
      case 'Team':
        return check.assigned(this.nodeInfo.teamId) && check.assigned(this.teamValidation) && this.teamValidation.isValid();
      case 'Office':
        return check.assigned(this.nodeInfo.officeId) && check.assigned(this.officeValidation) && this.officeValidation.isValid();
      default:
        return false;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (this.isFormValid() === false) {
      return;
    }
    switch (this.nodeInfo.nodeType) {
      case 'Employee':
      case 'Assistant':
        delete this.nodeInfo.departmentId;
        delete this.nodeInfo.companyId;
        delete this.nodeInfo.futureRoleName;
        delete this.nodeInfo.teamId;
        delete this.nodeInfo.areaId;
        delete this.nodeInfo.officeId;
        break;
      case 'Department':
        delete this.nodeInfo.employeeId;
        delete this.nodeInfo.companyId;
        delete this.nodeInfo.futureRoleName;
        delete this.nodeInfo.teamId;
        delete this.nodeInfo.areaId;
        delete this.nodeInfo.officeId;
        break;
      case 'Company':
        delete this.nodeInfo.employeeId;
        delete this.nodeInfo.departmentId;
        delete this.nodeInfo.futureRoleName;
        delete this.nodeInfo.teamId;
        delete this.nodeInfo.areaId;
        delete this.nodeInfo.officeId;
        break;
      case 'FutureRole':
        delete this.nodeInfo.employeeId;
        delete this.nodeInfo.departmentId;
        delete this.nodeInfo.companyId;
        delete this.nodeInfo.teamId;
        delete this.nodeInfo.areaId;
        delete this.nodeInfo.officeId;
        break;
      case 'Area':
        delete this.nodeInfo.employeeId;
        delete this.nodeInfo.departmentId;
        delete this.nodeInfo.futureRoleName;
        delete this.nodeInfo.companyId;
        delete this.nodeInfo.teamId;
        delete this.nodeInfo.officeId;
        break;
      case 'Team':
        delete this.nodeInfo.employeeId;
        delete this.nodeInfo.departmentId;
        delete this.nodeInfo.futureRoleName;
        delete this.nodeInfo.companyId;
        delete this.nodeInfo.areaId;
        delete this.nodeInfo.officeId;
        break;
      case 'Office':
        delete this.nodeInfo.employeeId;
        delete this.nodeInfo.departmentId;
        delete this.nodeInfo.futureRoleName;
        delete this.nodeInfo.companyId;
        delete this.nodeInfo.teamId;
        delete this.nodeInfo.areaId;
        break;
      default:
        break;
    }

    this.dialogRef.close(this.nodeInfo);
  }
}

export interface IAddEditNodeDialogData {
  mode: 'addFirst' | 'add' | 'edit';
  nodeInfo: INodeData;
}
