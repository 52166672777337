<orgos-container *ngIf="!loadingPage && !seeDetailsUserId" aspect="card">
  <orgos-column-container
    centerColumns="true"
    class="kenjo-ph-20px kenjo-pv-10px kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-bottom"
  >
    <orgos-column></orgos-column>
    <orgos-user-header
      *ngIf="userPersonal && userWork"
      orgosColumn="1"
      class="kenjo-ph-40px kenjo-pv-10px"
      size="medium"
      [userPersonal]="userPersonal"
      [userWork]="userWork"
    ></orgos-user-header>
    <orgos-column></orgos-column>
  </orgos-column-container>
  <div class="pdcp-current-salary-container kenjo-mt-20px kenjo-mb-40px" *ngIf="currentSalary && currentSalary.length > 0">
    <div class="kenjo-font-size-16px kenjo-font-color-light-text-757575 kenjo-text-align-center kenjo-mb-20px">
      {{i18n.page.currentSalary}}
    </div>
    <orgos-container aspect="card">
      <orgos-table
        [data]="currentSalary"
        [fullHeight]="true"
        [displayedColumns]="['_companyId','effectiveDate', 'payPeriod', 'amount']"
        [inheritBackground]="true"
        [centerContent]="true"
        [stickyRow]="false"
        [stickyColumn]="false"
      >
        <orgos-table-column columnKey="_companyId" [header]="i18n.userSalary._companyId">
          <div *orgosTableCell="let row" class="word-display">{{mapCompanyIdToCompanyName[row._companyId]}}</div>
        </orgos-table-column>
        <orgos-table-column columnKey="effectiveDate" [header]="i18n.userSalary.effectiveDate">
          <div *orgosTableCell="let row">{{row.effectiveDate | date: 'shortDate':'UTC'}}</div>
        </orgos-table-column>
        <orgos-table-column columnKey="payPeriod" [header]="i18n.userSalary.payPeriod">
          <div *orgosTableCell="let row">{{i18n.standardPicklist.payPeriod[row.payPeriod]}}</div>
        </orgos-table-column>
        <orgos-table-column columnKey="amount" [header]="i18n.userSalary.amount">
          <ng-container *orgosTableCell="let row">
            <div>
              {{row.amount | currency:row.currency:'code':'1.0-2'}}
              <span *ngIf="row.type !== 'Hourly'" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px"
                >({{row.partTime | number : '1.2-2'}}%)</span
              >
            </div>
            <div *ngIf="row.type !== 'Hourly'" class="kenjo-mt-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">
              {{i18n.page.fte}} {{row.amount * 100 / row.partTime | currency:row.currency:'code':'1.0-2'}}
            </div>
          </ng-container>
        </orgos-table-column>
      </orgos-table>
    </orgos-container>
  </div>

  <div class="kenjo-p-20px">
    <orgos-column-container centerColumns="true" class="kenjo-mb-20px">
      <orgos-column size="0" class="kenjo-font-size-18px kenjo-mr-10px">{{i18n.page.employmentTitle}}</orgos-column>
      <orgos-column size="0">
        <kenjo-icon
          [matTooltip]="i18n.page.employmentDescription"
          size="16"
          class="kenjo-font-color-success-00b72e kenjo-cursor-pointer"
          kenjo-cursor-pointer
        >
          help_outline</kenjo-icon
        >
      </orgos-column>
      <orgos-column></orgos-column>
      <orgos-column size="0" *ngIf="hasPermission.createUserEmployment">
        <orgos-action icon="add_circle" (click)="addEmployment()" class="kenjo-font-size-16px">
          {{i18n.page.addEmploymentButtonLabel}}</orgos-action
        >
      </orgos-column>
    </orgos-column-container>
    <orgos-container aspect="card" *ngIf="userEmployments.length > 0">
      <orgos-table
        [fullHeight]="true"
        [data]="userEmployments"
        [displayedColumns]="['_companyId', 'startDate', 'userEmploymentContractType', 'userEmploymentSubcategory', 'type', 'contract', 'comments', 'actions']"
      >
        <orgos-table-column columnKey="_companyId" [header]="i18n.userEmployment._companyId">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333 word-display" *orgosTableCell="let row">
            {{mapCompanyIdToCompanyName[row._companyId]}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="startDate" [header]="i18n.userEmployment.startDate" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{row.startDate | date: 'shortDate':'UTC'}}
          </div>
        </orgos-table-column>
        <orgos-table-column
          columnKey="userEmploymentContractType"
          [header]="i18n.userEmployment.userEmploymentContractType"
          [sortable]="true"
        >
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{ (mapContractTypeIdToContractTypeName$ | async)[row.userEmploymentContractType] }}
          </div>
        </orgos-table-column>
        <orgos-table-column
          columnKey="userEmploymentSubcategory"
          [header]="i18n.userEmployment.userEmploymentSubcategory"
          [sortable]="true"
        >
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{ (mapSubcategoryIdToContractTypeName$ | async)[row.userEmploymentSubcategory] }}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="type" [header]="i18n.userEmployment.type" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{i18n.standardPicklist.employmentType[row.type]}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="contract" [header]="i18n.userEmployment.contract" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{i18n.standardPicklist.contract[row.contract]}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="comments" [header]="i18n.userEmployment.comments">
          <div
            class="kenjo-font-size-14px kenjo-font-color-text-333333 word-display pdc-truncate-text kenjo-mt-10px kenjo-mb-10px"
            *orgosTableCell="let row"
          >
            {{row.comments}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="actions" [actionColumn]="true">
          <ng-container *orgosTableCell="let row">
            <ng-container *ngIf="canEditCompensation(row, 'editUserEmployment')">
              <kenjo-icon size="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer kenjo-m-10px"> more_vert </kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="editEmployment(row)">
                  <span>{{i18n.page.editButtonLabel}}</span>
                </button>
                <button *ngIf="hasPermission.deleteUserEmployment" mat-menu-item (click)="deleteEmployment(row)">
                  <span>{{i18n.page.deleteButtonLabel}}</span>
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </orgos-table-column>
      </orgos-table>
    </orgos-container>
    <orgos-container
      aspect="card"
      *ngIf="userEmployments.length === 0"
      class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-pv-10px kenjo-ph-20px"
    >
      {{i18n.page.noEmployment}}
    </orgos-container>

    <orgos-column-container centerColumns="true" class="kenjo-mt-60px kenjo-mb-20px">
      <orgos-column size="0" class="kenjo-font-size-18px kenjo-mr-10px">{{i18n.page.salaryTitle}}</orgos-column>
      <orgos-column size="0">
        <kenjo-icon
          [matTooltip]="i18n.page.salaryDescription"
          size="16"
          class="kenjo-font-color-success-00b72e kenjo-cursor-pointer"
          kenjo-cursor-pointer
        >
          help_outline</kenjo-icon
        >
      </orgos-column>
      <orgos-column></orgos-column>
      <orgos-column size="0" *ngIf="hasPermission.createUserSalary">
        <orgos-action icon="add_circle" class="kenjo-font-size-16px" [matMenuTriggerFor]="addSalaryMenu"
          >{{i18n.page.addSalaryButtonLabel}}</orgos-action
        >
        <mat-menu #addSalaryMenu="matMenu">
          <div mat-menu-item disabled>{{this.i18n.userSalary._companyId.toUpperCase()}}</div>
          <div mat-menu-item *ngFor="let row of companyNamesAndIds" (click)="addSalary(row._idCompany)">{{row.companyName}}</div>
        </mat-menu>
      </orgos-column>
    </orgos-column-container>

    <orgos-container aspect="card" *ngIf="userSalaries.length > 0">
      <orgos-table
        [fullHeight]="true"
        [data]="userSalaries"
        [displayedColumns]="['_companyId', 'effectiveDate','endDate', 'amount','currency', 'payPeriod', 'comments', 'actions' ]"
      >
        <orgos-table-column columnKey="_companyId" [header]="i18n.userSalary._companyId" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333 word-display" *orgosTableCell="let row">
            {{mapCompanyIdToCompanyName[row._companyId]}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="effectiveDate" [header]="i18n.userSalary.effectiveDate" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{row.effectiveDate | date: 'shortDate':'UTC'}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="endDate" [header]="i18n.userSalary.endDate" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{row.endDate | date: 'shortDate':'UTC'}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="amount" [header]="i18n.userSalary.amount" [sortable]="true">
          <ng-container *orgosTableCell="let row">
            <div>
              {{row.amount | number:'1.0-2'}}
              <span *ngIf="row.payPeriod !== 'Hourly'" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px"
                >({{row.partTime}}%)</span
              >
            </div>
            <div *ngIf="row.payPeriod !== 'Hourly'" class="kenjo-mt-10px kenjo-font-color-light-text-757575 kenjo-font-size-12px">
              {{i18n.page.fte}} {{row.amount * 100 / row.partTime | number:'1.0-2'}}
            </div>
          </ng-container>
        </orgos-table-column>
        <orgos-table-column columnKey="currency" [header]="i18n.userSalary.currency" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">{{row.currency}}</div>
        </orgos-table-column>
        <orgos-table-column columnKey="payPeriod" [header]="i18n.userSalary.payPeriod" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            {{i18n.standardPicklist.payPeriod[row.payPeriod]}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="comments" [header]="i18n.userSalary.comments">
          <div
            class="kenjo-font-size-14px kenjo-font-color-text-333333 word-display pdc-truncate-text kenjo-mt-10px kenjo-mb-10px"
            *orgosTableCell="let row"
          >
            {{row.comments}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="actions" [actionColumn]="true">
          <ng-container *orgosTableCell="let row">
            <ng-container *ngIf="canEditCompensation(row, 'editUserSalary')">
              <kenjo-icon size="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer kenjo-m-10px"> more_vert </kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="editSalary(row)">
                  <span>{{i18n.page.editButtonLabel}}</span>
                </button>
                <button mat-menu-item *ngIf="hasPermission.deleteUserSalary" (click)="deleteSalary(row)">
                  <span>{{i18n.page.deleteButtonLabel}}</span>
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </orgos-table-column>
      </orgos-table>
    </orgos-container>
    <orgos-container
      aspect="card"
      *ngIf="userSalaries.length === 0"
      class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-pv-10px kenjo-ph-20px"
    >
      {{i18n.page.noSalary}}
    </orgos-container>

    <orgos-column-container centerColumns="true" class="kenjo-mt-60px kenjo-mb-20px">
      <orgos-column size="0" class="kenjo-font-size-18px kenjo-mr-10px">{{i18n.page.variablePayTitle}}</orgos-column>
      <orgos-column size="0">
        <kenjo-icon
          [matTooltip]="i18n.page.variablePayDescription"
          size="16"
          class="kenjo-font-color-success-00b72e kenjo-cursor-pointer"
          kenjo-cursor-pointer
        >
          help_outline</kenjo-icon
        >
      </orgos-column>
      <orgos-column></orgos-column>
      <orgos-column size="0" *ngIf="hasPermission.createUserVariablePay">
        <orgos-action icon="add_circle" (click)="addVariablePay()" class="kenjo-font-size-16px">
          {{i18n.page.addVariablePayButtonLabel}}</orgos-action
        >
      </orgos-column>
    </orgos-column-container>
    <orgos-container aspect="card" *ngIf="userVariablePay.length > 0">
      <orgos-table
        [fullHeight]="true"
        [data]="userVariablePay"
        [displayedColumns]="['_companyId', 'effectiveDate', 'type', 'amount','currency', 'frequency', 'comments', 'actions']"
      >
        <orgos-table-column columnKey="_companyId" [header]="i18n.userVariablePay._companyId">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333 word-display" *orgosTableCell="let row">
            {{mapCompanyIdToCompanyName[row._companyId]}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="effectiveDate" [header]="i18n.userVariablePay.effectiveDate" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            <div>
              {{row.effectiveDate | date: 'shortDate':'UTC'}}<span *ngIf="row.endDate"> - {{row.endDate | date: 'shortDate':'UTC'}}</span>
            </div>
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="type" [header]="i18n.userVariablePay.type" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">{{variablePayTypes[row.type]}}</div>
        </orgos-table-column>
        <orgos-table-column columnKey="amount" [header]="i18n.userVariablePay.amount" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">{{row.amount | number: '1.0-2'}}</div>
        </orgos-table-column>
        <orgos-table-column columnKey="currency" [header]="i18n.userVariablePay.currency" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">{{row.currency}}</div>
        </orgos-table-column>
        <orgos-table-column columnKey="frequency" [header]="i18n.userVariablePay.frequency" [sortable]="true">
          <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
            <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *ngIf="!row.everyMonths || row.everyMonths === false">
              {{i18n.page.once}}
            </div>
            <div
              class="kenjo-font-size-14px kenjo-font-color-text-333333"
              *ngIf="row.everyMonths && row.everyMonths === true && row.frequency > 1"
            >
              {{i18n.page.everyLabel | i18nData : {months: row.frequency} }}
            </div>
            <div
              class="kenjo-font-size-14px kenjo-font-color-text-333333"
              *ngIf="row.everyMonths && row.everyMonths === true && row.frequency === 1"
            >
              {{i18n.page.everyMonth}}
            </div>
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="comments" [header]="i18n.userVariablePay.comments">
          <div
            class="kenjo-font-size-14px kenjo-font-color-text-333333 word-display pdc-truncate-text kenjo-mt-10px kenjo-mb-10px"
            *orgosTableCell="let row"
          >
            {{row.comments}}
          </div>
        </orgos-table-column>
        <orgos-table-column columnKey="actions" [actionColumn]="true">
          <ng-container *orgosTableCell="let row">
            <ng-container *ngIf="canEditCompensation(row, 'editUserVariablePay')">
              <kenjo-icon size="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer kenjo-m-10px"> more_vert </kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="editVariablePay(row)">
                  <span>{{i18n.page.editButtonLabel}}</span>
                </button>
                <button mat-menu-item *ngIf="hasPermission.deleteUserVariablePay" (click)="deleteVariablePay(row)">
                  <span>{{i18n.page.deleteButtonLabel}}</span>
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </orgos-table-column>
      </orgos-table>
    </orgos-container>
    <orgos-container
      aspect="card"
      *ngIf="userVariablePay.length === 0"
      class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-pv-10px kenjo-ph-20px"
    >
      {{i18n.page.noVariablePay}}
    </orgos-container>

    <!-- SALARY SURCHARGES -->
    <orgos-column-container centerColumns="true" class="kenjo-mt-60px kenjo-mb-20px">
      <orgos-column size="0" class="kenjo-font-size-18px kenjo-mr-10px">{{ i18n.page.surchargeRulesTitle }}</orgos-column>
      <orgos-column size="0">
        <kenjo-icon [matTooltip]="i18n.page.surchargeRulesTooltip" size="16" class="kenjo-font-color-success-00b72e kenjo-cursor-pointer"
          >help_outline</kenjo-icon
        >
      </orgos-column>
      <orgos-column></orgos-column>
      <orgos-column
        size="0"
        *ngIf="hasPermission.createSurcharge"
        [matTooltip]="addSurchargesTooltip"
        [ngClass]="{'kenjo-cursor-pointer': addSurchargesTooltip}"
      >
        <orgos-action
          icon="add_circle"
          (click)="toggleSuchargeOverlay()"
          [disabled]="addSurchargesDisabled"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          class="kenjo-font-size-16px"
        >
          {{ i18n.page.addSurchargeRuleButtonLabel }}
        </orgos-action>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="openAddSuchargeOverlay"
          (overlayOutsideClick)="openAddSuchargeOverlay = false"
          (backdropClick)="openAddSuchargeOverlay = false"
          (detach)="openAddSuchargeOverlay = false"
        >
          <div class="pdc-autocomplete-container">
            <kenjo-search-item-dropdown
              (selectOption)="addSurchargeRule($event)"
              [autofocus]="true"
              width="400px"
              [selectableOptions]="autocompleteOptions"
              [placeholder]="i18n.miscGlobal.search"
              [emptyOptionsText]="i18n.miscGlobal.noResultsFound"
            >
            </kenjo-search-item-dropdown>
          </div>
        </ng-template>
      </orgos-column>
    </orgos-column-container>
    <orgos-container aspect="card" *ngIf="salarySurcharges?.length > 0">
      <orgos-table
        [fullHeight]="true"
        [data]="salarySurcharges"
        [displayedColumns]="['status', 'name', 'extraRatePercentage', 'ruleStartDate', 'ruleEndDate', 'condition', 'actions']"
      >
        <orgos-table-column columnKey="status" [sortable]="false" cellWidth="2">
          <ng-container *orgosTableCell="let row">
            <div
              *ngIf="surchargeIdToSurchargeStatus[row._id] === 'SURCHARGE_STATUS_ACTIVE'"
              class="pdc-status-circle active"
              [matTooltip]="i18n.settingsPayroll.SURCHARGE_STATUS_ACTIVE"
            ></div>
            <div
              *ngIf="surchargeIdToSurchargeStatus[row._id] === 'SURCHARGE_STATUS_PAST'"
              class="pdc-status-circle past"
              [matTooltip]="i18n.settingsPayroll.SURCHARGE_STATUS_PAST"
            ></div>
            <div
              *ngIf="surchargeIdToSurchargeStatus[row._id] === 'SURCHARGE_STATUS_FUTURE'"
              class="pdc-status-circle future"
              [matTooltip]="i18n.settingsPayroll.SURCHARGE_STATUS_FUTURE"
            ></div>
          </ng-container>
        </orgos-table-column>
        <orgos-table-column columnKey="name" [sortable]="true" [header]="i18n.settingsPayroll.surchargeName">
          <ng-container *orgosTableCell="let row"> {{ row.name }} </ng-container>
        </orgos-table-column>
        <orgos-table-column columnKey="extraRatePercentage" [sortable]="true" [header]="i18n.settingsPayroll.surchargeSurchargeLabel">
          <ng-container *orgosTableCell="let row"> {{ row.extraRatePercentage }}% </ng-container>
        </orgos-table-column>
        <orgos-table-column columnKey="ruleStartDate" [sortable]="true" [header]="i18n.settingsPayroll.surchargeStartDate">
          <ng-container *orgosTableCell="let row"> {{ row.ruleStartDate | date:'dd/MM/yyyy' }} </ng-container>
        </orgos-table-column>
        <orgos-table-column columnKey="ruleEndDate" [sortable]="true" [header]="i18n.settingsPayroll.surchargeEndDate">
          <ng-container *orgosTableCell="let row">
            <ng-container *ngIf="row.ruleEndDate"> {{ row.ruleEndDate | date:'dd/MM/yyyy' }} </ng-container>
            <ng-container *ngIf="!row.ruleEndDate"> {{ i18n.settingsPayroll.noEndDate }} </ng-container>
          </ng-container>
        </orgos-table-column>
        <orgos-table-column columnKey="condition" [header]="i18n.settingsPayroll.surchargeCondition">
          <ng-container *orgosTableCell="let row">
            <kenjo-truncate-text> {{ surchargeIdToConditionMessage[row._id] }} </kenjo-truncate-text>
          </ng-container>
        </orgos-table-column>
        <orgos-table-column columnKey="actions" [actionColumn]="true">
          <ng-container *orgosTableCell="let row">
            <ng-container *ngIf="hasPermission.deleteSurcharge">
              <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert</kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="deleteEmployeeFromSurchargeRule(row)">
                  {{ i18n.page.deleteEmployeeFromSurchargeRuleActionButton }}
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </orgos-table-column>
      </orgos-table>
    </orgos-container>
    <orgos-container
      aspect="card"
      *ngIf="salarySurcharges?.length === 0 && isAttendanceEnabled"
      class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-pv-10px kenjo-ph-20px"
    >
      {{ i18n.page.noSalarySurcharges }}
    </orgos-container>
    <orgos-container
      aspect="card"
      *ngIf="!isAttendanceEnabled"
      class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-pv-10px kenjo-ph-20px"
    >
      {{ i18n.page.enableAttendanceToCreateSurchargesMessage }}
    </orgos-container>
  </div>
</orgos-container>

<orgos-time-off-user-page
  *ngIf="seeDetailsUserId"
  [userId]="seeDetailsUserId"
  [managePoliciesInfo]="userManagePoliciesInfo"
  (goBack)="exitTimeOffFullScreen()"
  class="pdap-time-off-page"
></orgos-time-off-user-page>
