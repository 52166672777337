<orgos-dialog-container [title]="headerText" (clickCloseButton)="closeDialog()">
  <orgos-page-message *ngIf="showWarning" messageType="warning" [messageText]="dialogTranslation.warningMessage" class="kenjo-mb-40px"></orgos-page-message>
  <div class="kenjo-font-color-light-text-757575">{{ subtitleText }} <span *ngIf="showWarning">{{ warningText }}</span></div>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()" [disabled]="voidingInProcess"> {{miscTranslation.goBackButtonDialog}}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised *ngIf="!voidingInProcess" color="Danger" (click)="sendReminder()"> {{voidDocumentButtonText}}</orgos-button-raised>
      <orgos-button-raised *ngIf="voidingInProcess" color="Danger" [disabled]="true"> {{voidDocumentButtonText}}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
