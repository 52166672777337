import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { getEmptyFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import {
  ShiftCardFilters,
  ShiftPlanCardService,
  ShiftplanPublishType,
} from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { FormatDatePipe } from '@app/standard/components/format-date/format-date.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  SHIFT_PLAN_CARD_BOTH,
  SHIFT_PLAN_CARD_OPEN,
  SHIFT_PLAN_CARD_SCHEDULED,
  SHIFT_PLAN_MONTHLY_VIEW,
  SHIFT_PLAN_WEEKLY_VIEW,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-publish-shift-dialog',
  templateUrl: 'publish-shift-dialog.html',
  styleUrls: ['publish-shift-dialog.scss'],
})
export class PublishShiftDialog implements OnInit {
  pageTranslation: { [key: string]: string };
  publishTitle: string;
  loading = true;
  notificationToggle: boolean = false;
  publishOptions = { open: false, scheduled: false };
  canSendNotifications: boolean = false;
  isPublishing: boolean = false;
  publishShiftsLimit: boolean = false;
  publishTypeConst = {
    SHIFT_PLAN_CARD_BOTH: SHIFT_PLAN_CARD_BOTH,
    SHIFT_PLAN_CARD_OPEN: SHIFT_PLAN_CARD_OPEN,
    SHIFT_PLAN_CARD_SCHEDULED: SHIFT_PLAN_CARD_SCHEDULED,
  };

  filters: ShiftCardFilters = { ...getEmptyFilters() };
  filtersApplied: boolean;
  otherShiftsOwners: boolean;
  shiftsData;

  constructor(
    public dialogRef: MatLegacyDialogRef<PublishShiftDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    private confirmDialog: MatLegacyDialog,
    public snackBar: MatLegacySnackBar,
    public formatDatePipe: FormatDatePipe
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.shiftsData = this.dialogInfo;
    this.canSendNotifications = this.shiftsData.notificationSetting;
    this.notificationToggle = this.canSendNotifications;
    this.publishShiftsLimit = this.shiftsData.publishShiftsLimit;
    this.handleWarnings();
    this.initPublishOptions();
    this.fetchTranslations();
  }

  initPublishOptions() {
    if (this.shiftsData.scheduledShiftsCount > 0) {
      this.publishOptions.scheduled = true;
      return;
    }

    if (this.shiftsData.openShiftsCount > 0 && this.shiftsData.scheduledShiftsCount === 0) {
      this.publishOptions = { open: true, scheduled: false };
    }
  }

  async fetchTranslations() {
    this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-publish-dialog');
    this.setTitle();
  }

  private setTitle() {
    const view = this.dialogInfo.view;

    if (view === SHIFT_PLAN_WEEKLY_VIEW) {
      const weekRange = ` ${this.formatDatePipe.transform(this.dialogInfo.startDay, 'D MMM')} - ${this.formatDatePipe.transform(
        this.dialogInfo.endDay,
        'D MMM'
      )}`;
      this.publishTitle = `${this.pageTranslation.dialogTitleWeek} ${weekRange}`;
    }

    if (view === SHIFT_PLAN_MONTHLY_VIEW) {
      const monthName = this.formatDatePipe.transform(this.dialogInfo.startDay, 'MMMM');
      this.publishTitle = `${this.pageTranslation.dialogTitleMonth} ${monthName}`;
    }
  }

  async publishShifts() {
    try {
      this.isPublishing = true;
      const publishType = this.calculatePublishType();

      const eventsToLogPromises = [];
      if (this.notificationToggle) {
        eventsToLogPromises.push(
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('Push notification sent', { category: 'Shiftplan', subcategory: 'Notifications' })
        );
      }
      if ([this.publishTypeConst.SHIFT_PLAN_CARD_OPEN, this.publishTypeConst.SHIFT_PLAN_CARD_BOTH].includes(publishType)) {
        eventsToLogPromises.push(
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('Open shifts published', { category: 'Shiftplan', subcategory: 'Open shift', subcategory2: 'Core activation' })
        );
      }

      const result = await this.injector
        .get(ShiftPlanCardService)
        .publishShiftCards(
          this.shiftsData.startDay,
          moment.utc(this.shiftsData.endDay).endOf('day').toISOString(),
          this.notificationToggle,
          this.filters,
          publishType
        );
      if (check.not.assigned(result)) {
        this.dialogRef.close(false);
        await this.showErrorDialogPopUp();
        return;
      }
      this.showSnackbar();
      await Promise.all(eventsToLogPromises);
    } catch {
      // do nothing
    } finally {
      this.isPublishing = false;
      this.dialogRef.close(true);
    }
  }

  private calculatePublishType(): ShiftplanPublishType {
    if (this.publishOptions.open && this.publishOptions.scheduled) {
      return this.publishTypeConst.SHIFT_PLAN_CARD_BOTH;
    }

    if (this.publishOptions.open) {
      return this.publishTypeConst.SHIFT_PLAN_CARD_OPEN;
    }

    if (this.publishOptions.scheduled) {
      return this.publishTypeConst.SHIFT_PLAN_CARD_SCHEDULED;
    }
  }

  private async showErrorDialogPopUp(): Promise<void> {
    this.isPublishing = false;
    const data = {
      titleText: this.pageTranslation.errorMessagePublishingTitle,
      subtitleText: this.pageTranslation.errorMessagePublishingSubTitle,
      confirmButtonText: this.pageTranslation.gotItButton,
    };
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, { data });
    await dialogRef.afterClosed().toPromise();
  }

  private handleWarnings() {
    this.filters = this.shiftsData.filters;
    this.filtersApplied =
      check.assigned(this.filters) &&
      (this.filters.locations.length > 0 ||
        this.filters.employees.length > 0 ||
        this.filters.roles.length > 0 ||
        this.filters.workingAreas.length > 0 ||
        this.filters.tags.length > 0);
    this.otherShiftsOwners = this.shiftsData.otherShiftsOwners;
  }

  closeDialog(): void {
    if (this.isPublishing) {
      return;
    }
    this.dialogRef.close();
  }

  showSnackbar(): void {
    const snackBarMessage =
      !this.notificationToggle || !this.canSendNotifications
        ? this.pageTranslation.snackbarOnlyPublished
        : this.pageTranslation.snackbarPublishedAndNotify;
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }
}
