import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTable, MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import * as check from 'check-types';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

import { GenericModel } from '../../core/generic-model';
import { InternationalizationService } from '../../services/core/internationalization.service';
import { TableSmartColumnDirective } from './table-smart-column.directive';

@Component({
  selector: 'orgos-table-smart',
  templateUrl: 'table-smart.component.html',
  styleUrls: ['table-smart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
          width: '250px',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          width: '0',
        })
      ),
      transition('* => open', [
        style({ width: '0', opacity: 0 }),
        animate('200ms ease-in', style({ width: '250px' })),
        animate('200ms ease-in', style({ opacity: '1' })),
      ]),
      transition('* => closed', [
        style({ width: '250px', opacity: 1 }),
        animate('200ms ease-out', style({ opacity: '0' })),
        animate('200ms ease-out', style({ width: '0' })),
      ]),
    ]),
  ],
})
export class TableSmartComponent implements OnInit, AfterViewInit {
  miscTranslation: any = {};
  tableInitialized: boolean = false;
  selectedRowIndex: number = -1;

  private PX_PER_COLUMN = 200;
  dataSource: MatLegacyTableDataSource<any> = new MatLegacyTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  @Input() tooltipPosition: 'above' | 'below' | 'left' | 'right' | 'before' | 'after' = 'before';

  // Bind component selection to changes in the parent selection
  @Input()
  set selectedList(selection: Array<any>) {
    this.selection = new SelectionModel<any>(true, selection);
  }

  @Input() sortBy: string;
  @Input() sortOrder: string;
  @Input() overrideWidth: { [key: string]: boolean } = {};
  @Input() fullHeight: boolean = false;
  @Input() fitParent: boolean = false;
  @Input() numberOfStickyColumns: number = 0;
  maxDynamicHeight: string;

  _customHeightToSubstract: number = 210;
  @Input()
  set customHeightToSubstract(newValue: number) {
    this._customHeightToSubstract = newValue ?? 210;
    this.maxDynamicHeight = this.fullHeight === false ? `calc(100vh - ${this.customHeightToSubstract}px)` : '';
  }

  get customHeightToSubstract(): number {
    return this._customHeightToSubstract;
  }

  @Output() sortTable: EventEmitter<any> = new EventEmitter<any>();

  private initialData: Array<any> = [];
  @Input()
  set data(data: Array<any>) {
    if (this.tableInitialized === true) {
      this.initialData = null;
      this.dataSource.data = data;
    } else {
      this.initialData = data;
    }
  }

  defaultFilterPredicate: (row: any, filter: string) => boolean = (row: any, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const accumulator = (currentTerm, key) => {
      if (check.instanceStrict(row, GenericModel)) {
        return `${currentTerm}${row.data[key]}`;
      } else {
        return `${currentTerm}${row[key]}`;
      }
    };

    let dataKeys;
    if (check.instanceStrict(row, GenericModel)) {
      dataKeys = Object.keys(row.data);
    } else {
      dataKeys = Object.keys(row);
    }

    const dataStr = dataKeys.reduce(accumulator, '').toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) !== -1;
  };

  private _customFilterPredicate: (row: any, filter: string) => boolean;
  @Input()
  set filterPredicate(filterPredicate: (row: any, filter: string) => boolean) {
    this._customFilterPredicate = filterPredicate;
    this.dataSource.filterPredicate = filterPredicate;
  }
  get filterPredicate(): (row: any, filter: string) => boolean {
    if (check.not.assigned(this._customFilterPredicate)) {
      return this.defaultFilterPredicate;
    }

    return this._customFilterPredicate;
  }

  defaultSortingDataAccessor: (row: any, sortHeaderId: string) => string | number = (row: any, sortHeaderId: string): string | number => {
    let value: any;
    if (check.instanceStrict(row, GenericModel)) {
      value = row.data[sortHeaderId];
    } else {
      value = row[sortHeaderId];
    }

    if (check.not.assigned(value) || check.emptyString(value)) {
      return '';
    }

    if (isNaN(+value) === false) {
      return +value;
    }

    const isoDate = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true);
    if (isoDate.isValid()) {
      return isoDate.valueOf();
    }

    const localizedShortDate = moment.utc(value, 'DD-MM-YYYY');
    if (localizedShortDate.isValid()) {
      return localizedShortDate.valueOf();
    }

    const localizedShortDateUsa = moment.utc(value, 'MM-DD-YYYY');
    if (localizedShortDateUsa.isValid()) {
      return localizedShortDateUsa.valueOf();
    }

    // If the value is a string and only whitespace, return the value.
    // Otherwise +value will convert it to 0.
    // tslint:disable-next-line: strict-boolean-expressions
    if (typeof value === 'string' && !value.trim()) {
      return value;
    }

    return value.toString().toLowerCase();
  };

  private _customSortingDataAccessor: (data: any, sortColumnKey: string) => string | number;
  @Input()
  set sortingDataAccessor(sortingDataAccessor: (data: any, sortColumnKey: string) => string | number) {
    this._customSortingDataAccessor = sortingDataAccessor;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }
  get sortingDataAccessor(): (data: any, sortColumnKey: string) => string | number {
    if (check.not.assigned(this._customSortingDataAccessor)) {
      return this.defaultSortingDataAccessor;
    }

    return this._customSortingDataAccessor;
  }

  @Input() manageColumns: boolean = false;
  @Input() bulkActionsAllowed: boolean = true;
  @Input() unselectableColumns: Array<string> = [];
  @Input() totalColumns: Array<string> = [];
  @Input() displayedColumns: Array<string> = [];
  @Input() headerLabels: IHeader;
  @Output() changeColumns: EventEmitter<any> = new EventEmitter<any>();

  viewManageColumns: boolean = false;
  inColumnsSetup: boolean = false;
  columnsControl: Array<IColumn> = [];
  columnsControlFiltered: Array<IColumn> = [];
  actionColumns: Array<string> = [];
  actions: Array<string> = [];
  closeManageColumsAnimation: boolean = false;

  @Input() stickyRow: boolean = true;
  @Input() stickyColumn: boolean = true;
  @Input() centerContent: boolean = false;
  @Input() maxHeight: string = '100%';
  @Input() selectable: boolean = false;
  @Input() positionRelativeTd: boolean = false;
  @Output() changeSelection: EventEmitter<any> = new EventEmitter<any>();

  private _tableWidth: string | 'dynamic' = '100%';
  @Input()
  set tableWidth(tableWidth: string | 'dynamic') {
    this._tableWidth = tableWidth;
  }
  get tableWidth(): string {
    if (this._tableWidth === 'dynamic') {
      return `${this.PX_PER_COLUMN * this.displayedColumns.length}px`;
    }

    return this._tableWidth;
  }

  @Input() disableRowHighlight: boolean = false;

  @Input()
  set inheritBackground(inheritBackground: boolean | 'true' | 'false') {
    if (inheritBackground === true || inheritBackground === 'true') {
      this.backgroundStyle = 'inherit';
    } else {
      this.backgroundStyle = 'white';
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  @ViewChild(MatLegacyTable, { static: false }) set table(matTable: MatLegacyTable<any>) {
    if (matTable && this.numberOfStickyColumns > 0) {
      this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
        matTable.updateStickyColumnStyles();
      });
    }
  }

  private sourceColumns: Array<TableSmartColumnDirective>;
  private _tableColumns: Array<TableSmartColumnDirective>;
  @ContentChildren(TableSmartColumnDirective)
  set tableColumns(tableColumns: Array<TableSmartColumnDirective>) {
    if (check.not.assigned(this.displayedColumns) || check.emptyArray(this.displayedColumns)) {
      this.displayedColumns = this.totalColumns;
    }
    this.sourceColumns = tableColumns;
    this.sourceColumns.forEach((iColumn) => {
      if (iColumn.actionColumn === true) {
        this.actionColumns.push(iColumn.columnKey);
      }
    });

    const processedDisplayedColumns = [];
    this._tableColumns = [];
    this.displayedColumns.forEach((element) => {
      const tableColumn = tableColumns.find((iColumn) => iColumn.columnKey === element);
      if (check.assigned(tableColumn)) {
        this._tableColumns.push(tableColumn);
        processedDisplayedColumns.push(element);
      }
    });
    this.displayedColumns = processedDisplayedColumns;

    if (this.actionColumns.length) {
      let nonActionColumns = this.displayedColumns.filter((column) => {
        if (this.actionColumns.includes(column)) {
          this.actions.push(column);
          return false;
        }
        return true;
      });
      this.displayedColumns = this.actions.concat(nonActionColumns);
    }
  }
  get tableColumns(): Array<TableSmartColumnDirective> {
    return this._tableColumns;
  }

  @HostBinding('style.background') backgroundStyle: string = 'white';

  constructor(private injector: Injector, private cdr: ChangeDetectorRef, private ngZone: NgZone) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation: any) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    if (check.assigned(this.totalColumns) && check.assigned(this.displayedColumns)) {
      this.columnsControl = this.displayedColumns.map((iColumnKey) => {
        return { columnKey: iColumnKey, visible: true };
      });

      if (check.not.equal(this.totalColumns.length, this.columnsControl.length)) {
        this.totalColumns
          .filter((iColumnKey) => {
            return !this.displayedColumns.includes(iColumnKey);
          })
          .forEach((iMissingHeader) => {
            this.columnsControl.push({ columnKey: iMissingHeader, visible: false });
          });
      }
    }
    if (this.fitParent === true) {
      this.maxDynamicHeight = '100%';
    } else {
      this.maxDynamicHeight = this.fullHeight === false ? `calc(100vh - ${this.customHeightToSubstract}px)` : '';
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.initialData;
    this.tableInitialized = true;
    this.columnsControlFiltered = this.columnsControl.filter((iColumn) => !this.actionColumns.includes(iColumn.columnKey));

    this.cdr.detectChanges();
  }

  isAllSelected() {
    const pendingToProcessRows = this.dataSource.data.filter((row) => {
      return check.assigned(row.disableTableColumn) && row.disableTableColumn === true;
    });
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected + pendingToProcessRows.length === numRows;
  }

  // It emits selected list once click on the master checkbox
  masterToggle() {
    const allSelected = this.isAllSelected();
    if (allSelected === true) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => {
        if (!row.disableTableColumn) {
          this.selection.select(row);
        }
      });
    }
    this.changeSelection.emit(this.selection.selected);
  }

  // It emits selected list once click on each checkbox
  selectCheckbox(row) {
    this.selection.toggle(row);
    this.changeSelection.emit(this.selection.selected);
  }

  public hideManageIcon(): void {
    setTimeout(() => {
      this.viewManageColumns = false;
      this.cdr.detectChanges();
    }, 50);
  }

  public openManageColumns(): void {
    this.inColumnsSetup = true;
    this.closeManageColumsAnimation = false;
  }

  public closeManageColumns(): void {
    this.closeManageColumsAnimation = true;
    setTimeout(() => {
      this.inColumnsSetup = false;
      this.cdr.detectChanges();
    }, 400);
  }

  public saveColumns(): void {
    this._tableColumns = [];
    this.displayedColumns = [];
    let nonActionColumns = [];

    this.columnsControlFiltered.forEach((iColumn) => {
      if (iColumn.visible && ((iColumn.columnKey === 'select' && this.bulkActionsAllowed) || iColumn.columnKey !== 'select')) {
        nonActionColumns.push(iColumn.columnKey);
      }
    });
    this.displayedColumns = this.actions.concat(nonActionColumns);

    this.displayedColumns.forEach((iColumn) => {
      const realColumn = this.sourceColumns.find((iRealColumns) => {
        return iRealColumns.columnKey === iColumn;
      });
      this._tableColumns.push(realColumn);
    });

    this.changeColumns.emit(this.displayedColumns);
  }

  public changeColumn(key: string): void {
    const index = this.columnsControl.findIndex((column) => column.columnKey === key);
    this.columnsControl[index].visible = !this.columnsControl[index].visible;
    this.saveColumns();
  }

  public sortColumn(value: any): void {
    const sortOptions: any = {};
    if (check.assigned(value.direction) && check.nonEmptyString(value.direction)) {
      sortOptions.sortBy = value.active;
      sortOptions.sortOrder = value.direction;
    }

    this.sortTable.emit(sortOptions);
  }
}

export interface IHeader {
  [columnKey: string]: string;
}

export interface IColumn {
  visible: boolean;
  columnKey: string;
}
