import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ACCESS_TYPE_FULL_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { COUNTRY_TO_MARKETING_REGION } from '@app/standard/services/locales/locales.utils';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { ErrorCodes } from '../../standard/core/error/error-codes';
import { OrgosError } from '../../standard/core/error/orgos-error';
import { InternationalizationService } from '../../standard/services/core/internationalization.service';
import { ErrorManagerService } from '../../standard/services/error/error-manager.service';
import { IChurnZeroEnabled } from '@app/private/services/private-organization.service';

@Injectable({
  providedIn: 'root'
})
export class PrivateHubspotService {
  private HUBSPOT_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/hubspot`;
  private HUBSPOT_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/hubspot`;
  private HUBSPOT_TRACKING_CODE_URL: string = environment.HUBSPOT_TRACKING_CODE_URL;
  private HUBSPOT_TRACKING_CODE_ELEMENT_ID: string = 'hs-script-loader';

  private RELEVANT_USER_PROFILES: Array<string> = ['admin', 'hr-admin', 'recruiter'];
  private RELEVANT_MARKETING_REGIONS: Array<string> = ['dach', 'latam'];

  private _window: any = window as any;
  private _hubspotConversationInstance: Partial<IHubspotConversation>;
  private _userInfo: IUserAccountModel;
  private _orgInfo: any;

  private PROFILE_MAP = {
    admin: 'Admin',
    'hr-admin': 'HR Admin',
    recruiter: 'Recruiter'
  };

  constructor(private injector: Injector, @Inject(DOCUMENT) private document: Document,  private router: Router) {
    // hubspot conversation settings //
    this._window.hsConversationsSettings = {
      loadImmediately: false,
      enableWidgetCookieBanner: true
    };
  }

  // check confluence for conditions under which the hubspot chat should appear
  public async loadHubspotTrackingCode(): Promise<void> {
    if (environment.production === false) {
      return;
    }
    if (check.not.nonEmptyString(this.HUBSPOT_TRACKING_CODE_URL)) {
      this.injector.get(ErrorManagerService).handleRawErrorSilently('Hubspot code url not defined', PrivateHubspotService.name, 'loadHubspotTrackingCode');
      return;
    }

    try {
      // 1. if not trial, not dach account or cz is enabled; return
      const subscription: IChurnZeroEnabled = await this.injector.get(PrivateOrganizationService).isChurnZeroEnabled();
      if (check.not.equal(subscription?.subscriptionStatus, picklistConstants.SUBSCRIPTION_IN_TRIAL)) {
        return;
      }
      if (subscription._churnzeroEnabled === true) {
        return;
      }

      if (this.RELEVANT_MARKETING_REGIONS.includes(COUNTRY_TO_MARKETING_REGION[subscription?.countryCode?.toLocaleLowerCase()]) === false) {
        return;
      }
      // 2. user authentication conditions
      if (this.injector.get(AuthenticationService).getAccessType() !== ACCESS_TYPE_FULL_ACCESS) {
        return;
      }

      //3. user account conditions
      this._userInfo = this.injector.get(PrivateAuthenticationService).getLoggedUser();
      if (this._userInfo?._onboarding === 'Employee') {
        return;
      }
      if (this.RELEVANT_USER_PROFILES.includes(this._userInfo.profileKey) === false) {
        return;
      }

      // 3. if script already loaded by previous user, reset; else download script
      if (check.assigned(this._hubspotConversationInstance)) {
        this._hubspotConversationInstance.clear({ resetWidget: true });
        await this.identifyHubspotUser();
        return;
      }
      await this.downloadScript();

      // 4. store conversation widget &&
      // 5. identify the chat user
      if (check.assigned(this._window.HubSpotConversations)) {
        await this.onHubspotInstanceReady();
      } else {
        this._window.hsConversationsOnReady = [async () => await this.onHubspotInstanceReady()];
      }
    } catch (error: any) {
      this.injector.get(ErrorManagerService).handleRawErrorSilently(`Error loading Hubspot tracking script: ${error?.message}`, PrivateHubspotService.name, 'loadHubspotTrackingCode');
    }
  }

  public async hubspotSignOut(): Promise<void> {
    if (check.not.assigned(this._hubspotConversationInstance)) {
      this._userInfo = undefined;
      return;
    }
    this._hubspotConversationInstance.clear();
    this._hubspotConversationInstance.widget.remove();
    this._userInfo = undefined;
  }

  // PRIVATE METHODS //
  private downloadScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const firstScriptElement = document.getElementsByTagName('script')[0];
      const hubspotScript = document.createElement('script');
      hubspotScript.type = 'text/javascript';
      hubspotScript.id = this.HUBSPOT_TRACKING_CODE_ELEMENT_ID;
      hubspotScript.async = true;
      hubspotScript.defer = true;
      hubspotScript.src = this.HUBSPOT_TRACKING_CODE_URL;

      firstScriptElement.parentNode.insertBefore(hubspotScript, firstScriptElement);
      hubspotScript.onload = () => {
        resolve();
      };
      hubspotScript.onerror = (event, source, lineno, colno, error) => {
        reject(new Error(`Could not load Hubspot tracking code script: ${typeof event === 'string' ? event : ''} ${error?.message}`));
      };
    });
  }

  private async onHubspotInstanceReady(): Promise<void> {
    // this prevents hsConversationsOnReady from trolling by firing the chat with the previously logged user
    if (check.not.assigned(this?._userInfo)) {
      return;
    }
    this.storeHubspotConversationsInstance();
    await this.identifyHubspotUser();
  }

  private storeHubspotConversationsInstance(): void {
    this._hubspotConversationInstance = this._window.HubSpotConversations as Partial<IHubspotConversation>;
  }

  private async identifyHubspotUser(): Promise<void> {
    const hubspotToken = await this.fetchVisitorToken();
    if (check.not.assigned(hubspotToken.token)) {
      this.injector.get(ErrorManagerService).handleRawErrorSilently('Could not identify visitor in Hubspot', PrivateHubspotService.name, 'loadHubspotTrackingCode');
      return;
    }
    this._window.hsConversationsSettings.identificationEmail = this._userInfo.email;
    this._window.hsConversationsSettings.identificationToken = hubspotToken.token;
    this._orgInfo = await this.injector.get(PrivateOrganizationService).getMyOrganization();

    this._hubspotConversationInstance.widget.load();
    this._hubspotConversationInstance.widget.refresh();
    this._hubspotConversationInstance.on('conversationStarted', this.onConversationStarted.bind(this));
  }

  private async fetchVisitorToken(): Promise<IHubspotVisitorIdentification> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };
    return (await this.injector.get(HttpClient).post(`${this.HUBSPOT_CONTROLLER_URL}/visitor-identification`, httpOptions).toPromise()) as IHubspotVisitorIdentification;
  }

  private onConversationStarted(): void {
    try {
      const _hsq = (this._window._hsq = this._window._hsq || []);
      _hsq.push([
        'identify',
        {
          email: this._userInfo.email,
          user_profile_in_kenjo: this.PROFILE_MAP[this._userInfo.profileKey],
          kenjo_orgid: this._orgInfo?._id ?? ''
        }
      ]);
      _hsq.push(['trackPageView']);
    } catch (error: any) {
      this.injector.get(ErrorManagerService).handleRawErrorSilently(`Error identifying contact '${this._userInfo?.email}' in Hubspot API: ${error?.message}`, PrivateHubspotService.name, 'identifyHubspotUser');
    }
  }

  // vvvvvvvvvvvvvvvvvvvv DEPRECATED METHODS vvvvvvvvvvvvvvvvvvvv //
  /**
   * This process is now done in the backend, so this method is deprecated
   * @param contactData from create-free-trial-account-with-form, retrieve body.client.hubspot
   * @param hrPlan
   * @param recruitingPlan
   * @param orgosAccountCreatedSuccessfully
   * @returns
   */
  public submitContact(contactData: ISubmitContactData, hrPlan: string = '', recruitingPlan: string = '', orgosAccountCreatedSuccessfully: boolean = true): void {
    if (check.not.assigned(environment.HUBSPOT_PORTAL_ID) || check.emptyString(environment.HUBSPOT_PORTAL_ID) || check.not.assigned(environment.HUBSPOT_FORM_ID) || check.emptyString(environment.HUBSPOT_FORM_ID)) {
      return;
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('sign-up-page')
      .then((pageTranslation) => {
        const legalConsentText = `${pageTranslation.termsAndConditionsTextPart1} ${pageTranslation.termsAndConditionsTextPart2} ${pageTranslation.termsAndConditionsTextPart3} ${pageTranslation.termsAndConditionsTextPart4} ${pageTranslation.termsAndConditionsTextPart5}`;
        this.submitContactData(contactData, hrPlan, recruitingPlan, orgosAccountCreatedSuccessfully, legalConsentText);
      })
      .catch(() => {
        this.submitContactData(contactData, hrPlan, recruitingPlan, orgosAccountCreatedSuccessfully);
      });
  }

  private submitContactData(contactData: ISubmitContactData, hrPlan: string, recruitingPlan: string, orgosAccountCreatedSuccessfully: boolean, legalConsentText: string = ''): void {
    const HUBSPOT_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${environment.HUBSPOT_PORTAL_ID}/${environment.HUBSPOT_FORM_ID}`;

    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    const httpOptions = {
      headers: httpHeaders
    };

    const allCookies = this.document.cookie.split(';');
    const cookiesMap = _.fromPairs(
      allCookies.map((iCookie) => {
        return iCookie.split('=');
      })
    );

    let chosenPlan = 'Suite free';
    if (check.assigned(hrPlan) && check.nonEmptyString(hrPlan) && (check.not.assigned(recruitingPlan) || check.emptyString(recruitingPlan))) {
      chosenPlan = hrPlan;
    } else if ((check.not.assigned(hrPlan) || check.emptyString(hrPlan)) && check.assigned(recruitingPlan) && check.nonEmptyString(recruitingPlan)) {
      chosenPlan = recruitingPlan;
    } else if (check.assigned(hrPlan) && check.nonEmptyString(hrPlan) && check.assigned(recruitingPlan) && check.nonEmptyString(recruitingPlan)) {
      chosenPlan = `${hrPlan} & ${recruitingPlan}`;
    }

    const submitData: any = {
      skipValidation: true,
      fields: [
        {
          name: 'company',
          value: contactData.companyName
        },
        {
          name: 'what_is_your_role_',
          value: contactData.what_is_your_role_
        },
        {
          name: 'firstname',
          value: contactData.firstName
        },
        {
          name: 'lastname',
          value: contactData.lastName
        },
        {
          name: 'email',
          value: contactData.email
        },
        {
          name: 'hs_language',
          value: this.injector.get(InternationalizationService).getLanguage()
        },
        {
          name: 'kenjo_account_type',
          value: contactData.kenjo_account_type
        },
        {
          name: 'kenjo_orgid',
          value: contactData.kenjo_orgid
        },
        {
          name: 'chosen_plan_sign_up',
          value: chosenPlan
        },
        {
          name: 'orgos_account_created_successfully',
          value: orgosAccountCreatedSuccessfully
        },
        {
          name: 'signup_date',
          value: moment.utc().format('YYYY-MM-DD')
        }
      ],
      context: {
        pageUri: this.document.location.href,
        pageName: 'Kenjo App Sign Up'
      },
      legalConsentOptions: {
        legitimateInterest: {
          value: true,
          subscriptionTypeId: environment.HUBSPOT_SUBSCRIPTION_TYPE_ID,
          legalBasis: 'CUSTOMER',
          text: legalConsentText
        }
      }
    };

    if (check.assigned(cookiesMap.hubspotutk)) {
      submitData.context.hutk = cookiesMap.hubspotutk;
    }

    this.injector
      .get(HttpClient)
      .post(HUBSPOT_URL, submitData, httpOptions)
      .toPromise()
      .then(() => {
        // Do nothing
      })
      .catch((originalError) => {
        const error = new OrgosError(originalError, ErrorCodes.THIRD_PARTY_SERVICE_ERROR, PrivateHubspotService.name, 'submitContact');
        error.message = `CONTACT NOT CREATED IN HUBSPOT: ${contactData.firstName} ${contactData.lastName} (${contactData.email}) at ${contactData.companyName}`;

        this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);
      });
  }

  public getContactInfo(email: string): Promise<IHubspotContactData> {
    return new Promise<IHubspotContactData>((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .get(`${this.HUBSPOT_MICROSERVICE_URL}/contact/${email}`)
        .toPromise()
        .then((contactData: IHubspotContactData) => {
          resolve(contactData);
        })
        .catch((originalError) => {
          const error = new OrgosError(originalError, ErrorCodes.THIRD_PARTY_SERVICE_ERROR, PrivateHubspotService.name, 'getContactInfo');
          error.message = 'Error getting contact information from Hubspot';

          reject(this.injector.get(ErrorManagerService).handleParsedErrorSilently(error));
        });
    });
  }
  // ^^^^^^^^^^^^^ DEPRECATED METHODS ^^^^^^^^^^^^^ //
}

// Outdated, check confluence for more info
export interface ISubmitContactData {
  companyName: string;
  what_is_your_role_: string;
  firstName: string;
  lastName: string;
  email: string;
  kenjo_account_type: string;
  kenjo_orgid: string;
}

export interface IHubspotContactData {
  companyName: string;
  what_is_your_role_: string;
  firstName: string;
  lastName: string;
  workEmail: string;
}

interface IHubspotConversationWidget {
  remove(): void;
  load(): void;
  refresh(): void;
}

interface IHubspotConversationWidgetClearOptions {
  resetWidget: boolean;
}

interface IHubspotConversation {
  widget: Partial<IHubspotConversationWidget>;
  clear(options?: IHubspotConversationWidgetClearOptions): void;
  off();
  on(eventName: string, callback: any);
}

interface IHubspotVisitorIdentification {
  token: string;
}
