import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { InputSearchShiftPlanComponent } from '@app/cloud-features/shift-plan/components/input-search-shift-plan/input-search-shift-plan.component';
import { SettingsShiftPlanSelect } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-select/settings-shift-plan-select';
import {
  IShiftPlanRoleSettingsModel,
  ShiftPlanRoleSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-role.service';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { SHIFT_PLAN_ACTIVATION_STATUS_FINISHED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-shift-plan-employee-roles',
  templateUrl: 'shift-plan-employee-roles.component.html',
  styleUrls: ['shift-plan-employee-roles.component.scss'],
})
export class ShiftPlanEmployeeRoles implements OnInit {
  @Input() activateShiftPlanTranslation: any;
  @Input() shiftPlanSettingsTranslations: any;
  @Input() userWork: GenericSimpleModel;
  @Input() progressIndex: number;
  @Output() changeIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() rolesIsCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(SettingsShiftPlanSelect) selectComponent: SettingsShiftPlanSelect;
  @ViewChild(InputSearchShiftPlanComponent) searchComponent: InputSearchShiftPlanComponent;

  allRoles: Array<IRoleEmployeeModel> = [];
  selectedRoles: Array<any> = [];
  selectedRolesInitialValues: Array<any> = [];
  filteredRoles: Array<IShiftPlanRoleSettingsModel> = [];

  dataHasChanged: boolean = false;
  formerRolesData: Array<string> = [];

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.fetchAllRoles();
    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.formerRolesData = this.userWork.data.roles.slice();
    }
  }

  private async fetchAllRoles() {
    try {
      this.allRoles = await this.injector.get(ShiftPlanRoleSettingsService).getRoles();
      this.registerRolesData();
      this.checkIfFieldsAreCorrect();
    } catch {
      //nothing
    }
  }

  registerRolesData() {
    this.selectedRoles = this.userWork.data.roles ? this.userWork.data.roles : [];
    if (check.assigned(this.userWork.data.roles) && check.not.emptyArray(this.userWork.data.roles)) {
      this.allRoles.forEach((iRole) => {
        iRole.selected = this.userWork.data.roles.includes(iRole._id);
      });
    }
    this.selectedRolesInitialValues = this.selectedRoles;
    this.filteredRoles = this.allRoles;
  }

  changeSelectedRoles(selection: any) {
    this.selectedRoles = [];
    selection.forEach((iRole) => {
      if (iRole.selected) {
        this.selectedRoles.push(iRole._id);
      }
    });

    const checkIfRolesAreTheSame = _.isEmpty(_.xor(this.selectedRolesInitialValues, this.selectedRoles));
    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataHasChanged = !checkIfRolesAreTheSame;
    }
    this.checkIfFieldsAreCorrect();
  }

  nextStep() {
    this.saveData();
    this.progressIndex++;
    this.changeIndex.emit(this.progressIndex);
  }

  previousStep() {
    this.saveData();
    this.progressIndex--;
    this.changeIndex.emit(this.progressIndex);
  }

  filterAll(filteredList: any) {
    this.filteredRoles = filteredList;
  }

  checkIfFieldsAreCorrect() {
    const fieldsAreCorrect = check.not.emptyArray(this.selectedRoles);
    this.rolesIsCompleted.emit(fieldsAreCorrect);
  }

  async saveData() {
    this.userWork.data.roles = [];
    this.userWork.data.roles = this.selectedRoles;
    await this.injector.get(UserWorkService).updateById(this.userWork.data._id, { roles: this.selectedRoles });

    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataSaved.emit(true);
    }
  }

  cancelChanges() {
    this.userWork.data.roles = this.formerRolesData;
    this.registerRolesData();
    this.checkIfFieldsAreCorrect();

    // Force child components to update
    this.selectComponent.data = this.filteredRoles;
    this.selectComponent.checkCounter();
    this.searchComponent.searchTerm = '';
  }

  saveSnackbar() {
    this.injector.get(MatLegacySnackBar).open(this.activateShiftPlanTranslation.changesSuccessful, 'OK', {
      duration: 5000,
    });
  }
}

export interface IRoleEmployeeModel {
  _id?: string;
  name: string;
  colour: string;
  selected?: boolean;
}
