<div *ngIf="!alternative" class="pcc-card kenjo-p-20px">
  <div>
    <div class="pcc-top kenjo-pb-20px">
      <div class="kenjo-font-size-25px kenjo-font-color-text-000000 kenjo-text-transform-uppercase pcc-plan-title">{{ translations.plans[plan.key]?.title }}</div>
      <div *ngIf="showPrice" class="kenjo-font-color-text-000000 pcc-price kenjo-font-weight-bold pcc-plan-price">{{ showYearly ? yearlyPrice : monthlyPrice }}</div>
      <div *ngIf="!translations.plans[plan.key]?.description" class="kenjo-font-color-light-text-757575">{{ showYearly ? translations.employeeMonthlyOption : translations.employeeMonthlyOption }}</div>
      <orgos-action (click)="onCalculatePrice()" *ngIf="!translations.plans[plan.key]?.description" class="kenjo-mt-10px">{{ translations.calculatePrice }}</orgos-action>
      <div *ngIf="translations.plans[plan.key]?.description" class="kenjo-text-align-center kenjo-font-color-light-text-757575">{{ translations.plans[plan.key]?.description }}</div>
    </div>

    <div class="pcc-features kenjo-pt-20px">
      <div *ngFor="let iFeature of plan.features" class="pcc-feature">
        <img class="kenjo-mr-10px sip-single-sign-on-img" src="/assets/images/tick.svg" />
        <div *ngIf="iFeature !== 'whistleblower'" class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.cardFeatures[iFeature] }}</div>
        <div *ngIf="iFeature === 'whistleblower'" class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.cardFeatures[iFeature] + ' (' + translations.isComing + ')' }}</div>
      </div>
    </div>
  </div>

  <div class="pcc-button">
    <orgos-button-raised *ngIf="!isCurrentPlan && !contactUs && !requestDemo" (click)="onOpenCheckout()">{{ translations.upgradeNow }}</orgos-button-raised>
    <orgos-button-raised *ngIf="!isCurrentPlan && requestDemo" (click)="onOpenLink()">{{ translations.requestDemo }}</orgos-button-raised>
    <orgos-button-raised *ngIf="!isCurrentPlan && contactUs" (click)="onOpenLink()">{{ translations.contactUs }}</orgos-button-raised>
    <orgos-button-raised *ngIf="isCurrentPlan" disabled="true">{{ translations.yourPlan }}</orgos-button-raised>
  </div>
</div>

<div *ngIf="alternative" class="pcc-card-alternative kenjo-p-20px">
  <div>
    <div class="pcc-top kenjo-pb-20px">
      <div class="pcc-most-popular kenjo-font-color-light-text-757575">{{ translations.mostPopular }}</div>
      <div class="kenjo-font-size-25px kenjo-font-color-text-000000 kenjo-text-transform-uppercase pcc-plan-title">{{ translations.plans[plan.key]?.title }}</div>
      <div class="kenjo-font-color-text-000000 pcc-price kenjo-font-weight-bold pcc-plan-price">{{ showYearly ? yearlyPrice : monthlyPrice }}</div>
      <div class="kenjo-font-color-light-text-757575">{{ translations.employeeMonthlyOption }}</div>
      <orgos-action (click)="onCalculatePrice()" class="kenjo-mt-10px">{{ translations.calculatePrice }}</orgos-action>
    </div>

    <div class="pcc-features kenjo-pt-20px">
      <div *ngFor="let iFeature of plan.features" class="pcc-feature">
        <img class="kenjo-mr-10px sip-single-sign-on-img" src="/assets/images/tick.svg" />
        <div *ngIf="iFeature !== 'whistleblower'" class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.cardFeatures[iFeature] }}</div>
        <div *ngIf="iFeature === 'whistleblower'" class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.cardFeatures[iFeature] + ' (' + translations.isComing + ')' }}</div>
      </div>
    </div>
  </div>

  <div class="pcc-button kenjo-mt-30px">
    <orgos-button-raised *ngIf="!isCurrentPlan && !contactUs" color="Upgrade" (click)="onOpenCheckout()">{{ translations.upgradeNow }}</orgos-button-raised>
    <orgos-button-raised *ngIf="!isCurrentPlan && contactUs" (click)="onOpenLink()">{{ translations.contactUs }}</orgos-button-raised>
    <orgos-button-raised *ngIf="isCurrentPlan" disabled="true">{{ translations.yourPlan }}</orgos-button-raised>
  </div>
</div>
