import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ITimeOffStatus } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { TIME_OFF_STATUS_CYCLE_STATUS_SCHEDULED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class TimeOffStatusService {
  private TIME_OFF_STATUS_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/time-off-status-db`;

  constructor(private injector: Injector, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  async assignUserPolicies(assignCalls: Array<ITimeOffAssignmentModel>): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.TIME_OFF_STATUS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, TimeOffStatusService.name, 'manageTimeOffStatusAssignments');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      const body = { assignments: assignCalls };
      return await this.http.post<any>(`${this.TIME_OFF_STATUS_MICROSERVICE_URL}/assignments`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffStatusService.name, 'manageTimeOffStatusAssignments');
    }
  }

  async getUserScheduledAssignments(userId: string): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.TIME_OFF_STATUS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, TimeOffStatusService.name, 'getUserScheduledAssignments');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      const body = { cycleStatus: TIME_OFF_STATUS_CYCLE_STATUS_SCHEDULED, _userId: userId };
      return await this.http.post<any>(`${this.TIME_OFF_STATUS_MICROSERVICE_URL}/find`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffStatusService.name, 'getUserScheduledAssignments');
    }
  }

  async adjustCarryOver(userId: string, timeOffTypeId: string, newCarryOver: number, description: string): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.TIME_OFF_STATUS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, TimeOffStatusService.name, 'adjustCarryOver');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      const body: any = { userId, timeOffTypeId, newCarryOver };
      if (check.assigned(description)) {
        body.description = description;
      }
      return await this.http.put(`${this.TIME_OFF_STATUS_MICROSERVICE_URL}/adjust-carry-over`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffStatusService.name, 'adjustCarryOver');
    }
  }

  async adjustTaken(userId: string, timeOffTypeId: string, newTaken: number, description: string): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.TIME_OFF_STATUS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, TimeOffStatusService.name, 'adjustTaken');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      const body: any = { userId, timeOffTypeId, newTaken };
      if (check.assigned(description)) {
        body.description = description;
      }
      return await this.http.put(`${this.TIME_OFF_STATUS_MICROSERVICE_URL}/adjust-taken`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffStatusService.name, 'adjustTaken');
    }
  }

  async adjustBalance(userId: string, timeOffTypeId: string, newBalance: number, description: string): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.TIME_OFF_STATUS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, TimeOffStatusService.name, 'adjustBalance');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      const body: any = { userId, timeOffTypeId, newBalance };
      if (check.assigned(description)) {
        body.description = description;
      }
      return await this.http.put(`${this.TIME_OFF_STATUS_MICROSERVICE_URL}/adjust-balance`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffStatusService.name, 'adjustBalance');
    }
  }

  async giveCompensation(giveCompensationParams: Array<IGiveCompensationParams>): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.TIME_OFF_STATUS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, TimeOffStatusService.name, 'giveCompensation');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      const body = {
        userCompensations: giveCompensationParams
      };
      return await this.http.put(`${this.TIME_OFF_STATUS_MICROSERVICE_URL}/give-compensation`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffStatusService.name, 'giveCompensation');
    }
  }

  async getArchivedCycle(previousStatusId: string): Promise<ITimeOffStatus | undefined> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(this.TIME_OFF_STATUS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, TimeOffStatusService.name, 'getArchivedCycle');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = { headers: httpHeaders };
      const findQuery = { _id: previousStatusId };
      const [archivedStatus] = await this.http.post<Array<ITimeOffStatus>>(`${this.TIME_OFF_STATUS_MICROSERVICE_URL}/find`, findQuery, httpOptions).toPromise();
      return archivedStatus;
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffStatusService.name, 'getArchivedCycle');
    }
  }
}

export interface ITimeOffAssignmentsModel {
  assignments: Array<ITimeOffAssignmentModel>;
}

export interface ITimeOffAssignmentModel {
  policyId: string;
  timeOffTypeId: string;
  userId?: string;
  action: string;
  assignDate?: Date;
  balance?: number;
}

export interface IGiveCompensationParams {
  userId: string;
  timeOffTypeId: string;
  compensation: number;
  description: string;
}
