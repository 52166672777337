import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { LAYOUTS, VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { getEmptyFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { getDaysOfCurrentMonth, getDaysOfCurrentWeek } from '@app/cloud-features/shift-plan/helpers/shiftplan-schedule.helper';
import { IShiftPlanGeneralSettingsModel } from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { ShiftCardFilters, ShiftPlanCardService } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import {
  IShiftplanExportPDFOptions,
  ShiftPlanGeneratePDFService,
} from '@app/cloud-features/shift-plan/services/shift-plan-generate-pdf.service';
import {
  IPublicHolidays,
  ShiftplanPublicHolidaysService,
} from '@app/cloud-features/shift-plan/services/shift-plan-public-holidays.service';
import { ITimeOffRequestSchedule, ShiftPlanTimeOffService } from '@app/cloud-features/shift-plan/services/shift-plan-time-off.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shift-plan-pdf-template',
  templateUrl: 'shift-plan-pdf-template.component.html',
  styleUrls: ['shift-plan-pdf-template.component.scss'],
})
export class ShiftPlanTemplatePDFComponent implements OnInit, AfterViewChecked {
  pdfContent: HTMLElement;
  timeoutExport: NodeJS.Timeout;
  currentDays: Array<moment.Moment>;
  shifts;
  timeOffs: ITimeOffRequestSchedule = {};
  holidays: Array<Array<IPublicHolidays>> = [];
  containerOptions = { contentHeightFix: 43, scalePDF: 2, hLandscape: 645, hPortrait: 1000 };
  orientation = { LANDSCAPE: 'landscape', PORTRAIT: 'portrait' };
  views = { ...VIEWS };
  layouts = { ...LAYOUTS };
  dataLoaded: boolean = false;
  colorConstants = userColorConstants;
  @Input() options: IShiftplanExportPDFOptions;
  @Input() filters: ShiftCardFilters = { ...getEmptyFilters() };
  @Input() users;
  @Input() generalSettings: IShiftPlanGeneralSettingsModel;
  @Input() componentTranslations: { [key: string]: string };

  @Output() exported: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('content') content: ElementRef;
  @ViewChild('snackbarError') snackbarError;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.fetchDataToPrint();
  }

  ngAfterViewChecked() {
    if (this.pdfContent) {
      return;
    }
    this.downloadPDF();
    this.cdr.detectChanges();
  }

  async fetchDataToPrint() {
    try {
      this.calculateCurrentDays();
      await Promise.all([this.getShifts(), this.getTimeOffs(), this.getPublicHolidays()]);
      this.dataLoaded = true;
    } catch {
      this.dataLoaded = false;
      this.showFailedSnackbar();
      this.exported.emit();
    }
  }

  async getShifts() {
    try {
      const { selectedRange, listView } = this.options;
      this.shifts = await this.injector
        .get(ShiftPlanCardService)
        .getShiftCards(selectedRange.from, selectedRange.to, this.filters, listView);
    } catch {
      this.showFailedSnackbar();
      this.exported.emit();
    }
  }

  async getTimeOffs() {
    if (!this.generalSettings.showTimeOff) {
      return;
    }

    const { selectedRange, frequency } = this.options;
    this.timeOffs = await this.injector.get(ShiftPlanTimeOffService).getTimeOffRequests(
      selectedRange.from.toDate(),
      selectedRange.to.toDate(),
      this.users.map((user) => user._id),
      frequency
    );
  }

  async getPublicHolidays() {
    if (this.generalSettings?.showPublicHolidays === false) {
      return;
    }

    const { selectedRange } = this.options;
    const { publicHolidays } = await this.injector
      .get(ShiftplanPublicHolidaysService)
      .getPublicHoliday({ fromDate: selectedRange.from.toDate(), toDate: selectedRange.to.toDate() });
    this.holidays = publicHolidays;
  }

  async downloadPDF() {
    try {
      this.pdfContent = this.content?.nativeElement;
      if (!this.pdfContent) {
        return;
      }
      const splitPageParams = this.calculatePageDistribution();
      this.timeoutExport = setTimeout(async () => {
        const pdfGenerated = await this.injector
          .get(ShiftPlanGeneratePDFService)
          .exportPDF(this.pdfContent, this.options, splitPageParams, this.componentTranslations);
        if (pdfGenerated) {
          this.logGeneratePDFAmplitudeEvents();
        } else {
          this.showFailedSnackbar();
        }
        this.exported.emit();
      }, 100);
    } catch {
      this.showFailedSnackbar();
      this.exported.emit();
    }
  }

  calculatePageDistribution() {
    const containerHeight = (this.content.nativeElement.clientHeight - 43) * this.containerOptions.scalePDF;
    const pxPageHeight =
      (this.options.orientation === this.orientation.LANDSCAPE ? this.containerOptions.hLandscape : this.containerOptions.hPortrait) *
      this.containerOptions.scalePDF;
    const nPages = Math.ceil(containerHeight / pxPageHeight);
    const positionsElements = document.querySelectorAll('.sppt-position');
    const positions = Array.from(positionsElements).map((element: any) => element.offsetTop * this.containerOptions.scalePDF); // scale applied: 2
    return { positions, pxPageHeight, nPages };
  }

  calculateCurrentDays() {
    const startDate = this.options.selectedRange.from;
    this.currentDays =
      this.options.frequency === 'month'
        ? getDaysOfCurrentMonth(startDate).daysOfThisMonth
        : getDaysOfCurrentWeek(startDate).daysOfCurrentWeek;
  }

  logGeneratePDFAmplitudeEvents() {
    const eventProperties = { category: 'Shiftplan', subcategory1: 'Actions', subcategory2: 'Generate PDF' };
    const layout = this.options.listView === 'user' ? 'employee' : 'role';
    this.injector.get(PrivateAmplitudeService).logEvent(`generate ${layout} pdf`, eventProperties);

    if (this.options.shiftType === 'both') {
      this.injector.get(PrivateAmplitudeService).logEvent(`combo shift type pdf`, eventProperties);
    }

    if (this.options.shiftStatus === 'both') {
      this.injector.get(PrivateAmplitudeService).logEvent(`combo shift status pdf`, eventProperties);
    }

    if (this.options.frequency === this.views.MONTH) {
      this.injector.get(PrivateAmplitudeService).logEvent(`Monthly shiftplan pdf created`, eventProperties);
    }
  }

  showFailedSnackbar() {
    this.injector.get(MatLegacySnackBar).openFromTemplate(this.snackbarError, {
      duration: 5000,
    });
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutExport);
  }
}
