import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class PeopleDirectoryService {
  private DATA_ENGINE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/data-engine`;

  constructor(private injector: Injector) {}

  getGridDataPeopleDirectory(queryOptions: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DATA_ENGINE_URL}`, ErrorCodes.UNAUTHORIZED, PeopleDirectoryService.name, 'getGridDataPeopleDirectory');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const groupBy = queryOptions?.groupBy ? queryOptions.groupBy : 'firstName';

      this.injector
        .get(HttpClient)
        .post(`${this.DATA_ENGINE_URL}/people-directory-grid/${groupBy}`, queryOptions, httpOptions)
        .toPromise()
        .then((users) => {
          resolve(users);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PeopleDirectoryService.name, 'getGridDataPeopleDirectory'));
        });
    });
  }

  getListDataPeopleDirectory(queryOptions: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DATA_ENGINE_URL}`, ErrorCodes.UNAUTHORIZED, PeopleDirectoryService.name, 'getListDataPeopleDirectory');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .post(`${this.DATA_ENGINE_URL}/people-directory-list`, queryOptions, httpOptions)
        .toPromise()
        .then((users) => {
          resolve(users);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PeopleDirectoryService.name, 'getListDataPeopleDirectory'));
        });
    });
  }

  getFiltersPeopleDirectory(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.DATA_ENGINE_URL}`, ErrorCodes.UNAUTHORIZED, PeopleDirectoryService.name, 'getFiltersPeopleDirectory');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get(`${this.DATA_ENGINE_URL}/people-directory-list/filters`, httpOptions)
        .toPromise()
        .then((filters) => {
          resolve(filters);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PeopleDirectoryService.name, 'getFiltersPeopleDirectory'));
        });
    });
  }
}

export interface IPeopleDirectoryResponse {
  currentPage: number;
  pages: number;
  records: Array<IPeopleDirectory>;
  totalOfRecords: number;
}

// Feel free to add more (optional?) fields
export interface IPeopleDirectory {
  _id: string;
  areaNames: Array<any>;
  companyName: string;
  departmentName?: string;
  displayName: string;
  jobTitle?: string;
  manager?: string;
  status: string;
  teamNames: Array<any>;
  photo: any;
}
