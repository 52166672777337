import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyRadioButton } from '@angular/material/legacy-radio';

import { IFilterOption } from '../filter-bar/filter-bar.component';

@Component({
  selector: 'kenjo-filter-bar-field',
  templateUrl: './filter-bar-field.component.html',
  styleUrls: ['./filter-bar-field.component.scss'],
})
export class FilterBarFieldComponent implements OnInit {
  @Input() label: string = '';
  @Input() optionKey: string = '';
  @Input() pickerType: 'single' | 'multiple' = 'multiple';
  @Input() noMatchingEmployeesLabel: string;
  @Input() filterDifference: { [key: string]: boolean };

  @Input() options: Array<IFilterOption> = [];
  @Input() filtersApplied: Array<IFilterOption> = [];
  @Input() showAvatar: boolean = false;
  @Input() hasBorder: boolean = true;
  @Input() hasSearch: boolean = false;
  @Input() buttonTranslations: { apply: string; reset: string };
  @Input() showApplyFilterButton: boolean = false;
  @Output() toggleFilterEvent = new EventEmitter<{ optionKey: string; optionIndex: number }>();
  @Output() clearFilterEvent = new EventEmitter<string>();
  @Output() applyFilterEvent = new EventEmitter<{ optionKey: string; options: Array<IFilterOption> }>();
  @ViewChildren(MatLegacyRadioButton) radioButtons: QueryList<MatLegacyRadioButton>;

  matchedOptions: Array<IFilterOption> = [];
  OPTION_TYPE_EMPLOYEES: string = 'employees';

  get optionsActiveCount(): number {
    return this.countActiveOptions(this.options);
  }

  ngOnInit() {
    this.handleClearClick = this.handleClearClick.bind(this);
    this.matchedOptions = this.options;
    if (this.showApplyFilterButton) {
      this.buttonTranslations.reset =
        this.buttonTranslations.reset.charAt(0).toUpperCase() + this.buttonTranslations.reset.slice(1).toLowerCase();
    }
  }

  ngOnChanges() {
    this.matchedOptions = this.options;
  }

  handleValueChange(optionIndex: number, event: Event) {
    if (event?.stopPropagation !== undefined) {
      event.stopPropagation();
    }
    this.toggleFilter(optionIndex);
  }

  handleValueChangeRadioButton(optionIndex: number, event: Event) {
    if (event?.stopPropagation !== undefined) {
      event.stopPropagation();
    }
    this.radioButtons.get(optionIndex).checked = true;
    this.toggleFilter(optionIndex);
  }

  private toggleFilter(optionIndex: number) {
    let selectedOption: IFilterOption;
    let finalOptionIndex: number = optionIndex;
    if (this.optionKey === this.OPTION_TYPE_EMPLOYEES) {
      selectedOption = this.matchedOptions[optionIndex];
      finalOptionIndex = this.options.findIndex((option) => selectedOption.name.toLocaleLowerCase() === option.name.toLowerCase());
    }

    this.toggleFilterEvent.emit({ optionKey: this.optionKey, optionIndex: finalOptionIndex });
  }

  handleClearClick(event: Event) {
    if (this.pickerType === 'single') {
      this.radioButtons.forEach((iButton) => {
        iButton.checked = false;
      });
    }

    event.stopPropagation();
    this.clearFilterEvent.emit(this.optionKey);
  }

  search(term: string) {
    this.matchedOptions = this.options.filter((option) => option.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
  }

  private countActiveOptions(options: Array<IFilterOption>) {
    return options.reduce((prev, curr) => {
      return prev + (curr.active ? 1 : 0);
    }, 0);
  }

  closeFilterField() {
    if (this.showApplyFilterButton) {
      const filtersSelected = this.options.filter((element) => element.active);
      const areArraysEqual =
        this.filtersApplied[this.optionKey].length === filtersSelected.length &&
        this.filtersApplied[this.optionKey].every((item) => filtersSelected.some((element) => element === item));

      if (!areArraysEqual) {
        this.options.forEach((element) => {
          if (this.filtersApplied[this.optionKey].some((item) => item === element)) {
            if (!element.active) {
              this.toggleFilter(this.matchedOptions.indexOf(element));
            }
          } else if (element.active) {
            this.toggleFilter(this.matchedOptions.indexOf(element));
          }
        });
      }
    }
  }

  applyFilter() {
    this.applyFilterEvent.emit({ optionKey: this.optionKey, options: this.options });
  }
}
