import { CoreFeaturesPipeModule } from '@app/common-components/core-features-pipe/core-features-pipe.module';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { PermissionsPipe } from '@app/common-components/permissions-pipe/components/permissions-pipe.pipe';
import { PermissionsPipeModule } from '@app/common-components/permissions-pipe/permissions-pipe.module';
import { RecruitingActivityWidgetComponent } from '@app/common-components/recruiting/recruiting-activity-widget/recruiting-activity-widget.component';
import { RecruitingCandidateHeaderComponent } from '@app/common-components/recruiting/recruiting-candidate-header/recruiting-candidate-header.component';
import { RecruitingDiscussionCommentsSettingsComponent } from '@app/common-components/recruiting/recruiting-discussion-comments-settings/recruiting-discussion-comments-settings.component';
import { RecruitingDiscussionCommentsComponent } from '@app/common-components/recruiting/recruiting-discussion-comments/recruiting-discussion-comments.component';
import { RecruitingDiscussionWidgetComponent } from '@app/common-components/recruiting/recruiting-discussion-widget/recruiting-discussion-widget.component';
import { RecruitingScoreResultsLineComponent } from '@app/common-components/recruiting/recruiting-score-results-line/recruiting-score-results-line.component';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { RecruitingDialogsModule } from '@app/common-dialogs/recruiting/recruiting-dialogs.module';

export const EXTERNAL_MODULES = [RecruitingDialogsModule, IconModule, TruncateTextModule, PermissionsPipeModule, CoreFeaturesPipeModule];

export const COMPONENTS = [RecruitingCandidateHeaderComponent, RecruitingScoreResultsLineComponent, RecruitingDiscussionWidgetComponent, RecruitingActivityWidgetComponent, RecruitingDiscussionCommentsComponent, RecruitingDiscussionCommentsSettingsComponent];

export const PROVIDERS = [PermissionsPipe];
