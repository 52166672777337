<div class="sdp-container" [style.width]="width">
  <div>
    <div class="sdp-select-container" [matMenuTriggerFor]="itemsMenu" #menuTrigger="matMenuTrigger">
      <div *ngIf="componentKey === 'policy-item'" [style.width]="width">
        <div *ngTemplateOutlet="attendancePolicyTemplate"></div>
      </div>
      <div *ngIf="componentKey === 'category-item'" [style.width]="width">
        <div *ngTemplateOutlet="categoryTemplate"></div>
      </div>
      <div></div>
    </div>
  </div>
  <mat-menu class="sdp-items" #itemsMenu="matMenu">
    <kenjo-search-item-dropdown-custom
      [componentKey]="componentKey"
      [width]="width"
      (onSelectOption)="onSelectOption($event)"
      [placeholder]="searchPlaceholder"
      [selectableOptions]="options"
      [emptyOptionsText]="emptyOptionsText"
    ></kenjo-search-item-dropdown-custom>
  </mat-menu>
</div>

<ng-template #attendancePolicyTemplate>
  <mat-form-field class="sdp-form-field">
    <mat-label kenjoRemoveAriaOwns>{{ label }}</mat-label>
    <mat-select [panelClass]="'sdp-select'" [(value)]="item.name" [required]="required">
      <mat-option #optionInput [value]="item.name"> {{ item.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #categoryTemplate>
  <div *ngIf="!item.name" [style.width]="width" class="sdp-category-container">
    <p class="kenjo-pl-10px">{{ label }}</p>
    <kenjo-icon size="24">arrow_drop_down</kenjo-icon>
  </div>
  <div *ngIf="item.name" [style.width]="width" class="sdp-select-category-container">
    <div class="kenjo-pl-10px kenjo-flex-horizontal kenjo-flex-gap-5px">
      <div class="sdp-color-container kenjo-mr-10px" [style.background-color]="colors[item.color]"></div>
      <div class="sdp-title-container kenjo-flex-gap-10px">{{ item.name }}</div>
    </div>
    <div class="kenjo-flex-center-content">
      <kenjo-icon size="24">arrow_drop_down</kenjo-icon>
      <kenjo-icon (click)="onCleanValue()" class="kenjo-font-color-archived-8f8f8f kenjo-pr-5px" size="20">clear</kenjo-icon>
    </div>
  </div>
</ng-template>
