<orgos-container aspect="card" *ngIf="!loadingPage && i18n?.page">
  <orgos-column-container class="sbp-main-container">
    <orgos-column size="0" class="sbp-menu kenjo-p-30px kenjo-border-right">
      <orgos-settings-billing-menu
        [menuTranslation]="i18n.billingPage.menu"
        [selectedOption]="1"
      ></orgos-settings-billing-menu>
    </orgos-column>

    <orgos-column
      size="1"
      class="sbup-empty kenjo-mt-50px"
      *ngIf="isMultiBilling && !errorRetrievingInfo"
    >
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-14px">{{i18n.page.multiCompanyActivated}}</div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">
        {{i18n.page.multiCompanyActivatedMoreInfo}}
      </div>
    </orgos-column>

    <orgos-column size="1" class="sbup-empty kenjo-mt-50px" *ngIf="errorRetrievingInfo">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-14px">{{i18n.page.notAvailableTitle}}</div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">
        {{i18n.page.notAvailableMessage}}
      </div>
    </orgos-column>

    <orgos-column
      size="1"
      class="kenjo-p-30px"
      *ngIf="!isMultiBilling && !errorRetrievingInfo && plansInfo"
    >
      <div class="sbup-title-container">
        <div class="kenjo-font-size-30px">{{i18n.page.upgradePlanTitle}}</div>
        <div class="kenjo-font-size-16px kenjo-mt-15px">{{i18n.page.chooseSubscription}}</div>

        <div class="sbup-time">
          <div class="sbup-discount kenjo-font-color-white-ffffff kenjo-font-weight-bold">
            {{ i18n.page.save }} 10%
          </div>
          <mat-button-toggle-group
            #toggleGroup="matButtonToggleGroup"
            [value]="showYearly ? 'yearly' : 'monthly'"
            class="kenjo-mt-30px sbup-time-options"
            (change)="showYearly = !showYearly"
          >
            <mat-button-toggle
              class="sbup-time-option"
              [class.kenjo-font-color-light-text-757575]="!showYearly"
              value="yearly"
            >
              {{i18n.page.yearlyOption}}
            </mat-button-toggle>
            <mat-button-toggle
              class="sbup-time-option"
              [class.kenjo-font-color-light-text-757575]="showYearly"
              value="monthly"
            >
              {{i18n.page.monthlyOption}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="sbup-adjust-country kenjo-mt-30px kenjo-p-10px" *ngIf="!isCountrySetInBilling">
        <kenjo-icon [size]="20" class="kenjo-mr-10px kenjo-font-color-warning-ffc107"
          >warning_outline</kenjo-icon
        >
        <div>
          {{i18n.page.adjustCountryMessageStart}}
          <a routerLink="/cloud/settings/billing/info">{{i18n.page.adjustCountryMessageLink}}</a>
        </div>
      </div>

      <div #cards></div>
      <div class="sbup-cards-container kenjo-mt-30px">
        <kenjo-plan-card
          class="sbup-normal-card"
          [plan]="plansInfo.starter"
          [translations]="i18n.page"
          [price]="planPrices.starter"
          [showYearly]="showYearly"
          [isCurrentPlan]="currentPlan.isStarter && !(!hasPaymentSources && currentPlan.isStarter)"
          [contactUs]="currentPlan.isConnect || (currentPlan.isGrowth && isInTrial === false && isCancelled === false)"
          [contactLink]="contactLink"
          (openCheckout)="openCheckout('starter')"
          (calculatePrice)="calculatePrice(calculator)"
        ></kenjo-plan-card>
        <kenjo-plan-card
          [plan]="plansInfo.growth"
          [translations]="i18n.page"
          [alternative]="true"
          [price]="planPrices.growth"
          [showYearly]="showYearly"
          [isCurrentPlan]="currentPlan.isGrowth && !(!hasPaymentSources && currentPlan.isGrowth)"
          [contactUs]="currentPlan.isConnect"
          [contactLink]="contactLink"
          (openCheckout)="openCheckout('growth')"
          (calculatePrice)="calculatePrice(calculator)"
        ></kenjo-plan-card>
        <kenjo-plan-card
          class="sbup-normal-card"
          [plan]="plansInfo.connect"
          [translations]="i18n.page"
          [showPrice]="false"
          [requestDemo]="isInTrial === true || (isInTrial === false && isCancelled === true)"
          [contactUs]="isInTrial === false && isCancelled === false"
          [isCurrentPlan]="false"
          [contactLink]="contactLink"
          (calculatePrice)="calculatePrice(calculator)"
        ></kenjo-plan-card>
      </div>

      <div class="sbup-in-trial kenjo-mt-30px kenjo-p-10px">
        <kenjo-icon [size]="20" class="kenjo-mr-10px kenjo-font-color-success-00b72e"
          >info_outline</kenjo-icon
        >
        <div>
          <div *ngIf="isInTrial">{{ i18n.page.inTrialMessage }}</div>
          <div>{{ i18n.page.useSlidingSelector }}</div>
        </div>
      </div>

      <div class="sbup-features kenjo-mt-50px">
        <div class="sbup-see-features-container">
          <div class="sbup-line"></div>
          <div class="sbup-action-container kenjo-ml-15px kenjo-mr-10px">
            <orgos-action class="kenjo-mr-5px" (click)="onShowFeatures()"
              >{{showFeatures ? i18n.page.hideFeatures : i18n.page.seeFeatures}}</orgos-action
            >
            <mat-icon
              class="sbup-arrow"
              [class.sbup-arrow-opened]="showFeatures"
              (click)="onShowFeatures()"
              >keyboard_arrow_down</mat-icon
            >
          </div>
          <div class="sbup-line"></div>
        </div>

        <div class="kenjo-mv-30px" *ngIf="showFeatures">
          <div *ngFor="let iModule of featuresInfo">
            <div class="sbup-module-row-title kenjo-font-color-text-000000 kenjo-font-size-14px">
              <div *ngIf="iModule.key !== 'compliance'">{{ i18n.page.modules[iModule.key] }}</div>
              <div *ngIf="iModule.key === 'compliance'">
                {{ i18n.page.modules[iModule.key] + ' (' + i18n.page.isComing + ')' }}
              </div>
              <div>{{ i18n.page.plans.starter.title }}</div>
              <div>{{ i18n.page.plans.growth.title }}</div>
              <div>{{ i18n.page.plans.connect.title }}</div>
            </div>
            <div
              *ngFor="let iFeature of iModule.features"
              class="
                kenjo-font-color-light-text-757575 kenjo-font-size-12px
                sbup-module-row-content
              "
            >
              <div>
                <div *ngIf="!iFeature.isNew && !iFeature.isComing">
                  {{ i18n.page.listFeatures[iFeature.key].title }}
                </div>
                <div *ngIf="iFeature.isNew">
                  {{ i18n.page.listFeatures[iFeature.key].title + ' (' + i18n.page.new + ')' }}
                </div>
                <div *ngIf="iFeature.isComing">
                  {{ i18n.page.listFeatures[iFeature.key].title + ' (' + i18n.page.isComing + ')' }}
                </div>
                <kenjo-icon
                  [size]="14"
                  [matTooltip]="i18n.page.listFeatures[iFeature.key].tooltip"
                  matTooltipPosition="above"
                  class="kenjo-cursor-pointer"
                  >help</kenjo-icon
                >
              </div>
              <div>
                <img *ngIf="iFeature.starter === true" src="/assets/images/tick.svg" />
                <img *ngIf="iFeature.starter === false" src="/assets/images/cross.svg" />
                <div *ngIf="iFeature.starter === 'unlimited'">{{ i18n.page.unlimited }}</div>
                <div
                  *ngIf="iFeature.starter !== true && iFeature.starter !== false && iFeature.starter !== 'unlimited'"
                >
                  {{ iFeature.starter }}
                </div>
              </div>
              <div>
                <img *ngIf="iFeature.growth === true" src="/assets/images/tick.svg" />
                <img *ngIf="iFeature.growth === false" src="/assets/images/cross.svg" />
                <div *ngIf="iFeature.growth === 'unlimited'">{{ i18n.page.unlimited }}</div>
                <div
                  *ngIf="iFeature.growth !== true && iFeature.growth !== false && iFeature.growth !== 'unlimited'"
                >
                  {{ iFeature.growth }}
                </div>
              </div>
              <div>
                <img *ngIf="iFeature.connect === true" src="/assets/images/tick.svg" />
                <img *ngIf="iFeature.connect === false" src="/assets/images/cross.svg" />
                <div *ngIf="iFeature.connect === 'unlimited'">{{ i18n.page.unlimited }}</div>
                <div
                  *ngIf="iFeature.connect !== true && iFeature.connect !== false && iFeature.connect !== 'unlimited'"
                >
                  {{ iFeature.connect }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sbup-see-features-container" *ngIf="showFeatures">
          <div class="sbup-line"></div>
          <div class="sbup-action-container kenjo-ml-15px kenjo-mr-10px">
            <orgos-action class="kenjo-mr-5px" (click)="onShowFeatures()"
              >{{showFeatures ? i18n.page.hideFeatures : i18n.page.seeFeatures}}</orgos-action
            >
            <mat-icon
              class="sbup-arrow"
              [class.sbup-arrow-opened]="showFeatures"
              (click)="onShowFeatures()"
              >keyboard_arrow_down</mat-icon
            >
          </div>
          <div class="sbup-line"></div>
        </div>

        <div class="kenjo-font-size-30px kenjo-text-align-center kenjo-mt-30px">
          {{i18n.page.calculatePriceTitle}}
        </div>

        <kenjo-price-calculator
          class="kenjo-mt-30px"
          [translations]="i18n.page"
          [recruitmentAddonPrices]="recruitmentAddonPrices"
          [recruitmentPlusAddonPrices]="recruitmentPlusAddonPrices"
          [onboardingAddonPrices]="onboardingAddonPrices"
          [datevImplementationFeePrices]="datevImplementationFeePrices"
          [planPrices]="planPrices"
          [showYearly]="showYearly"
          [currentPlan]="currentPlan"
          [isCurrentBillingYearly]="isCurrentBillingYearly"
          [isInTrialOrCancelled]="isInTrial || isCancelled"
          [contactUsLink]="contactLink"
          [minEmployees]="minEmployees"
          [isInTrial]="isInTrial"
          (changeShowYearly)="showYearly = $event"
          (openCheckout)="openCheckout($event, 'calculator')"
          (recruitingAddon)="recruitingAddon = $event;"
          (onboardingAddon)="onboardingAddon = $event;"
          (datevImplementation)="datevImplementation = $event;"
          (selectedEmployees)="selectedEmployees = $event;"
        ></kenjo-price-calculator>
        <div #calculator></div>
      </div>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
