import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { AttendanceSummaryService } from '@app/cloud-features/settings-attendance/services/attendance-summary.service';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { OvertimeBalanceDetailsController } from '@app/standard/services/user/controllers/overtime-balance-details.controller';
import { UserOvertimeHistoryService } from '@app/standard/services/user/user-overtime-history.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-overtime-adjust-balance',
  templateUrl: 'overtime-adjust-balance.dialog.html',
  styleUrls: ['overtime-adjust-balance.dialog.scss'],
})
export class OvertimeAdjustBalanceDialog implements OnInit {
  MAX_HOURS_VALUE = 1000;
  MAX_MINUTES_VALUE = 59;

  newBalance = {
    hours: 0,
    minutes: 0,
  };

  comments: string;

  COMPENSATION_TYPE: string = picklistConstants.OVERTIME_HISTORY_ENTRY_TYPE_ADJUST;
  saving: boolean = false;

  translation: any = {};

  year: number;
  month: number;
  currentBalance: number;
  userIds: Array<string> = [];
  userPersonal: any = {};
  balances: { [userId: string]: number };
  seeHistory: boolean = false;
  seeOvertimeHistory: boolean = false;
  overtimeHistory: Array<any> = [];
  history: Array<{ detail: string; when: Date; createdBy: string }> = [];
  attendanceSummaryId: string;

  isBulk: boolean = false;
  overtimeSettings: any = {};

  activateNegativeBalance: boolean = false;
  dateToApplyAdjustment: string;

  /**
   * @param dialogRef reference to the dialog
   * @param data object like:
   *  overtime: {[userId: string]: number}
   * It is a map (userId, overtime)
   * @param injector injector of angular
   */
  constructor(
    public dialogRef: MatLegacyDialogRef<OvertimeAdjustBalanceDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.userIds = Object.keys(this.data.userIds);
    this.balances = this.data.userIds;
    this.year = this.data.year;
    this.month = this.data.month;
    this.dateToApplyAdjustment = moment.utc().format('DD/MM/YYYY');
    if (moment.utc().month() + 1 !== this.month || moment.utc().year() !== this.year) {
      this.dateToApplyAdjustment = moment
        .utc()
        .month(this.month - 1)
        .year(this.year)
        .endOf('month')
        .format('DD/MM/YYYY');
    }

    this.currentBalance = this.balances[this.userIds[0]];
    this.isBulk = this.userIds.length > 1;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('overtime-adjust-dialog')
      .then((translation: any) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    const summaryQuery = {
      userId: this.userIds[0],
      month: this.month,
      year: this.year,
    };
    if (!this.isBulk) {
      this.injector
        .get(UserWorkScheduleService)
        .getById(this.userIds[0])
        .then((userWorkSchedule) => {
          if (check.assigned(userWorkSchedule) && check.nonEmptyObject(userWorkSchedule.overtimeSettings)) {
            this.overtimeSettings = userWorkSchedule.overtimeSettings;
          }
          return this.injector.get(AttendanceSummaryService).find(summaryQuery);
        })
        .then((withOvertime) => {
          const allEntries = [];
          if (
            check.not.assigned(withOvertime) ||
            check.emptyArray(withOvertime) ||
            check.emptyObject(withOvertime[0]) ||
            check.emptyArray(withOvertime[0].attendanceEntries)
          ) {
            this.overtimeHistory = [];
            return;
          }

          this.attendanceSummaryId = withOvertime[0]._id;

          withOvertime[0].attendanceEntries.forEach((entry) => {
            if (
              ((check.assigned(this.overtimeSettings) && this.overtimeSettings.allowUndertime && entry.totalOvertime !== 0) ||
                ((check.not.assigned(this.overtimeSettings.allowUndertime) || this.overtimeSettings.allowUndertime === false) &&
                  entry.totalOvertime > 0)) &&
              moment.utc().isSameOrAfter(entry.date, 'day')
            ) {
              allEntries.push(entry);
            }
          });
          this.overtimeHistory = allEntries.reduce((acc, entry, index) => {
            if (index > 0 && moment.utc(allEntries[index - 1].date).isSame(entry.date, 'day')) {
              const oldEntry = acc.pop();
              entry.totalOvertime += oldEntry.totalOvertime;
            }
            acc.push(entry);
            return acc;
          }, []);
        })
        .catch(() => {
          this.overtimeHistory = [];
        });
    }

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false)
      .then((userPersonals) => {
        this.userPersonal = userPersonals.reduce((total, iPersonal) => {
          total[iPersonal._id] = iPersonal;
          return total;
        }, {});
        return this.injector.get(OvertimeBalanceDetailsController).getUserMonthDetails(summaryQuery);
      })
      .then((detail) => {
        this.currentBalance = check.assigned(detail.currentBalance) ? detail.currentBalance : 0;
        this.history = detail.history.map((iEntry) => {
          const entry = {
            when: iEntry._createdAt,
            detail: null,
            createdBy: iEntry._createdById,
          };
          const data = {
            from: null,
            to: null,
            displayName: this.userPersonal[iEntry._createdById].displayName,
            comment: null,
          };
          let textToTransform;
          if (iEntry.type === picklistConstants.OVERTIME_HISTORY_ENTRY_TYPE_PAY) {
            data.from = this.injector.get(DurationPipe).transform(iEntry.minutes);
            data.to = `${this.injector.get(DurationPipe).transform(detail.payments[iEntry.relatedToId].amount)} ${
              detail.payments[iEntry.relatedToId].currency
            }`;
            data.comment = iEntry.comments;
            textToTransform =
              check.assigned(data.comment) && check.not.emptyString(data.comment)
                ? this.translation.payEntryWithComment
                : this.translation.payEntry;
            entry.detail = this.injector.get(I18nDataPipe).transform(textToTransform, data);
          } else if (iEntry.type === picklistConstants.OVERTIME_HISTORY_ENTRY_TYPE_TIME_OFF) {
            let timeOffTo = '';
            if (check.assigned(detail.timeOffs?.[iEntry.relatedToId]?.adjustment)) {
              const timeOff = detail.timeOffs[iEntry.relatedToId];
              if (timeOff._policyType === picklistConstants.POLICY_TYPE_DAY) {
                timeOffTo = timeOff.adjustment.toFixed(2);
              } else {
                timeOffTo = (timeOff.adjustment / 60).toFixed(2);
              }
            }
            data.from = this.injector.get(DurationPipe).transform(iEntry.minutes);
            data.to = timeOffTo;
            data.comment = iEntry.comments;
            textToTransform =
              check.assigned(data.comment) && check.not.emptyString(data.comment)
                ? this.translation.timeOffEntryWithComment
                : this.translation.timeOffEntry;
            entry.detail = this.injector.get(I18nDataPipe).transform(textToTransform, data);
          } else {
            data.from = check.assigned(iEntry.minutesBefore) ? this.injector.get(DurationPipe).transform(iEntry.minutesBefore) : '';
            data.to = this.injector.get(DurationPipe).transform(iEntry.minutes);
            data.comment = iEntry.comments;
            textToTransform =
              check.assigned(data.comment) && check.not.emptyString(data.comment)
                ? this.translation.adjustEntryWithComment
                : this.translation.adjustEntry;
            entry.detail = this.injector.get(I18nDataPipe).transform(textToTransform, data);
          }
          return entry;
        });
      })
      .catch((err) => {
        this.history = [];
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.saving = true;

    const callsToSaveCompensations = this.userIds.map((userId: string) => {
      const historyEntry = {
        type: this.COMPENSATION_TYPE,
        minutes: this.getNewBalanceMinutes(),
        minutesBefore: this.balances[userId],
        comments: this.comments,
        userId: userId,
        month: this.month,
        year: this.year,
      };
      return this.injector.get(UserOvertimeHistoryService).create(historyEntry);
    });

    Promise.all(callsToSaveCompensations)
      .then(() => {
        this.saving = false;

        if (check.assigned(this.attendanceSummaryId)) {
          this.injector
            .get(AttendanceSummaryService)
            .updateById(this.attendanceSummaryId, { showAdjustmentWarning: true })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
              this.dialogRef.close(true);
            });
        } else {
          this.dialogRef.close(true);
        }
      })
      .catch((err) => {
        this.saving = false;
      });
  }

  public getNewBalanceMinutes(): number {
    const hoursMinutes = check.assigned(this.newBalance.hours) ? this.newBalance.hours * 60 : 0;
    const minutes = check.assigned(this.newBalance.minutes) ? this.newBalance.minutes : 0;

    let totalMinutes = Number(hoursMinutes) + Number(minutes);
    if (this.activateNegativeBalance === true) {
      totalMinutes *= -1;
    }
    return totalMinutes;
  }

  public parseBalanceHours() {
    const hoursValue = Math.round(Math.abs(this.newBalance.hours ?? 0));

    this.newBalance.hours = hoursValue > this.MAX_HOURS_VALUE ? this.MAX_HOURS_VALUE : hoursValue;
  }

  public parseBalanceMinutes() {
    const minutesValue = Math.round(Math.abs(this.newBalance.minutes ?? 0));

    this.newBalance.minutes = minutesValue > this.MAX_MINUTES_VALUE ? this.MAX_MINUTES_VALUE : minutesValue;
  }
}
