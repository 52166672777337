import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { WarningMessageModule } from '@app/common-components/warning-message/warning-message.module';
import { WidgetAnniversaryComponent } from '@app/common-components/widgets/anniversary/orgos-widget-anniversary.component';
import { WidgetApprovalComponent } from '@app/common-components/widgets/approval/orgos-widget-approval.component';
import { WidgetBirthdayComponent } from '@app/common-components/widgets/birthday/orgos-widget-birthday.component';
import { WidgetJobOpeningComponent } from '@app/common-components/widgets/job-opening/orgos-widget-job-opening.component';
import { MyTeamWidgetDetailsDialog } from '@app/common-components/widgets/my-team/dialogs/my-team-widget-details.dialog';
import { WidgetMyTeamComponent } from '@app/common-components/widgets/my-team/orgos-widget-my-team.component';
import { WidgetNewApplicantComponent } from '@app/common-components/widgets/new-applicant/orgos-widget-new-applicant.component';
import { WidgetPlatformUsageComponent } from '@app/common-components/widgets/platform-usage/kenjo-widget-platform-usage.component';
import { WidgetPunchClockComponent } from '@app/common-components/widgets/punch-clock/orgos-widget-punch-clock.component';
import { WhoIsAwayWidgetDetailsDialog } from '@app/common-components/widgets/who-away/dialogs/who-is-away-widget-details.dialog';
import { WidgetWhoAwayComponent } from '@app/common-components/widgets/who-away/orgos-widget-who-away.component';
import { NgxCumulioDashboardModule } from '@cumul.io/ngx-cumulio-dashboard';

export const EXTERNAL_MODULES = [IconModule, WarningMessageModule, TruncateTextModule, NgxCumulioDashboardModule];

export const COMPONENTS = [
  WidgetAnniversaryComponent,
  WidgetApprovalComponent,
  WidgetBirthdayComponent,
  WidgetJobOpeningComponent,
  WidgetMyTeamComponent,
  WidgetNewApplicantComponent,
  WidgetPunchClockComponent,
  WidgetWhoAwayComponent,
  MyTeamWidgetDetailsDialog,
  WhoIsAwayWidgetDetailsDialog,
  WidgetPlatformUsageComponent
];

export const PROVIDERS = [];
