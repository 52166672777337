import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ISelectItem } from '@app/standard/components/select-dropdown-custom/select-dropdown-custom.component';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-add-employee-attendance-step',
  templateUrl: 'attendance-step.component.html',
  styleUrls: ['attendance-step.component.scss'],
})
export class AddEmployeeAttendanceStepComponent implements OnInit {
  @Input() translations: any = {};
  @Input() userAccount: GenericCacheModel;
  @Input() attendancePoliciesOptions: Array<ISelectItem>;

  _attendancePolicies: Array<IAttendancePolicy>;
  @Input() set attendancePolicies(attendancePolicies: Array<IAttendancePolicy>) {
    this._attendancePolicies = attendancePolicies;
    this.attendancePoliciesById = attendancePolicies.reduce((total, iPolicy) => ({ ...total, [iPolicy._id]: iPolicy }), {});
    const attendancePolicy = this.attendancePoliciesById[this.userAccount.data.attendancePolicy];
    this.methods = attendancePolicy.methods;
  }

  get attendancePolicies() {
    return this._attendancePolicies;
  }

  @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() skipStep: EventEmitter<boolean> = new EventEmitter<boolean>();

  attendancePolicyValidation: InputValidation;
  defaultPolicy: ISelectItem;
  attendancePoliciesById = {};
  methods = {
    timeSheet: false,
    punchClock: false,
    qrCode: false,
    pinCode: false,
  };

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.defaultPolicy = this.attendancePoliciesOptions.find((iPolicy) => iPolicy._isDefault);
  }

  onPolicyChange() {
    this.injector.get(PrivateAmplitudeService).logEvent('assign attendance policy while creating new employee', {
      platform: 'Web',
      category: 'Employee creation',
      subcategory1: 'Attendance Policies',
      subcategory2: 'Assign attendance policy',
    });

    const attendancePolicy = this.attendancePolicies.find(
      (iAttendancePolicy) => iAttendancePolicy._id === this.userAccount.data.attendancePolicy
    );
    this.methods = attendancePolicy.methods;
  }

  onPreviousStep() {
    this.previousStep.emit(true);
  }

  onNextStep() {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('attendance  next', { platform: 'Web', category: 'Employee', subcategory1: 'Creation wizard' });
    this.nextStep.emit(true);
  }

  onSkipStep() {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('attendance  skip', { platform: 'Web', category: 'Employee', subcategory1: 'Creation wizard' });
    this.injector.get(PrivateAmplitudeService).logEvent('skips assignment', {
      platform: 'Web',
      category: 'Employee creation',
      subcategory1: 'Attendance Policies',
      subcategory2: 'Assign attendance policy',
    });
    delete this.userAccount.data.attendancePolicy;
    this.skipStep.emit(true);
  }
}
