<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.virtualOffice" (clickCloseButton)="closeDialog()">
  <orgos-column-container>
    <orgos-input-simple-text orgosColumn [(value)]="newVirtualOffice.name" [label]="dialogTranslation.name" required="true" (validation)="officeNameValidation = $event">
      <orgos-input-error *ngIf="officeNameValidation && officeNameValidation.getError('required')">{{dialogTranslation.officeNameRequired}}</orgos-input-error>
    </orgos-input-simple-text>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-20px">
    <orgos-input-simple-text orgosColumn [(value)]="newVirtualOffice.street" [label]="dialogTranslation.street" class="kenjo-mr-10px"></orgos-input-simple-text>
    <orgos-input-simple-text orgosColumn [(value)]="newVirtualOffice.postalCode" [label]="dialogTranslation.postalCode" class="kenjo-ml-10px"></orgos-input-simple-text>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-20px">
    <orgos-input-simple-text orgosColumn [(value)]="newVirtualOffice.city" [label]="dialogTranslation.city" class="kenjo-mr-10px"></orgos-input-simple-text>
    <orgos-input-simple-select orgosColumn [(value)]="newVirtualOffice.country" [label]="dialogTranslation.country" picklist="country" class="kenjo-ml-10px"></orgos-input-simple-select>
  </orgos-column-container>
  <div class="kenjo-mt-20px kenjo-font-size-18px">{{dialogTranslation.attachCalendarHeader}}</div>
  <orgos-column-container class="kenjo-mt-20px" centerColumns="true">
    <kenjo-icon orgosColumn="0" [size]="20" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
    <orgos-column class="kenjo-font-color-light-text-757575 kenjo-ml-5px">{{dialogTranslation.calendarWarningText}}</orgos-column>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-20px">
    <orgos-input-simple-select orgosColumn [(value)]="newVirtualOffice.calendarId" [label]="dialogTranslation.calendarId" [options]="calendars" required="true" (validation)="officeCalendarValidation = $event" class="kenjo-mr-10px">
      <orgos-input-error *ngIf="officeCalendarValidation && officeCalendarValidation.getError('required')">{{dialogTranslation.officeCalendarRequired}}</orgos-input-error>
    </orgos-input-simple-select>
    <orgos-column class="kenjo-ml-10px"></orgos-column>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{miscTranslation.goBackButtonDialog}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="save()" color="Success" [disabled]="!officeNameValidation || officeNameValidation.hasErrors() || !officeCalendarValidation || officeCalendarValidation.hasErrors()">
        {{dialogTranslation.saveButton}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
