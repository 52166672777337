import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import {
  CalendarTemplateService,
  ICalendarTemplateModel,
  IHolidayTemplateModel,
} from '@app/standard/services/company/calendar-template.service';
import { CalendarService, HolidayDurationOption, ICalendarModel, IHolidayModel } from '@app/standard/services/company/calendar.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-calendar-year',
  templateUrl: 'calendar-year.component.html',
  styleUrls: ['calendar-year.component.scss'],
})
export class CalendarYearComponent implements OnInit {
  @Output() clickOnDate: EventEmitter<ICalendarGridItem> = new EventEmitter<ICalendarGridItem>();
  @Output() clickOnPreviousYear: EventEmitter<number> = new EventEmitter<number>(); // Emit event with the number of current year
  @Output() clickOnNextYear: EventEmitter<number> = new EventEmitter<number>(); // Emit event with the number of current year

  @Input() calendarId: string;
  @Input() view: 'month' | 'year' = 'year';
  @Input() year: number;
  @Input() month: number;
  @Input() minYear: number;
  @Input() maxYear: number;
  @Input() showLearnMore: boolean = false;

  isLoading: boolean = true;
  fullCalendar: Array<number> = [...Array(12).keys()];
  mapKeyToCalendarEvent: { [yearMonthDay: string]: ICalendarEvent };
  componentTranslations: { [stranslationKey: string]: string };

  private calendarTranslations: { [translationKey: string]: string } = {};
  private customCalendar: ICalendarModel;
  private calendarTemplate: ICalendarTemplateModel; // every customCalendar will refer to a calendarTemplate

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    if (!this.minYear) {
      this.minYear = this.year - 2;
    }
    if (!this.maxYear) {
      this.maxYear = this.year + 2;
    }

    if (!this.year) {
      this.year = new Date().getFullYear();
    }

    try {
      this.injector.get(GlobalBarService).setProgressBar(true);
      await this.initTranslations();
      await this.findCalendar();
      this.calculateCalendarEvents();
      this.isLoading = false;
      this.injector.get(GlobalBarService).setProgressBar(false);
    } catch (e) {
      this.calendarTranslations = {};
      this.mapKeyToCalendarEvent = {};
    }
  }

  private async initTranslations(): Promise<void> {
    this.componentTranslations = await this.injector.get(InternationalizationService).getAllTranslation('calendar-component');

    const standardPicklistTranslations = await this.injector.get(InternationalizationService).getAllTranslation('standard-picklists');
    if (standardPicklistTranslations?.holidays) {
      this.calendarTranslations = standardPicklistTranslations.holidays;
    }

    return;
  }

  private async findCalendar(): Promise<void> {
    if (!this.calendarId) {
      return;
    }

    this.customCalendar = await this.injector.get(CalendarService).getById(this.calendarId);

    // calculate the minYear
    if (this.customCalendar?._customHolidays?.length) {
      this.customCalendar._customHolidays.forEach((customHoliday: IHolidayModel) => {
        if (new Date(customHoliday.holidayDate).getFullYear() < this.minYear) {
          this.minYear = new Date(customHoliday.holidayDate).getFullYear();
        }
      });
    }

    if (!this.customCalendar?._calendarTemplateKey) {
      return;
    }

    this.calendarTemplate = await this.injector.get(CalendarTemplateService).getTemplate(this.customCalendar._calendarTemplateKey);

    // calculate the minYear again
    if (this.calendarTemplate?.holidays?.length) {
      this.calendarTemplate.holidays.forEach((templateHoliday: IHolidayTemplateModel) => {
        if (new Date(templateHoliday.holidayDate).getFullYear() < this.minYear) {
          this.minYear = new Date(templateHoliday.holidayDate).getFullYear();
        }
      });
    }

    if (this.year < this.minYear || this.year > this.maxYear) {
      this.year = new Date().getFullYear();
    }
  }

  private calculateCalendarEvents(year: number = this.year, month: number = this.month): void {
    this.mapKeyToCalendarEvent = convertCalendarModelToCalendarEvents(this.customCalendar, this.calendarTemplate, {
      ...this.calendarTranslations,
      ...this.componentTranslations,
    });
  }

  previousYear(): void {
    if (this.year === this.minYear) {
      return;
    }

    this.year = this.year - 1;

    this.clickOnPreviousYear.emit(this.year);
  }

  nextYear(): void {
    if (this.year === this.maxYear) {
      return;
    }

    this.year = this.year + 1;

    this.clickOnNextYear.emit(this.year);
  }

  openLearnMoreDialog(): void {
    const dialogData = {
      titleText: this.componentTranslations.learnMoreDialogTitle,
      subtitleText: this.componentTranslations.learnMoreDialogDescription,
      confirmButtonText: this.componentTranslations.learnMoreDialogOkButton,
      confirmButtonColor: 'Success',
    };
    this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
  }
}

// Given a standard or custom holiday, it will return a key used by yearMonthDateToHoliday field
export function getHolidayKey(holidayDate: Date): string {
  const dateOfHoliday = new Date(holidayDate);
  const key = `${dateOfHoliday.getFullYear()}-${dateOfHoliday.getMonth()}-${dateOfHoliday.getDate()}`;
  return key;
}

export function convertCalendarModelToCalendarEvents(
  customCalendar: ICalendarModel,
  calendarTemplate: ICalendarTemplateModel,
  translations?: { [translationKey: string]: string }
): { [yearMonthDay: string]: ICalendarEvent } {
  let customHolidays: { [yearMonthDay: string]: ICalendarEvent } = {};
  if (customCalendar?._customHolidays) {
    customHolidays = customCalendar._customHolidays.reduce((total, customHoliday: IHolidayModel) => {
      const yearMonthDay = getHolidayKey(new Date(customHoliday.holidayDate));

      const calendarEvent: ICalendarEvent = {
        isEditable: true,
        eventName: customHoliday.holidayName,
        eventDuration: customHoliday.holidayDuration,
        backgroundColor: '#DEDEDE',
      };
      if (customHoliday._id) {
        calendarEvent.eventId = customHoliday._id;
      }

      if (customHoliday.holidayDuration === HolidayDurationOption.MORNING && translations?.morning) {
        calendarEvent.eventName = `${calendarEvent.eventName} (${translations.morning})`;
      } else if (customHoliday.holidayDuration === HolidayDurationOption.AFTERNOON && translations?.afternoon) {
        calendarEvent.eventName = `${calendarEvent.eventName} (${translations.afternoon})`;
      }

      total[yearMonthDay] = calendarEvent;

      return total;
    }, {});
  }

  if (!calendarTemplate?.holidays?.length) {
    return customHolidays;
  }

  const templateHolidays = calendarTemplate.holidays.reduce((total, templateHoliday: IHolidayTemplateModel) => {
    if (!templateHoliday.holidayDate) {
      return total;
    }
    const yearMonthDay = getHolidayKey(new Date(templateHoliday.holidayDate));

    let eventName;
    if (templateHoliday.holidayName) {
      eventName = templateHoliday.holidayName;
    } else if (templateHoliday.holidayKey && translations && translations[templateHoliday.holidayKey]) {
      eventName = translations[templateHoliday.holidayKey];
    } else {
      eventName = templateHoliday.holidayName;
    }

    const calendarEvent: ICalendarEvent = {
      isEditable: false,
      eventName,
      eventDuration: templateHoliday.holidayDuration,
      backgroundColor: '#DEDEDE',
    };

    if (templateHoliday.holidayDuration === HolidayDurationOption.MORNING && translations?.morning) {
      calendarEvent.eventName = `${calendarEvent.eventName} (${translations.morning})`;
    } else if (templateHoliday.holidayDuration === HolidayDurationOption.AFTERNOON && translations?.afternoon) {
      calendarEvent.eventName = `${calendarEvent.eventName} (${translations.afternoon})`;
    }

    total[yearMonthDay] = calendarEvent;
    return total;
  }, {});
  return { ...templateHolidays, ...customHolidays };
}

export interface ICalendarGridItem {
  year: number;
  month: number;
  day: number;
  date: Date;
  isToday?: boolean; // true if this item is for today
  calendarEvent?: ICalendarEvent; // for public holidays mainly
  avatars?: Array<ICalendarGridAvatar>; // to list employees on this date of the calendar
}

export interface ICalendarGridAvatar {
  displayName: string;
  photoUrl?: string;
  hoverMessage?: string;
  color?: string;
}

export interface ICalendarEvent {
  eventId?: string; // in case this event is related to a database document
  isEditable?: boolean;
  eventName?: string;
  eventDuration?: HolidayDurationOption;
  textColor?: string;
  backgroundColor?: string;
}
