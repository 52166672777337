<orgos-container aspect="card">
  <orgos-container [class]="headerCssClasses">
    <div class="kenjo-p-20px">
      <mat-icon class="cacc-app-icon">{{ appMetadata.icon }}</mat-icon>
    </div>
  </orgos-container>
  <orgos-container class="kenjo-p-20px">
    <orgos-text type="Headline4">{{ appMetadata.name }}</orgos-text>
    <orgos-chip *ngIf="appStatus && appStatus.isActive === true" [color]="activeColor" class="kenjo-mt-10px cacc-chip"><orgos-text color="White">Active</orgos-text></orgos-chip>
    <orgos-chip *ngIf="appStatus && appStatus.isActive === false" [color]="inactiveColor" class="kenjo-mt-10px cacc-chip"><orgos-text color="White">Inactive</orgos-text></orgos-chip>
  </orgos-container>
</orgos-container>
