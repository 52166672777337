import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-button-toggle',
  templateUrl: 'button-toggle.component.html',
  styleUrls: ['button-toggle.component.scss']
})
export class ButtonToggleComponent {
  color: string = '#757575';

  private _activeColor: string = '#5993e3';
  @Input()
  set activeColor(activeColor: string) {
    this._activeColor = activeColor;
    this.color = this.checked ? this.activeColor : this.nonActiveColor;
  }
  get activeColor(): string {
    return this._activeColor;
  }

  private _nonActiveColor: string = '#757575';
  @Input()
  set nonActiveColor(nonActiveColor: string) {
    this._nonActiveColor = nonActiveColor;
    this.color = this.checked ? this.activeColor : this.nonActiveColor;
  }
  get nonActiveColor(): string {
    return this._nonActiveColor;
  }

  private _checked: boolean = false;
  @Input()
  set checked(checked: boolean) {
    this._checked = checked;
    this.color = this.checked ? this.activeColor : this.nonActiveColor;
  }
  get checked(): boolean {
    return this._checked;
  }

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle(): void {
    this.checked = this.checked ? false : true;
    this.checkedChange.emit(this.checked);
  }
}
