import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-settings-shift-plan-select',
  templateUrl: 'settings-shift-plan-select.html',
  styleUrls: ['settings-shift-plan-select.scss']
})
export class SettingsShiftPlanSelect implements OnInit {
  @Input() initialData: DataToSelect[] = [];
  @Input() dataType: string;
  @Input() data: DataToSelect[] = [];
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  colorConstants: any = userColorConstants;
  selectedCounter: number = 0;
  activateShiftPlanTranslation: any;
  DEFAULT_UNSELECTED_BACKGROUND: string = '#dedede';
  DEFAULT_SELECTED_BACKGROUND: string = '#5993e3';

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.fetchTranslations();
    this.checkCounter();
  }

  async fetchTranslations() {
    this.activateShiftPlanTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-activate-employee-dialog');
  }

  select(item: DataToSelect) {
    item.selected = true;
    this.selectedCounter++;
    this.setItemSelection(item, true);
  }

  clear(item: DataToSelect) {
    item.selected = false;
    this.selectedCounter--;
    this.setItemSelection(item, false);
  }

  setItemSelection(item: DataToSelect, select: boolean) {
    const itemIndex = this.initialData.findIndex((initialItem) => initialItem._id === item._id);
    this.initialData[itemIndex].selected = select;
    this.onChange.emit(this.initialData);
  }

  selectAll() {
    this.initialData = this.initialData.map((item) => {
      return { ...item, selected: true };
    });
    this.data = this.data.map((item) => {
      return { ...item, selected: true };
    });
    this.selectedCounter = this.initialData.length;
    this.onChange.emit(this.initialData);
  }

  clearAll() {
    this.initialData = this.initialData.map((item) => {
      return { ...item, selected: false };
    });
    this.data = this.data.map((item) => {
      return { ...item, selected: false };
    });
    this.selectedCounter = 0;
    this.onChange.emit(this.initialData);
  }

  checkCounter() {
    this.selectedCounter = 0;
    this.initialData.forEach((item) => {
      if (item.selected) {
        this.selectedCounter++;
      }
    });
  }
}

interface DataToSelect {
  name: string;
  selected?: boolean;
  colour?: string;
  _id?: string;
}
