import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

import { IWorkScheduleTemplateModel } from '../models/work-schedule-template.model';

@Injectable({
  providedIn: 'root'
})
export class WorkScheduleTemplateService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/work-schedule-template-db`;

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  create(data: object): Promise<IWorkScheduleTemplateModel> {
    return new Promise<IWorkScheduleTemplateModel>((resolve, reject) => {
      this.genericService
        .create(this.URL, data)
        .then((workScheduleTemplate: IWorkScheduleTemplateModel) => {
          resolve(workScheduleTemplate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkScheduleTemplateService.name, 'create'));
        });
    });
  }

  getWorkScheduledTemplates(): Promise<Array<IWorkScheduleTemplateModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<IWorkScheduleTemplateModel>>((resolve, reject) => {
      this.genericService
        .find(this.URL, findBody)
        .then((workScheduleTemplate: Array<IWorkScheduleTemplateModel>) => {
          resolve(workScheduleTemplate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkScheduleTemplateService.name, 'getWorkScheduledTemplates'));
        });
    });
  }

  getById(id: string): Promise<IWorkScheduleTemplateModel> {
    return new Promise<IWorkScheduleTemplateModel>((resolve, reject) => {
      this.genericService
        .getById(this.URL, id)
        .then((workScheduleTemplate: IWorkScheduleTemplateModel) => {
          resolve(workScheduleTemplate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkScheduleTemplateService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkScheduleTemplateService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkScheduleTemplateService.name, 'deleteById'));
        });
    });
  }

  setAsDefault(templateId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.URL}/default/${templateId}`, {}, httpOptions)
        .toPromise()
        .then((setAsDefaultResult) => {
          resolve(setAsDefaultResult);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkScheduleTemplateService.name, 'setAsDefault'));
        });
    });
  }
}
