<div class="scc-card">
  <div class="scc-content">
    <div class="scc-card-left kenjo-pr-20px">
      <div class="scc-plan-image"><img src="{{ '/assets/images/' + planLogo + '-plan.svg' }}" /></div>
      <div class="scc-plan-status">
        <div class="kenjo-text-transform-uppercase kenjo-font-size-25px">
          {{ subscription.plan.name }}
        </div>
        <div>{{ translations.currentPlan.activeEmployeesLabel | i18nData: { activeEmployees: subscription.activeUsers } }}</div>
        <div>
          <div class="kenjo-font-color-light-text-757575">{{ translations.currentPlan.subscriptionStatus }}</div>
          <orgos-chip class="kenjo-mt-10px kenjo-text-align-center kenjo-text-transform-uppercase" [class.kenjo-font-color-white-ffffff]="subscription.status !== 'non_renewing'" [color]="statusColors[subscription.status]"> {{ translations.currentPlan.status[subscription.status] }}</orgos-chip>
          <div *ngIf="['trial_expired', 'non_renewing', 'in_trial'].includes(subscription.status)">
            <orgos-action class="kenjo-mt-10px" (click)="onGoToPlans()">{{ translations.viewPlans }}</orgos-action>
          </div>
        </div>
      </div>
    </div>
    <div class="scc-card-line"></div>
    <div class="scc-card-right kenjo-p-40px">
      <div class="kenjo-font-color-light-text-757575">{{ translations.currentPlan.subscriptionStart }}</div>
      <div>{{ subscription.started_at | humanDate: false:'short':true }}</div>

      <div class="kenjo-font-color-light-text-757575">{{ translations.currentPlan.nextBillingFor }}</div>
      <div *ngIf="subscription.activeUsers > subscription.purchasedLicenses">{{ translations.currentPlan.activeLicenses | i18nData: { activeLicenses: subscription.activeUsers } }}</div>
      <div *ngIf="subscription.purchasedLicenses >= subscription.activeUsers">{{ translations.currentPlan.purchasedLicenses | i18nData: { purchasedLicenses: subscription.purchasedLicenses } }}</div>

      <div class="kenjo-font-color-light-text-757575">{{ translations.currentPlan.subscriptionEnd }}</div>
      <div>{{ subscription.next_billing_at | humanDate: false:'short':true }}</div>

      <div class="kenjo-font-color-light-text-757575">{{ translations.currentPlan.billingFrequency }}</div>
      <div>{{ billingFrequencyText }}</div>

      <div class="kenjo-font-color-light-text-757575">{{ translations.currentPlan.currency }}</div>
      <div>{{ subscription.currency_code }}</div>

      <div class="kenjo-font-color-light-text-757575">{{ translations.currentPlan.addon }}</div>
      <div>
        <div *ngFor="let iAddon of subscription.addons">{{ iAddon.name }}</div>
      </div>
    </div>
  </div>
</div>
