import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IInAppInviteTeammate } from '@app/common-dialogs/in-app-invite/in-app-invite-dialog.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable()
export class InAppInviteDialogService {
  private IN_APP_INVITE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/onboarding/send-invitations/in-app`;
  private HASH_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/hash-db/onboarding/invite`;

  private _dialogAccess: Observable<boolean> = of(false);
  public get hasDialogAccess$(): Observable<boolean> {
    return this._dialogAccess;
  }

  private _inviteLink: string = '';
  public get inviteLink(): string {
    return this._inviteLink;
  }

  private _teammates: Array<IInAppInviteTeammate> = [];
  public get teammates(): Array<IInAppInviteTeammate> {
    return this._teammates;
  }

  constructor(private injector: Injector) {}

  // Adds EMPTY teammate, with fields to be added by the form. the values are bound to this property and updated automatically
  addTeammate(): void {
    this._teammates.push({ fullName: '', workEmail: '' });
  }
  removeTeammate(index: number): void {
    this._teammates.splice(index, 1);
  }
  clearTeammates(): void {
    this._teammates = [];
  }

  // No need to await. user gets confirmation from email
  async sendInvitations() {
    this.sendAmplitudeEvent();
    this.sendChameleonEvent();
    this.sendChurnzeroEvent();
    const httpRequestBody: IInAppInviteRequestBody = { invitations: this._teammates };
    this.injector.get(HttpClient).post(this.IN_APP_INVITE_URL, httpRequestBody, {}).toPromise();
  }

  private sendAmplitudeEvent(): void {
    if (this.teammates.length === 0) {
      return;
    } else if (this.teammates.length === 1) {
      this.injector.get(PrivateAmplitudeService).logEvent('Single admin invited inapp', { category: 'PLG', subcategory1: 'Invitations' });
    } else {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('Multiple admins invited inapp', { category: 'PLG', subcategory1: 'Invitations' });
    }
  }

  private sendChameleonEvent(): void {
    this.injector.get(PrivateIntegrationsService).trackChameleonEvent('Single admin invited in-app');
  }

  private sendChurnzeroEvent(): void {
    this.injector.get(PrivateChurnzeroService).logSimpleEvent('ADMIN_USER_ADDED');
  }

  async getInviteLink(): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      this._inviteLink = await this.injector.get(HttpClient).get<string>(this.HASH_URL, httpOptions).toPromise();
    } catch {
      this._inviteLink = '';
    }
  }
}

interface IInAppInviteRequestBody {
  invitations: Array<IInAppInviteTeammate>;
}
