<div *ngIf="!loading && comment && comment !== ''" class="pcc-comment-container">
  <kenjo-simple-truncate-text class="pcc-comment-text">
    {{ comment }}
  </kenjo-simple-truncate-text>
  <div *ngIf="!payrollConfirmed" class="pcc-actions-container">
    <kenjo-icon [size]="24" (click)="openEditionModal()" [matTooltip]="i18n.editComment" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3 kenjo-mr-10px">mode_edit</kenjo-icon>
    <kenjo-icon [size]="24" (click)="deleteComment()" [matTooltip]="i18n.deleteComment" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757">delete</kenjo-icon>
  </div>
</div>

<div *ngIf="!loading && (!comment || comment === '')">
  <div *ngIf="!payrollConfirmed" class="pcc-no-comment-container">
    <orgos-action color="Primary" (click)="openEditionModal()" icon="add_circle">{{ i18n.addComment }}</orgos-action>
  </div>
  <div *ngIf="payrollConfirmed" class="pcc-no-comment-container">
    -
  </div>
</div>

<div *ngIf="loading">
  <orgos-loading-spinner></orgos-loading-spinner>
</div>