import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class RecruitingAgencyService implements IGenericService {
  private RECRUITING_AGENCY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-agency-db`;
  private RECRUITING_AGENCY_PERMISSIONS_KEY: string = 'recruiting-agency';
  private RECRUITING_AGENCY_INTERNATIONALIZATION: string = 'recruiting-agency-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IRecruitingAgency> {
    return new Promise<IRecruitingAgency>((resolve, reject) => {
      this.genericService
        .create(this.RECRUITING_AGENCY_URL, data)
        .then((agency: IRecruitingAgency) => {
          resolve(agency);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingAgencyService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IRecruitingAgency> {
    return new Promise<IRecruitingAgency>((resolve, reject) => {
      this.genericService
        .getById(this.RECRUITING_AGENCY_URL, id)
        .then((agency: IRecruitingAgency) => {
          resolve(agency);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingAgencyService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.RECRUITING_AGENCY_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingAgencyService.name, 'updateById'));
        });
    });
  }

  find(findBody: object): Promise<Array<IRecruitingAgency>> {
    return new Promise<Array<IRecruitingAgency>>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_AGENCY_URL, findBody)
        .then((results: Array<IRecruitingAgency>) => {
          resolve(results);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingAgencyService.name, 'find'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.RECRUITING_AGENCY_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingAgencyService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    const permissions = this.genericService.getPermissions(this.RECRUITING_AGENCY_PERMISSIONS_KEY);
    return permissions;
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.RECRUITING_AGENCY_INTERNATIONALIZATION);
  }

  resendEmail(option: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get<any>(`${this.RECRUITING_AGENCY_URL}/resend-email/${option._id}`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingAgencyService.name, 'resendEmail'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.RECRUITING_AGENCY_URL);
  }
}

export interface IRecruitingAgency {
  name: string;
  phone?: string;
  email: string;
  conditions?: string;
  language?: string;
  lastAccess?: Date;
  documents?: Array<any>;
  relatedUserId?: string;
  _createdAt?: Date;
  _createdById?: string;
  _id?: string;
}
