import { Component, Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { PrivateUserAccountService } from '@app/private/services/private-user-account.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ISelectOption } from '@app/standard/core/select-option';
import { GenericFilterPage } from '@app/standard/pages/generic-filter.page';
import { IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { AddEmployeeDialog } from '@app/standard/pages/people/dialogs/add-employee.dialog.html/add-employee.dialog';
import { UpdateUserWorkDialog } from '@app/standard/pages/people/dialogs/update-user-work-dialog/update-user-work-dialog.dialog';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { PeopleDirectoryService } from '@app/standard/services/data-engine/people-directory.service';
import { FilterBarController } from '@app/standard/services/filter-bar/filter-bar.service';
import { PeopleBarService } from '@app/standard/services/navigation-tabs/people-bar.service';
import { IPreferenceModel, PreferenceService } from '@app/standard/services/preference/preference.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import { IHeader } from 'src/app/standard/components/table/table.component';

@Component({
  selector: 'orgos-directory-page',
  templateUrl: 'directory.page.html',
  styleUrls: ['directory.page.scss'],
})
export class PeoplePage extends GenericFilterPage {
  employeesSelectedText: string;
  listGroups: Array<string>;
  headerLabels: IHeader;
  usersPerGroup: any;
  sortFilters: any;
  PREFERENCE_TYPE: string = pickLists.PREFERENCE_TYPE_TABLE;
  PREFERENCE_OPTION_KEY: string = 'people-directory-options';
  PREFERENCE_OPTION_COLUMNS_KEY: string = 'columns-directory-list';
  PREFERENCE_VIEW: string = 'view-people-directory-list';
  DEFAULT_SORT_BY: string = 'user-personal.displayName';
  FILTERS_KEY: string = 'directory';
  bypassEmail: boolean = true;
  canAddEmployees = false;
  canImportEmployees = false;
  bulkActionsAllowed: boolean = false;
  displayedColumns: Array<string> = [];
  totalColumns: Array<string> = [
    'select',
    'user-personal.displayName',
    'user-work.jobTitle',
    'user-work.manager',
    'company.name',
    'office.name',
    'department.name',
    'user-account.status',
  ];
  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'directory-page' },
    { name: 'menu', translationKey: 'people-menu' },
  ];
  protected sortableColumnOptions = {
    'user-work': 'user-work.jobTitle',
    'user-personal': 'user-personal.displayName',
    company: 'company.name',
    office: 'office.name',
    department: 'department.name',
  };
  protected fieldToQueryOptionMap = {
    status: 'status',
    companies: 'companyId',
    offices: 'officeId',
    departments: 'departmentId',
    teams: 'teamIds',
    areas: 'areaIds',
  };

  listFilters: Array<ISelectOption> = [];
  usersList: Array<any> = [];
  allAreas: Array<any> = [];
  allTeams: Array<any> = [];
  selectedUsers: Array<any> = [];
  myPermissions: any = {};
  isTimeOffApproverActive: boolean = false;

  constructor(injector: Injector, router: Router, public dialog: MatLegacyDialog, public snackBar: MatLegacySnackBar) {
    super(injector, router);
  }

  protected async getGlobalBarOptions(): Promise<Array<IMenuOption>> {
    return this.injector.get(PeopleBarService).getOptions(this.i18n.menu);
  }

  protected async getPreferences(): Promise<void> {
    this.preferences = await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_OPTION_KEY);
  }

  protected async getFilters(): Promise<void> {
    const pageFilters = await this.injector.get(FilterBarController).getFiltersByKey('directory');

    this.filters = pageFilters.primary;
    this.sortFilters = pageFilters.secondary.sortBy;
  }

  protected async fetchRelatedData(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view people page', { category: 'Navigation', type: 'directory' });

    this.myPermissions = await this.injector.get(PrivateSecurityService).getAllPermissions();

    this.canAddEmployees =
      check.assigned(this.myPermissions?.employees?.c_addEmployees_all) &&
      (this.myPermissions?.employees?.c_addEmployees_all === true ||
        check.nonEmptyArray(this.myPermissions?.employees?.c_addEmployees_custom));
    this.canImportEmployees = this.myPermissions?.import?.create_all === true;

    await this.getColumns();
    this.headerLabels = this.getHeaderLabels();

    const userProfile = this.getLoggedUser().profile;

    this.bulkActionsAllowed = userProfile._isAdmin === true || userProfile._profileKey === 'hr-admin';

    if (this.bulkActionsAllowed === false) {
      this.totalColumns = this.totalColumns.filter((iColumn) => iColumn !== 'select');
      return;
    }

    // Check if the time off feature is enabled and the timeOffApprover setting is enabled to display bulk change timeOffApprover
    const [timeOffStatus, timeOffConfiguration] = await Promise.all([
      this.injector.get(CloudRoutesService).getAppStatus('time-off'),
      this.injector.get(PrivateOrganizationService).getTimeOffConfiguration(),
    ]);
    this.isTimeOffApproverActive = timeOffStatus?.isActive && timeOffConfiguration?.enableTimeOffApprover;

    const { _bypassEmail: bypassEmail } = await this.injector.get(PrivateOrganizationService).getMyOrganization();
    this.bypassEmail = bypassEmail;
  }

  protected async afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view people page', { type: 'directory' });
  }

  /*
   * IMPORTANT! It is override because of the architecture. We have two different endpoints requiring different status query and we need to convert it before fetching data
   * Not override by default!
   */
  protected async toggleView(option: boolean): Promise<void> {
    if (this.queryOptions.listView === option) {
      return;
    }
    if (option) {
      this.injector.get(PrivateAmplitudeService).logEvent('list view', { platform: 'Web', category: 'People', subcategory1: 'Directory' });
    } else {
      this.injector.get(PrivateAmplitudeService).logEvent('card view', { platform: 'Web', category: 'People', subcategory1: 'Directory' });
    }

    this.queryOptions.listView = option;

    this.convertStatusQuery();

    await this.refreshData();
  }

  /*
   * Special method to convert the status query to the correct format for the new selected view.
   */
  private convertStatusQuery() {
    if (check.not.assigned(this.queryOptions['user-account']?.where) || check.emptyObject(this.queryOptions['user-account']?.where)) {
      return;
    }

    const currentQuery = { ...this.queryOptions['user-account']?.where };
    const valuesSelected = Object.keys(currentQuery);

    valuesSelected.forEach((iValue) => {
      if (this.queryOptions.listView === true) {
        this.queryOptions['user-account'].where[iValue] = {
          'user-accounts.isActive': currentQuery[iValue].isActive,
          'user-accounts.inactiveReason': currentQuery[iValue].inactiveReason,
        };
      } else {
        this.queryOptions['user-account'].where[iValue] = {
          isActive: currentQuery[iValue]['user-accounts.isActive'],
          inactiveReason: currentQuery[iValue]['user-accounts.inactiveReason'],
        };
      }
    });
  }

  protected setSpecificDefaultValuesForPage(): void {
    if (check.not.assigned(this.preferences?.preference)) {
      this.queryOptions['user-personal'] = {
        sortBy: this.DEFAULT_SORT_BY.split('.')[1],
        sortOrder: this.DEFAULT_SORT_ORDER,
      };
    }

    this.queryOptions.groupBy = this.myPermissions.employees && this.myPermissions.employees.c_addEmployees ? 'isActive' : 'firstName';
  }

  private getHeaderLabels(): IHeader {
    return {
      'user-personal.displayName': this.i18n.page.displayName,
      'user-work.jobTitle': this.i18n.page.jobTitle,
      'user-work.manager': this.i18n.page.managerName,
      'company.name': this.i18n.page.companyName,
      'office.name': this.i18n.page.officeName,
      'department.name': this.i18n.page.departmentName,
      'user-account.status': this.i18n.page.status,
    };
  }

  protected async fetchListData(): Promise<void> {
    try {
      this.selectedUsers = [];
      const { pages, totalOfRecords, records } = await this.injector
        .get(PeopleDirectoryService)
        .getListDataPeopleDirectory(this.queryOptions);

      this.paginationConfiguration.numberOfPages = pages;
      this.paginationConfiguration.totalOfRecords = totalOfRecords;
      this.usersList = records;
    } catch {
      this.selectedUsers = [];
    }
  }

  protected async fetchGridData(): Promise<void> {
    try {
      this.usersPerGroup = await this.injector.get(PeopleDirectoryService).getGridDataPeopleDirectory(this.queryOptions);
      this.listGroups = Object.keys(this.usersPerGroup).sort();
    } catch {
      this.listGroups = [];
      this.usersPerGroup = {};
    }
  }

  public async changeGroupField(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('sort by', { platform: 'Web', category: 'People', subcategory1: 'Directory' });
    await this.refreshData();
  }

  protected addFilter(value: string, field: string): void {
    if (check.not.assigned(this.queryOptions['user-work']?.where)) {
      this.queryOptions['user-work'] = {
        where: {},
      };
    }

    if (check.not.assigned(this.queryOptions['user-work'].where[field])) {
      this.queryOptions['user-work'].where[field] = {
        $in: [],
      };
    }

    if (this.queryOptions['user-work'].where[field].$in.indexOf(value) === -1) {
      this.queryOptions['user-work'].where[field].$in.push(value);
    }

    this.moveToPage(this.PAGE_SELECTOR['first']);
  }

  protected removeFilter(value: string, field: string): Promise<void> {
    if (
      check.not.assigned(this.queryOptions['user-work'].where[field].$in) ||
      check.emptyArray(this.queryOptions['user-work'].where[field].$in)
    ) {
      return;
    }

    if (this.queryOptions['user-work'].where[field].$in.length > 1) {
      this.queryOptions['user-work'].where[field].$in = this.queryOptions['user-work'].where[field].$in.filter(
        (fieldValue) => fieldValue !== value
      );
    } else if (this.queryOptions['user-work'].where[field].$in.length === 1) {
      delete this.queryOptions['user-work'].where[field];
    }

    if (
      check.not.assigned(this.queryOptions['user-work'].where.companyId) &&
      check.not.assigned(this.queryOptions['user-work'].where.officeId) &&
      check.not.assigned(this.queryOptions['user-work'].where.departmentId) &&
      check.not.assigned(this.queryOptions['user-work'].where.areaIds) &&
      check.not.assigned(this.queryOptions['user-work'].where.teamIds)
    ) {
      delete this.queryOptions['user-work'];
    }

    this.moveToPage(this.PAGE_SELECTOR['first']);
  }

  public addStatusFilter(optionSelected: string): void {
    if (check.not.assigned(this.queryOptions['user-account']?.where)) {
      this.queryOptions['user-account'] = {
        where: {},
      };
    }

    if (this.queryOptions.listView === true) {
      this.queryOptions['user-account'].where[optionSelected] = {
        'user-accounts.isActive': optionSelected === pickLists.USER_STATUS_ACTIVE,
        'user-accounts.inactiveReason':
          optionSelected === pickLists.USER_STATUS_DEACTIVATED
            ? pickLists.USER_STATUS_DEACTIVATED
            : { $ne: pickLists.USER_STATUS_DEACTIVATED },
      };

      return;
    }

    this.queryOptions['user-account'].where[optionSelected] = {
      isActive: optionSelected === pickLists.USER_STATUS_ACTIVE,
      inactiveReason:
        optionSelected === pickLists.USER_STATUS_DEACTIVATED
          ? pickLists.USER_STATUS_DEACTIVATED
          : { $ne: pickLists.USER_STATUS_DEACTIVATED },
    };
  }

  private removeStatusFilter(optionSelected: string): void {
    delete this.queryOptions['user-account'].where[optionSelected];
  }

  public selectListItem(selectedUsers: Array<any>): void {
    this.selectedUsers = selectedUsers;

    const data = {
      numberSelected: this.selectedUsers.length,
    };

    this.employeesSelectedText = this.injector.get(I18nDataPipe).transform(this.i18n.page.employeesSelectedText, data);
  }

  protected clearCustomFilters(): void {
    if (check.assigned(this.queryOptions['user-account']?.where)) {
      this.queryOptions['user-account'].where = {};
    }

    if (check.assigned(this.queryOptions['user-work']?.where)) {
      this.queryOptions['user-work'].where = {};
    }
  }

  public createEmployeePopup(): void {
    const dialogRef = this.dialog.open(AddEmployeeDialog);
    dialogRef.afterClosed().subscribe((newUserId) => {
      if (check.assigned(newUserId) && check.string(newUserId)) {
        this.injector.get(UserWorkService).clearUserWorkCache();
        this.snackBar.open(this.i18n.page.employeeConfigurationToastText, 'OK', {
          duration: 5000,
        });

        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('add employee', { platform: 'Web', category: 'People', subcategory1: 'Directory' });
        this.injector.get(PrivateIntegrationsService).trackChameleonEvent('add employee');
        this.router.navigateByUrl(`cloud/people/${newUserId}/personal`);
      }
    });
  }

  public activateEmployees(): void {
    const data = {
      titleText: this.i18n.page.activateEmployeesDialogTitle,
      subtitleText: this.i18n.page.activateEmployeesDialogSubtitle,
      confirmButtonText: this.i18n.page.activateEmployeesConfirmButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.page.activateEmployeesDialogCancelButton,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (dialogResponse) => {
      if (dialogResponse) {
        const resendActivationPromises = this.selectedUsers.map((iUser) =>
          this.injector.get(PrivateUserAccountService).resendActivation(iUser._id)
        );

        await Promise.all(resendActivationPromises);

        this.injector.get(MatLegacySnackBar).open(this.i18n.page.snackBarInvitationEmail, 'OK', {
          duration: 5000,
        });

        this.selectedUsers = [];
      }
    });
  }

  public changeUserWork(operation: string): void {
    const data = {
      users: this.selectedUsers,
      operation: operation,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(UpdateUserWorkDialog, { data: data, disableClose: true });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const snackBarMessage = check.not.assigned(result.name)
          ? this.i18n.page.snackBarClearUserWorkFromEmployees[result.operation]
          : this.injector
              .get(I18nDataPipe)
              .transform(this.i18n.page.snackBarAssignUserWorkToEmployees[result.operation], { name: result.name });
        this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', {
          duration: 5000,
        });

        if (operation === 'timeOffApprover') {
          this.injector.get(PrivateAmplitudeService).logEvent('bulk change time off approver', {
            category: 'Time off',
            platform: 'Web',
            subcategory1: 'Settings',
            type: 'Time off settings'
          });
        }

        await this.refreshData();
        this.selectedUsers = [];
      }
    });
  }

  public assignUserWorkScheduleTemplate(): void {
    const data = {
      users: this.selectedUsers,
      operation: 'workScheduleTemplate',
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(UpdateUserWorkDialog, { data: data, disableClose: true });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const snackBarMessage = this.injector.get(I18nDataPipe).transform(this.i18n.page.snackBarAssignTemplate, { name: result.name });

        this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', {
          duration: 5000,
        });

        await this.refreshData();
        this.selectedUsers = [];
      }
    });
  }

  protected getCurrentQueryValues(): {
    status: Array<string>;
    companies: Array<string>;
    offices: Array<string>;
    departments: Array<string>;
    teams: Array<string>;
    areas: Array<string>;
  } {
    const selectedValues = { status: [], companies: [], offices: [], departments: [], teams: [], areas: [] };

    selectedValues.status = this.queryOptions['user-account']?.where ? Object.keys(this.queryOptions['user-account']?.where) : [];
    selectedValues.companies = this.queryOptions['user-work']?.where?.companyId?.$in ?? [];
    selectedValues.offices = this.queryOptions['user-work']?.where?.officeId?.$in ?? [];
    selectedValues.departments = this.queryOptions['user-work']?.where?.departmentId?.$in ?? [];
    selectedValues.teams = this.queryOptions['user-work']?.where?.teamIds?.$in ?? [];
    selectedValues.areas = this.queryOptions['user-work']?.where?.areaIds?.$in ?? [];

    return selectedValues;
  }

  protected async executeActionOnFilter(isActive, value, fieldName): Promise<void> {
    if (fieldName === 'status') {
      const acceptedValues = [pickLists.USER_STATUS_ACTIVE, pickLists.USER_STATUS_NOTACTIVATED, pickLists.USER_STATUS_DEACTIVATED];

      if (!acceptedValues.includes(value)) {
        return;
      }

      isActive ? this.addStatusFilter(value) : this.removeStatusFilter(value);

      return;
    }

    isActive ? this.addFilter(value, fieldName) : this.removeFilter(value, fieldName);
  }

  protected async clearFilter(field): Promise<void> {
    this.resetView();

    const fieldName = this.fieldToQueryOptionMap[field];
    const model = field === 'status' ? 'user-account' : 'user-work';

    if (check.assigned(this.queryOptions[model]?.where[fieldName])) {
      delete this.queryOptions[model].where[fieldName];
    } else if (model === 'user-account') {
      delete this.queryOptions[model].where;
    }

    this.refreshAfterResettingPagination();
  }

  navigateToDataImport() {
    this.router.navigateByUrl(`cloud/settings/data-import`);
  }

  public async saveColumns(columns: Array<string>): Promise<void> {
    if (check.not.assigned(columns)) {
      return;
    }
    await this.injector.get(PreferenceService).setPreferenceByKey(this.PREFERENCE_OPTION_COLUMNS_KEY, columns, this.PREFERENCE_TYPE);
    this.displayedColumns = columns;
  }

  private async getColumns(): Promise<void> {
    const preferenceResult: IPreferenceModel = await this.injector
      .get(PreferenceService)
      .getPreferenceByKey(this.PREFERENCE_OPTION_COLUMNS_KEY);
    if (!preferenceResult?.preference || check.not.array(preferenceResult.preference)) {
      this.displayedColumns = this.totalColumns;
      return;
    }
    this.displayedColumns = preferenceResult.preference;
  }
}
