<orgos-dialog-container [title]="dialogTranslation.dialogHeader" (clickCloseButton)="closeDialog()">
  <div class="kenjo-font-color-light-text-757575">
    {{ subtitleText }}
  </div>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()"> {{miscTranslation.goBackButtonDialog}}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Success" (click)="sendReminder()"> {{dialogTranslation.sendReminderButton}}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
