import { Location } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IWorkflowFilter } from '@app/standard/components/workflow-filter/interface/workflow-filter';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { AddWorkflowActionDialog } from '@app/standard/pages/settings-workflows/add-worfklow-action-dialog/add-workflow-action.dialog';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { StandardServicesRegistry } from '@app/standard/services/standard-services.registry';
import { WorkflowActionService } from '@app/standard/services/workflow/workflow-action.service';
import { WorkflowService } from '@app/standard/services/workflow/workflow.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import { getPossibleValuesOfReference } from '@carlos-orgos/orgos-utils/metadata-describer';
import { environment } from '@env';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'orgos-workflow-summary',
  templateUrl: 'workflow-summary.page.html',
  styleUrls: ['workflow-summary.page.scss'],
})
export class WorkflowSummaryPage implements OnInit, OnDestroy {
  pageTranslation: any;
  miscTranslation: any = {};
  standardPicklists: any = {};
  objectTranslation: any = {};
  wfActionTranslation: any = {};
  targetObjectTranslation: any;
  editWorkflowTranslation: any = {};
  collectionFields: Array<any>;
  listActions: Array<GenericCacheModel>;
  newAction: GenericCacheModel;
  displayedColumns: Array<string> = ['name', 'type', 'actions'];
  filters: Array<{ conditions: Array<IWorkflowFilter> }> = [];
  recurrentWorkflowTimeRange: string = '';
  nextRecurringWorkflowDateTranslated: string = '';
  fieldNameOptionsMap: any = {};
  WORKFLOW_AGENDA_TIME: number = 7; // Workflows that are run automatically will be run at 7am (UTC)

  @Input() workflow: GenericCacheModel;
  @Input() customFieldsAvailable: Array<any>;
  @Input() chosenCollection: string;
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

  private backButtonSubscription: Subscription;

  constructor(
    private location: Location,
    private injector: Injector,
    private standardServicesRegistry: StandardServicesRegistry,
    public snackBar: MatLegacySnackBar,
    private dialog: MatLegacyDialog
  ) {
    // When the user clicks on the back button of the Browser:
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.onBackClick(false);
      }
    }) as Subscription;
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    this.injector.get(GlobalBarService).setEnableDefaultBars(false, true);
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('workflow-summary-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((standardPicklists) => {
        this.standardPicklists = standardPicklists;
      })
      .catch(() => {
        this.standardPicklists = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('workflow-action-collection')
      .then((wfActionTranslation) => {
        this.wfActionTranslation = wfActionTranslation;
      })
      .catch(() => {
        this.wfActionTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('workflow-collection')
      .then((objectTranslation) => {
        this.objectTranslation = objectTranslation;
      })
      .catch(() => {
        this.objectTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    let inCollection = this.workflow.data['inCollection'];
    if (
      check.contains(
        [
          'user-account',
          'user-work',
          'user-personal',
          'user-home',
          'user-address',
          'user-emergency',
          'user-financial',
          'user-confidential',
        ],
        inCollection
      )
    ) {
      inCollection = 'user';
    }

    if (inCollection === 'recurrent') {
      this.targetObjectTranslation = {};
    } else {
      this.injector
        .get(InternationalizationService)
        .getAllTranslation(`${inCollection}-collection`)
        .then((targetObjectTranslation) => {
          this.targetObjectTranslation = targetObjectTranslation;
        })
        .catch(() => {
          this.targetObjectTranslation = {};
        });
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-workflow-page')
      .then((editWorkflowTranslation) => {
        this.editWorkflowTranslation = editWorkflowTranslation;
        this.initRecurrentWorkflowTranslations();
      })
      .catch(() => {
        this.editWorkflowTranslation = {};
      });

    this.initListActions();
    this.initCollectionFields();
    this.initListWorkflowFilters();
  }

  private initListWorkflowFilters(): void {
    this.filters = [];
    if (
      check.assigned(this.workflow.data['conditions']) &&
      check.array(this.workflow.data['conditions']) &&
      check.nonEmptyArray(this.workflow.data['conditions'])
    ) {
      this.filters = this.workflow.data['conditions'].map((iFilter) => {
        const conditions = iFilter.map((iCondition) => {
          return {
            id: Math.random(),
            field: iCondition.fieldName,
            condition: iCondition.operatorNew,
            value: this.customCasting(iCondition.equalToValue, iCondition.equalToValueType, iCondition.fieldName),
            valueType: iCondition.equalToValueType,
          };
        });
        return { conditions };
      });
    }
  }

  private getNextRecurringWorkflowDate() {
    this.injector
      .get(WorkflowService)
      .getNextRecurringDate(this.workflow.data._id)
      .then((recurringInfo) => {
        if (recurringInfo && recurringInfo.nextDateExecution) {
          const nextDateExecution = moment(recurringInfo.nextDateExecution).add(this.WORKFLOW_AGENDA_TIME, 'hours');
          const translationData = {
            nextDateExecution: nextDateExecution.format('ll'),
            runsAt: nextDateExecution.format('HH:mm'),
          };

          if (
            this.workflow.data.scheduledOnSpecificDate.stopAfter &&
            moment(recurringInfo.nextDateExecution).isAfter(this.workflow.data.scheduledOnSpecificDate.stopAfter)
          ) {
            this.nextRecurringWorkflowDateTranslated = this.editWorkflowTranslation.workflowWontBeRun;
          } else {
            this.nextRecurringWorkflowDateTranslated = this.injector
              .get(I18nDataPipe)
              .transform(this.editWorkflowTranslation.recurringWorkflowNextDate, translationData);
          }
        }
      })
      .catch(() => {
        this.nextRecurringWorkflowDateTranslated = '';
      });
  }

  private customCasting(value: string, castingToType: string, fieldName: string): any {
    if (check.not.assigned(value)) {
      return null;
    } else if (check.equal('boolean', castingToType)) {
      return check.equal('true', value);
    } else if (check.equal('number', castingToType) && (check.equal('_startTime', fieldName) || check.equal('_endTime', fieldName))) {
      return this.convertMinutesToTime(value);
    } else if (check.equal('number', castingToType)) {
      return Number(value) ? Number(value) : value;
    } else if (check.equal('date', castingToType)) {
      return moment.utc(value).format('L');
    }
    return value;
  }

  convertMinutesToTime(value): string {
    const hourAux = parseInt((value / 60).toString().split('.')[0]);
    const hours = hourAux >= 10 ? hourAux : `0${hourAux}`;
    const minutesAux = parseInt(value) % 60;
    const minutes = minutesAux >= 10 ? minutesAux : `0${minutesAux}`;
    const time = `${hours}:${minutes}`;
    return time.toString();
  }

  private initListActions(): void {
    const workflowActionServiceClass = this.standardServicesRegistry.getService('WorkflowAction');
    this.injector
      .get(WorkflowActionService)
      .getActionsForWorkflow(this.workflow.data[fieldConstants.ID])
      .then((workflowActions) => {
        this.listActions = [];
        workflowActions.forEach((iWorkflowAction) => {
          this.listActions.push(new GenericCacheModel(this.injector, iWorkflowAction, workflowActionServiceClass));
        });
      })
      .catch(() => {
        this.listActions = [];
      });
  }

  private initRecurrentWorkflowTranslations(): void {
    if (!this.workflow.data || this.workflow.data.delayInstant !== 'recurrent') {
      return;
    }

    const everyX =
      this.workflow.data.scheduledOnSpecificDate.conditionsForEvery && this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX
        ? this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.everyX
        : 0;
    const translationData = {
      startDate: this.workflow.data.scheduledOnSpecificDate.startDate,
      stopAfter: this.workflow.data.scheduledOnSpecificDate.stopAfter,
      everyX: everyX,
      onWeekday: '',
    };
    if (translationData.stopAfter) {
      translationData.stopAfter = moment(translationData.stopAfter).format('ll');
    }

    let isSingular = '';
    if (translationData.everyX <= 1) {
      isSingular = '__singular';
    }

    const translatedWeekdays = this.injector.get(InternationalizationService).getTranslatedWeekdays();
    switch (this.workflow.data.scheduledOnSpecificDate.period) {
      case 'once':
        this.recurrentWorkflowTimeRange = this.injector
          .get(I18nDataPipe)
          .transform(this.editWorkflowTranslation[`recurrentTimeRangeSummaryOnce${isSingular}`], translationData);
        break;
      case 'day':
        this.recurrentWorkflowTimeRange = this.injector
          .get(I18nDataPipe)
          .transform(this.editWorkflowTranslation[`recurrentTimeRangeSummaryDaily${isSingular}`], translationData);
        break;
      case 'week':
        // onWeekday value is related to a week that starts on Sunday, but translatedWeekdays is a week array that starts on Monday
        const onWeekday = this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onWeeklyConditions.onWeekday;
        translationData.onWeekday =
          onWeekday === 0 ? translatedWeekdays[6]?.toLowerCase() : translatedWeekdays[onWeekday - 1]?.toLowerCase();
        this.recurrentWorkflowTimeRange = this.injector
          .get(I18nDataPipe)
          .transform(this.editWorkflowTranslation[`recurrentTimeRangeSummaryWeekly${isSingular}`], translationData);
        break;
      case 'month':
        if (!this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificDate) {
          translationData.onWeekday =
            translatedWeekdays[this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onWeekday]?.toLowerCase();
          const onSpecificWeek = this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificWeek;
          translationData['onSpecificWeek'] = '';
          if (onSpecificWeek === 'first') {
            translationData['onSpecificWeek'] = this.editWorkflowTranslation.firstWeek.toLowerCase();
          } else if (onSpecificWeek === 'last') {
            translationData['onSpecificWeek'] = this.editWorkflowTranslation.lastWeek.toLowerCase();
          } else if (onSpecificWeek === 2 || onSpecificWeek === '2') {
            translationData['onSpecificWeek'] = this.editWorkflowTranslation['2ndWeek'];
          } else if (onSpecificWeek === 3 || onSpecificWeek === '3') {
            translationData['onSpecificWeek'] = this.editWorkflowTranslation['3rdWeek'];
          } else if (onSpecificWeek === 4 || onSpecificWeek === '4') {
            translationData['onSpecificWeek'] = this.editWorkflowTranslation['4thWeek'];
          }

          if (onSpecificWeek === 'first' || onSpecificWeek === 'last') {
            // For first or last, use translation that builds something like "first monday of every month"
            this.recurrentWorkflowTimeRange = this.injector
              .get(I18nDataPipe)
              .transform(
                this.editWorkflowTranslation[`recurrentTimeRangeSummaryMonthlyNonSpecificForFirstOrLast${isSingular}`],
                translationData
              );
          } else {
            this.recurrentWorkflowTimeRange = this.injector
              .get(I18nDataPipe)
              .transform(this.editWorkflowTranslation[`recurrentTimeRangeSummaryMonthlyNonSpecific${isSingular}`], translationData);
          }
        } else {
          translationData['onSpecificDate'] =
            this.workflow.data.scheduledOnSpecificDate.conditionsForEvery.onMonthlyConditions.onSpecificDate;
          this.recurrentWorkflowTimeRange = this.injector
            .get(I18nDataPipe)
            .transform(this.editWorkflowTranslation[`recurrentTimeRangeSummaryMonthlySpecific${isSingular}`], translationData);
        }
        break;
      case 'year':
        translationData['dayNumber'] = moment(this.workflow.data.scheduledOnSpecificDate.startDate).date();
        translationData['monthName'] = moment(this.workflow.data.scheduledOnSpecificDate.startDate).format('MMMM');
        this.recurrentWorkflowTimeRange = this.injector
          .get(I18nDataPipe)
          .transform(`${this.editWorkflowTranslation.recurrentTimeRangeSummaryYearly}`, translationData);
        break;
      default:
        this.recurrentWorkflowTimeRange = '';
        break;
    }

    const todayStart = moment().startOf('d');
    if (
      this.workflow.data.scheduledOnSpecificDate.stopAfter &&
      moment(this.workflow.data.scheduledOnSpecificDate.stopAfter).isSameOrAfter(todayStart)
    ) {
      this.recurrentWorkflowTimeRange = `${this.recurrentWorkflowTimeRange}. ${this.injector
        .get(I18nDataPipe)
        .transform(this.editWorkflowTranslation.recurrentTimeRangeStopsAfter, translationData)}`;
    } else if (this.workflow.data.scheduledOnSpecificDate.stopAfter) {
      this.recurrentWorkflowTimeRange = `${this.recurrentWorkflowTimeRange}. ${this.injector
        .get(I18nDataPipe)
        .transform(this.editWorkflowTranslation.recurrentTimeRangeStoppedOn, translationData)}`;
    }
  }

  private initCollectionFields(): void {
    const collectionToServiceName = {
      document: 'Document',
      feed: 'Feed',
      task: 'Task',
      'time-off-request': 'TimeOffRequest',
      user: 'User',
      'user-confidential': 'User',
      'user-home': 'User',
      'user-work': 'User',
      position: 'Position',
      candidate: 'Candidate',
      'position-candidate': 'PositionCandidate',
      project: 'Project',
      'project-member': 'ProjectMember',
      'project-time-entry': 'ProjectTimeEntry',
    };
    if (this.workflow.data['inCollection'] === 'recurrent') {
      this.getNextRecurringWorkflowDate();
      return;
    }
    const serviceName = this.workflow.data['inCollection'].startsWith('user-')
      ? 'User'
      : collectionToServiceName[this.workflow.data['inCollection']];
    const serviceClass = this.standardServicesRegistry.getService(serviceName);
    this.injector
      // tslint:disable-next-line: deprecation
      .get(serviceClass)
      .getModel()
      .then((fieldsResult: any) => {
        this.collectionFields = fieldsResult;
        const usedFields = new Set();
        this.workflow.data['conditions'].forEach((iFilter) => {
          iFilter.forEach((iCondition) => {
            if (
              iCondition['equalToValueType'] === 'objectid' ||
              iCondition['equalToValueType'] === 'array' ||
              iCondition['fieldName'] === 'userAccount.profileKey'
            ) {
              usedFields.add(this.initLookupSelectOption(iCondition.fieldName));
            }
          });
          Promise.all([...usedFields]).then((asd) => {});
        });
      })
      .catch((error) => {
        // Error is already shown
        this.collectionFields = [];
      });
  }

  private async initLookupSelectOption(collectionField: any): Promise<void> {
    if (check.not.assigned(collectionField) || check.emptyString(collectionField)) {
      return;
    }

    let collectionName = this.chosenCollection;
    let fieldName = collectionField;
    if (check.contains(collectionField, '.')) {
      collectionName = collectionField.split('.')[0].replace('user', 'user-').toLowerCase();
      fieldName = collectionField.split('.')[1];
    }

    const selectOptions = await getPossibleValuesOfReference(
      this.injector.get(AuthenticationService).getAuthorizationHeader(),
      environment.PEOPLE_CLOUD_APP_URL,
      collectionName,
      fieldName
    );
    this.fieldNameOptionsMap[collectionField] = {};
    selectOptions.forEach((iOption) => {
      this.fieldNameOptionsMap[collectionField][iOption.value] = iOption.name;
    });
  }

  addAction(): void {
    const workflowActionServiceClass = this.standardServicesRegistry.getService('WorkflowAction');
    const rawWorkflowAction = {
      type: 'email_alert',
      data: {},
    };

    rawWorkflowAction[`${fieldConstants.READ_ONLY_PREFIX}workflowId`] = this.workflow.data[fieldConstants.ID];
    this.newAction = new GenericCacheModel(this.injector, rawWorkflowAction, workflowActionServiceClass);

    const dataForDialog = {
      workflowAction: this.newAction,
      collectionFields: this.collectionFields,
      collectionTranslation: this.targetObjectTranslation,
      inCollection: this.workflow.data.inCollection,
      customFieldsAvailable: this.customFieldsAvailable,
    };
    const dialogRef = this.dialog.open(AddWorkflowActionDialog, { data: dataForDialog });
    dialogRef.afterClosed().subscribe((cancelled: boolean) => {
      if (check.not.assigned(cancelled) || check.not.boolean(cancelled) || cancelled === false) {
        this.newAction = null;
        return;
      } else {
        this.snackBar.open(`${this.pageTranslation.createActionSnackbarMessage}`, 'OK', { duration: 5000 });
        this.initListActions();
      }
    });
  }

  editAction(action: GenericCacheModel): void {
    this.newAction = action;

    const dataForDialog = {
      workflowAction: this.newAction,
      collectionFields: this.collectionFields,
      collectionTranslation: this.objectTranslation,
      inCollection: this.workflow.data.inCollection,
    };
    const dialogRef = this.dialog.open(AddWorkflowActionDialog, { data: dataForDialog });
    dialogRef.afterClosed().subscribe((cancelled: boolean) => {
      if (check.not.assigned(cancelled) || check.not.boolean(cancelled) || cancelled === false) {
        this.newAction = null;
        return;
      } else {
        this.initListActions();
        this.snackBar.open(`${this.pageTranslation.editActionSnackbarMessage}`, 'OK', { duration: 5000 });
      }
    });
  }

  deleteAction(action: GenericCacheModel): void {
    const data = {
      titleText: this.pageTranslation.confirmDeleteTitle,
      subtitleText: this.pageTranslation.confirmDeleteSubtitle,
      confirmButtonText: this.pageTranslation.confirmDeleteButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        const actionId = action.data[fieldConstants.ID];
        this.injector
          .get(WorkflowActionService)
          .deleteById(actionId)
          .then(() => {
            this.initListActions();
            this.snackBar.open(`${this.pageTranslation.deleteActionSnackbarMessage}`, 'OK', { duration: 5000 });
          });
      }
    });
  }

  onBackClick(changeLocationState: boolean = true): void {
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, changeLocationState);
    this.goBack.emit();
  }

  getLabel(inCollection: string): string {
    if (check.not.assigned(inCollection) || check.emptyString(inCollection)) {
      return '';
    }

    if (inCollection.startsWith('user-')) {
      return this.editWorkflowTranslation['userCollection'];
    }
    return this.editWorkflowTranslation[`${inCollection}Collection`]
      ? this.editWorkflowTranslation[`${inCollection}Collection`]
      : inCollection;
  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();
  }
}
