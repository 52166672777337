import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PresenceValidationController {
  private PRESENCE_VALIDATION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/user-attendance/presence-validation`;

  constructor(private injector: Injector) {}

  async getPresenceValidation(date: string): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.PRESENCE_VALIDATION_URL}`, ErrorCodes.UNAUTHORIZED, PresenceValidationController.name, 'getPresenceValidation');
        throw error;
      }

      const isoDate = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.sssZ', true);

      if (check.not.assigned(date) || !isoDate.isValid() || isoDate.isAfter(moment.utc())) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.BAD_REQUEST, PresenceValidationController.name, 'getPresenceValidation');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get(`${this.PRESENCE_VALIDATION_URL}/${date}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PresenceValidationController.name, 'getPresenceValidation');
    }
  }
}
