import { Component, Input } from '@angular/core';

@Component({
  selector: 'orgos-document-icon',
  templateUrl: 'document-icon.component.html',
  styleUrls: ['document-icon.component.scss']
})
export class DocumentIconComponent {
  private _extension: string = '';
  @Input()
  set extension(extension: string) {
    this._extension = (extension ?? '').substring(0, 4).toUpperCase();
  }

  get extension(): string {
    return this._extension;
  }

  @Input() size: 'small' | 'normal' = 'normal';

  get isNormalSize(): boolean {
    return this.size === 'normal';
  }
}
