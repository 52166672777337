<orgos-dialog-container class="dialog-box" [title]="i18n.title" (clickCloseButton)="closeDialog()">
  <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-mb-30px">
    {{i18n.yourOvertimeBalance}}
  </div>
  <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-mb-30px">
    {{i18n.trackedHoursExpectedHours}}
  </div>
  <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-mb-30px">
    {{i18n.inGeneral}}
  </div>
  <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-mb-30px">
    {{i18n.negativeBalanceFormula}}
  </div>
  <div class="kenjo-font-size-14px kenjo-font-color-text-333333 kenjo-mb-30px">
    {{i18n.negativeBalanceExplanation}}
  </div>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Success">{{i18n.gotIt}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
