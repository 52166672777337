import { Component, Injector, Input, OnInit } from '@angular/core';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

import { StandardServicesRegistry } from '../../services/standard-services.registry';

@Component({
  selector: 'orgos-tag-list',
  templateUrl: 'tag-list.component.html',
  styleUrls: ['tag-list.component.scss']
})
export class TagListComponent implements OnInit {
  @Input() tagList: Array<any>;

  pageTranslation: any = {};

  constructor(private injector: Injector, private standardServicesRegistry: StandardServicesRegistry) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('pulse-insights-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
  }

  getColor(colorKey: string): string {
    return userColorConstants[colorKey];
  }
}
