import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { IPeopleDirectory } from '@app/standard/services/data-engine/people-directory.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as d3 from 'd3';
import * as moment from 'moment';
import PDFDocument from 'pdfkit/js/pdfkit.standalone';
import * as SVGtoPDF from 'svg-to-pdfkit';

import { AuthenticationService } from '../../../../../services/core/authentication.service';
import { InternationalizationService } from '../../../../../services/core/internationalization.service';

@Component({
  selector: 'kenjo-org-chart',
  templateUrl: 'org-chart.component.html',
  styleUrls: ['org-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrgChartComponent implements OnInit {
  @Input() tree: any = null;
  @Input() svgPosition: d3.ZoomTransform;
  @Input() enabledEditMode: boolean = false;
  @Input() isStandardOrgChart: boolean = false;

  private _searchTerms: string;
  public get searchTerms(): string {
    return this._searchTerms;
  }
  @Input() public set searchTerms(value: string) {
    this._searchTerms = value;
    this.searchOrgChartTree();
  }
  @Output() addNode: EventEmitter<{ operation: 'addManager' | 'addRightPeer' | 'addLeftPeer' | 'addSubordinate'; node: any }> =
    new EventEmitter();
  @Output() editNode: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteNode: EventEmitter<any> = new EventEmitter<any>();
  @Output() failedSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchResults: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('moreMenuTrigger', { read: MatLegacyMenuTrigger }) moreMenuTrigger;
  xPositionMoreMenu: number = 0;
  yPositionMoreMenu: number = 0;

  FILENAME: string = 'Org Chart';
  params: any = {};
  treeElements: any = {};
  svgTreeContainer: any = {};
  toolTipContainer: any = {};
  zoom: any;
  showFindMeButton: boolean = false;

  translation: any = {};

  constructor(private injector: Injector, private router: Router) {}
  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('org-chart-component')
      .then((translation) => {
        this.translation = translation;
        this.createOrgChart();
      })
      .catch(() => {
        // Do nothing
      });
  }

  searchOrgChartTree(): void {
    if (check.not.assigned(this.searchTerms) || check.emptyString(this.searchTerms) || check.less(this.searchTerms.length, 2)) {
      this.failedSearch.emit(false);
      return;
    }
    this.treeElements = this.getTreeNodes(this.tree);
    const matchingNodes = this.treeElements
      .descendants()
      .filter((node) =>
        check.assigned(node.data)
          ? (check.assigned(node.data.name) && node.data.name.toLowerCase().includes(this.searchTerms.toLowerCase())) ||
            (check.assigned(node.data.jobTitle) && node.data.jobTitle.toLowerCase().includes(this.searchTerms.toLowerCase()))
          : false
      );
    if (check.emptyArray(matchingNodes)) {
      this.failedSearch.emit(true);
    } else if (check.one(matchingNodes.length)) {
      // If only 1 search result, highlight and pan to them
      this.moveTreeToSingleSearchResult(matchingNodes[0]);
      this.failedSearch.emit(false);
    } else {
      // If multiple results, highlight all of them, pan to the first, then show a message
      this.moveTreeToMultipleSearchResult(matchingNodes);
      this.failedSearch.emit(false);
    }
    this.searchResults.emit(matchingNodes.length);
  }

  /*
   * orgChartConfiguration()
   * Coordinates and tree components sizes
   */
  private orgChartConfiguration(): object {
    const cardStyle = {
      cardWidth: 140,
      cardHeight: 130,
      cardStrokeRadius: 5,
      cardStrokeColor: '#dedede',
      cardStrokeWidth: 2,
      cardHeightTopBar: 5,
      cardEditBarWidth: 20,
    };

    const params: any = {
      // SVG
      svgWidth: 0,
      svgHeight: 0,

      // Cards
      genericCardStyles: cardStyle,

      xSVGPosition: 653,
      ySVGPosition: -60,
      hSeparation: this.isStandardOrgChart ? 165 : this.enabledEditMode ? 235 : 215,
      vSeparation: cardStyle.cardHeight + 70,

      radiusHalfCircle: 40,

      // Animation
      findTransitionTranslateDuration: 750,
      findTransitionColorDurationGo: 750,
      findTransitionColorDurationReturn: 5000,
    };

    params.EmployeeCard = {
      ...params.genericCardStyles,

      // Avatar options
      avatarRadius: 30,
      xAvatarPosition: cardStyle.cardWidth / 2,
      yAvatarPosition: 45,
      avatarTextFontSize: 18,
      xAvatarTextPosition: cardStyle.cardWidth / 2,
      yAvatarTextPosition: 51,

      // Name options
      xEmployeeNamePosition: cardStyle.cardWidth / 2,
      yEmployeeNamePosition: 95,
      employeeNameFontSize: 14,
      employeeNameMaxLength: 15,

      // Job title options
      xEmployeeJobTitlePosition: cardStyle.cardWidth / 2,
      yEmployeeJobTitlePosition: 115,
      employeeJobTitleFontSize: 12,
      employeeJobTitleColor: '#757575',
      employeeJobTitleMaxLength: 15,

      // Opacity
      opaque: 1,
      translucent: 0.4,
    };

    params.CompanyCard = {
      ...params.genericCardStyles,

      // Avatar options
      avatarRadius: 30,
      xAvatarPosition: cardStyle.cardWidth / 2,
      yAvatarPosition: 45,
      xAvatarIconPosition: 55,
      yAvatarIconPosition: 29,

      // Name options
      xCompanyNamePosition: cardStyle.cardWidth / 2,
      yCompanyNamePosition: 95,
      companyNameFontSize: 14,
      companyNameMaxLength: 15,
    };

    params.AreaCard = {
      ...params.genericCardStyles,

      // Avatar options
      avatarRadius: 30,
      xAvatarPosition: cardStyle.cardWidth / 2,
      yAvatarPosition: 45,
      xAvatarIconPosition: 52,
      yAvatarIconPosition: 27,

      // Name options
      xAreaNamePosition: cardStyle.cardWidth / 2,
      yAreaNamePosition: 95,
      areaNameFontSize: 14,
      areaNameMaxLength: 15,
    };

    params.OfficeCard = {
      ...params.genericCardStyles,

      // Avatar options
      avatarRadius: 30,
      xAvatarPosition: cardStyle.cardWidth / 2,
      yAvatarPosition: 45,
      xAvatarIconPosition: 55,
      yAvatarIconPosition: 30,

      // Name options
      xOfficeNamePosition: cardStyle.cardWidth / 2,
      yOfficeNamePosition: 95,
      officeNameFontSize: 14,
      officeNameMaxLength: 15,
    };

    params.DepartmentCard = {
      ...params.genericCardStyles,

      // Avatar options
      avatarRadius: 30,
      xAvatarPosition: cardStyle.cardWidth / 2,
      yAvatarPosition: 45,
      xAvatarIconPosition: 54,
      yAvatarIconPosition: 32,

      // Name options
      xDepartmentNamePosition: cardStyle.cardWidth / 2,
      yDepartmentNamePosition: 95,
      departmentNameFontSize: 14,
      departmentNameMaxLength: 15,
    };

    params.TeamCard = {
      ...params.genericCardStyles,

      // Avatar options
      avatarRadius: 30,
      xAvatarPosition: cardStyle.cardWidth / 2,
      yAvatarPosition: 45,
      xAvatarIconPosition: 52,
      yAvatarIconPosition: 27,

      // Name options
      xTeamNamePosition: cardStyle.cardWidth / 2,
      yTeamNamePosition: 95,
      teamNameFontSize: 14,
      teamNameMaxLength: 15,
    };

    params.FutureRoleCard = {
      ...params.genericCardStyles,

      // Avatar options
      avatarRadius: 30,
      xAvatarPosition: cardStyle.cardWidth / 2,
      yAvatarPosition: 45,
      xAvatarIconPosition: 52,
      yAvatarIconPosition: 25,

      // Name options
      xFutureRoleNamePosition: cardStyle.cardWidth / 2,
      yFutureRoleNamePosition: 95,
      futureRoleNameFontSize: 14,
      futureRoleNameMaxLength: 15,
    };

    params.AssistantCard = {
      ...params.EmployeeCard,

      // Assistant icon options
      xAssistantIconPosition: 75,
      yAssistantIconPosition: 55,
      assistantIconWidth: 25,
      assistantIconHeight: 25,
    };

    params.StandardEmployeeCard = {
      ...params.EmployeeCard,

      cardHeightWithBanner: cardStyle.cardHeight + 9,

      // Banner
      bannerHeight: 18,
      maxBannerTextLength: 10,
      bannerTextFontSize: 11,
      xBannerTextPosition: cardStyle.cardWidth / 2,
      yBannerTextPosition: cardStyle.cardHeight + 5,
    };

    params.StandardAssistantCard = {
      ...params.AssistantCard,

      cardHeightWithBanner: cardStyle.cardHeight + 9,

      // Banner
      bannerHeight: 18,
      maxBannerTextLength: 10,
      bannerTextFontSize: 11,
      xBannerTextPosition: cardStyle.cardWidth / 2,
      yBannerTextPosition: cardStyle.cardHeight + 5,
    };

    return params;
  }

  /*
   ** createOrgChart()
   ** Create OrgChart from input (tree)
   */
  private createOrgChart(): void {
    // Get config tree params
    this.params = this.orgChartConfiguration();
    // Built expected data from input for painting the Org Chart
    this.treeElements = this.getTreeNodes(this.tree);
    // Create DOM containers
    this.svgTreeContainer = this.getSvgTreeContainer();
    this.toolTipContainer = this.getTooltipContainer();
    this.initZoom();
    this.moveTreeToMe();
  }

  /*
   ** getTreeNodes(object)
   ** Retrieves a d3js tree
   */
  private getTreeNodes(orgChartData: object): object {
    // declares a tree layout and assigns the size
    const tree = d3
      .tree()
      .nodeSize([this.params.hSeparation, this.params.vSeparation])
      .separation(() => {
        return 1;
      });

    // assigns the data to a hierarchy using parent-child relationships
    const hierarchyData = d3.hierarchy(orgChartData);

    // maps the node data to the tree layout
    const treeNodes = tree(hierarchyData);
    return treeNodes;
  }

  private getSvgTreeContainer(): object {
    // Select and create SVG container
    const svg = d3.select('.org-chart-box').append('svg').style('width', '100%').style('height', '100%').attr('id', 'orgChart');
    // Get the width and height of SVG Container
    const containerWidthString = d3.select('.org-chart-box').style('width');
    const containerHeightString = d3.select('.org-chart-box').style('height');
    this.params.xSVGPosition = parseInt(containerWidthString.replace('px', ''), 10) / 2;
    this.params.svgWidth = parseInt(containerWidthString.replace('px', ''), 10);
    this.params.svgHeight = parseInt(containerHeightString.replace('px', ''), 10);
    // Adds the group
    const g = svg.append('g').attr('transform', `translate(${this.params.xSVGPosition},${this.params.ySVGPosition})`);
    return svg;
  }

  private getTooltipContainer(): object {
    const tooltip = d3.select('.org-chart-box').append('div').attr('class', 'tooltip').style('opacity', 0);
    return tooltip;
  }

  private update(): void {
    this.svgTreeContainer.select('g').selectAll('.node').remove();
    this.svgTreeContainer.select('g').selectAll('.link').remove();

    // DRAW LINKS
    const link = this.drawLink();

    // DRAW NODE
    const node = this.drawNode();

    // DRAW STANDARD EMPLOYEE CARD
    const standardEmployeeNode = node.filter((d) => {
      return d.data.nodeType === 'StandardEmployee';
    });
    this.drawStandardEmployeeCard(standardEmployeeNode, this.params.StandardEmployeeCard);

    // DRAW STANDARD ASSISTANT CARD
    const standardAssistantNode = node.filter((d) => {
      return d.data.nodeType === 'StandardAssistant';
    });
    this.drawStandardAssistantCard(standardAssistantNode, this.params.StandardAssistantCard);

    // DRAW EMPLOYEE CARD
    const employeeNode = node.filter((d) => {
      return d.data.nodeType === 'Employee';
    });
    this.drawEmployeeCard(employeeNode, this.params.EmployeeCard);

    // DRAW COMPANY CARD
    const companyNode = node.filter((d) => {
      return d.data.nodeType === 'Company';
    });
    this.drawCompanyCard(companyNode, this.params.CompanyCard);

    // DRAW AREA CARD
    const areaNode = node.filter((d) => {
      return d.data.nodeType === 'Area';
    });
    this.drawAreaCard(areaNode, this.params.AreaCard);

    // DRAW OFFICE CARD
    const officeNode = node.filter((d) => {
      return d.data.nodeType === 'Office';
    });
    this.drawOfficeCard(officeNode, this.params.OfficeCard);

    // DRAW DEPARTMENT CARD
    const departmentNode = node.filter((d) => {
      return d.data.nodeType === 'Department';
    });
    this.drawDepartmentCard(departmentNode, this.params.DepartmentCard);

    // DRAW TEAM CARD
    const teamNode = node.filter((d) => {
      return d.data.nodeType === 'Team';
    });
    this.drawTeamCard(teamNode, this.params.TeamCard);

    // DRAW FUTURE ROLE CARD
    const futureRoleNode = node.filter((d) => {
      return d.data.nodeType === 'FutureRole';
    });
    this.drawFutureRoleCard(futureRoleNode, this.params.FutureRoleCard);

    // DRAW ASSISTANT CARD
    const assistantNode = node.filter((d) => {
      return d.data.nodeType === 'Assistant';
    });
    this.drawAssistantCard(assistantNode, this.params.AssistantCard);
  }

  private drawNode(): any {
    const nodesData = this.treeElements.descendants();
    const node = this.svgTreeContainer.select('g').selectAll('.node').data(nodesData);

    // ADD NODES
    const nodeEnter = node
      .enter()
      .filter((d: any) => {
        return d.depth !== 0;
      })
      .append('g')
      .attr('class', (d: any) => {
        return `node ${d.children ? 'node--internal' : 'node--leaf'}`;
      })
      .attr('transform', (d: any) => {
        const xCorrection = this.params[`${d.data.nodeType}Card`].cardWidth / 2;
        const yCorrection = 65;

        return `translate(${d.x - xCorrection}, ${d.y - yCorrection})`;
      });

    return nodeEnter;
  }

  private drawStandardEmployeeCard(standardEmployeeNode: any, standardEmployeeParams: any): any {
    const standardEmployeeCard = this.addStandardCard(standardEmployeeNode, standardEmployeeParams);
    this.addEmployeeLink(standardEmployeeCard);
    this.addEmployeeAvatar(standardEmployeeCard, standardEmployeeParams);
    this.addEmployeeInfo(standardEmployeeCard, standardEmployeeParams);
    const childrenCounter = this.addChildrenCounter(standardEmployeeNode, standardEmployeeParams);
    childrenCounter.attr('transform', (d) => {
      // We adapt the position of childrenCounter in case the banner is included
      return this.includeBannerInStandardCard(d) ? 'translate(0, 10)' : 'translate(0,0)';
    });
    this.addEditButtons(standardEmployeeNode, standardEmployeeParams);
  }

  private drawStandardAssistantCard(standardAssistantNode: any, standardAssistantParams: any): any {
    const standardAssistantCard = this.addStandardCard(standardAssistantNode, standardAssistantParams);
    this.addEmployeeLink(standardAssistantCard);
    this.addEmployeeAvatar(standardAssistantCard, standardAssistantParams);
    this.addAssistantLogo(standardAssistantCard, standardAssistantParams);
    this.addEmployeeInfo(standardAssistantCard, standardAssistantParams);
    const childrenCounter = this.addChildrenCounter(standardAssistantNode, standardAssistantParams);
    childrenCounter.attr('transform', (d) => {
      // We adapt the position of childrenCounter in case the banner is included
      return this.includeBannerInStandardCard(d) ? 'translate(0, 10)' : 'translate(0,0)';
    });
    this.addEditButtons(standardAssistantNode, standardAssistantParams);
  }

  private drawEmployeeCard(employeeNode: any, employeeParams: any): any {
    const employeeCard = this.addCard(employeeNode, employeeParams);
    this.addEmployeeLink(employeeCard);
    this.addEmployeeAvatar(employeeCard, employeeParams);
    this.addEmployeeInfo(employeeCard, employeeParams);
    this.addChildrenCounter(employeeNode, employeeParams);
    this.addEditButtons(employeeNode, employeeParams);
  }

  private drawCompanyCard(companyNode: any, companyParams: any): any {
    const companyCard = this.addCard(companyNode, companyParams);
    this.addCompanyAvatar(companyCard, companyParams);
    this.addCompanyInfo(companyCard, companyParams);
    this.addChildrenCounter(companyNode, companyParams);
    this.addEditButtons(companyNode, companyParams);
  }

  private drawAreaCard(areaNode: any, areaParams: any): any {
    const areaCard = this.addCard(areaNode, areaParams);
    this.addAreaAvatar(areaCard, areaParams);
    this.addAreaInfo(areaCard, areaParams);
    this.addChildrenCounter(areaNode, areaParams);
    this.addEditButtons(areaNode, areaParams);
  }

  private drawOfficeCard(officeNode: any, officeParams: any): any {
    const officeCard = this.addCard(officeNode, officeParams);
    this.addOfficeAvatar(officeCard, officeParams);
    this.addOfficeInfo(officeCard, officeParams);
    this.addChildrenCounter(officeNode, officeParams);
    this.addEditButtons(officeNode, officeParams);
  }

  private drawDepartmentCard(departmentNode: any, departmentParams: any): any {
    const departmentCard = this.addCard(departmentNode, departmentParams);
    this.addDepartmentAvatar(departmentCard, departmentParams);
    this.addDepartmentInfo(departmentCard, departmentParams);
    this.addChildrenCounter(departmentNode, departmentParams);
    this.addEditButtons(departmentNode, departmentParams);
  }

  private drawTeamCard(teamNode: any, teamParams: any): any {
    const teamCard = this.addCard(teamNode, teamParams);
    this.addTeamAvatar(teamCard, teamParams);
    this.addTeamInfo(teamCard, teamParams);
    this.addChildrenCounter(teamNode, teamParams);
    this.addEditButtons(teamNode, teamParams);
  }

  private drawFutureRoleCard(futureRoleNode: any, futureRoleParams: any): any {
    const futureRoleCard = this.addCard(futureRoleNode, futureRoleParams);
    this.addFutureRoleAvatar(futureRoleCard, futureRoleParams);
    this.addFutureRoleInfo(futureRoleCard, futureRoleParams);
    this.addChildrenCounter(futureRoleNode, futureRoleParams);
    this.addEditButtons(futureRoleNode, futureRoleParams);
  }

  private drawAssistantCard(assistantNode: any, assistantParams: any): any {
    const assistantCard = this.addCard(assistantNode, assistantParams);
    this.addEmployeeLink(assistantCard);
    this.addEmployeeAvatar(assistantCard, assistantParams);
    this.addAssistantLogo(assistantCard, assistantParams);
    this.addEmployeeInfo(assistantCard, assistantParams);
    this.addChildrenCounter(assistantNode, assistantParams);
    this.addEditButtons(assistantNode, assistantParams);
  }

  private addStandardCard(node: any, cardParams: any): any {
    // Workaround to hide the background when using translucent cards
    const group = node.append('g');
    group
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', cardParams.cardWidth)
      .attr('height', (d) => {
        return this.includeBannerInStandardCard(d) ? cardParams.cardHeightWithBanner : cardParams.cardHeight;
      })
      .attr('fill', '#FFFFFF');

    const card = group.append('g').attr('fill-opacity', (d) => {
      return this.isTranslucentStandardCard(d) ? cardParams.translucent : cardParams.opaque;
    });

    // Card
    card
      .append('path')
      .attr('class', 'card-border')
      .attr('d', (d) => {
        const cardHeight = this.includeBannerInStandardCard(d) ? cardParams.cardHeightWithBanner : cardParams.cardHeight;
        return this.getRoundedRect(0, 0, cardParams.cardWidth, cardHeight, cardParams.cardStrokeRadius, true, true, true, true);
      })
      .attr('fill', '#FFFFFF')
      .attr('stroke', cardParams.cardStrokeColor)
      .attr('stroke-width', `${cardParams.cardStrokeWidth}px`);

    // Top bar
    card
      .append('path')
      .attr(
        'd',
        this.getRoundedRect(
          -1,
          -1,
          <number>cardParams.cardWidth + 2,
          cardParams.cardHeightTopBar,
          cardParams.cardStrokeRadius,
          true,
          true,
          false,
          false
        )
      )
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    // Add banner background if necessary
    card
      .filter((d) => {
        return this.includeBannerInStandardCard(d);
      })
      .append('path')
      .attr(
        'd',
        this.getRoundedRect(
          -1,
          cardParams.cardHeightWithBanner - cardParams.bannerHeight + 1,
          <number>cardParams.cardWidth + 2,
          cardParams.bannerHeight,
          cardParams.cardStrokeRadius,
          false,
          false,
          true,
          true
        )
      )
      .attr('fill', (d: any) => {
        if (check.assigned(d.data.startDate) && moment.utc().isBefore(d.data.startDate, 'days')) {
          return '#ffc107';
        } else if (check.assigned(d.data.terminationDate)) {
          return '#ff5757';
        }
      });

    // Add banner text if necessary
    const banner = card
      .filter((d) => {
        return this.includeBannerInStandardCard(d);
      })
      .append('text')
      .attr('x', cardParams.xBannerTextPosition)
      .attr('y', cardParams.yBannerTextPosition)
      .attr('text-anchor', 'middle')
      .attr('class', 'banner')
      .style('font-size', `${cardParams.bannerTextFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        if (check.assigned(d.data.startDate) && moment.utc().isBefore(d.data.startDate, 'days')) {
          return this.injector.get(DatePipe).transform(d.data.startDate, 'shortDate', 'UTC');
        } else if (check.assigned(d.data.terminationDate)) {
          return this.injector.get(DatePipe).transform(d.data.terminationDate, 'shortDate', 'UTC');
        } else {
          return this.translation.inactive;
        }
      });
    this.addToolTip(banner);

    return card;
  }

  private addCard(node: any, cardParams: any): any {
    // Workaround to hide the background when using translucent cards
    const group = node.append('g');
    group
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', cardParams.cardWidth)
      .attr('height', cardParams.cardHeight)
      .attr('fill', '#FFFFFF');

    const card = group.append('g').attr('fill-opacity', (d) => {
      return d.data.isArchived === true || d.data.isActive === false ? cardParams.translucent : cardParams.opaque;
    });

    // Card
    card
      .append('path')
      .attr('class', 'card-border')
      .attr(
        'd',
        this.getRoundedRect(0, 0, cardParams.cardWidth, cardParams.cardHeight, cardParams.cardStrokeRadius, true, true, true, true)
      )
      .attr('fill', '#FFFFFF')
      .attr('stroke', cardParams.cardStrokeColor)
      .attr('stroke-width', `${cardParams.cardStrokeWidth}px`);

    // Top bar
    card
      .append('path')
      .attr(
        'd',
        this.getRoundedRect(
          -1,
          -1,
          <number>cardParams.cardWidth + 2,
          cardParams.cardHeightTopBar,
          cardParams.cardStrokeRadius,
          true,
          true,
          false,
          false
        )
      )
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    return card;
  }

  private addEmployeeLink(employeeNode: any): void {
    employeeNode.on('click', async (_, d: any) => {
      if (
        this.enabledEditMode !== true &&
        (d.data.nodeType === 'Employee' ||
          d.data.nodeType === 'Assistant' ||
          d.data.nodeType === 'StandardEmployee' ||
          d.data.nodeType === 'StandardAssistant') &&
        d.data.isArchived === false
      ) {
        this.router.navigateByUrl(`/cloud/people/${d.data.employeeId}/personal`);
      }
    });
  }

  private addEmployeeAvatar(card: any, avatarParams: any): void {
    // circle container when there is no photo
    card
      .append('circle')
      .attr('id', 'sd')
      .attr('cx', avatarParams.xAvatarPosition)
      .attr('cy', avatarParams.yAvatarPosition)
      .attr('r', avatarParams.avatarRadius)
      .attr('fill', (d: any) => {
        return userColorConstants[d.data.color];
      });

    // avatar text
    card
      .append('text')
      .attr('x', avatarParams.xAvatarTextPosition)
      .attr('y', avatarParams.yAvatarTextPosition)
      .attr('text-anchor', 'middle')
      .attr('fill', '#FFFFFF')
      .style('font-size', `${avatarParams.avatarTextFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return this.calculateInitials(d.data.name);
      });

    // photo
    card
      .filter((d: any) => {
        return check.assigned(d.data._photo?._url) && check.nonEmptyString(d.data._photo?._url);
      })
      .append('defs')
      .append('clipPath')
      .attr('id', 'photo-circle')
      .append('circle')
      .attr('cx', avatarParams.xAvatarPosition)
      .attr('cy', avatarParams.yAvatarPosition)
      .attr('r', avatarParams.avatarRadius)
      .attr('fill', '#FFFFFF');

    card
      .filter((d: any) => {
        return check.assigned(d.data._photo?._url) && check.nonEmptyString(d.data._photo?._url);
      })
      .append('image')
      .attr('x', avatarParams.xAvatarPosition - avatarParams.avatarRadius)
      .attr('y', avatarParams.yAvatarPosition - avatarParams.avatarRadius)
      .attr('height', '60')
      .attr('width', '60')
      .attr('clip-path', 'url(#photo-circle)')
      .attr('href', (d: any) => {
        return d.data._photo?._url;
      });
  }

  private addEmployeeInfo(card: any, employeeInfoParams: any): void {
    // Employee name
    const employeeNameText = card
      .append('text')
      .attr('x', employeeInfoParams.xEmployeeNamePosition)
      .attr('y', employeeInfoParams.yEmployeeNamePosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${employeeInfoParams.employeeNameFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return d.data.name.length > employeeInfoParams.employeeNameMaxLength
          ? `${d.data.name.slice(0, employeeInfoParams.employeeNameMaxLength)}...`
          : d.data.name;
      });

    // Job title
    const employeeJobTitleText = card
      .append('text')
      .attr('x', employeeInfoParams.xEmployeeJobTitlePosition)
      .attr('y', employeeInfoParams.yEmployeeJobTitlePosition)
      .attr('text-anchor', 'middle')
      .attr('fill', employeeInfoParams.employeeJobTitleColor)
      .style('font-size', `${employeeInfoParams.employeeJobTitleFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return check.assigned(d.data.jobTitle)
          ? d.data.jobTitle.length > employeeInfoParams.employeeJobTitleMaxLength
            ? `${d.data.jobTitle.slice(0, employeeInfoParams.employeeJobTitleMaxLength)}...`
            : d.data.jobTitle
          : '';
      });

    // Add tooltips if necessary
    const employeeNameTooltipNode = employeeNameText.filter((d: any) => {
      return check.assigned(d.data.name) && d.data.name.length > employeeInfoParams.employeeNameMaxLength;
    });
    this.addToolTip(employeeNameTooltipNode, 'name');

    const employeeJobTitleTooltipNode = employeeJobTitleText.filter((d: any) => {
      return check.assigned(d.data.jobTitle) && d.data.jobTitle.length > employeeInfoParams.employeeJobTitleMaxLength;
    });
    this.addToolTip(employeeJobTitleTooltipNode, 'jobTitle');
  }

  private addCompanyAvatar(card: any, avatarParams: any): void {
    // Circle container
    card
      .append('circle')
      .attr('cx', avatarParams.xAvatarPosition)
      .attr('cy', avatarParams.yAvatarPosition)
      .attr('r', avatarParams.avatarRadius)
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    // Company icon
    card
      .append('path')
      .attr('transform', `translate(${avatarParams.xAvatarIconPosition},${avatarParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr(
        'd',
        'M23.334 13.334V0H6.66598V6.66598H0V30H13.334V23.334H16.666V30H30V13.334H23.334ZM6.66598 26.666H3.33402V23.334H6.66598V26.666ZM6.66598 20H3.33402V16.666H6.66598V20ZM6.66598 13.334H3.33402V10H6.66598V13.334ZM13.334 20H10V16.666H13.334V20ZM13.334 13.334H10V10H13.334V13.334ZM13.334 6.66598H10V3.33402H13.334V6.66598ZM20 20H16.666V16.666H20V20ZM20 13.334H16.666V10H20V13.334ZM20 6.66598H16.666V3.33402H20V6.66598ZM26.666 26.666H23.334V23.334H26.666V26.666ZM26.666 20H23.334V16.666H26.666V20Z'
      )
      .attr('fill', '#FFFFFF');
  }

  private addAreaAvatar(card: any, avatarParams: any): void {
    // Circle container
    card
      .append('circle')
      .attr('cx', avatarParams.xAvatarPosition)
      .attr('cy', avatarParams.yAvatarPosition)
      .attr('r', avatarParams.avatarRadius)
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    // area icon
    card
      .append('path')
      .attr('transform', `translate(${avatarParams.xAvatarIconPosition},${avatarParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr(
        'd',
        'M4.5 7.5v21c0 1.654 1.346 3 3 3h21c1.654 0 3-1.346 3-3v-21c0-1.654-1.346-3-3-3h-21c-1.654 0-3 1.346-3 3zm24.003 21H7.5v-21h21l.003 21z'
      )
      .attr('fill', '#FFFFFF');
    card
      .append('path')
      .attr('transform', `translate(${avatarParams.xAvatarIconPosition},${avatarParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr('d', 'M22.5 18h3v-7.5H18v3h4.5V18zM18 22.5h-4.5V18h-3v7.5H18v-3z')
      .attr('fill', '#FFFFFF');
  }

  private addOfficeAvatar(card: any, officeParams: any): void {
    // Circle container
    card
      .append('circle')
      .attr('cx', officeParams.xAvatarPosition)
      .attr('cy', officeParams.yAvatarPosition)
      .attr('r', officeParams.avatarRadius)
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    // office icon
    card
      .append('path')
      .attr('transform', `translate(${officeParams.xAvatarIconPosition},${officeParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr(
        'd',
        'M15.667 6.66574V-0.000976562H0.666992V29.999H30.667V6.66574H15.667V6.66574ZM6.66698 26.6658H3.66702V23.3325H6.66698V26.6658ZM6.66698 19.9991H3.66702V16.6658H6.66698V19.9991ZM6.66698 13.3325H3.66702V9.9991H6.66698V13.3324V13.3325ZM6.66698 6.66574H3.66702V3.33246H6.66698V6.66574ZM12.667 26.6658H9.66694V23.3325H12.6669V26.6658H12.667ZM12.667 19.9991H9.66694V16.6658H12.6669V19.9991H12.667ZM12.667 13.3325H9.66694V9.9991H12.6669V13.3324L12.667 13.3325ZM12.667 6.66574H9.66694V3.33246H12.6669V6.66574H12.667ZM27.667 26.6658H15.6669V23.3325H18.6669V19.9993H15.667V16.666H18.6669V13.3327H15.667V9.99941H27.667V26.6661L27.667 26.6658ZM24.667 13.3325H21.667V16.6658H24.667V13.3325V13.3325ZM24.667 19.9991H21.667V23.3324H24.667V19.9991Z'
      )
      .attr('fill', '#FFFFFF');
  }

  private addDepartmentAvatar(card: any, departmentParams: any): void {
    // Circle container
    card
      .append('circle')
      .attr('cx', departmentParams.xAvatarPosition)
      .attr('cy', departmentParams.yAvatarPosition)
      .attr('r', departmentParams.avatarRadius)
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    // department icon
    card
      .append('path')
      .attr('transform', `translate(${departmentParams.xAvatarIconPosition},${departmentParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr(
        'd',
        'M14.7996 6.30659H9.27083C8.93462 8.34795 6.99037 9.89717 4.64167 9.89717C4.63748 9.89717 4.63322 9.89717 4.62897 9.89717H4.62962C2.04962 9.89717 -0.0539551 8.034 -0.0539551 5.74456C-0.0539551 3.45981 2.04779 1.5967 4.63139 1.5967C6.81257 1.5967 8.65139 2.92614 9.16847 4.71829H15.1279C16.1393 1.95928 18.9917 0.00195312 22.355 0.00195312C22.3591 0.00195312 22.3632 0.00195312 22.3673 0.00195312H22.3666C26.5473 0.00195312 29.946 3.01462 29.946 6.71564C29.946 10.3046 26.7554 13.2462 22.7549 13.4262L23.1502 18.474C26.212 18.9635 28.5413 21.3398 28.5413 24.1899C28.5413 27.3951 25.6014 30.002 21.9837 30.002C18.3696 30.002 15.426 27.3935 15.426 24.1899C15.426 21.1757 18.0377 18.6888 21.3589 18.4077L20.9583 13.3188C17.4519 12.7314 14.7871 9.9935 14.7871 6.71558C14.7871 6.57824 14.7907 6.44242 14.8012 6.30501L14.7996 6.30659Z'
      )
      .attr('fill', '#FFFFFF');
  }

  private addTeamAvatar(card: any, avatarParams: any): void {
    // Circle container
    card
      .append('circle')
      .attr('cx', avatarParams.xAvatarPosition)
      .attr('cy', avatarParams.yAvatarPosition)
      .attr('r', avatarParams.avatarRadius)
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    // team icon
    card
      .append('path')
      .attr('transform', `translate(${avatarParams.xAvatarIconPosition},${avatarParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr(
        'd',
        'M18 3C9.72 3 3 9.72 3 18c0 8.28 6.72 15 15 15 8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15zm0 27c-6.615 0-12-5.385-12-12S11.385 6 18 6s12 5.385 12 12-5.385 12-12 12z'
      )
      .attr('fill', '#FFFFFF');
    card
      .append('path')
      .attr('transform', `translate(${avatarParams.xAvatarIconPosition},${avatarParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr('d', 'M12 24a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6-9a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z')
      .attr('fill', '#FFFFFF');
  }

  private addCompanyInfo(card: any, companyInfoParams: any): void {
    // Company name
    const companyNameText = card
      .append('text')
      .attr('x', companyInfoParams.xCompanyNamePosition)
      .attr('y', companyInfoParams.yCompanyNamePosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${companyInfoParams.companyNameFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return d.data.name.length > companyInfoParams.companyNameMaxLength
          ? `${d.data.name.slice(0, companyInfoParams.companyNameMaxLength)}...`
          : d.data.name;
      });

    // Add tooltips if necessary
    const companyNameTooltipNode = companyNameText.filter((d: any) => {
      return check.assigned(d.data.name) && d.data.name.length > companyInfoParams.companyNameMaxLength;
    });
    this.addToolTip(companyNameTooltipNode, 'name');
  }

  private addAreaInfo(card: any, areaInfoParams: any): void {
    // Company name
    const areaNameText = card
      .append('text')
      .attr('x', areaInfoParams.xAreaNamePosition)
      .attr('y', areaInfoParams.yAreaNamePosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${areaInfoParams.areaNameFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return d.data.name.length > areaInfoParams.areaNameMaxLength
          ? `${d.data.name.slice(0, areaInfoParams.areaNameMaxLength)}...`
          : d.data.name;
      });

    // Add tooltips if necessary
    const areaTooltipNode = areaNameText.filter((d: any) => {
      return check.assigned(d.data.name) && d.data.name.length > areaInfoParams.areaNameMaxLength;
    });
    this.addToolTip(areaTooltipNode, 'name');
  }

  private addTeamInfo(card: any, teamInfoParams: any): void {
    // Company name
    const teamNameText = card
      .append('text')
      .attr('x', teamInfoParams.xTeamNamePosition)
      .attr('y', teamInfoParams.yTeamNamePosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${teamInfoParams.teamNameFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return d.data.name.length > teamInfoParams.teamNameMaxLength
          ? `${d.data.name.slice(0, teamInfoParams.teamNameMaxLength)}...`
          : d.data.name;
      });

    // Add tooltips if necessary
    const teamTooltipNode = teamNameText.filter((d: any) => {
      return check.assigned(d.data.name) && d.data.name.length > teamInfoParams.teamNameMaxLength;
    });
    this.addToolTip(teamTooltipNode, 'name');
  }

  private addDepartmentInfo(card: any, departmentInfoParams: any): void {
    // Department name
    const departmentNameText = card
      .append('text')
      .attr('x', departmentInfoParams.xDepartmentNamePosition)
      .attr('y', departmentInfoParams.yDepartmentNamePosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${departmentInfoParams.departmentNameFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return d.data.name.length > departmentInfoParams.departmentNameMaxLength
          ? `${d.data.name.slice(0, departmentInfoParams.departmentNameMaxLength)}...`
          : d.data.name;
      });

    // Add tooltips if necessary
    const departmentNameTooltipNode = departmentNameText.filter((d: any) => {
      return check.assigned(d.data.name) && d.data.name.length > departmentInfoParams.departmentNameMaxLength;
    });
    this.addToolTip(departmentNameTooltipNode, 'name');
  }

  private addOfficeInfo(card: any, officeInfoParams: any): void {
    // Office name
    const officeNameText = card
      .append('text')
      .attr('x', officeInfoParams.xOfficeNamePosition)
      .attr('y', officeInfoParams.yOfficeNamePosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${officeInfoParams.officeNameFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return d.data.name.length > officeInfoParams.officeNameMaxLength
          ? `${d.data.name.slice(0, officeInfoParams.officeNameMaxLength)}...`
          : d.data.name;
      });

    // Add tooltips if necessary
    const officeNameTooltipNode = officeNameText.filter((d: any) => {
      return check.assigned(d.data.name) && d.data.name.length > officeInfoParams.officeNameMaxLength;
    });
    this.addToolTip(officeNameTooltipNode, 'name');
  }

  private addFutureRoleAvatar(card: any, avatarParams: any): void {
    // Circle container
    card
      .append('circle')
      .attr('cx', avatarParams.xAvatarPosition)
      .attr('cy', avatarParams.yAvatarPosition)
      .attr('r', avatarParams.avatarRadius)
      .attr('fill', (d) => {
        return userColorConstants[d.data.color];
      });

    // Future role icon
    card
      .append('path')
      .attr('transform', `translate(${avatarParams.xAvatarIconPosition},${avatarParams.yAvatarIconPosition})`)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr(
        'd',
        'M18 9c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zm0 13.5c4.05 0 8.7 1.935 9 3V27H9v-1.485c.3-1.08 4.95-3.015 9-3.015zM18 6c-3.315 0-6 2.685-6 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm0 13.5c-4.005 0-12 2.01-12 6V30h24v-4.5c0-3.99-7.995-6-12-6z'
      )
      .attr('fill', '#FFFFFF');
  }

  private addFutureRoleInfo(card: any, futureRoleInfoParams: any): void {
    // Future role name
    const futureRoleNameText = card
      .append('text')
      .attr('x', futureRoleInfoParams.xFutureRoleNamePosition)
      .attr('y', futureRoleInfoParams.yFutureRoleNamePosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${futureRoleInfoParams.futureRoleNameFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return d.data.futureRoleName.length > futureRoleInfoParams.futureRoleNameMaxLength
          ? `${d.data.futureRoleName.slice(0, futureRoleInfoParams.futureRoleNameMaxLength)}...`
          : d.data.futureRoleName;
      });

    // Add tooltips if necessary
    const futureRoleNameTooltipNode = futureRoleNameText.filter((d: any) => {
      return check.assigned(d.data.futureRoleName) && d.data.futureRoleName.length > futureRoleInfoParams.futureRoleNameMaxLength;
    });
    this.addToolTip(futureRoleNameTooltipNode, 'futureRoleName');
  }

  private addAssistantLogo(card, assistantParams: any): any {
    // Assistant logo
    const assistantLogo = card
      .append('g')
      .attr('class', 'assistant-logo')
      .attr('transform', `translate(${assistantParams.xAssistantIconPosition}, ${assistantParams.yAssistantIconPosition})`);

    assistantLogo.append('circle').attr('cx', 12).attr('cy', 12).attr('r', 11.5).attr('fill', '#FFFFFF').attr('stroke', '#DEDEDE');

    assistantLogo
      .append('path')
      .attr(
        'd',
        'M15.2 11.2a2.395 2.395 0 0 0 2.392-2.4 2.395 2.395 0 1 0-4.792 0c0 1.324 1.076 2.4 2.4 2.4zm-6.4 0a2.395 2.395 0 0 0 2.392-2.4 2.395 2.395 0 1 0-4.792 0c0 1.324 1.076 2.4 2.4 2.4zm0 1.6c-1.868 0-5.6.936-5.6 2.8v2h11.2v-2c0-1.864-3.732-2.8-5.6-2.8zm6.4 0c-.232 0-.492.016-.772.044.928.668 1.572 1.568 1.572 2.756v2h4.8v-2c0-1.864-3.732-2.8-5.6-2.8z'
      )
      .attr('fill', '#757575');

    this.addToolTip(assistantLogo);
  }

  private addChildrenCounter(card: any, cardParams: any): any {
    const childrenCounterParams = {
      radiusHalfCircle: this.params.radiusHalfCircle,
      xHalfCirclePosition: cardParams.cardWidth / 2 - this.params.radiusHalfCircle / 2,
      yHalfCirclePosition: cardParams.cardHeight,
      xChildrenCounterTextPosition: cardParams.cardWidth / 2,
      yChildrenCounterTextPosition: <number>cardParams.cardHeight + this.params.radiusHalfCircle / 3,
      childrenCounterTextFontSize: 12,
    };

    // Adds the half circle
    const childrenCounter = card
      .filter((d: any) => {
        return check.nonEmptyArray(d.data._children) || check.nonEmptyArray(d.data.children);
      })
      .append('g')
      .attr('class', 'children-counter')
      .on('click', (_, d) => {
        this.clickOnHalfCircle(d);
      });

    this.addToolTip(childrenCounter);

    childrenCounter
      .append('path')
      .attr('d', (d) => {
        return (
          'M' +
          childrenCounterParams.xHalfCirclePosition +
          ',' +
          childrenCounterParams.yHalfCirclePosition +
          ' a1,1 0 0,0' +
          childrenCounterParams.radiusHalfCircle +
          ',0'
        );
      })
      .attr('fill', '#dedede');

    // Adds the children counter
    childrenCounter
      .append('text')
      .attr('x', childrenCounterParams.xChildrenCounterTextPosition)
      .attr('y', childrenCounterParams.yChildrenCounterTextPosition)
      .attr('text-anchor', 'middle')
      .style('font-size', `${childrenCounterParams.childrenCounterTextFontSize}px`)
      .style('font-family', 'Nunito')
      .text((d: any) => {
        return check.assigned(d.data._children) ? d.data._children.length : d.data.children.length;
      });

    return childrenCounter;
  }

  private addEditButtons(card: any, cardParams): void {
    if (this.enabledEditMode !== true) {
      return;
    }

    const editButtons = card.append('g').style('opacity', 0);

    // More menu
    const moreMenuEditButton = editButtons
      .append('g')
      .attr('transform', `translate(${cardParams.cardWidth - 30}, 7)`)
      .on('click', (event, current) => {
        this.xPositionMoreMenu = event.pageX - 55;
        this.yPositionMoreMenu = event.pageY - 55;
        this.injector.get(ChangeDetectorRef).detectChanges();
        if (check.assigned(this.moreMenuTrigger)) {
          (<MatLegacyMenuTrigger>this.moreMenuTrigger).menuData = { node: current };
          (<MatLegacyMenuTrigger>this.moreMenuTrigger).openMenu();
        }
      });

    moreMenuEditButton.append('rect').attr('width', '24').attr('height', '24').attr('fill', 'transparent');

    moreMenuEditButton
      .append('path')
      .attr('class', 'more-menu-edit-button')
      .attr(
        'd',
        'M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
      )
      .attr('fill', '#757575');

    // Top button
    const topEditButton = editButtons
      .append('g')
      .attr('class', 'top-edit-button')
      .on('click', (_, current) => {
        this.addNode.emit({
          operation: 'addManager',
          node: current.data,
        });
      });

    this.addToolTip(topEditButton);

    topEditButton
      .append('path')
      .attr(
        'd',
        this.getRoundedRect(
          0,
          -cardParams.cardEditBarWidth + cardParams.cardHeightTopBar - 1,
          cardParams.cardWidth,
          cardParams.cardEditBarWidth,
          6,
          true,
          true,
          false,
          false
        )
      )
      .attr('fill', '#5893e3');

    topEditButton
      .append('path')
      .attr(
        'd',
        'M0.694967 4.58081H4.64218V0.414144H6.61578L6.61578 4.58081L10.563 4.58081V6.66414L6.61578 6.66414V10.8308H4.64218L4.64218 6.66414H0.694967V4.58081Z'
      )
      .attr('fill', '#FFFFFF')
      .attr('transform', `translate(${cardParams.cardWidth / 2 - 5}, ${-cardParams.cardEditBarWidth + cardParams.cardHeightTopBar + 3})`);

    // Right button
    const rightEditButton = editButtons
      .append('g')
      .attr('class', 'right-edit-button')
      .on('click', (_, current) => {
        this.addNode.emit({
          operation: 'addRightPeer',
          node: current.data,
        });
      });

    this.addToolTip(rightEditButton);

    rightEditButton
      .append('path')
      .attr(
        'd',
        this.getRoundedRect(
          cardParams.cardWidth - 1,
          cardParams.cardHeightTopBar - 2,
          cardParams.cardEditBarWidth,
          cardParams.cardHeight - cardParams.cardHeightTopBar + 2,
          6,
          false,
          true,
          true,
          false
        )
      )
      .attr('fill', '#5893e3');

    rightEditButton
      .append('path')
      .attr(
        'd',
        'M0.694967 4.58081H4.64218V0.414144H6.61578L6.61578 4.58081L10.563 4.58081V6.66414L6.61578 6.66414V10.8308H4.64218L4.64218 6.66414H0.694967V4.58081Z'
      )
      .attr('fill', '#FFFFFF')
      .attr('transform', `translate(${<number>cardParams.cardWidth + 4}, ${cardParams.cardHeight / 2 - 5})`);

    // Bottom button
    const bottomEditButton = editButtons
      .append('g')
      .attr('class', 'bottom-edit-button')
      .on('click', (_, current) => {
        this.addNode.emit({
          operation: 'addSubordinate',
          node: current.data,
        });
      });

    this.addToolTip(bottomEditButton);

    bottomEditButton
      .append('path')
      .attr(
        'd',
        this.getRoundedRect(0, cardParams.cardHeight - 1, cardParams.cardWidth, cardParams.cardEditBarWidth, 6, false, false, true, true)
      )
      .attr('fill', '#5893e3');

    bottomEditButton
      .append('path')
      .attr(
        'd',
        'M0.694967 4.58081H4.64218V0.414144H6.61578L6.61578 4.58081L10.563 4.58081V6.66414L6.61578 6.66414V10.8308H4.64218L4.64218 6.66414H0.694967V4.58081Z'
      )
      .attr('fill', '#FFFFFF')
      .attr('transform', `translate(${cardParams.cardWidth / 2 - 5}, ${<number>cardParams.cardHeight + 3})`);

    // Left button
    const leftEditButton = editButtons
      .append('g')
      .attr('class', 'left-edit-button')
      .on('click', (_, current) => {
        this.addNode.emit({
          operation: 'addLeftPeer',
          node: current.data,
        });
      });

    this.addToolTip(leftEditButton);

    leftEditButton
      .append('path')
      .attr(
        'd',
        this.getRoundedRect(
          -cardParams.cardEditBarWidth + 1,
          cardParams.cardHeightTopBar - 2,
          cardParams.cardEditBarWidth,
          cardParams.cardHeight - cardParams.cardHeightTopBar + 2,
          6,
          true,
          false,
          false,
          true
        )
      )
      .attr('fill', '#5893e3');

    leftEditButton
      .append('path')
      .attr(
        'd',
        'M0.694967 4.58081H4.64218V0.414144H6.61578L6.61578 4.58081L10.563 4.58081V6.66414L6.61578 6.66414V10.8308H4.64218L4.64218 6.66414H0.694967V4.58081Z'
      )
      .attr('fill', '#FFFFFF')
      .attr('transform', `translate(${-cardParams.cardEditBarWidth + 5}, ${cardParams.cardHeight / 2 - 5})`);

    card
      .on('mouseover', (_, cardData) => {
        editButtons
          .filter((d) => {
            return d.data._id === cardData.data._id;
          })
          .style('opacity', 1);
      })
      .on('mouseout', (_, cardData) => {
        editButtons
          .filter((d) => {
            return d.data._id === cardData.data._id;
          })
          .style('opacity', 0);
      });
  }

  private getRoundedRect(
    x: number,
    y: number,
    weight: number,
    height: number,
    radius: number,
    roundTopLeftCorner: boolean,
    roundTopRightCorner: boolean,
    roundBottomRightCorner: boolean,
    roundBottomLeftCorner: boolean
  ) {
    let rect;
    rect = `M${x + radius},${y}`;
    rect += `h${weight - 2 * radius}`;
    if (roundTopRightCorner === true) {
      rect += `a${radius},${radius} 0 0 1 ${radius},${radius}`;
    } else {
      rect += `h${radius}`;
      rect += `v${radius}`;
    }
    rect += `v${height - 2 * radius}`;
    if (roundBottomRightCorner === true) {
      rect += `a${radius},${radius} 0 0 1 ${-radius},${radius}`;
    } else {
      rect += `v${radius}`;
      rect += `h${-radius}`;
    }
    rect += `h${2 * radius - weight}`;
    if (roundBottomLeftCorner === true) {
      rect += `a${radius},${radius} 0 0 1 ${-radius},${-radius}`;
    } else {
      rect += `h${-radius}`;
      rect += `v${-radius}`;
    }
    rect += `v${2 * radius - height}`;
    if (roundTopLeftCorner === true) {
      rect += `a${radius},${radius} 0 0 1 ${radius},${-radius}`;
    } else {
      rect += `v${-radius}`;
      rect += `h${radius}`;
    }
    rect += 'z';
    return rect;
  }

  private addToolTip(element: any, dataId?: string): any {
    element
      .on('mouseover', (event, d) => {
        this.cardMouseOver(event, element, d, dataId);
      })
      .on('mouseout', () => {
        this.cardMouseOut();
      });
  }

  private cardMouseOver(event: any, element: any, tooltipInfo: any, dataId?: string): void {
    let tooltipText;
    if (check.assigned(dataId) === true) {
      tooltipText = tooltipInfo.data[dataId];
    } else if (element.attr('class') === 'banner') {
      tooltipText = this.getBannerTooltipText(tooltipInfo);
    } else if (element.attr('class') === 'children-counter') {
      tooltipText =
        check.assigned(tooltipInfo.children) && check.assigned(tooltipInfo.children.length > 0)
          ? this.translation.hideSubordinates
          : this.translation.showSubordinates;
    } else if (element.attr('class') === 'assistant-logo') {
      tooltipText = this.translation.assistantTooltip;
    } else if (element.attr('class') === 'top-edit-button') {
      tooltipText = this.translation.addManager;
    } else if (element.attr('class') === 'right-edit-button' || element.attr('class') === 'left-edit-button') {
      tooltipText = this.translation.addPeer;
    } else if (element.attr('class') === 'bottom-edit-button') {
      tooltipText = this.translation.addSubordinate;
    }

    this.toolTipContainer
      .style('opacity', 0.9)
      .style('left', `${event.pageX - 60}px`)
      .style('top', `${event.pageY - 35}px`)
      .text(tooltipText);
  }

  private cardMouseOut(): void {
    this.toolTipContainer.style('opacity', 0);
  }

  private isTranslucentStandardCard(node: any): boolean {
    return (
      node.data.isActive === false ||
      (check.assigned(node.data.startDate) && moment.utc().isBefore(node.data.startDate, 'days')) ||
      (check.assigned(node.data.terminationDate) && moment.utc().isAfter(node.data.terminationDate, 'days'))
    );
  }

  private includeBannerInStandardCard(node: any): boolean {
    return (
      node.data.isActive === false ||
      (check.assigned(node.data.startDate) && moment.utc().isBefore(node.data.startDate, 'days')) ||
      check.assigned(node.data.terminationDate)
    );
  }

  private getBannerTooltipText(node: any): string {
    let translationText = this.translation.inactive;
    if (check.assigned(node.data.startDate) && moment.utc().isBefore(node.data.startDate, 'days')) {
      translationText = this.translation.startDate;
    } else if (check.assigned(node.data.terminationDate)) {
      translationText = this.translation.terminationDate;
    }
    return translationText;
  }

  private drawLink(): any {
    const linksData = this.treeElements.links();
    const link = this.svgTreeContainer.select('g').selectAll('.link').data(linksData);
    // ADD LINKS
    const linkEnter = link
      .enter()
      .insert('path')
      .attr('class', 'link')
      .filter((d: any) => {
        return d.target.depth !== 0 && d.source.depth !== 0;
      })
      .attr('fill', 'none')
      .attr('stroke', '#dedede')
      .attr('stroke-width', '3px')
      .attr('d', (d) => {
        return (
          'M' +
          d.source.x +
          ',' +
          d.source.y +
          'V' +
          (3 * d.source.y + 4 * d.target.y) / 7 +
          'H' +
          d.target.x +
          'V' +
          (d.target.y - this.params.genericCardStyles.cardHeight / 2)
        );
      });
    return linkEnter;
  }

  private initZoom(): void {
    this.zoom = d3
      .zoom()
      .scaleExtent([-50, 5])
      .on('zoom', (event: any) => {
        const currentTransform = event.transform;
        this.svgTreeContainer.select('g').attr('transform', currentTransform);
      });

    this.svgTreeContainer.call(this.zoom);

    if (check.not.assigned(this.svgPosition)) {
      this.svgTreeContainer.call(this.zoom.transform, d3.zoomIdentity.translate(this.params.xSVGPosition, this.params.ySVGPosition));
    }
  }

  private clickOnHalfCircle(data: any): void {
    const node = this.getNodeById(this.tree, data.data._id);
    if (check.assigned(node.children) && check.nonEmptyArray(node.children)) {
      node._children = node.children;
      delete node.children;
    } else if (check.assigned(node._children) && check.nonEmptyArray(node._children)) {
      node.children = node._children;
      delete node._children;
    }

    this.treeElements = this.getTreeNodes(this.tree);
    this.update();

    // Hide tooltip
    this.cardMouseOut();
  }

  private calculateInitials(name: string): string {
    let nameInitials;
    if (check.not.assigned(name) || check.not.string(name)) {
      nameInitials = '-';
      return nameInitials;
    }
    const avatarName = name.trim();
    if (check.emptyString(avatarName)) {
      nameInitials = '-';
      return nameInitials;
    }
    const words = avatarName.trim().split(' ');
    if (check.not.array(words) || check.emptyArray(words) || words.length < 2) {
      nameInitials = `${avatarName.charAt(0)}${avatarName.charAt(0)}`.toUpperCase();
      return nameInitials;
    }
    nameInitials = `${words[0].trim().charAt(0)}${words[1].trim().charAt(0)}`.toUpperCase();
    return nameInitials;
  }

  public moveTreeToMe(): void {
    // Expand the tree
    this.treeElements = this.getTreeNodes(this.tree);
    this.update();

    // Move the tree to center over the logged user
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const userNodes = this.treeElements.descendants().filter((node) => {
      return node.data.employeeId === loggedUser._id;
    });

    this.showFindMeButton = check.assigned(userNodes) && check.nonEmptyArray(userNodes);

    if (check.assigned(this.svgPosition)) {
      this.svgTreeContainer.call(this.zoom.transform, this.svgPosition);
      this.svgPosition = null;
      return;
    }

    if (this.showFindMeButton === false) {
      return;
    }

    if (userNodes.length === 1) {
      this.moveTreeToNode(userNodes[0]);
    } else if (userNodes.length > 1) {
      this.zoomToFit();
    }

    this.highlightNodeCard(loggedUser._id);
  }

  public moveTreeToSingleSearchResult(userNode: any): void {
    this.moveTreeToNode(userNode);
    // Highlight the node card
    this.highlightNodeCard(userNode.data.employeeId);
  }

  public moveTreeToMultipleSearchResult(userNodes: Array<any>): void {
    // move tree to first result
    this.moveTreeToNode(userNodes[0]);
    // Highlight the node cards
    userNodes.forEach((node: any) => {
      this.highlightNodeCard(node.data.employeeId);
    });
  }

  private moveTreeToNode(userNode: any): void {
    const xMyPosition = -1 * userNode.x + this.params.svgWidth / 2;
    const yMyPosition = this.params.svgHeight / 2 + <number>this.params.ySVGPosition - userNode.y;
    this.svgTreeContainer.call(this.zoom.transform, d3.zoomIdentity.translate(xMyPosition, yMyPosition));
  }

  private highlightNodeCard(nodeId: string) {
    this.svgTreeContainer
      .selectAll('.node')
      .filter((d: any) => {
        return d.data.employeeId === nodeId;
      })
      .append('rect')
      .attr('x', -2)
      .attr('y', -2)
      .attr('rx', 5)
      .attr('width', 144)
      .attr('height', 134)
      .attr('fill', 'none')
      .attr('stroke', '#5993E3')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', '5px')
      .attr('opacity', 0)
      .transition()
      .duration(this.params.findTransitionColorDurationGo)
      .attr('opacity', 1)
      .transition()
      .duration(this.params.findTransitionColorDurationReturn)
      .attr('opacity', 0)
      .remove();
  }

  public collapseFromDepth(depth: number): void {
    this.collapseNodeAndChildrenFromDepth(depth, 1, this.tree);
    this.treeElements = this.getTreeNodes(this.tree);
    this.update();
  }

  private collapseNodeAndChildrenFromDepth(maxDepthVisible: number, currentDepth: number, node: any): void {
    if (maxDepthVisible < currentDepth && check.nonEmptyArray(node.children)) {
      node._children = node.children;
      delete node.children;
    } else if (maxDepthVisible >= currentDepth && check.nonEmptyArray(node._children)) {
      node.children = node._children;
      delete node._children;
    }

    if (check.nonEmptyArray(node.children)) {
      node.children.forEach((iChild) => {
        this.collapseNodeAndChildrenFromDepth(maxDepthVisible, currentDepth + 1, iChild);
      });
    }

    if (check.nonEmptyArray(node._children)) {
      node._children.forEach((iChild) => {
        this.collapseNodeAndChildrenFromDepth(maxDepthVisible, currentDepth + 1, iChild);
      });
    }
  }

  public expandFullOrgChart(): void {
    this.expandNodeAndChildren(this.tree);
    this.treeElements = this.getTreeNodes(this.tree);
    this.update();
  }

  private expandNodeAndChildren(node: any): void {
    if (check.nonEmptyArray(node._children)) {
      node.children = node._children;
      delete node._children;
    }

    if (check.nonEmptyArray(node.children)) {
      node.children.forEach((iChild) => {
        this.expandNodeAndChildren(iChild);
      });
    }
  }

  public zoomIn(): void {
    this.zoom.scaleBy(this.svgTreeContainer.transition().duration(this.params.findTransitionTranslateDuration), 1.2);
  }

  public zoomOut(): void {
    this.zoom.scaleBy(this.svgTreeContainer.transition().duration(this.params.findTransitionTranslateDuration), 1 / 1.2);
  }

  public zoomToFit(): void {
    this.zoom.scaleTo(this.svgTreeContainer, 1);

    const { width: svgWidth, height: svgHeight } = this.svgTreeContainer.node().getBoundingClientRect();
    const { width: treeWidth, height: treeHeight } = this.svgTreeContainer.select('g').node().getBoundingClientRect();
    const scale = Math.min((svgWidth - 50) / treeWidth, (svgHeight - 50) / treeHeight);

    const leftLeaf = this.getLeftLeaf(this.treeElements);
    const rightLeaf = this.getRightLeaf(this.treeElements);
    const mean = (leftLeaf.x + rightLeaf.x) / 2;

    this.zoom.translateTo(this.svgTreeContainer, -(svgWidth / 2 - mean), 120, [0, 0]);
    this.zoom.scaleTo(this.svgTreeContainer, scale, [svgWidth / 2, 0]);
  }

  public getSvgPosition(): d3.ZoomTransform {
    if (check.not.assigned(this.svgTreeContainer)) {
      return;
    }

    return d3.zoomTransform(this.svgTreeContainer.select('g').node());
  }

  public exportToSVG(): void {
    const originalTransform = document.getElementById('orgChart').children[0].getAttribute('transform');

    const leftLeaf = this.getLeftLeaf(this.treeElements);
    const rightLeaf = this.getRightLeaf(this.treeElements);
    const mean = (leftLeaf.x + rightLeaf.x) / 2 + 50;

    document.getElementById('orgChart').children[0].setAttribute('transform', `translate(0, 0) scale(1)`);
    const { width, height } = this.svgTreeContainer.select('g').node().getBoundingClientRect();
    document.getElementById('orgChart').children[0].setAttribute('transform', `translate(${width / 2 - mean + 100}, -100) scale(1)`);

    const svgElement = document.getElementById('orgChart');
    let svgSource = new XMLSerializer().serializeToString(svgElement);
    if (!svgSource.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      svgSource = svgSource.replace(/^<svg/, `<svg xmlns="http://www.w3.org/2000/svg"`);
    }
    if (!svgSource.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      svgSource = svgSource.replace(/^<svg/, `<svg xmlns:xlink="http://www.w3.org/1999/xlink"`);
    }
    svgSource = svgSource.replace(
      'id="orgChart" style="width: 100%; height: 100%;">',
      `id="orgChart" viewBox="0 0 ${width + 100} ${height + 100}" style="width: 100%; height: 100%;"><defs></defs>`
    );
    svgSource = `<?xml version="1.0" standalone="no"?>\r\n${svgSource}`;

    document.getElementById('orgChart').children[0].setAttribute('transform', originalTransform);

    this.downloadOrgChart(`data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgSource)}`, `${this.FILENAME}.svg`);
  }

  public exportToPDF(): void {
    const originalTransform = document.getElementById('orgChart').children[0].getAttribute('transform');

    const leftLeaf = this.getLeftLeaf(this.treeElements);
    const rightLeaf = this.getRightLeaf(this.treeElements);
    const mean = (leftLeaf.x + rightLeaf.x) / 2 + 50;

    document.getElementById('orgChart').children[0].setAttribute('transform', `translate(0, 0) scale(1)`);
    const { width, height } = this.svgTreeContainer.select('g').node().getBoundingClientRect();
    document.getElementById('orgChart').children[0].setAttribute('transform', `translate(${width / 2 - mean + 100}, -100) scale(1)`);

    const svgElement = document.getElementById('orgChart');
    const svgSource = new XMLSerializer().serializeToString(svgElement);

    document.getElementById('orgChart').children[0].setAttribute('transform', originalTransform);

    const doc = new PDFDocument({ size: [width * (72 / 96) + 75, height * (72 / 96) + 50] });

    // Create a writable stream
    const chunks: Uint8Array[] = [];
    const stream = doc.on('data', (chunk) => {
      chunks.push(chunk);
    });

    stream.on('end', () => {
      // Convert the chunks to a single Uint8Array
      const pdfData = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0));
      let offset = 0;
      chunks.forEach((chunk) => {
        pdfData.set(chunk, offset);
        offset += chunk.length;
      });

      // Create a Blob from the Uint8Array
      const blob = new Blob([pdfData], { type: 'application/pdf' });

      // Save the Blob using FileSaver.js
      saveAs(blob, `${this.FILENAME}.pdf`);
    });

    SVGtoPDF(doc, svgSource, 0, 0);
    doc.end();
  }

  private getLeftLeaf(node): any {
    if (check.not.assigned(node.children) || check.emptyArray(node.children)) {
      return node;
    }
    let leftest = node;
    node.children.forEach((child) => {
      const leftestChild = this.getLeftLeaf(child);
      if (leftestChild.x < leftest.x) {
        leftest = leftestChild;
      }
    });
    return leftest;
  }

  private getRightLeaf(node): any {
    if (check.not.assigned(node.children) || check.emptyArray(node.children)) {
      return node;
    }
    let rightest = node;
    node.children.forEach((child) => {
      const rightestChild = this.getRightLeaf(child);
      if (rightestChild.x > rightest.x) {
        rightest = rightestChild;
      }
    });
    return rightest;
  }

  private getNodeById(node, id): any {
    if (node._id === id) {
      return node;
    }

    if (check.not.assigned(node.children) || check.emptyArray(node.children)) {
      return;
    }

    let foundNode;
    for (const iChild of node.children) {
      foundNode = this.getNodeById(iChild, id);
      if (check.assigned(foundNode) && foundNode._id === id) {
        break;
      }
    }

    return foundNode;
  }

  private downloadOrgChart(url: string, filename: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
