<orgos-dialog-container
  class="awa-main-container"
  *ngIf="pageTranslation && parentInfo && parentInfo['workflowAction'] && parentInfo['workflowAction'].data"
  [title]="parentInfo['workflowAction'].data['data']['_id'] ? pageTranslation.dialogHeaderTextEdit : pageTranslation.dialogHeaderText"
  (clickCloseButton)="closeDialog()"
>
  <orgos-column-container>
    <orgos-input-text class="kenjo-pr-10px" orgosColumn required="true" [model]="parentInfo['workflowAction']" field="name" (validation)="nameValidation = $event"></orgos-input-text>
    <orgos-input-select class="kenjo-pl-10px" orgosColumn required="true" [options]="actionTypeOptions" [model]="parentInfo['workflowAction']" (modelChange)="onActionTypeChange()" field="type"></orgos-input-select>
  </orgos-column-container>

  <orgos-input-select
    *ngIf="parentInfo['workflowAction'].data.type === 'email_alert' || parentInfo['workflowAction'].data.type === 'slack_post'"
    class="kenjo-block"
    required="true"
    [options]="emailTemplateOptions"
    [model]="parentInfo['workflowAction']"
    field="emailTemplateId"
    [value]="parentInfo['workflowAction'].data.data"
    (validation)="emailTemplateValidation = $event"
  ></orgos-input-select>

  <div *ngIf="parentInfo['workflowAction'].data['type'] === 'email_alert'" class="kenjo-pt-20px">
    <div class="kenjo-font-size-16px">{{pageTranslation.senderEmailAddress}}</div>
    <orgos-column-container class="kenjo-mv-10px" centerColumns="true">
      <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline</kenjo-icon>
      <orgos-column class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{pageTranslation.senderEmailAddressInfo}} </orgos-column>
    </orgos-column-container>
    <orgos-column-container class="kenjo-p-10px kenjo-border kenjo-border-radius">
      <kenjo-input-email-name
        [domain]="domain"
        [showDomain]="!freeDomainList.includes(domain.split('.')[0])"
        [readOnly]="domain === KENJO_DOMAIN"
        orgosColumn="1"
        required="true"
        [label]="pageTranslation.userName"
        [model]="parentInfo['workflowAction']"
        field="senderName"
        [value]="parentInfo['workflowAction'].data.data"
        [errorMessage]="pageTranslation.senderNameError"
        (validation)="senderNameValidation = $event"
        class="kenjo-pr-5px"
      ></kenjo-input-email-name>
      <orgos-input-simple-select orgosColumn="1" required="true" (valueChange)="changeDomain($event)" [options]="senderDomainOptions" [label]="pageTranslation.domain" [value]="domain"></orgos-input-simple-select>
    </orgos-column-container>
  </div>

  <div *ngIf="parentInfo['workflowAction'].data['type'] === 'email_alert'" class="kenjo-pt-20px">
    <div class="kenjo-font-size-16px">{{pageTranslation.emailRecipientHeader}}</div>
    <orgos-column-container *ngIf="parentInfo['inCollection'] === 'position-candidate'" class="kenjo-pt-20px">
      <!-- Add the option to send emails to position-candidates -->
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toPositionCandidate" (valueChange)="toPositionCandidate = $event"></orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.toPositionCandidateLabel}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toPositionCandidateDescription}} </orgos-column>
    </orgos-column-container>
    <orgos-column-container *ngIf="parentInfo['inCollection'] !== 'position-candidate' && parentInfo['inCollection'] !== 'recurrent'" class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toEmployeeEmail" (valueChange)="toEmployeeEmail = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.toEmployeeLabel}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toEmployeeDescription}} </orgos-column>
    </orgos-column-container>
    <orgos-column-container *ngIf="parentInfo['inCollection'] !== 'position-candidate' && parentInfo['inCollection'] !== 'recurrent'" class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toManagerEmail" (valueChange)="toManagerEmail = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.toManagerLabel}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toManagerDescription}} </orgos-column>
    </orgos-column-container>
    <orgos-column-container *ngIf="parentInfo['inCollection'] === 'time-off-request' && isTimeOffApproverEnabled" class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toTimeOffApproverEmail" (valueChange)="toTimeOffApproverEmail = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.toTimeOffApproverLabel}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toTimeOffApproverDescription}} </orgos-column>
    </orgos-column-container>
    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toSpecificUser" (valueChange)="toSpecificUser = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toSpecificUserLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toSpecificUserDescription}} </orgos-column>
        </orgos-column-container>
        <orgos-search *ngIf="allUserPersonal && toSpecificUser === true" [placeholder]="pageTranslation.searchPlaceholder" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event" class="kenjo-pt-10px">
          <orgos-text class="orgos-search-error" color="Warn">{{pageTranslation.notFoundPeopleError}}</orgos-text>

          <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result.data" (selected)="addUserToSpecificPeopleSendEmail(result.data._id)">
            <kenjo-truncate-text>{{result.data.displayName}}</kenjo-truncate-text>
          </orgos-search-option>

          <orgos-column-container wrap="true" class="orgos-search-selection-container">
            <orgos-column size="0" *ngFor="let result of searchResults" class="kenjo-m-10px">
              <orgos-chip [imageUrl]="result.data._photo?._url" buttonIconName="clear" [onClickButtonFunction]="getFunctionDeleteUserFromSpecificPeopleSendEmail(result.data._id)" [addInitialsIfNoImage]="true" [chipText]="result.data.displayName" class="srp-search-result">
                {{result.data.displayName}}</orgos-chip
              >
            </orgos-column>
          </orgos-column-container>
        </orgos-search>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px" *ngIf="parentInfo['inCollection'] === 'position-candidate'" centerColumns="true">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toMentionedUsers" (valueChange)="toMentionedUsers = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.relatedEmployee}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.relatedEmployeeInfoEmail}} </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toDepartments" (valueChange)="toDepartments = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toDepartmentLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toDepartmentDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-input-simple-select *ngIf="toDepartments === true && toDepartmentOptions" [label]="pageTranslation.departmentLabel" [(value)]="toDepartmentsValue" [options]="toDepartmentOptions" class="kenjo-pt-10px kenjo-block" [multiSelect]="true"></orgos-input-simple-select>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toOffices" (valueChange)="toOffices = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toOfficeLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toOfficeDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-input-simple-select *ngIf="toOffices === true && toOfficeOptions" [label]="pageTranslation.officeLabel" [(value)]="toOfficesValue" [options]="toOfficeOptions" class="kenjo-pt-10px kenjo-block" [multiSelect]="true"></orgos-input-simple-select>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toCompanies" (valueChange)="toCompanies = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toCompanyLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toCompanyDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-input-simple-select *ngIf="toCompanies === true && toCompanyOptions" [label]="pageTranslation.companyLabel" [(value)]="toCompaniesValue" [options]="toCompanyOptions" class="kenjo-pt-10px kenjo-block" [multiSelect]="true"></orgos-input-simple-select>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toTeams" (valueChange)="toTeams = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toTeamLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toTeamDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-input-simple-select *ngIf="toTeams === true && toTeamOptions" [label]="pageTranslation.teamLabel" [(value)]="toTeamsValue" [options]="toTeamOptions" class="kenjo-pt-10px kenjo-block" [multiSelect]="true"></orgos-input-simple-select>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toAreas" (valueChange)="toAreas = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toAreaLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toAreaDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-input-simple-select *ngIf="toAreas === true && toAreaOptions" [label]="pageTranslation.areaLabel" [(value)]="toAreasValue" [options]="toAreaOptions" class="kenjo-pt-10px kenjo-block" [multiSelect]="true"></orgos-input-simple-select>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px" *ngIf="parentInfo['inCollection'] !== 'recurrent'">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toTargetField" (valueChange)="toTargetField = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toTargetFieldLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toTargetFieldDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-input-simple-select
          *ngIf="toTargetField === true"
          [label]="pageTranslation.fieldLabel"
          [(value)]="toTargetFieldValue"
          [options]="targetFieldOptions"
          class="kenjo-pt-10px kenjo-block"
          required="true"
          (validation)="targetFieldValidation = $event"
          (valueChange)="toTargetFieldValue = $event"
        >
        </orgos-input-simple-select>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toSpecificEmail" (valueChange)="toSpecificEmail = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container>
          <orgos-column size="1"> {{pageTranslation.toSpecificEmailLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.toSpecificEmailDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-input-simple-email *ngIf="toSpecificEmail === true" [label]="pageTranslation.emailAddress" [(value)]="toSpecificEmailValue" class="kenjo-pt-10px kenjo-block" required="true" (validation)="toSpecificEmailValidation = $event" (valueChange)="toSpecificEmailValue = $event">
          <orgos-input-error *ngIf="toSpecificEmailValidation && toSpecificEmailValidation.hasErrors()"> {{pageTranslation.emailNotValid}}</orgos-input-error>
        </orgos-input-simple-email>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container *ngIf="parentInfo['inCollection'] && groupOptionsPerTargetObject[parentInfo['inCollection']] && groupOptionsPerTargetObject[parentInfo['inCollection']].length > 0" class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toGroupsEnabled" (valueChange)="changeInToGroupsEnabled($event)"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px">
        <div>{{pageTranslation.toGroupsLabel}}</div>
        <ng-container *ngIf="toGroupsEnabled === true">
          <orgos-column-container *ngFor="let iGroup of groupOptionsPerTargetObject[parentInfo['inCollection']]" class="kenjo-pt-15px">
            <orgos-input-simple-checkbox orgosColumn="0" [value]="iGroup.selected" (valueChange)="iGroup.selected = $event"> </orgos-input-simple-checkbox>
            <orgos-column size="1" class="kenjo-ml-10px"> {{pageTranslation[iGroup.translationKeyLabel] ? pageTranslation[iGroup.translationKeyLabel] : iGroup.translationKeyLabel}} </orgos-column>
          </orgos-column-container>
        </ng-container>
      </orgos-column>
    </orgos-column-container>
  </div>

  <div *ngIf="parentInfo['workflowAction'].data['type'] === 'new_task'" class="kenjo-pt-20px">
    <div class="kenjo-font-size-16px">{{pageTranslation.taskRecipientHeader}}</div>
    <orgos-column-container class="kenjo-pt-20px" *ngIf="parentInfo['inCollection'] !== 'recurrent'" centerColumns="true">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toEmployeeEmail" (valueChange)="toEmployeeEmail = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.toEmployeeLabel}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.taskToEmployeeDescription}} </orgos-column>
    </orgos-column-container>
    <orgos-column-container class="kenjo-pt-20px" *ngIf="parentInfo['inCollection'] !== 'recurrent'" centerColumns="true">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toManagerEmail" (valueChange)="toManagerEmail = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.toManagerLabel}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.taskToManagerDescription}} </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px" *ngIf="parentInfo['inCollection'] === 'position-candidate'" centerColumns="true">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toMentionedUsers" (valueChange)="toMentionedUsers = $event"> </orgos-input-simple-checkbox>
      <orgos-column class="kenjo-pl-10px"> {{pageTranslation.relatedEmployee}} </orgos-column>
      <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.relatedEmployeeInfoToDo}} </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-20px">
      <orgos-input-simple-checkbox orgosColumn="0" [value]="toSpecificUser" (valueChange)="changeToSpecificUser($event)"></orgos-input-simple-checkbox>
      <!-- invoke change to specific user -->
      <orgos-column class="kenjo-pl-10px">
        <orgos-column-container centerColumns="true">
          <orgos-column size="1"> {{pageTranslation.toSpecificUserLabel}} </orgos-column>
          <orgos-column size="3" class="kenjo-pl-10px kenjo-font-color-light-text-757575"> {{pageTranslation.taskToSpecificDescription}} </orgos-column>
        </orgos-column-container>

        <orgos-search *ngIf="allUserPersonal && toSpecificUser === true" [placeholder]="pageTranslation.searchPlaceholder" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event" class="kenjo-pt-10px">
          <orgos-text class="orgos-search-error" color="Warn">{{pageTranslation.notFoundPeopleError}}</orgos-text>

          <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result.data" (selected)="addUserToSpecificPeopleSendEmail(result.data._id)">
            <kenjo-truncate-text>{{result.data.displayName}}</kenjo-truncate-text>
          </orgos-search-option>

          <orgos-column-container wrap="true" class="orgos-search-selection-container">
            <orgos-column size="0" *ngFor="let result of searchResults" class="kenjo-m-10px">
              <orgos-chip [imageUrl]="result.data._photo?._url" buttonIconName="clear" [onClickButtonFunction]="getFunctionDeleteUserFromSpecificPeopleSendEmail(result.data._id)" [addInitialsIfNoImage]="true" [chipText]="result.data.displayName" class="srp-search-result">
                {{result.data.displayName}}</orgos-chip
              >
            </orgos-column>
          </orgos-column-container>
        </orgos-search>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-pt-30px" centerColumns="true">
      <orgos-input-number orgosColumn="0" class="kenjo-pr-10px" [model]="parentInfo['workflowAction']" [value]="parentInfo['workflowAction'].data.data" [label]="pageTranslation.taskDueDate" field="dueDateDelay" required="true" (validation)="dueDateValidation = $event" step="1" min="0">
      </orgos-input-number>
      <orgos-column size="1">{{pageTranslation.taskDaysAfter}}</orgos-column>
    </orgos-column-container>
  </div>

  <ng-container *ngIf="parentInfo['workflowAction'].data.type === 'slack_post'">
    <orgos-input-select required="true" [options]="slackChannels" [model]="parentInfo['workflowAction']" [value]="parentInfo['workflowAction'].data.data" field="slackChannel" (validation)="slackChannelValidation = $event" class="kenjo-pt-20px kenjo-block"></orgos-input-select>
  </ng-container>

  <ng-container *ngIf="parentInfo['workflowAction'].data.type === 'asana_task'">
    <orgos-input-select
      required="true"
      [options]="asanaWorkspaces"
      [model]="parentInfo['workflowAction']"
      [value]="parentInfo['workflowAction'].data.data"
      field="asanaWorkspaceId"
      (modelChange)="onAsanaWorkspaceChange()"
      (validation)="asanaWorkspaceValidation = $event"
      class="kenjo-pt-20px kenjo-block"
    ></orgos-input-select>
    <orgos-input-select required="true" [options]="asanaTeams" [model]="parentInfo['workflowAction']" [value]="parentInfo['workflowAction'].data.data" field="asanaTeamId" (validation)="asanaTeamValidation = $event" class="kenjo-pt-10px kenjo-block"></orgos-input-select>
    <orgos-input-text required="true" [model]="parentInfo['workflowAction']" [value]="parentInfo['workflowAction'].data.data" field="asanaProjectName" (validation)="asanaProjectNameValidation = $event" class="kenjo-pt-10px kenjo-block"></orgos-input-text>
    <orgos-input-checkbox required="true" [model]="parentInfo['workflowAction']" [value]="parentInfo['workflowAction'].data.data" field="asanaProjectWithEmployeeName"></orgos-input-checkbox>
  </ng-container>

  <div class="kenjo-mt-60px kenjo-pb-30px kenjo-text-align-right">
    <orgos-button-raised color="Success" (click)="saveAction()" [disabled]="disableSaveButton || isSaving"> {{parentInfo['workflowAction']['data']['_id'] ? pageTranslation.saveButtonLabelEdit : pageTranslation.saveButtonLabel}} </orgos-button-raised>
  </div>
</orgos-dialog-container>
