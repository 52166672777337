import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IUserTimeOffRequestDetail } from '@app/cloud-features/shift-plan/services/shift-plan-time-off.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as moment from 'moment';

const fadeInOut = trigger('fadeInOutAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
]);

@Component({
  selector: 'kenjo-shift-plan-time-off-list',
  templateUrl: 'shift-plan-time-off-list.component.html',
  styleUrls: ['shift-plan-time-off-list.component.scss'],
  animations: [fadeInOut],
})
export class ShiftPlanTimeOffListComponent implements OnInit, AfterViewInit {
  USER_COLOR_CONSTANTS: { [key: string]: string } = userColorConstants;
  formattedDate: string;
  selectedTimeOff: IUserTimeOffRequestDetail;
  isDetailOpen: boolean = false;
  isLeft: boolean = true;

  @Input() parentTranslation: { [key: string]: string };
  @Input() timeOffList: Array<IUserTimeOffRequestDetail>;
  @Input() statusMaps: { color: { [key: string]: string }; list: { [key: string]: string } };
  @Input() userId: string;
  @Input() date: moment.Moment;
  @Input() disconnected: boolean;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('timeOffListContainer', { static: false }) timeOffListContainer: ElementRef;
  @ViewChild('detailsMenuTrigger', { static: false }) detailsMenuTrigger: MatMenuTrigger;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    const lang = this.injector.get(AuthenticationService).getLoggedUser().language;
    this.formattedDate = this.date.utc().locale(lang).format('ddd, D MMM').replace(/\./g, '');
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  openDetail($event: PointerEvent, index: number) {
    this.isLeft = $event.clientX >= window.innerWidth / 2;
    this.isDetailOpen = true;
    this.selectedTimeOff = this.timeOffList[index];
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('Shiftplan timeoff details checked', { category: 'Shiftplan', subcategory: 'Scheduling', subcategory2: 'timeoff' });
    this.cdr.detectChanges();
    this.detailsMenuTrigger.openMenu();
  }

  onClick(event: PointerEvent) {
    event.stopPropagation();
  }

  closeDetails() {
    this.isDetailOpen = false;
    this.detailsMenuTrigger.closeMenu();
    this.selectedTimeOff = null;
  }

  closeList() {
    this.close.emit();
  }
}
