import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { CompanyDocReadTrackingDialogServiceService } from '@app/standard/pages/documents/dialogs/company-doc-read-tracking-dialog/company-doc-read-tracking-dialog.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DocumentTagService, IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import { DocumentService } from '@app/standard/services/document/document.service';
import { ISmartDocsSettingsModel, SmartDocsSettingsService } from '@app/standard/services/document/smart-docs-settings.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-upload-document-dialog',
  templateUrl: 'upload-document.dialog.html',
  styleUrls: ['upload-document.dialog.scss'],
})
export class UploadDocumentDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};
  document: GenericCacheModel;
  allDocumentTags: Array<IDocumentTagModel> = [];
  hasTagDocumentPermission: boolean = false;
  isCompanyDoc: boolean = false;
  noDocumentTags: boolean = false;

  nameValidation: InputValidation;

  smartDocsSettings: ISmartDocsSettingsModel;
  areTagsMandatory: boolean = false;
  checkTags: boolean = false;

  requestReadReceipt: boolean = false;

  constructor(
    
    public dialogRef: MatLegacyDialogRef<UploadDocumentDialog>,
   
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
   
    private snackBar: MatLegacySnackBar,
   
    private injector: Injector,
   
    private i18nDataPipe: I18nDataPipe
  
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-upload-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.injector
      .get(DocumentTagService)
      .getTags({ applyPermissions: true, onlyActive: true })
      .then((allDocumentTags: Array<IDocumentTagModel>) => {
        this.allDocumentTags = allDocumentTags;
        if (this.allDocumentTags?.length === 0) {
          this.noDocumentTags = true;
        }
      })
      .catch((error) => {
        // An error is already shown
        this.allDocumentTags = [];
      });

    const rawDocument = {
      _file: this.data.rawDocument,
      relatedTo: this.data.uploadConfig.relatedTo,
      hidden: true,
      tags: [],
      validUntil: null,
      name: this.data.rawDocument._fileName ? this.data.rawDocument._fileName : '',
    };
    rawDocument.relatedTo = this.data.uploadConfig.relatedTo;
    if (rawDocument.relatedTo.typeRelatedTo === 'Company') {
      rawDocument.hidden = false;
      this.isCompanyDoc = true;
    } else if (rawDocument.relatedTo.typeRelatedTo === 'User' && this.data.uploadConfig.viewableByUser === true) {
      rawDocument.hidden = false;
    } else {
      rawDocument.hidden = true;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.hasTagDocumentPermission = loggedUser.profileKey === 'admin' || loggedUser.profileKey === 'hr-admin';

    this.document = new GenericCacheModel(this.injector, rawDocument, DocumentService, loggedUser._id);

    this.initSmartDocData();
  }

  private async initSmartDocData(): Promise<void> {
    try {
      await this.fetchSmartDocsData();
    } catch {}
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getExtension(): string {
    return this.document.data._file?._fileExtension ?? '';
  }

  isTagSelected(tag: IDocumentTagModel): boolean {
    if (check.not.assigned(this.document) || check.not.assigned(this.document.data.tags) || check.emptyArray(this.document.data.tags)) {
      return false;
    }

    return check.contains(this.document.data.tags, tag._id);
  }

  getTagColor(tag: IDocumentTagModel): string {
    if (this.isTagSelected(tag) === false) {
      return 'Neutral';
    }

    return userColorConstants[tag.color];
  }

  toggleTag(tag: IDocumentTagModel): Function {
    return () => {
      if (this.isTagSelected(tag) === false) {
        this.document.data.tags = this.document.data.tags.concat([tag._id]);
        return;
      }

      this.document.data.tags = this.document.data.tags.filter((iTag: string) => {
        return iTag !== tag._id;
      });
    };
  }

  async uploadDocument(): Promise<void> {
    if (check.not.assigned(this.nameValidation) || this.nameValidation.hasErrors()) {
      return;
    }

    if (this.data?.uploadConfig?.relatedTo?.typeRelatedTo === 'Company') {
      this.injector.get(PrivateAmplitudeService).logEvent('company doc added', { platform: 'Web', category: 'PQL' });
      this.injector.get(PrivateIntegrationsService).trackChameleonEvent('company doc added');
    } else if (this.data?.uploadConfig?.relatedTo?.typeRelatedTo === 'User') {
      this.injector.get(PrivateAmplitudeService).logEvent('employee doc added', { platform: 'Web', category: 'PQL' });
      this.injector.get(PrivateIntegrationsService).trackChameleonEvent('employee doc added');
    }

    try {
      if (this.requestReadReceipt === true) {
        this.document.data.lastReadRequestAt = moment();
      }
      const createdDocument: GenericCacheModel = await this.document.createInServer();
      if (this.requestReadReceipt === true) {
        this.injector.get(CompanyDocReadTrackingDialogServiceService).sendCompanyWideBulkRequestRead(createdDocument.data);
      }
      const documentUploadedSnackText = this.i18nDataPipe.transform(this.dialogTranslation.documentUploadedSnackText, createdDocument.data);
      this.snackBar.open(documentUploadedSnackText, 'OK', {
        duration: 5000,
      });
      this.dialogRef.close(createdDocument.data);
    } catch {
      // an error is shown
    }
  }

  setRequestReadReceipt(event: boolean): void {
    this.requestReadReceipt = event;
  }

  private async fetchSmartDocsData(): Promise<void> {
    await this.fetchSmartDocsSettings();
  }

  private async fetchSmartDocsSettings() {
    try {
      this.smartDocsSettings = await this.injector.get(SmartDocsSettingsService).find();
      this.areTagsMandatory = this.smartDocsSettings.areTagsMandatory;
    } catch {}
  }
}
export interface IUploadDocumentConfig {
  relatedTo: {
    typeRelatedTo: 'User' | 'Company';
    idRelatedTo?: string;
  };
  viewableByUser?: boolean;
}
