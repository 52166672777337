<orgos-dialog-container class="dialog-box" *ngIf="pageTranslation && !isLoading" [title]="pageTranslation.assignSurchargesFromCompensationDialogTitle" (clickCloseButton)="closeDialog()">

  <ng-container *ngIf="autocompleteOptions?.length">
    <orgos-input-simple-select
      [multiSelect]="true"
      [autoOrder]="true"
      [(value)]="selectedSurchargeIds"
      [options]="selectOptions"
      [label]="pageTranslation.selectSurchargesLabel"
      class="ass-multiselect kenjo-mb-10px">
    </orgos-input-simple-select>

    <orgos-column-container class="kenjo-mt-40px">
      <orgos-column></orgos-column>
      <orgos-column size="0">
        <orgos-button-raised
          color="Success"
          (click)="assignRules()"
          [disabled]="!selectedSurchargeIds?.length">
          {{ pageTranslation.assignSurchargesFromCompensationDialogConfirm }}
        </orgos-button-raised>
      </orgos-column>
    </orgos-column-container>
  </ng-container>
</orgos-dialog-container>