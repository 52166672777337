import { IToDo } from '@app/cloud-features/performance-review/controllers/performance-review-to-dos.controller';
import * as check from 'check-types';
import * as moment from 'moment';

export const sortToDosList = (toDosList: Array<IToDo>, sortOrder: string): Array<IToDo> => {
  if (check.not.assigned(toDosList)) {
    return [];
  }

  return [...toDosList].sort((a, b) => {
    if (check.not.assigned(a._createdAt) || check.not.assigned(b._createdAt) || moment.utc(a._createdAt).isSame(moment.utc(b._createdAt))) {
      return 0;
    }

    const order = sortOrder === 'asc' ? 1 : -1;
    return moment.utc(a._createdAt).isBefore(moment.utc(b._createdAt)) ? order : order * -1;
  });
};
