import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { COMPONENTS, EXTERNAL_MODULES, PROVIDERS } from '@app/common-components/full-screen-header/full-screen-header.config';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { INTERCEPTORS } from '@app/core/interceptors/interceptors.config';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';
@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, FormsModule, MaterialModule, StandardComponentsModule, IconModule, TruncateTextModule, ...EXTERNAL_MODULES],
  providers: [...INTERCEPTORS, ...PROVIDERS],
  exports: [...COMPONENTS]
})
export class FullScreenHeaderModule {}
