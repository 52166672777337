<div class="stor-container">
  <ng-container *ngIf="!submittingTimeOff || !repeatsOnEnabled">
    <orgos-column-container centerColumns="true" class="kenjo-pb-10px kenjo-pt-40px kenjo-ph-40px">
      <orgos-column size="1"></orgos-column>
      <kenjo-icon
        orgosColumn="0"
        [size]="24"
        class="stor-link-button kenjo-mr-10px"
        [matTooltip]="dialogTranslation.learnMore"
        matTooltipPosition="above"
        (click)="openHelpLink()"
        >auto_stories</kenjo-icon
      >
      <kenjo-icon orgosColumn="0" [size]="24" class="stor-close-button" (click)="closeDialog()">clear</kenjo-icon>
    </orgos-column-container>

    <orgos-loading-spinner *ngIf="loadingPage" class="stor-loading"></orgos-loading-spinner>
    <ng-container *ngIf="!loadingPage">
      <!-- TITLE -->
      <orgos-column-container centerColumns="true">
        <orgos-column size="1"></orgos-column>
        <orgos-column size="0">
          <orgos-container class="stor-circle-mini" [style.background-color]="USER_COLORS[timeOffType.color]"></orgos-container>
        </orgos-column>
        <orgos-column size="0" class="kenjo-ml-10px kenjo-font-size-30px">
          <kenjo-simple-truncate-text class="stor-dialog-title"> {{timeOffType.name}} </kenjo-simple-truncate-text>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>

      <!-- SUBTITLE -->
      <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
        <orgos-column size="1"></orgos-column>
        <orgos-column size="0">
          <orgos-text type="Headline4">
            {{isRequest ? dialogTranslation.requestDialogName : (isOnBehalf ? dialogTranslation.submitOnBehalfDialogName :
            dialogTranslation.submitDialogName)}}
          </orgos-text>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>

      <!-- CONTENT -->
      <div #scrollContainer *ngIf="timeOffRequest" class="stor-scroll-content kenjo-ph-40px">
        <!-- DATES -->
        <div class="stor-calendar-outer-box">
          <div class="stor-calendar-inner-box kenjo-pv-10px kenjo-ph-15px" [class]="{ 'kenjo-pb-15px' : policy.allowHalfDays }">
            <ng-container *ngIf="!isHourType || !LEGACY_ACTIVITY_TYPES.includes(timeOffType.workTime)">
              <!-- RANGE PICKER -->
              <kenjo-input-date-range-calendar-picker
                [requiredStart]="true"
                [requiredEnd]="true"
                [readOnlyStart]="isOngoing"
                [labelStart]="dialogTranslation.fromLabel"
                [labelEnd]="dialogTranslation.toLabel"
                [warningTooltipStart]="isOngoing ? dialogTranslation.ongoingRequestTooltip : ''"
                [minDate]="minFromDate"
                [maxDate]="maxToDate"
                [selectedRangeValue]="dailyRange"
                [recurringEvents]="recurringEvents"
                [singleEvents]="singleEvents"
                [loading]="loadingEvents"
                [eventsRange]="eventsRange"
                (selectedRangeValueChange)="onChangeDates($event)"
                (startDateValidationChange)="startDateValidation = $event"
                (endDateValidationChange)="endDateValidation = $event"
                (loadEventsChange)="updateCalendarEvents($event)"
              ></kenjo-input-date-range-calendar-picker>

              <!-- PART OF DAY TOGGLE BUTTON GROUP -->
              <orgos-column-container
                class="kenjo-mt-10px kenjo-mb-10px"
                *ngIf="policy.allowHalfDays && timeOffRequest?.data?.from && timeOffRequest?.data?.to"
              >
                <kenjo-button-toggle-group
                  *ngIf="fromAndToAreSameDay"
                  orgosColumn="1"
                  [options]="partOfDaySameDayOptions"
                  [value]="partOfDayWhenFromAndToAreSameDay"
                  [readOnly]="isOngoing || (conflict && ![TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST, TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT].includes(conflictType))"
                  (valueChange)="partOfDayWhenFromAndToAreSameDay = $event; onChangePartOfDay()"
                ></kenjo-button-toggle-group>
                <kenjo-button-toggle-group
                  *ngIf="!fromAndToAreSameDay"
                  orgosColumn="1"
                  [options]="partOfDayFromOptions"
                  [value]="partOfDayFrom"
                  [readOnly]="isOngoing || (conflict && ![TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST, TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT].includes(conflictType))"
                  (valueChange)="partOfDayFrom = $event; onChangePartOfDay()"
                ></kenjo-button-toggle-group>
                <kenjo-button-toggle-group
                  *ngIf="!fromAndToAreSameDay"
                  orgosColumn="1"
                  [options]="partOfDayToOptions"
                  [value]="partOfDayTo"
                  [readOnly]="conflict && ![TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST, TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT].includes(conflictType)"
                  (valueChange)="partOfDayTo = $event; onChangePartOfDay()"
                  class="kenjo-ml-30px"
                >
                </kenjo-button-toggle-group>
              </orgos-column-container>

              <!-- SELECTED DAYS -->
              <div
                *ngIf="isHourType && timeOffRequest?.data?.from && timeOffRequest?.data?.to && !LEGACY_ACTIVITY_TYPES.includes(timeOffType.workTime)"
                class="kenjo-font-size-14px kenjo-font-color-primary-5993e3"
                [ngClass]="{ 'kenjo-mb-15px': hourlySingleDayOptions.length > 1 }">
                {{ 
                  (duration === 1 ? dialogTranslation.selectedDay : dialogTranslation.selectedDays) | i18nData: { amount: duration }
                }}
              </div>

              <!-- SINGLE DAY HOURLY TOGGLE -->
              <orgos-column-container
              centerColumns="true"
                *ngIf="isHourType && timeOffRequest?.data?.from && timeOffRequest?.data?.to && fromAndToAreSameDay && !LEGACY_ACTIVITY_TYPES.includes(timeOffType.workTime) && (isWorkingDay || policy.countNaturalDays)"
              >
              <orgos-column size="1" class="kenjo-mv-5px">
                <kenjo-button-toggle-group
                  *ngIf="hourlySingleDayOptions.length > 1"
                  [options]="hourlySingleDayOptions"
                  [(value)]="hourToggleSingleDay"
                  [readOnly]="isOngoing || !isWorkingDay || (conflict && ![TIME_OFF_REQUEST_CONFLICT_WORK_SCHEDULE_LIMITS, TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME, TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST, TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT].includes(conflictType))"
                  (valueChange)="hourToggleSingleDay = $event;changeSingleDayToggle();"
                ></kenjo-button-toggle-group>
              </orgos-column>
                <kenjo-input-simple-hourly-range
                class="kenjo-ml-30px stor-hourly-range"
                *ngIf="hourToggleSingleDay === HOUR_TOGGLE_CUSTOM_TIME"
                orgosColumn="1"
                  (click)="showOngoingWarning()"
                  [translations]="dialogTranslation.hourlyRangeTexts"
                  [hourlyRange]="hourlyRange"
                  [readOnlyStart]="isOngoing"
                  (hourlyRangeChange)="computeWorkingDaysRequested()"
                  [validateHourlyRanges]="validateHourlyRanges"
                  [customValidationParameters]="customValidationParameters"
                  [debounceNewValues]="true"
                  [showTouchedInputErrors]="true"
                >
                </kenjo-input-simple-hourly-range>
              </orgos-column-container>

              <!-- REPEAT REQUEST BUTTON -->
              <orgos-input-simple-checkbox
                class="kenjo-mt-15px"
                *ngIf="!isEdit && timeOffRequest?.data?.from && timeOffRequest?.data?.to"
                [label]="dialogTranslation.repeatLabel"
                [(value)]="repeatsOnEnabled"
                [readOnly]="conflict === true && !repeatsOnEnabled"
                (valueChange)="toggleEnableRepeat()"
              ></orgos-input-simple-checkbox>

              <ng-container *ngIf="!isEdit && repeatsOnEnabled && timeOffRequest?.data?.from && timeOffRequest?.data?.to">
                <!-- REPEAT CONFIGURATION -->
                <orgos-column-container class="kenjo-mt-15px stor-repeat-settings">
                  <orgos-column>
                    <div class="kenjo-font-color-light-text-757575 stor-repeat-config-label">
                      {{dialogTranslation.repeatConfigurationLabel}} *
                    </div>
                    <orgos-column-container>
                      <!-- Quantity -->
                      <orgos-column class="stor-repeat-quantity" size="0">
                        <orgos-input-simple-number
                          [(value)]="frequencyQuantity"
                          [required]="true"
                          [step]="1"
                          [min]="repeatFrequencyMinLimit"
                          [max]="frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK ? REPEAT_MAX_LIMIT_WEEK : REPEAT_MAX_LIMIT_MONTH"
                          (valueChange)="onChangeFrequencyQuantity()"
                          [debounceNewValues]="true"
                          (validation)="frequencyQuantityValidation = $event"
                          [returnValueWithErrors]="true"
                        >
                        </orgos-input-simple-number>
                      </orgos-column>
                      <!-- Unit -->
                      <orgos-column class="stor-repeat-unit" size="0">
                        <orgos-input-simple-select
                          [value]="frequencyUnit"
                          [options]="frequencyUnitOptions"
                          (valueChange)="onChangeFrequencyUnit($event)"
                        >
                        </orgos-input-simple-select>
                      </orgos-column>
                    </orgos-column-container>
                  </orgos-column>
                  <!-- WEEK DAY SELECTOR -->
                  <orgos-column class="stor-repeat-on-config">
                    <div
                      class="kenjo-font-color-light-text-757575 stor-repeat-config-label"
                      *ngIf="frequencyUnit !== REPEAT_FREQUENCY_UNIT_WEEK || fromAndToAreSameDay"
                    >
                      {{dialogTranslation.weekdaySelectorLabel}} *
                    </div>
                    <div *ngIf="frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK && fromAndToAreSameDay">
                      <div class="stor-weekday-toggle">
                        <orgos-working-day-circle
                          *ngFor="let day of weekDayLabels; let i = index"
                          [label]="day"
                          [(value)]="selectedWeekDays[i].value"
                          [preventToggle]="selectedWeekDays[i].preventToggle"
                          (click)="checkPreventToggle(i)"
                          (valueChange)="changeWeekDay($event, i)"
                        >
                        </orgos-working-day-circle>
                      </div>
                    </div>
                    <div *ngIf="frequencyUnit !== REPEAT_FREQUENCY_UNIT_WEEK">
                      <orgos-input-simple-select
                        class="kenjo-full-width"
                        [(value)]="monthDaySelector"
                        [options]="monthDaySelectorOptions"
                        (valueChange)="onChangeMonthDaySelector($event)"
                        (validation)="monthDayValidation = $event"
                      >
                      </orgos-input-simple-select>
                    </div>
                    <!-- End date -->
                    <kenjo-input-date-calendar-picker
                      *ngIf="frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK && fromAndToAreSameDay === false"
                      class="kenjo-full-width kenjo-mr-40px"
                      [required]="true"
                      [label]="dialogTranslation.repeatsOnEndLabel"
                      [minDate]="repeatsMinDate"
                      [maxDate]="repeatsMaxDate"
                      [selectedDateValue]="repeatsOnEndDate"
                      [recurringEvents]="recurringEvents"
                      [singleEvents]="singleEvents"
                      [loading]="loadingEvents"
                      [eventsRange]="eventsRange"
                      [initialCalendarStart]="repeatsMinDate"
                      (selectedDateValueChange)="onChangeRepeatsOnEnd($event)"
                      (selectedDateValidationChange)="repeatsOnEndDateValidation = $event"
                      (loadEventsChange)="updateCalendarEvents($event)"
                    ></kenjo-input-date-calendar-picker>
                  </orgos-column>
                </orgos-column-container>
                <orgos-column-container>
                  <orgos-column size="1" *ngIf="repeatsOnEnabled && frequencyQuantityValidation">
                    <orgos-input-error *ngIf="frequencyQuantityValidation.getError('required')">
                      {{dialogTranslation.quantityRequiredError}}
                    </orgos-input-error>
                    <orgos-input-error *ngIf="frequencyQuantityValidation.getError('min')">
                      {{ dialogTranslation.quantityMinError | i18nData: { minQuantity: repeatFrequencyMinLimit, unitQuantity: frequencyUnit
                      === REPEAT_FREQUENCY_UNIT_WEEK ? (repeatFrequencyMinLimit === 1 ? dialogTranslation.weekUnit :
                      dialogTranslation.weeksUnit) : (repeatFrequencyMinLimit === 1 ? dialogTranslation.monthUnit :
                      dialogTranslation.monthsUnit) } }}
                    </orgos-input-error>
                    <orgos-input-error *ngIf="frequencyQuantityValidation.getError('max')">
                      {{ dialogTranslation.quantityMaxError | i18nData: { maxQuantity: frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK ?
                      REPEAT_MAX_LIMIT_WEEK : REPEAT_MAX_LIMIT_MONTH, unitQuantity: frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK ?
                      dialogTranslation.weeksUnit : dialogTranslation.monthsUnit } }}
                    </orgos-input-error>
                  </orgos-column>
                  <orgos-column
                    *ngIf="displayOriginalWeekdayError && frequencyUnit === REPEAT_FREQUENCY_UNIT_WEEK"
                    size="1"
                    class="stor-info-error-message"
                  >
                    <orgos-input-error>
                      <orgos-column-container>
                        <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-danger-ff5757 stor-info-error-icon">
                          info_outline
                        </kenjo-icon>
                        <span orgosColumn="1"> {{dialogTranslation.originalWeekdayError}} </span>
                      </orgos-column-container>
                    </orgos-input-error>
                  </orgos-column>
                </orgos-column-container>
                <orgos-column-container *ngIf="frequencyUnit !== REPEAT_FREQUENCY_UNIT_WEEK || fromAndToAreSameDay">
                  <!-- End date -->
                  <orgos-column class="stor-repeat-end-date" size="1">
                    <kenjo-input-date-calendar-picker
                      class="kenjo-full-width kenjo-mr-40px"
                      [required]="true"
                      [label]="dialogTranslation.repeatsOnEndLabel"
                      [minDate]="repeatsMinDate"
                      [maxDate]="repeatsMaxDate"
                      [selectedDateValue]="repeatsOnEndDate"
                      [recurringEvents]="recurringEvents"
                      [singleEvents]="singleEvents"
                      [loading]="loadingEvents"
                      [eventsRange]="eventsRange"
                      [initialCalendarStart]="repeatsMinDate"
                      (selectedDateValueChange)="onChangeRepeatsOnEnd($event)"
                      (selectedDateValidationChange)="repeatsOnEndDateValidation = $event"
                      (loadEventsChange)="updateCalendarEvents($event)"
                    ></kenjo-input-date-calendar-picker>
                  </orgos-column>
                  <orgos-column size="1"></orgos-column>
                </orgos-column-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isHourType && LEGACY_ACTIVITY_TYPES.includes(timeOffType.workTime)">
              <kenjo-input-simple-hourly-range (click)="showOngoingWarning()"
                [translations]="dialogTranslation.hourlyRangeTexts" [hourlyRange]="hourlyRange" [readOnlyStart]="isOngoing"
                (hourlyRangeChange)="computeWorkingDaysRequested()" [validateHourlyRanges]="validateHourlyRanges"
                [customValidationParameters]="customValidationParameters"
                [debounceNewValues]="true"
                [showTouchedInputErrors]="true">
                <kenjo-input-date-calendar-picker
                class="kenjo-full-width kenjo-mr-40px"
                [required]="true" [readOnly]="isOngoing" [label]="dialogTranslation.dateLabel"
                  [warningTooltip]="isOngoing ? dialogTranslation.ongoingRequestTooltip : ''" [minDate]="minFromDate"
                  [maxDate]="maxToDate" [selectedDateValue]="timeOffRequest.data.from" [recurringEvents]="recurringEvents"
                  [singleEvents]="singleEvents" [loading]="loadingEvents" [eventsRange]="eventsRange"
                  (selectedDateValueChange)="onChangeFromDateHour($event)" (selectedDateValidation)="startDateValidation = $event"
                  (loadEventsChange)="updateCalendarEvents($event)"></kenjo-input-date-calendar-picker>
              </kenjo-input-simple-hourly-range>
            </ng-container>
          </div>
          <div class="kenjo-mt-20px">
            <orgos-column-container class="stor-time-row">
              <orgos-column size="0">{{ dialogTranslation.totalTimeRequesting }}</orgos-column>
              <orgos-column *ngIf="!isHourType" size="0">
                {{ workingTime | number:'1.0-2' }} {{workingTime === 1 ? dialogTranslation.day: dialogTranslation.days }}
              </orgos-column>
              <orgos-column *ngIf="isHourType" size="0">
                {{ workingTime | duration }}
              </orgos-column>
            </orgos-column-container>
            <!-- AVAILABLE PAST CYCLE -->
            <orgos-column-container *ngIf="workingTimePastCycle" class="stor-time-row kenjo-mt-10px">
              <orgos-column size="0">{{ dialogTranslation.availablePastCycle }}</orgos-column>
              <orgos-column size="0">
                <span *ngIf="policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED && !isHourType">
                  {{ pastCycleAvailable | number:'1.0-2' }} {{ [1, -1].includes(pastCycleAvailable) ? dialogTranslation.day : dialogTranslation.days }}
                </span>
                <span *ngIf="policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED && isHourType">
                  {{ pastCycleAvailable | duration }}
                </span>
                <span *ngIf="policy.allowanceType === ALLOWANCE_TYPE_UNLIMITED">{{ dialogTranslation.unlimitedBalance }}</span>
              </orgos-column>
            </orgos-column-container>
            <!-- AVAILABLE CURRENT CYCLE -->
            <orgos-column-container class="stor-time-row kenjo-mt-10px">
              <orgos-column size="0">{{ dialogTranslation.availableCurrentCycle }}</orgos-column>
              <orgos-column size="0">
                <span *ngIf="!workingTime">--</span>
                <span
                  *ngIf="workingTime && policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED && !isHourType"
                  [ngClass]="{'stor-available-warning': conflict === false && newAvailable < 0, 'stor-available-error': conflict === true && newAvailable < 0}"
                >
                  {{ newAvailable | number:'1.0-2' }} {{ [1, -1].includes(newAvailable) ? dialogTranslation.day : dialogTranslation.days }}
                </span>
                <span
                  *ngIf="workingTime && policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED && isHourType"
                  [ngClass]="{'stor-available-warning': conflict === false && newAvailable < 0, 'stor-available-error': conflict === true && newAvailable < 0}"
                >
                  {{ newAvailable ? (newAvailable | duration) : '--' }}
                </span>
                <span *ngIf="workingTime && policy.allowanceType === ALLOWANCE_TYPE_UNLIMITED"
                  >{{ dialogTranslation.unlimitedBalance }}</span
                >
              </orgos-column>
            </orgos-column-container>
            <!-- AVAILABLE NEXT CYCLE -->
            <orgos-column-container *ngIf="nextCycleWorkingTime" class="stor-time-row kenjo-mt-10px">
              <orgos-column size="0">{{ dialogTranslation.availableNextCycle }}</orgos-column>
              <orgos-column size="0">
                <span *ngIf="policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED">
                  {{ nextCycleAvailable | number:'1.0-2' }} {{ [1, -1].includes(nextCycleAvailable) ? dialogTranslation.day :
                  dialogTranslation.days }}
                </span>
                <span *ngIf="policy.allowanceType === ALLOWANCE_TYPE_UNLIMITED">{{ dialogTranslation.unlimitedBalance }}</span>
              </orgos-column>
            </orgos-column-container>
            <orgos-column-container class="stor-time-row kenjo-mt-10px" *ngIf="showWarningExpiredCarryOver">
              <orgos-column size="0">{{ dialogTranslation.availableAfterExpiration }}</orgos-column>
              <orgos-column size="0" [ngClass]="conflict ? 'stor-available-error' : 'stor-available-warning'">
                {{ newAvailableAfterExpireCarryOver | number:'1.0-2' }} {{ dialogTranslation.days }}
              </orgos-column>
            </orgos-column-container>
          </div>
          <orgos-column-container *ngIf="hasDescendants" class="kenjo-mt-20px kenjo-mh-5px">
            <kenjo-icon orgosColumn="0" [size]="20" class="kenjo-font-color-primary-5993e3">info_outline</kenjo-icon>
            <orgos-column class="kenjo-ml-5px kenjo-font-color-light-text-757575">
              <!-- SELF DELEGATED APPROVER -->
              <ng-container *ngIf="!!userOnBehalfName === false">
                <ng-container *ngIf="hasDelegatedApprover === true"
                  >{{dialogTranslation.delegatedApproverMessage | i18nData: {delegatedApproverName} }}</ng-container
                >
                <ng-container *ngIf="hasDelegatedApprover === false"
                  >{{dialogTranslation.defaultDelegatedApproverMessage | i18nData: {delegatedApproverName} }}</ng-container
                >
              </ng-container>
              <!-- DELEGATED APPROVER ON BEHALF -->
              <ng-container *ngIf="!!userOnBehalfName === true">
                <ng-container *ngIf="hasDelegatedApprover === true"
                  >{{dialogTranslation.delegatedApproverMessageOnBehalf | i18nData: {delegatedApproverName, userOnBehalfName}
                  }}</ng-container
                >
                <ng-container *ngIf="hasDelegatedApprover === false"
                  >{{dialogTranslation.defaultDelegatedApproverMessageOnBehalf | i18nData: {userOnBehalfName} }}</ng-container
                >
              </ng-container>
            </orgos-column>
          </orgos-column-container>
        </div>
        <!-- INFO MESSAGES -->
        <div class="kenjo-mt-20px kenjo-font-color-text-333333">
          <kenjo-info-message *ngIf="conflictMessage" messageType="error" [messageText]="conflictMessage"></kenjo-info-message>
          <kenjo-info-message
            *ngIf="conflict === false && !showWarningExpiredCarryOver && newAvailable && newAvailable < 0 && workingTimeCurrentCycle > 0"
            messageType="warning"
            [messageText]="dialogTranslation.requestConflictNegativeAvailableWarning"
          ></kenjo-info-message>
          <kenjo-info-message
            *ngIf="conflict === false && showWarningExpiredCarryOver"
            messageType="warning"
            [messageText]="dialogTranslation.requestWarningNegativeAvailableAfterExpiration"
          ></kenjo-info-message>
          <kenjo-info-message
            *ngIf="isEdit && data.oldRequest?.repeatRequestId"
            messageType="primary"
            [messageText]="dialogTranslation.requestCreatedByRepetition"
          ></kenjo-info-message>
          <kenjo-repeat-request-conflict
            class="kenjo-mt-10px"
            *ngIf="repeatConflicts"
            [repeatConflicts]="repeatConflicts"
            [translations]="dialogTranslation.repeatConflictTranslations"
          >
          </kenjo-repeat-request-conflict>
        </div>
        <!-- DESCRIPTION -->
        <div class="kenjo-mt-20px">
          <orgos-input-text
            class="kenjo-full-width"
            [model]="timeOffRequest"
            id="time_off_request_description"
            field="description"
            [maxlength]="300"
            (validation)="descriptionValidation = $event"
            (modelChange)="calculateIfEditedRequest()"
          >
            <orgos-input-error *ngIf="descriptionValidation && descriptionValidation.getError('maxlength')">
              {{dialogTranslation.descriptionMaxLengthErrorMessage | i18nData: { maxLength: DESCRIPTION_MAX_LENGTH } }}</orgos-input-error
            >
          </orgos-input-text>
        </div>
        <!-- ATTACHMENTS -->
        <div class="kenjo-mb-10px">
          <kenjo-time-off-request-attachments
            *ngIf="timeOffType.attachment"
            [oldRequestAttachments]="oldRequestAttachments"
            [translations]="dialogTranslation"
            [requestPermissions]="requestPermissions"
            (update)="updateAttachments($event)"
            (onExpand)="onExpandAttachments($event)"
          ></kenjo-time-off-request-attachments>
        </div>
        <!-- ONGOING REQUEST EDITION MESSAGE -->
        <orgos-column-container *ngIf="isOngoing && startDateClicked" centerColumns="true" class="kenjo-mt-10px kenjo-mb-20px">
          <orgos-column size="0">
            <kenjo-icon [size]="20" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
          </orgos-column>
          <orgos-column class="kenjo-ml-5px kenjo-font-color-light-text-757575"> {{dialogTranslation.ongoingRequestMessage}} </orgos-column>
        </orgos-column-container>
        <!-- HALF DAYS GAP (to avoid movement behind calendar when selecting dates) -->
        <div *ngIf="(policy.allowHalfDays || (isHourType && fromAndToAreSameDay)) && (!timeOffRequest?.data?.from || !timeOffRequest?.data?.to)" class="stor-half-days-gap"></div>
        <div *ngIf="isHourType && (!timeOffRequest?.data?.from || !timeOffRequest?.data?.to)" class="stor-hour-toggle-gap"></div>
        <div *ngIf="isHourType && (timeOffRequest?.data?.from && timeOffRequest?.data?.to) && !fromAndToAreSameDay" class="stor-hour-gap"></div>
        <div *ngIf="isHourType && conflictMessage" class="kenjo-mt-20px"></div>
        <!-- REPEATS ON GAP -->
        <div *ngIf="(!timeOffRequest?.data?.from || !timeOffRequest?.data?.to)" class="stor-repeats-on-gap"></div>
      </div>
      <!-- BUTTONS -->
      <orgos-column-container centerColumns="true" class="kenjo-ph-40px kenjo-pb-40px kenjo-pt-15px stor-button-container">
        <orgos-column size="0" class="kenjo-text-align-center kenjo-mr-20px">
          <orgos-button-raised color="Neutral" (click)="closeDialog()">{{dialogTranslation.cancelButtonLabel}}</orgos-button-raised>
        </orgos-column>
        <orgos-column size="0" class="kenjo-text-align-center">
          <orgos-button-raised
            color="Primary"
            [disabled]="checkIfInvalidForm()"
            [matTooltip]="policy.allowNegativeBalance ? dialogTranslation.negativeBalanceLimitExceededLabel : dialogTranslation.negativeBalanceLabel"
            [matTooltipDisabled]="conflict === false || conflictType !== TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE"
            (click)="submitTimeOffRequest()"
          >
            {{(isRequest ? dialogTranslation.requestButtonLabel : (isOnBehalf ? dialogTranslation.submitOnBehalfButtonLabel :
            dialogTranslation.submitButtonLabel)) | titlecase }}
          </orgos-button-raised>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
  </ng-container>
  <orgos-column-container *ngIf="submittingTimeOff && repeatsOnEnabled" centerColumns="true">
    <orgos-column class="stor-submitting-request-loading-container kenjo-text-align-center">
      <orgos-loading-spinner color="Success"></orgos-loading-spinner>
      <div class="kenjo-font-size-20px kenjo-font-color-text-333333 kenjo-font-weight-bold kenjo-mt-40px">
        {{ dialogTranslation.creatingRequestsTitle }}
      </div>
      <orgos-column-container centerColumns="true" class="stor-submitting-request-subtitle">
        <div orgosColumn="0" class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-color-light-text-757575">
          {{ dialogTranslation.creatingRequestsSubtitle }}
        </div>
      </orgos-column-container>
    </orgos-column>
  </orgos-column-container>
</div>