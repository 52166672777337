import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ITask } from '@app/models/task.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { TasksAbstractPage } from '@app/standard/pages/tasks/tasks-abstract.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { TaskHelperService } from '@app/standard/services/task/task-helper.service';
import { TaskService } from '@app/standard/services/task/task.service';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-tasks-mine',
  templateUrl: 'tasks-mine.page.html',
  styleUrls: ['tasks-mine.page.scss']
})
export class TasksMinePage extends TasksAbstractPage implements OnInit {
  pageFindQuery: any;

  // specific tasks-mine properties
  taskReadOnlyAssignee: boolean = false;

  constructor(protected router: Router, protected injector: Injector) {
    super(router, injector);
  }

  ngOnInit(): void {
    this.tabOrder = 0;
    this.tabKey = 'mine';
    this.groupFields = ['_createdById', 'relatedTo', 'workflowName'];
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.pageFindQuery = { ownerId: loggedUser._id };

    this.injector.get(TaskHelperService).refreshData();
    this.fetchData();
    this.injector.get(PrivateAmplitudeService).logEvent('view to-dos page', { category: 'Navigation', type: 'my to-dos' });
  }

  protected async loadTasks(): Promise<void> {
    try {
      const taskPermissions = await this.injector.get(TaskService).getPermissions();
      this.taskReadOnlyAssignee = taskPermissions.edit_own && !taskPermissions.edit_all && !taskPermissions.edit_custom;

      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      const tasks: Array<ITask> = await this.applyFilters();
      await Promise.all([this.handlePermissions(loggedUser), this.handleTasks(tasks, loggedUser)]);
    } catch (error) {
      this.listTasks = [];
    }
  }
}
