import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

const INTEGRATIONS = ['join'];
@Injectable()
export class ApiKeyService {
  private APIKEY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/auth-api-key-db`;

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private http: HttpClient, private authenticationService: AuthenticationService) { }

  getAllApiKeys(): Promise<Array<any>> {
    const findBody = {
      integration: { $nin: INTEGRATIONS }
    };
    return new Promise<Array<IApiKeyModel>>((resolve, reject) => {
      this.genericService
        .find(this.APIKEY_URL, findBody)
        .then((allApiKeys: Array<IApiKeyModel>) => {
          resolve(allApiKeys);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApiKeyService.name, 'getAllApiKeys'));
        });
    });
  }

  getApiKeyById(_id: string): Promise<Array<any>> {
    const findBody = {
      integration: { $nin: INTEGRATIONS },
      _id
    };
    return new Promise<Array<IApiKeyModel>>((resolve, reject) => {
      this.genericService
        .find(this.APIKEY_URL, findBody)
        .then((allApiKeys: Array<IApiKeyModel>) => {
          resolve(allApiKeys);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApiKeyService.name, 'getApiKeyById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.APIKEY_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApiKeyService.name, 'deleteById'));
        });
    });
  }

  getApiKey(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, ApiKeyService.name, 'getApiKey');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.APIKEY_URL}/api-key`, httpOptions)
        .toPromise()
        .then((apiKey: string) => {
          resolve(apiKey);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApiKeyService.name, 'getApiKey'));
        });
    });
  }

  async getApiKeyForIntegration(integration): Promise<IApiKeyModel> {
    try {
      const findBody = {
        _deleted: { $ne: true },
        integration
      };
      const [apiKey] = await this.genericService.find(this.APIKEY_URL, findBody);
      return apiKey;
    } catch (error) {
      this.errorManager.handleRawError(error, ApiKeyService.name, 'getAllApiKeys');
    }
  }

  create(data: object): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, ApiKeyService.name, 'create');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(this.APIKEY_URL, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApiKeyService.name, 'create'));
        });
    });
  }

  update(apiKeyId: string, data: object): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, ApiKeyService.name, 'update');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .put(`${this.APIKEY_URL}/${apiKeyId}`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApiKeyService.name, 'update'));
        });
    });
  }

  refresh(apiKeyId: string, data: object): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, ApiKeyService.name, 'refresh');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .put(`${this.APIKEY_URL}/${apiKeyId}/refresh`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApiKeyService.name, 'refresh'));
        });
    });
  }
}

export interface IApiKeyModel {
  _id?: string;
  _maskedApiKey?: string;
  alias?: string;
  s_apiKey?: string;
  _refreshedAt?: Date;
}
