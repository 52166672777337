import { Injectable, Injector, OnInit } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';

import { InternationalizationService } from '../core/internationalization.service';
import { ErrorManagerService } from '../error/error-manager.service';

export const copyToClipboard = (text: string) => {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = text;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
};

@Injectable()
export class ClipboardService {
  constructor(private snackBar: MatLegacySnackBar, private errorManager: ErrorManagerService, private i18n: InternationalizationService) {}

  async copy(text: string, success?: string, failure?: string) {
    try {
      copyToClipboard(text);
      if (success !== undefined) {
        this.snackBar.open(success, 'OK', {
          duration: 5000,
        });
      }
    } catch (e) {
      if (failure !== undefined) {
        this.errorManager.handleRawError(failure, ClipboardService.name);
      }
    }
  }
}
