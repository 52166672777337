import { Component, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IShiftplanAttendanceData } from '@app/cloud-features/shift-plan/services/shift-plan-attendance.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shiftplan-attendance-card',
  templateUrl: 'shiftplan-attendance-card.component.html',
  styleUrls: ['shiftplan-attendance-card.component.scss'],
})
export class ShiftPlanAttendanceCardComponent {
  @Input() startDay: moment.Moment;
  @Input() isCurrentMonth: boolean;
  @Input() parentTranslation: { [key: string]: string };
  @Input() myAttendance: IShiftplanAttendanceData | null;
  @Input() attendanceVisibility: {
    notViewPermission: boolean;
    tracking: boolean;
    appStatus: boolean;
    showAttendance: boolean;
    hasOvertime: boolean;
    isAttendanceActive: boolean;
    overtimeTracking: boolean;
  };

  constructor(private injector: Injector, protected router: Router) {}
  openAttendanceTab() {
    this.injector.get(PrivateAmplitudeService).logEvent('navigated Shiftplan to attendance data', {
      category: 'Shiftplan',
      subcategory: 'Schedule',
    });
    this.injector.get(PrivateAmplitudeService).logEvent('navigated Shiftplan to attendance data', {
      category: 'Shiftplan',
      subcategory: 'Schedule',
    });
    const userAttendanceUrl = this.router.serializeUrl(
      this.router.createUrlTree([`/cloud/people/${this.myAttendance.userId}/attendance`], {
        queryParams: { month: this.startDay.format('MM-01-YYYY') },
      })
    );
    window.open(userAttendanceUrl, '_blank');
  }
}
