import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class MeetingResponseService {
  private MEETING_RESPONSE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/meeting-response-db`;
  private MEETING_RESPONSE_PERMISSIONS_KEY: string = 'meeting-response';
  private MEETING_INTERNATIONALIZATION: string = 'meeting-response-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any): Promise<IMeetingResponseModel> {
    return new Promise<IMeetingResponseModel>((resolve, reject) => {
      this.genericService
        .create(this.MEETING_RESPONSE_URL, data)
        .then((meeting: IMeetingResponseModel) => {
          resolve(meeting);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingResponseService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IMeetingResponseModel>> {
    return new Promise<Array<IMeetingResponseModel>>((resolve, reject) => {
      this.genericService
        .find(this.MEETING_RESPONSE_URL, findQuery)
        .then((meetings: Array<IMeetingResponseModel>) => {
          resolve(meetings);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingResponseService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<IMeetingResponseModel> {
    return new Promise<IMeetingResponseModel>((resolve, reject) => {
      this.genericService
        .getById(this.MEETING_RESPONSE_URL, id)
        .then((meeting: IMeetingResponseModel) => {
          resolve(meeting);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingResponseService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IMeetingResponseModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.MEETING_RESPONSE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingResponseService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.MEETING_RESPONSE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingResponseService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.MEETING_RESPONSE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.MEETING_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.MEETING_RESPONSE_URL);
  }

  getAllMeetings(): Promise<Array<IMeetingResponseModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<IMeetingResponseModel>>((resolve, reject) => {
      this.genericService
        .find(this.MEETING_RESPONSE_URL, findBody)
        .then((allMeetings: Array<IMeetingResponseModel>) => {
          resolve(allMeetings);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingResponseService.name, 'getAllMeetings'));
        });
    });
  }
}

export interface IMeetingResponseModel {
  _id?: string;
  _questionId?: string;
  _receiverId?: string;
  replyMessage?: string;
  replyDate?: Date;
  replied?: boolean;
}

export interface IQuestionResponses {
  total?: number;
  responded?: number;
  responses?: Array<IMeetingResponseModel>;
  questionId?: string;
}
