import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IWorkScheduleTemplateModel } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { ITimeOffAssignmentsModel } from '@app/cloud-features/time-off/services/time-off-status.service';
import { ITimeOffTypeModel } from '@app/cloud-features/time-off/services/time-off-type.service';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ISelectOption } from '@app/standard/core/select-option';
import { ICompanyModel } from '@app/standard/services/company/company.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AddEmployeeController {
  private ADD_EMPLOYEE_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/add-employee`;

  constructor(private injector: Injector, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  async getCreationData(): Promise<ICreationData> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.ADD_EMPLOYEE_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, AddEmployeeController.name, 'getCreationData');
        throw this.errorManager.handleRawError(error, AddEmployeeController.name, 'getCreationData');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get<any>(`${this.ADD_EMPLOYEE_CONTROLLER_URL}/`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, AddEmployeeController.name, 'getCreationData');
    }
  }

  async addEmployee(userAccount: any, userPersonal: any, userWork: any, userWorkSchedule: any, timeOffAssignments: ITimeOffAssignmentsModel, sendInvitation: boolean, addUserDatev: boolean, userDatevPersonalNumber: number): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.ADD_EMPLOYEE_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, AddEmployeeController.name, 'addEmployee');
        throw this.errorManager.handleRawError(error, AddEmployeeController.name, 'addEmployee');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector.get(UserPersonalService).clearCache();
      return await this.http.post<any>(`${this.ADD_EMPLOYEE_CONTROLLER_URL}/`, { userAccount, userWork, userPersonal, userWorkSchedule, timeOffAssignments, sendInvitation, addUserDatev, userDatevPersonalNumber }, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, AddEmployeeController.name, 'addEmployee');
    }
  }

  async addCandidateToEmployees(userAccount: any, userPersonal: any, userWork: any, userWorkSchedule: any, timeOffAssignments: ITimeOffAssignmentsModel, sendInvitation: boolean, candidateData: any, addUserDatev: boolean, userDatevPersonalNumber: number): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.ADD_EMPLOYEE_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, AddEmployeeController.name, 'addEmployee');
        throw this.errorManager.handleRawError(error, AddEmployeeController.name, 'addEmployee');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector.get(UserPersonalService).clearCache();
      return await this.http.post<any>(`${this.ADD_EMPLOYEE_CONTROLLER_URL}/candidateToEmployee`, { userAccount, userWork, userPersonal, userWorkSchedule, timeOffAssignments, sendInvitation, candidateData, addUserDatev, userDatevPersonalNumber }, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, AddEmployeeController.name, 'addEmployee');
    }
  }
}

export interface ICreationData {
  companies: Array<ICompanyModel>;
  offices: Array<ISelectOption>;
  areas: Array<ISelectOption>;
  divisions: Array<ISelectOption>;
  departments: Array<ISelectOption>;
  profiles: Array<ISelectOption>;
  workScheduleTemplates: Array<IWorkScheduleTemplateModel>;
  reportsToOptions: Array<{ _id: string; displayName: string; _photo?: any; isActive: boolean }>;
  timeOffTypes: Array<ITimeOffTypeModel>;
  attendancePolicies: Array<IAttendancePolicy>;
}
