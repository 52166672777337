import { CdkOverlayOrigin, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import * as check from 'check-types';

import { AutocompleteOptionDirective } from '../input-simple-autocomplete/autocomplete-option.directive';

@Component({
  selector: 'orgos-input-simple-search-user',
  templateUrl: 'input-simple-search-user.component.html',
  styleUrls: ['input-simple-search-user.component.scss'],
})
export class InputSimpleSearchUserComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {
  miscTranslation: any = {};
  showClearButton: boolean = false;
  showSavedHint: boolean = false;

  searching: boolean = false;
  searchResults: Array<IUserOptions> = [];

  private _indexSearchResultFocused: number = 0;
  set indexSearchResultFocused(indexSearchResultFocused: number) {
    this._indexSearchResultFocused = indexSearchResultFocused;

    this.makeScroll = true;
  }
  get indexSearchResultFocused(): number {
    return this._indexSearchResultFocused;
  }

  private timeouts: Array<number> = [];

  makeScroll: boolean = false;

  currentUser: any = {};

  searchResultsContaineOverlayRef: OverlayRef = null;

  _searchTerm: string = '';
  set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
    this.refreshSearchResults();
  }
  get searchTerm(): string {
    return this._searchTerm;
  }

  private _users: Array<IUserOptions> = [];
  @Input()
  set users(users: Array<IUserOptions>) {
    if (check.not.assigned(users)) {
      this._users = [];
      return;
    }

    this._users = users.slice().sort((userA: IUserOptions, userB: IUserOptions) => {
      const nameA = userA.displayName.toLowerCase();
      const nameB = userB.displayName.toLowerCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
    this.currentUser = this.getDisplayedValue();
  }
  get users(): Array<IUserOptions> {
    return this._users;
  }

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() value: string = '';
  @Input() enableClearButton: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() largeResultsBox: boolean = false;
  @Input() savedHint: boolean = false;
  @Input() showAvatar: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(CdkPortal, { static: true }) searchResultsContainer: Portal<any>;
  @ViewChild(CdkOverlayOrigin) searchInputOrigin: CdkOverlayOrigin;
  @ViewChild('isucInput') input: ElementRef;
  @ViewChildren(AutocompleteOptionDirective) results: QueryList<AutocompleteOptionDirective>;

  @HostListener('keydown', ['$event']) handleKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Down' || event.key === 'ArrowDown') {
      if (check.assigned(this.searchResults) && check.not.emptyArray(this.searchResults)) {
        this.indexSearchResultFocused = (this.indexSearchResultFocused + 1) % this.searchResults.length;
      }
      event.preventDefault();
    } else if (event.key === 'Up' || event.key === 'ArrowUp') {
      if (check.assigned(this.searchResults) && check.not.emptyArray(this.searchResults)) {
        this.indexSearchResultFocused =
          this.indexSearchResultFocused - 1 < 0 ? this.searchResults.length - 1 : this.indexSearchResultFocused - 1;
      }
      event.preventDefault();
    } else if (event.key === 'Enter') {
      if (check.assigned(this.searchResults) && check.not.emptyArray(this.searchResults)) {
        this.setValue(this.searchResults[this.indexSearchResultFocused]._id);
      }

      event.preventDefault();
    }
  }

  constructor(public injector: Injector, public overlay: Overlay, protected cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.showClearButton = this.enableClearButton && check.assigned(this.value);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  ngAfterViewChecked(): void {
    this.scrollToSearchResultFocused();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.currentUser = this.getDisplayedValue();
      this.showClearButton = this.enableClearButton && check.assigned(this.value);
    }
  }

  ngOnDestroy(): void {
    this.timeouts.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  }

  enableSearching(config = null): void {
    if (this.readOnly === true || this.searching === true) {
      return;
    }

    if (!config) {
      config = new OverlayConfig();
      config.hasBackdrop = true;
      config.minWidth = this.searchInputOrigin.elementRef.nativeElement.getBoundingClientRect().width;
      config.backdropClass = 'cp-event-details-overlay';
      config.positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.searchInputOrigin.elementRef)
        .withPositions([
          { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
          { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' },
        ])
        .withPush(false);
    }

    this.searchResultsContaineOverlayRef = this.overlay.create(config);
    this.searchResultsContaineOverlayRef.attach(this.searchResultsContainer);

    this.searchResultsContaineOverlayRef.backdropClick().subscribe(() => {
      this.disableSearching();
    });

    this.searching = true;
    this.searchTerm = '';

    this.refreshSearchResults();
  }

  private disableSearching(): void {
    if (check.assigned(this.searchResultsContaineOverlayRef)) {
      this.searchResultsContaineOverlayRef.detachBackdrop();
      this.searchResultsContaineOverlayRef.dispose();
    }

    this.searching = false;
    this.searchTerm = '';
  }

  setValue(id: string): void {
    this.value = id;
    this.valueChange.emit(id);
    this.currentUser = this.getDisplayedValue();

    this.showClearButton = this.enableClearButton && check.assigned(id);

    this.disableSearching();

    this.input.nativeElement.blur();
    this.activateSavedHint();
  }

  clearValue(): void {
    this.setValue(null);
  }

  refreshSearchResults(): void {
    if (this.searching === false) {
      this.searchResults = [];
      return;
    }

    const allResults = this.users;

    if (check.not.assigned(this.searchTerm) || check.emptyString(this.searchTerm)) {
      this.searchResults = allResults;

      this.indexSearchResultFocused = this.searchResults.findIndex((iSearchResult: IUserOptions) => {
        return iSearchResult._id === this.value;
      });

      return;
    }

    const filtereResults = allResults.filter((user: IUserOptions) => {
      const regExp = new RegExp(`^.*${this.searchTerm}.*$`, 'i');
      return regExp.test(user.displayName);
    });

    this.searchResults = filtereResults;

    this.indexSearchResultFocused = 0;
    return;
  }

  private scrollToSearchResultFocused(): void {
    if (this.makeScroll === true && check.assigned(this.results) && this.results.length > 0) {
      const searchResultFocused = this.results.toArray()[this.indexSearchResultFocused];
      if (check.assigned(searchResultFocused)) {
        searchResultFocused
          .getElementRef()
          .nativeElement.scrollIntoView(/*NO SUPPORTED BY ALL BROWSERS: {block: 'center', inline: 'nearest'}*/);
      }

      this.makeScroll = false;
    }
  }

  private activateSavedHint(): void {
    if (check.not.assigned(this.savedHint) || this.savedHint === false) {
      return;
    }

    this.cdr.detectChanges();

    const showHintTimeout: number = window.setTimeout(() => {
      this.showSavedHint = true;
      this.cdr.detectChanges();
    }, 2000);
    this.timeouts.push(showHintTimeout);

    const hideHintTimeout: number = window.setTimeout(() => {
      this.showSavedHint = false;
      this.cdr.detectChanges();
    }, 3000);
    this.timeouts.push(hideHintTimeout);
  }

  private getDisplayedValue(): any {
    if (check.not.nonEmptyString(this.value)) {
      return null;
    } else {
      const currentUser = this.users.find((user: IUserOptions) => {
        return user._id === this.value;
      });
      return currentUser;
    }
  }
}

export interface IUserOptions {
  _id: string;
  displayName: string;
  _photo?: IFileMetadata;
  roles?: any;
  tags?: any;
}
