<!-- ANNUAL GRANT POLICIES -->
<ng-container *ngIf="userPolicyStatus && statusData">
  <orgos-container aspect="card" *ngIf="userPolicyStatus.policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED" class="totsc-limited-card-container">
    <div class="totsc-color-horizontal-bar" [style.background-color]="colors[userPolicyStatus.timeOffType.color]"></div>
    <orgos-column-container centerColumns="true" class="kenjo-p-15px kenjo-border-bottom">
      <orgos-column size="0">
        <div class="totsc-limited-time-off-title">
          <kenjo-truncate-text class="kenjo-font-size-16px">
            {{ userPolicyStatus.timeOffType.name }}
          </kenjo-truncate-text>
        </div>
      </orgos-column>
      <orgos-column></orgos-column>
      <orgos-column size="0" *ngIf="userPolicyStatus && (canSeePolicyDetails || userPolicyStatus.policy.showDetails)">
        <orgos-action (click)="openTimeOffDetails()">{{ translation.details }}</orgos-action>
      </orgos-column>
    </orgos-column-container>

    <div class="totsc-info-container">
      <div>
        <orgos-column-container class="kenjo-ph-15px kenjo-mt-20px">
          <orgos-column>
            <kenjo-truncate-text class="totsc-allowance-text"> {{ translation.totalStartingBalance }} </kenjo-truncate-text>
          </orgos-column>

          <orgos-column size="0" class="kenjo-font-color-light-text-757575">
            {{ statusData.totalAllowance }}
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container class="kenjo-ph-15px kenjo-mt-15px">
          <orgos-column class="kenjo-pr-50px">{{ translation.taken }}</orgos-column>
          <orgos-column size="0" class="kenjo-font-color-light-text-757575">{{ statusData.taken }}</orgos-column>
        </orgos-column-container>

        <orgos-column-container class="kenjo-ph-15px kenjo-mt-15px">
          <orgos-column class="kenjo-pr-50px">{{ translation.planned }}</orgos-column>
          <orgos-column size="0" class="kenjo-font-color-light-text-757575">{{ statusData.planned }}</orgos-column>
        </orgos-column-container>
      </div>

      <orgos-column-container class="kenjo-ph-15px kenjo-mt-20px kenjo-pv-10px kenjo-color-grey-background-lighter-f8f8f8 kenjo-font-size-16px">
        <orgos-column>{{ translation.available | uppercase }}</orgos-column>
        <orgos-column size="0">{{ statusData.available }}</orgos-column>
      </orgos-column-container>

      <orgos-column-container class="kenjo-ph-10px kenjo-mt-20px" *ngIf="!userPolicyStatus.currentStatus.hasExpiredCarryOver && userPolicyStatus.currentStatus.currentCarryOver > 0">
        <orgos-column [matTooltip]="expireDateInfo" size="1" class="totsc-carry-over kenjo-font-color-light-text-757575 kenjo-border-radius-4px">
          <span class="totsc-carry-over-calendar">
            <kenjo-icon class="kenjo-font-color-primary-5993e3" [size]="14">calendar_today</kenjo-icon>
          </span>
          <kenjo-truncate-text class="totsc-carry-over-text kenjo-font-color-primary-5993e3"> {{ translation.pendingCarryOver | i18nData: { carryOver: statusData.currentCarryOver } }} </kenjo-truncate-text>
        </orgos-column>
      </orgos-column-container>
    </div>

    <!-- REQUEST BUTTON -->
    <div class="totsc-request-button-container kenjo-text-align-center" [matTooltip]="isOnProbation ? translation.probationPeriodTooltip : null" matTooltipPosition="above">
      <orgos-button-raised *ngIf="!onBehalf && showRequest && requireApproval" (click)="openTimeOffRequestDialog()" id="request_btn" [disabled]="disableRequestButton || isOnProbation"> {{ translation.requestButton }}</orgos-button-raised>
      <orgos-button-raised *ngIf="!onBehalf && showRequest && !requireApproval" (click)="openTimeOffRequestDialog()" id="submit_btn" [disabled]="disableRequestButton || isOnProbation"> {{ translation.submitButton }}</orgos-button-raised>
      <orgos-button-raised *ngIf="onBehalf" (click)="openTimeOffRequestDialog()" [disabled]="disableRequestButton || isOnProbation">{{ translation.submitOnBehalfButton }} </orgos-button-raised>
    </div>
  </orgos-container>
  <!-- END EARNED, ANNUAL GRANT & HOURLY PAID POLICIES -->

  <!-- UNLIMITED POLICIES -->
  <div class="kenjo-full-height" *ngIf="userPolicyStatus.policy.allowanceType === ALLOWANCE_TYPE_UNLIMITED">
    <div class="totsc-container-unlimited-card kenjo-border-bottom">
      <div class="totsc-color-vertical-bar kenjo-inline-block" [style.background-color]="colors[userPolicyStatus.timeOffType.color]"></div>
      <div class="totsc-unlimited-card-right-side kenjo-p-15px">
        <div class="totsc-unlimited-time-off-title">
          <kenjo-truncate-text class="kenjo-font-size-16px">
            {{ userPolicyStatus.timeOffType.name }}
          </kenjo-truncate-text>
        </div>
        <orgos-column-container class="totsc-unlimited-buttons" centerColumns="true">
          <orgos-action orgosColumn="0" *ngIf="userPolicyStatus && (canSeePolicyDetails || userPolicyStatus.policy.showDetails)" (click)="openTimeOffDetails()">{{ translation.details }}</orgos-action>
          <orgos-column></orgos-column>
          <orgos-column size="0" [matTooltip]="isOnProbation ? translation.probationPeriodTooltip : null" matTooltipPosition="above">
            <!-- REQUEST BUTTON -->
            <orgos-button-raised [disabled]="disableRequestButton || isOnProbation" *ngIf="!onBehalf && showRequest && requireApproval" (click)="openTimeOffRequestDialog()" id="request_btn">
              {{ translation.requestButton }}
            </orgos-button-raised>
            <orgos-button-raised [disabled]="disableRequestButton || isOnProbation" *ngIf="!onBehalf && showRequest && !requireApproval" (click)="openTimeOffRequestDialog()" id="submit_btn">
              {{ translation.submitButton }}
            </orgos-button-raised>
            <orgos-button-raised [disabled]="disableRequestButton || isOnProbation" *ngIf="onBehalf" (click)="openTimeOffRequestDialog()">
              {{ translation.submitOnBehalfButton }}
            </orgos-button-raised>
          </orgos-column>
        </orgos-column-container>
      </div>
    </div>
  </div>
  <!-- END UNLIMITED & HOURLY UNPAID POLICIES -->
</ng-container>
