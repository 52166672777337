import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-edit-work-schedule-simple-dialog',
  templateUrl: 'edit-work-schedule-simple.dialog.html',
  styleUrls: ['edit-work-schedule-simple.dialog.scss'],
})
export class EditWorkScheduleSimpleDialog implements OnInit {
  dialogTranslation: any = {};
  workSchedule: any;
  updateOp: boolean = true;
  oldDate: any;
  editMode: boolean = false;
  schedule: any = {};
  company: any;
  tempWeeklyHours: number;
  translatedWeekdays: Array<string>;
  userPersonal: GenericSimpleModel;
  overtimeActive: boolean = false;
  canEditWorkSchedule: Promise<boolean>;
  canEditUserWork: Promise<boolean>;
  weeklyHoursValidation: InputValidation;
  constructor(
    public dialogRef: MatLegacyDialogRef<EditWorkScheduleSimpleDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private workScheduleInfo: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.editMode = check.assigned(this.workScheduleInfo);
    this.translatedWeekdays = this.injector.get(InternationalizationService).getShortTranslatedWeekdays();
    this.workSchedule = {
      ...this.workScheduleInfo.workSchedule.data,
    };
    this.dialogTranslation = this.workScheduleInfo.translation;
    this.company = this.workScheduleInfo.company;
    this.tempWeeklyHours = this.workScheduleInfo.userWork.data.weeklyHours;
    this.userPersonal = this.workScheduleInfo.userPersonal.data;
    this.canEditUserWork = this.workScheduleInfo.userWork.canEdit;
    this.canEditWorkSchedule = this.workScheduleInfo.workSchedule.canEdit;
    this.overtimeActive =
      check.assigned(this.workScheduleInfo) &&
      check.assigned(this.workScheduleInfo.workSchedule) &&
      check.assigned(this.workScheduleInfo.workSchedule.data) &&
      check.assigned(this.workScheduleInfo.workSchedule.data.overtimeSettings) &&
      check.assigned(this.workScheduleInfo.workSchedule.data.overtimeSettings.trackOvertime) &&
      this.workScheduleInfo.workSchedule.data.overtimeSettings.trackOvertime === true;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    this.workScheduleInfo.workSchedule.data = this.workSchedule;
    this.workScheduleInfo.userWork.data.weeklyHours = this.tempWeeklyHours;
    const listPromises = [];
    Promise.all([this.workScheduleInfo.userWork.canEdit, this.workScheduleInfo.workSchedule.canEdit])
      .then(([userWorkPermission, userWorkSchedulePermission]) => {
        if (userWorkPermission) {
          listPromises.push(this.workScheduleInfo.userWork.update());
        }
        if (userWorkSchedulePermission) {
          listPromises.push(this.workScheduleInfo.workSchedule.update());
        }
        return Promise.all(listPromises);
      })
      .then(() => {
        const customScheduleInfo = {
          userIds: [this.workScheduleInfo.userWorkId],
          entry: this.workSchedule,
        };
        delete customScheduleInfo.entry.overtimeSettings;
        delete customScheduleInfo.entry.trackAttendance;

        return this.injector.get(UserWorkScheduleService).updateScheduleEntries(customScheduleInfo);
      })
      .then(() => {
        this.dialogRef.close({ operation: 'update' });
      })
      .catch(() => {
        //
      });
  }
}
