import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class EmailTemplateService {
  private EMAIL_TEMPLATE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/email-template-db`;
  private EMAIL_TEMPLATE_PERMISSIONS_KEY: string = 'email-template';
  private EMAIL_TEMPLATE_INTERNATIONALIZATION: string = 'email-template-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private injector: Injector, private authenticationService: AuthenticationService, private http: HttpClient) {}

  getAllTemplates(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const findQuery = {};
      findQuery[fieldConstants.ID] = { $ne: null };
      this.http
        .post(`${this.EMAIL_TEMPLATE_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'getAllTemplates'));
        });
    });
  }

  getTemplatesForCollection(collectionName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const findQuery = {
        inCollection: collectionName
      };
      this.http
        .post(`${this.EMAIL_TEMPLATE_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'getAllTemplates'));
        });
    });
  }

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.EMAIL_TEMPLATE_URL, data)
        .then((document: any) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'create'));
        });
    });
  }

  find(findBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .find(this.EMAIL_TEMPLATE_URL, findBody)
        .then((candidate: any) => {
          resolve(candidate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.EMAIL_TEMPLATE_URL, id)
        .then((doc: any) => {
          resolve(doc);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.EMAIL_TEMPLATE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.EMAIL_TEMPLATE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'deleteById'));
        });
    });
  }

  applyEmailTemplate(id: string, document: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .put(`${this.EMAIL_TEMPLATE_URL}/apply/${id}`, { document: document }, httpOptions)
        .toPromise()
        .then((appliedTemplate) => {
          resolve(appliedTemplate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'applyEmailTemplate'));
        });
    });
  }

  testEmailTemplate(emailSubject: string, emailBody: string, emailCollection: string, document: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const emailTemplate = {
        subject: emailSubject,
        body: emailBody,
        inCollection: emailCollection
      };
      this.http
        .post(`${this.EMAIL_TEMPLATE_URL}/test-template`, { document: document, template: emailTemplate }, httpOptions)
        .toPromise()
        .then((appliedTemplate) => {
          resolve(appliedTemplate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailTemplateService.name, 'testEmailTemplate'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.EMAIL_TEMPLATE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.EMAIL_TEMPLATE_INTERNATIONALIZATION);
  }
}
