export enum ErrorCodes {
  UNKNOWN = 0,
  CLIENT_ERROR = 1,
  SESSION_EXPIRED = 2,
  THIRD_PARTY_SERVICE_ERROR = 3,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  NOT_IMPLEMENTED = 501,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  CONFLICT = 409,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
}
