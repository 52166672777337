<ng-container *ngIf="!loadingPage">
  <!-- ACTION BAR -->
  <orgos-column-container centerColumns="true">
    <orgos-column class="dmdp-view">
      <orgos-list-view [preferenceKey]="PREFERENCE_VIEW" [newView]="selectedView" (onDeleteView)="switchView($event)" (onChangeView)="switchView($event)" (onClickNew)="createView()" [simpleView]="true"></orgos-list-view>
    </orgos-column>
    <orgos-column></orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px kenjo-text-align-right">
      <orgos-action *ngIf="profilePermissions['document'] && (profilePermissions['document'].create_all || profilePermissions['document'].create_own)" (click)="uploadDocument()" icon="add_circle" iconSize="large" class="kenjo-mr-40px"> {{ i18n.menu.uploadDocumentButtonTooltip }}</orgos-action>
      <kenjo-icon-toggle [matTooltip]="queryOptions.listView ? i18n.page.gridView: i18n.page.listView" [optionAIcon]="'list_icon'" [optionBIcon]="'grid_icon'" [selectedValue]="queryOptions.listView" (checkedChange)="toggleView($event)"></kenjo-icon-toggle>
    </orgos-column>
  </orgos-column-container>

  <!-- FILTER BAR -->
  <div class="kenjo-pt-20px">
    <kenjo-filter-bar
      [filterOptions]="filters"
      [shown]="true"
      [searchTerm]="queryOptions.search"
      (changeFilterEvent)="toggleFilter($event)"
      (clearFilterEvent)="clearFilter($event)"
      (clearAllFiltersEvent)="clearAllFilters()"
      (searchTermChanged)="searchByTerm($event)"
    ></kenjo-filter-bar>
  </div>

  <!-- No docs -->
  <div *ngIf="dataLoaded && myDocuments && myDocuments.length === 0" class="kenjo-p-20px">
    <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{ i18n.page.noDocumentsTitle }}</div>
      <div class="kenjo-mt-5px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.noDocumentsSubtitle }}</div>
    </div>
  </div>

  <!-- If still loading (GRID) -->
  <div *ngIf="!dataLoaded && queryOptions.listView === false" class="dmdp-list-container dmdp-list-loading-spinner kenjo-p-20px">
    <div>
      <orgos-loading-spinner></orgos-loading-spinner>
      <div class="kenjo-mt-20px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ i18n.page.loadingDataMessage }}</div>
    </div>
  </div>

  <!-- GRID VIEW -->
  <orgos-column-container wrap="true" *ngIf="dataLoaded && queryOptions.listView === false && myDocuments && myDocuments.length > 0">
    <orgos-document-card *ngFor="let document of myDocuments" orgosColumn [document]="document" [signatureTooltip]="i18n.page.digitalSignedTooltip" [allDocumentTags]="documentTags" class="kenjo-mb-20px kenjo-mr-20px"></orgos-document-card>
  </orgos-column-container>

  <!-- TABLE VIEW -->
  <ng-container *ngIf="queryOptions.listView === true">
    <orgos-container aspect="card" class="dmdp-table-container" *ngIf="!dataLoaded || (dataLoaded && myDocuments && myDocuments.length > 0)">
      <!-- If still loading -->
      <div *ngIf="!dataLoaded" class="dmdp-list-container">
        <div class="dmdp-list-loading-spinner dmdp-relative kenjo-p-20px">
          <div>
            <orgos-loading-spinner></orgos-loading-spinner>
            <div class="kenjo-mt-20px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ i18n.page.loadingDataMessage }}</div>
          </div>
        </div>
      </div>

      <!-- List -->
      <orgos-table-smart
        *ngIf="dataLoaded && myDocuments && myDocuments.length > 0"
        [data]="myDocuments"
        [manageColumns]="false"
        [totalColumns]="totalColumns"
        [selectable]="false"
        [sortBy]="sortBy"
        [sortOrder]="sortOrder"
        (sortTable)="changeColumnSort($event)"
        [customHeightToSubstract]="paginationConfiguration.totalOfRecords > 25 ? 321 : 274"
        [style.height]="paginationConfiguration.totalOfRecords > 25 ? 'calc(100vh - 321px)' : 'calc(100vh - 274px)'"
      >
        <orgos-table-smart-column cellWidth=" 10" columnKey="document.name" [header]="i18n.page.name" [sortable]="true">
          <ng-container *orgosTableSmartCell="let row">
            <orgos-column-container centerColumns="true">
              <orgos-document-icon orgosColumn="0" size="small" [extension]="row._file?._fileExtension" class="kenjo-pr-10px"></orgos-document-icon>
              <a *ngIf="row.hideDetailLink !== true" orgosColumn="0" (click)="openDocumentDetail(row._id)" class="dmdp-relevant-field">
                <kenjo-truncate-text class="kenjo-font-size-14px">{{row.name}}</kenjo-truncate-text>
              </a>
              <kenjo-truncate-text orgosColumn="0" *ngIf="row.hideDetailLink === true" class="kenjo-font-size-14px dmdp-relevant-field">{{row.name}}</kenjo-truncate-text>
            </orgos-column-container>
          </ng-container>
        </orgos-table-smart-column>
        <orgos-table-smart-column cellWidth="10" columnKey="document._createdAt" [header]="i18n.page.uploadedOn" [sortable]="true">
          <ng-container *orgosTableSmartCell="let row">
            <kenjo-truncate-text class="kenjo-font-size-14px dmdp-irrelevant-field"> {{row._createdAt | humanDate:false:'short'}}</kenjo-truncate-text>
          </ng-container>
        </orgos-table-smart-column>
        <orgos-table-smart-column cellWidth="10" columnKey="document.validUntil" [header]="i18n.page.validUntil" [sortable]="true">
          <ng-container *orgosTableSmartCell="let row">
            <kenjo-truncate-text class="kenjo-font-size-14px dmdp-irrelevant-field"> {{row.validUntil | utcDate | humanDate:false:'short'}}</kenjo-truncate-text>
          </ng-container>
        </orgos-table-smart-column>
        <orgos-table-smart-column cellWidth="10" columnKey="tags" [header]="i18n.page.tags">
          <!-- tags-->
          <ng-container *orgosTableSmartCell="let row">
            <div *ngIf="row.tags.length === 0">
              <div class="dmdp-tag-container kenjo-pr-5px">
                <orgos-chip color="#757575" class="kenjo-text-align-center">
                  <kenjo-truncate-text class="dmdp-tag-text kenjo-font-color-white-ffffff">{{ i18n.page.unlabelledTagName }}</kenjo-truncate-text>
                </orgos-chip>
              </div>
            </div>
            <div *ngIf="row.tags && row.tags.length < 4">
              <div *ngFor="let tag of row.tags" class="dmdp-tag-container kenjo-pr-5px">
                <orgos-chip *ngIf="documentTagById && documentTagById[tag] && documentTagById[tag].color" [color]="USER_COLORS[documentTagById[tag].color]" class="kenjo-text-align-center">
                  <kenjo-truncate-text class="dmdp-tag-text kenjo-font-color-white-ffffff">{{ documentTagById[tag].name }} </kenjo-truncate-text>
                </orgos-chip>
              </div>
            </div>
            <orgos-column-container *ngIf="row.tags && row.tags.length > 3">
              <orgos-column size="0" *ngFor="let tag of row.tags; index as i" class="kenjo-pr-5px" [class.dmdp-tag-container]="i < 3">
                <orgos-chip *ngIf="documentTagById && documentTagById[tag] && documentTagById[tag].color && i < 3" [color]="USER_COLORS[documentTagById[tag].color]" class="kenjo-text-align-center">
                  <kenjo-truncate-text class="dmdp-tag-text kenjo-font-color-white-ffffff">{{ documentTagById[tag].name }} </kenjo-truncate-text>
                </orgos-chip>
                <div *ngIf="i === 3 && row.processedTooltip" class="dmdp-tag-circle kenjo-pr-5px" [matTooltip]="row.processedTooltip.join('\n')" matTooltipClass="allow-cr">
                  <div>+{{ row.tags.length - 3 }}</div>
                </div>
              </orgos-column>
            </orgos-column-container>
          </ng-container>
        </orgos-table-smart-column>
        <orgos-table-smart-column cellWidth="10" columnKey="digitalSigned" [header]="'E-Signature'" [sortable]="false">
          <ng-container *orgosTableSmartCell="let row">
            <orgos-chip *ngIf="row.status && row.status === 'Signed'" class="kenjo-text-align-center kenjo-font-color-white-ffffff" [color]="'#00b72e'"> {{i18n.page.digitalSignatureSignedStatus}} </orgos-chip>
            <orgos-column-container *ngIf="row.status && row.status === 'Pending'">
              <orgos-chip orgosColumn="0" (click)="goToSignaturePage(row.signatureRequestId)" class="kenjo-text-align-center kenjo-font-color-light-text-757575 kenjo-cursor-pointer" [color]="'#dedede'"> {{i18n.page.digitalSignaturePendingStatus}} </orgos-chip>
              <orgos-column [size]="0" class="kenjo-pl-10px dmyp-center-sign-icon kenjo-cursor-pointer" (click)="goToSignaturePage(row.signatureRequestId)">
                <kenjo-icon [size]="18" [matTooltip]="i18n.page.viewAndSign" matTooltipPosition="above"> signature </kenjo-icon>
              </orgos-column>
            </orgos-column-container>
          </ng-container>
        </orgos-table-smart-column>
      </orgos-table-smart>
      <!-- PAGINATION -->
      <orgos-column-container class="dmdp-pagination" centerColumns="true" *ngIf="dataLoaded && paginationConfiguration.totalOfRecords > 25">
        <orgos-column></orgos-column>
        <orgos-column size="0">
          <orgos-column-container centerColumns="true">
            <orgos-column size="0" class="kenjo-font-color-light-text-757575">{{ i18n.page.displayingLabel }}</orgos-column>
            <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px dmdp-list-num-pages-container">
              <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
                <orgos-column-container centerColumns="true">
                  <orgos-column size="0" class="dmdp-list-records-page kenjo-font-color-text-333333">{{ queryOptions.recordsPerPage }}</orgos-column>
                  <orgos-column size="0">
                    <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                  </orgos-column>
                </orgos-column-container>
              </div>
              <mat-menu #paginationMenu="matMenu">
                <button *ngFor="let page of recordsToShowSelector" mat-menu-item (click)="changeRecordsToShow(page)">
                  <orgos-column size="0" class="kenjo-font-color-light-text-757575">{{ page }}</orgos-column>
                </button>
              </mat-menu>
            </orgos-column>
            <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-pl-10px">{{ i18n.page.outOfLabel }}</orgos-column>
            <orgos-column size="0" class="kenjo-font-color-text-333333 kenjo-pl-10px">{{ paginationConfiguration.totalOfRecords }}</orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="0" class="kenjo-mh-40px">
          <orgos-column-container centerColumns="true">
            <orgos-column size="0">
              <mat-icon class="kenjo-mr-10px dmdp-list-pagination-icon" [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }" (click)="moveToPage(PAGE_SELECTOR['first'])">first_page</mat-icon>
            </orgos-column>
            <orgos-column size="0">
              <mat-icon class="kenjo-mr-10px dmdp-list-pagination-icon" [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }" (click)="moveToPage(PAGE_SELECTOR['previous'])">keyboard_arrow_left</mat-icon>
            </orgos-column>
            <orgos-column size="0" class="kenjo-ml-10px">{{ queryOptions.page }} / {{ paginationConfiguration.numberOfPages }}</orgos-column>
            <orgos-column size="0">
              <mat-icon
                class="kenjo-ml-10px dmdp-list-pagination-icon"
                [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === paginationConfiguration.numberOfPages }"
                [class.kenjo-font-color-primary-5993e3]="queryOptions.page !== paginationConfiguration.numberOfPages"
                (click)="moveToPage(PAGE_SELECTOR['next'])"
              >
                keyboard_arrow_right
              </mat-icon>
            </orgos-column>
            <orgos-column size="0">
              <mat-icon
                class="kenjo-ml-10px dmdp-list-pagination-icon"
                [ngClass]="{'kenjo-font-color-disabled-c4c4c4 kenjo-no-cursor': queryOptions.page === paginationConfiguration.numberOfPages }"
                [class.kenjo-font-color-primary-5993e3]="queryOptions.page !== paginationConfiguration.numberOfPages"
                (click)="moveToPage(PAGE_SELECTOR['final'])"
              >
                last_page
              </mat-icon>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column></orgos-column>
      </orgos-column-container>
    </orgos-container>
  </ng-container>
</ng-container>
