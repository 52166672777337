<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogTitle" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-editor [(value)]="emailSignature" [label]="dialogTranslation.signaturePlaceholder" class="kenjo-block" size="medium"> </orgos-input-simple-editor>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{miscTranslation.goBackButtonDialog}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="save()" color="Success">{{dialogTranslation.saveButton}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
