<ng-container *ngIf="policy">
  <div class="kenjo-mb-20px kenjo-font-size-16px">{{ translations.policySettingsTitle | i18nData: { name: policy.name } }}</div>
  <orgos-container>
    <ul class="pdcc-list">
      <li class="kenjo-mb-10px">{{ allowanceTypeText }}</li>
      <li *ngIf="policy.carryOver === true" class="kenjo-mb-10px">
        {{ translations.carryOver | i18nData: { quantityMessage: this.carryQuantityMessage, durationMessage: this.carryOverDurationMessage } }}
      </li>
      <li *ngIf="policy.allowanceType !== ALLOWANCE_TYPE_UNLIMITED && policy.cycleType !== CYCLE_TYPE_DISABLED && !policy.carryOver" class="kenjo-mb-10px">{{ translations.carryOverDisabled }}</li>
      <li *ngIf="policy._type !== POLICY_TYPE_HOUR" class="kenjo-mb-10px">{{ policy.allowHalfDays ? translations.halfDays : translations.fullDaysOnly }}</li>
      <li *ngIf="timeOffType.workTime === TIME_OFF_TYPE_ACTIVITY_TYPE_PAID" class="kenjo-mb-10px">{{ translations[TIME_OFF_TYPE_ACTIVITY_TYPE_PAID] }}</li>
      <li *ngIf="timeOffType.workTime === TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID" class="kenjo-mb-10px">{{ translations[TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID] }}</li>
      <li *ngIf="timeOffType.workTime === TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID_NO_REDUCES_EXPECTED" class="kenjo-mb-10px">{{ translations[TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID_NO_REDUCES_EXPECTED] }}</li>
      <li *ngIf="timeOffType.workTime === TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_WORKING_PAID" class="kenjo-mb-10px">{{ translations[TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_WORKING_PAID] }}</li>
      <li *ngIf="timeOffType.workTime === TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_NOT_WORKING_PAID" class="kenjo-mb-10px">{{ translations[TIME_OFF_TYPE_ACTIVITY_TYPE_LEGACY_NOT_WORKING_PAID] }}</li>
      <li class="kenjo-mb-10px">{{ policy.requireApproval ? translations.approvalRequired : translations.noApprovalRequired }}</li>
      <li *ngIf="policy._type !== POLICY_TYPE_HOUR">{{ policy.countNaturalDays ? translations.publicHolidaysSubtracted : translations.publicHolidaysNonSubtracted }}</li>
    </ul>
  </orgos-container>
</ng-container>
