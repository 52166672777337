import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { PositionCandidateScoreService } from '@app/standard/services/recruiting/position-candidate-score.service';
import { PositionCandidateService } from '@app/standard/services/recruiting/position-candidate.service';
import { RecruitingScorecardService } from '@app/standard/services/recruiting/recruiting-scorecard.service';
import * as check from 'check-types';

@Component({
  selector: 'orgos-recruiting-score-candidate-dialog',
  templateUrl: 'recruiting-score-candidate-dialog.html',
  styleUrls: ['recruiting-score-candidate-dialog.scss'],
})
export class RecruitingScoreCandidateDialog implements OnInit {
  MIN_RATING = 1;
  MAX_RATING = 5;
  progressIndex: number = 1;
  dialogTranslation: any = {};
  dataIsCreated: boolean = false;
  position: GenericCacheModel;
  positionCandidate: GenericSimpleModel;
  candidate: GenericCacheModel;
  skillIdToRating: any = {};
  overall: any = {
    comment: '',
    rating: null,
  };
  skillIdToSkill: any = {};
  scorecard: any = {};
  myScore: any = {};

  savingScore: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<RecruitingScoreCandidateDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-score-candidate-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.fetchData();
  }

  private fetchData(): void {
    this.position = this.data.position;
    this.positionCandidate = this.data.positionCandidate;
    this.candidate = this.data.candidate;

    this.initMyScore();
  }

  private initMyScore(): void {
    const positionCandidateId = this.positionCandidate.data._id;
    const scorecardId = this.position.data.recruitingScorecardId;

    const scorecardPromise = this.injector.get(RecruitingScorecardService).getById(scorecardId);
    const myScorePromise = this.injector.get(PositionCandidateScoreService).getMyScoreForPositionCandidate(positionCandidateId);

    Promise.all([scorecardPromise, myScorePromise])
      .then((results) => {
        this.scorecard = results[0];
        this.myScore = results[1];

        let allSkills = [];
        if (
          check.assigned(this.scorecard) &&
          check.assigned(this.scorecard.subsections) &&
          check.array(this.scorecard.subsections) &&
          check.nonEmptyArray(this.scorecard.subsections)
        ) {
          this.scorecard.subsections
            .filter((iSubsection) => {
              return check.assigned(iSubsection.skills) && check.nonEmptyArray(iSubsection.skills);
            })
            .forEach((iSubsection) => {
              allSkills = allSkills.concat(iSubsection.skills);
            });
        }
        allSkills.forEach((iSkill) => {
          this.skillIdToSkill[iSkill._id] = iSkill;
          this.skillIdToRating[iSkill._id] = {
            skillComment: null,
            skillRating: null,
            skillId: iSkill._id,
            showComments: false,
          };
        });

        if (
          check.assigned(this.myScore) &&
          check.nonEmptyObject(this.myScore) &&
          check.assigned(this.myScore.ratings) &&
          check.nonEmptyArray(this.myScore.ratings)
        ) {
          this.myScore.ratings.forEach((iRating) => {
            if (
              check.assigned(iRating.skillId) &&
              check.nonEmptyString(iRating.skillId) &&
              check.assigned(this.skillIdToRating[iRating.skillId])
            ) {
              if (check.assigned(iRating.skillRating) && iRating.skillRating >= this.MIN_RATING && iRating.skillRating <= this.MAX_RATING) {
                this.skillIdToRating[iRating.skillId].skillRating = iRating.skillRating;
              }
              if (check.assigned(iRating.skillComment) && check.nonEmptyString(iRating.skillComment)) {
                this.skillIdToRating[iRating.skillId].skillComment = iRating.skillComment;
                this.skillIdToRating[iRating.skillId].showComments = true;
              }
            }
          });
        }

        if (check.assigned(this.myScore) && check.assigned(this.myScore.overallRating)) {
          this.overall.rating = this.myScore.overallRating;
        }
        if (check.assigned(this.myScore) && check.assigned(this.myScore.overallComment)) {
          this.overall.comment = this.myScore.overallComment;
        }
      })
      .catch(() => {});
  }

  public toggleCommentsForSkillId(skillId: string): void {
    this.skillIdToRating[skillId].showComments = !this.skillIdToRating[skillId].showComments;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveScore(): void {
    if (this.savingScore) {
      return;
    }

    this.savingScore = true;

    let isNewRecord = true;
    if (check.assigned(this.myScore._id) && check.nonEmptyString(this.myScore._id)) {
      isNewRecord = false;
    }
    const positionCandidateScore = this.buildPositionCandidateScore(isNewRecord);
    const savePromise =
      isNewRecord === true
        ? this.injector.get(PositionCandidateScoreService).create(positionCandidateScore)
        : this.injector.get(PositionCandidateScoreService).updateById(this.myScore._id, positionCandidateScore);
    savePromise
      .then(() => {
        const data = {
          jobTitle: this.position.data.jobTitle,
          candidateName: `${this.candidate.data.firstName} ${this.candidate.data.lastName}`,
        };
        const message = this.injector.get(I18nDataPipe).transform(this.dialogTranslation.scorecardSavedSnackbar, data);
        this.injector
          .get(PositionCandidateService)
          .touchUpdatedAtById(this.positionCandidate.data._id)
          .then(() => {})
          .catch(() => {});
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });

        this.dialogRef.close(positionCandidateScore);
      })
      .catch(() => {
        this.savingScore = false;
      });
  }

  private buildPositionCandidateScore(isNewRecord: boolean = false): any {
    // Build position candidate from the selection of the user
    const positionCandidate = {
      ratings: [],
    };

    if (isNewRecord === true) {
      positionCandidate['_positionCandidateId'] = this.positionCandidate.data._id;
    }

    if (check.assigned(this.overall.rating) && this.overall.rating >= this.MIN_RATING && this.overall.rating <= this.MAX_RATING) {
      positionCandidate['overallRating'] = this.overall.rating;
    }
    if (check.assigned(this.overall.comment) && check.nonEmptyString(this.overall.comment)) {
      positionCandidate['overallComment'] = this.overall.comment;
    }

    Object.keys(this.skillIdToRating).forEach((skillId) => {
      let addRating = false;
      const iRating = {
        skillId: skillId,
      };
      if (
        check.assigned(this.skillIdToRating[skillId].skillRating) &&
        this.skillIdToRating[skillId].skillRating >= this.MIN_RATING &&
        this.skillIdToRating[skillId].skillRating <= this.MAX_RATING
      ) {
        iRating['skillRating'] = this.skillIdToRating[skillId].skillRating;
        addRating = true;
      }
      if (check.assigned(this.skillIdToRating[skillId].skillComment) && check.nonEmptyString(this.skillIdToRating[skillId].skillComment)) {
        iRating['skillComment'] = this.skillIdToRating[skillId].skillComment;
        addRating = true;
      }

      if (addRating) {
        positionCandidate.ratings.push(iRating);
      }
    });
    return positionCandidate;
  }
}
