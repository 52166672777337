import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { getEmptyFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { ShiftCardFilters, ShiftPlanCardService } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-copy-shifts-dialog',
  templateUrl: 'copy-shifts-dialog.html',
  styleUrls: ['copy-shifts-dialog.scss'],
})
export class CopyShiftsDialog implements OnInit {
  pageTranslation: any = {};
  weekRange: { from: string; to: string };
  timePeriod: 'week' | 'day' = 'week';
  WEEK_OPTION: string = 'week';
  DAY_OPTION: string = 'day';
  loading: boolean = false;
  isCopying: boolean = false;
  filters: ShiftCardFilters = { ...getEmptyFilters() };
  copyFromDates = [];
  copyToDates = [];
  copyFromDay: string;
  copyToDays = [];
  includeOpen: boolean = false;
  includeDrafts: boolean = false;
  shiftCardSummary;
  isValid: boolean = false;
  SHIFTS_LIMIT: number = 10000;
  shiftsLimitReached: boolean = false;
  inputFromValidation = {};
  inputToValidation = {};
  isLoading: boolean = false;
  hasFromErrors: boolean = false;
  hasToErrors: boolean = false;
  hasSwitched: boolean = false;
  copyFromMultiple;

  constructor(
    public dialogRef: MatLegacyDialogRef<CopyShiftsDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    private confirmDialog: MatLegacyDialog,
    public snackBar: MatLegacySnackBar,
    public datePipe: DatePipe
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.weekRange = { from: this.dialogInfo.start, to: this.dialogInfo.end };
    this.filters = this.dialogInfo.filters;
    this.fetchTranslations();
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('Copy shift intent', { category: 'Shiftplan', subcategory: 'Actions', subcategory2: 'Copy' });
  }

  async fetchTranslations() {
    this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-copy-dialog');
  }

  changeTimePeriod(timePeriod: 'week' | 'day'): void {
    if (timePeriod === this.timePeriod) {
      return;
    }

    this.hasSwitched = false;
    if (this.canSwitchWithNoDialog()) {
      this.timePeriod = timePeriod;
      this.hasSwitched = true;
      this.clearValues();
      this.resetErrors();
      return;
    }

    const switchTimePeriodDialog = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, {
      data: {
        titleText: this.pageTranslation.switchTimePeriodTitle,
        subtitleText: this.pageTranslation.switchTimePeriodSubtitle,
        confirmButtonText: this.pageTranslation.switchButton,
        confirmButtonColor: 'Danger',
        cancelButtonText: this.pageTranslation.goBack,
      },
    });
    switchTimePeriodDialog.afterClosed().subscribe((switchTimePeriod) => {
      if (switchTimePeriod) {
        this.timePeriod = timePeriod;
        this.hasSwitched = true;
        this.clearValues();
        this.resetErrors();
      }
    });
  }

  canSwitchWithNoDialog(): boolean {
    if (this.timePeriod === this.WEEK_OPTION) {
      return this.copyFromDates.length === 0 && this.copyToDates.length === 0;
    }

    if (this.timePeriod === this.DAY_OPTION) {
      return check.emptyString(this.copyFromDay) && this.copyToDays.length === 0;
    }

    return true;
  }

  handleCopyValue(event, field) {
    this.resetErrors();
    if (field === 'from') {
      this.handleCopyFrom(event);
    }

    if (field === 'to') {
      this.handleCopyTo(event);
    }
  }

  resetErrors() {
    this.hasFromErrors = false;
    this.hasToErrors = false;
    this.inputFromValidation = {};
    this.inputToValidation = {};
  }

  clearValues() {
    this.copyFromDates = [];
    this.copyFromMultiple = null;
    this.copyToDates = [];
    this.copyFromDay = '';
    this.copyToDays = [];
    this.shiftCardSummary = null;
  }

  handleCopyFrom({ value, calendarType, removed }) {
    if (calendarType === 'multiple-weeks') {
      this.hasFromErrors = this.validateMultipleWeeksCopyFrom(value, removed) !== true;
      if (this.copyFromDates.length > 1) {
        this.copyFromMultiple = [...this.copyFromDates];
      } else {
        this.copyFromMultiple = null;
      }
    }
    if (calendarType === 'one-day') {
      this.copyFromDay = value[0]?.from;
    }

    this.shiftCardSummary = null;
    this.calculateSummary();
  }

  handleCopyTo({ value, calendarType, removed }) {
    if (calendarType === 'multiple-weeks') {
      this.hasToErrors = this.validateMultipleWeeksCopyTo(value, removed) !== true;
    }

    if (calendarType === 'multiple-days') {
      this.hasToErrors = this.validateMultipleDaysCopyTo(value) !== true;
    }
    this.checkValid();
  }

  validateMultipleWeeksCopyFrom(value, removed) {
    if (removed) {
      this.copyFromDates = [...value].sort(this.sortByDate());
    }

    const copyFromDates = check.array(value) ? [...value].sort(this.sortByDate()) : [...this.copyFromDates, value].sort(this.sortByDate());

    if (copyFromDates.length === 0) {
      this.inputFromValidation['required'] = true;
      return;
    }

    if (this.validateAlreadySelected(value, this.copyFromDates)) {
      this.inputFromValidation['alreadySelected'] = true;
      return;
    }

    if (this.validateFirstWeekSame(copyFromDates, 'from')) {
      this.inputFromValidation['sameWeek'] = true;
      this.hasToErrors = true;
      return;
    }

    if (
      (this.copyToDates.length > 0 && copyFromDates.length > this.copyToDates.length) ||
      (copyFromDates.length > 1 && copyFromDates.length < this.copyToDates.length)
    ) {
      this.inputFromValidation['numberOfWeeks'] = true;
      this.hasToErrors = true;
      return;
    }

    if (copyFromDates.length > 1 && this.validateConsecutiveWeeks(copyFromDates)) {
      this.inputFromValidation['consecutiveWeeks'] = true;
      return;
    }
    this.copyFromDates = [...copyFromDates];
    return true;
  }

  validateMultipleWeeksCopyTo(value, removed) {
    if (removed) {
      this.copyToDates = [...value].sort(this.sortByDate());
    }

    const copyToDates = check.array(value) ? [...value].sort(this.sortByDate()) : [...this.copyToDates, value].sort(this.sortByDate());
    if (copyToDates.length === 0) {
      this.inputToValidation['required'] = true;
      return false;
    }

    if (this.validateAlreadySelected(value, this.copyToDates)) {
      this.inputToValidation['alreadySelected'] = true;
      return false;
    }

    if (this.validateFirstWeekSame(copyToDates, 'to')) {
      this.inputToValidation['sameWeek'] = true;
      return false;
    }

    if (
      (copyToDates.length > 1 && this.copyFromDates.length > 1 && this.copyFromDates.length !== copyToDates.length) ||
      (copyToDates.length === 1 && copyToDates.length < this.copyFromDates.length)
    ) {
      this.inputToValidation['numberOfWeeks'] = true;
      return false;
    }

    if (copyToDates.length > 1 && copyToDates.length === this.copyFromDates.length && this.validateConsecutiveWeeks(copyToDates)) {
      this.inputToValidation['consecutiveWeeks'] = true;
      return false;
    }
    this.copyToDates = [...copyToDates];
    return true;
  }

  validateMultipleDaysCopyTo(value) {
    const copyToDays = [...value];
    if (this.validateAlreadySelected(value, copyToDays)) {
      this.inputToValidation['alreadySelected'] = true;
      return;
    }
    this.copyToDays = [...value.map((day) => day.from)];
    return true;
  }

  validateAlreadySelected(value, copyDates) {
    let alreadySelected = false;

    if (this.timePeriod === this.WEEK_OPTION) {
      for (let week of copyDates) {
        if (moment(value.from).isSame(week.from, 'day') && moment(value.to).isSame(week.to)) {
          alreadySelected = true;
          break;
        }
      }
    } else {
      for (let day of copyDates) {
        if (moment(this.copyFromDay).isSame(day.from, 'day')) {
          alreadySelected = true;
          break;
        }
      }
    }
    return alreadySelected;
  }

  validateFirstWeekSame(copyDates, field) {
    const copyDatesToCompare = field === 'from' ? [...this.copyToDates] : [...this.copyFromDates];
    if (check.not.array(copyDatesToCompare) || check.emptyArray(copyDatesToCompare)) {
      return false;
    }
    return moment(copyDates[0].from).isSame(copyDatesToCompare[0].from);
  }

  validateConsecutiveWeeks(copyDates) {
    let areDatesConsecutive = false;
    for (let i = 0; i < copyDates.length - 1; i++) {
      const isConsecutive = moment
        .utc(copyDates[i].from)
        .add(7, 'day')
        .isSame(copyDates[i + 1].from, 'day');

      if (!isConsecutive) {
        areDatesConsecutive = true;
        break;
      }
    }
    return areDatesConsecutive;
  }

  async calculateSummary() {
    this.isLoading = true;
    const dateRange = this.getRangeDates();

    if (check.assigned(dateRange)) {
      this.shiftCardSummary = await this.injector
        .get(ShiftPlanCardService)
        .getShiftCards(dateRange.startDate, dateRange.endDate, this.filters, 'user', false);
    }
    this.calculateTotal();
    this.checkValid();
    this.isLoading = false;
  }

  calculateTotal() {
    if (!this.shiftCardSummary) {
      return;
    }
    this.shiftCardSummary.total = this.shiftCardSummary.scheduledPublishedCount;
    if (this.includeOpen) {
      this.shiftCardSummary.total += this.shiftCardSummary.openPublishedCount;
      this.shiftCardSummary.total += this.shiftCardSummary.openDraftCount;
    }
    if (this.includeDrafts) {
      this.shiftCardSummary.total += this.shiftCardSummary.scheduledDraftCount;
    }
    if (this.shiftCardSummary.total === 0) {
      this.hasFromErrors = true;
    }

    if (this.shiftCardSummary.total > 0) {
      this.hasFromErrors = false;
    }
  }

  checkValid() {
    if (this.timePeriod === this.WEEK_OPTION) {
      this.shiftsLimitReached = this.shiftCardSummary?.total * this.copyToDates.length >= this.SHIFTS_LIMIT;
      this.isValid =
        this.shiftCardSummary?.total > 0 &&
        this.copyFromDates.length > 0 &&
        this.copyToDates.length > 0 &&
        !this.hasFromErrors &&
        !this.hasToErrors &&
        !this.shiftsLimitReached;
    }
    if (this.timePeriod === this.DAY_OPTION) {
      this.shiftsLimitReached = this.shiftCardSummary?.total * this.copyToDays.length >= this.SHIFTS_LIMIT;
      this.isValid = this.shiftCardSummary?.total > 0 && check.assigned(this.copyFromDay) && this.copyToDays.length > 0;
    }
  }

  async copyShifts() {
    try {
      this.isCopying = true;
      const commonPayload = { filters: this.filters, includeOpen: this.includeOpen, includeDraft: this.includeDrafts };

      if (check.assigned(this.copyFromDates) && this.copyFromDates.length > 1) {
        const multipleWeeksBody = this.createBodyToCopyShiftsMultipleWeeks();
        await this.injector.get(ShiftPlanCardService).copyShiftsMultipleWeeks({ ...multipleWeeksBody, ...commonPayload });
      } else {
        const copyShiftsBody = this.createBodyToCopyShifts();
        await this.injector
          .get(ShiftPlanCardService)
          .copyShifts({ ...copyShiftsBody, ...commonPayload }, this.timePeriod === this.DAY_OPTION);

        if (this.timePeriod === this.WEEK_OPTION) {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('Copy week shifts', { category: 'Shiftplan', subcategory: 'Actions', subcategory2: 'Copy week' });
        } else {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('Copy days shifts', { category: 'Shiftplan', subcategory: 'Actions', subcategory2: 'Copy day' });
        }
      }
    } catch {
      // do nothing
    } finally {
      this.dialogRef.close({ hasCopied: true, destinationWeek: this.calculateWeekFromDay() });
    }
  }

  calculateWeekFromDay() {
    const from = moment.utc(this.copyToDays?.[0]).startOf('week').toISOString();
    const to = moment.utc(this.copyToDays?.[0]).endOf('week').toISOString();
    let destinationWeek = { from, to };

    if (this.timePeriod === this.WEEK_OPTION) {
      destinationWeek = this.copyToDates[0];
    }
    return destinationWeek;
  }

  private sortByDate(): (a, b) => number {
    return (a, b) => (moment.utc(a.from).isAfter(b.from) ? 1 : -1);
  }

  public closeDialog(): void {
    if (this.isCopying) {
      return;
    }
    this.dialogRef.close();
  }

  createBodyToCopyShifts() {
    let copyToBody: any = {
      date: this.copyFromDay,
      daysToCopy: [...this.copyToDays],
    };
    if (this.timePeriod === this.WEEK_OPTION) {
      copyToBody = {
        startDate: this.copyFromDates[0].from,
        endDate: this.copyFromDates[0].to,
        weeksToCopy: [...this.copyToDates],
      };
    }
    return copyToBody;
  }

  createBodyToCopyShiftsMultipleWeeks() {
    const datesPayload = { weeksFromCopy: [], weeksToCopy: [] };

    for (let i = 0; i < this.copyToDates.length; i++) {
      datesPayload.weeksFromCopy.push(moment.utc(this.copyFromDates[i].from).startOf('day'));
      datesPayload.weeksToCopy.push(moment.utc(this.copyToDates[i].from).startOf('day'));
    }

    return {
      weeksFromCopy: [...datesPayload.weeksFromCopy],
      weeksToCopy: [...datesPayload.weeksToCopy],
    };
  }

  getRangeDates() {
    let startDate, endDate;

    if (this.timePeriod === this.WEEK_OPTION) {
      if (this.copyFromDates.length === 1) {
        startDate = this.copyFromDates[0].from;
        endDate = this.copyFromDates[0].to;
      }

      if (this.copyFromDates.length > 1) {
        startDate = this.copyFromDates[0].from;
        endDate = this.copyFromDates[this.copyFromDates.length - 1].to;
      }
    }

    if (this.timePeriod === this.DAY_OPTION && check.assigned(this.copyFromDay)) {
      startDate = moment.utc(this.copyFromDay).startOf('day').toISOString();
      endDate = moment.utc(this.copyFromDay).endOf('day').toISOString();
    }

    if (check.not.assigned(startDate) || check.not.assigned(endDate)) {
      return null;
    }

    return { startDate, endDate };
  }
}
