import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { SettingsShiftPlanWorkingAreasDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-working-areas/dialogs/add-edit-working-areas-dialog';
import {
  IShiftPlanWorkingAreaSettingsModel,
  ShiftPlanWorkingAreaSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-working-area.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { processShiftplanPermissions } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-settings-shift-plan-working-areas',
  templateUrl: 'settings-shift-plan-working-areas.html',
  styleUrls: ['settings-shift-plan-working-areas.scss'],
})
export class SettingsShiftPlanWorkingAreas implements OnInit {
  dialogTranslation: any = {};
  allWorkingAreas: Array<IShiftPlanWorkingAreaSettingsModel> = [];
  dataLoaded: boolean = false;
  filteredWorkingAreas: Array<IShiftPlanWorkingAreaSettingsModel> = [];
  i18n: any = {};
  mappedWorkingAreasPermissions: { [id: string]: boolean };
  canCreateWorkingAreas: boolean;
  SHIFT_PLAN_WORKING_AREA: string = 'shift-plan-working-area';

  @Input() parentTranslation: any = {};
  @Input() myProfileId: string;

  constructor(
    private injector: Injector,
    public snackBar: MatLegacySnackBar,
    private dialog: MatLegacyDialog,
    private i18nDataPipe: I18nDataPipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData() {
    try {
      const [i18nPage, dialogTranslation] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-working-areas'),
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-working-area-dialog'),
        this.findAllWorkingAreas(),
      ]);
      this.i18n.page = i18nPage;
      this.dialogTranslation = dialogTranslation;
      const workingAreaPermission = await this.injector
        .get(ShiftPlanPermissionsService)
        .getShiftplPermissionsByCollection('shift-plan-working-area');
      this.canCreateWorkingAreas = workingAreaPermission?.create_all ?? false;
      this.mappedWorkingAreasPermissions = processShiftplanPermissions(this.allWorkingAreas, workingAreaPermission, this.myProfileId);
    } catch {
      this.i18n.page = {};
      this.dialogTranslation = {};
    }
  }

  private async findAllWorkingAreas(): Promise<void> {
    try {
      const allWorkingAreas = await this.injector.get(ShiftPlanWorkingAreaSettingsService).getWorkingAreas();
      this.allWorkingAreas = allWorkingAreas;
      this.filteredWorkingAreas = allWorkingAreas;
      this.dataLoaded = true;
    } catch {
      this.allWorkingAreas = [];
      this.dataLoaded = false;
    }
  }

  public addWorkingArea(): void {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const workingAreaDocument: GenericCacheModel = new GenericCacheModel(
      this.injector,
      { name: '' },
      ShiftPlanWorkingAreaSettingsService,
      loggedUser[fieldConstants.ID]
    );
    const data = {
      workingArea: workingAreaDocument,
      isNewWorkingArea: true,
      dialogTranslation: this.dialogTranslation,
    };

    const dialogRef = this.dialog.open(SettingsShiftPlanWorkingAreasDialog, { data });
    dialogRef.afterClosed().subscribe(async (newWorkingArea: any) => {
      try {
        if (check.not.assigned(newWorkingArea)) {
          return;
        }
        await this.injector.get(ShiftPlanWorkingAreaSettingsService).create(newWorkingArea.workingAreaData);
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('add working area', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Company setup' });
        this.findAllWorkingAreas();
      } catch {
        this.findAllWorkingAreas();
      }
    });
  }

  public editWorkingArea(workingArea: IShiftPlanWorkingAreaSettingsModel): void {
    const disabled = this.mappedWorkingAreasPermissions[workingArea._id];
    if (disabled) {
      return;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const workingAreaDocument: GenericCacheModel = new GenericCacheModel(
      this.injector,
      { _id: workingArea[fieldConstants.ID], name: workingArea.name },
      ShiftPlanWorkingAreaSettingsService,
      loggedUser[fieldConstants.ID]
    );
    const data = {
      workingArea: workingAreaDocument,
      isNewWorkingArea: false,
      dialogTranslation: this.dialogTranslation,
    };

    const dialogRef = this.dialog.open(SettingsShiftPlanWorkingAreasDialog, { data });
    dialogRef.afterClosed().subscribe(async (editWorkingArea: any) => {
      try {
        if (check.not.assigned(editWorkingArea)) {
          return;
        }
        await this.injector
          .get(ShiftPlanWorkingAreaSettingsService)
          .updateById(editWorkingArea.workingAreaData[fieldConstants.ID], editWorkingArea.workingAreaData);
        this.findAllWorkingAreas();
      } catch {
        this.findAllWorkingAreas();
      }
    });
  }

  private deleteWorkingArea(translationData: any, workingAreaId: string): void {
    const confirmDeleteTransformed = this.i18nDataPipe.transform(this.dialogTranslation.confirmDeletionTitle, translationData);
    const data = {
      titleText: confirmDeleteTransformed,
      subtitleText: this.dialogTranslation.confirmDeletionSubtitle,
      confirmButtonText: this.dialogTranslation.confirmDeleteButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.dialogTranslation.goBackButtonDialog,
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm) => {
      try {
        if (confirm && confirm === true) {
          await this.injector.get(ShiftPlanWorkingAreaSettingsService).deleteById(workingAreaId);
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('delete working area', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Company setup' });
          const snackbarTransformed = this.i18nDataPipe.transform(this.dialogTranslation.snackbarSuccessDeleted, translationData);
          this.snackBar.open(snackbarTransformed, 'OK', { duration: 5000 });
          this.findAllWorkingAreas();
        }
      } catch {
        this.findAllWorkingAreas();
      }
    });
  }

  public async validateIfWorkingAreaIsAttached(workingAreaDocument: IShiftPlanWorkingAreaSettingsModel): Promise<void> {
    const disabled = this.mappedWorkingAreasPermissions[workingAreaDocument._id];
    if (disabled) {
      return;
    }

    const translationData = {
      name: workingAreaDocument.name,
    };

    const workingAreaId = workingAreaDocument[fieldConstants.ID];
    const canDeleteResponse = await this.injector.get(ShiftPlanWorkingAreaSettingsService).canDeleteWorkingArea(workingAreaId);

    if (check.not.assigned(canDeleteResponse) || check.emptyObject(canDeleteResponse) || check.not.assigned(canDeleteResponse.canDelete)) {
      return;
    }
    const { canDelete } = canDeleteResponse;

    if (canDelete === true) {
      this.deleteWorkingArea(translationData, workingAreaId);
      return;
    }

    if (check.not.assigned(canDeleteResponse.hasAssociatedKey)) {
      return;
    }

    const titleErrorMessage = this.getCanDeleteErrorMessage(canDeleteResponse.hasAssociatedKey);
    const data = {
      titleText: this.i18nDataPipe.transform(titleErrorMessage, translationData),
      confirmButtonText: this.dialogTranslation.gotItButton,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    await dialogRef.afterClosed().toPromise();
  }

  public setFilteredWorkingAreasToShow(recordsToShow: Array<IShiftPlanWorkingAreaSettingsModel>): void {
    this.filteredWorkingAreas = recordsToShow;
    this.cdr.detectChanges();
  }

  public getCanDeleteErrorMessage(key: string) {
    if (key === 'employees') {
      return this.dialogTranslation.attachedEmployeeMessage;
    }
    if (key === 'shift-cards') {
      return this.dialogTranslation.attachedShiftMessage;
    }
    if (key === 'shift-templates') {
      return this.dialogTranslation.attachedTemplateMessage;
    }
  }
}
