import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { IMenuOption } from '@app/standard/pages/generic.page';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import * as check from 'check-types';

@Injectable()
export class SmartDocsBarService {
  private profilePermissionsResources: Array<string> = ['document', 'e-signature'];

  constructor(private router: Router, private injector: Injector) {}

  async getOptions(pageTranslation): Promise<Array<IMenuOption>> {
    try {
      const options: Array<IMenuOption> = [{ name: pageTranslation.myDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/my-docs') }];

      const profilePermissions = await this.injector.get(PrivateSecurityService).getPermissionsForCollections(this.profilePermissionsResources);

      if (profilePermissions['document'] && (profilePermissions['document'].read_all || profilePermissions['document'].read_own || check.nonEmptyArray(profilePermissions['document'].read_custom))) {
        options.push({ name: pageTranslation.companyDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/company-docs') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('documents/employee-docs') === true) {
        options.push({ name: pageTranslation.employeeDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/employee-docs') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('documents/digital-signature') === true) {
        options.push({ name: pageTranslation.digitalSignatureTab, onClick: () => this.router.navigateByUrl('/cloud/documents/digital-signature') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('documents/templates') === true) {
        options.push({ name: pageTranslation.templatesTab, onClick: () => this.router.navigateByUrl('/cloud/documents/templates') });
      }

      if (this.injector.get(CloudRoutesService).checkRoute('documents/import') === true) {
        options.push({ name: pageTranslation.importTab, onClick: () => this.router.navigateByUrl('/cloud/documents/import') });
      }

      return options;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, SmartDocsBarService.name, 'getOptions');
    }
  }
}
