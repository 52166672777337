import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { CustomFieldService } from '@app/standard/services/custom-field/custom-field.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';

import { I18nDataPipe } from '../../../components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '../../../core/generic-cache-model';
import { ISelectOption } from '../../../core/select-option';
import { InputValidation } from '../../../core/validation/input-validation';
import { CompanyService } from '../../../services/company/company.service';
import { StandardServicesRegistry } from '../../../services/standard-services.registry';

@Component({
  selector: 'orgos-edit-email-template',
  templateUrl: 'edit-email-template.page.html',
  styleUrls: ['edit-email-template.page.scss'],
})
export class EditEmailTemplatePage implements OnInit, OnDestroy {
  listCollections: Array<any> = [
    {
      serviceName: 'Document',
      translationName: 'document-collection',
      collectionName: 'document',
    },
    {
      serviceName: 'User',
      translationName: 'user-collection',
      collectionName: 'user',
    },
    {
      serviceName: 'TimeOffRequest',
      translationName: 'time-off-request-collection',
      collectionName: 'time-off-request',
    },
    {
      serviceName: 'Task',
      translationName: 'task-collection',
      collectionName: 'task',
    },
    {
      serviceName: 'Feed',
      translationName: 'feed-collection',
      collectionName: 'feed',
    },
    {
      serviceName: 'Position',
      translationName: 'position-collection',
      collectionName: 'position',
    },
    {
      serviceName: 'PositionCandidate',
      translationName: 'position-candidate-collection',
      collectionName: 'position-candidate',
    },
    {
      serviceName: 'Candidate',
      translationName: 'candidate-collection',
      collectionName: 'candidate',
    },
    {
      serviceName: 'Project',
      translationName: 'project-collection',
      collectionName: 'project',
    },
    {
      serviceName: 'ProjectMember',
      translationName: 'project-member-collection',
      collectionName: 'project-member',
    },
    {
      serviceName: 'ProjectTimeEntry',
      translationName: 'project-time-entry-collection',
      collectionName: 'project-time-entry',
    },
  ];
  collectionsOptions: Array<ISelectOption>;
  fieldsOptions: Array<ISelectOption>;
  companyOptions: Array<ISelectOption>;
  pageTranslation: any = {};
  nameValidation: InputValidation;
  subjectValidation: InputValidation;
  bodyValidation: InputValidation;
  collectionValidation: InputValidation;
  categoryValidation: InputValidation;
  fieldChosen: GenericCacheModel;
  customFieldsAvailable: Array<any>;
  editWorkflowTranslation: any = {};
  myUserSelectOptions: Array<ISelectOption> = [];

  @Input() emailTemplate: GenericCacheModel;
  @Output() goBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  private backButtonSubscription: Subscription;

  constructor(
    private location: Location,
    private injector: Injector,
    private standardServicesRegistry: StandardServicesRegistry,
    public snackBar: MatLegacySnackBar,
    @Inject(DOCUMENT) private document: any
  ) {
    // When the user clicks on the back button of the Browser:
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.onBackClick(false, false);
      }
    }) as Subscription;
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    this.injector.get(GlobalBarService).setEnableDefaultBars(false, true);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-email-template-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-workflow-page')
      .then((editWorkflowTranslation) => {
        this.editWorkflowTranslation = editWorkflowTranslation;

        this.collectionsOptions = [];
        this.fieldsOptions = [];
        this.listCollections.forEach((iCollection) => {
          if (iCollection.collectionName !== 'candidate') {
            this.collectionsOptions.push({
              name: editWorkflowTranslation[`${iCollection.collectionName}Collection`],
              value: iCollection.collectionName,
            });
          }
        });

        // Special case of email template for recurrent workflows, where merge fields are not available
        this.collectionsOptions.push({
          name: editWorkflowTranslation[`recurrentCollection`],
          value: 'recurrent',
        });

        this.initMyUserSelectOptions();
      })
      .catch(() => {
        this.editWorkflowTranslation = {};
      });

    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((companies: Array<any>) => {
        this.companyOptions = companies.map((company: any) => {
          const companyOption = {
            name: company.name,
            value: company._id,
          };
          return companyOption;
        });
      })
      .catch(() => {
        // An error is already shown
        this.companyOptions = [];
      });

    this.initEmailTemplate();
    this.findModels();
    this.initFieldChosen();
  }

  private initMyUserSelectOptions(): void {
    this.myUserSelectOptions = [];

    this.myUserSelectOptions.push({ name: this.editWorkflowTranslation.myUserDisplayName, value: 'runningUser.userPersonal.displayName' });
    this.myUserSelectOptions.push({ name: this.editWorkflowTranslation.myUserEmail, value: 'runningUser.userAccount.email' });
    this.myUserSelectOptions.push({ name: this.editWorkflowTranslation.myUserJobTitle, value: 'runningUser.userWork.jobTitle' });
    this.myUserSelectOptions.push({ name: this.editWorkflowTranslation.myUserEmailSignature, value: 'runningUser.emailSignature' });
    this.myUserSelectOptions.push({ name: this.editWorkflowTranslation.myUserWorkPhone, value: 'runningUser.userWork.workPhone' });
    this.myUserSelectOptions.push({ name: this.editWorkflowTranslation.myUserWorkMobile, value: 'runningUser.userWork.workMobile' });
  }

  private addRelatedUserSelectOptions(): void {
    if (check.not.assigned(this.fieldsOptions) || check.not.array(this.fieldsOptions)) {
      return;
    }

    if (
      check.assigned(this.emailTemplate) &&
      this.emailTemplate.data &&
      (this.emailTemplate.data.inCollection === 'time-off-request' ||
        this.emailTemplate.data.inCollection === 'project' ||
        this.emailTemplate.data.inCollection === 'project-member' ||
        this.emailTemplate.data.inCollection === 'project-time-entry')
    ) {
      const listAddedValues = this.fieldsOptions
        .filter((iFieldOption) => {
          return iFieldOption.value.startsWith('relatedUser.');
        })
        .map((iFieldOption) => {
          return iFieldOption.value;
        });
      if (check.nonEmptyArray(listAddedValues)) {
        return;
      }
      this.fieldsOptions.push({ name: this.editWorkflowTranslation.relatedUserFirstName, value: 'relatedUser.firstName' });
      this.fieldsOptions.push({ name: this.editWorkflowTranslation.relatedUserLastName, value: 'relatedUser.lastName' });
      this.fieldsOptions.push({ name: this.editWorkflowTranslation.relatedUserDisplayName, value: 'relatedUser.displayName' });
    } else {
      this.fieldsOptions = this.fieldsOptions.filter((iFieldOption) => {
        return iFieldOption.value.startsWith('relatedUser.') === false;
      });
    }
  }

  private addRelatedProjectSelectOptions(): void {
    if (check.not.assigned(this.fieldsOptions) || check.not.array(this.fieldsOptions)) {
      return;
    }

    if (
      check.assigned(this.emailTemplate) &&
      check.assigned(this.emailTemplate.data) &&
      (this.emailTemplate.data.inCollection === 'project-member' || this.emailTemplate.data.inCollection === 'project-time-entry')
    ) {
      const listAddedValues = this.fieldsOptions
        .filter((iFieldOption) => {
          return iFieldOption.value.startsWith('relatedProject.');
        })
        .map((iFieldOption) => {
          return iFieldOption.value;
        });
      if (check.nonEmptyArray(listAddedValues)) {
        return;
      }
      this.fieldsOptions.push({ name: this.editWorkflowTranslation.relatedProjectTitle, value: 'relatedProject.title' });
    } else {
      this.fieldsOptions = this.fieldsOptions.filter((iFieldOption) => {
        return iFieldOption.value.startsWith('relatedProject.') === false;
      });
    }
  }

  private findModels(): void {
    const modelCalls = this.listCollections.map((iCollection) => {
      const iCollectionServiceClass = this.standardServicesRegistry.getService(iCollection.serviceName);
      // tslint:disable-next-line: deprecation
      return this.injector.get(iCollectionServiceClass).getModel();
    });
    Promise.all(modelCalls)
      .then((models) => {
        models.forEach((iModelResult, index) => {
          this.listCollections[index]['modelValues'] = iModelResult;
        });
        this.findTranslations();
      })
      .catch(() => {});
  }

  private findTranslations(): void {
    const translationCalls = this.listCollections.map((iCollection) => {
      return this.injector.get(InternationalizationService).getAllTranslation(iCollection.translationName);
    });

    Promise.all(translationCalls)
      .then((translations) => {
        translations.forEach((iTranslationResult, index) => {
          this.listCollections[index]['translationValues'] = iTranslationResult;
        });

        return this.injector.get(CustomFieldService).find({ _id: { $ne: null } });
      })
      .then((customFields) => {
        if (check.not.assigned(customFields) || check.not.array(customFields)) {
          this.customFieldsAvailable = [];
        }
        this.customFieldsAvailable = customFields;
        this.selectCollection();
      })
      .catch(() => {});
  }

  private initEmailTemplate(): void {
    const emailTemplateServiceClass = this.standardServicesRegistry.getService('EmailTemplate');
    if (check.assigned(this.emailTemplate) && check.nonEmptyObject(this.emailTemplate)) {
      this.emailTemplate = new GenericCacheModel(this.injector, this.emailTemplate, emailTemplateServiceClass);
    } else {
      const rawEmailTemplateData = {
        name: undefined,
        inCollection: undefined,
        subject: undefined,
        body: undefined,
        bodyType: 'rawText',
      };
      this.emailTemplate = new GenericCacheModel(this.injector, rawEmailTemplateData, emailTemplateServiceClass);
    }

    if (
      this.emailTemplate.data.bodyType === 'rawText' &&
      (!this.emailTemplate.data.bodyQuill || check.emptyString(this.emailTemplate.data.bodyQuill.html))
    ) {
      this.emailTemplate.data.bodyQuill = this.convertToQuill(this.emailTemplate.data.body);
    }

    if (check.not.assigned(this.emailTemplate.data.documents) || check.not.array(this.emailTemplate.data.documents)) {
      this.emailTemplate.data.documents = [];
    }
  }

  private initFieldChosen(): void {
    const emailTemplateServiceClass = this.standardServicesRegistry.getService('EmailTemplate');
    this.fieldChosen = new GenericCacheModel(this.injector, { field: undefined, fieldValue: undefined }, emailTemplateServiceClass);
  }

  selectCollection(): void {
    this.updateFieldsOptions(this.emailTemplate['data']['inCollection']);
    this.addRelatedUserSelectOptions();
    this.addRelatedProjectSelectOptions();
  }

  selectFieldValue(): void {
    this.fieldChosen['data']['fieldValue'] = '';
    const chosenField = this.fieldChosen['data']['field'];
    if (check.not.assigned(chosenField) || check.not.string(chosenField) || check.emptyString(chosenField)) {
      return;
    }
    this.fieldChosen['data']['fieldValue'] = check.contains(chosenField, '{!') ? chosenField : `{!${chosenField}}`;

    this.copyFieldToClipboard();
  }

  public copyFieldToClipboard(): void {
    const chosenField = this.fieldChosen['data']['field'];
    if (check.not.assigned(chosenField) || check.not.string(chosenField) || check.emptyString(chosenField)) {
      return;
    }

    const valueToCopy = this.fieldChosen['data']['fieldValue'];

    const el = document.createElement('textarea');
    el.value = valueToCopy;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    const fieldLabel = this.fieldsOptions.find((iField) => {
      return iField.value === chosenField;
    }).name;
    const data = {
      fieldLabel: fieldLabel,
    };
    const snackMessage = this.injector.get(I18nDataPipe).transform(this.pageTranslation.fieldCopiedToClipboard, data);
    this.snackBar.open(`${snackMessage}`, 'OK', { duration: 5000 });
  }

  public changeEntryType(newEntryType: string): void {
    if (newEntryType === 'htmlText') {
      this.emailTemplate.data.body = this.convertQuillToHtml(this.emailTemplate.data.bodyQuill);
    } else if (newEntryType === 'rawText') {
      this.emailTemplate.data.bodyQuill = this.convertToQuill(this.emailTemplate.data.body);
    }
    this.emailTemplate.data.bodyType = newEntryType;
  }

  private convertQuillToHtml(quillText: any): string {
    if (check.assigned(this.emailTemplate.data.bodyQuill)) {
      return this.emailTemplate.data.bodyQuill.html;
    }
    return this.emailTemplate.data.body;
  }

  private convertToQuill(textInHtml: string): any {
    const result = {
      // tslint:disable-next-line: strict-boolean-expressions
      html: !textInHtml ? '' : textInHtml,
      delta: undefined,
    };
    return result;
  }

  private updateFieldsOptions(selectedCollection: string): void {
    this.fieldsOptions = [];
    if (check.not.assigned(selectedCollection) || check.not.string(selectedCollection) || check.emptyString(selectedCollection)) {
      return;
    }
    const collectionInfo = this.listCollections.find((iCollection) => {
      return iCollection.collectionName === selectedCollection;
    });

    if (check.not.assigned(collectionInfo) || check.emptyObject(collectionInfo)) {
      return;
    }
    collectionInfo['modelValues'].forEach((iFieldModel) => {
      const fieldApiName = iFieldModel['name'];
      if (
        check.assigned(collectionInfo['translationValues'][fieldApiName]) &&
        check.nonEmptyString(collectionInfo['translationValues'][fieldApiName]) &&
        iFieldModel.name !== 'mentioned'
      ) {
        this.fieldsOptions.push({
          name: collectionInfo['translationValues'][fieldApiName] ? collectionInfo['translationValues'][fieldApiName] : fieldApiName,
          value: fieldApiName,
        });
      } else if (check.contains(fieldApiName, 'c_')) {
        this.fieldsOptions.push({
          name: this.findCustomFieldTranslation(fieldApiName),
          value: fieldApiName,
        });
      }
    });

    // In the specific case of position-candidate, add the model of the related position, candidate and stage:
    if (selectedCollection === 'position-candidate') {
      this.addSubModelsOfPositionCandidate();
    } else if (selectedCollection === 'project') {
      const labelTranslation = this.pageTranslation.projectDetailUrl;
      const urlPage = 'project/project-list/{!_id}';
      this.addLinkToPageFieldOption(labelTranslation, urlPage);
    } else if (selectedCollection === 'project-member') {
      const labelTranslation = this.pageTranslation.projectDetailUrl;
      const urlPage = 'project/project-list/{!_projectId}';
      this.addLinkToPageFieldOption(labelTranslation, urlPage);
    }
    this.fieldsOptions = this.fieldsOptions.concat(this.myUserSelectOptions);
  }

  private addSubModelsOfPositionCandidate(): void {
    // Remove the standard _positionId, _candidateId and stageId from the list of options
    this.fieldsOptions = this.fieldsOptions.filter((iFieldOption) => {
      return iFieldOption.value !== '_positionId' && iFieldOption.value !== '_candidateId' && iFieldOption.value !== 'stageId';
    });

    const positionLabel = this.editWorkflowTranslation['positionCollection'];
    const positionInfo = this.listCollections.find((iCollection) => {
      return iCollection.collectionName === 'position';
    });
    const positionOptions = positionInfo['modelValues']
      .filter((iFieldModel) => {
        const positionFields = ['jobTitle', 'jobDescription', 'positionType'];
        return iFieldModel['name'] && (check.contains(positionFields, iFieldModel['name']) || iFieldModel['name'].startsWith('c_'));
      })
      .map((iFieldModel) => {
        const fieldApiName = iFieldModel['name'];
        if (
          check.assigned(positionInfo['translationValues'][fieldApiName]) &&
          check.nonEmptyString(positionInfo['translationValues'][fieldApiName])
        ) {
          return {
            name: `${positionLabel} - ${positionInfo['translationValues'][fieldApiName]}`,
            value: `position.${iFieldModel['name']}`,
          };
        } else if (check.contains(fieldApiName, 'c_')) {
          const customFieldApiName = this.findCustomFieldTranslation(fieldApiName);
          return {
            name: `${positionLabel} - ${customFieldApiName}`,
            value: `position.${fieldApiName}`,
          };
        }
      });
    this.fieldsOptions = this.fieldsOptions.concat(positionOptions);

    const candidateLabel = this.editWorkflowTranslation['candidateCollection'];
    const candidateInfo = this.listCollections.find((iCollection) => {
      return iCollection.collectionName === 'candidate';
    });
    const candidateOptions: Array<ISelectOption> = candidateInfo['modelValues']
      .filter((iFieldModel) => {
        const candidateFields = ['firstName', 'lastName', 'email', 'phone'];
        return iFieldModel['name'] && (check.contains(candidateFields, iFieldModel['name']) || iFieldModel['name'].startsWith('c_'));
      })
      .map((iFieldModel) => {
        const fieldApiName = iFieldModel['name'];
        if (
          check.assigned(candidateInfo['translationValues'][fieldApiName]) &&
          check.nonEmptyString(candidateInfo['translationValues'][fieldApiName])
        ) {
          return {
            name: `${candidateLabel} - ${candidateInfo['translationValues'][fieldApiName]}`,
            value: `candidate.${iFieldModel['name']}`,
          };
        } else if (check.contains(fieldApiName, 'c_')) {
          const customFieldApiName = this.findCustomFieldTranslation(fieldApiName);
          return {
            name: `${candidateLabel} - ${customFieldApiName}`,
            value: `candidate.${fieldApiName}`,
          };
        }
      });
    this.fieldsOptions = this.fieldsOptions.concat(candidateOptions);

    const stageLabel = this.editWorkflowTranslation['hiringStageCollection'];
    this.fieldsOptions.push({
      name: stageLabel,
      value: 'stage.name',
    });

    const urlLabel = this.pageTranslation.positionCandidateUrl;
    this.fieldsOptions.push({
      name: urlLabel,
      value: `${this.document.location.origin}/cloud/recruiting/candidate/{!_candidateId}/{!_positionId}`,
    });
  }

  private addLinkToPageFieldOption(labelTranslation: string, urlPage: string): void {
    this.fieldsOptions.push({
      name: labelTranslation,
      value: `${this.document.location.origin}/cloud/${urlPage}`,
    });
  }

  private findCustomFieldTranslation(fieldApiName): string {
    if (check.not.assigned(fieldApiName) || check.not.string(fieldApiName) || check.emptyString(fieldApiName)) {
      return fieldApiName;
    }

    if (
      check.not.assigned(this.customFieldsAvailable) ||
      check.not.array(this.customFieldsAvailable) ||
      check.emptyArray(this.customFieldsAvailable)
    ) {
      return fieldApiName;
    }

    if (check.not.contains(fieldApiName, '.')) {
      const candidate = this.customFieldsAvailable.find((iCustomField) => {
        return iCustomField.fieldApiName === fieldApiName;
      });
      if (check.assigned(candidate) && check.assigned(candidate.fieldLabel)) {
        return candidate.fieldLabel;
      }
    } else if (check.contains(fieldApiName, '.')) {
      const field = fieldApiName.split('.')[1];

      const candidate = this.customFieldsAvailable.find((iCustomField) => {
        return iCustomField.fieldApiName === field;
      });
      if (check.assigned(candidate) && check.assigned(candidate.fieldLabel)) {
        return candidate.fieldLabel;
      }
    }

    return fieldApiName;
  }

  onBackClick(changeLocationState: boolean = true, refreshParentPage: boolean = false): void {
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, changeLocationState);
    this.goBack.emit(refreshParentPage);
  }

  saveTemplate(): void {
    if (
      this.emailTemplate.data.bodyType === 'rawText' &&
      (!this.emailTemplate.data.bodyQuill || !this.emailTemplate.data.bodyQuill.html || this.emailTemplate.data.bodyQuill.html === '')
    ) {
      return;
    }
    if (this.emailTemplate.data.bodyType !== 'rawText') {
      delete this.emailTemplate.data.bodyQuill;
    }

    if (
      check.assigned(this.nameValidation) &&
      this.nameValidation.isValid() === true &&
      check.assigned(this.subjectValidation) &&
      this.subjectValidation.isValid() === true &&
      check.assigned(this.bodyValidation) &&
      this.bodyValidation.isValid() === true &&
      check.assigned(this.collectionValidation) &&
      this.collectionValidation.isValid() === true &&
      check.assigned(this.categoryValidation) &&
      this.categoryValidation.isValid() === true
    ) {
      if (check.assigned(this.emailTemplate['data']['_id']) && check.nonEmptyString(this.emailTemplate['data']['_id'])) {
        this.emailTemplate.updateInServer().then(() => {
          this.snackBar.open(`${this.pageTranslation.updatedSnackbarMessage}`, 'OK', { duration: 5000 });
          this.onBackClick(true, true);
        });
      } else {
        this.emailTemplate.createInServer().then(() => {
          this.snackBar.open(`${this.pageTranslation.createdSnackbarMessage}`, 'OK', { duration: 5000 });
          this.onBackClick(true, true);
        });
      }
    }
  }

  public addAttachment(): void {
    this.injector
      .get(UppyHelperService)
      .uploadGenericDocument()
      .then((docToUpload: IFileMetadata | null) => {
        if (check.not.assigned(docToUpload) || check.emptyObject(docToUpload)) {
          return;
        }

        this.emailTemplate.data.documents.push(docToUpload);
      })
      .catch(() => {
        // do anything
      });
  }

  public deleteAttachment(attachment: IFileMetadata): void {
    _.remove(this.emailTemplate.data.documents, (iAttachment: IFileMetadata) => {
      return check.equal(attachment._fileNameInS3, iAttachment._fileNameInS3);
    });
  }

  public downloadAttachment(attachment: IFileMetadata): void {
    this.injector
      .get(HttpClient)
      .get(attachment._url, { responseType: 'arraybuffer' })
      .subscribe((arrayBuffer) => {
        FileSaver.saveAs(new Blob([arrayBuffer]), attachment._fileName);
      });
  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();
  }
}
