import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import * as check from 'check-types';

import { AuthenticationService } from '../../services/core/authentication.service';
import { InternationalizationService } from '../../services/core/internationalization.service';

@Component({
  selector: 'orgos-comment-editor',
  templateUrl: 'comment-editor.component.html',
  styleUrls: ['comment-editor.component.scss']
})
export class CommentEditorComponent {
  i18n: any = {};
  isEmpty: boolean = true;
  showButton: boolean = false;
  modules: any = {
    toolbar: false
  };
  style: any = {
    'font-family': 'Nunito, sans-serif'
  };
  loggedUser: any = {};
  private editor: any;
  private originalContent: IEditorContent;
  private _editorContent: IEditorContent;

  @Input()
  set editorContent(editorContent: IEditorContent) {
    this._editorContent = editorContent;
    this.originalContent = { ...editorContent };
    this.displayContent();
  }
  get editorContent(): IEditorContent {
    return this._editorContent;
  }

  @Input() commentOwnerId: string;
  @Output() updateComment: EventEmitter<any> = new EventEmitter<any>();

  constructor(private injector: Injector) {}

  onEditorCreated(editor: any): void {
    this.editor = editor;
    this.displayContent();
  }

  private displayContent(): void {
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-activity-widget')
      .then((i18n) => {
        this.i18n = i18n;
        if (check.not.assigned(this.editor)) {
          return;
        }

        if (check.not.assigned(this.editorContent)) {
          return;
        }

        if (check.assigned(this.editorContent.delta)) {
          this.editor.setContents(this.editorContent.delta);
          this.isEmpty = this.editor.getText() === '<p><br></p>';
        } else if (check.assigned(this.editorContent.html)) {
          this.editor.clipboard.dangerouslyPasteHTML(this.editorContent.html);
          this.isEmpty = this.editor.getText() === '<p><br></p>';
        } else {
          this.editor.setContents(null);
          this.isEmpty = true;
        }
      })
      .catch(() => {
        this.i18n = {};
      });
  }

  onContentChanged(content: any): void {
    if (check.not.assigned(content.html)) {
      return;
    }
    this.editorContent.html = content.html;
    this.editorContent.delta = content.editor.editor.delta;
  }

  saveComment() {
    this.updateComment.emit(this.editorContent);
  }

  cancelUpdate() {
    this.editor.setContents(this.originalContent.delta);
    this.showButton = false;
  }

  showEditButtons() {
    this.showButton = true;
  }
}

export interface IEditorContent {
  delta?: any;
  html?: any;
}
