<div class="dialog-box kenjo-m-40px">
  <orgos-column-container>
    <orgos-column size="1" class="kenjo-pr-20px kenjo-font-size-20px">
      {{pageTranslation.addCategoryDialogTitle}}
    </orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <div class="kenjo-pt-40px">
    <div class="kenjo-pb-10px kenjo-font-color-archived-8f8f8f">{{pageTranslation.addCategoryDialogSubtitle}}</div>
    <kenjo-select-simple-dropdown-custom *ngIf="pageTranslation.label" [defaultOption]="chosenCategory" [translations]="pageTranslation" [width]="'400px'" [componentKey]="'category-item'" [options]="listCategories" (selectOption)="onSelectOption($event)" (cleanOption)="removeCategory()"></kenjo-select-simple-dropdown-custom>
  </div>

  <div class="kenjo-mb-20px kenjo-pt-20px button-container kenjo-text-align-right">
    <orgos-button-raised (click)="closeDialog()" color="Neutral" class="kenjo-pr-20px">{{miscTranslations.cancelButton}}</orgos-button-raised>
    <orgos-button-raised (click)="saveData()" color="Success">{{miscTranslations.saveButton}}</orgos-button-raised>
  </div>
</div>
