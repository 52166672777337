import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { TimeOffUserPersonalModule } from '@app/common-components/time-off-user-personal/time-off-user-personal.module';
import { TimeOffUserPage } from '@app/common-components/time-off-user/component/time-off-user.page';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';

@NgModule({
  declarations: [TimeOffUserPage],
  imports: [CommonModule, IconModule, TruncateTextModule, MaterialModule, StandardComponentsModule, TimeOffUserPersonalModule],
  providers: [],
  exports: [TimeOffUserPage]
})
export class TimeOffUserModule {}
