import { Component, Input, OnInit } from '@angular/core';

import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { GenericSimpleModel } from '../../core/generic-simple-model';
import { ErrorManagerService } from '../../services/error/error-manager.service';

@Component({
  selector: 'orgos-output',
  template: '{{getValue()}}',
  styleUrls: ['output.component.scss']
})
export class OutputComponent implements OnInit {
  @Input() model: GenericSimpleModel;
  @Input() value: any;
  @Input() field: string;

  constructor(private errorManager: ErrorManagerService) {}

  ngOnInit(): void {
    if (!this.model || !this.field) {
      const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, OutputComponent.name, 'ngOnInit');
      error.message = `Outputs must be initialized with model and field. Model: ${this.model}. Field: ${this.field}.`;
      this.errorManager.handleParsedErrorSilently(error);
      return;
    }
  }

  getValue(): any {
    if (this.value) {
      return this.value[this.field];
    }

    return this.model.data[this.field];
  }
}
