<ng-container *ngIf="!isFinalStatus">
  <kenjo-chip
    *ngIf="chipText"
    type="secondary"
    size="s"
    [semantic]="chipSemantic"
    [text]="chipText"
    [matTooltip]="tooltipText"
  ></kenjo-chip>
</ng-container>
