<orgos-widget titleKey="jobOpening" [loadingWidget]="loadingWidget" [existingData]="jobOpenings && jobOpenings.length > 0">
  <orgos-widget-content>
    <div *ngFor="let jobOpening of jobOpenings" size="0" class="kenjo-mb-30px kenjo-cursor-pointer" (click)="navigateToAJobPositionPage(jobOpening.position._id)">
      <div *ngIf="jobOpening?.position?.jobTitle" class="kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-text-transform-uppercase">
        <kenjo-truncate-text>{{ jobOpening.position.jobTitle }}</kenjo-truncate-text>
      </div>
      <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
        <orgos-column size="0" class="kenjo-mr-5px">
          <kenjo-icon [size]="20" class="kenjo-font-color-light-text-757575">person</kenjo-icon>
        </orgos-column>
        <orgos-column size="0" class="kenjo-mr-30px kenjo-font-size-12px kenjo-font-color-light-text-757575">
          {{ jobOpening.totalCandidates }}
        </orgos-column>
        <orgos-column size="0" class="kenjo-mr-5px">
          <kenjo-icon [size]="20" class="kenjo-font-color-success-00b72e">person_add</kenjo-icon>
        </orgos-column>
        <orgos-column size="0" class="kenjo-mr-30px kenjo-font-size-12px kenjo-font-color-light-text-757575">
          {{ jobOpening.recentCandidates }}
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-text-align-right">
          <ng-container *ngIf="jobOpening.lastCandidateAddedOn"> {{ translation.lastAddedLabel }} {{ jobOpening.lastCandidateAddedOn | humanDate: false:'short' }} </ng-container>
          <ng-container *ngIf="!jobOpening.lastCandidateAddedOn"> - </ng-container>
        </orgos-column>
      </orgos-column-container>
    </div>
  </orgos-widget-content>
</orgos-widget>
