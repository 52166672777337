import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as check from 'check-types';

import { StandardServicesRegistry } from '../../services/standard-services.registry';
import { I18nDataPipe } from '../i18n-data/i18n-data.pipe';
import { FeedCommentsDialog } from './dialogs/feed-comments.dialog';

@Component({
  selector: 'orgos-feed',
  templateUrl: 'feed.component.html',
  styleUrls: ['feed.component.scss'],
})
export class FeedComponent implements OnInit {
  @Input() feed: any;
  @Input() displayName: string;
  @Input() photoUrl: string;
  @Input() mapUserIdToUserPersonal: any; // to display the people in the comments
  @Input() companyData: any;

  @Output() changeFeed: EventEmitter<string> = new EventEmitter<string>();

  isRead: boolean = true;
  feedTitle: string;
  questionTitle: string;

  pageTranslation: any = {};
  surveyTranslation: any = {};
  pulseInsightsTranslation: any = {};
  isReplyOpened: boolean = false;

  constructor(
    private injector: Injector,
    private standardServicesRegistry: StandardServicesRegistry,
    public dialog: MatLegacyDialog,
    public snackBar: MatLegacySnackBar
  ) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('feed-component')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('survey-page')
      .then((surveyTranslation) => {
        this.surveyTranslation = surveyTranslation;
        return this.injector.get(internationalizationServiceClass).getAllTranslation('pulse-insights-page');
      })
      .then((pulseInsightsTranslation) => {
        this.pulseInsightsTranslation = pulseInsightsTranslation;
        this.fetchData();
      })
      .catch(() => {
        this.surveyTranslation = {};
      });
  }

  private fetchData(): void {
    this.checkIfRead();
    this.initTitle();
  }

  private initTitle(): void {
    const data = {
      companyName: this.companyData.brandName,
    };

    if (
      check.assigned(this.feed.comments) &&
      check.array(this.feed.comments) &&
      check.nonEmptyArray(this.feed.comments) &&
      check.assigned(this.feed.comments[0]) &&
      check.nonEmptyString(this.feed.comments[0].message)
    ) {
      this.questionTitle = this.injector.get(I18nDataPipe).transform(this.feed.title, data);
      if (this.surveyTranslation[this.feed.title]) {
        this.questionTitle = this.injector.get(I18nDataPipe).transform(this.surveyTranslation[this.feed.title], data);
      } else if (this.pulseInsightsTranslation[this.feed.title]) {
        this.questionTitle = this.injector.get(I18nDataPipe).transform(this.pulseInsightsTranslation[this.feed.title], data);
      }
      this.feedTitle = this.injector.get(I18nDataPipe).transform(this.feed.comments[0].message, data);
    } else {
      this.feedTitle = this.injector.get(I18nDataPipe).transform(this.feed.title, data);
    }
  }

  private checkIfRead(): void {
    if (check.not.assigned(this.feed.comments) || check.not.array(this.feed.comments) || check.emptyArray(this.feed.comments)) {
      this.isRead = true;
      return;
    }
    const arrayNonRead = this.feed.comments.filter((iComment) => {
      return check.not.assigned(iComment.isRead) || iComment.isRead === false;
    });
    this.isRead = check.emptyArray(arrayNonRead);
  }

  openReplyPopup(): void {
    if (this.isReplyOpened === true) {
      return;
    }
    this.isReplyOpened = true;
    this.readComments();

    const dataDialog = {
      feed: this.feed,
      mapUserIdToUserPersonal: this.mapUserIdToUserPersonal,
      questionTitle: this.questionTitle,
    };

    const dialogRef = this.dialog.open(FeedCommentsDialog, { data: dataDialog });
    dialogRef.afterClosed().subscribe((changedFeed) => {
      this.isReplyOpened = false;
      if (changedFeed && changedFeed === true) {
        this.changeFeed.emit(this.feed._id);
      }
    });
  }

  private readComments(): void {
    const feedServiceClass = this.standardServicesRegistry.getService('Feed');
    this.injector
      .get(feedServiceClass)
      .readComment(this.feed._id)
      .then(() => {
        this.isRead = true;
      })
      .catch(() => {
        // Error managed silently by service
      });
  }
}
