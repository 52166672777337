import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { ICalendarEvent, ICalendarUserEvents } from '@app/standard/services/user/controllers/time-off-calendar.controller';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit, AfterViewInit, AfterViewChecked {
  i18n: any = {};
  translatedWeekdays: Array<string> = [];

  calendarWidth: number;
  calendarHeight: number;

  calendarOffsetHeight: number = 0;

  usersToShow: Array<IUserData> = [];
  datesOfYear: Array<moment.Moment> = [];
  datesOfYearToShow: Array<moment.Moment> = [];

  todayDate: moment.Moment = moment();

  heighOfAllUsers: number = 0;
  heightBeforeStartUser: number = 0;

  widthOfAllDates: number = 0;
  widthBeforeStartDate: number = 0;

  private _scroll: { top: number; left: number } = { top: 0, left: 0 };
  set scroll(scroll: { top: number; left: number }) {
    this._scroll = scroll;
  }
  get scroll(): { top: number; left: number } {
    return this._scroll;
  }

  private _users: Array<IUserData> = [];
  @Input()
  set users(users: Array<IUserData>) {
    if (check.not.assigned(users)) {
      this._users = [];
    } else {
      this._users = users;
    }

    this.heighOfAllUsers = this._users.reduce((tempHeighOfAllUsers, iUser) => {
      return tempHeighOfAllUsers + (iUser.collapsed ? iUser.collapsedHeight : iUser.expandedHeight);
    }, 0);

    this.refreshDataOnChange(false, true);
  }
  get users(): Array<IUserData> {
    return this._users;
  }
  private _year: number;
  @Input()
  set year(year: number) {
    const previousYear = check.assigned(year) ? this.year : undefined;

    if (check.not.assigned(year)) {
      this._year = this.todayDate.year();
    } else if (check.string(year)) {
      this._year = Number.parseInt(<any>year, 10);
    } else {
      this._year = <number>year;
    }

    this.yearChange.emit(this._year);

    const numberOfDaysInYear = moment.utc([this._year]).isLeapYear() ? 366 : 365;

    const currentLanguage = this.injector.get(InternationalizationService).getLanguage();
    this.datesOfYear = Array.from({ length: numberOfDaysInYear }, (item, iDay) => {
      return moment.utc(`${year}${iDay + 1}`, 'YYYYDDD').locale(currentLanguage);
    });

    this.widthOfAllDates = (numberOfDaysInYear + 1) * this.cellWidth;

    this.refreshDataOnChange(true, false);

    if (check.assigned(previousYear) && previousYear < this.year) {
      this.calendarElement.nativeElement.scrollLeft = 0;
      this.scroll = { top: 0, left: 0 };
    } else if (check.assigned(previousYear) && previousYear > this.year) {
      this.calendarElement.nativeElement.scrollLeft = numberOfDaysInYear * this.cellWidth - this.calendarWidth + 215;
      this.scroll = { top: 0, left: numberOfDaysInYear * this.cellWidth - this.calendarWidth + 215 };
    }
  }
  get year(): number {
    return this._year;
  }

  @Input() cellWidth: number = 32;
  @Input() datesBuffer: number = 6;
  @Input() usersBuffer: number = 6;

  @Output() yearChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('calendarContent', { static: true }) calendarElement: ElementRef;
  @ViewChild('usersColumnContentElement', { static: true }) usersColumnContentElement: ElementRef;
  @ViewChild('usersColumnElement', { static: true }) usersColumnElement: ElementRef;

  constructor(private cdr: ChangeDetectorRef, private injector: Injector) {}

  ngOnInit(): void {
    this.translatedWeekdays = this.injector.get(InternationalizationService).getTranslatedWeekdays();

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('calendar-component')
      .then((calendarComponentTranslation) => {
        this.i18n = calendarComponentTranslation;

        this.cdr.detectChanges();
      })
      .catch(() => {
        this.i18n = {};
      });
  }

  ngAfterViewInit(): void {
    if (this.year === this.todayDate.year()) {
      this.goToday();
    }
  }

  ngAfterViewChecked(): void {
    if (this.calendarWidth !== this.calendarElement.nativeElement.offsetWidth || this.calendarHeight !== this.calendarElement.nativeElement.offsetHeight) {
      this.calendarWidth = this.calendarElement.nativeElement.offsetWidth;
      this.calendarHeight = this.calendarElement.nativeElement.offsetHeight;

      this.refreshDataOnChange();
    }

    const newCalendarOffsetHeight = this.usersColumnElement.nativeElement.offsetHeight - this.usersColumnContentElement.nativeElement.offsetHeight - 80 > 0 ? this.usersColumnElement.nativeElement.offsetHeight - this.usersColumnContentElement.nativeElement.offsetHeight - 80 : 0;
    if (this.calendarOffsetHeight !== newCalendarOffsetHeight) {
      this.calendarOffsetHeight = newCalendarOffsetHeight;
      this.cdr.detectChanges();
    }
  }

  goToday(): void {
    const todayScrollLeft = Math.max(this.todayDate.dayOfYear() - 15, 0) * this.cellWidth;
    this.calendarElement.nativeElement.scrollLeft = todayScrollLeft;

    this.scroll = { top: 0, left: todayScrollLeft };

    if (this.year !== this.todayDate.year()) {
      this.year = undefined;
    } else {
      this.refreshDataOnChange(true, false);
    }
  }

  getFormattedHours(iEvent: ICalendarEvent): string {
    const fromMoment = moment.utc(iEvent.start);
    const toMoment = moment.utc(iEvent.end);
    const minutes = toMoment.diff(fromMoment, 'minutes');
    const duration = this.injector.get(DurationPipe).transform(minutes);
    return `${fromMoment.format('LT')} - ${toMoment.format('LT')} (${duration})`;
  }

  refreshDataOnChange(refreshDates: boolean = true, refreshUsers: boolean = true): void {
    if (refreshDates === true && check.assigned(this.calendarWidth) && check.assigned(this.datesOfYear) && check.nonEmptyArray(this.datesOfYear)) {
      const maxDatesToShow = Math.floor((this.calendarWidth - 215) / this.cellWidth) + this.datesBuffer;
      const startDateIndex = Math.max(Math.floor(this.scroll.left / this.cellWidth) - Math.floor(this.datesBuffer / 2), 0);
      const endDateIndex = Math.min(startDateIndex + maxDatesToShow, this.datesOfYear.length);
      this.datesOfYearToShow = this.datesOfYear.slice(startDateIndex, endDateIndex);

      this.widthBeforeStartDate = startDateIndex * this.cellWidth;
    }

    if (refreshUsers === true && check.assigned(this.calendarHeight) && check.assigned(this.users)) {
      let currentHeightForFindStartUserIndex = 0;
      let startUserIndex = this.users.findIndex((iUser) => {
        if (currentHeightForFindStartUserIndex >= this.scroll.top) {
          return true;
        }

        currentHeightForFindStartUserIndex += iUser.collapsed ? iUser.collapsedHeight : iUser.expandedHeight;
        return false;
      });

      let currentHeightForFindEndUserIndex = 0;
      let endUserIndex = this.users.findIndex((iUser) => {
        currentHeightForFindEndUserIndex += iUser.collapsed ? iUser.collapsedHeight : iUser.expandedHeight;

        if (currentHeightForFindEndUserIndex >= this.scroll.top + this.calendarHeight) {
          return true;
        }

        return false;
      });
      if (endUserIndex === -1) {
        endUserIndex = this.users.length;
      }

      startUserIndex = Math.max(startUserIndex - Math.floor(this.usersBuffer / 2), 0);
      endUserIndex = Math.min(endUserIndex + Math.ceil(this.usersBuffer / 2), this.users.length);
      this.usersToShow = this.users.slice(startUserIndex, endUserIndex);

      this.heightBeforeStartUser = this.users.slice(0, startUserIndex).reduce((tempHeightBeforeStartUser: number, iUser: IUserData) => {
        return tempHeightBeforeStartUser + (iUser.collapsed ? iUser.collapsedHeight : iUser.expandedHeight);
      }, 0);
    }

    this.cdr.detectChanges();
  }

  trackDateFunc(index: number, item: moment.Moment): number {
    return item.dayOfYear();
  }

  trackUserFunc(index: number, item: IUserData): string {
    return item.userId;
  }

  trackEventFunc(index: number, item: ICalendarEvent): string {
    return item.id;
  }
}

export interface IUserData {
  userId: string;
  displayName: string;
  _photo: IFileMetadata;
  workSchedule: Array<0 | 1 | 2 | 3 | 4 | 5 | 6>;
  nonWorkingDates: Array<string>;
  publicHolidays: Array<number>;
  events: ICalendarUserEvents;
  collapsed: boolean;
  collapsedHeight: number;
  expandedHeight: number;
  numberOfDifferentEventTypes: number;
}
