import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Route, Router } from '@angular/router';
import { NotificationService } from '@app/cloud-features/notifications/services/notification.service';
import { CloudFeaturesGuard } from '@app/core-features/_guards/cloud-features.guard';
import { SubscriptionExpiredPaywallDialog } from '@app/core-features/cloud/routing/components/subscription-expired/subscription-expired.component';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { UpdateAppDialog } from '@app/standard/pages/app-status/update-app.dialog';
import { StandardPagesRegistry } from '@app/standard/pages/standard-pages.registry';
import { AppStatusService } from '@app/standard/services/app-status/app-status.service';
import { CoreFeaturesService } from '@app/standard/services/core-features/core-features.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CloudRoutingService {
  private ROUTES_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/routes`;

  private initialUrl: string;
  private defaultUrl: string;

  private cloudFeaturesRoutesWithLazyLoading: Array<any>;

  private _cloudRoutes: Array<IRoute> = [];
  private set cloudRoutes(cloudRoutes: Array<IRoute>) {
    this._cloudRoutes = cloudRoutes;
    this.routesChanged.next(this.getConfiguredRoutes());
  }
  private get cloudRoutes(): Array<IRoute> {
    return this._cloudRoutes;
  }

  private routesInitialized: boolean = false;
  public isFreeTrialExpired: boolean = false;
  public routesChanged: Subject<Array<IRoute>> = new Subject<Array<IRoute>>();
  public newNotifications: Subject<boolean> = new Subject<boolean>();
  private currentAppVersion: number;
  constructor(private injector: Injector, private location: Location) {}

  setInitialUrl(newInitialUrl?: string): void {
    if (check.nonEmptyString(newInitialUrl)) {
      this.initialUrl = newInitialUrl;
      return;
    } else if (check.assigned(this.initialUrl)) {
      return;
    }

    this.initialUrl = this.location.path();
  }

  private configureLazyLoadingRoutes(): void {
    if (check.assigned(this.cloudFeaturesRoutesWithLazyLoading)) {
      return;
    }

    const cloudRoute: Route = this.injector.get(Router).config.find((route) => {
      return route.path === 'cloud';
    });

    if (
      check.not.assigned(cloudRoute) ||
      check.not.assigned(cloudRoute['_loadedRoutes']) ||
      check.not.assigned(cloudRoute['_loadedRoutes'][0])
    ) {
      return;
    }

    this.cloudFeaturesRoutesWithLazyLoading = cloudRoute['_loadedRoutes'][0].children;

    this.cloudFeaturesRoutesWithLazyLoading.forEach((iRoute: Route) => {
      const canLoadGuards = check.assigned(iRoute.canLoad) ? iRoute.canLoad : [];
      const canActivateGuards = check.assigned(iRoute.canActivate) ? iRoute.canActivate : [];
      const canActivateChildGuards = check.assigned(iRoute.canActivateChild) ? iRoute.canActivateChild : [];

      canLoadGuards.unshift(CloudFeaturesGuard);
      canActivateGuards.unshift(CloudFeaturesGuard);
      canActivateChildGuards.unshift(CloudFeaturesGuard);

      iRoute.canLoad = canLoadGuards;
      iRoute.canActivate = canActivateGuards;
      iRoute.canActivateChild = canActivateChildGuards;
    });
  }

  getConfiguredRoutes(): Array<IRoute> {
    // We return a cloned array in order not to leak the routes and avoid attacks.
    return this.cloudRoutes.slice(0);
  }

  async refreshRouter() {
    try {
      let routes = await this.getRoutes();

      this.isFreeTrialExpired = false;
      if (!routes?.length) {
        // if the free trial expired
        routes = this.getFreeTrialExpiredRoutes();
        this.isFreeTrialExpired = true;
      }

      if (_.isEqual(routes, this.cloudRoutes)) {
        this.cloudRoutes = this.getConfiguredRoutes();
        return;
      }

      this.configureLazyLoadingRoutes();
      this.reconfigureRouter(routes);

      if (this.isFreeTrialExpired) {
        this.showFreeTrialExpiredPaywall();
      }
    } catch {
      // If the routes cannot be retrieved, a sign out is forced
      this.injector.get(AuthenticationService).signOut();
    }
  }

  private getRoutes(): Promise<Array<IRoute>> {
    return new Promise((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, CloudRoutingService.name, 'getRoutes');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .get<Array<IRoute>>(`${this.ROUTES_URL}`, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          if (error.status === ErrorCodes.PAYMENT_REQUIRED) {
            resolve([]);
            return;
          }
          reject(this.injector.get(ErrorManagerService).handleRawError(error, CloudRoutingService.name, 'getRoutes'));
        });
    });
  }

  getApps(): Promise<Array<IApp>> {
    return new Promise((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, CloudRoutingService.name, 'getApps');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      this.injector
        .get(CoreFeaturesService)
        .getAllApps()
        .then((apps) => {
          resolve(apps);
        })
        .catch((error) => {
          // An error already shown
          reject(error);
        });
    });
  }
  private reconfigureRouter(newRoutes: Array<IRoute>): void {
    const cloudRoute: Route = this.injector.get(Router).config.find((route) => {
      return route.path === 'cloud';
    });

    if (
      check.not.assigned(cloudRoute) ||
      check.not.assigned(cloudRoute['_loadedRoutes']) ||
      check.not.assigned(cloudRoute['_loadedRoutes'][0])
    ) {
      return;
    }

    const legacyRoutes = newRoutes
      .filter((iRoute: IRoute) => {
        if (check.not.assigned(iRoute.legacy) || iRoute.legacy === false) {
          return false;
        }

        const page = this.injector.get(StandardPagesRegistry).getPage(iRoute.pageName);

        if (check.not.assigned(page)) {
          // If the page has not being defined in the page registry, we handle a silent error
          // and skip it for the user.
          const error = new OrgosError('CONFIGURATION ERROR', ErrorCodes.CLIENT_ERROR, CloudRoutingService.name, 'reconfigureRouter');
          error.message = `Route '${iRoute.url}' has not been configured in the page registry as '${iRoute.pageName}'`;
          this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

          return false;
        }

        return true;
      })
      .map((iRoute: IRoute) => {
        const page = this.injector.get(StandardPagesRegistry).getPage(iRoute.pageName);

        const route: Route = {
          path: iRoute.url,
          component: page,
        };

        return route;
      });

    cloudRoute['_loadedRoutes'][0].children = this.cloudFeaturesRoutesWithLazyLoading.concat(legacyRoutes);

    if (check.emptyArray(cloudRoute['_loadedRoutes'][0].children)) {
      this.injector.get(Router).resetConfig(this.injector.get(Router).config);
      return;
    }

    this.defaultUrl = cloudRoute['_loadedRoutes'][0].children[0].path;

    cloudRoute['_loadedRoutes'][0].children.push({
      path: '',
      component: cloudRoute['_loadedRoutes'][0].children[0].component,
      pathMatch: 'full',
    });

    cloudRoute['_loadedRoutes'][0].children.push({
      path: '**',
      redirectTo: this.defaultUrl,
    });

    this.cloudRoutes = newRoutes;
  }

  navigateToInitialRoute(): void {
    if (
      this.initialUrl.startsWith('/cloud/') === false ||
      this.initialUrl.endsWith('/cloud') === true ||
      this.initialUrl.endsWith('/cloud/') === true
    ) {
      this.navigateToDefaultRoute();
      return;
    }

    this.injector.get(Router).navigateByUrl(this.initialUrl, { replaceUrl: true });
  }

  navigateToDefaultRoute(): void {
    const defaultUrl = `/cloud/${this.defaultUrl}`;
    this.injector.get(Router).navigateByUrl(defaultUrl, { replaceUrl: true });
  }

  checkIfRouteExists(route: string): boolean {
    if (check.not.assigned(route) || check.not.string(route) || check.emptyString(route)) {
      return false;
    }

    const foundRoute = this.cloudRoutes.find((iRoute) => {
      return check.match(iRoute.url, `^${route}$`);
    });

    return check.assigned(foundRoute);
  }

  markRoutesAsInitialized(): void {
    this.routesInitialized = true;
  }

  areRoutesInitialized(): boolean {
    return this.routesInitialized;
  }

  resetCloudRouting(): void {
    this.routesInitialized = false;
    this.initialUrl = '';
    this.defaultUrl = '';
    this.cloudRoutes = [];
  }

  forceCheckIfNewNotifications(): void {
    this.injector
      .get(NotificationService)
      .checkIfNewNotifications()
      .then((newNotifications: boolean) => {
        this.newNotifications.next(newNotifications);
      })
      .catch(() => {
        this.newNotifications.next(false);
      });
  }

  async checkAppStatus() {
    const appStatus = await this.injector.get(AppStatusService).getAppStatus();
    if (check.not.assigned(appStatus)) {
      return;
    }
    const { newActiveNotificationsAvailable, latestDeployedVersion } = appStatus;
    this.newNotifications.next(newActiveNotificationsAvailable);
    if (check.not.assigned(latestDeployedVersion) || isNaN(Number(latestDeployedVersion))) {
      return;
    }

    if (!this.currentAppVersion) {
      this.currentAppVersion = Number(latestDeployedVersion);
      return;
    }

    if (this.currentAppVersion < Number(latestDeployedVersion)) {
      this.injector.get(MatLegacyDialog).open(UpdateAppDialog, { disableClose: true });
    }
  }

  private getFreeTrialExpiredRoutes(): Array<IRoute> {
    const FREE_TRIAL_BILLING_ROUTES: Array<IRoute> = [
      {
        url: 'settings/billing',
        pageName: 'settings-billing-upgrade',
        icon: 'settings_menu',
        appKey: 'core',
        legacy: true,
        default: false,
      },
      {
        url: 'settings/billing/info',
        pageName: 'settings-billing-info',
        icon: 'settings_menu',
        appKey: 'core',
        legacy: true,
        default: false,
      },
      {
        url: 'settings/billing/invoices',
        pageName: 'settings-billing-invoices',
        icon: 'settings_menu',
        appKey: 'core',
        legacy: true,
        default: false,
      },
      {
        url: 'settings/billing/platform-usage',
        pageName: 'settings-billing-platform-usage',
        icon: 'settings_menu',
        appKey: 'core',
        legacy: true,
        default: false,
      },
    ];
    return FREE_TRIAL_BILLING_ROUTES;
  }

  private showFreeTrialExpiredPaywall(): void {
    this.injector.get(Router).navigateByUrl(`/cloud/settings/billing`);
    this.injector.get(MatLegacyDialog).open(SubscriptionExpiredPaywallDialog);
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('expired free trial logged in: show free trial expiry gate', {
        category: 'Free trial',
        subcategory1: 'Gating',
        subcategory2: `Profile key ${this.injector.get(AuthenticationService).getLoggedUser()?.profileKey}`,
      });
  }

  isThisOrgExpiredFreeTrial(routes: Array<IRoute>): boolean {
    const freeTrialExpiredRoutes = this.getFreeTrialExpiredRoutes();
    if (routes.length !== freeTrialExpiredRoutes.length) {
      return false;
    }

    const routesObtained = routes.map((route) => route.url);
    const routesOfFreeTrial = freeTrialExpiredRoutes.map((route) => route.url);
    if ([...new Set(...routesObtained, ...routesOfFreeTrial)].length === freeTrialExpiredRoutes.length) {
      return true;
    }
    return false;
  }
}

export interface IRoute {
  url: string;
  pageName: string;
  icon?: string | boolean;
  default: boolean;
  appKey: string;
  legacy?: boolean;
}

export interface IApp {
  appKey: string;
  developmentState?: string;
  isActive: boolean;
  hasSettings?: boolean;
  isHomeWidget?: boolean;
  needSupportNotification?: boolean;
  lastSupportNotificationDate?: Date;
  dependencies?: [string];
}
