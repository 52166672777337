import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanRoleSettingsService implements IGenericService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-role-db`;
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan/can-delete`;
  private SHIFT_PLAN_ROLES_INTERNATIONALIZATION: string = 'shift-plan-settings-roles-tags';
  private SHIFTPLAN_ROLE_SERVICE: string = 'ShiftPlanRoleSettingsService';

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private genericService: GenericService,
    private errorManager: ErrorManagerService
  ) {}

  async create(data: object): Promise<IShiftPlanRoleSettingsModel> {
    try {
      return await this.genericService.create(this.MICROSERVICE_URL, data);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_ROLE_SERVICE, 'create');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  async getRoles(findQuery = { _id: { $ne: null } }): Promise<Array<IShiftPlanRoleSettingsModel>> {
    try {
      return await this.genericService.find(this.MICROSERVICE_URL, findQuery);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_ROLE_SERVICE, 'getRoles');
    }
  }

  async getById(id: string): Promise<IShiftPlanRoleSettingsModel> {
    try {
      return await this.genericService.getById(this.MICROSERVICE_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_ROLE_SERVICE, 'getById');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.MICROSERVICE_URL, id, data);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_ROLE_SERVICE, 'updateById');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.MICROSERVICE_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_ROLE_SERVICE, 'deleteById');
    }
  }

  getPermissions(): Promise<object> {
    return Promise.resolve({
      create_all: true,
      read_all: true,
      edit_all: true,
      delete_all: false,
    });
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.SHIFT_PLAN_ROLES_INTERNATIONALIZATION);
  }

  async canDeleteRole(roleId: string, setting = 'role'): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_ROLE_SERVICE, 'canDeleteRole');
    }

    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      return await this.http.get(`${this.CONTROLLER_URL}/${setting}/${roleId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_ROLE_SERVICE, 'canDeleteRole');
    }
  }
}

export interface IShiftPlanRoleSettingsModel {
  _id?: string;
  name: string;
  colour: string;
  codeName?: string;
  ownerId?: string;
}
