<orgos-container
  *ngIf="!loadingPage && userPersonal && userWork && !seeDetailsUserId && attendanceConflictsPerDay?.length"
  aspect="card"
  style="overflow-x: auto"
>
  <div class="pdap-main-container">
    <div class="pdap-tabs">
      <mat-tab-group class="small-tabs" mat-align-tabs="center" [selectedIndex]="selectedTab" (selectedIndexChange)="onChangeTab($event)">
        <mat-tab
          *ngFor="let iTab of listTabs"
          [label]="iTab"
          [disabled]="loadingChangeMonth || entryHasErrors || timeEntryBeingEdited || savingTimeEntries"
        ></mat-tab>
      </mat-tab-group>
    </div>
    <div class="pdap-actions-bar">
      <div class="pdap-actions-bar__col">
        <orgos-user-header
          orgosColumn="1"
          class="kenjo-pl-40px kenjo-pr-40px kenjo-pt-10px kenjo-pb-10px"
          size="medium"
          [userPersonal]="userPersonal"
          [userWork]="userWork"
        ></orgos-user-header>
      </div>
      <div class="pdap-actions-bar__col months-selector">
        <ng-container *ngIf="selectedTab === MONTHLY_TAB_INDEX">
          <kenjo-icon
            [size]="25"
            [disabled]="loadingChangeMonth || timeEntryBeingEdited || savingTimeEntries"
            class="kenjo-cursor-pointer"
            [class.kenjo-font-color-disabled-c4c4c4]="loadingChangeMonth || timeEntryBeingEdited || savingTimeEntries"
            [class.kenjo-font-color-primary-5993e3]="!loadingChangeMonth"
            (click)="changeMonth(-1)"
          >
            keyboard_arrow_left
          </kenjo-icon>
          <div class="kenjo-ph-20px">
            <kenjo-input-month-picker
              [preferenceDate]="currentMonthDate"
              (selectedDate)="changeParticularMonth($event)"
              [disabled]="loadingChangeMonth || timeEntryBeingEdited || savingTimeEntries"
            >
            </kenjo-input-month-picker>
          </div>
          <kenjo-icon
            [size]="25"
            [disabled]="loadingChangeMonth || timeEntryBeingEdited || savingTimeEntries"
            class="kenjo-cursor-pointer"
            [class.kenjo-font-color-disabled-c4c4c4]="loadingChangeMonth || timeEntryBeingEdited || savingTimeEntries"
            [class.kenjo-font-color-primary-5993e3]="!loadingChangeMonth"
            (click)="changeMonth(+1)"
          >
            keyboard_arrow_right
          </kenjo-icon>
        </ng-container>
        <ng-container *ngIf="selectedTab === DATE_RANGE_TAB_INDEX">
          <kenjo-input-date-range-picker
            [fromPlaceholder]="i18n.page.from"
            [toPlaceholder]="i18n.page.to"
            [(value)]="timeRange"
            (valueChange)="onDateRangeChange($event)"
            [showMenu]="false"
          >
          </kenjo-input-date-range-picker>
        </ng-container>
      </div>
      <div class="pdap-actions-bar__col actions">
        <kenjo-toggle-error-button
          *ngIf="amountDaysWithConflicts > 0 && selectedTab === MONTHLY_TAB_INDEX"
          class="kenjo-mr-20px"
          [indicatorLabel]="amountDaysWithConflicts"
          [useIndicator]="true"
          [buttonLabel]="i18n.page.daysWithConflicts"
          [indicatorSize]="'l'"
          [semantic]="'danger'"
          [active]="activeFilterOfConflicts"
          [disable]="loadingChangeMonth || timeEntryBeingEdited || savingTimeEntries"
          [active]="activeFilterOfConflicts"
          (click)="filterByConflicts()"
        ></kenjo-toggle-error-button>
        <div *ngIf="!downloadingDocument">
          <orgos-button-raised
            [matMenuTriggerFor]="actionsMenu"
            [disabled]="loadingChangeMonth || entryHasErrors || timeEntryBeingEdited || savingTimeEntries"
          >
            {{i18n.misc.actions}}</orgos-button-raised
          >
          <mat-menu #actionsMenu="matMenu">
            <ng-container
              *ngIf="userWorkSchedule && userWorkSchedule.overtimeSettings && userWorkSchedule.overtimeSettings.trackOvertime === true && currentMonthDate.isSameOrBefore(todayDate)"
            >
              <div class="kenjo-font-weight-bold kenjo-font-size-12px kenjo-mt-10px kenjo-ml-15px kenjo-mb-5px kenjo-pr-15px">
                {{i18n.globalMisc.overtimeOptionsMenu.overtimeActionsSection}}
              </div>
              <button mat-menu-item (click)="adjustBalance()" [disabled]="!canManage">
                {{i18n.globalMisc.overtimeOptionsMenu.adjustBalance}}
              </button>
              <button
                mat-menu-item
                (click)="compensateTimeOff()"
                [disabled]="!canManage || !canConvertTimeOff || !attendanceSummaryCurrentMonth || attendanceSummaryCurrentMonth.currentOvertimeBalance <= 0"
              >
                {{i18n.globalMisc.overtimeOptionsMenu.timeOff}}
              </button>
              <button
                mat-menu-item
                (click)="compensatePayment()"
                [disabled]="!canManage || !canPayOvertime || !attendanceSummaryCurrentMonth || attendanceSummaryCurrentMonth.currentOvertimeBalance <= 0"
              >
                {{i18n.globalMisc.overtimeOptionsMenu.payOut}}
              </button>
              <button mat-menu-item (click)="seeDetails()">{{i18n.globalMisc.overtimeOptionsMenu.history}}</button>
            </ng-container>
            <div
              *ngIf="canApprove"
              class="kenjo-font-weight-bold kenjo-font-size-12px kenjo-mt-10px kenjo-ml-15px kenjo-mb-5px kenjo-pr-15px"
            >
              {{i18n.globalMisc.overtimeOptionsMenu.attendanceActionsSection}}
            </div>
            <button
              [disabled]="!pendingToApprove"
              *ngIf="canApprove === true && selectedTab === MONTHLY_TAB_INDEX"
              mat-menu-item
              (click)="approveMonth()"
            >
              {{i18n.page.approveEntries}}
            </button>
            <button
              [disabled]="!(currentDateRange && currentDateRange.startDate && currentDateRange.endDate && pendingToApproveInCurrentDateRange)"
              *ngIf="canApprove && selectedTab === DATE_RANGE_TAB_INDEX"
              mat-menu-item
              (click)="approveDateRange()"
            >
              {{i18n.page.approveEntries}}
            </button>

            <ng-container *ngIf="selectedTab === MONTHLY_TAB_INDEX">
              <div class="kenjo-font-weight-bold kenjo-font-size-12px kenjo-mt-10px kenjo-ml-15px kenjo-mb-5px kenjo-pr-15px">
                {{i18n.globalMisc.overtimeOptionsMenu.exportActionsSection}}
              </div>
              <button mat-menu-item (click)="exportToPDF()">{{i18n.page.exportToPdf}}</button>
              <button mat-menu-item (click)="exportToDoc()">{{i18n.page.exportToDoc}}</button>
            </ng-container>
          </mat-menu>
        </div>
        <orgos-column-container *ngIf="downloadingDocument" class="kenjo-text-align-right">
          <orgos-column></orgos-column>
          <orgos-column size="0">
            <orgos-loading-spinner></orgos-loading-spinner>
          </orgos-column>
          <orgos-column size="0">
            <div class="kenjo-ml-10px kenjo-font-color-light-text-757575 kenjo-text-transform-uppercase">
              {{i18n.page.downloadingLabel}}
            </div>
          </orgos-column>
        </orgos-column-container>
      </div>
    </div>
    <div
      class="pdap-hours-summary"
      [class.with-warning]="showAdjustmentWarning && selectedTab === MONTHLY_TAB_INDEX"
      [class.with-warning-and-negative-balance]="showAdjustmentWarning && (attendanceSummaryCurrentMonth?.currentOvertimeBalance ? attendanceSummaryCurrentMonth?.currentOvertimeBalance : 0) < 0 && selectedTab === MONTHLY_TAB_INDEX"
      [class.days-scrolled]="daysContainerScrolled && !loadingChangeMonth"
    >
      <div class="pdap-hours-summary__container">
        <div class="pdap-hours-summary__main kenjo-ph-40px">
          <div class="kenjo-font-size-16px kenjo-font-color-text-333333 kenjo-mb-40px">{{i18n.page.hoursSummary}}</div>
          <div
            class="pdap-hours-summary__details-row"
            [class.no-overtime-details]="!userWorkSchedule || (userWorkSchedule && userWorkSchedule.overtimeSettings && userWorkSchedule.overtimeSettings.trackOvertime === false) || selectedTab === DATE_RANGE_TAB_INDEX"
          >
            <!-- Track time dashboard -->
            <div class="pdap-hours-summary__detail">
              <div class="pdap-hours-summary__detail__icon">
                <kenjo-icon size="50" class="pdap-track-time-icon" [iconClass]="'pdap-track-time-icon'">track_time</kenjo-icon>
              </div>
              <div class="pdap-hours-summary__detail__info">
                <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-mb-10px">
                  <ng-container *ngIf="selectedTab === MONTHLY_TAB_INDEX">
                    {{todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') ? (i18n.page.trackedSoFar | i18nData: ({
                    datesUntilNow: (datesUntilNow.from | humanDate: false:'shortWithoutYear') + ' - ' + ((datesUntilNow.to | humanDate:
                    false:'shortWithoutYear':true)) })) : i18n.page.tracked}}
                  </ng-container>
                  <ng-container *ngIf="selectedTab === DATE_RANGE_TAB_INDEX"> {{i18n.page.tracked}} </ng-container>
                </div>
                <div class="kenjo-font-size-20px kenjo-font-color-text-333333">
                  <ng-container *ngIf="selectedTab === MONTHLY_TAB_INDEX">
                    {{(totalWorkedMinutesInCurrentMonth ? totalWorkedMinutesInCurrentMonth : 0) | duration}}
                  </ng-container>
                  <ng-container *ngIf="selectedTab === DATE_RANGE_TAB_INDEX">
                    {{(totalWorkedMinutesInCurrentRange ? totalWorkedMinutesInCurrentRange : 0) | duration}}
                  </ng-container>
                </div>
              </div>
            </div>
            <!-- Expected dashboard -->
            <div class="pdap-hours-summary__detail">
              <div class="pdap-hours-summary__detail__icon">
                <kenjo-icon size="50" class="pdap-expected-icon">schedule</kenjo-icon>
              </div>
              <div class="pdap-hours-summary__detail__info" *ngIf="selectedTab === MONTHLY_TAB_INDEX">
                <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-mb-10px">
                  {{todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') ? (i18n.page.expectedSoFar | i18nData: ({
                  datesUntilNow: (datesUntilNow.from | humanDate: false:'shortWithoutYear') + ' - ' + ((datesUntilNow.to | humanDate:
                  false:'shortWithoutYear':true)) })) : i18n.page.expected}}
                </div>
                <div class="kenjo-font-size-20px kenjo-font-color-text-333333">
                  {{(todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') ? (totalExpectedMinutesToWorkSoFar ?? 0) :
                  totalExpectedMinutesToWorkInCurrentMonth) | duration}}
                </div>
              </div>

              <div class="pdap-hours-summary__detail__info" *ngIf="selectedTab === DATE_RANGE_TAB_INDEX">
                <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-mb-10px">
                  {{i18n.page.expected}}
                </div>
                <div class="kenjo-font-size-20px kenjo-font-color-text-333333">
                  {{(totalExpectedMinutesToWorkInCurrentRange ? totalExpectedMinutesToWorkInCurrentRange : 0) | duration}}
                </div>
              </div>
            </div>
            <!-- Paid hours dashboard -->
            <div
              *ngIf="showPaidHours === true && selectedTab === MONTHLY_TAB_INDEX"
              class="pdap-hours-summary__detail kenjo-cursor-pointer"
            >
              <div class="pdap-hours-summary__detail__icon">
                <kenjo-icon size="50" class="pdap-total-expected-icon">payroll_menu</kenjo-icon>
              </div>
              <div matTooltipPosition="above" [matTooltip]="i18n.page.totalPaidHoursTooltip" class="pdap-hours-summary__detail__info">
                <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-mb-10px">
                  {{todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') ? (i18n.page.totalPaidHoursSoFar | i18nData: ({
                  datesUntilNow: (datesUntilNow.from | humanDate: false:'shortWithoutYear') + ' - ' + ((datesUntilNow.to | humanDate:
                  false:'shortWithoutYear':true)) })) : i18n.page.totalPaidHours}}
                </div>
                <div class="kenjo-font-size-20px kenjo-font-color-text-333333">
                  <ng-container> {{(totalPaidMinutesInCurrentMonth ? totalPaidMinutesInCurrentMonth : 0) | duration}} </ng-container>
                </div>
              </div>
            </div>
            <!-- Expected this month dashboard -->
            <div *ngIf="todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') && selectedTab === MONTHLY_TAB_INDEX" class="pdap-hours-summary__detail">
              <div class="pdap-hours-summary__detail__icon">
                <kenjo-icon size="50" class="pdap-total-expected-icon">schedule</kenjo-icon>
              </div>
              <div class="pdap-hours-summary__detail__info">
                <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-mb-10px">
                    {{todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') ? i18n.page.expectedThisMonth :
                    i18n.page.expected}}
                </div>
                <div class="kenjo-font-size-20px kenjo-font-color-text-333333">
                  {{(totalExpectedMinutesToWorkInCurrentMonth ? totalExpectedMinutesToWorkInCurrentMonth : 0) | duration}}
                </div>
              </div>
            </div>
            <div class="pdap-hours-summary__detail" *ngIf="todayDate.format('YYYY MM') !== currentMonthDate.format('YYYY MM')"></div>
          </div>
        </div>
        <!-- Overtime dashboard -->
        <div
          class="pdap-hours-summary__overtime kenjo-ph-40px"
          *ngIf="userWorkSchedule && userWorkSchedule.overtimeSettings && userWorkSchedule.overtimeSettings.trackOvertime === true && selectedTab === MONTHLY_TAB_INDEX"
        >
          <div class="pdap-hours-summary__retry kenjo-mb-20px">
            <div class="kenjo-font-size-16px kenjo-font-color-text-333333">{{i18n.page.overtime}}</div>
            <orgos-action (click)="seeDetails()" *ngIf="currentMonthDate.isSameOrBefore(todayDate)"> {{i18n.page.details}} </orgos-action>
          </div>
          <div class="pdap-hours-summary__overtime-details">
            <div class="pdap-hours-summary__details-row kenjo-mb-15px" *ngIf="userWorkSchedule.overtimeSettings.transferNextMonth">
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">{{i18n.page.startingBalance}}</div>
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">
                {{(attendanceSummaryCurrentMonth?.lastMonthOvertime ? lastMonthOvertime : 0) | duration : true}}
              </div>
            </div>
            <div class="pdap-hours-summary__details-row kenjo-mb-15px">
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">{{i18n.page.earnedThisMonth}}</div>
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">
                {{(attendanceSummaryCurrentMonth?.trackedOvertime ? attendanceSummaryCurrentMonth?.trackedOvertime : 0) | duration : true}}
              </div>
            </div>
            <div class="pdap-hours-summary__details-row kenjo-mb-15px">
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">{{i18n.page.compensatedThisMonth}}</div>
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">
                {{(compensatedHours ? -compensatedHours : -0) | duration: true}}
              </div>
            </div>
            <div class="pdap-hours-summary__details-row">
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">
                {{todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM') ? i18n.page.currentBalance : i18n.page.endBalance}}
              </div>
              <div class="kenjo-font-color-text-333333 kenjo-font-size-14px">
                {{(attendanceSummaryCurrentMonth?.currentOvertimeBalance ? currentOvertimeBalance : 0) | duration : true}}
              </div>
            </div>
          </div>
          <div
            class="pdap-hours-summary__negative-balance kenjo-mt-15px"
            [class.visible]="(attendanceSummaryCurrentMonth?.currentOvertimeBalance ? attendanceSummaryCurrentMonth?.currentOvertimeBalance : 0) < 0"
          >
            <orgos-action (click)="seeNegativeBalanceInfo()"> {{i18n.page.whyIsMyBalanceNegative}} </orgos-action>
          </div>
        </div>
      </div>
      <orgos-warning-message
        *ngIf="userWorkSchedule && userWorkSchedule.overtimeSettings && userWorkSchedule.overtimeSettings.trackOvertime === true && showAdjustmentWarning && selectedTab === MONTHLY_TAB_INDEX"
        class="kenjo-mt-15px kenjo-ph-40px pdap-hours-summary__warning"
        canBeClosed="true"
        (hideWarning)="hideLastOvertimeAdjustmentWarning()"
      >
        {{i18n.page.overtimeWasManuallyAdjustedRecently}}<br />{{i18n.page.seeOvertimeDetails}}
      </orgos-warning-message>
      <orgos-button-raised
        *ngIf="!loadingChangeMonth && !activeFilterOfConflicts && !isTodayVisible && !scrollingToToday && !resetTodayButton && todayDate.format('YYYY MM') === currentMonthDate.format('YYYY MM')"
        invertColors="true"
        class="pdap-scroll-to-today"
        (click)="scrollToToday(true)"
        color="Primary"
      >
        {{i18n.page.jumpTo}}<br />{{i18n.page.today}}
      </orgos-button-raised>
    </div>
    <div
      class="pdap-days-container logged-user"
      [ngClass]="{
        'pdap-no-scrollbar': isLoadingConflicts || eventDialogsInfo['holiday']?.showDialog || eventDialogsInfo['time-off'].showDialog,
        'with-warning': showAdjustmentWarning && selectedTab === MONTHLY_TAB_INDEX,
        'with-warning-and-negative-balance': showAdjustmentWarning && (attendanceSummaryCurrentMonth?.currentOvertimeBalance ? attendanceSummaryCurrentMonth?.currentOvertimeBalance : 0) < 0 && selectedTab === MONTHLY_TAB_INDEX
      }"
      #dayElement
      (scroll)="onScroll()"
    >
      <ng-container
        *ngIf="(loadingChangeMonth && selectedTab === MONTHLY_TAB_INDEX) || (loadingChangeDateRange && selectedTab === DATE_RANGE_TAB_INDEX) || (isLoadingConflicts && selectedTab === MONTHLY_TAB_INDEX)"
      >
        <div class="pdap-spinner-container">
          <div class="pdap-spinner-container__content">
            <orgos-loading-spinner></orgos-loading-spinner>
            <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px kenjo-mt-20px">{{i18n.page.loadingDataMessage}} ...</div>
          </div>
        </div>
      </ng-container>

      <!-- DATE RANGE TABLE -->
      <ng-container *ngIf="!loadingChangeDateRange && daysOfCurrentDateRange && selectedTab === DATE_RANGE_TAB_INDEX">
        <div class="pdap-date-range-table-container">
          <orgos-table
            [hidePagination]="true"
            [emptyStateMessage]="i18n.page.emptyDateRangeTable"
            [fullHeight]="true"
            class="pdap-date-range-table kenjo-border kenjo-border-radius"
            [data]="dateRangeData"
            [displayedColumns]="displayedColumns"
            [totalColumns]="displayedColumns"
            [headerLabels]="headerLabels"
          >
            <orgos-table-column columnKey="date" [header]="i18n.page.date">
              <ng-container *orgosTableCell="let row">
                <div class="kenjo-font-size-14px kenjo-font-color-text-333333">{{row.date | date:'shortDate'}}</div>
              </ng-container>
            </orgos-table-column>
            <orgos-table-column columnKey="hoursExpected" [header]="i18n.page.hoursExpected">
              <ng-container *orgosTableCell="let row">
                <div class="kenjo-font-size-14px kenjo-font-color-text-333333">{{row.hoursExpected | duration}}</div>
              </ng-container>
            </orgos-table-column>
            <orgos-table-column columnKey="hoursTracked" [header]="i18n.page.hoursTracked">
              <ng-container *orgosTableCell="let row">
                <div class="kenjo-font-size-14px kenjo-font-color-text-333333">{{row.hoursTracked | duration}}</div>
              </ng-container>
            </orgos-table-column>
            <orgos-table-column columnKey="status" [header]="i18n.page.status">
              <ng-container *orgosTableCell="let row">
                <ng-container *ngIf="dateRangeIsAttendanceDayComplete[row.date.toISOString()]">
                  <orgos-chip *ngIf="row.hoursTracked > 0 || row.status" [color]="row.status ? 'Success' : 'Warning'">
                    {{row.status ? i18n.page.approvedLabel : i18n.page.pending}}
                  </orgos-chip>
                  <div *ngIf="row.hoursTracked === 0 && !row.status" class="kenjo-font-size-14px kenjo-font-color-text-333333">-</div>
                </ng-container>
                <ng-container *ngIf="!dateRangeIsAttendanceDayComplete[row.date.toISOString()] && row.hoursTracked > 0">
                  <orgos-chip color="Neutral"> {{i18n.page.incomplete}} </orgos-chip>
                </ng-container>
                <ng-container *ngIf="!dateRangeIsAttendanceDayComplete[row.date.toISOString()] && row.hoursTracked === 0">
                  <div class="kenjo-font-size-14px kenjo-font-color-text-333333">-</div>
                </ng-container>
              </ng-container>
            </orgos-table-column>
          </orgos-table>
        </div>
      </ng-container>

      <!-- LIST OF DAYS IN MONTHLY VIEW -->
      <ng-container *ngIf="!loadingChangeMonth && daysOfCurrentMonth && daysOfCurrentMonth.length > 0 && selectedTab === MONTHLY_TAB_INDEX">
        <div
          [@smoothCollapse]="{ value: null, params: { duration: activeFilterOfConflicts ? 300 : 0 }}"
          class="pdap-day-container"
          *ngFor="let iDayOfMonth of daysOfCurrentMonth; let indexOfDay = index; trackBy: trackByDays"
          #dayElement
          [class.today]="todayDate.format('YYYY-MM-DD') === iDayOfMonth.format('YYYY-MM-DD')"
          [class.before]="iDayOfMonth.isBefore(todayDate)"
          [class.today-success]="expectedHoursByDay[iDayOfMonth.date()] && todayDate.format('YYYY-MM-DD') === iDayOfMonth.format('YYYY-MM-DD') && minutesWorkedEachDay[indexOfDay] > 0 && minutesWorkedEachDay[indexOfDay] === expectedHoursByDay[iDayOfMonth.date()]?.expectedTime"
          [class.after]="iDayOfMonth.isAfter(todayDate)"
          [class.undertime]="expectedHoursByDay[iDayOfMonth.date()] && (!iDayOfMonth.isAfter(todayDate) && todayDate.format('YYYY-MM-DD') !== iDayOfMonth.format('YYYY-MM-DD') && minutesWorkedEachDay[indexOfDay] < expectedHoursByDay[iDayOfMonth.date()]?.expectedTime) || (todayDate.format('YYYY-MM-DD') === iDayOfMonth.format('YYYY-MM-DD') && minutesWorkedEachDay[indexOfDay] > 0 && minutesWorkedEachDay[indexOfDay] < expectedHoursByDay[iDayOfMonth.date()]?.expectedTime)"
          [class.overtime]="!iDayOfMonth.isAfter(todayDate) && expectedHoursByDay[iDayOfMonth.date()] && minutesWorkedEachDay[indexOfDay] > expectedHoursByDay[iDayOfMonth.date()]?.expectedTime"
          [class.non-working-day]="expectedHoursByDay[iDayOfMonth.date()] && expectedHoursByDay[iDayOfMonth.date()]?.expectedTime === 0"
        >
          <orgos-column-container centerColumns="true" class="pdap-day kenjo-p-20px">
            <orgos-column
              *ngIf="showShiftsByDay && showShiftsByDay[iDayOfMonth.toISOString()] !== undefined"
              size="0"
              class="kenjo-mr-10px"
            >
              <kenjo-icon
                *ngIf="showShiftsByDay[iDayOfMonth.toISOString()] === true"
                [size]="20"
                (click)="showShiftsByDay[iDayOfMonth.toISOString()] = false"
                class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer KKK"
                >keyboard_arrow_up</kenjo-icon
              >
              <kenjo-icon
                *ngIf="showShiftsByDay[iDayOfMonth.toISOString()] === false"
                [size]="20"
                (click)="showShiftsByDay[iDayOfMonth.toISOString()] = true"
                class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer XXX"
                >keyboard_arrow_down</kenjo-icon
              >
            </orgos-column>
            <orgos-column
              *ngIf="showShiftsByDay[iDayOfMonth.toISOString()] === undefined"
              size="0"
              class="kenjo-mr-10px kenjo-p-10px"
            ></orgos-column>
            <orgos-column
              *ngIf="expectedHoursByDay[iDayOfMonth.date()]"
              size="0"
              class="kenjo-mr-10px kenjo-font-size-30px"
              [class.pdap-non-working-day]="expectedHoursByDay[iDayOfMonth.date()]?.expectedTime === 0 || iDayOfMonth.isAfter(todayDate)"
            >
              <span class="kenjo-no-user-select">{{iDayOfMonth | date:'dd':'UTC'}}</span>
            </orgos-column>

            <orgos-column size="0" class="kenjo-mr-10px pdap-day-cell">
              <div
                *ngIf="expectedHoursByDay[iDayOfMonth.date()]"
                class="kenjo-no-user-select kenjo-font-size-16px kenjo-mb-5px"
                [class.pdap-non-working-day]="expectedHoursByDay[iDayOfMonth.date()]?.expectedTime === 0 || iDayOfMonth.isAfter(todayDate)"
              >
                {{getWeekDay(iDayOfMonth)}}
              </div>
              <div
                *ngIf="expectedHoursByDay[iDayOfMonth.date()]"
                class="kenjo-no-user-select kenjo-font-size-12px"
                [class.kenjo-font-color-light-text-757575]="expectedHoursByDay[iDayOfMonth.date()]?.expectedTime !== 0 && !iDayOfMonth.isAfter(todayDate)"
                [class.pdap-non-working-day]="expectedHoursByDay[iDayOfMonth.date()]?.expectedTime === 0 || iDayOfMonth.isAfter(todayDate)"
              >
                {{i18n.page.expected}}&nbsp;{{(expectedHoursByDay[iDayOfMonth.date()]?.expectedTime ? expectedHoursByDay[indexOfDay +
                1]?.expectedTime : 0) | duration}}
              </div>
            </orgos-column>
            <!-- Time off others dialog -->
            <orgos-column size="0" class="kenjo-mr-10px">
              <ng-container
                *ngIf="userAttendanceToTimeOffsOtherType[iDayOfMonth.toISOString()]?.length; else spaceWithoutOtherTimeOffTypes"
              >
                <kenjo-icon-highlight
                  #trigger="cdkOverlayOrigin"
                  icon="schedule_events"
                  semantic="neutral"
                  size="s"
                  [hoverable]="true"
                  cdkOverlayOrigin
                  (click)="eventDialogsInfo['time-off']?.showDialog === iDayOfMonth.toISOString() ? closeEventDialog('time-off') : toggleEventDialog(iDayOfMonth.toISOString(), 'time-off')"
                ></kenjo-icon-highlight>
                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="trigger"
                  [cdkConnectedOverlayOpen]="eventDialogsInfo['time-off']?.showDialog === iDayOfMonth.toISOString()"
                  (overlayOutsideClick)="closeEventDialog('time-off')"
                >
                  <kenjo-attendance-other-time-offs-dialog
                    class="kenjo-mv-5px"
                    *ngIf="eventDialogsInfo['time-off']?.showDialog === iDayOfMonth.toISOString()"
                    [timeOffs]="userAttendanceToTimeOffsOtherType[iDayOfMonth.toISOString()]"
                    (closeDialog)="closeEventDialog('time-off')"
                  ></kenjo-attendance-other-time-offs-dialog>
                </ng-template>
              </ng-container>
              <ng-template #spaceWithoutOtherTimeOffTypes>
                <div class="kenjo-mr-10px kenjo-ml-20px"></div>
              </ng-template>
            </orgos-column>
            <!-- Bank holidays dialog -->
            <orgos-column size="0" class="kenjo-mr-10px">
              <ng-container *ngIf="expectedHoursByDay[iDayOfMonth.date()]?.bankHolidays; else spaceWithoutBankHolidays">
                <kenjo-icon-highlight
                  #trigger="cdkOverlayOrigin"
                  icon="event"
                  semantic="neutral"
                  size="s"
                  [hoverable]="true"
                  cdkOverlayOrigin
                  (click)="eventDialogsInfo['holiday']?.showDialog === iDayOfMonth.toISOString() ? closeEventDialog('holiday') : toggleEventDialog(iDayOfMonth.toISOString(), 'holiday')"
                ></kenjo-icon-highlight>
                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="trigger"
                  [cdkConnectedOverlayOpen]="eventDialogsInfo['holiday']?.showDialog === iDayOfMonth.toISOString()"
                  (overlayOutsideClick)="closeEventDialog('holiday')"
                >
                  <kenjo-attendance-bank-holidays-dialog
                    class="kenjo-mv-5px"
                    *ngIf="eventDialogsInfo['holiday']?.showDialog === iDayOfMonth.toISOString()"
                    (closeDialog)="closeEventDialog('holiday')"
                    [bankHolidays]="expectedHoursByDay[iDayOfMonth.date()]?.bankHolidays"
                  ></kenjo-attendance-bank-holidays-dialog>
                </ng-template>
              </ng-container>
              <ng-template #spaceWithoutBankHolidays>
                <div class="kenjo-mr-10px kenjo-ml-20px"></div>
              </ng-template>
            </orgos-column>
            <orgos-column size="1" class="kenjo-mr-10px pdap-edit-entry-inputs">
              <div
                class="pdap-edit-entry-inputs__row"
                *ngFor="let iUserAttendance of allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()]; let indexOfTimeEntry = index"
                [matTooltip]="isEditable === false ? '' : (iDayOfMonth.isAfter(todayDate) && !allowEntriesInTheFuture) ? i18n.page.youCantEnterFutureAttendanceAheadOfTime : (timeEntryBeingEdited && (timeEntryBeingEdited.index !== indexOfTimeEntry || timeEntryBeingEdited.dayOfMonth.toISOString() !== iDayOfMonth.toISOString())) ? i18n.page.pleaseSaveTheOpenShiftBeforeEditing : null"
              >
                <!-- Time off requests in the time entries section -->
                <orgos-column-container *ngIf="iUserAttendance?.timeOffEntryInfo">
                  <orgos-column size="3">
                    <orgos-column-container class="pdap-time-off-request-box kenjo-ml-30px kenjo-mv-10px kenjo-pv-10px">
                      <orgos-column
                        size="1"
                        *ngIf="iUserAttendance.timeOffEntryInfo._policyType === 'Hour' && iUserAttendance.timeOffEntryInfo.duration !== 'FullDay'"
                        class="kenjo-pl-15px"
                      >
                        <orgos-column-container>
                          <orgos-column size="0" class="pdap-time-entry-arrow-icon">{{iUserAttendance.timeOffEntryInfo.from}}</orgos-column>
                          <orgos-column size="0" class="kenjo-ml-15px">
                            <kenjo-icon [size]="18" class="kenjo-mr-10px kenjo-font-color-light-text-757575 pdap-time-entry-arrow-icon">
                              arrow_right_alt
                            </kenjo-icon>
                          </orgos-column>
                          <orgos-column size="0" class="kenjo-ml-10px">{{iUserAttendance.timeOffEntryInfo.to}}</orgos-column>
                        </orgos-column-container>
                      </orgos-column>
                      <orgos-column
                        size="1"
                        class="kenjo-pl-15px"
                        *ngIf="iUserAttendance.timeOffEntryInfo._policyType !== 'Hour' || (iUserAttendance.timeOffEntryInfo._policyType === 'Hour' &&  iUserAttendance.timeOffEntryInfo.duration === 'FullDay')"
                      >
                        {{i18n.page['timeOffRequest' + iUserAttendance.timeOffEntryInfo.duration]}}
                      </orgos-column>
                      <orgos-column size="0" class="kenjo-ph-5px kenjo-ml-10px">
                        <kenjo-icon-highlight
                          icon="time_off_menu"
                          semantic="user"
                          size="s"
                          [userColor]="iUserAttendance.timeOffEntryInfo._timeOffTypeColor"
                        ></kenjo-icon-highlight>
                      </orgos-column>
                      <orgos-column size="1" class="kenjo-ml-10px">{{iUserAttendance.timeOffEntryInfo._timeOffTypeName}}</orgos-column>
                      <orgos-column size="0" class="kenjo-mr-15px"></orgos-column>
                    </orgos-column-container>
                  </orgos-column>
                  <orgos-column size="1"></orgos-column>
                </orgos-column-container>

                <div class="kenjo-mr-10px pdap-add-entry-button">
                  <kenjo-icon
                    *ngIf="fullDayTimeOff[iDayOfMonth.toISOString()] === false && ((allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false && isEditable === true && !savingTimeEntries && (canEdit || (!canEdit && someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false)) && indexOfTimeEntry === 0 && (!iDayOfMonth.isAfter(todayDate) || allowEntriesInTheFuture) && (!timeEntryBeingEdited || timeEntryBeingEdited && (timeEntryBeingEdited.dayOfMonth.toISOString() === iDayOfMonth.toISOString())) && !entryHasErrors && (!timeEntryBeingEdited || timeEntryBeingEdited && (timeEntryBeingEdited.dayOfMonth.toISOString() === iDayOfMonth.toISOString() && timeEntryBeingEdited.index === indexOfTimeEntry)) && minutesWorkedEachDay[indexOfDay] < MAX_HOURS_PER_DAY)
                    ||
                    ((allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && canApprove && canEdit)))"
                    [size]="20"
                    (click)="addTimeEntry(iDayOfMonth)"
                    class="kenjo-font-color-primary-5993e3"
                  >
                    add_circle
                  </kenjo-icon>
                </div>

                <div
                  class="pdap-edit-entry-inputs__top"
                  *ngIf="iUserAttendance._deleted === false && fullDayTimeOff[iDayOfMonth.toISOString()] === false || (fullDayTimeOff[iDayOfMonth.toISOString()] === true && iUserAttendance.startTime && iUserAttendance.endTime && !iUserAttendance.timeOffEntryInfo)"
                >
                  <orgos-input-simple-time
                    class="kenjo-ml-30px kenjo-mr-10px"
                    [value]="iUserAttendance.startTime"
                    (valueChange)="onInputTimeChange($event, iDayOfMonth, indexOfTimeEntry, iUserAttendance, indexOfDay,
                    'startTime')"
                    [label]="i18n.userAttendanceCollection.startTime"
                    [keepOriginalMinWidth]="true"
                    [enableClearButton]="true"
                    [maskPlaceholder]="copiedTimeEntry && copiedTimeEntryPlaceholder?.day?.toISOString() === iDayOfMonth.toISOString() && copiedTimeEntryPlaceholder?.index === indexOfTimeEntry ? parseTimeEntry(copiedTimeEntry.startTime, 'startTime', iDayOfMonth, indexOfTimeEntry) : null"
                    [readOnly]="
                      isEditable === false ||
                      (someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved && (!canApprove || !canEdit)) ||
                      (!canEdit && someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved) ||
                      iUserAttendance._timeOffRequestId ||
                      (iDayOfMonth.isAfter(todayDate) && !allowEntriesInTheFuture) ||
                      (timeEntryBeingEdited && (timeEntryBeingEdited.index !== indexOfTimeEntry || timeEntryBeingEdited.dayOfMonth.toISOString() !== iDayOfMonth.toISOString())) ||
                      (expectedHoursByDay[iDayOfMonth.date()]?.bankHolidays && attendancePolicy?.restrictCheckIn?.isActive && !['admin', 'hr-admin'].includes(loggedUser.profileKey)) ||
                      fullDayTimeOff[iDayOfMonth.toISOString()]"
                    [forceError]="(timeEntryErrors[iDayOfMonth.toISOString()] && timeEntryErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && timeEntryErrors[iDayOfMonth.toISOString()].missingStartTime) || (timeEntryInlineErrors[iDayOfMonth.toISOString()] && timeEntryInlineErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && (timeEntryInlineErrors[iDayOfMonth.toISOString()].overlappingStartTime || timeEntryInlineErrors[iDayOfMonth.toISOString()].generalError || timeEntryInlineErrors[iDayOfMonth.toISOString()]?.restrictCheckIn)) || timeEntryInlineErrors[iDayOfMonth.toISOString()]?.isOpenShift || timeEntryInlineErrors[iDayOfMonth.toISOString()]?.isFutureEntry"
                    [matTooltip]="(someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved && (!canApprove || !canEdit)) || (!canEdit && someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved) ? (i18n.page.approvedLabel | lowercase) : ''"
                    matTooltipPosition="left"
                  >
                  </orgos-input-simple-time>
                  <kenjo-icon [size]="18" class="kenjo-mr-10px kenjo-font-color-light-text-757575 pdap-time-entry-arrow-icon">
                    arrow_right_alt
                  </kenjo-icon>
                  <orgos-input-simple-time
                    class="kenjo-mr-10px"
                    [value]="iUserAttendance.endTime"
                    (valueChange)="onInputTimeChange($event, iDayOfMonth, indexOfTimeEntry, iUserAttendance, indexOfDay,
                    'endTime')"
                    [label]="i18n.userAttendanceCollection.endTime"
                    [keepOriginalMinWidth]="true"
                    [enableClearButton]="true"
                    [maskPlaceholder]="copiedTimeEntry && copiedTimeEntryPlaceholder?.day?.toISOString() === iDayOfMonth.toISOString() && copiedTimeEntryPlaceholder?.index === indexOfTimeEntry ? parseTimeEntry(copiedTimeEntry.endTime, 'endTime', iDayOfMonth, indexOfTimeEntry) : null"
                    [readOnly]="
                      isEditable === false ||
                      (someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved && (!canApprove || !canEdit)) ||
                      (!canEdit && someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved) || iUserAttendance._timeOffRequestId ||
                      (iDayOfMonth.isAfter(todayDate) && !allowEntriesInTheFuture) ||
                      (timeEntryBeingEdited && (timeEntryBeingEdited.index !== indexOfTimeEntry || timeEntryBeingEdited.dayOfMonth.toISOString() !== iDayOfMonth.toISOString())) ||
                      (expectedHoursByDay[iDayOfMonth.date()]?.bankHolidays && attendancePolicy?.restrictCheckIn?.isActive && !['admin', 'hr-admin'].includes(loggedUser.profileKey)) ||
                      fullDayTimeOff[iDayOfMonth.toISOString()]"
                    [forceError]="(timeEntryErrors[iDayOfMonth.toISOString()] && timeEntryErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && timeEntryErrors[iDayOfMonth.toISOString()].missingEndTime) || (timeEntryInlineErrors[iDayOfMonth.toISOString()] && timeEntryInlineErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && (timeEntryInlineErrors[iDayOfMonth.toISOString()].overlappingEndTime || timeEntryInlineErrors[iDayOfMonth.toISOString()].generalError || timeEntryInlineErrors[iDayOfMonth.toISOString()]?.restrictCheckIn)) ||timeEntryInlineErrors[iDayOfMonth.toISOString()]?.isOpenShift || timeEntryInlineErrors[iDayOfMonth.toISOString()]?.isFutureEntry"
                    [matTooltip]="(someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved && (!canApprove || !canEdit)) || (!canEdit && someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved) ? (i18n.page.approvedLabel | lowercase) : ''"
                    matTooltipPosition="right"
                  >
                  </orgos-input-simple-time>

                  <!-- Old breaks -->
                  <div
                    #breaksContainer
                    *ngIf="iUserAttendance && !iUserAttendance.breaks && iUserAttendance.breakTime > 0"
                    class="pdap-breaks-container kenjo-mr-10px"
                  >
                    <div
                      #breaks
                      class="pdap-old-breaks"
                      [class.hidden]="iUserAttendance._timeOffRequestId"
                      (click)="openBreaksDialog(breaksContainer, breaks, iUserAttendance, iDayOfMonth, indexOfTimeEntry, indexOfDay, isEditable === false || (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && (!canApprove || !canEdit)) || (!canEdit && allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true) || iUserAttendance._timeOffRequestId || (iDayOfMonth.isAfter(todayDate) && !allowEntriesInTheFuture) || (timeEntryBeingEdited && (timeEntryBeingEdited.index !== indexOfTimeEntry || timeEntryBeingEdited.dayOfMonth.toISOString() !== iDayOfMonth.toISOString())), $event)"
                    >
                      <div>{{ i18n.page.break }}</div>
                      <div *ngIf="iUserAttendance.breakTime > 0" class="kenjo-ml-10px">
                        {{ iUserAttendance.breakTime | duration : false : true : true }}
                      </div>
                    </div>
                    <div></div>
                  </div>

                  <!-- New breaks -->
                  <div
                    #breaksContainer
                    *ngIf="iUserAttendance.breaks || !iUserAttendance.breakTime || iUserAttendance.breakTime === 0"
                    class="pdap-breaks-container kenjo-mr-10px"
                  >
                    <div
                      #breaks
                      class="pdap-new-breaks"
                      [class.hidden]="iUserAttendance._timeOffRequestId"
                      [class.disabled]="(expectedHoursByDay[indexOfDay + 1]?.bankHolidays && attendancePolicy.restrictCheckIn.isActive && !['admin', 'hr-admin'].includes(loggedUser.profileKey)) || isRestrictCheckIn || futureEntriesErrors?.isFutureEntry || futureEntriesErrors?.isOpenShift || fullDayTimeOff[iDayOfMonth.toISOString()]"
                      (click)="openBreaksDialog(breaksContainer, breaks, iUserAttendance, iDayOfMonth, indexOfTimeEntry, indexOfDay, isEditable === false || (someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved && (!canApprove || !canEdit)) || (!canEdit && someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && iUserAttendance._approved) || iUserAttendance._timeOffRequestId || (iDayOfMonth.isAfter(todayDate) && !allowEntriesInTheFuture) || (timeEntryBeingEdited && (timeEntryBeingEdited.index !== indexOfTimeEntry || timeEntryBeingEdited.dayOfMonth.toISOString() !== iDayOfMonth.toISOString())), $event)"
                    >
                      <div class="pdap-total-breaks">{{ iUserAttendance?.breaks?.length || 0 }}</div>
                      <div class="kenjo-ml-10px">{{ i18n.page.breaks }}</div>
                      <div *ngIf="iUserAttendance?.breaks?.length > 0" class="kenjo-ml-10px">
                        {{ iUserAttendance.breakTime | duration : false : true : true }}
                      </div>
                    </div>
                    <div></div>
                  </div>

                  <div class="kenjo-mr-10px pdap-comment-field">
                    <orgos-input-simple-text
                      [value]="iUserAttendance._timeOffRequestId && iUserAttendance._allowanceType ? (i18n.page.hourlyAbsenceTitle + i18n.page['label' + iUserAttendance._allowanceType] + ' / ' + iUserAttendance.comment) : iUserAttendance.comment"
                      (valueChange)="updateTimeEntryBeingEdited({ dayOfMonth: iDayOfMonth, index: indexOfTimeEntry, iUserAttendance: iUserAttendance, type: 'comment', value: $event });isTimeEntryEditable(iDayOfMonth, indexOfTimeEntry);
                      canApproveOrEdit(iDayOfMonth);"
                      [label]="i18n.userAttendanceCollection.comment"
                      class="kenjo-block"
                      [readOnly]="isEditable === false || (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && (!canApprove || !canEdit)) || (!canEdit && allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true) || iUserAttendance._timeOffRequestId || (iDayOfMonth.isAfter(todayDate) && !allowEntriesInTheFuture) || (timeEntryBeingEdited && (timeEntryBeingEdited.index !== indexOfTimeEntry || timeEntryBeingEdited.dayOfMonth.toISOString() !== iDayOfMonth.toISOString())) || fullDayTimeOff[iDayOfMonth.toISOString()]"
                    >
                    </orgos-input-simple-text>
                  </div>
                  <div class="pdap-multi-action-buttons">
                    <span
                      class="pdap-action-fixed-button kenjo-pr-5px"
                      *ngIf="useAttendanceCategories && iUserAttendance.attendanceCategoryId && !fullDayTimeOff[iDayOfMonth.toISOString()]"
                    >
                      <kenjo-icon
                        matTooltipPosition="above"
                        [matTooltip]="iUserAttendance.attendanceSubCategoryId ? categoryIdToTooltip[iUserAttendance.attendanceSubCategoryId] : categoryIdToTooltip[iUserAttendance.attendanceCategoryId]"
                        [size]="18"
                        (click)="editAttendanceCategory(iUserAttendance)"
                        [style.color]="colors[categoryIdToCategory[iUserAttendance.attendanceCategoryId].color]"
                      >
                        bookmark
                      </kenjo-icon>
                    </span>
                    <span
                      class="pdap-action-entry-button pdap-action-entry-button--category"
                      *ngIf="(isEditable === true && useAttendanceCategories && !iUserAttendance.attendanceCategoryId && listCategoriesForThisUser?.length && (!iDayOfMonth.isAfter(todayDate) || allowEntriesInTheFuture))
                    &&
                      (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false || (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && canApprove && canEdit)) 
                    &&
                      !fullDayTimeOff[iDayOfMonth.toISOString()]"
                    >
                      <kenjo-icon
                        [matTooltip]="i18n.page.addCategoryDialogTitle"
                        matTooltipPosition="above"
                        [size]="16"
                        (click)="editAttendanceCategory(iUserAttendance)"
                        class="kenjo-font-color-primary-5993e3"
                      >
                        bookmark
                      </kenjo-icon>
                    </span>
                    <span
                      class="pdap-action-entry-button pdap-action-entry-button--copy"
                      *ngIf="(isEditable === true && (iDayOfMonth.isSameOrBefore(todayDate) || allowEntriesInTheFuture) && ((allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()][indexOfTimeEntry].startTime !== undefined && allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()][indexOfTimeEntry].startTime !== null) && (allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()][indexOfTimeEntry].endTime !== undefined && allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()][indexOfTimeEntry].endTime !== null)) && (!timeEntryBeingEdited || timeEntryBeingEdited && (timeEntryBeingEdited.dayOfMonth.toISOString() !== iDayOfMonth.toISOString() || (timeEntryBeingEdited.dayOfMonth.toISOString() === iDayOfMonth.toISOString() && timeEntryBeingEdited.index !== indexOfTimeEntry))))
                    &&
                      (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false || (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && canApprove && canEdit))"
                    >
                      <kenjo-icon
                        [matTooltip]="i18n.page.copy"
                        matTooltipPosition="above"
                        [size]="18"
                        (click)="copyTimeEntry(iDayOfMonth, indexOfTimeEntry)"
                        class="kenjo-font-color-primary-5993e3"
                      >
                        copy</kenjo-icon
                      >
                    </span>
                    <span
                      class="pdap-action-entry-button pdap-action-entry-button--paste"
                      (mouseover)="toggleMaskedPlaceholder(iDayOfMonth, indexOfTimeEntry, true)"
                      (mouseout)="toggleMaskedPlaceholder(iDayOfMonth, indexOfTimeEntry, false)"
                      *ngIf="(isEditable === true && copiedTimeEntry && (iDayOfMonth.isSameOrBefore(todayDate) || allowEntriesInTheFuture) && !iUserAttendance._timeOffRequestId && (!timeEntryBeingEdited || timeEntryBeingEdited && (timeEntryBeingEdited.dayOfMonth.toISOString() === iDayOfMonth.toISOString() && timeEntryBeingEdited.index === indexOfTimeEntry)))
                      &&
                      (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false || (allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true && canApprove && canEdit))"
                    >
                      <kenjo-icon
                        [matTooltip]="i18n.page.paste"
                        matTooltipPosition="above"
                        [size]="18"
                        (click)="pasteTimeEntry(iDayOfMonth, indexOfDay, indexOfTimeEntry)"
                        class="kenjo-font-color-primary-5993e3"
                        >paste</kenjo-icon
                      >
                    </span>
                    <span class="pdap-action-entry-button pdap-action-entry-button--delete">
                      <kenjo-icon
                        matTooltipPosition="above"
                        [matTooltip]="i18n.page.delete"
                        *ngIf="!iUserAttendance._timeOffRequestId &&
                        isEditable === true && !savingTimeEntries &&
                        daysWithAtLeastOneEntry[iDayOfMonth.toISOString()] &&
                        (canEdit || (!canEdit && someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false)) &&
                        (!iDayOfMonth.isAfter(todayDate) || allowEntriesInTheFuture === true) &&
                        (!timeEntryBeingEdited || timeEntryBeingEdited && (timeEntryBeingEdited.dayOfMonth.toISOString() === iDayOfMonth.toISOString() && timeEntryBeingEdited.index === indexOfTimeEntry))"
                        [size]="20"
                        (click)="deleteTimeEntry(iDayOfMonth, indexOfTimeEntry)"
                        class="kenjo-font-color-danger-ff5757"
                      >
                        delete</kenjo-icon
                      >
                    </span>
                  </div>
                  <orgos-button-raised
                    class="pdap-save-button kenjo-ml-10px"
                    [disabled]="savingTimeEntries"
                    *ngIf="isTimeEntryEditableFlag?.[iDayOfMonth.toISOString()]?.[indexOfTimeEntry] && canApproveOrEditFlag?.[iDayOfMonth.toISOString()]"
                    (click)="onSaveAttendance(iDayOfMonth)"
                  >
                    {{i18n.page.save}}
                  </orgos-button-raised>
                </div>
                <div
                  class="pdap-edit-entry-inputs__bottom"
                  *ngIf="iUserAttendance._deleted === false && fullDayTimeOff[iDayOfMonth.toISOString()] === false"
                >
                  <div
                    class="pdap-edit-message"
                    *ngIf="timeEntryErrors[iDayOfMonth.toISOString()] && timeEntryErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && timeEntryErrors[iDayOfMonth.toISOString()].mustSaveEditedShift && !timeEntryErrors[iDayOfMonth.toISOString()].missingStartTime && !timeEntryErrors[iDayOfMonth.toISOString()].missingEndTime"
                  >
                    <kenjo-icon [size]="20" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
                    <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-ml-10px">
                      {{i18n.page.pleaseSaveBeforeAdding}}
                    </div>
                  </div>
                  <div
                    class="pdap-edit-message"
                    *ngIf="timeEntryErrors[iDayOfMonth.toISOString()] && timeEntryErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && timeEntryErrors[iDayOfMonth.toISOString()].missingStartTime && !timeEntryErrors[iDayOfMonth.toISOString()].missingEntireShift"
                  >
                    <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.toAddANewShiftPleaseEnterStartTime}}</div>
                  </div>
                  <div
                    class="pdap-edit-message"
                    *ngIf="timeEntryErrors[iDayOfMonth.toISOString()] && timeEntryErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && timeEntryErrors[iDayOfMonth.toISOString()].missingEndTime && !timeEntryErrors[iDayOfMonth.toISOString()].missingEntireShift"
                  >
                    <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.toAddANewShiftPleaseEnterEndTime}}</div>
                  </div>
                  <div
                    class="pdap-edit-message"
                    *ngIf="timeEntryErrors[iDayOfMonth.toISOString()] && timeEntryErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && timeEntryErrors[iDayOfMonth.toISOString()].missingEntireShift"
                  >
                    <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">
                      {{i18n.page.toAddANewShiftPleaseCompleteOngoingOne}}
                    </div>
                  </div>
                  <div
                    class="pdap-edit-message"
                    *ngIf="timeEntryErrors[iDayOfMonth.toISOString()] && timeEntryErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry && timeEntryErrors[iDayOfMonth.toISOString()].mustSaveEditedShift && allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()].length > 1"
                  >
                    <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.pleaseSaveTheOpenShiftBeforeEditing}}</div>
                  </div>
                  <ng-container
                    *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()] && timeEntryInlineErrors[iDayOfMonth.toISOString()].index === indexOfTimeEntry"
                  >
                    <div
                      class="pdap-edit-message"
                      *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].overlappingStartTime || timeEntryInlineErrors[iDayOfMonth.toISOString()].overlappingEndTime && allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()].length > 1"
                    >
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.overlappingEntry}}</div>
                    </div>
                    <div class="pdap-edit-message" *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].breakTimeCannotBeLonger">
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.breakAndShiftConflict}}</div>
                    </div>
                    <div class="pdap-edit-message" *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].isFutureEntry">
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.futureEntriesNotAllowed}}</div>
                    </div>
                    <div class="pdap-edit-message" *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].isOpenShift">
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.openShiftNotAllowed}}</div>
                    </div>
                    <div
                      class="pdap-edit-message"
                      *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()]?.restrictCheckIn && !nextShiftDate"
                    >
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">
                        {{i18n.page.restrictCheckInConflict | i18nData: { hourLimit: restrictTimeLimit } }}
                      </div>
                    </div>
                    <div
                      class="pdap-edit-message"
                      *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()]?.restrictCheckIn && nextShiftDate"
                    >
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">
                        {{i18n.page.restrictCheckInWithDateConflict | i18nData: { hourLimit: restrictTimeLimit, dateLimit: nextShiftDate }
                        }}
                      </div>
                    </div>
                    <div class="pdap-edit-message" *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].breakAndShiftConflict">
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.breakAndShiftConflict}}</div>
                    </div>
                    <div class="pdap-edit-message" *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].startTimeEqualsEndTime">
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">{{i18n.page.endTimeMustBeAfterStartTime}}</div>
                    </div>
                    <div class="pdap-edit-message" *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].maxHoursPerShiftReached">
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">
                        {{i18n.page.maxShiftLength | i18nData: ({ hoursAndMinutes: (MAX_SHIFT_LENGTH | duration) })}}
                      </div>
                    </div>
                    <div class="pdap-edit-message" *ngIf="timeEntryInlineErrors[iDayOfMonth.toISOString()].maxHoursPerDayReached">
                      <div class="kenjo-font-size-12px kenjo-font-color-danger-ff5757">
                        {{i18n.page.maxHoursPerDay | i18nData: ({ hoursAndMinutes: (MAX_HOURS_PER_DAY | duration) })}}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </orgos-column>
            <orgos-column size="0" class="kenjo-mr-5px" *ngIf="attendanceGeolocationForCurrentMonth">
              <kenjo-icon
                *ngIf="attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()]"
                class="pdap-geolocation-icon kenjo-cursor-pointer"
                [ngClass]="{
                      '-green': attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()] === 'GREEN',
                      '-yellow': attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()] === 'YELLOW',
                      '-grey': attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()] === 'GREY'
                    }"
                [size]="24"
                [matTooltip]="
                            attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()] === 'GREEN' ? i18n.page.trackedSummaryFromAllowedLocation :
                            (
                                attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()] === 'YELLOW' ?
                                i18n.page.trackedSummaryFromUnlistedLocation : i18n.page.trackedSummaryFromNotLocation
                            )
                            "
                >location_on
              </kenjo-icon>
            </orgos-column>
            <orgos-column
              size="0"
              [class.kenjo-font-color-disabled-c4c4c4]="iDayOfMonth.isAfter(todayDate) || fullDayTimeOff[iDayOfMonth.toISOString()]"
              ><kenjo-button-semantic
                (menuOpened)="conflictMenuToggle(indexOfDay, true)"
                (menuClosed)="conflictMenuToggle(indexOfDay, false)"
                [matMenuTriggerFor]="conflictsMenu"
                [size]="'M'"
                [type]="'danger'"
                [isVisible]="attendanceConflictsPerDay[indexOfDay]?.hasConflicts"
                [icon]="'error_filled'"
                >{{ minutesWorkedEachDay[indexOfDay] | duration }}</kenjo-button-semantic
              >
              <div *ngIf="!attendanceConflictsPerDay[indexOfDay]?.hasConflicts" class="pdap-working-time">
                {{ minutesWorkedEachDay[indexOfDay] | duration }}
              </div></orgos-column
            >
            <mat-menu class="kenjo-border-radius-10px" #conflictsMenu="matMenu">
              <div class="pdap-conflicts-menu">
                <kenjo-icon class="pdap-conflicts-menu-icon">clear</kenjo-icon>
                <p class="kenjo-font-size-18px">{{i18n.page.conflicts}}</p>
                <div class="kenjo-mt-15px" *ngIf="attendanceConflictsPerDay[indexOfDay]?.overlappingConflict">
                  <p>{{ i18n.page.overlappingEntries }}</p>
                  <div class="pdap-conflicts-menu-chip" *ngIf="attendanceConflictsPerDay[indexOfDay]?.overlappingPublicHolidays">
                    {{ i18n.page.publicHolidays }}
                    <kenjo-icon
                      *ngIf="attendanceConflictsPerDay[indexOfDay]?.configuration?.isPublicHolidayPastValue"
                      class="kenjo-cursor-pointer"
                      [matTooltip]="i18n.page.conflictConfigHistoryOff"
                      >history</kenjo-icon
                    >
                  </div>
                  <div class="pdap-conflicts-menu-chip" *ngIf="attendanceConflictsPerDay[indexOfDay]?.overlappingNonWorkingDay">
                    {{i18n.page.nonWorkingDays }}
                    <kenjo-icon
                      *ngIf="attendanceConflictsPerDay[indexOfDay]?.configuration?.isNonWorkingDayPastValue"
                      class="kenjo-cursor-pointer"
                      [matTooltip]="i18n.page.conflictConfigHistoryOff"
                      >history</kenjo-icon
                    >
                  </div>
                </div>
                <div class="kenjo-mt-15px" *ngIf="attendanceConflictsPerDay[indexOfDay]?.missingTime">
                  <p>{{ i18n.page.missingTime }}</p>
                  <div class="pdap-conflicts-menu-chip" *ngIf="attendanceConflictsPerDay[indexOfDay]?.missingFullEntry">
                    {{ i18n.page.missingFullEntry }}
                    <kenjo-icon
                      *ngIf="attendanceConflictsPerDay[indexOfDay]?.configuration?.isMissingFullEntryPastValue"
                      class="kenjo-cursor-pointer"
                      [matTooltip]="i18n.page.conflictConfigHistoryOff"
                      >history</kenjo-icon
                    >
                  </div>
                  <div class="pdap-conflicts-menu-chip" *ngIf="attendanceConflictsPerDay[indexOfDay]?.entryNotCompleted">
                    {{ i18n.page.entryNotCompleted }}
                    <kenjo-icon
                      *ngIf="attendanceConflictsPerDay[indexOfDay]?.configuration?.isEntryNotCompletedPastValue"
                      class="kenjo-cursor-pointer"
                      [matTooltip]="i18n.page.conflictConfigHistoryOff"
                      >history</kenjo-icon
                    >
                  </div>
                </div>
                <div class="kenjo-mt-15px" *ngIf="attendanceConflictsPerDay[indexOfDay]?.maxDailyHours">
                  <p>{{ i18n.page.maximumHours }}</p>
                  <div class="pdap-conflicts-menu-chip">
                    {{ attendanceConflictsPerDay[indexOfDay]?.configuration?.maxHoursValue || attendancePolicy?.limitDailyHours?.limit |
                    duration }} {{ i18n.page.limitExceeded }}
                    <kenjo-icon
                      *ngIf="attendanceConflictsPerDay[indexOfDay]?.configuration?.isMaxHoursPastValue"
                      class="kenjo-cursor-pointer"
                      [matTooltip]="attendancePolicy.limitDailyHours?.isActive && attendancePolicy.limitDailyHours?.conflicts ? (i18n.page.conflictConfigHistory | i18nData: { timeLimit: attendancePolicy?.limitDailyHours?.limit | duration }) : i18n.page.conflictConfigHistoryOff"
                      >history</kenjo-icon
                    >
                  </div>
                </div>
                <div class="kenjo-mt-15px" *ngIf="attendanceConflictsPerDay[indexOfDay]?.minBreakTime">
                  <p>{{i18n.page.breakScheme}}</p>
                  <div class="pdap-conflicts-menu-chip">
                    {{ attendanceConflictsPerDay[indexOfDay]?.configuration?.minBreakValue | duration:false:true:true:true }} {{
                    i18n.page.breakNotReached }}
                    <kenjo-icon
                      *ngIf="attendanceConflictsPerDay[indexOfDay]?.configuration?.isMinBreakPastValue"
                      class="kenjo-cursor-pointer"
                      [matTooltip]="attendancePolicy?.breakReminder?.conflicts && attendancePolicy?.breakReminder?.reminders?.length ? (i18n.page.conflictConfigHistory | i18nData: { timeLimit: minBreakEachDay[indexOfDay] | duration:false:true:true:true }) : i18n.page.conflictConfigHistoryOff"
                      >history</kenjo-icon
                    >
                  </div>
                </div>
                <p class="kenjo-mt-20px kenjo-font-size-12px kenjo-font-color-archived-8f8f8f" *ngIf="!isEditable">
                  {{i18n.page.solveConflicts}}
                </p>
                <kenjo-button-flat
                  *ngIf="canApprove === true &&
                  allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()]?.length > 0 &&
                  allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()][0]?._changesTracking?.length > 0 &&
                  attendanceConflictsPerDay[indexOfDay]?.hasConflicts"
                  class="pdap-ignore-button"
                  (click)="ignoreConflicts(iDayOfMonth.toString()); logIgnoreConflicts('Ignore Conflict Button')"
                  >{{i18n.page.ignoreConflicts}}</kenjo-button-flat
                >
              </div>
            </mat-menu>
            <orgos-column size="0" class="pdap-approve-button">
              <orgos-action
                *ngIf="(canApprove === true &&
                        !attendanceOfDayChangedMap[iDayOfMonth.toISOString()] &&
                        !attendanceToDeleteMap[iDayOfMonth.toISOString()] &&
                        allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false &&
                        isAttendanceDayComplete[iDayOfMonth.toISOString()] &&
                        allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()] &&
                        allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()].length > 0 &&
                        minutesWorkedEachDay[indexOfDay] > 0)"
                icon="check_circle"
                iconSize="normal"
                (click)="approveDay(iDayOfMonth)"
              >
                {{ i18n.page.approveActionLabel }}
              </orgos-action>
              <orgos-chip
                *ngIf="!attendanceOfDayChangedMap[iDayOfMonth.toISOString()] && !attendanceToDeleteMap[iDayOfMonth.toISOString()] && allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === true"
                color="Success"
                >{{ i18n.page.approvedLabel }}</orgos-chip
              >
            </orgos-column>
          </orgos-column-container>
          <div *ngIf="showShiftsByDay[iDayOfMonth.toISOString()] === true" class="kenjo-mh-40px kenjo-pb-5px">
            <div
              *ngFor="let iUserAttendance of filterReportedShifts(allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()]); let indexOfTimeEntry = index"
            >
              <div
                *ngIf="iUserAttendance._deleted === false && iUserAttendance._changesTracking && iUserAttendance._changesTracking.length > 0"
                class="kenjo-mb-20px kenjo-mh-10px pdap-shift-box"
              >
                <div class="kenjo-font-color-text-333333 kenjo-font-size-16px kenjo-m-20px">
                  {{i18n.page.shiftHeaderLabel}} {{indexOfTimeEntry+1}}
                </div>
                <div *ngFor="let changesTrack of iUserAttendance._changesTracking; let indexOfTimeEntry = index" class="kenjo-m-20px">
                  <orgos-column-container
                    centerColumns="true"
                    *ngIf="!(changesTrack.operationType === 'Update' && changesTrack.fieldChanged === '_approved' && changesTrack.newValue === 'false') && changesTrack.fieldChanged !== 'breakTime'"
                  >
                    <orgos-avatar
                      orgosColumn="0"
                      *ngIf="!changesTrack.automation && userPersonalById[changesTrack._createdById]"
                      [avatarName]="userPersonalById[changesTrack._createdById].displayName"
                      [photoUrl]="userPersonalById[changesTrack._createdById]._photo?._url"
                      size="small"
                    ></orgos-avatar>
                    <orgos-avatar
                      orgosColumn="0"
                      *ngIf="changesTrack.automation"
                      [avatarName]="i18n.page.automationUserName"
                      photoUrl="/assets/images/kenjoLogoBlack.svg"
                      size="small"
                    ></orgos-avatar>
                    <orgos-column size="0">
                      <orgos-column-container>
                        <!-- Create shift -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Create'"
                          class="kenjo-pl-10px"
                        >
                          <div *ngIf="changesTrack.automation === undefined">
                            {{ i18n.page.createAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                            || i18n.page.apiUser } }}
                          </div>
                          <div
                            *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                          >
                            {{ i18n.page.createShiftSplitEntriesAutomationMessage }}
                          </div>
                        </orgos-column>

                        <!-- Start time set -->
                        <orgos-column
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'startTime' && !changesTrack.oldValue"
                          size="0"
                          class="kenjo-pl-10px"
                        >
                          <div *ngIf="changesTrack.automation === undefined">
                            {{i18n.page.setStartHourAuditMessage | i18nData: { userName:
                            userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                          <div
                            *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                          >
                            {{ i18n.page.updateStartHourSplitEntriesAutomationMessage | i18nData: { time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                        </orgos-column>

                        <!-- Ignored conflicts -->
                        <orgos-column
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'ignore-conflicts' && changesTrack.fieldChanged?.length"
                          size="0"
                          class="kenjo-pl-10px"
                        >
                          <div>
                            {{ i18n.page.timeTrackingIgnoredConflicts | i18nData: { userName:
                            userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser } }}
                            <ng-container *ngFor="let conflict of changesTrack.conflictConfiguration; let isLast = last">
                              <span *ngIf="conflict?.type === CONFLICT_TYPES.MAX_DAILY_HOURS">
                                {{ conflict?.value | duration }} {{ i18n.page.limitExceeded }}<span *ngIf="!isLast">,</span
                                ><span *ngIf="isLast">.</span>
                              </span>
                              <span *ngIf="conflict?.type === CONFLICT_TYPES.MIN_BREAK_TIME">
                                {{ conflict?.value | duration:false:true:true:true }} {{i18n.page.breakNotReached}}<span *ngIf="!isLast"
                                  >,</span
                                ><span *ngIf="isLast">.</span>
                              </span>
                              <span *ngIf="conflict?.type === CONFLICT_TYPES.OVERLAPPING_PUBLIC_HOLIDAYS">
                                {{ i18n.page.publicHolidays.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                              </span>
                              <span *ngIf="conflict?.type === CONFLICT_TYPES.OVERLAPPING_NON_WORKING_DAYS">
                                {{ i18n.page.nonWorkingDays.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                              </span>
                              <span *ngIf="conflict?.type === CONFLICT_TYPES.MISSING_FULL_ENTRY">
                                {{ i18n.page.missingFullEntry.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                              </span>
                              <span *ngIf="conflict?.type === CONFLICT_TYPES.ENTRY_NOT_COMPLETED">
                                {{ i18n.page.entryNotCompleted.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                              </span>
                            </ng-container>
                          </div>
                        </orgos-column>

                        <!-- Start time updated -->
                        <orgos-column
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'startTime' && changesTrack.oldValue"
                          size="0"
                          class="kenjo-pl-10px"
                        >
                          <div *ngIf="changesTrack.automation === undefined">
                            {{ i18n.page.updateStartHourAuditMessage | i18nData: { userName:
                            userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                          <div
                            *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                          >
                            {{ i18n.page.updateStartHourSplitEntriesAutomationMessage | i18nData: { time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                        </orgos-column>

                        <!-- End time set -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'endTime' && !changesTrack.oldValue"
                          class="kenjo-pl-10px"
                        >
                          <div *ngIf="changesTrack.automation === undefined">
                            {{ i18n.page.setEndHourAuditMessage | i18nData: { userName:
                            userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                          <div *ngIf="changesTrack.automation?.key === ATTENDANCE_AUTOMATION_MAX_HOURS">
                            {{ i18n.page.updateEndHourMaxHoursPerDayAutomationMessage | i18nData: { time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                          <div
                            *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                          >
                            {{ i18n.page.updateEndHourSplitEntriesAutomationMessage | i18nData: { time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                        </orgos-column>

                        <!-- End time updated -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'endTime' && changesTrack.oldValue"
                          class="kenjo-pl-10px"
                        >
                          <div *ngIf="changesTrack.automation === undefined">
                            {{ i18n.page.updateEndHourAuditMessage | i18nData: { userName:
                            userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                          <div *ngIf="changesTrack.automation?.key === ATTENDANCE_AUTOMATION_MAX_HOURS">
                            {{ i18n.page.updateEndHourMaxHoursPerDayAutomationMessage | i18nData: { time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                          <div
                            *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                          >
                            {{ i18n.page.updateEndHourSplitEntriesAutomationMessage | i18nData: { time:
                            convertMinutesToTime(changesTrack.newValue) } }}
                          </div>
                        </orgos-column>

                        <!-- Comment set -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'comment' && !changesTrack.oldValue"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.setCommentAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, newComment: changesTrack.newValue }
                          }}
                        </orgos-column>

                        <!-- Comment updated -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'comment'  && changesTrack.oldValue"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.updateCommentAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, newComment: changesTrack.newValue }
                          }}
                        </orgos-column>

                        <!-- Approved -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === '_approved'"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.approvedAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                          || i18n.page.apiUser } }}
                        </orgos-column>

                        <!-- Deleted -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === '_deleted'"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.deletedAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                          || i18n.page.apiUser } }}
                        </orgos-column>

                        <!-- Complete break created -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue === '' && changesTrack.newEnd && !changesTrack.automation"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.createBreakAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, start:
                          convertMinutesToTime(changesTrack.newStart), end: convertMinutesToTime(changesTrack.newEnd) } }}
                        </orgos-column>

                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue === '' && changesTrack.newEnd && changesTrack?.automation?.key === ATTENDANCE_AUTOMATION_AUTO_DEDUCT"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.updateHoursAutoDeductBreaks | i18nData: { startBreak: convertMinutesToTime(changesTrack.newStart),
                          endBreak: convertMinutesToTime(changesTrack.newEnd) } }}.
                        </orgos-column>

                        <!-- Break without end created -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue === '' && !changesTrack.newEnd"
                          class="kenjo-pl-10px"
                        >
                          <div *ngIf="changesTrack.automation === undefined">
                            {{ i18n.page.createBreakWithoutEndAuditMessage | i18nData: { userName:
                            userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, start:
                            convertMinutesToTime(changesTrack.newStart) } }}
                          </div>
                          <div
                            *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                          >
                            {{ i18n.page.createBreakWithoutEndSplitEntriesAutomationMessage | i18nData: { start:
                            convertMinutesToTime(changesTrack.newStart) } }}
                          </div>
                        </orgos-column>

                        <!-- Complete break updated -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.oldEnd && changesTrack.newEnd"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.updateBreakAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                          convertMinutesToTime(changesTrack.oldStart), oldEnd: convertMinutesToTime(changesTrack.oldEnd), newStart:
                          convertMinutesToTime(changesTrack.newStart), newEnd: convertMinutesToTime(changesTrack.newEnd) } }}
                        </orgos-column>

                        <!-- Break without end updated and completed -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && !changesTrack.oldEnd && changesTrack.newEnd"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.updateIncompleteBreakAndCompleteItWithoutEndAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                          convertMinutesToTime(changesTrack.oldStart), newStart: convertMinutesToTime(changesTrack.newStart), newEnd:
                          convertMinutesToTime(changesTrack.newEnd) } }}
                        </orgos-column>

                        <!-- Complete break update and remove end -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.oldEnd && changesTrack.newStart && !changesTrack.newEnd"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.updateBreakCompleteAndRemoveEndAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                          convertMinutesToTime(changesTrack.oldStart), oldEnd: convertMinutesToTime(changesTrack.oldEnd), newStart:
                          convertMinutesToTime(changesTrack.newStart) } }}
                        </orgos-column>

                        <!-- Incomplete break updated -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && !changesTrack.oldEnd && changesTrack.newStart && !changesTrack.newEnd"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.updateIncompleteBreakAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                          convertMinutesToTime(changesTrack.oldStart), newStart: convertMinutesToTime(changesTrack.newStart) } }}
                        </orgos-column>

                        <!-- Delete complete break -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.newValue === '' && changesTrack.oldEnd"
                          class="kenjo-pl-10px"
                        >
                          {{ i18n.page.deleteBreakAuditMessage | i18nData: { userName:
                          userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                          convertMinutesToTime(changesTrack.oldStart), oldEnd: convertMinutesToTime(changesTrack.oldEnd) } }}
                        </orgos-column>

                        <!-- Delete incomplete break -->
                        <orgos-column
                          size="0"
                          *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.newValue === '' && !changesTrack.oldEnd"
                          class="kenjo-pl-10px"
                        >
                          <div *ngIf="changesTrack.automation === undefined">
                            {{ i18n.page.deleteIncompleteBreakAuditMessage | i18nData: { userName:
                            userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                            convertMinutesToTime(changesTrack.oldStart) } }}
                          </div>
                          <div
                            *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                          >
                            {{ i18n.page.deleteBreakSplitEntriesAutomationMessage | i18nData: { oldStart:
                            convertMinutesToTime(changesTrack.oldStart) } }}
                          </div>
                          <div *ngIf="changesTrack.automation?.key === ATTENDANCE_AUTOMATION_MAX_HOURS">
                            {{ i18n.page.deleteBreakMaxHoursAutomationMessage | i18nData: { oldStart:
                            convertMinutesToTime(changesTrack.oldStart) } }}
                          </div>
                        </orgos-column>

                        <orgos-column size="0" class="kenjo-pl-10px">{{ changesTrack._createdAt | date:'short' }}</orgos-column>
                        <orgos-column *ngIf="changesTrack._interface && !changesTrack.automation" size="0" class="kenjo-pl-10px"
                          >({{ i18n.picklists.attendanceInterfaces[changesTrack._interface] }})</orgos-column
                        >
                        <orgos-column *ngIf="changesTrack.automation" size="0" class="kenjo-pl-10px"
                          >({{ i18n.picklists.attendanceAutomations[changesTrack.automation.key] }})</orgos-column
                        >
                      </orgos-column-container>
                      <orgos-column-container
                        centerColumns="true"
                        *ngIf="attendanceGeolocationForCurrentMonth && attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()]"
                        class="kenjo-pl-10px"
                      >
                        <orgos-column size="0">
                          <kenjo-icon
                            *ngIf="attendanceGeolocationForCurrentMonth && attendanceGeolocationForCurrentMonth[iDayOfMonth.toISOString()]"
                            class="pdap-geolocation-icon kenjo-cursor-pointer"
                            [ngClass]="
                              {'-green': changesTrack?._geolocation?._isWithinRadius,
                              '-yellow': changesTrack?._geolocation?.address && !changesTrack?._geolocation?._isWithinRadius,
                              '-grey': !changesTrack?._geolocation || !changesTrack?._geolocation?.address}"
                            [size]="24"
                            [matTooltip]="
                            changesTrack?._geolocation?._isWithinRadius ? i18n.page.trackedFromAllowedLocation :
                            (
                                changesTrack?._geolocation?.address && !changesTrack?._geolocation?._isWithinRadius ?
                                i18n.page.trackedFromUnlistedLocation : i18n.page.trackedFromNotLocation
                            )
                            "
                          >
                            location_on
                          </kenjo-icon>
                        </orgos-column>
                        <orgos-column size="0" class="kenjo-pl-5px kenjo-font-color-light-text-757575">
                          <ng-container *ngIf="changesTrack?._geolocation?._isWithinRadius === true">
                            {{changesTrack._geolocation._targetName}} ({{changesTrack._geolocation._targetAddress}})
                          </ng-container>
                          <ng-container *ngIf="!changesTrack?._geolocation?._isWithinRadius && changesTrack?._geolocation?.address">
                            {{changesTrack._geolocation.address}}
                          </ng-container>
                          <ng-container *ngIf="!changesTrack?._geolocation?._isWithinRadius && !changesTrack?._geolocation?.address">
                            {{i18n.page.trackedFromNotLocationDescription}}
                          </ng-container>
                        </orgos-column>
                      </orgos-column-container>
                    </orgos-column>
                  </orgos-column-container>
                </div>
              </div>
            </div>
            <div
              *ngFor="let iUserAttendance of filterDeletedShifts(allUserAttendanceForCurrentMonth[iDayOfMonth.toISOString()]); let indexOfTimeEntry = index"
            >
              <div *ngIf="iUserAttendance._deleted === true" class="kenjo-mb-20px kenjo-mh-10px pdap-shift-box">
                <div
                  *ngIf="iUserAttendance._changesTracking && iUserAttendance._changesTracking.length > 0"
                  class="kenjo-font-color-text-333333 kenjo-font-size-16px kenjo-m-20px"
                >
                  {{i18n.page.deletedShiftHeaderLabel}} {{indexOfTimeEntry+1}}
                </div>
                <div *ngFor="let changesTrack of iUserAttendance._changesTracking; let indexOfTimeEntry = index" class="kenjo-m-20px">
                  <orgos-column-container
                    *ngIf="changesTrack.fieldChanged !== '_approved' && changesTrack.fieldChanged !== 'breakTime'"
                    centerColumns="true"
                  >
                    <orgos-avatar
                      orgosColumn="0"
                      *ngIf="!changesTrack.automation && userPersonalById[changesTrack._createdById]"
                      [avatarName]="userPersonalById[changesTrack._createdById].displayName"
                      [photoUrl]="userPersonalById[changesTrack._createdById]._photo?._url"
                      size="small"
                    ></orgos-avatar>
                    <orgos-avatar
                      orgosColumn="0"
                      *ngIf="changesTrack.automation"
                      [avatarName]="i18n.page.automationUserName"
                      photoUrl="/assets/images/kenjoLogoBlack.svg"
                      size="small"
                    ></orgos-avatar>

                    <!-- Create shift -->
                    <orgos-column
                      size="0"
                      class="kenjo-pl-10px"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Create'"
                    >
                      <div *ngIf="changesTrack.automation === undefined">
                        {{ i18n.page.createAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName ||
                        i18n.page.apiUser } }}
                      </div>
                      <div
                        *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                      >
                        {{ i18n.page.createAuditMessage | i18nData: { userName: i18n.page.automationUserName } }}
                      </div>
                    </orgos-column>

                    <!-- Start time set -->
                    <orgos-column
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'startTime' && !changesTrack.oldValue"
                      size="0"
                      class="kenjo-pl-10px"
                    >
                      <div *ngIf="changesTrack.automation === undefined">
                        {{ i18n.page.setStartHourAuditMessage | i18nData: { userName:
                        userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                      <div
                        *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                      >
                        {{ i18n.page.updateEndHourSplitEntriesAutomationMessage | i18nData: { time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                    </orgos-column>

                    <!-- Ignored conflicts -->
                    <orgos-column
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'ignore-conflicts' && changesTrack.fieldChanged?.length"
                      size="0"
                      class="kenjo-pl-10px"
                    >
                      <div>
                        {{ i18n.page.timeTrackingIgnoredConflicts | i18nData: { userName:
                        userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser } }}
                        <ng-container *ngFor="let conflict of changesTrack.conflictConfiguration; let isLast = last">
                          <span *ngIf="conflict?.type === CONFLICT_TYPES.MAX_DAILY_HOURS">
                            {{ conflict?.value | duration }} {{ i18n.page.limitExceeded }}<span *ngIf="!isLast">,</span
                            ><span *ngIf="isLast">.</span>
                          </span>
                          <span *ngIf="conflict?.type === CONFLICT_TYPES.MIN_BREAK_TIME">
                            {{ conflict?.value | duration:false:true:true:true }} {{i18n.page.breakNotReached}}<span *ngIf="!isLast">,</span
                            ><span *ngIf="isLast">.</span>
                          </span>
                          <span *ngIf="conflict?.type === CONFLICT_TYPES.OVERLAPPING_PUBLIC_HOLIDAYS">
                            {{ i18n.page.publicHolidays.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                          </span>
                          <span *ngIf="conflict?.type === CONFLICT_TYPES.OVERLAPPING_NON_WORKING_DAYS">
                            {{ i18n.page.nonWorkingDays.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                          </span>
                          <span *ngIf="conflict?.type === CONFLICT_TYPES.MISSING_FULL_ENTRY">
                            {{ i18n.page.missingFullEntry.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                          </span>
                          <span *ngIf="conflict?.type === CONFLICT_TYPES.ENTRY_NOT_COMPLETED">
                            {{ i18n.page.entryNotCompleted.toLowerCase() }}<span *ngIf="!isLast">,</span><span *ngIf="isLast">.</span>
                          </span>
                        </ng-container>
                      </div>
                    </orgos-column>

                    <!-- Start time updated -->
                    <orgos-column
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'startTime' && changesTrack.oldValue"
                      size="0"
                      class="kenjo-pl-10px"
                    >
                      <div *ngIf="changesTrack.automation === undefined">
                        {{ i18n.page.updateStartHourAuditMessage | i18nData: { userName:
                        userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                      <div
                        *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                      >
                        {{ i18n.page.updateStartHourSplitEntriesAutomationMessage | i18nData: { time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                    </orgos-column>

                    <!-- End time set -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'endTime' && !changesTrack.oldValue"
                      class="kenjo-pl-10px"
                    >
                      <div *ngIf="changesTrack.automation === undefined">
                        {{ i18n.page.setEndHourAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                        || i18n.page.apiUser, time: convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                      <div *ngIf="changesTrack.automation?.key === ATTENDANCE_AUTOMATION_MAX_HOURS">
                        {{ i18n.page.updateEndHourMaxHoursPerDayAutomationMessage | i18nData: { time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                      <div
                        *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                      >
                        {{ i18n.page.updateEndHourSplitEntriesAutomationMessage | i18nData: { time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                    </orgos-column>

                    <!-- End time updated -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'endTime' && changesTrack.oldValue"
                      class="kenjo-pl-10px"
                    >
                      <div *ngIf="changesTrack.automation === undefined">
                        {{ i18n.page.updateEndHourAuditMessage | i18nData: { userName:
                        userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                      <div *ngIf="changesTrack.automation?.key === ATTENDANCE_AUTOMATION_MAX_HOURS">
                        {{ i18n.page.updateEndHourMaxHoursPerDayAutomationMessage | i18nData: { time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                      <div
                        *ngIf="[ATTENDANCE_AUTOMATION_SPLIT_ENTRIES, ATTENDANCE_AUTOMATION_TIME_OFF].includes(changesTrack.automation?.key)"
                      >
                        {{ i18n.page.updateEndHourSplitEntriesAutomationMessage | i18nData: { time:
                        convertMinutesToTime(changesTrack.newValue) } }}
                      </div>
                    </orgos-column>

                    <!-- Comment set -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'comment' && !changesTrack.oldValue"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.setCommentAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                      || i18n.page.apiUser, newComment: changesTrack.newValue } }}
                    </orgos-column>

                    <!-- Comment updated -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'comment'  && changesTrack.oldValue"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.updateCommentAuditMessage | i18nData: { userName:
                      userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, newComment: changesTrack.newValue } }}
                    </orgos-column>

                    <!-- Deleted -->
                    <orgos-column
                      size="0"
                      class="kenjo-pl-10px"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === '_deleted'"
                    >
                      {{ i18n.page.deletedAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName ||
                      i18n.page.apiUser } }}
                    </orgos-column>

                    <!-- Complete break created -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue === '' && changesTrack.newEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.createBreakAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                      || i18n.page.apiUser, start: convertMinutesToTime(changesTrack.newStart), end:
                      convertMinutesToTime(changesTrack.newEnd) } }}
                    </orgos-column>

                    <!-- Break without end created -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue === '' && !changesTrack.newEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.createBreakWithoutEndAuditMessage | i18nData: { userName:
                      userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, start:
                      convertMinutesToTime(changesTrack.newStart) } }}
                    </orgos-column>

                    <!-- Complete break updated -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.oldEnd && changesTrack.newEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.updateBreakAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                      || i18n.page.apiUser, oldStart: convertMinutesToTime(changesTrack.oldStart), oldEnd:
                      convertMinutesToTime(changesTrack.oldEnd), newStart: convertMinutesToTime(changesTrack.newStart), newEnd:
                      convertMinutesToTime(changesTrack.newEnd) } }}
                    </orgos-column>

                    <!-- Break without end updated and completed -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && !changesTrack.oldEnd && changesTrack.newEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.updateIncompleteBreakAndCompleteItWithoutEndAuditMessage | i18nData: { userName:
                      userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                      convertMinutesToTime(changesTrack.oldStart), newStart: convertMinutesToTime(changesTrack.newStart), newEnd:
                      convertMinutesToTime(changesTrack.newEnd) } }}
                    </orgos-column>

                    <!-- Complete break update and remove end -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.oldEnd && changesTrack.newStart && !changesTrack.newEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.updateBreakCompleteAndRemoveEndAuditMessage | i18nData: { userName:
                      userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                      convertMinutesToTime(changesTrack.oldStart), oldEnd: convertMinutesToTime(changesTrack.oldEnd), newStart:
                      convertMinutesToTime(changesTrack.newStart) } }}
                    </orgos-column>

                    <!-- Incomplete break updated -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && !changesTrack.oldEnd && changesTrack.newStart && !changesTrack.newEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.updateIncompleteBreakAuditMessage | i18nData: { userName:
                      userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                      convertMinutesToTime(changesTrack.oldStart), newStart: convertMinutesToTime(changesTrack.newStart) } }}
                    </orgos-column>

                    <!-- Delete complete break -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.newValue === '' && changesTrack.oldEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.deleteBreakAuditMessage | i18nData: { userName: userPersonalById[changesTrack._createdById]?.displayName
                      || i18n.page.apiUser, oldStart: convertMinutesToTime(changesTrack.oldStart), oldEnd:
                      convertMinutesToTime(changesTrack.oldEnd) } }}
                    </orgos-column>

                    <!-- Delete incomplete break -->
                    <orgos-column
                      size="0"
                      *ngIf="(userPersonalById[changesTrack._createdById] || changesTrack._interface === ATTENDANCE_INTERFACE_API) && changesTrack.operationType === 'Update' && changesTrack.fieldChanged === 'breaks' && changesTrack.oldValue !== '' && changesTrack.newValue === '' && !changesTrack.oldEnd"
                      class="kenjo-pl-10px"
                    >
                      {{ i18n.page.deleteIncompleteBreakAuditMessage | i18nData: { userName:
                      userPersonalById[changesTrack._createdById]?.displayName || i18n.page.apiUser, oldStart:
                      convertMinutesToTime(changesTrack.oldStart) } }}
                    </orgos-column>

                    <orgos-column size="0" class="kenjo-pl-10px kenjo-font-color-light-text-757575"
                      >{{ changesTrack._createdAt | date:'short' }}</orgos-column
                    >
                    <orgos-column *ngIf="changesTrack._interface && !changesTrack.automation" size="0" class="kenjo-pl-10px"
                      >({{ i18n.picklists.attendanceInterfaces[changesTrack._interface] }})</orgos-column
                    >
                    <orgos-column *ngIf="changesTrack.automation" size="0" class="kenjo-pl-10px"
                      >({{ i18n.picklists.attendanceAutomations[changesTrack.automation.key] }})</orgos-column
                    >
                  </orgos-column-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</orgos-container>

<orgos-time-off-user-page
  *ngIf="seeDetailsUserId"
  [userId]="seeDetailsUserId"
  [managePoliciesInfo]="userManagePoliciesInfo"
  (goBack)="exitTimeOffFullScreen()"
  class="pdap-time-off-page"
></orgos-time-off-user-page>

<ng-template cdkPortal>
  <div>
    <kenjo-breaks
      [translations]="i18n.breaksDialog"
      [userAttendance]="selectedUserAttendanceToChangeBreaks"
      [limits]="{
        maxShiftLength: MAX_SHIFT_LENGTH,
        maxHoursPerDay: MAX_HOURS_PER_DAY
      }"
      [attendancePolicy]="attendancePolicy"
      [loggedUser]="loggedUser"
      [minutesWorkedThisDay]="minutesWorkedEachDay[selectedIndexOfDayToChangeBreaks]"
      [readOnly]="selectedUserAttendanceToChangeBreaksIsReadOnly"
      [otherShiftsThisDay]="otherShiftsThisDayToChangeAttendanceBreaks"
      [isRestrictCheckIn]="isRestrictCheckIn"
      [restrictTimeLimit]="restrictTimeLimit"
      [attendanceConflictsCurrentDay]="attendanceConflictsCurrentDay"
      [nextShiftDate]="nextShiftDate"
      (close)="closeBreaksDialog($event)"
      (restrictLimitChange)="onShiftChange($event)"
    ></kenjo-breaks>
  </div>
</ng-template>
