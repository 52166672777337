import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as check from 'check-types';
import * as moment from 'moment';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';

@Component({
  selector: 'orgos-input-simple-date-picker',
  templateUrl: 'input-simple-date-picker.component.html',
  styleUrls: ['input-simple-date-picker.component.scss']
})
export class InputSimpleDatePickerComponent implements OnInit {
  startDate: string;
  isValueValid: boolean = true;
  showClearButton: boolean = false;

  @Input() enableClearButton: boolean = false;
  @Input() min: Date;
  @Input() max: Date;
  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() alwaysShowDates: boolean = false;
  @Input() value: any;
  @Input() customValidation: InputValidationFunction;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();

  @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<Date>;

  ngOnInit(): void {
    const currentISODate = this.value;
    this.startDate = check.assigned(currentISODate) && check.nonEmptyString(currentISODate) ? this.value : moment().toISOString();

    this.showClearButton = this.enableClearButton && check.assigned(currentISODate);
  }

  getValue(): string {
    return this.value;
  }

  onValueChange(newDate: moment.Moment): void {
    this.startDate = check.assigned(newDate) ? newDate.toISOString() : null;

    this.showClearButton = this.enableClearButton && check.assigned(newDate);

    this.value = check.assigned(newDate) ? newDate.toISOString() : null;

    this.makeValidation(this.value);

    if (this.isValueValid) {
      this.valueChange.emit(this.value);
    }
  }

  private makeValidation(isoDateToValidate: string): void {
    const inputValidation = this.validateValue(isoDateToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  protected validateValue(newISODate: string): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newISODate) || check.emptyString(newISODate))) {
      inputValidation.setError('required');
    }

    if (check.assigned(newISODate) && !moment.utc(newISODate).isValid()) {
      inputValidation.setError('date');
    }

    if (this.customValidation) {
      const customInputValidation = this.customValidation(newISODate);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  openDatepicker(): void {
    if (!this.readOnly) {
      this.datepicker.open();
    }
  }

  clearValue(): void {
    this.onValueChange(null);
  }
}
