<div *ngIf="i18n.page && parentTranslation">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">{{parentTranslation.generalHeader}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{parentTranslation.delegatedApprovalsSettings}}</span></div>
  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column class="kenjo-pr-80px" size="2">
      <div class="kenjo-font-size-16px">{{i18n.page.allowApproval}}</div>
      <div class="kenjo-pt-20px kenjo-font-color-light-text-757575">{{i18n.page.allowApprovalExplanation}}</div>
    </orgos-column>
    <mat-slide-toggle orgosColumn="2" [checked]="attendanceTrackingEnabled"> </mat-slide-toggle>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column class="kenjo-pr-80px" size="2">
      <div class="kenjo-font-size-16px">{{i18n.page.twoStepsApproval}}</div>
      <div class="kenjo-pt-20px kenjo-font-color-light-text-757575">{{i18n.page.twoStepsApprovalText}}</div>
    </orgos-column>
    <mat-slide-toggle orgosColumn="2" [checked]="attendanceTrackingEnabled"> </mat-slide-toggle>
  </orgos-column-container>

  <orgos-container class="kenjo-mt-40px" aspect="card">

    <div class="kenjo-pv-10px kenjo-pl-20px">
      <orgos-column-container>
        <div orgosColumn="1" class="kenjo-font-size-16px">
          {{i18n.page.approversOverview}}
        </div>
        <kenjo-icon orgosColumn="0" [size]="24" class="kenjo-pr-60px kenjo-font-color-primary-5993e3 kenjo-cursor-pointer"
          (click)="openApprovalsDialog()">mode_edit </kenjo-icon>
      </orgos-column-container>
    </div>
    <div class="kenjo-pl-20px kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-top">
      <orgos-column-container class="kenjo-pt-20px">
        <div orgosColumn="1">{{i18n.page.specificEmployee}}</div>
        <div orgosColumn="2">
          <orgos-column-container centerColumns="true">
            <orgos-column size="0" class="kenjo-mr-10px">
              <orgos-avatar [photoUrl]="" [avatarName]="'Bacterio'" size="tiny"></orgos-avatar>
            </orgos-column>
            <orgos-column>Bacterio</orgos-column>
          </orgos-column-container>
        </div>
      </orgos-column-container>
      <orgos-column-container class="kenjo-pt-20px kenjo-pb-40px">
        <div orgosColumn="1">{{i18n.page.managerApprover}}</div>
        <div orgosColumn="2">{{i18n.page.yes}}</div>
      </orgos-column-container>
    </div>
  </orgos-container>



</div>
