import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IShiftPlanGeneralSettingsModel, ShiftPlanGeneralSettingsService } from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';

@Component({
  selector: 'kenjo-settings-shift-plan-options',
  templateUrl: 'settings-shift-plan-options.html',
  styleUrls: ['settings-shift-plan-options.scss']
})
export class SettingsShiftPlanOptions implements OnInit {
  i18n: any = {};
  generalSettingsId: string;

  @Input() parentTranslation: any = {};
  @Input() generalSettings: IShiftPlanGeneralSettingsModel;

  @Output() updatedSettings: EventEmitter<IShiftPlanGeneralSettingsModel> = new EventEmitter<IShiftPlanGeneralSettingsModel>();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      this.i18n.page = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-general');
      this.generalSettingsId = this.generalSettings[fieldConstants.ID];
    } catch {
      this.i18n.page = {};
    }
  }

  public async changeSeeOtherSchedules(value: boolean): Promise<void> {
    this.generalSettings.employeesCanSeeOtherSchedules = value;
    try {
      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { employeesCanSeeOtherSchedules: value });
      this.updatedSettings.emit(this.generalSettings);
    } catch {
      // do nothing
    }
  }

  public async changeSeeOtherUnavailable(value: boolean): Promise<void> {
    this.generalSettings.employeesCanSeeOtherUnavailable = value;
    try {
      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { employeesCanSeeOtherUnavailable: value });
      this.updatedSettings.emit(this.generalSettings);
    } catch {
      // do nothing
    }
  }
}
