<orgos-widget titleKey="myAttendance" [loadingWidget]="loadingWidget" [existingData]="true" [footerHeight]="17">
  <orgos-widget-header-action
    *ngIf="permissions && permissions['attendance-app'] && permissions['attendance-app'].c_viewAttendanceTab_own === true"
  >
    <orgos-action (click)="navigateToAttendancePage()">{{ translation.viewAllButton }}</orgos-action>
  </orgos-widget-header-action>

  <orgos-widget-content>
    <kenjo-time-off-step
      *ngIf="widgetStep === WIDGET_STEP.TIME_OFF_STEP"
      [translations]="translation?.timeOffStep"
      [timeOffRequest]="timeOffRequest"
      class="owpc-time-off-container"
    ></kenjo-time-off-step>

    <div
      *ngIf="canEditAttendance && (widgetStep === WIDGET_STEP.OPENED_ENTRY_STEP || widgetStep === WIDGET_STEP.BREAK_TIME_STEP)"
      class="owpc-comment-menu kenjo-cursor-pointer"
    >
      <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="owpc-icon-position">more_vert</kenjo-icon>
    </div>
    <mat-menu #actionsMenu="matMenu" xPosition="before">
      <button [disabled]="!canEditAttendance || commentRequestLoading" mat-menu-item (click)="openCommentDialog()">
        <span>{{ userAttendanceForCurrentDay?.comment ? translation.editComment : translation.addComment }}</span>
      </button>
    </mat-menu>
    <!-- Chip -->
    <orgos-column-container wrap="true" class="kenjo-text-align-center kenjo-mt-10px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-chip
          *ngIf="widgetStep !== WIDGET_STEP.TIME_OFF_STEP"
          [color]="
            widgetStep !== WIDGET_STEP.CLOSED_ENTRY_STEP && widgetStep !== WIDGET_STEP.BREAK_TIME_STEP
              ? 'Success'
              : widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP
              ? 'Disabled'
              : 'Caution'
          "
        >
          <div class="fq-tag">{{ stepText[widgetStep] }}</div>
        </orgos-chip>
        <div *ngIf="widgetStep === WIDGET_STEP.APPROVED_STEP && totalAutoDeductedBreakTime" class="kenjo-mt-10px kenjo-flex-center-content">
          <span class="kenjo-font-weight-bold" [ngClass]="{ 'kenjo-text-transform-capitalize': loggedUser.language === 'de' }"
            >{{ totalAutoDeductedBreakTime | duration: false:false:true }} {{ translation?.breakTimeText | lowercase }}</span
          >
          <span class="kenjo-pl-5px kenjo-pr-5px">{{ translation.autoDeductedBreak }}</span>
          <kenjo-icon
            (click)="openAutoDeductDialog(userAttendanceForCurrentDay.breaks)"
            [size]="20"
            class="owpc-auto-deduct-icon kenjo-border-radius kenjo-cursor-pointer"
            >help</kenjo-icon
          >
        </div>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <!-- End Chip -->
    <!-- Category summary -->
    <div
      *ngIf="widgetStep === WIDGET_STEP.APPROVED_STEP && userAttendanceForCurrentDay?.attendanceCategoryId"
      class="owpc-category-container kenjo-mt-10px"
    >
      <kenjo-attendance-category-select
        [chosenCategoryId]="
          userAttendanceForCurrentDay?.attendanceCategoryId
            ? userAttendanceForCurrentDay?.attendanceSubCategoryId
              ? userAttendanceForCurrentDay.attendanceSubCategoryId
              : userAttendanceForCurrentDay.attendanceCategoryId
            : null
        "
        [categories]="attendanceCategories"
        [readOnly]="true"
      >
      </kenjo-attendance-category-select>
    </div>
    <!-- End Category summary -->
    <!-- Status Clock -->
    <orgos-column-container
      *ngIf="
        widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP ||
        widgetStep === WIDGET_STEP.OPENED_ENTRY_STEP ||
        widgetStep === WIDGET_STEP.BREAK_TIME_STEP
      "
      class="kenjo-mt-20px"
      [ngClass]="{ 'kenjo-mt-30px': widgetStep === WIDGET_STEP.BREAK_TIME_STEP && !canEditAttendance }"
    >
      <orgos-column size="1"></orgos-column>
      <orgos-column>
        <orgos-column-container>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0">
            <orgos-column-container centerColumns="true" class="kenjo-text-align-center">
              <orgos-column size="0" class="kenjo-mr-10px">
                <div
                  class="kwpc-status-circle"
                  [class]="
                    widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP
                      ? 'kenjo-color-disabled-background-c4c4c4'
                      : widgetStep === WIDGET_STEP.OPENED_ENTRY_STEP
                      ? 'kenjo-color-success-background-00b72e'
                      : 'kenjo-color-caution-background-ff8d0b'
                  "
                ></div>
              </orgos-column>
              <orgos-column size="0">
                <orgos-column-container
                  *ngIf="widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP"
                  class="kenjo-font-size-30px"
                  centerColumns="true"
                >
                  <orgos-column size="0">{{ currentHour }}</orgos-column>
                  <orgos-column size="0" [class.blinking]="widgetStep === WIDGET_STEP.OPENED_ENTRY_STEP">:</orgos-column>
                  <orgos-column size="0">{{ currentMinutes }}</orgos-column>
                </orgos-column-container>
                <orgos-column-container
                  *ngIf="widgetStep === WIDGET_STEP.OPENED_ENTRY_STEP"
                  class="kenjo-font-size-30px"
                  centerColumns="true"
                >
                  <orgos-column size="0">{{ currentAttendanceTime | splitDuration: 'hours' }}</orgos-column>
                  <orgos-column size="0" [class.blinking]="widgetStep === WIDGET_STEP.OPENED_ENTRY_STEP">:</orgos-column>
                  <orgos-column size="0">{{ currentAttendanceTime | splitDuration: 'minutes' }}</orgos-column>
                </orgos-column-container>
                <orgos-column-container *ngIf="widgetStep === WIDGET_STEP.BREAK_TIME_STEP" class="kenjo-font-size-30px">
                  <orgos-column size="0">{{ activeBreakTime | splitDuration: 'hours' }}</orgos-column>
                  <orgos-column size="0" class="blinking">:</orgos-column>
                  <orgos-column size="0">{{ activeBreakTime | splitDuration: 'minutes' }}</orgos-column>
                </orgos-column-container>
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
        </orgos-column-container>
        <div class="owpc-daily-total-container" *ngIf="showDailyTime && widgetStep !== WIDGET_STEP.BREAK_TIME_STEP">
          <p>
            {{ translation.dailyTotal }}<span> {{ displayedTotalWorkTime | splitDuration: 'hours' }}</span>
            <span> {{ displayedTotalWorkTime | splitDuration: 'minutes' }}</span>
          </p>
        </div>
        <div class="owpc-daily-total-container" *ngIf="showDailyTime && widgetStep === WIDGET_STEP.BREAK_TIME_STEP">
          <p>
            {{ translation.dailyTotal }}<span> {{ totalWorkTime | splitDuration: 'hours' }}</span>
            <span> {{ totalWorkTime | splitDuration: 'minutes' }}</span>
          </p>
        </div>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <!-- Horizontal row to separate category from message & image -->
    <ng-container *ngIf="userAttendanceForCurrentDay?.attendanceCategoryId && widgetStep === WIDGET_STEP.APPROVED_STEP">
      <div class="owpc-horizontal-row kenjo-border-bottom kenjo-pt-15px"></div>
    </ng-container>
    <!-- End horizontal row to separate category from message & image -->

    <orgos-column-container
      *ngIf="widgetStep === WIDGET_STEP.APPROVED_STEP"
      centerColumns="true"
      class="kenjo-text-align-center owpc-center-text owpc-inactive-punch-clock-container"
    >
      <orgos-column size="1">
        <div *ngIf="!canEditAttendance" class="kenjo-mt-40px kenjo-mb-30px owpc-inactive-punch-clock">
          {{ translation.cannotEditPunchClockText }} <a (click)="navigateAttendanceTab($event)">{{ translation.attendanceTab }}</a>
          {{ translation.cannotEditPunchClockSecondText }}
        </div>
        <div *ngIf="!this.isRestrictCheckIn && canEditAttendance">
          <div
            [ngClass]="{
              'kenjo-mt-30px': !userAttendanceForCurrentDay?.attendanceCategoryId,
              'kenjo-mt-15px': userAttendanceForCurrentDay?.attendanceCategoryId
            }"
          >
            {{ translation.dayTrackedText }}
          </div>
          <img class="kenjo-pt-10px" src="/assets/images/attendance-success.svg" width="87px'" />
        </div>
        <div *ngIf="this.isRestrictCheckIn && canEditAttendance" class="kenjo-mt-60px kenjo-mb-60px kenjo-font-size-18px">
          <div class="kenjo-mh-20px" *ngIf="!this.nextShiftDate">
            {{ translation.restrictCheckInConflict | i18nData: { hourLimit: restrictTimeLimit } }}
          </div>
          <div class="kenjo-mh-20px" *ngIf="this.nextShiftDate">
            {{ translation.restrictCheckInWithDateConflict | i18nData: { hourLimit: restrictTimeLimit, dateLimit: nextShiftDate } }}
          </div>
        </div>
      </orgos-column>
    </orgos-column-container>
    <!-- End Status Clock -->
    <!-- Message -->
    <orgos-column-container
      *ngIf="widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP"
      centerColumns="true"
      class="kenjo-mt-30px owpc-center-text"
    >
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0" class="kenjo-text-align-center owpc-inactive-punch-clock-container">
        <div class="kenjo-mv-20px owpc-inactive-punch-clock kenjo-mt-10px" *ngIf="!canEditAttendance">
          {{ translation.cannotEditPunchClockText }} <a (click)="navigateAttendanceTab($event)">{{ translation.attendanceTab }}</a>
          {{ translation.cannotEditPunchClockSecondText }}
        </div>
        <div class="kenjo-mh-20px" *ngIf="!this.isRestrictCheckIn && canEditAttendance">
          {{ translation.startTimeText1 }}<br />
          {{ translation.startTimeText2 }}
        </div>
        <!-- Restrict check in messages -->
        <div *ngIf="this.isRestrictCheckIn && canEditAttendance" class="kenjo-mt-60px kenjo-mb-60px kenjo-font-size-18px">
          <div *ngIf="!this.nextShiftDate">
            {{ translation.restrictCheckInConflict | i18nData: { hourLimit: restrictTimeLimit } }}
          </div>
          <div *ngIf="this.nextShiftDate">
            {{ translation.restrictCheckInWithDateConflict | i18nData: { hourLimit: restrictTimeLimit, dateLimit: nextShiftDate } }}
          </div>
        </div>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <!-- End Message -->
    <!-- Buttons -->
    <orgos-column-container
      centerColumns="true"
      [ngClass]="{
        'kenjo-mt-5px': widgetStep !== WIDGET_STEP.BREAK_TIME_STEP && !canEditAttendance && showDailyTime,
        'kenjo-mt-15px': widgetStep !== WIDGET_STEP.BREAK_TIME_STEP && !canEditAttendance && !showDailyTime,
        'kenjo-mt-20px':
          (widgetStep !== WIDGET_STEP.BREAK_TIME_STEP && canEditAttendance) ||
          (widgetStep === WIDGET_STEP.BREAK_TIME_STEP && !showDailyTime),
        'kenjo-mt-10px': widgetStep === WIDGET_STEP.BREAK_TIME_STEP && !canEditAttendance && showDailyTime
      }"
    >
      <orgos-column size="1"></orgos-column>
      <orgos-button-raised
        [disabled]="isRestrictCheckIn || clockInRequestLoading"
        (click)="clockIn()"
        *ngIf="widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP && canEditAttendance"
        orgosColumn="0"
        [color]="'Success'"
        class="kwpc-button-width"
        >{{ translation.clockInButton }}</orgos-button-raised
      >
      <orgos-column *ngIf="widgetStep === WIDGET_STEP.OPENED_ENTRY_STEP" size="1" class="kenjo-text-align-center">
        <div class="kenjo-mb-10px kenjo-mt-10px owpc-inactive-punch-clock" *ngIf="!canEditAttendance">
          {{ translation.cannotEditPunchClockText }} <a (click)="navigateAttendanceTab($event)">{{ translation.attendanceTab }}</a>
          {{ translation.cannotEditPunchClockSecondText }}
        </div>
        <div
          [ngClass]="
            canEditAttendance && attendanceCategories?.length ? 'owpc-button-container-size' : 'owpc-button-container-size-no-categories'
          "
          [ngStyle]="{ 'margin-top': showDailyTime ? '0px' : '22px' }"
        >
          <div
            class="kenjo-cursor-pointer"
            *ngIf="!customBehaviors.hideBreakButton"
            [matTooltip]="(currentTimeOff && attendancePolicy.overlappingWithTimeOff.isActive && !attendancePolicy.overlappingWithTimeOff?.conflicts) ? currentTimeOffToolTip : ''"
          >
            <orgos-button-raised
              [disabled]="
                breakRequestLoading ||
                checkOutRequestLoading ||
                (currentTimeOff && attendancePolicy.overlappingWithTimeOff.isActive && !attendancePolicy.overlappingWithTimeOff?.conflicts)
              "
              *ngIf="canEditAttendance"
              (click)="startBreak()"
              [color]="'Neutral'"
            >
              {{ translation.startBreakButton }}
            </orgos-button-raised>
          </div>
          <div
            [matTooltip]="
              canEditAttendance && !aMinuteHasPassed && !customBehaviors.allowCheckoutBeforeAMinute ? translation.waitForCheckOut : ''
            "
            matTooltipPosition="below"
          >
            <orgos-button-raised
              class="check-out-button"
              [disabled]="
                breakRequestLoading || checkOutRequestLoading || (!aMinuteHasPassed && !customBehaviors.allowCheckoutBeforeAMinute)
              "
              *ngIf="canEditAttendance"
              (click)="clockOut()"
              [color]="'Danger'"
            >
              {{ translation.clockOutButton }}
            </orgos-button-raised>
          </div>
        </div>
        <div class="kenjo-pt-20px" *ngIf="canEditAttendance && attendanceCategories?.length && !loadingWidget">
          <kenjo-select-simple-dropdown-custom
            *ngIf="canEditAttendance && attendanceCategories?.length && !loadingWidget"
            [defaultOption]="defaultCategory"
            [translations]="translation"
            [width]="'302px'"
            [componentKey]="'category-item'"
            [options]="attendanceCategories"
            (selectOption)="onSelectOption($event)"
            (cleanOption)="removeCategory()"
          ></kenjo-select-simple-dropdown-custom>
        </div>
      </orgos-column>
      <div class="owpc-button-container kenjo-text-align-center" orgosColumn="0">
        <div class="kenjo-mb-20px owpc-center-finish-break" *ngIf="!canEditAttendance && widgetStep === WIDGET_STEP.BREAK_TIME_STEP">
          {{ translation.cannotEditPunchClockText }} <a (click)="navigateAttendanceTab($event)">{{ translation.attendanceTab }}</a>
          {{ translation.cannotEditPunchClockSecondText }}
        </div>
        <orgos-button-raised
          [disabled]="breakRequestLoading || this.activeBreakTime === 0"
          (click)="finishBreak()"
          *ngIf="widgetStep === WIDGET_STEP.BREAK_TIME_STEP && canEditAttendance"
          color="Neutral"
          class="kwpc-button-width"
        >
          {{ translation.finishBreakButton }}
        </orgos-button-raised>
        <div
          class="owpc-button-container__tooltip"
          *ngIf="canEditAttendance && this.activeBreakTime === 0"
          [matTooltip]="translation.waitForBreak"
          matTooltipPosition="below"
        ></div>
      </div>
      <div
        class="owpc-button-container"
        orgosColumn="0"
        [matTooltip]="
          !canEditAttendance
            ? ''
            : allAttendanceOfDayApproved
            ? translation.approvedEntries
            : attendancePolicy?.limitDailyHours?.isActive && totalAttendanceForCurrentDay >= MAX_MINUTES_PER_DAY && !conflicts.maxDailyHours
            ? (translation.maxHoursPerDayReached | i18nData: { maxHoursPerDay: MAX_MINUTES_PER_DAY | duration })
            : ''
        "
      >
        <orgos-button-raised
          [disabled]="
            isRestrictCheckIn ||
            (!conflicts.maxDailyHours &&
              (clockInRequestLoading ||
                (attendancePolicy?.limitDailyHours?.isActive && totalAttendanceForCurrentDay >= MAX_MINUTES_PER_DAY) ||
                allAttendanceOfDayApproved))
          "
          (click)="addNewTracking()"
          *ngIf="widgetStep === WIDGET_STEP.APPROVED_STEP && canEditAttendance"
          orgosColumn="0"
          [color]="'Success'"
        >
          {{ translation.newShiftButton }}
        </orgos-button-raised>
      </div>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <!-- End Buttons -->
  </orgos-widget-content>

  <orgos-widget-footer>
    <orgos-column-container centerColumns="true">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0" class="kenjo-mr-20px">
        <div
          [class]="widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP ? 'kenjo-font-color-disabled-c4c4c4' : 'kenjo-font-color-success-00b72e'"
        >
          {{ translation.startStatusText }}
          <span
            *ngIf="summaryShift.clockInTime"
            [class]="{ 'kenjo-font-color-text-333333': widgetStep !== WIDGET_STEP.CLOSED_ENTRY_STEP }"
            >{{ summaryShift.clockInTime }}</span
          >
          <span
            *ngIf="!summaryShift.clockInTime"
            [class]="{ 'kenjo-font-color-text-333333': widgetStep !== WIDGET_STEP.CLOSED_ENTRY_STEP }"
            >{{ translation.nonTrackedPlaceholder }}</span
          >
        </div>
      </orgos-column>
      <orgos-column size="0" class="kenjo-mr-20px">
        <div [class]="widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP ? 'kenjo-font-color-disabled-c4c4c4' : 'kenjo-font-color-danger-ff5757'">
          {{ translation.endStatusText }}
          <span
            *ngIf="summaryShift.clockOutTime"
            [class]="{ 'kenjo-font-color-text-333333': widgetStep !== WIDGET_STEP.CLOSED_ENTRY_STEP }"
            >{{ summaryShift.clockOutTime }}</span
          >
          <span
            *ngIf="!summaryShift.clockOutTime"
            [class]="{ 'kenjo-font-color-text-333333': widgetStep !== WIDGET_STEP.CLOSED_ENTRY_STEP }"
            >{{ translation.nonTrackedPlaceholder }}</span
          >
        </div>
      </orgos-column>
      <orgos-column size="0">
        <div
          [class]="widgetStep === WIDGET_STEP.CLOSED_ENTRY_STEP ? 'kenjo-font-color-disabled-c4c4c4' : 'kenjo-font-color-caution-ff8d0b'"
        >
          {{ translation.breakTimeText }}
          <span *ngIf="summaryShift.breakTime" [class]="{ 'kenjo-font-color-text-333333': widgetStep !== WIDGET_STEP.CLOSED_ENTRY_STEP }">{{
            summaryShift.breakTime | duration
          }}</span>
          <span
            *ngIf="!summaryShift.breakTime"
            [class]="{ 'kenjo-font-color-text-333333': widgetStep !== WIDGET_STEP.CLOSED_ENTRY_STEP }"
            >{{ translation.nonTrackedPlaceholder }}</span
          >
        </div>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
  </orgos-widget-footer>
</orgos-widget>
