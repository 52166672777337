import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class AreaService implements IGenericService {
  private AREA_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/area-db`;
  private AREA_PERMISSIONS_KEY: string = 'area';
  private AREA_INTERNATIONALIZATION: string = 'area-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IAreaModel> {
    return new Promise<IAreaModel>((resolve, reject) => {
      this.genericService
        .create(this.AREA_URL, data)
        .then((area: IAreaModel) => {
          resolve(area);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AreaService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IAreaModel> {
    return new Promise<IAreaModel>((resolve, reject) => {
      this.genericService
        .getById(this.AREA_URL, id)
        .then((area: IAreaModel) => {
          resolve(area);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AreaService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.AREA_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AreaService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.AREA_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AreaService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.AREA_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.AREA_INTERNATIONALIZATION);
  }

  getData(
    findBody: any = {
      _id: { $ne: null }
    }
  ) {
    return new Promise<Array<IAreaModel>>((resolve, reject) => {
      this.genericService
        .find(this.AREA_URL, findBody)
        .then((areas: Array<IAreaModel>) => {
          resolve(areas);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, AreaService.name, 'getAreas'));
        });
    });
  }

  getAreas(data?: Array<string>): Promise<Array<IAreaModel>> {
    const findBody = {
      _id: data !== undefined && data.length > 0 ? data : { $ne: null }
    };
    return this.getData(findBody);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.AREA_URL);
  }
}

export interface IAreaModel {
  _id: string;
  name: string;
}

export interface IVirtualArea {
  _id?: string;
  name?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  calendarId?: string;
  motherAreaId?: string;
}
