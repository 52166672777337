import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputSimpleEditorComponent } from '@app/standard/components/input-simple-editor/input-simple-editor.component';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { EmailSignatureTemplateService } from '@app/standard/services/email-signature-template/email-signature-template.service';
import { EmailTemplateService } from '@app/standard/services/email-template/email-template.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { IEmail, RecruitingEmailService } from '@app/standard/services/recruiting/recruiting-email.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-email-composer',
  templateUrl: 'email-composer.html',
  styleUrls: ['email-composer.scss'],
})
export class EmailComposerComponent implements OnInit {
  minDate: moment.Moment;
  emailDate: moment.Moment = moment();
  emailTimeOptions: Array<ISelectOption> = [];
  emailTimeValue: number;

  dialogTranslation: any = {};
  listTemplates: Array<ISelectOption> = [];
  emailTemplateIdToEmailTemplate: any = {};
  ccEnabled: boolean = false;
  ccReceivers: string;
  bccEnabled: boolean = false;
  bccReceivers: string;
  emailTemplateId: string;
  emailTemplateChosen: string;
  emailBody: any;
  attachments: Array<any> = [];
  calculatingEmailTemplate: boolean = false;
  subjectValidation: InputValidation;
  bodyValidation: InputValidation;
  sendingEmail: boolean = false;
  sendLater: boolean = false;

  loggedUser: any = {};
  loggedUserWork: any = {};

  @Input() readOnlySubject: boolean = false;
  @Input() emailSubject: string;
  @Input() candidate: any;
  @Input() position: any;
  @Input() positionCandidate: any;
  @Input() allowSendLater: boolean = true;
  @Input() inReplyToEmailId: string;
  @Input() isDialog: boolean = false;
  @Output() emailScheduled: EventEmitter<void> = new EventEmitter<void>();
  @Output() emailSent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('bodyQuillEditor', { static: true }) bodyQuillEditor: InputSimpleEditorComponent;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-candidate-emails-page')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.injector
      .get(UserWorkService)
      .getByIdComplete(this.loggedUser._id)
      .then((loggedUserWork) => {
        this.loggedUserWork = loggedUserWork;
      })
      .catch(() => {
        this.loggedUserWork = {};
      });

    this.initDates();
    this.initListTemplates();
  }

  private initListTemplates(): void {
    const templateCollections = ['candidate', 'position-candidate'];
    if (check.assigned(this.position) && check.assigned(this.position._id) && check.nonEmptyString(this.position._id)) {
      templateCollections.push('position');
    }

    this.injector
      .get(EmailTemplateService)
      .find({ inCollection: { $in: templateCollections } })
      .then((emailTemplates) => {
        if (check.not.array(emailTemplates) || check.emptyArray(emailTemplates)) {
          this.listTemplates = [];
          return;
        }
        const templatesOrder = _.sortBy(emailTemplates, (iTemplate) => {
          return iTemplate.name.toLowerCase();
        });
        this.emailTemplateIdToEmailTemplate = {};
        this.listTemplates = templatesOrder.map((iTemplate) => {
          this.emailTemplateIdToEmailTemplate[iTemplate._id] = iTemplate;
          return { name: iTemplate.name, value: iTemplate._id };
        });

        return;
      })
      .catch(() => {
        //
      });
  }

  public applyEmailTemplate(): void {
    this.calculatingEmailTemplate = true;
    const document = this.getDocumentForEmailTemplate(this.emailTemplateChosen);
    this.injector
      .get(EmailTemplateService)
      .applyEmailTemplate(this.emailTemplateChosen, document)
      .then((templateResult) => {
        this.cdr.markForCheck();
        this.bodyValidation = new InputValidation();
        this.subjectValidation = new InputValidation();
        if (this.readOnlySubject === false) {
          this.emailSubject = templateResult.subject;
        }
        this.attachments =
          check.assigned(templateResult.attachments) && check.array(templateResult.attachments) ? templateResult.attachments : [];
        this.emailBody = this.convertToQuill(templateResult.body);
        this.calculatingEmailTemplate = false;
        this.cdr.detectChanges();
      })
      .catch(() => {
        const error = new OrgosError('EMAIL TEMPLATE ERROR', ErrorCodes.CLIENT_ERROR, EmailComposerComponent.name, 'applyEmailTemplate');
        error.message = `Email template could not be applied`;
        this.injector.get(ErrorManagerService).handleParsedError(error);
      });
  }

  private getDocumentForEmailTemplate(emailTemplateId: string): any {
    const emailTemplate = this.emailTemplateIdToEmailTemplate[emailTemplateId];
    if (check.not.assigned(emailTemplate)) {
      return null;
    }

    if (emailTemplate.inCollection === 'position-candidate' && check.assigned(this.positionCandidate)) {
      return this.positionCandidate;
    } else if (emailTemplate.inCollection === 'position') {
      return this.position;
    } else {
      return this.candidate;
    }
  }

  private convertToQuill(textInHtml: string): any {
    const result = {
      html: textInHtml,
      delta: undefined,
    };
    return result;
  }

  public sendEmail(): void {
    if (this.sendLater === true) {
      this.sendEmailLater();
    } else {
      this.sendEmailNow();
    }
  }

  public validateSchedule(): boolean {
    if (this.sendLater === false) {
      return true;
    }

    if (check.not.assigned(this.emailTimeValue) || check.not.assigned(this.emailDate)) {
      return false;
    }

    return true;
  }

  private sendEmailNow(): void {
    if (!this.isValidEmail() || this.sendingEmail) {
      return;
    }

    this.sendingEmail = true;
    const emailMessage: IEmail = this.getEmailMessage();
    this.injector
      .get(RecruitingEmailService)
      .sendEmail(this.candidate._id, emailMessage)
      .then(() => {
        const data = {
          emailSubject: this.emailSubject,
          emailAddress: this.candidate.email,
        };
        this.injector
          .get(MatLegacySnackBar)
          .open(`${this.injector.get(I18nDataPipe).transform(this.dialogTranslation.emailSent, data)}`, 'OK', { duration: 5000 });
        this.emailSent.emit();
      })
      .catch(() => {
        const error = new OrgosError('EMAIL SENDER ERROR', ErrorCodes.CLIENT_ERROR, EmailComposerComponent.name, 'sendEmailNow');
        error.message = `Email could not be sent`;
        this.injector.get(ErrorManagerService).handleParsedError(error);
      });
  }

  private sendEmailLater(): void {
    if (!this.isValidEmail() || this.sendingEmail) {
      return;
    }

    this.sendingEmail = true;
    if (check.assigned(this.emailDate) && check.assigned(this.emailTimeValue)) {
      const emailDate = moment(this.emailDate);
      if (check.not.assigned(emailDate) || check.emptyObject(emailDate)) {
        return;
      }

      emailDate.set({ hour: this.emailTimeValue, minute: 0 });

      const email: IEmail = this.getEmailMessage();

      this.injector
        .get(RecruitingEmailService)
        .scheduleEmail(this.candidate._id, email, emailDate.toDate())
        .then(() => {
          this.injector.get(MatLegacySnackBar).open(this.dialogTranslation.emailScheduledSnackbar, 'OK', { duration: 5000 });
          this.emailScheduled.emit();
        })
        .catch(() => {
          const error = new OrgosError('EMAIL SENDER ERROR', ErrorCodes.CLIENT_ERROR, EmailComposerComponent.name, 'sendEmailLater');
          error.message = `Email could not be scheduled`;
          this.injector.get(ErrorManagerService).handleParsedError(error);
        });
    }
  }

  private getEmailMessage(): IEmail {
    const emailMessage: IEmail = {
      subject: this.emailSubject,
      text: this.emailBody && this.emailBody.html ? this.emailBody.html : '',
      html: this.emailBody && this.emailBody.html ? this.emailBody.html : '',
      attachments: this.attachments,
      cc: this.convertToArrayOfEmails(this.ccReceivers),
      bcc: this.convertToArrayOfEmails(this.bccReceivers),
    };
    if (check.assigned(this.position) && check.assigned(this.position._id) && check.nonEmptyString(this.position._id)) {
      emailMessage._positionId = this.position._id;
    }

    if (check.assigned(this.inReplyToEmailId) && check.nonEmptyString(this.inReplyToEmailId)) {
      emailMessage.inReplyToEmailId = this.inReplyToEmailId;
    }
    return emailMessage;
  }

  private convertToArrayOfEmails(stringIn: string): Array<string> {
    if (check.not.assigned(stringIn) || check.emptyString(stringIn)) {
      return [];
    }

    if (check.not.contains(stringIn, ',')) {
      return [stringIn.trim()];
    }

    return stringIn.split(',').map((iEmail: string) => {
      return iEmail.trim();
    });
  }

  public addAttachment(): void {
    this.injector
      .get(UppyHelperService)
      .uploadGenericDocuments({ maxNumberOfFiles: 10 })
      .then((uploadedDocuments: Array<IFileMetadata> | null) => {
        if (check.not.assigned(uploadedDocuments)) {
          return;
        }
        this.attachments.push(...uploadedDocuments);
      })
      .catch(() => {});
  }

  public deleteAttachment(attachment): void {
    _.remove(this.attachments, (iAttachment) => {
      return check.equal(attachment._fileNameInS3, iAttachment._fileNameInS3);
    });
  }

  public downloadAttachment(attachment): void {
    this.injector
      .get(HttpClient)
      .get(attachment._url, { responseType: 'arraybuffer' })
      .subscribe((arrayBuffer) => {
        FileSaver.saveAs(new Blob([arrayBuffer]), attachment._fileName);
      });
  }

  public addSignature(): void {
    if (check.assigned(this.bodyQuillEditor)) {
      this.bodyQuillEditor.refreshContent();
    }

    if (
      check.not.assigned(this.loggedUserWork) ||
      check.not.assigned(this.loggedUserWork.emailSignature) ||
      check.not.assigned(this.loggedUserWork.emailSignature.html) ||
      check.equal(this.loggedUserWork.emailSignature.html, '<p><br></p>')
    ) {
      return;
    }

    const withoutParsing = this.loggedUserWork.emailSignature;
    withoutParsing.userId = this.loggedUserWork._id;
    this.injector
      .get(EmailSignatureTemplateService)
      .parseTemplate(withoutParsing)
      .then((signatureParsed) => {
        this.cdr.markForCheck();
        if (this.emailBody && this.emailBody.html) {
          this.emailBody.html += signatureParsed.html;
        } else {
          // Fix: https://app.asana.com/0/389662948049697/1112857414947068/f
          this.emailBody = this.convertToQuill(signatureParsed.html);
          this.cdr.detectChanges();
        }
        if (
          check.assigned(this.emailBody) &&
          check.assigned(this.emailBody.delta) &&
          check.assigned(this.emailBody.delta.ops) &&
          check.assigned(signatureParsed.delta) &&
          check.assigned(signatureParsed.delta.ops)
        ) {
          this.emailBody.delta.ops = this.emailBody.delta.ops.concat(signatureParsed.delta.ops);
        }

        if (check.assigned(this.bodyQuillEditor)) {
          this.bodyQuillEditor.refreshContent();
        }
      })
      .catch(() => {
        //
      });
  }

  public reevaluateEmailTimeOptions(): void {
    const today = moment();

    const hours: Array<ISelectOption> = Array.from({ length: 24 }, (item, iHour) => {
      return moment().startOf('day').hour(iHour);
    }).map((iDate) => {
      return { name: iDate.format('LT'), value: iDate.hour() };
    });

    if (today.isBefore(this.emailDate)) {
      this.emailTimeOptions = [].concat(hours);
      return;
    }

    const minEmailTime = this.getMinHour();
    if (this.emailTimeValue < minEmailTime) {
      this.emailTimeValue = minEmailTime;
    }
    this.emailTimeOptions = hours.filter((iOption) => {
      return iOption.value >= minEmailTime;
    });
  }

  private isValidEmail(): boolean {
    if (
      check.not.assigned(this.subjectValidation) ||
      this.subjectValidation.hasErrors() ||
      check.not.assigned(this.bodyValidation) ||
      this.bodyValidation.hasErrors()
    ) {
      return false;
    }
    return true;
  }

  private initDates(): void {
    this.minDate = moment();
    if (this.minDate.hour() === 23) {
      this.minDate.add(1, 'day').startOf('day');
    }
    this.emailTimeValue = this.getMinHour();
    this.reevaluateEmailTimeOptions();
  }

  private getMinHour(): number {
    const hour: number = this.minDate.hour();
    return hour + 1;
  }
}
