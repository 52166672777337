import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';
import { Subscription, interval, timer } from 'rxjs';
import { finalize, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanTimeOffService {
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan/time-off`;
  private SHIFTPLAN_TIME_OFF_SERVICE: string = 'ShiftPlanTimeOffService';

  public timeOffTimestamp$: Subscription;
  private snackbarTexts: { snackbar: string; refresh: string };
  private currentTimeoffTimestamp: Date = null;
  private timeOffRefreshSnackbar: MatLegacySnackBar = this.injector.get(MatLegacySnackBar);

  private _states = { isReload: false, subscriptionPaused: false, snackbarPaused: false };
  public get states(): { isReload: boolean; subscriptionPaused: boolean; snackbarPaused: boolean } {
    return this._states;
  }

  public set changeState(newValue: { [key: string]: boolean }) {
    this._states = { ...this._states, ...newValue };
  }

  private _isAfterFirstReload: boolean;
  public set isAfterFirstReload(value: boolean) {
    this._isAfterFirstReload = value;
  }
  public get isAfterFirstReload(): boolean {
    return this._isAfterFirstReload;
  }

  constructor(private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  async getTimeOffRequests(from: Date, to: Date, userIds: Array<string>, range: string): Promise<ITimeOffRequestSchedule> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_TIME_OFF_SERVICE, 'getTimeOffRequests');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http
        .post<ITimeOffRequestSchedule>(`${this.CONTROLLER_URL}/requests`, { from, to, userIds, range }, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawErrorSilently(error, this.SHIFTPLAN_TIME_OFF_SERVICE, 'getTimeOffRequests');
    }
  }

  async getTimeOffRequestDetail(from: Date, to: Date, userId: string, range: string): Promise<IUserTimeOffRequests> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_TIME_OFF_SERVICE, 'getTimeOffRequestDetail');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const fromTimestamp = from.getTime();
      const toTimestamp = to.getTime();

      const queryParams = `from=${fromTimestamp}&to=${toTimestamp}&userId=${userId}&range=${range}`;
      return await this.http.get<IUserTimeOffRequests>(`${this.CONTROLLER_URL}/requests-detail?${queryParams}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawErrorSilently(error, this.SHIFTPLAN_TIME_OFF_SERVICE, 'getTimeOffRequestDetail');
    }
  }

  async getLastTimeOffTimestamp(from: Date, to: Date, userIds: Array<string>, range: string): Promise<{ timestamp: Date }> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_TIME_OFF_SERVICE, 'getLastTimeOffTimestamp');
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http
        .post<{ timestamp: Date }>(`${this.CONTROLLER_URL}/time-off-updated-at`, { from, to, userIds, range }, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_TIME_OFF_SERVICE, 'getLastTimeOffTimestamp');
    }
  }

  //Handle time off changes checking last _updatedAt timestamp:
  public subscribeTimeOffs(
    from: Date,
    to: Date,
    userIds: Array<string>,
    range: string,
    snackbarTexts: { snackbar: string; refresh: string }
  ) {
    this.snackbarTexts = { ...snackbarTexts };
    const FIVE_MINUTES_TO_MILI = 300000;
    const SIX_HOURS_TO_MILI = 21600000;
    this.timeOffTimestamp$ = interval(FIVE_MINUTES_TO_MILI)
      .pipe(
        startWith(FIVE_MINUTES_TO_MILI),
        takeUntil(timer(SIX_HOURS_TO_MILI)),
        finalize(() => this.showRefreshSnackBar()),
        switchMap(() => this.injector.get(ShiftPlanTimeOffService).getLastTimeOffTimestamp(from, to, userIds, range))
      )
      .subscribe((timeoffTimestamp: { timestamp: Date }) => this.checkIfSameTimeOffTimestamp(timeoffTimestamp));
  }

  public showRefreshSnackBar() {
    if (!this.states.isReload) {
      const timeoffMessage = this.snackbarTexts.snackbar;
      this.timeOffRefreshSnackbar
        .open(timeoffMessage, this.snackbarTexts.refresh, {})
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });
    }
  }

  private checkIfSameTimeOffTimestamp(timeoffTimestamp: { timestamp: Date }) {
    if (this.currentTimeoffTimestamp === null) {
      this.currentTimeoffTimestamp = timeoffTimestamp?.timestamp;
      return;
    }

    if (check.not.emptyObject(timeoffTimestamp) && this.currentTimeoffTimestamp !== timeoffTimestamp?.timestamp) {
      this.currentTimeoffTimestamp = timeoffTimestamp.timestamp;
      if (this.states.subscriptionPaused) {
        this._states.snackbarPaused = true;
        this._states.isReload = true;
        this.timeOffTimestamp$.unsubscribe();
        this._states.isReload = false;
      } else {
        this.showRefreshSnackBar();
        this.timeOffTimestamp$.unsubscribe();
      }
    }
  }

  public reloadSubscription(
    from: Date,
    to: Date,
    userIds: Array<string>,
    range: string,
    snackbarTexts: { snackbar: string; refresh: string }
  ): void {
    if (this.isAfterFirstReload) {
      this._states.isReload = true;
      this.timeOffTimestamp$.unsubscribe();
      this.currentTimeoffTimestamp = null;
      this._states = { isReload: false, subscriptionPaused: false, snackbarPaused: false };
      this.subscribeTimeOffs(from, to, userIds, range, snackbarTexts);
    }
  }

  public finishSubscription() {
    this._states.isReload = true;
    this.timeOffRefreshSnackbar.dismiss();
    this.timeOffTimestamp$?.unsubscribe();
    this._states.isReload = false;
  }
}

export type IShiftPlanTimeOffStatus = 'Approved' | 'Pending' | 'Submitted' | 'InApproval';
export interface ITimeOffRequestDay {
  status?: IShiftPlanTimeOffStatus;
  total: number;
}
export interface ITimeOffRequestSchedule {
  [employeeId: string]: {
    days: {
      [day: number]: ITimeOffRequestDay;
    };
    isThereAnyList: boolean;
  };
}
export interface IUserTimeOffRequests {
  days: {
    [day: number]: Array<IUserTimeOffRequestDetail>;
  };
}
export interface IUserTimeOffRequestDetail {
  _id?: string;
  _timeOffTypeName: string;
  _timeOffTypeColor: string;
  status: IShiftPlanTimeOffStatus;
  from: string | Date;
  to: string | Date;
  _policyType: 'Day' | 'Hour';
  duration: number;
  _workTime: 'PaidReducesExpected' | 'UnpaidReducesExpected' | 'UnpaidNoReducesExpected' | 'Other';
  description: string;
  _createdAt: Date;
}