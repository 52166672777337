import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-input-toggle-round-simple',
  templateUrl: 'input-toggle-round-simple.component.html',
  styleUrls: ['input-toggle-round-simple.component.scss']
})
export class InputToggleRoundSimpleComponent {
  @Input()
  label: string;
  @Input()
  readOnly: boolean;
  private _value: boolean;
  @Input()
  set value(value: boolean) {
    if (this._value !== value) {
      this._value = value;
      this.valueChange.emit(value);
    }
  }

  get value() {
    return this._value;
  }
  @Output()
  valueChange = new EventEmitter();
}
