<orgos-dialog-container class="dialog-box kenjo-mt-10px" *ngIf="tag" [title]="isNewTag ? dialogTranslation.dialogAddTag : dialogTranslation.dialogEditTag" (clickCloseButton)="closeDialog()">
  <orgos-column-container class="" *ngIf="isNewTag">
    <orgos-input-text orgosColumn [label]="dialogTranslation.tagName" [model]="tag" field="name" [minlength]="3" [maxlength]="51" [required]="true" (validation)="nameCreateValidation = $event">
      <orgos-input-error *ngIf="nameCreateValidation && nameCreateValidation.getError('required')"> {{dialogTranslation.nameRequired}}</orgos-input-error>
      <orgos-input-error *ngIf="nameCreateValidation && !nameCreateValidation.getError('required') && nameCreateValidation.getError('minlength')"> {{dialogTranslation.tagMinLenght}} </orgos-input-error>
      <orgos-input-error *ngIf="nameCreateValidation && !nameCreateValidation.getError('required') && nameCreateValidation.getError('maxlength')"> {{dialogTranslation.tagMaxLenght}} </orgos-input-error>
    </orgos-input-text>
    <orgos-input-select orgosColumn [label]="dialogTranslation.tagCategory" [model]="tag" field="category" [options]="tagOptions" [required]="true" class="kenjo-ml-20px"></orgos-input-select>
  </orgos-column-container>
  <orgos-column-container class="" *ngIf="!isNewTag">
    <orgos-input-text orgosColumn [label]="dialogTranslation.tagName" [model]="tag" field="name" [minlength]="3" [maxlength]="51" [required]="true" (validation)="nameEditValidation = $event">
      <orgos-input-error *ngIf="nameEditValidation && nameEditValidation.getError('required')"> {{dialogTranslation.nameRequired}}</orgos-input-error>
      <orgos-input-error *ngIf="nameEditValidation && !nameEditValidation.getError('required') && nameEditValidation.getError('minlength')"> {{dialogTranslation.tagMinLenght}} </orgos-input-error>
      <orgos-input-error *ngIf="nameEditValidation && !nameEditValidation.getError('required') && nameEditValidation.getError('maxlength')"> {{dialogTranslation.tagMaxLenght}}  </orgos-input-error>
    </orgos-input-text>
    <orgos-input-select orgosColumn [label]="dialogTranslation.tagCategory" [model]="tag" field="category" [options]="tagOptions" [required]="true" class="kenjo-ml-20px"></orgos-input-select>
  </orgos-column-container>
  <div class="kenjo-mt-60px kenjo-mb-10px kenjo-text-align-right">
    <orgos-button-raised id="aetd-go-back-btn" color="Neutral" (click)="closeDialog()">{{ dialogTranslation.goBackButtonDialog }}</orgos-button-raised>
    <orgos-button-raised id="aetd-add-tag-btn" class="kenjo-ml-20px" *ngIf="isNewTag" color="Success" (click)="addTag()" [disabled]="!nameCreateValidation || nameCreateValidation.hasErrors()"> {{dialogTranslation.saveButtonLabel}} </orgos-button-raised>
    <orgos-button-raised id="aetd-edit-tag-btn" class="kenjo-ml-20px" *ngIf="!isNewTag" color="Success" (click)="editTag()" [disabled]="!nameEditValidation || nameEditValidation.hasErrors()"> {{dialogTranslation.saveButtonLabel}} </orgos-button-raised>
  </div>
</orgos-dialog-container>
