import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrlChangeService {
  private _previousUrl: string = undefined;
  private _currentUrl: string = undefined;

  constructor(private router: Router) {
    this._currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._previousUrl = this._currentUrl;
        this._currentUrl = event.url;
      }
    });
  }

  watchUrlChanges(): Observable<string> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router.url)
    );
  }

  public getPreviousUrl(): string {
    return this._previousUrl;
  }

  public getCurrentUrl(): string {
    return this._currentUrl;
  }
}
