import { Component, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';

@Component({
  selector: 'kenjo-subscription-card',
  templateUrl: 'subscription-card.component.html',
  styleUrls: ['subscription-card.component.scss']
})
export class SubscriptionCardComponent {
  planLogo = 'default';
  billingFrequencyText: string;
  statusColors = { active: 'Success', in_trial: 'Warning', cancelled: 'Danger', trial_expired: 'Danger', non_renewing: 'Neutral', future: '#7879f1' };

  @Input() translations: any;

  private _subscription: any;
  @Input() set subscription(subscription: any) {
    this._subscription = subscription;

    const itemPriceId = subscription?.plan?.item_price_id?.toLowerCase();

    if (itemPriceId.includes('starter')) {
      this.planLogo = 'starter';
    }
    if (itemPriceId.includes('growth')) {
      this.planLogo = 'growth';
    }
    if (itemPriceId.includes('connect')) {
      this.planLogo = 'connect';
    }

    this.setBillingFrequencyText();
  }

  get subscription() {
    return this._subscription;
  }

  constructor(private injector: Injector, private router: Router) {}

  setBillingFrequencyText() {
    if (this.subscription.billing_period_unit === 'month' && this.subscription.billing_period === 1) {
      this.billingFrequencyText = this.translations.currentPlan.billingFrequencyEveryMonth;
      return;
    }
    if (this.subscription.billing_period_unit === 'month' && this.subscription.billing_period > 1) {
      this.billingFrequencyText = this.injector.get(I18nDataPipe).transform(this.translations.currentPlan.billingFrequencyEveryNumberOfMonths, { months: this.subscription.billing_period });
      return;
    }
    if (this.subscription.billing_period_unit === 'year' && this.subscription.billing_period === 1) {
      this.billingFrequencyText = this.translations.currentPlan.billingFrequencyEveryYear;
      return;
    }
    if (this.subscription.billing_period_unit === 'year' && this.subscription.billing_period > 1) {
      this.billingFrequencyText = this.injector.get(I18nDataPipe).transform(this.translations.currentPlan.billingFrequencyEveryNumberOfYears, { months: this.subscription.billing_period });
      return;
    }
  }

  onGoToPlans() {
    this.router.navigateByUrl(`/cloud/settings/billing`);
    this.injector.get(PrivateAmplitudeService).logEvent('Billing page view plans clicked', { platform: 'Web', category: 'Free trial', subcategory1: 'Self serve' });
  }
}
