import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEnGB from '@angular/common/locales/en-GB';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { EXTERNAL_MODULES, PROVIDERS, ROUTES } from '@app/app.config';

registerLocaleData(localeEnGB);

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule, RouterModule.forRoot(ROUTES, {}), ...EXTERNAL_MODULES],
  providers: [...PROVIDERS],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
