import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { getEmptyFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { ShiftCardFilters, ShiftPlanCardService } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { FormatDatePipe } from '@app/standard/components/format-date/format-date.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-clear-schedule-dialog',
  templateUrl: 'clear-schedule-dialog.html',
  styleUrls: ['clear-schedule-dialog.scss'],
})
export class ClearScheduleDialog implements OnInit {
  dialogTranslation: { [key: string]: string };
  dateRange: string;
  isDeleting: boolean = false;
  includeOpen: boolean = false;
  totalShifts: number = 0;
  deleteActionSummaryList: Array<string>;
  shiftsLimitReached: boolean = false;
  SHIFTS_LIMIT_TO_DELETE: number = 18000;

  filters: ShiftCardFilters = { ...getEmptyFilters() };
  shiftsData: any;

  constructor(
    public dialogRef: MatLegacyDialogRef<ClearScheduleDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    public snackBar: MatLegacySnackBar,
    public formatDatePipe: FormatDatePipe
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.shiftsData = this.dialogInfo;
    this.shiftsLimitReached = this.dialogInfo.shiftsDeleteLimitReached;
    this.filters = this.shiftsData.filters;
    this.fetchTranslations();
    this.calculateTotal(false);
  }

  async fetchTranslations() {
    this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-clear-schedule-dialog');
    this.dateRange = `${this.formatDatePipe.transform(this.dialogInfo.startDay, 'D MMM')} - ${this.formatDatePipe.transform(
      this.dialogInfo.endDay,
      'D MMM'
    )}`;
    const { onlyPublishedRemain, changesNotNotified } = this.dialogTranslation;
    const dateRangeUnpublishedShifts = this.injector
      .get(I18nDataPipe)
      .transform(this.dialogTranslation.dateRangeUnpublishedShifts, { dateRange: this.dateRange });
    this.deleteActionSummaryList = [dateRangeUnpublishedShifts, onlyPublishedRemain, changesNotNotified];
  }

  calculateTotal(includeOpen: boolean) {
    this.includeOpen = includeOpen;
    this.totalShifts =
      this.includeOpen === true
        ? this.shiftsData.scheduledShiftsCount + this.shiftsData.openShiftsCount
        : this.shiftsData.scheduledShiftsCount;
  }

  async deleteShifts() {
    try {
      this.isDeleting = true;
      await this.injector
        .get(ShiftPlanCardService)
        .removeMany(
          this.shiftsData.startDay,
          moment.utc(this.shiftsData.endDay).endOf('day').toISOString(),
          this.includeOpen,
          this.filters,
          [],
          this.dialogInfo.view
        );
      const snackBarMessage = this.injector
        .get(I18nDataPipe)
        .transform(this.dialogTranslation.successfullyDeleteTooltip, { shiftsNumber: this.totalShifts });
      this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
    } catch {
      // do nothing
    } finally {
      this.isDeleting = false;
      this.dialogRef.close(true);
    }
  }

  closeDialog(): void {
    if (this.isDeleting) {
      return;
    }
    this.dialogRef.close();
  }
}
