import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanTagSettingsService implements IGenericService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-tag-db`;
  private SHIFT_PLAN_TAGS_INTERNATIONALIZATION: string = 'shift-plan-settings-roles-tags';
  private SHIFTPLAN_TAG_SERVICE: string = 'ShiftPlanTagSettingsService';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  async create(data: object): Promise<IShiftPlanTagSettingsModel> {
    try {
      return await this.genericService.create(this.MICROSERVICE_URL, data);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_TAG_SERVICE, 'create');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  async getTags(findQuery = { _id: { $ne: null } }): Promise<Array<IShiftPlanTagSettingsModel>> {
    try {
      return await this.genericService.find(this.MICROSERVICE_URL, findQuery);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_TAG_SERVICE, 'getTags');
    }
  }

  async getById(id: string): Promise<IShiftPlanTagSettingsModel> {
    try {
      return await this.genericService.getById(this.MICROSERVICE_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_TAG_SERVICE, 'getById');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.MICROSERVICE_URL, id, data);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_TAG_SERVICE, 'updateById');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.MICROSERVICE_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_TAG_SERVICE, 'deleteById');
    }
  }

  getPermissions(): Promise<object> {
    return Promise.resolve({
      create_all: true,
      read_all: true,
      edit_all: true,
      delete_all: false,
    });
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.SHIFT_PLAN_TAGS_INTERNATIONALIZATION);
  }
}

export interface IShiftPlanTagSettingsModel {
  _id?: string;
  name: string;
  category: string;
}

export type ShiftplanTagCategory = 'skills' | 'information' | 'other';
