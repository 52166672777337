import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IDatevSettingsModel } from '@app/cloud-features/settings-datev/models/datev-settings.model';
import { DatevSettingsService } from '@app/cloud-features/settings-datev/services/datev-settings.service';
import { ActivateShiftPlanDialog } from '@app/cloud-features/shift-plan/components/activate-shift-plan/activate-shift-plan.dialog';
import { ShiftPlanEmployeeListService } from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import {
  IShiftPlanRoleSettingsModel,
  ShiftPlanRoleSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-role.service';
import {
  IShiftPlanWorkingAreaSettingsModel,
  ShiftPlanWorkingAreaSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-working-area.service';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import { IUserWorkModel } from '@app/models/user-work.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { PrivateUserAccountService } from '@app/private/services/private-user-account.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InputValidationFunction } from '@app/standard/core/validation/input-validation-function';
import { IMenuOption } from '@app/standard/pages/generic.page';
import { PeopleHistoryDialog } from '@app/standard/pages/people-detail/dialogs/people-history.dialog';
import { AddEmailSignatureDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/add-email-signature/add-email-signature.dialog';
import { AddVirtualOfficeDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/add-virtual-office/add-virtual-office.dialog';
import { ChangeWorkEmailDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/change-work-email/change-work-email.dialog';
import { EditCostCentersDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/edit-cost-centers/edit-cost-centers.dialog';
import { EditWorkScheduleSimpleDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/edit-work-schedule-simple/edit-work-schedule-simple.dialog';
import { EditWorkScheduleDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/edit-work-schedule/edit-work-schedule.dialog';
import { CompleteShiftplanActivationDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/shift-plan-complete-activation/shift-plan-complete-activation.dialog';
import { ShowWorkScheduleDialog } from '@app/standard/pages/people-detail/people-detail-personal/dialogs/show-work-schedule/show-work-schedule.dialog';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { KioskService } from '@app/standard/services/attendance/kiosk.service';
import { AreaService } from '@app/standard/services/company/area.service';
import { CompanyService } from '@app/standard/services/company/company.service';
import { CostCenterService } from '@app/standard/services/company/cost-center.service';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { IOfficeModel, OfficeService } from '@app/standard/services/company/office.service';
import { TeamService } from '@app/standard/services/company/team.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IUploadPictureOptions, UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { UrlChangeService } from '@app/standard/services/core/url-changes.service';
import { EmailSignatureTemplateService } from '@app/standard/services/email-signature-template/email-signature-template.service';
import { FieldLevelPermissionsService } from '@app/standard/services/field-level-permissions/field-level-permissions.service';
import { ApiKeyService } from '@app/standard/services/integrations/api-key.service';
import { SingleSignOnService } from '@app/standard/services/single-sign-on/single-sign-on.service';
import { IUserAccountModel, UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserAddressService } from '@app/standard/services/user/user-address.service';
import { UserConfidentialService } from '@app/standard/services/user/user-confidential.service';
import { UserDatevService } from '@app/standard/services/user/user-datev.service';
import { UserEmergencyService } from '@app/standard/services/user/user-emergency.service';
import {
  IUserEmploymentTerminationReasonModel,
  UserEmploymentTerminationReasonService,
} from '@app/standard/services/user/user-employment-termination-reason.service';
import { UserFinancialService } from '@app/standard/services/user/user-financial.service';
import { UserHomeService } from '@app/standard/services/user/user-home.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserSalaryService } from '@app/standard/services/user/user-salary.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { UserService } from '@app/standard/services/user/user.service';
import { WorkflowService } from '@app/standard/services/workflow/workflow.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import isEmail from 'validator/es/lib/isEmail';

@Component({
  selector: 'orgos-people-detail-personal-page',
  templateUrl: 'people-detail-personal.page.html',
  styleUrls: ['people-detail-personal.page.scss'],
})
export class PeopleDetailPersonalPage implements OnInit, OnDestroy {
  USER_COLLECTIONS: Array<string> = [
    'user-account',
    'user-address',
    'user-work-schedule',
    'user-confidential',
    'user-emergency',
    'user-employment',
    'user-financial',
    'user-home',
    'user-personal',
    'user-salary',
    'user-work',
  ];

  translatedWeekdays: Array<string>;

  id: string;
  originPage: string;
  payrollGroupId: string;
  payrollPeriod: number;
  fullUser: any;
  workflowTargetDocument: any;
  kioskPinCode?: string;
  moment = moment;

  peopleDetailPageTranslation: any = {};
  userAccountTranslation: any = {};
  miscTranslation: any = {};
  standardPickList: any = {};
  userDatevPermissions: any = {};

  userTerminationReason$: Observable<Array<ISelectOption>>;
  defaultTerminationReasonTranslations: any = {};

  isAdmin: boolean;
  isNotMyUser: boolean;
  isMultiOrgUser: boolean;
  isDelegatedApproverActive: boolean;
  isTimeOffApproverActive: boolean;
  hasSubordinates: boolean;
  isUserTimeOffApprover: boolean;
  canBeActivated: boolean;
  isHrAdmin: boolean = false;
  isManager: boolean = false;
  isUserInPrimaryAccount: boolean = false;
  canActivateEmployee: boolean = false;
  canDeactivateEmployee: boolean = false;
  canDeleteEmployee: boolean = false;
  canSeeDatevFields: boolean = false;
  isDatevActive: boolean = false;
  userDataLoaded: boolean = false;
  isSSOMandatory: boolean = true;

  userAccount: GenericSimpleModel;
  userPersonal: GenericSimpleModel;
  userWork: GenericSimpleModel;
  userAddress: GenericSimpleModel;
  userHome: GenericSimpleModel;
  userFinancial: GenericSimpleModel;
  userEmergency: GenericSimpleModel;
  userWorkSchedule: GenericSimpleModel;
  userConfidential: GenericSimpleModel;
  userSalaries: GenericSimpleModel;
  userDatev: GenericSimpleModel;

  shiftPlanActive: boolean = false;
  showShiftplanLink: boolean = false;
  ACTIVATION_STATUS_FINISHED = picklists.SHIFT_PLAN_ACTIVATION_STATUS_FINISHED;

  eventDetail = {
    category: 'Employee profile',
    subcategory1: 'Work schedules',
    platform: 'Web',
  };

  private _tempWeeklyHours: number;
  set tempWeeklyHours(tempWeeklyHours: number) {
    this._tempWeeklyHours = tempWeeklyHours;
    this.cdr.detectChanges();
  }
  get tempWeeklyHours(): number {
    return this._tempWeeklyHours;
  }

  allUsersFull: Array<any> = [];
  allCompanies: Array<any> = [];
  companyOptions: Array<any> = [];
  departmentOptions: Array<ISelectOption> = [];
  officeOptions: Array<ISelectOption> = [];
  teamOptions: Array<ISelectOption> = [];
  areaOptions: Array<ISelectOption> = [];
  costCenterOptions: Array<ISelectOption> = [];
  reportsToOptions: Array<any> = [];
  timeOffUsersOptions: Array<any> = [];
  idToName: any = {};
  myPermissions: any = {};
  myCompany: any;

  personalNumberValidation: InputValidation;
  firstNameValidation: InputValidation;
  lastNameValidation: InputValidation;
  displayNameValidation: InputValidation;
  personalEmailValidation: InputValidation;
  emergencyEmailValidation: InputValidation;
  spouseBirthdateValidation: InputValidation;
  probationPeriodValidation: InputValidation;
  childBirthdateValidation: { [iChild: number]: InputValidation } = {};
  terminationDateValidation: InputValidation;
  birthdateValidation: InputValidation;
  startDateValidation: InputValidation;
  costCenterQuantityValidation: InputValidation;
  nationalInsuranceNumberValidation: InputValidation;

  selectTriggerWorkflow: boolean = false;
  listWorkflows: Array<any>;

  today: Date = new Date();

  isMarried: boolean = false;

  mapUserWorkByUserId: any = {};

  showScheduleHistory: boolean = true;
  mapScheduleTemplateById: any = {};
  currentWorkSchedule: any = {};
  hasCurrentWorkSchedule: boolean = false;
  selectEditWorkSchedule: boolean = false;

  apiEnabled: boolean = false;
  externalIdUniqueValidation: InputValidation;
  existingExternalIds: Array<any> = [];

  showDatevFields = false;
  userWorkCanEdit: boolean = false;

  isTimeOffEnabled: boolean = true;
  timeOffApprover: any;

  isDatevLodas = true;
  datevSettingsId: string;

  userManagePoliciesInfo: any;
  seeDetailsUserId: string;
  seeDetailsUserIdSubscription: Subscription;

  hideInvitationStatus: boolean = false;

  constructor(private route: ActivatedRoute, private injector: Injector, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
    this.translatedWeekdays = this.injector.get(InternationalizationService).getShortTranslatedWeekdays();
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe(async (id: string) => {
        this.id = id;
        await this.initData();
      });

    this.route.queryParams.subscribe((params) => {
      this.originPage = params.from;
      this.payrollGroupId = params.payrollGroupId;
      this.payrollPeriod = params.period;
    });

    this.fetchTranslations();
    this.injector.get(PrivateAmplitudeService).logEvent('view employee page', { type: 'personal' });
    this.seeDetailsUserIdSubscription = this.injector.get(PeopleDetailService).seeDetailsUserId$.subscribe((id: string) => {
      this.userManagePoliciesInfo = {
        userPersonal: this.userPersonal.data,
        userStartDate: this.userWork?.data.startDate,
        timeOffTypes: this.injector.get(PeopleDetailService).timeOffTypes,
      };
      this.seeDetailsUserId = id;
    });
  }
  private async initData(): Promise<void> {
    try {
      const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
      await this.getPermissions();
      if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
        this.fetchData();
        return;
      }
      const canSeePersonalTab = await this.injector
        .get(PeopleDetailService)
        .getPermissionsToSeePersonalTab(this.myPermissions['employees'], this.id);
      if (canSeePersonalTab === false) {
        // skip personal tab if we were kicked out from it
        this.injector
          .get(PeopleDetailService)
          .setUserWasKickedOutOfPersonalTabFromUrl(this.injector.get(UrlChangeService).getPreviousUrl());
        this.router.navigate(['../'], { relativeTo: this.route });
        return;
      }
      this.fetchData();
      return;
    } catch (e) {
      this.router.navigate(['../'], { relativeTo: this.route });
      return;
    }
  }

  private async fetchTranslations(): Promise<void> {
    try {
      const [miscTranslation, peopleDetailPageTranslation, userAccountTranslation, standardPickList] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('people-detail-misc'),
        this.injector.get(InternationalizationService).getAllTranslation('people-detail-personal-page'),
        this.injector.get(InternationalizationService).getAllTranslation('user-account-collection'),
        this.injector.get(InternationalizationService).getAllTranslation('standard-picklists'),
      ]);
      this.miscTranslation = miscTranslation;

      const options = [
        { name: miscTranslation.publicProfileTab, onClick: () => this.router.navigate(['../'], { relativeTo: this.route }) },
        { name: miscTranslation.personalTab, onClick: () => {} },
      ];

      this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
      this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(1);
      this.peopleDetailPageTranslation = peopleDetailPageTranslation;
      this.injector.get(GlobalBarService).setPageName(this.peopleDetailPageTranslation.pageName);
      this.userAccountTranslation = userAccountTranslation;
      this.standardPickList = standardPickList;
    } catch (e) {
      this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
      this.userAccountTranslation = {};
      this.peopleDetailPageTranslation = {};
      this.standardPickList = {};
    }
  }

  private async fetchData(): Promise<void> {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, false);

    this.userAccount = null;
    this.userPersonal = null;
    this.userWork = null;
    this.userAddress = null;
    this.userHome = null;
    this.userFinancial = null;
    this.userEmergency = null;
    this.userWorkSchedule = null;
    this.userConfidential = null;
    this.userDatev = null;
    this.shiftPlanActive = false;
    const currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    const userProfile = this.injector.get(AuthenticationService).getLoggedUser().profile;
    this.isAdmin = userProfile._isAdmin;
    this.isHrAdmin = userProfile._isStandard === true && check.equal('hr-admin', userProfile._profileKey);
    this.isManager = userProfile._isStandard === true && check.equal('manager', userProfile._profileKey);
    this.isNotMyUser = check.not.equal(this.id, currentUserId);

    if (check.equal(true, this.isAdmin)) {
      const apiKeys = await this.injector.get(ApiKeyService).getAllApiKeys();
      this.apiEnabled = check.assigned(apiKeys) && check.array(apiKeys) && apiKeys.length > 0;
      if (this.apiEnabled) {
        const userAccounts = await this.injector.get(UserAccountService).find({ externalId: { $exists: true, $ne: '' } });
        if (check.assigned(userAccounts) && check.nonEmptyArray(userAccounts)) {
          this.existingExternalIds = userAccounts
            .filter((user) => {
              return check.assigned(user.externalId) && check.nonEmptyString(user.externalId) && user._id !== this.id;
            })
            .map((user) => {
              return user.externalId;
            });
        }
      }
    }

    const SSOService = this.injector.get(SingleSignOnService);

    const [fullUser, kioskPinCode, datevStatus, isTimeOffEnabled] = await Promise.all([
      this.injector.get(UserService).getUserDetail(this.id, true),
      this.injector.get(KioskService).fetchPinCodeStatus(this.id),
      this.injector.get(CloudRoutesService).getAppStatus('datev'),
      this.injector.get(CloudRoutesService).getAppStatus('time-off'),
      this.injector.get(PeopleDetailService).onPeopleRouteLoaded(this.id),
      this.manageShiftPlanVisibility(),
    ]);

    try {
      this.fullUser = fullUser;
      this.isTimeOffEnabled = isTimeOffEnabled?.isActive === true;

      this.workflowTargetDocument = Object.assign({}, this.fullUser);
      this.workflowTargetDocument._id = this.id;

      this.userAccount = new GenericSimpleModel(this.injector, fullUser.userAccount, UserAccountService, this.id);
      this.userPersonal = new GenericSimpleModel(this.injector, fullUser.userPersonal, UserPersonalService, this.id);
      this.userWork = new GenericSimpleModel(this.injector, fullUser.userWork, UserWorkService, this.id);
      this.userWork.data.weeklyHours = this.userWork.data.weeklyHours === 0 ? null : this.userWork.data.weeklyHours;
      this.userAddress = new GenericSimpleModel(this.injector, fullUser.userAddress, UserAddressService, this.id);
      this.userHome = new GenericSimpleModel(this.injector, fullUser.userHome, UserHomeService, this.id);
      this.userFinancial = new GenericSimpleModel(this.injector, fullUser.userFinancial, UserFinancialService, this.id);
      this.userEmergency = new GenericSimpleModel(this.injector, fullUser.userEmergency, UserEmergencyService, this.id);
      this.userWorkSchedule = new GenericSimpleModel(this.injector, fullUser.userWorkSchedule, UserWorkScheduleService, this.id);
      this.kioskPinCode = kioskPinCode;

      this.setIsMarried();
      this.setUserWorkClearButton();

      this.userConfidential = new GenericSimpleModel(this.injector, fullUser.userConfidential, UserConfidentialService, this.id);
      if (check.assigned(this.myPermissions) && check.nonEmptyObject(this.myPermissions)) {
        const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
        await Promise.all([
          this.checkPermissionsToActivateEmployee(allUserWork),
          this.checkPermissionsToDeactivateEmployee(allUserWork),
          this.checkPermissionsToDeleteEmployee(allUserWork),
        ]);
      }
      if (datevStatus?.isActive !== true) {
        this.canSeeDatevFields = false;
        this.userDataLoaded = true;
      } else {
        this.isDatevActive = true;
      }
      if (
        check.assigned(this.userWorkSchedule) &&
        check.assigned(this.userWorkSchedule.data) &&
        check.assigned(this.userWorkSchedule.data.history) &&
        check.nonEmptyArray(this.userWorkSchedule.data.history)
      ) {
        this.getCurrentWorkSchedule();
      }
      const permissionResult = await this.injector
        .get(PrivateSecurityService)
        .computePermissions(this.userWork, 'employees.c_triggerManualWorkflows');

      if (permissionResult) {
        this.initWorkflows();
      }
      this.refreshSecondaryMenu();

      const singleSignOnData = await SSOService.checkIfMandatory(fullUser.userAccount.email);
      this.isSSOMandatory = singleSignOnData.isSSOMandatory;

      const userDatev = await this.getUserDatev();
      if (check.assigned(userDatev)) {
        this.isDatevLodas = userDatev.target === picklists.DATEV_INTERFACE_LODAS;
        this.userDatev = new GenericSimpleModel(this.injector, userDatev, UserDatevService, this.id);
        this.showDatevFields = this.route.snapshot.queryParams?.showDatevFields === 'true';
      }

      this.userDataLoaded = true;
      const companies = await this.injector.get(CompanyService).getCompanies();

      this.allCompanies = companies;
      this.myCompany = this.getMyCompany();
      this.companyOptions = companies.map((company: any) => {
        const companyOption = {
          name: company.name,
          value: company._id,
        };

        this.idToName[company._id] = company.name;
        return companyOption;
      });
      await this.setMultiOrgValues();

      if (
        check.assigned(this.myPermissions) &&
        check.assigned(this.myPermissions['user-personal']) &&
        this.myPermissions['user-personal'].read_all === true
      ) {
        const userWorks = await this.injector.get(UserWorkService).getAllUserWork(true);
        this.mapUserWorkByUserId = _.keyBy(userWorks, '_id');

        const activeAndNonActiveYetUserIds = await this.injector
          .get(UserAccountService)
          .find({ inactiveReason: { $ne: picklists.INACTIVE_REASON_DEACTIVATED } }, false);

        const allUsersToQueryIds = activeAndNonActiveYetUserIds.map((iUser) => iUser._id);

        // Check if manager and delegatedApprover are in the list.
        this.checkForMissingUsers(allUsersToQueryIds);

        const allUsersFull = await this.injector.get(UserPersonalService).find({ _id: { $in: allUsersToQueryIds } }, true);

        this.allUsersFull = allUsersFull;
        const availableUsersToReport = this.getAvailableUsersToReports();
        this.reportsToOptions = availableUsersToReport
          .map((iUser) => {
            this.idToName[iUser._id] = iUser.displayName;
            return {
              _id: iUser._id,
              displayName: iUser.displayName,
              _photo: iUser._photo,
              isActive: true,
            };
          })
          .filter((iUser) => {
            return iUser._id !== this.id;
          });

        this.timeOffUsersOptions = this.allUsersFull
          .map((iUser) => {
            this.idToName[iUser._id] = iUser.displayName;
            return {
              _id: iUser._id,
              displayName: iUser.displayName,
              _photo: iUser._photo,
              isActive: true,
            };
          })
          .filter((iUser) => {
            return iUser._id !== this.id;
          });

        this.injector.get(GlobalBarService).setProgressBar(false);
      } else {
        this.injector.get(GlobalBarService).setProgressBar(false);
      }

      this.getTerminationReasonTranslations();

      this.injector.get(UserEmploymentTerminationReasonService).loadAllUserEmploymentTerminationReasons();
      this.userTerminationReason$ = this.injector.get(UserEmploymentTerminationReasonService).userEmploymentTerminationReasons.pipe(
        map((terminationReason: Array<IUserEmploymentTerminationReasonModel>) =>
          terminationReason
            .filter((terminationReason: IUserEmploymentTerminationReasonModel) => terminationReason.isActive)
            .map((terminationReason: IUserEmploymentTerminationReasonModel) => ({
              name: this.defaultTerminationReasonTranslations[terminationReason.name]
                ? this.defaultTerminationReasonTranslations[terminationReason.name]
                : terminationReason.name,
              value: terminationReason._id,
            }))
        )
      );

      const [timeOffConfiguration, hasDescendants, isUserTimeOffApprover, teams, areas, departments, offices, costCenters] =
        await Promise.all([
          this.injector.get(PrivateOrganizationService).getTimeOffConfiguration(),
          this.injector.get(UserWorkService).checkIfUserHasDescendants(this.id),
          this.injector.get(UserWorkService).checkIfUserIsTimeOffApprover(this.id),
          this.injector.get(TeamService).getTeams(),
          this.injector.get(AreaService).getAreas(),
          this.injector.get(DepartmentService).getDepartments(),
          this.injector.get(OfficeService).getOffices(),
          this.injector.get(CostCenterService).getCostCenters(),
        ]);

      if (this.isTimeOffEnabled) {
        this.timeOffApprover = this.userWork.data.timeOffApproverId;
      }
      this.isDelegatedApproverActive = timeOffConfiguration.enableApprovalDelegation;
      this.isTimeOffApproverActive = timeOffConfiguration.enableTimeOffApprover;
      this.hasSubordinates = hasDescendants;
      this.isUserTimeOffApprover = isUserTimeOffApprover;

      this.teamOptions = teams.map((team) => {
        this.idToName[team._id] = team.name;
        return {
          name: team.name,
          value: team._id,
        };
      });

      this.areaOptions = areas.map((area) => {
        this.idToName[area._id] = area.name;
        return {
          name: area.name,
          value: area._id,
        };
      });

      this.departmentOptions = departments.map((department: any) => {
        const departmentOption = {
          name: department.name,
          value: department._id,
        };
        this.idToName[department._id] = department.name;
        return departmentOption;
      });

      this.officeOptions = offices.map((office: any) => {
        const officeOption = {
          name: office.name,
          value: office._id,
        };
        this.idToName[office._id] = office.name;
        return officeOption;
      });

      this.costCenterOptions = costCenters.map((chosenCostCenter: any) => {
        const costCenterFullName = `${chosenCostCenter.costCenterId} - ${chosenCostCenter.name}`;
        this.idToName[chosenCostCenter._id] = costCenterFullName;
        const costCenterOption = {
          name: costCenterFullName,
          value: chosenCostCenter._id,
        };
        return costCenterOption;
      });
    } catch (e) {
      this.userDataLoaded = true;
      this.officeOptions = [];
      this.reportsToOptions = [];
      this.timeOffUsersOptions = [];
      this.isDelegatedApproverActive = false;
      this.isTimeOffApproverActive = false;
      this.hasSubordinates = false;

      this.departmentOptions = [];
      this.officeOptions = [];
    }
  }

  private getAvailableUsersToReports(): Array<any> {
    const unavailableUserIds = this.generateAvailabilityTree(this.id, []);
    const availableUsers = this.allUsersFull.filter((user) => {
      return unavailableUserIds.includes(user._id) === false;
    });
    return availableUsers;
  }

  private generateAvailabilityTree(userId: string, branch?: Array<any>): Array<any> {
    let result = [userId];
    branch.push(userId);
    const children = this.allUsersFull.filter((user) => {
      return this.mapUserWorkByUserId[user._id] && this.mapUserWorkByUserId[user._id].reportsToId === userId;
    });
    if (check.nonEmptyArray(children) && children.length > 0) {
      children.forEach((child) => {
        if (branch.includes(child._id) === false) {
          result = result.concat(this.generateAvailabilityTree(child._id, branch));
        }
      });
    }
    return result;
  }

  private checkForMissingUsers(usersToQuery) {
    // check delegatedApprover
    if (
      check.assigned(this.userPersonal.data?.delegatedApproverId) &&
      !usersToQuery.some((iUserId) => iUserId.toString() === this.userPersonal.data?.delegatedApproverId.toString())
    ) {
      usersToQuery.push(this.userPersonal.data.delegatedApproverId);
    }
    // check reportsTo
    if (
      check.assigned(this.mapUserWorkByUserId?.[this.id]?.reportsToId) &&
      !usersToQuery.some((iUserId) => iUserId.toString() === this.mapUserWorkByUserId[this.id].reportsToId.toString())
    ) {
      usersToQuery.push(this.mapUserWorkByUserId[this.id].reportsToId);
    }
    // check time off approver
    if (
      check.assigned(this.mapUserWorkByUserId?.[this.id]?.timeOffApproverId) &&
      !usersToQuery.some((iUserId) => iUserId.toString() === this.mapUserWorkByUserId[this.id].timeOffApproverId.toString())
    ) {
      usersToQuery.push(this.mapUserWorkByUserId[this.id].timeOffApproverId);
    }
  }

  private async refreshSecondaryMenu(): Promise<void> {
    if (check.emptyObject(this.miscTranslation) || check.not.assigned(this.id)) {
      return;
    }

    const options: Array<IMenuOption> = [
      { name: this.miscTranslation.publicProfileTab, onClick: () => this.router.navigate(['../'], { relativeTo: this.route }) },
      { name: this.miscTranslation.personalTab, onClick: () => {} },
    ];
    try {
      const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
      if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
        if (
          this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') &&
          check.assigned(this.userWorkSchedule) &&
          check.assigned(this.userWorkSchedule.data) &&
          check.assigned(this.userWorkSchedule.data.trackAttendance) &&
          this.userWorkSchedule.data.trackAttendance === true
        ) {
          options.push({
            key: 'attendance-tab',
            name: this.miscTranslation.attendanceTab,
            onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
          });
        }
        options.push({
          key: 'compensation-tab',
          name: this.miscTranslation.compensationTab,
          onClick: () => this.router.navigate(['../compensation'], { relativeTo: this.route }),
        });
        options.push({
          name: this.miscTranslation.payrollTab,
          onClick: () => this.router.navigate(['../payroll'], { relativeTo: this.route }),
        });
        if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance')) {
          options.push({
            name: this.miscTranslation.performanceTab,
            onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
          });
        }

        this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
        this.injector.get(PeopleDetailService).verifySmartDocsLink(this.id);
        this.injector.get(PeopleDetailService).verifyShowTimeOffLink(this.id, this.userPersonal.data);
        return;
      }

      let employeesPermissions;
      let payrollPermissions;
      let performancePermissions;
      await this.getPermissions();

      employeesPermissions = this.myPermissions['employees'];
      payrollPermissions = this.myPermissions['payroll-feature'];
      performancePermissions = this.myPermissions['performance-feedback-results'];

      const canSeeAttendanceTab = await this.getPermissionsToSeeAttendanceTab(employeesPermissions);

      if (canSeeAttendanceTab === true) {
        options.push({
          key: 'attendance-tab',
          name: this.miscTranslation.attendanceTab,
          onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
        });
      }
      const canSeePayrollTabs = await this.getPermissionsToSeePayrollTabs(payrollPermissions);

      if (canSeePayrollTabs === true) {
        options.push({
          key: 'compensation-tab',
          name: this.miscTranslation.compensationTab,
          onClick: () => this.router.navigate(['../compensation'], { relativeTo: this.route }),
        });
        options.push({
          name: this.miscTranslation.payrollTab,
          onClick: () => this.router.navigate(['../payroll'], { relativeTo: this.route }),
        });
      }
      const canSeePerformanceTab = await this.getPermissionsToSeePerformanceTab(performancePermissions);

      if (canSeePerformanceTab === true) {
        options.push({
          name: this.miscTranslation.performanceTab,
          onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
        });
      }
      this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
      this.injector.get(PeopleDetailService).verifySmartDocsLink(this.id);
      this.injector.get(PeopleDetailService).verifyShowTimeOffLink(this.id, this.userPersonal.data);
    } catch (e) {
      // An error is already shown
      this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
      return;
    }
  }

  private async getTerminationReasonTranslations() {
    try {
      const defaultTerminationReasonTranslations = await this.injector
        .get(InternationalizationService)
        .getAllTranslation('user-employment-termination-reason-defaults');
      this.defaultTerminationReasonTranslations = defaultTerminationReasonTranslations;
    } catch {
      this.defaultTerminationReasonTranslations = {};
    }
  }

  async showPicker(): Promise<void> {
    const uploadPictureOptions: IUploadPictureOptions = {
      makeTransformations: true,
    };
    const uploadedPhoto = await this.injector.get(UppyHelperService).uploadGenericPicture(uploadPictureOptions);
    if (check.not.assigned(uploadedPhoto)) {
      return;
    }

    this.injector.get(GlobalBarService).setProgressBar(true);
    this.userPersonal.data.photo = uploadedPhoto;
    this.userPersonal.data._photo = uploadedPhoto;
    await this.userPersonal.update();
    this.injector.get(GlobalBarService).setProgressBar(false);
    this.refreshUserInGlobalBar();
  }

  refreshUserInGlobalBar(): void {
    this.injector.get(GlobalBarService).executeRefreshUser();
  }

  setIsMarried(): void {
    if (check.not.assigned(this.userHome) || check.not.assigned(this.userHome.data)) {
      return;
    }
    this.isMarried = this.userHome.data.maritalStatus === 'Married';
  }

  private async setMultiOrgValues(): Promise<void> {
    if (check.not.assigned(this.userAccount) || check.not.assigned(this.userAccount.data)) {
      return;
    }

    this.isMultiOrgUser = check.assigned(this.userAccount.data._primaryEmail) && isEmail(this.userAccount.data._primaryEmail);

    if (this.isMultiOrgUser) {
      this.isUserInPrimaryAccount = (
        await this.injector.get(UserAccountService).isEmployeePrimaryOrg(this.userAccount.data._id)
      ).isPrimaryOrg;
    }
  }

  addChild(): void {
    const newData: any = this.userHome.data;

    if (!newData.children) {
      newData.children = [];
    }
    newData.children.push({});

    this.userHome.update();
  }

  removeChild(iChild: number): void {
    const newData: any = this.userHome.data;
    newData.children.splice(iChild, 1);

    this.userHome.update();
  }

  openEditCostCentersDialog(chosenCostCenter?: any, costCenterIndex?: number): void {
    const isEdit = check.assigned(chosenCostCenter);
    const dialogRef = this.injector
      .get(MatLegacyDialog)
      .open(EditCostCentersDialog, { data: { costCenter: { ...chosenCostCenter }, costCenterOptions: this.costCenterOptions } });
    dialogRef.afterClosed().subscribe(async (newCostCenter) => {
      if (!newCostCenter) {
        return;
      }

      if (isEdit) {
        this.userWork.data.costCenters[costCenterIndex] = newCostCenter;
      } else {
        this.userWork.data.costCenters.push(newCostCenter);
      }

      try {
        await this.injector.get(UserWorkService).updateById(this.id, this.userWork.data);
        this.injector.get(MatLegacySnackBar).open(`${this.peopleDetailPageTranslation.costCenterSavedSnackbar}`, 'OK', {
          duration: 5000,
        });
      } catch {
        if (!isEdit) {
          this.userWork.data.costCenters.pop();
        }
      }
    });
  }

  removeCostCenter(iCostCenter: number): void {
    const newData: any = this.userWork.data;
    newData.costCenters.splice(iCostCenter, 1);

    this.userWork.update();
  }

  async openResetPasswordConfirmDialog(): Promise<void> {
    if (this.isMultiOrgUser && !this.isUserInPrimaryAccount) {
      this.openConfirmDialogForMultiOrg('password');
      return;
    }

    const getConfirmResetPasswordTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('confirm-reset-password-dialog');

    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

    const [confirmResetPasswordTranslation, globalMiscTranslation] = await Promise.all([
      getConfirmResetPasswordTranslations,
      getGlobalMiscTranslations,
    ]);
    const data = {
      titleText: confirmResetPasswordTranslation.dialogHeader,
      subtitleText: confirmResetPasswordTranslation.warningMessageText,
      cancelButtonText: globalMiscTranslation.goBackButtonDialog,
      confirmButtonText: confirmResetPasswordTranslation.confirmButtonLabel,
      confirmButtonColor: 'Success',
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.resetPassword();
      }
    });
  }

  async openActivateUserConfirmDialog(): Promise<void> {
    try {
      const getConfirmActivateUserTranslations = this.injector
        .get(InternationalizationService)
        .getAllTranslation('confirm-activate-user-dialog');
      const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

      const [confirmActivateUserTranslations, globalMiscTranslations] = await Promise.all([
        getConfirmActivateUserTranslations,
        getGlobalMiscTranslations,
      ]);

      const data = {
        titleText: confirmActivateUserTranslations.dialogHeader,
        subtitleText:
          !this.isMultiOrgUser || (this.isMultiOrgUser && this.isUserInPrimaryAccount)
            ? this.injector
                .get(I18nDataPipe)
                .transform(confirmActivateUserTranslations.warningMessageText, { email: this.userAccount.data.email })
            : confirmActivateUserTranslations.secondaryMultiOrgUserMessage,
        cancelButtonText: globalMiscTranslations.goBackButtonDialog,
        confirmButtonText: confirmActivateUserTranslations.confirmButtonLabel,
        confirmButtonColor: 'Danger',
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe(async (confirm) => {
        if (confirm) {
          try {
            this.injector.get(GlobalBarService).setProgressBar(true);

            const userId = this.userAccount.data._id;

            await this.injector.get(PrivateUserAccountService).resendActivation(userId);
            await this.refreshUserAccount();

            let snackbarMessage;
            if (!this.isMultiOrgUser || (this.isMultiOrgUser && this.isUserInPrimaryAccount)) {
              snackbarMessage = `${this.peopleDetailPageTranslation.confirmActivateMessageStarting} ${this.userAccount.data.email} ${this.peopleDetailPageTranslation.confirmActivateMessageEnding}`;
            } else {
              snackbarMessage = this.injector.get(I18nDataPipe).transform(this.peopleDetailPageTranslation.secondaryMultiOrgUserActivated, {
                displayName: this.userPersonal.data.displayName,
              });
            }

            this.injector.get(MatLegacySnackBar).open(snackbarMessage, 'OK', {
              duration: 5000,
            });

            const userAccount = await this.injector.get(UserAccountService).getById(this.id);
            this.userAccount = new GenericSimpleModel(this.injector, userAccount, UserAccountService, this.id);
            this.loadAvailableOptions();
          } catch {
            // An error is already shown
          } finally {
            this.injector.get(GlobalBarService).setProgressBar(false);
          }
        }
      });
    } catch {
      // Do nothing
    }
  }

  openHistoryDialog(): void {
    const parentInfo = {
      _id: this.userAccount.data._id,
      userCreatedAt: this.userAccount.data._createdAt,
      userCreatedById: this.userAccount.data._createdById,
      idToName: this.idToName,
      peopleDetailPageTranslation: this.peopleDetailPageTranslation,
    };

    this.injector.get(MatLegacyDialog).open(PeopleHistoryDialog, { data: parentInfo });
  }

  async openDeleteUserConfirmDialog(): Promise<void> {
    try {
      const confirmDeleteUserTranslation = await this.injector
        .get(InternationalizationService)
        .getAllTranslation('confirm-delete-user-dialog');
      const data = {
        titleText: confirmDeleteUserTranslation.dialogHeader,
        subtitleText: confirmDeleteUserTranslation.warningMessageText,
        confirmButtonText: confirmDeleteUserTranslation.confirmButtonLabel,
        cancelButtonText: confirmDeleteUserTranslation.cancelButtonLabel,
        confirmButtonColor: 'Danger',
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe(async (confirm) => {
        if (confirm && confirm === true) {
          this.injector.get(GlobalBarService).setProgressBar(true);
          const userId = this.userAccount.data._id;
          await this.injector.get(PrivateUserAccountService).archiveUser(userId);

          const message = this.injector
            .get(I18nDataPipe)
            .transform(this.peopleDetailPageTranslation.confirmDeleteMessage, { displayName: this.userPersonal.data.displayName });
          this.injector.get(MatLegacySnackBar).open(message, 'OK', {
            duration: 5000,
          });
          this.injector.get(GlobalBarService).setProgressBar(false);
          this.router.navigateByUrl('/cloud/people/directory');
        }
      });
    } catch (e) {
      // Do nothing
      this.injector.get(GlobalBarService).setProgressBar(false);
    }
  }

  async openDeactivateUserConfirmDialog(): Promise<void> {
    try {
      const getConfirmDeactivateUserTranslations = this.injector
        .get(InternationalizationService)
        .getAllTranslation('confirm-deactivate-user-dialog');
      const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

      const [confirmDeactivationUserTranslations, globalMiscTranslations] = await Promise.all([
        getConfirmDeactivateUserTranslations,
        getGlobalMiscTranslations,
      ]);

      const data = {
        titleText: confirmDeactivationUserTranslations.dialogHeader,
        subtitleText: confirmDeactivationUserTranslations.warningMessageText,
        cancelButtonText: globalMiscTranslations.goBackButtonDialog,
        confirmButtonText: confirmDeactivationUserTranslations.confirmButtonLabel,
        confirmButtonColor: 'Danger',
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe(async (confirm) => {
        if (confirm) {
          try {
            this.injector.get(GlobalBarService).setProgressBar(true);

            const userId = this.userAccount.data._id;
            await this.injector.get(PrivateUserAccountService).deactivateUser(userId);
            await this.refreshUserAccount();

            this.injector.get(MatLegacySnackBar).open(`${this.peopleDetailPageTranslation.confirmDeactivateMessage}`, 'OK', {
              duration: 5000,
            });

            const userAccount = await this.injector.get(UserAccountService).getById(this.id);
            this.userAccount = new GenericSimpleModel(this.injector, userAccount, UserAccountService, this.id);
            this.loadAvailableOptions();
          } catch {
            // An error is already shown
          } finally {
            this.injector.get(GlobalBarService).setProgressBar(false);
          }
        }
      });
    } catch {
      // Do nothing
    }
  }

  private resetPassword(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);

    const emailToReset = this.userAccount.data.email;
    this.injector
      .get(PrivateUserAccountService)
      .resetPassword(emailToReset)
      .then(() => {
        this.injector
          .get(MatLegacySnackBar)
          .open(
            `${this.peopleDetailPageTranslation.passwordResetStartMessageLabel} ${emailToReset} ${this.peopleDetailPageTranslation.passwordResetEndMessageLabel}`,
            'OK',
            {
              duration: 5000,
            }
          );
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch(() => {
        // An error is already shown
        this.injector.get(GlobalBarService).setProgressBar(false);
      });
  }

  private async initWorkflows(): Promise<void> {
    const allWorkflows = await this.injector.get(WorkflowService).getAllWorkflows();

    if (check.array(allWorkflows) && check.nonEmptyArray(allWorkflows)) {
      this.listWorkflows = allWorkflows.filter((iWorkflow) => {
        return iWorkflow.inCollection === 'user' || check.contains(this.USER_COLLECTIONS, iWorkflow.inCollection);
      });
    }
  }

  resetTriggerWorkflow(listWorkflows: any): void {
    this.injector.get(GlobalBarService).setPageName(this.peopleDetailPageTranslation.pageName);

    this.listWorkflows = listWorkflows;
    this.selectTriggerWorkflow = false;
  }

  getMyCompany(): any {
    const myCompany = this.allCompanies.find((iCompany) => {
      return iCompany._id === this.userWork.data.companyId;
    });
    return myCompany;
  }

  async updateMyLanguage(myLanguage: string): Promise<void> {
    // should be in this order
    await this.injector.get(UserAccountService).updateMyLanguage(myLanguage);
    await this.injector.get(AuthenticationService).refreshLoggedUser();
    await this.injector.get(CloudRoutingService).refreshRouter();

    this.fetchTranslations();
    this.fetchData();
  }

  async updateMyLocale(myLocale: string): Promise<void> {
    // should be in this order
    await this.injector.get(UserAccountService).updateMyLocale(myLocale);
    await this.injector.get(AuthenticationService).refreshLoggedUser();

    this.fetchTranslations();
    this.fetchData();
  }

  private async getPermissions(): Promise<void> {
    if (check.assigned(this.myPermissions) && check.nonEmptyObject(this.myPermissions)) {
      return;
    }

    const allPermissions = await this.injector.get(PrivateSecurityService).getAllPermissions();
    this.myPermissions = allPermissions;
  }

  public async openEmailSignatureDialog(): Promise<void> {
    const parentInfo = {
      emailSignature: null,
    };
    let signatureParsed;
    if (check.nonEmptyObject(this.userWork?.data?.emailSignature)) {
      const withoutParsing = this.userWork.data.emailSignature;
      withoutParsing.userId = this.id;
      signatureParsed = await this.injector.get(EmailSignatureTemplateService).parseTemplate(withoutParsing);
    }

    parentInfo.emailSignature = signatureParsed;
    const dialogRef = this.injector.get(MatLegacyDialog).open(AddEmailSignatureDialog, { data: parentInfo });
    dialogRef.afterClosed().subscribe((newEmailSignature) => {
      if (check.assigned(newEmailSignature) || newEmailSignature === '') {
        this.userWork.data.emailSignature = newEmailSignature === '' ? null : newEmailSignature;
        this.injector
          .get(UserWorkService)
          .updateEmailSignature({ _id: this.id, emailSignature: this.userWork.data.emailSignature })
          .then(() => {
            this.injector
              .get(MatLegacySnackBar)
              .open(`${this.peopleDetailPageTranslation.emailSignatureUpdatedSnackbar}`, 'OK', { duration: 5000 });
          })
          .catch(() => {
            delete this.userWork.data.emailSignature;
          });
      }
    });
  }

  public async openVirtualOfficeDialog(): Promise<void> {
    const userWorkCanEdit = await this.userWork.canEdit;
    if (!userWorkCanEdit) {
      return;
    }
    const parentInfo = {
      virtualOffice: Object.assign({}, this.userWork.data.virtualOffice),
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(AddVirtualOfficeDialog, { data: parentInfo });
    dialogRef.afterClosed().subscribe((afterCloseData) => {
      if (!afterCloseData || !afterCloseData.saved) {
        return;
      }
      this.userWork.data.virtualOffice = afterCloseData.virtualOffice;
      this.injector
        .get(UserWorkService)
        .updateById(this.id, this.userWork.data)
        .then(() => {
          this.injector.get(MatLegacySnackBar).open(`${this.peopleDetailPageTranslation.virtualOfficeSavedSnackbar}`, 'OK', {
            duration: 5000,
          });
        })
        .catch(() => {
          delete this.userWork.data.virtualOffice;
        });
    });
  }

  public async clearVirtualOffice(): Promise<void> {
    this.userWork.data.virtualOffice = {};
    await this.injector.get(UserWorkService).updateById(this.id, this.userWork.data);
    this.injector.get(MatLegacySnackBar).open(`${this.peopleDetailPageTranslation.virtualOfficeCleanedSnackbar}`, 'OK', {
      duration: 5000,
    });
  }

  public async changeWorkEmail(): Promise<void> {
    if (this.isMultiOrgUser) {
      this.openConfirmDialogForMultiOrg('email');
      return;
    }

    const parentInfo = {
      workEmail: this.userAccount.data.email,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ChangeWorkEmailDialog, { data: parentInfo });
    dialogRef.afterClosed().subscribe(async (newWorkEmail) => {
      if (newWorkEmail && check.assigned(newWorkEmail) && check.not.equal(newWorkEmail, this.userAccount.data.email)) {
        await this.injector.get(UserAccountService).updateById(this.id, { email: newWorkEmail });
        this.userAccount.data.email = newWorkEmail;
        this.injector.get(MatLegacySnackBar).open(`${this.peopleDetailPageTranslation.workEmailUpdatedSnackbar}`, 'OK', {
          duration: 5000,
        });
      }
    });
  }

  private openConfirmDialogForMultiOrg(key: string): void {
    const titleTranslationKey = `${key}MultiOrgDialogTitle`;
    const messageTranslationKey = `${key}MultiOrgDialogMessage`;

    const data = {
      titleText: this.peopleDetailPageTranslation[titleTranslationKey],
      subtitleText: this.peopleDetailPageTranslation[messageTranslationKey],
      confirmButtonText: this.peopleDetailPageTranslation.OkButton,
      confirmButtonColor: 'Success',
    };

    this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
  }

  public async enableAttendanceTracking() {
    try {
      await this.injector.get(UserWorkScheduleService).setAttendanceTracking({ _id: this.id, trackAttendance: true });
      this.userWorkSchedule.data.trackAttendance = true;
      this.injector.get(MatLegacySnackBar).open(this.peopleDetailPageTranslation.attendanceTrackingEnabledSnackbar, 'OK', {
        duration: 5000,
      });
      this.refreshSecondaryMenu();

      // Reload available routes if this is the logged user's profile
      if (this.id === this.injector.get(AuthenticationService).getLoggedUser()._id) {
        this.injector.get(CloudRoutingService).refreshRouter();
      }
    } catch {
      // Do nothing
    }
  }

  public async disableAttendanceTracking() {
    try {
      await this.injector.get(UserWorkScheduleService).setAttendanceTracking({ _id: this.id, trackAttendance: false });
      this.userWorkSchedule.data.trackAttendance = false;
      this.injector.get(MatLegacySnackBar).open(this.peopleDetailPageTranslation.attendanceTrackingDisabledSnackbar, 'OK', {
        duration: 5000,
      });
      this.refreshSecondaryMenu();

      // Reload available routes if this is the logged user's profile
      if (this.id === this.injector.get(AuthenticationService).getLoggedUser()._id) {
        this.injector.get(CloudRoutingService).refreshRouter();
      }
    } catch {
      // Do nothing
    }
  }

  private async getPermissionsToSeeAttendanceTab(employeesPermissions: any): Promise<boolean> {
    try {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (
        this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') === false ||
        check.not.assigned(this.userWorkSchedule) ||
        check.not.assigned(this.userWorkSchedule.data) ||
        this.userWorkSchedule.data.trackAttendance === false
      ) {
        return false;
      }

      if (loggedUser._id === this.id && employeesPermissions.c_viewAttendanceTab_own === true) {
        return true;
      }

      if (employeesPermissions.c_viewAttendanceTab_all === true) {
        return true;
      }

      if (
        check.not.assigned(employeesPermissions.c_viewAttendanceTab_custom) ||
        check.emptyArray(employeesPermissions.c_viewAttendanceTab_custom)
      ) {
        return false;
      }

      const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
      let myUserWork = this.userWork.data;
      if (check.not.assigned(myUserWork)) {
        myUserWork = allUserWork.find((iUserWork) => {
          return iUserWork._id === loggedUser._id;
        });
      }
      const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
        null,
        'user-work',
        employeesPermissions[`c_viewAttendanceTab_custom`],
        employeesPermissions[`c_viewAttendanceTab_own`],
        myUserWork,
        allUserWork,
        loggedUser
      );
      return customPermissionResult;
    } catch (e) {
      return false;
    }
  }

  private async getPermissionsToSeePayrollTabs(payrollPermissions: any): Promise<boolean> {
    try {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (
        this.injector.get(CloudRoutesService).checkRoute('people/:id/compensation') === false ||
        this.injector.get(CloudRoutesService).checkRoute('people/:id/payroll') === false
      ) {
        return false;
      }

      if (loggedUser._id === this.id && payrollPermissions.c_viewCompensation_own === true) {
        return true;
      }
      if (payrollPermissions.c_viewCompensation_all === true) {
        return true;
      }
      if (
        check.not.assigned(payrollPermissions.c_viewCompensation_custom) ||
        check.emptyArray(payrollPermissions.c_viewCompensation_custom)
      ) {
        return false;
      }

      const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
      let myUserWork = this.userWork.data;
      if (check.not.assigned(myUserWork)) {
        myUserWork = allUserWork.find((iUserWork) => {
          return iUserWork._id === this.id;
        });
      }
      const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
        null,
        'user-work',
        payrollPermissions[`c_viewCompensation_custom`],
        payrollPermissions[`c_viewCompensation_own`],
        myUserWork,
        allUserWork,
        loggedUser
      );
      return customPermissionResult;
    } catch (e) {
      return false;
    }
  }

  private async getPermissionsToSeePerformanceTab(performancePermissions: any): Promise<boolean> {
    try {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance') === false) {
        return false;
      }
      if (loggedUser._id === this.id) {
        return true;
      }
      if (performancePermissions.c_readFeedback_all === true) {
        return true;
      }
      if (
        check.not.assigned(performancePermissions.c_readFeedback_custom) ||
        check.emptyArray(performancePermissions.c_readFeedback_custom)
      ) {
        return false;
      }
      const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
      let myUserWork = this.userWork.data;
      if (check.not.assigned(myUserWork)) {
        myUserWork = allUserWork.find((iUserWork) => {
          return iUserWork._id === this.id;
        });
      }
      const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
        null,
        'user-work',
        performancePermissions.c_readFeedback_custom,
        performancePermissions.read_own,
        myUserWork,
        allUserWork,
        loggedUser
      );
      return customPermissionResult;
    } catch (e) {
      return false;
    }
  }

  public assignCustomSchedule(): void {
    delete this.currentWorkSchedule.isDefault;
    delete this.currentWorkSchedule._id;
    delete this.currentWorkSchedule._updatedById;
    delete this.currentWorkSchedule._createdById;
    delete this.currentWorkSchedule._createdAt;
    delete this.currentWorkSchedule._updatedAt;
    this.currentWorkSchedule.isCustom = true;
    const workScheduleInfo = {
      workScheduleTemplate: this.currentWorkSchedule,
      userWorkSchedule: this.userWorkSchedule,
    };
    const dialogRef = this.injector
      .get(MatLegacyDialog)
      .open(EditWorkScheduleDialog, { panelClass: 'kenjo-full-screen-dialog', data: workScheduleInfo });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.getCurrentWorkSchedule();
      }
    });
  }

  public showWorkScheduleDetail(): void {
    this.injector.get(MatLegacyDialog).open(ShowWorkScheduleDialog, { data: this.currentWorkSchedule });
  }

  private getCurrentWorkSchedule(): void {
    this.currentWorkSchedule = {};
    if (
      check.not.assigned(this.userWork.data) ||
      check.not.assigned(this.userWorkSchedule.data.history) ||
      check.emptyArray(this.userWorkSchedule.data.history)
    ) {
      return;
    }

    let entryIndex = 0;
    const today = moment.utc();
    while (
      entryIndex < this.userWorkSchedule.data.history.length &&
      moment.utc(this.userWorkSchedule.data.history[entryIndex].startDate).isSameOrBefore(today, 'day')
    ) {
      this.currentWorkSchedule = this.userWorkSchedule.data.history[entryIndex];
      entryIndex = entryIndex + 1;
    }

    this.hasCurrentWorkSchedule = check.nonEmptyObject(this.currentWorkSchedule);
  }

  public async removeScheduleTemplate(templateId: string): Promise<void> {
    const DELETE_PAST_WS_CUSTOM = 'delete past custom schedule in personal';
    const DELETE_PAST_WS_TEMPLATE = 'unassign template in past in personal';

    const dialogData = {
      titleText: this.peopleDetailPageTranslation.deleteTitle,
      confirmButtonText: this.peopleDetailPageTranslation.deleteConfirmText,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.peopleDetailPageTranslation.deleteCancel,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(async (confirm: boolean) => {
      if (confirm !== true) {
        return;
      }
      const selectedScheduleEntry = this.userWorkSchedule.data.history.find((workSchedule) => {
        return workSchedule._id === templateId;
      });
      const deleteBody = {
        userIds: [this.id],
        entryId: selectedScheduleEntry._id,
        date: selectedScheduleEntry.startDate,
      };
      await this.injector.get(UserWorkScheduleService).deleteScheduleEntries(deleteBody);
      const updatedHistory = this.userWorkSchedule.data.history.filter((workSchedule) => {
        return workSchedule._id !== templateId;
      });
      this.userWorkSchedule.data.history = updatedHistory;
      this.getCurrentWorkSchedule();
      this.injector.get(MatLegacySnackBar).open(this.peopleDetailPageTranslation.scheduleRemovedSnackbar, 'OK', { duration: 5000 });

      if (moment(this.currentWorkSchedule.startDate).isAfter(selectedScheduleEntry.startDate)) {
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent(selectedScheduleEntry.isCustom ? DELETE_PAST_WS_CUSTOM : DELETE_PAST_WS_TEMPLATE, this.eventDetail);
      }
    });
  }

  public async editCustomSchedule(customSchedule: any): Promise<void> {
    const EDIT_PAST_WS_CUSTOM = 'edits past custom schedule in personal';
    const CREATE_PAST_WS_CUSTOM = 'create custom schedule in past';

    const workScheduleInfo = {
      workScheduleTemplate: customSchedule,
      userWorkId: this.userWorkSchedule.data._id,
    };

    const dialogRef = this.injector
      .get(MatLegacyDialog)
      .open(EditWorkScheduleDialog, { panelClass: 'kenjo-full-screen-dialog', data: workScheduleInfo });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (check.not.assigned(result) || result === false) {
        return;
      }
      const snackBarMessage =
        result.operation === 'update'
          ? this.peopleDetailPageTranslation.scheduleUpdatedSnackbar
          : this.peopleDetailPageTranslation.scheduleCreatedSnackbar;

      const userWorkScheduleResult = await this.injector.get(UserWorkScheduleService).getById(this.id);
      this.userWorkSchedule.data = userWorkScheduleResult;
      this.getCurrentWorkSchedule();

      if (moment(this.currentWorkSchedule.startDate).isAfter(result.startDate)) {
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent(result.operation === 'update' ? EDIT_PAST_WS_CUSTOM : CREATE_PAST_WS_CUSTOM, this.eventDetail);
      }

      this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', {
        duration: 5000,
      });
    });
  }

  public async editCustomScheduleSimple(): Promise<void> {
    const editWorkScheduleSimpleTranslation = await this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-work-schedule-dialog-simple');
    const workScheduleInfo = {
      userWork: this.userWork,
      userPersonal: this.userPersonal,
      workSchedule: this.userWorkSchedule,
      userWorkId: this.userWorkSchedule.data._id,
      translation: editWorkScheduleSimpleTranslation,
      company: this.getMyCompany(),
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditWorkScheduleSimpleDialog, { data: workScheduleInfo });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (check.not.assigned(result) || result === false) {
        return;
      }
      const snackBarMessage =
        result.operation === 'update'
          ? this.peopleDetailPageTranslation.scheduleUpdatedSnackbar
          : this.peopleDetailPageTranslation.scheduleCreatedSnackbar;
      const userWorkScheduleResult = await this.injector.get(UserWorkScheduleService).getById(this.id);
      this.userWorkSchedule.data = userWorkScheduleResult;
      this.getCurrentWorkSchedule();

      this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', {
        duration: 5000,
      });
    });
  }

  public logTimeOffApproverChange() {
    this.injector.get(PrivateAmplitudeService).logEvent('select time off approver', {
      category: 'Time off',
      platform: 'Web',
      subcategory1: 'Settings',
      type: 'Time off settings',
    });
  }

  private async loadAvailableOptions() {
    const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();
    await Promise.all([
      this.checkPermissionsToActivateEmployee(allUserWork),
      this.checkPermissionsToDeactivateEmployee(allUserWork),
      this.checkPermissionsToDeleteEmployee(allUserWork),
      this.checkPermissionsEditPinCode(),
    ]);
  }

  private async refreshUserAccount(): Promise<void> {
    const userAccount: IUserAccountModel = await this.injector.get(UserAccountService).getById(this.id);
    this.userAccount = new GenericSimpleModel(this.injector, userAccount, UserAccountService, this.id);
  }

  private async checkPermissionsEditPinCode() {
    this.kioskPinCode = await this.injector.get(KioskService).fetchPinCodeStatus(this.id);
  }

  private async checkPermissionsToActivateEmployee(allUserWork: Array<IUserWorkModel>) {
    if (this.isNotMyUser !== true || check.not.assigned(this.userAccount?.data?.isActive) || this.userAccount?.data?.isActive === true) {
      this.canActivateEmployee = false;
      return;
    }

    if (this.myPermissions?.employees?.c_activateEmployees_all === true) {
      this.canActivateEmployee = true;
      return;
    }

    if (
      check.not.assigned(this.myPermissions?.employees?.c_activateEmployees_custom) ||
      check.emptyArray(this.myPermissions?.employees?.c_activateEmployees_custom)
    ) {
      this.canActivateEmployee = false;
      return;
    }

    const thisUserWork = allUserWork.find((iUserWork) => iUserWork._id === this.id);
    if (check.not.assigned(thisUserWork) || check.emptyObject(thisUserWork)) {
      this.canActivateEmployee = false;
      return;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
      null,
      'user-work',
      this.myPermissions.employees.c_activateEmployees_custom,
      null,
      thisUserWork,
      allUserWork,
      loggedUser
    );
    this.canActivateEmployee = customPermissionResult;
  }

  private async checkPermissionsToDeactivateEmployee(allUserWork: Array<IUserWorkModel>) {
    if (
      this.isNotMyUser !== true ||
      check.not.assigned(this.userAccount?.data?.isActive) ||
      check.assigned(this.userAccount?.data?.inactiveReason)
    ) {
      this.canDeactivateEmployee = false;
      return;
    }

    if (
      this.isAdmin === false &&
      check.assigned(this.userAccount) &&
      check.assigned(this.userAccount['rawData']) &&
      this.userAccount['rawData'].profileKey === 'admin'
    ) {
      this.canDeactivateEmployee = false;
      return;
    }

    if (this.myPermissions?.employees?.c_deactivateEmployees_all === true) {
      this.canDeactivateEmployee = true;
      return;
    }

    if (
      check.not.assigned(this.myPermissions?.employees?.c_deactivateEmployees_custom) ||
      check.emptyArray(this.myPermissions?.employees?.c_deactivateEmployees_custom)
    ) {
      this.canDeactivateEmployee = false;
      return;
    }

    const thisUserWork = allUserWork.find((iUserWork) => iUserWork._id === this.id);
    if (check.not.assigned(thisUserWork) || check.emptyObject(thisUserWork)) {
      this.canDeactivateEmployee = false;
      return;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
      null,
      'user-work',
      this.myPermissions.employees.c_deactivateEmployees_custom,
      null,
      thisUserWork,
      allUserWork,
      loggedUser
    );
    this.canDeactivateEmployee = customPermissionResult;
  }

  private async checkPermissionsToDeleteEmployee(allUserWork: Array<IUserWorkModel>) {
    if (this.isNotMyUser !== true || check.not.assigned(this.userAccount?.data?.isActive) || this.userAccount.data.isActive === true) {
      this.canDeleteEmployee = false;
      return;
    }

    if (this.myPermissions?.employees?.c_deleteEmployees_all === true) {
      this.canDeleteEmployee = true;
      return;
    }

    if (
      check.not.assigned(this.myPermissions?.employees?.c_deleteEmployees_custom) ||
      check.emptyArray(this.myPermissions?.employees?.c_deleteEmployees_custom)
    ) {
      this.canDeleteEmployee = false;
      return;
    }

    const thisUserWork = allUserWork.find((iUserWork) => iUserWork._id === this.id);
    if (check.not.assigned(thisUserWork) || check.emptyObject(thisUserWork)) {
      this.canDeleteEmployee = false;
      return;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
      null,
      'user-work',
      this.myPermissions.employees.c_deleteEmployees_custom,
      null,
      thisUserWork,
      allUserWork,
      loggedUser
    );
    this.canDeleteEmployee = customPermissionResult;
  }

  externalIdUniqueValidationFunction: InputValidationFunction = (value: any): InputValidation => {
    const inputValidation = new InputValidation();
    if (check.nonEmptyString(value) && this.existingExternalIds.includes(value)) {
      inputValidation.setError('notValid');
    }

    return inputValidation;
  };

  nationalInsuranceNumberValidationFunction: InputValidationFunction = (value: any): InputValidation => {
    const inputValidation = new InputValidation();

    if (check.not.string(value) || value.includes(':')) {
      inputValidation.setError('notValid');
    }

    return inputValidation;
  };

  async getUserDatev() {
    if (this.isDatevActive !== true) {
      this.canSeeDatevFields = false;
      return;
    }

    const datevFieldsPermissions = await this.injector.get(FieldLevelPermissionsService).getByCollection('user-datev');

    if (check.assigned(datevFieldsPermissions) && check.nonEmptyObject(datevFieldsPermissions)) {
      this.userDatevPermissions = datevFieldsPermissions['user-datev'];
    }

    const findQuery = {
      companyId: this.userWork.data.companyId,
      employees: { $in: [this.userAccount.data._id] },
    };

    const datevSettings = await this.injector.get(DatevSettingsService).find(findQuery);

    if (check.not.assigned(datevSettings) || check.emptyArray(datevSettings)) {
      this.canSeeDatevFields = false;
      return;
    }

    const userDatevSettings = datevSettings[0];
    this.datevSettingsId = userDatevSettings._id;

    const userDatev = await this.injector.get(UserDatevService).getById(this.id);
    this.canSeeDatevFields = true;

    await this.manageUserSalaries(userDatev, userDatevSettings);

    return userDatev;
  }

  async manageShiftPlanVisibility() {
    const shiftPlanStatus = await this.injector.get(CloudRoutesService).getAppStatus('shift-plan');
    const shiftPlanPermissions = (await this.injector.get(PrivateSecurityService).getAllPermissions())?.['shiftplan-app'];
    const customPermission = 'c_editEmployeeProfileShiftplan';
    const featureGroup = shiftPlanPermissions?.features;

    const validCustomArray =
      check.array(featureGroup?.[`${customPermission}_custom`]) && check.nonEmptyArray(featureGroup?.[`${customPermission}_custom`]);
    if (featureGroup?.[`${customPermission}_all`] || featureGroup?.[`${customPermission}_own`] || validCustomArray) {
      const employeesToAccess = await this.injector.get(ShiftPlanEmployeeListService).getEmployeesEditAccess();
      this.showShiftplanLink = this.getShifplanLinkAccess(employeesToAccess);
    }

    this.shiftPlanActive = shiftPlanStatus?.isActive;
    this.cdr.detectChanges();
  }

  getShifplanLinkAccess(employeesToAccess: Array<string>) {
    if (check.emptyArray(employeesToAccess)) {
      return true;
    }
    return employeesToAccess.includes(this.id);
  }

  openShiftPlanActivationDialog() {
    const data = {
      peopleDetailPageTranslation: this.peopleDetailPageTranslation,
      userPersonal: this.userPersonal,
      userWork: this.userWork,
      userAccount: this.userAccount,
      isNotAdmin: !this.isAdmin && !this.isHrAdmin,
    };
    this.injector.get(MatLegacyDialog).open(ActivateShiftPlanDialog, { data: data });
  }

  async checkShiftPlanActivationIsPossible() {
    const [allLocations, allRoles, allWorkingAreas] = await Promise.all([
      this.injector.get(OfficeService).getData({ activeForShiftplan: true }),
      this.injector.get(ShiftPlanRoleSettingsService).getRoles(),
      this.injector.get(ShiftPlanWorkingAreaSettingsService).getWorkingAreas(),
    ]);

    if (check.not.emptyArray(allLocations) && check.not.emptyArray(allRoles) && check.not.emptyArray(allWorkingAreas)) {
      this.openShiftPlanActivationDialog();
    } else {
      const data = await this.checkShiftplanActivationData(allLocations, allRoles, allWorkingAreas);
      this.injector.get(MatLegacyDialog).open(CompleteShiftplanActivationDialog, { data: data });
    }
  }

  private async checkShiftplanActivationData(
    allLocations: IOfficeModel[],
    allRoles: IShiftPlanRoleSettingsModel[],
    allWorkingAreas: IShiftPlanWorkingAreaSettingsModel[]
  ) {
    const permissions = await this.injector.get(PrivateSecurityService).getAllPermissions();
    const officePermissions = permissions['office'];
    const data = {
      translations: {
        titleText: this.peopleDetailPageTranslation.shiftPlanNotPossibleTitle,
        subtitleText: this.peopleDetailPageTranslation.shiftPlanNotPossibleSubtitle,
        createOffice: this.peopleDetailPageTranslation.activateShiftplanCreateOffice,
        createWA: this.peopleDetailPageTranslation.activateShiftplanCreateWA,
        createRole: this.peopleDetailPageTranslation.activateShiftplanCreateRole,
        confirmButtonText: this.peopleDetailPageTranslation.OkButton,
        learnMore: this.peopleDetailPageTranslation.learnMore,
        noPermissions: this.peopleDetailPageTranslation.activateShiftPlanNoPermissions,
        urlLocations: this.peopleDetailPageTranslation.urlLocations,
        urlSetUpShiftPlan: this.peopleDetailPageTranslation.urlSetUpShiftPlan,
      },
      permissions: {
        rolePermissions: permissions['shift-plan-role'].create_all,
        workingAreaPermissions: permissions['shift-plan-working-area'].create_all,
        officePermissions: officePermissions['create_all'],
      },
      activated: {
        locations: allLocations?.length,
        roles: allRoles?.length,
        workingAreas: allWorkingAreas?.length,
      },
    };
    return data;
  }

  async setUserWorkClearButton() {
    this.userWorkCanEdit = await this.userWork.canEdit;
  }

  protected navigateToPayroll() {
    if (check.not.assigned(this.payrollGroupId)) {
      return;
    }

    this.router.navigate(['/cloud/people/payroll'], {
      queryParams: { payrollGroupId: this.payrollGroupId, period: this.payrollPeriod },
    });
  }

  private async manageUserSalaries(userDatev: any, userDatevSettings: IDatevSettingsModel) {
    if (this.myPermissions?.payroll?.read_all === true) {
      const userSalaries = await this.injector.get(UserSalaryService).getUserSalaries(this.id);
      this.userSalaries = new GenericSimpleModel(this.injector, { _id: this.id, userSalaries }, UserSalaryService, this.id);

      const userSalary =
        check.assigned(this.userSalaries?.data) && check.nonEmptyArray(this.userSalaries.data.userSalaries)
          ? this.userSalaries.data.userSalaries[0]
          : undefined;

      if (check.not.assigned(userDatev?.salaryNumber) && check.assigned(userSalary) && userSalary.payPeriod !== 'Hourly') {
        userDatev.salaryNumber = userDatevSettings.payPeriodMapper.find(
          (period) => period.payPeriod === userSalary.payPeriod
        )?.salaryNumber;
      }
      if (check.not.assigned(userDatev?.salaryType) && check.assigned(userSalary)) {
        userDatev.salaryType = userDatevSettings.payPeriodMapper.find((period) => period.payPeriod === userSalary.payPeriod)?.salaryType;
      }
    }
  }

  async switchShowDatevFields() {
    if (this.showDatevFields === false) {
      const userDatev = await this.injector.get(UserDatevService).getById(this.id);
      if (check.assigned(userDatev)) {
        this.isDatevLodas = userDatev.target === picklists.DATEV_INTERFACE_LODAS;
        this.userDatev = this.userDatev.setNewData(userDatev);
      }
    }

    this.showDatevFields = !this.showDatevFields;
  }

  exitTimeOffFullScreen() {
    this.injector.get(PeopleDetailService).closeEmployeeTimeOff();
    this.injector.get(GlobalBarService).setPageName(this.peopleDetailPageTranslation.pageName);
    this.refreshSecondaryMenu();
  }

  async updateDatevSalary(field: string) {
    if (!this.userDatev.data[field]) {
      return;
    }

    const userDatev = await this.getUserDatev();
    if (check.assigned(userDatev)) {
      this.userDatev = new GenericSimpleModel(this.injector, userDatev, UserDatevService, this.id);
    }
  }

  ngOnDestroy(): void {
    this.seeDetailsUserIdSubscription.unsubscribe();
  }
}
