import { Pipe, PipeTransform } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as moment from 'moment';
import { I18nDataPipe } from '../i18n-data/i18n-data.pipe';

@Pipe({
    name: 'timeAgo'
})

// This pipe allows to show the "days ago" for a given date with respect to today
export class TimeAgoPipe implements PipeTransform {
    constructor(private internationalizationService: InternationalizationService, private i18nDataPipe: I18nDataPipe) { }

    transform(dateIn: string, onlyDays: boolean = true): string {
        const miscTranslation = this.internationalizationService.getMiscTranslation();
        const now = moment(new Date());
        const referenceDate = moment(dateIn);
        const nDays = now.diff(referenceDate, 'days');
        if (onlyDays === true) {
            return (nDays === 0 ? miscTranslation.today : this.i18nDataPipe.transform(miscTranslation.lastXDaysAgo, { nDays }));
        }
        const nMonths = now.diff(referenceDate, 'months');
        const nHours = now.diff(referenceDate, 'hours');
        const convertedValue = { nMonths, nDays, nHours };

        if (nMonths > 1) {
            return this.i18nDataPipe.transform(miscTranslation.lastXMonthsAgo, convertedValue);
        } else if (nDays > 1) {
            return this.i18nDataPipe.transform(miscTranslation.lastXDaysAgo, convertedValue);
        } else if (nDays === 1) {
            return miscTranslation.yesterday;
        } else if (nHours >= 1) {
            return this.i18nDataPipe.transform(miscTranslation.lastXHoursAgo, convertedValue);
        } else {
            return miscTranslation.lessThanOneHourAgo;
        }
    }
}