import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kenjo-icon',
  templateUrl: 'kenjo-icon.component.html',
  styleUrls: ['kenjo-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  sizeInPx = '24px';

  @Input()
  set size(size: number) {
    this.sizeInPx = `${size}px`;
  }

  @Input() iconClass: string = '';
  @Input() id: string = '';
  @Input() disabled: boolean = false;
}
