import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import {
  IPayrollSurchargeRule,
  PayrollSurchargeRulesService,
} from '@app/cloud-features/settings-surcharges/services/payroll-surcharge-rules.service';
import { PayrollSurchargeUtilsService } from '@app/cloud-features/settings-surcharges/services/payroll-surcharge-utils.service';
import { ISelectOption } from '@app/standard/core/select-option';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as moment from 'moment';

@Component({
  templateUrl: 'assign-surcharge-rule.dialog.html',
  styleUrls: ['assign-surcharge-rule.dialog.scss'],
})
export class AssignSurchargeRuleDialog implements OnInit {
  pageTranslation: { [translationKey: string]: string };
  userId: string;
  selectOptions: Array<ISelectOption> = [];
  selectedSurchargeIds: Array<string> = [];
  autocompleteOptions: Array<ISelectOption> = [];
  isLoading: boolean = true;
  private availableSurcharges: Array<IPayrollSurchargeRule>;
  private surchargeIdToSurchargeRule: { [surchargeId: string]: IPayrollSurchargeRule } = {};

  constructor(
    public dialogRef: MatLegacyDialogRef<AssignSurchargeRuleDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.userId = this.data.userId;
    this.initTranslations();
    this.calculateAvailableSurcharges();
  }

  private async initTranslations(): Promise<void> {
    this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('settings-payroll-page');
  }

  // available surcharges: those not assigned to the current employee and not overdue (endDate in the future or empty)
  private async calculateAvailableSurcharges(): Promise<void> {
    try {
      const allSurcharges: Array<IPayrollSurchargeRule> = await this.injector.get(PayrollSurchargeRulesService).getAll();

      const assignedSurcharges: Array<IPayrollSurchargeRule> = await this.injector
        .get(PayrollSurchargeRulesService)
        .getSurchargeRulesOfEmployee(this.userId);
      await this.initAutocompleteOptions(allSurcharges, assignedSurcharges);

      const assignedSurchargeIds = assignedSurcharges.map((surcharge) => surcharge._id);
      const today: moment.Moment = moment.utc().startOf('day');
      this.availableSurcharges = allSurcharges.filter((surchargeRule: IPayrollSurchargeRule) => {
        const ruleEndDate = surchargeRule?.ruleEndDate ? moment(surchargeRule.ruleEndDate) : null; // do not consider overdue rules
        if (ruleEndDate && ruleEndDate.isBefore(today, 'day')) {
          return false;
        }

        if (surchargeRule.assignedUserIds?.length && surchargeRule.assignedUserIds.includes(this.userId)) {
          // do not consider rules already assigned to this employee
          return false;
        }

        this.selectOptions.push({
          name: surchargeRule.name,
          value: surchargeRule._id,
        });
        this.surchargeIdToSurchargeRule[surchargeRule._id] = surchargeRule;
        return !assignedSurchargeIds.includes(surchargeRule._id);
      });

      this.isLoading = false;
    } catch (error) {
      this.availableSurcharges = [];
      this.isLoading = false;
    }
  }

  private async initAutocompleteOptions(
    allSurcharges: Array<IPayrollSurchargeRule>,
    assignedSurcharges: Array<IPayrollSurchargeRule>
  ): Promise<void> {
    const assignedSurchargeIds = assignedSurcharges.map((surcharge) => surcharge._id);
    const mapSurchargeIdToConditionMessage: { [surchargeId: string]: string } = await this.injector
      .get(PayrollSurchargeUtilsService)
      .calculateSurchargeIdToConditionMessage(allSurcharges);

    const today: moment.Moment = moment.utc().startOf('day');
    this.autocompleteOptions = allSurcharges
      .filter((surchargeRule: IPayrollSurchargeRule) => {
        const ruleEndDate = surchargeRule?.ruleEndDate ? moment(surchargeRule.ruleEndDate) : null; // do not consider overdue rules
        if (ruleEndDate && ruleEndDate.isBefore(today, 'day')) {
          return false;
        }

        if (surchargeRule.assignedUserIds?.length && surchargeRule.assignedUserIds.includes(this.userId)) {
          // do not consider rules already assigned to this employee
          return false;
        }

        this.selectOptions.push({
          name: surchargeRule.name,
          value: surchargeRule._id,
        });
        this.surchargeIdToSurchargeRule[surchargeRule._id] = surchargeRule;
        return !assignedSurchargeIds.includes(surchargeRule._id);
      })
      .map((surchargeRule: IPayrollSurchargeRule): ISelectOption => {
        return {
          name: surchargeRule.name,
          value: surchargeRule._id,
          subtitle: mapSurchargeIdToConditionMessage[surchargeRule._id],
          rightColText: `${surchargeRule.extraRatePercentage}%`,
        };
      });
  }

  public async assignRules(): Promise<void> {
    if (!this.selectedSurchargeIds?.length) {
      this.closeDialog();
      return;
    }

    try {
      const newRules: Array<IPayrollSurchargeRule> = [];
      const promisesToUpdateRules = [];
      this.selectedSurchargeIds.forEach((surchargeId: string) => {
        const surchargeRule: IPayrollSurchargeRule = this.surchargeIdToSurchargeRule[surchargeId];
        promisesToUpdateRules.push(this.injector.get(PayrollSurchargeRulesService).addEmployeeToRule(this.userId, surchargeId));
        newRules.push(surchargeRule);
      });
      await Promise.all(promisesToUpdateRules);
      this.injector.get(MatLegacySnackBar).open(this.pageTranslation.assignSurchargesFromCompensationSnackbar, 'OK', { duration: 5000 });
      this.closeDialog(newRules);
    } catch (error) {
      this.closeDialog();
    }
  }

  public closeDialog(assignToRules?: Array<IPayrollSurchargeRule>): void {
    this.dialogRef.close(assignToRules);
  }
}
