<orgos-container aspect="card" class="dpp-main-container" *ngIf="smartDocsSettings">
  <orgos-column-container centerColumns="true" class="kenjo-border-bottom kenjo-pv-10px kenjo-ph-30px">
    <orgos-column class="kenjo-font-size-18px">{{pageTranslation.smartDocsSectionTitle}}</orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" (click)="goBack()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575">clear</kenjo-icon>
  </orgos-column-container>

  <div class="kenjo-p-30px">
    <!-- Important info -->
    <kenjo-collapsible-wrapper [maxHeight]="30">
      <div class="kenjo-font-size-18px kenjo-mb-20px kenjo-mt-10px">{{pageTranslation.importantInfo}}</div>
      <div class="kenjo-font-size-14px kenjo-pv-10px kenjo-text-transform-uppercase">{{pageTranslation.disablingTagsTitle}}</div>
      <div class="kenjo-font-size-14px kenjo-pb-20px kenjo-mr-120px kenjo-font-color-light-text-757575">{{pageTranslation.disablingText}}</div>
      <div class="kenjo-font-size-14px kenjo-pv-10px kenjo-text-transform-uppercase">{{pageTranslation.deletingTagsTitle}}</div>
      <div class="kenjo-font-size-14px kenjo-pb-20px kenjo-mr-120px kenjo-font-color-light-text-757575">{{pageTranslation.deletingText}}</div>
      <div class="kenjo-font-size-14px kenjo-pv-10px kenjo-text-transform-uppercase">{{pageTranslation.addingMultipleTitle}}</div>
      <div class="kenjo-font-size-14px kenjo-pb-20px kenjo-mr-120px kenjo-font-color-light-text-757575">{{pageTranslation.addingMultipleText}}</div>
    </kenjo-collapsible-wrapper>

    <orgos-column-container>
      <div orgosColumn="3" class="kenjo-border-bottom kenjo-mv-30px"></div>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>

    <!-- Mandatory tags -->
    <div class="kenjo-font-size-16px kenjo-mb-20px kenjo-mt-10px">{{pageTranslation.makingTagsMandatoryTitle}}</div>
    <orgos-column-container>
      <div orgosColumn="3" class="kenjo-mr-30px kenjo-font-color-light-text-757575 kenjo-mb-10px">{{pageTranslation.makingTagsMandatoryText}}</div>
      <mat-slide-toggle orgosColumn="1" class="kenjo-ml-50px" [(checked)]="smartDocsSettings.areTagsMandatory" (change)="updateMandatoryTags($event.checked)"> </mat-slide-toggle>
    </orgos-column-container>

    <orgos-column-container>
      <div orgosColumn="3" class="kenjo-border-bottom kenjo-mv-30px"></div>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>

    <!-- Default tags -->
    <div class="kenjo-font-size-16px kenjo-mb-20px kenjo-mt-10px">{{pageTranslation.defaultTitle}}</div>
    <orgos-column-container *ngFor="let defaultTag of defaultTags" class="kenjo-mt-10px kenjo-border kenjo-border-radius" centerColumns="true">
      <orgos-column size="1" class="dpp-title kenjo-ml-20px">
        <div class="dpp-color-container" [style.background-color]=" colors[defaultTag.data.color]"></div>
        <kenjo-simple-truncate-text class="kenjo-ml-10px kenjo-mr-30px"> {{defaultTag.data.name}} </kenjo-simple-truncate-text>
      </orgos-column>
      <orgos-column size="0" class="dpp-clickable-icon-container kenjo-border-left" (click)="editTagDialog(defaultTag.rawData)">
        <kenjo-icon size="20" class="kenjo-font-color-primary-5993e3 kenjo-pv-10px kenjo-ph-15px" [matTooltip]="pageTranslation.editTagTooltip" matTooltipPosition="above">mode_edit</kenjo-icon>
      </orgos-column>
      <orgos-column size="0" class="kenjo-border-left">
        <mat-slide-toggle class="dpp-slide-toggle kenjo-pv-10px kenjo-ph-20px" [(ngModel)]="defaultTag.data.isActive" [(checked)]="defaultTag.data.isActive" (change)="changeTagStatus(defaultTag, $event.checked)"></mat-slide-toggle>
      </orgos-column>
    </orgos-column-container>

    <!-- Custom tags -->
    <div class="kenjo-mt-20px dpp-custom-tags-title">
      <div class="kenjo-font-size-16px kenjo-mv-20px">{{pageTranslation.customTitle}}</div>
      <orgos-action icon="add_circle" (click)="addTag()">{{pageTranslation.addTagButtonLabel}}</orgos-action>
    </div>

    <orgos-column-container *ngFor="let customTag of customTags; let index = index" class="kenjo-border kenjo-border-radius" [class.kenjo-mt-10px]="index !== 0" centerColumns="true">
      <orgos-column size="1" class="dpp-title">
        <div class="dpp-color-container kenjo-ml-20px" [style.background-color]=" colors[customTag.rawData.color]"></div>
        <kenjo-simple-truncate-text class="kenjo-ml-10px kenjo-mr-30px"> {{customTag.rawData.name}} </kenjo-simple-truncate-text>
      </orgos-column>
      <orgos-column size="0" class="dpp-clickable-icon-container kenjo-border-left" (click)="editTagDialog(customTag.rawData)">
        <kenjo-icon size="20" class="kenjo-font-color-primary-5993e3 kenjo-pv-10px kenjo-ph-15px" [matTooltip]="pageTranslation.editTagTooltip" matTooltipPosition="above">mode_edit</kenjo-icon>
      </orgos-column>
      <orgos-column size="0" class="dpp-clickable-icon-container kenjo-border-left">
        <kenjo-icon
          size="20"
          class="kenjo-pv-10px kenjo-ph-15px"
          [class]="customTag.data.documentsNumber?.length > 0 ? 'kenjo-font-color-disabled-c4c4c4' : 'kenjo-cursor-pointer kenjo-font-color-danger-ff5757'"
          (click)="deleteTag(customTag)"
          [matTooltip]="customTag.data.documentsNumber?.length > 0 ? pageTranslation.warningMessageText : pageTranslation.deleteTagButtonLabel"
          matTooltipPosition="above"
          >delete</kenjo-icon
        >
      </orgos-column>
      <orgos-column size="0" class="kenjo-border-left">
        <mat-slide-toggle class="dpp-slide-toggle kenjo-pv-10px kenjo-ph-20px" [(ngModel)]="customTag.data.isActive" [(checked)]="customTag.data.isActive" (change)="changeTagStatus(customTag, $event.checked)"> </mat-slide-toggle>
      </orgos-column>
    </orgos-column-container>
  </div>
</orgos-container>
