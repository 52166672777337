import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  IUserEmploymentTerminationReasonModel,
  UserEmploymentTerminationReasonService,
} from '@app/standard/services/user/user-employment-termination-reason.service';
import * as check from 'check-types';

@Component({
  selector: 'user-employment-termination-type-dialog',
  templateUrl: './user-employment-termination-type-dialog.html',
  styleUrls: ['./user-employment-termination-type-dialog.scss'],
})
export class UserEmploymentTerminationTypeDialog implements OnInit {
  pageTranslation: any = {};
  terminationReason: IUserEmploymentTerminationReasonModel;
  inputValidation: InputValidation;
  dialogTitle: string = '';

  constructor(
    private dialogRef: MatLegacyDialogRef<UserEmploymentTerminationTypeDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: IUserEmploymentTerminationReasonModel,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.inputValidation = new InputValidation();
    this.getTranslations();
    this.terminationReason = check.assigned(this.data)
      ? this.data
      : {
          name: '',
          isDefault: false,
          isActive: true,
        };
  }

  private async getTranslations() {
    try {
      this.pageTranslation = await this.injector
        .get(InternationalizationService)
        .getAllTranslation('user-employment-termination-type-dialog');
      this.dialogTitle = check.assigned(this.data)
        ? this.injector.get(I18nDataPipe).transform(this.pageTranslation.editTerminationReason, { name: this.data.name })
        : this.pageTranslation.addTerminationReason;
    } catch {
      this.pageTranslation = {};
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async saveTerminationReason() {
    try {
      if (check.assigned(this.terminationReason._id)) {
        await this.injector.get(UserEmploymentTerminationReasonService).updateById(this.terminationReason._id, this.terminationReason);
        this.dialogRef.close();
        return;
      }
      await this.injector.get(UserEmploymentTerminationReasonService).create(this.terminationReason);

      this.dialogRef.close();
    } catch {
      //Do nothing
    }
  }
}
