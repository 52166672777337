import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kenjo-full-screen-header',
  templateUrl: 'full-screen-header.component.html',
  styleUrls: ['full-screen-header.component.scss']
})
export class FullScreenHeaderComponent {
  @Input() pageName: string;
  @Input() enableBackArrow: boolean = false;
  @Input() showRightIcon: boolean = false;
  @Input() selectedTab: number = 0;
  @Input() rightAreaText: string;
  @Input() rightAreaIcon: string = 'add_circle';

  @Output() rightAreaClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() backClicked: EventEmitter<void> = new EventEmitter<void>();

  clickRightArea(): void {
    this.rightAreaClicked.emit();
  }

  clickBack(): void {
    this.backClicked.emit();
  }
}
