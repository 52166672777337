<button
  matTooltipPosition="before"
  [matTooltip]="tooltip"
  [class.icon-button]="iconButton"
  [disabled]="disabled"
  [class.brc-basic]="color === 'Basic'"
  [class.brc-primary]="color === 'Primary'"
  [class.brc-success]="color === 'Success'"
  [class.brc-danger]="color === 'Danger'"
  [class.brc-neutral]="color === 'Neutral'"
  [class.brc-sign-in]="color === 'Sign-In'"
  [class.brc-secondary]="color === 'Secondary'"
  [class.brc-upgrade]="color === 'Upgrade'"
  [class.brc-disabled]="color === 'Disabled' || disabled"
  [class.brc-inverted]="invertColors"
  [style.padding]="padding"
  [id]="id"
>
  <ng-content></ng-content>
</button>
