import { Injectable, Injector } from '@angular/core';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanPermissionsService {
  private SHIFTPLAN_APP_PERMISSIONS_KEY: string = 'shiftplan-app';
  private permissions$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private shiftplanAppPermissions$: BehaviorSubject<IShiftPlanPermissions> = new BehaviorSubject<IShiftPlanPermissions>(null);
  private SHIFTPLAN_PERMISSIONS_SERVICE: string = 'ShiftPlanPermissionsService';

  constructor(private injector: Injector, private errorManager: ErrorManagerService) {}

  async refreshPermissions(): Promise<void> {
    try {
      const permissions = await this.injector.get(PrivateSecurityService).getAllPermissions();
      this.permissions$.next(permissions);

      this.parseShiftplanPermissions(permissions);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_PERMISSIONS_SERVICE, 'refreshPermissions');
    }
  }

  private parseShiftplanPermissions(permissions): void {
    const schedulingPermissions = permissions?.[this.SHIFTPLAN_APP_PERMISSIONS_KEY]?.scheduling;
    const featuresPermissions = permissions?.[this.SHIFTPLAN_APP_PERMISSIONS_KEY]?.features;
    const shiftplanPermissions = {};

    //Fill open-scheduled shifts permissions
    ['open', 'scheduled'].forEach((shiftType) => {
      shiftplanPermissions[shiftType] = {
        view_publish: schedulingPermissions?.[`c_${shiftType}ShiftsViewPublished`] ?? false,
        view_unpublish: schedulingPermissions?.[`c_${shiftType}ShiftsViewUnpublished`] ?? false,
        create: schedulingPermissions?.[`c_${shiftType}ShiftsCreate`] ?? false,
        edit: schedulingPermissions?.[`c_${shiftType}ShiftsEdit`] ?? false,
        delete: schedulingPermissions?.[`c_${shiftType}ShiftsDelete`] ?? false,
      };
    });

    //Templates
    shiftplanPermissions['templates'] = {
      create: permissions?.['shift-plan-template']?.create_own || permissions?.['shift-plan-template']?.create_all,
      read: permissions?.['shift-plan-template']?.read_own || permissions?.['shift-plan-template']?.read_all,
      delete_all: permissions?.['shift-plan-template']?.delete_all,
      delete_own: permissions?.['shift-plan-template']?.delete_own,
      edit_all: permissions?.['shift-plan-template']?.edit_all,
      edit_own: permissions?.['shift-plan-template']?.edit_own,
    };

    //Actions
    const actions = {
      copy: schedulingPermissions?.c_copyShifts ?? false,
      publish: schedulingPermissions?.c_publishShifts ?? false,
      bulk_delete: schedulingPermissions?.c_deleteShiftsInBulk ?? false,
      capacity: schedulingPermissions?.c_capacityManagement ?? false,
    };

    //Tabs Visibility
    const tabs = {
      view_schedule: featuresPermissions?.c_viewScheduleTab ?? false,
      view_settings: featuresPermissions?.c_viewSettingsTab ?? false,
    };

    const finalPermissions = { ...shiftplanPermissions, ...actions, ...tabs } as IShiftPlanPermissions;
    this.shiftplanAppPermissions$.next(finalPermissions);
  }

  async getAllPermisions(): Promise<any> {
    return await this.permissions$.pipe(take(1)).toPromise();
  }

  async getShiftplanAppPermissions(): Promise<IShiftPlanPermissions> {
    return await this.shiftplanAppPermissions$.pipe(take(1)).toPromise();
  }

  async getShiftplPermissionsByCollection(collection: string): Promise<any> {
    const permissions = await this.permissions$.pipe(take(1)).toPromise();
    return permissions[collection];
  }

  disposePermissions() {
    this.permissions$.next(null);
    this.shiftplanAppPermissions$.next(null);
  }
}

export interface IShiftPlanPermissions {
  templates: {
    create: boolean;
    read: boolean;
    delete_all: boolean;
    delete_own: boolean;
    edit_all: boolean;
    edit_own: boolean;
  };
  copy: boolean;
  publish: boolean;
  bulk_delete: boolean;
  open: {
    view_publish: boolean;
    view_unpublish: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
  };
  scheduled: {
    view_publish: boolean;
    view_unpublish: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
  };
  capacity: boolean;
  view_schedule: boolean;
  view_settings: boolean;
}
