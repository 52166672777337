import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';
import * as _ from 'lodash';

declare const window: any;
declare const document: any;

@Injectable({
  providedIn: 'root',
})
export class PrivateChargebeeService {
  private CHARGEBEE_URL = `${environment.PEOPLE_CLOUD_APP_URL}/chargebee`;

  constructor(private injector: Injector) {}

  async getChargebeeInstance() {
    try {
      if (check.not.assigned(window['Chargebee'])) {
        await this.initChargebeeClient();
      }

      return window['Chargebee'].init({
        site: environment.CHARGEBEE_SITE,
        publishableKey: environment.CHARGEBEE_PUBLISHABLE_KEY,
      });
    } catch (error) {
      this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'runChargebee');
    }
  }

  private async initChargebeeClient(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.id = 'chargebee-script';
      script.type = 'text/javascript';
      script.src = 'https://js.chargebee.com/v2/chargebee.js';
      document.getElementsByTagName('head')[0].appendChild(script);
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error('Could not load Chargebee'));
      };
    });
  }

  public async getPlans(): Promise<IChargebeeStatus> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const status = await this.injector
        .get(HttpClient)
        .get<IChargebeeStatus>(`${this.CHARGEBEE_URL}/plans`, httpOptions)
        .toPromise();
      return status;
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, PrivateChargebeeService.name, 'getPlans');
    }
  }

  public async getCustomerInfo(): Promise<IChargebeeCustomerWrapper> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const customerInfo = await this.injector
        .get(HttpClient)
        .get<IChargebeeCustomerWrapper>(`${this.CHARGEBEE_URL}/customer`, httpOptions)
        .toPromise();
      return customerInfo;
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, PrivateChargebeeService.name, 'getCustomerInfo');
    }
  }

  public async updateCustomerInfo(dataToUpdate: IChargebeeCustomer): Promise<IChargebeeCustomer> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const customerInfo = await this.injector
        .get(HttpClient)
        .put<IChargebeeCustomer>(`${this.CHARGEBEE_URL}/customer`, dataToUpdate, httpOptions)
        .toPromise();
      this.injector.get(PrivateChurnzeroService).logSimpleEvent('BILLING_INFO_UPDATE');
      return customerInfo;
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'updateCustomerInfo');
    }
  }

  public async updateCustomerBillingInfo(
    dataToUpdate: IChargebeeCustomerBillingInfo
  ): Promise<IChargebeeCustomer> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const customerInfo = await this.injector
        .get(HttpClient)
        .put<IChargebeeCustomer>(
          `${this.CHARGEBEE_URL}/customer/billing`,
          dataToUpdate,
          httpOptions
        )
        .toPromise();
      return customerInfo;
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawError(
          error,
          PrivateChargebeeService.name,
          error.error && error.error.param
            ? `updateCustomerBillingInfo-${error.error.param}`
            : 'updateCustomerBillingInfo'
        );
    }
  }

  public async getCustomerSubscriptions(): Promise<IChargebeeSubscriptionWrapper> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const customerSubscriptionsWrapper = await this.injector
        .get(HttpClient)
        .get<IChargebeeSubscriptionWrapper>(`${this.CHARGEBEE_URL}/subscriptions`, httpOptions)
        .toPromise();
      return customerSubscriptionsWrapper;
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, PrivateChargebeeService.name, 'getCustomerSubscriptions');
    }
  }

  public updateCustomerSubscription(subscriptionId: string, newPlanId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const updateBody = {
        plan_id: newPlanId,
        invoice_immediately: true,
      };

      this.injector
        .get(HttpClient)
        .put(`${this.CHARGEBEE_URL}/subscriptions/${subscriptionId}`, updateBody, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(
            this.injector
              .get(ErrorManagerService)
              .handleRawError(error, PrivateChargebeeService.name, 'updateCustomerSubscription')
          );
        });
    });
  }

  public getInvoices(): Promise<Array<IChargebeeInvoice>> {
    return new Promise<Array<IChargebeeInvoice>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .get(`${this.CHARGEBEE_URL}/invoices`, httpOptions)
        .toPromise()
        .then((customerInvoices: Array<IChargebeeInvoice>) => {
          resolve(customerInvoices);
        })
        .catch((error) => {
          reject(
            this.injector
              .get(ErrorManagerService)
              .handleRawErrorSilently(error, PrivateChargebeeService.name, 'getInvoices')
          );
        });
    });
  }

  public downloadInvoice(invoiceId: string): void {
    const httpHeaders = new HttpHeaders().set(
      'Authorization',
      this.injector.get(AuthenticationService).getAuthorizationHeader()
    );

    const httpOptions = {
      headers: httpHeaders,
    };

    this.injector
      .get(HttpClient)
      .get(`${this.CHARGEBEE_URL}/invoices/${invoiceId}/download`, httpOptions)
      .toPromise()
      .then((invoiceDownloadInfo: { download_url: string }) => {
        window.open(invoiceDownloadInfo.download_url);
      })
      .catch((error) => {
        this.injector
          .get(ErrorManagerService)
          .handleRawError(error, PrivateChargebeeService.name, 'downloadInvoice');
      });
  }

  public downloadCreditNote(creditNoteId: string): void {
    const httpHeaders = new HttpHeaders().set(
      'Authorization',
      this.injector.get(AuthenticationService).getAuthorizationHeader()
    );

    const httpOptions = {
      headers: httpHeaders,
    };

    this.injector
      .get(HttpClient)
      .get(`${this.CHARGEBEE_URL}/credit-notes/${creditNoteId}/download`, httpOptions)
      .toPromise()
      .then((invoiceDownloadInfo: { download_url: string }) => {
        window.open(invoiceDownloadInfo.download_url);
      })
      .catch((error) => {
        this.injector
          .get(ErrorManagerService)
          .handleRawError(error, PrivateChargebeeService.name, 'downloadInvoice');
      });
  }

  public async getPaymentSources(): Promise<Array<IChargebeePaymentSource>> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const paymentSources = await this.injector
        .get(HttpClient)
        .get<Array<IChargebeePaymentSource>>(`${this.CHARGEBEE_URL}/payment-sources`, httpOptions)
        .toPromise();
      return paymentSources;
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'getPaymentSources');
    }
  }

  public async deletePaymentSource(paymentSourceId: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      await this.injector
        .get(HttpClient)
        .delete(`${this.CHARGEBEE_URL}/payment-sources/${paymentSourceId}`, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'deletePaymentSource');
    }
  }

  public async getHostedPageToManagePaymentSources(customerId: string): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const hostedPage = await this.injector
        .get(HttpClient)
        .get<IChargebeeHostedPage>(
          `${this.CHARGEBEE_URL}/hosted-page/manage-payment-sources/${customerId}`,
          httpOptions
        )
        .toPromise();
      return hostedPage;
    } catch (error) {
      this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'getHostedPageToManagePaymentSources');
    }
  }

  public async getCheckoutPage(
    plan: string,
    billingFrequency: 'yearly' | 'monthly',
    recruitingAddon: string,
    datevImplementation: boolean,
    onboardingAddon: boolean,
    selectedEmployees?: number
  ): Promise<IChargebeeHostedPageWrapper> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const checkoutPage = await this.injector
        .get(HttpClient)
        .post<IChargebeeHostedPageWrapper>(
          `${this.CHARGEBEE_URL}/checkout-page`,
          {
            plan,
            billingFrequency,
            recruitingAddon,
            datevImplementation,
            onboardingAddon,
            selectedEmployees,
          },
          httpOptions
        )
        .toPromise();
      return checkoutPage;
    } catch (error) {
      this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'getCheckoutPage');
    }
  }

  public async getChargebeeStatus(): Promise<IChargebeeStatus> {
    const defaultStatus = {
      subscriptions: [],
      plans: [],
      customerStatus: '',
      showFreeTrialBanner: false,
      overdueAmount: { amount: 0, currency: 'EUR' },
    };

    try {
      const status = await this.getPlans();
      if (check.assigned(status)) {
        return status;
      }

      const subscription = await this.injector.get(PrivateOrganizationService).getSubscription();
      if (check.assigned(subscription)) {
        defaultStatus.plans = subscription.plans ?? [];
        defaultStatus.customerStatus = subscription.status ?? '';
      }

      return defaultStatus;
    } catch {
      return defaultStatus;
    }
  }

  public async getPlansAndFeatures(): Promise<IPlansAndFeaturesWrapper> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const plans = await this.injector
        .get(HttpClient)
        .get<IPlansAndFeaturesWrapper>(`${this.CHARGEBEE_URL}/plans-and-features`, httpOptions)
        .toPromise();
      return plans;
    } catch (error) {
      this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'getPlansInfo');
    }
  }

  public async getPortalSession(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      const portalSession = await this.injector
        .get(HttpClient)
        .get<any>(`${this.CHARGEBEE_URL}/portal-session`, httpOptions)
        .toPromise();
      return portalSession;
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawError(error, PrivateChargebeeService.name, 'getPortalSession');
    }
  }

  public async cancelNonDefaultSubscriptions(): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      await this.injector
        .get(HttpClient)
        .put<any>(`${this.CHARGEBEE_URL}/cancel-non-default-subscriptions`, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(
          error,
          PrivateChargebeeService.name,
          'cancelNonDefaultSubscriptions'
        );
    }
  }

  public async cancelDefaultSubscriptionForCurrency(oldCurrency: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      await this.injector
        .get(HttpClient)
        .put<any>(
          `${this.CHARGEBEE_URL}/cancel-default-subscription-for-currency`,
          { oldCurrency },
          httpOptions
        )
        .toPromise();
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(
          error,
          PrivateChargebeeService.name,
          'cancelDefaultSubscriptionForCurrency'
        );
    }
  }

  public async activateSubscriptions(subscriptionId?: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      await this.injector
        .get(HttpClient)
        .post<any>(`${this.CHARGEBEE_URL}/activate-subscriptions`, { subscriptionId }, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, PrivateChargebeeService.name, 'activateSubscriptions');
    }
  }

  public async checkTrialEndAction(): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      await this.injector
        .get(HttpClient)
        .get<any>(`${this.CHARGEBEE_URL}/check-trial-end-action`, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, PrivateChargebeeService.name, 'checkTrialEndAction');
    }
  }

  public async updateFloor(subscriptionId: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set(
        'Authorization',
        this.injector.get(AuthenticationService).getAuthorizationHeader()
      );

      const httpOptions = {
        headers: httpHeaders,
      };

      await this.injector
        .get(HttpClient)
        .put<any>(`${this.CHARGEBEE_URL}/floor/${subscriptionId}`, {}, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, PrivateChargebeeService.name, 'updateFloor');
    }
  }
}

export interface IPlansAndFeaturesWrapper {
  plans: { [key: string]: { key: string; features: Array<string> } };
  features: {
    [key: string]: {
      key: string;
      features: Array<{ key: string; starter: boolean; growth: boolean; connect: boolean }>;
    };
  };
  prices: {
    planPrices: { [key: string]: { yearly: object; monthly: object } };
    recruitmentAddonPrices: { [key: string]: { yearly: object; monthly: object } };
    recruitmentPlusAddonPrices: { [key: string]: { yearly: object; monthly: object } };
    datevImplementationFeePrices: { [key: string]: { id: string; steps: object } };
    onboardingAddonPrices: { id: string; priceByPlan: { connect: number; growth: number } };
  };
  currentPlan: { isStarter: boolean; isGrowth: boolean; isConnect: boolean };
  isCountrySetInBilling: boolean;
  isInTrial: boolean;
  isMultiBilling: boolean;
  hasPaymentSources: boolean;
  contactLink: string;
  customerId: string;
  errorRetrievingInfo?: boolean;
  isCancelled?: boolean;
  isBillingYearly?: boolean;
  minEmployees: number;
}

export interface IChargebeeStatus {
  subscriptions: Array<{
    id: string;
    status: string;
    trialStart?: number;
    trialEnd?: number;
    items: Array<any>;
  }>;
  plans: Array<string>;
  customerStatus: string;
  showFreeTrialBanner: boolean;
  overdueAmount: { amount: number; currency: string };
}

export interface IChargebeePlan {
  planId: string;
  subscriptionStatus: string;
}

export interface IChargebeeCustomerWrapper {
  multiBilling: boolean;
  customers: Array<IChargebeeCustomer>;
}

export interface IChargebeeCustomer {
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  company?: string;
  locale?: string;
  vat_number?: string;
  billing_address?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    company?: string;
    line1?: string;
    line2?: string;
    zip?: string;
    city?: string;
    state?: string;
    country?: string;
  };
  invoice_notes?: string;
  cf_orgos_company_name?: string;
}

export interface IChargebeeCustomerBillingInfo {
  id?: string;
  billing_address?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    company?: string;
    line1?: string;
    line2?: string;
    zip?: string;
    city?: string;
    state?: string;
    country?: string;
  };
  vat_number?: string;
}

export interface IChargebeeSubscriptionWrapper {
  multiBilling: boolean;
  subscriptions: Array<IChargebeeSubscription>;
}

export interface IChargebeeSubscription {
  id: string;
  customer_id?: string;
  status?: 'future' | 'in_trial' | 'active' | 'non_renewing' | 'paused' | 'cancelled';
  plan_id?: string;
  plan_amount?: number;
  plan_quantity?: number;
  currency_code?: string;
  current_term_start?: string;
  current_term_end?: string;
  trial_start?: string;
  trial_end?: string;
  created_at?: string;
  start_date?: string;
  started_at?: string;
  activated_at?: string;
  plan?: any;
  addons?: Array<any>;
}
export interface IChargebeeCreditNote {
  cn_id?: string;
  cn_reason_code?: string;
  cn_create_reason_code?: string;
  cn_date?: number;
  cn_total?: string;
  cn_status?: string;
}
export interface IChargebeeInvoice {
  id: string;
  date?: string;
  status?: string;
  total?: number;
  adjustment_credit_notes?: Array<IChargebeeCreditNote>;
  issued_credit_notes?: Array<IChargebeeCreditNote>;
}

export interface IChargebeePaymentSource {
  id: string;
  customer_id: string;
  type: 'card' | 'direct_debit';
  status: 'valid' | 'expiring' | 'expired' | 'invalid' | 'pending_verification';
  card?: {
    first_name: string;
    last_name: string;
    expiry_month: number;
    expiry_year: number;
    masked_number: string;
  };
  bank_account?: {
    name_on_account: string;
    last4: string;
  };
}

export interface IChargebeeHostedPageWrapper {
  hostedPage: IChargebeeHostedPage;
  isLegacy: boolean;
  oldCurrency?: string;
  defaultSubscriptionId?: string;
}

export interface IChargebeeHostedPage {
  id: string;
  url?: string;
}

export interface IChargebeeCurrentPlans {
  hrPlan: string;
  recruitingPlan: string;
}
