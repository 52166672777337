<ng-container *ngIf="!loadingPage">
  <orgos-column-container centerColumns="true" class="toup-top-bar kenjo-border-bottom kenjo-ph-20px">
    <orgos-column size="0">
      <kenjo-icon size="20" (click)="exitFullScreenMode()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
    </orgos-column>

    <orgos-column size="0" class="toup-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-column size="0" class="kenjo-font-size-16px">{{i18n.page.pageTitle}}</orgos-column>

    <orgos-column size="0" class="toup-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-avatar orgosColumn="0" [photoUrl]="user?._photo?._url" [avatarName]="user?.displayName" size="small" class="kenjo-mr-10px"></orgos-avatar>
    <orgos-column>{{user?.displayName}}</orgos-column>
  </orgos-column-container>
  <div class="toup-main-container">
    <div class="kenjo-p-20px toup-content">
      <orgos-container aspect="card" *ngIf="!myStatus || myStatus.length === 0" class="kenjo-text-align-center toup-empty-time-off" [class]="emptyTimeOff ? 'toup-empty-history' : 'toup-empty-statuses kenjo-mb-20px'">
        <div>
          <img src="/assets/images/emptyState.svg" />
          <div class="kenjo-mt-20px kenjo-font-size-14px kenjo-font-color-text-333333">{{i18n.page.emptyTimeOffMessage}}</div>
          <orgos-button-raised *ngIf="managePoliciesButtonShown && managePoliciesInfo" class="kenjo-mt-20px" color="Primary" (click)="openManagePoliciesDialog()"> {{ i18n.page.emptyTimeOffButton }} </orgos-button-raised>
        </div>
      </orgos-container>
      <!-- LOOP OVER ALL TIME OFF STARTS -->
      <orgos-column-container wrap="true">
        <orgos-column size="0" *ngFor="let status of myLimitedStatus" class="kenjo-mr-20px kenjo-mb-20px">
          <orgos-time-off-type-status [userPolicyStatus]="status" [translation]="i18n.page" [onBehalf]="canCreateRequestsOnBehalf" [canSeePolicyDetails]="canSeePolicyDetails" [userId]="userId" (requestFinished)="refreshData()" class="kenjo-full-height"></orgos-time-off-type-status>
        </orgos-column>

        <orgos-column size="0" *ngFor="let statusGroup of myUnlimitedStatus" class="kenjo-mr-20px kenjo-mb-20px">
          <orgos-container aspect="card" class="toup-unlimited-card-container">
            <orgos-time-off-type-status *ngFor="let status of statusGroup" [userPolicyStatus]="status" [translation]="i18n.page" [onBehalf]="canCreateRequestsOnBehalf" [canSeePolicyDetails]="canSeePolicyDetails" [userId]="userId" (requestFinished)="refreshData()"></orgos-time-off-type-status>
          </orgos-container>
        </orgos-column>
      </orgos-column-container>
      <!-- LOOP OVER ALL TIME OFF ENDS -->

      <orgos-time-off-history *ngIf="!emptyTimeOff" [userId]="userId" [viewAsAdminOrManager]="true" [statusList]="myStatus" (dataChanged)="refreshData()" [refreshDataEvent]="refreshChildSubject.asObservable()" (emptyTimeOffShown)="emptyTimeOff = true"> </orgos-time-off-history>
    </div>
  </div>
</ng-container>
