import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';

@Pipe({
  name: 'i18nData',
  pure: true
})
export class I18nDataPipe implements PipeTransform {
  transform(value: string, data: any = {}): string {
    if (check.not.assigned(value)) {
      return '';
    }

    if (check.not.assigned(data) || check.emptyObject(data)) {
      return value;
    }

    const finalValue = value
      .split('%%')
      .map((iTerm) => {
        if (iTerm.startsWith('data.') === false) {
          return iTerm;
        }

        let substitutionValue: any = Object.assign({}, data);
        iTerm
          .split('.')
          .slice(1)
          .forEach((iKey: string) => {
            substitutionValue = substitutionValue[iKey];
          });

        return substitutionValue;
      })
      .join('');

    return finalValue;
  }
}
