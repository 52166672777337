import {
  IShiftPlanFilterOption,
  IShiftPlanFilters,
  ShiftCardFilters,
} from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import * as check from 'check-types';

export const getEmptyFilters = () => ({ locations: [], roles: [], employees: [], workingAreas: [], tags: [] });

export const isEmployeeInFilterApplied = (
  employee: IShiftPlanFilterOption,
  filtersApplied: ShiftCardFilters,
  filter: keyof ShiftCardFilters
) => {
  return !filtersApplied[filter].length || employee[filter]?.some((filterId) => filtersApplied[filter].includes(String(filterId)));
};

export const applyEmployeeFilter = (
  key: string,
  selectedItems: IShiftPlanFilters[],
  allFilterOptions: IShiftPlanFilters,
  originalData: IShiftPlanFilters,
  filtersApplied: ShiftCardFilters
) => {
  allFilterOptions = { ...originalData };

  if (!key && !selectedItems?.length) {
    filtersApplied = { ...getEmptyFilters() };
    return { allFilterOptions, filtersApplied };
  }

  const selectedOptionsApplied =
    key && selectedItems.length ? selectedItems.filter((option) => option.active).map((option) => option._id) : [];

  filtersApplied[key] = selectedOptionsApplied;
  allFilterOptions.employees = allFilterOptions.employees?.filter((employee) => {
    const employeeInWorkingArea = isEmployeeInFilterApplied(employee, filtersApplied, 'workingAreas');
    const employeeInLocation = isEmployeeInFilterApplied(employee, filtersApplied, 'locations');
    const employeeInRole = isEmployeeInFilterApplied(employee, filtersApplied, 'roles');
    const employeeInTag = isEmployeeInFilterApplied(employee, filtersApplied, 'tags');
    const employeeFiltered = employeeInWorkingArea && employeeInLocation && employeeInRole && employeeInTag;

    return employeeFiltered ? true : employee.active ? delete employee.active : '';
  });

  applyFiltersToShiftplanData(filtersApplied, allFilterOptions);

  return { allFilterOptions, filtersApplied };
};

export const applyFiltersToShiftplanData = (filtersApplied: ShiftCardFilters, allFilterOptions: IShiftPlanFilters) => {
  const allEmployees = allFilterOptions.employees;
  const filteredEmployees =
    filtersApplied.employees.length === 0
      ? allEmployees
      : allEmployees.filter((employee) => {
          const employeeId = typeof employee === 'string' ? employee : employee._id;
          return filtersApplied.employees.includes(employeeId);
        });
  const userRolesIds = filteredEmployees
    .reduce((acc, employee) => {
      const roleIds = Array.isArray(employee.roles) ? employee.roles.map((role) => (typeof role === 'string' ? role : role._id)) : [];
      return [...acc, ...roleIds];
    }, [])
    .map(String);
  allFilterOptions.roles = allFilterOptions.roles.filter((role) => {
    if (userRolesIds.includes(role._id) || userRolesIds.includes(role.toString())) {
      return true;
    } else if (role.active) {
      delete role.active;
    }
  });

  const userWorkingAreasIds = filteredEmployees
    .reduce((acc, employee) => {
      const workingAreaIds = Array.isArray(employee.workingAreas)
        ? employee.workingAreas.map((area) => (typeof area === 'string' ? area : area._id))
        : [];

      return [...acc, ...workingAreaIds];
    }, [])
    .map(String);
  allFilterOptions.workingAreas = allFilterOptions.workingAreas.filter((workingArea) => {
    if (userWorkingAreasIds.includes(workingArea._id) || userWorkingAreasIds.includes(workingArea.toString())) {
      return true;
    } else if (workingArea.active) {
      delete workingArea.active;
    }
  });

  const userTagsIds = filteredEmployees
    .reduce((acc, employee) => {
      const tagsIds = Array.isArray(employee.tags) ? employee.tags.map((tag) => (typeof tag === 'string' ? tag : tag._id)) : [];

      return [...acc, ...tagsIds];
    }, [])
    .map(String);
  allFilterOptions.tags = allFilterOptions.tags.filter((tag) => {
    if (userTagsIds.includes(tag._id) || userTagsIds.includes(tag.toString())) {
      return true;
    } else if (tag.active) {
      delete tag.active;
    }
  });

  if (
    filtersApplied.employees.length > 0 ||
    filtersApplied.roles.length > 0 ||
    filtersApplied.workingAreas.length > 0 ||
    filtersApplied.tags.length > 0
  ) {
    const userLocationsIds = filteredEmployees
      .reduce((acc, employee) => {
        const locationsIds = Array.isArray(employee.locations)
          ? employee.locations.map((location) => (typeof location === 'string' ? location : location._id))
          : [];

        return [...acc, ...locationsIds];
      }, [])
      .map(String);
    allFilterOptions.locations = allFilterOptions.locations.filter((location) => {
      if (userLocationsIds.includes(location._id) || userLocationsIds.includes(location.toString())) {
        return true;
      } else if (location.active) {
        delete location.active;
      }
    });
  }
};

export const updateFilters = (filter: string, allFilterOptions: IShiftPlanFilters, filtersPreference: ShiftCardFilters) => {
  if (filtersPreference[filter]?.length > 0) {
    const notAppliedElements = filtersPreference[filter].filter((elementId: any) => {
      return !allFilterOptions[filter].some((obj) => obj._id === elementId);
    });

    if (notAppliedElements?.length > 0) {
      filtersPreference[filter] = filtersPreference[filter].filter((str) => !notAppliedElements.includes(str));
    }
  }

  allFilterOptions[filter].forEach((element: any) => {
    if (filtersPreference[filter]?.includes(element._id)) {
      element.active = true;
    }
  });
  return { allFilterOptions, filtersPreference };
};

export const isAnyFilterApplied = (filters: ShiftCardFilters) => {
  return (
    check.assigned(filters) &&
    (filters.locations.length > 0 || filters.employees.length > 0 || filters.roles.length > 0 || filters.workingAreas.length > 0)
  );
};
