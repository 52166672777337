import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as check from 'check-types';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';

@Component({
  selector: 'orgos-input-simple-text-area',
  templateUrl: 'input-simple-text-area.component.html',
  styleUrls: ['input-simple-text-area.component.scss']
})
export class InputSimpleTextAreaComponent implements OnInit {
  isValueValid: boolean = true;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() value: any;
  @Input() rows: number = 1;
  @Input() placeholder: string = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Input() fillAppearance: boolean = false;
  @Input() autosize: boolean = false;

  ngOnInit(): void {
    this.makeValidation(this.value);
  }

  onValueChange(newValue: any): void {
    this.value = newValue;

    this.makeValidation(newValue);

    if (this.isValueValid) {
      this.valueChange.emit(newValue);
    }
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (this.customValidation) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }
}
