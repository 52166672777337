import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class PositionCandidateScoreService {
  private POSITION_CANDIDATE_SCORE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/position-candidate-score-db`;
  private POSITION_CANDIDATE_SCORE_PERMISSIONS_KEY: string = 'position-candidate-score';
  private POSITION_CANDIDATE_SCORE_INTERNATIONALIZATION: string = 'position-candidate-score-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.POSITION_CANDIDATE_SCORE_URL, data)
        .then((positionCandidateScore: any) => {
          resolve(positionCandidateScore);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateScoreService.name, 'create'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.POSITION_CANDIDATE_SCORE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateScoreService.name, 'updateById'));
        });
    });
  }

  getMyScoreForPositionCandidate(positionCandidateId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PositionCandidateScoreService.name, 'getMyScoreForPositionCandidate');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.POSITION_CANDIDATE_SCORE_URL}/${positionCandidateId}`, httpOptions)
        .toPromise()
        .then((myScore) => {
          resolve(myScore);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateScoreService.name, 'getMyScoreForPositionCandidate'));
        });
    });
  }

  getTotalScoreForPositionCandidate(positionCandidateId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PositionCandidateScoreService.name, 'getTotalScoreForPositionCandidate');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.POSITION_CANDIDATE_SCORE_URL}/total-score/${positionCandidateId}`, httpOptions)
        .toPromise()
        .then((totalScore) => {
          resolve(totalScore);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateScoreService.name, 'getTotalScoreForPositionCandidate'));
        });
    });
  }

  find(findBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .find(this.POSITION_CANDIDATE_SCORE_URL, findBody)
        .then((candidate: any) => {
          resolve(candidate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionCandidateScoreService.name, 'find'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.POSITION_CANDIDATE_SCORE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.POSITION_CANDIDATE_SCORE_INTERNATIONALIZATION);
  }
}
