import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import { IWhistleblowerPortal } from '@app/cloud-features/whistleblower-center/models/whistleblower.model';

export const SUPPORTED_LANGUAGES = ['en', 'es', 'de'] as const;
export const DEFAULT_PORTAL_LANGUAGE = 'en';
export type PortalLanguage = typeof SUPPORTED_LANGUAGES[number];

@Injectable({
  providedIn: 'root'
})
export class WhistleblowerPortalService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/whistleblower-portal-db`;

  constructor(private injector: Injector) {}

  async activateWhistleblowerPortal(): Promise<IWhistleblowerPortal> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).get<IWhistleblowerPortal>(`${this.URL}/true`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, WhistleblowerPortalService.name, 'activateWhistleblowerPortal');
    }
  }

  async deactivateWhistleblowerPortal(): Promise<IWhistleblowerPortal> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).post<IWhistleblowerPortal>(`${this.URL}/deactivate`, {}, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, WhistleblowerPortalService.name, 'deactivateWhistleblowerPortal');
    }
  }
}
