import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import * as check from 'check-types';

import { PrivateInternationalizationService } from './private/services/private-internationalization.service';
import { ErrorManagerService } from './standard/services/error/error-manager.service';

@Component({
  selector: 'orgos-web-app',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private route: ActivatedRoute, private injector: Injector) {}

  ngOnInit(): void {
    this.injector.get(CloudRoutingService).setInitialUrl();

    this.route.queryParams.subscribe((queryParams) => {
      if (check.assigned(queryParams) && check.assigned(queryParams['lang']) && check.not.emptyString(queryParams['lang'])) {
        this.injector.get(PrivateInternationalizationService).setUrlLanguage(queryParams['lang']);
      }
      if (check.assigned(queryParams) && check.assigned(queryParams['errors']) && check.not.emptyString(queryParams['errors'])) {
        this.injector.get(ErrorManagerService).setErrorsVisibility(queryParams['errors']);
      }
    });

    this.drawLogoInConsole();
  }

  private drawLogoInConsole(): void {
    console.log('%c KENJO', 'font-family:Nunito, sans-serif; font-size:80px; margin-left:97px; font-weight: bold');
    console.log(`%c Kenjo ${new Date().getFullYear()}, built with love`, 'font-family:Nunito, sans-serif; font-size:16px; margin-left:140px; font-weight: bold');
    console.log('%c Yes! we are hiring!', 'font-family:Nunito, sans-serif; font-size:14px; margin-left:180px;');
    console.log('%c https://careers.kenjo.io/', 'font-family: Nunito, sans-serif; font-size: 14px; margin-left: 170px; color: #59e37c');
  }
}
