import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { IMyTeamWidgetSettingsModel } from '@app/cloud-features/settings-my-team/models/my-team-widget-settings.model';
import { MyTeamWidgetSettingsService } from '@app/cloud-features/settings-my-team/services/my-team-widget-settings.service';
import { MyTeamWidgetDetailsDialog } from '@app/common-components/widgets/my-team/dialogs/my-team-widget-details.dialog';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { WidgetsService } from '@app/standard/services/widgets/widgets.service';
import {
  MY_TEAM_TRANSLATION_KEY_FAVORITES,
  MY_TEAM_TRANSLATION_KEY_MANAGER,
  POLICY_TYPE_DAY,
  POLICY_TYPE_HOUR,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-widget-my-team',
  templateUrl: 'orgos-widget-my-team.component.html',
  styleUrls: ['orgos-widget-my-team.component.scss'],
})
export class WidgetMyTeamComponent implements OnInit {
  myTeamMembers: Array<any>;
  myTeamWidgetSetting: IMyTeamWidgetSettingsModel;
  configurationKeys: Array<string>;
  allUsers: Array<any>;
  userTimeOffPermissions: any;
  allSectionsEmpty = false;

  POLICY_TYPE_HOUR: string = POLICY_TYPE_HOUR;
  POLICY_TYPE_DAY: string = POLICY_TYPE_DAY;
  MY_TEAM_TRANSLATION_KEY_MANAGER: string = MY_TEAM_TRANSLATION_KEY_MANAGER;
  MY_TEAM_TRANSLATION_KEY_FAVORITES = MY_TEAM_TRANSLATION_KEY_FAVORITES;
  widgetLoaded: boolean = false;
  existingData: boolean = false;
  isFavoritesSectionActive: boolean = false;
  pageTranslation: any = {};
  userAwayTodayById: object = {};

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('home-widget-my-team-component');
    } catch (error) {
      this.pageTranslation = {};
    }

    try {
      this.myTeamWidgetSetting = await this.injector.get(MyTeamWidgetSettingsService).find();
      const result = await this.injector.get(WidgetsService).getMyTeamMembers();

      this.configurationKeys = result.configurationKeys;
      this.myTeamMembers = result.sections;
      this.allSectionsEmpty = this.myTeamMembers.every((section) => section?.members?.length === 0);
      this.allUsers = result.allUsers;
      this.isFavoritesSectionActive = result.isFavoritesSectionActive;

      this.myTeamMembers.forEach((iSection) => {
        iSection.members = iSection.members.map((iMember) => this.buildUserTooltip(iSection, iMember));
      });

      this.existingData = check.array(this.myTeamMembers);
    } catch (error) {
      // An error is shown
    }

    this.widgetLoaded = true;
  }

  private buildUserTooltip(section, user): any {
    user.tooltipMessage = [];

    const userName = `${user.displayName} ${
      check.assigned(user.hierarchyTranslationKey) ? this.pageTranslation.sectionTooltips[user.hierarchyTranslationKey] : ''
    }`;
    user.tooltipMessage.push(userName);

    // If hierarchyTranslationKey is assigned, we don't want to show the second line (section - name). The exception is when the user is manager because we want to show this information always, included other sections
    if (
      (check.not.assigned(user.hierarchyTranslationKey) ||
        (check.assigned(user.hierarchyTranslationKey) && user.hierarchyTranslationKey === this.MY_TEAM_TRANSLATION_KEY_MANAGER)) &&
      check.assigned(section.name)
    ) {
      const sectionName = `${this.injector
        .get(I18nDataPipe)
        .transform(this.pageTranslation.sectionTooltips[section.translationKey], { sectionName: section.name })}`;
      user.tooltipMessage.push(sectionName);
    }

    if (user.isAway) {
      let duration = '';

      if (user.timeOffType === POLICY_TYPE_HOUR && user.from && user.to && moment.utc(user.from).isSame(user.to, 'day')) {
        duration = `${this.pageTranslation.fromText} ${this.getFormattedHours(user.from, user.to)}`;
      }

      const isAwayMessage = `${this.pageTranslation.awayTodayTooltip} ${duration}`;
      user.tooltipMessage.push(isAwayMessage);
    }

    user.tooltipMessage = user.tooltipMessage.join('\n\n');

    return user;
  }

  protected getFormattedHours(from: string, to: string): string {
    const fromMoment = moment.utc(from);
    const toMoment = moment.utc(to);
    const minutes = toMoment.diff(fromMoment, 'minutes');
    const duration = this.injector.get(DurationPipe).transform(minutes);
    return `${fromMoment.format('LT')} ${this.pageTranslation.toText} ${toMoment.format('LT')} (${duration})`;
  }

  protected navigateToEmployeeProfile(employee: any): void {
    if (check.not.assigned(employee) || check.not.assigned(employee._id)) {
      return;
    }
    this.injector.get(Router).navigateByUrl(`cloud/people/${employee._id}/personal`);
  }

  public openDetailsDialog(): void {
    const data = {
      configurationKeys: [...this.configurationKeys],
      myTeamWidgetSetting: { ...this.myTeamWidgetSetting },
      myTeamMembers: _.cloneDeep(this.myTeamMembers),
      allUsers: _.cloneDeep(this.allUsers),
      isFavoritesSectionActive: this.isFavoritesSectionActive,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(MyTeamWidgetDetailsDialog, { data });
    dialogRef.afterClosed().subscribe((updated: any) => {
      if (updated) {
        this.initData();
      }
    });
  }
}
