<mat-form-field *ngIf="model && field && !readOnly">
  <mat-label kenjoRemoveAriaOwns>{{ label }}</mat-label>
  <mat-select [ngModel]="getValue()" (ngModelChange)="setValue($event)" [required]="required" #matSelect>
    <mat-option *ngFor="let option of options" [value]="option.value"
      >{{ option.name }}{{ showDifference ? getDifference(option.value) : '' }}</mat-option
    >
  </mat-select>
  <mat-icon matSuffix [inline]="true">clock</mat-icon>
</mat-form-field>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getValueName()" readOnly disabled [required]="required" />
</mat-form-field>
<div *ngIf="!isValueValid" class="isc-error"><ng-content select="orgos-input-error"></ng-content></div>
<orgos-input-hint *ngIf="showSavedHint" class="isc-saved-hint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
