<orgos-container *ngIf="viewLabels && viewLabels.length > 0 && standardView && !simpleView" aspect="card" class="kenjo-mb-20px">
  <div class="kenjo-pt-20px kenjo-pl-20px kenjo-font-size-16px kenjo-font-color-text-333333">{{ translation.viewsLabel }}</div>
  <div class="kenjo-p-20px">
    <mat-radio-group [ngModel]="selectedView" (ngModelChange)="changeView($event)">
      <orgos-column-container *ngFor="let viewLabel of viewLabels; let last = last" [class.kenjo-mb-20px]="!last">
        <orgos-column size="1">
          <mat-radio-button [value]="viewLabel.value">
            <kenjo-truncate-text [style.min-width.px]="width">
              {{ viewLabel.name }}
            </kenjo-truncate-text>
          </mat-radio-button>
        </orgos-column>
        <orgos-column size="0" class="kenjo-pl-10px">
          <kenjo-icon *ngIf="viewLabel.value !== translation.allViewsLabel" [size]="18" [matTooltip]="translation.deleteViewTooltip" (click)="deleteView(viewLabel.value)" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757">delete</kenjo-icon>
        </orgos-column>
      </orgos-column-container>
    </mat-radio-group>
  </div>
</orgos-container>

<div *ngIf="viewLabels && viewLabels.length > 0 && !standardView && !simpleView">
  <div *ngIf="!standardView">
    <orgos-input-simple-select [matMenuTriggerFor]="viewsMenu" class="spr-select kenjo-pl-10px" [value]="selectedView" [options]="[{ name: selectedView, value: selectedView }]" (valueChange)="(undefined)"></orgos-input-simple-select>
    <mat-menu #viewsMenu="matMenu" class="view-menu">
      <div class="kenjo-border-bottom" mat-menu-item>
        <orgos-action (click)="createView()" icon="add_circle" iconSize="large">{{ translation.saveViewButton }}</orgos-action>
      </div>
      <div mat-menu-item *ngFor="let viewLabel of viewLabels; let last = last">
        <orgos-column-container centerColumns="true">
          <orgos-column>
            <div class="kenjo-font-color-text-333333 kenjo-font-size-14px" (click)="changeView(viewLabel.value)">{{ viewLabel.name }}</div>
          </orgos-column>
          <orgos-column></orgos-column>
          <orgos-column size="0" class="kenjo-ml-40px prc-icon">
            <kenjo-icon *ngIf="viewLabel.value !== translation.allViewsLabel" (click)="deleteView(viewLabel.value)" size="18" [matTooltip]="translation.deleteViewTooltip" iconClass="kenjo-font-color-danger-ff5757">delete</kenjo-icon>
          </orgos-column>
        </orgos-column-container>
      </div>
    </mat-menu>
  </div>
</div>

<div *ngIf="viewLabels && viewLabels.length > 0 && simpleView">
  <orgos-button-raised color="Basic" class="view-view-button kenjo-font-size-14px" [matMenuTriggerFor]="massMenu">
    <span class="kenjo-font-color-text-333333">{{ translation.viewsLabel }}:</span>
    {{ selectedView }}
    <kenjo-icon class="view-arrow-icon" [size]="24">arrow_drop_down</kenjo-icon>
  </orgos-button-raised>
  <mat-menu #massMenu="matMenu">
    <div class="kenjo-p-15px kenjo-border-bottom">
      <orgos-action (click)="createView()" icon="add_circle" color="Primary"> {{ translation.saveViewButton }}</orgos-action>
    </div>
    <orgos-column-container centerColumns="true" *ngFor="let viewOption of viewLabels">
      <orgos-column>
        <button (click)="changeView(viewOption.name)" mat-menu-item class="view-view-name-button">
          <kenjo-truncate-text [style.min-width.px]="width">{{ viewOption.name }}</kenjo-truncate-text>
        </button>
      </orgos-column>
      <orgos-column size="0" (click)="deleteView(viewOption.value)" class="kenjo-cursor-pointer">
        <kenjo-icon *ngIf="viewOption.value !== translation.allViewsLabel" [size]="18" [matTooltip]="translation.deleteViewTooltip" class="kenjo-font-color-danger-ff5757 kenjo-text-align-right kenjo-mh-20px">delete</kenjo-icon>
      </orgos-column>
    </orgos-column-container>
  </mat-menu>
</div>
