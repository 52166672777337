import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as check from 'check-types';

import { InputValidation } from '../../../../core/validation/input-validation';
import { InternationalizationService } from '../../../../services/core/internationalization.service';
import { IVariablePayType, VariablePayTypeService } from '../../../../services/variable-pay-type/variable-pay-type.service';

@Component({
  selector: 'orgos-dialog-edit-variable-pay-type',
  templateUrl: 'edit-variable-pay-type.dialog.html',
  styleUrls: ['edit-variable-pay-type.dialog.scss'],
})
export class EditVariablePayTypeDialog implements OnInit {
  pageTranslation: any = {};
  variablePayType: IVariablePayType;

  variablePayTypeValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditVariablePayTypeDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-variable-pay-type-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
    if (check.assigned(this.data) && check.nonEmptyObject(this.data)) {
      this.variablePayType = this.data;
    }
  }

  save(): void {
    this.injector
      .get(VariablePayTypeService)
      .updateById(this.variablePayType._id, this.variablePayType)
      .then(() => {
        this.dialogRef.close();
      })
      .catch((e) => {});
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
