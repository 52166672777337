import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { COMPONENTS, DIALOGS, EXTERNAL_MODULES, PROVIDERS } from '@app/common-components/layout-import-payslips/layout-import-payslips.config';
import { INTERCEPTORS } from '@app/core/interceptors/interceptors.config';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';

@NgModule({
  declarations: [...COMPONENTS, ...DIALOGS],
  imports: [CommonModule, FormsModule, MaterialModule, StandardComponentsModule, ...EXTERNAL_MODULES],
  providers: [...INTERCEPTORS, ...PROVIDERS],
  exports: [...COMPONENTS, ...DIALOGS]
})
export class LayoutImportPayslipsModule {}
