<div>
  <orgos-column-container cdkOverlayOrigin #calendarOverlay="cdkOverlayOrigin">
    <mat-form-field orgosColumn="1" (click)="openCalendar()" class="idrcp-date-input kenjo-cursor-pointer" [ngClass]="{ 'mat-form-field-invalid': selectedDateError }" [matTooltip]="warningTooltip" matTooltipPosition="above">
      <mat-label>{{ label }} <span *ngIf="required">*</span></mat-label>
      <input matInput  [ngModel]="selectedTime?.toDate() | date: 'shortDate': 'UTC'" [disabled]="readOnly" (ngModelChange)="onInputDateChange($event)" [ngClass]="{ 'kenjo-cursor-pointer': !readOnly }" />
      <mat-icon matSuffix *ngIf="warningTooltip" class="idrcp-warning-icon">warning_outline</mat-icon>
      <mat-datepicker-toggle matSuffix class="idrcp-calendar-icon"></mat-datepicker-toggle>
      <mat-hint class="mat-error" *ngIf="selectedDateError">{{ selectedDateError }}</mat-hint>
    </mat-form-field>
  </orgos-column-container>
  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="calendarOverlay" [cdkConnectedOverlayOpen]="displayCalendar" [cdkConnectedOverlayOffsetX]="0" [cdkConnectedOverlayOffsetY]="-10" (overlayOutsideClick)="closeCalendar()">
    <mat-card class="idrcp-calendar-card" (mousedown)="onClickCalendar($event)">
      <orgos-loading-spinner *ngIf="loading" class="idrcp-loading"></orgos-loading-spinner>
      <ng-container *ngIf="!loading">
        <orgos-column-container centerColumns="true" class="idrcp-calendar-headers kenjo-font-weight-bold kenjo-mt-15px kenjo-mb-15px">
          <orgos-column size="1" class="idrcp-calendar-header">
            <orgos-column-container class="idrcp-header-buttons idrcp-header-left-buttons">
              <button orgosColumn="0" mat-icon-button [disabled]="dateLimits.minYearReached" [matTooltip]="translations.previousYear" matTooltipPosition="above" (click)="moveCalendarToPrevious('year')">
                <mat-icon>double_arrow_left</mat-icon>
              </button>
              <button orgosColumn="0" mat-icon-button [disabled]="dateLimits.minMonthReached" [matTooltip]="translations.previousMonth" matTooltipPosition="above" (click)="moveCalendarToPrevious('month')">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </orgos-column-container>
          </orgos-column>
          <orgos-column size="1">
            <div class="idrcp-calendar-header idrcp-header-title">
              <span>{{ calendarPeriodLabel | uppercase }}</span>
            </div>
          </orgos-column>
          <orgos-column size="1" class="idrcp-calendar-header">
            <orgos-column-container class="idrcp-header-buttons idrcp-header-right-buttons">
              <button orgosColumn="0" mat-icon-button [disabled]="dateLimits.maxMonthReached" [matTooltip]="translations.nextMonth" matTooltipPosition="above" (click)="moveCalendarToNext('month')">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
              <button orgosColumn="0" mat-icon-button [disabled]="dateLimits.maxYearReached" [matTooltip]="translations.nextYear" matTooltipPosition="above" (click)="moveCalendarToNext('year')">
                <mat-icon>double_arrow_right</mat-icon>
              </button>
            </orgos-column-container>
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container>
          <orgos-column class="idrcp-month-calendar">
            <mat-calendar #calendar class="idrcp-start-calendar" [dateClass]="dateClass" [selected]="selectedTime" [startAt]="calendarStartAt" [minDate]="minDate" [maxDate]="maxDate" (selectedChange)="selectedChange($event)"> </mat-calendar>
          </orgos-column>
        </orgos-column-container>
      </ng-container>
    </mat-card>
  </ng-template>
</div>
