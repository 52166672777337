import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PolicyTypeType } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { PartOfDayFromType, PartOfDayToType, RequestStatusType, RequestTypeType } from '@app/cloud-features/time-off/services/time-off-request.service';
import { IRepeatConflict } from '@app/common-components/time-off-user-personal/components/repeat-request-conflict-info/repeat-request-conflict-info.component';
import { IFilterField } from '@app/standard/components/filter-bar/filter-bar.component';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import {
  TIME_OFF_REQUEST_CONFLICT_MISSING_PAST_CYCLE,
  TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE,
  TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME,
  TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST,
  TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT,
  TIME_OFF_REQUEST_STATUS_IN_APPROVAL,
  TIME_OFF_REQUEST_STATUS_PENDING
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TimeOffUserRequestController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/time-off-user-request`;

  constructor(private injector: Injector) {}

  async calculatePreRequestInfo(preRequestParams: IPreRequestParamsModel): Promise<IPreRequestInfoModel> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'calculatePreRequestInfo');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return (await this.injector.get(HttpClient).post(`${this.URL}/pre-request`, preRequestParams, httpOptions).toPromise()) as IPreRequestInfoModel;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'calculatePreRequestInfo');
    }
  }

  async hasRequestTabAccess(): Promise<IHasRequestTabAccess> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'hasRequestTabAccess');
    }

    const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    if (myProfile === 'admin' || myProfile === 'hr-admin') {
      return { hasRequestTabAccess: true };
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = { headers };

    try {
      return await this.injector.get(HttpClient).get<IHasRequestTabAccess>(`${this.URL}/request-tab-access`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'hasRequestTabAccess');
    }
  }

  async getRequestApprovalFilterOptions(): Promise<IFilterField[]> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'getRequestFilterOptions');
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = { headers };

    try {
      return await this.injector.get(HttpClient).get<IFilterField[]>(`${this.URL}/filters`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'getRequestFilterOptions');
    }
  }

  async getRequestApprovalSummary(queryOptions: any = {}, saveFilters: boolean = false): Promise<Array<IRequestApprovalSummaryModel>> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'getRequestApprovalSummary');
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = { headers };

    try {
      return await this.injector.get(HttpClient).post<Array<IRequestApprovalSummaryModel>>(`${this.URL}/request-approval-summary`, { query: queryOptions, saveFilters }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'getRequestApprovalSummary');
    }
  }

  async getRequestApprovalDetails(requestId: string): Promise<IRequestApprovalDetailModel> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'getRequestApprovalDetails');
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = { headers };

    try {
      return await this.injector.get(HttpClient).get<IRequestApprovalDetailModel>(`${this.URL}/request-approval-details/${requestId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'getRequestApprovalDetails');
    }
  }

  async getDelegatedApprover(userId): Promise<ITimeOffApproverModel> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'getDelegatedApprover');
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = { headers };

    try {
      return await this.injector.get(HttpClient).get<ITimeOffApproverModel>(`${this.URL}/delegated-approver/${userId}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'getDelegatedApprover');
    }
  }

  async getUsersAway(date: string): Promise<Array<string>> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'getUsersAway');
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = { headers };
    try {
      return await this.injector.get(HttpClient).get<Array<string>>(`${this.URL}/users-away/${date}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'getUsersAway');
    }
  }

  async getCalendarEvents(userId: string, startDate: Date, endDate: Date): Promise<ITimeOffCalendarEventsModel> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'getCalendarRequests');
      }
      const body = {
        userId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      };
      const httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
      };
      return await this.injector.get(HttpClient).post<ITimeOffCalendarEventsModel>(`${this.URL}/calendar-events`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'getCalendarRequests');
    }
  }

  async getHourlyFields(userId: string, from: string, to: string): Promise<IPrePopulateHoursModel> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserRequestController.name, 'getSingleDayHourlyFields');
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = { headers };

    try {
      return await this.injector.get(HttpClient).get<IPrePopulateHoursModel>(`${this.URL}/hourly-fields/${userId}?from=${from}&to=${to}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserRequestController.name, 'getSingleDayHourlyFields');
    }
  }
}

export interface IPreRequestParamsModel {
  _policyId: string;
  _userId: string;
  from: string;
  to: string;
  partOfDayFrom?: PartOfDayFromType;
  partOfDayTo?: PartOfDayToType;
  isEdit?: boolean;
  requestId?: string;
  repeatConfig?: {
    frequencyQuantity: number;
    frequencyUnit: string;
    endDate: Date;
    weekdays?: number[];
    monthDay?: string;
  }
  startTime?: string;
  endTime?: string;
}

export interface IPreRequestInfoModel {
  conflict: boolean;
  conflictType: PreRequestConflictType;
  workingTime: number;
  workingTimeCurrentCycle: number;
  workingTimePastCycle: number;
  pastCycleAvailable: number;
  newAvailable: number;
  nextCycleWorkingTime?: number;
  nextCycleAvailable?: number;
  newAvailableAfterExpireCarryOver: number;
  repeatConflicts?: IRepeatConflict[];
}

export interface IHasRequestTabAccess {
  hasRequestTabAccess: boolean;
}

export type PreRequestConflictType = TIME_OFF_REQUEST_CONFLICT_OVERLAP_REQUEST | TIME_OFF_REQUEST_CONFLICT_OVERLAP_SHIFT | TIME_OFF_REQUEST_CONFLICT_NON_WORKING_TIME | TIME_OFF_REQUEST_CONFLICT_NEGATIVE_BALANCE | TIME_OFF_REQUEST_CONFLICT_MISSING_PAST_CYCLE;

export interface IRequestFilterOption {
  name: string;
  _id: string;
  photoUrl?: string;
  active?: boolean;
}

export interface IRequestQueryOptions {
  'user-work'?: {
    where: IRequestQueryUserWorkOptions;
  };
  'time-off-type'?: {
    where: IRequestQueryTimeOffTypeOptions;
  };
  twoStepApprovalProgress?: TwoStepApprovalProgressType;
  search?: string;
  showAll?: boolean;
}

export type TwoStepApprovalProgressType = TIME_OFF_REQUEST_STATUS_PENDING | TIME_OFF_REQUEST_STATUS_IN_APPROVAL;

export interface IRequestQueryUserWorkOptions {
  reportsToId?: IRequestQueryOptionReportsToValue;
  companyId?: IRequestQueryOptionValue;
  departmentId?: IRequestQueryOptionValue;
  officeId?: IRequestQueryOptionValue;
  areaIds?: IRequestQueryOptionValue;
  teamIds?: IRequestQueryOptionValue;
  ownerId?: IRequestQueryOptionValue;
}
export interface IRequestQueryTimeOffTypeOptions {
  _id: IRequestQueryOptionValue;
}

interface IRequestQueryOptionValue {
  $in: Array<string>;
}

interface IRequestQueryOptionReportsToValue {
  $in?: Array<string>;
  $nin?: Array<string>;
}

export interface IRequestApprovalSummaryModel {
  user: {
    id: string;
    name: string;
    photoUrl: string;
  };
  request: {
    id: string;
    from: string;
    to: string;
    overdue: boolean;
  };
  timeOffType: {
    id: string;
    name: string;
    color: string;
  };
  policy: {
    type: PolicyTypeType;
  };
}

export interface IRequestApprovalDetailModel {
  id: string;
  user: {
    id: string;
    name: string;
    photoUrl: string;
  };
  request: {
    id: string;
    from: string;
    to: string;
    overdue: boolean;
    status: RequestStatusType;
    workingTime: number;
    duration: number;
    createdAt: string;
    attachments: Array<IRequestApprovalDetailAttachmentModel>;
    description: string;
    isEditAfterProcessed: boolean;
  };
  status: {
    unassignedPolicy: boolean;
    currentBalance: number;
    available: number;
    unlimitedAllowance: boolean;
  };
  timeOffType: {
    id: string;
    name: string;
    color: string;
  };
  policy: {
    type: PolicyTypeType;
  };
  firstApprover: {
    id: string;
    name: string;
    photoUrl: string;
  };
  secondApproverList?: Array<{
    _id: string;
    name: string;
    photoUrl?: string;
    approverType: 'user' | 'manager' | 'company' | 'office' | 'department' | 'team' | 'area';
  }>;
  alsoAway: Array<{
    user: {
      id: string;
      name: string;
      photoUrl: string;
    };
    request: {
      id: string;
      type: RequestTypeType;
      status: RequestStatusType;
      from: string;
      to: string;
      workingTime: number;
    };
    timeOffType: {
      id: string;
      name: string;
      color: string;
    };
    policy: {
      id: string;
      type: PolicyTypeType;
    };
  }>;
}
export interface IRequestApprovalDetailAttachmentModel {
  documentId: string;
  documentUrl: string;
  documentName: string;
  documentExtension: string;
}
export interface ITimeOffApproverModel {
  _id?: string;
  delegatedApproverName?: string;
  hasDescendants?: boolean;
  hasApprover?: boolean;
  userOnBehalfName?: string;
}

export interface ITimeOffCalendarEventsModel {
  nonWorkingEvents: Array<ITimeOffNonWorkingEvent>;
  holidayEvents: Array<ITimeOffHolidayEvent>;
  requestEvents: Array<ITimeOffRequestEvent>;
}

export interface ITimeOffNonWorkingEvent {
  startDate: Date;
  endDate: Date;
  daysOfWeek: Array<number>;
}

export interface ITimeOffHolidayEvent {
  name?: string;
  key?: string;
  date: Date;
}

export interface ITimeOffRequestEvent {
  name: string;
  startDate: Date;
  endDate: Date;
}

export interface IPrePopulateHoursModel {
  startTime?: number;
  endTime?: number;
  isWorkingDay?: boolean;
}