<mat-form-field
  class="isdpbc-form-field"
  [ngClass]="{ 'isdpbc-clickable': !readOnly, 'isdpbc-short-style': style === 'short', 'isdpbc-long-style': style === 'long', 'isdpbc-mat-field-error': forceError, 'isdpbc-mat-field-focus': isDatePickerOpened, 'mat-form-field-invalid': forceError }"
  appearance="outline"
  (click)="openDatepicker()"
>
  <input [ngClass]="{ 'isdpbc-show': readOnly, 'isdpbc-input-error': forceError }" matInput [ngModel]="getValue() | humanDate: false:'short'" readOnly [placeholder]="placeholder" [disabled]="readOnly" [required]="required" />
  <input
    matInput
    [matDatepicker]="datepicker"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    [placeholder]="placeholder"
    [required]="required"
    [min]="min"
    [max]="max"
    disabled="true"
    [ngClass]="{ 'isdpbc-show': !readOnly, 'isdpbc-input-error': forceError, 'isdpbc-clickable': !readOnly }"
    class="isdpbc-editable"
  />
  <mat-datepicker-toggle *ngIf="enableCalendarButton" [ngClass]="{ 'isdpbc-show': !readOnly, 'isdpbc-toggle-error': forceError }" matSuffix [for]="datepicker"></mat-datepicker-toggle>
  <mat-icon *ngIf="showClearButton && datepicker.opened === false" matSuffix class="isdpbc-clear-button" (click)="clearValue(); $event.stopPropagation()">clear</mat-icon>
</mat-form-field>
<mat-datepicker #datepicker [startAt]="startDate" disabled="false"></mat-datepicker>
<div *ngIf="!isValueValid" class="isdpbc-error"><ng-content select="orgos-input-error"></ng-content></div>
