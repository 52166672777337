import { Component, Input } from '@angular/core';

@Component({
  selector: 'orgos-text-icon',
  templateUrl: 'text-icon.component.html',
  styleUrls: ['text-icon.component.scss']
})
export class TextIconComponent {
  @Input() icon: string;
}
