import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'orgos-button',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss']
})
export class ButtonComponent {
  private _disabled: boolean = false;
  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.disabledClass = disabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @HostBinding('class.bc-disabled') disabledClass = false;
}
