import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class RecruitingEmailService {
  private RECRUITING_EMAIL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-email-db`;
  private RECRUITING_EMAIL_PERMISSIONS_KEY: string = 'recruiting-email';
  private RECRUITING_EMAIL_INTERNATIONALIZATION: string = 'recruiting-email-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.RECRUITING_EMAIL_URL, data)
        .then((positionCandidate: any) => {
          resolve(positionCandidate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'create'));
        });
    });
  }

  find(findBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_EMAIL_URL, findBody)
        .then((positionCandidate: any) => {
          resolve(positionCandidate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.RECRUITING_EMAIL_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.RECRUITING_EMAIL_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.RECRUITING_EMAIL_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.RECRUITING_EMAIL_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.RECRUITING_EMAIL_URL);
  }

  sendEmail(id: string, email: IEmail): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingEmailService.name, 'sendEmail');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.RECRUITING_EMAIL_URL}/${id}/send-email`, email, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'sendEmail'));
        });
    });
  }

  scheduleEmail(id: string, email: IEmail, emailDate: Date): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingEmailService.name, 'sendEmail');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.RECRUITING_EMAIL_URL}/${id}/schedule-email`, { email: email, emailDate: emailDate }, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'sendEmail'));
        });
    });
  }

  unscheduleEmail(id: string, jobId: string, positionId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingEmailService.name, 'unscheduleEmail');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .delete(`${this.RECRUITING_EMAIL_URL}/${id}/unschedule-email/${jobId}/${positionId}`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'unscheduleEmail'));
        });
    });
  }

  getScheduledEmails(id: string): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingEmailService.name, 'getScheduledEmails');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.RECRUITING_EMAIL_URL}/scheduled/${id}`, httpOptions)
        .toPromise()
        .then((result: Array<any>) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'getScheduledEmails'));
        });
    });
  }

  readEmail(candidateId: string, emailId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingEmailService.name, 'readEmail');
        return Promise.reject(this.errorManager.handleRawError(error));
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .put(`${this.RECRUITING_EMAIL_URL}/${candidateId}/read-email/${emailId}`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'readEmail'));
        });
    });
  }

  getUnreadCandidateEmails(candidates: Array<string>): Promise<Array<IEmail>> {
    return new Promise<Array<IEmail>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingEmailService.name, 'getUnreadCandidateEmails');
        return Promise.reject(this.errorManager.handleRawError(error));
      }
      if (check.not.assigned(candidates) || check.not.array(candidates) || check.emptyArray(candidates)) {
        resolve(undefined);
      }
      const findBody = {
        senderType: 'Candidate',
        readById: { $nin: [this.authenticationService.getLoggedUser()._id] },
        _candidateId: { $in: candidates }
      };

      this.genericService
        .find(this.RECRUITING_EMAIL_URL, findBody)
        .then((emails: Array<IEmail>) => {
          resolve(emails);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingEmailService.name, 'getUnreadCandidateEmails'));
        });
    });
  }
}

export interface IEmail {
  _id?: string;
  subject: string;
  text: string;
  html: string;
  attachments?: Array<any>;
  cc?: Array<string>;
  bcc?: Array<string>;
  inReplyToEmailId?: string;
  _positionId?: string;
  _candidateId?: string;
  _createdAt?: Date;
}

export interface IOfferEmail {
  text: string;
  html: string;
  attachments?: Array<any>;
  cc?: Array<string>;
  bcc?: Array<string>;
  inReplyToEmailId?: string;
  _positionId?: string;
  _candidateId?: string;
  _createdAt?: Date;
}
