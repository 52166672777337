import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import {
  HOLIDAY_DURATION_AFTERNOON,
  HOLIDAY_DURATION_FULL_DAY,
  HOLIDAY_DURATION_MORNING,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Component({
  selector: 'kenjo-attendance-other-time-offs-dialog',
  templateUrl: 'attendance-other-time-offs.dialog.html',
  styleUrls: ['attendance-other-time-offs.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendanceOtherTimeOffsDialog implements OnInit {
  @Input() timeOffs: IUserAttendanceModel[];
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  USER_COLORS: Record<string, string> = {};
  translations: Record<string, string>;

  constructor(private injector: Injector) {
    this.USER_COLORS = Object.keys(userColorConstants).reduce((result, iColorKey) => {
      result[iColorKey] = userColorConstants[iColorKey];
      return result;
    }, {});
  }

  ngOnInit() {
    this.sortTimeOffs();
    this.getTranslations();
  }

  private sortTimeOffs(): void {
    if (this.timeOffs?.length < 2) {
      return;
    }
    this.timeOffs = [
      ...this.timeOffs.sort((a: IUserAttendanceModel, b: IUserAttendanceModel) => {
        if (a.timeOffEntryInfo.duration === HOLIDAY_DURATION_FULL_DAY) {
          return -1;
        } else if (b.timeOffEntryInfo.duration === HOLIDAY_DURATION_FULL_DAY) {
          return 1;
        } else if (a.timeOffEntryInfo.duration === HOLIDAY_DURATION_MORNING) {
          return -1;
        } else if (b.timeOffEntryInfo.duration === HOLIDAY_DURATION_MORNING) {
          return 1;
        } else if (a.timeOffEntryInfo.duration === HOLIDAY_DURATION_AFTERNOON) {
          return 1;
        } else if (b.timeOffEntryInfo.duration === HOLIDAY_DURATION_AFTERNOON) {
          return -1;
        } else if (a.startTime < b.startTime) {
          return -1;
        } else if (b.startTime < a.startTime) {
          return 1;
        } else if (a.endTime < b.endTime) {
          return -1;
        } else if (b.endTime < a.endTime) {
          return 1;
        }
        return 0;
      }),
    ];
  }

  private async getTranslations(): Promise<void> {
    this.translations = await this.injector.get(InternationalizationService).getAllTranslation('people-detail-attendance-page');
    this.injector.get(ChangeDetectorRef).markForCheck();
  }
}
