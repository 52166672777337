import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  templateUrl: 'shift-plan-capacity.dialog.html',
  styleUrls: ['shift-plan-capacity.dialog.scss'],
})
export class ShiftPlanCapacityDialog implements OnInit {
  dialogTranslation: any = {};
  capacity: any;
  capacityData: Array<any> = [];
  weekDays: Array<string>;
  capacityValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<ShiftPlanCapacityDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private snackBar: MatLegacySnackBar,
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private i18nDataPipe: I18nDataPipe
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      const [dialogTranslation, weekDays] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-capacity-dialog'),
        this.injector.get(InternationalizationService).getTranslatedWeekdays(),
      ]);
      this.capacity = this.data.capacity;
      this.dialogTranslation = dialogTranslation;
      this.weekDays = weekDays;
      this.initCapacityData();
    } catch {
      this.dialogTranslation = {};
    }
  }

  private initCapacityData() {
    const capacityDataKeys = Object.keys(this.capacity);
    this.capacityData = this.weekDays.map((iWeekDay, index) => ({
      dayName: iWeekDay,
      key: capacityDataKeys[index],
    }));
  }

  public updatePlannedCapacity(minutes: number, key: number): void {
    this.capacity[key] = minutes ? minutes : 0;
    this.cdr.detectChanges();
  }

  public updateCapacityValidation(validation: InputValidation): void {
    this.capacityValidation = validation;
    this.cdr.detectChanges();
  }

  public savePlannedCapacity(): void {
    if (check.not.assigned(this.capacityValidation) || this.capacityValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close(this.capacity);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
