<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogName" (clickCloseButton)="closeDialog()">
  <ng-container *ngIf="salary">
    <div *ngIf="onGoingSalary">
      <orgos-warning-message class="kenjo-mb-20px di-warning-message">{{dialogTranslation.onGoingWarning}}</orgos-warning-message>
      <div class="kenjo-font-size-14px kenjo-pv-10px">{{dialogTranslation.onGoing}}</div>
      <orgos-column-container
        class="kenjo-ph-20px kenjo-mt-10px kenjo-border kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-radius-4px"
      >
        <orgos-input-date-picker
          class="kenjo-pt-20px kenjo-pb-10px"
          orgosColumn
          [model]="onGoingSalary"
          field="endDate"
          [min]="minEndDateOnGoing"
          [max]="getMaxOnGoingEndDate()"
        >
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('required')">
            {{dialogTranslation.effectiveDateRequired}}</orgos-input-error
          >
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('date')">
            {{dialogTranslation.effectiveDateNoValidFormat}}</orgos-input-error
          >
        </orgos-input-date-picker>
      </orgos-column-container>
    </div>
    <ng-container>
      <div *ngIf="onGoingSalary" class="kenjo-font-size-14px kenjo-pt-50px kenjo-pb-30px">{{dialogTranslation.newSalary}}</div>
      <orgos-column-container>
        <orgos-input-date-picker
          orgosColumn
          [model]="salary"
          field="effectiveDate"
          [required]="true"
          (validation)="effectiveDateValidation = $event"
          (currentValue)="minEndDateNewSalary = $event"
          [max]="salary.data.selectEndDate ? salary.data.selectEndDate : undefined"
          [min]="getMinStartDate()"
          class="kenjo-mr-10px"
        >
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('required')">
            {{dialogTranslation.effectiveDateRequired}}</orgos-input-error
          >
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('date')">
            {{dialogTranslation.effectiveDateNoValidFormat}}</orgos-input-error
          >
        </orgos-input-date-picker>
        <orgos-input-select
          orgosColumn
          [model]="salary"
          field="payPeriod"
          picklist="payPeriod"
          (modelChange)="recalculateSuggestedSurchargeHourlyRate()"
          [required]="true"
          (validation)="payPeriodValidation = $event"
          class="kenjo-ml-10px"
        >
          <orgos-input-error *ngIf="payPeriodValidation && payPeriodValidation.getError('required')">
            {{dialogTranslation.payPeriodRequired}}</orgos-input-error
          >
        </orgos-input-select>
      </orgos-column-container>
    </ng-container>
    <orgos-column-container *ngIf="!canEditPast">
      <orgos-column class="kenjo-mr-10px">
        <orgos-column-container centerColumns="true">
          <orgos-column size="0" class="kenjo-font-color-success-00b72e kenjo-font-size-18px kenjo-mr-5px">
            <mat-icon>info_outline</mat-icon>
          </orgos-column>
          <orgos-column>{{miscTranslation.payrollMinDate}}</orgos-column>
        </orgos-column-container>
      </orgos-column>
      <orgos-column></orgos-column>
    </orgos-column-container>
    <ng-container *ngIf="salary.data.payPeriod && salary.data.effectiveDate">
      <orgos-column-container class="kenjo-mt-20px">
        <orgos-input-select
          orgosColumn
          [model]="salary"
          [value]="{endDate: optionEndDate}"
          field="endDate"
          picklist="endDateType"
          (currentValue)="updateDateType($event)"
          class="kenjo-mr-10px"
        >
          <orgos-input-error *ngIf="payPeriodValidation && payPeriodValidation.getError('required')">
            {{dialogTranslation.payPeriodRequired}}</orgos-input-error
          >
        </orgos-input-select>
        <orgos-input-date-picker
          orgosColumn
          [model]="salary"
          field="selectEndDate"
          [readOnly]="isIndefiniteDate"
          [min]="minEndDateNewSalary"
          class="kenjo-ml-10px"
        >
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('required')">
            {{dialogTranslation.effectiveDateRequired}}</orgos-input-error
          >
          <orgos-input-error *ngIf="effectiveDateValidation && effectiveDateValidation.getError('date')">
            {{dialogTranslation.effectiveDateNoValidFormat}}</orgos-input-error
          >
        </orgos-input-date-picker>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-20px">
        <orgos-input-select
          orgosColumn
          readOnly="true"
          [model]="salary"
          field="_companyId"
          [value]="{_companyId: data.companyId}"
          [options]="companyOptions"
          [required]="true"
        >
        </orgos-input-select>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-20px">
        <orgos-input-number
          *ngIf="salary.data.payPeriod !== 'Hourly'"
          orgosColumn
          [model]="salary"
          field="amount"
          (modelChange)="recalculateSuggestedSurchargeHourlyRate()"
          step="0.01"
          [min]="0"
          [required]="true"
          (validation)="amountValidation = $event"
          (currentValue)="currentAmount = $event"
          class="kenjo-mr-10px"
        >
          <orgos-input-error *ngIf="amountValidation && amountValidation.getError('required')">
            {{dialogTranslation.salaryRequired}}</orgos-input-error
          >
          <orgos-input-error
            *ngIf="amountValidation && !amountValidation.getError('required') && !amountValidation.getError('float') && amountValidation.getError('min')"
          >
            {{dialogTranslation.salaryMinValue}}</orgos-input-error
          >
          <orgos-input-error *ngIf="amountValidation && !amountValidation.getError('required') && amountValidation.getError('float')">
            {{dialogTranslation.salaryNoValidFormat}}</orgos-input-error
          >
        </orgos-input-number>
        <orgos-input-number
          *ngIf="salary.data.payPeriod === 'Hourly'"
          orgosColumn
          [model]="salary"
          field="amount"
          [label]="dialogTranslation.hourlyRate"
          step="0.01"
          [min]="0"
          [required]="true"
          (validation)="amountValidation = $event"
          class="kenjo-mr-10px"
        >
          <orgos-input-error *ngIf="amountValidation && amountValidation.getError('required')">
            {{dialogTranslation.hourlyRateRequired}}</orgos-input-error
          >
          <orgos-input-error
            *ngIf="amountValidation && !amountValidation.getError('required') && !amountValidation.getError('float') && amountValidation.getError('min')"
          >
            {{dialogTranslation.hourlyRateMinValue}}</orgos-input-error
          >
          <orgos-input-error *ngIf="amountValidation && !amountValidation.getError('required') && amountValidation.getError('float')">
            {{dialogTranslation.hourlyRateNoValidFormat}}</orgos-input-error
          >
        </orgos-input-number>
        <orgos-input-select
          orgosColumn
          [sortOptions]="false"
          [model]="salary"
          field="currency"
          picklist="currency"
          [required]="true"
          (validation)="currencyValidation = $event"
          class="kenjo-ml-10px"
        >
          <orgos-input-error *ngIf="currencyValidation && currencyValidation.getError('required')">
            {{dialogTranslation.currencyRequired}}</orgos-input-error
          >
        </orgos-input-select>
      </orgos-column-container>
      <orgos-column-container *ngIf="salary.data.payPeriod !== 'Hourly'" class="kenjo-mt-20px">
        <orgos-input-number
          orgosColumn
          [model]="salary"
          field="partTime"
          [required]="true"
          [min]="0"
          [max]="100"
          step="0.01"
          suffix="%"
          (validation)="partTimeValidation = $event"
          (currentValue)="currentPartTime = $event"
          class="kenjo-mr-10px"
        >
          <orgos-input-error *ngIf="partTimeValidation && partTimeValidation.getError('required')">
            {{dialogTranslation.partTimeRequired}}</orgos-input-error
          >
          <orgos-input-error
            *ngIf="partTimeValidation && !partTimeValidation.getError('required') && !partTimeValidation.getError('float') && partTimeValidation.getError('min')"
          >
            {{dialogTranslation.partTimeMinValue}}</orgos-input-error
          >
          <orgos-input-error
            *ngIf="partTimeValidation && !partTimeValidation.getError('required') && !partTimeValidation.getError('float') && partTimeValidation.getError('max')"
          >
            {{dialogTranslation.partTimeMaxValue}}</orgos-input-error
          >
          <orgos-input-error *ngIf="partTimeValidation && !partTimeValidation.getError('required') && partTimeValidation.getError('float')">
            {{dialogTranslation.partTimeNoValidFormat}}</orgos-input-error
          >
        </orgos-input-number>
        <orgos-input-simple-text
          orgosColumn
          [readOnly]="true"
          [value]="getFTESalary()"
          [label]="dialogTranslation.fteSalary"
          [suffix]="salary.data.currency"
          class="kenjo-ml-10px"
        >
        </orgos-input-simple-text>
      </orgos-column-container>

      <!-- RATE PER HOUR -->
      <orgos-column-container *ngIf="salary.data.payPeriod !== 'Hourly'" class="kenjo-mt-20px">
        <orgos-column size="1">
          <div>
            <orgos-input-number
              orgosColumn="1"
              [model]="salary"
              field="surchargeHourlyRate"
              (modelChange)="surchargeHoursEdited()"
              (validation)="hourlyRateValidation = $event"
              [min]="0"
              step="0.01"
              [suffixIcon]="surchargeHoursEditedManually && !hourlyRateValidation?.hasErrors() ? 'replay' : null"
              [suffixIconTooltip]="dialogTranslation.recalculateSurchargeRateTooltip"
              (suffixIconClick)="recalculateSuggestedSurchargeHourlyRate(true)"
              class="kenjo-mr-10px kenjo-full-width"
            >
              <orgos-input-error
                *ngIf="hourlyRateValidation && !hourlyRateValidation.getError('float') && hourlyRateValidation.getError('min')"
              >
                {{ dialogTranslation.hourlyRateMinValue }}
              </orgos-input-error>
              <orgos-input-error *ngIf="hourlyRateValidation && hourlyRateValidation.getError('float')">
                {{ dialogTranslation.hourlyRateNoValidFormat }}
              </orgos-input-error>
            </orgos-input-number>
            <div
              *ngIf="!surchargeHoursEditedManually && salary.data?.surchargeHourlyRate && !hourlyRateValidation?.hasErrors()"
              class="kenjo-font-size-12px kenjo-font-color-light-text-757575 sd-subtext"
            >
              {{ dialogTranslation.ratePerHourSubtext }}
            </div>
          </div>
        </orgos-column>
        <orgos-column size="1" class="kenjo-ml-10px">
          <div class="kenjo-textarea kenjo-font-size-12px kenjo-font-color-light-text-757575">
            {{ dialogTranslation.ratePerHourClarification }}&nbsp;<a
              [href]="dialogTranslation.ratePerHourLearnMoreHyperlink"
              target="_blank"
              >{{ dialogTranslation.ratePerHourLearnMoreText }}</a
            >
          </div>
        </orgos-column>
      </orgos-column-container>

      <orgos-input-text-area [model]="salary" field="comments" class="kenjo-mt-40px"> </orgos-input-text-area>
    </ng-container>

    <orgos-column-container class="kenjo-mt-60px">
      <orgos-column></orgos-column>
      <orgos-column size="0">
        <orgos-button-raised (click)="closeDialog()" color="Neutral">{{miscTranslation.goBackButtonDialog}} </orgos-button-raised>
      </orgos-column>
      <orgos-column size="0" class="kenjo-ml-20px">
        <orgos-button-raised
          color="Success"
          (click)="addSalary()"
          [disabled]="!effectiveDateValidation || effectiveDateValidation.hasErrors() ||
            !payPeriodValidation || payPeriodValidation.hasErrors() ||
            !amountValidation || amountValidation.hasErrors() ||
            !currencyValidation || currencyValidation.hasErrors() ||
            !partTimeValidation || partTimeValidation.hasErrors() ||
            !hourlyRateValidation || hourlyRateValidation.hasErrors()"
        >
          {{dialogTranslation.buttonLabel}}</orgos-button-raised
        >
      </orgos-column>
    </orgos-column-container>
  </ng-container>
</orgos-dialog-container>
