import { Injector } from '@angular/core';
import * as check from 'check-types';

import { ErrorManagerService } from '../services/error/error-manager.service';
import { ErrorCodes } from './error/error-codes';
import { OrgosError } from './error/orgos-error';
import { GenericModel } from './generic-model';

export class GenericCacheModel extends GenericModel {
  private id: string;
  public data: any;

  constructor(injector: Injector, rawData: any, serviceClass: any, ownerId?: string, operationOptions?: any) {
    super(injector, rawData, serviceClass, ownerId, operationOptions);

    this.id = rawData._id;
    this.data = rawData;
  }

  public update(): Promise<void> {
    return Promise.resolve();
  }

  public createInServer(): Promise<GenericCacheModel> {
    return new Promise<GenericCacheModel>((resolve, reject) => {
      if ((check.assigned(this.data._id) && check.string(this.data._id) && check.not.emptyString(this.data._id)) || (check.assigned(this.id) && check.string(this.id) && check.not.emptyString(this.id))) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, GenericCacheModel.name, 'createInServer');
        error.message = 'Id cannot be set for creations';
        reject(this.injector.get(ErrorManagerService).handleParsedErrorSilently(error));
        return;
      }

      this.canCreate
        .then((canCreate) => {
          if (!canCreate) {
            const error = new OrgosError('UNAUTHORIZED', ErrorCodes.CLIENT_ERROR, GenericCacheModel.name, 'createInServer');
            error.message = 'No create permission';
            reject(this.injector.get(ErrorManagerService).handleParsedErrorSilently(error));
            return;
          }

          return this.service.create(this.data, this.operationOptions);
        })
        .then((data: any) => {
          const newModel = new GenericCacheModel(this.injector, data, this.serviceClass, data.ownerId);
          resolve(newModel);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateInServer(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (check.not.assigned(this.id) || check.not.string(this.id) || check.emptyString(this.id)) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, GenericCacheModel.name, 'updateInServer');
        error.message = 'Id is mandatory for updates';
        reject(this.injector.get(ErrorManagerService).handleParsedErrorSilently(error));
        return;
      }

      this.canEdit
        .then((canEdit) => {
          if (!canEdit) {
            const error = new OrgosError('UNAUTHORIZED', ErrorCodes.CLIENT_ERROR, GenericCacheModel.name, 'updateInServer');
            error.message = 'No edit permission';
            reject(this.injector.get(ErrorManagerService).handleParsedErrorSilently(error));
            return;
          }

          this.data._id = this.id;
          resolve(this.service.updateById(this.id, this.data, this.operationOptions));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
