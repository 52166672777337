import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'orgos-container',
  templateUrl: 'container.component.html',
  styleUrls: ['container.component.scss']
})
export class ContainerComponent {
  @Input()
  set aspect(aspect: 'default' | 'card') {
    if (aspect === 'card') {
      this.cardAspectClass = true;
    } else {
      this.cardAspectClass = false;
    }
  }

  @Input() set overflowVisible(overflowVisible: boolean) {
    this._overflowVisible = overflowVisible;
  }

  @HostBinding('class.cc-card-aspect') cardAspectClass: boolean = false;
  @HostBinding('class.cc-card-aspect-o') _overflowVisible: boolean = false;
}
