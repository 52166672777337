<orgos-dialog-container *ngIf="pageTranslation" class="csd-dialog-box" [title]="pageTranslation.dialogTitle" [disabledCloseButton]="isCopying" (clickCloseButton)="closeDialog()">
  <orgos-column-container *ngIf="filters && (filters.locations.length > 0 || filters.employees.length > 0 || filters.roles.length > 0)" class="kenjo-p-10px csd-error-area kenjo-mb-30px" centerColumns="true">
    <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
    <div class="kenjo-ml-10px" orgosColumn>{{pageTranslation.filtersSelected}}</div>
  </orgos-column-container>
  <orgos-column-container centerColumns="true" class="csd-tabs kenjo-border kenjo-mb-40px kenjo-font-size-16px">
    <div orgosColumn class="csd-tab kenjo-border-right kenjo-text-align-center csd-border-radius-left" [class.selected]="timePeriod === WEEK_OPTION" (click)="changeTimePeriod(WEEK_OPTION)">{{pageTranslation.week}}</div>
    <div orgosColumn class="csd-tab kenjo-text-align-center csd-border-radius-right" [class.selected]="timePeriod === DAY_OPTION" (click)="changeTimePeriod(DAY_OPTION)">{{pageTranslation.day}}</div>
  </orgos-column-container>
  <orgos-column-container centerColumns="true" class="kenjo-mb-40px csd-align-columns">
    <orgos-column size="1" class="kenjo-pr-10px">
      <kenjo-input-shiftplan-date-picker
        [required]="true"
        [isWeeklyPeriod]="timePeriod === WEEK_OPTION"
        [isFromDate]="true"
        [weekRange]="weekRange"
        [(hasErrors)]="hasFromErrors"
        class="csd-width-100"
        [label]="pageTranslation.copyFrom"
        [pageTranslation]="pageTranslation"
        [calendarType]="timePeriod === WEEK_OPTION ? 'multiple-weeks' : 'one-day'"
        [(selectedValues)]="copyFromDates"
        [hasSwitched]="hasSwitched"
        (valueChange)="handleCopyValue($event, 'from')"
      >
        <orgos-input-error *ngIf="inputFromValidation && inputFromValidation['alreadySelected']">{{pageTranslation.weekAlreadySelected}}</orgos-input-error>
        <orgos-input-error *ngIf="inputFromValidation && inputFromValidation['sameWeek']">{{pageTranslation.differentWeekErrorTo}}</orgos-input-error>
        <orgos-input-error *ngIf="inputFromValidation && inputFromValidation['consecutiveWeeks']">{{pageTranslation.consecutiveWeeksError}}</orgos-input-error>
        <orgos-input-error *ngIf="!(inputFromValidation['alreadySelected'] || inputFromValidation['sameWeek'] || inputFromValidation['consecutiveWeeks']) && ((shiftCardSummary?.total === 0 && timePeriod === WEEK_OPTION) || (inputFromValidation && inputFromValidation['required']))">{{pageTranslation.nothingToCopy}}</orgos-input-error>
        <orgos-input-error *ngIf="shiftCardSummary?.total === 0 && timePeriod !== WEEK_OPTION">{{pageTranslation.nothingToCopyDays}}</orgos-input-error>
      </kenjo-input-shiftplan-date-picker>
    </orgos-column>
    <orgos-column size="1" class="kenjo-pl-10px csd-align-columns">
      <kenjo-input-shiftplan-date-picker
        [required]="true"
        [isWeeklyPeriod]="timePeriod === WEEK_OPTION"
        [weekRange]="weekRange"
        [(hasErrors)]="hasToErrors"
        class="csd-width-100"
        [label]="pageTranslation.copyTo"
        [errorTexts]="true"
        [pageTranslation]="pageTranslation"
        [(selectedValues)]="copyToDates"
        [calendarType]="timePeriod === WEEK_OPTION ? 'multiple-weeks' : 'multiple-days'"
        [copyFromMultiple]="copyFromMultiple"
        [hasSwitched]="hasSwitched"
        (valueChange)="handleCopyValue($event, 'to')"
      >
        <orgos-input-error *ngIf="inputToValidation && inputToValidation['required']">{{pageTranslation.selectADateRange}}</orgos-input-error>
        <orgos-input-error *ngIf="inputToValidation && inputToValidation['alreadySelected']">{{timePeriod !== WEEK_OPTION ? pageTranslation.differentDayError: pageTranslation.differentWeekErrorFrom}}</orgos-input-error>
        <orgos-input-error *ngIf="inputToValidation && inputToValidation['numberOfWeeks']">{{pageTranslation.reselectCopyTo}}</orgos-input-error>
        <orgos-input-error *ngIf="inputFromValidation && inputFromValidation['numberOfWeeks']">{{pageTranslation.differentWeekNumberFrom}}</orgos-input-error>
        <orgos-input-error *ngIf="inputToValidation && inputToValidation['sameWeek']">{{pageTranslation.differentWeekErrorFrom}}</orgos-input-error>
        <orgos-input-error *ngIf="inputToValidation && inputToValidation['consecutiveWeeks']">{{pageTranslation.consecutiveWeeksError}}</orgos-input-error>
        <orgos-input-error *ngIf="shiftsLimitReached">{{SHIFTS_LIMIT}} {{pageTranslation.limitReachedError}}</orgos-input-error>
      </kenjo-input-shiftplan-date-picker>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container>
    <orgos-column size="1" centerColumns="true" class="kenjo-pr-10px">
      <orgos-column-container class="kenjo-mb-20px" centerColumns="true">
        <mat-slide-toggle [(ngModel)]="includeDrafts" (ngModelChange)="calculateTotal(); checkValid()" orgosColumn="0"></mat-slide-toggle>
        <div orgosColumn class="kenjo-ml-10px">{{pageTranslation.includeUnpublished}}</div>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mb-20px" centerColumns="true">
        <mat-slide-toggle [(ngModel)]="includeOpen" (ngModelChange)="calculateTotal(); checkValid()" orgosColumn="0"></mat-slide-toggle>
        <div orgosColumn class="kenjo-ml-10px">{{pageTranslation.includeOpen}}</div>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1" class="kenjo-pl-10px">
      <div class="kenjo-font-weight-bold">{{pageTranslation.copyShiftsSummary}}</div>
      <div *ngIf="!shiftCardSummary && timePeriod === WEEK_OPTION" class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{pageTranslation.noWeekSelectedSummary}}</div>
      <div *ngIf="!shiftCardSummary && timePeriod !== WEEK_OPTION" class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{pageTranslation.noDaySelectedSummary}}</div>
      <ng-container *ngIf="shiftCardSummary">
        <div *ngIf="shiftCardSummary.scheduledPublishedCount" class="kenjo-mt-10px">{{pageTranslation.publishedScheduledSummary}} {{shiftCardSummary.scheduledPublishedCount}}</div>
        <div *ngIf="includeDrafts && shiftCardSummary.scheduledDraftCount" class="kenjo-mt-10px">{{pageTranslation.unpublishedScheduledSummary}} {{shiftCardSummary.scheduledDraftCount}}</div>
        <div *ngIf="includeOpen && shiftCardSummary.openDraftCount" class="kenjo-mt-10px">{{pageTranslation.unpublishedOpenShiftsSummary}} {{shiftCardSummary.openDraftCount}}</div>
        <div class="kenjo-font-weight-bold kenjo-mt-20px">{{pageTranslation.totalSummary}} {{shiftCardSummary.total}}</div>
      </ng-container>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container *ngIf="isLoading || isCopying" class="csd-loading-container" centerColumns="true">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <div orgosColumn="0" class="kenjo-pl-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ pageTranslation.copyingButton | uppercase }}</div>
  </orgos-column-container>

  <orgos-column-container *ngIf="!isLoading && !isCopying" class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised [disabled]="isCopying" (click)="dialogRef.close()" color="Neutral">{{pageTranslation.backButton}}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised [disabled]="!isValid || isLoading || isCopying" (click)="copyShifts()" color="Success">{{pageTranslation.copyButton}}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
