import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { IFilterField } from '@app/standard/components/filter-bar/filter-bar.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IHeader } from '@app/standard/components/table/table.component';
import {
  CompanyDocReadTrackingDialogServiceService,
  IReadTrackingEmployeeData,
} from '@app/standard/pages/documents/dialogs/company-doc-read-tracking-dialog/company-doc-read-tracking-dialog.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-company-doc-read-tracking-dialog',
  templateUrl: './company-doc-read-tracking.dialog.html',
  styleUrls: ['./company-doc-read-tracking.dialog.scss'],
})
export class CompanyDocReadTrackingDialog implements OnInit {
  translations: any = {};
  headerLabels: IHeader;
  columns = ['displayName', 'requestedStatus', 'progressStatus', 'readDate', 'hasAccess', 'icon'];
  isLoading: boolean = false;
  oldNumberOfEmployees: number = 0;
  numberOfEmployeesWhoHaventReceivedRequest: number = 0;
  numberOfEmployeesWhoCanReceiveReminder: number = 0;
  sendingReadRequest: boolean = false;

  queryOptions: any = {
    paginationOptions: {
      recordsPerPage: 25,
      page: 1,
    },
    sortOptions: {
      sortBy: 'displayName',
      sortOrder: 'asc',
    },
    searchTerm: '',
    filterOptions: {},
  };

  // pagination settings
  recordsToShowSelector: Array<number> = [25, 50, 100];
  PAGE_SELECTOR: any = {
    first: 1,
    previous: 2,
    next: 3,
    final: 4,
    isInit: true,
  };
  numberOfPages: number = 1;
  filteredRecords: number = 0;
  totalRecords: number = 0;

  // filter settings
  filterFields: Array<IFilterField> = [];

  constructor(
    public dialogRef: MatLegacyDialogRef<CompanyDocReadTrackingDialog>,
    public service: CompanyDocReadTrackingDialogServiceService,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { documentId: string; documentName: string },
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.loadTranslations();
    this.loadData();
  }

  async loadTranslations(): Promise<void> {
    this.translations = await this.injector.get(InternationalizationService).getAllTranslation('documents-read-tracking-dialog');
    this.headerLabels = {
      displayName: this.translations.employeeNameColumnHeader,
      requestedStatus: this.translations.requestedStatusColumnHeader,
      progressStatus: this.translations.progressStatusColumnHeader,
      readDate: this.translations.readOnDateColumnHeader,
      hasAccess: this.translations.hasAccessColumnHeader,
    };
    this.buildStaticFilters();
  }

  private async loadData(): Promise<void> {
    this.isLoading = true;
    try {
      this.oldNumberOfEmployees = this.service.readTrackingEmployees.length;
      await this.service.loadEmployeeData(this.data.documentId, this.queryOptions);
      this.numberOfEmployeesWhoHaventReceivedRequest = this.service.readTrackingEmployees.filter(
        (employee: IReadTrackingEmployeeData) => check.not.assigned(employee.readBy.userId) && employee.hasAccess === true
      ).length;
      this.numberOfEmployeesWhoCanReceiveReminder = this.service.readTrackingEmployees.filter(
        (employee: IReadTrackingEmployeeData) =>
          check.assigned(employee.readBy.userId) && check.not.assigned(employee.readBy.readDate) && employee.hasAccess === true
      ).length;
      this.numberOfPages = this.service.readTrackingEmployeesMetadata.numberOfPages;
      this.totalRecords = this.service.readTrackingEmployeesMetadata.totalRecords;
      this.filteredRecords = this.service.readTrackingEmployeesMetadata.filteredRecords;
    } catch {
      // an error is already shown
    } finally {
      this.sendingReadRequest = false;
      this.isLoading = false;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // pagination methods
  onChangeRecordsToShow(recordsPerPage: number): void {
    if (recordsPerPage === this.queryOptions.paginationOptions.recordsPerPage) {
      return;
    }
    this.queryOptions.paginationOptions.recordsPerPage = recordsPerPage;
    this.queryOptions.paginationOptions.page = 1;
    this.loadData();
  }

  moveToPage(option: number): void {
    const currentPagePrev = this.queryOptions.paginationOptions.page;
    if (option === this.PAGE_SELECTOR['first']) {
      this.queryOptions.paginationOptions.page = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && this.queryOptions.paginationOptions.page > 1) {
      this.queryOptions.paginationOptions.page--;
    } else if (option === this.PAGE_SELECTOR['next'] && this.queryOptions.paginationOptions.page < this.numberOfPages) {
      this.queryOptions.paginationOptions.page++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      this.queryOptions.paginationOptions.page = this.numberOfPages;
    }
    if (currentPagePrev === this.queryOptions.paginationOptions.page) {
      return;
    }
    this.loadData();
  }

  // filter methods
  private buildStaticFilters(): void {
    this.filterFields = [
      {
        key: 'requested',
        label: this.translations.filters.requestedLabel,
        options: [
          {
            name: this.translations.filters.requestedOptionName,
            _id: true,
            active: false,
          },
          {
            name: this.translations.filters.notRequestedOptionName,
            _id: false,
            active: false,
          },
        ],
        showAvatar: false,
        pickerType: 'single',
      },
      {
        key: 'progress',
        label: this.translations.filters.readProgressLabel,
        options: [
          {
            name: this.translations.filters.readOptionName,
            _id: true,
            active: false,
          },
          {
            name: this.translations.filters.unreadOptionName,
            _id: false,
            active: false,
          },
        ],
        showAvatar: false,
        pickerType: 'single',
      },
      {
        key: 'access',
        label: this.translations.filters.accessLabel,
        options: [
          {
            name: this.translations.filters.yesOptionName,
            _id: true,
            active: false,
          },
          {
            name: this.translations.filters.noOptionName,
            _id: false,
            active: false,
          },
        ],
        showAvatar: false,
        pickerType: 'single',
      },
    ];
  }

  changeFilter(event: any): void {
    this.queryOptions.filterOptions[event.field] = event.value;
    this.loadData();
  }

  clearFilter(filterKey: string): void {
    delete this.queryOptions.filterOptions[filterKey];
    this.loadData();
  }

  clearAllFilters(): void {
    this.queryOptions.searchTerm = '';
    this.queryOptions.filterOptions = {};
    this.loadData();
  }

  searchTitle(event: string): void {
    const processedSearchTerm = event.trim();
    if (processedSearchTerm === this.queryOptions.searchTerm) {
      return;
    }
    if (processedSearchTerm.length > 0 && processedSearchTerm.length < 3) {
      return;
    }
    this.queryOptions.searchTerm = processedSearchTerm;
    this.loadData();
  }

  sortColumn(value: any): void {
    this.queryOptions.sortOptions.sortBy = value.sortBy;
    this.queryOptions.sortOptions.sortOrder = value.sortOrder;
    this.loadData();
  }

  async sendIndividualRequest(employee: IReadTrackingEmployeeData): Promise<void> {
    if (employee.hasAccess === false) {
      return;
    }
    try {
      let documentRequestReadTranslation;
      if (check.assigned(employee.readBy.userId) && check.not.assigned(employee.readBy.readDate)) {
        documentRequestReadTranslation = await this.injector
          .get(InternationalizationService)
          .getAllTranslation('documents-read-tracking-individual-reminder-confirmation-dialog');
      } else {
        documentRequestReadTranslation = await this.injector
          .get(InternationalizationService)
          .getAllTranslation('documents-read-tracking-individual-request-confirmation-dialog');
      }

      const dialogData = {
        titleText: documentRequestReadTranslation.dialogHeader,
        subtitleText: documentRequestReadTranslation.warningMessageText,
        confirmButtonText: documentRequestReadTranslation.confirmButtonLabel,
        confirmButtonColor: 'Success',
        cancelButtonText: documentRequestReadTranslation.cancelButtonLabel,
        employees: [employee],
      };
      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
      dialogRef.afterClosed().subscribe(async (requested: boolean) => {
        if (check.not.assigned(requested) || requested === false) {
          return;
        }
        this.sendingReadRequest = true;
        await this.service.sendIndividualRequest(employee, this.data.documentId, this.data.documentName);
        this.injector.get(MatLegacySnackBar).open(this.translations.requestSnackbarText, 'OK', {
          duration: 5000,
        });
        this.loadData();
      });
    } catch {
      // An error is already shown
    } finally {
      this.sendingReadRequest = false;
    }
  }

  async exportData(): Promise<void> {
    this.sendingReadRequest = true;
    await this.injector
      .get(CompanyDocReadTrackingDialogServiceService)
      .exportData(this.translations, this.data.documentId, this.queryOptions, this.headerLabels);
    this.injector.get(MatLegacySnackBar).open(this.translations.exportSnackBarText, 'OK', {
      duration: 5000,
    });
    this.sendingReadRequest = false;
  }

  // only for not requested employees
  async sendBulkReadRequest(): Promise<void> {
    const documentRequestReadTranslation = await this.injector
      .get(InternationalizationService)
      .getAllTranslation('documents-read-tracking-bulk-request-confirmation-dialog');

    const affectedEmployees = this.service.readTrackingEmployees.filter(
      (employee: IReadTrackingEmployeeData) => check.not.assigned(employee.readBy.userId) && employee.hasAccess === true
    );

    const dialogData = {
      titleText: documentRequestReadTranslation.dialogHeader,
      subtitleText: this.injector
        .get(I18nDataPipe)
        .transform(documentRequestReadTranslation.warningMessageText, { number: affectedEmployees.length }),
      confirmButtonText: documentRequestReadTranslation.confirmButtonLabel,
      confirmButtonColor: 'Success',
      cancelButtonText: documentRequestReadTranslation.cancelButtonLabel,
      employees: affectedEmployees,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(async (requested: boolean) => {
      if (check.not.assigned(requested) || requested === false) {
        return;
      }
      this.sendingReadRequest = true;
      await this.service.sendTargetedBulkRequest(affectedEmployees, this.data.documentId);
      this.injector.get(MatLegacySnackBar).open(this.translations.requestSnackbarText, 'OK', {
        duration: 5000,
      });
      this.loadData();
    });
  }

  async sendBulkReadReminder(): Promise<void> {
    const documentRequestReminderTranslation = await this.injector
      .get(InternationalizationService)
      .getAllTranslation('documents-read-tracking-bulk-reminder-confirmation-dialog');

    const affectedEmployees = this.service.readTrackingEmployees.filter(
      (employee: IReadTrackingEmployeeData) =>
        check.assigned(employee.readBy.userId) && check.not.assigned(employee.readBy.readDate) && employee.hasAccess === true
    );

    const dialogData = {
      titleText: documentRequestReminderTranslation.dialogHeader,
      subtitleText: this.injector
        .get(I18nDataPipe)
        .transform(documentRequestReminderTranslation.warningMessageText, { number: affectedEmployees.length }),
      confirmButtonText: documentRequestReminderTranslation.confirmButtonLabel,
      confirmButtonColor: 'Success',
      cancelButtonText: documentRequestReminderTranslation.cancelButtonLabel,
      employees: affectedEmployees,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(async (requested: boolean) => {
      if (check.not.assigned(requested) || requested === false) {
        return;
      }
      this.sendingReadRequest = true;
      await this.service.sendTargetedBulkRequest(affectedEmployees, this.data.documentId);
      this.injector.get(MatLegacySnackBar).open(this.translations.remindersSnackbarText, 'OK', {
        duration: 5000,
      });
      this.loadData();
    });
  }
}
