import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { getEmptyFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { ShiftCardFilters, ShiftPlanCardService } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-custom-delete-dialog',
  templateUrl: 'custom-delete-dialog.html',
  styleUrls: ['custom-delete-dialog.scss'],
})
export class CustomDeleteDialog implements OnInit {
  dialogTranslation: { [key: string]: string };
  isDeleting: boolean = false;
  totalShifts: number = 0;
  shiftsLimitReached: boolean = false;
  shiftsCount = { open: 0, scheduled: 0 };
  SHIFTS_LIMIT_TO_DELETE_CUSTOM: number = 2000;

  filters: ShiftCardFilters = { ...getEmptyFilters() };
  shiftsData: any;
  shiftsToDeleteList: any;

  constructor(
    public dialogRef: MatLegacyDialogRef<CustomDeleteDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    public snackBar: MatLegacySnackBar
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.shiftsData = this.dialogInfo;
    this.shiftsLimitReached = this.shiftsData.shiftsDeleteLimitReached;
    this.shiftsToDeleteList = Object.values(this.shiftsData.shiftsToDelete);
    this.filters = this.shiftsData.filters;
    this.fetchTranslations();
    this.calculateShiftsCount();
    this.calculateTotal();
  }

  async fetchTranslations() {
    this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-clear-schedule-dialog');
  }

  public calculateShiftsCount() {
    this.shiftsToDeleteList.forEach((shift) => {
      if (shift.isOpen) {
        this.shiftsCount.open++;
      } else {
        this.shiftsCount.scheduled++;
      }
    });
  }

  public calculateTotal() {
    this.totalShifts = this.shiftsToDeleteList.length;
  }

  public async deleteShifts() {
    try {
      this.isDeleting = true;

      if (this.shiftsToDeleteList?.length === 1) {
        await this.injector.get(ShiftPlanCardService).deleteAndNotify(this.shiftsToDeleteList?.[0]?._id);
      } else {
        const shiftToDeletePayload = this.shiftsToDeleteList.map((shift) => ({ _id: shift._id, ownerId: shift.ownerId }));
        await this.injector
          .get(ShiftPlanCardService)
          .removeMany(
            this.shiftsData.startDay,
            moment.utc(this.shiftsData.endDay).endOf('day').toISOString(),
            false,
            this.filters,
            shiftToDeletePayload
          );
      }

      const snackBarMessage = this.injector
        .get(I18nDataPipe)
        .transform(this.dialogTranslation.successfullyDeleteTooltip, { shiftsNumber: this.totalShifts });
      this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
    } catch {
      // do nothing
    } finally {
      this.isDeleting = false;
      this.dialogRef.close(true);
    }
  }

  public closeDialog(): void {
    if (this.isDeleting) {
      return;
    }
    this.dialogRef.close();
  }
}
