import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'kenjo-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class IndicatorComponent {
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';
  @Input() semantic: 'info' | 'success' | 'danger' | 'warning' | 'caution' | 'neutral' | 'user' = 'info';
  @Input() borderType: 'none' | 'white' | 'highlight' = 'none';
  @Input() numberLabel: number;
}
