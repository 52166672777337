<orgos-column-container *ngIf="creatingDocument === false && documentTemplate" class="dtdp-full-screen">
  <orgos-column size="9">
    <kenjo-previewer [metadata]="documentTemplate._file"></kenjo-previewer>
  </orgos-column>
  <orgos-column size="3" class="dtdp-info-container kenjo-p-20px kenjo-color-grey-background-f5f5f5 kenjo-border-left">
    <!-- SETUP MODE -->
    <ng-container *ngIf="documentTemplate.setupDone === false">
      <div class="kenjo-font-size-18px">{{pageTranslation.setupTitle}}</div>
      <orgos-input-text [model]="documentTemplateModel" field="name" class="kenjo-block kenjo-mt-40px" [required]="true" (validation)="nameValidation = $event">
        <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')"> {{pageTranslation.nameRequired}}</orgos-input-error>
      </orgos-input-text>
      <div class="kenjo-font-size-16px kenjo-mt-20px">{{documentTemplateCollectionTranslation.fields}}</div>
      <ng-container *ngFor="let rawField of documentTemplateModel.data.fields">
        <orgos-input-select class="kenjo-block kenjo-mt-10px" [model]="documentTemplateModel" [value]="rawField" field="field" [label]="rawField.tag" [options]="allFields" [enableClearButton]="true"> </orgos-input-select>
      </ng-container>
      <div *ngIf="!documentTemplateModel.data.fields || documentTemplateModel.data.fields.length === 0" class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{pageTranslation.noFieldsText}}</div>

      <orgos-column-container class="kenjo-mt-60px">
        <orgos-column></orgos-column>
        <orgos-button-raised orgosColumn="0" (click)="onBackClick()" color="Neutral">
          {{miscTranslation.goBackButtonDialog}}
        </orgos-button-raised>
        <orgos-button-raised orgosColumn="0" color="Success" (click)="updateDocumentTemplate()" [disabled]="!nameValidation || nameValidation.hasErrors()" class="kenjo-ml-10px"> {{pageTranslation.saveButtonLabel}}</orgos-button-raised>
      </orgos-column-container>
    </ng-container>
    <!-- END SETUP MODE -->

    <!-- READ MODE -->
    <orgos-action *ngIf="documentTemplate.setupDone === true && readModeActivated === true" icon="arrow_back" (click)="onBackClick()">{{pageTranslation.back}} </orgos-action>
    <ng-container *ngIf="documentTemplate.setupDone === true && readModeActivated === true">
      <div class="kenjo-font-size-16px kenjo-mt-40px">{{pageTranslation.actionsTitle}}</div>
      <div class="kenjo-mt-10px">
        <orgos-action icon="create_document" (click)="enableCreateDocumentMode()"> {{pageTranslation.createDocumentButtonLabel}}</orgos-action>
      </div>
      <div *ngIf="hasEditPermission === true" class="kenjo-mt-10px">
        <orgos-action icon="mode_edit" (click)="editModeActivated = true"> {{pageTranslation.editDocumentTemplateButtonLabel}}</orgos-action>
      </div>
      <div class="kenjo-mt-10px">
        <orgos-action icon="file_download" (click)="download()"> {{pageTranslation.downloadDocumentTemplateButtonLabel}}</orgos-action>
      </div>
      <div *ngIf="hasDeletePermission === true" class="kenjo-mt-10px">
        <orgos-action color="Danger" icon="delete" (click)="delete()">{{pageTranslation.deleteDocumentTemplateButtonLabel}} </orgos-action>
      </div>

      <div class="kenjo-font-size-16px kenjo-mt-40px">{{pageTranslation.templateDetailsTitle}}</div>
      <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px"> {{documentTemplateCollectionTranslation.name}}</orgos-column>
        <orgos-column size="2">{{documentTemplate.name}}</orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px">
          {{documentTemplateCollectionTranslation._createdById}}
        </orgos-column>
        <orgos-column size="2">{{getUserPersonal(documentTemplate._createdById)?.displayName}}</orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-10px" centerColumns="true">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px"> {{documentTemplateCollectionTranslation._createdAt}}</orgos-column>
        <orgos-column size="2">{{documentTemplate._createdAt | date: 'shortDate'}}</orgos-column>
      </orgos-column-container>
      <orgos-column-container class="kenjo-mt-10px">
        <orgos-column class="kenjo-font-color-light-text-757575 kenjo-mr-10px"> {{documentTemplateCollectionTranslation.fields}}</orgos-column>
        <orgos-column size="2">
          <ng-container *ngFor="let iField of documentTemplate.fields">
            <div class="kenjo-font-color-light-text-757575">{{iField.tag}}</div>
            <div class="kenjo-mb-10px">{{getFieldName(iField.field)}}</div>
          </ng-container>
          <ng-container *ngIf="!documentTemplate.fields || documentTemplate.fields.length === 0"> {{pageTranslation.naLabel}}</ng-container>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <!-- END READ MODE -->

    <!-- CREATE DOCUMENT MODE -->
    <ng-container *ngIf="documentTemplate.setupDone === true && createDocumentModeActivated === true">
      <div class="kenjo-font-size-16px">{{pageTranslation.createDocumentModeTitle}}</div>
      <orgos-input-search-user [model]="createDocumentModel" field="assigneeId" class="kenjo-block kenjo-mt-40px" [users]="assigneeOptions" [required]="true" (validation)="assigneeValidation = $event" (modelChange)="assigneeChange()">
        <orgos-input-error *ngIf="assigneeValidation && assigneeValidation.getError('required')"> {{pageTranslation.assigneeRequired}}</orgos-input-error>
      </orgos-input-search-user>

      <orgos-input-simple-checkbox *ngIf="createDocumentModel.data.assigneeId" [(value)]="makeVisibleToAssignee" [label]="pageTranslation.makeVisibleTo | i18nData: getUserPersonal(createDocumentModel.data.assigneeId)" class="kenjo-block kenjo-mt-20px kenjo-mb-40px"></orgos-input-simple-checkbox>

      <orgos-column-container class="kenjo-mt-20px">
        <orgos-column class="kenjo-font-color-light-text-757575">{{documentTemplateCollectionTranslation.fields}} </orgos-column>
        <orgos-column size="2">
          <ng-container *ngFor="let iField of documentTemplate.fields">
            <orgos-input-simple-text [label]="iField.tag" [(value)]="data[iField.tag]" class="kenjo-block kenjo-mb-20px"></orgos-input-simple-text>
          </ng-container>
          <ng-container *ngIf="!documentTemplate.fields || documentTemplate.fields.length === 0"> {{pageTranslation.naLabel}}</ng-container>
        </orgos-column>
      </orgos-column-container>

      <orgos-column-container class="kenjo-mt-60px">
        <orgos-column></orgos-column>
        <orgos-button-raised orgosColumn="0" color="Neutral" (click)="cancel()">{{miscTranslation.goBackButtonDialog}}</orgos-button-raised>
        <orgos-button-raised orgosColumn="0" color="Success" (click)="createDocument()" [disabled]="!assigneeValidation || assigneeValidation.hasErrors()" class="kenjo-ml-10px"> {{pageTranslation.createDocumentButtonLabel}}</orgos-button-raised>
      </orgos-column-container>
    </ng-container>
    <!-- END CREATE DOCUMENT MODE -->

    <!-- EDIT MODE -->
    <ng-container *ngIf="documentTemplate.setupDone === true && editModeActivated === true">
      <orgos-input-text [model]="documentTemplateModel" field="name" class="kenjo-block kenjo-mt-20px" [required]="true" (validation)="nameValidation = $event">
        <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')"> {{pageTranslation.nameRequired}}</orgos-input-error>
      </orgos-input-text>

      <div class="kenjo-mt-20px kenjo-font-size-16px">{{documentTemplateCollectionTranslation.fields}}</div>
      <ng-container *ngFor="let rawField of documentTemplateModel.data.fields">
        <orgos-input-select class="kenjo-block" [model]="documentTemplateModel" [value]="rawField" field="field" [label]="rawField.tag" [options]="allFields" [enableClearButton]="true"> </orgos-input-select>
      </ng-container>
      <div *ngIf="!documentTemplateModel.data.fields || documentTemplateModel.data.fields.length === 0" class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{pageTranslation.noFieldsText}}</div>

      <orgos-column-container class="kenjo-mt-60px">
        <orgos-column></orgos-column>
        <orgos-button-raised orgosColumn="0" color="Neutral" (click)="cancel()">{{miscTranslation.goBackButtonDialog}}</orgos-button-raised>
        <orgos-button-raised orgosColumn="0" color="Success" (click)="updateDocumentTemplate()" [disabled]="!nameValidation || nameValidation.hasErrors()" class="kenjo-ml-10px"> {{pageTranslation.saveButtonLabel}}</orgos-button-raised>
      </orgos-column-container>
    </ng-container>
    <!-- END EDIT MODE -->
  </orgos-column>
</orgos-column-container>

<orgos-row-container *ngIf="creatingDocument === true" class="dtdp-full-screen">
  <orgos-row size="1"></orgos-row>
  <orgos-row size="0">
    <mat-spinner class="dtdp-spinner kenjo-font-color-success-00b72e"> </mat-spinner>
  </orgos-row>
  <orgos-row size="0" class="kenjo-pt-40px kenjo-font-size-18px kenjo-text-align-center">
    {{pageTranslation.creatingDocumentMessage}}
  </orgos-row>
  <orgos-row size="1"></orgos-row>
</orgos-row-container>
