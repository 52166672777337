import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { PrivateInternationalizationService } from '@app/private/services/private-internationalization.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-input-month-picker',
  templateUrl: 'input-month-picker.component.html',
  styleUrls: ['input-month-picker.component.scss']
})
export class InputMonthPickerComponent implements OnInit {
  monthOnHover: string;
  currentDateToShow: string;
  currentSelectedMonthName: string;
  currentSelectedYear: number;
  previousYearValue: number;
  maxYear: number;
  maxMonth: number;
  months: any;

  DECREMENT_YEAR: number = -1;
  INCREMENT_YEAR: number = 1;
  isInitialized: boolean = false;
  isDialogOpened: boolean = false;
  isDateOnHover: boolean = false;
  moment: any = moment();

  @Input() tooltipMessage: string;
  @Input() label: string;

  _maxDate: moment.Moment;
  @Input()
  set maxDate(value: moment.Moment) {
    this._maxDate = value;
    this.setLimitRange();
  }
  get maxDate(): moment.Moment {
    return this._maxDate;
  }

  _disabled: boolean;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  _preferenceDate: moment.Moment;
  @Input()
  set preferenceDate(value: moment.Moment) {
    this._preferenceDate = value;
    if (this.isInitialized) {
      this.setInitialDate();
    }
  }
  get preferenceDate(): moment.Moment {
    return this._preferenceDate;
  }

  @Output() selectedDate: EventEmitter<IQueryDates> = new EventEmitter<IQueryDates>();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    const currentLanguage = this.injector.get(PrivateInternationalizationService).getLanguage();
    const localizedMoment = this.moment.locale(currentLanguage);
    this.months = localizedMoment._locale._months;
    this.isInitialized = true;
    this.setInitialDate();
  }

  public selectMonth(month: string): void {
    this.currentSelectedMonthName = month;
    this.monthOnHover = null;
    this.validateAndCreateDates();
    this.isDialogOpened = false;
  }

  public changeYear(value: number): void {
    this.previousYearValue = this.currentSelectedYear;
    this.currentSelectedYear += value;
  }

  public clickOutside(): void {
    this.checkIfYearChanged();
    this.isDialogOpened = false;
  }

  private validateAndCreateDates(): void {
    if (check.number(this.currentSelectedYear) && check.assigned(this.currentSelectedMonthName)) {
      const monthIndex = this.months.findIndex((iMonth) => {
        return iMonth === this.currentSelectedMonthName;
      });
      const queryDates = {
        startDayOfMonth: moment.utc([this.currentSelectedYear, monthIndex]).startOf('month'),
        endDayOfMonth: moment.utc([this.currentSelectedYear, monthIndex]).endOf('month')
      };
      if (!queryDates.startDayOfMonth.isValid() || !queryDates.endDayOfMonth.isValid()) {
        return;
      }
      this.currentDateToShow = `${this.months[monthIndex]} ${this.currentSelectedYear}`;
      this.selectedDate.emit(queryDates);
    }
  }

  private setInitialDate(): void {
    const currentSelectedDate = check.assigned(this.preferenceDate) ? this.preferenceDate : moment().utc();
    this.currentSelectedYear = currentSelectedDate.clone().year();
    this.currentSelectedMonthName = currentSelectedDate.clone().format('MMMM');
    this.currentDateToShow = `${this.months[currentSelectedDate.month()]} ${this.currentSelectedYear}`;
    this.previousYearValue = this.currentSelectedYear;
  }

  private checkIfYearChanged() {
    if (this.previousYearValue === this.currentSelectedYear) {
      return;
    }
    this.validateAndCreateDates();
  }

  private setLimitRange() {
    this.maxYear = this.maxDate.year();
    this.maxMonth = this.maxDate.month();
  }
}
export interface IQueryDates {
  startDayOfMonth: moment.Moment;
  endDayOfMonth: moment.Moment;
}
