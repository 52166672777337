import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as check from 'check-types';

import { I18nDataPipe } from '../../../components/i18n-data/i18n-data.pipe';
import { ISelectOption } from '../../../core/select-option';
import { InputValidation } from '../../../core/validation/input-validation';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { ProfileService } from '../../../services/profile/profile.service';

@Component({
  selector: 'orgos-clone-profile-dialog',
  templateUrl: 'clone-profile.dialog.html',
  styleUrls: ['clone-profile.dialog.scss'],
})
export class CloneProfileDialog implements OnInit {
  pageTranslation: any = {};
  newProfileName: string;
  profileToCloneFrom: string = 'employee';
  profileNameValidation: InputValidation;
  listOptions: Array<ISelectOption>;

  constructor(public dialogRef: MatLegacyDialogRef<CloneProfileDialog>, private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
    this.initPicklistStandardProfiles();
  }

  private initPicklistStandardProfiles(): void {
    this.injector
      .get(ProfileService)
      .getProfiles()
      .then((profiles: Array<any>) => {
        this.listOptions = profiles
          .filter((iProfile) => {
            return check.assigned(iProfile._isStandard) && iProfile._isStandard === true;
          })
          .map((iProfile) => {
            return { name: iProfile.name, value: iProfile._profileKey };
          });
        this.profileToCloneFrom = this.listOptions[0].value;
      })
      .catch(() => {});
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public cloneProfile(): void {
    if (
      check.not.assigned(this.newProfileName) ||
      check.emptyString(this.newProfileName) ||
      check.not.assigned(this.profileToCloneFrom) ||
      check.emptyString(this.profileToCloneFrom)
    ) {
      return;
    }

    const data = {
      newProfileName: this.newProfileName,
    };

    this.injector
      .get(ProfileService)
      .cloneFromProfile({ name: this.newProfileName })
      .then((newProfile) => {
        const message = this.injector.get(I18nDataPipe).transform(this.pageTranslation.profileClonedSnackbar, data);
        this.injector.get(MatLegacySnackBar).open(message, 'OK', { duration: 5000 });
        this.dialogRef.close(newProfile);
      })
      .catch(() => {});
  }
}
