<orgos-container *ngIf="drag" class="lsc-list" cdkDropList (cdkDropListDropped)="drop($event)">
  <orgos-column-container
    *ngFor="let item of items; let i = index"
    cdkDrag
    centerColumns="true"
    class="lsc-item-draggable"
    [class.kenjo-ph-20px]="!manageColumns"
    [class.kenjo-mb-10px]="manageColumns"
    [class.lsc-item]="!greySkin && !whiteStyle && !isExtension && !manageColumns"
    [class.lsc-item-extension-list]="isExtension"
    [class.lsc-item-grey]="greySkin"
    [class.lsc-item-white]="whiteStyle"
  >
    <orgos-column size="0" [class.kenjo-mr-10px]="!whiteStyle">
      <mat-icon cdkDragHandle class="lsc-handler kenjo-font-size-18px" *ngIf="!greySkin && !manageColumns">drag_handler</mat-icon>
      <mat-icon cdkDragHandle class="lsc-handler kenjo-font-size-18px" *ngIf="greySkin || manageColumns">drag_indicator</mat-icon>
    </orgos-column>
    <orgos-column [class.kenjo-ml-10px]="!whiteStyle">
      <ng-template [ngTemplateOutlet]="listItem.templateRef" [ngTemplateOutletContext]="{ $implicit: item, index: i }"> </ng-template>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
<orgos-container *ngIf="!drag" class="lsc-list">
  <orgos-column-container
    *ngFor="let item of items; let i = index"
    centerColumns="true"
    class="lsc-item-draggable kenjo-pl-40px"
    [class.lsc-item]="!greySkin && !whiteStyle"
    [class.lsc-item-grey]="greySkin"
    [class.lsc-item-white]="whiteStyle"
  >
    <orgos-column [class.kenjo-ml-10px]="!whiteStyle && !isExtended" [class.lsc-extended-list-item]="isExtended">
      <ng-template [ngTemplateOutlet]="listItem.templateRef" [ngTemplateOutletContext]="{ $implicit: item, index: i }"> </ng-template>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
