import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class OrgChartService {
  private ORG_CHART_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/org-chart-db`;
  private COMPANY_ORG_CHART_URL: string = `${this.ORG_CHART_URL}/generateCompanyOrgChartList`;
  private ORG_CHART_NODE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/org-chart-node-db`;

  constructor(private genericService: GenericService, private injector: Injector) {}

  async getOrgChartTree(orgChartRoute: string): Promise<IOrgChartTree> {
    try {
      return (await this.injector.get(HttpClient).get(`${this.ORG_CHART_NODE_URL}/${orgChartRoute}/tree`).toPromise()) as IOrgChartTree;
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'getOrgChartTree');
    }
  }

  async getOrgCharts(): Promise<Array<IOrgChart>> {
    try {
      return await this.genericService.find(this.ORG_CHART_URL, { _id: { $ne: null } });
    } catch (error) {
      await this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'getOrgCharts');
    }
  }

  addOrgChart(orgChart: IAddOrgChartParams): Promise<IOrgChart> {
    return new Promise<IOrgChart>((resolve, reject) => {
      this.genericService
        .create(this.ORG_CHART_URL, orgChart)
        .then((addedOrgChart: IOrgChart) => {
          resolve(addedOrgChart);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'addOrgChart'));
        });
    });
  }

  renameOrgChart(orgChartId: string, newOrgChartName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.ORG_CHART_URL, orgChartId, { name: newOrgChartName })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'renameOrgChart'));
        });
    });
  }

  deleteOrgChart(orgChartId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.ORG_CHART_URL, orgChartId)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'deleteOrgChart'));
        });
    });
  }

  addFirstNode(orgChartId: string, newNode: INodeData): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const body: any = {
        orgChartId: orgChartId,
        newNode: newNode
      };

      this.injector
        .get(HttpClient)
        .post(`${this.ORG_CHART_NODE_URL}/first`, body)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'addFirstNode'));
        });
    });
  }

  addNode(orgChartId: string, operation: 'addManager' | 'addRightPeer' | 'addLeftPeer' | 'addSubordinate', refNodeId: string, newNode: INodeData): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const body: any = {
        orgChartId: orgChartId,
        refNodeId: refNodeId,
        newNode: newNode
      };

      let httpRequest;
      if (operation === 'addManager') {
        httpRequest = this.injector.get(HttpClient).post(`${this.ORG_CHART_NODE_URL}/manager`, body).toPromise();
      } else if (operation === 'addRightPeer') {
        body.place = 'right';

        httpRequest = this.injector.get(HttpClient).post(`${this.ORG_CHART_NODE_URL}/peer`, body).toPromise();
      } else if (operation === 'addLeftPeer') {
        body.place = 'left';

        httpRequest = this.injector.get(HttpClient).post(`${this.ORG_CHART_NODE_URL}/peer`, body).toPromise();
      } else {
        httpRequest = this.injector.get(HttpClient).post(`${this.ORG_CHART_NODE_URL}/subordinate`, body).toPromise();
      }

      httpRequest
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'addNode'));
        });
    });
  }

  editNode(orgChartId: string, nodeId: string, nodeData: INodeData): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const body = {
        orgChartId: orgChartId,
        nodeData: nodeData
      };

      this.injector
        .get(HttpClient)
        .put(`${this.ORG_CHART_NODE_URL}/${nodeId}`, body)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'editNode'));
        });
    });
  }

  deleteNode(orgChartId: string, nodeId: string, deleteMode: 'onlyRole' | 'roleAndSubchart'): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const body = {
        orgChartId: orgChartId,
        deleteMode: deleteMode
      };
      this.injector
        .get(HttpClient)
        .post(`${this.ORG_CHART_NODE_URL}/${nodeId}/delete`, body)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OrgChartService.name, 'deleteNode'));
        });
    });
  }
}

type NodeType = 'Employee' | 'Department' | 'Company' | 'FutureRole' | 'Area' | 'Team' | 'Office';
export interface IOrgChart {
  _id: string;
  name: string;
}

export interface IAddOrgChartParams {
  name: string;
  base: 'masterOrgChart' | 'empty' | string;
}

export interface INodeData {
  nodeType: NodeType | 'Assistant';
  employeeId?: string;
  departmentId?: string;
  officeId?: string;
  companyId?: string;
  areaId?: string;
  teamId?: string;
  futureRoleName?: string;
  color: string;
  applyColorSubchart?: boolean;
}

export interface IOrgChartTree {
  _id: string;
  parentId: string;
  nodeType: NodeType;
  employeeId?: string;
  departmentId?: string;
  officeId?: string;
  companyId?: string;
  name: string;
  color: string;
  photoUrl?: string;
  jobTitle?: string;
  children: Array<IOrgChartTree>;
}
