import { Location } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { WorkflowRunsService } from '@app/standard/services/workflow/workflow-runs.service';
import { WorkflowService } from '@app/standard/services/workflow/workflow.service';
import * as check from 'check-types';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'orgos-workflow-trigger-manual',
  templateUrl: 'workflow-trigger-manual.page.html',
  styleUrls: ['workflow-trigger-manual.page.scss'],
})
export class WorkflowTriggerManualPage implements OnInit, OnDestroy {
  @Input() listWorkflows: Array<any>;
  @Input() targetDocument: any;
  @Input() targetId: any;

  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();

  workflowHistory: Array<any>;

  pageTranslation: any = {};

  private backButtonSubscription: Subscription;

  constructor(private location: Location, private injector: Injector) {
    // When the user clicks on the back button of the Browser:
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.onBackClick(false);
      }
    }) as Subscription;
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.injector.get(GlobalBarService).setEnableDefaultBars(false, true);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('workflow-trigger-manual-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    await this.buildWorkflowHistory();
  }

  private async buildWorkflowHistory(): Promise<void> {
    let unorderedHistory = [];
    if (check.not.assigned(this.listWorkflows) || check.not.array(this.listWorkflows) || check.emptyArray(this.listWorkflows)) {
      this.workflowHistory = [];
      return;
    }

    const workflowIds = new Set<string>();
    this.listWorkflows.forEach((iWorkflow) => {
      if (!workflowIds.has(iWorkflow._id)) {
        workflowIds.add(iWorkflow._id);
      }
    });

    const workflowRuns = await this.injector
      .get(WorkflowRunsService)
      .getWorkflowsRuns({ _workflowId: { $in: Array.from(workflowIds) }, _relatedId: this.targetId });

    const workflowRunsMap = workflowRuns.reduce((total, iWorkflowRun) => {
      if (check.not.assigned(total[iWorkflowRun._workflowId])) {
        total[iWorkflowRun._workflowId] = [];
      }
      total[iWorkflowRun._workflowId].push(iWorkflowRun);
      return total;
    }, {});

    this.listWorkflows.forEach((iWorkflow) => {
      if (check.assigned(workflowRunsMap[iWorkflow._id]) && check.nonEmptyArray(workflowRunsMap[iWorkflow._id])) {
        unorderedHistory = unorderedHistory.concat(
          workflowRunsMap[iWorkflow._id]?.map((iWorkflowRun) => {
            return {
              date: iWorkflowRun._runDate,
              name: iWorkflow.name,
            };
          })
        );
      }
    });
    this.workflowHistory = unorderedHistory.sort((a, b) => {
      const valueA = a.date;
      const valueB = b.date;
      return valueA > valueB ? -1 : 1;
    });
  }

  onBackClick(changeLocationState: boolean = true): void {
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, changeLocationState);
    this.goBack.emit(this.listWorkflows);
  }

  confirmDialog(workflowDocument: any): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((globalMiscTranslation) => {
        const data = {
          titleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmRunTitle, workflowDocument),
          subtitleText: this.pageTranslation.confirmRunSubtitle,
          confirmButtonText: this.pageTranslation.confirmRunButtonLabel,
          confirmButtonColor: 'Success',
          cancelButtonText: globalMiscTranslation.goBackButtonDialog,
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm && confirm === true) {
            this.runWorkflow(workflowDocument._id);
          }
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  private runWorkflow(workflowId: string): void {
    this.injector.get(GlobalBarService).setProgressBar(true);

    const targetDocument = this.targetDocument;

    // Ensure that this workflow is still active
    this.injector
      .get(WorkflowService)
      .getById(workflowId)
      .then((workflowDoc) => {
        this.injector
          .get(WorkflowService)
          .runWorkflowManually(workflowId, targetDocument)
          .then(() => {
            const workflowDocument = this.listWorkflows.find((iWorkflow) => {
              return check.equal(iWorkflow._id, workflowId);
            });
            this.injector
              .get(MatLegacySnackBar)
              .open(`${workflowDocument.name} ${this.pageTranslation.workflowTriggeredSnackbarMessage}`, 'OK', {
                duration: 5000,
              });
            this.reloadWorkflows();
            this.injector.get(GlobalBarService).setProgressBar(false);
          })
          .catch(() => {
            // An error is already shown
            this.injector.get(GlobalBarService).setProgressBar(false);
          });
      })
      .catch(() => {
        // An error is already shown
        this.injector.get(GlobalBarService).setProgressBar(false);
      });
  }

  private reloadWorkflows(): void {
    const arrayIdsToFind = this.listWorkflows.map((iWorkflow) => {
      return iWorkflow._id;
    });
    this.injector
      .get(WorkflowService)
      .getAllWorkflows()
      .then((allWorkflows) => {
        if (check.not.assigned(allWorkflows) || check.not.array(allWorkflows) || check.emptyArray(allWorkflows)) {
          this.listWorkflows = [];
        } else {
          this.listWorkflows = allWorkflows.filter((iWorkflow) => {
            return check.contains(arrayIdsToFind, iWorkflow._id);
          });
        }
        this.buildWorkflowHistory();
      });
  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();
  }
}
