import { Component } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { DocumentService } from '@app/standard/services/document/document.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { UserService } from '@app/standard/services/user/user.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';

@Component({
  selector: 'orgos-documents-employee-docs',
  templateUrl: 'documents-employee-docs.page.html',
  styleUrls: ['documents-employee-docs.page.scss']
})
export class DocumentsEmployeeDocsPage extends GenericPage {
  translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'documents-misc' },
    { name: 'page', translationKey: 'documents-employee-docs-page' }
  ];

  activeUsers: Array<any> = [];
  inactiveUsers: Array<any> = [];
  documentPermissions: any = {};

  /**
   * fetchData(resolveFetchData: Function, rejectFetchData: Function)
   * Evaluate permissions:
   * a) read_all: retrieve all users
   * b) read_custom: call to getRelatedUsers()
   * read_own is not possible here since checkRoutes ensure read_all or read_custom permissions
   */
  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.injector
      .get(DocumentService)
      .getPermissions()
      .then((documentPermissions) => {
        this.documentPermissions = documentPermissions;
        if (this.documentPermissions.read_all === true) {
          return this.getAllUsers();
        } else if (check.nonEmptyArray(this.documentPermissions.read_custom)) {
          return this.getRelatedUsers(this.documentPermissions.read_custom);
        }
      })
      .then(() => {
        resolveFetchData();
      })
      .catch(() => {
        // An error is already shown
        this.activeUsers = [];
        this.inactiveUsers = [];
        rejectFetchData();
      });
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    const options: Array<IMenuOption> = [
      { name: this.i18n.misc.myDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/my-docs') },
      { name: this.i18n.misc.companyDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/company-docs') },
      { name: this.i18n.misc.employeeDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/employee-docs') }
    ];

    if (this.injector.get(CloudRoutesService).checkRoute('documents/digital-signature') === true) {
      options.push({ name: this.i18n.misc.digitalSignatureTab, onClick: () => this.router.navigateByUrl('/cloud/documents/digital-signature') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/templates') === true) {
      options.push({ name: this.i18n.misc.templatesTab, onClick: () => this.router.navigateByUrl('/cloud/documents/templates') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/import') === true) {
      options.push({ name: this.i18n.misc.importTab, onClick: () => this.router.navigateByUrl('/cloud/documents/import') });
    }

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 2;

    return Promise.resolve();
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view smart docs page', { category: 'Navigation', type: 'employee docs' });
    return Promise.resolve();
  }

  private compareByDisplayName(iUserA: any, iUserB: any): number {
    // Use toUpperCase() to ignore character casing
    const valA = iUserA.userPersonal.displayName.toUpperCase();
    const valB = iUserB.userPersonal.displayName.toUpperCase();

    let comparison = 0;
    if (valA > valB) {
      comparison = 1;
    } else if (valA < valB) {
      comparison = -1;
    }
    return comparison;
  }

  private getAllUsers(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(UserService)
        .getAllUsersFull(false)
        .then((allUsersFull) => {
          this.activeUsers = allUsersFull
            .filter((iUser) => {
              return iUser.userAccount.isActive === true;
            })
            .sort(this.compareByDisplayName);
          this.inactiveUsers = allUsersFull
            .filter((iUser) => {
              return iUser.userAccount.isActive !== true;
            })
            .sort(this.compareByDisplayName);
          resolve();
        })
        .catch(() => {
          // An error is already shown
          this.activeUsers = [];
          this.inactiveUsers = [];
          reject();
        });
    });
  }

  /**
   * getRelatedUsers(customPermissionConditions: Array<any>)
   * 1. Call to getQueryFromBlockAndConditions() from utils to return an array of user ids allowed to see documents
   * 2. Get all active users
   * 3. Do filter over active users using the array of ids retrieved in the step 1
   */
  private getRelatedUsers(customPermissionConditions: Array<any>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      const userRequest = {
        userInfo: {
          _id: loggedUser._id
        }
      };
      let userIds = [];
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          const relatedUserQueries = customPermissionConditions.map((block) => {
            return customPermissions.getQueryFromBlockAndConditions(userRequest, null, 'document', block.blockAndConditions, null, null, allUserWork);
          });
          return Promise.all(relatedUserQueries);
        })
        .then((blockAndConditions) => {
          blockAndConditions.forEach((block) => {
            if (check.assigned(block['relatedTo.idRelatedTo']) && check.nonEmptyArray(block['relatedTo.idRelatedTo'].$in)) {
              userIds = userIds.concat(block['relatedTo.idRelatedTo'].$in);
            }
          });
          return this.injector.get(UserService).getAllUsersFull(true);
        })
        .then((visibleUsers) => {
          this.activeUsers = visibleUsers
            .filter((iUser) => {
              return userIds.includes(iUser.userAccount._id) === true;
            })
            .sort(this.compareByDisplayName);
          resolve();
        })
        .catch(() => {
          // An error is already shown
          this.activeUsers = [];
          reject();
        });
    });
  }
}
