import { CdkDrag, CdkDragEnd } from '@angular/cdk/drag-drop';
import { CdkOverlayOrigin, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IProcessedPayslipMetadata, IUsersMap } from '@app/common-components/layout-import-payslips/services/payslips-import.service';
import { SearchFunction } from '@app/standard/components/search/search.component';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-payslip-card',
  templateUrl: 'payslip-card.component.html',
  styleUrls: ['payslip-card.component.scss']
})
export class PayslipCardComponent implements AfterViewInit {
  @Input() i18n: any = {};
  @Input() payslip: IProcessedPayslipMetadata;
  @Input() digitalSignatureActive: boolean = false;
  @Input() availableCredits: number;

  matchingUsers: Array<any> = [];
  noMatchingUsers: Array<any> = [];

  allUsers: Array<any> = [];
  private _usersMap: IUsersMap;
  @Input() get usersMap(): IUsersMap {
    return this._usersMap;
  }
  set usersMap(value) {
    this._usersMap = value;

    if (check.assigned(this._usersMap)) {
      this.allUsers = [...Object.values(this._usersMap)];
      const tempNotMatching = { ...this._usersMap };
      if (check.assigned(this.payslip.tentativeUserIds)) {
        this.payslip.tentativeUserIds.forEach((iUserId) => {
          this.matchingUsers.push({ ...this._usersMap[iUserId] });
          delete tempNotMatching[iUserId];
        });
      }

      this.noMatchingUsers = Object.values(tempNotMatching);
    }
  }

  @Output() payslipChange: EventEmitter<IProcessedPayslipMetadata> = new EventEmitter<IProcessedPayslipMetadata>();
  searchUserOverlay: OverlayRef;
  searchResults: Array<any> = [];
  @ViewChild(CdkPortal) searchPortal: Portal<any>;

  readonly PREVIEW_HEIGHT: number = 400;

  dragPosition = { x: 0, y: 0 };

  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;
  @ViewChild(CdkOverlayOrigin) usersPicklistOrigin: CdkOverlayOrigin;

  constructor(private overlay: Overlay) {}

  ngAfterViewInit(): void {
    this.cdkDrag.ended.subscribe((event: CdkDragEnd) => {
      const newPositionY = this.dragPosition.y + event.distance.y;
      const pdfSize = this.cdkDrag.element.nativeElement.offsetHeight;

      if (newPositionY > 0) {
        this.dragPosition = { x: 0, y: 0 };
      } else if (newPositionY < -(pdfSize - this.PREVIEW_HEIGHT)) {
        this.dragPosition = { x: 0, y: -(pdfSize - this.PREVIEW_HEIGHT) };
      } else {
        this.dragPosition = { x: 0, y: newPositionY };
      }
    });
  }

  deletePayslip(): void {
    this.payslip.mustBeDeleted = true;
    this.payslipChange.emit(this.payslip);
  }

  recoverPayslip(): void {
    this.payslip.mustBeDeleted = false;
    this.payslipChange.emit(this.payslip);
  }

  requestSignatureChange(): void {
    if ((this.availableCredits <= 0 && this.payslip.signatureRequested === false) || check.not.assigned(this.payslip.userId)) {
      return;
    }
    this.payslip.signatureRequested = !this.payslip.signatureRequested;
    this.payslipChange.emit(this.payslip);
  }

  searchAssignee(assigneeElement: ElementRef): void {
    const searchUserOverlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop'
    };
    searchUserOverlayConfig.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(assigneeElement)
      .withPositions([
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
        { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' }
      ])
      .withPush(false);

    this.searchUserOverlay = this.overlay.create(searchUserOverlayConfig);
    this.searchUserOverlay.attach(this.searchPortal);
    this.searchUserOverlay.backdropClick().subscribe(() => {
      this.searchUserOverlay.dispose();
    });
  }

  selectAssignee(): void {
    this.payslipChange.emit(this.payslip);
  }

  public searchAssigneeFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      return Promise.resolve(this.allUsers);
    }

    const results = this.allUsers.filter((iUserPersonal: any) => {
      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return regExp.test(iUserPersonal.displayName);
    });

    return Promise.resolve(results);
  };
}
