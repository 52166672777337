import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';
import isEmail from 'validator/es/lib/isEmail';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { IChargebeeStatus } from '@app/private/services/private-chargebee.service';
import { IInvitationEmailStatus } from '@app/models/user-account.model';

@Injectable()
export class UserAccountService implements IGenericService {
  private USER_ACCOUNT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-account-db/user-accounts`;
  private USER_ACCOUNT_PERMISSIONS_KEY: string = 'user-account';
  private USER_ACCOUNT_INTERNATIONALIZATION: string = 'user-account-collection';

  constructor(private injector: Injector) {}

  create(): Promise<IUserAccountModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserAccountService.name, 'create');
    error.message = 'UserAccount should not be created';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserAccountModel> {
    return new Promise<IUserAccountModel>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .getById(this.USER_ACCOUNT_URL, id)
        .then((userAccount: IUserAccountModel) => {
          resolve(userAccount);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .updateById(this.USER_ACCOUNT_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'updateById'));
        });
    });
  }

  deleteById(): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserAccountService.name, 'deleteById');
    error.message = 'UserAccount should not be deleted';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.injector.get(GenericService).getPermissions(this.USER_ACCOUNT_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.injector.get(GenericService).getFieldsTranslations(this.USER_ACCOUNT_INTERNATIONALIZATION);
  }

  getAllUserAccount(activeUsersOnly: boolean, showArchived: boolean = false): Promise<Array<IUserAccountModel>> {
    return new Promise<Array<IUserAccountModel>>((resolve, reject) => {
      const findBody = {
        _id: { $ne: null },
      };

      if (showArchived === true) {
        findBody['isArchived'] = true;
      }

      if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(activeUsersOnly, true)) {
        findBody['isActive'] = true;
      }

      this.injector
        .get(GenericService)
        .find(this.USER_ACCOUNT_URL, findBody)
        .then((allUserAccount: Array<IUserAccountModel>) => {
          resolve(allUserAccount);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getAllUserAccount'));
        });
    });
  }

  find(findBody, showArchived: boolean = false): Promise<Array<IUserAccountModel>> {
    return new Promise<Array<IUserAccountModel>>((resolve, reject) => {
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }
      this.injector
        .get(GenericService)
        .find(this.USER_ACCOUNT_URL, findBody)
        .then((allUserAccount: Array<IUserAccountModel>) => {
          resolve(allUserAccount);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'find'));
        });
    });
  }

  getMyUserAccount(): Promise<IUserAccountModel> {
    return new Promise<IUserAccountModel>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserAccountService.name, 'getMyUserAccount');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .get<IUserAccountModel>(`${this.USER_ACCOUNT_URL}/me`, httpOptions)
        .toPromise()
        .then((userAccountResponse) => {
          resolve(userAccountResponse);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getMyUserAccount'));
        });
    });
  }

  countActiveUsers(ignoreDummy = false): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserAccountService.name, 'countActiveUsers');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .get(`${this.USER_ACCOUNT_URL}/count-active${ignoreDummy === true ? '?ignoreDummy=true' : ''}`, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'countActiveUsers'));
        });
    });
  }

  countEffectiveUsers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserAccountService.name, 'countEffectiveUsers');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .get(`${this.USER_ACCOUNT_URL}/count-effective`, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'countEffectiveUsers'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.injector.get(GenericService).getModel(this.USER_ACCOUNT_URL);
  }

  updateMyLanguage(language: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserAccountService.name, 'updateMyLanguage');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      const body = {
        language: language,
      };

      this.injector
        .get(HttpClient)
        .put(`${this.USER_ACCOUNT_URL}/update-my-language`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'updateMyLanguage'));
        });
    });
  }

  updateMyLocale(locale: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserAccountService.name, 'updateMyLocale');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      const body = {
        locale: locale,
      };

      this.injector
        .get(HttpClient)
        .put(`${this.USER_ACCOUNT_URL}/update-my-locale`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'updateMyLocale'));
        });
    });
  }

  getUserAccountWithAccessibleTimeOffs(activeUsersOnly: boolean): Promise<Array<IUserAccountModel>> {
    return new Promise<Array<IUserAccountModel>>((resolve, reject) => {
      const findBody = {
        _id: { $ne: null },
      };

      if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(activeUsersOnly, true)) {
        findBody['isActive'] = true;
      }

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .post(`${this.USER_ACCOUNT_URL}/accessible-time-offs`, findBody, httpOptions)
        .toPromise()
        .then((userAccountsAccessible: Array<IUserAccountModel>) => {
          resolve(userAccountsAccessible);
        })
        .catch((error) => {
          reject(
            this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getUserAccountWithAccessibleTimeOffs')
          );
        });
    });
  }

  getUserAccountWithManageableTimeOffs(activeUsersOnly: boolean): Promise<Array<IUserAccountModel>> {
    return new Promise<Array<IUserAccountModel>>((resolve, reject) => {
      const findBody = {
        _id: { $ne: null },
      };

      if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(activeUsersOnly, true)) {
        findBody['isActive'] = true;
      }

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .post(`${this.USER_ACCOUNT_URL}/manageable-time-offs`, findBody, httpOptions)
        .toPromise()
        .then((userAccountsManageable: Array<IUserAccountModel>) => {
          resolve(userAccountsManageable);
        })
        .catch((error) => {
          reject(
            this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getUserAccountWithManageableTimeOffs')
          );
        });
    });
  }

  getUsersAttendanceManaged(activeUsersOnly: boolean): Promise<Array<IUserAccountModel>> {
    return new Promise<Array<IUserAccountModel>>((resolve, reject) => {
      const findBody = {
        _id: { $ne: null },
      };

      if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(activeUsersOnly, true)) {
        findBody['isActive'] = true;
      }

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .post(`${this.USER_ACCOUNT_URL}/manageable-attendance-summary`, findBody, httpOptions)
        .toPromise()
        .then((userAccountsAccessible: Array<IUserAccountModel>) => {
          resolve(userAccountsAccessible);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getUsersAttendanceManaged'));
        });
    });
  }

  attendanceIsAccessible(userId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (check.not.assigned(userId) || check.emptyString(userId)) {
        resolve(false);
        return;
      }

      const findBody = {
        _id: userId,
      };

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .post(`${this.USER_ACCOUNT_URL}/accessible-attendance-summary`, findBody, httpOptions)
        .toPromise()
        .then((userAccountsAccessible: Array<IUserAccountModel>) => {
          resolve(check.assigned(userAccountsAccessible) && check.nonEmptyArray(userAccountsAccessible));
        })
        .catch((error) => {
          reject(
            this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getUserAccountWithAccessibleTimeOffs')
          );
        });
    });
  }

  attendanceIsManageable(userId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (check.not.assigned(userId) || check.emptyString(userId)) {
        resolve(false);
        return;
      }

      const findBody = {
        _id: userId,
      };

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .post(`${this.USER_ACCOUNT_URL}/manageable-attendance-summary`, findBody, httpOptions)
        .toPromise()
        .then((userAccountsAccessible: Array<IUserAccountModel>) => {
          resolve(check.assigned(userAccountsAccessible) && check.nonEmptyArray(userAccountsAccessible));
        })
        .catch((error) => {
          reject(
            this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getUserAccountWithAccessibleTimeOffs')
          );
        });
    });
  }

  getUsersWithEditableAttendance(): Promise<Array<IUserAccountModel>> {
    return new Promise<Array<IUserAccountModel>>((resolve, reject) => {
      const findBody = {
        _id: { $ne: null },
      };

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .post(`${this.USER_ACCOUNT_URL}/editable-attendance-summary`, findBody, httpOptions)
        .toPromise()
        .then((userAccountsAccessible: Array<IUserAccountModel>) => {
          resolve(userAccountsAccessible);
        })
        .catch((error) => {
          reject(
            this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getUserAccountWithEditableAttendance')
          );
        });
    });
  }

  getUsersWithApprovableAttendance(): Promise<Array<IUserAccountModel>> {
    return new Promise<Array<IUserAccountModel>>((resolve, reject) => {
      const findBody = {
        _id: { $ne: null },
      };

      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      this.injector
        .get(HttpClient)
        .post(`${this.USER_ACCOUNT_URL}/approvable-attendance-summary`, findBody, httpOptions)
        .toPromise()
        .then((userAccountsAccessible: Array<IUserAccountModel>) => {
          resolve(userAccountsAccessible);
        })
        .catch((error) => {
          reject(
            this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'getUserAccountWithEditableAttendance')
          );
        });
    });
  }

  containsDummyData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };
      this.injector
        .get(HttpClient)
        .get(`${this.USER_ACCOUNT_URL}/contains-dummy`, httpOptions)
        .toPromise()
        .then((responseData: IContainsDummyModel) => {
          if (check.not.assigned(responseData) || check.emptyObject(responseData) || check.not.assigned(responseData.containsDummy)) {
            resolve(false);
            return;
          }
          resolve(responseData.containsDummy);
          return;
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'containsDummyData'));
        });
    });
  }

  async canUseNewPassword(password: string): Promise<boolean> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader())
        .set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };

      const canUse = await this.injector
        .get(HttpClient)
        .post<boolean>(`${this.USER_ACCOUNT_URL}/can-use-new-password`, { password }, httpOptions)
        .toPromise();

      return canUse;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, UserAccountService.name, 'canUseNewPassword');
    }
  }

  getUserAccountStatus(userAccount: IUserAccountModel): pickLists.USER_STATUS_LIST {
    if (userAccount.isActive) {
      return pickLists.USER_STATUS_ACTIVE;
    }

    if (userAccount.inactiveReason) {
      return pickLists.USER_STATUS_DEACTIVATED;
    }

    return pickLists.USER_STATUS_NOTACTIVATED;
  }

  getUserInvitationStatus(userAccount: IUserAccountModel): UserInvitationEmailStatus {
    if (userAccount.isActive) {
      return INVITATION_COMPLETED;
    }

    if (userAccount.inactiveReason) {
      return NOT_INVITED;
    }

    if (!userAccount?._invitationEmailStatus) {
      return NOT_INVITED;
    }

    if (userAccount.isActive == false &&
      userAccount._invitationEmailStatus.invitationExpiresOn &&
      new Date(userAccount._invitationEmailStatus.invitationExpiresOn) < new Date()) {
        return INVITATION_EXPIRED;
    }

    const emailEvent = userAccount._invitationEmailStatus.t_event;
    if (!emailEvent) {
      return EMAIL_PROCESSED; // if no information is available, we assume the email was processed
    }

    if (emailEvent === 'processed') {
      return EMAIL_PROCESSED;
    }
    if (emailEvent === 'delivered') {
      return EMAIL_DELIVERED;
    }
    if (emailEvent === 'open' || emailEvent === 'click') {
      return EMAIL_READ;
    }
    if (emailEvent === 'deferred' ||
      emailEvent === 'bounce' ||
      emailEvent === 'dropped' ||
      emailEvent === 'spamreport' ||
      emailEvent === 'unsubscribe' ||
      emailEvent === 'group_unsubscribe'
    ) {
      return EMAIL_FAILED;
    }
    return;
  }

  /************************************************+
   *              MULTI-ORG METHODS
   ************************************************/

  async isMultiOrgUser(): Promise<boolean> {
    const myUserAccount = await this.getMyUserAccount();
    const isMultiOrgUser =
      check.assigned(myUserAccount) &&
      check.object(myUserAccount) &&
      check.nonEmptyObject(myUserAccount) &&
      check.assigned(myUserAccount._primaryEmail) &&
      isEmail(myUserAccount._primaryEmail);

    return isMultiOrgUser;
  }

  async isEmployeePrimaryOrg(employeeId: string): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(`${this.USER_ACCOUNT_URL}`, ErrorCodes.UNAUTHORIZED, UserAccountService.name, 'isEmployeePrimaryOrg');
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      return await this.injector
        .get(HttpClient)
        .get<Object>(`${this.USER_ACCOUNT_URL}/validate-primary-org/${employeeId}`, httpOptions)
        .toPromise();
    } catch (error) {
      throw new OrgosError(`${this.USER_ACCOUNT_URL}`, ErrorCodes.UNAUTHORIZED, UserAccountService.name, 'isEmployeePrimaryOrg');
    }
  }
}

export interface IUserAccountModel {
  _id?: string;
  email?: string;
  externalId?: string;
  _primaryEmail?: string;
  profileKey?: string;
  password?: string;
  language?: string;
  locale?: string;
  dashboardDesigner?: boolean;
  accessToModules?: Array<string>;
  isActive?: boolean;
  agencyId?: string;
  _activationDate?: Date;
  _onboarding?: 'Employee' | 'Finished';
  profile: {
    name: string;
    _profileKey: string;
    _isStandard: boolean;
    _isAdmin: boolean;
  };
  chargebeeStatus: IChargebeeStatus;
  inactiveReason?: string;
  attendancePolicy?: string;
  _invitationEmailStatus?: IInvitationEmailStatus;
}

export interface IContainsDummyModel {
  containsDummy: boolean;
}

export const EMAIL_PROCESSED = 'email_processed';
export const EMAIL_DELIVERED = 'email_delivered';
export const EMAIL_READ = 'email_read';
export const EMAIL_FAILED = 'email_failed';
export const INVITATION_EXPIRED = 'invitation_expired';
export const INVITATION_COMPLETED = 'invitation_completed';
export const NOT_INVITED = 'not_invited';
export type UserInvitationEmailStatus =
  | typeof EMAIL_PROCESSED
  | typeof EMAIL_DELIVERED
  | typeof EMAIL_READ
  | typeof EMAIL_FAILED
  | typeof INVITATION_EXPIRED
  | typeof INVITATION_COMPLETED
  | typeof NOT_INVITED;