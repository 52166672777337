import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'kenjo-edit-comment-dialog',
  templateUrl: 'edit-comment-dialog.component.html',
  styleUrls: ['edit-comment-dialog.component.scss'],
})
export class EditCommentDialogComponent {
  titleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  comment: string;
  commentLabel: string;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditCommentDialogComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {
    this.titleText = this.data.titleText;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.comment = this.data.comment;
    this.commentLabel = this.data.commentLabel;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    this.dialogRef.close(this.comment);
  }
}
