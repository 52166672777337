import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerformanceReviewListController {
  private PERFORMANCE_REVIEW_LIST_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/performance-review/list`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) {}

  getFilters(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewListController.name, 'getFilters');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.PERFORMANCE_REVIEW_LIST_URL}/filters`, httpOptions)
        .toPromise()
        .then((filters) => {
          resolve(filters);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PerformanceReviewListController.name, 'getFilters'));
        });
    });
  }

  getReviewsOnInit(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewListController.name, 'getReviews');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.PERFORMANCE_REVIEW_LIST_URL}/reviews-on-init`, httpOptions)
        .toPromise()
        .then((filters) => {
          resolve(filters);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PerformanceReviewListController.name, 'getReviews'));
        });
    });
  }

  getReviews(reviewOptions: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewListController.name, 'getReviews');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.PERFORMANCE_REVIEW_LIST_URL}/reviews`, reviewOptions, httpOptions)
        .toPromise()
        .then((filters) => {
          resolve(filters);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PerformanceReviewListController.name, 'getReviews'));
        });
    });
  }

  getPeopleReviewList(filterOptions: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, PerformanceReviewListController.name, 'getPeopleReviewList');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.PERFORMANCE_REVIEW_LIST_URL}/people-reviews`, filterOptions, httpOptions)
        .toPromise()
        .then((filters) => {
          resolve(filters);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PerformanceReviewListController.name, 'getPeopleReviewList'));
        });
    });
  }
}
