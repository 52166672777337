import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import * as check from 'check-types';

import { GenericCacheModel } from '../../../../core/generic-cache-model';
import { ISelectOption } from '../../../../core/select-option';
import { InputValidation } from '../../../../core/validation/input-validation';
import { CompanyService } from '../../../../services/company/company.service';
import { AuthenticationService } from '../../../../services/core/authentication.service';
import { InternationalizationService } from '../../../../services/core/internationalization.service';
import { UserVariablePayService } from '../../../../services/user/user-variable-pay.service';
import { UserWorkService } from '../../../../services/user/user-work.service';
import { VariablePayTypeService } from '../../../../services/variable-pay-type/variable-pay-type.service';

@Component({
  selector: 'orgos-add-variable-pay-dialog',
  templateUrl: 'add-variable-pay.dialog.html',
  styleUrls: ['add-variable-pay.dialog.scss'],
})
export class AddVariablePayDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  variablePay: GenericCacheModel;
  minDate: Date;
  variablePayTypes: Array<ISelectOption> = [];

  effectiveDateValidation: InputValidation;
  endDateValidation: InputValidation;
  typeValidation: InputValidation;
  amountValidation: InputValidation;
  currencyValidation: InputValidation;
  frequencyValidation: InputValidation;

  wizardStep: number = 1;
  STEP_TYPE_SELECTION: number = 1;
  STEP_DETAILS: number = 2;
  WIZARD_FINAL_STEP: number = this.STEP_DETAILS;
  isUpdateAction: boolean = false;
  canEditPast: boolean;
  companyOptions: Array<any> = [];

  defaultCurrency: string;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddVariablePayDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any
  ) {}

  ngOnInit(): void {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.canEditPast = this.dialogInfo.canEditPast;

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-add-variable-pay-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((dialogTranslation) => {
        this.miscTranslation = dialogTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.injector
      .get(VariablePayTypeService)
      .getAllTypes()
      .then((types) => {
        if (check.assigned(types) && check.nonEmptyArray(types)) {
          this.variablePayTypes = types.map((iType) => {
            const iOption: ISelectOption = {
              name: iType.name,
              value: iType._id,
            };
            return iOption;
          });
        }
      })
      .catch((e) => {
        //
      });

    let myCompanyId;
    this.injector
      .get(UserWorkService)
      .getById(loggedUser._id)
      .then((userWork) => {
        myCompanyId = userWork.companyId;
        return this.injector.get(CompanyService).getCompanies();
      })
      .then((allCompanies) => {
        this.companyOptions = allCompanies.map((iCompany: any) => {
          const companyOption = {
            name: iCompany.name,
            value: iCompany._id,
          };
          return companyOption;
        });
        const myCompany = allCompanies.find((iCompany) => {
          return iCompany._id === myCompanyId;
        });
        if (check.assigned(myCompany) && check.assigned(myCompany.defaultCurrency) && check.nonEmptyString(myCompany.defaultCurrency)) {
          this.defaultCurrency = myCompany.defaultCurrency;
        }
        let variablePayObject = {
          effectiveDate: undefined,
          type: undefined,
          amount: undefined,
          currency: this.defaultCurrency,
          _companyId: myCompanyId,
        };
        if (check.assigned(this.dialogInfo.record) && check.nonEmptyObject(this.dialogInfo.record)) {
          variablePayObject = this.dialogInfo.record;
        }

        this.variablePay = new GenericCacheModel(this.injector, variablePayObject, UserVariablePayService, this.dialogInfo.ownerId);
        if (check.not.assigned(this.variablePay.data.everyMonths)) {
          this.variablePay.data.everyMonths = false;
        }
      })
      .catch(() => {
        //
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public leftButtonAction(): void {
    if (this.wizardStep === 1) {
      this.dialogRef.close();
    }
    if (this.wizardStep >= 2) {
      this.wizardStep--;
    }
  }

  public rightButtonAction(): void {
    if (this.isValidFormData()) {
      if (this.isFinalStep()) {
        this.addVariablePay();
      } else {
        if (this.variablePay.data.everyMonths === false) {
          this.variablePay.data.endDate = null;
          this.variablePay.data.frequency = null;
        }
        this.wizardStep++;
      }
    }
  }

  public isValidFormData(): boolean {
    if (this.wizardStep === this.STEP_TYPE_SELECTION) {
      return check.assigned(this.variablePay.data.everyMonths);
    }
    if (this.variablePay.data.everyMonths === false) {
      return !(
        check.not.assigned(this.effectiveDateValidation) ||
        this.effectiveDateValidation.hasErrors() ||
        check.not.assigned(this.typeValidation) ||
        this.typeValidation.hasErrors() ||
        check.not.assigned(this.amountValidation) ||
        this.amountValidation.hasErrors() ||
        check.not.assigned(this.currencyValidation) ||
        this.currencyValidation.hasErrors()
      );
    } else {
      return !(
        check.not.assigned(this.effectiveDateValidation) ||
        this.effectiveDateValidation.hasErrors() ||
        check.not.assigned(this.typeValidation) ||
        this.typeValidation.hasErrors() ||
        check.not.assigned(this.amountValidation) ||
        this.amountValidation.hasErrors() ||
        check.not.assigned(this.currencyValidation) ||
        this.currencyValidation.hasErrors() ||
        (check.assigned(this.endDateValidation) && this.endDateValidation.hasErrors()) ||
        check.not.assigned(this.frequencyValidation) ||
        this.frequencyValidation.hasErrors()
      );
    }
  }

  // PRIVATE METHODS
  private isFinalStep(): boolean {
    return this.wizardStep === this.WIZARD_FINAL_STEP;
  }

  private addVariablePay(): void {
    if (!this.isValidFormData()) {
      return;
    }

    this.dialogRef.close(this.variablePay.data);
  }
}
