import { getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';

@Component({
  selector: 'kenjo-plan-card',
  templateUrl: 'plan-card.component.html',
  styleUrls: ['plan-card.component.scss']
})
export class PlanCardComponent {
  @Input() plan: any;

  @Input() set price(price: any) {
    this._monthlyPrice = `${(price.monthly.amount / 100).toString().replace('.', ',')}${getCurrencySymbol(price.monthly.currency, 'narrow')}`;
    this._yearlyPrice = `${(price.yearly.amount / 100).toString().replace('.', ',')}${getCurrencySymbol(price.yearly.currency, 'narrow')}`;
  }

  private _monthlyPrice: string;
  get monthlyPrice() {
    return this._monthlyPrice;
  }

  private _yearlyPrice: string;
  get yearlyPrice() {
    return this._yearlyPrice;
  }

  @Input() translations: any;
  @Input() alternative: boolean = false;
  @Input() showPrice: boolean = true;
  @Input() requestDemo: boolean = false;
  @Input() showYearly: boolean = true;
  @Input() isCurrentPlan: boolean = false;
  @Input() contactUs: boolean = false;
  @Input() contactLink: string;

  @Output() openCheckout: EventEmitter<boolean> = new EventEmitter(false);
  @Output() calculatePrice: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private injector: Injector) {}

  onOpenCheckout() {
    this.openCheckout.emit(true);
  }

  onOpenLink() {
    if (this.requestDemo) {
      this.injector.get(PrivateAmplitudeService).logEvent('demo request for connect', { category: 'Billing' });
    }
    window.open(this.contactLink);
  }

  onCalculatePrice() {
    this.calculatePrice.emit(true);
  }
}
