<div *ngIf="!uploadingFiles" #payslipsDropZonePayroll id="payslipsDropZonePayroll" class="pupc-drop-zone" (click)="onUpload()">
  <div class="pupc-drop-zone-content kenjo-p-30px">
    <div class="kenjo-font-color-light-text-757575">{{ i18n.dragAndDropText }}</div>
    <div class="pupc-drop-zone-icon kenjo-mt-15px">
      <kenjo-icon [size]="30" class="kenjo-mt-20px">attach_money</kenjo-icon>
      <div class="kenjo-mt-10px kenjo-text-transform-uppercase kenjo-font-size-12px">{{ i18n.payslips }}</div>
    </div>
  </div>
</div>

<div *ngIf="uploadingFiles">
  <div class="kenjo-text-align-center kenjo-p-60px kenjo-mt-20px">
    <orgos-loading-spinner></orgos-loading-spinner>
    <div class="kenjo-mt-20px">{{ i18n.uploadingFiles | i18nData: { progress: progress } }}</div>
  </div>
</div>
