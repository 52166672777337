import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { IUserHomeModel } from '../../../models/user-home.model';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class UserHomeService implements IGenericService {
  private USER_HOME_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-home-db`;
  private USER_HOME_PERMISSIONS_KEY: string = 'user-home';
  private USER_HOME_INTERNATIONALIZATION: string = 'user-home-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IUserHomeModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserHomeService.name, 'create');
    error.message = 'UserHome should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserHomeModel> {
    return new Promise<IUserHomeModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_HOME_URL, id)
        .then((userHome: IUserHomeModel) => {
          resolve(userHome);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserHomeService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_HOME_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserHomeService.name, 'updateById'));
        });
    });
  }

  find(findBody: any): Promise<Array<IUserHomeModel>> {
    return new Promise<Array<IUserHomeModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_HOME_URL, findBody)
        .then((userHome: Array<IUserHomeModel>) => {
          resolve(userHome);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserHomeService.name, 'find'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserHomeService.name, 'deleteById');
    error.message = 'UserHome should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_HOME_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_HOME_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_HOME_URL);
  }
}
