import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IAttendanceCategory } from '@app/cloud-features/settings-attendance/services/attendance-category.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  templateUrl: 'set-category.dialog.html',
  styleUrls: ['set-category.dialog.scss'],
})
export class SetCategoryDialog implements OnInit {
  pageTranslation: any = {};
  miscTranslations: any = {};
  chosenCategoryId: string;
  chosenCategory: IAttendanceCategory;
  listCategories: Array<IAttendanceCategory>;

  constructor(
    public dialogRef: MatLegacyDialogRef<SetCategoryDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: { listCategories: Array<IAttendanceCategory>; chosenCategoryId: string },
    private injector: Injector
  ) {
    this.listCategories = this.data.listCategories.map((iCategory) => {
      iCategory.value = iCategory._id;
      return iCategory;
    });
    this.chosenCategoryId = this.data.chosenCategoryId;
    this.chosenCategory = this.listCategories.find((category: IAttendanceCategory) => {
      return category._id === this.chosenCategoryId;
    });
  }

  async ngOnInit(): Promise<void> {
    await this.fetchTranslations();
    this.initChosenCategory();
  }

  private initChosenCategory(): void {
    if (!this.chosenCategoryId) {
      return;
    }
    this.listCategories.find((category: IAttendanceCategory) => {
      return category._id === this.chosenCategoryId;
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveData(): void {
    if (!this.chosenCategory) {
      this.dialogRef.close({ categoryId: null, subCategoryId: null });
    } else if (this.chosenCategory.parentCategoryId) {
      this.dialogRef.close({ categoryId: this.chosenCategory?.parentCategoryId, subCategoryId: this.chosenCategory?._id });
    } else {
      this.dialogRef.close({ categoryId: this.chosenCategory?._id, subCategoryId: null });
    }
  }

  removeCategory() {
    this.chosenCategory = {};
  }

  onSelectOption($event: string) {
    this.chosenCategory = this.listCategories.find(({ _id }) => _id === $event);
  }

  private async fetchTranslations(): Promise<void> {
    this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('people-detail-attendance-page');
    this.miscTranslations = await this.injector.get(InternationalizationService).getMiscTranslation();
  }
}
