import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTooltip } from '@angular/material/legacy-tooltip';
import { IInAppInviteTeammate } from '@app/common-dialogs/in-app-invite/in-app-invite-dialog.model';
import { InAppInviteDialogService } from '@app/common-dialogs/in-app-invite/in-app-invite-dialog.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { freeDomains } from '@app/standard/domains/free-domains.list';
import { ClipboardService } from '@app/standard/services/core/clipboard.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { isEmailBlacklisted } from '@carlos-orgos/orgos-utils/email-utils/index';
import * as check from 'check-types';

@Component({
  selector: 'in-app-invite-dialog',
  templateUrl: './in-app-invite.dialog.html',
  styleUrls: ['./in-app-invite.dialog.scss'],
})
export class InAppInviteDialog implements OnInit, OnDestroy {
  MAX_TEAMMATES: number = 5;

  translations: any = {};
  service: InAppInviteDialogService;

  @ViewChild('copyTooltip') tooltip: MatLegacyTooltip;
  duplicatedEmails: Array<boolean> = [];
  emailValidation: Array<InputValidation> = [];
  // boolean array hack because can't use required=true because it adds style changes and can't manually edit InputValidation is frozen by input component, so empty values are treated as valid
  isNameNonEmpty: Array<boolean> = [];
  isNotFullName: Array<boolean> = [];
  isEmailNonEmpty: Array<boolean> = [];
  isNotWorkEmail: Array<boolean> = [];
  cannotProceed: boolean;

  displayForm: boolean = true;

  constructor(private dialogRef: MatLegacyDialogRef<InAppInviteDialog>, private injector: Injector, private cdRef: ChangeDetectorRef) {
    this.service = this.injector.get(InAppInviteDialogService);
    dialogRef.disableClose = true;
    dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });
  }

  ngOnInit(): void {
    this.loadTranslations();
    this.initializeTeammates();
    this.validateTeammates();
    this.service.getInviteLink();
  }

  ngOnDestroy(): void {
    this.service.clearTeammates();
  }

  private async loadTranslations(): Promise<void> {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('in-app-invite-dialog');
    } catch {
      this.translations = {};
    }
  }

  private initializeTeammates(): void {
    if (check.emptyArray(this.service.teammates)) {
      this.service.addTeammate();
    }
  }

  closeDialog(): void {
    if (this.hasTeammateData() === false || this.displayForm === false) {
      this.service.clearTeammates();
      this.dialogRef.close();
      return;
    }
    const data = {
      titleText: this.translations.confirmDialogTitle,
      subtitleText: this.translations.confirmDialogDescription,
      confirmButtonText: this.translations.confirmDialogConfirm,
      cancelButtonText: this.translations.confirmDialogCancel,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm) => {
      if (confirm) {
        this.service.clearTeammates();
        this.dialogRef.close();
      }
    });
  }

  hasTeammateData(): boolean {
    return (
      this.isNameNonEmpty.some((value) => value === true) ||
      this.isEmailNonEmpty.some((value) => value === true) ||
      this.emailValidation.some((emailValidation: InputValidation) => emailValidation.hasErrors()) === true
    );
  }

  copyLink(): void {
    this.tooltip.disabled = false;
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.disabled = true;
    }, 3000);
    this.injector.get(ClipboardService).copy(this.service.inviteLink);
    this.injector.get(PrivateAmplitudeService).logEvent('Admin invite link copied', { category: 'PLG', subcategory1: 'Invitations' });
  }

  addTeammate(): void {
    this.service.addTeammate();
    this.validateTeammates();
  }

  removeTeammate(index: number): void {
    if (this.service.teammates.length <= 1) {
      return;
    }
    this.service.removeTeammate(index);
    this.emailValidation.splice(index, 1);
    this.isEmailNonEmpty.splice(index, 1);
    this.isNameNonEmpty.splice(index, 1);
    this.validateTeammates();
  }

  changeTeammateName(value: string, item: IInAppInviteTeammate, index: number): void {
    item.fullName = value;
    this.isNameNonEmpty[index] = check.nonEmptyString(item.fullName);
    this.validateTeammates();
  }

  changeTeammateEmail(value: string, item: IInAppInviteTeammate, index: number): void {
    item.workEmail = value;
    this.isEmailNonEmpty[index] = check.nonEmptyString(item.workEmail);
    this.validateTeammates();
  }

  async invite(): Promise<void> {
    // no need to await. user does not see errors if any
    this.service.sendInvitations();
    this.service.clearTeammates();
    this.service.addTeammate();
    this.cdRef.detectChanges();
    this.displayForm = false;
    this.isNameNonEmpty = [];
    this.isEmailNonEmpty = [];
  }

  validateTeammates(): void {
    this.duplicatedEmails = [];

    // reject if email errors
    if (this.emailValidation.some((emailValidation: InputValidation) => emailValidation.hasErrors())) {
      this.cannotProceed = true;
      return;
    }

    // reject if full names dont have last names
    this.isNotFullName = this.service.teammates.map((teammate: IInAppInviteTeammate) => {
      return teammate.fullName.length > 0 && (teammate.fullName.split(' ').length <= 1 || teammate.fullName.split(' ')[1]?.length < 1);
    });
    if (this.isNotFullName.some((item: boolean) => item === true)) {
      this.cannotProceed = true;
      return;
    }

    // reject if duplicate emails in array
    const mapTeammatesToEmail = this.service.teammates.map((teammate: IInAppInviteTeammate) => teammate.workEmail);
    mapTeammatesToEmail.forEach((email: string, idx: number) => {
      this.duplicatedEmails[idx] = check.nonEmptyString(email) && mapTeammatesToEmail.indexOf(email) !== idx;
    });
    if (this.duplicatedEmails.some((duplicate: boolean) => duplicate)) {
      this.cannotProceed = true;
      return;
    }

    // reject if email is not work email
    this.isNotWorkEmail = this.service.teammates.map((teammate: IInAppInviteTeammate) => {
      const domain = teammate.workEmail?.split('@')[1]?.toLocaleLowerCase() ?? '';
      const isBlacklisted = isEmailBlacklisted(teammate.workEmail);
      return (freeDomains[domain] === true || isBlacklisted === true) ?? false;
    });
    if (this.isNotWorkEmail.some((notWorkEmail: boolean) => notWorkEmail)) {
      this.cannotProceed = true;
      return;
    }

    // reject if any value is empty
    this.cannotProceed = this.service.teammates.some((teammate: IInAppInviteTeammate) => {
      return Object.values(teammate).some((property: string) => {
        return check.emptyString(property);
      });
    });
  }

  onEmailValidation(inputValidation: InputValidation, index: number): void {
    this.emailValidation[index] = inputValidation;
    this.validateTeammates();
  }

  resetForm(): void {
    this.displayForm = true;
  }

  async openDeleteDialog($event: Event, index: number) {
    if (this.service.teammates.length <= 1) {
      return;
    }

    $event.stopPropagation();
    const dialogTranslations = this.translations.dialogDeleteTeammate;
    const data = {
      titleText: dialogTranslations.dialogTitle,
      subtitleText: dialogTranslations.dialogDescription,
      confirmButtonText: dialogTranslations.confirm,
      cancelButtonText: dialogTranslations.goBack,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm) => {
      if (confirm) {
        this.removeTeammate(index);
      }
    });
  }
}
