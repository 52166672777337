import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { SettingsBarService } from '@app/standard/services/settings/settings-bar.service';
import * as check from 'check-types';
import * as moment from 'moment';

import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '../../../../components/i18n-data/i18n-data.pipe';
import { GlobalBarService } from '../../../../services/core/global-bar.service';
import { InternationalizationService } from '../../../../services/core/internationalization.service';
import { ApiKeyService, IApiKeyModel } from '../../../../services/integrations/api-key.service';
import { CreateApiKeyDialog } from './dialogs/create-api-key.dialog';

@Component({
  selector: 'orgos-api-key-preferences',
  templateUrl: 'api-key-preferences.page.html',
  styleUrls: ['api-key-preferences.page.scss'],
})
export class ApiKeyPreferencesPage implements OnInit {
  pageTranslation: any;
  miscTranslation: any = {};
  apiKeys: Array<any> = [];
  displayedColumns = ['alias', 'maskedKey', '_refreshedAt', 'actions'];

  DAYS_AGO_LIMIT_1: Number = 180;
  DAYS_AGO_LIMIT_2: Number = 365;
  MAX_API_KEYS_NUMBER: Number = 5;

  constructor(private injector: Injector, public dialog: MatLegacyDialog, public snackBar: MatLegacySnackBar, private router: Router) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    try {
      this.injector.get(GlobalBarService).setProgressBar(true);
      const appStatus = await this.injector.get(CloudRoutesService).getAppStatus('api-keys');
      if (check.not.assigned(appStatus) || (check.assigned(appStatus) && appStatus.isActive === false)) {
        this.router.navigateByUrl('/cloud/home');
        return;
      }
      await this.fetchData();
      await this.configureGlobalBar();
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('api-key-preferences-page');
      this.miscTranslation = await this.injector.get(InternationalizationService).getAllTranslation('misc');
    } catch (e) {
      this.miscTranslation = {};
      this.pageTranslation = {};
    }
  }

  private async fetchData(): Promise<void> {
    try {
      const now = moment(new Date());
      this.apiKeys = await this.injector.get(ApiKeyService).getAllApiKeys();
      this.apiKeys = this.apiKeys
        .map((apiKey) => {
          apiKey.daysAgo = now.diff(moment(apiKey._refreshedAt), 'days');
          return apiKey;
        })
        .sort((a, b) => {
          if (a.daysAgo < b.daysAgo) {
            return -1;
          } else {
            return 1;
          }
        });

      this.injector.get(GlobalBarService).setProgressBar(false);
    } catch (e) {
      this.injector.get(GlobalBarService).setProgressBar(false);
    }
  }

  private async configureGlobalBar(): Promise<void> {
    try {
      const settingsBarTranslation = await this.injector.get(InternationalizationService).getAllTranslation('settings-top-bar');
      const options = await this.injector.get(SettingsBarService).getOptions(settingsBarTranslation);
      this.injector.get(GlobalBarService).setPageName(settingsBarTranslation.pageName);
      this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
      this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(0);
    } catch (error) {
      throw error;
    }
  }

  public goBack(): void {
    this.injector.get(Router).navigate(['/cloud/settings/overview']);
  }

  public generateApiKey(apiKeyId?: string): void {
    // Provide the existing alias to avoid repeating them
    const aliasList = this.apiKeys.map((key) => {
      return key.alias;
    });
    const data = {
      apiKeyData: {
        alias: 'project',
        apiKey: '',
      },
      apiKeyId: apiKeyId,
      aliasList,
    };
    const dialogRef = this.dialog.open(CreateApiKeyDialog, { data });
    dialogRef.afterClosed().subscribe((apiKeyCreated) => {
      this.injector.get(GlobalBarService).setProgressBar(true);
      if (check.assigned(apiKeyCreated) && apiKeyCreated === true) {
        this.fetchData();
      } else {
        this.injector.get(GlobalBarService).setProgressBar(false);
      }
    });
  }

  public revokeApiKey(apiKeyId: string, apiKeyAlias: string): void {
    const dataTranslation = {
      alias: apiKeyAlias,
    };
    const data = {
      titleText: this.injector.get(I18nDataPipe).transform(this.pageTranslation.confirmDeleteMessage, dataTranslation),
      subtitleText: this.pageTranslation.confirmDeleteSubtitle,
      confirmButtonText: this.pageTranslation.confirmDeleteButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.miscTranslation.goBackButtonDialog,
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm && confirm === true) {
        this.injector.get(GlobalBarService).setProgressBar(true);
        this.injector
          .get(ApiKeyService)
          .deleteById(apiKeyId)
          .then(() => {
            const message = this.injector.get(I18nDataPipe).transform(this.pageTranslation.deletedSnackbarMessage, dataTranslation);
            this.snackBar.open(`${message}`, 'OK', {
              duration: 5000,
            });
            this.fetchData();
          })
          .catch(() => {
            // An error is already shown
            this.injector.get(GlobalBarService).setProgressBar(false);
          });
      }
    });
  }

  public editKey(id: String) {
    // Provide the existing alias to avoid repeating them
    const aliasList = [];
    this.apiKeys.forEach((key) => {
      if (key._id !== id) {
        aliasList.push(key.alias);
      }
    });
    const data = { id, aliasList };
    const dialogRef = this.dialog.open(CreateApiKeyDialog, { data });
    dialogRef.afterClosed().subscribe((apiKeyCreated) => {
      this.injector.get(GlobalBarService).setProgressBar(true);
      if (check.assigned(apiKeyCreated) && apiKeyCreated === true) {
        this.fetchData();
      } else {
        this.injector.get(GlobalBarService).setProgressBar(false);
      }
    });
  }
}
