import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ITask } from '@app/models/task.model';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { TaskHelperService } from '@app/standard/services/task/task-helper.service';
import { TaskService } from '@app/standard/services/task/task.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss'],
})
export class TaskDialogComponent implements OnInit, OnDestroy {
  pageTranslation: any = {};
  task: ITask;
  inputValidation: InputValidation;
  dialogTitle: string = '';
  taskAppPermissions: any = {};

  allActiveUserPersonal: Array<IUserPersonalModel>;
  allActiveUserPersonalSubscription: Subscription;

  constructor(private dialogRef: MatLegacyDialogRef<TaskDialogComponent>, private injector: Injector) {}

  ngOnInit(): void {
    this.inputValidation = new InputValidation();
    this.task = {
      title: '',
    };
    this.loadData();
  }

  ngOnDestroy() {
    this.allActiveUserPersonalSubscription.unsubscribe();
  }

  async loadData(): Promise<void> {
    await this.loadTranslations();
    await this.loadPermissions();
    if (this.taskAppPermissions.c_viewTasksAssignedToOthers === true || this.taskAppPermissions.c_accessCompanyTasks === true) {
      this.allActiveUserPersonalSubscription = this.injector
        .get(TaskHelperService)
        .getAllActiveUserPersonal()
        .pipe(distinctUntilChanged((dataA: any, dataB: any) => _.isEqual(dataA, dataB)))
        .subscribe((allActiveUserPersonal: Array<any>) => {
          this.allActiveUserPersonal = allActiveUserPersonal;
        });
    } else {
      const allowedAsigneeId = this.injector.get(AuthenticationService).getLoggedUser()._id;
      this.allActiveUserPersonalSubscription = this.injector
        .get(TaskHelperService)
        .getAllActiveUserPersonal()
        .pipe(distinctUntilChanged((dataA: any, dataB: any) => _.isEqual(dataA, dataB)))
        .subscribe((allActiveUserPersonal: Array<any>) => {
          this.allActiveUserPersonal = [allActiveUserPersonal.find((userPersonal: any) => userPersonal._id === allowedAsigneeId)];
        });
    }
  }

  async loadPermissions(): Promise<void> {
    // we can't use the method in TaskHelper because it just gives us the crud permissions, not the tab permissions
    this.taskAppPermissions = (await this.injector.get(PrivateSecurityService).getAllPermissions())['tasks-app'];
  }

  async loadTranslations(): Promise<void> {
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('tasks-dialog');
    } catch {
      this.pageTranslation = {};
    }
  }

  async save(): Promise<void> {
    try {
      await this.injector.get(TaskService).create(this.task);
      this.dialogRef.close();
    } catch {}
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
