import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class DatevAuthService {
  private DATEV_AUTH_SERVICE_URL = `${environment.PEOPLE_CLOUD_APP_URL}/datev-auth-db`;

  constructor(private injector: Injector) {}

  async getAuth(datevSettingsId: string): Promise<IDatevAuth | undefined> {
    try {
      const [auth] = await this.injector.get(HttpClient).post<Array<IDatevAuth>>(`${this.DATEV_AUTH_SERVICE_URL}/find`, { _datevSettingsId: datevSettingsId }).toPromise();
      return auth;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevAuthService.name, 'getAuth');
    }
  }

  async revoke(id: string) {
    try {
      await this.injector.get(HttpClient).delete<any>(`${this.DATEV_AUTH_SERVICE_URL}/${id}`).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevAuthService.name, 'revoke');
    }
  }
}

export interface IDatevAuth {
  _id: string;
  _createdBy: {
    _id: string;
    displayName: string;
    _photo?: IFileMetadata;
  };
  authorizedBy: string;
  _createdAt: Date;
  _validUntil: Date;
}
