import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { IStatusValues, ITimeOffUserPolicy } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { SubmitTimeOffRequestDialog } from '@app/common-components/time-off-user-personal/dialog/submit-time-off-request/submit-time-off-request.dialog';
import { TimeOffCardDetailsDialog } from '@app/common-components/time-off-user-personal/dialog/time-off-card-details/time-off-card-details.dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import {
  ALLOWANCE_TYPE_ANNUAL_GRANT,
  ALLOWANCE_TYPE_UNLIMITED,
  CYCLE_TYPE_DYNAMIC,
  POLICY_TYPE_HOUR,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-time-off-type-status',
  templateUrl: 'time-off-type-status.component.html',
  styleUrls: ['time-off-type-status.component.scss'],
})
export class TimeOffTypeStatusComponent implements OnInit {
  ALLOWANCE_TYPE_ANNUAL_GRANT: string = ALLOWANCE_TYPE_ANNUAL_GRANT;
  ALLOWANCE_TYPE_UNLIMITED: string = ALLOWANCE_TYPE_UNLIMITED;

  takenTooltip: string;
  expireDateInfo: string;
  colors: any = userColorConstants;
  statusData: IStatusValues = {};
  startDateIsMissing: boolean = false;
  disableRequestButton: boolean = false;
  startDateInFuture: boolean = false;

  isOnProbation: boolean = false;
  requireApproval: boolean;

  private _userPolicyStatus: ITimeOffUserPolicy;
  @Input()
  set userPolicyStatus(userPolicyStatus: ITimeOffUserPolicy) {
    this._userPolicyStatus = userPolicyStatus;
  }
  get userPolicyStatus(): ITimeOffUserPolicy {
    return this._userPolicyStatus;
  }

  @Input() translation: {
    [key: string]: string;
  };
  @Input() userId: string;
  @Input() showRequest: boolean;
  @Input() onBehalf: boolean = false;
  @Input() canCreateRequestsOnBehalf: boolean = false;
  @Input() canSeePolicyDetails: boolean = false;

  @Output() requestFinished: EventEmitter<void> = new EventEmitter();

  constructor(protected injector: Injector) {}

  ngOnInit() {
    if (check.assigned(this.userPolicyStatus)) {
      this.buildDataAndTexts();
    }
  }

  public openTimeOffRequestDialog(): void {
    const dialogOptions = {
      policy: this.userPolicyStatus.policy,
      timeOffType: this.userPolicyStatus.timeOffType,
      currentStatus: this.userPolicyStatus.currentStatus,
      startDate: this.userPolicyStatus.userWork.startDate,
      reportsToId: this.userPolicyStatus.userWork.reportsToId,
      isRequest: this.onBehalf === false && this.requireApproval,
      isOnBehalf: this.onBehalf,
      userId: this.userId,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(SubmitTimeOffRequestDialog, { data: dialogOptions, disableClose: true });
    dialogRef.afterClosed().subscribe((requested: boolean) => {
      if (check.assigned(requested) && requested === true) {
        this.requestFinished.emit();
      }
    });
  }

  public openTimeOffDetails(): void {
    this.injector.get(PrivateAmplitudeService).logEvent('view details balance card', {
      category: 'Time off',
      platform: 'Web',
      subcategory1: 'timeoff personal',
    });
    this.injector
      .get(MatLegacyDialog)
      .open(TimeOffCardDetailsDialog, { data: { userPolicyStatus: this.userPolicyStatus, userId: this.userId } });
  }

  private buildDataAndTexts() {
    const statusData: IStatusValues = timeOffHelpers.getStatusSummaryCardValues(
      this.userPolicyStatus.currentStatus,
      this.userPolicyStatus.policy.allowanceType
    );
    const isDynamic = this.userPolicyStatus.policy.cycleType === CYCLE_TYPE_DYNAMIC;
    this.startDateIsMissing = isDynamic && check.not.assigned(this.userPolicyStatus.userWork.startDate);
    this.startDateInFuture =
      check.assigned(this.userPolicyStatus.userWork.startDate) &&
      isDynamic &&
      moment.utc().isBefore(this.userPolicyStatus.userWork.startDate, 'day');

    const canRequestDynamicPolicy = isDynamic && !this.startDateIsMissing && !this.startDateInFuture;
    this.disableRequestButton = isDynamic ? !canRequestDynamicPolicy : false;

    Object.keys(statusData).forEach((iKey) => {
      if (this.userPolicyStatus.policy._type === POLICY_TYPE_HOUR) {
        this.statusData[iKey] = timeOffHelpers.getMinutesToDisplay(statusData[iKey], this.injector);
      } else {
        this.statusData[iKey] = timeOffHelpers.getDaysToDisplay(statusData[iKey], this.injector, this.translation);
      }
    });

    this.isOnProbation =
      this.userPolicyStatus.userWork.isOnProbation === true && this.userPolicyStatus?.policy?.freezeUntilProbationEnds === true;
    this.requireApproval = this.userPolicyStatus.policy.requireApproval;

    const { carryOverEnabled, carryOverExpireDate, available, currentCarryOver } = this.userPolicyStatus.currentStatus;
    if (carryOverEnabled === true && check.assigned(carryOverExpireDate)) {
      const expireDate = moment.utc(carryOverExpireDate).format('MMM D');
      let expireDateInfo = `${this.translation.expiresOn} ${expireDate}`;
      if (available < currentCarryOver) {
        expireDateInfo += `. ${this.injector
          .get(I18nDataPipe)
          .transform(this.translation.availableWithExpiredCarryOver, { available: this.statusData.availableAfterExpireCarryOver })}`;
      }
      this.expireDateInfo = expireDateInfo;
    }
  }
}
