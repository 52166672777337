<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogHeader" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-text [label]="dialogTranslation.inputTextLabel" [(value)]="documentNewName" class="kenjo-block" required="true" (validation)="fieldLabelValidation = $event">
    <orgos-input-error *ngIf="fieldLabelValidation && fieldLabelValidation.getError('required')"> {{dialogTranslation.validationMessage}}</orgos-input-error>
  </orgos-input-simple-text>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ miscTranslation.goBackButtonDialog }} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="updateDocumentsName()" color="Success">
        {{dialogTranslation.saveButtonLabel}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
