<div class="kenjo-p-40px asc-content">
  <div>
    <div class="kenjo-font-weight-bold kenjo-font-size-16px kenjo-mb-40px">{{ translations.activationHeader }}</div>

    <div class="asc-boxes kenjo-mb-15px">
      <!-- Invitation -->
      <div class="kenjo-border kenjo-border-radius">
        <div class="asc-box-title kenjo-pv-10px kenjo-ph-20px">
          <div class="kenjo-font-size-14px">{{ translations.sendInvitationLabel }}</div>
          <div>
            <mat-slide-toggle
              [checked]="activationOptions.sendInvitation"
              [disabled]="!activationPermissions.sendInvitation"
              [matTooltip]="!activationPermissions.sendInvitation ? translations.disabledActivateEmployeeTooltip : null"
              (change)="activationOptions.sendInvitation = $event.checked; logActivationEvent('invitation', $event.checked)"
              matTooltipPosition="above"
            >
            </mat-slide-toggle>
          </div>
        </div>
        <div class="kenjo-p-20px asc-box-content">
          <div class="kenjo-text-align-center asc-image-container">
            <img src="/assets/images/invitation.svg" class="asc-image" />
          </div>
          <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575 asc-description-container">{{ translations.sendInvitationDescription }}</div>
        </div>
      </div>

      <!-- Track attendance -->
      <div *ngIf="attendanceEnabled" class="kenjo-border kenjo-border-radius">
        <div class="asc-box-title kenjo-pv-10px kenjo-ph-20px">
          <div class="kenjo-font-size-14px">{{ translations.trackAttendanceLabel }}</div>
          <div>
            <mat-slide-toggle
              [checked]="userWorkSchedule.data.trackAttendance"
              [disabled]="!activationPermissions.trackAttendance"
              [matTooltip]="!activationPermissions.trackAttendance ? translations.disabledAttendanceTrackingTooltip : null"
              (change)="userWorkSchedule.data.trackAttendance = $event.checked; logActivationEvent('attendance', $event.checked)"
              matTooltipPosition="above"
            ></mat-slide-toggle>
          </div>
        </div>
        <div class="kenjo-p-20px asc-box-content">
          <div class="kenjo-text-align-center asc-image-container">
            <img src="/assets/images/attendance-success.svg" class="asc-image" />
          </div>
          <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575 asc-description-container">{{ translations.trackAttendanceDescription }}</div>
        </div>
      </div>
    </div>
    <div class="asc-boxes kenjo-mb-15px">
      <!-- Enable User Datev -->
      <div *ngIf="datevOptions.isActive" class="kenjo-border kenjo-border-radius">
        <div class="asc-box-title kenjo-pv-10px kenjo-ph-20px">
          <div class="kenjo-font-size-14px">{{ translations.addDatevLabel }}</div>
          <div>
            <mat-slide-toggle (change)="datevOptions.datevEnabled = $event.checked; logActivationEvent('datev', $event.checked)" [checked]="datevOptions.datevEnabled"> </mat-slide-toggle>
          </div>
        </div>
        <div class="kenjo-ph-20px asc-box-content">
          <div class="kenjo-text-align-center asc-image-container kenjo-pv-20px">
            <img src="/assets/images/payroll.svg" class="asc-image" />
          </div>
          <div *ngIf="datevOptions.datevEnabled !== true" class="kenjo-font-size-12px kenjo-font-color-light-text-757575 asc-description-container">{{ translations.addDatevDescription }}</div>
          <div *ngIf="datevOptions.datevEnabled === true" class="asc-description-container kenjo-pv-5px">
            <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">
              {{ translations.addDatevDescription }}
            </div>
            <div class="asc-pnr-container">
              <div class="asc-pnr-input-container">
                <orgos-input-simple-number
                  [placeholder]="translations.datevPersonalNumberLabel"
                  [(value)]="datevOptions.personalNumber"
                  (validation)="personalNumberValidation = $event"
                  [returnValueWithErrors]="true"
                  (valueChange)="onChangeValue($event)"
                  [step]="1"
                  [min]="1"
                  [max]="99999"
                  class="asc-pnr asc-input"
                  [forceError]="(personalNumberValidation && personalNumberValidation.hasErrors()) || isValidPersonalNumber !== true"
                ></orgos-input-simple-number>
                <orgos-input-error *ngIf="personalNumberValidation && personalNumberValidation.hasErrors() && personalNumberValidation.getError('max') === true"> {{ translations.datevPersonalNumberErrorMessage }}</orgos-input-error>
                <orgos-input-error *ngIf="personalNumberValidation && personalNumberValidation.hasErrors() && personalNumberValidation.getError('max') !== true"> {{ translations.datevInvalidPersonalNumberErrorMessage }}</orgos-input-error>
                <orgos-input-error *ngIf="isValidPersonalNumber !== true"> {{ translations.datevPersonalNumberInUseErrorMessage }}</orgos-input-error>
              </div>
              <kenjo-icon [size]="20" [matTooltip]="translations.datevPersonalNumberTooltip" class="kenjo-font-color-success-00b72e kenjo-ml-5px kenjo-cursor-pointer">help_outline</kenjo-icon>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>

  <div class="asc-button kenjo-mt-30px">
    <orgos-button-raised (click)="onPreviousStep()" color="Neutral">{{ translations.backLabel }}</orgos-button-raised>
    <orgos-button-raised (click)="onAddEmployee()" color="Success" [disabled]="(this.personalNumberValidation && this.personalNumberValidation.getError('max')) || addEmployeeAllowed !== true || disableButton">{{ translations.addEmployeeLabel }}</orgos-button-raised>
  </div>
</div>
