import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as profiles from '@carlos-orgos/orgos-security/profiles';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { PrivateSecurityService } from '../../../private/services/private-security.service';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class ProfileService implements IGenericService {
  private PROFILE_INTERNATIONALIZATION: string = 'profile-collection';
  private PROFILE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/profile-db`;

  constructor(private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  create(data: object): Promise<IProfileModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, ProfileService.name, 'create');
    error.message = 'Profile should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IProfileModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, ProfileService.name, 'getById');
    error.message = 'Profile should not be retrieved';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.PROFILE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProfileService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.PROFILE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProfileService.name, 'deleteById'));
        });
    });
  }

  getMine(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.injector
        .get(PrivateSecurityService)
        .getFullProfile()
        .then((profile) => {
          resolve(profile);
        });
    });
  }

  getPermissions(): Promise<object> {
    if (!this.authenticationService.isUserAuthenticated() || this.authenticationService.getLoggedUser().profile._isAdmin === false) {
      return Promise.resolve({ create_all: false, read_all: false, edit_all: false, delete_all: false });
    }

    return Promise.resolve({ create_all: true, read_all: true, edit_all: true, delete_all: true });
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.PROFILE_INTERNATIONALIZATION);
  }

  getProfiles(): Promise<Array<IProfileModel>> {
    return new Promise<Array<IProfileModel>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.PROFILE_URL}/all`, httpOptions)
        .toPromise()
        .then((profiles: Array<IProfileModel>) => {
          const noHiddenProfiles = profiles.filter((iProfile) => {
            return check.not.assigned(iProfile.hidden) || iProfile.hidden === false;
          });
          resolve(noHiddenProfiles);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProfileService.name, 'getProfiles'));
        });
    });
  }

  cloneFromProfile(data: object): Promise<IProfileModel> {
    return new Promise<IProfileModel>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.PROFILE_URL}/clone-from/employee`, data, httpOptions)
        .toPromise()
        .then((profile: IProfileModel) => {
          resolve(profile);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProfileService.name, 'cloneFromProfile'));
        });
    });
  }
}

export interface IProfileModel {
  _id?: string;
  _profileKey?: string;
  name: string;
  _isAdmin?: boolean;
  _isStandard?: boolean;
  permissions?: any;
  hidden?: boolean;
}
