import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserOvertimeHistoryService } from '@app/standard/services/user/user-overtime-history.service';
import { UserSalaryService } from '@app/standard/services/user/user-salary.service';
import { UserVariablePayService } from '@app/standard/services/user/user-variable-pay.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { VariablePayTypeService } from '@app/standard/services/variable-pay-type/variable-pay-type.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-overtime-compensation-pay',
  templateUrl: 'overtime-compensation-pay.dialog.html',
  styleUrls: ['overtime-compensation-pay.dialog.scss'],
})
export class OvertimeCompensationPayDialog implements OnInit {
  maxValues: { hours?: number; minutes?: number } = {
    hours: 0,
    minutes: 0,
  };

  newBalance = {
    hours: 0,
    minutes: 0,
  };

  amount: number = 0;
  comments: string;
  paymentDate: Date;
  totalAmount: number = 0;
  currency: string;

  COMPENSATION_TYPE: string = picklistConstants.OVERTIME_HISTORY_ENTRY_TYPE_PAY;
  saving: boolean = false;

  hoursValidation: InputValidation;
  policyValidation: InputValidation;
  amountValidation: InputValidation;
  dateValidation: InputValidation;

  translation: any = {};

  year: number;
  month: number;
  userIds: Array<string> = [];
  userPersonal: any = {};
  balances: { [userId: string]: number };
  nextBalance: number = 0;
  companyId: string;
  variableTypeOptions: Array<ISelectOption> = [];
  currencyOptions: Array<ISelectOption> = [];
  variablePayTypeId: string;

  currentBalance: number = 0;
  balanceAfterText: string;

  /**
   * @param dialogRef reference to the dialog
   * @param data object like:
   *  overtime: {[userId: string]: number}
   * It is a map (userId, overtime)
   * @param injector injector of angular
   */
  constructor(
    public dialogRef: MatLegacyDialogRef<OvertimeCompensationPayDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.userIds = Object.keys(this.data.userIds);
    this.balances = this.data.userIds;
    this.year = this.data.year;
    this.month = this.data.month;
    this.paymentDate = moment.utc().startOf('day').toDate();
    this.currentBalance = this.balances[this.userIds[0]];

    this.setNewMaxValuesFromMinutes(this.currentBalance);

    this.currency = 'EUR';
    this.currencyOptions = picklistConstants.CURRENCY_CODE_LIST.map((iCurrency) => {
      return {
        value: iCurrency,
        name: iCurrency,
      };
    });

    const userId = this.userIds[0];

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('overtime-compensation-pay-dialog')
      .then((translation: any) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(UserSalaryService)
      .getUserSalaries(userId)
      .then((salaries) => {
        if (check.assigned(salaries) && check.nonEmptyArray(salaries)) {
          this.currency = salaries[0].currency;
        }
      })
      .catch(() => {
        this.amount = 0;
      });

    this.injector
      .get(UserWorkService)
      .getById(userId)
      .then((userWork) => {
        this.companyId = userWork.companyId;
      })
      .catch(() => {
        this.companyId = null;
      });

    this.injector
      .get(VariablePayTypeService)
      .getAllTypes()
      .then((types) => {
        this.variableTypeOptions = types.map((iType) => {
          return {
            value: iType._id,
            name: iType.name,
          };
        });
      })
      .catch(() => {
        this.variableTypeOptions = [];
      });
  }

  public changeNewBalance(): void {
    this.totalAmount = this.calculateTotalAmount();
    this.nextBalance = this.currentBalance - this.getNewBalanceMinutes();

    const duration = this.injector.get(DurationPipe).transform(this.nextBalance);
    this.balanceAfterText = this.injector.get(I18nDataPipe).transform(this.translation.balanceAfterConversion, { hours: duration });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.saving = true;
    const payment = {
      _userId: this.userIds[0],
      _companyId: this.companyId,
      effectiveDate: this.paymentDate,
      everyMonths: false,
      type: this.variablePayTypeId,
      amount: this.totalAmount,
      comments: this.comments,
      currency: this.currency,
    };

    this.injector
      .get(UserVariablePayService)
      .create(payment)
      .then((iPayment) => {
        const historyEntry = {
          type: this.COMPENSATION_TYPE,
          minutes: this.getNewBalanceMinutes(),
          minutesBefore: this.balances[this.userIds[0]],
          relatedToId: iPayment._id,
          comments: this.comments,
          userId: this.userIds[0],
          month: this.month,
          year: this.year,
        };
        return this.injector.get(UserOvertimeHistoryService).create(historyEntry);
      })
      .then(() => {
        this.saving = false;
        this.dialogRef.close(true);
      })
      .catch((err) => {
        this.saving = false;
      });
  }

  public getNewBalanceMinutes(): number {
    const hoursMinutes = check.assigned(this.newBalance.hours) ? this.newBalance.hours * 60 : 0;
    const minutes = check.assigned(this.newBalance.minutes) ? this.newBalance.minutes : 0;

    return Number(hoursMinutes) + Number(minutes);
  }

  public calculateTotalAmount(): number {
    const decimalMinutes = Number((this.newBalance.minutes ?? 0) / 60).toFixed(2);
    return Number(((this.newBalance.hours + Number(decimalMinutes)) * (this.amount ?? 0)).toFixed(2));
  }

  public setNewMaxValuesFromMinutes(amount: number): void {
    const hours = amount / 60;
    const hoursRounded = Math.floor(hours);
    const minutes = Math.round((hours - hoursRounded) * 60);

    this.maxValues = {
      hours: hoursRounded,
      minutes,
    };
  }

  public preventWrongMinutesValue(newMinutesValue?: number | null): void {
    if (newMinutesValue === null) {
      this.newBalance.minutes = newMinutesValue;
      return;
    }

    const minutesToCompare = check.assigned(newMinutesValue) ? newMinutesValue : this.newBalance.minutes;

    if (this.newBalance.hours === (this.maxValues?.hours ?? 0) && minutesToCompare > this.maxValues.minutes) {
      this.newBalance.minutes = [45, 30, 15, 0].find((value) => value <= this.maxValues.minutes);
    } else if (
      this.newBalance.hours === (this.maxValues?.hours ?? 0) &&
      check.assigned(newMinutesValue) &&
      newMinutesValue <= this.maxValues.minutes
    ) {
      this.newBalance.minutes = newMinutesValue;
    } else if (this.newBalance.hours < (this.maxValues?.hours ?? 0) && check.assigned(newMinutesValue)) {
      this.newBalance.minutes = newMinutesValue;
    }
  }

  public parseBalanceHours() {
    const hoursValue = Math.round(Math.abs(this.newBalance.hours ?? 0));

    this.newBalance.hours = hoursValue > this.maxValues?.hours ? this.maxValues?.hours : hoursValue;
  }

  public parseBalanceMinutes() {
    const minutesValue = Math.round(Math.abs(this.newBalance.minutes ?? 0));
    const maxValuesMinutes = this.newBalance.hours === this.maxValues?.hours ? this.maxValues?.minutes : 59;

    this.newBalance.minutes = minutesValue > maxValuesMinutes ? maxValuesMinutes : minutesValue;
  }
}
