import { Injectable } from '@angular/core';
import { TimeOffPolicyService } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { TimeOffRequestService } from '@app/cloud-features/time-off/services/time-off-request.service';
import { TimeOffStatusHistoryService } from '@app/cloud-features/time-off/services/time-off-status-history.service';
import { TimeOffStatusService } from '@app/cloud-features/time-off/services/time-off-status.service';
import { TimeOffTypeService } from '@app/cloud-features/time-off/services/time-off-type.service';
// In app invitations
import { InAppInviteService } from '@app/standard/services/in-app-invite/in-app-invite.service';

// App Status:
import { AppStatusService } from './app-status/app-status.service';
// Approval Rule services:
import { ApprovalRulesService } from './approval-rules/approval-rules.service';
// Company vibe services:
import { CategoryService } from './company-vibe/category.service';
import { QuestionService } from './company-vibe/question.service';
import { SurveyResultService } from './company-vibe/survey-result.service';
import { SurveyService } from './company-vibe/survey.service';
import { AreaService } from './company/area.service';
// Company services:
import { CalendarTemplateService } from './company/calendar-template.service';
import { CalendarService } from './company/calendar.service';
import { CompanyService } from './company/company.service';
import { CostCenterService } from './company/cost-center.service';
import { DepartmentService } from './company/department.service';
import { OfficeService } from './company/office.service';
import { TeamService } from './company/team.service';
// Core features
import { CoreFeaturesService } from './core-features/core-features.service';
// Core services:
import { AuthenticationService } from './core/authentication.service';
import { ClipboardService } from './core/clipboard.service';
import { CloudRoutesService } from './core/cloud-routes.service';
import { GlobalBarService } from './core/global-bar.service';
import { HistoryService } from './core/history.service';
import { InternationalizationService } from './core/internationalization.service';
import { PicklistsService } from './core/picklists.service';
// Custom Field service
import { CustomFieldService } from './custom-field/custom-field.service';
// Data engine service
import { CandidateListService } from './data-engine/candidate-list.service';
import { PeopleDirectoryService } from './data-engine/people-directory.service';
import { SmartDocsService } from './data-engine/smart-docs.service';
import { DocumentExportService } from './document/document-export.service';
import { DocumentTagService } from './document/document-tag.service';
import { DocumentTemplateService } from './document/document-template.service';
// Document services:
import { DocumentService } from './document/document.service';
// Email signature template services
import { EmailSignatureTemplateService } from './email-signature-template/email-signature-template.service';
// Email template services
import { EmailTemplateService } from './email-template/email-template.service';
import { ErrorManagerService } from './error/error-manager.service';
// Error Services
import { ErrorParserService } from './error/error-parser.service';
// Feed services:
import { FeedService } from './feed/feed.service';
// Freebie services:
import { FreebieDataGeneratorService } from './freebie-data-generator/freebie-data-generator.service';
import { GenericService } from './generic.service';
// Ical service
import { IcalService } from './ical/ical.service';
// Auth Api key services:
import { ApiKeyService } from './integrations/api-key.service';
// Jobadpartner integration service
import { JobadpartnerService } from './jobadpartner/jobadpartner.service';
// People bar service
import { PeopleBarService } from './navigation-tabs/people-bar.service';
// Smart docs bar service
import { SmartDocsBarService } from './navigation-tabs/smart-docs-bar.service';
// Performance management services
import { GoalService } from './performance-management/goal.service';
import { MeetingResponseService } from './performance-management/meeting-response.service';
import { MeetingTemplateService } from './performance-management/meeting-template.service';
import { MeetingService } from './performance-management/meeting.service';
// Preferences services
import { PreferenceService } from './preference/preference.service';
// Profile service
import { ProfileService } from './profile/profile.service';
import { ProjectMemberService } from './project-management/project-member.service';
import { ProjectTimeEntryService } from './project-management/project-time-entry.service';
// Project management services
import { ProjectService } from './project-management/project.service';
import { CandidateTagService } from './recruiting/candidate-tag.service';
import { CandidateService } from './recruiting/candidate.service';
// Recruiting services
import { CompanyCareerSiteService } from './recruiting/company-career-site.service';
import { RecruitingCandidateService } from './recruiting/controllers/recruiting-candidate.service';
// Recruiting overview service:
import { RecruitingOverviewService } from './recruiting/controllers/recruiting-overview.service';
import { RecruitingPositionKanbanService } from './recruiting/controllers/recruiting-position-kanban.service';
import { RecruitingPositionListService } from './recruiting/controllers/recruiting-position-list.service';
import { HiringTeamService } from './recruiting/hiring-team.service';
import { PositionCandidateScoreService } from './recruiting/position-candidate-score.service';
import { PositionCandidateService } from './recruiting/position-candidate.service';
import { PositionTagService } from './recruiting/position-tag.service';
import { PositionService } from './recruiting/position.service';
import { RecruitingAgencyService } from './recruiting/recruiting-agency.service';
import { RecruitingDocumentTagService } from './recruiting/recruiting-document-tag.service';
import { RecruitingDocumentService } from './recruiting/recruiting-document.service';
import { RecruitingEmailService } from './recruiting/recruiting-email.service';
import { RecruitingPipelineStageService } from './recruiting/recruiting-pipeline-stage.service';
import { RecruitingPipelineService } from './recruiting/recruiting-pipeline.service';
import { RecruitingRejectionReasonService } from './recruiting/recruiting-rejection-reason.service';
import { RecruitingScheduleDeletionConfigurationService } from './recruiting/recruiting-schedule-deletion-configuration.service';
import { RecruitingScorecardService } from './recruiting/recruiting-scorecard.service';
import { RecruitingTalentPoolService } from './recruiting/recruiting-talent-pool.service';
// Report services:
import { ReportService } from './report/report.service';
// setting bar service
import { SettingsBarService } from './settings/settings-bar.service';
// Single Sing On service
import { SingleSignOnService } from './single-sign-on/single-sign-on.service';
// Tasks service
import { TaskHelperService } from './task/task-helper.service';
import { TaskService } from './task/task.service';
// 2 factor authentication
import { TwoFactorAuthenticationService } from './two-factor-authentication/two-factor-authentication.service';
// User services:
import { UserAccountService } from './user/user-account.service';
import { UserAddressService } from './user/user-address.service';
import { UserAttendanceService } from './user/user-attendance.service';
import { UserCompensationService } from './user/user-compensation.service';
import { UserConfidentialService } from './user/user-confidential.service';
import { UserDatevService } from './user/user-datev.service';
import { UserEmergencyService } from './user/user-emergency.service';
import { UserEmploymentContractTypeService } from './user/user-employment-contract-type.service';
import { UserEmploymentSubcategoryService } from './user/user-employment-subcategory.service';
import { UserEmploymentTerminationReasonService } from './user/user-employment-termination-reason.service';
import { UserEmploymentService } from './user/user-employment.service';
import { UserFinancialService } from './user/user-financial.service';
import { UserHomeService } from './user/user-home.service';
import { UserOvertimeHistoryService } from './user/user-overtime-history.service';
import { UserPersonalService } from './user/user-personal.service';
import { UserSalaryService } from './user/user-salary.service';
import { UserSnapshotService } from './user/user-snapshot.service';
import { UserUtilsService } from './user/user-utils.service';
import { UserVariablePayService } from './user/user-variable-pay.service';
import { UserWorkScheduleService } from './user/user-work-schedule.service';
import { UserWorkService } from './user/user-work.service';
import { UserService } from './user/user.service';
// Variable Pay Type
import { VariablePayTypeService } from './variable-pay-type/variable-pay-type.service';
// Widgets services:
import { WidgetsService } from './widgets/widgets.service';
import { WorkflowActionService } from './workflow/workflow-action.service';
import { WorkflowRunsService } from './workflow/workflow-runs.service';
// Workflow services:
import { WorkflowService } from './workflow/workflow.service';
import { QueryParamsService } from './navigation/query-params.service';

const servicesRegistry = {
  ErrorParser: ErrorParserService,
  ErrorManager: ErrorManagerService,

  ApiKeyService: ApiKeyService,

  ApprovalRulesService: ApprovalRulesService,

  Authentication: AuthenticationService,
  CloudRoutes: CloudRoutesService,
  GlobalBar: GlobalBarService,
  Internationalization: InternationalizationService,
  Picklist: PicklistsService,
  History: HistoryService,

  SettingsBar: SettingsBarService,
  PeopleBar: PeopleBarService,
  SmartDocs: SmartDocsBarService,
  QueryParam: QueryParamsService,

  CustomField: CustomFieldService,

  Document: DocumentService,
  DocumentExport: DocumentExportService,
  DocumentTag: DocumentTagService,
  DocumentTemplate: DocumentTemplateService,

  CandidateList: CandidateListService,
  PeopleDirectory: PeopleDirectoryService,

  Profile: ProfileService,

  UserAccount: UserAccountService,
  UserAddress: UserAddressService,
  UserAttendance: UserAttendanceService,
  UserCompensation: UserCompensationService,
  UserConfidential: UserConfidentialService,
  UserDatev: UserDatevService,
  UserEmergency: UserEmergencyService,
  UserEmployment: UserEmploymentService,
  UserFinancial: UserFinancialService,
  UserHome: UserHomeService,
  UserOvertimeHistory: UserOvertimeHistoryService,
  UserPersonal: UserPersonalService,
  UserSalary: UserSalaryService,
  UserSnapshot: UserSnapshotService,
  UserUtils: UserUtilsService,
  UserVariablePay: UserVariablePayService,
  UserWork: UserWorkService,
  UserWorkSchedule: UserWorkScheduleService,
  User: UserService,

  CalendarTemplate: CalendarTemplateService,
  Calendar: CalendarService,
  Company: CompanyService,
  Team: TeamService,
  Area: AreaService,
  Department: DepartmentService,
  Office: OfficeService,
  TimeOffType: TimeOffTypeService,
  TimeOffPolicy: TimeOffPolicyService,
  TimeOffStatus: TimeOffStatusService,
  TimeOffStatusHistoryService: TimeOffStatusHistoryService,
  TimeOffRequest: TimeOffRequestService,
  CostCenter: CostCenterService,

  Category: CategoryService,
  Survey: SurveyService,
  SurveyResult: SurveyResultService,
  Question: QuestionService,

  Feed: FeedService,

  Report: ReportService,

  Workflow: WorkflowService,
  WorkflowAction: WorkflowActionService,
  WorkflowRun: WorkflowRunsService,

  EmailTemplate: EmailTemplateService,

  EmailSignatureTemplate: EmailSignatureTemplateService,

  SingleSignOn: SingleSignOnService,
  TwoFactorAuthentication: TwoFactorAuthenticationService,

  Task: TaskService,

  VariablePayType: VariablePayTypeService,

  CompanyCareerSite: CompanyCareerSiteService,
  RecruitingScheduleDeletionConfiguration: RecruitingScheduleDeletionConfigurationService,
  PositionTag: PositionTagService,
  CandidateTag: CandidateTagService,
  Position: PositionService,
  Candidate: CandidateService,
  RecruitingPipeline: RecruitingPipelineService,
  RecruitingPipelineStage: RecruitingPipelineStageService,
  RecruitingScorecard: RecruitingScorecardService,
  HiringTeam: HiringTeamService,
  PositionCandidate: PositionCandidateService,
  PositionCandidateScore: PositionCandidateScoreService,
  RecruitingAgency: RecruitingAgencyService,
  RejectionReason: RecruitingRejectionReasonService,
  TalentPool: RecruitingTalentPoolService,
  RecruitingEmail: RecruitingEmailService,

  Jobadpartner: JobadpartnerService,

  Ical: IcalService,

  Goal: GoalService,
  Meeting: MeetingService,
  MeetingTemplate: MeetingTemplateService,
  MeetingResponse: MeetingResponseService,

  CoreFeatures: CoreFeaturesService,

  Project: ProjectService,
  ProjectMember: ProjectMemberService,
  ProjectTimeEntry: ProjectTimeEntryService,

  AppStatus: AppStatusService,

  InAppInvite: InAppInviteService,
};

@Injectable()
export class StandardServicesRegistry {
  getService(serviceName: string) {
    return servicesRegistry[serviceName];
  }
}

export const standardServices = [
  StandardServicesRegistry,
  GenericService,

  ErrorParserService,
  ErrorManagerService,

  ApiKeyService,

  ApprovalRulesService,

  AuthenticationService,
  CloudRoutesService,
  GlobalBarService,
  InternationalizationService,
  PicklistsService,
  HistoryService,
  ClipboardService,

  SettingsBarService,
  PeopleBarService,
  SmartDocsBarService,

  CustomFieldService,

  DocumentService,
  DocumentExportService,
  DocumentTagService,
  DocumentTemplateService,

  CandidateListService,
  PeopleDirectoryService,
  SmartDocsService,

  ProfileService,

  UserAccountService,
  UserAddressService,
  UserAttendanceService,
  UserCompensationService,
  UserConfidentialService,
  UserDatevService,
  UserEmergencyService,
  UserEmploymentService,
  UserFinancialService,
  UserHomeService,
  UserOvertimeHistoryService,
  UserPersonalService,
  UserSalaryService,
  UserSnapshotService,
  UserUtilsService,
  UserVariablePayService,
  UserWorkService,
  UserWorkScheduleService,
  UserService,
  UserEmploymentContractTypeService,
  UserEmploymentSubcategoryService,
  UserEmploymentTerminationReasonService,

  CalendarTemplateService,
  CalendarService,
  CompanyService,
  AreaService,
  TeamService,
  DepartmentService,
  OfficeService,
  TimeOffTypeService,
  TimeOffPolicyService,
  TimeOffStatusService,
  TimeOffRequestService,
  CostCenterService,

  FeedService,

  CategoryService,
  SurveyService,
  SurveyResultService,
  QuestionService,

  ReportService,

  WorkflowService,
  WorkflowActionService,
  WorkflowRunsService,

  EmailTemplateService,

  EmailSignatureTemplateService,

  SingleSignOnService,
  TwoFactorAuthenticationService,

  TaskService,
  TaskHelperService,

  VariablePayTypeService,

  CompanyCareerSiteService,
  RecruitingScheduleDeletionConfigurationService,
  PositionTagService,
  CandidateTagService,
  PositionService,
  CandidateService,
  RecruitingPipelineService,
  RecruitingPipelineStageService,
  RecruitingScorecardService,
  PositionCandidateService,
  RecruitingDocumentTagService,
  RecruitingDocumentService,
  HiringTeamService,
  PositionCandidateScoreService,
  RecruitingAgencyService,
  RecruitingRejectionReasonService,
  RecruitingTalentPoolService,
  RecruitingEmailService,
  RecruitingPositionKanbanService,

  JobadpartnerService,

  IcalService,

  ProjectService,
  ProjectMemberService,
  ProjectTimeEntryService,

  GoalService,
  MeetingService,
  MeetingTemplateService,
  MeetingResponseService,
  PreferenceService,
  CoreFeaturesService,

  FreebieDataGeneratorService,

  WidgetsService,

  RecruitingOverviewService,
  RecruitingPositionListService,
  RecruitingCandidateService,

  AppStatusService,

  InAppInviteService,
];
