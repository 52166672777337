<div *ngIf="!isLoading" class="tocd-dialog-container">
  <!-- TITLE -->
  <orgos-column-container class="tocd-header">
    <orgos-column size="0" class="tocd-plane-icon">
      <kenjo-icon [size]="24" class="">time_off_menu</kenjo-icon>
    </orgos-column>
    <orgos-column size="1" class="kenjo-font-size-20px"> {{ timeOffType.name }} </orgos-column>
    <orgos-column size="0">
      <kenjo-icon [size]="24" class="tocd-close-button kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear </kenjo-icon>
    </orgos-column>
  </orgos-column-container>
  <!-- TAB SELECTOR -->
  <mat-tab-group *ngIf="policy.cycleType !== CYCLE_TYPE_DISABLED || scheduledPolicy" [selectedIndex]="tabIndex" (selectedIndexChange)="tabIndex = $event; clickTab($event)" class="kenjo-mt-30px tocd-tab-selector">
    <mat-tab *ngIf="hasPastCycle" [label]="dialogTranslation.previousCycleTitle"></mat-tab>
    <mat-tab [label]="dialogTranslation.currentCycleTitle"></mat-tab>
    <mat-tab [label]="dialogTranslation.nextCycleTitle"></mat-tab>
  </mat-tab-group>

  <div class="tocd-scrollable-content">
    <!-- STATUS DETAILS -->
    <div class="tocd-status-container">
      <div *ngIf="isDataFetching" class="tocd-loading-spinner">
        <orgos-loading-spinner></orgos-loading-spinner>
        <div class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-color-light-text-757575">
          <ng-container>{{ dialogTranslation.loadingDataMessage }}</ng-container>
        </div>
      </div>
      <orgos-column-container *ngIf="!isDataFetching" class="kenjo-mt-20px">
        <kenjo-status-details-card class="kenjo-full-width" orgosColumn="0" *ngIf="hasPastCycle && tabIndex === 0" [status]="previousStatus" [policy]="policy" [userWork]="userWork" [translations]="statusDetailsTranslations"></kenjo-status-details-card>
        <kenjo-status-details-card
          class="kenjo-full-width"
          orgosColumn="0"
          *ngIf="hasPastCycle && tabIndex === 1 || !hasPastCycle && tabIndex === 0 || policy.cycleType === CYCLE_TYPE_DISABLED && !scheduledPolicy"
          [status]="currentStatus"
          [policy]="policy"
          [userWork]="userWork"
          [translations]="statusDetailsTranslations"
        ></kenjo-status-details-card>
        <kenjo-status-details-card
          class="kenjo-full-width"
          orgosColumn="0"
          *ngIf="hasPastCycle && tabIndex === 2 || !hasPastCycle && tabIndex === 1"
          [status]="nextStatus"
          [policy]="policy"
          [userWork]="userWork"
          [translations]="statusDetailsTranslations"
          [displayScheduledAssignment]="displayScheduledAssignment"
        ></kenjo-status-details-card>
      </orgos-column-container>
    </div>

    <!-- POLICY DETAILS -->
    <orgos-column-container class="kenjo-mt-30px">
      <kenjo-policy-details-card orgosColumn="0" class="kenjo-full-width" [policy]="policy" [timeOffType]="timeOffType" [translations]="policyDetailsTranslations"></kenjo-policy-details-card>
    </orgos-column-container>
  </div>

  <!-- FOOTER -->
  <orgos-column-container class="kenjo-mt-30px">
    <orgos-column size="1"></orgos-column>
    <orgos-button-raised orgosColumn="0" color="Success" (click)="closeDialog()"> {{dialogTranslation.closeButton}} </orgos-button-raised>
  </orgos-column-container>
</div>
