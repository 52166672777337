import { Component, Injector, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IWorkScheduleTemplateModel } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-time-table',
  templateUrl: 'time-table.component.html',
  styleUrls: ['time-table.component.scss']
})
export class TimeTableComponent implements OnInit {
  translations: any = {};

  _workScheduleTemplate: IWorkScheduleTemplateModel;
  @Input() set workScheduleTemplate(workScheduleTemplate: IWorkScheduleTemplateModel) {
    this._workScheduleTemplate = workScheduleTemplate;
    this.calculateMinutes();
  }
  get workScheduleTemplate(): IWorkScheduleTemplateModel {
    return this._workScheduleTemplate;
  }
  @Input() readOnly: boolean = false;

  translatedWeekdays: Array<string>;
  minutesByWeek: number = 0;
  weekdayShiftsValid: object = {}

  validDayShifts: boolean[] = [true, true, true, true, true, true, true];
  @Output() validSave: EventEmitter<boolean> = new EventEmitter();

  constructor(private injector: Injector) {}
  ngOnInit(): void {
    this.translatedWeekdays = this.injector.get(InternationalizationService).getTranslatedWeekdays();
    this.fetchData();
  }

  private fetchData(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('time-table-component')
      .then((pageTranslation) => {
        this.translations = pageTranslation;
      })
      .catch(() => {
        this.translations = {};
      });
  }

  public createShift(dayCounter: number): void {
    this.workScheduleTemplate.dayShifts[dayCounter].shifts.push({});
    this.validateShifts(dayCounter);
  }

  public deleteShift(dayCounter: number): void {
    this.workScheduleTemplate.dayShifts[dayCounter].shifts.pop();
    this.calculateMinutes();
    this.validateDayShifts(dayCounter);
  }

  private validateShifts(dayCounter: number): void {
    this.weekdayShiftsValid[dayCounter] = this.workScheduleTemplate.dayShifts[dayCounter].shifts.every(iShift => check.assigned(iShift.start) && check.assigned(iShift.end) && iShift.start !== iShift.end);
  }

  public validateDayShifts(dayCounter: number): void {
    const shiftsRanges: any[] = [];
    const shiftsCount = this.workScheduleTemplate.dayShifts[dayCounter].shifts.length;
    if (shiftsCount < 2) {
      this.validDayShifts[dayCounter] = true;
      this.validSave.emit(true);
    } else {
      this.workScheduleTemplate.dayShifts[dayCounter].shifts.forEach((shift) => {
        this.checkShifts(shift, shiftsRanges, dayCounter);
      });
    }
  }

  private checkShifts(
    shift: { start: number; end: number },
    shiftsRanges: any[],
    dayCounter: number
  ) {
    const isOvernightShift = shift.end < shift.start;
    for (let range of shiftsRanges) {
      const isRangeOvernight: boolean = range.end < range.start;
      if (
        ((isOvernightShift || isRangeOvernight) &&
          (shift.start < range.end || shift.end > range.start)) ||
        (shift.start >= range.start && shift.start < range.end) ||
        (shift.end > range.start && shift.end <= range.end)
      ) {
        this.validDayShifts[dayCounter] = false;
        this.validSave.emit(false);
      } else {
        this.validDayShifts[dayCounter] = true;
        this.validSave.emit(true);
      }
    }
    shiftsRanges.push({ start: shift.start, end: shift.end });
  }

  checkStartAndEnd(start: number, end: number): void {
    if (!start || !end) {
      this.validSave.emit(false);
    }
  }

  public calculateMinutes(): void {
    this.workScheduleTemplate.weeklyMinutes = 0;
    this.workScheduleTemplate.dayShifts.forEach((iDay, dayCounter) => {
      let dayShiftCounter = 0;
      if (this.workScheduleTemplate.type === pickLists.WORK_SCHEDULE_TEMPLATE_TYPE_FIXED) {
        iDay.shifts.forEach((shift) => {
          const start = shift.start ? shift.start : 0;
          let end = shift.end ? shift.end : 0;
          if (check.assigned(end) && check.number(end) && end < start) {
            end += 1440;
          }
          dayShiftCounter += start < end && check.assigned(shift.start) ? end - start : 0;
        });
        iDay.minutes = iDay.minutes >= 0 ? dayShiftCounter : iDay.minutes;
      }
      this.workScheduleTemplate.weeklyMinutes += iDay.minutes >= 0 ? iDay.minutes : 0;
      this.validateShifts(dayCounter);
    });
  }

  public resetTimeTable(): void {
    this.workScheduleTemplate.dayShifts.forEach((day) => {
      day.shifts = [{ start: 0, end: 0 }];
      day.minutes = check.assigned(day.minutes) ? 0 : day.minutes;
    });
    this.calculateMinutes();
  }
}
