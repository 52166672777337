import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PolicyTypeType } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { WorkTimeType } from '@app/cloud-features/time-off/services/time-off-type.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import {
  HOLIDAY_DURATION_AFTERNOON,
  HOLIDAY_DURATION_FULL_DAY,
  HOLIDAY_DURATION_MORNING,
  PART_OF_DAY_END,
  PART_OF_DAY_HALF,
  PART_OF_DAY_START,
  TIME_OFF_REQUEST_STATUS_APPROVED,
  TIME_OFF_REQUEST_STATUS_CANCELLED,
  TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED,
  TIME_OFF_REQUEST_STATUS_DECLINED,
  TIME_OFF_REQUEST_STATUS_IN_APPROVAL,
  TIME_OFF_REQUEST_STATUS_PENDING,
  TIME_OFF_REQUEST_STATUS_PROCESSED,
  TIME_OFF_REQUEST_STATUS_SUBMITTED,
  TIME_OFF_REQUEST_TYPE_REQUEST,
  TIME_OFF_REQUEST_TYPE_SUBMISSION
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TimeOffRequestService implements IGenericService {
  private TIME_OFF_REQUEST_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/time-off-request-db`;
  private TIME_OFF_REQUEST_PERMISSIONS_KEY: string = 'time-off-request';
  private TIME_OFF_REQUEST_INTERNATIONALIZATION: string = 'time-off-request-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private injector: Injector) {}

  create(data: ITimeOffRequestParamModel): Promise<ITimeOffRequestModel> {
    try {
      return this.genericService.create(this.TIME_OFF_REQUEST_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffRequestService.name, 'create');
    }
  }

  find(findBody: any): Promise<Array<ITimeOffRequestModel>> {
    try {
      return this.genericService.find(this.TIME_OFF_REQUEST_URL, findBody);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffRequestService.name, 'find');
    }
  }

  getById(id: string): Promise<ITimeOffRequestModel> {
    try {
      return this.genericService.getById(this.TIME_OFF_REQUEST_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffRequestService.name, 'getById');
    }
  }

  updateById(id: string, data: ITimeOffRequestModel): Promise<void> {
    try {
      return this.genericService.updateById(this.TIME_OFF_REQUEST_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, TimeOffRequestService.name, 'updateById');
    }
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, TimeOffRequestService.name, 'deleteById');
    error.message = 'TimeOffRequest should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);
    throw error;
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.TIME_OFF_REQUEST_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.TIME_OFF_REQUEST_INTERNATIONALIZATION);
  }

  async updateAttachments(requestId: string, createdDocumentIds: Array<string>): Promise<ITimeOffRequestModel> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_REQUEST_URL}`, ErrorCodes.UNAUTHORIZED, TimeOffRequestService.name, 'updateAttachments');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const body = {
        attachments: createdDocumentIds
      };
      return await this.injector.get(HttpClient).put<ITimeOffRequestModel>(`${this.TIME_OFF_REQUEST_URL}/${requestId}/attachments`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffRequestService.name, 'updateAttachments');
    }
  }

  async approveRequest(requestId: string): Promise<ITimeOffRequestModel> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_REQUEST_URL}`, ErrorCodes.UNAUTHORIZED, TimeOffRequestService.name, 'approveRequest');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).put<ITimeOffRequestModel>(`${this.TIME_OFF_REQUEST_URL}/${requestId}/approve`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffRequestService.name, 'approveRequest');
    }
  }

  async approveRequests(requestIds: Array<string>) {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_REQUEST_URL}`, ErrorCodes.UNAUTHORIZED, TimeOffRequestService.name, 'approveRequests');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      await this.injector.get(HttpClient).put(`${this.TIME_OFF_REQUEST_URL}/requests/approve`, { requests: requestIds }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffRequestService.name, 'approveRequests');
    }
  }

  async cancelRequest(requestId: string, description: string): Promise<ITimeOffRequestModel> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_REQUEST_URL}`, ErrorCodes.UNAUTHORIZED, TimeOffRequestService.name, 'cancelRequest');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).put<ITimeOffRequestModel>(`${this.TIME_OFF_REQUEST_URL}/${requestId}/cancel`, { description }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffRequestService.name, 'cancelRequest');
    }
  }

  async declineRequest(requestId: string, description: string): Promise<ITimeOffRequestModel> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_REQUEST_URL}`, ErrorCodes.UNAUTHORIZED, TimeOffRequestService.name, 'declineRequest');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).put<ITimeOffRequestModel>(`${this.TIME_OFF_REQUEST_URL}/${requestId}/decline`, { description }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffRequestService.name, 'declineRequest');
    }
  }

  async declineRequests(requestIds: Array<string>, description: string) {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.TIME_OFF_REQUEST_URL}`, ErrorCodes.UNAUTHORIZED, TimeOffRequestService.name, 'declineRequests');
        throw error;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      await this.injector.get(HttpClient).put(`${this.TIME_OFF_REQUEST_URL}/requests/decline`, { requests: requestIds, description }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffRequestService.name, 'declineRequests');
    }
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.TIME_OFF_REQUEST_URL);
  }
}

export interface ITimeOffRequestParamModel {
  _id?: string;
  _policyId: string;
  _userId: string;
  from: string;
  to: string;
  partOfDayFrom?: PartOfDayFromType;
  partOfDayTo?: PartOfDayToType;
  description: string;
  attachments?: Array<string>;
}

export interface ITimeOffRequestModel {
  _id: string;
  _userId: string;
  _timeOffTypeId: string;
  _timeOffTypeName: string;
  _timeOffTypeColor: string;
  _policyId: string;
  _policyType: PolicyTypeType;
  _policyName: string;
  _workTime?: WorkTimeType;
  _countNaturalDays?: boolean;
  _type: RequestTypeType;
  status: RequestStatusType;
  from: string;
  to: string;
  partOfDayFrom?: PartOfDayFromType;
  partOfDayTo?: PartOfDayToType;
  description?: string;
  attachments?: Array<string>;
  duration: number;
  workingTime: number;
  splitRequests?: Array<ISplitRequestModel>;
  _approvalRule: Array<IApprovalRuleModel>;
  _approvers: Array<string>;
  approvedBy: Array<IApprovedByModel>;
  dummyDate: string;
  ownerId: string;
  _updatedById: string;
  _createdById: string;
}

export interface ISplitRequestModel {
  _id: string;
  date: string;
  from?: string;
  to?: string;
  partOfDay: HolidayDurationType;
  workingTime: number;
  duration: number;
}

export interface IApprovalRuleModel {
  users: string;
  managerOfFirstApprover: string;
  company: string;
  office: string;
  area: string;
  department: string;
  team: string;
}

export interface IApprovedByModel {
  _userId: string;
  _approvedAt: string;
}
export type RequestTypeType = TIME_OFF_REQUEST_TYPE_SUBMISSION | TIME_OFF_REQUEST_TYPE_REQUEST;
export type RequestStatusType =
  | TIME_OFF_REQUEST_STATUS_APPROVED
  | TIME_OFF_REQUEST_STATUS_DECLINED
  | TIME_OFF_REQUEST_STATUS_CANCELLED
  | TIME_OFF_REQUEST_STATUS_PENDING
  | TIME_OFF_REQUEST_STATUS_SUBMITTED
  | TIME_OFF_REQUEST_STATUS_PROCESSED
  | TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED
  | TIME_OFF_REQUEST_STATUS_IN_APPROVAL;
export type HolidayDurationType = HOLIDAY_DURATION_MORNING | HOLIDAY_DURATION_AFTERNOON | HOLIDAY_DURATION_FULL_DAY;
export type PartOfDayFromType = PART_OF_DAY_START | PART_OF_DAY_HALF;
export type PartOfDayToType = PART_OF_DAY_END | PART_OF_DAY_HALF;
