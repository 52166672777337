import { Component, EventEmitter, HostBinding, Injector, Input, OnInit, Output } from '@angular/core';
import { getHolidayKey, ICalendarEvent, ICalendarGridItem } from '@app/standard/components/calendar-year/calendar-year.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-calendar-month',
  templateUrl: 'calendar-month.component.html',
  styleUrls: ['calendar-month.component.scss']
})
export class CalendarMonthComponent implements OnInit {
  @Output() clickOnDate: EventEmitter<ICalendarGridItem> = new EventEmitter<ICalendarGridItem>();

  _year: number;
  @Input()
  set year(yearIn: number) {
    this._year = yearIn;
    if (!this.initialLoadCompleted) {
      return;
    }
    this.calculateCalendar();
  }
  get year(): number {
    return this._year;
  }

  _month: number;
  @Input()
  set month(monthIn: number) {
    this._month = monthIn;
    if (!this.initialLoadCompleted) {
      return;
    }
    this.calculateCalendar();
  }
  get month(): number {
    return this._month;
  }

  @Input() mapKeyToCalendarEvent: { [yearMonthDay: string]: ICalendarEvent };

  @HostBinding("style.--offsetToStartTheMonth") offsetToStartTheMonth: number;

  startDate: Date;
  gridItemsForThisMonth: Array<ICalendarGridItem> = [];
  daysOfWeek: Array<Date> = []; // to be used in the columns to name the days of the week

  private initialLoadCompleted = false;
  private currentLocale: string;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.currentLocale = this.injector.get(InternationalizationService).getLocale();
    this.initDaysOfWeek();
    this.calculateCalendar();
    this.initialLoadCompleted = true;
  }

  private initDaysOfWeek(): void {
    const startOfWeek = moment().locale(this.currentLocale).startOf('week');
    this.daysOfWeek = [ startOfWeek.toDate() ];
    for (let i = 1; i < 7; i++) {
      this.daysOfWeek.push(startOfWeek.add(1, 'days').toDate());
    }
  }

  private calculateCalendar(year: number = this.year, month: number = this.month): void {
    this.startDate = new Date(this.year, this.month);

    this.offsetToStartTheMonth = moment(this.startDate).locale(this.currentLocale).startOf('month').weekday() + 1;
    this.gridItemsForThisMonth = Array(moment(this.startDate).locale(this.currentLocale).daysInMonth()).fill({}).map((value: any, index: number) => {
      const result: ICalendarGridItem = {
        year: this.year,
        month: this.month,
        day: index + 1,
        date: moment(Date.UTC(this.year, this.month, index+1)).utc().toDate(),
      };

      if (moment(result.date).isSame(moment(), 'date')) {
        result.isToday = true;
      }

      const keyForCalendarMap = getHolidayKey(result.date);
      if (this.mapKeyToCalendarEvent && this.mapKeyToCalendarEvent[keyForCalendarMap]) {
        result.calendarEvent = this.mapKeyToCalendarEvent[keyForCalendarMap];
      }

      return result;
    });
  }

  dateClick(calendarGridItem: ICalendarGridItem): void {
    if (calendarGridItem?.calendarEvent && !calendarGridItem.calendarEvent.isEditable) {
      return;
    }
    this.clickOnDate.emit(calendarGridItem);
  }
}