import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import * as check from 'check-types';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';
import { TimeEntry } from '../input-time-form-control/input-time-form-control.component';

@Component({
  selector: 'orgos-input-simple-time',
  templateUrl: 'input-simple-time.component.html',
  styleUrls: ['input-simple-time.component.scss']
})
export class InputSimpleTimeComponent implements OnInit {
  isValueValid: boolean = true;
  inputValue: TimeEntry;
  inputStringValue: string;
  showClearButton: boolean = false;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() enableClearButton: boolean = false;
  @Input()
  set value(value: number) {
    this.inputValue = this.convertToTimeEntry(value);
    this.inputStringValue = this.convertToString(value);
  }
  @Input() forceError: boolean = false;

  @Input() resetValue: TimeEntry = null;

  @Input() keepOriginalMinWidth: boolean = false;

  @Output() readonly valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() customValidation: InputValidationFunction;

  @Output() readonly validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Output() blurTimeInput: EventEmitter<void> = new EventEmitter<void>();
  @Output() cleanTimeInput: EventEmitter<void> = new EventEmitter<void>();

  @Input() maskPlaceholder: string | null = null;

  @Input() showClock: boolean = false;

  @Input() debounceNewValues: boolean = false;

  @Input() customMinWidth: number;

  @HostBinding('style.width.px') minWidth: number = 60;

  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.makeValidation(this.inputValue);
    this.showClearButton = this.enableClearButton && check.assigned(this.inputValue) && this.inputValue.hour !== '' && this.inputValue.minute !== '';
    this.minWidth = this.customMinWidth ? this.customMinWidth : !this.showClock ? (this.enableClearButton && !this.readOnly ? 60 : this.keepOriginalMinWidth ? 60 : 45) : undefined;
  }

  onValueChange(newValue: TimeEntry): void {
    if (check.not.assigned(newValue) || (newValue?.hour === '' && newValue?.minute === '')) {
      this.inputValue = null;
    } else {
      this.inputValue = newValue;
    }
    this.showClearButton = this.enableClearButton && check.assigned(this.inputValue) && this.inputValue.hour !== '' && this.inputValue.minute !== '';
    this.minWidth = this.customMinWidth ? this.customMinWidth : !this.showClock ? (this.enableClearButton && !this.readOnly ? 60 : this.keepOriginalMinWidth ? 60 : 45) : undefined;

    this.makeValidation(this.inputValue);

    if (this.isValueValid) {
      this.valueChange.emit(this.convertToMinutes(this.inputValue));
    }
  }

  onBlur(): void {
    this.blurTimeInput.emit();
  }

  private makeValidation(valueToValidate: TimeEntry): void {
    let valueToValidateInMinutes = null;

    if (check.assigned(valueToValidate) && (valueToValidate.hour === '' || valueToValidate.minute === '')) {
      valueToValidateInMinutes = -1;
    }

    if (check.assigned(valueToValidate) && valueToValidate.hour !== '' && valueToValidate.minute !== '') {
      valueToValidateInMinutes = this.convertToMinutes(valueToValidate);
    }

    const inputValidation = this.validateValue(valueToValidateInMinutes).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.not.number(newValue) || newValue === -1)) {
      inputValidation.setError('required');
    }

    if (check.assigned(newValue) && (newValue < 0 || newValue > 1439)) {
      inputValidation.setError('timeNotValid');
    }

    if (this.customValidation) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  clearValue(): void {
    if (this.resetValue !== null) {
      this.onValueChange(this.resetValue);
    } else {
      this.onValueChange(null);
    }
  }

  cleanError() {
    this.cleanTimeInput.emit();
  }

  convertToMinutes(timeEntryValue: TimeEntry): number {
    if (check.not.assigned(timeEntryValue)) {
      return null;
    }

    const hour = timeEntryValue.hour !== '' ? timeEntryValue.hour : '0';
    const minute = timeEntryValue.minute !== '' ? timeEntryValue.minute : '0';

    return parseInt(hour, 10) * 60 + parseInt(minute, 10);
  }

  convertToTimeEntry(minutesValue: number): TimeEntry {
    if (check.not.assigned(minutesValue)) {
      return undefined;
    }

    const hours = Math.floor((minutesValue / 60) % 24);
    const minutes = (((minutesValue / 60) % 24) - Math.floor((minutesValue / 60) % 24)) * 60;

    return new TimeEntry(`${this.decimalPipe.transform(hours, '2.0-0')}`, `${this.decimalPipe.transform(minutes, '2.0-0')}`);
  }

  convertToString(minutesValue: number): string {
    if (check.not.assigned(minutesValue)) {
      return undefined;
    }

    const hours = Math.floor((minutesValue / 60) % 24);
    const minutes = (((minutesValue / 60) % 24) - Math.floor((minutesValue / 60) % 24)) * 60;
    return `${this.decimalPipe.transform(hours, '2.0-0')}:${this.decimalPipe.transform(minutes, '2.0-0')}`;
  }
}
