import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IHeader } from '@app/standard/components/table/table.component';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { IDocumentModel } from '@app/standard/services/document/document.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as json2csv from 'json2csv';

@Injectable({
  providedIn: 'root',
})
export class CompanyDocReadTrackingDialogServiceService {
  private COMPANY_DOCS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/smart-docs/company-docs`;
  private DOCUMENT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/document-db`;

  private _readTrackingEmployees: Array<IReadTrackingEmployeeData> = [];
  public get readTrackingEmployees(): Array<IReadTrackingEmployeeData> {
    return this._readTrackingEmployees;
  }

  private _readTrackingEmployeesMetadata: { numberOfPages: number; filteredRecords: number; totalRecords: number } = {
    numberOfPages: 0,
    filteredRecords: 0,
    totalRecords: 0,
  };
  public get readTrackingEmployeesMetadata(): { numberOfPages: number; filteredRecords: number; totalRecords: number } {
    return this._readTrackingEmployeesMetadata;
  }

  constructor(private injector: Injector, private errorManager: ErrorManagerService) {}

  async loadEmployeeData(docId: string, queryOptions: any) {
    try {
      const response = await this.fetchEmployeeData(docId, queryOptions);
      this._readTrackingEmployees = response.data;
      this._readTrackingEmployeesMetadata = response.metadata;
    } catch (error) {
      this._readTrackingEmployees = [];
      throw this.errorManager.handleRawError(error, CompanyDocReadTrackingDialogServiceService.name, 'loadEmployeeData');
    }
  }

  private async fetchEmployeeData(docId: string, queryOptions: any): Promise<IReadTrackingEmployeeResponse> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    return await this.injector
      .get(HttpClient)
      .post<IReadTrackingEmployeeResponse>(`${this.COMPANY_DOCS_URL}/read-request-status/${docId}`, { queryOptions }, httpOptions)
      .toPromise();
  }

  async sendCompanyWideBulkRequestRead(document: IDocumentModel): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      const bulkRequestReadBody = {
        documentId: document._id,
      };
      await this.injector
        .get(HttpClient)
        .post(`${this.COMPANY_DOCS_URL}/bulk-read-request/${document._id}`, bulkRequestReadBody, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, CompanyDocReadTrackingDialogServiceService.name, 'sendCompanyWideBulkRequestRead');
    }
  }

  async sendIndividualRequest(employee: IReadTrackingEmployeeData, docId: string, docName: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      const requestReadQuery = {
        documentId: docId,
        documentName: docName,
        relatedToId: employee._id,
      };

      await this.injector.get(HttpClient).post(`${this.DOCUMENT_URL}/request-read`, requestReadQuery, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, CompanyDocReadTrackingDialogServiceService.name, 'sendIndividualRequest');
    }
  }

  // same endpoint handles requests and reminders
  async sendTargetedBulkRequest(employees: Array<IReadTrackingEmployeeData>, docId: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      const requestReadQuery = {
        documentId: docId,
        employees: employees.map((employee: IReadTrackingEmployeeData) => employee._id),
      };

      await this.injector
        .get(HttpClient)
        .post(`${this.COMPANY_DOCS_URL}/bulk-read-request/${docId}`, requestReadQuery, httpOptions)
        .toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, CompanyDocReadTrackingDialogServiceService.name, 'sendTargetedBulkRequest');
    }
  }

  async exportData(translations: any, docId: string, queryOptions: any, headerLabels: IHeader): Promise<void> {
    const unpaginatedQueryOptions = {
      sortOptions: queryOptions.sortOptions,
      searchTerm: queryOptions.searchTerm,
      filterOptions: queryOptions.filterOptions,
    };
    const unpaginatedData = await this.fetchEmployeeData(docId, unpaginatedQueryOptions);
    const humanReadableData: Array<any> = this.transformDataForExport(translations, unpaginatedData.data);
    const title = translations.dialogHeader.replace(' ', '-').toLowerCase();
    const fields = Object.keys(headerLabels).map((headerKey: string) => ({ value: headerKey, label: headerLabels[headerKey] }));
    const csv = json2csv.parse(humanReadableData, { fields: fields, withBOM: true });
    const blob = new Blob([csv], { type: 'text/csv' });
    FileSaver.saveAs(blob, `Kenjo-${title}.csv`);
  }

  private transformDataForExport(translations: any, data: Array<IReadTrackingEmployeeData>): Array<any> {
    const transformedTable = data.map((employee: IReadTrackingEmployeeData) => {
      return {
        displayName: employee.displayName,
        requestedStatus: check.assigned(employee.readBy.userId) ? translations.requestedChip : translations.notRequestedChip,
        progressStatus: check.assigned(employee.readBy.readDate) ? translations.progressReadChip : translations.progressNotReadChip,
        readDate: this.injector.get(DatePipe).transform(employee.readBy.readDate, 'short', 'UTC') ?? '',
        hasAccess: employee.hasAccess ? translations.hasAccessText : translations.hasNoAccessText,
      };
    });
    return transformedTable;
  }
}

export interface IReadTrackingEmployeeData {
  _id: string;
  email: string;
  displayName: string;
  language: string;
  _photo: { _url: string };
  readBy: { _id: string; userId: string; readDate: Date };
  hasAccess: boolean;
}

interface IReadTrackingEmployeeResponse {
  data: Array<IReadTrackingEmployeeData>;
  metadata: {
    numberOfPages: number;
    filteredRecords: number;
    totalRecords: number;
  };
}
