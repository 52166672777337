<orgos-container *ngIf="userValidation && pageTranslation" aspect="card" class="pvcc-card kenjo-mb-20px kenjo-mr-20px">
  <div
    [class.kenjo-color-disabled-background-c4c4c4]="(userValidation.status === WORK_SCHEDULE_STATUS_NON_WORKING_DAY || userValidation.status === WORK_SCHEDULE_STATUS_BANK_HOLIDAY) && !userValidation.hasConflict"
    [class.kenjo-color-success-background-00b72e]="userValidation.status === WORK_SCHEDULE_STATUS_LATE_CHECK_IN || (userValidation.conflictedStatus === WORK_SCHEDULE_STATUS_LATE_CHECK_IN && userValidation.hasConflict)"
    [class.kenjo-color-secondary-background-59e37c]="userValidation.status === WORK_SCHEDULE_STATUS_CHECKED_IN || (userValidation.conflictedStatus === WORK_SCHEDULE_STATUS_CHECKED_IN && userValidation.hasConflict)"
    [class.kenjo-color-primary-background-5993e3]="userValidation.status === WORK_SCHEDULE_STATUS_TIME_OFF && (!userValidation.hasConflict || userValidation.hasNotWorkingTimeOff)"
    [class.kenjo-color-danger-background-ff5757]="userValidation.status === WORK_SCHEDULE_STATUS_NOT_CHECKED"
    class="tos-color-bar pvcc-top-bar"
  ></div>
  <div class="kenjo-p-20px">
    <orgos-column-container>
      <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-cursor-pointer">
        <kenjo-icon *ngIf="userValidation.type === WORK_SCHEDULE_TEMPLATE_TYPE_FIXED" [matTooltip]="pageTranslation.tooltips.fixedSchedule" [size]="18">fixed</kenjo-icon>
        <kenjo-icon *ngIf="userValidation.type === WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE" [matTooltip]="pageTranslation.tooltips.flexibleSchedule" [size]="18">flex</kenjo-icon>
        <kenjo-icon *ngIf="userValidation.type === WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD" [matTooltip]="pageTranslation.tooltips.standardSchedule" [size]="18">standard</kenjo-icon>
      </orgos-column>
      <orgos-column>
        <div class="kenjo-text-align-center pvcc-pos-relative-container">
          <orgos-avatar
            [avatarName]="userValidation.displayName"
            [photoUrl]="userValidation._photo?._url"
            [class.pvcc-standard-avatar]="userValidation.status !== WORK_SCHEDULE_STATUS_NON_WORKING_DAY && userValidation.status !== WORK_SCHEDULE_STATUS_BANK_HOLIDAY && userValidation.status !== WORK_SCHEDULE_STATUS_TIME_OFF"
          ></orgos-avatar>
        </div>
      </orgos-column>
      <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-cursor-pointer">
        <kenjo-icon *ngIf="userValidation.status === WORK_SCHEDULE_STATUS_NON_WORKING_DAY" [size]="18" [matTooltip]="pageTranslation.tooltips.nonWorkingDay">non_working_day</kenjo-icon>
        <kenjo-icon *ngIf="userValidation.status === WORK_SCHEDULE_STATUS_BANK_HOLIDAY" [size]="18" [matTooltip]="pageTranslation.tooltips.publicHoliday">public_hol</kenjo-icon>
        <kenjo-icon *ngIf="userValidation.status === WORK_SCHEDULE_STATUS_TIME_OFF" [size]="18" [matTooltip]="pageTranslation.tooltips.timeOff">time_off_menu</kenjo-icon>
      </orgos-column>
    </orgos-column-container>
    <div class="kenjo-text-align-center kenjo-mt-10px">
      <kenjo-truncate-text>
        {{ userValidation.displayName }}
      </kenjo-truncate-text>
    </div>
    <div class="kenjo-text-align-center kenjo-mt-5px kenjo-font-color-light-text-757575">
      <kenjo-truncate-text>
        {{ userValidation.jobTitle }}
      </kenjo-truncate-text>
    </div>
    <div [class.kenjo-mt-40px]="!userValidation.jobTitle" class="kenjo-text-align-center kenjo-mt-20px kenjo-font-size-12px">
      <span class="kenjo-font-color-light-text-757575"> {{ pageTranslation.in | uppercase }} </span>
      <span [class.kenjo-font-color-disabled-c4c4c4]="!userValidation.formattedAttendanceStartTime">{{ userValidation.formattedAttendanceStartTime ? userValidation.formattedAttendanceStartTime : pageTranslation.notStarted }}</span>
      <kenjo-icon
        [size]="10"
        *ngIf="userValidation.status === WORK_SCHEDULE_STATUS_LATE_CHECK_IN || userValidation.conflictedStatus === WORK_SCHEDULE_STATUS_LATE_CHECK_IN"
        [matTooltip]="pageTranslation.tooltips.lateCheckIn | i18nData: { min: userValidation.minLate }"
        class="kenjo-ml-5px kenjo-font-color-light-text-757575 kenjo-cursor-pointer"
        >late</kenjo-icon
      >
    </div>
  </div>
</orgos-container>
