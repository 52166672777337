import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { IShiftplanWishDay, ShiftPlanWishDayType } from '@app/cloud-features/shift-plan/services/shift-plan-wish-day.service';
import { computeWishDay } from '@app/cloud-features/shift-plan/helpers/shiftplan-wish-day.helper';
import { SHIFT_PLAN_AVAILABILITY } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

const fadeInOut = trigger('fadeInOutAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
]);

@Component({
  selector: 'kenjo-shiftplan-wish-day',
  templateUrl: 'shiftplan-wish-day.component.html',
  styleUrls: ['shiftplan-wish-day.component.scss'],
  animations: [fadeInOut],
})
export class ShiftplanWishDayComponent implements OnInit, AfterViewInit {
  wishDay: { time: { start: string; end: string } } | null;
  label: { show: boolean; text: string } = { show: false, text: '' };
  _date: moment.Moment;
  WISH_DAY_COLORS = { Availability: '#eef4fc', Unavailability: '#fff2eb' };
  VIEWS = { ...VIEWS };
  SHIFT_PLAN_AVAILABILITY = SHIFT_PLAN_AVAILABILITY;

  @Input() userWishDays: Array<IShiftplanWishDay>;
  @Input() componentTranslation: Record<string, string>;
  @Input() view: 'week' | 'month';
  @Input() isMinimal: boolean = false;
  @Input() wishDayType: ShiftPlanWishDayType;
  @Input() emptyShifts: boolean = false;

  @Input()
  set date(value: moment.Moment) {
    this._date = value;
    this.initWishDay();
  }
  get date() {
    return this._date;
  }

  @ViewChild('wishDayContainer', { static: false }) wishDayContainer: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.initWishDay();
  }

  initWishDay() {
    if (check.not.assigned(this.date) || check.emptyArray(this.userWishDays)) {
      return;
    }
    this.wishDay = computeWishDay(this.date, this.userWishDays);
  }

  ngAfterViewInit() {
    if (check.assigned(this.wishDay) && check.assigned(this.wishDayContainer)) {
      this.updateWishDayUI();
    }
  }

  updateWishDayUI() {
    const $calendarCell = this.wishDayContainer.nativeElement.closest('.sspp-open-shift');
    if (check.not.assigned($calendarCell)) {
      return;
    }
    $calendarCell.style.backgroundColor = this.WISH_DAY_COLORS[this.wishDayType];

    const $addShiftBox = $calendarCell.querySelector('.add-shift');
    if (check.assigned($addShiftBox)) {
      $addShiftBox.style.minHeight = this.view === VIEWS.WEEK ? 'unset' : '20px';
    }

    const mappedTranslation = {
      Availability: this.componentTranslation.available,
      Unavailability: this.componentTranslation.notAvailable,
    };

    if (this.view === VIEWS.WEEK) {
      this.label = { show: true, text: mappedTranslation[this.wishDayType] };
    }

    if (this.view === VIEWS.MONTH) {
      this.label = { show: check.not.assigned(this.wishDay.time), text: mappedTranslation[this.wishDayType] };
    }
    this.cdr.detectChanges();
  }
}
