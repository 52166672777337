import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Injector, Input, Output } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'kenjo-sticky-save-footer',
  templateUrl: 'sticky-save-footer.component.html',
  styleUrls: ['sticky-save-footer.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'hidden',
        style({
          transform: 'translateY(100%) scale(1, 0)',
        })
      ),
      state(
        'shown',
        style({
          transform: 'translateY(0%)',
        })
      ),
      transition('hidden <=> shown', [animate(250)]),
    ]),
  ],
})
export class StickySaveFooterComponent {
  screenWidth: string;

  @Input() translations: any;
  @Input() disabled: boolean = false;
  @Input() isShown: boolean = false;
  @Input() isDiscardConfirmationRequired: boolean = false;
  @Output() onChangeAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('window:resize')
  onResize() {
    this.screenWidth = this.getScreenWidth();
  }

  constructor(private injector: Injector) {
    this.screenWidth = this.getScreenWidth();
  }

  protected emitChangeAction(isSaveAction: boolean): void {
    if (!isSaveAction && this.isDiscardConfirmationRequired) {
      const confirmDialogRef = this.openConfirmDialog();

      confirmDialogRef.afterClosed().subscribe((discardChanges: boolean): void => {
        if (!discardChanges) {
          return;
        }

        this.onChangeAction.emit(!discardChanges);
      });

      return;
    }

    this.onChangeAction.emit(isSaveAction);
  }

  private openConfirmDialog(): MatLegacyDialogRef<ConfirmDialogComponent, any> {
    const dialogData: any = {
      titleText: this.translations.confirmDiscardDialogTitle,
      subtitleText: this.translations.confirmDiscardDialogDescription,
      confirmButtonText: this.translations.confirmDiscardChangesButton ?? this.translations.discardChangesButton,
      cancelButtonText: this.translations.cancelChangesButton,
      confirmButtonColor: 'Danger',
    };

    return this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
  }

  private getScreenWidth(): string {
    return `${window.innerWidth}px`;
  }
}
