<orgos-dialog-container [title]="titleText" (clickCloseButton)="closeDialog()" [attr.id]="componentId !== null ? componentId : null">
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 cld-white-space">
    {{ subtitleText }}
  </div>
  <div *ngIf="list" class="kenjo-font-color-light-text-757575 cld-white-space">
    <ul class="kenjo-pl-20px">
      <li *ngFor="let item of list" class="kenjo-mb-10px">{{ item }}</li>
    </ul>
  </div>

  <orgos-column-container class="kenjo-mt-40px cld-wrap-buttons">
    <orgos-column size="1" class="cld-empty-column"></orgos-column>
    <orgos-column *ngIf="cancelButtonText" size="0" class="cld-flex-shrink">
      <orgos-button-raised color="Neutral" (click)="closeDialog()" class="cld-close cld-flex-shrink">{{ cancelButtonText }}</orgos-button-raised>
    </orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="cld-flex-shrink">
      <orgos-button-raised [color]="confirmButtonColor" class="kenjo-ml-20px cld-confirm" (click)="confirmDialog()">
        {{ confirmButtonText }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
