import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TimeOffUserPolicyController } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { ISelectOption } from '@app/standard/core/select-option';
import {
  IUserEmploymentContractTypeModel,
  UserEmploymentContractTypeService,
} from '@app/standard/services/user/user-employment-contract-type.service';
import {
  IUserEmploymentSubcategoryModel,
  UserEmploymentSubcategoryService,
} from '@app/standard/services/user/user-employment-subcategory.service';
import * as check from 'check-types';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GenericCacheModel } from '../../../../core/generic-cache-model';
import { InputValidation } from '../../../../core/validation/input-validation';
import { CompanyService } from '../../../../services/company/company.service';
import { AuthenticationService } from '../../../../services/core/authentication.service';
import { InternationalizationService } from '../../../../services/core/internationalization.service';
import { UserEmploymentService } from '../../../../services/user/user-employment.service';
import { UserWorkScheduleService } from '../../../../services/user/user-work-schedule.service';
import { UserWorkService } from '../../../../services/user/user-work.service';

@Component({
  selector: 'orgos-add-employment-dialog',
  templateUrl: 'add-employment.dialog.html',
  styleUrls: ['add-employment.dialog.scss'],
})
export class AddEmploymentDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  employment: GenericCacheModel;
  minDate: Date;
  timeOffOptions: Array<ISelectOption> = [];
  userId: string;

  startDateValidation: InputValidation;
  contractValidation: InputValidation;
  typeValidation: InputValidation;
  hoursBeforeValidation: InputValidation;
  hoursAfterValidation: InputValidation;
  typeSickValidation: InputValidation;
  typeVacationValidation: InputValidation;

  companyOptions: Array<any> = [];
  canEditPast: boolean = false;
  weeklyHours: number;
  userWork: any;
  userWorkSchedule: any;

  userEmploymentContractTypes$: Observable<Array<ISelectOption>>;
  userEmploymentSubcategories$: Observable<Array<ISelectOption>>;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddEmploymentDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.canEditPast = this.data.canEditPast;
    this.userId = this.data.userId;

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-add-employment-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((dialogTranslation) => {
        this.miscTranslation = dialogTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    let myUserWork;

    const calls: Array<any> = [this.injector.get(UserWorkService).getById(loggedUser._id)];
    calls.push(this.injector.get(UserWorkService).getById(this.userId));
    calls.push(this.injector.get(UserWorkScheduleService).getById(this.userId));

    Promise.all(calls)
      .then((userWorks) => {
        myUserWork = userWorks[0];
        this.userWork = userWorks[1];
        this.userWorkSchedule = userWorks[2];
        this.getWeeklyHours();
        return this.injector.get(CompanyService).getCompanies();
      })
      .then((allCompanies) => {
        this.companyOptions = allCompanies.map((iCompany) => {
          const companyOption = {
            name: iCompany.name,
            value: iCompany._id,
          };
          return companyOption;
        });
        const myCompany = allCompanies.find((iCompany) => {
          return iCompany._id === myUserWork.companyId;
        });

        this.employment = new GenericCacheModel(
          this.injector,
          {
            startDate: undefined,
            contract: undefined,
            type: undefined,
            _companyId: myCompany._id,
            weeklyHoursBeforeShortTime: this.weeklyHours,
          },
          UserEmploymentService,
          this.data.userId
        );
      })
      .catch(() => {
        this.employment = new GenericCacheModel(
          this.injector,
          { startDate: undefined, contract: undefined, type: undefined },
          UserEmploymentService,
          this.data.userId
        );
      });

    this.initTimeOffOptions();

    // We can't use async pipe directly because input options is typed to ISelectOptions
    this.injector.get(UserEmploymentContractTypeService).loadActiveUserEmploymentContractTypes();
    this.userEmploymentContractTypes$ = this.injector
      .get(UserEmploymentContractTypeService)
      .activeUserEmploymentContractTypes.pipe(
        map((contractTypes: Array<IUserEmploymentContractTypeModel>) =>
          contractTypes.map((contractType: IUserEmploymentContractTypeModel) => ({ name: contractType.name, value: contractType._id }))
        )
      );

    this.injector.get(UserEmploymentSubcategoryService).loadActiveUserEmploymentSubcategories();
    this.userEmploymentSubcategories$ = this.injector
      .get(UserEmploymentSubcategoryService)
      .activeUserEmploymentSubcategories.pipe(
        map((subcategories: Array<IUserEmploymentSubcategoryModel>) =>
          subcategories.map((subcategory: IUserEmploymentSubcategoryModel) => ({ name: subcategory.name, value: subcategory._id }))
        )
      );
  }

  private async initTimeOffOptions() {
    try {
      const timeOffPolicies = await this.injector.get(TimeOffUserPolicyController).getUserPolicies(this.userId);
      if (check.not.nonEmptyArray(timeOffPolicies)) {
        this.timeOffOptions = [];
        return;
      }
      timeOffPolicies.forEach((policy) => {
        this.timeOffOptions.push({
          name: policy.name,
          value: policy.id,
        });
      });
    } catch {
      this.timeOffOptions = [];
    }
  }

  public recalculateRatio(): void {
    this.employment.data.ratioShortTime = 0;
    const weeklyHoursBeforeShortTime = this.employment.data.weeklyHoursBeforeShortTime;
    const weeklyHoursShortTime = this.employment.data.weeklyHoursShortTime;

    if (check.not.assigned(weeklyHoursBeforeShortTime) || check.not.assigned(weeklyHoursShortTime)) {
      return;
    }

    this.employment.data.ratioShortTime = Math.round((weeklyHoursShortTime / weeklyHoursBeforeShortTime + Number.EPSILON) * 100) / 100;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public addEmployment(): void {
    if (
      check.not.assigned(this.startDateValidation) ||
      this.startDateValidation.hasErrors() ||
      check.not.assigned(this.contractValidation) ||
      this.contractValidation.hasErrors() ||
      check.not.assigned(this.typeValidation) ||
      this.typeValidation.hasErrors()
    ) {
      return;
    }

    this.dialogRef.close(this.employment.data);
  }

  private getWeeklyHours(): void {
    this.weeklyHours = this.userWork.weeklyHours;

    if (check.not.assigned(this.userWorkSchedule.history) || check.emptyArray(this.userWorkSchedule.history)) {
      return;
    }

    let entryIndex = 0;
    const today = moment.utc();
    let currentWorkSchedule: any;
    while (entryIndex < this.userWorkSchedule.history.length) {
      currentWorkSchedule = this.userWorkSchedule.history[entryIndex];
      entryIndex = entryIndex + 1;
    }
    this.weeklyHours = currentWorkSchedule ? currentWorkSchedule.weeklyMinutes / 60 : 0;
  }
}
