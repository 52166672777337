import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Injector, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import {
  IAddEditNodeDialogData,
  OrgChartAddEditNodeDialog,
} from '@app/standard/pages/people/org-chart/components/dialogs/org-chart-add-edit-node-dialog/org-chart-add-edit-node.dialog';
import { OrgChartAddViewDialog } from '@app/standard/pages/people/org-chart/components/dialogs/org-chart-add-view-dialog/org-chart-add-view.dialog';
import { OrgChartDeleteNodeDialog } from '@app/standard/pages/people/org-chart/components/dialogs/org-chart-delete-node-dialog/org-chart-delete-node.dialog';
import { OrgChartRenameViewDialog } from '@app/standard/pages/people/org-chart/components/dialogs/org-chart-rename-view-dialog/org-chart-rename-view.dialog';
import { OrgChartComponent } from '@app/standard/pages/people/org-chart/components/org-chart/org-chart.component';
import { IAddOrgChartParams, INodeData, IOrgChart, OrgChartService } from '@app/standard/pages/people/org-chart/services/org-chart.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'org-chart-page',
  templateUrl: 'org-chart.page.html',
  styleUrls: ['org-chart.page.scss'],
})
export class OrgChartPage extends GenericPage {
  protected profilePermissionsResources: Array<string> = ['org-chart-feature', 'settings'];
  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'org-chart-page' },
    { name: 'menu', translationKey: 'people-menu' },
    { name: 'misc', translationKey: 'misc' },
  ];

  get availableOrgChartList(): Array<ISelectOption> {
    return this.standardMasterOrgChart.concat(this.standardCompanyOrgCharts, this.orgChartCustomViews);
  }

  get showSearchResultsMessage(): boolean {
    return !this.searchFailed && this.searchCount > 0 && this.orgChartSearchTerms.length >= this.SEARCH_CHARACTERS_MIN_NUMBER;
  }

  ORG_CHART_SELECTED_PREFERENCE_KEY: string = 'org-chart-selected-view';
  standardMasterOrgChart: Array<ISelectOption> = [];
  standardCompanyOrgCharts: Array<ISelectOption> = [];
  orgChartCustomViews: Array<ISelectOption> = [];
  viewsOpened: boolean = false;
  selectedView: string;
  loadingOrgChartView: boolean = false;
  enabledEditMode: boolean = false;
  orgChartTree = null;
  svgPosition: d3.ZoomTransform = null;

  // Org chart search
  orgChartSearchTerms: string = '';
  SEARCH_CHARACTERS_MIN_NUMBER: number = 2;
  searchFailed: boolean = false;
  searchCount: number = 0;

  @ViewChild('orgChart') orgChartComponent: OrgChartComponent;

  constructor(
    protected injector: Injector,
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected location: Location
  ) {
    super(injector, cdr, router, route, location);
  }

  protected async configureGlobalBar(): Promise<void> {
    try {
      this.globalBarConfig.pageName = this.i18n.page.pageName;
      const options: Array<IMenuOption> = [
        {
          name: this.i18n.menu.directoryTab,
          onClick: () => {
            this.router.navigate(['../directory'], { relativeTo: this.route });
          },
        },
      ];

      if (this.injector.get(CloudRoutesService).checkRoute('people/org-chart') === true) {
        options.push({
          name: this.i18n.menu.orgChartTab,
          onClick: () => {
            this.router.navigateByUrl('/cloud/people/org-chart');
          },
        });
      }

      const optionIndex = _.findIndex(options, ['name', this.i18n.menu.orgChartTab]);
      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
    } catch (error) {
      throw error;
    }
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    const getOrgChartSelectedView = this.injector.get(PreferenceService).getPreferenceByKey(this.ORG_CHART_SELECTED_PREFERENCE_KEY);
    const getOrgCharts = this.injector.get(OrgChartService).getOrgCharts();
    try {
      const [orgChartSelectedView, customOrgCharts]: [{ preference: string }, Array<IOrgChart>] = await Promise.all([
        getOrgChartSelectedView,
        getOrgCharts,
      ]);
      this.orgChartCustomViews = customOrgCharts.map((iOrgChart) => ({ name: iOrgChart.name, value: iOrgChart._id }));
      this.standardMasterOrgChart = [{ name: this.i18n.page.standardOrgChart, value: 'masterOrgChart' }];
      const orgChartRoute: string =
        this.availableOrgChartList.find((orgCharView: ISelectOption) => orgCharView.value === orgChartSelectedView.preference)?.value ??
        'masterOrgChart';
      this.renderOrgChartView(orgChartRoute);
      resolveFetchData();
    } catch (error) {
      rejectFetchData();
    }
  }

  protected afterInit(): Promise<void> {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view org chart', { platform: 'Web', category: 'People', subcategory1: 'OrgChart' });
    this.injector.get(PrivateAmplitudeService).logEvent('view people page', { category: 'Navigation', type: 'org chart' });
    return Promise.resolve();
  }

  public renderOrgChartView(orgChartRoute: string): void {
    // clear search data when changing charts
    this.orgChartSearchTerms = '';
    this.searchFailed = false;
    this.searchCount = 0;

    this.svgPosition = orgChartRoute === this.selectedView ? this.getSvgPosition() : null;
    this.loadingOrgChartView = true;
    this.cdr.detectChanges();
    this.selectedView = orgChartRoute;
    this.injector
      .get(OrgChartService)
      .getOrgChartTree(this.selectedView)
      .then((orgChartTree: any) => {
        this.orgChartTree = orgChartTree;
        this.loadingOrgChartView = false;
        this.cdr.detectChanges();
        return this.injector.get(PreferenceService).setPreferenceByKey(this.ORG_CHART_SELECTED_PREFERENCE_KEY, orgChartRoute);
      })
      .then(() => {
        // Do nothing
      })
      .catch(() => {
        // Do nothing, an error is already shown
      });
  }

  public addOrgChartView(): void {
    const data = {
      availableOrgChartList: this.availableOrgChartList,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(OrgChartAddViewDialog, { data: data });
    dialogRef.afterClosed().subscribe((addOrgChartInfo: IAddOrgChartParams) => {
      if (check.not.assigned(addOrgChartInfo)) {
        return;
      }
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('add org chart', { platform: 'Web', category: 'People', subcategory1: 'OrgChart' });

      this.injector
        .get(OrgChartService)
        .addOrgChart(addOrgChartInfo)
        .then((addedOrgChart: IOrgChart) => {
          this.orgChartCustomViews = [
            ...this.orgChartCustomViews,
            {
              name: addedOrgChart.name,
              value: addedOrgChart._id,
            },
          ];
          this.editOrgChartView(addedOrgChart._id);
        })
        .catch(() => {
          // Do nothing, an error is already shown
        });
    });
  }

  public renameOrgChartView(viewId: string, viewName: string): void {
    const data = {
      name: viewName,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(OrgChartRenameViewDialog, { data: data });
    dialogRef.afterClosed().subscribe((newViewName: string) => {
      if (check.not.assigned(newViewName)) {
        return;
      }

      this.injector
        .get(OrgChartService)
        .renameOrgChart(viewId, newViewName)
        .then(() => {
          const view = _.find(this.orgChartCustomViews, ['value', viewId]);
          if (check.assigned(view)) {
            view.name = newViewName;
          }
        })
        .catch(() => {
          // Do nothing, an error is already shown
        });
    });
  }

  public editOrgChartView(viewId: string): void {
    this.viewsOpened = false;
    this.enabledEditMode = true;
    this.renderOrgChartView(viewId);
  }

  public exitEditMode(): void {
    this.enabledEditMode = false;
    this.renderOrgChartView(this.selectedView);
  }

  public deleteOrgChartView(viewId: string): void {
    const data = {
      titleText: this.i18n.page.removeViewDialog.title,
      subtitleText: this.i18n.page.removeViewDialog.subtitle,
      confirmButtonText: this.i18n.page.removeViewDialog.removeButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.misc.goBackButtonDialog,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirmDeletion: boolean) => {
      if (check.not.assigned(confirmDeletion) || confirmDeletion === false) {
        return;
      }

      this.injector
        .get(OrgChartService)
        .deleteOrgChart(viewId)
        .then(() => {
          this.orgChartCustomViews = _.reject(this.orgChartCustomViews, ['value', viewId]);

          if (viewId === this.selectedView) {
            this.renderOrgChartView('masterOrgChart');
          }
        })
        .catch(() => {
          // Do nothing, an error is already shown
        });
    });
  }

  public addFirstNode(): void {
    const data: IAddEditNodeDialogData = {
      mode: 'addFirst',
      nodeInfo: {
        nodeType: 'Employee',
        color: 'USER_COLOR_1',
      },
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(OrgChartAddEditNodeDialog, { data: data });
    dialogRef.afterClosed().subscribe((newNodeData: INodeData) => {
      if (check.not.assigned(newNodeData)) {
        return;
      }

      this.injector
        .get(OrgChartService)
        .addFirstNode(this.selectedView, newNodeData)
        .then(() => {
          this.editOrgChartView(this.selectedView);
        })
        .catch(() => {
          // Do nothing, an error is already shown
        });
    });
  }

  public addNode(addNodeOperation: any): void {
    const data: IAddEditNodeDialogData = {
      mode: 'add',
      nodeInfo: {
        nodeType: 'Employee',
        color: addNodeOperation.node.color,
      },
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(OrgChartAddEditNodeDialog, { data: data });
    dialogRef.afterClosed().subscribe((newNodeData: INodeData) => {
      if (check.not.assigned(newNodeData)) {
        return;
      }

      this.injector
        .get(OrgChartService)
        .addNode(this.selectedView, addNodeOperation.operation, addNodeOperation.node._id, newNodeData)
        .then(() => {
          this.renderOrgChartView(this.selectedView);
        })
        .catch(() => {
          // Do nothing, an error is already shown
        });
    });
  }

  public editNode(node: any): void {
    const data: IAddEditNodeDialogData = {
      mode: 'edit',
      nodeInfo: {
        nodeType: node.data.nodeType,
        employeeId: node.data.nodeType === 'Employee' || node.data.nodeType === 'Assistant' ? node.data.employeeId : null,
        companyId: node.data.nodeType === 'Company' ? node.data.companyId : null,
        departmentId: node.data.nodeType === 'Department' ? node.data.departmentId : null,
        areaId: node.data.nodeType === 'Area' ? node.data.areaId : null,
        teamId: node.data.nodeType === 'Team' ? node.data.teamId : null,
        officeId: node.data.nodeType === 'Office' ? node.data.officeId : null,
        futureRoleName: node.data.nodeType === 'FutureRole' ? node.data.futureRoleName : null,
        color: node.data.color,
      },
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(OrgChartAddEditNodeDialog, { data: data });
    dialogRef.afterClosed().subscribe((editedNodeData: INodeData) => {
      if (check.not.assigned(editedNodeData)) {
        return;
      }

      this.injector
        .get(OrgChartService)
        .editNode(this.selectedView, node.data._id, editedNodeData)
        .then(() => {
          this.renderOrgChartView(this.selectedView);
        })
        .catch(() => {
          // Do nothing, an error is already shown
        });
    });
  }

  public deleteNode(node: any): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(OrgChartDeleteNodeDialog);
    dialogRef.afterClosed().subscribe((deleteMode: 'onlyRole' | 'roleAndSubchart') => {
      if (check.not.assigned(deleteMode)) {
        return;
      }

      this.injector
        .get(OrgChartService)
        .deleteNode(this.selectedView, node.data._id, deleteMode)
        .then(() => {
          this.renderOrgChartView(this.selectedView);
        })
        .catch(() => {
          // Do nothing, an error is already shown
        });
    });
  }

  public getSvgPosition(): d3.ZoomTransform {
    if (check.not.assigned(this.orgChartComponent)) {
      return;
    }

    return this.orgChartComponent.getSvgPosition();
  }

  public exportOrgChartToSVG(): void {
    if (check.not.assigned(this.orgChartComponent)) {
      return;
    }
    this.injector.get(PrivateAmplitudeService).logEvent('export', { platform: 'Web', category: 'People', subcategory1: 'OrgChart' });
    this.orgChartComponent.exportToSVG();
  }

  public exportOrgChartToPDF(): void {
    if (check.not.assigned(this.orgChartComponent)) {
      return;
    }
    this.injector.get(PrivateAmplitudeService).logEvent('export', { platform: 'Web', category: 'People', subcategory1: 'OrgChart' });
    this.orgChartComponent.exportToPDF();
  }

  public changeView(): void {
    this.viewsOpened = this.viewsOpened === true ? false : true;
    if (this.viewsOpened) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('show org chart', { platform: 'Web', category: 'People', subcategory1: 'OrgChart' });
    }
  }

  showErrorMessage(failedSearch: boolean) {
    this.searchFailed = failedSearch;
    // prevents error: Expression has changed after it was checked
    this.cdr.detectChanges();
  }

  showSearchResults(searchCount: number) {
    this.searchCount = searchCount;
    // prevents error: Expression has changed after it was checked
    this.cdr.detectChanges();
  }
}
