import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { IAddOrgChartParams } from '@app/standard/pages/people/org-chart/services/org-chart.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-org-chart-add-view-dialog',
  templateUrl: 'org-chart-add-view.dialog.html',
  styleUrls: ['org-chart-add-view.dialog.scss'],
})
export class OrgChartAddViewDialog implements OnInit {
  i18n: any;
  baseOptions: Array<ISelectOption> = [];
  nameValidation: InputValidation;

  viewInfo: IAddOrgChartParams = {
    name: '',
    base: 'masterOrgChart',
  };

  constructor(
    public dialogRef: MatLegacyDialogRef<OrgChartAddViewDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('org-chart-page')
      .then((translation) => {
        this.i18n = translation;

        this.baseOptions = [
          {
            name: translation.addViewDialog.emptyBaseOption,
            value: 'empty',
          },
          ...this.data.availableOrgChartList,
        ];
      })
      .catch(() => {
        this.i18n = null;
        this.baseOptions = [];
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public addView(): void {
    if (!this.isDataValid()) {
      return;
    }
    this.dialogRef.close(this.viewInfo);
  }

  private isDataValid(): boolean {
    return check.assigned(this.nameValidation) && !this.nameValidation.hasErrors();
  }
}
