import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ICompensationParametersModel } from '@app/cloud-features/settings-attendance/models/compensation-parameters.model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as picklist from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-settings-shift-plan-approvals-dialog',
  templateUrl: 'settings-shift-plan-approvals-dialog.html',
  styleUrls: ['settings-shift-plan-approvals-dialog.scss'],
})
export class SettingsShiftPlanApprovalsDialog implements OnInit {
  oneYearAgo: moment.Moment = moment().subtract(1, 'years');
  loadedTranslations: boolean = false;
  MAX_WAIVER: number = 25;
  compensationParameters: ICompensationParametersModel = {
    trackOvertime: true,
    calculateSince: null,
    transferToNextMonth: false,
    allowUndertime: false,
    waiverActive: false,
    waiver: 0,
    waiverType: picklist.WAIVER_TYPE_MONTHLY,
    pendingToProcess: null,
  };
  waiverHour: any = {
    hours: 0,
    minutes: 0,
  };
  overrideSettingsChecks: any = {
    trackOvertime: false,
    calculateSince: false,
    transferToNextMonth: false,
    allowUndertime: false,
    waiverActive: false,
    waiver: false,
    waiverType: false,
  };
  previousWaiverType: string = picklist.WAIVER_TYPE_MONTHLY;
  dialogTranslation: any = {};
  selectedUsers: Array<any> = [];
  areUsersEqual: boolean;
  radioOptions = [];
  previousOvertimeSettings: ICompensationParametersModel;
  previousOvertimeSettingsChecks: ICompensationParametersModel;
  todayDate: moment.Moment = moment.utc();
  dependsOnTrackOvertime: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<SettingsShiftPlanApprovalsDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {
    this.selectedUsers = data.selectedUsers;
  }

  ngOnInit(): void {
    this.getDialogTranslation();
  }

  private async getDialogTranslation(): Promise<void> {
    try {
      const dialogTranslation = await this.injector
        .get(InternationalizationService)
        .getAllTranslation('shift-plan-settings-approvals-dialog');
      this.dialogTranslation = dialogTranslation;
      this.loadedTranslations = true;
      this.radioOptions = [
        { value: true, name: dialogTranslation.true },
        { value: false, name: dialogTranslation.false },
      ];
    } catch {
      this.dialogTranslation = {};
    }
  }
}
