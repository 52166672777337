<orgos-container aspect="card" class="kenjo-p-20px">
  <orgos-input-simple-editor #bodyQuillEditor [toolbar]="[]" [label]="i18n.writeComment" [(value)]="comment.comment" [readOnly]="creatingComment" required="true" theme="bubble" (validation)="commentValidation = $event" class="kenjo-block"></orgos-input-simple-editor>
  <div class="kenjo-mt-10px kenjo-text-align-right">
    <orgos-button-raised [disabled]="creatingComment || !comment.comment || !commentValidation || commentValidation.hasErrors()" (click)="createComment()">{{ creatingComment === false ? i18n.createCommentButton : i18n.creatingCommentButton }} </orgos-button-raised>
  </div>
  <div *ngFor="let iComment of userComments; let rowIndex = index" class="kenjo-mt-20px">
    <orgos-column-container centerColumns="true">
      <orgos-avatar orgosColumn="0" [avatarName]="iComment.displayName" [photoUrl]="iComment._photo?._url" size="tiny"> </orgos-avatar>
      <orgos-column size="0" class="kenjo-mh-10px kenjo-font-size-12px">{{ iComment.displayName }}</orgos-column>
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0" class="kenjo-font-size-12px kenjo-font-color-light-text-757575"> {{ iComment.createdAt | humanDate: false:'short' }}</orgos-column>
    </orgos-column-container>
    <div class="kenjo-mt-10px kenjo-p-10px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
      <orgos-comment-editor [commentOwnerId]="iComment.createdById" [editorContent]="iComment.comment" (updateComment)="saveComment($event, iComment.id)"></orgos-comment-editor>
    </div>
    <div *ngIf="rowIndex !== userComments.length - 1" class="kenjo-border-bottom kenjo-mb-20px kenjo-mt-20px"></div>
  </div>
</orgos-container>
