import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { InputValidationFunction } from '@app/standard/core/validation/input-validation-function';
import { IUserAccountModel, UserAccountService } from '@app/standard/services/user/user-account.service';
import * as check from 'check-types';

import { InputValidation } from '../../../../../core/validation/input-validation';
import { InternationalizationService } from '../../../../../services/core/internationalization.service';
import { ApiKeyService } from '../../../../../services/integrations/api-key.service';

@Component({
  selector: 'orgos-create-api-key',
  templateUrl: 'create-api-key.dialog.html',
  styleUrls: ['create-api-key.dialog.scss'],
})
export class CreateApiKeyDialog implements OnInit {
  pageTranslation: any = {};
  fieldLabelValidation: InputValidation;
  permissions: Array<any> = [
    { selected: false, label: 'Attendance', key: 'attendance' },
    { selected: false, label: 'Time-off', key: 'timeOff' },
    { selected: false, label: 'Compensation', key: 'compensation' },
    { selected: false, label: 'Document', key: 'document' }
  ];
  modulesCol1: Array<String> = ['Companies', 'Offices', 'Departments', 'Employees'];
  modulesCol2: Array<String> = ['Teams', 'Areas', 'Calendars', 'Custom fields'];

  apiKeyInfo: any = {
    alias: '',
    apiKey: '',
  };

  apiUserAccount: IUserAccountModel;
  apiKeyRegenerated: boolean = false;
  existingAliasValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<CreateApiKeyDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector,
    public snackBar: MatLegacySnackBar
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    // Edit API key
    if (check.assigned(this.data.id)) {
      this.editApiKey();
      return;
    }
    this.permissions.forEach((permission) => {
      permission.selected = true;
    });
    // Create new API key
    this.getApiKey();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Retrieve from back-end a valid api key when:
   * 1. Open for creation
   * 2. Click on re-generate on edit
   */
  async getApiKey() {
    this.apiKeyInfo.apiKey = await this.injector.get(ApiKeyService).getApiKey();
    this.apiKeyRegenerated = true;
  }

  private async getTranslations() {
    this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('api-key-preferences-page');
  }

  /**
   * Populate the dialog in edit mode
   */
  private async editApiKey() {
    const apiKeys = await this.injector.get(ApiKeyService).getApiKeyById(this.data.id);
    const userAccounts = await this.injector.get(UserAccountService).find({ apiKey: this.data.id, profileKey: 'api' });
    if ((userAccounts?.length > 0 ?? false) && (apiKeys?.length > 0 ?? false)) {
      this.apiUserAccount = userAccounts[0];
      this.apiKeyInfo.alias = apiKeys[0].alias;
      this.apiKeyInfo.apiKey = apiKeys[0]._maskedApiKey;
      this.permissions.forEach((permission) => {
        if (this.apiUserAccount.accessToModules?.length > 0 ?? false) {
          permission.selected = this.apiUserAccount.accessToModules.indexOf(permission.key) !== -1;
        }
      });
    }
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  /**
   * Different behaviors depending on if there is a creation or an edit
   */
  async saveApiKey() {
    if (check.not.assigned(this.existingAliasValidation) || this.existingAliasValidation.hasErrors()) {
      return;
    }

    const selectedModules = this.permissions.reduce((prev, curr) => {
      if (curr.selected === true) {
        prev.push(curr.key);
      }
      return prev;
    }, []);

    // Edit API key
    if (check.assigned(this.data.id)) {
      const updateApiKey = {
        alias: this.apiKeyInfo.alias,
        accessToModules: selectedModules,
      };

      // If the modules selection has changed then don't update the accessToModules
      if (selectedModules.length === this.apiUserAccount.accessToModules.length) {
        if (selectedModules.every((FormsModule) => this.apiUserAccount.accessToModules.indexOf(FormsModule) !== -1)) {
          delete updateApiKey.accessToModules;
        }
      }
      await this.injector.get(ApiKeyService).update(this.data.id, updateApiKey);

      // If the api key has been re-generated then call to the proper endpoint
      if (this.apiKeyRegenerated === true) {
        await this.injector.get(ApiKeyService).refresh(this.data.id, this.apiKeyInfo);
        this.snackBar.open(this.pageTranslation.confirmUpdateMessage, 'OK', { duration: 5000 });
        this.dialogRef.close(true);
        return;
      }

      this.snackBar.open(this.pageTranslation.confirmUpdateMessage, 'OK', { duration: 5000 });
      this.dialogRef.close(true);
      return;
    }
    // Create API key
    this.apiKeyInfo.accessToModules = selectedModules;
    await this.injector.get(ApiKeyService).create(this.apiKeyInfo);
    this.snackBar.open(this.pageTranslation.confirmCreateMessage, 'OK', { duration: 5000 });
    this.dialogRef.close(true);
  }

  public copyFieldToClipboard(): void {
    const valueToCopy = this.apiKeyInfo.apiKey;
    const el = document.createElement('textarea');
    el.value = valueToCopy;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    const snackMessage = this.pageTranslation.keyCopiedToClipboard;
    this.snackBar.open(`${snackMessage}`, 'OK', { duration: 5000 });
  }

  /**
   * Validates the 'alias' field
   * 1. Cannot be duplicated
   * 2. Cannot be empty
   * 3. Must be alphanumerical
   */
  existingAliasValidationFunction: InputValidationFunction = (value: any): InputValidation => {
    const inputValidation = new InputValidation();
    if (check.not.assigned(value) || check.emptyString(value)) {
      inputValidation.setError('empty');
    }
    if (this.data.aliasList.indexOf(value) !== -1) {
      inputValidation.setError('notValid');
    }
    return inputValidation;
  };
}
