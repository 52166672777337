import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IUniversalModel } from '@app/models/universal.model';
import { environment } from '@env';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserCompensationService } from './user-compensation.service';

@Injectable()
export class UserEmploymentSubcategoryService implements IGenericService {
  private USER_EMPLOYMENT_SUBCATEGORY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-employment-subcategory-db`;
  private USER_EMPLOYMENT_SUBCATEGORY_INTERNATIONALIZATION: string = 'user-employment-subcategory-collection';

  private $userEmploymentSubcategories = new BehaviorSubject<Array<IUserEmploymentSubcategoryModel>>([]);
  private $activeUserEmploymentSubcategories = new BehaviorSubject<Array<IUserEmploymentSubcategoryModel>>([]);

  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService, private userCompensationService: UserCompensationService) {}

  async create(data: object): Promise<IUserEmploymentSubcategoryModel> {
    try {
      return await this.genericService.create(this.USER_EMPLOYMENT_SUBCATEGORY_URL, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentSubcategoryService.name, 'create');
    }
  }

  async getById(id: string): Promise<IUserEmploymentSubcategoryModel> {
    try {
      return await this.genericService.getById(this.USER_EMPLOYMENT_SUBCATEGORY_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentSubcategoryService.name, 'getById');
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.USER_EMPLOYMENT_SUBCATEGORY_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentSubcategoryService.name, 'updateById');
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.USER_EMPLOYMENT_SUBCATEGORY_URL, id);
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserEmploymentSubcategoryService.name, 'deleteById');
    }
  }

  getPermissions(): Promise<object> {
    return this.userCompensationService.getPermissions();
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_EMPLOYMENT_SUBCATEGORY_INTERNATIONALIZATION);
  }

  /**
   * Fetches all user-employment-subcategory-db objects for this org, including their usage count
   * @returns observable with list (can be empty)
   */
  loadAllUserEmploymentSubcategories(): void {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };
    this.injector
      .get(HttpClient)
      .get<Array<IUserEmploymentSubcategoryModel>>(this.USER_EMPLOYMENT_SUBCATEGORY_URL, httpOptions)
      .subscribe(
        (subcategories: Array<IUserEmploymentSubcategoryModel>) => {
          this.setUserEmploymentSubcategories(subcategories);
          return;
        },
        (error: any) => this.errorManager.handleRawError(error, UserEmploymentSubcategoryService.name, 'loadAllUserEmploymentSubcategories')
      );
  }

  /**
   * Fetches ACTIVE user-employment-subcategory-db objects for this org, including their usage count
   * @returns observable with list (can be empty)
   */
  loadActiveUserEmploymentSubcategories(): void {
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders
    };
    this.injector
      .get(HttpClient)
      .get<Array<IUserEmploymentSubcategoryModel>>(`${this.USER_EMPLOYMENT_SUBCATEGORY_URL}/active`, httpOptions)
      .subscribe(
        (subcategories: Array<IUserEmploymentSubcategoryModel>) => {
          this.setActiveUserEmploymentSubcategories(subcategories);
          return;
        },
        (error: any) => this.errorManager.handleRawError(error, UserEmploymentSubcategoryService.name, 'loadAllUserEmploymentSubcategories')
      );
  }

  get userEmploymentSubcategories(): Observable<Array<IUserEmploymentSubcategoryModel>> {
    return this.$userEmploymentSubcategories.asObservable();
  }

  setUserEmploymentSubcategories(subcategories: Array<IUserEmploymentSubcategoryModel>): void {
    this.$userEmploymentSubcategories.next(subcategories);
  }

  get activeUserEmploymentSubcategories(): Observable<Array<IUserEmploymentSubcategoryModel>> {
    return this.$activeUserEmploymentSubcategories.asObservable();
  }
  setActiveUserEmploymentSubcategories(subcategories: Array<IUserEmploymentSubcategoryModel>) {
    this.$activeUserEmploymentSubcategories.next(subcategories);
  }
}

export interface IUserEmploymentSubcategoryModel extends IUniversalModel {
  name: string;
  isDefault: boolean;
  isActive: boolean;
  usageCount?: number;
}
