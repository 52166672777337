<!-- selector -->
<orgos-column-container centerColumns="true">
  <orgos-column *ngFor="let iValue of options; let index = index" [style.flex-grow]="index === options.length - 1 ? 0 : 1" (mouseenter)="mouseOverIndex = index" (mouseleave)="mouseOverIndex = -1">
    <orgos-column-container centerColumns="true" *ngIf="index < options.length - 1">
      <orgos-column
        [matTooltip]="index > 0 ? index + 1 + ' - ' + (isStandard ? translation.performanceReviewRating[index + 1] : options[index]) : ''"
        size="0"
        [class]="small ? 'ls-circle-small' : 'ls-circle'"
        [class.ls-editable]="!readOnly"
        [class.ls-selected-circle]="value >= index || (mouseOverIndex >= index && !value)"
        (click)="selectValue(index)"
      >
      </orgos-column>
      <orgos-column size="1" [class]="small ? 'ls-line-small' : 'ls-line'" [class.ls-selected-line]="value > index || (mouseOverIndex > index && !value)"></orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" *ngIf="index === options.length - 1">
      <orgos-column size="0" [class]="small ? 'ls-circle-small' : 'ls-circle'" [class.ls-editable]="!readOnly" [class.ls-selected-circle]="value >= index || (mouseOverIndex >= index && !value)" (click)="selectValue(index)"> </orgos-column>
    </orgos-column-container>
  </orgos-column>
</orgos-column-container>
<!-- END selector -->
<!-- limits value -->
<orgos-column-container *ngIf="showOptions" centerColumns="true" class="kenjo-pt-10px">
  <orgos-column size="1">
    <div type="SecondaryText" color="LightText" [tooltipPosition]="'below'">1 - {{ isStandard ? translation.performanceReviewRating['1'] : options[0] }}</div>
  </orgos-column>
  <orgos-column size="1"> </orgos-column>
  <orgos-column size="1">
    <div class="kenjo-text-align-right" type="SecondaryText" color="LightText" [tooltipPosition]="'below'">{{ options.length }} - {{ isStandard ? translation.performanceReviewRating[options.length] : options[options.length - 1] }}</div>
  </orgos-column>
</orgos-column-container>
<!-- END limits value -->
<!-- value -->
<orgos-column-container centerColumns="true" *ngIf="value >= 0" class="kenjo-mt-20px kenjo-p-20px ls-value-block">
  <orgos-column size="1">
    <div>{{ value + 1 }} - {{ isStandard ? translation.performanceReviewRating[value + 1] : options[value] }}</div>
  </orgos-column>
</orgos-column-container>
<!-- END value -->
