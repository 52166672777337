<ng-container *ngIf="!loadingPage">
  <div *ngIf="this.profilePermissions.goal && (this.profilePermissions.goal.create_all || this.profilePermissions.goal.create_own)" class="kenjo-text-align-right kenjo-mb-20px">
    <orgos-action icon="add_circle" iconSize="large" (click)="addGoal()">{{i18n.misc.addGoal}}</orgos-action>
  </div>
  <orgos-column-container *ngIf="allGoals && allGoals.length > 0">
    <orgos-column size="0" class="gp-filters-column kenjo-mr-20px">
      <!-- VIEWS -->
      <orgos-list-view [preferenceKey]="preferenceKey" [newView]="selectedView" (onDeleteView)="updateAfterViewChange($event)" (onChangeView)="updateAfterViewChange($event)"></orgos-list-view>
      <!-- NEW VIEW -->
      <orgos-action (click)="createView()" icon="add_circle" color="Primary" class="kenjo-mv-20px"> {{i18n.misc.saveView}}</orgos-action>
      <!-- FILTERS -->
      <orgos-container aspect="card">
        <orgos-container class="gp-search kenjo-ph-20px">
          <orgos-input-simple-text [label]="i18n.page.search" suffixIcon="search" (valueChange)="changeFilter('search', $event)"></orgos-input-simple-text>
        </orgos-container>
      </orgos-container>
      <orgos-container aspect="card">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>{{i18n.page.goalTypeFilter}}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox [label]="i18n.page.individualGoals" class="kenjo-mt-10px" (valueChange)="changeFilter('type-Individual', $event)" [value]="filters['type-Individual']"> </orgos-input-simple-checkbox>
          <orgos-input-simple-checkbox [label]="i18n.page.departmentGoals" class="kenjo-mt-10px" (valueChange)="changeFilter('type-Department', $event)" [value]="filters['type-Department']"> </orgos-input-simple-checkbox>
          <orgos-input-simple-checkbox [label]="i18n.page.brandGoals" class="kenjo-mt-10px" (valueChange)="changeFilter('type-Brand', $event)" [value]="filters['type-Brand']"> </orgos-input-simple-checkbox>
        </mat-expansion-panel>
        <mat-accordion displayMode="flat" multi="true">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.goalStatusFilter}}</mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div *ngFor="let value of statusValues">
                <orgos-input-simple-checkbox [label]="i18n.picklists.goalStatus[value]" class="kenjo-mt-10px" (valueChange)="changeFilter('status-'+value, $event)" [value]="filters['status-'+value]"> </orgos-input-simple-checkbox>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="showDepartments">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.goalDepartmentFilter}}</mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div *ngFor="let iDepartment of departments">
                <orgos-input-simple-checkbox [label]="iDepartment.name" class="kenjo-mt-10px" (valueChange)="changeFilter('department-'+iDepartment._id, $event)" [value]="filters['department-'+iDepartment._id]"></orgos-input-simple-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </orgos-container>
    </orgos-column>

    <orgos-column *ngIf="!filteredData || filteredData.length === 0" class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noGoalsText}}</div>
    </orgos-column>

    <orgos-column *ngIf="filteredData && filteredData.length > 0">
      <orgos-container *ngFor="let iGoal of filteredData; let last = last" aspect="card" class="gp-goal kenjo-cursor-pointer" [class.kenjo-mb-20px]="!last" (click)="goToGoalDetail(iGoal)">
        <orgos-column-container>
          <orgos-column size="0" class="bp-goal-tooltip" [matTooltip]="i18n.picklists.goalStatus[iGoal.status]" [style.background-color]="statusColorMap[iGoal.status]"> </orgos-column>
          <orgos-column class="kenjo-p-20px">
            <orgos-column-container class="kenjo-mb-20px" centerColumns="true">
              <orgos-column size="0" class="kenjo-mr-10px">
                <kenjo-icon *ngIf="iGoal.type === TYPE_INDIVIDUAL" [size]="20" [matTooltip]="i18n.picklists.goalType[iGoal.type]">person</kenjo-icon>
                <kenjo-icon *ngIf="iGoal.type === TYPE_DEPARTMENT && this.departmentsMap[iGoal.departmentId]" [size]="20" [matTooltip]="i18n.page.departmentGoal | i18nData:{departmentName: this.departmentsMap[iGoal.departmentId].name} "> department</kenjo-icon>
                <kenjo-icon *ngIf="iGoal.type === TYPE_BRAND" [size]="20" [matTooltip]="i18n.picklists.goalType[iGoal.type]">public</kenjo-icon>
              </orgos-column>
              <orgos-column>
                <kenjo-truncate-text class="kenjo-font-size-16px gp-goal-title kenjo-font-color-primary-5993e3"> {{iGoal.title}} </kenjo-truncate-text>
              </orgos-column>
              <orgos-column size="0" class="kenjo-mh-20px">
                <div class="gp-progress-bar" [matTooltip]="i18n.picklists.goalStatus[iGoal.status]">
                  <div [style.width.px]="iGoal.progress * 180 / 100" [style.background-color]="statusColorMap[iGoal.status]"></div>
                </div>
              </orgos-column>
              <orgos-column size="0" class="kenjo-font-size-16px" [style.color]="statusColorMap[iGoal.status]"> {{iGoal.progress | number:'1.0-0'}}% </orgos-column>
            </orgos-column-container>
            <div class="kenjo-mb-5px">{{i18n.page.owners}}</div>
            <orgos-column-container centerColumns="true">
              <orgos-column>
                <orgos-avatar *ngFor="let iOwner of iGoal.assignedUsers" size="tiny" [avatarName]="userPersonalMap[iOwner]?.displayName" [photoUrl]="userPersonalMap[iOwner]?._photo?._url" [matTooltip]="userPersonalMap[iOwner]?.displayName" matTooltipPosition="above" class="gp-avatar">
                </orgos-avatar>
                <div *ngIf="!iGoal.assignedUsers || iGoal.assignedUsers.length === 0" class="kenjo-font-color-light-text-757575">{{i18n.page.noOwners}}</div>
              </orgos-column>
              <orgos-column size="0" class="kenjo-ml-40px kenjo-mr-10px">
                <kenjo-icon [size]="20" [class.kenjo-font-color-danger-ff5757]="TODAY.isAfter(iGoal.dueDate, 'day')">schedule </kenjo-icon>
              </orgos-column>
              <orgos-column size="0">
                <div *ngIf="iGoal.dueDate" [class.kenjo-font-color-light-text-757575]="TODAY.isSameOrBefore(iGoal.dueDate, 'day')" [class.kenjo-font-color-danger-ff5757]="TODAY.isAfter(iGoal.dueDate, 'day')">{{iGoal.dueDate | utcDate | humanDate}}</div>
                <div *ngIf="!iGoal.dueDate" class="kenjo-font-color-light-text-757575">{{i18n.page.noDueDate}}</div>
              </orgos-column>
              <orgos-column *ngIf="iGoal.keyResults && iGoal.keyResults.length > 0" size="0" class="kenjo-ml-40px kenjo-mr-10px">
                <kenjo-icon [size]="20" *ngIf="getKeyResultData(iGoal).numberOfKeyResultsCompleted === getKeyResultData(iGoal).totalNumberOfKeyResults" class="kenjo-font-color-success-00b72e">check_circle</kenjo-icon>
                <kenjo-icon [size]="20" *ngIf="getKeyResultData(iGoal).numberOfKeyResultsCompleted !== getKeyResultData(iGoal).totalNumberOfKeyResults"> check_circle_outline</kenjo-icon>
              </orgos-column>
              <orgos-column
                *ngIf="iGoal.keyResults && iGoal.keyResults.length > 0"
                size="0"
                [class.kenjo-font-color-light-text-757575]="getKeyResultData(iGoal).numberOfKeyResultsCompleted !== getKeyResultData(iGoal).totalNumberOfKeyResults"
                [class.kenjo-font-color-success-00b72e]="getKeyResultData(iGoal).numberOfKeyResultsCompleted === getKeyResultData(iGoal).totalNumberOfKeyResults"
              >
                {{i18n.page.keyResults | i18nData:getKeyResultData(iGoal)}}
              </orgos-column>
              <orgos-column size="0" class="kenjo-ml-40px kenjo-mr-10px">
                <kenjo-icon [size]="20">mode_comment</kenjo-icon>
              </orgos-column>
              <orgos-column size="0" class="kenjo-font-color-light-text-757575">
                <div *ngIf="iGoal.updates && iGoal.updates.length > 0">{{i18n.page.updates | i18nData:iGoal}}</div>
                <div *ngIf="!iGoal.updates || iGoal.updates.length === 0" class="kenjo-font-color-light-text-757575">{{i18n.page.noUpdates}}</div>
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
        </orgos-column-container>
      </orgos-container>
    </orgos-column>
  </orgos-column-container>
  <div *ngIf="!allGoals || allGoals.length === 0" class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noDataGoals}}</div>
  </div>
</ng-container>
