import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggle } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { FeatureGatingService } from '@app/standard/components/feature-gating/services/feature-gating.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { CoreFeaturesService, IFeature } from '@app/standard/services/core-features/core-features.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as featureGatingConstants from '@carlos-orgos/orgos-utils/constants/feature-gating.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'orgos-feature-box',
  templateUrl: 'feature-box.component.html',
  styleUrls: ['feature-box.component.scss'],
})
export class FeatureBoxComponent implements OnInit {
  private _feature: IFeature;
  FEATURE_MAT_SLIDE_TOGGLE_ID: string = '';

  @Input() set feature(feature: IFeature) {
    this._feature = feature;
    this.checked = check.boolean(this.feature.isActive) ? this.feature.isActive : false;
    this.FEATURE_MAT_SLIDE_TOGGLE_ID = `${this._feature.appKey}-toggle`;
  }

  get feature() {
    return this._feature;
  }

  @Input() available: boolean = true;
  @Input() disabled: boolean = true;
  @Input() profileName: string;

  @Output() changeActivate: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatLegacySlideToggle) matSlide: MatLegacySlideToggle;

  pageTranslation: any = {};
  miscTranslation: any = {};
  appName: string;
  checked: boolean;
  entitlementRequiredToActivateFeature: string; // if this feature requires an entitlement to activate the app, the field entitlementRequiredToActivateFeature will be informed
  showEntitlementIcon: boolean = false;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    const getComponentTranslation = this.injector.get(InternationalizationService).getAllTranslation('feature-box-component');
    const getMiscTranslation = this.injector.get(InternationalizationService).getAllTranslation('misc');
    Promise.all([getComponentTranslation, getMiscTranslation])
      .then((translations) => {
        this.pageTranslation = translations[0];
        this.miscTranslation = translations[1];
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    if (this.feature.hideActivationToggle === true) {
      this.feature.isActive = true;
    }
    this.checked = check.boolean(this.feature.isActive) ? this.feature.isActive : false;

    this.evaluateIfEntitlementIsRequired();
  }

  // depending on the value of feature.appKey, the entitlement may be required or not
  private async evaluateIfEntitlementIsRequired(): Promise<void> {
    if (this.feature?.appKey === 'whistleblower') {
      this.entitlementRequiredToActivateFeature = featureGatingConstants.ENTITLEMENT_WB_ACTIVATION_ACCESS;
    }
    await this.evaluateIfEntitlementIconShouldBeShown();
  }

  private async evaluateIfEntitlementIconShouldBeShown(): Promise<void> {
    if (!this.entitlementRequiredToActivateFeature) {
      return;
    }
    const isEntitlementEnabled: boolean = await this.injector
      .get(FeatureGatingService)
      .validateEntitlementFromCache(this.entitlementRequiredToActivateFeature);
    this.showEntitlementIcon = !isEntitlementEnabled;
  }

  private async checkEntitlementValue(entitlementKey: featureGatingConstants.ENTITLEMENTS_LIST): Promise<boolean> {
    const isEntitled: boolean = await this.injector.get(FeatureGatingService).requireEntitlement(entitlementKey);
    return isEntitled;
  }

  public goToSettings(): void {
    if (this.disabled === true) {
      return;
    }

    if (this.feature?.settingsLink) {
      this.injector.get(Router).navigate([this.feature.settingsLink]);
      return;
    }

    this.injector.get(Router).navigate(['/cloud/settings', this.feature.appKey]);
  }

  public async changeActive(): Promise<void> {
    if (this.disabled === true || this.feature.hideActivationToggle === true) {
      return;
    }

    const previousValue = this.checked;
    this.matSlide.checked = this.checked;

    if (previousValue === false && this.entitlementRequiredToActivateFeature) {
      const isEntitled = await this.checkEntitlementValue(this.entitlementRequiredToActivateFeature);
      if (!isEntitled) {
        return;
      }
    }

    // send notification if needed
    if (previousValue === false && check.assigned(this.feature.needSupportNotification) && this.feature.needSupportNotification === true) {
      const data = {
        titleText: this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.needSupportMsg, { feature: this.miscTranslation[this.feature.appKey] }),
        confirmButtonText: this.pageTranslation.notifyBtn,
        confirmButtonColor: 'Success',
        cancelButtonText: this.miscTranslation.goBackButtonDialog,
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe((confirm: boolean) => {
        if (confirm === true) {
          this.feature.lastSupportNotificationDate = moment().toDate();
          this.injector
            .get(CoreFeaturesService)
            .sendSupportNotification(this.feature.appKey)
            .then(() => {
              this.injector.get(MatLegacySnackBar).open(this.pageTranslation.notificationConfirmMsg, 'OK', { duration: 5000 });
              return;
            })
            .catch(() => {
              this.feature.lastSupportNotificationDate = null;
            });
        }
      });
      return;
    }

    // oauth integration activation
    if (previousValue === false && this.feature.isOauth === true) {
      this.injector.get(Router).navigate(['/cloud/settings', this.feature.appKey]);
      return;
    }

    // app activation/deactivation & widget activation/deactivation & oauth integration deactivation
    if (previousValue === true) {
      // active => inactive
      let titleText;
      let subtitleText;
      if (this.feature.isHomeWidget === true) {
        titleText = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.deactivateConfirmTitleForWidgets, { widget: this.miscTranslation[this.feature.appKey] });
        subtitleText = this.pageTranslation.deactivateConfirmMsgForWidgets;
      } else if (this.feature.isIntegration === true) {
        titleText = this.injector.get(I18nDataPipe).transform(this.pageTranslation.deactivateConfirmTitleForIntegrations, {
          integration: this.miscTranslation[this.feature.appKey],
        });
        subtitleText = this.pageTranslation.deactivateConfirmMsgForIntegrations;
      } else {
        titleText = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.deactivateConfirmTitle, { feature: this.miscTranslation[this.feature.appKey] });
        subtitleText =
          this.feature?.appKey && this.pageTranslation[`deactivateConfirmMsg__${this.feature.appKey}`]
            ? this.pageTranslation[`deactivateConfirmMsg__${this.feature.appKey}`]
            : this.pageTranslation.deactivateConfirmMsg;
      }
      const data = {
        titleText: titleText,
        subtitleText: subtitleText,
        confirmButtonText: this.pageTranslation.deactivateBtn,
        confirmButtonColor: 'Danger',
        cancelButtonText: this.miscTranslation.goBackButtonDialog,
        componentId: this.checked === true && this.feature.appKey === 'shift-plan' ? 'shiftplan-deactivation' : null,
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
      dialogRef.afterClosed().subscribe((confirm: boolean) => {
        if (confirm === true) {
          if (this.feature.isParent) {
            this.injector
              .get(CoreFeaturesService)
              .getAllWidgets()
              .then((widgets) => {
                const childWidgets = this.getChildWidgets(this.feature._id, widgets);
                childWidgets.forEach((iChildWidget) => {
                  iChildWidget.isActive = !previousValue;
                  this.changeActivate.emit(iChildWidget);
                });
              })
              .then(() => {
                this.changeFeatureStatus(previousValue);
              });
          }
          this.changeFeatureStatus(previousValue);

          // amplitude event when feature is deactivated
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent(`Deactivate ${this.feature.appKey}`, { category: 'Settings', type: this.feature.appKey });
        }
        return;
      });
    } else {
      // inactive => active
      if (this.feature.isParent) {
        this.injector
          .get(CoreFeaturesService)
          .getAllWidgets()
          .then((widgets) => {
            const childWidgets = this.getChildWidgets(this.feature._id, widgets);
            const defaultWidget = childWidgets.find((iWidget) => {
              return iWidget.isDefault && iWidget.isDefault === true;
            });
            defaultWidget.isActive = !previousValue;
            this.changeActivate.emit(defaultWidget);
          })
          .then(() => {
            this.changeFeatureStatus(previousValue);
          });
      } else {
        this.changeFeatureStatus(previousValue);
      }

      // amplitude event when feature is activated
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent(`Activate ${this.feature.appKey}`, { category: 'Settings', type: this.feature.appKey });
    }
  }

  private getChildWidgets(parentWidgetId: string, allWidgets: Array<IFeature>) {
    return allWidgets.filter((iWidget) => {
      return iWidget.parentSettings === parentWidgetId;
    });
  }

  private changeFeatureStatus(previousValue: boolean): void {
    this.feature.isActive = !previousValue;
    this.checked = !previousValue;
    this.changeActivate.emit(this.feature);
  }
}
