<orgos-container *ngIf="dataLoaded" class="atd-dialog-box kenjo-p-40px">
  <orgos-column-container centerColumns="true" class="kenjo-mb-30px">
    <orgos-column class="atd-employee-header">
      <div class="kenjo-font-size-20px">{{editMode ? pageTranslation.editTemplateTitle : pageTranslation.addTemplateTitle }}</div>
    </orgos-column>
    <div
      *ngIf="editMode"
      class="atd-icon-container kenjo-cursor-pointer"
      [class.container-disabled]="loading || deletePermission"
      orgosColumn="0"
    >
      <kenjo-icon
        [size]="24"
        [disabled]="deletePermission"
        (click)="removeTemplate()"
        class="kenjo-font-color-danger-ff5757"
        [class.kenjo-font-color-disabled-c4c4c4]="loading || deletePermission"
        >delete</kenjo-icon
      >
    </div>
    <kenjo-icon
      orgosColumn="0"
      [size]="24"
      (click)="closeDialog()"
      class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      [disabled]="loading"
      >clear</kenjo-icon
    >
  </orgos-column-container>

  <orgos-column-container [class.atd-opacity-disabled]="loading">
    <orgos-input-text
      orgosColumn="0"
      class="kenjo-mr-10px"
      [required]="true"
      [readOnly]="loading"
      [label]="pageTranslation.templateName"
      [model]="template"
      field="name"
      [minlength]="3"
      [maxlength]="51"
      (modelChange)="setEditTouched()"
      (validation)="nameValidation = $event; checkTemplateReady()"
      (currentValue)="template.data.name = $event"
    >
      <orgos-input-error *ngIf="nameValidation?.getError('required')"> {{pageTranslation.nameRequired}}</orgos-input-error>
      <orgos-input-error *ngIf="!nameValidation?.getError('required') && nameValidation?.getError('minlength')">
        {{pageTranslation.templateMinLength}}
      </orgos-input-error>
      <orgos-input-error *ngIf="!nameValidation?.getError('required') && nameValidation?.getError('maxlength')">
        {{pageTranslation.templateMaxLength}}
      </orgos-input-error>
    </orgos-input-text>
    <orgos-column size="2"></orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-pt-20px" [class.atd-opacity-disabled]="loading">
    <orgos-input-simple-time
      orgosColumn
      class="kenjo-mr-10px"
      [label]="pageTranslation.startTime"
      [required]="true"
      [readOnly]="loading"
      [(value)]="template.data.start"
      (valueChange)="calculateShiftDuration(); setEditTouched(); checkTemplateReady()"
      [showClock]="true"
      [forceError]="hasDurationMinErrors || hasDurationMaxErrors"
      (validation)="startValidation = $event; checkTemplateReady()"
      (blurTimeInput)="startTouched = true"
    >
    </orgos-input-simple-time>
    <orgos-input-simple-time
      orgosColumn
      class="kenjo-mr-10px kenjo-ml-10px"
      [label]="pageTranslation.endTime"
      [required]="true"
      [readOnly]="loading"
      [(value)]="template.data.end"
      (valueChange)="calculateShiftDuration(); checkTemplateReady(); setEditTouched()"
      [showClock]="true"
      [forceError]="hasDurationMinErrors || hasDurationMaxErrors"
      (validation)="endValidation = $event; checkTemplateReady()"
      (blurTimeInput)="endTouched = true"
    >
    </orgos-input-simple-time>
    <orgos-input-simple-time
      orgosColumn
      [readOnly]="loading"
      class="kenjo-ml-10px"
      [label]="pageTranslation.break"
      [(value)]="template.data.break"
      (valueChange)="calculateShiftDuration(); checkTemplateReady(); setEditTouched()"
      [showClock]="true"
      [forceError]="hasBreakErrors"
    ></orgos-input-simple-time>
  </orgos-column-container>
  <orgos-column-container [class.atd-opacity-disabled]="loading">
    <orgos-column class="kenjo-mr-10px">
      <orgos-input-error *ngIf="startTouched && startValidation && startValidation.getError('required')">
        {{pageTranslation.startRequired}}</orgos-input-error
      >
    </orgos-column>
    <orgos-column class="kenjo-mr-10px kenjo-ml-10px">
      <orgos-input-error *ngIf="endTouched && endValidation && endValidation.getError('required')">
        {{pageTranslation.endRequired}}</orgos-input-error
      >
      <ng-container *ngIf="hasDurationMinErrors || hasDurationMaxErrors">
        <orgos-input-error *ngIf="hasDurationMinErrors">{{pageTranslation.minimumShiftDuration}} </orgos-input-error>
        <orgos-input-error *ngIf="hasDurationMaxErrors">{{pageTranslation.maximumShiftDuration}} </orgos-input-error>
      </ng-container>
    </orgos-column>
    <orgos-column class="kenjo-ml-10px">
      <orgos-input-error *ngIf="hasBreakErrors"> {{pageTranslation.maximumBreakDuration}} </orgos-input-error>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-30px" [class.atd-opacity-disabled]="loading">
    <div orgosColumn="0" class="kenjo-font-color-light-text-757575">
      {{breaktimeDeduction ? (pageTranslation.totalDurationIncludingBreaks | uppercase ) : (pageTranslation.totalDurationExcludingBreaks |
      uppercase)}}
    </div>
    <div orgosColumn="0" class="kenjo-ml-10px">
      {{shiftDuration.hours}}{{pageTranslation.hour}} {{shiftDuration.minutes}}{{pageTranslation.minutes}}
    </div>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-30px" [class.atd-opacity-disabled]="loading">
    <mat-form-field *ngIf="locations?.length === 0" orgosColumn>
      <mat-label kenjoRemoveAriaOwns>{{ pageTranslation.location }}</mat-label>
      <mat-select required>
        <mat-option>
          <div class="atd-button-option" *ngIf="isUserAdmin; else userNotAdmin">
            <div (click)="navigateToOfficeLocation();$event.stopPropagation()">
              <div class="atd-navigate-to-header kenjo-mt-10px">{{ pageTranslation.createNewLocation }}</div>
              <div class="atd-navigate-to-remember kenjo-mb-10px">{{ pageTranslation.rememberToEnableShiftplan }}</div>
            </div>
          </div>
          <ng-template #userNotAdmin>
            <div class="atd-button-option" (click)="$event.stopPropagation()">
              <div class="atd-navigate-to-header kenjo-mt-10px kenjo-font-color-danger-ff5757">
                {{pageTranslation.noLocationsAvailable }}
              </div>
              <div class="atd-navigate-to-remember kenjo-mb-10px kenjo-font-color-light-text-757575">
                {{ pageTranslation.noLocationsAvailableText }}
              </div>
            </div>
          </ng-template>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <orgos-input-select
      *ngIf="locations?.length > 0"
      (modelChange)="setEditTouched()"
      (validation)="locationValidation = $event; checkTemplateReady()"
      (currentValue)="template.data.locationId = $event"
      class="kenjo-pr-10px"
      [required]="true"
      [readOnly]="loading"
      orgosColumn
      [label]="pageTranslation.location"
      [model]="template"
      field="locationId"
      [enableClearButton]="true"
      [options]="locations"
      [sortOptions]="false"
      [id]="'atd-location-id'"
    >
      <orgos-input-error>{{pageTranslation.locationRequired}}</orgos-input-error>
    </orgos-input-select>
    <orgos-input-select
      (modelChange)="setEditTouched()"
      class="kenjo-pl-10px"
      orgosColumn
      [label]="pageTranslation.workingArea"
      [model]="template"
      field="workingAreaId"
      [enableClearButton]="true"
      [options]="workingAreas"
      [sortOptions]="false"
      [id]="'atd-working-area-id'"
    ></orgos-input-select>
  </orgos-column-container>
  <orgos-column-container class="kenjo-mt-20px" [class.atd-opacity-disabled]="loading">
    <mat-form-field *ngIf="roles?.length === 0" class="kenjo-pr-10px" orgosColumn>
      <mat-label kenjoRemoveAriaOwns>{{ pageTranslation.role }}</mat-label>
      <mat-select required>
        <mat-option [value]="navigateToRoles">
          <div class="atd-button-option kenjo-font-size-14px" (click)="navigateTo(SHIFT_PLAN_SETTINGS_ROLES_PATH)">
            {{ pageTranslation.createNewRole }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <orgos-input-select
      *ngIf="roles?.length > 0"
      (modelChange)="setEditTouched()"
      (validation)="roleValidation = $event; checkTemplateReady()"
      (currentValue)="template.data.roleId = $event"
      class="kenjo-pr-10px"
      [required]="true"
      [readOnly]="loading"
      orgosColumn
      [label]="pageTranslation.role"
      [model]="template"
      field="roleId"
      [enableClearButton]="true"
      [options]="roles"
      [sortOptions]="false"
      [id]="'atd-role-id'"
    >
      <orgos-input-error>{{pageTranslation.roleRequired}}</orgos-input-error>
    </orgos-input-select>
    <orgos-input-select
      (modelChange)="setEditTouched()"
      class="kenjo-pl-10px"
      orgosColumn
      [readOnly]="loading"
      [label]="pageTranslation.tag"
      [model]="template"
      field="tagId"
      [enableClearButton]="true"
      [options]="tags"
      [sortOptions]="false"
      [id]="'atd-tag-id'"
    ></orgos-input-select>
  </orgos-column-container>

  <orgos-column-container [class.atd-opacity-disabled]="loading">
    <orgos-input-text-area
      orgosColumn
      rows="2"
      [readOnly]="loading"
      [model]="template"
      field="notes"
      (modelChange)="setEditTouched()"
      [label]="pageTranslation.notes"
      [placeholder]="pageTranslation.notesPlaceholder"
      [id]="'atd-notes'"
    ></orgos-input-text-area>
  </orgos-column-container>

  <orgos-column-container [class.atd-opacity-disabled]="loading">
    <orgos-input-select
      (modelChange)="setEditTouched(); setEditApprovalRule()"
      orgosColumn
      [readOnly]="loading"
      [label]="pageTranslation.openShiftAssignment"
      [model]="template"
      field="approvalRule"
      [enableClearButton]="true"
      [options]="approvalRuleOptions"
      [sortOptions]="false"
    ></orgos-input-select>
  </orgos-column-container>

  <orgos-column-container *ngIf="loading" class="atd-loading-container" centerColumns="true">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <div orgosColumn="0" class="kenjo-pl-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">
      {{ deleting ? (pageTranslation.deleting | uppercase ) : (pageTranslation.saving | uppercase) }}
    </div>
  </orgos-column-container>

  <orgos-column-container *ngIf="!loading" class="kenjo-mt-60px" centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised [id]="'atd-back-button'" [disabled]="loading" (click)="closeDialog()" color="Neutral">
        {{pageTranslation.backButton}}
      </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised
        [id]="'atd-save-button'"
        *ngIf="!editMode && !loading"
        [disabled]="!isTemplateReady || loading"
        (click)="createTemplate()"
        color="Success"
      >
        {{pageTranslation.saveTemplateButton}}
      </orgos-button-raised>
      <orgos-button-raised
        [id]="'atd-edit-button'"
        *ngIf="editMode"
        [disabled]="(!(isTemplateReady && editDataTouched) || loading)"
        (click)="editTemplate()"
        color="Success"
      >
        {{pageTranslation.saveButton}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
