import { Injectable, Injector } from '@angular/core';
import { ITimeOffApproverModel, TimeOffUserRequestController } from '@app/cloud-features/time-off/services/time-off-user-request.controller';
import * as check from 'check-types';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApproverTimeOffService {
  private approversCache: Map<string, Promise<any>> = new Map<string, Promise<any>>();
  private cacheTime: Date;

  constructor(private injector: Injector) {}

  async getApprover(userId: string): Promise<ITimeOffApproverModel> {
    let approver = await this.approversCache.get(userId);

    if (check.not.assigned(approver) || moment.utc().add(15, 'minutes').isAfter(this.cacheTime)) {
      approver = await this.injector.get(TimeOffUserRequestController).getDelegatedApprover(userId);
      this.approversCache.set(userId, approver);
      this.cacheTime = moment().toDate();
    }

    return approver;
  }
}
