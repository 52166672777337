import { Injectable, Injector } from '@angular/core';
import { PrivateUppyService } from '@app/private/services/private-uppy.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { environment } from '@env';
import { UppyOptions } from '@uppy/core';
import { ImageEditorOptions } from '@uppy/image-editor';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class UppyHelperService {
  constructor(private injector: Injector) {}

  async uploadGenericDocument(options?: IUploadGenericDocumentOptions): Promise<IFileMetadata> {
    try {
      const acceptedFileTypes = options?.onlySignable ? ['.pdf', '.docx', '.doc'] : ['.pdf', '.ppt', '.pptx', '.xls', '.xlsx', '.doc', '.docx', '.rtf', '.odt', '.ods', '.odp', '.txt', '.png', '.jpeg', '.jpg', '.webp'];
      const maxSize = (options?.maxDocSize ?? parseInt(environment.MAX_SIZE_FOR_DOCUMENTS, 10)) * 1024 * 1024;
      const pickerOptions: UppyOptions = {
        allowMultipleUploadBatches: false,
        autoProceed: true,
        restrictions: {
          allowedFileTypes: acceptedFileTypes,
          maxFileSize: maxSize,
          maxNumberOfFiles: 1
        }
      };
      const allFiles = await this.injector.get(PrivateUppyService).picker(pickerOptions, false);
      if (check.not.assigned(allFiles) || check.emptyArray(allFiles)) {
        return null;
      }
      return allFiles;
    } catch {}
  }

  async uploadGenericDocuments(options?: IUploadGenericDocumentOptions): Promise<Array<IFileMetadata>> {
    try {
      const acceptedFileTypes = ['.pdf', '.ppt', '.pptx', '.xls', '.xlsx', '.doc', '.docx', '.rtf', '.odt', '.ods', '.odp', '.txt', '.png', '.jpeg', '.jpg', '.webp'];
      const maxSize = (options?.maxDocSize ?? parseInt(environment.MAX_SIZE_FOR_DOCUMENTS, 10)) * 1024 * 1024;
      const pickerOptions: UppyOptions = {
        allowMultipleUploadBatches: false,
        autoProceed: true,
        restrictions: {
          allowedFileTypes: acceptedFileTypes,
          maxFileSize: maxSize,
          maxNumberOfFiles: options.maxNumberOfFiles
        }
      };
      const allFiles = await this.injector.get(PrivateUppyService).multipleFilesPicker(pickerOptions, false);
      if (check.not.assigned(allFiles) || check.emptyArray(allFiles)) {
        return null;
      }
      return allFiles;
    } catch {}
  }

  async uploadGenericPicture(uploadPictureOptions?: IUploadPictureOptions, uploadWithPermanentUrlMetadata: boolean = false, onClose = () => {}): Promise<IFileMetadata | null> {
    try {
      const pickerOptions: UppyOptions = {
        allowMultipleUploadBatches: false,
        autoProceed: check.not.assigned(uploadPictureOptions?.makeTransformations) || uploadPictureOptions.makeTransformations !== true,
        restrictions: {
          allowedFileTypes: ['image/*', '.jpg', '.png', '.jpeg'],
          maxFileSize: parseInt(environment.MAX_SIZE_FOR_IMAGES, 10) * 1024 * 1024,
          maxNumberOfFiles: 1
        }
      };

      const allFiles = await this.injector.get(PrivateUppyService).picker(pickerOptions, true, uploadPictureOptions, uploadWithPermanentUrlMetadata, onClose);
      if (check.not.assigned(allFiles) || check.emptyArray(allFiles)) {
        return null;
      }
      return allFiles;
    } catch {
      // Do nothing
    }
  }
}
export interface IUploadPictureOptions {
  makeTransformations?: boolean;
  imageEditorOptions?: ImageEditorOptions;
}

export interface IUploadGenericDocumentOptions {
  onlySignable?: boolean;
  maxDocSize?: number;
  maxNumberOfFiles?: number;
}
