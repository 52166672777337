<orgos-dialog-container [title]="titleText" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-text [label]="descriptionLabel" [(value)]="description" [maxlength]="300" class="kenjo-block">
  </orgos-input-simple-text>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ backLabel }}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Danger" (click)="cancelTimeOffRequest()">{{ cancelLabel }}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>