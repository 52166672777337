<div class="" *ngIf="pageLoaded && i18n.page">
  <!-- SPECIFIC FILTERS -->
  <div class="pvp-wrapper">
  <orgos-column-container class="kenjo-mb-30px">
    <orgos-column>
      <orgos-column-container centerColumns="true" class="kenjo-border kenjo-color-white-background-ffffff kenjo-mr-120px">
        <orgos-column class="kenjo-border-right kenjo-ph-20px kenjo-pt-10px">
          <orgos-input-simple-select [(value)]="filters.type" picklist="workSchedulesTypes" [label]="i18n.page.scheduleFilterLabel"></orgos-input-simple-select>
        </orgos-column>
        <orgos-column class="kenjo-border-right kenjo-ph-20px kenjo-pt-10px">
          <orgos-input-simple-date-picker [(value)]="filters.date" [label]="i18n.page.dateFilterLabel" (valueChange)="adjustTime()" [max]="maxDate"> </orgos-input-simple-date-picker>
        </orgos-column>
        <orgos-column class="kenjo-border-right kenjo-ph-20px kenjo-pt-10px">
          <orgos-input-simple-time #timeFilter [(value)]="filters.time" [label]="i18n.page.timeFilterLabel" (blurTimeInput)="adjustTime()" class="pvp-input-width"></orgos-input-simple-time>
        </orgos-column>
        <orgos-column class="kenjo-pl-20px">
          <div class="kenjo-border-right kenjo-pv-15px">
            <kenjo-filter-bar-field [options]="statusFilter.options" [label]="statusFilter.label" [hasBorder]="false" (toggleFilterEvent)="toggleFilter($event);" (clearFilterEvent)="clearAllCustomFilters()" [optionKey]="statusFilter.key"></kenjo-filter-bar-field>
          </div>
        </orgos-column>
        <orgos-column class="kenjo-text-align-center">
          <orgos-button-raised (click)="amplitudeEventRefresh(); refreshAllData()">{{ i18n.page.refreshButton }}</orgos-button-raised>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="0">
      <kenjo-icon *ngIf="isGridSelected" [size]="28" [matTooltip]="i18n.page.listView" class="kenjo-mr-10px kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="isGridSelected = !isGridSelected">list_icon</kenjo-icon>
      <kenjo-icon *ngIf="!isGridSelected" [size]="28" [matTooltip]="i18n.page.gridView" class="kenjo-mr-10px kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="isGridSelected = !isGridSelected">grid_icon</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <orgos-action [class.pvp-list-filters]="filtersOpen" (click)="filtersOpen = !filtersOpen" class="kenjo-mb-30px">{{filtersOpen ? i18n.page.hideFilters : i18n.page.showFilters}} </orgos-action>

  <orgos-column-container>
    <!-- FILTERS -->
    <orgos-column size="0" *ngIf="filtersOpen" class="pvp-list-filters kenjo-mr-20px">
      <!-- SEARCH AND FILTERS -->
      <orgos-container aspect="card">
        <orgos-container class="pvp-list-search kenjo-ph-20px">
          <orgos-input-simple-text [label]="i18n.page.search" suffixIcon="search" [(value)]="searchTerm" (valueChange)="changeStandardFilters()"></orgos-input-simple-text>
        </orgos-container>
      </orgos-container>
      <mat-accordion displayMode="flat" multi="true">
        <mat-expansion-panel *ngIf="allCompanies && allCompanies.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ i18n.page.companyFilter }}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox *ngFor="let iCompany of allCompanies" (valueChange)="changeStandardFilters(iCompany._id, $event, 'companyId')" [value]="standardFilters.companyId.includes(iCompany._id)" [label]="iCompany.name" class="kenjo-block kenjo-mb-10px"></orgos-input-simple-checkbox>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="allOffices && allOffices.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ i18n.page.officeFilter }}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox *ngFor="let iOffice of allOffices" (valueChange)="changeStandardFilters(iOffice._id, $event, 'officeId')" [value]="standardFilters.officeId.includes(iOffice._id)" [label]="iOffice.name" class="kenjo-block kenjo-mb-10px"></orgos-input-simple-checkbox>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="allAreas && allAreas.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ i18n.page.areaFilter }}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox *ngFor="let iArea of allAreas" (valueChange)="changeStandardFilters(iArea._id, $event, 'areaId')" [value]="standardFilters.areaId.includes(iArea._id)" [label]="iArea.name" class="kenjo-block kenjo-mb-10px"></orgos-input-simple-checkbox>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="allDepartments && allDepartments.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ i18n.page.departmentFilter }}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox
            *ngFor="let iDepartment of allDepartments"
            (valueChange)="changeStandardFilters(iDepartment._id, $event, 'departmentId')"
            [value]="standardFilters.departmentId.includes(iDepartment._id)"
            [label]="iDepartment.name"
            class="kenjo-block kenjo-mb-10px"
          ></orgos-input-simple-checkbox>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-expansion-panel *ngIf="allTeams && allTeams.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ i18n.page.teamFilter }}</mat-panel-title>
        </mat-expansion-panel-header>
        <orgos-input-simple-checkbox *ngFor="let iTeam of allTeams" (valueChange)="changeStandardFilters(iTeam._id, $event, 'teamId')" [value]="standardFilters.teamId.includes(iTeam._id)" [label]="iTeam.name" class="kenjo-block kenjo-mb-10px"></orgos-input-simple-checkbox>
      </mat-expansion-panel>
    </orgos-column>

    <orgos-column size="1">
      <div class="kenjo-font-size-18px kenjo-mb-30px">{{ i18n.page.summaryCalculationText | i18nData: { date: selectedDate, hour: selectedTime } }}</div>
      <!-- GRID -->
      <div *ngIf="isGridSelected" class="pvp-grid-container">
        <!-- If still loading -->
        <orgos-container *ngIf="!dataLoaded" class="pvp-grid-spinner-container kenjo-text-align-center">
          <div class="pvp-grid-loading-spinner kenjo-p-20px">
            <div>
              <orgos-loading-spinner></orgos-loading-spinner>
              <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-mt-20px">{{ i18n.page.loadingDataMessage }}</div>
            </div>
          </div>
        </orgos-container>

        <!-- No data -->
        <div *ngIf="dataLoaded && allPresenceValidationProcessedAndFiltered.length === 0" class="pvp-empty-state kenjo-p-20px">
          <div class="kenjo-text-align-center kenjo-mv-80px">
            <img src="/assets/images/emptyState.svg" />
            <div class="kenjo-mt-20px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.noUsersMessage}}</div>
          </div>
        </div>

        <!-- Cards -->
        <ng-container *ngIf="dataLoaded && allPresenceValidationProcessedAndFiltered.length > 0">
          <kenjo-presence-validation-card *ngFor="let userValidation of allPresenceValidationProcessedAndFiltered" [pageTranslation]="i18n.page" [userValidation]="userValidation"></kenjo-presence-validation-card>
        </ng-container>
      </div>

      <!-- TABLE -->
      <ng-container *ngIf="!isGridSelected">
        <orgos-container aspect="card" [class]="filtersOpen ? 'pvp-open-list-data-container' : 'pvp-list-data-container'" class="pvp-table-container">
          <!-- If still loading -->
          <orgos-container *ngIf="!dataLoaded" class="pvp-list-spinner-container">
            <div class="pvp-list-loading-spinner kenjo-p-20px">
              <div>
                <orgos-loading-spinner></orgos-loading-spinner>
                <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-mt-20px">{{ i18n.page.loadingDataMessage }}</div>
              </div>
            </div>
          </orgos-container>

          <!-- No data -->
          <orgos-container *ngIf="dataLoaded && allPresenceValidationProcessedAndFiltered && allPresenceValidationProcessedAndFiltered.length === 0">
            <orgos-column-container centerColumns="true" class="kenjo-text-align-center kenjo-pt-40px kenjo-pb-40px">
              <orgos-column size="1"></orgos-column>
              <orgos-column size="4">
                <div class="kenjo-font-size-16px">
                  <kenjo-icon [size]="16" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
                  {{ i18n.page.noEmployeesTitle }}
                </div>
                <div class="kenjo-pt-20px kenjo-font-color-light-text-757575">{{ i18n.page.noEmployeesSubtitle }}</div>
              </orgos-column>
              <orgos-column size="1"></orgos-column>
            </orgos-column-container>
          </orgos-container>

          <!-- List -->
          <orgos-table *ngIf="dataLoaded && allPresenceValidationProcessedAndFiltered && allPresenceValidationProcessedAndFiltered.length > 0" [customHeightToSubstract]="allPresenceValidationProcessedAndFiltered.length > 25 ? 391 : 143" [data]="allPresenceValidationProcessedAndFiltered" [displayedColumns]="['displayName', 'icon', 'type', 'checkInHour', 'status']">
            <orgos-table-column columnKey="displayName" [header]="i18n.page.employeeLabel" cellWidth="305px">
              <orgos-column-container centerColumns="true" *orgosTableCell="let row">
                <orgos-column size="0">
                  <orgos-avatar size="tiny" [avatarName]="row.displayName" [photoUrl]="row._photo?._url"></orgos-avatar>
                </orgos-column>
                <orgos-column class="kenjo-ml-10px" size="1">
                  <kenjo-truncate-text>{{ row.displayName }}</kenjo-truncate-text>
                </orgos-column>
              </orgos-column-container>
            </orgos-table-column>
            <orgos-table-column columnKey="icon" cellWidth="70px">
              <div *orgosTableCell="let row" class="kenjo-font-color-light-text-757575 kenjo-cursor-pointer">
                <kenjo-icon *ngIf="row.status === WORK_SCHEDULE_STATUS_NON_WORKING_DAY" [size]="18" [matTooltip]="i18n.page.tooltips.nonWorkingDay">non_working_day</kenjo-icon>
                <kenjo-icon *ngIf="row.status === WORK_SCHEDULE_STATUS_BANK_HOLIDAY" [size]="18" [matTooltip]="i18n.page.tooltips.publicHoliday">public_hol</kenjo-icon>
                <kenjo-icon *ngIf="row.status === WORK_SCHEDULE_STATUS_TIME_OFF" [size]="18" [matTooltip]="i18n.page.tooltips.timeOff">time_off_menu</kenjo-icon>
              </div>
            </orgos-table-column>
            <orgos-table-column columnKey="type" [header]="i18n.page.scheduleLabel" cellWidth="200px">
              <orgos-column-container centerColumns="true" *orgosTableCell="let row">
                <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-cursor-pointer">
                  <kenjo-icon *ngIf="row.type === WORK_SCHEDULE_TEMPLATE_TYPE_FIXED" [size]="18" [matTooltip]="">fixed</kenjo-icon>
                  <kenjo-icon *ngIf="row.type === WORK_SCHEDULE_TEMPLATE_TYPE_FLEXIBLE" [size]="18" [matTooltip]="">flex</kenjo-icon>
                  <kenjo-icon *ngIf="row.type === WORK_SCHEDULE_TEMPLATE_TYPE_STANDARD" [size]="18" [matTooltip]="">standard</kenjo-icon>
                </orgos-column>
                <orgos-column size="0" class="kenjo-ml-10px">{{ i18n.page[row.type] }}</orgos-column>
              </orgos-column-container>
            </orgos-table-column>
            <orgos-table-column columnKey="checkInHour" [header]="i18n.page.checkInLabel" cellWidth="200px">
              <orgos-column-container *orgosTableCell="let row" centerColumns="true">
                <orgos-column size="0">{{ row.formattedAttendanceStartTime ? row.formattedAttendanceStartTime : '-' }}</orgos-column>
                <orgos-column size="0">
                  <kenjo-icon
                    [size]="15"
                    *ngIf="row.status === WORK_SCHEDULE_STATUS_LATE_CHECK_IN || row.conflictedStatus === WORK_SCHEDULE_STATUS_LATE_CHECK_IN"
                    [matTooltip]="i18n.page.tooltips.lateCheckIn | i18nData: { min: row.minLate }"
                    class="kenjo-ml-10px kenjo-font-color-light-text-757575 kenjo-cursor-pointer"
                    >late</kenjo-icon
                  >
                </orgos-column>
              </orgos-column-container>
            </orgos-table-column>
            <orgos-table-column columnKey="status" [header]="i18n.page.statusLabel">
              <orgos-chip *orgosTableCell="let row" [color]="row.hasConflict ? mapColorByStatus[row.conflictedStatus] : mapColorByStatus[row.status]"> {{ row.chipText | uppercase }} </orgos-chip>
            </orgos-table-column>
          </orgos-table>
        </orgos-container>
      </ng-container>
    </orgos-column>
  </orgos-column-container>
  </div>
  <!-- LEGEND -->
  <div class="pvp-legend-container kenjo-color-white-background-ffffff kenjo-border-top" [class]="isNavbarOpen ? 'pvp-legend-container-expanded-navbar' : 'pvp-legend-container-closed-navbar' ">
    <div class="asp-legend">
      <div class="asp-legend__circle asp-legend__circle--checked-in"></div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.legend.checkedIn }}</div>
    </div>
    <div class="asp-legend">
      <div class="asp-legend__circle asp-legend__circle--late-check-in"></div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.legend.lateCheckIn }}</div>
    </div>
    <div class="asp-legend">
      <div class="asp-legend__circle asp-legend__circle--no-check-in"></div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.legend.noCheckIn }}</div>
    </div>
    <div class="asp-legend">
      <div class="asp-legend__circle asp-legend__circle--time-off"></div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.legend.timeOff }}</div>
    </div>
    <div class="asp-legend">
      <div class="asp-legend__circle asp-legend__circle--bank-holiday"></div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.legend.bankHoliday }}</div>
    </div>
  </div>
</div>
