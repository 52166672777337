import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import { CandidateService } from 'src/app/standard/services/recruiting/candidate.service';

import { I18nDataPipe } from '../../../components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '../../../core/generic-cache-model';
import { InputValidation } from '../../../core/validation/input-validation';
import { AuthenticationService } from '../../../services/core/authentication.service';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { RecruitingDocumentTagService } from '../../../services/recruiting/recruiting-document-tag.service';
import { RecruitingDocumentService } from '../../../services/recruiting/recruiting-document.service';

@Component({
  selector: 'orgos-upload-recruiting-document-dialog',
  templateUrl: 'recruiting-upload-document.dialog.html',
  styleUrls: ['recruiting-upload-document.dialog.scss'],
})
export class RecruitingUploadDocumentDialog implements OnInit {
  document: GenericCacheModel;
  candidateId: string;
  positionCandidateId: string;
  pageTranslation: any = {};
  miscTranslation: any = {};
  listTags: Array<any>;

  nameValidation: InputValidation;
  uploadingFile: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<RecruitingUploadDocumentDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {
    if (check.assigned(this.data.rawDocument)) {
      this.document = new GenericCacheModel(this.injector, this.data.rawDocument, RecruitingDocumentService, this.data.rawDocument.ownerId);
    } else {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      this.document = new GenericCacheModel(this.injector, {}, RecruitingDocumentService, loggedUser._id);
    }
    if (check.assigned(this.data.uploadConfig)) {
      if (check.assigned(this.data.uploadConfig.candidateId)) {
        this.candidateId = this.data.uploadConfig.candidateId;
      }
      if (check.assigned(this.data.uploadConfig.positionCandidateId)) {
        this.positionCandidateId = this.data.uploadConfig.positionCandidateId;
      }
    }
  }

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('document-upload-dialog')
      .then((pageTranslation: any) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.initTags();
  }

  private initTags(): void {
    const inCollection = ['candidate'];
    if (check.assigned(this.positionCandidateId) && check.nonEmptyString(this.positionCandidateId)) {
      inCollection.push('position-candidate');
    }

    this.injector
      .get(RecruitingDocumentTagService)
      .find({ _inCollection: inCollection })
      .then((listTags) => {
        this.listTags = listTags.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      })
      .catch(() => {
        this.listTags = null;
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public getExtension(): string {
    return this.document.data._file?._fileExtension ?? '';
  }

  public isTagSelected(tag: any): boolean {
    if (check.not.assigned(this.document) || check.not.assigned(this.document.data.tag) || check.emptyArray(this.document.data.tag)) {
      return false;
    }

    return this.document.data.tag === tag._id;
  }

  public getTagColor(tag: any): string {
    if (this.isTagSelected(tag) === false) {
      return 'Neutral';
    }

    return userColorConstants[tag.color];
  }

  public toggleTag(tag: any): Function {
    return () => {
      if (this.isTagSelected(tag) === false) {
        this.document.data.tag = tag._id;
        return;
      }

      this.document.data.tag = null;
    };
  }

  public uploadDocument(): void {
    if (this.uploadingFile) {
      return;
    }

    if (check.not.assigned(this.nameValidation) || this.nameValidation.hasErrors()) {
      return;
    }

    if (check.not.assigned(this.document.data.tag) || check.emptyString(this.document.data.tag)) {
      return;
    }

    this.uploadingFile = true;

    this.setRelatedToField();

    if (check.not.assigned(this.document.data._id)) {
      this.document
        .createInServer()
        .then((createdDocument: GenericCacheModel) => {
          this.injector
            .get(CandidateService)
            .touchUpdatedAtById(this.candidateId)
            .then(() => {})
            .catch(() => {});
          const documentUploadedSnackText = this.injector
            .get(I18nDataPipe)
            .transform(this.pageTranslation.documentUploadedSnackText, createdDocument.data);
          this.injector.get(MatLegacySnackBar).open(documentUploadedSnackText, 'OK', {
            duration: 5000,
          });

          this.dialogRef.close(createdDocument.data);
        })
        .catch(() => {
          // An error is shown
          this.uploadingFile = false;
        });
    } else {
      this.document
        .updateInServer()
        .then(() => {
          this.injector
            .get(CandidateService)
            .touchUpdatedAtById(this.candidateId)
            .then(() => {})
            .catch(() => {});
          const documentUploadedSnackText = this.injector
            .get(I18nDataPipe)
            .transform(this.pageTranslation.documentUpdatedSnackText, this.document.data);
          this.injector.get(MatLegacySnackBar).open(documentUploadedSnackText, 'OK', {
            duration: 5000,
          });

          this.dialogRef.close(this.document.data);
        })
        .catch(() => {
          // An error is shown
          this.uploadingFile = false;
        });
    }
  }

  private setRelatedToField(): void {
    const tag = this.listTags.find((iTag) => {
      return iTag._id === this.document.data.tag;
    });

    if (check.not.assigned(tag) || check.not.assigned(tag._inCollection) || check.emptyString(tag._inCollection)) {
      return;
    }

    if (tag._inCollection === 'candidate') {
      this.document.data.relatedTo = this.candidateId;
    } else if (tag._inCollection === 'position-candidate') {
      this.document.data.relatedTo = this.positionCandidateId;
    }
  }
}
