<orgos-container aspect="card">
  <div class="tohc-header kenjo-pv-10px kenjo-ph-20px kenjo-font-size-16px">
    {{ componentTranslation.timeOffHistoryTitle }}
  </div>

  <ng-container *ngIf="!loadingComponent && (historyList?.length > 0 || timeOffTypeOptions.length > 0)">
    <!-- HISTORY TABLE -->
    <orgos-column-container class="kenjo-ph-20px kenjo-pt-20px">
      <orgos-column size="1" class="tohc-select">
        <orgos-input-simple-select *ngIf="timeOffTypeOptions" [label]="componentTranslation.timeOffType" [options]="timeOffTypeOptions" (valueChange)="filterByType($event)"></orgos-input-simple-select>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <mat-slide-toggle [checked]="showPolicyChanges" (change)="changeShowPolicy()"> {{ componentTranslation.showUpdatesOption }}</mat-slide-toggle>
      </orgos-column>
    </orgos-column-container>
    <div *ngIf="componentTranslation && historyList?.length > 0" class="tohc-table kenjo-mh-20px kenjo-mb-20px">
      <orgos-column-container class="tohc-row-header">
        <orgos-column size="0" class="tohc-header-when kenjo-pr-10px">
          <div class="kenjo-font-size-12px kenjo-pl-20px kenjo-pv-10px">
            {{ showPolicyChanges ? componentTranslation.period_update : (componentTranslation.period | uppercase) }}
          </div>
        </orgos-column>
        <orgos-column size="0" class="tohc-header-amount kenjo-pr-10px">
          <div class="kenjo-pv-10px kenjo-font-size-12px">{{ componentTranslation.amount | uppercase }}</div>
        </orgos-column>
        <orgos-column size="0" class="tohc-header-what kenjo-pr-10px">
          <div class="kenjo-pv-10px kenjo-font-size-12px">{{ componentTranslation.type | uppercase }}</div>
        </orgos-column>
        <orgos-column size="0" class="tohc-header-how">
          <div class="kenjo-pv-10px kenjo-font-size-12px">{{ componentTranslation.status | uppercase }}</div>
        </orgos-column>
      </orgos-column-container>
      <div class="tohc-table-body">
        <div *ngFor="let history of historyList" class="tohc-table-row-container">
          <!-- COLLAPSED ROW HEADER -->
          <orgos-column-container class="kenjo-pv-10px" (click)="history !== expandedElement ? (expandedElement = history) : (expandedElement = null); logViewTimeOffDetails()">
            <!-- PERIOD/UPDATE -->
            <orgos-column size="0" class="tohc-cell-when kenjo-pr-10px">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-ml-20px kenjo-pr-10px tohc-icon-container">
                  <kenjo-icon *ngIf="history === expandedElement" [size]="20" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> keyboard_arrow_up</kenjo-icon>
                  <kenjo-icon *ngIf="history !== expandedElement" [size]="20" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> keyboard_arrow_down</kenjo-icon>
                </orgos-column>

                <orgos-column *ngIf="history.isRequest === true">
                  <div *ngIf="history.policy._type === POLICY_TYPE_DAY || !((history.request.from | timeCompare : history.request.to : 'isSameDay') && !history?.request?.partOfDayFrom)">{{ history.request.from | date: 'shortDate':'UTC' }} - {{ history.request.to | date: 'shortDate':'UTC' }}</div>
                  <div *ngIf="history.isRequest === true && history.policy._type !== POLICY_TYPE_DAY && (history.request.from | timeCompare : history.request.to : 'isSameDay') && !history.request.partOfDayFrom">{{ history.request.from | date: 'shortDate':'UTC' }} - {{ history.request.from | timeOffTime }} {{ componentTranslation.submittedText2 }} {{ history.request.to | timeOffTime }}</div>
                </orgos-column>

                <orgos-column class="tohc-change-container" *ngIf="history.isRequest === false">
                  <kenjo-truncate-text *ngIf="history.action === TIME_OFF_ACTION_SCHEDULE_POLICY">
                    {{ componentTranslation.scheduledAssignmentTitle | i18nData: { policy: history.policy.name, date: (history.scheduledFor | humanDate: false:'short') } }}
                  </kenjo-truncate-text>
                  <kenjo-truncate-text *ngIf="history.action === TIME_OFF_ACTION_ASSIGN_POLICY || history.action === TIME_OFF_ACTION_REASSIGN_POLICY">
                    <span *ngIf="history.action === TIME_OFF_ACTION_ASSIGN_POLICY">{{ componentTranslation.assignedPolicyTitle | i18nData: { policy: history.policy.name } }}</span>
                    <span *ngIf="history.action === TIME_OFF_ACTION_REASSIGN_POLICY">{{ componentTranslation.reassignedPolicyTitle | i18nData: { newPolicy: history.policy.name } }}</span>
                    <span *ngIf="history.unlimitedAllowance === false">
                      <ng-container *ngIf="history.policy._type === POLICY_TYPE_DAY"> ({{ (history.adjustment ? history.adjustment : 0) | number: '1.0-2' | plusMinusSign }} {{ componentTranslation.days }}) </ng-container>
                      <ng-container *ngIf="history.policy._type !== POLICY_TYPE_DAY"> ({{ history.adjustment > 0 ? '+' : '' }}{{ history.adjustment ?? 0 | duration }}) </ng-container>
                    </span>
                    <span *ngIf="history.unlimitedAllowance === true"> ({{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.unlimitedDays : componentTranslation.unlimitedHours }}) </span>
                  </kenjo-truncate-text>
                  <kenjo-truncate-text *ngIf="history.action === TIME_OFF_ACTION_UNASSIGN_POLICY">
                    {{ componentTranslation.unassignedPolicyTitle | i18nData: { policy: history.policy.name } }}
                  </kenjo-truncate-text>
                  <kenjo-truncate-text
                    *ngIf="
                      history.action === TIME_OFF_ACTION_ADJUST_CARRY_OVER || history.action === TIME_OFF_ACTION_ADJUST_TAKEN || history.action === TIME_OFF_ACTION_ADJUST_BALANCE || history.action === TIME_OFF_ACTION_ADJUST_COMPENSATION || history.action === TIME_OFF_ACTION_ADD_EXTRA_ALLOWANCE_DAYS
                    "
                  >
                    {{ history.createdBy.name }}
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADJUST_CARRY_OVER">{{ componentTranslation.adjustedCarryOverText }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADJUST_TAKEN">{{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.adjustedDaysTakenText : componentTranslation.adjustedHoursTakenText }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADJUST_BALANCE || history.action === TIME_OFF_ACTION_ADJUST_COMPENSATION">{{ componentTranslation.adjustedBalanceText }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADD_EXTRA_ALLOWANCE_DAYS">{{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.extraAllowanceDaysText : componentTranslation.extraAllowanceHoursText }}</ng-container>
                    <ng-container *ngIf="history.policy._type === POLICY_TYPE_DAY"> ({{ history.adjustment | number: '1.0-2' | plusMinusSign }} {{ componentTranslation.days }})</ng-container>
                    <ng-container *ngIf="history.policy._type !== POLICY_TYPE_DAY"> ({{ history.adjustment > 0 ? '+' : '' }}{{ history.adjustment ?? 0 | duration }}) </ng-container>
                  </kenjo-truncate-text>
                  <kenjo-truncate-text *ngIf="history.action === TIME_OFF_ACTION_ACCRUAL || history.action === TIME_OFF_ACTION_CARRY_OVER || history.action === TIME_OFF_ACTION_EXPIRE_CARRY_OVER">
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_ACCRUAL">{{ componentTranslation.accrualText1 }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_CARRY_OVER">{{ componentTranslation.carryOverText1 }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_EXPIRE_CARRY_OVER">{{ componentTranslation.expiredCarryOverText1 }}</ng-container>
                    <ng-container *ngIf="history.unlimitedAllowance"> {{ componentTranslation.unlimitedDaysAccrued }}</ng-container>
                    <ng-container *ngIf="!history.unlimitedAllowance"> {{ history.policy._type === POLICY_TYPE_DAY ? (history.adjustment | number: '1.0-2') : (history.adjustment | duration) }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_ACCRUAL && history.policy._type === POLICY_TYPE_DAY"> {{ componentTranslation.accrualText2 }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_CARRY_OVER"> {{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.carryOverText2 : componentTranslation.carryOverHoursText2 }}</ng-container>
                    <ng-container *ngIf="history.action === TIME_OFF_ACTION_EXPIRE_CARRY_OVER"> {{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.expiredCarryOverText2 : componentTranslation.expiredCarryOverHoursText2 }}</ng-container>
                  </kenjo-truncate-text>
                  <kenjo-truncate-text *ngIf="history.action === TIME_OFF_ACTION_NEW_CYCLE">
                    {{ componentTranslation.newCycleText1 }} {{ history.cycleStartDate | date: 'mediumDate':'UTC' }} {{ componentTranslation.newCycleText2 }} {{ getShownCycleEndDate(history.cycleEndDate) | date: 'mediumDate':'UTC' }} {{ componentTranslation.newCycleText3 }}
                  </kenjo-truncate-text>
                  <kenjo-truncate-text *ngIf="history.action === TIME_OFF_ACTION_NEGATIVE_BALANCE">
                    {{ componentTranslation.negativeBalance }}
                    <ng-container *ngIf="history.policy._type === POLICY_TYPE_DAY"> ({{ history.adjustment | number: '1.0-2' | plusMinusSign }} {{ componentTranslation.days }})</ng-container>
                    <ng-container *ngIf="history.policy._type !== POLICY_TYPE_DAY"> ({{ history.adjustment > 0 ? '+' : '' }}{{ history.adjustment ?? 0 | duration }}) </ng-container>
                  </kenjo-truncate-text>
                  <div class="kenjo-font-color-light-text-757575 kenjo-mt-5px">{{ history.actionDate | humanDate: false:'short' }}</div>
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <!-- AMOUNT -->
            <orgos-column size="0" class="tohc-cell-amount kenjo-pr-10px">
              <orgos-column-container centerColumns="true" *ngIf="history.isRequest === true">
                <orgos-column>
                  <div *ngIf="history.policy._type === POLICY_TYPE_DAY">{{ history.request.workingTime }} {{ history.request.workingTime === 1 ? componentTranslation.day : componentTranslation.days }}</div>
                  <div *ngIf="history.policy._type !== POLICY_TYPE_DAY">{{ history.request.workingTime | duration }}</div>
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <!-- TYPE OF TIME OFF -->
            <orgos-column size="0" class="tohc-cell-what kenjo-pr-10px">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-mr-10px">
                  <div class="tohc-status-circle-mini" [style.background-color]="COLORS[history.timeOffType.color]"></div>
                </orgos-column>
                <orgos-column size="1" class="tohc-time-off-type-name">
                  <kenjo-truncate-text>{{ history.timeOffType.name }}</kenjo-truncate-text>
                </orgos-column>
              </orgos-column-container>
            </orgos-column>

            <!-- STATUS/TYPE OF ACTION-->
            <orgos-column size="0" class="tohc-cell-how">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0">
                  <orgos-chip *ngIf="history.isRequest === false" class="tohc-chip" color="Neutral"> {{ componentTranslation[history.action + 'ActionType'] }}</orgos-chip>
                  <orgos-time-off-request-status *ngIf="history.isRequest === true" [type]="history.request._type" [status]="history.request.status"></orgos-time-off-request-status>
                </orgos-column>
                <orgos-column size="1"></orgos-column>
                <orgos-column size="0" class="tohc-icon-container kenjo-mr-10px">
                  <a *ngIf="history.isRequest === true && history.requestPermission.canBeReviewed" routerLink="/cloud/time-off/requests" [queryParams]="{ request: history.request._id }" [matTooltip]="componentTranslation.reviewActionLabel" class="tohc-requests-link">
                    <kenjo-icon class="kenjo-font-color-primary-5993e3" [size]="18">visibility</kenjo-icon>
                  </a>
                </orgos-column>
                <orgos-column size="0" class="tohc-icon-container kenjo-mr-10px">
                  <kenjo-icon
                    *ngIf="history.isRequest === true && history.requestPermission.canBeEdited && checkEditionInTimeZone(history.request.to)"
                    (click)="openEditRequestDialog(history); $event.stopPropagation()"
                    [size]="18"
                    [matTooltip]="componentTranslation.editActionLabel"
                    class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer"
                    >mode_edit</kenjo-icon
                  >
                </orgos-column>
                <orgos-column size="0" class="tohc-icon-container kenjo-mr-20px">
                  <kenjo-icon
                    *ngIf="history.isRequest === true && history.requestPermission.canBeCanceled"
                    [size]="18"
                    (click)="openCancelRequestDialog(history); $event.stopPropagation()"
                    [matTooltip]="componentTranslation.cancelActionLabel"
                    class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer"
                  >
                    cancel
                  </kenjo-icon>
                  <kenjo-icon
                    *ngIf="history.action === TIME_OFF_ACTION_SCHEDULE_POLICY && history.canBeManaged === true"
                    [size]="18"
                    (click)="openCancelScheduledAssignment(history); $event.stopPropagation()"
                    [matTooltip]="componentTranslation.cancelScheduledAssignmentTooltip"
                    class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer"
                    >cancel</kenjo-icon
                  >
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
          </orgos-column-container>

          <!-- DETAILS TO EXPAND -->
          <orgos-column-container *ngIf="expandedElement === history">
            <orgos-column>
              <div class="kenjo-mh-20px kenjo-mb-10px">
                <!-- ATTACHMENTS START HERE -->
                <div *ngIf="history.isRequest === true && (history.requestAttachments?.length > 0 || checkAttachmentsCanBeAdded(history))">
                  <div class="tohc-attachments-title">
                    <div class="">{{ componentTranslation.attachments }}</div>
                    <div *ngIf="history.requestAttachments?.length > 0" class="kenjo-pv-10px">
                      <div *ngFor="let attachment of history.requestAttachments" class="kenjo-mv-10px tohc-detail-row">
                        <div class="kenjo-mr-10px">
                          <orgos-avatar [photoUrl]="attachment.createdByPhotoUrl" [avatarName]="attachment.createdByName" size="tiny"> </orgos-avatar>
                        </div>
                        <div class="tohc-detail-row kenjo-full-width">
                          <div class="tohc-detail-row kenjo-full-width">
                            <kenjo-simple-truncate-text class="tohc-attachment-max-width kenjo-mr-20px">{{ componentTranslation.addedAnAttachment | i18nData: { displayName: attachment.createdByName } }}:</kenjo-simple-truncate-text>
                            <orgos-document-icon class="tohc-middle-vertical-align" size="small" [extension]="attachment.documentExtension" (click)="downloadAttachment(attachment)" class="kenjo-cursor-pointer" [matTooltip]="attachment.documentName"></orgos-document-icon>
                            <kenjo-simple-truncate-text class="tohc-attachment-max-width kenjo-ml-5px kenjo-mr-10px">
                              <a (click)="downloadAttachment(attachment)" class="kenjo-font-size-14px">
                                {{ attachment.documentName }}
                              </a>
                            </kenjo-simple-truncate-text>
                            <kenjo-icon *ngIf="requestPermissions.canDeleteAttachment" [size]="18" (click)="deleteAttachment(history, attachment)" class="kenjo-ml-5px kenjo-cursor-pointer kenjo-font-color-danger-ff5757" [matTooltip]="componentTranslation.deleteAttachmentTooltip" matTooltipPosition="above">delete</kenjo-icon>
                            <kenjo-icon *ngIf="!requestPermissions.canDeleteAttachment" [size]="18" class="kenjo-ml-5px kenjo-font-color-disabled-c4c4c4 kenjo-cursor-default" [matTooltip]="componentTranslation.noDeleteAttachmentPermission" matTooltipPosition="above">delete</kenjo-icon>
                          </div>
                          <div class="tohc-inline kenjo-pl-10px kenjo-font-color-light-text-757575">
                            {{ attachment.createdAt | humanDate: false:'short' }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="checkAttachmentsCanBeAdded(history)">
                      <div *ngIf="history.requestAttachments.length < MAX_ATTACHMENT_COUNT" class="kenjo-pv-10px">
                        <orgos-action *ngIf="requestPermissions.canCreateAttachment" icon="add_circle" [matTooltip]="componentTranslation.attachmentMaxSize" matTooltipPosition="above" (click)="addAttachment(history)">{{ componentTranslation.attachFiles }} </orgos-action>
                        <span *ngIf="!requestPermissions.canCreateAttachment" [matTooltip]="componentTranslation.noUploadAttachmentPermission" matTooltipPosition="above">
                          <orgos-action icon="add_circle" [disabled]="true">{{ componentTranslation.attachFiles }} </orgos-action>
                        </span>
                      </div>
                      <div *ngIf="history.requestAttachments.length >= MAX_ATTACHMENT_COUNT" class="kenjo-pl-10px kenjo-pv-10px kenjo-font-color-light-text-757575">
                        {{ componentTranslation.maxUploadMessage }}
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- ATTACHMENTS END HERE -->
                <!-- HISTORY DATA -->
                <ng-container>
                  <div class="tohc-attachments-title">{{ componentTranslation.history }}</div>
                  <div *ngFor="let historyDetail of getHistoryDetail(history)" class="kenjo-mb-15px">
                    <orgos-column-container centerColumns="true">
                      <orgos-column size="0" class="kenjo-mr-10px">
                        <orgos-avatar *ngIf="showHistoryAvatar(history.isRequest, historyDetail.action)" [photoUrl]="historyDetail.createdBy.photoUrl" [avatarName]="historyDetail.createdBy.name" size="tiny"> </orgos-avatar>
                        <orgos-avatar *ngIf="!showHistoryAvatar(history.isRequest, historyDetail.action)" photoUrl="/assets/images/kenjoLogoBlack.svg" size="tiny"></orgos-avatar>
                      </orgos-column>
                      <orgos-column size="1">
                        <div *ngIf="history.isRequest === false" class="tohc-detail-row">
                          <kenjo-truncate-text class="tohc-detail-title" orgosColumn="1" *ngIf="historyDetail.action === TIME_OFF_ACTION_SCHEDULE_POLICY">
                            {{ componentTranslation.scheduledAssignmentDescription | i18nData: { user: historyDetail.createdBy.name, policy: history.policy.name, date: (history.scheduledFor | humanDate: false:'short') } }}
                          </kenjo-truncate-text>
                          <kenjo-truncate-text class="tohc-detail-title" orgosColumn="1" *ngIf="historyDetail.action === TIME_OFF_ACTION_ASSIGN_POLICY || historyDetail.action === TIME_OFF_ACTION_REASSIGN_POLICY">
                            <span *ngIf="historyDetail.action === TIME_OFF_ACTION_ASSIGN_POLICY">{{ componentTranslation.assignedPolicyDescription | i18nData: { user: historyDetail.createdBy.name, policy: historyDetail.policy.name } }}</span>
                            <span *ngIf="historyDetail.action === TIME_OFF_ACTION_REASSIGN_POLICY && !historyDetail.previousPolicy?.name">
                              {{ componentTranslation.reassignedPolicyDescriptionWithoutPrevious | i18nData: { user: historyDetail.createdBy.name, newPolicy: historyDetail.policy.name } }}
                            </span>
                            <span *ngIf="historyDetail.action === TIME_OFF_ACTION_REASSIGN_POLICY && historyDetail.previousPolicy?.name">
                              {{ componentTranslation.reassignedPolicyDescription | i18nData: { user: historyDetail.createdBy.name, newPolicy: historyDetail.policy.name, previousPolicy: historyDetail.previousPolicy?.name } }}
                            </span>
                            <span *ngIf="history.unlimitedAllowance === false">
                              <ng-container *ngIf="history.policy._type === POLICY_TYPE_DAY"> ({{ (history.adjustment ? history.adjustment : 0) | number: '1.0-2' | plusMinusSign }} {{ componentTranslation.days }}) </ng-container>
                              <ng-container *ngIf="history.policy._type !== POLICY_TYPE_DAY"> ({{ history.adjustment > 0 ? '+' : '' }}{{ history.adjustment ?? 0 | duration }}) </ng-container>
                            </span>
                            <span *ngIf="history.unlimitedAllowance === true"> ({{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.unlimitedDays : componentTranslation.unlimitedHours }}) </span>
                          </kenjo-truncate-text>
                          <kenjo-truncate-text class="tohc-detail-title" orgosColumn="1" *ngIf="historyDetail.action === TIME_OFF_ACTION_UNASSIGN_POLICY">
                            {{ componentTranslation.unassignedPolicyDescription | i18nData: { user: historyDetail.createdBy.name, policy: history.policy.name } }}
                          </kenjo-truncate-text>
                          <kenjo-truncate-text
                            class="tohc-detail-title"
                            orgosColumn="1"
                            *ngIf="
                              history.action === TIME_OFF_ACTION_ADJUST_CARRY_OVER ||
                              history.action === TIME_OFF_ACTION_ADJUST_TAKEN ||
                              history.action === TIME_OFF_ACTION_ADJUST_BALANCE ||
                              history.action === TIME_OFF_ACTION_ADJUST_COMPENSATION ||
                              history.action === TIME_OFF_ACTION_ADD_EXTRA_ALLOWANCE_DAYS
                            "
                          >
                            {{ historyDetail.createdBy.name }}
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADJUST_CARRY_OVER">{{ componentTranslation.adjustedCarryOverText }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADJUST_TAKEN">{{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.adjustedDaysTakenText : componentTranslation.adjustedHoursTakenText }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADJUST_BALANCE || history.action === TIME_OFF_ACTION_ADJUST_COMPENSATION">{{ componentTranslation.adjustedBalanceText }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_ADD_EXTRA_ALLOWANCE_DAYS">{{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.extraAllowanceDaysText : componentTranslation.extraAllowanceHoursText }}</ng-container>
                            <ng-container *ngIf="history.policy._type === POLICY_TYPE_DAY"> ({{ history.adjustment | number: '1.0-2' | plusMinusSign }} {{ componentTranslation.days }})</ng-container>
                            <ng-container *ngIf="history.policy._type !== POLICY_TYPE_DAY"> ({{ history.adjustment > 0 ? '+' : '' }}{{ history.adjustment ?? 0 | duration }})</ng-container>
                          </kenjo-truncate-text>
                          <kenjo-truncate-text class="tohc-detail-title" orgosColumn="1" *ngIf="history.action === TIME_OFF_ACTION_ACCRUAL || history.action === TIME_OFF_ACTION_CARRY_OVER || history.action === TIME_OFF_ACTION_EXPIRE_CARRY_OVER">
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_ACCRUAL">{{ componentTranslation.accrualText1 }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_CARRY_OVER">{{ componentTranslation.carryOverText1 }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_EXPIRE_CARRY_OVER">{{ componentTranslation.expiredCarryOverText1 }}</ng-container>
                            <ng-container *ngIf="history.unlimitedAllowance"> {{ componentTranslation.unlimitedDaysAccrued }}</ng-container>
                            <ng-container *ngIf="!history.unlimitedAllowance"> {{ history.policy._type === POLICY_TYPE_DAY ? (history.adjustment | number: '1.0-2') : (history.adjustment | duration) }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_ACCRUAL"> {{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.accrualText2 : componentTranslation.accrualHoursText2 }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_CARRY_OVER"> {{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.carryOverText2 : componentTranslation.carryOverHoursText2 }}</ng-container>
                            <ng-container *ngIf="history.action === TIME_OFF_ACTION_EXPIRE_CARRY_OVER"> {{ history.policy._type === POLICY_TYPE_DAY ? componentTranslation.expiredCarryOverText2 : componentTranslation.expiredCarryOverHoursText2 }}</ng-container>
                          </kenjo-truncate-text>
                          <kenjo-truncate-text class="tohc-detail-title" orgosColumn="1" *ngIf="history.action === TIME_OFF_ACTION_NEW_CYCLE">
                            {{ componentTranslation.newCycleText1 }} {{ history.cycleStartDate | date: 'mediumDate':'UTC' }} {{ componentTranslation.newCycleText2 }} {{ getShownCycleEndDate(history.cycleEndDate) | date: 'mediumDate':'UTC' }} {{ componentTranslation.newCycleText3 }}
                          </kenjo-truncate-text>
                          <kenjo-truncate-text class="tohc-detail-title" orgosColumn="1" *ngIf="history.action === TIME_OFF_ACTION_NEGATIVE_BALANCE">
                            {{ componentTranslation.negativeBalance }}
                            <ng-container *ngIf="history.policy._type === POLICY_TYPE_DAY"> ({{ history.adjustment | number: '1.0-2' | plusMinusSign }} {{ componentTranslation.days }})</ng-container>
                            <ng-container *ngIf="history.policy._type !== POLICY_TYPE_DAY"> ({{ history.adjustment > 0 ? '+' : '' }}{{ history.adjustment ?? 0 | duration }}) </ng-container>
                          </kenjo-truncate-text>
                          <div class="tohc-inline kenjo-pl-10px kenjo-font-color-light-text-757575">
                            {{ historyDetail.actionDate | humanDate: false:'short' }}
                          </div>
                        </div>
                        <div *ngIf="history.isRequest === true" class="tohc-detail-row">
                          <kenjo-truncate-text
                            class="tohc-detail-title"
                            orgosColumn="1"
                            *ngIf="historyDetail.action !== TIME_OFF_ACTION_APPROVE_REQUEST && (historyDetail.action !== TIME_OFF_ACTION_SUBMIT_TIME_OFF || (historyDetail.action === TIME_OFF_ACTION_SUBMIT_TIME_OFF && history.request._userId === historyDetail.createdBy._id))"
                          >
                            <ng-container>{{ historyDetail.createdBy.name }}</ng-container>

                            <ng-container *ngIf="historyDetail.action === TIME_OFF_ACTION_EDIT_REQUEST">
                              {{
                                history.policy._type === POLICY_TYPE_DAY
                                  ? (componentTranslation.editedDetailsText | i18nData: { days: historyDetail.workingTime, unit: historyDetail.workingTime === 1 ? componentTranslation.day : componentTranslation.days })
                                  : (componentTranslation.editedHoursDetailsText | i18nData: { hours: historyDetail.workingTime | duration })
                              }}
                            </ng-container>

                            <ng-container *ngIf="historyDetail.action === TIME_OFF_ACTION_REQUEST_TIME_OFF">
                              {{
                                history.policy._type === POLICY_TYPE_DAY
                                  ? (componentTranslation.requestedDetailsText | i18nData: { days: historyDetail.workingTime, unit: historyDetail.workingTime === 1 ? componentTranslation.day : componentTranslation.days })
                                  : (componentTranslation.requestedHoursDetailsText | i18nData: { hours: historyDetail.workingTime | duration })
                              }}
                            </ng-container>

                            <ng-container *ngIf="historyDetail.action === TIME_OFF_ACTION_DECLINE_REQUEST"> {{ componentTranslation.declinedRequestDetailsText }}</ng-container>

                            <ng-container *ngIf="historyDetail.action === TIME_OFF_ACTION_CANCEL_REQUEST || historyDetail.action === TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_REQUEST">
                              {{ componentTranslation.cancelledRequestDetailsText }}
                            </ng-container>

                            <ng-container *ngIf="historyDetail.action === TIME_OFF_ACTION_SUBMIT_TIME_OFF || (historyDetail.action === TIME_OFF_ACTION_PROCESS_SUBMISSION && history.request._userId === historyDetail.createdBy._id)">
                              {{
                                history.policy._type === POLICY_TYPE_DAY
                                  ? (componentTranslation.submittedDetailsText | i18nData: { days: historyDetail.workingTime, unit: historyDetail.workingTime === 1 ? componentTranslation.day : componentTranslation.days })
                                  : (componentTranslation.submittedHoursDetailsText | i18nData: { hours: historyDetail.workingTime | duration })
                              }}
                            </ng-container>

                            <ng-container class="tohc-detail-title" *ngIf="historyDetail.action === TIME_OFF_ACTION_CANCEL_SUBMISSION || historyDetail.action === TIME_OFF_ACTION_CANCEL_AFTER_PROCESSED_SUBMISSION">
                              {{ componentTranslation.cancelledSubmissionDetailsText }}
                            </ng-container>
                          </kenjo-truncate-text>

                          <ng-container *ngIf="historyDetail.action === TIME_OFF_ACTION_SUBMIT_TIME_OFF && history.request._userId !== historyDetail.createdBy._id">
                            <kenjo-truncate-text orgosColumn="1" class="tohc-detail-title tohc-secondary-info">
                              <span>{{ historyDetail.createdBy.name }} </span>
                              <span>{{ componentTranslation.submittedOnBehalfDetailsText1 }} </span>
                              <span>{{ history.requestUserName }}</span>
                              <span
                                >{{
                                  history.policy._type === POLICY_TYPE_DAY
                                    ? (componentTranslation.submittedOnBehalfDetailsText2 | i18nData: { days: historyDetail.workingTime, unit: historyDetail.workingTime === 1 ? componentTranslation.day : componentTranslation.days })
                                    : (componentTranslation.submittedHoursOnBehalfDetailsText2 | i18nData: { hours: historyDetail.workingTime | duration })
                                }}
                              </span>
                            </kenjo-truncate-text>
                          </ng-container>

                          <kenjo-truncate-text *ngIf="historyDetail.action === TIME_OFF_ACTION_APPROVE_REQUEST" orgosColumn="1">
                            {{ componentTranslation.approvedRequestDetailsText | i18nData: { displayName: historyDetail.createdBy.name } }}
                          </kenjo-truncate-text>

                          <div class="tohc-inline kenjo-pl-10px kenjo-font-color-light-text-757575">
                            {{ historyDetail.actionDate | humanDate: false:'short' }}
                          </div>
                        </div>
                      </orgos-column>
                    </orgos-column-container>
                    <div *ngIf="historyDetail.description" class="kenjo-mv-10px kenjo-p-10px tohc-description">
                      {{ historyDetail.description }}
                    </div>
                    <div *ngIf="historyDetail.fromScheduledAssignment && (historyDetail.action === TIME_OFF_ACTION_ASSIGN_POLICY || historyDetail.action === TIME_OFF_ACTION_REASSIGN_POLICY)" class="kenjo-mv-10px kenjo-p-10px tohc-description">
                      {{ componentTranslation.fromScheduledAssignment }}
                    </div>
                  </div>
                  <!-- APPROVAL -->
                  <div *ngIf="checkIfHistoryInApproval(history)" class="kenjo-mb-10px">
                    <!-- First approval -->
                    <div class="kenjo-border kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-radius-4px">
                      <div *ngIf="history.request.status === TIME_OFF_REQUEST_STATUS_PENDING" class="tohc-container-approvers" [ngClass]="{ 'kenjo-border-bottom': history.secondApproverList?.length > 0 }">
                        <div class="kenjo-inline-block tohc-colum-approval-rule kenjo-ml-15px">
                          {{ history.secondApproverList?.length > 0 ? componentTranslation.waitingForFirstApproval : componentTranslation.waitingForApproval }}
                        </div>
                        <div class="tohc-container-avatar">
                          <div class="tohc-container-user-approver">
                            <div class="tohc-container-user">
                              <div>
                                <orgos-avatar class="kenjo-ml-10px kenjo-mr-5px kenjo-pv-10px" [photoUrl]="history.firstApprover?.photoUrl" [avatarName]="history.firstApprover ? history.firstApprover.name : componentTranslation.adminApproverDisplayName" size="tiny"></orgos-avatar>
                              </div>
                              <div class="kenjo-inline-block">{{ history.firstApprover ? history.firstApprover.name : componentTranslation.adminApproverDisplayName }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Two step approval -->
                      <div *ngIf="history.secondApproverList?.length > 0" class="tohc-container-approvers">
                        <div class="kenjo-inline-block tohc-colum-approval-rule kenjo-ml-15px kenjo-mv-10px">
                          {{ componentTranslation.waitingForSecondApproval }}
                        </div>
                        <div class="tohc-container-user-approver">
                          <div *ngFor="let secondApprover of history.secondApproverList" class="tohc-container-user">
                            <div>
                              <orgos-avatar class="kenjo-ml-10px kenjo-mr-5px kenjo-pv-10px" [photoUrl]="secondApprover.photoUrl" [avatarName]="secondApprover.name ?? componentTranslation.adminApproverDisplayName" size="tiny"></orgos-avatar>
                            </div>
                            <div class="tohc-approver-name" *ngIf="secondApprover.approverType !== 'user' && secondApprover.approverType !== 'manager'">{{ componentTranslation[secondApprover.approverType + 'ApproverMessage'] | i18nData: { section: secondApprover.name } }}</div>
                            <div class="tohc-approver-name" *ngIf="secondApprover.approverType === 'user' || secondApprover.approverType === 'manager'">{{ secondApprover.name }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- END HISTORY DATA -->
              </div>
            </orgos-column>
          </orgos-column-container>
          <!-- END DETAILS TO EXPAND -->
        </div>
      </div>
    </div>
    <!-- END OF HISTORY TABLE -->
    <div *ngIf="!disabledShowMoreButton" class="kenjo-m-20px kenjo-text-align-center">
      <orgos-action (click)="showMoreRecords()" [disabled]="disabledShowMoreButton">{{ componentTranslation.seeMore }} </orgos-action>
    </div>
  </ng-container>
  <div *ngIf="!loadingComponent && (!historyList || historyList.length === 0)" class="tohc-empty-history-container">
    <div>
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{ componentTranslation.noTimeOffHistory }}</div>
    </div>
  </div>
  <orgos-column-container *ngIf="loadingComponent" class="tohc-loading-container">
    <orgos-loading-spinner orgosColumn="0"></orgos-loading-spinner>
  </orgos-column-container>
</orgos-container>
