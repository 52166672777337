import {
  SHIFT_PLAN_ACTIVATION_STATUS_LIST,
  SHIFT_PLAN_CARD_STATUS_DRAFT,
  SHIFT_PLAN_CARD_STATUS_PUBLISHED,
  SHIFT_PLAN_MONTHLY_VIEW,
  SHIFT_PLAN_ROLE_LAYOUT,
  SHIFT_PLAN_USER_LAYOUT,
  SHIFT_PLAN_WEEKLY_VIEW,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';

export const VIEWS = {
  MONTH: SHIFT_PLAN_MONTHLY_VIEW,
  WEEK: SHIFT_PLAN_WEEKLY_VIEW,
};

export const STATUS = {
  DRAFT: SHIFT_PLAN_CARD_STATUS_DRAFT,
  PUBLISHED: SHIFT_PLAN_CARD_STATUS_PUBLISHED,
};

//TODO: Remove from SHIFT_PLAN_ACTIVATION_STATUS_LIST 'personal' in orgos-utils
const [notStarted, personalStep, ...restStep] = SHIFT_PLAN_ACTIVATION_STATUS_LIST;
export const ACTIVATION_STATUS = [...restStep];

export const LAYOUTS = { USER: SHIFT_PLAN_USER_LAYOUT, ROLE: SHIFT_PLAN_ROLE_LAYOUT };
