import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class EmailSignatureTemplateService {
  private EMAIL_SIGNATURE_TEMPLATE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/email-signature-template-db`;
  private EMAIL_SIGNATURE_TEMPLATE_PERMISSIONS_KEY: string = 'email-signature-template';
  private EMAIL_SIGNATURE_TEMPLATE_INTERNATIONALIZATION: string = 'email-signature-template-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  create(data: IEmailSignatureTemplate): Promise<IEmailSignatureTemplate> {
    return new Promise<IEmailSignatureTemplate>((resolve, reject) => {
      this.genericService
        .create(this.EMAIL_SIGNATURE_TEMPLATE_URL, data)
        .then((document: IEmailSignatureTemplate) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailSignatureTemplateService.name, 'create'));
        });
    });
  }

  find(findBody: IEmailSignatureTemplate): Promise<Array<IEmailSignatureTemplate>> {
    return new Promise<Array<IEmailSignatureTemplate>>((resolve, reject) => {
      this.genericService
        .find(this.EMAIL_SIGNATURE_TEMPLATE_URL, findBody)
        .then((emailSignature: Array<IEmailSignatureTemplate>) => {
          resolve(emailSignature);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailSignatureTemplateService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<IEmailSignatureTemplate> {
    return new Promise<IEmailSignatureTemplate>((resolve, reject) => {
      this.genericService
        .getById(this.EMAIL_SIGNATURE_TEMPLATE_URL, id)
        .then((doc: IEmailSignatureTemplate) => {
          resolve(doc);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailSignatureTemplateService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IEmailSignatureTemplate): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.EMAIL_SIGNATURE_TEMPLATE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailSignatureTemplateService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.EMAIL_SIGNATURE_TEMPLATE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailSignatureTemplateService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.EMAIL_SIGNATURE_TEMPLATE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.EMAIL_SIGNATURE_TEMPLATE_INTERNATIONALIZATION);
  }

  parseTemplate(body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.EMAIL_SIGNATURE_TEMPLATE_URL}/parse`, body, httpOptions)
        .toPromise()
        .then((appliedTemplate) => {
          resolve(appliedTemplate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, EmailSignatureTemplateService.name, 'applyEmailTemplate'));
        });
    });
  }
}

export interface IEmailSignatureTemplate {
  _id?: string;
  language?: string;
  _createdAt?: Date;
  bodyType?: string;
  body?: string;
  bodyQuill?: any;
  companyId?: string;
}
