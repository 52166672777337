import { Injectable, Injector } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { ACCESS_TYPE_FULL_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

import { AuthenticationService } from '../../standard/services/core/authentication.service';

const AGENCY_PROFILE_KEY = 'agency';

@Injectable({
  providedIn: 'root'
})
export class EmployeeOnboardingGuard implements CanLoad, CanActivate {
  constructor(private injector: Injector) {}

  canLoad(): boolean {
    return this.executeGuard();
  }

  canActivate(): boolean {
    return this.executeGuard();
  }

  private executeGuard(): boolean {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    if (loggedUser.isActive === true && loggedUser._onboarding === 'Employee' && check.not.equal(loggedUser.profileKey, AGENCY_PROFILE_KEY) && this.injector.get(AuthenticationService).getAccessType() === ACCESS_TYPE_FULL_ACCESS) {
      return true;
    }

    this.injector.get(Router).navigate(['/signin']);
    return false;
  }
}
