// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  PEOPLE_CLOUD_APP_URL: 'http://localhost:3000',
  API_ENVIRONMENT_URL: 'http://localhost:3000',
  STATIC_RESOURCE_URL: 'https://static.kenjo.io',
  ENABLE_ERRORS_BY_CONSOLE: 'true',

  CAREER_SITE_DOMAIN: 'kenjo.io',

  AWS_APP_NAME: 'development',
  AWS_SLUG_COMMIT: 'development',

  MOBILE_APP_SIGNIN: 'https://m.kenjo.io',
  MOBILE_APP_LEARN_MORE_EN: 'https://help.kenjo.io/en/support/solutions/articles/60000689496-mobile-app-android-ios-',
  MOBILE_APP_LEARN_MORE_ES: 'https://help.kenjo.io/es/support/solutions/articles/60000689496-aplicaci%C3%B3n-m%C3%B3vil-para-android-e-ios',
  MOBILE_APP_LEARN_MORE_DE: 'https://help.kenjo.io/de/support/solutions/articles/60000689496-mobile-app-android-ios-',
  MOBILE_APP_GOOGLE_PLAY_URL: 'https://play.google.com/store/apps/details?id=io.kenjo',
  MOBILE_APP_APP_STORE_URL: 'https://apps.apple.com/app/kenjo/id1545945865',

  HELP_CENTER_URL_DEFAULT: 'https://help.kenjo.io/en/support/home',
  HELP_CENTER_URL_EN: 'https://help.kenjo.io/en/support/home',
  HELP_CENTER_URL_ES: 'https://help.kenjo.io/es/support/home',
  HELP_CENTER_URL_DE: 'https://help.kenjo.io/de/support/home',

  TERMS_AND_CONDITIONS_URL_DEFAULT: 'https://www.kenjo.io/legal/terms-and-conditions',
  TERMS_AND_CONDITIONS_URL_EN: 'https://www.kenjo.io/legal/terms-and-conditions',
  TERMS_AND_CONDITIONS_URL_ES: 'https://www.kenjo.io/legal/terminos-y-condiciones',
  TERMS_AND_CONDITIONS_URL_DE: 'https://www.kenjo.io/legal/agbs',

  PRIVACY_POLICY_URL_DEFAULT: 'https://www.kenjo.io/legal/privacy-statement-kenjo-app',
  PRIVACY_POLICY_URL_EN: 'https://www.kenjo.io/legal/privacy-statement-kenjo-app',
  PRIVACY_POLICY_URL_ES: 'https://www.kenjo.io/es/legal/declaracion-privacidad-kenjo-app',
  PRIVACY_POLICY_URL_DE: 'https://www.kenjo.io/de/legal/erklaerung-datenschutz-kenjo-app',

  IMPRESSUM_URL_DEFAULT: 'https://www.kenjo.io/legal/legal-notice',
  IMPRESSUM_URL_EN: 'https://www.kenjo.io/legal/legal-notice',
  IMPRESSUM_URL_ES: 'https://www.kenjo.io/es/legal/aviso-legal',
  IMPRESSUM_URL_DE: 'https://www.kenjo.io/de/legal/impressum',

  MAX_SIZE_FOR_DOCUMENTS: '10',
  MAX_SIZE_FOR_IMAGES: '5',
  MAX_ATTACHMENT_COUNT: 5,

  SENTRY_DSN: 'https://8aa7e89c783940f5a53f4a4443951528@sentry.io/202001',

  GOOGLE_CLIENT_ID: '1022588698204-f2m160jotlif9p835u61pqe161ps8r11.apps.googleusercontent.com',
  GOOGLE_ANALYTICS_TARGET_ID: 'UA-164782806-1',

  MICROSOFT_CLIENT_ID: '10c5a1ab-6d12-4787-8e8b-b91471796689',
  MICROSOFT_CODE_CHALLENGE: 'm6ggqdIo0yeRoaX328Y6F5gPnNpjP-BhwUNsbSMH-PQ',

  HUBSPOT_PORTAL_ID: '5869942',
  HUBSPOT_FORM_ID: 'be15f758-049d-4fb5-935e-07f3ca2cc106',
  HUBSPOT_SUBSCRIPTION_TYPE_ID: '6686040',
  HUBSPOT_TRACKING_CODE_URL: '//js.hs-scripts.com/2896645.js',

  AMPLITUDE_API_KEY: '',
  AMPLITUDE_IGNORE_DOMAINS: 'kenjo.io,orgos.io',
  CUMUL_IS_TEST_ENVIRONMENT: true,

  SIGN_UP_FREE_TRIAL_KEY: '1Emf3xfdrWvsL6tmPhW2Rp2q8HdTot',

  CHARGEBEE_SITE: 'orgos-test',
  CHARGEBEE_PUBLISHABLE_KEY: 'test_LY98dsLGKzEYVS4rYxcduGcdcuyBAdTlSqJ',

  CHURNZERO_API_KEY: '',
  CHURNZERO_API_ENDPOINT: '',

  CHAMELEON_SECRET_KEY: 'VO5OFKdVsHTr30y2ellMTQcLe9LP-1JWuTO-Bni9SLUWzjNFPs5X',

  KIOSK_URL: 'https://kiosk.kenjo.io',

  DATEV_CLIENT_ID: '2aad9bd33cc0dc209e527e5ef069c6f1',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
