import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { IValidateHashOptions, IValidateHashResult, PrivateHashService } from '@app/private/services/private-hash.service';
import { ISignInWithHashOptions, PrivateSignInService } from '@app/private/services/private-sign-in.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { HASH_TYPE_EXPIRED_PASSWORD } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordPolicyHashMobileGuard implements CanLoad, CanActivate {
  constructor(private injector: Injector) {}

  canLoad(_: Route, urlSegments: Array<UrlSegment>): boolean {
    if (check.not.assigned(urlSegments) || check.emptyArray(urlSegments) || check.not.assigned(urlSegments[1]) || check.emptyString(urlSegments[1].path)) {
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }

    return true;
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      if (check.not.assigned(route) || check.not.assigned(route.params) || check.not.assigned(route.params.hash) || check.emptyString(route.params.hash)) {
        this.injector.get(Router).navigate(['/signin']);
        return false;
      }

      const { hash } = route.params;

      const validateHashOptions: IValidateHashOptions = {
        type: HASH_TYPE_EXPIRED_PASSWORD,
        hashValue: hash
      };

      const validateHashResult = await this.injector.get(PrivateHashService).validateHash(validateHashOptions);

      const signInWithHashOptions = {
        type: HASH_TYPE_EXPIRED_PASSWORD,
        hashValue: hash,
        referenceId: validateHashResult.referenceId,
        s_orgId: validateHashResult.s_orgId
      };

      await this.injector.get(PrivateSignInService).signInWithHash(signInWithHashOptions);

      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false || this.injector.get(AuthenticationService).getLoggedUser().isActive === false) {
        this.injector.get(Router).navigate(['/signin']);
        return false;
      }

      return true;
    } catch {
      // An error is already shown if necessary
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }
  }
}
