import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IUploadDocumentConfig, UploadDocumentDialog } from '@app/standard/pages/documents/dialogs/upload-document.dialog';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { DocumentTagService, IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import { DocumentService, IDocumentModel } from '@app/standard/services/document/document.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service.js';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import { Subscription } from 'rxjs/internal/Subscription';
import { map } from 'rxjs/operators';

@Component({
  selector: 'orgos-documents-employee-detail-docs',
  templateUrl: 'documents-employee-detail-docs.page.html',
  styleUrls: ['documents-employee-detail-docs.page.scss'],
})
export class DocumentsEmployeeDetailDocsPage implements OnInit, AfterViewChecked, OnDestroy {
  MAX_DOC_SIZE: number = 50;
  unlabelledTag: IDocumentTagModel;

  selectedDocumentOrigin: string = '';
  employeeId: string = '';
  isAnyUnlabelledDoc: boolean = false;
  unlabelledFilter: boolean = false;
  canViewHiddenDocs: boolean = false;
  canUploadDocs: boolean = false;
  isDataLoaded: boolean = false;

  allEmployeeDocs: Array<IDocumentModel> = [];
  allDocumentTags: Array<IDocumentTagModel> = [];
  enabledTags: Array<string> = [];
  employeeDocsToDisplay: Array<IDocumentModel> = [];
  employeeVisibleDocs: Array<IDocumentModel> = [];
  employeeHiddenDocs: Array<IDocumentModel> = [];
  tagsToDisplay: Array<IDocumentTagModel> = [];
  selectedDocumentToDrop: IDocumentModel = {};
  pageTranslation: any = {};

  userPersonal: any = null;
  userAccount: any = null;

  documentsHeightStyle: SafeStyle;

  @ViewChild('tagFooter') tagFooter: ElementRef;

  private backButtonSubscription: Subscription;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private injector: Injector,
    protected cdr: ChangeDetectorRef
  ) {
    // When the user clicks on the back button of the Browser:
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.onBackClick();
      }
    }) as Subscription;
  }

  ngAfterViewChecked(): void {
    if (check.assigned(this.tagFooter)) {
      const tagFooterHeight = <number>this.tagFooter.nativeElement.offsetHeight + 49;
      this.documentsHeightStyle = this.injector.get(DomSanitizer).bypassSecurityTrustStyle(`calc(100vh - ${tagFooterHeight}px)`);
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
    this.injector.get(GlobalBarService).setEnableDefaultBars(false);

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('documents-employee-detail-docs-page')
      .then((pageTranslation: any) => {
        this.pageTranslation = pageTranslation;
        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        this.allEmployeeDocs = [];
        this.employeeDocsToDisplay = [];
        this.userPersonal = null;
        this.userAccount = null;

        this.injector.get(GlobalBarService).setProgressBar(true);

        this.employeeId = id;

        this.fetchData();
      });
  }

  private async fetchData(): Promise<void> {
    try {
      this.isDataLoaded = false;

      const getAllPermissions = this.injector.get(PrivateSecurityService).getAllPermissions();
      const getUserAccount = this.injector.get(UserAccountService).getById(this.employeeId);
      const getUserPersonal = this.injector.get(UserPersonalService).getById(this.employeeId);
      const getEmployeeDocs = this.injector.get(DocumentService).getEmployeeDocs(this.employeeId);
      const getDocumentTags = this.injector.get(DocumentTagService).getDocumentTags();

      const [permissions, userAccount, userPersonal, employeeDocs, documentTags] = await Promise.all([
        getAllPermissions,
        getUserAccount,
        getUserPersonal,
        getEmployeeDocs,
        getDocumentTags,
      ]);

      this.canViewHiddenDocs =
        check.assigned(permissions) &&
        check.assigned(permissions['document-app']) &&
        check.assigned(permissions['document-app'].c_viewHiddenDocuments) &&
        permissions['document-app'].c_viewHiddenDocuments === true;
      this.canUploadDocs = await this.getCanUploadPermission(permissions['document']);

      this.userAccount = userAccount;
      this.userPersonal = userPersonal;
      this.allEmployeeDocs = employeeDocs;
      this.allDocumentTags = documentTags;

      this.filterDocuments();
      this.getTagsToDisplay();

      this.isDataLoaded = true;
      this.injector.get(GlobalBarService).setProgressBar(false);
    } catch {
      this.canViewHiddenDocs = false;
      this.userAccount = null;
      this.userPersonal = null;
      this.allEmployeeDocs = [];
      this.allDocumentTags = [];
    }
  }

  onBackClick(): void {
    this.backButtonSubscription.unsubscribe();

    if (this.route?.snapshot?.queryParams?.ep === 'true') {
      this.router.navigate([this.injector.get(PeopleDetailService).lastOpenedRoute]);
      return;
    }
    this.employeeId = '';
    this.router.navigate(['/cloud/documents/employee-docs']);
  }

  getColor(color: string): string {
    if (check.not.assigned(color) || color === '') {
      return '#757575';
    }

    return userColorConstants[color];
  }

  onChangeFilter(show: boolean, tag: IDocumentTagModel): void {
    if (check.not.assigned(tag)) {
      return;
    }

    if (check.not.assigned(show) || show === false) {
      this.enabledTags = this.enabledTags.filter((iTagId) => {
        return iTagId !== tag._id;
      });
    } else {
      this.enabledTags = this.enabledTags.concat([tag._id]);
    }

    this.filterDocuments();
  }

  private getTagsToDisplay(): void {
    this.tagsToDisplay = this.allDocumentTags.filter((iDocumentTag) =>
      this.allEmployeeDocs.some((iDocument) => iDocument.tags.includes(iDocumentTag._id))
    );

    this.addUnlabelledTagToFilter();
  }

  private filterDocuments(): void {
    this.employeeDocsToDisplay = this.allEmployeeDocs.filter((iDocument) => {
      if (this.enabledTags.length === 0) {
        return true;
      }

      let hasSomeEnabledTag: boolean;

      hasSomeEnabledTag = iDocument.tags.some((iTagId) => {
        return check.contains(this.enabledTags, iTagId);
      });

      if (this.isAnyUnlabelledDoc && this.enabledTags.some((tag) => tag === this.unlabelledTag._id) && iDocument.tags.length === 0) {
        hasSomeEnabledTag = true;
      }

      return hasSomeEnabledTag;
    });

    this.employeeVisibleDocs = this.employeeDocsToDisplay.filter((doc) => {
      return check.assigned(doc.hidden) && doc.hidden === false;
    });

    this.employeeHiddenDocs = this.employeeDocsToDisplay.filter((doc) => {
      return check.assigned(doc.hidden) && doc.hidden === true;
    });
  }

  uploadVisibleDoc(): void {
    this.injector.get(PrivateAmplitudeService).logEvent('intent to add employee doc', { platform: 'Web', category: 'PQL' });
    this.injector.get(PrivateIntegrationsService).trackChameleonEvent('intent to add employee doc');
    const uploadConfig: IUploadDocumentConfig = {
      relatedTo: {
        typeRelatedTo: 'User',
        idRelatedTo: this.employeeId,
      },
      viewableByUser: true,
    };

    this.uploadDoc(uploadConfig);
  }

  uploadHiddenDoc(): void {
    this.injector.get(PrivateAmplitudeService).logEvent('intent to add employee doc', { platform: 'Web', category: 'PQL' });
    this.injector.get(PrivateIntegrationsService).trackChameleonEvent('intent to add employee doc');
    const uploadConfig: IUploadDocumentConfig = {
      relatedTo: {
        typeRelatedTo: 'User',
        idRelatedTo: this.employeeId,
      },
      viewableByUser: false,
    };

    this.uploadDoc(uploadConfig);
  }

  private uploadDoc(uploadConfig: IUploadDocumentConfig): void {
    this.injector
      .get(UppyHelperService)
      .uploadGenericDocument({ maxDocSize: this.MAX_DOC_SIZE })
      .then((rawDocument: IFileMetadata | null) => {
        if (check.not.assigned(rawDocument)) {
          return Promise.resolve(null);
        }

        return new Promise<void>((resolve, reject) => {
          const dialogData = {
            rawDocument,
            uploadConfig,
          };

          const dialogRef = this.injector.get(MatLegacyDialog).open(UploadDocumentDialog, { data: dialogData });
          dialogRef.afterClosed().subscribe((uploadedDocument: IDocumentModel) => {
            if (check.not.assigned(uploadedDocument)) {
              return;
            }

            resolve();
          });
        });
      })
      .then(() => {
        this.fetchData();
      })
      .then(() => {
        this.addUnlabelledTagToFilter();
      })
      .catch(() => {
        // An error already shown
        this.fetchData();
      });
  }

  private getCanUploadPermission(docPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (check.not.assigned(docPermissions) || check.emptyObject(docPermissions)) {
        resolve(false);
      }

      if (check.assigned(docPermissions.create_all) && docPermissions.create_all === true) {
        resolve(true);
      }

      if (check.not.assigned(docPermissions.create_custom) || check.emptyArray(docPermissions.create_custom)) {
        resolve(false);
      }
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          const myUserWork = allUserWork.find((iUserWork) => {
            return iUserWork._id === this.employeeId;
          });
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            docPermissions.create_custom,
            docPermissions.create_own,
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  droppedDocument(event: any): void {
    // Not moved
    if (event.previousContainer !== event.container) {
      this.updateDocumentVisibility();
      return;
    }
    this.selectedDocumentToDrop = {};
    this.selectedDocumentOrigin = '';
  }

  startToDragDocument(selectedDocument: IDocumentModel, origin: string): void {
    this.selectedDocumentToDrop = selectedDocument;
    this.selectedDocumentOrigin = origin;
  }

  private updateDocumentVisibility(): void {
    if (this.selectedDocumentOrigin === 'visible') {
      this.employeeVisibleDocs = this.employeeVisibleDocs.filter((document) => {
        return document._id !== this.selectedDocumentToDrop._id;
      });
    }
    if (this.selectedDocumentOrigin === 'hidden') {
      this.employeeHiddenDocs = this.employeeHiddenDocs.filter((document) => {
        return document._id !== this.selectedDocumentToDrop._id;
      });
    }
    this.selectedDocumentToDrop.hidden = this.selectedDocumentOrigin === 'visible';
    const snackBarMessage =
      this.selectedDocumentOrigin === 'visible'
        ? this.pageTranslation.documentMovedToHiddenSnackText
        : this.pageTranslation.documentMovedToVisibleSnackText;
    this.injector
      .get(DocumentService)
      .updateById(this.selectedDocumentToDrop._id, this.selectedDocumentToDrop)
      .then(() => {
        const documentEditedSnackText = this.injector
          .get(I18nDataPipe)
          .transform(snackBarMessage, { name: this.selectedDocumentToDrop.name });
        this.injector.get(MatLegacySnackBar).open(documentEditedSnackText, 'OK', {
          duration: 5000,
        });
        this.selectedDocumentToDrop = {};
        this.selectedDocumentOrigin = '';
        this.fetchData();
      })
      .catch(() => {
        // An error is already shown
      });
  }

  private addUnlabelledTagToFilter(): void {
    this.isAnyUnlabelledDoc = this.allEmployeeDocs.some((iDoc) => iDoc.tags.length === 0);

    if (this.isAnyUnlabelledDoc === false) {
      return;
    }

    this.unlabelledTag = this.allDocumentTags.find((iDocumentTag) => iDocumentTag._id === picklists.DOCUMENT_UNLABELLED_ID);

    const unlabelledTagIndex = this.tagsToDisplay.findIndex((iTag) => iTag._id === picklists.DOCUMENT_UNLABELLED_ID);

    if (unlabelledTagIndex < 0) {
      this.tagsToDisplay.push(this.unlabelledTag);
    }
  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();
  }
}
