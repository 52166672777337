import { Component, Injector, Input, OnInit } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'orgos-review-card',
  templateUrl: 'review-card.component.html',
  styleUrls: ['review-card.component.scss']
})
export class ReviewCardComponent implements OnInit {
  @Input() review: any;
  @Input() type: string = 'performance';

  translations: any = {};
  miscTranslations: any = {};
  STATUS_COLORS: any = {
    notStarted: '#8f8f8f',
    started: '#ff8d0b',
    shared: '#2c7ded',
    signed: '#00b72e'
  };
  RATING_VALUES: any = {};

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('review-card-component')
      .then((translations: any) => {
        this.translations = translations;
      })
      .catch(() => {
        this.translations = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('performance-review-misc')
      .then((translations: any) => {
        this.miscTranslations = translations;
      })
      .catch(() => {
        this.miscTranslations = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((translations: any) => {
        this.RATING_VALUES = translations.performanceReviewRating;
      })
      .catch(() => {
        this.translations = {};
      });
  }
}
