<div class="sws-main-container kenjo-p-40px">
  <orgos-column-container>
    <orgos-column size="1" class="kenjo-font-size-20px kenjo-font-color-text-333333">
      {{dialogTranslation.title}}
    </orgos-column>
    <orgos-column size="0">
      <kenjo-icon [size]="20" (click)="closeDialog()" class="kenjo-cursor-pointer">clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>
  <kenjo-time-table [readOnly]="true" [workScheduleTemplate]="workScheduleTemplate" class="kenjo-mt-40px"></kenjo-time-table>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{dialogTranslation.okButton}}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</div>
