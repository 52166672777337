import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { CreateListViewDialog } from '@app/standard/components/list-view/dialogs/create-list-view.dialog';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { GlobalBarService, IMenuOption } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IPreferenceModel } from '@app/standard/services/preference/preference.service';
import * as check from 'check-types';

export { IMenuOption } from '@app/standard/services/core/global-bar.service';

@Component({
  selector: 'kenjo-generic-filter-page',
  template: '',
})
export abstract class GenericFilterPage implements OnInit {
  protected hasGlobalBar = true;

  protected selectedView: string;
  protected sortBy: string;
  protected sortOrder: string;
  protected profilePermissions: object;
  protected preferences: IPreferenceModel;
  public filters: any;

  protected SEARCH_CHARACTERS_MIN_NUMBER: number = 3;
  protected dataLoaded: boolean = false;
  private _loadingPage: boolean = true;
  private setLoadingPage(loadingPage: boolean) {
    this._loadingPage = loadingPage;
    this.injector.get(GlobalBarService).setProgressBar(loadingPage);
  }
  get loadingPage(): boolean {
    return this._loadingPage;
  }

  public queryOptions: any = {};
  protected globalBarConfig: IGlobalBarConfig = {
    pageName: '',
    enableFullScreenMode: false,
    fullScreenModeUseSameUrl: true,
    secondaryMenuOptions: [],
  };

  // List
  protected DEFAULT_RECORDS_PER_PAGE: number = 25;
  protected DEFAULT_PAGE: number = 1;
  protected recordsToShowSelector: Array<number> = [25, 50, 100];
  protected PAGE_SELECTOR: any = {
    first: 1,
    previous: 2,
    next: 3,
    final: 4,
  };
  protected paginationConfiguration: any = {
    numberOfPages: 1,
    totalOfRecords: 0,
  };

  // Child components MUST override these attributes
  protected PREFERENCE_VIEW: string;
  protected PREFERENCE_OPTION_KEY: string;
  protected FILTERS_KEY: string;
  protected DEFAULT_SORT_BY: string;
  protected DEFAULT_SORT_ORDER: string = 'asc';
  protected translationResources: Array<ITranslationResource> = [];
  protected profilePermissionsResources: Array<string> = [];
  protected sortableColumnOptions: any = {};
  protected fieldToQueryOptionMap: any = {};

  private _i18n: any = {};
  get i18n(): any {
    return this._i18n;
  }

  constructor(protected injector: Injector, protected router: Router) {}

  ngOnInit(): void {
    this.initPage();
  }

  /*
   *  IMPORTANT! All generic method can't be override
   */

  private async initPage(): Promise<void> {
    try {
      this.injector.get(GlobalBarService).setProgressBar(true);
      this.setLoadingPage(true);

      await this.beforeInit();
      await this.fetchTranslationsGeneric();
      await this.fetchProfilePermissions();
      await this.configureGlobalBarGeneric();
      await this.getPreferencesGeneric();
      await this.fetchRelatedDataGeneric();
      await this.populateFiltersGeneric();
      await this.fetchDataGeneric();
      await this.afterInit();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, GenericFilterPage.name, 'initPage');
    } finally {
      this.setLoadingPage(false);
      this.injector.get(GlobalBarService).setProgressBar(false);
    }
  }

  protected async beforeInit(): Promise<void> {
    return;
  }

  protected async afterInit(): Promise<void> {
    return;
  }

  private async fetchTranslationsGeneric(): Promise<void> {
    try {
      const translationKeys = [];
      const translationPromises = this.translationResources.map((iTranslationResource: ITranslationResource) => {
        translationKeys.push(iTranslationResource.name);
        return this.injector.get(InternationalizationService).getAllTranslation(iTranslationResource.translationKey);
      });

      const translations = await Promise.all(translationPromises);

      translationKeys.forEach((iTranslationKey, i) => {
        this._i18n[iTranslationKey] = translations[i];
      });
    } catch {
      this.translationResources.forEach((iTranslationResource: ITranslationResource) => {
        this._i18n[iTranslationResource.name] = {};
      });
    }
  }

  private async fetchProfilePermissions(): Promise<void> {
    if (check.not.assigned(this.profilePermissionsResources)) {
      return;
    }

    this.profilePermissions = await this.injector
      .get(PrivateSecurityService)
      .getPermissionsForCollections(this.profilePermissionsResources);
  }

  /*********************************
   *          GLOBAL BAR
   ********************************/

  private async configureGlobalBarGeneric(): Promise<void> {
    const globalBarOptions = await this.getGlobalBarOptions();

    if (!this.hasGlobalBar) {
      return;
    }

    this.globalBarConfig.pageName = this.i18n.page.pageName;
    this.globalBarConfig.secondaryMenuOptions = globalBarOptions;
    this.injector.get(GlobalBarService).setPageName(this.globalBarConfig.pageName);
    this.injector.get(GlobalBarService).setSecondaryMenuOptions(this.globalBarConfig.secondaryMenuOptions);
    this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(this.globalBarConfig.selectedSecondaryMenuOption);
  }

  protected abstract getGlobalBarOptions(): Promise<Array<IMenuOption>>;

  /*********************************
   *             DATA
   ********************************/

  private async fetchRelatedDataGeneric(): Promise<void> {
    await this.fetchRelatedData();
  }

  private async fetchDataGeneric(): Promise<void> {
    this.dataLoaded = false;
    await this.fetchData();
    await this.executeDataSideEffects();
    this.dataLoaded = true;
  }

  /*
   * This method should be used to retrieve all related / helper data needed before filters initialization, never to retrieve the list / grid items for the page.
   */
  protected async fetchRelatedData(): Promise<void> {
    return;
  }

  /*
   * Will be executed after each filter change. For this reason we should avoid retrieve date
   * that only is required on initialization.
   */
  protected async fetchData(): Promise<void> {
    if (this.queryOptions.listView === false) {
      await this.fetchGridData();
      return;
    }

    await this.fetchListData();
  }

  protected async refreshData(): Promise<void> {
    try {
      if (this.loadingPage) {
        return;
      }

      this.dataLoaded = false;
      await this.fetchData();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, GenericFilterPage.name, 'refreshData');
    } finally {
      this.dataLoaded = true;
      this.injector.get(GlobalBarService).setProgressBar(false);
    }
  }

  protected abstract fetchListData(): Promise<void>;

  protected abstract fetchGridData(): Promise<void>;

  /*
   * Child components COULD override this method to process or to get extra data based on original data conditions. Not use to execute operations needed after each fetch, just on the first one.
   */
  protected executeDataSideEffects(): void {}

  /*********************************
   *          PREFERENCES
   ********************************/

  private async getPreferencesGeneric(): Promise<void> {
    await this.getPreferences();
    this.setDefaultValues();
    this.setSelectedView();
  }

  protected setDefaultValues(): void {
    if (check.assigned(this.preferences?.preference)) {
      this.queryOptions = { ...this.preferences.preference };

      this.queryOptions.page = this.queryOptions.page ?? this.DEFAULT_PAGE;
      this.queryOptions.recordsPerPage = this.queryOptions.recordsPerPage ?? this.DEFAULT_RECORDS_PER_PAGE;
      this.setPreferenceSortOrder();
      return;
    }

    if (check.assigned(this.queryOptions.listView)) {
      return;
    }

    this.queryOptions.listView = true;
    this.queryOptions.page = this.DEFAULT_PAGE;
    this.queryOptions.recordsPerPage = this.DEFAULT_RECORDS_PER_PAGE;
    this.queryOptions.sortBy = this.DEFAULT_SORT_BY;
    this.queryOptions.sortOrder = this.DEFAULT_SORT_ORDER;
    this.sortBy = this.DEFAULT_SORT_BY;
    this.sortOrder = this.DEFAULT_SORT_ORDER;

    this.setSpecificDefaultValuesForPage();
  }

  /*
   * Child components COULD override this method in order to initialize specific default values for each page.
   */
  protected setSpecificDefaultValuesForPage(): void {
    return;
  }

  /*
   * Child components MUST override getCurrentQueryValues in order to initialize the filters for the page.
   */
  protected abstract getCurrentQueryValues(): any;

  /*
   * Child components MUST override getPreferences in order get the page preferences.
   */
  protected abstract getPreferences(): void;

  /*********************************
   *           FILTERS
   ********************************/

  protected async populateFiltersGeneric(): Promise<void> {
    await this.getFilters();
    this.initBarFilters();
  }

  protected initBarFilters(): void {
    const currentQueryValues = this.getCurrentQueryValues();
    const filterBarKeys = Object.keys(this.filters);

    filterBarKeys.forEach((iKey) => {
      this.filters[iKey].forEach((iOption) => {
        iOption.name = iOption.name;
        iOption._id = iOption.value;
        iOption.active = currentQueryValues[iKey]?.includes(iOption.value);
      });
    });

    this.filters = { ...this.filters };
  }

  public async toggleFilter({ value, active, field }): Promise<void> {
    try {
      this.resetView();
      const fieldName = this.fieldToQueryOptionMap[field];
      this.executeActionOnFilter(active, value, fieldName);

      this.refreshAfterResettingPagination();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, GenericFilterPage.name, 'toggleFilter');
    }
  }

  /*
   * This method could be override by child component when a different flow for add or remove filters is required. Is the case for special or custom filters
   */
  protected executeActionOnFilter(isActive, value, fieldName): void {
    if (isActive) {
      this.addFilter(value, fieldName);
      return;
    }

    this.removeFilter(value, fieldName);
  }

  public async clearAllFilters(): Promise<void> {
    this.resetView();

    this.clearCustomFiltersGeneric();

    if (check.assigned(this.queryOptions.search)) {
      this.queryOptions.search = '';
    }

    this.initBarFilters();
    this.refreshAfterResettingPagination();
  }

  public async searchByTerm(searchTerm: string): Promise<void> {
    const processedSearchTerm = searchTerm.trim();

    if (processedSearchTerm === this.queryOptions.search) {
      return;
    }

    if (processedSearchTerm.length > 0 && processedSearchTerm.length < this.SEARCH_CHARACTERS_MIN_NUMBER) {
      return;
    }

    this.resetView();
    this.queryOptions.search = processedSearchTerm;

    this.refreshAfterResettingPagination();
  }

  private clearCustomFiltersGeneric(): void {
    this.clearCustomFilters();
  }

  protected clearCustomFilters(): void {
    return;
  }

  /*
   * Child components MUST override getFilters in order to retrieve the filters for the page.
   */
  protected abstract getFilters(): void;

  protected abstract clearFilter(field: any): Promise<void>;

  protected abstract addFilter(value: string, field: any): void;

  protected abstract removeFilter(value: string, field: any): void;

  /*********************************
   *            VIEWS
   ********************************/

  public createView(): void {
    const viewInfo = {
      viewType: this.PREFERENCE_VIEW,
      filters: this.queryOptions,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateListViewDialog, { data: { viewInfo } });
    dialogRef.afterClosed().subscribe(async (viewName) => {
      if (check.assigned(viewName)) {
        this.selectedView = viewName;
        this.queryOptions.selectedView = this.selectedView;
        if (this.PREFERENCE_VIEW === 'view-people-directory-list') {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('save view', { platform: 'Web', category: 'People', subcategory1: 'Directory' });
        }

        await this.refreshData();

        this.injector.get(MatLegacySnackBar).open(this.i18n.page.viewCreatedMessage, 'OK', {
          duration: 5000,
        });
      }
    });
  }

  /**
   *  Send new preferences to engine if a view is updated or removed with the current configuration and update list.
   *  If 'ALL' view is selected queryOptions are restarted
   */
  protected switchView(viewInfo: any): void {
    if (check.not.assigned(viewInfo) || check.emptyObject(viewInfo)) {
      return;
    }

    const previousSelection = { ...this.queryOptions };

    viewInfo.filters.listView = previousSelection.listView;

    this.queryOptions = viewInfo.filters;
    this.selectedView = viewInfo.viewName;

    this.initBarFilters();

    if (check.not.assigned(this.queryOptions) || check.emptyObject(this.queryOptions) || this.selectedView === 'All') {
      this.queryOptions = {
        listView: previousSelection.listView,
        page: this.DEFAULT_PAGE,
        recordsPerPage: this.DEFAULT_RECORDS_PER_PAGE,
      };
      this.setPreferenceSortOrder();
    }

    if (this.queryOptions.selectedView !== 'All') {
      this.queryOptions.selectedView = this.selectedView;
      if (check.assigned(this.queryOptions.sortBy)) {
        this.sortBy = this.queryOptions.sortBy;
        this.sortOrder = this.queryOptions.sortOrder;
      }
    }

    if (check.assigned(previousSelection.groupBy)) {
      this.queryOptions.groupBy = previousSelection.groupBy;
    }

    this.refreshData();
  }
  private setPreferenceSortOrder(): void {
    if (check.assigned(this.preferences?.preference?.sortBy)) {
      this.sortBy = this.preferences.preference.sortBy;
      this.sortOrder = this.preferences.preference.sortOrder;
    } else {
      this.sortBy = this.DEFAULT_SORT_BY;
      this.sortOrder = this.DEFAULT_SORT_ORDER;
    }
  }

  private setSelectedView(): void {
    if (
      check.assigned(this.queryOptions.selectedView) &&
      check.not.emptyString(this.queryOptions.selectedView) &&
      this.queryOptions.selectedView !== 'All'
    ) {
      this.selectedView = this.queryOptions.selectedView;
    }
  }

  protected async toggleView(option: boolean): Promise<void> {
    if (this.queryOptions.listView === option) {
      return;
    }

    this.queryOptions.listView = option;

    await this.refreshData();
  }

  protected resetView(): void {
    this.selectedView = ' ';
    this.queryOptions.selectedView = ' ';
  }

  /*********************************
   *             SORT
   ********************************/

  /**
   *  When the current sort column criteria changes it is saved by fetching data
   *  1. updateSortInQuery() prepares queryOptions to indicate sort criteria for the field into the corresponding collection
   */
  protected async changeColumnSort(sortOptions: any): Promise<void> {
    if (check.not.assigned(sortOptions) || check.emptyObject(sortOptions)) {
      return;
    }

    this.sortOrder = sortOptions.sortOrder;
    this.sortBy = sortOptions.sortBy;
    const sortFieldInfo = this.sortBy.split('.');

    if (sortFieldInfo.length !== 2) {
      return;
    }

    const [collection, field] = sortFieldInfo;

    if (
      check.not.assigned(this.sortOrder) ||
      check.emptyString(this.sortOrder) ||
      check.not.assigned(collection) ||
      check.emptyString(collection) ||
      check.not.assigned(field) ||
      check.emptyString(field)
    ) {
      return;
    }

    this.updateSortInQuery(collection, field, this.sortOrder);

    await this.refreshData();
  }

  private updateSortInQuery(collection: string, field: string, sortOrder: string): void {
    if (check.not.assigned(this.queryOptions[collection])) {
      this.queryOptions[collection] = {};
    }

    this.queryOptions.sortBy = this.sortBy;
    this.queryOptions.sortOrder = this.sortOrder;
    this.queryOptions[collection].sortBy = field;
    this.queryOptions[collection].sortOrder = sortOrder;

    this.cleanCollectionsInQuery(collection, sortOrder);
  }

  protected cleanCollectionsInQuery(sortedCollection: string, sortOrder: string): void {
    const columns = Object.keys(this.sortableColumnOptions);

    for (let i = 0; i < columns.length; i++) {
      const sortableColumn = this.queryOptions[columns[i]];

      if (check.not.assigned(sortableColumn) || check.not.assigned(sortableColumn.sortBy) || check.not.assigned(sortableColumn.sortOrder)) {
        continue;
      }

      if (sortedCollection === columns[i]) {
        this.sortOrder = sortOrder;
        continue;
      }

      if (check.not.assigned(sortableColumn?.where)) {
        delete this.queryOptions[columns[i]];
      }

      delete sortableColumn.sortBy;
      delete sortableColumn.sortOrder;
    }
  }

  /*********************************
   *          PAGINATION
   ********************************/

  public async moveToPage(option: number): Promise<void> {
    if (option === this.PAGE_SELECTOR['first']) {
      this.queryOptions.page = 1;
    } else if (option === this.PAGE_SELECTOR['previous'] && this.queryOptions.page > 1) {
      this.queryOptions.page--;
    } else if (option === this.PAGE_SELECTOR['next'] && this.queryOptions.page < this.paginationConfiguration.numberOfPages) {
      this.queryOptions.page++;
    } else if (option === this.PAGE_SELECTOR['final']) {
      this.queryOptions.page = this.paginationConfiguration.numberOfPages;
    }

    await this.refreshData();
  }

  /**
   *  When the number of records per page changes it is saved by fetching to the data engine
   */
  public async changeRecordsToShow(recordsPerPage: number): Promise<void> {
    if (recordsPerPage === this.queryOptions.recordsPerPage) {
      return;
    }

    this.queryOptions.recordsPerPage = recordsPerPage;
    this.queryOptions.page = 1;

    await this.refreshData();
  }

  /*********************************
   *          HELPERS
   ********************************/

  protected getLoggedUser(): IUserAccountModel {
    return this.injector.get(AuthenticationService).getLoggedUser();
  }

  protected async refreshAfterResettingPagination(): Promise<void> {
    if (this.queryOptions.listView) {
      await this.moveToPage(this.PAGE_SELECTOR['first']);
      return;
    }

    await this.refreshData();
  }
}

export interface ITranslationResource {
  name: string;
  translationKey: string;
}

export interface IGlobalBarConfig {
  pageName: string;
  enableFullScreenMode: boolean;
  fullScreenModeUseSameUrl: boolean;
  secondaryMenuOptions?: Array<IMenuOption>;
  selectedSecondaryMenuOption?: number;
}
