<div class="spel-main-container">
  <div>
    <!-- Title -->
    <div class="kenjo-font-size-16px kenjo-mb-10px kenjo-font-weight-bold">{{ activateShiftPlanTranslation.workingAreasStep }}</div>
    <div class="kenjo-font-size-14px kenjo-mb-30px kenjo-font-color-light-text-757575">{{ activateShiftPlanTranslation.workingSubtitle }}</div>

    <kenjo-input-search-shift-plan id="spewa-workingAreas" [list]="allWorkingAreas" [placeholder]="activateShiftPlanTranslation.searchWorkingAreas" (filteredResults)="filteredWorkingAreas = $event"></kenjo-input-search-shift-plan>
    <kenjo-settings-shift-plan-select
      *ngIf="filteredWorkingAreas.length > 0"
      [initialData]="allWorkingAreas"
      [data]="filteredWorkingAreas"
      [dataType]="activateShiftPlanTranslation.workingAreasStep"
      (onChange)="changeSelectedWorkingAreas($event)"
    ></kenjo-settings-shift-plan-select>
  </div>
</div>

<!-- Navigation buttons for creation-->
<div *ngIf="userWork.data.shiftPlanActivationStatus !== 'finished'" class="kenjo-text-align-right kenjo-mt-20px">
  <orgos-button-raised id="spewa-previous-button" [color]="'Neutral'" class="kenjo-mr-20px" (click)="previousStep()">{{ activateShiftPlanTranslation.backButton }}</orgos-button-raised>
  <orgos-button-raised id="spewa-next-button" [color]="'Success'" (click)="nextStep()" [disabled]="selectedWorkingAreas.length <= 0">{{ activateShiftPlanTranslation.nextButton }}</orgos-button-raised>
</div>

<!-- Navigation buttons for edition-->
<div *ngIf="userWork.data.shiftPlanActivationStatus === 'finished'" class="kenjo-text-align-right kenjo-mt-20px">
  <orgos-button-raised id="spewa-previous-button" [color]="'Neutral'" class="kenjo-mr-20px" [disabled]="!dataHasChanged" (click)="cancelChanges(); dataHasChanged = false">{{ activateShiftPlanTranslation.cancelButton }}</orgos-button-raised>
  <orgos-button-raised id="spewa-saveButton" [color]="'Success'" [disabled]="!dataHasChanged || selectedWorkingAreas.length <= 0" (click)="saveData(); saveSnackbar(); dataHasChanged = false">{{ activateShiftPlanTranslation.saveButton }} </orgos-button-raised>
</div>
