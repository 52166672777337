import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class PayrollController {
  private PAYROLL_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/payroll`;

  constructor(private injector: Injector, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  async getAllPayrollSettings(): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.PAYROLL_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, PayrollController.name, 'getAllPayrollSettings');
        throw this.errorManager.handleRawError(error, PayrollController.name, 'getAllPayrollSettings');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get<any>(`${this.PAYROLL_CONTROLLER_URL}/payroll-settings`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollController.name, 'getAllPayrollSettings');
    }
  }

  async getPayrollGroupData(payrollGroupId: string, periodIdentifierValue: number): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.PAYROLL_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, PayrollController.name, 'getPayrollGroupData');
        throw this.errorManager.handleRawError(error, PayrollController.name, 'getPayrollGroupData');
      }

      const body = {
        payrollGroupId,
        periodIdentifierValue
      };

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.post<any>(`${this.PAYROLL_CONTROLLER_URL}/payroll-group-data`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollController.name, 'getPayrollGroupData');
    }
  }

  async getPersonalPayroll(userId: string, year: number): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.PAYROLL_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, PayrollController.name, 'getPersonalPayroll');
        throw this.errorManager.handleRawError(error, PayrollController.name, 'getPersonalPayroll');
      }

      if (check.not.assigned(userId) || check.not.assigned(year)) {
        const error = new OrgosError(this.PAYROLL_CONTROLLER_URL, ErrorCodes.BAD_REQUEST, PayrollController.name, 'getPersonalPayroll');
        throw this.errorManager.handleRawError(error, PayrollController.name, 'getPersonalPayroll');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.http.get<any>(`${this.PAYROLL_CONTROLLER_URL}/personal-payroll/${userId}/${year}`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollController.name, 'getAllPayrollSettings');
    }
  }

  async checkForCorrections(payrollGroupId, fromDate, toDate, content): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.PAYROLL_CONTROLLER_URL, ErrorCodes.UNAUTHORIZED, PayrollController.name, 'checkForCorrections');
        throw this.errorManager.handleRawError(error, PayrollController.name, 'checkForCorrections');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const body = {
        payrollGroupId,
        fromDate,
        toDate,
        content
      };

      return await this.http.post<any>(`${this.PAYROLL_CONTROLLER_URL}/payroll-group-data/corrections`, body, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, PayrollController.name, 'checkForCorrections');
    }
  }
}
