<orgos-dialog-container
  [title]="titleText"
  [employees]="employees"
  (clickCloseButton)="closeDialog()"
  [attr.id]="componentId !== null ? componentId : null"
>
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 cd-white-space">
    {{ subtitleText }}
  </div>
  <div class="cd-white-space kenjo-mt-40px">
    <div class="kenjo-mt-15px" *ngIf="conflicts?.overlappingConflict">
      <p>{{ conflicts.text.overlappingEntriesOn }}</p>
      <div *ngIf="conflicts?.overlappingPublicHolidays" class="cd-conflicts-menu-chip">
        {{ conflicts.text.overlappingPublicHolidays }}
        <kenjo-icon
          *ngIf="conflicts?.configuration?.isPublicHolidayPastValue"
          class="kenjo-cursor-pointer"
          [matTooltip]="conflicts.text.tooltipConflictOff"
          >history</kenjo-icon
        >
      </div>
      <div *ngIf="conflicts?.overlappingNonWorkingDay" class="cd-conflicts-menu-chip">
        {{ conflicts.text.overlappingNonWorkingDay }}
        <kenjo-icon
          *ngIf="conflicts?.configuration?.isNonWorkingDayPastValue"
          class="kenjo-cursor-pointer"
          [matTooltip]="conflicts.text.tooltipConflictOff"
          >history</kenjo-icon
        >
      </div>
    </div>
    <div class="kenjo-mt-15px" *ngIf="conflicts?.missingTime">
      <p>{{ conflicts.text.missingTime }}</p>
      <div *ngIf="conflicts?.entryNotCompleted" class="cd-conflicts-menu-chip">
        {{ conflicts.text.entryNotCompleted }}
      </div>
      <div *ngIf="conflicts?.missingFullEntry" class="cd-conflicts-menu-chip">
        {{ conflicts.text.missingFullEntry }}
      </div>
    </div>
    <div class="kenjo-mt-15px" *ngIf="conflicts?.maxDailyHours">
      <p>{{ conflicts.text.maximumHours }}</p>
      <div class="cd-conflicts-menu-chip">
        {{ conflicts?.configuration?.maxHoursValue | duration }} {{ conflicts.text.limitExceeded }}
        <kenjo-icon
          *ngIf="conflicts?.configuration?.isMaxHoursPastValue"
          class="kenjo-cursor-pointer"
          [matTooltip]="
            attendancePolicy.limitDailyHours?.isActive && attendancePolicy.limitDailyHours?.conflicts
              ? (conflicts.text?.tooltipConflict | i18nData: { timeLimit: attendancePolicy?.limitDailyHours?.limit | duration })
              : conflicts.text.tooltipConflictOff
          "
          >history</kenjo-icon
        >
      </div>
    </div>
    <div class="kenjo-mt-15px" *ngIf="conflicts?.minBreakTime">
      <p>{{ conflicts.text.breakScheme }}</p>
      <div class="cd-conflicts-menu-chip">
        {{ conflicts?.configuration?.minBreakValue | duration: false:true:true:true }} {{ conflicts.text.breakNotReached }}
        <kenjo-icon
          *ngIf="conflicts?.configuration?.isMinBreakPastValue"
          class="kenjo-cursor-pointer"
          [matTooltip]="
            attendancePolicy?.breakReminder?.conflicts && attendancePolicy?.breakReminder?.reminders?.length
              ? (conflicts.text?.tooltipConflict | i18nData: { timeLimit: conflicts?.text?.minBreakToday | duration: false:true:true:true })
              : conflicts.text.tooltipConflictOff
          "
          >history</kenjo-icon
        >
      </div>
    </div>
  </div>
  <div *ngIf="bullets" class="kenjo-mt-20px">
    <ul *ngFor="let bullet of bullets">
      <li>
        <span class="kenjo-font-weight-bold">{{ bullet.title }}</span> - {{ bullet.text }}
      </li>
    </ul>
  </div>
  <orgos-column-container class="kenjo-mt-40px cd-wrap-buttons">
    <orgos-column size="1" class="cd-empty-column"></orgos-column>
    <orgos-column *ngIf="cancelButtonText" size="0" class="cd-flex-shrink">
      <orgos-button-raised [disabled]="cancelDisabled" color="Neutral" (click)="closeDialog()" class="cd-close cd-flex-shrink">{{
        cancelButtonText
      }}</orgos-button-raised>
    </orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="cd-flex-shrink">
      <orgos-button-raised
        [disabled]="confirmDisabled"
        [color]="confirmButtonColor"
        class="kenjo-ml-20px cd-confirm"
        (click)="confirmDialog()"
      >
        {{ confirmButtonText }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
