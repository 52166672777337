<div class="tc-main-container" [ngClass]="{ 'kenjo-full-height': fitParent }">
  <!-- TABLE DATA-->
  <div class="tc-table-container" [ngClass]="{ 'kenjo-full-height': fitParent }">
    <div
      [class]="fullHeight ? 'tc-full-table-wrapper' : 'tc-table-wrapper'"
      [style.max-height]="fullHeight ? maxHeight : maxDynamicHeight"
      [style.overflow]="fullHeight && maxHeight === '100%' ? 'hidden' : 'auto'"
      [ngClass]="{ 'kenjo-full-height': fitParent }"
    >
      <orgos-container *ngIf="displayedColumns && displayedColumns.length > 0">
        <mat-icon
          *ngIf="manageColumns && !inColumnsSetup"
          class="tc-manage-icon kenjo-border-bottom kenjo-border-left"
          [matTooltip]="miscTranslation.manageColumns"
          (click)="openManageColumns()"
          >view_column</mat-icon
        >
        <table
          mat-table
          (matSortChange)="sortColumn($event)"
          matSort
          [matSortActive]="sortBy"
          [matSortDirection]="sortOrder"
          [dataSource]="dataSource"
          [style.min-width]="tableWidth"
          [style.max-height]="'100%'"
          matSortDisableClear="true"
        >
          <ng-container
            *ngFor="let tableColumn of tableColumns; let i = index"
            [matColumnDef]="tableColumn.columnKey"
            [sticky]="stickyColumn && i < numberOfStickyColumns"
          >
            <ng-container *ngIf="selectable === true && tableColumn.columnKey === 'select'">
              <th mat-header-cell *matHeaderCellDef width="16px" class="selection-cell no-width">
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" class="selection-cell no-width">
                <mat-checkbox
                  [disabled]="row.disableTableColumn"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selectCheckbox(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="selectable === false || tableColumn.columnKey !== 'select'">
              <ng-container *ngIf="tableColumn.sortable === true">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [class.tc-action-column]="tableColumn.actionColumn"
                  [class.selection-cell]="tableColumn.selectable"
                  [class.no-width]="tableColumn.cellWidth"
                  [class.tc-center-content]="centerContent"
                  [class.kenjo-pl-40px]="numberOfStickyColumns > 0 && i === numberOfStickyColumns"
                  width="{{ tableColumn.cellWidth }}%"
                >
                  <orgos-column-container centerColumns="true">
                    <mat-checkbox
                      orgosColumn="0"
                      *ngIf="tableColumn.selectable"
                      class="kenjo-pr-20px"
                      (change)="tableColumn.changeColumnSelection($event)"
                      (click)="$event.stopPropagation()"
                      [checked]="tableColumn.allSelected"
                      [indeterminate]="tableColumn.indeterminate"
                    >
                    </mat-checkbox>
                    <div orgosColumn="0">
                      <ng-container *ngIf="tableColumn.headerIcon?.templateRef">
                        <ng-template
                          [ngTemplateOutlet]="tableColumn.headerIcon?.templateRef"
                          [ngTemplateOutletContext]="{ $implicit: row }"
                        ></ng-template>
                      </ng-container>
                    </div>
                    <orgos-column class="header-label-max-width">
                      <orgos-text
                        class="header-text-max-width"
                        [class.header-text-override-width]="overrideWidth[tableColumn.columnKey]"
                        type="SecondaryText"
                        [tooltipPosition]="tooltipPosition"
                        [noWrapText]="
                          tableColumn.showTooltip === true ? (tableColumn.tooltip ? tableColumn.tooltip : tableColumn.header) : ''
                        "
                        >{{ tableColumn.header | uppercase }}</orgos-text
                      >
                    </orgos-column>
                    <mat-icon
                      orgosColumn="0"
                      class="kenjo-pl-10px"
                      *ngIf="tableColumn.info"
                      class="tc-info-icon"
                      [matTooltip]="tableColumn.info"
                      >help_outline</mat-icon
                    >
                  </orgos-column-container>
                </th>
              </ng-container>
              <ng-container *ngIf="tableColumn.sortable === false">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [class.tc-action-column]="tableColumn.actionColumn"
                  [class.selection-cell]="tableColumn.selectable"
                  [class.no-width]="tableColumn.cellWidth"
                  [class.tc-center-content]="centerContent"
                  [class.kenjo-pl-40px]="numberOfStickyColumns > 0 && i === numberOfStickyColumns"
                  width="{{ tableColumn.cellWidth }}%"
                >
                  <orgos-column-container centerColumns="true">
                    <mat-checkbox
                      orgosColumn="0"
                      *ngIf="tableColumn.selectable"
                      class="kenjo-pl-20px"
                      (change)="tableColumn.changeColumnSelection($event)"
                      [checked]="tableColumn.allSelected"
                      [indeterminate]="tableColumn.indeterminate"
                    >
                    </mat-checkbox>
                    <div orgosColumn="0">
                      <ng-container *ngIf="tableColumn.headerIcon?.templateRef">
                        <ng-template
                          [ngTemplateOutlet]="tableColumn.headerIcon?.templateRef"
                          [ngTemplateOutletContext]="{ $implicit: row }"
                        ></ng-template>
                      </ng-container>
                    </div>
                    <orgos-text
                      orgosColumn="0"
                      type="SecondaryText"
                      class="header-label-max-width"
                      [tooltipPosition]="tooltipPosition"
                      [noWrapText]="
                        tableColumn.showTooltip === true ? (tableColumn.tooltip ? tableColumn.tooltip : tableColumn.header) : ''
                      "
                      >{{ tableColumn.header | uppercase }}</orgos-text
                    >
                    <mat-icon
                      orgosColumn="0"
                      class="kenjo-pl-10px"
                      *ngIf="tableColumn.info"
                      class="tc-info-icon"
                      [matTooltip]="tableColumn.info"
                      >help_outline</mat-icon
                    >
                  </orgos-column-container>
                </th>
              </ng-container>
              <td
                mat-cell
                *matCellDef="let row"
                [class.tc-action-column]="tableColumn.actionColumn"
                [class.tc-center-content]="centerContent || tableColumn.centerContent"
                [class.no-width]="tableColumn.cellWidth"
                [class.sticky-right-cell]="numberOfStickyColumns > 0 && i === numberOfStickyColumns"
                [class.multiple-sticky-rows]="numberOfStickyColumns > 0 && i < numberOfStickyColumns"
                [class.position-relative]="positionRelativeTd"
              >
                <ng-container *ngIf="tableColumn && tableColumn.tableCell && tableColumn.tableCell.templateRef">
                  <ng-template
                    [ngTemplateOutlet]="tableColumn.tableCell.templateRef"
                    [ngTemplateOutletContext]="{ $implicit: row }"
                  ></ng-template>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            (mouseenter)="viewManageColumns = true"
            style="width: 10px !important"
            (mouseleave)="hideManageIcon()"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="kenjo-pv-10px"
            style="width: 10px !important"
            [class.highlight-row]="!disableRowHighlight"
          ></tr>
        </table>
      </orgos-container>
    </div>
    <!-- END TABLE DATA-->
    <!-- MANAGE COLUMNS-->
    <div
      *ngIf="inColumnsSetup"
      [@openClose]="!closeManageColumsAnimation ? 'open' : 'closed'"
      class="kenjo-color-white-background-ffffff kenjo-border-left tsc-menu-column"
    >
      <orgos-column-container
        centerColumns="true"
        class="kenjo-color-white-background-ffffff kenjo-ph-15px kenjo-pv-15px tsc-manager-columns-header"
      >
        <orgos-column class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ miscTranslation.manageColumns }}</orgos-column>
        <kenjo-icon
          orgosColumn="0"
          [size]="20"
          class="kenjo-ml-30px kenjo-p-5px kenjo-mr-5px kenjo-cursor-pointer kenjo-font-color-light-text-757575"
          (click)="closeManageColumns()"
        >
          clear
        </kenjo-icon>
      </orgos-column-container>
      <div class="kenjo-ph-15px">
        <orgos-list-sortable [items]="columnsControlFiltered" [manageColumns]="true" [dragIndicator]="true" (changedOrder)="saveColumns()">
          <ng-container *orgosListItem="let item; let itemIndex = index">
            <orgos-column-container centerColumns="true" class="tsc-column-manager-row">
              <orgos-column size="1">
                {{ headerLabels[item.columnKey] }}
              </orgos-column>
              <orgos-column size="0" *ngIf="!unselectableColumns.includes(item.columnKey)">
                <kenjo-icon
                  [size]="20"
                  *ngIf="item.visible"
                  class="kenjo-mr-5px kenjo-font-color-primary-5993e3 tsc-menu-column-icon"
                  (click)="changeColumn(item.columnKey)"
                  [disabled]="
                    actionColumns.includes(item.columnKey) ||
                    (((actionColumns && actionColumns.length > 0 && displayedColumns.length === actionColumns.length + 1) ||
                      (unselectableColumns && unselectableColumns.length > 0 && displayedColumns.length === unselectableColumns.length) ||
                      displayedColumns.length === 1) &&
                      item.visible === true)
                  "
                  >visibility</kenjo-icon
                >
                <kenjo-icon
                  [size]="20"
                  *ngIf="!item.visible"
                  class="kenjo-mr-5px kenjo-font-color-light-text-757575 tsc-menu-column-icon"
                  (click)="changeColumn(item.columnKey)"
                  [disabled]="actionColumns.includes(item.columnKey)"
                  >visibility_off</kenjo-icon
                >
              </orgos-column>
            </orgos-column-container>
          </ng-container>
        </orgos-list-sortable>
      </div>
    </div>
  </div>
  <!-- END MANAGE COLUMNS-->
</div>
