<orgos-column-container *ngIf="selectTriggerWorkflow === false && userDataLoaded && !seeDetailsUserId">
  <orgos-column size="7">
    <orgos-container aspect="card">
      <orgos-column-container>
        <orgos-column></orgos-column>
        <orgos-column>
          <div class="kenjo-m-20px" [ngClass]="{ 'kenjo-text-align-center': !payrollGroupId || !payrollPeriod }">
            <orgos-user-item
              *ngIf="userPersonal && userWork && userAccount"
              [title]="userPersonal.data?.displayName"
              [subtitle]="userWork.data?.jobTitle"
              [photoUrl]="userPersonal.data?._photo?._url"
              [enableChangePhoto]="userPersonal.canEdit | async"
              [userAccountSatus]="userAccount?.data"
              [invitationStatus]="userAccount?.data"
              (changePhoto)="showPicker()"
              class="kenjo-inline-block kenjo-text-align-left"
            >
            </orgos-user-item>
          </div>
        </orgos-column>
        <orgos-column size="0" *ngIf="payrollGroupId && payrollPeriod">
          <orgos-action icon="launch" (click)="navigateToPayroll()" class="kenjo-m-20px kenjo-pt-5px"
            >{{ peopleDetailPageTranslation.goToPayrollTab }}</orgos-action
          >
        </orgos-column>
        <orgos-column *ngIf="!payrollGroupId || !payrollPeriod"></orgos-column>
      </orgos-column-container>

      <div *ngIf="userPersonal && userPersonal.canRead | async" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.personalSectionTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text
            orgosColumn
            [model]="userPersonal"
            field="firstName"
            required="true"
            (validation)="firstNameValidation = $event"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="firstNameValidation && firstNameValidation.getError('required')">
              {{peopleDetailPageTranslation.firstNameRequired}}</orgos-input-error
            >
          </orgos-input-text>
          <orgos-input-text
            orgosColumn
            [model]="userPersonal"
            field="lastName"
            required="true"
            (validation)="lastNameValidation = $event"
            class="kenjo-ml-10px"
          >
            <orgos-input-error *ngIf="lastNameValidation && lastNameValidation.getError('required')">
              {{peopleDetailPageTranslation.lastNameRequired}}</orgos-input-error
            >
          </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userPersonal" field="middleName" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text
            orgosColumn
            [model]="userPersonal"
            field="displayName"
            required="true"
            (validation)="displayNameValidation = $event"
            (modelChange)="refreshUserInGlobalBar()"
            class="kenjo-ml-10px"
            (cleanSpaces)="true"
          >
            <orgos-input-error *ngIf="displayNameValidation && displayNameValidation.getError('required')">
              {{peopleDetailPageTranslation.displayNameRequired}}</orgos-input-error
            >
          </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            [model]="userPersonal"
            field="birthdate"
            [enableClearButton]="true"
            [max]="today"
            (validation)="birthdateValidation = $event"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="birthdateValidation && birthdateValidation.getError('max')">
              {{peopleDetailPageTranslation.birthdateNoValidFuture}}</orgos-input-error
            >
            <orgos-input-error *ngIf="birthdateValidation && birthdateValidation.getError('date')">
              {{peopleDetailPageTranslation.birthdateNoValidFormat}}</orgos-input-error
            >
          </orgos-input-date-picker>
          <orgos-input-select
            orgosColumn
            [model]="userPersonal"
            field="gender"
            picklist="gender"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            [model]="userPersonal"
            field="nationality"
            picklist="nationality"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-text
            orgosColumn
            [model]="userPersonal"
            field="linkedin"
            prefix="https://www.linkedin.com/in/"
            class="kenjo-ml-10px"
          ></orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userPersonal" field="skype" class="kenjo-mr-10px"></orgos-input-text>
          <orgos-input-text orgosColumn [model]="userPersonal" field="slack" prefix="@" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text
            orgosColumn
            [model]="userPersonal"
            field="twitter"
            prefix="https://twitter.com/"
            class="kenjo-mr-10px"
          ></orgos-input-text>
          <orgos-input-text
            orgosColumn
            [model]="userPersonal"
            field="facebook"
            prefix="https://www.facebook.com/"
            class="kenjo-ml-10px"
          ></orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px" *ngIf="userDatev">
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['birthName']?.read"
            [readOnly]="!userDatevPermissions['birthName']?.update"
            [model]="userDatev"
            field="birthName"
            class="kenjo-mr-10px"
          ></orgos-input-text>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatev && userDatevPermissions['prefix']?.read"
            [readOnly]="!userDatevPermissions['prefix']?.update"
            picklist="datevPrefix"
            [enableClearButton]="true"
            [model]="userDatev"
            field="prefix"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px" *ngIf="userDatev">
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['birthPlace']?.read"
            [readOnly]="!userDatevPermissions['birthPlace']?.update"
            [model]="userDatev"
            field="birthPlace"
            class="kenjo-mr-10px"
          ></orgos-input-text>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['birthCountry']?.read"
            [readOnly]="!userDatevPermissions['birthCountry']?.update"
            [model]="userDatev"
            field="birthCountry"
            picklist="country"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-editor orgosColumn [model]="userPersonal" field="bio" size="small"></orgos-input-editor>
        </orgos-column-container>
        <orgos-custom-fields
          collectionName="user-personal"
          [model]="userPersonal"
          columnContainerClass="kenjo-mt-20px"
          leftColumnClass="kenjo-mr-10px"
          rightColumnClass="kenjo-ml-10px"
        ></orgos-custom-fields>
      </div>

      <div *ngIf="userWork && userWork.canRead | async" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.workSectionTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userWork" field="jobTitle" class="kenjo-mr-10px"></orgos-input-text>
          <orgos-input-email orgosColumn [model]="userAccount" field="email" readOnly="true" class="kenjo-ml-10px"> </orgos-input-email>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userWork" field="workPhone" class="kenjo-mr-10px"></orgos-input-text>
          <orgos-input-text orgosColumn [model]="userWork" field="workMobile" class="kenjo-ml-10px"></orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            [model]="userWork"
            field="startDate"
            [enableClearButton]="userWorkCanEdit"
            (validation)="startDateValidation = $event"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="startDateValidation && startDateValidation.getError('date')">
              {{peopleDetailPageTranslation.startDateNoValidFormat}}</orgos-input-error
            >
          </orgos-input-date-picker>
          <orgos-input-select
            orgosColumn
            [model]="userWork"
            field="companyId"
            [options]="companyOptions"
            [required]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            [model]="userWork"
            field="officeId"
            [options]="officeOptions"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-column class="kenjo-ml-10px">
            <orgos-column-container centerColumns="true">
              <orgos-column size="1">
                <orgos-input-simple-text
                  class="kenjo-block"
                  [value]="userWork?.data?.virtualOffice?.name"
                  [label]="peopleDetailPageTranslation.virtualOffice"
                  (click)="openVirtualOfficeDialog()"
                  [readOnly]="!userWorkCanEdit"
                ></orgos-input-simple-text>
              </orgos-column>
              <orgos-column
                size="0"
                class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
                *ngIf="userWork && userWork.data && userWork.data.virtualOffice && userWork.data.virtualOffice.name && userWorkCanEdit"
              >
                <kenjo-icon [size]="14" (click)="clearVirtualOffice()">clear</kenjo-icon>
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            [multiple]="true"
            [model]="userWork"
            field="areaIds"
            [options]="areaOptions"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            [model]="userWork"
            field="departmentId"
            [options]="departmentOptions"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px" centerColumns="true">
          <orgos-input-select
            orgosColumn
            [multiple]="true"
            [model]="userWork"
            field="teamIds"
            [options]="teamOptions"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-search-user
            orgosColumn
            [model]="userWork"
            field="reportsToId"
            class="kenjo-ml-10px"
            [users]="reportsToOptions"
            [enableClearButton]="true"
            [showAvatar]="true"
          ></orgos-input-search-user>
        </orgos-column-container>
        <orgos-column-container
          class="kenjo-mt-20px"
          centerColumns="true"
          *ngIf="isTimeOffEnabled === true && (isTimeOffApproverActive || isDelegatedApproverActive && (hasSubordinates || isUserTimeOffApprover))"
        >
          <orgos-input-search-user
            *ngIf="isTimeOffApproverActive === true"
            orgosColumn
            [model]="userWork"
            field="timeOffApproverId"
            (modelChange)="logTimeOffApproverChange()"
            class="kenjo-mr-10px"
            [label]="peopleDetailPageTranslation.timeOffApprover"
            [users]="timeOffUsersOptions"
            [matTooltip]="peopleDetailPageTranslation.timeOffApproverTooltip"
            matTooltipPosition="above"
            [readOnly]="!userWorkCanEdit"
            [enableClearButton]="true"
            [showAvatar]="true"
          ></orgos-input-search-user>
          <orgos-column
            *ngIf="isDelegatedApproverActive === false || (hasSubordinates === false && isUserTimeOffApprover === false)"
            class="kenjo-ml-10px"
          ></orgos-column>
          <orgos-input-search-user
            *ngIf="isDelegatedApproverActive && (hasSubordinates || isUserTimeOffApprover)"
            orgosColumn
            [model]="userPersonal"
            field="delegatedApproverId"
            [ngClass]="isTimeOffApproverActive ? 'kenjo-ml-10px' : 'kenjo-mr-10px'"
            [users]="timeOffUsersOptions"
            [enableClearButton]="true"
            [matTooltip]="peopleDetailPageTranslation.delegatedTimeOffApproverTooltip"
            matTooltipPosition="above"
            [readOnly]="!userWorkCanEdit"
            [showAvatar]="true"
          ></orgos-input-search-user>
          <orgos-column *ngIf="isTimeOffApproverActive === false" class="kenjo-ml-10px"></orgos-column>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px" centerColumns="true">
          <orgos-input-checkbox orgosColumn [model]="userWork" field="isAssistant"> </orgos-input-checkbox>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            [model]="userWork"
            field="probationPeriodEnd"
            [enableClearButton]="userWorkCanEdit"
            (validation)="probationPeriodValidation = $event"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="probationPeriodValidation && probationPeriodValidation.getError('date')">
              {{peopleDetailPageTranslation.probationPeriodNoValidFormat}}</orgos-input-error
            >
          </orgos-input-date-picker>
          <orgos-input-date-picker
            orgosColumn
            [model]="userWork"
            field="contractEnd"
            [enableClearButton]="userWorkCanEdit"
            class="kenjo-ml-10px"
          ></orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-number
            [readOnly]="true"
            *ngIf="myCompany && (!userWorkSchedule || !userWorkSchedule.data.history || !currentWorkSchedule.weeklyMinutes || (userWorkSchedule.data.history && userWorkSchedule.data.history.length === 0))"
            orgosColumn
            [model]="userWork"
            step="0.1"
            field="weeklyHours"
            [min]="1"
            [max]="myCompany?.weeklyHours"
            (currentValue)="tempWeeklyHours = $event"
            suffix="/{{myCompany?.weeklyHours}} ({{tempWeeklyHours / myCompany?.weeklyHours | percent: '1.0-2'}})"
            (validation)="weeklyHoursValidation = $event"
            class="kenjo-mr-10px"
          >
          </orgos-input-number>
          <orgos-input-simple-text
            orgosColumn
            *ngIf="userWorkSchedule && userWorkSchedule.data.history && userWorkSchedule.data.history.length > 0 && currentWorkSchedule.weeklyMinutes"
            [readOnly]="true"
            [label]="peopleDetailPageTranslation.currentWeeklyHours"
            [value]="currentWorkSchedule.weeklyMinutes / 60"
            class="kenjo-block"
          >
          </orgos-input-simple-text>
          <orgos-input-text orgosColumn [model]="userWork" field="employeeNumber" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-custom-fields
          collectionName="user-work"
          [model]="userWork"
          columnContainerClass="kenjo-mt-20px"
          leftColumnClass="kenjo-mr-10px"
          rightColumnClass="kenjo-ml-10px"
        ></orgos-custom-fields>
        <orgos-action *ngIf="userWork && userWork.canEdit | async" icon="add_circle" class="kenjo-mt-20px" (click)="openEditCostCentersDialog()">
          {{peopleDetailPageTranslation.addCostCenterButtonLabel}}</orgos-action
        >
        <div
          *ngFor="let costCenter of userWork.data.costCenters; let costCenterIndex = index"
          class="kenjo-mt-20px kenjo-ph-20px kenjo-border kenjo-border-radius pdp-subcontainer"
        >
          <orgos-column-container centerColumns="true">
            <orgos-input-simple-text
              [readOnly]="true"
              [label]="peopleDetailPageTranslation.costCenterLabel"
              orgosColumn
              [value]="idToName[costCenter._costCenterId]"
              class="kenjo-mr-10px kenjo-pt-15px kenjo-pb-10px"
            ></orgos-input-simple-text>
            <orgos-input-simple-number
              orgosColumn
              [readOnly]="true"
              [label]="peopleDetailPageTranslation.costCenterQuantityLabel"
              [value]="costCenter.quantity"
              [required]="true"
              [min]="0"
              [max]="100"
              step="0.5"
              suffix="%"
              class="kenjo-ml-10px kenjo-pt-15px kenjo-pb-10px"
            >
            </orgos-input-simple-number>
            <orgos-column
              *ngIf="userWork && userWork.canEdit | async"
              size="0"
              class="kenjo-pl-20px kenjo-ml-20px kenjo-border-left pdp-remove-button-separator"
            >
            </orgos-column>
            <kenjo-icon
              *ngIf="userWork && userWork.canEdit | async"
              orgosColumn="0"
              [size]="18"
              (click)="openEditCostCentersDialog(costCenter, costCenterIndex)"
              [matTooltip]="peopleDetailPageTranslation.edit"
              matTooltipPosition="before"
              class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
              >mode_edit</kenjo-icon
            >
            <orgos-column
              *ngIf="userWork && userWork.canEdit | async"
              size="0"
              class="kenjo-pl-20px kenjo-ml-20px kenjo-border-left pdp-remove-button-separator">
            </orgos-column>
            <kenjo-icon
              *ngIf="userWork && userWork.canEdit | async"
              orgosColumn="0"
              [size]="18"
              (click)="removeCostCenter(costCenterIndex)"
              [matTooltip]="peopleDetailPageTranslation.removeChildButtonLabel"
              matTooltipPosition="before"
              class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757"
              >delete</kenjo-icon
            >
          </orgos-column-container>
        </div>
      </div>

      <div *ngIf="userWorkSchedule && userWorkSchedule.canRead | async" class="pdp-section">
        <div
          *ngIf="userWorkSchedule && userWorkSchedule.data && (!userWorkSchedule.data.history || (userWorkSchedule.data.history && userWorkSchedule.data.history.length === 0))"
          class="pdp-work-schedule-header"
        >
          <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.workScheduleSectionTitle}}</div>
          <div *ngIf="(userWorkSchedule && userWorkSchedule.canEdit | async) || (userWork && userWork.canEdit | async)">
            <orgos-action (click)="editCustomScheduleSimple()" icon="mode_edit">{{peopleDetailPageTranslation.edit}}</orgos-action>
          </div>
        </div>
        <orgos-column-container
          *ngIf="userWorkSchedule && userWorkSchedule.data && (!userWorkSchedule.data.history || (userWorkSchedule.data.history && userWorkSchedule.data.history.length === 0))"
          class="kenjo-mt-20px"
          centerColumns="true"
        >
          <orgos-column class="kenjo-mr-10px">
            <orgos-input-toggle-round
              matTooltipPosition="above"
              [matTooltip]="(userWork && userWork.canEdit | async)? peopleDetailPageTranslation.editTooltip : null"
              [readOnly]="true"
              [model]="userWorkSchedule"
              field="mondayWorkingDay"
              [label]="translatedWeekdays[0]"
              class="kenjo-mr-10px"
            ></orgos-input-toggle-round>
            <orgos-input-toggle-round
              matTooltipPosition="above"
              [matTooltip]="(userWork && userWork.canEdit | async)? peopleDetailPageTranslation.editTooltip : null"
              [readOnly]="true"
              [model]="userWorkSchedule"
              field="tuesdayWorkingDay"
              [label]="translatedWeekdays[1]"
              class="kenjo-mr-10px"
            ></orgos-input-toggle-round>
            <orgos-input-toggle-round
              matTooltipPosition="above"
              [matTooltip]="(userWork && userWork.canEdit | async)? peopleDetailPageTranslation.editTooltip : null"
              [readOnly]="true"
              [model]="userWorkSchedule"
              field="wednesdayWorkingDay"
              [label]="translatedWeekdays[2]"
              class="kenjo-mr-10px"
            ></orgos-input-toggle-round>
            <orgos-input-toggle-round
              matTooltipPosition="above"
              [matTooltip]="(userWork && userWork.canEdit | async)? peopleDetailPageTranslation.editTooltip : null"
              [readOnly]="true"
              [model]="userWorkSchedule"
              field="thursdayWorkingDay"
              [label]="translatedWeekdays[3]"
              class="kenjo-mr-10px"
            ></orgos-input-toggle-round>
            <orgos-input-toggle-round
              matTooltipPosition="above"
              [matTooltip]="(userWork && userWork.canEdit | async)? peopleDetailPageTranslation.editTooltip : null"
              [readOnly]="true"
              [model]="userWorkSchedule"
              field="fridayWorkingDay"
              [label]="translatedWeekdays[4]"
              class="kenjo-mr-10px"
            ></orgos-input-toggle-round>
            <orgos-input-toggle-round
              matTooltipPosition="above"
              [matTooltip]="(userWork && userWork.canEdit | async)? peopleDetailPageTranslation.editTooltip : null"
              [readOnly]="true"
              [model]="userWorkSchedule"
              field="saturdayWorkingDay"
              [label]="translatedWeekdays[5]"
              class="kenjo-mr-10px"
            ></orgos-input-toggle-round>
            <orgos-input-toggle-round
              matTooltipPosition="above"
              [matTooltip]="(userWork && userWork.canEdit | async)? peopleDetailPageTranslation.editTooltip : null"
              [readOnly]="true"
              [model]="userWorkSchedule"
              field="sundayWorkingDay"
              [label]="translatedWeekdays[6]"
            ></orgos-input-toggle-round>
          </orgos-column>
          <orgos-column class="kenjo-ml-10px">
            <orgos-column-container centerColumns="true" class="pdp-track-attendance">
              <orgos-column size="0" class="kenjo-mr-10px">
                <kenjo-icon
                  *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === true"
                  [size]="20"
                  class="kenjo-font-color-success-00b72e"
                  >done</kenjo-icon
                >
                <kenjo-icon
                  *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === false"
                  [size]="20"
                  class="kenjo-font-color-danger-ff5757"
                  >clear</kenjo-icon
                >
              </orgos-column>
              <orgos-column class="kenjo-font-color-light-text-757575">
                <ng-container *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === true">
                  {{peopleDetailPageTranslation.attendanceTrackingEnabled}}</ng-container
                >
                <ng-container *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === false">
                  {{peopleDetailPageTranslation.attendanceTrackingDisabled}}</ng-container
                >
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
        </orgos-column-container>
        <div *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.history && userWorkSchedule.data.history.length > 0">
          <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.workScheduleSectionTitle}}</div>
          <orgos-column-container centerColumns="true" class="kenjo-mt-40px" *ngIf="currentWorkSchedule">
            <orgos-column>
              <orgos-input-simple-text
                [readOnly]="true"
                [label]="peopleDetailPageTranslation.assignedWorkScheduleLabel"
                [(value)]="currentWorkSchedule.name"
                class="kenjo-block"
              >
              </orgos-input-simple-text>
            </orgos-column>
            <orgos-column class="kenjo-ml-20px">
              <orgos-column-container centerColumns="true">
                <orgos-column size="1">
                  <orgos-input-simple-text
                    [readOnly]="true"
                    [label]="peopleDetailPageTranslation.expectedHoursLabel"
                    [(value)]="standardPickList?.workScheduleTemplateType[currentWorkSchedule?.type]"
                    class="kenjo-block"
                  >
                  </orgos-input-simple-text>
                </orgos-column>
                <orgos-column size="0">
                  <kenjo-icon
                    [disabled]="!hasCurrentWorkSchedule"
                    [size]="20"
                    (click)="showWorkScheduleDetail()"
                    [ngClass]="{'kenjo-font-color-disabled-c4c4c4': !hasCurrentWorkSchedule, 'kenjo-cursor-pointer kenjo-font-color-primary-5993e3': hasCurrentWorkSchedule }"
                    [matTooltip]="peopleDetailPageTranslation.tooltipExpected"
                    >visibility</kenjo-icon
                  >
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container centerColumns="true" class="kenjo-mt-20px">
            <orgos-column size="1">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-mr-10px">
                  <kenjo-icon
                    *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === true"
                    [size]="20"
                    class="kenjo-font-color-success-00b72e"
                    >done</kenjo-icon
                  >
                  <kenjo-icon
                    *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === false"
                    [size]="20"
                    class="kenjo-font-color-danger-ff5757"
                    >clear</kenjo-icon
                  >
                </orgos-column>
                <orgos-column class="kenjo-font-color-light-text-757575">
                  <ng-container *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === true">
                    {{peopleDetailPageTranslation.attendanceTrackingEnabled}}</ng-container
                  >
                  <ng-container *ngIf="userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === false">
                    {{peopleDetailPageTranslation.attendanceTrackingDisabled}}</ng-container
                  >
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="1"></orgos-column>
          </orgos-column-container>
          <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
            <orgos-column size="1">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-font-size-14px kenjo-font-color-text-333333">
                  {{peopleDetailPageTranslation.scheduleHistoryLabel}}
                </orgos-column>
                <orgos-column size="0" class="kenjo-ml-10px">
                  <kenjo-icon
                    *ngIf="showScheduleHistory"
                    [size]="20"
                    (click)="showScheduleHistory = !showScheduleHistory"
                    class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
                    >keyboard_arrow_up</kenjo-icon
                  >
                  <kenjo-icon
                    *ngIf="!showScheduleHistory"
                    [size]="20"
                    (click)="showScheduleHistory = !showScheduleHistory"
                    class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
                    >keyboard_arrow_down</kenjo-icon
                  >
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="1"></orgos-column>
            <orgos-column size="0">
              <orgos-action
                *ngIf="userWorkSchedule && userWorkSchedule.canEdit | async"
                (click)="editCustomSchedule()"
                icon="mode_edit"
                color="Primary"
              >
                {{peopleDetailPageTranslation.assignCustomScheduleButton}}
              </orgos-action>
            </orgos-column>
          </orgos-column-container>
          <div *ngIf="showScheduleHistory && userWorkSchedule" class="kenjo-mt-20px">
            <orgos-column-container
              centerColumns="true"
              *ngFor="let schedule of userWorkSchedule.data.history; let scheduleIndex = index"
              class="kenjo-mt-10px kenjo-ph-20px kenjo-border kenjo-border-radius pdp-subcontainer"
            >
              <orgos-column size="0" class="kenjo-mr-10px kenjo-pv-20px">
                <kenjo-icon
                  *ngIf="!schedule.isCustom"
                  [size]="20"
                  class="kenjo-font-color-light-text-757575"
                  [class.pdp-schedule-current-schedule]="schedule._id === currentWorkSchedule._id"
                  >blank_page_icon</kenjo-icon
                >
                <kenjo-icon
                  *ngIf="schedule.isCustom"
                  [size]="20"
                  class="kenjo-font-color-light-text-757575"
                  [class.pdp-schedule-current-schedule]="schedule._id === currentWorkSchedule._id"
                  >settings_filled</kenjo-icon
                >
              </orgos-column>
              <orgos-column size="1">
                <div
                  class="kenjo-font-size-14px kenjo-font-color-text-333333"
                  [class.pdp-schedule-current-schedule]="schedule._id === currentWorkSchedule._id"
                >
                  '{{schedule.name}}' {{peopleDetailPageTranslation.workScheduleHistoryStartDate}} {{schedule.startDate | date:
                  'shortDate':'UTC'}} {{userWorkSchedule?.data?.history?.[scheduleIndex + 1]?.startDate ? ' - ' +
                  peopleDetailPageTranslation.workScheduleHistoryEndDate + ' ' + (moment.utc(userWorkSchedule?.data?.history[scheduleIndex +
                  1]?.startDate).subtract(1, 'days').isSameOrAfter(schedule.startDate) ?
                  (moment.utc(userWorkSchedule?.data?.history[scheduleIndex + 1]?.startDate).subtract(1, 'days')| date: 'shortDate':'UTC') :
                  (moment.utc(userWorkSchedule?.data?.history[scheduleIndex + 1]?.startDate)) | date: 'shortDate':'UTC') : ''}}
                </div>
              </orgos-column>
              <kenjo-icon
                orgosColumn="0"
                *ngIf="schedule.isCustom && userWorkSchedule && userWorkSchedule.canEdit | async"
                [size]="20"
                class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer"
                (click)="editCustomSchedule(schedule)"
                >mode_edit</kenjo-icon
              >
              <orgos-column
                *ngIf="userWorkSchedule && userWorkSchedule.canEdit | async"
                size="0"
                class="kenjo-pl-20px kenjo-ml-20px kenjo-border-left pdp-remove-button-separator"
              >
              </orgos-column>
              <kenjo-icon
                *ngIf="userWorkSchedule && userWorkSchedule.canEdit | async"
                orgosColumn="0"
                [size]="18"
                (click)="removeScheduleTemplate(schedule._id)"
                [matTooltip]="peopleDetailPageTranslation.tooltipDeleteSchedule"
                matTooltipPosition="before"
                class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757"
                >delete</kenjo-icon
              >
            </orgos-column-container>
          </div>
        </div>
      </div>

      <div *ngIf="userAddress && userAddress.canRead | async" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.addressSectionTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userAddress" field="streetName" class="kenjo-mr-10px"></orgos-input-text>
          <orgos-input-text orgosColumn [model]="userAddress" field="houseNumber" class="kenjo-ml-10px"></orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userAddress" field="additionalAddress" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userAddress" field="postalCode" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userAddress" field="city" class="kenjo-mr-10px"></orgos-input-text>
          <orgos-input-select
            orgosColumn
            [model]="userAddress"
            field="country"
            picklist="country"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-custom-fields
          collectionName="user-address"
          [model]="userAddress"
          columnContainerClass="kenjo-mt-20px"
          leftColumnClass="kenjo-mr-10px"
          rightColumnClass="kenjo-ml-10px"
        ></orgos-custom-fields>
      </div>

      <div *ngIf="userHome && userHome.canRead | async" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.homeSectionTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-email
            orgosColumn
            [model]="userHome"
            field="personalEmail"
            (validation)="personalEmailValidation = $event"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="personalEmailValidation && personalEmailValidation.getError('email')">
              {{peopleDetailPageTranslation.personalEmailNoValidFormat}}</orgos-input-error
            >
          </orgos-input-email>
          <orgos-input-text orgosColumn [model]="userHome" field="personalPhone" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userHome" field="personalMobile" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-select
            orgosColumn
            [model]="userHome"
            field="maritalStatus"
            picklist="maritalStatus"
            [enableClearButton]="true"
            (modelChange)="setIsMarried()"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <ng-container *ngIf="isMarried">
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-text orgosColumn [model]="userHome" field="spouseFirstName" class="kenjo-mr-10px"> </orgos-input-text>
            <orgos-input-text orgosColumn [model]="userHome" field="spouseLastName" class="kenjo-ml-10px"> </orgos-input-text>
          </orgos-column-container>
          <orgos-column-container class="kenjo-mt-20px">
            <orgos-input-date-picker
              orgosColumn
              [model]="userHome"
              field="spouseBirthdate"
              [enableClearButton]="true"
              [max]="today"
              (validation)="spouseBirthdateValidation = $event"
              class="kenjo-mr-10px"
            >
              <orgos-input-error *ngIf="spouseBirthdateValidation && spouseBirthdateValidation.getError('max')">
                {{peopleDetailPageTranslation.spouseBirthdateNoValidFuture}}</orgos-input-error
              >
              <orgos-input-error *ngIf="spouseBirthdateValidation && spouseBirthdateValidation.getError('date')">
                {{peopleDetailPageTranslation.spouseBirthdateNoValidFormat}}</orgos-input-error
              >
            </orgos-input-date-picker>
            <orgos-input-select
              orgosColumn
              [model]="userHome"
              field="spouseGender"
              picklist="gender"
              [enableClearButton]="true"
              class="kenjo-ml-10px"
            ></orgos-input-select>
          </orgos-column-container>
        </ng-container>
        <orgos-action *ngIf="userHome && userHome.canEdit | async" icon="add_circle" (click)="addChild()" class="kenjo-mt-20px"
          >{{peopleDetailPageTranslation.addChildButtonLabel}}
        </orgos-action>
        <div
          *ngFor="let rawChild of userHome.data.children; let iChild = index"
          class="kenjo-mt-20px kenjo-ph-20px kenjo-border kenjo-border-radius pdp-subcontainer"
        >
          <orgos-column-container centerColumns="true">
            <orgos-column class="kenjo-mr-10px kenjo-pt-15px kenjo-pb-10px">
              <orgos-input-text orgosColumn [model]="userHome" [value]="rawChild" field="childFirstName" class="kenjo-block">
              </orgos-input-text>
              <orgos-input-date-picker
                orgosColumn
                [model]="userHome"
                [value]="rawChild"
                field="childBirthdate"
                [enableClearButton]="true"
                [max]="today"
                (validation)="childBirthdateValidation[iChild] = $event"
                class="kenjo-block"
              >
                <orgos-input-error *ngIf="childBirthdateValidation[iChild] && childBirthdateValidation[iChild].getError('max')">
                  {{peopleDetailPageTranslation.childBirthdateNoValidFuture}}</orgos-input-error
                >
                <orgos-input-error *ngIf="childBirthdateValidation[iChild] && childBirthdateValidation[iChild].getError('date')">
                  {{peopleDetailPageTranslation.childBirthdateNoValidFormat}}</orgos-input-error
                >
              </orgos-input-date-picker>
            </orgos-column>

            <orgos-column class="kenjo-ml-10px kenjo-pt-15px kenjo-pb-10px">
              <orgos-input-text orgosColumn [model]="userHome" [value]="rawChild" field="childLastName" class="kenjo-block">
              </orgos-input-text>
              <orgos-input-select
                orgosColumn
                [model]="userHome"
                [value]="rawChild"
                field="childGender"
                picklist="gender"
                [enableClearButton]="true"
                class="kenjo-block"
              ></orgos-input-select>
            </orgos-column>
            <orgos-column size="0" class="kenjo-pl-20px kenjo-ml-20px kenjo-border-left pdp-remove-button-separator"> </orgos-column>
            <kenjo-icon
              [size]="18"
              orgosColumn="0"
              (click)="removeChild(iChild)"
              [matTooltip]="peopleDetailPageTranslation.removeChildButtonLabel"
              matTooltipPosition="before"
              class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757"
              >delete
            </kenjo-icon>
          </orgos-column-container>
        </div>
        <orgos-custom-fields
          collectionName="user-home"
          [model]="userHome"
          columnContainerClass="kenjo-mt-20px"
          leftColumnClass="kenjo-mr-10px"
          rightColumnClass="kenjo-ml-10px"
        ></orgos-custom-fields>
      </div>

      <div *ngIf="userFinancial && userFinancial.canRead | async" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.financeSectionTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userFinancial" field="bankName" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userFinancial" field="accountHolderName" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userFinancial" field="accountNumber" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userFinancial" field="iban" class="kenjo-ml-10px"></orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userFinancial" field="swiftCode" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userFinancial" field="nationalId" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userFinancial" field="passport" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text
            orgosColumn
            [model]="userFinancial"
            field="nationalInsuranceNumber"
            class="kenjo-ml-10px"
            (validation)="nationalInsuranceNumberValidation = $event"
            [customValidation]="nationalInsuranceNumberValidationFunction"
          >
            <orgos-input-error *ngIf="nationalInsuranceNumberValidation && nationalInsuranceNumberValidation.getError('notValid')">
              {{peopleDetailPageTranslation.nationalInsuranceNumberValidationMessage}}</orgos-input-error
            >
          </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userFinancial" field="taxIdentificationNumber" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userFinancial" field="taxCode" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px" *ngIf="userDatev">
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['europeanInsuranceNumber']?.read"
            [readOnly]="!userDatevPermissions['europeanInsuranceNumber']?.update"
            [model]="userDatev"
            field="europeanInsuranceNumber"
            class="kenjo-mr-10px"
          >
          </orgos-input-text>
          <orgos-column></orgos-column>
        </orgos-column-container>
        <orgos-custom-fields
          collectionName="user-financial"
          [model]="userFinancial"
          columnContainerClass="kenjo-mt-20px"
          leftColumnClass="kenjo-mr-10px"
          rightColumnClass="kenjo-ml-10px"
        >
        </orgos-custom-fields>
      </div>

      <div class="pdp-section" *ngIf="userDatev && isDatevLodas">
        <div class="kenjo-font-size-18px">{{ peopleDetailPageTranslation.datevSpecificWageDataSectionTitle }}</div>

        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-number
            orgosColumn
            *ngIf="userDatevPermissions['personalNumber']?.read"
            [readOnly]="!userDatevPermissions['personalNumber']?.update"
            [model]="userDatev"
            field="personalNumber"
            (validation)="personalNumberValidation = $event"
            [max]="99999"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="personalNumberValidation && personalNumberValidation.getError('max')"
              >{{ peopleDetailPageTranslation.personalNumberNoValidLength }}</orgos-input-error
            >
          </orgos-input-number>
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['secondaryPostcode']?.read"
            [readOnly]="!userDatevPermissions['secondaryPostcode']?.update"
            [model]="userDatev"
            field="secondaryPostcode"
            class="kenjo-ml-10px"
          >
          </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <div orgosColumn *ngIf="userDatevPermissions['salaryNumber']?.read" class="kenjo-mr-10px pdp-datev-salary-container">
            <orgos-input-number
              *ngIf="userDatevPermissions['salaryNumber']?.read"
              [readOnly]="!userDatevPermissions['salaryNumber']?.update || userDatev.data.useSalaryNumberFromSettings"
              [model]="userDatev"
              field="salaryNumber"
              [min]="1"
              [max]="99"
              [step]="1"
            ></orgos-input-number>
            <div class="kenjo-ml-5px">
              <orgos-input-checkbox
                *ngIf="userDatevPermissions['useSalaryNumberFromSettings']?.read"
                [readOnly]="!userDatevPermissions['useSalaryNumberFromSettings']?.update"
                [model]="userDatev"
                field="useSalaryNumberFromSettings"
                marginTop="0px"
                [savedHint]="false"
                (modelChange)="updateDatevSalary('useSalaryNumberFromSettings')"
              ></orgos-input-checkbox>
              <div>
                {{ peopleDetailPageTranslation.useDatevGlobalSettings1 }}
                <a *ngIf="isAdmin" [routerLink]="['/cloud/payroll/datev']" [queryParams]="{ datevSettingsId: datevSettingsId }"
                  >{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</a
                >
                <ng-container *ngIf="!isAdmin">{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</ng-container>
              </div>
            </div>
          </div>
          <orgos-column-container orgosColumn *ngIf="userDatevPermissions['salaryType']?.read" class="kenjo-ml-10px">
            <div orgosColumn *ngIf="userDatevPermissions['salaryNumber']?.read" class="kenjo-mr-10px pdp-datev-salary-container">
              <orgos-input-number
                orgosColumn
                *ngIf="userDatevPermissions['salaryType']?.read"
                [readOnly]="!userDatevPermissions['salaryType']?.update || userDatev.data.useSalaryTypeFromSettings"
                [model]="userDatev"
                field="salaryType"
                [min]="1"
                [max]="8999"
                [step]="1"
              ></orgos-input-number>
              <div class="kenjo-ml-5px">
                <orgos-input-checkbox
                  *ngIf="userDatevPermissions['useSalaryTypeFromSettings']?.read"
                  [readOnly]="!userDatevPermissions['useSalaryTypeFromSettings']?.update"
                  [model]="userDatev"
                  field="useSalaryTypeFromSettings"
                  marginTop="0px"
                  [savedHint]="false"
                  (modelChange)="updateDatevSalary('useSalaryTypeFromSettings')"
                ></orgos-input-checkbox>
                <div>
                  {{ peopleDetailPageTranslation.useDatevGlobalSettings1 }}
                  <a *ngIf="isAdmin" [routerLink]="['/cloud/payroll/datev']" [queryParams]="{ datevSettingsId: datevSettingsId }"
                    >{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</a
                  >
                  <ng-container *ngIf="!isAdmin">{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</ng-container>
                </div>
              </div>
            </div>
          </orgos-column-container>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['severelyDisabled']?.read"
            [readOnly]="!userDatevPermissions['severelyDisabled']?.update"
            [model]="userDatev"
            field="severelyDisabled"
            picklist="datevSeverelyDisabled"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['initialDate']?.read"
            [readOnly]="!userDatevPermissions['initialDate']?.update"
            [model]="userDatev"
            field="initialDate"
            class="kenjo-ml-10px"
          >
          </orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['workPermitValidUntil']?.read"
            [readOnly]="!userDatevPermissions['workPermitValidUntil']?.update"
            [model]="userDatev"
            field="workPermitValidUntil"
            class="kenjo-mr-10px"
          >
          </orgos-input-date-picker>
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['residencePermitValidUntil']?.read"
            [readOnly]="!userDatevPermissions['residencePermitValidUntil']?.update"
            [model]="userDatev"
            field="residencePermitValidUntil"
            class="kenjo-ml-10px"
          >
          </orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['studyCertificateValidUntil']?.read"
            [readOnly]="!userDatevPermissions['studyCertificateValidUntil']?.update"
            [model]="userDatev"
            field="studyCertificateValidUntil"
            class="kenjo-mr-10px"
          >
          </orgos-input-date-picker>
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['academicDegree']?.read"
            [readOnly]="!userDatevPermissions['academicDegree']?.update"
            [model]="userDatev"
            field="academicDegree"
            class="kenjo-ml-10px"
          >
          </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['differentBankAccountHolderName']?.read"
            [readOnly]="!userDatevPermissions['differentBankAccountHolderName']?.update"
            [model]="userDatev"
            field="differentBankAccountHolderName"
            class="kenjo-mr-10px"
          >
          </orgos-input-text>
          <orgos-input-date-picker
            *ngIf="userDatevPermissions['terminationNotificationDate']?.read"
            [readOnly]="!userDatevPermissions['terminationNotificationDate']?.update"
            [matTooltip]="peopleDetailPageTranslation.datevTerminationNotificationDateTooltip"
            matTooltipPosition="above"
            orgosColumn
            [model]="userDatev"
            field="terminationNotificationDate"
            class="kenjo-ml-10px"
          >
          </orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['mainOrSecondaryEmployer']?.read"
            [readOnly]="!userDatevPermissions['mainOrSecondaryEmployer']?.update"
            [model]="userDatev"
            field="mainOrSecondaryEmployer"
            picklist="datevEmployerType"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['socialInsuranceGroupKey']?.read"
            [readOnly]="!userDatevPermissions['socialInsuranceGroupKey']?.update"
            [model]="userDatev"
            field="socialInsuranceGroupKey"
            picklist="datevSocialInsuranceType"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['socialSecurityGroup']?.read"
            [readOnly]="!userDatevPermissions['socialSecurityGroup']?.update"
            [model]="userDatev"
            field="socialSecurityGroup"
            picklist="datevSocialSecurityGroup"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['trainingStartDate']?.read"
            [readOnly]="!userDatevPermissions['trainingStartDate']?.update"
            [model]="userDatev"
            field="trainingStartDate"
            class="kenjo-ml-10px"
          >
          </orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['expectedTrainingEndDate']?.read"
            [readOnly]="!userDatevPermissions['expectedTrainingEndDate']?.update"
            [model]="userDatev"
            field="expectedTrainingEndDate"
            class="kenjo-mr-10px"
          >
          </orgos-input-date-picker>
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['trainingEndDate']?.read"
            [readOnly]="!userDatevPermissions['trainingEndDate']?.update"
            [model]="userDatev"
            field="trainingEndDate"
            class="kenjo-ml-10px"
          >
          </orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['highestSchoolDegree']?.read"
            [readOnly]="!userDatevPermissions['highestSchoolDegree']?.update"
            [model]="userDatev"
            field="highestSchoolDegree"
            picklist="datevHighestSchoolDegree"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['highestProfessionalEducation']?.read"
            [readOnly]="!userDatevPermissions['highestProfessionalEducation']?.update"
            [model]="userDatev"
            field="highestProfessionalEducation"
            picklist="datevHighestProfessionalEducation"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['contractType']?.read"
            [readOnly]="!userDatevPermissions['contractType']?.update"
            [model]="userDatev"
            field="contractType"
            picklist="datevContractType"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['masterCostCenter']?.read"
            [readOnly]="!userDatevPermissions['masterCostCenter']?.update"
            [model]="userDatev"
            field="masterCostCenter"
            class="kenjo-ml-10px"
          >
          </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['healthInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['healthInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="healthInsuranceContributionGroup"
            picklist="datevHealthInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['pensionInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['pensionInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="pensionInsuranceContributionGroup"
            picklist="datevPensionInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['unemploymentInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['unemploymentInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="unemploymentInsuranceContributionGroup"
            picklist="datevUnemploymentInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['careInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['careInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="careInsuranceContributionGroup"
            picklist="datevCareInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-number
            orgosColumn
            *ngIf="userDatevPermissions['basicVacationEntitlement']?.read"
            [readOnly]="!userDatevPermissions['basicVacationEntitlement']?.update"
            [model]="userDatev"
            field="basicVacationEntitlement"
            class="kenjo-mr-10px"
            [min]="0"
            [max]="99.9"
            [step]="0.1"
          >
          </orgos-input-number>
          <orgos-input-number
            orgosColumn
            *ngIf="userDatevPermissions['vacationEntitlementCurrentYear']?.read"
            [readOnly]="!userDatevPermissions['vacationEntitlementCurrentYear']?.update"
            [model]="userDatev"
            field="vacationEntitlementCurrentYear"
            class="kenjo-ml-10px"
            [min]="0"
            [max]="99.9"
            [step]="0.1"
          >
          </orgos-input-number>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-number
            orgosColumn
            *ngIf="userDatevPermissions['remainingVacationPreviousYear']?.read"
            [readOnly]="!userDatevPermissions['remainingVacationPreviousYear']?.update"
            [model]="userDatev"
            field="remainingVacationPreviousYear"
            class="kenjo-mr-10px"
            [min]="0"
            [max]="99.9"
            [step]="0.1"
          >
          </orgos-input-number>
          <orgos-column></orgos-column>
        </orgos-column-container>
      </div>

      <div class="pdp-section" *ngIf="userDatev && !isDatevLodas">
        <div class="kenjo-font-size-18px">{{ peopleDetailPageTranslation.datevSpecificWageDataSectionTitle }}</div>

        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-number
            orgosColumn
            *ngIf="userDatevPermissions['personalNumber']?.read"
            [readOnly]="!userDatevPermissions['personalNumber']?.update"
            [model]="userDatev"
            field="personalNumber"
            (validation)="personalNumberValidation = $event"
            [max]="99999"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="personalNumberValidation && personalNumberValidation.getError('max')"
              >{{ peopleDetailPageTranslation.personalNumberNoValidLength }}</orgos-input-error
            >
          </orgos-input-number>
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['academicDegree']?.read"
            [readOnly]="!userDatevPermissions['academicDegree']?.update"
            [model]="userDatev"
            field="academicDegree"
            class="kenjo-ml-10px"
          ></orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <div orgosColumn *ngIf="userDatevPermissions['salaryNumber']?.read" class="kenjo-mr-10px pdp-datev-salary-container">
            <orgos-input-number
              *ngIf="userDatevPermissions['salaryNumber']?.read"
              [readOnly]="!userDatevPermissions['salaryNumber']?.update || userDatev.data.useSalaryNumberFromSettings"
              [model]="userDatev"
              field="salaryNumber"
              [min]="1"
              [max]="99"
              [step]="1"
            ></orgos-input-number>
            <div class="kenjo-ml-5px">
              <orgos-input-checkbox
                *ngIf="userDatevPermissions['useSalaryNumberFromSettings']?.read"
                [readOnly]="!userDatevPermissions['useSalaryNumberFromSettings']?.update"
                [model]="userDatev"
                field="useSalaryNumberFromSettings"
                marginTop="0px"
                [savedHint]="false"
                (modelChange)="updateDatevSalary('useSalaryNumberFromSettings')"
              ></orgos-input-checkbox>
              <div>
                {{ peopleDetailPageTranslation.useDatevGlobalSettings1 }}
                <a *ngIf="isAdmin" [routerLink]="['/cloud/payroll/datev']" [queryParams]="{ datevSettingsId: datevSettingsId }"
                  >{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</a
                >
                <ng-container *ngIf="!isAdmin">{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</ng-container>
              </div>
            </div>
          </div>
          <orgos-column-container orgosColumn *ngIf="userDatevPermissions['salaryType']?.read" class="kenjo-ml-10px">
            <div orgosColumn *ngIf="userDatevPermissions['salaryNumber']?.read" class="kenjo-mr-10px pdp-datev-salary-container">
              <orgos-input-number
                orgosColumn
                *ngIf="userDatevPermissions['salaryType']?.read"
                [readOnly]="!userDatevPermissions['salaryType']?.update || userDatev.data.useSalaryTypeFromSettings"
                [model]="userDatev"
                field="salaryType"
                [min]="1"
                [max]="8999"
                [step]="1"
              ></orgos-input-number>
              <div class="kenjo-ml-5px">
                <orgos-input-checkbox
                  *ngIf="userDatevPermissions['useSalaryTypeFromSettings']?.read"
                  [readOnly]="!userDatevPermissions['useSalaryTypeFromSettings']?.update"
                  [model]="userDatev"
                  field="useSalaryTypeFromSettings"
                  marginTop="0px"
                  [savedHint]="false"
                  (modelChange)="updateDatevSalary('useSalaryTypeFromSettings')"
                ></orgos-input-checkbox>
                <div>
                  {{ peopleDetailPageTranslation.useDatevGlobalSettings1 }}
                  <a *ngIf="isAdmin" [routerLink]="['/cloud/payroll/datev']" [queryParams]="{ datevSettingsId: datevSettingsId }"
                    >{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</a
                  >
                  <ng-container *ngIf="!isAdmin">{{ peopleDetailPageTranslation.useDatevGlobalSettings2 }}</ng-container>
                </div>
              </div>
            </div>
          </orgos-column-container>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['workPermitValidUntil']?.read"
            [readOnly]="!userDatevPermissions['workPermitValidUntil']?.update"
            [model]="userDatev"
            field="workPermitValidUntil"
            class="kenjo-mr-10px"
          ></orgos-input-date-picker>
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['residencePermitValidUntil']?.read"
            [readOnly]="!userDatevPermissions['residencePermitValidUntil']?.update"
            [model]="userDatev"
            field="residencePermitValidUntil"
            class="kenjo-ml-10px"
          ></orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['studyCertificateValidUntil']?.read"
            [readOnly]="!userDatevPermissions['studyCertificateValidUntil']?.update"
            [model]="userDatev"
            field="studyCertificateValidUntil"
            class="kenjo-mr-10px"
          ></orgos-input-date-picker>
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['differentBankAccountHolderName']?.read"
            [readOnly]="!userDatevPermissions['differentBankAccountHolderName']?.update"
            [model]="userDatev"
            field="differentBankAccountHolderName"
            class="kenjo-ml-10px"
          ></orgos-input-text>
        </orgos-column-container>
        <orgos-column-container>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['socialSecurityGroup']?.read"
            [readOnly]="!userDatevPermissions['socialSecurityGroup']?.update"
            [model]="userDatev"
            field="socialSecurityGroup"
            picklist="datevLugSocialSecurityGroup"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['trainingStartDate']?.read"
            [readOnly]="!userDatevPermissions['trainingStartDate']?.update"
            [model]="userDatev"
            field="trainingStartDate"
            class="kenjo-ml-10px"
          ></orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['expectedTrainingEndDate']?.read"
            [readOnly]="!userDatevPermissions['expectedTrainingEndDate']?.update"
            [model]="userDatev"
            field="expectedTrainingEndDate"
            class="kenjo-mr-10px"
          ></orgos-input-date-picker>
          <orgos-input-date-picker
            orgosColumn
            *ngIf="userDatevPermissions['trainingEndDate']?.read"
            [readOnly]="!userDatevPermissions['trainingEndDate']?.update"
            [model]="userDatev"
            field="trainingEndDate"
            class="kenjo-ml-10px"
          ></orgos-input-date-picker>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['jobCode']?.read"
            [readOnly]="!userDatevPermissions['jobCode']?.update"
            [model]="userDatev"
            field="jobCode"
            class="kenjo-mr-10px"
          ></orgos-input-text>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['highestSchoolDegree']?.read"
            [readOnly]="!userDatevPermissions['highestSchoolDegree']?.update"
            [model]="userDatev"
            field="highestSchoolDegree"
            picklist="datevLugHighestSchoolDegree"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['highestProfessionalEducation']?.read"
            [readOnly]="!userDatevPermissions['highestProfessionalEducation']?.update"
            [model]="userDatev"
            field="highestProfessionalEducation"
            picklist="datevLugHighestProfessionalEducation"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['contractType']?.read"
            [readOnly]="!userDatevPermissions['contractType']?.update"
            [model]="userDatev"
            field="contractType"
            picklist="datevLugContractType"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['professionalLabourLeasing']?.read"
            [readOnly]="!userDatevPermissions['professionalLabourLeasing']?.update"
            [model]="userDatev"
            field="professionalLabourLeasing"
            picklist="datevProfessionalLabourLeasing"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['healthInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['healthInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="healthInsuranceContributionGroup"
            picklist="datevLugHealthInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['pensionInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['pensionInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="pensionInsuranceContributionGroup"
            picklist="datevLugPensionInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['unemploymentInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['unemploymentInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="unemploymentInsuranceContributionGroup"
            picklist="datevLugUnemploymentInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['careInsuranceContributionGroup']?.read"
            [readOnly]="!userDatevPermissions['careInsuranceContributionGroup']?.update"
            [model]="userDatev"
            field="careInsuranceContributionGroup"
            picklist="datevLugCareInsuranceContributionGroup"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-number
            orgosColumn
            *ngIf="userDatevPermissions['basicVacationEntitlement']?.read"
            [readOnly]="!userDatevPermissions['basicVacationEntitlement']?.update"
            [model]="userDatev"
            field="basicVacationEntitlement"
            class="kenjo-ml-10px"
            [min]="0"
            [max]="99.9"
            [step]="0.1"
          ></orgos-input-number>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-select
            orgosColumn
            *ngIf="userDatevPermissions['statusCode']?.read"
            [readOnly]="!userDatevPermissions['statusCode']?.update"
            [model]="userDatev"
            field="statusCode"
            picklist="datevStatusCode"
            [enableClearButton]="true"
            class="kenjo-mr-10px"
          ></orgos-input-select>
          <orgos-input-text
            orgosColumn
            *ngIf="userDatevPermissions['masterCostCenter']?.read"
            [readOnly]="!userDatevPermissions['masterCostCenter']?.update"
            [model]="userDatev"
            field="masterCostCenter"
            class="kenjo-ml-10px"
          ></orgos-input-text>
        </orgos-column-container>
      </div>

      <div *ngIf="userEmergency && userEmergency.canRead | async" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.emergencySectionTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyFirstName" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyMiddleName"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyLastName" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-select
            orgosColumn
            [model]="userEmergency"
            field="relation"
            picklist="relation"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyPhoneNumber" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyMobileNumber" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-email
            orgosColumn
            [model]="userEmergency"
            field="emergencyEmail"
            (validation)="emergencyEmailValidation = $event"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="emergencyEmailValidation && emergencyEmailValidation.getError('email')">
              {{peopleDetailPageTranslation.emergencyEmailNoValidFormat}}</orgos-input-error
            >
          </orgos-input-email>
          <div orgosColumn class="kenjo-ml-10px"></div>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyAddress" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyPostCode" class="kenjo-ml-10px"> </orgos-input-text>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text orgosColumn [model]="userEmergency" field="emergencyCity" class="kenjo-mr-10px"> </orgos-input-text>
          <orgos-input-select
            orgosColumn
            [model]="userEmergency"
            field="emergencyCountry"
            picklist="country"
            [enableClearButton]="true"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-custom-fields
          collectionName="user-emergency"
          [model]="userEmergency"
          columnContainerClass="kenjo-mt-20px"
          leftColumnClass="kenjo-mr-10px"
          rightColumnClass="kenjo-ml-10px"
        >
        </orgos-custom-fields>
      </div>

      <div *ngIf="userConfidential && userConfidential.canRead | async" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.confidentialSectionTitle}}</div>
        <orgos-column-container *ngIf="userAccount" class="kenjo-mt-20px" centerColumns="true">
          <orgos-column size="0" class="kenjo-mr-10px">
            <kenjo-icon
              *ngIf="userAccount && userAccount.data && userAccount.data.isActive === true"
              [size]="20"
              class="kenjo-font-color-success-00b72e"
            >
              done</kenjo-icon
            >
            <kenjo-icon
              *ngIf="userAccount && userAccount.data && userAccount.data.isActive === false"
              [size]="20"
              class="kenjo-font-color-danger-ff5757"
            >
              clear</kenjo-icon
            >
          </orgos-column>
          <orgos-column class="kenjo-font-color-light-text-757575">
            <ng-container *ngIf="userAccount && userAccount.data && userAccount.data.isActive === true">
              {{peopleDetailPageTranslation.userIsActive}}</ng-container
            >
            <ng-container *ngIf="userAccount && userAccount.data && userAccount.data.isActive === false">
              {{peopleDetailPageTranslation.userIsInactive}}</ng-container
            >
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-date-picker
            orgosColumn
            [model]="userConfidential"
            field="terminationDate"
            [enableClearButton]="true"
            (validation)="terminationDateValidation = $event"
            class="kenjo-mr-10px"
          >
            <orgos-input-error *ngIf="terminationDateValidation && terminationDateValidation.getError('date')">
              {{peopleDetailPageTranslation.terminationDateNoValidFormat}}</orgos-input-error
            >
          </orgos-input-date-picker>
          <orgos-input-select
            orgosColumn
            [model]="userConfidential"
            [sortOptions]="false"
            field="terminationReason"
            [enableClearButton]="true"
            [options]="userTerminationReason$ | async"
            class="kenjo-ml-10px"
          ></orgos-input-select>
        </orgos-column-container>
        <orgos-custom-fields
          collectionName="user-confidential"
          [model]="userConfidential"
          columnContainerClass="kenjo-mt-20px"
          leftColumnClass="kenjo-mr-10px"
          rightColumnClass="kenjo-ml-10px"
        >
        </orgos-custom-fields>
      </div>

      <div *ngIf="userAccount && userAccount.canRead && apiEnabled" class="pdp-section">
        <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.apiIntegrationsSectionTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-text
            orgosColumn
            [model]="userAccount"
            field="externalId"
            size="small"
            (validation)="externalIdUniqueValidation = $event"
            [customValidation]="externalIdUniqueValidationFunction"
          >
            <orgos-input-error *ngIf="externalIdUniqueValidation && externalIdUniqueValidation.getError('notValid')">
              {{peopleDetailPageTranslation.externalIdValidationMessage}}</orgos-input-error
            >
          </orgos-input-text>
          <div orgosColumn class="kenjo-ml-10px"></div>
        </orgos-column-container>
      </div>
    </orgos-container>
  </orgos-column>

  <orgos-column size="3" *ngIf="userDataLoaded" class="kenjo-ml-40px kenjo-mr-20px">
    <div class="kenjo-pt-20px" *ngIf="userAccount && isNotMyUser === false">
      <div class="kenjo-font-size-18px kenjo-pb-10px">{{peopleDetailPageTranslation.languageTitle}}</div>
      <orgos-input-simple-select
        orgosColumn
        [value]="userAccount?.data?.language"
        picklist="language"
        [label]="userAccountTranslation.language"
        (valueChange)="updateMyLanguage($event)"
        class="kenjo-block kenjo-mt-20px"
      ></orgos-input-simple-select>
      <orgos-input-simple-select
        orgosColumn
        [value]="userAccount?.data?.locale"
        picklist="locale"
        [label]="userAccountTranslation.locale"
        (valueChange)="updateMyLocale($event)"
        class="kenjo-block kenjo-mt-20px"
      ></orgos-input-simple-select>
    </div>
    <div
      class="kenjo-pt-30px"
      *ngIf="
        ((isAdmin === true || isHrAdmin === true || ('employees.c_triggerManualWorkflows' | permissions : userWork)) && listWorkflows && listWorkflows.length > 0) ||
        ((isAdmin === true || isHrAdmin === true || ('attendance-app.c_activateDeactivateTrackingPersonal' | permissions : userWork)) && userWorkSchedule && userWorkSchedule.data) ||
        ((isAdmin === true || isHrAdmin === true || ('employees.c_resetPassword' | permissions : userWork)) && isSSOMandatory === false && userAccount && userAccount.data.isActive === true) ||
        ('employees.c_changeWorkEmail' | permissions : userWork) ||
        (canActivateEmployee === true) ||
        (canDeactivateEmployee === true) ||
        (canDeleteEmployee === true) ||
        (kioskPinCode !== undefined) ||
        (shiftPlanActive === true && showShiftplanLink === true)
      "
    >
      <div class="kenjo-font-size-18px">{{peopleDetailPageTranslation.actionsTitle}}</div>
      <orgos-action
        *ngIf="(isAdmin === true || isHrAdmin === true || ('employees.c_triggerManualWorkflows' | permissions : userWork)) && listWorkflows && listWorkflows.length > 0"
        class="kenjo-block kenjo-mt-20px"
        (click)="selectTriggerWorkflow = true"
      >
        {{peopleDetailPageTranslation.triggerWorkflowButtonLabel}}</orgos-action
      >
      <orgos-action
        *ngIf="(isAdmin === true || isHrAdmin === true || ('attendance-app.c_activateDeactivateTrackingPersonal' | permissions : userWork)) && userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === false && ('attendance' | coreFeatures)"
        class="kenjo-block kenjo-mt-20px"
        (click)="enableAttendanceTracking()"
      >
        {{peopleDetailPageTranslation.enableAttendanceTracking}}
      </orgos-action>
      <orgos-action
        *ngIf="(isAdmin === true || isHrAdmin === true || ('attendance-app.c_activateDeactivateTrackingPersonal' | permissions : userWork)) && userWorkSchedule && userWorkSchedule.data && userWorkSchedule.data.trackAttendance === true && ('attendance' | coreFeatures)"
        class="kenjo-block kenjo-mt-20px"
        (click)="disableAttendanceTracking()"
      >
        {{peopleDetailPageTranslation.disableAttendanceTracking}}
      </orgos-action>
      <orgos-action
        *ngIf="(isAdmin === true || isHrAdmin === true || ('employees.c_resetPassword' | permissions : userWork)) && isSSOMandatory === false && userAccount && userAccount.data.isActive === true"
        class="kenjo-block kenjo-mt-20px"
        (click)="openResetPasswordConfirmDialog()"
      >
        {{peopleDetailPageTranslation.resetPasswordButtonLabel}}
      </orgos-action>
      <orgos-action
        *ngIf="'employees.c_changeWorkEmail' | permissions : userWork"
        class="kenjo-block kenjo-mt-20px"
        (click)="changeWorkEmail()"
        >{{peopleDetailPageTranslation.changeWorkEmail}}</orgos-action
      >
      <orgos-action *ngIf="canActivateEmployee === true" class="kenjo-block kenjo-mt-20px" (click)="openActivateUserConfirmDialog()">
        {{peopleDetailPageTranslation.activateUserButtonLabel}}</orgos-action
      >
      <orgos-action *ngIf="canDeactivateEmployee === true" class="kenjo-block kenjo-mt-20px" (click)="openDeactivateUserConfirmDialog()">
        {{peopleDetailPageTranslation.deactivateUserButtonLabel}}</orgos-action
      >
      <orgos-action *ngIf="canDeleteEmployee === true" class="kenjo-block kenjo-mt-20px" (click)="openDeleteUserConfirmDialog()">
        {{peopleDetailPageTranslation.deleteUserButtonLabel}}</orgos-action
      >
      <div *ngIf="shiftPlanActive === true && showShiftplanLink" class="kenjo-mt-20px pdp-activate-shift-plan">
        <orgos-action (click)="checkShiftPlanActivationIsPossible()">
          {{ (userWork?.data.shiftPlanActivationStatus !== ACTIVATION_STATUS_FINISHED || (userWork?.data.shiftPlanActivationStatus ===
          ACTIVATION_STATUS_FINISHED && userWork?.data.shiftPlanActive === false)) ? peopleDetailPageTranslation.shiftPlanTitleActivate :
          peopleDetailPageTranslation.shiftPlanTitleEdit }}
        </orgos-action>
        <kenjo-icon
          size="16"
          *ngIf="(userWork?.data.shiftPlanActive === false || userWork?.data.shiftPlanActive === undefined) && userWork?.data.shiftPlanActivationStatus !== undefined && userWork?.data.shiftPlanActivationStatus !== ''"
          [matTooltip]="userWork?.data.shiftPlanActivationStatus === ACTIVATION_STATUS_FINISHED && userWork?.data.shiftPlanActive === false ? peopleDetailPageTranslation.shiftPlanDeactivatedTooltip : peopleDetailPageTranslation.shiftPlanNoActivationCompletedTooltip"
          class="kenjo-font-color-warning-ffc107 kenjo-ml-10px"
          >warning_outline</kenjo-icon
        >
      </div>
      <kenjo-people-detail-pincode-action
        *ngIf="('attendance' | coreFeatures) && userWorkSchedule?.data?.trackAttendance === true && kioskPinCode"
        [userId]="id"
      ></kenjo-people-detail-pincode-action>
    </div>

    <div
      class="kenjo-pt-40px"
      *ngIf="(isAdmin === true || isHrAdmin === true || ('employees.c_editEmailSignature' | permissions : userWork)) && userAccount && userAccount.data && !userAccount.data._isArchived"
    >
      <div class="kenjo-font-size-18px kenjo-pb-10px">{{peopleDetailPageTranslation.emailSignatureTitle}}</div>
      <div class="kenjo-font-color-light-text-757575">{{peopleDetailPageTranslation.emailSignatureSubtitle}}</div>
      <orgos-action class="kenjo-block kenjo-mt-20px" (click)="openEmailSignatureDialog()">
        {{peopleDetailPageTranslation.emailSignatureButton}}
      </orgos-action>
    </div>

    <div class="kenjo-pt-40px" *ngIf="isAdmin === true || isHrAdmin === true || ('employees.c_viewHistory' | permissions : userWork)">
      <div class="kenjo-font-size-18px kenjo-pb-10px">{{peopleDetailPageTranslation.historyTitle}}</div>
      <div class="kenjo-font-color-light-text-757575">{{peopleDetailPageTranslation.historyDescription}}</div>
      <orgos-action class="kenjo-block kenjo-mt-20px" (click)="openHistoryDialog()">
        {{peopleDetailPageTranslation.showHistoryButtonLabel}}</orgos-action
      >
    </div>
  </orgos-column>
</orgos-column-container>
<orgos-time-off-user-page
  *ngIf="seeDetailsUserId"
  [userId]="seeDetailsUserId"
  [managePoliciesInfo]="userManagePoliciesInfo"
  (goBack)="exitTimeOffFullScreen()"
  class="pdpp-time-off-page"
></orgos-time-off-user-page>
<orgos-workflow-trigger-manual
  *ngIf="selectTriggerWorkflow === true && workflowTargetDocument"
  [listWorkflows]="listWorkflows"
  [targetDocument]="workflowTargetDocument"
  [targetId]="id"
  (goBack)="resetTriggerWorkflow($event)"
></orgos-workflow-trigger-manual>
