<div class="spphl-list-container" [class.spphl-list-container-move]="hasToMove">
  <orgos-column-container>
    <orgos-column size="1">
      <div>
        <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ translations.publicHolidays }}
        </div>
        <div class="kenjo-font-size-16px">{{ labelDay }}</div>
      </div>
    </orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" (click)="closeDetails()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      >clear</kenjo-icon
    >
  </orgos-column-container>
  <orgos-column-container *ngFor="let holiday of publicHolidays" class="spphl-list-holidays kenjo-mt-10px kenjo-p-10px">
    <kenjo-icon orgosColumn="0" class="kenjo-mr-10px" [size]="18">event </kenjo-icon>
    <orgos-column size="1" class="kenjo-ml-5px">
      <kenjo-truncate-text class="kenjo-font-size-14px kenjo-font-weight-bold spphl-list-holidays-word-break"
        >{{ translations.holidayIn }} "{{ holiday.calendar }}"</kenjo-truncate-text
      >
      <kenjo-truncate-text class="kenjo-font-size-12px spphl-list-holidays-word-break">{{ holiday.holidayName }}</kenjo-truncate-text>
    </orgos-column>
  </orgos-column-container>
</div>
