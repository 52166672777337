import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ManagePoliciesDialog } from '@app/cloud-features/time-off/pages/time-off-company/dialogs/manage-policies.dialog';
import { ITimeOffUserPolicy, TimeOffUserPolicyController } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'orgos-time-off-user-page',
  templateUrl: 'time-off-user.page.html',
  styleUrls: ['time-off-user.page.scss'],
})
export class TimeOffUserPage extends GenericPage {
  protected translationResources: Array<ITranslationResource> = [{ name: 'page', translationKey: 'time-off-user-page' }];
  protected profilePermissionsResources: Array<string> = ['time-off-app'];

  refreshChildSubject: Subject<void> = new Subject<void>();

  user: any;
  canSeePolicyDetails: boolean = false;
  emptyTimeOff = false;
  managePoliciesButtonShown: boolean = false;

  myStatus: Array<ITimeOffUserPolicy> = [];
  myLimitedStatus: Array<ITimeOffUserPolicy> = [];
  myUnlimitedStatus: Array<ITimeOffUserPolicy> = [];
  canCreateRequestsOnBehalf: boolean = false;

  @Input() userId: string;
  @Input() managePoliciesInfo: Object;
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

  protected beforeInit(): Promise<void> {
    const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    this.canSeePolicyDetails = ['admin', 'hr-admin'].includes(myProfile);
    this.globalBarConfig.enableFullScreenMode = true;
    this.globalBarConfig.fullScreenModeUseSameUrl = true;

    return Promise.resolve();
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view time off page', { category: 'Navigation', type: 'user' });
    this.initPermissions();
    return Promise.resolve();
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    const getUser = this.injector.get(UserPersonalService).getById(this.userId);
    const getStatus = this.injector.get(TimeOffUserPolicyController).getStatusByUserId(this.userId);
    try {
      const [user, status] = await Promise.all([getUser, getStatus, this.checkPermissionsToRequestOnBehalf()]);
      this.user = user;
      if (check.nonEmptyArray(status)) {
        this.myStatus = status.sort((a, b) => a.timeOffType.name.localeCompare(b.timeOffType.name));
        [this.myUnlimitedStatus, this.myLimitedStatus] = _.partition(
          this.myStatus,
          (iStatus) => iStatus.policy.allowanceType === picklistConstants.ALLOWANCE_TYPE_UNLIMITED
        );
        this.myUnlimitedStatus = this.myUnlimitedStatus.reduce((result, currentStatus, i) => {
          const indexValue = Math.floor(i / 3);
          if (check.not.assigned(result[indexValue])) {
            const subarray = [];
            subarray.push(currentStatus);
            result.push(subarray);
            return result;
          }
          result[indexValue].push(currentStatus);
          return result;
        }, []);
      }
      this.refreshChildSubject.next();
      resolveFetchData();
    } catch (error) {
      rejectFetchData();
    }
  }

  protected onExitFullScreenMode(): Promise<boolean> {
    this.goBack.emit();
    return Promise.resolve(true);
  }

  async getStartAnnualCycle() {
    const userWork = await this.injector.get(UserWorkService).getById(this.user._id);
    const userSeniority = moment().diff(userWork.startDate, 'years', false);
    const currentCycleStartDate = moment(userWork.startDate).add(userSeniority, 'years');
    return currentCycleStartDate;
  }

  private initPermissions() {
    this.managePoliciesButtonShown = !!this.profilePermissions['time-off-app'].c_manageTimeOff_all;
  }

  private async checkPermissionsToRequestOnBehalf() {
    if (
      check.assigned(this.profilePermissions['time-off-app'].c_submitRequestsOnBehalf_all) &&
      this.profilePermissions['time-off-app'].c_submitRequestsOnBehalf_all === true
    ) {
      this.canCreateRequestsOnBehalf = true;
      return;
    }

    if (
      check.not.assigned(this.profilePermissions['time-off-app'].c_submitRequestsOnBehalf_custom) ||
      check.emptyArray(this.profilePermissions['time-off-app'].c_submitRequestsOnBehalf_custom)
    ) {
      this.canCreateRequestsOnBehalf = false;
      return;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const allUserWork = await this.injector.get(UserWorkService).getAllUserWork(false);

    const thisUserWork = allUserWork.find((iUserWork) => iUserWork._id === this.userId);
    if (check.not.assigned(thisUserWork) || check.emptyObject(thisUserWork)) {
      this.canCreateRequestsOnBehalf = false;
      return;
    }

    const customPermissionResult = await customPermissions.applyCustomPermissionsToDocument(
      null,
      'user-work',
      this.profilePermissions['time-off-app'].c_submitRequestsOnBehalf_custom,
      null,
      thisUserWork,
      allUserWork,
      loggedUser
    );
    this.canCreateRequestsOnBehalf = customPermissionResult;
  }

  async openManagePoliciesDialog(): Promise<void> {
    const dialogRef = this.injector
      .get(MatLegacyDialog)
      .open(ManagePoliciesDialog, { data: this.managePoliciesInfo, panelClass: 'kenjo-full-screen-dialog' });
    dialogRef.afterClosed().subscribe((changedData: boolean) => {
      if (changedData === true) {
        this.emptyTimeOff = false;
        this.refreshData();
      }
    });
  }
}
