<div [class]="sizeClass" (click)="onAvatarClick()">
  <div *ngIf="enableChangePhoto" class="ac-camera-icon-container">
    <mat-icon class="ac-camera-icon">photo_camera</mat-icon>
  </div>

  <img *ngIf="isAnonymous === false && photoUrl" class="avatar-image" [class.ac-show-camera-hover]="enableChangePhoto" [src]="photoUrl" (error)="photoUrl = null" />
  <div *ngIf="isAnonymous === false && !photoUrl" class="name-user" [class.ac-show-camera-hover]="enableChangePhoto">
    <orgos-text [type]="textTypeClass">{{ nameInitials }}</orgos-text>
  </div>
  <div *ngIf="isAnonymous === true" [class]="anonymousClass">
    <div [class]="anonymousIcon">
      <mat-icon>person</mat-icon>
    </div>
  </div>
</div>
