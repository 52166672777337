import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanBreaktimeDeductionService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-breaktime-deduction-db`;
  private SHIFTPLAN_BREAKTIME_DEDUCTION_SERVICE: string = 'ShiftPlanBreaktimeDeductionService';

  constructor(private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  async getBreaktimeDeduction(): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(
          'PROGRAMMING ERROR',
          ErrorCodes.UNAUTHORIZED,
          this.SHIFTPLAN_BREAKTIME_DEDUCTION_SERVICE,
          'getBreaktimeDeduction'
        );
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.get(this.MICROSERVICE_URL, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_BREAKTIME_DEDUCTION_SERVICE, 'getBreaktimeDeduction');
    }
  }

  async updateBreaktimeDeduction(id: string, data: any): Promise<any> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(
          'PROGRAMMING ERROR',
          ErrorCodes.UNAUTHORIZED,
          this.SHIFTPLAN_BREAKTIME_DEDUCTION_SERVICE,
          'updateBreaktimeDeduction'
        );
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.put(`${this.MICROSERVICE_URL}/${id}`, data, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_BREAKTIME_DEDUCTION_SERVICE, 'updateBreaktimeDeduction');
    }
  }
}

export interface IShiftPlanBreaktimeDeductionModel {
  _id?: string;
  s_orgId: string;
  breaktimeDeduction: boolean;
}
