import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TimeOffUserPolicyController } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { ISelectOption } from '@app/standard/core/select-option';
import {
  IUserEmploymentContractTypeModel,
  UserEmploymentContractTypeService,
} from '@app/standard/services/user/user-employment-contract-type.service';
import {
  IUserEmploymentSubcategoryModel,
  UserEmploymentSubcategoryService,
} from '@app/standard/services/user/user-employment-subcategory.service';
import * as check from 'check-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GenericCacheModel } from '../../../../core/generic-cache-model';
import { InputValidation } from '../../../../core/validation/input-validation';
import { CompanyService } from '../../../../services/company/company.service';
import { InternationalizationService } from '../../../../services/core/internationalization.service';
import { PayrollSettingsService } from '../../../../services/payroll/payroll-settings.service';
import { UserEmploymentService } from '../../../../services/user/user-employment.service';

@Component({
  selector: 'orgos-edit-employment-dialog',
  templateUrl: 'edit-employment.dialog.html',
  styleUrls: ['edit-employment.dialog.scss'],
})
export class EditEmploymentDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};

  employment: GenericCacheModel;

  startDateValidation: InputValidation;
  contractValidation: InputValidation;
  typeValidation: InputValidation;
  hoursBeforeValidation: InputValidation;
  hoursAfterValidation: InputValidation;
  typeSickValidation: InputValidation;
  typeVacationValidation: InputValidation;

  minDate: Date;
  companyOptions: Array<any> = [];
  timeOffOptions: Array<ISelectOption> = [];
  optionsLoaded: boolean = false;
  canEditPast: boolean = false;
  userId: string;

  userEmploymentContractTypes$: Observable<Array<ISelectOption>>;
  userEmploymentSubcategories$: Observable<Array<ISelectOption>>;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditEmploymentDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private rawEmployment: any
  ) {
    // This prevents an undefined model error from the input-select component
    this.employment = new GenericCacheModel(
      this.injector,
      { startDate: undefined, contract: undefined, type: undefined, _companyId: undefined, weeklyHoursBeforeShortTime: undefined },
      UserEmploymentService,
      ''
    );
  }

  ngOnInit(): void {
    this.canEditPast = this.rawEmployment.canEditPast;
    this.userId = this.rawEmployment.record._userId;

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-compensation-edit-employment-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((dialogTranslation) => {
        this.miscTranslation = dialogTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.injector
      .get(CompanyService)
      .getCompanies()
      .then((allCompanies) => {
        this.companyOptions = allCompanies.map((iCompany: any) => {
          const companyOption = {
            name: iCompany.name,
            value: iCompany._id,
          };
          return companyOption;
        });
      })
      .catch(() => {
        this.companyOptions = [];
      });
    this.initInfo();
  }

  private async initInfo() {
    try {
      await this.initTimeOffOptions();

      this.employment = new GenericCacheModel(this.injector, this.rawEmployment.record, UserEmploymentService, '');
      this.injector.get(UserEmploymentContractTypeService).loadAllUserEmploymentContractTypes();
      this.userEmploymentContractTypes$ = this.injector.get(UserEmploymentContractTypeService).userEmploymentContractTypes.pipe(
        map((contractTypes: Array<IUserEmploymentContractTypeModel>) =>
          contractTypes
            // In case the employment has a disabled contract type assigned, we filter here instead of backend so it also shows up
            .filter(
              (contractType: IUserEmploymentContractTypeModel) =>
                contractType.isActive || contractType._id === this.employment.data.userEmploymentContractType
            )
            // We can't use async pipe directly because input options is typed to ISelectOptions
            .map((contractType: IUserEmploymentContractTypeModel) => ({ name: contractType.name, value: contractType._id }))
        )
      );
      this.injector.get(UserEmploymentSubcategoryService).loadAllUserEmploymentSubcategories();
      this.userEmploymentSubcategories$ = this.injector.get(UserEmploymentSubcategoryService).userEmploymentSubcategories.pipe(
        map((subcategories: Array<IUserEmploymentSubcategoryModel>) =>
          // same as above
          subcategories
            .filter(
              (subcategory: IUserEmploymentSubcategoryModel) =>
                subcategory.isActive || subcategory._id === this.employment.data.userEmploymentSubcategory
            )
            .map((subcategory: IUserEmploymentSubcategoryModel) => ({ name: subcategory.name, value: subcategory._id }))
        )
      );
      this.optionsLoaded = true;
    } catch {
      this.optionsLoaded = true;
    }
  }

  private async initTimeOffOptions() {
    try {
      const timeOffPolicies = await this.injector.get(TimeOffUserPolicyController).getUserPolicies(this.userId);
      if (check.not.nonEmptyArray(timeOffPolicies)) {
        this.timeOffOptions = [];
        return;
      }
      timeOffPolicies.forEach((policy) => {
        this.timeOffOptions.push({
          name: policy.name,
          value: policy.id,
        });
      });
    } catch {
      this.timeOffOptions = [];
    }
  }

  public recalculateRatio(): void {
    this.employment.data.ratioShortTime = 0;
    const weeklyHoursBeforeShortTime = this.employment.data.weeklyHoursBeforeShortTime;
    const weeklyHoursShortTime = this.employment.data.weeklyHoursShortTime;

    if (check.not.assigned(weeklyHoursBeforeShortTime) || check.not.assigned(weeklyHoursShortTime)) {
      return;
    }

    this.employment.data.ratioShortTime = Math.round((weeklyHoursShortTime / weeklyHoursBeforeShortTime + Number.EPSILON) * 100) / 100;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public updateEmployment(): void {
    if (
      check.not.assigned(this.startDateValidation) ||
      this.startDateValidation.hasErrors() ||
      check.not.assigned(this.contractValidation) ||
      this.contractValidation.hasErrors() ||
      check.not.assigned(this.typeValidation) ||
      this.typeValidation.hasErrors()
    ) {
      return;
    }

    this.dialogRef.close(this.employment.data);
  }
}
