<orgos-container aspect="card">
  <orgos-column-container class="kenjo-border-bottom kenjo-pv-10px kenjo-mb-30px kenjo-pl-20px">
    <orgos-column size="0" class="kenjo-font-size-16px">{{ i18n.filterDiscussion }}</orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-ph-20px kenjo-mh-5px kenjo-mb-30px">
    <orgos-column size="1">
      <orgos-input-simple-select class="rdcsc-w100" [label]="i18n.type" [value]="filterSettingsSelected.type" [options]="filterSettingsOptions.type" (valueChange)="filterSettingsSelected.type = $event"></orgos-input-simple-select>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-ph-20px kenjo-ml-5px kenjo-mb-120px">
    <orgos-column size="1">
      <orgos-input-simple-select class="rdcsc-w100" [label]="i18n.timeSorting" [value]="filterSettingsSelected.timeSorting" [options]="filterSettingsOptions.timeSorting" (valueChange)="filterSettingsSelected.timeSorting = $event"></orgos-input-simple-select>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-ph-20px kenjo-pb-15px">
    <orgos-column size="1">
      <div class="kenjo-mt-20px kenjo-text-align-left">
        <orgos-button-raised color="Neutral" (click)="closeFilterDiscussionSettings()">{{ i18n.activity.cancelCommentButton }}</orgos-button-raised>
      </div>
    </orgos-column>
    <orgos-column size="1">
      <div class="kenjo-mt-20px kenjo-text-align-right">
        <orgos-button-raised color="Success" (click)="saveFilterDiscussionSettings()">{{ i18n.activity.saveCommentButton }}</orgos-button-raised>
      </div>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
