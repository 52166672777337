<ng-container *ngIf="!loadingPage && meeting">
  <orgos-column-container centerColumns="true" class="mdp-top-bar kenjo-border-bottom kenjo-ph-20px">
    <orgos-column size="0">
      <kenjo-icon size="20" (click)="goBack()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
    </orgos-column>

    <orgos-column size="0" class="mdp-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-column size="0" class="kenjo-font-size-16px">{{i18n.page.Meeting}}</orgos-column>

    <orgos-column size="0" class="mdp-separator kenjo-mh-20px kenjo-border-left"></orgos-column>
  </orgos-column-container>

  <div class="mdp-main-container" id="meeting-exportable">
    <orgos-column-container class="kenjo-p-20px">
      <orgos-column size="2" class="kenjo-mr-20px">
        <!-- MEETING DETAIL-->
        <orgos-container aspect="card" class="mdp-detail-container">
          <!-- MEETING HEADER-->
          <div class="kenjo-p-20px kenjo-border-bottom">
            <orgos-column-container class="kenjo-mb-10px">
              <orgos-column size="1">
                <orgos-text type="Headline2">{{meeting.name}}</orgos-text>
              </orgos-column>
              <orgos-column size="0" *ngIf="meeting.ownerId === loggedUser._id || isAdmin" class="exclude-from-pdf">
                <ng-container>
                  <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert </kenjo-icon>
                  <mat-menu #actionsMenu="matMenu">
                    <button mat-menu-item (click)="exportToPDF()">
                      <span>{{i18n.page.exportToPdf}}</span>
                    </button>
                    <button mat-menu-item (click)="loadTemplate()">
                      <span>{{i18n.page.loadTemplate}}</span>
                    </button>
                    <button mat-menu-item (click)="saveAsTemplate()">
                      <span>{{i18n.page.saveAsTemplate}}</span>
                    </button>
                    <button mat-menu-item (click)="editMeeting()">
                      <span>{{i18n.page.edit}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteMeeting()">
                      <span>{{i18n.page.delete}}</span>
                    </button>
                  </mat-menu>
                </ng-container>
              </orgos-column>
            </orgos-column-container>
            <orgos-column-container centerColumns="true">
              <orgos-column size="0" class="kenjo-pr-10px">
                <mat-icon class="mdp-icon">schedule</mat-icon>
              </orgos-column>
              <orgos-column size="0" class="kenjo-pr-10px">
                <div *ngIf="meeting.startDate" class="kenjo-font-color-light-text-757575 exclude-from-pdf">
                  {{meeting.startDate | humanDate : true}}
                </div>
                <div *ngIf="meeting.startDate" class="kenjo-font-color-light-text-757575 include-in-pdf">
                  {{meeting.startDate | date:'medium'}}
                </div>
              </orgos-column>
              <orgos-column size="1"></orgos-column>
              <orgos-column size="0" *ngIf="repeatMeeting === true">
                <orgos-column-container centerColumns="true">
                  <orgos-column size="0" class="kenjo-pl-10px kenjo-font-color-light-text-757575">
                    {{i18n.misc.repeatsEvery}}
                  </orgos-column>
                  <orgos-column size="1" class="kenjo-pl-5px mdp-toggle-container">
                    <div
                      *ngFor="let weekDay of WEEKDAYS_ARRAY; index as i"
                      class="itrc-toggle itrc-disabled kenjo-ml-5px"
                      [class.itrc-checked]="meeting.repeatMeeting.weeklyRepetitions[weekDay] === true"
                    >
                      <span>{{ translatedWeekdays[i] }}</span>
                    </div>
                  </orgos-column>
                </orgos-column-container>
              </orgos-column>
            </orgos-column-container>
          </div>
          <!-- END MEETING HEADER-->

          <!-- AGENDA ITEMS DETAILS -->
          <div class="kenjo-p-40px">
            <div
              *ngIf="!meeting.agenda || meeting.agenda.length === 0"
              class="kenjo-text-align-center mdp-empty-state-size flex-vertical flex-center flex-center-vertically"
            >
              <img src="/assets/images/emptyState.svg" />
              <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noContentYet}}</div>
              <div class="kenjo-mt-10px kenjo-font-size-14px kenjo-font-color-light-text-757575 exclude-from-pdf">
                {{i18n.page.addAgendaItems}}
              </div>
            </div>

            <ng-container *ngFor="let iAgendaItem of meeting.agenda; let detailIndex = index; let last = last">
              <div class="mdp-agenda-item-container" [class.kenjo-pb-40px]="!last">
                <orgos-column-container class="kenjo-pb-10px">
                  <orgos-column size="0">
                    <div class="mdp-bullet-item-completed" *ngIf="iAgendaItem.completed === true">
                      <mat-icon class="mdp-icon">done</mat-icon>
                    </div>
                    <div class="mdp-bullet-item-no-completed" *ngIf="iAgendaItem.completed === false"></div>
                  </orgos-column>
                  <orgos-column size="0" class="kenjo-pr-20px mdp-disp-contents">
                    <orgos-text type="Headline3" [class.mdp-agenda-title-completed]="iAgendaItem.completed">
                      {{iAgendaItem.name}}</orgos-text
                    >
                  </orgos-column>
                  <orgos-column size="1"></orgos-column>
                  <orgos-column
                    size="0"
                    *ngIf="iAgendaItem.type === TYPE_LIST && (!iAgendaItem.completed || iAgendaItem.completed === false) && ((iAgendaItem.participantCanAdd && iAgendaItem.participantCanAdd === true) || meeting.ownerId === loggedUser._id)"
                  >
                    <orgos-action
                      class="kenjo-font-size-12px"
                      (click)="createListItem(detailIndex); $event.preventDefault()"
                      icon="add_circle"
                    >
                      {{i18n.page.addNewListItem}}</orgos-action
                    >
                  </orgos-column>
                  <orgos-column
                    size="0"
                    *ngIf="iAgendaItem.type === TYPE_TODO && (!iAgendaItem.completed || iAgendaItem.completed === false) && ((iAgendaItem.participantCanAdd && iAgendaItem.participantCanAdd === true) || meeting.ownerId === loggedUser._id)"
                  >
                    <orgos-action class="kenjo-font-size-12px" (click)="createTask(detailIndex); $event.preventDefault()" icon="add_circle">
                      {{i18n.page.addNewToDo}}</orgos-action
                    >
                  </orgos-column>
                </orgos-column-container>

                <!-- TYPE => TEXT -->
                <orgos-column-container *ngIf="iAgendaItem.type === TYPE_TEXT" class="kenjo-pb-10px">
                  <orgos-column
                    *ngIf="(iAgendaItem.completed && iAgendaItem.completed === true || ((!iAgendaItem.participantCanAdd || iAgendaItem.participantCanAdd === false) && meeting.ownerId !== loggedUser._id))"
                    class="mdp-write-here"
                  >
                    <div *ngIf="iAgendaItem.content && iAgendaItem.content !== undefined && iAgendaItem.content" class="mdp-no-border">
                      <orgos-editor-viewer [editorContent]="iAgendaItem.content" class="kenjo-p-10px"> </orgos-editor-viewer>
                    </div>
                  </orgos-column>
                  <orgos-column
                    *ngIf="(!iAgendaItem.completed || iAgendaItem.completed === false) && ((iAgendaItem.participantCanAdd && iAgendaItem.participantCanAdd === true) || meeting.ownerId === loggedUser._id)"
                  >
                    <div
                      *ngIf="clickHere !== detailIndex"
                      [class.mdp-no-border]="mouseHere !== detailIndex"
                      [class.kenjo-border]="mouseHere === detailIndex"
                      (click)="changeIndexAndSaveText(detailIndex)"
                      (mouseover)="mouseHere = detailIndex"
                      (mouseout)="mouseHere = -1"
                    >
                      <div
                        *ngIf="iAgendaItem.content && iAgendaItem.content !== undefined && iAgendaItem.content.html"
                        class="mdp-write-here"
                      >
                        <orgos-editor-viewer [editorContent]="iAgendaItem.content" class="kenjo-p-10px"> </orgos-editor-viewer>
                      </div>
                      <div *ngIf="(!iAgendaItem.content || iAgendaItem.content === undefined)" class="mdp-write-here kenjo-p-10px">
                        {{i18n.page.writeHere}}
                      </div>
                    </div>
                    <div *ngIf="clickHere === detailIndex">
                      <orgos-input-simple-editor
                        [mentionOptions]="mentionOptions"
                        [clickOnCreate]="true"
                        (inputBlur)="changeTextElement(detailIndex);"
                        [toolbar]="toolbar"
                        [(value)]="iAgendaItem.content"
                        class="mdp-quill-container kenjo-block kenjo-pt-20px"
                        size="medium"
                      ></orgos-input-simple-editor>
                    </div>
                  </orgos-column>
                </orgos-column-container>

                <!-- TYPE => LIST -->
                <orgos-column-container *ngIf="iAgendaItem.type === TYPE_LIST" class="kenjo-pb-10px">
                  <orgos-column>
                    <orgos-column-container *ngIf="iAgendaItem.content && iAgendaItem.content.length > 0">
                      <orgos-column
                        *ngIf="(!iAgendaItem.completed || iAgendaItem.completed === false) && ((iAgendaItem.participantCanAdd && iAgendaItem.participantCanAdd === true) || meeting.ownerId === loggedUser._id)"
                      >
                        <orgos-list-sortable [items]="iAgendaItem.content" (changedOrder)="saveListOrder(detailIndex)">
                          <ng-container *orgosListItem="let item; let itemIndex = index;">
                            <orgos-column-container centerColumns="true" class="kenjo-pv-10px">
                              <kenjo-icon
                                orgosColumn="0"
                                *ngIf="!item.completed"
                                [size]="20"
                                (click)="changeCompleteListItem(detailIndex, itemIndex)"
                                class="kenjo-cursor-pointer kenjo-font-color-light-text-757575 kenjo-mr-10px"
                              >
                                check_circle_outline</kenjo-icon
                              >
                              <kenjo-icon
                                orgosColumn="0"
                                *ngIf="item.completed"
                                [size]="20"
                                (click)="changeCompleteListItem(detailIndex, itemIndex)"
                                class="kenjo-cursor-pointer kenjo-font-color-success-00b72e kenjo-mr-10px"
                              >
                                check_circle</kenjo-icon
                              >
                              <input
                                orgosColumn
                                type="text"
                                [placeholder]="i18n.page.writeHere"
                                [ngModel]="item.name"
                                [readOnly]="!((!iAgendaItem.completed || iAgendaItem.completed === false) && ((iAgendaItem.participantCanAdd && iAgendaItem.participantCanAdd === true) || meeting.ownerId === loggedUser._id))"
                                (ngModelChange)="changeListItem(detailIndex, itemIndex, $event)"
                                (click)="changeIndexAndSaveText(detailIndex); editName = itemIndex"
                                (blur)="clickHere = -1; editName = -1"
                                class="mdp-title"
                                [class.mdp-title-edit-mode]="(clickHere === detailIndex && editName === itemIndex) || !item.name"
                                [matTooltip]="item.name"
                                [matTooltipDisabled]="(clickHere === detailIndex && editName === itemIndex) || !item.name"
                                matTooltipPosition="above"
                              />
                              <kenjo-icon
                                orgosColumn="0"
                                *ngIf="((!iAgendaItem.completed || iAgendaItem.completed === false) && ((iAgendaItem.participantCanAdd && iAgendaItem.participantCanAdd === true) || meeting.ownerId === loggedUser._id))"
                                [size]="20"
                                class="kenjo-cursor-pointer kenjo-ml-10px kenjo-font-color-danger-ff5757"
                                (click)="deleteListItem(detailIndex, itemIndex)"
                                >delete
                              </kenjo-icon>
                            </orgos-column-container>
                          </ng-container>
                        </orgos-list-sortable>
                      </orgos-column>
                      <orgos-column
                        *ngIf="(iAgendaItem.completed && iAgendaItem.completed === true) || ((!iAgendaItem.participantCanAdd || iAgendaItem.participantCanAdd === false) && meeting.ownerId !== loggedUser._id)"
                      >
                        <orgos-column-container
                          centerColumns="true"
                          class="kenjo-pv-10px kenjo-pl-20px kenjo-border-bottom"
                          *ngFor="let item of iAgendaItem.content; let itemIndex = index;"
                        >
                          <orgos-column size="0" class="kenjo-mr-10px">
                            <kenjo-icon *ngIf="!item.completed" [size]="20" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575">
                              check_circle_outline</kenjo-icon
                            >
                            <kenjo-icon *ngIf="item.completed" [size]="20" class="kenjo-cursor-pointer kenjo-font-color-success-00b72e">
                              check_circle</kenjo-icon
                            >
                          </orgos-column>
                          <orgos-column size="1">
                            <input
                              type="text"
                              [ngModel]="item.name"
                              [readOnly]="!((!iAgendaItem.completed || iAgendaItem.completed === false) && ((iAgendaItem.participantCanAdd && iAgendaItem.participantCanAdd === true) || meeting.ownerId === loggedUser._id))"
                              (ngModelChange)="changeListItem(detailIndex, itemIndex, $event)"
                              (click)="changeIndexAndSaveText(detailIndex); editName = itemIndex"
                              (blur)="clickHere = -1; editName = -1"
                              class="mdp-title"
                              [class.mdp-title-edit-mode]="(clickHere === detailIndex && editName === itemIndex) || !item.name"
                              [matTooltip]="item.name"
                              [matTooltipDisabled]="(clickHere === detailIndex && editName === itemIndex) || !item.name"
                              matTooltipPosition="above"
                            />
                          </orgos-column>
                        </orgos-column-container>
                      </orgos-column>
                    </orgos-column-container>
                    <orgos-column-container *ngIf="!iAgendaItem.content || iAgendaItem.content.length === 0">
                      <orgos-column size="1">
                        <orgos-text color="LightText">{{i18n.page.noItems}}</orgos-text>
                      </orgos-column>
                    </orgos-column-container>
                  </orgos-column>
                </orgos-column-container>

                <!-- TYPE => TODO -->
                <orgos-column-container centerColumns="true" *ngIf="iAgendaItem.type === TYPE_TODO" class="kenjo-pb-10px">
                  <orgos-column>
                    <div>
                      <ng-container *ngIf="iAgendaItem.content && iAgendaItem.content.length > 0">
                        <div *ngFor="let idTask of iAgendaItem.content; let indexOfTask = index;">
                          <orgos-task
                            [task]="allTasks[idTask]"
                            [showFullTitleInReadOnly]="exportingToPdf"
                            (taskDeleted)="onTaskDeleted(detailIndex, indexOfTask)"
                            [readOnly]="(iAgendaItem.completed && iAgendaItem.completed === true) || (!iAgendaItem.participantCanAdd && meeting?.ownerId !== loggedUser._id)"
                            [showRelatedTo]="false"
                            [readOnlyAssignee]="taskReadOnlyAssignee"
                            class="kenjo-pb-10px"
                          ></orgos-task>
                        </div>
                      </ng-container>
                      <orgos-column-container *ngIf="!iAgendaItem.content || iAgendaItem.content.length === 0">
                        <orgos-column size="1">
                          <orgos-text color="LightText">{{i18n.page.noItems}}</orgos-text>
                        </orgos-column>
                      </orgos-column-container>
                    </div>
                  </orgos-column>
                </orgos-column-container>

                <!-- TYPE => QUESTION -->
                <orgos-container *ngIf="iAgendaItem.type === TYPE_QUESTION" class="kenjo-pb-10px">
                  <orgos-column-container centerColumns="true">
                    <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-mr-5px kenjo-font-color-success-00b72e"> info_outline </kenjo-icon>
                    <orgos-column
                      class="kenjo-font-color-light-text-757575"
                      *ngIf="meeting.ownerId !== loggedUser._id && iAgendaItem.content.anonymous === true && iAgendaItem.content.public === false"
                    >
                      {{i18n.page.privateAnonymousResponseWarning}}</orgos-column
                    >
                    <orgos-column
                      class="kenjo-font-color-light-text-757575"
                      *ngIf="meeting.ownerId !== loggedUser._id && iAgendaItem.content.anonymous === true && iAgendaItem.content.public === true"
                    >
                      {{i18n.page.publicAnonymousResponseWarning}}</orgos-column
                    >
                    <orgos-column
                      class="kenjo-font-color-light-text-757575"
                      *ngIf="meeting.ownerId !== loggedUser._id && iAgendaItem.content.anonymous === false && iAgendaItem.content.public === false"
                    >
                      {{i18n.page.privateResponseWarning}}</orgos-column
                    >
                    <orgos-column
                      class="kenjo-font-color-light-text-757575"
                      *ngIf="meeting.ownerId !== loggedUser._id && iAgendaItem.content.anonymous === false && iAgendaItem.content.public === true"
                    >
                      {{i18n.page.publicResponseWarning}}</orgos-column
                    >
                    <orgos-column
                      class="kenjo-font-color-light-text-757575"
                      *ngIf="meeting.ownerId === loggedUser._id && iAgendaItem.content.public === true"
                    >
                      {{i18n.page.publicForOwner}}</orgos-column
                    >
                    <orgos-column
                      class="kenjo-font-color-light-text-757575"
                      *ngIf="meeting.ownerId === loggedUser._id && iAgendaItem.content.public === false"
                    >
                      {{i18n.page.privateForOwner}}</orgos-column
                    >
                  </orgos-column-container>

                  <div
                    class="kenjo-mv-15px kenjo-font-color-light-text-757575"
                    *ngIf="meeting.ownerId === loggedUser._id && responsesMap && responsesMap[iAgendaItem._id]"
                  >
                    {{i18n.page.totalAnswer}}: {{responsesMap[iAgendaItem._id].responded}}/{{responsesMap[iAgendaItem._id].total}}
                  </div>

                  <div *ngIf="responsesMap[iAgendaItem._id] && responsesMap[iAgendaItem._id].responses">
                    <ng-container *ngFor="let iResponse of responsesMap[iAgendaItem._id].responses; let responseIndex = index">
                      <orgos-column-container
                        centerColumns="true"
                        class="kenjo-mt-20px kenjo-mb-10px"
                        *ngIf="iResponse._receiverId === loggedUser._id || ((meeting.ownerId === loggedUser._id || (iAgendaItem.content.public && iAgendaItem.content.public === true)) && (!iAgendaItem.content.anonymous || iAgendaItem.content.anonymous === false))"
                      >
                        <orgos-column size="0" class="kenjo-mr-10px">
                          <div class="mdp-avatar-container">
                            <orgos-avatar
                              class="mdp-disp-contents"
                              size="small"
                              [avatarName]="userPersonalMap[iResponse._receiverId]?.displayName"
                              [photoUrl]="userPersonalMap[iResponse._receiverId]?._photo?._url"
                            ></orgos-avatar>
                            <mat-icon
                              class="mdp-icon-avatar kenjo-font-color-success-00b72e mdp-response-can-not-edit"
                              *ngIf="iResponse.replied && iResponse.replied === true"
                              >check_circle</mat-icon
                            >
                            <mat-icon
                              class="mdp-icon-avatar mdp-no-replied-icon mdp-response-can-not-edit"
                              *ngIf="!iResponse.replied || iResponse.replied === false"
                              >schedule</mat-icon
                            >
                          </div>
                        </orgos-column>
                        <orgos-column size="1"> {{userPersonalMap[iResponse._receiverId]?.displayName}} </orgos-column>
                      </orgos-column-container>

                      <orgos-column-container
                        centerColumns="true"
                        class="kenjo-mt-20px kenjo-mb-10px"
                        *ngIf=" iResponse.replied && iResponse.replied === true && iResponse._receiverId
                          !==loggedUser._id && ((meeting.ownerId===loggedUser._id || (iAgendaItem.content.public &&
                          iAgendaItem.content.public===true && myResponses.includes(iAgendaItem._id))) &&
                          iAgendaItem.content.anonymous && iAgendaItem.content.anonymous===true)"
                      >
                        <orgos-column size="0" class="kenjo-mr-10px">
                          <div class="mdp-logo-container">
                            <div>
                              <mat-icon class="mdp-anonymous-logo">person</mat-icon>
                            </div>
                          </div>
                        </orgos-column>
                        <orgos-column size="1"> {{i18n.page.anonymousFeedback}} </orgos-column>
                      </orgos-column-container>

                      <div
                        *ngIf="iResponse._receiverId === loggedUser._id || ((meeting.ownerId === loggedUser._id || (iAgendaItem.content.public && iAgendaItem.content.public === true && myResponses.includes(iAgendaItem._id))) && iResponse.replied && iResponse.replied === true)"
                      >
                        <div
                          class="
                            mdp-response-container
                            kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-20px
                          "
                        >
                          <div class="kenjo-mb-10px">
                            <textarea
                              matInput
                              cdkTextareaAutosize
                              rows="3"
                              class="mdp-response"
                              [readonly]="iResponse._receiverId !== loggedUser._id || (iAgendaItem.completed && iAgendaItem.completed === true)"
                              [class.mdp-response-can-not-edit]="iResponse._receiverId !== loggedUser._id || iAgendaItem.completed === true"
                              [placeholder]="i18n.page.noReplied"
                              [(ngModel)]="iResponse.replyMessage"
                              (click)="changeIndexAndSaveText(detailIndex); editResponse = responseIndex"
                            ></textarea>
                          </div>
                          <div
                            class="kenjo-text-align-right"
                            *ngIf="clickHere === detailIndex && editResponse === responseIndex && !(iResponse._receiverId !== loggedUser._id || (iAgendaItem.completed && iAgendaItem.completed === true)); else updatedAtBlock"
                          >
                            <orgos-action (click)="updateResponse(iResponse);clickHere = -1; editResponse = -1">
                              {{i18n.page.post}}</orgos-action
                            >
                          </div>
                          <ng-template #updatedAtBlock>
                            <div class="mdp-align-right" *ngIf="!!iResponse.replyMessage === true">
                              <orgos-text color="LightText" type="SecondaryText"> {{iResponse._updatedAt | humanDate : true}}</orgos-text>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </orgos-container>
              </div>
            </ng-container>
            <!-- END AGENDA ITEMS DETAILS -->
          </div>
        </orgos-container>
      </orgos-column>
      <!-- END MEETING DETAIL-->

      <!-- RIGHT COLUMN-->
      <orgos-column size="1">
        <!-- PARTICIPANTS -->
        <orgos-container aspect="card">
          <orgos-column-container centerColumns="true" class="kenjo-p-10px kenjo-border-bottom">
            <orgos-column size="1">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-font-size-16px"> {{i18n.page.participants}} </orgos-column>
                <orgos-column
                  size="0"
                  *ngIf="meeting.ownerId === loggedUser._id"
                  (click)="sendReminder()"
                  [matTooltip]="meeting.remindMeeting === true ? i18n.misc.sendReminderEnabledTooltip : i18n.misc.sendReminderDisabledTooltip"
                  class="kenjo-ml-10px kenjo-cursor-pointer mdp-notification exclude-from-pdf"
                >
                  <kenjo-icon [size]="18" class="kenjo-font-color-primary-5993e3"> notification_menu </kenjo-icon>
                  <div
                    class="mdp-notification-status kenjo-inline-block"
                    [class.mdp-notification-status-active]="meeting.remindMeeting === true"
                    [class.mdp-notification-status-inactive]="meeting.remindMeeting === false"
                  ></div>
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="0" *ngIf="meeting.ownerId === loggedUser._id" class="kenjo-ml-20px exclude-from-pdf">
              <orgos-action (click)="addParticipant(); $event.preventDefault()" icon="add_circle">
                {{i18n.page.addParticipant}}</orgos-action
              >
            </orgos-column>
          </orgos-column-container>
          <div class="mdp-participants-content">
            <!-- OWNER -->
            <orgos-column-container centerColumns="true" class="kenjo-pv-10px kenjo-ph-20px kenjo-border-bottom">
              <orgos-column size="0" class="kenjo-pr-20px">
                <orgos-avatar
                  size="small"
                  [avatarName]="userPersonalMap[meeting.ownerId]?.displayName"
                  [photoUrl]="userPersonalMap[meeting.ownerId]?._photo?._url"
                  [matTooltip]="userPersonalMap[meeting.ownerId]?.displayName"
                ></orgos-avatar>
              </orgos-column>
              <orgos-column size="1">
                {{userPersonalMap[meeting.ownerId]?.displayName}}
                <div class="kenjo-font-color-success-00b72e kenjo-inline-block">{{i18n.page.meetingOwner}}</div>
              </orgos-column>
              <orgos-column
                size="0"
                class="kenjo-font-color-success-00b72e kenjo-ml-10px kenjo-mr-30px"
                *ngIf="usersAway && usersAway[meeting.ownerId]"
              >
                <orgos-column-container centerColumns="true">
                  <mat-icon orgosColumn="0" class="mdp-icon">time_off</mat-icon>
                  <div orgosColumn="0" class="kenjo-ml-5px kenjo-inline-block">{{i18n.page.awayToday}}</div>
                </orgos-column-container>
              </orgos-column>
            </orgos-column-container>

            <!-- PARTICIPANTS LIST -->
            <orgos-column-container
              centerColumns="true"
              class="kenjo-pv-10px kenjo-ph-20px kenjo-border-bottom"
              *ngFor="let iParticipant of meeting.participants"
            >
              <orgos-column size="0" class="kenjo-pr-20px exclude-from-pdf">
                <div
                  class="mdp-avatar-container"
                  [class.mdp-click]="meeting.ownerId === loggedUser._id"
                  [matTooltip]="iParticipant.absent && iParticipant.absent === true? i18n.page.absent: i18n.page.present"
                  matTooltipPosition="right"
                >
                  <orgos-avatar
                    class="mdp-avatar"
                    (click)="changeAbsent(!iParticipant.absent, iParticipant.userId)"
                    size="small"
                    [avatarName]="userPersonalMap[iParticipant.userId]?.displayName"
                    [photoUrl]="userPersonalMap[iParticipant.userId]?._photo?._url"
                  ></orgos-avatar>
                  <mat-icon
                    class="mdp-icon-avatar kenjo-cursor-pointer kenjo-font-color-danger-ff5757"
                    (click)="changeAbsent(!iParticipant.absent, iParticipant.userId)"
                    *ngIf="iParticipant.absent && iParticipant.absent === true"
                    >cancel</mat-icon
                  >
                </div>
              </orgos-column>
              <orgos-column size="1" class="kenjo-pr-20px"> {{userPersonalMap[iParticipant.userId]?.displayName}} </orgos-column>
              <orgos-column
                size="0"
                class="kenjo-font-color-success-00b72e kenjo-ml-10px"
                [class.kenjo-mr-30px]="meeting.ownerId !== loggedUser._id"
                [class.kenjo-mr-10px]="meeting.ownerId === loggedUser._id"
                *ngIf="usersAway && usersAway[iParticipant.userId]"
              >
                <orgos-column-container centerColumns="true">
                  <mat-icon orgosColumn="0" class="mdp-icon-15px">time_off</mat-icon>
                  <div orgosColumn="0" class="kenjo-ml-5px kenjo-inline-block">{{i18n.page.awayToday}}</div>
                </orgos-column-container>
              </orgos-column>
              <orgos-column size="0" *ngIf="meeting.ownerId === loggedUser._id" class="exclude-from-pdf">
                <ng-container>
                  <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert </kenjo-icon>
                  <mat-menu #actionsMenu="matMenu">
                    <button
                      mat-menu-item
                      (click)="changeAbsent(true, iParticipant.userId)"
                      *ngIf="!iParticipant.absent || iParticipant.absent === false"
                    >
                      <span>{{i18n.page.markAsAbsent}}</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="changeAbsent(false, iParticipant.userId)"
                      *ngIf="iParticipant.absent && iParticipant.absent === true"
                    >
                      <span>{{i18n.page.markAsPresent}}</span>
                    </button>
                    <button mat-menu-item (click)="changeOwnership(iParticipant.userId)">
                      <span>{{i18n.page.transfer}}</span>
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="!participantsWithOneResponse.includes(iParticipant.userId)"
                      (click)="deleteParticipant(iParticipant.userId)"
                    >
                      <span>{{i18n.page.delete}}</span>
                    </button>
                  </mat-menu>
                </ng-container>
              </orgos-column>
            </orgos-column-container>
          </div>
        </orgos-container>

        <!-- AGENDA -->
        <orgos-container class="mdp-agenda-container kenjo-mt-20px" aspect="card">
          <orgos-column-container centerColumns="true" class="kenjo-p-10px">
            <orgos-column size="1">
              <orgos-text type="Headline4">{{i18n.page.agendaTitle}}</orgos-text>
            </orgos-column>
            <orgos-column size="0" class="exclude-from-pdf">
              <orgos-action *ngIf="meeting.ownerId === loggedUser._id" (click)="addAgendaItem(); $event.preventDefault()" icon="add_circle">
                {{i18n.page.addItem | uppercase}}</orgos-action
              >
            </orgos-column>
          </orgos-column-container>
          <div class="mdp-agenda-content">
            <div
              class="mdp-empty-agenda-size flex-horizontal flex-center flex-center-vertically"
              *ngIf="!meeting.agenda || meeting.agenda.length === 0"
            >
              <orgos-column-container centerColumns="true" class="kenjo-mt-60px">
                <orgos-column size="1"></orgos-column>
                <orgos-column size="0" class="kenjo-pr-10px">
                  <kenjo-icon [size]="20" class="kenjo-font-color-light-text-757575">agenda_icon</kenjo-icon>
                </orgos-column>
                <orgos-column size="0">
                  <orgos-text type="bodyText" color="LightText">{{i18n.page.noAgendaItems}}</orgos-text>
                </orgos-column>
                <orgos-column size="1"></orgos-column>
              </orgos-column-container>
            </div>
            <orgos-list-sortable
              *ngIf="meeting.agenda && meeting.agenda.length > 0 && meeting.ownerId === loggedUser._id"
              [items]="meeting.agenda"
              (changedOrder)="saveAgendaOrder()"
            >
              <ng-container *orgosListItem="let item; let itemIndex = index;">
                <orgos-column-container centerColumns="true" class="kenjo-pv-10px">
                  <kenjo-icon
                    orgosColumn="0"
                    *ngIf="!item.completed"
                    [size]="20"
                    (click)="changeCompleteAgendaItem(itemIndex)"
                    class="kenjo-cursor-pointer kenjo-font-color-light-text-757575 kenjo-mr-10px"
                  >
                    check_circle_outline</kenjo-icon
                  >
                  <kenjo-icon
                    orgosColumn="0"
                    *ngIf="item.completed"
                    [size]="20"
                    (click)="changeCompleteAgendaItem(itemIndex)"
                    class="kenjo-cursor-pointer kenjo-font-color-success-00b72e kenjo-mr-10px"
                  >
                    check_circle</kenjo-icon
                  >
                  <orgos-column size="1"> {{item.name}} </orgos-column>
                  <orgos-column size="0" class="exclude-from-pdf">
                    <ng-container>
                      <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert </kenjo-icon>
                      <mat-menu #actionsMenu="matMenu">
                        <button mat-menu-item (click)="editAgendaItem(itemIndex)">
                          <span>{{i18n.page.edit}}</span>
                        </button>
                        <button mat-menu-item (click)="deleteAgendaItem(itemIndex)">
                          <span>{{i18n.page.delete}}</span>
                        </button>
                      </mat-menu>
                    </ng-container>
                  </orgos-column>
                </orgos-column-container>
              </ng-container>
            </orgos-list-sortable>
            <div *ngIf="meeting.agenda && meeting.agenda.length > 0 && (meeting.ownerId !== loggedUser._id)" class="kenjo-border-top">
              <orgos-column-container
                centerColumns="true"
                class="kenjo-pv-10px kenjo-pl-20px kenjo-border-bottom"
                *ngFor="let item of meeting.agenda; let itemIndex = index;"
              >
                <orgos-column size="0" class="kenjo-mr-20px">
                  <kenjo-icon *ngIf="!item.completed" [size]="20" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575">
                    check_circle_outline</kenjo-icon
                  >
                  <kenjo-icon *ngIf="item.completed" [size]="20" class="kenjo-cursor-pointer kenjo-font-color-success-00b72e">
                    check_circle</kenjo-icon
                  >
                </orgos-column>
                <orgos-column size="1">
                  <orgos-text type="bodyText">{{item.name}}</orgos-text>
                </orgos-column>
              </orgos-column-container>
            </div>
          </div>
        </orgos-container>
      </orgos-column>
    </orgos-column-container>
  </div>
</ng-container>

<div *ngIf="loadingPage && i18n.generalMisc" class="kenjo-mh-20px mdp-loading-container">
  <div class="kenjo-text-align-center">
    <orgos-loading-spinner></orgos-loading-spinner>
    <div class="kenjo-mt-20px">{{ i18n.generalMisc.loading }}</div>
  </div>
</div>
