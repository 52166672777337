import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';

@Component({
  selector: 'orgos-settings-billing-menu',
  templateUrl: 'settings-billing-menu.component.html',
  styleUrls: ['settings-billing-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsBillingMenuComponent {
  @Input() menuTranslation: any = {};
  @Input() selectedOption: number = 0;

  constructor(private injector: Injector) {}

  sendPUEvent(): void {
    this.injector.get(PrivateAmplitudeService).logEvent('Navigate to usage dashboard', { category: 'PLG', subcategory1: 'Platform usage' });
  }
}
