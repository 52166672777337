import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class CustomFieldService implements IGenericService {
  private CUSTOM_FIELD_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/custom-field-db`;
  private CUSTOM_FIELD_PERMISSIONS_KEY: string = 'custom-field';
  private CUSTOM_FIELD_INTERNATIONALIZATION: string = 'custom-field-collection';

  constructor(private genericService: GenericService, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: ICustomFieldModel, generateForExistingRecords: boolean = false): Promise<ICustomFieldModel> {
    return new Promise<ICustomFieldModel>((resolve, reject) => {
      let url = this.CUSTOM_FIELD_URL;
      if (generateForExistingRecords) {
        url += '?generateForExistingRecords';
      }

      this.genericService
        .create(url, data)
        .then((customField: ICustomFieldModel) => {
          resolve(customField);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CustomFieldService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<ICustomFieldModel> {
    return new Promise<ICustomFieldModel>((resolve, reject) => {
      this.genericService
        .getById(this.CUSTOM_FIELD_URL, id)
        .then((customField: ICustomFieldModel) => {
          resolve(customField);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CustomFieldService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: ICustomFieldModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.CUSTOM_FIELD_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CustomFieldService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.CUSTOM_FIELD_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CustomFieldService.name, 'deleteById'));
        });
    });
  }

  find(findBody): Promise<Array<ICustomFieldModel>> {
    return new Promise<Array<ICustomFieldModel>>((resolve, reject) => {
      this.genericService
        .find(this.CUSTOM_FIELD_URL, findBody)
        .then((customFields: Array<ICustomFieldModel>) => {
          resolve(customFields);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CustomFieldService.name, 'find'));
        });
    });
  }

  getByCollection(collectionName: string): Promise<Array<ICustomFieldModel>> {
    return new Promise<Array<ICustomFieldModel>>((resolve, reject) => {
      const findBody = {
        collectionName: collectionName
      };

      this.genericService
        .find(this.CUSTOM_FIELD_URL, findBody)
        .then((customFields: Array<ICustomFieldModel>) => {
          resolve(customFields);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CustomFieldService.name, 'getByCollection'));
        });
    });
  }

  getUserCustomFields(): Promise<Array<ICustomFieldModel>> {
    return new Promise<Array<ICustomFieldModel>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${this.CUSTOM_FIELD_URL}/user`, httpOptions)
        .toPromise()
        .then((responseData: Array<ICustomFieldModel>) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CustomFieldService.name, 'getUserCustomFields'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.CUSTOM_FIELD_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.CUSTOM_FIELD_INTERNATIONALIZATION);
  }
}

export interface ICustomFieldModel {
  _id?: string;
  collectionName?: string;
  fieldApiName?: string;
  fieldLabel?: string;
  fieldType?: string;
  listValues?: Array<string>;
  autoNumberMask?: string;
  autoNumberNextValue?: number;
}
