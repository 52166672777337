<orgos-container aspect="card">
  <orgos-column-container centerColumns="true">
    <orgos-column size="0" *ngIf="isRead === false" class="fc-not-read"></orgos-column>
    <orgos-column size="0" class="kenjo-pv-20px kenjo-pl-20px">
      <orgos-avatar [isAnonymous]="feed.isAnonymous" [avatarName]="displayName" [photoUrl]="photoUrl" size="small"> </orgos-avatar>
    </orgos-column>
    <orgos-column class="kenjo-p-20px kenjo-border-right">
      <orgos-column-container *ngIf="questionTitle" centerColumns="true">
        <kenjo-icon orgosColumn="0" [size]="16" class="kenjo-font-color-light-text-757575 kenjo-mr-5px">forum </kenjo-icon>
        <orgos-column class="kenjo-font-color-light-text-757575">{{ questionTitle }}</orgos-column>
      </orgos-column-container>
      <div [class.fc-not-read-title]="isRead === false" class="kenjo-mt-15px kenjo-mb-10px">{{ feedTitle }}</div>
      <orgos-column-container centerColumns="true">
        <orgos-column size="0" class="kenjo-font-color-light-text-757575">
          {{ feed._createdAt | date: 'shortDate' }}
        </orgos-column>
        <kenjo-icon orgosColumn="0" *ngIf="feed.isAnonymous === true" [size]="16" class="kenjo-font-color-light-text-757575 kenjo-ml-20px">
          visibility_off
        </kenjo-icon>
        <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-ml-5px kenjo-mr-20px" *ngIf="feed.isAnonymous === true">
          {{ pageTranslation.anonymousFeedBackMessage }}
        </orgos-column>
        <kenjo-icon orgosColumn="0" *ngIf="feed.isAnonymous === false" [size]="16" class="kenjo-font-color-light-text-757575 kenjo-ml-20px">
          visibility
        </kenjo-icon>
        <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-ml-5px kenjo-mr-20px" *ngIf="feed.isAnonymous === false">
          {{ displayName }}
        </orgos-column>
        <orgos-column *ngIf="feed.tags && feed.tags.length > 0">
          <orgos-tag-list [tagList]="feed.tags"></orgos-tag-list>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="0" class="kenjo-p-20px">
      <orgos-action (click)="openReplyPopup()">{{ pageTranslation.replyButtonLabel }}</orgos-action>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
