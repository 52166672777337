import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  IRejectionReasonModel,
  RecruitingRejectionReasonService,
} from '@app/standard/services/recruiting/recruiting-rejection-reason.service';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-candidate-rejection-reason-dialog',
  templateUrl: 'candidate-rejection-reason.dialog.html',
  styleUrls: ['candidate-rejection-reason.dialog.scss'],
})
export class CandidateRejectionReasonDialog implements OnInit {
  i18n: any = {
    dialog: {},
  };
  miscTranslation: any = {};

  rejectionReason: IRejectionReasonModel;
  rejectionReasons: Array<any>;
  rejectionReasonSelected: string = '';
  titleText: string;

  constructor(
    private dialogRef: MatLegacyDialogRef<CandidateRejectionReasonDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-candidate-rejection-reason-dialog')
      .then((dialogTranslation) => {
        this.i18n.dialog = dialogTranslation;
        this.titleText = this.injector
          .get(I18nDataPipe)
          .transform(this.i18n.dialog.dialogTitle, { candidateName: this.data.candidateName });
      })
      .catch(() => {
        this.i18n.dialog = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.injector
      .get(RecruitingRejectionReasonService)
      .find({ _id: { $ne: null } })
      .then((recrutingRejectionReasons) => {
        this.rejectionReasons = Object.values(recrutingRejectionReasons).map((iRecruitingReason) => {
          return iRecruitingReason.name;
        });
        this.rejectionReasons = _.orderBy(this.rejectionReasons, [(rejectionReason) => rejectionReason.toLowerCase()], ['asc']);
      })
      .catch(() => {
        this.rejectionReasons = [];
      });
  }

  public cancelRejection(): void {
    this.dialogRef.close(false);
  }

  public acceptRejection(): void {
    this.dialogRef.close(this.rejectionReasonSelected);
  }

  public changeRejectionReason(rejectionReason: string, newValue: boolean): void {
    if (newValue === false) {
      this.rejectionReasonSelected = '';
      return;
    }
    this.rejectionReasonSelected = rejectionReason;
  }
}
