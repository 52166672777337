import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IAnnouncementWidgetModel } from '@app/models/controllers/announcements.model';
import { IApplicantsPerPosition, IPositionSummary } from '@app/models/controllers/recruiting.model';
import { IUserPersonalWorkModel } from '@app/models/controllers/user-personal-work.model';
import { IWhoIsAway } from '@app/models/controllers/who-is-away.model';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable()
export class WidgetsService {
  private WIDGETS_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/widgets`;

  constructor(private injector: Injector) {}

  getNewApplicants(): Promise<Array<IApplicantsPerPosition>> {
    return new Promise<Array<IApplicantsPerPosition>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<Array<IApplicantsPerPosition>>(`${this.WIDGETS_URL}/new-applicants`, httpOptions)
        .toPromise()
        .then((response: Array<IApplicantsPerPosition>) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'newApplicants'));
        });
    });
  }

  getJobOpenings(): Promise<Array<IPositionSummary>> {
    return new Promise<Array<IPositionSummary>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<Array<IPositionSummary>>(`${this.WIDGETS_URL}/job-openings`, httpOptions)
        .toPromise()
        .then((response: Array<IPositionSummary>) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'getJobOpenings'));
        });
    });
  }

  getAnnouncements(showArchived: boolean = false): Promise<IAnnouncementWidgetModel> {
    return new Promise<IAnnouncementWidgetModel>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        resolve(undefined);
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<IAnnouncementWidgetModel>(`${this.WIDGETS_URL}/announcements?showArchived=${showArchived}`, httpOptions)
        .toPromise()
        .then((response: IAnnouncementWidgetModel) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'getAnnouncements'));
        });
    });
  }

  getNextBirthdays(): Promise<Array<IUserPersonalModel>> {
    return new Promise<Array<IUserPersonalModel>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<Array<IUserPersonalModel>>(`${this.WIDGETS_URL}/next-birthdays`, httpOptions)
        .toPromise()
        .then((response: Array<IUserPersonalModel>) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'getNextBirthdays'));
        });
    });
  }

  getWorkAnniversaries(): Promise<Array<IUserPersonalWorkModel>> {
    return new Promise<Array<IUserPersonalWorkModel>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<Array<IUserPersonalWorkModel>>(`${this.WIDGETS_URL}/work-anniversaries`, httpOptions)
        .toPromise()
        .then((response: Array<IUserPersonalWorkModel>) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'getWorkAnniversaries'));
        });
    });
  }

  async getWhoIsAway(): Promise<IWhoIsAway> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get<IWhoIsAway>(`${this.WIDGETS_URL}/who-is-away`, httpOptions).toPromise();
    } catch (error) {
      const handledError = this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'whoIsAway');
      throw handledError;
    }
  }

  async getMyTeamMembers(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const myTeamMembers = await this.injector.get(HttpClient).get<Array<any>>(`${this.WIDGETS_URL}/my-team`, httpOptions).toPromise();

      return myTeamMembers;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'getMyTeamMembers');
    }
  }

  async getMyTeamMembersForCalendar(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const myTeamMembers = await this.injector.get(HttpClient).get<Array<any>>(`${this.WIDGETS_URL}/my-team/calendar`, httpOptions).toPromise();

      return myTeamMembers;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, WidgetsService.name, 'getMyTeamMembersForCalendar');
    }
  }
}
