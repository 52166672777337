import { Component, Input } from '@angular/core';
import { VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { IShiftplanExportPDFOptions } from '@app/cloud-features/shift-plan/services/shift-plan-generate-pdf.service';
import { IPublicHolidays } from '@app/cloud-features/shift-plan/services/shift-plan-public-holidays.service';
import { ITimeOffRequestDay } from '@app/cloud-features/shift-plan/services/shift-plan-time-off.service';
@Component({
  selector: 'kenjo-shiftplan-pdf-row',
  templateUrl: './shiftplan-pdf-row.component.html',
  styleUrls: ['./shiftplan-pdf-row.component.scss'],
})
export class ShiftplanPdfRowComponent {
  orientation = { LANDSCAPE: 'landscape', PORTRAIT: 'portrait' };
  views = { ...VIEWS };

  @Input() currentDays: Array<moment.Moment>;
  @Input() options: IShiftplanExportPDFOptions;
  @Input() employee;
  @Input() openShifts;
  @Input() timeOff: { days: { [day: number]: ITimeOffRequestDay } };
  @Input() holidays: Array<Array<IPublicHolidays>> = [];
  @Input() componentTranslations;

  constructor() {}
}
