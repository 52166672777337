import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import * as check from 'check-types';

@Component({
  templateUrl: 'add-edit-tag-dialog.html',
  styleUrls: ['add-edit-tag-dialog.scss'],
})
export class SettingsShiftPlanTagDialog implements OnInit {
  dialogTranslation: any = {};
  shiftPlanRolesSettingsTranslations: any = {};
  tag: GenericCacheModel;
  isNewTag: boolean;
  tagOptions: Array<ISelectOption> = [
    {
      name: 'Skills',
      value: 'skills',
    },
    {
      name: 'Information',
      value: 'information',
    },
    {
      name: 'Other',
      value: 'other',
    },
  ];

  nameCreateValidation: InputValidation;
  nameEditValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<SettingsShiftPlanTagDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private snackBar: MatLegacySnackBar,
    private injector: Injector,
    private i18nDataPipe: I18nDataPipe
  ) {}

  ngOnInit(): void {
    this.tag = this.data.tag;
    this.isNewTag = this.data.isNewTag;
    this.dialogTranslation = this.data.dialogTranslation;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addTag(): void {
    if (check.not.assigned(this.nameCreateValidation) || this.nameCreateValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close({
      tagData: this.tag.data,
    });
  }

  editTag(): void {
    if (check.not.assigned(this.nameEditValidation) || this.nameEditValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close({
      tagData: this.tag.data,
    });
  }
}
