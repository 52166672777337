import { Injectable, Injector } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IPayrollSurchargeRule } from '@app/cloud-features/settings-surcharges/services/payroll-surcharge-rules.service';
import { DatePipe } from '@angular/common';
import * as check from 'check-types';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PayrollSurchargeUtilsService {
  private settingsPayrollPageTranslations: {[translationKey: string]: string};

  constructor(private injector: Injector) {}

  convertMinutesToString(minutesValue: number): string {
    if (!minutesValue && minutesValue !== 0) {
      return undefined;
    }

    const hours = Math.floor((minutesValue / 60) % 24);
    const minutes = (((minutesValue / 60) % 24) - Math.floor((minutesValue / 60) % 24)) * 60;

    const time = `${this.injector.get(DecimalPipe).transform(hours, '2.0-0')}:${this.injector.get(DecimalPipe).transform(minutes, '2.0-0')}`;

    return time;
  }

  async getTranslationParams(surchargeRule: IPayrollSurchargeRule, maxLengthWeekDays: number = 100): Promise<ISurchargeMessageTranslationParams> {
    const translationParams: ISurchargeMessageTranslationParams = {
      from: '00:00',
      to: '23:59',
      weekdays: '',
      startsOn: '',
      endsOn: '',
      percentage: surchargeRule.extraRatePercentage ? surchargeRule.extraRatePercentage.toString() : ''
    };

    if (this.surchargeAppliesToSomeWeekday(surchargeRule) && check.assigned(surchargeRule.appliesFromTime) && check.assigned(surchargeRule.appliesToTime)) {
      translationParams.from = this.convertMinutesToString(surchargeRule.appliesFromTime);
      translationParams.to = this.convertMinutesToString(surchargeRule.appliesToTime);
      translationParams.weekdays = await this.getWeekdaysTanslated(surchargeRule, maxLengthWeekDays);
    }

    if (surchargeRule.ruleStartDate) {
      translationParams.startsOn = await this.getDateTranslated(surchargeRule.ruleStartDate);
    }

    if (surchargeRule.ruleEndDate) {
      translationParams.endsOn = await this.getDateTranslated(surchargeRule.ruleEndDate);
    }

    return translationParams;
  }

  async getDateTranslated(date: Date): Promise<string> {
    return this.injector.get(DatePipe).transform(date, 'd LLL YYYY', 'UTC');
  }

  private async initSettingsPayrollTranslations(): Promise<void> {
    if (!this.settingsPayrollPageTranslations) {
      this.settingsPayrollPageTranslations = await this.injector.get(InternationalizationService).getAllTranslation('settings-payroll-page');
    }
  }

  async getWeekdaysTanslated(surchargeRule: IPayrollSurchargeRule, maxLength: number = 100): Promise<string> {
    if (!surchargeRule.appliesToWeekdays) {
      return '';
    }

    await this.initSettingsPayrollTranslations();

    const weekDaysSetToTrue = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].filter((weekDay: string) => {
      return surchargeRule.appliesToWeekdays[weekDay];
    });
    const connector = ', ';
    const result = weekDaysSetToTrue.reduce((total: string, weekDay: string, index: number) => {
      const translatedWeekDay = this.settingsPayrollPageTranslations[weekDay].slice(0, maxLength);
      total = total ? `${total}${connector}${translatedWeekDay}` : translatedWeekDay;
      return total;
    }, '');
    return result;
  }

  surchargeAppliesToSomeWeekday(surchargeRule: IPayrollSurchargeRule): boolean {
    if (!surchargeRule?.appliesToWeekdays || check.emptyObject(surchargeRule.appliesToWeekdays)) {
      return false;
    }
    return Object.values(surchargeRule.appliesToWeekdays).some((value) => value === true);
  }

  async calculateSurchargeIdToConditionMessage(surchargeRules: Array<IPayrollSurchargeRule>): Promise<{[surchargeId: string]: string}> {
    const surchargeIdToConditionMessage: {[surchargeId: string]: string} = {};

    if (!surchargeRules?.length) {
      return {};
    }

    await this.initSettingsPayrollTranslations();

    for (let i = 0; i < surchargeRules.length; i++) {
      const translationParams = await this.injector.get(PayrollSurchargeUtilsService).getTranslationParams(surchargeRules[i], 3);

      let conditionMessage = '';
      const appliesToWeekdays = this.injector.get(PayrollSurchargeUtilsService).surchargeAppliesToSomeWeekday(surchargeRules[i]);
      if (surchargeRules[i].appliesToPublicHolidays && appliesToWeekdays) {
        conditionMessage = this.injector.get(I18nDataPipe).transform(this.settingsPayrollPageTranslations.surchargeTableSummaryWeekdaysAndHolidays, translationParams);
      } else if (surchargeRules[i].appliesToPublicHolidays && !appliesToWeekdays) {
        conditionMessage = this.injector.get(I18nDataPipe).transform(this.settingsPayrollPageTranslations.surchargeTableSummaryHolidaysOnly, translationParams);
      } else if (!surchargeRules[i].appliesToPublicHolidays && appliesToWeekdays) {
        conditionMessage = this.injector.get(I18nDataPipe).transform(this.settingsPayrollPageTranslations.surchargeTableSummaryWeekdaysOnly, translationParams);
      }
      surchargeIdToConditionMessage[surchargeRules[i]._id] = conditionMessage;
    }

    return surchargeIdToConditionMessage;
  }

  calculateSurchargeIdToSurchargeStatus(surchargeRules: Array<IPayrollSurchargeRule>): {[surchargeId: string]: 'SURCHARGE_STATUS_ACTIVE' | 'SURCHARGE_STATUS_PAST' | 'SURCHARGE_STATUS_FUTURE'} {
    if (!surchargeRules?.length) {
      return {};
    }

    const today: moment.Moment = moment.utc().startOf('day');

    const surchargeIdToSurchargeStatus = surchargeRules.reduce((total: { [ surchargeId: string ]: 'SURCHARGE_STATUS_ACTIVE' | 'SURCHARGE_STATUS_PAST' | 'SURCHARGE_STATUS_FUTURE' }, surchargeRule: IPayrollSurchargeRule) => {
      const ruleStartDate = moment(surchargeRule.ruleStartDate);
      const ruleEndDate = surchargeRule?.ruleEndDate ? moment(surchargeRule.ruleEndDate) : null;

      let status: 'SURCHARGE_STATUS_ACTIVE' | 'SURCHARGE_STATUS_PAST' | 'SURCHARGE_STATUS_FUTURE' = 'SURCHARGE_STATUS_ACTIVE';
      if (ruleEndDate && ruleEndDate.isBefore(today, 'day')) {
        status = 'SURCHARGE_STATUS_PAST';
      } else if (ruleStartDate.isAfter(today, 'day')) {
        status = 'SURCHARGE_STATUS_FUTURE';
      }

      total[surchargeRule._id] = status;
      return total;
    }, {});
    return surchargeIdToSurchargeStatus;
  }
}

export interface ISurchargeMessageTranslationParams {
  from?: string;
  to?: string;
  weekdays?: string;
  startsOn?: string;
  endsOn?: string;
  percentage?: string;
}
