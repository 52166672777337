import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'kenjo-confirm-dialog-form',
  templateUrl: 'confirm-dialog-form.component.html',
  styleUrls: ['confirm-dialog-form.component.scss'],
})
export class ConfirmDialogFormComponent {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' = 'Success';
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;
  confirmDisabled: boolean;
  cancelDisabled: boolean;
  employees: Array<any>;
  inputs: Array<IConfirmDialogInput>;

  constructor(
    public dialogRef: MatLegacyDialogRef<ConfirmDialogFormComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {
    this.titleText = this.data.titleText;
    this.subtitleText = this.data.subtitleText;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.confirmButtonId = this.data.confirmButtonId ?? '';
    this.cancelButtonId = this.data.cancelButtonId ?? '';
    this.componentId = this.data.componentId ?? null;
    this.confirmDisabled = this.data.confirmDisabled ?? false;
    this.cancelDisabled = this.data.cancelDisabled ?? false;
    this.employees = this.data.employees;
    this.inputs = cloneDeep(this.data.inputs);
    this.dialogRef.disableClose = !!this.data.disableClose;
  }

  closeDialog(): void {
    this.closeDialogWithParams(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.closeDialogWithParams(false);
      return;
    }
    this.closeDialogWithParams(true);
  }

  closeDialogWithParams(confirm: boolean): void {
    const formValues = this.inputs.reduce((total, input) => {
      return { ...total, [input.key]: input.value };
    }, {});
    this.dialogRef.close({ confirm, formValues });
  }
}

export interface IConfirmDialogInput {
  key: string;
  type: 'text';
  label: string;
  value: string;
}
