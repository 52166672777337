import { Injector } from '@angular/core';
import * as check from 'check-types';

import { ErrorManagerService } from '../services/error/error-manager.service';
import { ErrorCodes } from './error/error-codes';
import { OrgosError } from './error/orgos-error';
import { GenericModel, IPermissions } from './generic-model';

export class GenericSimpleModel extends GenericModel {
  private id: string;
  public data: any;

  constructor(injector: Injector, rawData: any, serviceClass: any, ownerId?: string, operationOptions?: any) {
    super(injector, rawData, serviceClass, ownerId, operationOptions);

    if (check.assigned(rawData) && check.object(rawData) && check.nonEmptyObject(rawData) && (check.not.assigned(rawData._id) || check.emptyString(rawData._id))) {
      const error = new OrgosError('POSSIBLE PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, GenericSimpleModel.name, 'constructor');
      error.message = 'rawData is assigned and not empty, but it does not have _id';
      this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);
    }

    if (check.assigned(rawData) && check.object(rawData) && check.nonEmptyObject(rawData) && check.assigned(rawData._id) && check.nonEmptyString(rawData._id)) {
      this.id = rawData._id;
      this.data = rawData;
    }
  }

  public setNewData(rawData: any): GenericSimpleModel {
    if (check.not.assigned(rawData) || check.not.object(rawData) || check.not.assigned(rawData._id) || check.not.equal(rawData._id, this.id)) {
      const error = new OrgosError('POSSIBLE PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, GenericSimpleModel.name, 'setNewData');
      error.message = 'rawData parameter was not fill, data was not changed';
      this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

      return this;
    }

    return new GenericSimpleModel(this.injector, rawData, this.serviceClass, this.ownerId);
  }

  public update(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.canEdit
        .then((canEdit) => {
          if (!canEdit) {
            const error = new OrgosError('UNAUTHORIZED', ErrorCodes.CLIENT_ERROR, GenericSimpleModel.name, 'update');
            error.message = 'No edit permission';
            reject(this.injector.get(ErrorManagerService).handleParsedErrorSilently(error));

            return;
          }

          this.data._id = this.id;
          resolve(this.service.updateById(this.id, this.data, this.operationOptions));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public delete(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.canDelete
        .then((canDelete) => {
          if (!canDelete) {
            const error = new OrgosError('UNAUTHORIZED', ErrorCodes.CLIENT_ERROR, GenericSimpleModel.name, 'delete');
            error.message = 'No delete permission';
            reject(this.injector.get(ErrorManagerService).handleParsedErrorSilently(error));
            return;
          }

          this.service
            .deleteById(this.id, this.operationOptions)
            .then(() => {
              this.data._id = null;
              this.data = null;
              const permissions: IPermissions = {
                create: false,
                read: false,
                edit: false,
                delete: false
              };
              this.setPermissions(permissions);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
