import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PolicyTypeType } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { HolidayDurationType } from '@app/cloud-features/time-off/services/time-off-request.service';
import { WorkTimeType } from '@app/cloud-features/time-off/services/time-off-type.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TimeOffCalendarController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/time-off-calendar`;

  constructor(private injector: Injector, private http: HttpClient) {}

  async getCalendarUserDayEvents(year: number): Promise<ICalendarUserDayEvents> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffCalendarController.name, 'getCalendarRequests');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).get<ICalendarUserDayEvents>(`${this.URL}/user-day-events/${year}`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffCalendarController.name, 'getCalendarRequests');
    }
  }
}

export interface ICalendarUserDayEvents {
  events: ICalendarEvents;
  timeOffTypes: Array<ICalendarTimeOffType>;
}
export interface ICalendarEvents {
  [userId: string]: ICalendarUserEvents;
}

export interface ICalendarUserEvents {
  [date: string]: Array<ICalendarEvent>;
}
export interface ICalendarEvent {
  id: string;
  start: moment.Moment;
  end: moment.Moment;
  pending: boolean;
  workTime: WorkTimeType;
  day: moment.Moment;
  dayDuration: HolidayDurationType;
  position: number;
  title?: string;
  color?: string;
  timeOffTypeId?: string;
  policyType?: PolicyTypeType;
}
export interface ICalendarTimeOffType {
  id: string;
  name: string;
  color: string;
  checked?: boolean;
}
