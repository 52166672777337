<orgos-container aspect="card" class="kenjo-p-20px kenjo-mb-20px" [style.display]="this.maxNumberOfGroups > this.MAX_GROUPS_IN_REPORT ? 'none' : 'block'">
  <canvas #chartElement class="rb-chart-container"></canvas>
</orgos-container>

<orgos-column-container class="kenjo-mb-20px" *ngIf="this.maxNumberOfGroups > this.MAX_GROUPS_IN_REPORT" centerColumns="true">
  <orgos-column></orgos-column>
  <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-success-00b72e kenjo-mr-5px">info_outline </kenjo-icon>
  <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-font-size-16px"> {{ pageTranslation.tooManyResultsStacked }}</orgos-column>
  <orgos-column></orgos-column>
</orgos-column-container>

<orgos-container aspect="card">
  <orgos-table [data]="stackedDataForDataSource" [displayedColumns]="displayedColumns" [stickyRow]="true" [stickyColumn]="true" [customHeightToSubstract]="90">
    <orgos-table-column *ngFor="let iField of displayedColumns; let first = first" [columnKey]="iField" [header]="first ? getGroupTranslation() : getTranslation(iField)" [sortable]="true">
      <ng-container *orgosTableCell="let row"> {{ row[iField] }}</ng-container>
    </orgos-table-column>
  </orgos-table>
</orgos-container>
