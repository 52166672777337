<mat-form-field *ngIf="!readOnly" [ngClass]="{ 'isnc-force-error': forceError }" >
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input matInput type="number" #input="matInput" [id]="id" [ngModel]="value" (ngModelChange)="onValueChange($event)"
    (blur)="onBlurEvent()" [required]="required" [min]="min" [max]="max" [step]="step" autocomplete="off"
    (keydown)="!keyboardOnly || (keyboardOnly && checkKey($event.key))" [placeholder]="placeholder"
    [noNegativeInput]="avoidNegativeSymbol" />
  <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput type="number" #input="matInput" [id]="id" [ngModel]="value" readOnly disabled [required]="required" />
  <div class="isnc-disabled" *ngIf="suffix" matSuffix>{{ suffix }}</div>
</mat-form-field>
<div *ngIf="!isValueValid" class="isnc-error"><ng-content select="orgos-input-error"></ng-content></div>