import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ITimeOffAssignmentModel } from '@app/cloud-features/time-off/services/time-off-status.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ICompanyModel } from '@app/standard/services/company/company.service';

@Component({
  selector: 'kenjo-add-employee-time-off-step',
  templateUrl: 'time-off-step.component.html',
  styleUrls: ['time-off-step.component.scss'],
})
export class AddEmployeeTimeOffStepComponent {
  @Input() translations: any = {};
  @Input() timeOffTypes: Array<any>;
  @Input() originalTimeOffTypes: Array<any>;
  @Input() companies: Array<ICompanyModel>;
  @Input() userWork: GenericCacheModel;
  @Input() userWorkSchedule: GenericCacheModel;
  @Input() workScheduleConfiguration: { workScheduleTypeSelected: 'Template' | 'Legacy'; workScheduleTemplateSelected: string };
  @Input() timeOffStepPermission: boolean = false;
  @Input() assignments: Array<ITimeOffAssignmentModel>;
  @Output() assignmentsChange: EventEmitter<Array<ITimeOffAssignmentModel>> = new EventEmitter<Array<ITimeOffAssignmentModel>>();

  @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() skipStep: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private injector: Injector) {}

  onPreviousStep() {
    this.previousStep.emit(true);
  }

  onNextStep() {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('timeoff next', { platform: 'Web', category: 'Employee', subcategory1: 'Creation wizard' });
    this.nextStep.emit(true);
  }

  onSkipStep() {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('timeoff skip', { platform: 'Web', category: 'Employee', subcategory1: 'Creation wizard' });
    this.skipStep.emit(true);
  }

  onAssignmentsChange(assignments) {
    this.assignmentsChange.emit(assignments);
  }
}
