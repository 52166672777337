<div class="spet-main-container">
  <div *ngIf="allTags?.length === 0" class="spet-no-tags-container">
    <div class="kenjo-font-size-14px kenjo-mt-30px kenjo-mb-30px kenjo-font-color-light-text-757575">{{ activateShiftPlanTranslation.noDataTags }}</div>
    <img class="spet-no-tag-image" src="/assets/images/emptyState.svg" />
  </div>
  <div *ngIf="allTags && allTags.length > 0">
    <!-- Title -->
    <div class="kenjo-font-size-16px kenjo-mb-10px kenjo-font-weight-bold">{{ activateShiftPlanTranslation.tagsStep }}</div>
    <div class="kenjo-font-size-14px kenjo-mb-30px kenjo-font-color-light-text-757575">{{ activateShiftPlanTranslation.tagsSubtitle }}</div>

    <kenjo-input-search-shift-plan id="spet-tags" [list]="categorySelectedData" [placeholder]="activateShiftPlanTranslation.searchTags" (filteredResults)="filteredTags = $event"></kenjo-input-search-shift-plan>

    <!-- Table -->
    <orgos-column-container class="spet-headers-container kenjo-border-left kenjo-border-top kenjo-border-right">
      <orgos-column size="1" class="kenjo-border-right spet-min-width-234px">
        <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-p-20px">{{ activateShiftPlanTranslation.categories | uppercase }}</div>
      </orgos-column>
      <orgos-column size="2">
        <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575 kenjo-p-20px">{{ activateShiftPlanTranslation.tagsStep | uppercase }}</div>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container class="spet-data-container kenjo-border spet-border-bottom-radius">
      <orgos-column size="1" class="kenjo-border-right spet-min-width-234px">
        <div *ngFor="let category of categories">
          <div class="kenjo-p-10px kenjo-border-bottom spet-non-selected kenjo-cursor-pointer" [class.spet-selected]="category.value === categorySelected" (click)="changeCategory(category.value)">{{ category.name }}</div>
        </div>
      </orgos-column>
      <orgos-column size="2">
        <orgos-column-container class="kenjo-ph-10px spet-tags-container">
          <orgos-column size="0" *ngFor="let item of filteredTags" class="spet-data kenjo-m-5px" [class.selected]="item.selected">
            <orgos-column-container [centerColumns]="true">
              <orgos-column size="0">
                <kenjo-simple-truncate-text class="spet-truncate-text">{{ item.name }}</kenjo-simple-truncate-text>
              </orgos-column>
              <kenjo-icon *ngIf="!item.selected" (click)="select(item)" class="kenjo-cursor-pointer" orgosColumn="0" [size]="16">add</kenjo-icon>
              <kenjo-icon *ngIf="item.selected" (click)="clear(item)" class="kenjo-cursor-pointer" orgosColumn="0" [size]="16">clear</kenjo-icon>
            </orgos-column-container>
          </orgos-column>
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
  </div>
</div>

<!-- Navigation buttons for creation-->
<div *ngIf="userWork.data.shiftPlanActivationStatus !== 'finished'" class="kenjo-text-align-right kenjo-mt-20px">
  <orgos-button-raised id="spet-previous-button" [color]="'Neutral'" class="kenjo-mr-20px" (click)="previousStep()">{{ activateShiftPlanTranslation.backButton }}</orgos-button-raised>
  <orgos-button-raised id="spet-next-button" [color]="'Success'" (click)="activateShiftPlan()">{{ activateShiftPlanTranslation.activate }}</orgos-button-raised>
</div>

<!-- Navigation buttons for edition-->
<div *ngIf="userWork.data.shiftPlanActivationStatus === 'finished'" class="kenjo-text-align-right kenjo-mt-20px">
  <orgos-button-raised id="spet-cancel-button" [color]="'Neutral'" class="kenjo-mr-20px" [disabled]="!dataHasChanged" (click)="cancelChanges(); dataHasChanged = false">{{ activateShiftPlanTranslation.cancelButton }}</orgos-button-raised>
  <orgos-button-raised id="spet-save-button" [disabled]="!dataHasChanged" [color]="'Success'" (click)="saveData(); saveSnackbar(); dataHasChanged = false">{{ activateShiftPlanTranslation.saveButton }} </orgos-button-raised>
</div>
