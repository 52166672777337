import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../../standard/services/error/error-manager.service';

@Injectable({
  providedIn: 'root'
})
export class PrivateHashService {
  private HASH_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/hash-db`;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  validateHash(options: IValidateHashOptions): Promise<IValidateHashResult> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post<IValidateHashResult>(`${this.HASH_URL}/validate`, options, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PrivateHashService.name, 'validateHash'));
        });
    });
  }

  // validates onboarding hash: a hash is valid if it is not past its duedate and the org's subscription is in_trial
  async validateOnboardingHash(options: IValidateHashOptions): Promise<IValidateHashResult> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders
      };
      const responseData = await this.http.post<IValidateHashResult>(`${this.HASH_URL}/validate/invite`, options, httpOptions).toPromise();
      return responseData;
    } catch (error: any) {
      this.errorManager.handleRawErrorSilently(error, PrivateHashService.name, 'validateOnboardingHash');
      throw error;
    }
  }

  invalidateHash(options: IInvalidateHashOptions, throwError = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(`${this.HASH_URL}/invalidate`, options, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (throwError) {
            reject(this.errorManager.handleRawError(error, PrivateHashService.name, 'invalidateHash'));
            return;
          }

          reject(this.errorManager.handleRawErrorSilently(error, PrivateHashService.name, 'invalidateHash'));
        });
    });
  }
}

export interface IInvalidateHashOptions {
  type: string;
  referenceId: string;
  secondaryReferenceId?: string;
}

export interface IValidateHashOptions {
  type?: string;
  hashValue: string;
  referenceId?: string;
}

export interface IValidateHashResult {
  referenceId: string;
  s_orgId: string;
}

export interface IValidateLinkInviteHashResult extends IValidateHashResult {
  language: string;
}