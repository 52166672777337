<div class="dialog-box">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <!-- TITLE -->
  <orgos-column-container centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-text type="Headline1">{{translation.dialogAddAgenda}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <!-- SUBTITLE -->
  <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-text *ngIf="wizardStep === 1" type="Headline4">{{translation.typeOfContentStep}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 2 && agendaItem.type === TYPE_TEXT" type="Headline4"> {{translation.textNameStep}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 2 && agendaItem.type === TYPE_LIST" type="Headline4"> {{translation.listNameStep}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 2 && agendaItem.type === TYPE_QUESTION" type="Headline4"> {{translation.questionNameStep}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 2 && agendaItem.type === TYPE_TODO" type="Headline4"> {{translation.taskNameStep}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <!-- CONTENT -->
  <div class="kenjo-ph-60px kenjo-pt-20px mdd-content">
    <!-- STEP 1 - AGENDA ITEM TYPE -->
    <orgos-column-container *ngIf="wizardStep === 1" centerColumns="true" class="kenjo-pt-40px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-column-container centerColumns="true" class="kenjo-pt-20px" *ngIf="picklistTranslation.agendaType">
          <orgos-column size="0" class="mdd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div class="mdd-option" [class.selected-option]="agendaItem.type === TYPE_TEXT" (click)="agendaItem.type = TYPE_TEXT">
              <div class="mdd-option-icon">
                <mat-icon>text</mat-icon>
              </div>
            </div>
            <div class="kenjo-pv-20px mdd-option-text">
              <orgos-text [color]="agendaItem.type === TYPE_TEXT? 'Primary': 'NormalText'"> {{picklistTranslation.agendaType[TYPE_TEXT] | uppercase}}</orgos-text>
            </div>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="mdd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div class="mdd-option" [class.selected-option]="agendaItem.type === TYPE_LIST" (click)="agendaItem.type = TYPE_LIST">
              <div class="mdd-option-icon">
                <mat-icon>list</mat-icon>
              </div>
            </div>
            <div class="kenjo-pv-20px mdd-option-text">
              <orgos-text [color]="agendaItem.type === TYPE_LIST? 'Primary': 'NormalText'"> {{picklistTranslation.agendaType[TYPE_LIST] | uppercase}}</orgos-text>
            </div>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="mdd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div class="mdd-option" [class.selected-option]="agendaItem.type === TYPE_TODO" (click)="agendaItem.type = TYPE_TODO">
              <div class="mdd-option-icon">
                <mat-icon>task_menu</mat-icon>
              </div>
            </div>
            <div class="kenjo-pv-20px mdd-option-text">
              <orgos-text [color]="agendaItem.type === TYPE_TODO? 'Primary': 'NormalText'"> {{picklistTranslation.agendaType[TYPE_TODO] | uppercase}}</orgos-text>
            </div>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="mdd-option-container kenjo-ph-40px kenjo-pv-20px">
            <div class="mdd-option" [class.selected-option]="agendaItem.type === TYPE_QUESTION" (click)="agendaItem.type = TYPE_QUESTION">
              <div class="mdd-option-icon">
                <mat-icon>question_icon</mat-icon>
              </div>
            </div>
            <div class="kenjo-pv-20px mdd-option-text">
              <orgos-text [color]="agendaItem.type === TYPE_QUESTION? 'Primary': 'NormalText'"> {{picklistTranslation.agendaType[TYPE_QUESTION] | uppercase}}</orgos-text>
            </div>
          </orgos-column>
        </orgos-column-container>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <!-- STEP 2 - TEXT -->
    <orgos-container *ngIf="wizardStep === 2 && agendaItem.type === TYPE_TEXT" class="kenjo-pt-40px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1"></orgos-column>
        <orgos-column size="0">
          <orgos-column-container>
            <orgos-column size="0" class="mdd-one-field">
              <orgos-input-simple-text [label]="translation.textName" class="mdd-one-field" required="true" [(value)]="agendaItem.name" (validation)="titleValidation = $event">
                <orgos-input-error class="mdd-one-field" *ngIf="titleValidation && titleValidation.getError('required')">{{translation.nameMandatory}} </orgos-input-error>
              </orgos-input-simple-text>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-20px">
            <orgos-column size="0">
              <mat-slide-toggle labelPosition="before" [(checked)]="agendaItem.participantCanAdd" (change)="setParticipantCanAdd($event.checked)">{{translation.textParticipantsCanAdd}} </mat-slide-toggle>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>
    </orgos-container>
    <!-- STEP 2 - LIST -->
    <orgos-container *ngIf="wizardStep === 2 && agendaItem.type === TYPE_LIST" class="kenjo-pt-40px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1"></orgos-column>
        <orgos-column size="0">
          <orgos-column-container>
            <orgos-column size="0" class="mdd-one-field">
              <orgos-input-simple-text [label]="translation.listName" class="mdd-one-field" required="true" [(value)]="agendaItem.name" (validation)="titleValidation = $event">
                <orgos-input-error class="mdd-one-field" *ngIf="titleValidation && titleValidation.getError('required')">{{translation.nameMandatory}} </orgos-input-error>
              </orgos-input-simple-text>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-20px">
            <orgos-column size="0">
              <mat-slide-toggle labelPosition="before" [(checked)]="agendaItem.participantCanAdd" (change)="setParticipantCanAdd($event.checked)">{{translation.listParticipantsCanAdd}} </mat-slide-toggle>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>
    </orgos-container>
    <!-- STEP 2 - TODO -->
    <orgos-container *ngIf="wizardStep === 2 && agendaItem.type === TYPE_TODO" class="kenjo-pt-40px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1"></orgos-column>
        <orgos-column size="0">
          <orgos-column-container>
            <orgos-column size="0" class="mdd-one-field">
              <orgos-input-simple-text [label]="translation.taskName" class="mdd-one-field" required="true" [(value)]="agendaItem.name" (validation)="titleValidation = $event">
                <orgos-input-error class="mdd-one-field" *ngIf="titleValidation && titleValidation.getError('required')">{{translation.nameMandatory}} </orgos-input-error>
              </orgos-input-simple-text>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-20px">
            <orgos-column size="0">
              <mat-slide-toggle labelPosition="before" [(checked)]="agendaItem.participantCanAdd" (change)="setParticipantCanAdd($event.checked)">{{translation.taskParticipantsCanAdd}} </mat-slide-toggle>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>
    </orgos-container>
    <!-- STEP 2 - QUESTION -->
    <orgos-container *ngIf="wizardStep === 2 && agendaItem.type === TYPE_QUESTION" class="kenjo-pt-20px">
      <orgos-column-container centerColumns="true">
        <orgos-column>
          <orgos-column-container>
            <orgos-column size="0" class="mdd-one-field">
              <orgos-input-simple-text [label]="translation.question" class="mdd-one-field" required="true" [(value)]="agendaItem.name" (validation)="titleValidation = $event">
                <orgos-input-error class="mdd-one-field" *ngIf="titleValidation && titleValidation.getError('required')">{{translation.questionMandatory}} </orgos-input-error>
              </orgos-input-simple-text>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-20px">
            <orgos-column size="1">
              <orgos-input-simple-radio name="visibility" [options]="visibilityOptions" [(value)]="questionIsPublic" [label]="translation.visibility"></orgos-input-simple-radio>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-10px">
            <orgos-column size="1">
              <orgos-input-simple-radio name="anonymity" [options]="anonymityOptions" [(value)]="questionIsAnonymous" [label]="translation.anonymity"></orgos-input-simple-radio>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
      </orgos-column-container>
    </orgos-container>
  </div>
  <!-- BUTTONS -->
  <orgos-column-container centerColumns="true" class="kenjo-pv-10px kenjo-mh-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="mdd-button-align">
          <div>
            <mat-icon class="mdd-cancel-button" (click)="leftButtonAction()"> {{wizardStep > 1 ? 'arrow_back_circle' : 'cancel_icon'}}</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText" *ngIf="wizardStep === 1">{{translation.dialogCancelBtn}}</orgos-text>
          </div>
          <div>
            <orgos-text type="SecondaryText" *ngIf="wizardStep > 1">{{translation.dialogBackBtn}}</orgos-text>
          </div>
        </orgos-column>
        <orgos-column size="1" class="mdd-button-align">
          <div>
            <mat-icon class="mdd-create-button" (click)="rightButtonAction()" [class.mdd-disabled-button]="!isValidFormData()">arrow_next_circle</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.mdd-disabled-button]="!isValidFormData()" *ngIf="wizardStep === 1"> {{translation.dialogNextBtn}}</orgos-text>
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.mdd-disabled-button]="!isValidFormData()" *ngIf="wizardStep > 1"> {{translation.dialogSaveBtn}}</orgos-text>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
