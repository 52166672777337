<div class="dialog-box">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <div class="kenjo-mh-60px">
    <orgos-column-container centerColumns="true">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-text type="Headline1">{{translation.saveTemplate}}</orgos-text>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-text type="Headline4">{{translation.dialogTemplateName}}</orgos-text>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
  </div>
  <orgos-column-container centerColumns="true" class="kenjo-mt-80px" *ngIf="template">
    <orgos-column size="1"></orgos-column>
    <orgos-input-simple-text class="kenjo-mt-40px" orgosColumn [label]="translation.templateName" [(value)]="template.name" (validation)="nameValidation = $event" required="true">
      <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">{{translation.nameMandatory}} </orgos-input-error>
    </orgos-input-simple-text>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <orgos-column-container centerColumns="true" class="ctd-warning-container">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" *ngIf="template && template.name && templates[template.name]" class="kenjo-pl-40px">
      <orgos-column-container centerColumns="true">
        <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
        <orgos-column class="kenjo-ml-10px kenjo-font-color-light-text-757575">{{translation.templateSameNameWarning}} </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <orgos-column-container centerColumns="true" class="kenjo-mt-80px kenjo-mh-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true" class="kenjo-mt-80px">
        <orgos-column size="1" class="ctd-button-align">
          <div>
            <mat-icon class="ctd-cancel-button" (click)="closeDialog()">cancel_icon</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText">{{translation.dialogCancelBtn}}</orgos-text>
          </div>
        </orgos-column>
        <orgos-column size="1" class="ctd-button-align">
          <div>
            <mat-icon class="ctd-create-button" (click)="saveView()" [class.ctd-disabled-button]="isSaving === true || (nameValidation && nameValidation.getError('required'))"> arrow_next_circle</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.ctd-disabled-button]="isSaving === true || (nameValidation && nameValidation.getError('required'))"> {{translation.dialogSaveBtn}}</orgos-text>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
