import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as check from 'check-types';
import { Subscription } from 'rxjs/internal/Subscription';

import { IUserAccountModel } from '../../models/user-account.model';
import { PrivateSecurityService } from '../../private/services/private-security.service';
import { AuthenticationService } from '../services/core/authentication.service';
import { GlobalBarService, IMenuOption } from '../services/core/global-bar.service';
import { InternationalizationService } from '../services/core/internationalization.service';

export { IMenuOption } from '../services/core/global-bar.service';

@Component({
  selector: 'orgos-generic-page',
  template: ''
})
export abstract class GenericPage implements OnInit, OnDestroy {
  private _loadingPage: boolean = true;
  private setLoadingPage(loadingPage: boolean) {
    this._loadingPage = loadingPage;
    this.injector.get(GlobalBarService).setProgressBar(loadingPage);
  }
  get loadingPage(): boolean {
    return this._loadingPage;
  }

  private _errorOnFetchingTranslations: boolean = false;
  private setErrorOnFetchingTranslations(errorOnFetchingTranslations: boolean): void {
    this._errorOnFetchingTranslations = errorOnFetchingTranslations;
    this.onFetchingTranslationsError();
  }
  get errorOnFetchingTranslations(): boolean {
    return this._errorOnFetchingTranslations;
  }

  private _errorOnFetchingData: boolean = false;
  private setErrorOnFetchingData(errorOnFetchingData: boolean): void {
    this._errorOnFetchingData = errorOnFetchingData;
    this.onFetchingDataError();
  }
  get errorOnFetchingData(): boolean {
    return this._errorOnFetchingData;
  }

  private _pageDestroyed: boolean = false;
  get pageDestroyed(): boolean {
    return this._pageDestroyed;
  }

  // Subcomponents MUST override this attribute in order to change the global bar configuration.
  protected globalBarConfig: IGlobalBarConfig = {
    pageName: '',
    enableFullScreenMode: false,
    fullScreenModeUseSameUrl: true,
    secondaryMenuOptions: [],
    selectedSecondaryMenuOption: 0
  };

  // Subcomponents MUST override this attribute in order to retrieve the correct translations and populate i18n attribute.
  protected translationResources: Array<ITranslationResource> = [];

  private _i18n: any = {};
  get i18n(): any {
    return this._i18n;
  }

  // Subcomponents MUST override this attribute in order to retrieve the wished permissions and populate profilePermissions attribute.
  protected profilePermissionsResources: Array<string> = [];

  private _profilePermissions: any = {};
  get profilePermissions(): any {
    return this._profilePermissions;
  }

  private exitFullScreenSubscription: Subscription;

  constructor(protected injector: Injector, protected cdr: ChangeDetectorRef, protected router: Router, protected route: ActivatedRoute, protected location: Location) {}

  /*
   * Subcomponents must NOT override ngOnInit.
   * Subcomponents must NOT invoke this method by themselves;
   */
  ngOnInit(): void {
    this._pageDestroyed = false;
    this.setLoadingPage(true);

    this.beforeInit()
      .then(() => {
        this.setScreenMode();

        return Promise.all([this.fetchTranslationsGeneric(), this.fetchProfilePermissions()]);
      })
      .then(() => {
        return this.fetchDataGeneric();
      })
      .then(() => {
        return this.configureGlobalBarGeneric();
      })
      .then(() => {
        this.fetchLazyData();
        return this.afterInit();
      })
      .then(() => {
        // Everything was initialized.
      })
      .catch(() => {
        // TODO: ??
      });
  }

  /*
   * Subcomponents must NOT override ngOnDestroy.
   * Subcomponents must NOT invoke this method by themselves;
   */
  ngOnDestroy(): void {
    if (this.exitFullScreenSubscription) {
      this.exitFullScreenSubscription.unsubscribe();
    }

    this.destroy()
      .then(() => {
        this._pageDestroyed = true;
      })
      .catch(() => {
        this._pageDestroyed = true;
      });
  }

  /*
   * Subcomponents can override beforeInit in order to:
   *   - Make any initialization that should be done in the constructor or ngOnInit
   *   - Subscribe to route changes
   *   - Enable full screen mode (globalBarConfig.enableFullScreenMode = true).
   *   - Configure full screen mode url (globalBarConfig.fullScreenModeUseSameUrl = true | false)
   */
  protected beforeInit(): Promise<void> {
    return Promise.resolve();
  }

  /*
   * Subcomponents can override afterInit in order to:
   *   - Make any operation when translations, data and global bar has been configured
   *
   * NOTE: afterInit() and fetchLazyData() are executed in parallel.
   */
  protected afterInit(): Promise<void> {
    return Promise.resolve();
  }

  private setScreenMode(): void {
    if (this.globalBarConfig.enableFullScreenMode === false) {
      this.injector.get(GlobalBarService).setEnableDefaultBars(true);
      return;
    }

    this.exitFullScreenSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.exitFullScreenModeGeneric(false);
      }
    }) as Subscription;

    this.injector.get(GlobalBarService).setEnableDefaultBars(false, this.globalBarConfig.fullScreenModeUseSameUrl);
  }

  /*
   * Subcomponents must NOT override refreshScreenMode.
   * However, subcomponents can invoke this method to refresh the screen mode.
   */
  refreshScreenMode(): void {
    if (this.loadingPage === false) {
      this.setScreenMode();
    }
  }

  /*
   * Subcomponents must NOT override refreshTranslations.
   * However, subcomponents can invoke this method to refresh translations.
   */
  refreshTranslations(): Promise<void> {
    if (this.loadingPage === false) {
      return this.fetchTranslationsGeneric();
    }
  }

  /*
   * Subcomponents cannot override this method
   */
  private fetchTranslationsGeneric(): Promise<void> {
    this.setErrorOnFetchingTranslations(false);

    const translationPromises = this.translationResources.map((iTranslationResource: ITranslationResource) => {
      return new Promise<void>((resolve, reject) => {
        this.injector
          .get(InternationalizationService)
          .getAllTranslation(iTranslationResource.translationKey)
          .then((translation: any) => {
            this._i18n[iTranslationResource.name] = translation;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    return new Promise<void>((resolve, reject) => {
      Promise.all(translationPromises)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          this.setErrorOnFetchingTranslations(true);
          reject(error);
        });
    });
  }

  /*
   * Subcomponents must NOT override refreshProfilePermissions.
   * However, subcomponents can invoke this method to refresh profile permissions.
   */
  refreshProfilePermissions(): Promise<void> {
    if (this.loadingPage === false) {
      return this.fetchProfilePermissions();
    }
  }

  /*
   * Subcomponents cannot override this method
   */
  private fetchProfilePermissions(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (check.not.assigned(this.profilePermissionsResources)) {
        resolve();
      }

      this.injector
        .get(PrivateSecurityService)
        .getPermissionsForCollections(this.profilePermissionsResources)
        .then((permissions: any) => {
          this._profilePermissions = permissions;
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /*
   * Subcomponents must NOT override refreshData
   * However, subcomponents can invoke this method to refresh their data.
   */
  refreshData(setLoading = false): Promise<void> {
    if (this.loadingPage === false) {
      if (setLoading) {
        this.setLoadingPage(true);
      }
      return this.fetchDataGeneric();
    }

    return Promise.resolve();
  }

  /*
   * Subcomponents cannot override this method
   */
  private fetchDataGeneric(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.pageDestroyed === true) {
        resolve();
        return;
      }

      this.setErrorOnFetchingData(false);

      const fetchDataResolve: Function = (): Promise<void> => {
        if (this.pageDestroyed === true) {
          resolve();
          return;
        }

        this.setLoadingPage(false);
        resolve();
      };

      const fetchDataReject: Function = (error?: any): Promise<void> => {
        this.setLoadingPage(false);
        this.setErrorOnFetchingData(true);
        reject(error);

        return;
      };

      this.fetchData(fetchDataResolve, fetchDataReject);
    });
  }

  /*
   * Subcomponents MUST override fetchData in order to retrieve the necessary data for the page.
   */
  protected abstract fetchData(resolveFetchData: Function, rejectFetchData: Function): void;

  /*
   * Subcomponents must NOT override refreshLazyData
   * However, subcomponents can invoke this method to refresh their lazy data.
   */
  refreshLazyData(): void {
    if (this.loadingPage === false) {
      this.fetchLazyData();
    }
  }

  /*
   * Subcomponents can override fetchLazyData in order to retrieve the lazy data for the page.
   *
   * * NOTE: afterInit() and fetchLazyData() are executed in parallel during initialization.
   */
  protected fetchLazyData(): void {}

  /*
   * Subcomponents must NOT override refreshGlobalBar
   * However, subcomponents can invoke this method to refresh the global bar with the current configuration.
   */
  refreshGlobalBar(): Promise<void> {
    if (this.loadingPage === false) {
      return this.configureGlobalBarGeneric();
    }
  }

  /*
   * Subcomponents cannot override this method
   */
  private configureGlobalBarGeneric(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.pageDestroyed === true) {
        resolve();
        return;
      }

      this.configureGlobalBar()
        .then(() => {
          if (this.pageDestroyed === true) {
            resolve();
            return;
          }

          this.injector.get(GlobalBarService).setPageName(this.globalBarConfig.pageName);
          this.injector.get(GlobalBarService).setSecondaryMenuOptions(this.globalBarConfig.secondaryMenuOptions);
          this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(this.globalBarConfig.selectedSecondaryMenuOption);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /*
   * Subcomponents can override configureGlobalBar with the purpose of changing globalBarConfig attribute.
   * This method should EXCLUSIVELY change the globalBarConfig attribute.
   *
   * This method will be executed:
   *   1. When the component is initialized for the first time.
   *   2. When the refreshGlobalBar is invoked.
   */
  protected configureGlobalBar(): Promise<void> {
    return Promise.resolve();
  }

  /*
   * Subcomponents can override destroy in order to:
   *   - Make any finalization that should be done in the ngOnDestroy
   *   - Unsubscribe from any active subscription
   */
  protected destroy(): Promise<void> {
    return Promise.resolve();
  }

  /*
   * Subcomponents can override onFetchingTranslationsError in order to change their behavior
   * if the translations could not be fetched. This method is invoked if the translations could not be retrieved.
   */
  protected onFetchingTranslationsError(): void {}

  /*
   * Subcomponents can override onFetchingDataError in order to change their behavior
   * if the data could not be fetched. This method is invoked if the data could not be retrieved.
   */
  protected onFetchingDataError(): void {}

  /*
   * Subcomponents must NOT override exitFullScreenPage
   * However, subcomponents can invoke this method to exit from full screen mode.
   */
  exitFullScreenMode(): void {
    this.exitFullScreenModeGeneric(true);
  }

  /*
   * Subcomponents cannot override this method
   */
  private exitFullScreenModeGeneric(changeLocationState: boolean): void {
    this.onExitFullScreenMode()
      .then((isOk) => {
        if (this.exitFullScreenSubscription) {
          this.exitFullScreenSubscription.unsubscribe();
        }

        if (isOk) {
          this.injector.get(GlobalBarService).setEnableDefaultBars(true, changeLocationState);
        }
      })
      .catch(() => {});
  }

  /*
   * Subcomponents can override onExitFullScreenMode if extra actions must be done when
   * the user clicks on back button.
   */
  protected onExitFullScreenMode(): Promise<boolean> {
    return Promise.resolve(true);
  }

  /*
   * Subcomponents must NOT override getLoggedUser
   * However, subcomponents can invoke this method to get the current logged user.
   */
  getLoggedUser(): IUserAccountModel {
    return this.injector.get(AuthenticationService).getLoggedUser();
  }
}

export interface ITranslationResource {
  name: string;
  translationKey: string;
}

export interface IGlobalBarConfig {
  pageName: string;
  enableFullScreenMode: boolean;
  fullScreenModeUseSameUrl: boolean;
  secondaryMenuOptions: Array<IMenuOption>;
  selectedSecondaryMenuOption: number;
}
