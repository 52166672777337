import { Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { IMyTeamWidgetSettingsModel } from '@app/cloud-features/settings-my-team/models/my-team-widget-settings.model';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import { ProfileService } from '@app/standard/services/profile/profile.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import {
  MY_TEAM_TRANSLATION_KEY_FAVORITES,
  MY_TEAM_TRANSLATION_KEY_SUBORDINATES,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-who-is-away-widget-details',
  templateUrl: 'who-is-away-widget-details.dialog.html',
  styleUrls: ['who-is-away-widget-details.dialog.scss'],
})
export class WhoIsAwayWidgetDetailsDialog implements OnInit {
  isAdmin: boolean;
  loggedUserId: string;
  allUsers: any;
  selectableUsers: any;
  favoriteUsers: Array<any>;
  myTeamMembers: Array<any>;
  MY_TEAM_TRANSLATION_KEY_SUBORDINATES: string = MY_TEAM_TRANSLATION_KEY_SUBORDINATES;
  isFavoritesSectionActive: boolean = false;
  isPageLoaded: boolean = false;
  showDefaultConfiguration: boolean = false;
  pageTranslation: any = {};
  configurationKeys: any;
  myTeamWidgetSetting: IMyTeamWidgetSettingsModel;

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  constructor(
    public dialogRef: MatLegacyDialogRef<WhoIsAwayWidgetDetailsDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData() {
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('who-is-away-widget-details-dialog');

      const profile = await this.injector.get(ProfileService).getMine();
      this.isAdmin = profile._isAdmin;
      this.loggedUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;

      this.configurationKeys = this.data.configurationKeys;
      this.myTeamWidgetSetting = this.data.myTeamWidgetSetting;
      this.isFavoritesSectionActive = this.data.configurationKeys?.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES);

      await this.processSelectedUsersAsFavorites();
      this.isPageLoaded = true;
    } catch (error) {
      this.pageTranslation = {};
    }
  }

  searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(this.favoriteUsers)) {
      return Promise.resolve([]);
    }

    const favoriteUserIds = this.favoriteUsers.map((iUserId) => iUserId._id);
    this.selectableUsers = this.allUsers.filter((iUser) => favoriteUserIds.includes(iUser._id) === false);

    if (check.not.assigned(value) || check.emptyString(value)) {
      return Promise.resolve(this.favoriteUsers);
    }

    let results = this.selectableUsers.filter((iUser: any) => {
      if (check.not.assigned(iUser)) {
        return false;
      }

      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return regExp.test(iUser.displayName);
    });

    results = _.orderBy(results, ['displayName'], ['asc']);

    return Promise.resolve(results);
  };

  private async processSelectedUsersAsFavorites() {
    if (this.isFavoritesSectionActive) {
      const [allUsers, favoriteUsers] = await Promise.all([
        this.injector.get(UserPersonalService).getAllUserPersonal(true, false),
        this.injector.get(PreferenceService).getPreferenceByKey('favorites-who-is-away-widget'),
      ]);
      this.allUsers = allUsers.filter((iUser: any) => iUser._id !== this.loggedUserId);
      if (check.assigned(favoriteUsers?.preference?.favoritesList)) {
        this.favoriteUsers = this.allUsers.filter((iUser) => favoriteUsers?.preference?.favoritesList.includes(iUser._id));
      } else {
        this.favoriteUsers = [];
      }
      this.selectableUsers = this.allUsers.filter((iUser) => this.favoriteUsers.includes(iUser._id) === false);
      return;
    }

    this.favoriteUsers = [];
    this.selectableUsers = [];
  }

  addFavoriteUser(userId: string) {
    const newFavoriteUserIndex = this.allUsers.findIndex((iUser) => iUser._id === userId);
    this.favoriteUsers.push(this.allUsers[newFavoriteUserIndex]);
    this.searchComponent.clearSearch();
  }

  deleteFavoriteUser(userId: string): Function {
    return () => {
      const favoriteUserToDeleteIndex = this.favoriteUsers.findIndex((iUser) => iUser._id === userId);
      this.favoriteUsers.splice(favoriteUserToDeleteIndex, 1);
    };
  }

  goToSettings() {
    this.injector.get(Router).navigateByUrl(`/cloud/settings/whoAway`);
    this.dialogRef.close(false);
  }

  async saveAndCloseFavoritesSection() {
    if (this.isFavoritesSectionActive === false) {
      this.closeDialog(false);
      return;
    }

    const favoritesList = this.favoriteUsers.map((iFavUser) => iFavUser._id);
    const preference = {
      favoritesList: favoritesList,
    };
    await this.injector.get(PreferenceService).setPreferenceByKey('favorites-who-is-away-widget', preference);
    this.closeDialog(true);
  }

  closeDialog(updated: boolean) {
    this.dialogRef.close(updated);
  }
}
