<orgos-container aspect="card">
  <div class="kenjo-pv-10px kenjo-ph-20px kenjo-font-size-16px ecpc-header">{{ popupTranslation.title }}</div>
  <div class="kenjo-p-20px ecpc-container">
    <div *ngIf="!calendars || calendars.length == 0">{{ popupTranslation.noCalendarsMessage }}</div>
    <div *ngIf="calendars && calendars.length > 0" class="ecpc-content">
      <orgos-input-simple-radio [options]="calendars" [(value)]="selectedCalendarUrl"></orgos-input-simple-radio>
    </div>
  </div>
  <orgos-column-container *ngIf="calendars && calendars.length > 0 && selectedCalendarUrl" class="ecpc-footer kenjo-pt-10px kenjo-ph-20px" centerColumns="true">
    <orgos-column>
      <orgos-input-simple-text [label]="popupTranslation.exportUrl" [value]="selectedCalendarUrl" [readOnly]="true" class="kenjo-block"></orgos-input-simple-text>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-10px">
      <mat-icon class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3" (click)="copyUrl()">files_empty</mat-icon>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
