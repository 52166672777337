import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { ATTENDANCE_CONFLICT_LIST } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root',
})
export class AttendanceConflictsService {
  private URL = `${environment.PEOPLE_CLOUD_APP_URL}/controller/attendance/attendance-conflicts`;
  private SERVICE_NAME = {
    ATTENDANCE_CONFLICTS: 'AttendanceConflictsService',
  };
  constructor(private injector: Injector) {}

  async find(): Promise<IAttendanceConflict[]> {
    try {
      const httpOptions = this.getHttpOptions();

      return await this.injector.get(HttpClient).post<IAttendanceConflict[]>(`${this.URL}`, {}, httpOptions).toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, this.SERVICE_NAME.ATTENDANCE_CONFLICTS, 'find');
    }
  }

  async findByMonth(date: Date, userId: string): Promise<IAttendanceConflict[]> {
    try {
      const httpOptions = this.getHttpOptions();
      return await this.injector
        .get(HttpClient)
        .post<IAttendanceConflict[]>(
          `${this.URL}/monthly-conflicts`,
          {
            _date: date,
            _userId: userId,
          },
          httpOptions
        )
        .toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, this.SERVICE_NAME.ATTENDANCE_CONFLICTS, 'findByMonth');
    }
  }

  async findByDay(date: Date, userId: string): Promise<IAttendanceConflict[]> {
    try {
      const httpOptions = this.getHttpOptions();
      return await this.injector
        .get(HttpClient)
        .post<IAttendanceConflict[]>(
          `${this.URL}/daily-conflicts`,
          {
            _date: date,
            _userId: userId,
          },
          httpOptions
        )
        .toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, this.SERVICE_NAME.ATTENDANCE_CONFLICTS, 'findByDay');
    }
  }

  async findByUserIds(userIds: string[]): Promise<IAttendanceConflict[]> {
    try {
      const httpOptions = this.getHttpOptions();
      return await this.injector
        .get(HttpClient)
        .post<IAttendanceConflict[]>(
          `${this.URL}/users-conflicts`,
          {
            userIds,
          },
          httpOptions
        )
        .toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, this.SERVICE_NAME.ATTENDANCE_CONFLICTS, 'findByUserIds');
    }
  }

  async findByAttendancePolicyId(attendancePolicyId: string): Promise<IAttendanceConflict[]> {
    try {
      const httpOptions = this.getHttpOptions();
      return await this.injector
        .get(HttpClient)
        .get<IAttendanceConflict[]>(`${this.URL}/users-conflicts/attendance-policies/${attendancePolicyId}`, httpOptions)
        .toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, this.SERVICE_NAME.ATTENDANCE_CONFLICTS, 'findByAttendancePolicyId');
    }
  }

  async ignoreConflicts(conflicts: IAttendanceConflict[], userAttendanceIds: string[], platform: string = 'attendance-tab'): Promise<[]> {
    try {
      const httpOptions = this.getHttpOptions();
      return await this.injector
        .get(HttpClient)
        .put<[]>(`${this.URL}/ignore-conflicts`, { conflicts, userAttendanceIds, platform }, httpOptions)
        .toPromise();
    } catch (error) {
      this.injector.get(ErrorManagerService).handleRawError(error, this.SERVICE_NAME.ATTENDANCE_CONFLICTS, 'ignoreConflicts');
    }
  }

  filterByDateAndType(attendanceConflicts: IAttendanceConflict[], date: string, type: ATTENDANCE_CONFLICT_LIST): IAttendanceConflict {
    const mapAttendanceConflicts = attendanceConflicts?.reduce((map, iAttendanceConflicts) => {
      if (iAttendanceConflicts._type === type) {
        map[iAttendanceConflicts._date.toString()] = iAttendanceConflicts;
      }
      return map;
    }, {});
    return mapAttendanceConflicts[date];
  }

  filterByDate(attendanceConflicts: IAttendanceConflict[], date: string): IAttendanceConflict[] {
    if (!attendanceConflicts?.length) {
      return [];
    }
    const mapAttendanceConflicts = attendanceConflicts?.reduce((map, iAttendanceConflicts) => {
      if (check.not.assigned(map[iAttendanceConflicts._date.toString()])) {
        map[iAttendanceConflicts._date.toString()] = [];
      }
      map[iAttendanceConflicts._date.toString()].push(iAttendanceConflicts);
      return map;
    }, {});
    return mapAttendanceConflicts[date] ?? [];
  }

  filterByType(attendanceConflicts: IAttendanceConflict[], type: ATTENDANCE_CONFLICT_LIST): boolean {
    if (attendanceConflicts?.length) {
      return attendanceConflicts.some((iConflict) => iConflict._type === type);
    }
    // If attendanceConflicts is not an array or is undefined, return false

    return false;
  }

  getHttpOptions() {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    return {
      headers: httpHeaders,
    };
  }
}

export interface IAttendanceConflict {
  _id: string;
  isResolved: boolean;
  _userId: string;
  _date: Date | string;
  s_orgId: string;
  _type: string;
  resolvedAt?: Date;
  resolvedBy?: string;
  configuration?: {
    isPastValue: boolean;
    value?: number;
  };
}

export interface IConflictsDialog {
  maxDailyHours: boolean;
  minBreakTime: boolean;
  overlappingPublicHolidays: boolean;
  overlappingNonWorkingDay: boolean;
  overlappingConflict: boolean;
  missingFullEntry: boolean;
  entryNotCompleted: boolean;
  missingTime: boolean;
  hasConflicts: boolean;
  menuOpened: boolean;
  text?: any;
  configuration?: {
    maxHoursValue: number;
    minBreakValue: number;
    isMaxHoursPastValue: boolean;
    isMinBreakPastValue: boolean;
    isPublicHolidayPastValue: boolean;
    isNonWorkingDayPastValue: boolean;
  };
}
