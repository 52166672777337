import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ITask } from '@app/models/task.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { TasksAbstractPage } from '@app/standard/pages/tasks/tasks-abstract.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { TaskHelperService } from '@app/standard/services/task/task-helper.service';

@Component({
  selector: 'orgos-tasks-assigned',
  templateUrl: 'tasks-assigned.page.html',
  styleUrls: ['tasks-assigned.page.scss']
})
export class TasksAssignedPage extends TasksAbstractPage implements OnInit {
  pageFindQuery: any;

  constructor(protected router: Router, protected injector: Injector) {
    super(router, injector);
  }

  ngOnInit(): void {
    this.tabOrder = 1;
    this.tabKey = 'assigned';
    this.groupFields = ['_createdById', 'relatedTo', 'workflowName'];
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.pageFindQuery = { _createdById: loggedUser._id, ownerId: { $ne: loggedUser._id } };

    this.injector.get(TaskHelperService).refreshData();
    this.fetchData();
    this.injector.get(PrivateAmplitudeService).logEvent('view to-dos page', { type: 'assigned to-dos' });
  }

  protected async loadTasks(): Promise<void> {
    try {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      const tasks: Array<ITask> = await this.applyFilters();
      await Promise.all([this.handlePermissions(loggedUser), this.handleTasks(tasks, loggedUser)]);
    } catch {
      this.listTasks = [];
    }
  }
}