<orgos-container aspect="card" class="kenjo-p-40px" *ngIf="!loadingPage && organization">
  <orgos-column-container class="kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8 kenjo-mb-40px kenjo-p-20px" id="clear-dummy-container" centerColumns="true" *ngIf="containsDummyData && currentUser.profileKey === 'admin'">
    <orgos-column size="0">
      <img src="/assets/images/dummyData.svg" class="sop-dummy-data-image" />
    </orgos-column>
    <orgos-column size="1" class="kenjo-ml-20px">
      <div class="kenjo-font-size-18px">{{i18n.page.deleteDummyTitle}}</div>
      <div class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{i18n.page.deleteDummyMessage}}</div>
      <div class="kenjo-text-align-right kenjo-mt-30px">
        <orgos-button-raised color="Neutral" class="kenjo-mr-15px" (click)="deleteDummyData()" id="clear-dummy-button"> {{ i18n.page.deleteDummyButton }}</orgos-button-raised>
        <orgos-button-raised (click)="navigateToDataImport()" id="import-employee-data-button">{{ i18n.page.importEmployeeDataButton }}</orgos-button-raised>
      </div>
    </orgos-column>
  </orgos-column-container>
  <div class="kenjo-flex-horizontal kenjo-flex-gap-30px">
    <div class="kenjo-flex-item-equal-size kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-20px">
      <div centerColumns="true" class="kenjo-mb-30px">
        <div (click)="showPicker()" class="sop-logo-container">
          <div class="sop-camera-icon">
            <mat-icon>photo_camera</mat-icon>
          </div>
          <div class="sop-logo"><img *ngIf="organization.logo?._url" [src]="organization.logo?._url" /></div>
        </div>
        <div class="sop-logo" *ngIf="!organization.logo?._url">
          <button mat-fab (click)="showPicker()" class="sop-avatar-button">
            <mat-icon>photo_camera</mat-icon>
          </button>
          <div class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{i18n.page.addYourCompanyPictureText}}</div>
        </div>
      </div>
      <orgos-input-simple-text class="kenjo-block" required="true" [readOnly]="profilePermissions['settings-overview'].c_manageBrand !== true" (validation)="brandNameValidation = $event" [label]="i18n.page.brandName" [(value)]="organization.brandName" (change)="saveBrand()">
        <orgos-input-error *ngIf="brandNameValidation && brandNameValidation.getError('required')"> {{i18n.page.brandMandatory}}</orgos-input-error>
      </orgos-input-simple-text>
    </div>
    <div class="kenjo-flex-item-equal-size kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8 kenjo-p-20px kenjo-flex-vertical kenjo-flex-gap-10px">
      <div class="kenjo-font-weight-bold">
        <span class="kenjo-font-size-18px">{{ i18n.page.yourKenjoUsage }}</span>
        <orgos-chip class="kenjo-ml-15px" color="New">{{ i18n.page.new }}</orgos-chip>
      </div>
      <div class="kenjo-flex-horizontal kenjo-flex-gap-40px">
        <img src="assets/images/usage-chart.svg" />
        <div class="kenjo-flex-vertical kenjo-text-word-wrap kenjo-flex-spread kenjo-flex-gap-20px">
          <div class="kenjo-font-color-light-text-757575">{{ i18n.page.checkUsageDescription }}</div>
          <div [matTooltip]="hasAccessToBilling === false ? i18n.page.noUsagePermissionsTooltip : ''" matTooltipPosition="above">
            <kenjo-button-flat [disabled]="hasAccessToBilling === false" class="sop-usage-button" (click)="checkUsageButtonClicked()">{{ i18n.page.checkUsageButton }}</kenjo-button-flat>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="kenjo-mt-40px">
    <div class="kenjo-pb-10px kenjo-font-size-16px">{{i18n.page.features}}</div>
    <div class="kenjo-pt-5px sop-apps-container">
      <div class="kenjo-pv-5px sop-apps-content" *ngFor="let app of apps; trackBy: trackApps">
        <orgos-feature-box [profileName]="currentUser.profileKey" [feature]="app" [disabled]="disabledApps[app.appKey]" (changeActivate)="changeActivate($event)"></orgos-feature-box>
      </div>
    </div>
  </div>
  <div class="kenjo-mt-40px">
    <div class="kenjo-pb-10px kenjo-font-size-16px">{{i18n.page.homeWidgets}}</div>
    <div class="kenjo-pt-5px sop-apps-container">
      <div class="kenjo-pv-5px sop-apps-content" *ngFor="let widget of widgets; trackBy: trackApps">
        <orgos-feature-box [profileName]="currentUser.profileKey" [feature]="widget" [disabled]="widgetsDisabled" [available]="widgetAvailable[widget.appKey]" (changeActivate)="changeActivate($event)"></orgos-feature-box>
      </div>
    </div>
  </div>
  <div class="kenjo-mt-40px">
    <div class="kenjo-pb-10px kenjo-font-size-16px">{{i18n.page.integrations}}</div>
    <div class="kenjo-pt-5px sop-apps-container">
      <div class="kenjo-pv-5px sop-apps-content" *ngFor="let integration of integrations; trackBy: trackApps">
        <orgos-feature-box [profileName]="currentUser.profileKey" [feature]="integration" [disabled]="false" (changeActivate)="changeActivate($event)"> </orgos-feature-box>
      </div>
    </div>
  </div>
</orgos-container>
