import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, UrlSegment } from '@angular/router';
import { IValidateHashOptions, IValidateHashResult, PrivateHashService } from '@app/private/services/private-hash.service';
import { ISignInWithHashOptions, PrivateSignInService } from '@app/private/services/private-sign-in.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { HASH_TYPE_NEW_USER } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class NewUserHashGuard implements CanActivate {
  constructor(private injector: Injector) {}

  canLoad(_: Route, urlSegments: Array<UrlSegment>): boolean {
    if (check.not.assigned(urlSegments) || check.emptyArray(urlSegments) || check.not.assigned(urlSegments[1]) || check.emptyString(urlSegments[1].path)) {
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (check.not.assigned(route) || check.not.assigned(route.params) || check.not.assigned(route.params.hash) || check.emptyString(route.params.hash)) {
        this.injector.get(Router).navigate(['/signin']);
        resolve(false);
        return;
      }

      const hash = route.params.hash;

      const validateHashOptions: IValidateHashOptions = {
        type: HASH_TYPE_NEW_USER,
        hashValue: hash
      };

      this.injector
        .get(PrivateHashService)
        .validateHash(validateHashOptions)
        .then((validateHashResult: IValidateHashResult) => {
          const signInWithHashOptions: ISignInWithHashOptions = {
            type: HASH_TYPE_NEW_USER,
            hashValue: hash,
            referenceId: validateHashResult.referenceId,
            s_orgId: validateHashResult.s_orgId
          };

          return this.injector.get(PrivateSignInService).signInWithHash(signInWithHashOptions);
        })
        .then(() => {
          if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
            this.injector.get(Router).navigate(['/signin']);
            resolve(false);
            return;
          }

          resolve(true);
          return;
        })
        .catch(() => {
          // An error is already shown if necessary
          this.injector.get(Router).navigate(['/signin']);
          resolve(false);
          return;
        });
    });
  }
}
