import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanEmployeeDirectoryService {
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan/directory`;
  private SHIFTPLAN_EMPLOYEE_DIRECTORY_SERVICE: string = 'ShiftPlanEmployeeDirectoryService';

  constructor(private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  async getShiftPlanFilters(): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, this.SHIFTPLAN_EMPLOYEE_DIRECTORY_SERVICE, 'getShiftPlanFilters');
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      const result = await this.http.get(`${this.CONTROLLER_URL}/filters`, httpOptions).toPromise();
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_EMPLOYEE_DIRECTORY_SERVICE, 'getShiftPlanFilters');
    }
  }

  async getShiftPlanEmployeesDirectory(queryOptions: any): Promise<any> {
    if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
      throw new OrgosError(
        'PROGRAMMING ERROR',
        ErrorCodes.UNAUTHORIZED,
        this.SHIFTPLAN_EMPLOYEE_DIRECTORY_SERVICE,
        'getShiftPlanEmployeesDirectory'
      );
    }
    const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    try {
      const result = await this.http.post(`${this.CONTROLLER_URL}/employees`, queryOptions, httpOptions).toPromise();
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_EMPLOYEE_DIRECTORY_SERVICE, 'getShiftPlanEmployeesDirectory');
    }
  }
}

export interface IShiftplanEmployee {
  employee?: {
    name: string;
    status: string;
    _id: string;
    _photo: IFileMetadata;
  };
  reportsTo?: {
    displayName: string;
    _id: string;
  };
  shiftPlanActivationStatus: string;
  shiftplanStatus: string;
  office?: {
    name: string;
    _id: string;
  };
  company?: {
    name: string;
    _id: string;
  };
  locations?: string;
  workingAreas?: string;
  roles?: [
    {
      colour: string;
      name: string;
      id: string;
    }
  ];
  tags?: string;
}
