<orgos-dialog-container [title]="translations.title" (clickCloseButton)="closeDialog()">
  <div class="kenjo-flex-vertical kenjo-flex-gap-30px iaid-content-width">
    <ng-container *ngIf="displayForm === true; else inviteConfirmation">
      <div class="kenjo-flex-vertical kenjo-flex-gap-30px">
        <div class="kenjo-font-color-light-text-757575 kenjo-font-size-18px">{{ translations.subtitle }}</div>
        <div class="kenjo-flex-vertical kenjo-flex-gap-20px">
          <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
            <div *ngFor="let teammate of service.teammates; index as index">
              <ng-container *ngTemplateOutlet="inviteForm; context: {teammate: teammate, index: index}"></ng-container>
            </div>
          </div>
          <div class="kenjo-flex-horizontal kenjo-flex-spread kenjo-flex-center-vertically">
            <orgos-action
              class="iaid-person-item"
              icon="person_add"
              [disabled]="cannotProceed || service.teammates.length >= MAX_TEAMMATES"
              (click)="addTeammate()"
              >{{ translations.addMorePeople }}</orgos-action
            >
            <kenjo-button-flat (click)="invite()" [disabled]="cannotProceed">{{ translations.sendInvite }}</kenjo-button-flat>
          </div>
        </div>
      </div>
      <div *ngIf="service.inviteLink !== ''" class="kenjo-flex-vertical kenjo-flex-gap-5px">
        <mat-divider></mat-divider>
        <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575 kenjo-pt-10px">
          {{ translations.orShare }}
          <orgos-action
            [uppercase]="false"
            [iconSize]="'14px'"
            [matTooltip]="translations.copied"
            [matTooltipDisabled]="true"
            [matTooltipPosition]="'above'"
            #copyTooltip="matTooltip"
            icon="union_link"
            (click)="copyLink()"
            >{{ translations.copyLink }}</orgos-action
          >
        </div>
        <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.linkExpires }}</div>
      </div>
    </ng-container>
  </div>
</orgos-dialog-container>

<ng-template #inviteForm let-teammate="teammate" let-index="index">
  <form class="kenjo-font-size-14px kenjo-flex-horizontal kenjo-flex-gap-15px iaid-form-field">
    <div class="kenjo-full-width kenjo-flex-vertical kenjo-flex-align-start">
      <label class="kenjo-font-weight-bold">{{ translations.fullName }}</label>
      <orgos-input-simple-text
        ngDefaultControl
        [debounceNewValues]="true"
        [debounceTimer]="500"
        appearance="outline"
        class="kenjo-flex-align-self-stretch"
        [value]="teammate.fullName"
        (valueChange)="changeTeammateName($event, teammate, index)"
        [matTooltip]="translations.fullNameTooltip"
        [matTooltipPosition]="'above'"
      ></orgos-input-simple-text>
      <mat-error *ngIf="isNameNonEmpty[index] === false">{{ translations.fullNameEmpty }}</mat-error>
      <mat-error *ngIf="isNotFullName[index] === true"> {{ translations.fullNameError }}</mat-error>
    </div>
    <div class="kenjo-full-width kenjo-flex-vertical kenjo-flex-align-start">
      <label class="kenjo-font-weight-bold">{{ translations.workEmail }}</label>
      <orgos-input-simple-email
        ngDefaultControl
        [debounceNewValues]="true"
        [debounceTimer]="500"
        appearance="outline"
        class="kenjo-flex-align-self-stretch"
        [value]="teammate.workEmail"
        (valueChange)="changeTeammateEmail($event, teammate, index)"
        (validation)="onEmailValidation($event, index)"
      >
      </orgos-input-simple-email>
      <mat-error *ngIf="emailValidation[index]?.getError('email')"> {{ translations.emailError }}</mat-error>
      <mat-error *ngIf="emailValidation[index]?.isValid() && isEmailNonEmpty[index] === false">{{ translations.emailEmpty }}</mat-error>
      <mat-error *ngIf="isNotWorkEmail[index] === true && !emailValidation[index].getError('email')"
        >{{ translations.notWorkEmailError | i18nData: { domain: teammate.workEmail?.split('@')[1] } }}</mat-error
      >
      <mat-error *ngIf="isEmailNonEmpty[index] === true && !emailValidation[index].getError('email') && duplicatedEmails[index]"
        >{{ translations.duplicatedEmailError }}</mat-error
      >
    </div>
    <div class="iaid-padding-top">
      <kenjo-icon
        [ngClass]="service.teammates.length > 1 ? 'kenjo-font-color-danger-ff5757 kenjo-cursor-pointer' : 'kenjo-font-color-disabled-c4c4c4'"
        [size]="20"
        [matTooltip]="translations.removeTooltip"
        [matTooltipDisabled]="service.teammates.length <= 1"
        (click)="openDeleteDialog($event, index)"
        >delete</kenjo-icon
      >
    </div>
  </form>
</ng-template>

<ng-template #inviteConfirmation>
  <div class="kenjo-flex-center-content">
    <div class="kenjo-flex-vertical kenjo-flex-center kenjo-flex-gap-10px">
      <div class="kenjo-flex-vertical kenjo-flex-center-vertically kenjo-flex-gap-20px">
        <img class="iaid-image-size" src="assets/images/inapp-invite-confirm.svg" alt="Invite confirmation" />
        <div class="kenjo-font-size-16px">{{ translations.confirmation}}</div>
      </div>
      <div
        class="kenjo-flex-horizontal kenjo-flex-spread kenjo-flex-center-vertically"
        [class.kenjo-flex-center]="service.inviteLink === ''"
      >
        <orgos-action class="iaid-person-item" icon="person_add" (click)="resetForm()">{{ translations.addMorePeople }}</orgos-action>
        <ng-container *ngIf="service.inviteLink !== ''">
          <div class="kenjo-font-size-16px">{{ translations.or }}</div>
          <orgos-action
            [uppercase]="false"
            [iconSize]="'14px'"
            [matTooltip]="translations.copied"
            [matTooltipDisabled]="true"
            [matTooltipPosition]="'above'"
            #copyTooltip="matTooltip"
            icon="union_link"
            (click)="copyLink()"
            >{{ translations.copyLink }}</orgos-action
          >
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
