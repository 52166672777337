import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class CompanyCareerSiteService {
  private COMPANY_CAREER_SITE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/company-career-site-db`;
  private COMPANY_CAREER_SITE_PERMISSIONS_KEY: string = 'company-career-site';
  private COMPANY_CAREER_SITE_INTERNATIONALIZATION: string = 'company-career-site-collection';
  private CAREER_SITE_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/career-site/public/`;

  constructor(
    private genericService: GenericService,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private errorManager: ErrorManagerService
  ) {}

  getCompanyCareerSite(): Promise<ICompanyCareerSiteModel> {
    return new Promise<ICompanyCareerSiteModel>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .get(`${this.COMPANY_CAREER_SITE_URL}/company-career-site`, httpOptions)
        .toPromise()
        .then((companyCareerSite: ICompanyCareerSiteModel) => {
          resolve(companyCareerSite);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CompanyCareerSiteService.name, 'getCompanyCareerSite'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.COMPANY_CAREER_SITE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CompanyCareerSiteService.name, 'updateById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.COMPANY_CAREER_SITE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.COMPANY_CAREER_SITE_INTERNATIONALIZATION);
  }

  async getPositionList(): Promise<any> {
    try {
      const subdomain = (await this.getCompanyCareerSite()).subdomain;
      return await this.http.get(`${this.CAREER_SITE_CONTROLLER_URL}${subdomain}/positions`).toPromise();
    } catch {}
  }

  async getCareerSiteInfo(): Promise<any> {
    try {
      const subdomain = (await this.getCompanyCareerSite()).subdomain;
      return await this.http.get(`${this.CAREER_SITE_CONTROLLER_URL}${subdomain}`).toPromise();
    } catch {}
  }
}

export interface ICompanyCareerSiteModel {
  _id: string;
  _updatedAt: Date;
  _createdAt: Date;
  _createdById: string;
  _updatedById: string;
  active: boolean;
  defaultLanguage: string;
  title: string;
  description: string;
  companyName: string;
  subdomain: string;
  logo?: IFileMetadata;
  favicon?: IFileMetadata;
  heroImage?: IFileMetadata;
  heroBackgroundColor: string;
  brandColor: string;
  companyWebsite?: string;
  companyLinkedIn?: string;
  companyTwitter?: string;
  companyFacebook?: string;
  companyBlog?: string;
  metadata?: Array<any>;
  companyPrivacyPolicy?: string;
  companyPrivacyPolicyMessage?: string;
  communicationConsentRequired?: boolean;
}
