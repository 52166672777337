import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IMeetingTemplateModel, MeetingTemplateService } from '@app/standard/services/performance-management/meeting-template.service';
import { IMeetingModel, MeetingService } from '@app/standard/services/performance-management/meeting.service';
import { TaskService } from '@app/standard/services/task/task.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-new-meeting',
  templateUrl: 'new-meeting.dialog.html',
  styleUrls: ['new-meeting.dialog.scss'],
})
export class NewMeetingDialog implements OnInit {
  meeting: IMeetingModel = {
    name: '',
  };
  loggedUser: any;
  meetingCache: GenericCacheModel;
  translation: any = {};
  picklistTranslation: any = {};
  departments: Array<any> = [];
  mapDepartments: any = {};
  templates: Array<IMeetingTemplateModel> = [];
  mapTemplates: any = {};
  mapUsers: any = {};
  TODAY = moment();
  isLoadTemplate: boolean = false;
  templateSelected: string = '';
  isUpdateAction: boolean = false;
  startTime: number = 0;
  repeatMeeting: boolean = false;
  templateIdToEdit: string;
  templateIdToDelete: string;
  tempTemplateName: string;
  isScheduled: boolean = false; // will be true if we are editing a meeting that has been scheduled already
  canBeSaved: boolean = false;

  wizardStep: number = 1;
  WIZARD_FINAL_STEP: number = 3;
  STEP_DETAIL: number = 1;
  STEP_TEMPLATE: number = 2;
  STEP_TIME: number = 3;

  TYPE_TEXT: string = picklists.AGENDA_TYPE_TEXT;
  TYPE_LIST: string = picklists.AGENDA_TYPE_LIST_VAL;
  TYPE_TODO: string = picklists.AGENDA_TYPE_TODO;
  TYPE_QUESTION: string = picklists.AGENDA_TYPE_QUESTION;
  WEEKDAYS_ARRAY = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  translatedWeekdays: Array<string>;
  titleValidation: InputValidation;

  LIVE: string = picklists.MEETING_TYPE_LIVE;
  ASYNC: string = picklists.MEETING_TYPE_ASYNC;

  constructor(
    public dialogRef: MatLegacyDialogRef<NewMeetingDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.translatedWeekdays = this.injector.get(InternationalizationService).getShortTranslatedWeekdays();
    if (check.assigned(this.data) && check.assigned(this.data.meeting)) {
      // is edit or new Meeting?
      this.isUpdateAction = true;
      const momentStartDate = moment(this.data.meeting.startDate);
      this.startTime = momentStartDate.minutes() + 60 * momentStartDate.hours();
      if (
        check.assigned(this.data.meeting.repeatMeeting) &&
        check.nonEmptyObject(this.data.meeting.repeatMeeting) &&
        check.nonEmptyObject(this.data.meeting.repeatMeeting.weeklyRepetitions)
      ) {
        // Check if the meeting repeats for any day:
        const repeatArray = Object.keys(this.data.meeting.repeatMeeting.weeklyRepetitions).filter((iKey) => {
          return this.data.meeting.repeatMeeting.weeklyRepetitions[iKey] === true;
        });
        this.repeatMeeting = check.nonEmptyArray(repeatArray);
        this.isScheduled = this.repeatMeeting;
      }
      this.meetingCache = new GenericCacheModel(this.injector, this.data.meeting, MeetingService, this.data.meeting.ownerId);
      if (check.assigned(this.data.isLoadTemplate)) {
        // is load Template
        this.isLoadTemplate = this.data.isLoadTemplate;
        this.wizardStep = this.STEP_TEMPLATE;
      }
    } else {
      // Set default of meeting date to today in the next hour (o'clock)
      if (check.not.assigned(this.meeting.startDate)) {
        this.meeting.startDate = new Date();
        this.startTime = 60 * new Date().getHours() + 60;
      }
      if (check.not.assigned(this.meeting.repeatMeeting) || check.not.assigned(this.meeting.repeatMeeting.weeklyRepetitions)) {
        this.meeting.repeatMeeting = {};
        this.meeting.repeatMeeting.weeklyRepetitions = {};
      }
      this.meetingCache = new GenericCacheModel(this.injector, this.meeting, MeetingService, this.loggedUser._id);
    }
    this.isValidFormData();

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('meetings-misc')
      .then((dialogTranslation) => {
        this.translation = dialogTranslation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('standard-picklists')
      .then((picklistTranslations) => {
        this.picklistTranslation = picklistTranslations;
      })
      .catch(() => {
        this.picklistTranslation = {};
      });

    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((resultDepartments) => {
        this.departments = resultDepartments.map((iDepartment) => {
          const iOption: ISelectOption = {
            name: iDepartment.name,
            value: iDepartment._id,
          };
          return iOption;
        });
        this.mapDepartments = _.keyBy(resultDepartments, '_id');
      })
      .catch(() => {
        this.departments = [];
      });

    this.injector
      .get(MeetingTemplateService)
      .getAllTemplates()
      .then((resultTemplates) => {
        this.templates = resultTemplates;
        this.templates.forEach((iTemplate) => {
          if (check.assigned(iTemplate.isDefault) && iTemplate.isDefault === true && check.assigned(this.translation[iTemplate.name])) {
            iTemplate.name = this.translation[iTemplate.name];
          }
        });
        this.mapTemplates = _.keyBy(this.templates, '_id');
      })
      .catch(() => {
        this.templates = [];
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false)
      .then((allUserPersonal: Array<any>) => {
        this.mapUsers = _.keyBy(allUserPersonal, '_id');
      })
      .catch(() => {
        this.mapUsers = {};
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public leftButtonAction(): void {
    if (this.wizardStep === 1 || this.isLoadTemplate === true) {
      this.dialogRef.close();
    }
    if (this.wizardStep >= 2 && this.isLoadTemplate === false) {
      this.wizardStep--;
      if (this.isUpdateAction === true && this.wizardStep === this.STEP_TEMPLATE) {
        this.wizardStep--;
      }
    }
    this.isValidFormData();
  }

  public rightButtonAction(): void {
    let newMeeting;
    let newAgendaItems;
    if (this.isValidFormData()) {
      if (this.isFinalStep()) {
        if (check.not.string(this.meetingCache.data.startDate)) {
          this.meetingCache.data.startDate = this.meetingCache.data.startDate.toISOString();
        }
        const meetingDateTime = moment(this.meetingCache.data.startDate.split('T')[0]);
        meetingDateTime.set({ hour: Math.floor(this.startTime / 60), minute: this.startTime % 60 });
        this.meetingCache.data.startDate = meetingDateTime.toISOString();
        let callsToCloneAgenda = [];
        if (check.assigned(this.templateSelected) && check.nonEmptyString(this.templateSelected)) {
          if (check.not.assigned(this.meetingCache.data.templateId)) {
            this.meetingCache.data.templateId = [];
          }
          this.meetingCache.data.templateId.push(this.templateSelected);
          if (
            check.assigned(this.mapTemplates[this.templateSelected]) &&
            check.assigned(this.mapTemplates[this.templateSelected].agenda) &&
            check.nonEmptyArray(this.mapTemplates[this.templateSelected].agenda)
          ) {
            callsToCloneAgenda = this.mapTemplates[this.templateSelected].agenda.map((agendaItem) => {
              return this.cloneAgendaItem(agendaItem);
            });
          }
        }
        Promise.all(callsToCloneAgenda)
          .then((agendaItems) => {
            if (check.nonEmptyArray(agendaItems)) {
              newAgendaItems = agendaItems;
              if (check.assigned(this.meetingCache.data.agenda) && check.array(this.meetingCache.data.agenda)) {
                this.meetingCache.data.agenda = this.meetingCache.data.agenda.concat(agendaItems);
              } else {
                this.meetingCache.data.agenda = agendaItems;
              }
            }

            if (check.assigned(this.meetingCache.data._id)) {
              this.meetingCache
                .updateInServer()
                .then(() => {
                  let updateTasks;
                  const newMeetingId = this.meetingCache.data._id;
                  let callsToUpdateTasksRelatedTo = [];
                  if (check.nonEmptyArray(newAgendaItems)) {
                    updateTasks = newAgendaItems.filter((task) => task.type === this.TYPE_TODO);
                  }
                  if (check.nonEmptyArray(updateTasks)) {
                    callsToUpdateTasksRelatedTo = updateTasks.map((agendaItem) => {
                      return this.updateTaskItem(agendaItem, newMeetingId);
                    });
                  }
                  return Promise.all(callsToUpdateTasksRelatedTo);
                })
                .then(() => {
                  this.injector.get(MatLegacySnackBar).open(this.translation.dialogMeetingUpdated, 'OK', {
                    duration: 5000,
                  });
                  this.dialogRef.close(true);
                  return;
                })
                .catch(() => {
                  //
                });
            } else {
              this.meetingCache
                .createInServer()
                .then((newData) => {
                  newMeeting = newData;
                  let updateTasks;
                  const newMeetingId = newMeeting.data._id;
                  let callsToUpdateTasksRelatedTo = [];
                  if (check.nonEmptyArray(this.meetingCache.data.agenda)) {
                    updateTasks = this.meetingCache.data.agenda.filter((task) => task.type === this.TYPE_TODO);
                  }
                  if (check.nonEmptyArray(updateTasks)) {
                    callsToUpdateTasksRelatedTo = updateTasks.map((agendaItem) => {
                      return this.updateTaskItem(agendaItem, newMeetingId);
                    });
                  }
                  return Promise.all(callsToUpdateTasksRelatedTo);
                })
                .then(() => {
                  this.injector.get(MatLegacySnackBar).open(this.translation.dialogMeetingCreated, 'OK', {
                    duration: 5000,
                  });
                  this.dialogRef.close(newMeeting.data._id);
                  this.logAmplitudeEvent(newMeeting);
                  return;
                })
                .catch(() => {
                  //
                });
            }
          })
          .catch((e) => {
            //
          });
      } else {
        this.wizardStep++;
        if (this.isUpdateAction === true && this.wizardStep === this.STEP_TEMPLATE) {
          this.wizardStep++;
        }
        this.isValidFormData();
      }
    }
  }

  logAmplitudeEvent(meeting: GenericCacheModel) {
    let withDepartment = 'no';
    if (check.assigned(meeting.data.departmentId) && check.nonEmptyString(meeting.data.departmentId)) {
      withDepartment = 'yes';
    }
    let withTemplate = 'no';
    if (check.assigned(this.templateSelected) && check.nonEmptyString(this.templateSelected)) {
      withTemplate = 'yes';
    }
    let withReminder = 'no';
    if (check.assigned(meeting.data.remindMeeting) && meeting.data.remindMeeting === true) {
      withReminder = 'yes';
    }
    let repeating = 'no';
    if (this.repeatMeeting === true) {
      repeating = 'yes';
    }
    this.injector.get(PrivateAmplitudeService).logEvent('create meeting', {
      category: 'Meetings',
      withDepartment: withDepartment,
      withTemplate: withTemplate,
      withReminder: withReminder,
      repeating: repeating,
    });
  }

  public isValidFormData(): boolean {
    this.canBeSaved = false;
    if (this.wizardStep === this.STEP_DETAIL) {
      if (check.not.assigned(this.titleValidation)) {
        this.canBeSaved = check.nonEmptyString(this.meetingCache?.data?.name);
      } else {
        this.canBeSaved = !(check.not.assigned(this.titleValidation) || this.titleValidation.hasErrors());
      }
    } else if (this.wizardStep === this.STEP_TEMPLATE) {
      this.canBeSaved = check.assigned(this.templateSelected) && check.nonEmptyString(this.templateSelected);
    } else if (this.wizardStep === this.STEP_TIME) {
      this.canBeSaved = check.assigned(this.meetingCache.data.startDate);
    }
    return this.canBeSaved;
  }

  public repeatMeetingClick(newVal: boolean): void {
    if (newVal === true) {
      // if user has just enabled repeat meeting, set this weekday as enabled
      const referenceDate = this.meetingCache?.data?.startDate ? moment(this.meetingCache.data.startDate) : moment();
      const weekDay = referenceDate.isoWeekday() - 1; // 1: monday, 7: sunday
      this.meetingCache.data.repeatMeeting = {};
      this.meetingCache.data.repeatMeeting.weeklyRepetitions = {};
      this.meetingCache.data.repeatMeeting.weeklyRepetitions[this.WEEKDAYS_ARRAY[weekDay]] = true;
    } else {
      this.meetingCache.data.repeatMeeting = {};
      this.meetingCache.data.repeatMeeting.weeklyRepetitions = {};
    }
    this.repeatMeeting = newVal;
  }

  public editTemplateClick(templateId: string): void {
    this.templateIdToDelete = null;
    this.tempTemplateName = this.templates.find((template: IMeetingTemplateModel) => {
      return template._id === templateId;
    }).name;
    this.templateIdToEdit = templateId;
  }

  public deleteTemplateClick(templateId: string): void {
    this.templateIdToEdit = null;
    this.templateIdToDelete = templateId;
    this.tempTemplateName = null;
  }

  public cancelEditOfTemplate(): void {
    this.templateIdToEdit = null;
    this.templateIdToDelete = null;
    this.tempTemplateName = null;
  }

  public editTemplate(templateId: string): void {
    if (check.not.assigned(this.tempTemplateName) || check.emptyString(this.tempTemplateName)) {
      return;
    }

    const newTempName = this.tempTemplateName;
    let oldTempName;
    this.templates.find((template: IMeetingTemplateModel) => {
      if (template._id === templateId) {
        oldTempName = template.name;
        return true;
      }
      return false;
    }).name = this.tempTemplateName;

    this.cancelEditOfTemplate();

    this.injector
      .get(MeetingTemplateService)
      .updateById(templateId, { name: newTempName })
      .then(() => {
        const message = this.injector
          .get(I18nDataPipe)
          .transform(this.translation.templateUpdatedSnackbar, { oldTemplateName: oldTempName, newTemplateName: newTempName });
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });
      })
      .catch(() => {
        // error is displayed
      });
  }

  public deleteTemplate(templateId: string): void {
    let templateName;
    this.templates = this.templates.filter((iTemplate: IMeetingTemplateModel) => {
      if (iTemplate._id === templateId) {
        templateName = iTemplate.name;
        return false;
      }
      return true;
    });
    this.cancelEditOfTemplate();

    this.injector
      .get(MeetingTemplateService)
      .deleteById(templateId)
      .then(() => {
        const message = this.injector.get(I18nDataPipe).transform(this.translation.templateDeletedStnackbar, { name: templateName });
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });
      })
      .catch(() => {
        // error is displayed
      });
  }

  // PRIVATE METHODS
  private isFinalStep(): boolean {
    return this.wizardStep === this.WIZARD_FINAL_STEP || this.isLoadTemplate === true;
  }

  private async updateTaskItem(element: any, newMeetingId: any): Promise<GenericCacheModel> {
    if (check.not.assigned(element)) {
      return;
    }
    if (check.not.assigned(element.content) || check.emptyArray(element.content)) {
      return element;
    }
    const tasks = await this.injector.get(TaskService).find({ _id: { $in: element.content } });
    const callToUpdateTasks = tasks.map((task) => {
      return this.injector.get(TaskService).updateById(task._id, { relatedTo: newMeetingId });
    });
    Promise.all(callToUpdateTasks);
  }

  private cloneAgendaItem(element: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (check.not.assigned(element)) {
        resolve(undefined);
        return;
      }
      const newElement = _.cloneDeep(element);
      newElement.completed = false;
      delete newElement._id;
      if (newElement.type !== this.TYPE_LIST && newElement.type !== this.TYPE_TODO) {
        resolve(newElement);
        return;
      } else if (newElement.type === this.TYPE_LIST) {
        if (check.not.assigned(newElement.content) || check.emptyArray(newElement.content)) {
          resolve(newElement);
          return;
        }
        newElement.content.forEach((item) => {
          if (check.assigned(item) && check.assigned(item.completed)) {
            item.completed = false;
          }
        });
        resolve(newElement);
        return;
      } else if (newElement.type === this.TYPE_TODO) {
        if (check.not.assigned(newElement.content) || check.emptyArray(newElement.content)) {
          resolve(newElement);
          return;
        }
        this.injector
          .get(TaskService)
          .find({ _id: { $in: newElement.content } })
          .then((tasks: Array<any>) => {
            const callToCreateTasks = tasks.map((task) => {
              const newTask = _.cloneDeep(task);
              delete newTask._id;
              newTask.isCompleted = false;
              return this.injector.get(TaskService).create(newTask);
            });
            return Promise.all(callToCreateTasks);
          })
          .then((resultTaskCreation) => {
            newElement.content = [];
            resultTaskCreation.forEach((result) => {
              newElement.content.push(result._id);
            });
            resolve(newElement);
            return;
          })
          .catch((e) => {
            newElement.content = [];
            reject(e);
          });
      }
    });
  }
}
