<orgos-container aspect="card" *ngIf="!loadingPage && selectedCustomerIndex > -1">
  <orgos-column-container class="sbp-main-container" [class.sbp-updating-info]="updatingCustomerInfo || updatingCustomerBillingInfo">
    <orgos-column size="0" class="sbp-menu kenjo-p-30px kenjo-border-right">
      <orgos-settings-billing-menu [menuTranslation]="i18n.page.menu" [selectedOption]="2"></orgos-settings-billing-menu>
    </orgos-column>

    <orgos-column size="1" class="sbp-main-content sbp-empty kenjo-mt-50px" *ngIf="multiBilling && !showError">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-14px">{{i18n.page.billingInfo.multiCompanyActivated}}</div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.billingInfo.multiCompanyActivatedMoreInfo}}</div>
    </orgos-column>

    <orgos-column size="1" class="sbp-main-content sbp-empty kenjo-mt-50px" *ngIf="showError">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-14px">{{i18n.page.billingInfo.notAvailableTitle}}</div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.billingInfo.notAvailableMessage}}</div>
    </orgos-column>

    <orgos-column size="1" class="sbp-main-content" *ngIf="!multiBilling && !showError">
      <div class="kenjo-p-30px">
        <div class="kenjo-font-size-18px">{{i18n.page.currentPlan.currentPlanTitle}}</div>
        <div class="kenjo-mt-30px sbp-subscription-cards">
          <ng-container *ngFor="let iSubscription of subscriptions">
            <kenjo-subscription-card [translations]="i18n.page" [subscription]="iSubscription"></kenjo-subscription-card>
          </ng-container>
        </div>

        <div class="sbp-legacy-plan kenjo-mt-30px" *ngIf="isLegacy">
          <div class="kenjo-p-10px">
            <kenjo-icon [size]="20" class="kenjo-mr-5px kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
            <div>{{i18n.page.billingInfo.legacyPlanMessageStart}} <a routerLink="/cloud/settings/billing">{{i18n.page.billingInfo.legacyPlanMessageLink}}</a></div>
          </div>
        </div>
      </div>
      <div class="kenjo-p-30px">
        <div class="kenjo-font-size-18px">{{i18n.page.billingInfo.billingInfoTitle}}</div>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.companyName"
            [required]="true"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.company"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.company', $event)"
            class="kenjo-mr-10px"
          ></orgos-input-simple-text>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.email"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.email"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.email', $event)"
            class="kenjo-ml-10px"
          ></orgos-input-simple-text>
        </orgos-column-container>
        <orgos-column-container>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.firstName"
            [required]="true"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.first_name"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.first_name', $event)"
            class="kenjo-mr-10px"
          ></orgos-input-simple-text>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.lastName"
            [required]="true"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.last_name"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.last_name', $event)"
            class="kenjo-ml-10px"
          ></orgos-input-simple-text>
        </orgos-column-container>
        <orgos-column-container>
          <orgos-input-simple-select
            orgosColumn
            [label]="i18n.page.billingInfo.locale"
            [required]="true"
            [options]="chargebeeLocales"
            [value]="chargebeeCustomers[selectedCustomerIndex].locale"
            (valueChange)="updateCustomerInfo(selectedCustomerIndex, 'locale', $event)"
            class="kenjo-mr-10px"
          ></orgos-input-simple-select>
          <orgos-column class="kenjo-ml-10px"></orgos-column>
        </orgos-column-container>

        <orgos-text type="Headline3" class="kenjo-mt-40px kenjo-mb-20px">{{i18n.page.billingInfo.billingAddressTitle}}</orgos-text>
        <orgos-column-container>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.addressLine1"
            [required]="true"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.line1"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.line1', $event)"
            class="kenjo-mr-10px"
          ></orgos-input-simple-text>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.addressLine2"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.line2"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.line2', $event)"
            class="kenjo-ml-10px"
          ></orgos-input-simple-text>
        </orgos-column-container>
        <orgos-column-container>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.postalCode"
            [required]="true"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.zip"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.zip', $event)"
            class="kenjo-mr-10px"
          ></orgos-input-simple-text>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.city"
            [required]="true"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.city"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.city', $event)"
            class="kenjo-ml-10px"
          ></orgos-input-simple-text>
        </orgos-column-container>
        <orgos-column-container>
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.state"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.state"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.state', $event)"
            class="kenjo-mr-10px"
          ></orgos-input-simple-text>
          <orgos-input-simple-select
            orgosColumn
            [label]="i18n.page.billingInfo.country"
            [required]="true"
            picklist="country"
            [value]="chargebeeCustomers[selectedCustomerIndex].billing_address.country"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'billing_address.country', $event)"
            class="kenjo-ml-10px"
          ></orgos-input-simple-select>
        </orgos-column-container>

        <orgos-text *ngIf="chargebeeCustomers[selectedCustomerIndex].billing_address && chargebeeCustomers[selectedCustomerIndex].billing_address.country" type="Headline3" class="kenjo-mt-40px kenjo-mb-20px">{{i18n.page.billingInfo.billingTaxesTitle}}</orgos-text>
        <orgos-column-container *ngIf="chargebeeCustomers[selectedCustomerIndex].billing_address && chargebeeCustomers[selectedCustomerIndex].billing_address.country">
          <orgos-input-simple-text
            orgosColumn
            [label]="i18n.page.billingInfo.vatNumber"
            [prefix]="chargebeeCustomers[selectedCustomerIndex].billing_address && chargebeeCustomers[selectedCustomerIndex].billing_address.country ? chargebeeCustomers[selectedCustomerIndex].billing_address.country : ''"
            [value]="chargebeeCustomers[selectedCustomerIndex].vat_number"
            (valueChange)="updateCustomerBillingInfo(selectedCustomerIndex, 'vat_number', $event)"
            class="kenjo-mr-10px"
          ></orgos-input-simple-text>
          <orgos-column class="kenjo-ml-10px"></orgos-column>
        </orgos-column-container>

        <orgos-column-container class="kenjo-mt-40px kenjo-mb-20px">
          <orgos-column>
            <orgos-text type="Headline3">{{i18n.page.billingInfo.paymentTitle}}</orgos-text>
          </orgos-column>
          <orgos-column size="0">
            <orgos-action icon="add_circle" (click)="openPaymentSources(selectedCustomerIndex)">{{i18n.page.billingInfo.addPaymentMethod}}</orgos-action>
          </orgos-column>
        </orgos-column-container>
        <orgos-column-container *ngIf="!currentPaymentSources || currentPaymentSources.length === 0" class="sbp-warning-area kenjo-p-10px" centerColumns="true">
          <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
          <orgos-column size="1" class="kenjo-ml-5px kenjo-font-color-light-text-757575"> {{i18n.page.billingInfo.noPaymentMethod}} </orgos-column>
        </orgos-column-container>
        <orgos-table [fullHeight]="true" *ngIf="currentPaymentSources && currentPaymentSources.length > 0" [data]="currentPaymentSources" [displayedColumns]="paymentMethodsTableDisplayedColumns">
          <orgos-table-column columnKey="type" [header]="i18n.page.billingInfo.typeColumnHeader" [sortable]="true">
            <ng-container *orgosTableCell="let iPaymentSource"> {{i18n.page.billingInfo.paymentMethodTypes[iPaymentSource.type]}} </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="owner" [header]="i18n.page.billingInfo.ownerColumnHeader" [sortable]="true">
            <ng-container *orgosTableCell="let iPaymentSource">
              <ng-container *ngIf="iPaymentSource.type === 'card' && iPaymentSource.card">{{iPaymentSource.card.first_name}}&nbsp;{{iPaymentSource.card.last_name}}</ng-container>
              <ng-container *ngIf="iPaymentSource.type === 'direct_debit' && iPaymentSource.bank_account">{{iPaymentSource.bank_account.name_on_account}}</ng-container>
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="number" [header]="i18n.page.billingInfo.numberColumnHeader" [sortable]="true">
            <ng-container *orgosTableCell="let iPaymentSource">
              <ng-container *ngIf="iPaymentSource.type === 'card' && iPaymentSource.card"
                >{{iPaymentSource.card.masked_number | slice:0:4}}&nbsp;{{iPaymentSource.card.masked_number | slice:4:8}}&nbsp;{{iPaymentSource.card.masked_number | slice:8:12}}&nbsp;{{iPaymentSource.card.masked_number | slice:12:16}}</ng-container
              >
              <ng-container *ngIf="iPaymentSource.type === 'direct_debit' && iPaymentSource.bank_account">****&nbsp;****&nbsp;****&nbsp;{{iPaymentSource.bank_account.last4}}</ng-container>
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="expirationDate" [header]="i18n.page.billingInfo.expirationDateColumnHeader">
            <ng-container *orgosTableCell="let iPaymentSource">
              <ng-container *ngIf="iPaymentSource.type === 'card' && iPaymentSource.card">{{iPaymentSource.card.expiry_month}}/{{iPaymentSource.card.expiry_year}}</ng-container>
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="status" [header]="i18n.page.billingInfo.statusColumnHeader">
            <ng-container *orgosTableCell="let iPaymentSource"> {{i18n.page.billingInfo.paymentMethodStatuses[iPaymentSource.status]}} </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="delete" header="" [actionColumn]="true">
            <ng-container *ngIf="currentPaymentSources.length > 1">
              <ng-container *orgosTableCell="let iPaymentSource">
                <a class="sbp-delete kenjo-font-color-light-text-757575" (click)="deletePaymentSource(iPaymentSource.id, selectedCustomerIndex)"><mat-icon>delete</mat-icon></a>
              </ng-container>
            </ng-container>
          </orgos-table-column>
        </orgos-table>
      </div>
    </orgos-column>
  </orgos-column-container>
</orgos-container>

<orgos-container aspect="card" *ngIf="!loadingPage && (errorOnFetchingData || selectedCustomerIndex === -1) && i18n.page">
  <orgos-column-container class="sbp-main-container">
    <orgos-column size="0" class="sbp-menu kenjo-p-30px kenjo-border-right">
      <orgos-settings-billing-menu [menuTranslation]="i18n.page.menu" [selectedOption]="1"></orgos-settings-billing-menu>
    </orgos-column>
    <orgos-column class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.errorPage.title}}</div>
      <div class="kenjo-mt-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.errorPage.subtitle}}</div>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
