import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class ProjectTimeEntryService {
  private PROJECT_TIME_ENTRY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/project-time-entry-db`;
  private PROJECT_TIME_ENTRY_PERMISSIONS_KEY: string = 'project-time-entry';
  private PROJECT_TIME_ENTRY_INTERNATIONALIZATION: string = 'project-time-entry-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any): Promise<IProjectTimeEntryModel> {
    return new Promise<IProjectTimeEntryModel>((resolve, reject) => {
      this.genericService
        .create(this.PROJECT_TIME_ENTRY_URL, data)
        .then((projectTimeEntry: IProjectTimeEntryModel) => {
          resolve(projectTimeEntry);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectTimeEntryService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IProjectTimeEntryModel>> {
    return new Promise<Array<IProjectTimeEntryModel>>((resolve, reject) => {
      this.genericService
        .find(this.PROJECT_TIME_ENTRY_URL, findQuery)
        .then((projectTimeEntries: Array<IProjectTimeEntryModel>) => {
          resolve(projectTimeEntries);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectTimeEntryService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<IProjectTimeEntryModel> {
    return new Promise<IProjectTimeEntryModel>((resolve, reject) => {
      this.genericService
        .getById(this.PROJECT_TIME_ENTRY_URL, id)
        .then((projectTimeEntry: IProjectTimeEntryModel) => {
          resolve(projectTimeEntry);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectTimeEntryService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IProjectTimeEntryModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.PROJECT_TIME_ENTRY_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectTimeEntryService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.PROJECT_TIME_ENTRY_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectTimeEntryService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.PROJECT_TIME_ENTRY_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.PROJECT_TIME_ENTRY_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.PROJECT_TIME_ENTRY_URL);
  }

  getAllProjectTimeEntries(): Promise<Array<IProjectTimeEntryModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<IProjectTimeEntryModel>>((resolve, reject) => {
      this.genericService
        .find(this.PROJECT_TIME_ENTRY_URL, findBody)
        .then((allProjectTimeEntries: Array<IProjectTimeEntryModel>) => {
          resolve(allProjectTimeEntries);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ProjectTimeEntryService.name, 'getAllProjectTimeEntries'));
        });
    });
  }
}

export interface IProjectTimeEntryModel {
  _userId: string;
  _projectId: string;
  _timeEntryDate: Date;
  numberOfMinutes: number;
  _costPerHour?: number;
  _departmentId?: string;
  _officeId?: string;
  _companyId?: string;
  roleName?: string;
}
