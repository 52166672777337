import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'kenjo-extensible-table',
  templateUrl: './extensible-table.component.html',
  styleUrls: ['./extensible-table.component.scss'],
})
export class ExtensibleTableComponent implements OnInit {
  addOverlay: OverlayRef;

  isExpanded = true;

  @Input() label: string;
  @Input() helpTitle: string;
  @Input() helpDescription: string;
  @Input() helpSuccessButtonText: string;
  @Input() buttonTooltip: string;
  @Input() headerTooltip: string;
  @Input() isAdditionEnabled: boolean = false;
  @Input() overlay: any;
  @Input() buttonText: string = '';
  @Input() onItemSelected: Subject<any>;

  @ViewChild(CdkPortal) addPortal: Portal<any>;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.overlay = this.injector.get(Overlay);
  }

  public addItem(elementRef: ElementRef): void {
    const addItemOverlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
    };

    addItemOverlayConfig.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withPositions([
        { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
        { originX: 'end', originY: 'center', overlayX: 'end', overlayY: 'center' },
      ])
      .withPush(false);

    this.addOverlay = this.overlay.create(addItemOverlayConfig);
    this.addOverlay.attach(this.addPortal);
    if (this.onItemSelected) {
      this.onItemSelected.subscribe(() => {
        this.addOverlay.dispose();
      });
    }
    this.addOverlay.backdropClick().subscribe(() => {
      this.addOverlay.dispose();
    });
  }

  protected onPressHelp(): void {
    const dialogData = {
      titleText: this.helpTitle,
      subtitleText: this.helpDescription,
      confirmButtonText: this.helpSuccessButtonText,
      confirmButtonColor: 'Success',
    };

    this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
  }
}
