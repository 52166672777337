import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import * as check from 'check-types';

@Component({
  templateUrl: 'add-edit-role-dialog.html',
  styleUrls: ['add-edit-role-dialog.scss'],
})
export class SettingsShiftPlanRoleDialog implements OnInit {
  dialogTranslation: any = {};
  isNewRole: boolean;
  isCodeValid: boolean = true;
  role: GenericCacheModel;
  keyWordsActions = { edited: 'Edited', created: 'Created' };

  nameCreateValidation: InputValidation;
  nameEditValidation: InputValidation;

  constructor(
    public dialogRef: MatLegacyDialogRef<SettingsShiftPlanRoleDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    public snackBar: MatLegacySnackBar,
    private i18nDataPipe: I18nDataPipe
  ) {}

  ngOnInit(): void {
    this.role = this.data.role;
    this.isNewRole = this.data.isNewRole;
    this.dialogTranslation = this.data.dialogTranslation;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public addRole(): void {
    if (check.not.assigned(this.nameCreateValidation) || this.nameCreateValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close({
      roleData: this.role.data,
    });
    this.showSnackbar(this.keyWordsActions.created);
  }

  public editRole(): void {
    if (check.not.assigned(this.nameEditValidation) || this.nameEditValidation.hasErrors()) {
      return;
    }
    this.dialogRef.close({
      roleData: this.role.data,
    });
    this.showSnackbar(this.keyWordsActions.edited);
  }

  showSnackbar(keyWord: string) {
    const snackbarMsg = this.dialogTranslation[`snackbarSuccessRole${keyWord}`];
    const snackbarTransformed = this.i18nDataPipe.transform(snackbarMsg, { name: this.role.data.name });
    this.snackBar.open(snackbarTransformed, 'OK', { duration: 5000 });
  }

  validateCodeName(currValue: string) {
    this.isCodeValid = currValue?.length !== 1;
  }
}
