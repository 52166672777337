import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IRequestApprovalSummaryModel, TimeOffUserRequestController } from '@app/cloud-features/time-off/services/time-off-user-request.controller';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'orgos-widget-approval',
  templateUrl: 'orgos-widget-approval.component.html',
  styleUrls: ['orgos-widget-approval.component.scss']
})
export class WidgetApprovalComponent implements OnInit {
  translation: any = {};
  approvalSummary: Array<IRequestApprovalSummaryModel> = [];
  approvalSummaryOverdue: Array<IRequestApprovalSummaryModel> = [];
  approvalSummaryPending: Array<IRequestApprovalSummaryModel> = [];
  loadingWidget: boolean = false;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData() {
    this.loadingWidget = true;
    await this.initTranslations();
    await this.initRequests();
    this.loadingWidget = false;
  }

  private async initTranslations() {
    try {
      this.translation = await this.injector.get(InternationalizationService).getAllTranslation('home-widget-approval-component');
    } catch {
      this.translation = {};
    }
  }

  private async initRequests() {
    try {
      this.approvalSummary = await this.injector.get(TimeOffUserRequestController).getRequestApprovalSummary();
      this.groupOverdueRequests();
    } catch {
      this.approvalSummary = [];
    }
  }

  private groupOverdueRequests() {
    this.approvalSummaryOverdue = [];
    this.approvalSummaryPending = [];
    this.approvalSummary.forEach((approvalEntry) => {
      if (approvalEntry.request.overdue) {
        this.approvalSummaryOverdue.push(approvalEntry);
      } else {
        this.approvalSummaryPending.push(approvalEntry);
      }
    });
  }

  public navigateToApprovalRequest(requestId: string): void {
    this.injector.get(Router).navigateByUrl(`cloud/time-off/requests?request=${requestId}`);
  }

  public navigateToApprovalRequestPage(): void {
    this.injector.get(Router).navigateByUrl(`cloud/time-off/requests`);
  }
}
