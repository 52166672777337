import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable()
export class TwoFactorAuthenticationService {
  private SINGLE_SIGN_ON_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/2-factor-authentication-db`;

  constructor(private http: HttpClient, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService) {}

  async getAuthenticationConfig(): Promise<any> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, TwoFactorAuthenticationService.name, 'activateProvider');
        throw this.errorManager.handleRawError(error);
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const authenticationConfig = this.http.get(`${this.SINGLE_SIGN_ON_URL}/config`, httpOptions).toPromise();
      return authenticationConfig;
    } catch (error) {
      throw this.errorManager.handleRawError(error, TwoFactorAuthenticationService.name, 'getAuthenticationConfig');
    }
  }

  async upsertAuthenticationConfig(config: I2FAConfig): Promise<any> {
    try {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, TwoFactorAuthenticationService.name, 'upsertAuthenticationConfig');
        throw this.errorManager.handleRawError(error);
      }

      if (check.not.assigned(config)) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.BAD_REQUEST, TwoFactorAuthenticationService.name, 'upsertAuthenticationConfig');
        throw this.errorManager.handleRawError(error);
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };

      const authenticationConfig = this.http.post(`${this.SINGLE_SIGN_ON_URL}/config`, config, httpOptions).toPromise();
      return authenticationConfig;
    } catch (error) {
      throw this.errorManager.handleRawError(error, TwoFactorAuthenticationService.name, 'upsertAuthenticationConfig');
    }
  }
}

export interface I2FAConfig {
  _id?: string;
  is2FARequired: boolean;
  mode?: string;
  listUserIds?: Array<string>;
  enabledForEveryone: boolean;
  _createdAt?: string;
  _updatedAt?: string;
}
