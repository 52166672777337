<orgos-container aspect="card">
  <mat-tab-group>
    <mat-tab *ngFor="let iLanguage of availableLanguages" [label]="iLanguage.languageLabel">
      <div *ngIf="iLanguage.invitationEmailModel" class="kenjo-p-40px kenjo-border-top">
        <div class="kenjo-font-size-18px">{{pageTranslation.invitationEmailSectionTitle}}</div>
        <div class="kenjo-mt-20px kenjo-mb-30px kenjo-font-color-light-text-757575">{{pageTranslation.invitationEmailSectionDescription}}</div>
        <orgos-input-text-area
          [model]="iLanguage.invitationEmailModel"
          field="value"
          [label]="pageTranslation.invitationEmailSectionTitle"
          [debounceNewValues]="true"
          (modelChange)="updateInvitationEmail(iLanguage.invitationEmailModel, englishInvitationEmailInput)"
          required="true"
          (validation)="iLanguage.invitationEmailValidation = $event"
          class="kenjo-block"
          #englishInvitationEmailInput
        >
          <orgos-input-error *ngIf="iLanguage.invitationEmailValidation && iLanguage.invitationEmailValidation.getError('required')">{{pageTranslation.invitationEmailRequired}}</orgos-input-error>
        </orgos-input-text-area>
      </div>

      <div *ngIf="iLanguage.aboutOrganizationModel" class="kenjo-ph-40px kenjo-pb-40px">
        <div class="kenjo-font-size-18px">{{pageTranslation.aboutOrganizationSectionTitle}}</div>
        <div class="kenjo-mt-20px kenjo-mb-30px kenjo-font-color-light-text-757575">{{pageTranslation.aboutOrganizationSectionDescription}}</div>
        <orgos-input-text-area
          [model]="iLanguage.aboutOrganizationModel"
          field="value"
          [label]="pageTranslation.aboutOrganizationSectionTitle"
          (modelChange)="updateAboutOrganization(iLanguage.aboutOrganizationModel, englishAboutOrganizationInput)"
          required="true"
          (validation)="iLanguage.aboutOrganizationValidation = $event"
          class="kenjo-block"
          #englishAboutOrganizationInput
        >
          <orgos-input-error *ngIf="iLanguage.aboutOrganizationValidation && iLanguage.aboutOrganizationValidation.getError('required')">{{pageTranslation.aboutOrganizationRequired}}</orgos-input-error>
        </orgos-input-text-area>
      </div>
    </mat-tab>
  </mat-tab-group>
</orgos-container>

<orgos-container aspect="card" class="kenjo-p-40px kenjo-mt-20px" *ngIf="allUserPersonal.length > 0">
  <div class="kenjo-font-size-18px">{{pageTranslation.peopleToKnowSectionTitle}}</div>
  <div class="kenjo-mv-20px kenjo-font-color-light-text-757575">{{pageTranslation.peopleToKnowSectionDescription}}</div>
  <orgos-search [placeholder]="pageTranslation.searchPlaceholder" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event">
    <orgos-text class="orgos-search-error" color="Warn" *ngIf="peopleToKnow().length < 6">{{pageTranslation.notFoundPeopleError}}</orgos-text>
    <orgos-text class="orgos-search-error" color="Warn" *ngIf="peopleToKnow().length >= 6">{{pageTranslation.noMoreThan6PeopleToKnowError}}</orgos-text>

    <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result.data" (selected)="addUserToPeopleToKnow(result.data._id)">
      <kenjo-truncate-text>{{result.data.displayName}}</kenjo-truncate-text>
    </orgos-search-option>

    <orgos-column-container wrap="true" class="orgos-search-selection-container">
      <orgos-column size="0" *ngFor="let result of searchResults" class="kenjo-m-10px">
        <orgos-chip [imageUrl]="result.data._photo?._url" buttonIconName="clear" [onClickButtonFunction]="getFunctionDeleteUserFromPeopleToKnow(result.data._id)" class="swwp-search-result">{{result.data.displayName}}</orgos-chip>
      </orgos-column>
    </orgos-column-container>
  </orgos-search>
</orgos-container>
