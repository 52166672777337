import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-row-selection',
  templateUrl: 'row-selection.component.html',
  styleUrls: ['row-selection.component.scss']
})
export class RowSelectionComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() selected: boolean = false;
  @Input() id: string;
  @Input() groupName: string;
  @Input() readOnly: boolean = false;

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  selectClick(): void {
    this.select.emit(this.id);
  }
}
