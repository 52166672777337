import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CumulService {
  private CUMUL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/bi/cumul`;

  constructor(private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  public getSSOAuth(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .get(`${this.CUMUL_URL}/sso-auth`, httpOptions)
        .toPromise()
        .then((ssoAuth: any) => {
          resolve(ssoAuth);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CumulService.name, 'getSSOAuth'));
        });
    });
  }

  public isTest(): boolean {
    return environment.CUMUL_IS_TEST_ENVIRONMENT;
  }

  async cacheDataSets() {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      await this.http.get(`${this.CUMUL_URL}/cache-datasets`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, CumulService.name, 'cacheDataSets');
    }
  }

  async syncVariants() {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };

      await this.http.put(`${this.CUMUL_URL}/sync-variants`, httpOptions).toPromise();
    } catch (error) {
      throw this.errorManager.handleRawError(error, CumulService.name, 'syncVariants');
    }
  }
}
