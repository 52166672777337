import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';

@Component({
  selector: 'kenjo-icon-highlight',
  templateUrl: 'icon-highlight.component.html',
  styleUrls: ['icon-highlight.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, IconModule],
})
export class IconHighlightComponent implements OnInit, OnChanges {
  @Input() icon: string;
  @Input() neutralColor: boolean = false;
  @Input() semantic: 'brand' | 'info' | 'danger' | 'warning' | 'caution' | 'neutral' | 'user' = 'neutral';
  @Input() size: 's' | 'm' | 'l' | 'xl' | '2xl' = 'm';
  @Input() userColor:
    | 'USER_COLOR_1'
    | 'USER_COLOR_2'
    | 'USER_COLOR_3'
    | 'USER_COLOR_4'
    | 'USER_COLOR_5'
    | 'USER_COLOR_6'
    | 'USER_COLOR_7'
    | 'USER_COLOR_8'
    | 'USER_COLOR_9'
    | 'USER_COLOR_10'
    | 'USER_COLOR_11'
    | 'USER_COLOR_12';
  @Input() hoverable: false;

  SEMANTIC_CLASSES = {
    brand: '--semantic-brand',
    info: '--semantic-info',
    danger: '--semantic-danger',
    warning: '--semantic-warning',
    caution: '--semantic-caution',
    neutral: '--semantic-neutral',
    'user-light': '--semantic-user-light',
  };

  USER_COLOR_CLASSES = {
    USER_COLOR_1: '--semantic-user-color-1',
    USER_COLOR_2: '--semantic-user-color-2',
    USER_COLOR_3: '--semantic-user-color-3',
    USER_COLOR_4: '--semantic-user-color-4',
    USER_COLOR_5: '--semantic-user-color-5',
    USER_COLOR_6: '--semantic-user-color-6',
    USER_COLOR_7: '--semantic-user-color-7',
    USER_COLOR_8: '--semantic-user-color-8',
    USER_COLOR_9: '--semantic-user-color-9',
    USER_COLOR_10: '--semantic-user-color-10',
    USER_COLOR_11: '--semantic-user-color-11',
    USER_COLOR_12: '--semantic-user-color-12',
  };

  iconSize: number = 12;
  iconColor: string;

  ngOnInit(): void {
    this.computeIconSize();
    this.computeIconColor();
  }

  ngOnChanges() {
    this.computeIconSize();
    this.computeIconColor();
  }

  private computeIconSize(): void {
    switch (this.size) {
      case 's':
        this.iconSize = 16;
        break;
      case 'm':
        this.iconSize = 24;
        break;
      case 'l':
        this.iconSize = 30;
        break;
      case 'xl':
        this.iconSize = 40;
        break;
      case '2xl':
        this.iconSize = 60;
        break;
    }
  }

  private computeIconColor() {
    if (this.semantic === 'user') {
      this.iconColor = this.USER_COLOR_CLASSES[this.userColor];
    } else {
      this.iconColor = this.SEMANTIC_CLASSES[this.semantic];
    }
  }
}
