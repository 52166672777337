import { CdkOverlayOrigin, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  selector: 'orgos-input-simple-emoji',
  templateUrl: 'input-simple-emoji.component.html',
  styleUrls: ['input-simple-emoji.component.scss']
})
export class InputSimpleEmojiComponent implements OnInit {
  miscTranslation: any = {};

  private _emojisSelected: Array<IEmojiModel>;
  @Input()
  set emojisSelected(value: Array<IEmojiModel>) {
    if (check.not.assigned(value)) {
      return;
    }

    if (check.assigned(value)) {
      this._emojisSelected = value;
    }
  }
  get emojisSelected(): Array<IEmojiModel> {
    return this._emojisSelected;
  }

  @Input() readOnly: boolean = false;

  @Output() newEmoji: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeEmoji: EventEmitter<string> = new EventEmitter<string>();

  emojiPickerContainerOverlayRef: OverlayRef = null;
  @ViewChild(CdkPortal, { static: true }) emojiPickerContainer: Portal<any>;
  @ViewChild(CdkOverlayOrigin, { static: true }) inputOrigin: CdkOverlayOrigin;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  openEmojiPicker(): void {
    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.minWidth = this.inputOrigin.elementRef.nativeElement.getBoundingClientRect().width;
    config.backdropClass = 'isetc-emoji-overlay';
    config.positionStrategy = this.injector
      .get(Overlay)
      .position()
      .flexibleConnectedTo(this.inputOrigin.elementRef)
      .withPositions([
        //{ originX: 'end', originY: 'top', overlayX: 'center', overlayY: 'bottom' }, // place it on top
        { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' }, // place it at the left
        { originX: 'end', originY: 'bottom', overlayX: 'center', overlayY: 'top' } // fallback: place overlay at the bottom
      ])
      .withPush(false);

    this.emojiPickerContainerOverlayRef = this.injector.get(Overlay).create(config);
    this.emojiPickerContainerOverlayRef.attach(this.emojiPickerContainer);

    this.emojiPickerContainerOverlayRef.backdropClick().subscribe(() => {
      this.emojiPickerContainerOverlayRef.dispose();
    });
  }

  emojiClick(emoji: IEmojiModel): void {
    if (this.readOnly === true) {
      return;
    }

    if (emoji.isEditable) {
      this.deleteEmoji(emoji.emojiIcon);
    } else {
      this.addEmoji(emoji.emojiIcon, false);
    }
  }

  addEmoji(emojiIcon: string, disposeOverlay: boolean = true): void {
    if (this.readOnly === true) {
      return;
    }

    if (disposeOverlay) {
      this.emojiPickerContainerOverlayRef.dispose();
    }
    this.newEmoji.emit(emojiIcon);
  }

  private deleteEmoji(emojiIcon: string): void {
    if (this.readOnly === true) {
      return;
    }

    this.removeEmoji.emit(emojiIcon);
  }
}

export interface IEmojiModel {
  tooltip?: string;
  isEditable?: boolean;
  numberClicks?: number;
  emojiIcon: string;
}
