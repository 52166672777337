import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable()
export class UserService {
  private USER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user`;

  constructor(private genericService: GenericService, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService, private injector: Injector) {}

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_URL);
  }

  getUser(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserService.name, 'getUser');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const findBody = {
        _id: id
      };
      this.http
        .post(`${this.USER_URL}/findFullUser`, findBody, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserService.name, 'getUser'));
        });
    });
  }

  // If queryAllFields is set to true, it will include all the fields in the response
  getUserDetail(id: string, queryAllFields: boolean = false): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserService.name, 'getUserDetail');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      let endPoint = `${this.USER_URL}/detail`;
      if (queryAllFields) {
        endPoint = `${this.USER_URL}/complete/detail`;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const findBody = {
        _id: id
      };
      this.http
        .post(endPoint, findBody, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserService.name, 'getUserDetail'));
        });
    });
  }

  getAllUsersFull(activeUsersOnly: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, UserService.name, 'getAllUsersFull');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const findBody = {
        _id: { $ne: null }
      };
      this.http
        .post(`${this.USER_URL}/findFullUsers`, findBody, httpOptions)
        .toPromise()
        .then((responseData: Array<any>) => {
          if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(activeUsersOnly, true) && check.assigned(responseData) && check.array(responseData) && check.nonEmptyArray(responseData)) {
            const olnyActiveUsers = responseData.filter((iFullUser) => {
              return check.assigned(iFullUser.userAccount.isActive) && check.equal(iFullUser.userAccount.isActive, true);
            });
            resolve(olnyActiveUsers);
          } else {
            resolve(responseData);
          }
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserService.name, 'getAllUsersFull'));
        });
    });
  }
}
