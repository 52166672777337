import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-disabled-app-dialog',
  templateUrl: './disabled-app.dialog.html',
  styleUrls: ['./disabled-app.dialog.scss'],
})
export class DisabledAppDialog implements OnInit {
  translations: any = {};

  constructor(private injector: Injector, private dialogRef: MatLegacyDialogRef<DisabledAppDialog>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.loadTranslations();
  }

  private async loadTranslations(): Promise<void> {
    this.translations = await this.injector.get(InternationalizationService).getAllTranslation('platform-usage-dialog');
  }

  buttonClicked(): void {
    this.dialogRef.close();
  }

  callToActionClicked(): void {
    this.injector.get(Router).navigateByUrl('cloud/settings/overview');
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
