import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanTemplateService implements IGenericService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-template-db`;
  private USER_WORK_PERMISSIONS_KEY: string = 'user-work';
  private USER_WORK_INTERNATIONALIZATION: string = 'user-work-collection';
  private SHIFTPLAN_TEMPLATE_SERVICE: string = 'ShiftPlanTemplateService';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  getById(id: string, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  create(body: any, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async getTemplates(findQuery = { _id: { $ne: null } }): Promise<Array<IShiftPlanTemplate>> {
    try {
      return await this.genericService.find(this.MICROSERVICE_URL, findQuery);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_TEMPLATE_SERVICE, 'getTemplates');
    }
  }

  async createTemplate(data: object): Promise<IShiftPlanTemplate> {
    try {
      return await this.genericService.create(this.MICROSERVICE_URL, data);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_TEMPLATE_SERVICE, 'create');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.MICROSERVICE_URL, id, data);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_TEMPLATE_SERVICE, 'updateById');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  async deleteById(id: string): Promise<void> {
    try {
      await this.genericService.deleteById(this.MICROSERVICE_URL, id);
    } catch (e) {
      const error = new OrgosError(this.MICROSERVICE_URL, ErrorCodes.CONFLICT, this.SHIFTPLAN_TEMPLATE_SERVICE, 'deleteById');
      error.message = e.error;
      throw this.errorManager.handleParsedError(error);
    }
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_WORK_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_WORK_INTERNATIONALIZATION);
  }
}

export interface IShiftPlanTemplate {
  _id?: string;
  s_orgId?: string;
  name: string;
  start: number;
  end: number;
  break?: number;
  locationId: string;
  workingAreaId?: string;
  roleId: string;
  tagId?: string;
  notes?: string;
  roleName?: string;
  roleColor?: string;
  locationName?: string;
  workingAreaName?: string | undefined;
  tagName?: string | undefined;
  ownerId?: string;
  approvalRule?: 'requiresApproval' | 'autoAssign';
  _createdAt: Date;
}

export interface IShiftplanTemplatePreference {
  sortBy: 'name' | '_createdAt';
  sortOrder: 'asc' | 'desc';
  search: string;
  applyFilters: boolean;
}

interface IShiftplanSortTooltip {
  _createdAt: string;
  name: string;
}

export interface IShiftplanTemplateSortOptions {
  SORT_BY: {
    createdAt: '_createdAt';
    name: 'name';
  };
  SORT_ORDER: {
    asc: { icon: string; value: 'asc'; tooltips: IShiftplanSortTooltip };
    desc: { icon: string; value: 'desc'; tooltips: IShiftplanSortTooltip };
  };
}
