<orgos-dialog-container *ngIf="dialogTranslation" class="cdd-dialog-box" [title]="shiftsLimitReached ? dialogTranslation.shiftDeletionLimitTitle : dialogTranslation.clearScheduleDialogTitle" [disabledCloseButton]="isDeleting" (clickCloseButton)="closeDialog()">
  <orgos-warning-message *ngIf="filters && (filters.locations.length > 0 || filters.employees.length > 0 || filters.roles.length > 0)" class="kenjo-mb-30px">{{dialogTranslation.filtersAppliedClearSchedule}}</orgos-warning-message>
  <orgos-column-container *ngIf="!shiftsLimitReached" class="kenjo-font-color-light-text-757575 cdd-white-space">
    <div orgosColumn class="kenjo-border-bottom kenjo-pb-20px kenjo-mb-30px kenjo-mt-20px">{{ dialogTranslation.changesNotNotified }}</div>
  </orgos-column-container>
  <orgos-column-container *ngIf="shiftsLimitReached" class="kenjo-font-color-light-text-757575 cdd-white-space">
    <div orgosColumn class="kenjo-border-bottom kenjo-pb-20px kenjo-mb-30px">{{ (dialogTranslation.shiftDeletionLimitSubtitle | i18nData : { shiftsLimit : SHIFTS_LIMIT_TO_DELETE_CUSTOM }) }}</div>
  </orgos-column-container>
  <ng-container *ngIf="shiftsCount.scheduled > 0 || shiftsCount.open > 0">
    <div class="kenjo-font-weight-bold">{{dialogTranslation.deleteShiftSummary}}</div>
    <div class="kenjo-mt-10px">{{ (dialogTranslation.unpublishedScheduled | i18nData: { shiftsNumber: shiftsCount.scheduled })}}</div>
    <div class="kenjo-mt-5px">{{ (dialogTranslation.unpublishedOpen | i18nData: { shiftsNumber: shiftsCount.open })}}</div>
    <div class="kenjo-font-weight-bold kenjo-mt-20px">{{ (dialogTranslation.totalSummary | i18nData: { shiftsNumber: totalShifts })}}</div>
  </ng-container>

  <orgos-column-container *ngIf="isDeleting" class="cdd-loading-container" centerColumns="true">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-loading-spinner></orgos-loading-spinner>
    </orgos-column>
    <div orgosColumn="0" class="kenjo-pl-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{dialogTranslation.deletingLoading}}</div>
  </orgos-column-container>
  <orgos-column-container *ngIf="!isDeleting" class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0" *ngIf="!shiftsLimitReached">
      <orgos-button-raised (click)="dialogRef.close()" color="Neutral">{{dialogTranslation.goBack}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px" *ngIf="!shiftsLimitReached">
      <orgos-button-raised [disabled]="totalShifts === 0" (click)="deleteShifts()" color="Danger">{{dialogTranslation.yesDelete}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px" *ngIf="shiftsLimitReached">
      <orgos-button-raised (click)="closeDialog()" color="Success">{{dialogTranslation.okGotIt}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
