<div class="er-score-title" [style.color]="currentRange.color">{{ getStringValue() }}</div>
<div class="kenjo-font-size-18px" [style.color]="currentRange.color">{{ pageTranslation[currentRange.key] }}</div>
<div class="kenjo-pt-10px">
  <input id="surveySlider" type="range" [min]="min" [max]="max" class="er-slider" name="surveySlider" [(ngModel)]="value" disabled="true" [ngClass]="'er-slider-bubble-' + currentRange.key" />
  <orgos-column-container class="er-slider-legend">
    <orgos-column size="1" class="er-left-col">{{ min }}</orgos-column>
    <orgos-column size="1" class="er-middle-col">{{ getMiddleValue() }}</orgos-column>
    <orgos-column size="1" class="er-right-col">{{ max }}</orgos-column>
  </orgos-column-container>
</div>
