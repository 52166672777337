import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-org-chart-rename-view-dialog',
  templateUrl: 'org-chart-rename-view.dialog.html',
  styleUrls: ['org-chart-rename-view.dialog.scss'],
})
export class OrgChartRenameViewDialog implements OnInit {
  i18n: any;
  nameValidation: InputValidation;

  viewName: string = '';

  constructor(
    public dialogRef: MatLegacyDialogRef<OrgChartRenameViewDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data
  ) {}

  ngOnInit(): void {
    this.viewName = this.data.name;

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('org-chart-page')
      .then((translation) => {
        this.i18n = translation;
      })
      .catch(() => {
        this.i18n = null;
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveView(): void {
    if (!this.isDataValid()) {
      return;
    }
    this.dialogRef.close(this.viewName);
  }

  private isDataValid(): boolean {
    return check.assigned(this.nameValidation) && !this.nameValidation.hasErrors();
  }
}
