<orgos-column-container *ngIf="roles?.length > 0" [centerColumns]>
  <orgos-column class="spf-roles-container">
    <div *ngFor="let role of roles" class="spf-name-chip" [ngStyle]="color ? { 'background-color': colorConstants[role.colour] } : {}">
      <div>{{ role.codeName || (role.name | slice: 0:2) }} - {{ role.name }}</div>
    </div>
  </orgos-column>
  <orgos-column size="0" class="kenjo-pr-20px spf-kenjo-logo-col">
    <div orgosColumn="0" class="spf-kenjo-logo">
      <img src="/assets/images/kenjoLogoHorizontalBlack.svg" />
    </div>
  </orgos-column>
</orgos-column-container>

<div *ngIf="roles?.length === 0" class="spf-kenjo-logo">
  <img src="/assets/images/kenjoLogoHorizontalBlack.svg" />
</div>
