import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from './../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class MeetingTemplateService {
  private MEETING_TEMPLATE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/meeting-template-db`;
  private MEETING_TEMPLATE_PERMISSIONS_KEY: string = 'meeting-template';
  private MEETING_TEMPLATE_INTERNATIONALIZATION: string = 'meeting-template-collection';

  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any): Promise<IMeetingTemplateModel> {
    return new Promise<IMeetingTemplateModel>((resolve, reject) => {
      this.genericService
        .create(this.MEETING_TEMPLATE_URL, data)
        .then((template: IMeetingTemplateModel) => {
          resolve(template);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingTemplateService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IMeetingTemplateModel>> {
    return new Promise<Array<IMeetingTemplateModel>>((resolve, reject) => {
      this.genericService
        .find(this.MEETING_TEMPLATE_URL, findQuery)
        .then((templates: Array<IMeetingTemplateModel>) => {
          resolve(templates);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingTemplateService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<IMeetingTemplateModel> {
    return new Promise<IMeetingTemplateModel>((resolve, reject) => {
      this.genericService
        .getById(this.MEETING_TEMPLATE_URL, id)
        .then((template: IMeetingTemplateModel) => {
          resolve(template);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingTemplateService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IMeetingTemplateModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.MEETING_TEMPLATE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingTemplateService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.MEETING_TEMPLATE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingTemplateService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.MEETING_TEMPLATE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.MEETING_TEMPLATE_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.MEETING_TEMPLATE_URL);
  }

  getAllTemplates(): Promise<Array<IMeetingTemplateModel>> {
    return new Promise<Array<IMeetingTemplateModel>>((resolve, reject) => {
      const findQuery = {};
      findQuery[fieldConstants.ID] = { $ne: null };
      this.genericService
        .find(this.MEETING_TEMPLATE_URL, findQuery)
        .then((templates: Array<IMeetingTemplateModel>) => {
          resolve(templates);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, MeetingTemplateService.name, 'find'));
        });
    });
  }
}

export interface IMeetingTemplateModel {
  _id?: string;
  name?: string;
  type?: 'live' | 'async';
  isDefault?: boolean;
  agenda?: Array<{ type: 'text' | 'list' | 'question' | 'toDo'; content: any; name: string; participantCanAdd: boolean; completed: boolean; _id?: string }>;
  participants?: Array<any>;
  _createdAt?: Date;
  _createdById?: string;
}
