import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import * as check from 'check-types';

import { ISelectOption } from '../../core/select-option';

@Component({
  selector: 'kenjo-search-item-dropdown',
  templateUrl: './search-item-dropdown.component.html',
  styleUrls: ['./search-item-dropdown.component.scss']
})
export class SearchItemDropdownComponent implements OnInit, AfterViewInit {
  filteredOptions: Array<ISelectOption> = [];
  activeOptionValue: string = null;

  @ViewChild('inputSearch') inputSearch: ElementRef;

  @Input() placeholder: string;
  @Input() actionText: string;
  @Input() emptyOptionsText: string;
  @Input() width: string = '260px';
  @Input() autofocus: boolean = false;
  @Input() autoOrder: boolean = true;

  _selectableOptions: Array<ISelectOption>;
  get selectableOptions(): any {
    return this._selectableOptions;
  }
  @Input() set selectableOptions(value) {
    this._selectableOptions = value;
    this.filteredOptions = value;
  }

  @Output() selectOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() handleAction: EventEmitter<void> = new EventEmitter<void>();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.selectableOptions = this.selectableOptions.sort((a, b) => {
      if (!a?.name) {
        return -1;
      }

      if (!b.name) {
        return 1;
      }

      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 1;
    });
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this.inputSearch?.nativeElement) {
      this.setFocus();
    }
  }

  private setFocus(): void {
    this.injector.get(NgZone).runOutsideAngular(() => {
      Promise.resolve().then(() => {
        this.inputSearch.nativeElement.focus();
      });
    });
  }

  emitSelection(id: string): void {
    this.selectOption.emit(id);
  }

  emitAction(): void {
    this.handleAction.emit();
  }

  searchByName(term: any): void {
    this.activeOptionValue = null;

    if (check.not.assigned(term) || check.emptyString(term)) {
      this.filteredOptions = this.selectableOptions;
      return;
    }

    this.filteredOptions = this.selectableOptions.filter((iOption: any) => {
      const regExp = new RegExp(`^.*${term}.*$`, 'i');
      return regExp.test(iOption.name);
    });

    this.activeOptionValue = this.filteredOptions.length > 0 ? this.filteredOptions[0].value : null;
  }

  // To enable keyboard actions
  navigateOptions(direction: 'up' | 'down'): void {
    if (!this.filteredOptions?.length) {
      return;
    }

    if (!this.activeOptionValue) {
      this.activeOptionValue = this.filteredOptions[0].value;
      return;
    }

    let newIndex: number;
    const currentIndex: number = this.filteredOptions.findIndex(option => option.value === this.activeOptionValue);
    if (currentIndex < 0) {
      return;
    }

    if (direction === 'up') {
      newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    } else if (direction === 'down') {
      newIndex = currentIndex < this.filteredOptions.length - 1 ? currentIndex + 1 : currentIndex;
    }

    this.activeOptionValue = this.filteredOptions[newIndex].value;
  }

  keyEnter(): void {
    if (this.activeOptionValue) {
      this.emitSelection(this.activeOptionValue);
    }
  }
}
