<orgos-column-container *ngIf="messageText || contentProjection === true" class="kenjo-p-10px" centerColumns="true">
  <kenjo-icon orgosColumn="0" *ngIf="messageType === 'warning'" [size]="18" class="kenjo-font-color-warning-ffc107">warning_outline</kenjo-icon>
  <kenjo-icon orgosColumn="0" *ngIf="messageType === 'error'" [size]="18" class="kenjo-font-color-danger-ff5757">error_outline</kenjo-icon>
  <kenjo-icon orgosColumn="0" *ngIf="messageType === 'info'" [size]="18" class="kenjo-font-color-success-00b72e">info_outline </kenjo-icon>
  <kenjo-icon orgosColumn="0" *ngIf="messageType === 'primary'" [size]="18" class="kenjo-font-color-primary-5993e3">info_outline </kenjo-icon>
  <orgos-column size="1" class="kenjo-ml-10px">
    <div *ngIf="messageText && contentProjection === false">{{ messageText }}</div>
    <ng-content *ngIf="contentProjection === true"></ng-content>
  </orgos-column>
</orgos-column-container>
