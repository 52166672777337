import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as _ from 'lodash';

@Pipe({
  name: 'timeShiftplan',
  pure: false
})
export class TimeShiftPlanPipe implements PipeTransform {
  private latestRawValue: number;
  private latestComputedValue: string | null = null;

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number, shiftTimeFormat: boolean = false, breakTimeFormat: boolean = false): string | null {
    if (check.not.assigned(value) || check.not.number(value)) {
      return null;
    }

    if (_.isEqual(this.latestRawValue, value)) {
      return this.latestComputedValue;
    }

    this.latestRawValue = value;

    if (shiftTimeFormat === true) {
      this.latestComputedValue = this.convertMinutesToStringTime(value, true);
      return this.latestComputedValue;
    }
    if (breakTimeFormat === true) {
      this.latestComputedValue = this.convertMinutesToStringTime(value, false, true);
      return this.latestComputedValue;
    }

    this.latestComputedValue = this.convertMinutesToStringTime(value);
    return this.latestComputedValue;
  }

  convertMinutesToStringTime(minutesValue: number, shiftTimeFormat: boolean = false, breakTimeFormat: boolean = false): string {
    if (check.not.assigned(minutesValue)) {
      return undefined;
    }

    const hours = Math.floor(minutesValue / 60);
    const minutes = minutesValue % 60;

    const hoursFormatted = this.decimalPipe.transform(hours, '2.0-0');
    const minutesFormatted = this.decimalPipe.transform(minutes, '2.0-0');

    if (shiftTimeFormat === true) {
      return `${hoursFormatted}:${minutesFormatted}`;
    }

    if (breakTimeFormat === true) {
      return minutesValue < 60 ? `${minutes} min` : minutes === 0 ? `${hours} h` : `${hours} h ${minutes} min`;
    }

    if (minutes === 0) {
      return `${hours}h`;
    }

    return `${hours}h ${minutesFormatted}m`;
  }
}
