import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IAttendanceCategory } from '@app/cloud-features/settings-attendance/services/attendance-category.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';

@Component({
  selector: 'kenjo-attendance-category-select',
  templateUrl: 'attendance-category-select.component.html',
  styleUrls: ['attendance-category-select.component.scss']
})
export class AttendanceCategorySelectComponent implements OnInit {
  @Input() categories: Array<IAttendanceCategory>;
  @Input() readOnly: boolean = false;
  @Input() createShiftsFromCategory: boolean = false;
  @Input() showShiftsFromCategoryTooltips: boolean = false;
  @Input() chosenCategoryId: string; // note that this could be the id of a subCategory, too
  @Input() label: string; // if informed, will add a label to the selectoption
  @Output() valueChange: EventEmitter<IAttendanceCategory> = new EventEmitter<IAttendanceCategory>();
  @Output() newShift: EventEmitter<IAttendanceCategory> = new EventEmitter<IAttendanceCategory>();

  chosenCategory: IAttendanceCategory;
  colors: any = userColorConstants;
  miscTranslations: any = {};
  categoryTooltips: {[categoryId: string]: {newShift: string, currentShift: string}};

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.fetchTranslations();
    this.initCategoryHierarchy();
  }

  private async fetchTranslations() {
    this.miscTranslations = await this.injector.get(InternationalizationService).getMiscTranslation();

    if (this.showShiftsFromCategoryTooltips && this.categories?.length) {
      this.initTooltipTranslations();
    }
  }

  private initTooltipTranslations(): void {
    this.categoryTooltips = {};

    this.categories.forEach((category: IAttendanceCategory) => {
      const categoryTooltip = {
        newShift: this.injector.get(I18nDataPipe).transform(this.miscTranslations.attendanceCategorySelectNewShift, { categoryName: category.name }),
        currentShift: this.injector.get(I18nDataPipe).transform(this.miscTranslations.attendanceCategoryUpdate, { categoryName: category.name })
      };

      this.categoryTooltips[category._id] = categoryTooltip;
    });
  }

  private initCategoryHierarchy(): void {
    if (!this.categories) {
      return;
    }

    if (this.chosenCategoryId) {
      this.initChosenCategory();
    }
  }

  private initChosenCategory() {
    if (!this.chosenCategoryId) {
      return;
    }
    this.chosenCategory = this.categories.find((category: IAttendanceCategory) => {
      return category._id === this.chosenCategoryId;
    });

    // maybe the chosenCategory is not among the list of categories,
    // for example, because that chosenCategory is not available to this user anymore
    // in that case, clear the chosenCategoryId
    if (!this.chosenCategory) {
      this.chosenCategoryId = null;
    }
  }

  newShiftForCategory(category: IAttendanceCategory): void {
    this.newShift.emit(category);
    this.chosenCategoryId = category._id;
    this.chosenCategory = category;
  }

  selectCategory(category: IAttendanceCategory): void {
    this.chosenCategoryId = category._id;
    this.chosenCategory = category;
    this.valueChange.emit(this.chosenCategory);
  }

  clearValue(): void {
    this.chosenCategoryId = null;
    this.chosenCategory = null;
    this.valueChange.emit(null);
  }
}
