<div
  *ngIf="wishDay"
  @fadeInOutAnimation
  #wishDayContainer
  class="swd-container"
  [class.hidden]="!emptyShifts"
  [ngClass]="wishDayType === SHIFT_PLAN_AVAILABILITY ? 'available' : 'not-available'"
>
  <kenjo-icon *ngIf="!(isMinimal && wishDay.time)" [size]="24">event_available</kenjo-icon>
  <span *ngIf="(label.show && !isMinimal) || view === VIEWS.WEEK" class="kenjo-block kenjo-font-size-12px kenjo-text-align-center">{{ label.text }}</span>
  <div class="kenjo-font-size-12px" *ngIf="wishDay.time">
    <div>{{ wishDay.time?.start }}</div>
    <div>{{ wishDay.time?.end }}</div>
  </div>
</div>
