import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';

import { ISelectOption } from '../../core/select-option';
import { InputValidation } from '../../core/validation/input-validation';
import { PicklistsService } from '../../services/core/picklists.service';
import { ErrorManagerService } from '../../services/error/error-manager.service';
import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-select',
  templateUrl: 'input-select.component.html',
  styleUrls: ['input-select.component.scss']
})
export class InputSelectComponent extends InputAbstractComponent implements OnInit {
  showClearButton: boolean = false;
  @Input() multiple = false;
  private _originalOptions: Array<ISelectOption> = [];
  private _options: Array<ISelectOption> = [];
  @Input()
  set options(options: Array<ISelectOption>) {
    if (check.not.assigned(options)) {
      this._originalOptions = [];
      this.applySort();
      return;
    }

    this._originalOptions = options;
    this.applySort();
  }
  get options(): Array<ISelectOption> {
    return this._options;
  }

  @Input()
  set hideAutoNumber(hideAutoNumber: boolean) {
    if (hideAutoNumber) {
      this.options = this.options.filter(iOption => iOption.value !== 'AutoNumber');
    }
  }

  @Input()
  set picklist(picklistName: string) {
    this.picklistService
      .getPicklist(picklistName)
      .then((picklist: object) => {
        this.options = Object.keys(picklist).reduce((tempOptions: Array<ISelectOption>, value: string) => {
          const picklistOption: ISelectOption = {
            name: picklist[value],
            value: value
          };
          tempOptions.push(picklistOption);

          return tempOptions;
        }, []);
      })
      .catch((err) => {
        this.options = [];
      });
  }

  @Input() enableClearButton: boolean = false;

  private _sortOptions: boolean = true;
  @Input()
  set sortOptions(sortOptions: boolean) {
    this._sortOptions = sortOptions;
    this.applySort();
  }
  get sortOptions(): boolean {
    return this._sortOptions;
  }

  @Input() leftDot = false;


  constructor(cdr: ChangeDetectorRef, injector: Injector, private picklistService: PicklistsService) {
    super(cdr, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.showClearButton = this.enableClearButton && check.assigned(this.getValue());
  }

  setValue(newValue: string): void {
    super.setValue(newValue);

    this.showClearButton = this.enableClearButton && check.assigned(newValue);
  }

  getValueName(): string {
    const value = super.getValue();

    if (check.not.assigned(value)) {
      return '';
    }

    if (this.multiple) {
      const options = this.options.filter((optionItem) => {
        return value.includes(optionItem.value);
      });
      const optionNames = options.map((o) => {
        return o.name;
      });
      return optionNames.join(', ');
    }
    const option = this.options.find((optionItem: ISelectOption) => {
      return optionItem.value === value;
    });

    if (check.not.assigned(option)) {
      return '';
    }

    return option.name;
  }

  private applySort(): void {
    if (this.sortOptions === false) {
      this._options = this._originalOptions.slice();
      return;
    }

    this._options = this._originalOptions.slice().sort((optionA: ISelectOption, optionB: ISelectOption) => {
      const nameA = optionA.name.toLowerCase();
      const nameB = optionB.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
