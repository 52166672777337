import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class UserEmergencyService implements IGenericService {
  private USER_EMERGENCY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-emergency-db`;
  private USER_EMERGENCY_PERMISSIONS_KEY: string = 'user-emergency';
  private USER_EMERGENCY_INTERNATIONALIZATION: string = 'user-emergency-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IUserEmergencyModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserEmergencyService.name, 'create');
    error.message = 'UserEmergency should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserEmergencyModel> {
    return new Promise<IUserEmergencyModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_EMERGENCY_URL, id)
        .then((userEmergency: IUserEmergencyModel) => {
          resolve(userEmergency);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmergencyService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_EMERGENCY_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmergencyService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserEmergencyService.name, 'deleteById');
    error.message = 'UserEmergency should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  find(findBody: any): Promise<Array<IUserEmergencyModel>> {
    return new Promise<Array<IUserEmergencyModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_EMERGENCY_URL, findBody)
        .then((userEmergency: Array<IUserEmergencyModel>) => {
          resolve(userEmergency);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmergencyService.name, 'find'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_EMERGENCY_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_EMERGENCY_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_EMERGENCY_URL);
  }
}

export interface IUserEmergencyModel {
  _id: string;
  emergencyFirstName?: string;
  emergencyMiddleName?: string;
  emergencyLastName?: string;
  relation?: string;
  emergencyPhoneNumber?: string;
  emergencyMobileNumber?: string;
  emergencyEmail?: string;
  emergencyAddress?: string;
  emergencyCity?: string;
  emergencyPostCode?: string;
  emergencyCountry?: string;
}
