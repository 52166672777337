<orgos-column-container centerColumns="true" class="pdpn-top-bar kenjo-border-bottom kenjo-ph-20px">
  <orgos-column size="0">
    <kenjo-icon size="20" [routerLink]="previousUrl" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
  </orgos-column>

  <orgos-column size="0" class="pdpn-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

  <orgos-column size="0" class="kenjo-font-size-16px">{{ pageTranslations.pageName }}</orgos-column>

  <orgos-column size="0" class="pdpn-separator kenjo-mh-20px kenjo-border-left"></orgos-column>
</orgos-column-container>

<orgos-container aspect="card" class="pdpn-main-container kenjo-mt-20px kenjo-p-30px">
  <div class="kenjo-font-size-18px">{{ pageTranslations.pageName }}</div>
  <div *ngIf="canEdit" class="pdpn-info-container">
    <kenjo-icon [size]="20" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
    <div class="kenjo-ml-10px kenjo-font-size-14px kenjo-font-color-light-text-757575">
      {{ hasNominationsLimit ? (pageTranslations.nominationsLimitMessage | i18nData: { min: nominationsLimit.min, max: nominationsLimit.max
      }) : pageTranslations.infoMessage }}
    </div>
  </div>

  <orgos-search
    *ngIf="!loading && canEdit"
    [disabled]="hasNominationsLimit && nominations.length === nominationsLimit.max"
    [tooltipMessage]="pageTranslations.maxNominationsTooltip"
    class="pdpn-search-bar kenjo-cursor-pointer"
    [alternativeStyle]="true"
    icon="search"
    [placeholder]="pageTranslations.searchForEmployeesLabel"
    [searchFunction]="searchUserFunction"
    (searchResultsChange)="searchResults = $event"
  >
    <orgos-text class="orgos-search-error" color="Warn">{{ pageTranslations.usersNotFound }}</orgos-text>

    <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="nominateUser(result)">
      <kenjo-truncate-text>{{ result.displayName }}</kenjo-truncate-text>
    </orgos-search-option>
  </orgos-search>

  <!-- Loading -->
  <div *ngIf="loading" class="kenjo-text-align-center kenjo-mv-120px">
    <orgos-loading-spinner></orgos-loading-spinner>
  </div>

  <!-- Nominations table -->
  <orgos-container aspect="card" *ngIf="!loading && nominations.length > 0" class="kenjo-mt-20px pdpn-nominations-table">
    <orgos-table [data]="nominations" [displayedColumns]="columns" [stickyColumn]="false" [stickyRow]="false">
      <orgos-table-column columnKey="name" [sortable]="false" [header]="pageTranslations.nameHeader">
        <orgos-column-container *orgosTableCell="let row" centerColumns="true">
          <orgos-avatar
            orgosColumn="0"
            [avatarName]="row.user.displayName"
            [photoUrl]="row.user._photo?._url"
            size="small"
            class="kenjo-cursor-pointer kenjo-mv-10px"
          ></orgos-avatar>
          <a orgosColumn="1" class="kenjo-ml-10px" (click)="openEmployeeDetail(row.user._id)">
            <kenjo-truncate-text>{{row.user.displayName}}</kenjo-truncate-text>
          </a>
        </orgos-column-container>
      </orgos-table-column>
      <orgos-table-column columnKey="job" [sortable]="false" [header]="pageTranslations.jobHeader">
        <kenjo-truncate-text *orgosTableCell="let row">{{ row.user.jobTitle }}</kenjo-truncate-text>
      </orgos-table-column>
      <orgos-table-column columnKey="department" [sortable]="false" [header]="pageTranslations.departmentHeader">
        <div *orgosTableCell="let row">{{ row.user.departmentName }}</div>
      </orgos-table-column>
      <orgos-table-column columnKey="action" [sortable]="false" [header]="pageTranslations.actionHeader">
        <div *orgosTableCell="let row">
          <orgos-button-raised
            [disabled]="!canEdit || performanceReviewStatus === PERFORMANCE_REVIEW_STATUS_RUNNING"
            (click)="removeNomination(row.user._id)"
            color="Danger"
            >{{ pageTranslations.removeButton }}</orgos-button-raised
          >
        </div>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>

  <!-- No nominations -->
  <div *ngIf="!loading && nominations.length === 0">
    <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyPerformanceData.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{pageTranslations.noNominationsTitle}}</div>
    </div>
  </div>

  <!-- Buttons -->
  <div
    *ngIf="nominations && (hasNominationsLimit && nominations.length >= nominationsLimit.min) || (!hasNominationsLimit && nominations.length > 0)"
    class="kenjo-mt-40px kenjo-text-align-right"
  >
    <orgos-button-raised
      *ngIf="canEdit && performanceReviewStatus!== PERFORMANCE_REVIEW_STATUS_RUNNING"
      [disabled]="submitting"
      color="Neutral"
      (click)="saveNominations()"
      >{{ pageTranslations.saveAndExitButton }}</orgos-button-raised
    >
    <orgos-button-raised *ngIf="canEdit" [disabled]="submitting" color="Success" class="kenjo-ml-20px" (click)="submitNominations()"
      >{{ pageTranslations.submitNominationsButton }}</orgos-button-raised
    >
  </div>
</orgos-container>
