import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

export interface IComposeEmailDialogData {
  candidate?: any;
  position?: any;
  positionCandidate?: any;
  multiplePositionCandidates?: Array<any>;
}
@Component({
  selector: 'orgos-compose-email-dialog',
  templateUrl: 'compose-email-dialog.html',
  styleUrls: ['compose-email-dialog.scss'],
})
export class ComposeEmailDialog implements OnInit {
  dialogTranslation: any = {};

  candidate: any;
  position: any;
  positionCandidate: any;
  multiplePositionCandidates: Array<any>;

  constructor(
    public dialogRef: MatLegacyDialogRef<ComposeEmailDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: IComposeEmailDialogData,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-candidate-emails-page')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });

    this.initData();
  }

  public initData(): void {
    if (
      check.assigned(this.data.multiplePositionCandidates) &&
      check.array(this.data.multiplePositionCandidates) &&
      check.nonEmptyArray(this.data.multiplePositionCandidates)
    ) {
      this.multiplePositionCandidates = this.data.multiplePositionCandidates;
      return;
    }
    this.candidate = this.data.candidate;
    this.position = this.data.position;
    this.positionCandidate = this.data.positionCandidate;
  }

  public closeDialog(dialogResult?: any): void {
    if (check.assigned(dialogResult)) {
      this.injector.get(PrivateAmplitudeService).logEvent('sent email', { category: 'General' });
    }
    this.dialogRef.close(dialogResult);
  }
}
