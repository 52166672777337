import { IShiftplanDays, IShiftplanWishDay } from '@app/cloud-features/shift-plan/services/shift-plan-wish-day.service';
import * as check from 'check-types';
import * as moment from 'moment';

export const groupWishDaysByUser = (wishDays: Array<IShiftplanWishDay> = []) => {
  return wishDays.reduce((acc, curr) => {
    if (check.not.assigned(acc[curr.userId])) {
      acc[curr.userId] = [curr];
    } else {
      acc[curr.userId].push(curr);
    }
    return acc;
  }, {});
};

export const computeWishDay = (date: moment.Moment, userWishDays: Array<IShiftplanWishDay>) => {
  if (check.not.assigned(userWishDays) || check.emptyArray(userWishDays)) {
    return null;
  }

  const foundWishDay = userWishDays.filter((wishDay) => {
    return moment.utc(date).isBetween(wishDay.startDate, wishDay.endDate, 'day', '[]');
  });

  if (check.not.assigned(foundWishDay) || check.emptyArray(foundWishDay)) {
    return null;
  }

  const dayOfWeek = date.clone().locale('en').format('dddd').toLowerCase();
  const selectedWishDay = foundWishDay.find((wishDay) => {
    return isWishDayTaken(wishDay, dayOfWeek as keyof IShiftplanDays, date);
  });

  if (check.not.assigned(selectedWishDay)) {
    return null;
  }

  return { time: formatTimeEntries(selectedWishDay.entries) };
};

export const isWishDayTaken = (wishDay: IShiftplanWishDay, dayOfWeek: keyof IShiftplanDays, date: moment.Moment) => {
  if (check.not.assigned(wishDay.frequency)) {
    return true;
  }

  if (check.assigned(wishDay.frequency) && wishDay.days[dayOfWeek] === true) {
    const { startDate, frequency } = wishDay;
    const selectedDateWeekStart = moment.utc(date).startOf('week');
    const startDateWeekStart = moment.utc(startDate).startOf('week');
    const weeksFromStart = selectedDateWeekStart.diff(startDateWeekStart, 'weeks');
    return weeksFromStart % frequency.value === 0;
  }

  return false;
};

const formatTimeEntries = (entries: Array<{ startTime: number; endTime: number }>) => {
  if (check.not.assigned(entries) || check.emptyArray(entries)) {
    return null;
  }

  const [entry] = entries;
  const today = moment.utc().startOf('day');
  const startTime = today.clone().add(entry.startTime, 'minutes');
  const endTime = today.clone().add(entry.endTime, 'minutes');

  return { start: startTime.format('HH:mm'), end: endTime.format('HH:mm') };
};
