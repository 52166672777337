import { Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ShiftPlanRoleSettingsService } from '@app/cloud-features/shift-plan/services/settings-shift-plan-role.service';
import { ShiftPlanTagSettingsService } from '@app/cloud-features/shift-plan/services/settings-shift-plan-tag.service';
import { ShiftPlanWorkingAreaSettingsService } from '@app/cloud-features/shift-plan/services/settings-shift-plan-working-area.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IMenuOption } from '@app/standard/pages/generic.page';
import { NewAppreciationDialog } from '@app/standard/pages/people-detail/dialogs/new-appreciation.dialog';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { AreaService } from '@app/standard/services/company/area.service';
import { CompanyService } from '@app/standard/services/company/company.service';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { OfficeService } from '@app/standard/services/company/office.service';
import { TeamService } from '@app/standard/services/company/team.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ClipboardService } from '@app/standard/services/core/clipboard.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UrlChangeService } from '@app/standard/services/core/url-changes.service';
import { QueryParamsService } from '@app/standard/services/navigation/query-params.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import { getDateFormat } from '@carlos-orgos/orgos-utils/date-utils';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'orgos-people-detail-public-profile-page',
  templateUrl: 'people-detail-public-profile.page.html',
  styleUrls: ['people-detail-public-profile.page.scss'],
})
export class PeopleDetailPublicProfilePage implements OnInit, OnDestroy {
  id: string;

  miscTranslation: any = {};
  pageTranslation: any = {};

  userAccount: any;
  userPersonal: any;
  userWork: any;
  userWorkSchedule: any;

  allLocations: any = {};
  allWorkingAreas: any = {};
  allRoles: any = {};
  allTags: any = {};
  seeAllShiftPlanItems: boolean = false;
  userLocationsFiltered: Array<string> = [];

  idToName: any = {};
  myPermissions: any = {};

  areaNames: string;
  teamNames: string;

  seeDetailsUserId: string;
  seeDetailsUserIdSubscription: Subscription;
  employeeTimeOffsVisible: boolean = false;
  employeeDocsVisible: boolean = false;
  // workaround for tabs to be shown in order
  isTabReady: { employeeTimeOffs: boolean; employeeDocs: boolean } = {
    employeeTimeOffs: false,
    employeeDocs: false,
  };
  isTimeOffEnabled: boolean = false;

  appreciationsEnabled: boolean = false;
  colorConstants: any = userColorConstants;
  isRestrictedProfile: boolean = false;

  getDateFormat: Function = (formatName) => {
    return getDateFormat(formatName, this.injector.get(InternationalizationService).getLocale());
  };
  shiftPlanActive: boolean = false;
  documentsActive: boolean = false;

  userManagePoliciesInfo: any;
  private _canSeePersonalTab: boolean = false;

  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    const kickedOutFromUrl = this.injector.get(PeopleDetailService).userWasKickedOutOfPersonalTabFromUrl;
    let backRoute;
    event.preventDefault();
    if (check.nonEmptyString(kickedOutFromUrl) && this._canSeePersonalTab === false) {
      backRoute = kickedOutFromUrl;
    } else if (this.injector.get(QueryParamsService).getQueryParam('canAccessAttendance') === 'false') {
      backRoute = '/cloud/attendance/attendance-summary';
      this.injector.get(QueryParamsService).removeQueryParam('canAccessAttendance');
    } else {
      backRoute = this.injector.get(UrlChangeService).getPreviousUrl();
      if (check.not.nonEmptyString(backRoute)) {
        return;
      }
    }
    setTimeout(() => {
      this.router.navigate([backRoute], { replaceUrl: true });
    }, 80);
  }

  constructor(private route: ActivatedRoute, private injector: Injector, private router: Router, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.injector.get(GlobalBarService).setProgressBar(true);
    this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);

    this.isTimeOffEnabled = this.injector.get(CloudRoutesService).checkRoute('time-off/personal');
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        this.userAccount = null;
        this.userPersonal = null;
        this.userWorkSchedule = null;
        this.userWork = null;

        this.injector.get(GlobalBarService).setProgressBar(true);
        this.id = id;
        if (!this.isValidId(id)) {
          this.router.navigateByUrl('/cloud/home');
          return;
        }

        const loggedUser: IUserAccountModel = this.injector.get(AuthenticationService).getLoggedUser();
        if (loggedUser.profileKey === 'restricted' && id !== loggedUser._id) {
          this.isRestrictedProfile = true;
          this.injector.get(GlobalBarService).setProgressBar(false);
          return;
        }

        this.fetchData();
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-misc')
      .then((miscTranslation: any) => {
        this.miscTranslation = miscTranslation;

        const options = [{ name: miscTranslation.publicProfileTab, onClick: () => {} }];

        this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
        this.injector.get(GlobalBarService).setSelectedSecondaryMenuOption(0);
      })
      .catch(() => {
        // An error is already shown.
        this.miscTranslation = {};
        this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('people-detail-public-profile-page')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;

        this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.appreciationsEnabled = false;
    this.injector
      .get(CloudRoutesService)
      .getAppStatus('thank-you-notes')
      .then((appStatus: any) => {
        if (check.assigned(appStatus) && appStatus.isActive) {
          const currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
          this.appreciationsEnabled = currentUserId !== this.id;
        }
      });
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view employee page', { category: 'Navigation', platform: 'Web', subcategory1: 'public profile', subcategory2: this.id });

    this.seeDetailsUserIdSubscription = this.injector.get(PeopleDetailService).seeDetailsUserId$.subscribe((id: string) => {
      this.userManagePoliciesInfo = {
        userPersonal: this.userPersonal,
        userStartDate: this.userWork?.startDate,
        timeOffTypes: this.injector.get(PeopleDetailService).timeOffTypes,
      };
      this.seeDetailsUserId = id;
    });
  }

  public addAppreciation(): void {
    if (!this.appreciationsEnabled) {
      return;
    }

    const data = {
      userPersonal: this.userPersonal,
    };
    this.injector.get(MatLegacyDialog).open(NewAppreciationDialog, { data });
  }

  private async fetchData(): Promise<void> {
    this.injector.get(GlobalBarService).setEnableDefaultBars(true, false);
    const userAccountPromise = this.injector.get(UserAccountService).getById(this.id);
    const userPersonalPromise = this.injector.get(UserPersonalService).getById(this.id);
    const userWorkPromise = this.injector.get(UserWorkService).getById(this.id);
    const userWorkSchedulePromise = this.injector.get(UserWorkScheduleService).getById(this.id);
    const shiftPlanStatusPromise = this.injector.get(CloudRoutesService).getAppStatus('shift-plan');

    const onPeopleRouteLoadedPromise = this.injector.get(PeopleDetailService).onPeopleRouteLoaded(this.id);
    try {
      let shiftPlanStatus;
      let documentsStatus;
      [this.userAccount, this.userPersonal, this.userWork, this.userWorkSchedule, shiftPlanStatus] = await Promise.all([
        userAccountPromise,
        userPersonalPromise,
        userWorkPromise,
        userWorkSchedulePromise,
        shiftPlanStatusPromise,
        onPeopleRouteLoadedPromise,
      ]);
      this.shiftPlanActive = shiftPlanStatus?.isActive;
      this.documentsActive = this.injector.get(PeopleDetailService).isDocumentsActive;
      if (this.shiftPlanActive) {
        await this.fetchShiftPlanData();
      }
      this.refreshSecondaryMenu();

      const teams = this.injector.get(TeamService).getTeams(this.userWork?.teamIds);
      const areas = this.injector.get(AreaService).getAreas(this.userWork?.areaIds);

      Promise.all([teams, areas]).then((items) => {
        (items as any).flat().forEach((item) => {
          this.idToName[item._id] = item.name;
        });
        this.areaNames = this.userWork?.areaIds?.map((e) => this.idToName[e]).join(', ');
        this.teamNames = this.userWork?.teamIds?.map((e) => this.idToName[e]).join(', ');
      });
    } catch {
      if (check.not.assigned(this.userAccount)) {
        this.router.navigateByUrl('/cloud/home');
      }
    }

    this.getPermissions()
      .then(() => {
        if (this.myPermissions['user-personal'].read_all === true) {
          this.injector
            .get(UserPersonalService)
            .getAllUserPersonal(false)
            .then((allUsersFull) => {
              allUsersFull.forEach((iUser) => {
                this.idToName[iUser._id] = iUser.displayName;
              });

              this.injector.get(GlobalBarService).setProgressBar(false);
            })
            .catch(() => {
              // An error is already shown
            });
        } else {
          this.injector.get(GlobalBarService).setProgressBar(false);
        }

        this.injector
          .get(CompanyService)
          .getCompanies()
          .then((companies: Array<any>) => {
            companies.forEach((iCompany: any) => {
              this.idToName[iCompany._id] = iCompany.name;
            });
          })
          .catch(() => {
            // An error is already shown
          });
        this.injector
          .get(DepartmentService)
          .getDepartments()
          .then((departments: Array<any>) => {
            departments.forEach((iDepartment: any) => {
              this.idToName[iDepartment._id] = iDepartment.name;
            });
          })
          .catch(() => {
            // An error is already shown
          });

        this.injector
          .get(OfficeService)
          .getOffices()
          .then((offices: Array<any>) => {
            offices.forEach((iOffice) => {
              this.idToName[iOffice._id] = iOffice.name;
            });
          })
          .catch(() => {
            // An error is already shown
          });

        // Get permissions to show the time off link
        this.employeeTimeOffsVisible = this.injector.get(PeopleDetailService).isEmployeeTimeOffsVisible;
      })
      .catch(() => {
        // An error is already shown
      });

    // Get permissions to show the documents link
    this.employeeDocsVisible = this.injector.get(PeopleDetailService).isEmployeeDocsVisible;
  }

  private getPermissionsToSeeAttendanceTab(employeesPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (
        this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') === false ||
        !this.userWorkSchedule ||
        this.userWorkSchedule.trackAttendance === false
      ) {
        resolve(false);
        return;
      }

      if (loggedUser._id === this.id && employeesPermissions.c_viewAttendanceTab_own === true) {
        resolve(true);
        return;
      }

      if (employeesPermissions.c_viewAttendanceTab_all === true) {
        resolve(true);
        return;
      }

      if (
        check.not.assigned(employeesPermissions.c_viewAttendanceTab_custom) ||
        check.emptyArray(employeesPermissions.c_viewAttendanceTab_custom)
      ) {
        resolve(false);
        return;
      }

      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            employeesPermissions[`c_viewAttendanceTab_custom`],
            employeesPermissions[`c_viewAttendanceTab_own`],
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private getPermissionsToSeePayrollTabs(payrollPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/compensation') === false) {
        resolve(false);
      }

      if (loggedUser._id === this.id && payrollPermissions.c_viewCompensation_own === true) {
        resolve(true);
      }
      if (payrollPermissions.c_viewCompensation_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(payrollPermissions.c_viewCompensation_custom) ||
        check.emptyArray(payrollPermissions.c_viewCompensation_custom)
      ) {
        resolve(false);
      }

      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            payrollPermissions[`c_viewCompensation_custom`],
            payrollPermissions[`c_viewCompensation_own`],
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private getPermissionsToSeePerformanceTab(performancePermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance') === false) {
        resolve(false);
      }
      if (loggedUser._id === this.id) {
        resolve(true);
      }
      if (performancePermissions.c_readFeedback_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(performancePermissions.c_readFeedback_custom) ||
        check.emptyArray(performancePermissions.c_readFeedback_custom)
      ) {
        resolve(false);
      }
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            performancePermissions.c_readFeedback_custom,
            performancePermissions.read_own,
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private refreshSecondaryMenu(): void {
    if (check.not.assigned(this.id)) {
      return;
    }

    if (check.emptyObject(this.miscTranslation)) {
      this.injector
        .get(InternationalizationService)
        .getAllTranslation('people-detail-misc')
        .then((miscTranslation: any) => {
          this.miscTranslation = miscTranslation;
          this.refreshSecondaryMenu();
          return;
        });
    }

    const options: Array<IMenuOption> = [{ name: this.miscTranslation.publicProfileTab, onClick: () => {} }];

    const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
      options.push({
        name: this.miscTranslation.personalTab,
        onClick: () => this.router.navigate(['personal'], { relativeTo: this.route }),
      });
      if (
        this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') &&
        this.userWorkSchedule &&
        this.userWorkSchedule.trackAttendance === true
      ) {
        options.push({
          key: 'attendance-tab',
          name: this.miscTranslation.attendanceTab,
          onClick: () => this.router.navigate(['attendance'], { relativeTo: this.route }),
        });
      }
      options.push({
        key: 'compensation-tab',
        name: this.miscTranslation.compensationTab,
        onClick: () => this.router.navigate(['compensation'], { relativeTo: this.route }),
      });
      options.push({ name: this.miscTranslation.payrollTab, onClick: () => this.router.navigate(['payroll'], { relativeTo: this.route }) });
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance')) {
        options.push({
          name: this.miscTranslation.performanceTab,
          onClick: () => this.router.navigate(['performance'], { relativeTo: this.route }),
        });
      }
      this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
      this.injector.get(PeopleDetailService).verifySmartDocsLink(this.id);
      this.injector.get(PeopleDetailService).verifyShowTimeOffLink(this.id, this.userPersonal);
      return;
    }

    let payrollPermissions;
    let userWorkPermissions;
    let employeesPermissions;
    let userPersonalPermissions;
    let performancePermissions;
    this.getPermissions()
      .then(() => {
        payrollPermissions = this.myPermissions['payroll-feature'];
        userWorkPermissions = this.myPermissions['user-work'];
        userPersonalPermissions = this.myPermissions['user-personal'];
        employeesPermissions = this.myPermissions['employees'];
        performancePermissions = this.myPermissions['performance-feedback-results'];
        return this.injector.get(PeopleDetailService).getPermissionsToSeePersonalTab(employeesPermissions, this.id);
      })
      .then((canSeePersonalTab: boolean) => {
        this._canSeePersonalTab = canSeePersonalTab;
        if (canSeePersonalTab === true) {
          options.push({
            name: this.miscTranslation.personalTab,
            onClick: () => this.router.navigate(['personal'], { relativeTo: this.route }),
          });
        }
        return this.getPermissionsToSeeAttendanceTab(employeesPermissions);
      })
      .then((canSeeAttendanceTab: boolean) => {
        if (canSeeAttendanceTab === true) {
          options.push({
            key: 'attendance-tab',
            name: this.miscTranslation.attendanceTab,
            onClick: () => this.router.navigate(['attendance'], { relativeTo: this.route }),
          });
        } else if (this.injector.get(QueryParamsService).getQueryParam('canAccessAttendance') === 'false') {
          this.injector.get(MatLegacySnackBar).open(this.miscTranslation.notPermitNavigation, '', {
            duration: 5000,
            panelClass: 'kenjo-error-snackbar',
          });
        }
        return this.getPermissionsToSeePayrollTabs(payrollPermissions);
      })
      .then((canSeePayrollTabs: boolean) => {
        if (canSeePayrollTabs === true) {
          options.push({
            key: 'compensation-tab',
            name: this.miscTranslation.compensationTab,
            onClick: () => this.router.navigate(['compensation'], { relativeTo: this.route }),
          });
          options.push({
            name: this.miscTranslation.payrollTab,
            onClick: () => this.router.navigate(['payroll'], { relativeTo: this.route }),
          });
        }
        return this.getPermissionsToSeePerformanceTab(performancePermissions);
      })
      .then((canSeePerformanceTab) => {
        if (canSeePerformanceTab === true) {
          options.push({
            name: this.miscTranslation.performanceTab,
            onClick: () => this.router.navigate(['performance'], { relativeTo: this.route }),
          });
        }
        this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
        this.injector.get(PeopleDetailService).verifySmartDocsLink(this.id);
        this.injector.get(PeopleDetailService).verifyShowTimeOffLink(this.id, this.userPersonal);
      })
      .catch(() => {
        // An error is already shown
        this.injector.get(GlobalBarService).setSecondaryMenuOptions(options);
        return;
      })
      .finally(() => {
        this.injector.get(QueryParamsService).setComesFrom('');
      });
  }

  private getPermissions(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (check.assigned(this.myPermissions) && check.nonEmptyObject(this.myPermissions)) {
        resolve();
        return;
      }

      this.injector
        .get(PrivateSecurityService)
        .getAllPermissions()
        .then((allPermissions) => {
          this.myPermissions = allPermissions;
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getName(id: string): string {
    const name = this.idToName[id];

    if (check.not.assigned(name)) {
      return '';
    }

    return name;
  }

  getReportsTo(id: string): any {
    const data = {
      userDisplayName: this.idToName[id],
    };

    if (check.not.assigned(data.userDisplayName)) {
      data.userDisplayName = '';
    }

    return data;
  }

  getLinkedInUrl(linkedInUsername: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(`https://www.linkedin.com/in/${linkedInUsername}`);
  }

  getSkypeUri(skypeUsername: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(`skype:${skypeUsername}?chat`);
  }

  openEmployeeDoc(): void {
    this.injector.get(PeopleDetailService).openEmployeeDoc(this.id);
  }

  public async openEmployeeTimeOff(): Promise<void> {
    this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.id);
  }

  exitTimeOffFullScreen() {
    this.injector.get(PeopleDetailService).closeEmployeeTimeOff();
    this.injector.get(GlobalBarService).setPageName(this.pageTranslation.pageName);
    this.refreshSecondaryMenu();
  }

  public clipboard(text: string, fieldName: string) {
    const pipe = this.injector.get(I18nDataPipe);
    this.injector
      .get(ClipboardService)
      .copy(
        text,
        pipe.transform(this.miscTranslation.clipboard.success, { fieldName }),
        pipe.transform(this.miscTranslation.clipboard.failure, { fieldName })
      );
  }

  private async fetchShiftPlanData(): Promise<void> {
    try {
      const shiftPlanLocationsPromise = this.injector.get(OfficeService).getData({ activeForShiftplan: true });
      const shiftPlanWorkingAreasPromise = this.injector.get(ShiftPlanWorkingAreaSettingsService).getWorkingAreas();
      const shiftPlanRolesPromise = this.injector.get(ShiftPlanRoleSettingsService).getRoles();
      const shiftPlanTagsPromise = this.injector.get(ShiftPlanTagSettingsService).getTags();

      [this.allLocations, this.allWorkingAreas, this.allRoles, this.allTags] = await Promise.all([
        shiftPlanLocationsPromise,
        shiftPlanWorkingAreasPromise,
        shiftPlanRolesPromise,
        shiftPlanTagsPromise,
      ]);

      this.allLocations = _.keyBy(this.allLocations, '_id');
      this.allWorkingAreas = _.keyBy(this.allWorkingAreas, '_id');
      this.allRoles = _.keyBy(this.allRoles, '_id');
      this.allTags = _.keyBy(this.allTags, '_id');
      this.userLocationsFiltered = this.userWork.locations?.filter((iLocationId) => check.assigned(this.allLocations[iLocationId]));
    } catch {}
  }

  isValidId(id: any) {
    return new RegExp('^[0-9a-fA-F]{24}$').test(id);
  }

  ngOnDestroy(): void {
    this.seeDetailsUserIdSubscription.unsubscribe();
  }
}
