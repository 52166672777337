import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-org-chart-delete-node-dialog',
  templateUrl: 'org-chart-delete-node.dialog.html',
  styleUrls: ['org-chart-delete-node.dialog.scss'],
})
export class OrgChartDeleteNodeDialog implements OnInit {
  i18n: any = { dialog: {}, misc: {} };

  selectedDeletionOption: 'onlyRole' | 'roleAndSubchart';

  constructor(private dialogRef: MatLegacyDialogRef<OrgChartDeleteNodeDialog>, private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('org-chart-delete-node-dialog')
      .then((dialogTranslation) => {
        this.i18n.dialog = dialogTranslation;
      })
      .catch(() => {
        this.i18n.dialog = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.i18n.misc = miscTranslation;
      })
      .catch(() => {
        this.i18n.misc = {};
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public deleteNode(): void {
    this.dialogRef.close(this.selectedDeletionOption);
  }
}
