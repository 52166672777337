<orgos-row-container *ngIf="userAccount && userPersonal && userWork && !seeDetailsUserId && !isRestrictedProfile">
  <orgos-row></orgos-row>
  <orgos-row size="0">
    <orgos-container aspect="card" class="pdppp-card">
      <div class="pdppp-header-container kenjo-border-bottom">
        <div *ngIf="appreciationsEnabled" (click)="addAppreciation()" class="pdpp-praise-container">
          <div [matTooltip]="pageTranslation.addYourAppreciationTooltip" class="pdpp-praise-box">
            <img src="/assets/images/praise.svg" class="pdpp-praise-image" />
            <div class="pdpp-action-text kenjo-pl-10px kenjo-pr-20px">{{pageTranslation.givePraiseLabel}}</div>
          </div>
        </div>
      </div>
      <div class="pdppp-info-container kenjo-ph-40px kenjo-pb-60px">
        <orgos-column-container>
          <orgos-column></orgos-column>
          <orgos-avatar
            orgosColumn="0"
            [avatarName]="userPersonal?.displayName"
            [photoUrl]="userPersonal?._photo?._url"
            size="large"
          ></orgos-avatar>
          <orgos-column class="pdppp-invisible"></orgos-column>
        </orgos-column-container>
        <orgos-text type="Headline3" class="kenjo-mt-10px pdppp-center-text">{{userPersonal?.displayName}}</orgos-text>
        <orgos-text *ngIf="userWork.jobTitle" class="kenjo-mt-10px pdppp-center-text">{{userWork.jobTitle}}</orgos-text>
        <orgos-text *ngIf="!userWork.jobTitle" class="kenjo-mt-10px pdppp-center-text" color="LightText">
          {{pageTranslation.jobTitle}}</orgos-text
        >
        <orgos-text
          *ngIf="userWork.reportsToId && myPermissions['user-personal'] && myPermissions['user-personal'].read_all === true"
          class="kenjo-mv-10px pdppp-center-text"
          ><a [routerLink]="['/cloud/people', userWork?.reportsToId, 'personal']"
            >{{pageTranslation.reportsTo | i18nData: getReportsTo(userWork.reportsToId)}}</a
          >
        </orgos-text>
        <orgos-column-container
          class="kenjo-pt-10px"
          *ngIf="employeeDocsVisible || employeeTimeOffsVisible || ('time-off-app.c_accessTimeOffCompanyPage' | permissions : userPersonal)"
        >
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0" class="kenjo-mr-5px" *ngIf="employeeDocsVisible && this.documentsActive">
            <button mat-mini-fab (click)="openEmployeeDoc()" [matTooltip]="pageTranslation.employeeDocs">
              <kenjo-icon [size]="24">documents_menu</kenjo-icon>
            </button>
          </orgos-column>
          <orgos-column
            size="0"
            class="kenjo-ml-5px"
            *ngIf="isTimeOffEnabled && (employeeTimeOffsVisible || ('time-off-app.c_accessTimeOffCompanyPage' | permissions : userPersonal))"
          >
            <button mat-mini-fab (click)="openEmployeeTimeOff()" [matTooltip]="pageTranslation.employeeTimeOff">
              <kenjo-icon [size]="20">time_off</kenjo-icon>
            </button>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
        </orgos-column-container>

        <orgos-column-container class="kenjo-pt-20px">
          <orgos-column class="kenjo-mh-10px pdppp-card-content">
            <orgos-text-icon icon="mail" [class.kenjo-font-color-light-text-757575]="!userAccount.email">
              <orgos-text
                (click)="clipboard(userAccount.email, pageTranslation.workEmail)"
                *ngIf="userAccount.email"
                class="pdppp-card-content-text kenjo-cursor-pointer"
                [noWrapText]="userAccount.email"
              >
                {{userAccount.email}}</orgos-text
              >
              <orgos-text *ngIf="!userAccount.email" color="LightText">{{pageTranslation.workEmail}}</orgos-text>
            </orgos-text-icon>
            <orgos-text-icon icon="company" class="kenjo-mt-20px" [class.kenjo-font-color-light-text-757575]="!userWork.companyId">
                <orgos-text *ngIf="idToName[userWork.companyId]" class="pdppp-card-content-text" [noWrapText]="idToName[userWork.companyId]"
                >{{idToName?.[userWork?.companyId]}}</orgos-text
                >
              <orgos-text *ngIf="!userWork.companyId" color="LightText">{{pageTranslation.company}}</orgos-text>
            </orgos-text-icon>
            <orgos-text-icon icon="department" class="kenjo-mt-20px" [class.kenjo-font-color-light-text-757575]="!userWork.departmentId">
              <orgos-text *ngIf="userWork.departmentId">{{getName(userWork.departmentId)}}</orgos-text>
              <orgos-text *ngIf="!userWork.departmentId" color="LightText">{{pageTranslation.department}}</orgos-text>
            </orgos-text-icon>
          </orgos-column>

          <orgos-column class="kenjo-mh-10px pdppp-card-content">
            <orgos-text-icon icon="phone" [class.kenjo-font-color-light-text-757575]="!userWork.workPhone">
              <orgos-text *ngIf="userWork.workPhone">{{userWork.workPhone}}</orgos-text>
              <orgos-text *ngIf="!userWork.workPhone" color="LightText">{{pageTranslation.workPhone}}</orgos-text>
            </orgos-text-icon>
            <orgos-text-icon
              icon="business"
              class="kenjo-mt-20px"
              [class.kenjo-font-color-light-text-757575]="!userWork.officeId && (!userWork.virtualOffice || userWork.virtualOffice.name)"
            >
              <orgos-text *ngIf="userWork.officeId" class="pdppp-card-content-text" [noWrapText]="getName(userWork.officeId)"
                >{{getName(userWork.officeId)}}</orgos-text
              >
              <orgos-text
                *ngIf="userWork.virtualOffice && userWork.virtualOffice.name"
                color="LightText"
                class="pdppp-card-content-text pdpp-virtual-office-text"
                [noWrapText]="userWork.virtualOffice.name"
                >{{userWork.virtualOffice.name}}</orgos-text
              >
              <orgos-text *ngIf="!userWork.officeId && (!userWork.virtualOffice || !userWork.virtualOffice.name)" color="LightText"
                >{{pageTranslation.office}}</orgos-text
              >
            </orgos-text-icon>
            <orgos-text-icon icon="team" class="kenjo-mt-20px" [class.kenjo-font-color-light-text-757575]="!userWork.teamIds?.length">
              <orgos-text *ngIf="userWork.teamIds?.length; else elseTeamsTemplate">{{teamNames}}</orgos-text>
              <ng-template #elseTeamsTemplate>
                <orgos-text color="LightText">{{pageTranslation.teams}}</orgos-text>
              </ng-template>
            </orgos-text-icon>
          </orgos-column>

          <orgos-column class="kenjo-mh-10px pdppp-card-content">
            <orgos-text-icon icon="stay_current_portrait" [class.kenjo-font-color-light-text-757575]="!userWork.workMobile">
              <orgos-text *ngIf="userWork.workMobile">{{userWork.workMobile}}</orgos-text>
              <orgos-text *ngIf="!userWork.workMobile" color="LightText">{{pageTranslation.workMobile}}</orgos-text>
            </orgos-text-icon>
            <orgos-text-icon icon="area" class="kenjo-mt-20px" [class.kenjo-font-color-light-text-757575]="!userWork.areaIds?.length">
              <orgos-text *ngIf="userWork.areaIds?.length; else elseAreasTemplate">{{areaNames}}</orgos-text>
              <ng-template #elseAreasTemplate>
                <orgos-text color="LightText">{{pageTranslation.areas}}</orgos-text>
              </ng-template>
            </orgos-text-icon>
          </orgos-column>
        </orgos-column-container>

        <orgos-container *ngIf="userPersonal.bio" [style.display]="editorViewer?.isEmpty ? 'none' : 'block'">
          <orgos-text class="kenjo-mt-40px" color="LightText">{{pageTranslation.about}}</orgos-text>
          <orgos-editor-viewer
            #editorViewer
            *ngIf="userPersonal.bio"
            class="kenjo-mt-10px"
            [editorContent]="userPersonal.bio"
          ></orgos-editor-viewer>
        </orgos-container>

        <!-- Shiftplan data -->
        <div *ngIf="shiftPlanActive && userWork.shiftPlanActive && userWork.shiftPlanActivationStatus === 'finished'">
          <div class="kenjo-pt-40px kenjo-pb-20px kenjo-font-color-light-text-757575">{{pageTranslation.shiftPlanInfoTitle}}</div>
          <orgos-column-container class="kenjo-font-color-light-text-757575">
            <orgos-column aspect="card" class="kenjo-border kenjo-border-radius pdppp-shiftplan-card-content">
              <div class="kenjo-font-size-12px kenjo-border-bottom kenjo-mh-15px kenjo-pv-15px kenjo-font-weight-bold">
                {{pageTranslation.locations}}
              </div>
              <div *ngIf="userWork.locations?.length > 0" class="kenjo-p-15px">
                <div *ngFor="let location of userLocationsFiltered; let i = index" class="kenjo-pb-10px">
                  <div *ngIf="i < 3 || seeAllShiftPlanItems" class="kenjo-font-size-12px kenjo-font-weight-bold">
                    <kenjo-simple-truncate-text class="pdppp-truncate-text">{{allLocations[location]?.name}}</kenjo-simple-truncate-text>
                  </div>
                  <div
                    *ngIf="(i < 3 || seeAllShiftPlanItems) && allLocations[location]?.street"
                    class="kenjo-pt-5px"
                    [style.font-size]="'10px'"
                  >
                    {{allLocations[location]?.street}}
                  </div>
                </div>
              </div>
              <orgos-action
                *ngIf="userLocationsFiltered?.length > 3 && !seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = true"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeMoreButton}}</orgos-action
              >
              <orgos-action
                *ngIf="userLocationsFiltered?.length > 3 && seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = false"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeLessButton}}</orgos-action
              >
            </orgos-column>
            <orgos-column aspect="card" class="kenjo-border kenjo-border-radius kenjo-ml-5px pdppp-shiftplan-card-content">
              <div class="kenjo-font-size-12px kenjo-border-bottom kenjo-mh-15px kenjo-pv-15px kenjo-font-weight-bold">
                {{pageTranslation.workingAreas}}
              </div>
              <div *ngIf="userWork.workingAreas?.length > 0" class="kenjo-p-15px">
                <div *ngFor="let workingArea of userWork.workingAreas; let i = index">
                  <orgos-chip *ngIf="i < 3 || seeAllShiftPlanItems" class="kenjo-font-size-12px kenjo-mb-10px">
                    <kenjo-simple-truncate-text class="pdppp-truncate-text"
                      >{{ allWorkingAreas[workingArea]?.name }}</kenjo-simple-truncate-text
                    >
                  </orgos-chip>
                </div>
              </div>
              <orgos-action
                *ngIf="userWork.workingAreas?.length > 3 && !seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = true"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeMoreButton}}</orgos-action
              >
              <orgos-action
                *ngIf="userWork.workingAreas?.length > 3 && seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = false"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeLessButton}}</orgos-action
              >
            </orgos-column>
            <orgos-column aspect="card" class="kenjo-border kenjo-border-radius kenjo-ml-5px pdppp-shiftplan-card-content">
              <div class="kenjo-font-size-12px kenjo-border-bottom kenjo-mh-15px kenjo-pv-15px kenjo-font-weight-bold">
                {{pageTranslation.roles}}
              </div>
              <div *ngIf="userWork.roles?.length > 0" class="kenjo-p-15px">
                <div *ngFor="let role of userWork.roles;  let i = index">
                  <orgos-chip
                    *ngIf="i < 3 || seeAllShiftPlanItems"
                    [style.background-color]="colorConstants[allRoles[role]?.colour]"
                    class="kenjo-font-size-12px kenjo-mb-10px kenjo-font-color-white-ffffff"
                  >
                    <kenjo-simple-truncate-text class="pdppp-truncate-text">{{ allRoles[role]?.name }}</kenjo-simple-truncate-text>
                  </orgos-chip>
                </div>
              </div>
              <orgos-action
                *ngIf="userWork.roles?.length > 3 && !seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = true"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeMoreButton}}</orgos-action
              >
              <orgos-action
                *ngIf="userWork.roles?.length > 3 && seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = false"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeLessButton}}</orgos-action
              >
            </orgos-column>
            <orgos-column aspect="card" class="kenjo-border kenjo-border-radius kenjo-ml-5px pdppp-shiftplan-card-content">
              <div class="kenjo-font-size-12px kenjo-border-bottom kenjo-mh-15px kenjo-pv-15px kenjo-font-weight-bold">
                {{pageTranslation.tags}}
              </div>
              <div *ngIf="userWork.tags?.length > 0" class="kenjo-p-15px">
                <div *ngFor="let tag of userWork.tags; let i = index">
                  <orgos-chip *ngIf="i < 3 || seeAllShiftPlanItems" class="kenjo-font-size-12px kenjo-mb-10px">
                    <kenjo-simple-truncate-text class="pdppp-truncate-text">{{ allTags[tag]?.name }}</kenjo-simple-truncate-text>
                  </orgos-chip>
                </div>
              </div>
              <orgos-action
                *ngIf="userWork.tags?.length > 3 && !seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = true"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeMoreButton}}</orgos-action
              >
              <orgos-action
                *ngIf="userWork.tags?.length > 3 && seeAllShiftPlanItems"
                (click)="seeAllShiftPlanItems = false"
                class="kenjo-ph-15px kenjo-pb-15px kenjo-font-size-12px"
                >{{pageTranslation.seeLessButton}}</orgos-action
              >
            </orgos-column>
          </orgos-column-container>
        </div>
      </div>
    </orgos-container>
  </orgos-row>
  <orgos-row></orgos-row>
</orgos-row-container>
<orgos-time-off-user-page
  *ngIf="seeDetailsUserId && !isRestrictedProfile"
  [userId]="seeDetailsUserId"
  [managePoliciesInfo]="userManagePoliciesInfo"
  (goBack)="exitTimeOffFullScreen()"
  class="pdppp-time-off-page"
></orgos-time-off-user-page>
