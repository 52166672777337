import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ITimeOffPolicyModel } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { TimeOffStatusService } from '@app/cloud-features/time-off/services/time-off-status.service';
import { ITimeOffTypeModel } from '@app/cloud-features/time-off/services/time-off-type.service';
import {
  ITimeOffNextCalculatedStatus,
  ITimeOffStatus,
  ITimeOffUserPolicy,
  ITimeOffUserWork,
  TimeOffUserPolicyController,
} from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CYCLE_TYPE_DISABLED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-time-off-card-details',
  templateUrl: 'time-off-card-details.dialog.html',
  styleUrls: ['time-off-card-details.dialog.scss'],
})
export class TimeOffCardDetailsDialog implements OnInit {
  CYCLE_TYPE_DISABLED = CYCLE_TYPE_DISABLED;

  // Tab index
  tabIndex: number = 0;

  // Params
  userId: string;
  timeOffType: ITimeOffTypeModel;
  policy: ITimeOffPolicyModel;
  scheduledPolicy: ITimeOffPolicyModel;
  userWork: ITimeOffUserWork;
  previousStatus: ITimeOffStatus;
  currentStatus: ITimeOffStatus;
  futureStatus: ITimeOffStatus; // received from dialog data (contains only taken/planned)
  nextStatus: ITimeOffNextCalculatedStatus; // processed next status to display on the card
  dialogTranslation: any;
  policyDetailsTranslations: { [key: string]: string };
  statusDetailsTranslations: { [key: string]: string };
  displayScheduledAssignment = false;

  // View
  isLoading: boolean = false;
  isDataFetching: boolean = false;
  hasPastCycle: boolean = false;

  constructor(
    private injector: Injector,
    private dialogRef: MatLegacyDialogRef<TimeOffCardDetailsDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private async initData(): Promise<void> {
    this.isLoading = true;
    await this.initTranslations();
    this.initParams();
    await this.checkPastCycle();
    this.isLoading = false;
  }

  private initParams() {
    const userPolicyStatus: ITimeOffUserPolicy = this.data.userPolicyStatus;
    this.policy = userPolicyStatus.policy;
    this.timeOffType = userPolicyStatus.timeOffType;
    this.userWork = userPolicyStatus.userWork;
    this.currentStatus = userPolicyStatus.currentStatus;
    this.futureStatus = userPolicyStatus.nextStatus;
    this.userId = this.data.userId;
  }

  private async initTranslations(): Promise<void> {
    try {
      this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('time-off-card-details');
      this.policyDetailsTranslations = { ...this.dialogTranslation.policyDetails, ...this.dialogTranslation.units };
      this.statusDetailsTranslations = { ...this.dialogTranslation.statusDetails, ...this.dialogTranslation.units };
    } catch {
      this.dialogTranslation = {};
      this.policyDetailsTranslations = {};
      this.statusDetailsTranslations = {};
    }
  }

  private async checkPastCycle(): Promise<void> {
    if (check.assigned(this.currentStatus?.previousStatusId)) {
      this.hasPastCycle = true;
      this.tabIndex = 1;
    }
  }

  async clickTab(tabNumber: number): Promise<void> {
    // next
    if ((this.hasPastCycle && tabNumber === 2) || (!this.hasPastCycle && tabNumber === 1)) {
      if (check.not.assigned(this.nextStatus)) {
        this.isDataFetching = true;
        const nextStatusValues = await this.injector
          .get(TimeOffUserPolicyController)
          .getNextStatusByUserIdAndPolicyId(this.currentStatus._userId, this.currentStatus._policyId);
        const nextStatus = timeOffHelpers.calculateNextStatus(
          nextStatusValues.scheduledPolicy ?? this.policy,
          this.currentStatus,
          nextStatusValues,
          this.futureStatus.planned,
          this.futureStatus.taken,
          this.userWork.startDate
        );
        this.scheduledPolicy = nextStatusValues.scheduledPolicy;
        this.nextStatus = nextStatus;
        this.isDataFetching = false;
      }
      if (this.scheduledPolicy) {
        this.policy = this.scheduledPolicy;
        this.displayScheduledAssignment = true;
      }
      return;
    }

    // previous
    if (tabNumber === 0 && this.hasPastCycle && check.not.assigned(this.previousStatus)) {
      this.isDataFetching = true;
      this.previousStatus = await this.injector
        .get(TimeOffStatusService)
        .getArchivedCycle(this.currentStatus?.previousStatusId?.toString());
      this.isDataFetching = false;
      return;
    }
    this.resetPolicy();
  }

  resetPolicy() {
    this.policy = this.data.userPolicyStatus.policy;
    this.displayScheduledAssignment = false;
  }
}
