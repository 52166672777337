<div *ngIf="permissions">
  <div *ngIf="permissionName" class="kenjo-pv-10px kenjo-ph-20px kenjo-mb-10px kenjo-border-bottom kenjo-font-size-16px">
    {{ permissionName }}
  </div>
  <div class="kenjo-ph-20px">
    <!-- CREATE PERMISSIONS START HERE -->
    <orgos-column-container centerColumns="true" class="kenjo-pt-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.create_own">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.create_own) || permissions.create_all === true" [(value)]="permissions.create_own" (valueChange)="changePermission('create_own')"> </orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.create_own }}
      </orgos-column>
    </orgos-column-container>
    <ng-container *ngIf="!permissionsToIgnore || !permissionsToIgnore.create_custom">
      <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
        <orgos-column size="0">
          <orgos-action icon="add_circle" class="cpb-add-icon" (click)="changePermission('create_custom')" [disabled]="readOnly || (readOnlyValues && readOnlyValues.create_custom)" color="Primary"> </orgos-action>
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ customTranslations.create_custom }}
        </orgos-column>
      </orgos-column-container>
      <orgos-custom-permission-summary *ngIf="permissions.create_custom && permissions.create_custom.length > 0 && !permissions.create_all" [customPermission]="permissions.create_custom" [permissionKey]="permissionKey"> </orgos-custom-permission-summary>
    </ng-container>
    <orgos-column-container centerColumns="true" class="kenjo-pt-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.create_all">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.create_all)" [(value)]="permissions.create_all" (valueChange)="changePermission('create_all')"> </orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.create_all }}
      </orgos-column>
    </orgos-column-container>
    <!-- CREATE PERMISSIONS END HERE -->

    <!-- READ PERMISSIONS START HERE -->
    <orgos-column-container centerColumns="true" class="kenjo-pt-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.read_own">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.read_own) || permissions.read_all === true" [(value)]="permissions.read_own" (valueChange)="changePermission('read_own')"> </orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.read_own }}
      </orgos-column>
    </orgos-column-container>
    <ng-container *ngIf="!permissionsToIgnore || !permissionsToIgnore.read_custom">
      <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
        <orgos-column size="0">
          <orgos-action icon="add_circle" class="cpb-add-icon" (click)="changePermission('read_custom')" [disabled]="readOnly || (readOnlyValues && readOnlyValues.read_custom)" color="Primary"> </orgos-action>
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ customTranslations.read_custom }}
        </orgos-column>
      </orgos-column-container>
      <orgos-custom-permission-summary *ngIf="permissions.read_custom && permissions.read_custom.length > 0 && !permissions.read_all" [customPermission]="permissions.read_custom" [permissionKey]="permissionKey"></orgos-custom-permission-summary>
    </ng-container>
    <orgos-column-container centerColumns="true" class="kenjo-pv-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.read_all">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.read_all)" [(value)]="permissions.read_all" (valueChange)="changePermission('read_all')"></orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.read_all }}
      </orgos-column>
    </orgos-column-container>
    <!-- READ PERMISSIONS END HERE -->

    <!-- EDIT PERMISSIONS START HERE -->
    <orgos-column-container centerColumns="true" class="kenjo-pt-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.edit_own">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.edit_own) || permissions.edit_all === true" [(value)]="permissions.edit_own" (valueChange)="changePermission('edit_own')"> </orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.edit_own }}
      </orgos-column>
    </orgos-column-container>
    <ng-container *ngIf="!permissionsToIgnore || !permissionsToIgnore.edit_custom">
      <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
        <orgos-column size="0">
          <orgos-action icon="add_circle" class="cpb-add-icon" (click)="changePermission('edit_custom')" [disabled]="readOnly || (readOnlyValues && readOnlyValues.edit_custom)" color="Primary"> </orgos-action>
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ customTranslations.edit_custom }}
        </orgos-column>
      </orgos-column-container>
      <orgos-custom-permission-summary *ngIf="permissions.edit_custom && permissions.edit_custom.length > 0 && !permissions.edit_all" [customPermission]="permissions.edit_custom" [permissionKey]="permissionKey"></orgos-custom-permission-summary>
    </ng-container>
    <orgos-column-container centerColumns="true" class="kenjo-pt-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.edit_all">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.edit_all)" [(value)]="permissions.edit_all" (valueChange)="changePermission('edit_all')"></orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.edit_all }}
      </orgos-column>
    </orgos-column-container>
    <!-- EDIT PERMISSIONS END HERE -->

    <!-- DELETE PERMISSIONS START HERE -->
    <orgos-column-container centerColumns="true" class="kenjo-pt-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.delete_own">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.delete_own) || permissions.delete_all === true" [(value)]="permissions.delete_own" (valueChange)="changePermission('delete_own')"> </orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.delete_own }}
      </orgos-column>
    </orgos-column-container>
    <ng-container *ngIf="!permissionsToIgnore || !permissionsToIgnore.delete_custom">
      <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
        <orgos-column size="0">
          <orgos-action icon="add_circle" class="cpb-add-icon" (click)="changePermission('delete_custom')" [disabled]="readOnly || (readOnlyValues && readOnlyValues.delete_custom)" color="Primary"> </orgos-action>
        </orgos-column>
        <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
          {{ customTranslations.delete_custom }}
        </orgos-column>
      </orgos-column-container>
      <orgos-custom-permission-summary *ngIf="permissions.delete_custom && permissions.delete_custom.length > 0 && !permissions.delete_all" [customPermission]="permissions.delete_custom" [permissionKey]="permissionKey"> </orgos-custom-permission-summary>
    </ng-container>
    <orgos-column-container centerColumns="true" class="kenjo-pt-10px" *ngIf="!permissionsToIgnore || !permissionsToIgnore.delete_all">
      <orgos-column size="0" class="kenjo-pr-10px">
        <orgos-input-simple-checkbox [readOnly]="readOnly || (readOnlyValues && readOnlyValues.delete_all)" [(value)]="permissions.delete_all" (valueChange)="changePermission('delete_all')"> </orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
        {{ customTranslations.delete_all }}
      </orgos-column>
    </orgos-column-container>
    <!-- DELETE PERMISSIONS END HERE -->
  </div>
</div>
