import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IWorkScheduleTemplateModel } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import * as pickLists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

import { InternationalizationService } from '../../../../../services/core/internationalization.service';

@Component({
  selector: 'kenjo-edit-work-schedule-dialog',
  templateUrl: 'edit-work-schedule.dialog.html',
  styleUrls: ['edit-work-schedule.dialog.scss'],
})
export class EditWorkScheduleDialog implements OnInit {
  dialogTranslation: any = {};
  customWorkSchedule: IWorkScheduleTemplateModel;
  updateOp: boolean = true;
  oldDate: any;
  editMode: boolean = false;
  constructor(
    public dialogRef: MatLegacyDialogRef<EditWorkScheduleDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private workScheduleInfo: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.editMode =
      check.assigned(this.workScheduleInfo.workScheduleTemplate) && check.nonEmptyObject(this.workScheduleInfo.workScheduleTemplate);
    // New custom schedule
    if (this.editMode === false) {
      this.updateOp = false;
      this.workScheduleInfo.workScheduleTemplate = {
        name: '',
        type: pickLists.WORK_SCHEDULE_TEMPLATE_TYPE_FIXED,
        weeklyMinutes: 0,
        isDefault: false,
        startDate: undefined,
        dayShifts: [
          { shifts: [{ start: undefined, end: undefined }], minutes: undefined },
          { shifts: [{ start: undefined, end: undefined }], minutes: undefined },
          { shifts: [{ start: undefined, end: undefined }], minutes: undefined },
          { shifts: [{ start: undefined, end: undefined }], minutes: undefined },
          { shifts: [{ start: undefined, end: undefined }], minutes: undefined },
          { shifts: [{ start: undefined, end: undefined }], minutes: undefined },
          { shifts: [{ start: undefined, end: undefined }], minutes: undefined },
        ],
        isCustom: true,
      };
    }
    this.oldDate = this.workScheduleInfo.workScheduleTemplate.startDate;
    this.customWorkSchedule = _.cloneDeep(this.workScheduleInfo.workScheduleTemplate);
    if (!this.customWorkSchedule.attendanceLimitSettings) {
      this.customWorkSchedule.attendanceLimitSettings = {};
    }
    this.loadTranslations();
  }

  private async loadTranslations(): Promise<void> {
    try {
      this.dialogTranslation = await this.injector.get(InternationalizationService).getAllTranslation('edit-work-schedule-dialog');
    } catch (e) {
      this.dialogTranslation = {};
    }
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public async save(): Promise<void> {
    this.cleanUpEmptyShifts();

    const customScheduleInfo = {
      userIds: [this.workScheduleInfo.userWorkId],
      entry: this.customWorkSchedule,
    };

    if (this.updateOp) {
      customScheduleInfo['oldDate'] = this.oldDate;
      customScheduleInfo['newDate'] = this.customWorkSchedule.startDate;
      await this.injector.get(UserWorkScheduleService).updateScheduleEntries(customScheduleInfo);
      this.dialogRef.close({ operation: 'update', startDate: this.customWorkSchedule.startDate });
      return;
    }
    await this.injector.get(UserWorkScheduleService).createScheduleEntries(customScheduleInfo);
    this.dialogRef.close({ operation: 'create', startDate: this.customWorkSchedule.startDate });
  }

  private cleanUpEmptyShifts(): void {
    this.customWorkSchedule.dayShifts.forEach((iWeekDay) => {
      if (iWeekDay.minutes === 0) {
        delete iWeekDay.minutes;
      }

      const validShifts = iWeekDay.shifts.filter(
        (iShift) => check.assigned(iShift.start) && check.assigned(iShift.end) && iShift.start !== iShift.end
      );
      iWeekDay.shifts = validShifts.length ? validShifts : [{}];
    });
  }
}
