import { Injectable } from '@angular/core';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class RecruitingDocumentService implements IGenericService {
  private RECRUITING_DOCUMENT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-document-db`;
  private RECRUITING_DOCUMENT_PERMISSIONS_KEY: string = 'recruiting-document';
  private RECRUITING_DOCUMENT_INTERNATIONALIZATION: string = 'recruiting-document-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IRecruitingDocumentModel> {
    return new Promise<IRecruitingDocumentModel>((resolve, reject) => {
      this.genericService
        .create(this.RECRUITING_DOCUMENT_URL, data)
        .then((recruitingDocumentTag: IRecruitingDocumentModel) => {
          resolve(recruitingDocumentTag);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingDocumentService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IRecruitingDocumentModel> {
    return new Promise<IRecruitingDocumentModel>((resolve, reject) => {
      this.genericService
        .getById(this.RECRUITING_DOCUMENT_URL, id)
        .then((recruitingDocumentTag: IRecruitingDocumentModel) => {
          resolve(recruitingDocumentTag);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingDocumentService.name, 'getById'));
        });
    });
  }

  find(findBody: any): Promise<Array<IRecruitingDocumentModel>> {
    return new Promise<Array<IRecruitingDocumentModel>>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_DOCUMENT_URL, findBody)
        .then((recruitingDocumentTag: Array<IRecruitingDocumentModel>) => {
          resolve(recruitingDocumentTag);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingDocumentService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.RECRUITING_DOCUMENT_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingDocumentService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.RECRUITING_DOCUMENT_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingDocumentService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.RECRUITING_DOCUMENT_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.RECRUITING_DOCUMENT_INTERNATIONALIZATION);
  }
}

export interface IRecruitingDocumentModel {
  _id?: string;
  _tag?: string;
  relatedTo?: string;
  _file?: IFileMetadata;
  ownerId?: string;
}
