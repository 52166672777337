import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputValidationFunction } from '@app/standard/core/validation/input-validation-function';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CustomFieldService } from '@app/standard/services/custom-field/custom-field.service';
import * as check from 'check-types';

import { GenericCacheModel } from '../../../core/generic-cache-model';
import { InputValidation } from '../../../core/validation/input-validation';

@Component({
  selector: 'orgos-dialog-edit-custom-field',
  templateUrl: 'edit-custom-field.dialog.html',
  styleUrls: ['edit-custom-field.dialog.scss'],
})
export class EditCustomFieldDialog implements OnInit {
  pageTranslation: any = {};
  parentInfo: object;
  customFieldDocument: GenericCacheModel;
  chosenListValues: Array<string> = [];
  emptyListItems: boolean = false;

  fieldLabelValidation: InputValidation;
  autoNumberMaskValidation: InputValidation;
  autoNumberNextValueValidation: InputValidation;

  updatingCustomField: boolean = false;

  autoNumberMaskValidationFunction: InputValidationFunction = (value: any): InputValidation => {
    const inputValidation = new InputValidation();

    if (check.not.match(value, /.*({\d+}).*/gi)) {
      inputValidation.setError('notValid');
    }

    return inputValidation;
  };

  constructor(
    public dialogRef: MatLegacyDialogRef<EditCustomFieldDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('edit-custom-field-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    const inputCustomField = Object.assign({}, this.data);
    this.customFieldDocument = new GenericCacheModel(this.injector, inputCustomField, CustomFieldService, '');
    this.chosenListValues = inputCustomField.listValues;

    this.cdr.detectChanges();
  }

  addNewListItem(): void {
    if (check.not.assigned(this.chosenListValues)) {
      this.chosenListValues = [];
    }
    this.chosenListValues.push('');
    this.evaluateListItems();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  updateField(): void {
    if (
      this.updatingCustomField === true ||
      check.not.assigned(this.fieldLabelValidation) ||
      this.fieldLabelValidation.hasErrors() ||
      this.emptyListItems ||
      (this.customFieldDocument.data.fieldType === 'AutoNumber' &&
        (check.not.assigned(this.autoNumberMaskValidation) || this.autoNumberMaskValidation.hasErrors())) ||
      (this.customFieldDocument.data.fieldType === 'AutoNumber' &&
        (check.not.assigned(this.autoNumberNextValueValidation) || this.autoNumberNextValueValidation.hasErrors()))
    ) {
      return;
    }

    this.updatingCustomField = true;

    this.injector.get(GlobalBarService).setProgressBar(true);

    const dataToUpdate = {
      fieldLabel: this.customFieldDocument.data.fieldLabel,
      listValues: this.chosenListValues,
      autoNumberMask: this.customFieldDocument.data.autoNumberMask,
      autoNumberNextValue: this.customFieldDocument.data.autoNumberNextValue,
    };
    this.injector
      .get(CustomFieldService)
      .updateById(this.customFieldDocument.data._id, dataToUpdate)
      .then(() => {
        const message = this.injector
          .get(I18nDataPipe)
          .transform(this.pageTranslation.updatedSnackbarMessage, { fieldName: this.customFieldDocument.data.fieldLabel });
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });

        this.dialogRef.close(this.customFieldDocument.data);
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch(() => {
        // Error will be shown by the service
        this.injector.get(GlobalBarService).setProgressBar(false);
        this.updatingCustomField = false;
      });
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  removeEntry(entryName: string): void {
    this.chosenListValues = this.chosenListValues.filter((iValue) => {
      return iValue !== entryName;
    });
    this.evaluateListItems();
  }

  evaluateListItems(): void {
    if (
      check.not.assigned(this.customFieldDocument) ||
      check.not.assigned(this.customFieldDocument.data) ||
      check.not.assigned(this.customFieldDocument.data.fieldType) ||
      check.not.equal(this.customFieldDocument.data.fieldType, 'List')
    ) {
      this.emptyListItems = false;
      return;
    } else if (
      check.not.assigned(this.chosenListValues) ||
      check.not.array(this.chosenListValues) ||
      check.emptyArray(this.chosenListValues)
    ) {
      this.emptyListItems = true;
      return;
    }

    const emptyArrayItems = this.chosenListValues.filter((iItem: string) => {
      return check.emptyString(iItem);
    });
    if (check.emptyArray(emptyArrayItems)) {
      // If there is not even one element which is empty
      this.emptyListItems = false;
      return;
    }
    this.emptyListItems = true;
  }
}
