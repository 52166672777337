import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class RecruitingScorecardService implements IGenericService {
  private RECRUITING_SCORECARD_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-scorecard-db`;
  private RECRUITING_SCORECARD_PERMISSIONS_KEY: string = 'recruiting-scorecard';
  private RECRUITING_SCORECARD_INTERNATIONALIZATION: string = 'recruiting-scorecard-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.RECRUITING_SCORECARD_URL, data)
        .then((recruitingScorecard: any) => {
          resolve(recruitingScorecard);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingScorecardService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.RECRUITING_SCORECARD_URL, id)
        .then((recruitingScorecard: any) => {
          resolve(recruitingScorecard);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingScorecardService.name, 'getById'));
        });
    });
  }

  find(findBody): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_SCORECARD_URL, findBody)
        .then((recruitingScorecards: Array<any>) => {
          resolve(recruitingScorecards);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingScorecardService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.RECRUITING_SCORECARD_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingScorecardService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.RECRUITING_SCORECARD_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingScorecardService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.RECRUITING_SCORECARD_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.RECRUITING_SCORECARD_INTERNATIONALIZATION);
  }
}
