import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';

import { IAttendanceSettingsModel } from '../models/attendance-settings.model';

@Injectable({
  providedIn: 'root'
})
export class AttendanceSettingsService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/attendance-settings-db`;

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private http: HttpClient, private injector: Injector) {}

  async find(data: any): Promise<Array<IAttendanceSettingsModel>> {
    try {
      return await this.genericService.find(this.MICROSERVICE_URL, data);
    } catch (error) {}
  }

  async updateById(id: string, data: object): Promise<void> {
    try {
      await this.genericService.updateById(this.MICROSERVICE_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, AttendanceSettingsService.name, 'updateById');
    }
  }

  async getPresenceSummaryTabStatus(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).get(`${this.MICROSERVICE_URL}/presence-summary-tab/get-status`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, AttendanceSettingsService.name, 'getPresenceSummaryTabStatus');
    }
  }

  async getPresenceSummaryTabAccess(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };
      return await this.injector.get(HttpClient).get(`${this.MICROSERVICE_URL}/presence-summary-tab/get-access`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, AttendanceSettingsService.name, 'getPresenceSummaryTabAccess');
    }
  }

  async changePresenceSummaryTabStatus(value: boolean): Promise<any> {
    try {
      if (check.not.assigned(value)) {
        const error = new OrgosError(`${this.MICROSERVICE_URL}`, ErrorCodes.BAD_REQUEST, AttendanceSettingsService.name, 'changePresenceSummaryTabStatus');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return this.injector.get(HttpClient).put(`${this.MICROSERVICE_URL}/presence-summary-tab/change-status`, { attendanceSummaryTabStatus: value }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, AttendanceSettingsService.name, 'changePresenceSummaryTabStatus');
    }
  }

  async getLateCheckInRange(): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get(`${this.MICROSERVICE_URL}/presence-summary-tab/get-late-check-in-range`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, AttendanceSettingsService.name, 'getLateCheckRange');
    }
  }

  async changeLateCheckRange(value: number): Promise<any> {
    try {
      if (check.not.assigned(value)) {
        const error = new OrgosError(`${this.MICROSERVICE_URL}`, ErrorCodes.BAD_REQUEST, AttendanceSettingsService.name, 'changeLateCheckRange');
        throw error;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return this.injector.get(HttpClient).put(`${this.MICROSERVICE_URL}/presence-summary-tab/change-late-check-in-range`, { lateCheckInRange: value }, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, AttendanceSettingsService.name, 'changeLateCheckRange');
    }
  }
}
