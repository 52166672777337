<orgos-dialog-container
  [title]="titleText"
  [employees]="employees"
  (clickCloseButton)="closeDialog()"
  [attr.id]="componentId !== null ? componentId : null"
>
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 cd-white-space">
    {{ subtitleText }}
  </div>
  <div *ngIf="bullets" class="kenjo-mt-20px">
    <ul *ngFor="let bullet of bullets">
      <li>
        <span *ngIf="bullet.title" class="kenjo-font-weight-bold">{{ bullet.title }}</span>
        <span *ngIf="bullet.title"> {{ bulletSeparator }} </span>
        <span *ngIf="bullet.text">{{ bullet.text }}</span>
      </li>
    </ul>
  </div>
  <orgos-column-container class="kenjo-mt-40px cd-wrap-buttons">
    <orgos-column size="1" class="cd-empty-column"></orgos-column>
    <orgos-column *ngIf="cancelButtonText" size="0" class="cd-flex-shrink">
      <orgos-button-raised [disabled]="cancelDisabled" color="Neutral" (click)="closeDialog()" class="cd-close cd-flex-shrink">{{
        cancelButtonText
      }}</orgos-button-raised>
    </orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="cd-flex-shrink">
      <orgos-button-raised
        [disabled]="confirmDisabled"
        [color]="confirmButtonColor"
        class="kenjo-ml-20px cd-confirm"
        (click)="confirmDialog()"
      >
        {{ confirmButtonText }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
