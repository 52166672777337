import { Injectable } from '@angular/core';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class CompanyService implements IGenericService {
  private COMPANY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/company-db/companies`;
  private COMPANY_MODEL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/company-db`;
  private COMPANY_PERMISSIONS_KEY: string = 'company';
  private COMPANY_INTERNATIONALIZATION: string = 'company-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<ICompanyModel> {
    return new Promise<ICompanyModel>((resolve, reject) => {
      this.genericService
        .create(this.COMPANY_URL, data)
        .then((company: ICompanyModel) => {
          resolve(company);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CompanyService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<ICompanyModel> {
    return new Promise<ICompanyModel>((resolve, reject) => {
      this.genericService
        .getById(this.COMPANY_URL, id)
        .then((company: ICompanyModel) => {
          resolve(company);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CompanyService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.COMPANY_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CompanyService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, CompanyService.name, 'deleteById');
    error.message = 'Company should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.COMPANY_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.COMPANY_INTERNATIONALIZATION);
  }

  getData(
    findBody: any = {
      _id: { $ne: null }
    }
  ) {
    return new Promise<Array<ICompanyModel>>((resolve, reject) => {
      this.genericService
        .find(this.COMPANY_URL, findBody)
        .then((companies: Array<ICompanyModel>) => {
          resolve(companies);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CompanyService.name, 'getCompanies'));
        });
    });
  }
  getCompanies(): Promise<Array<ICompanyModel>> {
    return this.getData();
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.COMPANY_MODEL_URL);
  }
}

export interface ICompanyModel {
  _id: string;
  name: string;
  industry?: string;
  vatId?: string;
  incorporationDate?: Date;
  parentCompany?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  defaultCurrency?: string;
  billingEmail?: string;
  contactPersonFirstName?: string;
  contactPersonLastName?: string;
  jobadpartnerHtmlTemplate?: IFileMetadata;
  mondayWorkingDay?: boolean;
  tuesdayWorkingDay?: boolean;
  wednesdayWorkingDay?: boolean;
  thursdayWorkingDay?: boolean;
  fridayWorkingDay?: boolean;
  saturdayWorkingDay?: boolean;
  sundayWorkingDay?: boolean;
  _createdAt?: Date;
  defaultLanguage?: string;
  weeklyHours?: number;
}
