<div *ngIf="translations.exportAndClose" class="eacdpd-container kenjo-pv-40px kenjo-ph-40px">
  <div *ngIf="isSendingStep" class="eacdpd-sending-step">
    <div class="kenjo-font-weight-bold kenjo-font-size-20px">{{ translations.exportAndClose }}</div>
    <div class="kenjo-text-align-center kenjo-mt-40px">
      <img src="/assets/images/payroll.svg" width="150px" />
    </div>
    <div class="kenjo-font-size-18px kenjo-mt-40px">{{ translations.creatingReport }}</div>
    <div class="eacdpd-sending kenjo-mt-20px kenjo-font-color-light-text-757575">
      <orgos-loading-spinner size="Small"></orgos-loading-spinner>
      <div class="kenjo-ml-10px">{{ translations.sendingReport }}</div>
    </div>
  </div>

  <div *ngIf="!isSendingStep">
    <div class="eacdpd-success-title">
      <div class="kenjo-font-size-20px">{{ translations.payrollClosedAndSent }}</div>
      <kenjo-icon [size]="24" class="kenjo-cursor-pointer kenjo-ml-40px kenjo-font-color-light-text-757575" (click)="close()">clear</kenjo-icon>
    </div>
    <div class="eacdpd-success-content kenjo-mt-30px kenjo-pl-20px">
      <div class="kenjo-mr-20px">
        <img src="/assets/images/payroll.svg" />
      </div>
      <div class="kenjo-font-color-light-text-757575">
        <div>{{ translations.payrollClosed }}</div>
        <div>{{ translations.onlyReopen }}</div>
      </div>
    </div>
    <div class="eacdpd-success-action kenjo-mt-30px">
      <orgos-button-raised color="Success" (click)="close()">{{ translations.closeDialog }}</orgos-button-raised>
    </div>
  </div>
</div>
