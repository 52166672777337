import '@app/standard/components/input-simple-editor/quill-normalization';

import { ChangeDetectorRef, Component, ElementRef, HostListener, Injector, Input, OnInit } from '@angular/core';
import * as check from 'check-types';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-editor',
  templateUrl: 'input-editor.component.html',
  styleUrls: ['input-editor.component.scss']
})
export class InputEditorComponent extends InputAbstractComponent implements OnInit {
  private startingQuillContent: boolean = false; // to prevent an update when the content is initialised for the first time
  private allowPasting: boolean = false; //only to allow copying on quill when it's the first time charging and startingQuillContent is set to true
  editor: any;
  focused: boolean = false;

  quillSizeClass: string = 'iec-quill-inherit';
  @Input()
  set size(size: 'large' | 'medium' | 'small' | 'inherit') {
    this.quillSizeClass = `iec-quill-${size}`;
  }

  @Input() toolbar: Array<any> = [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ indent: '-1' }, { indent: '+1' }], [{ header: [1, 2, 3, 4, false] }], [{ align: [] }], ['link']];

  modules: any = {
    toolbar: this.toolbar
  };

  style: any = {
    'font-family': 'Nunito, sans-serif'
  };

  customOptions = [{ import: 'attributors/style/align', whitelist: ['right', 'center', 'justify'] }];

  @HostListener('document:click', ['$event.target'])
  public onClick(clickedElement: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(clickedElement);
    if (clickedInside === true && this.focused === false) {
      this.editor.focus();
      this.focused = true;
    } else if (clickedInside === false && this.focused === true) {
      this.editor.blur();
      this.focused = false;
    }
  }

  constructor(protected cdr: ChangeDetectorRef, protected injector: Injector, private elementRef: ElementRef) {
    super(cdr, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.modules.toolbar = this.toolbar;
  }

  onEditorCreated(editor: any): void {
    this.editor = editor;

    if (check.assigned(super.getValue()) && check.assigned(super.getValue().delta)) {
      this.startingQuillContent = true;
      this.editor.setContents(super.getValue().delta);
    } else if (check.assigned(super.getValue()) && check.assigned(super.getValue().html)) {
      this.editor.clipboard.dangerouslyPasteHTML(super.getValue().html);
      super.setValue({ html: super.getValue().html, delta: this.editor.getContents() });
    } else {
      this.startingQuillContent = true;
      this.editor.setContents(null);
      this.allowPasting = true;
    }
  }

  onContentChanged(content: any): void {
    if (this.allowPasting) {
      this.startingQuillContent = false;
    } else if (this.startingQuillContent) {
      this.startingQuillContent = false;
      return;
    }

    if (check.not.assigned(content.html)) {
      super.setValue(null);
      return;
    }

    const rawData = {
      delta: content.editor.editor.delta,
      html: content.html
    };

    super.setValue(rawData);
  }
}
