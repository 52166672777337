import { Injector } from '@angular/core';

import { GenericModel } from './generic-model';
import { GenericSimpleModel } from './generic-simple-model';

export class GenericArrayModel extends GenericModel {
  public data: Array<GenericSimpleModel>;

  constructor(injector: Injector, rawData: Array<any>, serviceClass: any, ownerId?: string, operationOptions?: any) {
    super(injector, rawData, serviceClass, ownerId, operationOptions);

    this.data = rawData.map((data: any) => {
      return new GenericSimpleModel(injector, data, serviceClass, ownerId, operationOptions);
    });
  }

  public createNewElement(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.service
        .create(data, this.operationOptions)
        .then((createdElement: any) => {
          const createdElementModel = new GenericSimpleModel(this.injector, createdElement, this.serviceClass, this.ownerId, this.operationOptions);
          this.data.push(createdElementModel);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteElement(index: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.data[index]
        .delete()
        .then(() => {
          this.data.splice(index, 1);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
