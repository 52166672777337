import { Injectable } from '@angular/core';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class UserOvertimeHistoryService implements IGenericService {
  private USER_OVERTIME_HISTORY_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-overtime-history-db`;
  private USER_OVERTIME_HISTORY_PERMISSIONS_KEY: string = 'user-overtime-history';
  private USER_OVERTIME_HISTORY_INTERNATIONALIZATION: string = 'user-overtime-history-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IUserOvertimeHistoryModel> {
    return new Promise<IUserOvertimeHistoryModel>((resolve, reject) => {
      this.genericService
        .create(this.USER_OVERTIME_HISTORY_URL, data)
        .then((performanceCategory: IUserOvertimeHistoryModel) => {
          resolve(performanceCategory);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserOvertimeHistoryService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IUserOvertimeHistoryModel> {
    return new Promise<IUserOvertimeHistoryModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_OVERTIME_HISTORY_URL, id)
        .then((userPersonal: IUserOvertimeHistoryModel) => {
          resolve(userPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserOvertimeHistoryService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_OVERTIME_HISTORY_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserOvertimeHistoryService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserOvertimeHistoryService.name, 'deleteById');
    error.message = 'UserPersonal should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_OVERTIME_HISTORY_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_OVERTIME_HISTORY_INTERNATIONALIZATION);
  }

  find(findBody: any, showArchived: boolean = false): Promise<Array<IUserOvertimeHistoryModel>> {
    return new Promise<Array<IUserOvertimeHistoryModel>>((resolve, reject) => {
      if (showArchived === true) {
        findBody['isArchived'] = true;
      }

      this.genericService
        .find(this.USER_OVERTIME_HISTORY_URL, findBody)
        .then((allUserPersonal: Array<IUserOvertimeHistoryModel>) => {
          resolve(allUserPersonal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserOvertimeHistoryService.name, 'find'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_OVERTIME_HISTORY_URL);
  }
}

export interface IUserOvertimeHistoryModel {
  _id?: string;
  type?: picklistConstants.OVERTIME_HISTORY_ENTRY_TYPE;
  minutes?: number;
  relatedToId?: string;
  comments?: string;
  userId?: string;
  year?: number;
  month?: number;
  minutesBefore?: number;
}
