import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import * as check from 'check-types';
import * as _ from 'lodash';

import { I18nDataPipe } from '../../../components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { EditCustomPermissionsDialog } from '../edit-custom-permissions-dialog/edit-custom-permissions.dialog';

@Component({
  selector: 'orgos-permissions-box',
  templateUrl: 'permissions-box.component.html',
  styleUrls: ['permissions-box.component.scss'],
})
export class PermissionsBoxComponent implements OnInit {
  i18n: any = {};
  customTranslations: any = {};

  @Output() permissionsChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() readOnly: boolean = false;

  private _specificTranslations: any;
  @Input()
  set specificTranslations(translationsIn: any) {
    this._specificTranslations = translationsIn;
    this.recalculateCustomTranslations(translationsIn);
  }
  get specificTranslations(): any {
    return this._specificTranslations;
  }

  private _permissionKey: string;
  @Input()
  set permissionKey(permissionIn: string) {
    this._permissionKey = permissionIn;
  }
  get permissionKey(): string {
    return this._permissionKey;
  }

  private _permissionName: string;
  @Input()
  set permissionName(nameIn: string) {
    this._permissionName = nameIn;
    this.recalculateCustomTranslations();
  }
  get permissionName(): string {
    return this._permissionName;
  }

  private _permissions: any;
  @Input()
  set permissions(permissionsVal: any) {
    this._permissions = this.getEmptyPermission();
    if (check.assigned(permissionsVal) && check.object(permissionsVal) && check.nonEmptyObject(permissionsVal)) {
      Object.keys(permissionsVal).forEach((iKey) => {
        this._permissions[iKey] = permissionsVal[iKey];
      });
    }

    if (check.not.assigned(this.readOnlyValues)) {
      this.readOnlyValues = {};
    }
    this.calculateReadOnlyToTrue();
  }
  get permissions(): any {
    return this._permissions;
  }

  private _permissionsToIgnore: any;
  @Input()
  set permissionsToIgnore(permissionsObject: any) {
    this._permissionsToIgnore = permissionsObject;
  }
  get permissionsToIgnore(): any {
    return this._permissionsToIgnore;
  }

  private _readOnlyValues: any;
  @Input()
  set readOnlyValues(inValues: any) {
    this._readOnlyValues = Object.assign({}, inValues);
  }
  get readOnlyValues(): any {
    return this._readOnlyValues;
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((i18n) => {
        this.i18n = i18n;
        this.recalculateCustomTranslations();
      })
      .catch(() => {
        this.i18n = {};
      });
  }

  private recalculateCustomTranslations(specificTranslations?: any): void {
    this.customTranslations = Object.assign({}, this.i18n);
    if (check.assigned(specificTranslations) && check.nonEmptyObject(specificTranslations)) {
      Object.keys(specificTranslations).forEach((iTranslation) => {
        this.customTranslations[iTranslation] = this.i18n[specificTranslations[iTranslation]]
          ? this.i18n[specificTranslations[iTranslation]]
          : specificTranslations[iTranslation];
      });
    } else if (check.assigned(this.specificTranslations) && check.nonEmptyObject(this.specificTranslations)) {
      Object.keys(this.specificTranslations).forEach((iTranslation) => {
        this.customTranslations[iTranslation] = this.i18n[this.specificTranslations[iTranslation]]
          ? this.i18n[this.specificTranslations[iTranslation]]
          : this.specificTranslations[iTranslation];
      });
    }

    const translationData = {
      permissionName: this.permissionName,
    };
    Object.keys(this.customTranslations)
      .filter((iTranslationKey) => {
        return check.contains(
          [
            'create_all',
            'create_custom',
            'create_own',
            'read_all',
            'read_custom',
            'read_own',
            'edit_all',
            'edit_custom',
            'edit_own',
            'delete_all',
            'delete_custom',
            'delete_own',
          ],
          iTranslationKey
        );
      })
      .forEach((iTranslation) => {
        this.customTranslations[iTranslation] = this.injector
          .get(I18nDataPipe)
          .transform(this.customTranslations[iTranslation], translationData);
      });
  }

  private getEmptyPermission(): any {
    return {
      create_all: false,
      create_own: false,
      read_all: false,
      read_own: false,
      edit_all: false,
      edit_own: false,
      delete_all: false,
      delete_own: false,
    };
  }

  // This function sets to true the read only values when they are needed.
  // If any of these values should be set to false, that will be done within calculateReadOnlyToFalse function
  private calculateReadOnlyToTrue(): void {
    this.readOnlyValues = {
      create_all:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.create_all) && this.readOnlyValues.create_all === true,
      create_custom:
        check.assigned(this.readOnlyValues) &&
        check.assigned(this.readOnlyValues.create_custom) &&
        this.readOnlyValues.create_custom === true,
      create_own:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.create_own) && this.readOnlyValues.create_own === true,
      read_all:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.read_all) && this.readOnlyValues.read_all === true,
      read_custom:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.read_custom) && this.readOnlyValues.read_custom === true,
      read_own:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.read_own) && this.readOnlyValues.read_own === true,
      edit_all:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.edit_all) && this.readOnlyValues.edit_all === true,
      edit_custom:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.edit_custom) && this.readOnlyValues.edit_custom === true,
      edit_own:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.edit_own) && this.readOnlyValues.edit_own === true,
      delete_all:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.delete_all) && this.readOnlyValues.delete_all === true,
      delete_custom:
        check.assigned(this.readOnlyValues) &&
        check.assigned(this.readOnlyValues.delete_custom) &&
        this.readOnlyValues.delete_custom === true,
      delete_own:
        check.assigned(this.readOnlyValues) && check.assigned(this.readOnlyValues.delete_own) && this.readOnlyValues.delete_own === true,
    };

    if (this.permissions['delete_all'] === true && (!this.permissionsToIgnore || !this.permissionsToIgnore['delete_all'])) {
      this.readOnlyValues['delete_own'] = true;
      this.readOnlyValues['delete_custom'] = true;
      this.readOnlyValues['edit_all'] = true;
      this.readOnlyValues['edit_own'] = true;
      this.readOnlyValues['edit_custom'] = true;
      this.readOnlyValues['read_all'] = true;
      this.readOnlyValues['read_own'] = true;
      this.readOnlyValues['read_custom'] = true;
    }
    if (this.permissions['edit_all'] === true && (!this.permissionsToIgnore || !this.permissionsToIgnore['edit_all'])) {
      this.readOnlyValues['edit_own'] = true;
      this.readOnlyValues['edit_custom'] = true;
      this.readOnlyValues['read_all'] = true;
      this.readOnlyValues['read_own'] = true;
      this.readOnlyValues['read_custom'] = true;
    }
    if (this.permissions['read_all'] === true && (!this.permissionsToIgnore || !this.permissionsToIgnore['read_all'])) {
      this.readOnlyValues['read_own'] = true;
      this.readOnlyValues['read_custom'] = true;
    }
  }

  // This function sets to false the read only values when this action is needed.
  // If any of these values should be set to true, that will be done within calculateReadOnlyToTrue function
  private calculateReadOnlyToFalse(): void {
    if (this.permissions['delete_all'] === false) {
      this.readOnlyValues['delete_own'] = false;
      this.readOnlyValues['delete_custom'] = false;
      this.readOnlyValues['edit_all'] = false;
    }
    if (this.permissions['edit_all'] === false) {
      this.readOnlyValues['edit_own'] = false;
      this.readOnlyValues['edit_custom'] = false;
      this.readOnlyValues['read_all'] = false;
    }
    if (this.permissions['read_all'] === false) {
      this.readOnlyValues['read_own'] = false;
      this.readOnlyValues['read_custom'] = false;
    }
  }

  // This function setns the values of permissions to true when they are needed
  private setDependenciesToTrue(): void {
    if (this.permissions['delete_all'] === true && (!this.permissionsToIgnore || !this.permissionsToIgnore['delete_all'])) {
      this.permissions['delete_own'] = true;
      this.permissions['edit_all'] = true;
      this.permissions['edit_own'] = true;
      this.permissions['read_all'] = true;
      this.permissions['read_own'] = true;
    }
    if (this.permissions['edit_all'] === true && (!this.permissionsToIgnore || !this.permissionsToIgnore['edit_all'])) {
      this.permissions['edit_own'] = true;
      this.permissions['read_all'] = true;
      this.permissions['read_own'] = true;
    }
    if (this.permissions['read_all'] === true && (!this.permissionsToIgnore || !this.permissionsToIgnore['read_all'])) {
      this.permissions['read_own'] = true;
    }
    if (
      this.permissions['create_all'] === true &&
      this.permissionKey !== 'goal' &&
      (!this.permissionsToIgnore || !this.permissionsToIgnore['create_all'])
    ) {
      this.permissions['create_own'] = true;
    }
  }

  public changePermission(newPermission: string): void {
    if (
      check.not.assigned(newPermission) ||
      check.not.string(newPermission) ||
      this.readOnly === true ||
      (check.assigned(this.readOnlyValues) && this.readOnlyValues[newPermission] === true)
    ) {
      return;
    }

    if (newPermission.endsWith('_custom')) {
      this.openCustomPermissionsDialog(newPermission);
      return;
    }

    if (this.permissions[newPermission] === true) {
      // If we have just enabled a permission
      this.setDependenciesToTrue();
      this.calculateReadOnlyToTrue();
    } else if (this.permissions[newPermission] === false) {
      // If we have just disabled a permission
      this.calculateReadOnlyToFalse();
    }
    this.emitChange();
  }

  private openCustomPermissionsDialog(customOperation): void {
    const data = {
      permissions: _.cloneDeep(this.permissions[customOperation]),
      permissionLabel: this.customTranslations[customOperation],
      permissionKey: this.permissionKey,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditCustomPermissionsDialog, { data });
    dialogRef.afterClosed().subscribe((newPermissions) => {
      if (check.not.assigned(newPermissions) || check.not.array(newPermissions)) {
        return;
      }
      this.permissions[customOperation] = newPermissions;
      this.emitChange();
    });
  }

  public emitChange(): void {
    const data = {
      permissionKey: this.permissionKey,
      permissions: this.permissions,
    };
    this.permissionsChanged.emit(data);
  }
}
