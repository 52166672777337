import { ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import * as check from 'check-types';

import { ISelectOption } from '../../core/select-option';
import { PicklistsService } from '../../services/core/picklists.service';
import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-radio',
  templateUrl: 'input-radio.component.html',
  styleUrls: ['input-radio.component.scss']
})
export class InputRadioComponent extends InputAbstractComponent {
  @Input() options: Array<ISelectOption> = [];
  @Input() readOnly: boolean = false;
  @Input()
  set picklist(picklistName: string) {
    this.picklistService
      .getPicklist(picklistName)
      .then((picklist: object) => {
        this.options = Object.keys(picklist).reduce((tempOptions: Array<ISelectOption>, value: string) => {
          const picklistOption: ISelectOption = {
            name: picklist[value],
            value: value
          };

          tempOptions.push(picklistOption);

          return tempOptions;
        }, []);
      })
      .catch(() => {
        this.options = [];
      });
  }

  @Input() inline: boolean = false;

  constructor(cdr: ChangeDetectorRef, injector: Injector, private picklistService: PicklistsService) {
    super(cdr, injector);
  }

  getValueName(): string {
    const value = super.getValue();

    if (check.not.assigned(value)) {
      return '';
    }

    const option = this.options.find((option: ISelectOption) => {
      return option.value === value;
    });

    if (check.not.assigned(option)) {
      return '';
    }

    return option.name;
  }
}
