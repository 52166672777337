import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kenjo-group-by',
  templateUrl: './group-by.component.html',
  styleUrls: ['./group-by.component.scss']
})
export class GroupByComponent implements OnInit {
  @Input() groupByFieldLabel: string = '';
  @Input() listGroupings: Map<string, string>;
  @Input() initialGrouping: string;

  @Output() groupingValueChange: EventEmitter<string> = new EventEmitter<string>();

  groupingValue: string;
  // keeps the original order of the Map
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  ngOnInit(): void {
    this.groupingValue = this.initialGrouping ?? 'no_grouping';
  }
}
