<div size="0" class="isetc-emojis-container">
  <div class="isetc-emojis-selected">
    <div *ngFor="let iEmoji of emojisSelected" [matTooltip]="iEmoji.tooltip" matTooltipPosition="above" (click)="emojiClick(iEmoji)" class="itsetc-emoji-box kenjo-cursor-pointer" [ngClass]="{ 'itsetc-deleteable-emoji': iEmoji.isEditable, 'itsetc-non-deleteable-emoji': !iEmoji.isEditable }">
      <ngx-emoji [emoji]="iEmoji.emojiIcon" size="20" class="isetc-emoji"></ngx-emoji>
      <div class="kenjo-ml-5px kenjo-font-size-12px">
        {{ iEmoji.numberClicks }}
      </div>
    </div>
  </div>
  <div cdk-overlay-origin [ngClass]="{ '-read-only': readOnly === true }" class="isetc-add-emoji-container kenjo-cursor-pointer" (click)="openEmojiPicker()">
    <kenjo-icon [size]="20" class="isetc-smile-emoji">smile</kenjo-icon>
    <kenjo-icon [size]="20" class="isetc-happy-emoji">happy</kenjo-icon>
  </div>
</div>
<ng-template cdk-portal>
  <emoji-mart [title]="miscTranslation.addReaction" [i18n]="miscTranslation.emojiInputInternationalization" emoji="point_up" (emojiSelect)="addEmoji($event.emoji.colons)"></emoji-mart>
</ng-template>
