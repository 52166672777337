<orgos-dialog-container class="dialog-box" [title]="translation.title + ' ' + (isBulk ? translation.bulk : '')" (clickCloseButton)="closeDialog()">
  <orgos-column-container>
    <orgos-column class="kenjo-pr-20px">
      <div class="kenjo-font-size-14px" *ngIf="!isBulk">{{translation.currentBalance}}</div>
      <div class="kenjo-font-size-14px" *ngIf="isBulk">{{translation.maxBalance}}</div>
    </orgos-column>
    <orgos-column>
      <div class="kenjo-font-size-14px" *ngIf="!isBulk">{{currentBalance >0? '+': ''}}{{currentBalance | duration}}</div>
      <div class="kenjo-font-size-14px" *ngIf="isBulk">+{{maxBalanceToCompensate | duration}}</div>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <div class="kenjo-font-size-14px">{{translation.amountOfHours}}</div>
    </orgos-column>
    <orgos-column>
      <orgos-input-simple-number
        [step]="1"
        [min]="0"
        [max]="maxValues?.hours"
        class="kenjo-block"
        [(value)]="newBalance.hours"
        [changeValueOnBlur]="true"
        (blurInput)="parseBalanceHours(); preventWrongMinutesValue(); changeNewBalance()"
        [suffix]="translation.hoursUnits"
        class="octo-small-picker kenjo-mr-20px"
      ></orgos-input-simple-number>
      <orgos-input-simple-number
        [step]="1"
        [min]="0"
        [max]="newBalance.hours === maxValues?.hours ? maxValues?.minutes : 59"
        class="kenjo-block"
        [(value)]="newBalance.minutes"
        [changeValueOnBlur]="true"
        (blurInput)="parseBalanceMinutes(); preventWrongMinutesValue($event); changeNewBalance()"
        [suffix]="translation.minutesUnits"
        class="octo-small-picker"
      ></orgos-input-simple-number>
      <div *ngIf="newBalance.hours > 0 || newBalance.minutes > 0" class="kenjo-font-size-14px dialog-inline">{{balanceAfterText}}</div>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <div class="kenjo-font-size-14px">{{translation.policy}}</div>
    </orgos-column>
    <orgos-column *ngIf="timeOffOptions.length > 0">
      <orgos-input-simple-select class="kenjo-block" [options]="timeOffOptions" [(value)]="policyId"></orgos-input-simple-select>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true">
    <orgos-column class="kenjo-pr-20px">
      <div class="kenjo-font-size-14px" *ngIf="!policyId || !hourlyTimeOffs[policyId]">{{translation.additionalDays}}</div>
      <div class="kenjo-font-size-14px" *ngIf="hourlyTimeOffs[policyId]">{{translation.additionalHours}}</div>
    </orgos-column>
    <orgos-column>
      <orgos-input-simple-number [step]="0.5" [min]="0" class="dialog-input" [(value)]="compensation"></orgos-input-simple-number>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container>
    <orgos-column>
      <orgos-input-simple-text-area class="kenjo-block" [(value)]="comments" [label]="translation.comment"></orgos-input-simple-text-area>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" *ngIf="isBulk" class="kenjo-pt-20px">
    <orgos-column class="kenjo-pr-20px" size="0">
      <div class="kenjo-font-size-14px">{{translation.selectedUsers}}</div>
    </orgos-column>
    <orgos-column size="0">
      <orgos-action (click)="usersSelectedVisible = false" *ngIf="usersSelectedVisible">
        <kenjo-icon>keyboard_arrow_up</kenjo-icon>
      </orgos-action>
      <orgos-action (click)="usersSelectedVisible = true" *ngIf="!usersSelectedVisible">
        <kenjo-icon>keyboard_arrow_down</kenjo-icon>
      </orgos-action>
    </orgos-column>
    <orgos-column></orgos-column>
  </orgos-column-container>

  <div *ngIf="usersSelectedVisible">
    <orgos-table *ngIf="userIds && userIds.length > 0" class="kenjo-mt-20px aws-table" [data]="userIds" [totalColumns]="['name', 'overtime']">
      <orgos-table-column columnKey="name" [header]="translation.name | uppercase">
        <ng-container *orgosTableCell="let row">
          <orgos-column-container centerColumns="true">
            <orgos-avatar orgosColumn="0" [avatarName]="userPersonal[row].displayName" [photoUrl]="userPersonal[row]._photo?._url" size="tiny" class="kenjo-pr-5px"> </orgos-avatar>
            <kenjo-truncate-text orgosColumn="1">{{userPersonal[row].displayName}}</kenjo-truncate-text>
          </orgos-column-container>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="overtime" [header]="translation.overtime | uppercase">
        <ng-container *orgosTableCell="let row"> {{balances[row] >0? '+': ''}} {{balances[row] | duration}} </ng-container>
      </orgos-table-column>
    </orgos-table>
  </div>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{translation.cancel}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="save()" color="Success" [disabled]="saving || !compensation || !policyId || newBalance.hours === null || newBalance.minutes === null || (newBalance.hours === 0 && newBalance.minutes === 0)"> {{translation.save}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
