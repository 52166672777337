import { ChangeDetectorRef, Component, Injector, Input } from '@angular/core';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-toggle-round',
  templateUrl: 'input-toggle-round.component.html',
  styleUrls: ['input-toggle-round.component.scss']
})
export class InputToggleRoundComponent extends InputAbstractComponent {
  @Input() disabledColor: 'Disabled' | 'Success' = 'Success';

  constructor(cdr: ChangeDetectorRef, injector: Injector) {
    super(cdr, injector);
    this.debounceNewValues = false;
  }
}
