<orgos-container>
  <orgos-column-container centerColumns="true">
    <orgos-input-select
      [readOnly]="readOnly"
      orgosColumn="1"
      class="kenjo-p-20px"
      required="true"
      [options]="selectFieldOptions"
      [model]="auxModel"
      field="field"
      (modelChange)="changeInFilter(true)"
    ></orgos-input-select>
    <orgos-input-select
      [readOnly]="readOnly"
      orgosColumn="1"
      class="kenjo-pr-20px"
      required="true"
      [options]="selectOperatorOptions"
      [sortOptions]="false"
      [model]="auxModel"
      field="condition"
      (modelChange)="changeInFilter(false)"
    ></orgos-input-select>

    <orgos-input-text
      [readOnly]="readOnly"
      orgosColumn="1"
      *ngIf="
        auxModel.data.condition !== 'isNotEmpty' &&
        auxModel.data.condition !== 'isEmpty' &&
        auxModel.data.condition !== 'isChanged' &&
        !selectValueOptions &&
        (!workflowFilter.valueType || workflowFilter.valueType === 'Email' || workflowFilter.valueType === 'String')
      "
      class="kenjo-pr-20px"
      required="true"
      [model]="auxModel"
      field="value"
      (modelChange)="changeInFilter(false)"
    ></orgos-input-text>
    <orgos-input-select
      [readOnly]="readOnly"
      orgosColumn="1"
      *ngIf="
        auxModel.data.condition !== 'isNotEmpty' &&
        auxModel.data.condition !== 'isEmpty' &&
        auxModel.data.condition !== 'isChanged' &&
        selectValueOptions &&
        workflowFilter.valueType === 'String'
      "
      class="kenjo-pr-20px"
      required="true"
      [options]="selectValueOptions"
      [model]="auxModel"
      field="value"
      (modelChange)="changeInFilter(false)"
    ></orgos-input-select>
    <orgos-input-checkbox
      [readOnly]="readOnly"
      orgosColumn="1"
      *ngIf="
        auxModel.data.condition !== 'isNotEmpty' &&
        auxModel.data.condition !== 'isEmpty' &&
        auxModel.data.condition !== 'isChanged' &&
        workflowFilter.valueType === 'Boolean' &&
        workflowFilter.field !== 'mentioned'
      "
      [model]="auxModel"
      field="value"
      class="kenjo-pr-20px"
      (modelChange)="changeInFilter(false)"
      labelPosition="after"
      [label]="translatedFieldValue"
    ></orgos-input-checkbox>
    <orgos-input-date-picker
      [readOnly]="readOnly"
      orgosColumn="1"
      *ngIf="
        auxModel.data.condition !== 'isNotEmpty' &&
        auxModel.data.condition !== 'isEmpty' &&
        auxModel.data.condition !== 'isChanged' &&
        workflowFilter.valueType === 'Date'
      "
      [model]="auxModel"
      field="value"
      class="kenjo-pr-20px"
      required="true"
      (modelChange)="changeInFilter(false)"
    ></orgos-input-date-picker>
    <orgos-input-number
      [readOnly]="readOnly"
      orgosColumn="1"
      *ngIf="
        auxModel.data.condition !== 'isNotEmpty' &&
        auxModel.data.condition !== 'isEmpty' &&
        auxModel.data.condition !== 'isChanged' &&
        workflowFilter.valueType === 'Number' &&
        workflowFilter.field !== '_startTime' &&
        workflowFilter.field !== '_endTime'
      "
      class="kenjo-pr-20px"
      required="true"
      [model]="auxModel"
      field="value"
      [min]="0"
      (modelChange)="changeInFilter(false)"
    ></orgos-input-number>
    <orgos-column
      *ngIf="
        auxModel.data.condition !== 'isNotEmpty' &&
        auxModel.data.condition !== 'isEmpty' &&
        auxModel.data.condition !== 'isChanged' &&
        workflowFilter.valueType === 'Number' &&
        (workflowFilter.field === '_startTime' || workflowFilter.field === '_endTime')
      "
      size="1"
      class="kenjo-pr-20px"
      ><input
        type="time"
        [readOnly]="readOnly"
        min="00:00"
        max="23:59"
        [(value)]="auxModel.data['value']"
        (change)="auxModel.data['value'] = $event.target.value; changeInFilter(false)"
        required
    /></orgos-column>

    <orgos-input-select
      [readOnly]="readOnly"
      orgosColumn="1"
      *ngIf="
        auxModel.data.condition !== 'isNotEmpty' &&
        auxModel.data.condition !== 'isEmpty' &&
        auxModel.data.condition !== 'isChanged' &&
        findingSelectedOptions === false &&
        selectValueOptions &&
        (workflowFilter.valueType === 'ObjectID' || (workflowFilter.valueType === 'Array' && selectValueOptions.length > 0))
      "
      class="kenjo-pr-20px"
      required="true"
      [options]="selectValueOptions"
      [model]="auxModel"
      field="value"
      (modelChange)="changeInFilter(false)"
    ></orgos-input-select>
    <orgos-column size="0" *ngIf="findingSelectedOptions === true" class="kenjo-pr-20px">
      <mat-progress-spinner mode="indeterminate" diameter="35"></mat-progress-spinner>
    </orgos-column>
    <orgos-column *ngIf="readOnly === false" size="0" class="kenjo-border-right"> </orgos-column>
    <orgos-column *ngIf="readOnly === false" size="0" class="kenjo-p-20px" (click)="deleteFilter()">
      <kenjo-icon [size]="18" class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer">delete</kenjo-icon>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
