import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewChecked, Component, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
  ICalendarIntegrationSettings,
  SettingsCalendarIntegrationService,
} from '@app/cloud-features/settings-calendar-integration/services/settings-calendar-integration.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { IUserData } from '@app/standard/components/calendar/calendar.component';
import { CreateListViewDialog } from '@app/standard/components/list-view/dialogs/create-list-view.dialog';
import { ListViewComponent } from '@app/standard/components/list-view/list-view.component';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { ExportCalendarPopupComponent } from '@app/standard/pages/calendar/export-calendar-popup/export-calendar-popup.component';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { AreaService } from '@app/standard/services/company/area.service';
import { CalendarTemplateService } from '@app/standard/services/company/calendar-template.service';
import { CalendarService } from '@app/standard/services/company/calendar.service';
import { CompanyService } from '@app/standard/services/company/company.service';
import { DepartmentService } from '@app/standard/services/company/department.service';
import { OfficeService } from '@app/standard/services/company/office.service';
import { TeamService } from '@app/standard/services/company/team.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import {
  ICalendarEvent,
  ICalendarEvents,
  ICalendarTimeOffType,
  ICalendarUserEvents,
  TimeOffCalendarController,
} from '@app/standard/services/user/controllers/time-off-calendar.controller';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { WidgetsService } from '@app/standard/services/widgets/widgets.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-calendar-page',
  templateUrl: 'calendar.page.html',
  styleUrls: ['calendar.page.scss'],
})
export class CalendarPage extends GenericPage implements AfterViewChecked {
  protected translationResources: Array<ITranslationResource> = [{ name: 'page', translationKey: 'calendar-page' }];

  private allUserPersonal: Array<any> = [];

  private allUserWorkMap: any = {};
  private allUserWorkScheduleMap: any = {};
  private allOfficesMap: any = {};
  private allCompaniesMap: any = {};
  private allCalendarsMap: any = {};
  private allCalendarTemplatesMap: any = {};

  private calendarEvents: ICalendarEvents = {};
  private calendarTimeOffTypes: Array<ICalendarTimeOffType> = [];

  allOffices: Array<any> = [];
  allAreas: Array<any> = [];
  allDepartments: Array<any> = [];
  allTeams: Array<any> = [];
  myTeamIds: Array<string> = [];
  calendarIntegrationSettings: ICalendarIntegrationSettings;

  timeOffTypeIdsToDisplay: Array<ICalendarTimeOffType> = [];
  filteredTimeOffTypes: Array<ICalendarTimeOffType> = [];

  filtersOpened: boolean = false;
  peopleFilterOptions: Array<ISelectOption> = [];
  public filters: ICalendarFilters = {
    people: 'MyTeam',
    departments: [],
    offices: [],
    employees: [],
    areas: [],
    teams: [],
  };

  searchResults: Array<any> = [];

  year: number = moment().year();

  private allUsersForCalendar: Array<IUserData> = [];
  filteredUsersForCalendar: Array<IUserData> = [];

  calendarHeightStyle: SafeStyle;

  @ViewChild('calendarFooter') calendarFooter: ElementRef;

  preferenceKey: string = 'view-calendar';
  selectedView: string;
  viewsLoaded: boolean = false;
  @ViewChild(ListViewComponent) listView: ListViewComponent;

  ngAfterViewChecked(): void {
    if (check.assigned(this.listView)) {
      this.viewsLoaded = this.listView.isLoaded;
      this.cdr.detectChanges();
    }
    if (check.assigned(this.calendarFooter)) {
      const calendarFooterHeight = this.calendarFooter.nativeElement.offsetHeight;

      this.calendarHeightStyle = this.injector.get(DomSanitizer).bypassSecurityTrustStyle(`calc(100% - ${calendarFooterHeight}px)`);
      this.cdr.detectChanges();
    }
  }

  loggedUserForCalendar: IUserData;

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function): Promise<void> {
    try {
      await this.getMyTeamUsers();

      await this.getSpecificTimeOffs(this.year);

      this.allUserPersonal = await this.injector.get(UserPersonalService).getAllUserPersonal(true);

      const allUserWork: Array<any> = await this.injector.get(UserWorkService).getAllUserWork(true);
      this.allUserWorkMap = _.keyBy(allUserWork, '_id');

      const allUserWorkScheduleQuery = this.injector.get(UserWorkScheduleService).getAllUserWorkSchedule(true);
      const allCalendarTemplatesQuery = this.injector.get(CalendarTemplateService).getAvailableTemplates();
      const allCompaniesQuery = this.injector.get(CompanyService).getCompanies();
      const allCalendarsQuery = this.injector.get(CalendarService).getCalendars();
      const allOfficesQuery = this.injector.get(OfficeService).getOffices();
      const allAreasQuery = this.injector.get(AreaService).getAreas();
      const allDepartmentsQuery = this.injector.get(DepartmentService).getDepartments();
      const allTeamsQuery = this.injector.get(TeamService).getTeams();

      const [allUserWorkSchedule, allCalendarTemplates, allCompanies, allCalendars, allOffices, allAreas, allDepartments, allTeams] =
        await Promise.all([
          allUserWorkScheduleQuery,
          allCalendarTemplatesQuery,
          allCompaniesQuery,
          allCalendarsQuery,
          allOfficesQuery,
          allAreasQuery,
          allDepartmentsQuery,
          allTeamsQuery,
        ]);

      this.allUserWorkScheduleMap = _.keyBy(allUserWorkSchedule, '_id');
      this.allOfficesMap = _.keyBy(allOffices, '_id');
      this.allCompaniesMap = _.keyBy(allCompanies, '_id');
      this.allCalendarsMap = _.keyBy(allCalendars, '_id');
      this.allCalendarTemplatesMap = _.keyBy(allCalendarTemplates, 'templateKey');
      this.allOffices = allOffices;
      this.allAreas = allAreas;
      this.allDepartments = allDepartments;
      this.allTeams = allTeams;

      resolveFetchData();
    } catch {
      this.allUserPersonal = [];
      this.allUserWorkMap = {};
      this.allUserWorkScheduleMap = {};
      this.allOfficesMap = {};
      this.allAreas = [];
      this.allDepartments = [];
      this.allTeams = [];
      this.allCompaniesMap = {};
      this.allCalendarsMap = {};
      this.allCalendarTemplatesMap = {};
      rejectFetchData();
    }
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;
    this.globalBarConfig.selectedSecondaryMenuOption = 0;
    const options: Array<IMenuOption> = [{ name: this.i18n.page.overviewTab, onClick: () => this.router.navigateByUrl('/cloud/calendar') }];

    const profileKey = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    if (profileKey === 'admin' || profileKey === 'hr-admin') {
      options.push({ name: this.i18n.page.settingsTab, onClick: () => this.router.navigateByUrl('/cloud/calendar/settings') });
    }

    this.globalBarConfig.secondaryMenuOptions = options;

    return Promise.resolve();
  }

  protected afterInit(): Promise<void> {
    this.peopleFilterOptions = [
      { name: this.i18n.page.allFilterOption, value: 'All' },
      { name: this.i18n.page.myTeamFilterOption, value: 'MyTeam' },
      { name: this.i18n.page.employeesFilterOption, value: 'Employees' },
    ];

    this.createUserStructureForCalendar();
    this.updateTimeOffTypesToDisplay();

    this.injector.get(PrivateAmplitudeService).logEvent('view calendar page', { category: 'Navigation' });

    return Promise.resolve();
  }

  protected async fetchLazyData(): Promise<void> {
    this.calendarIntegrationSettings = await this.getCalendarIntegrationSettings();
  }

  private async getSpecificTimeOffs(year: number): Promise<any> {
    try {
      const { events, timeOffTypes } = await this.injector.get(TimeOffCalendarController).getCalendarUserDayEvents(year);
      this.calendarEvents = events;
      this.calendarTimeOffTypes = timeOffTypes;
    } catch {
      // do nothing
    }
  }

  private async getCalendarIntegrationSettings(): Promise<ICalendarIntegrationSettings> {
    const calendarSettings: ICalendarIntegrationSettings = await this.injector
      .get(SettingsCalendarIntegrationService)
      .getCalendarSettings();
    return calendarSettings;
  }

  private createUserStructureForCalendar(): void {
    const loggedUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    this.allUsersForCalendar = this.allUserPersonal.map((iUserPersonal) => {
      const userWorkSchedule = this.allUserWorkScheduleMap[iUserPersonal._id];
      const workScheduleForCalendar = [];
      if (userWorkSchedule.mondayWorkingDay === true) {
        workScheduleForCalendar.push(0);
      }
      if (userWorkSchedule.tuesdayWorkingDay === true) {
        workScheduleForCalendar.push(1);
      }
      if (userWorkSchedule.wednesdayWorkingDay === true) {
        workScheduleForCalendar.push(2);
      }
      if (userWorkSchedule.thursdayWorkingDay === true) {
        workScheduleForCalendar.push(3);
      }
      if (userWorkSchedule.fridayWorkingDay === true) {
        workScheduleForCalendar.push(4);
      }
      if (userWorkSchedule.saturdayWorkingDay === true) {
        workScheduleForCalendar.push(5);
      }
      if (userWorkSchedule.sundayWorkingDay === true) {
        workScheduleForCalendar.push(6);
      }

      const nonWorkingDates = this.getNonWorkingDates(userWorkSchedule);
      const publicHolidaysForCalendar = [];
      let userCalendarId;

      if (
        check.assigned(this.allUserWorkMap[iUserPersonal._id].virtualOffice) &&
        check.assigned(this.allUserWorkMap[iUserPersonal._id].virtualOffice.calendarId)
      ) {
        userCalendarId = this.allUserWorkMap[iUserPersonal._id].virtualOffice.calendarId;
      } else if (check.assigned(this.allUserWorkMap[iUserPersonal._id].officeId)) {
        userCalendarId = this.allOfficesMap[this.allUserWorkMap[iUserPersonal._id].officeId].calendarId;
      } else {
        userCalendarId = this.allCompaniesMap[this.allUserWorkMap[iUserPersonal._id].companyId].calendarId;
      }
      const userCalendar = this.allCalendarsMap[userCalendarId];
      this.allCalendarTemplatesMap[userCalendar._calendarTemplateKey].holidays.forEach((iStandardHoliday: any) => {
        publicHolidaysForCalendar.push(moment.utc(iStandardHoliday.holidayDate).format('YYYY-MM-DD'));
      });
      userCalendar._customHolidays.forEach((iCustomHoliday: any) => {
        publicHolidaysForCalendar.push(moment.utc(iCustomHoliday.holidayDate).format('YYYY-MM-DD'));
      });

      const userCalendarEvents = this.calendarEvents[iUserPersonal._id] ?? {};

      const userTimeOffTypesSet = Object.values(userCalendarEvents).reduce((userTypes, iEventsOfDay) => {
        return iEventsOfDay.reduce((dayUserTypes, event) => {
          dayUserTypes.add(event.timeOffTypeId);
          return dayUserTypes;
        }, userTypes);
      }, new Set());

      let maxEventsSameDay = 0;
      const userTimeOffTypes = [...userTimeOffTypesSet];
      Object.values(userCalendarEvents).forEach((eventsOfDay) => {
        maxEventsSameDay = Math.max(maxEventsSameDay, eventsOfDay.length);
        eventsOfDay.forEach((event) => {
          event.start = moment.utc(event.start);
          event.end = moment.utc(event.end);
          event.day = moment.utc(event.day);
          event.title = event.title ?? this.i18n.page.away;
          event.color = this.getColor(event.color);
          event.position = userTimeOffTypes.indexOf(event.timeOffTypeId);
          event.timeOffTypeId = event.timeOffTypeId ?? 'HidePolicy';
        });
      });

      const userForCalendar: IUserData = {
        userId: iUserPersonal._id,
        displayName: iUserPersonal.displayName,
        _photo: iUserPersonal._photo,
        nonWorkingDates: nonWorkingDates,
        workSchedule: workScheduleForCalendar,
        publicHolidays: publicHolidaysForCalendar,
        events: userCalendarEvents,
        collapsed: true,
        collapsedHeight: 20 + maxEventsSameDay * 20 < 60 ? 60 : 20 + maxEventsSameDay * 20,
        expandedHeight: 40 * userTimeOffTypes.length + 10 < 60 ? 60 : 40 * userTimeOffTypes.length + 10,
        numberOfDifferentEventTypes: userTimeOffTypes.length,
      };
      if (userForCalendar.userId === loggedUserId) {
        this.loggedUserForCalendar = userForCalendar;
      }
      return userForCalendar;
    });

    this.refreshUsersForCalendar();
  }

  private getNonWorkingDates(userWorkSchedule: any): Array<any> {
    const startOfYear = moment.utc().year(this.year).startOf('year');
    const endOfYear = moment.utc().year(this.year).endOf('year');
    let workScheduleForCalendar = [];

    // Go through all the history entry and return an array with the dates that is a nonWorkingDay, the dates what are out of the history we don`t set any nonWorking day, assuming Saturday & Sunday
    if (
      check.assigned(userWorkSchedule.history) &&
      check.array(userWorkSchedule.history) &&
      check.nonEmptyArray(userWorkSchedule.history)
    ) {
      userWorkSchedule.history.forEach((iTrack, index: number) => {
        let endDate = endOfYear.clone();
        const startDate = moment.utc(iTrack.startDate);
        if (check.assigned(userWorkSchedule.history[index + 1])) {
          endDate = moment.utc(userWorkSchedule.history[index + 1].startDate);
        }

        if (check.assigned(iTrack.dayShifts)) {
          // in shifts we start by 0 to monday
          for (let indexShift: number = 0; indexShift <= 4; indexShift++) {
            if (check.not.assigned(iTrack.dayShifts[indexShift].minutes)) {
              workScheduleForCalendar = workScheduleForCalendar.concat(this.generateDates(indexShift + 1, startDate, endDate));
            }
          }
        }
      });

      return workScheduleForCalendar;
    }

    if (userWorkSchedule.mondayWorkingDay === false) {
      workScheduleForCalendar = workScheduleForCalendar.concat(this.generateDates(1, startOfYear, endOfYear));
    }
    if (userWorkSchedule.tuesdayWorkingDay === false) {
      workScheduleForCalendar = workScheduleForCalendar.concat(this.generateDates(2, startOfYear, endOfYear));
    }
    if (userWorkSchedule.wednesdayWorkingDay === false) {
      workScheduleForCalendar = workScheduleForCalendar.concat(this.generateDates(3, startOfYear, endOfYear));
    }
    if (userWorkSchedule.thursdayWorkingDay === false) {
      workScheduleForCalendar = workScheduleForCalendar.concat(this.generateDates(4, startOfYear, endOfYear));
    }
    if (userWorkSchedule.fridayWorkingDay === false) {
      workScheduleForCalendar = workScheduleForCalendar.concat(this.generateDates(5, startOfYear, endOfYear));
    }

    return workScheduleForCalendar;
  }

  // generate dates based on the day of week until a date given
  // dayOfWeek 0 - 6 from  sunday to saturday
  private generateDates(dayOfWeek: number, startDate: moment.Moment, endDate: moment.Moment) {
    if (dayOfWeek < 0 || dayOfWeek > 6) {
      return [];
    }

    if (moment(endDate).isBefore(startDate)) {
      return [];
    }

    const datesResult = [];
    let dateIndex = moment(startDate).day(dayOfWeek);
    if (dateIndex.isBefore(startDate)) {
      dateIndex = moment(startDate).day(dayOfWeek + 7);
    }

    while (dateIndex.isBefore(endDate)) {
      datesResult.push(dateIndex.clone().format('YYYY-MM-DD'));
      dateIndex.day(7 + dayOfWeek);
    }

    return datesResult;
  }

  showOnlyTimeOffTypesOfTheYear(year: number, changeOfYear: boolean = false, toggleTimeOff: boolean = false) {
    if (this.year !== year || !changeOfYear) {
      const stringYear = String(year !== undefined ? year : this.year);

      const filteredTimeOffTypeIds = this.filteredUsersForCalendar.reduce<Array<string>>(
        (finalUserIdsArray: Array<string>, user: IUserData) => {
          const userTimeOffTypeIds = _.chain(user.events)
            .keys()
            .filter((key) => key.includes(stringYear))
            .reduce((userIdArray, key) => {
              if (check.assigned(user.events[key]) && check.array(user.events[key]) && check.nonEmptyArray(user.events[key])) {
                for (let userEventObject of user.events[key]) {
                  const timeOffTypeId = userEventObject.timeOffTypeId;
                  if (timeOffTypeId) {
                    userIdArray.push(timeOffTypeId);
                  }
                }
              }
              return userIdArray;
            }, [])
            .uniq()
            .value();

          return finalUserIdsArray.concat(userTimeOffTypeIds);
        },
        []
      );

      const timeOffTypeIdsInCurrentYear = _.uniq(filteredTimeOffTypeIds);
      this.filteredTimeOffTypes = this.calendarTimeOffTypes.filter((timeOffType) => {
        return timeOffTypeIdsInCurrentYear.includes(timeOffType.id);
      });

      if (!toggleTimeOff) {
        this.updateTimeOffTypesToDisplay();
      }

      // Filter users' events according to visible time off types
      const idsToDisplay = this.timeOffTypeIdsToDisplay
        .filter((iTimeOffType) => iTimeOffType.checked)
        .map((iTimeOffType) => iTimeOffType.id);

      this.filteredUsersForCalendar.forEach((iUser: IUserData) => {
        if (iUser.userId !== this.loggedUserForCalendar.userId) {
          Object.keys(iUser.events).forEach((date: string) => {
            iUser.events[date] = iUser.events[date].filter((iEvent: ICalendarEvent) => {
              return this.timeOffTypeIdsToDisplay.length === 0 ? true : _.includes(idsToDisplay, iEvent.timeOffTypeId);
            });
          });
        }
      });
    }

    this.year = year;
  }

  updateTimeOffTypesToDisplay() {
    this.timeOffTypeIdsToDisplay = this.filteredTimeOffTypes.map((timeOffType) => {
      return {
        id: timeOffType.id,
        color: this.getColor(timeOffType.color),
        name: timeOffType.name ?? this.i18n.page.away,
        checked: true,
      };
    });
  }

  changeFilters(filterKey: string, value: any): void {
    if (filterKey === 'people') {
      this.filters.people = value;
      this.filters.departments = [];
      this.filters.offices = [];
      this.filters.employees = [];
      this.filters.areas = [];
      this.filters.teams = [];
    } else {
      const valueIndex = this.filters[filterKey].indexOf(value);
      if (valueIndex === -1) {
        this.filters[filterKey].push(value);
      } else {
        this.filters[filterKey].splice(valueIndex, 1);
      }
    }
    this.refreshUsersForCalendar();
  }

  changeTimeOffTypeVisibility(show: boolean, timeOffType: ICalendarTimeOffType): void {
    if (check.not.assigned(timeOffType)) {
      return;
    }

    this.timeOffTypeIdsToDisplay = this.timeOffTypeIdsToDisplay.map((iTimeOffType) => {
      return iTimeOffType.id === timeOffType.id ? { ...iTimeOffType, checked: show } : iTimeOffType;
    });
    this.refreshUsersForCalendar(true);
  }

  private async updateYearData(year, toggleTimeOff: boolean = false) {
    await this.getSpecificTimeOffs(year);
    this.updateCalendar(toggleTimeOff, year);
    this.createUserStructureForCalendar();
  }

  private updateCalendar(toggleTimeOff: boolean = false, year: number) {
    const filteredUsersForCalendar = _.cloneDeep(this.allUsersForCalendar).filter((iUser: IUserData) => {
      return this.filterUser(iUser.userId);
    });

    const filteredUsers = filteredUsersForCalendar.filter((user) => {
      if (check.not.assigned(this.loggedUserForCalendar)) {
        return true;
      }
      return user.userId !== this.loggedUserForCalendar.userId;
    });

    const orderedUsers = _.orderBy(filteredUsers, ['displayName'], ['asc']);

    if (check.assigned(this.loggedUserForCalendar)) {
      orderedUsers.splice(0, 0, this.loggedUserForCalendar);
    }

    this.filteredUsersForCalendar = orderedUsers;
    this.showOnlyTimeOffTypesOfTheYear(year, false, toggleTimeOff);
  }

  refreshUsersForCalendar(toggleTimeOff: boolean = false, newYear?: number): void {
    if (newYear && this.year !== newYear) {
      this.updateYearData(newYear, toggleTimeOff);
    } else {
      this.updateCalendar(toggleTimeOff, this.year);
    }
  }

  private async getMyTeamUsers() {
    const myTeam = await this.injector.get(WidgetsService).getMyTeamMembersForCalendar();
    myTeam.sections.forEach((iSection) => {
      iSection.members.forEach((iUser) => {
        this.myTeamIds.push(iUser._id.toString());
      });
    });
  }

  private filterUser(userId: string): boolean {
    if (this.filters.people === 'MyTeam') {
      const currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
      return userId === currentUserId || this.myTeamIds.includes(userId);
    } else if (this.filters.people === 'All') {
      const userOfficeId = this.allUserWorkMap[userId].officeId;
      const userDepartmentId = this.allUserWorkMap[userId].departmentId;
      const userAreaIds = this.allUserWorkMap[userId].areaIds;
      const userTeamIds = this.allUserWorkMap[userId].teamIds;
      let conditionsMet = 0;

      if (check.not.nonEmptyArray(this.filters.offices) || this.filters.offices.includes(userOfficeId)) {
        conditionsMet++;
      }

      if (check.not.nonEmptyArray(this.filters.departments) || this.filters.departments.includes(userDepartmentId)) {
        conditionsMet++;
      }

      if (check.not.nonEmptyArray(this.filters.areas) || userAreaIds?.some((area: string) => this.filters.areas.includes(area))) {
        conditionsMet++;
      }

      if (check.not.nonEmptyArray(this.filters.teams) || userTeamIds?.some((team: string) => this.filters.teams.includes(team))) {
        conditionsMet++;
      }

      return conditionsMet === 4;
    } else if (this.filters.people === 'Employees') {
      return this.filters.employees.includes(userId);
    }

    return true;
  }

  removeEmployeeFromFilters(userId: string, search: SearchComponent): Function {
    return () => {
      this.changeFilters('employees', userId);
      search.refreshSearchResults();
    };
  }

  getColor(color: string): string {
    if (check.not.assigned(color)) {
      return '#757575';
    }
    return userColorConstants[color];
  }

  openExportCalendarPopup(exportButton: ElementRef): void {
    const overlay = this.injector.get(Overlay);

    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.backdropClass = 'cp-export-calendar-overlay';
    config.positionStrategy = overlay
      .position()
      .flexibleConnectedTo(exportButton)
      .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }])
      .withPush(false);

    const overlayRef = overlay.create(config);
    const exportCalendarPopupPortal = new ComponentPortal(ExportCalendarPopupComponent);
    overlayRef.attach(exportCalendarPopupPortal);
    overlayRef.backdropClick().subscribe(() => {
      overlayRef.dispose();
    });
  }

  public searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      const resultsWhenEmpty = this.allUserPersonal.filter((iUserPersonal: any) => {
        return this.filters.employees.includes(iUserPersonal._id);
      });

      return Promise.resolve(resultsWhenEmpty);
    }

    const results = this.allUserPersonal.filter((iUserPersonal: any) => {
      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return (
        regExp.test(iUserPersonal.displayName) &&
        !this.filters.employees.includes(iUserPersonal._id) &&
        iUserPersonal._id !== this.loggedUserForCalendar.userId
      );
    });

    return Promise.resolve(results);
  };

  public updateAfterViewChange(viewInfo): void {
    if (check.not.assigned(viewInfo) || check.emptyObject(viewInfo)) {
      return;
    }
    this.filters = viewInfo.filters;
    this.selectedView = viewInfo.viewName;
    this.refreshUsersForCalendar();
  }

  public createView(): void {
    const viewInfo = {
      viewType: this.preferenceKey,
      filters: this.filters,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateListViewDialog, { data: { viewInfo } });
    dialogRef.afterClosed().subscribe((viewName) => {
      if (check.assigned(viewName)) {
        this.selectedView = viewName;
        const message = this.i18n.page.viewCreatedMessage;
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });
      }
    });
  }
}

export interface ICalendarFilters {
  people: 'All' | 'MyTeam' | 'Employees';
  departments: Array<string>;
  offices: Array<string>;
  employees: Array<string>;
  areas: Array<string>;
  teams: Array<string>;
}
