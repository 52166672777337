<div class="sspvo-container" *ngIf="i18n.page && parentTranslation">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575 sspvo-flex">
    {{ parentTranslation.generalHeader }}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333"
      >{{ parentTranslation.viewOptionsSettings }}</span
    >
  </div>
  <!-- Time off -->
  <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
    <orgos-column size="1" class="kenjo-mr-40px">
      <div class="kenjo-mb-20px kenjo-font-size-16px">{{ i18n.page.showTimeOff }}</div>
    </orgos-column>
    <orgos-column></orgos-column>
    <orgos-column size="0" class="kenjo-ml-40px">
      <mat-slide-toggle
        class="kenjo-mb-20px"
        [disabled]="settingPermissions?.edit_all === false || updating"
        [checked]="generalSettings.showTimeOff"
        (change)="updateFeatureView($event.checked, 'timeoff')"
        [matTooltipDisabled]="settingPermissions?.edit_all === true"
        [matTooltip]="parentTranslation.noPermissionsTooltip"
        matTooltipPosition="above"
      ></mat-slide-toggle>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mb-30px" centerColumns="true">
    <orgos-column size="1" class="sspvo-description">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-mb-20px">{{ i18n.page.showTimeOffDescription }}</div>
      <img class="sspvo-timeoff-image" src="/assets/images/{{ timeOffImg }}" />
    </orgos-column>
  </orgos-column-container>
  <div class="sspvo-divider kenjo-mv-20px"></div>

  <!-- Compact Layout -->
  <ng-container *ngIf="cardFields.map">
    <div class="kenjo-font-size-16px kenjo-mb-20px">{{ i18n.page.customizeCompact }}</div>
    <orgos-column-container centerColumns="true" class="kenjo-mb-20px sspvo-description">
      <div orgosColumn class="kenjo-font-color-light-text-757575 kenjo-font-size-14px">{{ i18n.page.compactLayoutDescription }}</div>
    </orgos-column-container>
    <kenjo-shift-card
      class="kenjo-mb-30px sppvo-card"
      *ngIf="cardSizeSettings && dummyShift"
      [class.kenjo-cursor-pointer]="false"
      [shift]="dummyShift"
      [componentTranslation]="durationTranslation"
      [cardSizeSettings]="cardSizeSettings"
      [view]="'week'"
    ></kenjo-shift-card>
    <div class="sspvo-checkboxes-container">
      <mat-checkbox
        *ngFor="let field of cardFields.list"
        [matTooltip]="i18n.page.noPermissionsTooltip"
        [matTooltipDisabled]="settingPermissions?.edit_all === true"
        [disabled]="settingPermissions?.edit_all === false || cardFields.disabled[field.key] || updating"
        [(ngModel)]="cardFields.map[field.key]"
        (change)="onChangeCardSizeSetting()"
        class="kenjo-mb-10px"
        >{{ field.name }}</mat-checkbox
      >
    </div>
  </ng-container>

  <div class="sspvo-divider kenjo-mv-20px"></div>

  <orgos-column-container>
    <orgos-column size="1">
      <div class="kenjo-mb-5px kenjo-font-size-16px">{{i18n.page.showPublicHolidays}}</div>
      <div class="kenjo-mb-20px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.showPublicHolidaysText}}</div>
      <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575 kenjo-mt-20px">{{i18n.page.officeLocations}}</div>
      <mat-form-field *ngIf="shiftplanLocations?.length > 0">
        <mat-chip-list
          *ngIf="calendarsSelected.value.length > 0"
          #chipList
          [disabled]="generalSettings?.showPublicHolidays === false || settingPermissions?.edit_all === false || updating"
        >
          <mat-chip
            *ngFor="let calendarSelected of calendarsSelected.value"
            class="sspvo-chip"
            (removed)="onCalendarRemoved(calendarSelected)"
          >
            <orgos-column-container centerColumns="true">
              <orgos-column size="1" class="sspvo-chip-text">{{ calendarSelected?.name }}</orgos-column>
              <kenjo-icon
                orgosColumn="0"
                [size]="24"
                matChipRemove
                class="kenjo-cursor-pointer kenjo-ml-5px"
                [disabled]="!generalSettings?.showPublicHolidays || settingPermissions?.edit_all === false || updating"
              >
                clear
              </kenjo-icon>
            </orgos-column-container>
          </mat-chip>

          <input
            class="kenjo-cursor-pointer"
            matInput
            [placeholder]="i18n.page.addCalendars "
            [matChipInputFor]="chipList"
            [readonly]="true"
            (click)="matSelect.open()"
            [disabled]="generalSettings?.showPublicHolidays === false || settingPermissions?.edit_all === false || updating"
          />
        </mat-chip-list>
        <div class="sspvo-results-container">
          <mat-label *ngIf="!addedCalendars.length" class="sspvo-results-container-label" kenjoRemoveAriaOwns
            >{{i18n.page.addCalendars}}</mat-label
          >
          <mat-select
            #matSelect
            [formControl]="calendarsSelected"
            (selectionChange)="onCalendarSelectionChange($event)"
            [disabled]="generalSettings?.showPublicHolidays === false || settingPermissions?.edit_all === false || updating"
          >
            <ng-container *ngIf="remainingCalendars && remainingCalendars.length > 0">
              <mat-option *ngFor="let calendar of remainingCalendars" [value]="calendar"> {{ calendar?.name }} </mat-option>
            </ng-container>
            <ng-container *ngIf="!remainingCalendars || remainingCalendars.length === 0">
              <mat-option disabled>{{ i18n.page.allItemsAdded }}</mat-option>
            </ng-container>
          </mat-select>
        </div>
      </mat-form-field>
      <mat-form-field *ngIf="shiftplanLocations?.length === 0" class="kenjo-pr-10px sspvo-mat-form-field">
        <mat-label kenjoRemoveAriaOwns>{{i18n.page.addCalendars}}</mat-label>
        <mat-select [disabled]="!generalSettings?.showPublicHolidays || settingPermissions?.edit_all === false">
          <mat-option [value]="navigateToSettings">
            <div class="sspvo-button-option kenjo-mv-10px kenjo-mh-15px" (click)="navigateTo();$event.stopPropagation()">
              <div>
                <div class="kenjo-font-size-14px">{{i18n.page.createLocation}}</div>
                <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{i18n.page.noOfficeLocations}}</div>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-40px">
      <mat-slide-toggle
        class="kenjo-mb-20px"
        [disabled]="settingPermissions?.edit_all === false || updating"
        [checked]="generalSettings.showPublicHolidays"
        (change)="updateViewCalendars($event.checked)"
        [matTooltipDisabled]="settingPermissions?.edit_all === true"
        [matTooltip]="parentTranslation.noPermissionsTooltip"
        matTooltipPosition="below"
      ></mat-slide-toggle>
    </orgos-column>
  </orgos-column-container>

  <div class="sspvo-divider kenjo-mv-20px"></div>

  <!-- Attendance -->
  <ng-container class="kenjo-mb-40px">
    <orgos-column-container class="kenjo-mb-30px" [centerColumns]="true">
      <div orgosColumn class="kenjo-font-size-16px">{{ i18n.page.showAttendance }}</div>
      <kenjo-icon orgosColumn="0" [matTooltip]="i18n.page.attendanceInfoTooltip" matTooltipPosition="above" [size]="20"
        >help_outline</kenjo-icon
      >
      <orgos-column></orgos-column>
      <orgos-column orgosColumn="0">
        <mat-slide-toggle
          [disabled]="settingPermissions?.edit_all === false"
          [checked]="generalSettings.showAttendance"
          (change)="updateFeatureView($event.checked, 'attendance')"
          [matTooltipDisabled]="settingPermissions?.edit_all === true"
          [matTooltip]="i18n.page.noPermissionsTooltip"
          matTooltipPosition="above"
        ></mat-slide-toggle>
      </orgos-column>
    </orgos-column-container>

    <orgos-column-container class="kenjo-mb-20px" [centerColumns]="true" class="sspvo-description">
      <div orgosColumn class="kenjo-font-color-light-text-757575 kenjo-font-size-14px">
        {{ i18n.page.showAttendanceDescription }}
        <a [href]="i18n.page.learnMoreLink" target="”_blank”">{{ i18n.page.learnMoreAttendance }}</a>
      </div>
    </orgos-column-container>

    <ul class="kenjo-mb-20px kenjo-font-color-light-text-757575">
      <li><span class="kenjo-font-weight-bold"> {{ i18n.page.trackedSoFar }} </span> {{ i18n.page.trackedSofarDetails }}</li>
      <li><span class="kenjo-font-weight-bold"> {{ i18n.page.balance }} </span> {{ i18n.page.balanceDetails }}</li>
    </ul>
    <img class="sspvo-attendance-image" src="/assets/images/{{ attendanceImg }}" />
  </ng-container>
</div>
