<div *ngIf="!emailTemplateToEdit && listEmailTemplates && listEmailTemplates.length === 0">
  <div class="kenjo-text-align-right">
    <orgos-action icon="add_circle" iconSize="large" (click)="createEmailTemplate()">{{pageTranslation.addTemplateButton}}</orgos-action>
  </div>
  <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{pageTranslation.noTemplateHeader}}</div>
  </div>
</div>

<ng-container *ngIf="!emailTemplateToEdit && listEmailTemplates && listEmailTemplates.length > 0">
  <div class="kenjo-text-align-right kenjo-mb-20px">
    <orgos-action icon="add_circle" iconSize="large" (click)="createEmailTemplate()">{{pageTranslation.addEmailTemplateTooltip}}</orgos-action>
  </div>

  <orgos-column-container>
    <orgos-column size="0" class="set-filters-column kenjo-mr-20px">
      <orgos-container aspect="card">
        <div class="rpl-search kenjo-ph-20px">
          <orgos-input-simple-text [label]="pageTranslation.search" suffixIcon="search" (valueChange)="changeFilter('search', $event)" class="kenjo-block"></orgos-input-simple-text>
        </div>
      </orgos-container>

      <mat-accordion displayMode="flat" multi="true" *ngIf="standardPicklistsTranslation">
        <mat-expansion-panel *ngIf="allCategories.length > 0">
          <mat-expansion-panel-header *ngIf="standardPicklistsTranslation.emailTemplateCategory">
            <mat-panel-title>{{objectTranslation.category}}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox *ngFor="let iCategory of allCategories" [label]="standardPicklistsTranslation.emailTemplateCategory[iCategory]" [value]="filters[iCategory]" (valueChange)="changeFilter('category-'+iCategory, $event)" class="kenjo-block kenjo-mb-10px">
          </orgos-input-simple-checkbox>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="allLanguages.length > 0">
          <mat-expansion-panel-header *ngIf="standardPicklistsTranslation.emailTemplateLanguage">
            <mat-panel-title>{{objectTranslation.language}}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox *ngFor="let iLanguage of allLanguages" [label]="standardPicklistsTranslation.emailTemplateLanguage[iLanguage]" [value]="filters[iLanguage]" (valueChange)="changeFilter('language-'+iLanguage, $event)" class="kenjo-block kenjo-mb-10px">
          </orgos-input-simple-checkbox>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="allCompanies.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>{{objectTranslation.companyId}}</mat-panel-title>
          </mat-expansion-panel-header>
          <orgos-input-simple-checkbox *ngFor="let iCompany of allCompanies" [label]="iCompany.name" [value]="filters[iCompany._id]" (valueChange)="changeFilter('company-'+iCompany._id, $event)" class="kenjo-block kenjo-mb-10px"></orgos-input-simple-checkbox>
        </mat-expansion-panel>
      </mat-accordion>
    </orgos-column>

    <orgos-column>
      <orgos-container aspect="card">
        <div *ngIf="filteredData.length === 0" centerColumns="true" class="kenjo-text-align-center kenjo-p-40px">
          <div class="kenjo-font-size-16px"><mat-icon class="kenjo-mr-5px kenjo-font-color-success-00b72e">info_outline</mat-icon>{{pageTranslation.noTemplatesTitle}}</div>
          <div class="kenjo-mt-20px kenjo-font-color-light-text-757575">{{pageTranslation.noTemplatesSubtitle}}</div>
        </div>

        <orgos-table [fullHeight]="true" *ngIf="filteredData.length > 0 && standardPicklistsTranslation" [data]="filteredData" [displayedColumns]="displayedColumns" [sortingDataAccessor]="sortingDataAccessor">
          <orgos-table-column columnKey="category" [header]="objectTranslation.category" [sortable]="true">
            <ng-container *orgosTableCell="let row">
              {{row.category && standardPicklistsTranslation.emailTemplateCategory[row.category] ? standardPicklistsTranslation.emailTemplateCategory[row.category] : row.category}}
            </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="name" [header]="objectTranslation.name" [sortable]="true">
            <a *orgosTableCell="let row" (click)="editEmailTemplate(row)">{{row.name}} </a>
          </orgos-table-column>
          <orgos-table-column columnKey="inCollection" [header]="objectTranslation.inCollection" [sortable]="true">
            <ng-container *orgosTableCell="let row">{{getLabel(row.inCollection)}}</ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="language" [header]="objectTranslation.language" [sortable]="true">
            <ng-container *orgosTableCell="let row">{{standardPicklistsTranslation.emailTemplateLanguage[row.language]}} </ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="companyId" [header]="objectTranslation.companyId" [sortable]="true">
            <ng-container *orgosTableCell="let row"> {{mapCompanyIdToCompany[row.companyId] ? mapCompanyIdToCompany[row.companyId].name : ''}}</ng-container>
          </orgos-table-column>
          <orgos-table-column columnKey="actions" [actionColumn]="true">
            <ng-container *orgosTableCell="let row">
              <kenjo-icon [size]="20" class="kenjo-cursor-pointer" [matMenuTriggerFor]="actionsMenu">
                more_vert
              </kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="editEmailTemplate(row)">
                  <span>{{pageTranslation.editButtonLabel}}</span>
                </button>
                <button mat-menu-item (click)="deleteEmailTemplate(row)">
                  <span>{{pageTranslation.deleteButtonLabel}}</span>
                </button>
              </mat-menu>
            </ng-container>
          </orgos-table-column>
        </orgos-table>
      </orgos-container>
    </orgos-column>
  </orgos-column-container>
</ng-container>

<orgos-edit-email-template [emailTemplate]="emailTemplateToEdit" *ngIf="emailTemplateToEdit" (goBack)="resetEmailTemplateToEdit($event)"></orgos-edit-email-template>
