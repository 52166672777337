<orgos-dialog-container class="dialog-box" *ngIf="workingArea" [title]="isNewWorkingArea ? dialogTranslation.dialogAddWorkingArea : dialogTranslation.dialogEditWorkingArea" (clickCloseButton)="closeDialog()">
  <orgos-column-container class="kenjo-pt-20px" *ngIf="isNewWorkingArea">
    <orgos-input-text orgosColumn [label]="dialogTranslation.workingAreaName" [model]="workingArea" field="name" [minlength]="3" [maxlength]="51" [required]="true" class="kenjo-mr-10px" (validation)="nameCreateValidation = $event">
      <orgos-input-error *ngIf="nameCreateValidation && nameCreateValidation.getError('required')"> {{dialogTranslation.nameRequired}}</orgos-input-error>
      <orgos-input-error *ngIf="nameCreateValidation && !nameCreateValidation.getError('required') && nameCreateValidation.getError('minlength')"> {{dialogTranslation.workingAreaMinLenght}} </orgos-input-error>
      <orgos-input-error *ngIf="nameCreateValidation && !nameCreateValidation.getError('required') && nameCreateValidation.getError('maxlength')"> {{dialogTranslation.workingAreaMaxLenght}} </orgos-input-error>
    </orgos-input-text>
  </orgos-column-container>
  <orgos-column-container class="kenjo-pt-20px" *ngIf="!isNewWorkingArea">
    <orgos-input-text orgosColumn [label]="dialogTranslation.workingAreaName" [model]="workingArea" field="name" [minlength]="3" [maxlength]="51" [required]="true" class="kenjo-mr-10px" (validation)="nameEditValidation = $event">
      <orgos-input-error *ngIf="nameEditValidation && nameEditValidation.getError('required')"> {{dialogTranslation.nameRequired}}</orgos-input-error>
      <orgos-input-error *ngIf="nameEditValidation && !nameEditValidation.getError('required') && nameEditValidation.getError('minlength')"> {{dialogTranslation.workingAreaMinLenght}} </orgos-input-error>
      <orgos-input-error *ngIf="nameEditValidation && !nameEditValidation.getError('required') && nameEditValidation.getError('maxlength')"> {{dialogTranslation.workingAreaMaxLenght}} </orgos-input-error>
    </orgos-input-text>
  </orgos-column-container>
  <div class="kenjo-mt-60px kenjo-text-align-right">
    <orgos-button-raised id="aewad-go-back-btn" class="kenjo-mr-20px" color="Neutral" (click)="closeDialog()">{{ dialogTranslation.goBackButtonDialog }}</orgos-button-raised>
    <orgos-button-raised id="aewad-add-working-area-btn" *ngIf="isNewWorkingArea" color="Success" (click)="addWorkingArea()" [disabled]="!nameCreateValidation || nameCreateValidation.hasErrors()"> {{dialogTranslation.saveButtonLabel}} </orgos-button-raised>
    <orgos-button-raised id="aewad-edit-working-area-btn" *ngIf="!isNewWorkingArea" color="Success" (click)="editWorkingArea()" [disabled]="!nameEditValidation || nameEditValidation.hasErrors()"> {{dialogTranslation.saveButtonLabel}} </orgos-button-raised>
  </div>
</orgos-dialog-container>
