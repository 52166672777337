import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class QuestionService {
  private QUESTION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/question-db`;

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  getQuestions(arrayQuestionIds: Array<string>): Promise<Array<any>> {
    const findBody = {
      _id: { $in: arrayQuestionIds }
    };

    return new Promise<Array<any>>((resolve, reject) => {
      this.genericService
        .find(this.QUESTION_URL, findBody)
        .then((questions: Array<any>) => {
          resolve(questions);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, QuestionService.name, 'getQuestions'));
        });
    });
  }
}
