<div *ngIf="i18n && permissionsPackage && permissionValues">
  <!-- MAIN HEADER -->
  <div *ngIf="packageKey" class="kenjo-pv-10px kenjo-ph-20px kenjo-mb-10px kenjo-border-bottom kenjo-font-size-16px">
    {{ i18n[packageKey] }}
  </div>

  <div class="kenjo-ph-20px" *ngIf="permissionsPackage">
    <!-- AREAS -->
    <div *ngFor="let area of permissionsPackage" class="kenjo-pb-15px">
      <div class="kenjo-font-size-14px kenjo-pt-10px kenjo-pb-5px ppc-area-title">{{ i18n[area.name] }}</div>
      <!-- INDIVIDUAL PERMISSIONS, GROUPS OF PERMISSIONS OR COLLECTIONS -->
      <div *ngIf="area?.permissions?.length > 0">
        <div *ngFor="let permission of area.permissions" [class.kenjo-pv-10px]="permission.type === 'group' || permission.type === 'set' || permission.type === 'collection'">
          <!-- INDIVIDUAL -->
          <div *ngIf="permission.type === 'individual'">
            <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
              <orgos-column size="0" class="kenjo-pr-10px">
                <orgos-input-simple-checkbox
                  [readOnly]="readOnly || (readOnlyValues?.[packageKey]?.[area.key]?.[permission.key])"
                  [value]="permissionValues?.[packageKey]?.[area.key]?.[permission.key] ?? false"
                  (valueChange)="permissionValues[packageKey][area.key][permission.key] = $event; emitChange()"
                >
                </orgos-input-simple-checkbox>
              </orgos-column>
              <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px" [class.kenjo-font-color-text-000000]="(permission.type === 'group' || permission.type === 'collection') && permission.isHeader === true">
                {{ i18n[permission.name] }}
              </orgos-column>
            </orgos-column-container>
          </div>

          <!-- SET -->
          <div *ngIf="permission.type === 'set' || permission.type === 'set_permissions'" class="kenjo-pt-10px">
            <div *ngIf="permission.type === 'set'" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
              {{ i18n[permission.name] }}
            </div>
            <div *ngIf="permission.type === 'set_permissions'" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px ppc-collection-title">
              {{ i18n[permission.name] }}
            </div>
            <div *ngIf="permission.permissions && permission.permissions.length > 0">
              <div *ngFor="let individualPermission of permission.permissions">
                <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
                  <orgos-column size="0" class="kenjo-pr-10px" *ngIf="!individualPermission.isHeader && !individualPermission.key.endsWith('_custom')">
                    <orgos-input-simple-checkbox
                      [readOnly]="readOnly || (readOnlyValues?.[packageKey]?.[area.key]?.[individualPermission.key])"
                      [value]="permissionValues?.[packageKey]?.[area.key]?.[individualPermission.key] ?? false"
                      (valueChange)="permissionValues[packageKey][area.key][individualPermission.key] = $event; emitChange()"
                    >
                    </orgos-input-simple-checkbox>
                  </orgos-column>
                  <orgos-column size="0" *ngIf="individualPermission.key.endsWith('_custom')">
                    <orgos-action icon="add_circle" class="ppc-add-icon" (click)="openCustomPermissionsDialog(individualPermission.key, area.key)" [disabled]="readOnly || (readOnlyValues?.[packageKey]?.[area.key]?.[individualPermission.key])" color="Primary"> </orgos-action>
                  </orgos-column>
                  <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
                    {{ i18n[individualPermission.name] }}
                  </orgos-column>
                </orgos-column-container>
                <orgos-custom-permission-summary
                  *ngIf="individualPermission?.key?.endsWith('_custom') && packageKey && permissionValues[packageKey]?.[area.key]?.[individualPermission.key] && !(readOnlyValues?.[packageKey]?.[area.key]?.[individualPermission.key])"
                  [customPermission]="permissionValues[packageKey]?.[area.key]?.[individualPermission.key]"
                  [permissionKey]="packageKey"
                  class="kenjo-pl-20px kenjo-pr-10px"
                >
                </orgos-custom-permission-summary>
              </div>
            </div>
          </div>

          <!-- COLLECTION -->
          <div *ngIf="permission.type === 'collection'">
            <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px ppc-collection-title">
              {{ i18n[permission.name] }}
            </div>
            <div *ngIf="permission.permissions && permission.permissions.length > 0">
              <div *ngFor="let individualPermission of permission.permissions">
                <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
                  <orgos-column size="0" class="kenjo-pr-10px" *ngIf="!individualPermission.isHeader && !individualPermission?.key?.endsWith('_custom')">
                    <orgos-input-simple-checkbox
                      [readOnly]="readOnly || (readOnlyValues?.[permission.key]?.[individualPermission.key])"
                      [value]="permissionValues?.[permission.key]?.[individualPermission.key] ?? false"
                      (valueChange)="permissionValues[permission.key][individualPermission.key] = $event; emitChange(permission.key)"
                    >
                    </orgos-input-simple-checkbox>
                  </orgos-column>
                  <orgos-column size="0" *ngIf="individualPermission?.key?.endsWith('_custom')">
                    <orgos-action icon="add_circle" class="ppc-add-icon" (click)="openCustomPermissionsDialog(individualPermission.key, area.key)" [disabled]="readOnly || (readOnlyValues?.[permission.key]?.[individualPermission.key])" color="Primary"> </orgos-action>
                  </orgos-column>
                  <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
                    {{ i18n[individualPermission.name] }}
                  </orgos-column>
                </orgos-column-container>
                <orgos-custom-permission-summary
                  *ngIf="individualPermission?.key?.endsWith('_custom') && packageKey && permissionValues[permission.key]?.[individualPermission.key] && !(readOnlyValues?.[permission.key]?.[individualPermission.key])"
                  [customPermission]="permissionValues[permission.key]?.[individualPermission.key]"
                  [permissionKey]="packageKey"
                  class="kenjo-pl-20px kenjo-pr-10px"
                >
                </orgos-custom-permission-summary>
                <div *ngIf="individualPermission.permissions && individualPermission.permissions.length > 0">
                  <div *ngFor="let individualSubPermission of individualPermission.permissions">
                    <orgos-column-container centerColumns="true" class="kenjo-pt-10px kenjo-pl-20px">
                      <orgos-column size="0" class="kenjo-pr-10px" *ngIf="!individualSubPermission?.key?.endsWith('_custom')">
                        <orgos-input-simple-checkbox
                          [readOnly]="readOnly || (readOnlyValues?.[permission.key]?.[individualSubPermission.key])"
                          [value]="permissionValues?.[permission.key]?.[individualSubPermission.key] ?? false"
                          (valueChange)="permissionValues[permission.key][individualSubPermission.key] = $event; emitChange(permission.key)"
                        >
                        </orgos-input-simple-checkbox>
                      </orgos-column>
                      <orgos-column size="0" *ngIf="individualSubPermission?.key?.endsWith('_custom')">
                        <orgos-action icon="add_circle" class="ppc-add-icon" (click)="openCustomPermissionsDialog(individualSubPermission.key, area.key)" [disabled]="readOnly || (readOnlyValues?.[permission.key]?.[individualSubPermission.key])" color="Primary"> </orgos-action>
                      </orgos-column>
                      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
                        {{ i18n[individualSubPermission.name] }}
                      </orgos-column>
                    </orgos-column-container>
                    <orgos-custom-permission-summary
                      *ngIf="individualSubPermission?.key?.endsWith('_custom') && packageKey && permissionValues[permission.key]?.[individualSubPermission.key] && !(readOnlyValues?.[permission.key]?.[individualSubPermission.key])"
                      [customPermission]="permissionValues[permission.key]?.[individualSubPermission.key]"
                      [permissionKey]="packageKey"
                      class="kenjo-pl-20px kenjo-pr-10px"
                    >
                    </orgos-custom-permission-summary>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- GROUP -->
          <div *ngIf="permission.type === 'group'">
            <div size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
              {{ i18n[permission.name] }}
            </div>
            <!-- CONTROLLER CHECKBOXES -->
            <div *ngIf="permission.permissionControl && permission.permissionControl.length > 0">
              <div *ngFor="let permissionControl of permission.permissionControl">
                <orgos-column-container centerColumns="true" class="kenjo-pt-10px">
                  <orgos-column size="0" class="kenjo-pr-10px" *ngIf="!permissionControl?.key?.endsWith('_custom')">
                    <orgos-input-simple-checkbox
                      [readOnly]="readOnly || (readOnlyValues?.[packageKey]?.[area.key]?.[permissionControl.key])"
                      [value]="permissionValues?.[packageKey]?.[area.key]?.[permissionControl.key] ?? false"
                      (valueChange)="permissionValues[packageKey][area.key][permissionControl.key] = $event; emitChange()"
                    >
                    </orgos-input-simple-checkbox>
                  </orgos-column>
                  <orgos-column size="0" *ngIf="permissionControl?.key?.endsWith('_custom')">
                    <orgos-action icon="add_circle" class="ppc-add-icon" (click)="openCustomPermissionsDialog(permissionControl.key, area.key)" [disabled]="readOnly || (readOnlyValues?.[packageKey]?.[area.key]?.[permissionControl.key])" color="Primary"> </orgos-action>
                  </orgos-column>
                  <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
                    {{ i18n[permissionControl.name] }}
                  </orgos-column>
                </orgos-column-container>
                <!--TODO: permissionValues[packageKey]?.[area.key]?.[permissionControl.key] is empty-->
                <orgos-custom-permission-summary
                  *ngIf="permissionControl?.key?.endsWith('_custom') && packageKey && permissionValues[packageKey]?.[area.key]?.[permissionControl.key] && !(readOnlyValues?.[packageKey]?.[area.key]?.[permissionControl.key])"
                  [customPermission]="permissionValues[packageKey]?.[area.key]?.[permissionControl.key]"
                  [permissionKey]="packageKey"
                  class="kenjo-pl-20px kenjo-pr-10px"
                >
                </orgos-custom-permission-summary>
              </div>
            </div>
            <!-- SECTIONS -->
            <div *ngIf="permission.permissionSections && permission.permissionSections.length > 0">
              <div *ngFor="let section of permission.permissionSections; let rowIndex = index; let last = last; let first = first">
                <!-- SECTION HEADER -->
                <orgos-column-container centerColumns="true" *ngIf="first && permission.permissionSections.length !== 1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px ppc-section-item ppc-section-border kenjo-mt-10px">
                  <orgos-column size="0" class="ppc-section-horizontal-line"> </orgos-column>
                  <orgos-column size="1" class="ppc-section-header">
                    {{ i18n[section.name] }}
                  </orgos-column>
                </orgos-column-container>
                <div *ngIf="!first || (first && permission.permissionSections.length === 1)">
                  <div class="kenjo-pt-10px" [class.ppc-fake-margin]="!first"></div>
                  <orgos-column-container centerColumns="true" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px ppc-section-item">
                    <orgos-column size="0">
                      <div class="ppc-corner-section"></div>
                      <div class="ppc-corner-section-fake-layer" [class.ppc-corner-section-fake-layer-border]="!last"></div>
                    </orgos-column>
                    <orgos-column size="1" class="ppc-section-header">
                      {{ i18n[section.name] }}
                    </orgos-column>
                  </orgos-column-container>
                </div>
                <!-- SECTION PERMISSIONS -->
                <div *ngIf="section.permissions && section.permissions.length > 0">
                  <div *ngFor="let sectionPermission of section.permissions">
                    <orgos-column-container centerColumns="true" class="ppc-section-item kenjo-pl-15px kenjo-pt-10px" [class.ppc-section-border]="!last">
                      <orgos-column size="0" class="kenjo-pr-10px" *ngIf="!sectionPermission?.key?.endsWith('_custom')">
                        <orgos-input-simple-checkbox
                          [readOnly]="readOnly || (readOnlyValues?.[packageKey]?.[area.key]?.[sectionPermission.key])"
                          [value]="permissionValues?.[packageKey]?.[area.key]?.[sectionPermission.key] ?? false"
                          (valueChange)="permissionValues[packageKey][area.key][sectionPermission.key] = $event; emitChange()"
                        >
                        </orgos-input-simple-checkbox>
                      </orgos-column>
                      <orgos-column size="0" *ngIf="sectionPermission?.key?.endsWith('_custom')">
                        <orgos-action icon="add_circle" class="ppc-add-icon" (click)="openCustomPermissionsDialog(sectionPermission.key, area.key)" [disabled]="readOnly || (readOnlyValues?.[packageKey]?.[area.key]?.[sectionPermission.key])" color="Primary"> </orgos-action>
                      </orgos-column>
                      <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">
                        {{ i18n[sectionPermission.name] }}
                      </orgos-column>
                    </orgos-column-container>
                    <orgos-custom-permission-summary
                      *ngIf="sectionPermission?.key?.endsWith('_custom') && packageKey && permissionValues[packageKey]?.[area.key]?.[sectionPermission.key] && !(readOnlyValues?.[packageKey]?.[area.key]?.[sectionPermission.key])"
                      [customPermission]="permissionValues?.[packageKey]?.[area.key]?.[sectionPermission.key]"
                      [permissionKey]="packageKey"
                      class="kenjo-pl-20px kenjo-pr-10px"
                    >
                    </orgos-custom-permission-summary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
