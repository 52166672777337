import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-input-code-number',
  templateUrl: './input-code-number.component.html',
  styleUrls: ['./input-code-number.component.scss'],
})
export class InputCodeNumberComponent implements OnInit {
  errorMessage: string;

  isTouched = false;
  isDirty = false;
  hasErrors = false;
  error: InputCodeNumberErrors;

  @Input() value: string;
  @Input() reference: string;
  @Input() maxLength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() readOnly: boolean = false;
  @Input() decimals: boolean = true;
  @Input() width: number;
  @Input() errorTranslation: { [key: string]: string };
  @Input() required: boolean = false;
  @Input() forceError: boolean = false;

  @Output() onValidateValue: EventEmitter<{ [key: string]: any }> = new EventEmitter();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    try {
      if (check.not.assigned(this.errorTranslation)) {
        this.errorTranslation = await this.injector.get(InternationalizationService).getAllTranslation('input-code-number-errors');
      }

      if (check.assigned(this.value)) {
        this.isDirty = true;
        this.isTouched = true;
      }
    } catch (error) {
      this.errorTranslation = {};
    }
  }

  onBlur(event: any): void {
    if (!this.isTouched) {
      this.isTouched = true;
    }

    if (check.assigned(event.target.value) && check.nonEmptyString(event.target.value)) {
      this.isDirty = true;
      this.validateField(event.target.value.toString());
      this.onValidateValue.emit({
        reference: this.reference,
        hasErrors: this.hasErrors,
        error: this.error,
        value: event.target.value.toString(),
      });
    }
  }

  onValueChange(value: number): void {
    if (!this.isDirty) {
      this.isDirty = true;
    }

    const valueToString = value ? value.toString() : '';

    this.validateField(valueToString);
    this.onValidateValue.emit({ reference: this.reference, hasErrors: this.hasErrors, error: this.error, value: valueToString });
  }

  private validateField(value: string): void {
    if (!this.isTouched) {
      return;
    }

    this.validateRequiredField(value);
    if (this.hasErrors) {
      return;
    }
    this.validateMinMaxField(value);
    if (this.hasErrors) {
      return;
    }
    this.validateOnlyIntegersField(value);
    if (this.hasErrors) {
      return;
    }

    if (
      check.not.assigned(this.maxLength) ||
      (check.assigned(this.maxLength) && !this.required && (check.not.assigned(value) || check.emptyString(value)))
    ) {
      this.hasErrors = false;
      this.error = undefined;
      return;
    }

    this.validateLengthField(value);
  }

  private validateRequiredField(value: string): void {
    if (!this.required) {
      return;
    }

    if (check.assigned(value) && check.nonEmptyString(value)) {
      this.hasErrors = false;
      this.error = undefined;
      return;
    }

    this.hasErrors = true;
    this.error = 'Mandatory';
    this.errorMessage = this.errorTranslation[`${this.reference}Mandatory`];
    return;
  }

  private validateLengthField(value: string): void {
    if (check.assigned(value) && value.trim().length <= this.maxLength) {
      this.hasErrors = false;
      this.error = undefined;
      return;
    }

    this.hasErrors = true;
    this.error = 'Length';
    this.errorMessage = this.errorTranslation[`${this.reference}Length`];
  }

  private validateMinMaxField(value: string): void {
    if (check.nonEmptyString(value)) {
      if (check.assigned(this.min) && +value < this.min) {
        this.hasErrors = true;
        this.error = 'Min';
        this.errorMessage = this.errorTranslation[`${this.reference}Min`];
      }
      if (check.assigned(this.max) && +value > this.max) {
        this.hasErrors = true;
        this.error = 'Max';
        this.errorMessage = this.errorTranslation[`${this.reference}Max`];
      }
    }
  }

  private validateOnlyIntegersField(value: string): void {
    if (check.nonEmptyString(value)) {
      if (this.decimals === false && !Number.isInteger(+value)) {
        this.hasErrors = true;
        this.error = 'Decimals';
        this.errorMessage = this.errorTranslation[`${this.reference}Decimals`];
      }
    }
  }
}

type InputCodeNumberErrors = 'Mandatory' | 'Length' | 'Min' | 'Max' | 'Decimals';
