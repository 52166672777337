import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IApplicantsPerPosition } from '@app/models/controllers/recruiting.model';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IPreferenceModel, PreferenceService } from '@app/standard/services/preference/preference.service';
import { WidgetsService } from '@app/standard/services/widgets/widgets.service';
import * as check from 'check-types';

@Component({
  selector: 'orgos-widget-new-applicant',
  templateUrl: 'orgos-widget-new-applicant.component.html',
  styleUrls: ['orgos-widget-new-applicant.component.scss']
})
export class WidgetNewApplicantComponent implements OnInit {
  translation: any = {};
  loadingWidget: boolean = false;
  showRejected: boolean = true;
  preferenceColumnsKey: string = 'new-applicants-widget';
  preferenceType: string = 'home';
  newApplicants: Array<IApplicantsPerPosition>;
  positionIdContainsNonRejected: { [key: string]: boolean }; // required to hide position in case all candidates of a position are rejected

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.loadingWidget = true;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('home-widget-new-applicant-component')
      .then((translation) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    Promise.all([this.getWidgetInformation(), this.getRejectedOption()])
      .then(() => {
        this.loadingWidget = false;
      })
      .catch(() => {
        this.loadingWidget = false;
      });
  }

  private getWidgetInformation(): Promise<void> {
    return new Promise((resolve) => {
      this.injector
        .get(WidgetsService)
        .getNewApplicants()
        .then((newApplicantsRes: Array<IApplicantsPerPosition>) => {
          this.newApplicants = newApplicantsRes;
          this.initPositionContainsRejected();
          resolve();
        })
        .catch((error) => {
          this.newApplicants = [];
          resolve();
        });
    });
  }

  private initPositionContainsRejected(): void {
    this.positionIdContainsNonRejected = {};
    this.newApplicants.forEach((iNewApplicant: IApplicantsPerPosition) => {
      this.positionIdContainsNonRejected[iNewApplicant.position._id] = false;
      if (check.assigned(iNewApplicant.applicants) && iNewApplicant.applicants.length > 0) {
        const nonRejected = iNewApplicant.applicants.find((iApplicant) => {
          return iApplicant.positionCandidate.isDisqualified === false;
        });
        if (check.assigned(nonRejected) && check.nonEmptyObject(nonRejected)) {
          this.positionIdContainsNonRejected[iNewApplicant.position._id] = true;
        }
      }
    });
  }

  public changeRejectedVisibility(): void {
    this.showRejected = !this.showRejected;
    this.saveRejectedOption(!this.showRejected);
  }

  public navigateToCandidatePage(candidateId: string, positionId: string): void {
    this.injector.get(Router).navigateByUrl(`cloud/recruiting/candidate/${candidateId}/${positionId}`);
  }

  private saveRejectedOption(hideRejected: boolean): void {
    this.injector
      .get(PreferenceService)
      .setPreferenceByKey(this.preferenceColumnsKey, { hideRejected: hideRejected }, this.preferenceType)
      .then(() => {})
      .catch(() => {
        // Do nothing
      });
  }

  private getRejectedOption(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.injector
        .get(PreferenceService)
        .getPreferenceByKey(this.preferenceColumnsKey)
        .then((preferenceResult: IPreferenceModel) => {
          if (check.not.assigned(preferenceResult) || check.not.assigned(preferenceResult.preference) || check.emptyObject(preferenceResult.preference)) {
            this.showRejected = true;
            resolve();
            return;
          }

          this.showRejected = !preferenceResult.preference.hideRejected;
          resolve();
        })
        .catch((error) => {
          // Do nothing
          reject(error);
        });
    });
  }
}
