import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as check from 'check-types';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';

@Component({
  selector: 'orgos-input-simple-text',
  templateUrl: 'input-simple-text.component.html',
  styleUrls: ['input-simple-text.component.scss']
})
export class InputSimpleTextComponent implements OnInit {
  isValueValid: boolean = true;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() nonClick: boolean = false;
  @Input() required: boolean = false;
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() suffixIcon: string;
  @Input() debounceNewValues: boolean | 'true' | 'false';
  @Input() debounceTimer: number = 2000;
  @Input() placeholder: string = '';
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() showClearButton: boolean = false;
  @Input() appearance: string = 'standard';
  @Input() forceError: boolean = false;
  @Input() enableAlphaNumericFilter: boolean = false;

  private newValueDebounced: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.makeValidation(this.value);

    if (this.debounceNewValues === true || this.debounceNewValues === 'true') {
      this.newValueDebounced
        .pipe(
          debounce(() => {
            // If we are clearing the value, we do not delay the operation
            const time = this.value === null ? 0 : this.debounceTimer;
            return timer(time);
          }),
          distinctUntilChanged()
        )
        .subscribe((newValue) => {
          this.valueChange.emit(newValue);
        });
    }
  }

  onValueChange(newValue: any): void {
    this.value = newValue;
    const valueToValidate = newValue.trim();

    this.makeValidation(valueToValidate);

    if (this.isValueValid) {
      if (this.debounceNewValues === true || this.debounceNewValues === 'true') {
        this.newValueDebounced.next(newValue);
      } else {
        this.valueChange.emit(newValue);
      }
    }
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (check.assigned(newValue) && check.assigned(this.maxlength) && newValue.length > this.maxlength) {
      inputValidation.setError('maxlength');
    }

    if (check.assigned(newValue) && check.assigned(this.minlength) && newValue.length < this.minlength) {
      inputValidation.setError('minlength');
    }

    if (check.assigned(this.customValidation)) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }
}
