import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as Chart from 'chart.js';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-report-summary',
  templateUrl: 'report-summary.component.html',
  styleUrls: ['report-summary.component.scss']
})
export class ReportSummaryComponent implements OnInit {
  @Input() template: any;
  @Input() reportGrouping: any;
  @Input() pageTranslation: any = {};
  @Input() groupTranslation: string;
  @Input() summaryTranslation: string;
  @Input() idToName: any = {};

  MONTH_NAMES: Array<string> = [];
  MAX_GROUPS_IN_REPORT: number = 25;
  showChart: boolean = false;
  @ViewChild('chartElement', { static: true }) chartElement: ElementRef;

  displayedColumns: Array<string> = [];
  stackedDataForDataSource: Array<any> = [];
  mapStackToDataSet: any = {};
  emptyLabel: string = '';
  summaryTable: Array<any> = [];
  mapColumnLabelToTranslation: any = {};
  labels: Array<string> = [];

  maxNumberOfGroups: number = 0;

  ngOnInit(): void {
    this.MONTH_NAMES = moment.months();
    this.buildDisplay();
  }

  private buildDisplay(): void {
    // table
    this.initColumns();
    _.forOwn(this.reportGrouping, (groupResults, groupName) => {
      const iRow = groupResults;
      const label = this.idToName[groupName] ? this.idToName[groupName] : groupName;
      this.labels.push(label);
      groupResults[this.displayedColumns[0]] = label;
      this.summaryTable.push(iRow);
    });
    this.labels = this.labels.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      return (b < a) ? 1 : 0;
    });
    this.sortTable();

    // chart
    if (check.assigned(this.template.metadata) && check.assigned(this.template.metadata.comparison) && check.array(this.template.metadata.comparison) && check.not.emptyArray(this.template.metadata.comparison)) {
      this.showChart = true;
      this.drawChart();
    }
  }

  private initColumns(): void {
    this.displayedColumns = [this.getGroupTranslation()].concat(
      this.template.metadata.summaries.map((iMetadataSummary) => {
        this.mapColumnLabelToTranslation[iMetadataSummary.label] = this.pageTranslation[iMetadataSummary.label] ? this.pageTranslation[iMetadataSummary.label] : iMetadataSummary.label;
        return iMetadataSummary.label;
      })
    );
  }

  public getGroupTranslation(): string {
    return check.assigned(this.groupTranslation) ? this.groupTranslation : '';
  }

  private sortTable(): void {
    this.summaryTable = _.sortBy(this.summaryTable, (iElement) => {
      return iElement[this.displayedColumns[0]];
    });
  }

  drawChart(): void {
    this.emptyLabel =
      check.assigned(this.template.metadata) && check.assigned(this.template.metadata.grouping) && check.assigned(this.template.metadata.grouping.emptyGroupLevel2Label) && check.nonEmptyString(this.template.metadata.grouping.emptyGroupLevel2Label)
        ? this.template.metadata.grouping.emptyGroupLevel2Label
        : '';
    if (check.assigned(this.pageTranslation[this.emptyLabel])) {
      this.emptyLabel = this.pageTranslation[this.emptyLabel];
    }

    const chartCtx = this.chartElement.nativeElement.getContext('2d');

    const dataSets = [];

    this.template.metadata.comparison.forEach((iSummaryField, index) => {
      const iColor = this.getUserColor(index);
      const iDataset = {
        label: this.pageTranslation[iSummaryField] ? this.pageTranslation[iSummaryField] : iSummaryField,
        backgroundColor: iColor,
        data: [],
        borderColor: iColor,
        hoverBorderWidth: 2
      };
      dataSets.push(iDataset);
    });

    this.summaryTable.forEach((row) => {
      this.template.metadata.comparison.forEach((iSummaryField, index) => {
        const value = check.assigned(row[iSummaryField]) ? row[iSummaryField] : 0;
        dataSets[index].data.push(value);
      });
    });

    this.maxNumberOfGroups = dataSets.length;
    if (this.maxNumberOfGroups > this.MAX_GROUPS_IN_REPORT) {
      return;
    }
    check.assigned(
      new Chart(chartCtx, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: dataSets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                },
                scaleLabel: {
                  display: true,
                  labelString: this.getSummaryTranslation()
                }
              }
            ]
          },
          animation: {
            duration: 500,
            easing: 'linear'
          }
        }
      })
    );
  }

  getSummaryTranslation(): string {
    return check.assigned(this.summaryTranslation) ? this.summaryTranslation : '';
  }

  private getUserColor(index: number): string {
    if (index < 1) {
      return userColorConstants['USER_COLOR_1'];
    } else if (index >= Object.keys(userColorConstants).length) {
      const colorNumber = (index % Object.keys(userColorConstants).length) + 1;
      return userColorConstants[`USER_COLOR_${colorNumber}`];
    } else {
      const colorNumber = index + 1;
      return userColorConstants[`USER_COLOR_${colorNumber}`];
    }
  }
}
