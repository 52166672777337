import { Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { IRequestAttachment } from '@app/cloud-features/time-off/services/time-off-user-history.controller';
import { AttachmentError, PrivateUppyService } from '@app/private/services/private-uppy.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { environment } from '@env';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-time-off-request-attachments',
  templateUrl: 'time-off-request-attachments.component.html',
  styleUrls: ['time-off-request-attachments.component.scss']
})
export class TimeOffRequestAttachmentsComponent implements OnInit {
  @Input() translations: any;
  @Input() oldRequestAttachments: Array<IRequestAttachment>;
  @Input() requestPermissions: { canDeleteAttachment: boolean; canCreateAttachment: boolean };
  @Output() update: EventEmitter<ITimeOffAttachmentUpdate> = new EventEmitter<ITimeOffAttachmentUpdate>();
  @Output() onExpand: EventEmitter<void> = new EventEmitter<void>();

  attachmentError = AttachmentError;
  DOCUMENTS_MAX_SIZE = environment.MAX_SIZE_FOR_DOCUMENTS;
  DOCUMENTS_MAX_NUM = environment.MAX_ATTACHMENT_COUNT;

  hasPreviousAttachments = false;
  attachments: Array<ITimeOffAttachment> = [];
  attachmentsIdsToDelete: Array<string> = [];
  uploadingFiles: boolean = false;
  progress: number = 0;
  draggingOver: boolean = false;
  hasError: boolean = false;
  discardedAttachments = [];

  @ViewChild('documentDropZone') documentDropZone: ElementRef;

  constructor(private injector: Injector, private elementRef: ElementRef, private ngZone: NgZone) {}

  ngOnInit(): void {
    this.initDocuments();
    this.initDropZone();
  }

  private initDocuments() {
    this.hasPreviousAttachments = !!(this.oldRequestAttachments?.length > 0);
    if (check.assigned(this.oldRequestAttachments)) {
      Object.values(this.oldRequestAttachments).forEach((requestAttachment) => {
        this.attachments.push({
          id: requestAttachment.id,
          name: requestAttachment.documentName,
          size: this.getDocumentSize(requestAttachment.documentSize),
          hasError: false,
          isNew: false
        });
      });
      this.emitUpdate();
    }
  }

  private async initDropZone(): Promise<void> {
    if (this.requestPermissions?.canCreateAttachment === true) {
      const documentDropPaneOptions = {
        allowMultipleUploadBatches: true,
        restrictions: {
          maxFileSize: parseInt(this.DOCUMENTS_MAX_SIZE, 10) * 1024 * 1024,
          maxNumberOfFiles: this.DOCUMENTS_MAX_NUM,
          allowedFileTypes: ['.pdf', '.ppt', '.pptx', '.xls', '.xlsx', '.doc', '.docx', '.rtf', '.odt', '.ods', '.odp', '.txt', '.png', '.jpeg', '.jpg', '.webp']
        }
      };
      const documents = await this.injector.get(PrivateUppyService).getDropClient(
        '#documentDropZone',
        documentDropPaneOptions,
        () => {
          this.ngZone.run(() => {
            this.progress = 0;
            this.uploadingFiles = true;
          });
        },
        (progress) => {
          this.ngZone.run(() => {
            this.progress = progress >= 100 ? 100 : progress;
          });
        },
        {
          onDragOver: () => {
            this.ngZone.run(() => {
              this.draggingOver = true;
            });
          },
          onDragLeave: () => {
            this.ngZone.run(() => {
              this.draggingOver = false;
            });
          }
        },
        (fileName, fileSize, error) => {
          this.ngZone.run(() => {
            this.processError(fileName, fileSize, error);
          });
        }
      );
      this.processUpload(documents);
    }
  }

  private processUpload(documents: Array<IFileMetadata>) {
    if (check.assigned(documents)) {
      documents.forEach((document) => {
        this.insertAttachment(document);
      });
    }
    this.draggingOver = false;
    this.uploadingFiles = false;
    this.emitUpdate();
    this.initDropZone();
  }
  private insertAttachment(document: IFileMetadata): void {
    if (this.attachments.length >= this.DOCUMENTS_MAX_NUM) {
      return;
    }
    let hasError = false;
    let error;
    if (this.attachments.some((iDocument) => iDocument.name === document._fileName) === true) {
      hasError = true;
      error = AttachmentError.DUPLICATED;
    }
    const attachment: ITimeOffAttachment = {
      document: document,
      name: document._fileName,
      size: this.getDocumentSize(document._fileSizeInBytes),
      hasError,
      isNew: true
    };
    if (hasError === true) {
      attachment.error = error;
      this.hasError = true;
    }
    this.attachments.push(attachment);
  }

  private processError(fileName: string, fileSize: number, error: AttachmentError): void {
    if (error !== AttachmentError.MAX_NUM) {
      const attachment: ITimeOffAttachment = {
        name: fileName,
        size: this.getDocumentSize(fileSize),
        hasError: true,
        error: error,
        isNew: true
      };
      this.attachments.push(attachment);
      this.hasError = true;
      this.draggingOver = false;
      this.emitUpdate();
    }
  }

  private getDocumentSize(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0B';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const size = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;

    return size;
  }

  public onUpload(): void {
    const button = this.elementRef.nativeElement.getElementsByClassName('uppy-FileInput-btn');
    button?.[0]?.click();
  }

  public onDelete(index: number, attachment: ITimeOffAttachment): void {
    if (this.hasError === true) {
      attachment.hasError = false;
      if (this.attachments.some((iAttachment, iIndex) => iIndex !== index && iAttachment.name === this.attachments[index].name)) {
        const otherAttachment = this.attachments.find((iAttachment) => iAttachment.error === AttachmentError.DUPLICATED);
        if (check.assigned(attachment)) {
          delete otherAttachment.error;
          otherAttachment.hasError = false;
        }
      }
      if (this.attachments.length <= this.DOCUMENTS_MAX_NUM + 1) {
        const attachments = this.attachments.filter((iAttachment) => iAttachment.error === AttachmentError.MAX_NUM);
        attachments.forEach((attachment) => {
          delete attachment.error;
          attachment.hasError = false;
        });
      }
      if (this.attachments.some((iAttachment) => iAttachment.hasError === true) === false) {
        this.hasError = false;
      }
    }
    if (attachment.isNew === false) {
      this.attachmentsIdsToDelete.push(attachment.id);
    }
    this.attachments.splice(index, 1);
    this.emitUpdate();
  }

  private emitUpdate() {
    this.update.emit({ hasError: this.hasError, attachments: this.attachments, attachmentsIdsToDelete: this.attachmentsIdsToDelete });
  }

  public onOpenExpansionPanel() {
    this.onExpand.emit();
  }
}

export interface ITimeOffAttachment {
  document?: IFileMetadata;
  name: string;
  size: string;
  hasError: boolean;
  error?: AttachmentError;
  isNew: boolean;
  id?: string;
}
export interface ITimeOffAttachmentUpdate {
  hasError: boolean;
  attachments: Array<ITimeOffAttachment>;
  attachmentsIdsToDelete: Array<string>;
}
