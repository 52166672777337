import 'quill-mention';
import '@app/standard/components/input-simple-editor/quill-normalization';

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnInit, Output } from '@angular/core';
import * as check from 'check-types';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';
import { InternationalizationService } from '../../services/core/internationalization.service';

@Component({
  selector: 'orgos-input-simple-editor',
  templateUrl: 'input-simple-editor.component.html',
  styleUrls: ['input-simple-editor.component.scss']
})
export class InputSimpleEditorComponent implements OnInit {
  isValueValid: boolean = true;
  miscTranslation: any = {};

  editor: any;
  focused: boolean = false;

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() showSavedHint: boolean = false;
  @Input() includeTextValue: boolean = false;
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Output() inputBlur: EventEmitter<void> = new EventEmitter<void>();
  @Input() clickOnCreate: boolean = false;
  @Input() mentionOptions: Array<IMentionOption>;

  quillSizeClass: string = 'iec-quill-inherit';
  @Input()
  set size(size: 'large' | 'medium' | 'small' | 'inherit') {
    this.quillSizeClass = `iec-quill-${size}`;
  }

  @Input() toolbar: Array<any> = [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ indent: '-1' }, { indent: '+1' }], [{ header: [1, 2, 3, 4, false] }], [{ align: [] }], ['link']];

  @Input() theme: 'bubble' | 'snow' = 'snow';

  modules: any = {
    toolbar: this.toolbar
  };

  style: any = {
    'font-family': 'Nunito, sans-serif'
  };

  customOptions = [{ import: 'attributors/style/align', whitelist: ['right', 'center', 'justify'] }];

  @HostListener('document:click', ['$event.target'])
  public onClick(clickedElement: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(clickedElement);
    if (clickedInside === true && this.focused === false) {
      this.editor.focus();
      this.focused = true;
    } else if (clickedInside === false && this.focused === true) {
      this.editor.blur();
      this.focused = false;
      this.inputBlur.emit();
    }
  }

  constructor(private elementRef: ElementRef, private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.modules.toolbar = this.toolbar;

    this.modules.mention = {
      allowedChars: /^[\w\u00C0-\u017F]*$/,
      source: (searchTerm, renderList, mentionChar) => {
        if (check.not.assigned(this.mentionOptions) || check.not.array(this.mentionOptions)) {
          return;
        }

        if (searchTerm.length === 0) {
          renderList(this.mentionOptions, searchTerm);
        } else {
          const matches = this.mentionOptions.filter((iMentionOption: IMentionOption) => {
            const regExp = new RegExp(`^.*${searchTerm}.*$`, 'i');
            return regExp.test(iMentionOption.value);
          });

          renderList(matches, searchTerm);
        }
      },
      renderItem: (item) => {

        let initials = '';
        const words = item.value.trim().split(' ');
        if (check.not.array(words) || check.emptyArray(words) || words.length < 2) {
          initials = `${item.value.charAt(0)}${item.value.charAt(0)}`.toUpperCase();
        } else {
          initials = `${words[0].trim().charAt(0)}${words[1].trim().charAt(0)}`.toUpperCase();
        }

        let imgElement;
        if (item.photoUrl) {
          const imageElement = document.createElement('img');
          imageElement.classList.add('ql-mention-list-item-photo');
          imageElement.src = item.photoUrl;
          imgElement = imageElement
        } else {
          const initialsDiv = document.createElement('div');
          initialsDiv.classList.add('ql-mention-list-item-initials');
          const spanElement = document.createElement('span');
          spanElement.innerText = initials;
          initialsDiv.appendChild(spanElement)
          imgElement = initialsDiv;
        }

        const textDiv = document.createElement('div');
        textDiv.innerText = item.value;
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.appendChild(imgElement);
        container.appendChild(textDiv);
        return container;
      }
    };

    if (check.assigned(this.value)) {
      this.makeValidation(this.value);
    }
  }

  onEditorCreated(editor: any): void {
    this.cdr.markForCheck();

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('misc')
      .then((translation) => {
        this.miscTranslation = translation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });

    this.editor = editor;
    if (check.assigned(this.value) && check.assigned(this.value.delta)) {
      this.editor.setContents(this.value.delta);
    } else if (check.assigned(this.value) && check.assigned(this.value.html)) {
      this.editor.clipboard.dangerouslyPasteHTML(this.value.html);
      this.value = { html: this.value.html, delta: this.editor.getContents() };
    } else {
      this.editor.setContents(null);
    }
    this.cdr.detectChanges();
  }

  onContentChanged(content: any): void {
    if (check.not.assigned(content.html) || check.not.assigned(content.text) || content.text === '\n') {
      this.onValueChange(null);
      return;
    }

    const rawData: any = {
      delta: content.editor.editor.delta,
      html: content.html
    };

    if (this.includeTextValue === true) {
      rawData.text = content.text;
    }

    this.onValueChange(rawData);
  }

  private onValueChange(newValue: any): void {
    this.value = newValue;

    this.makeValidation(newValue);

    if (this.isValueValid) {
      this.valueChange.emit(newValue);
    }
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (this.customValidation) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  refreshContent(): void {
    this.onEditorCreated(this.editor);
  }
}

export interface IMentionOption {
  id: string;
  value: string;
  photoUrl?: string;
}
