<div class="tc-main-container" [class.tc-manage-columns]="inColumnsSetup">
  <!-- MANAGE COLUMNS-->
  <div *ngIf="inColumnsSetup" [@slideInSetup] class="tc-table-container tc-manage-columns">
    <orgos-column-container centerColumns="true" class="kenjo-pv-10px kenjo-ph-20px kenjo-border-bottom">
      <orgos-column class="kenjo-font-size-16px">{{ miscTranslation.manageColumns }}</orgos-column>
      <kenjo-icon
        orgosColumn="0"
        [size]="22"
        class="kenjo-ml-30px kenjo-cursor-pointer kenjo-font-color-light-text-757575"
        (click)="closeManageColumns()"
      >
        clear
      </kenjo-icon>
    </orgos-column-container>
    <div class="kenjo-p-20px tc-manage-columns-content">
      <orgos-list-sortable [greySkin]="true" [items]="columnsControl" (changedOrder)="saveColumns()">
        <ng-container *orgosListItem="let item; let itemIndex = index">
          <orgos-column-container centerColumns="true" class="kenjo-pv-10px">
            <orgos-column size="1">
              {{ headerLabels[item.columnKey] }}
            </orgos-column>
            <orgos-column size="0">
              <mat-slide-toggle
                labelPosition="before"
                [(checked)]="item.visible"
                (change)="changeColumn($event.checked, itemIndex)"
                [disabled]="actionColumns.includes(item.columnKey) || (displayedColumns.length === 1 && item.visible === true)"
              >
              </mat-slide-toggle>
            </orgos-column>
          </orgos-column-container>
        </ng-container>
      </orgos-list-sortable>
    </div>
  </div>
  <!-- END MANAGE COLUMNS -->
  <!-- TABLE DATA-->
  <div *ngIf="!inColumnsSetup" class="tc-table-container">
    <div
      [class]="fullHeight ? 'tc-full-table-wrapper' : 'tc-table-wrapper'"
      [style.max-height]="fullHeight ? maxHeight : maxDynamicHeight"
      [style.overflow]="fullHeight && maxHeight === '100%' ? 'hidden' : 'auto'"
    >
      <div *ngIf="displayedColumns && displayedColumns.length > 0">
        <mat-icon
          *ngIf="(omManageIcon || viewManageColumns) && manageColumns"
          class="tc-manage-icon"
          (mouseenter)="omManageIcon = true"
          (mouseleave)="omManageIcon = false"
          [matTooltip]="miscTranslation.manageColumns"
          (click)="openManageColumns()"
          >view_column</mat-icon
        >
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          [style.min-width]="tableWidth"
          [style.max-height]="'100%'"
          (matSortChange)="customSortColumn($event)"
          [matSortActive]="customSortBy"
          [matSortDirection]="customSortOrder"
          matSortDisableClear="true"
        >
          <ng-container
            *ngFor="let tableColumn of tableColumns; let i = index"
            [matColumnDef]="tableColumn.columnKey"
            [sticky]="stickyColumn && i === 0"
            [stickyEnd]="stickyLastColumn && i === tableColumns.length - 1"
          >
            <ng-container *ngIf="selectable === true && tableColumn.columnKey === 'select'">
              <th
                mat-header-cell
                *matHeaderCellDef
                width="16px"
                class="selection-cell no-width"
                [class.no-wrap-text]="tableColumn.noWrapText"
              >
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" class="selection-cell-checkbox no-width">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selectCheckbox(row) : null"
                  [checked]="selection.isSelected(row)"
                  [disabled]="row.excluded"
                  [matTooltip]="tooltipExcludeRows"
                  [matTooltipDisabled]="!row.excluded || !tooltipExcludeRows || tooltipExcludeRows === ''"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="selectable === false || tableColumn.columnKey !== 'select'">
              <ng-container *ngIf="tableColumn.sortable === true">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [class.tc-action-column]="tableColumn.actionColumn"
                  [class.selection-cell]="tableColumn.selectable"
                  [class.no-width]="tableColumn.cellWidth"
                  [class.tc-center-content]="centerContent"
                  [class.kenjo-pl-40px]="numberOfStickyColumns > 0 && i === numberOfStickyColumns"
                  width="{{ tableColumn.cellWidth }}%"
                >
                  <orgos-column-container centerColumns="true">
                    <mat-checkbox
                      orgosColumn="0"
                      *ngIf="tableColumn.selectable"
                      class="kenjo-pl-20px"
                      (change)="tableColumn.changeColumnSelection($event)"
                      (click)="$event.stopPropagation()"
                      [checked]="tableColumn.allSelected"
                      [indeterminate]="tableColumn.indeterminate"
                    >
                    </mat-checkbox>
                    <orgos-column class="header-label-max-width">
                      <orgos-text
                        class="header-text-max-width"
                        type="SecondaryText"
                        [tooltipPosition]="tooltipPosition"
                        [noWrapText]="
                          tableColumn.showTooltip === true ? (tableColumn.tooltip ? tableColumn.tooltip : tableColumn.header) : ''
                        "
                      >
                        {{ tableColumn.header | uppercase }}</orgos-text
                      >
                    </orgos-column>
                    <mat-icon
                      orgosColumn="0"
                      class="kenjo-pl-10px"
                      *ngIf="tableColumn.info && tableColumn.info !== ''"
                      class="tc-info-icon"
                      [matTooltip]="tableColumn.info"
                      >help_outline</mat-icon
                    >
                  </orgos-column-container>
                </th>
              </ng-container>
              <ng-container *ngIf="tableColumn.sortable === false">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [class.tc-action-column]="tableColumn.actionColumn"
                  [class.selection-cell]="tableColumn.selectable"
                  [class.no-width]="tableColumn.cellWidth"
                  [class.tc-center-content]="centerContent"
                  width="{{ tableColumn.cellWidth }}%"
                  [class.kenjo-pl-40px]="numberOfStickyColumns > 0 && i === numberOfStickyColumns"
                >
                  <orgos-column-container centerColumns="true">
                    <mat-checkbox
                      orgosColumn="0"
                      *ngIf="tableColumn.selectable"
                      class="kenjo-pl-20px"
                      (change)="tableColumn.changeColumnSelection($event)"
                      [checked]="tableColumn.allSelected"
                      [indeterminate]="tableColumn.indeterminate"
                    >
                    </mat-checkbox>
                    <orgos-text
                      orgosColumn="0"
                      type="SecondaryText"
                      class="header-label-max-width"
                      [tooltipPosition]="tooltipPosition"
                      [noWrapText]="
                        tableColumn.showTooltip === true ? (tableColumn.tooltip ? tableColumn.tooltip : tableColumn.header) : ''
                      "
                    >
                      {{ tableColumn.header | uppercase }}
                    </orgos-text>
                    <mat-icon
                      orgosColumn="0"
                      class="kenjo-pl-10px"
                      *ngIf="tableColumn.info && tableColumn.info !== ''"
                      class="tc-info-icon"
                      [matTooltip]="tableColumn.info"
                      >help_outline</mat-icon
                    >
                  </orgos-column-container>
                </th>
              </ng-container>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [class.tc-action-column]="tableColumn.actionColumn"
                [class.tc-center-content]="centerContent"
                [class.no-width]="tableColumn.cellWidth"
                [class.sticky-right-cell]="
                  (stickyLastColumn && i === tableColumns.length - 1) || (numberOfStickyColumns > 0 && i === numberOfStickyColumns)
                "
                [class.kenjo-pl-40px]="numberOfStickyColumns > 0 && i === numberOfStickyColumns"
              >
                <ng-container *ngIf="tableColumn && tableColumn.tableCell && tableColumn.tableCell.templateRef">
                  <ng-template
                    [ngTemplateOutlet]="tableColumn.tableCell.templateRef"
                    [ngTemplateOutletContext]="{ $implicit: row, rowNumber: rowIndex }"
                  ></ng-template>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: stickyRow"
            (mouseenter)="viewManageColumns = true"
            (mouseleave)="hideManageIcon()"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns; let rowIndex = index"
            class="kenjo-pv-10px"
            (mouseenter)="enterInRow(rowIndex)"
            (mouseleave)="leaveInRow(rowIndex)"
            [class.highlight-row]="!disableRowHighlight"
          ></tr>
        </table>
      </div>
      <div *ngIf="dataSource?.data && dataSource?.data?.length === 0 && emptyStateMessage">
        <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
          <img src="/assets/images/emptyState.svg" />
          <div class="kenjo-mt-20px">{{ emptyStateMessage }}</div>
        </div>
      </div>
    </div>
    <div
      *ngIf="hidePagination === false"
      [style.display]="
        !inColumnsSetup && dataSource.data && (dataSource.data.length > 25 || dataSource.data.length > customPageSize) ? 'block' : 'none'
      "
      class="kenjo-border-top kenjo-text-align-center t-paginator-container"
    >
      <mat-paginator [pageSize]="customPageSize ? customPageSize : 25" showFirstLastButtons class="kenjo-inline-block"> </mat-paginator>
    </div>
  </div>
  <!-- END TABLE DATA-->
</div>
