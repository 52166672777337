import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as check from 'check-types';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const withoutBody = check.not.assigned(req.body);
    const hasContentTypeHeader = req.headers.has('Content-Type');

    if (withoutBody || hasContentTypeHeader) {
      return next.handle(req);
    }

    const reqWithContentType = req.clone({
      headers: req.headers.set('Content-Type', 'application/json')
    });

    return next.handle(reqWithContentType);
  }
}
