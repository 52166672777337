<orgos-widget titleKey="myTeam" [loadingWidget]="!widgetLoaded" [existingData]="widgetLoaded && existingData">
  <orgos-widget-header-action>
    <orgos-action *ngIf="configurationKeys && configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" (click)="openDetailsDialog()">{{ pageTranslation.personalizeButton }}</orgos-action>
    <orgos-action *ngIf="configurationKeys && !configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" (click)="openDetailsDialog()">{{ pageTranslation.moreInfoButton }}</orgos-action>
  </orgos-widget-header-action>

  <orgos-widget-content *ngIf="myTeamMembers && myTeamMembers.length > 0 && allSectionsEmpty === false">
    <div *ngFor="let section of myTeamMembers; first as isFirst">
      <ng-container *ngIf="section.members.length > 0">
        <div [class.kenjo-mt-5px]="!isFirst" class="kenjo-font-size-12px kenjo-mb-10px">
          <span class="kenjo-font-color-light-text-757575">{{ pageTranslation.sectionLabels[section.translationKey] | uppercase }}</span
          ><span *ngIf="section.name">{{ section.name | uppercase }}</span>
        </div>
        <orgos-column-container wrap="true" class="kenjo-mb-10px">
          <orgos-column *ngFor="let member of section.members" size="0">
            <div class="wmt-avatar-container kenjo-mb-10px kenjo-mr-5px" [matTooltip]="member.tooltipMessage" matTooltipClass="wmt-custom-tooltip">
              <kenjo-icon *ngIf="member.isAway && member.timeOffType === POLICY_TYPE_HOUR" [size]="15" class="kenjo-p-5px wmt-away-icon">time_off_hourly</kenjo-icon>
              <kenjo-icon *ngIf="member.isAway && member.timeOffType === POLICY_TYPE_DAY" [size]="15" class="kenjo-p-5px wmt-away-icon">time_off</kenjo-icon>
              <orgos-avatar
                (click)="navigateToEmployeeProfile(member)"
                class="wmt-avatar"
                [photoUrl]="member._photo?._url"
                [avatarName]="member.displayName"
                [class.wmt-avatar-manager]="member.hierarchyTranslationKey && member.hierarchyTranslationKey === MY_TEAM_TRANSLATION_KEY_MANAGER"
                size="medium"
              ></orgos-avatar>
            </div>
          </orgos-column>
        </orgos-column-container>
      </ng-container>
    </div>
  </orgos-widget-content>

  <orgos-widget-content *ngIf="myTeamMembers && allSectionsEmpty === true">
    <div class="wmt-no-data kenjo-p-20px kenjo-font-color-light-text-757575">
      <div><kenjo-icon [size]="20">widgets</kenjo-icon></div>
      <div *ngIf="configurationKeys && configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" class="kenjo-font-size-12px kenjo-ml-10px kenjo-text-align-center">{{ pageTranslation.noEmployeeMatchingWithFavorites }}</div>
      <div *ngIf="configurationKeys && !configurationKeys.includes(MY_TEAM_TRANSLATION_KEY_FAVORITES)" class="kenjo-font-size-12px kenjo-ml-10px kenjo-text-align-center">{{ pageTranslation.noEmployeeMatchingWithoutFavorites }}</div>
    </div>
  </orgos-widget-content>
</orgos-widget>
