<orgos-column-container centerColumns="true">
  <orgos-input-simple-select orgosColumn="1" [autoOrder]="autoOrder" (valueChange)="changeInOptionValue($event)" [options]="allOptions" [(value)]="condition.collectionName" [label]="pageTranslation.filterPlaceholder" [readOnly]="readOnly"></orgos-input-simple-select>
  <orgos-input-simple-select orgosColumn="1" [autoOrder]="autoOrder" (valueChange)="changeInOperatorValue($event)" [options]="allOperators" [(value)]="condition.operator" [label]="pageTranslation.operatorPlaceholder" [readOnly]="!condition.collectionName || readOnly" class="kenjo-ml-20px">
  </orgos-input-simple-select>
  <orgos-input-simple-select
    orgosColumn="1"
    [autoOrder]="autoOrder"
    (valueChange)="changeInValue($event)"
    [options]="allPossibleValues[condition.collectionName]"
    [(value)]="condition.value"
    [label]="pageTranslation.valuePlaceholder"
    [readOnly]="!condition.collectionName || readOnly"
    class="kenjo-block kenjo-ml-20px"
    [class.kenjo-border-right]="hasDeleteButtonBorder"
    [ngClass]="{ 'crcc-delete-button-border': hasDeleteButtonBorder }"
  ></orgos-input-simple-select>

  <kenjo-icon *ngIf="!readOnly" [size]="18" orgosColumn="0" (click)="deleteThisCondition()" [matTooltip]="pageTranslation.deleteConditionTooltip" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757 kenjo-ml-20px">delete</kenjo-icon>
</orgos-column-container>
