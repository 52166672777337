import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from '@app/standard/services/company/company.service';
import { ILocationOfficeModel, OfficeService } from '@app/standard/services/company/office.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-settings-shift-plan-locations',
  templateUrl: 'settings-shift-plan-locations.html',
  styleUrls: ['settings-shift-plan-locations.scss'],
})
export class SettingsShiftPlanLocations implements OnInit {
  @Input() parentTranslation: any = {};
  i18n: any = {};
  locations: Array<ILocationOfficeModel>;
  filteredLocations: Array<ILocationOfficeModel>;
  displayedColumns: Array<string> = ['name', 'companyId', 'street', 'city', 'postalCode', 'country'];
  loading: boolean = true;
  companyMapName: { [id: string]: string };
  MY_COMPANY_SETTINGS_PATH: string = '/cloud/settings/structure/company';
  MY_OFFICE_SETTINGS_PATH: string = '/cloud/settings/structure/office';

  constructor(private injector: Injector, private router: Router, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.fetchData();
    this.getTranslations();
  }

  private async fetchData() {
    await this.refreshListOfLocations();
  }

  private async getTranslations(): Promise<void> {
    try {
      const [pageTranslation, standardPicklist] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-locations'),
        this.injector.get(InternationalizationService).getAllTranslation('standard-picklists'),
      ]);
      this.i18n.page = pageTranslation;
      this.i18n.country = standardPicklist.country;
    } catch (error) {
      this.i18n.page = {};
      this.i18n.country = {};
    }
  }

  private async refreshListOfLocations() {
    try {
      this.loading = true;
      this.locations = await this.injector.get(OfficeService).getSortedOffices();
      this.filteredLocations = this.locations;
      await this.getCompanyNamesById();
      this.loading = false;
    } catch {
      this.locations = [];
    }
  }

  public navigateToOfficeLocation(): void {
    const path = this.locations.length > 0 ? this.MY_OFFICE_SETTINGS_PATH : this.MY_COMPANY_SETTINGS_PATH;
    const url = this.router.serializeUrl(this.router.createUrlTree([path]));
    window.open(url, '_blank');
  }

  public setFilteredLocationsToShow(recordsToShow: Array<ILocationOfficeModel>): void {
    this.filteredLocations = recordsToShow;
    this.cdr.detectChanges();
  }

  async getCompanyNamesById() {
    const companies = await this.injector.get(CompanyService).getCompanies();
    this.companyMapName = companies.reduce((acc, curr) => ({ ...acc, [curr._id]: curr.name }), {});
  }
}
