import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IUserAccountModel } from '@app/models/user-account.model';
import { IChargebeeStatus, PrivateChargebeeService } from '@app/private/services/private-chargebee.service';
import { IOrganization, PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { environment } from '@env';
import * as amplitude from 'amplitude-js';
import * as check from 'check-types';

export const AMPLITUDE_DATEV_EVENT_KEYS = {
  employeeFields: 'employee',
  bankFields: 'bank',
  jobFields: 'employment',
  taxFields: 'tax',
  activityFields: 'occupation',
  socialSecurityFields: 'social security',
  regularWorkingHoursFields: 'working hours',
  interruptionTimesFields: 'interruption time',
  remunerationFields: 'fixed remuneration',
  clientDepartmentsFields: 'department service',
  hourlyAttendanceFields: 'hourly attendance',
  workScheduleFields: 'work schedule'
};

@Injectable({
  providedIn: 'root'
})
export class PrivateAmplitudeService {
  private API_KEY = environment.AMPLITUDE_API_KEY;

  private AMPLITUDE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/amplitude`;

  private IGNORE_DOMAINS = environment.AMPLITUDE_IGNORE_DOMAINS;

  private ignoreAccount: boolean;

  private client: amplitude.AmplitudeClient;

  private customerStatus: 'Customer' | 'Free trial';

  private loggedUser: IUserAccountModel;

  private organization: IOrganization;

  private currentAuthorizationHeader: string;

  private chargebeeStatus: IChargebeeStatus;

  private activeUsers: number;

  constructor(private injector: Injector) {
    this.initAmplitude();
  }

  private async initAmplitude() {
    try {
      if (check.assigned(this.API_KEY) && check.not.emptyString(this.API_KEY)) {
        await this.createAmplitudeClient();
      }
    } catch {
      // do nothing
    }
  }

  private async createAmplitudeClient() {
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    if (check.not.assigned(this.loggedUser?._id)) {
      return;
    }

    [this.organization, this.chargebeeStatus, this.activeUsers] = await Promise.all([this.injector.get(PrivateOrganizationService).getMyOrganization(), this.injector.get(PrivateChargebeeService).getChargebeeStatus(), this.getActiveEmployees()]);
    if (check.not.assigned(this.organization) || check.not.assigned(this.chargebeeStatus) || check.not.assigned(this.activeUsers)) {
      return;
    }

    const isFreeTrialAccount = this.organization?.isFreeTrialAccount === true;
    this.customerStatus = isFreeTrialAccount ? 'Free trial' : 'Customer';

    this.ignoreAccount = this.shouldIgnoreAccount();
    if (this.ignoreAccount !== true) {
      this.client = amplitude.getInstance();
      this.currentAuthorizationHeader = this.injector.get(AuthenticationService).getAuthorizationHeader();
      this.client.init(this.API_KEY, this.loggedUser._id, {
        apiEndpoint: this.getAmplitudeProxyUrl(),
        forceHttps: this.getAmplitudeProxyUrlProtocol() === 'https:',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: this.currentAuthorizationHeader
        }
      } as any);
    }
  }

  async logEvent(eventName: string, eventProperties?: any) {
    try {
      if (this.ignoreAccount === true || check.not.assigned(this.API_KEY) || check.emptyString(this.API_KEY) || this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        return;
      }

      if (check.not.assigned(this.client) || this.currentAuthorizationHeader !== this.injector.get(AuthenticationService).getAuthorizationHeader()) {
        await this.initAmplitude();
      }

      if (check.not.assigned(this.client)) {
        return;
      }

      const userProperties = {
        email: this.loggedUser.email,
        role: this.loggedUser.profile.name,
        customerStatus: this.customerStatus,
        subscriptionStatus: this.chargebeeStatus.customerStatus,
        plans: this.chargebeeStatus.plans,
        employees: this.activeUsers
      };

      this.client.setGroup('companyName', this.organization.brandName);
      this.client.setGroup('orgId', this.organization._id);
      this.client.setUserProperties(userProperties);
      this.client.logEvent(eventName, eventProperties);
    } catch {
      // do nothing
    }
  }

  /**
   * logs an event directly to out backend /log endpoint, without adding user properties.
   * this should be used to log events where those properties are unknown or irrelevant (ie, when a free trial is created)
   */
  async logSimpleEvent(eventName: string, eventProperties?: any) {
    try {
      if (this.ignoreAccount === true || check.not.assigned(this.API_KEY) || check.emptyString(this.API_KEY)) {
        return;
      }

      await this.injector.get(HttpClient).post(`${this.AMPLITUDE_URL}/log`, { event_type: eventName, event_properties: eventProperties }).toPromise();
    } catch {
      // do nothing
    }
  }

  private shouldIgnoreAccount(): boolean {
    if (check.not.assigned(this.organization) || check.not.assigned(this.organization.email)) {
      return true;
    }

    if (check.not.assigned(this.IGNORE_DOMAINS) || check.emptyString(this.IGNORE_DOMAINS)) {
      return false;
    }

    const domains = this.IGNORE_DOMAINS.split(',');
    const accountFound = domains.find((iDomain) => this.organization.email.endsWith(iDomain));
    return check.assigned(accountFound);
  }

  private getAmplitudeProxyUrl() {
    const url = `${environment.API_ENVIRONMENT_URL}/amplitude/log-event`;
    return url.replace(/^(https?:|)\/\//, '');
  }

  private getAmplitudeProxyUrlProtocol() {
    const url = new URL(environment.API_ENVIRONMENT_URL);
    return url.protocol;
  }

  async getActiveEmployees(): Promise<number> {
    const { activeUsers } = await this.injector.get(UserAccountService).countActiveUsers(true);
    return activeUsers;
  }
}
