import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { IndicatorComponent } from '@app/standard/standalone-components/indicator/indicator.component';

@Component({
  selector: 'kenjo-toggle-error-button',
  standalone: true,
  imports: [CommonModule, IndicatorComponent, IconModule],
  templateUrl: './toggle-error-button.component.html',
  styleUrls: ['./toggle-error-button.component.scss'],
})
export class ToggleErrorButtonComponent {
  @Input() useIndicator = false;
  @Input() indicatorLabel: number;
  @Input() icon: string;
  @Input() iconSize: number = 18;
  @Input() buttonLabel: string;
  @Input() indicatorSize: 'xs' | 's' | 'm' | 'l';
  @Input() semantic: 'info' | 'success' | 'danger' | 'warning' | 'caution' | 'neutral' | 'user';
  @Input() invert: boolean = false;
  @Input() disable: boolean = false;
  @Input() active: boolean = false;

  toggle(): void {
    this.active = !this.active;
  }
}
