<div *ngIf="participation.percentage && participation.total" class="pp-center-text">
  <div class="kenjo-font-size-16px kenjo-pb-5px">{{ pageTranslation.participationTitle }}</div>
  <div class="kenjo-font-size-30px kenjo-font-color-success-00b72e kenjo-pv-10px">{{ participation.percentage | number: '1.0-2' }}%</div>
  <div class="kenjo-font-color-light-text-757575 kenjo-pt-5px">{{ participation.responded }} / {{ participation.total }}</div>
</div>

<div *ngIf="!participation.percentage || !participation.total" class="pp-center-text">
  <div class="kenjo-font-size-16px kenjo-pb-5px">{{ pageTranslation.participationTitle }}</div>
  <div class="kenjo-font-color-light-text-757575 kenjo-pt-10px">{{ pageTranslation.noSurveysTitle }}</div>
</div>
