<div class="kenjo-color-grey-background-lighter-f8f8f8" *ngIf="candidate">
  <orgos-column-container class="kenjo-pt-20px kenjo-ph-20px" [class.kenjo-pb-10px]="listTabs.length > 0" [class.kenjo-pb-20px]="!listTabs || listTabs.length === 0" centerColumns="true">
    <orgos-column size="0" class="kenjo-mr-20px rch-avatar-container">
      <orgos-avatar [avatarName]="candidate.data.firstName.concat(' ' + candidate.data.lastName)" [photoUrl]="candidate.data.photo?._url" size="medium"></orgos-avatar>
      <kenjo-icon
        orgosColumn="0"
        [size]="20"
        *ngIf="showFullHeader === true && availableActions.canStarCandidate"
        class="rch-favorite-icon kenjo-font-color-primary-5993e3 kenjo-cursor-pointer"
        (click)="updateFavoriteTalentPool()"
        [matTooltip]="addedToFavorite ? pageTranslation.RemoveFromFavoriteTooltip : pageTranslation.AddToFavoriteTooltip"
      >
        {{ addedToFavorite ? 'star_full' : 'star_empty' }}</kenjo-icon
      >
    </orgos-column>

    <orgos-column size="0" centerColumns="true">
      <div class="kenjo-mb-10px" *ngIf="candidate.data._createdByAgencyId && !currentUser.agencyId">
        <div class="rch-sourced-text kenjo-pv-5px kenjo-ph-15px kenjo-font-color-white-ffffff kenjo-border-radius kenjo-inline-block kenjo-font-size-12px">
          {{ pageTranslation.agencySourced }}
        </div>
      </div>
      <kenjo-truncate-text class="rch-candidate-name kenjo-font-size-18px"> {{ candidate.data.firstName }}&nbsp;{{ candidate.data.lastName }}</kenjo-truncate-text>
      <ng-container *ngIf="position">
        <div class="kenjo-mt-10px" *ngIf="positionCandidate && positionCandidate.data">
          <span *ngIf="positionCandidate.data.isDisqualified === false">{{ pageTranslation.stageLabel }}:&nbsp;</span>
          <div *ngIf="positionCandidate.data.isDisqualified === false && stageAvailable" [style.background]="mapStageIdToStage[positionCandidate.data.stageId] ? USER_COLORS[mapStageIdToStage[positionCandidate.data.stageId].color] : ''" class="rch-circle-color"></div>
          <kenjo-truncate-text class="rch-stage-title" *ngIf="positionCandidate.data.isDisqualified === false" [class.kenjo-font-color-warning-ffc107]="!stageAvailable">
            &nbsp;{{ mapStageIdToStage[positionCandidate.data.stageId] && stageAvailable ? mapStageIdToStage[positionCandidate.data.stageId].name : pageTranslation.unknownStage }}
          </kenjo-truncate-text>

          <span *ngIf="positionCandidate.data.isDisqualified" class="kenjo-font-color-danger-ff5757">{{ pageTranslation.rejectedLabel }}</span>
          <kenjo-truncate-text class="kenjo-font-color-danger-ff5757 rch-reject-title" *ngIf="positionCandidate.data.isDisqualified && positionCandidate.data.rejectionReason && showFullHeader === true">:&nbsp;{{ positionCandidate.data.rejectionReason }} </kenjo-truncate-text>
        </div>
        <div *ngIf="position.data && position.data.jobTitle" class="kenjo-mt-10px">
          <a *ngIf="isAgency === false && (availableActions.canAccessPipeline === true || availableActions.canAccessJobOpeningConfig === true)" (click)="navigateToPosition(position.data._id)">
            <kenjo-truncate-text class="rch-job-title">{{ position.data.jobTitle | uppercase }}</kenjo-truncate-text>
          </a>
          <kenjo-truncate-text class="rch-job-title" *ngIf="isAgency === true || (availableActions.canAccessPipeline === false && availableActions.canAccessJobOpeningConfig === false)">{{ position.data.jobTitle | uppercase }}</kenjo-truncate-text>
        </div>
      </ng-container>
    </orgos-column>

    <orgos-column></orgos-column>

    <orgos-column *ngIf="scoreResults && scoreResults.overall && scoreResults.overall.potential !== null && scoreResults.overall.potential >= 0" size="1" class="kenjo-text-align-center kenjo-mh-10px">
      <div class="kenjo-font-size-25px">{{ scoreResults.overall.potential }}%</div>
      <div class="kenjo-mt-5px kenjo-font-color-light-text-757575">{{ scorecardTranslation.potentialLabel }}</div>
      <orgos-recruiting-score-results-line class="kenjo-mt-10px" [rating]="scoreResults.overall.ratings"> </orgos-recruiting-score-results-line>
    </orgos-column>

    <orgos-column></orgos-column>

    <orgos-column size="0" class="rch-start-column">
      <orgos-column-container class="rch-justify-end">
        <orgos-column size="0" *ngIf="showHiredStatus" size="0">
          <orgos-chip color="Success" class="kenjo-font-size-16px kenjo-ph-15px">{{ pageTranslation.hired }}</orgos-chip>
        </orgos-column>
        <orgos-column size="0" *ngIf="position && recruitingPipelineStages && positionCandidate && positionCandidate.data && showFullHeader === true">
          <div
            class="kenjo-text-align-right kenjo-mb-10px kenjo-mt-5px"
            *ngIf="
              !positionCandidate.data.hiredDate &&
              (actionsMenuVisibility.showConvertToEmployee === true ||
                actionsMenuVisibility.showDisqualify === true ||
                actionsMenuVisibility.showRejectAndSendEmail === true ||
                actionsMenuVisibility.showDeleteFromPosition === true ||
                actionsMenuVisibility.showDeletePermanently === true ||
                actionsMenuVisibility.showAnonymize === true)
            "
          >
            <kenjo-icon [size]="20" class="kenjo-cursor-pointer kenjo-ml-10px" [matMenuTriggerFor]="actionsMenu"> more_vert </kenjo-icon>
            <mat-menu #actionsMenu="matMenu">
              <button mat-menu-item (click)="convertToEmployee()" *ngIf="actionsMenuVisibility.showConvertToEmployee === true">
                {{ pageTranslation.convertToEmployee }}
              </button>
              <button mat-menu-item (click)="disqualify()" *ngIf="actionsMenuVisibility.showDisqualify === true">
                {{ pageTranslation.disqualifyTooltip }}
              </button>
              <button mat-menu-item (click)="rejectAndSendEmail()" *ngIf="actionsMenuVisibility.showRejectAndSendEmail === true">
                {{ pageTranslation.rejectAndSendEmail }}
              </button>
              <button mat-menu-item (click)="deleteFromPosition()" *ngIf="actionsMenuVisibility.showDeleteFromPosition === true">
                {{ pageTranslation.deleteFromPosition }}
              </button>
              <button mat-menu-item (click)="deletePermanently()" *ngIf="actionsMenuVisibility.showDeletePermanently === true">
                {{ pageTranslation.deletePermanently }}
              </button>
              <button mat-menu-item (click)="anonymize()" *ngIf="actionsMenuVisibility.showAnonymize === true">
                {{ pageTranslation.anonymizeButton }}
              </button>
            </mat-menu>
          </div>
        </orgos-column>
      </orgos-column-container>

      <orgos-column-container>
        <orgos-column *ngIf="position && recruitingPipelineStages && positionCandidate && positionCandidate.data && showFullHeader === true">
          <ng-container *ngIf="positionCandidate.data.isDisqualified === false && !candidate.data.convertedToUserId && availableActions.canReviewCandidate">
            <orgos-button-raised [matTooltip]="reviewDisabled ? pageTranslation.reviewDisabled : pageTranslation.reviewTooltip" (click)="reviewCandidate()" [disabled]="reviewDisabled"> {{ pageTranslation.reviewButton }}</orgos-button-raised>
          </ng-container>

          <ng-container *ngIf="positionCandidate.data.isDisqualified === false && availableActions.canMoveCandidate === true && !candidate.data.convertedToUserId">
            <orgos-button-raised mat-icon-button [matMenuTriggerFor]="statusMenu" [matTooltip]="pageTranslation.moveTooltip" class="kenjo-ml-10px">{{ pageTranslation.moveButton }}</orgos-button-raised>
            <mat-menu #statusMenu="matMenu">
              <ng-template ngFor let-iPipelineStage [ngForOf]="recruitingPipelineStages">
                <button mat-menu-item (click)="updateStage(iPipelineStage._id)">
                  <div [style.background]="iPipelineStage ? USER_COLORS[iPipelineStage.color] : ''" class="rch-circle-color kenjo-mr-10px"></div>
                  {{ iPipelineStage ? iPipelineStage.name : '' }}
                </button>
              </ng-template>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="positionCandidate.data.isDisqualified === false && !candidate.data.convertedToUserId && availableActions.canScoreCandidate === true">
            <orgos-button-raised [matTooltip]="pageTranslation.scoreTooltip" class="kenjo-ml-10px" (click)="scoreCandidate()">{{ pageTranslation.scoreButton }}</orgos-button-raised>
          </ng-container>

          <ng-container *ngIf="positionCandidate.data.isDisqualified === true && availableActions.canRequalifyCandidate">
            <orgos-button-raised (click)="requalify()" [matTooltip]="pageTranslation.requalifyTooltip">
              {{ pageTranslation.requalifyButtonLabel }}
            </orgos-button-raised>
          </ng-container>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
  </orgos-column-container>

  <ng-container *ngIf="listTabs.length > 0">
    <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTab = $event; clickTab($event)">
      <mat-tab *ngFor="let iTab of listTabs" [label]="iTab"></mat-tab>
    </mat-tab-group>
  </ng-container>
</div>
