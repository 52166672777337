import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger } from '@angular/material/legacy-menu';
import { ComponentItemType } from '@app/standard/components/search-item-dropdown-custom/search-item-dropdown-custom.component';
import { ISelectCategoryItem, ISelectItem } from '@app/standard/components/select-dropdown-custom/select-dropdown-custom.component';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-select-simple-dropdown-custom',
  templateUrl: './select-simple-dropdown-custom.component.html',
  styleUrls: ['./select-simple-dropdown-custom.component.scss'],
})
export class SelectSimpleDropdownCustomComponent implements OnInit, OnChanges {
  @Input() readonly options: Array<ISelectItem | ISelectCategoryItem> = [];
  @Input() required: boolean = false;
  @Input() width: string = '300px';
  @Input() componentKey: ComponentItemType;
  @Input() defaultOption: ISelectItem;
  @Input() translations: any;
  @Output() selectOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() cleanOption: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('optionInput', { static: false }) optionInput?: any;
  @ViewChild(MatLegacyMenuTrigger) trigger: MatLegacyMenuTrigger;
  item: ISelectItem | ISelectCategoryItem = { name: '', value: '' };
  emptyOptionsText: string = '';
  label: string = '';
  searchPlaceholder: string = '';
  colors: any = userColorConstants;

  ngOnInit(): void {
    this.initTranslations();
    if (this.defaultOption?.value) {
      this.item = this.defaultOption;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.defaultOption?.currentValue?.value) {
      this.item = changes?.defaultOption?.currentValue;
    }
  }

  onSelectOption($event: ISelectItem) {
    if (this.componentKey !== 'category-item') {
      this.optionInput.value = $event.name;
    }
    this.item = $event;
    this.emitSelection($event.value);
    this.trigger.closeMenu();
  }

  emitSelection(id: string): void {
    this.selectOption.emit(id);
  }

  initTranslations() {
    this.label = this.translations?.label || '';
    this.searchPlaceholder = this.translations?.search || '';
    this.emptyOptionsText = this.translations?.emptyOptionsText || '';
  }

  onCleanValue($event: Event) {
    this.item = { name: '', value: '' };
    this.cleanOption.emit();
    $event.stopPropagation();
  }
}
