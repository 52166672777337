import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CumulService } from '@app/cloud-features/dashboards/services/cumul.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { PlatformUsageService } from '@app/standard/services/platform-usage/platform-usage.service';
import { NgxCumulioDashboardComponent } from '@cumul.io/ngx-cumulio-dashboard';

@Component({
  selector: 'kenjo-widget-platform-usage',
  templateUrl: './kenjo-widget-platform-usage.component.html',
  styleUrls: ['./kenjo-widget-platform-usage.component.scss']
})
export class WidgetPlatformUsageComponent implements OnInit {
  isDatasetEmpty: boolean = false;
  loadingWidget: boolean = true;
  translations: any = {};

  // chart data, placeholder for now
  cumulAuth: any = {};
  loggedUser: any = {};
  widgetDashboard: any = {
    cumulId: '294b4b0a-c47c-4c2a-8e67-53f80268c3d0',
    screenMode: 'mobile'
  };

  @ViewChild('dashboardInstance') dashboardInstance: NgxCumulioDashboardComponent;

  hasAccessToBilling: boolean = false;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initWidget();
  }

  goToPlatformUsagePage(): void {
    this.injector.get(PrivateAmplitudeService).logEvent('Navigate to usage dashboard via widget', { category: 'PLG', subcategory1: 'Platform usage' });
    this.injector.get(Router).navigateByUrl(`cloud/settings/billing/platform-usage`);
  }

  refreshIconClicked(): void {
    this.dashboardInstance?.refreshData().subscribe();
  }

  learnMoreIconClicked(): void {
    const userLanguage: string = this.loggedUser.language || 'en';
    window.open(`https://help.kenjo.io/${userLanguage.toLowerCase()}/support/solutions/articles/60001048939`, '_blank');
  }

  async initWidget(): Promise<void> {
    this.loadingWidget = true;
    try {
      [this.translations, this.cumulAuth, this.isDatasetEmpty] = await Promise.all([this.injector.get(InternationalizationService).getAllTranslation('home-widget-platform-usage-component'), this.injector.get(CumulService).getSSOAuth(), this.injector.get(PlatformUsageService).getIsDatasetEmpty()]);
      this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      this.widgetDashboard.language = this.loggedUser.language || 'en';
      this.hasAccessToBilling = this.injector.get(CloudRoutesService).checkRoute('settings/billing');
    } catch {
      this.translations = {};
    } finally {
      this.loadingWidget = false;
    }
  }
}
