import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-input-shiftplan-date-picker',
  templateUrl: 'input-shiftplan-date-picker.component.html',
  styleUrls: ['input-shiftplan-date-picker.component.scss']
})
export class InputShiftplanDatePickerComponent implements OnInit {
  startDate: string;
  isValueValid: boolean = false;
  _hasSwitched: boolean = false;
  _hasErrors: boolean = false;
  dateRepeated: boolean = false;
  _selectedValues: Array<{ from: string; to: string }> | null;
  multipleWeekRange: Array<{ from: string; to: string }> | null = null;
  selectedDays: Array<{ from: string; to: string }> | null;
  resetDays = new Date(0);
  rangeWeekly = new UntypedFormGroup({
    fromWeek: new UntypedFormControl(),
    toWeek: new UntypedFormControl()
  });
  selectedDaysCalendar = new UntypedFormControl(new Date(0));
  MULTIPLE_WEEKS: string = 'multiple-weeks';
  ONE_DAY: string = 'one-day';
  MULTIPLE_DAYS: string = 'multiple-days';
  maxDaysToSelect: number = 30;

  selfClose: () => void;

  @Input() isWeeklyPeriod: boolean = true;
  @Input() isFromDate: boolean = false;
  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() errorTexts: boolean = false;
  @Input() weekRange: { from: string; to: string } | null = { from: null, to: null };
  @Input() dayRange: Array<{ from: string; to: string }> | null = [];
  @Input() copyFromMultiple: Array<{ from: string; to: string }> | null;
  @Input() calendarType: 'multiple-weeks' | 'one-day' | 'multiple-days';
  @Input() pageTranslation;
  @Input()
  set hasSwitched(value: boolean) {
    this._hasSwitched = value;
    if (value) {
      this._selectedValues = [];
      this.selectedDays = [];
    }
  }

  @Input()
  set selectedValues(value: Array<{ from: string; to: string }> | null) {
    this._selectedValues = [...value];
  }
  get selectedValues() {
    return this._selectedValues;
  }

  @Input()
  set hasErrors(value: boolean) {
    this._hasErrors = value;
  }
  get hasErrors() {
    return this._hasErrors;
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();

  datepicker: MatDatepicker<Date>;
  @ViewChild('datepicker', { static: false }) set datePickerContent(content: MatDatepicker<Date>) {
    if (content) {
      this.datepicker = content;
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.selfClose = this.datepicker.close;
    }
  }
  @ViewChild('datepickerDay', { static: false }) set datePickerDayContent(content: MatDatepicker<Date>) {
    if (content) {
      this.datepicker = content;
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.selfClose = this.datepicker.close;
    }
  }
  @ViewChild('datepickerFooter', { static: false }) datepickerFooter: ElementRef;

  inputValidation: InputValidation = new InputValidation();

  constructor(public injector: Injector) {}

  ngOnInit(): void {
    const currentISODate = this.weekRange?.from;
    this.startDate = check.assigned(currentISODate) && check.nonEmptyString(currentISODate) ? this.weekRange?.from : moment().toISOString();
  }

  public dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return ['selected'];
    }
    return [];
  };

  private _findDate(date: Date): number {
    return this.dayRange.findIndex((d) => moment(date).isSame(d.from));
  }

  onDateChange(event) {
    if (this.isWeeklyPeriod) {
      this.onDateChangeWeekly();
    } else {
      this.onDateChangeDaily(event);
    }
  }

  onDateChangeWeekly() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.classList.add('isdpc-mat-calendar');

    const selectedDate = this.rangeWeekly.get('fromWeek').value;
    if (check.not.assigned(selectedDate)) {
      return;
    }

    const startDate = moment.utc(selectedDate).startOf('week');
    const endDate = moment.utc(selectedDate).endOf('week');
    const currentValue = { from: startDate.toISOString(), to: endDate.toISOString() };

    if (!this.isValidRangeValues(startDate, endDate)) {
      return;
    }

    this.weekRange = { ...currentValue };
    this.makeValidation(currentValue);

    if (check.assigned(this.copyFromMultiple)) {
      this.calculateTargetWeeksMultiple(currentValue);
      return;
    } else {
      this.multipleWeekRange = null;
    }

    this.rangeWeekly.get('fromWeek').setValue(startDate);
    this.rangeWeekly.get('toWeek').setValue(endDate);
  }

  private isValidRangeValues(startDate, endDate): boolean {
    if (moment(startDate).isSame(this.weekRange.from, 'day') && moment(endDate).isSame(this.weekRange.to, 'day')) {
      this.clearValue();
      this.isValueValid = false;
      return false;
    }
    return true;
  }

  private calculateTargetWeeksMultiple(currentValue) {
    let lastDay;
    const startDate = this.copyFromMultiple[0].from;
    const startDateCopy = moment(currentValue.from);

    const weekDifference = moment.duration(Math.abs(moment(startDate).diff(startDateCopy))).asWeeks();
    this.rangeWeekly.get('fromWeek').setValue(startDate);

    if (moment(startDate).isSameOrBefore(startDateCopy, 'day')) {
      lastDay = moment
        .utc(this.copyFromMultiple[this.copyFromMultiple.length - 1].to)
        .add(weekDifference, 'week')
        .startOf('day')
        .toISOString();
      this.multipleWeekRange = this.copyFromMultiple.map((week) => ({
        from: moment(week.from).add(weekDifference, 'week').toISOString(),
        to: moment(moment.utc(week.to).startOf('day')).add(weekDifference, 'week').toISOString()
      }));
    }

    if (moment(startDate).isAfter(startDateCopy, 'day')) {
      lastDay = moment
        .utc(this.copyFromMultiple[this.copyFromMultiple.length - 1].to)
        .subtract(weekDifference, 'week')
        .toISOString();
      this.multipleWeekRange = this.copyFromMultiple.map((week) => ({
        from: moment(week.from).subtract(weekDifference, 'week').toISOString(),
        to: moment(moment.utc(week.to)).subtract(weekDifference, 'week').toISOString()
      }));
    }

    this.rangeWeekly.get('fromWeek').setValue(this.multipleWeekRange[0].from);
    this.rangeWeekly.get('toWeek').setValue(lastDay);
  }

  onDateChangeDaily(event) {
    const from = moment.utc(event.value);
    const to = from.clone().add(1, 'day');
    const currentValue = { from: from.toISOString(), to: to.toISOString() };
    if (this.calendarType === this.ONE_DAY) {
      this.dayRange = [currentValue];
    }
    if (this.calendarType === this.MULTIPLE_DAYS) {
      const index = this._findDate(event.value);
      if (index === -1) {
        this.dayRange = [...this.dayRange, currentValue];
      } else {
        this.dayRange.splice(index, 1);
      }
      this.resetDays = new Date(0);
      this.datepicker?.['_componentRef']?.instance?._calendar?.monthView?._createWeekCells();
    }
    this.makeValidation(currentValue);
  }

  private makeValidation(currentValue): void {
    const inputValidation = this.validateValue(currentValue).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  protected validateValue(currentValue: any): InputValidation {
    this.inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(currentValue?.from) || check.not.assigned(currentValue?.to) || check.emptyString(currentValue?.from) || check.emptyString(currentValue?.to))) {
      this.inputValidation.setError('required');
    }

    if (check.assigned(currentValue.from) && !moment.utc(currentValue.from).isValid()) {
      this.inputValidation.setError('date');
    }

    if (check.assigned(currentValue.to) && !moment.utc(currentValue.to).isValid()) {
      this.inputValidation.setError('date');
    }

    if (check.not.assigned(this.copyFromMultiple)) {
      if (this.isWeeklyPeriod) {
        this.selectedValues.forEach((week) => {
          if (moment(currentValue.from).isSame(week.from) && moment(currentValue.to).isSame(week.to)) {
            this.dateRepeated = true;
            this.inputValidation.setError('alreadySelected');
          }
        });
      } else if (this.isFromDate) {
        this.selectedDays.forEach((day) => {
          if (moment(currentValue.from).isSame(day.from)) {
            this.dateRepeated = true;
            this.inputValidation.setError('alreadySelected');
          }
        });
      }
    }

    return this.inputValidation;
  }

  openDatepicker(): void {
    if (!this.readOnly) {
      this.datepicker.open();
      this.datepicker?.['_componentRef']?.instance?.['_dateAdapter'].setLocale(moment.locale());
      // override default close method to avoid closing after selection
      this.datepicker.close = () => {};
      this.appendFooter();
      if (!this.isWeeklyPeriod) {
        this.dayRange = [...this.selectedDays];
        const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
        matCalendar.classList.add('isdpc-mat-calendar-day');
      }
    }
  }

  clearValue(): void {
    this.rangeWeekly.get('fromWeek').setValue(null);
    this.rangeWeekly.get('toWeek').setValue(null);
    this.weekRange = Object.assign({}, { from: null, to: null });
  }

  removeWeek(value: any): void {
    const index = this._selectedValues.indexOf(value);
    this._selectedValues.splice(index, 1);
    this.valueChange.emit({ value: this.selectedValues, calendarType: this.calendarType, removed: true });
  }

  removeDay(value: any): void {
    const index = this.selectedDays.indexOf(value);
    this.selectedDays.splice(index, 1);
    this.valueChange.emit({ value: this.selectedDays, calendarType: this.calendarType, removed: true });
  }

  onClose(): void {
    if (!this.isValueValid) {
      return;
    }
    const valueToEmit = this.isWeeklyPeriod ? this.weekRange : this.dayRange;
    this.emitValueChange(valueToEmit);
    this.closeDialog();
  }

  private closeDialog() {
    this.datepicker.close = this.selfClose;
    this.datepicker.close();
    this.clearValue();
  }

  emitValueChange(currentValue): void {
    if (this.calendarType === this.MULTIPLE_WEEKS) {
      const valueToEmit = this.multipleWeekRange ?? currentValue;
      this.valueChange.emit({ value: valueToEmit, calendarType: this.calendarType });
    }

    if (this.calendarType === this.MULTIPLE_DAYS || this.calendarType === this.ONE_DAY) {
      this.selectedDays = [...currentValue].sort((a, b) => (moment(a.from).isAfter(b.from) ? 1 : -1));
      this.valueChange.emit({ value: this.selectedDays, calendarType: this.calendarType });
    }
    this.isValueValid = false;
  }

  private appendFooter() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }

  closeFromListener() {
    this.isValueValid = false;
    this.closeDialog();
  }

  handleWeekRangeSelection(event) {
    if (check.not.assigned(this.weekRange.from)) {
      return;
    }
    const day = moment(this.weekRange.from).format('DD');
    if (Number(event?.target?.outerText) === Number(day) && !event?.target?.parentNode?.classList.contains('mat-calendar-body-range-start')) {
      event.preventDefault();
      this.clearValue();
      this.isValueValid = false;
    }
  }

  @HostListener('document:click', ['$event'])
  checkBackdrop(event) {
    if (event?.target?.parentNode?.className === 'cdk-overlay-container') {
      this.closeFromListener();
    }
    this.handleWeekRangeSelection(event);
  }

  @HostListener('document:keydown.escape', ['$event'])
  checkEscapeKey(event: KeyboardEvent) {
    this.closeFromListener();
  }
}
