import { AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import * as check from 'check-types';

import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../../services/error/error-manager.service';
import { SearchOptionComponent } from '../search-option/search-option.component';

@Component({
  selector: 'orgos-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit {
  _searchTerm: string;
  set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
    this.refreshSearchResults();
    this.selectedIndex = 0;
  }
  get searchTerm(): string {
    return this._searchTerm;
  }

  someSearchResult: boolean;

  selectedIndex = 0;

  @ViewChild('searchList', { read: ElementRef }) searchList: ElementRef;

  @Input() alternativeStyle: boolean;
  @Input() placeholder: string;
  @Input() tooltipMessage: string;
  @Input() disabled: boolean;
  @Input() searchFunction: SearchFunction;
  @Input() icon: string = 'arrow_drop_down';

  @Output() searchResultsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  constructor(private errorManager: ErrorManagerService) {}

  ngOnInit(): void {
    this.searchTerm = '';
  }

  clearSearch(): void {
    this.searchTerm = '';
  }

  refreshSearchResults(): void {
    if (check.not.assigned(this.searchFunction) || check.not.function(this.searchFunction)) {
      const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.CLIENT_ERROR, SearchComponent.name, 'refreshSearchResults');
      error.message = 'Search components must be initialized with a search function.';
      this.errorManager.handleParsedErrorSilently(error);
      return;
    }

    this.searchFunction(this.searchTerm)
      .then((searchResults) => {
        if (check.not.assigned(searchResults) || check.not.array(searchResults)) {
          return;
        }
        this.someSearchResult = searchResults.length > 0;
        this.searchResultsChange.emit(searchResults);
      })
      .catch(() => {
        // Search function should handle all possible errors in order to hide implementation
        // From this part, the best solution to act as if there are not results.
        this.someSearchResult = false;
        this.searchResultsChange.emit([]);
      });
  }

  searchFocus(): void {
    if (this.someSearchResult) {
      setTimeout(() => {
        this.searchList?.nativeElement.children[this.selectedIndex].focus();

        if (this.selectedIndex === 0) {
          this.searchList?.nativeElement.children[this.selectedIndex].scrollIntoView();
        }
      }, 0);
    }
  }

  moveInSearch(arrow: 'up' | 'down'): void {
    if (arrow === 'down') {
      if (this.selectedIndex + 1 < this.searchList.nativeElement.children.length) {
        this.selectedIndex++;
        this.searchFocus();
      }
    }

    if (arrow === 'up') {
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
        this.searchFocus();
      }
    }
  }
}

export type SearchFunction = (value: string) => Promise<Array<any>>;
