import { Injector, Pipe, PipeTransform } from '@angular/core';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Pipe({
  name: 'coreFeatures',
  pure: false
})
export class CoreFeaturesPipe implements PipeTransform {
  private cachedResult: boolean;
  private cachedCoreFeature: any;
  private cachedFeatureKey: string;
  constructor(private injector: Injector) {}

  transform(featureKey: string): boolean {
    if (check.not.assigned(featureKey) || check.not.string(featureKey) || check.emptyString(featureKey)) {
      return false;
    }

    if (check.assigned(this.cachedResult) && _.eq(this.cachedFeatureKey, featureKey)) {
      return this.cachedResult;
    }

    this.computeCoreFeatures(featureKey);
    return false;
  }

  async computeCoreFeatures(featureKey: string) {
    try {
      if (check.not.assigned(this.cachedCoreFeature) || !_.eq(this.cachedFeatureKey, featureKey)) {
        this.cachedFeatureKey = featureKey;
        this.cachedResult = false;
        this.cachedCoreFeature = (await this.injector.get(CloudRoutesService).getAppStatus(featureKey)) ?? { appKey: featureKey, isActive: false };
      }
      this.cachedResult = this.cachedCoreFeature?.isActive;
    } catch (error) {
      this.cachedFeatureKey = featureKey;
      this.cachedResult = false;
    }
  }
}
