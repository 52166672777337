import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kenjo-icon-toggle',
  templateUrl: 'icon-toggle.component.html',
  styleUrls: ['icon-toggle.component.scss']
})
export class IconToggleComponent {
  optionChosen: string = '';
  @Input() selectedValue;
  @Input() optionAIcon;
  @Input() optionBIcon;

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle(checked: boolean): void {
    this.selectedValue = checked;
    this.checkedChange.emit(checked);
  }
}
