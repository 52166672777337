import { Component, Input } from '@angular/core';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-text',
  templateUrl: 'input-text.component.html',
  styleUrls: ['input-text.component.scss']
})
export class InputTextComponent extends InputAbstractComponent {
  @Input() leftDot = false;
  @Input() placeholder: string = '';
}
