import { Injectable, Injector } from '@angular/core';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import * as permissions from '@carlos-orgos/orgos-security/permissions';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../../standard/services/error/error-manager.service';

@Injectable({
  providedIn: 'root'
})
export class PrivateSecurityService {
  private cache: Promise<object>;

  constructor(private errorManager: ErrorManagerService, private injector: Injector) { }

  getAllPermissions(): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      if (check.not.assigned(this.cache)) {
        this.cache = this.fetchFullProfile();
      }
      this.cache
        .then((profileData: object) => {
          resolve(profileData['permissions']);
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  getPermissionsForCollection(collectionName: string): Promise<any> {
    return new Promise<object>((resolve, reject) => {
      if (check.not.assigned(this.cache)) {
        this.cache = this.fetchFullProfile();
      }

      this.cache
        .then((profileData: object) => {
          resolve(profileData['permissions'][collectionName]);
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  getPermissionsForCollections(arrayCollections: Array<string>): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      if (check.not.assigned(this.cache)) {
        this.cache = this.fetchFullProfile();
      }

      this.cache
        .then((profileData: any) => {
          const result = {};
          arrayCollections.forEach((iCollection: string) => {
            if (check.assigned(profileData['permissions'][iCollection])) {
              result[iCollection] = profileData['permissions'][iCollection];
            }
          });
          resolve(result);
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  getFullProfile(): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      if (check.not.assigned(this.cache)) {
        this.cache = this.fetchFullProfile();
      }

      this.cache
        .then((profileData: object) => {
          resolve(profileData);
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  clearCache(): void {
    this.cache = null;
  }

  private fetchFullProfile(): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      const authorizationHeader = this.injector.get(AuthenticationService).getAuthorizationHeader();
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

      permissions
        .getProfilePermissions(loggedUser.profileKey, authorizationHeader, environment.PEOPLE_CLOUD_APP_URL)
        .then((permissionsData) => {
          const result = Object.assign({}, loggedUser.profile);
          result['permissions'] = permissionsData;
          result['_id'] = loggedUser.profile._profileKey;
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PrivateSecurityService.name, 'fetchFullProfile'));
        });
    });
  }

  async computePermissions(document: any, permissionKey: string) {
    try {
      const allPermissions = await this.getAllPermissions();

      const permissionsAll = (_.get(allPermissions, `${permissionKey}_all`) || _.get(allPermissions, permissionKey)) ?? false;

      if (permissionsAll === true) {
        return permissionsAll;
      }

      if (check.not.assigned(document)) {
        return false;
      }

      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      const permissionsOwn = _.get(allPermissions, `${permissionKey}_own`) ?? false;
      if (permissionsOwn === true && check.assigned(document.ownerId) && loggedUser?._id === document.ownerId) {
        return permissionsOwn;
      }

      const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();

      const permissionsCustom = _.get(allPermissions, `${permissionKey}_custom`) ?? [];

      const [collectionName] = permissionKey.split('.');

      const permissionCustom = await customPermissions.applyCustomPermissionsToDocument(null, collectionName, permissionsCustom, permissionsOwn, document, allUserWork, loggedUser);
      return permissionCustom ?? false;
    } catch (error) {
      return false;
    }
  }
}
