import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as moment from 'moment';

@Pipe({
  name: 'timeOffTime',
  pure: false
})
export class TimeOffTimePipe implements PipeTransform {
  constructor() {}

  transform(date: string): string {
    if (check.not.nonEmptyString(date)) {
      return '';
    }
    const momentDate = moment.utc(date);
    if (momentDate.isValid() === false) {
      return '';
    }
    return momentDate.format('LT');
  }
}
