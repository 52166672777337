<div *ngIf="toDosList.length > 0 && !loading" class="kenjo-pt-30px kenjo-ph-40px">
  <orgos-column-container>
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <div class="prtd-sort-icon kenjo-mb-10px" (click)="changeToDosSortOrder()">
        <kenjo-icon [matTooltip]="sortTooltip" matTooltipPosition="below" [size]="20">{{ sortIcon }}</kenjo-icon>
      </div>
    </orgos-column>
  </orgos-column-container>
  <kenjo-performance-review-to-do-card
    *ngFor="let toDo of toDosList"
    [toDo]="toDo"
    (nominatePeersClick)="onNominatePeersClick($event)"
    (viewNominationsClick)="onViewNominationsClick($event)"
    (writeReviewClick)="onWriteReviewClick($event)"
    (readReviewClick)="onReadReviewClick($event)"
    (viewNominationsSubordinatesClick)="onViewNominationsSubordinatesClick($event)"
    (nominateSubordinatesClick)="onNominateSubordinatesClick($event)"
  ></kenjo-performance-review-to-do-card>
</div>

<div *ngIf="toDosList.length === 0 && !loading">
  <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyPerformanceData.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{ pageTranslations.noToDosTitle }}</div>
  </div>
</div>

<div *ngIf="loading" class="prtd-loading">
  <orgos-loading-spinner [color]="'Success'"></orgos-loading-spinner>
</div>
