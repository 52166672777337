import { Component, Injector, Input, OnInit } from '@angular/core';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'orgos-recruiting-score-results-line',
  templateUrl: 'recruiting-score-results-line.component.html',
  styleUrls: ['recruiting-score-results-line.component.scss']
})
export class RecruitingScoreResultsLineComponent implements OnInit {
  i18n: any = {};
  validRating: boolean = false;

  private _rating: Array<IRating>;
  @Input()
  set rating(rating: Array<IRating>) {
    this._rating = rating;
    this.evaluateRating();
  }
  get rating(): Array<IRating> {
    return this._rating;
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('recruiting-scorecards-summary-page')
      .then((i18n) => {
        this.i18n = i18n;
      })
      .catch(() => {
        this.i18n = {};
      });
  }

  public getTooltipTranslation(translationKey, numEmployees): string {
    const data = {
      nEmployees: numEmployees
    };
    const result = this.injector.get(I18nDataPipe).transform(this.i18n[translationKey], data);
    return result;
  }

  private evaluateRating(): void {
    this.validRating = false;
    if (this.getRatingValue('1') > 0 || this.getRatingValue('2') > 0 || this.getRatingValue('3') > 0 || this.getRatingValue('4') > 0 || this.getRatingValue('5') > 0) {
      this.validRating = true;
    }
  }

  private getRatingValue(index: string): number {
    return this.rating[index];
  }
}

export interface IRating {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
}
