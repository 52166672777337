import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class UserController {
  private USER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/user`;

  constructor(private injector: Injector, private http: HttpClient, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  async getUserPersonalsByUserWork(findQuery: any): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.USER_URL, ErrorCodes.UNAUTHORIZED, UserController.name, 'getUserPersonalsByUserWork');
        throw this.errorManager.handleRawError(error, UserController.name, 'getUserPersonalsByUserWork');
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const userPersonal = await this.http.post(`${this.USER_URL}/user-personal`, findQuery, httpOptions).toPromise();

      return userPersonal;
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserController.name, 'getUserPersonalsByUserWork');
    }
  }
}
