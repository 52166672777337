import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { EditCustomPermissionsDialog } from '@app/standard/pages/settings-roles-and-permissions/edit-custom-permissions-dialog/edit-custom-permissions.dialog';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-permissions-package',
  templateUrl: 'permissions-package.component.html',
  styleUrls: ['permissions-package.component.scss'],
})
export class PermissionsPackage implements OnInit {
  i18n: any = {};
  customTranslations: any = {};

  @Output() permissionsChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() readOnlyValues: any = {};
  @Input() readOnly: boolean = true;
  @Input() packageKey: any;
  private previousPermissions: any = {};
  private _permissionValues: any = {};
  @Input()
  set permissionValues(permissionValues: any) {
    this._permissionValues = _.cloneDeep(permissionValues);
    this.previousPermissions = _.cloneDeep(permissionValues);
  }
  get permissionValues(): any {
    if (check.not.assigned(this._permissionValues)) {
      return {};
    }
    return this._permissionValues;
  }

  @Input() permissionsPackage: Array<any>;
  constructor(private injector: Injector) {}

  ngOnInit(): void {
    if (check.not.assigned(this.readOnlyValues)) {
      this.readOnlyValues = {};
    }
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((i18n) => {
        this.i18n = i18n;
      })
      .catch(() => {
        this.i18n = {};
      });
  }

  public emitChange(collectionKey?: string): void {
    const permissionKey = check.assigned(collectionKey) ? collectionKey : this.packageKey;
    const data = {
      permissionKey: permissionKey,
      permissions: this.permissionValues[permissionKey],
      previousPermissions: this.previousPermissions[permissionKey],
      packageKey: this.packageKey,
    };
    this.permissionsChanged.emit(data);
  }

  public openCustomPermissionsDialog(permissionName: string, area: string): void {
    if (check.not.assigned(permissionName) || check.not.string(permissionName) || this.readOnly === true) {
      return;
    }
    const data = {
      permissions: _.cloneDeep(this.permissionValues[this.packageKey][area][permissionName]),
      permissionLabel: this.i18n[permissionName],
      permissionKey: this.packageKey,
      permissionName: permissionName,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditCustomPermissionsDialog, { data });
    dialogRef.afterClosed().subscribe((newPermissions) => {
      if (check.not.assigned(newPermissions) || check.not.array(newPermissions)) {
        return;
      }

      this.permissionValues[this.packageKey][area][permissionName] = newPermissions;

      this.emitChange();
    });
  }
}
