<div *ngIf="i18n.page && parentTranslation">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575 sspo-flex">{{parentTranslation.generalHeader}}
    <span class="kenjo-font-color-text-333333">&nbsp;/&nbsp;{{parentTranslation.viewOptionsSettings}}</span>
    <span [style.background]="'#dedede'" [style.borderRadius]="'6px'" class="kenjo-p-5px kenjo-inline-block kenjo-ml-20px kenjo-font-size-14px kenjo-font-color-white-ffffff">{{parentTranslation.betaLabel}}</span>
  </div>
  <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
    <div orgosColumn="2" class="kenjo-font-size-16px kenjo-pr-40px">{{i18n.page.employeesCanSeeOthers}}</div>
    <mat-slide-toggle orgosColumn="3" [checked]="generalSettings.employeesCanSeeOtherSchedules" (change)="changeSeeOtherSchedules($event.checked)"></mat-slide-toggle>
  </orgos-column-container>

  <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
    <div orgosColumn="2" class="kenjo-font-size-16px kenjo-pr-40px">{{i18n.page.employeesCanSeeAvailable}}</div>
    <mat-slide-toggle orgosColumn="3" [checked]="generalSettings.employeesCanSeeOtherUnavailable" (change)="changeSeeOtherUnavailable($event.checked)"></mat-slide-toggle>
  </orgos-column-container>
</div>
