import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, UrlSegment } from '@angular/router';
import { IValidateHashOptions, IValidateHashResult, PrivateHashService } from '@app/private/services/private-hash.service';
import { ISignInWithHashOptions, PrivateSignInService } from '@app/private/services/private-sign-in.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ACCESS_TYPE_WHISTLEBLOWER, HASH_TYPE_WHISTLEBLOWER_REPORTER } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';

@Injectable({
  providedIn: 'root'
})
export class WhistleblowerHashGuard implements CanActivate {
  constructor(private injector: Injector) {}

  canLoad(_: Route, urlSegments: Array<UrlSegment>): boolean {
    if (!urlSegments?.length) {
      this.injector.get(Router).navigate(['/signin']);
      return false;
    }

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (!route?.params?.portalIdentifier) {
        this.injector.get(Router).navigate(['/signin']);
        resolve(false);
        return;
      }

      const hash = route.params.portalIdentifier;
      if (hash?.length !== picklistConstants.WHISTLEBLOWER_PORTAL_IDENTIFIER_LENGTH) {
        this.injector.get(Router).navigate(['/signin']);
        const error = new OrgosError(`The portal URL is not valid`, ErrorCodes.UNAUTHORIZED);
        error.message = `The portal URL is not valid`;
        this.injector.get(ErrorManagerService).handleParsedError(error);
        resolve(false);
        return;
      }

      const validateHashOptions: IValidateHashOptions = {
        type: HASH_TYPE_WHISTLEBLOWER_REPORTER,
        hashValue: hash
      };

      this.injector
        .get(PrivateHashService)
        .validateHash(validateHashOptions)
        .then((validateHashResult: IValidateHashResult) => {
          const signInWithHashOptions: ISignInWithHashOptions = {
            type: HASH_TYPE_WHISTLEBLOWER_REPORTER,
            hashValue: hash,
            referenceId: validateHashResult.referenceId,
            s_orgId: validateHashResult.s_orgId
          };
          return this.injector.get(PrivateSignInService).signInWithWhistleblowerHash(signInWithHashOptions);
        })
        .then(() => {
          if (this.injector.get(AuthenticationService).getAccessType() !== ACCESS_TYPE_WHISTLEBLOWER) {
            this.injector.get(Router).navigate(['/signin']);
            resolve(false);
            return;
          }

          resolve(true);
          return;
        })
        .catch(() => {
          // An error is already shown if necessary
          this.injector.get(Router).navigate(['/signin']);
          resolve(false);
          return;
        });
    });
  }
}
