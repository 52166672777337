import 'moment/locale/de';
import 'moment/locale/de-at';
import 'moment/locale/en-gb';
import 'moment/locale/es';

import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeAT from '@angular/common/locales/de-AT';
import localeEnUS from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeEsMX from '@angular/common/locales/es-MX';
import { Inject, Injectable, Injector } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { LegacyDateAdapter } from '@angular/material/legacy-core';
import * as check from 'check-types';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { ErrorManagerService } from '../../standard/services/error/error-manager.service';

@Injectable({
  providedIn: 'root',
})
export class PrivateInternationalizationService {
  private INTERNATIONALIZATION_DB_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/internationalization-db`;

  private languageToUse: string = 'en';
  private userLanguage: string;
  private urlLanguage: string;

  private localeToUse: string = 'en-GB';

  private cache: Map<string, Promise<any>> = new Map<string, Promise<any>>();

  private miscTranslation: any = {};

  constructor(private injector: Injector, @Inject(LegacyDateAdapter) private dateAdapter: MomentDateAdapter) {}

  setUrlLanguage(urlLanguage: string = 'en'): void {
    if (check.not.assigned(urlLanguage)) {
      this.urlLanguage = null;
    } else if (this.urlLanguage !== urlLanguage.toLowerCase()) {
      this.urlLanguage = urlLanguage.toLowerCase();
    }

    this.setLanguageToUse();
  }

  setUserLanguage(userLanguage: string = 'en'): void {
    if (this.userLanguage !== userLanguage) {
      this.userLanguage = userLanguage;
    }

    this.setLanguageToUse();
  }

  setUserLocale(userLocale: string = 'en-GB'): void {
    if (userLocale === 'de') {
      registerLocaleData(localeDe, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'de-AT') {
      registerLocaleData(localeDeAT, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'es') {
      registerLocaleData(localeEs, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'es-MX') {
      registerLocaleData(localeEsMX, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else if (userLocale === 'en-US') {
      registerLocaleData(localeEnUS, 'kenjo-locale');
      this.localeToUse = userLocale;
    } else {
      registerLocaleData(localeEnGB, 'kenjo-locale');
      this.localeToUse = 'en-GB';
    }

    this.dateAdapter.setLocale(this.localeToUse);
    moment.locale(this.localeToUse.toLowerCase());
  }

  private setLanguageToUse(): void {
    if (check.assigned(this.urlLanguage) && this.languageToUse !== this.urlLanguage) {
      this.languageToUse = this.urlLanguage;
      this.cache.clear();
    } else if (check.assigned(this.userLanguage) && this.languageToUse !== this.userLanguage) {
      this.languageToUse = this.userLanguage;
      this.cache.clear();
    } else if (check.not.assigned(this.urlLanguage) && check.not.assigned(this.userLanguage) && this.languageToUse !== null) {
      this.languageToUse = 'en';
      this.cache.clear();
    }

    this.refreshMiscTranslation();
  }

  getLanguage(): string {
    return this.languageToUse;
  }

  getLocale(): string {
    return this.localeToUse;
  }

  getMiscTranslation(): any {
    return this.miscTranslation;
  }

  getAllTranslation(translationResource: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let translationPromise = this.cache.get(translationResource);

      if (check.not.assigned(translationPromise)) {
        translationPromise = this.fetchTranslation(translationResource);
        this.cache.set(translationResource, translationPromise);
      }

      translationPromise
        .then((translationData: any) => {
          resolve(translationData);
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  getTranslation(translationResource: string, key: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let translationPromise = this.cache.get(translationResource);

      if (check.not.assigned(translationPromise)) {
        translationPromise = this.fetchTranslation(translationResource);
        this.cache.set(translationResource, translationPromise);
      }

      translationPromise
        .then((translationData: any) => {
          resolve(translationData[key]);
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  private refreshMiscTranslation() {
    this.fetchTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  private fetchTranslation(translationResource: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let urlParams;
      if (check.assigned(this.languageToUse)) {
        urlParams = new HttpParams().set('lang', this.languageToUse);
      } else {
        urlParams = new HttpParams();
      }

      const httpOptions = {
        params: urlParams,
      };
      this.injector
        .get(HttpClient)
        .get(`${this.INTERNATIONALIZATION_DB_URL}/translations/${translationResource}`, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateInternationalizationService.name, 'fetchTranslation'));
        });
    });
  }

  /**
   * Returns an array of strings with the weekdays
   * translated into the current user's language (instead of region)
   * _Example_:
   * ```
   * ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
   * ```
   */
  public getTranslatedWeekdays() {
    return this.getTranslatedWeekdaysWithFormat('dddd');
  }

  /**
   * Returns an array of strings with first two characters of the weekdays
   * translated into the current user's language (instead of region)
   * _Example_:
   * ```
   * ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
   * ```
   */
  public getShortTranslatedWeekdays() {
    return this.getTranslatedWeekdaysWithFormat('dd');
  }

  /**
   * ## getSanitizedUTCToTimeZone
   * Generates an UTC date **bypassing** the hour-shift of the
   * current time zone.
   * -----------------------------------
   * > **Warning**: Use only on edge cases
   *
   * -----------------------------------
   * @param date A `MomentInput` (can be empty, a date string...)
   * @returns a `moment.utc` instance with the local hour as if it were UTC.
   */
  public getSanitizedUTCToTimeZone(date?: moment.MomentInput) {
    const localDate = moment(date).local();
    const serialized = localDate.format('MM/DD/YYYY');
    const sanitizedUTC = moment.utc(serialized, 'MM/DD/YYYY').startOf('day');
    return sanitizedUTC;
  }

  /**
   * ## getSanitizedTimeZoneFromUTC
   * Generates an non-UTC date **bypassing** the hour-shift of the
   * current time zone.
   * -----------------------------------
   * > **Warning**: Use only on edge cases
   *
   * -----------------------------------
   * @param date A UTC `MomentInput` (can be empty, a date string...)
   * @returns a `moment` (non-UTC) instance with the UTC hour as if it were local.
   */
  public getSanitizedTimeZoneFromUTC(date?: moment.MomentInput) {
    const utcDate = moment.utc(date);
    const serialized = utcDate.format('MM/DD/YYYY');
    const sanitizedUTC = moment(serialized, 'MM/DD/YYYY').startOf('day');
    return sanitizedUTC;
  }

  /**
   * Returns an array of strings of the formatted weekdays
   * translated into the current user's language (instead of region)
   */
  private getTranslatedWeekdaysWithFormat(format: string) {
    const currentLanguage = this.getLanguage();
    const localizedMoment = moment().locale('es').startOf('week').locale(currentLanguage); // We set locale as 'es' to force Monday as start of week (KS-848)
    const result: Array<string> = [];
    for (let i = 0; i < 7; i++) {
      result.push(localizedMoment.format(format));
      localizedMoment.add(1, 'day');
    }
    return result;
  }
}
