import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class OAuthIntegrationService {
  private OAUTH_INTEGRATION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/oauth-integration-db`;

  constructor(private injector: Injector) {}

  exchangeCode(integration: string, code: string, state: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.OAUTH_INTEGRATION_URL}/exchange-code`, ErrorCodes.UNAUTHORIZED, OAuthIntegrationService.name, 'exchangeCode');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      const body = {
        integration,
        code,
        state
      };

      this.injector
        .get(HttpClient)
        .post(`${this.OAUTH_INTEGRATION_URL}/exchange-code`, body)
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OAuthIntegrationService.name, 'exchangeCode'));
        });
    });
  }

  getConnectedIntegrations(): Promise<Array<string>> {
    return new Promise<Array<string>>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.OAUTH_INTEGRATION_URL}/connected-integrations`, ErrorCodes.UNAUTHORIZED, OAuthIntegrationService.name, 'getConnectedOauthIntegrations');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      this.injector
        .get(HttpClient)
        .get(`${this.OAUTH_INTEGRATION_URL}/connected-integrations`)
        .toPromise()
        .then((response: any) => {
          const connectedIntegrations = check.assigned(response.connectedIntegrations) && check.array(response.connectedIntegrations) ? response.connectedIntegrations : [];
          resolve(connectedIntegrations);
        })
        .catch((error) => {
          this.injector.get(ErrorManagerService).handleRawError(error, OAuthIntegrationService.name, 'getConnectedOauthIntegrations');
          resolve([]);
        });
    });
  }

  disconnectIntegration(integration: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(`${this.OAUTH_INTEGRATION_URL}/revoke/${integration}`, ErrorCodes.UNAUTHORIZED, OAuthIntegrationService.name, 'disconnectIntegration');
        reject(this.injector.get(ErrorManagerService).handleRawError(error));
        return;
      }

      this.injector
        .get(HttpClient)
        .delete(`${this.OAUTH_INTEGRATION_URL}/revoke/${integration}`)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, OAuthIntegrationService.name, 'disconnectIntegration'));
        });
    });
  }
}
