import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { WhistleblowerPortalService } from '@app/cloud-features/whistleblower-center/services/whistleblower-portal.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { IOrganization, PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import { DeleteDummyDataDialog } from '@app/standard/pages/settings-overview/dialogs/delete-dummy-data.dialog';
import { CoreFeaturesService, IFeature } from '@app/standard/services/core-features/core-features.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { IUploadPictureOptions, UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { SettingsBarService } from '@app/standard/services/settings/settings-bar.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-settings-overview',
  templateUrl: 'settings-overview.page.html',
  styleUrls: ['settings-overview.page.scss'],
})
export class SettingsOverviewPage extends GenericPage {
  currentUser: IUserAccountModel;
  organization: IOrganization;
  brandNameValidation: InputValidation;

  apps: Array<IFeature> = [];
  appsMap: { [id: string]: IFeature } = {};
  allWidgets: Array<IFeature> = [];
  widgets: Array<IFeature> = [];
  integrations: Array<IFeature> = [];
  dependencies: { [id: string]: string } = {};
  widgetAvailable: { [id: string]: boolean } = {};
  disabledApps: { [id: string]: boolean } = {};
  widgetsDisabled: boolean = true;

  containsDummyData: boolean = false;
  hasAccessToBilling: boolean = false;

  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'settings-overview-page' },
    { name: 'misc', translationKey: 'misc' },
    { name: 'settingsBar', translationKey: 'settings-top-bar' },
  ];

  protected profilePermissionsResources: Array<string> = ['settings-overview', 'shiftplan-app'];

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(SettingsBarService)
        .getOptions(this.i18n.settingsBar)
        .then((options) => {
          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.settingsBar.settingsTab]);
          resolve();
        })
        .catch((error) => {
          // An error is already shown
          reject(error);
        });
    });
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.currentUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.hasAccessToBilling = this.injector.get(CloudRoutesService).checkRoute('settings/billing');

    const getOrganization = this.injector.get(PrivateOrganizationService).getMyOrganization();
    const getAllApps = this.injector.get(CoreFeaturesService).getAllApps();
    const getAllWidgets = this.injector.get(CoreFeaturesService).getAllWidgets();
    const getActiveWidgets = this.injector.get(CoreFeaturesService).getActiveWidgets();
    const getDependencies = this.injector.get(CoreFeaturesService).getWidgetDependencies();
    const getAllIntegrations = this.injector.get(CoreFeaturesService).getAllIntegrations();
    const getActiveIntegrations = this.injector.get(CoreFeaturesService).getActiveIntegrations();
    const getIsDummy = this.injector.get(UserAccountService).containsDummyData();

    Promise.all([
      getOrganization,
      getAllApps,
      getAllWidgets,
      getActiveWidgets,
      getDependencies,
      getAllIntegrations,
      getActiveIntegrations,
      getIsDummy,
    ])
      .then((results) => {
        const [organization, apps, widgets, activeWidgets, dependencies, integrations, activeIntegrations, isDummy] = results;
        if (check.not.assigned(organization)) {
          rejectFetchData();
          return;
        }
        this.organization = organization;
        delete this.organization['_bypassEmail'];

        this.allWidgets = widgets;
        this.widgets = this.buildAllWidgets(this.allWidgets, activeWidgets);
        this.dependencies = dependencies;

        this.containsDummyData = isDummy;

        return Promise.all([this.buildAllApps(apps), this.buildAllIntegrations(integrations, activeIntegrations)]);
      })
      .then(([apps, integrations]) => {
        this.apps = apps;
        this.integrations = integrations;
        this.appsMap = _.keyBy(apps, 'appKey');
        return this.initWidgets();
      })
      .then(() => {
        resolveFetchData();
      })
      .catch((error) => {
        rejectFetchData();
      });
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view settings page', { category: 'Navigation', type: 'overview' });
    return Promise.resolve();
  }

  public showPicker(): void {
    if (this.profilePermissions['settings-overview'].c_manageBrand !== true) {
      return;
    }

    const uploadPictureOptions: IUploadPictureOptions = {
      makeTransformations: true,
      imageEditorOptions: {
        cropperOptions: {
          croppedCanvasOptions: {},
          aspectRatio: 65 / 20,
        },
      },
    };
    this.injector
      .get(UppyHelperService)
      .uploadGenericPicture(uploadPictureOptions)
      .then((uploadedPicture: IFileMetadata | null) => {
        if (check.not.assigned(uploadedPicture)) {
          return Promise.resolve(null);
        }

        this.injector.get(GlobalBarService).setProgressBar(true);
        this.organization.logo = uploadedPicture;
        return this.injector.get(PrivateOrganizationService).updateMyOrganization(this.organization);
      })
      .then(() => {
        this.injector.get(GlobalBarService).setLogoUrl(this.organization.logo._url);
        this.injector.get(GlobalBarService).setProgressBar(false);
        this.injector.get(PrivateChurnzeroService).logSimpleEvent('LOGO_ADDED');
      })
      .catch(() => {
        // Do nothing, an error is already shown
      });
  }

  public saveBrand(): void {
    if (
      check.not.assigned(this.organization.brandName) ||
      check.emptyString(this.organization.brandName) ||
      check.not.assigned(this.brandNameValidation) ||
      this.brandNameValidation.hasErrors()
    ) {
      return;
    }

    if (check.not.assigned(this.organization.logo?._url)) {
      this.organization.logo = null;
    }

    this.injector
      .get(PrivateOrganizationService)
      .updateMyOrganization(this.organization)
      .then(() => {
        const mens = this.i18n.page.brandChanged;
        this.showSnackBar(mens, 'OK', 5000);
      })
      .catch(() => {
        // Do nothing, an error is already shown
      });
  }

  public async changeActivate(feature: any): Promise<void> {
    this.reloadWidgets();

    if (check.not.assigned(feature)) {
      return;
    }

    if (feature.appKey === 'datev') {
      this.injector.get(PrivateAmplitudeService).logEvent(feature?.isActive ? 'activate DATEV' : 'deactivated DATEV', {
        platform: 'Web',
        category: 'DATEV',
        subcategory1: 'Settings',
        subcategory2: 'Core activation',
      });
    }
    if (feature.appKey === 'shift-plan') {
      const activationEvent = feature.isActive === true ? 'Activate Shiftplan toggle' : 'Deactivate Shiftplan toggle';
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent(activationEvent, { platform: 'Web', category: 'Shiftplan', subcategory1: 'Settings', subcategory2: 'Core activation' });
      if (!feature.isActive) {
        this.injector.get(PrivateChurnzeroService).logDeactivateShiftplanEvent();
      }
    }
    if (feature.appKey === 'whistleblower') {
      if (feature.isActive === true) {
        await this.injector.get(WhistleblowerPortalService).activateWhistleblowerPortal();
      } else {
        const t = await this.injector.get(WhistleblowerPortalService).deactivateWhistleblowerPortal();
      }
    }
    if (feature.appKey === 'time-off') {
      this.injector.get(PrivateAmplitudeService).logEvent(feature?.isActive ? 'activate time off module' : 'deactivate time off module', {
        platform: 'Web',
        category: 'Time off',
        subcategory1: 'Settings',
        subcategory2: 'Core activation',
      });
    }

    let callToChange;
    const data = { type: this.i18n.misc[feature.appKey], action: null };
    if (check.assigned(feature.isHomeWidget) && feature.isHomeWidget === true) {
      if (feature.isActive && feature.isActive === true) {
        callToChange = this.injector.get(CoreFeaturesService).activateWidget(feature.appKey);
        data.action = this.i18n.page.enabled;
      } else {
        callToChange = this.injector.get(CoreFeaturesService).deactivateWidget(feature.appKey);
        data.action = this.i18n.page.disabled;
      }
    } else if (check.assigned(feature.isOauth) && feature.isOauth === true && (!feature.isActive || feature.isActive === false)) {
      callToChange = this.injector.get(CoreFeaturesService).deactivateOauthIntegration(feature.appKey);
      data.action = this.i18n.page.disabled;
    } else {
      if (feature.isActive && feature.isActive === true) {
        callToChange = this.injector.get(CoreFeaturesService).activateApp(feature.appKey);
        data.action = this.i18n.page.enabled;
        if (feature.appKey === 'performance-review') {
          this.injector.get(PrivateAmplitudeService).logEvent('enable performance management module', { category: 'Performance' });
        }
      } else {
        callToChange = this.injector.get(CoreFeaturesService).deactivateApp(feature.appKey);
        data.action = this.i18n.page.disabled;
        if (feature.appKey === 'performance-review') {
          this.injector.get(PrivateAmplitudeService).logEvent('deactivate performance management module', { category: 'Performance' });
        }
      }
    }

    callToChange
      .then(() => {
        this.initWidgets();
        const mens = this.injector.get(I18nDataPipe).transform(this.i18n.page.featureChanged, data);
        this.showSnackBar(mens, 'OK', 5000);
      })
      .catch(() => {
        // Do nothing, an error is already shown
      });
  }

  // PRIVATE METHODS
  private showSnackBar(message: string, result: string, time: number): void {
    this.injector.get(MatLegacySnackBar).open(message, result, { duration: time });
  }

  private buildAllApps(full: Array<IFeature>): Promise<Array<IFeature>> {
    return new Promise((resolve, reject) => {
      const filteredApps = full.filter((iApp) => check.not.assigned(iApp.parentSettings));
      const callDetailsApps = filteredApps.map((iApp) => {
        return this.injector.get(CoreFeaturesService).getAppDetail(iApp.appKey);
      });

      const allAppsMap = _.keyBy(filteredApps, 'appKey');
      Promise.all(callDetailsApps)
        .then((apps) => {
          const allApps = apps
            .map((iApp) => {
              const tempApp = allAppsMap[iApp.appKey];
              tempApp.isActive = iApp.isActive;
              tempApp.lastSupportNotificationDate = iApp.lastSupportNotificationDate;
              return tempApp;
            })
            .sort((featureA: IFeature, featureB: IFeature) => {
              const nameA = this.i18n.misc[featureA.appKey].toLowerCase();
              const nameB = this.i18n.misc[featureB.appKey].toLowerCase();
              return nameA.localeCompare(nameB);
            });
          const profileKey = this.currentUser.profileKey;
          allApps.forEach((iApp: any) => {
            if (iApp.appKey === 'asana') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageAsana !== true;
            } else if (iApp.appKey === 'attendance') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageAttendance !== true;
            } else if (iApp.appKey === 'performance-management') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageGoals !== true;
            } else if (iApp.appKey === 'performance-management-meetings') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageMeetings !== true;
            } else if (iApp.appKey === 'org-chart') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageOrgChart !== true;
            } else if (iApp.appKey === 'pulse') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_managePulse !== true;
            } else if (iApp.appKey === 'project-management') {
              this.disabledApps[iApp.appKey] = profileKey !== 'admin';
            } else if (iApp.appKey === 'recruiting') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageRecruiting !== true;
            } else if (iApp.appKey === 'reports') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageReports !== true;
            } else if (iApp.appKey === 'slack') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageSlack !== true;
            } else if (iApp.appKey === 'documents') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageSmartDocs !== true;
            } else if (iApp.appKey === 'time-off') {
              this.disabledApps[iApp.appKey] = this.profilePermissions['settings-overview'].c_manageTimeOff !== true;
            } else if (iApp.appKey === 'calendar') {
              this.disabledApps[iApp.appKey] = profileKey !== 'admin' && profileKey !== 'hr-admin';
            } else if (iApp.appKey === 'digital-signature') {
              this.disabledApps[iApp.appKey] = profileKey !== 'admin' && profileKey !== 'hr-admin';
            } else if (iApp.appKey === 'thank-you-notes') {
              this.disabledApps['thank-you-notes'] = profileKey !== 'admin' && profileKey !== 'hr-admin';
            } else if (iApp.appKey === 'performance-review') {
              this.disabledApps['performance-review'] = profileKey !== 'admin' && profileKey !== 'hr-admin';
            } else if (iApp.appKey === 'whistleblower') {
              this.disabledApps['whistleblower'] = profileKey !== 'admin' && profileKey !== 'hr-admin';
            } else if (iApp.appKey === 'shift-plan') {
              this.disabledApps['shift-plan'] =
                !this.profilePermissions['shiftplan-app'] ||
                !this.profilePermissions['shiftplan-app']['features'] ||
                !this.profilePermissions['shiftplan-app']['features'].c_viewSettingsTab
                  ? true
                  : false;
            } else {
              this.disabledApps[iApp.appKey] = false;
            }
          });

          resolve(allApps);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private initWidgets(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.widgetsDisabled = this.profilePermissions['settings-overview'].c_manageHomeWidgets !== true;

      this.widgetAvailable = this.allWidgets.reduce((result, widget) => {
        const dependency = this.dependencies[widget.appKey];
        result[widget.appKey] =
          check.not.assigned(dependency) ||
          (check.assigned(dependency) && check.assigned(this.appsMap[dependency]) && this.appsMap[dependency].isActive === true);
        return result;
      }, {});

      const callToDeactive = [];
      this.widgets.forEach((widget) => {
        if (widget.isActive === true && this.widgetAvailable[widget.appKey] === false) {
          callToDeactive.push(this.injector.get(CoreFeaturesService).deactivateWidget(widget.appKey));
        }
      });

      Promise.all(callToDeactive)
        .then(() => {
          return this.injector.get(CoreFeaturesService).getActiveWidgets();
        })
        .then((activeWidgets) => {
          this.widgets = this.buildAllWidgets(this.allWidgets, activeWidgets);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  private buildAllWidgets(full: Array<IFeature>, active: Array<string>): Array<IFeature> {
    const widgets = full
      .filter((iWidget) => {
        return check.not.assigned(iWidget.parentSettings);
      })
      .map((iWidget) => {
        iWidget.isActive = active.includes(iWidget.appKey);
        return iWidget;
      })
      .sort((featureA: IFeature, featureB: IFeature) => {
        const nameA = this.i18n.misc[featureA.appKey].toLowerCase();
        const nameB = this.i18n.misc[featureB.appKey].toLowerCase();
        return nameA.localeCompare(nameB);
      });
    return widgets;
  }

  async buildAllIntegrations(full: Array<IFeature>, active: Array<string>): Promise<Array<IFeature>> {
    const integrations = full
      .map((iIntegration) => {
        iIntegration.isActive = active.includes(iIntegration.appKey);
        return iIntegration;
      })
      .sort((featureA: IFeature, featureB: IFeature) => {
        if (featureA.appKey === 'api-keys') {
          return -1;
        }

        if (featureB.appKey === 'api-keys') {
          return 1;
        }

        const nameA = this.i18n.misc[featureA.appKey].toLowerCase();
        const nameB = this.i18n.misc[featureB.appKey].toLowerCase();
        return nameA.localeCompare(nameB);
      });

    const appDetails = await Promise.all(
      integrations.map((iIntegration) => this.injector.get(CoreFeaturesService).getAppDetail(iIntegration.appKey))
    );
    integrations.forEach((iIntegration) => {
      iIntegration.lastSupportNotificationDate = appDetails.find(
        (iAppDetail) => iAppDetail.appKey === iIntegration.appKey
      )?.lastSupportNotificationDate;
    });

    return integrations;
  }

  public trackApps(item: IFeature): string {
    return item.appKey;
  }

  public deleteDummyData(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(DeleteDummyDataDialog);
    dialogRef.afterClosed().subscribe((dummyDataDeleted: boolean) => {
      if (dummyDataDeleted === true) {
        this.containsDummyData = false;
      }
    });
  }

  /**
   * Change all the values of this.widgets so that the
   * component orgos-feature-box updates the value we are passing
   */
  private reloadWidgets() {
    this.widgets.forEach((iWidget, index) => {
      const tmp = _.cloneDeep(iWidget);
      this.widgets[index] = { appKey: iWidget.appKey, isActive: false };
      this.widgets[index] = tmp;
    });
  }

  navigateToDataImport(): void {
    this.router.navigateByUrl(`cloud/settings/data-import`);
  }

  checkUsageButtonClicked(): void {
    this.router.navigateByUrl(`cloud/settings/billing/platform-usage`);
  }
}
