<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogHeader" (clickCloseButton)="closeDialog()">
  <orgos-search [placeholder]="dialogTranslation.placeHolderText" [searchFunction]="searchUserFunction" (searchResultsChange)="searchResults = $event">
    <orgos-text class="orgos-search-error" color="Warn">{{dialogTranslation.warningMessageText}}</orgos-text>
    <orgos-search-option *ngFor="let result of searchResults" [userPersonal]="result" (selected)="selectUser(result)">
      <kenjo-truncate-text>{{result.displayName}}</kenjo-truncate-text>
    </orgos-search-option>
    <orgos-container class="orgos-search-selection-container">
      <orgos-chip *ngFor="let result of searchResults" [imageUrl]="result._photo?._url" class="kenjo-mv-10px" [addInitialsIfNoImage]="true" [chipText]="result.displayName">
        <orgos-text [noWrapText]="result.displayName" class="cp-search-result">{{result.displayName}}</orgos-text>
      </orgos-chip>
    </orgos-container>
  </orgos-search>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ miscTranslation.goBackButtonDialog }} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="updateDocumentsUser()" color="Success"> {{dialogTranslation.assignButtonLabel}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
