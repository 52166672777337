<ng-container *ngIf="loadingPage === false">
  <!-- HEADER OF SECTION STARTS -->
  <orgos-column-container class="kenjo-pb-20px" centerColumns="true">
    <orgos-column class="kenjo-font-size-18px">{{i18n.page.activeUsersSectionHeader}}</orgos-column>
    <orgos-column size="0" class="kenjo-ml-40px"> {{activeUsers.length}} {{i18n.page.members}} </orgos-column>
  </orgos-column-container>
  <!-- HEADER OF SECTION ENDS -->

  <!-- LOOP OVER ALL USERS STARTS -->
  <orgos-column-container wrap="true">
    <orgos-column size="0" *ngFor="let iUser of activeUsers">
      <a [routerLink]="['/cloud/documents/employee-docs', iUser.userPersonal._id]" class="kenjo-block kenjo-text-decoration-none kenjo-mr-20px kenjo-mb-20px kenjo-p-20px kenjo-border kenjo-border-radius kenjo-cursor-pointer kenjo-text-align-center dedp-card-element">
        <orgos-avatar [avatarName]="iUser.userPersonal.displayName" [photoUrl]="iUser.userPersonal._photo?._url"> </orgos-avatar>
        <kenjo-truncate-text class="kenjo-mt-10px dedp-card-element-name"> {{iUser.userPersonal.displayName}} </kenjo-truncate-text>
        <kenjo-truncate-text class="kenjo-mt-5px kenjo-font-color-light-text-757575"> {{iUser.userWork.jobTitle}} </kenjo-truncate-text>
      </a>
    </orgos-column>
  </orgos-column-container>
  <!-- LOOP OVER ALL USERS ENDS -->

  <ng-container *ngIf="inactiveUsers && inactiveUsers.length > 0">
    <!-- HEADER OF SECTION STARTS -->
    <orgos-column-container class="kenjo-pb-20px">
      <orgos-column class="kenjo-font-size-18px">{{i18n.page.inactiveUsersSectionHeader}}</orgos-column>
      <orgos-column size="0" class="kenjo-ml-40px"> {{inactiveUsers.length}} {{i18n.page.members}} </orgos-column>
    </orgos-column-container>
    <!-- HEADER OF SECTION ENDS -->

    <!-- LOOP OVER ALL USERS STARTS -->
    <orgos-column-container wrap="true">
      <orgos-column size="0" *ngFor="let iUser of inactiveUsers">
        <a [routerLink]="['/cloud/documents/employee-docs', iUser.userPersonal._id]" class="kenjo-block kenjo-text-decoration-none kenjo-mr-20px kenjo-mb-20px kenjo-p-20px kenjo-border kenjo-border-radius kenjo-cursor-pointer kenjo-text-align-center dedp-card-element">
          <orgos-avatar [avatarName]="iUser.userPersonal.displayName" [photoUrl]="iUser.userPersonal._photo?._url"> </orgos-avatar>
          <kenjo-truncate-text class="kenjo-mt-10px dedp-card-element-name"> {{iUser.userPersonal.displayName}} </kenjo-truncate-text>
          <kenjo-truncate-text class="kenjo-mt-5px kenjo-font-color-light-text-757575"> {{iUser.userWork.jobTitle}} </kenjo-truncate-text>
        </a>
      </orgos-column>
    </orgos-column-container>
    <!-- LOOP OVER ALL USERS ENDS -->
  </ng-container>
</ng-container>
