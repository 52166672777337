import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IWorkScheduleTemplateModel } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { InputNumberComponent } from '@app/standard/components/input-number/input-number.component';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { ICompanyModel } from '@app/standard/services/company/company.service';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-add-employee-work-step',
  templateUrl: 'work-step.component.html',
  styleUrls: ['work-step.component.scss'],
})
export class AddEmployeeWorkStepComponent {
  @Input() translations: any = {};
  @Input() translatedWeekdays: any = {};

  @Input() userAccount: GenericCacheModel;
  @Input() userWork: GenericCacheModel;
  @Input() userWorkSchedule: GenericCacheModel;

  @Input() companies: Array<ICompanyModel>;
  @Input() companiesOptions: Array<ISelectOption>;
  @Input() offices: Array<ISelectOption>;
  @Input() departments: Array<ISelectOption>;
  @Input() areas: Array<ISelectOption>;
  @Input() divisions: Array<ISelectOption>;

  @Input() profiles: Array<ISelectOption>;
  @Input() reportsToOptions: Array<{
    _id: string;
    displayName: string;
    _photo?: any;
    isActive: boolean;
  }>;

  @Input() workScheduleTemplates: Array<IWorkScheduleTemplateModel>;
  @Input() workScheduleTemplatesOptions: Array<ISelectOption>;
  @Input() workScheduleConfiguration: {
    workScheduleTypeSelected: 'Template' | 'Legacy';
    workScheduleTemplateSelected: string;
  };

  @Input() userProfile: string;

  @Input() isTimeOffEnabled: boolean = true;
  @Input() isTimeOffApproverActive: boolean = true;

  @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadTimeOffStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousStep: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(InputNumberComponent) childInputNumberComponent: InputNumberComponent;

  companyValidation: InputValidation;
  workEmailValidation: InputValidation;
  languageValidation: InputValidation;
  startDateValidation: InputValidation;
  contractEndDateValidation: InputValidation;
  weeklyHoursValidation: InputValidation;
  HOURS_IN_A_WEEK: number = 7 * 24;

  isWorkScheduleValid = true;
  company: any;

  bindTimeOffApproverToReportsTo: boolean = true;

  validateWorkSchedule() {
    if (this.workScheduleConfiguration.workScheduleTypeSelected === 'Template') {
      this.isWorkScheduleValid = check.assigned(this.workScheduleConfiguration.workScheduleTemplateSelected);
      return;
    }

    this.isWorkScheduleValid = true;
  }

  onCompanyChange() {
    this.onReloadTimeOffStep();
    const { companyId } = this.userWork.data;
    const company = this.companies.find((iCompany) => iCompany._id === companyId);
    if (check.not.assigned(company)) {
      return;
    }

    this.userAccount.data.language = company.defaultLanguage;
    this.userWork.data.weeklyHours = company.weeklyHours;
    this.company = company;
    if (this.childInputNumberComponent.field === 'weeklyHours') {
      this.childInputNumberComponent.setValue(company.weeklyHours);
      this.childInputNumberComponent.forceValidation();
    }

    this.userWorkSchedule.data.mondayWorkingDay = company.mondayWorkingDay;
    this.userWorkSchedule.data.tuesdayWorkingDay = company.tuesdayWorkingDay;
    this.userWorkSchedule.data.wednesdayWorkingDay = company.wednesdayWorkingDay;
    this.userWorkSchedule.data.thursdayWorkingDay = company.thursdayWorkingDay;
    this.userWorkSchedule.data.fridayWorkingDay = company.fridayWorkingDay;
    this.userWorkSchedule.data.saturdayWorkingDay = company.saturdayWorkingDay;
    this.userWorkSchedule.data.sundayWorkingDay = company.sundayWorkingDay;
  }

  onTemplateChange(templateId: string) {
    this.onReloadTimeOffStep();
    this.applyTemplate(templateId);
  }

  private applyTemplate(templateId: string) {
    const template = this.workScheduleTemplates.find((iTemplate) => iTemplate._id === templateId);
    if (check.not.assigned(template)) {
      return;
    }

    this.workScheduleConfiguration.workScheduleTemplateSelected = templateId;
    this.userWorkSchedule.data.history = [template];
  }

  onWorkScheduleTypeChange() {
    this.onReloadTimeOffStep();
    if (this.workScheduleConfiguration.workScheduleTypeSelected === 'Template') {
      if (check.assigned(this.workScheduleConfiguration.workScheduleTemplateSelected)) {
        this.applyTemplate(this.workScheduleConfiguration.workScheduleTemplateSelected);
        return;
      }
    }
  }

  onReloadTimeOffStep() {
    this.reloadTimeOffStep.emit(true);
  }

  onPreviousStep() {
    this.previousStep.emit(true);
  }

  onNextStep() {
    if (
      this.workScheduleConfiguration.workScheduleTypeSelected === 'Template' &&
      check.assigned(this.workScheduleConfiguration.workScheduleTemplateSelected)
    ) {
      this.applyTemplate(this.workScheduleConfiguration.workScheduleTemplateSelected);
    }

    if (this.workScheduleConfiguration.workScheduleTypeSelected === 'Legacy') {
      delete this.userWorkSchedule.data.history;
    }

    this.nextStep.emit(true);
  }

  computeTimeOffApprover(timeOffApproverId: string): void {
    this.bindTimeOffApproverToReportsTo = timeOffApproverId === this.userWork.data.reportsToId;
  }

  onReportsToChange(reportsToId: string): void {
    if (this.userWork.data.timeOffApproverId === this.userWork.data.reportsToId) {
      this.bindTimeOffApproverToReportsTo = true;
    }
    if (this.bindTimeOffApproverToReportsTo) {
      this.userWork.data.timeOffApproverId = reportsToId;
    }
  }
}
