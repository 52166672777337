import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { COMPONENTS, DIALOGS, EXTERNAL_MODULES, PAGES, PROVIDERS, ROUTES } from '@app/cloud-features/shift-plan/shift-plan.config';
import { INTERCEPTORS } from '@app/core/interceptors/interceptors.config';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';
@NgModule({
  declarations: [...PAGES, ...COMPONENTS, ...DIALOGS],
  imports: [CommonModule, RouterModule.forChild(ROUTES), FormsModule, MaterialModule, StandardComponentsModule, ...EXTERNAL_MODULES],
  providers: [...INTERCEPTORS, ...PROVIDERS],
})
export class ShiftPlanModule {}
