import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class PositionService {
  private POSITION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/position-db`;
  private POSITION_PERMISSIONS_KEY: string = 'position';
  private POSITION_INTERNATIONALIZATION: string = 'position-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.POSITION_URL, data)
        .then((position: any) => {
          resolve(position);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.POSITION_URL, id)
        .then((position: any) => {
          resolve(position);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.POSITION_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionService.name, 'updateById'));
        });
    });
  }

  find(findBody: object): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.genericService
        .find(this.POSITION_URL, findBody)
        .then((results: Array<any>) => {
          resolve(results);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionService.name, 'find'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.POSITION_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.POSITION_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.POSITION_INTERNATIONALIZATION);
  }

  getPositions(): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.genericService
        .find(this.POSITION_URL, { _id: { $ne: null } })
        .then((results: Array<any>) => {
          resolve(results);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PositionService.name, 'getPositions'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.POSITION_URL);
  }
}
