import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColors from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { CreateListViewDialog } from '../../../../components/list-view/dialogs/create-list-view.dialog';
import { ListViewComponent } from '../../../../components/list-view/list-view.component';
import { ISelectOption } from '../../../../core/select-option';
import { DepartmentService } from '../../../../services/company/department.service';
import { CloudRoutesService } from '../../../../services/core/cloud-routes.service';
import { GoalService, IGoalModel } from '../../../../services/performance-management/goal.service';
import { UserPersonalService } from '../../../../services/user/user-personal.service';
import { GenericPage, IMenuOption, ITranslationResource } from '../../../generic.page';
import { CreateGoalDialog } from './dialogs/create-goal-dialog/create-goal.dialog';

@Component({
  selector: 'orgos-goals',
  templateUrl: 'goals.page.html',
  styleUrls: ['goals.page.scss'],
})
export class GoalsPage extends GenericPage implements AfterViewChecked {
  protected profilePermissionsResources: Array<string> = ['goal'];

  allGoals: Array<IGoalModel>;
  userPersonalMap: any;
  TODAY: any = moment();

  viewOptions: Array<ISelectOption> = [];

  statusColorMap: any = {
    OnTrack: userColors.USER_COLOR_3,
    Behind: '#FFC107',
    Achieved: '#00B72E',
    NotAchieved: '#FF8D0B',
    Cancelled: '#8F8F8F',
    Deferred: '#8F8F8F',
    NotStarted: userColors.USER_COLOR_2,
  };

  selectedTypes: Array<string> = [];
  selectedStatus: Array<string> = [];
  statusValues: Array<string> = picklists.GOAL_STATUS_LIST;
  selectedDepartments: Array<string> = [];
  departments: Array<any> = [];
  departmentsMap: any = {};
  showDepartments: boolean = false;

  preferenceKey: string = 'view-goal';
  selectedView: string;
  viewsLoaded: boolean = false;
  filters: { [id: string]: boolean | string } = {};
  filteredData: Array<any> = [];

  TYPE_INDIVIDUAL: string = picklists.GOAL_TYPE_INDIVIDUAL;
  TYPE_DEPARTMENT: string = picklists.GOAL_TYPE_DEPARTMENT;
  TYPE_BRAND: string = picklists.GOAL_TYPE_BRAND;

  @ViewChild(ListViewComponent) listView: ListViewComponent;
  ngAfterViewChecked(): void {
    if (check.assigned(this.listView)) {
      this.viewsLoaded = this.listView.isLoaded;

      this.cdr.detectChanges();
    }
  }
  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'goals-misc' },
    { name: 'page', translationKey: 'goals-page' },
    { name: 'picklists', translationKey: 'standard-picklists' },
  ];

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    const NUMBER_OF_DATA_TO_FETCH = 3;
    let dataFetched = 0;

    this.injector
      .get(GoalService)
      .getAllGoals()
      .then((allGoals: Array<IGoalModel>) => {
        this.allGoals = allGoals;
        this.filteredData = allGoals;
        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.allGoals = [];
        rejectFetchData();
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, true)
      .then((allUserPersonal: Array<any>) => {
        this.userPersonalMap = _.keyBy(allUserPersonal, '_id');

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.userPersonalMap = {};
        rejectFetchData();
      });

    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((resultDepartments) => {
        this.departments = resultDepartments;
        this.departmentsMap = _.keyBy(this.departments, '_id');
        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        this.allGoals = [];
        rejectFetchData();
      });
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.misc.pageName;

    const options: Array<IMenuOption> = [
      { name: this.i18n.misc.homeTab, onClick: () => this.router.navigateByUrl('/cloud/home') },
      { name: this.i18n.misc.goalsTab, onClick: () => this.router.navigateByUrl('/cloud/goals') },
    ];

    if (this.injector.get(CloudRoutesService).checkRoute('meetings') === true) {
      options.push({
        name: this.i18n.misc.meetingsTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/meetings');
        },
      });
    }

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 1;

    return Promise.resolve();
  }

  public addGoal(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateGoalDialog);

    dialogRef.afterClosed().subscribe((saved) => {
      if (saved === true) {
        this.refreshData();
      }
    });
  }

  public goToGoalDetail(goal: IGoalModel): void {
    this.router.navigate([goal._id], { relativeTo: this.route });
  }

  public getKeyResultData(goal: IGoalModel): any {
    const numberOfKeyResultsCompleted = goal.keyResults.filter((iKeyResult) => {
      return iKeyResult.isCompleted === true;
    }).length;

    const data = {
      numberOfKeyResultsCompleted: numberOfKeyResultsCompleted,
      totalNumberOfKeyResults: goal.keyResults.length,
    };

    return data;
  }

  public changeFilter(id: string, newValue: boolean | string): void {
    if (newValue === true || (check.string(newValue) && check.not.emptyString(newValue))) {
      this.filters[id] = newValue;
    } else {
      delete this.filters[id];
    }
    if (check.equal(id, `type-${this.TYPE_DEPARTMENT}`)) {
      this.showDepartments = newValue === true;
    }
    this.filterData();
  }

  private filterData(): void {
    const activeFilters = Object.keys(this.filters);

    if (check.emptyArray(activeFilters)) {
      this.filteredData = this.allGoals;
      return;
    }

    const activeFiltersByType = _.groupBy(activeFilters, (iFilter) => {
      return iFilter === 'search' ? 'search' : iFilter.split('-')[0];
    });
    if (check.assigned(activeFiltersByType['search'])) {
      activeFiltersByType['search'] = [<string>this.filters['search']];
    }
    const filtering = (filteredResult, iGoal) => {
      let allGoalData = '';

      if (check.nonEmptyString(iGoal.title)) {
        allGoalData += iGoal.title;
      }
      if (check.nonEmptyString(iGoal.type)) {
        allGoalData += `type-${iGoal.type}`;
      }
      if (check.nonEmptyString(iGoal.status)) {
        allGoalData += `status-${iGoal.status}`;
      }
      if (check.nonEmptyString(iGoal.departmentId)) {
        allGoalData += `department-${iGoal.departmentId}`;
      }
      if (check.assigned(iGoal.assignedUsers) && check.array(iGoal.assignedUsers) && check.nonEmptyArray(iGoal.assignedUsers)) {
        iGoal.assignedUsers.forEach((iUserId) => {
          allGoalData += this.userPersonalMap[iUserId].displayName;
        });
      }

      allGoalData = allGoalData.toLowerCase();

      const showUser = Object.keys(activeFiltersByType).every((iActiveFilterType) => {
        return activeFiltersByType[iActiveFilterType].some((iFilter) => {
          const transformedFilter = iFilter.trim().toLowerCase();
          return allGoalData.indexOf(transformedFilter) !== -1;
        });
      });
      if (showUser === true) {
        filteredResult.push(iGoal);
      }
      return filteredResult;
    };
    this.filteredData = this.allGoals.reduce(filtering, []);
  }

  // VIEW CONTROLS
  public updateAfterViewChange(viewInfo): void {
    if (check.not.assigned(viewInfo) || check.emptyObject(viewInfo)) {
      return;
    }
    this.filters = viewInfo.filters;
    this.showDepartments =
      check.assigned(this.filters[`type-${this.TYPE_DEPARTMENT}`]) && check.equal(this.filters[`type-${this.TYPE_DEPARTMENT}`], true);
    this.selectedView = viewInfo.viewName;
    this.filterData();
  }

  public createView(): void {
    const viewInfo = {
      viewType: this.preferenceKey,
      filters: this.filters,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateListViewDialog, { data: { viewInfo } });
    dialogRef.afterClosed().subscribe((viewName) => {
      if (check.assigned(viewName)) {
        this.selectedView = viewName;
        const message = this.i18n.misc.viewCreatedMessage;
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });
      }
    });
  }
}
