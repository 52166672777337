import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfoMessageModule } from '@app/common-components/info-message/info-message.module';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { ManageUserPoliciesComponent } from '@app/common-components/manage-user-policies/component/manage-user-policies.component';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';

@NgModule({
  declarations: [ManageUserPoliciesComponent],
  imports: [CommonModule, IconModule, TruncateTextModule, MaterialModule, StandardComponentsModule, InfoMessageModule],
  providers: [],
  exports: [ManageUserPoliciesComponent]
})
export class ManageUserPoliciesModule {}
