import {  Component, EventEmitter, Input, Output } from '@angular/core';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-input-search-shift-plan',
  templateUrl: 'input-search-shift-plan.component.html',
  styleUrls: ['input-search-shift-plan.component.scss']
})
export class InputSearchShiftPlanComponent {
  private _list: Array<IShiftPlanOptions> = [];
  searching: boolean = false;
  searchResults: Array<IShiftPlanOptions> = [];
  private _searchTerm: string = '';
  set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
  }
  get searchTerm(): string {
    return this._searchTerm;
  }

  @Input() placeholder: string = '';
  @Input() sortable: boolean = true;
  @Input()
  set list(list: Array<IShiftPlanOptions>) {
    this.sortList(list);
  }
  get list(): Array<IShiftPlanOptions> {
    return this._list;
  }
  @Output() filteredResults: EventEmitter<Array<IShiftPlanOptions>> = new EventEmitter<Array<IShiftPlanOptions>>();

  public refreshSearchResults(): void {
    const allResults = this.list;
    if (check.not.assigned(this.searchTerm) || check.emptyString(this.searchTerm)) {
      this.searchResults = allResults;
      this.filteredResults.emit(this.searchResults);
      return;
    }
    const filteredResults = allResults.filter((itemList: IShiftPlanOptions) => {
      const regExp = new RegExp(`^.*${this.searchTerm}.*$`, 'i');
      return regExp.test(itemList.name);
    });

    this.searchResults = filteredResults;
    this.filteredResults.emit(this.searchResults);
    return;
  }

  private sortList(list: Array<IShiftPlanOptions>) {
    if (check.not.assigned(list)) {
      this._list = [];
      return;
    }

    if (this.sortable) {
      this._list = list.slice().sort((itemListA: IShiftPlanOptions, itemListB: IShiftPlanOptions) => {
        const nameA = itemListA.name.toLowerCase();
        const nameB = itemListB.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this._list = list.slice();
    }

    //Set the search result in case search term is different than empty
    if (check.assigned(this.searchTerm) && !check.emptyString(this.searchTerm)) {
      this.refreshSearchResults();
    }
  }
}

export interface IShiftPlanOptions {
  _id: string;
  name: string;
}
