import { OverlayConfig } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IUserOptions, InputSimpleSearchUserComponent } from '@app/standard/components/input-simple-search-user/input-simple-search-user.component';
import * as check from 'check-types';

@Component({
  selector: 'orgos-input-payslip-search-user',
  templateUrl: 'input-payslip-search-user.component.html',
  styleUrls: ['input-payslip-search-user.component.scss']
})
export class InputPayslipSearchUserComponent extends InputSimpleSearchUserComponent implements OnInit {
  @Input() pageTranslation: any = {};
  @Output() selectedEmployeeChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('isucInput') searchInput;

  matchingEmployees = [];
  searchMatchingResults = [];
  allEmployees: Array<any> = [];

  @Input()
  set noMatchingUsers(users) {
    this.allEmployees = this.sortEmployees(users);
  }

  @Input()
  set matchingUsers(users) {
    this.matchingEmployees = this.sortEmployees(users);
  }

  private sortEmployees(users) {
    if (check.not.assigned(users)) {
      return [];
    }

    return users.slice().sort((userA, userB) => {
      const nameA = userA.displayName?.toLowerCase();
      const nameB = userB.displayName?.toLowerCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  @Input() editMode: boolean = false;
  @Output() onBlur = new EventEmitter();

  ngOnInit(): void {
    super.ngOnInit();
  }

  setValue(_id: string) {
    if (this.readOnly === true) {
      return;
    }
    super.setValue(_id);
    this.searchTerm = '';
    this.selectedEmployeeChange.emit(_id);
  }

  remove(event) {
    super.setValue(undefined);
    event.stopPropagation();
    this.selectedEmployeeChange.emit(undefined);
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  refreshSearchResults() {
    this.searchResults = this.allEmployees.filter((employee) => {
      return employee?.displayName?.toLowerCase().includes(this.searchTerm.toLocaleLowerCase());
    });
    this.searchMatchingResults = this.matchingEmployees.filter((employee) => {
      return employee?.displayName?.toLowerCase().includes(this.searchTerm.toLocaleLowerCase());
    });
  }

  blurEvent() {
    this.onBlur.emit();
  }

  enableSearching(): void {
    this.searchInput.nativeElement.focus();
    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.minWidth = this.searchInputOrigin.elementRef.nativeElement.getBoundingClientRect().width;
    config.backdropClass = 'cp-event-details-overlay';
    config.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.searchInputOrigin.elementRef)
      .withPositions([
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
        { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' }
      ])
      .withPush(false);
    super.enableSearching(config);
    this.injector.get(ChangeDetectorRef).detectChanges();
  }
}
