import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class GoalService {
  private GOAL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/goal-db`;
  private GOAL_PERMISSIONS_KEY: string = 'goal';
  private GOAL_INTERNATIONALIZATION: string = 'goal-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any): Promise<IGoalModel> {
    return new Promise<IGoalModel>((resolve, reject) => {
      this.genericService
        .create(this.GOAL_URL, data)
        .then((goal: IGoalModel) => {
          resolve(goal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, GoalService.name, 'create'));
        });
    });
  }

  find(findQuery: any): Promise<Array<IGoalModel>> {
    return new Promise<Array<IGoalModel>>((resolve, reject) => {
      this.genericService
        .find(this.GOAL_URL, findQuery)
        .then((goals: Array<IGoalModel>) => {
          resolve(goals);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, GoalService.name, 'find'));
        });
    });
  }

  getById(id: string): Promise<IGoalModel> {
    return new Promise<IGoalModel>((resolve, reject) => {
      this.genericService
        .getById(this.GOAL_URL, id)
        .then((goal: IGoalModel) => {
          resolve(goal);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, GoalService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: IGoalModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.GOAL_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, GoalService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.GOAL_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, GoalService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<any> {
    return this.genericService.getPermissions(this.GOAL_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<any> {
    return this.genericService.getFieldsTranslations(this.GOAL_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.GOAL_URL);
  }

  getAllGoals(): Promise<Array<IGoalModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<IGoalModel>>((resolve, reject) => {
      this.genericService
        .find(this.GOAL_URL, findBody)
        .then((allGoals: Array<IGoalModel>) => {
          resolve(allGoals);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, GoalService.name, 'getAllGoals'));
        });
    });
  }
}

export interface IGoalModel {
  _id?: string;
  title?: string;
  description?: string;
  type?: 'Individual' | 'Department' | 'Brand';
  status?: 'OnTrack' | 'Behind' | 'Achieved' | 'NotAchieved' | 'Cancelled' | 'Deferred' | 'NotStarted';
  keyResults?: Array<{ title: string; isCompleted: boolean }>;
  progress?: number;
  assignedUsers?: Array<string>;
  dueDate?: Date;
  departmentId?: string;
  updates?: Array<any>;
  _createdAt?: Date;
  owners?: Array<any>;
}
