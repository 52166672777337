import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PrivateSecurityService } from '../../private/services/private-security.service';
import { ErrorCodes } from '../core/error/error-codes';
import { OrgosError } from '../core/error/orgos-error';
import { AuthenticationService } from './core/authentication.service';
import { InternationalizationService } from './core/internationalization.service';
import { ErrorManagerService } from './error/error-manager.service';

@Injectable()
export class GenericService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private internationalizationService: InternationalizationService,
    private errorManager: ErrorManagerService,
    private privateSecurityService: PrivateSecurityService
  ) {}

  create(serviceUrl: string, data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(serviceUrl, ErrorCodes.UNAUTHORIZED, GenericService.name, 'create');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post(serviceUrl, data, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getById(serviceUrl: string, id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(serviceUrl, ErrorCodes.UNAUTHORIZED, GenericService.name, 'getById');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const findBody = {
        _id: id
      };
      this.http
        .post<Array<any>>(`${serviceUrl}/find`, findBody, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData[0]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  find(serviceUrl: string, findBody: any): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(serviceUrl, ErrorCodes.UNAUTHORIZED, GenericService.name, 'find');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .post<Array<any>>(`${serviceUrl}/find`, findBody, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateById(serviceUrl: string, idToBeUpdated: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(serviceUrl, ErrorCodes.UNAUTHORIZED, GenericService.name, 'updateById');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      const dataToUpdate = Object.keys(data).reduce((tempData, currentKey) => {
        if (!currentKey.startsWith('s_') && !currentKey.startsWith('_')) {
          tempData[currentKey] = data[currentKey];
        }

        return tempData;
      }, {});

      this.http
        .put(`${serviceUrl}/${idToBeUpdated}`, dataToUpdate, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteById(serviceUrl: string, idToBeDeleted: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(serviceUrl, ErrorCodes.UNAUTHORIZED, GenericService.name, 'deleteById');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .delete(`${serviceUrl}/${idToBeDeleted}`, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPermissions(serviceName: string): Promise<any> {
    return new Promise<object>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(serviceName, ErrorCodes.UNAUTHORIZED, GenericService.name, 'getPermissions');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      this.privateSecurityService
        .getAllPermissions()
        .then((allPermissions) => {
          resolve(allPermissions[serviceName]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getFieldsTranslations(internationalizationResource: string): Promise<any> {
    return this.internationalizationService.getAllTranslation(internationalizationResource);
  }

  getModel(serviceUrl: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        const error = new OrgosError(undefined, ErrorCodes.UNAUTHORIZED, GenericService.name, 'getModel');
        reject(this.errorManager.handleRawError(error));
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.http
        .get(`${serviceUrl}/model`, httpOptions)
        .toPromise()
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, GenericService.name, 'getModel'));
        });
    });
  }
}

export interface IGenericService {
  create(data: any, operationOptions?: any): Promise<any>;
  getById(id: string, operationOptions?: any): Promise<any>;
  updateById(id: string, data: any, operationOptions?: any): Promise<void>;
  deleteById(id: string, operationOptions?: any): Promise<void>;
  getPermissions(operationOptions?: any): Promise<any>;
  getFieldsTranslations(operationOptions?: any): Promise<any>;
  getData?(operationOptions?: any): Promise<any>;
}
