<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogTitle" (clickCloseButton)="closeDialog()">
  <orgos-column-container centerColumns="true" class="kenjo-mb-10px">
    <orgos-column size="0">
      <kenjo-icon [size]="22" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
    </orgos-column>
    <orgos-column size="1" class="kenjo-ml-10px">
      <orgos-text type="BodyText" color="LightText">{{dialogTranslation.message}}</orgos-text>
    </orgos-column>
  </orgos-column-container>

  <orgos-input-simple-email [value]="currentEmail" [readOnly]="true" [label]="dialogTranslation.currentEmail" class="kenjo-block kenjo-pt-20px" size="medium"> </orgos-input-simple-email>

  <orgos-input-simple-email [(value)]="workEmail" [label]="dialogTranslation.newEmail" class="kenjo-block kenjo-pt-20px" size="medium" [required]="true" (validation)="workEmailValidation = $event">
    <orgos-input-error *ngIf="workEmailValidation && workEmailValidation.getError('required')"> {{dialogTranslation.emailRequired}}</orgos-input-error>
  </orgos-input-simple-email>

  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{miscTranslation.goBackButtonDialog}} </orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised (click)="save()" color="Success" [disabled]="!workEmailValidation || workEmailValidation.hasErrors()">
        {{dialogTranslation.saveButton}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
