import { Injectable } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ShiftplanWishDayService {
  private MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/shift-plan-wish-day-db`;
  private SHIFTPLAN_WISH_DAY_SERVICE: string = 'ShiftplanWishDayService';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  async getWishDays(findQuery: { startDate: Date; endDate: Date; userIds: Array<string> }): Promise<Array<IShiftplanWishDay>> {
    try {
      if (!findQuery) {
        throw new OrgosError(this.MICROSERVICE_URL, ErrorCodes.BAD_REQUEST, this.SHIFTPLAN_WISH_DAY_SERVICE, 'getWishDays');
      }

      return await this.genericService.find(this.MICROSERVICE_URL, findQuery);
    } catch (error) {
      throw this.errorManager.handleRawError(error, this.SHIFTPLAN_WISH_DAY_SERVICE, 'getWishDays');
    }
  }
}

export type ShiftPlanWishDayType = 'Availability' | 'Unavailability';

export interface IShiftplanWishDay {
  _id?: string;
  startDate: Date;
  endDate: Date;
  userId: string;
  type: ShiftPlanWishDayType;
  frequency?: {
    value: number;
    range: 'week' | 'month';
  };
  entries?: Array<{ startTime: number; endTime: number }>;
  days?: IShiftplanDays;
}

export interface IShiftplanDays {
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}
