import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[kenjo-alphanumeric-input]'
})
export class AlphaNumericInputDirective {
  @Input('kenjo-alphanumeric-input') enableAlphaNumericFilter: boolean = false;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.enableAlphaNumericFilter) {
      const allowedKeys = /[a-zA-Z0-9 áéíóúÁÉÍÓÚüÜñÑ]|\Backspace|\Tab|\Escape|\Enter|ArrowLeft|ArrowRight|ArrowUp|ArrowDown/;
      const key = event.key;
      if (!allowedKeys.test(key) && !event.ctrlKey && !event.metaKey) {
        event.preventDefault();
      }
    }
  }
}

