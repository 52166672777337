import { Component, Injector } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { IUsersMap } from '@app/common-components/layout-import-payslips/services/payslips-import.service';
import { SendSignatureConfirmDialogComponent } from '@app/common-components/send-signature-confirm-dialog/send-signature-confirm-dialog.component';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { SendReminderDialog } from '@app/standard/pages/documents/dialogs/send-reminder.dialog';
import { IUploadDocumentConfig, UploadDocumentDialog } from '@app/standard/pages/documents/dialogs/upload-document.dialog';
import { VoidDocumentDialog } from '@app/standard/pages/documents/dialogs/void-document.dialog';
import { GenericFilterPage } from '@app/standard/pages/generic-filter.page';
import { IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { IDocumentModel } from '@app/standard/services/document/document.service';
import { FilterBarController } from '@app/standard/services/filter-bar/filter-bar.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import {
  ISignature,
  SIGNATURE_STATUS,
  SIGNATURE_STATUS_MAP,
  SignatureRequestService,
} from '@app/standard/services/signature-request/signature-request.service';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-documents-digital-signature',
  templateUrl: './documents-digital-signature.page.html',
  styleUrls: ['./documents-digital-signature.page.scss'],
})
export class DocumentsDigitalSignaturePage extends GenericFilterPage {
  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'documents-misc' },
    { name: 'page', translationKey: 'documents-digital-signature-page' },
    { name: 'importPage', translationKey: 'documents-import-page' },
  ];

  protected fieldToQueryOptionMap = {
    tags: 'tags',
    recipients: 'recipients',
    authors: 'authors',
    statuses: 'statuses',
  };

  sortFilters: any;
  PREFERENCE_OPTION_KEY: string = 'digital-signatures-table';
  DEFAULT_SORT_BY: string = 'sent-on';
  DEFAULT_SORT_ORDER: string = 'asc';

  statusInfo = SIGNATURE_STATUS_MAP;
  signatureDocuments: Array<ISignature>;
  filtersOpened: boolean = false;
  totalColumns: Array<string> = ['select', 'document', 'recipient', 'recipient-email', 'author', 'sent-on', 'tags', 'status'];
  selectedSignatures: Array<ISignature> = [];
  signaturesSelectedText: string;
  bulkActionsAllowed: boolean = false;
  allSelectedAreInReady: boolean = true;
  allSelectedAreImportError: boolean = true;
  someSelectedAreImportError: boolean = true;

  originalAvailable: number;
  creditsAvailable: number;

  mapDocById: object = {};

  constructor(protected injector: Injector, protected router: Router) {
    super(injector, router);
  }

  protected async getGlobalBarOptions(): Promise<Array<IMenuOption>> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;
    const options: Array<IMenuOption> = [
      { name: this.i18n.misc.myDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/my-docs') },
      { name: this.i18n.misc.companyDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/company-docs') },
    ];

    if (this.injector.get(CloudRoutesService).checkRoute('documents/employee-docs') === true) {
      options.push({ name: this.i18n.misc.employeeDocsTab, onClick: () => this.router.navigateByUrl('/cloud/documents/employee-docs') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/digital-signature') === true) {
      options.push({
        name: this.i18n.misc.digitalSignatureTab,
        onClick: () => this.router.navigateByUrl('/cloud/documents/digital-signature'),
      });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/templates') === true) {
      options.push({ name: this.i18n.misc.templatesTab, onClick: () => this.router.navigateByUrl('/cloud/documents/templates') });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('documents/import') === true) {
      options.push({ name: this.i18n.misc.importTab, onClick: () => this.router.navigateByUrl('/cloud/documents/import') });
    }

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 3;

    return options;
  }

  protected async fetchData(): Promise<void> {
    const { total, used } = await this.injector.get(SignatureRequestService).getSignatureRequestCount();
    this.originalAvailable = total - used < 0 ? 0 : total * 1 - used * 1;
    const result: { records: Array<ISignature>; total: number } = await this.injector
      .get(SignatureRequestService)
      .getEmployeeSignatureRequests(this.queryOptions);
    this.signatureDocuments = this.processFinalResult(result.records);
    this.mapDocById = _.keyBy(result.records, '_id');

    this.dataLoaded = true;
  }

  protected async getPreferences(): Promise<void> {
    this.preferences = await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_OPTION_KEY);
  }

  async setPreferences(): Promise<void> {
    await this.injector.get(PreferenceService).setPreferenceByKey(this.PREFERENCE_OPTION_KEY, this.queryOptions);
  }

  protected async getFilters(): Promise<void> {
    const pageFilters = await this.injector.get(FilterBarController).getDigitalSignaturesFilters();
    this.filters = pageFilters.primary;
  }

  protected afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view smart docs page', { category: 'Navigation', type: 'digital signature' });
    return Promise.resolve();
  }

  // abstract filter methods
  public clearCustomFilters(): void {
    this.queryOptions.where = {};
  }

  public clearFilter(field: string): Promise<void> {
    this.resetView();

    const fieldName = this.fieldToQueryOptionMap[field];

    if (check.assigned(this.queryOptions.where?.[fieldName])) {
      delete this.queryOptions.where[fieldName];
    }

    this.refreshAfterResettingPagination();

    return;
  }

  public addFilter(value: string, field: string): void {
    if (check.not.assigned(value) || check.not.assigned(field)) {
      return;
    }

    if (check.not.assigned(this.queryOptions.where)) {
      this.queryOptions = {
        where: {},
      };
    }

    if (check.not.assigned(this.queryOptions.where[field])) {
      this.queryOptions.where[field] = {
        $in: [],
      };
    }

    this.queryOptions.where[field].$in.push(value);
  }

  protected removeFilter(value: string, field: string): void {
    if (check.not.assigned(this.queryOptions.where?.[field]?.$in) || check.emptyArray(this.queryOptions.where?.[field]?.$in)) {
      return;
    }

    if (this.queryOptions.where[field]?.$in?.length > 1) {
      this.queryOptions.where[field].$in = this.queryOptions.where[field].$in.filter((fieldValue) => fieldValue !== value);
    } else if (this.queryOptions.where[field]?.$in?.length === 1) {
      delete this.queryOptions.where[field];
    }
  }

  // abstract preferences and init methods
  protected getCurrentQueryValues(): { tags: Array<string>; authors: Array<string>; recipients: Array<string>; statuses: Array<string> } {
    const selectedValues = { tags: [], authors: [], recipients: [], statuses: [] };

    selectedValues.tags = this.queryOptions.where?.tags?.$in ?? [];
    selectedValues.authors = this.queryOptions.where?.authors?.$in ?? [];
    selectedValues.recipients = this.queryOptions.where?.recipients?.$in ?? [];
    selectedValues.statuses = this.queryOptions.where?.statuses?.$in ?? [];

    return selectedValues;
  }

  /*
   * Child components MUST override setDefaultValues in order to set default values previous to data retrieve.
   */
  protected setDefaultValues(): void {
    if (check.assigned(this.preferences?.preference)) {
      this.queryOptions = { ...this.preferences.preference };

      this.queryOptions.page = this.queryOptions.page ?? this.DEFAULT_PAGE;
      this.queryOptions.recordsPerPage = this.queryOptions.recordsPerPage ?? this.DEFAULT_RECORDS_PER_PAGE;
      return;
    }

    this.queryOptions = {
      sortBy: this.DEFAULT_SORT_BY,
      sortOrder: this.DEFAULT_SORT_ORDER,
    };
  }

  protected fetchListData(): Promise<void> {
    return null;
  }

  protected fetchGridData(): Promise<void> {
    return null;
  }

  private processFinalResult(signatures: Array<ISignature>): Array<ISignature> {
    this.setPreferences();

    // make records no selectable
    signatures.forEach((iDocument: ISignature) => {
      iDocument.excluded = iDocument.status === 'completed' || iDocument.status === 'canceled' || iDocument.status === 'declined';
    });

    // process tags
    return signatures.map((iSignature) => {
      if (check.not.assigned(iSignature.document.collapsedTags) || check.emptyArray(iSignature.document.collapsedTags)) {
        return iSignature;
      }

      iSignature.document.processedTooltip = iSignature.document.collapsedTags.map((iTag) => iTag.name);
      return iSignature;
    });
  }

  public openDocumentDetail(documentId: string): void {
    this.router.navigateByUrl(`cloud/documents/${documentId}`);
  }

  public openEmployeeDetail(userId: string): void {
    this.router.navigateByUrl(`cloud/people/${userId}`);
  }

  public viewDocument(signatureDocument: ISignature) {
    const { document } = signatureDocument;
    if (check.not.assigned(document)) {
      return;
    }
    this.router.navigateByUrl(`cloud/documents/${document._id}`);
  }

  public async sendSignatureAgain(documents: Array<ISignature>) {
    if (check.not.nonEmptyArray(documents)) {
      return;
    }

    const docIds = [];
    const uniqueDocs = documents.reduce((total, iDoc) => {
      if (!docIds.includes(iDoc._id.toString())) {
        total.push(iDoc);
        docIds.push(iDoc._id.toString());
      }
      return total;
    }, []);
    const users = [];
    const usersMap: IUsersMap = {};
    const uniqueEmployeeNumber = uniqueDocs.reduce((employeeNumber, iDoc) => {
      if (!users.includes(iDoc.signer._id.toString())) {
        usersMap[iDoc.signer._id.toString()] = iDoc.signer;
        users.push(iDoc.signer._id.toString());
        return employeeNumber + 1;
      }
      return employeeNumber;
    }, 0);
    const data = {
      translations: this.i18n.importPage.layoutImportPayslips.summary,
      summaryData: {
        documents: uniqueDocs.length,
        employees: uniqueEmployeeNumber,
        signatures: uniqueDocs.length,
        currentCredits: this.originalAvailable,
        creditsToBeUsed: uniqueDocs.length,
        showSignatures: true,
      },
      usersMap: usersMap,
      shouldImportPayslips: false,
      signatureRequests: uniqueDocs.map((iDoc) => iDoc.document),
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(SendSignatureConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((value) => {
      if (check.assigned(value) && value !== false) {
        this.refreshData();
        this.selectedSignatures = [];

        this.injector
          .get(MatLegacySnackBar)
          .open(this.i18n.importPage.layoutImportPayslips.payslipsImportedSnackBar, 'OK', { duration: 5000 });
      }
    });
  }

  public sendReminders(documents: Array<ISignature>) {
    if (check.not.nonEmptyArray(documents)) {
      return;
    }
    const data = { documents };
    this.injector.get(MatLegacyDialog).open(SendReminderDialog, { data });
  }

  public voidDocuments(documents: Array<ISignature>) {
    if (check.not.nonEmptyArray(documents)) {
      return;
    }

    const selectedRequestsMap = {};
    documents.forEach((selectedDoc) => {
      const { requestId, _id } = selectedDoc;
      selectedRequestsMap[requestId] ? selectedRequestsMap[requestId].push(_id) : (selectedRequestsMap[requestId] = [_id]);
    });

    // Checking for other documents in the same Request that should be deleted.
    const conflictiveDocsMap = [];
    this.signatureDocuments.forEach((doc) => {
      if (selectedRequestsMap[doc.requestId] && !selectedRequestsMap[doc.requestId].includes(doc._id)) {
        conflictiveDocsMap.push(doc._id);
      }
    });

    const data = { documents, documentsFromSameRequest: conflictiveDocsMap.length };
    const dialogRef = this.injector.get(MatLegacyDialog).open(VoidDocumentDialog, { data });

    dialogRef.afterClosed().subscribe((voided: boolean) => {
      if (voided) {
        this.refreshData();
        this.selectedSignatures = [];
      }
    });
  }

  public uploadDocument() {
    const uploadConfig: IUploadDocumentConfig = {
      relatedTo: {
        typeRelatedTo: 'Company',
      },
    };

    this.injector
      .get(UppyHelperService)
      .uploadGenericDocument()
      .then((rawDocument: any | null) => {
        if (check.not.assigned(rawDocument)) {
          return Promise.resolve();
        }

        const dialogData = {
          rawDocument,
          uploadConfig,
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(UploadDocumentDialog, { data: dialogData });
        dialogRef.afterClosed().subscribe((uploadedDocument: IDocumentModel) => {
          if (check.not.assigned(uploadedDocument)) {
            return;
          }

          this.router.navigateByUrl(`cloud/documents/company-docs`);
        });
      });
  }

  public selectListItem(selectedSignatures: Array<ISignature>) {
    this.selectedSignatures = selectedSignatures;
    const data = {
      numberSelected: this.selectedSignatures?.length ?? 0,
    };
    const { signaturesSelectedText, signaturesSelectedText_singular } = this.i18n.page;
    const selectedTemplate = data.numberSelected > 1 ? signaturesSelectedText : signaturesSelectedText_singular;
    this.signaturesSelectedText = this.injector.get(I18nDataPipe).transform(selectedTemplate, data);
    this.allSelectedAreInReady = this.selectedSignatures.every((iRequest) => iRequest.status === SIGNATURE_STATUS.READY);
    this.someSelectedAreImportError = false;
    this.allSelectedAreImportError = this.selectedSignatures.every((iRequest) => {
      this.someSelectedAreImportError = this.someSelectedAreImportError || iRequest.status === SIGNATURE_STATUS.IMPORT_ERROR;
      return iRequest.status === SIGNATURE_STATUS.IMPORT_ERROR;
    });
    this.creditsAvailable = this.originalAvailable - data.numberSelected;
  }

  sortingDataAccessor: (row: any, sortHeaderId: string) => string | number = (row: any, sortHeaderId: string): string | number => {
    if (sortHeaderId === 'recipient') {
      return row.signer.displayName.toLowerCase();
    }

    if (sortHeaderId === 'document') {
      return `${this.mapDocById[row._id].document.name.replace(/\s/g, '').toLowerCase()}`;
    }

    if (sortHeaderId === 'sent-on') {
      return `${moment.utc(row._createdAt).format('YYYYMMDDHHmm')}`;
    }
  };
}
