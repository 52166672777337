import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as picklistColorConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';

import { ISelectOption } from '../../core/select-option';
import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';
import { PicklistsService } from '../../services/core/picklists.service';

@Component({
  selector: 'orgos-input-simple-select',
  templateUrl: 'input-simple-select.component.html',
  styleUrls: ['input-simple-select.component.scss']
})
export class InputSimpleSelectComponent implements OnInit {
  isValueValid: boolean = true;
  showClearButton: boolean = false;

  private _autoOrder: boolean = true;
  @Input()
  set autoOrder(autoOrder: boolean) {
    this._autoOrder = autoOrder;
    this.applySort();
  }
  get autoOrder(): boolean {
    return this._autoOrder;
  }

  @Input() multiSelect: boolean = false;

  private _originalOptions: Array<ISelectOption> = [];
  private _options: Array<ISelectOption> = [];
  @Input()
  set options(options: Array<ISelectOption>) {
    if (check.not.assigned(options)) {
      this._originalOptions = [];
      this.applySort();
      return;
    }

    this._originalOptions = options;
    this.applySort();
  }
  get options(): Array<ISelectOption> {
    return this._options;
  }

  @Input()
  set picklist(picklistName: string) {
    this.picklistService
      .getPicklist(picklistName)
      .then((picklist: object) => {
        this.options = Object.keys(picklist).reduce((tempOptions: Array<ISelectOption>, value: string) => {
          const picklistOption: ISelectOption = {
            name: picklist[value],
            value: value
          };

          tempOptions.push(picklistOption);
          return tempOptions;
        }, []);
      })
      .catch(() => {
        this.options = [];
      });
  }

  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() enableClearButton: boolean = false;
  @Input() value: any;
  @Input() optionsDisabled: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Input() appearance: string = 'standard';
  @Input() panelClass: string = '';

  constructor(private picklistService: PicklistsService) {}

  ngOnInit(): void {
    this.makeValidation(this.value);
    this.showClearButton = this.enableClearButton && check.assigned(this.value);
  }

  getValueName(): string {
    if (check.not.assigned(this.value)) {
      return '';
    }

    if (this.multiSelect) {
      const options = this.value.map((iValue) => {
        return this.options.find((iOption: ISelectOption) => iOption.value === iValue).name;
      });
      return check.assigned(options) ? options.join(', ') : '';
    }

    const option = this.options.find((iOption: ISelectOption) => {
      return iOption.value === this.value;
    });

    return check.assigned(option) ? option.name : '';
  }

  onValueChange(newValue: any): void {
    this.value = newValue;
    this.showClearButton = this.enableClearButton && check.assigned(newValue);

    this.makeValidation(newValue);

    if (this.isValueValid) {
      this.valueChange.emit(newValue);
    }
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();

    if (this.required && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (check.assigned(this.customValidation)) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  clearValue(): void {
    this.onValueChange(null);
  }

  private applySort(): void {
    if (check.not.assigned(this.autoOrder) || this.autoOrder === false) {
      this._options = this._originalOptions.slice();
      return;
    }

    this._options = this._originalOptions.slice().sort((optionA: ISelectOption, optionB: ISelectOption) => {
      const nameA = check.assigned(optionA.name) ? optionA.name.toLowerCase() : '';
      const nameB = check.assigned(optionB.name) ? optionB.name.toLowerCase() : '';

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
