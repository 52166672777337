import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PerformanceReviewToDoCardComponent } from '@app/cloud-features/performance-review/components/performance-review-to-do-card/performance-review-to-do-card.component';
import { PerformanceReviewToDosComponent } from '@app/cloud-features/performance-review/components/performance-review-to-dos/performance-review-to-dos.component';
import { NominatePeersDialog } from '@app/cloud-features/performance-review/dialogs/nominate-peers/nominate-peers-dialog';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { MaterialModule } from '@app/material.module';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';

const COMPONENTS = [PerformanceReviewToDoCardComponent, PerformanceReviewToDosComponent];
const DIALOGS = [NominatePeersDialog];
const MODULES = [CommonModule, FormsModule, MaterialModule, StandardComponentsModule, IconModule];

@NgModule({
  declarations: [...COMPONENTS, ...DIALOGS],
  imports: [...MODULES],
  providers: [],
  exports: [...COMPONENTS, ...DIALOGS]
})
export class PerformanceReviewSharedModule {}
