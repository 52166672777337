<div class="cm">
  <div class="cm-month-year-title">{{ startDate | date: 'MMMM' | titlecase }}</div>

  <div class="cm-weekdays-container">
    <div class="day-title" *ngFor="let dayOfWeek of daysOfWeek">{{ dayOfWeek | date: 'EEEEEE' | titlecase }}</div>
  </div>

  <div class="cm-dates-container">
    <div *ngFor="let gridItem of gridItemsForThisMonth; let isFirst = first;"
    class="grid-item"
    [ngClass]="{
      '--first-day': isFirst,
      '--holiday': gridItem?.calendarEvent?.eventName,
      '--editable': gridItem?.calendarEvent?.isEditable === true,
      '--half-day': (gridItem?.calendarEvent?.eventDuration === 'Morning' || gridItem?.calendarEvent?.eventDuration === 'Afternoon'),
      '--morning': gridItem?.calendarEvent?.eventDuration === 'Morning',
      '--afternoon': gridItem?.calendarEvent?.eventDuration === 'Afternoon',
      '--today': gridItem?.isToday
    }"
    [matTooltip]="gridItem?.calendarEvent?.eventName"
    [style.background-color]="gridItem?.calendarEvent?.backgroundColor"
    [style.color]="gridItem?.calendarEvent?.textColor"
    (click)="dateClick(gridItem)">
      {{ gridItem.day }}
    </div>
  </div>
</div>