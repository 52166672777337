import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import {
  IShiftPlanBreaktimeDeductionModel,
  ShiftPlanBreaktimeDeductionService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-breaktime-deduction.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { ConfirmListDialogComponent } from '@app/standard/components/confirm-list-dialog/confirm-list-dialog.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  selector: 'kenjo-settings-shift-plan-breaks',
  templateUrl: 'settings-shift-plan-breaks.html',
  styleUrls: ['settings-shift-plan-breaks.scss'],
})
export class SettingsShiftPlanBreaks implements OnInit {
  i18n: any = {};
  loading: boolean = true;
  breaktimeDeduction: IShiftPlanBreaktimeDeductionModel;
  isBreaktimeDeducted: boolean = false;
  breaktimePermissions: { [key: string]: boolean };

  @Input() parentTranslation: any = {};
  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.loading = true;
    this.fetchData();
    this.fetchTranslations();
    this.loading = false;
  }

  private async fetchData() {
    try {
      this.breaktimeDeduction = await this.injector.get(ShiftPlanBreaktimeDeductionService).getBreaktimeDeduction();
      this.isBreaktimeDeducted = this.breaktimeDeduction.breaktimeDeduction;
      this.breaktimePermissions = await this.injector
        .get(ShiftPlanPermissionsService)
        .getShiftplPermissionsByCollection('shift-plan-breaktime-deduction');
    } catch {
      this.breaktimeDeduction = null;
      this.isBreaktimeDeducted = false;
    }
  }

  private async fetchTranslations(): Promise<void> {
    try {
      this.i18n.page = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-rules');
    } catch {
      this.i18n.page = {};
    }
  }

  public async deductBreakDuration(event: any) {
    const dialogData: any = {
      titleText: this.i18n.page.breaksPopUpTitle,
      list: [
        this.i18n.page.breaksPopUpListElement1,
        this.i18n.page.breaksPopUpListElement2,
        this.i18n.page.breaksPopUpListElement3,
        this.i18n.page.breaksPopUpListElement4,
      ],
      confirmButtonText: this.i18n.page.proceed,
      confirmButtonColor: 'Success',
      cancelButtonText: this.i18n.page.cancelBreakUpdate,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmListDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe((confirmClose: boolean) => {
      if (confirmClose) {
        this.saveBreakChanges(event.checked);
      } else {
        event.source.checked = !event.checked;
      }
    });
  }

  private async saveBreakChanges(checked: boolean) {
    try {
      await this.injector
        .get(ShiftPlanBreaktimeDeductionService)
        .updateBreaktimeDeduction(this.breaktimeDeduction._id, { breaktimeDeduction: checked });
      this.showSnackbar();
    } catch {
      //Do nothing
    }
  }

  public showSnackbar(): void {
    const snackBarMessage = this.i18n.page.breakRulesModal;
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }
}
