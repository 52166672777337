import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { KioskService } from '@app/standard/services/attendance/kiosk.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  template: `
    <orgos-dialog-container [title]="pageTranslations.pinCode" (clickCloseButton)="closeDialog()" class="ep-dialog-container">
      <div class="ep-container">
        <div class="ep-pincode-container">
          <div class="kenjo-font-color-light-text-757575 kenjo-mb-5px">{{ pageTranslations.pinCode }}</div>
          <div class="ep-pincode-form">
            <div class="ep-pincode-row" *ngIf="hidePinCode === false && !refreshing">
              <div
                *ngFor="let digit of (userPinCode$ | async)?.split(''); let index = index"
                class="ep-pincode-digit"
                [style]="'animation-delay: ' + index * 40 + 'ms'"
              >
                {{ digit }}
              </div>
            </div>
            <div class="ep-pincode-row" *ngIf="hidePinCode === true || refreshing">
              <div
                *ngFor="let digit of (userPinCode$ | async)?.split(''); let index = index"
                class="ep-pincode-digit"
                [style]="'animation-delay: ' + index * 40 + 'ms'"
              >
                *
              </div>
            </div>
            <kenjo-icon class="kenjo-cursor-pointer" (click)="hidePinCode = !hidePinCode; $event.stopPropagation()">{{
              hidePinCode ? 'visibility' : 'visibility_off'
            }}</kenjo-icon>
          </div>
        </div>
        <orgos-action *ngIf="!refreshing && !refreshed" class="kenjo-ml-50px" icon="sync" (click)="refreshPinCode()">{{
          pageTranslations.refreshPinCode
        }}</orgos-action>
        <orgos-action
          *ngIf="!refreshing && refreshed"
          class="kenjo-ml-50px ep-pincode-refreshed-action"
          icon="check_circle"
          [disabled]="true"
          >{{ pageTranslations.pinCodeRefreshed }}</orgos-action
        >
        <orgos-loading-spinner class="kenjo-ml-50px" *ngIf="refreshing"></orgos-loading-spinner>
        <div class="kenjo-ml-5px" *ngIf="refreshing">{{ miscTranslations.loading?.toUpperCase() }}</div>
      </div>
      <div class="ep-button-row">
        <orgos-button-raised color="Neutral" (click)="closeDialog()">
          {{ miscTranslations.goBackButtonDialog }}
        </orgos-button-raised>
      </div>
    </orgos-dialog-container>
  `,
  styleUrls: ['./edit-pincode.dialog.scss'],
})
export class EditPinCodeDialog implements OnInit {
  userId: string;
  userPinCode$ = this.injector.get(KioskService).userPinCode$;
  hidePinCode: boolean = true;
  refreshing: boolean = false;
  refreshed: boolean = false;
  pageTranslations: Record<string, string> = {};
  miscTranslations: Record<string, string> = {};

  constructor(
    public dialogRef: MatLegacyDialogRef<EditPinCodeDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: { userId: string },
    private injector: Injector
  ) {
    this.userId = this.data.userId;
  }

  ngOnInit(): void {
    this.fetchTranslations();
    this.userId = this.data.userId;
  }

  async refreshPinCode() {
    this.refreshing = true;
    const error = await this.injector.get(KioskService).refreshPinCode(this.userId);
    if (!error) {
      this.refreshed = true;
    }
    this.refreshing = false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private async fetchTranslations(): Promise<void> {
    this.pageTranslations = await this.injector.get(InternationalizationService).getAllTranslation('people-detail-personal-page');
    this.miscTranslations = await this.injector.get(InternationalizationService).getMiscTranslation();
  }
}
