import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class TeamService implements IGenericService {
  private TEAM_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/team-db`;
  private TEAM_PERMISSIONS_KEY: string = 'team';
  private TEAM_INTERNATIONALIZATION: string = 'team-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<ITeamModel> {
    return new Promise<ITeamModel>((resolve, reject) => {
      this.genericService
        .create(this.TEAM_URL, data)
        .then((team: ITeamModel) => {
          resolve(team);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TeamService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<ITeamModel> {
    return new Promise<ITeamModel>((resolve, reject) => {
      this.genericService
        .getById(this.TEAM_URL, id)
        .then((team: ITeamModel) => {
          resolve(team);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TeamService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.TEAM_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TeamService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.TEAM_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TeamService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.TEAM_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.TEAM_INTERNATIONALIZATION);
  }

  getData(
    findBody: any = {
      _id: { $ne: null }
    }
  ) {
    return new Promise<Array<ITeamModel>>((resolve, reject) => {
      this.genericService
        .find(this.TEAM_URL, findBody)
        .then((teams: Array<ITeamModel>) => {
          resolve(teams);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, TeamService.name, 'getTeams'));
        });
    });
  }

  getTeams(data?: Array<string>): Promise<Array<ITeamModel>> {
    const findBody = {
      _id: data !== undefined && data.length > 0 ? data : { $ne: null }
    };
    return this.getData(findBody);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.TEAM_URL);
  }
}

export interface ITeamModel {
  _id: string;
  name: string;
}

export interface IVirtualTeam {
  _id?: string;
  name?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  calendarId?: string;
  motherTeamId?: string;
}
