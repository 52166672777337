import { ElementRef } from '@angular/core';
import {
  IShiftPlanDate,
} from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import * as check from 'check-types';
import * as moment from 'moment';

const TIME_CONSTANTS = {
  DAYS_IN_A_WEEK: 7,
};

export const setWeekDaysLabelPure = (weekDayLabels: Array<string>, currentLocale: string): Array<string> => {
  const americaLocales = ['es-MX', 'en-US'];
  const europeLocales = ['es', 'de-AT', 'de', 'en-GB'];

  if (!americaLocales.includes(currentLocale) && !europeLocales.includes(currentLocale)) {
    return [];
  }

  if (!americaLocales.includes(currentLocale)) {
    return weekDayLabels;
  }

  const mondayLabel = weekDayLabels[0];
  const sundayLabel = weekDayLabels[6];
  const restOfDayLabels = weekDayLabels.slice(1, 6);

  return [sundayLabel, mondayLabel, ...restOfDayLabels];
};

export const getDaysOfCurrentWeek = (
  startDayOfWeek: moment.Moment
): {
  daysOfCurrentWeek: Array<moment.Moment>;
  labelsOfCurrentWeek: Array<string>;
  selectedDayIndex: number;
} => {
  const today = moment.utc().startOf('day');
  let selectedDayIndex = today.isSame(startDayOfWeek, 'day') ? 0 : -1;

  const firstDayLabel = startDayOfWeek.utc().format('ddd, D MMM').replace(/\./g, '');
  const daysOfCurrentWeek = [startDayOfWeek];
  const labelsOfCurrentWeek = [firstDayLabel];
  for (let i = 1; i < TIME_CONSTANTS.DAYS_IN_A_WEEK; i++) {
    const newDay = moment.utc(startDayOfWeek).add(i, 'day');
    const labelDay = newDay.utc().format('ddd, D MMM').replace(/\./g, '');
    daysOfCurrentWeek.push(newDay);
    labelsOfCurrentWeek.push(labelDay);

    if (today.isSame(newDay, 'day')) {
      selectedDayIndex = i;
    }
  }
  return { daysOfCurrentWeek, labelsOfCurrentWeek, selectedDayIndex };
};

export const getDaysOfCurrentMonth = (
  startDayOfMoth: moment.Moment
): {
  daysOfThisMonth: Array<moment.Moment>;
  labelsOfThistMonth: Array<string>;
  selectedDayIndex: number;
} => {
  const today = moment.utc().startOf('day');
  const daysOfThisMonth = [];
  const labelsOfThistMonth = [];
  let selectedDayIndex = today.isSame(startDayOfMoth, 'day') ? 0 : -1;

  const daysInMonth = startDayOfMoth.daysInMonth();
  for (let i = 0; i < daysInMonth; i++) {
    const monthDay = moment.utc(startDayOfMoth).add(i, 'day');
    const labelDay = monthDay.utc().format('dd').replace(/\./g, '');

    daysOfThisMonth.push(monthDay);
    labelsOfThistMonth.push(labelDay);

    if (today.isSame(monthDay, 'day')) {
      selectedDayIndex = i;
    }
  }
  return { daysOfThisMonth, labelsOfThistMonth, selectedDayIndex };
};

export const getWeekForCreate = (
  params
): {
  daysOfCurrentWeek: Array<moment.Moment>;
  dayIndex: number;
  selectedDate: moment.Moment;
  range: IShiftPlanDate;
} => {
  const { selectedDayIndex, dateRange, locale, isMonthly } = params;
  const today = moment.utc().startOf('day');
  const isTodayInRange = today.isBetween(dateRange.from, dateRange.to, 'day', '[]');

  //Calculate date that will contain the selected week
  const selectedDate = isMonthly
    ? setSelectedDayMonth({ ...params, today, isTodayInRange })
    : setSelectedDayWeek({ ...params, today, isTodayInRange });
  const startWeekSelected = moment.utc(selectedDate).locale(locale).startOf('week');

  //Find week and selected day index
  const { daysOfCurrentWeek } = getDaysOfCurrentWeek(startWeekSelected);
  const dayIndex = isMonthly
    ? findIndexDayMonth({ daysOfCurrentWeek, selectedDate })
    : findIndexDayWeek({
        selectedDayIndex,
        daysOfCurrentWeek,
        isTodayInRange,
        today,
        selectedDate,
      });

  //Set range from, to
  const range = {
    from: daysOfCurrentWeek[0],
    to: daysOfCurrentWeek[TIME_CONSTANTS.DAYS_IN_A_WEEK - 1].endOf('day'),
  };
  return { daysOfCurrentWeek, dayIndex, selectedDate, range };
};

export const setSelectedDayMonth = ({ selectedDayIndex, currentDays, isTodayInRange, today }) => {
  return check.assigned(selectedDayIndex) ? currentDays[selectedDayIndex] : isTodayInRange ? today : currentDays[0];
};

export const setSelectedDayWeek = ({ selectedDayIndex, currentDays, isTodayInRange, today }) => {
  return isTodayInRange ? today : currentDays[selectedDayIndex ?? 0];
};

export const findIndexDayMonth = ({ daysOfCurrentWeek, selectedDate }) => {
  return daysOfCurrentWeek.findIndex((date: moment.Moment) => moment.utc(date).isSame(selectedDate, 'day'));
};

export const findIndexDayWeek = ({ selectedDayIndex, daysOfCurrentWeek, isTodayInRange, today, selectedDate }) => {
  return (
    selectedDayIndex ??
    daysOfCurrentWeek.findIndex((date: moment.Moment) => moment.utc(date).isSame(isTodayInRange ? today : selectedDate, 'day'))
  );
};

export const navigateToDate = (dateRange: IShiftPlanDate, range: 'week' | 'month', value: number) => {
  const { from, to } = dateRange;
  return {
    from: moment.utc(from).clone().add(value, range),
    to: moment.utc(to).clone().add(value, range),
  };
};

export const scrollToDay = ({ day }: { day: moment.Moment }, container: ElementRef) => {
  const tabWidth = 100;
  const dayIndex = day.clone().date() - 1;
  container.nativeElement.scroll({ left: tabWidth * dayIndex, behavior: 'smooth' });
};

export const setInitialWeekRange = (startDate: moment.Moment): IShiftPlanDate => {
  const today = moment.utc();
  const sameMonth = today.isSame(startDate, 'month');
  const firstDay = sameMonth ? today : startDate;
  const from = moment.utc(firstDay).startOf('week');
  const to = moment.utc(firstDay).endOf('week').endOf('day');

  return { from, to };
};