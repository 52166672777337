<orgos-container *ngIf="!loadingPage && !seeDetailsUserId" aspect="card">
  <orgos-column-container
    centerColumns="true"
    *ngIf="userPersonal && userWork"
    class="kenjo-ph-20px kenjo-pv-10px kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-bottom"
  >
    <orgos-column></orgos-column>
    <orgos-user-header
      orgosColumn="1"
      class="kenjo-pl-40px kenjo-pr-40px kenjo-pt-10px kenjo-pb-10px"
      size="medium"
      [userPersonal]="userPersonal"
      [userWork]="userWork"
    ></orgos-user-header>
    <orgos-column></orgos-column>
  </orgos-column-container>
  <orgos-container class="pdcp-current-salary-container kenjo-p-20px">
    <orgos-column-container centerColumns="true" class="kenjo-mb-20px">
      <orgos-column size="1">
        <span *ngIf="i18n.page && optionCompany && optionCompany.length > 0">{{i18n.page.company}}</span>
        <orgos-input-simple-select
          *ngIf="optionCompany && optionCompany.length > 0"
          class="kenjo-ml-10px"
          [value]="companySelected"
          [options]="optionCompany"
          (valueChange)="changeCompany($event)"
        ></orgos-input-simple-select>
      </orgos-column>
      <orgos-column size="0" [class.kenjo-mt-20px]="!optionCompany || optionCompany.length === 0">
        <kenjo-icon
          [disabled]="loadingChangeYear"
          [class.kenjo-font-color-disabled-c4c4c4]="loadingChangeYear"
          [size]="25"
          class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer"
          (click)="changeYear(-1)"
        >
          keyboard_arrow_left</kenjo-icon
        >
      </orgos-column>
      <orgos-column
        size="0"
        [class.kenjo-font-color-disabled-c4c4c4]="loadingChangeYear"
        class="kenjo-mh-5px kenjo-font-size-20px"
        [class.kenjo-mt-20px]="!optionCompany || optionCompany.length === 0"
        >{{year}}</orgos-column
      >
      <orgos-column size="0" [class.kenjo-mt-20px]="!optionCompany || optionCompany.length === 0">
        <kenjo-icon
          [disabled]="loadingChangeYear"
          [class.kenjo-font-color-disabled-c4c4c4]="loadingChangeYear"
          [size]="25"
          class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer"
          (click)="changeYear(+1)"
        >
          keyboard_arrow_right</kenjo-icon
        >
      </orgos-column>
      <orgos-column size="1"> </orgos-column>
    </orgos-column-container>
    <ng-container *ngIf="optionCompany.length > 0">
      <orgos-column-container class="kenjo-mb-20px">
        <orgos-column size="0" class="kenjo-mr-5px">
          <kenjo-icon [size]="18" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
        </orgos-column>
        <orgos-column *ngIf="i18n.payroll" class="pdp-info-message">{{i18n.payroll.estimationAmountMessage}}</orgos-column>
      </orgos-column-container>
      <orgos-container *ngIf="userPayRoll.length > 0" aspect="card">
        <!-- TABLE STARTS -->
        <orgos-table
          [fullHeight]="true"
          [data]="userPayRoll"
          [displayedColumns]="['action', 'month', 'salary', 'variable', 'total', 'documents']"
        >
          <orgos-table-column columnKey="action" header="" [sortable]="false" cellWidth="3">
            <div
              *orgosTableCell="let row"
              (click)="changeVariableView(row)"
              [class.kenjo-cursor-pointer]="row.detailedVariables?.length > 0"
            >
              <div class="pdpp-column-icon">
                <kenjo-icon
                  *ngIf="!row.collapsed && row.detailedVariables?.length > 0"
                  [size]="20"
                  class="kenjo-pv-15px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
                >
                  keyboard_arrow_up</kenjo-icon
                >
                <kenjo-icon
                  *ngIf="row.collapsed && row.detailedVariables?.length > 0"
                  [size]="20"
                  class="kenjo-pv-15px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
                >
                  keyboard_arrow_down</kenjo-icon
                >
                <div *ngIf="!row.collapsed">
                  <orgos-column-container
                    centerColumns="true"
                    class="pdpp-expanded-content-row"
                    *ngFor="let detail of row.detailedVariables"
                  >
                    <orgos-column></orgos-column>
                  </orgos-column-container>
                </div>
              </div>
            </div>
          </orgos-table-column>

          <orgos-table-column columnKey="month" [header]="i18n.page.month" [sortable]="false" [info]="i18n.page.variablePay">
            <div
              *orgosTableCell="let row"
              (click)="changeVariableView(row)"
              [class.kenjo-cursor-pointer]="row.detailedVariables?.length > 0"
            >
              <div class="kenjo-pv-15px">{{row.date | date:"shortDate":"UTC"}} - {{row.endDate.toDate() | date:"shortDate":"UTC"}}</div>
              <div *ngIf="!row.collapsed">
                <orgos-column-container centerColumns="true" class="pdpp-expanded-content-row" *ngFor="let detail of row.detailedVariables">
                  <orgos-column></orgos-column>
                </orgos-column-container>
              </div>
            </div>
          </orgos-table-column>

          <orgos-table-column columnKey="salary" [header]="i18n.page.salary" [sortable]="false">
            <div
              *orgosTableCell="let row"
              (click)="changeVariableView(row)"
              [class.kenjo-cursor-pointer]="row.detailedVariables?.length > 0"
            >
              <div *ngIf="row.salary !== null && (!row.salaryEndDate || row.date < row.salaryEndDate)" class="kenjo-pv-15px">
                {{row.salary | number:'1.0-2'}} ({{row.salaryCurrency}})
              </div>
              <div *ngIf="!row.collapsed">
                <orgos-column-container centerColumns="true" class="pdpp-expanded-content-row" *ngFor="let detail of row.detailedVariables">
                  <orgos-column></orgos-column>
                </orgos-column-container>
              </div>
            </div>
          </orgos-table-column>

          <orgos-table-column columnKey="variable" [header]="i18n.page.variable" [sortable]="false">
            <div
              *orgosTableCell="let row"
              (click)="changeVariableView(row)"
              [class.kenjo-cursor-pointer]="row.detailedVariables?.length > 0"
            >
              <div *ngIf="row.variable !== null" class="kenjo-pv-15px">{{row.variable | number:'1.0-2'}} ({{row.variableCurrency}})</div>
              <div *ngIf="!row.collapsed">
                <orgos-column-container centerColumns="true" class="pdpp-expanded-content-row" *ngFor="let detail of row.detailedVariables">
                  <orgos-column size="0"> {{detail.amount | number:'1.0-2'}} ({{detail.currency}}) </orgos-column>
                  <orgos-column
                    size="0"
                    class="kenjo-ml-10px"
                    matTooltip="{{detail.type}}, {{detail.effectiveDate | date:'shortDate':'UTC'}}"
                  >
                    <kenjo-icon [size]="18" class="kenjo-font-color-success-00b72e">help_outline</kenjo-icon>
                  </orgos-column>
                </orgos-column-container>
              </div>
            </div>
          </orgos-table-column>

          <orgos-table-column columnKey="total" [header]="i18n.page.total" [sortable]="false">
            <div
              *orgosTableCell="let row"
              (click)="changeVariableView(row)"
              [class.kenjo-cursor-pointer]="row.detailedVariables?.length > 0"
            >
              <div *ngIf="row.variable !== null" class="kenjo-pv-15px">{{row.total | number:'1.0-2'}} ({{row.salaryCurrency}})</div>
              <div *ngIf="!row.collapsed">
                <orgos-column-container centerColumns="true" class="pdpp-expanded-content-row" *ngFor="let detail of row.detailedVariables">
                  <orgos-column></orgos-column>
                </orgos-column-container>
              </div>
            </div>
          </orgos-table-column>

          <orgos-table-column columnKey="documents" [header]="i18n.page.payrollDocument" [sortable]="false">
            <div
              *orgosTableCell="let row"
              (click)="changeVariableView(row)"
              [class.kenjo-cursor-pointer]="row.detailedVariables?.length > 0"
            >
              <div
                *ngFor="let document of row.files"
                class="kenjo-cursor-pointer kenjo-pv-5px kenjo-mr-10px kenjo-inline-block pdpp-doc-cell"
              >
                <orgos-document-icon
                  size="small"
                  *ngIf="document"
                  [extension]="document._file?._fileExtension"
                  (click)="downloadAttachment(document)"
                  [matTooltip]="document.name"
                ></orgos-document-icon>
              </div>
              <div *ngIf="!row.collapsed">
                <orgos-column-container centerColumns="true" class="pdpp-expanded-content-row" *ngFor="let detail of row.detailedVariables">
                  <orgos-column></orgos-column>
                </orgos-column-container>
              </div>
            </div>
          </orgos-table-column>
        </orgos-table>
        <!-- TABLE ENDS -->
      </orgos-container>
    </ng-container>
    <!-- NO CONTENT -->
    <div *ngIf="optionCompany.length === 0" class="kenjo-text-align-center pdpp-no-data">
      <div class="kenjo-empty-state kenjo-text-align-center kenjo-mt-80px kenjo-mb-30px">
        <img src="/assets/images/lama.svg" />
      </div>
      <div class="kenjo-font-size-16px kenjo-mb-20px">{{ i18n.payroll.noEmployeeDataFound }}</div>
      <div class="pdpp-not-found-message kenjo-font-color-light-text-757575">{{ i18n.page.noDataFoundMessage }}</div>
    </div>
    <!-- NO CONTENT END -->
  </orgos-container>
</orgos-container>

<orgos-time-off-user-page
  *ngIf="seeDetailsUserId"
  [userId]="seeDetailsUserId"
  [managePoliciesInfo]="userManagePoliciesInfo"
  (goBack)="exitTimeOffFullScreen()"
  class="pdap-time-off-page"
></orgos-time-off-user-page>
