import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class WorkflowService {
  private WORKFLOW_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/workflow-db`;
  private WORKFLOW_PERMISSIONS_KEY: string = 'workflow';
  private WORKFLOW_INTERNATIONALIZATION: string = 'workflow-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  getAllWorkflows(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const findQuery = {};
      findQuery[fieldConstants.ID] = { $ne: null };
      this.http
        .post(`${this.WORKFLOW_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowService.name, 'getAllWorkflows'));
        });
    });
  }

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.WORKFLOW_URL, data)
        .then((document: any) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.WORKFLOW_URL, id)
        .then((workflowDocument: any) => {
          resolve(workflowDocument);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.WORKFLOW_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowService.name, 'updateById'));
        });
    });
  }

  runWorkflowManually(id: string, targetDocument: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const reqBody = {
        document: targetDocument
      };
      this.http
        .post(`${this.WORKFLOW_URL}/force-run/${id}`, reqBody, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowService.name, 'runWorkflowManually'));
        });
    });
  }

  getNextRecurringDate(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.WORKFLOW_URL}/recurring-date/${id}`, httpOptions)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowService.name, 'getNextRecurringDate'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.WORKFLOW_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.WORKFLOW_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.WORKFLOW_INTERNATIONALIZATION);
  }
}
