import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ICandidate, IPositionCandidateModel } from '@app/models/controllers/recruiting.model';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IPipelineStageModel } from '@app/standard/services/recruiting/recruiting-pipeline-stage.service';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class RecruitingPositionKanbanService {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting/position-kanban`;

  constructor(private errorManager: ErrorManagerService, private injector: Injector, private http: HttpClient) {}

  async getPositionKanban(positionId: string): Promise<IPositionKanban> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, RecruitingPositionKanbanService.name, 'getPositionKanban');
      }

      const headers = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = { headers };
      const result = await this.http.get<IPositionKanban>(`${this.URL}/${positionId}`, httpOptions).toPromise();
      return result;
    } catch (error) {
      throw this.errorManager.handleRawError(error, RecruitingPositionKanbanService.name, 'getPositionKanban');
    }
  }
}

export interface IPositionKanban {
  pipelineStages: Array<IPipelineStageModel>;
  mapPositionCandidateToCandidate: {[id: string]: ICandidate};
  mapStageIdToListPositionCandidates: {[id: string]: Array<IPositionCandidateModel>},
  mapStageIdToListDisqualified: {[id: string]: Array<IPositionCandidateModel>},
  mapCandidateIdToNumberOtherPositions: {[id: string]: number};
  hasCandidateIdUnreadEmails: {[id: string]: boolean};
}
