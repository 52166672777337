import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorParserService } from './error-parser.service';

declare const window: any;

@Injectable()
export class ErrorManagerService {
  private showErrors: 'true' | 'false' = 'true';
  private showingError: boolean = false;
  private errorsQueue: Array<OrgosError> = [];

  constructor(private injector: Injector) {
    if (environment.production === true) {
      const config = {
        dsn: environment.SENTRY_DSN,
        release: environment.AWS_SLUG_COMMIT,
        environment: environment.AWS_APP_NAME,
        integrations: [new Integrations.Dedupe(), new Integrations.ExtraErrorData({ depth: 5 })],
      };

      Sentry.init(config);

      Sentry.configureScope((scope) => {
        scope.setTag('git_commit', environment.AWS_SLUG_COMMIT);
        scope.setTag('aws_app_name', environment.AWS_APP_NAME);
        scope.setTag('angular_production', `${environment.production}`);
      });
    }
  }

  setErrorsVisibility(visible: 'true' | 'false'): void {
    this.showErrors = visible;
  }

  handleRawError(error: any, serviceName?: string, operationName?: string, internalErrorCode?: string): Promise<OrgosError> {
    return new Promise<OrgosError>((resolve, reject) => {
      this.injector
        .get(ErrorParserService)
        .parseError(error, serviceName, operationName, internalErrorCode)
        .then((orgosError: OrgosError) => {
          resolve(this.handleParsedError(orgosError));
        })
        .catch((parsingError) => {
          // This situation should not be possible because ErrorParserService.parseError cannot reject
          const orgosError = new OrgosError(parsingError, ErrorCodes.CLIENT_ERROR, ErrorManagerService.name, 'handleRawErrorSilently');
          orgosError.message = 'ErrorParserService.parseError cannot reject the promise';
          reject(this.handleParsedErrorSilently(orgosError));
        });
    });
  }

  handleRawErrorSilently(error: any, serviceName: string, operationName: string): Promise<OrgosError> {
    return new Promise<OrgosError>((resolve, reject) => {
      this.injector
        .get(ErrorParserService)
        .parseError(error, serviceName, operationName)
        .then((orgosError: OrgosError) => {
          resolve(this.handleParsedErrorSilently(orgosError));
        })
        .catch((parsingError) => {
          // This situation should not be possible because ErrorParserService.parseError cannot reject
          const orgosError = new OrgosError(parsingError, ErrorCodes.CLIENT_ERROR, ErrorManagerService.name, 'handleRawErrorSilently');
          orgosError.message = 'ErrorParserService.parseError cannot reject the promise';
          reject(this.handleParsedErrorSilently(orgosError));
        });
    });
  }

  handleParsedError(orgosError: OrgosError): Promise<OrgosError> {
    return new Promise<OrgosError>((resolve) => {
      this.checkIfSessionIsValid(orgosError)
        .then(() => {
          // Display user error
          this.errorsQueue.push(orgosError);
          this.displayError();

          resolve(this.handleParsedErrorSilently(orgosError));
        })
        .catch(() => {
          resolve(orgosError);
        });
    });
  }

  handleParsedErrorSilently(orgosError: OrgosError): Promise<OrgosError> {
    return new Promise<OrgosError>((resolve) => {
      this.checkIfSessionIsValid(orgosError)
        .then(() => {
          // Log error details for developers
          this.logErrorForDevelopers(orgosError);

          resolve(orgosError);
        })
        .catch(() => {
          resolve(orgosError);
        });
    });
  }

  async forceReloadError(error: OrgosError, serviceName: string, operationName: string): Promise<void> {
    const orgosError = await this.injector.get(ErrorParserService).parseError(error, serviceName, operationName);

    const snackBarRef = this.injector.get(MatLegacySnackBar).open(orgosError.message, '', {
      duration: 5000,
      panelClass: 'kenjo-error-snackbar',
    });
    const timeOutRef: NodeJS.Timeout = setTimeout(() => {
      window.location.reload(true);
    }, 5000);

    snackBarRef.onAction().subscribe(() => {
      window.location.reload(true);
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.errorsQueue = []; // clear the errorsQueue to prevent stacking errors
      this.showingError = false;
      this.displayError();
      clearTimeout(timeOutRef);
      return orgosError;
    });
  }

  async displayCustomError(error: OrgosError, serviceName: string, operationName: string, internalErrorCode?: string): Promise<void> {
    const orgosError = await this.injector.get(ErrorParserService).parseError(error, serviceName, operationName, internalErrorCode);

    const snackBarRef = this.injector.get(MatLegacySnackBar).open(orgosError.message, '', {
      duration: 5000,
      panelClass: 'kenjo-error-snackbar',
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.errorsQueue = []; // clear the errorsQueue to prevent stacking errors
      this.showingError = false;
      this.displayError();
      return orgosError;
    });
  }

  private checkIfSessionIsValid(orgosError: OrgosError): Promise<void> {
    if (orgosError.errorCode !== ErrorCodes.UNAUTHORIZED) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(AuthenticationService)
        .refreshLoggedUser()
        .then(() => {
          // The error was real because the session has not expired.
          resolve();
        })
        .catch(() => {
          // The error was caused because the session has expired

          const error = new OrgosError('SESSION EXPIRED', ErrorCodes.SESSION_EXPIRED, ErrorManagerService.name, 'checkIfSessionIsValid');
          this.handleRawError(error)
            .then((sessionExpiredError) => {
              this.injector.get(MatLegacySnackBar).dismiss();
              this.errorsQueue = [sessionExpiredError];
              this.displayError();
            })
            .catch(() => {
              this.injector.get(MatLegacySnackBar).dismiss();
              this.errorsQueue = [];
              this.displayError();
            });

          this.injector.get(AuthenticationService).expireSession();
          reject();
        });
    });
  }

  private displayError(): void {
    if (this.showErrors === 'false') {
      return;
    }

    if (this.showingError === true || this.errorsQueue.length < 1) {
      return;
    }

    this.showingError = true;

    const errorToDisplay = this.errorsQueue.shift();
    this.errorsQueue = []; // clear the errorsQueue to prevent stacking errors

    const snackBarRef = this.injector
      .get(MatLegacySnackBar)
      .open(errorToDisplay.message, `${errorToDisplay.reloadApp === true ? 'Refresh app' : 'x'}`, {
        duration: errorToDisplay.reloadApp === false ? 5000 : undefined,
        panelClass: 'kenjo-error-snackbar',
      });

    snackBarRef.onAction().subscribe(() => {
      if (errorToDisplay.reloadApp === true) {
        window.location.reload(true);
      }
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.errorsQueue = []; // clear the errorsQueue to prevent stacking errors
      this.showingError = false;
      this.displayError();
    });
  }

  private logErrorForDevelopers(orgosError: OrgosError): void {
    if (environment.production === true) {
      const userInfo: any = this.injector.get(AuthenticationService).isUserAuthenticated()
        ? this.injector.get(AuthenticationService).getLoggedUser()
        : {};

      Sentry.withScope((scope) => {
        if (check.assigned(userInfo) && check.assigned(userInfo[fieldConstants.ID])) {
          userInfo.id = userInfo[fieldConstants.ID];
          delete userInfo[fieldConstants.ID];

          scope.setUser(userInfo);
        }

        scope.setExtra('Kenjo Error - Code', orgosError.errorCode);
        scope.setExtra('Kenjo Error - Service name', orgosError.serviceName);
        scope.setExtra('Kenjo Error - Operation name', orgosError.operationName);
        scope.setExtra('Kenjo Error - Message', orgosError.message);
        scope.setExtra('Kenjo Error - Reload App', orgosError.reloadApp);

        if (orgosError.originalError instanceof HttpErrorResponse) {
          scope.setExtra('Original Error - Name', orgosError.originalError.name);
          scope.setExtra('Original Error - Status', orgosError.originalError.status);
          scope.setExtra('Original Error - Status text', orgosError.originalError.statusText);
          scope.setExtra('Original Error - URL', orgosError.originalError.url);
          scope.setExtra('Original Error - Message', orgosError.originalError.message);
          scope.setExtra('Original Error - Ok', orgosError.originalError.ok);
          scope.setExtra('Original Error - Error', orgosError.originalError.error);

          Sentry.captureMessage(
            `${orgosError.serviceName}.${orgosError.operationName}(): ${orgosError.errorCode} - ${ErrorCodes[orgosError.errorCode]}`
          );
        } else {
          Sentry.captureException(orgosError.originalError);
        }
      });
    }

    if (environment.ENABLE_ERRORS_BY_CONSOLE === 'true') {
      // tslint:disable: no-console
      console.log('');
      console.log('----- ERROR -----');
      const sentryMessage: string = environment.production === true ? `Sentry id: ${Sentry.lastEventId()}` : 'No Sentry';
      console.log(sentryMessage);
      console.log(orgosError);
      console.log('-----------------');
      console.log('');
      // tslint:enable: no-console
    }
  }
}
