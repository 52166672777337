<div *ngIf="currentTimeOff?._id" class="tos-step-container">
  <div class="tosc-flex-center-container kenjo-flex-gap-5px kenjo-font-color-light-text-757575 kenjo-font-size-16px">
    <kenjo-icon>time_off_menu</kenjo-icon>
    <span class="kenjo-font-weight-bold">{{ translations.title }}</span>
  </div>

  <div class="kenjo-font-color-light-text-757575 kenjo-mt-15px kenjo-mb-20px kenjo-text-align-center">{{ translations.subtitle }}</div>

  <div class="tosc-time-off-container">
    <div class="tosc-time-off-header">
      <div class="kenjo-flex-center-content kenjo-flex-gap-5px">
        <kenjo-icon-highlight
          semantic="user"
          [userColor]="currentTimeOff?._timeOffTypeColor"
          size="s"
          [icon]="'time_off_menu'"
        ></kenjo-icon-highlight>
        <kenjo-simple-truncate-text class="tosc-time-off-max-width">{{ timeOffMap[currentTimeOff?._id]?.name }}</kenjo-simple-truncate-text>
      </div>
      <div *ngIf="showTodayRequest" class="tosc-today-request kenjo-font-color-light-text-757575">
        <kenjo-icon [size]="18">error_outline</kenjo-icon>
        <span matTooltipClass="tosc-tooltip" [matTooltip]="timeOffToolTip">
          {{ translations.todayRequest }}
        </span>
      </div>
    </div>
    <div class="tosc-time-off-bottom">
      <div class="tosc-time-off-bottom-left">
        <p *ngIf="showFromDate" class="kenjo-font-weight-bold kenjo-mb-0 kenjo-mt-15px">
          {{ currentTimeOff?.from | date: 'mediumDate' }}
        </p>
        <p *ngIf="showToDate" class="kenjo-font-weight-bold kenjo-mb-0 kenjo-mt-15px">
          {{ currentTimeOff?.to | date: 'mediumDate' }}
        </p>
        <p class="kenjo-text-align-left kenjo-mt-5px">
          <ng-container *ngIf="isFullDayOff">{{ translations.fullDayTimeOff }}</ng-container>
          <ng-container *ngIf="isMorningOff">{{ translations.morningTimeOff }}</ng-container>
          <ng-container *ngIf="isAfternoonOff">{{ translations.afternoonTimeOff }}</ng-container>
          <ng-container *ngIf="isCustomTimeOff"
            ><span class="kenjo-font-size-12px tosc-flex-center-container kenjo-font-color-light-text-757575 kenjo-flex-gap-2px"
              >{{ timeOffMap[currentTimeOff?._id]?.startTime }}<kenjo-icon [size]="18">arrow_right_alt</kenjo-icon>
              {{ timeOffMap[currentTimeOff?._id]?.endTime }}</span
            ></ng-container
          >
        </p>
      </div>
      <kenjo-icon *ngIf="showArrowIcon" [size]="18" class="kenjo-font-color-light-text-757575">arrow_forward</kenjo-icon>
      <div *ngIf="showEndDate">
        <p class="kenjo-font-weight-bold kenjo-mb-5px">{{ currentTimeOff.to | date: 'mediumDate' }}</p>
        <p class="kenjo-text-align-right kenjo-mt-5px">
          <ng-container *ngIf="isFullDayOffEnd">{{ translations.fullDayTimeOff }}</ng-container>
          <ng-container *ngIf="isMorningOffEnd">{{ translations.morningTimeOff }}</ng-container>
          <ng-container *ngIf="isAfternoonOffEnd">{{ translations.afternoonTimeOff }}</ng-container>
        </p>
      </div>
    </div>
  </div>

  <div class="tosc-time-off-details kenjo-font-color-primary-5993e3 kenjo-mt-10px">
    <a
      class="tosc-flex-center-container kenjo-reset-link kenjo-cursor-pointer kenjo-flex-gap-5px"
      [routerLink]="['/cloud/time-off/personal']"
      >{{ translations.timeOffDetails }} <kenjo-icon [size]="20">launch</kenjo-icon></a
    >
  </div>
</div>
