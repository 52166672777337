import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateRange, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatCalendar } from '@angular/material/datepicker';
import * as timeOffHelpers from '@app/cloud-features/time-off/time-off.helpers';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as check from 'check-types';
import * as moment from 'moment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'kenjo-input-date-calendar-picker',
  templateUrl: 'input-date-calendar-picker.component.html',
  styleUrls: ['input-date-calendar-picker.component.scss']
})
export class InputDateCalendarPickerComponent implements OnInit {
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() label: string;
  @Input() warningTooltip: string;

  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;

  @Input() singleEvents: Array<ISingleCalendarEvent>;
  @Input() recurringEvents: Array<IRecurringCalendarEvent>;
  @Input() selectedDateValue: Date;

  @Input() loading: boolean = false;
  @Input() eventsRange: DateRange<moment.Moment>;

  @Input() initialCalendarStart: moment.Moment;

  @Output() selectedDateValidationChange: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Output() selectedDateValueChange = new EventEmitter<Date>();
  @Output() loadEventsChange: EventEmitter<{ time: SelectedTimeType; date: moment.Moment }> = new EventEmitter<{ time: SelectedTimeType; date: moment.Moment }>();

  @ViewChild('calendar') calendar: MatCalendar<moment.Moment>;

  VALID_DATE_FORMATS: Array<string> = ['DD-MM-YYYY', 'D-MM-YYYY', 'DD-M-YYYY', 'D-M-YYYY', 'DD-MM-YY', 'D-MM-YY', 'DD-M-YY', 'D-M-YY'];

  selected: moment.Moment | null;
  selectedTime: moment.Moment | null;
  displayCalendar = false;

  dayEventsMap: Map<string, IDayCalendarEvent>;
  calendarStartAt: moment.Moment | null;
  calendarPeriodLabel: string;

  dateLimits = {
    minMonthReached: false,
    minYearReached: false,
    maxMonthReached: false,
    maxYearReached: false
  };
  selectedDateValidation: InputValidation;
  selectedDateError: string;
  previewDateChangeSubscription: Subscription;

  translations: { [translationKey: string]: string };

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    if (check.not.assigned(this.dayEventsMap)) {
      this.initCalendarEvents();
    }
    if (view === 'month') {
      return this.getDayEventStyles(cellDate.format('YYYY-MM-DD'));
    }
    return '';
  };

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initTranslations();
    this.initCalendarDates();
  }

  private async initTranslations() {
    try {
      this.translations = await this.injector.get(InternationalizationService).getAllTranslation('input-date-calendar-picker');
    } catch {
      this.translations = {};
    }
  }

  private initCalendarDates() {
    this.initSelectedTime();
    this.updateStartAt();
  }

  private initCalendarEvents() {
    this.dayEventsMap = new Map<string, IDayCalendarEvent>();
    if (check.nonEmptyArray(this.singleEvents)) {
      this.singleEvents.forEach((singleEvent) => {
        const startDateMoment = moment.utc(singleEvent.startDate);
        const daysBetween = Math.ceil(moment.utc(singleEvent.endDate).diff(startDateMoment, 'days')) + 1;
        [...Array(daysBetween).keys()].forEach((key) => {
          const formattedDate = startDateMoment.clone().add(key, 'day').format('YYYY-MM-DD');
          const isStart = key === 0;
          const isEnd = key === daysBetween - 1;
          this.addDayEvent({ formattedDate, name: singleEvent.name, isStart, isEnd, backgroundColor: singleEvent.backgroundColor, fontColor: singleEvent.fontColor });
        });
      });
    }
  }

  private addDayEvent({ formattedDate, name, isStart, isEnd, backgroundColor, fontColor }: { formattedDate: string; name: string; isStart: boolean; isEnd: boolean; backgroundColor: boolean; fontColor: boolean }) {
    let newDateEvent: IDayCalendarEvent;
    const previousDayEvent = this.dayEventsMap.get(formattedDate);
    if (check.assigned(previousDayEvent)) {
      newDateEvent = {
        names: [...previousDayEvent.names, name],
        isStart: previousDayEvent.isStart && isStart,
        isEnd: previousDayEvent.isEnd && isEnd,
        backgroundColor: previousDayEvent.backgroundColor || backgroundColor,
        fontColor: previousDayEvent.fontColor || fontColor
      };
    } else {
      newDateEvent = {
        names: [name],
        isStart,
        isEnd,
        backgroundColor,
        fontColor
      };
    }
    this.dayEventsMap.set(formattedDate, newDateEvent);
  }

  private getDayEventStyles(date: string) {
    let dayEvent = this.dayEventsMap.get(date);
    dayEvent = this.processDayRecurringEvents(date, dayEvent);
    const styles = [];
    if (check.assigned(dayEvent)) {
      if (dayEvent.isStart && dayEvent.isEnd) {
        styles.push('idrcp-single-day');
      } else if (dayEvent.isStart) {
        styles.push('idrcp-first-day');
      } else if (dayEvent.isEnd) {
        styles.push('idrcp-last-day');
      }
      if (dayEvent.backgroundColor) {
        styles.push('idrcp-background-color-day');
      }
      if (dayEvent.fontColor) {
        styles.push('idrcp-font-color-day');
      }
      const tooltipName = dayEvent.names.join('\n');
      const tooltipClass = `idrcp-${timeOffHelpers.generateUniqueId()}`;
      styles.push(tooltipClass, 'tooltip');
      setTimeout(() => {
        const tooltipElements = document.querySelectorAll(`.${tooltipClass} .mat-calendar-body-cell-content`);
        tooltipElements.forEach((elem) => {
          elem.setAttribute('tooltip-data', tooltipName);
        });
      }, 200);
    }
    return styles.join(' ');
  }

  private processDayRecurringEvents(date: string, dayEvent: IDayCalendarEvent): IDayCalendarEvent {
    const normalizedIsoWeekday = moment.utc(date).isoWeekday();
    let processedDayEvent = dayEvent;
    this.recurringEvents.forEach((recurringEvent) => {
      if (moment.utc(date).isBetween(recurringEvent.startDate, recurringEvent.endDate, 'day', '[]') && recurringEvent.daysOfWeek.includes(normalizedIsoWeekday)) {
        if (check.assigned(processedDayEvent)) {
          processedDayEvent = {
            names: [...processedDayEvent.names, recurringEvent.name],
            isStart: processedDayEvent.isStart,
            isEnd: processedDayEvent.isEnd,
            backgroundColor: processedDayEvent.backgroundColor || recurringEvent.backgroundColor,
            fontColor: processedDayEvent.fontColor || recurringEvent.fontColor
          };
        } else {
          processedDayEvent = {
            names: [recurringEvent.name],
            isStart: true,
            isEnd: true,
            backgroundColor: recurringEvent.backgroundColor,
            fontColor: recurringEvent.fontColor
          };
        }
      }
    });
    return processedDayEvent;
  }

  public openCalendar() {
    if (this.readOnly) {
      return;
    }
    this.displayCalendar = true;
    if (check.not.assigned(this.selectedDateValue)) {
      this.selectedDateValue = this.initialCalendarStart?.toDate() ?? moment.utc().toDate();
    }
    this.updateStartAt();
  }

  onClickCalendar(event) {
    // Prevent input focusout event from being triggered
    event.preventDefault();
    event.stopPropagation();
  }

  public closeCalendar() {
    this.displayCalendar = false;
    this.checkEmptyDates();
    this.printDateErrors();
  }

  public selectedChange(date: moment.Moment | null, manual: boolean = false, dateStr?: string) {
    const newDate = date?.startOf('day');
    const validation = this.checkValidDate(newDate, manual, dateStr);
    if (validation.hasErrors()) {
      if (this.selectedDateValidation.getError('required')) {
        this.selectedTime = null;
      }
      this.setNewDate();
      this.printDateErrors();
      return;
    }
    if (!this.readOnly) {
      this.selectedTime = newDate;
    }

    this.setNewDate();
    this.closeCalendar();
    this.printCalendarPreview({ activeDate: null });
    this.printDateErrors();
    if (manual === true) {
      this.moveCalendarToDate(newDate);
    }
  }

  public moveCalendarToPrevious(unit: 'month' | 'year') {
    this.calendar.activeDate = this.calendar.activeDate.clone().subtract(1, unit);
    this.updateCalendarPeriodLabel(this.calendar.activeDate);
    this.checkDateLimits(this.calendar.activeDate);
    this.syncStartAt();
    this.loadEvents();
  }

  public moveCalendarToNext(unit: 'month' | 'year') {
    this.calendar.activeDate = this.calendar.activeDate.clone().add(1, unit);
    this.updateCalendarPeriodLabel(this.calendar.activeDate);
    this.checkDateLimits(this.calendar.activeDate);
    this.syncStartAt();
    this.loadEvents();
  }

  private moveCalendarToDate(date: moment.Moment) {
    this.calendar.activeDate = date.clone();
    this.updateCalendarPeriodLabel(this.calendar.activeDate);
    this.checkDateLimits(this.calendar.activeDate);
    this.syncStartAt();
    this.loadEvents();
  }

  private loadEvents() {
    if (this.calendar.activeDate.isBefore(this.eventsRange.start)) {
      this.loadEventsChange.emit({ time: 'start', date: this.calendar.activeDate.clone().startOf('month') });
    } else if (this.calendar.activeDate.isSameOrAfter(this.eventsRange.end)) {
      this.loadEventsChange.emit({ time: 'end', date: this.calendar.activeDate.clone().add(1, 'month').startOf('month') });
    }
  }

  private syncStartAt() {
    this.calendarStartAt = this.calendar.activeDate.clone();
  }

  private initSelectedTime() {
    if (check.assigned(this.selectedDateValue)) {
      this.selectedTime = moment.utc(this.selectedDateValue);
      if (this.readOnly) {
        this.maxDate = this.selectedTime.clone();
        this.minDate = this.selectedTime.clone();
      }
    }
  }

  private updateStartAt() {
    this.calendarStartAt = this.selectedDateValue ? moment.utc(this.selectedDateValue) : this.initialCalendarStart ?? moment.utc();
    this.updateCalendarPeriodLabel(this.calendarStartAt);
    this.checkDateLimits(this.calendarStartAt);
  }

  private updateCalendarPeriodLabel(selectedDate: moment.Moment) {
    this.calendarPeriodLabel = this.injector.get(DatePipe).transform(selectedDate.toDate(), 'MMM y');
  }

  private checkDateLimits(selectedDate: moment.Moment) {
    const minDateStartOfMonth = this.minDate.clone().startOf('month');
    const maxDateStartOfMonth = this.maxDate.clone().startOf('month');
    this.dateLimits.minYearReached = selectedDate.clone().startOf('month').add(1, 'month').subtract(1, 'year').isSameOrBefore(minDateStartOfMonth);
    this.dateLimits.minMonthReached = selectedDate.clone().startOf('month').isSameOrBefore(minDateStartOfMonth);
    this.dateLimits.maxYearReached = selectedDate.clone().endOf('month').subtract(1, 'month').add(1, 'year').isSameOrAfter(maxDateStartOfMonth);
    this.dateLimits.maxMonthReached = selectedDate.clone().endOf('month').isSameOrAfter(maxDateStartOfMonth);
  }

  private printCalendarPreview({ activeDate, currentRange }: { activeDate: moment.Moment; currentRange?: DateRange<moment.Moment> }) {
    if ((currentRange && !currentRange.start) || activeDate?.isSame(this.calendar.activeDate, 'month')) {
      return;
    }
    if (currentRange?.start && activeDate && currentRange.start.month() === activeDate.month()) {
      return;
    }
    const isStartDayInLastMonth = currentRange?.start && activeDate && currentRange.start.clone().add(1, 'month').month() === activeDate.month();
    const startDay = isStartDayInLastMonth ? currentRange.start.date() : 0;
    const startCalendarDayElements = document.querySelectorAll('.idrcp-start-calendar .mat-calendar-body-cell');
    if (check.not.assigned(startCalendarDayElements) || startCalendarDayElements?.length === 0) {
      return;
    }
    startCalendarDayElements.forEach((dayElement) => {
      if (check.not.assigned(activeDate)) {
        dayElement.classList.remove('mat-calendar-body-in-preview', 'mat-calendar-body-preview-start');
        return;
      }
      const contentElement = dayElement.querySelector('.mat-calendar-body-cell-content');
      if (check.not.assigned(contentElement)) {
        return;
      }
      const contentDay = +contentElement.innerHTML;
      if (contentDay === startDay) {
        dayElement.classList.add('mat-calendar-body-preview-start', 'mat-calendar-body-in-preview');
      } else if (contentDay > startDay) {
        dayElement.classList.add('mat-calendar-body-in-preview');
      } else {
        dayElement.classList.remove('mat-calendar-body-in-preview', 'mat-calendar-body-preview-start');
      }
    });
  }

  public onInputDateChange(dateStr: string) {
    const momentDate = this.parseDateWithCustomSeparators(dateStr);
    this.selectedChange(momentDate, true, dateStr);
  }

  private parseDateWithCustomSeparators(dateString) {
    const cleanedDateString = dateString?.replace(/[\/.]/g, '-');
    return moment.utc(cleanedDateString, this.VALID_DATE_FORMATS, true);
  }

  private checkEmptyDates() {
    if (check.not.assigned(this.selectedDateValidation)) {
      const inputValidation = new InputValidation();
      if (this.required && check.not.assigned(this.selectedTime)) {
        inputValidation.setError('required');
      }
      if (inputValidation.hasErrors()) {
        this.selectedDateValidationChange.emit(inputValidation);
        this.selectedDateValidation = inputValidation;
      }
    }
  }

  private checkValidDate(date: moment.Moment, manual: boolean, dateStr?: string) {
    const inputValidation = new InputValidation();
    const required = this.required;
    if (required && manual === true && (check.not.assigned(dateStr) || check.emptyString(dateStr))) {
      inputValidation.setError('required');
    } else if (check.assigned(date)) {
      if (!date.isValid()) {
        inputValidation.setError('format');
      } else if (check.assigned(this.minDate) && date.isBefore(this.minDate)) {
        inputValidation.setError('min');
      } else if (check.assigned(this.maxDate) && date.isAfter(this.maxDate)) {
        inputValidation.setError('max');
      }
    }
    this.selectedDateValidation = inputValidation;
    this.selectedDateValidationChange.emit(inputValidation);
    return inputValidation;
  }

  private printDateErrors() {
    if (check.not.assigned(this.selectedDateValidation) || !this.selectedDateValidation.hasErrors()) {
      this.selectedDateError = undefined;
    } else if (this.selectedDateValidation.getError('required')) {
      this.selectedDateError = this.translations.dateRequired;
    } else if (this.selectedDateValidation.getError('format')) {
      this.selectedDateError = this.translations.dateFormat;
    } else if (this.selectedDateValidation.getError('min')) {
      this.selectedDateError = this.injector.get(I18nDataPipe).transform(this.translations.dateMin, { min: this.minDate.format('L') });
    } else if (this.selectedDateValidation.getError('max')) {
      this.selectedDateError = this.injector.get(I18nDataPipe).transform(this.translations.dateMax, { max: this.maxDate.format('L') });
    } else {
      this.selectedDateError = undefined;
    }
  }

  private setNewDate() {
    this.selectedDateValue = this.selectedTime.toDate();
    this.selectedDateValueChange.emit(this.selectedDateValue);
  }
}

export type SelectedTimeType = 'start' | 'end' | undefined;

export interface IRecurringCalendarEvent {
  name: string;
  startDate: Date;
  endDate: Date;
  daysOfWeek: Array<number>;
  backgroundColor: boolean;
  fontColor: boolean;
}

export interface ISingleCalendarEvent {
  name: string;
  startDate: Date;
  endDate: Date;
  backgroundColor: boolean;
  fontColor: boolean;
}

export interface IDayCalendarEvent {
  names: Array<string>;
  isStart: boolean;
  isEnd: boolean;
  backgroundColor: boolean;
  fontColor: boolean;
}

export interface ISelectedRange {
  selectedTime: SelectedTimeType;
  dateRange: DateRange<moment.Moment>;
}
