import { ChangeDetectorRef, Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { IAttendanceLimitSettings } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import { IAttendanceCategory } from '@app/cloud-features/settings-attendance/services/attendance-category.service';
import {
  AttendanceConflictsService,
  IAttendanceConflict,
} from '@app/cloud-features/settings-attendance/services/attendance-conflicts.service';
import { AttendanceLimitsController } from '@app/cloud-features/settings-attendance/services/attendance-limits.controller';
import { AttendancePolicyService, IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { ITimeOffRequestModel, TimeOffRequestService } from '@app/cloud-features/time-off/services/time-off-request.service';
import { EditCommentDialog } from '@app/common-components/widgets/punch-clock/dialogs/edit-comment.dialog';
import {
  calculateMinutesInGivenShift,
  checkOverlapping,
  computeCurrentTimeOff,
  convertToMinutes,
  convertToTime,
  getBreakTime,
  returnEmptyAttendance,
  returnNewAttendanceEndTime,
} from '@app/common-components/widgets/punch-clock/orgos-widget-punch-clock.helpers';
import { restrictCheckIn } from '@app/common-components/widgets/widgets.helpers';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { AutoDeductDialogComponent } from '@app/standard/components/auto-deduct-break-dialog/auto-deduct-dialog.component';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { BreakReminderDialog } from '@app/standard/pages/people-detail/people-detail-attendance/dialogs/break-reminder/break-reminder.dialog';
import { calculateAutoDeductedBreaksDuration, calculateIsCloseEntry } from '@app/standard/services/attendance/attendance-helpers';
import {
  BreakReminderController,
  IBreakReminderConfiguration,
} from '@app/standard/services/attendance/controllers/break-reminder.controller';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import {
  AttendanceSummaryController,
  IAttendanceSummaryResult,
} from '@app/standard/services/user/controllers/attendance-summary.controller';
import { IBreak, IUserAttendanceModel, UserAttendanceService } from '@app/standard/services/user/user-attendance.service';
import { IUserWorkScheduleModel, UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserService } from '@app/standard/services/user/user.service';
import { OverlappingDialogComponent } from '@app/standard/standalone-components/overlapping-dialog/overlapping-dialog.component';
import {
  AttendancePolicy as IAttendancePolicyLib,
  TimeOffRequest as ITimeOffRequestLib,
  UserAttendance as IUserAttendanceLib,
  PunchClockValidator,
  calculateTimeOffRequestPartOfDayForDate,
} from '@carlos-orgos/kenjo-shared-libs';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import {
  POLICY_TYPE_HOUR,
  TIME_OFF_REQUEST_STATUS_CANCELLED,
  TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED,
  TIME_OFF_REQUEST_STATUS_DECLINED,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'orgos-widget-punch-clock',
  templateUrl: 'orgos-widget-punch-clock.component.html',
  styleUrls: ['orgos-widget-punch-clock.component.scss'],
})
export class WidgetPunchClockComponent implements OnInit, OnDestroy {
  translation: any = {};
  maxShiftLengthDialogTranslations: any = {};
  maxDailyHoursDialogTranslations: any = {};
  splitRequestDialogTranslations: any = {};
  breakReminderTranslations: any = {};
  loggedUser: any = {};
  summaryShift: any = {};
  stepText: Array<any> = [];
  totalAutoDeductedBreakTime: number;
  loadingWidget: boolean = false;
  loadingSoftRefresh: boolean = false;
  widgetStep: number = 0;
  totalAttendanceForCurrentDay: number = 0;
  clockInRequestLoading: boolean = false;
  checkOutRequestLoading: boolean = false;
  breakRequestLoading: boolean = false;
  commentRequestLoading: boolean = false;
  aMinuteHasPassed: boolean = false;
  initializedBreakTime: boolean = false;
  permissions: any = {};
  canEditAttendance: boolean = false;
  filteredAttendanceEntries: Array<any> = [];

  MAX_SHIFT_LENGTH = 1440;
  MAX_MINUTES_PER_DAY = 1440;
  MINUTES_IN_DAY = 1440;
  OVERNIGHT_MARGIN_MINUTES = 840; // 14 hours
  RELOADING_SHIFT = 10000;
  RELOADING_TIME_OFF = 40000;
  WIDGET_STEP = {
    CLOSED_ENTRY_STEP: 0,
    OPENED_ENTRY_STEP: 1,
    BREAK_TIME_STEP: 2,
    APPROVED_STEP: 3,
    TIME_OFF_STEP: 4,
  };

  userWorkSchedule: IUserWorkScheduleModel;
  attendanceSummaryResult: IAttendanceSummaryResult;
  userAttendanceForCurrentDay: IUserAttendanceModel = {};
  openEntryInThePreviousDay: IUserAttendanceModel;
  allUserAttendancesForCurrentDay: Array<IUserAttendanceModel>;
  allAttendanceOfDayApproved: boolean = false;
  isAttendanceDayCreated: boolean = false;
  breakTimeInterval: NodeJS.Timeout;
  currentTimeOffInterval: NodeJS.Timeout;
  currentTimeOff: ITimeOffRequestModel;
  currentTimeOffToolTip: string;
  currentHour: string;
  currentMinutes: string;
  activeBreakTime: number;
  isRestrictCheckIn: boolean;
  restrictTimeLimit: number | string = 1440;
  nextShiftDate: string;
  defaultCategory: IAttendanceCategory = {};

  attendanceCategories: Array<IAttendanceCategory>;
  attendancePolicy: IAttendancePolicy;
  attendanceConflictsToday: IAttendanceConflict[];
  conflicts = {
    maxDailyHours: false,
  };
  customBehaviors = {
    showCategoryCreationFromShift: false,
    hideBreakButton: false,
    allowCheckoutBeforeAMinute: false,
  };

  totalWorkTime: number;
  actualMinute = moment().minutes() + moment().hours() * 60;
  showDailyTime = false;
  displayedTotalWorkTime: number;
  private timeSubscription: Subscription;
  attendanceSubscription: Subscription;

  private hasOneShift = false;

  public currentAttendanceTime: number = 0;

  protected timeOffRequest: ITimeOffRequestModel[] = [];

  DATE_FORMAT = 'YYYY-MM-DD';

  @HostListener('window:focus', ['$event'])
  async appFocusChange() {
    await this.softDataRefresh();
  }

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.fetchData();
    this.createCurrentTimeOffInterval();
  }

  async fetchData() {
    try {
      this.loadingWidget = true;
      this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      this.initCustomBehaviors();
      this.openEntryInThePreviousDay = await this.getOpenEntryInThePreviousDay();

      const loggedUserName = (await this.injector.get(UserService).getUser(this.loggedUser._id))?.userPersonal?.displayName ?? '';

      [
        this.timeOffRequest,
        this.permissions,
        this.userWorkSchedule,
        this.attendancePolicy,
        this.translation,
        this.maxShiftLengthDialogTranslations,
        this.maxDailyHoursDialogTranslations,
        this.splitRequestDialogTranslations,
        this.breakReminderTranslations,
        this.attendanceSummaryResult,
      ] = await Promise.all([
        this.getTodayTimeOff(),
        this.injector.get(PrivateSecurityService).getPermissionsForCollections(['attendance-app']),
        this.injector.get(UserWorkScheduleService).getById(this.loggedUser._id),
        this.injector.get(AttendancePolicyService).getAttendancePolicyByUserId(this.loggedUser._id),
        this.injector.get(InternationalizationService).getAllTranslation('home-widget-punch-clock-component'),
        this.injector.get(InternationalizationService).getAllTranslation('max-shift-length-dialog'),
        this.injector.get(InternationalizationService).getAllTranslation('max-daily-hours-dialog'),
        this.injector.get(InternationalizationService).getAllTranslation('split-request-dialog'),
        this.injector.get(InternationalizationService).getAllTranslation('break-reminder-dialog'),
        this.getExpectedHours(),
        this.initAttendanceLimitSettings(),
      ]);

      this.translation.dayTrackedText = this.injector.get(I18nDataPipe).transform(this.translation.dayTrackedText, { loggedUserName });
      this.stepText = [this.translation.inactiveChipText, this.translation.activeChipText, this.translation.breakChipText];
      this.canEditAttendance = this.attendancePolicy?.methods?.punchClock;

      if (!['admin', 'hr-admin'].includes(this.loggedUser.profileKey) && this.attendancePolicy?.limitDailyHours?.isActive) {
        this.MAX_MINUTES_PER_DAY = this.attendancePolicy?.limitDailyHours?.limit;
      }

      await this.calculateMinutesInCurrentDay();
      await this.getTodayAttendanceConflicts();

      this.updateFormattedCurrentTime();
      this.setDefaultCategory();
      this.checkAttendanceEntries();
      if (!this.breakTimeInterval) {
        this.breakTimeInterval = setInterval(() => {
          this.updateFormattedCurrentTime();
        }, this.RELOADING_SHIFT);
      }

      if (this.widgetStep === this.WIDGET_STEP.OPENED_ENTRY_STEP) {
        this.startAttendanceInterval();
      }
    } catch {
      // Do nothing
    } finally {
      this.loadingWidget = false;
    }
  }

  private createCurrentTimeOffInterval() {
    clearInterval(this.currentTimeOffInterval);
    this.currentTimeOffInterval = setInterval(() => {
      this.calculateCurrentTimeOffData(this.timeOffRequest);
      if (this.shouldSetTimeOffStep(this.currentTimeOff, this.attendancePolicy)) {
        this.widgetStep = this.WIDGET_STEP.TIME_OFF_STEP;
        return;
      } else {
        this.updateWidgetStep();
      }
    }, this.RELOADING_TIME_OFF);
  }

  private checkAttendanceEntries(): void {
    this.hasOneShift = this.allUserAttendancesForCurrentDay?.length > 1;
    const validatedAttendanceEntries = this.allUserAttendancesForCurrentDay.filter(
      (attendanceEntries) => check.not.assigned(attendanceEntries._allowanceType) || attendanceEntries._allowanceType === 'Paid'
    );

    if (
      this.hasOneShift ||
      (validatedAttendanceEntries.length > 0 &&
        check.assigned(this.userAttendanceForCurrentDay) &&
        check.assigned(this.userAttendanceForCurrentDay.startTime) &&
        check.not.assigned(this.userAttendanceForCurrentDay.endTime) &&
        this.userAttendanceForCurrentDay._id !== validatedAttendanceEntries[0]._id)
    ) {
      this.showDailyTime = true;
      this.getDailyTime();
    } else {
      this.showDailyTime = false;
    }
  }

  private initCustomBehaviors() {
    this.customBehaviors.showCategoryCreationFromShift = this.injector
      .get(UserAttendanceService)
      .customBehaviors.checkCanCreateCategoryFromShift();
    this.customBehaviors.hideBreakButton = this.injector.get(UserAttendanceService).customBehaviors.checkShouldHideBreakButton();
    this.customBehaviors.allowCheckoutBeforeAMinute = this.injector
      .get(UserAttendanceService)
      .customBehaviors.checkAllowCheckoutBeforeAMinute();
  }

  private async initAttendanceLimitSettings() {
    const attendanceLimitSettings: IAttendanceLimitSettings = await this.injector.get(AttendanceLimitsController).getLimitsConfig();

    if (attendanceLimitSettings?.attendanceCategories?.length) {
      this.attendanceCategories = attendanceLimitSettings.attendanceCategories;
      this.attendanceCategories.map((attendanceCategory) => {
        attendanceCategory.value = attendanceCategory._id;
      });
    }
  }

  public async clockIn(initialCategory?: IAttendanceCategory) {
    if (!this.canEditAttendance || this.loadingSoftRefresh) {
      return;
    }

    this.currentTimeOff = computeCurrentTimeOff(this.timeOffRequest);
    if (
      this.currentTimeOff &&
      this.attendancePolicy.overlappingWithTimeOff.isActive &&
      !this.attendancePolicy.overlappingWithTimeOff?.conflicts
    ) {
      this.updateWidgetStep();
      return;
    }

    this.clockInRequestLoading = true;

    await this.calculateMinutesInCurrentDay(true);

    this.userAttendanceForCurrentDay = returnEmptyAttendance(this.loggedUser);

    if (initialCategory) {
      this.userAttendanceForCurrentDay.attendanceCategoryId = initialCategory._id;
      if (initialCategory.parentCategoryId) {
        this.userAttendanceForCurrentDay.attendanceCategoryId = initialCategory.parentCategoryId;
        this.userAttendanceForCurrentDay.attendanceSubCategoryId = initialCategory._id;
      }
    }
    this.setDefaultCategory();
    this.userAttendanceForCurrentDay.startTime = this.getCurrentMinutes();
    this.injector.get(UserAttendanceService).customBehaviors.injectCustomFieldsForClockIn(this.userAttendanceForCurrentDay);

    const overlappingShift = this.returnFullyOverlappingShift(this.userAttendanceForCurrentDay, [...this.allUserAttendancesForCurrentDay]);

    if (check.assigned(overlappingShift)) {
      this.injector.get(MatLegacySnackBar).open(
        this.injector.get(I18nDataPipe).transform(this.splitRequestDialogTranslations.overlappingTimeoff, {
          startTime: convertToTime(overlappingShift.startTime),
          endTime: convertToTime(overlappingShift.endTime),
        }),
        '',
        {
          duration: 5000,
          panelClass: 'kenjo-error-snackbar',
        }
      );

      this.clockInRequestLoading = false;
      return;
    }

    this.aMinuteHasPassed = false;
    try {
      this.userAttendanceForCurrentDay = await this.injector
        .get(UserAttendanceService)
        .create({ ...this.userAttendanceForCurrentDay, interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB });

      this.allUserAttendancesForCurrentDay.push(this.userAttendanceForCurrentDay);
      this.injector.get(PrivateAmplitudeService).logEvent('check in', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Widget',
        subcategory2: 'Punch clock',
        subcategory3: 'Clock',
      });
      this.filteredAttendanceEntries.push(this.userAttendanceForCurrentDay);
      this.checkAttendanceEntries();
      this.updateWidgetStep();
    } catch (error) {
      await this.showSnackbarAndRefresh();
    } finally {
      this.startAttendanceInterval();
      this.clockInRequestLoading = false;
      this.userAttendanceForCurrentDay = await this.getActualUserAttendanceForCurrentDay();
    }
  }

  private async getDailyTime() {
    try {
      const { totalWorkTime } = await this.injector.get(UserAttendanceService).getDailyTime();
      this.totalWorkTime = totalWorkTime;
      if (this.widgetStep !== this.WIDGET_STEP.BREAK_TIME_STEP) {
        this.displayedTotalWorkTime = this.totalWorkTime;
        this.startDailyTotalInterval();
      }
    } catch (error) {
      // Do nothing
    }
  }

  startDailyTotalInterval(): void {
    const now = moment();
    const timeTillNextMinute = 60000 - (now.seconds() * 1000 + now.milliseconds());
    this.actualMinute = now.minutes() + now.hours() * 60;

    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }

    this.timeSubscription = timer(timeTillNextMinute, 60000)
      .pipe(
        switchMap(() => {
          const currentTimeMinutes = this.getCurrentMinutes();
          const totalWorkTime = currentTimeMinutes - this.actualMinute + this.totalWorkTime;
          return [totalWorkTime];
        })
      )
      .subscribe((totalWorkTime) => {
        this.displayedTotalWorkTime = totalWorkTime;
      });
  }

  public async clockOut() {
    try {
      const canUpdate = await this.checkIfCanUpdateAndShowSnackbar();
      if (!canUpdate || this.loadingSoftRefresh) {
        return;
      }
      this.checkOutRequestLoading = true;
      this.userAttendanceForCurrentDay = (await this.getActualUserAttendanceForCurrentDay()) ?? null;

      if (!this.canEditAttendance) {
        this.checkOutRequestLoading = false;
        return;
      }

      const minutes = this.getCurrentMinutes();
      this.userAttendanceForCurrentDay.endTime =
        minutes < this.userAttendanceForCurrentDay.startTime ? minutes + this.MINUTES_IN_DAY : minutes;
      this.injector.get(UserAttendanceService).customBehaviors.injectCustomFieldsForClockOut(this.userAttendanceForCurrentDay);
      let userAttendanceForCurrentDayMinutes = calculateMinutesInGivenShift(this.userAttendanceForCurrentDay);

      const data = this.punchClockValidator(this.userAttendanceForCurrentDay, this.timeOffRequest, this.attendancePolicy);
      if (this.isOverlapping(this.userAttendanceForCurrentDay) || data) {
        await this.openSplitRequestDialog(_.cloneDeep(this.userAttendanceForCurrentDay));
        return;
      }

      if (
        this.totalAttendanceForCurrentDay + userAttendanceForCurrentDayMinutes > this.MAX_MINUTES_PER_DAY &&
        !this.conflicts.maxDailyHours
      ) {
        await this.openMaxDailyHoursDialog();
      } else if (userAttendanceForCurrentDayMinutes > this.MAX_SHIFT_LENGTH) {
        await this.openMaxShiftLengthDialog();
      }

      await this.injector.get(UserAttendanceService).updateById(this.userAttendanceForCurrentDay._id, {
        ...this.userAttendanceForCurrentDay,
        interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB,
      });

      const breakReminderConfig = await this.injector
        .get(BreakReminderController)
        .getConfiguration(this.userAttendanceForCurrentDay._userId, this.userAttendanceForCurrentDay.date);
      if (breakReminderConfig.openReminder) {
        await this.openBreakReminder(breakReminderConfig, this.userAttendanceForCurrentDay._userId, this.userAttendanceForCurrentDay.date);
      }
      await this.calculateMinutesInCurrentDay();
      await this.injector
        .get(UserAttendanceService)
        .autoDeductBreak(this.userAttendanceForCurrentDay, this.attendancePolicy, this.loggedUser?.profileKey);
      this.checkOutRequestLoading = false;
      this.hasOneShift = true;
    } catch {
      // Do nothing
    } finally {
      // Check if the previous day there was an open entry to update properly the chip "hh mm registered"
      await this.calculateMinutesInCurrentDay();
      if (check.assigned(this.openEntryInThePreviousDay)) {
        const attendance =
          (this.userAttendanceForCurrentDay?.endTime ?? 0) -
          (this.userAttendanceForCurrentDay?.startTime ?? 0) -
          (this.userAttendanceForCurrentDay?.breakTime ?? 0);
        const data = { duration: this.injector.get(DurationPipe).transform(attendance) };
        this.stepText[3] = this.injector.get(I18nDataPipe).transform(this.translation.resumeChipText, data);
        this.openEntryInThePreviousDay = undefined;
        this.showDailyTime = false;
      }

      if (this.attendanceSubscription) {
        this.attendanceSubscription.unsubscribe();
      }
    }
  }

  async onSelectOption(categoryId: string) {
    const selectedCategory = this.attendanceCategories.find((iCategory) => {
      return iCategory._id === categoryId;
    });
    await this.setCategory(selectedCategory);
  }

  public async setCategory(category: IAttendanceCategory): Promise<void> {
    if (!this.userAttendanceForCurrentDay?._id) {
      return;
    }

    const canUpdate = await this.checkIfCanUpdateAndShowSnackbar();
    if (!canUpdate) {
      return;
    }

    if (!category?._id && this.userAttendanceForCurrentDay.attendanceCategoryId) {
      await this.removeCategory();
      return;
    }

    if (
      category._id === this.userAttendanceForCurrentDay.attendanceCategoryId ||
      category._id === this.userAttendanceForCurrentDay.attendanceSubCategoryId
    ) {
      return;
    }

    const bodyToUpdate = {
      attendanceCategoryId: category._id,
      attendanceSubCategoryId: null,
    };
    this.userAttendanceForCurrentDay.attendanceCategoryId = category._id;

    if (category.parentCategoryId) {
      bodyToUpdate.attendanceSubCategoryId = category._id;
      bodyToUpdate.attendanceCategoryId = category.parentCategoryId;
      this.userAttendanceForCurrentDay.attendanceCategoryId = category.parentCategoryId;
      this.userAttendanceForCurrentDay.attendanceSubCategoryId = category._id;
    }

    try {
      this.cdr.detectChanges();
      await this.injector
        .get(UserAttendanceService)
        .updateById(this.userAttendanceForCurrentDay._id, { ...bodyToUpdate, interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB });
      const message = this.injector.get(I18nDataPipe).transform(this.translation.categorySavedSnackbar, { categoryName: category.name });
      this.injector.get(MatLegacySnackBar).open(message, 'OK', {
        duration: 10000,
      });
      this.userAttendanceForCurrentDay = (await this.getActualUserAttendanceForCurrentDay()) ?? null;
      this.setDefaultCategory();
    } catch (error) {}
  }

  private async removeCategory(): Promise<void> {
    try {
      await this.injector.get(UserAttendanceService).updateById(this.userAttendanceForCurrentDay._id, {
        attendanceCategoryId: null,
        attendanceSubCategoryId: null,
        interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB,
      });
      this.userAttendanceForCurrentDay.attendanceCategoryId = null;
      this.userAttendanceForCurrentDay.attendanceSubCategoryId = null;
      this.injector.get(MatLegacySnackBar).open(this.translation.categoryDeletedSnackbar, 'OK', {
        duration: 10000,
      });
      this.userAttendanceForCurrentDay = (await this.getActualUserAttendanceForCurrentDay()) ?? null;
    } catch (error) {}
  }

  async openBreakReminder(breakReminderConfig: IBreakReminderConfiguration, userId: string, date: Date) {
    const breaks = await this.injector
      .get(MatLegacyDialog)
      .open(BreakReminderDialog, {
        data: {
          breakReminderConfig,
          translations: this.breakReminderTranslations,
        },
      })
      .afterClosed()
      .toPromise();

    if (check.assigned(breaks)) {
      await this.injector
        .get(BreakReminderController)
        .distributeBreaks(userId, date, breaks, picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB);
    }
  }

  public async openSplitRequestDialog(userAttendance?: IUserAttendanceModel, breakStart?: number) {
    const response = await this.injector.get(UserAttendanceService).splitEntries(userAttendance, breakStart);
    let dialogRef;
    if (response.result === 'deleted') {
      const overlappingShift = this.returnFullyOverlappingShift(userAttendance, [...this.allUserAttendancesForCurrentDay]);

      await this.calculateMinutesInCurrentDay();

      this.checkOutRequestLoading = false;

      dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, {
        data: {
          titleText: this.splitRequestDialogTranslations.deletedEntryTitle,
          subtitleText: this.injector.get(I18nDataPipe).transform(this.splitRequestDialogTranslations.deletedEntrySubtitle, {
            startTime: convertToTime(overlappingShift.startTime),
            endTime: convertToTime(overlappingShift.endTime),
          }),
          confirmButtonText: this.splitRequestDialogTranslations.gotItButton,
        },
      });

      await dialogRef.afterClosed().toPromise();
    } else {
      await this.calculateMinutesInCurrentDay();

      this.checkOutRequestLoading = false;
      if (response.result === 'errors') {
        dialogRef = this.injector.get(MatLegacyDialog).open(OverlappingDialogComponent, {
          data: {
            titleText: this.splitRequestDialogTranslations.conflictDetected,
            subtitleText: this.splitRequestDialogTranslations.description,
            maxDailyHourSubtitle: this.splitRequestDialogTranslations.maxDailyHoursDesc,
            infoTooltipText: this.splitRequestDialogTranslations.infoTooltip,
            warningText: this.injector.get(I18nDataPipe).transform(this.splitRequestDialogTranslations.warningMaxDailyHours, {
              maxHours: this.injector.get(DurationPipe).transform(this.attendancePolicy.limitDailyHours.limit, false, true, true),
            }),
            automationText: this.splitRequestDialogTranslations.automationChip,
            originalRecordText: this.splitRequestDialogTranslations.originalRecord,
            modifiedRecordText: this.splitRequestDialogTranslations.modifiedRecord,
            originalRecords: response?.originalEntries,
            modifiedRecords: response?.modifiedEntries,
            confirmButtonText: this.splitRequestDialogTranslations.gotItButton,
          },
        });
      } else {
        dialogRef = this.injector.get(MatLegacyDialog).open(OverlappingDialogComponent, {
          data: {
            titleText: this.splitRequestDialogTranslations.overlappingTitle,
            subtitleText: this.splitRequestDialogTranslations.description,
            infoTooltipText: this.splitRequestDialogTranslations.infoTooltip,
            automationText: this.splitRequestDialogTranslations.automationChip,
            originalRecordText: this.splitRequestDialogTranslations.originalRecord,
            modifiedRecordText: this.splitRequestDialogTranslations.modifiedRecord,
            originalRecords: response?.originalEntries,
            modifiedRecords: response?.modifiedEntries,
            confirmButtonText: this.splitRequestDialogTranslations.gotItButton,
          },
        });
      }
      const userAttendanceForCurrentDay = this.userAttendanceForCurrentDay;
      dialogRef.afterClosed().subscribe(async () => {
        const breakReminderConfig = await this.injector
          .get(BreakReminderController)
          .getConfiguration(userAttendanceForCurrentDay._userId, userAttendanceForCurrentDay.date);

        if (breakReminderConfig.openReminder) {
          await this.openBreakReminder(breakReminderConfig, userAttendanceForCurrentDay._userId, userAttendanceForCurrentDay.date);
        }
        await this.injector
          .get(UserAttendanceService)
          .autoDeductBreak(userAttendanceForCurrentDay, this.attendancePolicy, this.loggedUser?.profileKey);
        await this.calculateMinutesInCurrentDay();
      });
    }
    this.breakRequestLoading = false;
  }

  async openAutoDeductDialog(iBreaks: IBreak[]) {
    const autoDeductedBreaks = _.flatMap(this.allUserAttendancesForCurrentDay, (iUserAttendance) => iUserAttendance.breaks).filter(
      (iBreak) => iBreak?.autoDeducted
    );
    const data = {
      attendancePolicy: this.attendancePolicy,
      break: autoDeductedBreaks[0],
    };

    await this.injector
      .get(MatLegacyDialog)
      .open(AutoDeductDialogComponent, {
        data,
      })
      .afterClosed()
      .toPromise();
  }

  public async openMaxShiftLengthDialog() {
    const previousClockedTime = calculateMinutesInGivenShift(this.userAttendanceForCurrentDay);

    this.userAttendanceForCurrentDay.endTime = returnNewAttendanceEndTime(this.userAttendanceForCurrentDay, {
      type: 'MAX_SHIFT_LENGTH',
      value: this.MAX_SHIFT_LENGTH,
    });

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, {
      data: {
        titleText: this.translation.maxShiftLengthExceeded,
        subtitleText: this.injector.get(I18nDataPipe).transform(this.translation.cantClockOutShift, {
          tracked: this.injector.get(DurationPipe).transform(previousClockedTime),
          max: this.injector.get(DurationPipe).transform(this.MAX_SHIFT_LENGTH),
          newTime: convertToTime(this.userAttendanceForCurrentDay.endTime),
        }),
        confirmButtonText: this.translation.confirmButton,
      },
    });

    await dialogRef.afterClosed().toPromise();
  }

  public async openMaxDailyHoursDialog(): Promise<void> {
    const previousClockedTime = calculateMinutesInGivenShift(this.userAttendanceForCurrentDay);
    const newEndTime = returnNewAttendanceEndTime(this.userAttendanceForCurrentDay, {
      type: 'MAX_HOURS_PER_DAY',
      value: this.MAX_MINUTES_PER_DAY,
      totalTrackedAttendance: this.totalAttendanceForCurrentDay,
    });
    const automation = this.injector
      .get(UserAttendanceService)
      .getMaxHoursPerDayAutomationInfo(this.userAttendanceForCurrentDay, newEndTime);

    this.userAttendanceForCurrentDay.endTime = newEndTime;
    this.userAttendanceForCurrentDay.automation = automation;

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, {
      data: {
        titleText: this.translation.maxDailyHoursExceeded,
        subtitleText: this.injector.get(I18nDataPipe).transform(this.translation.cantClockOutDailyHours, {
          tracked: this.injector.get(DurationPipe).transform(this.totalAttendanceForCurrentDay + previousClockedTime),
          max: this.injector.get(DurationPipe).transform(this.MAX_MINUTES_PER_DAY),
          newTime: convertToTime(this.userAttendanceForCurrentDay.endTime),
        }),
        confirmButtonText: this.translation.confirmButton,
      },
    });
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('Max daily hours limit triggered', { platform: 'Web', category: 'Attendance', subcategory1: 'Max Daily hours' });
    await dialogRef.afterClosed().toPromise();
  }

  public addNewTracking(): void {
    this.userAttendanceForCurrentDay = undefined;
    this.updateWidgetStep();
  }

  public async startBreak() {
    try {
      const canUpdate = await this.checkIfCanUpdateAndShowSnackbar();
      if (!canUpdate || this.loadingSoftRefresh) {
        return;
      }
      this.breakRequestLoading = true;
      this.userAttendanceForCurrentDay = (await this.getActualUserAttendanceForCurrentDay()) ?? null;

      if (!this.canEditAttendance) {
        this.breakRequestLoading = false;
        return;
      }

      let tmpEndTime = this.getCurrentMinutes();
      tmpEndTime = tmpEndTime < this.userAttendanceForCurrentDay.startTime ? tmpEndTime + this.MINUTES_IN_DAY : tmpEndTime;
      const clonedUserAttendance = _.cloneDeep(this.userAttendanceForCurrentDay);
      clonedUserAttendance.endTime = tmpEndTime;

      const data = this.punchClockValidator(clonedUserAttendance, this.timeOffRequest, this.attendancePolicy);
      if (this.isOverlapping(clonedUserAttendance) || data) {
        this.openSplitRequestDialog(clonedUserAttendance, this.getCurrentMinutes());
        return;
      }

      const minutesInShift = calculateMinutesInGivenShift(clonedUserAttendance);
      let limitReached = false;

      if (this.MAX_MINUTES_PER_DAY < this.totalAttendanceForCurrentDay + minutesInShift && !this.conflicts?.maxDailyHours) {
        const newEndTime = clonedUserAttendance.endTime - (this.totalAttendanceForCurrentDay + minutesInShift - this.MAX_MINUTES_PER_DAY);
        const automation = this.injector
          .get(UserAttendanceService)
          .getMaxHoursPerDayFromBreakAutomationInfo(clonedUserAttendance, newEndTime);
        clonedUserAttendance.endTime = newEndTime;
        this.userAttendanceForCurrentDay.endTime = clonedUserAttendance.endTime;
        this.userAttendanceForCurrentDay.automation = automation;
        limitReached = true;
        await this.openLimitExceededDialog('daily', minutesInShift, this.MAX_MINUTES_PER_DAY, clonedUserAttendance.endTime);
      } else if (this.MAX_SHIFT_LENGTH < minutesInShift) {
        clonedUserAttendance.endTime = clonedUserAttendance.endTime - (minutesInShift - this.MAX_SHIFT_LENGTH);
        this.userAttendanceForCurrentDay.endTime = clonedUserAttendance.endTime;
        limitReached = true;
        await this.openLimitExceededDialog('shift', minutesInShift, this.MAX_SHIFT_LENGTH, clonedUserAttendance.endTime);
      }

      if (limitReached) {
        await this.injector.get(UserAttendanceService).updateById(this.userAttendanceForCurrentDay._id, {
          ...this.userAttendanceForCurrentDay,
          interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB,
        });
        const breakReminderConfig = await this.injector
          .get(BreakReminderController)
          .getConfiguration(this.userAttendanceForCurrentDay._userId, this.userAttendanceForCurrentDay.date);

        if (breakReminderConfig.openReminder) {
          await this.openBreakReminder(
            breakReminderConfig,
            this.userAttendanceForCurrentDay._userId,
            this.userAttendanceForCurrentDay.date
          );
        }
        await this.injector
          .get(UserAttendanceService)
          .autoDeductBreak(this.userAttendanceForCurrentDay, this.attendancePolicy, this.loggedUser?.profileKey);
        await this.calculateMinutesInCurrentDay();
        return;
      }

      this.breakRequestLoading = true;

      let startBreakTime = this.getCurrentMinutes();
      if (this.userAttendanceForCurrentDay.startTime > startBreakTime) {
        startBreakTime += this.MINUTES_IN_DAY;
      }

      if (check.assigned(this.userAttendanceForCurrentDay.breaks)) {
        this.userAttendanceForCurrentDay.breaks.push({ start: startBreakTime });
      } else {
        this.userAttendanceForCurrentDay.breaks = [{ start: startBreakTime }];
      }

      await this.injector.get(UserAttendanceService).updateById(this.userAttendanceForCurrentDay._id, {
        ...this.userAttendanceForCurrentDay,
        interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB,
      });
      this.activeBreakTime = 0;
      await this.calculateMinutesInCurrentDay();
      await this.getDailyTime();
      if (this.attendanceSubscription) {
        this.attendanceSubscription.unsubscribe();
      }
    } catch {
      // Do nothing
    }

    this.breakRequestLoading = false;
  }

  private async openLimitExceededDialog(limit: 'daily' | 'shift', tracked: number, max: number, newTime: number) {
    const titleText = limit === 'shift' ? this.translation.maxShiftLengthExceeded : this.translation.maxDailyHoursExceeded;
    const subtitleTranslation = limit === 'shift' ? this.translation.cantCreateBreakShift : this.translation.cantCreateBreakDailyHours;
    const subtitleText = this.injector.get(I18nDataPipe).transform(subtitleTranslation, {
      tracked: this.injector.get(DurationPipe).transform(tracked),
      max: this.injector.get(DurationPipe).transform(max),
      newTime: convertToTime(newTime),
    });

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, {
      data: {
        titleText,
        subtitleText,
        confirmButtonText: this.translation.confirmButton,
      },
    });

    await dialogRef.afterClosed().toPromise();
  }

  public async finishBreak(): Promise<void> {
    const canUpdate = await this.checkIfCanUpdateAndShowSnackbar();
    if (!canUpdate || this.loadingSoftRefresh) {
      return;
    }
    this.breakRequestLoading = true;
    this.userAttendanceForCurrentDay = (await this.getActualUserAttendanceForCurrentDay()) ?? null;

    if (!this.canEditAttendance) {
      this.breakRequestLoading = false;
      return;
    }

    const unfinishedBreak = this.userAttendanceForCurrentDay.breaks.find((iBreak) => check.not.assigned(iBreak.end));

    unfinishedBreak.end = this.getCurrentMinutes();
    if (unfinishedBreak.end < unfinishedBreak.start || this.userAttendanceForCurrentDay.startTime > unfinishedBreak.start) {
      unfinishedBreak.end += this.MINUTES_IN_DAY;
    }

    this.userAttendanceForCurrentDay.breakTime = getBreakTime(this.userAttendanceForCurrentDay);
    this.userAttendanceForCurrentDay.startBreakTime = null;
    this.injector
      .get(UserAttendanceService)
      .updateById(this.userAttendanceForCurrentDay._id, {
        ...this.userAttendanceForCurrentDay,
        interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB,
      })
      .then(() => {
        return this.calculateMinutesInCurrentDay();
      })
      .then(() => {
        this.actualMinute = moment().minutes() + moment().hours() * 60;
        this.getDailyTime();
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        this.breakRequestLoading = false;
        this.startAttendanceInterval();
      });
  }

  public navigateToAttendancePage(): void {
    this.injector.get(Router).navigateByUrl(`cloud/attendance/my-attendance`);
  }

  public updateWidgetStep(): void {
    this.calculateCurrentTimeOffData(this.timeOffRequest);
    // Handle time off condition
    if (this.shouldSetTimeOffStep(this.currentTimeOff, this.attendancePolicy)) {
      this.widgetStep = this.WIDGET_STEP.TIME_OFF_STEP;
      return;
    }

    // Handle all attendance approved condition
    if (this.allAttendanceOfDayApproved) {
      this.widgetStep = this.WIDGET_STEP.APPROVED_STEP;
      return;
    }

    // Handle unassigned attendance for the current day
    if (check.not.assigned(this.userAttendanceForCurrentDay?._id)) {
      this.handleUnassignedAttendance();
      return;
    }

    // Handle unassigned start time
    if (check.not.assigned(this.userAttendanceForCurrentDay.startTime)) {
      this.setWidgetStepBasedOnOpenEntry();
      return;
    }

    // Handle unfinished breaks
    const unfinishedBreak = this.getUnfinishedBreak();
    if (this.shouldHandleUnfinishedBreak(unfinishedBreak)) {
      this.handleUnfinishedBreak();
      return;
    }

    // Handle entries with no or incorrect end time
    if (this.shouldSetOpenEntryStep()) {
      this.handleOpenEntryStep();
      return;
    }

    this.finalizeWidgetStepUpdate();
  }

  private shouldSetTimeOffStep(currentTimeOff: ITimeOffRequestModel, attendancePolicy: IAttendancePolicy): boolean {
    return (
      check.assigned(currentTimeOff) &&
      attendancePolicy.overlappingWithTimeOff.isActive &&
      !attendancePolicy.overlappingWithTimeOff?.conflicts &&
      check.not.assigned(this.openEntryInThePreviousDay) &&
      (calculateIsCloseEntry(this.userAttendanceForCurrentDay) || check.not.assigned(this.userAttendanceForCurrentDay?.startTime))
    );
  }

  private handleUnassignedAttendance(): void {
    this.userAttendanceForCurrentDay = returnEmptyAttendance(this.loggedUser);
    this.updateSummaryShift();
    if (this.isAttendanceDayCreated) {
      this.clockIn();
      return;
    }

    if (check.assigned(this.openEntryInThePreviousDay)) {
      this.userAttendanceForCurrentDay = Object.assign({}, this.openEntryInThePreviousDay);
    }
    this.setWidgetStepBasedOnOpenEntry();
  }

  private setWidgetStepBasedOnOpenEntry(): void {
    this.widgetStep = check.not.assigned(this.openEntryInThePreviousDay)
      ? this.WIDGET_STEP.CLOSED_ENTRY_STEP
      : this.WIDGET_STEP.OPENED_ENTRY_STEP;
  }

  private shouldHandleUnfinishedBreak(unfinishedBreak: any): boolean {
    return check.not.assigned(this.userAttendanceForCurrentDay?.endTime) && check.assigned(unfinishedBreak);
  }

  private handleUnfinishedBreak(): void {
    if (check.not.assigned(this.activeBreakTime)) {
      this.updateBreakTime();
    }
    this.updateSummaryShift();
    this.widgetStep = this.WIDGET_STEP.BREAK_TIME_STEP;
  }

  private shouldSetOpenEntryStep(): boolean {
    return (
      check.not.assigned(this.userAttendanceForCurrentDay?.endTime) ||
      this.userAttendanceForCurrentDay?.endTime < this.userAttendanceForCurrentDay?.startTime
    );
  }

  private handleOpenEntryStep(): void {
    this.userAttendanceForCurrentDay.endTime = undefined;
    this.updateSummaryShift();
    this.widgetStep = this.WIDGET_STEP.OPENED_ENTRY_STEP;
  }

  private finalizeWidgetStepUpdate(): void {
    this.updateSummaryShift();
    this.isAttendanceDayCreated = true;
    this.widgetStep = this.WIDGET_STEP.APPROVED_STEP;
  }

  private getCurrentAttendance(): number {
    let currentTimeMinutes = this.getCurrentMinutes();
    const startTimeMinutes = this.userAttendanceForCurrentDay?.startTime !== undefined ? this.userAttendanceForCurrentDay?.startTime : 0;
    const breakTimeMinutes = this.userAttendanceForCurrentDay?.breakTime !== undefined ? this.userAttendanceForCurrentDay?.breakTime : 0;

    // If the shift started on one day and we are on the next one
    if (startTimeMinutes > currentTimeMinutes) {
      currentTimeMinutes += this.MINUTES_IN_DAY;
    }
    return Math.abs(currentTimeMinutes - startTimeMinutes - breakTimeMinutes);
  }

  startAttendanceInterval(): void {
    const now = moment();
    const timeTillNextMinute = 60000 - (now.seconds() * 1000 + now.milliseconds());
    this.actualMinute = now.minutes() + now.hours() * 60;
    this.currentAttendanceTime = this.getCurrentAttendance();
    if (this.attendanceSubscription) {
      this.attendanceSubscription.unsubscribe();
    }

    this.attendanceSubscription = timer(timeTillNextMinute, 60000)
      .pipe(
        switchMap(() => {
          return [this.getCurrentAttendance()];
        })
      )
      .subscribe((currentAttendanceTime) => {
        this.currentAttendanceTime = currentAttendanceTime;
      });
  }

  public getCurrentDailyTime(): number {
    let currentTimeMinutes = this.getCurrentMinutes();
    return currentTimeMinutes - this.actualMinute + this.totalWorkTime;
  }
  private updateSummaryShift(): void {
    this.summaryShift.clockInTime = check.assigned(this.userAttendanceForCurrentDay)
      ? convertToTime(this.userAttendanceForCurrentDay.startTime)
      : undefined;
    this.summaryShift.clockOutTime = check.assigned(this.userAttendanceForCurrentDay)
      ? convertToTime(this.userAttendanceForCurrentDay.endTime)
      : undefined;
    this.summaryShift.breakTime = check.assigned(this.userAttendanceForCurrentDay) ? this.userAttendanceForCurrentDay.breakTime : undefined;
  }

  private updateFormattedCurrentTime(): void {
    const currentTime = moment().format('LT');
    [this.currentHour, this.currentMinutes] = currentTime.split(':');
    if (this.currentMinutes.includes('PM')) {
      this.currentMinutes = this.currentMinutes.replace(' PM', '');
      this.currentHour = this.currentHour !== '12' ? (Number(this.currentHour) + 12).toString() : this.currentHour;
    }
    if (this.currentMinutes.includes('AM')) {
      this.currentMinutes = this.currentMinutes.replace(' AM', '');
      this.currentHour = this.currentHour === '12' ? (Number(this.currentHour) - 12).toString() : this.currentHour;
    }
    const minutes = this.getCurrentMinutes();
    if (this.widgetStep === this.WIDGET_STEP.BREAK_TIME_STEP) {
      const unfinishedBreak = this.getUnfinishedBreak();
      this.activeBreakTime = minutes - unfinishedBreak?.start;
    }
    this.aMinuteHasPassed = minutes !== this.userAttendanceForCurrentDay?.startTime;
    const { isRestrictCheckIn, restrictTimeLimit, nextShiftDate } = restrictCheckIn(
      this.getCurrentMinutes(),
      this.loggedUser,
      this.attendanceSummaryResult?.expectedHoursByDay ?? {},
      this.attendancePolicy,
      this.userWorkSchedule
    );
    this.restrictTimeLimit = restrictTimeLimit;
    this.nextShiftDate = nextShiftDate;
    this.isRestrictCheckIn = isRestrictCheckIn;
  }

  private updateBreakTime(): void {
    const unfinishedBreak = this.getUnfinishedBreak();
    if (check.assigned(unfinishedBreak)) {
      const endBreakTime = this.getCurrentMinutes();
      this.activeBreakTime = endBreakTime - unfinishedBreak.start;
      return;
    }

    this.updateWidgetStep();
  }

  private getToday(): moment.Moment {
    return this.injector.get(InternationalizationService).getSanitizedUTCToTimeZone();
  }

  private getCurrentMinutes(): number {
    const hour = moment().format('LT');
    const minutes = convertToMinutes(hour);
    return minutes;
  }

  private async calculateMinutesInCurrentDay(noStepUpdate?: boolean): Promise<void> {
    const userAttendanceForCurrentDayQuery = {
      _userId: this.loggedUser['_id'],
      date: this.getToday().toISOString(),
      _deleted: false,
    };

    try {
      this.allUserAttendancesForCurrentDay = await this.injector.get(UserAttendanceService).find(userAttendanceForCurrentDayQuery);
    } catch (error) {
      // Do nothing
    }

    if (
      this.allUserAttendancesForCurrentDay?.length > 0 &&
      check.not.equal(this.loggedUser.profileKey, 'admin') &&
      check.not.equal(this.loggedUser.profileKey, 'hr-admin')
    ) {
      this.allAttendanceOfDayApproved = _.every(this.allUserAttendancesForCurrentDay, ['_approved', true]);
    }

    if (this.allUserAttendancesForCurrentDay?.length) {
      const withoutTimeOffRequests = this.allUserAttendancesForCurrentDay.filter(
        (attendance) => attendance._timeOffRequestId === undefined
      );

      if (check.nonEmptyArray(withoutTimeOffRequests)) {
        const sortedByDescendingStartTime = withoutTimeOffRequests.sort((a, b) => ((a.startTime ?? 0) < (b.startTime ?? 0) ? 1 : -1));
        const lastOpenShift = sortedByDescendingStartTime.find((iShift) => iShift.endTime === undefined || iShift.endTime === null);
        this.userAttendanceForCurrentDay = lastOpenShift !== undefined ? lastOpenShift : sortedByDescendingStartTime[0];
      }

      this.filteredAttendanceEntries = this.allUserAttendancesForCurrentDay.filter(
        (userAttendance: IUserAttendanceModel) =>
          check.not.assigned(this.userAttendanceForCurrentDay) || userAttendance._id !== this.userAttendanceForCurrentDay?._id
      );
      this.checkTotalAttendance(this.allUserAttendancesForCurrentDay);
    }

    if (noStepUpdate) {
      return;
    }

    this.updateWidgetStep();
  }

  private checkTotalAttendance(userAttendance: Array<any>): void {
    this.totalAttendanceForCurrentDay = 0;
    let totalAutoDeductedBreak = 0;

    userAttendance.forEach((iUserAttendance) => {
      if (
        check.assigned(iUserAttendance.startTime) &&
        check.assigned(iUserAttendance.endTime) &&
        (check.not.assigned(iUserAttendance._allowanceType) ||
          (check.assigned(iUserAttendance._allowanceType) && iUserAttendance._allowanceType !== 'Unpaid')) &&
        iUserAttendance.startTime < this.getCurrentMinutes()
      ) {
        const breakTime = check.assigned(iUserAttendance.breakTime) ? iUserAttendance.breakTime : 0;
        const attendance = iUserAttendance.endTime - iUserAttendance.startTime - breakTime;
        this.totalAttendanceForCurrentDay += attendance;
        totalAutoDeductedBreak += calculateAutoDeductedBreaksDuration(_.cloneDeep(iUserAttendance));
      }
    });
    const data = { duration: this.injector.get(DurationPipe).transform(this.totalAttendanceForCurrentDay) };
    this.stepText[3] = this.injector.get(I18nDataPipe).transform(this.translation.resumeChipText, data);
    this.totalAutoDeductedBreakTime = totalAutoDeductedBreak;
  }

  private isOverlapping(currentEntry: IUserAttendanceModel): boolean {
    const overlappingEntry = this.filteredAttendanceEntries.find((iShift: IUserAttendanceModel) => {
      if (this.customBehaviors.allowCheckoutBeforeAMinute) {
        return this.injector.get(UserAttendanceService).customBehaviors.checkOverlappingWithSeconds(currentEntry, iShift);
      }

      if (check.not.assigned(iShift.startTime) || check.not.assigned(iShift.endTime)) {
        return false;
      }

      if (currentEntry.startTime < iShift.endTime && currentEntry.startTime >= iShift.startTime) {
        return true;
      }

      if (
        (currentEntry.endTime >= iShift.startTime && currentEntry.endTime <= iShift.endTime) ||
        (currentEntry.startTime <= iShift.startTime && currentEntry.endTime >= iShift.endTime)
      ) {
        return true;
      }
    });

    return check.assigned(overlappingEntry);
  }

  private returnFullyOverlappingShift(
    currentEntry: IUserAttendanceModel,
    allEntriesOfTheDay: Array<IUserAttendanceModel>
  ): IUserAttendanceModel {
    const findFunction = this.customBehaviors.allowCheckoutBeforeAMinute
      ? this.injector.get(UserAttendanceService).customBehaviors.checkOverlappingStartAndEndWithSeconds
      : checkOverlapping;

    if (check.assigned(allEntriesOfTheDay)) {
      return allEntriesOfTheDay.find((iShift: IUserAttendanceModel) => {
        return findFunction(currentEntry, iShift);
      });
    }

    return this.filteredAttendanceEntries.find((iShift: IUserAttendanceModel) => {
      return findFunction(currentEntry, iShift);
    });
  }

  private setDefaultCategory() {
    if (!this.attendanceCategories?.length) {
      return;
    }

    this.defaultCategory = this.attendanceCategories.find((iCategory) => {
      return (
        iCategory.value === this.userAttendanceForCurrentDay?.attendanceCategoryId ||
        iCategory.value === this.userAttendanceForCurrentDay?.attendanceSubCategoryId
      );
    });
  }

  ngOnDestroy(): void {
    if (this.breakTimeInterval) {
      clearInterval(this.breakTimeInterval);
    }
    if (this.currentTimeOffInterval) {
      clearInterval(this.currentTimeOffInterval);
    }
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }

    if (this.attendanceSubscription) {
      this.attendanceSubscription.unsubscribe();
    }
  }

  // Check if the previous day there is an open time entry and this is greater than the current time. In that case is returned to be closed in the clock-out
  // If the previous day doesn't required to be closed, then this function returns "undefined"
  private async getOpenEntryInThePreviousDay(): Promise<IUserAttendanceModel> {
    try {
      const userAttendancePreviousDayQuery = {
        _userId: this.loggedUser._id,
        date: this.injector.get(InternationalizationService).getSanitizedUTCToTimeZone().subtract(1, 'day').toISOString(),
        _deleted: false,
        endTime: { $exists: false },
      };

      const [previousDayUserAttendance] = await this.injector.get(UserAttendanceService).find(userAttendancePreviousDayQuery);

      return this.MINUTES_IN_DAY - previousDayUserAttendance?.startTime + this.getCurrentMinutes() < this.OVERNIGHT_MARGIN_MINUTES
        ? previousDayUserAttendance
        : undefined;
    } catch {
      // Do nothing
    }
  }

  private async getActualUserAttendanceForCurrentDay(): Promise<IUserAttendanceModel> {
    if (!this.userAttendanceForCurrentDay?._id) {
      return;
    }
    return this.injector.get(UserAttendanceService).getById(this.userAttendanceForCurrentDay._id);
  }

  public async createNewShiftForCategory(category: IAttendanceCategory): Promise<void> {
    await this.clockOut();
    await this.clockIn(category);
  }

  private getUnfinishedBreak(): IBreak | null {
    return this.userAttendanceForCurrentDay?.breaks?.find((iBreak) => check.not.assigned(iBreak?.end));
  }

  async getExpectedHours(): Promise<IAttendanceSummaryResult> {
    const response = await this.injector
      .get(AttendanceSummaryController)
      .getExpectedHoursByUserMonth((this.loggedUser as IUserAccountModel)._id, moment().month(), moment().year(), true);

    return response;
  }

  async getTodayAttendanceConflicts() {
    this.attendanceConflictsToday = await this.injector.get(AttendanceConflictsService).findByDay(moment().toDate(), this.loggedUser._id);

    this.conflicts.maxDailyHours =
      this.attendancePolicy?.limitDailyHours?.conflicts ||
      this.attendanceConflictsToday.some((conflict) => !conflict.isResolved && conflict._type === 'max-daily-hours');
  }

  async getTodayTimeOff() {
    const bodyTimeOff = {
      status: {
        $nin: [TIME_OFF_REQUEST_STATUS_DECLINED, TIME_OFF_REQUEST_STATUS_CANCELLED, TIME_OFF_REQUEST_STATUS_CANCELLED_AFTER_PROCESSED],
      },
      _workTime: { $ne: 'Other' },
      _userId: this.loggedUser._id,
      from: { $lte: moment.utc(moment().format(this.DATE_FORMAT)).endOf('day').toDate() },
      to: { $gte: moment.utc(moment().format(this.DATE_FORMAT)).startOf('day').toDate() },
    };

    return await this.injector.get(TimeOffRequestService).find(bodyTimeOff);
  }

  private punchClockValidator(
    userAttendance: IUserAttendanceModel,
    timeOffRequest: ITimeOffRequestModel[],
    attendancePolicy: IAttendancePolicy
  ) {
    return (
      PunchClockValidator.checkCheckOut({
        currentAttendanceEntry: userAttendance as IUserAttendanceLib,
        timeOffRequests: timeOffRequest as ITimeOffRequestLib[],
        attendancePolicy: attendancePolicy as IAttendancePolicyLib,
      }) as any
    )?.data;
  }

  async openCommentDialog() {
    this.commentRequestLoading = true;
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditCommentDialog, {
      data: {
        translations: this.translation.editCommentDialog,
        comment: this.userAttendanceForCurrentDay.comment,
      },
    });

    const newComment = await dialogRef.afterClosed().toPromise();

    if (check.assigned(newComment)) {
      await this.injector.get(UserAttendanceService).updateById(this.userAttendanceForCurrentDay._id, {
        comment: newComment,
        interface: picklists.ATTENDANCE_INTERFACE_PUNCH_CLOCK_WEB,
      });
      this.userAttendanceForCurrentDay.comment = newComment;

      this.injector.get(MatLegacySnackBar).open(this.translation.commentSaved, 'OK', {
        duration: 5000,
      });
      this.userAttendanceForCurrentDay = (await this.getActualUserAttendanceForCurrentDay()) ?? null;
    }

    this.commentRequestLoading = false;
  }

  calculateCurrentTimeOffData(timeOffRequest: ITimeOffRequestModel[]) {
    this.currentTimeOff = computeCurrentTimeOff(timeOffRequest);
    if (
      this.currentTimeOff &&
      this.attendancePolicy.overlappingWithTimeOff.isActive &&
      !this.attendancePolicy.overlappingWithTimeOff?.conflicts
    ) {
      this.currentTimeOffToolTip = this.setCurrentTimeOffToolTip(this.currentTimeOff);
    }
  }

  setCurrentTimeOffToolTip(timeOffRequest: ITimeOffRequestModel) {
    let toolTip = '';
    let type: string;
    type = calculateTimeOffRequestPartOfDayForDate(moment.utc() as any, timeOffRequest as ITimeOffRequestLib);

    if (!type && timeOffRequest._policyType === POLICY_TYPE_HOUR) {
      type = `${moment(timeOffRequest.from).utc().format('HH:mm')} - ${moment(timeOffRequest.to).utc().format('HH:mm')}`;
    }
    toolTip = `${this.translation.breakInWithTimeOff}\n ${timeOffRequest?._timeOffTypeName} : ${type}`;

    return toolTip;
  }

  private async checkIfCanUpdateAndShowSnackbar(): Promise<boolean> {
    try {
      if (this.userAttendanceForCurrentDay?._updatedAt && this.userAttendanceForCurrentDay?._id) {
        const canUpdateAttendance = await this.injector
          .get(UserAttendanceService)
          .canUpdateAttendance(this.userAttendanceForCurrentDay?._updatedAt, this.userAttendanceForCurrentDay?._id);

        if (!canUpdateAttendance) {
          await this.showSnackbarAndRefresh();
        }
        return canUpdateAttendance;
      }
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, 'WidgetPunchClockComponent', 'checkIfCanUpdateAndShowSnackbar');
    }
  }

  private async showSnackbarAndRefresh(): Promise<void> {
    this.injector.get(MatLegacySnackBar).open(this.translation.yourShiftWasAlreadyChanged, '', {
      duration: 5000,
      panelClass: 'kenjo-error-snackbar',
    });
    await this.softDataRefresh();

    this.injector.get(PrivateAmplitudeService).logEvent('The entry has already been updated from another platform', {
      platform: 'Web',
      category: 'Attendance',
      subcategory1: 'Outdated time entry',
    });
  }

  private async softDataRefresh(): Promise<void> {
    this.loadingSoftRefresh = true;
    this.isAttendanceDayCreated = false;
    this.allUserAttendancesForCurrentDay = [];
    this.userAttendanceForCurrentDay = undefined;

    this.initCustomBehaviors();
    this.openEntryInThePreviousDay = await this.getOpenEntryInThePreviousDay();

    [this.timeOffRequest, this.userWorkSchedule, this.attendancePolicy, this.attendanceSummaryResult] = await Promise.all([
      this.getTodayTimeOff(),
      this.injector.get(UserWorkScheduleService).getById(this.loggedUser._id),
      this.injector.get(AttendancePolicyService).getAttendancePolicyByUserId(this.loggedUser._id),
      this.getExpectedHours(),
      this.initAttendanceLimitSettings(),
    ]);
    await this.calculateMinutesInCurrentDay();
    await this.getTodayAttendanceConflicts();
    this.userAttendanceForCurrentDay =
      this.allUserAttendancesForCurrentDay.find(({ _id }) => this.userAttendanceForCurrentDay?._id === _id) ?? undefined;

    this.setDefaultCategory();
    this.checkAttendanceEntries();
    this.updateFormattedCurrentTime();
    if (this.widgetStep === this.WIDGET_STEP.OPENED_ENTRY_STEP) {
      this.startAttendanceInterval();
    }
    this.updateWidgetStep();
    this.loadingSoftRefresh = false;
  }

  navigateAttendanceTab($event) {
    $event.preventDefault();
    this.injector.get(Router).navigate(['/cloud/attendance/my-attendance']);
  }
}
