import { Component, Input } from '@angular/core';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-text-area',
  templateUrl: 'input-text-area.component.html',
  styleUrls: ['input-text-area.component.scss']
})
export class InputTextAreaComponent extends InputAbstractComponent {
  @Input() rows: number = 3;
  @Input() placeholder: string = '';
}
