<div class="dialog-box">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <!-- TITLE -->
  <orgos-column-container centerColumns="true">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-text type="Headline1" *ngIf="!meetingCache.data._id">{{translation.dialogCreateMeeting}}</orgos-text>
      <orgos-text type="Headline1" *ngIf="meetingCache.data._id">{{translation.dialogEditMeeting}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <!-- SUBTITLE -->
  <orgos-column-container centerColumns="true" class="kenjo-mt-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-text *ngIf="wizardStep === 1" type="Headline4">{{translation.nameStep}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 2" type="Headline4">{{translation.templateStep}}</orgos-text>
      <orgos-text *ngIf="wizardStep === 3 && meetingCache.data.type !== ASYNC" type="Headline4">{{translation.dateStep}} </orgos-text>
      <orgos-text *ngIf="wizardStep === 3 && meetingCache.data.type === ASYNC" type="Headline4"> {{translation.dateStepAsync}}</orgos-text>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <!-- CONTENT -->
  <div class="kenjo-ph-60px kenjo-pt-20px mdd-content">
    <!-- ALERT IF YOU ARE EDITING A MEETING THAT IS SCHEDULED-->
    <orgos-page-message *ngIf="isScheduled" messageType="warning" [messageText]="translation.editMeetingAlreadyScheduled"> </orgos-page-message>

    <!-- STEP 1 - NEW MEETING NAME + DEPARTMENT -->
    <orgos-container *ngIf="wizardStep === STEP_DETAIL" class="kenjo-pt-40px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1"></orgos-column>
        <orgos-column size="2">
          <orgos-column-container>
            <orgos-column class="mdd-one-field">
              <orgos-input-text [model]="meetingCache" field="name" class="mdd-one-field" required="true" (validation)="titleValidation = $event" (modelChange)="isValidFormData()">
                <orgos-input-error class="mdd-one-field" *ngIf="titleValidation && titleValidation.getError('required')">{{translation.titleMandatory}} </orgos-input-error>
              </orgos-input-text>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-20px">
            <orgos-column class="mdd-one-field">
              <orgos-input-select [model]="meetingCache" [options]="departments" [enableClearButton]="true" field="departmentId" class="kenjo-mr-10px mdd-one-field"> </orgos-input-select>
            </orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>
    </orgos-container>

    <!-- STEP 2 - CHOOSE TEMPLATE -->
    <orgos-container *ngIf="wizardStep === STEP_TEMPLATE" class="kenjo-pt-40px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="mdd-one-field">
          <div *ngFor="let template of templates">
            <orgos-column-container class="kenjo-mb-10px kenjo-p-20px mdd-border-item kenjo-border-radius kenjo-cursor-pointer" (click)="templateSelected = template._id; isValidFormData()" [class.mdd-border-selected-item]="templateSelected === template._id" centerColumns="true">
              <orgos-column size="0" class="kenjo-pr-10px">
                <mat-icon *ngIf="template.isDefault && template.isDefault === true" class="mdd-default-icon"> blank_page_icon</mat-icon>
                <mat-icon *ngIf="!(template.isDefault && template.isDefault === true)" class="mdd-custom-icon"> tactical_icon</mat-icon>
              </orgos-column>
              <orgos-column size="1" *ngIf="templateIdToEdit !== template._id || template.isDefault">
                {{template.name}}
              </orgos-column>
              <orgos-column size="1" *ngIf="templateIdToEdit === template._id && !template.isDefault">
                <orgos-input-simple-text [(value)]="tempTemplateName" class="mdd-edit-template-name-input"> </orgos-input-simple-text>
              </orgos-column>
              <orgos-column size="1" class="mdd-template-creator" *ngIf="!templateIdToEdit && !templateIdToDelete && !template.isDefault && mapUsers && mapUsers[template._createdById]">
                <orgos-text size="BodyText" color="LightText">
                  {{translation.createdBy}} {{mapUsers[template._createdById].displayName}}
                </orgos-text>
              </orgos-column>

              <!-- EDIT & DELETE TEMPLATE ACTIONS -->
              <orgos-column size="0" class="kenjo-pl-40px kenjo-pr-5px" *ngIf="!template.isDefault && (template._createdById === loggedUser?._id || loggedUser?.profileKey === 'admin' || loggedUser?.profileKey === 'hr-admin') && !templateIdToEdit && !templateIdToDelete">
                <kenjo-icon [size]="20" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3" [matTooltip]="translation.editTemplateNameTooltip" (click)="editTemplateClick(template._id)">mode_edit</kenjo-icon>
              </orgos-column>
              <orgos-column size="0" class="kenjo-ph-5px" *ngIf="!template.isDefault && (template._createdById === loggedUser?._id || loggedUser?.profileKey === 'admin' || loggedUser?.profileKey === 'hr-admin') && !templateIdToEdit && !templateIdToDelete">
                <kenjo-icon [size]="20" class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757" [matTooltip]="translation.deleteTemplateTooltip" (click)="deleteTemplateClick(template._id)">delete</kenjo-icon>
              </orgos-column>
              <orgos-column size="0" *ngIf="!template.isDefault && templateIdToEdit === template._id">
                <orgos-column-container>
                  <orgos-column>
                    <orgos-button-raised [disabled]="!!tempTemplateName === false" (click)="cancelEditOfTemplate(template._id)" color="Neutral">{{translation.dialogCancelBtn}} </orgos-button-raised>
                  </orgos-column>
                  <orgos-column class="kenjo-pl-10px">
                    <orgos-button-raised [disabled]="!!tempTemplateName === false" (click)="editTemplate(template._id)" color="Success">{{translation.dialogSaveBtn}} </orgos-button-raised>
                  </orgos-column>
                </orgos-column-container>
              </orgos-column>
              <orgos-column size="0" *ngIf="!template.isDefault && templateIdToDelete === template._id">
                <orgos-column-container>
                  <orgos-column>
                    <orgos-button-raised (click)="cancelEditOfTemplate(template._id)" color="Neutral">{{translation.dialogCancelBtn}} </orgos-button-raised>
                  </orgos-column>
                  <orgos-column class="kenjo-pl-10px">
                    <orgos-button-raised (click)="deleteTemplate(template._id)" color="Danger">{{translation.dialogDeleteBtn}} </orgos-button-raised>
                  </orgos-column>
                </orgos-column-container>
              </orgos-column>
            </orgos-column-container>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-container>

    <!-- STEP 3 - DATE & TIME -->
    <orgos-container *ngIf="wizardStep === STEP_TIME" class="kenjo-pt-40px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1"></orgos-column>
        <orgos-column size="7">
          <orgos-column-container>
            <orgos-column class="mdd-one-field">
              <orgos-input-date-picker [model]="meetingCache" (change)="isValidFormData()" [label]="translation.date" field="startDate" [min]="TODAY" required="true" class="mdd-one-field">
                <orgos-input-error class="mdd-one-field" *ngIf="!meetingCache?.data?.startDate"> {{translation.startDateMandatory}}</orgos-input-error>
              </orgos-input-date-picker>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-10px">
            <orgos-column size="1">
              <orgos-input-simple-time [label]="translation.startTime" [(value)]="startTime" required="true" class="kenjo-mr-10px mdd-one-field">
                <orgos-input-error *ngIf="!startTime">{{translation.startTimeMandatory}}</orgos-input-error>
              </orgos-input-simple-time>
            </orgos-column>
            <orgos-column size="1">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0">
                  <orgos-input-checkbox [label]="translation.remindMeeting" [model]="meetingCache" field="remindMeeting" class="kenjo-ml-20px"></orgos-input-checkbox>
                </orgos-column>
                <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-ml-5px kenjo-font-color-success-00b72e" [matTooltip]="translation.remindMeetingTooltip" matTooltipPosition="above">help_outline</kenjo-icon>
              </orgos-column-container>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container class="kenjo-pt-20px" centerColumns="true">
            <!-- REPEAT MEETING OPTIONS -->
            <orgos-column size="1">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0">
                  <mat-slide-toggle [checked]="repeatMeeting" (change)="repeatMeetingClick($event.checked)"> </mat-slide-toggle>
                </orgos-column>
                <orgos-column size="1" class="kenjo-pl-5px">
                  {{translation.repeatMeeting}}
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="1" *ngIf="repeatMeeting === true">
              <div class="mdd-toggle-container">
                <div
                  *ngFor="let weekDay of WEEKDAYS_ARRAY; index as i"
                  class="itrc-toggle kenjo-mr-10px"
                  [class.itrc-checked]="meetingCache.data.repeatMeeting.weeklyRepetitions[weekDay] === true"
                  (click)="meetingCache.data.repeatMeeting.weeklyRepetitions[weekDay] = !meetingCache.data.repeatMeeting.weeklyRepetitions[weekDay]"
                >
                  <span>{{ translatedWeekdays[i] }}</span>
                </div>
              </div>
            </orgos-column>
            <orgos-column size="1" *ngIf="repeatMeeting === false" class="mdd-empty-weekdays"></orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>
    </orgos-container>
  </div>

  <!-- BUTTONS -->
  <orgos-column-container centerColumns="true" class="kenjo-pv-10px kenjo-mh-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="mdd-button-align">
          <div>
            <mat-icon class="mdd-cancel-button" (click)="leftButtonAction()"> {{wizardStep > 1 ? 'arrow_back_circle' : 'cancel_icon'}}</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText" *ngIf="wizardStep === 1 || isLoadTemplate === true"> {{translation.dialogCancelBtn}}</orgos-text>
          </div>
          <div>
            <orgos-text type="SecondaryText" *ngIf="wizardStep > 1 && isLoadTemplate === false"> {{translation.dialogBackBtn}}</orgos-text>
          </div>
        </orgos-column>
        <orgos-column size="1" class="mdd-button-align">
          <div>
            <mat-icon class="mdd-create-button" (click)="rightButtonAction()" [class.mdd-disabled-button]="!canBeSaved"> arrow_next_circle</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.mdd-disabled-button]="!canBeSaved" *ngIf="!((wizardStep === STEP_TEMPLATE && isLoadTemplate === true) || wizardStep === WIZARD_FINAL_STEP)"> {{translation.dialogNextBtn}}</orgos-text>
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.mdd-disabled-button]="!canBeSaved" *ngIf="(wizardStep === STEP_TEMPLATE && isLoadTemplate === true) || wizardStep === WIZARD_FINAL_STEP"> {{translation.dialogSaveBtn}}</orgos-text>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
