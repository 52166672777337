<orgos-column-container centerColumns="true" *ngIf="totalOfRecords > queryOptions.recordsPerPage" class="sppc-pagination">
  <orgos-column></orgos-column>
  <orgos-column size="0">
    <orgos-column-container centerColumns="true">
      <orgos-text orgosColumn="0" type="BodyText" color="LightText"> {{ parentTranslation.displayingLabel }} </orgos-text>
      <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px">
        <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
          <orgos-column-container centerColumns="true">
            <orgos-text orgosColumn="0" type="BodyText" color="NormalText" [matTooltip]="parentTranslation.numberOfRecordsToShowText" class="sppc-records-page"> {{ queryOptions.recordsPerPage }}</orgos-text>
            <orgos-column size="0">
              <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
            </orgos-column>
          </orgos-column-container>
        </div>
        <mat-menu #paginationMenu="matMenu">
          <button *ngFor="let page of recordsToShowSelector" mat-menu-item (click)="changeRecordsToShow(page)">
            <orgos-text orgosColumn="0" type="BodyText" color="LightText">{{ page }}</orgos-text>
          </button>
        </mat-menu>
      </orgos-column>
      <orgos-text orgosColumn="0" type="BodyText" color="LightText" class="kenjo-pl-10px"> {{ parentTranslation.outOfLabel }}</orgos-text>
      <orgos-text orgosColumn="0" type="BodyText" color="NormalText" class="kenjo-pl-10px">{{ totalOfRecords }} </orgos-text>
    </orgos-column-container>
  </orgos-column>
  <orgos-column size="0" class="kenjo-mh-40px">
    <orgos-column-container centerColumns="true">
      <orgos-column size="0">
        <mat-icon class="kenjo-mr-10px sppc-pagination-icon" [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }" (click)="moveToPage(PAGE_SELECTOR['first'])">first_page</mat-icon>
      </orgos-column>
      <orgos-column size="0">
        <mat-icon class="kenjo-mr-10px sppc-pagination-icon" [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }" (click)="moveToPage(PAGE_SELECTOR['previous'])">keyboard_arrow_left</mat-icon>
      </orgos-column>
      <orgos-text orgosColumn="0" type="BodyText" class="kenjo-ml-10px"> {{ queryOptions.page }} / {{ numberOfPages }}</orgos-text>
      <orgos-column size="0">
        <mat-icon class="kenjo-ml-10px sppc-pagination-icon" [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== numberOfPages }" (click)="moveToPage(PAGE_SELECTOR['next'])">keyboard_arrow_right</mat-icon>
      </orgos-column>
      <orgos-column size="0">
        <mat-icon class="kenjo-ml-10px sppc-pagination-icon" [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': queryOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== numberOfPages }" (click)="moveToPage(PAGE_SELECTOR['final'])">last_page</mat-icon>
      </orgos-column>
    </orgos-column-container>
  </orgos-column>
  <orgos-column></orgos-column>
</orgos-column-container>
