<div *ngIf="!loading && translations">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
    {{ parentTranslation.rulesHeader }}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{ parentTranslation.availability }}</span>
  </div>
  <orgos-column-container centerColumns="true" class="kenjo-mt-40px">
    <orgos-column size="2" class="kenjo-mr-40px">
      <orgos-column-container centerColumns="true">
        <orgos-column size="0" class="kenjo-font-size-16px">{{ translations.showAvailabilityTitle }}</orgos-column>
        <orgos-column size="0" class="kenjo-ml-10px">
          <mat-icon
            [matTooltip]="tooltipText"
            matTooltipPosition="above"
            class="kenjo-cursor-pointer kenjo-font-size-25px kenjo-font-color-warning-ffc107"
          >
            upgrade
          </mat-icon>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1" class="kenjo-ml-40px">
      <mat-slide-toggle
        name="show"
        [(ngModel)]="wishDaysPreferences.show"
        [disabled]="settingsPermissions?.edit_all === false"
        [checked]="wishDaysPreferences?.show && !showEntitlementIcon"
        (change)="showWishDays($event); logAmplitudeEvent()"
        [matTooltipDisabled]="settingsPermissions?.edit_all === true"
        [matTooltip]="parentTranslation.noPermissionsTooltip"
        matTooltipPosition="below"
      ></mat-slide-toggle>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container centerColumns="true">
    <orgos-column size="2" class="kenjo-font-color-light-text-757575 kenjo-mt-10px"
      >{{ translations.showAvailabilitySubtitle }}</orgos-column
    >
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>

  <!-- Wish days previews -->
  <orgos-column-container
    [centerColumns]="true"
    class="sspwd-preview-container kenjo-mv-10px"
    [ngClass]="wishDaysPreferences.type === SHIFT_PLAN_AVAILABILITY ? 'available' : 'not-available'"
  >
    <orgos-column class="kenjo-border-right sspwd-preview-column">
      <kenjo-icon [size]="18">event_available</kenjo-icon>
      <span class="kenjo-block kenjo-text-align-center">{{ dummyWishDayData.type }}</span>
    </orgos-column>
    <orgos-column class="kenjo-border-left sspwd-preview-column">
      <kenjo-icon [size]="18">event_available</kenjo-icon>
      <span class="kenjo-block kenjo-text-align-center">{{ dummyWishDayData.type }}</span>
      <div>
        <div>{{ dummyWishDayData.time.start }}</div>
        <div>{{ dummyWishDayData.time.end }}</div>
      </div>
    </orgos-column>
  </orgos-column-container>

  <!-- Settings box -->
  <orgos-column-container centerColumns="true">
    <orgos-column size="2">
      <orgos-container aspect="card" class="kenjo-pl-20px kenjo-pr-20px kenjo-pt-20px">
        <div class="kenjo-font-size-14px">{{ translations.whatDoYouPreferToSee }}</div>
        <mat-radio-group
          name="type"
          [disabled]="!wishDaysPreferences?.show || showEntitlementIcon"
          class="sspwd-radio kenjo-pb-20px"
          (change)="saveData()"
          [(ngModel)]="wishDaysPreferences.type"
          [checked]="wishDaysPreferences?.type ?? SHIFT_PLAN_AVAILABILITY"
        >
          <mat-radio-button class="kenjo-font-size-14px kenjo-mt-15px" [value]="SHIFT_PLAN_AVAILABILITY"
            >{{ translations.availability }}</mat-radio-button
          >
          <mat-radio-button class="kenjo-font-size-14px kenjo-mt-15px" [value]="SHIFT_PLAN_UNAVAILABILITY"
            >{{ translations.unavailability }}</mat-radio-button
          >
        </mat-radio-group>
        <div class="kenjo-font-size-14px sspwd-mb-10px">{{ translations.setReminder }}</div>
        <mat-checkbox
          (change)="saveData()"
          name="send"
          [disabled]="!wishDaysPreferences?.show || showEntitlementIcon"
          [checked]="wishDaysPreferences?.reminder?.send ?? 'false'"
          [(ngModel)]="wishDaysPreferences.reminder.send"
        >
          {{ translations.setReminderEvery }}
        </mat-checkbox>
        <orgos-input-simple-number
          [debounceNewValues]="true"
          name="value"
          [min]="1"
          [value]="wishDaysPreferences?.reminder?.value"
          [readOnly]="!wishDaysPreferences?.show || !wishDaysPreferences.reminder?.send"
          (valueChange)="saveData()"
          [(value)]="wishDaysPreferences.reminder.value"
          class="kenjo-ml-15px sspwd-small-picker"
        ></orgos-input-simple-number>
        <mat-select
          name="range"
          appearance="fill"
          class="kenjo-ml-10px sspwd-small-drop-down"
          [disabled]="!wishDaysPreferences?.show || !wishDaysPreferences.reminder?.send || showEntitlementIcon"
          (valueChange)="saveData()"
          [(value)]="wishDaysPreferences.reminder.range"
          [(ngModel)]="wishDaysPreferences.reminder.range"
          panelClass="below"
        >
          <mat-option *ngFor="let key of rangeKeys" [value]="key"> {{ range[key] }} </mat-option>
        </mat-select>
      </orgos-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
<div *ngIf="loading" class="sspwd-data-loading">
  <orgos-loading-spinner></orgos-loading-spinner>
</div>
