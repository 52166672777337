import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { SettingsShiftPlanTagDialog } from '@app/cloud-features/shift-plan/pages/settings-shift-plan/components/settings-shift-plan-tags/dialogs/add-edit-tag-dialog';
import {
  IShiftPlanTagSettingsModel,
  ShiftPlanTagSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-tag.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { processShiftplanPermissions } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';

@Component({
  selector: 'kenjo-settings-shift-plan-tags',
  templateUrl: 'settings-shift-plan-tags.html',
  styleUrls: ['settings-shift-plan-tags.scss'],
})
export class SettingsShiftPlanTags implements OnInit {
  dialogTranslation: any = {};
  dataLoaded: boolean = false;
  allTags: Array<IShiftPlanTagSettingsModel> = [];
  filteredTags: Array<IShiftPlanTagSettingsModel> = [];
  tagOptions: Array<ISelectOption> = [
    {
      name: 'Skills',
      value: 'skills',
    },
    {
      name: 'Information',
      value: 'information',
    },
    {
      name: 'Other',
      value: 'other',
    },
  ];
  tagOptionsTranslations = {
    skills: 'Skills',
    information: 'Information',
    other: 'Other',
  };
  i18n: any = {};
  mappedTagsPermissions: { [id: string]: boolean };
  canCreateTags: boolean;
  SHIFT_PLAN_TAG: string = 'shift-plan-tag';

  @Input() parentTranslation: any = {};
  @Input() myProfileId: string;

  constructor(
    private injector: Injector,
    public snackBar: MatLegacySnackBar,
    private dialog: MatLegacyDialog,
    private i18nDataPipe: I18nDataPipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData() {
    try {
      const [i18nPage, dialogTranslation] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-roles-tags'),
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-tag-dialog'),
        this.findAllTags(),
      ]);
      this.i18n.page = i18nPage;
      this.dialogTranslation = dialogTranslation;
      const tagPermission = await this.injector.get(ShiftPlanPermissionsService).getShiftplPermissionsByCollection('shift-plan-tag');
      this.canCreateTags = tagPermission?.create_all ?? false;
      this.mappedTagsPermissions = processShiftplanPermissions(this.allTags, tagPermission, this.myProfileId);
    } catch {
      this.i18n.page = {};
      this.dialogTranslation = {};
    }
  }

  private async findAllTags(): Promise<void> {
    try {
      const allTags = await this.injector.get(ShiftPlanTagSettingsService).getTags({ _id: { $ne: null } });
      this.allTags = allTags;
      this.filteredTags = allTags;
      this.dataLoaded = true;
    } catch {
      this.allTags = [];
      this.dataLoaded = false;
    }
  }

  public addTag(): void {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const tagDocument: GenericCacheModel = new GenericCacheModel(
      this.injector,
      { name: '', category: 'skills' },
      ShiftPlanTagSettingsService,
      loggedUser[fieldConstants.ID]
    );
    const data = {
      tag: tagDocument,
      isNewTag: true,
      dialogTranslation: this.dialogTranslation,
    };

    const dialogRef = this.dialog.open(SettingsShiftPlanTagDialog, { data });
    dialogRef.afterClosed().subscribe(async (newTag: any) => {
      try {
        if (check.not.assigned(newTag)) {
          return;
        }
        await this.injector.get(ShiftPlanTagSettingsService).create(newTag.tagData);
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('add tag', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Company setup' });
        this.findAllTags();
      } catch {
        this.findAllTags();
      }
    });
  }

  public editTag(tag: IShiftPlanTagSettingsModel): void {
    const disabled = this.mappedTagsPermissions[tag._id];
    if (disabled) {
      return;
    }

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const tagDocument: GenericCacheModel = new GenericCacheModel(
      this.injector,
      { [fieldConstants.ID]: tag[fieldConstants.ID], name: tag.name, category: tag.category },
      ShiftPlanTagSettingsService,
      loggedUser[fieldConstants.ID]
    );
    const data = {
      tag: tagDocument,
      isNewTag: false,
      dialogTranslation: this.dialogTranslation,
    };

    const dialogRef = this.dialog.open(SettingsShiftPlanTagDialog, { data });
    dialogRef.afterClosed().subscribe(async (editTag: any) => {
      try {
        if (check.not.assigned(editTag)) {
          return;
        }
        await this.injector.get(ShiftPlanTagSettingsService).updateById(editTag.tagData[fieldConstants.ID], editTag.tagData);
        this.findAllTags();
      } catch {
        this.findAllTags();
      }
    });
  }

  public deleteTag(tagDocument: IShiftPlanTagSettingsModel): void {
    const disabled = this.mappedTagsPermissions[tagDocument._id];
    if (disabled) {
      return;
    }

    const translationData = {
      name: tagDocument.name,
    };
    const confirmDeleteTransformed = this.i18nDataPipe.transform(this.dialogTranslation.confirmTagDeletionTitle, translationData);
    const tagId = tagDocument[fieldConstants.ID];

    const data = {
      titleText: confirmDeleteTransformed,
      subtitleText: this.dialogTranslation.confirmTagDeletionSubtitle,
      confirmButtonText: this.dialogTranslation.confirmDeleteButtonLabel,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.dialogTranslation.goBackButtonDialog,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe(async (confirm) => {
      try {
        if (confirm && confirm === true) {
          await this.injector.get(ShiftPlanTagSettingsService).deleteById(tagId);
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('delete tag', { category: 'Shiftplan', subcategory: 'Settings', subcategory2: 'Company setup' });
          this.findAllTags();
          const snackbarTransformed = this.i18nDataPipe.transform(this.dialogTranslation.snackbarSuccessTagDeleted, translationData);
          this.snackBar.open(snackbarTransformed, 'OK', { duration: 5000 });
        }
      } catch {
        this.findAllTags();
      }
    });
  }

  public setFilteredTagsToShow(recordsToShow: Array<IShiftPlanTagSettingsModel>): void {
    this.filteredTags = recordsToShow;
    this.cdr.detectChanges();
  }
}
