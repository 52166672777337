<div [formGroup]="parts" class="ictfc-container" [attr.data-mask-placeholder]="maskPlaceholder" [class.ictfc-container--masked]="maskPlaceholder">
  <input
    allowNumbersOnly
    class="kenjo-cursor-text"
    #hour
    class="ictfc-input-element-hour"
    formControlName="hour"
    size="3"
    maxlength="3"
    [placeholder]="shouldLabelFloat ? '--' : ''"
    (keydown)="onKeyPress('hour', $event)"
    (input)="onInput('hour')"
    (focus)="onFocus('hour')"
    (blur)="onValueChange('hour', true)"
  />
  <span class="ictfc-input-spacer">:</span>
  <input
    allowNumbersOnly
    class="kenjo-cursor-text"
    #minute
    class="ictfc-input-element-minute"
    formControlName="minute"
    size="2"
    maxlength="2"
    [placeholder]="shouldLabelFloat ? '--' : ''"
    (keydown)="onKeyPress('minute', $event)"
    (input)="onInput('minute')"
    (focus)="onFocus('minute')"
    (blur)="onValueChange('minute', true)"
  />
</div>
