<orgos-column-container
  [centerColumns]="true"
  [matTooltip]="parentTranslation.activeNotificationsTooltip"
  [matTooltipDisabled]="canSendNotifications"
  matTooltipPosition="above"
  [class.kenjo-font-color-disabled-c4c4c4]="!canSendNotifications"
  class="kenjo-mv-20px kenjo-p-10px kenjo-color-grey-background-f5f5f5 ntc-notify-box"
>
  <div orgosColumn="0" class="kenjo-cursor-pointer">
    <mat-slide-toggle [disabled]="!canSendNotifications || loading" [(ngModel)]="notificationToggle" (change)="onToggleChange($event.checked)"></mat-slide-toggle>
  </div>
  <div class="kenjo-pl-10px" orgosColumn="1">{{ parentTranslation.notifyEmployeesToggle }}</div>
</orgos-column-container>
