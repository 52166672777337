<orgos-dialog-container [title]="titleText" (clickCloseButton)="closeDialog()">
  <div *ngIf="subtitleText" class="kenjo-font-color-light-text-757575 kenjo-mb-30px">{{ subtitleText }}</div>

  <orgos-column-container (click)="navigateTo( $event, 'cloud/settings/structure/office')" class="kenjo-mt-5px kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-radius spca-button">
    <orgos-column size="0" class="kenjo-ml-15px kenjo-pv-10px" *ngIf="data.permissions.officePermissions">
      <mat-icon *ngIf="data.activated.locations > 0" class="kenjo-font-color-success-00b72e kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle</mat-icon>
      <mat-icon *ngIf="data.activated.locations === 0" class="kenjo-font-color-light-text-757575 kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle_outline</mat-icon>
    </orgos-column>
    <orgos-column class="kenjo-ml-15px kenjo-pv-10px" size="0" *ngIf="!data.permissions.officePermissions">
      <mat-icon *ngIf="data.activated.locations === 0" class="kenjo-font-color-light-text-757575 kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> blocked</mat-icon>
      <mat-icon *ngIf="data.activated.locations > 0" class="kenjo-font-color-success-00b72e kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle</mat-icon>
    </orgos-column>

    <orgos-column size="1" class="kenjo-ml-10px kenjo-pv-10px">
      <div class="kenjo-font-size-14px">{{data.translations.createOffice}}</div>
      <a target="_blank" class="kenjo-font-size-12px kenjo-font-color-primary-5993e3" [href]="data.translations.urlLocations"> {{ data.translations.learnMore }} </a>
    </orgos-column>
    <orgos-column size="0" class="kenjo-pv-10px kenjo-mr-15px" [matTooltip]="!data.permissions.officePermissions ? data.translations.noPermissions : ''">
      <orgos-action [color]="color" orgosColumn="0" [disabled]="!data.permissions.officePermissions" class="kenjo-m-5px kenjo-font-color-light-text-757575 spca-navigate-to" (click)="navigateTo( $event, 'cloud/settings/structure/office')" icon="open_in_new" iconSize="medium"></orgos-action>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container (click)="navigateTo($event, 'cloud/shift-plan/settings/working-areas')" class="spca-button kenjo-mt-5px kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-radius">
    <orgos-column size="0" class="kenjo-ml-15px kenjo-pv-10px" *ngIf="data.permissions.workingAreaPermissions">
      <mat-icon *ngIf="data.activated.workingAreas > 0" class="kenjo-font-color-success-00b72e kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle</mat-icon>
      <mat-icon *ngIf="data.activated.workingAreas === 0" class="kenjo-font-color-light-text-757575 kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle_outline</mat-icon>
    </orgos-column>
    <orgos-column class="kenjo-ml-15px kenjo-pv-10px" size="0" *ngIf="!data.permissions.workingAreaPermissions">
      <mat-icon *ngIf="data.activated.workingAreas === 0" class="kenjo-font-color-light-text-757575 kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> blocked</mat-icon>
      <mat-icon *ngIf="data.activated.workingAreas > 0" class="kenjo-font-color-success-00b72e kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle</mat-icon>
    </orgos-column>

    <orgos-column size="1" class="kenjo-ml-10px kenjo-pv-10px">
      <div class="kenjo-font-size-14px">{{data.translations.createWA}}</div>
      <a target="_blank" class="kenjo-font-size-12px kenjo-font-color-primary-5993e3" [href]="data.translations.urlSetUpShiftPlan"> {{ data.translations.learnMore }} </a>
    </orgos-column>
    <orgos-column size="0" class="kenjo-pv-10px kenjo-mr-15px" [matTooltip]="!data.permissions.workingAreaPermissions ? data.translations.noPermissions : ''">
      <orgos-action
        [color]="color"
        orgosColumn="0"
        [disabled]="!data.permissions.workingAreaPermissions"
        class="kenjo-m-5px kenjo-font-color-light-text-757575 spca-navigate-to"
        (click)="navigateTo( $event, 'cloud/shift-plan/settings/working-areas')"
        icon="open_in_new"
        iconSize="medium"
      ></orgos-action>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container (click)="navigateTo( $event, 'cloud/shift-plan/settings/roles')" class="spca-button kenjo-mt-5px kenjo-color-grey-background-lighter-f8f8f8 kenjo-border-radius">
    <orgos-column size="0" class="kenjo-ml-15px kenjo-pv-10px" *ngIf="data.permissions.rolePermissions">
      <mat-icon *ngIf="data.activated.roles > 0" class="kenjo-font-color-success-00b72e kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle</mat-icon>
      <mat-icon *ngIf="data.activated.roles === 0" class="kenjo-font-color-light-text-757575 kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle_outline</mat-icon>
    </orgos-column>
    <orgos-column class="kenjo-ml-15px kenjo-pv-10px" size="0" *ngIf="!data.permissions.rolePermissions">
      <mat-icon *ngIf="data.activated.roles === 0" class="kenjo-font-color-light-text-757575 kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> blocked</mat-icon>
      <mat-icon *ngIf="data.activated.roles > 0" class="kenjo-font-color-success-00b72e kenjo-font-size-22px kenjo-cursor-pointer kenjo-m-5px"> check_circle</mat-icon>
    </orgos-column>

    <orgos-column size="1" class="kenjo-ml-10px kenjo-pv-10px">
      <div class="kenjo-font-size-14px">{{data.translations.createRole}}</div>
      <a target="_blank" class="kenjo-font-size-12px kenjo-font-color-primary-5993e3" [href]="data.translations.urlSetUpShiftPlan"> {{ data.translations.learnMore }} </a>
    </orgos-column>
    <orgos-column size="0" class="kenjo-pv-10px kenjo-mr-15px" [matTooltip]="!data.permissions.rolePermissions ? data.translations.noPermissions : ''">
      <orgos-action [color]="color" orgosColumn="0" [disabled]="!data.permissions.rolePermissions" class="kenjo-m-5px kenjo-font-color-light-text-757575 spca-navigate-to" (click)="navigateTo( $event, 'cloud/shift-plan/settings/roles')" icon="open_in_new" iconSize="medium"></orgos-action>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column *ngIf="confirmButtonText" size="0" class="kenjo-ml-20px">
      <orgos-button-raised [color]="confirmButtonColor" (click)="confirmDialog()"> {{ confirmButtonText }} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
