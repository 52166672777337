import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import {
  IShiftPlanGeneralSettingsModel,
  ShiftPlanGeneralSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  SHIFT_PLAN_APPROVAL_RULE_AUTO_ASSING,
  SHIFT_PLAN_APPROVAL_RULE_REQUIRED,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';

@Component({
  selector: 'kenjo-settings-shift-plan-open-shifts',
  templateUrl: 'settings-shift-plan-open-shifts.html',
  styleUrls: ['settings-shift-plan-open-shifts.scss'],
})
export class SettingsShiftPlanOpenShifts implements OnInit {
  i18n: any = {};
  generalSettingsId: string;
  loading: boolean = true;
  options = [];
  openShiftSettings: { requiresApproval: boolean; autoAssign: boolean } = { requiresApproval: true, autoAssign: false };
  settingPermissions: { [key: string]: boolean };
  disable: boolean = false;
  matTooltip: string = '';
  shiftSettingsConst = {
    SHIFT_PLAN_APPROVAL_RULE_REQUIRED: SHIFT_PLAN_APPROVAL_RULE_REQUIRED,
    SHIFT_PLAN_APPROVAL_RULE_AUTO_ASSING: SHIFT_PLAN_APPROVAL_RULE_AUTO_ASSING,
  };

  @Input() generalSettings: IShiftPlanGeneralSettingsModel;

  @Input() parentTranslation: any = {};
  @Output() updatedSettings: EventEmitter<IShiftPlanGeneralSettingsModel> = new EventEmitter<IShiftPlanGeneralSettingsModel>();

  constructor(private injector: Injector, public snackBar: MatLegacySnackBar) {}

  ngOnInit(): void {
    this.loading = true;
    this.fetchData();
    this.loading = false;
  }

  private async fetchData(): Promise<void> {
    try {
      this.settingPermissions = await this.injector
        .get(ShiftPlanPermissionsService)
        .getShiftplPermissionsByCollection('shift-plan-general-settings');
      this.openShiftSettings = this.generalSettings.openShifts ?? { requiresApproval: true, autoAssign: false };
      this.generalSettingsId = this.generalSettings._id;

      this.i18n.page = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-open-shifts');
      this.setOptions();
    } catch {
      this.i18n.page = {};
    }
  }

  private setOptions() {
    this.options = [
      {
        title: this.i18n.page.requiresApproval,
        explanation: this.i18n.page.requiresApprovalText,
        key: this.shiftSettingsConst.SHIFT_PLAN_APPROVAL_RULE_REQUIRED,
        value: this.openShiftSettings.requiresApproval,
      },
      {
        title: this.i18n.page.automaticallyAssign,
        explanation: this.i18n.page.automaticallyAssignText,
        key: this.shiftSettingsConst.SHIFT_PLAN_APPROVAL_RULE_AUTO_ASSING,
        value: this.openShiftSettings.autoAssign,
      },
    ];
  }

  async changeOpenShiftSettings(key: string) {
    if (this.openShiftSettings[key] === false) {
      this.openShiftSettings[key] = true;
      await this.updateOpenShiftsSettings();
      return;
    }

    const otherPropertyName =
      key === this.shiftSettingsConst.SHIFT_PLAN_APPROVAL_RULE_REQUIRED
        ? this.shiftSettingsConst.SHIFT_PLAN_APPROVAL_RULE_AUTO_ASSING
        : this.shiftSettingsConst.SHIFT_PLAN_APPROVAL_RULE_REQUIRED;
    this.openShiftSettings[key] = !this.openShiftSettings[key];
    if (this.openShiftSettings[otherPropertyName] === false) {
      this.openShiftSettings[otherPropertyName] = true;
    }
    await this.updateOpenShiftsSettings();
  }

  public async updateOpenShiftsSettings(): Promise<void> {
    try {
      this.generalSettings.openShifts = this.openShiftSettings;
      await this.injector.get(ShiftPlanGeneralSettingsService).updateById(this.generalSettingsId, { openShifts: this.openShiftSettings });
      const snackBarMessage = this.i18n.page.openShiftsModal;
      this.showSnackbar(snackBarMessage);
      this.updatedSettings.emit(this.generalSettings);
    } catch {
      // do nothing
    }
  }

  public showSnackbar(snackBarMessage: string): void {
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }
}
