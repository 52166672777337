<ng-container *ngIf="loadingPage === false && allTemplates.length > 0">
  <div class="kenjo-text-align-right kenjo-pt-20px kenjo-ph-20px dtp-action-bar">
    <orgos-action (click)="uploadTemplate()" icon="add_circle" iconSize="large"> {{i18n.misc.uploadTemplateButtonTooltip}}</orgos-action>
  </div>

  <orgos-column-container wrap="true" class="dtp-templates-container kenjo-p-10px">
    <orgos-document-template-card *ngFor="let template of allTemplates" orgosColumn [template]="template" class="kenjo-m-10px"></orgos-document-template-card>
  </orgos-column-container>
</ng-container>

<div *ngIf="loadingPage === false && allTemplates.length === 0" class="kenjo-p-20px">
  <div class="kenjo-text-align-right">
    <orgos-action icon="add_circle" iconSize="large" (click)="uploadTemplate()">{{i18n.page.uploadTemplateButtonLabel}}</orgos-action>
  </div>
  <div class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noTemplatesTitle}}</div>
    <div class="kenjo-mt-5px kenjo-font-size-14px kenjo-font-color-light-text-757575">{{i18n.page.noTemplatesSubtitle}}</div>
  </div>
</div>
