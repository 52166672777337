<orgos-dialog-container
  class="uuwd-dialog-box"
  [title]="operation !== 'workScheduleTemplate' ? dialogTitle : translation.assignTemplateTitle"
  (clickCloseButton)="closeDialog()"
  [employees]="employeeAvatars"
>
  <div
    *ngIf="(operation === 'timeOffApprover' && timeOffApproverUsers?.length > 0)
    || (operation === 'reportsTo' && reportsToUsers && reportsToUsers.length > 0)
    || (operation !== 'reportsTo' && operation !== 'workScheduleTemplate' && workPlaces && workPlaces.length > 0)
    || (operation === 'workScheduleTemplate' && workScheduleTemplateOptions && workScheduleTemplateOptions.length > 0)"
  >
    <!-- Company, office or department -->
    <orgos-column-container
      *ngIf="operation !== 'reportsTo' && operation !== 'workScheduleTemplate'"
      centerColumns="true"
      class="kenjo-mt-5px"
    >
      <orgos-column *ngIf="workPlaces && workPlaces.length > 0">
        <orgos-input-simple-select
          [multiSelect]="operation === 'area' || operation === 'team' && workPlaceId !== null"
          [autoOrder]="true"
          [label]="translation.emptyFieldLabel[operation]"
          class="kenjo-block"
          [options]="workPlaces"
          [(value)]="workPlaceId"
        ></orgos-input-simple-select>
      </orgos-column>
      <kenjo-icon
        *ngIf="operation !== 'company' && workPlaceId !== null"
        [size]="18"
        orgosColumn="0"
        class="kenjo-ml-10px kenjo-cursor-pointer kenjo-font-color-light-text-757575"
        (click)="['area','team'].includes(operation) ? workPlaceId = [] : workPlaceId = null"
        [matTooltip]="operation && translation && translation.removeUserWorkTooltip && translation.removeUserWorkTooltip[operation] ? translation.removeUserWorkTooltip[operation] : ''"
        >clear</kenjo-icon
      >
    </orgos-column-container>

    <!-- Reports to -->
    <div *ngIf="operation === 'reportsTo'" class="kenjo-mt-5px">
      <orgos-input-simple-select
        *ngIf="reportsToActions && reportsToActions.length > 0"
        [label]="translation.reportsToActionLabel"
        class="kenjo-block"
        [options]="reportsToActions"
        [(value)]="reportsToSelectedAction"
      ></orgos-input-simple-select>
      <orgos-column-container
        centerColumns="true"
        *ngIf="reportsToSelectedAction === 'replaceCurrent'"
        class="kenjo-mt-40px kenjo-font-size-12px kenjo-font-color-light-text-757575"
      >
        <orgos-column size="0">{{translation.reportsToInfoMessage}}</orgos-column>
        <kenjo-icon
          orgosColumn="0"
          [size]="18"
          class="kenjo-ml-10px kenjo-font-color-success-00b72e"
          [matTooltip]="translation.reportsToHelpTooltip"
          >help_outline</kenjo-icon
        >
      </orgos-column-container>
      <orgos-input-search-user
        *ngIf="reportsToSelectedAction === 'replaceCurrent' && userWork"
        class="kenjo-mt-20px"
        [model]="userWork"
        field="reportsToId"
        [users]="reportsToUsers"
        [enableClearButton]="true"
        [showAvatar]="true"
      ></orgos-input-search-user>
      <kenjo-info-message
        class="kenjo-mb-40px"
        *ngIf="reportsToSelectedAction === 'removeCurrent'"
        messageType="primary"
        [messageText]="translation.removeReportsToInfoMessage"
      ></kenjo-info-message>
    </div>

    <!-- Time off approver -->
    <div *ngIf="operation === 'timeOffApprover'" class="kenjo-mt-5px">
      <orgos-input-simple-search-user
        [label]="translation.timeOffApproverLabel"
        class="kenjo-mt-20px"
        [users]="timeOffApproverUsers"
        [(value)]="userWork.data"
        (valueChange)="onTimeOffApproverChange($event)"
        [showAvatar]="true"
      ></orgos-input-simple-search-user>
    </div>

    <!-- Work schedule template -->
    <div *ngIf="operation === 'workScheduleTemplate' && workScheduleTemplateOptions && workScheduleTemplateOptions.length > 0">
      <orgos-input-simple-select
        [label]="translation.assignTemplatePickListLabel"
        class="kenjo-block kenjo-mt-5px"
        [options]="workScheduleTemplateOptions"
        [(value)]="workScheduleTemplateId"
        (valueChange)="changeWorkSchedule($event)"
      ></orgos-input-simple-select>
      <div *ngIf="workScheduleTemplateSelected" class="kenjo-mt-10px">
        {{translation.templateStarDate}}: {{workScheduleTemplateSelected.startDate | date}}
      </div>
      <div
        class="kenjo-mv-20px kenjo-border kenjo-border-radius"
        *ngIf="workScheduleTemplateSelected && workScheduleTemplateSelected.dayShifts && workScheduleTemplateSelected.dayShifts.length === 7"
      >
        <orgos-column-container
          class="kenjo-pv-10px kenjo-ph-20px kenjo-border-bottom kenjo-mb-10px uuwd-work-schedule-table-header kenjo-font-size-12px"
        >
          <orgos-column>{{translation.daysColumn | uppercase}}</orgos-column>
          <orgos-column>{{translation.expectedHoursColumn | uppercase}}</orgos-column>
        </orgos-column-container>
        <orgos-column-container
          class="kenjo-pb-10px kenjo-ph-20px"
          *ngFor="let day of workScheduleTemplateSelected.dayShifts;let dayCounter = index"
        >
          <orgos-column>{{translatedWeekdays[dayCounter]}}</orgos-column>
          <orgos-column>{{(day.minutes ? day.minutes : 0) | duration}}</orgos-column>
        </orgos-column-container>
      </div>
    </div>
  </div>

  <div *ngIf="operation === 'reportsTo' && (!reportsToUsers || (reportsToUsers && reportsToUsers.length === 0))">
    {{translation.noManagersToAssign}}
  </div>
  <div *ngIf="operation === 'timeOffApprover' && (!timeOffApproverUsers || (timeOffApproverUsers && timeOffApproverUsers.length === 0))">
    {{translation.noApproversToAssign}}
  </div>
  <div
    *ngIf="translation.noWorkPlacesToAssign && operation !== 'reportsTo' && operation !== 'workScheduleTemplate' && (!workPlaces || (workPlaces && workPlaces.length === 0))"
  >
    {{translation.noWorkPlacesToAssign[operation]}}
  </div>
  <div
    *ngIf="operation === 'workScheduleTemplate' && (!workScheduleTemplateOptions || (workScheduleTemplateOptions && workScheduleTemplateOptions.length === 0))"
  >
    {{translation.noTemplatesToAssign}}
  </div>

  <orgos-column-container class="kenjo-mb-10px kenjo-mt-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised (click)="closeDialog()" color="Neutral">{{translation.cancel}} </orgos-button-raised>
    </orgos-column>
    <orgos-column
      size="0"
      class="kenjo-ml-20px"
      *ngIf="(operation === 'timeOffApprover' && timeOffApproverUsers?.length > 0)
      || (operation === 'reportsTo' && reportsToUsers && reportsToUsers.length > 0)
      || (operation !== 'reportsTo' && operation !== 'workScheduleTemplate' && workPlaces && workPlaces.length > 0)
      || (operation === 'workScheduleTemplate' && workScheduleTemplateOptions && workScheduleTemplateOptions.length > 0)"
    >
      <orgos-button-raised
        *ngIf="operation !== 'workScheduleTemplate'"
        (click)="saveWorkPlace()"
        color="Success"
        [disabled]="saving || (!usersWork || (usersWork && usersWork.length === 0)) || ((operation === 'area' || operation === 'team') && workPlaceId.length < 1) || (operation === 'timeOffApprover' && selectedTimeOffApprover === '') || (operation === 'reportsTo' && reportsToSelectedAction === 'replaceCurrent' && !userWork?.data?.reportsToId)"
      >
        {{translation.save}}
      </orgos-button-raised>
      <orgos-button-raised *ngIf="operation === 'workScheduleTemplate'" (click)="saveTemplate()" color="Success" [disabled]="saving">
        {{translation.save}}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
