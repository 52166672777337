<div class="cc-canvas" #calendarContent (scroll)="scroll = { top: $event.target.scrollTop, left: $event.target.scrollLeft }; refreshDataOnChange()">
  <div class="cc-global-container" [style.height.px]="heighOfAllUsers" [style.width.px]="widthOfAllDates">
    <div class="cc-users-column" #usersColumnElement>
      <div class="cc-header">
        <orgos-column-container centerColumns="true" class="kenjo-pv-10px">
          <orgos-column></orgos-column>
          <orgos-column size="0">
            <mat-icon (click)="year = year - 1" class="kenjo-font-size-25px kenjo-font-color-primary-5993e3 kenjo-cursor-pointer kenjo-no-user-select"> keyboard_arrow_left</mat-icon>
          </orgos-column>
          <orgos-column size="0" class="kenjo-font-color-light-text-757575">
            {{ year }}
          </orgos-column>
          <orgos-column size="0">
            <mat-icon (click)="year = year + 1" class="kenjo-font-size-25px kenjo-font-color-primary-5993e3 kenjo-cursor-pointer kenjo-no-user-select"> keyboard_arrow_right </mat-icon>
          </orgos-column>
          <orgos-column></orgos-column>
        </orgos-column-container>
        <orgos-action (click)="goToday()">{{ i18n.today }}</orgos-action>
      </div>
      <div class="cc-content" #usersColumnContentElement>
        <div [style.height.px]="heightBeforeStartUser"></div>
        <div *ngFor="let user of usersToShow; let index = index; trackBy: trackUserFunc" class="cc-user-row" [style.height.px]="user.collapsed ? user.collapsedHeight : user.expandedHeight" (click)="user.collapsed = !user.collapsed">
          <orgos-column-container centerColumns="true" class="cc-user-info-container">
            <orgos-column size="0">
              <orgos-avatar [photoUrl]="user._photo?._url" [avatarName]="user.displayName" size="small"></orgos-avatar>
            </orgos-column>
            <orgos-column class="cc-user-info kenjo-ph-10px">
              <kenjo-truncate-text>{{ user.displayName }}</kenjo-truncate-text>
              <div class="kenjo-mt-10px">
                <div class="cc-day" [class.cc-working-day]="user.workSchedule.includes(0)" [matTooltip]="translatedWeekdays[0]" matTooltipPosition="above"></div>
                <div class="cc-day" [class.cc-working-day]="user.workSchedule.includes(1)" [matTooltip]="translatedWeekdays[1]" matTooltipPosition="above"></div>
                <div class="cc-day" [class.cc-working-day]="user.workSchedule.includes(2)" [matTooltip]="translatedWeekdays[2]" matTooltipPosition="above"></div>
                <div class="cc-day" [class.cc-working-day]="user.workSchedule.includes(3)" [matTooltip]="translatedWeekdays[3]" matTooltipPosition="above"></div>
                <div class="cc-day" [class.cc-working-day]="user.workSchedule.includes(4)" [matTooltip]="translatedWeekdays[4]" matTooltipPosition="above"></div>
                <div class="cc-day" [class.cc-working-day]="user.workSchedule.includes(5)" [matTooltip]="translatedWeekdays[5]" matTooltipPosition="above"></div>
                <div class="cc-day" [class.cc-working-day]="user.workSchedule.includes(6)" [matTooltip]="translatedWeekdays[6]" matTooltipPosition="above"></div>
              </div>
            </orgos-column>
            <orgos-column size="0">
              <mat-icon *ngIf="user.collapsed" class="kenjo-font-size-25px kenjo-font-color-primary-5993e3"> keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="!user.collapsed" class="kenjo-font-size-25px kenjo-font-color-primary-5993e3"> keyboard_arrow_up</mat-icon>
            </orgos-column>
          </orgos-column-container>
        </div>
      </div>
    </div>
    <div class="cc-calendar-column">
      <div class="cc-header">
        <div [style.width.px]="widthBeforeStartDate"></div>
        <div>
          <div class="cc-cells-container" [style.flex-basis.px]="cellWidth">
            <div *ngFor="let iDate of datesOfYearToShow; let dateIndex = index; trackBy: trackDateFunc" [style.min-width.px]="cellWidth" [style.max-width.px]="cellWidth" [class.cc-first-day-month]="iDate.date() === 1" [class.cc-today]="iDate.isSame(todayDate, 'day')">
              <div [style.visibility]="iDate.date() === 1 ? 'visible' : 'hidden'" class="kenjo-mt-10px kenjo-ml-10px kenjo-font-size-12px kenjo-font-color-light-text-757575">
                {{ iDate | date: 'MMMM':'UTC' }}
              </div>
              <div class="kenjo-mv-10px kenjo-font-size-12px cc-day-label">{{ iDate.format('dd') }}</div>
              <div class="kenjo-mb-10px kenjo-font-size-12px cc-day-label">{{ iDate.date() }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cc-content">
        <div [style.width.px]="widthBeforeStartDate"></div>
        <div>
          <div [style.height.px]="heightBeforeStartUser"></div>
          <div *ngFor="let user of usersToShow; let index = index; trackBy: trackUserFunc" class="cc-user-row" [style.height.px]="user.collapsed ? user.collapsedHeight : user.expandedHeight">
            <div class="cc-cells-container" [style.flex-basis.px]="cellWidth">
              <div
                *ngFor="let date of datesOfYearToShow; trackBy: trackDateFunc; let isLastCell = last"
                class="cc-cell"
                [class.cc-cell-weekend]="date.day() === 0 || date.day() === 6"
                [style.min-width.px]="isLastCell ? cellWidth * 2 : cellWidth"
                [style.max-width.px]="isLastCell ? cellWidth * 2 : cellWidth"
              >
                <div *ngIf="user.nonWorkingDates.includes(date.format('YYYY-MM-DD')) && !user.publicHolidays.includes(date.format('YYYY-MM-DD')) && (!user.events[date.format('YYYY-MM-DD')] || user.events[date.format('YYYY-MM-DD')].length === 0)" class="cc-cell-non-working"></div>
                <div *ngIf="user.publicHolidays.includes(date.format('YYYY-MM-DD'))" class="cc-cell-non-working" [matTooltip]="i18n.publicHolidays" matTooltipPosition="above"></div>
                <div *ngIf="user.collapsed && user.events[date.format('YYYY-MM-DD')] && user.events[date.format('YYYY-MM-DD')].length > 0" class="cc-cell-collapsed-events" [style.height.px]="user.collapsedHeight">
                  <div class="cc-collapsed-events-container" [style.height.px]="user.events[date.format('YYYY-MM-DD')].length * 20">
                    <div *ngFor="let iEvent of user.events[date.format('YYYY-MM-DD')]; trackBy: trackEventFunc" class="cc-collapsed-event-box" [class.cc-event-pending]="iEvent.pending">
                      <div *ngIf="iEvent.policyType !== 'Hour' && iEvent.dayDuration === 'FullDay'" class="cc-collapsed-event" [style.background-color]="iEvent.color" [matTooltip]="iEvent.title" matTooltipPosition="above" [class.cc-non-working-day]="iEvent.workTime === false"></div>
                      <div
                        *ngIf="iEvent.policyType !== 'Hour' && iEvent.dayDuration !== 'FullDay'"
                        class="cc-collapsed-event"
                        matTooltip="{{ iEvent.title }} ({{ iEvent.dayDuration === 'Morning' ? i18n.morning : i18n.afternoon }})"
                        matTooltipPosition="above"
                        [class.cc-non-working-day]="iEvent.workTime === false"
                      >
                        <div class="cc-event-morning" [style.background-color]="iEvent.dayDuration === 'Morning' ? iEvent.color : '#c4c4c4'"></div>
                        <div class="cc-event-afternoon" [style.background-color]="iEvent.dayDuration === 'Afternoon' ? iEvent.color : '#c4c4c4'"></div>
                      </div>
                      <div *ngIf="iEvent.policyType === 'Hour' && (iEvent.start.isSame(iEvent.end, 'day') && !iEvent.end.isSame(iEvent.start.clone().endOf('day')))" class="cc-collapsed-event" [matTooltip]="iEvent.title + '\n' + getFormattedHours(iEvent)" matTooltipPosition="above" [class.cc-non-working-day]="iEvent.workTime === false">
                        <div class="cc-event-morning" [style.background-color]="iEvent.color"></div>
                        <div class="cc-event-afternoon" [style.background-color]="'#c4c4c4'"></div>
                      </div>
                      <div *ngIf="iEvent.policyType === 'Hour' && (!iEvent.start.isSame(iEvent.end, 'day') || iEvent.end.isSame(iEvent.start.clone().endOf('day')))" class="cc-collapsed-event" [style.background-color]="iEvent.color" [matTooltip]="iEvent.title" matTooltipPosition="above" [class.cc-non-working-day]="iEvent.workTime === false">
                        <div class="cc-event-morning" [style.background-color]="iEvent.color"></div>
                        <div class="cc-event-afternoon" [style.background-color]="iEvent.color"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!user.collapsed && user.events[date.format('YYYY-MM-DD')] && user.events[date.format('YYYY-MM-DD')].length > 0">
                  <ng-container *ngFor="let iEvent of user.events[date.format('YYYY-MM-DD')]; trackBy: trackEventFunc">
                    <div
                      *ngIf="iEvent.start.isSame(date, 'day')"
                      class="cc-expanded-event"
                      [class.cc-event-pending]="iEvent.pending"
                      [style.top.px]="user.numberOfDifferentEventTypes < 2 ? 5 : iEvent.position * 40"
                      [style.width.px]="(iEvent.end.diff(iEvent.start, 'day') + 1) * cellWidth - 6"
                      [style.border-color]="iEvent.color"
                    >
                      <div *ngIf="!iEvent.pending" class="cc-expanded-event-confirmed" [style.background-color]="iEvent.color"></div>
                    </div>
                    <div
                      *ngIf="iEvent.end.isSame(date, 'day') && !iEvent.start.isSame(date, 'day')"
                      class="cc-expanded-event cc-expanded-event-end"
                      [class.cc-event-pending]="iEvent.pending"
                      [style.top.px]="user.numberOfDifferentEventTypes < 2 ? 5 : iEvent.position * 40"
                      [style.left.px]="-((iEvent.end.diff(iEvent.start, 'day') - 1) * cellWidth)"
                      [style.width.px]="iEvent.end.diff(iEvent.start, 'day') * cellWidth - 6"
                      [style.border-color]="iEvent.color"
                    >
                      <div *ngIf="!iEvent.pending" class="cc-expanded-event-confirmed" [style.background-color]="iEvent.color"></div>
                    </div>
                    <div
                      *ngIf="iEvent.policyType !== 'Hour' && iEvent.dayDuration === 'FullDay'"
                      class="cc-expanded-event-dot"
                      [style.top.px]="user.numberOfDifferentEventTypes < 2 ? 27 : iEvent.position * 40 + 22"
                      [style.left.px]="cellWidth / 2 - 4"
                      [style.background-color]="iEvent.color"
                      [matTooltip]="iEvent.title"
                      matTooltipPosition="above"
                      [class.cc-non-working-day]="iEvent.workTime === false"
                    ></div>
                    <div
                      *ngIf="iEvent.policyType !== 'Hour' && iEvent.dayDuration !== 'FullDay'"
                      class="cc-expanded-event-dot"
                      [style.top.px]="user.numberOfDifferentEventTypes < 2 ? 27 : iEvent.position * 40 + 22"
                      [style.left.px]="cellWidth / 2 - 4"
                      matTooltip="{{ iEvent.title }} ({{ iEvent.dayDuration === 'Morning' ? i18n.morning : i18n.afternoon }})"
                      matTooltipPosition="above"
                      [class.cc-non-working-day]="iEvent.workTime === false"
                    >
                      <div class="cc-event-morning" [style.background-color]="iEvent.dayDuration === 'Morning' ? iEvent.color : '#c4c4c4'"></div>
                      <div class="cc-event-afternoon" [style.background-color]="iEvent.dayDuration === 'Afternoon' ? iEvent.color : '#c4c4c4'"></div>
                    </div>
                    <div
                      *ngIf="iEvent.policyType === 'Hour' && (iEvent.start.isSame(iEvent.end, 'day') && !iEvent.end.isSame(iEvent.start.clone().endOf('day')))"
                      class="cc-expanded-event-dot"
                      [style.top.px]="user.numberOfDifferentEventTypes < 2 ? 27 : iEvent.position * 40 + 22"
                      [style.left.px]="cellWidth / 2 - 4"
                      [style.background-color]="iEvent.color"
                      [matTooltip]="iEvent.title + '\n' + getFormattedHours(iEvent)"
                      matTooltipPosition="above"
                      [class.cc-non-working-day]="iEvent.workTime === false"
                    >
                      <div class="cc-event-morning" [style.background-color]="'#c4c4c4'"></div>
                      <div class="cc-event-afternoon" [style.background-color]="iEvent.color"></div>
                    </div>
                    <div
                      *ngIf="iEvent.policyType === 'Hour' && (!iEvent.start.isSame(iEvent.end, 'day') || iEvent.end.isSame(iEvent.start.clone().endOf('day')))"
                      class="cc-expanded-event-dot"
                      [style.top.px]="user.numberOfDifferentEventTypes < 2 ? 27 : iEvent.position * 40 + 22"
                      [style.left.px]="cellWidth / 2 - 4"
                      [style.background-color]="iEvent.color"
                      [matTooltip]="iEvent.title"
                      matTooltipPosition="above"
                      [class.cc-non-working-day]="iEvent.workTime === false"
                    ></div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div [style.height.px]="calendarOffsetHeight">
            <div class="cc-cells-container" [style.flex-basis.px]="cellWidth">
              <div
                *ngFor="let date of datesOfYearToShow; trackBy: trackDateFunc; let isLastCell = last"
                class="cc-cell"
                [class.cc-cell-weekend]="date.day() === 0 || date.day() === 6"
                [style.min-width.px]="isLastCell ? cellWidth * 2 : cellWidth"
                [style.max-width.px]="isLastCell ? cellWidth * 2 : cellWidth"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
