import { Component, Input } from '@angular/core';
import * as check from 'check-types';
import isEmail from 'validator/es/lib/isEmail';

import { InputValidation } from '../../core/validation/input-validation';
import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-email',
  templateUrl: 'input-email.component.html',
  styleUrls: ['input-email.component.scss']
})
export class InputEmailComponent extends InputAbstractComponent {
  @Input() inputId: string;

  protected validateValue(newValue: any): InputValidation {
    const inputValidation = super.validateValue(newValue);

    if (check.assigned(newValue) && check.not.emptyString(newValue) && !isEmail(newValue)) {
      inputValidation.setError('email');
    }

    return inputValidation;
  }
}
