<div class="od-dialog-container">
  <kenjo-icon [size]="24" class="od-close-icon kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"
    >clear</kenjo-icon
  >
  <div class="kenjo-flex-start kenjo-flex-horizontal kenjo-flex-gap-10px kenjo-mb-30px">
    <div class="od-title kenjo-font-size-20px">{{ titleText }}</div>
    <kenjo-chip
      size="s"
      [semantic]="'neutral'"
      [type]="'secondary'"
      [text]="automationText"
      class="kenjo-mr-10px kenjo-font-size-14px app-tip"
    ></kenjo-chip>
  </div>
  <div *ngIf="!warningText" class="od-subtitle kenjo-font-color-light-text-757575">{{ subtitleText }}</div>
  <div *ngIf="warningText" class="od-subtitle kenjo-font-color-light-text-757575">
    <div>{{ subtitleText }}</div>
    <div class="kenjo-mt-10px">
      {{ maxDailyHourSubtitle }} <span class="kenjo-font-weight-bold">{{ warningText }}</span>
    </div>
  </div>
  <div class="od-overlapping-entries kenjo-mt-20px kenjo-mb-40px">
    <div class="od-original-records od-record-box">
      <div class="kenjo-font-size-18px kenjo-text-align-center">
        {{ originalRecordText }}
      </div>
      <div class="od-record-entries">
        <div class="od-record" *ngFor="let record of originalRecords">
          <div *ngIf="record.isTimeOff" class="od-time-off-circle" [style.backgroundColor]="userColors[record.color]"></div>
          <div *ngIf="!record.isTimeOff" class="od-time-off-circle"></div>
          <div class="kenjo-flex-center-content kenjo-flex-gap-4px">
            {{ record.text }}
            <kenjo-simple-truncate-text
              *ngIf="record.isTimeOff"
              class="kenjo-font-color-light-text-757575 od-truncate-text"
              >{{ record.timeOffTypeText }}</kenjo-simple-truncate-text
            >
          </div>
        </div>
      </div>
    </div>
    <kenjo-icon class="kenjo-font-color-light-text-757575 kenjo-flex-align-self-center">arrow_forward</kenjo-icon>
    <div class="od-modified-records od-record-box">
      <div class="kenjo-font-size-18px kenjo-text-align-center kenjo-flex-center-content kenjo-flex-gap-10px">
        {{ modifiedRecordText }}
        <kenjo-icon class="kenjo-font-color-primary-5993e3" [matTooltipPosition]="'above'" [matTooltip]="infoTooltipText" [size]="20"
          >error_outline</kenjo-icon
        >
      </div>
      <div class="od-record-entries">
        <div class="od-record" *ngFor="let record of modifiedRecords">
          <div *ngIf="record.isTimeOff" class="od-time-off-circle" [style.backgroundColor]="userColors[record.color]"></div>
          <div *ngIf="!record.isTimeOff" class="od-time-off-circle"></div>
          <div class="kenjo-flex-center-content kenjo-flex-gap-4px">
            {{ record.text }}
            <kenjo-simple-truncate-text
              *ngIf="record.isTimeOff"
              class="kenjo-font-color-light-text-757575 od-truncate-text"
              >{{ record.timeOffTypeText }}</kenjo-simple-truncate-text
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="od-wrap-buttons">
    <kenjo-button-raised
      *ngIf="cancelButtonText"
      [disabled]="cancelDisabled"
      color="Neutral"
      (click)="closeDialog()"
      class="od-close od-flex-shrink"
      >{{ cancelButtonText }}</kenjo-button-raised
    >
    <kenjo-button-raised
      [disabled]="confirmDisabled"
      [color]="confirmButtonColor"
      class="kenjo-ml-20px od-confirm"
      (click)="confirmDialog()"
    >
      {{ confirmButtonText }}
    </kenjo-button-raised>
  </div>
</div>
