import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

import { AuthenticationService } from '../core/authentication.service';

@Injectable()
export class UserDatevService implements IGenericService {
  private USER_DATEV_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-datev-db`;
  private USER_DATEV_INTERNATIONALIZATION: string = 'user-datev-collection';
  private USER_DATEV_PERMISSIONS_KEY: string = 'user-datev';

  constructor(
    private injector: Injector,
    private errorManager: ErrorManagerService,
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {}

  create(data: any, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async find(findQuery: any): Promise<any> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.USER_DATEV_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, UserDatevService.name, 'find');
        throw this.errorManager.handleRawError(error, UserDatevService.name, 'find');
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      const userDatev = await this.http.post(`${this.USER_DATEV_MICROSERVICE_URL}/find`, findQuery, httpOptions).toPromise();

      return userDatev;
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserDatevService.name, 'find');
    }
  }

  async getById(id: string, operationOptions?: any): Promise<any> {
    try {
      return await this.injector.get(GenericService).getById(this.USER_DATEV_MICROSERVICE_URL, id);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, UserDatevService.name, 'getById');
    }
  }

  async updateById(id: string, data: any, operationOptions?: any): Promise<void> {
    try {
      await this.injector.get(GenericService).updateById(this.USER_DATEV_MICROSERVICE_URL, id, data);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, UserDatevService.name, 'updateById');
    }
  }

  deleteById(id: string, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  getPermissions(operationOptions?: any): Promise<any> {
    return this.injector.get(GenericService).getPermissions(this.USER_DATEV_PERMISSIONS_KEY);
  }

  getFieldsTranslations(operationOptions?: any): Promise<any> {
    return this.injector.get(GenericService).getFieldsTranslations(this.USER_DATEV_INTERNATIONALIZATION);
  }

  getData?(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async getNumberPnrsInformed(): Promise<{ totalEmployees: number; employeesWithPnr: number }> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(
          this.USER_DATEV_MICROSERVICE_URL,
          ErrorCodes.UNAUTHORIZED,
          'UserDatevService',
          'getNumberPnrsInformed'
        );
        throw this.errorManager.handleRawError(error, 'UserDatevService', 'getNumberPnrsInformed');
      }

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.authenticationService.getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders,
      };

      const userDatev: { totalEmployees: number; employeesWithPnr: number } = await this.http
        .get<{ totalEmployees: number; employeesWithPnr: number }>(`${this.USER_DATEV_MICROSERVICE_URL}/number-pnrs-informed`, httpOptions)
        .toPromise();

      return userDatev;
    } catch (error) {
      throw this.errorManager.handleRawError(error, UserDatevService.name, 'find');
    }
  }
}
