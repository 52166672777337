import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { calculateShiftDurationPure } from '@app/cloud-features/shift-plan/helpers/shiftplan-card.helper';
import { filterLocationsPure, mapValuesFromRawCollection } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import { navigateToDate, setWeekDaysLabelPure } from '@app/cloud-features/shift-plan/helpers/shiftplan-schedule.helper';
import { DeleteShiftDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/delete-shift-dialog/delete-shift.dialog';
import {
  IUserShiftplanWorkSchedules,
  ShiftPlanEmployeeListService,
} from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import { ShiftPlanBreaktimeDeductionService } from '@app/cloud-features/shift-plan/services/settings-shift-plan-breaktime-deduction.service';
import { IShiftPlanDate, ShiftPlanCardService } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { ILocationOfficeModel } from '@app/standard/services/company/office.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import {
  SHIFT_PLAN_CARD_STATUS_DRAFT,
  SHIFT_PLAN_CARD_STATUS_PUBLISHED,
  SHIFT_PLAN_WEEKLY_VIEW,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-add-shift.dialog',
  templateUrl: 'add-shift.dialog.html',
  styleUrls: ['add-shift.dialog.scss'],
})
export class AddShiftDialog implements OnInit {
  weekDayLabels: Array<string>;
  selectedWeekDays: Array<boolean>;

  daysOfTheWeek: Array<moment.Moment> = [];

  shift: GenericCacheModel;
  shiftData: any;
  locations: Array<ISelectOption>;
  roles: Array<ISelectOption>;
  workingAreas: ISelectOption[] = [];
  tags: ISelectOption[] = [];
  allEmployees = [];
  filteredEmployees = [];
  everyoneElse = [];
  defaultEmployeeId: string;

  startTouched: boolean = false;
  endTouched: boolean = false;
  employeeTouched: boolean = false;
  dateValidation: InputValidation;
  startValidation: InputValidation;
  endValidation: InputValidation;
  locationValidation: InputValidation;
  roleValidation: InputValidation;
  repeatEndDateValidation: InputValidation;

  isOpenShift: boolean = true;
  isAssignShift: boolean = false;

  MINIMUM_DIFFERENCE_SHIFT_DURATION: number = 15;
  MAXIMUM_DIFFERENCE_SHIFT_DURATION: number = 1425;
  MINUTES_IN_A_DAY: number = 1440;
  MINUTES_IN_AN_HOUR: number = 60;
  SHIFT_PLAN_SETTINGS_ROLES_PATH: string = '/cloud/shift-plan/settings/roles';
  MY_COMPANY_SETTINGS_PATH: string = '/cloud/settings/structure/company';
  MY_OFFICE_SETTINGS_PATH: string = '/cloud/settings/structure/office';

  hasDurationMinErrors: boolean = false;
  hasDurationMaxErrors: boolean = false;
  hasBreakErrors: boolean = false;

  shiftDuration: { hours: number; minutes: number } = { hours: 0, minutes: 0 };
  isShiftReady: boolean = false;
  isDialogDisabled: boolean = false;

  pageTranslation: { [key: string]: string };
  loading: boolean = false;
  loadingBulk: boolean = false;
  shiftMaxReached: boolean = false;

  dateRange: IShiftPlanDate | null;
  isSomeDaySelected: boolean;
  readOnly: boolean = false;
  editMode: boolean = false;
  editDataTouched: boolean = false;
  editModeInitialDate: Date;
  employeesWorkSchedule: IUserShiftplanWorkSchedules;
  SHIFT_PLAN_CARD_STATUS_DRAFT: string = SHIFT_PLAN_CARD_STATUS_DRAFT;
  SHIFT_PLAN_CARD_STATUS_PUBLISHED: string = SHIFT_PLAN_CARD_STATUS_PUBLISHED;

  notMatchedEntitiesMap;
  sameWeekEdit: boolean;
  shiftplanUserSearchIds: { [key: string]: string } = {};
  initialEmployeeId: Array<string>;
  maxEmployeesReached: boolean = false;
  activeLocations: Array<ILocationOfficeModel> = [];
  initialShiftData: any;
  notificationToggle: boolean = true;
  canSendNotifications: boolean = false;
  isEmployeeDataLoaded: boolean = false;
  viewAdvancedOptions: boolean = false;
  areAdvancedOptionsValid: boolean = false;
  frequencyOptions: Array<ISelectOption>;
  frequencySelected: number = 1;
  FREQUENCY_CHANGE_LIMIT = { LOW: 8, MID: 16 };
  YEAR_LIMIT = { LOW: 1, MID: 3, HIGH: 5 };
  DATE_VALUES = { BEFORE: -1, AFTER: 1 };
  repeatEndDate: Date;
  minDayToCreate: moment.Moment;
  maxDayToCreate: moment.Moment;
  dialogCloseLoading: MatLegacyDialogRef<ConfirmDialogComponent, any>;
  timeOut: NodeJS.Timeout;
  LOADING_SCREEN_TIMEOUT: number = 2500;

  breaktimeDeduction: boolean = false;
  shiftplanPermissions: IShiftPlanPermissions;
  actionPermissions = { edit: false, delete: false };
  approvalRuleOptions: Array<ISelectOption>;
  approvalRuleSelected: 'requiresApproval' | 'autoAssign';
  currentOwner: IUserPersonalModel | null;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddShiftDialog>,
    public injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private dialogInfo: any,
    private datePipe: DatePipe,
    public snackBar: MatLegacySnackBar,
    private confirmDialog: MatLegacyDialog,
    protected router: Router
  ) {
    this.dialogRef.disableClose = true;
    this.daysOfTheWeek = this.dialogInfo.days;
    this.canSendNotifications = this.dialogInfo.notificationSetting;
    this.employeesWorkSchedule = this.dialogInfo.employeesWorkSchedule;
    this.dateRange = { from: this.dialogInfo.startDay, to: this.dialogInfo.endDay };
    this.shiftplanUserSearchIds = {
      enableSearchingInput: 'asd-enable-searching',
      inputSearch: 'asd-input-search',
      resultsBox: 'asd-results-box',
    };
  }
  ngOnInit(): void {
    this.getPermissions();
    this.setWeekDaysLabel();
    this.getBreakTimeDeductionValue();
    this.selectedWeekDays = this.weekDayLabels.map(() => false); // Initialize all days to not checked
    this.filterLocations();
    if (this.dialogInfo.readOnly === true) {
      this.initReadOnlyModeData();
    } else {
      this.initData();
    }
    const shiftRawData = check.assigned(this.dialogInfo.shift)
      ? this.createShiftFromData()
      : this.createShiftDataFromDate(moment(), this.dialogInfo.selectedData);
    this.shift = new GenericCacheModel(this.injector, shiftRawData, ShiftPlanCardService, '');
    this.shift.canEdit = Promise.resolve(true);
  }

  async getPermissions() {
    this.shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
  }

  async getUserWorkSchedules() {
    this.employeesWorkSchedule = await this.injector
      .get(ShiftPlanEmployeeListService)
      .getEmployeesSchedules(this.dateRange.from, this.dateRange.to, SHIFT_PLAN_WEEKLY_VIEW);
  }

  private async initReadOnlyModeData(): Promise<void> {
    this.readOnly = true;
    this.dialogRef.disableClose = false;
    this.shiftData = this.dialogInfo.shift;
    this.defaultEmployeeId = this.dialogInfo.selectedData?.employee;
    this.pageTranslation = await this.fetchTranslation();
    this.initApprovalRulesOptions();
    await this.getBreakTimeDeductionValue();
    const minutesDuration =
      this.shiftData.end > this.shiftData.start
        ? this.shiftData.end - this.shiftData.start
        : this.shiftData.end + this.MINUTES_IN_A_DAY - this.shiftData.start;
    const totalMinutesDuration = this.breaktimeDeduction ? minutesDuration - (this.shiftData.break ?? 0) : minutesDuration;
    this.shiftDuration = {
      hours: Math.floor(totalMinutesDuration / this.MINUTES_IN_AN_HOUR),
      minutes: totalMinutesDuration % this.MINUTES_IN_AN_HOUR,
    };

    this.isEmployeeDataLoaded = true;
    this.isOpenShift = !this.shiftData.employeeId;

    if (this.isOpenShift === true) {
      this.currentOwner = await this.injector.get(UserPersonalService).getById(this.shiftData.ownerId);
    }
    this.setEditDeletePermissions();
  }

  setEditDeletePermissions() {
    this.actionPermissions.edit =
      ((!this.shiftData.employeeId && this.shiftplanPermissions['open'].edit === true) ||
        (this.shiftData.employeeId && this.shiftplanPermissions['scheduled'].edit === true)) ??
      false;
    this.actionPermissions.delete =
      ((!this.shiftData.employeeId && this.shiftplanPermissions['open'].delete === true) ||
        (this.shiftData.employeeId && this.shiftplanPermissions['scheduled'].delete === true)) ??
      false;
  }

  public async initEditModeData(): Promise<void> {
    if (this.loading || this.actionPermissions.edit === false) {
      return;
    }

    this.isAssignShift = false;
    this.readOnly = false;
    this.dialogRef.disableClose = true;
    this.editMode = true;
    this.editModeInitialDate = this.shiftData.date;
    await this.getData();
    this.defaultEmployeeId = this.dialogInfo.selectedData?.employee;
    this.calculateShiftDuration();
    this.filterEmployees();
    this.injector.get(PrivateAmplitudeService).logEvent(this.isOpenShift ? 'Edit unpub open shift' : 'Edit unpub scheduled shift', {
      category: 'Shiftplan',
      subcategory: 'Scheduling',
      subcategory2: this.isOpenShift ? 'Open shift' : 'Scheduled shift',
    });
  }

  public async initData(): Promise<void> {
    if (check.assigned(this.dialogInfo.selectedDayIndex)) {
      this.selectedWeekDays[this.dialogInfo.selectedDayIndex] = true;
      this.isSomeDaySelected = true;
    }
    await this.getData();
    this.initApprovalRulesOptions();
    this.defaultEmployeeId = this.dialogInfo.selectedData.employee;
    this.filterEmployees();
    this.isEmployeeDataLoaded = true;
  }

  private async getBreakTimeDeductionValue() {
    try {
      this.breaktimeDeduction = (await this.injector.get(ShiftPlanBreaktimeDeductionService).getBreaktimeDeduction()).breaktimeDeduction;
    } catch {
      this.breaktimeDeduction = false;
    }
  }

  async getData() {
    this.loading = false;
    this.pageTranslation = await this.fetchTranslation();
    this.roles = mapValuesFromRawCollection(this.dialogInfo.response.roles);
    this.workingAreas = mapValuesFromRawCollection(this.dialogInfo.response.workingAreas);
    this.tags = mapValuesFromRawCollection(this.dialogInfo.response.tags);
    this.allEmployees = this.dialogInfo.response.users;
    this.filteredEmployees = this.allEmployees;
    this.isDialogDisabled = this.roles.length === 0 || this.locations.length === 0;
  }

  async fetchTranslation() {
    const pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-settings-add-shift-dialog');
    return { ...pageTranslation, noPermissionsTooltip: this.dialogInfo?.noPermissionsTooltip };
  }

  async createShift() {
    try {
      this.loading = true;
      const createShiftPayload = this.createShiftBody();
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent(createShiftPayload.employees.length === 0 ? 'added unpublished open shift' : 'added unpublished scheduled shift', {
          category: 'Shiftplan',
          subcategory: 'Scheduling',
          subcategory2: createShiftPayload.employees.length === 0 ? 'Open shift' : 'Scheduled shifts',
        });

      await this.showLoadingScreenAndCreate(createShiftPayload);
    } catch {
      // do nothing
    }
  }

  async editShift() {
    if (this.loading || this.actionPermissions.edit === false) {
      return;
    }

    try {
      this.loading = true;
      const shiftToEdit = this.createShiftBodyToEdit();
      const shiftId = this.shift.data._id;

      const eventsToLogPromises = [];
      if (this.dialogInfo.notificationSetting) {
        eventsToLogPromises.push(
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('Push notification sent', { category: 'Shiftplan', subcategory: 'Notifications' })
        );
      }
      if (this.checkAssignEmployeeAmplitudeEvent(this.initialShiftData, shiftToEdit)) {
        eventsToLogPromises.push(
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent('Open shift applicant assigned', { category: 'Shiftplan', subcategory: 'Publishing', subcategory2: 'Open shifts' })
        );
      }

      await this.injector.get(ShiftPlanCardService).updateById(shiftId, shiftToEdit, this.notificationToggle);
      this.loading = false;
      this.snackBar.open(this.pageTranslation.snackbarSuccessShiftEdited, 'OK', { duration: 5000 });
      this.dialogRef.close(true);
      await Promise.all(eventsToLogPromises);
    } catch {
      this.loading = false;
    }
  }

  private async checkAssignEmployeeAmplitudeEvent(oldShiftData, newShiftData) {
    const { employeeId: oldEmployeeId, status, applicants } = oldShiftData;
    const { employeeId: newEmployeeId } = newShiftData;
    return status === this.SHIFT_PLAN_CARD_STATUS_PUBLISHED && check.not.assigned(oldEmployeeId) && applicants.includes(newEmployeeId);
  }

  removeShift(): void {
    if (this.loading || this.actionPermissions.delete === false) {
      return;
    }

    if (this.shiftData?.status === this.SHIFT_PLAN_CARD_STATUS_DRAFT) {
      this.removeShiftDraft();
    }
    if (this.shiftData?.status === this.SHIFT_PLAN_CARD_STATUS_PUBLISHED) {
      this.removeShiftPublished();
    }
  }

  removeShiftDraft(): void {
    const shiftId = this.editMode === true ? this.shift.data._id : this.shiftData._id;

    const data = {
      titleText: this.pageTranslation.confirmDeleteShiftTitle,
      subtitleText: this.pageTranslation.editLeaveIntentSubtitle,
      confirmButtonText: this.pageTranslation.confirmDeleteShiftButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.pageTranslation.backButton,
      confirmButtonId: 'dsd-delete-draft-button',
      cancelButtonId: 'dsd-cancel-draft-button',
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe(async (confirm) => {
      try {
        if (confirm === true) {
          await this.injector.get(ShiftPlanCardService).deleteAndNotify(shiftId);
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent(this.isOpenShift ? 'delete unpublished open shift' : 'delete unpublished scheduled shift ', {
              category: 'Shiftplan',
              subcategory: 'Scheduling',
              subcategory2: this.isOpenShift ? 'Open shift' : 'Scheduled shift',
            });
          this.snackBar.open(this.pageTranslation.snackbarSuccessShiftDeleted, 'OK', { duration: 5000 });
          this.dialogRef.close(true);
        }
      } catch {
        this.getData();
      }
    });
  }

  removeShiftPublished(): void {
    const data = {
      shift: this.shiftData,
      notificationSetting: this.dialogInfo.notificationSetting,
    };
    if (this.dialogInfo.notificationSetting) {
      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('Push notification sent', { category: 'Shiftplan', subcategory: 'Notifications' });
    }
    const dialogRef = this.injector.get(MatLegacyDialog).open(DeleteShiftDialog, { data });
    dialogRef.afterClosed().subscribe(async (confirm) => {
      try {
        if (confirm === true) {
          this.dialogRef.close(true);
        }
      } catch {
        this.getData();
      }
    });
  }

  checkShiftReady() {
    if (this.editMode === false) {
      this.isSomeDaySelected = this.selectedWeekDays.some((selected) => selected);
    }
    const allMandatoryDataFilledAdd =
      check.assigned(this.shift.data.start) &&
      check.assigned(this.shift.data.end) &&
      check.assigned(this.shift.data.locationId) &&
      check.assigned(this.shift.data.roleId) &&
      ((this.isOpenShift && check.assigned(this.shift.data.slots) && check.assigned(this.approvalRuleSelected)) ||
        (check.assigned(this.shift.data.employees) && this.shift.data.employees.length > 0));

    const allMandatoryDataFilledEdit =
      check.assigned(this.shift.data.start) &&
      check.assigned(this.shift.data.end) &&
      check.assigned(this.shift.data.locationId) &&
      check.assigned(this.shift.data.roleId) &&
      ((this.isOpenShift && check.assigned(this.approvalRuleSelected)) ||
        (check.assigned(this.shift.data.employees) && this.shift.data.employees.length === 1));

    const inputsValidation =
      !this.startValidation ||
      this.startValidation.hasErrors() ||
      !this.endValidation ||
      this.endValidation.hasErrors() ||
      !this.locationValidation ||
      this.locationValidation.hasErrors() ||
      !this.roleValidation ||
      this.roleValidation.hasErrors();

    const canCreatePermission = this.isOpenShift && !this.shiftplanPermissions?.['open']?.create;
    const dateValidation = !this.dateValidation || this.dateValidation.hasErrors();
    const durationTimeErrors = this.hasDurationMaxErrors || this.hasDurationMinErrors || this.hasBreakErrors;
    const advancedOptionsValidation = this.viewAdvancedOptions ? this.areAdvancedOptionsValid : true;

    if (this.editMode === true) {
      this.isShiftReady = allMandatoryDataFilledEdit && !inputsValidation && !dateValidation && !durationTimeErrors;
    } else {
      this.isShiftReady =
        this.isSomeDaySelected &&
        allMandatoryDataFilledAdd &&
        !inputsValidation &&
        !durationTimeErrors &&
        advancedOptionsValidation &&
        !canCreatePermission;
    }

    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  calculateShiftDuration() {
    const { shiftDuration, errors } = calculateShiftDurationPure(
      this.shift.data.start,
      this.shift.data.end,
      this.shift.data.break ?? 0,
      this.breaktimeDeduction
    );
    this.shiftDuration = { ...shiftDuration };
    this.hasDurationMinErrors = errors['hasDurationMinErrors'];
    this.hasDurationMaxErrors = errors['hasDurationMaxErrors'];
    this.hasBreakErrors = errors['hasBreakErrors'];
  }

  filterEmployees() {
    this.notMatchedEntitiesMap = {};
    let notMatchedEntities = [];
    const showApplicants =
      this.editMode === true &&
      check.assigned(this.shift.data?.applicants) &&
      this.shift.data.status === this.SHIFT_PLAN_CARD_STATUS_PUBLISHED;
    this.filteredEmployees = showApplicants ? this.getInterestedEmployees() : this.allEmployees;

    if (this.shift.data.locationId) {
      this.filteredEmployees = showApplicants
        ? this.filteredEmployees
        : this.filteredEmployees.filter((employee) => employee.locations?.find((location) => location._id === this.shift.data.locationId));
      notMatchedEntities = notMatchedEntities.concat(this.locations.filter((location) => location.value !== this.shift.data.locationId));
    }
    if (this.shift.data.roleId && this.roles) {
      this.filteredEmployees = showApplicants
        ? this.filteredEmployees
        : this.filteredEmployees.filter((employee) => employee.rolesGrouped[this.shift.data.roleId]);
      notMatchedEntities = notMatchedEntities.concat(this.roles.filter((role) => role.value !== this.shift.data.roleId));
    }
    if (this.shift.data.tagId) {
      notMatchedEntities = notMatchedEntities.concat(this.tags.filter((tag) => tag.value !== this.shift.data.tagId));
    }
    if (this.shift.data.workingAreaId) {
      notMatchedEntities = notMatchedEntities.concat(
        this.workingAreas.filter((workingAreas) => workingAreas.value !== this.shift.data.workingAreaId)
      );
    }
    this.sameWeekEdit =
      !this.editMode || (this.editMode && moment(this.editModeInitialDate).isSame(moment(this.shift.data.date), SHIFT_PLAN_WEEKLY_VIEW));
    if (this.sameWeekEdit) {
      this.filteredEmployees = this.filteredEmployees.filter((employee) => {
        return this.employeesWorkSchedule?.[employee._id]?.workSchedule.every((workingDay, i) => {
          if (this.selectedWeekDays[i]) {
            return workingDay;
          }
          return true;
        });
      });
    }
    notMatchedEntities.forEach((entity) => {
      this.notMatchedEntitiesMap[entity._id] = true;
    });
    const filteredEmployeesIds = this.filteredEmployees.map((filteredEmployee) => filteredEmployee._id);
    this.everyoneElse = this.allEmployees.filter((employee) => filteredEmployeesIds.includes(employee._id) === false);
    this.checkShiftReady();
  }

  private getInterestedEmployees() {
    const applicants = this.shift.data?.applicants ?? [];
    return this.allEmployees.filter((employee) => applicants.includes(employee._id));
  }

  private createShiftDataFromDate(date, selectedData) {
    return {
      date,
      start: undefined,
      end: undefined,
      break: 0,
      locationId: undefined,
      workingAreaId: undefined,
      roleId: selectedData.role,
      tagId: undefined,
      employees: [],
      slots: 1,
      notes: undefined,
    };
  }

  private createShiftBody() {
    const shift = { ...this.shift.data, ...(this.isOpenShift && { approvalRule: this.approvalRuleSelected }) };
    shift.break ??= 0;

    const employees = this.shift.data.employees.map((employee) => employee._id);
    const slots = this.shift.data.slots;
    const validAdvancedOptions = this.areAdvancedOptionsValid && this.viewAdvancedOptions;
    delete shift.date;
    delete shift.employees;
    delete shift.slots;

    return {
      startDate: this.dateRange.from,
      endDate: this.dateRange.to.endOf('day'),
      selectedDays: this.selectedWeekDays,
      shift,
      employees,
      slots,
      ...(validAdvancedOptions && { advancedOptions: { frequency: this.frequencySelected, repeatEndDate: this.repeatEndDate } }),
    };
  }

  private createShiftFromData() {
    const shift = { ...this.dialogInfo.shift };
    this.defaultEmployeeId = this.dialogInfo.shift.employeeId;
    const activeLocationsIds = this.activeLocations.map((iLocation) => iLocation._id);
    const employees = check.assigned(shift?.employee) ? [{ _id: shift.employee._id, name: shift.employee.displayName }] : [];

    if (!activeLocationsIds.includes(shift.locationId)) {
      shift.locationId = null;
    }

    shift.locationId = shift.location?._id ?? this.dialogInfo.shift.locationId;
    shift.workingAreaId = shift.workingAreaId ?? null;
    shift.tagId = shift.tagId ?? null;
    shift.notes = shift.notes ?? '';
    shift.break = shift.break ?? 0;

    this.initialShiftData = Object.assign({}, { ...shift, employees, slots: 1 });
    return { ...shift, employees, slots: 1 };
  }

  private createShiftBodyToEdit() {
    const shift = this.shift.data;
    const employeeId = this.shift.data.employees.length === 1 && !this.isOpenShift ? this.shift.data.employees[0]._id : null;
    return {
      date: shift.date,
      start: shift.start,
      end: shift.end,
      break: shift.break,
      locationId: shift.locationId,
      workingAreaId: shift.workingAreaId,
      roleId: shift.roleId,
      tagId: shift.tagId,
      employeeId,
      notes: shift.notes,
      filters: this.dialogInfo.filters,
      ...(this.isOpenShift && { approvalRule: this.approvalRuleSelected }),
    };
  }

  public closeDialog() {
    if (this.loading) {
      return;
    }

    if (!this.editMode || !this.editDataTouched) {
      this.dialogRef.close({ newShift: false });
      return;
    }

    const data = {
      titleText: this.pageTranslation.leaveEditShiftTitle,
      subtitleText: this.pageTranslation.editLeaveIntentSubtitle,
      confirmButtonText: this.pageTranslation.confirmLeaveEditShiftButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.pageTranslation.backButton,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm === true) {
        this.dialogRef.close(false);
      }
    });
  }

  public closeDialogLoading() {
    this.viewAdvancedOptions = true;
    if (this.editMode || !this.viewAdvancedOptions) {
      this.dialogRef.close({ newShift: false });
      return;
    }
    clearTimeout(this.timeOut);
    const data = {
      titleText: this.pageTranslation.unsavedChangesTittle,
      subtitleText: this.pageTranslation.unsavedChangesSubtitle,
      confirmButtonText: this.pageTranslation.confirmCloseButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.pageTranslation.cancelGoBackButton,
    };
    this.dialogCloseLoading = this.confirmDialog.open(ConfirmDialogComponent, { data });
    this.dialogCloseLoading.afterClosed().subscribe(async (confirm) => {
      if (confirm && confirm === true) {
        this.dialogRef.close({ newShift: false });
      } else {
        this.loading = false;
        this.loadingBulk = false;
      }
    });
  }

  public showSnackbar(isOpenShift: boolean, selectedDays: number, employees: number, slots: number): void {
    let snackBarMessage: string = '';
    if (isOpenShift) {
      snackBarMessage =
        selectedDays > 1 || slots > 1 ? this.pageTranslation.snackbarOpenShiftsCreated : this.pageTranslation.snackbarOpenShiftCreated;
    } else {
      snackBarMessage =
        selectedDays > 1 || employees > 1
          ? this.pageTranslation.snackbarScheduledShiftsCreated
          : this.pageTranslation.snackbarScheduledShiftCreated;
    }
    this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', { duration: 5000 });
  }

  public navigateTo(path: string): void {
    this.injector.get(Router).navigateByUrl(path);
    this.dialogRef.close(true);
  }

  filterLocations(): void {
    this.locations = filterLocationsPure(this.dialogInfo.response.locations);
  }

  setEditTouched() {
    this.editDataTouched = false;
    if (!_.isEqual(this.initialShiftData, this.shift.data)) {
      this.editDataTouched = true;
    }
  }

  setWeekDaysLabel() {
    const weekDayLabels = this.injector.get(InternationalizationService).getShortTranslatedWeekdays();
    const currentLocale = this.injector.get(InternationalizationService).getLocale();
    this.weekDayLabels = setWeekDaysLabelPure(weekDayLabels, currentLocale);
  }

  showAdvancedOptions(event: boolean) {
    this.viewAdvancedOptions = event;
    if (!event) {
      this.repeatEndDate = null;
      this.checkShiftReady();
      return;
    }
    this.initFrequencyOptions();
    this.calculateDateRangesToCreate();
  }

  initFrequencyOptions() {
    const frequencyValues = Array.from({ length: 24 }, (_, i) => i + 1);
    this.frequencyOptions = frequencyValues.map((frequency) => {
      return {
        name:
          frequency === 1
            ? this.pageTranslation.everyWeek
            : this.injector.get(I18nDataPipe).transform(this.pageTranslation.everyWeekNumber, { frequency }),
        value: frequency,
      };
    });
  }

  initApprovalRulesOptions() {
    const approvalRulesValues = this.dialogInfo.openShiftSettings ?? { requiresApproval: true, autoAssign: false };
    this.approvalRuleOptions = Object.entries(approvalRulesValues)
      .filter(([_, value]) => value === true)
      .map(([key, _]) => {
        return {
          name: this.pageTranslation[key],
          value: key,
        };
      });
    this.approvalRuleSelected = this.shift.data?.approvalRule ?? this.approvalRuleOptions[0].value;
  }

  calculateDateRangesToCreate() {
    if (!this.viewAdvancedOptions) {
      return;
    }
    const firstDayIndex = this.selectedWeekDays.findIndex((day) => day === true);
    this.minDayToCreate = moment(this.dateRange.from).add(firstDayIndex, 'day');

    let yearLimit: number;
    if (this.frequencySelected <= this.FREQUENCY_CHANGE_LIMIT.LOW) {
      yearLimit = this.YEAR_LIMIT.LOW;
    }

    if (this.frequencySelected > this.FREQUENCY_CHANGE_LIMIT.LOW && this.frequencySelected <= this.FREQUENCY_CHANGE_LIMIT.MID) {
      yearLimit = this.YEAR_LIMIT.MID;
    }

    if (this.frequencySelected > this.FREQUENCY_CHANGE_LIMIT.MID) {
      yearLimit = this.YEAR_LIMIT.HIGH;
    }
    this.maxDayToCreate = moment(this.minDayToCreate).add(yearLimit, 'year');

    if (
      firstDayIndex === -1 ||
      moment(this.repeatEndDate).isBefore(this.minDayToCreate, 'day') ||
      moment(this.repeatEndDate).isAfter(this.maxDayToCreate, 'day')
    ) {
      this.repeatEndDate = null;
    }
    this.checkAdvancedOptions();
  }

  checkAdvancedOptions() {
    if (this.repeatEndDateValidation?.getError('required')) {
      this.repeatEndDate = null;
    }
    this.areAdvancedOptionsValid = check.assigned(this.frequencySelected) && check.assigned(this.repeatEndDate);
    this.checkShiftReady();
  }

  async showLoadingScreenAndCreate(createShiftPayload) {
    if (!this.areAdvancedOptionsValid || !this.viewAdvancedOptions) {
      const { ok } = await this.createMany(createShiftPayload);
      if (ok) {
        this.showSnackbar(
          this.isOpenShift,
          createShiftPayload.selectedDays.filter((day) => day).length,
          createShiftPayload.employees.length,
          createShiftPayload.slots
        );
      }
      return;
    }
    this.loadingBulk = true;
    this.timeOut = setTimeout(async () => {
      const { ok } = await this.createMany(createShiftPayload);
      if (ok) {
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent(this.isOpenShift ? 'repeating open shifts created' : 'repeating scheduled shifts created', {
            category: 'Shiftplan',
            subcategory: 'Scheduling',
            subcategory2: 'Repeating',
          });
        const snackbarMessage = this.isOpenShift
          ? this.pageTranslation.snackbarRepeatOpenShiftsCreated
          : this.pageTranslation.snackbarRepeatScheduledShiftsCreated;
        this.injector.get(MatLegacySnackBar).open(snackbarMessage, 'OK', { duration: 5000 });
      }
    }, this.LOADING_SCREEN_TIMEOUT);
  }

  async createMany(createShiftPayload) {
    const { status } = await this.injector.get(ShiftPlanCardService).createMany(createShiftPayload);
    this.loading = false;
    this.loadingBulk = false;

    if (status === ErrorCodes.CONFLICT) {
      this.showErrorSnackbar(this.pageTranslation.shiftsMaxNumberReachedError);
      return { ok: false };
    }

    if (status === ErrorCodes.UNKNOWN) {
      this.showErrorSnackbar(this.pageTranslation.errorShiftCreation);
      return { ok: false };
    }

    this.dialogRef.close({ newShift: true, destination: { ...this.getDestinationWeek() } });
    this.dialogCloseLoading?.close();
    return { ok: true };
  }

  getDestinationWeek() {
    const { from, to } = this.dateRange;
    const fistDayIndexCreate = this.selectedWeekDays.findIndex(Boolean);
    const newFrom = moment.utc(from).add(fistDayIndexCreate, 'day');

    return { from: newFrom, to };
  }

  setSelectEmployee(value: boolean) {
    this.isAssignShift = value;
  }

  async handleWeek(value: number) {
    this.dateRange = navigateToDate(this.dateRange, SHIFT_PLAN_WEEKLY_VIEW, value);
    if (this.viewAdvancedOptions) {
      this.calculateDateRangesToCreate();
    }
    await this.getUserWorkSchedules();
  }

  public navigateToOfficeLocation(): void {
    const path = this.dialogInfo.response.locations.length > 0 ? this.MY_OFFICE_SETTINGS_PATH : this.MY_COMPANY_SETTINGS_PATH;
    const url = this.router.serializeUrl(this.router.createUrlTree([path]));
    window.open(url, '_blank');
  }

  showErrorSnackbar(message: string) {
    this.injector.get(MatLegacySnackBar).open(message, 'OK', {
      duration: 5000,
      panelClass: 'kenjo-error-snackbar',
    });
  }
}
