import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITask } from '@app/models/task.model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { TaskService } from '@app/standard/services/task/task.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TaskController {
  TASK_CONTROLLER_URL = `${environment.PEOPLE_CLOUD_APP_URL}/controller/task`;

  constructor(private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  async createTaskForCandidate(data: ITask): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const task = await this.http.post(`${this.TASK_CONTROLLER_URL}/candidate`, data, httpOptions).toPromise();

      return task;
    } catch (error: any) {
      throw this.errorManager.handleRawError(error, TaskService.name, 'createTaskForCandidate');
    }
  }

  async updateTaskForCandidate(id: string, data: ITask): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      await this.http.put(`${this.TASK_CONTROLLER_URL}/candidate/${id}`, data, httpOptions).toPromise();
    } catch (error: any) {
      throw this.errorManager.handleRawError(error, TaskService.name, 'updateTaskForCandidate');
    }
  }

  async deleteTaskForCandidate(id: string): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      await this.http.delete(`${this.TASK_CONTROLLER_URL}/candidate/${id}`, httpOptions).toPromise();
    } catch (error: any) {
      throw this.errorManager.handleRawError(error, TaskService.name, 'deleteTaskForCandidate');
    }
  }
}
