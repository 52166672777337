import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-recruiting-discussion-comments-settings',
  templateUrl: 'recruiting-discussion-comments-settings.component.html',
  styleUrls: ['recruiting-discussion-comments-settings.component.scss']
})
export class RecruitingDiscussionCommentsSettingsComponent implements OnInit {
  PREFERENCE_FILTER_DISCUSSION_SETTINGS: string = 'recruiting-discussion-settings';
  RECRUITING_DISCUSSION_SETTINGS_TYPE: Array<string> = picklistConstants.RECRUITING_DISCUSSION_SETTINGS_TYPE;
  RECRUITING_DISCUSSION_SETTINGS_TIME_SORTING: Array<string> = picklistConstants.RECRUITING_DISCUSSION_SETTINGS_TIME_SORTING;

  @Input() i18n: any;
  @Input() filterSettings: any;
  @Output() updatedSettingsFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private injector: Injector) {}
  filterSettingsOptions: IFilterDiscussionCommentsSettings = {
    type: {},
    timeSorting: {}
  };
  filterSettingsSelected: IFilterDiscussionCommentsSettings = {
    type: '',
    timeSorting: ''
  };
  ngOnInit(): void {
    this.buildSettingsOptions();
  }

  private buildSettingsOptions(): void {
    this.filterSettingsOptions.type = this.RECRUITING_DISCUSSION_SETTINGS_TYPE.map((eachType) => {
      if (eachType === this.filterSettings.type) {
        this.filterSettingsSelected.type = eachType;
      }

      return {
        value: eachType,
        name: _.capitalize(this.i18n[eachType])
      };
    });

    this.filterSettingsOptions.timeSorting = this.RECRUITING_DISCUSSION_SETTINGS_TIME_SORTING.map((eachTimeSorting) => {
      if (eachTimeSorting === this.filterSettings.timeSorting) {
        this.filterSettingsSelected.timeSorting = eachTimeSorting;
      }
      return {
        value: eachTimeSorting,
        name: this.i18n[eachTimeSorting]
      };
    });
  }

  public closeFilterDiscussionSettings(): void {
    this.updatedSettingsFinished.emit(true);
  }

  public async saveFilterDiscussionSettings(): Promise<void> {
    const preferenceBody = { ...this.filterSettingsSelected };
    await this.injector.get(PreferenceService).setPreferenceByKey(this.PREFERENCE_FILTER_DISCUSSION_SETTINGS, preferenceBody);
    this.updatedSettingsFinished.emit(true);
  }
}

export interface IFilterDiscussionCommentsSettings {
  type: any;
  timeSorting: any;
}
