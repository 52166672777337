<button
  *ngIf="isVisible"
  [class.bs-container-m]="size === 'M'"
  [class.bs-container-s]="size === 'S'"
  [class.bs-container-l]="size === 'L'"
  [class.bs-container-xs]="size === 'XS'"
  [class.bs-danger]="type === 'danger'"
  [class.bs-success]="type === 'success'"
  [class.bs-warning]="type === 'warning'"
  [class.bs-caution]="type === 'caution'"
  [class.bs-neutral]="type === 'neutral'"
  [style.width]="width + 'px'"
  class="bs-container"
>
  <ng-content></ng-content>
  <kenjo-icon>{{ icon }}</kenjo-icon>
</button>
