<div class="dialog-box">
  <orgos-column-container class="kenjo-p-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <div class="kenjo-mh-60px">
    <orgos-column-container centerColumns="true">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-text type="Headline1">{{translation.saveViewDialogTitle}}</orgos-text>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <orgos-column-container centerColumns="true" class="kenjo-mt-10px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-text type="Headline4">{{translation.saveViewDialogSubtitle}}</orgos-text>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
  </div>
  <orgos-column-container centerColumns="true" class="kenjo-mt-80px">
    <orgos-column size="1"></orgos-column>
    <orgos-input-simple-text class="kenjo-mt-40px" orgosColumn [label]="translation.viewNameLabel" [(value)]="viewName" (validation)="nameValidation = $event" required="true">
      <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">{{translation.nameFieldRequired}} </orgos-input-error>
      <orgos-input-error *ngIf="viewName === translation.allViewsLabel">AAAA</orgos-input-error>
    </orgos-input-simple-text>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
  <orgos-column-container centerColumns="true" class="kenjo-mt-80px kenjo-mh-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="1">
      <orgos-column-container centerColumns="true" class="kenjo-mt-80px">
        <orgos-column size="1" class="clvd-button-align">
          <div>
            <mat-icon class="clvd-cancel-button" (click)="closeDialog()">cancel_icon</mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText">{{translation.cancelButtonLabel}}</orgos-text>
          </div>
        </orgos-column>
        <orgos-column size="1" class="clvd-button-align">
          <div>
            <mat-icon class="clvd-create-button" (click)="saveView()" [class.clvd-disabled-button]="nameValidation && nameValidation.getError('required')">arrow_next_circle </mat-icon>
          </div>
          <div>
            <orgos-text type="SecondaryText" [class.clvd-disabled-button]="nameValidation && nameValidation.getError('required')"> {{translation.saveButtonLabel}}</orgos-text>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</div>
