import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ICandidateData } from '@app/cloud-features/recruiting/recruiting-candidate-main/model/candidate-review-data.model';
import { IDiscussionComment } from '@app/cloud-features/recruiting/recruiting-candidate-main/model/discussion-comment.model';
import { IRecruitingActivity } from '@app/common-components/recruiting/recruiting-activity-widget/recruiting-activity-widget.component';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IMentionOption } from '@app/standard/components/input-simple-editor/input-simple-editor.component';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IPreferenceModel, PreferenceService } from '@app/standard/services/preference/preference.service';
import { CandidateService } from '@app/standard/services/recruiting/candidate.service';
import { RecruitingCandidateService } from '@app/standard/services/recruiting/controllers/recruiting-candidate.service';
import { HiringTeamService, IHiringTeamModel } from '@app/standard/services/recruiting/hiring-team.service';
import { ICandidateCommentModel } from '@app/standard/services/recruiting/position-candidate.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-recruiting-discussion-widget',
  templateUrl: 'recruiting-discussion-widget.component.html',
  styleUrls: ['recruiting-discussion-widget.component.scss'],
})
export class RecruitingDiscussionWidgetComponent implements OnInit {
  @ViewChild('visibleActivitiesContainer', { read: ElementRef }) visibleActivitiesContainer: ElementRef;

  i18n: any = {};
  PREFERENCE_FILTER_DISCUSSION_SETTINGS: string = 'recruiting-discussion-settings';
  RECRUITING_DISCUSSION_SETTINGS_TEAM: string = picklistConstants.RECRUITING_DISCUSSION_SETTINGS_TEAM;
  RECRUITING_DISCUSSION_SETTINGS_PRIVATE: string = picklistConstants.RECRUITING_DISCUSSION_SETTINGS_PRIVATE;
  RECRUITING_DISCUSSION_SETTINGS_AGENCY: string = picklistConstants.RECRUITING_DISCUSSION_SETTINGS_AGENCY;
  RECRUITING_DISCUSSION_SETTINGS_NEW_TO_OLD: string = picklistConstants.RECRUITING_DISCUSSION_SETTINGS_NEW_TO_OLD;
  RECRUITING_DISCUSSION_SETTINGS_OLD_TO_NEW: string = picklistConstants.RECRUITING_DISCUSSION_SETTINGS_OLD_TO_NEW;

  mentionOptions: Array<IMentionOption> = [];

  creatingComment: boolean = false;
  filterSettings: any;

  comment: ICandidateCommentModel = {
    private: false,
    comment: '',
  };

  commentValidation: InputValidation;

  visibleActivities: Array<IRecruitingActivity> = [];
  agencySelected: boolean = false;
  filterSettingsSelected: boolean = false;
  commentActivities: Array<IRecruitingActivity> = [];
  userPersonalIdMap: { [key: string]: IUserPersonalModel } = {};
  hiringTeamMembersIds: Array<string> = [];
  newHiringTeamMembersMessage: string = '';
  showNewHiringTeamMembersWarning: boolean = false;

  _candidate: ICandidateData;
  @Input() set candidate(candidate: ICandidateData) {
    this._candidate = candidate;
    if (this.initialized) {
      this.initComments();
    }
  }
  get candidate(): ICandidateData {
    return this._candidate;
  }

  @Input() positionId: string;
  @Input() internalUser: boolean = true;
  @Input() allowEditComments: boolean = true;
  @Input() agency: string;
  @Input() getCandidateAndPositionIdsFromURLParams: boolean = false;

  @Output() commentCreated: EventEmitter<ICandidateCommentModel | void> = new EventEmitter<ICandidateCommentModel | void>();

  private currentUserId: string;
  private initialized: boolean = false;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    const promisesToResolve = [
      this.injector.get(InternationalizationService).getAllTranslation('recruiting-discussion-widget'),
      this.injector.get(InternationalizationService).getAllTranslation('recruiting-activity-widget'),
      this.getAllUserPersonal(),
      this.getFilterDiscussionSettings(),
      this.getHiringTeamMembers(),
    ];
    Promise.all(promisesToResolve).then((allData: any) => {
      this.i18n = allData[0];
      this.i18n.activity = allData[1];

      this.initComments();
      this.initialized = true;
    });
  }

  private async initComments(postCreate?: boolean): Promise<void> {
    const [comments, userPersonalIdMap] = await this.getAllComments(this.candidate._id, this.positionId);

    this.userPersonalIdMap = userPersonalIdMap ?? {};
    this.commentActivities = (comments ?? []).length === 0 ? [] : this.convertCommentsToActivity(comments);

    this.changePrivateValue(false, this.filterSettings.preference.timeSorting, postCreate);
    this.setFilterDiscussionSettings();
  }

  private async getAllComments(candidateId: string, positionId: string): Promise<Array<Array<IDiscussionComment> | any>> {
    return this.injector.get(RecruitingCandidateService).getDiscussionWidgetData(candidateId, positionId);
  }

  private convertCommentsToActivity(comments: Array<IDiscussionComment>): Array<IRecruitingActivity> {
    const loggedAsAgency = check.assigned(this.injector.get(AuthenticationService).getLoggedUser().agencyId);

    return comments.map((iComment) => {
      const data = {
        userName: '',
        candidateName: `${this.candidate.firstName} ${this.candidate.lastName}`,
      };

      let info;

      if (check.assigned(iComment.agency) && check.nonEmptyString(iComment.agency) && check.not.equal(iComment.agency, 'company')) {
        if (loggedAsAgency) {
          info = this.injector.get(I18nDataPipe).transform(this.i18n.activity.commentByYou, data);
        } else {
          data.userName = iComment.agency;
          info = this.injector.get(I18nDataPipe).transform(this.i18n.activity.commentByUser, data);
        }
      } else if (iComment[fieldConstants.CREATED_BY] !== this.currentUserId || loggedAsAgency) {
        if (
          check.assigned(this.userPersonalIdMap) &&
          check.assigned(this.userPersonalIdMap[iComment[fieldConstants.CREATED_BY]]) &&
          check.assigned(this.userPersonalIdMap[iComment[fieldConstants.CREATED_BY]].displayName)
        ) {
          data.userName = this.userPersonalIdMap[iComment[fieldConstants.CREATED_BY]].displayName;
          info = this.injector.get(I18nDataPipe).transform(this.i18n.activity.commentByUser, data);
        }
      } else {
        info = this.injector.get(I18nDataPipe).transform(this.i18n.activity.commentByYou, data);
      }

      const activity: IRecruitingActivity = {
        _id: iComment._id,
        comment: iComment.comment,
        _createdById: iComment._createdById,
        icon: iComment.private === true ? 'private_comment_notification' : 'conversation',
        iconColor: '#8480ff',
        date: moment(iComment[fieldConstants.CREATED_AT]),
        isPrivate: iComment.private && iComment.private === true ? true : false,
        additionalInfoHtml: iComment.comment.html,
        mainInfo: info,
        agency: check.assigned(iComment.agency) && check.nonEmptyString(iComment.agency) ? iComment.agency : null,
      };

      return activity;
    });
  }

  commentChange(): void {
    const comment: any = this.comment.comment;
    const allNewHiringTeamMembers = new Set<string>();
    if (check.assigned(comment?.delta?.ops)) {
      comment.delta.ops.forEach((iCommentOp) => {
        if (
          check.assigned(iCommentOp?.insert?.mention?.id) &&
          !this.hiringTeamMembersIds.includes(iCommentOp.insert.mention.id) &&
          !allNewHiringTeamMembers.has(iCommentOp.insert.mention.value)
        ) {
          allNewHiringTeamMembers.add(iCommentOp.insert.mention.value);
        }
      });
    }

    this.showNewHiringTeamMembersWarning = allNewHiringTeamMembers.size !== 0;
    const translation =
      allNewHiringTeamMembers.size > 1 ? this.i18n.newHiringTeamMembersWarningSingular : this.i18n.newHiringTeamMembersWarningPlural;
    this.newHiringTeamMembersMessage = this.injector
      .get(I18nDataPipe)
      .transform(translation, { newHiringTeamMembers: Array.from(allNewHiringTeamMembers).join(', ') });
  }

  async getHiringTeamMembers(): Promise<void> {
    const hiringTeam = await this.injector.get(HiringTeamService).getMembersForPositionId(this.positionId);
    const hiringTeamMembers = hiringTeam;
    this.hiringTeamMembersIds = hiringTeamMembers.map((iHiringTeamMember: IHiringTeamModel) => iHiringTeamMember._userId);
  }

  createComment(): void {
    if (
      this.creatingComment ||
      check.not.assigned(this.comment.comment) ||
      check.not.assigned(this.commentValidation) ||
      this.commentValidation.hasErrors()
    ) {
      return;
    }

    this.creatingComment = true;
    if (check.assigned(this.agency)) {
      this.comment.agency = this.agency;
    }

    if (check.assigned(this.positionId)) {
      this.comment._positionId = this.positionId;
    }

    this.injector
      .get(CandidateService)
      .createComment(this.candidate?._id, this.comment)
      .then((result) => {
        this.initComments(true);

        const commentSnackBarRef = this.injector.get(MatLegacySnackBar).open(this.i18n.commentCreatedSnackText, 'OK', {
          duration: 5000,
        });

        this.injector.get(PrivateAmplitudeService).logEvent('add note overview page', {
          platform: 'Web',
          category: 'Recruiting',
          subcategory1: 'Job openings',
          subcategory2: 'Candidate Overview',
        });

        if (check.assigned(result.newHiringTeamMembersIds) && check.not.emptyArray(result.newHiringTeamMembersIds)) {
          this.showNewHiringTeamMembersWarning = false;
          commentSnackBarRef.afterDismissed().subscribe(() => {
            this.showNextHiringTeamMemberSnackBar(result.newHiringTeamMembersIds);
            this.hiringTeamMembersIds.push(...result.newHiringTeamMembersIds);
          });
        }

        this.creatingComment = false;

        // tslint:disable-next-line: strict-boolean-expressions
        const comment = { ...this.comment, ...(check.nonEmptyString(result.commentId) && { _id: result.commentId }) };

        this.commentCreated.emit(comment);
        this.clearWidget();
      })
      .catch(() => {
        this.creatingComment = false;
      });
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  public onCommentSaved() {
    this.commentCreated.emit();
    this.initComments(true);
  }

  public changePrivateValue(privateValue: boolean, timeSorting: string, postCreate?: boolean): void {
    if (!postCreate) {
      this.comment.private = privateValue;
      this.comment.agency = '';
      this.agencySelected = false;
    }

    this.initVisibleActivities(timeSorting);
    this.scrollToTop();
  }

  public changeToAgencyValue(timeSorting: string): void {
    this.agencySelected = true;
    this.comment.private = false;
    this.comment.agency = this.agency ?? 'company';

    this.initVisibleActivities(timeSorting);
    this.scrollToTop();
  }

  private clearWidget(): void {
    this.comment.comment = '';
  }

  async updateFilterDiscussionSettings(updatedSettingsFinished): Promise<void> {
    this.filterSettingsSelected = !updatedSettingsFinished;
    this.injector.get(ChangeDetectorRef).detectChanges();
    await this.getFilterDiscussionSettings();
    this.setFilterDiscussionSettings();
  }

  public async getFilterDiscussionSettings(): Promise<void> {
    try {
      const preferenceResult: IPreferenceModel = await this.injector
        .get(PreferenceService)
        .getPreferenceByKey(this.PREFERENCE_FILTER_DISCUSSION_SETTINGS);
      this.filterSettings = preferenceResult;
      if (check.not.assigned(preferenceResult.preference)) {
        this.filterSettings = preferenceResult;
        this.filterSettings.preference = {
          type: this.RECRUITING_DISCUSSION_SETTINGS_TEAM,
          timeSorting: this.RECRUITING_DISCUSSION_SETTINGS_NEW_TO_OLD,
        };
      } else {
        this.filterSettings.preference = preferenceResult.preference;
      }
    } catch (error) {
      //Do nothing
    }
  }

  private async getAllUserPersonal(): Promise<void> {
    try {
      const allUserPersonal: Array<any> = await this.injector.get(UserPersonalService).getAllUserPersonal(false, false);
      this.mentionOptions = allUserPersonal.map((iUserPersonal: any) => {
        const mentionOption: IMentionOption = {
          id: iUserPersonal._id,
          value: iUserPersonal.displayName,
          photoUrl: iUserPersonal._photo?._url,
        };
        return mentionOption;
      });
    } catch (error) {
      this.mentionOptions = [];
    }
  }

  private initVisibleActivities(timeSorting: string): void {
    if (this.agencySelected === false && check.not.assigned(this.agency)) {
      this.visibleActivities = this.commentActivities.filter((iActivity) => {
        return (
          this.comment.private === iActivity.isPrivate && (check.not.assigned(iActivity.agency) || check.emptyString(iActivity.agency))
        );
      });
    } else {
      this.visibleActivities = this.commentActivities.filter((iActivity) => {
        return check.assigned(iActivity.agency) && check.nonEmptyString(iActivity.agency);
      });
    }

    let sortedVisibleActivities;
    if (timeSorting === this.RECRUITING_DISCUSSION_SETTINGS_NEW_TO_OLD) {
      sortedVisibleActivities = [...this.visibleActivities].sort(this.sortCommentsByDate()).reverse();
    } else {
      sortedVisibleActivities = [...this.visibleActivities].sort(this.sortCommentsByDate());
    }

    this.visibleActivities = sortedVisibleActivities;
  }

  private sortCommentsByDate(): (a, b) => number {
    return (a, b) => {
      const aMoment = a['date'];
      const bMoment = b['date'];

      if (aMoment.isBefore(bMoment)) {
        return -1;
      } else if (aMoment.isAfter(bMoment)) {
        return 1;
      }

      return 0;
    };
  }

  private scrollToTop() {
    this.injector.get(ChangeDetectorRef).detectChanges();

    if (check.assigned(this.visibleActivitiesContainer) && check.assigned(this.visibleActivitiesContainer?.nativeElement)) {
      this.visibleActivitiesContainer.nativeElement.scrollTop = 0;
    }
  }

  private showNextHiringTeamMemberSnackBar(newHiringTeamMembersIds: Array<any> = [], indexOfNewHiringTeamMemberToShow: number = 0): void {
    const iNewHiringTeamMemberId = newHiringTeamMembersIds[indexOfNewHiringTeamMemberToShow];

    if (check.not.assigned(iNewHiringTeamMemberId)) {
      return;
    }

    const hiringTeamMember = this.mentionOptions.find((iMentionOption) => {
      return iMentionOption.id === iNewHiringTeamMemberId;
    });

    const snackBarMessage = this.injector
      .get(I18nDataPipe)
      .transform(this.i18n.userAddedHiringTeamSnackText, { displayName: hiringTeamMember.value });
    const snackBarRef = this.injector.get(MatLegacySnackBar).open(snackBarMessage, 'OK', {
      duration: 5000,
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.showNextHiringTeamMemberSnackBar(newHiringTeamMembersIds, indexOfNewHiringTeamMemberToShow + 1);
    });
  }

  public openFilterDicussionSettings(): void {
    this.filterSettingsSelected = true;
    this.setFilterDiscussionSettings();
  }

  private setFilterDiscussionSettings(): void {
    const { type, timeSorting } = this.filterSettings.preference;

    if (type === this.RECRUITING_DISCUSSION_SETTINGS_TEAM) {
      this.changePrivateValue(false, timeSorting);
    } else if (type === this.RECRUITING_DISCUSSION_SETTINGS_PRIVATE) {
      this.changePrivateValue(true, timeSorting);
    } else if (type === this.RECRUITING_DISCUSSION_SETTINGS_AGENCY) {
      this.changeToAgencyValue(timeSorting);
    }
  }
}
