<orgos-dialog-container class="uettd-main-container" [title]="dialogTitle" (clickCloseButton)="closeDialog()">
    <orgos-input-simple-text [(value)]="terminationReason.name" [label]="pageTranslation.terminationReason" (validation)="inputValidation = $event" required="true" class="kenjo-block" maxlength="90">
      <orgos-input-error
        *ngIf="
          inputValidation &&
          inputValidation.getError('required')"
        >{{pageTranslation.terminationReasonRequired}}</orgos-input-error
      >
    </orgos-input-simple-text>
    <div class="kenjo-pt-60px kenjo-text-align-right">
      <orgos-button-raised color="Success" [disabled]="!inputValidation || inputValidation.hasErrors()" (click)="saveTerminationReason()">{{ !terminationReason._id ? pageTranslation.add : pageTranslation.edit }}</orgos-button-raised>
    </div>
  </orgos-dialog-container>