<div cdkDrag [cdkDragDisabled]="isPublished" (cdkDragStarted)="onDrag(true)" (cdkDragEnded)="onDrag(false)" id="shift-card">
  <div class="scc-card-container">
    <div
      *ngIf="cardSizeSettings?.compactHeight"
      (click)="onClick($event)"
      [@cardAnimation]="{
        value: cardSizeSettings.value,
        params: {
          height: view === VIEWS.MONTH ? 'fit-content' : cardSizeSettings?.compactHeight,
          width: view === VIEWS.WEEK ? 'unset' : '83px'
        }
      }"
      class="scc-card kenjo-mb-5px"
      [class.kenjo-pv-5px]="view === VIEWS.WEEK"
      [class.monthly-view]="view === VIEWS.MONTH"
      [class.minimal]="showMinimalInfo"
      [class.with-applicants]="(isPublished && !shift.employeeId) || (isSelectShiftsActive && shift.status === STATUS.DRAFT)"
      [class.sc-published-text]="isPublished"
      [style.border-color]="cardColor"
      [style.background-image]="!isPublished ? cardColorTransparent : undefined"
      [style.background-color]="isPublished ? cardColor : undefined"
    >
      <div class="scc-card-left-border" [style.background-color]="cardColor"></div>
      <div *ngIf="cardSizeSettings.role" [class.kenjo-pl-10px]="!showMinimalInfo" [class.kenjo-font-weight-bold]="cardSizeSettings.firstField === 'role'">
        <kenjo-truncate-text *ngIf="!showMinimalInfo" class="sc-role-name">{{ shift.role.name }}</kenjo-truncate-text>
        <div *ngIf="showMinimalInfo" class="kenjo-font-size-12px kenjo-font-weight-bold kenjo-text-align-center">{{ trucantedRoleName }}</div>
      </div>
      <!-- TO KEEP -->
      <!-- <div *ngIf="!shift.employeeId" class="scc-slots" [style.background-color]="cardColor">1</div> -->
      <div *ngIf="cardSizeSettings.time" class="kenjo-pl-10px" [class.kenjo-font-weight-bold]="cardSizeSettings.firstField === 'time'">{{ start }} - {{ end }}</div>
      <div *ngIf="cardSizeSettings.duration" class="kenjo-pl-10px" [class.kenjo-font-weight-bold]="cardSizeSettings.firstField === 'duration'">{{ shiftDuration.hours }}{{ componentTranslation.hour }} {{ shiftDuration.minutes }}{{ componentTranslation.minute }}</div>
      <div *ngIf="cardSizeSettings.location" class="kenjo-pl-10px" [class.kenjo-pr-10px]="view === VIEWS.MONTH" [class.kenjo-font-weight-bold]="cardSizeSettings.firstField === 'location'">
        <kenjo-truncate-text [class.sc-trunc-name-open]="view === VIEWS.WEEK">{{ shift.location.name }}</kenjo-truncate-text>
      </div>
      <div *ngIf="cardSizeSettings.tag" class="kenjo-pl-10px" [class.kenjo-pr-10px]="view === VIEWS.MONTH" [class.kenjo-font-weight-bold]="cardSizeSettings.firstField === 'tag'">
        <kenjo-truncate-text [class.sc-trunc-name-open]="view === VIEWS.WEEK">
          <span [class.sc-no-tag]="!shift.tag?.name">{{ shift.tag?.name ?? componentTranslation.noTag }}</span>
        </kenjo-truncate-text>
      </div>
      <div *ngIf="isSelectShiftsActive && shift.status === STATUS.DRAFT" class="sc-check-box" [class.monthly]="view === VIEWS.MONTH" [class.minimal]="showMinimalInfo">
        <mat-checkbox [checked]="shift.selected" [ngModel]="shift.selected" (change)="changeSelection($event.checked)"></mat-checkbox>
      </div>
      <div *ngIf="isPublishedOpenShift" class="sc-applicants" [class.monthly]="view === VIEWS.MONTH" [class.minimal]="showMinimalInfo">
        <div>
          <kenjo-icon [size]="showMinimalInfo ? 10 : 14">people</kenjo-icon>
          <div>{{ shift.applicants?.length ?? 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
