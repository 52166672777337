<orgos-widget titleKey="birthday" [loadingWidget]="loadingWidget" [existingData]="(todayUsers && todayUsers.length > 0) || (thisWeekUsers && thisWeekUsers.length > 0) || (followingMonthUsers && followingMonthUsers.length > 0)" [footerHeight]="40">
  <orgos-widget-content>
    <ng-container *ngIf="todayUsers && todayUsers.length > 0">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">{{ translation.todayLabel }}</div>
      <orgos-column-container wrap="true" class="kenjo-mb-10px">
        <orgos-column *ngFor="let user of todayUsers" size="0" class="kenjo-mv-10px kenjo-mr-10px">
          <orgos-avatar *ngIf="user" (click)="navigateToPersonalPage(user._id)" class="wb-avatar" [class.wp-avatar-selected]="userSelected === user" [photoUrl]="user._photo?._url" [avatarName]="user.displayName" size="small" (mouseenter)="userSelected = user"></orgos-avatar>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <ng-container *ngIf="thisWeekUsers && thisWeekUsers.length > 0">
      <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">{{ translation.thisWeekLabel }}</div>
      <orgos-column-container wrap="true" class="kenjo-mb-10px">
        <orgos-column *ngFor="let user of thisWeekUsers" size="0" class="kenjo-mv-10px kenjo-mr-10px">
          <orgos-avatar *ngIf="user" (click)="navigateToPersonalPage(user._id)" class="wb-avatar" [class.wp-avatar-selected]="userSelected === user" [photoUrl]="user._photo?._url" [avatarName]="user.displayName" size="small" (mouseenter)="userSelected = user"></orgos-avatar>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <ng-container *ngIf="followingMonthUsers && followingMonthUsers.length > 0">
      <div *ngFor="let user of followingMonthUsers" size="0" class="kenjo-mb-10px">
        <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px">{{ getDateInMomentFormat(user.month) | date: 'MMMM':'UTC' | uppercase }}</div>
        <ng-container *ngIf="user.birthdays && user.birthdays.length > 0">
          <orgos-column-container wrap="true">
            <orgos-column *ngFor="let user of user.birthdays" size="0" class="kenjo-mv-10px kenjo-mr-10px">
              <orgos-avatar *ngIf="user" (click)="navigateToPersonalPage(user._id)" class="wb-avatar" [class.wp-avatar-selected]="userSelected === user" [photoUrl]="user._photo?._url" [avatarName]="user.displayName" size="small" (mouseenter)="userSelected = user"></orgos-avatar>
            </orgos-column>
          </orgos-column-container>
        </ng-container>
      </div>
    </ng-container>
  </orgos-widget-content>
  <orgos-widget-footer>
    <kenjo-truncate-text class="kenjo-pb-10px kenjo-font-size-14px" *ngIf="userSelected">{{ userSelected.displayName }}</kenjo-truncate-text>
    <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px" *ngIf="userSelected">
      {{ translation.birthdateLabel }}: <span class="kenjo-font-color-text-333333">{{ userSelected.nextBirthday | date: getDateFormat('dayMonth'):'UTC' }}</span>
    </div>
  </orgos-widget-footer>
</orgos-widget>
