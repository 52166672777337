import { Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

import { I18nDataPipe } from '../../../../../components/i18n-data/i18n-data.pipe';
import { SearchComponent, SearchFunction } from '../../../../../components/search/search.component';
import { GenericCacheModel } from '../../../../../core/generic-cache-model';
import { AuthenticationService } from '../../../../../services/core/authentication.service';
import { InternationalizationService } from '../../../../../services/core/internationalization.service';
import { IMeetingModel, MeetingService } from '../../../../../services/performance-management/meeting.service';
import { UserPersonalService } from '../../../../../services/user/user-personal.service';
import { ITranslationResource } from '../../../../generic.page';

@Component({
  selector: 'orgos-add-participant',
  templateUrl: 'add-participant.dialog.html',
  styleUrls: ['add-participant.dialog.scss'],
})
export class AddParticipantDialog implements OnInit {
  meeting: IMeetingModel;
  createdOn: string;
  userPersonalMap: any;
  userWorkMap: any;
  loggedUser: any;
  meetingCache: GenericCacheModel;
  numberOfParticipants: string = '';
  newParticipantsAdded: string = '';
  searchResults: Array<any> = [];
  usersSelected: Array<any> = [];
  allUsers: Array<any>;
  userIdToUserPersonal: any;
  translation: any = {};

  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'meetings-misc' },
    { name: 'page', translationKey: 'meetings-detail-page' },
  ];

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  constructor(
    public dialogRef: MatLegacyDialogRef<AddParticipantDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private snackBar: MatLegacySnackBar,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();

    if (check.assigned(this.data) && check.assigned(this.data.meeting)) {
      this.meetingCache = new GenericCacheModel(this.injector, this.data.meeting, MeetingService, loggedUser._id);
    }

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('meetings-misc')
      .then((dialogTranslation) => {
        this.translation = dialogTranslation;
        this.numberOfParticipants = this.injector.get(I18nDataPipe).transform(this.translation.numberOfParticipants, {
          numberOfParticipants: (this.meetingCache.data.participants.length as number) + 1,
        });
      })
      .catch(() => {
        this.translation = {};
      });

    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(true)
      .then((allUsers) => {
        this.allUsers = allUsers;
        this.userIdToUserPersonal = _.keyBy(allUsers, fieldConstants.ID);
        const participantsId = this.meetingCache.data.participants.map((participant) => {
          return participant.userId;
        });
        _.remove(this.allUsers, (iUser) => {
          return iUser._id === loggedUser._id || _.includes(participantsId, iUser._id);
        });
      })
      .catch((error) => {
        this.allUsers = [];
      });
  }

  public searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(value) || check.emptyString(value)) {
      const userPersonals = this.usersSelected.map((iParticipant) => {
        return this.userIdToUserPersonal[iParticipant];
      });
      return Promise.resolve(userPersonals);
    }
    const results = _.chain(this.allUsers)
      .filter((userAccount: any) => {
        const userPersonal = this.userIdToUserPersonal[userAccount[fieldConstants.ID]];

        if (check.not.assigned(userPersonal)) {
          return false;
        }

        const regExp = new RegExp(`^.*${value}.*$`, 'i');
        return regExp.test(userPersonal.displayName);
      })
      .forEach((iResult) => {
        iResult.displayName = check.assigned(this.userIdToUserPersonal[iResult._id])
          ? this.userIdToUserPersonal[iResult._id].displayName
          : '';
      })
      .orderBy(['displayName'], ['asc'])
      .value();

    return Promise.resolve(
      results.map((iResult) => {
        return this.userIdToUserPersonal[iResult[fieldConstants.ID]];
      })
    );
  };

  public assignUser(userId: string): void {
    if (check.not.assigned(userId) || check.emptyString(userId)) {
      return;
    }
    this.usersSelected.push(userId);

    _.remove(
      this.allUsers,
      _.iteratee({
        _id: userId,
      })
    );

    this.newParticipantsAdded = this.injector
      .get(I18nDataPipe)
      .transform(this.translation.newParticipantsAdded, { numberOfUsers: this.usersSelected.length });
    this.searchComponent.clearSearch();
  }

  public removeUser(userId: string): Function {
    return () => {
      if (check.not.assigned(userId) || check.emptyString(userId)) {
        return;
      }
      _.remove(this.usersSelected, (element) => {
        return element === userId;
      });
      this.allUsers.push(this.userIdToUserPersonal[userId]);
      this.newParticipantsAdded = this.injector
        .get(I18nDataPipe)
        .transform(this.translation.newParticipantsAdded, { numberOfUsers: this.usersSelected.length });
      this.searchComponent.clearSearch();
      this.searchComponent.refreshSearchResults();
    };
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public rightButtonAction(): void {
    this.usersSelected.forEach((idUser) => {
      this.meetingCache.data.participants.push({
        userId: idUser,
      });
    });
    this.meetingCache
      .updateInServer()
      .then(() => {
        this.snackBar.open(this.translation.participantAdded, 'OK', {
          duration: 5000,
        });

        this.dialogRef.close(true);
        return;
      })
      .catch(() => {
        // an error will be shown
      });
  }
}
