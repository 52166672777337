import { Component } from '@angular/core';
import { IChargebeeCreditNote, IChargebeeCustomer, IChargebeeCustomerWrapper, IChargebeeInvoice, PrivateChargebeeService } from '@app/private/services/private-chargebee.service';
import { GenericPage, ITranslationResource } from '@app/standard/pages/generic.page';
import { SettingsBarService } from '@app/standard/services/settings/settings-bar.service';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-settings-billing-invoices-page',
  templateUrl: 'settings-billing-invoices.page.html',
  styleUrls: ['settings-billing-invoices.page.scss']
})
export class SettingsBillingInvoicesPage extends GenericPage {
  chargebeeInvoices: Array<IChargebeeInvoice> = [];
  invoicesTableDisplayedColumns: Array<string> = [];
  chargebeeCustomersByIdMap: { [customerId: string]: IChargebeeCustomer } = {};
  mapInvoiceIdToCreditNoteIds: { [invoiceId: string]: Array<string> } = {};

  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'settings-billing-page' },
    { name: 'settingsTopBar', translationKey: 'settings-top-bar' }
  ];

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    const NUMBER_OF_DATA_TO_FETCH = 2;
    let dataFetched = 0;

    this.injector
      .get(PrivateChargebeeService)
      .getCustomerInfo()
      .then((customerWrapper: IChargebeeCustomerWrapper) => {
        this.chargebeeCustomersByIdMap = _.keyBy(customerWrapper.customers, 'id');

        if (customerWrapper.multiBilling === true) {
          this.invoicesTableDisplayedColumns = ['company', 'date', 'status', 'amount', 'downloadCreditNote', 'download'];
        } else {
          this.invoicesTableDisplayedColumns = ['date', 'status', 'amount', 'downloadCreditNote', 'download'];
        }

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        // An error is already shown

        rejectFetchData();
      });

    this.injector
      .get(PrivateChargebeeService)
      .getInvoices()
      .then((chargebeeInvoices: Array<IChargebeeInvoice>) => {
        this.chargebeeInvoices = chargebeeInvoices;
        this.initCreditNotes();

        dataFetched++;
        if (dataFetched === NUMBER_OF_DATA_TO_FETCH) {
          resolveFetchData();
        }
      })
      .catch(() => {
        // An error is already shown

        rejectFetchData();
      });
  }

  private initCreditNotes(): void {
    if (!this.chargebeeInvoices?.length) {
      return;
    }

    const creditNoteIds = []; // To prevent duplicates

    this.chargebeeInvoices.forEach((iInvoice: IChargebeeInvoice) => {
      if (iInvoice.adjustment_credit_notes?.length) {
        if (!this.mapInvoiceIdToCreditNoteIds[iInvoice.id]) {
          this.mapInvoiceIdToCreditNoteIds[iInvoice.id] = [];
        }
        iInvoice.adjustment_credit_notes.forEach((iCreditNote: IChargebeeCreditNote) => {
          if (creditNoteIds.indexOf(iCreditNote.cn_id) < 0) {
            this.mapInvoiceIdToCreditNoteIds[iInvoice.id].push(iCreditNote.cn_id);
            creditNoteIds.push(iCreditNote.cn_id);
          }
        });
      }

      if (iInvoice.issued_credit_notes?.length) {
        if (!this.mapInvoiceIdToCreditNoteIds[iInvoice.id]) {
          this.mapInvoiceIdToCreditNoteIds[iInvoice.id] = [];
        }
        iInvoice.issued_credit_notes.forEach((iCreditNote: IChargebeeCreditNote) => {
          if (creditNoteIds.indexOf(iCreditNote.cn_id) < 0) {
            this.mapInvoiceIdToCreditNoteIds[iInvoice.id].push(iCreditNote.cn_id);
            creditNoteIds.push(iCreditNote.cn_id);
          }
        });
      }
    });
  }

  protected configureGlobalBar(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.globalBarConfig.pageName = this.i18n.page.pageName;

      this.injector
        .get(SettingsBarService)
        .getOptions(this.i18n.settingsTopBar)
        .then((options) => {
          const optionIndex = _.findIndex(options, ['name', this.i18n.settingsTopBar.billingTab]);

          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;

          resolve();
        })
        .catch(() => {
          // An error is already shown

          resolve();
        });
    });
  }

  protected onFetchingDataError(): void {
    this.refreshGlobalBar();
  }

  downloadInvoice(invoiceId: string): void {
    this.injector.get(PrivateChargebeeService).downloadInvoice(invoiceId);
  }

  downloadCreditNote(creditNoteId: string): void {
    this.injector.get(PrivateChargebeeService).downloadCreditNote(creditNoteId);
  }
}
