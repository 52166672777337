import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orgos-input-simple-radio-button',
  templateUrl: 'input-simple-radio-button.component.html',
  styleUrls: ['input-simple-radio-button.component.scss']
})
export class InputSimpleRadioButtonComponent {
  @Input() label: string = '';
  @Input() value: boolean;
  @Input() indeterminate: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
