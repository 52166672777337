import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fieldConstants from '@carlos-orgos/orgos-utils/constants/field.constants';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../standard/services/core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class WorkflowActionService {
  private WORKFLOW_ACTION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/workflow-action-db`;
  private WORKFLOW_ACTION_PERMISSIONS_KEY: string = 'workflow-action';
  private WORKFLOW_ACTION_INTERNATIONALIZATION: string = 'workflow-action-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private authenticationService: AuthenticationService, private http: HttpClient) {}

  getActionsForWorkflow(workflowId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const findQuery = {
        _workflowId: workflowId
      };
      findQuery[fieldConstants.ID] = { $ne: null };
      this.http
        .post(`${this.WORKFLOW_ACTION_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowActionService.name, 'getActionsForWorkflow'));
        });
    });
  }

  create(data: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .create(this.WORKFLOW_ACTION_URL, data)
        .then((document: any) => {
          resolve(document);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowActionService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.genericService
        .getById(this.WORKFLOW_ACTION_URL, id)
        .then((doc: any) => {
          resolve(doc);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowActionService.name, 'getById'));
        });
    });
  }

  find(findQuery: object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.WORKFLOW_ACTION_URL}/find`, findQuery, httpOptions)
        .toPromise()
        .then((docs) => {
          resolve(docs);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowActionService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.WORKFLOW_ACTION_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowActionService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.WORKFLOW_ACTION_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, WorkflowActionService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.WORKFLOW_ACTION_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.WORKFLOW_ACTION_INTERNATIONALIZATION);
  }
}
