import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { CreateListViewDialog } from '../../../../components/list-view/dialogs/create-list-view.dialog';
import { ListViewComponent } from '../../../../components/list-view/list-view.component';
import { ISelectOption } from '../../../../core/select-option';
import { DepartmentService } from '../../../../services/company/department.service';
import { CloudRoutesService } from '../../../../services/core/cloud-routes.service';
import { IMeetingTemplateModel, MeetingTemplateService } from '../../../../services/performance-management/meeting-template.service';
import { IMeetingModel, MeetingService } from '../../../../services/performance-management/meeting.service';
import { UserPersonalService } from '../../../../services/user/user-personal.service';
import { GenericPage, IMenuOption, ITranslationResource } from '../../../generic.page';
import { NewMeetingDialog } from '../dialogs/new-meeting-dialog/new-meeting.dialog';

@Component({
  selector: 'orgos-meetings',
  templateUrl: 'meetings.page.html',
  styleUrls: ['meetings.page.scss'],
})
export class MeetingsPage extends GenericPage implements AfterViewChecked {
  allMeetings: Array<IMeetingModel>;
  userPersonalMap: any;
  TODAY: any = moment();
  TOTAL_ATTENDEE_SHOWN: number = 4;
  viewOptions: Array<ISelectOption> = [];

  typeValues: Array<string> = picklists.MEETING_TYPE_LIST;
  whenValues: Array<string> = picklists.WHEN_LIST;
  departments: Array<any> = [];
  mapDepartments: any = {};
  templates: Array<IMeetingTemplateModel> = [];
  whenFilter: Array<ISelectOption> = [];

  preferenceKey: string = 'view-meeting';
  selectedView: string;
  viewsLoaded: boolean = false;
  filters: { [id: string]: boolean | string } = {};
  filteredData: Array<IMeetingModel> = [];

  protected translationResources: Array<ITranslationResource> = [
    { name: 'misc', translationKey: 'meetings-misc' },
    { name: 'page', translationKey: 'meetings-page' },
    { name: 'picklists', translationKey: 'standard-picklists' },
  ];

  @ViewChild(ListViewComponent) listView: ListViewComponent;
  ngAfterViewChecked(): void {
    if (check.assigned(this.listView)) {
      this.viewsLoaded = this.listView.isLoaded;

      this.cdr.detectChanges();
    }
  }

  protected fetchLazyData(): void {
    this.injector
      .get(DepartmentService)
      .getDepartments()
      .then((resultDepartments) => {
        this.departments = resultDepartments;
        this.mapDepartments = _.keyBy(resultDepartments, '_id');
      })
      .catch(() => {
        this.departments = [];
      });
    this.injector
      .get(MeetingTemplateService)
      .getAllTemplates()
      .then((resultTemplates) => {
        this.templates = resultTemplates;

        this.templates.forEach((iTemplate) => {
          if (check.assigned(iTemplate.isDefault) && iTemplate.isDefault === true && check.assigned(this.i18n.misc[iTemplate.name])) {
            iTemplate.name = this.i18n.misc[iTemplate.name];
          }
        });
      })
      .catch(() => {
        this.templates = [];
      });
    this.injector
      .get(UserPersonalService)
      .getAllUserPersonal(false, true)
      .then((allUserPersonal: Array<any>) => {
        this.userPersonalMap = _.keyBy(allUserPersonal, '_id');
      })
      .catch(() => {
        this.userPersonalMap = {};
      });
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.injector
      .get(MeetingService)
      .getAllMeetings()
      .then((allMeetings: Array<IMeetingModel>) => {
        this.allMeetings = allMeetings;
        this.filters = {
          'when-today': true,
          'when-thisWeek': true,
        };

        this.filterData();
        resolveFetchData();
      })
      .catch(() => {
        this.allMeetings = [];
        rejectFetchData();
      });
  }

  protected configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.misc.pageName;

    const options: Array<IMenuOption> = [{ name: this.i18n.misc.homeTab, onClick: () => this.router.navigateByUrl('/cloud/home') }];

    if (this.injector.get(CloudRoutesService).checkRoute('goals') === true) {
      options.push({
        name: this.i18n.misc.goalsTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/goals');
        },
      });
    }

    options.push({
      name: this.i18n.misc.meetingsTab,
      onClick: () => this.router.navigateByUrl('/cloud/meetings'),
    });

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 2;

    return Promise.resolve();
  }

  // FILTERS
  public changeFilter(id: string, newValue: boolean | string): void {
    if (newValue === true || (check.string(newValue) && check.not.emptyString(newValue))) {
      this.filters[id] = newValue;
    } else {
      delete this.filters[id];
    }
    this.filterData();
  }

  private filterData(): void {
    const activeFilters = Object.keys(this.filters);

    if (check.emptyArray(activeFilters)) {
      const allMeetingsByTime = _.groupBy(this.allMeetings, (iMeeting) => {
        if (moment.utc().isSame(iMeeting.startDate, 'day')) {
          return 'today';
        } else if (moment.utc().isBefore(iMeeting.startDate, 'day')) {
          return 'future';
        } else {
          return 'past';
        }
      });

      this.filteredData = _.concat(
        [],
        _.orderBy(allMeetingsByTime.today, ['startDate'], ['desc']),
        _.orderBy(allMeetingsByTime.future, ['startDate'], ['asc']),
        _.orderBy(allMeetingsByTime.past, ['startDate'], ['desc'])
      );

      return;
    }

    const activeFiltersByType = _.groupBy(activeFilters, (iFilter) => {
      return iFilter === 'search' ? 'search' : iFilter.split('-')[0];
    });
    if (check.assigned(activeFiltersByType['search'])) {
      activeFiltersByType['search'] = [<string>this.filters['search']];
    }
    const filtering = (filteredResult: Array<IMeetingModel>, iMeeting: IMeetingModel) => {
      let allMeetingData = '';

      if (check.nonEmptyString(iMeeting.name)) {
        allMeetingData += iMeeting.name;
      }
      if (check.nonEmptyString(iMeeting.departmentId)) {
        allMeetingData += `department-${iMeeting.departmentId}`;
      }
      if (check.assigned(iMeeting.templateId) && check.nonEmptyArray(iMeeting.templateId)) {
        iMeeting.templateId.forEach((template) => {
          allMeetingData += `template-${template}`;
        });
      }
      if (check.assigned(iMeeting.startDate)) {
        const now = moment();
        const refDate = moment(iMeeting.startDate);
        const daysAgo = now.startOf('day').diff(refDate.startOf('day'), 'days');
        if (daysAgo === 0) {
          allMeetingData += `when-${picklists.WHEN_TODAY}`;
        } else if (daysAgo > 0) {
          allMeetingData += `when-${picklists.WHEN_PAST}`;
        } else {
          allMeetingData += `when-${picklists.WHEN_UPCOMING}`;
        }

        if (now.week() === refDate.week() && now.year() === refDate.year()) {
          allMeetingData += `when-${picklists.WHEN_THIS_WEEK}`;
        }
      }

      allMeetingData = allMeetingData.toLowerCase();

      const showUser = Object.keys(activeFiltersByType).every((iActiveFilterType) => {
        return activeFiltersByType[iActiveFilterType].some((iFilter) => {
          const transformedFilter = iFilter.trim().toLowerCase();
          return allMeetingData.indexOf(transformedFilter) !== -1;
        });
      });
      if (showUser === true) {
        filteredResult.push(iMeeting);
      }
      return filteredResult;
    };

    const filterApplied = this.allMeetings.reduce(filtering, []);
    const filteredDataByTime = _.groupBy(filterApplied, (iMeeting) => {
      if (moment.utc().isSame(iMeeting.startDate, 'day')) {
        return 'today';
      } else if (moment.utc().isBefore(iMeeting.startDate, 'day')) {
        return 'future';
      } else {
        return 'past';
      }
    });

    this.filteredData = _.concat(
      [],
      _.orderBy(filteredDataByTime.today, ['startDate'], ['desc']),
      _.orderBy(filteredDataByTime.future, ['startDate'], ['asc']),
      _.orderBy(filteredDataByTime.past, ['startDate'], ['desc'])
    );
  }

  public addNewMeeting(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(NewMeetingDialog);

    dialogRef.afterClosed().subscribe((saved) => {
      if (check.assigned(saved) && check.not.emptyString(saved)) {
        this.router.navigate([saved], { relativeTo: this.route });
      }
    });
  }

  // VIEW CONTROLS
  public updateAfterViewChange(viewInfo: any): void {
    if (check.not.assigned(viewInfo) || check.emptyObject(viewInfo)) {
      return;
    }
    this.filters = viewInfo.filters;
    this.selectedView = viewInfo.viewName;
    this.filterData();
  }

  public createView(): void {
    const viewInfo = {
      viewType: this.preferenceKey,
      filters: this.filters,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CreateListViewDialog, { data: { viewInfo } });
    dialogRef.afterClosed().subscribe((viewName) => {
      if (check.assigned(viewName)) {
        this.selectedView = viewName;
        const message = this.i18n.misc.viewCreatedMessage;
        this.injector.get(MatLegacySnackBar).open(message, 'OK', {
          duration: 5000,
        });
      }
    });
  }
}
