<div #timeOffListContainer @fadeInOutAnimation class="sptol-container kenjo-p-20px kenjo-border" (click)="onClick($event)">
  <orgos-column-container [centerColumns]="true" class="kenjo-mb-20px">
    <div *ngIf="formattedDate" orgosColumn class="kenjo-font-size-20px kenjo-pl-5px">{{ formattedDate }}</div>
    <kenjo-icon orgosColumn="0" [size]="24" (click)="closeList()" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
      >clear</kenjo-icon
    >
  </orgos-column-container>
  <div *ngIf="timeOffList.length > 0 && !disconnected" class="sptol-inside-container">
    <orgos-column-container
      *ngFor="let iTimeOffDetail of timeOffList; let i = index"
      [centerColumns]="true"
      class="sptol-list-item kenjo-border kenjo-cursor-pointer"
      (click)="openDetail($event, i)"
      [class.kenjo-mb-10px]="timeOffList?.length > i + 1"
    >
      <div orgosColumn="0" class="sptol-dot" [style.background-color]="USER_COLOR_CONSTANTS[iTimeOffDetail?._timeOffTypeColor]"></div>
      <kenjo-truncate-text orgosColumn class="kenjo-font-size-12px kenjo-font-color-text-333333 sptol-name">{{
        iTimeOffDetail?._timeOffTypeName
      }}</kenjo-truncate-text>
      <orgos-column size="0" class="sptol-card-status">
        <orgos-chip *ngIf="iTimeOffDetail?.status" [color]="statusMaps.color[iTimeOffDetail?.status]">{{
          statusMaps.list[iTimeOffDetail?.status] | uppercase
        }}</orgos-chip>
      </orgos-column>
    </orgos-column-container>
  </div>
  <!-- Details Menu -->
  <div
    class="sptol-details-trigger"
    [class.is-left]="isLeft"
    #detailsMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="timeOffDetailsMenu"
    (menuClosed)="closeDetails()"
  ></div>
  <mat-menu #timeOffDetailsMenu="matMenu" yPosition="above" [xPosition]="isLeft ? 'before' : 'after'" class="sptol-details-menu">
    <kenjo-shift-plan-time-off-details
      *ngIf="selectedTimeOff"
      [parentTranslation]="parentTranslation"
      [statusMaps]="statusMaps"
      [timeOffDetail]="selectedTimeOff"
      [userId]="userId"
      [disconnected]="disconnected"
      (close)="closeDetails()"
    ></kenjo-shift-plan-time-off-details>
  </mat-menu>
  <div *ngIf="timeOffList.length === 0 && !disconnected" class="sptol-empty-list">
    <div>
      <kenjo-icon [size]="63">event_busy</kenjo-icon>
      <div class="no-connection kenjo-font-size-15px kenjo-font-color-text-333333 kenjo-mt-10px">
        <div class="kenjo-text-align-center">{{ parentTranslation.timeOffCancelled }}</div>
        <div class="kenjo-text-align-center">{{ parentTranslation.refreshForUpdates }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="disconnected" class="sptol-empty-list">
    <div>
      <kenjo-icon [size]="63">cloud_off</kenjo-icon>
      <div class="no-connection kenjo-font-size-20px kenjo-font-color-text-333333 kenjo-mt-10px">{{ parentTranslation.noConnection }}</div>
    </div>
  </div>
</div>
