import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { environment } from '@env';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isNotOrgOSRequest = req.url.startsWith(environment.PEOPLE_CLOUD_APP_URL) === false;
    const hasAuthenticationHeader = req.headers.has('Authentication');
    const nobodyIsAuthenticated = this.injector.get(AuthenticationService).isUserAuthenticated() === false;
    if (isNotOrgOSRequest || hasAuthenticationHeader || nobodyIsAuthenticated) {
      return next.handle(req);
    }

    const authToken = this.injector.get(AuthenticationService).getAuthorizationHeader();

    const authReq = req.clone({
      headers: req.headers.set('Authorization', authToken)
    });

    return next.handle(authReq);
  }
}
