import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabGroup } from '@angular/material/legacy-tabs';
import { AttendancePreferencesService } from '@app/cloud-features/settings-attendance/services/attendance-preferences.service';
import { mapUserAttendanceTracking, mapUserOvertimeTracking } from '@app/cloud-features/shift-plan/helpers/shiftplan-attendance.helper';
import { changeCardSize, handleCardSize } from '@app/cloud-features/shift-plan/helpers/shiftplan-card.helper';
import { applyEmployeeFilter, getEmptyFilters, updateFilters } from '@app/cloud-features/shift-plan/helpers/shiftplan-filters.helper';
import { mapById } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import {
  getDaysOfCurrentMonth,
  getDaysOfCurrentWeek,
  getWeekForCreate,
  navigateToDate,
  scrollToDay,
} from '@app/cloud-features/shift-plan/helpers/shiftplan-schedule.helper';
import { groupWishDaysByUser } from '@app/cloud-features/shift-plan/helpers/shiftplan-wish-day.helper';
import { AddShiftDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/add-shift-dialog/add-shift.dialog';
import { ClearScheduleDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/clear-schedule-dialog/clear-schedule-dialog';
import { CopyShiftsDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/copy-shifts-dialog/copy-shifts-dialog';
import { CustomDeleteDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/custom-delete-dialog/custom-delete-dialog';
import { GeneratePDFDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/generate-pdf-dialog/generate-pdf-dialog';
import { PublishShiftDialog } from '@app/cloud-features/shift-plan/pages/schedules-shift-plan/components/publish-shift-dialog/publish-shift-dialog';
import {
  IUserShiftplanWorkSchedules,
  ShiftPlanEmployeeListService,
} from '@app/cloud-features/shift-plan/services/schedules-employee-list.service';
import { ShiftPlanBreaktimeDeductionService } from '@app/cloud-features/shift-plan/services/settings-shift-plan-breaktime-deduction.service';
import {
  IShiftCardSizeSettings,
  IShiftPlanGeneralSettingsModel,
  ShiftPlanGeneralSettingsService,
} from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { IShiftPlanRoleSettingsModel } from '@app/cloud-features/shift-plan/services/settings-shift-plan-role.service';
import { IShiftPlanTagSettingsModel } from '@app/cloud-features/shift-plan/services/settings-shift-plan-tag.service';
import { IShiftPlanWorkingAreaSettingsModel } from '@app/cloud-features/shift-plan/services/settings-shift-plan-working-area.service';
import {
  IShiftplanAttendanceSummary,
  ShiftPlanAttendanceService,
} from '@app/cloud-features/shift-plan/services/shift-plan-attendance.service';
import {
  IShiftCard,
  IShiftPlanCardModel,
  IShiftPlanDate,
  IShiftPlanFilters,
  ShiftCardFilters,
  ShiftPlanCardService,
} from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { IShiftplanExportPDFOptions } from '@app/cloud-features/shift-plan/services/shift-plan-generate-pdf.service';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import {
  IPublicHolidayList,
  IPublicHolidays,
  ShiftplanPublicHolidaysService,
} from '@app/cloud-features/shift-plan/services/shift-plan-public-holidays.service';
import { IShiftPlanTemplate } from '@app/cloud-features/shift-plan/services/shift-plan-template.service';
import { ITimeOffRequestSchedule, ShiftPlanTimeOffService } from '@app/cloud-features/shift-plan/services/shift-plan-time-off.service';
import { IShiftplanWishDay, ShiftplanWishDayService } from '@app/cloud-features/shift-plan/services/shift-plan-wish-day.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { FeatureGatingService } from '@app/standard/components/feature-gating/services/feature-gating.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ISelectOption } from '@app/standard/core/select-option';
import { GenericPage, IMenuOption } from '@app/standard/pages/generic.page';
import { ILocationOfficeModel } from '@app/standard/services/company/office.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { PicklistsService } from '@app/standard/services/core/picklists.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import { UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import * as featureGatingConstants from '@carlos-orgos/orgos-utils/constants/feature-gating.constants';
import {
  SHIFT_PLAN_CARD_COMPACT,
  SHIFT_PLAN_CARD_EXPANDED,
  SHIFT_PLAN_CARD_SIZE_LIST,
  SHIFT_PLAN_CARD_STATUS_DRAFT,
  SHIFT_PLAN_CARD_STATUS_PUBLISHED,
  SHIFT_PLAN_LAYOUT_LIST,
  SHIFT_PLAN_MONTHLY_VIEW,
  SHIFT_PLAN_ROLE_LAYOUT,
  SHIFT_PLAN_USER_LAYOUT,
  SHIFT_PLAN_WEEKLY_VIEW,
  TIME_OFF_REQUEST_STATUS_APPROVED,
  TIME_OFF_REQUEST_STATUS_IN_APPROVAL,
  TIME_OFF_REQUEST_STATUS_PENDING,
  TIME_OFF_REQUEST_STATUS_PROCESSED,
  TIME_OFF_REQUEST_STATUS_SUBMITTED,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as moment from 'moment';

type ChipColor = 'Neutral' | 'Warning' | 'Danger';
@Component({
  selector: 'kenjo-schedules-shift-plan',
  templateUrl: 'schedules-shift-plan.page.html',
  styleUrls: ['schedules-shift-plan.page.scss'],
})
export class SchedulesShiftPlanPage extends GenericPage implements AfterViewChecked {
  pageTranslation: { [key: string]: string };
  loading: boolean = false;
  shiftTranslation: { [key: string]: string };
  timeOffTranslation: { [key: string]: string };
  monthRange: IShiftPlanDate | null;
  weekRange: IShiftPlanDate | null;
  today: moment.Moment = moment.utc().startOf('day');
  selectedDayIndex: number = 0;
  daysOfCurrentWeek: { value: Array<moment.Moment>; labels: Array<string> };
  daysOfCurrentMonth: { value: Array<moment.Moment>; labels: Array<string> };
  currentUser: IUserAccountModel;
  layout: string;
  layoutOptions: Array<ISelectOption>;
  view: string;
  viewOptions: Array<ISelectOption>;
  cardSize: string;
  cardSizeOptions: Array<ISelectOption>;
  cardSizeSettings: IShiftCardSizeSettings;
  roles: Array<IShiftPlanRoleSettingsModel> = [];
  tags: Array<IShiftPlanTagSettingsModel> = [];
  workingAreas: Array<IShiftPlanWorkingAreaSettingsModel> = [];
  locations: Array<ILocationOfficeModel> = [];
  allEmployees: any;
  allDataResponse: any;
  generalSettings: IShiftPlanGeneralSettingsModel;
  DATE_VALUES = { BEFORE: -1, CURRENT: 0, AFTER: 1 };
  show: { [key: string]: boolean } = {
    filters: true,
    layoutMenu: false,
    templateMenu: false,
    templateDetails: false,
    publishReminder: false,
    cards: true,
    loadingMore: false,
    today: false,
    minimal: false,
  };
  breaktimeDeduction: boolean = false;
  allTemplates: Array<IShiftPlanTemplate> = [];
  draggedTemplate: IShiftPlanTemplate;
  draggedShiftCard: IShiftPlanCardModel;
  draggedShiftCardDateIndex: number;
  LIST_IDS: Array<string> = [];
  cdkDropListString: string = 'cdk-drop-list';
  OPEN_SHIFT: string = 'openShift';
  EMPLOYEE_LAYOUT: string = 'employeeLayout';
  dataLoaded: boolean = false;
  chipColors: { [key: string]: ChipColor } = {
    neutral: 'Neutral',
    warning: 'Warning',
    danger: 'Danger',
  };
  colorConstants: any = userColorConstants;
  DEFAULT_VALUES = {
    LAYOUT: SHIFT_PLAN_USER_LAYOUT,
    VIEW: SHIFT_PLAN_WEEKLY_VIEW,
    CARD_SIZE: SHIFT_PLAN_CARD_COMPACT,
  };
  allFilterOptions: IShiftPlanFilters;
  originalData: IShiftPlanFilters;
  shiftCards;
  employeeSchedules: IUserShiftplanWorkSchedules;
  userMapTotalWorkingTime: { [employeeId: string]: { totalWorkingHours: number } } | undefined;
  SHIFTS_PAGINATION = 3;
  SHIFTS_PAGE = 1;
  PENDING_SHIFTS = 0;
  SHIFTS_PAGINATION_EMPLOYEE = 50;
  SHIFTS_PAGE_EMPLOYEE = 1;
  PENDING_EMPLOYEE = 0;
  MAXIMUM_EMPLOYEES_PER_ROLE = 15;
  weekDaysArray: Array<number> = Array.from(Array(7).keys());
  isTemplate: boolean = false;
  scrollBlocked: boolean = false;
  timeOffs: ITimeOffRequestSchedule;
  attendanceSummary: IShiftplanAttendanceSummary;
  showNoResultsForFiltersApllied: boolean = false;
  isEmptyPublicHolidays: boolean = false;
  dropdownCalendars: { _id: string; name: string; isOffice: boolean; isSelected: boolean }[] = [];
  attendanceStartDay: moment.Moment;
  usersTrackingSettings: { [id: string]: boolean };
  showOvertimeTracking: boolean = false;
  usersOvertimeTracking: { [id: string]: boolean };

  @ViewChild('mainContainer') container: ElementRef;

  @HostBinding('style.--monthly-tab') tabWidthMinimal: string;

  tabsDate: MatLegacyTabGroup;
  tabWidth: number | undefined;
  @ViewChild('tabsDate', { static: false }) set tabsContent(content: MatLegacyTabGroup) {
    if (content) {
      this.tabsDate = content;
      this.tabWidth = this.tabsDate._elementRef.nativeElement.clientWidth / this.tabsDate._tabs.length;
      this.injector.get(ChangeDetectorRef).detectChanges();
    }
  }
  hasScrolled = { top: false, left: false };
  isAtTop: boolean = true;
  scrollInfo = { hasToScroll: false, lastScroll: 0, left: 0 };
  hoverInfo = { index: -1, value: false };
  filters: ShiftCardFilters = { ...getEmptyFilters() };
  filtersApplied: ShiftCardFilters = { ...getEmptyFilters() };
  filtersPreference: ShiftCardFilters = { ...getEmptyFilters() };
  activeOptions: { [category: string]: string } = {};
  shiftplanPermissions: IShiftPlanPermissions;
  closePanel: boolean = false;
  SHIFTS_LIMIT_TO_DELETE = { CLEAR: 18000, CUSTOM: 2000 };
  panelStatus: 'close' | 'open' | 'standby' = 'standby';
  isDragging: boolean = false;
  isDropping: boolean = false;
  isSelectShiftsActive: boolean = false;
  clearAll: boolean = false;
  selectAll: boolean = false;
  isPublishing: boolean = false;
  selectedShiftIds: { [key: string]: { _id: string; ownerId: string; isOpen: boolean } } = {};
  selectedShiftIdsLength: number = 0;
  shiftsNumber: number = 0;
  mappedCollections: any;
  actionAfterLeaveIntent: () => void;
  statusTimeOffMaps: { color: { [key: string]: string }; list: { [key: string]: string } };
  isUserAdminOrManager: boolean = false;

  exporting: boolean = false;
  generatePDFtranslations: { [key: string]: string };
  optionsPDF: IShiftplanExportPDFOptions;
  shiftsPDF: any;
  PREFERENCE_KEY: string = 'shift-plan-schedule';
  blockDrop = { open: false, scheduled: false };
  viewOptionLabels: { [key: string]: string };
  cardSizePreferences: { week: string; month: string };
  publicHolidays: Array<Array<IPublicHolidays>> = [];
  isLoaded: boolean = false;

  wishDaysById: Record<string, Array<IShiftplanWishDay>> = {};

  VIEWS = { MONTH: SHIFT_PLAN_MONTHLY_VIEW, WEEK: SHIFT_PLAN_WEEKLY_VIEW };
  LAYOUT = { USER: SHIFT_PLAN_USER_LAYOUT, ROLE: SHIFT_PLAN_ROLE_LAYOUT };
  CARD_SIZE = { COMPACT: SHIFT_PLAN_CARD_COMPACT, EXPANDED: SHIFT_PLAN_CARD_EXPANDED };
  STATUS = { DRAFT: SHIFT_PLAN_CARD_STATUS_DRAFT, PUBLISHED: SHIFT_PLAN_CARD_STATUS_PUBLISHED };

  protected async fetchData(resolveFetchData: Function): Promise<void> {
    try {
      await this.initPermissions();
      await Promise.all([this.initSettings(), this.initTranslations(), this.initShiftplanData()]);
      await this.initLayoutAndViewOptions();
      await Promise.all([this.getShiftCards(), this.fetchTimeOffTranslations(), this.setPublishReminder()]);
      await this.initRest();
      resolveFetchData();
      this.dataLoaded = true;
    } catch {
      this.pageTranslation = {};
      this.dataLoaded = true;
    }
  }

  protected async configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.pageTranslation.pageName;
    const options: Array<IMenuOption> = [];
    if (this.shiftplanPermissions['view_schedule']) {
      options.push({
        key: 'shiftplan-schedules',
        name: this.pageTranslation.schedulesTab,
        onClick: () => this.router.navigateByUrl('/cloud/shift-plan/schedules'),
      });
    }
    if (this.shiftplanPermissions['view_settings']) {
      options.push({
        key: 'shiftplan-settings',
        name: this.pageTranslation.settingsTab,
        onClick: () => this.router.navigateByUrl('/cloud/shift-plan/settings'),
      });
    }

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = 0;
    return Promise.resolve();
  }

  async initPermissions() {
    await this.injector.get(ShiftPlanPermissionsService).refreshPermissions();
    this.shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
    this.currentUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.setBlockDrop(true, true);
    this.isUserAdminOrManager =
      this.currentUser.profileKey === 'admin' || this.currentUser.profileKey === 'hr-admin' || this.currentUser.profileKey === 'manager';
  }

  private async getFilterPreferences(): Promise<void> {
    this.allFilterOptions = await this.injector.get(ShiftPlanCardService).getShiftCardsFilters();
    this.originalData = { ...this.allFilterOptions };
  }

  async initSettings() {
    const [generalSettings, breaktimeDeduction] = await Promise.all([
      this.injector.get(ShiftPlanGeneralSettingsService).getGeneralSettings(),
      this.injector.get(ShiftPlanBreaktimeDeductionService).getBreaktimeDeduction(),
    ]);
    this.generalSettings = generalSettings[0];
    this.breaktimeDeduction = breaktimeDeduction.breaktimeDeduction;
  }

  async initTranslations() {
    this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-schedules-page');
    this.shiftTranslation = {
      hour: this.pageTranslation.hour,
      minute: this.pageTranslation.minute,
      noTag: this.pageTranslation.noTag,
    };
  }

  async initCalendars() {
    try {
      this.dropdownCalendars = [];
      if (this.generalSettings?.showPublicHolidays === true) {
        const result: IPublicHolidayList = await this.getPublicHolidays();
        this.dropdownCalendars = result.dropdownCalendarList;
      } else {
        this.isEmptyPublicHolidays = true;
      }
    } catch {
      this.publicHolidays = [];
      this.isEmptyPublicHolidays = true;
    }
  }

  private async getPublicHolidays() {
    const dateRange = this.getDateRange();
    const result: IPublicHolidayList = await this.injector
      .get(ShiftplanPublicHolidaysService)
      .getPublicHoliday({ fromDate: dateRange.from.toDate(), toDate: dateRange.to.toDate() });

    this.publicHolidays = result.publicHolidays;
    this.isEmptyPublicHolidays = !this.publicHolidays.some((subArray) => subArray.length > 0);
    return result;
  }

  async toggleCalendarSelection(id: string): Promise<void> {
    const selectedCalendars = this.dropdownCalendars.filter((calendar) => calendar.isSelected).map(({ _id }) => _id);
    const index = selectedCalendars.indexOf(id);
    let isSelected = false;
    if (index === -1) {
      isSelected = true;
      selectedCalendars.push(id);
    } else {
      isSelected = false;
      selectedCalendars.splice(index, 1);
    }

    await this.setShiftplanPreference({ publicHolidays: selectedCalendars });
    await this.getPublicHolidays();
    const updatedCalendar = this.dropdownCalendars.find((calendar) => calendar._id === id);

    if (updatedCalendar) {
      updatedCalendar.isSelected = isSelected;
    }
  }

  async initShiftplanData() {
    const [response] = await Promise.all([
      this.injector.get(ShiftPlanEmployeeListService).getEmployeesDetailed(),
      this.getFilterPreferences(),
    ]);
    this.allDataResponse = response;
    this.roles = this.sortByField(response.roles, 'name');
    this.tags = response.tags;
    this.workingAreas = this.sortByField(response.workingAreas, 'name');
    this.locations = response.locations;
    this.allEmployees = response.users;
    this.allTemplates = this.shiftplanPermissions['templates'].read ? response.templates.sort(this.sortByDate()) : [];
  }

  async initLayoutAndViewOptions() {
    try {
      const optionPromises = ['shiftPlanSchedulesLayoutOptions', 'shiftPlanSchedulesViewOptions', 'shiftPlanSchedulesCardSizeOptions'].map(
        (iPicklist) => this.injector.get(PicklistsService).getPicklist(iPicklist)
      );
      const [layoutOptions, viewOptions, cardSizeOptions] = await Promise.all(optionPromises);
      this.viewOptionLabels = viewOptions as {};

      const availableOptions = {
        layout: SHIFT_PLAN_LAYOUT_LIST,
        view: [this.VIEWS.WEEK, this.VIEWS.MONTH],
        cardSize: ['minimal', ...SHIFT_PLAN_CARD_SIZE_LIST],
      };
      this.layoutOptions = Object.keys(layoutOptions).reduce((acc, currLayoutOption) => {
        if (availableOptions.layout.includes(currLayoutOption)) {
          acc = [...acc, { name: layoutOptions[currLayoutOption], value: currLayoutOption }];
        }
        return acc;
      }, []);

      this.viewOptions = Object.keys(viewOptions).reduce((acc, currViewOption) => {
        if (availableOptions.view.includes(currViewOption)) {
          acc = [...acc, { name: viewOptions[currViewOption], value: currViewOption }];
        }
        return acc;
      }, []);

      this.cardSizeOptions = Object.keys(cardSizeOptions).reduce((acc, currCardSizeOption) => {
        if (availableOptions.cardSize.includes(currCardSizeOption)) {
          acc = [...acc, { name: cardSizeOptions[currCardSizeOption], value: currCardSizeOption }];
        }
        return acc;
      }, []);

      const shiftPlanPreferences = (await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_KEY))?.preference;

      this.layout = shiftPlanPreferences?.layout ?? this.DEFAULT_VALUES.LAYOUT;
      this.view = shiftPlanPreferences?.view ?? this.DEFAULT_VALUES.VIEW;
      this.initCardSize(shiftPlanPreferences?.cardSize);

      this.filtersApplied = { ...getEmptyFilters() };
      this.filtersPreference = shiftPlanPreferences?.filters ?? { ...getEmptyFilters() };

      await this.applySavedFilter();
      //Keep this because some operations are still only week range based
      this.changeWeek(this.DATE_VALUES.CURRENT);
      if (this.view === this.VIEWS.MONTH) {
        this.changeMonth(this.DATE_VALUES.CURRENT);
      }

      this.cdr.detectChanges();
    } catch {
      //do nothing
    }
  }

  initCardSize(cardSize) {
    this.cardSize = handleCardSize({ cardSize, view: this.view });
    this.cardSizePreferences = check.nonEmptyObject(cardSize)
      ? cardSize
      : { week: this.DEFAULT_VALUES.CARD_SIZE, month: this.DEFAULT_VALUES.CARD_SIZE };
    this.cardSizePreferences[this.view] = this.cardSize;
  }

  async initRest() {
    await this.initTimeOffSub();
    this.mapShiftPlanCollectionsById();
    this.changeCardSize();
    await this.initCalendars();
  }

  private async applySavedFilter() {
    this.allFilterOptions = { ...this.originalData };
    this.applyFilterAndUpdate('employees');

    Object.keys(this.allFilterOptions).forEach((filter) => {
      if (filter !== 'employees') {
        this.applyFilterAndUpdate(filter);
      }
    });

    await this.setShiftplanPreference({ filters: this.filtersPreference });
  }

  private applyFilterAndUpdate(filterOption: string) {
    const resultingEmployeeFiltersApplied = updateFilters(filterOption, this.allFilterOptions, this.filtersPreference);
    this.allFilterOptions = resultingEmployeeFiltersApplied.allFilterOptions;
    this.filtersPreference = resultingEmployeeFiltersApplied.filtersPreference;

    this.filtersApplied[filterOption] = this.filtersPreference[filterOption];

    const employeeFilteredOptions = this.allFilterOptions[filterOption].map((element) => {
      return { ...element, active: this.filtersApplied[filterOption]?.includes(element._id) };
    });
    const resultingFiltersApplied = applyEmployeeFilter(
      filterOption,
      employeeFilteredOptions,
      this.allFilterOptions,
      this.originalData,
      this.filtersApplied
    );

    this.allFilterOptions = resultingFiltersApplied.allFilterOptions;
    this.filtersApplied = resultingFiltersApplied.filtersApplied;
  }

  private async initTimeOffSub() {
    this.injector.get(ShiftPlanTimeOffService).isAfterFirstReload = true;
    if (!this.generalSettings.showTimeOff || !this.isUserAdminOrManager || check.emptyArray(this.allEmployees)) {
      return;
    }

    const dateRange = this.getDateRange();
    this.injector.get(ShiftPlanTimeOffService).subscribeTimeOffs(
      dateRange.from.toDate(),
      dateRange.to.endOf('day').toDate(),
      this.allEmployees.map((employee) => employee._id),
      this.view,
      { snackbar: this.pageTranslation.timeOffSnackbar, refresh: this.pageTranslation.refresh }
    );
  }

  private reloadSubscription(): void {
    const timestamp$ = this.injector.get(ShiftPlanTimeOffService).timeOffTimestamp$;
    if (!this.generalSettings.showTimeOff || !timestamp$ || check.emptyArray(this.allEmployees)) {
      return;
    }

    const dateRange = this.getDateRange();
    this.injector.get(ShiftPlanTimeOffService).reloadSubscription(
      dateRange.from.toDate(),
      dateRange.to.endOf('day').toDate(),
      this.allEmployees.map((employee) => employee._id),
      this.view,
      { snackbar: this.pageTranslation.timeOffSnackbar, refresh: this.pageTranslation.refresh }
    );
  }

  public changeWeek(newWeek: IShiftPlanDate | number) {
    this.isLoaded = false;
    this.handleViewDateRange(this.VIEWS.WEEK, newWeek);
    this.initWeekDays();

    const isCurrentWeek = this.today.isBetween(this.weekRange.from, this.weekRange.to.endOf('day'), 'day', '[]');
    this.show.today = !isCurrentWeek;
    this.isLoaded = true;
  }

  public changeMonth(newMonth: IShiftPlanDate | number) {
    this.handleViewDateRange(this.VIEWS.MONTH, newMonth);
    this.initMonthDays();
  }

  handleViewDateRange(view: 'week' | 'month', newValue: IShiftPlanDate | number) {
    const viewMap = {
      week: 'weekRange',
      month: 'monthRange',
    };
    const viewValue = viewMap[view];
    let from: moment.Moment, to: moment.Moment;
    if (newValue === this.DATE_VALUES.CURRENT) {
      this[viewValue] = {
        from: moment.utc().startOf(view),
        to: moment.utc().endOf(view).endOf('day'),
      };
      return;
    }
    if (check.not.number(newValue)) {
      const newValueRange = newValue as IShiftPlanDate;
      this[viewValue] = {
        from: moment.utc(newValueRange?.from).locale(this.currentUser.locale).startOf(view),
        to: moment.utc(newValueRange?.from).locale(this.currentUser.locale).endOf(view).endOf('day'),
      };
      return;
    }
    ({ from, to } = navigateToDate(this[viewValue], view, newValue as number));
    this[viewValue] = { from, to };
  }

  calculateDateTabWidth() {
    this.show.minimal = this.cardSize === 'minimal';
    const tabWidth = this.show.minimal ? (window.innerWidth - 325) / this.daysOfCurrentMonth.labels.length : 100;
    if (tabWidth < 49) {
      this.tabWidthMinimal = '49px';
      return;
    }
    this.tabWidthMinimal = `${tabWidth}px`;
  }

  private initMonthDays() {
    const { daysOfThisMonth, labelsOfThistMonth, selectedDayIndex } = getDaysOfCurrentMonth(this.monthRange.from);
    this.daysOfCurrentMonth = { value: daysOfThisMonth, labels: labelsOfThistMonth };
    this.selectedDayIndex = selectedDayIndex;
    this.calculateDateTabWidth();
  }

  public initWeekDays(): void {
    const { daysOfCurrentWeek, labelsOfCurrentWeek, selectedDayIndex } = getDaysOfCurrentWeek(this.weekRange.from);
    this.daysOfCurrentWeek = { value: daysOfCurrentWeek, labels: labelsOfCurrentWeek };
    this.selectedDayIndex = selectedDayIndex;
  }

  private sortByField(unsortedList: Array<any>, field: string): Array<any> {
    const sortedList = unsortedList.sort((a: any, b: any) => {
      const nameA = a[field].toLowerCase();
      const nameB = b[field].toLowerCase();

      if (nameA > nameB) {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }
      return 0;
    });
    return sortedList;
  }

  public navigateToSettings(): void {
    if (!this.shiftplanPermissions['view_settings']) {
      return;
    }
    this.router.navigateByUrl('/cloud/shift-plan/settings/roles');
  }

  async getWeeklyUserWorkSchedules(range) {
    return await this.injector.get(ShiftPlanEmployeeListService).getEmployeesSchedules(range.from, range.to, this.VIEWS.WEEK);
  }

  async addNewShift(selectedDayIndex = null, selectedData = {}, template: null | IShiftPlanTemplate = null) {
    if (this.loading || this.isDropping) {
      return;
    }

    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = () => this.addNewShift(selectedDayIndex, selectedData, template);
      this.openLeaveSelectionDialog();
      return;
    }
    const timeOffService = this.injector.get(ShiftPlanTimeOffService);
    timeOffService.changeState = { subscriptionPaused: true };
    const lastScroll = this.container.nativeElement.scrollTop;

    const { daysOfCurrentWeek, range, dayIndex } = this.getDateRangeForActions({
      selectedDayIndex,
    });

    const weeklyWorkSchedule = await this.getWeeklyUserWorkSchedules(range);

    const openDialogTime = new Date();
    const addDialog = this.injector.get(MatLegacyDialog).open(AddShiftDialog, {
      data: {
        response: this.allDataResponse,
        selectedDayIndex: dayIndex,
        selectedData,
        days: daysOfCurrentWeek,
        startDay: range.from,
        endDay: range.to,
        readOnly: false,
        employeesWorkSchedule: weeklyWorkSchedule,
        noPermissionsTooltip: this.pageTranslation.noPermissionsTooltip,
        ...(check.assigned(template) && { shift: { ...this.getShiftBodyFromTemplate(template) } }),
        openShiftSettings: this.generalSettings.openShifts,
      },
    });
    addDialog.afterClosed().subscribe(async ({ newShift, destination }) => {
      timeOffService.changeState = { subscriptionPaused: false };
      const closeDialogTime = new Date();
      const timeElapsed = closeDialogTime.getTime() - openDialogTime.getTime();
      this.injector.get(PrivateAmplitudeService).logEvent('time spent to add shifts', {
        category: 'Shiftplan',
        subcategory: 'Scheduling',
        subcategory2: 'Time spent',
        value: timeElapsed,
      });
      if (newShift) {
        if (check.assigned(template)) {
          this.injector.get(PrivateAmplitudeService).logEvent('added shift from template', {
            category: 'Shiftplan',
            subcategory: 'templates',
            subcategory2: 'using templates',
          });
        } else {
          this.injector
            .get(PrivateAmplitudeService)
            .logEvent(this.layout === this.LAYOUT.ROLE ? 'shift add role layout' : 'shift add employee layout', {
              category: 'Shiftplan',
              subcategory: 'Scheduling',
              subcategory2: 'Shifts addition',
            });
        }
        await this.refreshCalendar(lastScroll, destination);
      } else if (timeOffService.states.snackbarPaused) {
        this.injector.get(ShiftPlanTimeOffService).showRefreshSnackBar();
        timeOffService.changeState = { snackbarPaused: false };
      }
    });
  }

  getDateRangeForActions({ selectedDayIndex }) {
    const dateRange = this.getDateRange();
    const isMonthly = this.view === 'month';
    const currentDays = isMonthly ? this.daysOfCurrentMonth.value : this.daysOfCurrentWeek.value;
    const weekRangeData = getWeekForCreate({
      isMonthly,
      dateRange,
      selectedDayIndex,
      currentDays,
      locale: this.currentUser.locale,
    });
    return weekRangeData;
  }

  registerNewShift(plain = false) {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent(
        plain
          ? 'click add new shift'
          : this.layout === this.LAYOUT.ROLE
          ? 'shift add intent role layout'
          : 'shift add intent employee layout',
        { category: 'Shiftplan', subcategory: 'Scheduling', subcategory2: 'Shifts addition' }
      );
  }

  public async changeFilters({ optionKey, options }) {
    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = async () => await this.changeFilters({ optionKey, options });
      this.openLeaveSelectionDialog(this.pageTranslation.leaveSelectionSubtitleFilters);
      return;
    }

    const resultingFiltersApplied = applyEmployeeFilter(optionKey, options, this.allFilterOptions, this.originalData, this.filtersApplied);

    this.allFilterOptions = resultingFiltersApplied.allFilterOptions;
    this.filtersApplied = resultingFiltersApplied.filtersApplied;

    this.filtersPreference = this.filtersApplied;
    this.setShiftplanPreference({ filters: this.filtersPreference });

    if (this.allFilterOptions.employees.length !== 0) {
      this.showNoResultsForFiltersApllied = false;
      await this.getShiftCards();
      return;
    }
    this.showNoResultsForFiltersApllied = true;
  }

  clearFilters() {
    Object.keys(this.originalData).forEach((filter) => {
      if (Array.isArray(this.originalData[filter])) {
        this.originalData[filter].forEach((element: any) => {
          if (element.hasOwnProperty('active')) {
            delete element.active;
          }
        });
      }
    });

    this.changeFilters({ optionKey: '', options: [] });
  }

  private checkFiltersApplied() {
    this.allFilterOptions = { ...this.originalData };

    const locations = this.allFilterOptions.locations?.filter((location) => location.active).map((location) => location._id);
    const roles = this.allFilterOptions.roles?.filter((role) => role.active).map((role) => role._id);
    const workingAreas = this.allFilterOptions.workingAreas
      ?.filter((workingArea) => workingArea.active)
      .map((workingArea) => workingArea._id);
    const employees = this.allFilterOptions.employees?.filter((employee) => employee.active).map((employee) => employee._id);
    if (
      this.filtersApplied.employees.length !== employees.length ||
      this.filtersApplied.locations.length !== locations.length ||
      this.filtersApplied.workingAreas.length === workingAreas.length ||
      this.filtersApplied.roles.length === roles.length
    ) {
      this.filtersApplied.roles = roles;
      this.filtersApplied.locations = locations;
      this.filtersApplied.employees = employees;
      this.filtersApplied.workingAreas = workingAreas;

      this.allFilterOptions.employees = this.allFilterOptions.employees.filter((employee) => {
        return (
          (workingAreas.length === 0 || employee.workingAreas.some((workingAreasIds) => workingAreas.includes(workingAreasIds + ''))) &&
          (locations.length === 0 || employee.locations.some((locationId) => locations.includes(locationId + ''))) &&
          (roles.length === 0 || employee.roles.some((roleId) => roles.includes(roleId + '')))
        );
      });
    }
  }

  async openShiftReadOnlyMode(shift: any, selectedDayIndex = null, selectedData = {}) {
    if (this.loading || this.isDropping) {
      return;
    }

    const lastScroll = this.container.nativeElement.scrollTop;
    if (check.not.assigned(shift) || check.emptyObject(shift) || shift.isPublished === true) {
      return;
    }

    const { range, daysOfCurrentWeek } = this.getDateRangeForActions({ selectedDayIndex });
    const weeklyWorkSchedule = await this.getWeeklyUserWorkSchedules(range);
    const readShiftDialog = this.injector.get(MatLegacyDialog).open(AddShiftDialog, {
      data: {
        response: this.allDataResponse,
        selectedDayIndex,
        selectedData,
        days: daysOfCurrentWeek,
        startDay: range.from,
        endDay: range.to,
        shift,
        readOnly: true,
        employeesWorkSchedule: weeklyWorkSchedule,
        notificationSetting: this.generalSettings.sendNotifications,
        noPermissionsTooltip: this.pageTranslation.noPermissionsTooltip,
        openShiftSettings: this.generalSettings.openShifts,
        filters: this.filters,
      },
    });

    readShiftDialog.afterClosed().subscribe(async (shiftDeleted) => {
      if (shiftDeleted === true) {
        this.showSelectShiftsPopUp(false);
        this.refreshCalendar(lastScroll);
      }
    });
  }

  private async refreshCalendar(lastScroll, destination?: IShiftPlanDate) {
    if (check.assigned(destination)) {
      const navigateTo =
        this.view === this.VIEWS.WEEK ? () => this.navigateToWeek(destination) : () => this.refreshMonthCalendar(destination);
      await navigateTo();
      return;
    }

    await this.getShiftCards();
    this.scrollInfo.hasToScroll = true;
    this.scrollInfo.lastScroll = lastScroll;
    this.reloadSubscription();
  }

  async refreshMonthCalendar(destination: IShiftPlanDate) {
    await this.navigateToMonth(destination);
    scrollToDay({ day: destination.from }, this.container);
  }

  ngAfterViewChecked(): void {
    if (this.scrollInfo.hasToScroll && this.container.nativeElement.scrollTop === 0) {
      this.container.nativeElement.scrollTo({ top: this.scrollInfo.lastScroll });
      this.scrollInfo.hasToScroll = false;
    }
  }

  async getShiftCards() {
    this.LIST_IDS = [];
    this.loading = true;
    await Promise.all([this.fetchShiftCards(), this.getTimeOffSchedule(), this.getAttendanceData(), this.getWishDays()]);
    this.SHIFTS_PAGE = 1;
    this.calculatePendingShifts();
    this.calculatePendingEmployees();
    this.setIdsDragAndDrop();
    this.loading = false;
    this.showSelectShiftsPopUp(false);
    this.injector.get(ShiftPlanTimeOffService).changeState = { subscriptionPaused: false };
  }

  async fetchShiftCards(onlyCapacity = false) {
    try {
      const dateRange = this.getDateRange();
      this.employeeSchedules = await this.injector
        .get(ShiftPlanEmployeeListService)
        .getEmployeesSchedules(dateRange.from, dateRange.to, this.view);

      this.filters = {
        locations: this.allFilterOptions.locations.filter((location) => location.active).map((location) => location._id),
        roles: this.allFilterOptions.roles.filter((role) => role.active).map((location) => location._id),
        workingAreas: this.allFilterOptions.workingAreas.filter((workingArea) => workingArea.active).map((workingArea) => workingArea._id),
        employees: this.allFilterOptions.employees.filter((employee) => employee.active).map((employee) => employee._id),
        tags: this.allFilterOptions.tags.filter((tag) => tag.active).map((tag) => tag._id),
      };

      if (
        this.filtersApplied.employees?.length === 0 &&
        (this.filtersApplied.roles?.length !== 0 ||
          this.filtersApplied.locations?.length !== 0 ||
          this.filtersApplied.workingAreas?.length !== 0 ||
          this.filtersApplied.tags?.length !== 0)
      ) {
        this.filters.employees = this.allFilterOptions.employees.map((employee) => employee._id);
      }

      const shiftCards = await this.injector
        .get(ShiftPlanCardService)
        .getShiftCards(dateRange.from, dateRange.to.endOf('day'), this.filters, this.layout);
      this.userMapTotalWorkingTime = shiftCards.userMapTotalWorkingTime;
      if (onlyCapacity) {
        this.shiftCards.employeeCountByDays = [...shiftCards.employeeCountByDays];
        this.shiftCards.workingTimeInMinutesPerDay = [...shiftCards.workingTimeInMinutesPerDay];
      } else {
        this.shiftCards = shiftCards;
      }
      if (this.layout === this.LAYOUT.ROLE) {
        this.shiftCards.shiftsGrouped
          .filter((role) => role.shiftsGrouped.length <= this.MAXIMUM_EMPLOYEES_PER_ROLE)
          .forEach((role) => (role.expanded = true));
      }
      this.reloadSubscription();
    } catch {}
  }

  getDateRange() {
    if (this.view === this.VIEWS.WEEK) {
      return (
        this.weekRange ?? {
          from: this.monthRange.from.startOf('week'),
          to: this.monthRange.from.endOf('week').endOf('day'),
        }
      );
    }

    if (this.view === this.VIEWS.MONTH) {
      return (
        { from: this.monthRange.from, to: this.monthRange.to.endOf('month').endOf('day') } ?? {
          from: this.weekRange.from.startOf('month'),
          to: this.weekRange.from.endOf('month').endOf('day'),
        }
      );
    }
  }

  async setShiftplanPreference(preference: object) {
    const currPreference = (await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_KEY))?.preference ?? {};
    await this.injector.get(PreferenceService).setPreferenceByKey(this.PREFERENCE_KEY, { ...currPreference, ...preference });
  }

  async registerChangeLayout() {
    this.injector.get(PrivateAmplitudeService).logEvent(this.layout === this.LAYOUT.ROLE ? 'click role layout' : 'click employee layout', {
      category: 'Shiftplan',
      subcategory: 'Scheduling',
      subcategory2: 'Layout Usage',
    });
    await this.getShiftCards();
  }

  public async openPublishDialog() {
    if (this.loading) {
      return;
    }

    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = async () => await this.openPublishDialog();
      this.openLeaveSelectionDialog();
      return;
    }
    this.isPublishing = true;
    this.injector.get(PrivateAmplitudeService).logEvent('publish shift step 1', {
      category: 'Shiftplan',
      subcategory: 'Publishing',
      subcategory2: 'publish steps',
    });

    const dateRange = this.getDateRange();
    const PUBLISH_LIMIT = 10000;
    const shiftCards = await this.injector
      .get(ShiftPlanCardService)
      .getShiftCards(dateRange.from.toDate(), dateRange.to.endOf('day'), this.filters, this.layout, false);
    const data = {
      startDay: dateRange.from,
      endDay: dateRange.to,
      view: this.view,
      openShiftsCount: shiftCards.openDraftCount,
      openShiftsMinutesCount: shiftCards.openDraftMinutesCount,
      scheduledShiftsCount: shiftCards.scheduledDraftCount,
      scheduledShiftsMinutesCount: shiftCards.scheduledDraftMinutesCount,
      scheduledShiftsEmployeeCount: shiftCards.scheduledDraftEmployeesCount,
      notificationSetting: this.generalSettings.sendNotifications,
      publishShiftsLimit: shiftCards.openDraftCount + shiftCards.scheduledDraftCount >= PUBLISH_LIMIT,
      filters: this.filters,
      otherShiftsOwners: shiftCards.otherShiftsOwners,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(PublishShiftDialog, { data });
    dialogRef.afterClosed().subscribe(async (hasPublished) => {
      if (hasPublished) {
        this.injector.get(PrivateAmplitudeService).logEvent('publish shift step 2', {
          category: 'Shiftplan',
          subcategory: 'Publishing',
          subcategory2: 'publish steps',
        });
        this.injector.get(PrivateAmplitudeService).logEvent(data.notificationSetting ? 'publish with notify' : 'publish without notify', {
          category: 'Shiftplan',
          subcategory: 'Publishing',
          subcategory2: 'Notify employee',
        });
        if (data.openShiftsCount > 0) {
          this.injector.get(PrivateAmplitudeService).logEvent('published open shift', {
            category: 'Shiftplan',
            subcategory: 'Publishing',
            subcategory2: 'Open shifts',
          });
        }
        if (data.scheduledShiftsCount > 0) {
          this.injector.get(PrivateAmplitudeService).logEvent('published scheduled shift', {
            category: 'Shiftplan',
            subcategory: 'Publishing',
            subcategory2: 'Open shifts',
          });
        }
        await Promise.all([this.setShiftplanPreference({ hasPublished: true }), this.getShiftCards()]);
      }
      this.isPublishing = false;
    });
  }

  loadMore() {
    this.SHIFTS_PAGE++;
    this.PENDING_SHIFTS -= this.SHIFTS_PAGINATION;
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  loadLess() {
    this.SHIFTS_PAGE = 1;
    this.calculatePendingShifts();
  }

  calculatePendingShifts() {
    this.PENDING_SHIFTS =
      this.shiftCards.openShiftCardsGroupedByDay.reduce((maxShifts, day) => (maxShifts < day.length ? day.length : maxShifts), 0) -
      this.SHIFTS_PAGINATION * this.SHIFTS_PAGE;
  }

  calculatePendingEmployees() {
    this.SHIFTS_PAGINATION_EMPLOYEE = this.view === this.VIEWS.WEEK ? 50 : 25;
    this.SHIFTS_PAGE_EMPLOYEE = 1;
    this.PENDING_EMPLOYEE = this.shiftCards.shiftsGrouped.length - this.SHIFTS_PAGINATION_EMPLOYEE * this.SHIFTS_PAGE_EMPLOYEE;
  }

  loadMoreEmployees() {
    this.show.loadingMore = true;

    setTimeout(() => {
      this.SHIFTS_PAGE_EMPLOYEE++;
      this.PENDING_EMPLOYEE -= this.SHIFTS_PAGINATION_EMPLOYEE;
      this.show.loadingMore = false;
    }, 500);
  }

  onScroll() {
    this.hasScrolled.top = this.container.nativeElement.scrollTop !== 0;
    this.hasScrolled.left = this.container.nativeElement.scrollLeft !== 0;

    this.scrollInfo.left = this.container.nativeElement.scrollLeft;

    if (this.container.nativeElement.scrollTop + this.container.nativeElement.clientHeight >= this.container.nativeElement.scrollHeight) {
      this.loadMoreEmployees();
    }
  }

  openCopyShiftsDialog() {
    if (this.loading) {
      return;
    }

    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = () => this.openCopyShiftsDialog();
      this.openLeaveSelectionDialog();
      return;
    }

    const range = this.view === this.VIEWS.WEEK ? this.getDateRangeForActions({ selectedDayIndex: null }).range : { ...this.monthRange };
    const data = {
      start: range.from.toISOString(),
      end: range.to.toISOString(),
      filters: this.filters,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CopyShiftsDialog, { data });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.hasCopied) {
        this.injector.get(MatLegacySnackBar).open(this.pageTranslation.shiftsCopied, 'OK', {
          duration: 5000,
        });
        await this.navigateToWeek(result.destinationWeek);
      }
    });
  }

  async navigateToWeek(destinationWeek) {
    this.isLoaded = false;
    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = async () => await this.navigateToWeek(destinationWeek);
      this.openLeaveSelectionDialog();
      return;
    }

    this.changeWeek(destinationWeek);
    this.checkFiltersApplied();
    await this.initCalendars();
    await Promise.all([this.getShiftCards(), this.setPublishReminder()]);
    this.isLoaded = true;
  }

  async navigateToMonth(destinationMonth) {
    this.isLoaded = false;
    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = async () => await this.navigateToMonth(destinationMonth);
      this.openLeaveSelectionDialog();
      return;
    }
    this.changeMonth(destinationMonth);
    await this.initCalendars();
    await Promise.all([this.getShiftCards(), this.setPublishReminder()]);
    this.isLoaded = true;
  }

  async navigateTodayMonth() {
    this.isLoaded = false;
    const isCurrentMonth = this.today.isBetween(this.monthRange.from, this.monthRange.to, 'day', '[]');
    if (!isCurrentMonth) {
      await this.navigateToMonth(this.DATE_VALUES.CURRENT);
    }
    await this.initCalendars();
    scrollToDay({ day: this.today }, this.container);
    this.isLoaded = true;
  }

  async openClearScheduleDialog() {
    if (this.loading) {
      return;
    }

    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = () => this.openClearScheduleDialog();
      this.openLeaveSelectionDialog();
      return;
    }
    this.injector.get(PrivateAmplitudeService).logEvent('clear schedule intent', {
      category: 'Shiftplan',
      subcategory: 'bulk deletion',
      subcategory2: 'clear schedule',
    });

    const dateRange = this.getDateRange();
    const shiftCards = await this.injector
      .get(ShiftPlanCardService)
      .getShiftCards(dateRange.from, dateRange.to, this.filters, this.layout, false);
    const data = {
      startDay: dateRange.from,
      endDay: dateRange.to,
      openShiftsCount: shiftCards.openDraftCount,
      scheduledShiftsCount: shiftCards.scheduledDraftCount,
      filters: this.filters,
      shiftsDeleteLimitReached: this.SHIFTS_LIMIT_TO_DELETE.CLEAR <= shiftCards.openDraftCount + shiftCards.scheduledDraftCount,
      view: this.view,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ClearScheduleDialog, { data });
    dialogRef.afterClosed().subscribe(async (deleted) => {
      if (deleted) {
        this.injector.get(PrivateAmplitudeService).logEvent('clear schedule completed', {
          category: 'Shiftplan',
          subcategory: 'bulk deletion',
          subcategory2: 'clear schedule',
        });
        await this.getShiftCards();
      }
    });
  }

  showDropdownMenu(menu: string) {
    if (this.loading || this.isDropping) {
      return;
    }
    this.show[menu] = !this.show[menu];
  }

  registerTodayEvent() {
    this.injector.get(PrivateAmplitudeService).logEvent('click today', {
      category: 'Shiftplan',
      subcategory: 'Scheduling',
      subcategory2: 'Layout Usage',
    });
  }

  async changeViewLayout(view: string = null, layout: string = null, cardSize: string = null) {
    if (this.isSelectShiftsActive) {
      this.actionAfterLeaveIntent = async () => await this.changeViewLayout(view, layout, cardSize);
      this.openLeaveSelectionDialog();
      return;
    }

    if (check.assigned(layout)) {
      this.loading = true;
      this.layout = layout;
      await this.setShiftplanPreference({ layout });
      await this.registerChangeLayout();
      return;
    }

    if (check.assigned(view) && this.view !== view) {
      this.show.cards = false;
      this.loading = true;
      this.view = view;
      this.cardSize = this.cardSizePreferences[this.view];
      await this.setShiftplanPreference({ view });

      if (this.view === this.VIEWS.WEEK) {
        await this.changeViewToWeek();
      }
      if (this.view === this.VIEWS.MONTH) {
        await this.navigateToMonth(this.weekRange);
        this.injector.get(PrivateAmplitudeService).logEvent('select monthly view', {
          category: 'Shiftplan',
          subcategory: 'Scheduling',
          subcategory2: 'View Usage',
        });
      }

      this.changeCardSize();
      this.tabsDate.realignInkBar();
      this.loading = false;
      this.show.cards = true;
    }

    if (check.assigned(cardSize)) {
      this.cardSize = cardSize;
      this.cardSizePreferences[this.view] = cardSize;
      await this.setShiftplanPreference({ cardSize: this.cardSizePreferences });
      this.changeCardSize(true);

      if (this.cardSize === this.CARD_SIZE.COMPACT) {
        this.injector.get(PrivateAmplitudeService).logEvent('select compact card', { category: 'Shiftplan', subcategory: 'Settings' });
      }
    }
  }

  async changeViewToWeek() {
    let newWeek: IShiftPlanDate | number = { ...this.monthRange };
    const isCurrentMonth = this.today.isBetween(this.monthRange?.from, this.monthRange?.to, 'day', '[]');

    if (isCurrentMonth) {
      newWeek = this.DATE_VALUES.CURRENT;
    }
    await this.navigateToWeek(newWeek);
  }

  changeCardSize(recalculate = false) {
    let shiftCardSettings =
      this.generalSettings.shiftSettings ?? this.injector.get(ShiftPlanGeneralSettingsService).getCardSizeDefaultOptions();
    const compact = [this.CARD_SIZE.COMPACT, 'minimal'].includes(this.cardSize);
    if (this.cardSize === 'minimal') {
      shiftCardSettings = { role: true, location: false, time: false, duration: false, tag: false };
    }
    const { cardSizeSettings, fieldsNumber } = changeCardSize(shiftCardSettings, compact);
    this.cardSizeSettings = { ...cardSizeSettings, fieldsNumber, value: this.cardSize };

    if (check.not.assigned(this.tabWidthMinimal) || recalculate) {
      this.calculateDateTabWidth();
    }

    if (fieldsNumber < 4) {
      const shiftsPerPage = { oneField: 12, twoFields: 6 };
      this.SHIFTS_PAGINATION = fieldsNumber === 1 ? shiftsPerPage.oneField : shiftsPerPage.twoFields;
      this.calculatePendingShifts();
    } else {
      this.SHIFTS_PAGINATION = 3;
    }
  }

  showTemplateDropdownMenu() {
    if (this.loading || (this.show.templateMenu && this.closePanel)) {
      return;
    }

    if (this.show.templateMenu) {
      this.injector.get(PrivateAmplitudeService).logEvent('open shift template module', {
        category: 'Shiftplan',
        subcategory: 'templates',
        subcategory2: 'module',
      });
      this.closePanel = true;
      setTimeout(() => {
        this.show.templateMenu = false;
        this.closePanel = false;
        this.panelStatus = 'standby';
        this.injector.get(PrivateAmplitudeService).logEvent('close shift template module', {
          category: 'shiftplan',
          subcategory: 'templates',
          subcategory2: 'module',
        });
      }, 300);
    } else {
      this.injector.get(PrivateAmplitudeService).logEvent('close shift template module', {
        category: 'Shiftplan',
        subcategory: 'templates',
        subcategory2: 'module',
      });
      this.show.templateMenu = true;
      this.injector.get(PrivateAmplitudeService).logEvent('open shift template module', {
        category: 'shiftplan',
        subcategory: 'templates',
        subcategory2: 'module',
      });
    }
  }
  private sortByDate(): (a, b) => number {
    return (a, b) => (moment.utc(a._createdAt).isAfter(b._createdAt) ? -1 : 1);
  }

  async drop(event: CdkDragDrop<number[]>) {
    if (this.isDragging || this.loading || !event.isPointerOverContainer || this.isDropping) {
      return;
    }

    const droppedElement = event.item.element.nativeElement.id;
    const SHIFT_CARD_ID = 'shift-card';
    const TEMPLATE_ID = 'template';

    const targetDropData = {
      employeeId: event.container.id.split('-')[4] === this.OPEN_SHIFT ? null : event.container.id.split('-')[4],
      roleSectionId: event.container.id.split('-')[5] === this.EMPLOYEE_LAYOUT ? null : event.container.id.split('-')[5],
      dateIndex: Number(event.container.id.split('-')[3]),
      isDroppable: event.previousContainer !== event.container,
    };

    if (targetDropData.isDroppable !== true) {
      return;
    }

    if (!this.checkDropPermissions(droppedElement === SHIFT_CARD_ID, targetDropData.employeeId)) {
      this.injector.get(MatLegacySnackBar).open(this.pageTranslation.noPermissionsTooltip, 'OK', {
        duration: 5000,
        panelClass: 'kenjo-error-snackbar',
      });
      return;
    }

    this.isDropping = true;
    if (droppedElement === TEMPLATE_ID) {
      this.isTemplate = true;
      await this.createShiftDragAndDrop(targetDropData);
      this.show.templateDetails = false;
    } else if (droppedElement === SHIFT_CARD_ID) {
      this.isTemplate = false;
      await this.updateShiftDragAndDrop(targetDropData);
    }

    this.isDropping = false;
  }

  checkDropPermissions(isShiftCard: boolean, employeeId: string) {
    const permissionToCheck = isShiftCard ? 'edit' : 'create';
    const notDropScheduled = check.assigned(employeeId) && !this.shiftplanPermissions['scheduled'][permissionToCheck];
    const notDropOpen = check.not.assigned(employeeId) && !this.shiftplanPermissions['open'][permissionToCheck];
    return !(notDropScheduled || notDropOpen);
  }

  async createShiftDragAndDrop(targetDropData) {
    try {
      const shiftCreationBody = this.getShiftDataDragAndDrop({
        ...targetDropData,
        ...this.draggedTemplate,
      });
      const newShift = await this.injector.get(ShiftPlanCardService).create(shiftCreationBody);
      const isShiftFiltered = this.checkFilters(newShift.roleId, newShift.locationId, targetDropData.roleSectionId);

      if (!isShiftFiltered) {
        const snackbarTransformed = this.injector.get(I18nDataPipe).transform(this.pageTranslation.snackbarShiftRoleCreated, {
          roleName: this.mappedCollections.rolesMapped[newShift.roleId].name,
        });
        this.injector.get(MatLegacySnackBar).open(snackbarTransformed, 'OK', {
          duration: 5000,
        });
        return;
      }
      await this.updateShiftCardsView(
        newShift,
        targetDropData.dateIndex,
        this.layout === this.LAYOUT.ROLE && targetDropData.roleSectionId !== this.draggedTemplate.roleId
      );
      this.calculatePendingShifts();
    } catch {
      // do nothing
    }
  }

  async updateShiftDragAndDrop(targetDropData) {
    try {
      if (this.isSelectShiftsActive) {
        return;
      }
      let shiftCreationBody = {};
      let updatedCard: IShiftPlanCardModel;
      if (this.draggedShiftCard.employeeId === targetDropData.employeeId) {
        shiftCreationBody = this.getShiftDataDragAndDrop({
          ...targetDropData,
          ...this.draggedShiftCard,
        });
        updatedCard = { ...shiftCreationBody, ...this.draggedShiftCard };
        const daysOfCurrentView = this.view === this.VIEWS.WEEK ? 'daysOfCurrentWeek' : 'daysOfCurrentMonth';
        updatedCard.date = this[daysOfCurrentView].value[targetDropData.dateIndex].toDate();
      } else {
        shiftCreationBody = this.getShiftDataDragAndDrop({
          ...this.draggedShiftCard,
          ...targetDropData,
        });
        updatedCard = { ...this.draggedShiftCard, ...shiftCreationBody };
      }

      if (targetDropData.roleSectionId !== null) {
        shiftCreationBody['roleId'] = targetDropData.roleSectionId;
        updatedCard.roleId = targetDropData.roleSectionId;
      }
      this.removeOldShift(this.draggedShiftCard);
      await this.injector.get(ShiftPlanCardService).updateById(this.draggedShiftCard._id, shiftCreationBody);
      await this.updateShiftCardsView(
        updatedCard,
        targetDropData.dateIndex,
        this.layout === this.LAYOUT.ROLE && targetDropData.roleSectionId !== this.draggedShiftCard.roleId
      );
      if (this.layout !== this.LAYOUT.ROLE || (this.layout === this.LAYOUT.ROLE && check.not.assigned(updatedCard.employeeId))) {
        this.injector.get(MatLegacySnackBar).open(this.pageTranslation.shiftEditedSuccessfully, 'OK', {
          duration: 5000,
        });
      }
      this.calculatePendingShifts();
    } catch {
      // do nothing
    }
  }

  checkFilters(role: string, location: string, roleSection = null) {
    const roleFiltered = check.nonEmptyArray(this.filters.roles);
    const locationsFiltered = check.nonEmptyArray(this.filters.locations);
    const bothFiltered = roleFiltered && locationsFiltered;

    if (bothFiltered) {
      return this.filters.roles.includes(role) && this.filters.locations.includes(location);
    }
    if (roleFiltered) {
      return check.assigned(roleSection) ? this.filters.roles.includes(role) && roleSection === role : this.filters.roles.includes(role);
    }
    if (locationsFiltered) {
      this.filters.locations.includes(location);
    }
    return true;
  }

  getShiftDataDragAndDrop(payload) {
    const { start, end, employeeId, locationId, roleId, workingAreaId, tagId, notes, dateIndex, approvalRule } = payload;
    const daysOfCurrentView = this.view === this.VIEWS.WEEK ? 'daysOfCurrentWeek' : 'daysOfCurrentMonth';

    const shiftCreationBody = {
      date: this[daysOfCurrentView].value[dateIndex],
      start,
      end,
      employeeId,
      locationId,
      roleId,
      workingAreaId,
      tagId,
      notes,
      break: payload.break,
      approvalRule,
    };
    return shiftCreationBody;
  }

  async updateShiftCardsView(newShift, dateIndex, isSameRole) {
    if (check.assigned(newShift.employeeId)) {
      if (this.layout === 'user') {
        const employeeIndex = this.shiftCards.shiftsGrouped.findIndex(
          (employee) => employee._id.toString() === newShift.employeeId.toString()
        );
        this.shiftCards.shiftsGrouped[employeeIndex].shiftsByDay[dateIndex].push(this.populateNewShiftCard(newShift));
        this.shiftCards.scheduledDraftCount++;
        if (this.isTemplate) {
          this.injector.get(MatLegacySnackBar).open(this.pageTranslation.snackbarScheduledShiftCreated, 'OK', {
            duration: 5000,
          });
        }
      }

      if (this.layout === this.LAYOUT.ROLE) {
        const roleIndex = this.shiftCards.shiftsGrouped.findIndex((role) => role._id.toString() === newShift.roleId.toString());
        const employeeIndex = this.shiftCards.shiftsGrouped[roleIndex].shiftsGrouped.findIndex(
          (employee) => employee._id.toString() === newShift.employeeId.toString()
        );
        let snackbarMessage = '';
        if (this.isTemplate) {
          const snackbarTransformed = this.injector.get(I18nDataPipe).transform(this.pageTranslation.snackbarShiftRoleCreated, {
            roleName: this.mappedCollections.rolesMapped[newShift.roleId].name,
          });
          snackbarMessage = isSameRole ? snackbarTransformed : this.pageTranslation.snackbarScheduledShiftCreated;
        } else {
          snackbarMessage = this.pageTranslation.shiftEditedSuccessfullyRoles;
        }
        if (roleIndex !== -1 && employeeIndex !== -1) {
          this.shiftCards.shiftsGrouped[roleIndex].shiftsGrouped[employeeIndex].shiftsByDay[dateIndex].push(
            this.populateNewShiftCard(newShift)
          );
          this.shiftCards.scheduledDraftCount++;
          if (this.isTemplate) {
            const snackbarTransformed = this.injector.get(I18nDataPipe).transform(this.pageTranslation.snackbarShiftRoleCreated, {
              roleName: this.mappedCollections.rolesMapped[newShift.roleId].name,
            });
            snackbarMessage = isSameRole ? snackbarTransformed : this.pageTranslation.snackbarScheduledShiftCreated;
            this.injector.get(MatLegacySnackBar).open(snackbarMessage, 'OK', {
              duration: 5000,
            });
            this.injector.get(PrivateAmplitudeService).logEvent('created scheduled shift with drag and drop', {
              category: 'Shiftplan',
              subcategory: 'templates',
              subcategory2: 'using templates',
            });
          } else {
            this.injector.get(MatLegacySnackBar).open(this.pageTranslation.shiftEditedSuccessfully, 'OK', {
              duration: 5000,
            });
            this.injector.get(PrivateAmplitudeService).logEvent('drag and drop edit role layout', {
              category: 'Shiftplan',
              subcategory: 'scheduling',
            });
          }
          return;
        }
        this.injector.get(MatLegacySnackBar).open(snackbarMessage, 'OK', {
          duration: 5000,
        });
      }
      if (this.isTemplate) {
        this.injector.get(PrivateAmplitudeService).logEvent('created scheduled shift with drag and drop', {
          category: 'Shiftplan',
          subcategory: 'templates',
          subcategory2: 'using templates',
        });
      } else {
        const userLayout = this.layout === 'user' ? 'employee' : this.layout;
        this.injector.get(PrivateAmplitudeService).logEvent(`drag and drop edit ${userLayout} layout`, {
          category: 'Shiftplan',
          subcategory: 'scheduling',
        });
      }
      return;
    }
    this.shiftCards.openShiftCardsGroupedByDay[dateIndex].push(this.populateNewShiftCard(newShift));
    this.shiftCards.openDraftCount++;
    if (this.isTemplate) {
      this.injector.get(PrivateAmplitudeService).logEvent('created open shift with drag and drop', {
        category: 'Shiftplan',
        subcategory: 'templates',
        subcategory2: 'using templates',
      });
      this.injector.get(MatLegacySnackBar).open(this.pageTranslation.snackbarOpenShiftCreated, 'OK', {
        duration: 5000,
      });
    }
  }

  private removeOldShift(oldShift: any) {
    if (check.assigned(oldShift.employeeId)) {
      if (this.layout === this.LAYOUT.USER) {
        const employeeIndex = this.shiftCards.shiftsGrouped.findIndex(
          (employee) => employee._id.toString() === oldShift.employeeId.toString()
        );
        const cardIndex = this.shiftCards.shiftsGrouped[employeeIndex].shiftsByDay[this.draggedShiftCardDateIndex].findIndex(
          (card) => card._id === oldShift._id
        );
        this.shiftCards.shiftsGrouped[employeeIndex].shiftsByDay[this.draggedShiftCardDateIndex].splice(cardIndex, 1);
        this.shiftCards.scheduledDraftCount--;
      } else if (this.layout === this.LAYOUT.ROLE) {
        const roleIndex = this.shiftCards.shiftsGrouped.findIndex((role) => role._id.toString() === oldShift.roleId.toString());
        const employeeIndex = this.shiftCards.shiftsGrouped[roleIndex].shiftsGrouped.findIndex(
          (employee) => employee._id.toString() === oldShift.employeeId.toString()
        );
        const cardIndex = this.shiftCards.shiftsGrouped[roleIndex].shiftsGrouped[employeeIndex].shiftsByDay[
          this.draggedShiftCardDateIndex
        ].findIndex((card) => card._id === oldShift._id);

        if (roleIndex !== -1 && employeeIndex !== -1) {
          this.shiftCards.shiftsGrouped[roleIndex].shiftsGrouped[employeeIndex].shiftsByDay[this.draggedShiftCardDateIndex].splice(
            cardIndex,
            1
          );
          this.shiftCards.scheduledDraftCount--;
        }
      }
    } else {
      const cardIndex = this.shiftCards.openShiftCardsGroupedByDay[this.draggedShiftCardDateIndex].findIndex(
        (card) => card._id === oldShift._id
      );
      this.shiftCards.openShiftCardsGroupedByDay[this.draggedShiftCardDateIndex].splice(cardIndex, 1);
      this.shiftCards.openDraftCount--;
      this.SHIFTS_PAGE = 1;
    }
  }

  populateNewShiftCard(newShift: any): any {
    const finalShift = {
      ...newShift,
      ...(check.assigned(newShift.workingAreaId) && {
        workingArea: this.mappedCollections.workingAreasMapped[newShift.workingAreaId],
      }),
      ...(check.assigned(newShift.tagId) && {
        tag: this.mappedCollections.tagsMapped[newShift.tagId],
      }),
      ...(check.assigned(newShift.employeeId) && {
        employee: this.mappedCollections.employeesMapped[newShift.employeeId],
      }),
      role: this.mappedCollections.rolesMapped[newShift.roleId],
      location: this.mappedCollections.locationsMapped[newShift.locationId],
    };
    return finalShift;
  }

  mapShiftPlanCollectionsById() {
    const rolesMapped = mapById(this.roles);
    const locationsMapped = mapById(this.locations);
    const workingAreasMapped = mapById(this.workingAreas);
    const tagsMapped = mapById(this.tags);
    const employeesMapped = mapById(this.allEmployees.map(({ _id, displayName, _photo }) => ({ _id, displayName, _photo })));

    this.mappedCollections = {
      rolesMapped,
      locationsMapped,
      workingAreasMapped,
      tagsMapped,
      employeesMapped,
    };
  }

  addId(dayIndex, employeeId = this.OPEN_SHIFT, roleId = this.EMPLOYEE_LAYOUT) {
    this.LIST_IDS.push(`${this.cdkDropListString}-${dayIndex}-${employeeId}-${roleId}`);
  }

  setIdsDragAndDrop() {
    const openShifts = this.shiftCards?.openShiftCardsGroupedByDay;
    const scheduleShifts = this.shiftCards?.shiftsGrouped;

    openShifts.forEach((_, index) => {
      this.addId(index);
    });

    if (this.layout === this.LAYOUT.USER) {
      scheduleShifts.slice(0, this.SHIFTS_PAGE_EMPLOYEE * this.SHIFTS_PAGINATION_EMPLOYEE).forEach((employee) => {
        employee.shiftsByDay.forEach((_, index) => {
          this.addId(index, employee._id);
        });
      });
    }

    if (this.layout === this.LAYOUT.ROLE) {
      scheduleShifts.forEach((role) => {
        role.shiftsGrouped.slice(0, this.SHIFTS_PAGINATION_EMPLOYEE).forEach((employee) => {
          employee.shiftsByDay.forEach((_, index) => {
            this.addId(index, employee._id, role._id);
          });
        });
      });
    }
  }

  handleDraggingTemplate({ isDragging, template }: { isDragging: boolean; template: IShiftPlanTemplate }) {
    this.isDragging = isDragging;
    if (this.loading) {
      return;
    }

    this.setBlockDrop(false);
    this.dragTemplateStyle();
    this.show.templateDetails = false;
    this.closePanel = isDragging;
    this.panelStatus = isDragging === true ? 'close' : 'open';
    this.draggedTemplate = isDragging === true ? null : template;
  }

  handleDraggingShiftCard(value, shift, dayIndex) {
    this.isDragging = value;
    if (this.isSelectShiftsActive || this.loading) {
      return;
    }

    this.setBlockDrop(true);
    this.show.templateMenu = false;
    this.closePanel = false;
    this.dragTemplateStyle();
    this.draggedShiftCardDateIndex = dayIndex;
    this.draggedShiftCard = value === true ? null : shift;
  }

  setBlockDrop(isShiftCard: boolean, init: boolean = false) {
    if (init) {
      this.blockDrop.open = this.shiftplanPermissions['open'].create === false;
      this.blockDrop.scheduled = this.shiftplanPermissions['scheduled'].create === false;
      return;
    }

    const permissionToCheck = isShiftCard ? 'edit' : 'create';
    //Check permission if is dragging otherwise reset to false
    this.blockDrop.open = this.isDragging
      ? this.shiftplanPermissions['open'][permissionToCheck] === false
      : this.shiftplanPermissions['open'].create === false;
    this.blockDrop.scheduled = this.isDragging
      ? this.shiftplanPermissions['scheduled'][permissionToCheck] === false
      : this.shiftplanPermissions['scheduled'].create === false;
  }

  private getShiftBodyFromTemplate(template: IShiftPlanTemplate) {
    const { start, end, roleId, locationId, workingAreaId, tagId, notes, approvalRule } = template;
    return {
      start,
      end,
      break: template.break,
      roleId,
      workingAreaId,
      locationId,
      tagId,
      notes,
      approvalRule,
    };
  }

  public async openCustomDeleteDialog() {
    if (this.loading) {
      return;
    }
    this.injector.get(PrivateAmplitudeService).logEvent('custom delete intent', {
      category: 'Shiftplan',
      subcategory: 'Custom bulk deletion',
    });
    const { range } = this.getDateRangeForActions({ selectedDayIndex: null });
    const data = {
      startDay: range.from,
      endDay: range.to,
      filters: this.filters,
      shiftsToDelete: this.selectedShiftIds,
      shiftsDeleteLimitReached: this.SHIFTS_LIMIT_TO_DELETE.CUSTOM <= this.shiftCards.openDraftCount + this.shiftCards.scheduledDraftCount,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(CustomDeleteDialog, { data });
    dialogRef.afterClosed().subscribe(async (deleted) => {
      if (deleted) {
        this.injector.get(PrivateAmplitudeService).logEvent('custom delete completed', {
          category: 'Shiftplan',
          subcategory: 'Custom bulk deletion',
        });
        this.showSelectShiftsPopUp(false);
        await this.getShiftCards();
      }
    });
  }

  private dragTemplateStyle() {
    const selectors = '.sspp-non-droppable, .pmc-menu, .gb-header, .cc-shell-global-bar';
    const areaToBlur = document.querySelectorAll(selectors);
    const area: Array<any> = Array.from(areaToBlur);
    if (this.isDragging) {
      document.body.style.cursor = 'grabbing';
      area.forEach((element) => (element.style.filter = 'blur(2px)'));
    } else {
      document.body.style.cursor = '';
      area.forEach((element) => (element.style.filter = ''));
    }
  }

  public showSelectShiftsPopUp(value: boolean) {
    if (this.loading) {
      return;
    }
    const timeOffService = this.injector.get(ShiftPlanTimeOffService);
    timeOffService.changeState = { subscriptionPaused: true };
    this.shiftsNumber = this.calculateShiftsNumberSelected();
    this.isSelectShiftsActive = value;
    if (!value) {
      this.clearAllSelectedShifts();
      if (timeOffService.states.snackbarPaused) {
        timeOffService.showRefreshSnackBar();
        timeOffService.changeState = { snackbarPaused: false };
      }
      timeOffService.changeState = { subscriptionPaused: false };
    }
  }

  public closeSelectShiftsPopUp() {
    if (this.isSelectShiftsActive && Object.keys(this.selectedShiftIds).length > 0) {
      this.actionAfterLeaveIntent = async () => this.closeSelectShiftsPopUp();
      this.openLeaveSelectionDialog();
      return;
    }
    this.showSelectShiftsPopUp(false);
  }

  public changeShiftsSelected(value: boolean, shift: { _id: string; ownerId: string; isOpen: boolean }) {
    if (value === true) {
      this.selectedShiftIds = {
        ...this.selectedShiftIds,
        [shift._id]: { _id: shift._id, ownerId: shift.ownerId, isOpen: shift.isOpen },
      };
      this.clearAll = false;
    } else {
      delete this.selectedShiftIds[shift._id];
      this.selectAll = false;
    }
    this.selectedShiftIdsLength = Object.keys(this.selectedShiftIds).length;
    this.shiftsNumber = this.calculateShiftsNumberSelected();
  }

  private calculateShiftsNumberSelected() {
    const openShiftsNumber = this.shiftCards.openShiftCardsGroupedByDay.reduce((shiftsNumberCount, shiftsByDay) => {
      const pendingShiftByDay = shiftsByDay.slice(0, this.SHIFTS_PAGE * this.SHIFTS_PAGINATION).length;
      return shiftsNumberCount + pendingShiftByDay;
    }, 0);
    return openShiftsNumber + this.shiftCards.scheduledDraftCount;
  }

  public clearAllSelectedShifts() {
    this.clearAll = true;
    this.selectAll = false;
    this.selectedShiftIds = {};
    this.selectedShiftIdsLength = 0;
  }

  public selectAllShifts() {
    this.selectAll = true;
    this.clearAll = false;
    this.selectedShiftIds = {};
    this.selectedShiftIdsLength = 0;
  }

  public async openLeaveSelectionDialog(subtitleText = null) {
    if (this.loading) {
      return;
    }
    const subtitleTexT = `${this.pageTranslation.leaveSelectionSubtitle} ${subtitleText ?? ''}`;
    const data = {
      titleText: this.pageTranslation.leaveSelectionTitle,
      subtitleText: subtitleTexT,
      cancelButtonText: this.pageTranslation.goBack,
      confirmButtonText: this.pageTranslation.yesLeave,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe((leaveIntent) => {
      if (leaveIntent) {
        this.showSelectShiftsPopUp(false);
        this.actionAfterLeaveIntent();
      }
    });
  }

  async fetchTimeOffTranslations() {
    if (!this.generalSettings.showTimeOff) {
      return;
    }

    try {
      const [timeOffStatusList, componentTranslation] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('standard-picklists'),
        this.injector.get(InternationalizationService).getAllTranslation('shift-plan-time-off'),
      ]);
      this.timeOffTranslation = componentTranslation;

      const TIME_OFF_LIST_STATUS_MAP = timeOffStatusList?.['time-off-request_status'];
      TIME_OFF_LIST_STATUS_MAP[TIME_OFF_REQUEST_STATUS_PROCESSED] = TIME_OFF_LIST_STATUS_MAP[TIME_OFF_REQUEST_STATUS_APPROVED];
      const TIME_OFF_COLOR_STATUS_MAP = {
        [TIME_OFF_REQUEST_STATUS_APPROVED]: 'Success',
        [TIME_OFF_REQUEST_STATUS_PENDING]: 'Warning',
        [TIME_OFF_REQUEST_STATUS_SUBMITTED]: 'Success',
        [TIME_OFF_REQUEST_STATUS_IN_APPROVAL]: 'Warning',
        [TIME_OFF_REQUEST_STATUS_PROCESSED]: 'Success',
      };

      this.statusTimeOffMaps = { list: TIME_OFF_LIST_STATUS_MAP, color: TIME_OFF_COLOR_STATUS_MAP };
    } catch {
      this.statusTimeOffMaps = { list: {}, color: {} };
    }
  }

  async getTimeOffSchedule() {
    if (!this.generalSettings.showTimeOff || check.emptyArray(this.allEmployees)) {
      return;
    }

    try {
      const dateRange = this.getDateRange();
      this.timeOffs = await this.injector.get(ShiftPlanTimeOffService).getTimeOffRequests(
        dateRange.from.toDate(),
        dateRange.to.endOf('day').toDate(),
        this.allEmployees.map((employee) => employee._id),
        this.view
      );
    } catch {
      this.timeOffs = {} as ITimeOffRequestSchedule;
    }
  }

  async getAttendanceData() {
    if (!this.generalSettings.showAttendance || check.emptyArray(this.allEmployees)) {
      return;
    }

    try {
      const userIds = this.allEmployees.map((employee) => employee._id);
      this.attendanceStartDay = this.getDateRange().from;
      this.attendanceSummary = await this.injector
        .get(ShiftPlanAttendanceService)
        .getAttendanceData({ startDay: this.attendanceStartDay, userIds, onlyOvertime: true });
      await this.getUsersTrackAttendanceSetting({ userIds });
      const showOvertimeTracking = await this.injector.get(AttendancePreferencesService).getAttendanceOvertimeEnabledStatus();
      this.showOvertimeTracking = showOvertimeTracking.attendanceOvertimeEnabled;
    } catch {
      this.attendanceSummary = {};
      this.usersTrackingSettings = {};
      this.usersOvertimeTracking = {};
    }
  }

  private async checkEntitlementValue(entitlementKey: featureGatingConstants.ENTITLEMENTS_LIST) {
    const isEntitled = await this.injector.get(FeatureGatingService).validateEntitlementFromCache(entitlementKey);
    return isEntitled;
  }

  async getWishDays() {
    if (!this.generalSettings.wishDays?.show || check.emptyArray(this.allEmployees)) {
      return;
    }

    try {
      const isEntitled = await this.checkEntitlementValue(featureGatingConstants.ENTITLEMENT_SHP_WISH_DAYS);
      if (!isEntitled) {
        this.generalSettings.wishDays.show = false;
        await this.injector
          .get(ShiftPlanGeneralSettingsService)
          .updateById(this.generalSettings._id, { wishDays: this.generalSettings.wishDays });
        this.wishDaysById = {};
        return;
      }
      const userIds = this.allEmployees.map((employee) => employee._id);
      const { from, to } = this.getDateRange();
      const wishDays = await this.injector
        .get(ShiftplanWishDayService)
        .getWishDays({ userIds, startDate: from.toDate(), endDate: to.toDate() });
      this.wishDaysById = groupWishDaysByUser(wishDays);
    } catch {
      this.wishDaysById = {};
    }
  }

  async getUsersTrackAttendanceSetting({ userIds }) {
    const usersWorkSchedule = await this.injector.get(UserWorkScheduleService).find({ _id: { $in: userIds } });
    this.usersTrackingSettings = mapUserAttendanceTracking(usersWorkSchedule);
    this.usersOvertimeTracking = mapUserOvertimeTracking(usersWorkSchedule);
  }

  async openGeneratePDFDialog() {
    if (this.loading) {
      return;
    }
    this.generatePDFtranslations = await this.injector.get(InternationalizationService).getAllTranslation('shift-plan-generate-pdf');
    const range = this.getDateRange();

    const data = {
      range,
      filters: this.filters,
      layout: this.layout,
      dialogTranslation: this.generatePDFtranslations,
      view: this.view,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(GeneratePDFDialog, { data });
    dialogRef.afterClosed().subscribe(async ({ generatePDF, options }) => {
      if (generatePDF) {
        this.optionsPDF = { ...options };
        this.exporting = true;
      }
    });
  }

  async setPublishReminder() {
    const hasPublished =
      (await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_KEY))?.preference?.hasPublished ?? false;
    this.show.publishReminder = this.shiftplanPermissions['publish'] === true && hasPublished === false;
  }

  trackById(index: number, shift: IShiftCard) {
    return shift._id;
  }

  protected destroy(): Promise<void> {
    this.injector.get(ShiftPlanPermissionsService).disposePermissions();
    this.injector.get(ShiftPlanTimeOffService).finishSubscription();
    this.injector.get(ShiftPlanTimeOffService).isAfterFirstReload = false;
    return Promise.resolve();
  }

  onHover(eventName: 'enter' | 'leave', dayIndex: number) {
    if (this.view === this.VIEWS.WEEK) {
      return;
    }

    const resetIndex = eventName === 'leave';
    this.hoverInfo = { index: resetIndex ? -1 : dayIndex, value: !resetIndex };
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.view === this.VIEWS.MONTH) {
      this.calculateDateTabWidth();
    }
  }

  @HostListener('document:keydown', ['$event'])
  keypress(event: KeyboardEvent) {
    const isArrowKey = event.key.includes('Arrow');
    if (this.scrollBlocked && isArrowKey) {
      event.preventDefault();
    }
  }
}
