import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IRecruitingOverview } from '@app/models/controllers/recruiting.model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable()
export class RecruitingOverviewService {
  private RECRUITING_OVERVIEW_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting`;

  constructor(private injector: Injector) {}

  getPage(): Promise<IRecruitingOverview> {
    return new Promise<IRecruitingOverview>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<IRecruitingOverview>(`${this.RECRUITING_OVERVIEW_URL}/overview`, httpOptions)
        .toPromise()
        .then((response: IRecruitingOverview) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, RecruitingOverviewService.name, 'getPage'));
        });
    });
  }
}
