import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IUserTimeOffRequestDetail } from '@app/cloud-features/shift-plan/services/shift-plan-time-off.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import * as pickListConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as moment from 'moment';

const fadeInOut = trigger('fadeInOutAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
]);
@Component({
  selector: 'kenjo-shift-plan-time-off-details',
  templateUrl: 'shift-plan-time-off-details.component.html',
  styleUrls: ['shift-plan-time-off-details.component.scss'],
  animations: [fadeInOut],
})
export class ShiftPlanTimeOffDetailsComponent implements OnInit, AfterViewInit {
  showManageRequest: boolean = false;
  USER_COLOR_CONSTANTS: { [key: string]: string } = userColorConstants;
  periodTime: { from: { date: string; hour: string }; to: { date: string; hour: string } };
  isOverflown: boolean = false;

  @Input() parentTranslation: { [key: string]: string };
  @Input() timeOffDetail: IUserTimeOffRequestDetail;
  @Input() userId: string;
  @Input() disconnected: boolean;
  @Input() statusMaps: { color: { [key: string]: string }; list: { [key: string]: string } };

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('timeOffDetailContainer', { static: false }) timeOffDetailContainer: ElementRef;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.checkManageRequestLink();
    this.setPeriodTime();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  closeDetails() {
    this.close.emit();
  }

  setPeriodTime() {
    const from = moment.utc(this.timeOffDetail?.from);
    const to = moment.utc(this.timeOffDetail?.to);

    this.periodTime = {
      from: { date: from.format('D MMM YYYY'), hour: from.format('HH:mm') },
      to: { date: to.format('D MMM YYYY'), hour: to.format('HH:mm') },
    };
  }

  async checkManageRequestLink() {
    const currentUser = this.injector.get(AuthenticationService).getLoggedUser();
    const allowedStatuses = [pickListConstants.TIME_OFF_REQUEST_STATUS_PENDING];
    const userPersonal = await this.injector.get(UserPersonalService).getById(this.userId);

    if (['admin', 'hr-admin'].includes(currentUser.profileKey)) {
      allowedStatuses.push(pickListConstants.TIME_OFF_REQUEST_STATUS_IN_APPROVAL);
    }

    if (allowedStatuses.includes(this.timeOffDetail?.status)) {
      this.showManageRequest = true;
    }

    if (
      !(
        currentUser.profileKey === 'admin' ||
        currentUser.profileKey === 'hr-admin' ||
        currentUser.profileKey === 'manager' ||
        currentUser._id === userPersonal?.delegatedApproverId
      )
    ) {
      this.showManageRequest = false;
    }
  }

  goToManageRequest() {
    this.injector.get(Router).navigateByUrl(`/cloud/time-off/requests?request=${this.timeOffDetail._id}`);
  }

  onClick(event: PointerEvent) {
    event.stopPropagation();
  }
}
