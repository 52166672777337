import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'kenjo-shift-plan-complete-activation',
  templateUrl: 'shift-plan-complete-activation.dialog.html',
  styleUrls: ['shift-plan-complete-activation.dialog.scss'],
})
export class CompleteShiftplanActivationDialog {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: string = 'Success';
  color: string = 'LightText';

  constructor(
    public dialogRef: MatLegacyDialogRef<CompleteShiftplanActivationDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    protected router: Router
  ) {
    this.titleText = this.data.translations.titleText;
    this.subtitleText = this.data.translations.subtitleText;
    this.confirmButtonText = this.data.translations.confirmButtonText;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  navigateTo(event: Event, link: string): void {
    if (event.target instanceof HTMLAnchorElement) {
      return;
    }
    const url = this.router.serializeUrl(this.router.createUrlTree([link]));
    window.open(url, '_blank');
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }
}
