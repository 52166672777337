<div *ngIf="status === 'title'" class="spl-title">
  {{ labelText }}
</div>

<div *ngIf="status === 'draft'" class="spl-draft">
  <div class="spl-card-left-border"></div>
  <div>{{ labelHeader }}</div>
  <div>{{ labelText }}</div>
</div>

<div *ngIf="status === 'published'" class="spl-published">
  <div>{{ labelHeader }}</div>
  <div>{{ labelText }}</div>
</div>

<div *ngIf="status === 'time-off'" class="spl-published time-off">
  {{ labelText }}
</div>

<orgos-column-container *ngIf="status === 'holiday'" class="spl-published holiday" [centerColumns]="true">
  <orgos-column size="0" class="kenjo-mr-5px">
    <div class="spl-holiday-number">{{ HOLIDAYS_EXAMPLE_NUMBER }}</div>
  </orgos-column>
  <orgos-column>{{ labelText }}</orgos-column>
</orgos-column-container>
