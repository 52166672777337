import { Location } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  INominableUser,
  INomination,
  PerformanceReviewNominationsController,
} from '@app/cloud-features/performance-review/controllers/performance-review-nominations.controller';
import { PerformanceReviewService } from '@app/cloud-features/performance-review/services/performance-review.service';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UrlChangeService } from '@app/standard/services/core/url-changes.service';
import {
  PERFORMANCE_REVIEW_STATUS_NOT_STARTED,
  PERFORMANCE_REVIEW_STATUS_RUNNING,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'kenjo-people-detail-performance-nominate',
  templateUrl: 'people-detail-performance-nominate.page.html',
  styleUrls: ['people-detail-performance-nominate.page.scss'],
})
export class PeopleDetailPerformanceNominatePage implements OnInit, OnDestroy {
  userId: string;
  performanceId: string;
  previousUrl: string;
  pageTranslations: any = {};
  searchTerm: string = '';
  hasNominationsLimit: boolean;
  nominationsLimit: any;
  searchResults = new Array<INominableUser>();
  allNominableUsers = new Array<INominableUser>();

  PERFORMANCE_REVIEW_STATUS_RUNNING = PERFORMANCE_REVIEW_STATUS_RUNNING;

  nominations = new Array<INomination>();
  canEdit = false;
  performanceReviewStatus = PERFORMANCE_REVIEW_STATUS_NOT_STARTED;
  submitting = false;
  loading = false;

  columns = ['name', 'job', 'department'];

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  private backButtonSubscription: Subscription;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    public dialog: MatLegacyDialog,
    public snackBar: MatLegacySnackBar
  ) {
    this.backButtonSubscription = this.location.subscribe((popEvent) => {
      if (popEvent.type === 'popstate') {
        this.router.navigateByUrl('/cloud');
      }
    }) as Subscription;
  }

  ngOnInit() {
    this.previousUrl = this.injector.get(UrlChangeService).getPreviousUrl();

    this.loading = true;
    this.injector.get(GlobalBarService).setEnableDefaultBars(false, true);
    this.injector.get(GlobalBarService).setSecondaryMenuOptions([]);
    this.initData();
  }

  setPreviousUrl() {
    if (!this.previousUrl || this.previousUrl === '/cloud') {
      this.previousUrl = `/cloud/performance-review/personal`;
      return;
    }

    if (this.previousUrl.endsWith('peformance')) {
      this.previousUrl = `/cloud/people/${this.userId}/performance`;
      return;
    }
  }

  private async initData(): Promise<void> {
    try {
      this.pageTranslations = await this.injector
        .get(InternationalizationService)
        .getAllTranslation('people-detail-performance-nominate-page');
    } catch {
      this.pageTranslations = {};
    }

    this.route.params.subscribe(async ({ id, performanceId }) => {
      this.userId = id;
      this.performanceId = performanceId;
      this.setPreviousUrl();
      try {
        this.allNominableUsers = await this.injector
          .get(PerformanceReviewNominationsController)
          .getNominableUsers(this.userId, this.performanceId);
      } catch {
        this.allNominableUsers = [];
      }

      try {
        const result = await this.injector.get(PerformanceReviewNominationsController).getNominations(this.userId, this.performanceId);
        this.nominations = result.nominations;
        this.canEdit = result.canEdit;
        this.performanceReviewStatus = result.performanceReviewStatus;

        if (this.canEdit) {
          this.columns.push('action');
        }
      } catch {
        this.nominations = [];
      }

      try {
        const performanceReview = await this.injector.get(PerformanceReviewService).getById(this.performanceId);
        this.hasNominationsLimit = performanceReview.reviewerType.peer.hasNominationsLimit;
        this.nominationsLimit = this.hasNominationsLimit ? performanceReview.reviewerType.peer.nominationsLimit : null;
      } catch {
        // An error is shown
      }

      this.loading = false;
    });
  }

  searchUserFunction: SearchFunction = (newSearchTerm: string) => {
    const regexp = new RegExp(newSearchTerm, 'i');
    const results = this.allNominableUsers.filter((iUser) => {
      const searchCriterium = regexp.test(iUser.displayName);
      if (searchCriterium) {
        const alreadyNominated =
          this.nominations.find((iNomination) => iNomination.user?._id?.toString() === iUser?._id?.toString()) !== undefined;
        return !alreadyNominated;
      }
      return false;
    });
    return Promise.resolve(results);
  };

  nominateUser(userToNominate: INominableUser) {
    const newNomination: INomination = { user: userToNominate, nominationSubmitted: false, performanceReviewId: this.performanceId };
    this.nominations = [...this.nominations, newNomination];
    this.searchComponent.clearSearch();
  }

  removeNomination(userId: string) {
    this.nominations = this.nominations.filter((iNomination) => iNomination.user._id !== userId);
  }

  submitNominations() {
    this.submitting = true;
    this.injector
      .get(PerformanceReviewService)
      .updateNominations(this.nominations, this.performanceId, true, this.userId)
      .then((success) => {
        if (success) {
          this.injector.get(MatLegacySnackBar).open(this.pageTranslations.submittedMessage, 'OK', { duration: 5000 });
          this.router.navigateByUrl(this.previousUrl);
        }
      })
      .catch(() => {
        // An error is already shown
      })
      .finally(() => (this.submitting = false));
  }

  saveNominations() {
    this.submitting = true;
    this.injector
      .get(PerformanceReviewService)
      .updateNominations(this.nominations, this.performanceId, false, this.userId)
      .then((success) => {
        if (success) {
          this.injector.get(MatLegacySnackBar).open(this.pageTranslations.savedMessage, 'OK', { duration: 5000 });
          this.router.navigateByUrl(this.previousUrl);
        }
      })
      .catch(() => {
        // An error is already shown
      })
      .finally(() => (this.submitting = false));
  }

  public openEmployeeDetail(userId: string): void {
    this.router.navigateByUrl(`cloud/people/${userId}`);
  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();
  }
}
