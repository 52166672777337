import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { KioskService } from '@app/standard/services/attendance/kiosk.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

import { EditPinCodeDialog } from '../../dialogs/edit-pincode.dialog';

@Component({
  selector: 'kenjo-people-detail-pincode-action',
  template: `
    <orgos-action *ngIf="!loading && (userPincode$ | async) !== undefined" class="kenjo-mt-20px" (click)="openPincodeDialog()">{{
      translations.editPinCodeButtonLabel
    }}</orgos-action>
  `,
})
export class PeopleDetailPincodeActionComponent implements OnInit, OnDestroy {
  @Input() userId: string;

  loading = true;
  translations: Record<string, string>;
  userPincode$ = this.injector.get(KioskService).userPinCode$;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    try {
      const [translations] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('people-detail-personal-page'),
        this.injector.get(KioskService).fetchPinCodeStatus(this.userId),
      ]);
      this.translations = translations;
    } catch (error) {
      this.translations = {};
    } finally {
      this.loading = false;
    }
  }

  async openPincodeDialog() {
    const data = { userId: this.userId };
    this.injector.get(MatLegacyDialog).open(EditPinCodeDialog, { data });
  }

  ngOnDestroy(): void {
    this.injector.get(KioskService).flushPincode();
  }
}
