import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { IUserAccountModel } from '@app/models/user-account.model';
import {
  calculateHoursAndMinutes,
  formatWorkSchedule,
  getCurrentWorkScheduleFromHistory,
} from '@app/standard/pages/people-detail/people-detail-attendance/people-detail.helpers';
import { IExpectedHoursByDayObject } from '@app/standard/services/user/controllers/attendance-summary.controller';
import { IUserWorkScheduleModel } from '@app/standard/services/user/user-work-schedule.service';
import * as moment from 'moment';

const MAX_HOURS_PER_DAY = 1440;
const GRACE_MINUTES = 'grace-minutes';
const WORK_SCHEDULE_TYPE = 'fixed';

export const restrictCheckIn = (
  startTime: number,
  loggedUser: IUserAccountModel,
  expectedHoursByDay: IExpectedHoursByDayObject,
  attendancePolicy: IAttendancePolicy,
  userWorkSchedule: IUserWorkScheduleModel
): {
  isRestrictCheckIn: boolean;
  nextShiftDate?: string;
  restrictTimeLimit?: string | number;
} => {
  const isAdmin = ['admin', 'hr-admin'].includes(loggedUser.profileKey);
  const language = loggedUser.language || 'en';
  const { isActive } = attendancePolicy?.restrictCheckIn;
  if (isAdmin || !isActive) {
    return { isRestrictCheckIn: false };
  }
  const date = moment();
  const fixedWorkSchedule = getCurrentWorkScheduleFromHistory(date, userWorkSchedule);
  const userWorkScheduleFormatted = formatWorkSchedule(fixedWorkSchedule, userWorkSchedule);
  const {
    mondayWorkingDay,
    tuesdayWorkingDay,
    wednesdayWorkingDay,
    thursdayWorkingDay,
    fridayWorkingDay,
    saturdayWorkingDay,
    sundayWorkingDay,
  } = userWorkScheduleFormatted;
  const workingDays = [
    mondayWorkingDay,
    tuesdayWorkingDay,
    wednesdayWorkingDay,
    thursdayWorkingDay,
    fridayWorkingDay,
    saturdayWorkingDay,
    sundayWorkingDay,
  ];
  let dayIndex = moment(date).isoWeekday() - 1;
  const currentDay = moment(date).date();
  let restrictTimeLimit;
  let nextShiftDate = '';
  if (startTime) {
    restrictTimeLimit = 1440;
  }

  if (!fixedWorkSchedule || fixedWorkSchedule.type !== WORK_SCHEDULE_TYPE) {
    return { isRestrictCheckIn: false, nextShiftDate, restrictTimeLimit };
  }

  if (!workingDays[dayIndex] || expectedHoursByDay?.[currentDay]?.bankHolidays) {
    const { nextShiftDate: nextShift, restrictTimeLimit: timeLimit } = setNextShiftValues(
      date,
      dayIndex,
      fixedWorkSchedule,
      expectedHoursByDay,
      userWorkScheduleFormatted,
      attendancePolicy,
      restrictTimeLimit,
      language
    );
    return { isRestrictCheckIn: true, nextShiftDate: nextShift, restrictTimeLimit: timeLimit };
  }

  const shiftsOnDay = fixedWorkSchedule.dayShifts[dayIndex].shifts;
  const {
    isWithinShift,
    restrictTimeLimit: timeLimit,
    nextShiftDate: nextShift,
  } = calculateIsWithinShift(shiftsOnDay, startTime, {
    dayOfMonth: date,
    dayIndex,
    fixedWorkSchedule,
    restrictTimeLimit,
    userWorkSchedule: userWorkScheduleFormatted,
    attendancePolicy,
    expectedHoursByDay,
    language,
  });
  restrictTimeLimit = timeLimit;
  nextShiftDate = nextShift;
  return { isRestrictCheckIn: !isWithinShift, restrictTimeLimit, nextShiftDate };
};

const setNextShiftValues = (
  date: moment.Moment,
  dayIndex: number,
  fixedWorkSchedule,
  expectedHoursByDay: IExpectedHoursByDayObject,
  userWorkSchedule: IUserWorkScheduleModel,
  attendancePolicy: IAttendancePolicy,
  restrictTimeLimit,
  language: string
) => {
  const { nextShiftDate, restrictTimeLimit: timeLimit } = calculateNextShiftDate({
    dayOfMonth: date,
    dayIndex,
    fixedWorkSchedule,
    restrictTimeLimit,
    userWorkSchedule,
    attendancePolicy,
    expectedHoursByDay,
    language,
  });
  return { nextShiftDate, restrictTimeLimit: timeLimit };
};

export const calculateNextShiftDate = (
  nextShiftObject: INextShiftObject,
  extraDay: number = 0
): { nextShiftDate: string; restrictTimeLimit: any } => {
  let { dayOfMonth, dayIndex, expectedHoursByDay, fixedWorkSchedule, restrictTimeLimit, attendancePolicy, userWorkSchedule, language } =
    nextShiftObject;
  let currentDay = moment(dayOfMonth).date() + extraDay;
  const {
    mondayWorkingDay,
    tuesdayWorkingDay,
    wednesdayWorkingDay,
    thursdayWorkingDay,
    fridayWorkingDay,
    saturdayWorkingDay,
    sundayWorkingDay,
  } = userWorkSchedule;
  const { graceMinutes, type: restrictType } = attendancePolicy?.restrictCheckIn;
  const workingDays = [
    mondayWorkingDay,
    tuesdayWorkingDay,
    wednesdayWorkingDay,
    thursdayWorkingDay,
    fridayWorkingDay,
    saturdayWorkingDay,
    sundayWorkingDay,
  ];
  let daysToAdd = extraDay;
  let previousStart = 1440;
  let nextShiftDate = '';

  for (let i = 0; i < Object.keys(expectedHoursByDay).length; i++) {
    if (workingDays[dayIndex] && !expectedHoursByDay[currentDay]?.bankHolidays) {
      break;
    }
    daysToAdd++;
    currentDay++;
    if (dayIndex >= 6) {
      dayIndex = 0;
    } else {
      dayIndex++;
    }

    if (currentDay >= Object.keys(expectedHoursByDay).length) {
      currentDay = 1;
    }
  }
  const shiftsOnDay = fixedWorkSchedule.dayShifts[dayIndex].shifts;
  shiftsOnDay.forEach(({ start }) => {
    if (previousStart > start) {
      previousStart = start;
    }
  });
  if (restrictType === GRACE_MINUTES) {
    restrictTimeLimit = calculateHoursAndMinutes(previousStart - graceMinutes);
  } else {
    restrictTimeLimit = calculateHoursAndMinutes(previousStart);
  }
  nextShiftDate = moment(dayOfMonth).add(daysToAdd, 'day').locale(language).format('D MMM');

  return { nextShiftDate: nextShiftDate, restrictTimeLimit: restrictTimeLimit };
};

export const calculateIsWithinShift = (
  shiftsOnDay: any,
  startTime: number,
  nextShiftObject: INextShiftObject
): { isWithinShift: boolean; restrictTimeLimit: number | string; nextShiftDate: string } => {
  let { attendancePolicy, restrictTimeLimit } = nextShiftObject;
  let nextShiftDate = '';
  const { graceMinutes, type: restrictType } = attendancePolicy?.restrictCheckIn;
  let isWithinShift: boolean;
  let timeLimit = MAX_HOURS_PER_DAY;
  const baseStartTime = startTime;
  let overnightShift = false;

  for (let i = 0; i < shiftsOnDay.length; i++) {
    const { start, end } = shiftsOnDay[i];
    const adjustedEnd = end < start ? end + MAX_HOURS_PER_DAY : end;
    startTime = end < start && startTime < start ? baseStartTime + MAX_HOURS_PER_DAY : baseStartTime;
    if (startTime !== baseStartTime && !overnightShift) {
      overnightShift = true;
      timeLimit = start;
    }
    if (restrictType === GRACE_MINUTES) {
      isWithinShift = startTime >= start - graceMinutes && startTime <= adjustedEnd;
      if (startTime <= start - graceMinutes && startTime < adjustedEnd && start - graceMinutes < timeLimit) {
        timeLimit = start - graceMinutes < 0 ? 0 : start - graceMinutes;
      }
    } else {
      isWithinShift = startTime >= start && startTime < adjustedEnd;
      if (startTime <= start && startTime <= adjustedEnd && start < timeLimit) {
        timeLimit = start;
      }
    }

    if (isWithinShift) {
      break;
    }
  }

  if (timeLimit === MAX_HOURS_PER_DAY && !overnightShift) {
    nextShiftObject.dayIndex++;
    const { nextShiftDate: nextShift, restrictTimeLimit: restrictCheckIn } = calculateNextShiftDate(nextShiftObject, 1);
    restrictTimeLimit = restrictCheckIn;
    nextShiftDate = nextShift;
  } else {
    restrictTimeLimit = calculateHoursAndMinutes(timeLimit);
  }
  return { isWithinShift, restrictTimeLimit, nextShiftDate };
};

export interface INextShiftObject {
  dayOfMonth: moment.Moment;
  dayIndex: number;
  fixedWorkSchedule: History;
  restrictTimeLimit: number | string;
  userWorkSchedule: IUserWorkScheduleModel;
  attendancePolicy: IAttendancePolicy;
  expectedHoursByDay: IExpectedHoursByDayObject;
  language: string;
}

export interface History {
  attendanceLimitSettings: AttendanceLimitSettings;
  isDefault: boolean;
  name: string;
  type: string;
  weeklyMinutes: number;
  startDate: Date;
  dayShifts: DayShift[];
  _id: string;
}

export interface AttendanceLimitSettings {
  allowEntriesInTheFuture: boolean;
  maxShiftLength: number;
  limitDailyHours: LimitDailyHours;
  breakReminder: BreakReminder;
}

export interface DayShift {
  shifts: Shift[];
  minutes?: number;
  _id: string;
}

export interface Shift {
  start?: number;
  end?: number;
  _id: string;
}

export interface LimitDailyHours {
  isActive: boolean;
  maxHoursPerDay: number;
}

export interface BreakReminder {
  isActive: boolean;
  minutesBeforeReminder: number;
  suggestedBreakLength: number;
  breakReminderMessage: string;
}
