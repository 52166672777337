<ng-container *ngIf="!loadingPage && goalCacheModel">
  <orgos-column-container centerColumns="true" class="gdp-top-bar kenjo-border-bottom kenjo-ph-20px">
    <orgos-column size="0">
      <kenjo-icon size="20" (click)="closeGoalDetailPage()" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> arrow_back</kenjo-icon>
    </orgos-column>

    <orgos-column size="0" class="gdp-separator kenjo-mh-20px kenjo-border-left"></orgos-column>

    <orgos-column size="0" class="kenjo-font-size-16px">{{i18n.page.Goal}}</orgos-column>

    <orgos-column size="0" class="gdp-separator kenjo-mh-20px kenjo-border-left"></orgos-column>
  </orgos-column-container>

  <div class="gdp-main-wrapper kenjo-ph-20px">
    <orgos-container aspect="card" class="gdp-main-container">
      <orgos-column-container>
        <orgos-column size="0" class="gdp-overview-column kenjo-p-40px kenjo-border-right">
          <div class="kenjo-font-size-16px kenjo-mb-10px">{{i18n.page.status}}</div>

          <div [matMenuTriggerFor]="statusMenu" class="kenjo-cursor-pointer" *ngIf="goalCacheModel && goalCacheModel.canEdit | async; else readOnlyStatus">
            <orgos-column-container centerColumns="true">
              <orgos-column size="0" class="kenjo-pr-10px">
                <div class="gdp-status-circle" [style.background-color]="statusColorMap[goalCacheModel.data.status]"></div>
              </orgos-column>
              <orgos-column size="0" class="kenjo-pr-10px"> {{i18n.picklists.goalStatus[goalCacheModel.data.status]}} </orgos-column>
              <orgos-column size="0">
                <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
              </orgos-column>
            </orgos-column-container>
          </div>
          <mat-menu #statusMenu="matMenu">
            <button *ngFor="let iStatus of listStatus" mat-menu-item (click)="changeStatusTo(iStatus)">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-pr-10px">
                  <orgos-container class="gdp-status-circle" [style.background-color]="statusColorMap[iStatus]"> </orgos-container>
                </orgos-column>
                <orgos-column>{{i18n.picklists.goalStatus[iStatus]}}</orgos-column>
              </orgos-column-container>
            </button>
          </mat-menu>
          <ng-template #readOnlyStatus>
            <orgos-column-container centerColumns="true">
              <orgos-column size="0" class="kenjo-pr-10px">
                <div class="gdp-status-circle" [style.background-color]="statusColorMap[goalCacheModel.data.status]"></div>
              </orgos-column>
              <orgos-column size="0" class="kenjo-pr-10px"> {{i18n.picklists.goalStatus[goalCacheModel.data.status]}} </orgos-column>
            </orgos-column-container>
          </ng-template>

          <div class="kenjo-mt-40px">
            <div class="kenjo-font-size-16px kenjo-mb-10px">{{i18n.page.info}}</div>
            <orgos-column-container centerColumns="true" class="kenjo-mb-10px">
              <orgos-column size="0" class="kenjo-mr-10px">
                <kenjo-icon *ngIf="goalCacheModel && goalCacheModel.data.type === TYPE_INDIVIDUAL" [size]="20">person </kenjo-icon>
                <kenjo-icon *ngIf="goalCacheModel && goalCacheModel.data.type === TYPE_DEPARTMENT" [size]="20">home </kenjo-icon>
                <kenjo-icon *ngIf="goalCacheModel && goalCacheModel.data.type === TYPE_BRAND" [size]="20">public </kenjo-icon>
              </orgos-column>
              <orgos-column>
                <div *ngIf="!departmentText">{{i18n.picklists.goalType[goalCacheModel.data.type]}} {{i18n.misc.goal}}</div>
                <div *ngIf="departmentText">{{departmentText}}</div>
              </orgos-column>
            </orgos-column-container>
            <orgos-column-container centerColumns="true">
              <orgos-column size="0" class="kenjo-mr-10px">
                <kenjo-icon [size]="20" [class.kenjo-font-color-danger-ff5757]="TODAY.isAfter(goalCacheModel.data.dueDate, 'day')">schedule </kenjo-icon>
              </orgos-column>
              <orgos-column *ngIf="goalCacheModel.data.dueDate" [class.kenjo-font-color-danger-ff5757]="TODAY.isAfter(goalCacheModel.data.dueDate, 'day')"> {{goalCacheModel.data.dueDate | utcDate | humanDate}} </orgos-column>
              <orgos-column *ngIf="!goalCacheModel.data.dueDate">{{i18n.page.noDueDate}}</orgos-column>
            </orgos-column-container>
          </div>
          <div class="kenjo-font-size-16px kenjo-mt-40px kenjo-mb-10px">{{i18n.page.owners}}</div>
          <orgos-user-item [width]="'calc(100% - 40px)'" *ngFor="let iOwner of goalCacheModel.data.assignedUsers" size="small" [title]="userPersonalMap[iOwner]?.displayName" [photoUrl]="userPersonalMap[iOwner]?._photo?._url" class="kenjo-mb-10px"></orgos-user-item>
          <div *ngIf="!goalCacheModel.data.assignedUsers || goalCacheModel.data.assignedUsers.length === 0" class="kenjo-font-color-light-text-757575">{{i18n.page.noOwners}}</div>
        </orgos-column>

        <orgos-column class="gdp-goal-column kenjo-p-40px">
          <orgos-column-container centerColumns="true" class="kenjo-mb-10px">
            <orgos-column size="1" class="kenjo-mr-10px">
              <kenjo-truncate-text class="kenjo-font-size-25px">{{goalCacheModel.data.title}}</kenjo-truncate-text>
            </orgos-column>
            <orgos-column size="0">
              <kenjo-icon *ngIf="goalCacheModel && (goalCacheModel.canEdit || goalCacheModel.canDelete) | async" [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert</kenjo-icon>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="editGoal()" *ngIf="goalCacheModel && goalCacheModel.canEdit | async">
                  <span>{{i18n.page.editLabel}}</span>
                </button>
                <button mat-menu-item (click)="deleteGoal()" *ngIf="goalCacheModel && goalCacheModel.canDelete | async">
                  <span>{{i18n.page.deleteLabel}}</span>
                </button>
              </mat-menu>
            </orgos-column>
          </orgos-column-container>
          <div class="kenjo-font-size-16px kenjo-font-color-light-text-757575 kenjo-mb-40px">{{createdOn}}</div>
          <orgos-text *ngIf="goalCacheModel.data.description && goalCacheModel.data.description.length > 0" [preserveLineBreaks]="true" class="kenjo-mb-40px kenjo-font-color-light-text-757575"> {{goalCacheModel.data.description}}</orgos-text>
          <ng-container *ngIf="goalCacheModel.data.keyResults && goalCacheModel.data.keyResults.length > 0">
            <div class="kenjo-font-size-18px kenjo-mb-20px">{{i18n.page.keyResults}}&nbsp;({{completedKeyResults()}}/{{goalCacheModel.data.keyResults.length}})</div>
            <orgos-column-container *ngFor="let keyResult of goalCacheModel.data.keyResults; let keyIndex = index" centerColumns="true" class="kenjo-mb-10px">
              <orgos-column size="0" class="kenjo-mr-10px">
                <kenjo-icon [size]="20" *ngIf="goalCacheModel && (goalCacheModel.canEdit | async) && keyResult.isCompleted" (click)="completeKeyResult(keyIndex)" class="kenjo-font-color-success-00b72e kenjo-cursor-pointer"> check_circle</kenjo-icon>
                <kenjo-icon [size]="20" *ngIf="goalCacheModel && (goalCacheModel.canEdit | async) && !keyResult.isCompleted" (click)="completeKeyResult(keyIndex)" class="kenjo-cursor-pointer"> check_circle_outline</kenjo-icon>
                <kenjo-icon [size]="20" *ngIf="(!goalCacheModel || !(goalCacheModel.canEdit | async)) && keyResult.isCompleted" class="kenjo-font-color-success-00b72e">check_circle</kenjo-icon>
                <kenjo-icon [size]="20" *ngIf="(!goalCacheModel || !(goalCacheModel.canEdit | async)) && !keyResult.isCompleted"> check_circle_outline</kenjo-icon>
              </orgos-column>
              <orgos-column class="kenjo-font-color-light-text-757575">{{keyResult.title}}</orgos-column>
            </orgos-column-container>
          </ng-container>
          <ng-container *ngIf="!goalCacheModel.data.keyResults || goalCacheModel.data.keyResults.length === 0">
            <div class="kenjo-font-size-18px">{{i18n.page.goalProgress}}</div>
            <orgos-column-container centerColumns="true" *ngIf="goalCacheModel.canEdit | async; else readOnlySlider">
              <orgos-column class="kenjo-mr-10px">
                <mat-slider #progressSlider min="0" max="100" step="1" [(value)]="progress" (valueChange)="updateProgress($event)" class="kenjo-block"></mat-slider>
              </orgos-column>
              <orgos-column size="0" style="width: 37px;"> {{progressSlider.displayValue | number:'1.0-0'}}% </orgos-column>
              <orgos-action orgosColumn="0" (click)="changeProgress()" *ngIf="progressChanged" class="kenjo-ml-10px">{{i18n.page.updateBtn}} </orgos-action>
            </orgos-column-container>
            <ng-template #readOnlySlider>
              <orgos-column-container centerColumns="true">
                <orgos-column class="kenjo-mr-10px">
                  <mat-slider #progressSliderReadOnly min="0" max="100" step="1" [(value)]="progress" class="kenjo-block" disabled="true"></mat-slider>
                </orgos-column>
                <orgos-column size="0" style="width: 37px;"> {{progressSliderReadOnly.displayValue | number:'1.0-0'}}% </orgos-column>
              </orgos-column-container>
            </ng-template>
          </ng-container>
          <div class="kenjo-font-size-18px kenjo-mt-30px">{{i18n.page.updates}}</div>
          <orgos-input-simple-text-area [(value)]="update" class="kenjo-block" [rows]="4" *ngIf="goalCacheModel && goalCacheModel.canEdit | async"></orgos-input-simple-text-area>
          <div class="kenjo-text-align-right kenjo-mb-30px">
            <orgos-button-raised (click)="postUpdate()" *ngIf="goalCacheModel && goalCacheModel.canEdit | async"> {{i18n.page.postBtn}}</orgos-button-raised>
          </div>

          <div *ngFor="let iUpdate of updates; let updateIndex = index">
            <div *ngIf="iUpdate.type === UPDATE_TYPE_USER && transforUpdate(iUpdate)" class="kenjo-p-20px kenjo-mt-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-mr-10px">
                  <kenjo-icon [size]="20" class="gdp-comment">conversation</kenjo-icon>
                </orgos-column>
                <orgos-column size="1"> {{transforUpdate(iUpdate).postedBy}} </orgos-column>
                <orgos-column size="0" class="kenjo-ml-20px"> {{iUpdate.when | humanDate}} </orgos-column>
                <orgos-column size="0" class="kenjo-ml-20px" *ngIf="goalCacheModel && goalCacheModel.canEdit | async">
                  <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert </kenjo-icon>
                  <mat-menu #actionsMenu="matMenu">
                    <button mat-menu-item (click)="deleteUpdate(updateIndex)">
                      <span>{{i18n.page.deleteLabel}}</span>
                    </button>
                  </mat-menu>
                </orgos-column>
              </orgos-column-container>
              <orgos-column-container class="kenjo-pt-10px">
                <orgos-column>
                  <orgos-text [preserveLineBreaks]="true" class="gdp-update-text"> {{iUpdate.text}} </orgos-text>
                </orgos-column>
              </orgos-column-container>
            </div>
            <div *ngIf="iUpdate.type !== UPDATE_TYPE_USER && transforUpdate(iUpdate)" class="kenjo-p-20px kenjo-mt-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
              <orgos-column-container centerColumns="true">
                <orgos-column size="0" class="kenjo-mr-10px">
                  <kenjo-icon [size]="20" class="gdp-updated">update</kenjo-icon>
                </orgos-column>
                <orgos-column size="1"> {{transforUpdate(iUpdate).postedBy}} </orgos-column>
                <orgos-column size="0" class="kenjo-ml-20px"> {{iUpdate.when | humanDate}} </orgos-column>
                <orgos-column size="0" class="kenjo-ml-20px" *ngIf="goalCacheModel && goalCacheModel.canEdit | async">
                  <kenjo-icon [size]="20" [matMenuTriggerFor]="actionsMenu" class="kenjo-cursor-pointer">more_vert </kenjo-icon>
                  <mat-menu #actionsMenu="matMenu">
                    <button mat-menu-item (click)="deleteUpdate(updateIndex)">
                      <span>{{i18n.page.deleteLabel}}</span>
                    </button>
                  </mat-menu>
                </orgos-column>
              </orgos-column-container>
              <orgos-column-container class="kenjo-pt-10px">
                <orgos-column>
                  <orgos-text [preserveLineBreaks]="true" class="gdp-update-text"> {{transforUpdate(iUpdate).text}} </orgos-text>
                </orgos-column>
              </orgos-column-container>
            </div>
          </div>
        </orgos-column>
      </orgos-column-container>
    </orgos-container>
  </div>
</ng-container>
