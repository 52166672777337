import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IBreak } from '@app/standard/services/user/user-attendance.service';

@Component({
  selector: 'kenjo-auto-deduct-dialog',
  templateUrl: 'auto-deduct-dialog.component.html',
  styleUrls: ['auto-deduct-dialog.component.scss'],
})
export class AutoDeductDialogComponent implements OnInit {
  translations: any;
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary' = 'Success';
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;
  confirmDisabled: boolean;
  cancelDisabled: boolean;
  bullets: Array<{ text: string }>;
  attendancePolicy: IAttendancePolicy;
  break: IBreak;
  tooltipText: string = '';
  isPastAutoDeduct: boolean;

  constructor(
    public dialogRef: MatLegacyDialogRef<AutoDeductDialogComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.confirmButtonId = this.data.confirmButtonId ?? '';
    this.cancelButtonId = this.data.cancelButtonId ?? '';
    this.componentId = this.data.componentId ?? null;
    this.confirmDisabled = this.data.confirmDisabled ?? false;
    this.cancelDisabled = this.data.cancelDisabled ?? false;
    this.dialogRef.disableClose = !!this.data.disableClose;
    this.attendancePolicy = this.data.attendancePolicy;
    this.break = this.data.break;
  }

  async ngOnInit(): Promise<void> {
    this.translations = await this.injector.get(InternationalizationService).getAllTranslation('breaks-dialog');
    this.titleText = this.translations.autoDeductDialog.title;
    this.subtitleText = this.translations.autoDeductDialog.subtitle;
    this.confirmButtonText = this.translations.autoDeductDialog.confirm;
    this.checkIsPastAutoDeduct();
    this.initTranslations();
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }

  initTranslations() {
    const { reminders } = this.attendancePolicy?.breakReminder;
    const tooltipReminders = [];

    for (const iReminder of reminders) {
      tooltipReminders.push(
        this.injector.get(I18nDataPipe).transform(this.translations?.autoDeductDialog?.reminder, {
          triggeredAfter: this.injector.get(DurationPipe).transform(iReminder?.triggeredAfter, false, true, true),
          suggestedBreak: this.injector.get(DurationPipe).transform(iReminder?.suggestedBreak, false, true, true),
        })
      );
    }
    this.bullets = [
      {
        text: this.injector.get(I18nDataPipe).transform(this.translations?.autoDeductDialog?.reminder, {
          triggeredAfter: this.injector.get(DurationPipe).transform(this.break?.triggeredAfter, false, true, true),
          suggestedBreak: this.injector.get(DurationPipe).transform(this.break?.suggestedBreak, false, true, true),
        }),
      },
    ];
    this.tooltipText = this.translations.autoDeductDialog.tooltip + tooltipReminders.join(', ') + '.';
  }

  checkIsPastAutoDeduct() {
    const { breakReminder } = this.attendancePolicy;

    let isPast = true;

    for (let index = 0; index < breakReminder?.reminders?.length; index++) {
      const reminder = breakReminder?.reminders[index];
      if (reminder?.suggestedBreak === this.break?.suggestedBreak && reminder?.triggeredAfter === this.break?.triggeredAfter) {
        isPast = false;
        break;
      }
    }
    this.isPastAutoDeduct = isPast;
  }
}

export interface IConfirmDialogData {
  titleText: string;
  subtitleText: string;
  confirmButtonText: string;
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary';
  cancelButtonText: string;
}
