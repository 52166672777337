<orgos-dialog-container *ngIf="dialogTranslation" class="dialog-box" [title]="dialogTranslation.dialogHeader" (clickCloseButton)="closeDialog()">
  <orgos-column-container centerColumns="true">
    <orgos-column size="1" *ngIf="signatureOptions">
      <orgos-text class="kenjo-mt-40px kenjo-font-size-12px kenjo-font-color-light-text-757575">{{dialogTranslation.messageInputLabel}}</orgos-text>
      <div class="kenjo-border kenjo-border-radius kenjo-mt-10px message-box kenjo-p-20px">
        <orgos-input-simple-editor [required]="false" [(value)]="signatureOptions.personalMessage" [toolbar]="toolbar" class="message-input"> </orgos-input-simple-editor>
      </div>
    </orgos-column>
  </orgos-column-container>

  <orgos-column-container class="button-panel kenjo-m-40px">
    <orgos-column></orgos-column>
    <orgos-button-raised orgosColumn="0" color="Neutral" (click)="onCancelAddPersonalMessage()">{{dialogTranslation.goBackButtonLabel}}</orgos-button-raised>
    <orgos-button-raised orgosColumn="0" color="Success" [disabled]="!signatureOptions.personalMessage" class="kenjo-ml-10px" (click)="onSaveAddPersonalMessage()">{{dialogTranslation.saveButtonLabel}}</orgos-button-raised>
  </orgos-column-container>
</orgos-dialog-container>
