import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class ApprovalRulesService implements IGenericService {
  private APPROVAL_RULES_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/time-off-approval-rule-db`;
  private APPROVAL_RULES_PERMISSIONS_KEY: string = 'time-off-approval-rule';
  private APPROVAL_RULES_INTERNATIONALIZATION: string = 'time-off-approval-rule-collection';

  constructor(private injector: Injector, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IApprovalRuleModel> {
    return new Promise<IApprovalRuleModel>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .create(this.APPROVAL_RULES_URL, data)
        .then((rule) => {
          resolve(rule);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApprovalRulesService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IApprovalRuleModel> {
    return new Promise<IApprovalRuleModel>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .getById(this.APPROVAL_RULES_URL, id)
        .then((office: IApprovalRuleModel) => {
          resolve(office);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApprovalRulesService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .updateById(this.APPROVAL_RULES_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApprovalRulesService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .deleteById(this.APPROVAL_RULES_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApprovalRulesService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.injector.get(GenericService).getPermissions(this.APPROVAL_RULES_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.injector.get(GenericService).getFieldsTranslations(this.APPROVAL_RULES_INTERNATIONALIZATION);
  }

  getApprovalRules(): Promise<Array<IApprovalRuleModel>> {
    const findBody = {
      _id: { $ne: null }
    };

    return new Promise<Array<IApprovalRuleModel>>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .find(this.APPROVAL_RULES_URL, findBody)
        .then((approvalRules: Array<IApprovalRuleModel>) => {
          resolve(approvalRules);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApprovalRulesService.name, 'getApprovalRules'));
        });
    });
  }

  find(findBody): Promise<Array<IApprovalRuleModel>> {
    return new Promise<Array<IApprovalRuleModel>>((resolve, reject) => {
      this.injector
        .get(GenericService)
        .find(this.APPROVAL_RULES_URL, findBody)
        .then((approvalRules: Array<IApprovalRuleModel>) => {
          resolve(approvalRules);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApprovalRulesService.name, 'find'));
        });
    });
  }

  createApprovalRule(approvalRule: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const body = {
        approvalRule
      };

      this.injector
        .get(GenericService)
        .create(`${this.APPROVAL_RULES_URL}`, body)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, ApprovalRulesService.name, 'createApprovalRule'));
        });
    });
  }
}

export interface IApprovalRuleModel {
  isActive: boolean;
  name: string;
  policies: Array<string>;
  conditions: Array<any>;
  approvers: any;
  _id?: string;
}
