<kenjo-breaks
  [attendancePolicy]="attendancePolicy"
  [loggedUser]="loggedUser"
  [restrictTimeLimit]="restrictTimeLimit"
  [nextShiftDate]="nextShiftDate"
  [isRestrictCheckIn]="isRestrictCheckIn"
  [attendanceConflictsCurrentDay]="attendanceConflictsToday"
  (restrictLimitChange)="onShiftChanges($event)"
  [translations]="translations"
  [userAttendance]="userAttendance"
  [limits]="limits"
  [minutesWorkedThisDay]="minutesWorkedThisDay"
  [readOnly]="readOnly"
  [otherShiftsThisDay]="otherShiftsThisDay"
  (close)="closeDialog($event)"
></kenjo-breaks>
