import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { IChurnZeroEnabled, PrivateOrganizationService } from '@app/private/services/private-organization.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { CandidateService } from '@app/standard/services/recruiting/candidate.service';
import { environment } from '@env';
import * as check from 'check-types';

const ALLOWED_STATUS = ['active', 'non_renewing', 'future'];
const EVENT_CATALOG = {
  DEACTIVATED_SHIFTPLAN: {
    name: 'Deactivate Shiftplan toggle',
    description: 'An admin deactivated Shiftplan from the toggle in the settings page',
  },
  LOGIN: {
    name: 'Login',
    description: 'A contact successfully logged in to the platform',
  },
  DUPLICATE_PERFORMANCE_REVIEW: {
    name: 'Duplicate performance review',
    description: 'A performance review was duplicated',
  },
  CLOSED_DATEV_PAYROLL: {
    name: 'DATEV payroll export',
    description: 'A DATEV payroll export was made in a Kenjo account',
  },
  CANDIDATE_ADDED_TO_JOB_OPENING: {
    name: 'New candidate added to a job opening',
    description: 'A candidate was added to a job opening',
    customFields: {
      origin: {
        name: 'cf_Origin',
      },
    },
  },
  BILLING_INFO_UPDATE: {
    name: 'Billing info updated',
    description: 'Billing info update from the billings page',
  },
  PAYMENT_METHOD_ADDED: {
    name: 'Payment method added',
    description: 'Payment method added from the billings page',
  },
  SHIFTPLAN_ACTIVATED_FOR_EMPLOYEE: {
    name: 'Shiftplan activated for employee',
    description: 'Shiftplan activated for employee from profile page',
  },
  SHIFTPLAN_MOBILE_NOTIFICATIONS_ACTIVATED: {
    name: 'Shiftplan mobile notifications activated',
    description: 'Shiftplan mobile notifications activated from shiftplan settings',
  },
  EMPLOYEE_ASSIGNED_TO_TIMEOFF_POLICY: {
    name: 'Employee assigned to timeoff policy',
    description: 'Employee assigned to timeoff policy from timeoff settings',
  },
  DEPARTMENT_ADDED: {
    name: 'Department added',
    description: 'Department added from company settings',
  },
  COMPANY_SMARTDOC_ADDED: {
    name: 'Smartdoc added to company',
    description: 'Smartdoc added to company',
  },
  PAYROLL_GROUP_CREATED: {
    name: 'Payroll group created',
    description: 'Payroll group created from payroll settings tab',
  },
  NEW_CUSTOM_WORKFLOW_CREATED: {
    name: 'New custom workflow created',
    description: 'New custom workflow created from workflow settings tab',
  },
  LOGO_ADDED: {
    name: 'Logo added',
    description: 'Logo added from settings overview tab',
  },
  EMAIL_CONTENT_UPDATED: {
    name: 'Email content updated',
    description: 'Invitation email content updated from welcome wizard tab',
  },
  PULSE_FREQUENCY_CHANGED: {
    name: 'Pulse activated + added/changed frequency',
    description: 'Pulse frequency updated from Pulse setting tab',
  },
  MEETING_TEMPLATE_CREATED: {
    name: 'Meeting template created',
    description: 'Meeting template created from meeting page',
  },
  BULK_IMPORT_EMPLOYEE_DATA: {
    name: 'Bulk import employee data',
    description: 'Bulk import employee data from bulk import page',
  },
  BULK_IMPORT_SALARY_DATA: {
    name: 'Bulk import salary data',
    description: 'Bulk import salary data from bulk import page',
  },
  ADMIN_USER_ADDED: {
    name: 'New admin user added',
    description: 'A new admin is added to an account',
  },
};

// https://github.com/InvisibleCRM/ChurnZeroJS/blob/main/src/client.ts
interface IChurnZero {
  push(args: Array<any>): void;
}

// Prevents "Property 'ChurnZero' does not exist on type window" error by 'casting' window into IChurnZeroAPI
export interface IChurnZeroAPI {
  ChurnZero: IChurnZero;
}
const CHURNZERO_API: Partial<IChurnZeroAPI> = window as any;

interface IChurnzeroConfig {
  url: string;
  apiKey: string;
  accountId: string;
  contactId: string;
}

interface IChurnzeroEvent {
  name: string;
  description?: string;
  quantity?: number;
  customFields?: Array<{ name: string; value: string }>;
}
/**
 * This Class loads the script for CZ so customer success can view metrics from connected accounts in the CZ console.
 * No data is sent to CZ from the web app. All the data is sent from the server for security reasons
 */
@Injectable({
  providedIn: 'root',
})
export class PrivateChurnzeroService {
  private CHURNZERO_TRACK_EVENT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/churnzero/track-event`;

  private _churnzeroConfig: IChurnzeroConfig;
  private _churnZeroInstance: IChurnZero;
  private _initializedForUser: { [key: string]: boolean } = {};

  constructor(private injector: Injector) {}

  private async loadConfig(): Promise<void> {
    const myOrg = await this.injector.get(PrivateOrganizationService).getMyOrganization();
    const myUser = this.injector.get(PrivateAuthenticationService).getLoggedUser();
    this._churnzeroConfig = {
      url: `${environment.CHURNZERO_API_ENDPOINT}/churnzero.js`,
      apiKey: environment.CHURNZERO_API_KEY,
      accountId: myOrg._id,
      contactId: myUser.email,
    };
  }

  private loadScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const firstScriptElement = document.getElementsByTagName('script')[0];
      const churnZeroScript = document.createElement('script');
      churnZeroScript.async = true;
      churnZeroScript.src = this._churnzeroConfig.url;
      churnZeroScript.id = 'churnzero_script';
      churnZeroScript.type = 'text/javascript';
      firstScriptElement.parentNode.insertBefore(churnZeroScript, firstScriptElement);
      churnZeroScript.onload = () => {
        resolve();
      };
      churnZeroScript.onerror = (event, source, lineno, colno, error) => {
        reject(new Error(`Could not load ChurnZero script: ${typeof event === 'string' ? event : ''} ${error?.message}`));
      };
    });
  }

  private async connect(): Promise<void> {
    // in order to use the churnzero client to connect to churnzero api,
    // we can store this variable in a class attribute of type IChurnZero and call the push method from elsewhere in the app
    this._churnZeroInstance = CHURNZERO_API.ChurnZero;
    this._churnZeroInstance.push(['setAppKey', this._churnzeroConfig.apiKey]);
    this._churnZeroInstance.push(['setContact', this._churnzeroConfig.accountId, this._churnzeroConfig.contactId]);
  }

  public async loadChurnZero(): Promise<void> {
    if (
      check.not.assigned(environment.CHURNZERO_API_ENDPOINT) ||
      check.not.assigned(environment.CHURNZERO_API_KEY) ||
      check.emptyString(environment.CHURNZERO_API_ENDPOINT) ||
      check.emptyString(environment.CHURNZERO_API_KEY)
    ) {
      this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently('ChurnZero keys not defined', PrivateChurnzeroService.name, 'loadChurnZero');
      return;
    }
    try {
      if (!(await this.checkChurnZeroEnabled()) || this._initializedForUser[this._churnzeroConfig?.contactId]) {
        return;
      }
      // signing in with google or microsoft will always reload the page and the script
      await this.loadConfig();
      if (check.not.assigned(document.getElementById('churnzero_script'))) {
        await this.loadScript();
      }
      await this.connect();
      this._initializedForUser[this._churnzeroConfig.contactId] = true;
    } catch (error) {
      this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(`Error loading ChurnZero script: ${error?.message}`, PrivateChurnzeroService.name, 'loadChurnZero');
    }
  }

  public async churnZeroSignOut(): Promise<void> {
    if (check.assigned(this._churnZeroInstance)) {
      this._churnZeroInstance.push(['stop']);
    }
    delete this._initializedForUser[this._churnzeroConfig?.contactId];
  }

  private async checkChurnZeroEnabled(): Promise<boolean> {
    const subscription: IChurnZeroEnabled = await this.injector.get(PrivateOrganizationService).isChurnZeroEnabled();
    return subscription._churnzeroEnabled || ALLOWED_STATUS.includes(subscription.subscriptionStatus);
  }

  /**
   * We send the event from the backend instead of using churnzero js directly to prevent samesite: strict trolling
   * This method is run in the background and does not need awaiting
   * @param czEvent event to send to cz
   */
  private trackEvent(czEvent: IChurnzeroEvent): void {
    const eventBody = {
      eventName: czEvent.name,
      description: czEvent.description,
      ...(check.nonEmptyArray(czEvent.customFields) && { customFields: czEvent.customFields }),
    };
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
    const httpOptions = {
      headers: httpHeaders,
    };
    this.injector
      .get(HttpClient)
      .post(`${this.CHURNZERO_TRACK_EVENT_URL}`, eventBody, httpOptions)
      .toPromise()
      // try/catch does not capture async call without await
      .catch((error: any) =>
        this.injector.get(ErrorManagerService).handleRawErrorSilently(error, PrivateChurnzeroService.name, `trackEvent: ${czEvent.name}`)
      );
  }

  private async canTrackEvent(): Promise<boolean> {
    if (this._initializedForUser[this._churnzeroConfig?.contactId] === true) {
      return true;
    }
    if ((await this.checkChurnZeroEnabled()) === true) {
      return true;
    }
    return false;
  }

  public async logSimpleEvent(eventKey: string): Promise<void> {
    if ((await this.canTrackEvent()) === false) {
      return;
    }
    this.trackEvent(EVENT_CATALOG[eventKey]);
  }

  public async logDeactivateShiftplanEvent(): Promise<void> {
    // prevents signout tomfoolery
    if (!this._initializedForUser[this._churnzeroConfig?.contactId] && !(await this.checkChurnZeroEnabled())) {
      return;
    }

    this.trackEvent(EVENT_CATALOG.DEACTIVATED_SHIFTPLAN);
  }

  public async logLoginEvent(): Promise<void> {
    if ((await this.canTrackEvent()) === false) {
      return;
    }
    this.trackEvent(EVENT_CATALOG.LOGIN);
  }

  public async logDuplicatePerformanceReviewEvent(): Promise<void> {
    if ((await this.canTrackEvent()) === false) {
      return;
    }
    this.trackEvent(EVENT_CATALOG.DUPLICATE_PERFORMANCE_REVIEW);
  }

  public async logDatevExportEvent(): Promise<void> {
    if ((await this.canTrackEvent()) === false) {
      return;
    }
    this.trackEvent(EVENT_CATALOG.CLOSED_DATEV_PAYROLL);
  }

  public async logNewCandidateAssignedToPositionEvent(candidateId: string, candidateOrigin?: string) {
    try {
      if ((await this.canTrackEvent()) === false) {
        return;
      }
      let originKey = candidateOrigin;
      if (check.nonEmptyString(candidateOrigin) === false) {
        const candidate: any = await this.injector.get(CandidateService).getById(candidateId);
        originKey = candidate.origin;
      }

      // we get the possible values from the backend. lang=en makes the backend not find anything, so it returns english as backup
      const urlParams = new HttpParams().set('lang', 'en');
      const httpOptions = {
        params: urlParams,
      };
      const { candidateOrigin: candidateOriginValues } = (await this.injector
        .get(HttpClient)
        .get(`${environment.PEOPLE_CLOUD_APP_URL}/internationalization-db/translations/standard-picklists`, httpOptions)
        .toPromise()) as any;

      const event: IChurnzeroEvent = {
        name: EVENT_CATALOG.CANDIDATE_ADDED_TO_JOB_OPENING.name,
        description: EVENT_CATALOG.CANDIDATE_ADDED_TO_JOB_OPENING.description,
        customFields: [
          {
            name: EVENT_CATALOG.CANDIDATE_ADDED_TO_JOB_OPENING.customFields.origin.name,
            value: candidateOriginValues[originKey] ?? originKey,
          },
        ],
      };
      await this.trackEvent(event);
    } catch (error) {
      this.injector
        .get(ErrorManagerService)
        .handleRawErrorSilently(error, PrivateChurnzeroService.name, 'logNewCandidateAssignedToPositionEvent');
    }
  }
}
