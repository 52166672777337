<div class="sspp-main-standard-container" *ngIf="dataLoaded">
  <div class="sspp-main-container">
    <!-- Top Menu Bar -->
    <div class="kenjo-border-bottom kenjo-border-right kenjo-color-white-background-ffffff sspp-sticky sspp-non-droppable">
      <orgos-column-container [centerColumns]="true" class="kenjo-pl-20px sspp-menu">
        <!-- Date pickers -->
        <orgos-column-container orgosColumn [centerColumns]="true" class="date-pickers">
          <kenjo-shiftplan-weekly-picker
            *ngIf="view === VIEWS.WEEK && weekRange"
            orgosColumn="0"
            [states]="{ loading, dragging: isDragging, dropping: isDropping }"
            [weekRange]="weekRange"
            [parentTranslation]="{ doneButton: pageTranslation.doneButton }"
            (valueChange)="navigateToWeek($event)"
          ></kenjo-shiftplan-weekly-picker>

          <kenjo-shiftplan-monthly-picker
            *ngIf="view === VIEWS.MONTH && monthRange"
            orgosColumn="0"
            [states]="{ loading, dragging: isDragging, dropping: isDropping }"
            [monthRange]="monthRange"
            (valueChange)="navigateToMonth($event)"
          ></kenjo-shiftplan-monthly-picker>

          <div orgosColumn="0" class="kenjo-ml-20px">
            <div class="sspp-range-selector kenjo-border kenjo-font-size-14px" [matMenuTriggerFor]="viewMassMenu">
              <div *ngIf="viewOptionLabels" class="kenjo-font-color-text-333333">{{ viewOptionLabels[view] }}</div>
              <kenjo-icon class="view-arrow-icon" [size]="24">arrow_drop_down</kenjo-icon>
            </div>
            <mat-menu #viewMassMenu="matMenu">
              <orgos-column-container centerColumns="true" *ngFor="let viewOption of viewOptions">
                <button
                  orgosColumn
                  mat-menu-item
                  [disabled]="loading"
                  class="kenjo-font-size-14px"
                  (click)="changeViewLayout(viewOption.value, null, null)"
                >
                  {{ viewOption.name }}
                </button>
              </orgos-column-container>
            </mat-menu>
          </div>
          <orgos-column size="0">
            <orgos-action
              *ngIf="view === VIEWS.WEEK"
              [disabled]="loading || isDropping || !show.today"
              (click)="navigateToWeek(DATE_VALUES.CURRENT)"
              class="kenjo-mh-20px"
            >
              {{ pageTranslation.today }}</orgos-action
            >
            <orgos-action
              *ngIf="view === VIEWS.MONTH"
              [disabled]="loading || isDropping"
              (click)="navigateTodayMonth()"
              class="kenjo-mh-20px"
            >
              {{ pageTranslation.today }}</orgos-action
            >
          </orgos-column>
        </orgos-column-container>

        <orgos-column-container orgosColumn="0" class="kenjo-pr-20px" [centerColumns]="true">
          <orgos-column class="sspp-input-select-column kenjo-pr-10px">
            <div *ngIf="generalSettings?.showPublicHolidays !== false" id="calendarButton">
              <div
                class="sspp-filter-list-btn"
                (click)="showDropdownMenu('calendarMenu')"
                (menuClosed)="show.calendarMenu = false"
                [matMenuTriggerFor]="calendarMenu"
                [class.sspp-grabbing]="isDragging"
                [class.kenjo-font-color-primary-5993e3]="show.calendarMenu"
                [class.disabled]="loading || isDropping"
              >
                <mat-icon>event</mat-icon>
                <div>{{pageTranslation.holidays}}</div>
              </div>
              <mat-menu #calendarMenu="matMenu" (menuClosed)="show.calendarMenu = false">
                <div class="sspp-layout-dropdown kenjo-p-10px">
                  <div class="kenjo-font-color-light-text-757575 kenjo-text-transform-uppercase sspp-layout-dropdown-header">
                    {{pageTranslation.publicHolidays}}
                  </div>
                  <mat-checkbox
                    *ngFor="let calendar of dropdownCalendars"
                    class="sspp-public-holidays-element"
                    [checked]="calendar.isSelected"
                    (click)="$event.stopPropagation(); toggleCalendarSelection(calendar._id)"
                  >
                    <kenjo-truncate-text class="sspp-calendar-dropdown kenjo-font-size-14px kenjo-ml-10px"
                      >{{ calendar.name }}</kenjo-truncate-text
                    >
                    <div *ngIf="calendar.isOffice" class="kenjo-font-size-12px kenjo-ml-10px">
                      {{ pageTranslation.yourAssignedLocation }}
                    </div>
                  </mat-checkbox>
                </div>
              </mat-menu>
            </div>
            <div id="layoutButton">
              <div
                class="sspp-filter-list-btn kenjo-font-color-primary-5993e3"
                (click)="showDropdownMenu('layoutMenu')"
                (menuClosed)="show.layoutMenu = false"
                [matMenuTriggerFor]="layoutMassMenu"
                [class.sspp-grabbing]="isDragging"
                [class.kenjo-font-color-primary-5993e3]="show.layoutMenu"
                [class.disabled]="loading || isDropping"
              >
                <mat-icon>space_dashboard</mat-icon>
                <div>{{pageTranslation.layout}}</div>
              </div>
              <mat-menu #layoutMassMenu="matMenu" (menuClosed)="show.layoutMenu = false">
                <div class="sspp-layout-dropdown kenjo-p-10px">
                  <ng-container>
                    <div class="kenjo-font-color-light-text-757575 kenjo-text-transform-uppercase sspp-layout-dropdown-header">
                      {{pageTranslation.layout}}
                    </div>
                    <mat-radio-group class="sspp-layout-dropdown-group">
                      <mat-radio-button
                        class="kenjo-font-size-14px sspp-layout-dropdown-element"
                        (change)="changeViewLayout(null, layoutOption.value, null)"
                        *ngFor="let layoutOption of layoutOptions"
                        [value]="layoutOption.value"
                        [checked]="layoutOption.value === layout"
                      >
                        {{layoutOption.name}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </ng-container>
                  <ng-container>
                    <div class="kenjo-font-color-light-text-757575 kenjo-text-transform-uppercase sspp-layout-dropdown-header">
                      {{pageTranslation.cardSize}}
                    </div>
                    <mat-radio-group class="sspp-layout-dropdown-group">
                      <ng-container *ngFor="let cardSizeOption of cardSizeOptions">
                        <mat-radio-button
                          *ngIf="(view === VIEWS.WEEK && cardSizeOption.value !== 'minimal') || (view === VIEWS.MONTH && cardSizeOption.value !== 'expanded')"
                          class="kenjo-font-size-14px sspp-layout-dropdown-element"
                          (change)="changeViewLayout(null, null, cardSizeOption.value)"
                          [value]="cardSizeOption.value"
                          [checked]="cardSizeOption.value === cardSize"
                        >
                          {{cardSizeOption.name}}
                        </mat-radio-button>
                      </ng-container>
                    </mat-radio-group>
                  </ng-container>
                </div>
              </mat-menu>
            </div>
            <div
              (click)="show.filters = loading ? show.filters : !show.filters"
              class="sspp-filter-list-btn"
              [class.sspp-grabbing]="isDragging"
              [class.kenjo-font-color-primary-5993e3]="show.filters"
              [class.disabled]="loading || isDropping"
            >
              <mat-icon>filter_list</mat-icon>
              <div>{{pageTranslation.filters}}</div>
            </div>
            <div
              *ngIf="shiftplanPermissions['templates'].read"
              class="sspp-filter-list-btn kenjo-font-color-primary-5993e3"
              (click)="showTemplateDropdownMenu()"
              [class.sspp-grabbing]="isDragging"
              [class.kenjo-font-color-primary-5993e3]="show.templateMenu"
              [class.disabled]="loading || isDropping"
            >
              <mat-icon>style</mat-icon>
              <div>{{pageTranslation.templates}}</div>
            </div>
          </orgos-column>

          <orgos-column class="sspp-input-select-column">
            <div *ngIf="shiftplanPermissions['copy'] || shiftplanPermissions['bulk_delete']" class="ssp-divider">
              <orgos-button-raised [disabled]="loading || isDropping" [matMenuTriggerFor]="massMenu" class="kenjo-mv-10px" color="Primary">
                {{pageTranslation.actionsButton | uppercase}}
              </orgos-button-raised>
              <mat-menu #massMenu="matMenu">
                <ng-container>
                  <button *ngIf="shiftplanPermissions['copy']" mat-menu-item (click)="openCopyShiftsDialog()">
                    {{pageTranslation.copyShifts}}
                  </button>
                  <button
                    mat-menu-item
                    [disabled]="shiftCards?.openDraftCount === 0 && shiftCards?.openPublishedCount === 0 && shiftCards?.scheduledDraftCount === 0 && shiftCards?.scheduledPublishedCount === 0"
                    (click)="openGeneratePDFDialog()"
                  >
                    {{pageTranslation.generatePDF}}
                  </button>
                  <div
                    *ngIf="shiftCards && shiftplanPermissions['bulk_delete']"
                    [matTooltip]="pageTranslation.noUnpublishedSelectShiftsTooltip"
                    [matTooltipDisabled]="shiftCards?.openDraftCount > 0 || shiftCards.scheduledDraftCount > 0"
                    [matTooltipPosition]="'before'"
                  >
                    <button
                      mat-menu-item
                      [disabled]="shiftCards?.openDraftCount === 0 && shiftCards.scheduledDraftCount === 0"
                      (click)="showSelectShiftsPopUp(true)"
                    >
                      {{pageTranslation.selectShifts}}
                    </button>
                  </div>
                  <div
                    *ngIf="shiftCards && shiftplanPermissions['bulk_delete']"
                    [matTooltip]="pageTranslation.noUnpublishedShiftsTooltip"
                    [matTooltipDisabled]="shiftCards?.openDraftCount > 0 || shiftCards.scheduledDraftCount > 0"
                    [matTooltipPosition]="'before'"
                  >
                    <button
                      mat-menu-item
                      [disabled]="shiftCards?.openDraftCount === 0 && shiftCards.scheduledDraftCount === 0"
                      (click)="openClearScheduleDialog()"
                    >
                      {{pageTranslation.clearSchedule}}
                    </button>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
            <div class="kenjo-ml-10px">
              <orgos-button-raised
                *ngIf="shiftplanPermissions['publish']"
                [disabled]="!shiftCards || (shiftCards.scheduledDraftCount === 0 && shiftCards.openDraftCount === 0 ) || loading || isDropping || isPublishing"
                (click)="openPublishDialog()"
                class="kenjo-mv-10px sspp-publish-btn"
                color="Success"
              >
                <div
                  *ngIf="!(!shiftCards || (shiftCards.scheduledDraftCount === 0 && shiftCards.openDraftCount === 0 ) || loading || isDropping || isPublishing)"
                  class="indicator-box"
                >
                  <div class="indicator-dot"></div>
                </div>
                {{pageTranslation.publishButton}}
              </orgos-button-raised>
            </div>
          </orgos-column>
        </orgos-column-container>
      </orgos-column-container>
    </div>

    <!-- Templates panel -->
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <kenjo-shift-plan-template-panel
        *ngIf="show.templateMenu"
        [showTemplateDetails]="show.templateDetails"
        [isDragging]="isDragging"
        [closePanel]="closePanel"
        [LIST_IDS]="LIST_IDS"
        [breaktimeDeduction]="breaktimeDeduction"
        [openShifts]="generalSettings.openShifts"
        [allDataResponse]="allDataResponse"
        [panelStatus]="panelStatus"
        [filters]="filters"
        (handleDraggingTemplateEvent)="handleDraggingTemplate($event)"
        (addNewShiftEvent)="addNewShift($event.selectedDayIndex, $event.selectedData, $event.template)"
      ></kenjo-shift-plan-template-panel>
    </div>

    <!-- Filters -->
    <div *ngIf="show.filters" class="kenjo-mt-10px kenjo-mh-20px sspp-non-droppable" [class.sspp-top-67px]="show.filters">
      <orgos-column-container
        [centerColumns]="true"
        [class.kenjo-mv-10px]="!shiftplanPermissions['copy'] && !shiftplanPermissions['publish'] && !shiftplanPermissions['bulk_delete']"
      >
        <orgos-column>
          <kenjo-filter-bar
            class="sspp-filters"
            [filterOptions]="allFilterOptions"
            [shown]="true"
            [includeEmployeeSearch]="false"
            [applyFiltersImmediately]="false"
            [resetTooltipPosition]="'left'"
            [disabledApplyFilters]="loading"
            (changeFilterEvent)="changeFilters($event)"
            [applyEachFilter]="true"
          ></kenjo-filter-bar>
        </orgos-column>
      </orgos-column-container>
    </div>

    <!-- Publish banner -->
    <orgos-column-container
      *ngIf="show.publishReminder === true && (shiftCards?.scheduledDraftCount > 0 || shiftCards?.openDraftCount > 0) && !loading"
      class="sspp-publish-banner kenjo-ph-10px kenjo-p-10px kenjo-mh-20px kenjo-mt-10px"
      [centerColumns]="true"
    >
      <kenjo-icon orgosColumn="0" [size]="18" class="kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
      <orgos-column *ngIf="shiftCards.scheduledDraftCount + shiftCards.openDraftCount > 1" class="kenjo-pl-10px"
        >{{ pageTranslation.publishReminderMany | i18nData : { shiftsNumber: shiftCards.scheduledDraftCount + shiftCards.openDraftCount,
        view: viewOptionLabels[view] | lowercase } }}</orgos-column
      >
      <orgos-column *ngIf="shiftCards.scheduledDraftCount + shiftCards.openDraftCount === 1" class="kenjo-pl-10px"
        >{{ pageTranslation.publishReminderOne | i18nData: { view: viewOptionLabels[view] | lowercase } }}</orgos-column
      >
      <kenjo-icon
        orgosColumn="0"
        [size]="18"
        (click)="show.publishReminder = false;"
        class="kenjo-cursor-pointer kenjo-font-color-light-text-757575"
        [disabled]="loading"
        >clear</kenjo-icon
      >
    </orgos-column-container>
    <div *ngIf="showNoResultsForFiltersApllied" class="sspp-non-droppable">
      <div class="kenjo-text-align-center ssp-template-box">
        <img src="/assets/images/emptyState.svg" />
        <div class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-weight-bold">{{pageTranslation.filtersNoMatchingEmployeesTitle}}</div>
        <div class="kenjo-mt-10px kenjo-font-size-12px">{{pageTranslation.filtersNoMatchingEmployeesDescription}}</div>
        <orgos-button-raised class="kenjo-mt-20px kenjo-text-transform-uppercase" color="Primary" (click)="clearFilters()">
          {{ pageTranslation.clearFiltersButton}}</orgos-button-raised
        >
      </div>
    </div>
    <div
      *ngIf="!showNoResultsForFiltersApllied"
      class="sspp-shifts-parent-container kenjo-mt-10px kenjo-mh-20px kenjo-mb-20px"
      [class.no-filters]="(!show.filters && !show.publishReminder) || (!show.filters && show.publishReminder === true && (shiftCards?.scheduledDraftCount === 0 || shiftCards?.openDraftCount === 0))"
      [class.filters]="(show.filters && !show.publishReminder) || (show.filters && show.publishReminder === true && (shiftCards?.scheduledDraftCount === 0 || shiftCards?.openDraftCount === 0))"
      [class.publish-banner-filters]="show.filters && show.publishReminder === true && (shiftCards?.scheduledDraftCount > 0 || shiftCards?.openDraftCount > 0)"
      [class.publish-banner-no-filters]="!show.filters && show.publishReminder === true && (shiftCards?.scheduledDraftCount > 0 || shiftCards?.openDraftCount > 0)"
      #mainContainer
      (scroll)="onScroll()"
      cdkDropListGroup
      cdkScrollable
    >
      <div id="droppable" class="sspp-shifts-container" [class.monthly]="view === VIEWS.MONTH">
        <div class="sspp-sticky">
          <orgos-column-container>
            <orgos-column
              *ngIf="shiftplanPermissions['open'].create === true || shiftplanPermissions['scheduled'].create === true"
              (click)="registerNewShift(true); addNewShift();"
              size="0"
              class="sspp-add-shift-background kenjo-font-color-white-ffffff sspp-add-shift"
              [class.kenjo-cursor-pointer]="!isDragging"
              [class.disabled]="loading || isDropping"
              [class.sspp-scroll-x-indicator]="hasScrolled.left"
              [class.sticky-month]="hasScrolled.left"
              [class.monthly]="view === VIEWS.MONTH"
              [class.public-holidays]="isLoaded && !isEmptyPublicHolidays && (generalSettings?.showPublicHolidays === true || generalSettings?.showPublicHolidays === undefined)"
            >
              <div>
                <mat-icon *ngIf="view === VIEWS.WEEK" class="kenjo-font-size-25px kenjo-pr-10px">add_circle</mat-icon>
                <div class="kenjo-inline-block" [class.sspp-break-word]="view === VIEWS.MONTH && !show.minimal">
                  {{pageTranslation.addShiftButton | uppercase}}
                </div>
              </div>
            </orgos-column>
            <orgos-column
              *ngIf="!(shiftplanPermissions['open'].create === true || shiftplanPermissions['scheduled'].create === true)"
              size="0"
              class="kenjo-color-white-background-ffffff sspp-add-shift kenjo-border-bottom"
              [class.sspp-scroll-x-indicator]="hasScrolled.left"
              [class.sticky-month]="hasScrolled.left"
              [class.monthly]="view === VIEWS.MONTH"
              [class.public-holidays]="isLoaded && !isEmptyPublicHolidays && (generalSettings?.showPublicHolidays === true || generalSettings?.showPublicHolidays === undefined)"
            ></orgos-column>
            <orgos-column *ngIf="view === VIEWS.WEEK" class="kenjo-border-bottom" [class.sspp-no-selected-day]="selectedDayIndex === -1">
              <mat-tab-group
                *ngIf="daysOfCurrentWeek && daysOfCurrentWeek.labels?.length > 0"
                #tabsDate
                [selectedIndex]="selectedDayIndex"
                [class.sspp-grabbing]="isDragging"
                [disableRipple]="true"
                [class.sspp-day-cell]="isLoaded && !isEmptyPublicHolidays"
              >
                <mat-tab *ngFor="let day of daysOfCurrentWeek.labels ; let dayIndex = index" disabled [label]="day | uppercase">
                  <ng-template mat-tab-label>
                    <div>
                      <ng-container *ngIf="isLoaded">
                        <kenjo-shift-plan-public-holiday
                          *ngIf="!isEmptyPublicHolidays"
                          mat-button
                          class="kenjo-mh-15px sspp-public-holiday kenjo-mb-5px"
                          [publicHolidays]="publicHolidays[dayIndex]"
                          [dayIndex]="dayIndex"
                          [date]="daysOfCurrentWeek.value[dayIndex]"
                          [totalOfDays]="daysOfCurrentWeek.labels?.length"
                          [view]="view"
                          [language]="currentUser.language"
                          [translations]="{publicHolidays: pageTranslation.publicHolidays, holidayIn: pageTranslation.holidayIn}"
                        ></kenjo-shift-plan-public-holiday>
                      </ng-container>
                      <div class="date">{{ day }}</div>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </orgos-column>
            <orgos-column
              *ngIf="view === VIEWS.MONTH"
              class="kenjo-border-bottom sspp-no-selected-day sspp-monthly-tabs"
              [class.minimal]="show.minimal"
            >
              <mat-tab-group
                *ngIf="daysOfCurrentMonth && daysOfCurrentMonth.labels?.length > 0"
                #tabsDate
                [class.sspp-grabbing]="isDragging"
                [class.sspp-day-cell]="isLoaded && !isEmptyPublicHolidays"
              >
                <mat-tab *ngFor="let day of daysOfCurrentMonth.labels; let dayIndex = index" disabled>
                  <ng-template mat-tab-label>
                    <div
                      class="sspp-monthly-tab"
                      [class.no-public-holidays]="isEmptyPublicHolidays"
                      [class.weekend]="daysOfCurrentMonth.value[dayIndex] | isWeekend"
                      [class.highlighted]="hoverInfo.index === dayIndex && hoverInfo.value"
                      [class.selected]="selectedDayIndex === dayIndex"
                    >
                      <ng-container *ngIf="isLoaded">
                        <kenjo-shift-plan-public-holiday
                          *ngIf="!isEmptyPublicHolidays"
                          mat-button
                          class="sspp-public-holiday kenjo-mb-5px"
                          [dayIndex]="dayIndex"
                          [publicHolidays]="publicHolidays[dayIndex]"
                          [date]="daysOfCurrentMonth.value[dayIndex]"
                          [totalOfDays]="daysOfCurrentMonth.labels?.length"
                          [view]="view"
                          [language]="currentUser.language"
                          [translations]="{publicHolidays: pageTranslation.publicHolidays, holidayIn: pageTranslation.holidayIn}"
                        ></kenjo-shift-plan-public-holiday>
                      </ng-container>
                      <div class="label">{{ day }}</div>
                      <div class="day">{{ dayIndex + 1 }}</div>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </orgos-column>
          </orgos-column-container>
        </div>

        <ng-container *ngIf="!loading">
          <orgos-column-container class="kenjo-color-white-background-ffffff sspp-border-bottom-open-shifts">
            <orgos-column size="0" class="kenjo-p-10px" [class.sspp-sticky-month]="hasScrolled.left">
              <div class="kenjo-font-color-success-00b72e kenjo-font-size-14px">{{pageTranslation.openShifts}}</div>
            </orgos-column>
            <orgos-column></orgos-column>
          </orgos-column-container>

          <div *ngIf="shiftCards">
            <orgos-column-container>
              <orgos-column
                size="0"
                class="sspp-open-shift kenjo-color-grey-background-f5f5f5 sspp-load-more sspp-mw-200"
                [class.sspp-scroll-x-indicator]="hasScrolled.left"
                [class.sticky-month]="hasScrolled.left"
              >
                <a *ngIf="PENDING_SHIFTS > 0" class="sspp-load-more" (click)="loadMore()">
                  <orgos-column-container centerColumns="true">
                    <orgos-column size="0"> {{pageTranslation.seeMore}} </orgos-column>
                    <kenjo-icon orgosColumn="0">keyboard_arrow_down</kenjo-icon>
                  </orgos-column-container>
                </a>
                <a *ngIf="PENDING_SHIFTS <= 0 && SHIFTS_PAGE !== 1" class="sspp-load-more" (click)="loadLess()">
                  <orgos-column-container centerColumns="true">
                    <orgos-column size="0"> {{pageTranslation.seeLess}} </orgos-column>
                    <kenjo-icon orgosColumn="0">keyboard_arrow_up</kenjo-icon>
                  </orgos-column-container>
                </a>
              </orgos-column>
              <orgos-column
                class="sspp-open-shift"
                [class.monthly-view]="view === VIEWS.MONTH"
                [class.minimal]="show.minimal"
                [class.dragging]="isDragging"
                [class.block]="blockDrop.open"
                *ngFor="let dayShifts of shiftCards.openShiftCardsGroupedByDay; let i = index"
                [attr.data-index]="i"
                id="{{cdkDropListString}}-{{i}}-{{OPEN_SHIFT}}-{{EMPLOYEE_LAYOUT}}"
                cdkDrop
                cdkDropList
                (cdkDropListDropped)="drop($event)"
                cdkDropListSortingDisabled
              >
                <ng-container *ngFor="let shift of dayShifts | slice:0:(SHIFTS_PAGE * SHIFTS_PAGINATION); trackBy: trackById">
                  <kenjo-shift-card
                    *ngIf="show.cards"
                    (draggingEventEmitter)="handleDraggingShiftCard($event, shift, i)"
                    [class.kenjo-cursor-pointer]="!isDragging"
                    [shift]="shift"
                    [view]="view"
                    [componentTranslation]="shiftTranslation"
                    [breaktimeDeduction]="breaktimeDeduction"
                    [cardSizeSettings]="cardSizeSettings"
                    [isSelectShiftsActive]="isSelectShiftsActive"
                    [clear]="clearAll"
                    [select]="selectAll"
                    [selected]="selectedShiftIds[shift._id]"
                    (valueSelectedChange)="changeShiftsSelected($event, { _id: shift._id, ownerId: shift.ownerId, isOpen: !shift?.employeeId })"
                    (openReadOnly)="openShiftReadOnlyMode(shift, null, { employee: 'open-shift' })"
                  >
                  </kenjo-shift-card>
                </ng-container>
                <div
                  *ngIf="!blockDrop.open"
                  (click)="registerNewShift();addNewShift(i, { employee: 'open-shift' })"
                  (mouseenter)="onHover('enter', i)"
                  (mouseleave)="onHover('leave', i)"
                  class="add-shift"
                  [class.sspp-grabbing]]="isDragging"
                  [class.kenjo-cursor-pointer]="!isDragging"
                  [class.small-add-shift]="dayShifts.length > 0"
                  [class.minimal]="show.minimal"
                  [class.month]="view === VIEWS.MONTH"
                >
                  <mat-icon class="sspp-icon-size kenjo-font-color-primary-5993e3" [class.reduced]="view === VIEWS.MONTH">add</mat-icon>
                </div>
                <div
                  *ngIf="blockDrop.open"
                  class="block-drop"
                  [class.sspp-grabbing]="isDragging"
                  [class.small-add-shift]="dayShifts.length > 0"
                  [class.month]="view === VIEWS.MONTH"
                >
                  <mat-icon class="sspp-icon-size kenjo-font-color-danger-ff5757" [class.reduced]="view === VIEWS.MONTH">blocked</mat-icon>
                </div>
              </orgos-column>
            </orgos-column-container>
          </div>

          <orgos-column-container class="kenjo-color-white-background-ffffff sspp-border-bottom-scheduled-shifts">
            <orgos-column size="0" class="kenjo-p-10px" [class.sspp-sticky-month]="hasScrolled.left">
              <div class="kenjo-font-size-14px">{{pageTranslation.scheduledShifts}}</div>
            </orgos-column>
            <orgos-column></orgos-column>
          </orgos-column-container>
        </ng-container>

        <div *ngIf="layout === LAYOUT.USER && !loading" class="kenjo-color-white-background-ffffff">
          <orgos-column-container
            *ngFor="let employee of shiftCards?.shiftsGrouped | slice:0:(SHIFTS_PAGE_EMPLOYEE * SHIFTS_PAGINATION_EMPLOYEE); trackBy: trackById"
          >
            <orgos-column
              size="0"
              class="sspp-display-employee-column kenjo-color-white-background-ffffff"
              [class.sspp-scroll-x-indicator]="hasScrolled.left"
              [class.sticky-month]="hasScrolled.left"
            >
              <kenjo-shift-plan-employee-display
                class="sspp-display-employee"
                [employee]="employee"
                [totalExpected]="employeeSchedules?.[employee._id]?.totalExpected ?? 0"
                [view]="view"
                [attendance]="{ overtimeTracking: usersOvertimeTracking?.[employee._id], startDay: attendanceStartDay, hasOvertime: attendanceSummary?.[employee._id], tracking: usersTrackingSettings?.[employee._id], showAttendance: generalSettings.showAttendance, showOvertimeTracking: showOvertimeTracking }"
                [parentTranslation]="pageTranslation"
              >
              </kenjo-shift-plan-employee-display>
              <!-- TODO: Keep or remove it depending on the evolution of this feature -->
              <!-- <kenjo-icon size="14" *ngIf="!(employee.startDate && today.isSameOrAfter(employee.startDate)) || !(employee.contractEnd && today.isSameOrBefore(employee.contractEnd))" [matTooltip]="pageTranslation.warningEmployeeShiftPlanTooltip" class="kenjo-font-color-warning-ffc107 sspp-warning-icon"
            >warning_outline</kenjo-icon
          > -->
            </orgos-column>
            <orgos-column
              class="sspp-open-shift"
              [class.monthly-view]="view === VIEWS.MONTH"
              [class.minimal]="show.minimal"
              [class.dragging]="isDragging"
              [class.block]="blockDrop.scheduled"
              *ngFor="let dayShifts of employee.shiftsByDay; let i = index"
              [attr.data-index]="i"
              id="{{cdkDropListString}}-{{i}}-{{employee._id}}-{{EMPLOYEE_LAYOUT}}"
              cdkDrop
              cdkDropList
              (cdkDropListDropped)="drop($event)"
              cdkDropListSortingDisabled
            >
              <kenjo-shift-plan-time-off-card
                *ngIf="generalSettings.showTimeOff && timeOffs?.[employee._id] && show.cards"
                [timeOff]="timeOffs?.[employee._id]?.days[i]"
                [showMore]="timeOffs?.[employee._id]?.isThereAnyList"
                [currentDay]="{ index: i, value: view === VIEWS.WEEK ? daysOfCurrentWeek.value[i] : daysOfCurrentMonth.value[i] }"
                [dateRange]="view === VIEWS.WEEK ?  weekRange : monthRange"
                [view]="view"
                [container]="container"
                [userId]="employee._id"
                [container]="container"
                [componentTranslation]="timeOffTranslation"
                [statusMaps]="statusTimeOffMaps"
                (blockScroll)="scrollBlocked = $event"
              ></kenjo-shift-plan-time-off-card>
              <ng-container *ngIf="show.cards">
                <kenjo-shift-card
                  (draggingEventEmitter)="handleDraggingShiftCard($event, shift, i)"
                  [class.kenjo-cursor-pointer]="!isDragging"
                  *ngFor="let shift of dayShifts; trackBy: trackById"
                  [shift]="shift"
                  [view]="view"
                  [componentTranslation]="shiftTranslation"
                  [breaktimeDeduction]="breaktimeDeduction"
                  [cardSizeSettings]="cardSizeSettings"
                  [isSelectShiftsActive]="isSelectShiftsActive"
                  [clear]="clearAll"
                  [select]="selectAll"
                  [selected]="selectedShiftIds[shift._id]"
                  (valueSelectedChange)="changeShiftsSelected($event, { _id: shift._id, ownerId: shift.ownerId, isOpen: !shift?.employeeId })"
                  (openReadOnly)="openShiftReadOnlyMode(shift)"
                >
                </kenjo-shift-card>
              </ng-container>

              <!--  Wish day -->
              <kenjo-shiftplan-wish-day
                *ngIf="wishDaysById?.[employee._id]"
                [ngStyle]="{ 'height': dayShifts?.length === 0 ? '100%' : 'unset' }"
                [userWishDays]="wishDaysById[employee._id]"
                [date]="view === VIEWS.WEEK ? daysOfCurrentWeek.value[i] : daysOfCurrentMonth.value[i]"
                [view]="view"
                [emptyShifts]="dayShifts?.length === 0"
                [wishDayType]="generalSettings.wishDays?.type"
                [componentTranslation]="pageTranslation"
                [isMinimal]="show.minimal"
              >
              </kenjo-shiftplan-wish-day>
              <div
                *ngIf="!blockDrop.scheduled"
                (click)="registerNewShift();addNewShift(i, {employee: employee._id})"
                (mouseenter)="onHover('enter', i)"
                (mouseleave)="onHover('leave', i)"
                class="add-shift"
                [class.kenjo-cursor-pointer]="!isDragging"
                [class.sspp-grabbing]="isDragging"
                [class.small-add-shift]="dayShifts.length > 0"
                [class.minimal]="show.minimal"
                [class.month]="view === VIEWS.MONTH"
              >
                <mat-icon class="sspp-icon-size kenjo-font-color-primary-5993e3" [class.reduced]="view === VIEWS.MONTH">add</mat-icon>
              </div>
              <div
                *ngIf="blockDrop.scheduled"
                class="block-drop"
                [class.sspp-grabbing]="isDragging"
                [class.small-add-shift]="dayShifts.length > 0"
                [class.month]="view === VIEWS.MONTH"
                [class.minimal]="show.minimal"
              >
                <mat-icon class="sspp-icon-size kenjo-font-color-danger-ff5757" [class.reduced]="view === VIEWS.MONTH">blocked</mat-icon>
              </div>
            </orgos-column>
          </orgos-column-container>
          <orgos-column-container
            *ngIf="PENDING_EMPLOYEE > 0 && show.loadingMore"
            [centerColumns]="true"
            class="kenjo-border-right kenjo-pv-20px"
          >
            <orgos-column [style.margin-left]="scrollInfo.left + 'px'" class="sspp-load-more-employees">
              <orgos-loading-spinner [size]="'Large'" [color]="'Success'"></orgos-loading-spinner>
            </orgos-column>
          </orgos-column-container>
        </div>
        <ng-container *ngIf="layout === LAYOUT.ROLE && !loading">
          <div *ngIf="!roles || roles.length === 0 " class="sspp-no-roles-row">
            <div class="kenjo-text-align-center kenjo-pv-30px kenjo-ph-120px kenjo-mv-60px kenjo-color-white-background-ffffff">
              <div>
                <kenjo-icon [size]="16" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
                <span class="kenjo-font-size-14px kenjo-font-weight-bold">{{pageTranslation.noRolesHeader}}</span>
              </div>
              <div class="kenjo-font-size-12px kenjo-mt-10px kenjo-font-color-light-text-757575">
                {{pageTranslation.noRolesDescription}}
              </div>
              <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">
                <span>{{pageTranslation.noRolesGoToSettings}}</span>&nbsp;<a (click)="navigateToSettings()"
                  >{{pageTranslation.settingsLink}}</a
                >
              </div>
            </div>
          </div>

          <div *ngFor="let role of shiftCards?.shiftsGrouped">
            <ng-container *ngIf="role?.shiftsGrouped?.length > 0">
              <div
                (click)="role.expanded = !role.expanded"
                [style.background-color]="colorConstants[role.colour]"
                [class.kenjo-cursor-pointer]="!isDragging"
              >
                <orgos-column-container [centerColumns]="true" class="kenjo-font-size-14px kenjo-font-color-white-ffffff">
                  <orgos-column-container
                    class="kenjo-p-10px"
                    orgosColumn="0"
                    size="0"
                    [centerColumns]="true"
                    [class.sspp-sticky-month]="hasScrolled.left"
                  >
                    <kenjo-icon *ngIf="!role.expanded" orgosColumn="0">keyboard_arrow_down</kenjo-icon>
                    <kenjo-icon *ngIf="role.expanded" orgosColumn="0">keyboard_arrow_up</kenjo-icon>
                    <orgos-column class="kenjo-pl-5px"> {{role.name | uppercase}} </orgos-column>
                  </orgos-column-container>
                  <orgos-column></orgos-column>
                </orgos-column-container>
              </div>
            </ng-container>

            <div *ngIf="role.expanded" class="kenjo-color-white-background-ffffff">
              <orgos-column-container *ngFor="let employee of role.shiftsGrouped; trackBy: trackById">
                <orgos-column
                  size="0"
                  class="sspp-display-employee-column kenjo-color-white-background-ffffff"
                  [class.sspp-scroll-x-indicator]="hasScrolled.left"
                  [class.sticky-month]="hasScrolled.left"
                >
                  <kenjo-shift-plan-employee-display
                    class="sspp-display-employee"
                    [employee]="employee"
                    [totalExpected]="employeeSchedules?.[employee._id]?.totalExpected ?? 0"
                    [shiftsWorkingTime]="userMapTotalWorkingTime?.[employee._id]?.totalWorkingHoursInMinutes"
                    [warningTooltip]="pageTranslation.warningUpdateRole"
                    [view]="view"
                    [attendance]="{ overtimeTracking: usersOvertimeTracking?.[employee._id], startDay: attendanceStartDay, hasOvertime: attendanceSummary?.[employee._id], tracking: usersTrackingSettings?.[employee._id], showAttendance: generalSettings.showAttendance, showOvertimeTracking: showOvertimeTracking }"
                    [parentTranslation]="pageTranslation"
                  >
                  </kenjo-shift-plan-employee-display>
                </orgos-column>
                <orgos-column
                  class="sspp-open-shift"
                  [class.monthly-view]="view === VIEWS.MONTH"
                  [class.minimal]="show.minimal"
                  [class.dragging]="isDragging"
                  [class.block]="blockDrop.scheduled"
                  *ngFor="let dayShifts of employee.shiftsByDay; let i = index"
                  [attr.data-index]="i"
                  id="{{cdkDropListString}}-{{i}}-{{employee._id}}-{{role._id}}"
                  cdkDrop
                  cdkDropList
                  (cdkDropListDropped)="drop($event)"
                  cdkDropListSortingDisabled
                >
                  <kenjo-shift-plan-time-off-card
                    *ngIf="generalSettings.showTimeOff && timeOffs?.[employee._id] && show.cards"
                    [timeOff]="timeOffs?.[employee._id]?.days[i]"
                    [showMore]="timeOffs?.[employee._id]?.isThereAnyList"
                    [currentDay]="{ index: i, value: view === VIEWS.WEEK ? daysOfCurrentWeek?.value[i] : daysOfCurrentMonth?.value[i] }"
                    [dateRange]="view === VIEWS.WEEK ? weekRange : monthRange"
                    [userId]="employee._id"
                    [view]="view"
                    [container]="container"
                    [componentTranslation]="timeOffTranslation"
                    [statusMaps]="statusTimeOffMaps"
                    (blockScroll)="scrollBlocked = $event"
                  ></kenjo-shift-plan-time-off-card>
                  <ng-container *ngIf="show.cards">
                    <kenjo-shift-card
                      (draggingEventEmitter)="handleDraggingShiftCard($event, shift, i)"
                      [class.kenjo-cursor-pointer]="!isDragging"
                      *ngFor="let shift of dayShifts; trackBy: trackById"
                      [shift]="shift"
                      [view]="view"
                      [componentTranslation]="shiftTranslation"
                      [breaktimeDeduction]="breaktimeDeduction"
                      [cardSizeSettings]="cardSizeSettings"
                      [isSelectShiftsActive]="isSelectShiftsActive"
                      [clear]="clearAll"
                      [select]="selectAll"
                      [selected]="selectedShiftIds[shift._id]"
                      (valueSelectedChange)="changeShiftsSelected($event, { _id: shift._id, ownerId: shift.ownerId, isOpen: !shift?.employeeId })"
                      (openReadOnly)="openShiftReadOnlyMode(shift)"
                    >
                    </kenjo-shift-card>
                  </ng-container>

                  <!--  Wish day -->
                  <kenjo-shiftplan-wish-day
                    *ngIf="wishDaysById?.[employee._id]"
                    [ngStyle]="{'height': dayShifts?.length === 0 ? 'unset' : '100%' }"
                    [userWishDays]="wishDaysById[employee._id]"
                    [date]="view === VIEWS.WEEK ? daysOfCurrentWeek.value[i] : daysOfCurrentMonth.value[i]"
                    [view]="view"
                    [emptyShifts]="dayShifts?.length === 0"
                    [wishDayType]="generalSettings.wishDays?.type"
                    [componentTranslation]="pageTranslation"
                    [isMinimal]="show.minimal"
                  >
                  </kenjo-shiftplan-wish-day>
                  <div
                    *ngIf="!blockDrop.scheduled"
                    (click)="registerNewShift(); addNewShift(i, {role: role._id, employee: employee._id})"
                    (mouseenter)="onHover('enter', i)"
                    (mouseleave)="onHover('leave', i)"
                    class="add-shift"
                    [class.kenjo-cursor-pointer]="!isDragging"
                    [class.sspp-grabbing]="isDragging"
                    [class.small-add-shift]="dayShifts.length > 0"
                    [class.minimal]="show.minimal"
                    [class.month]="view === VIEWS.MONTH"
                  >
                    <mat-icon class="sspp-icon-size kenjo-font-color-primary-5993e3" [class.reduced]="view === VIEWS.MONTH">add</mat-icon>
                  </div>
                  <div
                    *ngIf="blockDrop.scheduled"
                    class="block-drop"
                    [class.sspp-grabbing]="isDragging"
                    [class.small-add-shift]="dayShifts.length > 0"
                    [class.month]="view === VIEWS.MONTH"
                    [class.minimal]="show.minimal"
                  >
                    <mat-icon class="sspp-icon-size kenjo-font-color-danger-ff5757" [class.reduced]="view === VIEWS.MONTH"
                      >blocked</mat-icon
                    >
                  </div>
                </orgos-column>
              </orgos-column-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="layout === 'working-area'">
          <div *ngFor="let workingArea of workingAreas">
            <div class="sspp-bg-color-dedede">
              <orgos-column-container>
                <orgos-column class="kenjo-p-10px">
                  <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{workingArea.name | uppercase}}</div>
                </orgos-column>
              </orgos-column-container>
            </div>

            <div>
              <orgos-column-container>
                <orgos-column
                  size="0"
                  [class.kenjo-cursor-pointer]="!isDragging"
                  class="kenjo-font-color-primary-5993e3 sspp-add-shift-role"
                >
                  <div>
                    <mat-icon class="kenjo-font-size-25px kenjo-pr-10px">add_circle</mat-icon>
                    <div class="kenjo-inline-block">{{pageTranslation.addShiftButton | uppercase}}</div>
                  </div>
                </orgos-column>
                <orgos-column class="sspp-open-shift" *ngFor="let shifts of weekDaysArray"></orgos-column>
              </orgos-column-container>
            </div>
          </div>
        </ng-container>
        <!-- Loading Spinner -->
        <div
          *ngIf="loading === true"
          class="sspp-loading-container"
          [class.monthly]="view === VIEWS.MONTH"
          [style.margin-left]="scrollInfo.left + 'px'"
        >
          <div class="kenjo-text-align-center">
            <orgos-loading-spinner></orgos-loading-spinner>
            <div class="kenjo-mt-20px">{{ pageTranslation.loading }}</div>
          </div>
        </div>
        <!-- Capacity footer -->
        <kenjo-shift-plan-capacity
          *ngIf="shiftCards && !loading && shiftplanPermissions['capacity']"
          class="kenjo-border-top sspp-sticky capacity"
          [states]="{ loading, dragging: isDragging, dropping: isDropping }"
          [parentTranslation]="pageTranslation"
          [workingTimeInMinutesPerDay]="shiftCards.workingTimeInMinutesPerDay"
          [employeeCountByDays]="shiftCards.employeeCountByDays"
          [viewRange]="view"
          [daysOfThisMonth]="daysOfCurrentMonth?.value"
          [hasScrolled]="hasScrolled.left"
          [minimal]="show.minimal"
        ></kenjo-shift-plan-capacity>
      </div>
      <kenjo-scroll-to-top *ngIf="hasScrolled.top" [container]="container"></kenjo-scroll-to-top>
    </div>
  </div>
</div>
<kenjo-shift-plan-select-shifts
  *ngIf="isSelectShiftsActive"
  [loading]="loading"
  [parentTranslation]="pageTranslation"
  [selectedShifts]="selectedShiftIdsLength"
  [shiftsNumber]="shiftsNumber"
  (clearAllEvent)="clearAllSelectedShifts()"
  (selectAllEvent)="selectAllShifts()"
  (closeDialog)="closeSelectShiftsPopUp()"
  (deleteDialog)="openCustomDeleteDialog()"
></kenjo-shift-plan-select-shifts>
<kenjo-loading-screen
  *ngIf="exporting"
  [title]="generatePDFtranslations.generatingPDFtitle"
  [subtitle]="generatePDFtranslations.generatingPDFsubtitle"
></kenjo-loading-screen>
<kenjo-shift-plan-pdf-template
  *ngIf="exporting && optionsPDF"
  [options]="optionsPDF"
  [filters]="filters"
  [users]="allEmployees"
  [generalSettings]="generalSettings"
  [componentTranslations]="generatePDFtranslations"
  (exported)="exporting = false"
></kenjo-shift-plan-pdf-template>
