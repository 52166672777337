import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as _ from 'lodash';

import { InternationalizationService } from '../../services/core/internationalization.service';
import { I18nDataPipe } from '../i18n-data/i18n-data.pipe';

@Pipe({
  name: 'splitDuration',
  pure: false
})
export class SplitDurationPipe implements PipeTransform {
  private latestRawValue: number;
  private latestComputedValue: string | null = null;

  constructor(private internationalizationService: InternationalizationService, private i18nDataPipe: I18nDataPipe) {}

  transform(value: number, type: 'minutes' | 'hours', usePlusSymbol: boolean = false): string | null {
    if (check.not.assigned(value) || check.not.number(value)) {
      return null;
    }

    if (_.isEqual(this.latestRawValue, value)) {
      return this.latestComputedValue;
    }

    this.latestRawValue = value;

    const miscTranslation = this.internationalizationService.getMiscTranslation();

    if (type === 'hours') {
      this.latestComputedValue = this.convertHoursDuration(value, miscTranslation.durationHours !== undefined ? miscTranslation.durationHours : '%%data.hours%%h', usePlusSymbol);
    }

    if (type === 'minutes') {
      this.latestComputedValue = this.convertMinutesDuration(value, miscTranslation.durationMinutes !== undefined ? miscTranslation.durationMinutes : '%%data.minutes%%min', usePlusSymbol);
    }

    return this.latestComputedValue;
  }

  private convertMinutesDuration(value: number, translation: string, usePlusSymbol: boolean): string {
    const absValue = Math.abs(value);
    const convertedValue = {
      minutes: Math.round((absValue / 60 - Math.floor(absValue / 60)) * 60)
    };

    return this.i18nDataPipe.transform(translation, convertedValue);
  }

  private convertHoursDuration(value: number, translation: string, usePlusSymbol: boolean): string {
    const absValue = Math.abs(value);
    const convertedValue = {
      hours: Math.floor(absValue / 60)
    };
    let duration = this.i18nDataPipe.transform(translation, convertedValue);

    if (value < 0) {
      duration = `-${duration}`;
    }
    if (value > 0 && usePlusSymbol === true) {
      duration = `+${duration}`;
    }

    return duration;
  }
}
