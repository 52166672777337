import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IShiftCardSizeSettings } from '@app/cloud-features/shift-plan/services/settings-shift-plan-general.service';
import { IShiftCard } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { IShiftPlanPermissions, ShiftPlanPermissionsService } from '@app/cloud-features/shift-plan/services/shift-plan-permissions.service';
import { SHIFT_PLAN_CARD_COMPACT, SHIFT_PLAN_CARD_EXPANDED, SHIFT_PLAN_CARD_STATUS_DRAFT, SHIFT_PLAN_CARD_STATUS_PUBLISHED, SHIFT_PLAN_MONTHLY_VIEW, SHIFT_PLAN_WEEKLY_VIEW } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as moment from 'moment';
const DEFAULT_HEIGHT = '93px';
const DEFAULT_WIDTH = '83px';

@Component({
  animations: [
    trigger('cardAnimation', [
      state('expanded', style({ height: '93px' })),
      state('compact', style({ height: '{{height}}', width: '{{width}}' }), { params: { height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH } }),
      state('minimal', style({ width: '100%' })),
      transition('* <=> *', [animate('400ms cubic-bezier(.17,.7,.32,1)')])
    ])
  ],
  selector: 'kenjo-shift-card',
  templateUrl: 'shift-card.component.html',
  styleUrls: ['shift-card.component.scss']
})
export class ShiftCardComponent implements OnInit {
  start: string;
  end: string;
  shiftDuration: { hours: number; minutes: number } = { hours: 0, minutes: 0 };
  private MINUTES_IN_AN_HOUR: number = 60;
  private MINUTES_IN_A_DAY: number = 1440;
  cardColor: string;
  cardColorTransparent: string;
  OPACITY_HEX_NUMBER: string = '1A';
  isPublished: boolean = false;
  _isSelectShiftsActive: boolean = false;
  _cardSizeSettings: IShiftCardSizeSettings;
  shiftplanPermissions: IShiftPlanPermissions;
  showMinimalInfo: boolean = false;
  trucantedRoleName: string;
  isPublishedOpenShift: boolean = false;

  CARD_SIZE = { COMPACT: SHIFT_PLAN_CARD_COMPACT, EXPANDED: SHIFT_PLAN_CARD_EXPANDED };
  STATUS = { DRAFT: SHIFT_PLAN_CARD_STATUS_DRAFT, PUBLISHED: SHIFT_PLAN_CARD_STATUS_PUBLISHED };
  VIEWS = { MONTH: SHIFT_PLAN_MONTHLY_VIEW, WEEK: SHIFT_PLAN_WEEKLY_VIEW };

  @Input() shift: IShiftCard;
  @Input() view: string;
  @Input() breaktimeDeduction: boolean = false;
  @Input() componentTranslation: { [key: string]: string };
  @Input() selected: { _id: string; ownerId: string; isOpen: boolean };

  @Input()
  set isSelectShiftsActive(value: boolean) {
    this._isSelectShiftsActive = value;
    if (!value) {
      this.setSelectShiftsField(value);
    }
  }
  get isSelectShiftsActive() {
    return this._isSelectShiftsActive;
  }

  @Input()
  set cardSizeSettings(value: IShiftCardSizeSettings) {
    this._cardSizeSettings = value;
    this.calculateRoleName();
  }
  get cardSizeSettings() {
    return this._cardSizeSettings;
  }

  @Input()
  set clear(value: boolean) {
    if (value && this.isSelectShiftsActive) {
      this.setSelectShiftsField(false);
    }
  }

  @Input()
  set select(value: boolean) {
    if (value && this.isSelectShiftsActive) {
      this.changeSelection(true);
    }
  }

  @Output() valueSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openReadOnly: EventEmitter<void> = new EventEmitter<void>();
  @Output() draggingEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.getPermissions();
    const isSelected = check.assigned(this.selected) && this.isSelectShiftsActive;
    this.setSelectShiftsField(isSelected);
    this.calculateShiftDuration();
    this.calculateColor();
  }

  async getPermissions() {
    this.shiftplanPermissions = await this.injector.get(ShiftPlanPermissionsService).getShiftplanAppPermissions();
    this.isPublished = this.shift.status === this.STATUS.PUBLISHED;
    this.isPublishedOpenShift = this.isPublished && !this.shift.employeeId;
  }

  private setSelectShiftsField(value: boolean) {
    this.shift.selected = value;
  }

  private calculateShiftDuration() {
    if (check.assigned(this.shift.start) && !check.assigned(this.shift.end)) {
      this.shiftDuration = { hours: 0, minutes: 0 };
    } else {
      const startDate = moment.utc(this.shift.date).clone().add(this.shift.start, 'minutes');
      const endDate = moment.utc(this.shift.date).clone().add(this.shift.end, 'minutes');
      this.start = startDate.format('HH:mm');
      this.end = endDate.format('HH:mm');
      const breakDeducted = this.breaktimeDeduction ? 1 : 0;
      const minutesDuration = this.shift.end > this.shift.start ? this.shift.end - this.shift.start - (this.shift.break ?? 0) * breakDeducted : this.shift.end + this.MINUTES_IN_A_DAY - this.shift.start - (this.shift.break ?? 0) * breakDeducted;
      this.shiftDuration = { hours: Math.floor(minutesDuration / this.MINUTES_IN_AN_HOUR), minutes: minutesDuration % this.MINUTES_IN_AN_HOUR };
    }
  }

  private calculateColor() {
    this.cardColor = userColorConstants[this.shift.role.colour] ?? this.shift.role.colour;
    this.cardColorTransparent = `linear-gradient(135deg, #ffffff 25%, ${userColorConstants[this.shift.role.colour] + this.OPACITY_HEX_NUMBER} 25%, ${userColorConstants[this.shift.role.colour] + this.OPACITY_HEX_NUMBER} 50%, #ffffff 50%, #ffffff 75%, ${
      userColorConstants[this.shift.role.colour] + this.OPACITY_HEX_NUMBER
    } 75%, ${userColorConstants[this.shift.role.colour] + this.OPACITY_HEX_NUMBER} 100%)`;
  }

  calculateRoleName() {
    this.showMinimalInfo = this.cardSizeSettings.value === 'minimal';
    this.trucantedRoleName = this.shift.role.codeName || this.shift.role.name.slice(0, 2);
  }

  onDrag(event: boolean) {
    this.draggingEventEmitter.emit(event);
  }

  public changeSelection(value: boolean) {
    if (this.shift.status !== this.STATUS.DRAFT) {
      return;
    }
    this.setSelectShiftsField(value);
    this.valueSelectedChange.emit(value);
  }

  public onClick(event: any) {
    const isCheckBox = event.target.classList.contains('mat-checkbox-inner-container');
    if (isCheckBox) {
      return;
    }
    this.openReadOnly.emit();
  }
}
