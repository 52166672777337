import { Component, Injector, Input, OnInit } from '@angular/core';
import { getPossibleValuesOfReference } from '@carlos-orgos/orgos-utils/metadata-describer';
import * as _ from 'lodash';

import { environment } from '../../../../../environments/environment';
import { ISelectOption } from '../../../../standard/core/select-option';
import { I18nDataPipe } from '../../../components/i18n-data/i18n-data.pipe';
import { AuthenticationService } from '../../../services/core/authentication.service';
import { InternationalizationService } from '../../../services/core/internationalization.service';
import { PicklistsService } from '../../../services/core/picklists.service';

@Component({
  selector: 'orgos-custom-permission-summary',
  templateUrl: 'custom-permission-summary.component.html',
  styleUrls: ['custom-permission-summary.component.scss']
})
export class CustomPermissionSummaryComponent implements OnInit {
  i18n: any = {};
  userWorkTranslation: any = {};
  mapIdToName: any = {};
  calculatingValues: boolean = false;
  collectionTranslation: any = {};

  @Input() customPermission: any;
  @Input() permissionKey: string;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('settings-roles-and-permissions-page')
      .then((i18n) => {
        this.i18n = _.clone(i18n);
        if (this.permissionKey === 'goal') {
          this.i18n.subordinateEmployees = this.i18n.assignedUsersOfGoal;
          this.injector
            .get(InternationalizationService)
            .getAllTranslation('goal-collection')
            .then((collectionTranslation) => {
              this.collectionTranslation = _.clone(collectionTranslation);
              this.collectionTranslation['type'] = this.i18n.typeOfGoal;
              this.collectionTranslation['departmentId'] = this.i18n.departmentOfGoal;
            })
            .catch(() => {
              this.collectionTranslation = {};
            });
          this.injector
            .get(PicklistsService)
            .getPicklist('goalType')
            .then((goalTypes) => {
              Object.keys(goalTypes).forEach((iGoalTypeKey) => {
                this.mapIdToName[iGoalTypeKey] = goalTypes[iGoalTypeKey];
              });
            })
            .catch((error) => {});
        }
      })
      .catch(() => {
        this.i18n = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-work-collection')
      .then((userWorkTranslation) => {
        this.userWorkTranslation = userWorkTranslation;
      })
      .catch(() => {
        this.userWorkTranslation = {};
      });

    this.calculatingValues = true;
    Promise.all([this.initLookupSelectOption('departmentId'), this.initLookupSelectOption('companyId'), this.initLookupSelectOption('officeId'), this.initLookupSelectOption('teamIds'), this.initLookupSelectOption('areaIds')])
      .then((results) => {
        results.forEach((arraySelectOptions: Array<ISelectOption>) => {
          arraySelectOptions.forEach((iSelectOption: ISelectOption) => {
            this.mapIdToName[iSelectOption.value] = iSelectOption.name;
          });
        });
        this.calculatingValues = false;
      })
      .catch(() => {
        this.mapIdToName = {};
      });
  }

  public getEqualsToLoggedUserField(userField: string): string {
    const translationData = {
      fieldName: this.userWorkTranslation[userField]
    };
    return this.injector.get(I18nDataPipe).transform(this.i18n.loggedUserField, translationData);
  }

  private initLookupSelectOption(fieldName: string): Promise<Array<ISelectOption>> {
    return new Promise((resolve, reject) => {
      getPossibleValuesOfReference(this.injector.get(AuthenticationService).getAuthorizationHeader(), environment.PEOPLE_CLOUD_APP_URL, 'user-work', fieldName)
        .then((selectOptions: Array<ISelectOption>) => {
          resolve(selectOptions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
