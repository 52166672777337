export class ListLimiter {
  private _listLimit: number;
  public get limit() {
    return this._listLimit;
  }
  constructor(private batchSize: number) {
    this._listLimit = batchSize;
  }
  showMore() {
    this._listLimit += this.batchSize;
  }
}