<div class="org-chart-box"></div>
<div class="org-chart-toolbar kenjo-color-grey-background-lighter-f8f8f8 kenjo-border kenjo-border-radius kenjo-text-align-center">
  <div *ngIf="showFindMeButton" class="kenjo-border-bottom kenjo-p-10px kenjo-cursor-pointer" (click)="moveTreeToMe()" [matTooltip]="translation.findMe" matTooltipPosition="before"><kenjo-icon>my_location</kenjo-icon></div>
  <div class="kenjo-border-bottom kenjo-p-10px kenjo-cursor-pointer" [matMenuTriggerFor]="expandCollapse" [matTooltip]="translation.expandCollapse" matTooltipPosition="before"><kenjo-icon>collapse</kenjo-icon></div>
  <div class="kenjo-p-10px kenjo-cursor-pointer" (click)="zoomIn()" [matTooltip]="translation.zoomIn" matTooltipPosition="before"><kenjo-icon>zoom_in</kenjo-icon></div>
  <div class="kenjo-ph-10px kenjo-cursor-pointer" (click)="zoomOut()" [matTooltip]="translation.zoomOut" matTooltipPosition="before"><kenjo-icon>zoom_out</kenjo-icon></div>
  <div class="kenjo-p-10px kenjo-cursor-pointer" (click)="zoomToFit()" [matTooltip]="translation.zoomToFit" matTooltipPosition="before"><kenjo-icon>zoom_to_fit</kenjo-icon></div>
</div>
<div #moreMenuTrigger [matMenuTriggerFor]="moreMenu" class="occ-menu-trigger" [style.top.px]="yPositionMoreMenu" [style.left.px]="xPositionMoreMenu"></div>
<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent let-node="node">
    <button mat-menu-item (click)="editNode.emit(node)">
      {{ translation.editNode }}
    </button>
    <button mat-menu-item (click)="deleteNode.emit(node)">
      {{ translation.deleteNode }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #expandCollapse="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="collapseFromDepth(2)">
      {{ translation.collapseToTwoLevels }}
    </button>
    <button mat-menu-item (click)="expandFullOrgChart()">
      {{ translation.expandFullOrgChart }}
    </button>
  </ng-template>
</mat-menu>
