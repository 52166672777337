import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as check from 'check-types';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { InputValidation } from '../../core/validation/input-validation';
import { InputValidationFunction } from '../../core/validation/input-validation-function';

@Component({
  selector: 'orgos-input-simple-number',
  templateUrl: 'input-simple-number.component.html',
  styleUrls: ['input-simple-number.component.scss']
})
export class InputSimpleNumberComponent implements OnInit, OnDestroy {
  isValueValid: boolean = true;
  private _step: number = 1;
  private newValueDebounced: Subject<any> = new Subject<any>();

  @Input() debounceNewValues: boolean = false;

  @Input()
  set step(step: number) {
    this._step = Math.abs(step);
  }
  get step(): number {
    return this._step;
  }
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() avoidNegativeSymbol: boolean = false;
  @Input() returnValueWithErrors: boolean = false;
  @Input() value: any;
  @Input() max: number;
  @Input() min: number;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() blurInput: EventEmitter<any> = new EventEmitter<any>();
  @Input() customValidation: InputValidationFunction;
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();
  @Input() suffix: string;
  @Input() forceError: boolean = false;
  @Input() changeValueOnBlur: boolean = false;
  @Input() id: string = '';

  @Input() keyboardOnly: boolean = false;
  _allowedKeys = ['ArrowUp', 'ArrowDown', 'Tab'];

  @Input() isDeletionAllowed: boolean = true;

  ngOnInit(): void {
    if (this.isDeletionAllowed) {
      this._allowedKeys = this._allowedKeys.concat(['Delete', 'Backspace']);
    }
    this.makeValidation(this.value);

    this.newValueDebounced.pipe(debounceTime(2000), distinctUntilChanged()).subscribe((newValue: any) => {
      this.value = newValue;

      if (this.isValueValid || this.returnValueWithErrors === true) {
        this.valueChange.emit(newValue);
      }
    });
  }

  onBlurEvent(): void {
    this.blurInput.emit();
  }

  onValueChange(newValue: any): void {
    if (this.debounceNewValues === true) {
      this.makeValidation(newValue);
      this.newValueDebounced.next(newValue);
      return;
    }

    this.value = newValue;
    this.makeValidation(this.value);

    if (this.isValueValid || this.returnValueWithErrors === true) {
      this.valueChange.emit(newValue);
    }
  }

  private makeValidation(valueToValidate: string): void {
    const inputValidation = this.validateValue(valueToValidate).freeze();
    this.isValueValid = this.changeValueOnBlur ? true : inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  private validateValue(newValue: any): InputValidation {
    const inputValidation = new InputValidation();
    const acceptOnlyIntegers = check.integer(this.step);

    let absModulo = 0;
    if (acceptOnlyIntegers === false) {
      const tempAbsModulo = Math.round(newValue * Math.pow(10, this.decimalPlaces(this.step))) % Math.round(this.step * Math.pow(10, this.decimalPlaces(this.step)));
      absModulo = parseFloat(Math.abs(tempAbsModulo).toFixed(this.decimalPlaces(this.step)));
    }

    if (this.required && (check.not.assigned(newValue) || check.emptyString(newValue))) {
      inputValidation.setError('required');
    }

    if (check.assigned(newValue) && check.not.emptyString(newValue) && acceptOnlyIntegers === true && check.not.integer(newValue)) {
      inputValidation.setError('integer');
    } else if (check.assigned(newValue) && check.not.emptyString(newValue) && acceptOnlyIntegers === false && (this.decimalPlaces(newValue) > this.decimalPlaces(this.step) || absModulo > 0)) {
      inputValidation.setError('float');
    }

    if (check.assigned(newValue) && check.not.emptyString(newValue) && newValue < this.min) {
      inputValidation.setError('min');
    } else if (check.assigned(newValue) && check.not.emptyString(newValue) && newValue > this.max) {
      inputValidation.setError('max');
    }

    if (check.assigned(this.customValidation)) {
      const customInputValidation = this.customValidation(newValue);
      Object.keys(customInputValidation.getAllErrors()).map((error) => {
        inputValidation.setError(error);
      });
    }

    return inputValidation;
  }

  private decimalPlaces(newValue: any): number {
    const match = `${newValue}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

    if (check.not.assigned(match)) {
      return 0;
    }

    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
    );
  }

  public checkKey(keyCode: string): boolean {
    if (!this._allowedKeys.includes(keyCode)) {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.newValueDebounced.complete();
  }
}
