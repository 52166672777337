<orgos-container aspect="card" class="lipc-main-card">
  <div class="kenjo-ph-20px lipc-main-content" *ngIf="!processingPayslips && !importingPayslips" cdkScrollable>
    <!-- TITLE ZONE: -->
    <orgos-column-container class="kenjo-mv-20px" centerColumns="true">
      <kenjo-icon orgosColumn="0" [size]="24" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3" (click)="goBack()"> arrow_back </kenjo-icon>
      <orgos-column size="0" class="kenjo-font-size-18px kenjo-ml-20px">{{ i18n.layoutImportPayslips.title }} </orgos-column>
      <orgos-column></orgos-column>
      <!-- WARNING ZONE: -->
      <orgos-warning-message orgosColumn="0" class="lipc-warning-message"> {{ i18n.misc.warningMessage }}</orgos-warning-message>
    </orgos-column-container>

    <!-- DATA ZONE: -->
    <orgos-column-container *ngIf="payslips.length > 0" centerColumns="true" class="kenjo-ph-20px kenjo-mb-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8">
      <orgos-column size="0" class="kenjo-pv-10px">
        <orgos-input-simple-text [label]="i18n.layoutImportPayslips.name" [(value)]="payslipsName" class="lipc-payslip-name-input" [required]="true" (validation)="payslipsNameValidation = $event"> </orgos-input-simple-text>
      </orgos-column>
      <orgos-column size="0" class="kenjo-pv-10px kenjo-pl-20px">
        <kenjo-input-month-picker [maxDate]="maxDate" [label]="i18n.layoutImportPayslips.date" [preferenceDate]="payslipsDate" (selectedDate)="setPayslipDate($event)" [tooltipMessage]="i18n.layoutImportPayslips.date"></kenjo-input-month-picker>
      </orgos-column>
      <orgos-column size="0" class="kenjo-ml-60px kenjo-pl-20px kenjo-border-left lipc-add-tag-container kenjo-pt-30px">
        <orgos-action cdk-overlay-origin (click)="addTag()" icon="add_circle" class="lipc-add-tag kenjo-pv-10px kenjo-ph-20px">
          {{ i18n.layoutImportPayslips.addTag }}
        </orgos-action>
      </orgos-column>
      <orgos-column class="kenjo-ml-20px">
        <orgos-chip *ngFor="let iTagId of payslipsTags" class="kenjo-font-color-white-ffffff kenjo-m-5px" [color]="getTagColor(tagsMap[iTagId].color)" buttonIconName="cancel" [onClickButtonFunction]="deleteTag(iTagId)"> {{ tagsMap[iTagId].name }}</orgos-chip>
      </orgos-column>
    </orgos-column-container>

    <!-- DIGITAL SIGNATURE: -->
    <orgos-column-container *ngIf="digitalSignatureActive && userCanCreateSignatureRequest" class="kenjo-mb-20px lipc-main-checkbox" centerColumns="true">
      <orgos-column
        size="0"
        class="kenjo-p-20px kenjo-border kenjo-border-radius"
        [matTooltip]="allAssignedPayslipsSelected === false && creditsAvailable === 0 ? i18n.layoutImportPayslips.noMoreCredits : i18n.layoutImportPayslips.noUserAssignedTooltip"
        [matTooltipDisabled]="!((allAssignedPayslipsSelected === false && creditsAvailable === 0 && originalCreditsAvailable >= assignedPayslips) || assignedPayslips <= 0 || originalCreditsAvailable <= 0)"
      >
        <orgos-input-simple-checkbox
          [disabled]="(allAssignedPayslipsSelected === false && creditsAvailable === 0 && originalCreditsAvailable > 0) || assignedPayslips <= 0 || originalCreditsAvailable <= 0"
          [(value)]="allAssignedPayslipsSelected"
          [indeterminate]="selectedForSignaturePayslips > 0 && assignedPayslips > selectedForSignaturePayslips"
          (valueChange)="changSelectionInAssignedPayslips()"
          [label]="i18n.layoutImportPayslips.requestDigitalSignature"
        >
        </orgos-input-simple-checkbox>
      </orgos-column>
      <orgos-column class="kenjo-ml-20px">
        {{ i18n.layoutImportPayslips.creditsAvailable | i18nData: { credits: creditsAvailable } }}
      </orgos-column>
    </orgos-column-container>

    <!-- REVIEW ZONE: -->
    <div *ngIf="payslips.length > 0" class="lipc-payslips-container">
      <orgos-payslip-card
        *ngFor="let payslip of payslips"
        [availableCredits]="creditsAvailable"
        [digitalSignatureActive]="digitalSignatureActive && userCanCreateSignatureRequest"
        [i18n]="i18n"
        [payslip]="payslip"
        (payslipChange)="recomputePayslipsAssignments()"
        [usersMap]="usersMap"
        class="kenjo-m-10px"
      >
      </orgos-payslip-card>
    </div>
  </div>

  <!-- FOOTER: -->
  <orgos-column-container *ngIf="!processingPayslips && !importingPayslips" centerColumns="true" class="kenjo-mt-10px kenjo-p-20px lipc-counter-container">
    <orgos-column size="0" class="kenjo-mh-20px kenjo-font-color-light-text-757575">
      {{ i18n.layoutImportPayslips.counter.totalDocuments }}:
      <div>{{ this.totalPayslips }}</div>
    </orgos-column>
    <orgos-column size="0" class="kenjo-mh-20px kenjo-font-color-light-text-757575">
      {{ i18n.layoutImportPayslips.counter.employeesAssigned }}:
      <div>{{ this.uniqueEmployeesAssigned }}</div>
    </orgos-column>
    <orgos-column size="0" class="kenjo-mh-20px kenjo-font-color-light-text-757575" *ngIf="digitalSignatureActive && userCanCreateSignatureRequest">
      {{ i18n.layoutImportPayslips.counter.digitalSignatureRequested }}:
      <div>{{ this.selectedForSignaturePayslips }}</div>
    </orgos-column>
    <orgos-column></orgos-column>
    <orgos-column size="0" class="kenjo-mr-20px" [class.kenjo-font-color-danger-ff5757]="assignedPayslips < totalPayslips">
      {{ i18n.layoutImportPayslips.payslipsAssigned | i18nData: { assignedPayslips: assignedPayslips, totalPayslips: totalPayslips } }}
    </orgos-column>
    <orgos-column
      size="0"
      [matTooltip]="assignedPayslips < totalPayslips ? i18n.layoutImportPayslips.unassignErrorTooltip : i18n.layoutImportPayslips.nameErrorTooltip"
      matTooltipPosition="above"
      [matTooltipDisabled]="!(assignedPayslips < totalPayslips || !payslipsNameValidation || payslipsNameValidation.hasErrors())"
    >
      <orgos-button-raised (click)="openImportAllPayslipsConfirmDialog()" [disabled]="assignedPayslips < totalPayslips || !payslipsNameValidation || payslipsNameValidation.hasErrors()"> {{ i18n.misc.importAllButtonLabel }}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>

  <!-- PROCESSING ZONE: -->
  <div *ngIf="processingPayslips && !importingPayslips" class="lipc-main-card">
    <div class="lipc-progress-container kenjo-text-align-center">
      <div class="kenjo-mt-20px">
        <orgos-loading-spinner></orgos-loading-spinner>
      </div>
      <div class="kenjo-mt-20px">
        {{ i18n.layoutImportPayslips.processingPayslips[processingPayslipsTask] | i18nData: { progress: processingPayslipsProgress } }}
      </div>
    </div>
  </div>
</orgos-container>
