import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CustomFieldService } from '@app/standard/services/custom-field/custom-field.service';
import * as check from 'check-types';

import { ISelectOption } from '../../core/select-option';
import { InputValidation } from '../../core/validation/input-validation';
import { InputAbstractComponent } from '../input-abstract/input-abstract.component';
import { EditCustomFieldDialog } from './dialogs/edit-custom-field.dialog';

@Component({
  selector: 'orgos-input-custom-field',
  templateUrl: 'input-custom-field.component.html',
  styleUrls: ['input-custom-field.component.scss'],
})
export class InputCustomFieldComponent extends InputAbstractComponent implements OnInit {
  pageTranslation: any = {};
  emailValidation: InputValidation;
  listOptions: Array<ISelectOption>;
  private originalCustomField: object;

  @Input() manageCustomField: boolean = false;
  @Output() updateCustomField: EventEmitter<object> = new EventEmitter<object>();
  @Output() deleteCustomField: EventEmitter<object> = new EventEmitter<object>();

  ngOnInit(): void {
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('input-custom-field-component')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    if (this.customFieldDocument && this.customFieldDocument.fieldType === 'List') {
      this.listOptions = this.customFieldDocument.listValues.map((iValue: string) => {
        return { name: iValue, value: iValue };
      });
    }
    this.originalCustomField = Object.assign({}, this.customFieldDocument);
    if (this.customFieldDocument && this.customFieldDocument['listValues']) {
      this.originalCustomField['listValues'] = this.customFieldDocument['listValues'].concat([]);
    }
  }

  editCustomFieldDialog(): void {
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditCustomFieldDialog, { data: this.customFieldDocument });

    dialogRef.afterClosed().subscribe((customFieldUpdated) => {
      if (check.assigned(customFieldUpdated) && check.object(customFieldUpdated) && check.nonEmptyObject(customFieldUpdated)) {
        this.updateCustomField.emit(this.customFieldDocument);
      } else {
        this.customFieldDocument = Object.assign({}, this.originalCustomField);
        if (check.assigned(this.originalCustomField) && this.originalCustomField['listValues']) {
          this.customFieldDocument['listValues'] = this.originalCustomField['listValues'].concat([]);
        }
      }
    });
  }

  deleteCustomFieldDialog(): void {
    const getDeleteCustomFieldDialogTranslations = this.injector
      .get(InternationalizationService)
      .getAllTranslation('delete-custom-field-dialog');
    const getGlobalMiscTranslations = this.injector.get(InternationalizationService).getAllTranslation('misc');

    Promise.all([getDeleteCustomFieldDialogTranslations, getGlobalMiscTranslations])
      .then(([deleteCustomFieldDialogTranslations, globalMiscTranslation]) => {
        const data: any = {
          titleText: `${this.customFieldDocument.fieldLabel}: ${deleteCustomFieldDialogTranslations.dialogHeaderText}`,
          subtitleText: deleteCustomFieldDialogTranslations.warningMessageText,
          confirmButtonText: deleteCustomFieldDialogTranslations.deleteButtonLabel,
          confirmButtonColor: 'Danger',
          cancelButtonText: globalMiscTranslation.goBackButtonDialog,
        };

        const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: data });
        dialogRef.afterClosed().subscribe((customFieldDeleted) => {
          if (customFieldDeleted && customFieldDeleted === true) {
            this.injector.get(GlobalBarService).setProgressBar(true);

            this.injector
              .get(CustomFieldService)
              .deleteById(this.customFieldDocument._id)
              .then((customFieldsOfCollection) => {
                this.deleteCustomField.emit(this.customFieldDocument);
                this.injector.get(GlobalBarService).setProgressBar(false);
              })
              .catch(() => {
                // Error handled by service
              });
          }
        });
      })
      .catch(() => {
        // Do nothing
      });
  }
}
