import { Component, Input } from '@angular/core';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-slide-toggle',
  templateUrl: 'input-slide-toggle.component.html',
  styleUrls: ['input-slide-toggle.component.scss']
})
export class InputSlideToggleComponent extends InputAbstractComponent {
  @Input() labelPosition: 'before' | 'after' = 'before';
  @Input() showLabel: boolean = true;
}
