import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { IUserWorkModel } from '@app/models/user-work.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { StandardComponentsModule } from '@app/standard/components/standard-components.module';
import { CompanyService, ICompanyModel } from '@app/standard/services/company/company.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { IUserAccountModel } from '@app/standard/services/user/user-account.service';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { IconHighlightComponent } from '@app/standard/standalone-components/icon-highlight/icon-highlight.component';

@Component({
  templateUrl: 'subscription-expired.component.html',
  styleUrls: ['subscription-expired.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, StandardComponentsModule, IconModule, IconHighlightComponent],
})
export class SubscriptionExpiredPaywallDialog implements OnInit {
  private BILLING_URL = 'settings/billing/info';
  private BOOK_DEMO_URL_EN = 'https://www.kenjo.io/book-a-demo';
  private BOOK_DEMO_URL_DE = 'https://www.kenjo.io/de/demo-buchen';
  private BOOK_DEMO_URL_ES = 'https://www.kenjo.io/es/agendar-demo';

  pageTranslations: Record<string, string | Record<string, string>> = {};
  disableButtons: boolean = false;
  mode: 'admin' | 'non-admin';

  constructor(public dialogRef: MatLegacyDialogRef<SubscriptionExpiredPaywallDialog>, private injector: Injector) {}

  ngOnInit(): void {
    this.blockBackButton();
    this.getTranslations();
    this.initMode();
  }

  private blockBackButton(): void {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
  }

  private async getTranslations(): Promise<void> {
    this.pageTranslations = await this.injector.get(InternationalizationService).getAllTranslation('feature-gating');
  }

  private initMode(): void {
    const profileKey = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    if (profileKey === 'admin' || profileKey === 'hr-admin') {
      this.mode = 'admin';
    } else {
      this.mode = 'non-admin';
      this.dialogRef.disableClose = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  async bookDemo(): Promise<void> {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('Demo intent at free trial expiry gate', { category: 'Free trial', subcategory1: 'Gating' });

    if (this.injector.get(CloudRoutesService).checkRoute(this.BILLING_URL) === false) {
      return;
    }

    this.disableButtons = true;
    const bookDemoUrl: string = await this.getBookDemoUrlWithParams();
    window.open(bookDemoUrl, '_blank');
    this.disableButtons = false;
  }

  private async getBookDemoUrlWithParams(): Promise<string> {
    const language: string = this.injector.get(AuthenticationService).getLoggedUser().language;
    const initialUrl: string = this.getInitialBookDemoUrl(language);
    const urlParams: string = await this.getBookDemoUrlParams(language);
    return `${initialUrl}?${urlParams}`;
  }

  private getInitialBookDemoUrl(language: string): string {
    if (language === 'de') {
      return this.BOOK_DEMO_URL_DE;
    } else if (language === 'es') {
      return this.BOOK_DEMO_URL_ES;
    }
    return this.BOOK_DEMO_URL_EN;
  }

  private async getBookDemoUrlParams(language: string): Promise<string> {
    const urlParams = new URLSearchParams();
    const userId: string = this.injector.get(AuthenticationService).getLoggedUser()._id;
    const userPersonal: IUserPersonalModel = await this.injector.get(UserPersonalService).getById(userId);
    const userAccount: IUserAccountModel = await this.injector.get(UserAccountService).getById(userId);
    const userWork: IUserWorkModel = await this.injector.get(UserWorkService).getById(userId);
    const companyId = userWork.companyId;
    const company: ICompanyModel = await this.injector.get(CompanyService).getById(companyId);
    urlParams.append('firstname', userPersonal.firstName);
    urlParams.append('lastname', !userPersonal.lastName || !userPersonal.lastName.trim() ? userPersonal.firstName : userPersonal.lastName);
    urlParams.append('email', userAccount.email);
    urlParams.append('name', company.name);
    urlParams.append('country', language === 'es' ? 'Spain' : 'Germany');
    urlParams.append('utm_source', 'app');
    urlParams.append('utm_medium', 'postfreetrialgate');
    urlParams.append('utm_campaign', 'democta');
    return urlParams.toString();
  }

  choosePlan(): void {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('Plan check intent at free trial expiry gate', { category: 'Free trial', subcategory1: 'Gating' });
    this.closeDialog();
  }

  async signOut(): Promise<void> {
    await this.injector.get(AuthenticationService).signOut();
    this.closeDialog();
  }
}
