import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericCacheModel } from '@app/standard/core/generic-cache-model';
import { InputValidation } from '@app/standard/core/validation/input-validation';

@Component({
  selector: 'kenjo-add-employee-personal-step',
  templateUrl: 'personal-step.component.html',
  styleUrls: ['personal-step.component.scss']
})
export class AddEmployeePersonalStepComponent {
  @Input() translations: any = {};
  @Input() userPersonal: GenericCacheModel;

  @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();

  firstNameValidation: InputValidation;
  lastNameValidation: InputValidation;
  birthdateValidation: InputValidation;

  todayDate: Date = new Date();

  onNextStep() {
    this.nextStep.emit(true);
  }
}
