import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { CommentEditorComponent } from '@app/standard/components/comment-editor/comment-editor.component';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { ListLimiter } from '@app/standard/components/limit-list-pipe/list-limiter.model';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { CandidateService } from '@app/standard/services/recruiting/candidate.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-recruiting-discussion-comments',
  templateUrl: 'recruiting-discussion-comments.component.html',
  styleUrls: ['recruiting-discussion-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecruitingDiscussionCommentsComponent implements OnInit {
  i18n: any = {};

  @ViewChildren(CommentEditorComponent) discussionComments: QueryList<CommentEditorComponent>;

  @Input() allowEditComments: boolean = false;
  @Input() candidateId: any = {};
  @Input() positionId: string;
  _allActivity: Array<IRecruitingActivity>;
  @Input() limitList?: boolean;

  @Output() commentSaved: EventEmitter<void> = new EventEmitter<void>();

  currentUserId: string;
  listLimiter: ListLimiter;

  @Input() set allActivity(value: Array<IRecruitingActivity>) {
    this._allActivity = value;
    this.listLimiter = new ListLimiter(this.limitList ? 10 : this.allActivity.length);
  }

  get allActivity() {
    return this._allActivity;
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    this.initTranslationsAndData();
  }

  async initTranslationsAndData() {
    this.i18n.activity = await this.injector.get(InternationalizationService).getAllTranslation('recruiting-activity-widget');
  }

  trackActivities(index: number, activity: IRecruitingActivity): number {
    return activity.date.valueOf();
  }

  async saveComment(updatedComment: any, commentId: string, isPrivate: boolean): Promise<void> {
    try {
      await this.injector.get(CandidateService).updateComment(this.candidateId, commentId, updatedComment, this.positionId, isPrivate);
      this.commentSaved.emit();

      this.injector.get(MatLegacySnackBar).open(this.i18n.activity.commentSavedMessage, 'OK', {
        duration: 5000,
      });
    } catch (error) {
      // Do nothing
    }
  }

  private async getCandidateModelUpdated(): Promise<GenericSimpleModel | void> {
    try {
      const candidate = await this.injector.get(CandidateService).getById(this.candidateId);

      if (
        check.not.assigned(candidate) &&
        check.not.assigned(candidate._id) &&
        check.not.string(candidate._id) &&
        check.emptyString(candidate._id)
      ) {
        return;
      }

      const candidateModel = new GenericSimpleModel(this.injector, candidate, CandidateService, '');

      return candidateModel;
    } catch (error) {
      // Do nothing
    }
  }

  public goToCandidate(candidateId: string, positionId?: string): void {
    if (check.assigned(positionId)) {
      this.injector.get(Router).navigateByUrl(`cloud/recruiting/candidate/${candidateId}/${positionId}`);
      return;
    }
    this.injector.get(Router).navigateByUrl(`cloud/recruiting/candidate/${candidateId}`);
  }

  public editComment(index: number) {
    this.discussionComments.toArray()[index]?.showEditButtons();
  }

  public deleteComment(commentId: string) {
    const data = {
      titleText: this.i18n.activity.deleteDiscussionCommentDialogTitle,
      subtitleText: this.i18n.activity.deleteDiscussionCommentDialogSubtitle,
      confirmButtonText: this.i18n.activity.deleteDiscussionCommentConfirmButton,
      confirmButtonColor: 'Danger',
      cancelButtonText: this.i18n.activity.discussionCommentDialogCancelButton,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe(async (confirm) => {
      if (confirm && confirm === true) {
        try {
          const candidateModel = await this.getCandidateModelUpdated();

          if (typeof candidateModel === 'undefined') {
            return;
          }

          const updatedComments = candidateModel.data.comments.filter((comment) => {
            return comment._id !== commentId;
          });

          candidateModel.data.comments = updatedComments;
          await candidateModel.update();

          this.commentSaved.emit();

          this.injector.get(MatLegacySnackBar).open(this.i18n.activity.commentDeletedMessage, 'OK', {
            duration: 5000,
          });
        } catch (error) {
          // Do nothing
        }
      }
    });
  }
}

export interface IRecruitingActivity {
  _id?: string;
  comment?: any;
  _createdById?: string;
  _candidateId?: string;
  _positionId?: string;
  icon: string;
  date: moment.Moment;
  mainInfo: string;
  mainInfoTranslationKey?: string;
  mainInfoTranslationData?: any;
  additionalInfoTitle?: string;
  additionalInfoText?: string;
  additionalInfoHtml?: string;
  iconColor?: string;
  isPrivate?: boolean;
  agency?: string;
}
