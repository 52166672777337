<orgos-dialog-container [title]="i18n.dialog.dialogTitle" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-radio picklist="orgChartElementDeletionOptions" [label]="i18n.dialog.deletionOptionsLabel" [required]="true" [(value)]="selectedDeletionOption"></orgos-input-simple-radio>

  <!-- BUTTONS -->
  <orgos-column-container class="kenjo-mt-40px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Neutral" (click)="closeDialog()">{{ i18n.misc.goBackButtonDialog }}</orgos-button-raised>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px">
      <orgos-button-raised color="Danger" (click)="deleteNode()" [disabled]="!selectedDeletionOption">
        {{ i18n.dialog.deleteButton }}
      </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
