<div class="sdd-main-dialog-box kenjo-m-40px" *ngIf="surchargeSummary">
  <div class="sdd-top-icons">
    <div class="sdd-top-owner-container">
      <orgos-user-item
      [title]="surchargeSummary.userPersonal?.displayName"
      [photoUrl]="surchargeSummary.userPersonal?._photo?._url"
      [subtitle]="translations.surchargeDetailPopupSummaryTitle"
      size="small">
      </orgos-user-item>
      <div class="sdd-learn-more-container kenjo-ml-5px">
        <a [href]="translations.surchargeDetailPopupLearnMoreUrl" target="_blank">{{ translations.surchargeDetailPopupLearnMoreText }}</a>
      </div>
    </div>
    <kenjo-icon [size]="24" class="sdd-close-button kenjo-cursor-pointer kenjo-ml-40px kenjo-font-color-light-text-757575" (click)="closeDialog()">clear</kenjo-icon>
  </div>
  <div class="kenjo-mt-20px kenjo-ph-10px kenjo-pv-5px sdd-flex-container">
    <div class="sdd-flex-container sdd-grow-one kenjo-font-size-16px kenjo-font-weight-bold">
      <div>
        {{ translations.surchargeDetailPopupHourlyRateApplied }}&nbsp;
        <span *ngFor="let salaryApplied of surchargeSummary.salariesApplied; let first = first;">
          <ng-container *ngIf="!first">
            &nbsp;/&nbsp;
          </ng-container>
          {{ salaryApplied.surchargeHourlyRate }}{{ surchargeSummary.currencySymbol }}
        </span>
      </div>
      <div class="kenjo-ml-5px">
        <kenjo-icon *ngIf="surchargeSummary.salariesApplied?.length > 1"
          [matTooltip]="translations.surchargeDetailPopupMultipleSalariesInfoTooltip"
          [size]="16"
          class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer">info_outline</kenjo-icon>
      </div>
    </div>
    <div *ngIf="canSeeUserAttendance" class="kenjo-font-size-12px">
      <a (click)="openAttendance()" class="sdd-flex-container">
        <div>
          {{ translations.surchargeDetailPopupReviewTrackedTime }}
        </div>
        <div>
          <kenjo-icon size="16" class="kenjo-ml-5px">launch</kenjo-icon>
        </div>
      </a>
    </div>
  </div>

  <ng-container *ngIf="surchargeSummary.surchargePayments">
    <!-- SURCHARGE PAYMENT DETAIL -->
    <div *ngFor="let surchargePayment of surchargeSummary.surchargePayments" class="sdd-hover-background">
      <div
        class="sdd-surcharge-payment-box kenjo-mt-10px kenjo-pv-20px kenjo-ph-10px kenjo-cursor-pointer"
        (click)="surchargePayment.showAttendances = !surchargePayment.showAttendances">
        <div class="sdd-expand-icon-container kenjo-ph-5px">
          <kenjo-icon [@rotateIcon]="surchargePayment.showAttendances" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3">keyboard_arrow_down</kenjo-icon>
        </div>
        <div class="sdd-flex-one kenjo-ml-10px">
          <div class="sdd-flex-container">
            <div class="sdd-left-container">
              <div class="sdd-rule-title-container kenjo-font-size-16px">
                {{ surchargePayment.surchargeRule.name }}
              </div>
              <div class="kenjo-mt-10px">
                <span class="kenjo-font-size-12px kenjo-font-color-light-text-757575">{{ translations.surchargeDetailPopupFactor }}</span>
                <span class="kenjo-ml-5px kenjo-font-size-12px">{{ surchargePayment.surchargeRule.extraRatePercentage }}%</span>
              </div>
            </div>
            <div class="sdd-right-container sdd-flex-container">
              <div class="sdd-grow-one">
                <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">
                  {{ translations.surchargeDetailPopupTrackedTime }}
                </div>
                <div class="kenjo-font-size-14px kenjo-mt-10px">
                  {{ surchargePayment.surchargeTime.hours }}h&nbsp;{{ surchargePayment.surchargeTime.minutes }}min
                </div>
              </div>
              <div class="sdd-grow-one">
                <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">
                  {{ translations.surchargeDetailPopupAdditionalRate }}
                </div>
                <div class="kenjo-font-size-14px kenjo-mt-10px">
                  <span *ngFor="let rateApplied of surchargePayment.additionalRatesPerHour; let first = first;">
                    <ng-container *ngIf="!first">
                      &nbsp;/&nbsp;
                    </ng-container>
                    +{{ rateApplied }}{{ surchargeSummary.currencySymbol }}
                  </span>
                </div>
              </div>
              <div class="sdd-grow-one">
                <div class="kenjo-font-size-12px kenjo-font-color-light-text-757575">
                  {{ translations.surchargeDetailPopupAdditionalPay }}
                </div>
                <div class="kenjo-font-size-14px kenjo-mt-10px kenjo-font-weight-bold">
                  {{ surchargePayment.additionalPayment }}{{ surchargeSummary.currencySymbol }}
                </div>
              </div>
            </div>
          </div>

          <!-- USER ATTENDANCE DETAILS -->
          <div
            *ngIf="surchargePayment.showAttendances"
            [@fadeInOut]="surchargePayment.showAttendances ? 'active' : 'inactive'"
            class="sdd-attendances-container">
            <div class="sdd-dashed-line kenjo-mt-20px kenjo-mb-20px kenjo-mr-30px"></div>
            <div *ngFor="let surchargeAttendance of surchargePayment.surchargeAttendances" class="kenjo-mt-10px sdd-flex-container">
              <div class="sdd-left-container kenjo-font-size-12px">
                {{ surchargeAttendance.userAttendance.date | date: 'shortDate' }}
              </div>
              <div class="sdd-right-container sdd-flex-container kenjo-font-size-14x">
                <div class="sdd-grow-one">
                  {{ surchargeAttendance.surchargeTime.hours }}h&nbsp;{{ surchargeAttendance.surchargeTime.minutes }}min
                </div>
                <div class="sdd-grow-one kenjo-font-size-14x">
                  +{{ surchargeAttendance.additionalRatePerHour }}{{ surchargeSummary.currencySymbol }}
                </div>
                <div class="sdd-grow-one kenjo-font-weight-bold">
                  {{ surchargeAttendance.additionalPayment }}{{ surchargeSummary.currencySymbol }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
</div>
