import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PrivateSignInService } from '@app/private/services/private-sign-in.service';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class SignInWithMicrosoftGuard implements CanActivate {
  ORIGIN_URL_KEY = 'originalUrl';

  constructor(private injector: Injector) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const codeToken = route.queryParamMap.get('code');
      const redirectUri = route.queryParamMap.get('state');

      if (check.not.assigned(codeToken) || check.not.assigned(redirectUri)) {
        this.injector.get(Router).navigate(['/signin']);
        return false;
      }

      const originTxt = localStorage.getItem(this.ORIGIN_URL_KEY);

      if (check.assigned(originTxt)) {
        localStorage.removeItem(this.ORIGIN_URL_KEY);
        const origin = JSON.parse(originTxt);
        origin.isSSO = true;
        localStorage.setItem(this.ORIGIN_URL_KEY, JSON.stringify(origin));
      }

      await this.injector.get(PrivateSignInService).signInWithMicrosoft(codeToken, redirectUri);
      this.injector.get(Router).navigate(['/cloud']);
      return false;
    } catch {
      // An error is already shown

      this.injector.get(Router).navigate(['/signin']);
      return false;
    }
  }
}
