import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { IFilterOption } from '@app/standard/components/filter-bar/filter-bar.component';
import { ISelectOption } from '@app/standard/core/select-option';
import { IUploadDocumentConfig, UploadDocumentDialog } from '@app/standard/pages/documents/dialogs/upload-document.dialog';
import { GenericFilterPage } from '@app/standard/pages/generic-filter.page';
import { ITranslationResource } from '@app/standard/pages/generic.page';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { UppyHelperService } from '@app/standard/services/core/uppy-helper.service';
import { SmartDocsService } from '@app/standard/services/data-engine/smart-docs.service';
import { DocumentTagService, IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import { DocumentService, IDocumentModel } from '@app/standard/services/document/document.service';
import { FilterBarController } from '@app/standard/services/filter-bar/filter-bar.service';
import { SmartDocsBarService } from '@app/standard/services/navigation-tabs/smart-docs-bar.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import * as picklists from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-documents-my-docs',
  templateUrl: 'documents-my-docs.page.html',
  styleUrls: ['documents-my-docs.page.scss'],
})
export class DocumentsMyDocsPage extends GenericFilterPage {
  profilePermissions: any;
  unlabelledTagOption: ISelectOption;

  PREFERENCE_OPTION_KEY: string = 'my-docs-options';
  PREFERENCE_VIEW: string = 'view-my-docs-list';
  DEFAULT_SORT_BY: string = 'document._createdAt';
  DEFAULT_SORT_ORDER: string = 'desc';
  MAX_DOC_SIZE: number = 50;
  sortBy: string = this.DEFAULT_SORT_BY;
  sortOrder: string = this.DEFAULT_SORT_ORDER;
  unlabelledTagId: string = picklists.DOCUMENT_UNLABELLED_ID;
  MAX_RECORDS_PER_PAGE: number = 1000;
  isAnyUnlabelledDocument: boolean = false;
  unlabelledFilter: boolean = false;

  myDocuments: Array<IDocumentModel> = [];
  documentTags: Array<IDocumentTagModel> = [];
  totalColumns: Array<string> = ['document.name', 'document._createdAt', 'document.validUntil', 'tags', 'digitalSigned'];
  protected translationResources: Array<ITranslationResource> = [
    { name: 'menu', translationKey: 'documents-misc' },
    { name: 'page', translationKey: 'documents-my-docs-page' },
  ];
  protected profilePermissionsResources: Array<string> = ['document', 'e-signature'];
  protected documentTagById: any = {};
  protected USER_COLORS: any = {};
  protected fieldToQueryOptionMap = {
    tags: 'tags',
    fileTypes: '_file._fileExtension',
  };

  protected async getGlobalBarOptions(): Promise<any> {
    return await this.injector.get(SmartDocsBarService).getOptions(this.i18n.menu);
  }

  protected async getPreferences(): Promise<void> {
    this.preferences = await this.injector.get(PreferenceService).getPreferenceByKey(this.PREFERENCE_OPTION_KEY);
  }

  protected async getFilters(): Promise<void> {
    const pageFilters = await this.injector.get(FilterBarController).getFiltersByKey('my-docs');

    this.filters = pageFilters.primary;
    this.unlabelledTagOption = this.filters.tags.find((iOption) => iOption.value === this.unlabelledTagId);
  }

  protected async fetchRelatedData(): Promise<void> {
    try {
      const documentTags = await this.injector.get(DocumentTagService).getDocumentTags();
      this.documentTags = _.orderBy(documentTags, [(iTag) => iTag.name.toLowerCase()], ['asc']);

      this.loadUserColors();
      this.getDocumentTagMapById();
    } catch {
      this.documentTags = [];
    }
  }

  protected async afterInit(): Promise<void> {
    this.injector.get(PrivateAmplitudeService).logEvent('view smart docs page', { category: 'Navigation', type: 'my docs' });
    return Promise.resolve();
  }

  public async uploadDocument(): Promise<void> {
    const uploadConfig: IUploadDocumentConfig = {
      relatedTo: {
        typeRelatedTo: 'User',
        idRelatedTo: this.injector.get(AuthenticationService).getLoggedUser()._id,
      },
      viewableByUser: true,
    };

    const rawDocument = await this.injector.get(UppyHelperService).uploadGenericDocument({ maxDocSize: this.MAX_DOC_SIZE });

    if (check.not.assigned(rawDocument)) {
      return;
    }

    const dialogData = {
      rawDocument,
      uploadConfig,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(UploadDocumentDialog, { data: dialogData });
    dialogRef.afterClosed().subscribe(async (uploadedDocument: IDocumentModel) => {
      if (check.not.assigned(uploadedDocument)) {
        return;
      }

      this.clearAllFilters();
      await this.refreshData();
    });
  }

  protected setSpecificDefaultValuesForPage(): void {
    if (check.not.assigned(this.preferences?.preference)) {
      this.queryOptions.document = {
        sortBy: this.DEFAULT_SORT_BY.split('.')[1],
        sortOrder: this.DEFAULT_SORT_ORDER,
      };
    }
  }

  protected async fetchListData(): Promise<void> {
    try {
      const { pages, totalOfRecords, records } = await this.injector.get(SmartDocsService).getListDataMyDocs(this.queryOptions);

      this.paginationConfiguration.numberOfPages = pages;
      this.paginationConfiguration.totalOfRecords = totalOfRecords;
      this.myDocuments = this.processDocumentsToPrint(records);
    } catch {
      this.myDocuments = [];
    }
  }

  protected async fetchGridData(): Promise<void> {
    try {
      const records = await this.injector.get(DocumentService).getMyDocs(this.preferences.preferenceKey, this.queryOptions);
      const myDocuments = this.processDocumentsToPrint(records);
      this.myDocuments = check.assigned(myDocuments) ? this.sortGridDocuments(myDocuments) : [];
    } catch {
      this.myDocuments = [];
    }
  }
  sortGridDocuments(myDocuments: Array<IDocumentModel>) {
    if (this.sortBy === 'document.validUntil') {
      return myDocuments.sort((a, b) => {
        if (check.not.assigned(a.validUntil) && check.not.assigned(b.validUntil)) {
          return 0;
        } else if (check.not.assigned(a.validUntil)) {
          return this.sortOrder === 'desc' ? 1 : -1;
        } else if (check.not.assigned(b.validUntil)) {
          return this.sortOrder === 'desc' ? -1 : 1;
        } else if (this.sortOrder === 'desc') {
          return moment(a.validUntil).isBefore(b.validUntil) ? 1 : -1;
        } else {
          return moment(a.validUntil).isAfter(b.validUntil) ? 1 : -1;
        }
      });
    } else if (this.sortBy === 'document._createdAt') {
      myDocuments.sort((a, b) => (moment(a._createdAt).isAfter(b._createdAt) ? 1 : -1));
      return this.sortOrder === 'desc' ? myDocuments.reverse() : myDocuments;
    } else if (this.sortBy === 'document.name') {
      myDocuments.sort((a, b) => a.name.localeCompare(b.name));
      return this.sortOrder === 'desc' ? myDocuments.reverse() : myDocuments;
    } else {
      return myDocuments.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  private processDocumentsToPrint(documentsToProcess: Array<IDocumentModel>): Array<IDocumentModel> {
    const documentsOnProcess = this.processCollapsedTags(documentsToProcess);
    const processedDocuments = this.processSignatureStatus(documentsOnProcess);

    return processedDocuments;
  }

  private loadUserColors(): void {
    this.USER_COLORS = Object.keys(userColorConstants).reduce((result, iColorKey) => {
      result[iColorKey] = userColorConstants[iColorKey];
      return result;
    }, {});
  }

  protected addFilter(value: string, field: string): void {
    let queryCriteria = field === 'tags' ? '$all' : '$in';

    if (check.not.assigned(this.queryOptions.document?.where)) {
      this.queryOptions.document = {
        where: {},
      };
    }

    const queryPath = this.queryOptions.document.where;

    if (check.assigned(queryPath[field]?.$exists)) {
      queryPath[field][queryCriteria] = [this.unlabelledTagId, value];
      delete queryPath[field].$exists;
      delete queryPath[field].$size;
      return;
    }

    if (check.not.assigned(queryPath[field])) {
      queryPath[field] = {};
      queryPath[field][queryCriteria] = [];
    }

    if (queryPath[field][queryCriteria].indexOf(value) !== -1) {
      return;
    }

    if (value !== this.unlabelledTagId) {
      queryPath[field][queryCriteria].push(value);
      return;
    }

    if (queryPath[field][queryCriteria].length === 0) {
      queryPath[field] = { $exists: true, $size: 0 };
      return;
    }

    queryPath[field][queryCriteria].push(value);
  }

  protected removeFilter(value: string, field: string): void {
    const queryCriteria = field === 'tags' ? '$all' : '$in';

    if (value === this.unlabelledTagId && check.assigned(this.queryOptions.document.where[field].$exists)) {
      delete this.queryOptions.document.where[field];
      return;
    }

    const queryPath = this.queryOptions.document.where;

    if (check.not.assigned(queryPath[field][queryCriteria]) || check.emptyArray(queryPath[field][queryCriteria])) {
      delete this.queryOptions.document.where[field];
      return;
    }

    if (queryPath[field][queryCriteria].length > 1) {
      this.queryOptions.document.where[field][queryCriteria] = queryPath[field][queryCriteria].filter((fieldValue) => fieldValue !== value);

      if (queryPath[field][queryCriteria].length === 1 && queryPath[field][queryCriteria][0] === this.unlabelledTagId) {
        this.queryOptions.document.where[field] = { $exists: true, $size: 0 };
      }

      return;
    }

    delete this.queryOptions.document.where[field];
  }

  private getDocumentTagMapById(): void {
    this.documentTagById = this.documentTags.reduce((result, tag) => {
      result[tag._id] = tag;
      return result;
    }, {});
  }

  private processCollapsedTags(documents: Array<IDocumentModel>): Array<IDocumentModel> {
    return documents.map((iDoc) => {
      if (check.not.assigned(iDoc.collapsedTagIds) || check.emptyArray(iDoc.collapsedTagIds)) {
        return iDoc;
      }

      iDoc.processedTooltip = iDoc.collapsedTagIds.map((iTag) =>
        check.assigned(this.documentTagById[iTag]) ? this.documentTagById[iTag].name : ''
      );
      return iDoc;
    });
  }

  private processSignatureStatus(documents: Array<any>): Array<any> {
    return documents.map((iDoc) => {
      if (iDoc.digitalSigned) {
        iDoc.status = 'Signed';
      } else if (iDoc.pending) {
        iDoc.status = 'Pending';
      }

      return iDoc;
    });
  }

  protected getCurrentQueryValues(): IDocumentsFilterOptions {
    const selectedValues = { tags: [], fileTypes: [] };

    if (this.queryOptions.document?.where?.[this.fieldToQueryOptionMap['tags']]?.$exists) {
      selectedValues.tags = [this.unlabelledTagId];
    } else {
      selectedValues.tags = this.queryOptions.document?.where?.[this.fieldToQueryOptionMap['tags']]?.$all ?? [];
    }

    selectedValues.fileTypes = this.queryOptions.document?.where?.[this.fieldToQueryOptionMap['fileTypes']]?.$in ?? [];

    return selectedValues;
  }

  public async clearFilter(field): Promise<void> {
    this.resetView();

    const fieldName = this.fieldToQueryOptionMap[field];
    const model = 'document';

    if (check.assigned(this.queryOptions[model].where?.[fieldName])) {
      delete this.queryOptions[model].where[fieldName];
    }

    this.refreshAfterResettingPagination();
  }

  public async clearCustomFilters(): Promise<void> {
    if (check.assigned(this.queryOptions.document?.where)) {
      this.queryOptions.document.where = {};
    }
  }

  public goToSignaturePage(signatureRequestId) {
    this.router.navigateByUrl(
      `/cloud/documents/sign/${this.injector.get(AuthenticationService).getLoggedUser()._id}/${signatureRequestId}`
    );
  }

  public openDocumentDetail(documentId: string): void {
    this.router.navigateByUrl(`cloud/documents/${documentId}`);
  }
}

export interface IDocumentsFilterOptions {
  tags?: Array<IFilterOption>;
  fileTypes?: Array<IFilterOption>;
}
