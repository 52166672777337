import { IUserWorkScheduleModel } from '@app/standard/services/user/user-work-schedule.service';

export const mapUserAttendanceTracking = (userWorkSchedules: Array<IUserWorkScheduleModel>) => {
  if (!userWorkSchedules) {
    return {};
  }
  return userWorkSchedules.reduce((acc, curr) => {
    return { ...acc, [curr._id]: curr.trackAttendance };
  }, {});
};

export const mapUserOvertimeTracking = (userWorkSchedules: Array<IUserWorkScheduleModel>) => {
  if (!userWorkSchedules) {
    return {};
  }
  return userWorkSchedules.reduce((acc, curr) => {
    return { ...acc, [curr._id]: curr.overtimeSettings?.trackOvertime };
  }, {});
};
