import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { InputPayslipSearchUserComponent } from '@app/common-components/layout-import-payslips/components/input-payslip-search-user/input-payslip-search-user.component';
import { PayslipCardComponent } from '@app/common-components/layout-import-payslips/components/payslip-card/payslip-card.component';
import { TagsPicklistComponent } from '@app/common-components/layout-import-payslips/components/tags-picklist/tags-picklist.component';
import { LayoutImportPayslipsComponent } from '@app/common-components/layout-import-payslips/main-component/layout-import-payslips.component';
import { PdfRenderModule } from '@app/common-components/pdf-render/pdf-render.module';
import { SendSignatureConfirmDialogComponent } from '@app/common-components/send-signature-confirm-dialog/send-signature-confirm-dialog.component';
import { WarningMessageModule } from '@app/common-components/warning-message/warning-message.module';

export const EXTERNAL_MODULES = [WarningMessageModule, IconModule, PdfRenderModule];

export const COMPONENTS = [LayoutImportPayslipsComponent, PayslipCardComponent];

export const DIALOGS = [SendSignatureConfirmDialogComponent, InputPayslipSearchUserComponent, TagsPicklistComponent];

export const PROVIDERS = [];
