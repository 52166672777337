<orgos-text class="iec-label" [color]="focused ? 'Primary' : 'LightText'">{{ label }}{{ required ? ' *' : '' }}</orgos-text>
<orgos-container [class]="quillSizeClass">
  <quill-editor
    *ngIf="model && field && !readOnly"
    [modules]="modules"
    [customOptions]="customOptions"
    [style]="style"
    [required]="required"
    [minLength]="minlength"
    [maxLength]="maxlength"
    placeholder=""
    (onEditorCreated)="onEditorCreated($event)"
    (onContentChanged)="onContentChanged($event)"
    class="orgos-quill-editor"
  >
  </quill-editor>
  <quill-editor *ngIf="model && field && readOnly" [modules]="{ toolbar: false }" [customOptions]="customOptions" [style]="style" [required]="required" placeholder="" [readOnly]="true" (onEditorCreated)="onEditorCreated($event)" class="orgos-quill-editor"> </quill-editor>
</orgos-container>
<div [class.iec-quill-container-no-focus]="!focused" [class.iec-quill-container-focus]="focused"></div>
<div class="iec-hint">
  <div *ngIf="!isValueValid" class="iec-error"><ng-content select="orgos-input-error"></ng-content></div>
  <orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
</div>
