import { ContentChild, Directive, EventEmitter, Input, Output } from '@angular/core';

import { TableHeatmapCellDirective } from './table-heatmap-cell.directive';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'orgos-table-heatmap-column'
})
export class TableHeatmapColumnDirective {
  @Input() columnKey: string = '';
  @Input() header: string = '';
  @Input() subHeader: string = '';
  @Input() info: string = '';
  @Input() sortable: boolean = false;
  @Input() tooltip: string = '';
  @Input() showTooltip: boolean = true;
  @Input() centerContent: boolean = false;
  @Input() fullCell: boolean = false;

  @ContentChild(TableHeatmapCellDirective) tableCell: TableHeatmapCellDirective;
}
