<div overlay class="sid-overlay-container kenjo-border" [style.width]="width">
  <div
    class="kenjo-color-white-background-ffffff kenjo-border-radius-4px kenjo-pt-20px kenjo-pb-10px kenjo-ph-20px"
    (click)="onMenuClick($event)"
  >
    <div *ngIf="term" class="sid-search-active">{{ placeholder }}</div>
    <div class="sid-overlay-header kenjo-pb-5px">
      <input
        #inputSearch
        class="kenjo-cursor-text"
        [ngStyle]="{ 'border-color': term ? '#5993e3' : '#8f8f8f' }"
        matInput
        [ngModel]="''"
        (ngModelChange)="searchByName($event)"
        (keydown.arrowDown)="navigateOptions('down')"
        (keydown.arrowUp)="navigateOptions('up')"
        (keydown.enter)="keyEnter()"
        autocomplete="off"
        [placeholder]="placeholder"
      />
      <kenjo-icon
        [size]="24"
        [ngStyle]="{ 'border-color': term ? '#5993e3' : '#8f8f8f' }"
        class="sid-search-icon kenjo-mr-10px kenjo-font-color-archived-8f8f8f"
        >search</kenjo-icon
      >
    </div>
  </div>
  <div class="sid-options" [ngStyle]="{ 'overflow-y': filteredOptions.length < MAX_LENGTH_OPTIONS ? 'hidden' : '' }" #optionsContainer>
    <ng-container *ngIf="filteredOptions.length > 0">
      <div
        *ngFor="let option of filteredOptions; last as isLast; first as isFirst"
        (click)="emitSelection(option); emitSelectedOption(option)"
        [class.sid-active-option]="activeOptionValue && option.value === activeOptionValue"
        [class.sid-last]="isLast && !actionText"
        [class.kenjo-border-bottom]="!componentKey === 'policy-item'"
        class="kenjo-color-white-background-ffffff kenjo-cursor-pointer sid-option-container"
      >
        <!-- Items must be inside this div to scroll properly-->
        <div #optionItem>
          <kenjo-select-item-attendance-policy
            *ngIf="componentKey === 'policy-item'"
            [option]="option"
          ></kenjo-select-item-attendance-policy>
          <kenjo-select-item-category *ngIf="componentKey === 'category-item'" [option]="option"></kenjo-select-item-category>
        </div>
      </div>
    </ng-container>
    <div
      *ngIf="filteredOptions.length === 0"
      [class.sid-last]="!actionText"
      class="sid-empty-item kenjo-color-white-background-ffffff kenjo-font-size-14px"
    >
      {{ emptyOptionsText }}
    </div>
    <div
      *ngIf="actionText"
      (click)="emitAction()"
      class="
        sid-selectable-item sid-last
        kenjo-font-color-primary-5993e3 kenjo-cursor-pointer kenjo-font-weight-bold kenjo-color-white-background-ffffff kenjo-border-top
      "
    >
      {{ actionText }}
    </div>
  </div>
</div>
