<ng-container *ngIf="!loadingPage">
  <div class="kenjo-text-align-right">
    <orgos-action icon="add_circle" iconSize="large" (click)="addNewMeeting()">{{i18n.misc.addMeeting}}</orgos-action>
  </div>
  <orgos-column-container *ngIf="allMeetings && allMeetings.length > 0">
    <orgos-column size="0" class="mp-filters-column kenjo-mr-20px kenjo-mt-20px">
      <!-- VIEWS -->
      <orgos-list-view [preferenceKey]="preferenceKey" [newView]="selectedView" (onDeleteView)="updateAfterViewChange($event)" (onChangeView)="updateAfterViewChange($event)"></orgos-list-view>
      <!-- NEW VIEW -->
      <orgos-action (click)="createView()" icon="add_circle" color="Primary" class="kenjo-mv-20px mp-save-view"> {{i18n.misc.saveView}}</orgos-action>
      <!-- FILTERS -->
      <orgos-container aspect="card">
        <orgos-container class="mp-search kenjo-ph-20px">
          <orgos-input-simple-text [label]="i18n.page.search" suffixIcon="search" (valueChange)="changeFilter('search', $event)"></orgos-input-simple-text>
        </orgos-container>
      </orgos-container>
      <orgos-container aspect="card">
        <mat-accordion displayMode="flat" multi="true">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.meetingType}}</mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="templates">
              <div *ngFor="let iTemplate of templates">
                <orgos-input-simple-checkbox [label]="iTemplate.name" class="kenjo-mt-10px" (valueChange)="changeFilter('template-'+iTemplate._id, $event)" [value]="filters['template-'+iTemplate._id]"></orgos-input-simple-checkbox>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.when}}</mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div *ngFor="let value of whenValues">
                <orgos-input-simple-checkbox [label]="i18n.picklists.when[value]" class="kenjo-mt-10px" (valueChange)="changeFilter('when-'+value, $event)" [value]="filters['when-'+value]"> </orgos-input-simple-checkbox>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{i18n.page.department}}</mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div *ngFor="let iDepartment of departments">
                <orgos-input-simple-checkbox [label]="iDepartment.name" class="kenjo-mt-10px" (valueChange)="changeFilter('department-'+iDepartment._id, $event)" [value]="filters['department-'+iDepartment._id]"></orgos-input-simple-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </orgos-container>
    </orgos-column>

    <orgos-column *ngIf="!filteredData || filteredData.length === 0" class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
      <img src="/assets/images/emptyState.svg" />
      <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noMeetingsText}}</div>
    </orgos-column>

    <orgos-column *ngIf="filteredData && filteredData.length > 0">
      <a *ngFor="let iMeeting of filteredData" [routerLink]="['/cloud/meetings/', iMeeting._id]" class="mp-no-underline">
        <orgos-container aspect="card" class="mp-meeting kenjo-p-20px kenjo-cursor-pointer kenjo-mt-20px">
          <orgos-column-container class="kenjo-mb-20px" centerColumns="true">
            <orgos-column class="mp-meeting-name kenjo-font-size-16px kenjo-font-color-primary-5993e3">
              {{iMeeting.name}}
            </orgos-column>
            <orgos-column size="0" class="kenjo-mh-10px" *ngIf="iMeeting.departmentId">
              <kenjo-icon [size]="20">department</kenjo-icon>
            </orgos-column>
            <orgos-column size="0" class="kenjo-mh-10px kenjo-font-color-light-text-757575" *ngIf="iMeeting.departmentId && mapDepartments[iMeeting.departmentId]">
              {{mapDepartments[iMeeting.departmentId].name}}
            </orgos-column>
          </orgos-column-container>
          <div class="kenjo-mb-5px">{{i18n.page.participants}}</div>
          <orgos-column-container centerColumns="true" *ngIf="userPersonalMap">
            <orgos-column>
              <orgos-column-container>
                <orgos-column size="0">
                  <orgos-avatar class="mp-owner-avatar" size="tiny" [avatarName]="userPersonalMap[iMeeting.ownerId]?.displayName" [photoUrl]="userPersonalMap[iMeeting.ownerId]?._photo?._url" [matTooltip]="userPersonalMap[iMeeting.ownerId]?.displayName" matTooltipPosition="above"> </orgos-avatar>
                </orgos-column>
                <orgos-column size="0" *ngFor="let iAttendee of iMeeting.participants; let i=index">
                  <orgos-avatar
                    *ngIf="i < TOTAL_ATTENDEE_SHOWN"
                    class="mp-avatar"
                    size="tiny"
                    [avatarName]="userPersonalMap[iAttendee.userId]?.displayName"
                    [photoUrl]="userPersonalMap[iAttendee.userId]?._photo?._url"
                    [matTooltip]="userPersonalMap[iAttendee.userId]?.displayName"
                    matTooltipPosition="above"
                  >
                  </orgos-avatar>
                </orgos-column>
                <orgos-column size="0" *ngIf="iMeeting.participants && iMeeting.participants.length > TOTAL_ATTENDEE_SHOWN">
                  <div class="mp-attendee-number kenjo-border kenjo-color-grey-background-lighter-f8f8f8 kenjo-font-size-12px">
                    +{{iMeeting.participants.length - TOTAL_ATTENDEE_SHOWN}}
                  </div>
                </orgos-column>
              </orgos-column-container>
            </orgos-column>
            <orgos-column size="0" class="kenjo-ml-40px kenjo-mr-10px">
              <kenjo-icon [size]="20">schedule</kenjo-icon>
            </orgos-column>
            <orgos-column size="0">
              <div *ngIf="iMeeting.startDate" class="kenjo-font-color-light-text-757575">
                {{iMeeting.startDate | humanDate : true}}
              </div>
              <div *ngIf="!iMeeting.startDate" class="kenjo-font-color-light-text-757575">{{i18n.page.noStartDate}}</div>
            </orgos-column>
          </orgos-column-container>
        </orgos-container>
      </a>
    </orgos-column>
  </orgos-column-container>
  <div *ngIf="!allMeetings || allMeetings.length === 0" class="kenjo-empty-state kenjo-text-align-center kenjo-mv-80px">
    <img src="/assets/images/emptyState.svg" />
    <div class="kenjo-mt-20px kenjo-font-size-16px">{{i18n.page.noDataMeetings}}</div>
  </div>
</ng-container>
