import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_RANGE_SELECTION_STRATEGY, MatDatepicker } from '@angular/material/datepicker';
import { isRangeInvalid } from '@app/cloud-features/shift-plan/helpers/shiftplan-general.helper';
import { IShiftPlanDate } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { WeekRangeSelectionStrategy } from '@app/common-components/kenjo-range-datepicker/component/range-datepicker.strategy';
import { FormatDatePipe } from '@app/standard/components/format-date/format-date.pipe';
import * as _ from 'lodash';
import * as moment from 'moment';
@Component({
  selector: 'kenjo-range-datepicker',
  templateUrl: 'range-datepicker.component.html',
  styleUrls: ['range-datepicker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: WeekRangeSelectionStrategy,
    },
  ],
})
export class RangeDatepickerComponent implements OnInit {
  range = new FormGroup({
    from: new FormControl(),
    to: new FormControl(),
  });
  dateFormatted: string = '';

  datepicker: MatDatepicker<Date>;
  @ViewChild('datepicker', { static: false }) set datePickerContent(content: MatDatepicker<Date>) {
    if (content) {
      this.datepicker = content;
      this.injector.get(ChangeDetectorRef).detectChanges();
    }
  }
  @Input() startAt: moment.Moment;
  @Input() disabled: boolean = false;
  @Input() label: string = '';
  @Input() value: IShiftPlanDate;

  @Output() newDate: EventEmitter<IShiftPlanDate> = new EventEmitter<IShiftPlanDate>();

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.setDefaultValue();
  }

  setDefaultValue() {
    if (isRangeInvalid(this.value)) {
      return;
    }

    this.formatDate(this.value);
    this.range.get('from').setValue(this.value.from);
    this.range.get('to').setValue(this.value.to);
  }

  onDateChange() {
    const range = this.range.value as IShiftPlanDate;
    if (isRangeInvalid(range)) {
      return;
    }

    this.newDate.emit(range);
    this.formatDate(range);
  }

  formatDate(range: IShiftPlanDate) {
    const formattedFrom = this.injector.get(FormatDatePipe).transform(range.from, 'D MMM');
    const formattedTo = this.injector.get(FormatDatePipe).transform(range.to, 'D MMM, YYYY');
    this.dateFormatted = `${formattedFrom} - ${formattedTo}`;
  }

  openDatePicker() {
    this.datepicker.open();
  }
}
