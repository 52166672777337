<orgos-dialog-container class="uect-main-container" [title]="dialogTitle" (clickCloseButton)="closeDialog()">
  <orgos-input-simple-text [(value)]="contractType.name" [label]="pageTranslation.contractType" (validation)="inputValidation = $event" required="true" class="kenjo-block" maxlength="90">
    <orgos-input-error
      *ngIf="
        inputValidation &&
        inputValidation.getError('required')"
      >{{pageTranslation.contractTypeRequired}}</orgos-input-error
    >
  </orgos-input-simple-text>
  <div class="kenjo-pt-60px kenjo-text-align-right">
    <orgos-button-raised *ngIf="!contractType._id" color="Success" [disabled]="!inputValidation || inputValidation.hasErrors()" (click)="save()">{{pageTranslation.add}}</orgos-button-raised>
    <orgos-button-raised *ngIf="contractType._id" color="Success" [disabled]="!inputValidation || inputValidation.hasErrors()" (click)="save()">{{pageTranslation.edit}}</orgos-button-raised>
  </div>
</orgos-dialog-container>
