import { Component, EventEmitter, Inject, Injector, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import {
  INominableUser,
  INomination,
  PerformanceReviewNominationsController,
} from '@app/cloud-features/performance-review/controllers/performance-review-nominations.controller';
import { PerformanceReviewService } from '@app/cloud-features/performance-review/services/performance-review.service';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';

@Component({
  templateUrl: 'nominate-peers-dialog.html',
  styleUrls: ['nominate-peers-dialog.scss'],
})
export class NominatePeersDialog implements OnInit {
  @Output() nominationsSaved = new EventEmitter<Array<INomination>>();
  performanceReviewId: string;
  nominationUser: INominableUser;
  readMode: boolean;
  hasNominationsLimit: boolean;
  totalColumns = ['name', 'jobTitle'];
  nominations: Array<INomination>;

  @ViewChild(SearchComponent) searchComponent: SearchComponent;
  translation: any = {};
  allNominableUsers = new Array<INominableUser>();
  searchResults = new Array<INominableUser>();

  loading = true;
  savingNominations = false;

  nominationsLimit: any;

  constructor(
    public dialogRef: MatLegacyDialogRef<NominatePeersDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {
    this.performanceReviewId = data.performanceReviewId;
    this.nominationUser = data.nominationUser;
    this.nominations = data.nominations;
    this.readMode = data.readMode;
    if (!this.readMode) {
      this.totalColumns.push('actions');
    }
  }

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    try {
      if (this.data.nominations === undefined) {
        const nominationsResult = await this.injector
          .get(PerformanceReviewNominationsController)
          .getNominations(this.data?.nominationUser?._id, this.data?.performanceReviewId);
        this.nominations = nominationsResult.nominations;
      } else {
        this.nominations = this.data.nominations;
      }
    } catch (error) {
      // An error is shown
    }

    try {
      this.translation = await this.injector.get(InternationalizationService).getAllTranslation('performance-review-nominate-peers-dialog');
    } catch (error) {
      this.translation = {};
    }

    try {
      this.allNominableUsers = await this.injector
        .get(PerformanceReviewNominationsController)
        .getNominableUsers(this.nominationUser._id, this.performanceReviewId);
    } catch (error) {
      this.allNominableUsers = [];
    }

    try {
      const performanceReview = await this.injector.get(PerformanceReviewService).getById(this.performanceReviewId);
      this.hasNominationsLimit = performanceReview.reviewerType.peer.hasNominationsLimit;
      this.nominationsLimit = this.hasNominationsLimit ? performanceReview.reviewerType.peer.nominationsLimit : null;
    } catch (error) {
      // An error is shown
    }

    this.loading = false;
  }

  public closeDialog(nominationsSaved: boolean): void {
    this.dialogRef.close(nominationsSaved);
  }

  remove(nomination: INomination) {
    const index = this.nominations.findIndex((n) => n.user._id === nomination.user._id);
    this.nominations.splice(index, 1);
    this.nominations = [...this.nominations];
    this.searchComponent.clearSearch();
  }

  save() {
    this.savingNominations = true;
    this.injector
      .get(PerformanceReviewService)
      .updateNominations(this.nominations, this.performanceReviewId, true, this.nominationUser._id)
      .catch((e) => {
        // An error is already shown
      })
      .finally(() => {
        this.savingNominations = false;
        this.closeDialog(true);
      });
  }

  public searchUserFunction: SearchFunction = (newSearchTerm: string): Promise<Array<INominableUser>> => {
    const regexp = new RegExp(newSearchTerm, 'i');
    const results = this.allNominableUsers.filter((iUser) => {
      const searchCriterium = regexp.test(iUser.displayName);
      if (searchCriterium) {
        const alreadyNominated =
          this.nominations.find((iNomination) => iNomination.user?._id?.toString() === iUser?._id?.toString()) !== undefined;
        return !alreadyNominated;
      }
      return false;
    });
    return Promise.resolve(results);
  };

  public assignUser(userToNominate): void {
    const newNomination: INomination = { user: userToNominate, nominationSubmitted: false, performanceReviewId: this.performanceReviewId };
    this.nominations = [...this.nominations, newNomination];
    this.searchComponent.clearSearch();
  }
}
