<orgos-column-container
  class="kenjo-mb-20px"
  *ngIf="!loadingPage && usersDisplayedColumns && usersDisplayedColumns.length > 0"
  centerColumns="true"
>
  <orgos-column size="0" *ngIf="filtersOpened" class="asp-list-filters kenjo-mr-20px">
    <orgos-action (click)="filtersOpened = false">{{i18n.page.hideFilters}} </orgos-action>
  </orgos-column>
  <orgos-column size="0" *ngIf="!filtersOpened" class="kenjo-mr-30px">
    <orgos-action (click)="filtersOpened = true">{{i18n.page.showFilters}} </orgos-action>
  </orgos-column>
  <orgos-column size="1"></orgos-column>
</orgos-column-container>

<orgos-column-container *ngIf="!loadingPage">
  <!-- FILTERS -->
  <orgos-column *ngIf="filtersOpened" size="0" class="asp-list-filters kenjo-mr-20px">
    <!-- VIEWS -->
    <orgos-list-view
      [preferenceKey]="PREFERENCE_VIEW_KEY"
      [newView]="selectedView"
      (onDeleteView)="updateAfterViewChange($event)"
      (onChangeView)="updateAfterViewChange($event)"
    ></orgos-list-view>
    <orgos-action (click)="createView()" icon="add_circle" color="Primary" class="kenjo-m-20px" *ngIf="viewsLoaded">
      {{i18n.page.saveViewButton}}</orgos-action
    >
    <!-- SEARCH AND FILTERS -->
    <orgos-container aspect="card">
      <orgos-container class="asp-list-search kenjo-ph-20px">
        <orgos-input-simple-text
          [(value)]="queryOptions.search"
          [label]="i18n.page.search"
          suffixIcon="search"
          (valueChange)="searchUser($event)"
        ></orgos-input-simple-text>
      </orgos-container>
    </orgos-container>
    <mat-accordion displayMode="flat" multi="true">
      <mat-expansion-panel *ngIf="allStatus && allStatus.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>{{i18n.page.statusFilter}}</mat-panel-title>
        </mat-expansion-panel-header>
        <orgos-input-simple-checkbox
          *ngFor="let iStatus of allStatus"
          (valueChange)="changeStatusFilters(iStatus.value, $event, 'status')"
          [value]="queryOptions?.['user-account']?.where?.[iStatus.value]"
          [label]="iStatus.name"
          class="kenjo-block kenjo-mb-10px"
        ></orgos-input-simple-checkbox>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="allCompanies && allCompanies.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>{{i18n.page.companyFilter}}</mat-panel-title>
        </mat-expansion-panel-header>
        <orgos-input-simple-checkbox
          *ngFor="let iCompany of allCompanies"
          (valueChange)="changeFilters(iCompany._id, $event, 'companyId')"
          [value]="queryOptions?.['user-work']?.where?.companyId?.['$in']?.includes(iCompany._id)"
          [label]="iCompany.name"
          class="kenjo-block kenjo-mb-10px"
        ></orgos-input-simple-checkbox>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="allOffices && allOffices.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>{{i18n.page.officeFilter}}</mat-panel-title>
        </mat-expansion-panel-header>
        <orgos-input-simple-checkbox
          *ngFor="let iOffice of allOffices"
          (valueChange)="changeFilters(iOffice._id, $event, 'officeId')"
          [value]="queryOptions?.['user-work']?.where?.officeId?.['$in']?.includes(iOffice._id)"
          [label]="iOffice.name"
          class="kenjo-block kenjo-mb-10px"
        ></orgos-input-simple-checkbox>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="allAreas && allAreas.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>{{i18n.page.areaFilter}}</mat-panel-title>
        </mat-expansion-panel-header>
        <orgos-input-simple-checkbox
          *ngFor="let iArea of allAreas"
          (valueChange)="changeFilters(iArea._id, $event, 'areaIds')"
          [value]="queryOptions?.['user-work']?.where?.areaIds?.['$in']?.includes(iArea._id)"
          [label]="iArea.name"
          class="kenjo-block kenjo-mb-10px"
        ></orgos-input-simple-checkbox>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="allDepartments && allDepartments.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>{{i18n.page.departmentFilter}}</mat-panel-title>
        </mat-expansion-panel-header>
        <orgos-input-simple-checkbox
          *ngFor="let iDepartment of allDepartments"
          (valueChange)="changeFilters(iDepartment._id, $event, 'departmentId')"
          [value]=" queryOptions?.['user-work']?.where?.departmentId?.['$in']?.includes(iDepartment._id)"
          [label]="iDepartment.name"
          class="kenjo-block kenjo-mb-10px"
        ></orgos-input-simple-checkbox>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="allTeams && allTeams.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>{{i18n.page.teamFilter}}</mat-panel-title>
        </mat-expansion-panel-header>
        <orgos-input-simple-checkbox
          *ngFor="let iTeam of allTeams"
          (valueChange)="changeFilters(iTeam._id, $event, 'teamIds')"
          [value]="queryOptions?.['user-work']?.where?.teamIds?.['$in']?.includes(iTeam._id)"
          [label]="iTeam.name"
          class="kenjo-block kenjo-mb-10px"
        ></orgos-input-simple-checkbox>
      </mat-expansion-panel>
    </mat-accordion>
  </orgos-column>
  <orgos-column>
    <orgos-container aspect="card" [overflowVisible]="true">
      <orgos-column-container centerColumns="true">
        <orgos-column size="1">
          <div class="asp-tabs kenjo-ph-20px">
            <mat-tab-group
              mat-align-tabs="start"
              [selectedIndex]="selectedTab"
              (focusChange)="toggleViewToMonth($event);"
              class="small-tabs"
            >
              <mat-tab *ngFor="let iTab of listTabs" [label]="iTab | uppercase"></mat-tab>
            </mat-tab-group>
          </div>
        </orgos-column>
      </orgos-column-container>
      <orgos-column-container centerColumns="true" class="kenjo-ph-20px kenjo-pv-15px kenjo-border-bottom">
        <orgos-column size="0">
          <orgos-column-container centerColumns="true" class="asp-month-picker-container">
            <orgos-column size="1"></orgos-column>
            <orgos-column
              size="0"
              [matTooltip]="i18n.page.recalculatingTooTilMessage"
              matTooltipPosition="below"
              [matTooltipDisabled]="dataLoaded"
            >
              <kenjo-icon
                [size]="25"
                [ngClass]="dataLoaded ? 'kenjo-font-color-primary-5993e3' :  'kenjo-font-color-disabled-c4c4c4 asp-disabled-arrow'"
                class="kenjo-cursor-pointer kenjo-no-user-select"
                (click)="moveToDate(DECREMENT_TIME)"
                >keyboard_arrow_left</kenjo-icon
              >
            </orgos-column>
            <orgos-column
              *ngIf="monthSelected"
              size="0"
              [matTooltip]="i18n.page.recalculatingTooTilMessage"
              matTooltipPosition="below"
              [matTooltipDisabled]="dataLoaded"
              class="kenjo-mh-30px"
            >
              <kenjo-input-month-picker
                [disabled]="!dataLoaded"
                [preferenceDate]="preferenceDate"
                (selectedDate)="detectChangesOnMonthPicker($event)"
              ></kenjo-input-month-picker>
            </orgos-column>
            <orgos-column
              *ngIf="!monthSelected"
              size="0"
              [class.kenjo-font-color-disabled-c4c4c4]="!dataLoaded"
              class="kenjo-mh-5px kenjo-font-size-16px"
            >
              {{startDayOfWeek | date: 'd LLLL': 'UTC'}} - {{endDayOfWeek | date: 'd LLLL': 'UTC'}}, {{endDayOfWeek | date: 'yyyy'}}
            </orgos-column>
            <orgos-column
              size="0"
              [matTooltip]="i18n.page.recalculatingTooTilMessage"
              matTooltipPosition="below"
              [matTooltipDisabled]="dataLoaded"
            >
              <kenjo-icon
                [size]="25"
                [ngClass]="dataLoaded ? 'kenjo-font-color-primary-5993e3' :  'kenjo-font-color-disabled-c4c4c4 asp-disabled-arrow'"
                class="kenjo-cursor-pointer kenjo-no-user-select"
                (click)="moveToDate(INCREMENT_TIME)"
                >keyboard_arrow_right</kenjo-icon
              >
            </orgos-column>
            <orgos-column size="1"></orgos-column>
          </orgos-column-container>
        </orgos-column>
        <orgos-column size="1" class="kenjo-ml-20px">
          <orgos-column size="0">
            <div class="asp-button-container">
              <div>
                <orgos-button-raised [disabled]="selectedUsers?.length === 0" [matMenuTriggerFor]="massMenu" class="kenjo-ml-30px">
                  {{i18n.page.overtimeActions}}</orgos-button-raised
                >
                <orgos-column *ngIf="selectedUsers.length > 0" size="1" class="kenjo-pl-20px kenjo-font-color-light-text-757575">
                  {{usersSelectedText}}
                </orgos-column>
              </div>
              <kenjo-toggle-error-button
                *ngIf="totalUsersWithConflicts > 0"
                [indicatorLabel]="totalUsersWithConflicts"
                [useIndicator]="true"
                [buttonLabel]="totalUsersWithConflicts > 1 ? i18n.page.employeesWithConflicts : i18n.page.employeeWithConflicts"
                [indicatorSize]="'l'"
                [semantic]="'danger'"
                [disabled]="!selectedUsers?.length === 0"
                [active]="this.queryOptions.filterConflicts"
                (click)="filterUsersWithConflicts()"
              ></kenjo-toggle-error-button>
            </div>
            <mat-menu #massMenu="matMenu">
              <div class="asp-overtime-actions-menu">
                <div class="kenjo-font-weight-bold kenjo-font-size-12px kenjo-mt-10px kenjo-ml-15px kenjo-mb-5px">
                  {{i18n.misc.overtimeOptionsMenu.overtimeActionsSection}}
                </div>
                <button
                  mat-menu-item
                  [disabled]="(selectedUsers[0] && !userToManage.includes(selectedUsers[0]._id)) || !canManageInBulk()"
                  (click)="adjustBalance()"
                >
                  {{i18n.misc.overtimeOptionsMenu.adjustBalance}}
                </button>
                <button
                  mat-menu-item
                  [disabled]="(selectedUsers[0] && (selectedUsers[0].currentOvertimeBalance <= 0 || !userToManage.includes(selectedUsers[0]._id))) || !letConvertInBulk || !canManageInBulk()"
                  (click)="compensateTimeOff()"
                >
                  {{i18n.misc.overtimeOptionsMenu.timeOff}}
                </button>
                <button
                  mat-menu-item
                  [disabled]="selectedUsers.length !== 1 || !canPayOvertime || (selectedUsers[0] && (selectedUsers[0].currentOvertimeBalance <= 0 || !userToManage.includes(selectedUsers[0]._id))) "
                  (click)="compensatePayment()"
                >
                  {{i18n.misc.overtimeOptionsMenu.payOut}}
                </button>
                <button mat-menu-item [disabled]="selectedUsers.length !== 1" (click)="openHistory()">
                  {{i18n.misc.overtimeOptionsMenu.history}}
                </button>

                <div class="kenjo-font-weight-bold kenjo-font-size-12px kenjo-mt-10px kenjo-ml-15px kenjo-mb-5px">
                  {{i18n.misc.overtimeOptionsMenu.attendanceActionsSection}}
                </div>
                <button
                  mat-menu-item
                  [disabled]="selectedUsers.length === 0 && dataLoaded || !canApproveInBulk()"
                  (click)="monthSelected ? openApproveAllEntries() : openApproveWeekly()"
                >
                  {{i18n.misc.overtimeOptionsMenu.approveEntries}}
                </button>
              </div>
            </mat-menu>
          </orgos-column>
        </orgos-column>
      </orgos-column-container>
      <orgos-container
        *ngIf="dataLoaded && attendanceUserList && attendanceUserList.length > 0"
        aspect="card"
        class="kenjo-ml-20px kenjo-mr-20px kenjo-mt-20px"
        [class.asp-max-table-width]="filtersOpened && trackOvertimeEnabled"
      >
        <orgos-table
          [data]="attendanceUserList"
          [displayedColumns]="usersDisplayedColumns"
          enableCustomSorting="true"
          (customSortTable)="sortColumn($event)"
          [customSortBy]="sortBy"
          [hidePagination]="true"
          [customSortOrder]="sortOrder"
          [customHeightToSubstract]="totalOfRecords > 25 ? '339' : '292'"
          [disableRowHighlight]="true"
          [selectable]="true"
          (changeSelection)="selectListItem($event)"
          [selectedList]="selectedUsers"
        >
          <!-- SELECT -->
          <orgos-table-column columnKey="select" [sortable]="false" [actionColumn]="true"> </orgos-table-column>

          <!-- EMPLOYEE -->
          <orgos-table-column columnKey="sortingName" [header]="i18n.page.nameCol" [sortable]="true">
            <div *orgosTableCell="let row">
              <a
                (click)="setComeFromSummary()"
                [routerLink]="['/cloud/people/', row._id, 'attendance']"
                [queryParams]="{ month: monthSelected ? preferenceDate?.format('MM-01-YYYY') : startDayOfWeek?.format('MM-01-YYYY'), conflicts: queryOptions.filterConflicts }"
              >
                <orgos-column-container centerColumns="true" class="kenjo-cursor-pointer kenjo-pv-5px asp-relevant-field">
                  <orgos-avatar
                    orgosColumn="0"
                    [avatarName]="row.displayName"
                    [photoUrl]="row._photo?._url"
                    size="small"
                    class="kenjo-pr-10px"
                  ></orgos-avatar>
                  <kenjo-truncate-text class="kenjo-font-size-14px kenjo-font-color-primary-5993e3" orgosColumn
                    >{{row.displayName}}</kenjo-truncate-text
                  >
                </orgos-column-container>
              </a>
            </div>
          </orgos-table-column>

          <orgos-table-column columnKey="additionalInfo" [header]="i18n.page.status" [sortable]="false">
            <div *orgosTableCell="let row">
              <div orgosColumn="0" class="asp-icon-notifications">
                <kenjo-icon
                  [size]="20"
                  *ngIf="row.approvalCounts?.approved && !row.pendingToApprove"
                  class="kenjo-cursor-pointer asp-icon-notifications__icon kenjo-font-color-success-00b72e"
                  [matTooltip]="(monthSelected ? i18n.page.completelyApproved : i18n.page.completelyApprovedWeek) | i18nData: ({ tracked: (row.approvalCounts.approved + row.approvalCounts.pending), month: months[startDayOfMonth.month()], employeeName: row.displayName })"
                >
                  check_circle
                </kenjo-icon>
                <div *ngIf="row.pendingToApprove" class="asp-ball">
                  <div
                    class="asp-ball__icon kenjo-cursor-pointer"
                    [matTooltip]="(monthSelected ? i18n.page.partiallyApproved : i18n.page.partiallyApprovedWeek) | i18nData: ({ approved: row.approvalCounts.approved, tracked: (row.approvalCounts.approved + row.approvalCounts.pending) })"
                  ></div>
                </div>
                <kenjo-icon
                  [matTooltip]="i18n.page.noTrackedTimeYet"
                  *ngIf="!row.approvalCounts?.approved && !row.pendingToApprove"
                  [size]="17"
                  class="kenjo-cursor-pointer asp-icon-notifications__icon kenjo-font-color-disabled-c4c4c4"
                >
                  no_entries_approved
                </kenjo-icon>
                <kenjo-icon
                  [size]="24"
                  *ngIf="row.geolocationStatus"
                  [ngClass]="{'-green': row.geolocationStatus === 'GREEN', '-yellow': row.geolocationStatus === 'YELLOW', '-grey': row.geolocationStatus === 'GREY'}"
                  class="kenjo-cursor-pointer asp-icon-notifications__icon"
                  [matTooltip]="
                          row.geolocationStatus === 'GREEN' ? i18n.page.trackedSummaryFromAllowedLocation :
                          (
                              row.geolocationStatus === 'YELLOW' ?
                              i18n.page.trackedSummaryFromUnlistedLocation : i18n.page.trackedSummaryFromNotLocation
                          )
                          "
                  >location_on
                </kenjo-icon>
                <div *ngIf="!row.geolocationStatus" class="asp-icon-notifications__icon">-</div>
              </div>
            </div>
          </orgos-table-column>

          <!-- MONTH: TRACKED / EXPECTED -->
          <!-- WEEK: DAYS -->
          <orgos-table-column
            showTooltip="false"
            columnKey="column3"
            [info]="monthSelected === true ? trackedColumnTooltipText : ''"
            [header]="monthSelected === true ? i18n.page.trackedExpectedCol : i18n.page.daysCol"
          >
            <div *orgosTableCell="let row">
              <orgos-column-container centerColumns="true" *ngIf="monthSelected === false && row.trackedHours">
                <orgos-column
                  *ngFor="let day of row.trackedHours; let colIndex = index"
                  class="kenjo-mr-10px asp-box"
                  [matTooltip]="dateTooltipMap[colIndex]?.toolTip"
                  size="0"
                  [ngClass]="{
                    'asp-positive-overtime': day.tracked > day.expectedTime && !attendanceConflictsPerUser[row._id]?.[dateTooltipMap[colIndex]?.date],
                    'asp-negative-overtime': day.tracked > 0 && day.tracked < day.expectedTime && !attendanceConflictsPerUser[row._id]?.[dateTooltipMap[colIndex]?.date],
                    'asp-box-danger': !!attendanceConflictsPerUser[row._id]?.[dateTooltipMap[colIndex]?.date]
                  }"
                >
                  <a
                    (click)="setComeFromSummary(); navigateToAttendanceTab($event, row._id)"
                    (contextmenu)="navigateToNewAttendanceTab()"
                    [routerLink]="['/cloud/people', row._id, 'attendance']"
                    [queryParams]="{ month: monthSelected ? preferenceDate?.format('MM-01-YYYY') : startDayOfWeek?.format('MM-01-YYYY'), conflicts: true }"
                    *ngIf="!!attendanceConflictsPerUser[row._id]?.[dateTooltipMap[colIndex]?.date]"
                    class="kenjo-reset-link"
                  >
                    <div class="asp-box-content">{{day.tracked | duration}}</div>
                  </a>
                  <div *ngIf="!attendanceConflictsPerUser[row._id]?.[dateTooltipMap[colIndex]?.date]" class="asp-box-content">
                    {{day.tracked | duration}}
                  </div>
                </orgos-column>
              </orgos-column-container>
              <orgos-column-container centerColumns="true" *ngIf="monthSelected === false && !row.trackedHours">
                <orgos-column
                  *ngFor="let emptyDay of [0, 0, 0, 0, 0, 0, 0]; let colIndex = index"
                  class="kenjo-mr-10px asp-box"
                  [matTooltip]="dateTooltipMap[colIndex]?.toolTip"
                  size="0"
                >
                  <div class="asp-box-content">{{emptyDay | duration}}</div>
                </orgos-column>
              </orgos-column-container>
              <orgos-column-container *ngIf="monthSelected === true" centerColumns="true" class="asp-relative">
                <orgos-column size="0">
                  <div
                    *ngIf="!row?.hasConflicts"
                    class="asp-month-box kenjo-pv-10px kenjo-ph-20px asp-tracked-box-size"
                    [ngClass]="{
                      'asp-positive-overtime': (row.totalTrackedHours > (row.totalExpectedHours * 1.1)),
                      'asp-negative-overtime': (row.totalTrackedHours < (row.totalExpectedHours * 0.9))
                    }"
                  >
                    {{row.totalTrackedHours | duration}} / {{row.totalExpectedHours | duration}}
                  </div>
                  <a
                    (click)="setComeFromSummary(); navigateToAttendanceTab($event, row._id)"
                    (contextmenu)="navigateToNewAttendanceTab()"
                    [routerLink]="['/cloud/people', row._id, 'attendance']"
                    [queryParams]="{ month: monthSelected ? preferenceDate?.format('MM-01-YYYY') : startDayOfWeek?.format('MM-01-YYYY'), conflicts: true }"
                    *ngIf="row?.hasConflicts"
                    class="kenjo-reset-link"
                  >
                    <kenjo-button-semantic
                      *ngIf="row?.hasConflicts"
                      class="kenjo-font-size-16px"
                      [width]="220"
                      [size]="'L'"
                      [icon]="'error_filled'"
                      [type]="'danger'"
                      >{{i18n.page.goToConflicts}}</kenjo-button-semantic
                    >
                  </a>
                </orgos-column>
              </orgos-column-container>
            </div>
          </orgos-table-column>

          <!-- MONTH: DIFFERENCE -->
          <!-- WEEK: NONE -->
          <orgos-table-column
            showTooltip="false"
            columnKey="column4"
            [header]="i18n.page.differenceCol"
            [info]="pageInfo && pageInfo.hasWaiver && pageInfo.hasWaiver === true ? i18n.page.differenceTooltip : i18n.page.differenceTooltipNoWaiver"
          >
            <div *orgosTableCell="let row">({{row.difference | duration: true}})</div>
          </orgos-table-column>

          <!-- MONTH: OVERTIME BALANCE -->
          <!-- WEEK: TRACKED / EXPECTED -->
          <orgos-table-column
            [noWrapText]="true"
            showTooltip="false"
            columnKey="column5"
            [header]="monthSelected === true ? i18n.page.overtimeBalanceCol : i18n.page.trackedExpectedCol"
            [info]="
            monthSelected === true ?
            (pageInfo && pageInfo.hasTransferOvertime && pageInfo.hasTransferOvertime === true ? i18n.page.overtimeMonthTooltip : i18n.page.overtimeMonthTooltipNoTransfer) :
            i18n.page.trackedWeekTooltip
          "
          >
            <div *orgosTableCell="let row">
              <div
                *ngIf="monthSelected === true && row.trackOvertime"
                class="asp-month-box kenjo-pv-10px kenjo-ph-20px asp-overtime-box-size"
                [ngClass]="{
                  'asp-positive-overtime': row.currentOvertimeBalance > 0,
                  'asp-negative-overtime': row.currentOvertimeBalance < 0
                }"
              >
                {{row.currentOvertimeBalance | duration}}
              </div>
              <div *ngIf="monthSelected === true && !row.trackOvertime" [matTooltip]="i18n.page.overtimeNotEnabledTooltip">-</div>
              <div class="asp-tracked-expected-box" *ngIf="monthSelected === false">
                {{row.totalTrackedHours | duration}} / {{row.totalExpectedHours | duration}}
              </div>
            </div>
          </orgos-table-column>

          <!-- MONTH: COMPENSATED -->
          <!-- WEEK: OVERTIME -->
          <orgos-table-column
            showTooltip="false"
            columnKey="column6"
            [header]="monthSelected === true ? i18n.page.compensatedCol : i18n.page.overtimeColWeek"
            [info]="monthSelected === true ? i18n.page.compensatedTooltip : i18n.page.overtimeWeekTooltip"
          >
            <div *orgosTableCell="let row">
              <div *ngIf="monthSelected === false && row.trackOvertime">{{row.overtimeHours | duration}}</div>
              <div *ngIf="monthSelected === false && !row.trackOvertime" [matTooltip]="i18n.page.overtimeNotEnabledTooltip">-</div>
              <div *ngIf="monthSelected === true && row.trackOvertime">{{row.overtimeCompensated | duration}}</div>
              <div *ngIf="monthSelected === true && !row.trackOvertime" [matTooltip]="i18n.page.overtimeNotEnabledTooltip">-</div>
            </div>
          </orgos-table-column>
        </orgos-table>

        <!-- PAGINATION -->
        <orgos-column-container centerColumns="true" class="asp-pagination" *ngIf="totalOfRecords > 25">
          <orgos-column size="1"></orgos-column>
          <orgos-column size="0">
            <orgos-column-container centerColumns="true">
              <orgos-text orgosColumn="0" type="BodyText" color="LightText"> {{i18n.page.displayingLabel}}</orgos-text>
              <orgos-column size="0" class="kenjo-pl-10px kenjo-ml-10px">
                <div [matMenuTriggerFor]="paginationMenu" class="kenjo-cursor-pointer">
                  <orgos-column-container centerColumns="true">
                    <orgos-text
                      orgosColumn="0"
                      type="BodyText"
                      color="NormalText"
                      [matTooltip]="i18n.page.numberOfRecordsToShowText"
                      class="asp-list-records-page"
                    >
                      {{queryOptions.recordsPerPage}}</orgos-text
                    >
                    <orgos-column size="0">
                      <mat-icon class="kenjo-font-size-18px">arrow_drop_down</mat-icon>
                    </orgos-column>
                  </orgos-column-container>
                </div>
                <mat-menu #paginationMenu="matMenu">
                  <button *ngFor="let page of recordsToShowSelector" mat-menu-item (click)="changeRecordsToShow(page)">
                    <orgos-text orgosColumn="0" type="BodyText" color="LightText">{{page}}</orgos-text>
                  </button>
                </mat-menu>
              </orgos-column>
              <orgos-text orgosColumn="0" type="BodyText" color="LightText" class="kenjo-pl-10px"> {{i18n.page.outOfLabel}}</orgos-text>
              <orgos-text orgosColumn="0" type="BodyText" color="NormalText" class="kenjo-pl-10px"> {{totalOfRecords}}</orgos-text>
            </orgos-column-container>
          </orgos-column>
          <orgos-column size="0" class="kenjo-mh-40px">
            <orgos-column-container centerColumns="true">
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-mr-10px asp-list-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                  (click)="moveToPage(PAGE_SELECTOR['first'])"
                  >first_page</mat-icon
                >
              </orgos-column>
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-mr-10px asp-list-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === 1, 'kenjo-font-color-primary-5993e3': queryOptions.page !== 1 }"
                  (click)="moveToPage(PAGE_SELECTOR['previous'])"
                  >keyboard_arrow_left</mat-icon
                >
              </orgos-column>
              <orgos-text orgosColumn="0" type="BodyText" class="kenjo-ml-10px">{{queryOptions.page}} / {{numberOfPages}} </orgos-text>
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-ml-10px asp-list-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== numberOfPages }"
                  (click)="moveToPage(PAGE_SELECTOR['next'])"
                  >keyboard_arrow_right</mat-icon
                >
              </orgos-column>
              <orgos-column size="0">
                <mat-icon
                  class="kenjo-ml-10px asp-list-pagination-icon"
                  [ngClass]="{'kenjo-font-color-disabled-c4c4c4': queryOptions.page === numberOfPages, 'kenjo-font-color-primary-5993e3': queryOptions.page !== numberOfPages }"
                  (click)="moveToPage(PAGE_SELECTOR['final'])"
                  >last_page</mat-icon
                >
              </orgos-column>
            </orgos-column-container>
          </orgos-column>
          <orgos-column size="1"></orgos-column>
        </orgos-column-container>
      </orgos-container>

      <div class="asp-legend-container" *ngIf="dataLoaded && attendanceUserList && attendanceUserList.length > 0">
        <div class="asp-legend">
          <div class="asp-legend__circle asp-legend__circle--overtime"></div>
          <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.overtimeLegend }}</div>
        </div>
        <div class="asp-legend">
          <div class="asp-legend__circle asp-legend__circle--undertime"></div>
          <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.undertimeLegend }}</div>
        </div>
        <div class="asp-legend">
          <div class="asp-legend__circle asp-legend__circle--conflicts"></div>
          <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ i18n.page.conflicts }}</div>
        </div>
      </div>

      <!-- No data -->
      <orgos-column-container
        *ngIf="dataLoaded && attendanceUserList && attendanceUserList.length === 0"
        centerColumns="false"
        class="kenjo-text-align-center kenjo-p-20px"
      >
        <orgos-column size="1"></orgos-column>
        <orgos-column size="4">
          <orgos-text type="Headline4" color="NormalText">
            <kenjo-icon [size]="16" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon>
            {{i18n.page.noAttendanceTitle}}
          </orgos-text>
          <orgos-text type="BodyText" color="LightText" class="kenjo-pt-20px">{{i18n.page.noAttendanceSubtitle}} </orgos-text>
        </orgos-column>
        <orgos-column size="1"></orgos-column>
      </orgos-column-container>

      <!-- If still loading -->
      <div *ngIf="!dataLoaded" class="asp-list-spinner-container kenjo-p-20px">
        <div class="asp-list-loading-spinner kenjo-p-20px">
          <div>
            <orgos-loading-spinner></orgos-loading-spinner>
            <orgos-text type="SecondaryText" color="LightText" class="kenjo-mt-20px">{{i18n.page.loadingDataMessage}} </orgos-text>
          </div>
        </div>
      </div>
    </orgos-container>
  </orgos-column>
</orgos-column-container>
