import { ContentChild, Directive, EventEmitter, Input, Output } from '@angular/core';

import { TableCellDirective } from './table-cell.directive';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'orgos-table-column'
})
export class TableColumnDirective {
  @Input() columnKey: string = '';
  @Input() header: string = '';
  @Input() info: string = '';
  @Input() sortable: boolean = false;
  @Input() actionColumn: boolean = false;
  @Input() cellWidth: string = '';
  @Input() tooltip: string = '';
  @Input() showTooltip: boolean = true;
  @Input() centerContent: boolean = false;
  @Input() noWrapText: boolean = false;

  @Input() selectable: boolean = false;
  @Input() allSelected: boolean = false;
  @Input() indeterminate: boolean = false;

  @Output() selectAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ContentChild(TableCellDirective) tableCell: TableCellDirective;

  public changeColumnSelection(event: any): void {
    this.selectAction.emit(event.checked);
  }
}
