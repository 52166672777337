import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { OAuthIntegrationService } from '@app/cloud-features/oauth-callback/services/oauth-integration.service';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { PrivateOrganizationService } from '../../../private/services/private-organization.service';
import { CloudRoutesService } from '../core/cloud-routes.service';
import { ErrorManagerService } from '../error/error-manager.service';

@Injectable()
export class CoreFeaturesService {
  private APP_STORE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/core-features-db`;
  private coreFeaturesCache: Array<IFeature>;

  constructor(private http: HttpClient, private injector: Injector, private errorManager: ErrorManagerService) {}

  getCoreFeaturesCache(appKey: string): Promise<IFeature> {
    return new Promise<IFeature>((resolve) => {
      let promise: any = Promise.resolve();
      if (check.not.assigned(this.coreFeaturesCache) || check.emptyArray(this.coreFeaturesCache)) {
        promise = this.getAllApps();
      }
      promise
        .then(() => {
          const result: IFeature = this.coreFeaturesCache.find((iFeature: IFeature) => {
            return iFeature.appKey === appKey;
          });
          resolve(result);
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  }

  getAllApps(): Promise<Array<IFeature>> {
    return new Promise<Array<IFeature>>((resolve, reject) => {
      this.http
        .get(`${this.APP_STORE_URL}`)
        .toPromise()
        .then((coreFeatures: Array<IFeature>) => {
          const allApps = coreFeatures.filter((feature) => {
            return !feature.isHomeWidget && !feature.isIntegration;
          });
          this.coreFeaturesCache = coreFeatures;
          resolve(allApps);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getAllApps'));
        });
    });
  }

  getAllWidgets(): Promise<Array<IFeature>> {
    return new Promise<Array<IFeature>>((resolve, reject) => {
      this.http
        .get(`${this.APP_STORE_URL}`)
        .toPromise()
        .then((coreFeatures: Array<IFeature>) => {
          const allWidgets = coreFeatures.filter((feature) => {
            return feature.isHomeWidget === true;
          });
          resolve(allWidgets);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getAllWidgets'));
        });
    });
  }

  getAllIntegrations(): Promise<Array<IFeature>> {
    return new Promise<Array<IFeature>>((resolve, reject) => {
      this.http
        .get(`${this.APP_STORE_URL}`)
        .toPromise()
        .then((coreFeatures: Array<IFeature>) => {
          const allIntegrations = coreFeatures.filter((feature) => {
            return feature.isIntegration && feature.isIntegration === true;
          });
          resolve(allIntegrations);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getAllWidgets'));
        });
    });
  }

  async getActiveIntegrations(): Promise<Array<string>> {
    const [nonOauthIntegrations, oauthIntegrations] = await Promise.all([
      this.getNonOauthIntegrations(),
      this.injector.get(OAuthIntegrationService).getConnectedIntegrations(),
    ]);
    nonOauthIntegrations.forEach((nonOauthIntegration) => {
      if (nonOauthIntegration.isActive === true) {
        oauthIntegrations.push(nonOauthIntegration.appKey);
      }
    });

    return oauthIntegrations;
  }

  private async getNonOauthIntegrations(): Promise<Array<IFeature>> {
    const nonOauthIntegrationsCalls = ['api-keys', 'datev', 'join'].map((integration) => this.getAppDetail(integration));
    return Promise.all(nonOauthIntegrationsCalls);
  }

  getActiveWidgets(): Promise<Array<string>> {
    return new Promise<Array<string>>((resolve, reject) => {
      this.injector
        .get(PrivateOrganizationService)
        .getActiveHomeWidgets()
        .then((activeWidgets: Array<string>) => {
          resolve(activeWidgets);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getAllActiveWidgets'));
        });
    });
  }

  getApp(appKey: string): Promise<IFeature> {
    return new Promise<IFeature>((resolve, reject) => {
      this.http
        .get(`${this.APP_STORE_URL}/${appKey}`)
        .toPromise()
        .then((app: Array<IFeature>) => {
          resolve(app[0]);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getApp'));
        });
    });
  }

  activateApp(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(CloudRoutesService)
        .activateApp(appKey)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'activateApp'));
        })
        .finally(async () => {
          await this.injector.get(CloudRoutingService).refreshRouter();
        });
    });
  }

  deactivateApp(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(CloudRoutesService)
        .deactivateApp(appKey)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'deactivateApp'));
        })
        .finally(async () => {
          await this.injector.get(CloudRoutingService).refreshRouter();
        });
    });
  }

  activateWidget(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(PrivateOrganizationService)
        .enableHomeWidget(appKey)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'activateWidget'));
        });
    });
  }

  deactivateWidget(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(PrivateOrganizationService)
        .disableHomeWidget(appKey)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'deactivateWidget'));
        });
    });
  }

  deactivateOauthIntegration(appKey: string): Promise<void> {
    return this.injector.get(OAuthIntegrationService).disconnectIntegration(appKey);
  }

  sendSupportNotification(appKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(CloudRoutesService)
        .sendSupportNotification(appKey)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'sendSupportNotification'));
        });
    });
  }

  getAppDetail(appKey: string): Promise<IFeature> {
    return new Promise<IFeature>((resolve, reject) => {
      this.injector
        .get(CloudRoutesService)
        .getAppStatus(appKey)
        .then((app) => {
          const tempApp: IFeature = {
            appKey: app.appKey,
            isActive: app.isActive,
            needSupportNotification: app.needSupportNotification,
            lastSupportNotificationDate: app.lastSupportNotificationDate,
          };
          resolve(tempApp);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getAppDetail'));
        });
    });
  }

  getDependantWidgets(): Promise<{ [id: string]: Array<string> }> {
    return new Promise<{ [id: string]: Array<string> }>((resolve, reject) => {
      this.http
        .get(`${this.APP_STORE_URL}`)
        .toPromise()
        .then((coreFeatures: Array<IFeature>) => {
          const result: { [id: string]: Array<string> } = coreFeatures.reduce((tempResult, feature) => {
            if (check.assigned(feature.dependencies) && check.nonEmptyArray(feature.dependencies)) {
              tempResult[feature.appKey] = feature.dependencies;
            }
            return tempResult;
          }, {});
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getDependantWidgets'));
        });
    });
  }

  getWidgetDependencies(): Promise<{ [id: string]: string }> {
    return new Promise<{ [id: string]: string }>((resolve, reject) => {
      this.http
        .get(`${this.APP_STORE_URL}`)
        .toPromise()
        .then((coreFeatures: Array<IFeature>) => {
          const result: { [id: string]: string } = coreFeatures.reduce((tempResult, feature) => {
            if (check.assigned(feature.dependencies) && check.nonEmptyArray(feature.dependencies)) {
              feature.dependencies.forEach((widget) => {
                tempResult[widget] = feature.appKey;
              });
            }
            return tempResult;
          }, {});
          resolve(result);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, CoreFeaturesService.name, 'getWidgetDependencies'));
        });
    });
  }
}

export interface IFeature {
  _id?: string;
  appKey: string;
  developmentState?: string;
  isActive: boolean;
  hideActivationToggle?: boolean;
  hasSettings?: boolean;
  isHomeWidget?: boolean;
  isIntegration?: boolean;
  isOauth?: boolean;
  needSupportNotification?: boolean;
  lastSupportNotificationDate?: Date;
  dependencies?: [string];
  useLogo?: boolean;
  isParent?: boolean;
  parentSettings?: any;
  isDefault?: boolean;
  settingsLink?: string;
}
