import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IFileMetadata } from '@app/standard/services/file/file-metadata.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { ACCESS_TYPE_AGENCY_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';

@Injectable({
  providedIn: 'root'
})
export class PrivateOrganizationService implements IGenericService {
  private ORGANIZATION_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/organization-db`;
  private ORGANIZATION_PERMISSIONS_KEY: string = 'organization';
  private ORGANIZATION_INTERNATIONALIZATION: string = 'organization-collection';

  constructor(private injector: Injector) {}

  create(): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateOrganizationService.name, 'create');
    error.message = 'Organization should not be created';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateOrganizationService.name, 'getById');
    error.message = 'Organization should not be got';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  updateById(): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateOrganizationService.name, 'updateById');
    error.message = 'Organization should not be updated';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  deleteById(): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateOrganizationService.name, 'deleteById');
    error.message = 'Organization should not be deleted';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.injector.get(GenericService).getPermissions(this.ORGANIZATION_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.injector.get(GenericService).getFieldsTranslations(this.ORGANIZATION_INTERNATIONALIZATION);
  }

  getInvitationEmail(language: string): Promise<IInvitationEmailModel> {
    return new Promise<IInvitationEmailModel>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      let urlParams;
      if (check.assigned(language)) {
        urlParams = new HttpParams().set('lang', language);
      } else {
        urlParams = new HttpParams();
      }

      const httpOptions = {
        headers: httpHeaders,
        params: urlParams
      };

      this.injector
        .get(HttpClient)
        .get<IInvitationEmailModel>(`${this.ORGANIZATION_URL}/organizations/invitation-email`, httpOptions)
        .toPromise()
        .then((invitationEmailResponse) => {
          resolve(invitationEmailResponse);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getInvitationEmail'));
        });
    });
  }

  updateInvitationEmail(invitationEmail: IInvitationEmailModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      delete invitationEmail._id;

      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/invitation-email`, invitationEmail, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'updateInvitationEmail'));
        });
    });
  }

  getAboutOrganization(language: string): Promise<IAboutOrganizationModel> {
    return new Promise<IAboutOrganizationModel>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      let urlParams;
      if (check.assigned(language)) {
        urlParams = new HttpParams().set('lang', language);
      } else {
        urlParams = new HttpParams();
      }

      const httpOptions = {
        headers: httpHeaders,
        params: urlParams
      };

      this.injector
        .get(HttpClient)
        .get<IAboutOrganizationModel>(`${this.ORGANIZATION_URL}/organizations/about-organization`, httpOptions)
        .toPromise()
        .then((aboutOrganizationResponse) => {
          resolve(aboutOrganizationResponse);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getAboutOrganization'));
        });
    });
  }

  updateAboutOrganization(aboutOrganization: IAboutOrganizationModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      delete aboutOrganization._id;

      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/about-organization`, aboutOrganization, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'updateAboutOrganization'));
        });
    });
  }

  getPulseInfo(): Promise<IPulseInfoModel> {
    return new Promise<IPulseInfoModel>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<IPulseInfoModel>(`${this.ORGANIZATION_URL}/organizations/pulse-info`, httpOptions)
        .toPromise()
        .then((pulseInfoResponse) => {
          resolve(pulseInfoResponse);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getPulseInfo'));
        });
    });
  }

  updatePulseFrequency(pulseFrequency: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/pulse-frequency/${pulseFrequency}`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'updatePulseFrequency'));
        });
    });
  }

  updatePulseWeekday(pulseWeekday: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/pulse-weekday/${pulseWeekday}`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'updatePulseWeekday'));
        });
    });
  }

  getEmail2caseLink(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<any>(`${this.ORGANIZATION_URL}/organizations/email2caseLink`, httpOptions)
        .toPromise()
        .then((email2caseResponse) => {
          resolve(email2caseResponse.link);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getEmail2caseLink'));
        });
    });
  }

  enableHomeWidget(widgetKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/enable-home-widget/${widgetKey}`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'enableHomeWidget'));
        });
    });
  }

  disableHomeWidget(widgetKey: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/disable-home-widget/${widgetKey}`, {}, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'disableHomeWidget'));
        });
    });
  }

  getActiveHomeWidgets(): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        resolve([]);
        return;
      }

      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<Array<any>>(`${this.ORGANIZATION_URL}/organizations/active-home-widgets`, httpOptions)
        .toPromise()
        .then((activeHomeWidgets) => {
          resolve(activeHomeWidgets);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getActiveHomeWidgets'));
        });
    });
  }

  getApiConnectors(): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<Array<any>>(`${this.ORGANIZATION_URL}/organizations/api-connectors`, httpOptions)
        .toPromise()
        .then((activeHomeWidgets) => {
          resolve(activeHomeWidgets);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getApiConnectors'));
        });
    });
  }

  getMyOrganization(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get(`${this.ORGANIZATION_URL}/organizations/my-organization`, httpOptions)
        .toPromise()
        .then((org) => {
          resolve(org);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getMyOrganization'));
        });
    });
  }

  updateMyOrganization(org: IOrganization): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const { brandName, logo } = org;
      const body = { brandName, logo };
      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/my-organization`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'updateMyOrganization'));
        });
    });
  }

  getTimeOffConfiguration(): Promise<ITimeOffConfigurationModel> {
    return new Promise<ITimeOffConfigurationModel>((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .get(`${this.ORGANIZATION_URL}/organizations/time-off`)
        .toPromise()
        .then((timeOffConfiguration: ITimeOffConfigurationModel) => {
          resolve(timeOffConfiguration);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getTimeOffConfiguration'));
        });
    });
  }

  setTimeOffConfiguration(timeOffConfiguration: Partial<ITimeOffConfigurationModel>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.injector
        .get(HttpClient)
        .put(`${this.ORGANIZATION_URL}/organizations/time-off`, timeOffConfiguration)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'setTimeOffConfiguration'));
        });
    });
  }

  async getFilesConfiguration(): Promise<IFilesConfigurationModel> {
    try {
      return await this.injector.get(HttpClient).get<IFilesConfigurationModel>(`${this.ORGANIZATION_URL}/organizations/files`).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getFilesConfiguration');
    }
  }

  async setFilesConfiguration(filesConfiguration: IFilesConfigurationModel): Promise<void> {
    try {
      await this.injector.get(HttpClient).put(`${this.ORGANIZATION_URL}/organizations/files`, filesConfiguration).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'setFilesConfiguration');
    }
  }

  async isChurnZeroEnabled(): Promise<IChurnZeroEnabled> {
    try {
      if (this.injector.get(AuthenticationService).getAccessType() === ACCESS_TYPE_AGENCY_ACCESS) {
        return;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return (await this.injector.get(HttpClient).get(`${this.ORGANIZATION_URL}/organizations/check-churnzero-enabled`, httpOptions).toPromise()) as IChurnZeroEnabled;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'isChurnZeroEnabled');
    }
  }

  async getSubscription(): Promise<ISubscription> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get(`${this.ORGANIZATION_URL}/organizations/subscription`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getSubscription');
    }
  }

  async getOnboardingStatus(): Promise<IOnboardingStatus> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      return await this.injector.get(HttpClient).get<IOnboardingStatus>(`${this.ORGANIZATION_URL}/organizations/check-onboarding-status`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateOrganizationService.name, 'getOnboardingStatus');
    }
  }

}

export interface IInvitationEmailModel {
  _id: string;
  language: string;
  value: string;
}

export interface IAboutOrganizationModel {
  _id: string;
  language: string;
  value: string;
}

export interface IPulseInfoModel {
  pulseFrequency: number;
  pulseWeekday: number;
}
export interface IOrganization {
  email?: string;
  logo: IFileMetadata;
  brandName: string;
  isFreeTrialAccount?: boolean;
  _createdAt?: Date;
  _id?: string;
}

export interface ITimeOffConfigurationModel {
  hidePastTimeOffs: boolean;
  enableApprovalDelegation: boolean;
  enableTimeOffApprover: boolean;
}

export interface IFilesConfigurationModel {
  enableCdnCache: boolean;
  expirationTimeForInternalFilesInMonths: number;
  expirationTimeForExternalFilesInMonths: number;
}

export interface IChurnZeroEnabled {
  _churnzeroEnabled: boolean;
  subscriptionStatus: string;
  countryCode: string;
}
export interface ISubscription {
  status?: string;
  plans?: Array<string>;
  countryCode?: string;
}

export interface IOnboardingStatus {
  _onboardingFinished: boolean
}