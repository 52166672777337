<div
  #content
  *ngIf="dataLoaded"
  class="sppt-content"
  [ngClass]="{
    'sppt-portrait': options.orientation === orientation.PORTRAIT,
    'sppt-landscape': options.orientation === orientation.LANDSCAPE
  }"
>
  <!-- Open shifts -->
  <div *ngIf="shifts.openShifts.length > 0" class="kenjo-mb-30px">
    <orgos-column-container [centerColumns]="true" class="kenjo-mb-15px">
      <kenjo-shiftplan-pdf-label orgosColumn [labelText]="componentTranslations.openShifts" [status]="'title'"></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        orgosColumn="0"
        [labelText]="componentTranslations.publicHolidaysLabel"
        [status]="'holiday'"
      ></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        orgosColumn="0"
        class="kenjo-ml-10px"
        [labelText]="componentTranslations.timeOffLabel"
        [status]="'time-off'"
      ></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        orgosColumn="0"
        class="kenjo-ml-10px"
        [labelHeader]="componentTranslations.unpublishedShift"
        [labelText]="componentTranslations.unpublishedShiftText"
        [status]="'draft'"
      ></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        orgosColumn="0"
        class="kenjo-ml-10px"
        [labelHeader]="componentTranslations.publishedShift"
        [labelText]="componentTranslations.publishedShiftText"
        [status]="'published'"
      ></kenjo-shiftplan-pdf-label>
    </orgos-column-container>
    <!-- Days -->
    <kenjo-shiftplan-pdf-header
      [frequency]="options.frequency"
      [currentDays]="currentDays"
      [holidays]="holidays"
    ></kenjo-shiftplan-pdf-header>
    <!-- Open shifts row -->
    <kenjo-shiftplan-pdf-row
      [openShifts]="shifts.openShiftCardsGroupedByDay"
      [currentDays]="currentDays"
      [options]="options"
      [holidays]="holidays"
      [componentTranslations]="componentTranslations"
    ></kenjo-shiftplan-pdf-row>
    <div class="sppt-position"></div>
  </div>

  <!-- Scheduled shifts -->
  <div *ngIf="shifts.scheduledDraftCount > 0 || shifts.scheduledPublishedCount > 0">
    <orgos-column-container [centerColumns]="true" class="kenjo-mb-15px">
      <kenjo-shiftplan-pdf-label
        orgosColumn
        [labelText]="componentTranslations.scheduledShifts"
        [status]="'title'"
      ></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        *ngIf="shifts.openShifts.length === 0"
        orgosColumn="0"
        [labelText]="componentTranslations.publicHolidaysLabel"
        [status]="'holiday'"
      ></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        *ngIf="shifts.openShifts.length === 0"
        orgosColumn="0"
        class="kenjo-ml-10px"
        [labelText]="componentTranslations.timeOffLabel"
        [status]="'time-off'"
      ></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        *ngIf="shifts.openShifts.length === 0"
        orgosColumn="0"
        class="kenjo-ml-10px"
        [labelText]="componentTranslations.unpublished"
        [status]="'draft'"
      ></kenjo-shiftplan-pdf-label>
      <kenjo-shiftplan-pdf-label
        *ngIf="shifts.openShifts.length === 0"
        orgosColumn="0"
        class="kenjo-ml-10px"
        [labelText]="componentTranslations.published"
        [status]="'published'"
      ></kenjo-shiftplan-pdf-label>
    </orgos-column-container>
    <!-- Days -->
    <kenjo-shiftplan-pdf-header
      [frequency]="options.frequency"
      [currentDays]="currentDays"
      [holidays]="holidays"
    ></kenjo-shiftplan-pdf-header>
    <!-- Employee shifts row -->

    <ng-container *ngIf="options.listView === layouts.USER">
      <div *ngFor="let employee of shifts.shiftsGrouped">
        <kenjo-shiftplan-pdf-row
          [employee]="employee"
          [currentDays]="currentDays"
          [options]="options"
          [timeOff]="timeOffs?.[employee._id]"
          [holidays]="holidays"
          [componentTranslations]="componentTranslations"
        ></kenjo-shiftplan-pdf-row>
        <div class="sppt-position"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="options.listView === layouts.ROLE">
      <div *ngFor="let role of shifts.shiftsGrouped">
        <ng-container *ngIf="role.shiftsGrouped.length > 0">
          <div
            [style.background-color]="colorConstants[role.colour]"
            class="kenjo-pl-10px kenjo-pv-5px kenjo-border-right kenjo-border-bottom kenjo-border-left"
          >
            <span *ngIf="options.frequency === views.WEEK"> {{ role.name }} </span>
            <span *ngIf="options.frequency === views.MONTH"> {{ role.codeName || (role.name | slice: 0:2) }} - {{ role.name }} </span>
          </div>
          <div *ngFor="let employee of role.shiftsGrouped">
            <kenjo-shiftplan-pdf-row
              [employee]="employee"
              [currentDays]="currentDays"
              [options]="options"
              [timeOff]="timeOffs?.[employee._id]"
              [holidays]="holidays"
              [componentTranslations]="componentTranslations"
            ></kenjo-shiftplan-pdf-row>
            <div class="sppt-position"></div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <!-- Days column to repeat -->
  <kenjo-shiftplan-pdf-header
    [frequency]="options.frequency"
    [currentDays]="currentDays"
    [holidays]="holidays"
  ></kenjo-shiftplan-pdf-header>

  <!-- Kenjo logo footer -->
  <kenjo-shiftplan-pdf-footer
    [color]="options.color"
    [roles]="options.frequency === views.MONTH ? shifts.roles : []"
  ></kenjo-shiftplan-pdf-footer>
</div>

<!-- Custom snackbar -->
<ng-template #snackbarError>
  <div class="sppt-snackbar">
    <div class="kenjo-font-weight-bold">{{ componentTranslations.somethingWentWrong }}</div>
    <div>{{ componentTranslations.generatePDFError }}</div>
  </div>
</ng-template>
