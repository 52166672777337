<div class="add-dialog-box kenjo-p-40px" *ngIf="dialogTranslations && dataLoaded">
  <!-- HEADER -->
  <div class="add-header">
    <div class="kenjo-font-size-18px kenjo-font-weight-bold">{{ dialogTranslations.dialogName }}</div>
    <kenjo-icon
      [size]="24"
      class="dc-close-button kenjo-cursor-pointer kenjo-ml-40px kenjo-font-color-light-text-757575"
      (click)="closeDialog()"
      >clear</kenjo-icon
    >
  </div>

  <div class="kenjo-mt-40px add-main">
    <div class="kenjo-pt-40px">
      <div class="add-steps">
        <div class="add-step">
          <div class="add-icon" [class.add-completed]="currentSection >= 0"><kenjo-icon [size]="18">done</kenjo-icon></div>
          <div
            class="add-title"
            [class.kenjo-font-weight-bold]="currentSection === 0"
            [class.kenjo-font-color-disabled-c4c4c4]="currentSection < 0"
            [class.add-clickable]="currentSection > 0"
            (click)="goToSection(0)"
          >
            {{ dialogTranslations.personalSection }}
          </div>
        </div>
        <div class="add-line">
          <div></div>
        </div>
        <div class="add-step">
          <div class="add-icon" [class.add-completed]="currentSection >= 1"><kenjo-icon [size]="18">done</kenjo-icon></div>
          <div
            class="add-title"
            [class.kenjo-font-weight-bold]="currentSection === 1"
            [class.kenjo-font-color-disabled-c4c4c4]="currentSection < 1"
            [class.add-clickable]="currentSection > 1"
            (click)="goToSection(1)"
          >
            {{ dialogTranslations.workSection }}
          </div>
        </div>
        <div class="add-line">
          <div></div>
        </div>
        <div *ngIf="timeOffEnabled" class="add-step">
          <div class="add-icon" [class.add-completed]="currentSection >= 2"><kenjo-icon [size]="18">done</kenjo-icon></div>
          <div
            class="add-title"
            [class.kenjo-font-weight-bold]="currentSection === 2"
            [class.kenjo-font-color-disabled-c4c4c4]="currentSection < 2"
            [class.add-clickable]="currentSection > 2"
            (click)="goToSection(2)"
          >
            {{ dialogTranslations.timeOffSection }}
          </div>
        </div>
        <div *ngIf="timeOffEnabled" class="add-line">
          <div></div>
        </div>
        <div *ngIf="attendanceEnabled" class="add-step">
          <div class="add-icon" [class.add-completed]="currentSection >= 3"><kenjo-icon [size]="18">done</kenjo-icon></div>
          <div
            class="add-title"
            [class.kenjo-font-weight-bold]="currentSection === 3"
            [class.kenjo-font-color-disabled-c4c4c4]="currentSection < 3"
            [class.add-clickable]="currentSection > 3"
            (click)="goToSection(3)"
          >
            {{ dialogTranslations.attendanceSection }}
          </div>
        </div>
        <div *ngIf="attendanceEnabled" class="add-line">
          <div></div>
        </div>
        <div class="add-step">
          <div class="add-icon" [class.add-completed]="currentSection >= 4"><kenjo-icon [size]="18">done</kenjo-icon></div>
          <div
            class="add-title"
            [class.kenjo-font-weight-bold]="currentSection === 4"
            [class.kenjo-font-color-disabled-c4c4c4]="currentSection < 4"
            [class.add-clickable]="currentSection > 4"
            (click)="goToSection(4)"
          >
            {{ dialogTranslations.activationSection }}
          </div>
        </div>
      </div>
    </div>

    <!-- SECTIONS -->
    <div class="add-current-section">
      <kenjo-add-employee-personal-step
        *ngIf="currentSection === 0"
        [translations]="dialogTranslations"
        [userPersonal]="userPersonal"
        (nextStep)="onNextStep()"
      ></kenjo-add-employee-personal-step>
      <kenjo-add-employee-work-step
        *ngIf="currentSection === 1"
        [translations]="dialogTranslations"
        [translatedWeekdays]="translatedWeekdays"
        [userWork]="userWork"
        [userWorkSchedule]="userWorkSchedule"
        [userAccount]="userAccount"
        [workScheduleTemplates]="workScheduleTemplates"
        [workScheduleTemplatesOptions]="workScheduleTemplatesOptions"
        [companies]="companies"
        [companiesOptions]="companiesOptions"
        [offices]="offices"
        [areas]="areas"
        [divisions]="divisions"
        [departments]="departments"
        [profiles]="profiles"
        [reportsToOptions]="reportsToOptions"
        [userProfile]="userProfile"
        [workScheduleConfiguration]="workScheduleConfiguration"
        [isTimeOffEnabled]="timeOffEnabled"
        [isTimeOffApproverActive]="isTimeOffApproverActive"
        (nextStep)="onNextStep()"
        (previousStep)="onPreviousStep()"
        (reloadTimeOffStep)="onReloadTimeOffStep()"
      >
      </kenjo-add-employee-work-step>
      <kenjo-add-employee-time-off-step
        *ngIf="currentSection === 2"
        [translations]="dialogTranslations"
        [timeOffTypes]="timeOffTypes"
        [originalTimeOffTypes]="originalOffTypes"
        [userWork]="userWork"
        [workScheduleConfiguration]="workScheduleConfiguration"
        [timeOffStepPermission]="timeOffStepPermission"
        [userWorkSchedule]="userWorkSchedule"
        [companies]="companies"
        [(assignments)]="assignments"
        (nextStep)="onNextStep()"
        (previousStep)="onPreviousStep()"
        (skipStep)="onReloadTimeOffStep(); onNextStep()"
      >
      </kenjo-add-employee-time-off-step>
      <kenjo-add-employee-attendance-step
        *ngIf="currentSection === 3"
        [translations]="dialogTranslations"
        [userAccount]="userAccount"
        [attendancePolicies]="attendancePolicies"
        [attendancePoliciesOptions]="attendancePoliciesOptions"
        (nextStep)="onNextStep()"
        (previousStep)="onPreviousStep()"
        (skipStep)="onNextStep()"
      >
      </kenjo-add-employee-attendance-step>
      <kenjo-add-employee-activation-step
        *ngIf="currentSection === 4"
        [attendanceEnabled]="attendanceEnabled"
        [datevOptions]="datevOptions"
        [translations]="dialogTranslations"
        [activationOptions]="activationOptions"
        [activationPermissions]="activationPermissions"
        [userWorkSchedule]="userWorkSchedule"
        (previousStep)="onPreviousStep()"
        (addEmployee)="onAddEmployee($event)"
      >
      </kenjo-add-employee-activation-step>
    </div>
  </div>
</div>
