import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IPositionListRow } from '@app/models/controllers/recruiting.model';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { environment } from '@env';

@Injectable()
export class RecruitingPositionListService {
  private RECRUITING_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting`;

  constructor(private injector: Injector) {}

  getPage(): Promise<Array<IPositionListRow>> {
    return new Promise<Array<IPositionListRow>>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());

      const httpOptions = {
        headers: httpHeaders
      };

      this.injector
        .get(HttpClient)
        .get<Array<IPositionListRow>>(`${this.RECRUITING_URL}/position-list`, httpOptions)
        .toPromise()
        .then((response: Array<IPositionListRow>) => {
          resolve(response);
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, RecruitingPositionListService.name, 'getPage'));
        });
    });
  }
}
