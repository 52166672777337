<orgos-container aspect="card" *ngIf="!filterSettingsSelected" class="rdw-overflown-unset">
  <orgos-column-container class="kenjo-border-bottom kenjo-pv-10px kenjo-ph-20px">
    <orgos-column size="0" class="kenjo-font-size-16px">{{ i18n.title }}</orgos-column>
    <orgos-column size="1"></orgos-column>
    <orgos-action *ngIf="internalUser" orgosColumn="0" class="kenjo-ml-20px" (click)="openFilterDicussionSettings()">{{ i18n.settings }}</orgos-action>
  </orgos-column-container>

  <kenjo-recruiting-discussion-comments
    *ngIf="visibleActivities && visibleActivities.length > 0"
    class="rccw-max-height kenjo-p-20px"
    #visibleActivitiesContainer
    [allowEditComments]="allowEditComments"
    [candidateId]="candidate?._id"
    [positionId]="positionId"
    [allActivity]="visibleActivities"
    (commentSaved)="onCommentSaved()"
  ></kenjo-recruiting-discussion-comments>
  <div class="kenjo-p-20px kenjo-border-top">
    <orgos-input-simple-editor
      [mentionOptions]="comment.private === false && !agency ? mentionOptions : null"
      [label]="i18n.writeComment"
      [(value)]="comment.comment"
      (valueChange)="commentChange()"
      [readOnly]="creatingComment"
      theme="bubble"
      (validation)="commentValidation = $event"
      class="kenjo-block"
    ></orgos-input-simple-editor>
    <orgos-page-message *ngIf="showNewHiringTeamMembersWarning" messageType="warning" [messageText]="newHiringTeamMembersMessage"></orgos-page-message>
    <div class="kenjo-mt-20px kenjo-text-align-right">
      <orgos-button-raised [disabled]="creatingComment || !comment.comment || !commentValidation || commentValidation.hasErrors()" (click)="createComment()"> {{ creatingComment === false ? i18n.createCommentButton : i18n.creatingCommentButton }}</orgos-button-raised>
    </div>
  </div>

  <orgos-column-container *ngIf="visibleActivities && visibleActivities.length === 0" centerColumns="true" class="kenjo-p-40px">
    <orgos-column size="1"></orgos-column>
    <kenjo-icon orgosColumn="0" [size]="22" class="kenjo-mr-5px kenjo-font-color-light-text-757575">forum </kenjo-icon>
    <orgos-column size="0" class="kenjo-font-color-light-text-757575">
      {{ agencySelected === false ? (this.comment.private === true ? i18n.noPrivateComments : i18n.noTeamComments) : i18n.noAgencyComments }}
    </orgos-column>
    <orgos-column size="1"></orgos-column>
  </orgos-column-container>
</orgos-container>
<kenjo-recruiting-discussion-comments-settings *ngIf="filterSettingsSelected" [i18n]="i18n" [filterSettings]="filterSettings.preference" (updatedSettingsFinished)="updateFilterDiscussionSettings($event)"></kenjo-recruiting-discussion-comments-settings>
