import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { environment } from '@env';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanAttendanceService {
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/shiftplan/attendance`;
  private SHIFTPLAN_ATTENDANCE_SERVICE: string = 'ShiftPlanAttendanceService';

  constructor(
    private http: HttpClient,
    private injector: Injector
  ) {}

  async getAttendanceData(payload: IShiftplanAttendanceRequestBody): Promise<IShiftplanAttendanceSummary> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(
          'PROGRAMMING ERROR',
          ErrorCodes.UNAUTHORIZED,
          this.SHIFTPLAN_ATTENDANCE_SERVICE,
          'getAttendanceData'
        );
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders,
      };
      return await this.http.post<IShiftplanAttendanceSummary>(`${this.CONTROLLER_URL}/find`, { ...payload }, httpOptions).toPromise();
    } catch {}
  }

  defaultAttendanceData() {
    return { currentOvertimeBalance: 0, trackedTime: 0, totalOvertime: 0 };
  }
}

export interface IShiftplanAttendanceSummary {
  [employeeId: string]: boolean | IShiftplanAttendanceData;
}

export interface IShiftplanAttendanceData {
  _id?: string;
  userId: string;
  currentOvertimeBalance: number;
  totalOvertime: number;
  trackedTime: number;
}

export interface IShiftplanAttendanceRequestBody {
  startDay: moment.Moment;
  userIds: Array<string>;
  onlyOvertime: boolean;
}
