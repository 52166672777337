<orgos-container *ngIf="pageTranslations" aspect="card" class="kenjo-mb-10px kenjo-p-20px">
  <orgos-column-container centerColumns="true">
    <orgos-column size="1" class="kenjo-mr-30px prtdc-title">
      <div>
        <div class="kenjo-font-size-14px">
          <ng-container *ngIf="!feedbackTypeSubtitle">{{ pageTranslations[toDo.name] }}</ng-container>
          <ng-container *ngIf="feedbackTypeSubtitle">{{ feedbackTypeSubtitle }}</ng-container>
        </div>
        <div class="kenjo-font-color-light-text-757575 kenjo-mt-15px kenjo-font-size-14px">
          <kenjo-truncate-text>{{ toDo.performanceReviewTitle }}</kenjo-truncate-text>
        </div>
      </div>
    </orgos-column>
    <orgos-column size="1">
      <orgos-chip [color]="statusColor">{{ pageTranslations[toDo.status] }}</orgos-chip>
      <div
        *ngIf="dueDateTranslation"
        class="prtdc-due-date-container kenjo-mt-10px"
        [ngClass]="{
          'kenjo-font-color-danger-ff5757': isOverdue && toDo.status !== 'completed',
          'kenjo-font-color-light-text-757575': !isOverdue || toDo.status === 'completed'
        }"
      >
        <kenjo-icon>clock</kenjo-icon>
        <div class="kenjo-ml-10px">{{ dueDateTranslation }} {{ pageTranslations.agendaRuntime }}</div>
      </div>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised *ngIf="pageTranslations[toDo.actionType]" (click)="onActionButtonClick()">{{
        pageTranslations[toDo.actionType]
      }}</orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-container>
