<div>
  <orgos-page-message *ngIf="listPositionCandidates && listPositionCandidates.length > 1" messageType="warning" [messageText]="multipleRecipientsClarificationText" class="kenjo-mb-20px"></orgos-page-message>

  <orgos-column-container centerColumns="true">
    <orgos-column size="1">
      <orgos-input-simple-text [value]="emailAddressesOfCandidates" [readOnly]="true" [label]="dialogTranslation.toField" [matTooltip]="tooltipAddress" class="kenjo-block"></orgos-input-simple-text>
    </orgos-column>
    <orgos-column *ngIf="ccEnabled === false" size="0" class="kenjo-mh-10px">
      <a (click)="ccEnabled = true">{{dialogTranslation.ccButton}}</a>
    </orgos-column>
    <orgos-column *ngIf="bccEnabled === false" size="0">
      <a (click)="bccEnabled = true">{{dialogTranslation.bccButton}}</a>
    </orgos-column>
  </orgos-column-container>

  <orgos-input-simple-text *ngIf="ccEnabled === true" [(value)]="ccReceivers" [label]="dialogTranslation.ccButton" [matTooltip]="dialogTranslation.emailSeparator" class="kenjo-block"></orgos-input-simple-text>

  <orgos-input-simple-text *ngIf="bccEnabled === true" [(value)]="bccReceivers" [label]="dialogTranslation.bccButton" [matTooltip]="dialogTranslation.emailSeparator" class="kenjo-block"></orgos-input-simple-text>

  <orgos-input-simple-text [(value)]="emailSubject" [label]="dialogTranslation.subject" [readOnly]="readOnlySubject" required="true" (validation)="subjectValidation = $event" class="kenjo-block">
    <orgos-input-error *ngIf="subjectValidation && subjectValidation.getError('required')"> {{dialogTranslation.subjectRequired}}</orgos-input-error>
  </orgos-input-simple-text>

  <orgos-input-simple-select *ngIf="listTemplates" [(value)]="emailTemplateChosen" (valueChange)="applyEmailTemplate()" [options]="listTemplates" [label]="dialogTranslation.selectTemplate"></orgos-input-simple-select>

  <orgos-input-simple-editor #bodyQuillEditor [readOnly]="calculatingEmailTemplate" [(value)]="emailBody" [label]="dialogTranslation.body" required="true" class="kenjo-block kenjo-mt-20px" size="medium" (validation)="bodyValidation = $event">
    <orgos-input-error *ngIf="bodyValidation && bodyValidation.getError('required')">{{dialogTranslation.bodyRequired}} </orgos-input-error>
  </orgos-input-simple-editor>

  <div *ngIf="attachments && attachments.length > 0" class="kenjo-mt-10px">
    <!-- ATTACHMENTS START HERE -->
    <div class="ced-attachment-title">{{dialogTranslation.attachmentsTitle | i18nData:{nAttachments: attachments.length} }}</div>
    <div class="kenjo-mt-10px">
      <orgos-column-container class="ced-attachment-container kenjo-pv-5px kenjo-ph-10px kenjo-border kenjo-color-grey-background-lighter-f8f8f8 kenjo-mr-10px kenjo-mb-10px" centerColumns="true" wrap="true" *ngFor="let iDocument of attachments">
        <orgos-column size="0" class="kenjo-mr-10px">
          <orgos-document-icon size="small" [extension]="iDocument._fileExtension"> </orgos-document-icon>
        </orgos-column>
        <orgos-column>
          <a (click)="downloadAttachment(iDocument)">
            <kenjo-truncate-text class="ced-attachment-text">{{iDocument._fileName}}</kenjo-truncate-text>
          </a>
        </orgos-column>
        <kenjo-icon orgosColumn="0" [size]="15" (click)="deleteAttachment(iDocument)" class="kenjo-font-color-danger-ff5757 kenjo-cursor-pointer kenjo-ml-10px" [matTooltip]="dialogTranslation.deleteAttachmentTooltip">cancel</kenjo-icon>
      </orgos-column-container>
    </div>
    <!-- ATTACHMENTS END HERE -->
  </div>

  <orgos-column-container class="kenjo-mt-20px">
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-action (click)="addAttachment()" color="Primary" icon="attach_file" [matTooltip]="dialogTranslation.attachFiles">{{dialogTranslation.attachFiles}}</orgos-action>
    </orgos-column>
    <orgos-column size="0" class="kenjo-ml-20px" [matTooltip]="!loggedUserWork || !loggedUserWork.emailSignature || !loggedUserWork.emailSignature.html || loggedUserWork.emailSignature.html === '<p><br></p>' ? dialogTranslation.signatureIsDisabled : ''">
      <orgos-action (click)="addSignature()" color="Primary" icon="signature" [disabled]="!loggedUserWork || !loggedUserWork.emailSignature || !loggedUserWork.emailSignature.html || loggedUserWork.emailSignature.html === '<p><br></p>'"> {{dialogTranslation.addSignature}}</orgos-action>
    </orgos-column>
  </orgos-column-container>
</div>

<orgos-column-container class="kenjo-mt-60px" centerColumns="true">
  <orgos-column size="1"></orgos-column>
  <orgos-column size="0" *ngIf="sendLater">
    <orgos-input-simple-date-picker class="ced-date" required="true" orgosColumn="1" [(value)]="emailDate" [label]="dialogTranslation.chooseDateSchedule" [min]="minDate" (valueChange)="reevaluateEmailTimeOptions()"> </orgos-input-simple-date-picker>
  </orgos-column>
  <orgos-column size="0" *ngIf="sendLater" class="kenjo-ml-20px">
    <orgos-input-simple-select class="ced-time" orgosColumn="1" required="true" [(value)]="emailTimeValue" [options]="emailTimeOptions" [label]="dialogTranslation.chooseTimeSchedule" [autoOrder]="false"> </orgos-input-simple-select>
  </orgos-column>
  <orgos-column size="0" class="kenjo-ml-20px">
    <orgos-column-container centerColumns="true" [matMenuTriggerFor]="filterMenu" class="kenjo-cursor-pointer">
      <orgos-column size="0"> {{sendLater ? dialogTranslation.sendLater : dialogTranslation.sendImmediately}} </orgos-column>
      <kenjo-icon orgosColumn="0" [size]="24">arrow_drop_down</kenjo-icon>
    </orgos-column-container>
    <mat-menu #filterMenu="matMenu">
      <button mat-menu-item (click)="sendLater = false">{{dialogTranslation.sendImmediately}}</button>
      <button mat-menu-item (click)="sendLater = true">{{dialogTranslation.sendLater}}</button>
    </mat-menu>
  </orgos-column>
  <orgos-column size="0" class="kenjo-ml-20px">
    <orgos-button-raised color="Success" (click)="sendEmail()" [disabled]="sendingEmail || !subjectValidation || subjectValidation.hasErrors() || !bodyValidation || bodyValidation.hasErrors() || !validateSchedule()"> {{dialogTranslation.sendNowButton}}</orgos-button-raised>
  </orgos-column>
</orgos-column-container>
