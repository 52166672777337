<div class="kenjo-p-40px tosc-content">
  <div class="tosc-header">
    <div class="kenjo-font-weight-bold kenjo-font-size-16px">{{ translations.timeOffHeader }}</div>
    <orgos-action (click)="onSkipStep()" [matTooltip]="translations.skipStepTooltip" matTooltipPosition="above">{{
      translations.skipStepLabel }}</orgos-action>
  </div>

  <div *ngIf="timeOffStepPermission && !(timeOffTypes?.length > 0)"
    class="kenjo-pv-40px kenjo-text-align-center tosc-empty">
    <div class="kenjo-empty-state kenjo-text-align-center kenjo-mt-80px kenjo-mb-20px">
      <img src="/assets/images/emptyState.svg" />
    </div>
    <div class="kenjo-font-size-14px">{{ translations.noTimeOffPolicies }}</div>
  </div>

  <div *ngIf="!timeOffStepPermission" class="kenjo-pv-40px kenjo-text-align-center">
    <img src="/assets/images/lock.svg" class="kenjo-mr-10px" />
    <div class="kenjo-mt-10px kenjo-font-size-16px">
      {{ translations.unauthorizedTimeOffStepTitle }}
    </div>
    <div class="kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-mt-5px kenjo-mb-20px">
      {{ translations.unauthorizedTimeOffStepSubtitle }}
    </div>
  </div>

  <orgos-container aspect="card" class="mpd-manage-user-policies-card" *ngIf="timeOffTypes?.length > 0">
    <kenjo-manage-user-policies
      [timeOffTypes]="timeOffTypes"
      [originalTimeOffTypes]="originalTimeOffTypes"
      [userStartDate]="userWork.data?.startDate"
      [contractEnd]="userWork.data?.contractEnd"
      [userWork]="userWork"
      [userWorkSchedule]="userWorkSchedule"
      (assignments)="onAssignmentsChange($event)" class="wizard">
    </kenjo-manage-user-policies>
  </orgos-container>

  <div class="tosc-button kenjo-mt-40px">
    <orgos-button-raised (click)="onPreviousStep()" color="Neutral">{{ translations.backLabel }}</orgos-button-raised>
    <orgos-button-raised (click)="onNextStep()" color="Success" [disabled]="!(assignments.length > 0)">{{
      translations.nextLabel }}</orgos-button-raised>
  </div>
</div>