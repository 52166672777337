import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IDatevSettingsModel } from '@app/cloud-features/settings-datev/models/datev-settings.model';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { UserController } from '@app/standard/services/user/controllers/user.controller';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class DatevSettingsService implements IGenericService {
  private DATEV_SETTINGS_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/datev-settings-db`;
  private DATEV_SETTINGS_PERMISSIONS_KEY: string = 'datev-settings';

  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getById(id: string, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async updateById(id: string, data: any, operationOptions?: any): Promise<void> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.DATEV_SETTINGS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, UserController.name, 'getUserPersonalsByUserWork');
        throw this.errorManager.handleRawError(error, UserController.name, 'getUserPersonalsByUserWork');
      }

      await this.genericService.updateById(this.DATEV_SETTINGS_MICROSERVICE_URL, id, data);
    } catch (error) {
      throw this.errorManager.handleRawError(error, DatevSettingsService.name, 'updateById');
    }
  }

  deleteById(id: string, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  getPermissions(operationOptions?: any): Promise<any> {
    return this.genericService.getPermissions(this.DATEV_SETTINGS_PERMISSIONS_KEY);
  }

  getFieldsTranslations(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async find(findQuery?: any): Promise<Array<IDatevSettingsModel>> {
    try {
      if (this.injector.get(PrivateAuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError(this.DATEV_SETTINGS_MICROSERVICE_URL, ErrorCodes.UNAUTHORIZED, UserController.name, 'find');
        throw this.errorManager.handleRawError(error, UserController.name, 'find');
      }

      const datevSettings = await this.injector
        .get(HttpClient)
        .post<Array<IDatevSettingsModel>>(`${this.DATEV_SETTINGS_MICROSERVICE_URL}/find`, findQuery ?? { _id: { $ne: null } })
        .toPromise();

      return datevSettings;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, DatevSettingsService.name, 'find');
    }
  }
}
