import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { IWidgetSettingsModel } from '@app/cloud-features/settings-who-is-away/models/widget-settings.model';
import { WidgetSettingsService } from '@app/cloud-features/settings-who-is-away/services/widget-settings.service';
import { WhoIsAwayWidgetDetailsDialog } from '@app/common-components/widgets/who-away/dialogs/who-is-away-widget-details.dialog';
import { IAwayRequest } from '@app/models/controllers/who-is-away.model';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { WidgetsService } from '@app/standard/services/widgets/widgets.service';
import * as picklistConstants from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'orgos-widget-who-away',
  templateUrl: 'orgos-widget-who-away.component.html',
  styleUrls: ['orgos-widget-who-away.component.scss'],
})
export class WidgetWhoAwayComponent implements OnInit {
  translation: any = {};
  loadingWidget: boolean = false;
  awayRequests: Array<Array<IAwayRequest>> = [];
  userSelected: IAwayRequest;
  widgetSettings: IWidgetSettingsModel;
  configurationKeys: Array<string>;
  allUsers: Array<any>;
  noRequestsToShow = false;

  MY_TEAM_TRANSLATION_KEY_FAVORITES = picklistConstants.MY_TEAM_TRANSLATION_KEY_FAVORITES;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.loadingWidget = true;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('home-widget-who-away-component')
      .then((translation) => {
        this.translation = translation;
      })
      .catch(() => {
        this.translation = {};
      });

    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    try {
      this.widgetSettings = await this.injector.get(WidgetSettingsService).find('whoIsAway');
      const whoIsAway = await this.injector.get(WidgetsService).getWhoIsAway();
      this.configurationKeys = whoIsAway.configurationKeys;
      this.awayRequests = this.organizeAllUsersAway(whoIsAway.awayRequests);
    } catch (_) {
      this.awayRequests = [];
    } finally {
      this.noRequestsToShow = this.awayRequests.length === 0 || this.awayRequests?.every((request) => request?.length === 0);
      this.loadingWidget = false;
    }
  }

  private organizeAllUsersAway(allUsersAway: Array<any>): Array<Array<IAwayRequest>> {
    const organized = new Array<Array<IAwayRequest>>();
    for (let iDay = 0; iDay < 30; iDay++) {
      const dayStart = this.injector.get(InternationalizationService).getSanitizedUTCToTimeZone(moment.utc().add(iDay, 'day'));
      const dayEnd = this.injector.get(InternationalizationService).getSanitizedUTCToTimeZone(moment.utc().add(iDay, 'day')).endOf('day');
      const usersAway = iDay > 7 ? organized.pop() : [];
      allUsersAway.forEach((iUserAway) => {
        const hasUserNotAdded = usersAway.every((iUserAlreadyAdded) => {
          return iUserAlreadyAdded._id !== iUserAway._id;
        });

        if (hasUserNotAdded && moment.utc(iUserAway.from).isSameOrBefore(dayEnd) && moment.utc(iUserAway.to).isSameOrAfter(dayStart)) {
          usersAway.push(iUserAway);
        }
      });
      organized.push(usersAway);
    }
    const userDay = organized.find((user) => {
      return check.not.emptyArray(user);
    });

    this.userSelected = userDay?.[0];

    return organized;
  }

  getDayTitle(day: number): string {
    if (day === 0) {
      return this.translation.todayTitle;
    } else if (day === 1) {
      return this.translation.tomorrowTitle;
    } else if (day >= 7) {
      return this.translation.upcomingTitle;
    } else {
      const isoStringDate = moment.utc().add(day, 'day').toISOString();
      return this.injector.get(DatePipe).transform(isoStringDate, 'fullDate');
    }
  }

  getFormattedDuration(userRequest: IAwayRequest): string {
    const fromMoment = moment.utc(userRequest.from);
    const toMoment = moment.utc(userRequest.to);
    const minutes = toMoment.diff(fromMoment, 'minutes');
    const duration = this.injector.get(DurationPipe).transform(minutes);
    return `${this.translation.from2} ${fromMoment.format('LT')} ${this.translation.to2} ${toMoment.format('LT')} (${duration})`;
  }

  isOnlyOneDay(userRequest: IAwayRequest): boolean {
    return moment.utc(userRequest.from).isSame(moment.utc(userRequest.to), 'day');
  }

  isHourTimeOff(userRequest: IAwayRequest) {
    return picklistConstants.POLICY_TYPE_HOUR === userRequest._policyType && !userRequest.partOfDayFrom;
  }

  areUsersAway(): boolean {
    if (check.not.assigned(this.awayRequests) || check.not.array(this.awayRequests) || check.emptyArray(this.awayRequests)) {
      return false;
    }

    const someUserAway = this.awayRequests.some((iUsersPerDay) => {
      return check.assigned(iUsersPerDay) && check.array(iUsersPerDay) && check.nonEmptyArray(iUsersPerDay);
    });

    return someUserAway;
  }

  public navigateToPersonalPage(userId: string): void {
    this.injector.get(Router).navigateByUrl(`cloud/people/${userId}/personal`);
  }

  public openDetailsDialog(): void {
    const data = {
      configurationKeys: [...this.configurationKeys],
      myTeamWidgetSetting: { ...this.widgetSettings },
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(WhoIsAwayWidgetDetailsDialog, { data });
    dialogRef.afterClosed().subscribe((updated: any) => {
      if (updated) {
        this.fetchData();
      }
    });
  }
}
