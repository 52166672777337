import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';

@Component({
  selector: 'kenjo-button-semantic',
  templateUrl: 'button-semantic.component.html',
  standalone: true,
  imports: [CommonModule, IconModule],
  styleUrls: ['button-semantic.component.scss'],
})
export class ButtonSemanticComponent {
  @Input() icon: string;
  @Input() isVisible: boolean = true;
  @Input() isActive: boolean = false;
  @Input() type: 'danger' | 'success' | 'warning' | 'caution' | 'neutral' = 'danger';
  @Input() width: number;
  @Input()
  set size(value: 'S' | 'M' | 'L' | 'XL') {
    if (value === 'S' || value === 'M' || value === 'L' || value === 'XL') {
      this._size = value;
    } else {
      this._size = 'M';
    }
  }
  get size(): 'S' | 'M' | 'L' | 'XL' {
    return this._size;
  }
  private _size: 'S' | 'M' | 'L' | 'XL';

  constructor() {}
}
