import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { IAudienceVisibility } from '@app/standard/components/audience-selector/services/audience-selector.component.service';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { DocumentTagService, IDocumentTagModel } from '@app/standard/services/document/document-tag.service';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as Chance from 'chance';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'orgos-edit-tag-dialog',
  templateUrl: 'edit-tag.dialog.html',
  styleUrls: ['edit-tag.dialog.scss'],
})
export class EditTagDialog implements OnInit {
  isEdit: boolean = false;
  dialogTranslation: any = {};
  documentTagCollectionTranslation: any = {};
  tag: IDocumentTagModel;
  dataIsCompleted: boolean = false;
  nameValidation: InputValidation;
  predefinedTag: boolean = false;

  constructor(
    public dialogRef: MatLegacyDialogRef<EditTagDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private snackBar: MatLegacySnackBar,
    private injector: Injector,
    private i18nDataPipe: I18nDataPipe,
    private tagService: DocumentTagService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    await this.fetchTranslations();

    const chance = new Chance();
    if (this?.data?.predefined === false) {
      this.isEdit = true;
      this.tag = _.cloneDeep(this.data);
      this.dataIsCompleted = true;
    } else if (check.assigned(this.data) && (this.data.custom === false || this.data.predefined)) {
      this.predefinedTag = true;
      this.tag = _.cloneDeep(this.data);
      this.tag.visibleToEveryone = check.assigned(this.tag.visibleToEveryone) ? this.tag.visibleToEveryone : true;
      this.isEdit = true;
      this.dataIsCompleted = true;
    } else {
      this.tag = {
        name: '',
        color: chance.pickone(Object.keys(userColorConstants)),
        visibleToEveryone: true,
        visibleToSpecific: {},
      };
    }
  }

  async fetchTranslations() {
    try {
      [this.dialogTranslation, this.documentTagCollectionTranslation] = await Promise.all([
        this.injector.get(InternationalizationService).getAllTranslation('document-add-tag-dialog'),
        this.injector.get(InternationalizationService).getAllTranslation('document-tag-collection'),
      ]);
    } catch {
      this.dialogTranslation = {};
      this.documentTagCollectionTranslation = {};
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  checkIfDataIsCompleted(): void {
    this.dataIsCompleted = false;
    if (check.emptyString(this.tag.name)) {
      return;
    }

    if (this.tag.visibleToEveryone) {
      this.dataIsCompleted = true;
      return;
    }

    this.dataIsCompleted = this.validateTagVisibility(this.tag.visibleToSpecific);
  }

  private validateTagVisibility(tagVisibility: IAudienceVisibility): boolean {
    if (!tagVisibility) {
      return false;
    }

    if (
      !tagVisibility?.areaIds?.length &&
      !tagVisibility?.companyIds?.length &&
      !tagVisibility?.departmentIds?.length &&
      !tagVisibility?.officeIds?.length &&
      !tagVisibility?.teamIds?.length &&
      !tagVisibility?.userIds?.length
    ) {
      return false;
    }

    return true;
  }

  async addTag() {
    try {
      if (check.not.assigned(this.nameValidation) || this.nameValidation.hasErrors()) {
        return;
      }

      const createdTag = await this.tagService.create(this.tag);

      const tagAddedSnackText = this.i18nDataPipe.transform(this.dialogTranslation.tagAddedSnackText, createdTag);
      this.snackBar.open(tagAddedSnackText, 'OK', {
        duration: 5000,
      });

      this.dialogRef.close(createdTag);
    } catch {
      // An error is shown
    }
  }

  async editTag() {
    try {
      if (check.not.assigned(this.nameValidation) || this.nameValidation.hasErrors()) {
        return;
      }

      let editedTag = { ...this.tag };
      if (this.tag.predefined) {
        delete editedTag.name;
        delete editedTag.color;
      }

      await this.tagService.updateById(this.tag._id, editedTag);

      const tagEditedSnackText = this.i18nDataPipe.transform(this.dialogTranslation.tagEditedSnackText, this.tag);
      this.snackBar.open(tagEditedSnackText, 'OK', {
        duration: 5000,
      });
      this.dialogRef.close(this.tag);
    } catch {
      // An error is shown
    }
  }
}
