<div class="imp-month-picker-container" (orgosClickOutside)="clickOutside()">
  <div
    (click)="isDialogOpened = disabled ? false : true"
    (mouseenter)="isDateOnHover = true"
    (mouseleave)="isDateOnHover = false"
    class="kenjo-font-size-18px kenjo-cursor-pointer selected-month kenjo-font-color-primary-5993e3"
    [class.kenjo-font-size-14px]="label"
    [ngClass]="{ 'kenjo-font-color-disabled-c4c4c4': disabled, 'text-underlined': isDateOnHover }"
  >
    <div *ngIf="!label">
      {{ currentDateToShow | titlecase }}
    </div>

    <orgos-input-simple-text *ngIf="label" [label]="label" [value]="currentDateToShow | titlecase" [required]="true" [nonClick]="true"> </orgos-input-simple-text>
  </div>

  <div *ngIf="isDialogOpened" class="dialog-box kenjo-color-white-background-ffffff kenjo-pt-5px kenjo-border-radius">
    <orgos-column-container centerColumns="true" class="imp-year-selector kenjo-border-bottom">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <kenjo-icon [size]="30" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="changeYear(DECREMENT_YEAR)">keyboard_arrow_left</kenjo-icon>
      </orgos-column>
      <orgos-column size="0" class="kenjo-mh-60px kenjo-font-size-16px">
        {{ currentSelectedYear }}
      </orgos-column>
      <orgos-column size="0">
        <kenjo-icon [size]="30" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="changeYear(INCREMENT_YEAR)">keyboard_arrow_right</kenjo-icon>
      </orgos-column>
      <orgos-column size="1"></orgos-column>
    </orgos-column-container>
    <div
      *ngFor="let month of months; let i = index"
      [matTooltip]="tooltipMessage"
      [matTooltipDisabled]="!((currentSelectedYear === maxYear && i > maxMonth) || currentSelectedYear > maxYear)"
      [ngClass]="{ 'kenjo-border-right': (i + 1) % 3 !== 0, 'kenjo-pb-5px': i > 8 }"
      class="imp-month-container kenjo-inline-block kenjo-ph-10px kenjo-pt-5px kenjo-text-align-center"
    >
      <div
        (click)="selectMonth(month)"
        (mouseenter)="monthOnHover = month"
        (mouseleave)="monthOnHover = null"
        [ngClass]="{
          'kenjo-mt-5px': i < 3,
          'kenjo-mb-5px': i > 8,
          'kenjo-font-color-light-text-757575': monthOnHover !== month && month !== currentSelectedMonthName && !((currentSelectedYear === maxYear && i > maxMonth) || currentSelectedYear > maxYear),
          'kenjo-font-color-primary-5993e3': monthOnHover === month || (month === currentSelectedMonthName && !((currentSelectedYear === maxYear && i > maxMonth) || currentSelectedYear > maxYear)),
          'kenjo-color-grey-background-lighter-f8f8f8': monthOnHover === month || (month === currentSelectedMonthName && !((currentSelectedYear === maxYear && i > maxMonth) || currentSelectedYear > maxYear)),
          'kenjo-font-color-disabled-c4c4c4': (currentSelectedYear === maxYear && i > maxMonth) || currentSelectedYear > maxYear
        }"
        class="kenjo-cursor-pointer kenjo-pv-5px kenjo-font-size-14px kenjo-border-radius"
      >
        {{ month }}
      </div>
      <div *ngIf="i <= 8" class="kenjo-border-bottom kenjo-mt-5px"></div>
    </div>
  </div>
</div>
