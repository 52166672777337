import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import {
  IUserEmploymentContractTypeModel,
  UserEmploymentContractTypeService,
} from '@app/standard/services/user/user-employment-contract-type.service';
import * as check from 'check-types';

@Component({
  selector: 'user-employment-contract-type-dialog',
  templateUrl: './user-employment-contract-type-dialog.html',
  styleUrls: ['./user-employment-contract-type-dialog.scss'],
})
export class UserEmploymentContractTypeDialog implements OnInit {
  pageTranslation: any = {};
  contractType: IUserEmploymentContractTypeModel;
  inputValidation: InputValidation;
  dialogTitle: string = '';

  constructor(
    private dialogRef: MatLegacyDialogRef<UserEmploymentContractTypeDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: IUserEmploymentContractTypeModel,
    private injector: Injector
  ) {}

  get conflictErrorCode() {
    return ErrorCodes.CONFLICT;
  }

  ngOnInit(): void {
    this.inputValidation = new InputValidation();
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('user-employment-contract-type-dialog')
      .then((pageTranslation: any) => {
        this.pageTranslation = pageTranslation;
        this.dialogTitle = check.assigned(this.data)
          ? this.injector.get(I18nDataPipe).transform(pageTranslation.editContractType, { name: this.data.name })
          : pageTranslation.addContractType;
      })
      .catch(() => {
        this.pageTranslation = {};
      });
    this.contractType = check.assigned(this.data)
      ? this.data
      : {
          name: '',
          isDefault: false,
          isActive: true,
        };
  }

  /**
   * Saves the changes to the database. If there is _id, we are editing, else we are creating
   */
  async save(): Promise<void> {
    if (check.assigned(this.contractType._id)) {
      try {
        this.injector.get(UserEmploymentContractTypeService).updateById(this.contractType._id, this.contractType);
        this.dialogRef.close();
      } catch (e: any) {}
    } else {
      try {
        this.injector.get(UserEmploymentContractTypeService).create(this.contractType);
        this.dialogRef.close();
      } catch (e: any) {}
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
