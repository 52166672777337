// tslint:disable: max-classes-per-file

/* CODE BASED ON: https://material.angular.io/guide/creating-a-custom-form-field-control */

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyFormFieldControl } from '@angular/material/legacy-form-field';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

/** Data structure for holding time entry. */
export class ShiftPlanCapacityTimeEntry {
  constructor(public hour: string, public minute: string) {}
}

@Component({
  selector: 'kenjo-shift-plan-input-capacity-time-form-control',
  templateUrl: 'input-capacity-time-form-control.component.html',
  styleUrls: ['input-capacity-time-form-control.component.scss'],
  providers: [{ provide: MatLegacyFormFieldControl, useExisting: InputCapacityFormControlComponent }],
})
export class InputCapacityFormControlComponent implements MatLegacyFormFieldControl<ShiftPlanCapacityTimeEntry>, OnDestroy, OnInit {
  static nextId: number = 0;
  parts: UntypedFormGroup;
  stateChanges: Subject<void> = new Subject<void>();
  focused: boolean = false;
  controlType: string = 'ictfc-input';
  hourFocused: boolean = false;
  minuteFocused: boolean = false;
  initValue: boolean = false;

  @HostBinding() id: string = `ictfc-input-${InputCapacityFormControlComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy: string = '';

  @ViewChild('hour', { static: true }) hour: any;
  @ViewChild('minute', { static: true }) minute: any;

  @Output() blurTimeInput: EventEmitter<void> = new EventEmitter<void>();

  get empty(): boolean {
    const {
      value: { hour, minute },
    } = this.parts;

    return !hour && !minute;
  }

  errorState: boolean = false;
  ngControl: NgControl | null = null;

  @HostBinding('class.ictfc-floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  private _placeholder: string;
  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  get placeholder(): string {
    return this._placeholder;
  }

  private _required: boolean = false;
  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  get required(): boolean {
    return this._required;
  }

  private _disabled: boolean = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() maskPlaceholder: string | null = null;

  private _prevValue: ShiftPlanCapacityTimeEntry;
  @Input()
  set value(time: ShiftPlanCapacityTimeEntry) {
    const defaultTimeEntry = new ShiftPlanCapacityTimeEntry('', '');

    this._prevValue = check.assigned(time) && time.hour !== 'null' && time.minute !== 'null' ? time : defaultTimeEntry;

    this.parts.setValue({ hour: this._prevValue.hour, minute: this._prevValue.minute });
    this.stateChanges.next();

    if (this.initValue === true) {
      this.valueChange.emit(this._prevValue);
    }
  }

  get value(): ShiftPlanCapacityTimeEntry {
    return this._prevValue;
  }

  @Output() valueChange: EventEmitter<ShiftPlanCapacityTimeEntry> = new EventEmitter<ShiftPlanCapacityTimeEntry>();

  constructor(fb: UntypedFormBuilder, private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>, private injector: Injector) {
    this.parts = fb.group({
      hour: '',
      minute: '',
    });

    fm.monitor(elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.initValue = true;
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
  }

  setDescribedByIds(ids: Array<string>): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input')!.focus();
    }
  }

  onValueChange(type: 'hour' | 'minute', fromBlur?: boolean): void {
    if (this.focused === true && this.hourFocused === true && type === 'hour') {
      this.hourFocused = false;
      this.setValue(this.parts.value);
    }

    if (this.focused === true && this.minuteFocused === true && type === 'minute') {
      this.minuteFocused = false;
      this.setValue(this.parts.value);
    }

    if (this.focused === false && (this.parts.value.hour !== '' || this.parts.value.minute !== '')) {
      this.hourFocused = false;
      this.minuteFocused = false;

      const hour = this.parts.value.hour === '' ? '000' : this.parts.value.hour;
      const minute = this.parts.value.minute === '' ? '00' : this.parts.value.minute;

      this.setValue({ hour, minute });
    }

    if (fromBlur) {
      this.blurTimeInput.emit();
    }
  }

  onInput(type: 'hour' | 'minute'): void {
    if (type === 'hour' && this.parts.value.hour.length === 4 && this.minuteFocused === false) {
      this.onValueChange('hour');
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.minute.nativeElement.focus();
    }

    if (type === 'minute' && this.parts.value.minute.length === 2) {
      this.onValueChange('minute');
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.minute.nativeElement.blur();
    }
  }

  onFocus(type: 'hour' | 'minute'): void {
    if (type === 'hour') {
      this.hourFocused = true;
      this.hour.nativeElement.select();
    } else {
      this.minuteFocused = true;
      this.minute.nativeElement.select();
    }
  }

  onKeyPress(type: 'hour' | 'minute', event: KeyboardEvent): void {
    if (['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
      event.preventDefault();
    }

    if (event.key === 'ArrowRight' && type === 'hour') {
      this.minute.nativeElement.focus();
    }

    if (event.key === 'ArrowLeft' && type === 'minute') {
      this.hour.nativeElement.focus();
    }

    if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && type === 'hour') {
      const unparsedHour = this.parts.value?.hour ? Number(this.parts.value.hour) + (event.key === 'ArrowUp' ? 1 : -1) : 0;
      const hour = unparsedHour < 0 ? '999' : unparsedHour > 999 ? '000' : String(unparsedHour);

      this.setValue({ hour: hour.length === 1 ? `0${hour}` : hour, minute: this.parts.value.minute ?? '' });
    }

    if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && type === 'minute') {
      const unparsedMinute = this.parts.value?.minute ? Number(this.parts.value.minute) + (event.key === 'ArrowUp' ? 1 : -1) : 0;
      const minute = unparsedMinute < 0 ? '59' : unparsedMinute > 59 ? '00' : String(unparsedMinute);

      this.setValue({ hour: this.parts.value.hour ?? '', minute: minute.length === 1 ? `0${minute}` : minute });
    }
  }

  setValue(newValue: ShiftPlanCapacityTimeEntry): void {
    const auxValue = new ShiftPlanCapacityTimeEntry(newValue.hour ?? '', newValue.minute ?? '');

    if (auxValue.hour !== '' && Number(auxValue.hour) > 999) {
      auxValue.hour = '999';
    }

    if (auxValue.minute !== '' && Number(auxValue.minute) > 59) {
      auxValue.minute = '59';
    }

    this.value = auxValue;
  }
}
