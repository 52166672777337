<orgos-column-container>
  <orgos-column-container
    orgosColumn
    class="spc-scheduled-hours spc-capacity-container kenjo-font-size-12px"
    [class.spc-scroll-x-indicator]="hasScrolled"
    [class.sticky-month]="hasScrolled"
  >
    <div orgosColumn class="kenjo-pl-10px" [class.spc-font-size-10]="view === VIEWS.MONTH">
      <kenjo-truncate-text class="spc-text">{{ parentTranslation.scheduledCapacity }}</kenjo-truncate-text>
      <kenjo-truncate-text class="spc-text">{{ parentTranslation.plannedCapacity }}</kenjo-truncate-text>
    </div>
    <div orgosColumn="0" class="spc-edit-capacity kenjo-border-left" [class.kenjo-cursor-pointer]="!states?.dragging">
      <mat-icon (click)="openShiftPlanCapacityDialog()" class="kenjo-p-10px kenjo-font-size-18px kenjo-font-color-primary-5993e3">
        mode_edit
      </mat-icon>
    </div>
  </orgos-column-container>
  <orgos-column
    *ngFor="let plannedDayTime of plannedCapacityTimePerDay; let i = index"
    [attr.data-index]="i"
    class="spc-width-shifts-box spc-scheduled-hours"
    [class.monthly]="view === VIEWS.MONTH"
    [class.minimal]="minimal"
    [class.spc-border-radius-bottom-right-6px]="i === 6"
  >
    <div
      class="spc-align-center"
      [ngClass]="{ 'spc-font-size-10': view === VIEWS.MONTH && !minimal, 'spc-week-view': view === VIEWS.WEEK }"
    >
      <mat-icon [ngClass]="{ 'spc-mat-icon': view === VIEWS.MONTH && !minimal, 'spc-mat-icon-week-view': view === VIEWS.WEEK }"
        >person</mat-icon
      >
      <span class="spc-line-height" [class.kenjo-pl-5px]="minimal">{{ employeeCountByDays[i] }}</span>
    </div>
    <!--Capacity-->
    <orgos-chip
      *ngIf="!minimal"
      class="spc-height-18px"
      [class.kenjo-ml-10px]="view === VIEWS.WEEK"
      matTooltip="{{ workingTimeInMinutesPerDay[i] | timeShiftplan }} / {{ plannedDayTime | timeShiftplan }}"
      [color]="
        plannedDayTime === workingTimeInMinutesPerDay[i]
          ? chipColors.neutral
          : plannedDayTime > workingTimeInMinutesPerDay[i]
          ? chipColors.warning
          : chipColors.danger
      "
    >
      <div class="spc-line-height" [ngClass]="{ 'spc-font-size-10': view === VIEWS.MONTH, 'kenjo-font-size-12px': view === VIEWS.WEEK }">
        {{ (plannedDayTime === 0 ? 0 : workingTimeInMinutesPerDay[i] / plannedDayTime) | percent }}
      </div>
    </orgos-chip>
  </orgos-column>
</orgos-column-container>
