import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserCompensationService } from './user-compensation.service';

@Injectable()
export class UserEmploymentService implements IGenericService {
  private USER_EMPLOYMENT_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-employment-db`;
  private USER_EMPLOYMENT_INTERNATIONALIZATION: string = 'user-employment-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService, private userCompensationService: UserCompensationService) {}

  create(data: object): Promise<IUserEmploymentModel> {
    return new Promise<IUserEmploymentModel>((resolve, reject) => {
      this.genericService
        .create(this.USER_EMPLOYMENT_URL, data)
        .then((userEmployment: IUserEmploymentModel) => {
          resolve(userEmployment);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmploymentService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IUserEmploymentModel> {
    return new Promise<IUserEmploymentModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_EMPLOYMENT_URL, id)
        .then((userEmployment: IUserEmploymentModel) => {
          resolve(userEmployment);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmploymentService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_EMPLOYMENT_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmploymentService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.USER_EMPLOYMENT_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmploymentService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.userCompensationService.getPermissions();
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_EMPLOYMENT_INTERNATIONALIZATION);
  }

  getUserEmployments(userId: string): Promise<Array<IUserEmploymentModel>> {
    const findBody = {
      _userId: userId
    };

    return new Promise<Array<IUserEmploymentModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_EMPLOYMENT_URL, findBody)
        .then((userEmployments: Array<IUserEmploymentModel>) => {
          resolve(userEmployments);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserEmploymentService.name, 'getUserEmployments'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_EMPLOYMENT_URL);
  }
}

export interface IUserEmploymentModel {
  _id: string;
  _userId: string;
  startDate?: string;
  // This field displays as "working hours" in the HTML
  contract?: string;
  // This field displays as "employment type" in the html
  type?: string;
  comments?: string;
  userEmploymentContractType?: string;
  userEmploymentSubcategory?: string;
}
