// we hardcode the most popular domains because using the library freemail in angular is not possible
// due to it requiring common nodejs packages (fs and such, which webpack does not include)
export const freeDomains = {
  'gmail.com': true,
  'outlook.com': true,
  'yahoo.com': true,
  'hotmail.com': true,
  'icloud.com': true,
  'mail.ru': true,
  'proton.me': true,
  'protonmail.com': true,
  'zoho.com': true,
  'aol.com': true,
  'gmx.com': true,
  'yandex.ru': true,
  'mail.com': true,
  'fastmail.com': true,
  'yopmail.com': true,
  'tutanota.com': true,
  'hey.com': true,
  'bluewin.ch': true,
  'hushmail.com': true,
  'runbox.com': true,
  'libero.it': true,
  'rediffmail.com': true,
};
