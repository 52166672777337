<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogName" (clickCloseButton)="closeDialog()">
  <orgos-page-message
    *ngIf="noDocumentTags"
    messageType="warning"
    [messageText]="dialogTranslation.noDocumentTagsWarning"
    class="kenjo-mb-40px"
  ></orgos-page-message>
  <div class="kenjo-font-size-14px kenjo-mb-10px">{{ dialogTranslation.dialogDocSubtitle }}</div>
  <div class="ud-grey-border kenjo-pt-20px kenjo-ph-20px kenjo-mb-30px upd-matformfield-padding">
    <orgos-column-container
      centerColumns="true"
      class="kenjo-pv-10px kenjo-ph-20px kenjo-border kenjo-border-radius kenjo-color-grey-background-lighter-f8f8f8"
    >
      <orgos-document-icon orgosColumn="0" [extension]="getExtension()" class="kenjo-pr-20px"></orgos-document-icon>
      <orgos-output class="ud-output" orgosColumn="1" [model]="document" field="name"></orgos-output>
      <orgos-text orgosColumn="0" class="kenjo-pr-20px">.{{ getExtension() }}</orgos-text>
      <kenjo-icon orgosColumn="0" class="kenjo-font-size-18px kenjo-font-color-success-00b72e"> done </kenjo-icon>
    </orgos-column-container>

    <orgos-column-container class="kenjo-mt-10px">
      <orgos-input-text
        orgosColumn
        class="kenjo-mr-10px"
        [model]="document"
        field="name"
        [required]="true"
        (validation)="nameValidation = $event"
      >
        <orgos-input-error *ngIf="nameValidation && nameValidation.getError('required')">
          {{ dialogTranslation.nameRequired }}</orgos-input-error
        >
      </orgos-input-text>
      <orgos-input-date-picker orgosColumn class="kenjo-ml-10px" [model]="document" field="validUntil" [enableClearButton]="true">
      </orgos-input-date-picker>
    </orgos-column-container>
  </div>

  <div>
    <div *ngIf="!isCompanyDoc" class="kenjo-mt-10px kenjo-mb-5px kenjo-font-color-light-text-757575">{{ dialogTranslation.tag }}</div>
    <div *ngIf="isCompanyDoc" class="kenjo-mb-5px kenjo-font-size-14px">{{ dialogTranslation.dialogTagSubtitle }}</div>
    <div *ngIf="isCompanyDoc" class="kenjo-mb-20px kenjo-font-size-14px kenjo-font-color-light-text-757575">
      {{ dialogTranslation.companyDocsWarningTextBeforeLink }}
      <a [routerLink]="['/cloud/settings/documents']" target="_blank">{{ dialogTranslation.companyDocsWarningTextLink }}</a>{{
      dialogTranslation.companyDocsWarningTextAfterLink }}
    </div>
    <orgos-chip *ngIf="noDocumentTags" color="#757575" class="kenjo-text-align-center">
      <kenjo-truncate-text class="ud-tag-text kenjo-font-color-white-ffffff">{{ dialogTranslation.unlabelledTagName }}</kenjo-truncate-text>
    </orgos-chip>
    <orgos-column-container wrap="true">
      <orgos-chip
        orgosColumn="0"
        *ngFor="let tag of allDocumentTags"
        [color]="getTagColor(tag)"
        [buttonIconName]="isTagSelected(tag) === true ? 'clear' : 'add'"
        [class.kenjo-font-color-white-ffffff]="isTagSelected(tag) === true"
        [onClickButtonFunction]="toggleTag(tag)"
        class="kenjo-mv-5px kenjo-mr-10px"
        >{{ tag.name }}</orgos-chip
      >
    </orgos-column-container>
  </div>
  <div *ngIf="isCompanyDoc" class="kenjo-mt-30px kenjo-flex-horizontal kenjo-flex-gap-10px">
    <div>
      <orgos-input-simple-checkbox [value]="requestReadReceipt" (valueChange)="setRequestReadReceipt($event)"></orgos-input-simple-checkbox>
    </div>
    <div class="kenjo-flex-vertical kenjo-flex-gap-10px">
      <div class="kenjo-font-size-14px">{{ dialogTranslation.requestReadReceipt }}</div>
      <div class="kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ dialogTranslation.requestReadReceiptDescription }}</div>
    </div>
  </div>
  <orgos-column-container class="kenjo-mt-60px">
    <orgos-column size="1"></orgos-column>
    <orgos-column
      size="0"
      class="kenjo-ml-20px"
      [matTooltip]="(areTagsMandatory && document.data.tags.length === 0)? dialogTranslation.missingTagsToolTip : null"
    >
      <orgos-button-raised
        [disabled]="!nameValidation || nameValidation.hasErrors() || (areTagsMandatory && document.data.tags.length === 0)"
        color="Success"
        (click)="uploadDocument()"
      >
        {{ dialogTranslation.uploadButtonLabel }}</orgos-button-raised
      >
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
