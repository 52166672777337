import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class RecruitingRejectionReasonService {
  private RECRUITING_REJECTION_REASON_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-rejection-reason-db`;
  private RECRUITING_REJECTION_REASON_PERMISSIONS_KEY: string = 'recruiting-rejection-reason';
  private RECRUITING_REJECTION_REASON_INTERNATIONALIZATION: string = 'recruiting-rejection-reason-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IRejectionReasonModel> {
    return new Promise<IRejectionReasonModel>((resolve, reject) => {
      this.genericService
        .create(this.RECRUITING_REJECTION_REASON_URL, data)
        .then((rejectionReason: IRejectionReasonModel) => {
          resolve(rejectionReason);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingRejectionReasonService.name, 'create'));
        });
    });
  }

  find(findBody: object): Promise<Array<IRejectionReasonModel>> {
    return new Promise<Array<IRejectionReasonModel>>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_REJECTION_REASON_URL, findBody)
        .then((rejectionReasons: Array<any>) => {
          resolve(rejectionReasons);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingRejectionReasonService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: IRejectionReasonModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.RECRUITING_REJECTION_REASON_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingRejectionReasonService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.RECRUITING_REJECTION_REASON_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingRejectionReasonService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.RECRUITING_REJECTION_REASON_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.RECRUITING_REJECTION_REASON_INTERNATIONALIZATION);
  }
}

export interface IRejectionReasonModel {
  _id?: string;
  name: string;
}
