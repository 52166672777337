<div *ngIf="translations" class="abh-main-container kenjo-p-20px kenjo-border kenjo-border-radius-10px">
    <div class="flex-horizontal flex-center-vertically">
      <div class="kenjo-flex-size-1 kenjo-font-size-14px kenjo-font-color-light-text-757575">{{ translations.publicHolidays }}</div>
      <div class="kenjo-flex-size-0 kenjo-ml-10px">
        <kenjo-icon size="25" class="kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog.emit()">clear</kenjo-icon>
      </div>
    </div>
    <div
      class="kenjo-mt-10px kenjo-p-10px kenjo-border-radius-5px kenjo-color-grey-background-lighter-f8f8f8"
    >
      <div class="flex-horizontal flex-center-vertically kenjo-mt-5px">
        <kenjo-icon [size]="15" class="kenjo-font-color-light-text-757575">event</kenjo-icon>
        <div class="kenjo-pl-10px">
            <div class="kenjo-font-size-14px kenjo-font-weight-bold">{{ translations.publicHoliday }}
                <span *ngIf="bankHolidays.holidayDuration !== 'FullDay'"> - {{ translations['timeOffRequest' + bankHolidays.holidayDuration]}}</span>
            </div>
            <div class="kenjo-font-size-12px kenjo-pt-5px">{{bankHolidays.holidayName}}</div>
        </div>
      </div>
    </div>
</div>