import { animate, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { MatMenu } from '@angular/material/menu';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IAttendanceSummaryModel } from '@app/cloud-features/settings-attendance/models/attendance-summary.model';
import { IAttendanceLimitSettings } from '@app/cloud-features/settings-attendance/models/work-schedule-template.model';
import {
  AttendanceCategoryService,
  IAttendanceCategory,
} from '@app/cloud-features/settings-attendance/services/attendance-category.service';
import {
  AttendanceConflictsService,
  IAttendanceConflict,
  IConflictsDialog,
} from '@app/cloud-features/settings-attendance/services/attendance-conflicts.service';
import { AttendanceLimitsController } from '@app/cloud-features/settings-attendance/services/attendance-limits.controller';
import {
  AttendancePolicyService,
  IAttendancePolicy,
  IUserAccount,
} from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { AttendancePreferencesService } from '@app/cloud-features/settings-attendance/services/attendance-preferences.service';
import { AttendanceSettingsService } from '@app/cloud-features/settings-attendance/services/attendance-settings.service';
import { AttendanceSummaryService } from '@app/cloud-features/settings-attendance/services/attendance-summary.service';
import { TimeOffTypeService } from '@app/cloud-features/time-off/services/time-off-type.service';
import { TimeOffUserPolicyController } from '@app/cloud-features/time-off/services/time-off-user-policy.controller';
import { IUserAccountModel } from '@app/models/user-account.model';
import { IUserPersonalModel } from '@app/models/user-personal.model';
import { IUserWorkModel } from '@app/models/user-work.model';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { PrivateInternationalizationService } from '@app/private/services/private-internationalization.service';
import { PrivateSecurityService } from '@app/private/services/private-security.service';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import { DurationPipe } from '@app/standard/components/duration/duration.pipe';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { IQueryDates } from '@app/standard/components/input-month-picker/input-month-picker.component';
import { GenericPage, IMenuOption, ITranslationResource } from '@app/standard/pages/generic.page';
import { SetCategoryDialog } from '@app/standard/pages/people-detail/dialogs/set-category.dialog';
import { BreakReminderDialog } from '@app/standard/pages/people-detail/people-detail-attendance/dialogs/break-reminder/break-reminder.dialog';
import {
  calculateHoursAndMinutes,
  formatWorkSchedule,
  getCurrentWorkScheduleFromHistory,
} from '@app/standard/pages/people-detail/people-detail-attendance/people-detail.helpers';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { OvertimeAdjustBalanceDialog } from '@app/standard/pages/people/dialogs/overtime-adjust-balance-dialog/overtime-adjust-balance.dialog';
import { OvertimeBalanceHistoryDialog } from '@app/standard/pages/people/dialogs/overtime-balance-history-dialog/overtime-balance-history.dialog';
import { OvertimeCompensationPayDialog } from '@app/standard/pages/people/dialogs/overtime-compensation-pay-dialog/overtime-compensation-pay.dialog';
import { OvertimeCompensationTimeOffDialog } from '@app/standard/pages/people/dialogs/overtime-compensation-time-off-dialog/overtime-compensation-time-off.dialog';
import { OvertimeNegativeBalanceDialog } from '@app/standard/pages/people/dialogs/overtime-negative-balance-dialog/overtime-negative-balance.dialog';
import {
  calculateIsCloseEntry,
  calculateIsOpenEndTimeEntry,
  calculateIsOpenShift,
  calculateWasOvernight,
  checkShiftWithoutBreak,
  evaluateOverlappingTimeOff,
  hasShiftBreakConflict,
  isAnyBreakOpen,
  setRealEndTime,
  splitChangeTrackingBreaks,
} from '@app/standard/services/attendance/attendance-helpers';
import {
  BreakReminderController,
  IBreakReminderConfiguration,
} from '@app/standard/services/attendance/controllers/break-reminder.controller';
import { FutureEntriesService, IFutureEntryErrors } from '@app/standard/services/attendance/future-entries.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { CloudRoutesService } from '@app/standard/services/core/cloud-routes.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { UrlChangeService } from '@app/standard/services/core/url-changes.service';
import { DocumentExportService } from '@app/standard/services/document/document-export.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { QueryParamsService } from '@app/standard/services/navigation/query-params.service';
import { AttendanceSummaryController } from '@app/standard/services/user/controllers/attendance-summary.controller';
import { OvertimeBalanceDetailsController } from '@app/standard/services/user/controllers/overtime-balance-details.controller';
import { UserAccountService } from '@app/standard/services/user/user-account.service';
import { IBreak, ITimeOffEntry, IUserAttendanceModel, UserAttendanceService } from '@app/standard/services/user/user-attendance.service';
import { UserPersonalService } from '@app/standard/services/user/user-personal.service';
import { IPaidHoursInfo, IPaidHoursInfoResult, UserSalaryService } from '@app/standard/services/user/user-salary.service';
import { IUserWorkScheduleModel, UserWorkScheduleService } from '@app/standard/services/user/user-work-schedule.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { VariablePayTypeService } from '@app/standard/services/variable-pay-type/variable-pay-type.service';
import { ConflictsDialogComponent } from '@app/standard/standalone-components/conflicts-dialog/conflicts-dialog.component';
import { TimeOffRequest as ITimeOffRequestLib, calculateTimeOffRequestPartOfDayForDate } from '@carlos-orgos/kenjo-shared-libs';
import {
  ALLOWANCE_TYPE_UNPAID,
  ATTENDANCE_AUTOMATION_AUTO_DEDUCT,
  ATTENDANCE_AUTOMATION_MAX_HOURS,
  ATTENDANCE_AUTOMATION_SPLIT_ENTRIES,
  ATTENDANCE_AUTOMATION_TIME_OFF,
  ATTENDANCE_CONFLICT_ENTRY_NOT_COMPLETED,
  ATTENDANCE_CONFLICT_MAX_DAILY_HOURS,
  ATTENDANCE_CONFLICT_MIN_BREAK_TIME,
  ATTENDANCE_CONFLICT_MISSING_FULL_ENTRY,
  ATTENDANCE_CONFLICT_OVERLAPPING_NON_WORKING_DAYS,
  ATTENDANCE_CONFLICT_OVERLAPPING_PUBLIC_HOLIDAYS,
  ATTENDANCE_INTERFACE_API,
  ATTENDANCE_INTERFACE_ATTENDANCE_TAB,
  HOLIDAY_DURATION_AFTERNOON,
  HOLIDAY_DURATION_FULL_DAY,
  HOLIDAY_DURATION_MORNING,
  OVERTIME_HISTORY_ENTRY_TYPE_PAY,
  OVERTIME_HISTORY_ENTRY_TYPE_TIME_OFF,
  TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER,
  TIME_OFF_TYPE_ACTIVITY_TYPE_PAID,
  TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID,
  TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID_NO_REDUCES_EXPECTED,
} from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'orgos-people-detail-attendance',
  templateUrl: 'people-detail-attendance.page.html',
  styleUrls: ['people-detail-attendance.page.scss'],
  animations: [
    trigger('smoothCollapse', [
      transition(
        ':leave',
        [style({ height: '*', opacity: 1, overflow: 'hidden' }), animate('{{duration}}ms ease-in', style({ height: 0, opacity: 0 }))],
        { params: { duration: 0 } }
      ),
    ]),
  ],
})
export class PeopleDetailAttendancePage extends GenericPage implements AfterViewInit, OnDestroy {
  protected profilePermissionsResources: Array<string> = [
    'payroll-feature',
    'employees',
    'performance-review',
    'attendance-app',
    'performance-feedback-results',
  ];

  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'people-detail-attendance-page' },
    { name: 'misc', translationKey: 'people-detail-misc' },
    { name: 'userAttendanceCollection', translationKey: 'user-attendance-collection' },
    { name: 'globalMisc', translationKey: 'misc' },
    { name: 'picklists', translationKey: 'standard-picklists' },
    { name: 'attendanceMenu', translationKey: 'attendance-menu' },
    { name: 'breaksDialog', translationKey: 'breaks-dialog' },
    { name: 'breakReminderDialog', translationKey: 'break-reminder-dialog' },
  ];

  private isMyAttendancePage = false;

  @ViewChildren('dayElement') dayElements: QueryList<any>;
  @ViewChild('conflictsMenu') conflictsMenu: MatMenu;

  @Input() listTabs: Array<string> = [];

  MONTHLY_TAB_INDEX = 0;
  DATE_RANGE_TAB_INDEX = 1;
  GRACE_MINUTES = 'grace-minutes';
  WORK_SCHEDULE_TYPE = 'fixed';
  CONFLICT_TYPES = {
    MAX_DAILY_HOURS: ATTENDANCE_CONFLICT_MAX_DAILY_HOURS,
    MIN_BREAK_TIME: ATTENDANCE_CONFLICT_MIN_BREAK_TIME,
    OVERLAPPING_PUBLIC_HOLIDAYS: ATTENDANCE_CONFLICT_OVERLAPPING_PUBLIC_HOLIDAYS,
    OVERLAPPING_NON_WORKING_DAYS: ATTENDANCE_CONFLICT_OVERLAPPING_NON_WORKING_DAYS,
    MISSING_FULL_ENTRY: ATTENDANCE_CONFLICT_MISSING_FULL_ENTRY,
    ENTRY_NOT_COMPLETED: ATTENDANCE_CONFLICT_ENTRY_NOT_COMPLETED,
  };

  @Input() selectedTab: number = 0;

  MAX_SHIFT_LENGTH = 1440;
  MAX_HOURS_PER_DAY = 1440;
  ATTENDANCE_AUTOMATION_MAX_HOURS = ATTENDANCE_AUTOMATION_MAX_HOURS;
  ATTENDANCE_AUTOMATION_SPLIT_ENTRIES = ATTENDANCE_AUTOMATION_SPLIT_ENTRIES;
  ATTENDANCE_AUTOMATION_AUTO_DEDUCT = ATTENDANCE_AUTOMATION_AUTO_DEDUCT;
  ATTENDANCE_AUTOMATION_TIME_OFF = ATTENDANCE_AUTOMATION_TIME_OFF;

  weekDays: any;
  userId: string;
  language: string;
  locale: string;
  todayDate: moment.Moment;
  currentMonthDate: moment.Moment;
  timeEntryErrors: { [key: string]: any } = {};
  timeEntryOverlappingErrors: { [key: string]: any } = {};
  allUserAttendanceForCurrentMonth: { [key: string]: Array<IUserAttendanceModel> } = {};
  allUserAttendanceForCurrentDateRange: { [key: string]: Array<IUserAttendanceModel> } = {};
  attendanceGeolocationForCurrentMonth: { [key: string]: 'GREY' | 'YELLOW' | 'GREEN' };
  userWorkSchedule: IUserWorkScheduleModel;
  userPersonal: IUserPersonalModel;
  userAccount: IUserAccount; // Difference between this and the logged user is that this refers to the owner of the current attendance tab
  userPersonalById: any = {};
  copiedTimeEntry: IUserAttendanceModel;
  userWork: IUserWorkModel;
  workingDays: Array<boolean> = [];
  daysOfCurrentMonth: Array<moment.Moment> = [];
  daysOfCurrentMonthToCalculateWorkTime: Array<moment.Moment> = []; // Used to calculate the total worked time of the month
  daysOfCurrentDateRange: Array<moment.Moment> = [];
  totalExpectedMinutesToWorkInCurrentMonth: number;
  totalExpectedMinutesToWorkInCurrentRange: number;
  totalExpectedMinutesToWorkSoFar: number;
  totalWorkedMinutesInCurrentMonth: number;
  totalPaidMinutesInCurrentMonth: number;
  totalWorkedMinutesInCurrentRange: number;
  minutesToWorkPerWorkingDay: number;
  minutesWorkedEachDay: Array<number> = [];
  minBreakEachDay: Array<number> = [];
  minutesWorkedEachDayInCurrentRange: Array<number> = [];
  totalSuggestedBreak: number;
  attendanceOfDayChangedMap: any = {};
  attendanceToDeleteMap: any = {};
  isEditable: boolean = true;
  timeOffTypesById: any = {};
  dayElementsSubscription: Subscription;
  daysContainerElement: any;
  todayElement: any;
  scrolledToTodayOnce: boolean = false;
  monthHasChanged: boolean = false;
  isTodayVisible: boolean = true;
  scrollingToToday: boolean = false;
  timeEntryBeingEdited:
    | undefined
    | {
        dayOfMonth: moment.Moment;
        index: number;
        iUserAttendance: IUserAttendanceModel;
        type: string | 'startTime' | 'endTime' | 'breakTime' | 'comment' | 'breaks';
        value: number | string | Array<IBreak>;
      };
  originalAttendance: IUserAttendanceModel;
  entryIsOverlapping: boolean = false;
  resetTodayButton: boolean = false;
  timeRange: { to?: Date; from?: Date } = {};
  currentDateRange: { startDate: Date; endDate: Date };
  attendanceWasInitialized: boolean = false;
  copiedTimeEntryPlaceholder: { day?: moment.Moment; index?: number };
  compensatedHours: number = 0;
  restrictTimeLimit: number | string = this.MAX_SHIFT_LENGTH;
  nextShiftDate: string;
  isRestrictCheckIn: boolean = false;
  futureEntriesErrors: IFutureEntryErrors;
  attendanceConflictsPerDay: IConflictsDialog[];
  attendanceConflicts: IAttendanceConflict[] = [];
  attendanceConflictsCurrentDay: IAttendanceConflict[];
  attendanceConflictsToCheck: IAttendanceConflict[] = [];
  amountDaysWithConflicts: number = 0;
  isLoadingConflicts: boolean = false;

  activeFilterOfConflicts: boolean = false;
  timeOutRef: NodeJS.Timeout;

  displayedColumns: Array<string> = ['date', 'hoursExpected', 'hoursTracked', 'status'];
  headerLabels: { [key: string]: string } = {};

  showShiftsByDay: any = {};
  canManage: boolean = false;
  canEdit: boolean = false;
  canApprove: boolean = false;
  canConvertTimeOff: boolean = false;
  canPayOvertime: boolean = false;
  pendingToApprove: boolean = false;
  pendingToApproveInCurrentDateRange: boolean = false;
  pendingToApproveConflicts: boolean = false;
  pendingToApproveInCurrentDateRangeConflicts: boolean = false;
  isAttendanceDayComplete: any = {};
  dateRangeIsAttendanceDayComplete: any = {};
  allAttendanceOfDayApprovedMap: any = {};
  dateRangeAttendanceOfDayApprovedMap: any = {};
  someAttendanceOfDayApprovedMap: any = {};
  daysContainerScrolled: boolean = false;
  showAdjustmentWarning: boolean = false;
  datesUntilNow: { from: moment.Moment; to: moment.Moment };
  dateRangeData: Array<any> = [];
  attendanceSummaryId: string;
  fullDayTimeOff: any = {};
  daysWithAtLeastOneEntry: any = {};

  FILENAME: string = 'Attendance';
  MONTHS_FOR_SPLIT = 6;

  expectedHoursByDay: any = {};
  expectedHoursByDayInCurrentRange: any = {};
  attendanceSummaryCurrentMonth: any = {};
  lastMonthOvertime: number;
  currentOvertimeBalance: number;
  allowEntriesInTheFuture: boolean = false;
  attendancePolicy: IAttendancePolicy;

  loadingChangeDateRange: boolean = false;
  loadingChangeMonth: boolean = true;

  isTimeEntryEditableFlag: any = {};
  canApproveOrEditFlag: any = {};

  savingTimeEntries: boolean = false;

  entryHasErrors: boolean = false;
  timeEntryInlineErrors: { [key: string]: any } = {};
  loggedUser: IUserAccountModel;

  useAttendanceCategories: boolean = false;
  categoryIdToCategory: { [categoryId: string]: IAttendanceCategory } = {};
  categoryIdToTooltip: { [categoryId: string]: string } = {};
  colors: any = userColorConstants;
  private listCategoriesForThisUser: Array<IAttendanceCategory>;

  downloadingDocument: boolean = false;

  selectedBreaksElement: ElementRef;
  selectedUserAttendanceToChangeBreaks: IUserAttendanceModel;
  overlay: any;
  breaksOverlay: OverlayRef;
  @ViewChild(CdkPortal) breaksPortal: Portal<any>;
  selectedDateToChangeBreaks: moment.Moment;
  selectedIndexOfTimeEntryToChangeBreaks: number;
  selectedIndexOfDayToChangeBreaks: number;
  selectedUserAttendanceToChangeBreaksIsReadOnly: boolean;

  otherShiftsThisDayToChangeAttendanceBreaks: Array<IUserAttendanceModel>;
  userAttendanceToTimeOffsOtherType: { [key: string]: Array<IUserAttendanceModel> } = {};
  eventDialogsInfo: object = {
    'time-off': {
      isClosing: false,
      showDialog: '',
    },
    holiday: {
      isClosing: false,
      showDialog: '',
    },
  };

  ATTENDANCE_INTERFACE_API = ATTENDANCE_INTERFACE_API;

  subtractWhenDelete = false;
  scrollToSubtractWhenDelete = 0;

  userManagePoliciesInfo: any;
  seeDetailsUserId: string;
  seeDetailsUserIdSubscription: Subscription;

  showPaidHours: boolean = false;
  timeOffOverlappingValidationIsActive: boolean = false;

  @HostListener('window:focus', ['$event'])
  async appFocusChange() {
    await Promise.all([this.initUserAttendance(), this.getAttendancePolicy()]);
    this.computeMinutesWorkedEachDay();
  }

  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    let backRoute;
    event.preventDefault();
    if (this.injector.get(QueryParamsService).comesFrom === 'attendance-summary') {
      backRoute = '/cloud/attendance/attendance-summary';
      this.injector.get(QueryParamsService).setComesFrom('');
    } else {
      backRoute = this.injector.get(UrlChangeService).getPreviousUrl();
      if (check.not.nonEmptyString(backRoute)) {
        return;
      }
    }
    setTimeout(() => {
      this.router.navigate([backRoute], { replaceUrl: true });
    }, 80);
  }

  constructor(
    private renderer: Renderer2,
    protected injector: Injector,
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected location: Location
  ) {
    super(injector, cdr, router, route, location);
  }

  protected async fetchData(resolveFetchData: Function, rejectFetchData: Function) {
    try {
      this.attendanceOfDayChangedMap = {};
      this.attendanceToDeleteMap = {};
      if (check.not.assigned(this.userId) || check.emptyString(this.userId)) {
        resolveFetchData();
        return;
      }

      this.headerLabels = {
        date: this.i18n.page.date,
        hoursExpected: this.i18n.page.hoursExpected,
        hoursTracked: this.i18n.page.hoursTracked,
        status: this.i18n.page.status,
      };

      this.listTabs = [this.i18n.page.monthlyTab, this.i18n.page.dateRangeTab];

      const NUMBER_OF_DATA_TO_FETCH = 12;
      let dataFetched = 0;

      await this.getAttendancePolicy();
      this.totalSuggestedBreak = this.injector.get(AttendancePolicyService).calculateTotalSuggestedBreak(this.attendancePolicy);

      this.injector
        .get(AttendanceLimitsController)
        .getLimitsConfig(this.userId)
        .then(async (settings: IAttendanceLimitSettings) => {
          if (check.assigned(settings)) {
            if (settings.useAttendanceCategories === true && settings.attendanceCategories?.length) {
              this.useAttendanceCategories = true;
              this.listCategoriesForThisUser = settings.attendanceCategories;

              await this.initCategoriesMap();
            } else if (settings.useAttendanceCategories === true && !settings.attendanceCategories?.length) {
              // In this case, we are using attendance categories and this employee does not have any category assigned to it
              // let's find all the categories and build the map of categories to ensure that we can display the existing categories
              this.useAttendanceCategories = true;
              await this.initCategoriesMap();
            }
          }

          dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
        });
      try {
        await this.initAttendanceSummary();
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        // An error is already shown
        this.attendanceSummaryCurrentMonth = null;
        rejectFetchData();
      }

      try {
        await this.initUserAttendance();
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch (error) {
        // An error is already shown
        this.allUserAttendanceForCurrentMonth = {};
        rejectFetchData();
      }

      try {
        const allUsersPersonal = await this.injector.get(UserPersonalService).getAllUserPersonal(false);
        this.userPersonalById = _.keyBy(allUsersPersonal, '_id');
        this.userPersonal = allUsersPersonal.find((userPersonal) => {
          return userPersonal._id === this.userId;
        });
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        // An error is already shown
        this.userPersonal = null;
        rejectFetchData();
      }

      try {
        const userWork = await this.injector.get(UserWorkService).getById(this.userId);
        this.userWork = userWork;
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch (error) {
        // An error is already shown
        this.userWork = null;
        rejectFetchData();
      }

      try {
        await this.initUserWorkSchedule();

        if (!this.userWorkSchedule.trackAttendance) {
          this.router.navigate(['../'], { relativeTo: this.route });
          return;
        }
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
        this.calculateCompensatedHours();
      } catch {
        // An error is already shown
        this.userWorkSchedule = null;
        this.workingDays = [];
        rejectFetchData();
      }

      try {
        await this.getTimeOffTypes();
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        this.timeOffTypesById = {};
        rejectFetchData();
      }
      try {
        const commonPolicies = await this.injector.get(TimeOffUserPolicyController).getCommonPolicies([this.userId], false);

        if (check.nonEmptyArray(commonPolicies)) {
          this.canConvertTimeOff = true;
        }
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        // An error is already shown
        rejectFetchData();
      }
      try {
        const usersWithEditableAttendance = await this.injector.get(UserAccountService).getUsersWithEditableAttendance();
        const editableUsers = usersWithEditableAttendance
          .filter((user) => {
            return (
              user._id !== this.loggedUser._id ||
              check.equal(this.loggedUser.profileKey, 'admin') ||
              check.equal(this.loggedUser.profileKey, 'hr-admin')
            );
          })
          .map((iUser) => iUser._id);
        // Avoid to modify approved entries in the own attendance tab
        this.canEdit = editableUsers.includes(this.userId);
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        // An error is already shown
        rejectFetchData();
      }

      try {
        const userAccounts = await this.injector.get(UserAccountService).getUsersWithApprovableAttendance();

        // This is the userAccount of the user in the current attendance tab, which is not always the same as the one logged
        this.userAccount = (await this.injector.get(UserAccountService).getById(this.userId)) as IUserAccount;

        // Approved is required to know if the logged user has permissions over the opened tab
        this.canApprove = check.assigned(userAccounts.find((user) => user._id === this.userId.toString()));
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        rejectFetchData();
      }

      this.seeDetailsUserIdSubscription = this.injector.get(PeopleDetailService).seeDetailsUserId$.subscribe((id: string) => {
        this.userManagePoliciesInfo = {
          userPersonal: this.userPersonal,
          userStartDate: this.userWork.startDate,
          timeOffTypes: this.injector.get(PeopleDetailService).timeOffTypes,
        };
        this.seeDetailsUserId = id;
      });

      try {
        const types = await this.injector.get(VariablePayTypeService).getAllTypes();
        if (check.nonEmptyArray(types)) {
          this.canPayOvertime = true;
        }
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        this.canPayOvertime = false;
      }

      try {
        const paidHoursInfo: IPaidHoursInfo = {
          userId: this.userId,
          month: moment(this.currentMonthDate).month() + 1,
          year: moment(this.currentMonthDate).year()
        };
        const paidHoursVisibility: IPaidHoursInfoResult = await this.injector.get(UserSalaryService).getPaidHoursVisibility(paidHoursInfo);
        this.showPaidHours = paidHoursVisibility?.showPaidHours ?? false;
        dataFetched = this.checkDataFetched(resolveFetchData, dataFetched, NUMBER_OF_DATA_TO_FETCH);
      } catch {
        this.canPayOvertime = false;
        rejectFetchData();
      }
    } catch (error) {
      rejectFetchData(error);
    }
  }

  onInputTimeChange(
    $event,
    iDayOfMonth: moment.Moment,
    indexOfTimeEntry: number,
    iUserAttendance: IUserAttendanceModel,
    indexOfDay: number,
    type: 'startTime' | 'endTime'
  ) {
    this.handleInputTimeChange($event, iDayOfMonth, indexOfTimeEntry, iUserAttendance, indexOfDay, type);
  }

  private handleInputTimeChange(
    $event,
    iDayOfMonth: moment.Moment,
    indexOfTimeEntry: number,
    iUserAttendance: IUserAttendanceModel,
    indexOfDay: number,
    type: 'startTime' | 'endTime' | 'comment'
  ) {
    this.updateTimeEntryBeingEdited({
      dayOfMonth: iDayOfMonth,
      index: indexOfTimeEntry,
      iUserAttendance: iUserAttendance,
      type,
      value: $event,
    });
    this.computeMinutesWorkedEachDay();
    this.updateTimeEntryErrors(iDayOfMonth, true);
    this.updateTimeEntryInlineErrors(iDayOfMonth, indexOfDay, indexOfTimeEntry);
    this.isTimeEntryEditable(iDayOfMonth, indexOfTimeEntry);
    this.canApproveOrEdit(iDayOfMonth);
  }

  protected async configureGlobalBar() {
    try {
      if (this.isMyAttendancePage) {
        await this.configureAttendanceGlobalBar();
        return;
      }

      await this.configurePeopleGlobalBar();
    } catch (error) {
      throw error;
    }
  }

  checkDataFetched(resolveFetchData: Function, dataFetched: number, numberOfDataFetch: number): number {
    dataFetched++;

    if (dataFetched === numberOfDataFetch) {
      resolveFetchData();
    }
    return dataFetched;
  }

  private async configurePeopleGlobalBar() {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    const options: Array<IMenuOption> = [
      {
        name: this.i18n.misc.publicProfileTab,
        onClick: () => {
          this.router.navigate(['../'], { relativeTo: this.route });
        },
      },
    ];

    const myProfile = this.loggedUser.profileKey;

    let canSeePersonalTabPromise = Promise.resolve(true);
    if (!['admin', 'hr-admin', 'finance-admin'].includes(myProfile)) {
      canSeePersonalTabPromise = this.injector
        .get(PeopleDetailService)
        .getPermissionsToSeePersonalTab(this.profilePermissions['employees'], this.userId);
    }

    const [canSeePersonalTab, canSeePayrollTabs, canSeePerformanceTab] = await Promise.all([
      canSeePersonalTabPromise,
      this.getPermissionsToSeePayrollTabs(this.profilePermissions['payroll-feature']),
      this.getPermissionsToSeePerformanceTab(this.profilePermissions['performance-feedback-results']),
      this.injector.get(PeopleDetailService).onPeopleRouteLoaded(this.userId),
    ]);

    if (canSeePersonalTab) {
      options.push({
        name: this.i18n.misc.personalTab,
        onClick: () => {
          this.router.navigate(['../personal'], { relativeTo: this.route });
        },
      });
    }

    options.push({ key: 'attendance-tab', name: this.i18n.misc.attendanceTab, onClick: () => {} });

    if (canSeePayrollTabs === true) {
      options.push({
        key: 'compensation-tab',
        name: this.i18n.misc.compensationTab,
        onClick: () => this.router.navigate(['../compensation'], { relativeTo: this.route }),
      });
      options.push({ name: this.i18n.misc.payrollTab, onClick: () => this.router.navigate(['../payroll'], { relativeTo: this.route }) });
    }

    if (canSeePerformanceTab === true) {
      options.push({
        name: this.i18n.misc.performanceTab,
        onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
      });
    }

    if (this.injector.get(PeopleDetailService).canSeeUsersSmartDocs()) {
      options.push({
        name: this.i18n.misc.smartDocsTab,
        onClick: () => this.injector.get(PeopleDetailService).openEmployeeDoc(this.userId),
      });
    }

    if (this.injector.get(PeopleDetailService).canSeeUsersTimeOff()) {
      options.push({
        name: this.i18n.misc.timeOffTab,
        onClick: () => this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.userId),
      });
    }

    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.attendanceTab]);
  }

  private async configureAttendanceGlobalBar() {
    this.globalBarConfig.pageName = this.i18n.attendanceMenu.pageName;

    const options: Array<IMenuOption> = [];

    if (this.injector.get(CloudRoutesService).checkRoute('attendance/my-attendance') === true) {
      options.push({
        name: this.i18n.attendanceMenu.myAttendanceTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/attendance/my-attendance');
        },
      });
    }

    const attendanceSummaryPermission = await this.injector.get(PrivateSecurityService).getPermissionsForCollection('attendance-app');
    let attendanceSummaryTabStatus;

    if (check.not.assigned(attendanceSummaryPermission)) {
      return;
    }

    if (
      (check.assigned(attendanceSummaryPermission.c_viewAttendanceSummary_all) &&
        attendanceSummaryPermission.c_viewAttendanceSummary_all === true) ||
      (check.assigned(attendanceSummaryPermission.c_viewAttendanceSummary_custom) &&
        check.not.emptyArray(attendanceSummaryPermission.c_viewAttendanceSummary_custom))
    ) {
      attendanceSummaryTabStatus = await this.injector.get(AttendancePreferencesService).getAttendanceSummaryTabAccess();
    }

    if (check.assigned(attendanceSummaryTabStatus) && attendanceSummaryTabStatus === true) {
      options.push({
        name: this.i18n.attendanceMenu.attendanceSummaryTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/attendance/attendance-summary');
        },
      });
    }

    const isPresenceSummaryTabEnabled = await this.injector.get(AttendanceSettingsService).getPresenceSummaryTabAccess();

    if (
      check.assigned(attendanceSummaryPermission.c_viewAttendanceCheckInTab) &&
      attendanceSummaryPermission.c_viewAttendanceCheckInTab === true &&
      this.injector.get(CloudRoutesService).checkRoute('attendance/check-ins') === true &&
      check.assigned(isPresenceSummaryTabEnabled) &&
      isPresenceSummaryTabEnabled
    ) {
      options.push({
        name: this.i18n.attendanceMenu.checkInTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/attendance/check-ins');
        },
      });
    }

    if (this.injector.get(CloudRoutesService).checkRoute('attendance/settings') === true) {
      options.push({
        name: this.i18n.attendanceMenu.settingsTab,
        onClick: () => {
          this.router.navigateByUrl('/cloud/attendance/settings');
        },
      });
    }

    this.injector.get(PeopleDetailService).verifySmartDocsLink(this.userId);
    this.injector.get(PeopleDetailService).verifyShowTimeOffLink(this.userId, this.userPersonal);

    const optionIndex = _.findIndex(options, ['name', this.i18n.attendanceMenu.myAttendanceTab]);
    this.globalBarConfig.secondaryMenuOptions = options;
    this.globalBarConfig.selectedSecondaryMenuOption = optionIndex;
  }

  public onDateRangeChange(value: any): void {
    if (
      check.not.assigned(value) ||
      check.not.assigned(value.to) ||
      check.not.assigned(value.from) ||
      (this.currentDateRange?.startDate === value.from && this.currentDateRange?.endDate === value.to)
    ) {
      if (check.not.assigned(value.to) || check.not.assigned(value.from)) {
        this.resetDateRangeView();
      }

      return;
    }

    this.loadingChangeDateRange = true;

    this.currentDateRange = {
      startDate: value.from,
      endDate: value.to,
    };

    this.updateDateRangeView();
  }

  public getWeekDay(dayOfMonth: moment.Moment) {
    const dayOfTheWeek = this.weekDays[dayOfMonth.weekday()];
    return `${dayOfTheWeek[0].toUpperCase() + dayOfTheWeek.substring(1)}`;
  }

  public resetDateRangeView(): void {
    this.totalExpectedMinutesToWorkInCurrentRange = 0;
    this.expectedHoursByDayInCurrentRange = [];
    this.allUserAttendanceForCurrentDateRange = {};
    this.dateRangeIsAttendanceDayComplete = {};
    this.dateRangeAttendanceOfDayApprovedMap = {};
    this.dateRangeData = [];
    this.daysOfCurrentDateRange = [];
    this.totalWorkedMinutesInCurrentRange = 0;
    this.currentDateRange = { startDate: null, endDate: null };
  }

  public updateDateRangeView(): void {
    if (
      check.not.assigned(this.currentDateRange) ||
      check.not.assigned(this.currentDateRange?.startDate) ||
      check.not.assigned(this.currentDateRange?.endDate)
    ) {
      return;
    }

    this.initUserAttendanceDateRange()
      .then(async () => {
        this.computeMinutesWorkedEachDayInCurrentRange();
        await this.updateDateRangeExpectedHours();
      })
      .catch(() => {
        this.resetDateRangeView();
      });
  }

  public initTableData(): void {
    this.dateRangeData = this.daysOfCurrentDateRange.reduce((accumulator: any, value: moment.Moment, index: number) => {
      const hoursTracked = this.minutesWorkedEachDayInCurrentRange[index] ?? 0;
      const hoursExpected = this.expectedHoursByDayInCurrentRange[index + 1]?.expectedTime ?? 0;

      if (hoursTracked === 0 && hoursExpected === 0) {
        return accumulator;
      }

      accumulator.push({
        date: value,
        hoursTracked: hoursTracked,
        hoursExpected: hoursExpected,
        status: this.dateRangeAttendanceOfDayApprovedMap[value.toISOString()],
      });

      return accumulator;
    }, []);
  }

  public ngAfterViewInit(): void {
    this.dayElementsSubscription = this.dayElements.changes.subscribe(() => {
      if (check.assigned(this.seeDetailsUserId)) {
        return;
      }
      if (this.daysContainerElement) {
        this.daysContainerElement.scrollTop = this.daysContainerElement?.scrollTop - 1;
      }

      if (this.dayElements.length > 1 && this.isSelectedMonthTodayMonth() && (this.monthHasChanged || !this.scrolledToTodayOnce)) {
        this.daysContainerElement = this.dayElements.first?.nativeElement;
        this.todayElement = this.dayElements.find((element: ElementRef) => element?.nativeElement.classList.contains('today'));
        this.daysContainerElement.scrollTop = this.todayElement?.nativeElement.offsetTop;
        this.scrolledToTodayOnce = true;
      } else if (this.dayElements.length !== 1 || this.scrolledToTodayOnce || this.monthHasChanged || !this.isSelectedMonthTodayMonth()) {
        const scroll = this.daysContainerElement?.scrollTop - 1 - (this.subtractWhenDelete ? this.scrollToSubtractWhenDelete : 0);
        this.daysContainerElement = this.dayElements.first?.nativeElement;
        this.daysContainerElement.scrollTop = scroll;
        this.subtractWhenDelete = false;
        this.monthHasChanged = false;
      }
    });
  }

  public isSelectedMonthTodayMonth(): boolean {
    return this.todayDate.format('YYYY-MM') === this.currentMonthDate.format('YYYY-MM');
  }

  public scrollToToday(clickedOnButton = false): void {
    if (this.isSelectedMonthTodayMonth()) {
      this.todayElement = this.dayElements.find((element: ElementRef) => element?.nativeElement.classList.contains('today'));
      if (clickedOnButton) {
        this.scrollingToToday = true;
        this.daysContainerElement.scrollTo({ top: this.todayElement?.nativeElement.offsetTop, behavior: 'smooth' });
        return;
      }

      this.daysContainerElement.scrollTop = this.todayElement?.nativeElement.offsetTop;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    moment.locale(this.locale);

    this.dayElementsSubscription?.unsubscribe();
    this.seeDetailsUserIdSubscription?.unsubscribe();

    if (this.timeOutRef) {
      clearTimeout(this.timeOutRef);
    }
  }

  protected beforeInit(): Promise<void> {
    this.loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        const myProfile = this.loggedUser.profileKey;
        this.language = this.loggedUser.language;
        this.locale = this.loggedUser.locale;

        moment.locale(this.language);
        this.setDatesToShow();

        if (
          this.injector.get(CloudRoutesService).checkRoute('attendance/my-attendance') === true &&
          this.router.url === '/cloud/attendance/my-attendance'
        ) {
          this.isMyAttendancePage = true;
          this.userId = this.loggedUser._id;
        } else {
          this.userId = id;
        }
        if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
          this.canManage = true;
          this.refreshData()
            .then(() => {
              this.refreshGlobalBar();
            })
            .catch(() => {});
          return;
        }

        let employeesPermissions;
        this.injector
          .get(PrivateSecurityService)
          .getPermissionsForCollection('employees')
          .then((permissions) => {
            employeesPermissions = permissions;
            const checkManageAttendance = this.injector.get(UserAccountService).attendanceIsManageable(this.userId);
            const callToPermissionsDetails = [this.getPermissionsToSeeAttendanceTab(employeesPermissions), checkManageAttendance];
            return Promise.all(callToPermissionsDetails);
          })
          .then((results: Array<boolean>) => {
            const canAccessAttendance = results[0];
            if (!canAccessAttendance) {
              this.router.navigate(['../'], { relativeTo: this.route, queryParams: { canAccessAttendance: false } });
              return;
            }
            this.canManage = results[1];
            return this.refreshData();
          })
          .then(() => {
            this.refreshGlobalBar();
            return;
          })
          .catch(() => {
            // An error is already shown
            this.router.navigate(['../'], { relativeTo: this.route });
            return;
          });
      });

    return Promise.resolve();
  }

  private setDatesToShow() {
    this.weekDays = moment.weekdays();
    if (this.language != 'en') {
      this.weekDays.push(this.weekDays.shift());
    }
    const queryMonth = this.injector.get(QueryParamsService).getQueryParam('month');

    this.todayDate = this.getSanitizedUTCToTimeZone();
    this.currentMonthDate = queryMonth
      ? this.getSanitizedUTCToTimeZone(moment(queryMonth, 'MM-DD-YYYY').local().startOf('month'))
      : this.getSanitizedUTCToTimeZone(moment().local().startOf('month'));
    this.setDatesUntilNow();
  }

  private async filterWithConflictsParam() {
    const queryConflicts = this.injector.get(QueryParamsService).getQueryParam('conflicts');
    if (queryConflicts === 'true' && !this.activeFilterOfConflicts && this.attendanceConflicts?.length) {
      await this.filterByConflicts();
    }
  }

  private setDatesUntilNow() {
    moment.locale(this.locale);
    this.datesUntilNow = {
      from: this.getSanitizedUTCToTimeZone(moment().local().startOf('month')),
      to: this.getSanitizedUTCToTimeZone(),
    };
    moment.locale(this.language);
  }

  protected afterInit(): Promise<void> {
    this.updateAttendanceSummary(false);
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view employee page', { category: 'Navigation', platform: 'Web', subcategory1: 'attendance', subcategory2: this.userId });
    return Promise.resolve();
  }

  private getPermissionsToSeeAttendanceTab(employeesPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.initUserWorkSchedule().then(() => {
        if (
          this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') === false ||
          check.not.assigned(this.userWorkSchedule) ||
          this.userWorkSchedule.trackAttendance === false
        ) {
          resolve(false);
          return;
        }

        if (this.loggedUser._id === this.userId && employeesPermissions.c_viewAttendanceTab_own === true) {
          resolve(true);
          return;
        }

        if (employeesPermissions.c_viewAttendanceTab_all === true) {
          resolve(true);
          return;
        }

        if (
          check.not.assigned(employeesPermissions.c_viewAttendanceTab_custom) ||
          check.emptyArray(employeesPermissions.c_viewAttendanceTab_custom)
        ) {
          resolve(false);
          return;
        }

        this.injector
          .get(UserWorkService)
          .getAllUserWorkCache()
          .then((allUserWork) => {
            let myUserWork = this.userWork;
            if (check.not.assigned(myUserWork)) {
              myUserWork = allUserWork.find((iUserWork) => {
                return iUserWork._id === this.userId;
              });
            }
            return customPermissions.applyCustomPermissionsToDocument(
              null,
              'user-work',
              employeesPermissions[`c_viewAttendanceTab_custom`],
              employeesPermissions[`c_viewAttendanceTab_own`],
              myUserWork,
              allUserWork,
              this.loggedUser
            );
          })
          .then((customPermissionResult) => {
            resolve(customPermissionResult);
          })
          .catch(() => {
            resolve(false);
          });
      });
    });
  }

  private async getPermissionsToEditUserAttendance(attendanceDocument: any): Promise<boolean> {
    try {
      if (check.not.assigned(this.profilePermissions['attendance-app']) || check.emptyObject(this.profilePermissions['attendance-app'])) {
        return false;
      }

      if (check.not.assigned(attendanceDocument) || check.not.assigned(attendanceDocument._userId)) {
        return false;
      }

      if (['admin', 'hr-admin'].includes(this.loggedUser.profileKey)) {
        return true;
      }

      const isMyOwnAttendance = attendanceDocument._userId === this.loggedUser._id;
      if (isMyOwnAttendance) {
        return this.attendancePolicy.methods.timeSheet;
      }

      if (this.profilePermissions['attendance-app'].c_editAttendanceTab_all === true) {
        return true;
      }

      if (
        check.not.assigned(this.profilePermissions['attendance-app'].c_editAttendanceTab_custom) ||
        check.emptyArray(this.profilePermissions['attendance-app'].c_editAttendanceTab_custom)
      ) {
        return false;
      }

      let myUserWork = this.userWork;

      const allUserWork = await this.injector.get(UserWorkService).getAllUserWorkCache();

      if (check.not.assigned(myUserWork)) {
        myUserWork = allUserWork.find((iUserWork) => {
          return iUserWork._id === this.userId;
        });
      }

      return await customPermissions.applyCustomPermissionsToDocument(
        null,
        'user-work',
        this.profilePermissions['attendance-app'].c_editAttendanceTab_custom,
        false,
        attendanceDocument,
        allUserWork,
        this.loggedUser
      );
    } catch {
      return false;
    }
  }

  private getPermissionsToSeePerformanceTab(performancePermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance') === false) {
        resolve(false);
      }
      if (this.loggedUser._id === this.userId) {
        resolve(true);
      }
      if (performancePermissions.c_readFeedback_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(performancePermissions.c_readFeedback_custom) ||
        check.emptyArray(performancePermissions.c_readFeedback_custom)
      ) {
        resolve(false);
      }
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.userId;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            performancePermissions.c_readFeedback_custom,
            performancePermissions.read_own,
            myUserWork,
            allUserWork,
            this.loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private async initUserAttendance() {
    try {
      let oneUserAttendanceDocument;
      this.userAttendanceToTimeOffsOtherType = {};
      const allUserAttendanceForCurrentMonthQuery = {
        _userId: this.userId,
        date: {
          $gte: this.currentMonthDate.toISOString(),
          $lte: this.currentMonthDate.clone().endOf('month').toISOString(),
        },
        _deleted: false,
      };

      // 1. Get all the user-attendance for the current user, month and year and the expected time
      const [allUserAttendanceForCurrentMonth] = await Promise.all([
        this.injector.get(UserAttendanceService).find(allUserAttendanceForCurrentMonthQuery),
        this.getExpectedHoursAndTotals(),
      ]);

      if (check.assigned(allUserAttendanceForCurrentMonth) && check.nonEmptyArray(allUserAttendanceForCurrentMonth)) {
        oneUserAttendanceDocument = allUserAttendanceForCurrentMonth[0];
      }

      // 2. Create a key-value object: date with an array of all the existing entries: 2024-07-01T00:00:00.000Z: [{},{},...]
      this.allUserAttendanceForCurrentMonth = _.groupBy(allUserAttendanceForCurrentMonth, 'date');

      if (!this.activeFilterOfConflicts) {
        this.daysOfCurrentMonth = Array.from({ length: this.currentMonthDate.daysInMonth() }, (value, iDayOfMonth) => {
          return this.currentMonthDate.clone().date(iDayOfMonth + 1);
        });
      }
      // Creating the days to correctly calculate the work time
      this.daysOfCurrentMonthToCalculateWorkTime = Array.from({ length: this.currentMonthDate.daysInMonth() }, (value, iDayOfMonth) => {
        return this.currentMonthDate.clone().date(iDayOfMonth + 1);
      });

      // 3. Iterate day by day to create dummy data ready to be filled
      this.daysOfCurrentMonth.forEach((iDayOfCurrentMonth: moment.Moment) => {
        this.fullDayTimeOff[iDayOfCurrentMonth.toISOString()] = false;
        this.daysWithAtLeastOneEntry[iDayOfCurrentMonth.toISOString()] = false;
        if (check.not.assigned(this.allUserAttendanceForCurrentMonth[iDayOfCurrentMonth.toISOString()])) {
          const defaultUserAttendance: IUserAttendanceModel = {
            _userId: this.userId,
            ownerId: this.userId,
            date: iDayOfCurrentMonth.toDate(),
            startTime: undefined,
            endTime: undefined,
            breaks: [],
            comment: undefined,
            _changesTracking: [],
            _deleted: false,
            _approved: false,
          };
          if (check.not.assigned(oneUserAttendanceDocument)) {
            oneUserAttendanceDocument = defaultUserAttendance;
          }
          this.allUserAttendanceForCurrentMonth[iDayOfCurrentMonth.toISOString()] = [defaultUserAttendance];
        }

        let isFullTimeOff = false;
        let prevHalfTimeOff = false;
        // Determine if adding time-off is needed
        if (this.expectedHoursByDay?.[iDayOfCurrentMonth.date()]?.timeOffs?.length > 0) {
          this.expectedHoursByDay[iDayOfCurrentMonth.date()].timeOffs.forEach((timeOff) => {
            if (timeOff._workTime === TIME_OFF_TYPE_ACTIVITY_TYPE_OTHER) {
              this.addTimeOffOtherTypeToAttendance(iDayOfCurrentMonth, timeOff);
              return;
            }
          });

          this.expectedHoursByDay[iDayOfCurrentMonth.date()].timeOffs.forEach((timeOff) => {
            if (
              ![
                TIME_OFF_TYPE_ACTIVITY_TYPE_PAID,
                TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID,
                TIME_OFF_TYPE_ACTIVITY_TYPE_UNPAID_NO_REDUCES_EXPECTED,
              ].includes(timeOff._workTime)
            ) {
              return;
            }
            const timeOffDuration = calculateTimeOffRequestPartOfDayForDate(iDayOfCurrentMonth as any, timeOff as ITimeOffRequestLib);
            // This condition manage the interaction entries depending on if the policy enabled/disabled tim-off overlapping validation
            if (this.timeOffOverlappingValidationIsActive && !isFullTimeOff) {
              isFullTimeOff =
                timeOffDuration === 'FullDay' ||
                (prevHalfTimeOff && timeOffDuration === 'Morning') ||
                (prevHalfTimeOff && timeOffDuration === 'Afternoon');
              prevHalfTimeOff = timeOffDuration === 'Morning' || timeOffDuration === 'Afternoon';
            }
            const userAttendance = this.convertTimeOffEntryToUserAttendanceModel(iDayOfCurrentMonth, timeOff);
            this.allUserAttendanceForCurrentMonth[iDayOfCurrentMonth.toISOString()].push(userAttendance);
          });
        }

        this.fullDayTimeOff[iDayOfCurrentMonth.toISOString()] = isFullTimeOff;
        const unsortedDay = this.allUserAttendanceForCurrentMonth[iDayOfCurrentMonth.toISOString()];
        this.allUserAttendanceForCurrentMonth[iDayOfCurrentMonth.toISOString()] = unsortedDay.sort((a: any, b: any) => {
          if (a?.timeOffEntryInfo?.duration === 'Morning' && b?.timeOffEntryInfo?.duration !== 'Morning') {
            return -1;
          }
          if (a?.timeOffEntryInfo?.duration !== 'Morning' && b?.timeOffEntryInfo?.duration === 'Morning') {
            return 1;
          }
          if (a?.timeOffEntryInfo?.duration === 'Afternoon' && b?.timeOffEntryInfo?.duration !== 'Afternoon') {
            return 1;
          }
          if (a?.timeOffEntryInfo?.duration !== 'Afternoon' && b?.timeOffEntryInfo?.duration === 'Afternoon') {
            return -1;
          }
          if (a.startTime == null && b.startTime != null) {
            return 1;
          }
          if (a.startTime != null && b.startTime == null) {
            return -1;
          }
          if (a.startTime != null && b.startTime != null) {
            return a.startTime < b.startTime ? -1 : 1;
          }
          return 0;
        });
      });

      await this.getAttendanceConflicts();
      await this.filterWithConflictsParam();

      this.injector.get(QueryParamsService).removeMultipleQueryParams({ ['month']: null, ['conflicts']: null });
      this.isEditable = await this.getPermissionsToEditUserAttendance(oneUserAttendanceDocument);
      this.allAttendanceOfDayApprovedMap = {};
      this.isAttendanceDayComplete = {};

      Object.keys(this.allUserAttendanceForCurrentMonth).forEach((day) => {
        if (
          check.not.assigned(this.allUserAttendanceForCurrentMonth[day]) ||
          check.emptyArray(this.allUserAttendanceForCurrentMonth[day])
        ) {
          return;
        }

        this.isAttendanceDayComplete[day] = this.allUserAttendanceForCurrentMonth[day].every((userAttendance) =>
          calculateIsCloseEntry(userAttendance)
        );

        this.allAttendanceOfDayApprovedMap[day] = this.allUserAttendanceForCurrentMonth[day]
          .filter((item) => !item.timeOffEntryInfo)
          .every((item) => item._approved === true);
        this.someAttendanceOfDayApprovedMap[day] = this.allUserAttendanceForCurrentMonth[day]
          .filter((item) => !item.timeOffEntryInfo)
          .some((item) => item._approved === true);

        this.daysWithAtLeastOneEntry[day] = this.allUserAttendanceForCurrentMonth[day].some((entry) => {
          return entry._id && !entry._timeOffRequestId;
        });
        this.pendingToApprove = allUserAttendanceForCurrentMonth.some(
          (attendance) =>
            attendance._approved === false &&
            calculateIsCloseEntry(attendance) &&
            this.isAttendanceDayComplete[moment.utc(attendance.date).toISOString()]
        );
        this.calculatePendingToApproveConflicts(allUserAttendanceForCurrentMonth);

        this.allUserAttendanceForCurrentMonth[day].forEach((userAttendance) => {
          if (check.assigned(userAttendance._changesTracking) && userAttendance._changesTracking.length > 0) {
            this.showShiftsByDay[day] = false;
            userAttendance._changesTracking = userAttendance._changesTracking.filter((iChangeTracking) => {
              return !(
                check.equal(iChangeTracking.operationType, 'Update') &&
                check.equal(iChangeTracking.fieldChanged, 'startBreakTime') &&
                check.assigned(iChangeTracking.oldValue)
              );
            });
          }
        });
      });
      this.calculateGeolocationStatuses();
    } catch {
      throw new Error('');
    }
  }

  private addTimeOffOtherTypeToAttendance(dateIn: moment.Moment, timeOffEntry: ITimeOffEntry): void {
    const dayIsoString = dateIn.toISOString();
    if (!this.userAttendanceToTimeOffsOtherType[dayIsoString]) {
      this.userAttendanceToTimeOffsOtherType[dayIsoString] = [];
    }
    const userAttendance = this.convertTimeOffEntryToUserAttendanceModel(dateIn, timeOffEntry);
    this.userAttendanceToTimeOffsOtherType[dayIsoString].push(userAttendance);
  }

  private convertTimeOffEntryToUserAttendanceModel(dateIn: moment.Moment, timeOffEntry: ITimeOffEntry): IUserAttendanceModel {
    const timeOffDuration = calculateTimeOffRequestPartOfDayForDate(dateIn as any, timeOffEntry as ITimeOffRequestLib);
    const result: IUserAttendanceModel = {
      _deleted: true,
      date: dateIn.toDate(),
      startTime: timeOffDuration === HOLIDAY_DURATION_AFTERNOON ? 720 : 0,
      endTime: timeOffDuration === HOLIDAY_DURATION_MORNING ? 720 : 1440,
      timeOffEntryInfo: {
        _timeOffTypeName: timeOffEntry._timeOffTypeName,
        _timeOffTypeColor: timeOffEntry._timeOffTypeColor,
        _policyType: timeOffEntry._policyType,
        duration: timeOffDuration ? timeOffDuration : timeOffEntry.duration,
      },
    };
    if (!timeOffDuration) {
      const fromHHMM = timeOffEntry.from.slice(11, 16);
      const toHHMM = timeOffEntry.to.slice(11, 16);
      result.timeOffEntryInfo.from = fromHHMM;
      result.timeOffEntryInfo.to = toHHMM;
      result.startTime = this.convertTimeToMinutes(fromHHMM);
      result.endTime = this.convertTimeToMinutes(toHHMM);
    }
    return result;
  }

  private async initUserAttendanceDateRange(): Promise<void> {
    try {
      let oneUserAttendanceDocument;

      const allUserAttendanceForCurrentMonthQuery = {
        _userId: this.userId,
        date: {
          $gte: moment.utc(this.currentDateRange.startDate).toISOString(),
          $lte: moment.utc(this.currentDateRange.endDate).toISOString(),
        },
        _deleted: false,
      };

      const allUserAttendanceForCurrentDateRange: Array<IUserAttendanceModel> = await this.injector
        .get(UserAttendanceService)
        .find(allUserAttendanceForCurrentMonthQuery);

      if (check.assigned(allUserAttendanceForCurrentDateRange) && check.nonEmptyArray(allUserAttendanceForCurrentDateRange)) {
        oneUserAttendanceDocument = allUserAttendanceForCurrentDateRange[0];
      }

      this.allUserAttendanceForCurrentDateRange = _.groupBy(allUserAttendanceForCurrentDateRange, 'date');

      this.daysOfCurrentDateRange = Array.from(
        { length: Math.abs(moment.utc(this.currentDateRange.startDate).diff(moment.utc(this.currentDateRange.endDate), 'days')) + 1 },
        (value, iDayOfMonth) => {
          const dayDate = moment.utc(this.currentDateRange.startDate).add(iDayOfMonth, 'days').toDate();

          return moment(dayDate);
        }
      );

      this.daysOfCurrentDateRange.forEach((iDayOfCurrentMonth: moment.Moment) => {
        if (check.not.assigned(this.allUserAttendanceForCurrentDateRange[iDayOfCurrentMonth.toISOString()])) {
          const defaultUserAttendance: IUserAttendanceModel = {
            _userId: this.userId,
            ownerId: this.userId,
            date: iDayOfCurrentMonth.toDate(),
            startTime: undefined,
            endTime: undefined,
            breakTime: undefined,
            comment: undefined,
            _changesTracking: [],
            _deleted: false,
            _approved: false,
          };
          if (check.not.assigned(oneUserAttendanceDocument)) {
            oneUserAttendanceDocument = defaultUserAttendance;
          }
          this.allUserAttendanceForCurrentDateRange[iDayOfCurrentMonth.toISOString()] = [defaultUserAttendance];
        }
      });

      this.dateRangeIsAttendanceDayComplete = {};
      this.dateRangeAttendanceOfDayApprovedMap = {};

      Object.keys(this.allUserAttendanceForCurrentDateRange).forEach((day) => {
        if (
          check.not.assigned(this.allUserAttendanceForCurrentDateRange[day]) ||
          check.emptyArray(this.allUserAttendanceForCurrentDateRange[day])
        ) {
          return;
        }

        this.dateRangeIsAttendanceDayComplete[day] = (this.allUserAttendanceForCurrentMonth[day] ?? []).every((userAttendance) =>
          calculateIsCloseEntry(userAttendance)
        );
        this.dateRangeAttendanceOfDayApprovedMap[day] = _.every(this.allUserAttendanceForCurrentDateRange[day], ['_approved', true]);
        this.someAttendanceOfDayApprovedMap[day] = _.some(this.allUserAttendanceForCurrentDateRange[day], ['_approved', true]);
      });

      this.pendingToApproveInCurrentDateRange = allUserAttendanceForCurrentDateRange.some(
        (attendance) =>
          attendance._approved === false &&
          calculateIsCloseEntry(attendance) &&
          this.dateRangeIsAttendanceDayComplete[moment.utc(attendance.date).toISOString()]
      );
      this.calculatePendingToApproveDateRangeConflicts(allUserAttendanceForCurrentDateRange);
    } catch (error) {
      throw error;
    }
  }

  // Checks if geolocations should be informed, if true, it informs the object attendanceGeolocationForCurrentMonth and sets the colors for those entries
  private calculateGeolocationStatuses(): Promise<void> {
    if (!this.attendancePolicy.locationTracking) {
      this.attendanceGeolocationForCurrentMonth = null;
      return;
    }

    this.attendanceGeolocationForCurrentMonth = {};
    Object.keys(this.allUserAttendanceForCurrentMonth).forEach((isoDate: string) => {
      if (
        check.assigned(this.allUserAttendanceForCurrentMonth[isoDate]) &&
        this.allUserAttendanceForCurrentMonth[isoDate].length > 0 &&
        check.assigned(this.allUserAttendanceForCurrentMonth[isoDate][0]._changesTracking) &&
        this.allUserAttendanceForCurrentMonth[isoDate][0]._changesTracking.length > 0
      ) {
        this.attendanceGeolocationForCurrentMonth[isoDate] = this.injector
          .get(UserAttendanceService)
          .getColorForAttendances(this.allUserAttendanceForCurrentMonth[isoDate]);
      }
    });
  }

  private initUserWorkSchedule(): Promise<void> {
    if (check.assigned(this.userWorkSchedule) && check.nonEmptyObject(this.userWorkSchedule)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.injector
        .get(UserWorkScheduleService)
        .getById(this.userId)
        .then((userWorkSchedule: IUserWorkScheduleModel) => {
          this.userWorkSchedule = userWorkSchedule;
          if (this.userWorkSchedule.trackAttendance === false) {
            this.router.navigate(['../'], { relativeTo: this.route });
            resolve();
            return;
          }
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  private async getTimeOffTypes(): Promise<void> {
    try {
      const timeOffTypes = await this.injector.get(TimeOffTypeService).getTimeOffTypes();
      this.timeOffTypesById = _.keyBy(timeOffTypes, '_id');
    } catch (error) {
      // An error is already shown
      throw error;
    }
  }

  private initAttendanceSummary(): Promise<void> {
    return new Promise((resolve, reject) => {
      const attendanceSummaryQuery = {
        userId: this.userId,
        month: this.currentMonthDate.month() + 1,
        year: this.currentMonthDate.year(),
      };
      this.injector
        .get(AttendanceSummaryService)
        .getAttendanceSummaryUserByDate(attendanceSummaryQuery)
        .then((attendanceSummary: IAttendanceSummaryModel) => {
          this.attendanceSummaryCurrentMonth = null;

          if (check.assigned(attendanceSummary) && check.nonEmptyArray(attendanceSummary) && check.nonEmptyObject(attendanceSummary[0])) {
            this.attendanceSummaryCurrentMonth = attendanceSummary[0];
            return this.injector.get(AttendanceSummaryService).calculateOvertime(this.attendanceSummaryCurrentMonth._id);
          }
          return;
        })
        .then(() => {
          if (check.assigned(this.attendanceSummaryCurrentMonth) && check.nonEmptyObject(this.attendanceSummaryCurrentMonth)) {
            return this.injector.get(AttendanceSummaryService).getById(this.attendanceSummaryCurrentMonth._id);
          }
          return;
        })
        .then((updatedAttendanceSummary) => {
          if (check.assigned(updatedAttendanceSummary) && check.nonEmptyObject(updatedAttendanceSummary)) {
            this.attendanceSummaryCurrentMonth = updatedAttendanceSummary;
            this.attendanceSummaryId = this.attendanceSummaryCurrentMonth._id;
            this.showAdjustmentWarning = !!this.attendanceSummaryCurrentMonth?.showAdjustmentWarning;
            this.lastMonthOvertime = Math.round(this.attendanceSummaryCurrentMonth.lastMonthOvertime) ?? 0;
            this.currentOvertimeBalance = Math.round(this.attendanceSummaryCurrentMonth.currentOvertimeBalance) ?? 0;
          } else {
            this.showAdjustmentWarning = false;
          }

          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  private async initCategoriesMap() {
    this.categoryIdToCategory = {};
    this.categoryIdToTooltip = {};

    const allCategories: Array<IAttendanceCategory> = await this.injector.get(AttendanceCategoryService).getAll();
    allCategories.forEach((category: IAttendanceCategory) => {
      this.categoryIdToCategory[category._id] = category;
    });
    // Now that we have the map of categoryIdToCategory, init the tooltips
    allCategories.forEach((category: IAttendanceCategory) => {
      if (!category.parentCategoryId) {
        this.categoryIdToTooltip[category._id] = this.injector
          .get(I18nDataPipe)
          .transform(this.i18n.page.editCategoryTooltip, { categoryName: category.name });
      } else {
        const parentCategoryName = this.categoryIdToCategory[category.parentCategoryId].name;
        this.categoryIdToTooltip[category._id] = this.injector
          .get(I18nDataPipe)
          .transform(this.i18n.page.editCategoryWithSubCategoryTooltip, {
            categoryName: parentCategoryName,
            subcategoryName: category.name,
          });
      }
    });
  }

  editAttendanceCategory(userAttendance: IUserAttendanceModel) {
    if (!this.isEditable || !this.listCategoriesForThisUser?.length) {
      return;
    }

    if (!this.allowEntriesInTheFuture && moment(userAttendance.date).isAfter(moment(new Date()).endOf('day'))) {
      return;
    }

    const profileKey = this.loggedUser.profileKey;
    if (userAttendance._approved && profileKey !== 'admin' && profileKey !== 'hr-admin') {
      return;
    }

    const dialogRef = this.injector.get(MatLegacyDialog).open(SetCategoryDialog, {
      data: {
        listCategories: this.listCategoriesForThisUser,
        chosenCategoryId: userAttendance.attendanceSubCategoryId
          ? userAttendance.attendanceSubCategoryId
          : userAttendance.attendanceCategoryId,
      },
    });
    dialogRef.afterClosed().subscribe(async (value: { categoryId?: string; subCategoryId?: string }) => {
      if (value) {
        if (value.categoryId !== userAttendance.attendanceCategoryId || value.subCategoryId !== userAttendance.attendanceSubCategoryId) {
          if (userAttendance._id) {
            // if userAttendance record is not inserted yet, just add the category to the memory
            await this.injector.get(UserAttendanceService).updateById(userAttendance._id, {
              attendanceCategoryId: value.categoryId ?? null,
              attendanceSubCategoryId: value.subCategoryId ?? null,
            });
            this.injector.get(MatLegacySnackBar).open(this.i18n.page.categoryUpdated, 'OK', { duration: 7000 });
          }
          userAttendance.attendanceCategoryId = value.categoryId;
          userAttendance.attendanceSubCategoryId = value.subCategoryId;
          this.injector.get(PrivateAmplitudeService).logEvent('categorize shift', {
            platform: 'Web',
            category: 'Attendance',
            subcategory1: 'Attendance Tab',
            subcategory2: 'Monthly',
          });
        }
      }
    });
  }

  getMinutesWorkedInEditedShift(withoutBreakTime?: boolean): number {
    if (check.assigned(this.timeEntryBeingEdited) && check.assigned(this.timeEntryBeingEdited.iUserAttendance)) {
      const {
        endTime: iEndTime,
        startTime: iStartTime,
        breakTime: iBreakTime,
        _deleted: iDeleted,
      } = this.timeEntryBeingEdited.iUserAttendance;
      const endTime = check.assigned(iEndTime) && check.number(iEndTime) ? iEndTime : 0;
      const startTime = check.assigned(iStartTime) && check.number(iStartTime) ? iStartTime : 0;
      const breakTime = withoutBreakTime ? 0 : check.assigned(iBreakTime) && check.number(iBreakTime) ? iBreakTime : 0;
      const minutesWorkedInEditedShift = iDeleted === false ? endTime - startTime - breakTime : 0;
      return minutesWorkedInEditedShift;
    }

    return 0;
  }

  private computeTotalWorkedMinutesInCurrentMonth(): void {
    this.daysOfCurrentMonthToCalculateWorkTime.forEach((iDayOfCurrentMonth: moment.Moment) => {
      const iUserAttendanceOfDay = this.allUserAttendanceForCurrentMonth[iDayOfCurrentMonth.toISOString()];
      let minutesWorkedThisDay = 0;
      if (iUserAttendanceOfDay) {
        minutesWorkedThisDay = iUserAttendanceOfDay.reduce(
          (minutesWorkedInPreviousShift: number, iUserAttendance: IUserAttendanceModel) => {
            // Hourly absences type unpaid are ignored from tracking
            if (check.assigned(iUserAttendance._allowanceType) && iUserAttendance._allowanceType === ALLOWANCE_TYPE_UNPAID) {
              return minutesWorkedInPreviousShift;
            }

            // In case that the shift was overnight but now it's not
            if (calculateWasOvernight(iUserAttendance)) {
              iUserAttendance.endTime -= 1440;
            }

            if (
              check.assigned(iUserAttendance.endTime) &&
              check.number(iUserAttendance.endTime) &&
              iUserAttendance.endTime < iUserAttendance.startTime
            ) {
              iUserAttendance.endTime += 1440;
            }

            const minutesWorkedInCurrentShift = this.calculateMinutesInGivenShift(iUserAttendance);

            if (minutesWorkedInCurrentShift < 0) {
              return minutesWorkedInPreviousShift;
            }

            return minutesWorkedInPreviousShift + minutesWorkedInCurrentShift;
          },
          0
        );
      }

      if (
        (this.todayDate.format('YYYY MM') === this.currentMonthDate.format('YYYY MM') &&
          this.todayDate.isSameOrAfter(iDayOfCurrentMonth, 'day')) ||
        this.todayDate.format('YYYY MM') !== this.currentMonthDate.format('YYYY MM')
      ) {
        this.totalWorkedMinutesInCurrentMonth += minutesWorkedThisDay;
      }
    });
  }

  private computeTotalPaidHoursInCurrentMonth(): void {
    const startOfMonth = this.currentMonthDate.clone().startOf('month');
    const isCurrentMonth = this.todayDate.isSame(this.currentMonthDate, 'month');
    const totalPaidHoursFromTimeOff: any = Object.entries(this.expectedHoursByDay).reduce((totalAcc, [dayKey, currDay]: [string, any]) => {
      const dayToCheck = startOfMonth.clone().date(Number(dayKey));
      if (!(currDay?.timeOffs?.length > 0) || (isCurrentMonth && dayToCheck.isAfter(this.todayDate, 'day'))) {
        return totalAcc;
      }
      const totalPaidOfDay = currDay.timeOffs.reduce((timeOffAcc: number, currTimeOff: any) => {
        let hoursPerTimeOffRequest = 0;
        // Only count time-off request type PAID, the legacy hours are computed by 'totalWorkedMinutesInCurrentMonth'
        if (currTimeOff._workTime === TIME_OFF_TYPE_ACTIVITY_TYPE_PAID) {
          const timeOffDuration = calculateTimeOffRequestPartOfDayForDate(currTimeOff.from as any, currTimeOff as ITimeOffRequestLib);
          // If timeOffDuration is FullDay, Morning or Afternoon
          if (timeOffDuration) {
            hoursPerTimeOffRequest = currDay.minutesPerDay / (timeOffDuration === HOLIDAY_DURATION_FULL_DAY ? 1 : 2);
          } else {
            hoursPerTimeOffRequest = currTimeOff?.duration ?? 0;
          }
        }
        return timeOffAcc + hoursPerTimeOffRequest;
      }, 0);
      return totalAcc + totalPaidOfDay;
    }, 0);
    this.totalPaidMinutesInCurrentMonth = this.totalWorkedMinutesInCurrentMonth + totalPaidHoursFromTimeOff;
  }

  computeMinutesWorkedEachDay(): void {
    this.totalWorkedMinutesInCurrentMonth = 0;

    this.computeTotalWorkedMinutesInCurrentMonth();
    this.computeTotalPaidHoursInCurrentMonth();

    this.minutesWorkedEachDay = this.daysOfCurrentMonth.map((iDayOfCurrentMonth: moment.Moment) => {
      const iUserAttendanceOfDay = this.allUserAttendanceForCurrentMonth[iDayOfCurrentMonth.toISOString()];
      const minutesWorkedThisDay = iUserAttendanceOfDay.reduce(
        (minutesWorkedInPreviousShift: number, iUserAttendance: IUserAttendanceModel) => {
          // Hourly absences type unpaid are ignored from tracking
          if (check.assigned(iUserAttendance._allowanceType) && iUserAttendance._allowanceType === ALLOWANCE_TYPE_UNPAID) {
            return minutesWorkedInPreviousShift;
          }

          // In case that the shift was overnight but now it's not
          if (calculateWasOvernight(iUserAttendance)) {
            iUserAttendance.endTime -= 1440;
          }

          if (
            check.assigned(iUserAttendance.endTime) &&
            check.number(iUserAttendance.endTime) &&
            iUserAttendance.endTime < iUserAttendance.startTime
          ) {
            iUserAttendance.endTime += 1440;
          }

          const minutesWorkedInCurrentShift = this.calculateMinutesInGivenShift(iUserAttendance);

          if (minutesWorkedInCurrentShift < 0) {
            return minutesWorkedInPreviousShift;
          }

          return minutesWorkedInPreviousShift + minutesWorkedInCurrentShift;
        },
        0
      );

      return minutesWorkedThisDay;
    });

    const sortedReminders = this.attendancePolicy?.breakReminder?.reminders?.sort((a, b) => b.triggeredAfter - a.triggeredAfter);
    this.minBreakEachDay = this.minutesWorkedEachDay.map((iMinutesDay) => {
      const reminder = sortedReminders?.find((iReminder) => {
        return iReminder.triggeredAfter < iMinutesDay;
      });
      return reminder?.suggestedBreak ?? 0;
    });
  }

  computeConflictsPerDay(): void {
    this.amountDaysWithConflicts = 0;

    const attendanceConflictsService = this.injector.get(AttendanceConflictsService);

    this.attendanceConflictsPerDay = this.daysOfCurrentMonth.map((day: moment.Moment) => {
      const conflicts = attendanceConflictsService.filterByDate(this.attendanceConflicts, day.toISOString());
      const hasConflicts = conflicts?.length > 0;

      if (hasConflicts) {
        this.amountDaysWithConflicts++;
      }

      const maxDailyHourConflict = conflicts.find((conflict) => conflict._type === this.CONFLICT_TYPES.MAX_DAILY_HOURS);
      const minBreakConflict = conflicts.find((conflict) => conflict._type === this.CONFLICT_TYPES.MIN_BREAK_TIME);
      const overlappingPublicHolidays = conflicts.find((conflict) => conflict._type === this.CONFLICT_TYPES.OVERLAPPING_PUBLIC_HOLIDAYS);
      const overlappingNonWorkingDay = conflicts.find((conflict) => conflict._type === this.CONFLICT_TYPES.OVERLAPPING_NON_WORKING_DAYS);
      const missingFullEntry = conflicts.find((conflict) => conflict._type === this.CONFLICT_TYPES.MISSING_FULL_ENTRY);
      const entryNotCompleted = conflicts.find((conflict) => conflict._type === this.CONFLICT_TYPES.ENTRY_NOT_COMPLETED);

      return {
        maxDailyHours: !!maxDailyHourConflict,
        minBreakTime: !!minBreakConflict,
        overlappingPublicHolidays: !!overlappingPublicHolidays,
        overlappingNonWorkingDay: !!overlappingNonWorkingDay,
        overlappingConflict: !!overlappingPublicHolidays || !!overlappingNonWorkingDay,
        missingFullEntry: !!missingFullEntry,
        entryNotCompleted: !!entryNotCompleted,
        missingTime: !!missingFullEntry || !!entryNotCompleted,
        hasConflicts,
        configuration: {
          maxHoursValue: maxDailyHourConflict?.configuration?.value ?? 0,
          minBreakValue: minBreakConflict?.configuration?.value ?? 0,
          isMaxHoursPastValue: maxDailyHourConflict?.configuration?.isPastValue,
          isMinBreakPastValue: minBreakConflict?.configuration?.isPastValue,
          isPublicHolidayPastValue: overlappingPublicHolidays?.configuration?.isPastValue,
          isNonWorkingDayPastValue: overlappingNonWorkingDay?.configuration?.isPastValue,
          isMissingFullEntryPastValue: missingFullEntry?.configuration?.isPastValue,
          isEntryNotCompletedPastValue: entryNotCompleted?.configuration?.isPastValue,
        },
        menuOpened: false,
      };
    });
  }

  async calculateCompensatedHours(): Promise<void> {
    if (!this.userWorkSchedule?.overtimeSettings?.trackOvertime) {
      return;
    }

    const options = {
      userId: this.userId,
      month: moment(this.currentMonthDate).month() + 1,
      year: moment(this.currentMonthDate).year(),
    };

    const detail = await this.injector.get(OvertimeBalanceDetailsController).getUserMonthDetails(options);

    this.compensatedHours = detail.history.reduce((acc: number, iEntry) => {
      if (iEntry.type === OVERTIME_HISTORY_ENTRY_TYPE_PAY || iEntry.type === OVERTIME_HISTORY_ENTRY_TYPE_TIME_OFF) {
        return acc + Number(iEntry.minutes ?? 0);
      }

      return acc;
    }, 0);
  }

  calculateMinutesInGivenShift(userAttendance: IUserAttendanceModel): number {
    const endTime = check.assigned(userAttendance.endTime) && check.number(userAttendance.endTime) ? userAttendance.endTime : undefined;
    const startTime =
      check.assigned(userAttendance.startTime) && check.number(userAttendance.startTime) ? userAttendance.startTime : undefined;
    const breakTime = check.assigned(userAttendance.breakTime) && check.number(userAttendance.breakTime) ? userAttendance.breakTime : 0;

    return userAttendance._deleted === false && check.assigned(startTime) && check.assigned(endTime) ? endTime - startTime - breakTime : 0;
  }

  computeMinutesWorkedEachDayInCurrentRange(): void {
    this.totalWorkedMinutesInCurrentRange = 0;

    this.minutesWorkedEachDayInCurrentRange = this.daysOfCurrentDateRange.map((iDayOfCurrentMonth: moment.Moment) => {
      const iUserAttendanceOfDay = this.allUserAttendanceForCurrentDateRange[iDayOfCurrentMonth.toISOString()];
      const minutesWorkedThisDay = iUserAttendanceOfDay.reduce(
        (minutesWorkedInPreviousShift: number, iUserAttendance: IUserAttendanceModel) => {
          // Hourly absences type unpaid are ignored from tracking
          if (check.assigned(iUserAttendance._allowanceType) && iUserAttendance._allowanceType === ALLOWANCE_TYPE_UNPAID) {
            return minutesWorkedInPreviousShift;
          }
          if (
            check.assigned(iUserAttendance.endTime) &&
            check.number(iUserAttendance.endTime) &&
            iUserAttendance.endTime < iUserAttendance.startTime
          ) {
            iUserAttendance.endTime += 1440;
          }
          const endTime = check.assigned(iUserAttendance.endTime) && check.number(iUserAttendance.endTime) ? iUserAttendance.endTime : 0;
          const startTime =
            check.assigned(iUserAttendance.startTime) && check.number(iUserAttendance.startTime) ? iUserAttendance.startTime : 0;
          const breakTime =
            check.assigned(iUserAttendance.breakTime) && check.number(iUserAttendance.breakTime) ? iUserAttendance.breakTime : 0;
          const minutesWorkedInCurrentShift = iUserAttendance._deleted === false ? endTime - startTime - breakTime : 0;
          if (minutesWorkedInCurrentShift < 0) {
            return minutesWorkedInPreviousShift;
          }

          return minutesWorkedInPreviousShift + minutesWorkedInCurrentShift;
        },
        0
      );

      if (
        this.todayDate.format('YYYY MM') === this.currentMonthDate.format('YYYY MM') &&
        this.todayDate.isSameOrAfter(iDayOfCurrentMonth, 'day')
      ) {
        this.totalWorkedMinutesInCurrentRange += minutesWorkedThisDay;
      } else if (this.todayDate.format('YYYY MM') !== this.currentMonthDate.format('YYYY MM')) {
        this.totalWorkedMinutesInCurrentRange += minutesWorkedThisDay;
      }

      return minutesWorkedThisDay;
    });
  }

  async changeMonth(step: number): Promise<void> {
    if (this.loadingChangeMonth === true) {
      return;
    }
    this.loadingChangeMonth = true;
    this.currentMonthDate = this.currentMonthDate.clone().add(step, 'month');
    this.monthHasChanged = true;
    try {
      await this.refreshData();
    } catch (error) {
      // Handle error if needed
    } finally {
      if (this.activeFilterOfConflicts) {
        if (this.attendanceConflicts.length > 0) {
          this.activeFilterOfConflicts = false;
        }
        this.filterByConflicts();
      }
      this.updateAttendanceSummary();
    }
  }

  async changeParticularMonth(monthDates: IQueryDates): Promise<void> {
    if (this.loadingChangeMonth === true) {
      return;
    }
    this.loadingChangeMonth = true;
    this.currentMonthDate = monthDates.startDayOfMonth;
    this.monthHasChanged = true;
    try {
      await this.refreshData();
    } catch {
      // Handle error if needed
    } finally {
      if (this.activeFilterOfConflicts) {
        if (this.attendanceConflicts.length > 0) {
          this.activeFilterOfConflicts = false;
        }
        this.filterByConflicts();
      }
      this.updateAttendanceSummary();
    }
  }

  addTimeEntry(dayOfMonth: moment.Moment): void {
    if (!this.isEditable) {
      return;
    }

    const defaultUserAttendance: IUserAttendanceModel = {
      _userId: this.userId,
      ownerId: this.userId,
      date: dayOfMonth.toDate(),
      startTime: undefined,
      endTime: undefined,
      breakTime: undefined,
      comment: undefined,
      _changesTracking: [],
      _deleted: false,
      _approved: false,
      breaks: [],
    };

    this.updateTimeEntryErrors(dayOfMonth);

    if (
      check.assigned(this.timeEntryErrors[dayOfMonth.toISOString()]) &&
      Object.keys(this.timeEntryErrors[dayOfMonth.toISOString()]).length > 1
    ) {
      return;
    }

    const index = this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()].findIndex((entry) => {
      return entry?.timeOffEntryInfo?.duration === 'Afternoon';
    });
    if (index !== -1) {
      this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()].splice(index, 0, defaultUserAttendance);
    } else {
      this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()].push(defaultUserAttendance);
    }
    this.computeMinutesWorkedEachDay();
  }

  updateTimeEntryErrors(dayOfMonth: moment.Moment, resetErrors: boolean = false): void {
    const allTimeEntriesOfTheDay = this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()];

    if (resetErrors) {
      this.timeEntryErrors = {};
      return;
    }

    if (!this.timeEntryErrors[dayOfMonth.toISOString()]) {
      this.timeEntryErrors = {};
    }

    allTimeEntriesOfTheDay.forEach((date: IUserAttendanceModel, index: number) => {
      const errors: any = {
        index,
      };

      let hasErrors = false;

      if (date.startTime === null || date.startTime === undefined) {
        errors.missingStartTime = true;
        hasErrors = true;
      }

      if (date.endTime === null || date.endTime === undefined) {
        errors.missingEndTime = true;
        hasErrors = true;
      }

      if (errors.missingStartTime && errors.missingEndTime) {
        errors.missingEntireShift = true;
        hasErrors = true;
      }

      if (
        this.attendanceOfDayChangedMap[dayOfMonth.toISOString()] &&
        this.timeEntryBeingEdited?.index === index &&
        this.timeEntryBeingEdited?.dayOfMonth?.toISOString() === dayOfMonth.toISOString()
      ) {
        errors.mustSaveEditedShift = true;
        hasErrors = true;
      }

      if (hasErrors) {
        this.timeEntryErrors[dayOfMonth.toISOString()] = { ...errors };
      }
    });
  }

  updateTimeEntryInlineErrors(
    dayOfMonth: moment.Moment,
    indexOfDay: number,
    indexOfTimeEntry: number,
    resetErrors: boolean = false,
    userAttendance: IUserAttendanceModel = null
  ): void {
    this.cdr.detectChanges();
    const allTimeEntriesOfTheDay = this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()];
    const currentEntry = this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry];
    if (resetErrors) {
      this.entryHasErrors = false;
      this.timeEntryInlineErrors = {};
      return;
    }

    if (!this.timeEntryInlineErrors[dayOfMonth.toISOString()]) {
      this.entryHasErrors = false;
      this.timeEntryInlineErrors = {};
    }

    let anyHadErrors;
    const todayMaxDailyHourConflict = this.injector
      .get(AttendanceConflictsService)
      .filterByDateAndType(this.attendanceConflicts, moment(dayOfMonth.toISOString()).toISOString(), this.CONFLICT_TYPES.MAX_DAILY_HOURS);
    // Update these method to check for other types of conflicts.
    const conflictIndex = this.daysOfCurrentMonth?.findIndex((iDay) => iDay.date() === dayOfMonth.date());
    if (this.timeEntryBeingEdited || this.attendanceConflictsPerDay[conflictIndex].hasConflicts) {
      this.updateAttendanceConflictsOnTheFly(indexOfDay, todayMaxDailyHourConflict);
    }

    allTimeEntriesOfTheDay.forEach((iShift: IUserAttendanceModel, index: number) => {
      let errors: any = {
        index: indexOfTimeEntry,
      };

      let hasErrors = false;
      // Overlapping validation: always check overlapping between entries, but if the time-off overlapping is active it is considered as well
      if (
        (index !== indexOfTimeEntry && calculateIsCloseEntry(iShift) && !iShift.timeOffEntryInfo) ||
        evaluateOverlappingTimeOff(iShift, this.timeOffOverlappingValidationIsActive)
      ) {
        if (
          currentEntry.startTime &&
          !currentEntry.endTime &&
          currentEntry.startTime >= (iShift.startTime ?? 0) &&
          currentEntry.startTime < (iShift.endTime ?? 0)
        ) {
          errors.overlappingStartTime = true;
        }
        if (currentEntry.startTime < iShift.endTime && currentEntry.startTime >= iShift.startTime) {
          errors.overlappingStartTime = true;
        }

        if (
          (currentEntry.endTime > iShift.startTime && currentEntry.endTime <= iShift.endTime) ||
          (currentEntry.startTime <= iShift.startTime && currentEntry.endTime >= iShift.endTime)
        ) {
          errors.overlappingEndTime = true;
        }

        if (errors.overlappingStartTime || errors.overlappingEndTime) {
          hasErrors = true;
        }
      }

      if (index === indexOfTimeEntry && calculateIsCloseEntry(currentEntry)) {
        if (check.assigned(currentEntry.breakTime)) {
          const breakTime = currentEntry.breakTime;
          if (
            breakTime >
            (check.assigned(this.timeEntryBeingEdited?.iUserAttendance)
              ? this.getMinutesWorkedInEditedShift(true)
              : currentEntry?.endTime - currentEntry?.startTime)
          ) {
            errors = { index: indexOfTimeEntry };

            errors.breakTimeCannotBeLonger = true;
            errors.generalError = true;
            hasErrors = true;
          }
        }

        if (currentEntry.startTime === currentEntry.endTime) {
          errors = { index: indexOfTimeEntry };

          errors.startTimeEqualsEndTime = true;
          errors.generalError = true;
          hasErrors = true;
        }
        if (
          this.getMinutesWorkedInEditedShift() > this.MAX_SHIFT_LENGTH &&
          this.minutesWorkedEachDay[indexOfDay] <= this.MAX_HOURS_PER_DAY
        ) {
          errors = { index: indexOfTimeEntry };

          errors.maxHoursPerShiftReached = true;
          errors.generalError = true;
          hasErrors = true;
        }

        if (this.minutesWorkedEachDay[indexOfDay] > this.MAX_HOURS_PER_DAY && !todayMaxDailyHourConflict) {
          errors = { index: indexOfTimeEntry };

          errors.maxHoursPerDayReached = true;
          errors.generalError = true;
          hasErrors = true;
        }
      }

      if (
        calculateIsOpenEndTimeEntry(currentEntry.startTime, currentEntry.endTime) &&
        calculateIsOpenEndTimeEntry(iShift.startTime, iShift.endTime) &&
        index !== indexOfTimeEntry
      ) {
        errors = { index: indexOfTimeEntry };
        errors.mustSaveEditedShift = true;
        hasErrors = true;
      }

      if (
        index === indexOfTimeEntry &&
        check.not.assigned(currentEntry.startTime) &&
        (check.not.assigned(currentEntry._id) || (check.assigned(currentEntry._id) && check.assigned(currentEntry.endTime)))
      ) {
        errors = { index: indexOfTimeEntry };

        hasErrors = true;
      }

      if (!hasErrors && hasShiftBreakConflict(currentEntry)) {
        errors = { index: indexOfTimeEntry };
        errors.breakAndShiftConflict = true;
        errors.generalError = true;

        hasErrors = true;
      }

      this.isRestrictCheckIn = false;
      if (userAttendance) {
        this.calculateIsFutureEntry(userAttendance, this.allowEntriesInTheFuture);
      } else {
        this.calculateIsFutureEntry(currentEntry, this.allowEntriesInTheFuture);
      }
      if (check.not.assigned(currentEntry.startTime)) {
        this.isRestrictCheckIn = this.restrictCheckIn(userAttendance);
      } else {
        this.isRestrictCheckIn = this.restrictCheckIn(currentEntry);
        if (!this.isRestrictCheckIn && userAttendance?.startTime) {
          this.isRestrictCheckIn = this.restrictCheckIn(userAttendance);
        }
      }

      if (this.isRestrictCheckIn) {
        errors.restrictCheckIn = true;
        hasErrors = true;
      }
      if (this.futureEntriesErrors.isFutureEntry) {
        errors.isFutureEntry = true;
        hasErrors = true;
      } else if (this.futureEntriesErrors.isOpenShift) {
        errors.isOpenShift = true;
        hasErrors = true;
      }

      if (hasErrors) {
        anyHadErrors = true;
        this.entryHasErrors = true;
        this.timeEntryInlineErrors[dayOfMonth.toISOString()] = { ...errors };
      }
    });
    if (!anyHadErrors) {
      this.entryHasErrors = false;
      this.timeEntryInlineErrors = {};
    }
  }

  updateTimeEntryBeingEdited(
    newValue:
      | undefined
      | {
          dayOfMonth: moment.Moment;
          index: number;
          iUserAttendance: IUserAttendanceModel;
          type: string | 'startTime' | 'endTime' | 'breakTime' | 'comment' | 'breaks';
          value: number | string | Array<IBreak>;
        }
  ) {
    if (check.assigned(newValue)) {
      if (check.not.assigned(this.originalAttendance)) {
        this.originalAttendance = { ...this.allUserAttendanceForCurrentMonth[newValue?.dayOfMonth.toISOString()][newValue?.index] };
      }

      this.allUserAttendanceForCurrentMonth[newValue.dayOfMonth.toISOString()][newValue.index][newValue.type] = newValue.value;

      if (!this.areAttendancesEqual(newValue.dayOfMonth.toISOString(), newValue.index)) {
        this.attendanceOfDayChangedMap[newValue?.dayOfMonth.toISOString()] = true;
        this.timeEntryBeingEdited = newValue;
      } else {
        delete this.attendanceOfDayChangedMap[newValue?.dayOfMonth.toISOString()];
        this.resetTimeEntryBeingEdited();
      }

      return;
    }

    this.resetTimeEntryBeingEdited();
  }

  resetTimeEntryBeingEdited(): void {
    this.originalAttendance = undefined;
    this.timeEntryBeingEdited = undefined;
  }

  areAttendancesEqual(dayOfMonth: string, index: number): boolean {
    let areAttendancesEqual = true;
    const attendanceToCompare = this.allUserAttendanceForCurrentMonth[dayOfMonth][index];
    if (check.assigned(attendanceToCompare?.endTime)) {
      attendanceToCompare.endTime = setRealEndTime(attendanceToCompare);
    }
    if (check.assigned(attendanceToCompare)) {
      Object.keys(attendanceToCompare).forEach((key: string) => {
        if (
          (this.originalAttendance[key] !== undefined && !_.isEqual(this.originalAttendance[key], attendanceToCompare[key])) ||
          (this.originalAttendance[key] === undefined && check.assigned(attendanceToCompare[key]))
        ) {
          areAttendancesEqual = false;
        }
      });
    }

    return areAttendancesEqual;
  }

  copyTimeEntry(dayOfMonth: moment.Moment, indexOfTimeEntry: number): void {
    this.copiedTimeEntry = { ...this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry] };
  }

  toggleMaskedPlaceholder(dayOfMonth: moment.Moment, indexOfTimeEntry: number, showPlaceholder: boolean) {
    this.copiedTimeEntryPlaceholder = showPlaceholder ? { day: dayOfMonth, index: indexOfTimeEntry } : {};
  }

  parseTimeEntry(
    totalMinutes: number | undefined | null,
    type: 'startTime' | 'endTime' | 'breakTime',
    dayOfMonth: moment.Moment,
    indexOfTimeEntry: number
  ): string {
    if (check.not.assigned(totalMinutes)) {
      return check.assigned(this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry]?.[type]) ? ' ' : null;
    }

    const hours = Math.floor((totalMinutes / 60) % 24);
    const minutes = (((totalMinutes / 60) % 24) - Math.floor((totalMinutes / 60) % 24)) * 60;

    return `${this.injector.get(DecimalPipe).transform(hours, '2.0-0')}:${this.injector.get(DecimalPipe).transform(minutes, '2.0-0')}`;
  }

  pasteTimeEntry(dayOfMonth: moment.Moment, indexOfDay: number, indexOfTimeEntry: number): void {
    this.updateTimeEntryBeingEdited({
      dayOfMonth: dayOfMonth,
      index: indexOfTimeEntry,
      iUserAttendance: this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry],
      type: 'startTime',
      value: this.copiedTimeEntry.startTime ?? null,
    });
    this.updateTimeEntryBeingEdited({
      dayOfMonth: dayOfMonth,
      index: indexOfTimeEntry,
      iUserAttendance: this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry],
      type: 'endTime',
      value: this.copiedTimeEntry.endTime ?? null,
    });
    this.updateTimeEntryBeingEdited({
      dayOfMonth: dayOfMonth,
      index: indexOfTimeEntry,
      iUserAttendance: this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry],
      type: 'breakTime',
      value: this.copiedTimeEntry.breakTime ?? null,
    });
    this.updateTimeEntryBeingEdited({
      dayOfMonth: dayOfMonth,
      index: indexOfTimeEntry,
      iUserAttendance: this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry],
      type: 'breaks',
      value: this.copiedTimeEntry.breaks ?? null,
    });
    this.computeMinutesWorkedEachDay();

    if (check.assigned(this.copiedTimeEntry.attendanceCategoryId)) {
      this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry].attendanceCategoryId =
        this.copiedTimeEntry.attendanceCategoryId;
    }

    this.updateTimeEntryErrors(dayOfMonth, true);
    this.updateTimeEntryInlineErrors(dayOfMonth, indexOfDay, indexOfTimeEntry);

    this.isTimeEntryEditable(dayOfMonth, indexOfTimeEntry);
    this.canApproveOrEdit(dayOfMonth);
  }

  deleteTimeEntry(dayOfMonth: moment.Moment, indexOfTimeEntry: number): void {
    if (!this.isEditable) {
      return;
    }

    const data = {
      titleText: this.i18n.page.deleteTimeEntryConfirmDialog.title,
      subtitleText: this.i18n.page.deleteTimeEntryConfirmDialog.text,
      confirmButtonText: this.i18n.page.deleteTimeEntryConfirmDialog.yesDelete,
      confirmButtonColor: 'Success',
      cancelButtonText: this.i18n.page.deleteTimeEntryConfirmDialog.noGoBack,
    };

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });

    dialogRef.afterClosed().subscribe((value) => {
      if (check.assigned(value) && value === true) {
        const timeEntryToDelete = this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry];

        if (
          check.assigned(timeEntryToDelete._id) &&
          check.not.emptyString(timeEntryToDelete._id) &&
          check.not.array(this.attendanceToDeleteMap[dayOfMonth.toISOString()])
        ) {
          this.attendanceToDeleteMap[dayOfMonth.toISOString()] = [timeEntryToDelete._id];
        } else if (
          check.assigned(timeEntryToDelete._id) &&
          check.not.emptyString(timeEntryToDelete._id) &&
          check.array(this.attendanceToDeleteMap[dayOfMonth.toISOString()])
        ) {
          this.attendanceToDeleteMap[dayOfMonth.toISOString()].push(timeEntryToDelete._id);
        }

        this.allUserAttendanceForCurrentMonth[dayOfMonth.toISOString()][indexOfTimeEntry]._deleted = true;

        this.subtractWhenDelete = true;
        this.save(dayOfMonth, indexOfTimeEntry, true);
      }
    });
  }

  /**
   * save(dateToSave: string)
   * Description: Method executed every time a save button is clicked. Apply 'reduce' over an array of entries with the same date to ensure it is executed sequentially
   */
  async save(dateToSave: moment.Moment, indexOfTimeEntry: number, useAsDelete: boolean = false): Promise<void> {
    if (!this.isEditable) {
      return;
    }

    this.savingTimeEntries = true;
    const userAttendanceToSave = this.allUserAttendanceForCurrentMonth[dateToSave.toISOString()][indexOfTimeEntry];
    delete userAttendanceToSave.fullDayTimeOff;
    const approveEntry = !useAsDelete && check.not.assigned(userAttendanceToSave?._id);
    try {
      if (check.not.assigned(userAttendanceToSave) || (check.not.assigned(userAttendanceToSave?.startTime) && !useAsDelete)) {
        return;
      }

      if (check.assigned(this.attendanceConflicts) && this.attendanceConflicts.length > 0) {
        this.attendanceConflictsToCheck = this.attendanceConflicts.filter((iAttendanceConflict: IAttendanceConflict) => {
          return moment(iAttendanceConflict._date).toISOString() === dateToSave.toISOString();
        });
      }

      const userAttendanceUpdated = (await this.saveUserAttendance(userAttendanceToSave)) as IUserAttendanceModel;
      const breakReminderConfig = await this.shouldOpenBreakReminder(userAttendanceToSave);

      if (check.assigned(userAttendanceUpdated)) {
        this.allUserAttendanceForCurrentMonth[dateToSave.toISOString()][indexOfTimeEntry] = userAttendanceUpdated;
      }

      if (
        approveEntry &&
        this.canEdit &&
        this.someAttendanceOfDayApprovedMap[dateToSave.toISOString()] &&
        userAttendanceToSave._approved === false &&
        check.assigned(userAttendanceToSave.startTime) &&
        check.assigned(userAttendanceToSave.endTime)
      ) {
        this.approveDay(dateToSave);
      }

      delete this.attendanceOfDayChangedMap[dateToSave.toISOString()];
      delete this.attendanceToDeleteMap[dateToSave.toISOString()];

      if (breakReminderConfig.openReminder) {
        await this.openBreakReminder(breakReminderConfig, userAttendanceToSave._userId, userAttendanceToSave.date);
      }
      if (check.assigned(userAttendanceToSave?.endTime) && userAttendanceToSave?._deleted !== true) {
        if (check.not.assigned(userAttendanceToSave._id)) {
          userAttendanceToSave._id = userAttendanceUpdated._id;
        }
        await this.injector
          .get(UserAttendanceService)
          .autoDeductBreak(
            this.allUserAttendanceForCurrentMonth[dateToSave.toISOString()][indexOfTimeEntry],
            this.attendancePolicy,
            this.loggedUser.profileKey
          );
      }
    } catch {
      // Do nothing
    } finally {
      this.logAmplitudeEvent(userAttendanceToSave);
      this.savingTimeEntries = false;
      this.updateTimeEntryBeingEdited(undefined);
      this.updateTimeEntryErrors(dateToSave, true);
      this.updateTimeEntryInlineErrors(dateToSave, 0, 0, true);
      await this.disapproveDay(userAttendanceToSave);
      await this.initAttendanceSummary();
      await this.initUserAttendance();
      if (this.attendanceConflicts?.length === 0 && this.activeFilterOfConflicts) {
        await this.filterByConflicts();
      }
      if (this.activeFilterOfConflicts) {
        this.computeDaysOfTheMonthWithConflicts();
      }
      this.handleConflictsSnackBar(dateToSave);

      this.computeMinutesWorkedEachDay();
      if (useAsDelete) {
        this.cleanDeletedRecordsFromAllUserAttendance(dateToSave.toISOString());
      }
      this.isTimeEntryEditable(dateToSave, indexOfTimeEntry);
      this.canApproveOrEdit(dateToSave);
    }
  }

  private handleConflictsSnackBar(dateToSave: moment.Moment) {
    if (check.assigned(this.attendanceConflictsToCheck) && this.attendanceConflictsToCheck.length > 0) {
      const attendanceConflictsAfterUpdate: IAttendanceConflict[] = this.attendanceConflicts.filter(
        (iAttendanceConflict: IAttendanceConflict) => {
          return moment(iAttendanceConflict._date).toISOString() === dateToSave.toISOString();
        }
      );
      if (this.attendanceConflicts.length === 0) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.page.allConflictsOfThisMonthSolved, 'OK', { duration: 7000 });
      } else if (attendanceConflictsAfterUpdate.length === 0) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.page.allConflictsOfThisDaySolved, 'OK', { duration: 7000 });
      } else if (attendanceConflictsAfterUpdate.length < this.attendanceConflictsToCheck.length) {
        const conflictsSolved = this.attendanceConflictsToCheck.length - attendanceConflictsAfterUpdate.length;
        const data = {
          solvedConflicts: conflictsSolved,
          totalConflicts: this.attendanceConflictsToCheck.length,
        };
        this.injector
          .get(MatLegacySnackBar)
          .open(`${this.injector.get(I18nDataPipe).transform(this.i18n.page.variableConflictsOfThisDaySolved, data)}`, 'OK', {
            duration: 7000,
          });
      } else if (attendanceConflictsAfterUpdate.length === this.attendanceConflictsToCheck.length && this.activeFilterOfConflicts) {
        this.injector
          .get(MatLegacySnackBar)
          .open(this.i18n.page.shiftSavedWithConflicts, 'OK', { duration: 7000, panelClass: 'kenjo-error-snackbar' });
      }
    }
    this.attendanceConflictsToCheck = [];
  }

  async openBreakReminder(breakReminderConfig: IBreakReminderConfiguration, userId: string, date: Date) {
    const breaks = await this.injector
      .get(MatLegacyDialog)
      .open(BreakReminderDialog, {
        data: {
          breakReminderConfig,
          translations: this.i18n.breakReminderDialog,
        },
      })
      .afterClosed()
      .toPromise();

    if (check.assigned(breaks)) {
      await this.injector.get(BreakReminderController).distributeBreaks(userId, date, breaks, ATTENDANCE_INTERFACE_ATTENDANCE_TAB);
      await this.initUserAttendance();
    }
  }

  logAmplitudeEvent(userAttendance: IUserAttendanceModel) {
    const operation = check.assigned(userAttendance) && userAttendance._deleted ? 'delete' : 'upsert';

    if (operation === 'upsert' && check.assigned(userAttendance._id)) {
      if (check.not.assigned(this.originalAttendance)) {
        return;
      }
      this.injector.get(PrivateAmplitudeService).logEvent('update one shift', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Attendance Tab',
        subcategory2: 'Monthly',
        subcategory3: 'Manual entry',
      });
    } else if (operation === 'upsert' && check.not.assigned(userAttendance._id)) {
      this.injector.get(PrivateAmplitudeService).logEvent('save one shift', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Attendance Tab',
        subcategory2: 'Monthly',
        subcategory3: 'Manual entry',
      });
      this.injector.get(PrivateIntegrationsService).trackChameleonEvent('save attendance entry');
    } else if (operation === 'delete' && check.assigned(userAttendance._id)) {
      this.injector.get(PrivateAmplitudeService).logEvent('delete one shift', {
        platform: 'Web',
        category: 'Attendance',
        subcategory1: 'Attendance Tab',
        subcategory2: 'Monthly',
        subcategory3: 'Manual entry',
      });
    }
  }

  cleanDeletedRecordsFromAllUserAttendance(dateToUpdate: string): void {
    this.allUserAttendanceForCurrentMonth[dateToUpdate] = this.allUserAttendanceForCurrentMonth[dateToUpdate].filter((timeEntry) => {
      return timeEntry._deleted === false || check.assigned(timeEntry.timeOffEntryInfo);
    });
  }

  /**
   * saveUserAttendance(iUserAttendance)
   * Description: Method executed by the reduce function to ensure it is executed sequentially.
   * 1. Get the current value 'iUserAttendance' of the iteration by reduce
   * 2. Execute de corresponding CRUD method (promised)
   * 3. The returned value 'saveResult' is evaluated once the promise is solved to update the 'allUserAttendanceForCurrentMonth' array with new values
   */
  saveUserAttendance(iUserAttendance: IUserAttendanceModel) {
    return new Promise(async (resolve, reject) => {
      const operation = check.assigned(iUserAttendance) && iUserAttendance._deleted ? 'delete' : 'upsert';
      let callToSave;

      (iUserAttendance as any).interface = ATTENDANCE_INTERFACE_ATTENDANCE_TAB;
      if (operation === 'upsert' && check.assigned(iUserAttendance._id)) {
        callToSave = this.injector.get(UserAttendanceService).updateById(iUserAttendance._id, iUserAttendance);
      } else if (operation === 'upsert' && check.not.assigned(iUserAttendance._id)) {
        callToSave = this.injector.get(UserAttendanceService).create(iUserAttendance);
      } else if (operation === 'delete' && check.assigned(iUserAttendance._id)) {
        callToSave = this.injector.get(UserAttendanceService).deleteById(iUserAttendance._id);
      } else {
        return resolve(undefined);
      }

      callToSave
        .then((saveResult) => {
          resolve(saveResult);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  filterReportedShifts(allUserAttendanceForCurrentDay: Array<IUserAttendanceModel>): Array<IUserAttendanceModel> {
    return allUserAttendanceForCurrentDay.filter((shift) => {
      if (shift._deleted === false) {
        allUserAttendanceForCurrentDay.forEach((iUserAttendance) => {
          iUserAttendance._changesTracking?.forEach((iChange) => {
            splitChangeTrackingBreaks(iChange);
          });
        });
      }

      return shift._deleted === false;
    });
  }

  filterDeletedShifts(allUserAttendanceForCurrentDay: Array<IUserAttendanceModel>): Array<IUserAttendanceModel> {
    const filteredDeletedShifts = allUserAttendanceForCurrentDay.filter((shift) => {
      if (shift._deleted === true) {
        allUserAttendanceForCurrentDay.forEach((iUserAttendance) => {
          iUserAttendance._changesTracking?.forEach((iChange) => {
            splitChangeTrackingBreaks(iChange);
          });
        });
      }

      return shift._deleted === true;
    });

    const orderedDeletedShifts = _.orderBy(filteredDeletedShifts, ['_deletedAt'], ['asc']);
    return orderedDeletedShifts;
  }

  exportToPDF(): void {
    this.downloadingDocument = true;
    this.injector
      .get(AttendanceSummaryController)
      .getAttendanceMonthInfoPDF(this.userId, this.currentMonthDate.month(), this.currentMonthDate.year())
      .then((result) => {
        const fileName = `${this.userPersonal.displayName}-${this.currentMonthDate.month() + 1}-${this.currentMonthDate.year()}.pdf`;
        FileSaver.saveAs(new Blob([result]), fileName);
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('export to pdf', { platform: 'Web', category: 'Attendance', subcategory1: 'Attendance Tab', subcategory2: 'Monthly' });
        this.downloadingDocument = false;
      })
      .catch(() => {
        this.downloadingDocument = false;
      });
  }

  exportToDoc(): void {
    const language = this.injector.get(PrivateInternationalizationService).getLanguage();
    const templateId = `attendance-template-${language}-v3.docx`;
    const fileContent = this.getFileContent();
    this.injector
      .get(DocumentExportService)
      .getDocument(templateId, fileContent)
      .then((documentToExport) => {
        const fileName = `${this.FILENAME}-${fileContent.monthDate}-${fileContent.displayName}.docx`;
        FileSaver.saveAs(new Blob([documentToExport]), fileName);
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('export to doc', { platform: 'Web', category: 'Attendance', subcategory1: 'Attendance Tab', subcategory2: 'Monthly' });
      })
      .catch(() =>
        // Do nothing
        {}
      );
  }

  async approveDay(dayOfMonth: moment.Moment): Promise<void> {
    const monthNumber = this.currentMonthDate.month();
    const dayNumber = dayOfMonth.date();
    const conflictIndex = this.daysOfCurrentMonth?.findIndex((iDay) => iDay.date() === dayOfMonth.date());
    const year: string = this.currentMonthDate.year().toString();
    const month: string = moment().month(monthNumber).format('MM');
    const day: string = moment().month(monthNumber).date(dayNumber).format('DD');
    this.attendanceConflictsPerDay[conflictIndex].text = {
      limitExceeded: this.i18n.page.limitExceeded,
      missingTime: this.i18n.page.missingTime,
      entryNotCompleted: this.i18n.page.entryNotCompleted,
      maximumHours: this.i18n.page.maximumHours,
      overlappingPublicHolidays: this.i18n.page.publicHolidays,
      overlappingNonWorkingDay: this.i18n.page.nonWorkingDays,
      overlappingEntriesOn: this.i18n.page.overlappingEntries,
      breakNotReached: this.i18n.page.breakNotReached,
      breakScheme: this.i18n.page.breakScheme,
      minBreakToday: this.minBreakEachDay[conflictIndex],
      tooltipConflict: this.i18n.page.conflictConfigHistory,
      tooltipConflictOff: this.i18n.page.conflictConfigHistoryOff,
    };
    const dialogData = {
      titleText: this.i18n.page.ignoreConflictsDialog.approveTitle,
      subtitleText: this.i18n.page.ignoreConflictsDialog.approveSubtitle,
      confirmButtonText: this.i18n.page.ignoreConflictsDialog.ignoreApproveButton,
      confirmButtonColor: 'Success',
      cancelButtonText: this.i18n.page.ignoreConflictsDialog.cancel,
      attendanceConflicts: this.attendanceConflictsPerDay[conflictIndex],
      attendancePolicy: this.attendancePolicy,
      configuration: this.attendanceConflictsPerDay[conflictIndex].configuration,
    };
    const conflicts = this.injector.get(AttendanceConflictsService).filterByDate(this.attendanceConflicts, dayOfMonth.toISOString());
    try {
      if (conflicts && conflicts?.length > 0) {
        const dialogRef = this.injector.get(MatLegacyDialog).open(ConflictsDialogComponent, { data: dialogData });
        const ignore = await dialogRef.afterClosed().toPromise();
        if (ignore) {
          await this.injector.get(UserAttendanceService).approveDay(this.userId, year, month, day);
          await this.injector.get(PrivateAmplitudeService).logEvent('approve day', {
            platform: 'Web',
            category: 'Attendance',
            subcategory1: 'Attendance Tab',
            subcategory2: 'Monthly',
          });
          this.injector.get(PrivateIntegrationsService).trackChameleonEvent('approve day');
          await this.ignoreConflicts(dayOfMonth.toISOString(), true);
          this.injector.get(MatLegacySnackBar).open(
            this.injector.get(I18nDataPipe).transform(this.i18n.page.approveAndIgnore, {
              date: `${moment(dayOfMonth).format('MMMM')} ${moment(dayOfMonth).format('DD')}`,
            }),
            'OK',
            { duration: 5000 }
          );
        }
      } else {
        await this.injector.get(UserAttendanceService).approveDay(this.userId, year, month, day);
        await this.injector.get(PrivateAmplitudeService).logEvent('approve day', {
          platform: 'Web',
          category: 'Attendance',
          subcategory1: 'Attendance Tab',
          subcategory2: 'Monthly',
        });
      }
      await this.initUserAttendance();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PeopleDetailAttendancePage.name, 'approveDay');
    } finally {
      if (this.activeFilterOfConflicts) {
        if (this.attendanceConflicts.length > 0) {
          this.activeFilterOfConflicts = false;
        }
        await this.filterByConflicts();
      }
    }
  }

  async disapproveDay(iUserAttendance: IUserAttendanceModel): Promise<void> {
    await this.getAttendanceConflicts();
    const conflictIndex = this.daysOfCurrentMonth.findIndex((iDay) => iDay.date() === moment(iUserAttendance.date).date());
    const approvedUserAttendance = this.allUserAttendanceForCurrentMonth[moment(iUserAttendance.date).toISOString()].filter(
      (iUserAttendance) => iUserAttendance._approved
    );
    if (
      !this.attendanceConflictsPerDay[conflictIndex]?.hasConflicts ||
      (!iUserAttendance._approved && !approvedUserAttendance?.length) ||
      !this.canApprove
    ) {
      return;
    }
    const monthNumber = this.currentMonthDate.month();
    const dayNumber = moment(iUserAttendance.date).date();
    const year: string = this.currentMonthDate.year().toString();
    const month: string = moment().month(monthNumber).format('MM');
    const day: string = moment().month(monthNumber).date(dayNumber).format('DD');
    this.attendanceConflictsPerDay[conflictIndex].text = {
      limitExceeded: this.i18n.page.limitExceeded,
      maximumHours: this.i18n.page.maximumHours,
    };
    try {
      await this.injector.get(UserAttendanceService).disapproveDay(this.userId, year, month, day);
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PeopleDetailAttendancePage.name, 'disapproveDay');
    }
  }

  approveMonth(): void {
    const language = this.injector.get(PrivateInternationalizationService).getLanguage();
    const month = this.currentMonthDate.month();
    const year = this.currentMonthDate.year();
    const monthName: string = moment().locale(language).month(month).format('MMMM');
    let dialogData;
    if (this.attendanceConflicts?.length) {
      dialogData = {
        titleText: this.i18n.page.ignoreInBulkConflictsDialog.title,
        subtitleText: this.injector
          .get(I18nDataPipe)
          .transform(this.i18n.page.ignoreInBulkConflictsDialog.subtitle, { employee: this.userPersonal.displayName }),
        confirmButtonText: this.i18n.page.ignoreInBulkConflictsDialog.ignoreButton,
        confirmButtonColor: 'Success',
        cancelButtonText: this.i18n.page.ignoreInBulkConflictsDialog.cancel,
      };
    } else {
      dialogData = {
        titleText: this.i18n.page.approveAllDialog.title,
        subtitleText: this.injector
          .get(I18nDataPipe)
          .transform(this.i18n.page.approveAllDialog.subtitle, { year: year, month: monthName, employee: this.userPersonal.displayName }),
        confirmButtonText: this.i18n.page.approveAllDialog.approveAllButton,
        confirmButtonColor: 'Success',
        cancelButtonText: this.i18n.page.approveAllDialog.cancel,
      };
    }
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response && this.pendingToApproveConflicts) {
        this.injector
          .get(UserAttendanceService)
          .approveAllEntries([this.userId], year, month, 'attendance-tab', [...this.attendanceConflicts])
          .then((allAttendanceEntriesWereComplete?: boolean) => {
            this.injector
              .get(MatLegacySnackBar)
              .open(
                check.not.assigned(allAttendanceEntriesWereComplete)
                  ? this.i18n.page.approveAllSnackbar
                  : this.i18n.page.approveAllWithSomeIncompleteSnackbar,
                'OK',
                { duration: 5000 }
              );
            this.injector
              .get(PrivateAmplitudeService)
              .logEvent('manage attendance/overtime', { type: 'approve all', source: 'individual summary' });
            this.initUserAttendance();
          })
          .catch(() => {
            // Do nothing
          })
          .finally(() => {
            if (this.activeFilterOfConflicts) {
              if (this.attendanceConflicts.length > 0) {
                this.activeFilterOfConflicts = false;
              }
              this.filterByConflicts();
            }
          });
      } else if (response && !this.pendingToApproveConflicts) {
        this.injector
          .get(MatLegacySnackBar)
          .open(this.i18n.page.notAbleToApproveEntries, '', { duration: 5000, panelClass: 'kenjo-error-snackbar' });
      }
    });
  }

  approveDateRange(): void {
    if (
      check.not.assigned(this.currentDateRange) ||
      check.not.assigned(this.currentDateRange.startDate) ||
      check.not.assigned(this.currentDateRange.endDate)
    ) {
      return;
    }

    const dateRange = {
      startDate: moment.utc(this.currentDateRange.startDate).toISOString(),
      endDate: moment.utc(this.currentDateRange.endDate).toISOString(),
    };

    const year = this.currentMonthDate.year();
    const dateRangeConflicts = this.attendanceConflicts.filter(
      (iAttendanceConflicts) =>
        moment(iAttendanceConflicts._date).isSameOrAfter(moment.utc(this.currentDateRange.startDate)) &&
        moment(iAttendanceConflicts._date).isSameOrBefore(moment.utc(this.currentDateRange.endDate))
    );
    let dialogData;
    if (dateRangeConflicts?.length) {
      dialogData = {
        titleText: this.i18n.page.ignoreInBulkConflictsDialog.title,
        subtitleText: this.injector
          .get(I18nDataPipe)
          .transform(this.i18n.page.ignoreInBulkConflictsDialog.subtitle, { employee: this.userPersonal.displayName }),
        confirmButtonText: this.i18n.page.ignoreInBulkConflictsDialog.ignoreButton,
        confirmButtonColor: 'Success',
        cancelButtonText: this.i18n.page.ignoreInBulkConflictsDialog.cancel,
      };
    } else {
      dialogData = {
        titleText: this.i18n.page.approveAllDialog.title,
        subtitleText: this.injector.get(I18nDataPipe).transform(this.i18n.page.approveAllDialog.dateRangeSubtitle, {
          fromDate: this.injector.get(DatePipe).transform(this.currentDateRange.startDate, 'shortDate', 'UTC'),
          toDate: this.injector.get(DatePipe).transform(this.currentDateRange.endDate, 'shortDate', 'UTC'),
          employee: this.userPersonal.displayName,
        }),
        confirmButtonText: this.i18n.page.approveAllDialog.approveAllButton,
        confirmButtonColor: 'Success',
        cancelButtonText: this.i18n.page.approveAllDialog.cancel,
      };
    }

    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response && this.pendingToApproveInCurrentDateRangeConflicts) {
        this.injector
          .get(UserAttendanceService)
          .approveAllEntries([this.userId], year, dateRange, 'attendance-tab', [...dateRangeConflicts])
          .then((allAttendanceEntriesWereComplete?: boolean) => {
            this.injector
              .get(MatLegacySnackBar)
              .open(
                check.not.assigned(allAttendanceEntriesWereComplete)
                  ? this.i18n.page.approveAllSnackbar
                  : this.i18n.page.approveAllWithSomeIncompleteSnackbar,
                'OK',
                { duration: 5000 }
              );
            this.updateDateRangeView();
            return this.initUserAttendance();
          })
          .catch(() => {
            // Do nothing
          })
          .finally(() => {
            if (this.activeFilterOfConflicts) {
              if (this.attendanceConflicts.length > 0) {
                this.activeFilterOfConflicts = false;
              }
              this.filterByConflicts();
            }
          });
      } else if (response && !this.pendingToApproveInCurrentDateRangeConflicts) {
        this.injector
          .get(MatLegacySnackBar)
          .open(this.i18n.page.notAbleToApproveEntries, '', { duration: 5000, panelClass: 'kenjo-error-snackbar' });
      }
    });
  }

  public onChangeTab(newTab: number): void {
    this.selectedTab = newTab;

    if (this.selectedTab === this.MONTHLY_TAB_INDEX) {
      this.cdr.detectChanges();
      this.scrollToToday();
    } else if (this.selectedTab === this.DATE_RANGE_TAB_INDEX) {
      this.isTodayVisible = true;
      this.injector.get(PrivateAmplitudeService).logEvent('complete date range', {
        category: 'Attendance',
        platform: 'Web',
        subcategory1: 'Attendance Tab',
        subcategory2: 'Date Range',
      });
      this.updateDateRangeView();
    }
  }

  public compensateTimeOff(): void {
    const data = this.setCompensateData();
    const dialogRef = this.injector.get(MatLegacyDialog).open(OvertimeCompensationTimeOffDialog, { data: data });

    dialogRef.afterClosed().subscribe((compensated) => {
      if (compensated) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.globalMisc.overtimeConfirmationActions.timeOff, 'OK', {
          duration: 5000,
        });
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('manage attendance/overtime', { type: 'convert to time off', source: 'individual summary' });

        this.calculateCompensatedHours();
        this.initAttendanceSummary();
      }
    });
  }

  public compensatePayment(): void {
    const data = this.setCompensateData();
    const dialogRef = this.injector.get(MatLegacyDialog).open(OvertimeCompensationPayDialog, { data: data });

    dialogRef.afterClosed().subscribe((compensated) => {
      if (compensated) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.globalMisc.overtimeConfirmationActions.pay, 'OK', {
          duration: 5000,
        });
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('manage attendance/overtime', { type: 'pay out', source: 'individual summary' });

        this.calculateCompensatedHours();
        this.initAttendanceSummary();
      }
    });
  }

  public adjustBalance(): void {
    const data = this.setCompensateData();
    const dialogRef = this.injector.get(MatLegacyDialog).open(OvertimeAdjustBalanceDialog, { data: data });

    dialogRef.afterClosed().subscribe((compensated) => {
      if (compensated) {
        this.injector.get(MatLegacySnackBar).open(this.i18n.globalMisc.overtimeConfirmationActions.balanceAdjusted, 'OK', {
          duration: 5000,
        });
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('manage attendance/overtime', { type: 'adjust balance', source: 'individual summary' });
        this.initAttendanceSummary();
      }
    });
  }

  setCompensateData() {
    const users = {};
    users[this.userId] =
      this.attendanceSummaryCurrentMonth && this.attendanceSummaryCurrentMonth.currentOvertimeBalance
        ? this.attendanceSummaryCurrentMonth.currentOvertimeBalance
        : 0;
    return {
      userIds: users,
      month: moment(this.currentMonthDate).month() + 1,
      year: moment(this.currentMonthDate).year(),
    };
  }

  public seeDetails(): void {
    const data = {
      userId: this.userId,
      month: moment(this.currentMonthDate).month() + 1,
      year: moment(this.currentMonthDate).year(),
      fullDetail: true,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(OvertimeBalanceHistoryDialog, { data: data });
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('show details', { category: 'Attendance', platform: 'Web', subcategory1: 'Attendance Tab', subcategory2: 'Monthly' });
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('manage attendance/overtime', { type: 'show details', source: 'individual summary' });
    dialogRef.afterClosed().subscribe((closeData) => {
      if (check.assigned(closeData) && closeData.hasRecalculated === true) {
        Promise.all([this.initAttendanceSummary(), this.initUserAttendance()]);
      }
    });
  }

  public seeNegativeBalanceInfo(): void {
    this.injector.get(MatLegacyDialog).open(OvertimeNegativeBalanceDialog);
  }

  public hideLastOvertimeAdjustmentWarning(): void {
    if (check.assigned(this.attendanceSummaryId)) {
      this.injector
        .get(AttendanceSummaryService)
        .updateById(this.attendanceSummaryId, { showAdjustmentWarning: false })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.initAttendanceSummary();
        });
    }
  }

  getColor(timeOffTypeId: string): string {
    if (check.not.assigned(timeOffTypeId)) {
      return '#757575';
    }

    if (check.not.assigned(this.timeOffTypesById[timeOffTypeId]) || check.not.assigned(this.timeOffTypesById[timeOffTypeId].color)) {
      return '#757575';
    }

    return userColorConstants[this.timeOffTypesById[timeOffTypeId].color];
  }

  onScroll(): void {
    if (this.loadingChangeMonth || this.selectedTab === this.DATE_RANGE_TAB_INDEX) {
      this.daysContainerScrolled = false;
      return;
    }

    const daysContainer = this.dayElements.first?.nativeElement;
    this.daysContainerScrolled = daysContainer?.scrollTop > 0;

    if (this.isSelectedMonthTodayMonth()) {
      const today = this.dayElements.find((element: ElementRef) => element?.nativeElement.classList.contains('today'));
      if (!today) {
        return;
      }
      const lastDay = this.dayElements.get(this.dayElements?.length - 1)?.nativeElement;

      const { height, top } = today?.nativeElement.getBoundingClientRect();
      const containerRect = daysContainer.getBoundingClientRect();

      this.scrollToSubtractWhenDelete = 0;
      if (check.assigned(lastDay) && !navigator.userAgent.toLowerCase().includes('firefox')) {
        const { bottom: lastDayBottom } = lastDay?.getBoundingClientRect();
        if (lastDayBottom <= containerRect.bottom + 64) {
          this.scrollToSubtractWhenDelete = containerRect.bottom + 64 - lastDayBottom;
        }
      }

      this.isTodayVisible = containerRect.top - top <= height;

      if (this.scrollingToToday && this.isTodayVisible) {
        this.scrollingToToday = false;
      }
    }
  }

  private getFileContent(): any {
    let days = Object.entries(this.allUserAttendanceForCurrentMonth).map(([key, value]) => {
      const tracks = [];
      value.forEach((trackElement) => {
        if (trackElement._deleted === true) {
          return;
        }

        const startTime = this.convertMinutesToTime(trackElement.startTime);
        const endTime = this.convertMinutesToTime(trackElement.endTime);
        const breakTime = this.convertMinutesToTime(trackElement.breakTime);

        const track = {
          startTime: check.assigned(startTime) ? startTime : '--:--',
          endTime: check.assigned(endTime) ? endTime : '--:--',
          breakTime: check.assigned(breakTime) ? breakTime : '--:--',
          comment: check.assigned(trackElement.comment) ? trackElement.comment : '',
        };
        tracks.push(track);
      });
      const day = {
        day: check.assigned(moment(key).format('L')) ? moment(key).format('L') : '',
        tracks: tracks,
      };
      return day;
    });
    days = _.orderBy(days, ['day'], ['asc']);
    this.minutesWorkedEachDay.forEach((minutes, index) => {
      days[index]['workingTime'] = this.injector.get(DurationPipe).transform(minutes);
    });
    const overtime =
      this.attendanceSummaryCurrentMonth && this.attendanceSummaryCurrentMonth.currentOvertimeBalance
        ? this.injector.get(DurationPipe).transform(this.attendanceSummaryCurrentMonth.currentOvertimeBalance)
        : this.injector.get(DurationPipe).transform(0);
    const fileContent = {
      displayName: this.userPersonal.displayName,
      jobTitle: this.userWork.jobTitle,
      monthDate: this.currentMonthDate.format('MMM YYYY'),
      expectedTime: this.injector.get(DurationPipe).transform(this.totalExpectedMinutesToWorkInCurrentMonth),
      trackedTime: this.injector.get(DurationPipe).transform(this.totalWorkedMinutesInCurrentMonth),
      justifiedAbsence: this.injector.get(DurationPipe).transform(this.totalPaidMinutesInCurrentMonth),
      overtime: overtime,
      monthDays: days,
    };
    return fileContent;
  }

  private convertMinutesToTime(minutesValue: number): string {
    if (check.not.assigned(minutesValue)) {
      return undefined;
    }
    const hours = Math.floor((minutesValue / 60) % 24);
    const minutes = (((minutesValue / 60) % 24) - Math.floor((minutesValue / 60) % 24)) * 60;
    return `${this.injector.get(DecimalPipe).transform(hours, '2.0-0')}:${this.injector.get(DecimalPipe).transform(minutes, '2.0-0')}`;
  }

  private convertTimeToMinutes(value: string) {
    const [hours, minutes] = value.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  private getPermissionsToSeePayrollTabs(payrollPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/compensation') === false) {
        resolve(false);
      }

      if (this.loggedUser._id === this.userId && payrollPermissions.c_viewCompensation_own === true) {
        resolve(true);
      }
      if (payrollPermissions.c_viewCompensation_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(payrollPermissions.c_viewCompensation_custom) ||
        check.emptyArray(payrollPermissions.c_viewCompensation_custom)
      ) {
        resolve(false);
      }

      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.userId;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            payrollPermissions[`c_viewCompensation_custom`],
            payrollPermissions[`c_viewCompensation_own`],
            myUserWork,
            allUserWork,
            this.loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  /**
   * updateAttendanceSummary()
   * This is executed when the page is loaded or when the user skips to another month to get the totalExpectedMinutesToWorkInCurrentMonth,
   * totalExpectedMinutesToWorkSoFar and expectedHoursByDay
   * 1. Update expected hours
   * 2. Compute totalWorkedMinutesInCurrentMonth based in the worked minutes and userTimeOff per each day
   */
  private async updateAttendanceSummary(getExpectedHours: boolean = true) {
    try {
      if (getExpectedHours) {
        await this.getExpectedHoursAndTotals();
      }
      this.resetTodayButtonHandler();
      this.loadingChangeMonth = false;
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.scrollToToday();
      this.setDatesUntilNow();
      this.computeMinutesWorkedEachDay();
    } catch (error) {
      this.totalExpectedMinutesToWorkInCurrentMonth = 0;
      this.totalExpectedMinutesToWorkSoFar = 0;
      this.totalPaidMinutesInCurrentMonth = 0;
      this.expectedHoursByDay = {};
    }
  }

  private async getExpectedHoursAndTotals() {
    try {
      const expectedTime = await this.injector
        .get(AttendanceSummaryController)
        .getExpectedHoursByUserMonth(this.userId, this.currentMonthDate.month(), this.currentMonthDate.year(), true);
      this.totalExpectedMinutesToWorkInCurrentMonth = expectedTime.totalExpectedMonth;
      this.totalExpectedMinutesToWorkSoFar = expectedTime.totalExpectedSoFar;
      this.expectedHoursByDay = expectedTime.expectedHoursByDay;
    } catch (error) {
      this.totalExpectedMinutesToWorkInCurrentMonth = 0;
      this.totalExpectedMinutesToWorkSoFar = 0;
      this.expectedHoursByDay = {};
    }
  }

  private async updateDateRangeExpectedHours(): Promise<void> {
    try {
      const result = await this.injector
        .get(AttendanceSummaryController)
        .getExpectedHoursByUserDateRange(
          this.userId,
          moment.utc(this.currentDateRange.startDate).format('YYYY-MM-DD'),
          moment.utc(this.currentDateRange.endDate).format('YYYY-MM-DD'),
          true
        );
      this.totalExpectedMinutesToWorkInCurrentRange = result.totalExpectedMonth;
      this.expectedHoursByDayInCurrentRange = result.expectedHoursByDay;
      this.loadingChangeDateRange = false;

      this.initTableData();
    } catch (error) {
      // Handle error
    }
  }

  private resetTodayButtonHandler(): void {
    if (this.currentMonthDate.format('YYYY MM') === this.todayDate.format('YYYY MM') && this.resetTodayButton) {
      this.resetTodayButton = false;
    } else if (this.currentMonthDate.format('YYYY MM') !== this.todayDate.format('YYYY MM')) {
      this.resetTodayButton = true;
    }
  }

  private getSanitizedUTCToTimeZone(date?: string | moment.Moment | Date) {
    return this.injector.get(InternationalizationService).getSanitizedUTCToTimeZone(date);
  }

  private restrictCheckIn(currentEntry: IUserAttendanceModel) {
    if (!currentEntry) {
      return false;
    }
    const isAdmin = ['admin', 'hr-admin'].includes(this.loggedUser.profileKey);
    const { isActive } = this.attendancePolicy?.restrictCheckIn;
    if (isAdmin || !isActive) {
      return false;
    }

    const { startTime, date } = currentEntry;
    const fixedWorkSchedule = getCurrentWorkScheduleFromHistory(date, this.userWorkSchedule);
    this.userWorkSchedule = formatWorkSchedule(fixedWorkSchedule, this.userWorkSchedule);
    const {
      mondayWorkingDay,
      tuesdayWorkingDay,
      wednesdayWorkingDay,
      thursdayWorkingDay,
      fridayWorkingDay,
      saturdayWorkingDay,
      sundayWorkingDay,
    } = this.userWorkSchedule;
    const workingDays = [
      mondayWorkingDay,
      tuesdayWorkingDay,
      wednesdayWorkingDay,
      thursdayWorkingDay,
      fridayWorkingDay,
      saturdayWorkingDay,
      sundayWorkingDay,
    ];
    let dayIndex = moment(date).isoWeekday() - 1;
    if (startTime) {
      this.restrictTimeLimit = this.MAX_SHIFT_LENGTH;
    }

    if (!fixedWorkSchedule || fixedWorkSchedule.type !== this.WORK_SCHEDULE_TYPE) {
      return false;
    }

    if (!workingDays[dayIndex]) {
      this.calculateNextShiftDate(date, dayIndex, fixedWorkSchedule);
      return true;
    }

    const shiftsOnDay = fixedWorkSchedule.dayShifts[dayIndex].shifts;
    const isWithinShift = this.calculateIsWithinShift(shiftsOnDay, startTime, date, dayIndex, fixedWorkSchedule);

    return !isWithinShift;
  }

  calculateNextShiftDate(dayOfMonth, dayIndex: number, fixedWorkSchedule, extraDay: number = 0) {
    const {
      mondayWorkingDay,
      tuesdayWorkingDay,
      wednesdayWorkingDay,
      thursdayWorkingDay,
      fridayWorkingDay,
      saturdayWorkingDay,
      sundayWorkingDay,
    } = this.userWorkSchedule;
    const { graceMinutes, type: restrictType } = this.attendancePolicy.restrictCheckIn;
    const workingDays = [
      mondayWorkingDay,
      tuesdayWorkingDay,
      wednesdayWorkingDay,
      thursdayWorkingDay,
      fridayWorkingDay,
      saturdayWorkingDay,
      sundayWorkingDay,
    ];
    let currentDay = moment(dayOfMonth).date() + extraDay;
    let daysToAdd = extraDay;
    let previousStart = this.MAX_SHIFT_LENGTH;
    this.nextShiftDate = '';
    dayIndex += extraDay;

    for (let i = 0; i < Object.keys(this.expectedHoursByDay).length - 1; i++) {
      if (workingDays[dayIndex] && !this.expectedHoursByDay[currentDay]?.bankHolidays) {
        break;
      }
      daysToAdd++;
      currentDay++;
      if (dayIndex >= 6) {
        dayIndex = 0;
      } else {
        dayIndex++;
      }

      if (currentDay >= Object.keys(this.expectedHoursByDay).length) {
        currentDay = 1;
      }
    }

    const shiftsOnDay = fixedWorkSchedule.dayShifts[dayIndex].shifts;
    shiftsOnDay.forEach(({ start }) => {
      if (previousStart > start) {
        previousStart = start;
      }
    });
    if (restrictType === this.GRACE_MINUTES) {
      this.restrictTimeLimit = calculateHoursAndMinutes(previousStart - graceMinutes);
    } else {
      this.restrictTimeLimit = calculateHoursAndMinutes(previousStart);
    }
    this.nextShiftDate = moment(dayOfMonth).add(daysToAdd, 'day').locale(this.language).format('D MMM');
  }

  calculateIsWithinShift(shiftsOnDay: any, startTime: number, date: Date, dayIndex: number, fixedWorkSchedule): boolean {
    const { graceMinutes, type: restrictType } = this.attendancePolicy.restrictCheckIn;
    let isWithinShift: boolean;
    let timeLimit = this.MAX_SHIFT_LENGTH;
    this.nextShiftDate = '';
    const baseStartTime = startTime;
    let overnightShift = false;

    for (let i = 0; i < shiftsOnDay.length; i++) {
      const { start, end } = shiftsOnDay[i];
      const adjustedEnd = end < start ? end + this.MAX_SHIFT_LENGTH : end;
      startTime = end < start && startTime < start ? baseStartTime + this.MAX_SHIFT_LENGTH : baseStartTime;
      if (startTime !== baseStartTime && !overnightShift) {
        overnightShift = true;
        timeLimit = start;
      }
      if (restrictType === this.GRACE_MINUTES) {
        isWithinShift = startTime >= start - graceMinutes && startTime <= adjustedEnd;
        if (startTime <= start - graceMinutes && startTime < adjustedEnd && start - graceMinutes < timeLimit) {
          timeLimit = start - graceMinutes < 0 ? 0 : start - graceMinutes;
        }
      } else {
        isWithinShift = startTime >= start && startTime < adjustedEnd;
        if (startTime <= start && startTime <= adjustedEnd && start < timeLimit) {
          timeLimit = start;
        }
      }

      if (isWithinShift) {
        break;
      }
    }

    if (timeLimit === this.MAX_SHIFT_LENGTH && !overnightShift) {
      this.calculateNextShiftDate(date, dayIndex, fixedWorkSchedule, 1);
    } else {
      this.restrictTimeLimit = calculateHoursAndMinutes(timeLimit);
    }
    return isWithinShift;
  }

  calculateIsFutureEntry(userAttendance: IUserAttendanceModel, allowEntriesInTheFuture: boolean) {
    this.futureEntriesErrors = this.injector.get(FutureEntriesService).calculateIsFutureShift(userAttendance, allowEntriesInTheFuture);
  }

  onShiftChange(userAttendance: IUserAttendanceModel) {
    if (userAttendance.startTime === null) return;
    this.restrictCheckIn(userAttendance);
    const indexOfTimeEntry = this.selectedIndexOfTimeEntryToChangeBreaks;
    this.updateTimeEntryInlineErrors(
      moment(userAttendance.date),
      this.selectedIndexOfDayToChangeBreaks,
      indexOfTimeEntry,
      false,
      userAttendance
    );
  }

  openBreaksDialog(
    breaksContainerElement: ElementRef,
    breaksElement: ElementRef,
    userAttendance: IUserAttendanceModel,
    dateToSave: moment.Moment,
    indexOfTimeEntry: number,
    indexOfDay: number,
    readOnly: boolean,
    target: any
  ) {
    this.originalAttendance = _.cloneDeep(userAttendance);
    this.selectedBreaksElement = breaksElement;
    this.selectedUserAttendanceToChangeBreaks = _.cloneDeep(userAttendance);
    if (checkShiftWithoutBreak(this.selectedUserAttendanceToChangeBreaks)) {
      this.selectedUserAttendanceToChangeBreaks.breaks = [];
    }
    this.selectedDateToChangeBreaks = dateToSave;
    this.selectedIndexOfTimeEntryToChangeBreaks = indexOfTimeEntry;
    this.selectedIndexOfDayToChangeBreaks = indexOfDay;
    this.selectedUserAttendanceToChangeBreaksIsReadOnly = readOnly;
    this.otherShiftsThisDayToChangeAttendanceBreaks = _.cloneDeep(this.allUserAttendanceForCurrentMonth[dateToSave.toISOString()]);

    this.otherShiftsThisDayToChangeAttendanceBreaks.splice(indexOfTimeEntry, 1);
    if (this.timeOffOverlappingValidationIsActive === false) {
      this.otherShiftsThisDayToChangeAttendanceBreaks = this.otherShiftsThisDayToChangeAttendanceBreaks.filter(
        (iShift) => !iShift.timeOffEntryInfo
      );
    }
    this.renderer.addClass(breaksElement, 'selected');

    this.overlay = this.injector.get(Overlay);
    const breaksOverlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
    };

    if (window.innerHeight - target.clientY < 200) {
      breaksOverlayConfig.minHeight = 500;
    }

    breaksOverlayConfig.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(breaksContainerElement)
      .withPositions([
        { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' },
        { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'bottom', panelClass: 'kenjo-breaks-bottom' },
      ])
      .withPush(false);

    this.breaksOverlay = this.overlay.create(breaksOverlayConfig);
    this.breaksOverlay.attach(this.breaksPortal);
    this.breaksOverlay.backdropClick().subscribe(() => {
      this.closeBreaksDialog({ save: false });
    });
  }

  async closeBreaksDialog(saveOperation: {
    save: boolean;
    hasChanges?: boolean;
    newAttendance?: IUserAttendanceModel;
    operation?: 'saveNewBreak' | 'saveOldBreak' | 'forceClose';
  }) {
    if (saveOperation.operation === 'forceClose') {
      this.renderer.removeClass(this.selectedBreaksElement, 'selected');
      this.breaksOverlay.dispose();
    }

    if (saveOperation.save && saveOperation.operation === 'saveNewBreak') {
      this.renderer.removeClass(this.selectedBreaksElement, 'selected');
      this.breaksOverlay.dispose();
      this.saveBreaks(saveOperation.newAttendance);
      return;
    }

    if (saveOperation.save && saveOperation.operation === 'saveOldBreak') {
      this.renderer.removeClass(this.selectedBreaksElement, 'selected');
      this.breaksOverlay.dispose();
      this.saveOldBreaks(saveOperation.newAttendance);
      return;
    }

    if (saveOperation.hasChanges) {
      const dialogData = {
        titleText: this.i18n.breaksDialog.closeDialog.title,
        subtitleText: this.i18n.breaksDialog.closeDialog.subtitle,
        confirmButtonText: this.i18n.breaksDialog.closeDialog.confirm,
        confirmButtonColor: 'Danger',
        cancelButtonText: this.i18n.breaksDialog.closeDialog.cancel,
      };

      const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
      const confirm = await dialogRef.afterClosed().toPromise();
      if (confirm) {
        this.updateTimeEntryInlineErrors(
          this.selectedDateToChangeBreaks,
          this.selectedIndexOfDayToChangeBreaks,
          this.selectedIndexOfTimeEntryToChangeBreaks,
          true
        );
        this.isRestrictCheckIn = false;
        this.futureEntriesErrors = this.injector.get(FutureEntriesService).resetError();
        this.renderer.removeClass(this.selectedBreaksElement, 'selected');
        this.breaksOverlay.dispose();
      }

      return;
    }

    this.renderer.removeClass(this.selectedBreaksElement, 'selected');
    this.breaksOverlay.dispose();
  }

  async saveOldBreaks(newAttendance: IUserAttendanceModel) {
    this.updateTimeEntryBeingEdited({
      dayOfMonth: this.selectedDateToChangeBreaks,
      index: this.selectedIndexOfTimeEntryToChangeBreaks,
      iUserAttendance: this.selectedUserAttendanceToChangeBreaks,
      type: 'breakTime',
      value: newAttendance.breakTime,
    });
    this.computeMinutesWorkedEachDay();
    this.save(this.selectedDateToChangeBreaks, this.selectedIndexOfTimeEntryToChangeBreaks);
  }

  async saveBreaks(newAttendance: IUserAttendanceModel) {
    if (!this.isEditable) {
      return;
    }

    this.savingTimeEntries = true;
    const approveEntry = check.not.assigned(newAttendance._id);
    const dateToSave = moment.utc(newAttendance.date);

    try {
      if (check.assigned(this.attendanceConflicts) && this.attendanceConflicts.length > 0) {
        this.attendanceConflictsToCheck = this.attendanceConflicts.filter((iAttendanceConflict: IAttendanceConflict) => {
          return moment(iAttendanceConflict._date).toISOString() === dateToSave.toISOString();
        });
      }

      const userAttendanceUpdated = await this.saveUserAttendance(newAttendance);
      const breakReminderConfig = await this.shouldOpenBreakReminder(newAttendance);

      if (check.assigned(userAttendanceUpdated) || check.assigned(newAttendance._id)) {
        this.allUserAttendanceForCurrentMonth[this.selectedDateToChangeBreaks.toISOString()][this.selectedIndexOfTimeEntryToChangeBreaks] =
          { ...newAttendance };
      }

      if (
        approveEntry &&
        this.canEdit &&
        this.someAttendanceOfDayApprovedMap[this.selectedDateToChangeBreaks.toISOString()] &&
        newAttendance._approved === false &&
        check.assigned(newAttendance.startTime) &&
        check.assigned(newAttendance.endTime)
      ) {
        await this.approveDay(this.selectedDateToChangeBreaks);
      }

      delete this.attendanceOfDayChangedMap[this.selectedDateToChangeBreaks.toISOString()];
      delete this.attendanceToDeleteMap[this.selectedDateToChangeBreaks.toISOString()];

      if (breakReminderConfig.openReminder) {
        await this.openBreakReminder(breakReminderConfig, newAttendance._userId, newAttendance.date);
      }
      if (check.assigned(userAttendanceUpdated) || check.assigned(newAttendance._id)) {
        await this.injector
          .get(UserAttendanceService)
          .autoDeductBreak(
            this.allUserAttendanceForCurrentMonth[this.selectedDateToChangeBreaks.toISOString()][
              this.selectedIndexOfTimeEntryToChangeBreaks
            ],
            this.attendancePolicy,
            this.loggedUser.profileKey
          );
      }
    } catch {
      // Do nothing
    } finally {
      this.logAmplitudeEvent(newAttendance);
      this.savingTimeEntries = false;
      this.updateTimeEntryBeingEdited(undefined);
      this.updateTimeEntryErrors(this.selectedDateToChangeBreaks, true);
      this.updateTimeEntryInlineErrors(this.selectedDateToChangeBreaks, 0, 0, true);
      await this.disapproveDay(newAttendance);
      await this.initAttendanceSummary();
      await this.initUserAttendance();
      if (this.attendanceConflicts?.length === 0 && this.activeFilterOfConflicts) {
        await this.filterByConflicts();
      }
      if (this.activeFilterOfConflicts) {
        this.computeDaysOfTheMonthWithConflicts();
      }
      this.handleConflictsSnackBar(dateToSave);
      this.computeMinutesWorkedEachDay();
      this.isTimeEntryEditable(this.selectedDateToChangeBreaks, this.selectedIndexOfTimeEntryToChangeBreaks);
      this.canApproveOrEdit(this.selectedDateToChangeBreaks);
    }
  }

  private async getAttendancePolicy() {
    const isAdmin = ['admin', 'hr-admin'].includes(this.loggedUser.profileKey);

    this.attendancePolicy = await this.injector.get(AttendancePolicyService).getAttendancePolicyByUserId(this.userId);
    this.allowEntriesInTheFuture = isAdmin || this.attendancePolicy.allowEntriesInTheFuture;
    this.timeOffOverlappingValidationIsActive = this.attendancePolicy?.overlappingWithTimeOff?.isActive === true;
    if (!isAdmin && this.attendancePolicy?.limitDailyHours?.isActive && !this.attendancePolicy.limitDailyHours.conflicts) {
      this.MAX_HOURS_PER_DAY = this.attendancePolicy.limitDailyHours.limit;
    }
  }

  private async getAttendanceConflicts() {
    this.attendanceConflicts = await this.injector.get(AttendanceConflictsService).findByMonth(this.currentMonthDate.toDate(), this.userId);

    this.computeConflictsPerDay();
  }

  conflictMenuToggle(index: number, isOpened: boolean) {
    this.attendanceConflictsPerDay[index].menuOpened = isOpened;
  }

  private async shouldOpenBreakReminder(userAttendance: IUserAttendanceModel): Promise<IBreakReminderConfiguration> {
    if (
      calculateIsOpenShift(userAttendance.startTime, userAttendance.endTime) ||
      this.isShiftWithOldBreak(userAttendance) ||
      isAnyBreakOpen(userAttendance)
    ) {
      return { openReminder: false };
    }

    return await this.injector.get(BreakReminderController).getConfiguration(userAttendance._userId, userAttendance.date);
  }

  private isShiftWithOldBreak(userAttendance: IUserAttendanceModel) {
    return check.not.assigned(userAttendance.breaks);
  }

  async exitTimeOffFullScreen() {
    this.injector.get(PeopleDetailService).closeEmployeeTimeOff();
    this.injector.get(GlobalBarService).setPageName(this.i18n.page.pageName);
    await this.appFocusChange();
    this.refreshGlobalBar();
    this.scrollToToday();
  }

  private computeDaysOfTheMonthWithConflicts(): void {
    if (this.attendanceConflicts?.length) {
      const includedDays = [];
      const daysOfTheMonthWithConflicts: Array<moment.Moment> = [];
      for (const iAttendanceConflict of this.attendanceConflicts) {
        if (!includedDays.includes(moment.utc(iAttendanceConflict._date.toString()).format('YYYY-MM-DD'))) {
          includedDays.push(moment.utc(iAttendanceConflict._date.toString()).format('YYYY-MM-DD'));
          daysOfTheMonthWithConflicts.push(moment.utc(iAttendanceConflict._date.toString()));
        }
      }

      daysOfTheMonthWithConflicts.sort((a, b) => a.diff(b));

      this.daysOfCurrentMonth = [...daysOfTheMonthWithConflicts];
    }
    this.computeConflictsPerDay();
    this.computeMinutesWorkedEachDay();
  }

  async filterByConflicts(): Promise<void> {
    this.activeFilterOfConflicts = !this.activeFilterOfConflicts;
    this.isLoadingConflicts = true;
    if (this.activeFilterOfConflicts) {
      this.computeDaysOfTheMonthWithConflicts();
      this.isLoadingConflicts = false;
      return;
    }
    await this.initAttendanceSummary();
    await this.initUserAttendance();
    this.computeMinutesWorkedEachDay();
    this.timeOutRef = setTimeout(() => {
      this.isLoadingConflicts = false;
      this.scrollToToday();
    }, 300);
  }

  updateAttendanceConflictsOnTheFly(indexOfDay: number, todayConflict: IAttendanceConflict) {
    let isAdmin = ['admin', 'hr-admin'].includes(this.loggedUser?.profileKey);

    if (!this.userAccount) {
      isAdmin = ['admin', 'hr-admin'].includes(this.loggedUser?.profileKey);
    } else {
      isAdmin = ['admin', 'hr-admin'].includes(this.userAccount?.profileKey);
    }

    if (
      this.minutesWorkedEachDay[indexOfDay] <= this.attendancePolicy.limitDailyHours?.limit ||
      !this.attendancePolicy.limitDailyHours.isActive ||
      isAdmin
    ) {
      this.attendanceConflictsPerDay[indexOfDay].maxDailyHours = false;
    } else if (
      (this.attendancePolicy.limitDailyHours.conflicts || todayConflict?._type === this.CONFLICT_TYPES.MAX_DAILY_HOURS) &&
      !isAdmin
    ) {
      this.attendanceConflictsPerDay[indexOfDay].maxDailyHours = true;
    }

    // Add new conflicts here like this this.attendanceConflictsPerDay[indexOfDay].maxDailyHours || .....
    const { maxDailyHours, minBreakTime, overlappingConflict } = this.attendanceConflictsPerDay[indexOfDay];
    this.attendanceConflictsPerDay[indexOfDay].hasConflicts = maxDailyHours || minBreakTime || overlappingConflict;
  }

  async ignoreConflicts(date: string, alreadyConfirmed: boolean = false): Promise<void> {
    const attendanceConflictsService = this.injector.get(AttendanceConflictsService);
    const formattedDate = new Date(date).toISOString();
    const conflicts = attendanceConflictsService.filterByDate(this.attendanceConflicts, formattedDate);
    const userAttendanceIds = this.allUserAttendanceForCurrentMonth[formattedDate]?.reduce((acc, iUserAttendance) => {
      const id = iUserAttendance._id;
      if (id) {
        acc.push(id);
      }
      return acc;
    }, []);
    if (conflicts && conflicts.length > 0 && userAttendanceIds && userAttendanceIds?.length > 0) {
      try {
        if (alreadyConfirmed) {
          await attendanceConflictsService.ignoreConflicts(conflicts, userAttendanceIds, 'attendance-tab');
        } else {
          const dialogData = {
            titleText: this.i18n.page.ignoreConflictsDialog.title,
            subtitleText: this.i18n.page.ignoreConflictsDialog.subtitle,
            confirmButtonText: this.i18n.page.ignoreConflictsDialog.ignoreButton,
            confirmButtonColor: 'Danger',
            cancelButtonText: this.i18n.page.ignoreConflictsDialog.cancel,
          };
          const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data: dialogData });
          const ignore = await dialogRef.afterClosed().toPromise();

          if (ignore) {
            await attendanceConflictsService.ignoreConflicts(conflicts, userAttendanceIds, 'attendance-tab');
            this.injector.get(MatLegacySnackBar).open(
              this.injector.get(I18nDataPipe).transform(this.i18n.page.ignoreConflictsSnackBar, {
                date: `${moment(formattedDate).format('MMMM')} ${moment(formattedDate).format('DD')}`,
              }),
              'OK',
              { duration: 5000 }
            );
            this.logIgnoreConflicts('Confirm Ignore Conflicts');
            await this.initUserAttendance();
          }
        }
      } catch (error) {
        throw this.injector.get(ErrorManagerService).handleRawError(error, PeopleDetailAttendancePage.name, 'approveDay');
      } finally {
        if (this.activeFilterOfConflicts) {
          if (this.attendanceConflicts.length > 0) {
            this.activeFilterOfConflicts = false;
          }
          await this.filterByConflicts();
        }
      }
    }
  }

  calculatePendingToApproveConflicts(allUserAttendanceForCurrentMonth: IUserAttendanceModel[]) {
    this.pendingToApproveConflicts = allUserAttendanceForCurrentMonth?.some((attendance) => {
      const conflictIndex = this.daysOfCurrentMonth?.findIndex((iDay) => iDay.date() === moment(attendance.date).date());
      return (
        attendance._approved === false &&
        calculateIsCloseEntry(attendance) &&
        this.isAttendanceDayComplete[moment.utc(attendance.date).toISOString()] &&
        !this.attendanceConflictsPerDay[conflictIndex]?.hasConflicts
      );
    });
  }

  calculatePendingToApproveDateRangeConflicts(allUserAttendanceForCurrentDateRange: IUserAttendanceModel[]) {
    this.pendingToApproveInCurrentDateRangeConflicts = allUserAttendanceForCurrentDateRange?.some((attendance) => {
      const conflictIndex = this.daysOfCurrentMonth?.findIndex((iDay) => iDay.date() === moment(attendance.date).date());
      return (
        attendance._approved === false &&
        calculateIsCloseEntry(attendance) &&
        this.dateRangeIsAttendanceDayComplete[moment.utc(attendance.date).toISOString()] &&
        !this.attendanceConflictsPerDay[conflictIndex]?.hasConflicts
      );
    });
  }

  isTimeEntryEditable(iDayOfMonth: moment.Moment, indexOfTimeEntry: number): void {
    this.isTimeEntryEditableFlag[iDayOfMonth.toISOString()] = {
      [indexOfTimeEntry]:
        this.isEditable === true &&
        this.timeEntryBeingEdited &&
        this.timeEntryBeingEdited?.iUserAttendance &&
        !this.isStartTimeAndEndTimeUndefined() &&
        this.timeEntryBeingEdited?.index === indexOfTimeEntry &&
        this.timeEntryBeingEdited?.dayOfMonth.toISOString() === iDayOfMonth.toISOString() &&
        this.attendanceOfDayChangedMap[iDayOfMonth.toISOString()] &&
        this.canEditConditionsMet(iDayOfMonth) &&
        !this.timeEntryInlineErrors[iDayOfMonth.toISOString()],
    };
  }

  canEditConditionsMet(iDayOfMonth: moment.Moment): boolean {
    return !this.canEdit || !this.someAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] || !this.isStartTimeAndEndTimeUndefined();
  }

  isStartTimeAndEndTimeUndefined(): boolean {
    if (!this.timeEntryBeingEdited) {
      return false;
    }
    const attendance = this.timeEntryBeingEdited?.iUserAttendance;
    return (
      (attendance?.startTime === undefined || attendance?.startTime === null) &&
      (attendance?.endTime === undefined || attendance?.endTime === null)
    );
  }

  canApproveOrEdit(iDayOfMonth: moment.Moment): void {
    this.canApproveOrEditFlag[iDayOfMonth.toISOString()] =
      this.allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false || (this.canApprove && this.canEdit);
  }

  onSaveAttendance(iDayOfMonth: moment.Moment): void {
    if (this.allAttendanceOfDayApprovedMap[iDayOfMonth.toISOString()] === false && this.isStartTimeAndEndTimeUndefined()) {
      this.deleteTimeEntry(iDayOfMonth, this.timeEntryBeingEdited?.index);
    } else {
      this.save(iDayOfMonth, this.timeEntryBeingEdited?.index);
    }
    this.canApproveOrEditFlag = {};
    this.isTimeEntryEditableFlag = {};
  }

  trackByDays(index: number, day: moment.Moment): any {
    return day.format('YYYY-MM-DD');
  }

  logIgnoreConflicts(feature: string) {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent(feature, { category: 'Attendance', platform: 'Web', subcategory1: 'Attendance Details', subcategory2: 'Ignore Conflicts' });
  }

  toggleEventDialog(newVal: string, dialogType: string) {
    if (this.eventDialogsInfo[dialogType].isClosing) {
      return;
    }
    if (!newVal || newVal === this.eventDialogsInfo[dialogType].showDialog) {
      this.closeEventDialog(dialogType);
      return;
    }
    this.eventDialogsInfo[dialogType].showDialog = newVal;
  }

  closeEventDialog(dialogType: string) {
    this.eventDialogsInfo[dialogType].isClosing = true;
    this.eventDialogsInfo[dialogType].showDialog = null;
    setTimeout(() => {
      // debounce to prevent multiple events from being fired by the overlay
      this.eventDialogsInfo[dialogType].isClosing = false;
    }, 300);
    return;
  }
}
