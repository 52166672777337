import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tooltipRolesEmployee',
})
export class TooltipRolesEmployeePipe implements PipeTransform {
  private cache: Map<string, string> = new Map<string, string>();

  transform(roles: any[]): string {
    const cacheKey = JSON.stringify(roles);
    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey);
    }

    const tooltipContent = this.generateTooltipContent(roles);
    this.cache.set(cacheKey, tooltipContent);

    return tooltipContent;
  }

  private generateTooltipContent(roles: any[]): string {
    let tooltip = '';
    for (let i = 2; i < roles.length; i++) {
      tooltip += roles[i].name;
      if (i < roles.length - 1) {
        tooltip += ', ';
      }
    }
    return tooltip;
  }
}
