import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

@Pipe({
  name: 'convertChildren',
  pure: true
})
export class ConvertChildrenPipe implements PipeTransform {
  transform(children: Array<any>): string {
    if (check.not.assigned(children) || check.not.array(children) || check.emptyArray(children)) {
      return '-';
    }

    return children
      .map((iChild) => {
        return `${iChild.childFirstName ?? ''} ${iChild.childLastName ?? ''} (${moment.utc(iChild.childBirthdate).format('L')})`.replace(' (Invalid date)', '').replace(/\s+/, ' ');
      })
      .join(', ');
  }
}
