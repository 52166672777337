<div *ngIf="i18n.page">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">{{i18n.page.shiftRules}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{i18n.page.openShifts}}</span></div>
  <div class="kenjo-mt-30px kenjo-font-size-18px kenjo-font-color-text-333333">{{i18n.page.openShiftsAssignation}}</div>
  <div class="kenjo-mt-10px kenjo-font-color-light-text-757575">{{i18n.page.chooseApprovalType}}</div>
  <orgos-column-container>
    <orgos-column>
      <mat-radio-group>
        <ng-container *ngFor="let option of options">
          <div class="sspos-row kenjo-mt-30px">
            <mat-slide-toggle
            [(ngModel)]="option.value"
            [checked]="openShiftSettings[option.key]"
            (change)="changeOpenShiftSettings(option.key)"
            [disabled]="settingPermissions?.edit_all !== true"
            [matTooltipDisabled]="settingPermissions?.edit_all === true"
            [matTooltip]="i18n.page.noPermissionsTooltip">
          </mat-slide-toggle>
            <div class="kenjo-ml-15px">
              <div class="kenjo-font-size-16px">{{ option.title }}</div>
              <div class="kenjo-mt-5px kenjo-font-color-light-text-757575">{{ option.explanation }}</div>
            </div>
          </div>
        </ng-container>
      </mat-radio-group>
    </orgos-column>
    <orgos-column></orgos-column>
  </orgos-column-container>
</div>
