<div #publicHolidayCard>
  <orgos-column-container
    (click)="move()"
    mat-button
    [matMenuTriggerFor]="menu"
    *ngIf="loaded"
    centerColumns="true"
    class="spph-list-container kenjo-font-weight-bold kenjo-cursor-pointer"
    [class.empty]="publicHolidays?.length < 1"
  >
    <orgos-column
      size="0"
      class="spph-holiday-circle"
      [class.kenjo-ml-10px]="view === views.WEEK"
      [class.kenjo-mh-5px]="view !== views.WEEK"
    >
      <div class="spph-holiday-number">{{ publicHolidays?.length === 1 ? '' : publicHolidays?.length }}</div>
    </orgos-column>
    <orgos-column *ngIf="view === views.WEEK" class="kenjo-ml-5px kenjo-mr-10px kenjo-mv-5px spph-holiday-text">
      <kenjo-truncate-text>{{
        publicHolidays?.length === 1 ? publicHolidays[0].holidayName : translations.publicHolidays
      }}</kenjo-truncate-text>
    </orgos-column>
  </orgos-column-container>

  <mat-menu #menu="matMenu" class="spph-mat-menu">
    <ng-template matMenuContent>
      <kenjo-shift-plan-public-holiday-list
        [language]="language"
        [hasToMove]="hasToMove"
        [date]="date"
        [publicHolidays]="publicHolidays"
        [translations]="translations"
      >
      </kenjo-shift-plan-public-holiday-list>
    </ng-template>
  </mat-menu>
</div>
