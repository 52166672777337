<div class="ews-main-container kenjo-pv-60px">
  <orgos-column-container class="kenjo-mb-30px">
    <orgos-column size="1" class="kenjo-font-size-20px">{{dialogTranslation.pageHeader}}</orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" class="kenjo-ml-40px kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()">clear </kenjo-icon>
  </orgos-column-container>

  <orgos-container aspect="card" class="kenjo-p-40px">
    <orgos-page-message messageType="warning" [messageText]="dialogTranslation.warningMessage"></orgos-page-message>
    <kenjo-working-hours [workScheduleTemplate]="customWorkSchedule" class="kenjo-mt-40px" [editMode]="editMode"></kenjo-working-hours>

    <orgos-column-container centerColumns="true" class="kenjo-mt-60px">
      <orgos-column size="1"></orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Neutral" class="kenjo-mr-20px" (click)="closeDialog()">{{dialogTranslation.cancelButton}}</orgos-button-raised>
      </orgos-column>
      <orgos-column size="0">
        <orgos-button-raised color="Success" (click)="save()" [disabled]="!customWorkSchedule.startDate || !customWorkSchedule.name || customWorkSchedule.name === '' || customWorkSchedule.weeklyMinutes === 0">{{dialogTranslation.saveButton}}</orgos-button-raised>
      </orgos-column>
    </orgos-column-container>
  </orgos-container>
</div>
