<orgos-dialog-container *ngIf="isPageLoaded && pageTranslation && configurationKeys" [title]="isFavoritesSectionActive ? pageTranslation.dialogTitleWithFavorites : pageTranslation.dialogTitle" (clickCloseButton)="closeDialog(false)" class="wiawd-dialog-container">
  <div *ngIf="!isFavoritesSectionActive && !isAdmin" class="kenjo-font-color-light-text-757575 kenjo-mb-30px">{{ pageTranslation.employeeDescription }}</div>

  <!-- FAVORITES -->
  <ng-container *ngIf="isFavoritesSectionActive">
    <div class="kenjo-font-color-light-text-757575 kenjo-mb-15px">{{ pageTranslation.addFavoritesDescription }}</div>
    <orgos-search [placeholder]="pageTranslation.peopleSearchPlaceholder" [searchFunction]="searchUserFunction" (searchResultsChange)="selectableUsers = $event;" [alternativeStyle]="true" [icon]="'search'">
      <orgos-text class="orgos-search-error" color="Warn">{{ pageTranslation.notFoundPeopleError }}</orgos-text>

      <orgos-search-option *ngFor="let user of selectableUsers" [userPersonal]="user" (selected)="addFavoriteUser(user._id)">
        <orgos-text>{{ user.displayName }}</orgos-text>
      </orgos-search-option>

      <orgos-column-container wrap="true" class="orgos-search-selection-container kenjo-mt-10px">
        <orgos-column size="0" *ngFor="let user of favoriteUsers" class="kenjo-mv-10px kenjo-mr-10px">
          <orgos-chip [imageUrl]="user._photo?._url" buttonIconName="clear" [onClickButtonFunction]="deleteFavoriteUser(user._id)" class="srp-search-result" [addInitialsIfNoImage]="true" [chipText]="user.displayName">{{ user.displayName }}</orgos-chip>
        </orgos-column>
      </orgos-column-container>
    </orgos-search>
  </ng-container>

  <!-- SECTIONS -->
  <orgos-column-container centerColumns="true" *ngIf="isFavoritesSectionActive && !showDefaultConfiguration" class="kenjo-mt-30px kenjo-mb-20px">
    <orgos-column size="0" class="kenjo-mr-10px">{{ pageTranslation.showDefaultConfiguration }}</orgos-column>
    <orgos-column size="0">
      <kenjo-icon class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="showDefaultConfiguration = !showDefaultConfiguration" [size]="24">keyboard_arrow_down</kenjo-icon>
    </orgos-column>
  </orgos-column-container>
  <orgos-column-container centerColumns="true" *ngIf="showDefaultConfiguration" class="kenjo-mt-30px">
    <orgos-column size="0" class="kenjo-mr-10px">{{ pageTranslation.hideDefaultConfiguration }}</orgos-column>
    <orgos-column size="0">
      <kenjo-icon class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer" (click)="showDefaultConfiguration = !showDefaultConfiguration" [size]="24">keyboard_arrow_up</kenjo-icon>
    </orgos-column>
  </orgos-column-container>

  <ng-container *ngIf="isFavoritesSectionActive && showDefaultConfiguration">
    <orgos-column-container *ngIf="isAdmin" class="kenjo-mt-20px kenjo-mb-30px">
      <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-mr-5px">{{ pageTranslation.adminDescription }}</orgos-column>
      <orgos-column size="0">
        <orgos-action [uppercase]="false" (click)="goToSettings()" class="wiawd-action-button kenjo-font-size-14px">{{ pageTranslation.settingsLink }} <kenjo-icon size="14" class="kenjo-font-color-primary-5993e3">launch</kenjo-icon></orgos-action>
      </orgos-column>
    </orgos-column-container>
    <div *ngIf="!isAdmin" class="kenjo-mt-20px kenjo-mb-30px kenjo-font-color-light-text-757575">{{ pageTranslation.employeeDescription }}</div>
  </ng-container>

  <ng-container *ngIf="(showDefaultConfiguration && isFavoritesSectionActive) || !isFavoritesSectionActive">
    <orgos-column-container *ngIf="isAdmin && !isFavoritesSectionActive" class="kenjo-mb-30px">
      <orgos-column size="0" class="kenjo-font-color-light-text-757575 kenjo-mr-5px">{{ pageTranslation.adminDescription }}</orgos-column>
      <orgos-column size="0">
        <orgos-action [uppercase]="false" (click)="goToSettings()" class="wiawd-action-button kenjo-font-size-14px">{{ pageTranslation.settingsLink }} <kenjo-icon size="14" class="kenjo-font-color-primary-5993e3">launch</kenjo-icon></orgos-action>
      </orgos-column>
    </orgos-column-container>
    <div *ngFor="let sectionKey of configurationKeys; first as isFirst" class="kenjo-pb-10px kenjo-border-bottom kenjo-pb-20px" [class.kenjo-mt-20px]="!isFirst">
      <div class="kenjo-font-size-16px">{{ pageTranslation[sectionKey].label }}</div>
      <div *ngIf="pageTranslation[sectionKey].description" class="kenjo-font-color-light-text-757575 kenjo-font-size-14px kenjo-mt-20px">
        {{ pageTranslation[sectionKey].description }}
      </div>
      <div *ngIf="sectionKey === MY_TEAM_TRANSLATION_KEY_SUBORDINATES" class="kenjo-color-grey-background-lighter-f8f8f8 kenjo-font-color-light-text-757575 kenjo-mt-20px kenjo-ph-15px kenjo-pv-10px">
        {{ pageTranslation[sectionKey][myTeamWidgetSetting.subordinatesScope] }}
      </div>
    </div>
  </ng-container>

  <!-- BUTTONS -->
  <div class="kenjo-mt-40px kenjo-text-align-right">
    <orgos-button-raised color="Success" (click)="saveAndCloseFavoritesSection()">{{ isFavoritesSectionActive ? pageTranslation.saveAndCloseButton : pageTranslation.gotItButton}}</orgos-button-raised>
  </div>
</orgos-dialog-container>
