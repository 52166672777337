import { Pipe, PipeTransform } from '@angular/core';
import * as check from 'check-types';
import * as _ from 'lodash';
import * as moment from 'moment';

import { InternationalizationService } from '../../services/core/internationalization.service';

@Pipe({
  name: 'humanDate',
  pure: false
})
export class HumanDatePipe implements PipeTransform {
  private latestRawValue: any;
  private latestComputedValue: string | null = null;

  constructor(private internationalizationService: InternationalizationService) {}

  transform(value: any, includeHours: boolean = false, sameElseFormat: 'shortWithoutYear' | 'short' | 'medium' | 'long' = 'medium', alwaysShowDates: boolean = false): string | null {
    if (check.not.assigned(value) || (check.string(value) && check.emptyString(value)) || (check.not.string(value) && !moment.isMoment(value) && !moment.isDate(value))) {
      return null;
    }

    if (_.isEqual(this.latestRawValue, value)) {
      return this.latestComputedValue;
    }

    this.latestRawValue = value;

    const miscTranslation = this.internationalizationService.getMiscTranslation();
    if (check.not.assigned(miscTranslation) || check.emptyObject(miscTranslation)) {
      this.latestComputedValue = this.humanizeDate(value, includeHours, sameElseFormat);
    } else {
      this.latestComputedValue = this.humanizeDate(value, includeHours, sameElseFormat, miscTranslation, alwaysShowDates);
    }

    return this.latestComputedValue;
  }

  private humanizeDate(value: any, includeHours: boolean, sameElseFormat: 'shortWithoutYear' | 'short' | 'medium' | 'long', translations: any = { today: 'Today', tomorrow: 'Tomorrow', yesterday: 'Yesterday' }, alwaysShowDates?: boolean): string {
    const valueMoment = moment(value);

    const hoursFormat = includeHours ? ' LT' : '';

    let normalFormat = '';
    if (sameElseFormat === 'shortWithoutYear') {
      normalFormat = `L${hoursFormat}`;
    } else if (sameElseFormat === 'short') {
      normalFormat = `L${hoursFormat}`;
    } else if (sameElseFormat === 'medium') {
      normalFormat = `ll${hoursFormat}`;
    } else {
      normalFormat = `LL${hoursFormat}`;
    }

    if (sameElseFormat === 'shortWithoutYear') {
      return valueMoment.format(normalFormat).replace(new RegExp(`[^]?${valueMoment.format('YYYY')}.?`), '');
    } else {
      return valueMoment.calendar(null, {
        sameDay: alwaysShowDates ? normalFormat : `[${translations.today}]${hoursFormat}`,
        nextDay: alwaysShowDates ? normalFormat : `[${translations.tomorrow}]${hoursFormat}`,
        nextWeek: alwaysShowDates ? normalFormat : `dddd${hoursFormat}`,
        lastDay: alwaysShowDates ? normalFormat : `[${translations.yesterday}]${hoursFormat}`,
        lastWeek: normalFormat,
        sameElse: normalFormat
      });
    }
  }
}
