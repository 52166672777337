import { ErrorHandler, Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ErrorManagerService } from '../../standard/services/error/error-manager.service';

@Injectable()
export class PrivateUnexpectedErrorHandler implements ErrorHandler {
  constructor(private errorManager: ErrorManagerService) {}

  handleError(error: Error | ErrorEvent): void {
    if (environment.ENABLE_ERRORS_BY_CONSOLE === 'true') {
      this.errorManager.handleRawError(error, 'UnknownService', 'unknownOperation');
    } else {
      this.errorManager.handleRawErrorSilently(error, 'UnknownService', 'unknownOperation');
    }
  }
}
