import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CloudRoutingService } from '@app/core-features/cloud/routing/cloud-routing.service';
import { IChargebeeStatus } from '@app/private/services/private-chargebee.service';
import * as check from 'check-types';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { environment } from '@env';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { IOrganization } from '@app/private/services/private-organization.service';
import { IUserWorkModel } from '@app/models/user-work.model';
import { IMultiOrgOption } from '@app/models/controllers/organizations.model';
import { IProductNotification } from '@app/cloud-features/notifications/services/user-product-notification.service';

@Injectable()
export class GlobalBarService {
  private CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/global-bar`;
  private GLOBAL_BAR_SERVICE: string = 'GlobalBarService';

  constructor(private router: Router, private location: Location, private injector: Injector) {
    this.router.events
      .pipe(
        pairwise(),
        filter((events) => {
          return events[0] instanceof NavigationEnd && events[1] instanceof NavigationStart;
        })
      )
      .subscribe((events: Array<any>) => {
        if (check.assigned(events) && check.nonEmptyArray(events) && check.assigned(events[1]) && events[1].url === '/') {
          this.injector.get(CloudRoutingService).navigateToDefaultRoute();
        } else {
          this.setEnableDefaultBars(true);
        }
      });
  }
  public pageName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public setPageName(pageName: string): void {
    this.pageName.next(pageName);
  }

  public progressBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public setProgressBar(progressBar: boolean): void {
    this.progressBar.next(progressBar);
  }

  public enableDefaultBars: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public setEnableDefaultBars(enableDefaultBars: boolean, changeLocationState?: boolean): void {
    if (enableDefaultBars === false && check.assigned(changeLocationState) && changeLocationState === true) {
      this.router.navigateByUrl(`${this.location.path()}#full-screen`);
    } else if (enableDefaultBars === true && check.assigned(changeLocationState) && changeLocationState === true) {
      this.location.back();
    }

    this.enableDefaultBars.next(enableDefaultBars);
  }

  public secondaryMenuOptions: BehaviorSubject<Array<IMenuOption>> = new BehaviorSubject<Array<IMenuOption>>([]);
  public setSecondaryMenuOptions(secondaryMenuOptions: Array<IMenuOption>): void {
    if (check.not.assigned(secondaryMenuOptions)) {
      this.secondaryMenuOptions.next([]);
      return;
    }
    this.secondaryMenuOptions.next(secondaryMenuOptions);
  }

  public pushSecondaryMenuOption(secondaryMenuOption: IMenuOption): void {
    this.secondaryMenuOptions.next(this.secondaryMenuOptions.value.concat(secondaryMenuOption));
  }

  public selectedSecondaryMenuOption: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public setSelectedSecondaryMenuOption(selectedSecondaryMenuOption: number): void {
    this.selectedSecondaryMenuOption.next(selectedSecondaryMenuOption);
  }

  public logoUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public setLogoUrl(logoUrl: string): void {
    this.logoUrl.next(logoUrl);
  }

  public refreshUser: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);
  public executeRefreshUser(): void {
    this.refreshUser.next(void 0);
  }

  public chargebeeStatus: BehaviorSubject<IChargebeeStatus> = new BehaviorSubject<IChargebeeStatus>({ subscriptions: [], plans: [], customerStatus: '', showFreeTrialBanner: false, overdueAmount: { amount: 0, currency: 'EUR' } });
  public setChargebeeStatus(chargebeeStatus: IChargebeeStatus): void {
    this.chargebeeStatus.next(chargebeeStatus);
  }

  public resetGlobalBar(): void {
    this.setPageName('');
    this.setProgressBar(false);
    this.setEnableDefaultBars(true);
    this.setSecondaryMenuOptions([]);
    this.setSelectedSecondaryMenuOption(0);
    this.setLogoUrl('');
  }

  async getMine(): Promise<IGlobalBarData> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        return undefined;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const result: IGlobalBarData = await this.injector.get(HttpClient).get<IGlobalBarData>(`${this.CONTROLLER_URL}/mine`, httpOptions).toPromise();
      return result;
    } catch(error) {
      this.injector.get(ErrorManagerService).handleRawError(error, this.GLOBAL_BAR_SERVICE, 'getMine');
    }
  }
}

export interface IMenuOption {
  name: string;
  onClick?: Function;
  key?: string;
}

export interface IGlobalBarData {
  organization: IOrganization;
  userWork: IUserWorkModel;
  isMultiOrgUser: boolean;
  multiOrgOptions: Array<IMultiOrgOption>;
  productNotifications: Array<IProductNotification>;
};

