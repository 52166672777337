<orgos-dialog-container class="dialog-box" [title]="dialogTranslation.dialogName" (clickCloseButton)="closeDialog()">
  <ng-container *ngIf="costCenter">
    <orgos-column-container>
      <orgos-input-text orgosColumn [model]="costCenter" field="costCenterId" class="kenjo-mr-10px"></orgos-input-text>
      <orgos-input-text orgosColumn [model]="costCenter" field="name" class="kenjo-mr-10px"></orgos-input-text>
    </orgos-column-container>
    <div class="kenjo-mt-60px kenjo-text-align-right">
      <orgos-button-raised color="Success" (click)="addCostCenter()">{{dialogTranslation.buttonLabel}} </orgos-button-raised>
    </div>
  </ng-container>
</orgos-dialog-container>
