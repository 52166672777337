import { Component, Inject, Injector, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import {
  IProcessedPayslipMetadata,
  IUsersMap,
  PayslipsImportService,
} from '@app/common-components/layout-import-payslips/services/payslips-import.service';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateUppyService } from '@app/private/services/private-uppy.service';
import { DocumentService } from '@app/standard/services/document/document.service';
import {
  ISignatureCreateRequest,
  ISignatureOptions,
  ISignatureRequestRecipient,
  SignatureRequestService,
} from '@app/standard/services/signature-request/signature-request.service';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-send-signature-confirm-dialog',
  templateUrl: 'send-signature-confirm-dialog.component.html',
  styleUrls: ['send-signature-confirm-dialog.component.scss'],
})
export class SendSignatureConfirmDialogComponent {
  translations: any = {};
  summaryData: any = {};
  totalAfter: number;
  cancelButtonText: string;
  confirmButtonId: string;
  cancelButtonId: string;
  componentId: string;

  importingProgress: number;
  importingPayslips: boolean;
  onGoing: boolean;
  importSuccess: boolean;
  signaturesWithError: number = 0;

  usersMap: IUsersMap = {};
  shouldImportPayslips: boolean = false;
  signatureRequests: Array<any>;

  signatureOptions: ISignatureOptions = {
    signersOption: 'specific',
    signingMode: 'sequential',
    recipients: [],
    docOwners: [],
    companies: [],
    offices: [],
    departments: [],
    includeInactiveEmployees: true,
  };

  constructor(
    public dialogRef: MatLegacyDialogRef<SendSignatureConfirmDialogComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    public injector: Injector
  ) {
    this.translations = this.data.translations;
    this.summaryData = this.data.summaryData;
    this.totalAfter = this.summaryData.currentCredits - this.summaryData.creditsToBeUsed;
    this.usersMap = this.data.usersMap;
    this.shouldImportPayslips = this.data.shouldImportPayslips;
    this.signatureRequests = this.data.signatureRequests;
  }

  goToDigitalSignatures(): void {
    this.injector.get(Router).navigateByUrl(`/cloud/documents/digital-signature`);
    this.dialogRef.close();
  }

  goToImport(): void {
    this.dialogRef.close(true);
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  async confirmDialog(): Promise<void> {
    this.importingProgress = 0;
    this.importingPayslips = true;
    this.onGoing = true;

    if (this.shouldImportPayslips) {
      await this.importAllPayslips();
    } else {
      await this.sendSignatureRequests();
    }
    this.onGoing = false;
  }

  private async importAllPayslips(): Promise<void> {
    const finalPayslipsName = this.data.payslipsName.toLowerCase().endsWith('.pdf')
      ? this.data.payslipsName
      : `${this.data.payslipsName}.pdf`;
    const payslipsToUploadToS3 = _.filter(this.data.payslips, { isNewFile: true, mustBeDeleted: false });
    const fullProgress = payslipsToUploadToS3.length * 2 + this.summaryData.creditsToBeUsed;
    let statusProgress = 0;

    // upload documents to s3
    const uploadFiles = payslipsToUploadToS3.map(async (iPayslipToUpload: any, index) => {
      const fileMetadata = await this.injector
        .get(PrivateUppyService)
        .upload(new Blob([iPayslipToUpload.rawData]), finalPayslipsName, 'application/pdf');
      statusProgress = index + 1;
      this.importingProgress = (statusProgress / fullProgress) * 100;
      iPayslipToUpload.fileMetadata = fileMetadata;
      return iPayslipToUpload;
    });

    // create document-db records
    try {
      const payslipsUploaded: Array<IProcessedPayslipMetadata> = await Promise.all(uploadFiles);

      const payslipsToSaveInDb = _.filter(this.data.payslips, { isNewFile: false, mustBeDeleted: false }).concat(payslipsUploaded);
      const documentsToStore = payslipsToSaveInDb.map((iPayslip: IProcessedPayslipMetadata, index) => {
        const iDocumentFormated = {
          name: this.data.payslipsName,
          _file: iPayslip.fileMetadata,
          relatedTo: {
            typeRelatedTo: 'User',
            idRelatedTo: iPayslip.userId,
          },
          tags: this.data.payslipsTags,
          _isPayslip: true,
          _payslipPeriod: this.data.payslipsDate,
          hidden: false,
          _sentToSignature: iPayslip.signatureRequested,
        };

        return iDocumentFormated;
      });

      const uploadProgressCreation = documentsToStore.length * 0.2;
      const uploadProgress = documentsToStore.length * 0.8;
      statusProgress += uploadProgressCreation;
      this.importingProgress = (statusProgress / fullProgress) * 100;

      const documentUploaded = await this.injector.get(DocumentService).createInBulk(documentsToStore);

      statusProgress += uploadProgress;
      this.importingProgress = (statusProgress / fullProgress) * 100;
      // send documents to signature

      this.signaturesWithError = await this.sendToSignature(documentUploaded, { statusProgress, fullProgress });
      if (this.signaturesWithError > 0) {
        this.importSuccess = false;
      } else {
        this.importSuccess = true;
      }

      this.injector
        .get(PrivateAmplitudeService)
        .logEvent('upload payslips', { platform: 'Web', category: 'Payroll', subcategory1: 'Payroll', subcategory2: 'Payroll Group' });
    } catch (error) {
      this.importSuccess = false;
    }
  }

  private async sendSignatureRequests(): Promise<void> {
    try {
      // send documents to signature
      this.signaturesWithError = await this.sendToSignature(this.signatureRequests, { statusProgress: 0, fullProgress: 100 });
      if (this.signaturesWithError > 0) {
        this.importSuccess = false;
      } else {
        this.importSuccess = true;
      }

      // this.injector.get(PrivateAmplitudeService).logEvent(' payslips', { platform: 'Web', category: 'Payroll', subcategory1: 'Payroll', subcategory2: 'Payroll Group' });
    } catch {
      this.importSuccess = false;
    }
  }

  async sendToSignature(documents, { statusProgress, fullProgress }): Promise<number> {
    let errors = 0;
    let signatureProgress = statusProgress + 1;
    for (const iDocument of documents) {
      try {
        if (iDocument._sentToSignature) {
          const signatureRequest = this.buildSignatureRequest(iDocument);
          await this.injector.get(SignatureRequestService).createSignatureRequest(signatureRequest);
          this.importingProgress = (signatureProgress / fullProgress) * 100;
          signatureProgress++;
        }
      } catch (error) {
        errors++;
      }
    }
    return errors;
  }

  private buildSignatureRequest(document): ISignatureCreateRequest {
    const signerInfo = this.usersMap[document.relatedTo.idRelatedTo];
    const request: ISignatureCreateRequest = {
      recipients: [this.buildRecipient(signerInfo)],
      docOwners: [document._isPayslip ? document.relatedTo.idRelatedTo : document.ownerId],
      documentId: document._id,
      signingMode: this.signatureOptions.signingMode,
      deliveryType: 'url',
      personalMessage: this.signatureOptions.personalMessage,
    };
    return request;
  }

  private buildRecipient(signerInfo): ISignatureRequestRecipient {
    const recipient: ISignatureRequestRecipient = {
      signerId: signerInfo._id,
      name: signerInfo.displayName,
      email: signerInfo.email,
      signerType: 'Employee',
    };
    return recipient;
  }
}
