// tslint:disable: max-classes-per-file

/* CODE BASED ON: https://material.angular.io/guide/creating-a-custom-form-field-control */

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyFormFieldControl } from '@angular/material/legacy-form-field';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

/** Data structure for holding time entry. */
export class TimeEntry {
  constructor(public hour: string, public minute: string) {}
}

@Component({
  selector: 'orgos-input-time-form-control',
  templateUrl: 'input-time-form-control.component.html',
  styleUrls: ['input-time-form-control.component.scss'],
  providers: [{ provide: MatLegacyFormFieldControl, useExisting: InputTimeFormControlComponent }],
})
export class InputTimeFormControlComponent implements MatLegacyFormFieldControl<TimeEntry>, OnDestroy, OnInit {
  static nextId: number = 0;
  readonly KEY_EVENTS = {
    BACKSPACE: 'Backspace',
    DELETE: 'Delete',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_LEFT: 'ArrowLeft',
  };
  finalHourNumbers = ['3', '4', '5', '6', '7', '8', '9'];
  parts: UntypedFormGroup;
  stateChanges: Subject<void> = new Subject<void>();
  focused: boolean = false;
  controlType: string = 'itfc-input';
  hourFocused: boolean = false;
  minuteFocused: boolean = false;
  initValue: boolean = false;

  @HostBinding() id: string = `itfc-input-${InputTimeFormControlComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy: string = '';

  @ViewChild('hour', { static: true }) hour: any;
  @ViewChild('minute', { static: true }) minute: any;

  @Output() blurTimeInput: EventEmitter<void> = new EventEmitter<void>();

  get empty(): boolean {
    const {
      value: { hour, minute },
    } = this.parts;

    return !hour && !minute;
  }

  errorState: boolean = false;
  ngControl: NgControl | null = null;

  @HostBinding('class.itfc-floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  private _placeholder: string;
  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  get placeholder(): string {
    return this._placeholder;
  }

  private _required: boolean = false;
  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  get required(): boolean {
    return this._required;
  }

  private _disabled: boolean = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() maskPlaceholder: string | null = null;

  private _prevValue: TimeEntry;
  @Input()
  set value(time: TimeEntry) {
    const defaultTimeEntry = new TimeEntry('', '');

    const lastValue = { ...this._prevValue };
    this._prevValue = check.assigned(time) && time.hour !== 'null' && time.minute !== 'null' ? time : defaultTimeEntry;

    this.parts.setValue({ hour: this._prevValue.hour, minute: this._prevValue.minute });
    this.stateChanges.next();

    if (this.initValue === true) {
      if (this.debounceNewValues !== true) {
        this.valueChange.emit(this._prevValue);
      } else if (
        check.emptyString(lastValue.hour) !== check.emptyString(this._prevValue.hour) ||
        check.emptyString(lastValue.minute) !== check.emptyString(this._prevValue.minute) ||
        +lastValue.hour !== +this._prevValue.hour ||
        +lastValue.minute !== +this._prevValue.minute
      ) {
        this.newValueDebounced.next(this._prevValue);
      }
    }
  }

  get value(): TimeEntry {
    return this._prevValue;
  }

  @Input() debounceNewValues: boolean = false;
  @Input() debounceTime: number = 500;
  private newValueDebounced: Subject<any> = new Subject<any>();

  @Output() valueChange: EventEmitter<TimeEntry> = new EventEmitter<TimeEntry>();

  constructor(fb: UntypedFormBuilder, private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>, private injector: Injector) {
    this.parts = fb.group({
      hour: '',
      minute: '',
    });

    fm.monitor(elRef, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.initValue = true;
    this.initDebounce();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
  }

  private initDebounce(): void {
    if (this.debounceNewValues !== true) {
      return;
    }
    this.newValueDebounced
      .pipe(
        debounce(() => {
          return timer(this.debounceTime);
        }),
        distinctUntilChanged()
      )
      .subscribe((newValue) => {
        this.valueChange.emit(this._prevValue);
      });
  }

  setDescribedByIds(ids: Array<string>): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input')!.focus();
    }
  }

  onValueChange(type: 'hour' | 'minute', fromBlur?: boolean): void {
    if (this.focused === true && this.hourFocused === true && type === 'hour') {
      this.hourFocused = false;
      this.setValue(this.parts.value);
    }

    if (this.focused === true && this.minuteFocused === true && type === 'minute') {
      this.minuteFocused = false;
      this.setValue(this.parts.value);
    }

    if (this.focused === false && (this.parts.value.hour !== '' || this.parts.value.minute !== '')) {
      this.hourFocused = false;
      this.minuteFocused = false;

      const hour = this.parts.value.hour === '' ? '00' : this.parts.value.hour;
      const minute = this.parts.value.minute === '' ? '00' : this.parts.value.minute;

      this.setValue({ hour, minute });
    }

    if (fromBlur) {
      this.blurTimeInput.emit();
    }
  }

  onInput(type: 'hour' | 'minute'): void {
    if (
      type === 'hour' &&
      (this.parts.value.hour.length === 2 || this.finalHourNumbers.includes(this.parts.value.hour)) &&
      this.minuteFocused === false
    ) {
      this.onValueChange('hour');
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.minute.nativeElement.focus();
    }

    if (type === 'minute' && this.parts.value.minute.length === 2) {
      this.onValueChange('minute');
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.minute.nativeElement.blur();
    }
  }

  onFocus(type: 'hour' | 'minute'): void {
    if (type === 'hour') {
      this.hourFocused = true;
      this.hour.nativeElement.select();
    } else {
      this.minuteFocused = true;
      this.minute.nativeElement.select();
    }
  }

  onKeyPress(type: 'hour' | 'minute', event: KeyboardEvent): void {
    if (
      [
        this.KEY_EVENTS.ARROW_RIGHT,
        this.KEY_EVENTS.ARROW_LEFT,
        this.KEY_EVENTS.ARROW_UP,
        this.KEY_EVENTS.ARROW_DOWN,
        this.KEY_EVENTS.BACKSPACE,
        this.KEY_EVENTS.DELETE,
      ].includes(event.key)
    ) {
      event.preventDefault();
    }

    if (event.key === this.KEY_EVENTS.ARROW_RIGHT && type === 'hour') {
      this.minute.nativeElement.focus();
    }

    if (event.key === this.KEY_EVENTS.ARROW_LEFT && type === 'minute') {
      this.hour.nativeElement.focus();
    }

    if ((event.key === this.KEY_EVENTS.ARROW_UP || event.key === this.KEY_EVENTS.ARROW_DOWN) && type === 'hour') {
      const unparsedHour = this.parts.value?.hour ? Number(this.parts.value.hour) + (event.key === this.KEY_EVENTS.ARROW_UP ? 1 : -1) : 0;
      const hour = unparsedHour < 0 ? '23' : unparsedHour > 23 ? '00' : String(unparsedHour);

      this.setValue({ hour: hour.length === 1 ? `0${hour}` : hour, minute: this.parts.value.minute ?? '' });
    }

    if ((event.key === this.KEY_EVENTS.ARROW_UP || event.key === this.KEY_EVENTS.ARROW_DOWN) && type === 'minute') {
      const unparsedMinute = this.parts.value?.minute
        ? Number(this.parts.value.minute) + (event.key === this.KEY_EVENTS.ARROW_UP ? 1 : -1)
        : 0;
      const minute = unparsedMinute < 0 ? '59' : unparsedMinute > 59 ? '00' : String(unparsedMinute);

      this.setValue({ hour: this.parts.value.hour ?? '', minute: minute.length === 1 ? `0${minute}` : minute });
    }

    if ([this.KEY_EVENTS.BACKSPACE, this.KEY_EVENTS.DELETE].includes(event.key)) {
      const removedValue = (event.target as HTMLInputElement)?.value;

      if (type === 'hour') {
        const unparsedHour = this.parts.value?.hour;
        const hour = removedValue.length === 2 || removedValue.length === (unparsedHour?.length || 0) ? '' : String(unparsedHour);
        this.setValue({ hour: hour.length === 1 ? `0${hour}` : hour, minute: this.parts.value.minute ?? '' });
      }

      if (type === 'minute') {
        const unparsedMinute = this.parts.value?.minute;
        const minute = removedValue.length === 2 || removedValue.length === (unparsedMinute?.length || 0) ? '' : String(unparsedMinute);
        this.setValue({ hour: this.parts.value.hour ?? '', minute: minute.length === 1 ? `0${minute}` : minute });
      }

      this.onValueChange(type);
    }
  }

  setValue(newValue: TimeEntry): void {
    const auxValue = new TimeEntry(newValue.hour ?? '', newValue.minute ?? '');

    if (auxValue.hour !== '' && Number(auxValue.hour) > 23) {
      auxValue.hour = '23';
    }

    if (auxValue.minute !== '' && Number(auxValue.minute) > 59) {
      auxValue.minute = '59';
    }

    this.value = auxValue;
  }
}
