import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PolicyTypeType } from '@app/cloud-features/time-off/services/time-off-policy.service';
import { ITimeOffRequestModel } from '@app/cloud-features/time-off/services/time-off-request.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { TIME_OFF_ACTION_ASSIGN_POLICY, TIME_OFF_ACTION_SCHEDULE_POLICY, TIME_OFF_ACTION_UNASSIGN_POLICY } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class TimeOffUserHistoryController {
  private URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/time-off-user-history`;

  constructor(private injector: Injector) {}

  async getUserHistoryList(options: IFilter): Promise<IHistoryResult> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserHistoryController.name, 'getUserHistoryList');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return (await this.injector.get(HttpClient).post(`${this.URL}/`, options, httpOptions).toPromise()) as IHistoryResult;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserHistoryController.name, 'getUserHistoryList');
    }
  }

  async getTypes(userId: string): Promise<Array<ITimeOffType>> {
    try {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        throw new OrgosError(`${this.URL}`, ErrorCodes.UNAUTHORIZED, TimeOffUserHistoryController.name, 'getTypes');
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return (await this.injector.get(HttpClient).get(`${this.URL}/types/${userId}`, httpOptions).toPromise()) as Array<ITimeOffType>;
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, TimeOffUserHistoryController.name, 'getTypes');
    }
  }
}

export interface IHistoryResult {
  records: Array<ITimeOffHistory>;
  disabledShowMoreButton: boolean;
}
export interface IFilter {
  userId: string;
  historyListPage: number;
  showPolicyChanges: boolean;
  typeSelected: string;
}

export interface ITimeOffHistory {
  action: HistoryAction;
  actionDate: string;
  isRequest: boolean;
  canBeManaged: boolean;
  unlimitedAllowance: boolean;
  timeOffType: {
    _id: string;
    name: string;
    color: string;
    attachments: boolean;
    tags: Array<string>;
  };
  policy: {
    _id: string;
    _type: PolicyTypeType;
    name: string;
  };
  previousPolicy: {
    _id: string;
    _type: PolicyTypeType;
    name: string;
  };
  createdBy: {
    _id: string;
    name: string;
    photoUrl: string;
  };
  scheduledFor?: string;
  adjustment?: number;
  cycleStartDate?: string;
  cycleEndDate?: string;
  description?: string;
  request?: ITimeOffRequestModel;
  requestUserName?: string;
  requestHistory?: Array<IDetailHistory>;
  requestAttachments?: Array<IRequestAttachment>;
  requestPermission?: {
    canBeCanceled: boolean;
    canBeReviewed: boolean;
    canBeEdited: boolean;
  };
  firstApprover?: {
    _id: string;
    name: string;
    photoUrl: string;
  };
  secondApproverList?: Array<{
    _id: string;
    name: string;
    photoUrl?: string;
    approverType: 'user' | 'manager' | 'company' | 'office' | 'department' | 'team' | 'area';
  }>;
}
export interface IDetailHistory {
  action: HistoryAction;
  actionDate: string;
  createdBy: {
    _id: string;
    name: string;
    photoUrl: string;
  };
  workingTime?: number;
  adjustment?: number;
  cycleStartDate?: string;
  cycleEndDate?: string;
  description?: string;
}
export interface IRequestAttachment {
  id: string;
  documentName: string;
  documentUrl: string;
  documentExtension: string;
  documentSize: number;
  createdByName: string;
  createdByPhotoUrl: string;
  createdAt: string;
}

export interface ITimeOffType {
  _id: string;
  name: string;
}

export type HistoryAction = TIME_OFF_ACTION_ASSIGN_POLICY | TIME_OFF_ACTION_UNASSIGN_POLICY | TIME_OFF_ACTION_SCHEDULE_POLICY;
