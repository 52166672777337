<orgos-widget titleKey="approval" [loadingWidget]="loadingWidget" [existingData]="approvalSummaryOverdue?.length > 0 || approvalSummaryPending?.length > 0" [footerHeight]="17">
  <orgos-widget-content>
    <ng-container *ngIf="approvalSummaryOverdue?.length > 0">
      <orgos-text type="SecondaryText" color="Warn">{{ translation.overdueLabel }} ({{ approvalSummaryOverdue.length }}) </orgos-text>
      <orgos-column-container wrap="true" class="kenjo-mb-10px">
        <orgos-column *ngFor="let approvalEntry of approvalSummaryOverdue" size="0" class="kenjo-mv-10px kenjo-mr-10px">
          <orgos-avatar
            (click)="navigateToApprovalRequest(approvalEntry.request.id)"
            [matTooltip]="approvalEntry.user.name"
            class="wa-avatar"
            orgosColumn="0"
            [photoUrl]="approvalEntry.user.photoUrl"
            [avatarName]="approvalEntry.user.name"
            size="small"
          ></orgos-avatar>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
    <ng-container *ngIf="approvalSummaryPending?.length > 0">
      <orgos-text type="SecondaryText" color="LightText">{{ translation.pendingLabel }} ({{ approvalSummaryPending.length }})</orgos-text>
      <orgos-column-container wrap="true">
        <orgos-column *ngFor="let approvalEntry of approvalSummaryPending" size="0" class="kenjo-mv-10px kenjo-mr-10px">
          <orgos-avatar
            (click)="navigateToApprovalRequest(approvalEntry.request.id)"
            [matTooltip]="approvalEntry.user.name"
            class="wa-avatar"
            orgosColumn="0"
            [photoUrl]="approvalEntry.user.photoUrl"
            [avatarName]="approvalEntry.user.name"
            size="small"
          ></orgos-avatar>
        </orgos-column>
      </orgos-column-container>
    </ng-container>
  </orgos-widget-content>
  <orgos-widget-footer>
    <div class="kenjo-text-align-center">
      <orgos-action (click)="navigateToApprovalRequestPage()">{{ translation.viewAllButton }}</orgos-action>
    </div>
  </orgos-widget-footer>
</orgos-widget>
