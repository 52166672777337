import { Component, HostBinding, OnInit } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { PerformanceReviewListController } from '@app/cloud-features/performance-review/controllers/performance-review-list.controller';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PeopleDetailService } from '@app/standard/pages/people-detail/people-detail.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import * as customPermissions from '@carlos-orgos/orgos-utils/middlewares/custom-permission-utils/custom-permission-utils';
import * as check from 'check-types';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUserPersonalModel } from '../../../../models/user-personal.model';
import { PrivateSecurityService } from '../../../../private/services/private-security.service';
import { ISelectOption } from '../../../core/select-option';
import { AuthenticationService } from '../../../services/core/authentication.service';
import { CloudRoutesService } from '../../../services/core/cloud-routes.service';
import { UserPersonalService } from '../../../services/user/user-personal.service';
import { IUserWorkScheduleModel, UserWorkScheduleService } from '../../../services/user/user-work-schedule.service';
import { UserWorkService } from '../../../services/user/user-work.service';
import { GenericPage, IMenuOption, ITranslationResource } from '../../generic.page';

@Component({
  selector: 'orgos-people-detail-performance-review-page',
  templateUrl: 'people-detail-performance-review.page.html',
  styleUrls: ['people-detail-performance-review.page.scss'],
})
export class PeopleDetailPerformanceReviewPage extends GenericPage implements OnInit {
  id: string;

  protected profilePermissionsResources: Array<string> = ['user-confidential', 'payroll-feature', 'employees'];
  protected translationResources: Array<ITranslationResource> = [
    { name: 'page', translationKey: 'people-detail-performance-review-page' },
    { name: 'misc', translationKey: 'people-detail-misc' },
    { name: 'globalMisc', translationKey: 'misc' },
    { name: 'standardPickList', translationKey: 'standard-picklists' },
  ];

  myPermissions: any = {};

  userPersonal: IUserPersonalModel;
  userWorkSchedule: IUserWorkScheduleModel;
  userWork: any;

  filterBy: Array<ISelectOption> = [];
  filterFrom: Array<ISelectOption> = [];

  selectedFilterBy: string = 'allActivity';
  selectedFilterFrom: string = 'last6Months';

  feedbacksByType: any = {};
  reviewsToShow: boolean = false;

  tabIndex: number = 0;
  showToDosTab: boolean = false;

  userManagePoliciesInfo: any;
  seeDetailsUserId: string;
  seeDetailsUserIdSubscription: Subscription;
  @HostBinding('style.max-width') maxWidth = '1410px';

  protected beforeInit(): Promise<void> {
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('id');
        })
      )
      .subscribe((id: string) => {
        const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
        const myProfile = loggedUser.profileKey;

        if (id === loggedUser._id.toString()) {
          this.showToDosTab = true;
          this.tabIndex = 0;
        }

        this.id = id;
        if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
          return;
        }
        this.getPermissions()
          .then(() => {
            return this.getPermissionsToSeePerformanceTab(this.myPermissions['performance-feedback-results']);
          })
          .then((canAccessPerformance: boolean) => {
            if (!canAccessPerformance) {
              this.router.navigate(['../'], { relativeTo: this.route });
              return;
            }
            this.refreshData();
          })
          .catch(() => {
            // An error is already shown
            this.router.navigate(['../'], { relativeTo: this.route });
            return;
          });
      });
    return Promise.resolve();
  }

  protected fetchData(resolveFetchData: Function, rejectFetchData: Function): void {
    this.initFilters();
    if (check.not.assigned(this.id) || check.emptyString(this.id)) {
      rejectFetchData();
      return;
    }

    const getUserPersonal = this.injector.get(UserPersonalService).getById(this.id);
    const getUserWork = this.injector.get(UserWorkService).getById(this.id);
    const getUserWorkSchedule = this.injector.get(UserWorkScheduleService).getById(this.id);
    Promise.all([getUserPersonal, getUserWork, getUserWorkSchedule])
      .then((userInfo) => {
        this.userPersonal = userInfo[0];
        this.userWork = userInfo[1];
        this.userWorkSchedule = userInfo[2];
        resolveFetchData();
      })
      .catch(() => {
        rejectFetchData();
        // An error is already shown
      });

    const filterOptions = {
      userId: this.id,
      filterFrom: this.selectedFilterFrom,
    };
    this.injector
      .get(PerformanceReviewListController)
      .getPeopleReviewList(filterOptions)
      .then((performanceReviewResults) => {
        this.feedbacksByType = performanceReviewResults;
        this.checkReviewsToShow();
        resolveFetchData();
      })
      .catch(() => {
        this.reviewsToShow = false;
        rejectFetchData();
        // An error is already shown
      });
    this.seeDetailsUserIdSubscription = this.injector.get(PeopleDetailService).seeDetailsUserId$.subscribe((id: string) => {
      this.userManagePoliciesInfo = {
        userPersonal: this.userPersonal,
        userStartDate: this.userWork.startDate,
        timeOffTypes: this.injector.get(PeopleDetailService).timeOffTypes,
      };
      this.seeDetailsUserId = id;
      this.maxWidth = '';
    });
  }

  protected async configureGlobalBar(): Promise<void> {
    this.globalBarConfig.pageName = this.i18n.page.pageName;

    await this.injector.get(PeopleDetailService).onPeopleRouteLoaded(this.id);

    const options: Array<IMenuOption> = [
      {
        name: this.i18n.misc.publicProfileTab,
        onClick: () => {
          this.router.navigate(['../'], { relativeTo: this.route });
        },
      },
    ];

    const myProfile = this.injector.get(AuthenticationService).getLoggedUser().profileKey;
    if (myProfile === 'admin' || myProfile === 'hr-admin' || myProfile === 'finance-admin') {
      options.push({
        name: this.i18n.misc.personalTab,
        onClick: () => {
          this.router.navigate(['../personal'], { relativeTo: this.route });
        },
      });
      options.push({
        key: 'compensation-tab',
        name: this.i18n.misc.compensationTab,
        onClick: () => {
          this.router.navigate(['../compensation'], { relativeTo: this.route });
        },
      });
      options.push({
        name: this.i18n.misc.payrollTab,
        onClick: () => {
          this.router.navigate(['../payroll'], { relativeTo: this.route });
        },
      });

      if (
        this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') &&
        check.assigned(this.userWorkSchedule) &&
        check.assigned(this.userWorkSchedule.trackAttendance) &&
        this.userWorkSchedule.trackAttendance === true
      ) {
        options.splice(2, 0, {
          key: 'attendance-tab',
          name: this.i18n.misc.attendanceTab,
          onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
        });
      }

      options.push({
        name: this.i18n.misc.performanceTab,
        onClick: () => {
          this.router.navigate(['../performance'], { relativeTo: this.route });
        },
      });

      if (this.injector.get(PeopleDetailService).canSeeUsersSmartDocs()) {
        options.push({
          name: this.i18n.misc.smartDocsTab,
          onClick: () => this.injector.get(PeopleDetailService).openEmployeeDoc(this.id),
        });
      }

      if (this.injector.get(PeopleDetailService).canSeeUsersTimeOff()) {
        options.push({
          name: this.i18n.misc.timeOffTab,
          onClick: () => this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.id),
        });
      }

      this.globalBarConfig.secondaryMenuOptions = options;
      this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.performanceTab]);

      return Promise.resolve();
    }

    return new Promise((resolve) => {
      let payrollPermissions;
      this.getPermissions()
        .then(() => {
          payrollPermissions = this.myPermissions['payroll-feature'];
          return this.injector.get(PeopleDetailService).getPermissionsToSeePersonalTab(this.myPermissions['employees'], this.id);
        })
        .then((canAccessPersonal: boolean) => {
          if (canAccessPersonal === true) {
            options.push({
              name: this.i18n.misc.personalTab,
              onClick: () => {
                this.router.navigate(['../personal'], { relativeTo: this.route });
              },
            });
          }
          return this.getPermissionsToSeeAttendanceTab(this.myPermissions['employees']);
        })
        .then((canAccessAttendance: boolean) => {
          if (canAccessAttendance === true) {
            options.splice(2, 0, {
              key: 'attendance-tab',
              name: this.i18n.misc.attendanceTab,
              onClick: () => this.router.navigate(['../attendance'], { relativeTo: this.route }),
            });
          }
          return this.getPermissionsToSeeCompensationTab(payrollPermissions);
        })
        .then((canSeeCompensationTab: boolean) => {
          if (canSeeCompensationTab === true) {
            options.push({
              key: 'compensation-tab',
              name: this.i18n.misc.compensationTab,
              onClick: () => this.router.navigate(['../compensation'], { relativeTo: this.route }),
            });
            options.push({
              name: this.i18n.misc.payrollTab,
              onClick: () => this.router.navigate(['../payroll'], { relativeTo: this.route }),
            });
          }
          return this.getPermissionsToSeePerformanceTab(this.myPermissions['performance-feedback-results']);
        })
        .then((canSeePerformanceTab: boolean) => {
          if (canSeePerformanceTab === true) {
            options.push({
              name: this.i18n.misc.performanceTab,
              onClick: () => this.router.navigate(['../performance'], { relativeTo: this.route }),
            });
          }

          if (this.injector.get(PeopleDetailService).canSeeUsersSmartDocs()) {
            options.push({
              name: this.i18n.misc.smartDocsTab,
              onClick: () => this.injector.get(PeopleDetailService).openEmployeeDoc(this.id),
            });
          }

          if (this.injector.get(PeopleDetailService).canSeeUsersTimeOff()) {
            options.push({
              name: this.i18n.misc.timeOffTab,
              onClick: () => this.injector.get(PeopleDetailService).openEmployeeTimeOff(this.id),
            });
          }

          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.performanceTab]);
          resolve();
        })
        .catch(() => {
          this.globalBarConfig.secondaryMenuOptions = options;
          this.globalBarConfig.selectedSecondaryMenuOption = _.findIndex(options, ['name', this.i18n.misc.personalTab]);
          resolve();
        });
    });
  }

  protected afterInit(): Promise<void> {
    this.logAmplitudeEvents();
    return Promise.resolve();
  }

  private logAmplitudeEvents() {
    this.injector
      .get(PrivateAmplitudeService)
      .logEvent('view employee page', { category: 'Navigation', platform: 'Web', subcategory1: 'performance', subcategory2: this.id });

    const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
    const curentEvent = this.id === loggedUser._id ? 'current' : 'another';

    this.injector.get(PrivateAmplitudeService).logEvent(`view performance tab (of ${curentEvent} user profile)`, {
      category: 'Performance',
      subcategory1: 'Navigation',
      subcategory2: 'Access To-Dos/Activity',
    });
  }

  private getPermissions(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (check.assigned(this.myPermissions) && check.nonEmptyObject(this.myPermissions)) {
        resolve();
        return;
      }

      this.injector
        .get(PrivateSecurityService)
        .getAllPermissions()
        .then((allPermissions) => {
          this.myPermissions = allPermissions;
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private getPermissionsToSeeAttendanceTab(employeesPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      this.initUserWorkSchedule()
        .then(() => {
          if (
            this.injector.get(CloudRoutesService).checkRoute('people/:id/attendance') === false ||
            check.not.assigned(this.userWorkSchedule) ||
            this.userWorkSchedule.trackAttendance === false
          ) {
            resolve(false);
          }

          if (loggedUser._id === this.id && employeesPermissions.c_viewAttendanceTab_own === true) {
            resolve(true);
          }
          if (employeesPermissions.c_viewAttendanceTab_all === true) {
            resolve(true);
          }
          if (
            check.not.assigned(employeesPermissions.c_viewAttendanceTab_custom) ||
            check.emptyArray(employeesPermissions.c_viewAttendanceTab_custom)
          ) {
            resolve(false);
          }

          return this.injector.get(UserWorkService).getAllUserWorkCache();
        })
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            employeesPermissions[`c_viewAttendanceTab_custom`],
            employeesPermissions[`c_viewAttendanceTab_own`],
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private initUserWorkSchedule(): Promise<void> {
    if (check.assigned(this.userWorkSchedule) && check.nonEmptyObject(this.userWorkSchedule)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.injector
        .get(UserWorkScheduleService)
        .getById(this.id)
        .then((userWorkSchedule: IUserWorkScheduleModel) => {
          this.userWorkSchedule = userWorkSchedule;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  private getPermissionsToSeeCompensationTab(payrollPermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/compensation') === false) {
        resolve(false);
      }

      if (loggedUser._id === this.id && payrollPermissions.c_viewCompensation_own === true) {
        resolve(true);
      }
      if (payrollPermissions.c_viewCompensation_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(payrollPermissions.c_viewCompensation_custom) ||
        check.emptyArray(payrollPermissions.c_viewCompensation_custom)
      ) {
        resolve(false);
      }

      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            payrollPermissions[`c_viewCompensation_custom`],
            payrollPermissions[`c_viewCompensation_own`],
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private getPermissionsToSeePerformanceTab(performancePermissions: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const loggedUser = this.injector.get(AuthenticationService).getLoggedUser();
      if (this.injector.get(CloudRoutesService).checkRoute('people/:id/performance') === false) {
        resolve(false);
      }
      if (loggedUser._id === this.id) {
        resolve(true);
      }
      if (performancePermissions.c_readFeedback_all === true) {
        resolve(true);
      }
      if (
        check.not.assigned(performancePermissions.c_readFeedback_custom) ||
        check.emptyArray(performancePermissions.c_readFeedback_custom)
      ) {
        resolve(false);
      }
      this.injector
        .get(UserWorkService)
        .getAllUserWorkCache()
        .then((allUserWork) => {
          let myUserWork = this.userWork;
          if (check.not.assigned(myUserWork)) {
            myUserWork = allUserWork.find((iUserWork) => {
              return iUserWork._id === this.id;
            });
          }
          return customPermissions.applyCustomPermissionsToDocument(
            null,
            'user-work',
            performancePermissions.c_readFeedback_custom,
            performancePermissions.read_own,
            myUserWork,
            allUserWork,
            loggedUser
          );
        })
        .then((customPermissionResult) => {
          resolve(customPermissionResult);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private initFilters(): void {
    this.filterBy.push({ name: this.i18n.page.allActivityFilterOption, value: 'allActivity' });
    this.filterBy.push({ name: this.i18n.page.iGaveFeedbackFilterOption, value: 'userIsReviewer' });
    this.filterBy.push({ name: this.i18n.page.iReceivedFeedbackFilterOption, value: 'userIsReviewee' });
    this.filterFrom.push({ name: this.i18n.page.allTimeFilterOption, value: 'allTime' });
    this.filterFrom.push({ name: this.i18n.page.lastYearFilterOption, value: 'lastYear' });
    this.filterFrom.push({ name: this.i18n.page.last6MonthsFilterOption, value: 'last6Months' });
  }

  public openReview(review: any, isReviewWhereUserIdGaveFeedback: boolean): void {
    if (!review.canRead) {
      return;
    }
    this.injector.get(Router).navigate([`cloud/performance-review/form/${this.userPersonal._id}/${review.linkToDetail}/0`], {
      queryParams: { isUserReviewer: isReviewWhereUserIdGaveFeedback },
    });
  }

  public changeFilterFrom(): void {
    const filterOptions = {
      userId: this.id,
      filterFrom: this.selectedFilterFrom,
    };
    this.injector
      .get(PerformanceReviewListController)
      .getPeopleReviewList(filterOptions)
      .then((performanceReviewResults) => {
        this.feedbacksByType = performanceReviewResults;
        this.checkReviewsToShow();
      })
      .catch(() => {
        // An error is already shown
      });
  }

  public changeFilterBy(): void {
    this.checkReviewsToShow();
  }

  private checkReviewsToShow(): void {
    this.reviewsToShow = false;
    if (check.not.assigned(this.feedbacksByType)) {
      return;
    }

    if (
      this.selectedFilterBy === 'allActivity' &&
      (check.nonEmptyArray(this.feedbacksByType.userIsReviewer) || check.nonEmptyArray(this.feedbacksByType.userIsReviewee))
    ) {
      this.reviewsToShow = true;
      return;
    }
  }

  public hasReviewInfo(reviewType: string): boolean {
    return this.feedbacksByType && this.feedbacksByType[reviewType] && this.feedbacksByType[reviewType].length > 0;
  }

  exitTimeOffFullScreen() {
    this.injector.get(PeopleDetailService).closeEmployeeTimeOff();
    this.injector.get(GlobalBarService).setPageName(this.i18n.page.pageName);
    this.refreshGlobalBar();
    this.maxWidth = '1410px';
  }

  destroy(): Promise<void> {
    this.seeDetailsUserIdSubscription.unsubscribe();
    return Promise.resolve();
  }
}
