import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { SignatureRequestService } from '@app/standard/services/signature-request/signature-request.service';

import { StandardServicesRegistry } from '../../../services/standard-services.registry';

@Component({
  selector: 'orgos-send-reminder-dialog',
  templateUrl: 'send-reminder.dialog.html',
  styleUrls: ['send-reminder.dialog.scss'],
})
export class SendReminderDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};
  subtitleText: string;

  constructor(
    private injector: Injector,
    private dialogRef: MatLegacyDialogRef<SendReminderDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private snackBar: MatLegacySnackBar,
    private standardServicesRegistry: StandardServicesRegistry
  ) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('documents-send-reminder-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
        const { documents } = this.data;
        const { dialogSubtitle_singular, dialogSubtitle } = this.dialogTranslation;
        const subtitleTemplate = documents.length === 1 ? dialogSubtitle_singular : dialogSubtitle;
        const subtitleData = {
          recipient: documents[0].name,
          selectedNumber: documents.length,
        };
        this.subtitleText = this.injector.get(I18nDataPipe).transform(subtitleTemplate, subtitleData);
      })
      .catch(() => {
        this.dialogTranslation = {};
      });
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  sendReminder(): void {
    this.dialogRef.close();

    this.injector
      .get(SignatureRequestService)
      .sendReminders(this.data.documents)
      .then(() => {
        this.snackBar.open(this.dialogTranslation.reminderSentSuccessful, 'OK', {
          duration: 5000,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.dialogRef.close();
      });
  }
}
