import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '@app/standard/core/select-option';

@Component({
  selector: 'kenjo-custom-rule-condition-component',
  templateUrl: 'custom-rule-condition.component.html',
  styleUrls: ['custom-rule-condition.component.scss']
})
export class CustomRuleConditionComponent {
  selectedOption: any;
  selectedOperator: any;
  selectedValue: any;

  optionValues: Array<any> = [];

  @Input() allOptions: Array<ISelectOption>;
  @Input() allOperators: Array<ISelectOption>;
  @Input() allPossibleValues: any;
  @Input() condition: any;
  @Input() pageTranslation: any;
  @Input() readOnly: boolean = false;
  @Input() autoOrder: boolean = false;
  @Input() hasDeleteButtonBorder: boolean = false;

  @Output() validateConditions: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteCondition: EventEmitter<any> = new EventEmitter<any>();

  public changeInOptionValue(event: any) {
    this.optionValues = [...this.allPossibleValues[event]];
    this.optionValues = this.optionValues.map((iValue) => {
      return { name: iValue.name, value: iValue._id };
    });
    this.condition.collectionName = event;
    this.condition.value = undefined;
    this.validate();
  }

  public changeInOperatorValue(event: any) {
    this.condition.operator = event;
    this.validate();
  }

  public changeInValue(event: any) {
    this.condition.value = event;
    this.validate();
  }

  private validate(): void {
    this.validateConditions.emit();
  }

  public deleteThisCondition() {
    this.deleteCondition.emit();
  }
}
