<div class="ahcc-card kenjo-font-color-light-text-757575 kenjo-font-size-12px">
  <orgos-column-container *ngIf="!rows" class="ahcc-loading-container kenjo-mv-15px">
    <orgos-loading-spinner orgosColumn="0"></orgos-loading-spinner>
  </orgos-column-container>
  <ng-container *ngIf="rows">
    <orgos-column-container *ngFor="let row of rows" class="ahcc-row" [ngClass]="{ 'kenjo-font-weight-bold': row.highlight }">
      <orgos-column size="0" class="ahcc-column">
        {{ row.text }}
      </orgos-column>
      <orgos-column *ngIf="policyType === POLICY_TYPE_DAY" size="0" class="ahcc-column kenjo-text-align-right kenjo-ml-10px">
        <span>
          <span *ngIf="!row.noSign">{{ row.value | number: '1.0-2' | plusMinusSign }}</span>
          <span *ngIf="row.noSign">{{ row.value | number: '1.0-2' }}</span>
          {{ row.value === 1 || row.value === -1 ? this.translations.day : this.translations.days }}
        </span>
      </orgos-column>
      <orgos-column *ngIf="policyType !== POLICY_TYPE_DAY" size="0" class="ahcc-column kenjo-text-align-right kenjo-ml-10px"> {{ !row.noSign && row.value > 0 ? '+' : '' }}{{ row.value | duration }} </orgos-column>
      <orgos-column size="0" *ngIf="showTooltip" class="ahcc-column ahcc-info-tooltip kenjo-ml-10px">
        <kenjo-icon orgosColumn="0" *ngIf="row.tooltipInfo" [size]="16" class="kenjo-cursor-pointer kenjo-font-color-success-00b72e" [matTooltip]="row.tooltipInfo">info_outline</kenjo-icon>
      </orgos-column>
    </orgos-column-container>
  </ng-container>
</div>
