import { Pipe, PipeTransform } from '@angular/core';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import * as check from 'check-types';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate',
  pure: false
})
export class FormatDatePipe implements PipeTransform {
  constructor(private authService: PrivateAuthenticationService) {}

  transform(value: Date | moment.Moment | string, format: string): string {
    if (check.not.assigned(value) || (check.string(value) && check.emptyString(value)) || (check.not.string(value) && !moment.isMoment(value) && !moment.isDate(value))) {
      return null;
    }
    const currentLang = this.authService.getLoggedUser().language;
    return moment.utc(value).locale(currentLang).format(format).replace(/\./g, '');
  }
}
