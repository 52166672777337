import { Directive, HostListener } from '@angular/core';
import * as check from 'check-types';
@Directive({
  selector: '[allowNumbersOnly]'
})
export class AllowNumbersOnlyDirective {
  private _allowedKeys = ['ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab'];

  constructor() {}

  @HostListener('keydown', ['$event'])
  onInputChange(event) {
    const eventKey = event.key;

    if (this._allowedKeys.includes(eventKey)) {
      return;
    }

    if (check.assigned(String(eventKey).match(/[^0-9]/))) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
