<div *ngIf="customPermission && customPermission.length > 0" class="kenjo-text-align-center">
  <ng-container *ngFor="let iBlockAndConditions of customPermission; let isLastOr = last">
    <div class="cps-and-container kenjo-border kenjo-inline-block kenjo-ph-5px">
      <!-- BLOCK OF AND CONDITIONS STARTS HERE -->
      <ng-container *ngFor="let andCondition of iBlockAndConditions.blockAndConditions; let isLastAnd = last">
        <!-- SPECIFIC AND CONDITION STARTS HERE -->
        <div class="kenjo-p-10px kenjo-inline-block cps-no-shrink">
          <div *ngIf="andCondition.subordinatesOnly === true" class="kenjo-font-size-12px">
            {{ i18n.subordinateEmployees }}
          </div>
          <div *ngIf="andCondition.directSubordinatesOnly === true" class="kenjo-font-size-12px">
            {{ i18n.directSubordinateEmployees }}
          </div>

          <ng-container *ngIf="!calculatingValues">
            <div *ngIf="!andCondition.subordinatesOnly && !andCondition.directSubordinatesOnly" class="kenjo-font-size-12px">
              {{ collectionTranslation[andCondition.ownerInCollection.field] ? collectionTranslation[andCondition.ownerInCollection.field] : userWorkTranslation[andCondition.ownerInCollection.field] }}&nbsp;
              <span class="kenjo-font-color-light-text-757575">{{ i18n[andCondition.operator] }}&nbsp;</span>
              {{ andCondition.equalsToStaticValue ? mapIdToName[andCondition.equalsToStaticValue] : getEqualsToLoggedUserField(andCondition.equalsToLoggedUserField.field) }}
            </div>
          </ng-container>

          <mat-progress-spinner *ngIf="calculatingValues === true" mode="indeterminate" diameter="35" class="kenjo-ml-60px kenjo-mr-20px kenjo-mt-10px"> </mat-progress-spinner>
        </div>

        <!-- SPECIFIC AND CONDITION ENDS HERE -->
        <div *ngIf="iBlockAndConditions.blockAndConditions.length > 0 && isLastAnd === false" class="kenjo-inline-block kenjo-font-size-12px">
          {{ i18n.and }}
        </div>
      </ng-container>
      <!-- BLOCK OF AND CONDITIONS ENDS HERE -->
    </div>

    <div *ngIf="customPermission.length > 0 && isLastOr === false" class="kenjo-pv-5px kenjo-font-size-12px">
      {{ i18n.or }}
    </div>
  </ng-container>
</div>
