import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@app/standard/components/confirm-dialog/confirm-dialog.component';
import {
  IPayrollEmployeeData,
  IPayrollVariablePaymentData,
  PayrollCommentService,
} from '@app/standard/services/payroll/payroll-comment.service';
import check from 'check-types';
import * as moment from 'moment';

import { EditCommentDialogComponent } from '../edit-comment-dialog/edit-comment-dialog.component';

@Component({
  selector: 'kenjo-payroll-comment-cell',
  templateUrl: 'payroll-comment-cell.component.html',
  styleUrls: ['payroll-comment-cell.component.scss'],
})
export class PayrollCommentCellComponent implements OnInit {
  _comment: string | undefined;
  get comment() {
    return this._comment;
  }
  @Input() set comment(value: string | undefined) {
    this._comment = value;
    this.initPropertiesByCommentType();
    this.loading = false;
  }

  @Input() employeeData?: IPayrollEmployeeData;
  @Input() variablePaymentData?: IPayrollVariablePaymentData;
  @Input() timeOffData?: any;
  @Input() i18n: { [key: string]: string };
  @Input() periodFrom: moment.Moment;
  @Input() payrollConfirmed: boolean;
  @Input() settingsId: string;

  @Output() commentUpdated = new EventEmitter<number>();

  loading = false;
  commentId?: string;
  createComment: Function;

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.initPropertiesByCommentType();
  }

  private initPropertiesByCommentType() {
    if (check.assigned(this.employeeData)) {
      this.commentId = this.employeeData.data?.['payroll-comment.commentId']?.value;
      this.createComment = this.createEmployeeComment.bind(this);
    }
    if (check.assigned(this.variablePaymentData)) {
      this.commentId = this.variablePaymentData.commentId;
      this.createComment = this.createVariableComment.bind(this);
    }
    if (check.assigned(this.timeOffData)) {
      this.commentId = this.timeOffData.commentId;
      this.createComment = this.createTimeOffComment.bind(this);
    }
  }

  async openEditionModal() {
    const data = {
      comment: this.comment,
      commentLabel: this.i18n.commentLabel,
      titleText: this.i18n.addComment,
      cancelButtonText: this.i18n.goBackButton,
      confirmButtonText: this.i18n.saveButton,
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(EditCommentDialogComponent, { data });

    const newComment = await dialogRef.afterClosed().toPromise();

    if (newComment === false) {
      return;
    }

    if (this.comment !== undefined) {
      await this.editComment(newComment);
    } else {
      await this.createComment(newComment);
    }
  }

  private async editComment(newComment: string) {
    if (check.nonEmptyString(this.commentId)) {
      await this.injector.get(PayrollCommentService).updateComment(this.commentId, newComment);
      this.triggerCommentUpdated();
    }
  }

  private async createEmployeeComment(newComment: string) {
    await this.injector.get(PayrollCommentService).createEmployeeComment(this.periodFrom, this.employeeData, newComment, this.settingsId);
    this.triggerCommentUpdated();
  }

  private async createVariableComment(newComment: string) {
    await this.injector
      .get(PayrollCommentService)
      .createVariableComment(this.periodFrom, this.variablePaymentData, newComment, this.settingsId);
    this.triggerCommentUpdated();
  }

  private async createTimeOffComment(newComment: string) {
    await this.injector.get(PayrollCommentService).createTimeOffComment(this.periodFrom, this.timeOffData, newComment, this.settingsId);
    this.triggerCommentUpdated();
  }

  async deleteComment() {
    const data = {
      titleText: this.i18n.deleteCommentDialogHeader,
      subtitleText: this.i18n.deleteCommentDialogSubtitle,
      cancelButtonText: this.i18n.goBackDialogButton,
      confirmButtonText: this.i18n.deleteDialogButton,
      confirmButtonColor: 'Danger',
    };
    const dialogRef = this.injector.get(MatLegacyDialog).open(ConfirmDialogComponent, { data });
    const deletionConfirmed = await dialogRef.afterClosed().toPromise();

    if (deletionConfirmed && check.nonEmptyString(this.commentId)) {
      await this.injector.get(PayrollCommentService).deleteComment(this.commentId);
      this.triggerCommentUpdated();
    }
  }

  private triggerCommentUpdated() {
    this.loading = true;
    this.commentUpdated.emit();
  }
}
