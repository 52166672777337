<mat-expansion-panel hideToggle class="kenjo-expansion-panel kenjo-mb-40px" (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="panelOpenState">
  <mat-expansion-panel-header [id]="'mat-expansion-panel-'+id">
    <mat-panel-title class="kenjo-text-transform-uppercase">
      <kenjo-icon [size]="20" [class.kenjo-r180]="panelOpenState" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer kenjo-mr-10px kenjo-expansion-panel-pointer">keyboard_arrow_up</kenjo-icon>
      {{ title }}
    </mat-panel-title>
    <mat-panel-description *ngIf="summary">
      {{ summary }}
    </mat-panel-description>
    <kenjo-icon *ngIf="icon" [size]="20" [id]="'kenjo-icon-add-'+id" [matTooltip]="iconTooltip" (click)="onClick($event)" class="kenjo-font-color-primary-5993e3 kenjo-cursor-pointer"> {{ icon }} </kenjo-icon>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
