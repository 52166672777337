import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService } from '../generic.service';

@Injectable()
export class PreferenceService {
  private PREFERENCE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-preference-db`;
  private PREFERENCE_KEY: string = 'user-preference-db';
  private PREFERENCE_INTERNATIONALIZATION: string = 'user-preference-collection';

  constructor(private http: HttpClient, private genericService: GenericService, private authenticationService: AuthenticationService, private errorManager: ErrorManagerService) {}

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.PREFERENCE_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.PREFERENCE_INTERNATIONALIZATION);
  }

  getPreferenceByKey(preferenceKey: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        return undefined;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .get(`${this.PREFERENCE_URL}/${preferenceKey}`, httpOptions)
        .toPromise()
        .then((preference) => {
          resolve(preference);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PreferenceService.name, 'getPreferenceByKey'));
        });
    });
  }

  setPreferenceByKey(preferenceKey: string, preference: any, type?: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.authenticationService.isUserAuthenticated() === false) {
        return undefined;
      }

      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.authenticationService.getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const body: IPreferenceModel = {
        preference: preference
      };

      if (check.assigned(type)) {
        body.preferenceType = type;
      }
      this.http
        .put(`${this.PREFERENCE_URL}/${preferenceKey}`, body, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, PreferenceService.name, 'setPreferenceByKey'));
        });
    });
  }
}

export interface IPreferenceModel {
  _id?: string;
  preferenceType?: string;
  preferenceKey?: string;
  preference: any;
}
