<ng-container *ngIf="!isLoading">
  <div *ngIf="view === 'month'">
    <kenjo-calendar-month [year]="year" [month]="month"></kenjo-calendar-month>
  </div>

  <div *ngIf="view !== 'month'" class="kcy-year-container">
    <div class="kcy-year-header">
      <div class="--arrow-container kenjo-text-align-right" [ngClass]="{ '--empty': year <= minYear }">
        <kenjo-icon *ngIf="year > minYear" (click)="previousYear();" [size]="25" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3">keyboard_arrow_left</kenjo-icon>
      </div>
      <div class="--year-number kenjo-font-size-18px">{{ year }}</div>
      <div class="right-arrow-container">
        <div class="--arrow-container kenjo-text-align-left" [ngClass]="{ '--empty': year >= maxYear }">
          <kenjo-icon *ngIf="year < maxYear" (click)="nextYear();" [size]="25" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3">keyboard_arrow_right</kenjo-icon>
        </div>
        <div *ngIf="showLearnMore" class="--showmore-container">
          <orgos-action (click)="openLearnMoreDialog()">{{ componentTranslations.learnMoreButton }}</orgos-action>
        </div>
      </div>
    </div>
    <div class="kcy-months-container">
      <kenjo-calendar-month *ngFor="let iMonth of fullCalendar" (clickOnDate)="clickOnDate.emit($event)"
        [mapKeyToCalendarEvent]="mapKeyToCalendarEvent"
        [year]="year" [month]="iMonth"
        class="kcy-month"></kenjo-calendar-month>
    </div>
  </div>
</ng-container>