import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { DocumentService } from '@app/standard/services/document/document.service';
import { SIGNATURE_STATUS, SignatureRequestService } from '@app/standard/services/signature-request/signature-request.service';

import { StandardServicesRegistry } from '../../../services/standard-services.registry';

@Component({
  selector: 'orgos-void-document-dialog',
  templateUrl: 'void-document.dialog.html',
  styleUrls: ['void-document.dialog.scss'],
})
export class VoidDocumentDialog implements OnInit {
  dialogTranslation: any = {};
  miscTranslation: any = {};
  headerText: string;
  subtitleText: string;
  showWarning: boolean;
  warningText: string;
  voidDocumentButtonText: string;
  voidingInProcess: boolean = false;
  constructor(
    private injector: Injector,
    private dialogRef: MatLegacyDialogRef<VoidDocumentDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private snackBar: MatLegacySnackBar,
    private standardServicesRegistry: StandardServicesRegistry
  ) {}

  ngOnInit(): void {
    const internationalizationServiceClass = this.standardServicesRegistry.getService('Internationalization');
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('documents-void-document-dialog')
      .then((dialogTranslation) => {
        this.dialogTranslation = dialogTranslation;
        const { documentsFromSameRequest, documents } = this.data;

        const {
          dialogHeader,
          dialogHeader_singular,
          dialogSubtitle,
          dialogSubtitle_singular,
          warningSubtitle,
          warningSubtitle_singular,
          voidDocumentButton,
          voidDocumentButton_singular,
        } = this.dialogTranslation;
        const [headerTemplate, subtitleText] =
          documents.length > 1 ? [dialogHeader, dialogSubtitle] : [dialogHeader_singular, dialogSubtitle_singular];

        this.headerText = headerTemplate;
        this.subtitleText = subtitleText;

        this.showWarning = this.data.documentsFromSameRequest > 0;
        if (this.showWarning) {
          const warningSubtitleTemplate = this.data.documentsFromSameRequest > 1 ? warningSubtitle : warningSubtitle_singular;
          this.warningText = this.injector
            .get(I18nDataPipe)
            .transform(warningSubtitleTemplate, { documentsFromSameRequest, userDisplayName: documents[0].name });
        }

        this.voidDocumentButtonText = this.showWarning || documents.length > 1 ? voidDocumentButton : voidDocumentButton_singular;
      })
      .catch(() => {
        this.dialogTranslation = {};
      });
    this.injector
      .get(internationalizationServiceClass)
      .getAllTranslation('misc')
      .then((miscTranslation) => {
        this.miscTranslation = miscTranslation;
      })
      .catch(() => {
        this.miscTranslation = {};
      });
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  async sendReminder(): Promise<void> {
    try {
      this.voidingInProcess = true;
      const requests = this.data.documents.filter((iDoc) => iDoc.status !== SIGNATURE_STATUS.IMPORT_ERROR);
      if (requests?.length && requests.length > 0) {
        await this.injector.get(SignatureRequestService).voidDocuments(requests);
      }
      const editImportErrors = this.data.documents
        .filter((iDoc) => iDoc.status === SIGNATURE_STATUS.IMPORT_ERROR)
        .map((iDoc) => {
          this.injector.get(DocumentService).updateById(iDoc.document._id, { sentToSignature: false });
        });
      await Promise.all(editImportErrors);
      const { documentVoidedSuccessful, documentVoidedSuccessful_singular } = this.dialogTranslation;
      const message = this.data.documents.length > 1 ? documentVoidedSuccessful : documentVoidedSuccessful_singular;
      this.snackBar.open(message, 'OK', {
        duration: 5000,
      });
      this.dialogRef.close(true);
    } catch {
      this.dialogRef.close(false);
    } finally {
      this.voidingInProcess = false;
    }
  }
}
