import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { IShiftPlanDate } from '@app/cloud-features/shift-plan/services/shift-plan-card.service';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { InputValidation } from '@app/standard/core/validation/input-validation';
import * as check from 'check-types';
import * as moment from 'moment';

@Component({
  selector: 'kenjo-shiftplan-weekly-picker',
  templateUrl: 'shiftplan-weekly-picker.component.html',
  styleUrls: ['shiftplan-weekly-picker.component.scss'],
})
export class ShiftplanWeeklyPickerComponent implements OnInit {
  startAt: moment.Moment;
  startDate: string;
  endDate: string;
  currentLanguage: string;
  _weekRange: IShiftPlanDate | null = { from: null, to: null };
  currentWeek: IShiftPlanDate | null = { from: null, to: null };
  WEEK_VALUES = { BEFORE: -1, CURRENT: 0, AFTER: 1 };
  isValueValid: boolean = false;
  rangeWeekly = new UntypedFormGroup({
    fromWeek: new UntypedFormControl(),
    toWeek: new UntypedFormControl(),
  });
  limitDates: { min: moment.Moment; max: moment.Moment } = { min: moment.utc().subtract(20, 'years'), max: moment.utc().add(20, 'years') };
  selfClose: () => void;

  @Input() states: { loading: boolean; dropping: boolean; dragging: boolean };
  @Input() blockPicker: boolean = false;
  @Input() parentTranslation: { [key: string]: string } = {};
  @Input()
  set weekRange(newWeek: IShiftPlanDate) {
    this._weekRange = { ...newWeek };
    this.setThisWeekRange(newWeek);
  }

  get weekRange() {
    return this._weekRange;
  }

  @Output() valueChange: EventEmitter<IShiftPlanDate | number> = new EventEmitter<IShiftPlanDate | number>();
  @Output() validation: EventEmitter<InputValidation> = new EventEmitter<InputValidation>();

  datepicker: MatDatepicker<Date>;
  @ViewChild('datepicker', { static: false }) set datePickerContent(content: MatDatepicker<Date>) {
    if (content) {
      this.datepicker = content;
      this.injector.get(ChangeDetectorRef).detectChanges();
      this.selfClose = this.datepicker.close;
    }
  }
  @ViewChild('datepickerFooter', { static: false }) datepickerFooter: ElementRef;

  inputValidation: InputValidation = new InputValidation();

  constructor(public injector: Injector) {}

  ngOnInit(): void {
    this.currentLanguage = this.injector.get(PrivateAuthenticationService).getLoggedUser().language;
    this.setThisWeekRange(this.weekRange);
  }

  onDateChange(event) {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.classList.add('isdpc-mat-calendar');

    const selectedDate = this.rangeWeekly.get('fromWeek').value;
    if (check.not.assigned(selectedDate)) {
      return;
    }

    const startDate = moment.utc(selectedDate).startOf('week');
    const endDate = moment.utc(selectedDate).endOf('week');
    const currentValue = { from: startDate, to: endDate };

    if (!this.validateRangeValues(startDate, endDate, 'current') || !this.validateRangeValues(startDate, endDate, 'range')) {
      return;
    }

    this._weekRange = { ...currentValue };
    this.makeValidation(currentValue);

    this.rangeWeekly.get('fromWeek').setValue(startDate);
    this.rangeWeekly.get('toWeek').setValue(endDate);
  }

  private validateRangeValues(startDate, endDate, validation: string): boolean {
    const weekToValidate = validation === 'current' ? { ...this.currentWeek } : { ...this.weekRange };
    if (moment(startDate).isSame(weekToValidate.from, 'day') && moment(endDate).isSame(weekToValidate.to, 'day')) {
      this.clearValue();
      this.isValueValid = false;
      return false;
    }
    return true;
  }

  private makeValidation(currentValue): void {
    const inputValidation = this.validateValue(currentValue).freeze();
    this.isValueValid = inputValidation.isValid();
    this.validation.emit(inputValidation);
  }

  protected validateValue(currentValue: any): InputValidation {
    this.inputValidation = new InputValidation();

    if (
      check.not.assigned(currentValue?.from) ||
      check.not.assigned(currentValue?.to) ||
      check.emptyString(currentValue?.from) ||
      check.emptyString(currentValue?.to)
    ) {
      this.inputValidation.setError('required');
    }

    if (check.assigned(currentValue.from) && !moment.utc(currentValue.from).isValid()) {
      this.inputValidation.setError('date');
    }

    if (check.assigned(currentValue.to) && !moment.utc(currentValue.to).isValid()) {
      this.inputValidation.setError('date');
    }
    return this.inputValidation;
  }

  openDatepicker(): void {
    if (this.states.loading) {
      return;
    }

    this.datepicker.open();
    this.datepicker?.['_componentRef']?.instance?.['_dateAdapter'].setLocale(moment.locale());
    // override default close method to avoid closing after selection
    this.datepicker.close = () => {};
    this.appendFooter();
  }

  clearValue(): void {
    this.rangeWeekly.get('fromWeek').setValue(null);
    this.rangeWeekly.get('toWeek').setValue(null);
    this.weekRange = Object.assign({}, { from: null, to: null });
  }

  onClose(): void {
    if (!this.isValueValid) {
      return;
    }
    this.valueChange.emit(this.weekRange);
    this.isValueValid = false;
    this.closeDialog();
  }

  private closeDialog() {
    this.datepicker.close = this.selfClose;
    this.datepicker.close();
    this.clearValue();
  }

  private appendFooter() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }

  closeFromListener() {
    this.isValueValid = false;
    this.closeDialog();
  }

  setThisWeekRange(newWeek) {
    if (check.not.assigned(newWeek?.from) || check.not.assigned(newWeek?.to) || check.not.assigned(this.currentLanguage)) {
      return;
    }
    this.currentWeek = { ...newWeek };
    this.startAt = this.currentWeek.from;
    this.startDate = moment(newWeek?.from.toISOString()).locale(this.currentLanguage).format('D MMM').replace(/\./g, '');
    this.endDate = moment(newWeek?.to.startOf('day').toISOString()).locale(this.currentLanguage).format('D MMM, YYYY').replace(/\./g, '');
  }

  handleWeekRangeSelection(event) {
    if (check.not.assigned(this.weekRange.from)) {
      return;
    }
    const day = moment(this.weekRange.from).format('DD');
    if (
      Number(event?.target?.outerText) === Number(day) &&
      !event?.target?.parentNode?.classList.contains('mat-calendar-body-range-start')
    ) {
      event.preventDefault();
      this.clearValue();
      this.isValueValid = false;
    }
  }

  @HostListener('document:click', ['$event'])
  checkBackdrop(event) {
    if (event?.target?.parentNode?.className === 'cdk-overlay-container') {
      this.closeFromListener();
    }
    this.handleWeekRangeSelection(event);
  }

  @HostListener('document:keydown.escape', ['$event'])
  checkEscapeKey(event: KeyboardEvent) {
    this.closeFromListener();
  }
}
