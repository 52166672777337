<ng-container *ngIf="!filterFields?.length">
  <orgos-container aspect="card" *ngIf="translationsLoaded" class="fb-card kenjo-mb-20px" [class.fb-card-hidden]="!shown">
    <orgos-column-container [centerColumns]="true" class="kenjo-border-bottom">
      <orgos-column
        size="1"
        [ngClass]="{
          'fb-carousel-contracted': applyFiltersImmediately && isSearchBarExpanded,
          'fb-carousel-search': applyFiltersImmediately && !isSearchBarExpanded,
          'fb-carousel-apply': !applyFiltersImmediately && !noFilters,
          'fb-carousel': !applyFiltersImmediately && noFilters
        }"
      >
        <div class="fb-row">
          <kenjo-icon
            *ngIf="optionsListWidth < optionsListItemsWidth && showOptionsLeftArrow"
            (click)="scrollOptions(-1)"
            class="fb-arrow fb-arrow-left options-arrow-left kenjo-mh-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_left</kenjo-icon
          >
          <div #optionsList class="fb-tags-container">
            <kenjo-filter-bar-field
              *ngFor="let key of filterOptionsKeys"
              [options]="filterOptions[key]"
              [label]="getFilterLabel(key)"
              [showAvatar]="key === 'managers' || key === 'employees'"
              [hasSearch]="key === 'employees'"
              (toggleFilterEvent)="toggleFilter($event)"
              (clearFilterEvent)="clearFilter($event)"
              (applyFilterEvent)="applyFilter($event)"
              [buttonTranslations]="{ apply: componentTranslations.applyFilterButton, reset: componentTranslations.clearFilters }"
              [showApplyFilterButton]="applyEachFilter"
              [filterDifference]="filterDifference"
              [filtersApplied]="filtersApplied"
              [optionKey]="key"
            ></kenjo-filter-bar-field>
          </div>
          <kenjo-icon
            *ngIf="optionsListWidth < optionsListItemsWidth && showOptionsRightArrow"
            (click)="scrollOptions(1)"
            class="fb-arrow fb-arrow-right options-arrow-right kenjo-mh-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_right</kenjo-icon
          >
        </div>
      </orgos-column>
      <orgos-column *ngIf="!applyFiltersImmediately && !noFilters && !applyEachFilter" size="0" class="kenjo-border-right">
        <div class="fb-row kenjo-ph-20px">
          <orgos-action [disabled]="disabledApplyFilters || !filtersDifference" (click)="applyFilters()">{{
            componentTranslations.applyFilters
          }}</orgos-action>
        </div>
      </orgos-column>
      <orgos-column *ngIf="applyFiltersImmediately && !showButtonToApplyAllFilters" size="0">
        <orgos-column-container centerColumns="true" class="fb-row kenjo-border-left" [class.fb-right-column-width]="isSearchBarExpanded">
          <kenjo-icon
            orgosColumn="0"
            (click)="expandSearcher()"
            [class.kenjo-cursor-pointer]="!isSearchBarExpanded"
            class="kenjo-mh-20px kenjo-font-color-primary-5993e3"
            >search</kenjo-icon
          >
          <orgos-column>
            <input
              *ngIf="isSearchBarExpanded"
              #searchInput
              type="text"
              [placeholder]="componentTranslations.searchLabel"
              (keyup)="emitSearchTermChanged(searchInput.value)"
              [(ngModel)]="searchTerm"
              class="fb-search-field kenjo-ml-10px"
            />
          </orgos-column>
          <kenjo-icon
            orgosColumn="0"
            *ngIf="isSearchBarExpanded"
            [size]="21"
            (click)="isSearchBarExpanded = false; resetOptionsScroll(); emitSearchTermChanged('')"
            class="kenjo-mh-20px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >clear</kenjo-icon
          >
        </orgos-column-container>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container [centerColumns]="true" class="kenjo-color-grey-background-lighter-f8f8f8">
      <orgos-column size="1" class="fb-carousel-chips">
        <div *ngIf="!filtersAreEmpty" class="fb-row kenjo-color-grey-background-lighter-f8f8f8 kenjo-ph-10px">
          <kenjo-icon
            *ngIf="filtersListWidth < filtersListItemsWidth && showLeftArrow"
            (click)="scrollFilters(-1)"
            class="fb-arrow fb-arrow-left filters-arrow-left kenjo-mr-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_left</kenjo-icon
          >
          <div #filtersList class="fb-tags-container">
            <kenjo-filter-bar-tag
              *ngFor="let filter of activeOptions | keyvalue"
              [label]="getFilterLabel(filter.key)"
              [activeValues]="filter.value"
              [optionKey]="filter.key"
              class="fb-chip"
            ></kenjo-filter-bar-tag>
          </div>
          <kenjo-icon
            *ngIf="filtersListWidth < filtersListItemsWidth && showRightArrow"
            (click)="scrollFilters(1)"
            class="fb-arrow fb-arrow-right filters-arrow-right kenjo-ml-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_right</kenjo-icon
          >
        </div>
        <div *ngIf="filtersAreEmpty" class="kenjo-ml-20px kenjo-font-color-light-text-757575">
          {{ disabledApplyFilters ? componentTranslations.noFilters : componentTranslations.noFiltersYet }}
        </div>
      </orgos-column>
      <orgos-column size="0">
        <div class="fb-row kenjo-color-white-background-ffffff kenjo-border-left">
          <orgos-action
            [matTooltip]="componentTranslations.resetFilters | titlecase"
            matTooltipPosition="above"
            (click)="clearAllFilters()"
            [disabled]="filtersAreEmpty"
            class="fb-clean-filter-icon"
            icon="clear"
          ></orgos-action>
        </div>
      </orgos-column>
    </orgos-column-container>
  </orgos-container>
</ng-container>

<ng-container *ngIf="filterFields?.length && componentTranslations !== undefined">
  <orgos-container aspect="card" *ngIf="translationsLoaded" class="fb-card kenjo-mb-20px" [class.fb-card-hidden]="!shown">
    <orgos-column-container [centerColumns]="true" class="kenjo-border-bottom">
      <orgos-column size="1" [class]="isSearchBarExpanded ? 'fb-carousel-contracted' : 'fb-carousel-search'">
        <div class="fb-row">
          <kenjo-icon
            *ngIf="optionsListWidth < optionsListItemsWidth && showOptionsLeftArrow"
            (click)="scrollOptions(-1)"
            class="fb-arrow fb-arrow-left options-arrow-left kenjo-mh-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_left</kenjo-icon
          >
          <div #optionsList class="fb-tags-container">
            <kenjo-filter-bar-field
              *ngFor="let filterField of filterFields"
              [options]="filterField.options"
              [label]="filterField.label"
              [showAvatar]="filterField.showAvatar"
              [pickerType]="filterField.pickerType"
              (toggleFilterEvent)="toggleFilter($event)"
              (clearFilterEvent)="clearFilter($event)"
              (applyFilterEvent)="applyFilter($event)"
              [optionKey]="filterField.key"
              [noMatchingEmployeesLabel]="componentTranslations.noMatchingEmployeesLabel"
            ></kenjo-filter-bar-field>
          </div>
          <kenjo-icon
            *ngIf="optionsListWidth < optionsListItemsWidth && showOptionsRightArrow"
            (click)="scrollOptions(1)"
            class="fb-arrow fb-arrow-right options-arrow-right kenjo-mh-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_right</kenjo-icon
          >
        </div>
      </orgos-column>
      <orgos-column size="0" *ngIf="includeEmployeeSearch">
        <div class="fb-row kenjo-border-left" [class.fb-right-column-width]="isSearchBarExpanded">
          <kenjo-icon (click)="isSearchBarExpanded = true" class="kenjo-mh-20px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >search</kenjo-icon
          >
          <input
            *ngIf="isSearchBarExpanded"
            #searchInput
            type="text"
            [placeholder]="componentTranslations.searchLabel"
            (keyup)="emitSearchTermChanged(searchInput.value)"
            [(ngModel)]="searchTerm"
            class="fb-search-field kenjo-ml-10px"
          />
          <kenjo-icon
            *ngIf="isSearchBarExpanded"
            (click)="isSearchBarExpanded = false; emitSearchTermChanged('')"
            class="kenjo-mh-20px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >clear</kenjo-icon
          >
        </div>
      </orgos-column>
    </orgos-column-container>
    <orgos-column-container [centerColumns]="true" class="kenjo-color-grey-background-lighter-f8f8f8">
      <orgos-column size="1" class="fb-carousel-chips-reset">
        <div *ngIf="!filtersAreEmpty" class="fb-row kenjo-color-grey-background-lighter-f8f8f8 kenjo-pl-20px">
          <kenjo-icon
            *ngIf="filtersListWidth < filtersListItemsWidth && showLeftArrow"
            (click)="scrollFilters(-1)"
            class="fb-arrow fb-arrow-left filters-arrow-left kenjo-mr-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_left</kenjo-icon
          >
          <div #filtersList class="fb-tags-container">
            <kenjo-filter-bar-tag
              *ngFor="let filter of activeOptions | keyvalue"
              [label]="optionKeyToLabel[filter.key]"
              [activeValues]="filter.value"
              [optionKey]="filter.key"
              class="fb-chip"
            ></kenjo-filter-bar-tag>
          </div>
          <kenjo-icon
            *ngIf="filtersListWidth < filtersListItemsWidth && showRightArrow"
            (click)="scrollFilters(1)"
            class="fb-arrow fb-arrow-right filters-arrow-right kenjo-ml-10px kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >keyboard_arrow_right</kenjo-icon
          >
        </div>
        <div *ngIf="filtersAreEmpty" class="kenjo-ml-20px kenjo-font-color-light-text-757575">{{ componentTranslations.noFiltersYet }}</div>
      </orgos-column>
      <orgos-column size="0">
        <div class="fb-row kenjo-color-white-background-ffffff kenjo-border-left">
          <orgos-action
            [matTooltip]="componentTranslations.resetFilters | titlecase"
            [matTooltipPosition]="resetTooltipPosition"
            (click)="clearAllFilters()"
            [disabled]="filtersAreEmpty"
            class="fb-clean-filter-icon"
            icon="clear"
            ></orgos-action
          >
        </div>
      </orgos-column>
    </orgos-column-container>
  </orgos-container>
</ng-container>
