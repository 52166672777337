<mat-form-field *ngIf="!readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput #input="matInput" [required]="required" [matAutocomplete]="auto" [formControl]="formController" />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput #input="matInput" [ngModel]="value" readOnly disabled [required]="required" />
</mat-form-field>
<div *ngIf="!isValueValid" class="ista-error"><ng-content select="orgos-input-error"></ng-content></div>
