import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { GenericService, IGenericService } from '@app/standard/services/generic.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class SmartDocsSettingsService implements IGenericService {
  private SMART_DOCS_SETTINGS_MICROSERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/smart-docs-settings-db`;
  private SMART_DOCS_SETTINGS_INTERNATIONALIZATION: string = 'smart-docs-settings-collection';
  private SMART_DOCS_SETTINGS_PERMISSIONS_KEY: string = 'smart-docs-settings';

  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: any, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getById(id: string, operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  updateById(id: string, data: any, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }
  deleteById(id: string, operationOptions?: any): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getPermissions(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getFieldsTranslations(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getData?(operationOptions?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async find(): Promise<ISmartDocsSettingsModel> {
    try {
      const smartDocsSettings = await this.injector
        .get(HttpClient)
        .post(`${this.SMART_DOCS_SETTINGS_MICROSERVICE_URL}/find`, { _id: { $ne: null } })
        .toPromise();
      return smartDocsSettings[0];
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, SmartDocsSettingsService.name, 'find');
    }
  }

  async updateMandatoryTags(id: string, areTagsMandatory: boolean) {
    try {
      await this.injector.get(HttpClient).put(`${this.SMART_DOCS_SETTINGS_MICROSERVICE_URL}/update-mandatory-tags/${id}`, { areTagsMandatory }).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, SmartDocsSettingsService.name, 'updateMandatoryTags');
    }
  }
}

export interface ISmartDocsSettingsModel {
  _id: string;
  areTagsMandatory: boolean;
}
