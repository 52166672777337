import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as check from 'check-types';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';
import { UserAccountService } from './user-account.service';

@Injectable()
export class UserWorkScheduleService implements IGenericService {
  private USER_WORK_SCHEDULE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-work-schedule-db`;
  private USER_WORK_SCHEDULE_PERMISSIONS_KEY: string = 'user-work-schedule';
  private USER_WORK_SCHEDULE_INTERNATIONALIZATION: string = 'user-work-schedule-collection';

  constructor(private injector: Injector, private genericService: GenericService, private errorManager: ErrorManagerService, private http: HttpClient) {}

  create(data: object): Promise<IUserWorkScheduleModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserWorkScheduleService.name, 'create');
    error.message = 'UserWorkSchedule should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserWorkScheduleModel> {
    return new Promise<IUserWorkScheduleModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_WORK_SCHEDULE_URL, id)
        .then((userWorkSchedule: IUserWorkScheduleModel) => {
          resolve(userWorkSchedule);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_WORK_SCHEDULE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserWorkScheduleService.name, 'deleteById');
    error.message = 'UserWorkSchedule should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_WORK_SCHEDULE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_WORK_SCHEDULE_INTERNATIONALIZATION);
  }

  getAllUserWorkSchedule(activeUsersOnly: boolean): Promise<Array<IUserWorkScheduleModel>> {
    if (check.assigned(activeUsersOnly) && check.boolean(activeUsersOnly) && check.equal(true, activeUsersOnly)) {
      // Find the active users only
      return new Promise<Array<IUserWorkScheduleModel>>((resolve, reject) => {
        this.injector
          .get(UserAccountService)
          .getAllUserAccount(true)
          .then((allActiveUsers: Array<any>) => {
            const arrayActiveUserIds = allActiveUsers.map((iActiveUser) => {
              return iActiveUser._id;
            });
            const findBody = {
              _id: {
                $in: arrayActiveUserIds
              }
            };
            return this.genericService.find(this.USER_WORK_SCHEDULE_URL, findBody);
          })
          .then((allUserWorkSchedule: Array<IUserWorkScheduleModel>) => {
            resolve(allUserWorkSchedule);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'getAllUserWorkSchedule'));
          });
      });
    } else {
      // Find all user attendance (active and inactive)
      const findBody = {
        _id: { $ne: null }
      };
      return new Promise<Array<IUserWorkScheduleModel>>((resolve, reject) => {
        this.genericService
          .find(this.USER_WORK_SCHEDULE_URL, findBody)
          .then((allUserWorkSchedule: Array<IUserWorkScheduleModel>) => {
            resolve(allUserWorkSchedule);
          })
          .catch((error) => {
            reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'getAllUserWorkSchedule'));
          });
      });
    }
  }

  getWorkScheduleByTemplateId(templateId: string): Promise<Array<IUserWorkScheduleModel>> {
    return new Promise<Array<IUserWorkScheduleModel>>((resolve, reject) => {
      const query = {
        'history._id': templateId
      };
      this.genericService
        .find(this.USER_WORK_SCHEDULE_URL, query)
        .then((userWorkSchedules: Array<IUserWorkScheduleModel>) => {
          resolve(userWorkSchedules);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'getById'));
        });
    });
  }

  createScheduleEntries(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, UserWorkScheduleService.name, 'createScheduleEntries');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.USER_WORK_SCHEDULE_URL}/create-entries`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'createScheduleEntries'));
        });
    });
  }

  updateScheduleEntries(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, UserWorkScheduleService.name, 'updateScheduleEntries');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.USER_WORK_SCHEDULE_URL}/update-entries`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'updateScheduleEntries'));
        });
    });
  }

  deleteScheduleEntries(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, UserWorkScheduleService.name, 'deleteScheduleEntries');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.USER_WORK_SCHEDULE_URL}/delete-entries`, data, httpOptions)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'deleteScheduleEntries'));
        });
    });
  }

  getScheduleEntriesByDate(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.injector.get(AuthenticationService).isUserAuthenticated() === false) {
        const error = new OrgosError('PROGRAMMING ERROR', ErrorCodes.UNAUTHORIZED, UserWorkScheduleService.name, 'getScheduleEntriesByDate');
        reject(error);
        return;
      }
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      this.http
        .post(`${this.USER_WORK_SCHEDULE_URL}/schedule-entries`, data, httpOptions)
        .toPromise()
        .then((scheduleEntriesByDate) => {
          resolve(scheduleEntriesByDate);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'getScheduleEntriesByDate'));
        });
    });
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_WORK_SCHEDULE_URL);
  }

  async setAttendanceTracking(body: { _id: string; trackAttendance: boolean }) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.http.post(`${this.USER_WORK_SCHEDULE_URL}/update-tracking`, body, httpOptions).toPromise();
    } catch (error) {
      return this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'getScheduleEntriesByDate');
    }
  }

  async updateAttendanceTrackingInBulk(employees: string[], attendanceTrackingEnable: boolean) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.http.post(`${this.USER_WORK_SCHEDULE_URL}/update-attendance-tracking-in-bulk`, { employees, attendanceTrackingEnable }, httpOptions).toPromise();
    } catch (error) {
      return this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'updateAttendanceTrackingInBulk');
    }
  }

  find(findBody: any): Promise<Array<IUserWorkScheduleModel>> {
    return new Promise<Array<IUserWorkScheduleModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_WORK_SCHEDULE_URL, findBody)
        .then((userWorkSchedules: Array<IUserWorkScheduleModel>) => {
          resolve(userWorkSchedules);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'find'));
        });
    });
  }

  async getWorkingHoursPerWeekForUserId(userId: string): Promise<number> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const userIdToWorkingHours: {[userId: string]: number} = await this.http.post<{[userId: string]: number}>(`${this.USER_WORK_SCHEDULE_URL}/weekly-hours`, { userIds: [userId] }, httpOptions).toPromise();
      return userIdToWorkingHours[userId] ? userIdToWorkingHours[userId] : 0;
    } catch (error) {
      this.errorManager.handleRawError(error, UserWorkScheduleService.name, 'getWorkingHoursPerWeekForUserId');
    }
  }
}

export interface IUserWorkScheduleModel {
  _id: string;
  trackAttendance?: boolean;
  mondayWorkingDay?: boolean;
  tuesdayWorkingDay?: boolean;
  wednesdayWorkingDay?: boolean;
  thursdayWorkingDay?: boolean;
  fridayWorkingDay?: boolean;
  saturdayWorkingDay?: boolean;
  sundayWorkingDay?: boolean;
  history?: Array<any>;
  startDate?: Date;
  overtimeSettings?: any;
}
