import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { InputSearchShiftPlanComponent } from '@app/cloud-features/shift-plan/components/input-search-shift-plan/input-search-shift-plan.component';
import { ShiftPlanTagSettingsService, ShiftplanTagCategory } from '@app/cloud-features/shift-plan/services/settings-shift-plan-tag.service';
import { GenericSimpleModel } from '@app/standard/core/generic-simple-model';
import { ISelectOption } from '@app/standard/core/select-option';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import { SHIFT_PLAN_ACTIVATION_STATUS_FINISHED } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-shift-plan-employee-tags',
  templateUrl: 'shift-plan-employee-tags.component.html',
  styleUrls: ['shift-plan-employee-tags.component.scss'],
})
export class ShiftPlanEmployeeTags implements OnInit {
  @Input() activateShiftPlanTranslation: { [key: string]: string };
  @Input() userWork: GenericSimpleModel;
  @Input() progressIndex: number;
  @Output() changeIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() activation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(InputSearchShiftPlanComponent) searchComponent: InputSearchShiftPlanComponent;

  allTags: Array<ITagEmployeeModel>;
  selectedTags: Array<string> = [];
  filteredTags: Array<ITagEmployeeModel> = [];
  dataByCategory: { [key: string]: Array<ITagEmployeeModel> } = {};
  categorySelectedData: Array<ITagEmployeeModel>;
  categorySelected: ShiftplanTagCategory;
  CATEGORY_DEFAULT_SKILLS: ShiftplanTagCategory = 'skills';
  categories: Array<ISelectOption> = [];
  dataHasChanged: boolean = false;
  formerTagsData: Array<string> = [];

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.fetchAllTags();
    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.formerTagsData = [...(this.userWork.data.tags ?? [])];
    }
  }

  private async fetchAllTags() {
    try {
      this.allTags = await this.injector.get(ShiftPlanTagSettingsService).getTags();
      this.buildCategories();
      this.registerTagsData();
    } catch {
      // do nothing
    }
  }

  buildCategories() {
    this.categories = [
      {
        name: this.activateShiftPlanTranslation.skills,
        value: 'skills',
      },
      {
        name: this.activateShiftPlanTranslation.information,
        value: 'information',
      },
      {
        name: this.activateShiftPlanTranslation.other,
        value: 'other',
      },
    ];
  }

  registerTagsData() {
    this.selectedTags = this.userWork.data.tags ? this.userWork.data.tags.slice() : [];
    if (check.assigned(this.userWork.data.tags) && check.not.emptyArray(this.userWork.data.tags)) {
      this.allTags.forEach((iTag) => {
        if (this.userWork.data.tags.includes(iTag._id)) {
          iTag.selected = true;
        } else {
          iTag.selected = false;
        }
      });
    } else {
      this.allTags.forEach((iTag) => {
        iTag.selected = false;
      });
    }
    this.dataByCategory = _.groupBy(this.allTags, 'category');
    this.categorySelectedData = check.not.emptyArray(this.dataByCategory) ? this.dataByCategory[this.CATEGORY_DEFAULT_SKILLS] : [];
    this.filteredTags = this.categorySelectedData;
    this.categorySelected = this.categories[0].value;
  }

  changeSelectedTags(selected: boolean, item: any) {
    if (selected) {
      this.selectedTags.push(item._id);
    } else {
      this.selectedTags = this.selectedTags.filter((iTag) => {
        return iTag !== item._id;
      });
    }

    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataHasChanged = true;
    }
  }

  changeCategory(category: any): void {
    this.categorySelectedData = this.dataByCategory[category] ? this.dataByCategory[category] : [];
    this.filteredTags = this.categorySelectedData;
    this.categorySelected = category;
  }

  previousStep() {
    this.saveData();
    this.progressIndex--;
    this.changeIndex.emit(this.progressIndex);
  }

  activateShiftPlan() {
    this.saveData();
    this.activation.emit(true);
  }

  select(item) {
    item.selected = true;
    this.changeSelectedTags(true, item);
  }

  clear(item) {
    item.selected = false;
    this.changeSelectedTags(false, item);
  }

  async saveData() {
    this.userWork.data.tags = this.selectedTags.length ? this.selectedTags : null;
    await this.injector.get(UserWorkService).updateById(this.userWork.data._id, { tags: this.selectedTags });

    if (this.userWork.data.shiftPlanActivationStatus === SHIFT_PLAN_ACTIVATION_STATUS_FINISHED) {
      this.dataSaved.emit(true);
    }
  }

  cancelChanges() {
    this.userWork.data.tags = this.formerTagsData;
    this.registerTagsData();
    this.searchComponent.searchTerm = '';
  }

  saveSnackbar() {
    this.injector.get(MatLegacySnackBar).open(this.activateShiftPlanTranslation.changesSuccessful, 'OK', {
      duration: 5000,
    });
  }
}

export interface ITagEmployeeModel {
  _id?: string;
  name: string;
  selected?: boolean;
}
