<mat-form-field *ngIf="!readOnly" [class.mat-form-field-invalid]="forceError">
  <mat-label>{{ label }}</mat-label>
  <kenjo-shift-plan-input-capacity-time-form-control [maskPlaceholder]="maskPlaceholder" [value]="inputValue" (valueChange)="onValueChange($event)" (blurTimeInput)="onBlur()" [required]="required"></kenjo-shift-plan-input-capacity-time-form-control>
  <mat-icon *ngIf="showClearButton" matSuffix class="ictc-clear-button kenjo-cursor-pointer" (click)="clearValue(); $event.stopPropagation()">clear</mat-icon>
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput type="text" [ngModel]="inputStringValue" readOnly disabled [required]="required" />
</mat-form-field>
<div *ngIf="!isValueValid" class="ictc-error"><ng-content select="orgos-input-error"></ng-content></div>
