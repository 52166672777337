import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { PrivateAuthenticationService } from '@app/private/services/private-authentication.service';
import { PrivateChurnzeroService } from '@app/private/services/private-churnzero.service';
import { PrivateHubspotService } from '@app/private/services/private-hubspot.service';
import { PrivateIntegrationsService } from '@app/private/services/private-integrations.service';
import { PrivateSearchService } from '@app/private/services/private-search.service';
import { ErrorCodes } from '@app/standard/core/error/error-codes';
import { OrgosError } from '@app/standard/core/error/orgos-error';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { IGenericService } from '@app/standard/services/generic.service';
import { ACCESS_TYPE_PASSWORD_EXPIRED_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { HASH_TYPE_WHISTLEBLOWER_REPORTER } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class PrivateSignInService implements IGenericService {
  private SIGN_IN_SERVICE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/auth`;
  private SUPPORT_SIGN_IN_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/auth-support`;
  private SIGN_IN_INTERNATIONALIZATION: string = 'sign-in-page';

  constructor(private http: HttpClient, private injector: Injector) {}

  async signIn(signInOptions: ISignInOptions): Promise<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      const bodyData = {
        grant_type: 'password',
        username: signInOptions.email,
        password: signInOptions.password,
      };

      const signInResponse: ISignInResponse = await this.http.post(`${this.SIGN_IN_SERVICE_URL}/token`, bodyData, httpOptions).toPromise();
      if (signInResponse?.access_token) {
        if (typeof signInResponse.access_token === 'string' || signInResponse.access_token instanceof String) {
          await this.injector.get(PrivateAuthenticationService).authenticate(signInResponse.access_token as string);

          // Init all this services if the token is not an expired password one
          if (this.injector.get(AuthenticationService).getAccessType() !== ACCESS_TYPE_PASSWORD_EXPIRED_ACCESS) {
            this.injector.get(PrivateIntegrationsService).init();
            this.injector.get(PrivateChurnzeroService).loadChurnZero();
            this.injector.get(PrivateHubspotService).loadHubspotTrackingCode();
            this.injector.get(PrivateSearchService).initSearch();
            this.injector.get(PrivateAmplitudeService).logEvent('user login', { category: 'General', type: 'email' });
            this.injector.get(PrivateChurnzeroService).logLoginEvent();
          }
          return { ...signInResponse, is2FARequired: false };
        } else {
          return { ...signInResponse, is2FARequired: true };
        }
      }
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signIn');
    }
  }

  async signInWith2FACode(referenceId: string, hashValue: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };
      const bodyData = { referenceId, hashValue };
      const validation2FAResponse: any = await this.http
        .post(`${this.SIGN_IN_SERVICE_URL}/complete-2fa-sign-in`, bodyData, httpOptions)
        .toPromise();
      await this.injector.get(PrivateAuthenticationService).authenticate(validation2FAResponse.access_token);
      this.injector.get(PrivateIntegrationsService).init();
      this.injector.get(PrivateChurnzeroService).loadChurnZero();
      this.injector.get(PrivateHubspotService).loadHubspotTrackingCode();
      this.injector.get(PrivateSearchService).initSearch();
      this.injector.get(PrivateAmplitudeService).logEvent('user login', { category: 'General', type: 'email' });
      this.injector.get(PrivateChurnzeroService).logLoginEvent();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signInWith2FACode');
    }
  }

  async resend2FACode(username: string, referenceId: string, reference2Id: string): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };
      const bodyData = { username, referenceId, reference2Id };
      await this.http.post(`${this.SIGN_IN_SERVICE_URL}/resend-otp-code`, bodyData, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signInWith2FACode');
    }
  }

  async signInOnMultiOrg(accessToken: string): Promise<void> {
    try {
      await this.injector.get(PrivateAuthenticationService).authenticate(accessToken);

      this.injector.get(PrivateIntegrationsService).init();
      this.injector.get(PrivateChurnzeroService).loadChurnZero();
      this.injector.get(PrivateHubspotService).loadHubspotTrackingCode();
      this.injector.get(PrivateSearchService).initSearch();
      this.injector.get(PrivateAmplitudeService).logEvent('user login', { category: 'General', type: 'email' });
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signInOnMultiOrg');
    }
  }

  signInWithCookie(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpOptions = {
        withCredentials: true,
      };

      this.http
        .get(`${this.SIGN_IN_SERVICE_URL}/cookie`, httpOptions)
        .toPromise()
        .then((responseData) => {
          const accessToken = responseData['access_token'];
          return this.injector.get(PrivateAuthenticationService).authenticate(accessToken);
        })
        .then(() => {
          this.injector.get(PrivateIntegrationsService).init();
          this.injector.get(PrivateChurnzeroService).loadChurnZero();
          this.injector.get(PrivateHubspotService).loadHubspotTrackingCode();
          this.injector.get(PrivateSearchService).initSearch();
          this.injector.get(PrivateAmplitudeService).logEvent('user login', { category: 'General', type: 'cookie' });
          resolve();
        })
        .catch((error) => {
          // We do not notify user and developers because it is easy to fail if cookies were deleted or expired
          // It will require to be handled individually if necessary
          const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'signInWithCookie');
          orgosError.message = 'Sign in with cookie was not successful';
          reject(orgosError);
        });
    });
  }

  signInWithHash(signInWithHashOptions: ISignInWithHashOptions): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      this.http
        .post(`${this.SIGN_IN_SERVICE_URL}/hash`, signInWithHashOptions, httpOptions)
        .toPromise()
        .then((responseData) => {
          const accessToken = responseData['access_token'];
          return this.injector.get(PrivateAuthenticationService).authenticate(accessToken);
        })
        .then(() => {
          // Init all this services if the token is not an expired password one
          if (this.injector.get(AuthenticationService).getAccessType() !== ACCESS_TYPE_PASSWORD_EXPIRED_ACCESS) {
            this.injector.get(PrivateIntegrationsService).init();
            this.injector.get(PrivateSearchService).initSearch();
            this.injector.get(PrivateAmplitudeService).logEvent('user login', { category: 'General', type: 'hash' });
          }
          resolve();
        })
        .catch((error) => {
          // We do not notify user and developers because it could fail in different cases
          // It will require to be handled individually if necessary

          const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'signInWithHash');
          orgosError.message = 'Sign in with hash was not successful';
          reject(orgosError);
        });
    });
  }

  async signInWithWhistleblowerHash(signInWithHashOptions: ISignInWithHashOptions): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };
      if (signInWithHashOptions?.type !== HASH_TYPE_WHISTLEBLOWER_REPORTER) {
        throw new OrgosError('Hash type error', ErrorCodes.UNAUTHORIZED, PrivateSignInService.name, 'signInWithWhistleblowerHash');
      }
      const responseData = await this.http.post(`${this.SIGN_IN_SERVICE_URL}/hash`, signInWithHashOptions, httpOptions).toPromise();
      const accessToken = responseData['access_token'];
      await this.injector.get(PrivateAuthenticationService).authenticate(accessToken);
      return;
    } catch (error: any) {
      // We do not notify user and developers because it could fail in different cases
      // It will require to be handled individually if necessary
      const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'signInWithOnboardingHash');
      orgosError.message = 'Sign in in whistleblower portal was not successful';
      return;
    }
  }

  async signInWithOnboardingHash(signInWithHashOptions: ISignInWithHashOptions): Promise<void> {
    try {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
      const httpOptions = {
        headers: httpHeaders,
      };
      const responseData = await this.http.post(`${this.SIGN_IN_SERVICE_URL}/hash`, signInWithHashOptions, httpOptions).toPromise();
      const accessToken = responseData['access_token'];
      await this.injector.get(PrivateAuthenticationService).authenticate(accessToken);
      return;
    } catch (error: any) {
      // We do not notify user and developers because it could fail in different cases
      // It will require to be handled individually if necessary
      const orgosError = new OrgosError(error, ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'signInWithOnboardingHash');
      orgosError.message = 'Sign in for onboarding was not successful';
      return;
    }
  }

  signInWithSupportHash(hashValue: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      const bodyData = {
        hash: hashValue,
      };

      this.http
        .post(`${this.SUPPORT_SIGN_IN_URL}/sign-in`, bodyData, httpOptions)
        .toPromise()
        .then((responseData) => {
          const accessToken = responseData['authToken']['access_token'];
          return this.injector.get(PrivateAuthenticationService).authenticate(accessToken);
        })
        .then(() => {
          this.injector.get(PrivateIntegrationsService).init();
          this.injector.get(PrivateSearchService).initSearch();
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signInWithSupportHash'));
        });
    });
  }

  signInWithAgencyHash(agency: ISignInAgencyOptions): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      const bodyData = {
        hash: agency.hash,
        email: agency.email,
      };

      this.http
        .post(`${this.SIGN_IN_SERVICE_URL}/sign-in-agency`, bodyData, httpOptions)
        .toPromise()
        .then((responseData) => {
          const accessToken = responseData['access_token'];
          return this.injector.get(PrivateAuthenticationService).authenticate(accessToken);
        })
        .then(() => {
          this.injector.get(PrivateIntegrationsService).init();
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signInWithAgencyHash'));
        });
    });
  }

  signInWithGoogle(googleCodeToken: string, redirectUri: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      const httpOptions = {
        headers: httpHeaders,
      };

      const bodyData = {
        googleCodeToken: googleCodeToken,
        redirectUri: redirectUri,
      };

      this.http
        .post(`${this.SIGN_IN_SERVICE_URL}/google`, bodyData, httpOptions)
        .toPromise()
        .then((responseData) => {
          const accessToken = responseData['access_token'];
          return this.injector.get(PrivateAuthenticationService).authenticate(accessToken);
        })
        .then(() => {
          this.injector.get(PrivateIntegrationsService).init();
          this.injector.get(PrivateChurnzeroService).loadChurnZero();
          this.injector.get(PrivateHubspotService).loadHubspotTrackingCode();
          this.injector.get(PrivateSearchService).initSearch();
          this.injector.get(PrivateAmplitudeService).logEvent('user login', { category: 'General', type: 'SSO' });
          this.injector.get(PrivateChurnzeroService).logLoginEvent();
          resolve();
        })
        .catch((error) => {
          reject(this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signInWithGoogle'));
        });
    });
  }

  async signInWithMicrosoft(microsoftCodeToken: string, redirectUri: string): Promise<void> {
    try {
      const httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      };

      const bodyData = {
        microsoftCodeToken,
        redirectUri,
      };

      const response = await this.http.post(`${this.SIGN_IN_SERVICE_URL}/microsoft`, bodyData, httpOptions).toPromise();
      const accessToken = response['access_token'];
      await this.injector.get(PrivateAuthenticationService).authenticate(accessToken);

      this.injector.get(PrivateIntegrationsService).init();
      this.injector.get(PrivateChurnzeroService).loadChurnZero();
      this.injector.get(PrivateHubspotService).loadHubspotTrackingCode();
      this.injector.get(PrivateSearchService).initSearch();
      this.injector.get(PrivateAmplitudeService).logEvent('user login', { category: 'General', type: 'SSO' });
      this.injector.get(PrivateChurnzeroService).logLoginEvent();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateSignInService.name, 'signInWithMicrosoft');
    }
  }

  create(data: object): Promise<object> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'create');
    error.message = 'SignIn should not be created';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<any> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'getById');
    error.message = 'SignIn should not be got';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  updateById(id: string, data: object): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'updateById');
    error.message = 'SignIn should not be updated';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, PrivateSignInService.name, 'deleteById');
    error.message = 'SignIn should not be deleted';
    this.injector.get(ErrorManagerService).handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getPermissions(): Promise<object> {
    return Promise.resolve({
      create_all: true,
      read_all: true,
      edit_all: true,
      delete_all: false,
    });
  }

  getFieldsTranslations(): Promise<object> {
    return this.injector.get(InternationalizationService).getAllTranslation(this.SIGN_IN_INTERNATIONALIZATION);
  }

  // TODO: later: hs + churnzero methods + get my subs
}

export interface ISignInResponse {
  access_token?: string | ISignInAccessToken2FA;
  token_type?: string;
}

export interface ISignInAccessToken2FA {
  is2FARequired?: boolean;
  mode?: string;
  email?: string;
  referenceId?: string;
  reference2Id?: string;
}

export interface ISignInOptions {
  email: string;
  password: string;
}

export interface ISignInAgencyOptions {
  email: string;
  hash: string;
}

export interface ISignInWithHashOptions {
  type: string;
  hashValue: string;
  referenceId: string;
  s_orgId: string;
}
