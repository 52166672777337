<mat-form-field *ngIf="isWeeklyPeriod" (click)="openDatepicker()" class="kenjo-cursor-pointer isdpc-mat-form" [class.invalidDate]="hasErrors">
  <ng-container>
    <mat-label *ngIf="label && isWeeklyPeriod" [class.invalidDate]="hasErrors" [class.isdpc-required]="required">{{ label }}</mat-label>
    <orgos-column-container>
    <mat-chip-list orgosColumn  #chipList>
      <mat-chip *ngFor="let value of selectedValues" [selectable]="false" [removable]="true" (removed)="removeWeek(value)">
        <orgos-column-container centerColumns="true">
          <div orgosColumn>{{ value?.from | date: 'd LLL yyyy':'UTC' }} - {{ value?.to | date: 'd LLL yyyy':'UTC' }}</div>
          <orgos-column size="0">
            <mat-icon matChipRemove *ngIf="true">clear</mat-icon>
          </orgos-column>
        </orgos-column-container>
      </mat-chip>
    </mat-chip-list>
    <kenjo-icon orgosColumn="0" [size]="20" [class.invalidDate]="hasErrors" class="kenjo-font-color-light-text-757575">calendar_menu</kenjo-icon>
  </orgos-column-container>
  </ng-container>
  <mat-date-range-input [class.hasSomeValue]="selectedValues?.length > 0" [formGroup]="rangeWeekly" [rangePicker]="datepicker" hidden>
    <input matStartDate [formControlName]="'fromWeek'" (dateInput)="onDateChange($event)" [matChipInputFor]="chipList" readonly hidden />
    <input matEndDate [formControlName]="'toWeek'" readonly hidden />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
  <mat-date-range-picker (closed)="onClose()" #datepicker [startAt]="startDate"></mat-date-range-picker>
</mat-form-field>

<mat-form-field *ngIf="!isWeeklyPeriod" (click)="openDatepicker()" class="kenjo-cursor-pointer isdpc-mat-form-day" [class.invalidDate]="hasErrors">
  <ng-container>
    <mat-label *ngIf="label && !isWeeklyPeriod" [class.invalidDate]="hasErrors" [class.isdpc-required]="required">{{ label }}</mat-label>
    <orgos-column-container>
    <mat-chip-list orgosColumn #chipListDay>
      <mat-chip *ngFor="let value of selectedDays" [selectable]="false" [removable]="true" (removed)="removeDay(value)">
        <orgos-column-container centerColumns="true">
          <div orgosColumn>{{ value?.from | date: 'd LLL yyyy':'UTC' }}</div>
          <orgos-column size="0">
            <mat-icon matChipRemove *ngIf="true">clear</mat-icon>
          </orgos-column>
        </orgos-column-container>
      </mat-chip>
    </mat-chip-list>
    <kenjo-icon orgosColumn="0" [size]="20" [class.invalidDate]="hasErrors" class="kenjo-font-color-light-text-757575">calendar_menu</kenjo-icon>
  </orgos-column-container>
  </ng-container>
  <div *ngIf="selectedDays?.length <= 0" class="isdp-no-data-to-show"></div>
  <input matInput [value]="resetDays" [formControl]="selectedDaysCalendar" [matDatepicker]="datepickerDay" (dateChange)="onDateChange($event)" [matChipInputFor]="chipListDay" hidden />
  <mat-datepicker-toggle matSuffix [for]="datepickerDay"></mat-datepicker-toggle>
  <mat-datepicker #datepickerDay [startAt]="startDate" [dateClass]="dateClass"></mat-datepicker>
</mat-form-field>

<div class="isdp-error">
  <ng-content select="orgos-input-error"></ng-content>
</div>

<div [style.display]="'none'">
  <div #datepickerFooter>
    <orgos-column-container class="kenjo-border-top" centerColumns="true">
      <div orgosColumn="0" *ngIf="errorTexts && !inputValidation?.hasErrors() && isWeeklyPeriod && !copyFromMultiple" class="kenjo-p-10px kenjo-font-color-light-text-757575">{{ pageTranslation?.calendarMultipleWeeks }}</div>
      <div orgosColumn="0" *ngIf="errorTexts && !inputValidation?.hasErrors() && !isWeeklyPeriod" class="kenjo-p-10px kenjo-font-color-light-text-757575">
        <span [class.kenjo-font-color-danger-ff5757]="dayRange.length > maxDaysToSelect">{{ dayRange.length }}/{{ maxDaysToSelect }}</span
        >&nbsp;{{ pageTranslation.daysSelected }}
      </div>
      <div orgosColumn="0" *ngIf="errorTexts && inputValidation?.getError('alreadySelected')" class="kenjo-p-10px kenjo-font-color-light-text-757575">{{ pageTranslation?.calendarAlreadySelected }}</div>
      <orgos-column></orgos-column>
      <orgos-button-raised [disabled]="!isValueValid || dayRange.length > maxDaysToSelect" class="kenjo-p-10px" orgosColumn="0" color="Primary" (click)="onClose()">{{ pageTranslation?.calendarConfirmButton }}</orgos-button-raised>
    </orgos-column-container>
  </div>
</div>
