import { Injectable, Injector } from '@angular/core';
import { VIEWS } from '@app/cloud-features/shift-plan/constants/shiftplan.constants';
import { I18nDataPipe } from '@app/standard/components/i18n-data/i18n-data.pipe';
import { CompanyService } from '@app/standard/services/company/company.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { UserWorkService } from '@app/standard/services/user/user-work.service';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';

import { IShiftPlanDate } from './shift-plan-card.service';

@Injectable({
  providedIn: 'root',
})
export class ShiftPlanGeneratePDFService {
  private translations: { [key: string]: string };
  private views = { ...VIEWS };
  private PDF_DATA: IPdfData = {
    unit: 'in',
    defaultOrientation: 'landscape',
    margin: { x: 0.45, y: 1 },
    height: 11.7,
    width: 8.3,
    hLandscape: 645,
    hPortrait: 1000,
    scale: 2,
    image: { type: 'jpeg', quality: 0.6 },
    marginHeader: 0.45,
    marginFooter: 0.35,
    textColor: '#333',
    drawColor: '#DEDEDE',
  };

  constructor(private injector: Injector) {}

  async exportPDF(
    content: HTMLElement,
    options: IShiftplanExportPDFOptions,
    splitPageParams: { positions: Array<number>; pxPageHeight: number; nPages: number } = null,
    componentTranslations: { [key: string]: string }
  ) {
    this.translations = { ...componentTranslations };
    const html2CanvasOptions = {
      scrollX: 0,
      scrollY: 0,
      backgroundColor: null,
      useCORS: true,
      imageTimeout: 10000,
      scale: this.PDF_DATA.scale,
    };

    try {
      const [canvas, companyName] = await Promise.all([html2canvas(content, html2CanvasOptions), this.getCompanyName()]);
      const pageDistribution = this.#splitPagesByPageHeight(splitPageParams);
      const [canvasContent, canvasFooter, canvasDays] = this.#splitCanvas(canvas, options.frequency);
      const displayOrientation = options?.orientation ?? this.PDF_DATA.defaultOrientation;
      const isLandscape = displayOrientation === this.PDF_DATA.defaultOrientation;
      const isWeekly = options.frequency === this.views.WEEK;

      //Measures in inches
      const filename = `${this.translations.shiftplanExport}_${moment().format('DD-MM-YYYY')}`;
      const imgWidthIn = isLandscape ? this.PDF_DATA.height : this.PDF_DATA.width;
      const pageHeightIn = isLandscape ? this.PDF_DATA.width : this.PDF_DATA.height;

      const innerPageWidth = imgWidthIn - this.PDF_DATA.margin.x * 2;
      const innerPageHeight = pageHeightIn - this.PDF_DATA.margin.y * 2;

      const pxFullHeight = canvas.height - 96;
      const pxPageHeight = (isLandscape ? this.PDF_DATA.hLandscape : this.PDF_DATA.hPortrait) * this.PDF_DATA.scale;

      const pageHeights = [...pageDistribution.eachPageHeight];
      const pageY = [...pageDistribution.contentYPositions];
      const nPages = Math.ceil(pxFullHeight / pxPageHeight);

      let pageHeight = innerPageHeight;

      const imgDataDays = canvasDays.toDataURL(`image/${this.PDF_DATA.image.type}`, this.PDF_DATA.image.quality);
      const imgDataFooter = canvasFooter.toDataURL(`image/${this.PDF_DATA.image.type}`, this.PDF_DATA.image.quality);

      // Create a one-page canvas to split up the full image.
      const pageCanvas = document.createElement('canvas');
      const pageCtx = pageCanvas.getContext('2d');
      pageCanvas.width = canvasContent.width;

      // Initialize the PDF.
      const pdf = new jsPDF({
        orientation: displayOrientation,
        unit: this.PDF_DATA.unit,
        format: [imgWidthIn, pageHeightIn],
        compress: true,
        precision: 10,
      });

      for (let page = 0; page < nPages; page++) {
        pageCanvas.height = pageHeights[page];
        pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;

        // Display the page.
        const w = pageCanvas.width;
        const h = pageCanvas.height;
        pageCtx.fillStyle = '#fff';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvasContent, 0, pageY[page], w, h, 0, 0, w, h);

        let marginY = this.PDF_DATA.margin.y;
        if (page > 0) {
          pdf.addPage();
          marginY = this.PDF_DATA.margin.y + (isWeekly ? 0.2 : 0.3);
          pdf.addImage(
            imgDataDays,
            this.PDF_DATA.image.type.toUpperCase(),
            this.PDF_DATA.margin.x,
            this.PDF_DATA.margin.y,
            innerPageWidth,
            isWeekly ? 0.23 : 0.325,
            undefined,
            'FAST'
          );
        }

        this.#addHeader(pdf, companyName, options);
        this.#addFooter(pdf, imgDataFooter, { pageNumber: page + 1, totalPages: nPages, innerPageWidth, isWeekly });

        const imgData = pageCanvas.toDataURL(`image/${this.PDF_DATA.image.type}`, this.PDF_DATA.image.quality);
        pdf.addImage(
          imgData,
          this.PDF_DATA.image.type.toUpperCase(),
          this.PDF_DATA.margin.x,
          marginY,
          innerPageWidth,
          pageHeight,
          undefined,
          'FAST'
        );
      }
      pdf.save(filename);
      return true;
    } catch {
      return false;
    }
  }

  #addHeader(pdf: jsPDF, companyName: string, options: IShiftplanExportPDFOptions) {
    pdf.setFontSize(9);
    pdf.setTextColor(this.PDF_DATA.textColor);

    //Date
    pdf.text(`${options.rangeLabel.from} - ${options.rangeLabel.to}`, this.PDF_DATA.marginHeader, this.PDF_DATA.marginHeader, {
      align: 'left',
    });
    pdf.text(
      this.translations.shiftplanExportedOn,
      pdf.internal.pageSize.getWidth() - this.PDF_DATA.marginHeader,
      this.PDF_DATA.marginHeader,
      { align: 'right' }
    );

    //Company name
    const today = moment().format('DD/MM/YYYY');
    pdf.text(companyName, this.PDF_DATA.marginHeader, this.PDF_DATA.marginHeader + 0.2, { align: 'left' });
    pdf.text(`${today}`, pdf.internal.pageSize.getWidth() - this.PDF_DATA.marginHeader, this.PDF_DATA.marginHeader + 0.2, {
      align: 'right',
    });

    //Divisor
    pdf.setLineWidth(0.02);
    pdf.setDrawColor(this.PDF_DATA.drawColor);
    pdf.line(
      this.PDF_DATA.marginHeader,
      this.PDF_DATA.marginHeader + 0.3,
      pdf.internal.pageSize.getWidth() - this.PDF_DATA.marginHeader,
      this.PDF_DATA.marginHeader + 0.3
    );
  }

  #addFooter(pdf: jsPDF, imgDataFooter, data: { pageNumber: number; totalPages: number; innerPageWidth: number; isWeekly: boolean }) {
    //Add kenjo-logo
    const footerImgSize = { w: data.isWeekly ? 0.8 : data.innerPageWidth, h: data.isWeekly ? 0.18 : 0.5 };
    const footerImgPosition = {
      x: this.PDF_DATA.marginFooter + 0.1,
      y: pdf.internal.pageSize.getHeight() - this.PDF_DATA.marginFooter - (data.isWeekly ? 0 : 0.35),
    };
    pdf.addImage(
      imgDataFooter,
      this.PDF_DATA.image.type.toUpperCase(),
      footerImgPosition.x,
      footerImgPosition.y,
      footerImgSize.w,
      footerImgSize.h,
      undefined,
      'FAST'
    );

    //Add page marker
    const transformedFooterText = this.injector
      .get(I18nDataPipe)
      .transform(this.translations.pageFooter, { pageNumber: data.pageNumber, totalPages: data.totalPages });
    pdf.setFontSize(9);
    pdf.setTextColor(this.PDF_DATA.textColor);

    const pageTextSizePostion = {
      x: data.isWeekly ? this.PDF_DATA.marginFooter + 1.38 : pdf.internal.pageSize.getWidth() - 1.1,
      y: pdf.internal.pageSize.getHeight() - (data.isWeekly ? 0.21 : 0.39),
    };
    pdf.text(transformedFooterText, pageTextSizePostion.x, pageTextSizePostion.y);
  }

  #splitCanvas(canvas: HTMLCanvasElement, frequency: 'week' | 'month') {
    // Calculate the height of each split canvas
    const splitHeights = {
      content: frequency === this.views.WEEK ? 97 : 173,
      days: frequency === this.views.WEEK ? 46 : 66,
      footer: frequency === this.views.WEEK ? 46 : 100,
    };

    const splitHeightContent = canvas.height - splitHeights.content;
    const splitHeightDays = splitHeights.days;
    const splitHeightFooter = splitHeights.footer;

    // Content
    const canvasContent = document.createElement('canvas');
    canvasContent.width = canvas.width;
    canvasContent.height = splitHeightContent;

    const ctxContent = canvasContent.getContext('2d');
    ctxContent.drawImage(canvas, 0, 0, canvas.width, splitHeightContent, 0, 0, canvas.width, splitHeightContent);

    //Days header if it is more than 1 page
    const canvasDays = document.createElement('canvas');
    canvasDays.width = canvas.width;
    canvasDays.height = splitHeightDays + 6;

    const ctxDays = canvasDays.getContext('2d');
    ctxDays.drawImage(canvas, 0, splitHeightContent - 4, canvas.width, canvasDays.height, 0, 0, canvas.width, canvasDays.height);

    //Footer
    const canvasFooter = document.createElement('canvas');
    canvasFooter.width = frequency === this.views.WEEK ? 170 : canvas.width;
    canvasFooter.height = splitHeightFooter;

    const ctxFooter = canvasFooter.getContext('2d');
    ctxFooter.drawImage(
      canvas,
      0,
      splitHeightContent + splitHeightDays - 3,
      canvasFooter.width,
      splitHeightFooter,
      0,
      0,
      canvasFooter.width,
      splitHeightFooter
    );

    return [canvasContent, canvasFooter, canvasDays];
  }

  #splitPagesByPageHeight({ positions, pxPageHeight, nPages }) {
    const eachPageHeight: Array<number> = [];
    const contentYPositions: Array<number> = [0];
    const firstPosition = positions[0];
    let positionsToAdd = [];
    const pagesToAdd = Math.trunc(firstPosition / pxPageHeight);

    if (pagesToAdd > 0) {
      for (let i = 1; i <= pagesToAdd; i++) {
        positionsToAdd.push(pxPageHeight * i);
      }
      positions = [...positionsToAdd, ...positions];
    }

    //Always start at 0
    let currentPageY = 0;

    if (nPages === 1) {
      eachPageHeight.push(positions[positions.length - 1]);
      return { eachPageHeight, contentYPositions, nPages };
    }

    for (let i = 0; i < nPages; i++) {
      const findSizeIndex = positions.findIndex((position) => position > pxPageHeight + currentPageY);
      if (findSizeIndex === -1) {
        eachPageHeight.push(positions[positions.length - 1] - currentPageY);
        break;
      }
      const pageHeight = positions[findSizeIndex - 1];
      eachPageHeight.push(pageHeight - currentPageY);
      contentYPositions.push(pageHeight);

      currentPageY = pageHeight;
    }
    return { eachPageHeight, contentYPositions, nPages };
  }

  async getCompanyName() {
    const myProfile = this.injector.get(AuthenticationService).getLoggedUser();
    const myUserWork = await this.injector.get(UserWorkService).getById(myProfile._id);
    const myCompany = await this.injector.get(CompanyService).getById(myUserWork.companyId);
    return myCompany?.name;
  }
}

export type DisplayOrientation = 'portrait' | 'landscape';
type PDFUnit = 'pt' | 'px' | 'in' | 'mm' | 'cm' | 'ex' | 'em' | 'pc';
export interface IPdfData {
  unit: PDFUnit;
  defaultOrientation: DisplayOrientation;
  margin: { x: number; y: number };
  height: number;
  width: number;
  hLandscape: number;
  hPortrait: number;
  scale: number;
  image: { type: 'jpeg' | 'png'; quality: number };
  marginHeader: number;
  marginFooter: number;
  textColor: string;
  drawColor: string;
}
export interface IShiftplanExportPDFOptions {
  rangeLabel: { from: string; to: string };
  selectedRange?: IShiftPlanDate;
  listView?: 'user' | 'role';
  shiftType?: 'open' | 'scheduled' | 'both';
  shiftStatus?: 'draft' | 'published' | 'both';
  orientation: DisplayOrientation;
  includeWorkingTime: boolean;
  frequency: 'week' | 'month';
  color: boolean;
  space: boolean;
}
