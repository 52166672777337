import { Component, Input } from '@angular/core';

@Component({
  selector: 'kenjo-loading-screen',
  templateUrl: 'loading-screen.component.html',
  styleUrls: ['loading-screen.component.scss']
})
export class LoadingScreenComponent {
  @Input() title;
  @Input() subtitle: string;
}
