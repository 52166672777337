import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  comesFrom: string = '';
  constructor(private router: Router, private route: ActivatedRoute, private location: Location) {}

  getQueryParam(queryParam: string): string {
    return this.route.snapshot.queryParamMap.get(queryParam);
  }

  async getMultipleQueryParams(queryParams: string[]): Promise<Map<string, string>> {
    const queryParamsMap = new Map<string, string>();

    queryParams.forEach(async (queryParam) => {
      const paramValue = this.getQueryParam(queryParam);
      queryParamsMap.set(queryParam, paramValue);
    });

    return queryParamsMap;
  }

  // This one doesn't remove query param definitely
  removeQueryParam(paramKey: string): void {
    const queryParams: Params = { ...this.route.snapshot.queryParams };
    if (queryParams.hasOwnProperty(paramKey)) {
      delete queryParams[paramKey];

      const url = this.location.path();
      const index = url.indexOf('?');
      const baseUrl = index > -1 ? url.substring(0, index) : url;

      this.location.replaceState(baseUrl);
    }
  }

  // This one removes query param definitely
  removeQueryParamWithoutMerging(param: string): void {
    const queryParams = { ...this.route.snapshot.queryParams };

    delete queryParams[param];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: ''
    });
  }

  // This one removes query param definitely
  removeMultipleQueryParams(paramKeys: { [key: string]: any }): void {
    this.router.navigate([], {
      queryParams: paramKeys,
      queryParamsHandling: 'merge'
    });
  }

  setComesFrom(path: string): void {
    this.comesFrom = path;
  }

  setGoBackButton(backPath: string): void {
    if (this.comesFrom !== '') {
      const url = this.router.url;
      history.pushState(null, '', backPath);
      history.pushState(null, '', url);
    }
  }
}
