<div class="kenjo-pv-60px kenjo-ph-20px rcpd-main-container" *ngIf="candidate">
  <orgos-column-container centerColumns="true">
    <orgos-column size="1" class="kenjo-font-size-20px"> {{dialogTranslation.scoreCandidateTitle}} </orgos-column>
    <kenjo-icon orgosColumn="0" [size]="24" class="kenjo-ml-40px kenjo-cursor-pointer kenjo-font-color-light-text-757575" (click)="closeDialog()"> clear </kenjo-icon>
  </orgos-column-container>

  <orgos-container aspect="card" class="kenjo-p-40px kenjo-mt-30px">
    <orgos-column-container centerColumns="true">
      <orgos-column size="0" class="kenjo-mr-20px">
        <orgos-avatar [avatarName]="candidate.data.firstName.concat(' '+candidate.data.lastName)" [photoUrl]="candidate.data.photo?._url" size="medium"></orgos-avatar>
      </orgos-column>
      <orgos-column size="1">
        <div class="kenjo-font-size-18px">{{candidate.data.firstName}}&nbsp;{{candidate.data.lastName}}</div>
        <div class="kenjo-mt-10px" *ngIf="position.data && position.data.jobTitle">{{position.data.jobTitle}}</div>
      </orgos-column>
    </orgos-column-container>

    <div *ngIf="scorecard" class="kenjo-pt-10px">
      <!-- Workaround to solve: https://app.asana.com/0/389662948049697/764314821124050 -->
      <input style="position: fixed; left: -1000000px" />
      <!-- End workaround-->
      <ng-container *ngIf="scorecard.subsections && scorecard.subsections.length > 0">
        <ng-container *ngFor="let iSubsection of scorecard.subsections; let i = index">
          <ng-container *ngIf="iSubsection && iSubsection.skills && iSubsection.skills.length > 0">
            <div class="kenjo-mt-40px kenjo-mb-20px kenjo-font-size-16px">{{iSubsection.sectionTitle}}</div>
            <div *ngFor="let iSkill of iSubsection.skills; let j = index; let even = even" class="kenjo-p-20px" [class.kenjo-color-grey-background-lighter-f8f8f8]="even" [class.kenjo-border-radius]="even">
              <orgos-column-container centerColumns="true">
                <orgos-column size="1" class="kenjo-font-color-light-text-757575 kenjo-mr-40px"> {{iSkill.skillName}} </orgos-column>
                <orgos-column size="0" class="kenjo-mr-10px" (click)="skillIdToRating[iSkill._id].skillRating = 1">
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating !== 1" [matTooltip]="dialogTranslation.veryBadTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> angry</kenjo-icon>
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating === 1" [matTooltip]="dialogTranslation.veryBadTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> angry2</kenjo-icon>
                </orgos-column>
                <orgos-column size="0" class="kenjo-mr-10px" (click)="skillIdToRating[iSkill._id].skillRating = 2">
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating !== 2" [matTooltip]="dialogTranslation.badTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> sad </kenjo-icon>
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating === 2" [matTooltip]="dialogTranslation.badTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> sad2 </kenjo-icon>
                </orgos-column>
                <orgos-column size="0" class="kenjo-mr-10px" (click)="skillIdToRating[iSkill._id].skillRating = 3">
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating !== 3" [matTooltip]="dialogTranslation.neutralTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> neutral</kenjo-icon>
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating === 3" [matTooltip]="dialogTranslation.neutralTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> neutral2</kenjo-icon>
                </orgos-column>
                <orgos-column size="0" class="kenjo-mr-10px" (click)="skillIdToRating[iSkill._id].skillRating = 4">
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating !== 4" [matTooltip]="dialogTranslation.goodTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3">smile </kenjo-icon>
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating === 4" [matTooltip]="dialogTranslation.goodTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> smile2 </kenjo-icon>
                </orgos-column>
                <orgos-column size="0" class="kenjo-mr-40px" (click)="skillIdToRating[iSkill._id].skillRating = 5">
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating !== 5" [matTooltip]="dialogTranslation.veryGoodTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> happy</kenjo-icon>
                  <kenjo-icon [size]="18" *ngIf="skillIdToRating[iSkill._id].skillRating === 5" [matTooltip]="dialogTranslation.veryGoodTooltip" matTooltipPosition="above" class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"> happy2</kenjo-icon>
                </orgos-column>
                <orgos-column size="0">
                  <orgos-action (click)="toggleCommentsForSkillId(iSkill._id)"> {{skillIdToRating[iSkill._id].showComments ? dialogTranslation.hideComments : dialogTranslation.showComments}} </orgos-action>
                </orgos-column>
              </orgos-column-container>
              <orgos-input-simple-text-area *ngIf="skillIdToRating[iSkill._id].showComments === true" label='{{dialogTranslation.makeNotePlaceholder}} "{{iSkill.skillName}}"' [rows]="3" [(value)]="skillIdToRating[iSkill._id].skillComment" class="kenjo-block kenjo-pt-20px">
              </orgos-input-simple-text-area>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <div class="kenjo-mt-40px kenjo-font-size-16px kenjo-mb-10px">{{dialogTranslation.overallCommentsTitle}}</div>
      <mat-label>{{dialogTranslation.overallCommentsPlaceholder}}</mat-label>
      <textarea matInput [(ngModel)]="overall.comment" [rows]="4" class="kenjo-block kenjo-mt-10px rcpd-score-comments-textarea"></textarea>

      <div class="kenjo-mt-40px kenjo-mb-20px kenjo-font-size-16px">{{dialogTranslation.overallRatingTitle}}</div>
      <orgos-column-container centerColumns="true">
        <orgos-column size="1" class="kenjo-pr-10px kenjo-pl-10px" (click)="overall.rating = 1">
          <div class="rcpd-overall-rating kenjo-border-radius kenjo-cursor-pointer kenjo-text-align-center kenjo-pv-10px kenjo-ph-20px" [class.rcpd-overall-rating-selected]="overall.rating === 1">
            <kenjo-icon *ngIf="overall.rating !== 1" [size]="18">angry</kenjo-icon>
            <kenjo-icon *ngIf="overall.rating === 1" [size]="18">angry2</kenjo-icon>
            <div class="kenjo-mt-5px">{{dialogTranslation.veryBadButtonLabel}}</div>
          </div>
        </orgos-column>
        <orgos-column size="1" class="kenjo-pr-10px kenjo-pl-10px" (click)="overall.rating = 2">
          <div class="rcpd-overall-rating kenjo-border-radius kenjo-cursor-pointer kenjo-text-align-center kenjo-pv-10px kenjo-ph-20px" [class.rcpd-overall-rating-selected]="overall.rating === 2">
            <kenjo-icon *ngIf="overall.rating !== 2" [size]="18">sad</kenjo-icon>
            <kenjo-icon *ngIf="overall.rating === 2" [size]="18">sad2</kenjo-icon>
            <div class="kenjo-mt-5px">{{dialogTranslation.badButtonLabel}}</div>
          </div>
        </orgos-column>
        <orgos-column size="1" class="kenjo-pr-10px kenjo-pl-10px" (click)="overall.rating = 3">
          <div class="rcpd-overall-rating kenjo-border-radius kenjo-cursor-pointer kenjo-text-align-center kenjo-pv-10px kenjo-ph-20px" [class.rcpd-overall-rating-selected]="overall.rating === 3">
            <kenjo-icon *ngIf="overall.rating !== 3" [size]="18">neutral</kenjo-icon>
            <kenjo-icon *ngIf="overall.rating === 3" [size]="18">neutral2</kenjo-icon>
            <div class="kenjo-mt-5px">{{dialogTranslation.neutralButtonLabel}}</div>
          </div>
        </orgos-column>
        <orgos-column size="1" class="kenjo-pr-10px kenjo-pl-10px" (click)="overall.rating = 4">
          <div class="rcpd-overall-rating kenjo-border-radius kenjo-cursor-pointer kenjo-text-align-center kenjo-pv-10px kenjo-ph-20px" [class.rcpd-overall-rating-selected]="overall.rating === 4">
            <kenjo-icon *ngIf="overall.rating !== 4" [size]="18">smile</kenjo-icon>
            <kenjo-icon *ngIf="overall.rating === 4" [size]="18">smile2</kenjo-icon>
            <div class="kenjo-mt-5px">{{dialogTranslation.goodButtonLabel}}</div>
          </div>
        </orgos-column>
        <orgos-column size="1" class="kenjo-pr-10px kenjo-pl-10px" (click)="overall.rating = 5">
          <div class="rcpd-overall-rating kenjo-border-radius kenjo-cursor-pointer kenjo-text-align-center kenjo-pv-10px kenjo-ph-20px" [class.rcpd-overall-rating-selected]="overall.rating === 5">
            <kenjo-icon *ngIf="overall.rating !== 5" [size]="18">happy</kenjo-icon>
            <kenjo-icon *ngIf="overall.rating === 5" [size]="18">happy2</kenjo-icon>
            <div class="kenjo-mt-5px">{{dialogTranslation.veryGoodButtonLabel}}</div>
          </div>
        </orgos-column>
      </orgos-column-container>

      <div class="kenjo-text-align-right kenjo-mt-60px">
        <orgos-button-raised color="Success" [disabled]="savingScore" (click)="saveScore()"> {{dialogTranslation.saveButtonLabel}}</orgos-button-raised>
      </div>
    </div>
  </orgos-container>
</div>
