import { CommonModule } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { IAttendancePolicy } from '@app/cloud-features/settings-attendance/services/attendance-policy.service';
import { IconModule } from '@app/common-components/kenjo-icon/kenjo-icon.module';
import { TruncateTextModule } from '@app/common-components/truncate-text/truncate-text.module';
import { convertToTime } from '@app/common-components/widgets/punch-clock/orgos-widget-punch-clock.helpers';
import { IBreak, IUserAttendanceModel } from '@app/standard/services/user/user-attendance.service';
import { ButtonRaisedStandaloneComponent } from '@app/standard/standalone-components/button-raised/button-raised-standalone.component';
import { ChipV2Component } from '@app/standard/standalone-components/chip/chip.component';
import * as userColors from '@carlos-orgos/orgos-utils/constants/user-color.constants';

@Component({
  selector: 'kenjo-overlapping-dialog',
  templateUrl: 'overlapping-dialog.component.html',
  styleUrls: ['overlapping-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, IconModule, MatLegacyTooltipModule, ButtonRaisedStandaloneComponent, TruncateTextModule, ChipV2Component],
})
export class OverlappingDialogComponent {
  titleText: string;
  subtitleText: string;
  maxDailyHourSubtitle: string
  confirmButtonText: string;
  cancelButtonText: string;
  infoTooltipText: string;
  warningText: string;
  automationText: string;
  originalRecordText: string;
  modifiedRecordText: string;
  originalRecords: IEntryRecord[];
  modifiedRecords: IEntryRecord[];
  confirmButtonColor: 'Success' | 'Warning' | 'Danger' | 'Neutral' | 'Primary' = 'Success';
  attendancePolicy: IAttendancePolicy;
  cancelDisabled: boolean;
  confirmDisabled: boolean;
  userColors: Record<string, string>;

  constructor(
    public dialogRef: MatLegacyDialogRef<OverlappingDialogComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public data: any
  ) {
    this.titleText = this.data.titleText;
    this.subtitleText = this.data.subtitleText;
    this.maxDailyHourSubtitle = this.data.maxDailyHourSubtitle
    this.warningText = this.data.warningText;
    this.infoTooltipText = this.data.infoTooltipText;
    this.automationText = this.data.automationText;
    this.originalRecordText = this.data.originalRecordText;
    this.modifiedRecordText = this.data.modifiedRecordText;
    this.originalRecords = this.parseEntriesForTranslation(this.data.originalRecords);
    this.modifiedRecords = this.parseEntriesForTranslation(this.data.modifiedRecords);
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    this.cancelDisabled = this.data.cancelDisabled;
    this.confirmDisabled = this.data.confirmDisabled;
    this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : this.confirmButtonColor;
    this.attendancePolicy = this.data.attendancePolicy;
    this.dialogRef.disableClose = !!this.data.disableClose;
    this.userColors = userColors;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.dialogRef.close(false);
      return;
    }
    this.dialogRef.close(true);
  }

  private parseEntriesForTranslation(
    entries: Array<IUserAttendanceModel | ITimeOffAttendance>
  ): { isTimeOff: boolean; text: string; color?: string }[] {
    entries.sort((a, b) => {
      if (a.startTime !== b.startTime) {
        return (a.startTime ?? 0) > (b.startTime ?? 0) ? 1 : -1;
      } else {
        return (a.endTime ?? 0) > (b.endTime ?? 0) ? -1 : 1;
      }
    });

    return entries.map((entry: ITimeOffAttendance) => {
      const entryTranslation = `${convertToTime(entry.startTime)} - ${entry.endTime ? convertToTime(entry.endTime) : ''}`;

      if (entry?.isTimeOff) {
        return { isTimeOff: true, text: `${entryTranslation}`, timeOffTypeText: `(${entry?.timeOffName})`, color: entry?.timeOffColor };
      } else {
        return { isTimeOff: false, text: `${entryTranslation}\n` };
      }
    });
  }
}

export interface ITimeOffAttendance {
  startTime: number;
  endTime: number;
  date: string;
  breaks: IBreak[];
  timeOffColor: string;
  timeOffName: string;
  isTimeOff: boolean;
}

export interface IEntryRecord {
  isTimeOff: boolean;
  text: string;
  color?: string;
  timeOffTypeText?: string;
}
