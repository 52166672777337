<orgos-dialog-container [title]="translations.breakTimeNotCompleted" icon="break" (clickCloseButton)="close()">
  <div class="kenjo-font-color-light-text-757575">
    {{ translations.workTimeRequire | i18nData: { workTime: breakReminderConfig.reminder.triggeredAfter | duration: true:true:true } }}
    <span class="kenjo-font-weight-bold"
      >{{ translations.requireAtLeast | i18nData: { breakTime: breakReminderConfig.reminder.suggestedBreak | duration: false:true:true }
      }}</span
    >
  </div>

  <div class="kenjo-font-weight-bold kenjo-mt-30px">{{ translations.todaysBreaks }}</div>

  <div #breaksContainer class="brd-breaks kenjo-mt-10px">
    <ng-container *ngFor="let iBreak of breaks; let index = index">
      <!-- Normal breaks -->
      <div
        *ngIf="!iBreak.isReadOnly"
        class="brd-break kenjo-ph-15px kenjo-pt-10px"
        [class.kenjo-pb-5px]="iBreak.conflicts?.sameStartAndEnd || iBreak.conflicts?.overlappingStart || iBreak.conflicts?.overlappingEnd || iBreak.conflicts?.outOfShiftStart || iBreak.conflicts?.outOfShiftEnd || iBreak.conflicts?.overlappingTimeOff"
      >
        <div>
          <orgos-input-simple-time
            class="kenjo-mr-20px"
            [(value)]="iBreak.start"
            [label]="translations.from"
            [keepOriginalMinWidth]="true"
            [forceError]="iBreak.conflicts?.overlappingStart || iBreak.conflicts?.overlappingEnd || iBreak.conflicts?.outOfShiftStart || iBreak.conflicts?.outOfShiftEnd || iBreak.conflicts?.sameStartAndEnd || iBreak.conflicts?.overlappingTimeOff"
            [maskPlaceholder]="iBreak.start > MINUTES_IN_DAY ? parseTimeEntry(iBreak.start, 'start') : null"
            [enableClearButton]="true"
            (valueChange)="onBreakChange()"
          >
          </orgos-input-simple-time>
          <kenjo-icon [size]="18" class="kenjo-mr-20px kenjo-font-color-light-text-757575">arrow_right_alt</kenjo-icon>
          <orgos-input-simple-time
            [(value)]="iBreak.end"
            [label]="translations.to"
            [keepOriginalMinWidth]="true"
            [forceError]="iBreak.conflicts?.overlappingStart || iBreak.conflicts?.overlappingEnd || iBreak.conflicts?.outOfShiftStart || iBreak.conflicts?.outOfShiftEnd || iBreak.conflicts?.sameStartAndEnd || iBreak.conflicts?.overlappingTimeOff"
            [maskPlaceholder]="iBreak.end > MINUTES_IN_DAY ? parseTimeEntry(iBreak.end, 'end') : null"
            [enableClearButton]="true"
            (valueChange)="onBreakChange()"
          >
          </orgos-input-simple-time>
        </div>

        <!-- Same start and end -->
        <div *ngIf="iBreak.conflicts?.sameStartAndEnd" class="brd-break-error kenjo-font-color-danger-ff5757 kenjo-font-size-12px">
          {{ translations.sameStartAndEnd }}
        </div>

        <!-- Overlapping with other break -->
        <div
          *ngIf="!iBreak.conflicts?.sameStartAndEnd && (iBreak.conflicts?.overlappingStart || iBreak.conflicts?.overlappingEnd)"
          class="brd-break-error kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
        >
          {{ translations.overlappingBreaks }}
        </div>

        <!-- Start before from -->
        <div
          *ngIf="!iBreak.conflicts?.sameStartAndEnd && !iBreak.conflicts?.overlappingStart && !iBreak.conflicts?.overlappingEnd && iBreak.conflicts?.outOfShiftStart && iBreak.start < breakReminderConfig.from"
          class="brd-break-error kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
        >
          {{ translations.cantStartBefore | i18nData: { time: breakReminderConfig.from | time } }}
        </div>

        <!-- Start after to -->
        <div
          *ngIf="!iBreak.conflicts?.sameStartAndEnd && !iBreak.conflicts?.overlappingStart && !iBreak.conflicts?.overlappingEnd && iBreak.conflicts?.outOfShiftStart && iBreak.start > breakReminderConfig.to"
          class="brd-break-error kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
        >
          {{ translations.cantStartAfter | i18nData: { time: breakReminderConfig.to | time } }}
        </div>

        <!-- End after to -->
        <div
          *ngIf="!iBreak.conflicts?.sameStartAndEnd && !iBreak.conflicts?.overlappingStart && !iBreak.conflicts?.overlappingEnd && !iBreak.conflicts?.outOfShiftStart && iBreak.conflicts?.outOfShiftEnd"
          class="brd-break-error kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
        >
          {{ translations.cantEndAfter | i18nData: { time: breakReminderConfig.to | time } }}
        </div>

        <!-- Time off hourly conflict -->
        <div
          *ngIf="!iBreak.conflicts?.sameStartAndEnd && !iBreak.conflicts?.overlappingStart && !iBreak.conflicts?.overlappingEnd && !iBreak.conflicts?.outOfShiftStart && !iBreak.conflicts?.outOfShiftEnd && iBreak.conflicts?.overlappingTimeOff"
          class="brd-break-error kenjo-font-color-danger-ff5757 kenjo-font-size-12px"
        >
          {{ translations.overlappingTimeOff }}
        </div>

        <kenjo-icon
          [size]="24"
          class="brd-delete-break kenjo-font-color-danger-ff5757 kenjo-cursor-pointer kenjo-mb-20px"
          (click)="deleteBreak(index)"
          >delete</kenjo-icon
        >
        <div class="brd-break-duration kenjo-ml-10px">{{ iBreak.duration | duration: false:true:true }}</div>
      </div>

      <!-- Read only breaks -->
      <div *ngIf="iBreak.isReadOnly" class="brd-break-read-only kenjo-ph-15px">
        <div class="brd-break-read-only-time kenjo-font-color-light-text-757575">
          <div>{{ iBreak.start | time }}</div>
          <kenjo-icon [size]="18" class="kenjo-ml-40px kenjo-mr-20px">arrow_right_alt</kenjo-icon>
          <div>{{ iBreak.end | time }}</div>
        </div>
        <div class="brd-break-read-only-duration">{{ iBreak.duration | duration: false:true:true }}</div>
      </div>
    </ng-container>
  </div>

  <div class="brd-add-break kenjo-mt-10px kenjo-ph-15px kenjo-pv-10px" (click)="addBreak()" [class.kenjo-mt-10px]="breaks?.length > 0">
    <orgos-action [uppercase]="false" [forceBlockContainer]="false" icon="add_circle">{{ translations.addABreak }}</orgos-action>
  </div>

  <div class="brd-break-time kenjo-font-size-16px kenjo-mt-30px">
    <div>{{ translations.breakTime }}</div>
    <div>{{ breakTime | duration: false:true:true }}</div>
  </div>

  <div
    *ngIf="breakReminderConfig.reminder.suggestedBreak - breakTime > 0"
    class="brd-break-time kenjo-font-size-16px kenjo-mt-10px kenjo-font-color-caution-ff8d0b"
  >
    <div>{{ translations.timeRemaining }}</div>
    <div>{{ breakReminderConfig.reminder.suggestedBreak - breakTime | duration: false:true:true }}</div>
  </div>

  <div class="brd-action kenjo-mt-10px">
    <orgos-button-raised color="Neutral" (click)="close()">{{ translations.skip }}</orgos-button-raised>
    <orgos-button-raised class="kenjo-ml-20px" color="Success" [disabled]="!canBeSaved" (click)="save()"
      >{{ translations.save }}</orgos-button-raised
    >
  </div>
</orgos-dialog-container>
