import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import * as userColorConstants from '@carlos-orgos/orgos-utils/constants/user-color.constants';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'kenjo-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['chip.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatLegacyTooltipModule],
})
export class ChipV2Component implements OnInit, OnChanges {
  @Input() type: 'primary' | 'secondary' = 'primary';
  @Input() size: 's' | 'm' = 's';
  @Input() semantic: 'info' | 'success' | 'danger' | 'warning' | 'neutral' | 'user' = 'info';
  @Input() upperCase: boolean;
  @Input() userColorKey: string;
  @Input() text: string;
  @Input() icon: boolean;
  @Input() iconTooltip: string;

  @Output() iconClick = new EventEmitter<void>();

  cssClasses: { [key: string]: boolean } = {};
  userColorValue: string;

  ngOnInit(): void {
    this.updateProperties();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.updateProperties();
    }
  }

  private updateProperties(): void {
    this.initUserColorValue();
    this.initCssClasses();
  }

  private initUserColorValue(): void {
    if (!this.userColorKey) {
      return;
    }
    this.userColorValue = userColorConstants[this.userColorKey];
  }

  private initCssClasses(): void {
    this.cssClasses = {
      [`cc-type-${this.type}`]: true,
      [`cc-size-${this.size}`]: true,
      [`cc-semantic-${this.semantic}`]: true,
    };
  }
}
