<orgos-dialog-container
  *ngIf="parentInfo"
  [title]="parentInfo.questionTitle ? parentInfo.questionTitle : (insightsTranslation[parentInfo.feed.title] ? insightsTranslation[parentInfo.feed.title] : (surveysTranslation[parentInfo.feed.title] ? surveysTranslation[parentInfo.feed.title] : parentInfo.feed.title))"
  (clickCloseButton)="closeDialog()"
>
  <div class="kenjo-font-color-light-text-757575 kenjo-mb-20px">{{pageTranslation.askedThisQuestionOn}} {{parentInfo.feed._createdAt | date:'shortDate'}}</div>

  <ng-container *ngFor="let iComment of parentInfo.feed.comments; let i = index;">
    <orgos-feed-comment class="kenjo-mb-20px" [feedComment]="iComment" [displayName]="iComment.sentById ? parentInfo.mapUserIdToUserPersonal[iComment.sentById]?.displayName : ''" [photoUrl]="iComment.sentById ? parentInfo.mapUserIdToUserPersonal[iComment.sentById]?._photo?._url : ''">
    </orgos-feed-comment>
  </ng-container>

  <orgos-column-container>
    <orgos-column size="0" class="kenjo-mr-10px">
      <orgos-avatar [isAnonymous]="isAnonymous" [avatarName]="parentInfo.mapUserIdToUserPersonal[currentUserId]?.displayName" [photoUrl]="parentInfo.mapUserIdToUserPersonal[currentUserId]?._photo?._url" size="small" class="kenjo-mt-20px"></orgos-avatar>
    </orgos-column>
    <orgos-input-simple-text-area orgosColumn [label]="pageTranslation.replyPlaceholderMessage" [rows]="6" [(value)]="commentText"></orgos-input-simple-text-area>
  </orgos-column-container>

  <orgos-column-container class="kenjo-mt-60px" centerColumns="true">
    <orgos-column size="0" class="kenjo-font-color-light-text-757575"> {{pageTranslation.anonymousFeedbackLabel}} </orgos-column>
    <orgos-column size="0" class="kenjo-mh-10px">
      <mat-slide-toggle orgosColumn="0" [(ngModel)]="isAnonymous" class="slide-toggle"></mat-slide-toggle>
    </orgos-column>
    <orgos-column size="1"></orgos-column>
    <orgos-column size="0">
      <orgos-button-raised color="Success" (click)="addComment()" [disabled]="!commentText || commentText.trim().length === 0">{{pageTranslation.replyButtonLabel}} </orgos-button-raised>
    </orgos-column>
  </orgos-column-container>
</orgos-dialog-container>
