import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { PrivateAmplitudeService } from '@app/private/services/private-amplitude.service';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { GlobalBarService } from '@app/standard/services/core/global-bar.service';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { FeedService } from '@app/standard/services/feed/feed.service';

@Component({
  selector: 'orgos-feed-comments-dialog',
  templateUrl: 'feed-comments.dialog.html',
  styleUrls: ['feed-comments.dialog.scss'],
})
export class FeedCommentsDialog implements OnInit, OnDestroy {
  pageTranslation: any = {};
  insightsTranslation: any = {};
  surveysTranslation: any = {};
  commentText: string = '';
  commentAdded: boolean = false;

  currentUserId: string;
  isAnonymous: boolean = true;

  constructor(
    public dialogRef: MatLegacyDialogRef<FeedCommentsDialog>,
    private injector: Injector,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public parentInfo: any
  ) {}

  ngOnInit(): void {
    this.currentUserId = this.injector.get(AuthenticationService).getLoggedUser()._id;
    this.injector
      .get(InternationalizationService)
      .getAllTranslation('feed-comments-dialog')
      .then((pageTranslation) => {
        this.pageTranslation = pageTranslation;
      })
      .catch(() => {
        this.pageTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('pulse-insights-page')
      .then((insightsTranslation) => {
        this.insightsTranslation = insightsTranslation;
      })
      .catch(() => {
        this.insightsTranslation = {};
      });

    this.injector
      .get(InternationalizationService)
      .getAllTranslation('survey-page')
      .then((surveysTranslation) => {
        this.surveysTranslation = surveysTranslation;
      })
      .catch(() => {
        this.surveysTranslation = {};
      });

    this.calculateIsAnonymous();
  }

  private calculateIsAnonymous(): void {
    const userProfile = this.injector.get(AuthenticationService).getLoggedUser().profile;
    this.isAnonymous = userProfile._isAdmin === true || userProfile._profileKey === 'hr-admin' ? false : true;
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close(this.commentAdded);
  }

  addComment(): void {
    if (!this.commentText || this.commentText.trim().length === 0) {
      return;
    }

    this.injector.get(GlobalBarService).setProgressBar(true);

    const data = {
      message: this.commentText,
      isAnonymous: this.isAnonymous,
    };
    this.injector
      .get(FeedService)
      .createComment(this.parentInfo['feed']._id, data)
      .then((feeds) => {
        if (this.isAnonymous === false) {
          data['sentById'] = this.currentUserId;
        }
        this.parentInfo['feed'].comments.push(data);
        this.commentAdded = true;
        this.injector.get(MatLegacySnackBar).open(`${this.pageTranslation.commentAddedMessage}`, 'OK', {
          duration: 5000,
        });
        this.commentText = '';
        this.injector
          .get(PrivateAmplitudeService)
          .logEvent('reply to feedback', { category: 'Performance', anonymous: this.isAnonymous === true ? 'yes' : 'no' });
        this.injector.get(GlobalBarService).setProgressBar(false);
      })
      .catch(() => {
        // Error already handled in service
      });
  }
}
