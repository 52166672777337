<orgos-dialog-container
  [title]="importingPayslips && (onGoing || importSuccess) ? '' : shouldImportPayslips ? translations.importPayslipsSummary : translations.requestSignatures"
  [disabledCloseButton]="importingPayslips && onGoing"
  (clickCloseButton)="closeDialog()"
  [attr.id]="componentId !== null ? componentId : null"
>
  <div *ngIf="!importingPayslips">
    <!-- BOXES SECTION -->
    <orgos-column-container class="kenjo-mt-10px">
      <orgos-column class="pcd-summary-container">
        <div class="pcd-icon-container kenjo-border-radius"><kenjo-icon [size]="20">document_notification</kenjo-icon></div>
        <div class="pcd-summary-content kenjo-ml-10px">
          {{ translations.documents }}
          <div>{{ summaryData.documents }}</div>
        </div>
      </orgos-column>
      <orgos-column class="pcd-summary-container kenjo-ml-40px">
        <div class="pcd-icon-container kenjo-border-radius"><kenjo-icon [size]="20">people</kenjo-icon></div>
        <div class="pcd-summary-content kenjo-ml-10px">
          {{ translations.employees }}
          <div>{{ summaryData.employees }}</div>
        </div>
      </orgos-column>
      <orgos-column class="kenjo-ml-40px">
        <div *ngIf="summaryData.showSignatures && summaryData.creditsToBeUsed > 0" class="pcd-summary-container">
          <div class="pcd-icon-container kenjo-border-radius"><kenjo-icon [size]="20">signature</kenjo-icon></div>
          <div class="pcd-summary-content kenjo-ml-10px">
            {{ translations.signatures }}
            <div>{{ summaryData.signatures }}</div>
          </div>
        </div>
      </orgos-column>
    </orgos-column-container>

    <!-- SIGNATURES SECTION -->
    <div class="kenjo-mt-40px" [class.pcd-signature-counter]="!summaryData.showSignatures || summaryData.creditsToBeUsed <= 0">
      <div *ngIf="summaryData.showSignatures && summaryData.creditsToBeUsed > 0">
        <div class="pcd-summary-subtitle">
          {{ translations.remainingCredits }}
        </div>
        <orgos-column-container class="kenjo-mt-20px kenjo-font-color-light-text-757575">
          <orgos-column size="0"> {{ translations.currentCredits }}</orgos-column>
          <orgos-column size="1" class="pcd-empty-column"></orgos-column>
          <orgos-column size="0">{{ summaryData.currentCredits }}</orgos-column>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mv-20px kenjo-font-color-light-text-757575">
          <orgos-column size="0"> {{ translations.creditsToBeUsed }}</orgos-column>
          <orgos-column size="1" class="pcd-empty-column"></orgos-column>
          <orgos-column size="0">{{ summaryData.creditsToBeUsed }}</orgos-column>
        </orgos-column-container>
        <orgos-column-container class="kenjo-border-bottom">
          <orgos-column size="0"></orgos-column>
        </orgos-column-container>
        <orgos-column-container class="kenjo-mt-20px">
          <orgos-column size="0"> {{ shouldImportPayslips ? translations.creditsAfter : translations.creditsAfterSignatures }}</orgos-column>
          <orgos-column size="1" class="pcd-empty-column"></orgos-column>
          <orgos-column size="0">{{ totalAfter }}</orgos-column>
        </orgos-column-container>
      </div>
    </div>

    <!-- ACTIONS SECTION -->
    <orgos-column-container class="kenjo-mt-40px pcd-wrap-buttons">
      <orgos-column size="1" class="pcd-empty-column"></orgos-column>
      <orgos-column size="0" class="pcd-flex-shrink">
        <orgos-button-raised color="Neutral" (click)="closeDialog()" class="pcd-close pcd-flex-shrink">{{ translations.goBack }}</orgos-button-raised>
      </orgos-column>
      <orgos-column size="0" class="pcd-flex-shrink">
        <orgos-button-raised class="kenjo-ml-20px pcd-confirm" (click)="confirmDialog()">
          {{ shouldImportPayslips ? translations.importAllDocuments : translations.requestSignatures }}
        </orgos-button-raised>
      </orgos-column>
    </orgos-column-container>
  </div>

  <!-- Import - 1st step -->
  <div *ngIf="importingPayslips && onGoing" class="pcd-progress-container kenjo-text-align-center">
    <div class="pcd-loading kenjo-p-20px">
      <div class="pcd-icon-container kenjo-border-radius">
        <kenjo-icon class="pcd-ongoin-step-icon" [size]="20">document_notification</kenjo-icon>
      </div>
    </div>
    <div class="pcd-loading">
      <div class="pc-loading-bar">
        <mat-progress-bar mode="determinate" [value]="importingProgress"></mat-progress-bar>
      </div>
    </div>
    <div class="kenjo-mt-20px kenjo-pt-20px kenjo-font-size-25px">
      {{ translations.importingDocuments }}
    </div>
    <div class="kenjo-pt-10px kenjo-font-size-16px">
      <div>{{ translations.pleaseWait }}</div>
      <b>{{ translations.doNotClose }}</b>
    </div>
  </div>

  <!-- Import - Success -->
  <div *ngIf="importingPayslips && !onGoing && importSuccess" class="lipc-progress-container kenjo-text-align-center">
    <img src="/assets/images/success.svg" width="30%" height="30%" />
    <div class="kenjo-pt-20px kenjo-font-size-25px">
      {{ translations.importSuccessful }}
    </div>
    <div class="kenjo-pt-10px kenjo-font-size-16px">
      {{ translations.allDocumentsImportedOk }}
    </div>
    <orgos-button-raised class="kenjo-pt-20px pcd-got-it" (click)="goToImport()">
      {{ translations.gotIt }}
    </orgos-button-raised>
  </div>

  <!-- Import - Signature Errors -->
  <div *ngIf="importingPayslips && !onGoing && !importSuccess && summaryData.showSignatures && signaturesWithError > 0" class="lipc-progress-container">
    <div class="kenjo-pt-20px">
      {{ translations.errorMessage1 }}
    </div>
    <div class="kenjo-pt-20px">
      <orgos-column-container centerColumns="true">
        <orgos-document-icon orgosColumn="0" size="small" extension="pdf" class="kenjo-mr-10px"></orgos-document-icon>
        <orgos-column size="0" class="kenjo-mr-5px"> {{ signaturesWithError }} </orgos-column>
        <orgos-column size="0" class="kenjo-mr-20px">
          {{ translations.documents | lowercase }}
        </orgos-column>
        <orgos-column size="0" class="kenjo-mr-5px">
          <orgos-chip color="#FF5757" class="kenjo-text-align-center pcd-white-text">
            {{ translations.importError }}
          </orgos-chip>
        </orgos-column>
      </orgos-column-container>
    </div>
    <div class="kenjo-pt-20px">
      {{ translations.errorMessage2 }}
    </div>
    <orgos-column-container class="kenjo-mt-40px pcd-wrap-buttons">
      <orgos-column size="1" class="pcd-empty-column"></orgos-column>
      <orgos-column size="0" class="pcd-flex-shrink">
        <orgos-button-raised class="kenjo-ml-20px pcd-confirm" (click)="goToDigitalSignatures()" [color]="Neutral">
          {{ translations.goToDigitalSignatures }}
        </orgos-button-raised>
      </orgos-column>
    </orgos-column-container>
  </div>
</orgos-dialog-container>
