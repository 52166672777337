<mat-form-field *ngIf="model && field && !readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput #input="matInput" [required]="required" [minlength]="minlength" [maxlength]="maxlength" [matAutocomplete]="auto" [formControl]="formController" />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field *ngIf="model && field && readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput #input="matInput" [ngModel]="getValue()" readOnly disabled [required]="required" />
  <div *ngIf="prefix && (input.focused || !input.empty)" matPrefix>{{ prefix }}</div>
</mat-form-field>
<div *ngIf="!isValueValid" class="ita-error"><ng-content select="orgos-input-error"></ng-content></div>
