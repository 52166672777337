import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPipelineStageModel } from '@app/standard/services/recruiting/recruiting-pipeline-stage.service';

import { environment } from '../../../../environments/environment';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

export interface IPipelineDetailModel {
  pipeline: IPipelineModel;
  stages: Array<IPipelineStageModel>;
}

@Injectable()
export class RecruitingPipelineService implements IGenericService {
  private RECRUITING_PIPELINE_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/recruiting-pipeline-db`;
  private RECRUITING_PIPELINE_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/recruiting/job-opening-detail/pipeline`;
  private RECRUITING_PIPELINE_PERMISSIONS_KEY: string = 'recruiting-pipeline';
  private RECRUITING_PIPELINE_INTERNATIONALIZATION: string = 'recruiting-pipeline-collection';

  constructor(private genericService: GenericService, private http: HttpClient, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IPipelineModel> {
    return new Promise<IPipelineModel>((resolve, reject) => {
      this.genericService
        .create(this.RECRUITING_PIPELINE_URL, data)
        .then((recruitingPipeline: IPipelineModel) => {
          resolve(recruitingPipeline);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingPipelineService.name, 'create'));
        });
    });
  }

  getById(id: string): Promise<IPipelineModel> {
    return new Promise<IPipelineModel>((resolve, reject) => {
      this.genericService
        .getById(this.RECRUITING_PIPELINE_URL, id)
        .then((recruitingPipeline: IPipelineModel) => {
          resolve(recruitingPipeline);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingPipelineService.name, 'getById'));
        });
    });
  }

  async getDetails(id: string): Promise<IPipelineDetailModel> {
    try {
      return await this.http
        .post<IPipelineDetailModel>(this.RECRUITING_PIPELINE_CONTROLLER_URL, { id })
        .toPromise();
    } catch (error) {
      this.errorManager.handleRawError(error, RecruitingPipelineService.name, 'getById');
    }
  }

  find(findBody: object): Promise<Array<IPipelineModel>> {
    return new Promise<Array<IPipelineModel>>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_PIPELINE_URL, findBody)
        .then((recruitingPipelines: Array<any>) => {
          resolve(recruitingPipelines);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingPipelineService.name, 'find'));
        });
    });
  }

  updateById(id: string, data: IPipelineModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.RECRUITING_PIPELINE_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingPipelineService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .deleteById(this.RECRUITING_PIPELINE_URL, id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingPipelineService.name, 'deleteById'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.RECRUITING_PIPELINE_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.RECRUITING_PIPELINE_INTERNATIONALIZATION);
  }

  getAllPipelines(): Promise<Array<IPipelineModel>> {
    return new Promise<Array<IPipelineModel>>((resolve, reject) => {
      this.genericService
        .find(this.RECRUITING_PIPELINE_URL, { _id: { $ne: null } })
        .then((results: Array<IPipelineModel>) => {
          results.sort((a: IPipelineModel, b: IPipelineModel) => {
            if (a._isDefault === true) {
              return -1;
            }

            if (b._isDefault === true) {
              return 1;
            }

            return a.name.localeCompare(b.name);
          });
          resolve(results);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, RecruitingPipelineService.name, 'getAllPipelines'));
        });
    });
  }
}

export interface IPipelineModel {
  _id: string;
  name: string;
  _isDefault: boolean;
  stageIds?: Array<string>;
}
