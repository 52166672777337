import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/standard/services/core/authentication.service';
import { ErrorManagerService } from '@app/standard/services/error/error-manager.service';
import { ACCESS_TYPE_SURVEY_ACCESS, ACCESS_TYPE_AGENCY_ACCESS } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import { environment } from '@env';
import * as check from 'check-types';
import Fuse from 'fuse.js';

@Injectable({
  providedIn: 'root'
})
export class PrivateSearchService {
  private SEARCH_CONTROLLER_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/controller/search`;
  private FUSE_OPTIONS = {
    keys: [
      'displayName', // User display name
      'name', // Document name
      { name: 'fullName', getFn: (item) => `${item.firstName} ${item.lastName}` } // Compose user or candidate first name and last name
    ],
    minMatchCharLength: 2,
    threshold: 0.3
  };
  private FUSE_SEARCH_LIMIT = 500;

  private searchableItems: Array<ISearchItem> = [];
  private fuse: Fuse<ISearchItem>;

  constructor(private http: HttpClient, private injector: Injector) {}

  public async initSearch(): Promise<void> {
    try {
      // Restricted profile should not have access to search functionality
      if (this.injector.get(AuthenticationService).getLoggedUser().profileKey === 'restricted') {
        return;
      }
      const accessType = this.injector.get(AuthenticationService).getAccessType();
      if (accessType === ACCESS_TYPE_SURVEY_ACCESS || accessType === ACCESS_TYPE_AGENCY_ACCESS) {
        return;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      const result = await this.http.get(`${this.SEARCH_CONTROLLER_URL}/items`, httpOptions).toPromise();
      this.initSearchInfo(result as Array<ISearchItem>);
    } catch {
      this.clearSearchInfo();
    }
  }

  private initSearchInfo(searchableItems: Array<ISearchItem>) {
    this.searchableItems = searchableItems;
    this.fuse = new Fuse(this.searchableItems, this.FUSE_OPTIONS);
  }

  public clearSearchInfo(): void {
    this.searchableItems = [];
    this.fuse = undefined;
  }

  public search(searchTerm: string): { searchResults: Array<ISearchResult>; searchItems: Array<ISearchItem> } {
    if (check.not.assigned(this.searchableItems) || check.emptyArray(this.searchableItems) || check.not.assigned(this.fuse)) {
      return { searchResults: [], searchItems: [] };
    }
    const searchUsers: Array<ISearchUser> = [];
    const searchCandidates: Array<ISearchCandidate> = [];
    const searchDocuments: Array<ISearchDocument> = [];
    const fuseResult = this.fuse.search(searchTerm, { limit: this.FUSE_SEARCH_LIMIT });
    const searchItems = fuseResult.map((iResult) => {
      if (iResult.item.itemType === ItemType.USER) {
        searchUsers.push(iResult.item as ISearchUser);
      } else if (iResult.item.itemType === ItemType.CANDIDATE) {
        searchCandidates.push(iResult.item as ISearchCandidate);
      } else {
        searchDocuments.push(iResult.item as ISearchDocument);
      }
      return iResult.item;
    });

    const searchResults: Array<ISearchResult> = [
      { searchType: ItemType.USER, items: searchUsers },
      { searchType: ItemType.CANDIDATE, items: searchCandidates },
      { searchType: ItemType.DOCUMENT, items: searchDocuments }
    ];
    return { searchResults, searchItems };
  }

  public async searchAll(): Promise<any> {
    try {
      // Restricted profile should not have access to search functionality
      if (this.injector.get(AuthenticationService).getLoggedUser().profileKey === 'restricted') {
        return;
      }
      const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.injector.get(AuthenticationService).getAuthorizationHeader());
      const httpOptions = {
        headers: httpHeaders
      };
      return await this.http.get(`${this.SEARCH_CONTROLLER_URL}/items`, httpOptions).toPromise();
    } catch (error) {
      throw this.injector.get(ErrorManagerService).handleRawError(error, PrivateSearchService.name, 'searchAll');

    }
  }
}

export interface ISearchResult {
  searchType: ItemType;
  items: Array<ISearchItem>;
}
export enum ItemType {
  USER = 'user',
  CANDIDATE = 'candidate',
  DOCUMENT = 'document'
}
export type ISearchItem = ISearchUser | ISearchCandidate | ISearchDocument;
export interface ISearchUser {
  _id: string;
  itemType: ItemType;
  displayName: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
}
export interface ISearchCandidate {
  _id: string;
  itemType: ItemType;
  firstName: string;
  lastName: string;
  photoUrl: string;
}
export interface ISearchDocument {
  _id: string;
  itemType: ItemType;
  name: string;
  fileName: string;
  fileExtension: string;
}
