import { Component, Inject, Injector, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { IMyTeamWidgetSettingsModel } from '@app/cloud-features/settings-my-team/models/my-team-widget-settings.model';
import { SearchComponent, SearchFunction } from '@app/standard/components/search/search.component';
import { InternationalizationService } from '@app/standard/services/core/internationalization.service';
import { PreferenceService } from '@app/standard/services/preference/preference.service';
import { ProfileService } from '@app/standard/services/profile/profile.service';
import { MY_TEAM_TRANSLATION_KEY_SUBORDINATES } from '@carlos-orgos/orgos-utils/constants/picklist.constants';
import * as check from 'check-types';
import * as _ from 'lodash';

@Component({
  selector: 'kenjo-my-team-widget-details',
  templateUrl: 'my-team-widget-details.dialog.html',
  styleUrls: ['my-team-widget-details.dialog.scss'],
})
export class MyTeamWidgetDetailsDialog implements OnInit {
  isAdmin: boolean;
  configurationKeys: any;
  allUsers: any;
  selectableUsers: any;
  favoriteUsers: Array<any>;
  myTeamMembers: Array<any>;
  myTeamWidgetSetting: IMyTeamWidgetSettingsModel;

  MY_TEAM_TRANSLATION_KEY_SUBORDINATES: string = MY_TEAM_TRANSLATION_KEY_SUBORDINATES;
  isFavoritesSectionActive: boolean = false;
  isPageLoaded: boolean = false;
  showDefaultConfiguration: boolean = false;
  pageTranslation: any = {};

  @ViewChild(SearchComponent) searchComponent: SearchComponent;

  constructor(
    public dialogRef: MatLegacyDialogRef<MyTeamWidgetDetailsDialog>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    try {
      this.pageTranslation = await this.injector.get(InternationalizationService).getAllTranslation('my-team-widget-details-dialog');
      const profile = await this.injector.get(ProfileService).getMine();

      this.isAdmin = profile._isAdmin;

      this.configurationKeys = this.data.configurationKeys;
      this.myTeamWidgetSetting = this.data.myTeamWidgetSetting;
      this.isFavoritesSectionActive = this.data.isFavoritesSectionActive;
      this.myTeamMembers = this.data.myTeamMembers;
      this.allUsers = this.data.allUsers;

      this.processSelectedUsersAsFavorites();
      this.isPageLoaded = true;
    } catch (error) {
      this.pageTranslation = {};
    }
  }

  public searchUserFunction: SearchFunction = (value: string): Promise<Array<any>> => {
    if (check.not.assigned(this.favoriteUsers)) {
      return Promise.resolve([]);
    }

    const favoriteUserIds = this.favoriteUsers.map((iUserId) => iUserId._id);
    this.selectableUsers = this.allUsers.filter((iUser) => favoriteUserIds.includes(iUser._id) === false);

    if (check.not.assigned(value) || check.emptyString(value)) {
      return Promise.resolve(this.favoriteUsers);
    }

    let results = this.selectableUsers.filter((iUser: any) => {
      if (check.not.assigned(iUser)) {
        return false;
      }

      const regExp = new RegExp(`^.*${value}.*$`, 'i');
      return regExp.test(iUser.displayName);
    });

    results = _.orderBy(results, ['displayName'], ['asc']);

    return Promise.resolve(results);
  };

  public deleteFavoriteUser(userId: string): Function {
    return () => {
      const favoriteUserToDeleteIndex = this.favoriteUsers.findIndex((iUser) => iUser._id === userId);
      this.favoriteUsers.splice(favoriteUserToDeleteIndex, 1);
    };
  }

  public addFavoriteUser(userId: string): void {
    const newFavoriteUserIndex = this.allUsers.findIndex((iUser) => iUser._id === userId);
    this.favoriteUsers.push(this.allUsers[newFavoriteUserIndex]);
    this.searchComponent.clearSearch();
  }

  public async saveAndCloseFavoritesSection(): Promise<void> {
    if (this.isFavoritesSectionActive === false) {
      this.closeDialog(false);
      return;
    }

    const favoritesList = this.favoriteUsers.map((iFavUser) => iFavUser._id);
    const preference = {
      favoritesList: favoritesList,
    };
    await this.injector.get(PreferenceService).setPreferenceByKey('favorites-colleagues-widget', preference);
    this.closeDialog(true);
  }

  goToSettings(): void {
    this.injector.get(Router).navigateByUrl(`/cloud/settings/myTeam`);
    this.dialogRef.close(false);
  }

  public closeDialog(updated: boolean): void {
    this.dialogRef.close(updated);
  }

  private processSelectedUsersAsFavorites(): void {
    if (this.isFavoritesSectionActive) {
      const favoriteUsers = this.myTeamMembers
        .find((iSection) => iSection.translationKey === 'favorites')
        ?.members?.map((iMember) => iMember._id);

      if (check.assigned(favoriteUsers)) {
        this.favoriteUsers = this.allUsers.filter((iUser) => favoriteUsers.includes(iUser._id));
        this.selectableUsers = this.allUsers.filter((iUser) => favoriteUsers.includes(iUser._id) === false);
        return;
      }
    }

    this.favoriteUsers = [];
    this.selectableUsers = [...this.allUsers];
  }
}
