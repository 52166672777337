<mat-form-field *ngIf="!readOnly" [id]="componentIds?.enableSearchingInput" (click)="enableSearching()" cdk-overlay-origin>
  <mat-label>{{ label }}</mat-label>
  <ng-container *ngIf="isOpenShift">
    <input matInput [ngModel]="pageTranslation.openShiftText" readonly (ngModelChange)="searchTerm = $event" #isucInput />
  </ng-container>
  <ng-container *ngIf="!isOpenShift">
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let employee of selectedEmployees">
        {{ employee.name }}
        <kenjo-icon [size]="16" class="kenjo-cursor-pointer" (click)="remove(employee, $event)">clear</kenjo-icon>
      </mat-chip>
      <input matInput [placeholder]="selectedEmployees?.length > 0 ? pageTranslation.addMorePlaceholder : pageTranslation.addEmployeesPlaceholder" [matChipInputFor]="chipList" [id]="componentIds?.inputSearch" [(ngModel)]="searchTerm" #isucInput (blur)="blurEvent()" />
    </mat-chip-list>
  </ng-container>
  <mat-icon matSuffix *ngIf="searching">search</mat-icon>
  <div matSuffix *ngIf="!searching" class="issuc-select-arrow-wrapper mat-select-arrow"></div>
  <mat-icon *ngIf="!searching && showClearButton" matSuffix class="issuc-clear-button" (click)="clearValue(); $event.stopPropagation()">clear</mat-icon>
</mat-form-field>
<mat-form-field *ngIf="readOnly">
  <mat-label>{{ label }}</mat-label>
  <input matInput [ngModel]="getDisplayedValue()" readOnly disabled />
</mat-form-field>
<ng-template cdk-portal>
  <div class="issuc-cdk-box">
    <div [id]="componentIds?.resultsBox" class="issuc-results-container">
      <!-- Add open shift -->
      <orgos-column-container *ngIf="(shiftplanPermissions['open'].create && !editMode) || (editMode && shiftplanPermissions['open'].edit)" [centerColumns]="true" class="kenjo-pl-15px issuc-result kenjo-pv-5px" (click)="setValue('open-shift'); blurEvent()">
        <orgos-column size="0" class="kenjo-color-white-background-ffffff add-open-shift">
          <kenjo-icon class="kenjo-p-10px" [size]="24">person_add</kenjo-icon>
        </orgos-column>
        <orgos-column orgosColumn="0" class="kenjo-p-10px kenjo-mr-50px">
          <div>{{ pageTranslation.openShift }}</div>
          <orgos-column-container class="kenjo-mt-5px" orgosColumn="0">
            <div orgosColumn="0" class="kenjo-mr-5px issu-notWorkingDay" *ngFor="let day of daysInWeekList"></div>
          </orgos-column-container>
        </orgos-column>
        <orgos-column orgosColumn>
          <div>{{ pageTranslation.createEmptyShift }}</div>
        </orgos-column>
      </orgos-column-container>

      <ng-container *ngIf="(shiftplanPermissions['scheduled'].create && !editMode) || (editMode && shiftplanPermissions['scheduled'].edit)">
        <!-- Matching role/location -->
        <orgos-column-container *ngIf="!showInterestedEmployees" [centerColumns]="true" class="kenjo-pl-15px kenjo-pt-15px kenjo-pb-20px kenjo-font-color-light-text-757575">
          <kenjo-icon class="kenjo-pl-10px kenjo-pr-5px" [size]="20" orgosColumn="0">verified</kenjo-icon>
          <orgos-column>
            {{ searchMatchingResults?.length !== 0 ? pageTranslation.matchingEmployees : pageTranslation.noMatching }}
          </orgos-column>
        </orgos-column-container>

        <!-- Interested employees -->
        <orgos-column-container *ngIf="showInterestedEmployees" [centerColumns]="true" class="kenjo-pl-15px kenjo-pt-15px kenjo-pb-20px kenjo-font-color-light-text-757575">
          <kenjo-icon class="kenjo-pl-10px kenjo-pr-5px" [class.kenjo-font-color-disabled-c4c4c4]="searchMatchingResults?.length === 0" [size]="20" orgosColumn="0">people</kenjo-icon>
          <orgos-column>
            {{ pageTranslation.matchingInterestedEmployees }}
          </orgos-column>
        </orgos-column-container>

        <!-- Employees search result -->
        <div *ngIf="searchMatchingResults?.length > 0">
          <cdk-virtual-scroll-viewport itemSize="50" [class.issu-more-items]="searchMatchingResults?.length >= 3" [class.issu-one-item]="searchMatchingResults?.length == 1" [class.issu-two-items]="searchMatchingResults?.length == 2">
            <div *cdkVirtualFor="let result of searchMatchingResults" class="kenjo-p-10px issuc-result issu-item" (click)="setValue(result._id); blurEvent()" orgosAutocompleteOption>
              <kenjo-input-shiftplan-user [user]="result" [employeeSchedules]="employeeSchedules" [isSameWeek]="isSameWeek" [notMatchedEntities]="notMatchedEntities" [selectedData]="selectedData" [pageTranslation]="pageTranslation"></kenjo-input-shiftplan-user>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>

        <!-- Everyone else-->
        <div *ngIf="searchResults?.length > 0">
          <orgos-column-container [centerColumns]="true" class="kenjo-pl-15px kenjo-pt-15px kenjo-pb-20px kenjo-font-color-light-text-757575">
            <kenjo-icon class="kenjo-pl-10px kenjo-pr-5px" [size]="20" orgosColumn="0">groups</kenjo-icon>
            <orgos-column class="issu-else">
              {{ pageTranslation.everyoneElse }}
            </orgos-column>
          </orgos-column-container>
          <cdk-virtual-scroll-viewport itemSize="50" [class.issu-more-items]="searchResults?.length >= 3" [class.issu-one-item]="searchResults?.length == 1" [class.issu-two-items]="searchResults?.length == 2">
            <div *cdkVirtualFor="let result of searchResults" class="kenjo-p-10px issuc-result issu-item" (click)="setValue(result._id)" orgosAutocompleteOption>
              <kenjo-input-shiftplan-user [user]="result" [employeeSchedules]="employeeSchedules" [isSameWeek]="isSameWeek" [notMatchedEntities]="notMatchedEntities" [selectedData]="selectedData" [pageTranslation]="pageTranslation"></kenjo-input-shiftplan-user>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
