<div *ngIf="!readOnly" (click)="enableSearching()" cdk-overlay-origin>
  <div class="search-container">
    <div class="input-container">
      <input class="kenjo-font-size-14px kenjo-font-color-text-333333" [placeholder]="label" [ngModel]="getDisplayedValue()" (ngModelChange)="searchTerm = $event" [required]="required" #isucInput />
    </div>
    <div class="icon-container" *ngIf="getDisplayedValue() !== ''"><kenjo-icon [size]="24" class="kenjo-block kenjo-cursor-pointer kenjo-font-color-danger-ff5757" (click)="clearInput()">cancel</kenjo-icon></div>
    <div class="icon-container" *ngIf="getDisplayedValue() === ''"><kenjo-icon [size]="24" class="kenjo-block">search</kenjo-icon></div>
  </div>
</div>

<div *ngIf="readOnly">
  <div class="search-container">
    <div class="input-container">
      <input class="kenjo-font-size-14px kenjo-font-color-text-333333" [placeholder]="label" readOnly disabled [ngModel]="getDisplayedValue()" (ngModelChange)="searchTerm = $event" [required]="required" #isucInput />
    </div>
    <div class="icon-container" *ngIf="getDisplayedValue() !== ''"><kenjo-icon [size]="24" class="kenjo-block kenjo-cursor-pointer kenjo-font-color-danger-ff5757" (click)="clearInput()">cancel</kenjo-icon></div>
    <div class="icon-container" *ngIf="getDisplayedValue() === ''"><kenjo-icon [size]="24" class="kenjo-block">search</kenjo-icon></div>
  </div>
</div>

<ng-template cdk-portal>
  <orgos-container class="isuc-results-container">
    <orgos-text *ngIf="searchResults && searchResults.length === 0" color="Warn" class="kenjo-p-10px">{{ miscTranslation.userNotFoundInInputSearchUser }}</orgos-text>
    <orgos-column-container *ngFor="let result of searchResults; let i = index" centerColumns="true" class="kenjo-p-10px isuc-result" [class.isuc-result-focused]="indexSearchResultFocused === i" (click)="setValue(result._id)" orgosAutocompleteOption>
      <orgos-avatar orgosColumn="0" [photoUrl]="result._photo?._url" [avatarName]="result.displayName" size="small" class="kenjo-mr-10px"></orgos-avatar>
      <orgos-text orgosColumn>{{ result.displayName }}</orgos-text>
    </orgos-column-container>
  </orgos-container>
</ng-template>
<orgos-input-hint *ngIf="showSavedHint">{{ miscTranslation.savedHintText }}</orgos-input-hint>
