<ng-container *ngIf="i18n && i18n.page">
  <div class="kenjo-font-size-18px kenjo-font-color-light-text-757575">
    {{parentTranslation.rolesTagsHeader}}&nbsp;/&nbsp;<span class="kenjo-font-color-text-333333">{{parentTranslation.rolesSettings}}</span>
  </div>
  <div class="kenjo-mt-30px kenjo-font-color-light-text-757575">{{i18n.page.rolesDescription}}</div>

  <orgos-action *ngIf="canCreateRoles && allRoles" icon="add_circle" class="kenjo-mt-30px" (click)="addRole()"
    >{{i18n.page.addRoleButton}}</orgos-action
  >
  <div *ngIf="allRoles.length === 0" class="kenjo-text-align-center kenjo-p-40px">
    <div class="kenjo-flex-horizontal kenjo-flex-center">
      <kenjo-icon [size]="16" class="kenjo-mr-10px kenjo-font-color-success-00b72e">info_outline</kenjo-icon
      ><span class="kenjo-font-size-16px">{{i18n.page.noRolesHeader}}</span>
    </div>
    <div class="kenjo-mt-20px kenjo-font-color-light-text-757575">{{i18n.page.noRolesDescription}}</div>
  </div>
  <kenjo-input-search-shift-plan
    class="sspr-role-container"
    *ngIf="allRoles && allRoles.length > 0 && mappedRolesPermissions"
    [list]="allRoles"
    [placeholder]="i18n.page.searchForRoles"
    (filteredResults)="filteredRoles = $event"
  ></kenjo-input-search-shift-plan>
  <orgos-container aspect="card" *ngIf="filteredRoles && filteredRoles.length > 0" class="kenjo-mt-20px kenjo-mb-10px sspr-role-container">
    <orgos-table [fullHeight]="true" [data]="filteredRoles" [displayedColumns]="['colour', 'name', 'codeName', 'actions']">
      <orgos-table-column columnKey="colour" [cellWidth]="5">
        <ng-container *orgosTableCell="let row">
          <orgos-column-container centerColumns="true">
            <orgos-column
              class="kenjo-cursor-pointer kenjo-ml-20px kenjo-mr-20px"
              [style.backgroundColor]="colorConstantsObject[row.colour]"
              class="sspr-role-circle-mini"
            ></orgos-column>
          </orgos-column-container>
        </ng-container>
      </orgos-table-column>
      <orgos-table-column columnKey="name" [header]="i18n.page.roleNameColumn" [sortable]="true" [cellWidth]="50">
        <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
          <kenjo-truncate-text class="sspr-role-name">{{row.name}}</kenjo-truncate-text>
        </div>
      </orgos-table-column>
      <orgos-table-column columnKey="codeName" [header]="i18n.page.roleNameCodeColumn" [sortable]="true" [cellWidth]="30">
        <div class="kenjo-font-size-14px kenjo-font-color-text-333333" *orgosTableCell="let row">
          <kenjo-truncate-text class="sspr-role-name">{{row.codeName}}</kenjo-truncate-text>
        </div>
      </orgos-table-column>
      <orgos-table-column columnKey="actions" [actionColumn]="true" [cellWidth]="15">
        <div *orgosTableCell="let row" class="kenjo-text-align-left">
          <kenjo-icon
            [size]="20"
            (click)="editRole(row)"
            [matTooltip]="mappedRolesPermissions[row._id] ? parentTranslation.noPermissionsTooltip : ''"
            matTooltipPosition="below"
            [class.sspr-icon-disabled]="mappedRolesPermissions[row._id]"
            class="kenjo-cursor-pointer kenjo-font-color-primary-5993e3"
            >mode_edit</kenjo-icon
          >
          <kenjo-icon
            [size]="20"
            (click)="validateIfRoleIsAttached(row)"
            [matTooltip]="mappedRolesPermissions[row._id] ? parentTranslation.noPermissionsTooltip : ''"
            matTooltipPosition="below"
            [class.sspr-icon-disabled]="mappedRolesPermissions[row._id]"
            class="kenjo-cursor-pointer kenjo-font-color-danger-ff5757 kenjo-ml-10px"
            >delete</kenjo-icon
          >
        </div>
      </orgos-table-column>
    </orgos-table>
  </orgos-container>
  <kenjo-shift-plan-paginator
    class="sspr-role-container"
    [parentTranslation]="parentTranslation"
    [data]="allRoles"
    (recordsToShow)="setFilteredRolesToShow($event)"
  ></kenjo-shift-plan-paginator>
</ng-container>
