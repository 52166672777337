import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, takeWhile } from 'rxjs/operators';

import { InputAbstractComponent } from '../input-abstract/input-abstract.component';

@Component({
  selector: 'orgos-input-text-autocomplete',
  templateUrl: 'input-text-autocomplete.component.html',
  styleUrls: ['input-text-autocomplete.component.scss']
})
export class InputTextAutocompleteComponent extends InputAbstractComponent implements OnInit, OnDestroy {
  private keepSubscriptions: boolean = true;

  @Input() suggestedOptions: Array<string>;
  filteredOptions: Observable<Array<string>>;
  formController = new UntypedFormControl();

  ngOnInit() {
    super.ngOnInit();
    this.filteredOptions = this.formController.valueChanges.pipe(
      takeWhile(() => this.keepSubscriptions),
      startWith<string>(''),
      map((value) => this._filter(value))
    );

    this.formController.valueChanges.pipe(takeWhile(() => this.keepSubscriptions)).subscribe((newValue: string) => {
      this.setValue(newValue);
    });

    this.formController.setValue(this.getValue());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.keepSubscriptions = false;
  }

  private _filter(value: string): Array<string> {
    const filterValue = value.toLowerCase();

    return this.suggestedOptions.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
