import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ErrorCodes } from '../../core/error/error-codes';
import { OrgosError } from '../../core/error/orgos-error';
import { ErrorManagerService } from '../error/error-manager.service';
import { GenericService, IGenericService } from '../generic.service';

@Injectable()
export class UserConfidentialService implements IGenericService {
  private USER_CONFIDENTIAL_URL: string = `${environment.PEOPLE_CLOUD_APP_URL}/user-confidential-db`;
  private USER_CONFIDENTIAL_PERMISSIONS_KEY: string = 'user-confidential';
  private USER_CONFIDENTIAL_INTERNATIONALIZATION: string = 'user-confidential-collection';

  constructor(private genericService: GenericService, private errorManager: ErrorManagerService) {}

  create(data: object): Promise<IUserConfidentialModel> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserConfidentialService.name, 'create');
    error.message = 'UserConfidential should not be created';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  getById(id: string): Promise<IUserConfidentialModel> {
    return new Promise<IUserConfidentialModel>((resolve, reject) => {
      this.genericService
        .getById(this.USER_CONFIDENTIAL_URL, id)
        .then((userConfidential: IUserConfidentialModel) => {
          resolve(userConfidential);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserConfidentialService.name, 'getById'));
        });
    });
  }

  updateById(id: string, data: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.genericService
        .updateById(this.USER_CONFIDENTIAL_URL, id, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserConfidentialService.name, 'updateById'));
        });
    });
  }

  deleteById(id: string): Promise<void> {
    const error = new OrgosError('NOT IMPLEMENTED', ErrorCodes.CLIENT_ERROR, UserConfidentialService.name, 'deleteById');
    error.message = 'UserConfidential should not be deleted';
    this.errorManager.handleParsedErrorSilently(error);

    return Promise.reject(error);
  }

  find(findBody: any): Promise<Array<IUserConfidentialModel>> {
    return new Promise<Array<IUserConfidentialModel>>((resolve, reject) => {
      this.genericService
        .find(this.USER_CONFIDENTIAL_URL, findBody)
        .then((userConfidential: Array<IUserConfidentialModel>) => {
          resolve(userConfidential);
        })
        .catch((error) => {
          reject(this.errorManager.handleRawError(error, UserConfidentialService.name, 'find'));
        });
    });
  }

  getPermissions(): Promise<object> {
    return this.genericService.getPermissions(this.USER_CONFIDENTIAL_PERMISSIONS_KEY);
  }

  getFieldsTranslations(): Promise<object> {
    return this.genericService.getFieldsTranslations(this.USER_CONFIDENTIAL_INTERNATIONALIZATION);
  }

  getModel(): Promise<any> {
    return this.genericService.getModel(this.USER_CONFIDENTIAL_URL);
  }
}

export interface IUserConfidentialModel {
  _id: string;
  status?: string;
  terminationDate?: string;
  terminationReason?: string;
}
