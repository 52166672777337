<div *ngIf="templateTranslations && !isLoading" class="sptp-resizable-container sptp-non-droppable">
  <div
    id="templateMenu"
    class="sptp-resizable-content"
    appResizable
    [style.minHeight]="allTemplates?.length === 0 ? '400px' : '236px'"
    [class.sptp-resizable-content-animation-open]="!closePanel"
    [class.sptp-resizable-content-animation-close]="closePanel"
    [class.hidden]="panelStatus === 'close'"
  >
    <div class="sptp-relative-container" *ngIf="shiftplanPermissions['templates'].read; else userCannotReadTemplates">
      <div class="kenjo-m-15px">
        <orgos-column-container class="sptp-buttons" [centerColumns]="true">
          <!-- New Template -->
          <div
            orgosColumn
            class="sptp-new-template"
            (click)="addNewTemplate(); $event.preventDefault()"
            [class.disabled]="!shiftplanPermissions['templates'].create"
          >
            <kenjo-icon [size]="20">add</kenjo-icon>
            <div class="kenjo-text-nowrap">{{ templateTranslations.newTemplate }}</div>
          </div>
          <orgos-column></orgos-column>

          <!-- Sort icons -->
          <div
            orgosColumn="0"
            class="sptp-new-template icon"
            (click)="changeSortOrder()"
            [class.disabled]="!shiftplanPermissions['templates'].create || displayedTemplates.length <= 1"
          >
            <kenjo-icon [matTooltip]="sortTooltip" matTooltipPosition="below" [size]="20">{{ sortIcon }}</kenjo-icon>
          </div>

          <!-- Template settings -->
          <div
            orgosColumn="0"
            class="sptp-new-template icon"
            [matMenuTriggerFor]="sortSettingsMenu"
            [class.sptp-ripple]="rippleSettings"
            [class.disabled]="!shiftplanPermissions['templates'].create"
          >
            <kenjo-icon [matTooltip]="templateTranslations.templateSettingsTooltip" matTooltipPosition="below" [size]="20"
              >settings_menu</kenjo-icon
            >
          </div>

          <mat-menu #sortSettingsMenu="matMenu">
            <kenjo-shiftplan-template-sort-settings
              *ngIf="sortPreference"
              [sortPreference]="sortPreference"
              [templateTranslations]="templateTranslations"
              [isSorting]="isSorting"
              [canBeSorted]="displayedTemplates.length > 1"
              (sortByChange)="changeSortBy($event)"
              (applyFiltersChange)="changeApplyFilters()"
            ></kenjo-shiftplan-template-sort-settings>
          </mat-menu>
        </orgos-column-container>

        <div *ngIf="allTemplates.length > 0; else noTemplates">
          <orgos-input-simple-text
            [(value)]="searchTerm"
            [debounceNewValues]="true"
            [debounceTimer]="200"
            [showClearButton]="true"
            [label]="templateTranslations.searchTemplates"
            suffixIcon="search"
            (valueChange)="searchTemplates($event)"
            class="sptp-search"
          ></orgos-input-simple-text>

          <div class="kenjo-font-color-light-text-757575 kenjo-font-size-12px sptp-results">
            <span *ngIf="!searchTerm">{{
              displayedTemplates.length === 0
                ? templateTranslations.noResults
                : (templateTranslations.totalTemplates | i18nData: { total: sortedTemplates.length })
            }}</span>
            <ng-container *ngIf="searchTerm">
              <span *ngIf="displayedTemplates.length === 0; else searchResults">{{ templateTranslations.noResults }}</span>
              <ng-template #searchResults>
                {{ templateTranslations.showingResults | i18nData: { filtered: displayedTemplates.length, total: sortedTemplates.length } }}
              </ng-template>
            </ng-container>
          </div>

          <orgos-column-container class="kenjo-mb-15px" [centerColumns]="true" *ngIf="sortPreference.applyFilters">
            <orgos-column size="0">
              <div
                [matTooltip]="templateTranslations.disableFiltersTooltip"
                matTooltipPosition="below"
                (mouseenter)="rippleSettings = true"
                (mouseleave)="rippleSettings = false"
              >
                <kenjo-icon [size]="20" class="kenjo-mr-10px kenjo-font-color-primary-5993e3">info_outline</kenjo-icon>
              </div>
            </orgos-column>
            <orgos-column>
              <kenjo-truncate-text>{{ templateTranslations.scheduleFiltersApplied }}</kenjo-truncate-text>
            </orgos-column>
          </orgos-column-container>

          <div
            *ngIf="displayedTemplates.length > 0 && !isSorting; else noTemplatesResults"
            cdkDropList
            #templateList="cdkDropList"
            [cdkDropListData]="allTemplates"
            cdkDropListSortingDisabled
            [cdkDropListConnectedTo]="LIST_IDS"
          >
            <div
              *ngFor="let template of displayedTemplates; let i = index; trackBy: trackByTemplate"
              class="sptp-template"
              [ngStyle]="{ transform: 'translateY(' + i * 100 + '%)' }"
            >
              <kenjo-shift-plan-template
                (draggingEventEmitter)="handleDraggingTemplate($event, template)"
                [showTemplateDetails]="showTemplateDetails"
                [selectedTemplateId]="templateDetail?._id"
                [template]="template"
                [pageTranslation]="templateTranslations"
                (openAddShiftDialogEvent)="addNewShift(null, {}, template)"
                (mouseenter)="showDetails($event, template)"
                (mouseleave)="hideDetails()"
                (editTemplateEvent)="addNewTemplate(template)"
              ></kenjo-shift-plan-template>
            </div>
          </div>
          <ng-template #noTemplatesResults>
            <div class="kenjo-text-align-center sptp-template-box" [@isSortingAnimation]>
              <ng-container *ngIf="displayedTemplates.length === 0">
                <img src="/assets/images/emptyState.svg" />
                <div class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-weight-bold">{{ templateTranslations.noTemplates }}</div>
                <div class="kenjo-mt-10px kenjo-font-size-12px">
                  {{
                    !areFiltersEmpty && sortPreference.applyFilters && !searchTerm
                      ? templateTranslations.noTemplatesFilter
                      : templateTranslations.noTemplatesSearch
                  }}
                </div>
              </ng-container>
              <ng-container *ngIf="isSorting && displayedTemplates.length !== 0">
                <orgos-loading-spinner [color]="'Success'"></orgos-loading-spinner>
              </ng-container>
            </div>
          </ng-template>
        </div>
        <ng-template #noTemplates>
          <div class="kenjo-text-align-center sptp-template-box">
            <img src="/assets/images/emptyState.svg" />
            <div class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-weight-bold">{{ templateTranslations.noTemplatesYet }}</div>
            <div class="kenjo-mt-10px kenjo-font-size-12px">{{ templateTranslations.noTemplatesText }}</div>
            <orgos-button-raised class="kenjo-mt-20px kenjo-text-transform-uppercase" color="Primary" (click)="addNewTemplate()">
              {{ templateTranslations.newTemplate }}</orgos-button-raised
            >
          </div>
        </ng-template>
      </div>
    </div>
    <ng-template #userCannotReadTemplates>
      <div class="kenjo-text-transform-uppercase kenjo-font-weight-bold kenjo-m-15px">
        <div class="kenjo-font-size-16px kenjo-text-align-center">{{ templateTranslations.templates }}</div>
      </div>
      <div class="kenjo-text-align-center sptp-template-box">
        <img src="/assets/images/emptyState.svg" />
        <div class="kenjo-mt-20px kenjo-font-size-16px kenjo-font-weight-bold">{{ templateTranslations.noAccessTemplates }}</div>
        <div class="kenjo-mt-10px kenjo-font-size-12px">{{ templateTranslations.noAccessTemplatesContact }}</div>
      </div>
    </ng-template>
    <div class="sptp-resize-box kenjo-pv-10px kenjo-border-top">
      <div></div>
    </div>
  </div>
</div>
<div *ngIf="showTemplateDetails && templateDetail && !isDragging" class="sptp-non-droppable">
  <kenjo-shift-plan-template-details
    [template]="templateDetail"
    [detailsPosition]="detailsPosition"
    [pageTranslation]="templateTranslations"
  ></kenjo-shift-plan-template-details>
</div>
