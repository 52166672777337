import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import * as check from 'check-types';

@Component({
  selector: 'orgos-text',
  templateUrl: 'text.component.html',
  styleUrls: ['text.component.scss']
})
export class TextComponent implements AfterViewInit {
  disableTooltip: boolean = true;
  @Input() tooltipPosition: 'above' | 'below' | 'left' | 'right' | 'before' | 'after' = 'before';
  @Input()
  set type(type: 'Headline1' | 'Headline2' | 'Headline3' | 'Headline4' | 'BodyText' | 'SecondaryText') {
    this.typeHeadline1Class = false;
    this.typeHeadline2Class = false;
    this.typeHeadline3Class = false;
    this.typeHeadline4Class = false;
    this.typeBodyTextClass = false;
    this.typeSecondaryTextClass = false;

    if (check.not.assigned(type)) {
      this.typeBodyTextClass = true;
    } else if (type === 'Headline1') {
      this.typeHeadline1Class = true;
    } else if (type === 'Headline2') {
      this.typeHeadline2Class = true;
    } else if (type === 'Headline3') {
      this.typeHeadline3Class = true;
    } else if (type === 'Headline4') {
      this.typeHeadline4Class = true;
    } else if (type === 'BodyText') {
      this.typeBodyTextClass = true;
    } else if (type === 'SecondaryText') {
      this.typeSecondaryTextClass = true;
    } else {
      this.typeBodyTextClass = true;
    }
  }

  @Input()
  set color(color: 'Primary' | 'Warn' | 'NormalText' | 'LightText' | 'White') {
    this.colorPrimaryClass = false;
    this.colorWarnClass = false;
    this.colorNormalTextClass = false;
    this.colorLightTextClass = false;
    this.colorWhiteClass = false;

    if (check.not.assigned(color)) {
      this.colorNormalTextClass = true;
    } else if (color === 'Primary') {
      this.colorPrimaryClass = true;
    } else if (color === 'Warn') {
      this.colorWarnClass = true;
    } else if (color === 'NormalText') {
      this.colorNormalTextClass = true;
    } else if (color === 'LightText') {
      this.colorLightTextClass = true;
    } else if (color === 'White') {
      this.colorWhiteClass = true;
    } else {
      this.colorNormalTextClass = true;
    }
  }

  @Input() preserveLineBreaks: boolean = false;

  private _noWrapText: string = '';
  @Input()
  set noWrapText(noWrapText: string) {
    this._noWrapText = noWrapText;
  }
  get noWrapText(): string {
    return this._noWrapText;
  }

  @HostBinding('class.kenjo-font-size-30px') typeHeadline1Class: boolean = false;
  @HostBinding('class.kenjo-font-size-25px') typeHeadline2Class: boolean = false;
  @HostBinding('class.kenjo-font-size-18px') typeHeadline3Class: boolean = false;
  @HostBinding('class.kenjo-font-size-16px') typeHeadline4Class: boolean = false;
  @HostBinding('class.kenjo-font-size-14px') typeBodyTextClass: boolean = true;
  @HostBinding('class.kenjo-font-size-12px') typeSecondaryTextClass: boolean = false;

  @HostBinding('class.kenjo-font-color-primary-5993e3') colorPrimaryClass: boolean = false;
  @HostBinding('class.kenjo-font-color-danger-ff5757') colorWarnClass: boolean = false;
  @HostBinding('class.kenjo-font-color-text-333333') colorNormalTextClass: boolean = true;
  @HostBinding('class.kenjo-font-color-light-text-757575') colorLightTextClass: boolean = false;
  @HostBinding('class.kenjo-font-color-white-ffffff') colorWhiteClass: boolean = false;

  @ViewChild('contentWrapper', { static: true }) content: ElementRef;

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.refreshTooltip();
  }

  refreshTooltip(): void {
    if (check.assigned(this.noWrapText) && check.nonEmptyString(this.noWrapText) && check.assigned(this.content)) {
      const parentWidth = this.elementRef.nativeElement.scrollWidth;
      const contentWidth = this.content.nativeElement.scrollWidth;

      this.disableTooltip = parentWidth >= contentWidth;
      this.cdr.detectChanges();
    } else {
      this.disableTooltip = true;
      this.cdr.detectChanges();
    }
  }
}
